import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'
import { combineLatest } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { RecommendationsQuery } from '../../recommendations/state/recommendations.query'
import { WorkplanStore, WorkplanState } from './workplan.store'

@Injectable({ providedIn: 'root' })
export class WorkplanQuery extends QueryEntity<WorkplanState> {
  constructor(
    protected store: WorkplanStore,
    private recommendationsQuery: RecommendationsQuery
  ) {
    super(store)
  }

  selectedItems$ = combineLatest(
    this.selectAll(),
    this.recommendationsQuery.selectAll({ asObject: true })
  ).pipe(map(joinItems), shareReplay({ bufferSize: 1, refCount: true }))

  selectNotificaton$ = this.select((state) => state.notify)
}

function joinItems([workplanItems, recommendations]) {
  return workplanItems.map((workplan) => {
    const recommendation = recommendations[workplan.recommendationId]
    return {
      ...workplan,
      ...recommendation,
    }
  })
}

function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, { [obj[key]]: hash[obj[key]] })
  })
}
