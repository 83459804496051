import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FiltersService } from '../recommendations/state/filters/filters.service'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  regions = [
    'City of Rock Hill',
    'Town of Fort Mill',
    'Town of Pineville',
    'City of Charlotte',
    'City of Belmont',
    'City of Gastonia',
    'City of Kings Mountain',
    'City of Mount Holly',
    'Town of Harrisburg',
    'City of Concord',
    'City of Kannapolis',
    'City of Salisbury',
    'Mount Mourne Area',
    'Town of Mooresville',
    'York County',
    'Mecklenburg County',
    'Iredell County',
    'Gaston County',
    'Cabarrus County',
    'Rowan County',
  ]
  region: any

  modes = [
    { name: 'Bike', value: 'bike' },
    { name: 'Car', value: 'car' },
    { name: 'Bus', value: 'bus' },
    { name: 'Train', value: 'train' },
    { name: 'Freight', value: 'freight' },
    { name: 'Rideshare', value: 'rideshare' },
    { name: 'Microtransit', value: 'micro' },
    { name: 'Walking', value: 'walking' },
  ]

  constructor(private filterService: FiltersService, private router: Router) {}

  ngOnInit(): void {}

  keyValueFilter(keyArea: string) {
    this.filterService.setFilter({
      id: 'keyArea',
      value: keyArea,
      predicate: (entity) => entity.keyArea === keyArea,
    })
    this.router.navigate(['/recommendations'])
  }
}
