import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ID } from '@datorama/akita'
import { tap } from 'rxjs/operators'
import { Recommendation } from 'src/app/types'
import { createWorkplan, Workplan } from './workplan.model'
import { WorkplanQuery } from './workplan.query'
import { WorkplanStore } from './workplan.store'

@Injectable({ providedIn: 'root' })
export class WorkplanService {
  constructor(
    private workplanStore: WorkplanStore,
    private workplanQuery: WorkplanQuery
  ) {}

  addRecommendationToWorkplan(recommendationId: Recommendation['id']) {
    const item = createWorkplan({ recommendationId: recommendationId })

    return this.workplanStore.upsert(recommendationId, item)
  }

  removeRecommendation(recommendationId: Recommendation['id']) {
    this.workplanStore.remove(recommendationId)
  }
}
