import { Recommendation } from '../../recommendations/state/recommendation.model'

export interface Workplan {
  recommendationId: Recommendation['id']
  description: string
  keyArea: string
  modeCount: number
  loeSmallCount: number
  levelOfEffortCount: number
  loeLargeCount: number
  mpoRoleCount: number
  mpoRoleTeamCount: number
}

export function createWorkplan(params: Partial<Workplan>) {
  return {
    description: '',
    keyArea: '',
    modeCount: 0,
    loeSmallCount: 0,
    levelOfEffortCount: 0,
    loeLargeCount: 0,
    mpoRoleCount: 0,
    mpoRoleTeamCount: 0,
    ...params,
  } as Workplan
}
