import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { geo, Geojson, Features } from './map-shapes'

@Injectable({
  providedIn: 'root',
})
export class MapService {
  mapData: Geojson = geo
  layerData: Features[] = geo.features
  private _mapData = new BehaviorSubject<Geojson>(null)
  private _mapLayer = new BehaviorSubject<Features[]>(this.layerData)
  mapLayer$ = this._mapLayer.asObservable()
  mapData$ = this._mapData.asObservable()
  constructor() {}

  getLayer(id: string): Observable<Features[]> {
    let layer: Features[]
    if (this.hasParent(id)) {
      layer = this.layerData.filter(
        (f) => f.properties.SolutionID.replace(':', '-') === id
      )
    } else {
      layer = this.layerData.filter(
        (f) => f.properties.SolutionID === id
      )
    }

    if (layer.length === 0) {
      layer = this.layerData.filter((f) => f.properties.SolutionID === 'main')
    }

    this._mapLayer.next(layer)
    return this.mapLayer$
  }

  private hasParent(id: string): boolean {
    return (id.match(/-/g) || []).length === 2
  }
}
