import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core'
import { FORMERR } from 'dns'
import * as mapboxgl from 'mapbox-gl'

import {
  AnyLayout,
  Map,
  accessToken,
  LngLatBounds,
  LngLatLike,
} from 'mapbox-gl'
import { MAPBOX_API_KEY } from 'ngx-mapbox-gl'
import { Subscription } from 'rxjs'
import { geo } from './map-shapes'
import { MapService } from './map.service'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() id: string
  geojson: any = geo
  mapSubscription: Subscription
  filter: any
  style = 'mapbox://styles/lukeus/cku4favw21knj17pj0cf61pg7'
  lat = 35.2051
  lng = -80.8127
  bounds: mapboxgl.LngLatBounds
  zoom = [6]

  layouts: { [key: string]: AnyLayout } = {
    recom: {
      visibility: 'visible',
    },
  }

  constructor(private ref: ChangeDetectorRef, private mapData: MapService) {}
  // ngAfterContentChecked(): void {
  //   this.ref.detectChanges()
  // }

  ngOnInit(): void {
    if (this.id) {
      this.mapSubscription = this.mapData.getLayer(this.id).subscribe((s) => {
        this.filter = s
        this.geojson.features = this.filter
        this.zoomToBounds()
      })
    }
    // //this.map.repaint = true
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.mapSubscription.unsubscribe()
  }

  // Supports mulitple features (with the same SolutionID) with multipolygons(an array of polygons within a feature.)
  zoomToBounds() {
    let myBounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds([this.lng, this.lat, this.lng, this.lat]);
    for (let feature of this.geojson.features) {
      let coordinates: any = feature.geometry.coordinates
      // start with the center point
      for (let polygon of coordinates) {
        let newBounds: mapboxgl.LngLatBounds = myBounds
        myBounds = polygon.reduce((bounds, coord) => {
          return bounds.extend(coord)
        }, myBounds);
      };
    }
    this.bounds = myBounds;
  }

  toggleLayer(evt: { value: string }) {
    this.layouts[evt.value] = {
      ...this.layouts[evt.value],
      visibility:
        this.layouts[evt.value].visibility === 'visible' ? 'none' : 'visible',
    }
  }
}
