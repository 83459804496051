import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LayoutModule } from '@angular/cdk/layout'

import { WelcomeComponent } from './welcome/welcome.component'

import { MaterialModule } from './shared/modules/material.module'
import { RecommendationsComponent } from './recommendations/recommendations.component'

import { RecommendationCardComponent } from './recommendations/recommendation-card/recommendation-card.component'
import { ListComponent } from './recommendations/list/list.component'
import { DetailsComponent } from './recommendations/details/details.component'
import { MapComponent } from './recommendations/map/map.component'
import { GraphQLModule } from './graphql/graphql.module'
import { SearchFilterPipe } from './shared/search-filter.pipe'
import { PopupComponent } from './recommendations/popup/popup.component'
import { AkitaNgEffectsModule } from '@datorama/akita-ng-effects'
import { WorkplanEffects } from './workplan/state/workplan.effect'
import { WorkplanComponent } from './workplan/workplan.component'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FiltersComponent } from './recommendations/filters/filters.component'

import { HighchartsChartModule } from 'highcharts-angular'
import { ChartComponent } from './recommendations/chart/chart.component'
import { ScrollToSelectedDirective } from './shared/scroll-to-directive'
import { HorizontalScrollComponent } from './shared/components/horizontal-scroll/horizontal-scroll.component'
import { NgxPrintModule } from 'ngx-print';
import { PrintComponent } from './print/print.component'

@NgModule({
  declarations: [
    AppComponent,
    RecommendationsComponent,
    WelcomeComponent,
    RecommendationCardComponent,
    ListComponent,
    DetailsComponent,
    MapComponent,
    SearchFilterPipe,
    PopupComponent,
    WorkplanComponent,
    FiltersComponent,
    ChartComponent,
    ScrollToSelectedDirective,
    HorizontalScrollComponent,
    PrintComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    GraphQLModule,
    HighchartsChartModule,
    MaterialModule,
    NgxPrintModule,
    AkitaNgEffectsModule.forRoot([WorkplanEffects]),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
