export const barChartData = [
  {
    ID: 'PI-1',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0155314,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.3863314,
  },
  {
    ID: 'PI-2',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.00874501,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.25871171,
  },
  {
    ID: 'PI-3',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.05,
    'Land Use Alignment': 0,
    'Mode Choice': 0.00874501,
    'Regional Scalability': 0.0333,
    Equity: 0,
    Total: 0.29204501,
  },
  {
    ID: 'PI-4',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.00562001,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.33058701,
  },
  {
    ID: 'PI-5',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.25,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0124064,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.4499064,
  },
  {
    ID: 'PI-6',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0.2,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.03062,
    'Regional Scalability': 0,
    Equity: 0.025,
    Total: 0.28062,
  },
  {
    ID: 'PI-7',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.33487813,
  },
  {
    ID: 'PI-8',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.15,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.35987813,
  },
  {
    ID: 'PI-9',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.15,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.45987843,
  },
  {
    ID: 'PI-10',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0518775,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6185105,
  },
  {
    ID: 'PI-11',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0450911,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5617241,
  },
  {
    ID: 'PI-12',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0552707,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6219037,
  },
  {
    ID: 'PI-13',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.1,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0510729,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.3843729,
  },
  {
    ID: 'PI-14',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0456275,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.3914608,
  },
  {
    ID: 'PI-15',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.05,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0208021,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2541021,
  },
  {
    ID: 'PI-16',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.05,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0208021,
    'Regional Scalability': 0.05,
    Equity: 0.025,
    Total: 0.2708021,
  },
  {
    ID: 'PI-17',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0490207,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.4240207,
  },
  {
    ID: 'PI-18',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0422343,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.371401,
  },
  {
    ID: 'PI-19',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.25,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0774139,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.5857469,
  },
  {
    ID: 'PI-20',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0552707,
    'Regional Scalability': 0.01665,
    Equity: 0.05,
    Total: 0.5385877,
  },
  {
    ID: 'PI-21',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0262475,
    'Regional Scalability': 0,
    Equity: 0.025,
    Total: 0.3429142,
  },
  {
    ID: 'PI-22',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.00437251,
    'Regional Scalability': 0.01665,
    Equity: 0.0125,
    Total: 0.39185551,
  },
  {
    ID: 'PI-23',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.003125,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.3405917,
  },
  {
    ID: 'PI-24',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.003125,
    'Regional Scalability': 0,
    Equity: 0.0125,
    Total: 0.1572917,
  },
  {
    ID: 'PI-25',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0833333,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.276183,
  },
  {
    ID: 'PI-26',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.05,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.616667,
  },
  {
    ID: 'PI-27',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.25,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0676771,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.7176771,
  },
  {
    ID: 'PI-28',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0263411,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.4346741,
  },
  {
    ID: 'PI-29',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.25,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0642839,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.6642839,
  },
  {
    ID: 'PI-30',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.25,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0596432,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.6096432,
  },
  {
    ID: 'PI-31',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0289296,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.2413963,
  },
  {
    ID: 'PR-1',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0919661,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4419331,
  },
  {
    ID: 'PR-2',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0919661,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4419331,
  },
  {
    ID: 'PR-3',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0333021,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.2957688,
  },
  {
    ID: 'PR-4',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.216667,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0820546,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4445216,
  },
  {
    ID: 'PR-5',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.1178497,
  },
  {
    ID: 'PR-6',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.06875,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.485383,
  },
  {
    ID: 'PR-7',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.15,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0619636,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4452636,
  },
  {
    ID: 'PR-8',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0608907,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.7150237,
  },
  {
    ID: 'PR-9',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.1,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0810754,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4477084,
  },
  {
    ID: 'PR-10',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.00562001,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.10142001,
  },
  {
    ID: 'PR-11',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0453593,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.3661593,
  },
  {
    ID: 'PR-12',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0578593,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3161593,
  },
  {
    ID: 'PR-13',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0578593,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3161593,
  },
  {
    ID: 'PR-14',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0289296,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.2788963,
  },
  {
    ID: 'PR-15',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.19737813,
  },
  {
    ID: 'PR-16',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0578593,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3161593,
  },
  {
    ID: 'PR-17',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.071875,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.421842,
  },
  {
    ID: 'PR-18',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0364271,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2697271,
  },
  {
    ID: 'PR-19',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.266667,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0478543,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.7478213,
  },
  {
    ID: 'PR-20',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0422343,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.342201,
  },
  {
    ID: 'PR-21',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.25,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0684818,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.6184818,
  },
  {
    ID: 'PR-22',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.15,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0619636,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4452636,
  },
  {
    ID: 'PR-23',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0441929,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.2483262,
  },
  {
    ID: 'PR-24',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.075,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2333,
  },
  {
    ID: 'PR-25',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0820546,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5320216,
  },
  {
    ID: 'PR-26',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0429454,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4804124,
  },
  {
    ID: 'PR-27',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0833333,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0566929,
    'Regional Scalability': 0.01665,
    Equity: 0.025,
    Total: 0.3566762,
  },
  {
    ID: 'PR-28',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.3,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0783932,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.5991932,
  },
  {
    ID: 'PR-29',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.333333,
    Safety: 0.2,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0786614,
    'Regional Scalability': 0.01665,
    Equity: 0.0375,
    Total: 0.7161444,
  },
  {
    ID: 'PR-30',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0745571,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.6245571,
  },
  {
    ID: 'PR-31',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.266667,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0765157,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6514827,
  },
  {
    ID: 'PR-32',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0653568,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3986568,
  },
  {
    ID: 'PR-33',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0864271,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.6155601,
  },
  {
    ID: 'PR-34',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.15,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.06875,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.41455,
  },
  {
    ID: 'PR-35',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0289296,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4080626,
  },
  {
    ID: 'PR-36',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.003125,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.2572917,
  },
  {
    ID: 'PR-37',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.566633,
  },
  {
    ID: 'PR-38',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0581275,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4664275,
  },
  {
    ID: 'PR-39',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.3,
    'Land Use Alignment': 0,
    'Mode Choice': 0.0578593,
    'Regional Scalability': 0,
    Equity: 0.025,
    Total: 0.4828593,
  },
  {
    ID: 'PR-40',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.4345167,
  },
  {
    ID: 'PR-41',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.15,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0675025,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.4050025,
  },
  {
    ID: 'PR-42',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0251747,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.4126417,
  },
  {
    ID: 'PR-43',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.00562001,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.37642001,
  },
  {
    ID: 'PR-44',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.003125,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.307258,
  },
  {
    ID: 'PR-45',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.3,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0671407,
    'Regional Scalability': 0,
    Equity: 0.0125,
    Total: 0.6046407,
  },
  {
    ID: 'PR-46',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0419661,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.3044661,
  },
  {
    ID: 'PR-47',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0898204,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.5772874,
  },
  {
    ID: 'PR-48',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0410679,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5243679,
  },
  {
    ID: 'PR-49',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0776821,
    'Regional Scalability': 0.01665,
    Equity: 0.0375,
    Total: 0.5734991,
  },
  {
    ID: 'PR-50',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0643775,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4060105,
  },
  {
    ID: 'PR-51',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0864271,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.6155601,
  },
  {
    ID: 'PR-52',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.3,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0518775,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6685105,
  },
  {
    ID: 'PR-53',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.283333,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0752682,
    'Regional Scalability': 0,
    Equity: 0.05,
    Total: 0.5586012,
  },
  {
    ID: 'PR-54',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.15,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0376747,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.316808,
  },
  {
    ID: 'PR-55',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0354479,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.3354146,
  },
  {
    ID: 'PR-56',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0643775,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.3643442,
  },
  {
    ID: 'PR-57',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0888411,
    'Regional Scalability': 0,
    Equity: 0.0375,
    Total: 0.5180081,
  },
  {
    ID: 'PR-58',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.15,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0606225,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.3564225,
  },
  {
    ID: 'PR-59',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0484843,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.2859513,
  },
  {
    ID: 'PR-60',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0441929,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3941599,
  },
  {
    ID: 'PR-61',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0453593,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2369926,
  },
  {
    ID: 'PR-62',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0450911,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3700581,
  },
  {
    ID: 'PR-63',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.071875,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.421842,
  },
  {
    ID: 'PR-64',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0776821,
    'Regional Scalability': 0.01665,
    Equity: 0.0375,
    Total: 0.5734991,
  },
  {
    ID: 'PM-1',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0830339,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.5121669,
  },
  {
    ID: 'PM-2',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0574975,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4366305,
  },
  {
    ID: 'PM-5',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.075,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2083,
  },
  {
    ID: 'PM-6',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0453593,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.420326,
  },
  {
    ID: 'PM-7',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0518775,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5935105,
  },
  {
    ID: 'PM-8',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0875936,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5875606,
  },
  {
    ID: 'PM-9',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.283333,
    Safety: 0.3,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.09438,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.861013,
  },
  {
    ID: 'PM-10',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0909868,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.7242868,
  },
  {
    ID: 'PM-11',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.2,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0475861,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.5433861,
  },
  {
    ID: 'PM-12',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.366667,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0864272,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.8863942,
  },
  {
    ID: 'PM-13',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.266667,
    Safety: 0.3,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0642839,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.7892509,
  },
  {
    ID: 'PM-14',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.00651821,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.49398521,
  },
  {
    ID: 'PM-15',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.216667,
    Safety: 0.15,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0484843,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5484513,
  },
  {
    ID: 'PM-16',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.216667,
    Safety: 0.1,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0731225,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.4980895,
  },
  {
    ID: 'PM-17',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.15,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0608907,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4650237,
  },
  {
    ID: 'PM-18',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0909868,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4534538,
  },
  {
    ID: 'PM-19',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.23904473,
  },
  {
    ID: 'PM-20',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.1,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0966068,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5132398,
  },
  {
    ID: 'PM-21',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0808071,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6641071,
  },
  {
    ID: 'PM-22',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.3,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0617889,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6867559,
  },
  {
    ID: 'PM-23',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0774139,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3190472,
  },
  {
    ID: 'PM-24',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.1,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0453593,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.4078263,
  },
  {
    ID: 'PM-25',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.1,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.25987813,
  },
  {
    ID: 'PM-26',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0586639,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.6044639,
  },
  {
    ID: 'PM-27',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.05,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0518775,
    'Regional Scalability': 0,
    Equity: 0.025,
    Total: 0.3352105,
  },
  {
    ID: 'PM-28',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.25,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0518775,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.4726775,
  },
  {
    ID: 'PM-29',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0808071,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4474401,
  },
  {
    ID: 'PM-30',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0625936,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4625606,
  },
  {
    ID: 'PM-31',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.00991143,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.24737813,
  },
  {
    ID: 'PM-32',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.3,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0808071,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.7307741,
  },
  {
    ID: 'PM-33',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0450911,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.3283911,
  },
  {
    ID: 'PM-34',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.283333,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.816633,
  },
  {
    ID: 'PM-35',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.266667,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0574975,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.7074645,
  },
  {
    ID: 'PM-37',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.216667,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0552707,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6552377,
  },
  {
    ID: 'PM-38',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.3,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6833,
  },
  {
    ID: 'PM-39',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.266667,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0919661,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6419331,
  },
  {
    ID: 'PM-40',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0875936,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4625606,
  },
  {
    ID: 'PM-41',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0608907,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4775237,
  },
  {
    ID: 'PM-42',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.266667,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0799089,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6298759,
  },
  {
    ID: 'ET-1',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0833333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0616954,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4408287,
  },
  {
    ID: 'ET-2',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0543725,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5876725,
  },
  {
    ID: 'ET-3',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0478543,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4728213,
  },
  {
    ID: 'ET-4',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0864271,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.6155601,
  },
  {
    ID: 'ET-5',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.15,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0552707,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4510707,
  },
  {
    ID: 'ET-6',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3720167,
  },
  {
    ID: 'ET-7',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0475861,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5142191,
  },
  {
    ID: 'ET-8',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0354479,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4604146,
  },
  {
    ID: 'ET-9',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0444611,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.4194281,
  },
  {
    ID: 'ET-10',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0140157,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2639824,
  },
  {
    ID: 'ET-11',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.2,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0254429,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.4295759,
  },
  {
    ID: 'ET-12',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0254429,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5504099,
  },
  {
    ID: 'ET-13',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.133333,
    Safety: 0.2,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0254429,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.4420759,
  },
  {
    ID: 'ET-14',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0254429,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.5129099,
  },
  {
    ID: 'ET-15',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0833333,
    Safety: 0.2,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0864272,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5030605,
  },
  {
    ID: 'ET-16',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.05,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0509793,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.3842793,
  },
  {
    ID: 'ET-17',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.05,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0509793,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.4217793,
  },
  {
    ID: 'ET-18',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0898204,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6814534,
  },
  {
    ID: 'ET-19',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2499667,
  },
  {
    ID: 'ET-20',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.2541333,
  },
  {
    ID: 'ET-21',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0453593,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.2244926,
  },
  {
    ID: 'ET-22',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0666667,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0271457,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.2396124,
  },
  {
    ID: 'ET-23',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.213683,
  },
  {
    ID: 'ET-24',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.116667,
    Safety: 0.15,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0799089,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5048759,
  },
  {
    ID: 'ET-25',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.2,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0410679,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5827009,
  },
  {
    ID: 'ET-26',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0932136,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.5598466,
  },
  {
    ID: 'ET-27',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.25,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0574975,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6324645,
  },
  {
    ID: 'ET-28',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.0333333,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.00803393,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.27466723,
  },
  {
    ID: 'ET-29',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.183333,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.075,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.479133,
  },
  {
    ID: 'ET-30',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0.1,
    'Land Use Alignment': 0,
    'Mode Choice': 0.00437251,
    'Regional Scalability': 0,
    Equity: 0.0125,
    Total: 0.11687251,
  },
  {
    ID: 'ET-31',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.366667,
    Safety: 0.25,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0966068,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.8840738,
  },
  {
    ID: 'ET-32',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.05,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0509793,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.4592793,
  },
  {
    ID: 'ET-33',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.1,
    Safety: 0.3,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0463386,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5296386,
  },
  {
    ID: 'ET-34',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0410679,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.5410349,
  },
  {
    ID: 'ET-35',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.333333,
    Safety: 0.05,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0799089,
    'Regional Scalability': 0.0333,
    Equity: 0.05,
    Total: 0.6215419,
  },
  {
    ID: 'ET-36',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.333333,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0410679,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.6077009,
  },
  {
    ID: 'ET-37',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.2,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.4428497,
  },
  {
    ID: 'ET-38',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.2,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0254429,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.5295759,
  },
  {
    ID: 'ET-39',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0478543,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.5019873,
  },
  {
    ID: 'ET-40',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.166667,
    Safety: 0.2,
    'Land Use Alignment': 0.05,
    'Mode Choice': 0.0220497,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.4970167,
  },
  {
    ID: 'ET-41',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.233333,
    Safety: 0.15,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0574975,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.5116305,
  },
  {
    ID: 'ET-42',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0.3,
    Safety: 0.2,
    'Land Use Alignment': 0.025,
    'Mode Choice': 0.0799089,
    'Regional Scalability': 0.0333,
    Equity: 0.0125,
    Total: 0.6507089,
  },
  {
    ID: 'ET-43',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0254429,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2837429,
  },
  {
    ID: 'ET-44',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0.1,
    'Land Use Alignment': 0.1,
    'Mode Choice': 0.0608907,
    'Regional Scalability': 0.0333,
    Equity: 0.0375,
    Total: 0.3316907,
  },
  {
    ID: 'ET-45',
    Category: 'Safety and Travel Peformance',
    'Travel Performance': 0,
    Safety: 0.1,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0574975,
    'Regional Scalability': 0.0333,
    Equity: 0.025,
    Total: 0.2907975,
  },
  {
    ID: 'PI-1',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0388286,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.3220619,
  },
  {
    ID: 'PI-2',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0218625,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.3092625,
  },
  {
    ID: 'PI-3',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0,
    Safety: 0.0218625,
    'Land Use Alignment': 0,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.1884292,
  },
  {
    ID: 'PI-4',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.01405,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.3722834,
  },
  {
    ID: 'PI-5',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0310161,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.3768494,
  },
  {
    ID: 'PI-6',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.07655,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0,
    Equity: 0,
    Total: 0.2807167,
  },
  {
    ID: 'PI-7',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0247786,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.5121786,
  },
  {
    ID: 'PI-8',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0247786,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.4663453,
  },
  {
    ID: 'PI-9',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0247786,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.4913453,
  },
  {
    ID: 'PI-10',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.129694,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.7462606,
  },
  {
    ID: 'PI-11',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.112728,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.712628,
  },
  {
    ID: 'PI-12',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.138177,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.7547436,
  },
  {
    ID: 'PI-13',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.127682,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.5609153,
  },
  {
    ID: 'PI-14',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.114069,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.00833333,
    Equity: 0,
    Total: 0.48906903,
  },
  {
    ID: 'PI-15',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0520054,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.3310721,
  },
  {
    ID: 'PI-16',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0520054,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.025,
    Equity: 0.15,
    Total: 0.3811721,
  },
  {
    ID: 'PI-17',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.122552,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.535052,
  },
  {
    ID: 'PI-18',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.105586,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0166667,
    Equity: 0,
    Total: 0.4722527,
  },
  {
    ID: 'PI-19',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.193535,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0333333,
    Equity: 0,
    Total: 0.6477016,
  },
  {
    ID: 'PI-20',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.138177,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0416667,
    Equity: 0.04995,
    Total: 0.6797937,
  },
  {
    ID: 'PI-21',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0656188,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0166667,
    Equity: 0,
    Total: 0.3947855,
  },
  {
    ID: 'PI-22',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0109313,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0333333,
    Equity: 0.04995,
    Total: 0.3692146,
  },
  {
    ID: 'PI-23',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0078125,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.3993792,
  },
  {
    ID: 'PI-24',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0078125,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0,
    Total: 0.1411459,
  },
  {
    ID: 'PI-25',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0551241,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0208333,
    Equity: 0.0999,
    Total: 0.3091907,
  },
  {
    ID: 'PI-26',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.125,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0416667,
    Equity: 0,
    Total: 0.65,
  },
  {
    ID: 'PI-27',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.169193,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0625,
    Equity: 0,
    Total: 0.7150263,
  },
  {
    ID: 'PI-28',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0658527,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0333333,
    Equity: 0,
    Total: 0.486686,
  },
  {
    ID: 'PI-29',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.16071,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0625,
    Equity: 0,
    Total: 0.6898767,
  },
  {
    ID: 'PI-30',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.149108,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0625,
    Equity: 0,
    Total: 0.661608,
  },
  {
    ID: 'PI-31',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0723241,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.3680575,
  },
  {
    ID: 'PR-1',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.229915,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7756484,
  },
  {
    ID: 'PR-2',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.229915,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7756484,
  },
  {
    ID: 'PR-3',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0832554,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.4081554,
  },
  {
    ID: 'PR-4',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.205137,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0,
    'Regional Scalability': 0.0541667,
    Equity: 0.0999,
    Total: 0.6592037,
  },
  {
    ID: 'PR-5',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0551241,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.3175241,
  },
  {
    ID: 'PR-6',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.171875,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.5051083,
  },
  {
    ID: 'PR-7',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.154909,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0375,
    Equity: 0.0999,
    Total: 0.7089757,
  },
  {
    ID: 'PR-8',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.152227,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.7562936,
  },
  {
    ID: 'PR-9',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.202688,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.6442546,
  },
  {
    ID: 'PR-10',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.01405,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.27645,
  },
  {
    ID: 'PR-11',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.113398,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.6174647,
  },
  {
    ID: 'PR-12',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.144648,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.594548,
  },
  {
    ID: 'PR-13',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.144648,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.594548,
  },
  {
    ID: 'PR-14',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0723241,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.4805575,
  },
  {
    ID: 'PR-15',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0247786,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.3788453,
  },
  {
    ID: 'PR-16',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.144648,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.594548,
  },
  {
    ID: 'PR-17',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.179688,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7254214,
  },
  {
    ID: 'PR-18',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0910679,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.4784679,
  },
  {
    ID: 'PR-19',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.119636,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0666667,
    Equity: 0.0999,
    Total: 0.769536,
  },
  {
    ID: 'PR-20',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.105586,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.6388194,
  },
  {
    ID: 'PR-21',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.171204,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0625,
    Equity: 0,
    Total: 0.683704,
  },
  {
    ID: 'PR-22',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.154909,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0375,
    Equity: 0.0999,
    Total: 0.7089757,
  },
  {
    ID: 'PR-23',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.110482,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.46038203,
  },
  {
    ID: 'PR-24',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.1875,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.6124,
  },
  {
    ID: 'PR-25',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.205137,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7842037,
  },
  {
    ID: 'PR-26',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.107363,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.6489297,
  },
  {
    ID: 'PR-27',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.141732,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0208333,
    Equity: 0.04995,
    Total: 0.5083486,
  },
  {
    ID: 'PR-28',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.195983,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.075,
    Equity: 0.0999,
    Total: 0.7500497,
  },
  {
    ID: 'PR-29',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.196654,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0833333,
    Equity: 0.04995,
    Total: 0.634104,
  },
  {
    ID: 'PR-30',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.186393,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.05,
    Equity: 0,
    Total: 0.7030597,
  },
  {
    ID: 'PR-31',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.191289,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0666667,
    Equity: 0.0999,
    Total: 0.7453557,
  },
  {
    ID: 'PR-32',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.163392,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.5841253,
  },
  {
    ID: 'PR-33',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.216068,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.7243013,
  },
  {
    ID: 'PR-34',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.171875,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0375,
    Equity: 0.0999,
    Total: 0.6259417,
  },
  {
    ID: 'PR-35',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0723241,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.5388907,
  },
  {
    ID: 'PR-36',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.0078125,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0,
    Total: 0.4036459,
  },
  {
    ID: 'PR-37',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.25,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.7082333,
  },
  {
    ID: 'PR-38',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.145319,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.657719,
  },
  {
    ID: 'PR-39',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0,
    Safety: 0.144648,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.344648,
  },
  {
    ID: 'PR-40',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0,
    Safety: 0.0551241,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.3008575,
  },
  {
    ID: 'PR-41',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.168756,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.481256,
  },
  {
    ID: 'PR-42',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0629366,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.3545033,
  },
  {
    ID: 'PR-43',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.01405,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.2972833,
  },
  {
    ID: 'PR-44',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0078125,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.2743791,
  },
  {
    ID: 'PR-45',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.167852,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.05,
    Equity: 0,
    Total: 0.417852,
  },
  {
    ID: 'PR-46',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.104915,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.4465817,
  },
  {
    ID: 'PR-47',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.224551,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7827844,
  },
  {
    ID: 'PR-48',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.10267,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.6859033,
  },
  {
    ID: 'PR-49',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.194205,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.04995,
    Total: 0.6524884,
  },
  {
    ID: 'PR-50',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.160944,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.648344,
  },
  {
    ID: 'PR-51',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.216068,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.7243013,
  },
  {
    ID: 'PR-52',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.129694,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.7629273,
  },
  {
    ID: 'PR-53',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.188171,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0708333,
    Equity: 0,
    Total: 0.675671,
  },
  {
    ID: 'PR-54',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0941866,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.41491993,
  },
  {
    ID: 'PR-55',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.0886196,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.621853,
  },
  {
    ID: 'PR-56',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.160944,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.6941774,
  },
  {
    ID: 'PR-57',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.222103,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0,
    Total: 0.5054364,
  },
  {
    ID: 'PR-58',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.151556,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0,
    'Regional Scalability': 0.0375,
    Equity: 0.0999,
    Total: 0.588956,
  },
  {
    ID: 'PR-59',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.121211,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.4752777,
  },
  {
    ID: 'PR-60',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.110482,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.4270487,
  },
  {
    ID: 'PR-61',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.113398,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.43829803,
  },
  {
    ID: 'PR-62',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.112728,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.4709614,
  },
  {
    ID: 'PR-63',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.179688,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7254214,
  },
  {
    ID: 'PR-64',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.194205,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.04995,
    Total: 0.6524884,
  },
  {
    ID: 'PM-1',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.207585,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.6866516,
  },
  {
    ID: 'PM-2',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.143744,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.4978106,
  },
  {
    ID: 'PM-5',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.1875,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.5499,
  },
  {
    ID: 'PM-6',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.113398,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.6174647,
  },
  {
    ID: 'PM-7',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.129694,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.679594,
  },
  {
    ID: 'PM-8',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.218984,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.8105507,
  },
  {
    ID: 'PM-9',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.23595,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0708333,
    Equity: 0.0999,
    Total: 0.9066833,
  },
  {
    ID: 'PM-10',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.227467,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.8607003,
  },
  {
    ID: 'PM-11',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.118965,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.4980317,
  },
  {
    ID: 'PM-12',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.216068,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0916667,
    Equity: 0.0999,
    Total: 0.890968,
  },
  {
    ID: 'PM-13',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.16071,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0666667,
    Equity: 0.0999,
    Total: 0.7647767,
  },
  {
    ID: 'PM-14',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.0162955,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.5745289,
  },
  {
    ID: 'PM-15',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.121211,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0541667,
    Equity: 0.0999,
    Total: 0.6002777,
  },
  {
    ID: 'PM-16',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.182806,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0541667,
    Equity: 0.0999,
    Total: 0.570206,
  },
  {
    ID: 'PM-17',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.152227,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.5729603,
  },
  {
    ID: 'PM-18',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.227467,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.6857004,
  },
  {
    ID: 'PM-19',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0247786,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.44967863,
  },
  {
    ID: 'PM-20',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.241517,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.6955836,
  },
  {
    ID: 'PM-21',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.202018,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.8185847,
  },
  {
    ID: 'PM-22',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.154472,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.7335387,
  },
  {
    ID: 'PM-23',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.193535,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.64343503,
  },
  {
    ID: 'PM-24',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.113398,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.450798,
  },
  {
    ID: 'PM-25',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0247786,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.3121786,
  },
  {
    ID: 'PM-26',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.14666,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.6632267,
  },
  {
    ID: 'PM-27',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.129694,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0458333,
    Equity: 0,
    Total: 0.329694,
  },
  {
    ID: 'PM-28',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.129694,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0625,
    Equity: 0.0999,
    Total: 0.5337607,
  },
  {
    ID: 'PM-29',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.202018,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.751918,
  },
  {
    ID: 'PM-30',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.156484,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.7147174,
  },
  {
    ID: 'PM-31',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0247786,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.383012,
  },
  {
    ID: 'PM-32',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.202018,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.8435847,
  },
  {
    ID: 'PM-33',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.112728,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.5292947,
  },
  {
    ID: 'PM-34',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.25,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0708333,
    Equity: 0.0999,
    Total: 0.9040666,
  },
  {
    ID: 'PM-35',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.143744,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0666667,
    Equity: 0.0999,
    Total: 0.7769774,
  },
  {
    ID: 'PM-37',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.138177,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0541667,
    Equity: 0.0999,
    Total: 0.7589104,
  },
  {
    ID: 'PM-38',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.25,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.075,
    Equity: 0.0999,
    Total: 0.8582333,
  },
  {
    ID: 'PM-39',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.229915,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0666667,
    Equity: 0.0999,
    Total: 0.829815,
  },
  {
    ID: 'PM-40',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.218984,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.7147174,
  },
  {
    ID: 'PM-41',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.152227,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.714627,
  },
  {
    ID: 'PM-42',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.199772,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0666667,
    Equity: 0.0999,
    Total: 0.799672,
  },
  {
    ID: 'ET-1',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.154238,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0208333,
    Equity: 0.0999,
    Total: 0.6249713,
  },
  {
    ID: 'ET-2',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.135931,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.6149977,
  },
  {
    ID: 'ET-3',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.119636,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.632036,
  },
  {
    ID: 'ET-4',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.216068,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.7243013,
  },
  {
    ID: 'ET-5',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.138177,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.613077,
  },
  {
    ID: 'ET-6',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0551241,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.4758575,
  },
  {
    ID: 'ET-7',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.118965,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.5730316,
  },
  {
    ID: 'ET-8',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0886196,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.609353,
  },
  {
    ID: 'ET-9',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.111153,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.6068864,
  },
  {
    ID: 'ET-10',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0350393,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.4307727,
  },
  {
    ID: 'ET-11',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0636072,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.3635072,
  },
  {
    ID: 'ET-12',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.0636072,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.5968406,
  },
  {
    ID: 'ET-13',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0636072,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0333333,
    Equity: 0.0999,
    Total: 0.4010072,
  },
  {
    ID: 'ET-14',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0636072,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.5093406,
  },
  {
    ID: 'ET-15',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.216068,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0208333,
    Equity: 0.0999,
    Total: 0.678468,
  },
  {
    ID: 'ET-16',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.127448,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.4315147,
  },
  {
    ID: 'ET-17',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.127448,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.5315147,
  },
  {
    ID: 'ET-18',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.224551,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.786951,
  },
  {
    ID: 'ET-19',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.3957334,
  },
  {
    ID: 'ET-20',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.48740003,
  },
  {
    ID: 'ET-21',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.113398,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.52163133,
  },
  {
    ID: 'ET-22',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0678643,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0,
    'Regional Scalability': 0.0166667,
    Equity: 0.0999,
    Total: 0.484431,
  },
  {
    ID: 'ET-23',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.0551241,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.48835743,
  },
  {
    ID: 'ET-24',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.199772,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0291667,
    Equity: 0.0999,
    Total: 0.7038387,
  },
  {
    ID: 'ET-25',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.10267,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.64007,
  },
  {
    ID: 'ET-26',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.233034,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.8121006,
  },
  {
    ID: 'ET-27',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.143744,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.706144,
  },
  {
    ID: 'ET-28',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.0200848,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0.00833333,
    Equity: 0.0999,
    Total: 0.42415143,
  },
  {
    ID: 'ET-29',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.1875,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0458333,
    Equity: 0.0999,
    Total: 0.7124,
  },
  {
    ID: 'ET-30',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0,
    Safety: 0.0109313,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0,
    Equity: 0,
    Total: 0.0817646,
  },
  {
    ID: 'ET-31',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.241517,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0833333,
    'Regional Scalability': 0.0916667,
    Equity: 0.0999,
    Total: 0.878917,
  },
  {
    ID: 'ET-32',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.127448,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.6190147,
  },
  {
    ID: 'ET-33',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.115846,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.1,
    'Regional Scalability': 0.025,
    Equity: 0.0999,
    Total: 0.478246,
  },
  {
    ID: 'ET-34',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.10267,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.5734034,
  },
  {
    ID: 'ET-35',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.199772,
    'Land Use Alignment': 0.15,
    'Mode Choice': 0.0166667,
    'Regional Scalability': 0.0833333,
    Equity: 0.0999,
    Total: 0.737172,
  },
  {
    ID: 'ET-36',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.10267,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0833333,
    Equity: 0.0999,
    Total: 0.4734033,
  },
  {
    ID: 'ET-37',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0551241,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.05,
    Equity: 0.0999,
    Total: 0.3550241,
  },
  {
    ID: 'ET-38',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.0636072,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.3885072,
  },
  {
    ID: 'ET-39',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.119636,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.4278693,
  },
  {
    ID: 'ET-40',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.125,
    Safety: 0.0551241,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.0416667,
    Equity: 0.0999,
    Total: 0.4633575,
  },
  {
    ID: 'ET-41',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.143744,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.05,
    'Regional Scalability': 0.0583333,
    Equity: 0.0999,
    Total: 0.4519773,
  },
  {
    ID: 'ET-42',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.0625,
    Safety: 0.199772,
    'Land Use Alignment': 0.0375,
    'Mode Choice': 0.0666667,
    'Regional Scalability': 0.075,
    Equity: 0.0999,
    Total: 0.5413387,
  },
  {
    ID: 'ET-43',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.0636072,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.5218405,
  },
  {
    ID: 'ET-44',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.25,
    Safety: 0.152227,
    'Land Use Alignment': 0.1125,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.6479603,
  },
  {
    ID: 'ET-45',
    Category: 'Multimodal & Land Use Coordination',
    'Travel Performance': 0.1875,
    Safety: 0.143744,
    'Land Use Alignment': 0.075,
    'Mode Choice': 0.0333333,
    'Regional Scalability': 0,
    Equity: 0.0999,
    Total: 0.5394773,
  },
  {
    ID: 'PI-1',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0264034,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.3596167,
  },
  {
    ID: 'PI-2',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0148665,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.3405798,
  },
  {
    ID: 'PI-3',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0,
    Safety: 0,
    'Land Use Alignment': 0.0148665,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.2364132,
  },
  {
    ID: 'PI-4',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.085,
    'Land Use Alignment': 0.00955402,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.38860072,
  },
  {
    ID: 'PI-5',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.0210909,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.0375,
    Equity: 0,
    Total: 0.4044239,
  },
  {
    ID: 'PI-6',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.052054,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0,
    Equity: 0,
    Total: 0.286221,
  },
  {
    ID: 'PI-7',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.5125627,
  },
  {
    ID: 'PI-8',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.4967294,
  },
  {
    ID: 'PI-9',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.5342294,
  },
  {
    ID: 'PI-10',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0881917,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.7314047,
  },
  {
    ID: 'PI-11',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0766548,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.6932018,
  },
  {
    ID: 'PI-12',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0939602,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.7371732,
  },
  {
    ID: 'PI-13',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.0868239,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5525372,
  },
  {
    ID: 'PI-14',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0775667,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0125,
    Equity: 0,
    Total: 0.4517337,
  },
  {
    ID: 'PI-15',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0353636,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.3469103,
  },
  {
    ID: 'PI-16',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0353636,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0375,
    Equity: 0.18,
    Total: 0.4070303,
  },
  {
    ID: 'PI-17',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0833352,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0375,
    Equity: 0,
    Total: 0.4983352,
  },
  {
    ID: 'PI-18',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0717983,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.4317983,
  },
  {
    ID: 'PI-19',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.131604,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.05,
    Equity: 0,
    Total: 0.612437,
  },
  {
    ID: 'PI-20',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0939602,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0625,
    Equity: 0.05994,
    Total: 0.6364002,
  },
  {
    ID: 'PI-21',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0446208,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.3621208,
  },
  {
    ID: 'PI-22',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.1275,
    'Land Use Alignment': 0.00743326,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.05,
    Equity: 0.05994,
    Total: 0.36737326,
  },
  {
    ID: 'PI-23',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.1275,
    'Land Use Alignment': 0.0053125,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.4001925,
  },
  {
    ID: 'PI-24',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0053125,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.1419792,
  },
  {
    ID: 'PI-25',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.03125,
    Equity: 0.11988,
    Total: 0.3269477,
  },
  {
    ID: 'PI-26',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.085,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.0625,
    Equity: 0,
    Total: 0.620833,
  },
  {
    ID: 'PI-27',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.115051,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.09375,
    Equity: 0,
    Total: 0.682134,
  },
  {
    ID: 'PI-28',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0447798,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.05,
    Equity: 0,
    Total: 0.4722798,
  },
  {
    ID: 'PI-29',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.109283,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.09375,
    Equity: 0,
    Total: 0.6497,
  },
  {
    ID: 'PI-30',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.101393,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.09375,
    Equity: 0,
    Total: 0.615143,
  },
  {
    ID: 'PI-31',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.085,
    'Land Use Alignment': 0.0491804,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.3482271,
  },
  {
    ID: 'PR-1',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.156342,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.6866387,
  },
  {
    ID: 'PR-2',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.156342,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.6866387,
  },
  {
    ID: 'PR-3',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.0425,
    'Land Use Alignment': 0.0566137,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.424827,
  },
  {
    ID: 'PR-4',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.139493,
    'Mode Choice': 0,
    'Regional Scalability': 0.08125,
    Equity: 0.11988,
    Total: 0.595623,
  },
  {
    ID: 'PR-5',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.085,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.2848644,
  },
  {
    ID: 'PR-6',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.116875,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.513005,
  },
  {
    ID: 'PR-7',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.105338,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.05625,
    Equity: 0.11988,
    Total: 0.6481347,
  },
  {
    ID: 'PR-8',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.103514,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.741727,
  },
  {
    ID: 'PR-9',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.137828,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.6160413,
  },
  {
    ID: 'PR-10',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.085,
    'Land Use Alignment': 0.00955402,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.25693402,
  },
  {
    ID: 'PR-11',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.0771108,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5586575,
  },
  {
    ID: 'PR-12',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0983608,
    'Mode Choice': 0,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5107408,
  },
  {
    ID: 'PR-13',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0983608,
    'Mode Choice': 0,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5107408,
  },
  {
    ID: 'PR-14',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.0491804,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.4757271,
  },
  {
    ID: 'PR-15',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.3742294,
  },
  {
    ID: 'PR-16',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0983608,
    'Mode Choice': 0,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5107408,
  },
  {
    ID: 'PR-17',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.122188,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.6524847,
  },
  {
    ID: 'PR-18',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0619261,
    'Mode Choice': 0,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.4318061,
  },
  {
    ID: 'PR-19',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0813523,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.1,
    Equity: 0.11988,
    Total: 0.7745653,
  },
  {
    ID: 'PR-20',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0717983,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.583345,
  },
  {
    ID: 'PR-21',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.116419,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.09375,
    Equity: 0,
    Total: 0.630169,
  },
  {
    ID: 'PR-22',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.105338,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.05625,
    Equity: 0.11988,
    Total: 0.6481347,
  },
  {
    ID: 'PR-23',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.1275,
    'Land Use Alignment': 0.0751279,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4041746,
  },
  {
    ID: 'PR-24',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.1275,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.50238,
  },
  {
    ID: 'PR-25',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.139493,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.723123,
  },
  {
    ID: 'PR-26',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.0730071,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.6141371,
  },
  {
    ID: 'PR-27',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0963779,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.03125,
    Equity: 0.05994,
    Total: 0.4534012,
  },
  {
    ID: 'PR-28',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.133268,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.1125,
    Equity: 0.11988,
    Total: 0.6898147,
  },
  {
    ID: 'PR-29',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.133724,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.125,
    Equity: 0.05994,
    Total: 0.637831,
  },
  {
    ID: 'PR-30',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.126747,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.075,
    Equity: 0,
    Total: 0.648414,
  },
  {
    ID: 'PR-31',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.130077,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.1,
    Equity: 0.11988,
    Total: 0.727457,
  },
  {
    ID: 'PR-32',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.111107,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5343203,
  },
  {
    ID: 'PR-33',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.146926,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.689306,
  },
  {
    ID: 'PR-34',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.116875,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.05625,
    Equity: 0.11988,
    Total: 0.5746717,
  },
  {
    ID: 'PR-35',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0491804,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.5273937,
  },
  {
    ID: 'PR-36',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.0053125,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0,
    Total: 0.3544792,
  },
  {
    ID: 'PR-37',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.17,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.65113,
  },
  {
    ID: 'PR-38',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0988167,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.6336967,
  },
  {
    ID: 'PR-39',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0,
    'Land Use Alignment': 0.0983608,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.0375,
    Equity: 0,
    Total: 0.3808608,
  },
  {
    ID: 'PR-40',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.3690314,
  },
  {
    ID: 'PR-41',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.114754,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0375,
    Equity: 0,
    Total: 0.444754,
  },
  {
    ID: 'PR-42',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0427969,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.3901769,
  },
  {
    ID: 'PR-43',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.00955402,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.34276732,
  },
  {
    ID: 'PR-44',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0053125,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.3135258,
  },
  {
    ID: 'PR-45',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.114139,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.075,
    Equity: 0,
    Total: 0.434139,
  },
  {
    ID: 'PR-46',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0713423,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0375,
    Equity: 0,
    Total: 0.390509,
  },
  {
    ID: 'PR-47',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.152695,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.720492,
  },
  {
    ID: 'PR-48',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0698154,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.6580287,
  },
  {
    ID: 'PR-49',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.13206,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.05994,
    Total: 0.616167,
  },
  {
    ID: 'PR-50',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.109442,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.6034887,
  },
  {
    ID: 'PR-51',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.146926,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.689306,
  },
  {
    ID: 'PR-52',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0881917,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.7580717,
  },
  {
    ID: 'PR-53',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.127956,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.10625,
    Equity: 0,
    Total: 0.6008727,
  },
  {
    ID: 'PR-54',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.085,
    'Land Use Alignment': 0.0640469,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4039269,
  },
  {
    ID: 'PR-55',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0602614,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.5718081,
  },
  {
    ID: 'PR-56',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.109442,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.6209887,
  },
  {
    ID: 'PR-57',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.0425,
    'Land Use Alignment': 0.15103,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0,
    Total: 0.490197,
  },
  {
    ID: 'PR-58',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.103058,
    'Mode Choice': 0,
    'Regional Scalability': 0.05625,
    Equity: 0.11988,
    Total: 0.534188,
  },
  {
    ID: 'PR-59',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.1275,
    'Land Use Alignment': 0.0824233,
    'Mode Choice': 0,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.4160533,
  },
  {
    ID: 'PR-60',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0751279,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.4462579,
  },
  {
    ID: 'PR-61',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.085,
    'Land Use Alignment': 0.0771108,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4061575,
  },
  {
    ID: 'PR-62',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.085,
    'Land Use Alignment': 0.0766548,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.4557015,
  },
  {
    ID: 'PR-63',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.122188,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.6524847,
  },
  {
    ID: 'PR-64',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.13206,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.05994,
    Total: 0.616167,
  },
  {
    ID: 'PM-1',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.141158,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.6381213,
  },
  {
    ID: 'PM-2',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.0425,
    'Land Use Alignment': 0.0977458,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.5097091,
  },
  {
    ID: 'PM-5',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.1275,
    'Mode Choice': 0,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.45988,
  },
  {
    ID: 'PM-6',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0771108,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.5994908,
  },
  {
    ID: 'PM-7',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0881917,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.6809887,
  },
  {
    ID: 'PM-8',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.148909,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.751289,
  },
  {
    ID: 'PM-9',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.160446,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.10625,
    Equity: 0.11988,
    Total: 0.886576,
  },
  {
    ID: 'PM-10',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.154678,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.822891,
  },
  {
    ID: 'PM-11',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.085,
    'Land Use Alignment': 0.0808963,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.5099433,
  },
  {
    ID: 'PM-12',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.146926,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.1375,
    Equity: 0.11988,
    Total: 0.877639,
  },
  {
    ID: 'PM-13',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.109283,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.1,
    Equity: 0.11988,
    Total: 0.786663,
  },
  {
    ID: 'PM-14',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.011081,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.578878,
  },
  {
    ID: 'PM-15',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.0824233,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.08125,
    Equity: 0.11988,
    Total: 0.6185533,
  },
  {
    ID: 'PM-16',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.085,
    'Land Use Alignment': 0.124308,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.08125,
    Equity: 0.11988,
    Total: 0.5487713,
  },
  {
    ID: 'PM-17',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.103514,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.565894,
  },
  {
    ID: 'PM-18',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.154678,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.6187247,
  },
  {
    ID: 'PM-19',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4308961,
  },
  {
    ID: 'PM-20',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.164232,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.6611953,
  },
  {
    ID: 'PM-21',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.137372,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.778919,
  },
  {
    ID: 'PM-22',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.105041,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.744921,
  },
  {
    ID: 'PM-23',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.131604,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.5456507,
  },
  {
    ID: 'PM-24',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.085,
    'Land Use Alignment': 0.0771108,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.4403241,
  },
  {
    ID: 'PM-25',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.3425627,
  },
  {
    ID: 'PM-26',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0997287,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.6562757,
  },
  {
    ID: 'PM-27',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0881917,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.06875,
    Equity: 0,
    Total: 0.3111084,
  },
  {
    ID: 'PM-28',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.1275,
    'Land Use Alignment': 0.0881917,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.09375,
    Equity: 0.11988,
    Total: 0.4984884,
  },
  {
    ID: 'PM-29',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.137372,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.6739187,
  },
  {
    ID: 'PM-30',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.106409,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.6554557,
  },
  {
    ID: 'PM-31',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.1275,
    'Land Use Alignment': 0.0168494,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.3583961,
  },
  {
    ID: 'PM-32',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.137372,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.819752,
  },
  {
    ID: 'PM-33',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.0766548,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5157015,
  },
  {
    ID: 'PM-34',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.17,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.10625,
    Equity: 0.11988,
    Total: 0.869463,
  },
  {
    ID: 'PM-35',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0977458,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.1,
    Equity: 0.11988,
    Total: 0.7642928,
  },
  {
    ID: 'PM-37',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.0939602,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.08125,
    Equity: 0.11988,
    Total: 0.7417572,
  },
  {
    ID: 'PM-38',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.17,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.1125,
    Equity: 0.11988,
    Total: 0.7957133,
  },
  {
    ID: 'PM-39',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.156342,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.1,
    Equity: 0.11988,
    Total: 0.7695553,
  },
  {
    ID: 'PM-40',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.148909,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.6554557,
  },
  {
    ID: 'PM-41',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.103514,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.6588107,
  },
  {
    ID: 'PM-42',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.135845,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.1,
    Equity: 0.11988,
    Total: 0.7490583,
  },
  {
    ID: 'ET-1',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.104882,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.03125,
    Equity: 0.11988,
    Total: 0.591012,
  },
  {
    ID: 'ET-2',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0924333,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.6064803,
  },
  {
    ID: 'ET-3',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0813523,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.6145656,
  },
  {
    ID: 'ET-4',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.146926,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.689306,
  },
  {
    ID: 'ET-5',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0939602,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5863402,
  },
  {
    ID: 'ET-6',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.4590311,
  },
  {
    ID: 'ET-7',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0808963,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.5541096,
  },
  {
    ID: 'ET-8',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0602614,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.6093084,
  },
  {
    ID: 'ET-9',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0755838,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.5821305,
  },
  {
    ID: 'ET-10',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0238267,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.4078734,
  },
  {
    ID: 'ET-11',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0432529,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.4047999,
  },
  {
    ID: 'ET-12',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0432529,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.5872999,
  },
  {
    ID: 'ET-13',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0432529,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.05,
    Equity: 0.11988,
    Total: 0.4472999,
  },
  {
    ID: 'ET-14',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.0432529,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.5447999,
  },
  {
    ID: 'ET-15',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.085,
    'Land Use Alignment': 0.146926,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.03125,
    Equity: 0.11988,
    Total: 0.659723,
  },
  {
    ID: 'ET-16',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0866648,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.4357115,
  },
  {
    ID: 'ET-17',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.085,
    'Land Use Alignment': 0.0866648,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.5207115,
  },
  {
    ID: 'ET-18',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.152695,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.764242,
  },
  {
    ID: 'ET-19',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.3840467,
  },
  {
    ID: 'ET-20',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4565467,
  },
  {
    ID: 'ET-21',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0771108,
    'Mode Choice': 0,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4644908,
  },
  {
    ID: 'ET-22',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.1275,
    'Land Use Alignment': 0.0461477,
    'Mode Choice': 0,
    'Regional Scalability': 0.025,
    Equity: 0.11988,
    Total: 0.4460277,
  },
  {
    ID: 'ET-23',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.4248644,
  },
  {
    ID: 'ET-24',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.135845,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.04375,
    Equity: 0.11988,
    Total: 0.634475,
  },
  {
    ID: 'ET-25',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0698154,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.6201124,
  },
  {
    ID: 'ET-26',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.17,
    'Land Use Alignment': 0.158463,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.7404263,
  },
  {
    ID: 'ET-27',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.0977458,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.7109588,
  },
  {
    ID: 'ET-28',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0136577,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0.0125,
    Equity: 0.11988,
    Total: 0.411871,
  },
  {
    ID: 'ET-29',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.1275,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.06875,
    Equity: 0.11988,
    Total: 0.6402967,
  },
  {
    ID: 'ET-30',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0,
    'Land Use Alignment': 0.00743326,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0,
    Equity: 0,
    Total: 0.10326656,
  },
  {
    ID: 'ET-31',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.164232,
    'Mode Choice': 0.133333,
    'Regional Scalability': 0.1375,
    Equity: 0.11988,
    Total: 0.852445,
  },
  {
    ID: 'ET-32',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0866648,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.5632115,
  },
  {
    ID: 'ET-33',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0787756,
    'Mode Choice': 0.16,
    'Regional Scalability': 0.0375,
    Equity: 0.11988,
    Total: 0.5236556,
  },
  {
    ID: 'ET-34',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0698154,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.5713624,
  },
  {
    ID: 'ET-35',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.17,
    Safety: 0.1275,
    'Land Use Alignment': 0.135845,
    'Mode Choice': 0.0266667,
    'Regional Scalability': 0.125,
    Equity: 0.11988,
    Total: 0.7048917,
  },
  {
    ID: 'ET-36',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.0425,
    'Land Use Alignment': 0.0698154,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.125,
    Equity: 0.11988,
    Total: 0.5221954,
  },
  {
    ID: 'ET-37',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.075,
    Equity: 0.11988,
    Total: 0.3973644,
  },
  {
    ID: 'ET-38',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0432529,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.4422999,
  },
  {
    ID: 'ET-39',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0813523,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.4537323,
  },
  {
    ID: 'ET-40',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.085,
    'Land Use Alignment': 0.0374844,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.0625,
    Equity: 0.11988,
    Total: 0.4965314,
  },
  {
    ID: 'ET-41',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.0977458,
    'Mode Choice': 0.08,
    'Regional Scalability': 0.0875,
    Equity: 0.11988,
    Total: 0.4701258,
  },
  {
    ID: 'ET-42',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.0425,
    Safety: 0.0425,
    'Land Use Alignment': 0.135845,
    'Mode Choice': 0.106667,
    'Regional Scalability': 0.1125,
    Equity: 0.11988,
    Total: 0.559892,
  },
  {
    ID: 'ET-43',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.17,
    'Land Use Alignment': 0.0432529,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.4714662,
  },
  {
    ID: 'ET-44',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.1275,
    Safety: 0.17,
    'Land Use Alignment': 0.103514,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.5742273,
  },
  {
    ID: 'ET-45',
    Category: 'Public Engagement Feedback',
    'Travel Performance': 0.085,
    Safety: 0.1275,
    'Land Use Alignment': 0.0977458,
    'Mode Choice': 0.0533333,
    'Regional Scalability': 0,
    Equity: 0.11988,
    Total: 0.4834591,
  },
]
