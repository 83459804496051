import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Filter } from './filter.model';

export interface FiltersState extends EntityState<Filter> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filters' })
export class FiltersStore extends EntityStore<FiltersState> {

  constructor() {
    super();
  }

}
