import { Injectable } from '@angular/core'
import {
  EntityState,
  EntityStore,
  MultiActiveState,
  StoreConfig,
} from '@datorama/akita'
import { Recommendation } from './recommendation.model'

export interface RecommendationsState
  extends EntityState<Recommendation>,
    MultiActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'recommendations' })
export class RecommendationsStore extends EntityStore<RecommendationsState> {
  constructor() {
    super({ active: [] })
  }
}
