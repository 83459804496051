import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ID } from '@datorama/akita'
import { Apollo } from 'apollo-angular'
import { gql } from 'graphql-tag'
import { map, tap } from 'rxjs/operators'
import { Recommendation } from './recommendation.model'
import { RecommendationsStore } from './recommendations.store'
import { Query } from '../../types'

const GET_RECOMMENDATIONS = gql`
  query recommendationQuery($id: String) {
    recommendationsIndirectList(id: $id) {
      id
      description
      stratSolu
      uct
      keyArea
      keyAreaSort
      impTimeFrame
      timeSort
      isIndirectkey
      isPostKey
      isPreKey
      modeCar
      modeBus
      modeMicro
      modeFreight
      modePed
      modeBike
      modeRideShare
      modeTransit
      modeOther
      impactFactor
      agencies
      jurisdictions
      el
    }
    recommendationlistLong {
      id
      description
      stratSolu
      uct
      keyArea
      keyAreaSort
      impTimeFrame
      timeSort
      isIndirectkey
      isPostKey
      isPreKey
      modeCar
      modeBus
      modeMicro
      modeFreight
      modePed
      modeBike
      modeRideShare
      modeTransit
      modeOther
      impactFactor
      agencies
      jurisdictions
      el
    }
    recommendationlistMedium {
      id
      description
      stratSolu
      uct
      keyArea
      keyAreaSort
      impTimeFrame
      timeSort
      isIndirectkey
      isPostKey
      isPreKey
      modeCar
      modeBus
      modeMicro
      modeFreight
      modePed
      modeBike
      modeRideShare
      modeTransit
      modeOther
      impactFactor
      agencies
      jurisdictions
      el
    }
    recommendationlistImmediate {
      id
      description
      stratSolu
      uct
      keyArea
      keyAreaSort
      impTimeFrame
      timeSort
      isIndirectkey
      isPostKey
      isPreKey
      modeCar
      modeBus
      modeMicro
      modeFreight
      modePed
      modeBike
      modeRideShare
      modeTransit
      modeOther
      impactFactor
      agencies
      jurisdictions
      el
    }
    recommendationlistShort {
      id
      description
      stratSolu
      uct
      keyArea
      keyAreaSort
      impTimeFrame
      timeSort
      isIndirectkey
      isPostKey
      isPreKey
      modeCar
      modeBus
      modeMicro
      modeFreight
      modePed
      modeBike
      modeRideShare
      modeTransit
      modeOther
      impactFactor
      agencies
      jurisdictions
      el
    }
    recommendationlist {
      id
      description
      stratSolu
      uct
      keyArea
      keyAreaSort
      impTimeFrame
      timeSort
      isIndirectkey
      isPostKey
      isPreKey
      modeCar
      modeBus
      modeMicro
      modeFreight
      modePed
      modeBike
      modeRideShare
      modeTransit
      modeOther
      impactFactor
      agencies
      jurisdictions
      el
      loeSmall
      levelOfEffort
      loeLarge
      mpoRole
      loeMPPOTeam
      costEstimate
    }
    keyAreas {
      name
    }
    umbrellaCategories {
      name
    }
  }
`

@Injectable({ providedIn: 'root' })
export class RecommendationsService {
  constructor(
    private recommendationsStore: RecommendationsStore,
    private http: HttpClient,
    private apollo: Apollo
  ) {}

  addAll(recommendations: Recommendation[]) {
    this.recommendationsStore.set(recommendations)
  }

  add(recommendation: Recommendation) {
    this.recommendationsStore.add(recommendation)
  }

  update(id, recommendation: Partial<Recommendation>) {
    this.recommendationsStore.update(id, recommendation)
  }
  setActive(id: ID) {
    this.recommendationsStore.setActive([id])
  }

  remove(id: ID) {
    this.recommendationsStore.remove(id)
  }

  getRecommendationById(id: string) {
    return this.apollo
      .query<Query>({ query: GET_RECOMMENDATIONS, variables: { id: '' } })
      .pipe(
        map((r) => r.data.recommendations.filter((f) => f.id === id)),
        tap((re) => this.recommendationsStore.setActive([re[0].id]))
      )
  }

  getRecommendations() {
    return this.apollo
      .query<Query>({ query: GET_RECOMMENDATIONS, variables: { id: '' } })
      .pipe(
        map((r) => r.data.recommendationlist),
        tap((re) => this.recommendationsStore.set(re))
      )
  }
}

// be curious
// ask questions
// stay engaged
