import { Injectable } from '@angular/core'
import { QueryConfig, QueryEntity } from '@datorama/akita'
import { Recommendation } from './recommendation.model'
import {
  RecommendationsStore,
  RecommendationsState,
} from './recommendations.store'

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'timeSort' })
export class RecommendationsQuery extends QueryEntity<RecommendationsState> {
  constructor(protected store: RecommendationsStore) {
    super(store)
  }

  getRecommendations(term: string, sortBy: keyof Recommendation) {
    return this.selectAll({
      sortBy,
      filterBy: (entity) => entity.stratSolu.toLowerCase().includes(term),
    })
  }
  getRecommendationsByTime(time: string) {
    return this.selectAll({
      filterBy: (entity) => entity.impTimeFrame.toLowerCase().includes(time),
    })
  }
  getRecommendationParent(id: string) {
    if (this.hasParent(id)) {
      const idArray = id.split('-')
      let parentId = `${idArray[0]}-${idArray[1]}`
      return this.getEntity(parentId)
    }
    return null
  }

  private hasParent(id: string): boolean {
    return (id.match(/-/g) || []).length === 2
  }
}
