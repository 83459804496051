import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Recommendation } from 'src/app/types'
import { WorkplanService } from 'src/app/workplan/state/workplan.service'
import { RecommendationsQuery } from '../state/recommendations.query'
import { RecommendationsService } from '../state/recommendations.service'

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  recommendation: Recommendation

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      recommendation: Recommendation
    },
    private recommendationsQuery: RecommendationsQuery,
    private recommendationService: RecommendationsService,
    private workplanService: WorkplanService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.recommendation = data.recommendation
  }

  ngOnInit(): void {
    this.recommendationService.setActive(this.recommendation.id)
    // console.log(this.recommendation)
  }

  addToWorkplan() {
    this.workplanService.addRecommendationToWorkplan(this.recommendation.id)
    const snack = this.snackBar.open(
      `${this.recommendation.stratSolu} has been added to the Workplan`,
      '',
      {
        duration: 4000,
      }
    )
  }

  goToDetails() {
    this.router.navigateByUrl(`details/${this.recommendation.id}`)
  }
}
