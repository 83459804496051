import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { WorkplanService } from 'src/app/workplan/state/workplan.service'
import { FiltersService } from '../state/filters/filters.service'
import { Recommendation } from '../state/recommendation.model'
import { RecommendationsQuery } from '../state/recommendations.query'
import { RecommendationsService } from '../state/recommendations.service'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

@UntilDestroy()
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, AfterContentChecked {
  recommendation$: Observable<Recommendation[]>
  @ViewChild('printArea') printArea: ElementRef
  parentId: string
  //recommendation$ = this.recommendationQuery.selectEntity(this.id)
  @Input() recommendation: Recommendation
  parentRecommendation: Recommendation
  preRecommendations: Recommendation[]
  postRecommendations: Recommendation[]
  indRecommendations: Recommendation[]
  buttonType: string
  @Input() recId: string
  @Input() printMe: boolean
  constructor(
    private ref: ChangeDetectorRef,
    private recommendationQuery: RecommendationsQuery,
    private recommendationService: RecommendationsService,
    private filterService: FiltersService,
    private activeRoute: ActivatedRoute,
    private workplanService: WorkplanService
  ) {
    this.printMe = false
    this.filterService.get().subscribe()
    this.activeRoute.paramMap
      .pipe(
        take(1),
        map((params) => params.get('id')),
        filter((id) => !this.recommendationQuery.hasEntity(id)),
        untilDestroyed(this),
        switchMap((id) => this.filterService.get())
      )
      .subscribe((r) => {
        if (r) {
          this.recommendation = r.filter((f) => f.id === this.id)[0]

          if (this.hasParent(this.id)) {
            this.parentRecommendation =
              this.recommendationQuery.getRecommendationParent(
                this.recommendation.id
              )
            this.createRelationshipLists(this.parentRecommendation)
          } else {
            this.recommendationService.setActive(this.id)
            this.createRelationshipLists(this.recommendation)
          }
        }
      })
  }

  ngAfterContentChecked(): void {
    // detect changes after all the content is loaded
    //this.ref.detectChanges()
  }

  ngOnInit(): void {
    //window.print()

    if (
      !this.recommendation &&
      this.recommendationQuery.getActive()[0] !== undefined
    ) {
      this.recommendation = this.recommendationQuery.getActive()[0]
    }
    if (this.recommendation && this.hasParent(this.recommendation.id)) {
      this.parentRecommendation =
        this.recommendationQuery.getRecommendationParent(this.recommendation.id)
      this.createRelationshipLists(this.parentRecommendation)
    } else {
      //this.recommendationService.setActive(this.id)
      this.createRelationshipLists(this.recommendation)
    }
  }

  createRelationshipLists(rec: Recommendation) {
    // populate recommendation relationships
    //console.log(rec)
    //console.log('creating relationships')
    this.preRecommendations = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        rec.isPreKey.indexOf(entity.id) > 0 && rec.id !== entity.id,
    })
    this.postRecommendations = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        rec.isPostKey.indexOf(entity.id) > 0 && rec.id !== entity.id,
    })
    this.indRecommendations = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        rec.isIndirectkey.indexOf(entity.id) > 0 && rec.id !== entity.id,
    })
  }

  /** Get Recommendation Id */
  get id() {
    const _id = this.activeRoute.snapshot.params.id
    this.recommendationService.setActive(_id)
    this.buttonType = 'close'
    if (_id) {
      this.buttonType = 'back'

      return this.activeRoute.snapshot.params.id
    }
    return this.recId
  }

  print() {
    this.printMe = true

    setTimeout(
      () =>
        this.makePDF(
          this.recommendation.id + '-' + this.recommendation.stratSolu
        ),
      50
    )
  }
  public makePDF(filename: string): void {
    let DATA = document.getElementById('printArea')

    let PDF = new jsPDF('l', 'mm', 'a4', true)
    html2canvas(DATA, { height: 1600, useCORS: true })
      .then((canvas) => {
        canvas.style.height = '1200px'

        canvas.id = 'printCanvas'
        const FILEURI = canvas.toDataURL('image/png')

        let fileWidth = 285
        let fileHeight = (canvas.height * fileWidth) / canvas.width

        document.body.appendChild(canvas)

        let position = 5
        //PDF.addImage(FILEURI, 'PNG', 5, position, 280, 220)
        PDF.addImage(FILEURI, 'PNG', 5, position, fileWidth, fileHeight)
        PDF.save(filename)
      })
      .then((result) => {
        let element = document.getElementById('printCanvas')
        this.printMe = false
        document.body.removeChild(element)
      })
  }

  addToWorkplan(): void {
    this.workplanService.addRecommendationToWorkplan(this.id)
  }

  hasParent(id: string): boolean {
    return (id.match(/-/g) || []).length === 2
  }

  addSpace(phrase: string) {
    return phrase
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([a-z])(\/)([A-Z])/g, '$1 $2 $3')
      .replace(/([A-Z])(\/)([A-Y])/g, '$1 $2 $3')
      .replace(/([Stud])([S])/g, '$1 $2')
  }
}
