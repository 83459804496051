import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { Observable, of } from 'rxjs'
import { filter, map, tap, first, catchError, take } from 'rxjs/operators'
import { Query, Recommendation } from '../types'
import {
  getQueryParams,
  getTotal,
  getErrorMessage,
} from '../utils/query-helpers'
import { WorkplanService } from '../workplan/state/workplan.service'
import { DetailsComponent } from './details/details.component'
import { PopupComponent } from './popup/popup.component'
import { RecommendationCardComponent } from './recommendation-card/recommendation-card.component'
import { FiltersService } from './state/filters/filters.service'
import { RecommendationsQuery } from './state/recommendations.query'
import { RecommendationsService } from './state/recommendations.service'

const GET_RECOMMENDATIONS = gql`
  query recommendationQuery($id: String) {
    recommendationsIndirectList(id: $id) {
      id
      stratSolu
      uct
      keyArea
      description
      modeCar
      modeBus
      impTimeFrame
      isIndirectkey
      isPreKey
      isPostKey
    }
    recommendationlistLong {
      id
      stratSolu
      uct
      keyArea
      description
      modeCar
      modeBus
      impTimeFrame
      isIndirectkey
      isPreKey
      isPostKey
    }
    recommendationlistMedium {
      id
      stratSolu
      uct
      keyArea
      description
      modeCar
      modeBus
      impTimeFrame
      isIndirectkey
      isPreKey
      isPostKey
    }
    recommendationlistImmediate {
      id
      stratSolu
      uct
      keyArea
      description
      modeCar
      modeBus
      impTimeFrame
      isIndirectkey
      isPreKey
      isPostKey
    }
    recommendationlistShort {
      id
      stratSolu
      uct
      keyArea
      description
      modeCar
      modeBus
      impTimeFrame
      isIndirectkey
      isPreKey
      isPostKey
    }
    recommendations {
      id
      stratSolu
      uct
      keyArea
      description
      modeCar
      modeBus
      impTimeFrame
      isIndirectkey
      isPreKey
      isPostKey
    }
    keyAreas {
      name
    }
    umbrellaCategories {
      name
    }
    indirectKeys {
      id
      keys
    }
  }
`

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecommendationsComponent implements OnInit {
  recommendationList: Recommendation[] = []
  recommendationListShort: Recommendation[]
  recommendationListIndirect: Recommendation[]
  recommendationListImmediate: Recommendation[]
  recommendationListMedium: Recommendation[]
  recommendationListLong: Recommendation[]
  recommendationListFirst: Recommendation[]
  error = ''
  loading = false
  pageSize: number
  recommendations: Observable<Recommendation[]>
  total = 0
  immediateTerm = 'Immediate (2021-2023)'
  shortTerm = 'Short-Term (2023-2026)'
  medTerm = 'Medium-Term (2026-2035)'
  longTerm = 'Long-Term (2035-2050+)'
  search = 'PM-2'
  region = 'Region'
  selectedName = ''
  regions: any = [
    'City of Rock Hill',
    'Town of Fort Mill',
    'Town of Pineville',
    'City of Charlotte',
    'City of Belmont',
    'City of Gastonia',
    'City of Kings Mountain',
    'City of Mount Holly',
    'Town of Harrisburg',
    'City of Concord',
    'City of Kannapolis',
    'City of Salisbury',
    'Mount Mourne Area',
    'Town of Mooresville',
    'York County',
    'Mecklenburg County',
    'Iredell County',
    'Gaston County',
    'Cabarrus County',
    'Rowan County',
  ]

  modes: any = [
    { name: 'Bike', value: 'bike' },
    { name: 'Car', value: 'car' },
    { name: 'Bus', value: 'bus' },
    { name: 'Train', value: 'train' },
    { name: 'Freight', value: 'freight' },
    { name: 'Rideshare', value: 'rideshare' },
    { name: 'Microtransit', value: 'micro' },
    { name: 'Walking', value: 'walking' },
  ]

  id: string = 'PM-12'
  activeCard = false
  preList: Recommendation[]
  postList: Recommendation[]
  indList: Recommendation[]

  @ViewChild('recommendationContent', { static: true })
  recommendationContent: ElementRef
  private recoCards: QueryList<RecommendationCardComponent>
  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    private workplanService: WorkplanService,
    private recommendationService: RecommendationsService,
    private recommendationQuery: RecommendationsQuery,
    private filtersService: FiltersService
  ) {
    this.recommendations = this.filtersService.selectAll()
  }

  ngOnInit(): void {
    this.error = ''
    this.loading = true

    this.recommendations.subscribe((r) => {
      if (r.length) {
        let data = r

        this.recommendationList = data
        // this.recommendationList = [];
      }
    })
  }
  openDetails(id: string): void {
    this.recommendationService.setActive(id)

    this.dialog
      .open(PopupComponent, {
        // position: { top: '15' },
        width: '50%',
        // height: '100%',
        data: {
          recommendation: this.recommendationQuery.getActive()[0],
        },
      })
      .afterClosed()
      .subscribe((s) => {
        if (s) {
        }
      })
  }

  addToWorkplan(id: string): void {

    this.workplanService.addRecommendationToWorkplan(id)
  }

  addAllToWorkPlan() {
    let wp = [...this.preList, ...this.postList, ...this.indList]
    
    wp.forEach((v) => {
      this.workplanService.addRecommendationToWorkplan(v.id)
    })
  }
  toggleEvent(event) {
    const card = this.recoCards.filter((f) => f.recommendation.id === event)[0]
    card.toggleActive(event)
  }
  seeDetails(id: string): void {
    if (this.hasParent(id)) {
      id = id.slice(0, 5).trim()
    }
    this.recommendationService.setActive(id)

    this.activeCard = !this.activeCard
    this.search = id
    this.selectedName = this.getName(id)
    this.recommendationListLong = []

    this.recommendationListFirst = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        this.recommendationQuery.getActive()[0].isPreKey.indexOf(entity.id) >
          0 && this.recommendationQuery.getActive()[0].id !== entity.id,
    })
    let indirecrt = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        this.recommendationQuery
          .getActive()[0]
          .isIndirectkey.indexOf(entity.id) > 0 &&
        this.recommendationQuery.getActive()[0].id !== entity.id &&
        entity.isIndirectkey.length > 0,
    })

    if (indirecrt) {
      this.recommendationListImmediate = indirecrt.filter(
        (f) => f.impTimeFrame === this.immediateTerm
      )
      this.recommendationListShort = indirecrt.filter(
        (f: Recommendation) => this.shortTerm === f.impTimeFrame
      )
      this.recommendationListLong = indirecrt.filter(
        (f: Recommendation) => this.longTerm === f.impTimeFrame
      )

      this.recommendationListMedium = indirecrt.filter(
        (f: Recommendation) =>
          this.medTerm === f.impTimeFrame && f.isIndirectkey.length > 0
      )
    }
  }

  createRelationshipLists(rec: Recommendation) {
    // populate recommendation relationships
    //console.log(rec)
    this.preList = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        rec.isPreKey.indexOf(entity.id) > 0 && rec.id !== entity.id,
    })
    this.postList = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        rec.isPostKey.indexOf(entity.id) > 0 && rec.id !== entity.id,
    })
    this.indList = this.recommendationQuery.getAll({
      filterBy: (entity) =>
        rec.isIndirectkey.indexOf(entity.id) > 0 && rec.id !== entity.id,
    })
  }
  getTimeFilterList(timeframe: string) {
    let list = this.recommendationList.filter(
      (f) => f.impTimeFrame === timeframe
    )
    return list
  }
  getName(id: string): string {
    let _id = ''

    if (this.hasParent(id)) {
      _id = id.slice(0, 5).trim()
    } else {
      _id = id
    }
    const name =
      _id +
      ' - ' +
      this.recommendationList.filter((f) => f.id === _id)[0].stratSolu
    this.createRelationshipLists(
      this.recommendationList.filter((f) => f.id === _id)[0]
    )
    return name
  }

  hasParent(id: string) {
    return (id.match(/-/g) || []).length === 2
  }
}
