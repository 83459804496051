import { Injectable } from '@angular/core'
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita'
import { Workplan } from './workplan.model'

export interface WorkplanState extends EntityState<Workplan> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workplan' })
export class WorkplanStore extends EntityStore<WorkplanState> {
  constructor() {
    super()
  }

  notify(notify: boolean) {
    this.update((state) => ({ ...state, notify }))
  }
}
