import { Injectable } from '@angular/core'
import {
  Actions,
  createEffect,
  Effect,
  ofType,
} from '@datorama/akita-ng-effects'
import { WorkplanActions } from './workplan.actions'
import { map, switchMap, tap } from 'rxjs/operators'
import { WorkplanStore } from './workplan.store'
import { timer } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class WorkplanEffects {
  constructor(
    private actions$: Actions,
    private workplanStore: WorkplanStore
  ) {}

  removeItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkplanActions.removeWorkplan),
        tap(({ recommendationId }) =>
          this.workplanStore.remove(recommendationId)
        ),
        map((_) => {
          return WorkplanActions.removeWorkplanSuccess()
        })
      ),
    { dispatch: true }
  )

  @Effect()
  removeItemSuccess = this.actions$.pipe(
    ofType(WorkplanActions.removeWorkplanSuccess),
    tap((_) => this.workplanStore.notify(true)),
    switchMap((state) =>
      timer(3000).pipe(tap((_) => this.workplanStore.notify(false)))
    )
  )
}
