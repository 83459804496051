import { createAction, props } from '@datorama/akita-ng-effects'
import { ID } from '@datorama/akita'

export namespace WorkplanActions {
  export const removeWorkplan = createAction(
    'Remove Workplan',
    props<{ recommendationId: ID }>()
  )
  export const removeWorkplanSuccess = createAction('Remove Workplan Success')

  export const test = createAction('Testing Action')
}
