import { Component } from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { WorkplanQuery } from './workplan/state/workplan.query'
import { FiltersService } from './recommendations/state/filters/filters.service'
import { Filter } from './recommendations/state/filters/filter.model'
import { AkitaFilter } from 'akita-filters-plugin'
import { FiltersState } from './recommendations/state/filters/filters.store'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  modes: any = [
    { name: 'Bike', value: 'bike' },
    { name: 'Car', value: 'car' },
    { name: 'Bus', value: 'bus' },
    { name: 'Train', value: 'train' },
    { name: 'Freight', value: 'freight' },
    { name: 'Rideshare', value: 'rideshare' },
    { name: 'Microtransit', value: 'micro' },
    { name: 'Walking', value: 'walking' },
  ]
  wpCount$: Observable<number>
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    )

  constructor(
    private breakpointObserver: BreakpointObserver,
    private workplanQuery: WorkplanQuery,
    private filterService: FiltersService
  ) {
    this.wpCount$ = this.workplanQuery.selectCount()
    this.filterService.get().subscribe()
  }
}
