import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ID } from '@datorama/akita'
import { tap } from 'rxjs/operators'
import { RecommendationsState } from '../recommendations.store'
import { Filter } from './filter.model'
import { FiltersState, FiltersStore } from './filters.store'
import { AkitaFiltersPlugin } from 'akita-filters-plugin'
import { AkitaFilter } from 'akita-filters-plugin'
import { FiltersQuery } from './filters.query'
import { RecommendationsService } from '../recommendations.service'
import { empty, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FiltersService {
  filtersRecommendation: AkitaFiltersPlugin<FiltersState>

  constructor(
    private filtersStore: FiltersStore,
    private filtersQuery: FiltersQuery,
    private recommendationService: RecommendationsService,
    private http: HttpClient
  ) {
    this.filtersRecommendation = new AkitaFiltersPlugin<FiltersState>(
      this.filtersQuery
    )
  }

  get() {
    const requset = this.recommendationService.getRecommendations().pipe(
      tap((response) => {
        this.filtersStore.set(response)
      })
    )

    return this.filtersQuery.getHasCache() === false ? requset : empty()
  }

  getRec() {}

  setFilter(filter: AkitaFilter<FiltersState>) {
    this.filtersRecommendation.setFilter(filter)
  } 

  removeFilter(id: string) {
    this.filtersRecommendation.removeFilter(id)
  }
  removeAllFilters() {
    this.filtersRecommendation.clearFilters()
  }

  getFilterValue(id: string): any | null {
    return this.filtersRecommendation.getFilterValue(id)
  }

  selectFilters(): Observable<AkitaFilter<FiltersState>[]> {
    return this.filtersRecommendation.selectFilters()
  }

  selectAll(): Observable<Filter[]> {
    return this.filtersRecommendation.selectAllByFilters() as Observable<
      Filter[]
    >
  }

  update(id, filter: Partial<Filter>) {
    this.filtersStore.update(id, filter)
  }

  remove(id: ID) {
    this.filtersStore.remove(id)
  }
}
