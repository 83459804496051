import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith } from 'rxjs/operators'
import { Filter } from '../state/filters/filter.model'
import { FiltersState } from '../state/filters/filters.store'
import { AkitaFilter, searchFilter } from 'akita-filters-plugin'
import { FiltersService } from '../state/filters/filters.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatFormFieldControl } from '@angular/material/form-field'

@UntilDestroy()
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  filtersForm = new FormGroup({
    search: new FormControl(),
    modeControl: new FormControl(),
    regionControl: new FormControl(),
    keyAreaControl: new FormControl(),
  })

  // mode: string
  // region: string
  // keyArea: string

  public filters$: Observable<AkitaFilter<Filter, FiltersState>[]>
  public nbRefresh: number = 0

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.setIntialFilters()
    this.filtersForm.controls.search.valueChanges
      .pipe(startWith(''), untilDestroyed(this))
      .subscribe((search: string) => {
        if (search) {
          console.log(search)
          this.filtersService.setFilter({
            id: 'search',
            value: search,
            name: `"${search}"`,
            predicate: (entity) => searchFilter(search, entity),
          })
        } else {
          this.filtersService.removeFilter('search')
        }
      })

    this.filtersForm.controls.keyAreaControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((keyArea) => {
        this.filtersService.setFilter({
          id: 'keyArea',
          value: keyArea,
          predicate: (entity) => entity.keyArea === keyArea,
        })
      })

    this.filtersForm.controls.modeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((mode) => {
        this.filtersService.setFilter({
          id: 'mode',
          value: mode,
          predicate: (entity) =>
            entity.modeBike === mode ||
            entity.modeBus === mode ||
            entity.modeCar === mode ||
            entity.modeFreight === mode ||
            entity.modeMicro === mode ||
            entity.modeOther === mode ||
            entity.modePed === mode ||
            entity.modeRideShare === mode ||
            entity.modeTransit === mode,
        })
      })
    this.filtersForm.controls.regionControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((region) => {
        this.filtersService.setFilter({
          id: 'region',
          value: region,
          predicate: (entity) =>
            entity.jurisdictions.includes(region) ||
            entity.jurisdictions?.length === 0,
        })
      })

    this.filters$ = this.filtersService.selectFilters()
  }

  removeFilter(id: any) {
    console.log('remove', id)
    this.filtersService.removeFilter(id)
    this.setIntialFilters()
    //this.setIntialFilters()
  }
  remove() {
    this.filtersService.removeAllFilters()
    this.setIntialFilters()
  }
  refresh() {
    this.nbRefresh = this.filtersService.filtersRecommendation.refresh()
  }

  private setIntialFilters() {
    this.filtersForm.setValue(
      {
        search: this.filtersService.getFilterValue('search'),
        modeControl: this.filtersService.getFilterValue('mode'),
        regionControl: this.filtersService.getFilterValue('jurisdictions'),
        keyAreaControl: this.filtersService.getFilterValue('keyArea'),
      },
      { emitEvent: false }
    )
  }
}
function startsWith(
  arg0: string
): import('rxjs').OperatorFunction<any, unknown> {
  throw new Error('Function not implemented.')
}
