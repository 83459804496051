export interface Geojson {
  type: string
  features?: Features[] | null
}
export interface Features {
  type: string
  //id: number
  geometry?: Geometry
  properties: Properties
}
export interface Geometry {
  type?: string
  coordinates?: (((number | number[] | null)[] | null)[] | null)[] | null
}
export interface Properties {
  OBJECTID?: number
  ShapeID?: string
  
  

  Shape_Length: number
  Shape_Area: number
  SolutionID?: string
  
}

export const geo = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "id": 1,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.675797368999952, 35.627370375000055],
            [-80.675172884999938, 35.627876080000078],
            [-80.674900364999985, 35.628097084000046],
            [-80.674882517999947, 35.628111640000043],
            [-80.674647303999961, 35.628304569000079],
            [-80.674416755999971, 35.62849232700006],
            [-80.674164797999936, 35.628696008000077],
            [-80.673898791999989, 35.628909364000037],
            [-80.67387741999994, 35.628926622000051],
            [-80.673519314999965, 35.629217761000064],
            [-80.672939420999967, 35.62968920600008],
            [-80.672802369999943, 35.629799651000042],
            [-80.672715559999972, 35.629868686000066],
            [-80.672634369999969, 35.62993252900003],
            [-80.672281937999969, 35.63020143500006],
            [-80.672197790999974, 35.630258200000071],
            [-80.672140612999954, 35.630292422000025],
            [-80.672068002999936, 35.63033807100004],
            [-80.672011834999978, 35.63037399500007],
            [-80.671977594999987, 35.630396259000065],
            [-80.671961790999944, 35.630405821000068],
            [-80.671941738999976, 35.630417698000031],
            [-80.671921525999949, 35.630429415000037],
            [-80.671901232999971, 35.630440927000052],
            [-80.671882174999951, 35.630451503000074],
            [-80.671728817999963, 35.630534622000027],
            [-80.671699651999973, 35.630549955000049],
            [-80.671665345999941, 35.630567567000071],
            [-80.671623656999941, 35.630588432000025],
            [-80.671589928999936, 35.630604883000046],
            [-80.671548798999936, 35.630624432000047],
            [-80.671516211999972, 35.630639495000025],
            [-80.671369069999969, 35.630704994000041],
            [-80.671197390999964, 35.630780740000034],
            [-80.671025559999975, 35.630855877000045],
            [-80.67085285099995, 35.630930723000063],
            [-80.670688738999957, 35.631001223000055],
            [-80.67051715599996, 35.631074297000055],
            [-80.670493642999986, 35.631084383000029],
            [-80.669685386999959, 35.631433637000043],
            [-80.668896133999965, 35.631773571000053],
            [-80.668537679999986, 35.631927595000036],
            [-80.668119956999988, 35.632105882000076],
            [-80.667701459999989, 35.632285200000069],
            [-80.666926869999941, 35.632618929000046],
            [-80.666125650999959, 35.63296153400006],
            [-80.664135595999937, 35.633820891000028],
            [-80.662978472999953, 35.634317562000035],
            [-80.662652761999937, 35.634457690000033],
            [-80.661917625999934, 35.634774998000069],
            [-80.661064826999961, 35.635140793000062],
            [-80.660487165999939, 35.635389762000045],
            [-80.660042014999988, 35.635580528000048],
            [-80.659582355999987, 35.635776511000074],
            [-80.659565318999967, 35.635783813000046],
            [-80.659447881999938, 35.635834414000044],
            [-80.659370415999945, 35.635867138000037],
            [-80.659301070999959, 35.635895939000079],
            [-80.659198832999948, 35.635937583000043],
            [-80.659107229999961, 35.635974087000079],
            [-80.659006131999945, 35.63601348800006],
            [-80.658912918999988, 35.636049006000064],
            [-80.658818983999936, 35.63608401700003],
            [-80.65872397399994, 35.636118642000042],
            [-80.658635028999981, 35.63615035600003],
            [-80.658495660999961, 35.636199097000031],
            [-80.658386278999956, 35.636236764000046],
            [-80.658289553999964, 35.636269353000046],
            [-80.658191593999959, 35.63630166400003],
            [-80.658092315999966, 35.636333701000069],
            [-80.657984244999966, 35.636367778000078],
            [-80.657884741999965, 35.636398420000035],
            [-80.657784088999961, 35.636428708000039],
            [-80.657710408999947, 35.636450361000072],
            [-80.657558381999934, 35.636492184000076],
            [-80.65739948199996, 35.636535896000055],
            [-80.657340391999981, 35.636552530000074],
            [-80.657148555999981, 35.636607768000033],
            [-80.656951635999974, 35.636663870000064],
            [-80.654109053999946, 35.637467825000044],
            [-80.653548996999973, 35.637627229000032],
            [-80.652990426999963, 35.637787106000076],
            [-80.652645047999954, 35.637886415000025],
            [-80.652613001999953, 35.637895742000069],
            [-80.652510435999943, 35.637927128000058],
            [-80.652229607999971, 35.638016303000029],
            [-80.652204228999949, 35.638024435000034],
            [-80.651933415999963, 35.638111994000042],
            [-80.651913169999943, 35.638118587000065],
            [-80.651737686999979, 35.638176152000028],
            [-80.651554768999972, 35.638236792000043],
            [-80.651372120999952, 35.638297994000027],
            [-80.651197427999989, 35.638357141000029],
            [-80.651178974999937, 35.638363429000037],
            [-80.651123422999945, 35.638382482000054],
            [-80.65094757199995, 35.638442789000067],
            [-80.650926695999942, 35.638450000000034],
            [-80.65071713399999, 35.63852289600004],
            [-80.650585695999951, 35.638567857000055],
            [-80.650336624999966, 35.638653058000045],
            [-80.65014197499994, 35.638719998000056],
            [-80.649938459999987, 35.63878999800005],
            [-80.649247535999962, 35.639029407000066],
            [-80.648353077999957, 35.639332168000067],
            [-80.646882151999989, 35.639831103000063],
            [-80.645374014999959, 35.64034343600008],
            [-80.644726773999935, 35.640564023000024],
            [-80.644079203999979, 35.640785661000052],
            [-80.641910061999965, 35.641521761000035],
            [-80.639806342999975, 35.642242281000051],
            [-80.638867019999964, 35.642561335000039],
            [-80.63792768199994, 35.642877454000029],
            [-80.637905036999939, 35.642885135000029],
            [-80.636743136999939, 35.643282278000072],
            [-80.636377910999954, 35.64340586000003],
            [-80.635392838999962, 35.643738058000054],
            [-80.634991879999973, 35.643873402000054],
            [-80.634608433999972, 35.644002122000074],
            [-80.63458925499998, 35.644008351000025],
            [-80.634568513999966, 35.64401487300006],
            [-80.634547772999952, 35.644021185000042],
            [-80.634528425999974, 35.644026877000044],
            [-80.634501201999967, 35.644034597000029],
            [-80.634481712999957, 35.644039916000054],
            [-80.634460572999956, 35.644045478000066],
            [-80.634432059999938, 35.644052607000049],
            [-80.634341850999988, 35.644071778000068],
            [-80.634321244999967, 35.644075573000066],
            [-80.634291892999954, 35.64408039500006],
            [-80.634261777999939, 35.644084733000057],
            [-80.634190826999941, 35.644094389000031],
            [-80.634171407999986, 35.644096872000034],
            [-80.634140685999967, 35.644100468000033],
            [-80.634121635999975, 35.644102481000061],
            [-80.634092691999967, 35.644105207000052],
            [-80.634071891999952, 35.644106930000078],
            [-80.634051025999952, 35.644108435000078],
            [-80.634022432999984, 35.644110176000027],
            [-80.632729229999939, 35.644173998000042],
            [-80.63249454399994, 35.644190687000048],
            [-80.632153976999973, 35.644233325000073],
            [-80.631818481999971, 35.644297376000054],
            [-80.631490075999977, 35.644382455000027],
            [-80.631170732999976, 35.644488051000053],
            [-80.630862373999946, 35.644613528000036],
            [-80.630566853999937, 35.644758132000049],
            [-80.630285949999973, 35.644920994000074],
            [-80.630021350999982, 35.645101133000026],
            [-80.629774647999966, 35.645297468000024],
            [-80.629547323999986, 35.645508816000074],
            [-80.629340747999947, 35.645733907000078],
            [-80.62915615999998, 35.645971388000078],
            [-80.628994673999955, 35.646219831000053],
            [-80.628857257999982, 35.646477741000069],
            [-80.628744739999945, 35.646743567000044],
            [-80.62865779599997, 35.647015710000062],
            [-80.628596950999963, 35.647292535000076],
            [-80.628562568999939, 35.647572375000038],
            [-80.628554858999962, 35.647853549000047],
            [-80.628573864999964, 35.648134366000079],
            [-80.628619475999983, 35.648413135000055],
            [-80.628691415999981, 35.648688182000058],
            [-80.628789253999969, 35.648957850000045],
            [-80.62891240099998, 35.649220519000039],
            [-80.629060115999948, 35.649474609000038],
            [-80.629231510999944, 35.649718592000056],
            [-80.62942555799998, 35.649951000000044],
            [-80.629641084999946, 35.650170435000064],
            [-80.629876800999966, 35.65037557800008],
            [-80.630131284999948, 35.65056519500007],
            [-80.630403006999984, 35.650738144000059],
            [-80.630690333999951, 35.650893387000053],
            [-80.630991536999943, 35.651029989000051],
            [-80.631304804999957, 35.651147128000048],
            [-80.631628253999963, 35.651244101000032],
            [-80.631959936999976, 35.65132032300005],
            [-80.632297859999937, 35.651375336000058],
            [-80.632639989999973, 35.651408810000078],
            [-80.632984270999941, 35.651420543000029],
            [-80.633265482999946, 35.651413949000073],
            [-80.634589881999943, 35.65134858700003],
            [-80.634651992999977, 35.651345164000077],
            [-80.634742612999958, 35.651339651000058],
            [-80.634803072999944, 35.651335627000037],
            [-80.634883077999973, 35.651329854000039],
            [-80.634940436999955, 35.651325408000048],
            [-80.635020341999962, 35.651318789000072],
            [-80.635081069999956, 35.651313413000025],
            [-80.635170828999946, 35.651304958000026],
            [-80.635231587999954, 35.651298889000032],
            [-80.635311238999975, 35.651290477000032],
            [-80.635371830999986, 35.651283731000035],
            [-80.635461271999986, 35.651273262000075],
            [-80.635511192999957, 35.651267178000069],
            [-80.635587559999976, 35.651257423000061],
            [-80.63563071699997, 35.651251727000044],
            [-80.635739054999988, 35.651236983000047],
            [-80.63577061999996, 35.651232592000042],
            [-80.635838817999968, 35.651222897000025],
            [-80.635894161999943, 35.651214735000053],
            [-80.635962203999952, 35.651204337000024],
            [-80.636021315999983, 35.651194965000059],
            [-80.636098876999938, 35.651182222000045],
            [-80.636157852999986, 35.651172192000047],
            [-80.63622552399994, 35.651160292000043],
            [-80.636280256999953, 35.651150372000075],
            [-80.636347739999962, 35.651137776000041],
            [-80.636369288999958, 35.651133708000032],
            [-80.636398756999938, 35.651127994000035],
            [-80.636456438999971, 35.651116642000034],
            [-80.636518314999989, 35.651104080000039],
            [-80.636691417999941, 35.651067857000044],
            [-80.636805695999954, 35.651042615000051],
            [-80.636873557999934, 35.651026831000024],
            [-80.636923231999958, 35.651015020000045],
            [-80.637000526999941, 35.650996245000044],
            [-80.637050140999975, 35.650983935000056],
            [-80.637117534999959, 35.650966863000065],
            [-80.637144845999956, 35.650959866000051],
            [-80.637163555999962, 35.650954981000041],
            [-80.637230717999955, 35.650937312000053],
            [-80.637276693999979, 35.650924992000057],
            [-80.637343617999989, 35.650906727000063],
            [-80.637392733999945, 35.650893061000033],
            [-80.637468912999964, 35.65087145900003],
            [-80.637517530999958, 35.650857415000075],
            [-80.637583910999979, 35.650837884000055],
            [-80.637629475999972, 35.65082424700006],
            [-80.637695595999958, 35.650804124000047],
            [-80.63774076499999, 35.650790149000045],
            [-80.637806611999963, 35.650769440000033],
            [-80.637851590999958, 35.650755065000055],
            [-80.637917154999968, 35.650733775000049],
            [-80.637943157999985, 35.650725253000076],
            [-80.637964721999936, 35.65071806900005],
            [-80.638379443999952, 35.650578844000052],
            [-80.63878751499999, 35.650441101000069],
            [-80.639774287999956, 35.650108326000066],
            [-80.640160933999937, 35.649977483000043],
            [-80.641326099999958, 35.649579231000075],
            [-80.642267543999935, 35.649262389000057],
            [-80.643232257999955, 35.64893470800007],
            [-80.645334675999948, 35.648214623000058],
            [-80.647502801999963, 35.647478860000035],
            [-80.648156580999967, 35.647255100000052],
            [-80.648793004999959, 35.647038199000065],
            [-80.650293016999967, 35.646528620000026],
            [-80.651760850999949, 35.646030727000038],
            [-80.652668277999965, 35.645723573000055],
            [-80.652702520999981, 35.64571184600004],
            [-80.653399525999987, 35.645470319000026],
            [-80.653591927999969, 35.645404147000079],
            [-80.653778800999987, 35.645339878000073],
            [-80.654019830999971, 35.645257430000072],
            [-80.654163688999972, 35.645208220000029],
            [-80.654188300999976, 35.645199731000048],
            [-80.654399663999982, 35.645126207000033],
            [-80.654565170999945, 35.645069447000026],
            [-80.654611804999945, 35.64505345200007],
            [-80.654761548999943, 35.645002740000052],
            [-80.654913600999976, 35.64495179000005],
            [-80.655065693999973, 35.644901368000035],
            [-80.655215631999965, 35.644852194000066],
            [-80.655463630999975, 35.644772010000054],
            [-80.655664879999961, 35.644708104000074],
            [-80.655936467999936, 35.644630011000061],
            [-80.656481366999969, 35.644474044000049],
            [-80.657026306999967, 35.644318942000041],
            [-80.659869982999965, 35.64351466800008],
            [-80.660090243999946, 35.643451911000056],
            [-80.660266588999946, 35.643401144000052],
            [-80.660395948999962, 35.643365557000038],
            [-80.660591224999962, 35.643311836000066],
            [-80.660677263999958, 35.643287361000034],
            [-80.660809742999959, 35.643248428000049],
            [-80.660841475999973, 35.643238991000032],
            [-80.66097358799999, 35.643199237000033],
            [-80.661005100999944, 35.643189642000038],
            [-80.661136840999973, 35.643149073000075],
            [-80.66116955199999, 35.643138879000048],
            [-80.661310276999984, 35.643094504000032],
            [-80.661342739999952, 35.64308414900006],
            [-80.661473680999961, 35.643041893000031],
            [-80.661504743999956, 35.643031757000074],
            [-80.661636087999966, 35.642988434000074],
            [-80.661664267999981, 35.642979032000028],
            [-80.661792134999985, 35.642935949000048],
            [-80.661823905999938, 35.642925127000069],
            [-80.661960465999982, 35.642878102000054],
            [-80.661983280999948, 35.64287018300007],
            [-80.662148490999982, 35.64281240300005],
            [-80.662177143999941, 35.642802284000027],
            [-80.662296891999972, 35.642759588000047],
            [-80.662329752999938, 35.64274774200004],
            [-80.662458249999986, 35.642700912000066],
            [-80.662492381999982, 35.642688332000034],
            [-80.662620391999951, 35.642640619000076],
            [-80.662654248999957, 35.642627860000061],
            [-80.662781764999977, 35.642579271000045],
            [-80.662816755999984, 35.642565786000034],
            [-80.662952815999972, 35.642512758000066],
            [-80.662987649999934, 35.642499030000067],
            [-80.663114107999945, 35.64244863600004],
            [-80.663148631999945, 35.642434728000069],
            [-80.66328369699994, 35.642379709000068],
            [-80.663314639999953, 35.642366981000066],
            [-80.663413185999957, 35.642326053000033],
            [-80.663440475999948, 35.642314621000025],
            [-80.663547599999958, 35.64226936700004],
            [-80.663579546999983, 35.642255737000028],
            [-80.663704396999947, 35.642201942000042],
            [-80.664163600999984, 35.64200614400005],
            [-80.664626561999967, 35.641807740000047],
            [-80.665205626999978, 35.64155816300007],
            [-80.66606075499999, 35.64119136000005],
            [-80.666800522999949, 35.640872051000031],
            [-80.66711801799994, 35.640735454000037],
            [-80.668280540999945, 35.640236450000032],
            [-80.67026432199998, 35.639379775000066],
            [-80.671061809999969, 35.639038766000056],
            [-80.671839519999935, 35.638703691000046],
            [-80.672243015999982, 35.63853079900008],
            [-80.672665165999945, 35.638350613000057],
            [-80.673038322999957, 35.638190276000046],
            [-80.673836527999981, 35.637846478000029],
            [-80.674638461999962, 35.637499951000052],
            [-80.674812205999956, 35.63742594200005],
            [-80.675004636999972, 35.637343276000024],
            [-80.675205946999938, 35.637256032000039],
            [-80.675407047999954, 35.63716809400006],
            [-80.675607748999937, 35.637079541000048],
            [-80.675800777999939, 35.636993628000027],
            [-80.675862880999944, 35.636965456000041],
            [-80.675949561999971, 35.636925388000066],
            [-80.675995929999942, 35.636903652000058],
            [-80.676082014999963, 35.636862735000079],
            [-80.676125370999955, 35.636841859000072],
            [-80.676205936999963, 35.636802557000067],
            [-80.676245605999952, 35.636782937000078],
            [-80.676330530999962, 35.636740433000057],
            [-80.676373582999986, 35.636718610000059],
            [-80.676449472999934, 35.63667964900003],
            [-80.676489797999977, 35.636658698000076],
            [-80.676565174999951, 35.636619070000052],
            [-80.67661713199999, 35.636591333000069],
            [-80.676816644999974, 35.636483193000061],
            [-80.676856932999954, 35.636461097000051],
            [-80.676914866999937, 35.636428945000034],
            [-80.676952412999981, 35.636407877000067],
            [-80.677009939999948, 35.636375243000032],
            [-80.677046793999978, 35.636354109000024],
            [-80.677105022999967, 35.636320355000066],
            [-80.677140908999945, 35.636299320000035],
            [-80.677197609999951, 35.636265734000062],
            [-80.677233959999967, 35.636243971000056],
            [-80.677290237999955, 35.636209918000077],
            [-80.677326635999975, 35.63618765800004],
            [-80.677382482999974, 35.636153137000065],
            [-80.677468565999959, 35.636098556000036],
            [-80.677492008999934, 35.63608337200003],
            [-80.677748426999983, 35.635922841000024],
            [-80.678045826999949, 35.635722212000076],
            [-80.678063011999939, 35.635710560000064],
            [-80.67824944399996, 35.63557608900004],
            [-80.678725369999938, 35.635212936000073],
            [-80.678773796999963, 35.635175424000067],
            [-80.678887926999948, 35.635085676000074],
            [-80.678905930999974, 35.635071437000079],
            [-80.679013762999944, 35.634985677000032],
            [-80.679033354999945, 35.634969985000055],
            [-80.679187850999938, 35.634845475000077],
            [-80.679201708999983, 35.63483425700008],
            [-80.679788502999941, 35.634357173000069],
            [-80.680136009999956, 35.634074635000047],
            [-80.68040367499998, 35.633859915000073],
            [-80.680679754999971, 35.633636724000041],
            [-80.680932921999954, 35.633430532000034],
            [-80.681170496999982, 35.633235671000079],
            [-80.681431609999947, 35.633023905000073],
            [-80.682051236999939, 35.632522101000063],
            [-80.682662257999937, 35.632028254000033],
            [-80.682684257999938, 35.632010349000041],
            [-80.682953408999936, 35.631789745000049],
            [-80.683215964999988, 35.631576358000075],
            [-80.683542821999936, 35.631313203000047],
            [-80.683889960999977, 35.631036625000036],
            [-80.68410838799997, 35.630861610000068],
            [-80.684352974999968, 35.630664726000077],
            [-80.684597155999938, 35.630467210000063],
            [-80.684834160999969, 35.630274569000051],
            [-80.685149218999982, 35.630017063000025],
            [-80.685871565999946, 35.629428908000079],
            [-80.686849242999983, 35.628631083000073],
            [-80.687190973999975, 35.62835394800004],
            [-80.687949215999936, 35.627741343000025],
            [-80.688513288999957, 35.627283187000046],
            [-80.688566865999974, 35.62724068700004],
            [-80.688616922999984, 35.627201860000071],
            [-80.688665563999962, 35.627165045000027],
            [-80.688722007999957, 35.627123414000039],
            [-80.688818716999947, 35.627057892000039],
            [-80.688852417999954, 35.62703859800007],
            [-80.688881558999981, 35.627022564000072],
            [-80.688918013999967, 35.627003253000055],
            [-80.688947713999937, 35.626988117000053],
            [-80.688979181999969, 35.626972671000033],
            [-80.68901108099999, 35.626957599000036],
            [-80.689043139999967, 35.626943032000042],
            [-80.689075559999935, 35.626928875000033],
            [-80.689108198999975, 35.626915190000034],
            [-80.68914121399996, 35.626901912000051],
            [-80.689172915999961, 35.626889696000035],
            [-80.689212458999975, 35.626875144000053],
            [-80.689244776999942, 35.62686380100007],
            [-80.689267506999954, 35.626856255000064],
            [-80.68929744199994, 35.626847134000059],
            [-80.689372797999965, 35.62682518400004],
            [-80.689440033999972, 35.626806689000034],
            [-80.689482401999953, 35.626795848000029],
            [-80.689519384999983, 35.626787817000036],
            [-80.68955346599995, 35.626780991000032],
            [-80.689594263999936, 35.626773415000059],
            [-80.689615314999969, 35.626769812000077],
            [-80.689682045999973, 35.626759627000069],
            [-80.689699836999978, 35.626757217000034],
            [-80.689722006999943, 35.626754557000027],
            [-80.689790504999962, 35.626749449000044],
            [-80.689856090999967, 35.626745243000073],
            [-80.689928490999989, 35.626741324000079],
            [-80.689954449999959, 35.626740220000045],
            [-80.690012081999953, 35.626740275000031],
            [-80.690082445999963, 35.626740866000034],
            [-80.690152929999954, 35.626741958000025],
            [-80.690226199999984, 35.626743642000065],
            [-80.690348154999981, 35.626747627000043],
            [-80.690448439999955, 35.626752078000038],
            [-80.690496422999956, 35.626754207000033],
            [-80.690556615999981, 35.626756542000066],
            [-80.69137679499994, 35.626783815000067],
            [-80.693002027999967, 35.626839637000046],
            [-80.694260931999963, 35.626879665000047],
            [-80.694378864999976, 35.626884820000043],
            [-80.694405850999942, 35.62688593200005],
            [-80.694566140999939, 35.626892140000052],
            [-80.694593246999943, 35.62689312100008],
            [-80.694743559999949, 35.626898191000066],
            [-80.694770533999986, 35.626899034000076],
            [-80.694930905999968, 35.626903646000073],
            [-80.69496488599998, 35.62690451800006],
            [-80.69519547699997, 35.626909707000038],
            [-80.69523574699997, 35.626910463000058],
            [-80.695466021999948, 35.626913939000076],
            [-80.695506070999954, 35.626914396000075],
            [-80.69573629599995, 35.626916175000076],
            [-80.695772207999937, 35.626916332000064],
            [-80.696006583999974, 35.626916420000043],
            [-80.696045737999953, 35.626916293000079],
            [-80.69626595699998, 35.626914788000079],
            [-80.696342450999964, 35.626913727000044],
            [-80.696957015999942, 35.626900878000072],
            [-80.697571833999973, 35.626889668000047],
            [-80.69760126999995, 35.626889052000024],
            [-80.698018450999939, 35.626879184000074],
            [-80.698430032999966, 35.626870427000028],
            [-80.698781383999972, 35.626863723000042],
            [-80.699149232999957, 35.626857464000068],
            [-80.699173491999943, 35.626856997000061],
            [-80.699714015999973, 35.626845384000035],
            [-80.700515909999979, 35.626829024000074],
            [-80.70125429999996, 35.626815127000043],
            [-80.701283288999946, 35.626814504000038],
            [-80.701676428999974, 35.626805006000041],
            [-80.70205056399999, 35.626797147000048],
            [-80.702454621999948, 35.626789962000032],
            [-80.702867160999972, 35.626784008000072],
            [-80.702898418999951, 35.626783467000052],
            [-80.703324934999955, 35.626774856000054],
            [-80.70498887499997, 35.626739272000066],
            [-80.706647883999949, 35.626704631000052],
            [-80.709216810999976, 35.62665596100004],
            [-80.709248397999943, 35.626655270000072],
            [-80.709439820999989, 35.626650528000027],
            [-80.70950101599999, 35.62664866800003],
            [-80.709692342999972, 35.626641772000028],
            [-80.709753522999961, 35.626639221000062],
            [-80.709899582999981, 35.626627288000066],
            [-80.709944772999961, 35.62662619200006],
            [-80.709992758999988, 35.626623979000044],
            [-80.71010343599994, 35.626619211000047],
            [-80.710136874999989, 35.626618325000038],
            [-80.710246455999936, 35.626612590000036],
            [-80.710389096999961, 35.62660053500008],
            [-80.710468917999947, 35.626593229000036],
            [-80.710520358999986, 35.626588397000035],
            [-80.710585826999989, 35.626582090000056],
            [-80.710619697999959, 35.626577747000056],
            [-80.710676077999949, 35.62657048300008],
            [-80.710776516999942, 35.626563317000034],
            [-80.710833326999989, 35.626556810000068],
            [-80.710932663999984, 35.626544897000031],
            [-80.710964008999952, 35.626541044000078],
            [-80.71098957199996, 35.626537764000034],
            [-80.711088711999935, 35.626524800000027],
            [-80.711142238999969, 35.626517527000033],
            [-80.71123128499994, 35.626504970000042],
            [-80.711473768999952, 35.626465070000052],
            [-80.711631808999982, 35.62643530400004],
            [-80.711835149999956, 35.626396986000032],
            [-80.712038432999975, 35.626358053000047],
            [-80.712054256999977, 35.626354996000032],
            [-80.712257176999969, 35.626315459000068],
            [-80.712459924999962, 35.62627532700003],
            [-80.712482107999961, 35.626270885000054],
            [-80.713119404999986, 35.626141848000032],
            [-80.713621883999963, 35.626038472000062],
            [-80.717128008999964, 35.625329125000064],
            [-80.71798516299998, 35.625156874000027],
            [-80.718415688999983, 35.625071697000067],
            [-80.718860671999948, 35.624982294000063],
            [-80.719233666999969, 35.624906291000059],
            [-80.720248481999988, 35.624697667000078],
            [-80.721094959999959, 35.624525678000055],
            [-80.721854210999936, 35.624374180000075],
            [-80.722039592999977, 35.624337185000059],
            [-80.722594632999972, 35.624225559000024],
            [-80.723147995999966, 35.624113622000039],
            [-80.723849614999949, 35.623973739000064],
            [-80.72460302199994, 35.623822130000065],
            [-80.725287109999954, 35.623683257000039],
            [-80.725976180999965, 35.623542200000031],
            [-80.72685775299999, 35.623365191000062],
            [-80.727773315999968, 35.623180714000057],
            [-80.72927618999995, 35.622874911000054],
            [-80.73014477199996, 35.622700800000075],
            [-80.730190105999952, 35.622691505000034],
            [-80.730435675999956, 35.622640035000074],
            [-80.730949973999941, 35.622538741000028],
            [-80.731491732999984, 35.622430681000026],
            [-80.732016279999982, 35.622324755000079],
            [-80.732425055999954, 35.622240744000067],
            [-80.732687778999946, 35.622187918000066],
            [-80.733028790999981, 35.622120762000065],
            [-80.733360531999949, 35.622056951000047],
            [-80.733628707999969, 35.622006459000033],
            [-80.733719177999944, 35.621989667000037],
            [-80.735700819999977, 35.62162184500005],
            [-80.735876696999981, 35.62158936700007],
            [-80.736730075999958, 35.621431776000065],
            [-80.736748993999981, 35.62142824600005],
            [-80.737350312999979, 35.621314942000026],
            [-80.737586204999957, 35.621271138000054],
            [-80.738017036999963, 35.621191126000042],
            [-80.738043116999961, 35.621186231000024],
            [-80.738196805999962, 35.621157334000031],
            [-80.738311075999945, 35.621136429000046],
            [-80.738427136999974, 35.621115694000025],
            [-80.738542835999965, 35.621095518000061],
            [-80.738666437999939, 35.621074512000064],
            [-80.73877459299996, 35.621056864000025],
            [-80.738833374999956, 35.621049494000033],
            [-80.738886321999985, 35.621043588000077],
            [-80.738939149999965, 35.621038355000053],
            [-80.738989810999954, 35.621033965000038],
            [-80.739038917999949, 35.621030361000066],
            [-80.739091672999962, 35.62102715900005],
            [-80.739147604999971, 35.621024415000079],
            [-80.739202017999958, 35.621022373000073],
            [-80.739273344999958, 35.621020540000075],
            [-80.739364845999944, 35.621018929000059],
            [-80.739398531999939, 35.621018770000035],
            [-80.739433460999976, 35.621019145000048],
            [-80.739479560999939, 35.621020384000076],
            [-80.739511756999946, 35.621021614000028],
            [-80.73953673699998, 35.621022825000068],
            [-80.739571775999934, 35.621024883000075],
            [-80.739596744999972, 35.621026605000054],
            [-80.739631758999963, 35.621029382000074],
            [-80.739658300999963, 35.62103176200003],
            [-80.739684968999939, 35.621034412000029],
            [-80.73971494999995, 35.621037702000024],
            [-80.739756735999947, 35.621042799000065],
            [-80.739830558999984, 35.621052804000044],
            [-80.739866056999972, 35.62105749400007],
            [-80.740339000999938, 35.621118134000028],
            [-80.74070233599997, 35.621165770000061],
            [-80.741063795999935, 35.621214072000043],
            [-80.741089802999966, 35.621217481000031],
            [-80.741604720999987, 35.621283427000037],
            [-80.741793602999962, 35.621308792000036],
            [-80.742017730999976, 35.62133814300006],
            [-80.742229168999984, 35.621365130000072],
            [-80.742292801999952, 35.621372863000033],
            [-80.742371962999982, 35.621382005000044],
            [-80.742444600999988, 35.62138989500005],
            [-80.742543768999951, 35.621399988000064],
            [-80.74262893599996, 35.621407975000068],
            [-80.742738263999968, 35.621417356000052],
            [-80.742823459999954, 35.62142398900005],
            [-80.742923032999954, 35.621430952000026],
            [-80.742949718999967, 35.621432752000032],
            [-80.743008326999984, 35.621436242000073],
            [-80.743118013999947, 35.621442177000063],
            [-80.743290454999965, 35.621448763000046],
            [-80.743450072999963, 35.621452322000039],
            [-80.743500558999983, 35.621453212000063],
            [-80.743580391999956, 35.621454251000046],
            [-80.743616250999935, 35.62145459900006],
            [-80.743706066999948, 35.621455175000051],
            [-80.74373284099994, 35.621455282000056],
            [-80.743758662999937, 35.621455258000026],
            [-80.743918339999937, 35.621454739000058],
            [-80.743985770999984, 35.62145410100004],
            [-80.744145419999938, 35.621451602000036],
            [-80.74427917099996, 35.621447861000036],
            [-80.744388910999987, 35.621443439000075],
            [-80.744465611999942, 35.62143980500008],
            [-80.744575236999935, 35.621433834000072],
            [-80.744615748999934, 35.621431476000055],
            [-80.744651902999976, 35.62142911400008],
            [-80.744761387999972, 35.621421595000072],
            [-80.744837772999972, 35.621415806000073],
            [-80.744947080999964, 35.621406744000069],
            [-80.745023612999944, 35.621399850000046],
            [-80.745132712999975, 35.621389242000078],
            [-80.745171213999981, 35.621385359000044],
            [-80.745280732999959, 35.621373917000028],
            [-80.745328908999966, 35.621368665000034],
            [-80.74539869399996, 35.621360739000067],
            [-80.745460504999983, 35.621353356000043],
            [-80.745522320999953, 35.621345610000049],
            [-80.745576910999944, 35.62133846100005],
            [-80.745636288999947, 35.62133037600006],
            [-80.745716465999976, 35.621318841000061],
            [-80.745824969999944, 35.621302391000029],
            [-80.745923645999937, 35.621286486000031],
            [-80.746021845999962, 35.621269711000025],
            [-80.746119977999967, 35.621252000000027],
            [-80.746231886999965, 35.621230712000056],
            [-80.746296584999982, 35.621217933000025],
            [-80.746480531999964, 35.621180401000061],
            [-80.746543961999976, 35.621167052000033],
            [-80.746620972999949, 35.62115035000005],
            [-80.746671267999943, 35.621139185000061],
            [-80.746757628999944, 35.621119566000061],
            [-80.746807871999977, 35.621107891000065],
            [-80.746884382999951, 35.621089716000029],
            [-80.746928309999987, 35.621079081000062],
            [-80.746995051999988, 35.621062615000028],
            [-80.747036269999967, 35.621052267000039],
            [-80.747084553999969, 35.621039936000045],
            [-80.747102414999972, 35.62103537400003],
            [-80.74712517599994, 35.62102950700006],
            [-80.747146162999968, 35.621024000000034],
            [-80.747220963999951, 35.621004202000051],
            [-80.747311776999936, 35.620979281000075],
            [-80.747415961999934, 35.620949665000069],
            [-80.747494453999934, 35.620926675000078],
            [-80.747607849999952, 35.62089247800003],
            [-80.747665865999977, 35.62087460500004],
            [-80.747730632999946, 35.620854230000077],
            [-80.747772488999942, 35.620840864000058],
            [-80.747836226999937, 35.62082020500003],
            [-80.747873788999982, 35.620807867000053],
            [-80.747941106999974, 35.620785409000064],
            [-80.747985580999966, 35.620770340000036],
            [-80.748058103999938, 35.620745389000035],
            [-80.74810522599995, 35.620728915000029],
            [-80.748177570999985, 35.620703219000063],
            [-80.748221724999951, 35.620687302000078],
            [-80.748284724999962, 35.620664257000044],
            [-80.748400559999936, 35.62062024200003],
            [-80.748490940999943, 35.62058459800005],
            [-80.74854929299994, 35.62056115300004],
            [-80.748678638999934, 35.620508099000062],
            [-80.748750650999966, 35.620477885000071],
            [-80.748875043999988, 35.62042451800005],
            [-80.748928563999982, 35.62040117500004],
            [-80.748990306999985, 35.620373801000028],
            [-80.749028301999942, 35.62035675900006],
            [-80.749098489999938, 35.620324912000058],
            [-80.749159535999979, 35.620296696000025],
            [-80.749272770999937, 35.620243389000052],
            [-80.749328091999985, 35.620216910000067],
            [-80.749403432999941, 35.620180234000031],
            [-80.749433974999988, 35.620165180000072],
            [-80.749493236999967, 35.620135688000062],
            [-80.749546251999959, 35.620108885000036],
            [-80.749664247999988, 35.620048293000025],
            [-80.749734218999947, 35.620011612000042],
            [-80.749851295999974, 35.61994896300007],
            [-80.749920261999989, 35.619911303000038],
            [-80.750035882999953, 35.619846880000068],
            [-80.750108712999975, 35.619805424000049],
            [-80.75015235099994, 35.619779724000068],
            [-80.750441944999977, 35.619606979000025],
            [-80.750735130999942, 35.619431177000024],
            [-80.750881746999937, 35.619342843000027],
            [-80.751463805999947, 35.61899212700007],
            [-80.752087482999968, 35.618618087000073],
            [-80.753373700999987, 35.617843489000052],
            [-80.753522799999985, 35.617753959000026],
            [-80.754736664999939, 35.617025056000045],
            [-80.754754607999985, 35.617014224000059],
            [-80.755012435999959, 35.616857764000031],
            [-80.755036874999973, 35.616842826000038],
            [-80.755229253999971, 35.616724398000031],
            [-80.755250153999953, 35.61671145300005],
            [-80.755400088999977, 35.616618272000039],
            [-80.755464985999936, 35.61657953100007],
            [-80.755529009999975, 35.616540590000056],
            [-80.755590469999959, 35.616502530000048],
            [-80.755649265999978, 35.616465552000079],
            [-80.755705589999934, 35.616429534000076],
            [-80.755768043999979, 35.616388929000038],
            [-80.755833804999952, 35.616345332000037],
            [-80.755918356999985, 35.616288175000079],
            [-80.75593517599998, 35.616276749000065],
            [-80.755993333999982, 35.616236338000078],
            [-80.756076316999952, 35.616177669000024],
            [-80.756129432999955, 35.616139505000035],
            [-80.756263847999946, 35.616041369000072],
            [-80.75632079199994, 35.61599906500004],
            [-80.756379710999965, 35.615954523000028],
            [-80.756423362999953, 35.615921074000028],
            [-80.756474267999977, 35.615881538000053],
            [-80.756521800999963, 35.615844063000054],
            [-80.756604501999959, 35.615777732000026],
            [-80.756666334999977, 35.61572714600004],
            [-80.756743550999943, 35.615662716000031],
            [-80.756801508999956, 35.615613437000036],
            [-80.756870341999957, 35.615553796000029],
            [-80.756918103999965, 35.615511749000063],
            [-80.757012964999944, 35.615426906000039],
            [-80.757056769999963, 35.615387143000078],
            [-80.757130161999953, 35.615319524000029],
            [-80.757147698999972, 35.615303269000037],
            [-80.757176141999935, 35.615276483000059],
            [-80.757248222999976, 35.615207928000075],
            [-80.757293535999963, 35.615164135000043],
            [-80.75736427999999, 35.615094659000079],
            [-80.757408553999937, 35.615050474000043],
            [-80.757477947999973, 35.614980097000057],
            [-80.757519567999964, 35.614937230000066],
            [-80.757581464999987, 35.614872481000077],
            [-80.757638502999953, 35.614811495000026],
            [-80.75767282399994, 35.614773470000046],
            [-80.757690407999974, 35.614753719000078],
            [-80.757714258999954, 35.61472666800006],
            [-80.757783706999987, 35.614647137000077],
            [-80.757821440999976, 35.614603240000065],
            [-80.757889440999975, 35.614522879000049],
            [-80.757924784999943, 35.614480470000046],
            [-80.757985834999943, 35.614406093000071],
            [-80.758018970999956, 35.614365128000031],
            [-80.758078782999974, 35.614290082000025],
            [-80.758110472999988, 35.614249739000059],
            [-80.758169825999971, 35.614173015000063],
            [-80.758204296999963, 35.614127722000035],
            [-80.758256312999947, 35.614058240000077],
            [-80.75829551999999, 35.614004842000043],
            [-80.758346071999938, 35.613934635000078],
            [-80.758384304999936, 35.613880472000062],
            [-80.758433368999988, 35.613809561000039],
            [-80.758470369999941, 35.613754994000033],
            [-80.758517931999961, 35.613683406000064],
            [-80.758540145999973, 35.613649544000054],
            [-80.758553820999964, 35.613628258000062],
            [-80.75859986599994, 35.61355601200006],
            [-80.758634625999946, 35.61350030400007],
            [-80.75867913899998, 35.61342742100004],
            [-80.75871261399999, 35.61337141100006],
            [-80.758755586999939, 35.613297913000054],
            [-80.758786386999986, 35.613244104000046],
            [-80.75882373099995, 35.613177447000055],
            [-80.758849474999977, 35.613130621000039],
            [-80.758897872999967, 35.61304089500004],
            [-80.758908514999973, 35.613021006000054],
            [-80.758922430999974, 35.612994494000077],
            [-80.758965483999987, 35.612911563000068],
            [-80.758990313999959, 35.612862747000065],
            [-80.75902810499997, 35.612786895000056],
            [-80.75905202499996, 35.612737866000032],
            [-80.759092101999954, 35.61265394600008],
            [-80.75911623099995, 35.612602259000028],
            [-80.759154735999971, 35.612517849000028],
            [-80.759177867999938, 35.612465934000056],
            [-80.759198491999939, 35.612418527000045],
            [-80.759214794999934, 35.612381052000046],
            [-80.759229454999968, 35.612346811000066],
            [-80.759236992999945, 35.612328767000065],
            [-80.759272327999952, 35.612243438000064],
            [-80.759295393999935, 35.612186173000055],
            [-80.759335095999973, 35.612084775000028],
            [-80.759368201999962, 35.611996354000041],
            [-80.759387317999938, 35.611942867000039],
            [-80.759400797999945, 35.611904361000029],
            [-80.759421815999985, 35.611843042000032],
            [-80.759435620999966, 35.611801835000051],
            [-80.759455753999987, 35.611740316000066],
            [-80.759464089999938, 35.611714463000055],
            [-80.759469068999977, 35.611698640000043],
            [-80.75948843499998, 35.611636516000033],
            [-80.759500493999951, 35.611596900000052],
            [-80.759517013999982, 35.611541295000052],
            [-80.759527934999937, 35.611503663000065],
            [-80.759543727999983, 35.611447918000067],
            [-80.759554782999942, 35.611407862000078],
            [-80.759571946999984, 35.611343992000059],
            [-80.759582483999964, 35.611303685000053],
            [-80.759596723999948, 35.611247662000039],
            [-80.759606689999941, 35.61120730500005],
            [-80.759622073999935, 35.611143132000052],
            [-80.75963104899995, 35.611104586000067],
            [-80.759649164999985, 35.611024420000035],
            [-80.759655651999935, 35.610994995000055],
            [-80.759667386999979, 35.610940008000057],
            [-80.759676783999964, 35.610894166000037],
            [-80.759687835999955, 35.610838054000055],
            [-80.759697137999979, 35.610788659000036],
            [-80.759707241999934, 35.610732426000027],
            [-80.75971631699997, 35.610679165000079],
            [-80.759726696999962, 35.610614766000026],
            [-80.759734775999959, 35.610561603000065],
            [-80.759742836999976, 35.61050515200003],
            [-80.759749510999939, 35.610455439000077],
            [-80.759757435999973, 35.610392278000063],
            [-80.759762470999988, 35.610348955000063],
            [-80.759768619999988, 35.610292343000026],
            [-80.759773919999986, 35.610239082000078],
            [-80.759779778999985, 35.610174306000033],
            [-80.759784510999964, 35.610115496000049],
            [-80.759788250999975, 35.610062320000054],
            [-80.759818133999943, 35.609621025000024],
            [-80.759846932999949, 35.609184421000066],
            [-80.759848007999949, 35.609167373000048],
            [-80.759866264999971, 35.60886363700007],
            [-80.759882423999954, 35.608570203000056],
            [-80.759883226999989, 35.608555112000033],
            [-80.759893900999941, 35.608344026000054],
            [-80.75990382599997, 35.608132916000045],
            [-80.759906083999965, 35.608071019000079],
            [-80.759914506999962, 35.607747446000076],
            [-80.759915273999979, 35.607715486000075],
            [-80.759921997999982, 35.607434196000042],
            [-80.759922847999974, 35.607337629000028],
            [-80.759918995999953, 35.606824844000073],
            [-80.759914221999964, 35.606427513000028],
            [-80.759912596999982, 35.606322555000077],
            [-80.759892323999964, 35.605022199000075],
            [-80.759890762999987, 35.604936403000067],
            [-80.759887506999974, 35.604758036000078],
            [-80.75988373499996, 35.604433603000075],
            [-80.759882567999966, 35.604205239000066],
            [-80.759883072999969, 35.604159146000029],
            [-80.759886527999981, 35.603976011000043],
            [-80.759891209999978, 35.603832418000025],
            [-80.759896595999976, 35.60377358900007],
            [-80.759902436999937, 35.603721474000054],
            [-80.759909303999962, 35.603668869000046],
            [-80.759915297999953, 35.603628567000044],
            [-80.759919623999963, 35.603603131000057],
            [-80.759925794999958, 35.603571081000041],
            [-80.759931249999966, 35.603545724000071],
            [-80.759937236999974, 35.603520443000036],
            [-80.759947355999941, 35.603481938000073],
            [-80.759958004999987, 35.603447067000047],
            [-80.759970654999961, 35.603408742000056],
            [-80.759986089999984, 35.603364848000069],
            [-80.759998087999975, 35.603332778000038],
            [-80.760010811999962, 35.60330728200006],
            [-80.760028259999956, 35.603273401000024],
            [-80.76004783999997, 35.603236325000069],
            [-80.760067855999978, 35.603199351000058],
            [-80.760085640999989, 35.603167291000034],
            [-80.76011701799996, 35.603112537000072],
            [-80.760127709999949, 35.603096157000039],
            [-80.760140530999934, 35.603077892000044],
            [-80.760153579999951, 35.603061688000025],
            [-80.760165159999985, 35.603048075000061],
            [-80.760175107999942, 35.603036803000066],
            [-80.760191212999985, 35.603020275000063],
            [-80.760215851999988, 35.602995069000031],
            [-80.760232075999966, 35.602979158000039],
            [-80.760248598999965, 35.602963571000032],
            [-80.760274463999963, 35.602940183000044],
            [-80.760290826999949, 35.602928400000053],
            [-80.760308358999964, 35.602917068000068],
            [-80.76032119599995, 35.602909446000069],
            [-80.760351103999938, 35.602895491000027],
            [-80.760398989999942, 35.602873548000048],
            [-80.760454958999958, 35.60284838900003],
            [-80.760503440999969, 35.602827016000049],
            [-80.760552705999942, 35.602805698000054],
            [-80.760602261999964, 35.602784653000072],
            [-80.760652186999948, 35.602763851000077],
            [-80.760702049999963, 35.602743469000075],
            [-80.760752181999976, 35.602723372000071],
            [-80.760802573999968, 35.602703566000059],
            [-80.760826079999958, 35.602694510000049],
            [-80.760878252999987, 35.602675501000078],
            [-80.760958850999941, 35.60264673100005],
            [-80.761031045999971, 35.602621471000077],
            [-80.761112020999974, 35.60259370700004],
            [-80.761201493999977, 35.602563744000065],
            [-80.761273630999938, 35.602540058000045],
            [-80.761354855999969, 35.602513972000054],
            [-80.761435054999936, 35.602488787000027],
            [-80.761531745999946, 35.602459168000053],
            [-80.761625824999953, 35.602431117000037],
            [-80.761672247999968, 35.60241833300006],
            [-80.761769913999956, 35.602392146000057],
            [-80.761841131999972, 35.602373628000066],
            [-80.761865826999951, 35.602367205000064],
            [-80.761962575999974, 35.602342824000061],
            [-80.762044558999946, 35.602322795000077],
            [-80.762441556999988, 35.602229861000069],
            [-80.762687454999934, 35.602171703000067],
            [-80.76281131099995, 35.602142398000069],
            [-80.76319089499998, 35.602051605000042],
            [-80.763532789999942, 35.601968917000079],
            [-80.763712444999953, 35.601927770000032],
            [-80.763941382999974, 35.60187469400006],
            [-80.76408672599996, 35.601840504000052],
            [-80.764128403999962, 35.601858779000054],
            [-80.764654150999945, 35.602090820000058],
            [-80.764709878999952, 35.602114999000037],
            [-80.764798449999944, 35.602152772000068],
            [-80.764831714999957, 35.602166813000053],
            [-80.764929570999982, 35.602207691000046],
            [-80.764958483999976, 35.602219657000035],
            [-80.76506285399995, 35.602262320000079],
            [-80.76509806599995, 35.602276551000045],
            [-80.765196788999958, 35.602316009000049],
            [-80.765232026999968, 35.602329936000046],
            [-80.765331174999972, 35.602368683000066],
            [-80.765366721999953, 35.602382417000058],
            [-80.765466284999945, 35.602420445000064],
            [-80.765500140999961, 35.602433234000046],
            [-80.765591005999966, 35.602467182000055],
            [-80.765625163999971, 35.602479802000062],
            [-80.765725496999949, 35.602516454000067],
            [-80.765833500999975, 35.602554515000065],
            [-80.766461841999956, 35.602768784000034],
            [-80.766485847999945, 35.602781614000037],
            [-80.766632232999939, 35.602877741000043],
            [-80.766775294999945, 35.602970197000047],
            [-80.766932510999936, 35.603073651000045],
            [-80.766963288999989, 35.60309372100005],
            [-80.767196081999941, 35.603244141000062],
            [-80.767735057999971, 35.603590927000027],
            [-80.768246074999979, 35.603917802000069],
            [-80.768409712999983, 35.60402466000005],
            [-80.768427702999986, 35.604036345000054],
            [-80.76860955099994, 35.604153839000048],
            [-80.768631910999943, 35.604168191000042],
            [-80.768902087999948, 35.604340468000032],
            [-80.768928934999963, 35.604357452000045],
            [-80.769200718999969, 35.604528036000033],
            [-80.769318814999963, 35.604599649000079],
            [-80.769346248999966, 35.604615588000058],
            [-80.770258190999982, 35.605141167000056],
            [-80.770378508999954, 35.605208123000068],
            [-80.770527770999934, 35.605288292000068],
            [-80.77056438599999, 35.60530774800003],
            [-80.770706725999958, 35.605382571000064],
            [-80.770742679999955, 35.605401272000051],
            [-80.770886750999978, 35.605475416000047],
            [-80.77092414599997, 35.605494451000027],
            [-80.771077637999952, 35.605571717000032],
            [-80.771112958999936, 35.605589308000049],
            [-80.771290234999981, 35.605676413000026],
            [-80.771333507999941, 35.605697401000043],
            [-80.771506338999984, 35.605780149000054],
            [-80.771549987999947, 35.605800776000024],
            [-80.771723957999939, 35.605881916000044],
            [-80.771768909999935, 35.605902600000036],
            [-80.771952779999936, 35.605986057000052],
            [-80.772013007999988, 35.606012898000074],
            [-80.772234916999935, 35.606109986000035],
            [-80.772275039999954, 35.606127326000035],
            [-80.772498155999983, 35.606222557000024],
            [-80.772537814999964, 35.606239277000043],
            [-80.772753081999952, 35.606328920000067],
            [-80.772771260999946, 35.606336448000036],
            [-80.772792821999985, 35.606345265000073],
            [-80.773018190999949, 35.606436802000076],
            [-80.773058110999955, 35.606452812000043],
            [-80.77327554499999, 35.606538918000069],
            [-80.773313857999938, 35.606553906000045],
            [-80.773523447999935, 35.606634892000045],
            [-80.773557850999964, 35.60664803800006],
            [-80.774413333999973, 35.606971308000027],
            [-80.775153197999941, 35.607252174000052],
            [-80.775339788999986, 35.607323227000052],
            [-80.775637444999973, 35.607439605000025],
            [-80.775661027999945, 35.607448756000053],
            [-80.77597997099997, 35.607571578000034],
            [-80.776002607999942, 35.607580232000032],
            [-80.776294982999957, 35.607691184000032],
            [-80.776316255999973, 35.607699202000049],
            [-80.776590493999947, 35.607801837000068],
            [-80.776779917999988, 35.607874361000029],
            [-80.776985526999965, 35.607952429000079],
            [-80.77705909499997, 35.607980108000049],
            [-80.777257408999958, 35.608054669000069],
            [-80.777530725999952, 35.608156321000024],
            [-80.777795210999955, 35.608253614000034],
            [-80.777846240999963, 35.608272197000076],
            [-80.778030461999947, 35.608339219000072],
            [-80.779604179999978, 35.608935125000073],
            [-80.779931768999973, 35.609058684000047],
            [-80.780545985999936, 35.609289054000044],
            [-80.781275672999982, 35.609560844000043],
            [-80.781297433999953, 35.609568891000038],
            [-80.781490204999955, 35.609639670000035],
            [-80.781522016999986, 35.609651227000029],
            [-80.781718350999938, 35.609721809000064],
            [-80.781756686999984, 35.609735467000064],
            [-80.781950794999943, 35.609803764000048],
            [-80.781982847999984, 35.609814920000076],
            [-80.782186194999952, 35.609884928000042],
            [-80.782216428999959, 35.609895230000063],
            [-80.782394649999958, 35.609955321000029],
            [-80.782417925999937, 35.609963084000071],
            [-80.782566680999935, 35.610012278000056],
            [-80.782708346999982, 35.610060786000076],
            [-80.782741516999977, 35.610072014000025],
            [-80.782919560999972, 35.61013154300008],
            [-80.78295888699995, 35.61014451300008],
            [-80.783173552999983, 35.610214338000048],
            [-80.783216834999962, 35.61022820200003],
            [-80.783371485999965, 35.610276980000037],
            [-80.783432328999936, 35.610296171000073],
            [-80.783468277999987, 35.61030736400005],
            [-80.783609318999936, 35.610350707000066],
            [-80.78363866799998, 35.610359630000062],
            [-80.783789506999938, 35.610404995000067],
            [-80.783819870999935, 35.610414025000068],
            [-80.783971111999961, 35.610458493000067],
            [-80.784000637999952, 35.610467079000045],
            [-80.784142781999947, 35.610507949000066],
            [-80.784255720999965, 35.610539036000034],
            [-80.78433586999995, 35.610559444000046],
            [-80.78461563999997, 35.610628316000032],
            [-80.784645070999943, 35.61063547100008],
            [-80.784925419999979, 35.610702761000027],
            [-80.784954977999973, 35.61070976600007],
            [-80.785235889999967, 35.610775467000053],
            [-80.785265479999964, 35.610782297000071],
            [-80.785546944999965, 35.610846408000043],
            [-80.785571484999934, 35.610851935000028],
            [-80.78575618299999, 35.610893074000046],
            [-80.785810606999974, 35.610904894000043],
            [-80.785985381999978, 35.610941885000045],
            [-80.786031106999985, 35.61095135100004],
            [-80.786216086999957, 35.610988793000047],
            [-80.786258729999986, 35.610997241000064],
            [-80.78644860299994, 35.611033888000065],
            [-80.786494630999982, 35.611042560000044],
            [-80.786670771999979, 35.611074942000073],
            [-80.786715558999958, 35.611082977000024],
            [-80.786892099999989, 35.611113871000043],
            [-80.78692631499996, 35.611119744000064],
            [-80.787182608999956, 35.611162874000058],
            [-80.787221250999949, 35.61116923000003],
            [-80.787320210999951, 35.611185137000064],
            [-80.787350517999982, 35.611189919000026],
            [-80.787449612999978, 35.611205264000034],
            [-80.787485794999952, 35.611210741000036],
            [-80.787624738999966, 35.611231284000041],
            [-80.787665609999976, 35.611237164000045],
            [-80.787794841999983, 35.611255254000071],
            [-80.78783421299994, 35.611260617000028],
            [-80.787963637999951, 35.611277758000028],
            [-80.787989326999934, 35.61128109200007],
            [-80.78812454399997, 35.611298164000061],
            [-80.788165585999934, 35.611303343000031],
            [-80.78819750599996, 35.611307275000058],
            [-80.788326962999975, 35.611322826000048],
            [-80.788363777999962, 35.611327120000055],
            [-80.788493390999975, 35.611341785000036],
            [-80.788530359999982, 35.61134583900008],
            [-80.788660120999964, 35.611359614000037],
            [-80.788696996999988, 35.611363401000062],
            [-80.788826893999953, 35.611376289000077],
            [-80.788871057999984, 35.611380487000076],
            [-80.789051122999979, 35.61139686100006],
            [-80.78909079899995, 35.611400321000076],
            [-80.789593088999936, 35.611442265000051],
            [-80.790148332999934, 35.611490308000043],
            [-80.790576954999949, 35.611528470000053],
            [-80.790817802999982, 35.611550335000061],
            [-80.791061338999953, 35.611572984000077],
            [-80.791085702999965, 35.611575194000068],
            [-80.791324986999939, 35.611596355000074],
            [-80.791345876999969, 35.611598162000064],
            [-80.79153415899998, 35.611614064000037],
            [-80.791712537999956, 35.611628574000065],
            [-80.791737939999962, 35.611630577000028],
            [-80.792077415999984, 35.61165655700006],
            [-80.792116016999955, 35.611659373000066],
            [-80.792211137999971, 35.611665969000057],
            [-80.792276915999935, 35.611671142000034],
            [-80.792416295999942, 35.611683605000053],
            [-80.792638399999987, 35.611702770000079],
            [-80.793227061999971, 35.611752594000052],
            [-80.79434706699999, 35.611844567000048],
            [-80.795259530999942, 35.611920896000072],
            [-80.79551244299995, 35.61194131700006],
            [-80.795738817999961, 35.611960098000054],
            [-80.796043095999948, 35.611986134000063],
            [-80.796373310999968, 35.612015335000024],
            [-80.796409266999945, 35.612018394000074],
            [-80.796478053999977, 35.612024015000031],
            [-80.796526103999952, 35.612027726000065],
            [-80.796604800999944, 35.612033451000059],
            [-80.796652608999977, 35.612036716000034],
            [-80.796734957999945, 35.612041923000049],
            [-80.796786881999935, 35.612044954000055],
            [-80.796866688999955, 35.612049231000071],
            [-80.796919321999951, 35.61205179600006],
            [-80.796961623999948, 35.612053650000064],
            [-80.79699965399999, 35.612055319000035],
            [-80.797052218999966, 35.612057370000059],
            [-80.797132706999946, 35.612060120000024],
            [-80.797185212999977, 35.612061661000041],
            [-80.797265737999965, 35.612063634000037],
            [-80.797318402999963, 35.612064669000063],
            [-80.797398948999955, 35.612065862000065],
            [-80.797451544999944, 35.612066386000038],
            [-80.79753210299998, 35.612066799000047],
            [-80.797554307999974, 35.612066868000056],
            [-80.797588068999971, 35.612066798000058],
            [-80.797678694999945, 35.612066331000051],
            [-80.797736503999943, 35.612065725000036],
            [-80.797847087999969, 35.612063979000027],
            [-80.797880102999954, 35.612063357000068],
            [-80.797980214999939, 35.612061168000025],
            [-80.798022247999938, 35.612060086000042],
            [-80.798122326999987, 35.612057123000056],
            [-80.798164398999972, 35.612055713000075],
            [-80.798264440999958, 35.612051975000043],
            [-80.79830670299998, 35.612050231000069],
            [-80.798406696999962, 35.612045714000033],
            [-80.798446467999952, 35.61204377200005],
            [-80.798536413999955, 35.612039047000053],
            [-80.798576495999953, 35.612036794000062],
            [-80.798683057999938, 35.612030397000069],
            [-80.79871828399996, 35.612028131000045],
            [-80.798818085999983, 35.612021372000072],
            [-80.798860113999979, 35.612018361000025],
            [-80.798959835999938, 35.612010828000052],
            [-80.799000786999954, 35.612007578000032],
            [-80.799090465999939, 35.612000118000026],
            [-80.799133517999962, 35.611996365000039],
            [-80.799233093999987, 35.611987280000051],
            [-80.799274443999934, 35.611983346000045],
            [-80.799373912999954, 35.611973500000033],
            [-80.799415265999983, 35.611969245000068],
            [-80.799514617999989, 35.611958636000054],
            [-80.799555912999949, 35.611954066000067],
            [-80.799655138999981, 35.611942697000075],
            [-80.799696336999943, 35.611937814000044],
            [-80.799795426999935, 35.611925686000063],
            [-80.799817789999963, 35.611922901000071],
            [-80.799836509999977, 35.611920497000028],
            [-80.799935456999947, 35.611907611000049],
            [-80.799976682999954, 35.61190208000005],
            [-80.80007547699995, 35.611888434000036],
            [-80.800116407999951, 35.611882620000074],
            [-80.800215040999944, 35.611868220000076],
            [-80.800256098999967, 35.611862063000046],
            [-80.800354562999985, 35.611846907000029],
            [-80.800398675999986, 35.611839927000062],
            [-80.800474643999962, 35.611827582000046],
            [-80.800519988999952, 35.611820013000056],
            [-80.80060169799998, 35.611806011000056],
            [-80.800618724999936, 35.611803093000049],
            [-80.800659140999983, 35.611796005000031],
            [-80.800757562999934, 35.611778353000034],
            [-80.800795840999967, 35.611771344000033],
            [-80.800884246999942, 35.611754819000055],
            [-80.800917357999936, 35.61174851800007],
            [-80.80102065899996, 35.611728425000024],
            [-80.801060809999967, 35.611720451000053],
            [-80.801158611999938, 35.611700634000044],
            [-80.801198799999952, 35.61169232900005],
            [-80.801296372999957, 35.611671768000065],
            [-80.801336470999956, 35.611663155000031],
            [-80.801433803999942, 35.611641851000059],
            [-80.801473562999945, 35.611632986000075],
            [-80.801570648999984, 35.611610946000042],
            [-80.801610591999975, 35.611601713000027],
            [-80.801707419999957, 35.611578932000043],
            [-80.801747148999937, 35.61156942100007],
            [-80.801843713999972, 35.611545904000025],
            [-80.801883238999949, 35.611536114000046],
            [-80.801979531999962, 35.611511864000079],
            [-80.802019584999982, 35.611501608000026],
            [-80.802757084999939, 35.611309626000036],
            [-80.802774214999943, 35.611305132000041],
            [-80.803142202999936, 35.611207919000037],
            [-80.803504560999954, 35.611113135000039],
            [-80.803867107999963, 35.611019244000033],
            [-80.804236101999948, 35.610924639000075],
            [-80.804263764999973, 35.610917460000053],
            [-80.806015240999955, 35.610455439000077],
            [-80.806176412999946, 35.61041434100008],
            [-80.806203262999986, 35.610407417000033],
            [-80.806375430999935, 35.610362533000057],
            [-80.806403504999935, 35.610355130000073],
            [-80.806603765999967, 35.610301716000038],
            [-80.806634160999977, 35.61029350900003],
            [-80.806833979999965, 35.610238908000042],
            [-80.806862208999974, 35.61023110800005],
            [-80.807033277999949, 35.610183318000054],
            [-80.807126118999975, 35.610156439000036],
            [-80.807220665999978, 35.610128098000075],
            [-80.807322868999961, 35.610096289000069],
            [-80.807416531999934, 35.610066055000061],
            [-80.807458708999945, 35.610052237000048],
            [-80.807512822999968, 35.610033903000044],
            [-80.807596316999934, 35.610005087000047],
            [-80.807686425999975, 35.609973024000055],
            [-80.807769118999943, 35.609942706000027],
            [-80.80786360999997, 35.609906969000065],
            [-80.807954496999969, 35.609871532000057],
            [-80.808029482999984, 35.609841586000073],
            [-80.808047696999949, 35.609834095000053],
            [-80.808128559999943, 35.609800649000078],
            [-80.80821595499998, 35.609763500000042],
            [-80.808247404999975, 35.609749768000029],
            [-80.808295860999976, 35.609728610000047],
            [-80.808373269999947, 35.609693997000079],
            [-80.808478440999977, 35.609645856000043],
            [-80.808551049999949, 35.609611882000024],
            [-80.808637598999951, 35.60957049600006],
            [-80.808683740999982, 35.60954812500006],
            [-80.808713587999989, 35.609533323000051],
            [-80.808799109999939, 35.609490534000031],
            [-80.808874028999981, 35.60945220800005],
            [-80.808958488999963, 35.609408039000073],
            [-80.809032625999976, 35.609368412000038],
            [-80.809115991999988, 35.609322874000043],
            [-80.809189172999936, 35.609282029000042],
            [-80.809271407999972, 35.609235142000045],
            [-80.809307289999936, 35.609214469000051],
            [-80.809343450999961, 35.609193189000052],
            [-80.809424525999987, 35.60914497300007],
            [-80.809495507999941, 35.609101869000028],
            [-80.809575390999953, 35.609052344000077],
            [-80.809645390999947, 35.609008041000038],
            [-80.809724047999964, 35.60895722500004],
            [-80.809797621999962, 35.608908643000063],
            [-80.809874811999975, 35.608856553000066],
            [-80.809896550999952, 35.608841788000063],
            [-80.809952298999974, 35.608803035000051],
            [-80.810020190999978, 35.608755045000066],
            [-80.810083519999978, 35.608709414000032],
            [-80.810150280999949, 35.60866038000006],
            [-80.810212406999938, 35.608613872000035],
            [-80.810278016999973, 35.608563812000057],
            [-80.810339013999965, 35.608516378000047],
            [-80.810410003999948, 35.608460094000066],
            [-80.810463488999972, 35.608416813000076],
            [-80.810526724999988, 35.608364753000046],
            [-80.810585498999956, 35.60831543900008],
            [-80.810647511999946, 35.608262410000066],
            [-80.81070518699994, 35.60821214300006],
            [-80.810765956999944, 35.608158160000073],
            [-80.810818815999937, 35.608110359000079],
            [-80.810839931999965, 35.608090920000052],
            [-80.810885115999952, 35.608049322000056],
            [-80.810905458999969, 35.608030462000045],
            [-80.810924310999951, 35.608012746000043],
            [-80.810983129999954, 35.607957110000029],
            [-80.811032241999953, 35.607909845000052],
            [-80.81108988699998, 35.607853397000042],
            [-80.811140605999981, 35.607802813000035],
            [-80.811203261999935, 35.607739158000072],
            [-80.811252904999947, 35.607687781000038],
            [-80.811308019999956, 35.60762967200003],
            [-80.811353931999975, 35.607580402000053],
            [-80.811408968999956, 35.607520280000074],
            [-80.811452755999937, 35.607471565000026],
            [-80.81150540799996, 35.607411951000074],
            [-80.811549230999958, 35.607361431000072],
            [-80.811600627999951, 35.607301093000046],
            [-80.811643482999955, 35.607249853000042],
            [-80.811693609999963, 35.607188803000042],
            [-80.811758789999942, 35.607107067000072],
            [-80.811882352999987, 35.606947461000061],
            [-80.811891205999984, 35.606935975000056],
            [-80.812018141999943, 35.606770574000052],
            [-80.812118876999989, 35.60663825000006],
            [-80.812143466999942, 35.606605916000035],
            [-80.81226888599997, 35.606439764000072],
            [-80.812279978999982, 35.606424986000036],
            [-80.812457489999986, 35.60618725300003],
            [-80.812469576999945, 35.606170970000051],
            [-80.812730260999956, 35.605817681000076],
            [-80.812904771999968, 35.60558281200008],
            [-80.813065430999984, 35.605368284000065],
            [-80.813374576999934, 35.604957872000057],
            [-80.813493882999978, 35.604800592000061],
            [-80.813618345999942, 35.60463782000005],
            [-80.813743836999947, 35.604475039000079],
            [-80.813871087999985, 35.604311276000033],
            [-80.813913206999985, 35.60425586100007],
            [-80.814080919999981, 35.604030700000067],
            [-80.814091503999975, 35.604016418000072],
            [-80.814257944999952, 35.603790630000049],
            [-80.814268478999963, 35.603776264000032],
            [-80.814429193999956, 35.603555945000039],
            [-80.814444046999938, 35.603535510000029],
            [-80.814468043999966, 35.603502267000067],
            [-80.814579247999973, 35.603348209000046],
            [-80.814720421999937, 35.603163547000065],
            [-80.814893392999977, 35.60293923100005],
            [-80.815054358999987, 35.602732286000048],
            [-80.815067457999987, 35.602715447000037],
            [-80.815241746999959, 35.602493279000043],
            [-80.815256792999946, 35.602473966000048],
            [-80.815370601999973, 35.602318378000064],
            [-80.815426890999959, 35.602236864000076],
            [-80.815647923999961, 35.601940955000032],
            [-80.815885184999956, 35.601624511000068],
            [-80.816008727999986, 35.60146405100005],
            [-80.81614048299997, 35.601295157000038],
            [-80.81626827499997, 35.601133441000059],
            [-80.816381238999952, 35.600992320000046],
            [-80.816481732999989, 35.600872975000073],
            [-80.816594977999955, 35.600741325000058],
            [-80.816715228999954, 35.600604455000052],
            [-80.816836135999949, 35.600469758000031],
            [-80.816932021999946, 35.600365391000025],
            [-80.817042184999934, 35.600248272000044],
            [-80.817148611999983, 35.600137638000035],
            [-80.817256595999936, 35.600027822000072],
            [-80.817376825999986, 35.599908245000051],
            [-80.817506824999953, 35.599780870000075],
            [-80.817567675999953, 35.599721840000029],
            [-80.817627036999966, 35.599664995000069],
            [-80.817686837999986, 35.599608467000053],
            [-80.817747838999935, 35.599551547000033],
            [-80.817799058999981, 35.599504342000046],
            [-80.817916300999968, 35.599398198000074],
            [-80.818032804999973, 35.599295196000071],
            [-80.818083748999982, 35.599251092000031],
            [-80.818120086999954, 35.599219636000043],
            [-80.818231389999937, 35.599125079000032],
            [-80.818289493999941, 35.599074765000069],
            [-80.818336206999959, 35.599033534000057],
            [-80.818366586999957, 35.599007185000062],
            [-80.81842674699999, 35.598956151000039],
            [-80.818463854999948, 35.598925400000041],
            [-80.818498747999968, 35.598897019000049],
            [-80.818533953999975, 35.59886888300008],
            [-80.81857040899996, 35.598840289000066],
            [-80.818646913999942, 35.598781796000026],
            [-80.818672627999945, 35.59876259400005],
            [-80.81872545799996, 35.598723802000052],
            [-80.818824107999944, 35.598649127000044],
            [-80.818948663999947, 35.598551935000046],
            [-80.819047036999962, 35.598477129000059],
            [-80.819147533999967, 35.59840261100004],
            [-80.81924218499995, 35.598334133000037],
            [-80.819345543999987, 35.59826117700004],
            [-80.819462998999938, 35.598180299000035],
            [-80.819637456999942, 35.598053612000058],
            [-80.819663631999958, 35.598033573000066],
            [-80.820258055999943, 35.597646448000035],
            [-80.820634021999979, 35.597401155000057],
            [-80.820897674999969, 35.597229136000067],
            [-80.821464496999965, 35.596862244000079],
            [-80.821862680999971, 35.596605496000052],
            [-80.822270267999954, 35.59634350400006],
            [-80.822768235999945, 35.596021112000074],
            [-80.823512088999962, 35.59553830200008],
            [-80.823529229999963, 35.595527119000053],
            [-80.823906221999948, 35.595279929000071],
            [-80.824284555999952, 35.595034104000035],
            [-80.824641527999972, 35.59480422200005],
            [-80.82499880499995, 35.594576106000034],
            [-80.825021338999989, 35.59456162400005],
            [-80.825712025999962, 35.594114725000054],
            [-80.825888106999969, 35.594000793000077],
            [-80.826722562999976, 35.593461944000069],
            [-80.826753051999958, 35.593442078000066],
            [-80.827083551999976, 35.593224774000078],
            [-80.827406923999945, 35.593014007000079],
            [-80.82776297199996, 35.592784059000053],
            [-80.828127764999977, 35.592550712000047],
            [-80.828153602999976, 35.59253405700008],
            [-80.829194073999986, 35.591858262000073],
            [-80.829216377999956, 35.591861899000037],
            [-80.829422637999983, 35.591896104000057],
            [-80.829501953999966, 35.591908644000057],
            [-80.829591057999949, 35.591922047000025],
            [-80.829625681999971, 35.591927138000074],
            [-80.829784311999958, 35.591949938000027],
            [-80.829827259999945, 35.591955934000055],
            [-80.829976227999964, 35.591976116000069],
            [-80.830015172999936, 35.591981246000046],
            [-80.830167078999978, 35.592000617000053],
            [-80.830210220999959, 35.592005941000025],
            [-80.830369598999937, 35.592024955000056],
            [-80.830397314999971, 35.592028189000075],
            [-80.830437194999945, 35.592032737000068],
            [-80.830597413999953, 35.592048590000047],
            [-80.83085852499994, 35.592070500000034],
            [-80.830877541999939, 35.592072063000046],
            [-80.831127415999958, 35.59209214200007],
            [-80.831146274999981, 35.592093624000029],
            [-80.831415113999981, 35.592114266000067],
            [-80.831652345999942, 35.592131713000072],
            [-80.831899306999958, 35.592149107000068],
            [-80.832138031999989, 35.592165180000052],
            [-80.832154654999954, 35.592166272000043],
            [-80.832341981999946, 35.592178282000077],
            [-80.832730645999959, 35.592209463000074],
            [-80.832792821999988, 35.592214090000027],
            [-80.833199313999955, 35.592241992000027],
            [-80.833581419999973, 35.592268782000076],
            [-80.833964165999987, 35.59229616500005],
            [-80.83436026399994, 35.592325079000034],
            [-80.834397096999965, 35.592327641000054],
            [-80.83476822199998, 35.592352189000053],
            [-80.835130623999987, 35.592377313000043],
            [-80.83551252999996, 35.592405039000028],
            [-80.835893657999975, 35.592433970000059],
            [-80.836001040999975, 35.592442090000077],
            [-80.836094397999943, 35.592449628000054],
            [-80.836177969999937, 35.59245678700006],
            [-80.836272054999938, 35.592465309000033],
            [-80.836347299999943, 35.592472497000074],
            [-80.836378327999967, 35.592475711000077],
            [-80.836441440999977, 35.592482659000041],
            [-80.836493193999956, 35.592488737000053],
            [-80.836545203999947, 35.592495206000024],
            [-80.836604767999972, 35.592503054000076],
            [-80.836663302999966, 35.592511222000041],
            [-80.83672183799996, 35.592519845000027],
            [-80.836780210999962, 35.592528901000037],
            [-80.836839430999987, 35.592538553000054],
            [-80.83688992499998, 35.592547162000074],
            [-80.836975417999952, 35.592562369000063],
            [-80.83713226499998, 35.59258989600005],
            [-80.837257295999962, 35.592613110000059],
            [-80.837372873999982, 35.592635722000068],
            [-80.837497213999939, 35.592661296000074],
            [-80.837620435999952, 35.59268792000006],
            [-80.837743861999968, 35.592715877000046],
            [-80.837858065999967, 35.592742915000031],
            [-80.838006095999958, 35.592779484000062],
            [-80.838117616999966, 35.592805739000028],
            [-80.838272644999961, 35.592840452000075],
            [-80.838409009999964, 35.592872025000077],
            [-80.838544912999964, 35.592904532000034],
            [-80.838698934999968, 35.592942557000072],
            [-80.838827809999941, 35.592972647000067],
            [-80.839013888999943, 35.593013622000058],
            [-80.839031700999954, 35.593017606000046],
            [-80.839200862999974, 35.59305542900006],
            [-80.839387769999973, 35.593097869000076],
            [-80.839571757999977, 35.59314028700004],
            [-80.839867175999984, 35.593209718000026],
            [-80.840068243999951, 35.593258062000075],
            [-80.840167109999982, 35.593281832000059],
            [-80.840229886999964, 35.593296516000066],
            [-80.841042139999956, 35.593481239000027],
            [-80.841839721999975, 35.593663715000048],
            [-80.842825850999986, 35.593891198000051],
            [-80.843554429999983, 35.594057802000066],
            [-80.843899710999949, 35.594138267000062],
            [-80.844272676999935, 35.594224154000074],
            [-80.844663075999961, 35.594312933000026],
            [-80.845019925999964, 35.594393037000032],
            [-80.845292159999985, 35.594457810000051],
            [-80.845613766999975, 35.594533568000031],
            [-80.846008623999978, 35.594623876000071],
            [-80.846036672999958, 35.594630656000049],
            [-80.846080909999955, 35.594641247000027],
            [-80.84612077099996, 35.594650699000056],
            [-80.846192319999943, 35.594667221000066],
            [-80.846301272999938, 35.594693470000038],
            [-80.846381656999938, 35.59472194500006],
            [-80.846455126999956, 35.59474391100008],
            [-80.84664498099994, 35.59479912900008],
            [-80.846668737999948, 35.594806728000037],
            [-80.846756306999964, 35.594835359000058],
            [-80.84685032799996, 35.594866855000078],
            [-80.846943254999985, 35.594898766000028],
            [-80.847032098999989, 35.594930030000057],
            [-80.84727894699995, 35.595019434000051],
            [-80.847540463999962, 35.595114982000041],
            [-80.847801182999945, 35.595211071000051],
            [-80.848068490999935, 35.595310444000063],
            [-80.848113204999947, 35.59532682400004],
            [-80.848500146999982, 35.595466480000027],
            [-80.848887823999974, 35.595607852000057],
            [-80.848975268999936, 35.595640053000068],
            [-80.849230184999954, 35.595733925000047],
            [-80.849588434999987, 35.595867075000058],
            [-80.849622885999963, 35.59587973500004],
            [-80.85009546799995, 35.596051414000044],
            [-80.850572936999981, 35.596223128000076],
            [-80.850679748999937, 35.596260184000073],
            [-80.850911653999958, 35.59633162800003],
            [-80.851056417999985, 35.596372493000047],
            [-80.851109445999953, 35.596387157000038],
            [-80.85118584899999, 35.596407848000069],
            [-80.851222764999989, 35.596417699000028],
            [-80.851300211999956, 35.596438060000025],
            [-80.851352520999967, 35.596451513000034],
            [-80.85149675599996, 35.596487808000063],
            [-80.851550113999963, 35.596500936000041],
            [-80.851627331999964, 35.596519504000071],
            [-80.851664562999986, 35.596528312000032],
            [-80.851741975999971, 35.596546328000045],
            [-80.851876231999938, 35.596575725000037],
            [-80.851934511999957, 35.59658769300006],
            [-80.851994306999984, 35.596599612000034],
            [-80.852086590999988, 35.596617381000044],
            [-80.852137089999985, 35.596626849000074],
            [-80.852215545999968, 35.59664116600004],
            [-80.852266242999974, 35.596650163000049],
            [-80.852354729999945, 35.596665425000026],
            [-80.852405498999985, 35.596673929000076],
            [-80.852484340999979, 35.596686745000056],
            [-80.85253215399996, 35.596694294000031],
            [-80.852611164999985, 35.596706403000042],
            [-80.852659176999964, 35.596713538000074],
            [-80.852738345999967, 35.596724936000044],
            [-80.852788874999987, 35.59673196500006],
            [-80.852876134999974, 35.596743682000067],
            [-80.852907142999982, 35.596747754000035],
            [-80.852931792999982, 35.596750860000043],
            [-80.853505249999955, 35.596821766000062],
            [-80.853808901999969, 35.596860112000059],
            [-80.854129828999987, 35.596901344000059],
            [-80.85415332599996, 35.596904307000045],
            [-80.85497430199996, 35.597006046000047],
            [-80.855515873999934, 35.597074287000055],
            [-80.855667364999988, 35.597093373000064],
            [-80.856534536999959, 35.597201604000077],
            [-80.856667383999934, 35.597218185000031],
            [-80.857303422999962, 35.597297560000072],
            [-80.857534810999937, 35.597327480000047],
            [-80.857779345999973, 35.597358456000052],
            [-80.85779809099995, 35.597360796000032],
            [-80.857968863999986, 35.597381813000027],
            [-80.858152562999976, 35.597404420000032],
            [-80.858346934999986, 35.597430616000054],
            [-80.858381064999946, 35.597435105000045],
            [-80.85883688499996, 35.597493543000041],
            [-80.85915601399995, 35.597524838000027],
            [-80.859453563999978, 35.597545127000046],
            [-80.85947594199996, 35.597546605000048],
            [-80.859506090999957, 35.597548452000069],
            [-80.85954501599997, 35.597550726000065],
            [-80.859854224999935, 35.597591218000048],
            [-80.859885361999943, 35.597597761000031],
            [-80.859964853999941, 35.597613827000032],
            [-80.860219072999939, 35.597656809000057],
            [-80.860546173999978, 35.597704015000033],
            [-80.860876496999936, 35.597751685000048],
            [-80.860960152999951, 35.597763086000043],
            [-80.86098926599999, 35.597766740000054],
            [-80.861806038999987, 35.597866980000049],
            [-80.861991125999964, 35.597890138000025],
            [-80.862168173999976, 35.597912290000068],
            [-80.862624995999965, 35.597971137000059],
            [-80.863091331999954, 35.598033136000026],
            [-80.863115869999945, 35.598036341000068],
            [-80.863141103999965, 35.598039517000075],
            [-80.864432822999959, 35.59819897400007],
            [-80.864842745999965, 35.598249829000054],
            [-80.865102801999967, 35.598282576000031],
            [-80.865443378999942, 35.598325971000065],
            [-80.865792930999987, 35.59837110400008],
            [-80.866132255999958, 35.59841549500004],
            [-80.866486067999972, 35.598462381000047],
            [-80.866508515999953, 35.598465308000073],
            [-80.866534673999979, 35.598468598000068],
            [-80.866918966999947, 35.598515959000053],
            [-80.867299210999988, 35.598563399000057],
            [-80.867678583999975, 35.598611310000024],
            [-80.86806269799996, 35.598660408000057],
            [-80.868087607999939, 35.598663533000035],
            [-80.868581392999943, 35.598724304000029],
            [-80.869069864999972, 35.598785305000035],
            [-80.869499614999938, 35.598839703000067],
            [-80.87012690499995, 35.598919957000078],
            [-80.870503829999961, 35.598967491000053],
            [-80.870901215999936, 35.599016820000031],
            [-80.871276093999938, 35.599062606000075],
            [-80.871693078999954, 35.599114733000079],
            [-80.872282478999978, 35.599189221000074],
            [-80.87282449199995, 35.599258601000031],
            [-80.872992948999979, 35.599279611000043],
            [-80.873025294999934, 35.59928354300007],
            [-80.873294824999959, 35.599315478000051],
            [-80.873335507999968, 35.599320141000078],
            [-80.873605391999945, 35.599350038000068],
            [-80.873646123999947, 35.599354394000045],
            [-80.873916332999954, 35.59938224900003],
            [-80.873957048999955, 35.599386290000041],
            [-80.874143882999988, 35.599404120000031],
            [-80.874438054999985, 35.599441479000063],
            [-80.874804777999941, 35.599489560000052],
            [-80.875161309999953, 35.599537751000071],
            [-80.875538119999987, 35.599590221000028],
            [-80.875596086999963, 35.599597970000048],
            [-80.875867938999988, 35.599625820000028],
            [-80.875988492999966, 35.599635095000053],
            [-80.876089169999943, 35.59964358600007],
            [-80.87618973299999, 35.599652814000024],
            [-80.876290195999957, 35.599662779000028],
            [-80.876391143999967, 35.599673543000051],
            [-80.876482894999981, 35.599683987000049],
            [-80.876583639999978, 35.599696182000059],
            [-80.876704353999969, 35.599711700000057],
            [-80.876747036999973, 35.599717005000059],
            [-80.876925724999978, 35.599738487000025],
            [-80.87709095699995, 35.599759391000077],
            [-80.877246787999979, 35.599780070000065],
            [-80.877410452999982, 35.599802804000035],
            [-80.877601162999952, 35.599830603000044],
            [-80.877780901999984, 35.599858096000048],
            [-80.877970028999982, 35.599888390000046],
            [-80.878167713999972, 35.599921489000053],
            [-80.878335387999982, 35.599946833000047],
            [-80.87879339999995, 35.600008658000036],
            [-80.878815686999985, 35.600011618000053],
            [-80.879164464999974, 35.600057210000045],
            [-80.87918087099996, 35.600059328000043],
            [-80.879541772999971, 35.600105165000059],
            [-80.879868155999986, 35.600145506000047],
            [-80.880290422999963, 35.600196422000067],
            [-80.88059826999995, 35.600234109000041],
            [-80.88092568199994, 35.60027492100005],
            [-80.881258029999969, 35.600317109000059],
            [-80.881348775999982, 35.600327845000038],
            [-80.881409277999978, 35.600334554000028],
            [-80.881453332999968, 35.600340221000067],
            [-80.881481989999941, 35.600344317000065],
            [-80.88150508199999, 35.60034790800006],
            [-80.881526574999953, 35.600351618000047],
            [-80.881574729999954, 35.600360158000058],
            [-80.881600326999944, 35.600365193000073],
            [-80.881620919999989, 35.60036948000004],
            [-80.881647169999951, 35.600375244000077],
            [-80.881749381999953, 35.600402440000039],
            [-80.881793326999968, 35.600414700000044],
            [-80.88183176299998, 35.600425702000052],
            [-80.88190827699998, 35.600448296000025],
            [-80.881979723999962, 35.600470198000039],
            [-80.882050739999954, 35.600492777000056],
            [-80.882119174999957, 35.600515321000046],
            [-80.88224413599994, 35.600559571000076],
            [-80.88226412299997, 35.600566602000072],
            [-80.882439774999966, 35.600627969000072],
            [-80.882458631999953, 35.600634516000071],
            [-80.882606839999937, 35.600685635000048],
            [-80.88262473399999, 35.600691769000036],
            [-80.882782494999958, 35.600745521000078],
            [-80.882804715999953, 35.600753034000036],
            [-80.883019970999953, 35.600825260000079],
            [-80.883299972999964, 35.600920256000052],
            [-80.883703881999963, 35.60105728700006],
            [-80.884402888999944, 35.601293142000031],
            [-80.884784082999943, 35.60142120200004],
            [-80.884805443999937, 35.601428307000049],
            [-80.885041743999977, 35.601506298000061],
            [-80.885260550999988, 35.601579174000051],
            [-80.885624742999937, 35.601701889000026],
            [-80.88590504199999, 35.601797550000072],
            [-80.886192711999968, 35.601896837000027],
            [-80.887615227999959, 35.60239477600004],
            [-80.887666020999973, 35.602412251000032],
            [-80.888385611999979, 35.602655539000068],
            [-80.888961823999978, 35.602850344000046],
            [-80.889268517999938, 35.602953703000026],
            [-80.889342699999986, 35.602977962000068],
            [-80.889679061999971, 35.603085079000039],
            [-80.890005140999961, 35.603190348000055],
            [-80.890330775999985, 35.603296902000068],
            [-80.890659549999953, 35.603405937000048],
            [-80.890688958999988, 35.603415765000079],
            [-80.890939541999956, 35.603501933000075],
            [-80.891227553999954, 35.603600254000071],
            [-80.891695211999945, 35.603758342000049],
            [-80.891712936999966, 35.603764297000055],
            [-80.891798320999953, 35.603792799000075],
            [-80.892186592999963, 35.603922406000038],
            [-80.892216849999954, 35.603932401000066],
            [-80.892518498999948, 35.604030985000065],
            [-80.892747499999984, 35.604107413000065],
            [-80.892845350999949, 35.604140864000044],
            [-80.892982537999956, 35.604187761000048],
            [-80.893224718999988, 35.604272526000045],
            [-80.893300765999982, 35.604298457000027],
            [-80.893372900999964, 35.604322307000075],
            [-80.893395067999961, 35.604329926000048],
            [-80.893443636999962, 35.604347372000063],
            [-80.893485773999942, 35.604363291000027],
            [-80.893527591999941, 35.604379882000046],
            [-80.893577748999974, 35.604400750000025],
            [-80.893598005999934, 35.604409554000028],
            [-80.893690456999934, 35.604451730000051],
            [-80.893783381999981, 35.604493047000062],
            [-80.894088923999959, 35.604625067000029],
            [-80.894387345999974, 35.60475503300006],
            [-80.894685089999939, 35.604885725000031],
            [-80.894980748999956, 35.605016523000074],
            [-80.89513064099998, 35.605083511000032],
            [-80.895152156999984, 35.605093064000073],
            [-80.895319663999942, 35.605166946000054],
            [-80.89534187199996, 35.605176676000042],
            [-80.895509893999986, 35.605249782000044],
            [-80.895532149999951, 35.605259398000044],
            [-80.895700674999944, 35.605331724000052],
            [-80.895719465999946, 35.605339742000069],
            [-80.895835057999989, 35.60538877700003],
            [-80.89587641899999, 35.605406099000049],
            [-80.896459208999943, 35.605647017000024],
            [-80.896753936999971, 35.605757590000053],
            [-80.896938640999963, 35.605820319000031],
            [-80.897121357999936, 35.605883286000051],
            [-80.897303582999939, 35.605947016000073],
            [-80.897484253999949, 35.606011137000053],
            [-80.897691376999944, 35.606085790000066],
            [-80.897899941999981, 35.60616217300003],
            [-80.897934242999952, 35.606174594000038],
            [-80.898605448999945, 35.606414865000033],
            [-80.899281676999976, 35.606658087000028],
            [-80.899463036999975, 35.606723314000078],
            [-80.899849978999953, 35.606861821000052],
            [-80.900149123999938, 35.606970501000035],
            [-80.900449776999949, 35.607081163000032],
            [-80.900535512999966, 35.607111836000058],
            [-80.900732301999938, 35.60718023000004],
            [-80.90086479699994, 35.607226278000041],
            [-80.90118046799995, 35.60733785900004],
            [-80.90152177799996, 35.607460617000072],
            [-80.901938392999966, 35.607613064000077],
            [-80.902121115999989, 35.60767926300008],
            [-80.902148097999941, 35.607688949000078],
            [-80.902388259999952, 35.607774386000074],
            [-80.902418958999988, 35.607785196000066],
            [-80.902650635999976, 35.607865924000066],
            [-80.902713013999971, 35.607887203000075],
            [-80.902775600999973, 35.607907647000047],
            [-80.902834713999937, 35.607926531000032],
            [-80.902878158999954, 35.607940409000037],
            [-80.902974020999977, 35.607969996000065],
            [-80.903077399999972, 35.608000794000077],
            [-80.903174049999961, 35.608028559000047],
            [-80.903278269999987, 35.608057401000053],
            [-80.903346693999936, 35.608075832000054],
            [-80.903375693999976, 35.608083342000043],
            [-80.903480701999968, 35.608110212000042],
            [-80.903578495999966, 35.608134231000065],
            [-80.903684104999968, 35.608159092000051],
            [-80.903760341999941, 35.608176439000033],
            [-80.903818219999948, 35.608189155000048],
            [-80.903871984999967, 35.608200674000045],
            [-80.903930052999954, 35.608212799000057],
            [-80.904005017999964, 35.608227885000076],
            [-80.904055050999943, 35.60823732800003],
            [-80.904141898999967, 35.608253289000061],
            [-80.904264994999949, 35.608274421000033],
            [-80.904342655999983, 35.608286818000067],
            [-80.904466444999969, 35.608305095000048],
            [-80.904554259999941, 35.608317015000068],
            [-80.904595269999959, 35.608322420000036],
            [-80.904686174999938, 35.608333260000052],
            [-80.904774390999989, 35.608343020000063],
            [-80.904906529999948, 35.608355996000057],
            [-80.904995062999944, 35.608363592000046],
            [-80.905117458999939, 35.60837269700005],
            [-80.905199954999944, 35.608377846000053],
            [-80.905318243999943, 35.60838392900007],
            [-80.905397332999939, 35.608387132000075],
            [-80.905514950999986, 35.608390621000069],
            [-80.905593628999952, 35.608392102000039],
            [-80.905702857999984, 35.608393055000079],
            [-80.905835114999945, 35.608392710000032],
            [-80.905924914999957, 35.608391725000047],
            [-80.906005008999955, 35.608390186000065],
            [-80.906129043999954, 35.608386387000053],
            [-80.906219050999937, 35.608382599000038],
            [-80.906338381999944, 35.608376260000057],
            [-80.906422790999954, 35.608370776000072],
            [-80.906539098999986, 35.608361957000056],
            [-80.906618764999962, 35.608355051000046],
            [-80.90673482699998, 35.608343728000079],
            [-80.906814242999985, 35.608335113000066],
            [-80.906922337999958, 35.608322275000035],
            [-80.907008936999944, 35.608310991000053],
            [-80.907124113999942, 35.608294697000076],
            [-80.907202863999942, 35.608282682000038],
            [-80.907317468999963, 35.608263919000024],
            [-80.907395804999965, 35.608250216000044],
            [-80.907489000999988, 35.608233055000028],
            [-80.907509651999987, 35.608229018000031],
            [-80.907587521999972, 35.608213637000063],
            [-80.907693686999949, 35.608191531000045],
            [-80.907761394999966, 35.608176705000062],
            [-80.907859831999986, 35.608154153000044],
            [-80.907927102999963, 35.608138057000076],
            [-80.908010993999937, 35.608117249000031],
            [-80.908058758999971, 35.608104979000075],
            [-80.908081410999955, 35.608099105000065],
            [-80.908113870999955, 35.608090498000024],
            [-80.908256295999934, 35.608052239000074],
            [-80.908301417999951, 35.608039899000062],
            [-80.908395982999934, 35.60801357500003],
            [-80.908438959999955, 35.608001411000032],
            [-80.908542613999941, 35.607971586000076],
            [-80.908587530999966, 35.607958440000061],
            [-80.908690786999955, 35.607927708000034],
            [-80.908733481999946, 35.607914797000035],
            [-80.908826992999934, 35.607886075000067],
            [-80.908869490999962, 35.607872817000043],
            [-80.908971951999945, 35.607840363000037],
            [-80.909014350999939, 35.607826728000077],
            [-80.909107123999945, 35.607796445000076],
            [-80.909145785999954, 35.60778364500004],
            [-80.909465185999977, 35.60767594400005],
            [-80.909692143999962, 35.607600628000057],
            [-80.909830346999968, 35.607555461000061],
            [-80.909979455999974, 35.607507310000074],
            [-80.909997345999955, 35.607501497000044],
            [-80.91052743399996, 35.60732814000005],
            [-80.911726550999958, 35.606937773000027],
            [-80.912055136999982, 35.606831359000068],
            [-80.912644022999984, 35.606641866000075],
            [-80.913229073999958, 35.60645515300007],
            [-80.91326612499995, 35.60644317200007],
            [-80.91414804499999, 35.606154232000051],
            [-80.914513961999944, 35.606033942000067],
            [-80.915025814999979, 35.605865671000061],
            [-80.915443656999969, 35.605732604000025],
            [-80.915891167999973, 35.605588416000046],
            [-80.916271334999976, 35.605464589000064],
            [-80.916739563999954, 35.605310547000045],
            [-80.917022174999943, 35.605218215000036],
            [-80.917346512999984, 35.605113216000063],
            [-80.917633017999947, 35.605021346000058],
            [-80.917667643999948, 35.605031020000069],
            [-80.917787191999935, 35.60506390200004],
            [-80.918153257999961, 35.605163767000079],
            [-80.918529201999945, 35.605265024000062],
            [-80.919417603999989, 35.605502325000032],
            [-80.92011761699996, 35.605690039000024],
            [-80.920443960999989, 35.605777983000053],
            [-80.920705278999947, 35.60584775500007],
            [-80.921100248999949, 35.605951920000052],
            [-80.921114440999986, 35.60595562900005],
            [-80.921475328999975, 35.60604937800008],
            [-80.921778681999967, 35.606129893000059],
            [-80.921796882999956, 35.606134688000054],
            [-80.922040919999972, 35.606198426000049],
            [-80.922071567999978, 35.606207287000075],
            [-80.922152431999962, 35.606231216000026],
            [-80.92223563999994, 35.606256410000071],
            [-80.922309207999945, 35.606279180000058],
            [-80.922391678999986, 35.606305262000035],
            [-80.922473117999971, 35.606331594000039],
            [-80.922555044999967, 35.606358669000031],
            [-80.922620779999988, 35.606380844000057],
            [-80.922656956999958, 35.606393420000074],
            [-80.922918548999974, 35.606486353000037],
            [-80.923366417999944, 35.606647298000041],
            [-80.923509550999938, 35.606698333000054],
            [-80.92353034599995, 35.606705696000063],
            [-80.923713604999989, 35.606770120000078],
            [-80.923737396999968, 35.606778417000044],
            [-80.923903598999971, 35.606835910000029],
            [-80.924309526999934, 35.606979682000031],
            [-80.925604783999972, 35.607435214000077],
            [-80.926009950999969, 35.607577339000045],
            [-80.926327617999959, 35.60768807900007],
            [-80.926602346999971, 35.607785263000039],
            [-80.926850165999952, 35.607874098000025],
            [-80.92709832099996, 35.607964173000028],
            [-80.927336842999978, 35.608051811000053],
            [-80.927593269999988, 35.608147173000077],
            [-80.927649476999989, 35.608167691000062],
            [-80.928569424999978, 35.608497306000061],
            [-80.928617711999948, 35.608514330000048],
            [-80.928951265999956, 35.608630012000049],
            [-80.928979473999959, 35.608639701000072],
            [-80.929304702999957, 35.608750340000029],
            [-80.929332553999984, 35.608759724000038],
            [-80.929658939999968, 35.608868640000026],
            [-80.929686637999964, 35.608877792000044],
            [-80.929918737999969, 35.608953746000054],
            [-80.930013677999966, 35.608984815000042],
            [-80.930137344999935, 35.60902354600006],
            [-80.93024122199995, 35.609054637000042],
            [-80.930283384999939, 35.609067060000029],
            [-80.930378160999965, 35.609094543000026],
            [-80.930418513999939, 35.609106067000027],
            [-80.930513610999981, 35.609132804000069],
            [-80.930556003999982, 35.609144528000058],
            [-80.930660967999984, 35.609173077000037],
            [-80.930705583999952, 35.609184998000046],
            [-80.930812405999973, 35.609213031000024],
            [-80.930855755999971, 35.609224195000024],
            [-80.930961435999961, 35.609250923000047],
            [-80.931006239999988, 35.609262043000058],
            [-80.931112259999963, 35.609287860000052],
            [-80.931155359999934, 35.609298162000073],
            [-80.93125202799996, 35.609320835000062],
            [-80.931299266999986, 35.60933168300005],
            [-80.931425323999974, 35.609360021000043],
            [-80.931478869999978, 35.609371766000038],
            [-80.931605351999963, 35.609398817000056],
            [-80.931659165999974, 35.609410033000074],
            [-80.931786052999939, 35.609435790000077],
            [-80.93183670999997, 35.609445814000026],
            [-80.931967204999978, 35.609470897000051],
            [-80.932044640999948, 35.60948518500004],
            [-80.932113365999953, 35.609497339000029],
            [-80.932178095999973, 35.609508375000075],
            [-80.932296176999955, 35.609527760000049],
            [-80.932341316999953, 35.609534971000073],
            [-80.932449776999988, 35.60955182400005],
            [-80.932493161999957, 35.609558382000046],
            [-80.93260182299997, 35.609574354000074],
            [-80.932649071999947, 35.609581084000069],
            [-80.932777730999987, 35.609598826000024],
            [-80.932829069999968, 35.609605653000074],
            [-80.932959086999972, 35.609622305000073],
            [-80.933009531999971, 35.609628513000075],
            [-80.933138665999934, 35.60964378500006],
            [-80.933190165999974, 35.609649625000031],
            [-80.933319508999944, 35.60966366100007],
            [-80.933371155999964, 35.609669015000065],
            [-80.933500691999939, 35.609681812000076],
            [-80.93355239999994, 35.609686669000041],
            [-80.933682110999939, 35.609698225000045],
            [-80.933733852999978, 35.609702585000036],
            [-80.933863722999945, 35.609712900000034],
            [-80.933915585999955, 35.609716769000045],
            [-80.93404559399994, 35.609725839000077],
            [-80.934079876999988, 35.609728121000046],
            [-80.934217750999949, 35.609735103000048],
            [-80.934327545999963, 35.609739263000051],
            [-80.934361138999975, 35.60974043300007],
            [-80.934480955999959, 35.609744231000036],
            [-80.934514684999954, 35.609745195000073],
            [-80.934624545999952, 35.609747995000077],
            [-80.934656721999943, 35.609748720000027],
            [-80.934766602999957, 35.609750869000038],
            [-80.934798915999977, 35.609751405000054],
            [-80.934908815999961, 35.609752901000036],
            [-80.934940894999954, 35.609753243000057],
            [-80.935050804999946, 35.60975409100007],
            [-80.935083102999954, 35.609754244000044],
            [-80.935198058999958, 35.609754443000043],
            [-80.935225129999935, 35.609754401000032],
            [-80.935335115999976, 35.609753946000069],
            [-80.935376037999958, 35.609753623000074],
            [-80.935546135999971, 35.609751639000024],
            [-80.935597460999986, 35.60975079800005],
            [-80.935777521999967, 35.609746997000059],
            [-80.935853595999959, 35.609744859000045],
            [-80.936507780999989, 35.609721880000052],
            [-80.936581990999969, 35.609718765000025],
            [-80.936771200999942, 35.609709526000074],
            [-80.93680960599994, 35.609707514000036],
            [-80.936988757999984, 35.609697492000066],
            [-80.937027177999937, 35.609695207000073],
            [-80.937217540999939, 35.609683202000042],
            [-80.937254458999973, 35.609680738000066],
            [-80.937433370999941, 35.609668182000064],
            [-80.937475372999984, 35.609665071000052],
            [-80.937594112999989, 35.60965580900006],
            [-80.937642403999973, 35.609651825000071],
            [-80.937819413999989, 35.60963642300004],
            [-80.937858837999954, 35.609632847000057],
            [-80.938025967999977, 35.60961707000007],
            [-80.93806331899998, 35.609613413000034],
            [-80.938222276999966, 35.609597292000046],
            [-80.938257538999949, 35.60959359800006],
            [-80.93840640999997, 35.609577511000055],
            [-80.938440450999963, 35.60957372300004],
            [-80.938589160999982, 35.609556695000038],
            [-80.938623208999957, 35.609552687000075],
            [-80.938771754999948, 35.609534719000067],
            [-80.93880577699997, 35.609530493000079],
            [-80.938955189999945, 35.609511452000049],
            [-80.939013251999938, 35.609503719000031],
            [-80.939320280999937, 35.609461121000038],
            [-80.939344905999974, 35.609457647000056],
            [-80.93964942599996, 35.609413944000039],
            [-80.939736215999972, 35.609401245000072],
            [-80.939826776999951, 35.609387190000064],
            [-80.939984481999943, 35.609361325000066],
            [-80.940017571999988, 35.609355790000052],
            [-80.940273444999946, 35.609312169000077],
            [-80.940315068999951, 35.609304903000066],
            [-80.94058023599996, 35.609257526000079],
            [-80.94061549099996, 35.609251104000066],
            [-80.940791949999948, 35.609218346000034],
            [-80.940832345999979, 35.60921068500005],
            [-80.940889924999965, 35.609199201000024],
            [-80.941057945999944, 35.609164716000066],
            [-80.94166790099996, 35.609038922000025],
            [-80.942275104999965, 35.608912746000044],
            [-80.942312868999977, 35.608904754000037],
            [-80.942981380999981, 35.608760713000038],
            [-80.943646558999944, 35.608618469000078],
            [-80.94410379499999, 35.608521318000044],
            [-80.94460548699999, 35.608416276000071],
            [-80.944861001999982, 35.608356152000056],
            [-80.945184605999941, 35.608259907000047],
            [-80.945498101999988, 35.608143472000052],
            [-80.94579960599998, 35.608007547000057],
            [-80.946087304999935, 35.607852949000062],
            [-80.94635946599999, 35.607680610000045],
            [-80.946614454999974, 35.607491565000032],
            [-80.946850736999977, 35.607286950000059],
            [-80.947066891999953, 35.607067998000048],
            [-80.94726161799997, 35.606836025000064],
            [-80.947433745999945, 35.606592426000077],
            [-80.947582239999974, 35.606338666000056],
            [-80.947706207999943, 35.606076272000053],
            [-80.947804904999941, 35.605806821000044],
            [-80.947877734999963, 35.605531934000055],
            [-80.947924261999958, 35.605253266000034],
            [-80.947944206999978, 35.604972491000069],
            [-80.947937449999984, 35.604691296000055],
            [-80.94790403099995, 35.60441137600003],
            [-80.947844152999949, 35.604134413000054],
            [-80.94775817499999, 35.603862071000037],
            [-80.94764661399995, 35.603595990000031],
            [-80.947510141999942, 35.603337768000074],
            [-80.94734957999998, 35.603088961000026],
            [-80.947165892999976, 35.60285106200007],
            [-80.946960186999945, 35.602625504000059],
            [-80.946733697999946, 35.602413642000045],
            [-80.946487788999946, 35.602216751000071],
            [-80.946223937999946, 35.602036014000078],
            [-80.945943733999968, 35.601872520000029],
            [-80.945648858999959, 35.601727249000078],
            [-80.945341087999964, 35.601601077000055],
            [-80.94502227299995, 35.601494762000073],
            [-80.944694327999969, 35.601408943000024],
            [-80.944359227999939, 35.60134413600008],
            [-80.944018986999936, 35.601300731000038],
            [-80.943675649999989, 35.601278990000026],
            [-80.943331283999953, 35.601279041000055],
            [-80.942987956999957, 35.601300888000026],
            [-80.94264773499998, 35.601344395000069],
            [-80.942402818999938, 35.601389625000024],
            [-80.941882881999959, 35.601498499000058],
            [-80.941403678999961, 35.601600307000069],
            [-80.940723570999978, 35.601745744000027],
            [-80.940066010999942, 35.601887421000072],
            [-80.939485715999979, 35.602008008000041],
            [-80.938888688999953, 35.602131133000057],
            [-80.938774088999935, 35.602154652000024],
            [-80.938664448999987, 35.602175007000028],
            [-80.938437615999987, 35.602215534000038],
            [-80.93821928999995, 35.602252754000062],
            [-80.938123244999986, 35.602268507000076],
            [-80.938097224999979, 35.602272317000029],
            [-80.937820276999958, 35.602312053000048],
            [-80.937555219999979, 35.602348828000061],
            [-80.937453464999976, 35.602361795000036],
            [-80.937338969999985, 35.602375644000062],
            [-80.937224190999984, 35.602388787000052],
            [-80.937110076999943, 35.602401119000035],
            [-80.936987466999938, 35.602413554000066],
            [-80.936858668999946, 35.60242571200007],
            [-80.936725513999988, 35.60243729900003],
            [-80.93665198399998, 35.602443034000032],
            [-80.936513244999958, 35.60245277000007],
            [-80.936362556999939, 35.602462273000071],
            [-80.936221923999938, 35.602470139000047],
            [-80.936088994999977, 35.602476630000069],
            [-80.93550996099998, 35.602496969000072],
            [-80.935393624999961, 35.602499425000076],
            [-80.935269708999954, 35.602500870000028],
            [-80.935196217999987, 35.602501174000054],
            [-80.935118553999985, 35.602501036000035],
            [-80.935040786999934, 35.602500436000071],
            [-80.934963108999966, 35.602499378000061],
            [-80.934885474999987, 35.602497860000028],
            [-80.934808601999976, 35.602495901000054],
            [-80.93471901099997, 35.60249304000007],
            [-80.934697566999944, 35.602491652000026],
            [-80.934619439999949, 35.602485447000049],
            [-80.934541493999973, 35.602478502000054],
            [-80.934463665999942, 35.602470814000071],
            [-80.93438587299994, 35.602462371000058],
            [-80.93430828399994, 35.602453195000066],
            [-80.934230846999981, 35.602443278000067],
            [-80.934151489999977, 35.602432334000071],
            [-80.934088154999984, 35.602423025000064],
            [-80.934023979999949, 35.602413053000078],
            [-80.933962109999982, 35.602402897000047],
            [-80.933901606999939, 35.602391268000076],
            [-80.933828559999938, 35.602376440000057],
            [-80.93375567999999, 35.60236085300005],
            [-80.933680108999965, 35.60234386500008],
            [-80.933628577999968, 35.602331779000053],
            [-80.93356653799998, 35.60231667100004],
            [-80.933505589999982, 35.602301257000079],
            [-80.93344508499996, 35.602285378000033],
            [-80.933383685999956, 35.602268679000076],
            [-80.933329840999988, 35.602253540000049],
            [-80.933276371999966, 35.602238035000028],
            [-80.93325574499994, 35.602231861000064],
            [-80.933222288999957, 35.602220913000053],
            [-80.933004205999964, 35.602149546000078],
            [-80.932706034999967, 35.602050048000024],
            [-80.932408771999974, 35.601948923000066],
            [-80.932113492999974, 35.601846518000059],
            [-80.931245995999973, 35.601535700000056],
            [-80.931006933999981, 35.60144679800004],
            [-80.930989999999952, 35.601440541000045],
            [-80.930733070999963, 35.601346141000079],
            [-80.93071467599998, 35.60133942300007],
            [-80.930448030999969, 35.601242638000031],
            [-80.930429243999981, 35.601235862000067],
            [-80.930162054999983, 35.601140084000065],
            [-80.930142260999958, 35.601133036000078],
            [-80.92984680699999, 35.601028522000036],
            [-80.929825045999962, 35.601020881000068],
            [-80.929505829999982, 35.600909608000052],
            [-80.929113929999971, 35.600772134000067],
            [-80.927832627999976, 35.600321522000058],
            [-80.927419954999948, 35.600175356000079],
            [-80.927385006999941, 35.600163123000073],
            [-80.927213313999971, 35.600103730000058],
            [-80.927052087999982, 35.600047053000026],
            [-80.926931714999967, 35.600004140000067],
            [-80.926478577999944, 35.599841315000049],
            [-80.926183569999978, 35.599736494000069],
            [-80.926151466999954, 35.599725211000077],
            [-80.926077272999976, 35.599699419000046],
            [-80.92603330999998, 35.599684362000062],
            [-80.925930777999952, 35.599649775000046],
            [-80.925901200999988, 35.599639899000067],
            [-80.925789017999989, 35.59960282600008],
            [-80.925757951999969, 35.599592671000039],
            [-80.925645429999975, 35.599556289000077],
            [-80.925614372999974, 35.599546357000065],
            [-80.925501519999955, 35.599510666000072],
            [-80.925471628999958, 35.599501315000055],
            [-80.925367895999955, 35.599469208000073],
            [-80.925337839999941, 35.599460006000072],
            [-80.925224366999942, 35.599425649000068],
            [-80.925196515999971, 35.599417305000031],
            [-80.92508156599996, 35.59938328800007],
            [-80.925050789999943, 35.599374285000067],
            [-80.924940493999941, 35.599342395000065],
            [-80.924811149999982, 35.599306815000034],
            [-80.924513637999951, 35.599229113000035],
            [-80.924207106999972, 35.599147755000047],
            [-80.924180627999988, 35.599140802000079],
            [-80.923815823999973, 35.599046037000051],
            [-80.923449611999956, 35.598949464000043],
            [-80.923215729999981, 35.59888701400007],
            [-80.922894403999976, 35.598800418000053],
            [-80.922183762999964, 35.598609855000063],
            [-80.921300319999943, 35.598373884000068],
            [-80.920950779999941, 35.598279747000049],
            [-80.920611856999983, 35.598187280000047],
            [-80.920522372999983, 35.598162663000039],
            [-80.920304836999946, 35.598100958000032],
            [-80.920268408999959, 35.598090769000066],
            [-80.920087577999936, 35.598040905000062],
            [-80.92005616199998, 35.59803234900005],
            [-80.919874881999988, 35.59798358900008],
            [-80.919843297999989, 35.597975200000064],
            [-80.919661576999943, 35.59792754800003],
            [-80.919641557999967, 35.597922341000071],
            [-80.919593661999954, 35.597909986000047],
            [-80.919482339999945, 35.597882571000071],
            [-80.919453578999935, 35.597875823000038],
            [-80.919418546999964, 35.597867730000075],
            [-80.91939410699996, 35.597862233000058],
            [-80.919336360999978, 35.597849391000068],
            [-80.91926360399998, 35.597833750000063],
            [-80.919215265999981, 35.597823715000061],
            [-80.919148471999961, 35.59781029800007],
            [-80.919099947999939, 35.597800876000065],
            [-80.919033683999942, 35.597788446000038],
            [-80.918986064999956, 35.597779827000068],
            [-80.918915143999982, 35.59776748400003],
            [-80.91886010199994, 35.59775828800008],
            [-80.918826212999988, 35.597752738000054],
            [-80.918790197999954, 35.597747086000027],
            [-80.918742981999969, 35.597739840000031],
            [-80.918658095999945, 35.597727508000048],
            [-80.918581856999936, 35.597717055000032],
            [-80.918475966999949, 35.597703609000064],
            [-80.918398135999951, 35.597694509000064],
            [-80.918297483999936, 35.597683699000072],
            [-80.918222789999959, 35.597676392000039],
            [-80.918128320999983, 35.597668088000034],
            [-80.918050062999953, 35.597661902000027],
            [-80.917865773999949, 35.597650491000024],
            [-80.91782589099995, 35.597648703000061],
            [-80.91775494999996, 35.597645986000032],
            [-80.91765763799998, 35.597643768000069],
            [-80.917617480999979, 35.597643213000026],
            [-80.917472009999983, 35.597643146000053],
            [-80.91743185699994, 35.597643665000078],
            [-80.917330288999949, 35.59764592700003],
            [-80.917268045999947, 35.597648254000035],
            [-80.917217917999949, 35.597650415000032],
            [-80.917036276999966, 35.597661301000073],
            [-80.916986297999983, 35.597665140000061],
            [-80.916886864999981, 35.59767370000003],
            [-80.916804984999942, 35.597682141000064],
            [-80.916755241999965, 35.597687653000037],
            [-80.916571724999983, 35.597711193000066],
            [-80.91652260799998, 35.59771835600003],
            [-80.916449041999954, 35.597729608000066],
            [-80.916370386999972, 35.597742200000027],
            [-80.916314193999938, 35.59775151100007],
            [-80.916216918999964, 35.597768163000069],
            [-80.916156883999975, 35.597778794000078],
            [-80.916069594999954, 35.597794769000075],
            [-80.916013113999952, 35.597805423000068],
            [-80.915926089999971, 35.597822327000074],
            [-80.915866538999978, 35.597834250000062],
            [-80.915770174999977, 35.597854120000079],
            [-80.91558260499994, 35.597896389000027],
            [-80.915397482999936, 35.597945328000037],
            [-80.915215161999981, 35.598000842000033],
            [-80.914413237999952, 35.59826150300006],
            [-80.914087457999983, 35.598365977000071],
            [-80.913736601999972, 35.598479560000044],
            [-80.913430861999984, 35.598579442000073],
            [-80.912966966999988, 35.598732063000057],
            [-80.912616559999947, 35.598846197000057],
            [-80.912200813999959, 35.598980151000035],
            [-80.911776994999968, 35.599115104000077],
            [-80.911731879999934, 35.599129701000038],
            [-80.911197624999943, 35.599305335000054],
            [-80.910836352999979, 35.599424098000043],
            [-80.909978047999971, 35.599705297000071],
            [-80.909400135999988, 35.59988973600008],
            [-80.908790446999944, 35.600085918000048],
            [-80.908445364999977, 35.600197673000025],
            [-80.907231968999952, 35.600592683000059],
            [-80.906704537999985, 35.600765166000031],
            [-80.906547480999961, 35.600815900000043],
            [-80.906381554999939, 35.600870109000027],
            [-80.906359677999944, 35.600877314000059],
            [-80.906110138999964, 35.60096012300005],
            [-80.906086913999957, 35.600967893000075],
            [-80.905785041999934, 35.601069671000062],
            [-80.905708453999978, 35.601044000000059],
            [-80.905554796999979, 35.600988344000029],
            [-80.905140615999983, 35.600836775000062],
            [-80.90511451399999, 35.600827306000042],
            [-80.904746956999986, 35.600695109000071],
            [-80.90472101499995, 35.600685859000066],
            [-80.904380080999942, 35.600565349000078],
            [-80.904355076999934, 35.600556586000039],
            [-80.904210018999947, 35.600506172000053],
            [-80.904056275999949, 35.600452740000037],
            [-80.903788526999961, 35.600354190000076],
            [-80.903768806999949, 35.600346979000051],
            [-80.903448544999947, 35.600230629000066],
            [-80.903426231999958, 35.600222582000072],
            [-80.903034949999949, 35.600082526000051],
            [-80.902861228999939, 35.600020045000065],
            [-80.902177569999935, 35.59977415700007],
            [-80.901516685999979, 35.59953758100005],
            [-80.901313209999955, 35.599463062000041],
            [-80.901289147999989, 35.599454319000074],
            [-80.901058667999962, 35.599371248000068],
            [-80.901035548999971, 35.599362980000024],
            [-80.900832221999963, 35.599290820000078],
            [-80.900810458999956, 35.599283152000055],
            [-80.900606662999962, 35.599211878000062],
            [-80.900584968999965, 35.599204347000068],
            [-80.900380714999983, 35.599133959000028],
            [-80.900359351999953, 35.599126650000073],
            [-80.900316751999981, 35.599112183000045],
            [-80.89990807099997, 35.598943236000025],
            [-80.899822522999955, 35.598906948000035],
            [-80.899674669999968, 35.59884349500004],
            [-80.899528667999959, 35.598779970000066],
            [-80.899383136999973, 35.598715783000046],
            [-80.899235661999967, 35.598649877000071],
            [-80.899219038999945, 35.598642485000028],
            [-80.898902433999979, 35.598502435000057],
            [-80.898578879999945, 35.598360416000048],
            [-80.898254771999973, 35.598219267000047],
            [-80.89798327799997, 35.598101949000068],
            [-80.897915528999988, 35.598070967000069],
            [-80.89789245999998, 35.598060491000069],
            [-80.897858926999959, 35.598045523000053],
            [-80.897797094999987, 35.598018204000027],
            [-80.897757017999936, 35.598000713000033],
            [-80.897694846999968, 35.597973912000043],
            [-80.89763757999998, 35.597949658000061],
            [-80.897521246999986, 35.597901260000071],
            [-80.897446029999969, 35.59787069600003],
            [-80.897328597999945, 35.597824105000029],
            [-80.897252527999967, 35.597794649000036],
            [-80.897134033999976, 35.597749884000052],
            [-80.897108515999946, 35.597740323000039],
            [-80.897057506999943, 35.597721687000046],
            [-80.896938000999967, 35.597678761000054],
            [-80.896872610999935, 35.597655782000061],
            [-80.896789300999956, 35.597627149000061],
            [-80.896732461999989, 35.597607992000064],
            [-80.896681365999939, 35.597591107000028],
            [-80.896463494999978, 35.597514852000074],
            [-80.896428863999972, 35.597502873000053],
            [-80.896274391999953, 35.597450067000068],
            [-80.896159169999976, 35.597410678000074],
            [-80.896124377999968, 35.597398926000039],
            [-80.895863052999971, 35.597311710000042],
            [-80.895833018999951, 35.597301791000064],
            [-80.895531477999953, 35.597203243000024],
            [-80.89515832099994, 35.597078683000063],
            [-80.89508237299998, 35.597053331000041],
            [-80.894638155999985, 35.596903180000027],
            [-80.89437533499995, 35.596813455000074],
            [-80.894114636999973, 35.596723803000032],
            [-80.894073013999957, 35.596709692000047],
            [-80.894011802999955, 35.596689244000061],
            [-80.89366231799994, 35.596573335000073],
            [-80.893643149999946, 35.596567020000066],
            [-80.893298505999951, 35.596454246000064],
            [-80.893279328999938, 35.596448013000042],
            [-80.892934048999962, 35.596336549000057],
            [-80.892914876999953, 35.596330401000046],
            [-80.892609448999963, 35.596233135000034],
            [-80.892354221999938, 35.596147116000054],
            [-80.891782307999961, 35.595953765000047],
            [-80.891088216999947, 35.595719102000032],
            [-80.88968078299996, 35.595226449000052],
            [-80.889659997999956, 35.595219224000061],
            [-80.889345362999961, 35.59511064700007],
            [-80.889039512999943, 35.595006251000029],
            [-80.889020952999942, 35.594999958000074],
            [-80.888630522999961, 35.594868420000068],
            [-80.888382281999952, 35.594785735000073],
            [-80.888145577999978, 35.594707601000039],
            [-80.887787537999941, 35.594587319000027],
            [-80.887102698999968, 35.594356248000054],
            [-80.886706665999952, 35.594221889000039],
            [-80.886410568999963, 35.594121449000056],
            [-80.886190425999985, 35.594047569000054],
            [-80.886052897999946, 35.594000712000025],
            [-80.885922921999963, 35.593955881000056],
            [-80.88576664499999, 35.593901284000026],
            [-80.885610665999934, 35.593846050000025],
            [-80.885537915999976, 35.593820913000059],
            [-80.885500883999953, 35.593808434000039],
            [-80.885468211999978, 35.593797548000055],
            [-80.885347468999953, 35.593757774000039],
            [-80.885297295999976, 35.593741536000039],
            [-80.885175970999967, 35.593702963000055],
            [-80.885125584999969, 35.593687231000047],
            [-80.885003693999977, 35.593649864000042],
            [-80.884958436999966, 35.593636224000079],
            [-80.884830638999972, 35.593598480000026],
            [-80.884789560999934, 35.593586535000043],
            [-80.88471395199997, 35.593564892000074],
            [-80.884680551999963, 35.593555454000068],
            [-80.884595254999965, 35.593531658000074],
            [-80.884546105999959, 35.593518207000045],
            [-80.88439387599999, 35.593477354000072],
            [-80.884348529999954, 35.593465405000074],
            [-80.884281697999938, 35.593448116000047],
            [-80.884176562999983, 35.593422083000064],
            [-80.884109938999984, 35.593406318000063],
            [-80.884065221999947, 35.593395944000065],
            [-80.883996693999961, 35.593380385000046],
            [-80.883950250999987, 35.593370062000076],
            [-80.883883225999966, 35.59335548200005],
            [-80.883836876999965, 35.593345618000058],
            [-80.883769660999974, 35.593331629000033],
            [-80.883722654999985, 35.593322070000056],
            [-80.883655259999955, 35.593308681000053],
            [-80.883608165999988, 35.593299548000061],
            [-80.883540298999947, 35.593286704000036],
            [-80.883472714999982, 35.593274367000049],
            [-80.883396093999977, 35.593260891000057],
            [-80.883346236999955, 35.593252122000024],
            [-80.883278527999948, 35.593240662000028],
            [-80.883205391999979, 35.593228772000032],
            [-80.883162661999961, 35.593222038000079],
            [-80.883094141999948, 35.59321152800004],
            [-80.88303981699994, 35.593203480000057],
            [-80.882951522999974, 35.593190862000029],
            [-80.882886673999963, 35.593181999000024],
            [-80.882788326999957, 35.593169167000042],
            [-80.882730292999952, 35.593161916000042],
            [-80.882661309999946, 35.593153678000078],
            [-80.882610067999963, 35.593147808000026],
            [-80.882576534999941, 35.593144129000052],
            [-80.882277235999936, 35.593106143000057],
            [-80.881926630999942, 35.59306243900005],
            [-80.881596895999962, 35.593022071000064],
            [-80.881180926999946, 35.592971921000071],
            [-80.880888605999985, 35.592935794000027],
            [-80.880558168999983, 35.592893812000057],
            [-80.880230336999944, 35.592850959000032],
            [-80.879867556999955, 35.592801990000055],
            [-80.879734911999947, 35.592779781000047],
            [-80.879699336999977, 35.592773949000048],
            [-80.879471341999988, 35.592737428000078],
            [-80.87943417799994, 35.592731609000055],
            [-80.879217274999974, 35.592698431000031],
            [-80.87917999299998, 35.592692862000035],
            [-80.878952876999961, 35.592659757000035],
            [-80.878917251999951, 35.592654687000049],
            [-80.878719477999937, 35.59262721500005],
            [-80.878687063999962, 35.592622812000059],
            [-80.878498948999948, 35.592597850000061],
            [-80.878466554999989, 35.592593651000072],
            [-80.878268624999976, 35.592568610000058],
            [-80.878235539999935, 35.592564529000072],
            [-80.878062154999952, 35.592543685000066],
            [-80.877944689999936, 35.592528584000036],
            [-80.877905245999955, 35.592523662000076],
            [-80.877765734999969, 35.592506775000061],
            [-80.877727745999948, 35.592502313000068],
            [-80.877598037999974, 35.592487547000076],
            [-80.877559893999944, 35.592483343000026],
            [-80.877420044999951, 35.592468431000043],
            [-80.877380544999937, 35.592464366000058],
            [-80.877231696999957, 35.592449616000067],
            [-80.877200949999974, 35.59244669800006],
            [-80.877060796999956, 35.592433838000034],
            [-80.877021135999939, 35.592430346000071],
            [-80.876874467999983, 35.592418000000066],
            [-80.876643186999956, 35.592386168000075],
            [-80.876621887999988, 35.592383246000054],
            [-80.876244131999954, 35.592332187000068],
            [-80.876222928999937, 35.592329364000079],
            [-80.875834924999936, 35.592278492000048],
            [-80.875813350999977, 35.592275708000045],
            [-80.875425047999954, 35.592226395000068],
            [-80.875259338999967, 35.59220795300007],
            [-80.875008001999959, 35.592183968000029],
            [-80.874778488999937, 35.592160308000075],
            [-80.874549388999981, 35.592134930000043],
            [-80.874316424999961, 35.592107327000065],
            [-80.874181890999978, 35.592090559000042],
            [-80.873648933999959, 35.592022328000041],
            [-80.873043729999949, 35.591945840000051],
            [-80.872604395999986, 35.591890929000044],
            [-80.872224815999971, 35.591844563000052],
            [-80.871848507999971, 35.591797850000034],
            [-80.871491838999987, 35.59175287000005],
            [-80.870867134999969, 35.591672946000074],
            [-80.870420962999958, 35.591616470000076],
            [-80.869913721999978, 35.591553126000065],
            [-80.869423209999979, 35.591492753000068],
            [-80.869043853999983, 35.591444267000043],
            [-80.86864787199994, 35.591394258000037],
            [-80.868251810999936, 35.591344845000037],
            [-80.867883931999984, 35.591299502000027],
            [-80.867545597999936, 35.591254670000069],
            [-80.867188313999975, 35.591207931000042],
            [-80.866820942999937, 35.591160496000043],
            [-80.866463945999953, 35.591115009000077],
            [-80.866186137999989, 35.591080028000079],
            [-80.865762146999941, 35.59102742400006],
            [-80.864493098999958, 35.590870764000044],
            [-80.864040948999957, 35.590810652000073],
            [-80.864019305999989, 35.590807819000077],
            [-80.863541875999942, 35.590746318000072],
            [-80.863522485999965, 35.590743856000074],
            [-80.863335759999984, 35.590720493000049],
            [-80.863137923999943, 35.59069574800003],
            [-80.862365318999935, 35.590600920000043],
            [-80.862091265999936, 35.590561370000046],
            [-80.861932408999962, 35.59053844500005],
            [-80.861867000999951, 35.590524701000049],
            [-80.861619247999954, 35.590478771000051],
            [-80.861463638999965, 35.590456068000037],
            [-80.860758742999963, 35.590363760000059],
            [-80.860574200999963, 35.59034282600004],
            [-80.860372654999935, 35.590327271000035],
            [-80.860264632999986, 35.590320960000042],
            [-80.860166029999959, 35.590315198000042],
            [-80.86005501599999, 35.590307629000051],
            [-80.859775480999986, 35.590271791000077],
            [-80.85956768799997, 35.590243785000041],
            [-80.859506326999963, 35.590235875000076],
            [-80.859291915999961, 35.590209488000028],
            [-80.859130411999956, 35.590189612000074],
            [-80.858910038999966, 35.590161705000071],
            [-80.858680408999987, 35.590132005000044],
            [-80.858656571999973, 35.590128977000063],
            [-80.858008727999959, 35.590048128000035],
            [-80.857875906999936, 35.590031550000049],
            [-80.857015197999942, 35.589924126000028],
            [-80.85686973299994, 35.589905799000064],
            [-80.85631749099997, 35.589836220000052],
            [-80.855497416999981, 35.589734587000066],
            [-80.855177254999944, 35.589693461000024],
            [-80.854848070999935, 35.589651894000042],
            [-80.854288770999972, 35.589582728000039],
            [-80.854254709999964, 35.589578155000027],
            [-80.85422476399998, 35.589573844000029],
            [-80.854193650999946, 35.589569075000043],
            [-80.854164127999979, 35.589564277000079],
            [-80.854126340999983, 35.589557759000058],
            [-80.854091776999951, 35.589551042000039],
            [-80.853984917999981, 35.589524132000065],
            [-80.85368903799997, 35.589418576000071],
            [-80.853249117999951, 35.589258751000045],
            [-80.85289397799994, 35.589126771000053],
            [-80.852624941999977, 35.589027689000034],
            [-80.852522832999966, 35.588990102000025],
            [-80.852104609999969, 35.58883759400004],
            [-80.851724648999948, 35.58870044400004],
            [-80.851466677999952, 35.588604555000074],
            [-80.85117938999997, 35.588498675000039],
            [-80.850891669999953, 35.588393555000039],
            [-80.850610150999955, 35.588291586000025],
            [-80.850567158999979, 35.588276237000059],
            [-80.850438738999969, 35.588231047000079],
            [-80.850402720999966, 35.588218525000059],
            [-80.850273817999948, 35.588174261000063],
            [-80.850237866999976, 35.588162067000042],
            [-80.850108492999937, 35.58811872800004],
            [-80.850073626999972, 35.588107188000038],
            [-80.849953081999956, 35.588067778000038],
            [-80.849922066999966, 35.58805774800004],
            [-80.849818381999967, 35.588024585000028],
            [-80.849721280999972, 35.58799459200003],
            [-80.849688843999957, 35.587985042000071],
            [-80.849470720999989, 35.587921602000051],
            [-80.84938604599995, 35.587895593000042],
            [-80.849345018999941, 35.587883179000073],
            [-80.84930615899998, 35.587871764000056],
            [-80.849193413999956, 35.587839132000056],
            [-80.849113257999988, 35.587816634000035],
            [-80.848990316999959, 35.587783194000053],
            [-80.848899449999976, 35.587762647000034],
            [-80.848794331999954, 35.587735492000036],
            [-80.84871968799996, 35.587716560000047],
            [-80.848614965999957, 35.587690111000029],
            [-80.848562124999944, 35.587676778000059],
            [-80.848540064999952, 35.587672257000065],
            [-80.848446527999954, 35.587653034000027],
            [-80.848060644999975, 35.587561084000072],
            [-80.847764389999952, 35.587491296000053],
            [-80.847471055999961, 35.587421494000068],
            [-80.847414440999955, 35.587408363000065],
            [-80.847030552999968, 35.587322184000072],
            [-80.846668357999988, 35.587239820000036],
            [-80.846322620999956, 35.587160202000064],
            [-80.845980275999977, 35.587080412000034],
            [-80.845958899999971, 35.587075478000031],
            [-80.845229262999965, 35.586908639000058],
            [-80.844243788999961, 35.586681308000038],
            [-80.843430443999978, 35.586495223000043],
            [-80.842643085999953, 35.58631616100007],
            [-80.842575585999953, 35.586299933000078],
            [-80.842361515999983, 35.58624846400005],
            [-80.84233549999999, 35.586242278000043],
            [-80.842016355999988, 35.586167271000079],
            [-80.841994664999959, 35.586162222000041],
            [-80.841791279999939, 35.586115333000066],
            [-80.841776986999946, 35.586112060000062],
            [-80.841562329999988, 35.586063331000048],
            [-80.841376269999955, 35.586021715000072],
            [-80.841341808999971, 35.586014024000065],
            [-80.841203856999982, 35.58598363200008],
            [-80.841095631999963, 35.585956913000075],
            [-80.841058815999986, 35.585947966000049],
            [-80.840886006999938, 35.585906631000057],
            [-80.840849082999966, 35.585897941000042],
            [-80.840667610999958, 35.585855939000055],
            [-80.840638785999943, 35.585849392000057],
            [-80.84052111799997, 35.585823045000041],
            [-80.840404011999965, 35.585794116000045],
            [-80.840354698999988, 35.585782188000053],
            [-80.840191100999959, 35.585743456000046],
            [-80.840145523999979, 35.585732883000048],
            [-80.839967757999943, 35.585692614000038],
            [-80.839916702999972, 35.585681316000034],
            [-80.839742348999948, 35.585643644000072],
            [-80.839691073999973, 35.585632832000044],
            [-80.839516180999965, 35.585596861000056],
            [-80.839466245999972, 35.585586841000065],
            [-80.839300600999934, 35.585554434000073],
            [-80.839250472999936, 35.585544877000075],
            [-80.839074608999965, 35.585512226000048],
            [-80.839022936999982, 35.585502897000026],
            [-80.838853030999985, 35.585473087000025],
            [-80.838761226999964, 35.58545674800007],
            [-80.838723060999939, 35.585450097000034],
            [-80.838634496999987, 35.585435000000075],
            [-80.838596233999965, 35.585428620000073],
            [-80.838492977999977, 35.585411795000027],
            [-80.83845729899997, 35.585406134000038],
            [-80.838358540999934, 35.585390812000071],
            [-80.83831810099997, 35.585384697000052],
            [-80.83821917399996, 35.585370123000075],
            [-80.838178789999972, 35.585364331000051],
            [-80.838079697999945, 35.585350504000075],
            [-80.838038998999934, 35.585344983000027],
            [-80.837939756999958, 35.585331909000047],
            [-80.837901234999947, 35.585326975000044],
            [-80.837811794999936, 35.585315850000029],
            [-80.837775195999939, 35.585311425000043],
            [-80.837685645999954, 35.585300909000068],
            [-80.837646875999951, 35.585296498000048],
            [-80.837547251999979, 35.585285529000032],
            [-80.83751270199997, 35.585281839000061],
            [-80.837442892999945, 35.585274609000066],
            [-80.837401918999944, 35.585270523000077],
            [-80.837290109999969, 35.585259842000028],
            [-80.83726369599998, 35.585257383000055],
            [-80.837143635999951, 35.585246509000058],
            [-80.837117464999949, 35.585244204000048],
            [-80.837007337999978, 35.585234769000067],
            [-80.836980907999987, 35.585232569000027],
            [-80.836860694999984, 35.585222864000059],
            [-80.836833272999968, 35.585220720000052],
            [-80.836713748999955, 35.585211682000079],
            [-80.836316282999974, 35.585181526000042],
            [-80.83589912299999, 35.585151240000073],
            [-80.835502119999944, 35.585123716000055],
            [-80.835132211999962, 35.585099236000076],
            [-80.834746612999936, 35.585071091000032],
            [-80.834348685999942, 35.58504262200006],
            [-80.833950651999942, 35.585014716000046],
            [-80.833567524999978, 35.584988414000065],
            [-80.833166695999978, 35.584956257000044],
            [-80.833080099999961, 35.584950009000067],
            [-80.832858243999965, 35.584935786000074],
            [-80.832645272999969, 35.584921458000053],
            [-80.832431831999941, 35.584906424000053],
            [-80.832229009999935, 35.584891510000034],
            [-80.831987405999939, 35.584872946000075],
            [-80.831756246999987, 35.584854371000063],
            [-80.831593121999958, 35.584840683000039],
            [-80.831515082999942, 35.584831372000053],
            [-80.831408354999951, 35.584817764000036],
            [-80.831301588999963, 35.584803299000043],
            [-80.831181838999953, 35.584786088000044],
            [-80.831149760999949, 35.584781263000025],
            [-80.830969176999986, 35.58475132500007],
            [-80.830729199999951, 35.584712187000036],
            [-80.830498554999963, 35.584675209000068],
            [-80.830329653999968, 35.584648585000025],
            [-80.830297160999976, 35.584642633000044],
            [-80.83019933199995, 35.584625653000046],
            [-80.830094297999949, 35.584608442000047],
            [-80.830012874999966, 35.584595842000056],
            [-80.829934118999972, 35.584584280000058],
            [-80.829860088999965, 35.584573939000052],
            [-80.829790978999938, 35.584564776000036],
            [-80.829721913999947, 35.58455607500008],
            [-80.829652633999956, 35.584547801000042],
            [-80.829578222999942, 35.584539439000025],
            [-80.829498863999959, 35.584531080000033],
            [-80.82945071599994, 35.584526226000037],
            [-80.829264418999969, 35.584511535000047],
            [-80.829214258999968, 35.584508450000044],
            [-80.829125243999954, 35.584503708000057],
            [-80.829075021999984, 35.584501446000047],
            [-80.829007099999956, 35.584498814000028],
            [-80.828985911999951, 35.58449816600006],
            [-80.828935644999945, 35.58449672900008],
            [-80.828837889999988, 35.584494815000028],
            [-80.828777535999961, 35.584494177000067],
            [-80.828679342999976, 35.584494025000026],
            [-80.828629046999936, 35.584494401000029],
            [-80.828539944999989, 35.584495799000024],
            [-80.828489666999985, 35.584497000000056],
            [-80.828451609999945, 35.584498042000064],
            [-80.828400791999968, 35.584499851000032],
            [-80.82835055399994, 35.584501874000068],
            [-80.828252447999944, 35.584506716000078],
            [-80.828192244999968, 35.584510234000049],
            [-80.828094527999951, 35.584516830000041],
            [-80.828044446999968, 35.58452066600006],
            [-80.827964861999988, 35.584527353000055],
            [-80.82791634199998, 35.58453180500004],
            [-80.827818653999941, 35.584541787000035],
            [-80.827738918999955, 35.58455067500006],
            [-80.827514559999941, 35.584580481000046],
            [-80.827221169999973, 35.584625783000035],
            [-80.827073680999945, 35.584650679000049],
            [-80.826927311999953, 35.584679649000066],
            [-80.826782227999956, 35.584712656000079],
            [-80.826705173999983, 35.584731342000055],
            [-80.826580434999983, 35.584763233000047],
            [-80.826504087999979, 35.584783764000065],
            [-80.826380736999965, 35.584818584000061],
            [-80.826303803999963, 35.584841341000072],
            [-80.826182736999954, 35.584878829000047],
            [-80.826108000999966, 35.58490299500005],
            [-80.82598736999995, 35.584943685000042],
            [-80.825913536999963, 35.584969634000061],
            [-80.825835021999978, 35.584997967000049],
            [-80.825794248999955, 35.585013269000058],
            [-80.825721382999973, 35.585040982000066],
            [-80.825603755999964, 35.585087452000039],
            [-80.825531941999941, 35.585116895000056],
            [-80.825415559999954, 35.585166383000058],
            [-80.825344909999956, 35.58519751800003],
            [-80.825311860999989, 35.585212230000025],
            [-80.82523810899994, 35.585246145000042],
            [-80.825177227999973, 35.58527476900008],
            [-80.825106958999982, 35.585308514000076],
            [-80.825081148999971, 35.585321170000043],
            [-80.824967628999957, 35.585378750000075],
            [-80.824950764999983, 35.585387594000053],
            [-80.824905485999977, 35.585411668000063],
            [-80.824822439999934, 35.585456581000074],
            [-80.824731998999937, 35.585506835000047],
            [-80.824649851999936, 35.585553700000048],
            [-80.824565606999954, 35.585602965000078],
            [-80.824500976999957, 35.585641699000064],
            [-80.824422661999961, 35.585689731000059],
            [-80.824345355999981, 35.585738253000045],
            [-80.824269755999978, 35.585786925000036],
            [-80.824199541999974, 35.585833151000031],
            [-80.824117631999968, 35.58588840300007],
            [-80.822639934999984, 35.586848393000025],
            [-80.822279886999979, 35.587078696000049],
            [-80.822263607999957, 35.587089158000026],
            [-80.821891920999974, 35.587329196000042],
            [-80.821876389999943, 35.587339273000055],
            [-80.821537538999962, 35.587560120000035],
            [-80.821522742999946, 35.587569804000054],
            [-80.821200171999976, 35.587781886000073],
            [-80.820376980999981, 35.588313438000057],
            [-80.820198180999967, 35.588429124000072],
            [-80.81951870599994, 35.588868750000074],
            [-80.819158024999979, 35.589099049000026],
            [-80.818779279999944, 35.589342920000036],
            [-80.818764118999979, 35.589352728000051],
            [-80.818370293999976, 35.589608608000049],
            [-80.818354898999985, 35.589618655000038],
            [-80.817978834999963, 35.589865226000029],
            [-80.817247339999938, 35.590339998000047],
            [-80.816766539999946, 35.590651274000038],
            [-80.816365575999953, 35.590908986000045],
            [-80.815955817999964, 35.591173188000027],
            [-80.815368689999957, 35.591553220000037],
            [-80.815091677999987, 35.591733929000043],
            [-80.814718391999975, 35.59197746500007],
            [-80.814059375999989, 35.592406636000078],
            [-80.813948701999948, 35.592481129000078],
            [-80.813889017999941, 35.592522642000063],
            [-80.813836784999978, 35.592559547000064],
            [-80.813785258999985, 35.592596529000048],
            [-80.813734951999947, 35.59263319300004],
            [-80.813675802999967, 35.592676993000055],
            [-80.813655229999938, 35.592692482000075],
            [-80.813600810999958, 35.592729954000049],
            [-80.813559540999961, 35.592758725000067],
            [-80.813415163999935, 35.592860628000039],
            [-80.813374327999952, 35.592889809000042],
            [-80.813239000999943, 35.592987710000045],
            [-80.813200694999978, 35.593015748000028],
            [-80.81305727299997, 35.593122086000051],
            [-80.813016207999965, 35.59315292000008],
            [-80.812876543999948, 35.593259119000038],
            [-80.812834936999934, 35.593291168000064],
            [-80.812738319999937, 35.593366553000067],
            [-80.812715885999978, 35.593383042000028],
            [-80.812694204999957, 35.593399116000057],
            [-80.812635442999976, 35.593442995000032],
            [-80.812597123999979, 35.593471947000069],
            [-80.812480870999934, 35.593560826000044],
            [-80.812439738999956, 35.593592676000071],
            [-80.812367954999957, 35.593648978000033],
            [-80.81233816699995, 35.593672561000062],
            [-80.812274141999978, 35.593723725000075],
            [-80.812246328999947, 35.593746147000047],
            [-80.81218285999995, 35.593797768000059],
            [-80.812153584999976, 35.593821800000057],
            [-80.812083722999944, 35.59387969200003],
            [-80.812047559999939, 35.593910011000048],
            [-80.811952367999936, 35.59399075500005],
            [-80.811921497999947, 35.594017210000061],
            [-80.811857928999984, 35.594072336000067],
            [-80.811831498999936, 35.594095459000073],
            [-80.811800374999962, 35.594122928000047],
            [-80.811703903999955, 35.594204879000074],
            [-80.811676750999936, 35.594228152000028],
            [-80.81162438399997, 35.594273480000027],
            [-80.811557426999968, 35.594331442000055],
            [-80.811525656999947, 35.59435923500007],
            [-80.811375578999957, 35.594491910000045],
            [-80.811340198999972, 35.594523562000063],
            [-80.81119214499995, 35.594657591000043],
            [-80.811165956999957, 35.594681510000044],
            [-80.811092658999939, 35.594749056000069],
            [-80.811074574999964, 35.594765825000025],
            [-80.810995168999966, 35.59483991500008],
            [-80.810976492999941, 35.594857453000031],
            [-80.810898144999953, 35.594931508000059],
            [-80.810879615999966, 35.594949135000036],
            [-80.810801850999951, 35.595023598000068],
            [-80.810783509999965, 35.595041274000039],
            [-80.81070632899997, 35.595116141000062],
            [-80.810692335999988, 35.595129780000036],
            [-80.810545094999952, 35.595274036000035],
            [-80.810524295999983, 35.595294567000053],
            [-80.810378199999946, 35.595439857000031],
            [-80.810352129999956, 35.595466036000062],
            [-80.810209783999937, 35.595610773000033],
            [-80.810180311999943, 35.595641074000071],
            [-80.810044271999971, 35.595782476000068],
            [-80.810014572999989, 35.595813695000061],
            [-80.809874326999989, 35.59596278500004],
            [-80.809843914999988, 35.595995497000047],
            [-80.809718052999983, 35.596132478000072],
            [-80.80968865899996, 35.596164841000075],
            [-80.809539990999951, 35.596330444000046],
            [-80.809513380999988, 35.596360405000041],
            [-80.809499917999972, 35.596375728000055],
            [-80.809366781999984, 35.596527249000076],
            [-80.809341117999963, 35.596556767000038],
            [-80.809202354999968, 35.596718066000051],
            [-80.809177034999948, 35.596747812000046],
            [-80.809034335999968, 35.596917260000055],
            [-80.808976615999939, 35.596987555000055],
            [-80.808827967999946, 35.597173235000071],
            [-80.808813713999939, 35.59719115300004],
            [-80.808671880999952, 35.59737061800007],
            [-80.808657853999989, 35.597388483000032],
            [-80.808511906999968, 35.59757554600003],
            [-80.808497803999956, 35.597593740000036],
            [-80.808353218999969, 35.597781507000036],
            [-80.808325371999956, 35.597818152000059],
            [-80.808070536999935, 35.598158000000069],
            [-80.807805882999958, 35.598512258000028],
            [-80.80775353699994, 35.598584179000056],
            [-80.807728874999952, 35.598619426000027],
            [-80.807696618999955, 35.598666130000026],
            [-80.80758048499996, 35.59881417400004],
            [-80.807558238999945, 35.598842743000034],
            [-80.807387858999959, 35.599061792000043],
            [-80.80719646099999, 35.59930997500004],
            [-80.807016163999947, 35.599545757000044],
            [-80.806957491999981, 35.599624714000072],
            [-80.806817594999984, 35.599818496000069],
            [-80.806797831999972, 35.599845856000059],
            [-80.806644115999973, 35.600056554000048],
            [-80.806488182999942, 35.600268060000076],
            [-80.806347703999961, 35.600456634000068],
            [-80.806236720999948, 35.60059945900008],
            [-80.806094202999986, 35.600784307000026],
            [-80.805952527999978, 35.60096956600006],
            [-80.805817584999943, 35.601147432000062],
            [-80.80549500799998, 35.601575618000027],
            [-80.805320259999974, 35.601808942000048],
            [-80.805130313999939, 35.602064545000076],
            [-80.804868676999945, 35.60241906300007],
            [-80.804702868999982, 35.60264109700006],
            [-80.804591823999942, 35.602788210000028],
            [-80.804576005999934, 35.602808991000074],
            [-80.80448415699999, 35.602929651000068],
            [-80.804370658999972, 35.603077502000076],
            [-80.804282808999972, 35.603190647000076],
            [-80.804271858999982, 35.603203156000063],
            [-80.804259716999979, 35.603216278000048],
            [-80.804239695999968, 35.603236615000071],
            [-80.804221331999941, 35.603254112000059],
            [-80.804191985999978, 35.603280587000029],
            [-80.80417590199994, 35.603293086000065],
            [-80.804160497999987, 35.603302860000042],
            [-80.804146122999953, 35.603311291000068],
            [-80.80412662699996, 35.603321940000058],
            [-80.804111648999935, 35.603329545000065],
            [-80.804094841999984, 35.603337488000079],
            [-80.80400154199998, 35.60336436800003],
            [-80.803831180999964, 35.603410920000044],
            [-80.803660111999989, 35.603456548000054],
            [-80.803515710999989, 35.603494193000074],
            [-80.803347202999987, 35.603537162000066],
            [-80.803305762999969, 35.603547911000078],
            [-80.801558261999958, 35.604008892000024],
            [-80.801194459999977, 35.604102172000069],
            [-80.800807430999953, 35.604202402000055],
            [-80.800420764999956, 35.604303544000061],
            [-80.800049624999986, 35.604401581000047],
            [-80.799342070999955, 35.60458576700006],
            [-80.799285662999978, 35.604599973000063],
            [-80.79922877499996, 35.604613827000037],
            [-80.799171722999972, 35.604627249000032],
            [-80.799114527999961, 35.604640234000044],
            [-80.799057030999961, 35.60465281900008],
            [-80.798999682999977, 35.60466490400006],
            [-80.798942121999971, 35.60467656600008],
            [-80.798883320999948, 35.60468800700005],
            [-80.79883319399994, 35.604697377000036],
            [-80.798774204999972, 35.604707957000073],
            [-80.798718554999937, 35.604717493000066],
            [-80.798687352999934, 35.604722563000053],
            [-80.798631450999949, 35.604731168000058],
            [-80.798573792999946, 35.604739586000051],
            [-80.798516070999938, 35.604747559000032],
            [-80.798458271999948, 35.604755085000079],
            [-80.79840046399994, 35.604762161000053],
            [-80.798342335999962, 35.60476882200004],
            [-80.798284367999941, 35.604775011000072],
            [-80.798226323999984, 35.604780757000071],
            [-80.79816882099999, 35.604786004000061],
            [-80.798121279999975, 35.604789958000026],
            [-80.798062954999978, 35.604794364000043],
            [-80.798005243999967, 35.604798272000039],
            [-80.797946262999972, 35.604801808000047],
            [-80.797896260999948, 35.604804435000062],
            [-80.797837296999944, 35.604807098000038],
            [-80.797779511999977, 35.604809258000046],
            [-80.797721380999974, 35.604810978000046],
            [-80.797658841999976, 35.604812346000074],
            [-80.797593648999964, 35.604813376000038],
            [-80.797559969999952, 35.604813550000074],
            [-80.797533673999965, 35.604813415000024],
            [-80.797505755999964, 35.604813002000071],
            [-80.797477776999983, 35.60481231600005],
            [-80.797441965999951, 35.604811015000053],
            [-80.797421862999954, 35.604810133000058],
            [-80.797394416999964, 35.604808662000039],
            [-80.797367314999974, 35.604806950000068],
            [-80.797338321999973, 35.604804840000043],
            [-80.797311414999967, 35.604802641000049],
            [-80.796983802999989, 35.60477368100004],
            [-80.796650380999949, 35.604745150000042],
            [-80.796398176999958, 35.604724224000051],
            [-80.796148794999965, 35.604704093000066],
            [-80.795244061999938, 35.604628403000049],
            [-80.794129443999964, 35.604536877000044],
            [-80.793562484999939, 35.604488884000034],
            [-80.793366530999947, 35.604471986000078],
            [-80.793215632999988, 35.604458478000026],
            [-80.793157462999943, 35.60445358700008],
            [-80.793037534999939, 35.604444155000067],
            [-80.792987540999945, 35.604440455000031],
            [-80.792886674999977, 35.604433460000052],
            [-80.792579388999968, 35.604409945000043],
            [-80.792431249999936, 35.604397906000031],
            [-80.79227065699996, 35.604384331000063],
            [-80.792054309999969, 35.604365198000039],
            [-80.791811324999969, 35.604342607000035],
            [-80.791548803999945, 35.604318772000056],
            [-80.791097548999971, 35.604278599000054],
            [-80.79051273999994, 35.60422800200007],
            [-80.790014170999939, 35.604186356000071],
            [-80.789876072999959, 35.60417379900008],
            [-80.78978669899999, 35.604164931000071],
            [-80.789693817999989, 35.604155071000037],
            [-80.789601061999974, 35.604144576000067],
            [-80.789505971999972, 35.604133154000067],
            [-80.789480905999937, 35.604129991000036],
            [-80.789369109999939, 35.604115883000077],
            [-80.789275631999942, 35.604103503000033],
            [-80.789186681999979, 35.604091052000058],
            [-80.789086073999954, 35.604076177000024],
            [-80.789020329999971, 35.604065997000077],
            [-80.788955904999966, 35.604055641000059],
            [-80.788736006999954, 35.604018636000035],
            [-80.788599028999954, 35.603994665000073],
            [-80.788468230999968, 35.603970619000052],
            [-80.78832932499995, 35.603943812000068],
            [-80.788190804999942, 35.603915774000029],
            [-80.788066062999974, 35.603889372000026],
            [-80.787920904999964, 35.603857041000026],
            [-80.787666537999939, 35.603799102000039],
            [-80.787415037999949, 35.603740280000068],
            [-80.787164367999935, 35.603680114000042],
            [-80.786996539999961, 35.603638799000066],
            [-80.786964903999944, 35.603629703000024],
            [-80.786843744999942, 35.60359408100004],
            [-80.786722783999949, 35.603557701000057],
            [-80.786614320999945, 35.60352436900007],
            [-80.786571411999944, 35.603510836000055],
            [-80.78643840899997, 35.60346888600003],
            [-80.78626503299995, 35.603412493000064],
            [-80.786125329999948, 35.603365784000061],
            [-80.785975832999952, 35.603314596000075],
            [-80.785925524999982, 35.603297665000071],
            [-80.785765665999975, 35.603244799000038],
            [-80.785621201999959, 35.603196087000072],
            [-80.785449078999989, 35.603136830000039],
            [-80.785286825999947, 35.603079741000045],
            [-80.785116352999978, 35.603018459000054],
            [-80.784950278999986, 35.602957482000079],
            [-80.784242029999973, 35.602693689000034],
            [-80.783647010999971, 35.602470520000054],
            [-80.783334679999939, 35.602352714000062],
            [-80.781736142999989, 35.601747418000059],
            [-80.781711666999968, 35.60173822400003],
            [-80.781675193999945, 35.601724794000063],
            [-80.781460427999946, 35.601646660000029],
            [-80.78142629499996, 35.601634241000056],
            [-80.781194914999958, 35.601549127000055],
            [-80.780955080999945, 35.601459930000033],
            [-80.780773838999949, 35.601391773000046],
            [-80.780715018999956, 35.601369654000052],
            [-80.780537233999951, 35.601302148000059],
            [-80.780343291999941, 35.601227885000071],
            [-80.780308099999957, 35.601214562000052],
            [-80.780026864999968, 35.601109309000037],
            [-80.779756518999989, 35.601006720000044],
            [-80.779460811999968, 35.600892846000079],
            [-80.779154453999979, 35.600773068000024],
            [-80.779113521999989, 35.600757274000046],
            [-80.778901979999944, 35.60067672200006],
            [-80.778150007999955, 35.600391267000077],
            [-80.777304640999944, 35.600071821000029],
            [-80.777131360999988, 35.600004866000063],
            [-80.776953096999989, 35.599934273000031],
            [-80.776767494999945, 35.599858890000064],
            [-80.776592021999988, 35.599785820000079],
            [-80.776408730999947, 35.599707588000058],
            [-80.776237186999936, 35.599632537000048],
            [-80.776105769999958, 35.599572889000058],
            [-80.775976090999961, 35.599512409000056],
            [-80.775846788999957, 35.599450503000071],
            [-80.775717234999945, 35.599386854000045],
            [-80.775602800999934, 35.599329251000029],
            [-80.77549554899997, 35.59927405600007],
            [-80.77538937199995, 35.599218244000042],
            [-80.775319260999936, 35.599180589000071],
            [-80.774540675999958, 35.598731882000038],
            [-80.77435397499994, 35.598614706000035],
            [-80.774108529999978, 35.598458206000032],
            [-80.773946700999943, 35.598353651000025],
            [-80.773774655999944, 35.59824130800007],
            [-80.773739569999975, 35.598218633000045],
            [-80.773220994999974, 35.597886946000074],
            [-80.772699200999966, 35.597551224000028],
            [-80.77248900699999, 35.597415410000053],
            [-80.772331919999942, 35.597312045000024],
            [-80.772301391999974, 35.597292138000057],
            [-80.772127753999939, 35.597179925000034],
            [-80.772097568999982, 35.597160591000033],
            [-80.771922201999985, 35.597049293000055],
            [-80.77189673099997, 35.59703324700007],
            [-80.771707387999982, 35.596914865000031],
            [-80.771662708999941, 35.596887295000045],
            [-80.771615050999969, 35.596858269000052],
            [-80.771543148999967, 35.596818968000036],
            [-80.77148865199996, 35.596787094000035],
            [-80.771417718999942, 35.596746087000042],
            [-80.771377146999953, 35.596722876000058],
            [-80.771311289999971, 35.596685562000062],
            [-80.771262211999954, 35.596658212000079],
            [-80.771177028999944, 35.596614749000025],
            [-80.771126387999971, 35.596589321000067],
            [-80.77104649599994, 35.596549676000052],
            [-80.770995922999987, 35.596524876000046],
            [-80.770973715999958, 35.596514106000029],
            [-80.77092136999994, 35.596490867000057],
            [-80.770878373999949, 35.596471557000029],
            [-80.770821965999971, 35.596445996000057],
            [-80.770784325999955, 35.596424747000071],
            [-80.770717854999987, 35.596395466000047],
            [-80.77068583099998, 35.596381103000056],
            [-80.770603662999974, 35.596344948000024],
            [-80.770559459999959, 35.596325984000032],
            [-80.770490303999964, 35.596296943000027],
            [-80.77044570299995, 35.596278615000074],
            [-80.770375872999978, 35.596250544000043],
            [-80.770330885999954, 35.596232858000064],
            [-80.770260907999955, 35.596205956000063],
            [-80.770209499999964, 35.596186648000071],
            [-80.770144349999953, 35.596162775000039],
            [-80.770098631999986, 35.596146385000054],
            [-80.77002736999998, 35.596121440000047],
            [-80.769981306999966, 35.596105704000024],
            [-80.769909234999943, 35.596081687000037],
            [-80.769862844999977, 35.596066613000062],
            [-80.769819934999987, 35.596053024000071],
            [-80.769297768999934, 35.595875680000063],
            [-80.769268324999985, 35.595864924000068],
            [-80.76921145099999, 35.595843677000062],
            [-80.769146531999979, 35.595818881000071],
            [-80.769082783999977, 35.595793969000056],
            [-80.769019345999936, 35.595768615000054],
            [-80.768956126999967, 35.595742779000034],
            [-80.768892348999941, 35.595716137000068],
            [-80.768848439999942, 35.59569741100006],
            [-80.76834002999999, 35.595473034000065],
            [-80.767827600999965, 35.595248339000079],
            [-80.767774571999951, 35.595224144000042],
            [-80.767728278999982, 35.595203391000041],
            [-80.767649295999945, 35.595168495000053],
            [-80.767597936999948, 35.595146156000055],
            [-80.767527236999967, 35.595115891000034],
            [-80.76747871799995, 35.595095430000072],
            [-80.767407567999953, 35.595065876000035],
            [-80.767358635999983, 35.595045861000074],
            [-80.767287045999979, 35.59501702600005],
            [-80.767237773999966, 35.594997488000047],
            [-80.767165753999961, 35.594969377000041],
            [-80.767134081999984, 35.59495713900003],
            [-80.76709774599999, 35.594943243000046],
            [-80.767058713999972, 35.594928503000062],
            [-80.76702850199996, 35.59491735000006],
            [-80.766991880999967, 35.594903966000061],
            [-80.76692227999996, 35.594879108000043],
            [-80.766885383999977, 35.594866237000076],
            [-80.76681502799994, 35.59484227400003],
            [-80.766777864999938, 35.594829921000041],
            [-80.766707013999962, 35.594806948000041],
            [-80.766669598999954, 35.594795119000025],
            [-80.766598574999989, 35.594773233000069],
            [-80.766557662999958, 35.594760954000037],
            [-80.766489219999983, 35.594740972000068],
            [-80.766451329999938, 35.594730195000068],
            [-80.766379248999954, 35.594710259000067],
            [-80.766341139999952, 35.594700015000058],
            [-80.766268477999972, 35.594681047000051],
            [-80.766230157999985, 35.594671339000058],
            [-80.766157545999988, 35.594653498000071],
            [-80.76611903099996, 35.594644326000036],
            [-80.766073846999973, 35.594633778000059],
            [-80.766045600999973, 35.594627399000046],
            [-80.766006894999975, 35.594618768000032],
            [-80.765933314999984, 35.594602914000063],
            [-80.76589443499995, 35.594594827000037],
            [-80.765820726999948, 35.594580041000029],
            [-80.76578168399999, 35.594572499000037],
            [-80.765707246999966, 35.594558669000037],
            [-80.765668051999967, 35.594551675000048],
            [-80.765584235999938, 35.594537410000044],
            [-80.765534473999935, 35.594529348000037],
            [-80.765450983999983, 35.594516509000073],
            [-80.765411485999948, 35.594510754000055],
            [-80.765154288999952, 35.594479595000053],
            [-80.764921453999989, 35.59446212000006],
            [-80.764871544999949, 35.594459454000059],
            [-80.764794849999987, 35.59445590100006],
            [-80.764744893999989, 35.59445394100004],
            [-80.764711164999937, 35.594452723000074],
            [-80.764660572999958, 35.594451288000073],
            [-80.764600579999978, 35.594449867000037],
            [-80.764516171999958, 35.59444852200005],
            [-80.764466161999962, 35.594448114000045],
            [-80.764389429999937, 35.594448030000024],
            [-80.764339415999984, 35.594448328000055],
            [-80.764254924999989, 35.594449488000066],
            [-80.764194929999974, 35.594450778000066],
            [-80.764146814999947, 35.594452025000066],
            [-80.764102960999935, 35.594453535000071],
            [-80.764043010999956, 35.59445584000008],
            [-80.763958788999958, 35.594459735000044],
            [-80.763908883999989, 35.594462431000068],
            [-80.763824601999943, 35.59446764300003],
            [-80.763764800999979, 35.594471808000037],
            [-80.763680888999943, 35.594478309000067],
            [-80.763631145999966, 35.594482553000034],
            [-80.763584070999968, 35.594486776000053],
            [-80.763547150999955, 35.594490379000035],
            [-80.763487587999975, 35.594496398000047],
            [-80.763404102999971, 35.594505492000053],
            [-80.763354592999974, 35.594511276000048],
            [-80.763270954999939, 35.594521712000073],
            [-80.763211713999965, 35.59452957700006],
            [-80.763128553999934, 35.594541280000044],
            [-80.763079349999941, 35.594548598000074],
            [-80.763026261999983, 35.594556767000029],
            [-80.763003979999951, 35.59456036000006],
            [-80.762954935999971, 35.594568372000026],
            [-80.762872440999956, 35.594582514000024],
            [-80.762813824999967, 35.594593039000074],
            [-80.762583415999984, 35.594639717000064],
            [-80.762432755999953, 35.594676111000069],
            [-80.762324873999944, 35.59470387500005],
            [-80.762236387999963, 35.594726149000053],
            [-80.762147896999977, 35.594747934000054],
            [-80.762058524999986, 35.594769441000039],
            [-80.761499535999974, 35.594900997000025],
            [-80.761301405999973, 35.594946925000045],
            [-80.76110420599997, 35.594992080000054],
            [-80.761041980999948, 35.595006728000044],
            [-80.76068171899999, 35.595093868000049],
            [-80.760328092999941, 35.595178450000049],
            [-80.76021695299994, 35.595204740000042],
            [-80.759982996999952, 35.595260078000024],
            [-80.759573260999957, 35.595355986000072],
            [-80.759524083999963, 35.595367749000047],
            [-80.759399193999968, 35.595398260000024],
            [-80.759362477999957, 35.595407371000078],
            [-80.759228337999957, 35.595441176000065],
            [-80.759190411999953, 35.595450886000037],
            [-80.759146872999963, 35.595462208000072],
            [-80.75905669399998, 35.59548565700004],
            [-80.759018788999981, 35.595495666000033],
            [-80.758885407999969, 35.595531429000062],
            [-80.758851722999964, 35.595540583000059],
            [-80.758747201999938, 35.595569365000074],
            [-80.758667689999982, 35.595591949000038],
            [-80.758621504999951, 35.595605468000031],
            [-80.758602415999974, 35.595611100000042],
            [-80.758467856999971, 35.595651215000032],
            [-80.758431324999947, 35.595662257000072],
            [-80.758299180999984, 35.595702734000042],
            [-80.758264967999935, 35.595713346000025],
            [-80.758152055999972, 35.59574880200006],
            [-80.758120592999944, 35.595758794000062],
            [-80.758008013999984, 35.595794951000073],
            [-80.757976707999944, 35.595805118000044],
            [-80.757892939999977, 35.595832622000046],
            [-80.757864190999953, 35.595842063000077],
            [-80.757833245999961, 35.595852337000053],
            [-80.757721351999976, 35.59588988400003],
            [-80.757690041999979, 35.595900505000031],
            [-80.757578500999955, 35.595938749000027],
            [-80.757548819999954, 35.595949030000043],
            [-80.757446889999983, 35.595984692000059],
            [-80.757417112999974, 35.595995215000073],
            [-80.757306264999954, 35.59603478300005],
            [-80.757275493999941, 35.596045880000077],
            [-80.757165016999977, 35.596086132000039],
            [-80.757143337999935, 35.59609408700004],
            [-80.757079535999935, 35.596118167000043],
            [-80.756997927999976, 35.596149609000065],
            [-80.75696692799994, 35.59616167300004],
            [-80.756885620999981, 35.596193629000027],
            [-80.756854610999937, 35.596205939000072],
            [-80.756773609999982, 35.596238410000069],
            [-80.756742853999981, 35.596250861000044],
            [-80.756662162999987, 35.596283842000048],
            [-80.756631529999936, 35.59629648400005],
            [-80.756551153999965, 35.596329974000071],
            [-80.756520577999936, 35.596342836000076],
            [-80.756440518999966, 35.596376834000068],
            [-80.75641019699998, 35.596389833000046],
            [-80.756330462999983, 35.596424334000062],
            [-80.756300067999973, 35.596437608000031],
            [-80.75622066599999, 35.59647261300006],
            [-80.756190001999983, 35.596486259000073],
            [-80.756100991999972, 35.596526269000037],
            [-80.756069258999958, 35.596540671000071],
            [-80.755990566999969, 35.596576731000027],
            [-80.755960748999939, 35.596590518000028],
            [-80.755882398999972, 35.596627073000036],
            [-80.755852533999985, 35.596641133000048],
            [-80.755774533999954, 35.596678184000041],
            [-80.755646027999944, 35.596741623000071],
            [-80.755578220999951, 35.59677638900007],
            [-80.755501967999976, 35.596816379000074],
            [-80.755414973999962, 35.596863121000069],
            [-80.755341029999954, 35.59690372700004],
            [-80.755258561999938, 35.596950003000075],
            [-80.755189362999943, 35.596989630000053],
            [-80.755116123999983, 35.597032425000066],
            [-80.755061993999959, 35.597064561000025],
            [-80.755013699999949, 35.597093689000076],
            [-80.754967312999952, 35.597122050000053],
            [-80.754911519999951, 35.59715662900004],
            [-80.754881500999943, 35.597175398000047],
            [-80.754862143999958, 35.597187678000068],
            [-80.754806945999974, 35.597222895000073],
            [-80.754761372999951, 35.597252366000077],
            [-80.75471455099995, 35.597283055000048],
            [-80.754669785999965, 35.597312786000032],
            [-80.75461573399997, 35.597349165000026],
            [-80.754571238999972, 35.597379513000078],
            [-80.754525410999975, 35.597411187000034],
            [-80.754481376999934, 35.597442026000067],
            [-80.754428505999954, 35.597479546000045],
            [-80.754381884999987, 35.597513101000061],
            [-80.754329843999983, 35.597551089000035],
            [-80.754310520999979, 35.597565278000047],
            [-80.754274265999982, 35.597592355000074],
            [-80.754201394999939, 35.597647386000062],
            [-80.754137042999957, 35.597696965000068],
            [-80.754065529999934, 35.597753170000033],
            [-80.753964176999943, 35.597835433000057],
            [-80.753915220999943, 35.597876468000038],
            [-80.753876040999955, 35.597909729000037],
            [-80.753818267999975, 35.597959407000076],
            [-80.753782501999979, 35.597990576000029],
            [-80.753734834999989, 35.598032611000065],
            [-80.753696754999964, 35.598066622000033],
            [-80.753643030999967, 35.598115223000036],
            [-80.753605750999952, 35.598149378000073],
            [-80.75355940299994, 35.598192384000072],
            [-80.753524922999986, 35.598224762000029],
            [-80.75347932699998, 35.598268094000048],
            [-80.753445236999937, 35.598300882000046],
            [-80.753400438999961, 35.598344491000034],
            [-80.753367338999965, 35.598377098000071],
            [-80.753323167999952, 35.598421130000077],
            [-80.753306536999958, 35.598437809000075],
            [-80.753287920999981, 35.598456722000037],
            [-80.753238221999936, 35.598507562000066],
            [-80.753203732999964, 35.598543299000028],
            [-80.753160931999957, 35.598588226000061],
            [-80.753129103999981, 35.598622044000024],
            [-80.753086952999979, 35.598667380000052],
            [-80.753055558999961, 35.598701562000031],
            [-80.753014064999945, 35.59874730000007],
            [-80.75298341599995, 35.59878149900004],
            [-80.752942579999967, 35.598827629000027],
            [-80.752907261999951, 35.598868107000044],
            [-80.752872555999943, 35.598908465000079],
            [-80.752843619999965, 35.598942538000074],
            [-80.752798712999947, 35.598996258000057],
            [-80.752766164999969, 35.599035765000053],
            [-80.75273271399999, 35.599076951000029],
            [-80.752700925999989, 35.599116651000031],
            [-80.752664378999953, 35.599162954000064],
            [-80.752631408999946, 35.599205298000072],
            [-80.752599291999957, 35.599247184000035],
            [-80.752571199999977, 35.599284318000059],
            [-80.752539702999968, 35.599326516000076],
            [-80.752509644999975, 35.599367387000029],
            [-80.752473692999956, 35.599417010000025],
            [-80.752448995999941, 35.599451547000058],
            [-80.752414303999956, 35.599500864000049],
            [-80.75238563399995, 35.599542270000029],
            [-80.752351285999964, 35.59959264400004],
            [-80.752323352999952, 35.599634249000076],
            [-80.752294606999953, 35.599677737000036],
            [-80.752267227999937, 35.599719816000061],
            [-80.752234508999948, 35.599770909000028],
            [-80.752207915999975, 35.599813106000056],
            [-80.752180575999944, 35.599857195000027],
            [-80.752154639999958, 35.599899707000077],
            [-80.752123569999981, 35.599951482000051],
            [-80.752108183999951, 35.599977381000031],
            [-80.752088362999984, 35.600011538000047],
            [-80.752029146999973, 35.600114979000068],
            [-80.752007081999977, 35.600154130000078],
            [-80.751970011999958, 35.600220941000032],
            [-80.75195350499996, 35.600251056000047],
            [-80.751917180999953, 35.600318141000059],
            [-80.751901032999967, 35.600348334000046],
            [-80.751865457999941, 35.600415684000041],
            [-80.751849624999977, 35.60044603800003],
            [-80.751814799999977, 35.600513651000028],
            [-80.751795958999935, 35.600550807000047],
            [-80.751746984999954, 35.600648920000026],
            [-80.751719254999955, 35.600705846000039],
            [-80.751699541999983, 35.600748108000062],
            [-80.751643606999949, 35.600870314000076],
            [-80.751578184999971, 35.601023624000049],
            [-80.751541348999979, 35.601116592000039],
            [-80.751519599999938, 35.601173054000071],
            [-80.751479126999982, 35.601281243000074],
            [-80.75146035299997, 35.601333132000036],
            [-80.751424567999948, 35.601434874000063],
            [-80.751405150999972, 35.601491828000064],
            [-80.751373996999973, 35.601586172000054],
            [-80.75135643699997, 35.601641051000058],
            [-80.751327093999976, 35.60173578000007],
            [-80.751304647999973, 35.601811671000064],
            [-80.751282327999945, 35.601890861000072],
            [-80.751271457999962, 35.601930437000078],
            [-80.751265875999934, 35.601951611000061],
            [-80.751241092999976, 35.60204699600007],
            [-80.751225250999937, 35.602110778000053],
            [-80.751204456999972, 35.602198537000049],
            [-80.751190624999936, 35.602259723000031],
            [-80.751171677999935, 35.602347757000075],
            [-80.751158590999978, 35.602411932000052],
            [-80.751140033999945, 35.602508259000047],
            [-80.751128351999967, 35.602572659000032],
            [-80.751113098999951, 35.602662280000061],
            [-80.751103443999966, 35.602722892000031],
            [-80.751088933999938, 35.602820388000055],
            [-80.751081566999972, 35.602873113000044],
            [-80.751067721999959, 35.602979062000031],
            [-80.751061167999978, 35.603033047000054],
            [-80.751049264999949, 35.603139156000054],
            [-80.751043703999983, 35.603193176000048],
            [-80.751033743999983, 35.603299417000073],
            [-80.751029172999949, 35.603353487000049],
            [-80.751020951999976, 35.60346261400008],
            [-80.75101416199999, 35.603591884000025],
            [-80.751007413999957, 35.603812127000026],
            [-80.751006557999972, 35.603846645000033],
            [-80.751002530999983, 35.604058773000077],
            [-80.751002178999954, 35.604082228000038],
            [-80.751001276999943, 35.604163822000032],
            [-80.751001132999988, 35.604211380000038],
            [-80.751002441999958, 35.604473296000037],
            [-80.751002600999982, 35.604492609000033],
            [-80.75100656099994, 35.604836359000046],
            [-80.751006851999989, 35.604855962000045],
            [-80.75101026699997, 35.605044194000072],
            [-80.751011677999941, 35.605121972000063],
            [-80.751031682999951, 35.606414518000065],
            [-80.751033146999987, 35.606509196000047],
            [-80.751037594999957, 35.60688232800004],
            [-80.751040932999956, 35.607335126000066],
            [-80.751035202999958, 35.607573716000047],
            [-80.751034596999943, 35.60759900000005],
            [-80.751027105999981, 35.607885507000049],
            [-80.751019130999964, 35.608054896000056],
            [-80.751009293999971, 35.608248815000024],
            [-80.750994205999973, 35.60852261000008],
            [-80.75097733299998, 35.608802497000056],
            [-80.750949383999966, 35.609225565000031],
            [-80.750920378999979, 35.60965324600005],
            [-80.750918551999973, 35.609676578000062],
            [-80.750916724999968, 35.609692026000062],
            [-80.750912698999969, 35.609715362000031],
            [-80.750900879999961, 35.609768733000067],
            [-80.750894949999974, 35.609793460000049],
            [-80.750890992999985, 35.609809022000036],
            [-80.750884595999935, 35.609832815000061],
            [-80.750879801999986, 35.609849734000079],
            [-80.750874777999968, 35.609866636000049],
            [-80.750868073999982, 35.609888132000037],
            [-80.750859028999969, 35.609915424000064],
            [-80.750846544999945, 35.609948191000058],
            [-80.750833875999945, 35.609978776000048],
            [-80.750828918999957, 35.609990168000024],
            [-80.750819616999934, 35.610011544000031],
            [-80.750804739999978, 35.610044151000068],
            [-80.750788695999972, 35.610077739000076],
            [-80.750775308999948, 35.610104604000071],
            [-80.750756940999963, 35.610139979000053],
            [-80.750733693999962, 35.610183068000026],
            [-80.750724564999985, 35.610199360000024],
            [-80.750713671999961, 35.610217987000055],
            [-80.750703300999987, 35.610234966000064],
            [-80.750692593999986, 35.610251763000065],
            [-80.750681467999982, 35.610268508000047],
            [-80.750670009999965, 35.610285065000028],
            [-80.750658192999936, 35.610301476000075],
            [-80.75064307699995, 35.610321664000026],
            [-80.750618066999948, 35.610353990000078],
            [-80.750591014999941, 35.61038792800008],
            [-80.75056422199998, 35.610420567000062],
            [-80.750530380999976, 35.610460469000031],
            [-80.750512264999941, 35.610479148000024],
            [-80.750486250999984, 35.610504694000042],
            [-80.750459852999938, 35.610529799000062],
            [-80.750431352999954, 35.610556055000075],
            [-80.750382305999949, 35.610599919000038],
            [-80.75036619499997, 35.610613878000038],
            [-80.750348905999942, 35.610628303000055],
            [-80.750321777999943, 35.610649873000057],
            [-80.750235588999942, 35.610712997000064],
            [-80.75021645299995, 35.610726526000064],
            [-80.750196374999973, 35.610739802000069],
            [-80.750151849999952, 35.610766243000057],
            [-80.750052879999942, 35.61082616300007],
            [-80.749871721999966, 35.610939057000053],
            [-80.74970215899998, 35.611043436000045],
            [-80.749465441999973, 35.611187080000036],
            [-80.748260612999957, 35.611910529000056],
            [-80.748106837999956, 35.612002868000047],
            [-80.746822528999985, 35.612776288000077],
            [-80.746197533999975, 35.613151106000032],
            [-80.745607689999986, 35.613506493000045],
            [-80.745469535999973, 35.613589732000037],
            [-80.745193363999988, 35.613755327000035],
            [-80.744970322999961, 35.613888363000058],
            [-80.744947861999947, 35.613900877000049],
            [-80.744900222999945, 35.613926368000079],
            [-80.744836943999985, 35.613958751000041],
            [-80.744808323999962, 35.613972754000031],
            [-80.744753319999973, 35.613998648000063],
            [-80.744724714999961, 35.614011543000061],
            [-80.744655030999979, 35.61404155200006],
            [-80.744592126999976, 35.614066994000041],
            [-80.744558793999943, 35.614078534000043],
            [-80.744538228999943, 35.614085396000064],
            [-80.744508719999942, 35.614094890000047],
            [-80.744456097999944, 35.614110855000035],
            [-80.744432844999949, 35.614117392000026],
            [-80.744405177999965, 35.61412449200003],
            [-80.744380976999935, 35.614130463000038],
            [-80.744351651999978, 35.614137429000039],
            [-80.744315469999947, 35.614145648000033],
            [-80.744295350999948, 35.614150012000039],
            [-80.744202770999948, 35.614169553000067],
            [-80.744177794999985, 35.614174391000063],
            [-80.744154038999966, 35.614178679000076],
            [-80.744134978999966, 35.614181924000036],
            [-80.744104566999965, 35.614186170000039],
            [-80.744063061999952, 35.61418877300008],
            [-80.744011519999958, 35.614193785000055],
            [-80.74397861999995, 35.614196513000024],
            [-80.743945660999941, 35.61419877600008],
            [-80.743910498999981, 35.614200660000051],
            [-80.743849169999976, 35.61420167600005],
            [-80.74374955199994, 35.614202001000024],
            [-80.743703936999964, 35.614201707000063],
            [-80.743667378999987, 35.614201232000028],
            [-80.743624828999941, 35.614200249000078],
            [-80.743599881999955, 35.614198119000037],
            [-80.743574120999938, 35.61419542200008],
            [-80.743406492999952, 35.614174103000039],
            [-80.743218129999946, 35.614149438000027],
            [-80.743030611999984, 35.614124242000059],
            [-80.742997633999948, 35.61411991500006],
            [-80.742483960999948, 35.614054129000067],
            [-80.742124020999938, 35.614006040000049],
            [-80.741731933999972, 35.613954637000063],
            [-80.741265865999935, 35.613894868000045],
            [-80.741175677999934, 35.613882645000047],
            [-80.741104661999941, 35.613873502000047],
            [-80.740995089999956, 35.613860137000074],
            [-80.740930462999984, 35.613852650000069],
            [-80.740840605999949, 35.613842790000035],
            [-80.740785690999985, 35.613837047000061],
            [-80.74069921399996, 35.613828460000036],
            [-80.740653618999943, 35.613824174000058],
            [-80.740573444999939, 35.613816988000053],
            [-80.740518171999952, 35.613812319000033],
            [-80.74042785599994, 35.61380515500008],
            [-80.740372478999973, 35.613801049000074],
            [-80.740292109999984, 35.613795504000052],
            [-80.740236722999953, 35.613791966000065],
            [-80.74014621799995, 35.613786651000055],
            [-80.74009063799997, 35.613783673000057],
            [-80.740010122999934, 35.613779773000033],
            [-80.739954750999971, 35.613777373000062],
            [-80.739864108999939, 35.613773908000042],
            [-80.739836947999947, 35.613772939000057],
            [-80.739802521999934, 35.613771904000032],
            [-80.739681752999957, 35.613768659000073],
            [-80.739593987999967, 35.613767009000071],
            [-80.739473163999946, 35.613765714000067],
            [-80.739389119999942, 35.61376546200006],
            [-80.739278355999943, 35.613765986000033],
            [-80.73920831099997, 35.613766768000062],
            [-80.739059831999953, 35.613769383000033],
            [-80.739015889999962, 35.613770335000027],
            [-80.738890525999977, 35.613773556000069],
            [-80.738866328999961, 35.613774231000036],
            [-80.738826348999964, 35.613775585000042],
            [-80.738708502999941, 35.613780007000059],
            [-80.738645969999936, 35.613782713000035],
            [-80.738527483999974, 35.613788527000054],
            [-80.738464735999969, 35.613791970000079],
            [-80.738346358999934, 35.613799153000059],
            [-80.738277875999984, 35.613803744000052],
            [-80.738159012999972, 35.613812469000038],
            [-80.73808794699994, 35.613818156000036],
            [-80.737968631999934, 35.613828495000064],
            [-80.737930005999942, 35.613831981000033],
            [-80.737902433999977, 35.613834641000039],
            [-80.737783322999974, 35.613846440000032],
            [-80.737717068999984, 35.61385341600004],
            [-80.737598187999936, 35.613866677000033],
            [-80.737532312999974, 35.61387443600006],
            [-80.737413688999936, 35.613889150000034],
            [-80.73734768099996, 35.61389775300006],
            [-80.737229339999942, 35.613913923000041],
            [-80.737114665999968, 35.613930857000071],
            [-80.737083745999939, 35.613935859000037],
            [-80.736896033999983, 35.613966791000053],
            [-80.736869633999959, 35.613971210000045],
            [-80.736721622999937, 35.613996364000059],
            [-80.736699063999936, 35.614000248000025],
            [-80.736561071999972, 35.614024311000037],
            [-80.736539319999963, 35.614028150000024],
            [-80.736401477999948, 35.61405277700004],
            [-80.736379742999986, 35.614056706000042],
            [-80.736242053999945, 35.614081896000073],
            [-80.736216485999989, 35.61408663900005],
            [-80.73605033299998, 35.614117879000048],
            [-80.736035945999959, 35.614120582000055],
            [-80.735616365999988, 35.614198499000054],
            [-80.735366933999956, 35.61424482700005],
            [-80.734761205999973, 35.614358952000032],
            [-80.733917277999979, 35.614514796000037],
            [-80.73373709699996, 35.614548070000069],
            [-80.731750197999986, 35.614916866000044],
            [-80.731646307999938, 35.614936158000035],
            [-80.731354498999963, 35.614991090000046],
            [-80.731334024999967, 35.614994987000046],
            [-80.730980513999953, 35.615062985000066],
            [-80.730957565999972, 35.615067451000073],
            [-80.730594694999979, 35.615138912000077],
            [-80.730574028999968, 35.615143024000076],
            [-80.73028994699996, 35.615200144000028],
            [-80.730267623999964, 35.615204683000059],
            [-80.72986567199996, 35.615287307000074],
            [-80.729371583999978, 35.615387074000068],
            [-80.728854598999988, 35.615490192000038],
            [-80.728321366999978, 35.615595207000069],
            [-80.728258456999981, 35.615607994000072],
            [-80.72800416299998, 35.615661292000027],
            [-80.72714319399995, 35.615833889000044],
            [-80.725635098999987, 35.616140746000042],
            [-80.724733233999984, 35.61632245800007],
            [-80.72384531199998, 35.616500742000028],
            [-80.723825768999973, 35.616504704000079],
            [-80.723135403999947, 35.616646028000048],
            [-80.722468461999938, 35.616781421000042],
            [-80.721733216999951, 35.616929375000041],
            [-80.72102644499995, 35.617070292000051],
            [-80.720464762999939, 35.617183901000033],
            [-80.71992256599998, 35.617292946000077],
            [-80.719745358999944, 35.617328307000037],
            [-80.718967758999952, 35.617483483000058],
            [-80.718101383999965, 35.617659497000034],
            [-80.718084438999938, 35.617662954000025],
            [-80.717073266999989, 35.61787083400003],
            [-80.716724048999936, 35.617941998000049],
            [-80.716308544999947, 35.618025480000028],
            [-80.715877929999976, 35.618110675000025],
            [-80.71499804299998, 35.618287485000053],
            [-80.711468135999951, 35.619001652000065],
            [-80.710964796999974, 35.619105203000061],
            [-80.710355401999948, 35.619228578000047],
            [-80.710179715999971, 35.619263364000062],
            [-80.710000709999974, 35.619298230000027],
            [-80.709822218999989, 35.619332427000074],
            [-80.709635017999972, 35.619371171000068],
            [-80.709609144999945, 35.619375620000028],
            [-80.709569389999956, 35.619378870000048],
            [-80.709528051999939, 35.619382456000039],
            [-80.709478856999965, 35.619386359000032],
            [-80.709428237999987, 35.619390088000046],
            [-80.709404556999971, 35.619391869000026],
            [-80.709269988999949, 35.619396712000025],
            [-80.709139809999954, 35.61940140300004],
            [-80.708994919999952, 35.619404992000057],
            [-80.706431247999944, 35.61945356800004],
            [-80.704760259999944, 35.619488454000077],
            [-80.703099105999968, 35.619523981000043],
            [-80.702694436999934, 35.619532148000076],
            [-80.702279252999972, 35.619538156000033],
            [-80.701839630999984, 35.619545973000072],
            [-80.701430710999944, 35.619554562000076],
            [-80.701035041999944, 35.619564107000031],
            [-80.700302685999986, 35.619577894000031],
            [-80.699486555999954, 35.619594543000062],
            [-80.698952001999942, 35.619606026000042],
            [-80.698585175999938, 35.619612274000076],
            [-80.698210365999955, 35.619619426000042],
            [-80.697774200999959, 35.619628707000061],
            [-80.697358794999957, 35.619638525000028],
            [-80.696744261999982, 35.619649739000067],
            [-80.696153315999936, 35.619662084000026],
            [-80.695990973999983, 35.619663195000044],
            [-80.69580037299994, 35.619663126000034],
            [-80.69561009399996, 35.619661655000073],
            [-80.695420140999943, 35.619658788000038],
            [-80.695226558999934, 35.619654432000061],
            [-80.695096718999935, 35.619650698000044],
            [-80.69497355499999, 35.619646544000034],
            [-80.694840243999977, 35.619641380000076],
            [-80.694703521999941, 35.619635405000054],
            [-80.694643738999957, 35.619633124000075],
            [-80.693361462999974, 35.619592357000045],
            [-80.691743751999979, 35.619536786000026],
            [-80.690948275999972, 35.61951033400004],
            [-80.69093047399997, 35.619509544000039],
            [-80.690798610999934, 35.61950369200008],
            [-80.690737719999959, 35.619501332000027],
            [-80.690555229999973, 35.619495366000024],
            [-80.690502488999982, 35.619493899000076],
            [-80.690382464999971, 35.619491142000072],
            [-80.690341863999947, 35.619490360000043],
            [-80.690231815999937, 35.619488654000065],
            [-80.690193134999959, 35.619488192000063],
            [-80.690083073999972, 35.619487268000057],
            [-80.690042577999975, 35.619487078000077],
            [-80.689922505999959, 35.619486965000078],
            [-80.689885451999942, 35.619487056000025],
            [-80.689795400999969, 35.619487584000069],
            [-80.689665581999975, 35.619489894000026],
            [-80.689596193999989, 35.619492402000049],
            [-80.689434998999957, 35.619499259000065],
            [-80.689372308999964, 35.619502289000025],
            [-80.689241448999951, 35.619509372000039],
            [-80.689187353999955, 35.61951257000004],
            [-80.689066665999974, 35.619520311000031],
            [-80.689010548999988, 35.619524203000026],
            [-80.688886375999971, 35.619533463000039],
            [-80.688870087999987, 35.619534677000047],
            [-80.688822369999968, 35.619538446000035],
            [-80.688742080999987, 35.619545145000075],
            [-80.688688428999967, 35.61954989000003],
            [-80.688617276999935, 35.619557015000055],
            [-80.688597355999946, 35.61955914300006],
            [-80.688546260999942, 35.619564849000028],
            [-80.688416978999953, 35.619579911000073],
            [-80.688349104999986, 35.619588256000043],
            [-80.688279654999974, 35.619597243000044],
            [-80.68823230299995, 35.61960358500005],
            [-80.688162974999955, 35.619613184000059],
            [-80.688095443999941, 35.619622973000048],
            [-80.687967045999983, 35.619642421000037],
            [-80.687931613999979, 35.619647909000037],
            [-80.687899504999962, 35.619653094000057],
            [-80.687830572999985, 35.619664439000076],
            [-80.68778367699997, 35.619672372000025],
            [-80.687714897999967, 35.619684324000048],
            [-80.687654596999948, 35.619695161000038],
            [-80.687546846999965, 35.619715167000038],
            [-80.68747344999997, 35.619729330000041],
            [-80.687366132999955, 35.619750826000029],
            [-80.687292928999966, 35.619766029000061],
            [-80.687186073999953, 35.619789012000069],
            [-80.687136629999941, 35.619799896000075],
            [-80.687109905999989, 35.619805987000063],
            [-80.686993901999983, 35.619832748000078],
            [-80.686857916999941, 35.619866042000069],
            [-80.686715422999953, 35.619902966000041],
            [-80.686640910999984, 35.61992286800006],
            [-80.686499237999953, 35.61996184000003],
            [-80.686424968999972, 35.619982870000058],
            [-80.686275783999974, 35.620026339000049],
            [-80.686227573999986, 35.620040719000031],
            [-80.686152843999935, 35.620063422000044],
            [-80.686113731999967, 35.620075476000068],
            [-80.68603927099997, 35.620098752000047],
            [-80.685941688999947, 35.62013034000006],
            [-80.685838966999938, 35.620164744000078],
            [-80.685770486999957, 35.620188227000028],
            [-80.685668484999951, 35.620224028000052],
            [-80.685597487999985, 35.62024954900005],
            [-80.685485480999944, 35.620290769000064],
            [-80.68541645199997, 35.620316781000042],
            [-80.68531604399999, 35.620355473000075],
            [-80.685249191999958, 35.620381793000035],
            [-80.68514958999998, 35.620421849000024],
            [-80.685083206999934, 35.620449112000074],
            [-80.684984440999983, 35.620490522000068],
            [-80.68491864799995, 35.620518676000074],
            [-80.684820743999978, 35.620561429000077],
            [-80.684779030999948, 35.620579879000047],
            [-80.684755523999968, 35.620590485000037],
            [-80.684658508999973, 35.620634568000071],
            [-80.684593951999943, 35.620664482000052],
            [-80.684497852999982, 35.620709883000075],
            [-80.684433836999972, 35.620740714000078],
            [-80.684338679999939, 35.620787422000035],
            [-80.684275385999968, 35.620819081000036],
            [-80.684181199999955, 35.620867080000039],
            [-80.684115558999963, 35.620901188000062],
            [-80.684006786999987, 35.620958822000034],
            [-80.683949334999966, 35.620989897000072],
            [-80.683857259999968, 35.621040554000047],
            [-80.683795939999982, 35.621074904000068],
            [-80.683704914999964, 35.621126814000036],
            [-80.683644392999952, 35.621161947000076],
            [-80.68355444499997, 35.621215091000067],
            [-80.683494561999964, 35.621251099000062],
            [-80.683405714999935, 35.621305466000024],
            [-80.683357017999981, 35.621335694000038],
            [-80.683231518999946, 35.621417716000053],
            [-80.682997855999986, 35.621576177000065],
            [-80.682892119999963, 35.621650200000033],
            [-80.68280998399996, 35.621709551000038],
            [-80.682774728999959, 35.621735302000047],
            [-80.682683940999937, 35.621802408000065],
            [-80.68264547299998, 35.621831180000072],
            [-80.682557720999966, 35.621897594000075],
            [-80.682518085999959, 35.62192796000005],
            [-80.682450616999972, 35.621980289000078],
            [-80.682424110999989, 35.622000848000027],
            [-80.682384901999967, 35.622031634000052],
            [-80.682299164999961, 35.622099781000031],
            [-80.682264976999988, 35.622127251000052],
            [-80.681692274999989, 35.622592398000052],
            [-80.680936393999957, 35.623203053000054],
            [-80.680578965999985, 35.62349290700007],
            [-80.67959501699994, 35.62429578900003],
            [-80.678870003999975, 35.624886083000035],
            [-80.678557679999983, 35.625141343000053],
            [-80.678336664999961, 35.625320973000044],
            [-80.678108000999941, 35.625505928000052],
            [-80.677878586999952, 35.625690586000076],
            [-80.677677703999962, 35.625851540000042],
            [-80.677329843999985, 35.626128668000035],
            [-80.677313212999934, 35.626141988000029],
            [-80.676970677999975, 35.626417749000041],
            [-80.67695579399998, 35.626429788000053],
            [-80.676672323999981, 35.62666018200008],
            [-80.67640090499998, 35.626882607000027],
            [-80.675797368999952, 35.627370375000055]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 1,
        "SolutionID": "PI-22",
        "Shape_Length": 0.7089719030154028,
        "Shape_Area": 0.0026176158838385408
      }
    },
    {
      "type": "Feature",
      "id": 2,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.768491774999973, 34.422344580000072],
            [-80.765514260999964, 34.422358503000055],
            [-80.762536805999957, 34.422379559000035],
            [-80.75955943699995, 34.422407748000069],
            [-80.756582177999974, 34.422443071000032],
            [-80.753605052999944, 34.42248552500007],
            [-80.750628086999939, 34.422535113000038],
            [-80.747651303999987, 34.42259183200008],
            [-80.744674728999939, 34.422655682000027],
            [-80.741698387999975, 34.422726663000049],
            [-80.738722302999975, 34.422804775000031],
            [-80.735746500999937, 34.422890016000053],
            [-80.732771004999961, 34.422982386000058],
            [-80.729795839999952, 34.423081884000055],
            [-80.726821031999975, 34.423188510000045],
            [-80.72384660299997, 34.42330226200005],
            [-80.720872579999934, 34.423423140000068],
            [-80.717898985999966, 34.423551142000065],
            [-80.714925846999961, 34.423686267000051],
            [-80.711953186999949, 34.423828515000025],
            [-80.708981028999972, 34.423977884000067],
            [-80.706009399999971, 34.424134373000072],
            [-80.703038323999976, 34.424297980000063],
            [-80.700067824999962, 34.424468705000038],
            [-80.697097926999959, 34.424646546000076],
            [-80.694128655999975, 34.424831501000028],
            [-80.691160035999985, 34.425023569000075],
            [-80.688192090999962, 34.425222748000067],
            [-80.685224846999972, 34.425429037000072],
            [-80.682258326999943, 34.425642434000054],
            [-80.67929255599995, 34.425862937000034],
            [-80.676327557999969, 34.426090544000033],
            [-80.67336335899995, 34.426325254000062],
            [-80.670399982999982, 34.426567064000039],
            [-80.66743745399998, 34.426815972000043],
            [-80.664475795999977, 34.427071978000072],
            [-80.661515034999979, 34.427335077000066],
            [-80.658555194999963, 34.427605269000026],
            [-80.655596299999956, 34.427882550000049],
            [-80.652638373999935, 34.428166919000034],
            [-80.649681442999963, 34.428458374000058],
            [-80.646725530999959, 34.42875691100005],
            [-80.643770661999952, 34.429062529000078],
            [-80.640816860999962, 34.429375224000069],
            [-80.637864150999974, 34.429694995000034],
            [-80.63491255799994, 34.430021839000062],
            [-80.631962106999936, 34.430355752000025],
            [-80.629012819999957, 34.430696733000048],
            [-80.626064722999956, 34.431044777000068],
            [-80.623117840999953, 34.431399884000029],
            [-80.620172196999988, 34.431762049000042],
            [-80.617227816999957, 34.432131269000024],
            [-80.614284722999969, 34.432507542000053],
            [-80.611342941999965, 34.432890864000058],
            [-80.60840249599994, 34.433281233000059],
            [-80.60546341099996, 34.433678644000054],
            [-80.602525710999942, 34.434083095000062],
            [-80.599589419999973, 34.43449458300006],
            [-80.596654561999969, 34.434913103000042],
            [-80.593721161999952, 34.435338653000031],
            [-80.590789244999939, 34.435771228000078],
            [-80.587858832999984, 34.436210826000035],
            [-80.584929952999971, 34.436657443000058],
            [-80.582002626999952, 34.437111075000075],
            [-80.579076879999946, 34.437571718000072],
            [-80.576152736999973, 34.43803936900008],
            [-80.57323022099996, 34.438514023000039],
            [-80.570309356999985, 34.438995677000037],
            [-80.567390168999964, 34.43948432600007],
            [-80.564472681999973, 34.439979967000056],
            [-80.561556918999941, 34.440482596000038],
            [-80.558642903999953, 34.440992208000068],
            [-80.555730661999974, 34.441508799000076],
            [-80.552820216999976, 34.442032365000046],
            [-80.54991159299999, 34.442562902000077],
            [-80.547004813999934, 34.443100404000063],
            [-80.544099904999939, 34.443644869000025],
            [-80.541196887999945, 34.444196290000036],
            [-80.53829578899996, 34.444754664000072],
            [-80.535396630999969, 34.445319987000062],
            [-80.532499438999935, 34.445892252000078],
            [-80.529604235999955, 34.44647145600004],
            [-80.526711046999935, 34.447057594000057],
            [-80.523819894999974, 34.447650661000068],
            [-80.520930804999978, 34.44825065200007],
            [-80.518043799999987, 34.448857562000057],
            [-80.515158903999975, 34.449471386000027],
            [-80.512276141999962, 34.450092119000033],
            [-80.509395536999989, 34.450719756000069],
            [-80.506517112999973, 34.451354292000076],
            [-80.503640893999943, 34.45199572100006],
            [-80.500766903999988, 34.452644037000027],
            [-80.497895166999967, 34.453299237000067],
            [-80.495025705999979, 34.453961314000026],
            [-80.492158545999985, 34.45463026300007],
            [-80.48929370999997, 34.455306078000035],
            [-80.486431221999965, 34.455988753000042],
            [-80.483571104999953, 34.456678284000077],
            [-80.480713383999955, 34.457374663000053],
            [-80.477858081999955, 34.458077887000059],
            [-80.475005223999972, 34.458787948000065],
            [-80.472154830999955, 34.459504841000069],
            [-80.46930692899997, 34.460228560000076],
            [-80.466461540999944, 34.460959098000046],
            [-80.463618690999965, 34.46169645100008],
            [-80.46077840099997, 34.462440611000034],
            [-80.45794069699997, 34.463191573000074],
            [-80.455105600999957, 34.463949330000048],
            [-80.452273135999974, 34.464713876000076],
            [-80.449443327999973, 34.46548520500005],
            [-80.446616197999958, 34.466263310000045],
            [-80.443791769999962, 34.467048185000067],
            [-80.440970068999945, 34.467839824000066],
            [-80.438151116999961, 34.46863821900007],
            [-80.435334937999983, 34.469443365000075],
            [-80.432521554999937, 34.470255254000051],
            [-80.429710990999979, 34.471073880000063],
            [-80.426903270999958, 34.47189923600007],
            [-80.424098416999982, 34.472731316000079],
            [-80.421296452999968, 34.473570111000072],
            [-80.418497401999957, 34.474415616000044],
            [-80.41570128699999, 34.475267823000024],
            [-80.412908131999984, 34.476126726000075],
            [-80.41011795899999, 34.476992317000054],
            [-80.407330792999971, 34.477864588000045],
            [-80.40454665599998, 34.478743534000046],
            [-80.401765570999942, 34.479629146000036],
            [-80.398987561999945, 34.480521417000034],
            [-80.396212650999985, 34.481420340000057],
            [-80.393440861999977, 34.482325907000074],
            [-80.390672217999963, 34.483238111000048],
            [-80.387906741999984, 34.484156944000063],
            [-80.385144456999967, 34.485082399000078],
            [-80.382385385999953, 34.486014468000064],
            [-80.379629551999983, 34.486953143000051],
            [-80.376876976999938, 34.487898416000064],
            [-80.374127685999952, 34.488850280000065],
            [-80.371381699999972, 34.489808727000025],
            [-80.368639042999973, 34.490773748000038],
            [-80.36589973699995, 34.491745337000054],
            [-80.363163805999989, 34.492723484000066],
            [-80.360431271999971, 34.493708181000045],
            [-80.357702156999949, 34.494699421000064],
            [-80.354976485999941, 34.495697195000048],
            [-80.352254278999965, 34.496701495000025],
            [-80.349535560999982, 34.497712313000079],
            [-80.346820353999988, 34.498729640000079],
            [-80.344108679999977, 34.499753467000062],
            [-80.34140056199999, 34.500783787000046],
            [-80.338696022999954, 34.501820591000069],
            [-80.335995084999979, 34.502863870000056],
            [-80.333297770999934, 34.503913615000045],
            [-80.330604102999985, 34.504969818000063],
            [-80.327914103999944, 34.506032470000036],
            [-80.325227795999979, 34.507101562000059],
            [-80.322545201999958, 34.508177086000046],
            [-80.319866342999944, 34.509259032000045],
            [-80.317191243999957, 34.510347391000039],
            [-80.314519924999956, 34.511442155000054],
            [-80.311852408999982, 34.512543314000027],
            [-80.309188718999962, 34.513650860000041],
            [-80.306528875999959, 34.514764782000043],
            [-80.303872902999956, 34.51588507200006],
            [-80.301220821999948, 34.517011720000028],
            [-80.298572655999976, 34.51814471800003],
            [-80.295928425999989, 34.519284055000071],
            [-80.293288153999981, 34.520429723000063],
            [-80.290651862999937, 34.521581711000067],
            [-80.288019574999964, 34.522740010000064],
            [-80.285391311999945, 34.523904611000034],
            [-80.28276709499994, 34.525075504000029],
            [-80.280146945999945, 34.526252678000048],
            [-80.277530888999934, 34.527436126000055],
            [-80.274918942999989, 34.528625835000071],
            [-80.27231113299996, 34.529821798000057],
            [-80.269707477999987, 34.531024003000027],
            [-80.26710800099994, 34.532232441000076],
            [-80.264512723999985, 34.533447102000025],
            [-80.261921667999957, 34.534667975000048],
            [-80.259334855999953, 34.535895051000068],
            [-80.256752308999978, 34.537128319000033],
            [-80.254174047999982, 34.538367769000047],
            [-80.251600094999958, 34.539613391000046],
            [-80.249030471999959, 34.540865174000032],
            [-80.246465199999989, 34.542123108000055],
            [-80.24390429999994, 34.54338718300005],
            [-80.241347795999957, 34.544657388000076],
            [-80.238795705999962, 34.545933713000068],
            [-80.236248053999986, 34.547216146000039],
            [-80.233704859999989, 34.548504677000039],
            [-80.231166145999964, 34.54979929700005],
            [-80.228631933999964, 34.551099992000047],
            [-80.226102242999957, 34.55240675400006],
            [-80.223577095999985, 34.553719571000045],
            [-80.22105651399994, 34.55503843300005],
            [-80.218540517999941, 34.556363327000042],
            [-80.216029129999981, 34.557694244000061],
            [-80.213522368999975, 34.559031171000072],
            [-80.211020257999962, 34.560374099000057],
            [-80.20852281699996, 34.56172301600003],
            [-80.206030066999972, 34.56307791100005],
            [-80.203542029999937, 34.564438771000027],
            [-80.201058724999939, 34.565805587000057],
            [-80.198580174999961, 34.567178347000038],
            [-80.196106399999962, 34.568557039000041],
            [-80.193637420999949, 34.569941652000068],
            [-80.191173257999935, 34.571332174000077],
            [-80.188713931999985, 34.572728594000068],
            [-80.186259463999988, 34.574130900000057],
            [-80.183809874999952, 34.575539080000055],
            [-80.181365185999937, 34.576953124000056],
            [-80.17892541599997, 34.578373018000036],
            [-80.176490585999943, 34.579798751000055],
            [-80.174060716999975, 34.581230312000059],
            [-80.171635829999957, 34.582667689000061],
            [-80.169215943999973, 34.584110868000039],
            [-80.16680108099996, 34.58555984000003],
            [-80.164391259999945, 34.587014590000024],
            [-80.161986501999934, 34.588475108000068],
            [-80.159586826999941, 34.589941381000074],
            [-80.157192255999973, 34.591413397000053],
            [-80.154802807999943, 34.592891144000077],
            [-80.152418503999968, 34.594374609000056],
            [-80.150039363999952, 34.595863780000059],
            [-80.147665407999966, 34.597358645000043],
            [-80.145296655999971, 34.598859191000031],
            [-80.142933127999981, 34.600365405000048],
            [-80.140574843999957, 34.601877276000039],
            [-80.13822182399997, 34.603394791000028],
            [-80.13587408799998, 34.604917936000049],
            [-80.133531654999956, 34.606446700000049],
            [-80.131194545999961, 34.607981069000061],
            [-80.128862779999963, 34.609521031000043],
            [-80.126536376999979, 34.611066573000073],
            [-80.124215356999969, 34.612617681000074],
            [-80.121899738999957, 34.614174344000048],
            [-80.119589542999961, 34.615736549000076],
            [-80.117284787999949, 34.617304281000031],
            [-80.114985493999939, 34.618877528000041],
            [-80.112691679999955, 34.620456278000063],
            [-80.110403365999957, 34.622040516000027],
            [-80.108120571999962, 34.62363023000006],
            [-80.105843315999948, 34.625225406000027],
            [-80.103571616999943, 34.626826031000064],
            [-80.101305495999952, 34.628432092000025],
            [-80.099044970999955, 34.630043576000048],
            [-80.096790061999968, 34.631660468000064],
            [-80.094540786999971, 34.633282756000028],
            [-80.09229716599998, 34.634910426000033],
            [-80.090059217999965, 34.636543464000056],
            [-80.087826960999962, 34.638181857000063],
            [-80.085600414999988, 34.639825591000033],
            [-80.083379597999965, 34.641474652000056],
            [-80.081164529999967, 34.643129027000043],
            [-80.078955229999963, 34.644788702000028],
            [-80.076751714999943, 34.646453662000056],
            [-80.074554004999982, 34.648123895000026],
            [-80.072362117999944, 34.649799386000041],
            [-80.07017607399996, 34.651480122000066],
            [-80.067995889999963, 34.653166087000045],
            [-80.065821584999981, 34.654857269000047],
            [-80.063653177999981, 34.656553652000071],
            [-80.061490687999935, 34.658255224000072],
            [-80.059334130999957, 34.659961969000051],
            [-80.057183527999939, 34.661673873000041],
            [-80.055038895999985, 34.663390923000065],
            [-80.052900253999951, 34.665113103000067],
            [-80.050767618999942, 34.666840400000069],
            [-80.048641009999983, 34.66857279900006],
            [-80.046520444999942, 34.670310285000028],
            [-80.044405942999958, 34.672052845000053],
            [-80.042297519999977, 34.673800463000077],
            [-80.040195195999956, 34.675553125000079],
            [-80.038098987999945, 34.677310816000045],
            [-80.036008913999979, 34.679073521000078],
            [-80.033924991999982, 34.68084122700003],
            [-80.031847238999944, 34.682613918000072],
            [-80.02977567399995, 34.684391579000078],
            [-80.027710313999989, 34.686174195000035],
            [-80.02565117599994, 34.687961753000025],
            [-80.023598278999941, 34.689754235000066],
            [-80.021551639999984, 34.69155162900006],
            [-80.019511276999935, 34.693353918000071],
            [-80.017477205999967, 34.695161088000077],
            [-80.015449444999945, 34.696973123000078],
            [-80.013428011999963, 34.69879000800006],
            [-80.011412923999956, 34.700611729000059],
            [-80.00940419899996, 34.702438270000073],
            [-80.007401851999987, 34.704269615000044],
            [-80.005405902999939, 34.706105750000063],
            [-80.003416366999943, 34.707946659000072],
            [-80.001433261999978, 34.70979232600007],
            [-79.999456603999988, 34.711642737000034],
            [-79.997486410999954, 34.71349787500003],
            [-79.995522699999981, 34.715357726000036],
            [-79.993565487999945, 34.717222273000061],
            [-79.991614789999971, 34.719091502000026],
            [-79.989670624999974, 34.720965396000054],
            [-79.987733008999953, 34.722843939000029],
            [-79.985801957999968, 34.724727117000043],
            [-79.983877488999951, 34.726614913000049],
            [-79.981959617999962, 34.728507312000033],
            [-79.98004836299998, 34.730404297000064],
            [-79.978143738999961, 34.73230585400006],
            [-79.976245762999952, 34.734211965000043],
            [-79.974354450999954, 34.736122615000056],
            [-79.972469819999958, 34.738037788000042],
            [-79.970591885999966, 34.739957468000057],
            [-79.968720664999978, 34.741881639000042],
            [-79.96685617199995, 34.743810285000052],
            [-79.964998424999976, 34.74574338900004],
            [-79.963147438999954, 34.747680936000052],
            [-79.961303230999988, 34.749622909000038],
            [-79.959465814999987, 34.751569292000056],
            [-79.957635207999942, 34.753520069000047],
            [-79.955811425999968, 34.755475223000076],
            [-79.953994484999953, 34.757434738000029],
            [-79.952184399999965, 34.759398598000075],
            [-79.950381186999948, 34.761366786000053],
            [-79.948584860999972, 34.763339285000029],
            [-79.946795437999981, 34.765316080000048],
            [-79.945012933999976, 34.767297153000072],
            [-79.94323736399997, 34.769282488000044],
            [-79.941468742999973, 34.771272069000076],
            [-79.939707085999942, 34.773265878000075],
            [-79.93795240999998, 34.775263899000038],
            [-79.93620472899994, 34.777266115000032],
            [-79.934464057999946, 34.779272510000055],
            [-79.932730411999955, 34.781283066000071],
            [-79.931003806999968, 34.783297767000079],
            [-79.929284256999949, 34.78531659600003],
            [-79.927571777999958, 34.787339536000047],
            [-79.92586638399996, 34.789366570000027],
            [-79.924168089999966, 34.791397681000035],
            [-79.922476909999943, 34.793432852000024],
            [-79.920792860999939, 34.795472066000059],
            [-79.919115954999938, 34.797515305000047],
            [-79.917446207999944, 34.799562553000044],
            [-79.915783634999968, 34.801613793000058],
            [-79.914128248999987, 34.803669007000053],
            [-79.912480065999944, 34.805728178000038],
            [-79.910839098999986, 34.807791289000079],
            [-79.909205362999955, 34.809858322000025],
            [-79.907578871999988, 34.811929260000056],
            [-79.905959640999981, 34.814004086000068],
            [-79.904347682999969, 34.81608278300007],
            [-79.902743011999974, 34.818165332000035],
            [-79.901145643999939, 34.820251717000076],
            [-79.899555590999967, 34.822341919000053],
            [-79.897972866999964, 34.824435922000077],
            [-79.896397485999955, 34.826533708000056],
            [-79.894829462999951, 34.828635259000066],
            [-79.893268810999984, 34.83074055700007],
            [-79.891715542999975, 34.832849585000076],
            [-79.890169672999946, 34.834962325000049],
            [-79.888631214999975, 34.837078760000054],
            [-79.887100181999983, 34.839198871000065],
            [-79.885576586999946, 34.841322641000033],
            [-79.884060444999989, 34.843450052000037],
            [-79.882551766999939, 34.845581086000038],
            [-79.881050568999967, 34.847715725000057],
            [-79.879556861999959, 34.849853951000057],
            [-79.878070659999935, 34.851995746000057],
            [-79.876591975999986, 34.854141093000067],
            [-79.875120822999975, 34.856289973000059],
            [-79.873657213999934, 34.858442368000055],
            [-79.872201160999964, 34.860598261000064],
            [-79.870752678999963, 34.862757632000068],
            [-79.86931177799994, 34.864920464000079],
            [-79.867878472999962, 34.867086739000058],
            [-79.86645277599996, 34.869256437000047],
            [-79.865034698999978, 34.871429543000033],
            [-79.863624254999934, 34.873606036000069],
            [-79.862221456999976, 34.875785898000061],
            [-79.860826315999986, 34.877969112000073],
            [-79.859438844999943, 34.880155659000025],
            [-79.858059056999934, 34.882345520000058],
            [-79.856686963999948, 34.88453867700008],
            [-79.855322576999981, 34.886735112000054],
            [-79.853965908999953, 34.888934806000066],
            [-79.852616971999964, 34.891137740000033],
            [-79.851275777999945, 34.893343897000079],
            [-79.849942338999938, 34.895553257000074],
            [-79.848616666999988, 34.897765802000038],
            [-79.847298772999977, 34.899981513000057],
            [-79.845988668999951, 34.902200371000049],
            [-79.844686366999952, 34.90442235900008],
            [-79.843391878999967, 34.906647457000076],
            [-79.842105214999947, 34.908875647000059],
            [-79.840826387999982, 34.911106909000068],
            [-79.839555408999956, 34.913341225000067],
            [-79.83829228899998, 34.91557857600003],
            [-79.837037039999984, 34.917818943000043],
            [-79.835789672999965, 34.92006230800007],
            [-79.834550197999988, 34.922308652000027],
            [-79.833318626999983, 34.924557955000068],
            [-79.832094970999947, 34.926810198000055],
            [-79.83087924199998, 34.929065363000063],
            [-79.829671448999989, 34.931323431000067],
            [-79.828471603999958, 34.933584382000049],
            [-79.827279716999954, 34.935848198000031],
            [-79.826095798999972, 34.938114859000052],
            [-79.824919861999945, 34.940384347000077],
            [-79.823751913999956, 34.942656642000031],
            [-79.822591967999983, 34.94493172500006],
            [-79.821440032999988, 34.947209577000024],
            [-79.820296118999977, 34.949490178000076],
            [-79.819160237999938, 34.951773510000066],
            [-79.818032398999947, 34.954059553000036],
            [-79.816912612999943, 34.956348288000072],
            [-79.815800888999945, 34.958639695000045],
            [-79.814697237999951, 34.960933756000031],
            [-79.813601668999979, 34.963230450000026],
            [-79.81251419299997, 34.965529759000049],
            [-79.811434819999988, 34.967831663000027],
            [-79.810363558999939, 34.970136143000047],
            [-79.809300418999953, 34.972443179000038],
            [-79.808245411999962, 34.974752752000029],
            [-79.807198544999949, 34.977064842000061],
            [-79.806159828999967, 34.979379429000062],
            [-79.805129273999967, 34.981696495000051],
            [-79.804106887999978, 34.98401601900008],
            [-79.803092680999953, 34.986337983000055],
            [-79.802086661999965, 34.988662366000028],
            [-79.801088840999967, 34.99098914800004],
            [-79.800099225999986, 34.993318311000053],
            [-79.799117826999975, 34.995649834000062],
            [-79.798144651999962, 34.997983698000041],
            [-79.797179710999956, 35.000319883000031],
            [-79.796223011999984, 35.002658370000063],
            [-79.795274563999953, 35.004999137000027],
            [-79.794334375999938, 35.007342167000047],
            [-79.793402456999956, 35.009687438000071],
            [-79.792478813999935, 35.012034932000063],
            [-79.791563457999985, 35.014384628000073],
            [-79.790656394999985, 35.016736506000029],
            [-79.789757634999944, 35.019090547000076],
            [-79.788867185999948, 35.021446730000036],
            [-79.787985054999979, 35.023805036000056],
            [-79.787111251999988, 35.026165445000061],
            [-79.786245783999959, 35.028527937000035],
            [-79.785388659999967, 35.030892491000031],
            [-79.784539886999937, 35.033259089000069],
            [-79.783699472999956, 35.035627709000039],
            [-79.782867425999939, 35.03799833100004],
            [-79.782043753999972, 35.040370937000034],
            [-79.78122846499997, 35.042745505000028],
            [-79.780421564999983, 35.045122015000061],
            [-79.779623063999964, 35.047500448000051],
            [-79.778832966999971, 35.049880783000049],
            [-79.778051283999957, 35.052263001000028],
            [-79.777278019999983, 35.054647080000052],
            [-79.776513182999963, 35.057033001000036],
            [-79.775756781999974, 35.059420743000032],
            [-79.775008820999972, 35.061810287000071],
            [-79.774269309999966, 35.064201612000033],
            [-79.773538253999959, 35.066594698000074],
            [-79.772815659999935, 35.068989524000074],
            [-79.772101536999969, 35.071386071000063],
            [-79.771395888999962, 35.073784317000047],
            [-79.770698724999988, 35.076184243000057],
            [-79.770010049999939, 35.078585829000076],
            [-79.769329871999958, 35.080989054000042],
            [-79.76865819599999, 35.083393897000064],
            [-79.767995029999952, 35.08580033800007],
            [-79.767340378999961, 35.088208357000042],
            [-79.766694249999944, 35.090617934000079],
            [-79.76605664899995, 35.093029047000073],
            [-79.765427582999962, 35.095441677000053],
            [-79.764807056999985, 35.097855803000073],
            [-79.764195076999954, 35.100271405000058],
            [-79.763591649999967, 35.10268846200006],
            [-79.76299678099997, 35.105106954000064],
            [-79.762410475999957, 35.107526859000075],
            [-79.761832739999988, 35.109948158000066],
            [-79.761263579999934, 35.112370831000078],
            [-79.760703001999957, 35.114794855000071],
            [-79.760151008999969, 35.117220212000063],
            [-79.759607608999943, 35.119646880000062],
            [-79.759072805999949, 35.12207483800006],
            [-79.758546605999982, 35.124504067000032],
            [-79.758029012999941, 35.12693454600003],
            [-79.757520033999981, 35.129366253000057],
            [-79.757019672999945, 35.131799168000043],
            [-79.756527934999951, 35.134233271000028],
            [-79.756044825999936, 35.136668541000063],
            [-79.755570348999981, 35.139104957000029],
            [-79.755104509999967, 35.141542498000035],
            [-79.754647312999964, 35.143981145000055],
            [-79.754198763999966, 35.146420875000047],
            [-79.753758866999988, 35.148861669000041],
            [-79.753327625999987, 35.151303506000033],
            [-79.752905044999977, 35.153746364000028],
            [-79.752491129999953, 35.156190224000056],
            [-79.752085883999939, 35.158635064000066],
            [-79.751689311999939, 35.161080863000052],
            [-79.751301417999969, 35.163527602000045],
            [-79.750922204999938, 35.16597525800006],
            [-79.750551677999965, 35.168423812000071],
            [-79.750189840999951, 35.170873241000038],
            [-79.749836697999967, 35.173323527000036],
            [-79.749492251999982, 35.175774647000026],
            [-79.749156506999952, 35.178226581000047],
            [-79.74882946799994, 35.180679308000038],
            [-79.748511135999934, 35.18313280700005],
            [-79.748201515999938, 35.185587058000067],
            [-79.747900611999967, 35.188042039000038],
            [-79.747608425999942, 35.190497729000072],
            [-79.747324961999936, 35.192954108000038],
            [-79.747050223999963, 35.195411155000045],
            [-79.746784212999955, 35.197868848000041],
            [-79.746526933999974, 35.200327168000058],
            [-79.74627838899994, 35.202786092000053],
            [-79.746038580999937, 35.205245601000058],
            [-79.745807512999988, 35.207705672000031],
            [-79.74558518799995, 35.21016628600006],
            [-79.745371607999971, 35.212627421000036],
            [-79.745166775999962, 35.215089056000068],
            [-79.74497069499995, 35.217551170000036],
            [-79.744783366999968, 35.220013743000038],
            [-79.744604794999987, 35.222476753000024],
            [-79.744434979999937, 35.224940179000043],
            [-79.744273924999959, 35.227404001000025],
            [-79.744121632999963, 35.22986819700003],
            [-79.74397810399995, 35.232332746000054],
            [-79.743843341999934, 35.234797628000024],
            [-79.743717347999961, 35.237262821000058],
            [-79.743600124999944, 35.239728304000039],
            [-79.743491672999937, 35.242194057000063],
            [-79.743391993999978, 35.244660058000079],
            [-79.743301090999978, 35.247126287000071],
            [-79.743218964999983, 35.249592721000056],
            [-79.743145616999982, 35.252059341000063],
            [-79.743081048999954, 35.254526125000041],
            [-79.743025261999946, 35.256993052000041],
            [-79.742978256999947, 35.259460102000048],
            [-79.742940035999936, 35.261927252000078],
            [-79.742910599999959, 35.264394482000057],
            [-79.742889948999959, 35.266861771000038],
            [-79.742878084999973, 35.269329099000061],
            [-79.742875007999942, 35.271796443000028],
            [-79.742880720999949, 35.274263783000038],
            [-79.742895221999959, 35.276731097000038],
            [-79.74291851299995, 35.27919836500007],
            [-79.742950593999979, 35.281665566000072],
            [-79.742991466999968, 35.284132679000038],
            [-79.743041130999984, 35.28659968200003],
            [-79.743099586999961, 35.289066554000044],
            [-79.743166834999954, 35.291533275000063],
            [-79.743242874999964, 35.293999823000036],
            [-79.743327707999981, 35.29646617700007],
            [-79.743421332999958, 35.298932316000048],
            [-79.743523750999941, 35.301398220000067],
            [-79.743634961999987, 35.303863866000029],
            [-79.743754963999947, 35.306329235000078],
            [-79.743883758999971, 35.30879430400006],
            [-79.744021345999954, 35.311259053000072],
            [-79.744167723999965, 35.313723461000052],
            [-79.744322892999946, 35.316187507000052],
            [-79.744486852999955, 35.318651169000077],
            [-79.744659601999956, 35.321114427000055],
            [-79.744841140999938, 35.323577260000036],
            [-79.745031467999979, 35.326039645000037],
            [-79.745230582999966, 35.328501564000078],
            [-79.745438483999976, 35.330962993000071],
            [-79.745655170999953, 35.333423913000047],
            [-79.745880642999964, 35.335884302000068],
            [-79.746114897999973, 35.338344140000061],
            [-79.746357934999935, 35.340803404000042],
            [-79.746609753999962, 35.343262075000041],
            [-79.746870351999974, 35.345720130000075],
            [-79.747139728999969, 35.348177550000059],
            [-79.747417881999979, 35.350634313000057],
            [-79.747704809999959, 35.353090398000063],
            [-79.748000511999976, 35.355545783000025],
            [-79.748304985999937, 35.358000449000031],
            [-79.748618228999987, 35.360454374000028],
            [-79.748940240999957, 35.36290753600008],
            [-79.749271017999945, 35.365359916000045],
            [-79.749610559999951, 35.367811491000055],
            [-79.74995886399995, 35.370262241000034],
            [-79.750315926999974, 35.372712146000026],
            [-79.750681747999977, 35.375161183000046],
            [-79.75105632399999, 35.377609332000077],
            [-79.751439652999977, 35.380056572000058],
            [-79.751831731999971, 35.38250288200004],
            [-79.752232557999946, 35.384948242000064],
            [-79.75264212999997, 35.387392629000033],
            [-79.753060443999971, 35.389836024000033],
            [-79.753487496999981, 35.392278404000024],
            [-79.753923286999964, 35.394719751000025],
            [-79.754367810999952, 35.397160041000063],
            [-79.754821064999987, 35.399599255000055],
            [-79.755283046999978, 35.402037371000063],
            [-79.755753752999965, 35.40447436900007],
            [-79.756233180999971, 35.406910228000072],
            [-79.756721325999933, 35.409344927000063],
            [-79.757218184999942, 35.411778444000049],
            [-79.757723755999962, 35.41421076000006],
            [-79.758238033999987, 35.416641853000044],
            [-79.758761014999948, 35.419071702000053],
            [-79.759292696999978, 35.421500287000072],
            [-79.75983307499996, 35.423927586000048],
            [-79.760382144999937, 35.426353579000079],
            [-79.760939902999951, 35.428778246000036],
            [-79.761506346999965, 35.431201564000048],
            [-79.762081469999941, 35.433623514000033],
            [-79.762665269999957, 35.436044075000041],
            [-79.763257740999961, 35.438463225000078],
            [-79.763858879999987, 35.440880944000071],
            [-79.764468682999961, 35.443297212000061],
            [-79.765087143999949, 35.445712008000044],
            [-79.765714259999982, 35.44812531000008],
            [-79.766350024999952, 35.450537098000041],
            [-79.766994434999958, 35.452947352000024],
            [-79.767647485999987, 35.455356050000034],
            [-79.768309170999942, 35.457763172000057],
            [-79.768979486999967, 35.460168698000075],
            [-79.769658428999946, 35.462572606000037],
            [-79.770345990999942, 35.464974876000042],
            [-79.771042167999951, 35.467375488000073],
            [-79.771746954999969, 35.469774420000078],
            [-79.772460346999935, 35.472171652000043],
            [-79.77318233799997, 35.474567164000064],
            [-79.773912922999955, 35.476960935000079],
            [-79.774652096999944, 35.479352944000027],
            [-79.775399852999954, 35.481743170000072],
            [-79.77615618599998, 35.484131594000075],
            [-79.776921090999963, 35.486518195000031],
            [-79.777694560999976, 35.488902951000057],
            [-79.778476590999958, 35.491285843000071],
            [-79.779267174999973, 35.493666851000057],
            [-79.780066305999981, 35.496045952000031],
            [-79.780873978999978, 35.49842312800007],
            [-79.781690186999981, 35.500798358000054],
            [-79.782514922999951, 35.503171621000035],
            [-79.783348182999987, 35.505542897000055],
            [-79.784189958999946, 35.50791216500005],
            [-79.785040244999948, 35.510279405000063],
            [-79.785899032999964, 35.512644597000076],
            [-79.78676631899998, 35.515007720000028],
            [-79.787642093999978, 35.517368755000064],
            [-79.788526351999963, 35.519727679000027],
            [-79.789419085999953, 35.522084474000053],
            [-79.790320288999965, 35.524439119000078],
            [-79.791229954999949, 35.526791594000031],
            [-79.792148075999989, 35.529141878000075],
            [-79.793074643999944, 35.531489952000072],
            [-79.794009652999989, 35.533835794000026],
            [-79.794953094999983, 35.536179385000025],
            [-79.795904963999988, 35.538520705000053],
            [-79.796865249999939, 35.540859733000048],
            [-79.797833947999948, 35.543196450000039],
            [-79.798811048999937, 35.545530834000033],
            [-79.799796544999936, 35.547862867000049],
            [-79.80079042899996, 35.550192527000036],
            [-79.801792692999982, 35.55251979500008],
            [-79.802803329999961, 35.554844651000053],
            [-79.803822329999946, 35.557167075000052],
            [-79.804849685999955, 35.559487047000061],
            [-79.805885389999958, 35.561804546000076],
            [-79.806929432999937, 35.564119552000079],
            [-79.807981807999965, 35.566432047000035],
            [-79.809042505999969, 35.568742009000061],
            [-79.810111518999975, 35.571049419000076],
            [-79.811188836999975, 35.573354257000062],
            [-79.812274452999986, 35.575656503000062],
            [-79.813368356999945, 35.577956138000047],
            [-79.814470541999981, 35.580253140000025],
            [-79.815580996999984, 35.582547491000071],
            [-79.81669971499997, 35.584839171000056],
            [-79.817826686999979, 35.587128160000077],
            [-79.818961901999955, 35.589414438000063],
            [-79.820105352999974, 35.591697986000042],
            [-79.82125702999997, 35.593978783000068],
            [-79.822416923999981, 35.596256810000057],
            [-79.823585024999943, 35.598532048000038],
            [-79.82476132499994, 35.600804476000064],
            [-79.825945812999976, 35.603074075000052],
            [-79.827138479999974, 35.605340826000031],
            [-79.828339315999983, 35.607604709000043],
            [-79.829548311999986, 35.609865704000072],
            [-79.830765458999963, 35.612123792000034],
            [-79.831990744999985, 35.614378954000074],
            [-79.833224161999965, 35.616631169000073],
            [-79.834465698999963, 35.61888041900005],
            [-79.835715345999972, 35.621126683000057],
            [-79.836973092999983, 35.623369943000057],
            [-79.838238929999989, 35.625610180000024],
            [-79.83951284699998, 35.627847372000076],
            [-79.840794832999961, 35.630081503000042],
            [-79.84208487799998, 35.632312551000041],
            [-79.843382970999983, 35.634540499000025],
            [-79.844689101999961, 35.636765326000045],
            [-79.846003259999975, 35.638987013000076],
            [-79.847325434999959, 35.641205541000033],
            [-79.848655614999984, 35.643420892000051],
            [-79.849993789999985, 35.645633045000068],
            [-79.851339949999954, 35.647841981000056],
            [-79.852694081999971, 35.650047683000025],
            [-79.854056174999982, 35.652250129000038],
            [-79.85542621999997, 35.654449302000046],
            [-79.856804203999957, 35.65664518300008],
            [-79.858190115999946, 35.658837751000078],
            [-79.859583945999987, 35.661026990000039],
            [-79.860985679999942, 35.663212878000024],
            [-79.862395308999965, 35.665395398000044],
            [-79.863812819999964, 35.667574531000071],
            [-79.865238201999944, 35.669750257000032],
            [-79.866671442999973, 35.671922559000052],
            [-79.868112530999952, 35.674091416000067],
            [-79.86956145399995, 35.67625681100003],
            [-79.87101820099997, 35.678418724000039],
            [-79.872482759999968, 35.680577138000046],
            [-79.87395511699998, 35.682732032000047],
            [-79.875435262999986, 35.684883389000049],
            [-79.876923182999974, 35.687031190000027],
            [-79.878418865999947, 35.689175416000069],
            [-79.879922299999976, 35.691316049000079],
            [-79.88143347099998, 35.693453069000043],
            [-79.882952368999952, 35.695586460000072],
            [-79.884478978999937, 35.697716201000048],
            [-79.886013289999937, 35.699842276000027],
            [-79.887555287999987, 35.701964664000059],
            [-79.889104961999976, 35.70408334900003],
            [-79.890662296999949, 35.706198311000037],
            [-79.892227281999965, 35.708309532000044],
            [-79.893799902999945, 35.71041699400007],
            [-79.895380146999969, 35.712520678000033],
            [-79.896968000999948, 35.714620567000054],
            [-79.898563451999962, 35.716716642000051],
            [-79.900166486999979, 35.718808885000044],
            [-79.901777091999975, 35.720897277000063],
            [-79.903395253999975, 35.722981802000049],
            [-79.905020959999945, 35.725062440000045],
            [-79.906654195999977, 35.727139173000069],
            [-79.908294947999934, 35.729211984000074],
            [-79.909943201999965, 35.73128085500008],
            [-79.91159894599997, 35.73334576700006],
            [-79.913262164999935, 35.735406703000024],
            [-79.914932844999953, 35.737463645000048],
            [-79.916610971999944, 35.739516575000039],
            [-79.918296532999989, 35.741565475000073],
            [-79.919989513999951, 35.743610327000056],
            [-79.921689898999944, 35.745651114000054],
            [-79.923397675999979, 35.747687818000031],
            [-79.925112828999943, 35.749720421000063],
            [-79.926835343999983, 35.751748906000046],
            [-79.928565207999952, 35.753773254000066],
            [-79.930302404999964, 35.755793449000066],
            [-79.932046920999937, 35.757809473000066],
            [-79.933798740999976, 35.759821308000028],
            [-79.935557850999942, 35.761828937000075],
            [-79.937324234999949, 35.763832342000057],
            [-79.939097879999963, 35.76583150700003],
            [-79.940878769999983, 35.76782641300008],
            [-79.942666890999988, 35.769817043000046],
            [-79.944462226999974, 35.771803380000051],
            [-79.946264761999942, 35.773785407000048],
            [-79.948074482999971, 35.775763107000046],
            [-79.949891373999947, 35.777736462000064],
            [-79.951715419999971, 35.779705455000055],
            [-79.953546603999939, 35.781670069000029],
            [-79.955384912999989, 35.783630287000051],
            [-79.957230329999959, 35.785586093000063],
            [-79.959082839999951, 35.787537468000039],
            [-79.960942426999964, 35.789484396000034],
            [-79.962809074999939, 35.791426860000058],
            [-79.964682769999968, 35.793364843000063],
            [-79.966563493999956, 35.795298329000047],
            [-79.968451232999939, 35.797227300000031],
            [-79.970345969999983, 35.79915174000007],
            [-79.972247688999971, 35.80107163200006],
            [-79.97415637499995, 35.802986960000055],
            [-79.976072010999985, 35.804897706000077],
            [-79.977994580999962, 35.806803854000066],
            [-79.979924067999946, 35.808705387000032],
            [-79.981860456999982, 35.81060228900003],
            [-79.983803730999966, 35.81249454400006],
            [-79.985753872999965, 35.814382135000074],
            [-79.987710867999965, 35.81626504500008],
            [-79.989674697999988, 35.818143258000077],
            [-79.991645346999974, 35.820016758000065],
            [-79.993622798999979, 35.821885528000053],
            [-79.995607035999967, 35.823749553000027],
            [-79.997598040999947, 35.825608815000066],
            [-79.999595797999973, 35.827463300000034],
            [-80.001600290999988, 35.829312990000062],
            [-80.003611500999966, 35.831157869000037],
            [-80.005629411999962, 35.832997922000061],
            [-80.007654005999939, 35.834833132000028],
            [-80.009685267999942, 35.836663484000042],
            [-80.011723177999954, 35.838488962000042],
            [-80.013767719999976, 35.84030954900004],
            [-80.015818876999958, 35.842125229000033],
            [-80.017876630999979, 35.843935988000055],
            [-80.019940964999989, 35.84574180900006],
            [-80.022011860999953, 35.847542676000046],
            [-80.024089301999936, 35.849338574000058],
            [-80.026173268999969, 35.851129487000037],
            [-80.028263744999947, 35.852915400000029],
            [-80.030360712999936, 35.854696296000043],
            [-80.032464153999967, 35.856472161000056],
            [-80.034574050999936, 35.858242979000067],
            [-80.036690384999986, 35.860008734000075],
            [-80.038813138999956, 35.861769412000058],
            [-80.04094229399999, 35.863524996000024],
            [-80.043077832999984, 35.865275472000064],
            [-80.045219735999979, 35.867020824000065],
            [-80.047367986999973, 35.86876103600008],
            [-80.049522564999961, 35.870496095000078],
            [-80.051683453999942, 35.872225984000067],
            [-80.053850634999947, 35.873950689000026],
            [-80.056024088999948, 35.875670195000055],
            [-80.058203796999976, 35.877384486000039],
            [-80.060389740999938, 35.879093547000025],
            [-80.062581902999966, 35.880797365000035],
            [-80.064780262999989, 35.882495923000079],
            [-80.066984802999968, 35.884189207000077],
            [-80.069195502999946, 35.885877203000064],
            [-80.071412345999988, 35.887559894000049],
            [-80.073635310999975, 35.889237268000045],
            [-80.075864380999974, 35.890909309000051],
            [-80.078099535999968, 35.892576003000045],
            [-80.080340755999941, 35.894237334000024],
            [-80.082588022999971, 35.89589329000006],
            [-80.084841316999984, 35.897543854000048],
            [-80.087100618999955, 35.899189013000068],
            [-80.08936590899998, 35.900828752000052],
            [-80.091637168999966, 35.90246305800008],
            [-80.093914378999955, 35.904091915000038],
            [-80.096197518999986, 35.905715310000062],
            [-80.098486568999988, 35.907333228000027],
            [-80.10078151099998, 35.908945655000025],
            [-80.103082323999956, 35.910552578000079],
            [-80.105388987999959, 35.912153982000063],
            [-80.107701483999961, 35.913749852000024],
            [-80.110019791999946, 35.915340176000029],
            [-80.112343891999956, 35.916924940000058],
            [-80.114673762999985, 35.918504129000041],
            [-80.117009386999939, 35.92007772900007],
            [-80.119350742999984, 35.921645728000044],
            [-80.121697809999944, 35.923208111000065],
            [-80.124050568999962, 35.924764864000053],
            [-80.126408998999977, 35.926315974000033],
            [-80.128773080999963, 35.927861428000028],
            [-80.131142792999981, 35.929401212000073],
            [-80.133518114999958, 35.930935312000031],
            [-80.135899026999937, 35.932463716000029],
            [-80.138285508999957, 35.933986409000056],
            [-80.140677538999967, 35.935503378000078],
            [-80.143075096999951, 35.937014611000052],
            [-80.14547816299995, 35.938520094000069],
            [-80.14788671599996, 35.940019813000049],
            [-80.150300734999973, 35.941513756000063],
            [-80.152720198999987, 35.943001910000078],
            [-80.155145086999937, 35.94448426200006],
            [-80.157575378999979, 35.945960798000044],
            [-80.160011053999938, 35.947431506000044],
            [-80.162452089999988, 35.948896372000036],
            [-80.164898465999954, 35.95035538500008],
            [-80.167350161999934, 35.95180853100004],
            [-80.169807154999944, 35.953255798000043],
            [-80.172269425999957, 35.954697173000056],
            [-80.174736951999989, 35.956132643000046],
            [-80.177209712999968, 35.957562196000026],
            [-80.179687686999955, 35.958985819000077],
            [-80.182170851999956, 35.960403500000041],
            [-80.184659186999966, 35.961815226000056],
            [-80.187152670999978, 35.963220986000067],
            [-80.189651281999943, 35.964620766000053],
            [-80.192154998999968, 35.966014555000072],
            [-80.194663798999954, 35.967402341000025],
            [-80.197177660999955, 35.968784110000058],
            [-80.199696562999975, 35.970159852000052],
            [-80.202220484999941, 35.971529554000028],
            [-80.204749401999948, 35.972893203000069],
            [-80.207283294999968, 35.974250789000052],
            [-80.209822139999972, 35.975602300000048],
            [-80.212365915999953, 35.976947722000034],
            [-80.214914600999975, 35.978287046000048],
            [-80.217468172999986, 35.979620258000068],
            [-80.220026608999945, 35.980947348000029],
            [-80.222589887999959, 35.982268303000069],
            [-80.225157986999989, 35.983583113000066],
            [-80.227730883999982, 35.984891765000043],
            [-80.230308555999954, 35.986194248000061],
            [-80.232890981999958, 35.987490551000064],
            [-80.235478138999952, 35.988780662000067],
            [-80.238070003999951, 35.990064571000062],
            [-80.240666555999951, 35.991342265000071],
            [-80.243267769999989, 35.992613734000031],
            [-80.245873625999934, 35.993878966000068],
            [-80.248484098999938, 35.995137951000061],
            [-80.251099168999986, 35.996390677000079],
            [-80.253718810999942, 35.997637134000058],
            [-80.256343002999984, 35.998877310000069],
            [-80.258971722999945, 36.000111195000045],
            [-80.261604946999967, 36.001338778000047],
            [-80.26424265299994, 36.002560048000078],
            [-80.266884816999948, 36.003774994000025],
            [-80.269531416999939, 36.004983606000053],
            [-80.272182429999987, 36.006185873000049],
            [-80.274837832999935, 36.007381785000064],
            [-80.277497601999983, 36.008571331000041],
            [-80.280161713999973, 36.009754501000032],
            [-80.28283014699997, 36.010931284000037],
            [-80.285502876999942, 36.01210167000005],
            [-80.288179880999962, 36.013265649000061],
            [-80.290861134999943, 36.014423211000064],
            [-80.293546616999947, 36.015574345000061],
            [-80.296236301999954, 36.016719041000044],
            [-80.29893016799997, 36.017857290000052],
            [-80.301628189999974, 36.018989081000029],
            [-80.304330345999972, 36.020114404000026],
            [-80.307036611999933, 36.02123325000008],
            [-80.309746964999988, 36.02234560900007],
            [-80.312461379999945, 36.023451471000044],
            [-80.315179833999935, 36.024550826000052],
            [-80.317902302999983, 36.025643665000075],
            [-80.320628764999981, 36.026729978000049],
            [-80.323359193999977, 36.027809755000078],
            [-80.326093566999987, 36.028882988000078],
            [-80.328831860999969, 36.02994966600005],
            [-80.331574050999961, 36.03100978100008],
            [-80.33432011299999, 36.032063322000056],
            [-80.337070023999956, 36.033110282000052],
            [-80.339823759999945, 36.034150650000072],
            [-80.342581295999935, 36.035184417000039],
            [-80.345342608999943, 36.036211575000038],
            [-80.348107673999948, 36.037232115000052],
            [-80.350876466999978, 36.038246027000071],
            [-80.353648963999944, 36.039253302000077],
            [-80.356425140999988, 36.040253932000041],
            [-80.359204973999965, 36.041247908000059],
            [-80.361988437999969, 36.042235222000045],
            [-80.364775509999959, 36.043215864000047],
            [-80.367566163999982, 36.044189825000046],
            [-80.370360375999951, 36.04515709900005],
            [-80.37315812199995, 36.046117675000062],
            [-80.375959377999948, 36.047071546000041],
            [-80.378764118999982, 36.048018703000025],
            [-80.381572319999975, 36.048959138000043],
            [-80.384383957999944, 36.049892842000077],
            [-80.387199005999946, 36.050819808000028],
            [-80.390017441999987, 36.051740027000051],
            [-80.392839239999944, 36.052653491000058],
            [-80.395664374999967, 36.053560192000077],
            [-80.39849282299997, 36.054460123000069],
            [-80.401324558999988, 36.055353275000073],
            [-80.404159557999947, 36.056239640000058],
            [-80.406997795999985, 36.057119211000042],
            [-80.409839247999969, 36.057991980000054],
            [-80.412683887999947, 36.058857939000063],
            [-80.415531691999945, 36.059717081000031],
            [-80.418382634999944, 36.06056939900003],
            [-80.421236692999969, 36.061414883000054],
            [-80.424093838999966, 36.062253529000031],
            [-80.426954049999949, 36.063085327000067],
            [-80.429817298999978, 36.063910270000065],
            [-80.432683562999955, 36.064728352000031],
            [-80.435552814999937, 36.065539565000051],
            [-80.438425030999952, 36.066343902000028],
            [-80.441300185999978, 36.067141357000025],
            [-80.444178253999951, 36.067931921000024],
            [-80.447059209999964, 36.068715588000032],
            [-80.44994302899994, 36.069492351000065],
            [-80.452829685999973, 36.070262204000073],
            [-80.45571915499994, 36.071025139000028],
            [-80.458611411999982, 36.071781150000049],
            [-80.461506429999986, 36.07253023100003],
            [-80.464404184999978, 36.073272374000055],
            [-80.46730464999996, 36.074007573000074],
            [-80.470207800999958, 36.074735822000036],
            [-80.473113612999953, 36.075457114000073],
            [-80.476022057999955, 36.076171443000078],
            [-80.478933112999982, 36.076878803000056],
            [-80.481846751999967, 36.077579187000026],
            [-80.484762948999958, 36.07827259000004],
            [-80.487681677999944, 36.078959005000058],
            [-80.490602913999965, 36.079638426000031],
            [-80.493526631999941, 36.080310847000078],
            [-80.49645280499999, 36.080976263000025],
            [-80.499381407999977, 36.08163466700006],
            [-80.502312414999949, 36.082286053000075],
            [-80.505245800999944, 36.082930417000057],
            [-80.508181539999953, 36.083567752000079],
            [-80.511119605999966, 36.08419805300008],
            [-80.514059972999974, 36.084821314000067],
            [-80.517002614999967, 36.085437529000046],
            [-80.519947507999973, 36.086046694000061],
            [-80.522894623999946, 36.08664880300006],
            [-80.52584393799998, 36.08724385000005],
            [-80.528795423999952, 36.087831831000074],
            [-80.531749055999967, 36.088412740000024],
            [-80.534704807999958, 36.088986573000057],
            [-80.537662654999963, 36.089553323000075],
            [-80.540622569999982, 36.090112986000065],
            [-80.54358452699995, 36.090665558000069],
            [-80.546548500999961, 36.091211033000036],
            [-80.549514463999969, 36.091749407000066],
            [-80.552482391999945, 36.092280674000051],
            [-80.555452257999946, 36.092804830000034],
            [-80.558424035999963, 36.093321871000057],
            [-80.561397700999976, 36.09383179200006],
            [-80.56437322499994, 36.094334589000027],
            [-80.56735058199996, 36.094830257000069],
            [-80.57032974699996, 36.095318791000068],
            [-80.573310693999986, 36.095800188000055],
            [-80.576293394999936, 36.096274443000027],
            [-80.579277825999952, 36.096741552000026],
            [-80.582263958999988, 36.097201511000037],
            [-80.585251768999967, 36.097654316000046],
            [-80.588241228999948, 36.098099963000038],
            [-80.591232312999978, 36.098538448000056],
            [-80.594224993999944, 36.098969767000028],
            [-80.597219246999941, 36.099393916000054],
            [-80.60021504499997, 36.09981089200005],
            [-80.603212361999965, 36.100220691000061],
            [-80.606211170999984, 36.100623309000071],
            [-80.609211445999961, 36.101018743000054],
            [-80.612213159999953, 36.101406990000044],
            [-80.615216287999942, 36.101788045000035],
            [-80.618220801999939, 36.102161906000049],
            [-80.621226676999981, 36.102528569000071],
            [-80.624233885999956, 36.102888032000067],
            [-80.627242401999979, 36.103240290000031],
            [-80.630252198999983, 36.103585341000041],
            [-80.63326325099996, 36.103923182000074],
            [-80.636275530999967, 36.104253809000056],
            [-80.639289012999939, 36.104577221000056],
            [-80.64230366999999, 36.10489341400006],
            [-80.645319474999951, 36.105202384000052],
            [-80.648336401999984, 36.105504131000032],
            [-80.651354424999965, 36.105798650000054],
            [-80.654373516999954, 36.106085940000071],
            [-80.65739365099995, 36.106365997000069],
            [-80.660414800999945, 36.106638820000057],
            [-80.66343693999994, 36.106904406000069],
            [-80.666460041999983, 36.107162752000079],
            [-80.669484079999961, 36.10741385700004],
            [-80.67250902699999, 36.107657717000052],
            [-80.675534856999946, 36.107894332000058],
            [-80.67856154399999, 36.108123699000032],
            [-80.681589059999965, 36.108345815000064],
            [-80.684617378999974, 36.108560680000039],
            [-80.687646473999962, 36.108768290000057],
            [-80.690676318999977, 36.108968645000061],
            [-80.693706885999973, 36.109161742000026],
            [-80.696738150999977, 36.109347580000076],
            [-80.699770083999965, 36.109526157000062],
            [-80.702802660999964, 36.109697471000061],
            [-80.705835853999986, 36.109861522000074],
            [-80.708869636999964, 36.110018307000075],
            [-80.711903981999967, 36.110167825000076],
            [-80.714938862999986, 36.110310075000029],
            [-80.717974253999955, 36.11044505600006],
            [-80.721010127999989, 36.110572767000065],
            [-80.724046457999975, 36.110693205000075],
            [-80.727083216999972, 36.110806371000024],
            [-80.730120377999981, 36.110912264000035],
            [-80.733157915999982, 36.111010881000027],
            [-80.736195801999941, 36.111102223000046],
            [-80.739234010999951, 36.111186289000045],
            [-80.742272514999968, 36.111263078000036],
            [-80.745311287999982, 36.111332589000028],
            [-80.748350303999985, 36.111394821000033],
            [-80.751389533999941, 36.11144977500004],
            [-80.754428953999934, 36.111497450000059],
            [-80.757468534999987, 36.111537844000054],
            [-80.760508250999976, 36.111570959000062],
            [-80.76354807599995, 36.111596793000047],
            [-80.766587981999976, 36.111615346000065],
            [-80.769627942999989, 36.111626619000049],
            [-80.77266793199999, 36.111630610000077],
            [-80.775707921999981, 36.111627321000071],
            [-80.778747886999952, 36.111616750000053],
            [-80.781787798999972, 36.111598899000057],
            [-80.784827632999963, 36.111573767000039],
            [-80.78786735999995, 36.111541355000043],
            [-80.790906955999958, 36.11150166300007],
            [-80.793946391999953, 36.111454690000073],
            [-80.796985641999981, 36.111400438000032],
            [-80.800024678999989, 36.11133890800005],
            [-80.803063476999966, 36.11127009900008],
            [-80.806102007999982, 36.111194012000055],
            [-80.809140246999959, 36.111110648000079],
            [-80.812178164999978, 36.111020007000036],
            [-80.815215737999949, 36.110922091000077],
            [-80.818252936999954, 36.110816901000078],
            [-80.821289735999983, 36.110704436000049],
            [-80.82432610799998, 36.110584699000071],
            [-80.827362026999936, 36.110457690000032],
            [-80.830397464999976, 36.110323410000035],
            [-80.833432396999967, 36.110181861000058],
            [-80.836466794999978, 36.110033043000044],
            [-80.839500632999943, 36.109876959000076],
            [-80.842533883999977, 36.109713609000039],
            [-80.845566521999956, 36.109542995000027],
            [-80.84859851899995, 36.109365118000028],
            [-80.851629847999959, 36.109179981000068],
            [-80.854660484999954, 36.108987584000033],
            [-80.857690399999967, 36.108787929000073],
            [-80.860719568999968, 36.108581018000052],
            [-80.86374796399997, 36.108366853000064],
            [-80.866775558999962, 36.108145436000029],
            [-80.869802325999956, 36.107916769000042],
            [-80.87282823999999, 36.107680853000033],
            [-80.875853273999951, 36.10743769100003],
            [-80.878877400999954, 36.107187285000066],
            [-80.881900593999944, 36.106929637000064],
            [-80.884922826999968, 36.106664749000061],
            [-80.887944073999961, 36.10639262400008],
            [-80.89096430699999, 36.106113264000044],
            [-80.89398349999999, 36.105826672000035],
            [-80.897001626999952, 36.105532850000031],
            [-80.90001866099999, 36.10523180000007],
            [-80.903034575999982, 36.10492352600005],
            [-80.906049343999939, 36.104608030000065],
            [-80.909062940999945, 36.104285314000037],
            [-80.912075337999966, 36.103955382000038],
            [-80.915086508999934, 36.103618237000035],
            [-80.918096428999945, 36.103273881000064],
            [-80.921105069999953, 36.102922318000026],
            [-80.924112405999949, 36.10256355000007],
            [-80.927118409999935, 36.102197581000041],
            [-80.930123056999946, 36.101824414000077],
            [-80.933126319999985, 36.10144405300008],
            [-80.93612817199994, 36.101056500000027],
            [-80.939128585999981, 36.100661758000058],
            [-80.942127537999966, 36.100259833000052],
            [-80.945124999999962, 36.099850726000057],
            [-80.948120944999971, 36.099434442000074],
            [-80.951115347999973, 36.099010985000064],
            [-80.95410818199997, 36.09858035700006],
            [-80.957099420999953, 36.098142563000067],
            [-80.960089038999968, 36.097697607000043],
            [-80.96307700899996, 36.09724549200007],
            [-80.966063304999977, 36.096786223000038],
            [-80.969047900999954, 36.096319803000029],
            [-80.972030770999936, 36.095846237000046],
            [-80.975011887999983, 36.095365529000048],
            [-80.977991225999972, 36.09487768200006],
            [-80.98096875899995, 36.094382703000065],
            [-80.983944460999965, 36.093880593000051],
            [-80.986918304999961, 36.093371359000059],
            [-80.989890265999975, 36.092855005000047],
            [-80.992860317999941, 36.092331534000039],
            [-80.995828433999975, 36.091800952000028],
            [-80.998794587999953, 36.091263264000077],
            [-81.001758754999969, 36.090718474000028],
            [-81.004720907999967, 36.090166586000066],
            [-81.007681020999939, 36.089607607000062],
            [-81.010639067999989, 36.089041540000039],
            [-81.013595023999983, 36.08846839000006],
            [-81.01654886099999, 36.087888163000059],
            [-81.019500554999979, 36.087300864000042],
            [-81.022450079999942, 36.086706498000069],
            [-81.025397408999936, 36.086105070000031],
            [-81.028342516999942, 36.085496585000044],
            [-81.03128537799995, 36.084881050000035],
            [-81.034225964999962, 36.08425846800003],
            [-81.037164253999947, 36.083628846000067],
            [-81.040100217999964, 36.082992189000038],
            [-81.043033831999935, 36.082348503000048],
            [-81.045965069999966, 36.081697793000046],
            [-81.048893905999989, 36.081040066000071],
            [-81.051820314999986, 36.08037532600008],
            [-81.054744269999958, 36.079703580000057],
            [-81.057665746999987, 36.079024834000052],
            [-81.060584718999962, 36.078339093000068],
            [-81.063501160999976, 36.077646365000078],
            [-81.066415047999953, 36.07694665300005],
            [-81.069326352999951, 36.07623996600006],
            [-81.07223505199994, 36.075526309000054],
            [-81.075141117999976, 36.074805688000026],
            [-81.078044526999975, 36.074078110000073],
            [-81.080945251999935, 36.073343580000028],
            [-81.083843269999988, 36.07260210700008],
            [-81.086738552999975, 36.071853695000073],
            [-81.089631076999979, 36.071098352000035],
            [-81.09252081599999, 36.070336084000076],
            [-81.095407744999989, 36.069566898000062],
            [-81.098291838999955, 36.068790801000034],
            [-81.101173072999984, 36.068007800000032],
            [-81.104051419999962, 36.06721790000006],
            [-81.106926856999962, 36.066421110000078],
            [-81.109799357999975, 36.065617437000071],
            [-81.112668896999935, 36.064806886000042],
            [-81.115535449999982, 36.063989466000066],
            [-81.118398990999935, 36.06316518400007],
            [-81.121259494999947, 36.062334047000036],
            [-81.124116937999986, 36.06149606200006],
            [-81.126971294999976, 36.060651236000069],
            [-81.129822538999974, 36.059799578000025],
            [-81.132670646999941, 36.058941094000033],
            [-81.135515593999969, 36.058075791000078],
            [-81.138357353999936, 36.057203679000054],
            [-81.141195902999982, 36.056324763000077],
            [-81.144031215999973, 36.055439053000043],
            [-81.146863267999947, 36.054546555000059],
            [-81.149692033999941, 36.053647278000028],
            [-81.15251748999998, 36.052741229000048],
            [-81.155339609999942, 36.051828417000024],
            [-81.158158370999956, 36.050908849000052],
            [-81.160973747999947, 36.049982534000037],
            [-81.16378571499996, 36.049049479000075],
            [-81.166594248999957, 36.048109693000072],
            [-81.169399323999983, 36.047163184000055],
            [-81.172200916999941, 36.046209960000056],
            [-81.174999002999982, 36.045250030000034],
            [-81.177793557999962, 36.044283402000076],
            [-81.180584555999985, 36.04331008500003],
            [-81.183371973999954, 36.042330087000039],
            [-81.186155786999961, 36.041343417000064],
            [-81.188935970999978, 36.040350083000078],
            [-81.191712501999973, 36.039350094000042],
            [-81.194485354999983, 36.038343459000032],
            [-81.197254505999979, 36.037330187000066],
            [-81.200019931999975, 36.036310286000059],
            [-81.202781606999963, 36.035283766000077],
            [-81.205539508999948, 36.034250636000024],
            [-81.208293611999977, 36.033210904000043],
            [-81.211043892999953, 36.032164580000028],
            [-81.213790327999959, 36.031111673000055],
            [-81.216532891999975, 36.030052191000038],
            [-81.21927156199996, 36.028986146000079],
            [-81.222006314999987, 36.027913545000047],
            [-81.22473712499999, 36.026834398000062],
            [-81.227463969999974, 36.025748715000077],
            [-81.230186825999965, 36.024656505000053],
            [-81.232905667999944, 36.023557778000054],
            [-81.235620473999973, 36.022452544000032],
            [-81.238331218999974, 36.021340811000073],
            [-81.241037879999965, 36.02022259000006],
            [-81.24374043399996, 36.019097891000058],
            [-81.246438855999941, 36.017966723000029],
            [-81.249133123999968, 36.016829097000027],
            [-81.251823212999966, 36.015685022000071],
            [-81.25450910099994, 36.014534508000054],
            [-81.257190763999972, 36.013377566000031],
            [-81.259868178999966, 36.012214206000067],
            [-81.262541321999947, 36.011044437000066],
            [-81.265210169999989, 36.009868270000027],
            [-81.267874699999936, 36.008685716000059],
            [-81.270534888999975, 36.007496785000058],
            [-81.273190713999952, 36.006301486000041],
            [-81.275842150999949, 36.005099832000042],
            [-81.278489177999973, 36.003891831000033],
            [-81.281131770999934, 36.002677495000057],
            [-81.283769907999954, 36.001456835000056],
            [-81.286403564999944, 36.000229860000047],
            [-81.289032719999966, 35.998996583000064],
            [-81.29165734999998, 35.997757013000069],
            [-81.294277431999944, 35.996511161000058],
            [-81.296892943999978, 35.995259039000075],
            [-81.299503860999948, 35.994000657000072],
            [-81.302110162999952, 35.992736027000035],
            [-81.304711826999949, 35.991465159000029],
            [-81.307308827999975, 35.990188065000041],
            [-81.309901146999948, 35.988904755000078],
            [-81.312488757999972, 35.987615241000071],
            [-81.315071641999964, 35.986319535000064],
            [-81.31764977399996, 35.985017647000063],
            [-81.320223131999967, 35.983709590000046],
            [-81.322791694999978, 35.982395374000077],
            [-81.325355438999964, 35.98107501100003],
            [-81.327914343999964, 35.979748512000072],
            [-81.330468384999961, 35.978415890000065],
            [-81.333017542999983, 35.977077155000075],
            [-81.335561792999954, 35.975732320000077],
            [-81.338101114999972, 35.974381396000069],
            [-81.34063548599994, 35.973024396000028],
            [-81.343164883999975, 35.971661330000074],
            [-81.34568928799996, 35.970292212000061],
            [-81.348208675999956, 35.968917052000052],
            [-81.350723024999979, 35.96753586300008],
            [-81.353232313999968, 35.966148658000066],
            [-81.355736521999972, 35.964755447000073],
            [-81.358235625999953, 35.963356244000067],
            [-81.36072960599995, 35.961951061000036],
            [-81.36321843799999, 35.960539909000033],
            [-81.36570210299999, 35.959122802000024],
            [-81.368180577999965, 35.957699751000064],
            [-81.370653842999957, 35.956270769000071],
            [-81.373121874999981, 35.954835869000078],
            [-81.375584652999976, 35.953395064000063],
            [-81.378042156999982, 35.951948365000078],
            [-81.380494363999958, 35.950495785000044],
            [-81.382941254999935, 35.94903733700005],
            [-81.385382806999985, 35.947573035000062],
            [-81.38781899899999, 35.946102889000031],
            [-81.39024981099999, 35.944626915000072],
            [-81.39267522099999, 35.943145124000068],
            [-81.395095208999976, 35.941657529000054],
            [-81.397509753999941, 35.94016414400005],
            [-81.399918834999937, 35.938664981000045],
            [-81.402322430999959, 35.937160053000071],
            [-81.404720521999934, 35.935649374000036],
            [-81.407113085999981, 35.934132958000077],
            [-81.40950010399996, 35.932610816000079],
            [-81.41188155399999, 35.931082963000051],
            [-81.414257415999941, 35.929549411000039],
            [-81.416627669999968, 35.928010175000054],
            [-81.418992294999953, 35.926465267000026],
            [-81.421351270999935, 35.924914702000024],
            [-81.423704577999956, 35.923358492000034],
            [-81.426052194999954, 35.921796652000069],
            [-81.42839410199997, 35.920229194000058],
            [-81.430730279999977, 35.91865613300007],
            [-81.433060706999981, 35.917077483000071],
            [-81.435385364999945, 35.915493256000047],
            [-81.437704231999987, 35.913903468000058],
            [-81.440017288999968, 35.912308131000032],
            [-81.442324516999975, 35.910707261000027],
            [-81.444625894999945, 35.909100870000032],
            [-81.446921402999976, 35.907488973000056],
            [-81.449211022999975, 35.905871584000067],
            [-81.451494733999937, 35.904248716000041],
            [-81.453772515999958, 35.902620385000034],
            [-81.456044350999946, 35.900986605000071],
            [-81.458310217999951, 35.89934738900007],
            [-81.460570097999948, 35.897702752000043],
            [-81.462823972999956, 35.89605270800007],
            [-81.46507182199997, 35.894397272000049],
            [-81.467313625999964, 35.892736458000059],
            [-81.469549365999967, 35.891070281000054],
            [-81.471779022999954, 35.889398755000059],
            [-81.474002578999944, 35.887721895000027],
            [-81.476220011999942, 35.886039716000028],
            [-81.478431305999948, 35.884352231000037],
            [-81.480636440999945, 35.882659456000056],
            [-81.482835397999963, 35.88096140600004],
            [-81.485028157999977, 35.879258095000068],
            [-81.48721470299995, 35.877549539000029],
            [-81.489395012999978, 35.875835752000057],
            [-81.491569070999958, 35.874116748000063],
            [-81.493736856999988, 35.872392544000036],
            [-81.495898353999962, 35.870663154000056],
            [-81.49805354199998, 35.868928594000067],
            [-81.500202403999936, 35.867188877000046],
            [-81.502344919999985, 35.865444020000041],
            [-81.504481072999965, 35.863694038000062],
            [-81.506610844999955, 35.861938946000066],
            [-81.508734216999983, 35.860178759000064],
            [-81.510851170999956, 35.858413493000057],
            [-81.512961688999951, 35.856643162000069],
            [-81.515065753999977, 35.854867783000032],
            [-81.517163346999951, 35.853087371000072],
            [-81.519254449999949, 35.851301942000077],
            [-81.52133904599998, 35.84951151000007],
            [-81.523417115999962, 35.847716093000031],
            [-81.52548864399995, 35.845915704000049],
            [-81.527553610999973, 35.844110360000059],
            [-81.529612000999975, 35.842300077000061],
            [-81.531663794999986, 35.840484870000068],
            [-81.533708975999957, 35.838664756000071],
            [-81.535747526999955, 35.836839749000035],
            [-81.537779429999944, 35.835009867000053],
            [-81.539804667999988, 35.833175124000036],
            [-81.541823224999973, 35.831335538000076],
            [-81.543835081999987, 35.829491123000025],
            [-81.545840221999981, 35.827641896000046],
            [-81.547838629999944, 35.825787874000071],
            [-81.549830287999953, 35.823929071000066],
            [-81.55181517799997, 35.822065505000069],
            [-81.553793283999937, 35.82019719200008],
            [-81.555764589999967, 35.818324147000055],
            [-81.557729078999955, 35.816446388000031],
            [-81.559686732999978, 35.814563930000077],
            [-81.561637536999967, 35.812676790000069],
            [-81.563581474999978, 35.810784985000055],
            [-81.565518527999984, 35.808888530000047],
            [-81.567448681999963, 35.806987443000025],
            [-81.569371918999934, 35.805081739000059],
            [-81.571288224999989, 35.803171436000071],
            [-81.573197580999988, 35.801256549000072],
            [-81.575099972999965, 35.799337097000034],
            [-81.576995383999986, 35.797413095000024],
            [-81.578883798999982, 35.795484560000034],
            [-81.580765199999973, 35.793551509000054],
            [-81.582639573999984, 35.791613958000028],
            [-81.584506901999987, 35.78967192600004],
            [-81.586367170999949, 35.787725427000055],
            [-81.588220363999937, 35.785774480000043],
            [-81.590066465999939, 35.783819102000052],
            [-81.591905460999953, 35.781859308000037],
            [-81.593737332999979, 35.779895117000024],
            [-81.595562067999936, 35.77792654600006],
            [-81.597379648999947, 35.775953611000034],
            [-81.599190061999934, 35.77397632900005],
            [-81.600993291999941, 35.771994719000077],
            [-81.602789321999978, 35.77000879600007],
            [-81.604578138999955, 35.768018579000056],
            [-81.606359726999983, 35.766024085000026],
            [-81.608134071999984, 35.764025331000028],
            [-81.609901156999967, 35.762022334000051],
            [-81.611660968999956, 35.76001511100003],
            [-81.613413492999939, 35.758003681000048],
            [-81.615158713999961, 35.75598806000005],
            [-81.616896617999942, 35.753968267000062],
            [-81.618627188999938, 35.751944318000028],
            [-81.620350413999972, 35.749916232000032],
            [-81.622066277999977, 35.747884025000076],
            [-81.62377476599994, 35.745847716000071],
            [-81.625475864999942, 35.743807322000066],
            [-81.62716955999997, 35.741762861000041],
            [-81.628855836999946, 35.739714351000032],
            [-81.630534681999961, 35.737661809000031],
            [-81.632206080999936, 35.735605253000074],
            [-81.633870019999961, 35.733544702000074],
            [-81.635526484999957, 35.731480172000033],
            [-81.637175462999949, 35.72941168300008],
            [-81.63881693899998, 35.727339251000046],
            [-81.640450899999962, 35.725262895000071],
            [-81.642077331999985, 35.723182632000032],
            [-81.643696222999949, 35.721098482000059],
            [-81.645307556999967, 35.719010462000028],
            [-81.646911322999983, 35.716918589000045],
            [-81.648507505999987, 35.714822883000068],
            [-81.650096092999945, 35.71272336100003],
            [-81.651677071999984, 35.710620042000073],
            [-81.653250428999968, 35.708512944000063],
            [-81.654816149999988, 35.706402084000047],
            [-81.65637422399999, 35.704287482000041],
            [-81.657924636999951, 35.702169156000025],
            [-81.659467375999952, 35.700047124000037],
            [-81.66100242899995, 35.697921405000045],
            [-81.662529781999979, 35.695792016000041],
            [-81.664049423999984, 35.693658976000052],
            [-81.665561340999943, 35.691522305000035],
            [-81.667065521999973, 35.689382020000039],
            [-81.668561952999937, 35.687238139000044],
            [-81.670050622999952, 35.685090683000055],
            [-81.671531517999938, 35.682939668000074],
            [-81.673004627999944, 35.680785114000059],
            [-81.674469938999948, 35.678627039000048],
            [-81.675927439999953, 35.676465462000067],
            [-81.677377117999981, 35.674300403000075],
            [-81.67881896199998, 35.672131878000073],
            [-81.680252960999951, 35.669959908000067],
            [-81.681679100999986, 35.667784511000036],
            [-81.683097370999974, 35.665605706000065],
            [-81.684507760999963, 35.663423512000065],
            [-81.685910256999989, 35.661237948000064],
            [-81.687304848999986, 35.659049032000041],
            [-81.688691525999957, 35.65685678300008],
            [-81.690070274999982, 35.654661221000026],
            [-81.691441085999941, 35.652462365000076],
            [-81.692803946999959, 35.65026023300004],
            [-81.694158846999983, 35.648054845000047],
            [-81.695505775999948, 35.645846220000067],
            [-81.696844720999934, 35.64363437600008],
            [-81.69817567299998, 35.641419333000044],
            [-81.699498619999986, 35.639201111000034],
            [-81.700813551999943, 35.636979727000039],
            [-81.702120457999968, 35.634755202000065],
            [-81.703419326999949, 35.632527555000024],
            [-81.704710147999947, 35.630296805000057],
            [-81.705992911999942, 35.628062971000077],
            [-81.707267606999949, 35.625826072000052],
            [-81.708534223999948, 35.623586129000046],
            [-81.709792751999942, 35.621343159000048],
            [-81.71104318099998, 35.619097184000054],
            [-81.71228549999995, 35.616848221000055],
            [-81.713519700999939, 35.614596291000055],
            [-81.714745770999968, 35.612341413000024],
            [-81.715963702999943, 35.610083606000046],
            [-81.717173485999979, 35.607822891000069],
            [-81.718375109999954, 35.605559285000027],
            [-81.719568564999975, 35.603292810000028],
            [-81.720753841999965, 35.601023485000042],
            [-81.721930931999964, 35.598751328000048],
            [-81.723099823999974, 35.596476361000043],
            [-81.724260509999965, 35.594198602000063],
            [-81.725412979999987, 35.591918071000066],
            [-81.726557223999976, 35.589634788000069],
            [-81.727693234999947, 35.587348772000041],
            [-81.728821000999972, 35.585060044000045],
            [-81.729940515999942, 35.58276862300005],
            [-81.731051768999976, 35.580474528000025],
            [-81.732154750999939, 35.578177781000079],
            [-81.733249454999964, 35.575878399000032],
            [-81.734335869999938, 35.57357640400005],
            [-81.735413988999937, 35.571271815000046],
            [-81.736483802999942, 35.568964652000034],
            [-81.73754530299999, 35.566654935000031],
            [-81.73859848099994, 35.564342684000053],
            [-81.739643327999943, 35.562027919000059],
            [-81.740679836999959, 35.559710659000075],
            [-81.741707997999981, 35.557390926000039],
            [-81.742727804999959, 35.555068738000045],
            [-81.74373924799994, 35.552744115000053],
            [-81.744742319999943, 35.550417079000056],
            [-81.745737012999939, 35.548087648000035],
            [-81.746723317999965, 35.545755843000052],
            [-81.747701228999972, 35.543421685000055],
            [-81.748670736999941, 35.541085192000025],
            [-81.749631835999935, 35.538746386000071],
            [-81.750584515999947, 35.536405287000036],
            [-81.751528770999982, 35.534061914000063],
            [-81.752464593999946, 35.531716288000041],
            [-81.753391976999978, 35.529368428000055],
            [-81.754310911999937, 35.527018357000031],
            [-81.755221393999989, 35.524666092000075],
            [-81.756123412999955, 35.522311656000056],
            [-81.757016964999934, 35.519955067000069],
            [-81.757902040999966, 35.517596347000051],
            [-81.758778633999952, 35.515235515000029],
            [-81.759646738999948, 35.512872593000054],
            [-81.760506346999989, 35.510507600000039],
            [-81.76135745299996, 35.508140556000058],
            [-81.76220004999999, 35.505771482000057],
            [-81.763034130999984, 35.503400399000043],
            [-81.763859689999947, 35.501027327000031],
            [-81.764676720999944, 35.498652286000038],
            [-81.765485216999934, 35.496275297000068],
            [-81.766285171999982, 35.493896380000024],
            [-81.76707657999998, 35.491515556000024],
            [-81.767859435999981, 35.489132844000039],
            [-81.768633731999955, 35.486748267000053],
            [-81.769399462999957, 35.484361843000045],
            [-81.770156622999934, 35.481973594000067],
            [-81.770905206999942, 35.479583541000068],
            [-81.771645207999939, 35.477191703000074],
            [-81.772376621999967, 35.474798101000033],
            [-81.773099441999989, 35.472402756000065],
            [-81.773813662999942, 35.470005689000061],
            [-81.774519280999982, 35.467606920000037],
            [-81.775216287999967, 35.465206469000066],
            [-81.775904680999986, 35.462804358000028],
            [-81.776584453999988, 35.460400607000054],
            [-81.777255602999958, 35.457995237000034],
            [-81.777918120999971, 35.455588267000053],
            [-81.778572003999955, 35.453179720000037],
            [-81.779217247999952, 35.450769616000059],
            [-81.77985384699997, 35.448357975000079],
            [-81.780481796999936, 35.445944818000044],
            [-81.781101092999961, 35.443530165000027],
            [-81.781711730999973, 35.44111403900007],
            [-81.782313705999968, 35.438696458000038],
            [-81.782907013999989, 35.43627744500003],
            [-81.783491649999974, 35.433857020000062],
            [-81.784067609999965, 35.431435203000035],
            [-81.784634890999939, 35.429012015000069],
            [-81.785193486999958, 35.426587478000044],
            [-81.78574339499994, 35.424161612000034],
            [-81.786284611999974, 35.421734438000044],
            [-81.786817131999953, 35.419305976000032],
            [-81.787340952999955, 35.416876248000051],
            [-81.787856069999975, 35.414445274000059],
            [-81.788362479999989, 35.412013075000061],
            [-81.788860179999972, 35.409579673000053],
            [-81.789349165999965, 35.40714508700006],
            [-81.789829433999955, 35.404709339000078],
            [-81.790300981999962, 35.402272450000055],
            [-81.790763804999983, 35.399834441000053],
            [-81.791217900999982, 35.397395332000031],
            [-81.79166326699999, 35.39495514400005],
            [-81.792099899999982, 35.392513899000051],
            [-81.792527795999945, 35.390071617000046],
            [-81.792946952999955, 35.387628320000033],
            [-81.793357366999942, 35.385184027000037],
            [-81.793759037999962, 35.382738761000041],
            [-81.794151959999965, 35.380292541000074],
            [-81.794536132999951, 35.377845390000061],
            [-81.794911552999963, 35.37539732700003],
            [-81.795278218999954, 35.372948375000078],
            [-81.795636126999966, 35.370498553000061],
            [-81.795985274999964, 35.368047884000077],
            [-81.796325661999958, 35.365596387000039],
            [-81.796657284999981, 35.363144084000055],
            [-81.796980141999938, 35.360690996000073],
            [-81.797294230999967, 35.358237144000043],
            [-81.797599550999962, 35.355782549000025],
            [-81.797896098999956, 35.353327232000026],
            [-81.798183872999971, 35.350871214000051],
            [-81.798462872999949, 35.348414516000048],
            [-81.798733095999978, 35.345957158000033],
            [-81.798994540999956, 35.343499163000047],
            [-81.799247205999961, 35.341040551000049],
            [-81.799491089999947, 35.338581343000044],
            [-81.799726192999969, 35.336121560000038],
            [-81.799952510999958, 35.333661223000036],
            [-81.800170045999948, 35.331200353000042],
            [-81.800378793999982, 35.328738972000053],
            [-81.800578756999982, 35.326277100000027],
            [-81.800769930999934, 35.323814758000026],
            [-81.80095231699994, 35.321351968000045],
            [-81.801125913999954, 35.318888750000042],
            [-81.801290721999976, 35.31642512600007],
            [-81.801446738999971, 35.31396111600003],
            [-81.801593964999938, 35.311496743000077],
            [-81.801732398999945, 35.309032025000079],
            [-81.801862041999982, 35.306566986000064],
            [-81.801982892999945, 35.30410164500006],
            [-81.802094950999958, 35.30163602500005],
            [-81.802198216999955, 35.299170145000062],
            [-81.802292689999945, 35.296704028000079],
            [-81.802378370999975, 35.294237693000071],
            [-81.802455258999942, 35.291771163000078],
            [-81.802523354999948, 35.289304458000061],
            [-81.802582658999938, 35.28683759900008],
            [-81.802633170999968, 35.284370608000074],
            [-81.802674890999981, 35.281903505000059],
            [-81.802707820999956, 35.27943631200003],
            [-81.80273195999996, 35.276969049000058],
            [-81.802747308999983, 35.274501738000026],
            [-81.802753867999968, 35.272034400000052],
            [-81.80275163999994, 35.269567055000039],
            [-81.802740622999977, 35.267099726000026],
            [-81.80272081999999, 35.264632432000042],
            [-81.80269223099998, 35.26216519500008],
            [-81.802654856999936, 35.259698036000032],
            [-81.802608698999961, 35.257230976000073],
            [-81.802553758999977, 35.25476403600004],
            [-81.802490036999984, 35.25229723700005],
            [-81.80241753599995, 35.249830601000042],
            [-81.80233625599999, 35.247364148000031],
            [-81.802246199999956, 35.244897898000033],
            [-81.802147367999964, 35.242431874000033],
            [-81.802039761999936, 35.239966097000035],
            [-81.801923383999963, 35.237500586000067],
            [-81.801798235999968, 35.235035364000055],
            [-81.80166431899994, 35.232570452000061],
            [-81.801521635999961, 35.230105869000056],
            [-81.801370188999954, 35.22764163800008],
            [-81.801209978999964, 35.225177780000024],
            [-81.80104100899996, 35.222714314000029],
            [-81.800863280999977, 35.22025126300008],
            [-81.800676796999937, 35.217788648000067],
            [-81.80048155999998, 35.215326488000073],
            [-81.80027757199997, 35.212864806000027],
            [-81.80006483599999, 35.210403622000058],
            [-81.799843353999961, 35.207942957000057],
            [-81.799613129999955, 35.20548283200003],
            [-81.799374164999961, 35.203023269000028],
            [-81.799126461999947, 35.200564287000077],
            [-81.798870024999985, 35.198105908000059],
            [-81.79860485599994, 35.195648154000025],
            [-81.798330958999941, 35.193191044000059],
            [-81.798048336999955, 35.190734599000052],
            [-81.797756991999961, 35.188278842000045],
            [-81.797456927999974, 35.185823791000075],
            [-81.797148148999952, 35.183369470000059],
            [-81.796830656999987, 35.180915897000034],
            [-81.796504456999969, 35.178463095000041],
            [-81.796169551999981, 35.176011083000049],
            [-81.79582594499999, 35.173559884000042],
            [-81.795473639999955, 35.171109517000048],
            [-81.795112641999935, 35.16866000300007],
            [-81.794742952999968, 35.166211364000048],
            [-81.794364577999943, 35.163763621000044],
            [-81.793977520999988, 35.161316793000026],
            [-81.793581784999958, 35.158870902000046],
            [-81.79317737599996, 35.156425969000054],
            [-81.792764295999973, 35.153982014000064],
            [-81.79234255099999, 35.151539058000026],
            [-81.791912144999969, 35.149097122000057],
            [-81.791473082999971, 35.146656226000061],
            [-81.791025367999964, 35.144216392000033],
            [-81.790569004999952, 35.141777640000043],
            [-81.790103998999939, 35.139339991000043],
            [-81.789630354999986, 35.136903466000035],
            [-81.789148077999982, 35.134468085000037],
            [-81.788657171999944, 35.132033868000065],
            [-81.788157641999987, 35.129600837000055],
            [-81.787649493999936, 35.127169013000071],
            [-81.787132731999975, 35.124738415000024],
            [-81.786607361999984, 35.122309065000024],
            [-81.786073387999977, 35.11988098300003],
            [-81.785530816999938, 35.117454190000046],
            [-81.784979652999937, 35.115028706000032],
            [-81.784419901999968, 35.11260455200005],
            [-81.783851569999968, 35.110181748000059],
            [-81.78327466099995, 35.107760316000054],
            [-81.782689181999956, 35.10534027500006],
            [-81.782095138999978, 35.102921646000027],
            [-81.781492536999963, 35.100504450000074],
            [-81.780881381999961, 35.098088707000045],
            [-81.780261679999967, 35.09567443800006],
            [-81.779633435999983, 35.093261662000032],
            [-81.778996657999983, 35.090850402000058],
            [-81.778351351999959, 35.088440676000062],
            [-81.777697522999972, 35.086032506000038],
            [-81.777035176999959, 35.08362591100007],
            [-81.776364321999949, 35.081220913000038],
            [-81.775684962999946, 35.078817532000073],
            [-81.77499710799998, 35.07641578700003],
            [-81.774300761999939, 35.074015700000075],
            [-81.773595931999978, 35.071617291000052],
            [-81.772882625999955, 35.069220579000046],
            [-81.772160848999988, 35.06682558600005],
            [-81.771430608999935, 35.064432332000024],
            [-81.770691911999961, 35.062040836000051],
            [-81.76994476699997, 35.059651120000069],
            [-81.769189178999966, 35.057263203000048],
            [-81.768425154999989, 35.05487710500006],
            [-81.767652703999943, 35.052492847000053],
            [-81.766871831999936, 35.050110450000034],
            [-81.766082546999939, 35.047729932000038],
            [-81.765284854999948, 35.045351315000062],
            [-81.764478765999968, 35.042974618000073],
            [-81.763664284999948, 35.040599862000079],
            [-81.762841420999962, 35.038227066000047],
            [-81.762010181999983, 35.035856251000041],
            [-81.761170573999948, 35.033487437000076],
            [-81.760322606999978, 35.031120644000055],
            [-81.759466287999942, 35.028755892000049],
            [-81.758601623999937, 35.02639320000003],
            [-81.75772862499997, 35.024032590000047],
            [-81.756847296999979, 35.021674080000025],
            [-81.755957649999971, 35.019317691000026],
            [-81.755059690999985, 35.016963443000066],
            [-81.754153427999938, 35.014611355000056],
            [-81.753238870999951, 35.012261448000061],
            [-81.752316027999939, 35.009913741000048],
            [-81.751384905999942, 35.007568254000034],
            [-81.750445514999967, 35.005225008000025],
            [-81.749497863999977, 35.002884021000057],
            [-81.748541959999955, 35.000545314000078],
            [-81.747577812999964, 34.998208906000059],
            [-81.746605431999967, 34.995874817000072],
            [-81.745624825999982, 34.993543068000065],
            [-81.744636002999982, 34.991213676000029],
            [-81.743638972999975, 34.988886663000073],
            [-81.742633743999988, 34.986562048000053],
            [-81.741620325999975, 34.984239851000041],
            [-81.740598728999942, 34.981920090000074],
            [-81.739568960999975, 34.979602786000044],
            [-81.738531031999969, 34.97728795900008],
            [-81.737484950999942, 34.974975627000049],
            [-81.736430727999959, 34.97266581100007],
            [-81.735368372999972, 34.970358529000066],
            [-81.734297893999951, 34.968053802000043],
            [-81.733219302999942, 34.965751649000026],
            [-81.732132607999972, 34.963452089000043],
            [-81.731037818999937, 34.961155141000063],
            [-81.729934946999947, 34.958860826000034],
            [-81.728824001999953, 34.956569162000051],
            [-81.727704991999985, 34.954280168000025],
            [-81.726577928999973, 34.951993865000077],
            [-81.72544282299998, 34.949710271000072],
            [-81.724299683999959, 34.947429405000037],
            [-81.723148521999974, 34.945151287000044],
            [-81.721989347999966, 34.942875937000053],
            [-81.720822171999941, 34.940603372000055],
            [-81.719647003999967, 34.938333613000054],
            [-81.718463855999971, 34.936066678000032],
            [-81.717272736999973, 34.933802587000059],
            [-81.71607365899996, 34.93154135900005],
            [-81.714866631999939, 34.929283012000042],
            [-81.713651667999954, 34.927027566000049],
            [-81.712428775999967, 34.924775040000043],
            [-81.711197967999965, 34.922525453000048],
            [-81.709959254999944, 34.920278823000046],
            [-81.70871264799996, 34.918035170000053],
            [-81.707458157999952, 34.915794513000037],
            [-81.706195796999964, 34.913556870000036],
            [-81.704925575999937, 34.911322260000077],
            [-81.703647504999935, 34.909090703000061],
            [-81.702361596999936, 34.906862216000036],
            [-81.701067862999935, 34.904636819000075],
            [-81.699766313999987, 34.902414531000034],
            [-81.698456961999966, 34.900195369000073],
            [-81.697139818999972, 34.897979354000029],
            [-81.695814895999945, 34.895766503000061],
            [-81.694482205999975, 34.893556835000027],
            [-81.693141758999957, 34.891350369000065],
            [-81.69179356799998, 34.889147122000054],
            [-81.690437645999964, 34.886947115000055],
            [-81.689074002999973, 34.884750365000059],
            [-81.687702651999984, 34.882556891000036],
            [-81.686323604999984, 34.880366712000068],
            [-81.684936874999948, 34.878179844000044],
            [-81.683542473999978, 34.875996308000026],
            [-81.682140412999956, 34.873816122000051],
            [-81.680730705999963, 34.871639303000052],
            [-81.679313364999985, 34.869465870000056],
            [-81.677888401999951, 34.867295842000033],
            [-81.676455829999952, 34.86512923600003],
            [-81.675015661999964, 34.862966072000063],
            [-81.673567909999974, 34.860806366000077],
            [-81.672112586999958, 34.858650137000041],
            [-81.670649705999949, 34.856497404000038],
            [-81.66917927999998, 34.85434818400006],
            [-81.667701321999971, 34.852202496000075],
            [-81.666215843999964, 34.850060357000075],
            [-81.664722859999983, 34.847921786000029],
            [-81.663222383999937, 34.845786800000042],
            [-81.661714426999936, 34.843655418000026],
            [-81.660199003999935, 34.841527657000029],
            [-81.658676127999968, 34.839403535000031],
            [-81.657145811999953, 34.837283070000069],
            [-81.655608068999982, 34.835166280000067],
            [-81.654062912999962, 34.833053183000061],
            [-81.652510357999972, 34.830943796000042],
            [-81.65095041699999, 34.828838137000048],
            [-81.64938310399998, 34.826736224000058],
            [-81.647808432999966, 34.824638075000053],
            [-81.64622641699998, 34.822543706000033],
            [-81.644637069999987, 34.820453137000072],
            [-81.643040405999955, 34.818366383000068],
            [-81.641436438999961, 34.816283463000048],
            [-81.63982518399996, 34.814204394000058],
            [-81.638206652999941, 34.812129194000079],
            [-81.63658086199996, 34.810057880000045],
            [-81.634947823999937, 34.80799047000005],
            [-81.633307553999941, 34.805926980000038],
            [-81.631660065999938, 34.803867428000046],
            [-81.63000537399995, 34.801811832000055],
            [-81.628343492999988, 34.799760208000066],
            [-81.626674437999952, 34.797712574000059],
            [-81.624998221999988, 34.795668947000024],
            [-81.623314859999937, 34.793629344000067],
            [-81.621624367999971, 34.791593783000053],
            [-81.619926759999942, 34.789562280000041],
            [-81.618222049999986, 34.787534852000078],
            [-81.616510252999944, 34.785511516000042],
            [-81.614791383999943, 34.783492290000027],
            [-81.613065458999984, 34.781477190000032],
            [-81.611332491999974, 34.779466233000051],
            [-81.609592497999984, 34.777459437000061],
            [-81.607845491999967, 34.775456817000077],
            [-81.606091488999937, 34.77345839000003],
            [-81.60433050599994, 34.771464174000073],
            [-81.602562555999953, 34.769474185000036],
            [-81.600787654999976, 34.767488440000079],
            [-81.599005818999956, 34.765506955000035],
            [-81.59721706299996, 34.763529747000064],
            [-81.595421401999943, 34.761556833000043],
            [-81.593618851999963, 34.759588228000041],
            [-81.591809428999966, 34.757623950000038],
            [-81.589993147999962, 34.755664015000036],
            [-81.588170025999943, 34.753708440000025],
            [-81.586340075999942, 34.751757240000074],
            [-81.584503316999985, 34.74981043300005],
            [-81.582659762999981, 34.747868034000078],
            [-81.580809429999988, 34.745930059000045],
            [-81.57895233399995, 34.743996526000046],
            [-81.577088490999984, 34.742067449000047],
            [-81.575217917999964, 34.740142846000026],
            [-81.573340630999951, 34.738222732000054],
            [-81.571456644999955, 34.736307124000064],
            [-81.569565976999968, 34.734396037000067],
            [-81.56766864299999, 34.732489487000066],
            [-81.565764658999967, 34.730587491000051],
            [-81.563854042999935, 34.728690064000034],
            [-81.561936809999963, 34.726797222000073],
            [-81.560012975999939, 34.724908982000045],
            [-81.558082558999956, 34.723025358000029],
            [-81.55614557499996, 34.721146366000028],
            [-81.55420204099994, 34.71927202300003],
            [-81.552251972999954, 34.71740234400005],
            [-81.550295387999938, 34.71553734500003],
            [-81.548332302999938, 34.71367704000005],
            [-81.546362734999946, 34.711821447000034],
            [-81.544386699999961, 34.709970580000061],
            [-81.542404215999966, 34.708124454000028],
            [-81.54041529899996, 34.706283086000042],
            [-81.538419966999982, 34.704446490000066],
            [-81.536418236999964, 34.702614682000046],
            [-81.534410125999955, 34.700787677000051],
            [-81.532395650999945, 34.698965491000024],
            [-81.530374828999982, 34.697148138000045],
            [-81.528347677999989, 34.695335635000049],
            [-81.526314214999957, 34.693527995000068],
            [-81.524274457999979, 34.691725234000046],
            [-81.522228422999945, 34.689927368000042],
            [-81.520176128999935, 34.688134411000078],
            [-81.518117592999943, 34.686346378000053],
            [-81.516052831999957, 34.684563285000024],
            [-81.513981863999959, 34.682785145000025],
            [-81.511904707999975, 34.681011974000057],
            [-81.509821379999948, 34.679243787000075],
            [-81.507731897999975, 34.677480599000035],
            [-81.505636280999965, 34.675722423000025],
            [-81.503534545999969, 34.673969276000037],
            [-81.501426709999976, 34.672221171000047],
            [-81.499312793999934, 34.670478123000066],
            [-81.49719281299997, 34.668740147000051],
            [-81.495066785999938, 34.667007256000034],
            [-81.492934731999981, 34.665279467000062],
            [-81.490796667999973, 34.663556792000065],
            [-81.488652612999942, 34.661839247000046],
            [-81.486502584999982, 34.660126846000026],
            [-81.48434660199996, 34.658419603000027],
            [-81.482184681999968, 34.656717532000073],
            [-81.480016844999966, 34.655020647000072],
            [-81.477843108999934, 34.653328963000035],
            [-81.475663490999978, 34.651642494000043],
            [-81.473478010999941, 34.649961254000061],
            [-81.471286686999974, 34.648285256000065],
            [-81.469089537999935, 34.646614516000056],
            [-81.466886581999972, 34.644949046000079],
            [-81.464677838999989, 34.64328886100003],
            [-81.462463325999977, 34.641633974000058],
            [-81.46024306299995, 34.63998440000006],
            [-81.458017068999936, 34.638340152000069],
            [-81.455785361999972, 34.636701243000061],
            [-81.453547961999959, 34.635067688000049],
            [-81.451304886999935, 34.633439500000065],
            [-81.449056155999983, 34.631816692000029],
            [-81.446801789999938, 34.630199279000067],
            [-81.444541805999961, 34.628587273000051],
            [-81.442276222999965, 34.62698068900005],
            [-81.440005061999955, 34.62537953900005],
            [-81.437728340999968, 34.62378383600003],
            [-81.435446079999963, 34.622193595000056],
            [-81.433158297999967, 34.620608828000059],
            [-81.430865013999949, 34.619029549000061],
            [-81.428566246999935, 34.617455770000049],
            [-81.426262017999989, 34.615887505000046],
            [-81.423952345999965, 34.614324768000074],
            [-81.421637249999947, 34.612767570000074],
            [-81.41931674999995, 34.611215924000078],
            [-81.416990864999946, 34.609669845000042],
            [-81.414659614999948, 34.608129345000066],
            [-81.412323020999963, 34.606594435000034],
            [-81.409981099999982, 34.60506513100006],
            [-81.407633874999988, 34.60354144300004],
            [-81.405281363999961, 34.602023385000052],
            [-81.402923585999986, 34.600510969000027],
            [-81.400560562999942, 34.599004209000043],
            [-81.398192313999971, 34.597503115000052],
            [-81.395818858999974, 34.596007702000065],
            [-81.393440217999967, 34.594517981000024],
            [-81.391056410999965, 34.593033965000075],
            [-81.388667458999976, 34.59155566700008],
            [-81.386273380999967, 34.590083097000047],
            [-81.383874196999955, 34.588616270000045],
            [-81.381469928999934, 34.587155196000026],
            [-81.379060594999942, 34.585699889000068],
            [-81.376646217999962, 34.584250360000055],
            [-81.374226814999986, 34.582806621000032],
            [-81.371802409999987, 34.581368685000029],
            [-81.369373019999955, 34.579936562000057],
            [-81.366938667999989, 34.578510266000023],
            [-81.364499373999934, 34.577089808000039],
            [-81.362055157999976, 34.575675200000035],
            [-81.35960603999996, 34.574266454000053],
            [-81.357152041999939, 34.572863581000036],
            [-81.354693183999984, 34.571466593000025],
            [-81.352229486999988, 34.570075501000076],
            [-81.349760970999967, 34.568690318000051],
            [-81.347287656999981, 34.567311054000072],
            [-81.34480956699997, 34.565937722000058],
            [-81.34232671999996, 34.564570333000063],
            [-81.339839137999945, 34.563208897000038],
            [-81.337346841999988, 34.561853427000074],
            [-81.33484985299998, 34.560503933000064],
            [-81.332348190999937, 34.559160427000052],
            [-81.329841877999968, 34.557822920000035],
            [-81.327330934999964, 34.556491423000068],
            [-81.32481538199994, 34.555165947000035],
            [-81.322295240999949, 34.553846504000035],
            [-81.319770533999986, 34.55253310300003],
            [-81.317241280999951, 34.551225757000054],
            [-81.314707502999966, 34.549924476000058],
            [-81.312169222999955, 34.548629270000049],
            [-81.309626459999947, 34.547340151000071],
            [-81.307079236999982, 34.546057129000076],
            [-81.304527573999962, 34.54478021500006],
            [-81.301971493999986, 34.543509419000031],
            [-81.299411017999944, 34.542244753000034],
            [-81.296846166999956, 34.54098622600003],
            [-81.294276962999959, 34.539733849000072],
            [-81.29170342599997, 34.538487632000056],
            [-81.289125579999961, 34.537247586000035],
            [-81.28654344499995, 34.536013722000064],
            [-81.283957042999987, 34.534786048000058],
            [-81.281366395999953, 34.53356457600006],
            [-81.278771524999968, 34.532349316000079],
            [-81.276172451999969, 34.531140278000066],
            [-81.273569198999951, 34.529937471000039],
            [-81.270961787999966, 34.528740906000053],
            [-81.268350240999951, 34.527550593000058],
            [-81.265734578999968, 34.526366541000073],
            [-81.263114823999956, 34.525188761000038],
            [-81.260490998999956, 34.524017262000029],
            [-81.257863124999972, 34.522852054000055],
            [-81.255231222999953, 34.521693146000075],
            [-81.252595317999976, 34.520540549000032],
            [-81.249955428999954, 34.519394272000056],
            [-81.247311579999973, 34.518254324000054],
            [-81.244663791999983, 34.517120714000043],
            [-81.242012087999967, 34.515993453000078],
            [-81.239356488999988, 34.514872549000074],
            [-81.236697018999962, 34.513758013000029],
            [-81.234033698999951, 34.512649852000038],
            [-81.231366550999951, 34.511548076000054],
            [-81.228695597999945, 34.510452695000026],
            [-81.226020861999984, 34.509363718000031],
            [-81.223342364999951, 34.508281153000041],
            [-81.220660129999942, 34.507205010000064],
            [-81.217974178999953, 34.506135297000071],
            [-81.215284534999967, 34.505072023000025],
            [-81.212591219999979, 34.504015198000047],
            [-81.209894256999974, 34.502964829000064],
            [-81.207193666999956, 34.501920926000025],
            [-81.204489473999956, 34.500883498000064],
            [-81.201781700999959, 34.49985255200005],
            [-81.199070368999969, 34.498828098000047],
            [-81.196355500999971, 34.497810144000027],
            [-81.193637120999938, 34.496798698000077],
            [-81.190915249999989, 34.495793769000045],
            [-81.188189910999938, 34.494795365000073],
            [-81.185461127999986, 34.493803495000066],
            [-81.182728922999956, 34.492818166000063],
            [-81.179993317999958, 34.491839387000027],
            [-81.177254336999965, 34.490867166000044],
            [-81.174512001999972, 34.489901511000028],
            [-81.171766335999962, 34.488942430000066],
            [-81.169017362999966, 34.487989930000026],
            [-81.16626510399999, 34.487044021000031],
            [-81.16350958299995, 34.486104709000074],
            [-81.160750822999944, 34.485172003000059],
            [-81.157988846999956, 34.484245910000027],
            [-81.15522367799997, 34.483326438000063],
            [-81.152455337999982, 34.482413594000036],
            [-81.149683851999953, 34.481507387000079],
            [-81.146909240999946, 34.480607823000071],
            [-81.144131529999981, 34.479714910000041],
            [-81.14135073999995, 34.478828655000029],
            [-81.138566895999986, 34.477949066000065],
            [-81.13578002099996, 34.477076151000063],
            [-81.132990137999968, 34.476209915000027],
            [-81.13019726899995, 34.475350367000033],
            [-81.127401438999982, 34.474497514000063],
            [-81.124602669999945, 34.473651362000055],
            [-81.121800984999936, 34.472811919000037],
            [-81.118996408999976, 34.471979192000049],
            [-81.116188963999946, 34.471153187000027],
            [-81.113378673999989, 34.470333912000058],
            [-81.110565561999977, 34.469521373000077],
            [-81.107749651999939, 34.468715577000069],
            [-81.104930965999984, 34.467916530000025],
            [-81.102109528999961, 34.467124239000043],
            [-81.099285362999979, 34.466338712000038],
            [-81.096458492999943, 34.465559953000025],
            [-81.093628941999953, 34.464787970000032],
            [-81.090796731999944, 34.464022770000042],
            [-81.087961888999985, 34.46326435800006],
            [-81.085124434999955, 34.462512740000079],
            [-81.082284393999942, 34.461767924000071],
            [-81.079441788999986, 34.461029914000051],
            [-81.076596644999938, 34.460298718000047],
            [-81.073748983999963, 34.459574342000053],
            [-81.070898830999965, 34.458856790000027],
            [-81.068046208999988, 34.458146070000055],
            [-81.065191141999946, 34.457442187000026],
            [-81.062333652999939, 34.456745147000049],
            [-81.059473766999986, 34.45605495500007],
            [-81.056611505999967, 34.455371618000072],
            [-81.05374689599995, 34.454695142000048],
            [-81.050879957999939, 34.454025530000024],
            [-81.048010717999944, 34.453362791000075],
            [-81.045139199999937, 34.452706928000055],
            [-81.042265425999972, 34.452057947000071],
            [-81.039389420999953, 34.451415853000071],
            [-81.036511208999968, 34.450780653000038],
            [-81.033630812999945, 34.450152350000053],
            [-81.03074825799996, 34.449530951000042],
            [-81.02786356699994, 34.448916461000067],
            [-81.024976763999973, 34.448308884000028],
            [-81.022087873999965, 34.447708225000042],
            [-81.019196919999956, 34.447114490000047],
            [-81.016303925999978, 34.446527684000046],
            [-81.013408916999936, 34.445947811000053],
            [-81.010511914999938, 34.445374876000074],
            [-81.007612945999938, 34.444808884000054],
            [-81.004712032999976, 34.444249839000065],
            [-81.001809200999958, 34.443697747000044],
            [-80.998904471999936, 34.443152611000073],
            [-80.995997872999965, 34.442614437000032],
            [-80.993089425999983, 34.442083228000058],
            [-80.990179154999964, 34.441558990000033],
            [-80.987267085999974, 34.441041726000037],
            [-80.984353240999951, 34.440531440000029],
            [-80.981437644999971, 34.440028138000059],
            [-80.978520322999941, 34.439531823000038],
            [-80.975601297999958, 34.439042499000038],
            [-80.972680594999986, 34.438560170000073],
            [-80.969758236999951, 34.438084841000034],
            [-80.966834249999977, 34.437616515000059],
            [-80.963908656999934, 34.437155196000049],
            [-80.960981481999966, 34.436700888000075],
            [-80.958052750999968, 34.43625359400005],
            [-80.955122485999937, 34.435813319000033],
            [-80.952190711999947, 34.43538006600005],
            [-80.949257453999962, 34.434953838000069],
            [-80.946322735999956, 34.434534640000038],
            [-80.94338658199996, 34.434122474000048],
            [-80.940449016999935, 34.433717344000058],
            [-80.937510064999969, 34.433319254000025],
            [-80.934569748999934, 34.432928206000042],
            [-80.931628094999951, 34.432544204000067],
            [-80.928685127999984, 34.432167251000067],
            [-80.92574086999997, 34.431797350000068],
            [-80.922795346999976, 34.431434505000027],
            [-80.919848582999975, 34.431078718000037],
            [-80.916900601999942, 34.430729992000067],
            [-80.913951428999951, 34.430388330000028],
            [-80.911001088999967, 34.430053734000069],
            [-80.908049604999974, 34.42972620900008],
            [-80.905097001999934, 34.429405756000051],
            [-80.902143304999981, 34.429092378000064],
            [-80.899188537999976, 34.42878607800003],
            [-80.896232724999948, 34.428486857000053],
            [-80.893275890999973, 34.428194720000079],
            [-80.890318060999959, 34.427909667000051],
            [-80.887359257999947, 34.427631702000042],
            [-80.884399507999944, 34.42736082600004],
            [-80.881438834999983, 34.42709704300006],
            [-80.878477262999979, 34.426840353000046],
            [-80.875514816999953, 34.426590760000067],
            [-80.872551520999934, 34.426348265000058],
            [-80.869587399999944, 34.426112871000043],
            [-80.866622478999943, 34.42588457800008],
            [-80.863656780999975, 34.425663390000068],
            [-80.86069033199999, 34.425449308000054],
            [-80.857723155999963, 34.425242334000075],
            [-80.854755277999971, 34.425042469000061],
            [-80.851786721999986, 34.42484971500005],
            [-80.848817512999972, 34.424664074000077],
            [-80.84584767399997, 34.424485547000074],
            [-80.842877231999978, 34.424314136000078],
            [-80.839906209999981, 34.424149842000077],
            [-80.836934633999988, 34.423992666000061],
            [-80.833962525999937, 34.423842610000065],
            [-80.830989912999939, 34.423699676000069],
            [-80.82801681899997, 34.423563863000027],
            [-80.825043267999945, 34.423435174000076],
            [-80.822069284999941, 34.423313609000047],
            [-80.819094894999978, 34.423199170000032],
            [-80.816120121999973, 34.423091857000031],
            [-80.813144990999945, 34.42299167200008],
            [-80.810169525999981, 34.422898614000076],
            [-80.807193751999989, 34.422812685000054],
            [-80.804217692999941, 34.42273388600006],
            [-80.801241374999961, 34.422662217000038],
            [-80.798264821999965, 34.422597679000035],
            [-80.795288057999983, 34.422540272000049],
            [-80.792311108999968, 34.422489997000071],
            [-80.789333997999961, 34.422446854000043],
            [-80.786356749999982, 34.422410844000069],
            [-80.783379390999983, 34.422381967000035],
            [-80.780401943999948, 34.422360223000055],
            [-80.777424433999954, 34.422345612000072],
            [-80.774446885999964, 34.422338134000029],
            [-80.771469324999941, 34.422337790000029],
            [-80.768491774999973, 34.422344580000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 2,
        "SolutionID": "main",
        "Shape_Length": 5.9036095886013769,
        "Shape_Area": 2.7329901062200164
      }
    },
    {
      "type": "Feature",
      "id": 4,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.836050157999978, 34.93487374800003],
              [-80.836604811999962, 34.934861699000066],
              [-80.837159164999946, 34.934842457000059],
              [-80.837713082999983, 34.934816026000078],
              [-80.838266425999961, 34.93478241300005],
              [-80.838819055999977, 34.934741625000072],
              [-80.839370837999979, 34.934693674000073],
              [-80.83992163299996, 34.93463857100005],
              [-80.840471306999973, 34.934576330000027],
              [-80.841019719999963, 34.934506966000072],
              [-80.841566737999983, 34.934430497000051],
              [-80.842112225999983, 34.934346940000069],
              [-80.842656047999981, 34.934256318000052],
              [-80.843198065999957, 34.934158653000054],
              [-80.843738149999979, 34.934053969000047],
              [-80.84427616399995, 34.93394229200004],
              [-80.844811971999945, 34.933823649000033],
              [-80.845345445999953, 34.933698069000059],
              [-80.845876449999935, 34.933565586000043],
              [-80.846404852999967, 34.933426230000066],
              [-80.846930523999958, 34.933280038000078],
              [-80.847453332999976, 34.933127044000059],
              [-80.847973149999973, 34.932967287000054],
              [-80.848489843999971, 34.932800807000035],
              [-80.849003289999985, 34.932627645000025],
              [-80.849513359999946, 34.932447843000034],
              [-80.85001992499997, 34.932261448000077],
              [-80.850522860999945, 34.932068505000075],
              [-80.851022042999944, 34.931869060000054],
              [-80.851517347999959, 34.931663165000032],
              [-80.852008651999938, 34.931450871000038],
              [-80.852495831999988, 34.931232229000045],
              [-80.852978769999936, 34.931007295000029],
              [-80.853457344999981, 34.930776123000044],
              [-80.853931435999982, 34.93053877300008],
              [-80.854400927999961, 34.930295302000047],
              [-80.854865703999963, 34.930045770000049],
              [-80.855325647999962, 34.929790241000035],
              [-80.85578064699996, 34.929528776000041],
              [-80.85623058699997, 34.92926144200004],
              [-80.856675355999982, 34.928988304000029],
              [-80.857114845999945, 34.928709430000026],
              [-80.857548945999952, 34.928424890000031],
              [-80.857977547999951, 34.928134754000041],
              [-80.858400547999963, 34.927839094000035],
              [-80.858817838999983, 34.927537983000036],
              [-80.859229316999972, 34.927231496000047],
              [-80.859634882999956, 34.92691971000005],
              [-80.860034431999964, 34.926602701000036],
              [-80.860427867999988, 34.926280549000069],
              [-80.860815093999975, 34.925953332000063],
              [-80.861196012999983, 34.92562113300005],
              [-80.861570527999959, 34.925284034000072],
              [-80.86193855099998, 34.924942118000047],
              [-80.862299986999972, 34.924595472000078],
              [-80.862654747999954, 34.924244179000027],
              [-80.86300274499996, 34.923888327000043],
              [-80.863343892999978, 34.923528005000037],
              [-80.863678105999952, 34.923163303000024],
              [-80.864005303999988, 34.922794310000029],
              [-80.864325401999963, 34.922421120000024],
              [-80.864638323999941, 34.922043822000035],
              [-80.864943989999972, 34.921662512000069],
              [-80.865242325999986, 34.921277284000041],
              [-80.865533256999981, 34.920888234000074],
              [-80.865816710999979, 34.920495458000062],
              [-80.866092617999982, 34.920099054000048],
              [-80.866360910999958, 34.919699120000075],
              [-80.866621520999956, 34.919295755000064],
              [-80.866874385999949, 34.918889059000037],
              [-80.867119441999989, 34.918479134000052],
              [-80.867356626999936, 34.918066080000074],
              [-80.867585884999983, 34.917650001000027],
              [-80.867807156999959, 34.917230999000026],
              [-80.868020390999959, 34.91680917900004],
              [-80.86822553099995, 34.91638464600004],
              [-80.868422528999986, 34.915957503000072],
              [-80.86861133299999, 34.915527858000075],
              [-80.868791898999973, 34.915095818000054],
              [-80.868964181999957, 34.914661488000036],
              [-80.86912813899994, 34.91422497700006],
              [-80.86928372899996, 34.913786394000056],
              [-80.869430913999963, 34.913345847000073],
              [-80.869569658999978, 34.912903444000051],
              [-80.869699925999953, 34.912459297000055],
              [-80.869821686999956, 34.912013516000059],
              [-80.869934909999984, 34.911566210000046],
              [-80.870039566999935, 34.911117491000027],
              [-80.870135630999982, 34.910667469000032],
              [-80.870223080999949, 34.910216258000048],
              [-80.870301892999976, 34.90976396700006],
              [-80.870372049999958, 34.909310710000057],
              [-80.870433532999982, 34.908856600000036],
              [-80.870486326999981, 34.908401748000074],
              [-80.870530420999955, 34.907946268000046],
              [-80.870565799999952, 34.907490272000075],
              [-80.870592459999955, 34.907033874000035],
              [-80.870610390999957, 34.906577187000039],
              [-80.870619590999979, 34.906120324000028],
              [-80.870620057999986, 34.905663398000058],
              [-80.870611789999941, 34.905206522000071],
              [-80.870594790999974, 34.904749810000055],
              [-80.870569063999937, 34.904293376000055],
              [-80.870534615999986, 34.90383733200008],
              [-80.870491456999957, 34.903381791000072],
              [-80.870439595999983, 34.90292686600003],
              [-80.870379045999982, 34.902472671000055],
              [-80.870309823999946, 34.902019317000054],
              [-80.87023194599999, 34.901566918000071],
              [-80.870145431999958, 34.90111558500007],
              [-80.870050302999971, 34.90066543000006],
              [-80.869946581999955, 34.900216565000051],
              [-80.869834297999944, 34.899769101000061],
              [-80.869713474999969, 34.899323150000043],
              [-80.869584143999987, 34.89887882000005],
              [-80.869446340999957, 34.898436224000079],
              [-80.869300095999961, 34.897995470000069],
              [-80.869145445999948, 34.89755666800005],
              [-80.86898242999996, 34.897119926000073],
              [-80.868811090999941, 34.896685353000066],
              [-80.868631466999943, 34.896253057000024],
              [-80.868443606999961, 34.895823144000076],
              [-80.868247554999982, 34.895395722000046],
              [-80.868043359999945, 34.894970895000029],
              [-80.86783107399998, 34.894548771000075],
              [-80.867610748999937, 34.894129452000072],
              [-80.867382438999982, 34.893713043000048],
              [-80.867146201999958, 34.893299648000038],
              [-80.86690209599999, 34.892889369000045],
              [-80.866650181999944, 34.89248230700008],
              [-80.866390521999961, 34.892078563000041],
              [-80.866123180999978, 34.891678238000054],
              [-80.86584822399999, 34.891281431000039],
              [-80.865565720999939, 34.890888239000049],
              [-80.865275742999984, 34.89049876100006],
              [-80.864978357999973, 34.890113093000025],
              [-80.864673643999936, 34.889731330000075],
              [-80.864361673999952, 34.889353567000057],
              [-80.86404252799997, 34.888979898000059],
              [-80.863716280999938, 34.888610416000063],
              [-80.863383017999979, 34.888245211000026],
              [-80.863042819999976, 34.887884376000045],
              [-80.862695770999949, 34.887527997000063],
              [-80.862341957999945, 34.887176165000028],
              [-80.861981468999943, 34.886828967000042],
              [-80.861614390999989, 34.88648648800006],
              [-80.861240817999942, 34.886148813000034],
              [-80.860860841999965, 34.885816025000054],
              [-80.860474555999986, 34.885488209000073],
              [-80.860082056999943, 34.885165444000052],
              [-80.859683440999959, 34.884847810000053],
              [-80.859278808999989, 34.884535387000028],
              [-80.858868258999962, 34.884228252000071],
              [-80.858451892999938, 34.883926480000071],
              [-80.858029816999988, 34.883630147000076],
              [-80.857602131999954, 34.883339326000055],
              [-80.857168945999945, 34.883054089000041],
              [-80.856730366999955, 34.882774506000032],
              [-80.856286501999989, 34.882500648000075],
              [-80.855837461999954, 34.882232582000029],
              [-80.855383358999973, 34.881970374000048],
              [-80.854924303999951, 34.88171408900007],
              [-80.854460410999934, 34.881463791000044],
              [-80.853991796999935, 34.881219541000064],
              [-80.853518574999953, 34.880981402000032],
              [-80.85304086299999, 34.880749430000037],
              [-80.85255878199996, 34.880523684000025],
              [-80.852072448999934, 34.880304220000028],
              [-80.851581985999985, 34.880091091000054],
              [-80.851087513999971, 34.879884352000033],
              [-80.850589153999977, 34.879684052000073],
              [-80.850087031999976, 34.879490243000077],
              [-80.849581269999987, 34.879302971000072],
              [-80.849071995999964, 34.879122282000026],
              [-80.848559332999969, 34.878948224000055],
              [-80.848043410999935, 34.878780837000079],
              [-80.847524355999951, 34.878620162000061],
              [-80.84700229799995, 34.878466242000059],
              [-80.846477363999952, 34.878319113000032],
              [-80.845949686999973, 34.878178812000044],
              [-80.845419395999954, 34.878045373000077],
              [-80.84488662199999, 34.877918829000066],
              [-80.84435149799998, 34.877799214000049],
              [-80.84381415699994, 34.877686554000036],
              [-80.843274730999951, 34.87758087900005],
              [-80.842733352999971, 34.877482215000043],
              [-80.842190157999937, 34.877390586000047],
              [-80.841645282999934, 34.877306014000055],
              [-80.841098857999953, 34.877228521000063],
              [-80.840551021999943, 34.87715812700003],
              [-80.840001909999955, 34.87709484800007],
              [-80.839451657999973, 34.877038700000071],
              [-80.838900400999989, 34.876989696000066],
              [-80.838348276999966, 34.876947850000079],
              [-80.837795423999978, 34.876913172000059],
              [-80.837241974999984, 34.876885670000036],
              [-80.836688070999969, 34.87686535000006],
              [-80.836133845999939, 34.876852218000067],
              [-80.835579439999947, 34.876846278000073],
              [-80.835024989999965, 34.876847530000077],
              [-80.834470630999988, 34.876855975000069],
              [-80.833916502999955, 34.876871609000034],
              [-80.833362740999974, 34.876894431000039],
              [-80.832809483999938, 34.876924433000056],
              [-80.832256868999934, 34.876961608000045],
              [-80.831705030999956, 34.877005948000033],
              [-80.831154109999943, 34.877057440000044],
              [-80.830604238999967, 34.877116073000025],
              [-80.830055554999944, 34.877181832000076],
              [-80.829508195999949, 34.877254700000037],
              [-80.82896229499994, 34.877334659000041],
              [-80.828417988999945, 34.877421691000052],
              [-80.827875411999969, 34.877515772000038],
              [-80.827334699999938, 34.877616881000051],
              [-80.826795982999954, 34.877724991000036],
              [-80.82625939899998, 34.877840076000041],
              [-80.825725077999948, 34.877962108000077],
              [-80.825193154999965, 34.878091055000027],
              [-80.824663757999986, 34.878226887000039],
              [-80.824137021999945, 34.878369570000075],
              [-80.823613073999979, 34.878519069000049],
              [-80.823092047999978, 34.878675345000033],
              [-80.822574068999984, 34.878838361000078],
              [-80.822059266999986, 34.879008076000048],
              [-80.821547769999938, 34.879184448000046],
              [-80.821039704999976, 34.879367434000073],
              [-80.82053519599998, 34.879556987000058],
              [-80.820034369999973, 34.879753063000067],
              [-80.819537348999972, 34.879955610000025],
              [-80.819044258999952, 34.880164580000041],
              [-80.818555219999951, 34.880379921000042],
              [-80.818070353999985, 34.880601579000029],
              [-80.817589780999981, 34.880829499000072],
              [-80.817113619999986, 34.881063625000024],
              [-80.816641989999937, 34.881303899000045],
              [-80.816175004999934, 34.881550262000076],
              [-80.81571278399997, 34.881802651000044],
              [-80.815255440999977, 34.882061006000072],
              [-80.814803086999973, 34.882325261000062],
              [-80.814355836999937, 34.882595351000077],
              [-80.813913799999966, 34.882871211000065],
              [-80.813477086999967, 34.883152770000038],
              [-80.813045803999955, 34.883439959000043],
              [-80.812620060999961, 34.883732708000025],
              [-80.812199960999976, 34.884030943000027],
              [-80.811785608999969, 34.884334591000027],
              [-80.811377107999988, 34.88464357600003],
              [-80.810974558999987, 34.884957823000036],
              [-80.810578061999934, 34.885277252000037],
              [-80.810187715999973, 34.885601785000063],
              [-80.809803614999964, 34.885931342000049],
              [-80.80942585799994, 34.886265841000068],
              [-80.809054535999962, 34.886605197000051],
              [-80.808689742999945, 34.88694933000005],
              [-80.808331567999971, 34.887298152000028],
              [-80.807980099999952, 34.887651577000042],
              [-80.807635427999969, 34.888009517000057],
              [-80.807297634999941, 34.888371885000026],
              [-80.806966804999945, 34.88873858900007],
              [-80.806643021999946, 34.88910953900006],
              [-80.806326364999961, 34.889484644000049],
              [-80.806016912999951, 34.889863810000065],
              [-80.805714742999953, 34.890246944000069],
              [-80.805419928999981, 34.890633950000051],
              [-80.805132544999935, 34.891024732000062],
              [-80.804852660999984, 34.891419194000036],
              [-80.804580349999981, 34.891817237000055],
              [-80.804315675999987, 34.892218764000063],
              [-80.804058704999989, 34.892623675000038],
              [-80.803809501999979, 34.893031868000037],
              [-80.803568129999974, 34.893443244000025],
              [-80.803334646999986, 34.893857700000069],
              [-80.803109110999969, 34.894275134000054],
              [-80.802891578999947, 34.894695441000067],
              [-80.802682103999985, 34.89511851900005],
              [-80.802480738999975, 34.895544261000055],
              [-80.802287533999959, 34.895972562000054],
              [-80.80210253599995, 34.896403317000079],
              [-80.801925791999963, 34.896836418000078],
              [-80.801757346999977, 34.897271758000045],
              [-80.801597238999989, 34.897709230000032],
              [-80.801445512999976, 34.898148725000055],
              [-80.801302201999988, 34.898590133000027],
              [-80.801167344999953, 34.899033344000031],
              [-80.801040973999989, 34.899478251000062],
              [-80.800923120999983, 34.899924742000053],
              [-80.80081381399998, 34.900372707000031],
              [-80.800713082999948, 34.900822033000054],
              [-80.800620950999985, 34.901272611000024],
              [-80.80053744099996, 34.901724328000057],
              [-80.800462574999983, 34.902177073000075],
              [-80.800396370999977, 34.902630732000034],
              [-80.800338844999942, 34.903085194000028],
              [-80.800290012999938, 34.903540346000057],
              [-80.800249884999971, 34.903996075000066],
              [-80.800218473999962, 34.904452268000057],
              [-80.800195785999961, 34.904908812000031],
              [-80.800181825999971, 34.905365593000056],
              [-80.80017659899994, 34.905822499000067],
              [-80.800180105999971, 34.906279416000075],
              [-80.80019234699995, 34.906736231000025],
              [-80.800213318999965, 34.907192831000032],
              [-80.800243015999968, 34.90764910200005],
              [-80.800281429999984, 34.908104930000036],
              [-80.800328554999965, 34.908560205000072],
              [-80.800384374999965, 34.909014811000077],
              [-80.800448880999966, 34.909468636000042],
              [-80.800522052999952, 34.909921570000051],
              [-80.800603875999968, 34.91037349700008],
              [-80.800694327999963, 34.910824307000041],
              [-80.800793386999942, 34.911273888000039],
              [-80.800901028999988, 34.911722128000065],
              [-80.801017227999978, 34.912168916000041],
              [-80.801141953999945, 34.912614141000063],
              [-80.801275177999969, 34.913057692000052],
              [-80.801416864999965, 34.913499461000072],
              [-80.801566979999961, 34.913939337000045],
              [-80.801725488999978, 34.914377211000044],
              [-80.801892349999946, 34.914812975000075],
              [-80.802067521999959, 34.91524651900005],
              [-80.80225096199996, 34.915677738000056],
              [-80.802442624999969, 34.916106524000043],
              [-80.802642462999984, 34.916532769000071],
              [-80.802850426999953, 34.916956370000037],
              [-80.803066465999962, 34.917377221000038],
              [-80.803290524999966, 34.917795217000048],
              [-80.803522549999968, 34.918210253000041],
              [-80.803762481999968, 34.91862223000004],
              [-80.804010263999942, 34.919031042000029],
              [-80.804265832999988, 34.919436590000032],
              [-80.804529125999977, 34.919838771000059],
              [-80.804800076999982, 34.920237487000065],
              [-80.805078619999961, 34.920632639000075],
              [-80.805364686999951, 34.921024129000045],
              [-80.80565820399994, 34.921411859000045],
              [-80.805959100999985, 34.921795733000067],
              [-80.806267301999981, 34.922175657000025],
              [-80.806582730999935, 34.922551535000025],
              [-80.80690531099998, 34.922923275000073],
              [-80.80723495999996, 34.923290784000073],
              [-80.807571596999935, 34.923653971000078],
              [-80.807915140999967, 34.924012746000074],
              [-80.808265502999973, 34.924367020000034],
              [-80.808622597999943, 34.924716706000027],
              [-80.808986337999954, 34.925061715000027],
              [-80.809356630999957, 34.92540196300007],
              [-80.809733386999937, 34.925737365000032],
              [-80.810116512999969, 34.926067838000051],
              [-80.81050591099995, 34.926393300000029],
              [-80.810901487999956, 34.926713671000073],
              [-80.811303143999965, 34.927028871000061],
              [-80.811710779999942, 34.927338820000045],
              [-80.812124292999954, 34.927643443000079],
              [-80.812543583999968, 34.927942664000057],
              [-80.812968545999979, 34.928236410000068],
              [-80.81339907499995, 34.928524605000064],
              [-80.813835063999989, 34.928807181000025],
              [-80.814276403999941, 34.92908406500004],
              [-80.814722986999982, 34.929355190000024],
              [-80.815174700999989, 34.929620488000069],
              [-80.815631434999943, 34.929879893000077],
              [-80.81609307399998, 34.930133341000044],
              [-80.816559505999976, 34.930380770000056],
              [-80.81703061199994, 34.930622116000052],
              [-80.817506277999939, 34.930857321000076],
              [-80.817986385999973, 34.931086328000049],
              [-80.818470813999966, 34.931309077000037],
              [-80.818959444999962, 34.931525514000043],
              [-80.819452154999965, 34.931735587000048],
              [-80.819948822999947, 34.931939241000066],
              [-80.820449327999938, 34.932136427000046],
              [-80.820953541999984, 34.932327096000051],
              [-80.821461340999974, 34.932511201000068],
              [-80.821972599999981, 34.932688696000071],
              [-80.822487191999983, 34.932859535000034],
              [-80.823004988999969, 34.93302367900003],
              [-80.82352586199994, 34.933181085000058],
              [-80.824049681999952, 34.933331715000065],
              [-80.824576318999959, 34.933475532000045],
              [-80.825105642999972, 34.933612498000059],
              [-80.825637521999965, 34.933742581000047],
              [-80.826171822999981, 34.933865748000073],
              [-80.826708415999974, 34.933981969000058],
              [-80.82724716499996, 34.934091215000024],
              [-80.827787936999982, 34.934193458000038],
              [-80.828330598999969, 34.934288674000072],
              [-80.828875015999984, 34.934376837000059],
              [-80.82942105099994, 34.934457928000029],
              [-80.829968570999938, 34.934531925000044],
              [-80.830517439999937, 34.934598812000047],
              [-80.831067518999987, 34.934658569000078],
              [-80.83161867299998, 34.934711183000047],
              [-80.83217076699998, 34.934756641000035],
              [-80.832723660999989, 34.934794931000056],
              [-80.833277217999978, 34.934826045000079],
              [-80.833831302999954, 34.934849973000041],
              [-80.834385777999955, 34.934866711000041],
              [-80.834940503999974, 34.934876254000073],
              [-80.835495341999945, 34.934878600000047],
              [-80.836050157999978, 34.93487374800003]
            ]
          ],
          [
            [
              [-80.553374604999988, 35.012822854000035],
              [-80.55392982799998, 35.012812106000069],
              [-80.554484773999945, 35.01279416400007],
              [-80.555039309999984, 35.012769032000051],
              [-80.555593294999937, 35.012736717000053],
              [-80.556146591999948, 35.012697226000057],
              [-80.556699063999986, 35.012650569000073],
              [-80.557250573999966, 35.012596758000029],
              [-80.557800984999972, 35.012535806000074],
              [-80.558350159999975, 35.012467729000036],
              [-80.558897962999936, 35.012392544000079],
              [-80.559444257999985, 35.012310267000032],
              [-80.559988909999959, 35.012220921000051],
              [-80.560531781999941, 35.012124528000072],
              [-80.561072740999975, 35.01202111200007],
              [-80.561611652999943, 35.011910697000076],
              [-80.562148381999975, 35.011793311000076],
              [-80.562682796999979, 35.011668984000039],
              [-80.563214763999952, 35.01153774800008],
              [-80.56374415199997, 35.011399632000064],
              [-80.564270828999952, 35.011254674000043],
              [-80.564794663999976, 35.011102908000055],
              [-80.565315527999985, 35.010944371000051],
              [-80.565833289999944, 35.010779104000051],
              [-80.566347822999944, 35.010607148000076],
              [-80.566858999999965, 35.010428545000025],
              [-80.56736669199995, 35.010243339000056],
              [-80.567870773999971, 35.01005157700007],
              [-80.568371120999984, 35.009853305000036],
              [-80.568867608999938, 35.009648574000039],
              [-80.569360114999938, 35.009437433000073],
              [-80.56984851499999, 35.009219937000069],
              [-80.570332689999987, 35.008996137000054],
              [-80.570812519999947, 35.008766090000051],
              [-80.571287882999968, 35.008529854000074],
              [-80.571758663999958, 35.008287486000029],
              [-80.572224745999961, 35.008039047000068],
              [-80.572686010999973, 35.007784599000047],
              [-80.573142346999987, 35.00752420300006],
              [-80.573593639999956, 35.007257927000069],
              [-80.574039777999985, 35.00698583500008],
              [-80.574480650999988, 35.006707994000067],
              [-80.574916147999943, 35.006424475000074],
              [-80.57534616199996, 35.006135347000054],
              [-80.575770585999976, 35.005840681000052],
              [-80.576189315999954, 35.00554055200007],
              [-80.576602245999936, 35.005235033000076],
              [-80.577009275999956, 35.004924201000051],
              [-80.577410301999976, 35.004608132000044],
              [-80.577805227999988, 35.004286906000061],
              [-80.578193953999971, 35.003960601000074],
              [-80.578576383999973, 35.003629298000078],
              [-80.578952421999986, 35.003293081000038],
              [-80.579321977999939, 35.002952031000063],
              [-80.579684956999984, 35.00260623500003],
              [-80.580041269999981, 35.002255778000062],
              [-80.580390829999942, 35.001900746000047],
              [-80.580733548999945, 35.001541228000065],
              [-80.581069341999978, 35.001177313000028],
              [-80.581398126999943, 35.000809091000065],
              [-80.581719820999979, 35.000436655000044],
              [-80.582034344999954, 35.000060095000038],
              [-80.582341621999944, 34.999679505000074],
              [-80.582641573999979, 34.999294981000048],
              [-80.582934127999977, 34.998906616000056],
              [-80.583219209999982, 34.998514510000064],
              [-80.583496750999984, 34.998118756000054],
              [-80.583766682999965, 34.997719455000038],
              [-80.584028935999982, 34.99731670500006],
              [-80.584283447999951, 34.996910606000029],
              [-80.584530154999982, 34.996501260000059],
              [-80.584768994999934, 34.996088766000071],
              [-80.584999910999954, 34.995673229000033],
              [-80.58522284299994, 34.995254750000072],
              [-80.585437737999939, 34.994833434000043],
              [-80.585644541999955, 34.994409386000029],
              [-80.585843203999957, 34.993982710000068],
              [-80.586033673999964, 34.993553511000073],
              [-80.586215906999939, 34.993121899000073],
              [-80.586389855999983, 34.99268797700006],
              [-80.586555478999969, 34.992251855000063],
              [-80.586712733999946, 34.991813641000078],
              [-80.586861582999973, 34.991373443000043],
              [-80.587001989999976, 34.990931371000045],
              [-80.58713391699996, 34.990487533000078],
              [-80.587257334999947, 34.990042041000038],
              [-80.587372212999981, 34.989595005000069],
              [-80.58747852099998, 34.989146536000078],
              [-80.587576233999982, 34.988696744000038],
              [-80.587665326999968, 34.988245742000061],
              [-80.587745778999988, 34.987793641000053],
              [-80.58781756999997, 34.987340553000024],
              [-80.587880681999934, 34.98688659100003],
              [-80.587935098999935, 34.986431867000078],
              [-80.587980809999976, 34.985976495000045],
              [-80.588017799999989, 34.985520587000053],
              [-80.588046063999968, 34.985064256000044],
              [-80.588065590999975, 34.984607615000073],
              [-80.588076379999961, 34.984150778000071],
              [-80.58807842799996, 34.983693857000048],
              [-80.588071731999946, 34.98323696600005],
              [-80.588056294999944, 34.982780219000063],
              [-80.588032122999948, 34.982323729000029],
              [-80.587999218999983, 34.981867609000062],
              [-80.587957593999988, 34.981411971000057],
              [-80.587907256999983, 34.980956929000058],
              [-80.587848219999955, 34.980502596000065],
              [-80.587780499999951, 34.980049084000029],
              [-80.587704111999983, 34.979596507000053],
              [-80.587619075999953, 34.979144976000043],
              [-80.58752541299998, 34.978694602000076],
              [-80.587423145999935, 34.978245498000035],
              [-80.587312301999987, 34.977797775000056],
              [-80.587192905999984, 34.977351544000044],
              [-80.587064988999941, 34.976906916000075],
              [-80.586928583999963, 34.976464001000068],
              [-80.586783723999986, 34.976022908000061],
              [-80.586630444999969, 34.975583748000076],
              [-80.586468783999976, 34.975146628000061],
              [-80.586298783999951, 34.974711657000057],
              [-80.586120484999981, 34.974278943000058],
              [-80.585933931999989, 34.97384859400006],
              [-80.58573917199999, 34.973420716000078],
              [-80.585536250999951, 34.972995415000071],
              [-80.585325222999984, 34.972572797000055],
              [-80.585106137999958, 34.972152966000067],
              [-80.584879050999973, 34.971736025000041],
              [-80.584644017999949, 34.97132208000005],
              [-80.584401097999944, 34.970911233000038],
              [-80.584150351999938, 34.970503584000028],
              [-80.583891840999968, 34.970099235000077],
              [-80.583625629999972, 34.969698287000028],
              [-80.583351782999955, 34.969300838000038],
              [-80.583070370999963, 34.968906987000025],
              [-80.582781462999947, 34.96851683400007],
              [-80.582485128999963, 34.968130472000041],
              [-80.582181444999947, 34.96774799800005],
              [-80.581870484999968, 34.967369507000058],
              [-80.581552325999951, 34.966995093000037],
              [-80.58122704699997, 34.966624850000073],
              [-80.580894729999955, 34.966258867000079],
              [-80.580555454999967, 34.965897237000036],
              [-80.580209307999951, 34.965540048000037],
              [-80.579856374999963, 34.96518739000004],
              [-80.579496742999936, 34.964839349000044],
              [-80.579130500999952, 34.964496012000041],
              [-80.578757739999958, 34.964157464000039],
              [-80.578378552999936, 34.96382378900006],
              [-80.577993032999984, 34.963495070000079],
              [-80.577601276999985, 34.963171388000035],
              [-80.577203378999968, 34.962852822000059],
              [-80.57679944299997, 34.962539453000034],
              [-80.576389563999953, 34.962231358000054],
              [-80.575973845999954, 34.961928612000065],
              [-80.575552392999953, 34.96163129200005],
              [-80.575125306999951, 34.961339471000031],
              [-80.574692694999953, 34.961053222000032],
              [-80.574254665999945, 34.960772613000074],
              [-80.573811324999951, 34.960497716000077],
              [-80.573362784999972, 34.96022860000005],
              [-80.57290915599998, 34.959965329000056],
              [-80.572450550999974, 34.959707971000057],
              [-80.571987081999964, 34.959456587000034],
              [-80.571518866999952, 34.959211241000048],
              [-80.571046018999937, 34.958971994000024],
              [-80.570568654999988, 34.958738903000039],
              [-80.570086895999964, 34.958512029000076],
              [-80.56960085999998, 34.958291427000063],
              [-80.569110666999961, 34.958077150000065],
              [-80.568616439999971, 34.957869253000069],
              [-80.568118297999945, 34.957667787000048],
              [-80.56761636899995, 34.957472802000041],
              [-80.567110773999957, 34.957284345000062],
              [-80.566601639999988, 34.957102464000059],
              [-80.56608909199997, 34.956927205000056],
              [-80.565573257999972, 34.956758610000065],
              [-80.565054264999958, 34.95659672000005],
              [-80.564532242999974, 34.956441577000078],
              [-80.564007319999973, 34.956293218000042],
              [-80.563479625999946, 34.956151681000051],
              [-80.562949291999985, 34.956016999000042],
              [-80.562416449999944, 34.955889208000031],
              [-80.561881230999973, 34.955768338000041],
              [-80.561343767999972, 34.95565442000003],
              [-80.560804194999946, 34.955547480000064],
              [-80.560262643999977, 34.955447548000052],
              [-80.559719249999944, 34.955354646000046],
              [-80.559174148999944, 34.955268797000031],
              [-80.558627473999934, 34.955190024000046],
              [-80.558079360999955, 34.955118345000074],
              [-80.557529945999988, 34.955053779000025],
              [-80.556979364999961, 34.954996341000026],
              [-80.556427753999969, 34.954946046000032],
              [-80.555875249999985, 34.954902906000029],
              [-80.555321990999971, 34.954866931000026],
              [-80.554768112999966, 34.954838131000031],
              [-80.554213751999953, 34.954816513000026],
              [-80.55365904699994, 34.954802082000072],
              [-80.553104134999955, 34.95479484100008],
              [-80.552549152999973, 34.954794793000076],
              [-80.551994238999953, 34.95480193800006],
              [-80.551439529999982, 34.954816273000063],
              [-80.550885163999965, 34.954837796000049],
              [-80.550331277999987, 34.954866500000037],
              [-80.549778008999965, 34.954902379000032],
              [-80.549225494999973, 34.954945424000073],
              [-80.548673871999938, 34.954995624000048],
              [-80.548123275999956, 34.955052967000029],
              [-80.547573843999942, 34.955117438000059],
              [-80.547025713999972, 34.95518902200007],
              [-80.546479017999957, 34.955267701000025],
              [-80.545933894999962, 34.955353455000079],
              [-80.545390476999955, 34.955446263000056],
              [-80.544848901999956, 34.955546103000074],
              [-80.544309300999942, 34.955652949000068],
              [-80.543771808999963, 34.95576677400004],
              [-80.543236558999979, 34.955887552000036],
              [-80.542703684999935, 34.956015251000053],
              [-80.542173315999946, 34.95614984000008],
              [-80.541645586999948, 34.956291287000056],
              [-80.541120624999962, 34.956439555000031],
              [-80.540598563999936, 34.956594608000046],
              [-80.540079528999968, 34.956756408000047],
              [-80.539563652999959, 34.956924914000069],
              [-80.539051059999963, 34.957100086000025],
              [-80.538541879999968, 34.95728187800006],
              [-80.538036236999972, 34.95747024700006],
              [-80.537534257999937, 34.957665146000068],
              [-80.537036065999985, 34.957866526000032],
              [-80.53654178499994, 34.958074338000074],
              [-80.536051536999935, 34.958288530000061],
              [-80.535565444999975, 34.958509048000053],
              [-80.535083627999938, 34.958735840000031],
              [-80.534606205999978, 34.958968846000062],
              [-80.534133296999983, 34.959208012000033],
              [-80.533665016999976, 34.959453278000069],
              [-80.53320148499995, 34.95970458100004],
              [-80.532742814999949, 34.959961861000068],
              [-80.532289118999984, 34.960225053000045],
              [-80.531840509999938, 34.960494092000033],
              [-80.531397099999936, 34.960768912000049],
              [-80.530958998999949, 34.961049444000025],
              [-80.530526313999985, 34.961335620000057],
              [-80.530099154999959, 34.961627368000052],
              [-80.529677624999977, 34.961924615000044],
              [-80.529261829999939, 34.962227288000065],
              [-80.528851872999951, 34.962535312000057],
              [-80.528447855999957, 34.962848612000073],
              [-80.52804987799999, 34.96316710800005],
              [-80.527658038999959, 34.963490723000064],
              [-80.527272434999986, 34.96381937600006],
              [-80.526893162999954, 34.964152986000045],
              [-80.526520314999971, 34.964491469000052],
              [-80.52615398599994, 34.96483474300004],
              [-80.525794265999934, 34.965182721000076],
              [-80.525441241999943, 34.965535319000026],
              [-80.525095004999969, 34.965892447000044],
              [-80.524755638999977, 34.966254019000075],
              [-80.524423226999943, 34.966619944000058],
              [-80.52409785399999, 34.966990132000035],
              [-80.523779599999955, 34.967364491000069],
              [-80.523468542999979, 34.967742927000074],
              [-80.523164761999965, 34.968125349000047],
              [-80.522868328999948, 34.968511660000047],
              [-80.522579321999956, 34.968901764000066],
              [-80.522297808999951, 34.969295566000028],
              [-80.522023862999959, 34.969692967000071],
              [-80.521757548999972, 34.970093869000038],
              [-80.521498933999965, 34.970498174000056],
              [-80.521248083999978, 34.970905779000077],
              [-80.521005058999947, 34.971316585000068],
              [-80.520769921999943, 34.971730490000027],
              [-80.520542727999953, 34.972147390000032],
              [-80.520323535999978, 34.972567183000024],
              [-80.52011239899997, 34.972989766000069],
              [-80.519909370999983, 34.973415032000048],
              [-80.519714501999943, 34.973842876000049],
              [-80.519527838999977, 34.974273193000045],
              [-80.519349429999977, 34.974705875000041],
              [-80.519179318999988, 34.975140817000067],
              [-80.519017545999986, 34.975577908000048],
              [-80.51886415599995, 34.97601704300007],
              [-80.518719182999973, 34.976458111000056],
              [-80.51858266399995, 34.976901002000034],
              [-80.518454634999955, 34.977345609000054],
              [-80.518335125999954, 34.977791819000061],
              [-80.518224165999982, 34.978239522000024],
              [-80.518121784999948, 34.978688608000027],
              [-80.518028007999987, 34.97913896600005],
              [-80.517942855999934, 34.979590483000038],
              [-80.517866352999988, 34.980043048000027],
              [-80.51779851699996, 34.98049654700003],
              [-80.517739364999954, 34.980950869000026],
              [-80.517688911999983, 34.981405903000052],
              [-80.517647169999975, 34.981861533000028],
              [-80.517614150999975, 34.982317648000048],
              [-80.517589861999966, 34.982774135000057],
              [-80.51757430899994, 34.983230879000075],
              [-80.517567496999959, 34.983687768000038],
              [-80.517569426999955, 34.984144689000061],
              [-80.517580098999986, 34.984601529000031],
              [-80.517599510999958, 34.985058173000027],
              [-80.517627657999981, 34.985514509000041],
              [-80.517664531999969, 34.985970423000026],
              [-80.51771012599994, 34.986425804000078],
              [-80.517764426999975, 34.986880536000058],
              [-80.517827423999961, 34.98733450900005],
              [-80.517899099999966, 34.987787609000065],
              [-80.517979435999962, 34.988239724000039],
              [-80.518068414999959, 34.988690742000074],
              [-80.518166011999938, 34.989140551000048],
              [-80.518272205999949, 34.989589038000076],
              [-80.518386968999948, 34.990036094000061],
              [-80.518510273999937, 34.990481608000039],
              [-80.518642088999968, 34.990925467000068],
              [-80.518782382999973, 34.991367564000029],
              [-80.518931118999944, 34.991807788000074],
              [-80.519088262999958, 34.992246029000057],
              [-80.519253773999935, 34.992682180000031],
              [-80.519427611999959, 34.993116131000079],
              [-80.51960973499996, 34.993547776000071],
              [-80.519800095999983, 34.99397700600008],
              [-80.519998649999934, 34.994403717000068],
              [-80.520205344999965, 34.994827801000042],
              [-80.520420132999959, 34.995249154000078],
              [-80.520642957999939, 34.995667671000035],
              [-80.520873767999944, 34.996083248000048],
              [-80.521112501999937, 34.996495783000057],
              [-80.521359104999988, 34.996905172000027],
              [-80.521613512999977, 34.997311315000047],
              [-80.521875663999936, 34.997714110000061],
              [-80.522145492999982, 34.998113458000034],
              [-80.522422932999973, 34.998509259000059],
              [-80.522707916999934, 34.998901416000024],
              [-80.523000370999966, 34.999289831000056],
              [-80.523300224999957, 34.999674406000054],
              [-80.523607403999961, 35.000055049000025],
              [-80.523921831999985, 35.000431663000029],
              [-80.524243431999935, 35.000804156000072],
              [-80.524572121999938, 35.001172435000058],
              [-80.524907821999989, 35.00153640700006],
              [-80.525250449999987, 35.001895984000043],
              [-80.525599918999944, 35.00225107600005],
              [-80.525956142999974, 35.002601596000034],
              [-80.526319033999982, 35.002947455000026],
              [-80.526688500999967, 35.003288568000073],
              [-80.527064454999959, 35.003624850000051],
              [-80.52744680099994, 35.003956218000042],
              [-80.527835441999969, 35.004282590000059],
              [-80.528230285999939, 35.004603886000041],
              [-80.528631232999942, 35.004920024000057],
              [-80.529038182999955, 35.005230926000024],
              [-80.529451034999965, 35.005536516000063],
              [-80.529869687999962, 35.005836717000079],
              [-80.530294036999976, 35.006131456000048],
              [-80.530723977999969, 35.006420659000071],
              [-80.531159402999947, 35.006704253000066],
              [-80.531600203999972, 35.006982170000072],
              [-80.532046271999945, 35.007254339000042],
              [-80.532497497999941, 35.00752069400005],
              [-80.532953766999981, 35.007781167000076],
              [-80.533414967999988, 35.008035695000046],
              [-80.533880985999986, 35.00828421500006],
              [-80.534351704999949, 35.008526664000044],
              [-80.534827007999979, 35.008762983000054],
              [-80.535306778999939, 35.008993113000031],
              [-80.53579089599998, 35.009216995000031],
              [-80.536279242999967, 35.009434576000046],
              [-80.536771693999981, 35.00964580200008],
              [-80.537268128999983, 35.009850619000076],
              [-80.537768425999957, 35.010048977000054],
              [-80.538272458999984, 35.010240826000029],
              [-80.538780103999954, 35.01042612100008],
              [-80.539291233999961, 35.010604812000054],
              [-80.539805722999972, 35.010776857000053],
              [-80.540323443999966, 35.010942213000078],
              [-80.540844267999944, 35.011100839000051],
              [-80.541368062999936, 35.011252696000042],
              [-80.541894702999969, 35.011397746000057],
              [-80.542424055999959, 35.011535952000031],
              [-80.542955989999939, 35.01166728100003],
              [-80.543490372999941, 35.01179170000006],
              [-80.544027072999938, 35.011909178000053],
              [-80.544565955999985, 35.012019686000031],
              [-80.545106887999964, 35.012123196000061],
              [-80.545649735999973, 35.012219684000058],
              [-80.546194364999963, 35.01230912300008],
              [-80.54674063799996, 35.012391494000042],
              [-80.547288422999941, 35.012466774000075],
              [-80.547837580999953, 35.012534947000063],
              [-80.548387975999958, 35.012595993000048],
              [-80.548939471999972, 35.012649899000053],
              [-80.549491932999956, 35.012696651000056],
              [-80.550045218999969, 35.012736238000059],
              [-80.550599195999951, 35.012768649000066],
              [-80.55115372399996, 35.012793877000036],
              [-80.551708666999957, 35.012811914000054],
              [-80.55226388799997, 35.012822758000027],
              [-80.552819244999966, 35.012826405000055],
              [-80.553374604999988, 35.012822854000035]
            ]
          ],
          [
            [
              [-81.245568642999956, 35.02608213600007],
              [-81.246123850999936, 35.026068203000079],
              [-81.246678721999956, 35.026047078000033],
              [-81.247233120999965, 35.02601876500006],
              [-81.247786908999956, 35.025983273000065],
              [-81.248339947999966, 35.025940609000031],
              [-81.248892099999978, 35.025890784000069],
              [-81.249443228999951, 35.025833811000041],
              [-81.249993199999949, 35.025769703000037],
              [-81.250541872999975, 35.025698477000049],
              [-81.251089113999967, 35.025620151000055],
              [-81.25163478799999, 35.025534742000048],
              [-81.252178756999967, 35.025442274000056],
              [-81.252720886999953, 35.025342769000076],
              [-81.253261043999942, 35.02523625200007],
              [-81.253799093999987, 35.025122749000047],
              [-81.254334900999936, 35.025002287000063],
              [-81.254868335999959, 35.024874897000075],
              [-81.255399263999948, 35.024740613000063],
              [-81.255927552999935, 35.024599464000062],
              [-81.256453073999978, 35.024451488000068],
              [-81.256975694999937, 35.024296721000042],
              [-81.257495286999983, 35.024135200000046],
              [-81.258011718999967, 35.023966967000035],
              [-81.25852486499997, 35.023792064000077],
              [-81.259034597999971, 35.023610532000077],
              [-81.25954078999996, 35.023422419000042],
              [-81.260043314999962, 35.023227770000062],
              [-81.26054204999997, 35.02302663200004],
              [-81.261036870999988, 35.022819058000039],
              [-81.261527654999952, 35.022605098000042],
              [-81.262014277999981, 35.022384805000058],
              [-81.262496622999947, 35.022158233000027],
              [-81.262974567999947, 35.021925440000075],
              [-81.263447993999989, 35.021686483000053],
              [-81.263916785999982, 35.021441420000031],
              [-81.264380825999979, 35.021190313000034],
              [-81.264839996999967, 35.020933226000068],
              [-81.265294188999974, 35.020670219000067],
              [-81.265743286999964, 35.02040136100004],
              [-81.266187178999985, 35.02012671600005],
              [-81.266625756999986, 35.019846353000048],
              [-81.267058910999936, 35.01956034300008],
              [-81.267486532999953, 35.019268755000041],
              [-81.267908517999956, 35.018971663000059],
              [-81.268324761999963, 35.018669139000053],
              [-81.268735158999959, 35.018361259000073],
              [-81.269139609999968, 35.018048100000044],
              [-81.269538011999941, 35.01772973900006],
              [-81.269930268999985, 35.017406255000026],
              [-81.270316282999943, 35.017077728000061],
              [-81.270695957999976, 35.016744240000037],
              [-81.271069197999964, 35.016405874000043],
              [-81.271435912999948, 35.016062713000053],
              [-81.271796011999982, 35.015714844000058],
              [-81.272149402999958, 35.015362351000078],
              [-81.272496001999968, 35.015005323000025],
              [-81.272835719999989, 35.014643848000048],
              [-81.273168475999967, 35.014278015000059],
              [-81.273494185999937, 35.01390791700004],
              [-81.273812766999981, 35.013533645000052],
              [-81.274124142999938, 35.013155290000043],
              [-81.274428235999949, 35.01277294700003],
              [-81.274724970999955, 35.01238671200008],
              [-81.275014272999954, 35.011996679000049],
              [-81.275296071999946, 35.011602947000029],
              [-81.275570296999945, 35.011205611000037],
              [-81.275836881999965, 35.010804772000029],
              [-81.276095758999986, 35.010400528000048],
              [-81.276346863999947, 35.00999298000005],
              [-81.276590135999982, 35.009582228000056],
              [-81.276825513999938, 35.009168375000058],
              [-81.277052938999987, 35.008751524000047],
              [-81.277272355999969, 35.008331776000034],
              [-81.277483710999945, 35.007909239000071],
              [-81.277686948999985, 35.007484015000045],
              [-81.27788202399995, 35.007056209000041],
              [-81.278068882999946, 35.006625930000041],
              [-81.278247483999962, 35.006193282000027],
              [-81.278417779999984, 35.005758374000038],
              [-81.27857972999999, 35.005321313000024],
              [-81.278733293999949, 35.004882208000026],
              [-81.278878433999978, 35.004441167000039],
              [-81.279015113999947, 35.003998301000024],
              [-81.279143298999941, 35.003553718000035],
              [-81.279262959999983, 35.003107529000033],
              [-81.279374066999935, 35.002659846000029],
              [-81.279476589999945, 35.002210778000062],
              [-81.279570504999981, 35.00176043700003],
              [-81.279655789999936, 35.001308935000054],
              [-81.279732421999938, 35.000856384000031],
              [-81.279800384999987, 35.000402896000026],
              [-81.279859659999943, 34.999948583000048],
              [-81.279910232999953, 34.99949355900003],
              [-81.279952091999974, 34.999037936000036],
              [-81.279985224999962, 34.998581827000066],
              [-81.28000962699997, 34.998125345000062],
              [-81.28002528899998, 34.997668604000069],
              [-81.280032209999945, 34.997211717000027],
              [-81.280030386999954, 34.996754796000062],
              [-81.28001981999995, 34.996297955000045],
              [-81.280000512999948, 34.995841308000024],
              [-81.279972469999962, 34.995384969000042],
              [-81.279935698999964, 34.994929048000074],
              [-81.279890208999973, 34.994473661000029],
              [-81.279836010999986, 34.99401892000003],
              [-81.27977311799998, 34.993564937000031],
              [-81.279701546999945, 34.993111825000028],
              [-81.279621315999975, 34.992659697000079],
              [-81.279532443999983, 34.992208665000078],
              [-81.279434953999953, 34.991758840000045],
              [-81.279328868999983, 34.991310335000037],
              [-81.279214217999936, 34.990863258000047],
              [-81.279091026999936, 34.990417724000054],
              [-81.278959326999939, 34.989973841000051],
              [-81.278819152999972, 34.989531720000059],
              [-81.278670536999982, 34.989091469000073],
              [-81.278513517999954, 34.988653199000055],
              [-81.278348132999952, 34.988217018000057],
              [-81.278174426999954, 34.987783033000028],
              [-81.277992437999956, 34.987351353000065],
              [-81.277802215999941, 34.98692208500006],
              [-81.277603805999945, 34.986495336000075],
              [-81.277397255999972, 34.986071209000045],
              [-81.277182620999952, 34.985649813000066],
              [-81.276959949999934, 34.985231249000037],
              [-81.276729300999989, 34.984815622000042],
              [-81.276490731999957, 34.98440303600006],
              [-81.276244299999973, 34.983993592000047],
              [-81.275990067999942, 34.983587392000061],
              [-81.275728097999945, 34.983184536000067],
              [-81.275458454999978, 34.982785125000078],
              [-81.275181205999957, 34.982389258000069],
              [-81.274896420999937, 34.981997031000049],
              [-81.274604169999975, 34.981608545000029],
              [-81.274304523999945, 34.981223893000049],
              [-81.273997560999987, 34.98084317100006],
              [-81.273683352999967, 34.980466474000025],
              [-81.273361980999937, 34.980093894000049],
              [-81.273033522999981, 34.979725526000038],
              [-81.272698061999961, 34.979361459000074],
              [-81.272355678999986, 34.979001784000047],
              [-81.272006459999943, 34.978646591000029],
              [-81.271650492999981, 34.978295966000076],
              [-81.271287865999966, 34.977949997000053],
              [-81.270918666999989, 34.977608770000074],
              [-81.270542988999978, 34.977272369000048],
              [-81.270160924999971, 34.976940877000061],
              [-81.269772569999986, 34.976614378000079],
              [-81.269378018999987, 34.976292952000051],
              [-81.268977370999949, 34.975976676000073],
              [-81.26857072599995, 34.975665633000062],
              [-81.268158182999969, 34.975359897000033],
              [-81.267739844999937, 34.975059544000032],
              [-81.26731581699994, 34.974764649000065],
              [-81.266886201999966, 34.974475286000029],
              [-81.266451107999956, 34.974191525000037],
              [-81.266010643999948, 34.973913436000032],
              [-81.26556491499997, 34.973641090000058],
              [-81.265114035999943, 34.973374554000031],
              [-81.264658115999964, 34.973113892000072],
              [-81.264197268999965, 34.972859171000039],
              [-81.263731609999979, 34.972610452000026],
              [-81.263261252999939, 34.97236779800005],
              [-81.262786314999971, 34.972131270000034],
              [-81.262306912999975, 34.971900924000067],
              [-81.261823166999989, 34.971676819000038],
              [-81.261335196999937, 34.971459011000036],
              [-81.260843122999972, 34.971247551000033],
              [-81.260347067999987, 34.971042495000063],
              [-81.259847152999953, 34.970843892000062],
              [-81.259343504999947, 34.97065179100008],
              [-81.258836244999941, 34.970466240000064],
              [-81.258325501999934, 34.970287284000051],
              [-81.25781139999998, 34.970114969000065],
              [-81.257294067999965, 34.969949338000049],
              [-81.256773633999956, 34.969790429000057],
              [-81.256250226999953, 34.969638284000041],
              [-81.255723974999967, 34.969492941000055],
              [-81.255195010999955, 34.969354434000024],
              [-81.254663463999975, 34.969222799000079],
              [-81.254129466999984, 34.969098067000061],
              [-81.253593150999961, 34.968980271000078],
              [-81.253054650999957, 34.968869439000059],
              [-81.252514097999949, 34.968765598000061],
              [-81.251971626999989, 34.968668774000037],
              [-81.251427371999966, 34.968578992000062],
              [-81.250881468999978, 34.968496272000039],
              [-81.250334051999971, 34.968420637000065],
              [-81.249785256999985, 34.968352105000065],
              [-81.249235218999956, 34.968290692000039],
              [-81.24868407699995, 34.968236415000035],
              [-81.248131963999981, 34.968189285000051],
              [-81.247579018999943, 34.968149315000062],
              [-81.247025378999979, 34.968116516000066],
              [-81.246471179999958, 34.968090893000067],
              [-81.245916560999945, 34.968072456000073],
              [-81.245361656999989, 34.968061208000051],
              [-81.244806606999987, 34.96805715000005],
              [-81.244251547999966, 34.968060286000025],
              [-81.243696616999955, 34.968070614000055],
              [-81.243141953999952, 34.968088131000059],
              [-81.242587692999962, 34.968112834000067],
              [-81.242033972999934, 34.968144715000051],
              [-81.241480931999945, 34.968183767000028],
              [-81.240928704999988, 34.968229980000046],
              [-81.240377430999956, 34.968283343000053],
              [-81.239827244999958, 34.968343843000071],
              [-81.239278282999976, 34.968411465000031],
              [-81.238730681999982, 34.968486192000057],
              [-81.23818457699997, 34.968568005000066],
              [-81.237640103999979, 34.96865688400004],
              [-81.237097395999967, 34.968752807000044],
              [-81.236556590999953, 34.968855751000035],
              [-81.236017818999983, 34.968965690000061],
              [-81.235481216999972, 34.969082596000078],
              [-81.234946914999966, 34.96920644100004],
              [-81.23441504799996, 34.969337194000047],
              [-81.233885744999952, 34.969474822000052],
              [-81.233359138999958, 34.969619293000051],
              [-81.232835359999967, 34.969770569000048],
              [-81.232314537999969, 34.969928613000036],
              [-81.231796801999963, 34.97009338600003],
              [-81.231282279999959, 34.970264847000067],
              [-81.23077109899998, 34.970442955000067],
              [-81.23026338699998, 34.970627664000062],
              [-81.229759267999952, 34.970818928000028],
              [-81.229258868999977, 34.971016702000043],
              [-81.228762311999958, 34.971220935000076],
              [-81.228269721999936, 34.971431577000033],
              [-81.227781218999951, 34.971648576000064],
              [-81.227296925999951, 34.971871878000059],
              [-81.226816961999987, 34.972101427000041],
              [-81.226341444999946, 34.972337167000035],
              [-81.225870494999981, 34.972579040000028],
              [-81.225404226999956, 34.97282698600003],
              [-81.224942756999951, 34.973080941000035],
              [-81.224486200999934, 34.973340846000042],
              [-81.22403466999998, 34.973606634000078],
              [-81.223588275999987, 34.973878240000033],
              [-81.223147130999962, 34.974155597000049],
              [-81.222711342999958, 34.97443863500007],
              [-81.222281019999969, 34.974727285000029],
              [-81.221856271999968, 34.975021476000052],
              [-81.221437198999979, 34.975321134000069],
              [-81.22102390799995, 34.975626185000067],
              [-81.220616502999974, 34.975936553000054],
              [-81.220215080999935, 34.976252163000026],
              [-81.219819743999949, 34.976572934000046],
              [-81.219430589999945, 34.976898788000028],
              [-81.219047713999942, 34.977229645000079],
              [-81.218671213999983, 34.977565422000055],
              [-81.218301179999969, 34.977906035000046],
              [-81.217937705999987, 34.978251402000069],
              [-81.217580880999947, 34.978601436000076],
              [-81.217230793999988, 34.978956049000033],
              [-81.216887531999987, 34.979315155000052],
              [-81.216551178999964, 34.979678665000051],
              [-81.216221819999987, 34.980046488000028],
              [-81.215899534999949, 34.980418532000044],
              [-81.215584405999948, 34.98079470700003],
              [-81.215276509999967, 34.981174919000068],
              [-81.214975923999987, 34.981559073000028],
              [-81.214682720999974, 34.981947075000051],
              [-81.214396975999989, 34.982338827000035],
              [-81.214118757999984, 34.982734234000077],
              [-81.213848138999936, 34.98313319600004],
              [-81.213585181999974, 34.98353561700003],
              [-81.213329954999949, 34.983941394000055],
              [-81.21308252099999, 34.984350428000027],
              [-81.212842940999963, 34.984762618000047],
              [-81.212611274999972, 34.985177861000068],
              [-81.212387579999984, 34.985596054000041],
              [-81.212171911999974, 34.986017094000033],
              [-81.21196432499994, 34.986440877000064],
              [-81.211764868999978, 34.98686729700006],
              [-81.211573594999948, 34.987296248000064],
              [-81.211390549999976, 34.987727625000048],
              [-81.211215779999975, 34.988161320000074],
              [-81.211049328999934, 34.988597226000024],
              [-81.210891234999963, 34.989035235000074],
              [-81.210741541999937, 34.989475238000068],
              [-81.210600283999952, 34.98991712600008],
              [-81.210467497999957, 34.990360789000079],
              [-81.210343214999966, 34.990806119000069],
              [-81.21022746899996, 34.991253003000054],
              [-81.210120284999959, 34.991701332000048],
              [-81.210021692999987, 34.992150994000042],
              [-81.209931716999961, 34.992601878000073],
              [-81.209850377999942, 34.993053872000075],
              [-81.209777696999936, 34.99350686400004],
              [-81.20971369199998, 34.993960741000024],
              [-81.209658379999951, 34.994415392000064],
              [-81.209611773999939, 34.994870703000061],
              [-81.209573884999941, 34.99532656100007],
              [-81.209544724999944, 34.995782854000026],
              [-81.209524298999952, 34.996239468000056],
              [-81.209512612999958, 34.996696290000045],
              [-81.209509669999989, 34.997153206000064],
              [-81.209515469999985, 34.997610104000046],
              [-81.209530013999938, 34.998066871000049],
              [-81.209553296999957, 34.998523393000028],
              [-81.209585312999934, 34.998979555000062],
              [-81.209626053999955, 34.999435247000065],
              [-81.209675512999979, 34.999890354000058],
              [-81.20973367299996, 35.000344764000033],
              [-81.209800524999935, 35.000798363000058],
              [-81.209876047999956, 35.001251041000046],
              [-81.209960225999964, 35.001702683000076],
              [-81.210053037999955, 35.00215318000005],
              [-81.210154459999956, 35.002602417000048],
              [-81.210264467999934, 35.003050284000039],
              [-81.21038303499995, 35.003496670000061],
              [-81.210510130999978, 35.003941465000025],
              [-81.210645724999949, 35.004384557000037],
              [-81.210789783999985, 35.004825837000055],
              [-81.210942269999975, 35.005265197000028],
              [-81.211103149999985, 35.005702525000061],
              [-81.211272378999979, 35.006137716000069],
              [-81.211449918999961, 35.006570658000044],
              [-81.211635723999962, 35.007001247000062],
              [-81.211829748999946, 35.007429375000072],
              [-81.212031944999978, 35.007854935000069],
              [-81.212242261999961, 35.008277823000071],
              [-81.212460650999958, 35.008697934000054],
              [-81.212687052999968, 35.009115162000057],
              [-81.212921415999972, 35.009529404000034],
              [-81.21316367999998, 35.009940559000029],
              [-81.21341378599999, 35.010348523000062],
              [-81.213671670999986, 35.010753196000053],
              [-81.213937271999953, 35.011154477000048],
              [-81.214210522999963, 35.011552266000024],
              [-81.21449135599994, 35.01194646600004],
              [-81.214779701999987, 35.012336978000064],
              [-81.215075488999958, 35.012723705000042],
              [-81.215378642999951, 35.013106550000032],
              [-81.215689090999945, 35.013485422000031],
              [-81.216006754999967, 35.013860222000062],
              [-81.216331555999943, 35.014230861000044],
              [-81.216663412999935, 35.014597244000072],
              [-81.217002243999957, 35.014959281000074],
              [-81.217347967999956, 35.015316884000072],
              [-81.217700494999974, 35.015669962000061],
              [-81.218059738999955, 35.016018429000042],
              [-81.218425611999976, 35.016362198000024],
              [-81.218798021999987, 35.016701182000077],
              [-81.219176876999938, 35.017035299000042],
              [-81.21956208499995, 35.01736446600006],
              [-81.219953546999989, 35.017688600000042],
              [-81.220351168999969, 35.018007622000027],
              [-81.220754850999981, 35.018321452000066],
              [-81.221164492999947, 35.018630012000074],
              [-81.221579991999988, 35.018933226000058],
              [-81.22200124799997, 35.019231017000038],
              [-81.222428153999942, 35.019523314000025],
              [-81.222860605999983, 35.019810043000064],
              [-81.223298494999938, 35.020091133000051],
              [-81.223741713999971, 35.020366513000056],
              [-81.224190150999959, 35.020636117000038],
              [-81.224643696999976, 35.020899876000044],
              [-81.225102236999987, 35.021157726000069],
              [-81.225565659999972, 35.021409601000073],
              [-81.226033848999975, 35.021655442000053],
              [-81.226506687999972, 35.021895184000073],
              [-81.226984060999939, 35.022128770000052],
              [-81.227465849999987, 35.022356141000046],
              [-81.227951931999939, 35.022577241000079],
              [-81.228442189999953, 35.02279201500005],
              [-81.228936499999975, 35.023000411000055],
              [-81.229434740999977, 35.023202375000039],
              [-81.229936788999964, 35.023397857000077],
              [-81.230442517999961, 35.02358681000004],
              [-81.230951803999972, 35.023769188000074],
              [-81.231464518999985, 35.023944942000071],
              [-81.231980538999949, 35.024114031000067],
              [-81.232499732999941, 35.024276413000052],
              [-81.233021973999939, 35.024432047000062],
              [-81.233547128999987, 35.024580896000032],
              [-81.234075071999939, 35.024722921000034],
              [-81.234605669999951, 35.024858087000041],
              [-81.23513879099994, 35.024986360000071],
              [-81.23567430199995, 35.025107711000032],
              [-81.236212071999944, 35.025222107000047],
              [-81.236751966999975, 35.02532952100006],
              [-81.237293850999947, 35.025429925000026],
              [-81.237837592999938, 35.025523296000074],
              [-81.238383054999986, 35.025609609000071],
              [-81.238930101999983, 35.025688843000069],
              [-81.239478599999984, 35.02576097900004],
              [-81.240028412999948, 35.025826000000052],
              [-81.240579400999934, 35.025883887000077],
              [-81.241131429999939, 35.025934628000073],
              [-81.24168436399998, 35.025978210000062],
              [-81.242238062999945, 35.026014621000058],
              [-81.242792389999977, 35.026043854000079],
              [-81.243347208999978, 35.026065900000049],
              [-81.243902381999987, 35.026080753000031],
              [-81.244457770999986, 35.026088412000036],
              [-81.245013235999977, 35.026088872000059],
              [-81.245568642999956, 35.02608213600007]
            ]
          ],
          [
            [
              [-80.610058333999973, 35.467993861000025],
              [-80.610616660999938, 35.467982853000024],
              [-80.611174704999939, 35.467964652000035],
              [-80.611732330999985, 35.467939261000026],
              [-80.612289397999973, 35.467906688000028],
              [-80.612845768999989, 35.467866940000079],
              [-80.613401303999979, 35.467820027000073],
              [-80.613955866999959, 35.467765961000055],
              [-80.614509320999957, 35.467704755000057],
              [-80.615061524999987, 35.46763642500008],
              [-80.615612345999978, 35.46756098700007],
              [-80.616161645999966, 35.467478460000052],
              [-80.616709286999935, 35.467388865000032],
              [-80.617255134999937, 35.467292224000062],
              [-80.617799052999942, 35.467188561000057],
              [-80.618340907999936, 35.467077901000039],
              [-80.618880562999948, 35.466960272000051],
              [-80.619417885999951, 35.466835702000026],
              [-80.619952742999942, 35.466704225000058],
              [-80.620485000999963, 35.466565871000057],
              [-80.621014527999989, 35.466420676000041],
              [-80.621541192999985, 35.466268675000038],
              [-80.62206486499997, 35.466109905000053],
              [-80.622585412999968, 35.465944407000052],
              [-80.623102709999955, 35.465772221000066],
              [-80.62361662699999, 35.465593390000038],
              [-80.624127034999958, 35.46540796000005],
              [-80.624633806999952, 35.465215974000046],
              [-80.625136818999977, 35.465017481000075],
              [-80.625635946999978, 35.464812532000053],
              [-80.626131064999981, 35.464601175000041],
              [-80.62662204999998, 35.464383465000026],
              [-80.627108781999937, 35.464159453000036],
              [-80.627591139999936, 35.463929198000073],
              [-80.628069001999961, 35.463692756000057],
              [-80.628542252999978, 35.463450183000077],
              [-80.629010771999958, 35.463201543000025],
              [-80.629474444999971, 35.462946897000052],
              [-80.629933155999936, 35.46268630600008],
              [-80.630386791999967, 35.462419836000038],
              [-80.630835238999964, 35.462147554000069],
              [-80.631278387999942, 35.461869526000044],
              [-80.631716126999947, 35.461585822000075],
              [-80.632148348999976, 35.461296513000036],
              [-80.632574945999977, 35.461001669000041],
              [-80.63299581199999, 35.460701365000034],
              [-80.633410842999979, 35.46039567400004],
              [-80.633819935999952, 35.46008467300004],
              [-80.634222988999966, 35.459768438000026],
              [-80.63461990199994, 35.459447050000051],
              [-80.635010578999982, 35.459120585000051],
              [-80.635394919999953, 35.458789126000056],
              [-80.635772829999951, 35.458452756000042],
              [-80.63614421799997, 35.458111558000041],
              [-80.636508988999935, 35.457765616000074],
              [-80.636867053999936, 35.457415016000027],
              [-80.637218323999946, 35.45705984500006],
              [-80.637562710999987, 35.456700191000039],
              [-80.63790013199997, 35.456336144000034],
              [-80.638230501999942, 35.455967795000049],
              [-80.638553737999985, 35.455595234000043],
              [-80.638869760999967, 35.455218553000066],
              [-80.639178491999985, 35.454837846000032],
              [-80.63947985599998, 35.454453208000075],
              [-80.639773777999949, 35.454064733000052],
              [-80.640060182999946, 35.453672521000044],
              [-80.640339002999951, 35.453276665000033],
              [-80.640610166999977, 35.452877265000041],
              [-80.640873607999936, 35.452474421000034],
              [-80.641129261999936, 35.452068231000055],
              [-80.641377064999972, 35.451658798000039],
              [-80.641616954999961, 35.451246221000076],
              [-80.641848872999958, 35.450830605000078],
              [-80.642072760999952, 35.450412051000058],
              [-80.642288565999934, 35.44999066400004],
              [-80.642496230999939, 35.449566549000053],
              [-80.642695707999962, 35.44913980900003],
              [-80.642886944999987, 35.44871055200008],
              [-80.643069896999975, 35.448278884000047],
              [-80.643244516999971, 35.44784491200005],
              [-80.643410761999974, 35.44740874200005],
              [-80.643568591999951, 35.446970485000065],
              [-80.643717966999986, 35.446530249000034],
              [-80.643858850999948, 35.446088141000075],
              [-80.643991206999942, 35.445644273000028],
              [-80.644115004999946, 35.445198755000035],
              [-80.644230212999958, 35.444751696000026],
              [-80.644336802999987, 35.444303208000065],
              [-80.644434747999981, 35.443853402000059],
              [-80.644524024999953, 35.443402390000074],
              [-80.644604610999977, 35.442950283000073],
              [-80.64467648699997, 35.442497193000065],
              [-80.644739634999951, 35.442043233000049],
              [-80.644794038999976, 35.441588516000024],
              [-80.644839686999944, 35.441133154000056],
              [-80.644876565999937, 35.440677261000076],
              [-80.644904667999981, 35.440220948000047],
              [-80.644923984999934, 35.43976433000006],
              [-80.644934514999989, 35.439307520000057],
              [-80.644936253999958, 35.438850630000047],
              [-80.644929200999968, 35.438393774000076],
              [-80.644913358999986, 35.437937066000075],
              [-80.64488873099998, 35.437480620000031],
              [-80.644855323999934, 35.437024546000032],
              [-80.644813146999979, 35.436568960000045],
              [-80.644762208999964, 35.436113974000079],
              [-80.644702523999968, 35.435659700000031],
              [-80.644634106999945, 35.435206252000057],
              [-80.644556974999944, 35.434753742000055],
              [-80.644471145999944, 35.434302282000033],
              [-80.644376642999987, 35.433851983000068],
              [-80.644273488999943, 35.433402959000034],
              [-80.644161710999981, 35.43295531900003],
              [-80.644041333999951, 35.432509175000064],
              [-80.643912388999979, 35.432064638000043],
              [-80.64377491099998, 35.431621817000064],
              [-80.643628930999967, 35.431180823000034],
              [-80.643474484999956, 35.43074176500005],
              [-80.64331161299998, 35.43030475200004],
              [-80.643140356999936, 35.429869891000067],
              [-80.642960755999979, 35.429437291000056],
              [-80.642772856999954, 35.429007059000071],
              [-80.642576705999943, 35.428579303000049],
              [-80.642372351999938, 35.428154126000038],
              [-80.64215984599997, 35.427731636000033],
              [-80.641939239999942, 35.42731193700007],
              [-80.641710588999956, 35.426895132000027],
              [-80.641473950999966, 35.426481326000044],
              [-80.641229382999938, 35.426070621000065],
              [-80.640976946999956, 35.425663118000045],
              [-80.640716704999988, 35.425258919000044],
              [-80.640448721999974, 35.424858123000035],
              [-80.640173063999953, 35.424460830000044],
              [-80.639889799999935, 35.424067139000044],
              [-80.639599001999954, 35.423677148000024],
              [-80.639300737999974, 35.42329095100007],
              [-80.638995084999976, 35.422908647000042],
              [-80.638682117999963, 35.42253032800005],
              [-80.638361915999951, 35.422156089000055],
              [-80.63803455599998, 35.421786024000028],
              [-80.637700121999956, 35.421420222000052],
              [-80.637358693999943, 35.421058777000042],
              [-80.637010358999987, 35.420701775000055],
              [-80.636655201999986, 35.420349306000048],
              [-80.636293312999953, 35.420001459000048],
              [-80.635924778999936, 35.419658318000074],
              [-80.635549693999963, 35.419319968000025],
              [-80.635168150999959, 35.41898649400008],
              [-80.634780240999987, 35.418657978000056],
              [-80.634386064999944, 35.41833450200005],
              [-80.633985715999984, 35.418016145000024],
              [-80.633579297999972, 35.417702987000041],
              [-80.633166907999964, 35.417395106000072],
              [-80.63274864999994, 35.417092576000073],
              [-80.632324628999982, 35.416795475000072],
              [-80.631894946999978, 35.416503874000057],
              [-80.63145971299997, 35.416217847000041],
              [-80.631019033999962, 35.415937463000034],
              [-80.630573018999939, 35.41566279400007],
              [-80.630121778999978, 35.415393907000066],
              [-80.629665424999985, 35.415130867000073],
              [-80.629204071999936, 35.414873741000065],
              [-80.628737831999956, 35.414622592000057],
              [-80.62826682299999, 35.414377483000067],
              [-80.627791158999969, 35.414138474000026],
              [-80.627310958999942, 35.413905624000051],
              [-80.626826343999937, 35.41367899100004],
              [-80.626337430999968, 35.413458633000062],
              [-80.625844343999972, 35.413244601000031],
              [-80.62534720299999, 35.413036950000048],
              [-80.624846131999959, 35.412835732000076],
              [-80.62434125499999, 35.412640995000061],
              [-80.623832696999955, 35.412452789000042],
              [-80.623320584999988, 35.412271160000046],
              [-80.622805043999961, 35.41209615300005],
              [-80.622286203999977, 35.411927812000044],
              [-80.621764190999954, 35.411766177000061],
              [-80.621239136999975, 35.411611289000064],
              [-80.620711169999936, 35.411463187000038],
              [-80.620180422999965, 35.411321907000058],
              [-80.61964702399996, 35.411187484000038],
              [-80.619111108999959, 35.411059952000073],
              [-80.618572807999954, 35.410939342000063],
              [-80.618032255999935, 35.410825684000031],
              [-80.617489586999966, 35.410719005000033],
              [-80.616944932999957, 35.410619334000046],
              [-80.616398430999936, 35.410526693000065],
              [-80.615850217999935, 35.410441106000064],
              [-80.615300424999987, 35.410362595000038],
              [-80.614749192999966, 35.410291179000069],
              [-80.61419665599999, 35.410226875000035],
              [-80.613642951999964, 35.41016970000004],
              [-80.613088215999937, 35.41011966700006],
              [-80.612532587999965, 35.410076789000072],
              [-80.611976205999952, 35.410041077000074],
              [-80.611419204999947, 35.410012539000036],
              [-80.61086172499995, 35.409991183000045],
              [-80.610303902999988, 35.409977013000059],
              [-80.609745877999956, 35.409970033000036],
              [-80.609187788999975, 35.409970246000057],
              [-80.608629770999983, 35.409977651000077],
              [-80.608071965999955, 35.409992246000058],
              [-80.607514510999977, 35.410014028000035],
              [-80.606957541999975, 35.410042990000079],
              [-80.606401199999937, 35.410079127000074],
              [-80.605845620999958, 35.410122428000079],
              [-80.605290943999989, 35.410172884000076],
              [-80.604737304999958, 35.410230482000031],
              [-80.604184840999949, 35.410295207000047],
              [-80.603633689999981, 35.410367043000065],
              [-80.60308398799998, 35.410445973000037],
              [-80.602535870999986, 35.410531978000051],
              [-80.601989473999936, 35.410625035000066],
              [-80.60144493599995, 35.410725122000031],
              [-80.600902386999962, 35.410832214000038],
              [-80.600361963999944, 35.410946284000033],
              [-80.599823800999957, 35.411067305000074],
              [-80.599288030999958, 35.411195246000034],
              [-80.598754785999972, 35.411330075000023],
              [-80.598224198999958, 35.41147176000004],
              [-80.597696400999951, 35.411620265000067],
              [-80.597171522999986, 35.411775553000041],
              [-80.59664969499994, 35.411937585000032],
              [-80.596131045999982, 35.412106322000056],
              [-80.595615704999943, 35.412281723000035],
              [-80.595103798999958, 35.412463742000057],
              [-80.59459545499999, 35.412652335000075],
              [-80.594090800999936, 35.412847457000055],
              [-80.593589957999939, 35.41304905700008],
              [-80.593093053999951, 35.413257087000034],
              [-80.592600208999954, 35.413471495000067],
              [-80.592111547999934, 35.41369222600008],
              [-80.59162718999994, 35.413919229000044],
              [-80.591147255999942, 35.414152444000024],
              [-80.59067186499999, 35.414391816000034],
              [-80.590201132999937, 35.414637284000037],
              [-80.589735179999934, 35.414888788000042],
              [-80.58927411999997, 35.415146266000079],
              [-80.588818064999941, 35.41540965300004],
              [-80.588367130999984, 35.415678884000044],
              [-80.587921428999948, 35.41595389400004],
              [-80.587481068999978, 35.416234613000029],
              [-80.587046159999943, 35.416520972000058],
              [-80.586616810999942, 35.416812901000071],
              [-80.586193126999945, 35.417110325000067],
              [-80.585775212999977, 35.417413173000057],
              [-80.585363174999941, 35.417721369000049],
              [-80.584957111999984, 35.41803483700005],
              [-80.584557126999982, 35.418353498000045],
              [-80.58416331799998, 35.41867727500005],
              [-80.583775782999965, 35.419006087000071],
              [-80.583394617999943, 35.419339852000064],
              [-80.583019916999945, 35.419678487000056],
              [-80.582651774999988, 35.42002190900007],
              [-80.582290281999974, 35.42037003300004],
              [-80.581935525999938, 35.420722772000033],
              [-80.581587597999942, 35.42108003900006],
              [-80.581246581999949, 35.421441745000038],
              [-80.58091256299997, 35.421807801000057],
              [-80.58058562399998, 35.422178116000055],
              [-80.580265847999954, 35.422552599000028],
              [-80.57995331099994, 35.422931155000072],
              [-80.579648093999936, 35.423313693000068],
              [-80.579350269999964, 35.423700117000067],
              [-80.579059914999959, 35.42409033000007],
              [-80.578777098999979, 35.424484237000058],
              [-80.578501893999942, 35.424881739000057],
              [-80.578234366999936, 35.425282739000068],
              [-80.577974584999936, 35.425687137000068],
              [-80.577722611999945, 35.426094832000047],
              [-80.577478511999971, 35.426505724000037],
              [-80.577242344999945, 35.426919711000039],
              [-80.577014168999938, 35.427336689000072],
              [-80.576794040999971, 35.427756556000077],
              [-80.576582014999985, 35.428179208000074],
              [-80.576378143999989, 35.428604540000038],
              [-80.576182480999989, 35.429032446000065],
              [-80.575995070999966, 35.429462821000072],
              [-80.575815962999968, 35.429895557000066],
              [-80.575645201999976, 35.430330548000029],
              [-80.575482826999973, 35.430767686000024],
              [-80.575328881999951, 35.431206862000067],
              [-80.575183403999972, 35.43164796700006],
              [-80.575046427999951, 35.432090892000076],
              [-80.57491798999996, 35.432535528000074],
              [-80.574798121999947, 35.432981763000043],
              [-80.574686851999957, 35.433429488000058],
              [-80.574584208999966, 35.433878591000052],
              [-80.574490218999983, 35.434328961000062],
              [-80.574404904999938, 35.434780486000079],
              [-80.574328286999958, 35.435233055000026],
              [-80.574260386999981, 35.435686555000075],
              [-80.574201218999974, 35.436140874000046],
              [-80.574150798999938, 35.436595899000054],
              [-80.574109139999962, 35.437051517000043],
              [-80.574076252999987, 35.437507616000062],
              [-80.574052144999939, 35.437964082000065],
              [-80.574036821999982, 35.438420802000053],
              [-80.57403028899995, 35.438877661000049],
              [-80.574032547999934, 35.439334550000069],
              [-80.574043596999957, 35.439791353000032],
              [-80.57406343599996, 35.440247956000064],
              [-80.574092057999962, 35.440704246000053],
              [-80.574129454999934, 35.441160111000045],
              [-80.574175620999938, 35.441615439000032],
              [-80.574230541999952, 35.442070114000046],
              [-80.574294207999969, 35.442524025000068],
              [-80.574366599999962, 35.442977060000032],
              [-80.57444770099994, 35.443429105000064],
              [-80.574537490999944, 35.443880050000075],
              [-80.57463594799998, 35.444329781000079],
              [-80.574743048999949, 35.444778187000054],
              [-80.574858765999977, 35.445225158000028],
              [-80.574983070999963, 35.445670582000048],
              [-80.57511593299995, 35.446114348000037],
              [-80.575257320999981, 35.446556348000058],
              [-80.575407195999958, 35.446996471000034],
              [-80.575565524999945, 35.447434608000037],
              [-80.575732266999978, 35.447870650000027],
              [-80.575907380999979, 35.448304489000066],
              [-80.576090823999948, 35.448736017000044],
              [-80.576282550999963, 35.449165128000061],
              [-80.576482512999974, 35.449591715000054],
              [-80.576690660999986, 35.450015673000053],
              [-80.576906945999951, 35.450436895000053],
              [-80.57713131099996, 35.450855278000063],
              [-80.577363702999946, 35.451270717000057],
              [-80.577604061999978, 35.451683111000079],
              [-80.577852330999974, 35.452092355000048],
              [-80.578108446999977, 35.452498349000052],
              [-80.57837234699997, 35.452900993000071],
              [-80.578643965999959, 35.453300185000046],
              [-80.57892323599998, 35.453695828000036],
              [-80.579210088999957, 35.454087823000066],
              [-80.57950445299997, 35.45447607300008],
              [-80.579806253999948, 35.454860480000036],
              [-80.58011541999997, 35.455240952000054],
              [-80.580431871999963, 35.455617391000033],
              [-80.580755531999955, 35.455989707000072],
              [-80.581086320999987, 35.456357805000039],
              [-80.581424154999979, 35.456721594000044],
              [-80.581768953999983, 35.457080984000072],
              [-80.582120627999984, 35.457435887000031],
              [-80.582479091999971, 35.457786214000066],
              [-80.582844257999966, 35.458131878000074],
              [-80.583216032999985, 35.458472793000055],
              [-80.583594326999958, 35.458808875000045],
              [-80.583979045999968, 35.459140040000079],
              [-80.584370092999961, 35.459466206000059],
              [-80.584767372999977, 35.459787293000034],
              [-80.585170786999981, 35.460103220000065],
              [-80.585580233999963, 35.460413909000067],
              [-80.585995611999977, 35.46071928300006],
              [-80.586416819999954, 35.461019266000051],
              [-80.586843751999936, 35.461313785000073],
              [-80.587276303999943, 35.461602765000066],
              [-80.587714365999943, 35.461886135000043],
              [-80.588157831999979, 35.462163824000072],
              [-80.588606588999937, 35.462435764000077],
              [-80.589060528999937, 35.462701888000026],
              [-80.589519536999944, 35.462962129000061],
              [-80.58998349999996, 35.463216422000073],
              [-80.59045230199996, 35.463464705000035],
              [-80.590925827999968, 35.463706915000046],
              [-80.59140395999998, 35.463942994000035],
              [-80.591886579999937, 35.464172882000071],
              [-80.592373565999935, 35.464396521000026],
              [-80.592864800999962, 35.464613857000074],
              [-80.593360158999985, 35.464824837000037],
              [-80.593859518999977, 35.465029406000042],
              [-80.594362757999988, 35.465227514000048],
              [-80.594869749999987, 35.465419113000053],
              [-80.595380367999951, 35.46560415600004],
              [-80.595894486999953, 35.465782594000075],
              [-80.596411979999971, 35.465954385000032],
              [-80.596932717999948, 35.466119486000025],
              [-80.597456570999952, 35.466277856000033],
              [-80.597983407999948, 35.466429456000071],
              [-80.598513099999934, 35.466574248000029],
              [-80.59904551599999, 35.46671219600006],
              [-80.599580522999986, 35.46684326500008],
              [-80.600117986999976, 35.466967424000075],
              [-80.600657776999981, 35.467084641000042],
              [-80.601199756999961, 35.467194889000041],
              [-80.601743792999969, 35.467298138000046],
              [-80.602289750999944, 35.467394363000039],
              [-80.602837493999971, 35.467483540000046],
              [-80.603386886999942, 35.467565648000061],
              [-80.603937792999943, 35.467640666000079],
              [-80.60449007699998, 35.467708575000074],
              [-80.605043598999941, 35.467769359000044],
              [-80.605598222999959, 35.467823002000046],
              [-80.606153812999935, 35.467869491000044],
              [-80.60671022799994, 35.467908815000044],
              [-80.607267331999935, 35.467940964000036],
              [-80.607824985999969, 35.467965929000059],
              [-80.608383051999965, 35.467983705000051],
              [-80.608941390999973, 35.467994287000067],
              [-80.609499863999986, 35.46799767300007],
              [-80.610058333999973, 35.467993861000025]
            ]
          ],
          [
            [
              [-81.226644270999941, 35.501555698000061],
              [-81.227202734999935, 35.501541854000038],
              [-81.227760860999979, 35.501520817000028],
              [-81.228318512999977, 35.501492594000069],
              [-81.228875552999966, 35.501457191000043],
              [-81.229431840999951, 35.50141461700008],
              [-81.229987238999968, 35.501364883000065],
              [-81.230541608999943, 35.501308000000051],
              [-81.231094815999938, 35.501243984000041],
              [-81.231646718999968, 35.501172850000046],
              [-81.232197183999972, 35.501094615000056],
              [-81.232746072999987, 35.501009299000032],
              [-81.233293249999974, 35.50091692400008],
              [-81.233838577999961, 35.500817511000037],
              [-81.234381922999944, 35.50071108800006],
              [-81.234923150999975, 35.50059767700003],
              [-81.23546212499997, 35.500477309000075],
              [-81.235998712999958, 35.500350013000059],
              [-81.236532781999983, 35.50021582200003],
              [-81.237064197999985, 35.500074767000058],
              [-81.237592830999972, 35.499926885000036],
              [-81.238118548999978, 35.499772212000039],
              [-81.238641220999966, 35.499610786000062],
              [-81.239160716999947, 35.499442647000023],
              [-81.239676909999957, 35.499267838000037],
              [-81.24018966999995, 35.499086400000067],
              [-81.24069887099995, 35.49889838100006],
              [-81.241204383999957, 35.498703826000053],
              [-81.241706086999955, 35.49850278200006],
              [-81.242203852999978, 35.498295302000031],
              [-81.242697560999943, 35.498081436000064],
              [-81.243187085999978, 35.497861236000062],
              [-81.243672307999987, 35.497634758000061],
              [-81.244153106999988, 35.497402058000034],
              [-81.244629360999966, 35.497163194000052],
              [-81.245100954999941, 35.49691822300008],
              [-81.24556776999998, 35.496667209000066],
              [-81.24602969099999, 35.496410214000036],
              [-81.246486602999937, 35.496147299000029],
              [-81.246938393999983, 35.495878532000063],
              [-81.247384949999969, 35.495603978000076],
              [-81.247826160999978, 35.495323706000079],
              [-81.248261917999969, 35.495037786000069],
              [-81.248692111999958, 35.494746288000044],
              [-81.249116636999986, 35.49444928500003],
              [-81.24953538799997, 35.49414685000005],
              [-81.24994825999994, 35.493839058000049],
              [-81.250355151999941, 35.493525987000055],
              [-81.250755961999971, 35.493207713000061],
              [-81.251150590999941, 35.492884316000072],
              [-81.251538940999978, 35.49255587500005],
              [-81.25192091599996, 35.492222472000037],
              [-81.252296419999936, 35.491884191000054],
              [-81.252665362999949, 35.491541115000075],
              [-81.253027650999968, 35.491193329000055],
              [-81.253383192999934, 35.49084091900005],
              [-81.253731903999949, 35.490483973000039],
              [-81.254073695999978, 35.490122579000058],
              [-81.254408483999953, 35.489756827000065],
              [-81.254736186999935, 35.489386809000052],
              [-81.25505672099996, 35.489012616000025],
              [-81.255370006999954, 35.488634339000043],
              [-81.255675968999981, 35.488252073000069],
              [-81.255974530999936, 35.487865915000043],
              [-81.256265617999986, 35.487475957000072],
              [-81.256549157999984, 35.487082300000054],
              [-81.256825080999988, 35.486685038000076],
              [-81.257093318999978, 35.486284271000045],
              [-81.25735380499998, 35.485880099000042],
              [-81.257606474999989, 35.485472622000032],
              [-81.257851265999989, 35.485061940000037],
              [-81.258088116999943, 35.484648156000048],
              [-81.258316970999942, 35.484231372000067],
              [-81.258537768999986, 35.483811692000074],
              [-81.258750458999941, 35.483389220000049],
              [-81.258954985999935, 35.482964061000075],
              [-81.259151301999964, 35.482536319000076],
              [-81.259339353999962, 35.482106102000046],
              [-81.259519100999967, 35.481673516000058],
              [-81.259690493999983, 35.48123866800006],
              [-81.259853493999969, 35.480801666000048],
              [-81.260008057999983, 35.480362618000072],
              [-81.260154148999959, 35.47992163400005],
              [-81.260291731999985, 35.479478823000079],
              [-81.260420769999939, 35.47903429400003],
              [-81.260541234999948, 35.478588159000026],
              [-81.260653094999952, 35.47814052700005],
              [-81.260756321999963, 35.477691510000056],
              [-81.260850890999961, 35.477241218000074],
              [-81.260936778999962, 35.476789764000046],
              [-81.261013964999961, 35.476337260000037],
              [-81.261082429999988, 35.475883817000067],
              [-81.261142155999948, 35.475429549000069],
              [-81.261193129999981, 35.474974567000061],
              [-81.261235339999985, 35.474518986000078],
              [-81.261268771999937, 35.474062917000026],
              [-81.261293421999937, 35.473606473000075],
              [-81.261309280999967, 35.473149770000077],
              [-81.26131634699999, 35.472692918000064],
              [-81.261314618999961, 35.472236032000069],
              [-81.261304095999947, 35.471779224000045],
              [-81.261284780999972, 35.471322609000026],
              [-81.261256678999985, 35.470866300000068],
              [-81.261219796999967, 35.470410409000067],
              [-81.261174145999973, 35.469955049000077],
              [-81.261119734999966, 35.469500334000031],
              [-81.261056578999955, 35.469046376000051],
              [-81.260984693999944, 35.468593287000033],
              [-81.26090409699998, 35.468141181000078],
              [-81.260814808999953, 35.467690169000036],
              [-81.260716850999984, 35.46724036300003],
              [-81.260610248999967, 35.466791875000069],
              [-81.260495028999969, 35.46634481500007],
              [-81.260371219999968, 35.465899295000042],
              [-81.26023885099994, 35.465455425000073],
              [-81.26009795799996, 35.465013315000078],
              [-81.259948573999964, 35.464573074000043],
              [-81.259790735999957, 35.464134813000044],
              [-81.259624483999971, 35.463698639000029],
              [-81.25944986099995, 35.46326466000005],
              [-81.259266906999983, 35.462832984000045],
              [-81.259075669999959, 35.462403718000076],
              [-81.25887619599996, 35.46197697000008],
              [-81.258668535999959, 35.46155284300005],
              [-81.258452739999939, 35.461131445000035],
              [-81.258228862999943, 35.460712878000038],
              [-81.257996959999957, 35.460297246000039],
              [-81.257757088999938, 35.459884653000074],
              [-81.257509307999953, 35.459475202000078],
              [-81.257253681999941, 35.459068992000027],
              [-81.25699027099995, 35.458666126000026],
              [-81.256719141999952, 35.458266703000049],
              [-81.256440361999978, 35.457870822000075],
              [-81.256153998999935, 35.457478580000043],
              [-81.255860127999938, 35.457090077000032],
              [-81.255558816999951, 35.456705407000072],
              [-81.255250143999945, 35.456324665000068],
              [-81.254934183999978, 35.455947947000027],
              [-81.254611016999945, 35.455575345000057],
              [-81.254280720999986, 35.455206953000072],
              [-81.253943379999953, 35.454842861000031],
              [-81.253599075999944, 35.454483160000052],
              [-81.253247895999948, 35.454127937000067],
              [-81.252889925999966, 35.453777283000079],
              [-81.252525255999956, 35.453431284000033],
              [-81.252153973999953, 35.453090024000062],
              [-81.251776174999975, 35.452753590000043],
              [-81.251391951999949, 35.452422063000029],
              [-81.251001397999971, 35.45209552700004],
              [-81.25060461299995, 35.451774063000073],
              [-81.250201691999962, 35.451457748000053],
              [-81.249792738999986, 35.451146664000078],
              [-81.249377851999952, 35.450840886000037],
              [-81.248957134999955, 35.450540490000037],
              [-81.248530692999964, 35.45024555100008],
              [-81.248098629999959, 35.449956142000076],
              [-81.24766105499998, 35.44967233400007],
              [-81.247218076999957, 35.449394197000061],
              [-81.24676980299995, 35.449121801000047],
              [-81.246316345999958, 35.448855214000048],
              [-81.24585781799999, 35.44859450000007],
              [-81.245394332999979, 35.448339725000039],
              [-81.244926004999968, 35.44809095200003],
              [-81.244452950999971, 35.447848243000067],
              [-81.243975287999945, 35.447611658000028],
              [-81.243493132999959, 35.44738125300006],
              [-81.243006607999973, 35.447157089000029],
              [-81.242515831999981, 35.446939221000036],
              [-81.242020926999942, 35.446727700000054],
              [-81.241522016999966, 35.446522581000067],
              [-81.241019221999977, 35.446323914000061],
              [-81.240512669999987, 35.446131748000028],
              [-81.240002483999945, 35.445946131000028],
              [-81.239488792999964, 35.44576710900003],
              [-81.238971721999974, 35.445594727000071],
              [-81.238451400999963, 35.445429026000056],
              [-81.237927956999954, 35.445270048000054],
              [-81.237401521999971, 35.445117832000051],
              [-81.236872223999967, 35.444972417000031],
              [-81.236340195999958, 35.444833838000079],
              [-81.235805568999979, 35.444702129000063],
              [-81.235268474999941, 35.444577323000033],
              [-81.234729047999963, 35.444459451000057],
              [-81.234187420999945, 35.444348543000046],
              [-81.233643728999937, 35.444244624000078],
              [-81.233098104999954, 35.444147723000071],
              [-81.232550685999968, 35.444057862000079],
              [-81.232001606999972, 35.443975063000039],
              [-81.231451002999961, 35.443899348000059],
              [-81.230899011999952, 35.443830734000073],
              [-81.230345769999985, 35.443769240000051],
              [-81.229791413999976, 35.443714880000073],
              [-81.229236080999954, 35.443667667000057],
              [-81.228679908999936, 35.443627613000046],
              [-81.228123035999943, 35.443594730000029],
              [-81.227565599999934, 35.443569022000077],
              [-81.227007737999941, 35.443550500000072],
              [-81.226449588999969, 35.443539165000061],
              [-81.225891290999982, 35.443535021000059],
              [-81.225332983999976, 35.443538069000056],
              [-81.224774801999956, 35.44354830900005],
              [-81.224216887999944, 35.443565738000075],
              [-81.223659377999979, 35.443590352000058],
              [-81.223102409999967, 35.443622144000074],
              [-81.222546122999972, 35.443661106000036],
              [-81.221990652999978, 35.44370723000003],
              [-81.22143613999998, 35.443760502000032],
              [-81.220882719999963, 35.443820912000035],
              [-81.220330528999966, 35.443888442000059],
              [-81.219779704999951, 35.443963077000035],
              [-81.219230384999946, 35.44404479800005],
              [-81.218682703999946, 35.444133586000078],
              [-81.218136797999989, 35.444229417000031],
              [-81.217592803999935, 35.444332269000029],
              [-81.217050852999989, 35.444442114000026],
              [-81.216511081999954, 35.44455892700006],
              [-81.215973624999947, 35.444682680000028],
              [-81.215438613999936, 35.444813339000063],
              [-81.214906180999947, 35.444950874000028],
              [-81.214376459999983, 35.445095252000044],
              [-81.213849578999941, 35.445246434000069],
              [-81.213325671999939, 35.445404385000074],
              [-81.212804866999988, 35.44556906400004],
              [-81.212287293999964, 35.445740432000036],
              [-81.211773078999954, 35.445918447000054],
              [-81.211262351999949, 35.446103062000077],
              [-81.21075523799999, 35.44629423300006],
              [-81.210251863999986, 35.446491914000035],
              [-81.209752352999942, 35.446696054000029],
              [-81.209256829999958, 35.446906603000059],
              [-81.208765416999938, 35.44712350900005],
              [-81.208278237999934, 35.447346718000063],
              [-81.20779540999996, 35.447576176000041],
              [-81.207317055999965, 35.447811823000052],
              [-81.206843291999974, 35.448053604000052],
              [-81.206374235999988, 35.44830145800006],
              [-81.205910005999954, 35.448555322000061],
              [-81.205450716999962, 35.448815136000064],
              [-81.204996479999977, 35.449080834000029],
              [-81.204547409999975, 35.449352349000037],
              [-81.204103617999976, 35.449629617000028],
              [-81.203665212999965, 35.449912565000034],
              [-81.203232303999982, 35.450201127000071],
              [-81.202804999999955, 35.450495229000069],
              [-81.202383404999978, 35.450794798000061],
              [-81.201967622999973, 35.451099762000069],
              [-81.201557759999957, 35.451410043000067],
              [-81.201153914999963, 35.45172556600005],
              [-81.200756187999957, 35.45204625100007],
              [-81.20036467999995, 35.452372020000041],
              [-81.199979485999961, 35.452702792000025],
              [-81.199600701999941, 35.453038485000036],
              [-81.199228422999965, 35.453379015000053],
              [-81.198862739999981, 35.453724299000044],
              [-81.198503743999936, 35.454074250000076],
              [-81.198151523999968, 35.454428782000036],
              [-81.197806168999989, 35.454787807000059],
              [-81.197467761999974, 35.455151237000052],
              [-81.19713638799999, 35.455518980000079],
              [-81.196812128999966, 35.455890946000068],
              [-81.19649506799999, 35.456267044000072],
              [-81.196185278999963, 35.456647178000026],
              [-81.195882843999982, 35.45703125700004],
              [-81.19558783399998, 35.45741918300007],
              [-81.195300323999959, 35.457810861000041],
              [-81.195020383999974, 35.458206195000059],
              [-81.194748086999937, 35.458605085000045],
              [-81.194483495999975, 35.459007433000068],
              [-81.194226678999939, 35.459413140000038],
              [-81.193977700999937, 35.459822105000057],
              [-81.193736620999971, 35.460234226000068],
              [-81.193503501999942, 35.460649401000069],
              [-81.193278398999951, 35.461067528000058],
              [-81.193061369999953, 35.461488502000066],
              [-81.192852466999966, 35.461912220000045],
              [-81.192651742999942, 35.462338577000025],
              [-81.192459248999967, 35.46276746500007],
              [-81.192275030999951, 35.463198781000074],
              [-81.192099135999968, 35.463632416000053],
              [-81.191931606999958, 35.464068263000058],
              [-81.191772484999944, 35.464506214000039],
              [-81.191621811999937, 35.464946160000068],
              [-81.191479622999964, 35.465387993000036],
              [-81.191345953999985, 35.465831602000037],
              [-81.191220839999971, 35.466276878000031],
              [-81.191104310999947, 35.466723710000053],
              [-81.190996393999967, 35.467171988000075],
              [-81.190897118999942, 35.46762160000003],
              [-81.19080650899997, 35.468072436000057],
              [-81.190724587999966, 35.468524383000045],
              [-81.190651374999959, 35.46897732900004],
              [-81.190586888999974, 35.469431162000035],
              [-81.190531144999966, 35.46988576900003],
              [-81.190484158999936, 35.470341038000072],
              [-81.19044594099995, 35.47079685600005],
              [-81.190416501999948, 35.471253109000031],
              [-81.190395849999959, 35.471709685000064],
              [-81.190383986999962, 35.472166471000037],
              [-81.190380918999949, 35.472623351000038],
              [-81.190386646999968, 35.473080216000028],
              [-81.190401166999948, 35.473536950000039],
              [-81.190424478999944, 35.473993440000072],
              [-81.190456575999974, 35.474449573000072],
              [-81.190497447999974, 35.474905236000041],
              [-81.190547088999949, 35.475360316000035],
              [-81.190605482999956, 35.475814700000058],
              [-81.19067261899994, 35.476268275000052],
              [-81.190748477999989, 35.476720930000056],
              [-81.190833042999941, 35.477172551000024],
              [-81.190926291999972, 35.477623028000039],
              [-81.191028201999984, 35.478072246000067],
              [-81.191138748999947, 35.478520096000068],
              [-81.19125790399994, 35.478966467000078],
              [-81.191385638999975, 35.47941124700003],
              [-81.191521923999971, 35.479854327000055],
              [-81.191666721999979, 35.480295596000076],
              [-81.191819997999971, 35.480734945000052],
              [-81.191981715999987, 35.481172266000044],
              [-81.192151833999958, 35.48160744900008],
              [-81.192330310999978, 35.482040386000051],
              [-81.192517102999943, 35.482470971000055],
              [-81.192712162999953, 35.48289909600004],
              [-81.192915442999947, 35.483324655000047],
              [-81.193126892999942, 35.483747544000039],
              [-81.193346460999976, 35.484167656000068],
              [-81.193574090999959, 35.48458488700004],
              [-81.193809728999952, 35.484999134000077],
              [-81.194053313999973, 35.485410295000065],
              [-81.194304788999943, 35.48581826700007],
              [-81.194564088999982, 35.48622294900008],
              [-81.19483115099996, 35.486624241000072],
              [-81.19510590799996, 35.487022042000035],
              [-81.195388292999951, 35.487416255000028],
              [-81.195678234999946, 35.487806782000064],
              [-81.195975662999956, 35.488193526000032],
              [-81.196280502999969, 35.488576389000059],
              [-81.196592679999981, 35.488955280000027],
              [-81.196912115999964, 35.489330101000064],
              [-81.19723873099997, 35.489700762000041],
              [-81.197572445999981, 35.490067169000042],
              [-81.197913176999975, 35.490429231000064],
              [-81.198260839999989, 35.490786860000071],
              [-81.198615348999965, 35.491139967000038],
              [-81.198976614999935, 35.491488463000053],
              [-81.199344549999978, 35.491832262000059],
              [-81.199719060999939, 35.492171279000047],
              [-81.200100056999986, 35.492505429000062],
              [-81.200487442999986, 35.492834630000061],
              [-81.200881120999952, 35.49315880100005],
              [-81.201280996999969, 35.493477860000041],
              [-81.201686968999979, 35.493791729000066],
              [-81.20209893699996, 35.494100329000048],
              [-81.202516798999966, 35.494403585000043],
              [-81.20294045199995, 35.49470141900008],
              [-81.203369789999954, 35.494993760000057],
              [-81.203804706999961, 35.495280535000063],
              [-81.204245094999976, 35.495561671000075],
              [-81.20469084399997, 35.495837099000028],
              [-81.205141844999957, 35.49610675200006],
              [-81.20559798499994, 35.496370562000038],
              [-81.206059149999987, 35.496628463000036],
              [-81.206525227999975, 35.496880392000037],
              [-81.206996101999948, 35.497126287000071],
              [-81.20747165399996, 35.497366084000078],
              [-81.207951767999987, 35.497599727000079],
              [-81.20843632399999, 35.497827156000028],
              [-81.208925200999943, 35.498048315000062],
              [-81.209418280999955, 35.498263149000024],
              [-81.20991543699995, 35.498471606000066],
              [-81.210416547999955, 35.498673632000077],
              [-81.210921490999965, 35.498869179000053],
              [-81.211430137999969, 35.499058196000078],
              [-81.211942363999981, 35.49924063900005],
              [-81.212458040999934, 35.499416460000077],
              [-81.212977042999967, 35.499585617000037],
              [-81.213499239999976, 35.499748068000031],
              [-81.214024502999962, 35.499903771000049],
              [-81.214552698999967, 35.500052690000075],
              [-81.215083700999969, 35.500194787000055],
              [-81.215617374999965, 35.500330026000029],
              [-81.216153587999941, 35.500458373000072],
              [-81.216692206999937, 35.500579798000047],
              [-81.217233100999977, 35.500694269000064],
              [-81.217776131999983, 35.500801759000069],
              [-81.218321166999942, 35.500902241000063],
              [-81.218868070999974, 35.500995690000025],
              [-81.219416707999983, 35.50108208100005],
              [-81.219966940999939, 35.501161395000054],
              [-81.220518634999962, 35.501233612000078],
              [-81.221071651999978, 35.501298714000029],
              [-81.221625853999967, 35.50135668300004],
              [-81.222181104999947, 35.501407506000078],
              [-81.222737266999957, 35.501451171000042],
              [-81.223294199999941, 35.501487667000049],
              [-81.223851766999985, 35.501516984000034],
              [-81.224409830999946, 35.501539115000071],
              [-81.22496825199994, 35.501554054000053],
              [-81.225526891999948, 35.501561800000047],
              [-81.226085609999984, 35.501562347000061],
              [-81.226644270999941, 35.501555698000061]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 4,
        "SolutionID": "PI-3",
        "Shape_Length": 1.0131959163109732,
        "Shape_Area": 0.01610277733306675
      }
    },
    {
      "type": "Feature",
      "id": 5,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.249194963999969, 35.025268501000028],
            [-81.249308402999986, 35.025685689000056],
            [-81.249176231999968, 35.02864855200005],
            [-81.24917439699999, 35.028863729000079],
            [-81.249190368999962, 35.02910071000008],
            [-81.249197524999943, 35.029161497000075],
            [-81.249618103999978, 35.032445753000047],
            [-81.249638577999974, 35.032581136000033],
            [-81.250128588999985, 35.035388948000048],
            [-81.250135741999941, 35.035428393000075],
            [-81.250189189999958, 35.035661631000039],
            [-81.250261069999965, 35.035891495000044],
            [-81.250351074999969, 35.036117000000047],
            [-81.250458818999959, 35.036337181000079],
            [-81.250462550999941, 35.03634412100007],
            [-81.251332310999942, 35.037956976000032],
            [-81.251453606999974, 35.038163948000033],
            [-81.251505763999944, 35.038243734000048],
            [-81.252125252999974, 35.039163639000037],
            [-81.25061982699998, 35.046145577000061],
            [-81.250189018999947, 35.047784656000033],
            [-81.25015581699995, 35.047924162000072],
            [-81.250115268999934, 35.048159152000039],
            [-81.250106523999989, 35.048232216000031],
            [-81.249975190999976, 35.049456471000042],
            [-81.249962162999964, 35.049620076000053],
            [-81.249959672999978, 35.049679195000067],
            [-81.249913379999953, 35.051116747000037],
            [-81.249790520999966, 35.051219443000036],
            [-81.247385838999946, 35.052349222000032],
            [-81.247362905999978, 35.052360071000066],
            [-81.239948320999986, 35.055891798000061],
            [-81.239867990999983, 35.055930997000075],
            [-81.239626378999958, 35.056060847000026],
            [-81.239622079999947, 35.05606332800005],
            [-81.235437139999988, 35.05848269300003],
            [-81.235210657999971, 35.058622768000077],
            [-81.235166848999938, 35.058652101000064],
            [-81.233829599999979, 35.059559269000033],
            [-81.233654454999964, 35.059684595000078],
            [-81.23344826999994, 35.059850698000048],
            [-81.233262746999969, 35.060020673000054],
            [-81.231172206999986, 35.062061727000071],
            [-81.231167644999971, 35.062066188000074],
            [-81.227388644999962, 35.065767435000055],
            [-81.227386197999977, 35.065769833000047],
            [-81.227208116999975, 35.065956635000077],
            [-81.227073493999967, 35.066116618000024],
            [-81.224639052999976, 35.06918205900007],
            [-81.224610833999975, 35.069218053000043],
            [-81.224463918999959, 35.069422364000047],
            [-81.224435394999944, 35.069465870000045],
            [-81.223128345999953, 35.071492336000063],
            [-81.223026516999937, 35.071660602000065],
            [-81.222913288999962, 35.071878930000025],
            [-81.222817670999973, 35.072102878000067],
            [-81.222740072999954, 35.072331488000032],
            [-81.222680827999966, 35.072563780000053],
            [-81.222659120999936, 35.072675527000058],
            [-81.222444813999971, 35.073901268000043],
            [-81.222425879999946, 35.074024502000043],
            [-81.22240530199997, 35.07423840000007],
            [-81.222064423999939, 35.079912448000073],
            [-81.222063142999957, 35.079935213000056],
            [-81.22205926099997, 35.080126642000039],
            [-81.222090979999962, 35.082807220000063],
            [-81.222091876999968, 35.082853122000074],
            [-81.222107780999977, 35.083090105000053],
            [-81.222142505999955, 35.083325727000044],
            [-81.222195904999978, 35.083558976000063],
            [-81.222267747999979, 35.08378885500008],
            [-81.222288737999975, 35.083846162000043],
            [-81.222822758999939, 35.085261759000048],
            [-81.222891752999942, 35.085429977000047],
            [-81.222999487999971, 35.085650180000073],
            [-81.223124513999949, 35.085864121000043],
            [-81.223165567999956, 35.085927324000068],
            [-81.22749300299995, 35.092433302000074],
            [-81.228701561999969, 35.09434000300007],
            [-81.228982439999982, 35.095252736000077],
            [-81.229033891999961, 35.095783306000044],
            [-81.228770853999947, 35.097676697000054],
            [-81.22836439699995, 35.098999279000054],
            [-81.225885859999948, 35.106255139000041],
            [-81.22159620299999, 35.11042720100005],
            [-81.221584867999979, 35.110438270000031],
            [-81.221406675999958, 35.110625063000043],
            [-81.221243732999937, 35.110821030000068],
            [-81.221096733999957, 35.111025337000058],
            [-81.220976616999963, 35.111219049000056],
            [-81.22034567299994, 35.112316683000074],
            [-81.220335367999951, 35.112334740000051],
            [-81.220238827999935, 35.112518144000035],
            [-81.216342364999946, 35.120517651000057],
            [-81.212019116999954, 35.128845645000069],
            [-81.211964578999982, 35.12895565000008],
            [-81.211868863999939, 35.129179588000056],
            [-81.211791180999967, 35.12940818900006],
            [-81.211774170999945, 35.129467575000035],
            [-81.209908906999942, 35.136233882000056],
            [-81.20986659099998, 35.136406782000051],
            [-81.209825882999951, 35.136641756000074],
            [-81.209803969999939, 35.136878415000069],
            [-81.209802010999965, 35.136921653000059],
            [-81.209531915999946, 35.144016434000036],
            [-81.209013759999948, 35.147556725000072],
            [-81.208352179999963, 35.148386048000077],
            [-81.204449969999985, 35.150696054000036],
            [-81.198656142999937, 35.152723009000056],
            [-81.198606734999942, 35.15274058600005],
            [-81.198346216999937, 35.152843302000065],
            [-81.198094435999963, 35.152959791000058],
            [-81.19785246899994, 35.153089556000054],
            [-81.197621354999967, 35.153232039000045],
            [-81.197402079999961, 35.153386631000046],
            [-81.197195583999985, 35.153552670000067],
            [-81.197002752999936, 35.153729446000057],
            [-81.196824410999966, 35.153916201000072],
            [-81.196661321999954, 35.154112134000059],
            [-81.196514183999966, 35.154316409000046],
            [-81.196490059999974, 35.154353047000029],
            [-81.195083914999941, 35.156518160000076],
            [-81.194977477999942, 35.156693262000033],
            [-81.194972360999941, 35.15670231200005],
            [-81.194413549999979, 35.157694357000025],
            [-81.194228885999962, 35.158019283000044],
            [-81.194031310999947, 35.158364157000051],
            [-81.194024357999979, 35.158376352000062],
            [-81.194016966999982, 35.158389446000058],
            [-81.193966756999941, 35.158478867000042],
            [-81.193940648999956, 35.158526255000027],
            [-81.193871738999974, 35.15865375900006],
            [-81.193842208999968, 35.158709658000078],
            [-81.193775916999982, 35.158838093000043],
            [-81.193747547999976, 35.158894363000059],
            [-81.193683890999978, 35.159023693000051],
            [-81.193661861999942, 35.159069339000041],
            [-81.19365744199996, 35.159078718000046],
            [-81.193599952999989, 35.159201230000065],
            [-81.193578350999985, 35.159248233000028],
            [-81.19353678899995, 35.15934059600005],
            [-81.193518959999949, 35.159380949000024],
            [-81.193478730999971, 35.159473712000079],
            [-81.19345962899996, 35.159518691000073],
            [-81.193421074999947, 35.159611428000062],
            [-81.193406279999977, 35.159647638000024],
            [-81.193368975999988, 35.159740558000067],
            [-81.193355044999976, 35.159775864000039],
            [-81.193315888999962, 35.159876858000075],
            [-81.193311765999965, 35.159887550000064],
            [-81.193302384999981, 35.159912314000053],
            [-81.193267404999972, 35.160005839000064],
            [-81.193254329999945, 35.160041449000062],
            [-81.19321770199997, 35.160143082000047],
            [-81.193200170999944, 35.160193038000045],
            [-81.193141193999963, 35.160365743000057],
            [-81.193113998999934, 35.160449189000076],
            [-81.193032570999947, 35.160711516000049],
            [-81.193023371999971, 35.160741689000076],
            [-81.192935147999947, 35.161036443000057],
            [-81.192933763999974, 35.161041082000054],
            [-81.192928141999971, 35.161060199000076],
            [-81.192886397999985, 35.161203829000044],
            [-81.192879829999981, 35.161226758000055],
            [-81.192809079999961, 35.161477388000037],
            [-81.192791769999985, 35.161534430000074],
            [-81.192786963999936, 35.161550420000026],
            [-81.192784053999958, 35.16156025600003],
            [-81.192420263999963, 35.162797529000045],
            [-81.192410393999978, 35.162828855000043],
            [-81.192402069999957, 35.16285368900003],
            [-81.192390473999978, 35.162886313000058],
            [-81.192382646999988, 35.162907134000079],
            [-81.192351395999935, 35.16298536000005],
            [-81.192340243999979, 35.16301366600004],
            [-81.19228716899994, 35.163150287000065],
            [-81.192246395999973, 35.163249885000027],
            [-81.192191983999976, 35.163375819000066],
            [-81.192138872999976, 35.163492143000042],
            [-81.192074694999974, 35.163625628000034],
            [-81.191998349999949, 35.163779666000039],
            [-81.191936270999975, 35.163901745000032],
            [-81.191865156999938, 35.164038508000033],
            [-81.191841367999984, 35.164085157000045],
            [-81.191723604999936, 35.164320672000031],
            [-81.19159941099997, 35.164562902000057],
            [-81.19156996199996, 35.164621779000072],
            [-81.19153642699996, 35.164690535000034],
            [-81.191519675999984, 35.164725400000066],
            [-81.191508298999963, 35.164749702000051],
            [-81.191444173999969, 35.164888150000024],
            [-81.191417352999963, 35.164947617000053],
            [-81.191386769999951, 35.165017292000073],
            [-81.191364858999975, 35.165068403000078],
            [-81.19131926099999, 35.165177357000061],
            [-81.191293878999943, 35.165239845000031],
            [-81.191250697999976, 35.165349461000062],
            [-81.191240375999939, 35.165376015000049],
            [-81.191226702999984, 35.165412318000051],
            [-81.191185952999945, 35.165522560000056],
            [-81.191162581999947, 35.165587994000077],
            [-81.191121632999966, 35.165706765000039],
            [-81.19109974099996, 35.165772620000041],
            [-81.191064068999935, 35.165884043000062],
            [-81.191045229999986, 35.165945037000029],
            [-81.191035831999955, 35.165977246000068],
            [-81.191015189999973, 35.166049446000045],
            [-81.191002698999966, 35.166094420000036],
            [-81.190987514999961, 35.166150740000035],
            [-81.190972856999963, 35.166207236000048],
            [-81.190946689999976, 35.166312184000049],
            [-81.19092868499996, 35.166388557000062],
            [-81.190903463999973, 35.166502047000051],
            [-81.190898611999955, 35.166524267000057],
            [-81.190890241999966, 35.166564636000032],
            [-81.190877214999944, 35.166629686000078],
            [-81.190864343999976, 35.166698021000059],
            [-81.19084131599999, 35.166828502000044],
            [-81.190826608999942, 35.166920018000042],
            [-81.190807631999974, 35.167050936000066],
            [-81.190802800999961, 35.167085684000028],
            [-81.190798472999973, 35.167119706000051],
            [-81.190790505999985, 35.167185304000043],
            [-81.190782938999973, 35.167254264000064],
            [-81.190770058999988, 35.167385683000077],
            [-81.190764181999953, 35.167453605000048],
            [-81.190759290999949, 35.167518793000056],
            [-81.190755086999957, 35.167584543000032],
            [-81.190754962999961, 35.16758691900003],
            [-81.19074816899996, 35.167717421000077],
            [-81.190744825999957, 35.167808315000059],
            [-81.19074201899997, 35.167938916000026],
            [-81.190741332999949, 35.168007181000064],
            [-81.190741425999988, 35.168072492000078],
            [-81.190742373999967, 35.168143550000025],
            [-81.190745882999977, 35.168282306000037],
            [-81.190746006999973, 35.168287040000052],
            [-81.190749411999946, 35.168370331000062],
            [-81.190755679999938, 35.168484508000063],
            [-81.190759548999949, 35.168544095000073],
            [-81.190763826999955, 35.168601135000074],
            [-81.190768053999989, 35.168651741000076],
            [-81.190811459999964, 35.169123013000046],
            [-81.190846892999957, 35.169545701000061],
            [-81.190849103999938, 35.169570799000041],
            [-81.19086953599998, 35.16979210900007],
            [-81.190871049999942, 35.169808045000025],
            [-81.190893470999981, 35.170037473000036],
            [-81.190895103999935, 35.170053726000049],
            [-81.190918780999937, 35.170283070000039],
            [-81.190919900999972, 35.170293733000051],
            [-81.190920473999938, 35.170299051000029],
            [-81.190942432999975, 35.170501132000027],
            [-81.191037659999949, 35.171495385000071],
            [-81.19104096999996, 35.171528052000042],
            [-81.191043261999937, 35.17154881700003],
            [-81.191061809999951, 35.171711626000047],
            [-81.191069685999935, 35.171774797000069],
            [-81.191090600999985, 35.171929187000046],
            [-81.191099547999954, 35.17199047500003],
            [-81.19112365999996, 35.172144551000031],
            [-81.191134189999957, 35.17220747600004],
            [-81.19116302599997, 35.172369273000072],
            [-81.191168053999945, 35.172396752000054],
            [-81.191197554999974, 35.172535832000051],
            [-81.191224317999968, 35.172648938000066],
            [-81.19123755399994, 35.17270269800008],
            [-81.19126433699995, 35.172807406000061],
            [-81.191274969999938, 35.172847828000045],
            [-81.191278534999981, 35.172860903000071],
            [-81.191309448999959, 35.172973297000055],
            [-81.191324662999989, 35.173026722000031],
            [-81.191355287999954, 35.173130717000049],
            [-81.191369607999945, 35.173177960000032],
            [-81.191396958999974, 35.173265664000041],
            [-81.191424457999972, 35.173349642000062],
            [-81.191435300999956, 35.173381239000037],
            [-81.19144653699999, 35.173413412000059],
            [-81.191454455999974, 35.173435442000027],
            [-81.191488746999937, 35.173529722000069],
            [-81.191509011999983, 35.173583891000078],
            [-81.191545092999945, 35.173677720000057],
            [-81.191565539999942, 35.173729535000064],
            [-81.191600182999935, 35.173815126000079],
            [-81.191620560999979, 35.173864294000055],
            [-81.19165669299997, 35.173949471000071],
            [-81.191677678999952, 35.173997834000033],
            [-81.191677965999986, 35.173998484000037],
            [-81.191715582999961, 35.174083225000061],
            [-81.191737696999951, 35.174131954000075],
            [-81.191776789999949, 35.174216244000036],
            [-81.191798654999957, 35.174262438000028],
            [-81.191835466999976, 35.174338660000046],
            [-81.191857091999964, 35.174382597000033],
            [-81.191895112999987, 35.174458417000039],
            [-81.191914380999947, 35.174496232000024],
            [-81.192192862999946, 35.175034215000039],
            [-81.19249608399997, 35.175627990000066],
            [-81.192507230999979, 35.175649623000027],
            [-81.192667982999978, 35.175958776000073],
            [-81.192682734999948, 35.175986820000048],
            [-81.192846653999936, 35.176294853000059],
            [-81.192851723999979, 35.176304341000048],
            [-81.192858558999944, 35.176317021000045],
            [-81.192955975999951, 35.176496801000042],
            [-81.192964954999979, 35.176513261000025],
            [-81.193066979999969, 35.176699050000025],
            [-81.193115465999938, 35.176784370000064],
            [-81.193171431999986, 35.176879606000057],
            [-81.193194447999986, 35.176918188000059],
            [-81.193265014999952, 35.17703472200003],
            [-81.193290202999947, 35.177075666000064],
            [-81.193358036999939, 35.17718422300004],
            [-81.19337061899995, 35.177204204000077],
            [-81.193383835999953, 35.177224873000057],
            [-81.193457810999973, 35.17733965900004],
            [-81.193485147999979, 35.17738141500007],
            [-81.193561072999955, 35.17749559300006],
            [-81.193587597999965, 35.177534902000048],
            [-81.193658547999974, 35.177638523000041],
            [-81.193696913999986, 35.177693429000044],
            [-81.193736596999941, 35.177749089000031],
            [-81.193773018999934, 35.177799243000038],
            [-81.19377824899999, 35.177806297000075],
            [-81.193845427999975, 35.177896671000042],
            [-81.193887037999957, 35.177951540000038],
            [-81.193955847999973, 35.178040503000034],
            [-81.193998352999984, 35.178094398000042],
            [-81.194068786999935, 35.178181994000056],
            [-81.194112408999956, 35.17823521300005],
            [-81.194184719999953, 35.17832177300005],
            [-81.194209790999935, 35.178351468000074],
            [-81.19422415799994, 35.178368204000037],
            [-81.194281041999943, 35.178434069000048],
            [-81.194344830999967, 35.178506041000048],
            [-81.194422170999985, 35.178591089000065],
            [-81.194477626999969, 35.178650772000026],
            [-81.194557095999983, 35.178734489000078],
            [-81.194597014999943, 35.178775916000063],
            [-81.194611867999981, 35.178791018000027],
            [-81.194687082999963, 35.178867079000042],
            [-81.194740933999981, 35.178920486000038],
            [-81.194817908999937, 35.178995354000051],
            [-81.194875319999937, 35.179050080000025],
            [-81.194960658999946, 35.179129807000038],
            [-81.195022824999967, 35.179186666000078],
            [-81.195033622999972, 35.179196299000068],
            [-81.195155062999959, 35.179304260000038],
            [-81.19525947599999, 35.179393956000069],
            [-81.195386209999981, 35.17949916300006],
            [-81.195451727999966, 35.179552429000069],
            [-81.195552357999986, 35.179632549000075],
            [-81.195573857999989, 35.179649552000058],
            [-81.195610850999969, 35.179678276000061],
            [-81.195713249999983, 35.179756874000077],
            [-81.195785586999989, 35.179811175000054],
            [-81.195934866999949, 35.179920763000041],
            [-81.196040843999981, 35.179996111000037],
            [-81.196067719999974, 35.180014467000035],
            [-81.19618074999994, 35.18009104500004],
            [-81.19622329699996, 35.18011950600004],
            [-81.196300576999988, 35.18017055200005],
            [-81.196360052999978, 35.180209152000032],
            [-81.196508888999972, 35.180304053000043],
            [-81.196590369999967, 35.180354777000048],
            [-81.19674981299994, 35.180451668000046],
            [-81.196766896999975, 35.180461998000055],
            [-81.196813386999963, 35.180489589000047],
            [-81.196894146999966, 35.180536863000043],
            [-81.19695857399995, 35.180573870000046],
            [-81.197122114999956, 35.180666042000041],
            [-81.197249843999941, 35.180735387000027],
            [-81.197314992999964, 35.180768789000069],
            [-81.197476528999971, 35.180850015000033],
            [-81.197508272999983, 35.180865827000048],
            [-81.197670634999952, 35.180945935000068],
            [-81.197702527999979, 35.180961521000029],
            [-81.197865705999959, 35.181040507000034],
            [-81.197897712999975, 35.181055852000043],
            [-81.198061689999975, 35.181133709000051],
            [-81.198099874999969, 35.181151631000034],
            [-81.198325545999978, 35.181256333000078],
            [-81.198352217999968, 35.181268608000039],
            [-81.199265656999955, 35.181685576000064],
            [-81.19933888199995, 35.181720084000062],
            [-81.199410556999965, 35.181755147000047],
            [-81.199446381999962, 35.181773240000041],
            [-81.19951456199999, 35.181808985000032],
            [-81.199515915999939, 35.181809870000052],
            [-81.199533822999967, 35.181822062000037],
            [-81.199564240999962, 35.181844426000055],
            [-81.199592713999948, 35.181867183000065],
            [-81.199596442999962, 35.181871750000028],
            [-81.199603673999945, 35.181881077000071],
            [-81.199650229999975, 35.181943162000039],
            [-81.199693338999964, 35.182003447000056],
            [-81.19971048299999, 35.18202836200004],
            [-81.199731223999947, 35.182059398000035],
            [-81.199767655999949, 35.182115620000047],
            [-81.199835520999954, 35.18221645400007],
            [-81.199836299999959, 35.182217568000056],
            [-81.199851526999964, 35.182243289000041],
            [-81.199906925999983, 35.182333640000024],
            [-81.199920112999962, 35.182354258000032],
            [-81.200212535999981, 35.182807926000066],
            [-81.200503354999967, 35.183262292000052],
            [-81.200519292999957, 35.183286961000078],
            [-81.200679469999955, 35.18353260300006],
            [-81.200828261999959, 35.183763418000069],
            [-81.201002701999982, 35.184037400000079],
            [-81.201175916999944, 35.184313106000047],
            [-81.201184291999937, 35.184326368000029],
            [-81.201202778999971, 35.184355182000047],
            [-81.201357186999985, 35.184593243000052],
            [-81.201663423999946, 35.185070852000024],
            [-81.201817218999963, 35.185313397000073],
            [-81.201867014999948, 35.185394238000072],
            [-81.201914454999951, 35.185475231000055],
            [-81.20192476699998, 35.185493617000077],
            [-81.202005038999971, 35.185647367000058],
            [-81.202020442999981, 35.185677949000024],
            [-81.202034051999988, 35.185706099000072],
            [-81.202047147999963, 35.185734370000034],
            [-81.202059371999951, 35.185761938000041],
            [-81.202073394999957, 35.185795091000045],
            [-81.202083882999943, 35.185821116000056],
            [-81.20208784099998, 35.185831433000033],
            [-81.202095977999988, 35.185854822000067],
            [-81.202110872999981, 35.185899616000029],
            [-81.202125394999939, 35.185945331000028],
            [-81.202141283999936, 35.185997949000068],
            [-81.202154120999978, 35.186042771000075],
            [-81.202168450999977, 35.186095669000053],
            [-81.202180028999976, 35.186140985000065],
            [-81.202192452999952, 35.18619267300005],
            [-81.202206521999983, 35.186255550000055],
            [-81.202220254999986, 35.186322525000037],
            [-81.202232420999962, 35.186387996000065],
            [-81.202244163999978, 35.186458380000033],
            [-81.202251259999969, 35.186505354000076],
            [-81.202304353999978, 35.186874732000035],
            [-81.202306386999965, 35.186888648000036],
            [-81.20235482399994, 35.187214806000043],
            [-81.202391535999936, 35.187489463000077],
            [-81.202395991999936, 35.187521494000066],
            [-81.202420166999957, 35.187688648000062],
            [-81.202423670999963, 35.187712211000076],
            [-81.202446742999939, 35.187863287000027],
            [-81.20246586799999, 35.188003396000056],
            [-81.202476326999943, 35.188073789000043],
            [-81.202503891999982, 35.188245340000037],
            [-81.202516020999951, 35.188315535000072],
            [-81.202519335999966, 35.188333452000052],
            [-81.202522494999982, 35.188367088000064],
            [-81.20252637599998, 35.188405725000052],
            [-81.202527524999937, 35.188416278000034],
            [-81.202536494999947, 35.188497372000029],
            [-81.202542330999961, 35.188546431000077],
            [-81.20255264299999, 35.188627417000077],
            [-81.202560013999971, 35.18868139500006],
            [-81.202574159999983, 35.188778416000048],
            [-81.202583713999957, 35.188839538000025],
            [-81.202594537999971, 35.18890441800005],
            [-81.202656424999986, 35.189348708000068],
            [-81.202658543999974, 35.189363640000067],
            [-81.202729217999945, 35.189853034000066],
            [-81.20276408999996, 35.19011011300006],
            [-81.202769741999987, 35.190149795000025],
            [-81.202794121999943, 35.190313150000065],
            [-81.202798735999977, 35.190343029000076],
            [-81.20282476899996, 35.190506214000038],
            [-81.202828997999973, 35.190532014000041],
            [-81.202871407999965, 35.190783807000059],
            [-81.202879793999955, 35.19083127500005],
            [-81.202902128999938, 35.190952086000038],
            [-81.202903778999939, 35.190960938000046],
            [-81.20291193199995, 35.191002695000066],
            [-81.202926579999939, 35.191075018000049],
            [-81.202938643999971, 35.191131797000025],
            [-81.202970735999941, 35.191276044000062],
            [-81.202989601999946, 35.191355781000027],
            [-81.203029621999974, 35.19151535900005],
            [-81.203043952999963, 35.191570341000045],
            [-81.203047221999952, 35.191582329000028],
            [-81.20309545799995, 35.19175781000007],
            [-81.20311068999996, 35.191811333000032],
            [-81.203129453999964, 35.19187508400006],
            [-81.203145015999951, 35.191926315000046],
            [-81.203179828999964, 35.192037476000053],
            [-81.203201738999951, 35.19210474700003],
            [-81.203241817999981, 35.192223239000043],
            [-81.203257730999951, 35.192269113000066],
            [-81.203260345999979, 35.192276442000036],
            [-81.203282893999983, 35.192339361000052],
            [-81.203290476999939, 35.192360291000057],
            [-81.203410617999964, 35.192688244000067],
            [-81.203472033999958, 35.192843184000026],
            [-81.203554719999943, 35.193036885000026],
            [-81.203573153999969, 35.193079221000062],
            [-81.203574120999974, 35.193081398000061],
            [-81.20365987699995, 35.19327419800004],
            [-81.203676329999951, 35.193310585000063],
            [-81.203732818999981, 35.19343348700005],
            [-81.203748061999988, 35.193466171000068],
            [-81.203818480999985, 35.193615014000045],
            [-81.203891615999964, 35.193772874000047],
            [-81.20409151299998, 35.194210080000062],
            [-81.204119489999982, 35.19426967000004],
            [-81.204155282999977, 35.194343951000064],
            [-81.204184106999946, 35.194405654000036],
            [-81.204221210999947, 35.194488063000051],
            [-81.204268896999963, 35.19459805200006],
            [-81.204286304999982, 35.194639491000032],
            [-81.204304841999942, 35.194685308000032],
            [-81.204322353999942, 35.19473038600006],
            [-81.20433920499994, 35.19477562700007],
            [-81.204356754999935, 35.194824835000077],
            [-81.204385226999989, 35.19490765300003],
            [-81.20440113799998, 35.194955910000033],
            [-81.204415709999978, 35.195002195000029],
            [-81.204431183999986, 35.195053880000046],
            [-81.204447319999986, 35.195110957000054],
            [-81.204462508999939, 35.195168178000074],
            [-81.204470388999937, 35.195199831000025],
            [-81.204479365999987, 35.195240761000036],
            [-81.204513117999966, 35.195406681000065],
            [-81.204525329999967, 35.195471780000048],
            [-81.204544078999959, 35.195578884000042],
            [-81.204562002999978, 35.195690827000078],
            [-81.204580247999957, 35.195816438000065],
            [-81.204583516999946, 35.195838378000076],
            [-81.204656668999974, 35.196317168000064],
            [-81.204744698999946, 35.196919530000059],
            [-81.204746131999968, 35.196929232000059],
            [-81.204790108999987, 35.197223444000031],
            [-81.204869376999966, 35.197772347000068],
            [-81.204923698999949, 35.198160327000039],
            [-81.20493004399998, 35.198203375000048],
            [-81.204961502999936, 35.198406606000049],
            [-81.204967400999976, 35.198443219000069],
            [-81.205000020999989, 35.198638059000075],
            [-81.20500530299995, 35.19866864200003],
            [-81.205029838999963, 35.198806486000024],
            [-81.205034601999955, 35.198832579000054],
            [-81.205061874999956, 35.198978373000045],
            [-81.205062380999948, 35.198981076000052],
            [-81.205066895999948, 35.199004580000064],
            [-81.205176181999946, 35.199561602000074],
            [-81.205181051999944, 35.199585900000045],
            [-81.205230537999967, 35.199827655000036],
            [-81.205234141999938, 35.199845000000039],
            [-81.205283947999988, 35.200081299000033],
            [-81.205297531999975, 35.200142608000078],
            [-81.205325288999973, 35.200262040000041],
            [-81.205362678999961, 35.200459356000067],
            [-81.205369063999967, 35.200492066000038],
            [-81.205419320999965, 35.200742217000027],
            [-81.205425638999941, 35.200772847000053],
            [-81.205473387999973, 35.200998463000076],
            [-81.205480127999977, 35.201029507000044],
            [-81.20553423299998, 35.201272594000045],
            [-81.205539091999981, 35.201295465000044],
            [-81.205577137999967, 35.201511505000042],
            [-81.20558338099994, 35.201545797000051],
            [-81.20558886699996, 35.201574222000033],
            [-81.205598398999939, 35.201622379000071],
            [-81.205600592999986, 35.201634538000064],
            [-81.205603880999945, 35.201654931000064],
            [-81.205610954999941, 35.201708214000064],
            [-81.205613374999984, 35.201731819000031],
            [-81.205616180999982, 35.201768555000058],
            [-81.205618029999982, 35.201820887000054],
            [-81.205618848999961, 35.201874730000043],
            [-81.205611924999971, 35.202008271000068],
            [-81.205590995999955, 35.202100832000042],
            [-81.205590829999949, 35.202101484000025],
            [-81.20558755199994, 35.202113292000035],
            [-81.205584057999943, 35.202124953000066],
            [-81.205580836999957, 35.20213496100007],
            [-81.205574841999976, 35.202152314000045],
            [-81.205562025999939, 35.202187090000052],
            [-81.205557751999947, 35.202198021000072],
            [-81.205554393999989, 35.202206123000053],
            [-81.205548204999957, 35.20222020500006],
            [-81.205544456999974, 35.202228265000031],
            [-81.205542348999984, 35.202232571000025],
            [-81.205523640999957, 35.202263314000049],
            [-81.205479299999979, 35.202334388000054],
            [-81.205464118999942, 35.202358190000041],
            [-81.20545440799998, 35.202369667000028],
            [-81.205430453999952, 35.202396836000048],
            [-81.205400826999949, 35.202429063000068],
            [-81.205375326999956, 35.202455685000075],
            [-81.205348589999971, 35.202482510000038],
            [-81.205330271999969, 35.202500183000041],
            [-81.205215144999954, 35.202605641000048],
            [-81.205073834999951, 35.20273276000006],
            [-81.204937681999979, 35.202853083000036],
            [-81.204791702999955, 35.202979825000057],
            [-81.204688833999967, 35.203067766000061],
            [-81.204586497999969, 35.20315411200005],
            [-81.204255788999944, 35.203428417000055],
            [-81.204237692999982, 35.20344351500006],
            [-81.203957849999938, 35.203678355000079],
            [-81.203936255999963, 35.203696603000026],
            [-81.203616749999981, 35.203968514000053],
            [-81.203604177999978, 35.203979257000071],
            [-81.203572526999949, 35.204006699000047],
            [-81.203462094999963, 35.204103455000052],
            [-81.203424889999951, 35.20413645900004],
            [-81.203343691999976, 35.204209392000053],
            [-81.203315771999939, 35.204234708000058],
            [-81.203228716999945, 35.20431439500004],
            [-81.203187923999963, 35.204352264000079],
            [-81.203117047999967, 35.204418989000033],
            [-81.203088659999935, 35.204443752000032],
            [-81.203075109999986, 35.204455768000059],
            [-81.202932038999961, 35.204589051000028],
            [-81.202877920999981, 35.204643076000025],
            [-81.202846631999989, 35.204674934000025],
            [-81.202722308999967, 35.204807671000026],
            [-81.20266505799998, 35.204873328000076],
            [-81.20265362799995, 35.204886749000025],
            [-81.202639123999973, 35.20490458900008],
            [-81.20253650799998, 35.205001106000054],
            [-81.20249539699995, 35.205040337000071],
            [-81.20232058299996, 35.205209577000062],
            [-81.20230504999995, 35.205224699000041],
            [-81.202280189999954, 35.205249253000034],
            [-81.202108330999977, 35.205420522000054],
            [-81.20207520799994, 35.205453934000047],
            [-81.201962293999941, 35.205569223000055],
            [-81.20193980199997, 35.205592381000031],
            [-81.201777433999951, 35.205760964000035],
            [-81.201292924999962, 35.206260138000061],
            [-81.199904995999987, 35.207685466000044],
            [-81.199879977999956, 35.207711399000061],
            [-81.199750233999964, 35.207847155000024],
            [-81.199629073999972, 35.207972131000076],
            [-81.19942869099998, 35.208175054000037],
            [-81.199140224999951, 35.208461405000037],
            [-81.199108566999939, 35.208493195000074],
            [-81.198889596999948, 35.208715638000058],
            [-81.198864403999949, 35.208741471000053],
            [-81.198691211999972, 35.208920731000035],
            [-81.198667089999958, 35.20894592500008],
            [-81.198437773999956, 35.209187627000063],
            [-81.198272343999975, 35.209359517000053],
            [-81.198070749999943, 35.209565321000071],
            [-81.197854782999968, 35.209781608000071],
            [-81.197813973999985, 35.209823097000026],
            [-81.197771069999988, 35.20986737700008],
            [-81.197723524999958, 35.209917335000057],
            [-81.197645607999959, 35.21000070100007],
            [-81.197587328999987, 35.21006449600003],
            [-81.197583101999953, 35.210069237000027],
            [-81.197501797999962, 35.210160614000074],
            [-81.197436919999973, 35.210235504000025],
            [-81.197352857999988, 35.210335187000055],
            [-81.197276398999975, 35.210428936000028],
            [-81.197148238999944, 35.210591491000059],
            [-81.197130601999959, 35.210614045000057],
            [-81.197051797999961, 35.210719568000059],
            [-81.197011631999942, 35.210775471000034],
            [-81.19697599899996, 35.210825991000036],
            [-81.19693693499994, 35.210882416000061],
            [-81.196904133999965, 35.210930648000044],
            [-81.196902255999987, 35.210933462000071],
            [-81.196864303999973, 35.210990397000046],
            [-81.196828583999945, 35.211045092000063],
            [-81.196787230999973, 35.211109733000058],
            [-81.19675067899999, 35.211168147000024],
            [-81.196710757999938, 35.211233393000043],
            [-81.19667739199997, 35.211289109000063],
            [-81.196643121999955, 35.211347594000074],
            [-81.196619991999967, 35.211387695000042],
            [-81.196614372999989, 35.211397631000068],
            [-81.196580958999959, 35.211456955000074],
            [-81.196546099999978, 35.211520436000058],
            [-81.196514006999962, 35.211580413000036],
            [-81.196468768999978, 35.211668059000033],
            [-81.196419750999951, 35.211766610000041],
            [-81.196391972999947, 35.211823845000026],
            [-81.196379220999972, 35.21185110600004],
            [-81.196354452999969, 35.211904698000069],
            [-81.196327725999936, 35.211964087000069],
            [-81.196304100999953, 35.212018028000045],
            [-81.19627503199996, 35.21208654600008],
            [-81.196246354999971, 35.212156380000067],
            [-81.196225334999951, 35.212208884000063],
            [-81.196124699999984, 35.212466870000071],
            [-81.196118412999965, 35.212483121000048],
            [-81.19604060599994, 35.212711710000065],
            [-81.196033461999946, 35.212736064000069],
            [-81.196001500999955, 35.212846708000029],
            [-81.195979966999971, 35.212925118000044],
            [-81.195963413999948, 35.212988662000043],
            [-81.195943925999984, 35.213067772000045],
            [-81.195917982999958, 35.213179472000036],
            [-81.195906724999986, 35.213229873000046],
            [-81.195896473999937, 35.213279704000058],
            [-81.195874473999936, 35.213391370000068],
            [-81.195859965999944, 35.213470551000057],
            [-81.195849117999956, 35.213534547000052],
            [-81.195838292999952, 35.213603208000052],
            [-81.19582965699999, 35.213662421000038],
            [-81.195819078999989, 35.213726220000069],
            [-81.195804850999934, 35.213821449000079],
            [-81.195799181999973, 35.213866675000077],
            [-81.195787826999947, 35.213963206000074],
            [-81.195776618999957, 35.214077262000046],
            [-81.19577210999995, 35.21413437800004],
            [-81.195767012999966, 35.214211749000071],
            [-81.195766633999938, 35.214219101000026],
            [-81.195763753999984, 35.214276288000065],
            [-81.19576069499999, 35.214361076000046],
            [-81.195759444999965, 35.21441830100008],
            [-81.19575880299999, 35.214503281000077],
            [-81.195759186999965, 35.214560515000073],
            [-81.195760201999974, 35.214620721000074],
            [-81.195761417999961, 35.214657453000029],
            [-81.195763141999976, 35.21470129000005],
            [-81.19576430099994, 35.214761255000042],
            [-81.195764825999959, 35.214783959000044],
            [-81.195766852999952, 35.214839748000031],
            [-81.195770578999941, 35.214921236000066],
            [-81.195775197999978, 35.214999606000049],
            [-81.195781657999987, 35.215089148000061],
            [-81.195786894999969, 35.215152357000079],
            [-81.195791457999974, 35.215201140000033],
            [-81.19579542799994, 35.215240754000035],
            [-81.195799715999954, 35.215278555000054],
            [-81.195813997999949, 35.215397729000074],
            [-81.19582571899997, 35.215484742000058],
            [-81.195836239999949, 35.215555058000064],
            [-81.195845940999959, 35.215615668000055],
            [-81.195856754999966, 35.215679088000059],
            [-81.195865750999985, 35.215729288000034],
            [-81.195868948999987, 35.215746047000039],
            [-81.195876379999959, 35.215784393000035],
            [-81.195889094999984, 35.215862165000033],
            [-81.195908542999973, 35.215998643000034],
            [-81.195924554999976, 35.216133056000047],
            [-81.195930078999936, 35.216187369000068],
            [-81.195944682999937, 35.216360034000047],
            [-81.195958460999975, 35.21654710200005],
            [-81.19596891599997, 35.216712825000059],
            [-81.195978803999935, 35.21689927500006],
            [-81.195980761999976, 35.216932659000065],
            [-81.195995470999947, 35.217161468000029],
            [-81.19600507399997, 35.217276231000028],
            [-81.196037178999973, 35.217587557000058],
            [-81.196038372999965, 35.217598925000061],
            [-81.196048897999958, 35.217685390000042],
            [-81.19606870299998, 35.217830512000035],
            [-81.19608614699996, 35.217975840000065],
            [-81.196102133999943, 35.218090344000075],
            [-81.196138979999944, 35.218321683000056],
            [-81.196173733999956, 35.218553244000077],
            [-81.196180536999975, 35.218596437000031],
            [-81.196214611999949, 35.218802993000054],
            [-81.196245144999978, 35.218997910000041],
            [-81.196272975999989, 35.21918529900006],
            [-81.196300990999987, 35.219384671000057],
            [-81.196307320999949, 35.219427478000057],
            [-81.196332040999948, 35.219586745000072],
            [-81.196352299999944, 35.219728580000037],
            [-81.196378881999976, 35.219931884000061],
            [-81.196381221999957, 35.219956990000071],
            [-81.196384233999936, 35.220017724000058],
            [-81.196384386999966, 35.220022304000054],
            [-81.196384462999958, 35.220030156000064],
            [-81.196384400999989, 35.220031673000051],
            [-81.196384295999962, 35.22003331600007],
            [-81.196383972999968, 35.220036975000028],
            [-81.196383399999945, 35.22004210800003],
            [-81.196375275999969, 35.220104975000027],
            [-81.196369412999957, 35.220145130000049],
            [-81.196365076999939, 35.220171780000044],
            [-81.196361860999957, 35.220189881000067],
            [-81.196348274999934, 35.220257665000076],
            [-81.196234017999984, 35.220751373000041],
            [-81.196223318999955, 35.220799365000062],
            [-81.196209875999955, 35.22086562800007],
            [-81.196189427999968, 35.220972524000047],
            [-81.196172334999972, 35.221057212000062],
            [-81.196131707999939, 35.221244182000078],
            [-81.196097719999955, 35.221388392000051],
            [-81.195998930999963, 35.221788569000068],
            [-81.195978798999988, 35.221863358000064],
            [-81.19595101799996, 35.221962894000058],
            [-81.195922390999954, 35.22206183600008],
            [-81.195893717999979, 35.222157541000058],
            [-81.195848370999954, 35.222302803000048],
            [-81.195798783999976, 35.222454608000078],
            [-81.195792655999981, 35.222473570000034],
            [-81.19568739999994, 35.222802911000031],
            [-81.195591687999979, 35.223096954000027],
            [-81.19558602799998, 35.223114517000056],
            [-81.195494536999945, 35.22340134500007],
            [-81.195244284999944, 35.224158432000024],
            [-81.195238950999965, 35.224174717000039],
            [-81.195153695999977, 35.22443746600004],
            [-81.195146657999942, 35.22445943300005],
            [-81.194964357999936, 35.225035749000028],
            [-81.194870959999946, 35.225311643000055],
            [-81.194784268999967, 35.225561497000058],
            [-81.194705165999949, 35.225768050000056],
            [-81.194658925999988, 35.225884797000049],
            [-81.194594138999946, 35.226044358000024],
            [-81.194590078999965, 35.226053828000033],
            [-81.19458528399997, 35.226064533000056],
            [-81.194564688999947, 35.226107768000077],
            [-81.194549516999984, 35.226137314000027],
            [-81.194538080999962, 35.226157288000024],
            [-81.194518285999948, 35.226190055000075],
            [-81.194499944999961, 35.226218814000049],
            [-81.194350575999977, 35.226415677000034],
            [-81.194244333999961, 35.226553164000052],
            [-81.194125805999988, 35.226704478000045],
            [-81.192708219999986, 35.228500900000029],
            [-81.192289118999952, 35.229029533000073],
            [-81.192282109999951, 35.229038403000061],
            [-81.191205940999964, 35.23040448200004],
            [-81.191144920999989, 35.230480489000058],
            [-81.19110765299996, 35.230527703000064],
            [-81.191097785999943, 35.230540477000034],
            [-81.191039073999946, 35.23061683800006],
            [-81.190997179999954, 35.230672430000027],
            [-81.190940154999964, 35.23074964500006],
            [-81.190899387999934, 35.230805988000043],
            [-81.190844072999937, 35.230884036000077],
            [-81.19079335899994, 35.230957548000049],
            [-81.190772142999947, 35.230989156000078],
            [-81.190706994999971, 35.231081146000065],
            [-81.190689636999934, 35.23110588000003],
            [-81.19060022299999, 35.231234454000059],
            [-81.190583126999968, 35.231259263000027],
            [-81.19049494799998, 35.231388407000054],
            [-81.190478118999977, 35.231413284000041],
            [-81.190391175999935, 35.231542994000051],
            [-81.190388431999963, 35.231547093000074],
            [-81.190373631999989, 35.231569425000032],
            [-81.190278488999979, 35.231714200000056],
            [-81.190259850999951, 35.231742868000026],
            [-81.190161614999965, 35.231895608000059],
            [-81.190116194999973, 35.231968194000046],
            [-81.190022067999962, 35.232122878000041],
            [-81.189982847999943, 35.232189000000062],
            [-81.18994424999994, 35.232255785000063],
            [-81.189931630999979, 35.232277808000049],
            [-81.189912856999968, 35.232311307000032],
            [-81.18987150199996, 35.232386096000027],
            [-81.189841371999989, 35.232441827000059],
            [-81.189805535999938, 35.232509641000036],
            [-81.189776491999964, 35.23256589600004],
            [-81.189738226999964, 35.232641784000066],
            [-81.189710369999943, 35.232698378000066],
            [-81.189677335999988, 35.232767144000036],
            [-81.18966960299997, 35.232783357000073],
            [-81.189650563999976, 35.232824285000049],
            [-81.189615437999976, 35.232901187000039],
            [-81.189589939999962, 35.232958473000053],
            [-81.189559748999955, 35.233028113000046],
            [-81.189535379999938, 35.233085858000038],
            [-81.189503427999966, 35.233163689000037],
            [-81.189477377999935, 35.233229192000067],
            [-81.189450276999935, 35.233299600000066],
            [-81.18944933399996, 35.233302054000035],
            [-81.189425806999964, 35.233365337000066],
            [-81.189400356999954, 35.233436098000027],
            [-81.189378971999986, 35.233497545000034],
            [-81.189355044999957, 35.233568664000074],
            [-81.189335030999985, 35.233630258000062],
            [-81.18931263099995, 35.233701709000059],
            [-81.189299712999969, 35.233743968000056],
            [-81.189293888999941, 35.233763755000041],
            [-81.189273027999945, 35.233835519000024],
            [-81.189255682999942, 35.233897592000062],
            [-81.189236360999985, 35.233969647000038],
            [-81.189220276999947, 35.234032252000077],
            [-81.189202505999958, 35.234104574000071],
            [-81.18918780599995, 35.234167242000069],
            [-81.189171589999944, 35.234239808000041],
            [-81.189166083999964, 35.234264945000064],
            [-81.189158247999956, 35.234302619000061],
            [-81.189143587999979, 35.234375410000041],
            [-81.189132050999945, 35.234435860000076],
            [-81.18912034899995, 35.234500755000056],
            [-81.189108897999972, 35.234568682000031],
            [-81.18909869099997, 35.234633756000051],
            [-81.189088697999978, 35.234702670000047],
            [-81.189088611999978, 35.234703317000026],
            [-81.189079938999953, 35.234768541000051],
            [-81.189071032999948, 35.234842551000042],
            [-81.189063111999985, 35.234916075000058],
            [-81.189056058999938, 35.234990197000059],
            [-81.189050651999935, 35.235055658000078],
            [-81.189045703999966, 35.235125570000037],
            [-81.18904183799998, 35.235191102000044],
            [-81.189040832999979, 35.235209062000024],
            [-81.189038534999952, 35.235261103000028],
            [-81.189036212999952, 35.235326686000064],
            [-81.189034559999982, 35.235396594000065],
            [-81.189033778999942, 35.235462200000029],
            [-81.18903377099997, 35.235532284000044],
            [-81.189034534999962, 35.235597892000044],
            [-81.189035407999938, 35.235643185000072],
            [-81.189036173999966, 35.235667895000063],
            [-81.189038481999944, 35.235733476000064],
            [-81.189041516999964, 35.235799100000065],
            [-81.189044801999955, 35.23585644700006],
            [-81.189050104999978, 35.235932128000059],
            [-81.189065658999937, 35.236119851000069],
            [-81.189072392999947, 35.236190821000037],
            [-81.189074003999963, 35.236205540000071],
            [-81.189082194999969, 35.236278718000051],
            [-81.189090734999979, 35.236347760000058],
            [-81.189100630999974, 35.23642079800004],
            [-81.189112626999986, 35.236501159000056],
            [-81.189128467999979, 35.236598275000063],
            [-81.189141014999961, 35.236669778000078],
            [-81.189144109999972, 35.236686002000056],
            [-81.189161278999961, 35.236774707000052],
            [-81.189178700999946, 35.236858387000041],
            [-81.189198395999938, 35.236946739000075],
            [-81.189219122999987, 35.23703370700008],
            [-81.189243470999941, 35.237129629000037],
            [-81.18925563199997, 35.237176017000024],
            [-81.189319522999938, 35.237383150000028],
            [-81.189357811999969, 35.237493508000057],
            [-81.189365806999945, 35.237516269000025],
            [-81.18937336099998, 35.237537275000079],
            [-81.18941048399995, 35.23763937800004],
            [-81.189426645999959, 35.237682836000033],
            [-81.189468298999941, 35.237792370000079],
            [-81.189485783999942, 35.23783732000004],
            [-81.189529170999947, 35.237946402000034],
            [-81.18954537999997, 35.237986367000076],
            [-81.189580680999939, 35.238071743000035],
            [-81.189602946999969, 35.238124269000025],
            [-81.189676731999953, 35.238294113000052],
            [-81.18968711499997, 35.238317752000057],
            [-81.189706503999957, 35.238360584000077],
            [-81.189777241999934, 35.238513883000053],
            [-81.189802457999974, 35.238567255000078],
            [-81.189854032999961, 35.238673910000045],
            [-81.189879108999946, 35.238724644000058],
            [-81.189931323999986, 35.238828041000033],
            [-81.190000135999981, 35.238968637000028],
            [-81.190083172999948, 35.23914568500004],
            [-81.190125642999988, 35.23923975200006],
            [-81.19016970399997, 35.239340207000055],
            [-81.190216125999939, 35.239449122000053],
            [-81.190235334999954, 35.239493271000072],
            [-81.190304680999986, 35.239639542000077],
            [-81.190343389999953, 35.239716236000049],
            [-81.190366653999945, 35.239764060000027],
            [-81.190392743999951, 35.23981976500005],
            [-81.190411350999966, 35.239861074000032],
            [-81.190456680999944, 35.239967218000061],
            [-81.19050823799995, 35.240094315000078],
            [-81.190516405999972, 35.240115112000069],
            [-81.190523403999975, 35.240133851000053],
            [-81.190529525999978, 35.240151185000059],
            [-81.190535328999943, 35.240168608000033],
            [-81.190539855999987, 35.240183065000053],
            [-81.190546977999986, 35.240207527000052],
            [-81.190551938999988, 35.240226091000068],
            [-81.190568311999982, 35.240293937000047],
            [-81.190577668999936, 35.240331663000063],
            [-81.190612189999968, 35.24046714900004],
            [-81.190481759999955, 35.240611657000045],
            [-81.19022500799997, 35.24089358100008],
            [-81.190210644999979, 35.240909441000042],
            [-81.189774453999974, 35.241393871000071],
            [-81.189371165999944, 35.241831682000054],
            [-81.189358321999975, 35.241845696000041],
            [-81.189153756999985, 35.24207001700006],
            [-81.189141081999935, 35.242083985000079],
            [-81.188824508999971, 35.242434624000055],
            [-81.188811223999949, 35.242449417000046],
            [-81.188799407999966, 35.242462705000037],
            [-81.188687653999978, 35.242589002000045],
            [-81.188670397999942, 35.242608641000061],
            [-81.18855967199994, 35.242735549000031],
            [-81.188535808999973, 35.242763170000046],
            [-81.188340258999972, 35.242991774000075],
            [-81.188233067999988, 35.243123077000064],
            [-81.188088419999985, 35.243308892000073],
            [-81.187949198999945, 35.243485642000053],
            [-81.18779828299995, 35.243674933000079],
            [-81.187628921999988, 35.243884721000029],
            [-81.187591132999955, 35.243932335000068],
            [-81.18754404799995, 35.243992691000074],
            [-81.187538601999961, 35.243999690000066],
            [-81.187498183999935, 35.244052768000074],
            [-81.187467758999958, 35.244093501000066],
            [-81.187414742999977, 35.244166378000045],
            [-81.187355612999966, 35.244249865000029],
            [-81.187301722999962, 35.244328172000053],
            [-81.187286530999984, 35.244351096000059],
            [-81.187229565999985, 35.244437795000067],
            [-81.187164024999959, 35.244541513000058],
            [-81.187110491999988, 35.244629674000066],
            [-81.187066551999976, 35.244704152000054],
            [-81.187045273999956, 35.244741293000061],
            [-81.187039221999953, 35.244751902000075],
            [-81.187010632999943, 35.244803266000076],
            [-81.186973870999964, 35.244870700000035],
            [-81.186929112999962, 35.244955694000055],
            [-81.186890610999967, 35.245031450000056],
            [-81.18685039199994, 35.245113381000067],
            [-81.186774199999945, 35.245287197000039],
            [-81.186755299999959, 35.245334024000044],
            [-81.186735571999975, 35.245384122000075],
            [-81.186712335999971, 35.245446578000042],
            [-81.186681108999949, 35.245533302000069],
            [-81.186636365999959, 35.245666909000079],
            [-81.186614139999961, 35.245738614000061],
            [-81.186604254999963, 35.245771131000026],
            [-81.186579205999976, 35.245860016000051],
            [-81.18655999799995, 35.245932306000043],
            [-81.186536338999986, 35.246027446000028],
            [-81.186526981999975, 35.246067829000026],
            [-81.186516213999937, 35.246116074000042],
            [-81.186506436999935, 35.246163451000029],
            [-81.186491972999988, 35.24623648000005],
            [-81.18646844999995, 35.246371156000066],
            [-81.186464749999971, 35.246395599000039],
            [-81.186457218999976, 35.24644851000005],
            [-81.186445393999975, 35.246550595000031],
            [-81.186435741999958, 35.24664862700007],
            [-81.186430388999952, 35.246709335000048],
            [-81.186424016999979, 35.246791126000062],
            [-81.18642039599996, 35.246843585000079],
            [-81.186415961999955, 35.246917260000032],
            [-81.186414750999973, 35.246938659000079],
            [-81.186413390999974, 35.246966869000062],
            [-81.186410186999979, 35.247040586000026],
            [-81.186408365999966, 35.24709320900007],
            [-81.186406254999952, 35.247175150000032],
            [-81.186405780999962, 35.247209620000035],
            [-81.18639137599996, 35.247239125000078],
            [-81.186307057999954, 35.247407882000061],
            [-81.186221705999969, 35.247574392000047],
            [-81.186194868999962, 35.247627942000065],
            [-81.186164994999956, 35.247688929000049],
            [-81.186146450999956, 35.247727419000057],
            [-81.186142390999976, 35.247736021000037],
            [-81.186113553999974, 35.247797350000042],
            [-81.186085071999969, 35.247859618000064],
            [-81.186040430999981, 35.247960004000049],
            [-81.186007113999949, 35.248037650000072],
            [-81.185965260999978, 35.248138843000049],
            [-81.185935193999967, 35.248214240000038],
            [-81.185934076999956, 35.248217149000027],
            [-81.185895035999977, 35.248319096000046],
            [-81.185862886999985, 35.248406911000075],
            [-81.18581871899994, 35.248533338000072],
            [-81.185774114999958, 35.248671194000053],
            [-81.185764742999936, 35.248703272000057],
            [-81.185748950999937, 35.248758428000031],
            [-81.185721357999967, 35.248861519000059],
            [-81.185699627999952, 35.248948747000043],
            [-81.185677113999986, 35.249045846000058],
            [-81.185676924999939, 35.249046726000074],
            [-81.185666670999979, 35.249094556000045],
            [-81.185648349999951, 35.249186988000076],
            [-81.185633774999985, 35.249267037000038],
            [-81.185623342999975, 35.24932950300007],
            [-81.185621983999965, 35.249332588000073],
            [-81.185574010999972, 35.249447415000077],
            [-81.185561663999977, 35.249478664000037],
            [-81.185550620999948, 35.24950700900007],
            [-81.185514210999941, 35.249606777000054],
            [-81.185500544999968, 35.249646281000025],
            [-81.185459082999955, 35.249775093000039],
            [-81.185454582999967, 35.249790335000057],
            [-81.185442904999945, 35.249830264000025],
            [-81.185441437999941, 35.249835780000069],
            [-81.185114486999964, 35.250781982000035],
            [-81.185084576999941, 35.25087303600003],
            [-81.185061506999944, 35.250947084000074],
            [-81.185050130999969, 35.250981204000027],
            [-81.185028689999967, 35.251041213000065],
            [-81.185026469999968, 35.251047091000032],
            [-81.184998360999941, 35.251117144000034],
            [-81.184881145999952, 35.251364614000067],
            [-81.184552406999956, 35.252051494000057],
            [-81.184546918999956, 35.252063020000037],
            [-81.184513969999955, 35.252134799000032],
            [-81.184486980999964, 35.252195533000076],
            [-81.183974162999959, 35.25317409400003],
            [-81.18395931799995, 35.253202759000033],
            [-81.183731474999945, 35.25364802100006],
            [-81.183594835999941, 35.25391079700006],
            [-81.183447744999967, 35.254190203000064],
            [-81.183429835999959, 35.254224712000052],
            [-81.18319532999999, 35.254683129000057],
            [-81.182957944999941, 35.255140350000033],
            [-81.182944176999968, 35.255167165000046],
            [-81.18267130199996, 35.255704655000045],
            [-81.182542784999953, 35.255954187000043],
            [-81.182404884999983, 35.256219430000044],
            [-81.182389806999936, 35.25624878800005],
            [-81.182141226999988, 35.256738796000036],
            [-81.181892460999961, 35.257219815000042],
            [-81.18186729599995, 35.25726949500006],
            [-81.181724713999984, 35.257556979000071],
            [-81.181695825999952, 35.257614295000053],
            [-81.181685032999951, 35.257634604000032],
            [-81.181617019999976, 35.257769971000073],
            [-81.181579841999962, 35.257851294000034],
            [-81.181559684999968, 35.257897046000039],
            [-81.181500912999979, 35.258039632000077],
            [-81.18148415099995, 35.258084257000064],
            [-81.181469903999982, 35.258123079000029],
            [-81.181417344999943, 35.258278773000029],
            [-81.181407643999989, 35.258310216000041],
            [-81.181399072999966, 35.258338474000027],
            [-81.18137269999994, 35.258432320000054],
            [-81.18136642199994, 35.258456096000032],
            [-81.181333296999981, 35.258594517000063],
            [-81.181329002999973, 35.258614843000032],
            [-81.181319044999952, 35.258662801000071],
            [-81.181282692999957, 35.258875898000042],
            [-81.181276219999972, 35.258924257000047],
            [-81.181276013999934, 35.258925794000049],
            [-81.181255334999946, 35.25913878700004],
            [-81.181252370999971, 35.259187373000032],
            [-81.18125102099998, 35.259211029000028],
            [-81.181247910999957, 35.259448354000028],
            [-81.181249514999934, 35.25949475300007],
            [-81.181253162999951, 35.259580754000069],
            [-81.181255190999934, 35.259652445000029],
            [-81.181256204999954, 35.259730025000067],
            [-81.18125594299994, 35.259830186000045],
            [-81.181256309999981, 35.259885431000043],
            [-81.18126261599997, 35.260280881000028],
            [-81.181262909999987, 35.260296625000024],
            [-81.181271482999989, 35.260700277000069],
            [-81.18127187999994, 35.260716787000035],
            [-81.181272347999936, 35.260732682000025],
            [-81.18127951799994, 35.260955736000028],
            [-81.181282567999972, 35.261124772000073],
            [-81.181288699999982, 35.261576141000035],
            [-81.181295431999956, 35.262469816000078],
            [-81.18129550499998, 35.262953075000041],
            [-81.181292920999965, 35.263538714000049],
            [-81.181292892999977, 35.263554300000067],
            [-81.181293158999949, 35.263875631000076],
            [-81.181293186999937, 35.263886282000044],
            [-81.181295089999935, 35.264318107000065],
            [-81.181293490999963, 35.264735913000038],
            [-81.181292316999986, 35.264746242000058],
            [-81.181288247999987, 35.264784239000051],
            [-81.181285806999938, 35.264808484000071],
            [-81.181274988999974, 35.264949287000036],
            [-81.181272691999936, 35.265001133000055],
            [-81.181270961999985, 35.265049761000057],
            [-81.181270147999953, 35.265235240000038],
            [-81.181273159999989, 35.265311301000054],
            [-81.181278290999956, 35.265409214000044],
            [-81.181286475999968, 35.265523401000053],
            [-81.181293897999979, 35.265604962000054],
            [-81.181298520999974, 35.265651697000067],
            [-81.181304256999965, 35.265701424000042],
            [-81.181314330999953, 35.265782792000039],
            [-81.181327793999969, 35.265878789000055],
            [-81.181340507999948, 35.26595990900006],
            [-81.181355993999944, 35.266049812000063],
            [-81.181361204999973, 35.266076866000049],
            [-81.181383499999981, 35.266189867000037],
            [-81.181419585999947, 35.266349904000037],
            [-81.181435792999935, 35.26641373700005],
            [-81.181447898999977, 35.266459908000058],
            [-81.181462598999985, 35.266512402000046],
            [-81.181474080999976, 35.266552052000065],
            [-81.181504311999959, 35.266650320000053],
            [-81.181524835999937, 35.266713312000036],
            [-81.181551794999962, 35.266792448000047],
            [-81.181558363999955, 35.266810741000029],
            [-81.181572526999958, 35.266849803000071],
            [-81.181612400999938, 35.266954011000053],
            [-81.181640462999951, 35.267023660000064],
            [-81.181684090999966, 35.267126708000035],
            [-81.181693888999973, 35.267148561000056],
            [-81.181721571999958, 35.267209683000033],
            [-81.181777024999974, 35.267325952000078],
            [-81.181807255999956, 35.267386256000066],
            [-81.181849868999961, 35.267468370000074],
            [-81.181855632999941, 35.267479064000042],
            [-81.181875798999954, 35.267516310000076],
            [-81.181927611999981, 35.267608500000051],
            [-81.181961898999987, 35.267667329000062],
            [-81.18201682199998, 35.267758272000037],
            [-81.182039497999938, 35.267794535000064],
            [-81.182052195999972, 35.267814685000076],
            [-81.182142958999975, 35.267950465000069],
            [-81.182197686999984, 35.268028344000072],
            [-81.182248917999971, 35.268099367000048],
            [-81.182289107999964, 35.268152623000049],
            [-81.18233695899994, 35.268214822000061],
            [-81.182409352999969, 35.268305873000031],
            [-81.182442754999954, 35.26834653800006],
            [-81.182488354999975, 35.268400977000056],
            [-81.182508243999962, 35.268424072000073],
            [-81.182548704999988, 35.268470667000031],
            [-81.182621887999971, 35.268552498000076],
            [-81.182663921999961, 35.268598147000034],
            [-81.182734369999935, 35.268672621000064],
            [-81.182771626999966, 35.268710967000061],
            [-81.182781802999955, 35.268721400000061],
            [-81.182838574999948, 35.268778204000057],
            [-81.182876940999961, 35.268815809000046],
            [-81.182952075999935, 35.268887532000065],
            [-81.182998215999987, 35.268930432000047],
            [-81.183054772999981, 35.268982015000063],
            [-81.183075202999987, 35.269000169000037],
            [-81.183115900999951, 35.269036087000075],
            [-81.183189152999944, 35.26909919600007],
            [-81.183230889999948, 35.269134301000065],
            [-81.183328551999978, 35.269213858000057],
            [-81.183332434999954, 35.269907028000034],
            [-81.183332604999976, 35.269926328000054],
            [-81.183335991999968, 35.270206427000062],
            [-81.183336211999972, 35.270221449000076],
            [-81.183340716999965, 35.270484260000046],
            [-81.18334605299998, 35.271450256000037],
            [-81.183346197999981, 35.271467562000055],
            [-81.183351603999938, 35.271944192000035],
            [-81.183356723999964, 35.272620256000039],
            [-81.183357779999938, 35.273013523000031],
            [-81.183357885999953, 35.273031877000051],
            [-81.183362816999988, 35.27358875300007],
            [-81.183364663999953, 35.274194658000056],
            [-81.183364934999986, 35.274226790000057],
            [-81.183368452999957, 35.27448073000005],
            [-81.183368856999948, 35.274503543000037],
            [-81.183377399999983, 35.274900436000053],
            [-81.183382697999946, 35.275298679000059],
            [-81.183393703999968, 35.276487116000055],
            [-81.183396602999949, 35.276902298000039],
            [-81.183396744999982, 35.276917178000076],
            [-81.183405965999953, 35.277686423000034],
            [-81.183407215999978, 35.27795601300005],
            [-81.183407150999983, 35.278401341000063],
            [-81.183407220999982, 35.278422176000049],
            [-81.183410443999946, 35.278891990000034],
            [-81.183410589999937, 35.278907446000062],
            [-81.183414266999989, 35.279212404000077],
            [-81.183414533999951, 35.279230117000054],
            [-81.18342079699994, 35.279577783000036],
            [-81.183425734999958, 35.279926414000045],
            [-81.183434190999947, 35.280837799000039],
            [-81.183434562999935, 35.28086471000006],
            [-81.18343733599994, 35.281016009000041],
            [-81.183434322999972, 35.281030874000066],
            [-81.183401315999959, 35.281226498000024],
            [-81.18339808099995, 35.281250463000049],
            [-81.183397427999978, 35.28125532200005],
            [-81.183378120999976, 35.281447073000038],
            [-81.183376479999936, 35.281471147000047],
            [-81.183373758999949, 35.281516044000057],
            [-81.183370209999964, 35.281734621000055],
            [-81.183374908999951, 35.28196365000008],
            [-81.183377852999968, 35.282158774000038],
            [-81.183380177999936, 35.282440333000068],
            [-81.183380245999956, 35.282732053000075],
            [-81.183381315999952, 35.282811117000051],
            [-81.183383287999959, 35.282865835000052],
            [-81.183384029999957, 35.282882248000078],
            [-81.183384676999935, 35.282948450000049],
            [-81.183385850999969, 35.283249721000061],
            [-81.183383148999951, 35.283564573000035],
            [-81.183384109999963, 35.283669859000042],
            [-81.183384936999971, 35.283696323000072],
            [-81.183389985999952, 35.283837597000058],
            [-81.183391086999961, 35.284099998000045],
            [-81.183391316999973, 35.284126528000058],
            [-81.183393124999952, 35.284264395000037],
            [-81.183386678999966, 35.284348622000039],
            [-81.183382170999948, 35.284418224000035],
            [-81.18337906499994, 35.284655547000057],
            [-81.183394894999935, 35.284892529000047],
            [-81.18342959499995, 35.285128154000063],
            [-81.183483014999979, 35.285361414000079],
            [-81.183554926999989, 35.285591310000029],
            [-81.183645023999986, 35.285816857000043],
            [-81.183752917999982, 35.286037089000047],
            [-81.183878148999952, 35.286251064000055],
            [-81.184020179999948, 35.286457864000056],
            [-81.184178403999965, 35.286656606000065],
            [-81.184352141999966, 35.286846435000029],
            [-81.184540650999963, 35.287026542000035],
            [-81.184743123999965, 35.287196154000071],
            [-81.184958692999942, 35.287354544000038],
            [-81.18518643699997, 35.287501034000059],
            [-81.185425376999945, 35.287634998000044],
            [-81.185674493999954, 35.287755861000051],
            [-81.185932718999936, 35.287863105000042],
            [-81.186198945999934, 35.287956271000041],
            [-81.186472034999952, 35.288034961000051],
            [-81.186750815999972, 35.288098837000064],
            [-81.187034095999934, 35.288147626000068],
            [-81.187320661999934, 35.288181120000047],
            [-81.18760928599994, 35.288199173000066],
            [-81.187898731999951, 35.288201709000077],
            [-81.188187760999938, 35.288188718000072],
            [-81.188475134999976, 35.288160254000047],
            [-81.188759621999964, 35.288116439000078],
            [-81.189040003999935, 35.28805746300003],
            [-81.189315081999951, 35.287983576000045],
            [-81.189583676999973, 35.287895096000057],
            [-81.189844637999954, 35.287792401000047],
            [-81.190096847999939, 35.287675931000024],
            [-81.190339226999981, 35.287546185000053],
            [-81.190570734999937, 35.287403719000054],
            [-81.190790384999957, 35.287249142000064],
            [-81.190997231999972, 35.287083118000055],
            [-81.191190391999953, 35.286906356000031],
            [-81.191369038999937, 35.286719613000059],
            [-81.191532406999954, 35.286523690000024],
            [-81.19167979599996, 35.286319425000045],
            [-81.191810574999977, 35.286107694000066],
            [-81.191924184999948, 35.285889402000066],
            [-81.192020138999965, 35.285665486000028],
            [-81.192098026999986, 35.285436902000072],
            [-81.192157513999973, 35.285204632000045],
            [-81.192198347999977, 35.284969669000077],
            [-81.192215849999968, 35.28480262000005],
            [-81.192219580999961, 35.284753799000043],
            [-81.192225497999971, 35.284696929000063],
            [-81.192237346999946, 35.284545880000053],
            [-81.19224124599998, 35.28434930800006],
            [-81.19223743799995, 35.284061444000031],
            [-81.192236162999961, 35.28376542500007],
            [-81.192235111999935, 35.283698143000038],
            [-81.192234280999969, 35.283671679000065],
            [-81.192229897999937, 35.283549403000052],
            [-81.19223216499995, 35.283281884000075],
            [-81.192232247999982, 35.283244771000057],
            [-81.192230930999983, 35.282916220000061],
            [-81.192230809999955, 35.282898774000046],
            [-81.192229549999979, 35.282771109000066],
            [-81.192228616999955, 35.282720382000036],
            [-81.192226638999955, 35.282665665000025],
            [-81.192226536999954, 35.282663416000048],
            [-81.192226454999968, 35.28242668200005],
            [-81.192226351999977, 35.282402829000034],
            [-81.192223811999952, 35.282099189000064],
            [-81.192223572999978, 35.282078879000039],
            [-81.192223339999941, 35.28206358500006],
            [-81.192257996999956, 35.281864149000057],
            [-81.192279999999982, 35.281627499000024],
            [-81.192283014999987, 35.281552715000032],
            [-81.192287928999974, 35.281375523000065],
            [-81.192288565999945, 35.281238245000054],
            [-81.192279910999957, 35.280768832000035],
            [-81.192271413999947, 35.279863637000062],
            [-81.192271241999947, 35.279849105000039],
            [-81.192266079999968, 35.279487484000072],
            [-81.192265894999935, 35.279476038000041],
            [-81.192259650999972, 35.279131506000056],
            [-81.192256141999962, 35.278843134000056],
            [-81.19225299499999, 35.278391466000073],
            [-81.192253010999934, 35.277949448000072],
            [-81.192252977999942, 35.277935230000026],
            [-81.192251612999939, 35.277647596000065],
            [-81.192251428999953, 35.277625728000032],
            [-81.19224208199995, 35.276852989000076],
            [-81.192239165999979, 35.276441863000059],
            [-81.192239109999946, 35.276435093000032],
            [-81.192227886999945, 35.275237258000061],
            [-81.192227749999972, 35.275225229000057],
            [-81.192222163999986, 35.274808752000069],
            [-81.192221734999976, 35.274784312000065],
            [-81.192213131999949, 35.274386605000075],
            [-81.192209969999965, 35.274160137000024],
            [-81.19220808099999, 35.273561663000066],
            [-81.192207975999963, 35.27354439100003],
            [-81.192202988999952, 35.272988057000077],
            [-81.192201895999972, 35.272596695000061],
            [-81.19220181999998, 35.272582152000041],
            [-81.192196527999954, 35.271893212000066],
            [-81.192196419999959, 35.27188200300003],
            [-81.192190996999955, 35.271408424000072],
            [-81.192185507999966, 35.270433802000071],
            [-81.192185112999937, 35.270399244000032],
            [-81.192180410999981, 35.270126668000046],
            [-81.192177202999972, 35.269863730000054],
            [-81.192173079999975, 35.269141706000028],
            [-81.192172806999963, 35.269114954000031],
            [-81.192170395999938, 35.26895101100007],
            [-81.192169853999985, 35.268923088000065],
            [-81.19216215199998, 35.268603442000028],
            [-81.19216193699998, 35.268595017000052],
            [-81.192161398999986, 35.268577028000038],
            [-81.192156803999978, 35.26843771800003],
            [-81.192150855999955, 35.268323748000057],
            [-81.19214335099997, 35.268218646000037],
            [-81.19213797599997, 35.268153450000057],
            [-81.19212816299995, 35.268048476000047],
            [-81.192124171999978, 35.268008649000024],
            [-81.192121657999962, 35.267985980000049],
            [-81.192110557999968, 35.267889223000054],
            [-81.19210305699994, 35.267829195000047],
            [-81.192089950999957, 35.267732267000042],
            [-81.192081159999987, 35.267671879000034],
            [-81.192065914999944, 35.267574127000046],
            [-81.192055854999978, 35.267513670000028],
            [-81.192038606999972, 35.267416144000038],
            [-81.192032754999957, 35.26738406100003],
            [-81.192027293999956, 35.267355760000044],
            [-81.192008045999955, 35.26725848500007],
            [-81.191996642999982, 35.267203527000049],
            [-81.191980944999955, 35.267131238000047],
            [-81.191961354999989, 35.267046835000031],
            [-81.191953516999945, 35.267015115000049],
            [-81.191936537999936, 35.266949520000026],
            [-81.191935642999965, 35.266946223000048],
            [-81.19192705599994, 35.266914634000045],
            [-81.191897134999977, 35.266811663000055],
            [-81.191877719999979, 35.266748922000033],
            [-81.191836616999979, 35.266625299000054],
            [-81.191832228999942, 35.266613043000063],
            [-81.191809856999953, 35.266550971000072],
            [-81.19177153399994, 35.266450071000065],
            [-81.191759249999961, 35.266419313000029],
            [-81.191731732999983, 35.266352611000059],
            [-81.191718722999951, 35.266322055000046],
            [-81.191698853999981, 35.26627637200005],
            [-81.191678283999977, 35.266231001000051],
            [-81.191653783999982, 35.266178047000039],
            [-81.191566463999948, 35.266003192000028],
            [-81.191441243999975, 35.265789225000049],
            [-81.191299230999959, 35.265582432000031],
            [-81.191141029999983, 35.26538369900004],
            [-81.19096732099996, 35.265193879000037],
            [-81.190778848999969, 35.26501378100005],
            [-81.190613430999974, 35.264873565000073],
            [-81.190604442999984, 35.26486634400004],
            [-81.190596264999954, 35.264858504000074],
            [-81.190496588999963, 35.26476615100006],
            [-81.190435953999952, 35.264711860000034],
            [-81.190434662999962, 35.264710705000027],
            [-81.190365486999951, 35.264650222000057],
            [-81.190310230999955, 35.264603004000037],
            [-81.190242151999939, 35.264546094000025],
            [-81.190185646999964, 35.264499886000067],
            [-81.190138908999984, 35.26446244400006],
            [-81.190139407999936, 35.264328308000074],
            [-81.190139397999985, 35.264303808000079],
            [-81.19013741699996, 35.263865058000079],
            [-81.190137127999947, 35.263556847000075],
            [-81.190139644999988, 35.262972214000058],
            [-81.190139671999987, 35.262958642000058],
            [-81.19013954199994, 35.262457616000063],
            [-81.190139454999951, 35.262435655000047],
            [-81.190132472999949, 35.26152200100006],
            [-81.19013228099999, 35.261504003000027],
            [-81.190125885999976, 35.261036994000051],
            [-81.19012567499999, 35.261023712000053],
            [-81.190122104999944, 35.260827085000074],
            [-81.190121517999955, 35.260801086000072],
            [-81.19012104799998, 35.260785191000025],
            [-81.190113701999962, 35.260557393000056],
            [-81.190105597999946, 35.260177818000045],
            [-81.190101488999971, 35.259921892000079],
            [-81.190288729999963, 35.259559769000077],
            [-81.190300501999957, 35.259536781000065],
            [-81.190547420999962, 35.259049953000044],
            [-81.190680614999962, 35.258793713000045],
            [-81.190686454999934, 35.258782423000071],
            [-81.190822265999941, 35.258518679000076],
            [-81.190831022999987, 35.258501549000073],
            [-81.191101388999982, 35.257968898000058],
            [-81.191336387999968, 35.257516186000032],
            [-81.191345529999978, 35.257498444000078],
            [-81.191575695999973, 35.257048422000025],
            [-81.191717617999984, 35.256778783000073],
            [-81.191725408999957, 35.256763890000059],
            [-81.191870894999965, 35.256484048000061],
            [-81.191880812999955, 35.256464817000051],
            [-81.192106197999976, 35.256024273000037],
            [-81.19266072399995, 35.254965922000054],
            [-81.192719800999953, 35.254847446000042],
            [-81.192752743999961, 35.254775665000068],
            [-81.192803871999956, 35.254660583000032],
            [-81.193115551999938, 35.25400920900006],
            [-81.193121038999948, 35.253997682000033],
            [-81.193121205999944, 35.253997333000029],
            [-81.193270376999976, 35.253682327000035],
            [-81.193322165999973, 35.253567233000069],
            [-81.193355273999941, 35.253489639000065],
            [-81.193381973999976, 35.253425125000035],
            [-81.19343835899997, 35.253284563000079],
            [-81.193455615999937, 35.253240600000026],
            [-81.193461848999959, 35.253224236000051],
            [-81.193485575999944, 35.253161412000054],
            [-81.193505239999979, 35.25310789200006],
            [-81.193547508999984, 35.252989551000042],
            [-81.193569320999984, 35.252926372000047],
            [-81.193601040999965, 35.252831213000036],
            [-81.193620016999944, 35.252772347000075],
            [-81.193638036999971, 35.252714486000059],
            [-81.193914232999987, 35.251914932000034],
            [-81.193979486999979, 35.251806697000063],
            [-81.194093042999953, 35.251588402000039],
            [-81.194188950999944, 35.251364483000032],
            [-81.19426679999998, 35.25113589700004],
            [-81.194326255999954, 35.250903624000046],
            [-81.194367065999984, 35.250668658000052],
            [-81.194389053999942, 35.25043200600004],
            [-81.194391995999979, 35.250349110000059],
            [-81.194466262999981, 35.250204197000073],
            [-81.194481622999945, 35.250173841000048],
            [-81.194580237999958, 35.249976427000036],
            [-81.194593537999936, 35.249949495000067],
            [-81.19467162899997, 35.249789526000029],
            [-81.19468464199997, 35.249762557000054],
            [-81.194750235999948, 35.249625010000045],
            [-81.194753935999984, 35.249617225000065],
            [-81.194805367999948, 35.249502995000057],
            [-81.194821906999948, 35.249464272000068],
            [-81.194855848999964, 35.249381639000035],
            [-81.194886646999976, 35.249303568000073],
            [-81.194897177999962, 35.249276509000026],
            [-81.19491820199994, 35.249220163000075],
            [-81.194932450999943, 35.249180834000072],
            [-81.19496146299997, 35.249097090000078],
            [-81.194987651999952, 35.24901789200004],
            [-81.195015459999979, 35.248929396000051],
            [-81.195022582999968, 35.248905128000047],
            [-81.195045660999938, 35.248825284000077],
            [-81.195070385999941, 35.24873457800004],
            [-81.195082731999946, 35.24868639400006],
            [-81.195107177999944, 35.24858363900006],
            [-81.19512846799995, 35.24848673300005],
            [-81.195131626999967, 35.24847218900004],
            [-81.195151795999948, 35.24836960500005],
            [-81.195170490999942, 35.248265014000026],
            [-81.195189236999965, 35.248146576000067],
            [-81.195203682999988, 35.248041828000055],
            [-81.195205572999953, 35.248027884000066],
            [-81.195214745999976, 35.247951499000067],
            [-81.19521993699999, 35.247903030000032],
            [-81.195227108999973, 35.247826856000074],
            [-81.195233683999959, 35.24774594400003],
            [-81.195239322999953, 35.247661852000078],
            [-81.195239896999965, 35.247650574000033],
            [-81.195242891999953, 35.247589537000067],
            [-81.195356052999955, 35.247447585000032],
            [-81.195369381999967, 35.247430762000079],
            [-81.195521652999957, 35.247237423000058],
            [-81.195534445999954, 35.247221085000035],
            [-81.195633603999966, 35.247093690000042],
            [-81.195761954999966, 35.246943628000054],
            [-81.195852078999962, 35.246840323000072],
            [-81.195942692999949, 35.246737908000057],
            [-81.196240303999957, 35.246408239000061],
            [-81.19643210299995, 35.246197899000038],
            [-81.196843464999972, 35.245751281000025],
            [-81.196872317999976, 35.245719598000051],
            [-81.197315655999944, 35.245227183000054],
            [-81.197570870999982, 35.244946921000064],
            [-81.197582197999964, 35.244934426000043],
            [-81.197867268999971, 35.244618555000045],
            [-81.197880250999958, 35.244604094000067],
            [-81.19789185999997, 35.244591038000067],
            [-81.197950867999964, 35.244524350000063],
            [-81.197984898999948, 35.244485352000027],
            [-81.198042825999948, 35.244418031000066],
            [-81.198076243999935, 35.244378644000051],
            [-81.19813308099998, 35.244310700000028],
            [-81.198167466999962, 35.244268976000058],
            [-81.198228714999971, 35.244193534000033],
            [-81.198262454999963, 35.244151343000055],
            [-81.198316965999936, 35.244082130000038],
            [-81.198333050999963, 35.24406155500003],
            [-81.198348329999988, 35.244041725000045],
            [-81.198401722999961, 35.243971926000029],
            [-81.198434052999971, 35.243929003000062],
            [-81.198491477999937, 35.243851562000032],
            [-81.198523011999953, 35.243808362000038],
            [-81.198574019999967, 35.243737373000044],
            [-81.198606291999965, 35.243691702000035],
            [-81.198665982999955, 35.243605795000065],
            [-81.19870186299994, 35.243553143000042],
            [-81.198777628999949, 35.243434621000063],
            [-81.198808989999975, 35.243383280000046],
            [-81.198853217999954, 35.243308760000048],
            [-81.198883140999953, 35.243256846000065],
            [-81.198893838999936, 35.243238147000056],
            [-81.198928279999961, 35.243175979000057],
            [-81.198960694999982, 35.243115977000059],
            [-81.199008939999942, 35.243023208000068],
            [-81.199043102999951, 35.242954901000076],
            [-81.199073946999988, 35.242891538000038],
            [-81.199088107999955, 35.242861233000042],
            [-81.199116409999988, 35.242799847000072],
            [-81.199155883999936, 35.242710723000073],
            [-81.199182218999965, 35.242648751000047],
            [-81.199218864999978, 35.242558747000032],
            [-81.199243214999967, 35.242496230000029],
            [-81.199248817999944, 35.242481738000038],
            [-81.19929870499999, 35.242342059000066],
            [-81.199309128999971, 35.242310541000052],
            [-81.199334453999938, 35.242230361000054],
            [-81.199353542999972, 35.242166951000058],
            [-81.199356156999954, 35.242158222000057],
            [-81.199383867999984, 35.242059180000069],
            [-81.199400590999971, 35.241995324000072],
            [-81.199423778999972, 35.241900661000045],
            [-81.199434762999942, 35.241852509000068],
            [-81.199443297999949, 35.241813939000053],
            [-81.199458830999959, 35.24173664500006],
            [-81.199475468999935, 35.241647885000077],
            [-81.199493921999988, 35.241538361000039],
            [-81.199499982999953, 35.241497845000026],
            [-81.199506787999951, 35.241449696000075],
            [-81.199513567999986, 35.24139488700007],
            [-81.19952286399996, 35.241313602000048],
            [-81.199533410999948, 35.241203330000076],
            [-81.199537173999943, 35.241154440000059],
            [-81.199541816999954, 35.241082868000035],
            [-81.199543612999946, 35.241044051000074],
            [-81.199546854999937, 35.240962454000055],
            [-81.199549121999951, 35.240865971000062],
            [-81.199549598999965, 35.240800466000053],
            [-81.199548743999969, 35.240704255000026],
            [-81.199546280999982, 35.240605925000068],
            [-81.199546128999941, 35.24060010900007],
            [-81.199540716999934, 35.240483894000079],
            [-81.199534219999975, 35.240385687000071],
            [-81.199526750999951, 35.240294192000079],
            [-81.199521989999937, 35.240245169000048],
            [-81.199519001999988, 35.240215898000031],
            [-81.199511715999961, 35.240153940000027],
            [-81.199499178999986, 35.240056127000059],
            [-81.199486910999951, 35.239970268000036],
            [-81.19948050499994, 35.239929626000048],
            [-81.19946758399999, 35.239853933000063],
            [-81.199452685999972, 35.239772887000072],
            [-81.19945043499996, 35.239760774000047],
            [-81.199434634999989, 35.239682449000043],
            [-81.199420723999935, 35.239617882000061],
            [-81.19940748099998, 35.239559227000029],
            [-81.199369436999973, 35.239398142000027],
            [-81.199365133999947, 35.239380169000071],
            [-81.199312414999952, 35.239162831000044],
            [-81.199303829999963, 35.239128319000031],
            [-81.199236962999976, 35.238865987000054],
            [-81.199216392999972, 35.238780786000063],
            [-81.199197997999988, 35.238708461000044],
            [-81.199174553999967, 35.238620762000039],
            [-81.199172080999972, 35.238611572000025],
            [-81.199156132999974, 35.238554803000056],
            [-81.199130697999976, 35.238467478000075],
            [-81.199112629999945, 35.238407697000071],
            [-81.199090342999966, 35.238336548000063],
            [-81.199073012999975, 35.238282926000068],
            [-81.199049402999947, 35.238212067000063],
            [-81.19903112999998, 35.238158824000038],
            [-81.199006205999979, 35.23808826700008],
            [-81.199003910999977, 35.238081794000038],
            [-81.198986873999957, 35.238035068000045],
            [-81.198960636999971, 35.237964834000024],
            [-81.198941554999976, 35.237915035000071],
            [-81.198917139999935, 35.237852886000042],
            [-81.198903974999951, 35.237819918000071],
            [-81.198834744999942, 35.237649296000029],
            [-81.198812260999944, 35.237595303000035],
            [-81.198743006999962, 35.237433177000071],
            [-81.19872468899996, 35.237391127000024],
            [-81.198717579999936, 35.23737523300008],
            [-81.19867609399995, 35.237283153000078],
            [-81.19865602699997, 35.237239479000039],
            [-81.198609522999959, 35.237140208000028],
            [-81.198588772999983, 35.237096749000045],
            [-81.19854441199999, 35.237005575000069],
            [-81.198522978999961, 35.236962334000054],
            [-81.198520924999968, 35.236958265000055],
            [-81.19850891699997, 35.236930099000062],
            [-81.198495545999947, 35.236899183000048],
            [-81.198437744999978, 35.236767437000026],
            [-81.19843190399996, 35.236754205000068],
            [-81.19842364699997, 35.236735767000027],
            [-81.198364355999956, 35.236604468000053],
            [-81.198344676999966, 35.23656171600004],
            [-81.198240102999989, 35.236338800000055],
            [-81.198216636999973, 35.23628984000004],
            [-81.198126840999976, 35.236106405000044],
            [-81.198108548999983, 35.23606962100007],
            [-81.198059828999988, 35.235973165000075],
            [-81.198033418999955, 35.235918561000062],
            [-81.197990304999962, 35.23582514900005],
            [-81.197942379999972, 35.235714856000072],
            [-81.197926209999935, 35.23567575800007],
            [-81.197899700999983, 35.235609128000078],
            [-81.197880861999977, 35.235559600000045],
            [-81.197876606999955, 35.235508319000076],
            [-81.197877008999967, 35.235506519000069],
            [-81.197879386999944, 35.235496838000074],
            [-81.197881989999985, 35.235487128000045],
            [-81.197884807999969, 35.235477428000024],
            [-81.197887824999952, 35.235467802000073],
            [-81.197891051999989, 35.235458209000058],
            [-81.19789362299997, 35.23545105900007],
            [-81.197895461999963, 35.23544627800004],
            [-81.197902121999959, 35.235430053000073],
            [-81.197907374999943, 35.235417933000065],
            [-81.19791636299999, 35.23539824900007],
            [-81.197922076999987, 35.235386353000024],
            [-81.197931887999971, 35.235366892000059],
            [-81.19793813199999, 35.235355073000051],
            [-81.197948723999957, 35.235335915000064],
            [-81.197952136999959, 35.235330008000062],
            [-81.198004027999957, 35.235244721000072],
            [-81.198069806999968, 35.235142428000074],
            [-81.198146839999936, 35.235025194000059],
            [-81.198216582999976, 35.234921131000078],
            [-81.198287791999974, 35.234816825000053],
            [-81.198359972999981, 35.234713019000026],
            [-81.198441869999954, 35.234597361000056],
            [-81.198491843999989, 35.234524884000052],
            [-81.198512704999985, 35.234493801000042],
            [-81.198522067999988, 35.234480590000032],
            [-81.198537754999961, 35.234459345000062],
            [-81.198552005999943, 35.234440808000045],
            [-81.198599610999963, 35.234381504000055],
            [-81.198620499999947, 35.234355234000077],
            [-81.199703475999968, 35.232980357000031],
            [-81.200121581999952, 35.232452918000035],
            [-81.200126663999981, 35.232446493000054],
            [-81.201550703999942, 35.230641684000034],
            [-81.201558709999972, 35.230631501000062],
            [-81.201688593999961, 35.230465670000058],
            [-81.201698889999989, 35.230452462000073],
            [-81.201703266999971, 35.230446808000067],
            [-81.201826542999981, 35.230287259000079],
            [-81.201845895999952, 35.230261982000059],
            [-81.202057494999963, 35.22998306900007],
            [-81.202154778999954, 35.229848445000073],
            [-81.202213519999987, 35.229763008000077],
            [-81.20223976699998, 35.229724282000063],
            [-81.202246500999934, 35.22971416300004],
            [-81.202279827999973, 35.22966389700008],
            [-81.202313529999969, 35.229612071000076],
            [-81.202373570999953, 35.229517911000073],
            [-81.202417494999963, 35.229447152000034],
            [-81.20247943399994, 35.229344611000045],
            [-81.202519862999964, 35.229275870000038],
            [-81.20257068799998, 35.229187080000031],
            [-81.202576559999954, 35.229176779000056],
            [-81.202599172999953, 35.22913630100004],
            [-81.202627844999938, 35.229084137000029],
            [-81.202671975999976, 35.229001094000068],
            [-81.202733932999934, 35.228880418000074],
            [-81.202777447999949, 35.228792463000048],
            [-81.202835533999973, 35.228670497000053],
            [-81.202838774999975, 35.228663668000024],
            [-81.202867548999961, 35.228601191000052],
            [-81.202898507999976, 35.228532050000069],
            [-81.202919283999961, 35.228484636000076],
            [-81.202945758999988, 35.228422870000031],
            [-81.202969634999988, 35.228365649000068],
            [-81.203051208999966, 35.228164694000043],
            [-81.203061402999936, 35.228139269000053],
            [-81.203119329999936, 35.227992975000063],
            [-81.203131566999957, 35.22796158500006],
            [-81.203132447999963, 35.227959289000069],
            [-81.20323594599995, 35.227688962000059],
            [-81.203270302999954, 35.22759478200004],
            [-81.203377250999949, 35.227286451000055],
            [-81.203384924999966, 35.227264052000066],
            [-81.20349213999998, 35.226947245000076],
            [-81.203511623999987, 35.226887718000057],
            [-81.203699823999955, 35.226292551000029],
            [-81.203778850999981, 35.226048920000039],
            [-81.204031465999947, 35.225284443000078],
            [-81.204041547999964, 35.22525338500003],
            [-81.204135166999947, 35.22495979200005],
            [-81.204230575999986, 35.22466658500008],
            [-81.204235694999966, 35.224650712000027],
            [-81.204340423999952, 35.224322910000069],
            [-81.20439326099995, 35.224161105000064],
            [-81.204405712999971, 35.224122106000038],
            [-81.204462459999945, 35.223940266000056],
            [-81.204472897999949, 35.223906130000046],
            [-81.204510876999962, 35.223779321000052],
            [-81.204512340999941, 35.223774417000072],
            [-81.204519134999941, 35.223751269000047],
            [-81.204555880999976, 35.223624219000044],
            [-81.204563880999956, 35.22359606100008],
            [-81.204599392999967, 35.223468773000036],
            [-81.204606883999986, 35.22344143600003],
            [-81.204639051999948, 35.223321896000073],
            [-81.20465510799994, 35.223259643000063],
            [-81.204765420999934, 35.222812585000042],
            [-81.204772994999985, 35.222781180000027],
            [-81.20481678699997, 35.222595286000058],
            [-81.204828556999985, 35.222543285000029],
            [-81.204879601999949, 35.22230826100008],
            [-81.204881322999938, 35.222300286000063],
            [-81.204888834999963, 35.222264181000071],
            [-81.204913408999971, 35.222142358000042],
            [-81.204919332999964, 35.222112198000048],
            [-81.204931702999943, 35.222047498000052],
            [-81.205042896999942, 35.221566807000045],
            [-81.205053590999967, 35.221518814000035],
            [-81.20506190399999, 35.221478725000054],
            [-81.205090853999934, 35.221334202000037],
            [-81.205103189999988, 35.22126890800007],
            [-81.205116057999987, 35.221196442000064],
            [-81.205123436999941, 35.221153059000073],
            [-81.205135237999968, 35.221080468000025],
            [-81.20514271899998, 35.221031968000034],
            [-81.205155683999976, 35.220943097000031],
            [-81.205160940999974, 35.220905394000056],
            [-81.205162410999947, 35.220894170000065],
            [-81.205177060999972, 35.22078072100004],
            [-81.205183340999952, 35.220728485000052],
            [-81.205190586999947, 35.220663398000056],
            [-81.205197342999952, 35.22059571300008],
            [-81.205203822999977, 35.22052237500003],
            [-81.205209285999956, 35.220450712000059],
            [-81.205213995999941, 35.220377283000062],
            [-81.205215972999952, 35.220343436000064],
            [-81.205217734999962, 35.220305388000043],
            [-81.205220667999981, 35.220231897000076],
            [-81.205222664999951, 35.220160167000074],
            [-81.205223825999951, 35.220086644000048],
            [-81.205224055999963, 35.220010741000067],
            [-81.205223263999983, 35.219929211000078],
            [-81.205222313999968, 35.219877566000036],
            [-81.205221722999966, 35.219858066000029],
            [-81.20521956999994, 35.219794060000027],
            [-81.205217582999978, 35.219746350000037],
            [-81.205211932999987, 35.219632663000027],
            [-81.205209067999988, 35.219583286000045],
            [-81.205205256999989, 35.219525832000045],
            [-81.205198892999988, 35.219446086000062],
            [-81.205187421999938, 35.219323151000026],
            [-81.20518334999997, 35.219282501000066],
            [-81.205175053999938, 35.219212774000027],
            [-81.20513892799994, 35.218936718000066],
            [-81.205134099999952, 35.218901449000043],
            [-81.20510872899996, 35.218723950000026],
            [-81.205103350999934, 35.218687892000048],
            [-81.205079136999984, 35.218531997000071],
            [-81.205052471999977, 35.21834238100007],
            [-81.20504909999994, 35.218319059000066],
            [-81.205017758999986, 35.218108185000062],
            [-81.205014147999975, 35.21808453400007],
            [-81.204979848999983, 35.217865730000028],
            [-81.204975966999939, 35.217841607000025],
            [-81.204943442999934, 35.217644578000034],
            [-81.204909904999965, 35.217421282000032],
            [-81.20490577399994, 35.217394594000041],
            [-81.204875886999957, 35.217207066000071],
            [-81.204862423999941, 35.217095015000041],
            [-81.204856245999963, 35.217046887000038],
            [-81.204839812999978, 35.216926569000066],
            [-81.204818648999947, 35.216721545000041],
            [-81.204808687999957, 35.216566868000029],
            [-81.204798874999938, 35.216382198000076],
            [-81.204797139999982, 35.216352371000028],
            [-81.204784735999965, 35.216156079000029],
            [-81.204782591999958, 35.216124767000053],
            [-81.20476644699994, 35.215905894000059],
            [-81.204763887999945, 35.215873596000051],
            [-81.204745756999955, 35.215659496000058],
            [-81.204741042999956, 35.215608939000049],
            [-81.20473033199994, 35.215503741000077],
            [-81.204728710999973, 35.215488222000033],
            [-81.204724671999941, 35.215452534000065],
            [-81.204701512999975, 35.215258306000067],
            [-81.204692560999945, 35.215189918000078],
            [-81.204663871999969, 35.21498874100007],
            [-81.204654529999971, 35.214927725000052],
            [-81.204629668999985, 35.214775756000051],
            [-81.204617269999972, 35.214705227000024],
            [-81.204614069999934, 35.214688469000066],
            [-81.204604991999986, 35.214641645000029],
            [-81.204601982999975, 35.214616560000024],
            [-81.204601486999934, 35.214609693000057],
            [-81.204601346999937, 35.21460664600005],
            [-81.204600461999974, 35.214561106000076],
            [-81.204601691999983, 35.214553210000076],
            [-81.204607756999962, 35.214511596000079],
            [-81.204629375999957, 35.214456160000054],
            [-81.204631166999945, 35.214453230000061],
            [-81.204659936999974, 35.214416735000043],
            [-81.204673019999973, 35.214401219000024],
            [-81.204690546999984, 35.214381519000028],
            [-81.204712606999976, 35.214357914000061],
            [-81.204920370999957, 35.21414982400006],
            [-81.204946504999953, 35.214123397000037],
            [-81.205172973999936, 35.213892178000037],
            [-81.205196623999939, 35.21386781800004],
            [-81.205383322999978, 35.21367381400006],
            [-81.205402262999939, 35.213653991000058],
            [-81.205629004999935, 35.213414979000049],
            [-81.205777539999985, 35.213261228000079],
            [-81.205968114999962, 35.213067613000078],
            [-81.206254395999963, 35.212783408000064],
            [-81.206281754999964, 35.212755976000039],
            [-81.206508233999955, 35.212526607000029],
            [-81.20653310199998, 35.212501189000079],
            [-81.206676185999981, 35.212353585000074],
            [-81.20669522899999, 35.212333801000057],
            [-81.206822009999939, 35.212201132000075],
            [-81.20819946499995, 35.210786437000024],
            [-81.208203761999982, 35.210782018000032],
            [-81.208695587999955, 35.210275262000039],
            [-81.208705935999944, 35.210264559000052],
            [-81.208862271999976, 35.210102223000035],
            [-81.208947439999974, 35.210015257000066],
            [-81.209082587999944, 35.209880561000034],
            [-81.20921664499997, 35.20975076700006],
            [-81.209353344999954, 35.209622181000043],
            [-81.209506587999954, 35.209482035000065],
            [-81.209604173999935, 35.209389706000024],
            [-81.209782607999955, 35.209202934000075],
            [-81.209870150999961, 35.209101237000027],
            [-81.209871152999938, 35.209100029000069],
            [-81.209882242999981, 35.209089878000043],
            [-81.209930928999938, 35.209046144000069],
            [-81.210000691999937, 35.208985017000032],
            [-81.210287157999971, 35.208741209000038],
            [-81.210547144999964, 35.208523018000051],
            [-81.210882068999979, 35.208245200000079],
            [-81.210908531999962, 35.208223059000034],
            [-81.211034111999936, 35.208117093000055],
            [-81.211054184999966, 35.208100043000059],
            [-81.211178888999939, 35.207993430000045],
            [-81.211184612999944, 35.207988528000044],
            [-81.211202459999981, 35.207973123000045],
            [-81.211373588999948, 35.207824534000054],
            [-81.211400291999951, 35.20780114300004],
            [-81.211563032999948, 35.207657312000038],
            [-81.211589510999943, 35.207633703000056],
            [-81.211757412999987, 35.207482652000067],
            [-81.21178411599999, 35.207458410000072],
            [-81.211950349999938, 35.207306125000059],
            [-81.212024902999985, 35.207236040000055],
            [-81.212107590999949, 35.207156264000048],
            [-81.212128234999966, 35.207136200000036],
            [-81.212162240999987, 35.207102496000061],
            [-81.212243119999982, 35.207021341000029],
            [-81.212296740999989, 35.206966460000046],
            [-81.212375819999977, 35.206883894000043],
            [-81.212429318999966, 35.206826880000051],
            [-81.21251196299994, 35.206736976000059],
            [-81.212549260999936, 35.206695798000055],
            [-81.212564476999944, 35.206678644000078],
            [-81.212639460999981, 35.206593587000043],
            [-81.212689040999976, 35.206536182000036],
            [-81.212762315999953, 35.206449573000043],
            [-81.212810472999934, 35.206391461000067],
            [-81.212881687999982, 35.206303704000049],
            [-81.212928364999982, 35.206244962000028],
            [-81.212997499999972, 35.206156091000025],
            [-81.213001024999983, 35.206151552000051],
            [-81.21314821499999, 35.205947258000037],
            [-81.213169435999987, 35.205915092000055],
            [-81.213235170999951, 35.205814236000037],
            [-81.213253786999985, 35.205785361000039],
            [-81.213318411999978, 35.205684025000039],
            [-81.213336770999945, 35.20565491900004],
            [-81.213400278999984, 35.205553105000035],
            [-81.213420226999972, 35.205520725000042],
            [-81.213495832999968, 35.20539646800006],
            [-81.213548282999966, 35.205307237000056],
            [-81.213581611999984, 35.205247074000056],
            [-81.213638021999941, 35.20514273200007],
            [-81.21367356099995, 35.205075154000042],
            [-81.213700471999971, 35.205022520000057],
            [-81.213726410999982, 35.204970667000055],
            [-81.213759730999982, 35.20490256800008],
            [-81.213778361999971, 35.204863848000059],
            [-81.213787708999973, 35.204843919000041],
            [-81.213819582999975, 35.204775354000049],
            [-81.213847783999938, 35.204712980000068],
            [-81.213881491999985, 35.204636276000031],
            [-81.213908322999941, 35.204573420000031],
            [-81.213937108999971, 35.204503942000031],
            [-81.213961206999954, 35.204444077000062],
            [-81.213988517999951, 35.204374199000029],
            [-81.213995839999939, 35.204355286000066],
            [-81.214010538999958, 35.204316189000053],
            [-81.214045222999971, 35.204222050000055],
            [-81.214066891999948, 35.204161334000048],
            [-81.214094261999946, 35.20408208300006],
            [-81.214115300999936, 35.204019000000073],
            [-81.214138362999961, 35.203947322000033],
            [-81.214157071999978, 35.203887088000045],
            [-81.214178634999939, 35.20381509300006],
            [-81.21418026799995, 35.203809622000051],
            [-81.214196064999953, 35.20375467100007],
            [-81.214216121999982, 35.203682385000036],
            [-81.214232253999967, 35.203621862000034],
            [-81.21425080399996, 35.203549305000024],
            [-81.214270878999969, 35.203465938000079],
            [-81.214314563999949, 35.203272641000069],
            [-81.214321924999979, 35.203239199000052],
            [-81.21433009499998, 35.203199787000074],
            [-81.214344537999978, 35.203127715000051],
            [-81.214358222999977, 35.203054795000071],
            [-81.214372281999943, 35.202974468000036],
            [-81.214385342999947, 35.202893356000061],
            [-81.214398375999963, 35.202804731000072],
            [-81.214404187999946, 35.202763201000039],
            [-81.21440824299998, 35.202731367000069],
            [-81.214416120999942, 35.202666767000039],
            [-81.214433982999935, 35.20246194300006],
            [-81.214434112999982, 35.202459428000054],
            [-81.214453583999955, 35.202083150000078],
            [-81.214457215999971, 35.201883614000053],
            [-81.214454402999934, 35.201700047000031],
            [-81.214453651999975, 35.201664771000026],
            [-81.214452883999968, 35.201640228000031],
            [-81.214448803999971, 35.201525077000042],
            [-81.21444593199999, 35.201462592000041],
            [-81.214442682999959, 35.201405052000041],
            [-81.214438733999941, 35.201345655000068],
            [-81.214430565999976, 35.201238894000028],
            [-81.214423657999987, 35.201161760000048],
            [-81.214412717999949, 35.20105516700005],
            [-81.214411310999935, 35.201041745000055],
            [-81.214402262999954, 35.20096637000006],
            [-81.214383784999939, 35.200827310000079],
            [-81.214371616999983, 35.200744565000036],
            [-81.214357144999951, 35.200654834000034],
            [-81.214347572999941, 35.200598850000063],
            [-81.214335809999966, 35.200533733000043],
            [-81.214331843999958, 35.200512218000028],
            [-81.214326354999969, 35.200483794000036],
            [-81.214318080999988, 35.200442011000064],
            [-81.214276343999984, 35.200205157000028],
            [-81.21427009699994, 35.200170865000075],
            [-81.21425609399995, 35.200100965000047],
            [-81.214237127999979, 35.200011741000026],
            [-81.214230874999942, 35.199983006000025],
            [-81.214177001999985, 35.199741075000077],
            [-81.214135757999941, 35.199546300000065],
            [-81.214091841999959, 35.199327820000065],
            [-81.214045915999975, 35.199085592000074],
            [-81.214020131999973, 35.198963392000053],
            [-81.213985315999935, 35.19881365100008],
            [-81.213943786999948, 35.198616727000058],
            [-81.213898539999946, 35.198395793000032],
            [-81.213794152999981, 35.197864028000026],
            [-81.21377175899994, 35.197744387000057],
            [-81.213752254999974, 35.197634881000056],
            [-81.213725241999953, 35.197473638000076],
            [-81.213699930999951, 35.197310236000078],
            [-81.213647686999934, 35.196937383000034],
            [-81.213645860999975, 35.196924553000031],
            [-81.213564541999972, 35.19636186200006],
            [-81.213562371999956, 35.196347108000055],
            [-81.213517986999989, 35.196050373000048],
            [-81.213429186999974, 35.195443192000027],
            [-81.213426292999941, 35.195423842000025],
            [-81.213353287999951, 35.194946352000045],
            [-81.21333348099995, 35.194810081000071],
            [-81.21332687599994, 35.194766845000061],
            [-81.213302010999939, 35.194611657000053],
            [-81.213294756999971, 35.194568403000062],
            [-81.213269046999983, 35.194421626000064],
            [-81.21326249699996, 35.194385520000026],
            [-81.213242629999968, 35.19427967200005],
            [-81.213242413999978, 35.194278521000058],
            [-81.213233754999976, 35.194234300000062],
            [-81.21319075699995, 35.194023037000079],
            [-81.213181173999942, 35.193977721000067],
            [-81.213158044999943, 35.193872320000025],
            [-81.213138489999949, 35.193788830000074],
            [-81.213114494999957, 35.193692486000032],
            [-81.213102654999943, 35.193646469000043],
            [-81.213075013999969, 35.193542381000043],
            [-81.213071204999949, 35.19352817500004],
            [-81.213061933999938, 35.193494670000064],
            [-81.21303258599994, 35.193390898000075],
            [-81.213019284999973, 35.193345213000043],
            [-81.212990685999955, 35.19324972000004],
            [-81.212977800999965, 35.193207777000055],
            [-81.212950314999944, 35.193120503000046],
            [-81.212937407999959, 35.193080464000047],
            [-81.212908709999965, 35.192993454000032],
            [-81.21289608699999, 35.192955979000033],
            [-81.212855315999946, 35.192837418000067],
            [-81.212844389999987, 35.192806182000027],
            [-81.212843396999972, 35.192803395000055],
            [-81.212812362999955, 35.192716406000045],
            [-81.212797223999985, 35.192674890000035],
            [-81.212764926999967, 35.192588213000079],
            [-81.212749186999986, 35.192546855000046],
            [-81.212715629999934, 35.192460498000059],
            [-81.212699288999943, 35.192419299000051],
            [-81.212664478999955, 35.192333278000035],
            [-81.212648305999949, 35.192294061000041],
            [-81.212615574999973, 35.192216164000058],
            [-81.212601179999979, 35.192182445000071],
            [-81.212537211999972, 35.192034933000059],
            [-81.212525918999972, 35.19200920000003],
            [-81.212518957999976, 35.191993634000028],
            [-81.212463258999946, 35.191869954000026],
            [-81.212444336999965, 35.191828709000049],
            [-81.212397597999939, 35.191728677000071],
            [-81.212380779999989, 35.191693240000063],
            [-81.212350768999954, 35.191630973000031],
            [-81.212161114999958, 35.191216263000058],
            [-81.212154295999937, 35.19120145100004],
            [-81.212072202999934, 35.191024293000055],
            [-81.212061069999947, 35.191000520000046],
            [-81.211992738999982, 35.190856121000024],
            [-81.211952112999938, 35.190767751000067],
            [-81.211884328999986, 35.190615390000062],
            [-81.211843985999963, 35.190520906000074],
            [-81.211756240999989, 35.190281450000043],
            [-81.211746955999956, 35.190255550000074],
            [-81.211727235999945, 35.190197268000077],
            [-81.211710986999947, 35.190145399000073],
            [-81.21170763799995, 35.190134025000077],
            [-81.211675945999957, 35.190018773000077],
            [-81.211654307999936, 35.189932531000068],
            [-81.211637403999987, 35.189856590000034],
            [-81.211633631999973, 35.189837972000078],
            [-81.211620355999969, 35.189766205000069],
            [-81.211584095999967, 35.189551066000035],
            [-81.211562490999938, 35.189415722000035],
            [-81.211543286999984, 35.189287143000058],
            [-81.211509344999968, 35.189037115000076],
            [-81.211505756999941, 35.18901151700004],
            [-81.211434259999976, 35.18851679800008],
            [-81.211367774999985, 35.188039873000037],
            [-81.21135550799994, 35.187959771000067],
            [-81.21134309599995, 35.187885424000058],
            [-81.211337337999964, 35.187845964000076],
            [-81.211333583999988, 35.187816504000068],
            [-81.211330043999965, 35.187784536000038],
            [-81.21132692499998, 35.187751352000078],
            [-81.211323037999989, 35.187701632000028],
            [-81.211315120999984, 35.18761618700006],
            [-81.211281980999956, 35.187389131000032],
            [-81.211258313999963, 35.187261255000067],
            [-81.211242034999941, 35.187160006000056],
            [-81.211224475999984, 35.187031476000072],
            [-81.21121763299999, 35.186984193000058],
            [-81.211192733999951, 35.186821272000032],
            [-81.21117256399998, 35.186681913000029],
            [-81.21113506599994, 35.186401594000074],
            [-81.211128945999974, 35.186358249000079],
            [-81.211078288999943, 35.186017393000043],
            [-81.211024617999954, 35.185644270000068],
            [-81.211021459999984, 35.185622862000059],
            [-81.211009277999949, 35.185542286000043],
            [-81.211001996999983, 35.185496516000057],
            [-81.210981851999975, 35.18537585200005],
            [-81.210972188999961, 35.18532108200003],
            [-81.210949802999949, 35.18520068600003],
            [-81.210948665999979, 35.185194602000024],
            [-81.210939034999967, 35.185145612000042],
            [-81.210914183999989, 35.185024472000066],
            [-81.210902760999943, 35.184971218000044],
            [-81.21087748299999, 35.184858302000066],
            [-81.210866608999936, 35.184811464000063],
            [-81.210843451999949, 35.184715163000078],
            [-81.210832936999964, 35.184672765000073],
            [-81.21081045699998, 35.184584814000061],
            [-81.210799466999958, 35.18454304200003],
            [-81.210799168999984, 35.184541944000046],
            [-81.210773233999987, 35.184446250000065],
            [-81.210761326999943, 35.184403530000054],
            [-81.210736263999934, 35.184316048000028],
            [-81.210723728999938, 35.18427345200007],
            [-81.210694984999975, 35.184178299000052],
            [-81.210681822999959, 35.184135827000034],
            [-81.210654192999982, 35.184048871000073],
            [-81.210641180999971, 35.184008855000059],
            [-81.210612824999941, 35.183923612000058],
            [-81.210598912999956, 35.183882734000065],
            [-81.210567314999935, 35.183791939000059],
            [-81.210560242999975, 35.183771837000052],
            [-81.210533063999947, 35.183698316000061],
            [-81.210501699999952, 35.183616595000046],
            [-81.21048224599997, 35.183567147000076],
            [-81.21045159199997, 35.183491096000068],
            [-81.210430694999957, 35.183440505000078],
            [-81.210395215999938, 35.183356644000071],
            [-81.210373197999957, 35.183305827000027],
            [-81.210338898999964, 35.183228487000065],
            [-81.210338387999968, 35.183227335000026],
            [-81.210316803999945, 35.183179754000037],
            [-81.210281158999976, 35.183102828000074],
            [-81.210258162999935, 35.18305425300008],
            [-81.210221175999948, 35.182977755000024],
            [-81.210197423999944, 35.18292963600004],
            [-81.210159104999946, 35.182853578000049],
            [-81.210137118999967, 35.182810720000077],
            [-81.210022537999976, 35.182591298000034],
            [-81.210005025999976, 35.182558218000054],
            [-81.209974839999973, 35.182503207000025],
            [-81.20992451799998, 35.182413500000052],
            [-81.20989306499996, 35.182358642000054],
            [-81.209810507999975, 35.182217722000075],
            [-81.209771512999964, 35.182152826000049],
            [-81.20969007299999, 35.182020630000068],
            [-81.209666305999974, 35.181982607000066],
            [-81.209495800999946, 35.181713749000039],
            [-81.209495062999963, 35.18171258700005],
            [-81.209486412999979, 35.181699029000072],
            [-81.209170529999938, 35.181206452000026],
            [-81.209160689999976, 35.181191197000032],
            [-81.209014923999973, 35.180966492000039],
            [-81.20884943599998, 35.180703126000026],
            [-81.208841060999987, 35.180689865000033],
            [-81.208838342999968, 35.180685589000063],
            [-81.208653074999972, 35.180394644000046],
            [-81.208642556999962, 35.180378230000031],
            [-81.208483540999964, 35.180131589000041],
            [-81.208473646999948, 35.180116332000068],
            [-81.208316511999953, 35.179875390000063],
            [-81.208030582999982, 35.179428729000051],
            [-81.208024583999986, 35.179419392000057],
            [-81.207764576999978, 35.179016076000039],
            [-81.207749900999943, 35.178991290000056],
            [-81.20770762899997, 35.178921801000058],
            [-81.207684943999936, 35.178885487000059],
            [-81.207671809999965, 35.178864628000042],
            [-81.207638121999935, 35.178812587000039],
            [-81.207581277999964, 35.178726469000026],
            [-81.207537613999989, 35.17866218100005],
            [-81.207517706999965, 35.17863146600007],
            [-81.207490570999937, 35.178590241000052],
            [-81.207442951999951, 35.178518999000062],
            [-81.207416365999961, 35.178479801000037],
            [-81.20736767599999, 35.178409047000059],
            [-81.207330934999959, 35.178356680000036],
            [-81.207245733999969, 35.178237549000073],
            [-81.207212031999973, 35.178191219000041],
            [-81.207197998999959, 35.178172380000035],
            [-81.207109774999935, 35.178054742000029],
            [-81.207074589999934, 35.17800860400007],
            [-81.207021361999978, 35.177939958000024],
            [-81.206963930999962, 35.177867800000058],
            [-81.206892128999982, 35.177779884000074],
            [-81.206840708999948, 35.177718304000052],
            [-81.206835489999946, 35.177712205000034],
            [-81.206795529999965, 35.177665594000075],
            [-81.206737091999969, 35.177599040000075],
            [-81.206660518999968, 35.177513882000028],
            [-81.20658485499996, 35.177432180000039],
            [-81.206511490999958, 35.177355245000058],
            [-81.206477252999946, 35.177319799000031],
            [-81.206433045999972, 35.177275335000047],
            [-81.206350727999961, 35.177193869000064],
            [-81.206262977999984, 35.177109678000079],
            [-81.206171106999989, 35.17702421000007],
            [-81.20611476199997, 35.176972789000047],
            [-81.206069828999944, 35.176933158000054],
            [-81.205981102999942, 35.176856065000038],
            [-81.205918331999953, 35.176802630000054],
            [-81.205862538999952, 35.176756097000066],
            [-81.205795788999978, 35.176701600000058],
            [-81.20568893799998, 35.176616203000037],
            [-81.20566114899998, 35.176594186000045],
            [-81.205620833999944, 35.176562920000038],
            [-81.205562676999989, 35.176518381000051],
            [-81.205496276999952, 35.176468555000042],
            [-81.205392592999942, 35.176392330000056],
            [-81.205324956999959, 35.176343615000064],
            [-81.205264639999939, 35.176301056000057],
            [-81.205223674999957, 35.176272507000078],
            [-81.205205284999977, 35.176259916000049],
            [-81.205121107999958, 35.176202605000071],
            [-81.205051338999965, 35.17615607700003],
            [-81.204966644999956, 35.176100761000043],
            [-81.204896686999973, 35.176056004000031],
            [-81.204811731999939, 35.176002768000046],
            [-81.204742381999949, 35.175960188000033],
            [-81.204741223999974, 35.175959491000071],
            [-81.204654994999942, 35.175907657000039],
            [-81.204483082999957, 35.175809370000025],
            [-81.204399389999935, 35.175763901000039],
            [-81.204339061999974, 35.175731703000054],
            [-81.204169892999971, 35.175643015000048],
            [-81.204164645999981, 35.175640268000052],
            [-81.204106901999978, 35.175610600000027],
            [-81.204012871999964, 35.175563114000056],
            [-81.203959554999983, 35.175536611000041],
            [-81.203830234999941, 35.175473350000061],
            [-81.20376818799997, 35.175443557000051],
            [-81.203637629999946, 35.175382030000037],
            [-81.203585094999937, 35.175357662000067],
            [-81.202658695999958, 35.174934789000076],
            [-81.202465490999941, 35.174845153000035],
            [-81.202336601999946, 35.174783957000045],
            [-81.202205382999978, 35.17472044200008],
            [-81.202074846999949, 35.17465603800008],
            [-81.202027212999951, 35.174632087000077],
            [-81.201990896999973, 35.174611620000064],
            [-81.201974143999962, 35.174601814000027],
            [-81.201887383999974, 35.174549093000053],
            [-81.201808873999937, 35.174499035000053],
            [-81.201782511999966, 35.174481623000077],
            [-81.201758205999965, 35.174465156000053],
            [-81.201710755999954, 35.17443032500006],
            [-81.201673658999937, 35.174401852000074],
            [-81.201635117999956, 35.174371167000061],
            [-81.201593943999967, 35.174336991000075],
            [-81.201560756999982, 35.174307490000047],
            [-81.201540437999938, 35.174288507000028],
            [-81.201519073999975, 35.174267729000064],
            [-81.201498201999982, 35.17424662500008],
            [-81.201473878999934, 35.17422100400006],
            [-81.201456322999945, 35.174201700000026],
            [-81.20145073599997, 35.174195231000056],
            [-81.201420019999944, 35.174158466000051],
            [-81.201392650999935, 35.174124433000031],
            [-81.201365903999942, 35.17408985600008],
            [-81.201340523999988, 35.174055718000034],
            [-81.201302019999957, 35.173999491000075],
            [-81.201253038999937, 35.173925843000063],
            [-81.201205616999971, 35.173852268000076],
            [-81.201163271999974, 35.173784514000033],
            [-81.201116783999964, 35.173707756000056],
            [-81.201097101999949, 35.173674270000049],
            [-81.201023149999969, 35.173539626000036],
            [-81.200936182999953, 35.173379160000025],
            [-81.200785595999946, 35.17309623400007],
            [-81.200637742999959, 35.172811940000031],
            [-81.200335848999941, 35.172220878000076],
            [-81.200327487999971, 35.17220461900007],
            [-81.200054521999959, 35.171677395000074],
            [-81.200036988999955, 35.171642438000049],
            [-81.200021937999963, 35.171611279000047],
            [-81.20000485099996, 35.171574448000058],
            [-81.199988926999936, 35.17153858000006],
            [-81.199973615999966, 35.171502497000063],
            [-81.199959405999948, 35.171467396000025],
            [-81.199943696999981, 35.171426557000075],
            [-81.199937331999934, 35.171409061000077],
            [-81.199935094999944, 35.171401889000037],
            [-81.199919284999964, 35.171348223000052],
            [-81.199903069999948, 35.171289294000076],
            [-81.199889999999982, 35.171238218000042],
            [-81.199889233999954, 35.171234981000055],
            [-81.199880894999978, 35.171188219000044],
            [-81.199866492999945, 35.171096256000055],
            [-81.19985399899997, 35.171004099000072],
            [-81.199842083999954, 35.170899603000066],
            [-81.199747490999982, 35.169913089000033],
            [-81.199744177999946, 35.169880423000052],
            [-81.199743603999934, 35.169875104000027],
            [-81.199720426999988, 35.16966203000004],
            [-81.199698391999959, 35.169448805000059],
            [-81.199677520999956, 35.169235474000061],
            [-81.199658961999944, 35.169034681000028],
            [-81.199623509999981, 35.168612320000079],
            [-81.199621355999966, 35.168587871000057],
            [-81.199579105999987, 35.168129673000067],
            [-81.19957895999994, 35.168127728000059],
            [-81.199576737999962, 35.168087352000043],
            [-81.199575288999938, 35.168030287000079],
            [-81.199576331999936, 35.167981503000078],
            [-81.199578907999978, 35.167931931000055],
            [-81.199584773999959, 35.167872020000061],
            [-81.199591885999951, 35.16782291800007],
            [-81.199600772999986, 35.167772532000072],
            [-81.199610493999955, 35.167728768000075],
            [-81.199620099999947, 35.167690224000069],
            [-81.199621608999962, 35.16768462400006],
            [-81.199622463999958, 35.167681635000065],
            [-81.199632693999945, 35.167649671000049],
            [-81.199651002999985, 35.167596550000042],
            [-81.19966803799997, 35.167550452000057],
            [-81.199686523999958, 35.167503511000064],
            [-81.199708357999953, 35.167451328000027],
            [-81.199714658999937, 35.167436971000029],
            [-81.199751300999935, 35.167357841000069],
            [-81.199756043999969, 35.16734811200007],
            [-81.199872793999987, 35.16712035900008],
            [-81.199887854999986, 35.167090610000059],
            [-81.200001351999958, 35.166863579000051],
            [-81.200067289999936, 35.166736747000073],
            [-81.200081009999963, 35.166710060000071],
            [-81.200157573999945, 35.166559466000024],
            [-81.200172803999976, 35.166529125000068],
            [-81.200265400999967, 35.166342257000053],
            [-81.200282641999934, 35.166306933000044],
            [-81.200369351999939, 35.166126545000054],
            [-81.200378075999936, 35.166108253000061],
            [-81.200396806999947, 35.166067952000049],
            [-81.200477104999948, 35.165892047000057],
            [-81.20050400599996, 35.165831483000034],
            [-81.200583807999976, 35.165646738000078],
            [-81.200607788999946, 35.165589718000035],
            [-81.200671163999971, 35.165434874000027],
            [-81.200692474999983, 35.165381436000075],
            [-81.200750435999964, 35.165232195000044],
            [-81.200788168999964, 35.165137722000054],
            [-81.200804182999946, 35.16509681000008],
            [-81.200811783999939, 35.165076791000047],
            [-81.200841097999955, 35.16499877800004],
            [-81.200860618999968, 35.164945375000059],
            [-81.200891253999941, 35.164859163000074],
            [-81.200909812999953, 35.164805399000045],
            [-81.200936188999947, 35.164726682000037],
            [-81.200953736999963, 35.164672688000053],
            [-81.200981122999963, 35.164585742000043],
            [-81.200995642999942, 35.164538329000038],
            [-81.200998551999987, 35.164528493000034],
            [-81.201366850999989, 35.163275437000038],
            [-81.201389027999937, 35.16320233600004],
            [-81.201393831999951, 35.163186345000042],
            [-81.20140601899999, 35.163144299000066],
            [-81.201481709999939, 35.162876060000031],
            [-81.201516657999946, 35.162755770000047],
            [-81.201596782999957, 35.162487982000073],
            [-81.201660519999962, 35.162282577000042],
            [-81.201696736999963, 35.162176489000046],
            [-81.201717948999942, 35.162117613000078],
            [-81.201739632999988, 35.162059621000026],
            [-81.201765064999961, 35.161994007000033],
            [-81.201788007999937, 35.16193684700005],
            [-81.201809672999957, 35.161884721000035],
            [-81.201831396999978, 35.161834615000032],
            [-81.201853291999953, 35.161785947000055],
            [-81.201886823999985, 35.161714475000053],
            [-81.201923078999982, 35.161640800000043],
            [-81.201960415999963, 35.161568451000051],
            [-81.202001422999956, 35.161492564000071],
            [-81.202031253999962, 35.161439425000026],
            [-81.202224457999989, 35.161102123000035],
            [-81.20223021299995, 35.16109203700006],
            [-81.202420887999949, 35.160756476000074],
            [-81.202422085999956, 35.160754366000049],
            [-81.202427200999978, 35.160745315000042],
            [-81.202936491999935, 35.159841028000073],
            [-81.203550554999936, 35.158895398000027],
            [-81.20836405099999, 35.157211335000056],
            [-81.208413459999974, 35.157193754000048],
            [-81.208673977999979, 35.157091016000038],
            [-81.208925756999975, 35.156974505000051],
            [-81.209167717999946, 35.156844719000048],
            [-81.20921537199996, 35.15681690100007],
            [-81.214180044999978, 35.153877824000062],
            [-81.214363481999953, 35.153763128000037],
            [-81.214582725999946, 35.153608506000069],
            [-81.214789185999962, 35.153442437000024],
            [-81.214981977999969, 35.153265633000046],
            [-81.215160276999939, 35.153078851000032],
            [-81.215291606999983, 35.152923230000056],
            [-81.216968403999942, 35.150821045000043],
            [-81.217000115999952, 35.150780707000024],
            [-81.217147195999985, 35.150576407000074],
            [-81.217277690999936, 35.150364643000046],
            [-81.217391043999953, 35.150146323000058],
            [-81.217486767999958, 35.149922381000067],
            [-81.217564454999945, 35.149693777000039],
            [-81.217623770999978, 35.149461489000032],
            [-81.217658934999974, 35.149266140000066],
            [-81.218323431999977, 35.144722673000047],
            [-81.218328958999962, 35.144683045000079],
            [-81.218350848999989, 35.144446385000037],
            [-81.218352803999949, 35.144403147000048],
            [-81.218615002999968, 35.13749615200004],
            [-81.220297785999946, 35.131389465000041],
            [-81.224483053999961, 35.123325684000065],
            [-81.224520819999952, 35.123250594000069],
            [-81.228385279999941, 35.115315169000041],
            [-81.228712307999956, 35.114746155000034],
            [-81.233247779999942, 35.110334593000061],
            [-81.233259113999964, 35.110323523000034],
            [-81.23343727799994, 35.110136713000031],
            [-81.233600190999937, 35.109940729000073],
            [-81.233747155999936, 35.109736407000071],
            [-81.233877542999949, 35.109524624000073],
            [-81.233990793999965, 35.109306288000028],
            [-81.234086424999987, 35.109082332000071],
            [-81.234125609999978, 35.108974103000037],
            [-81.236878188999981, 35.100913608000042],
            [-81.236908038999957, 35.100821558000064],
            [-81.237400889999947, 35.099217305000025],
            [-81.237409447999937, 35.099188967000032],
            [-81.237468669999942, 35.098956668000028],
            [-81.237506717999963, 35.098740569000029],
            [-81.237851528999954, 35.096256647000075],
            [-81.237854089999985, 35.096237761000054],
            [-81.237875909999957, 35.096001096000066],
            [-81.237878849999959, 35.095763765000072],
            [-81.237865988999943, 35.095557030000066],
            [-81.237750873999971, 35.094371305000038],
            [-81.237747783999964, 35.094341058000055],
            [-81.237713008999947, 35.094105442000057],
            [-81.237659557999962, 35.093872199000032],
            [-81.237630040999989, 35.093770127000028],
            [-81.237131396999985, 35.092150303000039],
            [-81.237089020999974, 35.092022505000045],
            [-81.23699899099995, 35.091796991000024],
            [-81.236891208999964, 35.091576800000041],
            [-81.236766781999961, 35.091363888000046],
            [-81.235299442999974, 35.08904928000004],
            [-81.235298799999953, 35.089048266000077],
            [-81.235257734999948, 35.088985066000077],
            [-81.231120588999943, 35.082766089000074],
            [-81.230908849999935, 35.08220496000007],
            [-81.230884465999964, 35.080162924000035],
            [-81.231208160999984, 35.074765212000045],
            [-81.231283776999987, 35.074332456000036],
            [-81.232136837999974, 35.073009656000067],
            [-81.234316633999981, 35.070264552000026],
            [-81.23792294399999, 35.066732150000064],
            [-81.239753199999939, 35.064945082000065],
            [-81.240650398999946, 35.064336408000031],
            [-81.244537826999988, 35.06208896000004],
            [-81.251773632999971, 35.058642295000027],
            [-81.254695887999958, 35.057269303000055],
            [-81.254799143999946, 35.057219244000066],
            [-81.255040735999955, 35.057089365000024],
            [-81.255271477999941, 35.056946770000025],
            [-81.255490384999973, 35.056792071000075],
            [-81.255652022999982, 35.056663668000056],
            [-81.257063982999966, 35.055483343000049],
            [-81.257108474999939, 35.055445606000035],
            [-81.257300943999951, 35.055268732000059],
            [-81.25747893199997, 35.055081886000039],
            [-81.25764167799997, 35.054885868000042],
            [-81.257788483999946, 35.054681516000073],
            [-81.257918719999964, 35.054469706000077],
            [-81.258031830999983, 35.054251345000068],
            [-81.258127330999969, 35.054027369000039],
            [-81.258158298999945, 35.053942751000079],
            [-81.258540776999951, 35.052849542000047],
            [-81.25858728999998, 35.052705527000057],
            [-81.258646417999955, 35.052473216000067],
            [-81.258686943999976, 35.052238223000074],
            [-81.258708691999971, 35.052001553000025],
            [-81.258711174999974, 35.051942434000068],
            [-81.258774079999966, 35.04998246200006],
            [-81.258869159999961, 35.049095266000052],
            [-81.259258965999948, 35.047611585000027],
            [-81.259290747999955, 35.047478627000032],
            [-81.26108263399999, 35.039163908000035],
            [-81.261084037999979, 35.039157357000079],
            [-81.261124549999977, 35.038922362000051],
            [-81.261146286999974, 35.038685692000058],
            [-81.261149156999977, 35.038448358000039],
            [-81.261133148999988, 35.038211379000074],
            [-81.261098329999982, 35.037975768000024],
            [-81.261044849999962, 35.037742534000074],
            [-81.260972937999952, 35.037512677000052],
            [-81.260882901999935, 35.037287180000078],
            [-81.260775127999977, 35.037067009000054],
            [-81.260650078999959, 35.036853106000024],
            [-81.26059791299997, 35.036773323000034],
            [-81.259315314999981, 35.034869041000036],
            [-81.258771793999983, 35.033861343000069],
            [-81.258379998999942, 35.031617675000064],
            [-81.258000556999946, 35.028657148000036],
            [-81.258141786999943, 35.025483524000038],
            [-81.258143596999957, 35.025268347000065],
            [-81.258127600999956, 35.025031367000054],
            [-81.258092795999971, 35.024795754000024],
            [-81.258039332999942, 35.024562519000028],
            [-81.258038327999941, 35.02455881700007],
            [-81.257716839999944, 35.023376960000064],
            [-81.257645954999987, 35.023150802000032],
            [-81.257555943999989, 35.022925303000079],
            [-81.257486042999972, 35.022777839000071],
            [-81.254125448999957, 35.016120706000038],
            [-81.254087609999942, 35.016047994000076],
            [-81.253962609999974, 35.015834084000062],
            [-81.253820871999949, 35.015627358000074],
            [-81.253680997999936, 35.015449964000027],
            [-81.252641928999935, 35.014212694000037],
            [-81.252047706999974, 35.013471065000033],
            [-81.253480772999978, 35.006634119000068],
            [-81.257956072999946, 34.990524231000052],
            [-81.25800062899998, 34.990342558000066],
            [-81.258041124999977, 34.990107564000027],
            [-81.258062858999949, 34.989870892000056],
            [-81.258065735999935, 34.989633558000037],
            [-81.258055980999984, 34.989461176000077],
            [-81.257873249999989, 34.987326418000066],
            [-81.257867014999988, 34.987261819000025],
            [-81.257832227999984, 34.987026206000053],
            [-81.257778789999975, 34.986792970000067],
            [-81.257706931999962, 34.986563110000077],
            [-81.257616961999986, 34.986337609000032],
            [-81.257509263999964, 34.98611743400005],
            [-81.257384299999956, 34.985903527000062],
            [-81.257343421999963, 34.985840565000046],
            [-81.256095655999957, 34.983963311000025],
            [-81.255994843999986, 34.983819550000078],
            [-81.255837031999988, 34.983620895000058],
            [-81.255663771999934, 34.983431160000066],
            [-81.255475806999982, 34.983251157000041],
            [-81.255273940999984, 34.983081657000071],
            [-81.255059037999956, 34.982923386000039],
            [-81.254832017999945, 34.98277702200005],
            [-81.254812408999953, 34.982765278000045],
            [-81.250858654999945, 34.980411042000071],
            [-81.250819521999972, 34.979623088000039],
            [-81.250818161999973, 34.979597844000068],
            [-81.250472516999935, 34.97365284600005],
            [-81.250460456999974, 34.973507012000027],
            [-81.250425696999969, 34.973271397000076],
            [-81.250372288999984, 34.973038157000076],
            [-81.250301181999987, 34.972810315000061],
            [-81.249755972999935, 34.971272144000068],
            [-81.249755254999968, 34.971270121000032],
            [-81.249665322999988, 34.971044615000039],
            [-81.249557666999976, 34.970824432000029],
            [-81.249432747999947, 34.970610517000068],
            [-81.249291099999937, 34.970403784000041],
            [-81.249231287999976, 34.970325090000074],
            [-81.246223119999968, 34.966477350000048],
            [-81.246175541999946, 34.966417783000054],
            [-81.244800486999964, 34.964732536000042],
            [-81.244502051999973, 34.964074553000046],
            [-81.244194005999987, 34.960487089000026],
            [-81.244188401999963, 34.956075596000062],
            [-81.244187278999959, 34.955997400000058],
            [-81.244171335999965, 34.955760416000032],
            [-81.24413660099998, 34.955524799000045],
            [-81.244083222999961, 34.955291556000077],
            [-81.244012148999957, 34.955063709000058],
            [-81.24178668899998, 34.948781900000029],
            [-81.241785971999946, 34.948779878000039],
            [-81.24169608599999, 34.948554365000064],
            [-81.241588480999951, 34.94833417500007],
            [-81.241463616999965, 34.948120251000034],
            [-81.241322028999946, 34.947913509000045],
            [-81.241164321999975, 34.947714834000067],
            [-81.241071311999974, 34.947609700000044],
            [-81.238221443999976, 34.944506665000063],
            [-81.238141309999946, 34.944422040000063],
            [-81.238018336999971, 34.944301514000074],
            [-81.235008175999951, 34.94147788600003],
            [-81.232435073999966, 34.937793016000057],
            [-81.229332524999961, 34.932957831000067],
            [-81.228859974999978, 34.930412666000052],
            [-81.228761857999984, 34.926814173000025],
            [-81.228745965999963, 34.926577187000078],
            [-81.228711287999943, 34.92634156400004],
            [-81.22865797299994, 34.926108315000079],
            [-81.228610612999944, 34.925949819000039],
            [-81.227891522999982, 34.923757063000039],
            [-81.227867161999939, 34.923685679000073],
            [-81.227777341999968, 34.923460155000043],
            [-81.227669807999973, 34.923239952000074],
            [-81.227545017999944, 34.923026014000072],
            [-81.227424269999972, 34.922847686000068],
            [-81.226310943999977, 34.921307054000067],
            [-81.226290183999936, 34.921278623000035],
            [-81.226132563999954, 34.921079927000051],
            [-81.225959505999981, 34.920890150000048],
            [-81.225771749999979, 34.920710099000075],
            [-81.225582556999939, 34.920550399000035],
            [-81.215222211999958, 34.912325705000057],
            [-81.214240382999947, 34.911124774000029],
            [-81.213596134999989, 34.909952820000058],
            [-81.213150304999942, 34.904374743000062],
            [-81.213142878999975, 34.904295188000049],
            [-81.213141217999976, 34.904279999000039],
            [-81.212652213999945, 34.899910824000074],
            [-81.212619254999936, 34.899690385000042],
            [-81.212566002999949, 34.899457127000062],
            [-81.21249434899994, 34.899227239000027],
            [-81.212481108999953, 34.89919059500005],
            [-81.211232458999973, 34.89580042700004],
            [-81.211044375999961, 34.894131635000065],
            [-81.211011693999978, 34.893913604000034],
            [-81.210958449999964, 34.893680346000053],
            [-81.210886805999962, 34.893450456000039],
            [-81.210797066999987, 34.893224919000033],
            [-81.210689617999947, 34.893004700000063],
            [-81.210564918999978, 34.892790744000024],
            [-81.210423503999948, 34.892583965000028],
            [-81.210265978999985, 34.892385248000039],
            [-81.210093017999952, 34.892195447000063],
            [-81.209905362999962, 34.89201537200006],
            [-81.209703815999944, 34.891845795000052],
            [-81.209489240999972, 34.891687442000034],
            [-81.209262556999988, 34.891540990000067],
            [-81.209169810999981, 34.89148876400003],
            [-81.209103779999964, 34.891451581000069],
            [-81.209024733999968, 34.891407068000035],
            [-81.208962471999939, 34.891376729000058],
            [-81.208824538999977, 34.891309514000056],
            [-81.208776789999945, 34.891286247000039],
            [-81.208735546999947, 34.891269044000069],
            [-81.208641263999937, 34.891228531000024],
            [-81.208545820999973, 34.891189906000079],
            [-81.208519786999943, 34.891179047000037],
            [-81.208496605999983, 34.891170900000077],
            [-81.208380141999953, 34.891128316000049],
            [-81.208262230999935, 34.891088528000068],
            [-81.208254825999973, 34.891085925000027],
            [-81.208237809999957, 34.891080694000038],
            [-81.208145660999946, 34.891052365000064],
            [-81.208141738999984, 34.891051200000049],
            [-81.208136641999943, 34.891049697000028],
            [-81.026801367999951, 34.837391628000034],
            [-81.026625897999963, 34.837342953000075],
            [-81.026448097999946, 34.837300408000033],
            [-81.026268284999958, 34.837264068000025],
            [-81.026086776999989, 34.837233998000045],
            [-81.025903894999942, 34.837210252000034],
            [-81.025719963999961, 34.83719287100007],
            [-81.025535308999963, 34.837181885000064],
            [-81.025350256999957, 34.837177316000066],
            [-81.025165134999952, 34.837179169000024],
            [-81.024980270999947, 34.837187443000062],
            [-81.024795993999987, 34.837202123000054],
            [-81.024604708999959, 34.837224239000079],
            [-81.024414785999966, 34.837253255000064],
            [-81.024226589999955, 34.837289118000058],
            [-81.02404048599999, 34.837331758000062],
            [-81.023856829999943, 34.837381091000054],
            [-81.023675977999972, 34.837437025000042],
            [-81.023498278999966, 34.837499450000053],
            [-81.023324072999969, 34.837568245000057],
            [-81.023153698999977, 34.837643281000055],
            [-81.022987481999962, 34.837724409000032],
            [-81.022825745999967, 34.837811475000024],
            [-81.022668799999963, 34.837904310000056],
            [-81.022516947999975, 34.838002737000068],
            [-81.021142818999976, 34.839646766000044],
            [-81.004653173999941, 34.859368851000056],
            [-81.002869873999941, 34.865482021000048],
            [-81.001434119999942, 34.871444537000059],
            [-80.999373252999987, 34.880603997000037],
            [-80.998049020999986, 34.883957403000068],
            [-80.994834228999935, 34.889080251000053],
            [-81.02843328199998, 34.925871222000069],
            [-81.035937290999982, 34.932152188000032],
            [-81.043784737999943, 34.937714934000041],
            [-81.048168060999956, 34.940132340000048],
            [-81.049693806999983, 34.940640364000046],
            [-81.057698207999977, 34.940919287000042],
            [-81.060803943999986, 34.941222202000063],
            [-81.076330545999951, 34.948312560000033],
            [-81.08959506399998, 34.954194276000067],
            [-81.094048095999938, 34.956430853000029],
            [-81.09673606399997, 34.957274075000043],
            [-81.098538375999965, 34.957302046000052],
            [-81.105088662999947, 34.957009861000074],
            [-81.111350777999974, 34.956722366000065],
            [-81.119911671999944, 34.95615682600004],
            [-81.125675630999979, 34.956114663000051],
            [-81.127700194999989, 34.956375597000033],
            [-81.130535661999943, 34.95733412900006],
            [-81.134419916999946, 34.959816606000061],
            [-81.138663032999943, 34.962233267000045],
            [-81.140264895999962, 34.962857470000074],
            [-81.145991545999948, 34.964120265000076],
            [-81.148108677999971, 34.965150504000064],
            [-81.149879713999951, 34.96672084800008],
            [-81.15136421699998, 34.96835561000006],
            [-81.153367139999943, 34.970515067000065],
            [-81.154975533999959, 34.97137628400003],
            [-81.157080442999984, 34.971931948000076],
            [-81.162779729999954, 34.972126508000031],
            [-81.164662291999946, 34.972448708000059],
            [-81.172456132999969, 34.975512375000051],
            [-81.174700624999957, 34.975887230000069],
            [-81.178879335999966, 34.975811797000063],
            [-81.18378960199999, 34.976138285000047],
            [-81.187444342999981, 34.978029947000039],
            [-81.18987280999994, 34.979884446000028],
            [-81.192082049999954, 34.98162422200005],
            [-81.195660392999969, 34.983339040000033],
            [-81.19893789799994, 34.984584596000047],
            [-81.200284387999943, 34.986399284000072],
            [-81.20266212699994, 34.989025757000036],
            [-81.207704913999976, 34.992846131000078],
            [-81.209358780999935, 34.995423151000068],
            [-81.210919113999978, 35.001043718000062],
            [-81.211799346999953, 35.007545838000055],
            [-81.214540856999974, 35.011261645000047],
            [-81.223676957999942, 35.017032456000038],
            [-81.227846998999951, 35.021445890000052],
            [-81.232094540999981, 35.022236488000033],
            [-81.244550217999972, 35.020556904000046],
            [-81.246436348999964, 35.019802817000027],
            [-81.249194963999969, 35.025268501000028]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 5,
        "SolutionID": "PI-4",
        "Shape_Length": 1.339793194962799,
        "Shape_Area": 0.025213484309038942
      }
    },
    {
      "type": "Feature",
      "id": 6,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820207235999987, 35.235051884000029],
            [-80.820423235999954, 35.235209884000028],
            [-80.820613234999939, 35.235340883000049],
            [-80.820800234999979, 35.235468884000056],
            [-80.821207234999974, 35.235756884000068],
            [-80.821687235999946, 35.236096884000062],
            [-80.821797235999952, 35.236164884000061],
            [-80.821916236999982, 35.236223884000026],
            [-80.822071235999942, 35.23628188400005],
            [-80.822152235999965, 35.236304885000038],
            [-80.822284235999973, 35.236335884000027],
            [-80.822532235999972, 35.236382885000069],
            [-80.822762236999949, 35.236408883000024],
            [-80.822903235999945, 35.236414884000055],
            [-80.823018236999985, 35.23641288400006],
            [-80.823187235999967, 35.236393883000062],
            [-80.823345236999955, 35.236364884000068],
            [-80.82358723699997, 35.236311884000031],
            [-80.823754235999957, 35.236259884000049],
            [-80.823834236999971, 35.236227884000073],
            [-80.823911236999948, 35.23619188400005],
            [-80.824109236999959, 35.236082884000041],
            [-80.824203236999949, 35.236022883000032],
            [-80.824335236999957, 35.235929884000029],
            [-80.824582236999959, 35.235751884000024],
            [-80.824886236999987, 35.235520884000039],
            [-80.825132236999934, 35.235329884000066],
            [-80.825887236999961, 35.23474288400007],
            [-80.825968236999984, 35.234681884000054],
            [-80.826137236999955, 35.234555884000031],
            [-80.826164236999944, 35.234573884000042],
            [-80.826412236999943, 35.234735883000042],
            [-80.827339237999979, 35.23539188400008],
            [-80.829496236999944, 35.233748884000079],
            [-80.830596237999941, 35.232948883000063],
            [-80.831796238999971, 35.23194888300003],
            [-80.832330238999987, 35.231607883000038],
            [-80.832383237999977, 35.231570883000074],
            [-80.832780238999987, 35.231288882000058],
            [-80.831964238999944, 35.230583883000065],
            [-80.831765237999946, 35.23040188300007],
            [-80.831559238999944, 35.230214882000041],
            [-80.831294237999941, 35.229988883000033],
            [-80.831021238999938, 35.229749883000068],
            [-80.830819237999947, 35.22957288200007],
            [-80.830536238999969, 35.229324883000061],
            [-80.830048237999961, 35.228896882000072],
            [-80.829850237999949, 35.228722883000046],
            [-80.829527237999969, 35.228440882000029],
            [-80.829366237999977, 35.228299882000044],
            [-80.829025236999939, 35.227984882000044],
            [-80.828858236999963, 35.227808882000033],
            [-80.828780236999989, 35.227716882000038],
            [-80.828624237999975, 35.227508882000052],
            [-80.82850323699995, 35.227316882000025],
            [-80.82845023699997, 35.227218882000045],
            [-80.82832323699995, 35.226941882000062],
            [-80.828234237999936, 35.226735882000071],
            [-80.828187236999952, 35.226579882000067],
            [-80.828171236999935, 35.226500883000028],
            [-80.828147236999939, 35.226231882000036],
            [-80.828140236999957, 35.225977881000063],
            [-80.828153236999981, 35.225749882000059],
            [-80.828163237999945, 35.225675882000075],
            [-80.827807237999934, 35.226526881000041],
            [-80.827717237999934, 35.226740881000069],
            [-80.827630237999983, 35.226899882000055],
            [-80.827606235999951, 35.226939882000067],
            [-80.827494237999986, 35.227093882000077],
            [-80.82740623799998, 35.227198882000039],
            [-80.827363236999986, 35.22726988200003],
            [-80.827350236999962, 35.227305881000063],
            [-80.827337236999938, 35.227324882000062],
            [-80.827311236999947, 35.227352882000048],
            [-80.82728723799994, 35.227366882000069],
            [-80.827253236999979, 35.227376882000044],
            [-80.82714823699996, 35.227393882000058],
            [-80.82696823699996, 35.227409882000075],
            [-80.826657236999949, 35.227415882000059],
            [-80.826341236999951, 35.227401882000038],
            [-80.82623323699994, 35.227406882000025],
            [-80.825913236999952, 35.227436883000053],
            [-80.825465236999946, 35.227514883000026],
            [-80.825297236999972, 35.227570883000055],
            [-80.825207236999972, 35.227620882000053],
            [-80.825107235999951, 35.227687882000055],
            [-80.82502423699998, 35.227762882000036],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 6,
        "SolutionID": "PI-5",
        "Shape_Length": 0.038499905378580911,
        "Shape_Area": 6.3360392433037783e-5
      }
    },
    {
      "type": "Feature",
      "id": 7,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.968547274999935, 35.14692485900008],
            [-80.968638273999943, 35.146944860000076],
            [-80.968710274999978, 35.14697386000006],
            [-80.968804274999968, 35.147031860000027],
            [-80.969596274999958, 35.14762285900008],
            [-80.969507275999945, 35.14770586000003],
            [-80.969426275999979, 35.147792860000038],
            [-80.969368274999965, 35.147878860000048],
            [-80.969336274999989, 35.147955860000025],
            [-80.96932127499997, 35.148022860000026],
            [-80.96931727599997, 35.148097860000064],
            [-80.969451274999983, 35.148799860000054],
            [-80.969828274999941, 35.148800860000051],
            [-80.969931274999965, 35.148809859000039],
            [-80.97002727499995, 35.148836860000074],
            [-80.970102275999977, 35.148871860000042],
            [-80.970160274999955, 35.148906860000068],
            [-80.970412274999944, 35.149056861000076],
            [-80.97055527599997, 35.149120860000039],
            [-80.970676275999949, 35.149150860000077],
            [-80.971529274999966, 35.149344860000042],
            [-80.971638275999965, 35.149002860000053],
            [-80.971707275999961, 35.148748860000069],
            [-80.971796275999964, 35.148362859000031],
            [-80.971957275999955, 35.147548860000029],
            [-80.972137275999955, 35.146793860000059],
            [-80.972237275999987, 35.146445860000028],
            [-80.972323274999951, 35.146199859000035],
            [-80.97242827599996, 35.145939860000055],
            [-80.97251427599997, 35.145765859000051],
            [-80.972671276999961, 35.145478859000036],
            [-80.973611274999939, 35.143922859000043],
            [-80.973808275999943, 35.143579860000045],
            [-80.974312275999978, 35.142707858000051],
            [-80.974587275999966, 35.142233859000044],
            [-80.974903275999964, 35.141659858000025],
            [-80.975257276999969, 35.141012858000067],
            [-80.975511276999953, 35.140571858000044],
            [-80.975595276999968, 35.140434858000049],
            [-80.975767276999989, 35.140155858000071],
            [-80.975851276999947, 35.140019858000073],
            [-80.975963275999959, 35.139850858000045],
            [-80.97625927699994, 35.139434857000026],
            [-80.976533277999977, 35.139037857000062],
            [-80.976785276999976, 35.138681858000041],
            [-80.977209276999986, 35.138063857000077],
            [-80.977314276999948, 35.137903858000072],
            [-80.977385276999939, 35.137785858000029],
            [-80.977491276999956, 35.137582857000041],
            [-80.977559276999955, 35.13743185800007],
            [-80.977591276999988, 35.137347857000066],
            [-80.977754276999974, 35.136778857000024],
            [-80.977794276999987, 35.136612857000046],
            [-80.977830275999963, 35.136423857000068],
            [-80.976934276999941, 35.136084857000071],
            [-80.975122275999979, 35.135347857000056],
            [-80.974155275999976, 35.134961857000064],
            [-80.973593275999974, 35.134745858000031],
            [-80.972384274999968, 35.134248857000046],
            [-80.971153274999949, 35.13375585700004],
            [-80.970699274999959, 35.133583857000076],
            [-80.970172273999935, 35.133403856000029],
            [-80.969917274999943, 35.133324857000048],
            [-80.96967127399995, 35.133253857000057],
            [-80.969139274999975, 35.133111856000028],
            [-80.968824273999985, 35.133039857000028],
            [-80.968370272999948, 35.132947857000033],
            [-80.967991274999974, 35.13288185600004],
            [-80.967613273999973, 35.132823856000073],
            [-80.967279273999964, 35.132782856000063],
            [-80.966748272999951, 35.132737857000052],
            [-80.966325272999939, 35.132710857000063],
            [-80.965864272999966, 35.132692857000052],
            [-80.964866273999974, 35.132672856000056],
            [-80.964667271999986, 35.130695856000045],
            [-80.964589272999945, 35.129927857000041],
            [-80.964565272999948, 35.129653857000051],
            [-80.964488272999972, 35.128934857000047],
            [-80.964442272999975, 35.128502856000068],
            [-80.964384272999951, 35.128004855000029],
            [-80.964322271999947, 35.127330855000025],
            [-80.96426027199999, 35.126763856000025],
            [-80.964236271999937, 35.126539855000033],
            [-80.964228272999947, 35.12639785500005],
            [-80.964228272999947, 35.126290855000036],
            [-80.964252271999953, 35.126138856000068],
            [-80.964259272999982, 35.126051855000071],
            [-80.964197271999979, 35.125327854000034],
            [-80.964214271999936, 35.125289855000062],
            [-80.964234271999942, 35.125268855000058],
            [-80.964707271999941, 35.124297855000066],
            [-80.962008270999945, 35.124358854000036],
            [-80.959431271999961, 35.124557855000035],
            [-80.95890327099994, 35.124598855000045],
            [-80.95810327099997, 35.124566856000058],
            [-80.958087269999965, 35.124562856000068],
            [-80.957936269999948, 35.124529855000048],
            [-80.957701269999973, 35.123979856000062],
            [-80.957593270999951, 35.123851855000055],
            [-80.957418270999938, 35.123573855000075],
            [-80.957306269999947, 35.123369855000078],
            [-80.956950270999982, 35.122617854000055],
            [-80.95707626899997, 35.122576855000034],
            [-80.957212269999957, 35.122520854000072],
            [-80.957412270999953, 35.122417855000037],
            [-80.95747726999997, 35.122374855000032],
            [-80.957594269999959, 35.122287855000025],
            [-80.95769826999998, 35.122200855000074],
            [-80.957784269999934, 35.122119854000061],
            [-80.957885269999963, 35.122011855000039],
            [-80.95796027099999, 35.12190585500008],
            [-80.958041269999967, 35.121768855000028],
            [-80.958191269999986, 35.121469854000054],
            [-80.958341270999938, 35.121116854000036],
            [-80.958376270999963, 35.12099885400005],
            [-80.958382270999948, 35.120963854000024],
            [-80.958395270999972, 35.120883854000056],
            [-80.958405270999947, 35.120659854000053],
            [-80.95840426999996, 35.12047285400007],
            [-80.958387270999935, 35.120313854000074],
            [-80.958358270999952, 35.120138854000061],
            [-80.958314270999949, 35.119985854000049],
            [-80.958284269999979, 35.119903854000029],
            [-80.95803626999998, 35.11937385400006],
            [-80.957787269999983, 35.118866854000032],
            [-80.957721269999979, 35.118719853000073],
            [-80.957667269999945, 35.118572854000035],
            [-80.957633269999974, 35.118412854000042],
            [-80.957617270999947, 35.118268854000064],
            [-80.95762426999994, 35.118024854000055],
            [-80.957650269999988, 35.117551854000055],
            [-80.957650269999988, 35.117472854000027],
            [-80.957642269999951, 35.117420854000045],
            [-80.957606269999985, 35.117318854000075],
            [-80.95603626999997, 35.118086854000069],
            [-80.955391268999961, 35.118409854000049],
            [-80.954180269999938, 35.119004854000025],
            [-80.95354126999996, 35.119311854000046],
            [-80.95326326899999, 35.119445854000048],
            [-80.952752267999983, 35.119688855000049],
            [-80.95128626799999, 35.120405855000058],
            [-80.950823267999965, 35.120646855000075],
            [-80.950544266999941, 35.120783854000024],
            [-80.94962026799999, 35.121225855000034],
            [-80.949250267999957, 35.121406855000032],
            [-80.948673266999947, 35.121685854000077],
            [-80.94850626799996, 35.121766855000033],
            [-80.947857266999961, 35.122068855000066],
            [-80.94732126699995, 35.122328855000035],
            [-80.946926266999981, 35.122529855000039],
            [-80.946764267999981, 35.122618855000042],
            [-80.946281266999961, 35.122896856000068],
            [-80.945986266999967, 35.123088855000049],
            [-80.945644266999977, 35.123328855000068],
            [-80.945478266999942, 35.123457855000026],
            [-80.945163266999941, 35.123711855000067],
            [-80.945041265999976, 35.123817856000073],
            [-80.944976266999959, 35.123873855000056],
            [-80.944825266999942, 35.124016856000026],
            [-80.944494265999936, 35.12434885600004],
            [-80.944220266999935, 35.124654856000063],
            [-80.944025266999972, 35.124891856000033],
            [-80.943850266999959, 35.125120856000024],
            [-80.943596266999975, 35.125491856000053],
            [-80.943364264999957, 35.125877856000045],
            [-80.943266264999977, 35.12605585600005],
            [-80.943170265999981, 35.126245856000025],
            [-80.943077265999989, 35.126445856000032],
            [-80.942767266999965, 35.127151857000058],
            [-80.942620265999949, 35.127514856000062],
            [-80.942471264999938, 35.127913856000077],
            [-80.942343266999956, 35.128195857000037],
            [-80.942172266999989, 35.128588857000068],
            [-80.941930265999986, 35.129193857000075],
            [-80.941744265999944, 35.12962785700006],
            [-80.942387266999958, 35.129769857000042],
            [-80.942844265999952, 35.129908857000032],
            [-80.943399265999972, 35.130065857000034],
            [-80.943911266999976, 35.130198857000039],
            [-80.944460265999965, 35.130370857000059],
            [-80.94564726699997, 35.130716857000039],
            [-80.94606426699994, 35.130838857000072],
            [-80.946766267999976, 35.131025857000054],
            [-80.946961267999939, 35.131074857000044],
            [-80.94720026799996, 35.131133858000055],
            [-80.947422266999979, 35.131184856000061],
            [-80.947772267999937, 35.131274857000051],
            [-80.948378266999953, 35.131412857000043],
            [-80.949958267999989, 35.131791857000053],
            [-80.950267267999948, 35.131856857000059],
            [-80.950847268999951, 35.131992857000057],
            [-80.951539267999976, 35.132124857000065],
            [-80.952082268999959, 35.132218857000055],
            [-80.952300268999977, 35.132246857000041],
            [-80.952991268999938, 35.132337857000039],
            [-80.953622268999936, 35.13239685700006],
            [-80.95395026999995, 35.132426857000041],
            [-80.954343268999935, 35.132451857000035],
            [-80.954988269999944, 35.132475857000031],
            [-80.955303269999945, 35.132483858000057],
            [-80.956834270999934, 35.132513857000049],
            [-80.956949270999985, 35.132598857000062],
            [-80.957024270999966, 35.132655857000032],
            [-80.957136270999968, 35.132747857000027],
            [-80.957322270999953, 35.132862857000077],
            [-80.957704270999955, 35.133156857000074],
            [-80.957777270999941, 35.133222858000067],
            [-80.957974270999955, 35.133464857000035],
            [-80.958384270999943, 35.133929856000066],
            [-80.958537270999955, 35.13411185700005],
            [-80.958672270999955, 35.134259857000075],
            [-80.958884271999977, 35.134505858000068],
            [-80.958946270999945, 35.134561858000041],
            [-80.959353271999987, 35.135053858000049],
            [-80.959472270999981, 35.135185857000067],
            [-80.959605270999987, 35.135354857000038],
            [-80.959726270999965, 35.135481857000059],
            [-80.959894271999985, 35.13568385700006],
            [-80.959993271999963, 35.135829858000079],
            [-80.960221271999956, 35.136140858000033],
            [-80.96045527199999, 35.136481858000025],
            [-80.960695271999953, 35.136803858000064],
            [-80.960731271999975, 35.136844858000075],
            [-80.960882271999935, 35.137074857000073],
            [-80.960942271999954, 35.137148858000046],
            [-80.961039271999937, 35.137290858000028],
            [-80.96111627199997, 35.137380857000039],
            [-80.961167271999955, 35.137466858000039],
            [-80.961201272999972, 35.137507858000049],
            [-80.961309272999983, 35.137680858000067],
            [-80.961425271999985, 35.137795858000061],
            [-80.961533272999986, 35.137979858000051],
            [-80.961613272999955, 35.138077859000077],
            [-80.961734271999944, 35.138274858000045],
            [-80.96181427199997, 35.138350858000024],
            [-80.961841271999958, 35.138388858000042],
            [-80.96185527199998, 35.138417858000025],
            [-80.961880272999963, 35.13841885800008],
            [-80.961875271999986, 35.138458858000035],
            [-80.961901272999967, 35.138558858000067],
            [-80.961915272999988, 35.138628857000072],
            [-80.961916271999939, 35.138684858000033],
            [-80.961883271999966, 35.138830858000063],
            [-80.961850272999982, 35.139104858000053],
            [-80.96179127299996, 35.13948085800007],
            [-80.961765272999969, 35.139719858000035],
            [-80.961712271999943, 35.139958859000046],
            [-80.961699272999965, 35.140070858000058],
            [-80.961669272999984, 35.140282858000035],
            [-80.961632271999974, 35.140446858000075],
            [-80.961590271999967, 35.140753858000039],
            [-80.961515271999986, 35.141173859000048],
            [-80.961495272999969, 35.141354859000046],
            [-80.961418272999936, 35.141819859000066],
            [-80.961366272999953, 35.142096859000048],
            [-80.961344271999963, 35.142246859000068],
            [-80.961340272999962, 35.142368859000044],
            [-80.961330271999941, 35.142444859000079],
            [-80.961317271999974, 35.142776859000037],
            [-80.961318271999971, 35.142884859000048],
            [-80.961309271999937, 35.143216859000063],
            [-80.961318272999961, 35.143326859000069],
            [-80.961354271999937, 35.143443859000058],
            [-80.961389271999963, 35.143658860000073],
            [-80.961471272999972, 35.143985860000043],
            [-80.961496272999966, 35.144153859000028],
            [-80.961526272999947, 35.14427586000005],
            [-80.961572272999945, 35.144412859000056],
            [-80.961601272999985, 35.144549860000041],
            [-80.961610272999962, 35.144616859000053],
            [-80.963090272999978, 35.144432859000062],
            [-80.96322827399996, 35.14441486000004],
            [-80.96341227299996, 35.144401859000027],
            [-80.963517272999979, 35.144410859000061],
            [-80.963577272999942, 35.14442585900008],
            [-80.963728272999958, 35.144493860000068],
            [-80.964522272999943, 35.144922860000065],
            [-80.964772273999984, 35.145040860000051],
            [-80.964854273999947, 35.145068859000048],
            [-80.965097273999959, 35.145139860000029],
            [-80.965810272999988, 35.14535685900006],
            [-80.965932273999954, 35.145400859000063],
            [-80.966003273999945, 35.145436859000029],
            [-80.966083272999981, 35.145504860000074],
            [-80.966130273999966, 35.145563859000049],
            [-80.966165273999934, 35.145623860000057],
            [-80.966185272999951, 35.145706859000029],
            [-80.966219273999968, 35.146105859000045],
            [-80.966813273999946, 35.146085859000038],
            [-80.967293273999985, 35.146069860000068],
            [-80.967424274999985, 35.146060859000045],
            [-80.967478274999962, 35.146946860000071],
            [-80.968446273999973, 35.146917859000041],
            [-80.968547274999935, 35.14692485900008]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 7,
        "SolutionID": "PI-5",
        "Shape_Length": 0.11544154739262291,
        "Shape_Area": 0.00040404447494354217
      }
    },
    {
      "type": "Feature",
      "id": 8,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.923646260999988, 35.131746858000042],
            [-80.92366526099994, 35.131771858000036],
            [-80.924902260999943, 35.133981858000027],
            [-80.931495262999988, 35.131622858000071],
            [-80.932444262999979, 35.131290857000067],
            [-80.932472263999955, 35.131293858000049],
            [-80.932512262999978, 35.131288857000072],
            [-80.932590263999941, 35.131271858000048],
            [-80.932688262999989, 35.131239857000025],
            [-80.933197263999944, 35.131054857000038],
            [-80.933495262999941, 35.130947857000024],
            [-80.934107262999987, 35.130735857000047],
            [-80.934245263999969, 35.130684857000062],
            [-80.934758263999981, 35.13049785700008],
            [-80.934945263999964, 35.13042985800007],
            [-80.935633264999979, 35.130168857000058],
            [-80.935803263999958, 35.130105856000057],
            [-80.936413263999953, 35.129883857000038],
            [-80.937749264999979, 35.129412857000034],
            [-80.937826264999956, 35.12937585700007],
            [-80.937934264999967, 35.129332857000065],
            [-80.938047264999966, 35.129265858000053],
            [-80.93824026499999, 35.129136857000049],
            [-80.938437264999948, 35.128976857000055],
            [-80.938153264999983, 35.128825857000038],
            [-80.937805264999952, 35.128614857000059],
            [-80.937542263999944, 35.128392857000051],
            [-80.937221263999959, 35.128034857000046],
            [-80.93663926399995, 35.127006856000037],
            [-80.935562264999987, 35.125194856000064],
            [-80.935159262999946, 35.124411856000052],
            [-80.934684263999941, 35.123579856000049],
            [-80.934280262999948, 35.122775855000043],
            [-80.934108263999974, 35.122477856000046],
            [-80.933976263999966, 35.12226585600007],
            [-80.933730261999983, 35.121810856000025],
            [-80.933993262999934, 35.121741855000039],
            [-80.934165262999954, 35.121678856000074],
            [-80.935333262999961, 35.121248856000079],
            [-80.941847264999979, 35.118854854000062],
            [-80.941764264999961, 35.118734855000071],
            [-80.941710264999983, 35.118647854000073],
            [-80.941556264999974, 35.118363855000041],
            [-80.941297264999946, 35.117869854000048],
            [-80.941108264999968, 35.11750885400005],
            [-80.940951264999967, 35.117228855000064],
            [-80.940220264999937, 35.115888854000048],
            [-80.940157264999982, 35.115772854000056],
            [-80.93932926399998, 35.114253853000037],
            [-80.939154263999967, 35.113942853000026],
            [-80.939014263999979, 35.113720854000064],
            [-80.938920263999989, 35.11358785300007],
            [-80.938803262999954, 35.113438853000048],
            [-80.938665262999962, 35.113293854000062],
            [-80.93859826399995, 35.113224853000077],
            [-80.93836126399998, 35.113020854000069],
            [-80.93826026399995, 35.112942854000039],
            [-80.938244263999934, 35.112929853000026],
            [-80.938116264999962, 35.112837854000077],
            [-80.937846263999973, 35.112666853000064],
            [-80.937436262999938, 35.112454854000077],
            [-80.937260263999974, 35.11237585300006],
            [-80.937089263999951, 35.112299854000071],
            [-80.935660261999942, 35.111691854000071],
            [-80.934851262999985, 35.11134385400004],
            [-80.934594262999951, 35.111233853000044],
            [-80.932858261999968, 35.110474853000028],
            [-80.932829262999974, 35.110464854000043],
            [-80.932793261999961, 35.110452853000027],
            [-80.932747261999964, 35.110448852000047],
            [-80.932691261999935, 35.110459853000066],
            [-80.929617260999976, 35.109115853000048],
            [-80.928808260999972, 35.108761853000033],
            [-80.928727260999949, 35.108693852000044],
            [-80.928540260999966, 35.108617853000055],
            [-80.928455260999954, 35.108583852000038],
            [-80.928914259999942, 35.107856853000044],
            [-80.930094261999955, 35.10598585200006],
            [-80.930261260999941, 35.105685852000079],
            [-80.930326261999937, 35.105568852000033],
            [-80.930769260999966, 35.10464685200003],
            [-80.93116226099994, 35.10367085200005],
            [-80.931237261999968, 35.103429852000033],
            [-80.931147261999968, 35.103332851000062],
            [-80.931062261999955, 35.103240852000056],
            [-80.930731260999949, 35.102882851000061],
            [-80.930314260999978, 35.10243285100006],
            [-80.92967226199994, 35.101740851000045],
            [-80.929030259999934, 35.101047852000079],
            [-80.926643259999935, 35.098469851000061],
            [-80.924256258999947, 35.095891850000044],
            [-80.922456258999944, 35.093947850000063],
            [-80.920656257999951, 35.092003849000037],
            [-80.92030325799999, 35.091622850000078],
            [-80.919962256999952, 35.091253850000044],
            [-80.91995125699998, 35.091241850000074],
            [-80.919920257999934, 35.091386850000049],
            [-80.919684256999972, 35.092130850000046],
            [-80.918915256999981, 35.094588851000026],
            [-80.918572257999983, 35.095665850000046],
            [-80.918521256999952, 35.095826850000037],
            [-80.917484256999956, 35.099089851000031],
            [-80.917289256999936, 35.099713851000047],
            [-80.916425257999947, 35.10247785100006],
            [-80.916119255999945, 35.103436852000073],
            [-80.91548925699999, 35.105371852000076],
            [-80.91540325699998, 35.105667853000057],
            [-80.915363255999978, 35.105868853000061],
            [-80.915352256999938, 35.105988852000053],
            [-80.915348256999948, 35.106092853000064],
            [-80.915351256999941, 35.106199853000078],
            [-80.915364256999965, 35.106380853000076],
            [-80.915385255999979, 35.10651585200003],
            [-80.915574255999957, 35.107476853000037],
            [-80.915752255999962, 35.108379853000031],
            [-80.915883256999962, 35.109013854000068],
            [-80.91600425699994, 35.109603854000056],
            [-80.916035257999965, 35.109822854000072],
            [-80.916049256999941, 35.110027853000076],
            [-80.916029256999934, 35.110250854000071],
            [-80.915982257999985, 35.110437854000054],
            [-80.915907256999958, 35.110632854000073],
            [-80.915704256999959, 35.110973854000065],
            [-80.915516256999979, 35.111223855000048],
            [-80.915482256999951, 35.111256854000032],
            [-80.915296256999966, 35.111454854000044],
            [-80.915076256999953, 35.111700854000048],
            [-80.914190255999983, 35.112602854000045],
            [-80.913746256999957, 35.113066854000067],
            [-80.91359825699999, 35.113220855000066],
            [-80.913271256999963, 35.113542855000048],
            [-80.913176255999986, 35.113625855000066],
            [-80.913098256999945, 35.113687854000034],
            [-80.91291225599997, 35.113824855000075],
            [-80.912586256999987, 35.114026855000077],
            [-80.911715255999979, 35.114568855000073],
            [-80.911091255999963, 35.114961855000047],
            [-80.910311254999954, 35.115445854000029],
            [-80.909690254999987, 35.115818855000043],
            [-80.909524255999941, 35.115924856000049],
            [-80.909151254999983, 35.116153856000039],
            [-80.908866254999964, 35.116317855000034],
            [-80.908463254999958, 35.116529855000067],
            [-80.907035254999982, 35.117220855000028],
            [-80.906703253999979, 35.117386856000053],
            [-80.905617254999981, 35.117915856000025],
            [-80.90499125499997, 35.118237856000064],
            [-80.904716253999936, 35.118429856000034],
            [-80.905421254999965, 35.119085857000073],
            [-80.90582825499996, 35.119435857000042],
            [-80.90633825499998, 35.119848856000033],
            [-80.90698325599999, 35.120324856000025],
            [-80.907655255999941, 35.120774856000025],
            [-80.90843225499998, 35.121234856000058],
            [-80.909014255999978, 35.121550856000056],
            [-80.909575255999982, 35.121834857000067],
            [-80.910624255999949, 35.122311856000067],
            [-80.910743255999989, 35.122359857000049],
            [-80.912190256999963, 35.122946857000045],
            [-80.912652256999934, 35.123140857000067],
            [-80.912946256999987, 35.123229857000069],
            [-80.913651257999959, 35.123467856000048],
            [-80.914443256999959, 35.123752857000056],
            [-80.914511256999958, 35.123776857000053],
            [-80.91474625799998, 35.123870856000053],
            [-80.91567325699998, 35.124277857000038],
            [-80.916311257999951, 35.124588857000049],
            [-80.916484258999958, 35.124680857000044],
            [-80.916784256999961, 35.124850857000069],
            [-80.916985257999954, 35.124970857000051],
            [-80.917416258999936, 35.125220857000045],
            [-80.917520258999957, 35.125269857000035],
            [-80.917800258999989, 35.12544385700005],
            [-80.918147258999966, 35.125668857000051],
            [-80.918252257999939, 35.125735857000052],
            [-80.918503258999976, 35.125896857000043],
            [-80.918890258999966, 35.126162857000054],
            [-80.919495258999973, 35.126628857000071],
            [-80.920117258999937, 35.127157858000032],
            [-80.920521258999941, 35.12752185800008],
            [-80.920825258999969, 35.12781185700004],
            [-80.921142259999954, 35.12812685800003],
            [-80.921465258999945, 35.128477858000053],
            [-80.921740259999979, 35.128813858000058],
            [-80.92224325899997, 35.129441858000064],
            [-80.922525259999986, 35.129843858000072],
            [-80.922866260999967, 35.130374858000039],
            [-80.923291259999985, 35.131101857000033],
            [-80.923646260999988, 35.131746858000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 8,
        "SolutionID": "PI-5",
        "Shape_Length": 0.13221896471737354,
        "Shape_Area": 0.00069486008436230083
      }
    },
    {
      "type": "Feature",
      "id": 9,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820207235999987, 35.235051884000029],
            [-80.820423235999954, 35.235209884000028],
            [-80.820613234999939, 35.235340883000049],
            [-80.820800234999979, 35.235468884000056],
            [-80.821207234999974, 35.235756884000068],
            [-80.821687235999946, 35.236096884000062],
            [-80.821797235999952, 35.236164884000061],
            [-80.821916236999982, 35.236223884000026],
            [-80.822071235999942, 35.23628188400005],
            [-80.822152235999965, 35.236304885000038],
            [-80.822284235999973, 35.236335884000027],
            [-80.822532235999972, 35.236382885000069],
            [-80.822762236999949, 35.236408883000024],
            [-80.822903235999945, 35.236414884000055],
            [-80.823018236999985, 35.23641288400006],
            [-80.823187235999967, 35.236393883000062],
            [-80.823345236999955, 35.236364884000068],
            [-80.82358723699997, 35.236311884000031],
            [-80.823754235999957, 35.236259884000049],
            [-80.823834236999971, 35.236227884000073],
            [-80.823911236999948, 35.23619188400005],
            [-80.824109236999959, 35.236082884000041],
            [-80.824203236999949, 35.236022883000032],
            [-80.824335236999957, 35.235929884000029],
            [-80.824582236999959, 35.235751884000024],
            [-80.824886236999987, 35.235520884000039],
            [-80.825132236999934, 35.235329884000066],
            [-80.825887236999961, 35.23474288400007],
            [-80.825968236999984, 35.234681884000054],
            [-80.826137236999955, 35.234555884000031],
            [-80.826164236999944, 35.234573884000042],
            [-80.826412236999943, 35.234735883000042],
            [-80.827339237999979, 35.23539188400008],
            [-80.829496236999944, 35.233748884000079],
            [-80.830596237999941, 35.232948883000063],
            [-80.831796238999971, 35.23194888300003],
            [-80.832330238999987, 35.231607883000038],
            [-80.832383237999977, 35.231570883000074],
            [-80.832780238999987, 35.231288882000058],
            [-80.831964238999944, 35.230583883000065],
            [-80.831765237999946, 35.23040188300007],
            [-80.831559238999944, 35.230214882000041],
            [-80.831294237999941, 35.229988883000033],
            [-80.831021238999938, 35.229749883000068],
            [-80.830819237999947, 35.22957288200007],
            [-80.830536238999969, 35.229324883000061],
            [-80.830048237999961, 35.228896882000072],
            [-80.829850237999949, 35.228722883000046],
            [-80.829527237999969, 35.228440882000029],
            [-80.829366237999977, 35.228299882000044],
            [-80.829025236999939, 35.227984882000044],
            [-80.828858236999963, 35.227808882000033],
            [-80.828780236999989, 35.227716882000038],
            [-80.828624237999975, 35.227508882000052],
            [-80.82850323699995, 35.227316882000025],
            [-80.82845023699997, 35.227218882000045],
            [-80.82832323699995, 35.226941882000062],
            [-80.828234237999936, 35.226735882000071],
            [-80.828187236999952, 35.226579882000067],
            [-80.828171236999935, 35.226500883000028],
            [-80.828147236999939, 35.226231882000036],
            [-80.828140236999957, 35.225977881000063],
            [-80.828153236999981, 35.225749882000059],
            [-80.828163237999945, 35.225675882000075],
            [-80.827807237999934, 35.226526881000041],
            [-80.827717237999934, 35.226740881000069],
            [-80.827630237999983, 35.226899882000055],
            [-80.827606235999951, 35.226939882000067],
            [-80.827494237999986, 35.227093882000077],
            [-80.82740623799998, 35.227198882000039],
            [-80.827363236999986, 35.22726988200003],
            [-80.827350236999962, 35.227305881000063],
            [-80.827337236999938, 35.227324882000062],
            [-80.827311236999947, 35.227352882000048],
            [-80.82728723799994, 35.227366882000069],
            [-80.827253236999979, 35.227376882000044],
            [-80.82714823699996, 35.227393882000058],
            [-80.82696823699996, 35.227409882000075],
            [-80.826657236999949, 35.227415882000059],
            [-80.826341236999951, 35.227401882000038],
            [-80.82623323699994, 35.227406882000025],
            [-80.825913236999952, 35.227436883000053],
            [-80.825465236999946, 35.227514883000026],
            [-80.825297236999972, 35.227570883000055],
            [-80.825207236999972, 35.227620882000053],
            [-80.825107235999951, 35.227687882000055],
            [-80.82502423699998, 35.227762882000036],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 9,
        "SolutionID": "PI-6",
        "Shape_Length": 0.038499905378580911,
        "Shape_Area": 6.3360392433037783e-5
      }
    },
    {
      "type": "Feature",
      "id": 10,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.981352773999959, 34.870199636000052],
            [-80.980594408999934, 34.870235815000058],
            [-80.979367160999971, 34.870305354000038],
            [-80.978465792999941, 34.870362659000079],
            [-80.977562301999967, 34.870429175000027],
            [-80.977053111999965, 34.87045385600004],
            [-80.976352650999956, 34.870547681000062],
            [-80.974530791999939, 34.870643324000071],
            [-80.972819634999951, 34.870869817000028],
            [-80.97153293599996, 34.871074236000027],
            [-80.970046537999963, 34.871342885000047],
            [-80.968570118999935, 34.871646712000029],
            [-80.967104946999939, 34.871985457000051],
            [-80.965652232999958, 34.872358839000071],
            [-80.963993929999958, 34.87283044600008],
            [-80.962788907999936, 34.873208262000048],
            [-80.96138068199997, 34.873683598000071],
            [-80.959989623999945, 34.874192175000076],
            [-80.958616933999963, 34.874733554000045],
            [-80.957263736999948, 34.87530729000008],
            [-80.955710193999948, 34.876015617000064],
            [-80.954620248999959, 34.876549921000048],
            [-80.953332158999956, 34.877217783000049],
            [-80.952067935999935, 34.877915945000041],
            [-80.950828630999979, 34.878643830000044],
            [-80.949615260999963, 34.879400840000073],
            [-80.948216426999977, 34.880329998000036],
            [-80.947270412999956, 34.880999648000056],
            [-80.946140849999949, 34.88184013800003],
            [-80.945041119999985, 34.882707099000072],
            [-80.943972149999979, 34.883599801000059],
            [-80.942934820999938, 34.884517508000044],
            [-80.941929993999963, 34.885459458000071],
            [-80.941544186999977, 34.885829398000055],
            [-80.940958508999984, 34.886424867000073],
            [-80.940021164999962, 34.887412939000058],
            [-80.939118744999973, 34.888422852000076],
            [-80.938251997999942, 34.889453769000056],
            [-80.937421644999972, 34.89050483300008],
            [-80.936628377999966, 34.891575171000056],
            [-80.936304328999938, 34.892024435000053],
            [-80.935872849999953, 34.892663902000038],
            [-80.935155702999964, 34.893770108000069],
            [-80.934492913999975, 34.894865498000058],
            [-80.933883165999987, 34.895948450000049],
            [-80.933515743999976, 34.896630172000073],
            [-80.933257118999961, 34.89712445400005],
            [-80.933036662999939, 34.89754801600003],
            [-80.932860120999976, 34.89788855300003],
            [-80.932635523999977, 34.898320237000064],
            [-80.932260199999973, 34.899043293000034],
            [-80.931938142999968, 34.899662294000052],
            [-80.931662436999943, 34.90019691100008],
            [-80.931514299999947, 34.900480383000058],
            [-80.931382024999948, 34.900736166000058],
            [-80.931178597999974, 34.901126165000051],
            [-80.930759710999951, 34.901938176000044],
            [-80.930294301999936, 34.902878499000053],
            [-80.930017452999948, 34.90344895700008],
            [-80.929590565999945, 34.904274379000071],
            [-80.929054753999935, 34.905308121000076],
            [-80.928697249999971, 34.905992618000028],
            [-80.928253592999965, 34.906854917000032],
            [-80.92796696299996, 34.907405806000043],
            [-80.927521462999948, 34.908252115000039],
            [-80.927232190999973, 34.908811613000069],
            [-80.926993098999958, 34.909278310000047],
            [-80.926507825999977, 34.910210878000044],
            [-80.926059291999934, 34.911075652000079],
            [-80.925702222999973, 34.911762779000071],
            [-80.925242523999941, 34.912646676000065],
            [-80.924856254999952, 34.913388394000037],
            [-80.924450440999976, 34.914168532000076],
            [-80.92398473999998, 34.915065245000051],
            [-80.923447092999936, 34.916097637000064],
            [-80.92314098899999, 34.916694171000074],
            [-80.922783785999968, 34.917400345000033],
            [-80.922260603999973, 34.918485410000073],
            [-80.921876229999953, 34.919327266000039],
            [-80.921505054999955, 34.920198071000073],
            [-80.921029268999973, 34.921389380000051],
            [-80.920734100999937, 34.922177158000068],
            [-80.920519271999979, 34.922791025000038],
            [-80.920386701999973, 34.923181854000063],
            [-80.920256293999955, 34.923582074000024],
            [-80.92000474799994, 34.924384501000077],
            [-80.919843484999944, 34.924925639000037],
            [-80.919661700999939, 34.925583367000058],
            [-80.919403944999942, 34.926584303000027],
            [-80.919241641999974, 34.927264711000078],
            [-80.919124014999966, 34.92779762400005],
            [-80.919009215999949, 34.928356093000048],
            [-80.918889771999943, 34.928994763000048],
            [-80.918725182999935, 34.929966361000027],
            [-80.918632729999956, 34.930573540000069],
            [-80.918569329999968, 34.931036023000047],
            [-80.918499721999979, 34.931613635000076],
            [-80.918377081999949, 34.932790858000033],
            [-80.918314296999938, 34.933548552000047],
            [-80.918288759999939, 34.933969601000058],
            [-80.918249954999965, 34.934762127000056],
            [-80.918216596999969, 34.93590795700004],
            [-80.918211563999989, 34.936526364000031],
            [-80.918227774999934, 34.937485930000037],
            [-80.918250343999944, 34.938138510000044],
            [-80.918288872999938, 34.938905911000063],
            [-80.918324278999989, 34.939443378000078],
            [-80.918387516999985, 34.940176504000078],
            [-80.918498104999969, 34.941225271000064],
            [-80.918561101999956, 34.941749093000055],
            [-80.918615808999959, 34.942153305000033],
            [-80.918714138999974, 34.942799390000062],
            [-80.918897804999972, 34.94387649500004],
            [-80.918997815999944, 34.944414106000067],
            [-80.919109456999934, 34.94496403200003],
            [-80.91928601099994, 34.945748485000024],
            [-80.919597129999943, 34.947002123000061],
            [-80.919770232999952, 34.947649223000042],
            [-80.919867370999953, 34.947986813000057],
            [-80.920076006999977, 34.948686340000052],
            [-80.920251261999965, 34.949238394000076],
            [-80.920520453999984, 34.950044794000064],
            [-80.920786876999955, 34.950794471000052],
            [-80.92102050699998, 34.95141505600003],
            [-80.921194975999981, 34.951863307000053],
            [-80.92138276899999, 34.952333098000054],
            [-80.92150535899998, 34.952631730000064],
            [-80.921778011999947, 34.953274135000072],
            [-80.922033302999978, 34.953864254000052],
            [-80.922388385999966, 34.954691646000072],
            [-80.922672409999961, 34.955357236000054],
            [-80.922990439999978, 34.956094636000046],
            [-80.923231093999959, 34.956645913000045],
            [-80.923423876999948, 34.957100909000076],
            [-80.923553211999945, 34.957399046000035],
            [-80.923705148999943, 34.957756346000053],
            [-80.923902229999953, 34.958209941000064],
            [-80.924228640999956, 34.958977253000057],
            [-80.924491972999988, 34.959585812000057],
            [-80.92473954999997, 34.960152657000037],
            [-80.924985087999971, 34.960724126000059],
            [-80.925169035999943, 34.96114863400004],
            [-80.925358021999955, 34.961586338000075],
            [-80.925613268999939, 34.962180400000079],
            [-80.926031087999945, 34.963149774000044],
            [-80.926334831999952, 34.963849768000046],
            [-80.92665696399996, 34.964608483000063],
            [-80.926884403999964, 34.96514387700006],
            [-80.927129358999935, 34.965701050000064],
            [-80.927405796999949, 34.96634748200006],
            [-80.92774478299998, 34.96714100500003],
            [-80.928082323999945, 34.96793120600006],
            [-80.928339813999969, 34.968529039000032],
            [-80.928547699999967, 34.969012176000035],
            [-80.928966564999939, 34.969993392000049],
            [-80.929198434999989, 34.970527663000041],
            [-80.929594904999988, 34.971449482000025],
            [-80.929795934999959, 34.971925296000052],
            [-80.93008733299996, 34.972614667000073],
            [-80.930383595999956, 34.97329312200003],
            [-80.930731121999941, 34.974117534000072],
            [-80.931133428999942, 34.975165154000024],
            [-80.930639901999939, 34.975823310000067],
            [-80.930326964999949, 34.976287537000076],
            [-80.930038757999966, 34.976711247000026],
            [-80.929434653999976, 34.977598680000028],
            [-80.929144373999975, 34.978031122000061],
            [-80.928807867999978, 34.978517046000036],
            [-80.928511354999955, 34.978950184000041],
            [-80.927960623999979, 34.979769081000029],
            [-80.927709689999972, 34.980146194000042],
            [-80.92713753999999, 34.98099611400005],
            [-80.926855972999988, 34.981425665000074],
            [-80.926325590999966, 34.982471942000075],
            [-80.925981432999947, 34.982942598000079],
            [-80.925655432999974, 34.983409154000071],
            [-80.925162674999967, 34.984243520000064],
            [-80.924607975999947, 34.985191687000054],
            [-80.924404488999983, 34.98555977500007],
            [-80.924208345999944, 34.985930554000049],
            [-80.924019600999941, 34.986303925000072],
            [-80.923838302999968, 34.98667978800006],
            [-80.923664500999962, 34.987058042000058],
            [-80.923498242999983, 34.987438585000064],
            [-80.923339572999964, 34.987821317000055],
            [-80.923188532999973, 34.988206134000052],
            [-80.922677764999946, 34.988991798000029],
            [-80.922489266999946, 34.989407205000077],
            [-80.92224534099995, 34.989955331000033],
            [-80.922080523999966, 34.990334450000034],
            [-80.921824636999986, 34.990942071000063],
            [-80.921520278999935, 34.991690110000036],
            [-80.921295864999934, 34.992260632000068],
            [-80.92115780599994, 34.992625372000077],
            [-80.920954248999976, 34.99316458900006],
            [-80.920739527999956, 34.993758283000034],
            [-80.920576807999964, 34.994223247000036],
            [-80.920346638999945, 34.994902737000075],
            [-80.920057512999961, 34.995801632000052],
            [-80.91983171399994, 34.996543493000047],
            [-80.919692063999946, 34.997016554000027],
            [-80.919586849999973, 34.997385188000067],
            [-80.919401289999939, 34.998069961000056],
            [-80.919222458999968, 34.99876254600008],
            [-80.919069228999945, 34.999387780000063],
            [-80.918903143999955, 35.000104423000039],
            [-80.918680105999954, 35.001126957000054],
            [-80.918566599999963, 35.001676992000057],
            [-80.918400151999947, 35.002523384000028],
            [-80.918262004999974, 35.003200975000027],
            [-80.918181446999938, 35.003600548000065],
            [-80.918018871999948, 35.004408010000077],
            [-80.91782152199994, 35.005361009000069],
            [-80.917613674999984, 35.006392078000033],
            [-80.917506508999963, 35.006909386000075],
            [-80.917402325999944, 35.007429349000063],
            [-80.917291449999937, 35.007966857000042],
            [-80.91715643799995, 35.008620551000035],
            [-80.91707650099994, 35.009016342000052],
            [-80.916974024999945, 35.009373958000026],
            [-80.916631535999954, 35.009879193000074],
            [-80.916301351999948, 35.010366306000037],
            [-80.915679956999952, 35.01131128600008],
            [-80.915376220999974, 35.011787130000073],
            [-80.914931374999981, 35.012506475000066],
            [-80.914665272999969, 35.012946294000074],
            [-80.91437024399994, 35.013447867000025],
            [-80.913952001999974, 35.014179003000038],
            [-80.913445820999982, 35.015099685000052],
            [-80.913159726999936, 35.015641499000026],
            [-80.912826719999941, 35.016293904000065],
            [-80.912545398999953, 35.01685924800006],
            [-80.912223005999977, 35.017527410000071],
            [-80.911839933999943, 35.018353149000063],
            [-80.911662249999949, 35.018749787000047],
            [-80.911302576999958, 35.019582771000046],
            [-80.91087952099997, 35.020611919000032],
            [-80.910544254999934, 35.021471443000053],
            [-80.910265826999989, 35.022220033000053],
            [-80.909990518999962, 35.023006420000058],
            [-80.909643181999968, 35.024053544000026],
            [-80.909347594999986, 35.025000937000073],
            [-80.909150936999936, 35.025681533000068],
            [-80.908950230999949, 35.026434433000077],
            [-80.908705877999978, 35.027408180000066],
            [-80.908540181999967, 35.028108616000054],
            [-80.90838410799995, 35.028808131000062],
            [-80.908268623999959, 35.02936690100006],
            [-80.908084154999983, 35.030345767000028],
            [-80.907956666999951, 35.031093742000053],
            [-80.907871850999982, 35.031631148000031],
            [-80.907737059999988, 35.03254706000007],
            [-80.907619015999956, 35.033404683000072],
            [-80.907521637999935, 35.034122045000061],
            [-80.907382233999954, 35.03519417800004],
            [-80.907332286999974, 35.035570793000034],
            [-80.90723337299994, 35.036323117000052],
            [-80.907147926999983, 35.03704216400007],
            [-80.907070558999976, 35.03759941800007],
            [-80.906978244999948, 35.038260705000027],
            [-80.90689297199998, 35.038832391000028],
            [-80.906827045999989, 35.039303867000058],
            [-80.906649930999947, 35.040666679000026],
            [-80.906522393999978, 35.041607608000049],
            [-80.906306088999941, 35.043217367000068],
            [-80.906232726999974, 35.043796593000025],
            [-80.906170094999936, 35.044231448000062],
            [-80.906036341999936, 35.045215645000042],
            [-80.905315738999946, 35.046149867000054],
            [-80.904856094999957, 35.046759683000062],
            [-80.903761956999972, 35.048246255000038],
            [-80.903378773999975, 35.048767303000034],
            [-80.901839088999964, 35.050835457000062],
            [-80.900325136999982, 35.052868923000062],
            [-80.899275694999972, 35.054255586000068],
            [-80.898905622999962, 35.054744562000053],
            [-80.898341092999942, 35.05549374900005],
            [-80.89762196099997, 35.056468944000073],
            [-80.897075757999971, 35.057211926000036],
            [-80.896739943999989, 35.057649878000063],
            [-80.896322322999936, 35.058199135000052],
            [-80.89578628299995, 35.058922191000079],
            [-80.895320846999937, 35.059524613000065],
            [-80.894644586999959, 35.06041739300008],
            [-80.894325354999978, 35.060846339000079],
            [-80.893298631999983, 35.062249447000056],
            [-80.892063503999964, 35.063857178000035],
            [-80.89100956599998, 35.065264009000032],
            [-80.889827471999979, 35.066872989000046],
            [-80.889222759999939, 35.06768407800007],
            [-80.888667040999962, 35.068439874000035],
            [-80.888111820999939, 35.069217045000073],
            [-80.887545087999968, 35.070034784000029],
            [-80.887194120999936, 35.070558629000061],
            [-80.886509817999979, 35.071614050000051],
            [-80.885998229999984, 35.072445479000066],
            [-80.885561008999957, 35.073198447000038],
            [-80.885288512999978, 35.07368187700007],
            [-80.885002258999975, 35.07419881800007],
            [-80.884646414999963, 35.074858445000075],
            [-80.884286517999954, 35.075564385000064],
            [-80.883789906999937, 35.076588646000062],
            [-80.883549495999944, 35.077103350000073],
            [-80.88336557599996, 35.077513586000066],
            [-80.883236991999979, 35.077805814000044],
            [-80.883093164999934, 35.078140251000036],
            [-80.882950585999936, 35.078477376000023],
            [-80.882763359999956, 35.078937490000044],
            [-80.882401422999976, 35.079861054000048],
            [-80.882147079999982, 35.080548775000068],
            [-80.881952405999982, 35.081110471000045],
            [-80.881661215999941, 35.081986879000056],
            [-80.881363309999983, 35.082939506000059],
            [-80.881180756999981, 35.083566273000031],
            [-80.88101484699996, 35.084178924000071],
            [-80.880791614999964, 35.085047862000067],
            [-80.880563668999969, 35.085982225000066],
            [-80.880396215999951, 35.086713638000049],
            [-80.880292201999964, 35.087212813000065],
            [-80.88012133899997, 35.088107042000047],
            [-80.880025655999987, 35.088654201000054],
            [-80.879830052999978, 35.089724897000053],
            [-80.879648040999939, 35.09081438000004],
            [-80.879072411999971, 35.091767796000056],
            [-80.878323677999958, 35.092861615000061],
            [-80.877877112999954, 35.09347032200003],
            [-80.877542571999982, 35.093926361000058],
            [-80.877010179999957, 35.094651947000045],
            [-80.87647553499994, 35.095366618000071],
            [-80.875836380999942, 35.096248479000053],
            [-80.875324872999954, 35.096997659000067],
            [-80.87460521099996, 35.098103391000052],
            [-80.874151732999962, 35.098832819000052],
            [-80.873840555999948, 35.099357123000061],
            [-80.873421839999935, 35.100067248000073],
            [-80.872845609999956, 35.101085028000057],
            [-80.872451524999974, 35.101808697000024],
            [-80.872255428999949, 35.102166360000069],
            [-80.872054504999937, 35.102535463000038],
            [-80.87184879199998, 35.102911520000077],
            [-80.871507225999949, 35.10355136700008],
            [-80.871242093999967, 35.103970600000025],
            [-80.870878865999941, 35.104510657000048],
            [-80.870147876999965, 35.105636335000042],
            [-80.869676538999954, 35.106319244000076],
            [-80.869417772999952, 35.106686525000043],
            [-80.868860377999965, 35.107490502000076],
            [-80.868396659999974, 35.108179133000078],
            [-80.868170006999947, 35.108514887000069],
            [-80.86720584599999, 35.109478153000055],
            [-80.865950716999976, 35.110640373000024],
            [-80.865090129999942, 35.111441494000076],
            [-80.864321056999984, 35.112148381000054],
            [-80.863919733999978, 35.112522718000037],
            [-80.863432147999958, 35.112984095000058],
            [-80.863132878999977, 35.113262832000032],
            [-80.862679327999956, 35.113672060000056],
            [-80.862156148999986, 35.114149518000033],
            [-80.861481322999964, 35.11477996800005],
            [-80.861073215999966, 35.115151788000048],
            [-80.860631806999947, 35.115552340000079],
            [-80.860086739999986, 35.116055842000037],
            [-80.859461710999938, 35.116643174000046],
            [-80.858941303999984, 35.117143273000067],
            [-80.858489296999949, 35.117553673000032],
            [-80.857990787999938, 35.118014154000036],
            [-80.857121456999948, 35.118832242000053],
            [-80.85648333499995, 35.119436567000037],
            [-80.855975635999982, 35.119916302000036],
            [-80.855037548999974, 35.120800744000064],
            [-80.854389836999985, 35.121451800000045],
            [-80.853554033999956, 35.122322573000076],
            [-80.852921905999949, 35.12300547500007],
            [-80.852617270999986, 35.123342895000064],
            [-80.851988848999952, 35.124057958000037],
            [-80.851303211999948, 35.124865740000075],
            [-80.851003184999968, 35.125229268000055],
            [-80.850547748999986, 35.125794337000059],
            [-80.850128583999947, 35.126330100000075],
            [-80.849607749999961, 35.127015785000026],
            [-80.849351215999945, 35.127360600000031],
            [-80.849123003999978, 35.127674811000077],
            [-80.848771485999976, 35.128167182000027],
            [-80.848519440999951, 35.128530542000078],
            [-80.848132174999989, 35.129103344000043],
            [-80.847792830999936, 35.129619889000026],
            [-80.84727892799998, 35.130428707000078],
            [-80.846946558999946, 35.130972395000072],
            [-80.846624060999943, 35.13152040500006],
            [-80.846215540999935, 35.132235173000026],
            [-80.845734902999936, 35.133109018000027],
            [-80.845447724999985, 35.133653308000078],
            [-80.845202602999962, 35.134137113000065],
            [-80.844855597999981, 35.134843077000028],
            [-80.844323758999963, 35.135979399000064],
            [-80.84409371199996, 35.136501890000034],
            [-80.843784526999968, 35.137239557000044],
            [-80.843495503999975, 35.137959223000053],
            [-80.843207043999939, 35.138710150000065],
            [-80.842963357999963, 35.139385825000033],
            [-80.842708597999945, 35.140136635000033],
            [-80.842552190999982, 35.140617801000076],
            [-80.842416535999973, 35.141030985000043],
            [-80.842286920999982, 35.141385124000067],
            [-80.842131219999942, 35.141814478000072],
            [-80.841946284999949, 35.142335819000039],
            [-80.841672675999973, 35.143077125000048],
            [-80.841525004999937, 35.143488953000031],
            [-80.841238100999988, 35.144308735000038],
            [-80.841081948999943, 35.144767146000049],
            [-80.840858266999987, 35.145432956000036],
            [-80.840596888999983, 35.146168388000035],
            [-80.840241463999973, 35.147189336000054],
            [-80.840007068999967, 35.147852913000065],
            [-80.839708500999961, 35.148738939000054],
            [-80.83947995699998, 35.149401675000036],
            [-80.839313893999986, 35.149864879000063],
            [-80.839117293999948, 35.150425860000041],
            [-80.838858157999937, 35.151181382000061],
            [-80.838620795999987, 35.151908215000049],
            [-80.838316655999961, 35.152897470000028],
            [-80.837594828999954, 35.155406477000042],
            [-80.837405955999941, 35.156035017000079],
            [-80.837201289999939, 35.156706177000046],
            [-80.83704559499995, 35.157247344000041],
            [-80.836578021999969, 35.15885193400004],
            [-80.836452512999983, 35.159295696000072],
            [-80.836197843999969, 35.160223590000044],
            [-80.83585956099995, 35.161382644000071],
            [-80.835520364999979, 35.162560279000047],
            [-80.835281168999984, 35.163385590000075],
            [-80.835056527999939, 35.164161573000058],
            [-80.834884909999971, 35.164750360000028],
            [-80.834681478999983, 35.165462309000077],
            [-80.834501580999984, 35.166119923000053],
            [-80.834323476999941, 35.166810618000056],
            [-80.834213357999943, 35.167263196000079],
            [-80.834088262999956, 35.167804229000069],
            [-80.834016735999967, 35.168126491000066],
            [-80.833931121999967, 35.168534854000029],
            [-80.833821139999941, 35.169087855000043],
            [-80.833733522999978, 35.169557728000029],
            [-80.833704354999952, 35.170517227000062],
            [-80.833633364999969, 35.170772549000048],
            [-80.833569762999957, 35.171029181000051],
            [-80.833513584999935, 35.171286979000058],
            [-80.833464861999971, 35.171545797000078],
            [-80.833423622999987, 35.171805491000043],
            [-80.833389888999989, 35.172065914000029],
            [-80.83336368099998, 35.172326921000035],
            [-80.83331941299997, 35.172752870000068],
            [-80.833253204999949, 35.173303646000079],
            [-80.833202451999966, 35.17372262300006],
            [-80.833157171999972, 35.174193476000028],
            [-80.83313018399997, 35.174747847000049],
            [-80.83310832799998, 35.175511072000063],
            [-80.833118960999968, 35.176275377000024],
            [-80.83314844299997, 35.17650709600008],
            [-80.833170540999959, 35.176739369000074],
            [-80.833185237999942, 35.176972037000041],
            [-80.833192524999959, 35.17720494100007],
            [-80.833192396999948, 35.177437921000035],
            [-80.833184852999977, 35.17767081900007],
            [-80.833169898999984, 35.17790347600004],
            [-80.833147544999974, 35.178135733000033],
            [-80.83319206699997, 35.178570594000064],
            [-80.833258314999966, 35.179380627000057],
            [-80.833155301999966, 35.179998229000034],
            [-80.833194814999956, 35.180524324000032],
            [-80.83323163099999, 35.180949551000026],
            [-80.833281672999988, 35.181465026000069],
            [-80.833287621999943, 35.181884800000034],
            [-80.832805174999976, 35.182891206000079],
            [-80.832342421999954, 35.183478341000068],
            [-80.831826847999935, 35.184136760000058],
            [-80.831029503999957, 35.185206246000064],
            [-80.830556162999983, 35.185867335000069],
            [-80.830202306999979, 35.186382437000077],
            [-80.829871969999942, 35.186873920000039],
            [-80.829443608999952, 35.187526638000065],
            [-80.828940855999974, 35.188317780000034],
            [-80.82864228699998, 35.188804283000024],
            [-80.828389376999951, 35.18923056400007],
            [-80.828056792999973, 35.189804000000038],
            [-80.82759055799994, 35.190631575000054],
            [-80.827309475999982, 35.191147839000052],
            [-80.826981990999968, 35.191758352000079],
            [-80.826641093999967, 35.192401687000029],
            [-80.826345365999941, 35.19296212900008],
            [-80.826060705999964, 35.193488509000076],
            [-80.82579852799995, 35.193976431000067],
            [-80.825365857999941, 35.194798185000025],
            [-80.825042986999961, 35.195407714000055],
            [-80.824581942999941, 35.196263411000075],
            [-80.824045208999962, 35.19670942700003],
            [-80.823706111999968, 35.196996564000074],
            [-80.823151049999979, 35.197480247000044],
            [-80.822311002999982, 35.198237973000062],
            [-80.821865917999958, 35.198645704000057],
            [-80.821388849999948, 35.199094152000043],
            [-80.820932429999971, 35.199535528000069],
            [-80.820361808999962, 35.200110003000077],
            [-80.819527156999982, 35.200981322000075],
            [-80.819061115999943, 35.201475043000073],
            [-80.818700554999964, 35.201866684000038],
            [-80.818076877999943, 35.20257312800004],
            [-80.81720545099995, 35.203603017000034],
            [-80.816600104999964, 35.20435090400008],
            [-80.816158607999967, 35.204923131000044],
            [-80.815769426999964, 35.205440008000039],
            [-80.815303467999968, 35.206075198000065],
            [-80.814911471999949, 35.206623662000027],
            [-80.814530167999976, 35.207174468000062],
            [-80.814072931999988, 35.207857036000064],
            [-80.81372485299994, 35.208392355000058],
            [-80.813440562999972, 35.208841145000065],
            [-80.813260419999949, 35.209131257000024],
            [-80.812646064999967, 35.210089664000066],
            [-80.812057911999943, 35.21054149400004],
            [-80.811261785999989, 35.211140009000076],
            [-80.810665920999952, 35.211601873000063],
            [-80.80987866199996, 35.212236545000053],
            [-80.809448801999963, 35.212594324000065],
            [-80.808865983999965, 35.213090512000065],
            [-80.80845560399996, 35.213447573000053],
            [-80.807897434999973, 35.21395153800006],
            [-80.806887126999982, 35.214892295000027],
            [-80.806499197999983, 35.215261780000048],
            [-80.806096044999947, 35.215664261000029],
            [-80.805514035999977, 35.216259686000058],
            [-80.805098889999954, 35.216695288000039],
            [-80.804583873999945, 35.217252071000075],
            [-80.803990364999947, 35.217922608000038],
            [-80.803176852999968, 35.218879493000031],
            [-80.802695282999935, 35.219465491000051],
            [-80.802353367999956, 35.219900109000037],
            [-80.801886062999984, 35.220509314000026],
            [-80.801539644999934, 35.220973251000032],
            [-80.801017412999954, 35.221696964000046],
            [-80.800711219999982, 35.222137949000057],
            [-80.800331189999952, 35.222700821000046],
            [-80.800022583999976, 35.22316931000006],
            [-80.799740575999976, 35.223606897000025],
            [-80.799407081999959, 35.22413668400003],
            [-80.798911094999937, 35.224967449000076],
            [-80.798268146999987, 35.226104938000049],
            [-80.797899067999936, 35.226788415000044],
            [-80.797731995999982, 35.227113757000041],
            [-80.797506470999963, 35.227567011000076],
            [-80.797160468999948, 35.22828479900005],
            [-80.796872900999972, 35.22890629300008],
            [-80.796629591999988, 35.229456175000053],
            [-80.796339415999967, 35.230142411000031],
            [-80.796133162999979, 35.230654250000043],
            [-80.795860262999952, 35.231360038000048],
            [-80.795680660999949, 35.231843353000045],
            [-80.795433435999939, 35.232558575000041],
            [-80.795060759999956, 35.233708444000058],
            [-80.794744243999958, 35.234797019000041],
            [-80.794479784999965, 35.235822734000067],
            [-80.794286309999961, 35.236613159000058],
            [-80.79394195499998, 35.237455407000027],
            [-80.793630388999986, 35.238153935000071],
            [-80.793367135999972, 35.238730561000068],
            [-80.793105602999958, 35.239315391000048],
            [-80.792892141999971, 35.23978687500005],
            [-80.792714846999957, 35.24017525000005],
            [-80.792519895999988, 35.240611749000038],
            [-80.792245756999989, 35.241215938000039],
            [-80.791807272999961, 35.242146578000074],
            [-80.791631973999984, 35.242529268000055],
            [-80.791176568999958, 35.243555516000072],
            [-80.790983714999982, 35.244005407000031],
            [-80.790532484999972, 35.245103762000042],
            [-80.79025526099997, 35.245816811000054],
            [-80.790057451999985, 35.246346456000026],
            [-80.789920045999963, 35.246727669000052],
            [-80.789798856999937, 35.247075799000072],
            [-80.789560446999985, 35.247787434000031],
            [-80.789417654999966, 35.248235603000069],
            [-80.789250971999934, 35.248781233000045],
            [-80.789119623999966, 35.249230121000039],
            [-80.788991768999949, 35.249691631000076],
            [-80.788827728999934, 35.250311999000076],
            [-80.788705010999934, 35.250802078000049],
            [-80.78844799999996, 35.251930815000037],
            [-80.788316348999956, 35.252576943000065],
            [-80.788198096999963, 35.253207520000046],
            [-80.788113329999987, 35.253694242000051],
            [-80.787977524999974, 35.254565435000075],
            [-80.787906833999955, 35.255078182000034],
            [-80.787827153999956, 35.255719514000077],
            [-80.787782229999948, 35.256115685000054],
            [-80.787745474999952, 35.256485546000079],
            [-80.787692800999935, 35.257075067000073],
            [-80.787663137999971, 35.257449456000074],
            [-80.787637165999968, 35.257847470000058],
            [-80.787602135999975, 35.258490490000042],
            [-80.787584773999981, 35.258887562000041],
            [-80.787573377999934, 35.259275940000066],
            [-80.787561581999967, 35.259901220000074],
            [-80.787558306999983, 35.260292999000058],
            [-80.78755887799997, 35.260601263000069],
            [-80.787566459999937, 35.261230086000069],
            [-80.787583300999984, 35.26180363800006],
            [-80.787619957999937, 35.262620067000057],
            [-80.787694141999964, 35.263640867000049],
            [-80.787763846999951, 35.264439321000054],
            [-80.787813185999937, 35.264925492000032],
            [-80.787905871999953, 35.265696511000044],
            [-80.788040322999962, 35.26660283700005],
            [-80.788256779999983, 35.267844055000069],
            [-80.788443442999949, 35.268773190000047],
            [-80.78860191299998, 35.269475477000071],
            [-80.788717825999981, 35.269958540000061],
            [-80.788879743999985, 35.270598203000077],
            [-80.789017211999976, 35.271112856000059],
            [-80.789171450999959, 35.271657502000039],
            [-80.789368631999935, 35.272314281000035],
            [-80.789532179999981, 35.272828656000058],
            [-80.789763834999974, 35.273523667000063],
            [-80.789929093999945, 35.27399957800003],
            [-80.79006702099997, 35.274378794000029],
            [-80.79034355999994, 35.275109852000071],
            [-80.790551821999941, 35.275632881000035],
            [-80.790840366999987, 35.276328486000068],
            [-80.791063272999963, 35.276845871000035],
            [-80.791311939999957, 35.277398952000055],
            [-80.791655565999974, 35.278132209000034],
            [-80.791909942999951, 35.278653317000078],
            [-80.792033320999963, 35.279258063000043],
            [-80.792113601999972, 35.279811000000052],
            [-80.792189061999977, 35.280282168000042],
            [-80.79230292799997, 35.280940886000053],
            [-80.792391969999983, 35.28141989900007],
            [-80.792520459999935, 35.282057016000067],
            [-80.792642658999966, 35.282616492000045],
            [-80.792743204999965, 35.283049257000073],
            [-80.792916938999952, 35.28380134400004],
            [-80.793025236999938, 35.284429332000059],
            [-80.793222195999988, 35.285469014000057],
            [-80.793366237999976, 35.286133103000054],
            [-80.793593400999953, 35.287085009000066],
            [-80.79374788399997, 35.287688107000065],
            [-80.79390701899996, 35.288264777000052],
            [-80.794059903999937, 35.288789156000064],
            [-80.794278262999967, 35.289501850000079],
            [-80.79441100799994, 35.289918020000073],
            [-80.794552137999972, 35.290339931000062],
            [-80.794752291999941, 35.290934840000034],
            [-80.794874030999949, 35.29128251800006],
            [-80.795004868999968, 35.291644053000027],
            [-80.795236012999965, 35.29226000500006],
            [-80.795397922999939, 35.292674293000061],
            [-80.79561807999994, 35.293220164000047],
            [-80.795777816999987, 35.293604797000057],
            [-80.796002531999989, 35.294126028000051],
            [-80.79633245499997, 35.29485744200008],
            [-80.796614706999947, 35.295469081000078],
            [-80.796516477999944, 35.296512628000073],
            [-80.796370433999982, 35.297149674000025],
            [-80.796204300999989, 35.297885093000048],
            [-80.796040663999975, 35.298555094000051],
            [-80.795927426999981, 35.299036362000038],
            [-80.795801641999958, 35.299594839000065],
            [-80.795715279999968, 35.299998455000036],
            [-80.795588914999939, 35.300629411000045],
            [-80.795487647999948, 35.301184897000041],
            [-80.795403750999981, 35.301658083000063],
            [-80.795313388999944, 35.30210988400006],
            [-80.795154393999951, 35.302944645000025],
            [-80.79500447099997, 35.303801070000077],
            [-80.794872407999947, 35.304626595000059],
            [-80.794795952999948, 35.305119385000069],
            [-80.794700638999984, 35.30579491900005],
            [-80.794632706999948, 35.306281823000063],
            [-80.794542941999964, 35.306995331000053],
            [-80.794468502999962, 35.307669010000041],
            [-80.794582840999965, 35.308568019000063],
            [-80.794405179999956, 35.309173689000033],
            [-80.794376703999944, 35.309481731000062],
            [-80.79435633199995, 35.309790204000024],
            [-80.79434407499997, 35.310098966000055],
            [-80.794339936999961, 35.310407872000042],
            [-80.79434392099995, 35.310716780000064],
            [-80.794308090999948, 35.311164155000029],
            [-80.794227038999963, 35.312282251000056],
            [-80.794184691999988, 35.313199144000066],
            [-80.79419590599997, 35.313752187000034],
            [-80.794226661999971, 35.31420487400004],
            [-80.794278493999968, 35.31491569800005],
            [-80.794336605999945, 35.315823886000032],
            [-80.794349954999973, 35.316727921000052],
            [-80.794402816999934, 35.317161808000037],
            [-80.794312325999954, 35.318137570000033],
            [-80.794352390999961, 35.318918778000068],
            [-80.794397390999961, 35.31961850700003],
            [-80.79443882999999, 35.320341357000075],
            [-80.794458630999941, 35.320664419000025],
            [-80.794478983999966, 35.321059655000056],
            [-80.794501985999943, 35.321455031000028],
            [-80.794532815999958, 35.321936268000059],
            [-80.794563214999982, 35.32258054700003],
            [-80.794628571999965, 35.323583410000026],
            [-80.794665949999967, 35.324281307000035],
            [-80.794692411999961, 35.324750923000067],
            [-80.794717107999986, 35.325124902000027],
            [-80.794747625999946, 35.325607756000068],
            [-80.794784745999948, 35.326320431000056],
            [-80.794820609999988, 35.326922153000055],
            [-80.794858750999936, 35.327638901000057],
            [-80.794896920999975, 35.32821188500003],
            [-80.794929826999976, 35.328756296000051],
            [-80.794964793999952, 35.329394562000061],
            [-80.794988822999983, 35.329793712000026],
            [-80.79501156699996, 35.330187982000041],
            [-80.795042747999958, 35.330672143000072],
            [-80.795063001999949, 35.331025465000039],
            [-80.795089318999942, 35.331493667000075],
            [-80.795116979999989, 35.332132710000053],
            [-80.795132277999983, 35.332446727000047],
            [-80.795159651999938, 35.333003443000052],
            [-80.795205195999984, 35.333775761000027],
            [-80.795246426999938, 35.334491286000059],
            [-80.795275851999975, 35.335076723000043],
            [-80.795327589999943, 35.336012857000071],
            [-80.795363693999946, 35.336582484000076],
            [-80.795405161999952, 35.33719411900006],
            [-80.795446690999938, 35.33799613900004],
            [-80.795461940999985, 35.338436286000046],
            [-80.79518130799994, 35.33913629400007],
            [-80.793643352999936, 35.342500414000028],
            [-80.79344206199994, 35.342955505000077],
            [-80.793209483999988, 35.343500849000066],
            [-80.792776468999989, 35.344546276000074],
            [-80.79234644099995, 35.345591979000062],
            [-80.791977650999968, 35.346534450000036],
            [-80.791783059999943, 35.347056866000059],
            [-80.791496926999969, 35.347856363000062],
            [-80.791306434999967, 35.348416604000079],
            [-80.790943909999953, 35.34954069500003],
            [-80.790766057999974, 35.350124755000024],
            [-80.790598281999962, 35.35071008400007],
            [-80.79043917599995, 35.351302101000044],
            [-80.790141534999975, 35.35248802600006],
            [-80.789977543999953, 35.353201639000076],
            [-80.789792638999984, 35.354087012000036],
            [-80.789696465999953, 35.354583221000041],
            [-80.789518126999951, 35.355577345000029],
            [-80.78943596299996, 35.356075260000068],
            [-80.789281388999939, 35.357102389000033],
            [-80.789224395999952, 35.357507320000025],
            [-80.789145204999954, 35.358124188000033],
            [-80.78907954899995, 35.35868406000003],
            [-80.78902093499994, 35.359200084000065],
            [-80.788937418999978, 35.360035977000052],
            [-80.788871790999963, 35.360809538000069],
            [-80.78882858999998, 35.361434233000068],
            [-80.788790602999939, 35.362155898000026],
            [-80.788774049999972, 35.362554316000057],
            [-80.788761802999943, 35.362962676000052],
            [-80.788747488999945, 35.363694124000062],
            [-80.788745351999978, 35.364062665000063],
            [-80.788750015999938, 35.364608788000055],
            [-80.788756072999945, 35.364944614000024],
            [-80.788775722999958, 35.365730403000043],
            [-80.788819276999959, 35.366750979000074],
            [-80.788914996999949, 35.367961131000072],
            [-80.789020172999983, 35.368905128000051],
            [-80.78915260499997, 35.370047018000037],
            [-80.789303348999965, 35.371117000000027],
            [-80.789536904999977, 35.37245800200003],
            [-80.789921815999946, 35.374427426000068],
            [-80.790060498999935, 35.375069040000028],
            [-80.790303748999975, 35.376093810000043],
            [-80.790613426999982, 35.377295090000075],
            [-80.79075161999998, 35.377806108000073],
            [-80.790992494999955, 35.378618754000058],
            [-80.791382164999959, 35.379831212000056],
            [-80.79176294399997, 35.380903384000078],
            [-80.792126301999986, 35.381836474000067],
            [-80.792355113999974, 35.38239830200007],
            [-80.792761931999962, 35.38336457500003],
            [-80.792974826999966, 35.383858940000039],
            [-80.793207995999978, 35.384383669000044],
            [-80.793563730999949, 35.385156901000073],
            [-80.793895770999939, 35.385853668000038],
            [-80.794185504999973, 35.386444538000035],
            [-80.794442757999946, 35.386952984000061],
            [-80.794712981999965, 35.38747255100003],
            [-80.794938041999956, 35.387892648000047],
            [-80.795256978999987, 35.388471930000037],
            [-80.795520219999958, 35.388946392000037],
            [-80.795813430999942, 35.389458926000032],
            [-80.796083407999959, 35.389920280000069],
            [-80.796311191999962, 35.39030587700006],
            [-80.796511253999938, 35.390644563000023],
            [-80.796578257999954, 35.391027339000061],
            [-80.796640482999976, 35.391443605000063],
            [-80.796710941999947, 35.39188848200007],
            [-80.796824870999956, 35.392559272000028],
            [-80.796927690999951, 35.39315390400003],
            [-80.79702317999994, 35.39367474900007],
            [-80.797169783999948, 35.394418785000028],
            [-80.797325442999977, 35.395112513000072],
            [-80.797614525999961, 35.396288936000076],
            [-80.797796342999959, 35.396972898000058],
            [-80.798008468999967, 35.397745899000029],
            [-80.798222299999964, 35.398478197000031],
            [-80.79852163299995, 35.39943293500005],
            [-80.798677905999966, 35.399908910000079],
            [-80.798947173999977, 35.400692706000029],
            [-80.799110760999952, 35.401155475000053],
            [-80.799302388999934, 35.401676847000033],
            [-80.79962528599998, 35.40252124400007],
            [-80.80007496099995, 35.403631226000073],
            [-80.800339691999966, 35.404248020000068],
            [-80.800713271999939, 35.405064762000052],
            [-80.801408274999972, 35.406523843000059],
            [-80.801780694999934, 35.407275907000042],
            [-80.802165263999939, 35.408010259000037],
            [-80.802641395999956, 35.408875218000048],
            [-80.802885222999976, 35.409305566000057],
            [-80.803330581999944, 35.410069042000032],
            [-80.80362930199999, 35.410569783000028],
            [-80.804061857999955, 35.41127171800008],
            [-80.804302616999962, 35.412290022000036],
            [-80.804786106999984, 35.415226039000061],
            [-80.805075614999964, 35.416842742000028],
            [-80.805165254999963, 35.417324334000057],
            [-80.80528604899996, 35.417993250000052],
            [-80.805369155999983, 35.418425108000065],
            [-80.805464461999975, 35.418951158000027],
            [-80.80556594799998, 35.419487068000024],
            [-80.805732417999934, 35.420307921000074],
            [-80.805876148999971, 35.420960754000077],
            [-80.805985183999951, 35.421439618000079],
            [-80.806099188999951, 35.42191064900004],
            [-80.80633987899995, 35.422850536000055],
            [-80.806538049999972, 35.423566489000052],
            [-80.806657104999942, 35.423973224000065],
            [-80.806851860999984, 35.424626117000059],
            [-80.807038725999973, 35.425222747000078],
            [-80.807246612999961, 35.42586130400008],
            [-80.807478189999983, 35.426547002000063],
            [-80.807736593999948, 35.427288752000038],
            [-80.807923984999945, 35.427806650000036],
            [-80.808180812999979, 35.428498138000066],
            [-80.808405709999988, 35.429080111000076],
            [-80.808568399999956, 35.429488058000061],
            [-80.808789815999944, 35.430031488000054],
            [-80.809072785999945, 35.430706438000072],
            [-80.809281347999956, 35.431184936000079],
            [-80.809503323999934, 35.43168614800004],
            [-80.809744415999944, 35.432218959000068],
            [-80.809909138999956, 35.432575664000069],
            [-80.810287198999958, 35.433380123000063],
            [-80.810496074999946, 35.433811797000033],
            [-80.810743086999935, 35.434309864000056],
            [-80.811051956999961, 35.434920868000063],
            [-80.811432638999975, 35.435652813000047],
            [-80.811792643999979, 35.436333206000029],
            [-80.812059188999967, 35.436832882000033],
            [-80.812342875999946, 35.437367154000071],
            [-80.812608824999984, 35.43786532300004],
            [-80.812941639999963, 35.438482749000059],
            [-80.813248809999948, 35.439053381000065],
            [-80.813466451999943, 35.439458191000028],
            [-80.814121222999972, 35.440662532000033],
            [-80.815364912999939, 35.442790591000062],
            [-80.815305165999973, 35.443332117000068],
            [-80.815307896999968, 35.44378516900008],
            [-80.815273911999952, 35.444461794000063],
            [-80.815287275999935, 35.445396599000048],
            [-80.815348222999944, 35.446126444000072],
            [-80.81541858099996, 35.446947113000078],
            [-80.815458025999988, 35.447673816000076],
            [-80.815590032999978, 35.448560775000033],
            [-80.815669501999935, 35.449282493000055],
            [-80.815614890999939, 35.449916633000043],
            [-80.815697887999988, 35.450595544000066],
            [-80.815745113999981, 35.45094874800003],
            [-80.815830093999978, 35.451537997000059],
            [-80.815982490999943, 35.452491959000042],
            [-80.816189159999965, 35.453683316000024],
            [-80.816369331999965, 35.45462550600007],
            [-80.816476242999954, 35.455142207000051],
            [-80.816606362999948, 35.455712394000045],
            [-80.816893753999977, 35.456884613000057],
            [-80.817047320999961, 35.457469185000036],
            [-80.817251501999976, 35.458195759000034],
            [-80.817512201999989, 35.459091116000025],
            [-80.817780314999936, 35.459966962000067],
            [-80.818072322999967, 35.460863393000068],
            [-80.818312276999961, 35.461561330000052],
            [-80.81855154699997, 35.462222305000068],
            [-80.818869439999958, 35.463051199000063],
            [-80.819067725999957, 35.463551052000071],
            [-80.819255168999973, 35.464020961000074],
            [-80.819562811999958, 35.464764657000046],
            [-80.819723106999959, 35.46514077300003],
            [-80.819885829999976, 35.465511507000031],
            [-80.820073483999977, 35.46594268900003],
            [-80.820286075999945, 35.466415509000058],
            [-80.820763714999941, 35.467434419000028],
            [-80.821256999999946, 35.468438025000069],
            [-80.821509849999984, 35.468948699000066],
            [-80.821829210999965, 35.469625694000058],
            [-80.821832877999952, 35.470055691000027],
            [-80.821787018999942, 35.470863454000039],
            [-80.821721886999967, 35.472100562000037],
            [-80.821698871999956, 35.472637644000031],
            [-80.821680069999957, 35.473197592000076],
            [-80.82166592599998, 35.473568234000027],
            [-80.821648241999981, 35.474195862000045],
            [-80.821636989999945, 35.474542310000061],
            [-80.821626619999961, 35.474940999000069],
            [-80.821614427999975, 35.475331554000036],
            [-80.821597555999972, 35.476151161000075],
            [-80.821591324999986, 35.477084421000029],
            [-80.821362486999988, 35.477695415000028],
            [-80.821099084999958, 35.478198306000024],
            [-80.820851516999937, 35.478684603000033],
            [-80.820461368999986, 35.479469047000066],
            [-80.820193555999936, 35.480012649000059],
            [-80.819765275999941, 35.480875836000052],
            [-80.819468926999946, 35.481475673000034],
            [-80.819203904999938, 35.481997031000049],
            [-80.818799984999941, 35.482804201000079],
            [-80.818546164999987, 35.483316586000058],
            [-80.818162398999959, 35.484084268000061],
            [-80.81790416299998, 35.484590284000035],
            [-80.817651611999963, 35.485096110000029],
            [-80.81746074199998, 35.485489390000055],
            [-80.817041929999959, 35.486368241000037],
            [-80.816759629999979, 35.486991991000025],
            [-80.816381515999979, 35.487885578000032],
            [-80.81619937399995, 35.488334062000035],
            [-80.816071416999989, 35.488655128000062],
            [-80.815867775999948, 35.489177432000076],
            [-80.815704729999936, 35.489609162000079],
            [-80.815528684999947, 35.490094809000027],
            [-80.815347217999943, 35.490615723000076],
            [-80.815222404999986, 35.490985498000043],
            [-80.815107874999967, 35.491335933000073],
            [-80.815011668999944, 35.491635919000032],
            [-80.814805507999949, 35.492299832000072],
            [-80.814683226999989, 35.492709650000052],
            [-80.814530679999962, 35.49325880300006],
            [-80.814286330999948, 35.494193149000068],
            [-80.814135484999952, 35.49481197800003],
            [-80.813980938999975, 35.495504357000073],
            [-80.813834801999974, 35.496210317000077],
            [-80.813700911999945, 35.496900250000067],
            [-80.813629528999968, 35.497295981000036],
            [-80.813546648999989, 35.49778394100008],
            [-80.813489138999955, 35.498142785000027],
            [-80.813441293999972, 35.498459205000074],
            [-80.813369828999953, 35.49899023200004],
            [-80.813267677999988, 35.49984575700006],
            [-80.813192525999966, 35.500582940000072],
            [-80.81310168899995, 35.501655482000047],
            [-80.813041363999957, 35.502434665000067],
            [-80.812965462999955, 35.503242039000042],
            [-80.812928054999986, 35.503732631000048],
            [-80.812860669999964, 35.504748708000079],
            [-80.812824541999987, 35.505296850000036],
            [-80.812721008999972, 35.50666196700007],
            [-80.812624824999943, 35.507939689000068],
            [-80.812522653999963, 35.509334458000069],
            [-80.812488160999976, 35.509914995000031],
            [-80.812453657999981, 35.510464636000052],
            [-80.812401797999939, 35.510951127000055],
            [-80.812373449999939, 35.511341601000026],
            [-80.812229207999962, 35.512091023000039],
            [-80.812086495999949, 35.512750415000028],
            [-80.811959062999961, 35.513321636000057],
            [-80.811855912999988, 35.51379002200008],
            [-80.811757499999942, 35.514232230000061],
            [-80.81164810599995, 35.514717828000073],
            [-80.811522734999983, 35.515290096000058],
            [-80.811439804999964, 35.515660675000049],
            [-80.811323093999988, 35.516191316000061],
            [-80.81120604299997, 35.51671943000008],
            [-80.811101286999985, 35.517193410000061],
            [-80.811002945999974, 35.517632565000042],
            [-80.810894664999978, 35.518131906000065],
            [-80.810807900999976, 35.518521987000042],
            [-80.810694976999969, 35.519023316000073],
            [-80.810507281999946, 35.519870516000026],
            [-80.810414923999986, 35.520285125000044],
            [-80.810256724999988, 35.520998501000065],
            [-80.810129243999938, 35.521577416000071],
            [-80.810051252999983, 35.521937682000043],
            [-80.809960825999951, 35.522347555000067],
            [-80.809855927999934, 35.522819279000032],
            [-80.809754182999939, 35.52328803100005],
            [-80.809672780999961, 35.52365295900006],
            [-80.80958037399995, 35.524073168000029],
            [-80.80937374399997, 35.524961137000048],
            [-80.809172782999951, 35.525797524000041],
            [-80.809050404999937, 35.526351951000038],
            [-80.808758500999943, 35.527690179000047],
            [-80.808674716999974, 35.528023129000076],
            [-80.80854487299996, 35.528554548000045],
            [-80.808436205999953, 35.529021597000053],
            [-80.808310089999964, 35.529589257000055],
            [-80.808212346999937, 35.530044063000048],
            [-80.808129573999963, 35.530433724000034],
            [-80.807945858999972, 35.53129892700008],
            [-80.807769991999976, 35.53215696400008],
            [-80.807684327999937, 35.532569095000042],
            [-80.807576876999974, 35.533045387000072],
            [-80.807455807999986, 35.533588411000039],
            [-80.807311377999952, 35.534233091000033],
            [-80.807196053999974, 35.534765976000074],
            [-80.807008438999958, 35.53560212900004],
            [-80.806834901999935, 35.536367655000049],
            [-80.806703791999951, 35.536970500000052],
            [-80.806597039999986, 35.537440343000071],
            [-80.806488244999969, 35.537928157000067],
            [-80.806366665999974, 35.538491990000068],
            [-80.806150656999989, 35.539458471000046],
            [-80.806042738999963, 35.539967599000079],
            [-80.805915719999973, 35.540543832000026],
            [-80.805782760999989, 35.541127625000058],
            [-80.805638316999989, 35.541774401000055],
            [-80.805520004999948, 35.542337682000039],
            [-80.805414515999985, 35.542868657000042],
            [-80.805325022999966, 35.543343619000041],
            [-80.805243476999976, 35.543808092000063],
            [-80.805135783999958, 35.544468528000039],
            [-80.80504555999994, 35.545087851000062],
            [-80.804981003999956, 35.545524382000053],
            [-80.804914015999941, 35.546005862000072],
            [-80.804844100999958, 35.54654793900005],
            [-80.804771276999986, 35.547157236000032],
            [-80.80471584199995, 35.547668334000036],
            [-80.804664241999944, 35.548194609000063],
            [-80.804602151999973, 35.548928053000054],
            [-80.804557554999974, 35.549566340000069],
            [-80.804518787999939, 35.550193310000054],
            [-80.80449845399994, 35.550569447000044],
            [-80.804476893999947, 35.551044310000066],
            [-80.804459752999946, 35.551527010000029],
            [-80.804444399999966, 35.552112932000057],
            [-80.804433385999971, 35.552930162000052],
            [-80.804439848999948, 35.553809612000066],
            [-80.804475784999966, 35.554950616000042],
            [-80.804506767999953, 35.55569637900004],
            [-80.804536553999981, 35.55624651200003],
            [-80.804563523999946, 35.556676393000032],
            [-80.80459012099999, 35.55705159300004],
            [-80.804624753999974, 35.557491729000049],
            [-80.804676275999952, 35.55806861700006],
            [-80.80473232199995, 35.558633444000066],
            [-80.804774264999935, 35.559017955000058],
            [-80.805036732999952, 35.559311949000062],
            [-80.804946453999946, 35.559895152000024],
            [-80.804915498999947, 35.56041966600003],
            [-80.804845342999954, 35.560954181000056],
            [-80.804587967999964, 35.561495378000075],
            [-80.804430770999943, 35.562056073000065],
            [-80.804241024999953, 35.562753572000076],
            [-80.804108275999965, 35.563253133000046],
            [-80.803961197999968, 35.563808771000026],
            [-80.80378765599994, 35.564497108000069],
            [-80.803604255999971, 35.56527300700003],
            [-80.803473810999947, 35.565861003000066],
            [-80.803326632999983, 35.566574517000049],
            [-80.803234701999941, 35.567049578000024],
            [-80.80313134499994, 35.567619183000033],
            [-80.803000736999934, 35.568396678000056],
            [-80.802881934999959, 35.569178431000068],
            [-80.802796412999953, 35.569799365000051],
            [-80.80273355199995, 35.570302759000072],
            [-80.802666866999971, 35.57088893100007],
            [-80.802606111999978, 35.571488596000052],
            [-80.802552632999948, 35.572090030000027],
            [-80.802518869999972, 35.572519778000071],
            [-80.802489729999934, 35.572943119000058],
            [-80.802460698999937, 35.573417253000059],
            [-80.80243511599997, 35.573916952000047],
            [-80.802410162999934, 35.574551645000042],
            [-80.80239336599999, 35.575219381000068],
            [-80.802385892999951, 35.575927014000058],
            [-80.802387834999934, 35.576513421000072],
            [-80.802396036999937, 35.577052452000032],
            [-80.802411577999976, 35.577670854000075],
            [-80.802433919999942, 35.578262571000039],
            [-80.802463580999984, 35.578854855000031],
            [-80.802500730999952, 35.579447734000041],
            [-80.802550710999981, 35.580106923000073],
            [-80.802599143999942, 35.580660635000072],
            [-80.802660381999942, 35.581272182000077],
            [-80.802734033999968, 35.581920331000049],
            [-80.802816102999941, 35.582563163000032],
            [-80.802889479999976, 35.583084558000053],
            [-80.802987636999944, 35.583725712000046],
            [-80.803089339999985, 35.58434429600004],
            [-80.803196170999968, 35.584946487000025],
            [-80.80330536699995, 35.585519834000024],
            [-80.803429470999959, 35.586129415000073],
            [-80.80354244199998, 35.58665183100004],
            [-80.803657989999977, 35.587159030000066],
            [-80.803752949999989, 35.587557950000075],
            [-80.803869426999938, 35.588031220000062],
            [-80.804047736999962, 35.588724149000029],
            [-80.804198027999973, 35.589300342000058],
            [-80.804295928999977, 35.589667443000053],
            [-80.804490595999937, 35.590411084000038],
            [-80.804637727999989, 35.590966819000073],
            [-80.804747025999973, 35.591382600000031],
            [-80.804851359999986, 35.591775763000044],
            [-80.804943639999976, 35.592123495000067],
            [-80.805070282999964, 35.592603721000046],
            [-80.805208044999972, 35.593120618000057],
            [-80.805326263999973, 35.59356904100008],
            [-80.805467866999948, 35.594098966000047],
            [-80.805778553999971, 35.595272606000037],
            [-80.805955868999945, 35.595950558000027],
            [-80.806272638999985, 35.597148693000065],
            [-80.806356533999974, 35.597530996000046],
            [-80.806211633999965, 35.598292131000051],
            [-80.80609134599996, 35.598962638000046],
            [-80.805949004999945, 35.599737680000032],
            [-80.805850916999987, 35.600279157000045],
            [-80.805754409999963, 35.600804694000033],
            [-80.805646740999975, 35.601413626000067],
            [-80.805547984999976, 35.601959824000062],
            [-80.805477846999963, 35.602354521000052],
            [-80.805375517999948, 35.60292524700003],
            [-80.805273949999957, 35.603486869000051],
            [-80.805128091999961, 35.604290951000053],
            [-80.805007885999942, 35.604948695000076],
            [-80.804930117999959, 35.605386041000031],
            [-80.804824210999982, 35.605971767000028],
            [-80.804686152999977, 35.606739982000079],
            [-80.804600498999946, 35.607223149000049],
            [-80.80451076899999, 35.607732003000024],
            [-80.804391022999937, 35.608443333000025],
            [-80.804259964999972, 35.609309435000057],
            [-80.804147519999958, 35.610159776000046],
            [-80.804070072999934, 35.610826081000027],
            [-80.804017929999986, 35.61133638900003],
            [-80.803954830999942, 35.612058804000071],
            [-80.80392253499997, 35.612495208000041],
            [-80.803875919999939, 35.613267456000074],
            [-80.803843106999977, 35.613980620000063],
            [-80.80382739099997, 35.61446287800004],
            [-80.803816238999957, 35.614972454000053],
            [-80.803810174999967, 35.615556190000063],
            [-80.803811886999938, 35.616111996000029],
            [-80.803821780999954, 35.616697868000074],
            [-80.803840817999969, 35.617308435000041],
            [-80.803867632999982, 35.617897258000028],
            [-80.803903294999941, 35.618492171000071],
            [-80.803946143999951, 35.619071461000033],
            [-80.803997524999943, 35.619654669000056],
            [-80.804061961999935, 35.620278162000034],
            [-80.804136226999958, 35.620920916000046],
            [-80.804221097999971, 35.621570782000049],
            [-80.804315903999964, 35.622233645000051],
            [-80.804419951999989, 35.622889799000063],
            [-80.804523682999957, 35.623498545000075],
            [-80.804594367999982, 35.623881380000057],
            [-80.804703558999961, 35.624440875000062],
            [-80.804780538999978, 35.624813882000069],
            [-80.804948311999965, 35.625558139000077],
            [-80.805131600999971, 35.62631377200006],
            [-80.805326488999981, 35.627060631000063],
            [-80.805532446999962, 35.627800543000035],
            [-80.805765314999974, 35.62858486600004],
            [-80.80597897499996, 35.629267936000076],
            [-80.806234122999967, 35.630040516000065],
            [-80.806480912999973, 35.630749276000074],
            [-80.806621735999954, 35.631136983000033],
            [-80.806847970999968, 35.631736387000046],
            [-80.807083132999935, 35.632336925000061],
            [-80.807235369999944, 35.632709390000059],
            [-80.807474113999945, 35.633276994000028],
            [-80.807711167999969, 35.633820354000079],
            [-80.807973421999975, 35.634404460000042],
            [-80.80826733899994, 35.635041413000067],
            [-80.808666765999988, 35.635886649000042],
            [-80.80902187099997, 35.63662948700005],
            [-80.809320326999966, 35.637242767000032],
            [-80.809543340999937, 35.637706447000028],
            [-80.809801337999943, 35.638238778000073],
            [-80.810092598999972, 35.63874798300003],
            [-80.810323638999989, 35.639336766000042],
            [-80.810411731999977, 35.640043425000044],
            [-80.810506273999977, 35.640757047000079],
            [-80.810846177999963, 35.641548701000033],
            [-80.810838901999944, 35.642021185000033],
            [-80.810805562999974, 35.642552966000039],
            [-80.810447655999951, 35.643120373000045],
            [-80.810274796999977, 35.643576171000063],
            [-80.810073373999955, 35.644124565000027],
            [-80.809814442999937, 35.644855808000045],
            [-80.809593207999967, 35.645507418000079],
            [-80.809410457999945, 35.646065036000039],
            [-80.809286971999938, 35.646452871000065],
            [-80.809158080999964, 35.64686981400007],
            [-80.809011368999961, 35.64736098700007],
            [-80.808835273999989, 35.64797808700007],
            [-80.808619226999951, 35.648776099000031],
            [-80.80842043499996, 35.649558573000036],
            [-80.808221198999945, 35.650388921000058],
            [-80.808075332999977, 35.651029980000033],
            [-80.808001679999961, 35.651367452000045],
            [-80.80790535899996, 35.651827969000067],
            [-80.807807921999938, 35.652316839000036],
            [-80.807682686999954, 35.652988578000077],
            [-80.80753002299997, 35.653881779000073],
            [-80.807410216999983, 35.654658749000077],
            [-80.807321226999989, 35.655295705000071],
            [-80.807223575999956, 35.656057006000026],
            [-80.807126159999939, 35.656900515000075],
            [-80.806983174999971, 35.657835650000038],
            [-80.806699827999978, 35.658395598000027],
            [-80.806426372999965, 35.658924730000024],
            [-80.805939128999967, 35.659839901000055],
            [-80.805754847999935, 35.66017855900003],
            [-80.805353477999972, 35.660925387000077],
            [-80.805023644999949, 35.661546706000024],
            [-80.804715146999968, 35.662127511000051],
            [-80.804393230999949, 35.662748836000048],
            [-80.804028130999939, 35.663475733000041],
            [-80.803776593999942, 35.663993808000043],
            [-80.803523588999951, 35.664529854000079],
            [-80.803228375999936, 35.665176908000035],
            [-80.802922437999939, 35.665882037000074],
            [-80.802668769999968, 35.666489915000056],
            [-80.802432804999967, 35.667079910000041],
            [-80.802128789999983, 35.667873947000032],
            [-80.801901843999985, 35.668495182000072],
            [-80.801652178999973, 35.669215831000031],
            [-80.801456476999988, 35.669808587000034],
            [-80.801191696999979, 35.670654195000054],
            [-80.800987171999964, 35.671353890000034],
            [-80.800791353999955, 35.672063100000059],
            [-80.800582682999959, 35.672864324000045],
            [-80.80038129999997, 35.673701400000027],
            [-80.80026570299998, 35.674214024000037],
            [-80.800145750999945, 35.674790786000074],
            [-80.800032061999957, 35.675374324000074],
            [-80.799925790999964, 35.675960394000072],
            [-80.799824371999989, 35.67656478400005],
            [-80.799729527999943, 35.677181114000064],
            [-80.799647738999965, 35.677764914000079],
            [-80.799570699999947, 35.678373630000067],
            [-80.799503577999985, 35.678968088000033],
            [-80.799444580999989, 35.679560974000026],
            [-80.799395890999961, 35.680122761000064],
            [-80.799361424999972, 35.680582125000058],
            [-80.799313176999988, 35.681359073000067],
            [-80.799284872999976, 35.68197592000007],
            [-80.799268429999984, 35.682703269000058],
            [-80.799270933999935, 35.683117518000074],
            [-80.799262220999935, 35.683643062000044],
            [-80.799263013999962, 35.68430433900005],
            [-80.799269471999935, 35.68460895700008],
            [-80.799284281999974, 35.685147131000065],
            [-80.799300865999953, 35.685603488000027],
            [-80.799321029999987, 35.686015964000035],
            [-80.799364720999961, 35.686682813000061],
            [-80.799426991999951, 35.687395366000032],
            [-80.799480499999959, 35.68794570600005],
            [-80.799538259999963, 35.688470874000075],
            [-80.799605091999979, 35.689167135000048],
            [-80.799679007999941, 35.689723580000077],
            [-80.799768272999984, 35.690334727000049],
            [-80.799866497999972, 35.690947655000059],
            [-80.799973338999962, 35.691559958000028],
            [-80.800088277999976, 35.692168883000079],
            [-80.800218185999938, 35.692807477000031],
            [-80.80035391499996, 35.693428542000049],
            [-80.800501710999981, 35.694061134000037],
            [-80.800653310999962, 35.694670946000031],
            [-80.800808270999937, 35.695259531000033],
            [-80.800976283999944, 35.695863959000064],
            [-80.801150296999936, 35.696458618000065],
            [-80.801321844999961, 35.697017441000071],
            [-80.80152554099999, 35.697652380000079],
            [-80.80172702699997, 35.698251517000074],
            [-80.801928264999958, 35.698824656000056],
            [-80.802114057999972, 35.699333742000078],
            [-80.802308926999956, 35.699848819000067],
            [-80.802490475999946, 35.700313290000054],
            [-80.80290247399995, 35.700574749000054],
            [-80.802843016999987, 35.701187179000044],
            [-80.802965229999984, 35.701698578000048],
            [-80.802633742999944, 35.702046084000074],
            [-80.802526476999958, 35.702556817000072],
            [-80.80239241299995, 35.703246104000073],
            [-80.802268909999952, 35.703934748000052],
            [-80.802192154999943, 35.704401186000041],
            [-80.802121861999979, 35.704859366000051],
            [-80.802065142999936, 35.705257232000065],
            [-80.802118420999989, 35.705818645000079],
            [-80.802041151999958, 35.706313672000078],
            [-80.801963457999989, 35.706867188000047],
            [-80.801873001999979, 35.707647868000038],
            [-80.801812680999944, 35.708272358000045],
            [-80.80178284699997, 35.708633647000056],
            [-80.801747354999975, 35.709128464000059],
            [-80.801705327999969, 35.709756421000066],
            [-80.801670262999949, 35.710363606000044],
            [-80.80164022799994, 35.71100801800003],
            [-80.801647856999978, 35.711473475000048],
            [-80.801662075999957, 35.711965554000074],
            [-80.801712984999938, 35.712385632000064],
            [-80.801751765999938, 35.712965567000026],
            [-80.801761283999952, 35.713375271000075],
            [-80.801782498999955, 35.713739413000042],
            [-80.801811554999972, 35.714103196000053],
            [-80.801848441999937, 35.714466505000075],
            [-80.801893148999966, 35.714829231000067],
            [-80.80194566199998, 35.715191259000051],
            [-80.801869089999968, 35.715894147000029],
            [-80.801939150999942, 35.716533417000051],
            [-80.802051309999968, 35.717422390000024],
            [-80.802156564999962, 35.718146211000033],
            [-80.802279021999937, 35.718895245000056],
            [-80.802420577999953, 35.719672865000064],
            [-80.802582853999979, 35.720479168000054],
            [-80.802741482999977, 35.721199348000027],
            [-80.802877931999944, 35.721777682000038],
            [-80.803005578999944, 35.722296164000056],
            [-80.80313496499997, 35.722824957000057],
            [-80.803308207999976, 35.723503738000034],
            [-80.803449757999942, 35.724063197000078],
            [-80.803609055999971, 35.724688155000024],
            [-80.803792321999936, 35.725409698000078],
            [-80.803980932999934, 35.726163057000065],
            [-80.804136946999961, 35.726775201000066],
            [-80.804233088999979, 35.727158924000037],
            [-80.804332723999948, 35.727562754000076],
            [-80.804414425999937, 35.727915041000074],
            [-80.804629972999976, 35.729138129000034],
            [-80.804799238999976, 35.730008810000072],
            [-80.804923513999938, 35.730944084000043],
            [-80.805012966999982, 35.731744348000063],
            [-80.80507003699995, 35.73227015100008],
            [-80.805122867999955, 35.732745008000052],
            [-80.805201550999982, 35.733463047000043],
            [-80.805253844999982, 35.733968470000036],
            [-80.805305529999941, 35.734455669000056],
            [-80.805363778999947, 35.734972825000057],
            [-80.805433969999967, 35.73559937300007],
            [-80.805604741999957, 35.737165573000027],
            [-80.805655871999988, 35.737616484000057],
            [-80.805702366999981, 35.738054064000039],
            [-80.805820923999988, 35.739129318000039],
            [-80.805938677999961, 35.740233854000053],
            [-80.805999682999982, 35.740777648000062],
            [-80.806057844999941, 35.741310331000079],
            [-80.806123477999961, 35.74192658700008],
            [-80.806195169999967, 35.742587932000049],
            [-80.806261387999939, 35.743185815000061],
            [-80.806358001999968, 35.744045305000043],
            [-80.806416539999987, 35.744589719000032],
            [-80.806557139999938, 35.745874703000027],
            [-80.806643984999937, 35.746664318000057],
            [-80.806708844999946, 35.747264481000059],
            [-80.806798492999974, 35.748072225000044],
            [-80.806858377999959, 35.748617437000064],
            [-80.806961901999955, 35.749579738000079],
            [-80.807021913999961, 35.750146864000044],
            [-80.807097451999937, 35.75081300800008],
            [-80.807178809999982, 35.751571599000044],
            [-80.807239975999948, 35.752120563000062],
            [-80.807302095999944, 35.752659959000027],
            [-80.807375891999982, 35.753332556000032],
            [-80.807428679999987, 35.753826887000059],
            [-80.807492543999956, 35.754407366000066],
            [-80.807544058999952, 35.754866689000039],
            [-80.807662944999947, 35.755962091000072],
            [-80.807734279999977, 35.756594867000047],
            [-80.807821875999934, 35.757392012000025],
            [-80.807895650999967, 35.758081992000029],
            [-80.807952006999983, 35.758590041000048],
            [-80.808004736999976, 35.759081992000063],
            [-80.808160166999983, 35.760479641000074],
            [-80.808225363999952, 35.761088271000062],
            [-80.808271402999935, 35.761502525000026],
            [-80.808328415999938, 35.762029723000069],
            [-80.808398988999954, 35.762665927000057],
            [-80.808532367999987, 35.763896720000048],
            [-80.808597650999957, 35.764478565000047],
            [-80.808793782999942, 35.766270708000036],
            [-80.808907155999975, 35.767288625000049],
            [-80.809027765999986, 35.768402278000053],
            [-80.809141888999989, 35.769434305000061],
            [-80.80920643799999, 35.770054440000024],
            [-80.809275730999957, 35.770691151000051],
            [-80.809343197999965, 35.771296562000032],
            [-80.809412065999936, 35.77213220200008],
            [-80.809542059999956, 35.77338110200003],
            [-80.809678521999956, 35.774357155000075],
            [-80.809724668999934, 35.774781573000041],
            [-80.809772715999941, 35.775215465000031],
            [-80.809829676999982, 35.775887471000033],
            [-80.809885016999942, 35.776451568000027],
            [-80.809944297999948, 35.776991510000073],
            [-80.809987429999978, 35.777353525000024],
            [-80.810045878999972, 35.777907363000054],
            [-80.810114814999963, 35.778540551000049],
            [-80.810096261999945, 35.779048998000064],
            [-80.810038457999951, 35.77963610300003],
            [-80.809990392999964, 35.780131728000072],
            [-80.809880517999943, 35.781276611000067],
            [-80.809828249999953, 35.781804385000044],
            [-80.809739432999947, 35.782686823000063],
            [-80.809651628999973, 35.783388547000072],
            [-80.809530449999954, 35.784534665000024],
            [-80.809440602999985, 35.785831705000078],
            [-80.809382325999934, 35.78627235700003],
            [-80.80924665699996, 35.787520843000038],
            [-80.809162328999946, 35.788603837000039],
            [-80.809126315999947, 35.78914820600005],
            [-80.809075212999971, 35.78969231800005],
            [-80.809013903999983, 35.790318052000032],
            [-80.808958783999969, 35.790917312000033],
            [-80.808900392999988, 35.791515872000048],
            [-80.808853892999934, 35.792012146000047],
            [-80.808802208999964, 35.792536211000026],
            [-80.808757137999976, 35.792990926000073],
            [-80.808695420999982, 35.793631326000025],
            [-80.808652022999979, 35.79407704700003],
            [-80.808606727999972, 35.794556683000053],
            [-80.80856425099995, 35.794992805000049],
            [-80.80852103999996, 35.795458950000068],
            [-80.808493240999951, 35.795774542000061],
            [-80.808446778999951, 35.796293344000048],
            [-80.808379612999943, 35.79707529600006],
            [-80.808335417999956, 35.797642445000065],
            [-80.80829950399999, 35.798142443000074],
            [-80.808266542999945, 35.79863614900006],
            [-80.808234423999977, 35.799152799000069],
            [-80.808197373999974, 35.79978447600007],
            [-80.808154895999962, 35.800499675000026],
            [-80.808085046999963, 35.801665927000045],
            [-80.808044267999946, 35.802401088000067],
            [-80.808028254999954, 35.802711199000044],
            [-80.807983320999938, 35.803170723000051],
            [-80.807911587999968, 35.804230456000028],
            [-80.80786324799999, 35.805540391000079],
            [-80.807825046999938, 35.805938467000033],
            [-80.807733545999952, 35.807189609000034],
            [-80.807682529999965, 35.808606627000074],
            [-80.807658826999955, 35.80928087500007],
            [-80.807612214999949, 35.809996658000045],
            [-80.807504519999952, 35.811396685000034],
            [-80.80745731199994, 35.812649438000051],
            [-80.807445074999976, 35.81308941900005],
            [-80.807415015999936, 35.813604938000026],
            [-80.807357162999949, 35.814630859000033],
            [-80.807328480999956, 35.815115965000075],
            [-80.807306448999952, 35.81551005700004],
            [-80.807277411999962, 35.816050147000055],
            [-80.807254115999967, 35.816459869000028],
            [-80.807226863999972, 35.816905126000051],
            [-80.807195758999967, 35.81744939500004],
            [-80.807158537999953, 35.818148842000028],
            [-80.807125588999952, 35.818707921000055],
            [-80.807095662999984, 35.819260568000061],
            [-80.807070100999965, 35.819706336000024],
            [-80.807020700999942, 35.820591451000041],
            [-80.806964812999979, 35.821575821000067],
            [-80.806924863999939, 35.822313665000024],
            [-80.806880394999951, 35.823103348000075],
            [-80.806855151999969, 35.823566549000077],
            [-80.806823342999962, 35.82410369400003],
            [-80.806763156999978, 35.825180384000078],
            [-80.806714651999982, 35.826087942000072],
            [-80.806659096999965, 35.827079840000067],
            [-80.806602985999973, 35.828041681000059],
            [-80.806576689999986, 35.828518419000034],
            [-80.80654588799996, 35.829102443000068],
            [-80.806491925999978, 35.830039746000068],
            [-80.806399146999979, 35.831685316000062],
            [-80.806372305999957, 35.832160816000055],
            [-80.806447094999953, 35.83288230800008],
            [-80.806380041999944, 35.833411221000063],
            [-80.806320159999984, 35.833940704000042],
            [-80.80626745699999, 35.834470692000025],
            [-80.806221938999954, 35.835001122000051],
            [-80.806183611999984, 35.835531929000069],
            [-80.80615248099997, 35.836063047000039],
            [-80.806133487999944, 35.836443221000025],
            [-80.806121100999974, 35.836823575000039],
            [-80.806115321999982, 35.837204034000024],
            [-80.806116152999948, 35.837584521000053],
            [-80.806123593999985, 35.837964961000068],
            [-80.805984581999951, 35.83899800100005],
            [-80.80591740899996, 35.840195965000078],
            [-80.805873247999955, 35.841004113000054],
            [-80.805837928999949, 35.841596035000066],
            [-80.805803388999948, 35.842191202000038],
            [-80.805693376999955, 35.843700431000059],
            [-80.805639614999961, 35.845096480000052],
            [-80.805613508999954, 35.845595531000072],
            [-80.805591937999964, 35.846080918000041],
            [-80.805574466999985, 35.84656969200006],
            [-80.80556095999998, 35.84707042000008],
            [-80.805553259999954, 35.847476808000067],
            [-80.805548907999935, 35.847970036000049],
            [-80.805551363999939, 35.848486738000076],
            [-80.805561392999948, 35.849137294000059],
            [-80.805579078999983, 35.849779755000043],
            [-80.805594127999939, 35.850167131000035],
            [-80.805614623999986, 35.850588500000072],
            [-80.805652914999939, 35.851235065000026],
            [-80.805731677999972, 35.852221370000052],
            [-80.805859326999951, 35.853435495000042],
            [-80.806031737999945, 35.85466344200006],
            [-80.806249970999943, 35.855904084000031],
            [-80.806377385999951, 35.856557426000052],
            [-80.806512037999937, 35.857139084000039],
            [-80.806817724999974, 35.858367426000029],
            [-80.807166776999964, 35.859588082000073],
            [-80.807558907999976, 35.86080004300004],
            [-80.807993795999948, 35.862002309000047],
            [-80.808471076999979, 35.86319386100007],
            [-80.808749249999948, 35.863852350000059],
            [-80.808990350999977, 35.864373707000027],
            [-80.809551201999966, 35.865540890000034],
            [-80.810153166999953, 35.866694447000043],
            [-80.810795730999985, 35.867833381000025],
            [-80.811478356999942, 35.868956749000063],
            [-80.812200502999985, 35.870063650000077],
            [-80.812635084999954, 35.870703548000051],
            [-80.812961565999956, 35.871153154000069],
            [-80.813760900999966, 35.872224341000049],
            [-80.814597852999952, 35.873276331000056],
            [-80.815471724999952, 35.87430824300003],
            [-80.816381793999938, 35.875319224000066],
            [-80.817327302999956, 35.876308431000041],
            [-80.817918166999959, 35.876904617000037],
            [-80.818307464999975, 35.877275041000075],
            [-80.819321471999956, 35.878218254000046],
            [-80.820368479999956, 35.879137284000024],
            [-80.821447617999979, 35.880031368000061],
            [-80.822557998999969, 35.880899766000027],
            [-80.823698683999964, 35.881741746000046],
            [-80.824654141999986, 35.882412651000038],
            [-80.826067155999965, 35.883343679000063],
            [-80.827293005999934, 35.884102319000078],
            [-80.828545221999946, 35.884831875000032],
            [-80.829822772999989, 35.885531747000073],
            [-80.831124597999974, 35.886201355000026],
            [-80.832226271999957, 35.886737137000068],
            [-80.833796717999974, 35.887447574000078],
            [-80.83516478599995, 35.888023145000034],
            [-80.836552697999934, 35.888566385000047],
            [-80.837959313999988, 35.889076845000034],
            [-80.839383414999986, 35.889554082000075],
            [-80.840602114999967, 35.889933522000035],
            [-80.842279387999952, 35.890407356000026],
            [-80.843748839999989, 35.890782681000076],
            [-80.84523100399997, 35.891123378000032],
            [-80.846724649999942, 35.891429164000044],
            [-80.848228493999954, 35.891699775000063],
            [-80.849530369999968, 35.891905885000028],
            [-80.851261821999969, 35.892134618000057],
            [-80.852788775999954, 35.892298486000072],
            [-80.854320915999949, 35.892426464000039],
            [-80.85585697099998, 35.892518443000029],
            [-80.857395633999943, 35.892574345000071],
            [-80.858743089999962, 35.89259504000006],
            [-80.860475702999963, 35.892577766000045],
            [-80.862014558999988, 35.892525282000065],
            [-80.863550901999986, 35.892436716000077],
            [-80.86508345499999, 35.892312142000037],
            [-80.866610965999939, 35.892151665000029],
            [-80.867963753999959, 35.891980303000025],
            [-80.86964575099995, 35.891723561000049],
            [-80.871150478999937, 35.891456294000079],
            [-80.872645124999963, 35.891153831000054],
            [-80.874128457999973, 35.890816421000068],
            [-80.875599182999963, 35.890444357000035],
            [-80.876915586999985, 35.890080133000026],
            [-80.878497988999982, 35.889597524000067],
            [-80.879923694999945, 35.889123449000067],
            [-80.881331995999972, 35.888616124000066],
            [-80.882721726999989, 35.888075970000045],
            [-80.884091731999945, 35.887503435000042],
            [-80.885329955999964, 35.886951554000063],
            [-80.886768040999982, 35.886263150000048],
            [-80.888072083999987, 35.885596448000058],
            [-80.889351978999969, 35.884899416000053],
            [-80.890606659999946, 35.884172631000069],
            [-80.891835036999964, 35.883416726000064],
            [-80.89287264099994, 35.882744616000025],
            [-80.893364156999951, 35.882389906000071],
            [-80.894199661999949, 35.881811233000064],
            [-80.894888546999937, 35.881317682000031],
            [-80.895560183999976, 35.880812457000047],
            [-80.896461663999958, 35.880111274000058],
            [-80.897113702999945, 35.879586846000052],
            [-80.897749643999987, 35.879049968000061],
            [-80.898599826999941, 35.878307775000053],
            [-80.899212724999984, 35.877754481000068],
            [-80.899755437999943, 35.87724146000005],
            [-80.900606983999978, 35.876406691000057],
            [-80.901169219999986, 35.875836553000056],
            [-80.901534805999972, 35.875451675000079],
            [-80.90246390599998, 35.874451961000034],
            [-80.90354182699997, 35.87320330700004],
            [-80.904382298999963, 35.872153182000034],
            [-80.905153218999942, 35.871129043000053],
            [-80.90594992299998, 35.869995970000048],
            [-80.906675772999961, 35.868890678000071],
            [-80.907362170999988, 35.867768813000055],
            [-80.908008548999987, 35.866631306000045],
            [-80.908614366999984, 35.865479108000045],
            [-80.909164524999937, 35.864345901000036],
            [-80.909702355999968, 35.863134474000049],
            [-80.910183625999935, 35.86194398300006],
            [-80.910622536999938, 35.860742696000045],
            [-80.911018724999963, 35.859531615000037],
            [-80.911371864999978, 35.85831173400004],
            [-80.911677951999934, 35.857101211000042],
            [-80.911947870999938, 35.855849642000067],
            [-80.912170255999968, 35.854609503000063],
            [-80.912348645999941, 35.853364647000035],
            [-80.912482891999957, 35.852116116000047],
            [-80.912572882999939, 35.850864958000045],
            [-80.912618546999965, 35.849612206000074],
            [-80.912619847999963, 35.84835890100004],
            [-80.912611422999987, 35.847870558000068],
            [-80.912633866999954, 35.847468592000041],
            [-80.912715840999965, 35.846368682000048],
            [-80.912749117999965, 35.845823587000041],
            [-80.912796276999984, 35.844774575000031],
            [-80.912815911999985, 35.844250419000048],
            [-80.912838567999984, 35.84373862800004],
            [-80.912864737999939, 35.843269838000026],
            [-80.912895062999951, 35.842722909000031],
            [-80.912915683999984, 35.84233617600006],
            [-80.912973014999977, 35.841326029000072],
            [-80.913007745999948, 35.840664281000045],
            [-80.913068062999969, 35.839605478000067],
            [-80.913096096999936, 35.839080855000077],
            [-80.913123844999973, 35.838579312000036],
            [-80.913154047999967, 35.838001920000067],
            [-80.913180094999973, 35.837515187000065],
            [-80.913205411999968, 35.837065748000043],
            [-80.913230878999968, 35.836602625000069],
            [-80.913296410999976, 35.835469940000053],
            [-80.913369260999957, 35.834093084000074],
            [-80.913409885999954, 35.833379007000076],
            [-80.913464570999963, 35.832374167000069],
            [-80.913520793999965, 35.831381774000079],
            [-80.913552290999974, 35.83079755600005],
            [-80.913574805999986, 35.830365224000047],
            [-80.913608873999976, 35.829764859000079],
            [-80.913685391999934, 35.828346306000071],
            [-80.91375101899996, 35.827164254000024],
            [-80.913789834999989, 35.826436905000037],
            [-80.913821721999966, 35.82587081500003],
            [-80.91385487499997, 35.825230733000069],
            [-80.91388821399994, 35.824642073000064],
            [-80.913924646999988, 35.824000987000034],
            [-80.91394556399996, 35.82361684600005],
            [-80.914003433999937, 35.822603451000077],
            [-80.914038461999951, 35.821952042000078],
            [-80.914092296999968, 35.820997039000076],
            [-80.914139116999934, 35.820113381000056],
            [-80.914178245999949, 35.819409225000072],
            [-80.914237659999969, 35.818345198000031],
            [-80.914275518999943, 35.817687273000047],
            [-80.914312934999941, 35.816979160000074],
            [-80.914347058999965, 35.81637586800008],
            [-80.914376656999934, 35.815864513000065],
            [-80.914404108999975, 35.815327745000047],
            [-80.914450253999973, 35.814511733000074],
            [-80.914489059999937, 35.813797961000034],
            [-80.914522396999985, 35.813188527000079],
            [-80.914599805999956, 35.812080016000039],
            [-80.914638973999956, 35.811291260000075],
            [-80.914658696999936, 35.810842424000043],
            [-80.914675555999963, 35.810422073000041],
            [-80.914706343999967, 35.80987512400003],
            [-80.914735617999952, 35.809302030000026],
            [-80.914770684999951, 35.808696027000053],
            [-80.914799236999954, 35.808325506000074],
            [-80.914826442999981, 35.807932506000043],
            [-80.914852810999946, 35.807511400000067],
            [-80.91487776799994, 35.807116842000028],
            [-80.914904886999977, 35.806619559000069],
            [-80.914926109999953, 35.80617198200008],
            [-80.914961630999983, 35.805417558000045],
            [-80.914990892999981, 35.804626606000056],
            [-80.915014025999938, 35.80418241600006],
            [-80.91507495899998, 35.803224385000078],
            [-80.915101475999961, 35.802835752000078],
            [-80.915132484999958, 35.802481589000024],
            [-80.91518376199997, 35.801928728000064],
            [-80.915225815999975, 35.801438244000053],
            [-80.915368776999969, 35.799946959000067],
            [-80.915418382999974, 35.799413980000054],
            [-80.915466625999954, 35.79891679800005],
            [-80.915543868999976, 35.798090105000028],
            [-80.915619900999957, 35.797310817000039],
            [-80.915679889999979, 35.796668540000042],
            [-80.91575232799994, 35.795901873000048],
            [-80.915837124999939, 35.795149683000034],
            [-80.915890631999957, 35.794592895000051],
            [-80.915942886999972, 35.794077002000051],
            [-80.915980695999963, 35.793681954000078],
            [-80.916014349999955, 35.793280326000058],
            [-80.91607953099998, 35.792433679000055],
            [-80.916169568999976, 35.791471887000057],
            [-80.916229574999988, 35.790845674000025],
            [-80.916280215999961, 35.790297552000027],
            [-80.916326125999944, 35.789815866000026],
            [-80.916372539999941, 35.789319749000072],
            [-80.916428326999949, 35.788739902000032],
            [-80.916471411999964, 35.788272025000026],
            [-80.916556656999944, 35.787359307000031],
            [-80.916629052999951, 35.786606060000054],
            [-80.916694610999969, 35.785908636000045],
            [-80.916751916999942, 35.785314479000078],
            [-80.916816419999975, 35.784607393000044],
            [-80.916868285999954, 35.784098392000033],
            [-80.916916367999988, 35.783588122000026],
            [-80.916977460999988, 35.782853419000048],
            [-80.917039500999977, 35.781965250000042],
            [-80.917087803999948, 35.781087310000032],
            [-80.917123534999973, 35.780244444000061],
            [-80.917142745999968, 35.779689846000053],
            [-80.917155156999968, 35.77925556100007],
            [-80.917162082999937, 35.778743076000069],
            [-80.917159044999948, 35.777618699000072],
            [-80.917153739999947, 35.777287114000046],
            [-80.917141661999949, 35.776682496000035],
            [-80.917102264999983, 35.775829890000068],
            [-80.917011190999972, 35.774535626000045],
            [-80.916984613999944, 35.774182662000044],
            [-80.91693990899995, 35.773649803000069],
            [-80.916887334999956, 35.77308825700004],
            [-80.916839250999942, 35.772623734000035],
            [-80.916785968999989, 35.772139396000057],
            [-80.916687783999976, 35.771094383000047],
            [-80.916600029999984, 35.770269323000036],
            [-80.916536401999963, 35.769680944000072],
            [-80.916450544999975, 35.768916604000026],
            [-80.916384640999979, 35.768298238000057],
            [-80.916177703999949, 35.767737025000031],
            [-80.916137144999936, 35.767486579000035],
            [-80.916103686999975, 35.767235436000078],
            [-80.916077349999966, 35.766983730000049],
            [-80.916058146999944, 35.766731597000046],
            [-80.916046086999984, 35.766479169000036],
            [-80.916041177999944, 35.766226582000058],
            [-80.916043421999973, 35.765973969000072],
            [-80.916052817999969, 35.765721466000059],
            [-80.915969360999952, 35.76473240200005],
            [-80.915923785999951, 35.764250046000029],
            [-80.915881859999956, 35.763835099000062],
            [-80.91581062399996, 35.763325823000059],
            [-80.915727549999986, 35.762704824000025],
            [-80.915641281999967, 35.762114824000037],
            [-80.915502858999957, 35.761343176000025],
            [-80.915319981999971, 35.760749901000054],
            [-80.915434871999935, 35.759932870000057],
            [-80.915362613999946, 35.759288067000057],
            [-80.91531078099996, 35.758838618000027],
            [-80.91514078299997, 35.757286124000075],
            [-80.915083268999979, 35.756766847000051],
            [-80.915032873999962, 35.756317963000072],
            [-80.914818620999938, 35.754365594000035],
            [-80.914762379999956, 35.753870249000045],
            [-80.91460725799999, 35.752471580000076],
            [-80.914546365999968, 35.751915500000052],
            [-80.914490877999981, 35.75141773100006],
            [-80.914435220999962, 35.75090724000006],
            [-80.914369006999948, 35.750330985000062],
            [-80.914248260999955, 35.749230858000033],
            [-80.914191897999956, 35.748703414000033],
            [-80.914121064999961, 35.748067930000047],
            [-80.914011501999937, 35.747088062000046],
            [-80.913955312999974, 35.746605427000077],
            [-80.913890805999984, 35.746006697000041],
            [-80.91382546799997, 35.745423494000079],
            [-80.913724288999958, 35.744509047000065],
            [-80.913666628999977, 35.743991264000044],
            [-80.913597019999941, 35.743345929000043],
            [-80.913545463999981, 35.742903951000073],
            [-80.913468192999972, 35.742226487000039],
            [-80.913385216999984, 35.741464912000026],
            [-80.913294198999949, 35.74064544600003],
            [-80.913222488999963, 35.739980909000053],
            [-80.913169862999951, 35.739489347000074],
            [-80.913086246999967, 35.738760152000054],
            [-80.912981746999947, 35.737834930000076],
            [-80.912917843999935, 35.737241992000065],
            [-80.912845123999944, 35.736602042000072],
            [-80.912765158999946, 35.735879480000051],
            [-80.912714173999973, 35.735401514000046],
            [-80.912623919999987, 35.734602811000059],
            [-80.912550502999977, 35.73393344200008],
            [-80.91247349799994, 35.733249869000076],
            [-80.912404265999953, 35.73260859100003],
            [-80.912328228999968, 35.731916984000065],
            [-80.912236605999965, 35.731102511000074],
            [-80.912187407999966, 35.730638767000073],
            [-80.912135846999945, 35.730166580000059],
            [-80.912081508999961, 35.729681392000032],
            [-80.911971917999949, 35.72870643400006],
            [-80.91190022699999, 35.728054607000047],
            [-80.911841849999973, 35.727520728000059],
            [-80.911739986999976, 35.726618300000041],
            [-80.911687166999968, 35.726096128000052],
            [-80.911643921999939, 35.725629938000054],
            [-80.911592267999936, 35.725114655000027],
            [-80.911532075999958, 35.724558807000051],
            [-80.911424644999954, 35.723722959000042],
            [-80.911321442999963, 35.722824555000045],
            [-80.911250716999973, 35.722225129000037],
            [-80.911180325999965, 35.721672066000053],
            [-80.911043248999988, 35.720616392000068],
            [-80.910948151999946, 35.719956745000047],
            [-80.910867260999964, 35.719415952000077],
            [-80.910709016999988, 35.718464578000066],
            [-80.910614850999934, 35.717845993000026],
            [-80.910544954999978, 35.717417152000053],
            [-80.910382044999949, 35.716389309000078],
            [-80.910283872999969, 35.715863171000024],
            [-80.91010390799994, 35.714971253000044],
            [-80.910001429999966, 35.714495861000046],
            [-80.909902493999937, 35.713971515000026],
            [-80.910018438999941, 35.713585838000029],
            [-80.910247979999951, 35.712855730000058],
            [-80.910454215999948, 35.712166717000059],
            [-80.91066111799995, 35.711431615000038],
            [-80.910855698999967, 35.710700956000039],
            [-80.910979190999967, 35.710207438000054],
            [-80.911118148999947, 35.709622205000073],
            [-80.911232661999975, 35.709110856000052],
            [-80.911335845999986, 35.708623325000076],
            [-80.911435392999977, 35.708125120000034],
            [-80.911534478999954, 35.707596590000037],
            [-80.91162247799997, 35.707093564000047],
            [-80.91172697799999, 35.706443426000078],
            [-80.911852307999936, 35.705576656000062],
            [-80.911935981999989, 35.704925612000068],
            [-80.911992077999969, 35.704438184000026],
            [-80.912034971999958, 35.704030755000076],
            [-80.912096368999983, 35.703367988000025],
            [-80.912150260999965, 35.702686954000058],
            [-80.912199915999963, 35.701871069000049],
            [-80.912227648999988, 35.701220939000052],
            [-80.912249989999964, 35.700349435000078],
            [-80.912255529999982, 35.69951254800003],
            [-80.912249145999965, 35.698855582000078],
            [-80.912232868999979, 35.698224137000068],
            [-80.912202831999934, 35.697485894000067],
            [-80.912164655999959, 35.696830596000041],
            [-80.912104487999954, 35.696017500000039],
            [-80.912028739999982, 35.695199660000071],
            [-80.911926023999968, 35.694284301000039],
            [-80.911805544999936, 35.693376905000036],
            [-80.911699314999964, 35.69267227000006],
            [-80.911606310999957, 35.692113845000051],
            [-80.911529652999945, 35.691682983000078],
            [-80.911439688999963, 35.69120711100004],
            [-80.911346897999977, 35.690743372000043],
            [-80.91125563199995, 35.690310550000049],
            [-80.911099078999939, 35.689615954000033],
            [-80.910895953999955, 35.688779442000055],
            [-80.910674959999938, 35.68793528100008],
            [-80.910486874999947, 35.687261160000048],
            [-80.910279380999953, 35.686565330000064],
            [-80.909994433999941, 35.685668445000033],
            [-80.909769427999947, 35.685000974000047],
            [-80.909604498999954, 35.684533692000059],
            [-80.909346184999947, 35.683862597000029],
            [-80.909333244999971, 35.683307837000029],
            [-80.909670465999966, 35.682992308000053],
            [-80.909887461999972, 35.682426732000067],
            [-80.910098642999969, 35.681855754000026],
            [-80.910254407999957, 35.681418575000066],
            [-80.910377176999987, 35.681064155000058],
            [-80.910567944999968, 35.680500474000041],
            [-80.910750230999952, 35.67994772600008],
            [-80.910933307999983, 35.679375604000029],
            [-80.911069607999934, 35.678937361000067],
            [-80.911250140999982, 35.678337147000036],
            [-80.911384658999964, 35.677871771000071],
            [-80.911537770999985, 35.677318661000072],
            [-80.911735089999979, 35.676567785000032],
            [-80.911898658999974, 35.675908610000079],
            [-80.911993009999946, 35.675508882000031],
            [-80.912147913999945, 35.674824147000038],
            [-80.91229890599999, 35.674120462000076],
            [-80.912422872999969, 35.673503671000049],
            [-80.912548091999952, 35.672837620000053],
            [-80.912639162999938, 35.672320483000078],
            [-80.912733809999963, 35.671747888000027],
            [-80.912823215999936, 35.67116769900008],
            [-80.91288517299995, 35.670739726000079],
            [-80.912938031999943, 35.670351887000038],
            [-80.913004587999978, 35.669834818000027],
            [-80.913066716999936, 35.669316983000044],
            [-80.913123956999982, 35.668803127000047],
            [-80.91317051599998, 35.668352791000075],
            [-80.913286061999941, 35.667727649000028],
            [-80.91354802799998, 35.667172303000029],
            [-80.913833396999962, 35.666554882000071],
            [-80.914119945999971, 35.665911957000048],
            [-80.914360835999958, 35.665350578000073],
            [-80.914677610999945, 35.664583407000066],
            [-80.91501317899997, 35.663721549000059],
            [-80.91519763499997, 35.663220725000031],
            [-80.915369398999985, 35.662754150000069],
            [-80.915524912999956, 35.662325360000068],
            [-80.915698202999977, 35.661827306000077],
            [-80.915869593999957, 35.661311056000045],
            [-80.91600723199997, 35.660868081000046],
            [-80.916127862999986, 35.660468929000047],
            [-80.916269994999936, 35.659989629000052],
            [-80.916402109999979, 35.659531488000027],
            [-80.916557577999981, 35.658986151000079],
            [-80.916721418999941, 35.658409866000056],
            [-80.916883197999937, 35.657774507000056],
            [-80.917079522999984, 35.65695504200005],
            [-80.917220329999964, 35.656324674000075],
            [-80.917361562999986, 35.655648843000051],
            [-80.917489953999961, 35.654989015000069],
            [-80.917578527999979, 35.654503007000073],
            [-80.917686733999972, 35.653861533000054],
            [-80.917808038999965, 35.653071136000051],
            [-80.917915196999957, 35.652286996000043],
            [-80.917984064999985, 35.651728763000051],
            [-80.918041715999948, 35.651207561000035],
            [-80.918110575999947, 35.650507335000043],
            [-80.918164624999974, 35.649867439000047],
            [-80.918210338999984, 35.649230167000042],
            [-80.918250561999969, 35.648570091000067],
            [-80.918281898999965, 35.647890337000035],
            [-80.918300906999946, 35.647304916000053],
            [-80.918313069999954, 35.646654717000047],
            [-80.918316072999971, 35.645990682000047],
            [-80.918311184999936, 35.645449143000064],
            [-80.918294389999971, 35.644668653000053],
            [-80.918274164999957, 35.644071813000039],
            [-80.918245893999938, 35.643484683000054],
            [-80.918198522999944, 35.642729661000033],
            [-80.918148726999959, 35.642082046000041],
            [-80.918106470999987, 35.641601460000061],
            [-80.918049617999941, 35.641022507000059],
            [-80.917976098999986, 35.640354338000066],
            [-80.917880277999984, 35.639585213000032],
            [-80.917774435999945, 35.638811907000047],
            [-80.917670544999964, 35.638079201000039],
            [-80.917560561999949, 35.637311975000046],
            [-80.917420521999986, 35.636349487000075],
            [-80.917320779999955, 35.635655391000057],
            [-80.917260010999939, 35.635247797000034],
            [-80.917195242999981, 35.634790275000057],
            [-80.917094950999967, 35.63408232300003],
            [-80.916892970999982, 35.63270404900004],
            [-80.916812971999946, 35.632132653000042],
            [-80.916742285999987, 35.63163640700003],
            [-80.916662487999986, 35.631089768000038],
            [-80.916593605999935, 35.630628239000032],
            [-80.916527291999955, 35.630165733000069],
            [-80.916443766999976, 35.629585261000045],
            [-80.916341671999987, 35.628859250000062],
            [-80.916247354999939, 35.628206385000055],
            [-80.916109118999941, 35.627254912000069],
            [-80.916041174999975, 35.626771869000038],
            [-80.915928636999979, 35.626002638000045],
            [-80.915830237999955, 35.625374647000058],
            [-80.915734357999952, 35.624800596000057],
            [-80.915634836999971, 35.624236813000039],
            [-80.915531414999975, 35.623683960000051],
            [-80.915349078999952, 35.622786331000043],
            [-80.915253901999961, 35.62235196000006],
            [-80.915107620999947, 35.621715466000069],
            [-80.914936836999971, 35.621004367000069],
            [-80.914800401999969, 35.620462209000038],
            [-80.914674138999942, 35.619981234000079],
            [-80.914542883999957, 35.619499870000027],
            [-80.914383890999943, 35.618940097000063],
            [-80.914164708999976, 35.618203046000076],
            [-80.913970454999969, 35.617577044000029],
            [-80.913811498999962, 35.617084219000049],
            [-80.913650805999964, 35.61660112800007],
            [-80.913460899999961, 35.61602663900004],
            [-80.913163882999982, 35.615179443000045],
            [-80.912935565999987, 35.614560515000051],
            [-80.912726128999964, 35.614018455000064],
            [-80.912496215999965, 35.61344897500004],
            [-80.912234836999971, 35.612822762000064],
            [-80.911971076999976, 35.612207419000072],
            [-80.911739218999969, 35.61168542300004],
            [-80.911810511999988, 35.61110345700007],
            [-80.911908447999963, 35.610542935000069],
            [-80.911979751999979, 35.609327047000079],
            [-80.912089899999955, 35.608893568000042],
            [-80.912183117999973, 35.608442835000062],
            [-80.912346964999983, 35.607911228000034],
            [-80.912434725999958, 35.607453828000075],
            [-80.912535915999968, 35.607031728000038],
            [-80.912638885999968, 35.606490643000029],
            [-80.912721089999934, 35.606031804000054],
            [-80.912788215999967, 35.605581310000048],
            [-80.912846226999989, 35.605119345000048],
            [-80.912922561999949, 35.604545699000028],
            [-80.913007068999946, 35.60394169500006],
            [-80.91308597799997, 35.603160238000044],
            [-80.913088757999958, 35.602433532000077],
            [-80.913092536999955, 35.601952733000076],
            [-80.91332652899996, 35.601513092000062],
            [-80.913382164999973, 35.600849532000041],
            [-80.91343216599995, 35.600174908000042],
            [-80.913472580999951, 35.599488468000061],
            [-80.913494602999947, 35.599013422000041],
            [-80.913519379999968, 35.598317927000039],
            [-80.91353673399999, 35.597635756000045],
            [-80.913544251999951, 35.596842202000062],
            [-80.913541531999954, 35.596232280000038],
            [-80.913535852999985, 35.595795790000068],
            [-80.913518118999946, 35.595086061000075],
            [-80.913492347999977, 35.594412899000076],
            [-80.913463387999968, 35.593834192000031],
            [-80.913443091999966, 35.593483325000079],
            [-80.913416502999951, 35.593080031000056],
            [-80.913375894999945, 35.592538554000043],
            [-80.913328305999983, 35.591984402000037],
            [-80.913287819999937, 35.591559372000063],
            [-80.913244523999936, 35.591141953000033],
            [-80.913190550999957, 35.590659014000039],
            [-80.913129433999984, 35.590152828000043],
            [-80.913063591999958, 35.589646662000064],
            [-80.912982126999964, 35.589066519000028],
            [-80.912903292999943, 35.588544803000048],
            [-80.912813222999944, 35.587998718000051],
            [-80.91272758599996, 35.587497875000054],
            [-80.912634139999966, 35.586979765000024],
            [-80.912531422999962, 35.586446865000028],
            [-80.912403063999989, 35.585822976000031],
            [-80.912269725999977, 35.585210204000077],
            [-80.912101606999954, 35.584476826000071],
            [-80.911941837999962, 35.583815732000062],
            [-80.911742502999971, 35.58303869100007],
            [-80.911570787999949, 35.582332350000058],
            [-80.911435142999949, 35.581816452000055],
            [-80.911294340999973, 35.581301640000049],
            [-80.911116818999972, 35.580670045000033],
            [-80.910905673999935, 35.579880790000061],
            [-80.91075305399994, 35.579309200000068],
            [-80.910608836999984, 35.578766097000027],
            [-80.910504514999957, 35.57837145700006],
            [-80.910364724999965, 35.57783966900007],
            [-80.910225982999975, 35.57732784500007],
            [-80.910122167999987, 35.576945414000079],
            [-80.909974094999939, 35.57638849600005],
            [-80.910022501999947, 35.575858326000059],
            [-80.910218067999949, 35.575150693000069],
            [-80.910353745999942, 35.574650333000079],
            [-80.910479201999976, 35.57417913300003],
            [-80.910587916999987, 35.573758076000047],
            [-80.910705168999982, 35.573281850000058],
            [-80.910822907999943, 35.57277911400007],
            [-80.91095330099995, 35.572193024000057],
            [-80.911082120999936, 35.571576579000066],
            [-80.911198113999944, 35.570983827000077],
            [-80.911302506999959, 35.570413849000033],
            [-80.911398499999962, 35.569859435000069],
            [-80.911484990999952, 35.569325513000024],
            [-80.91157041699995, 35.568758135000053],
            [-80.911652076999985, 35.568169614000055],
            [-80.911732195999946, 35.567533016000027],
            [-80.911796817999971, 35.566958793000026],
            [-80.911855620999972, 35.566370496000047],
            [-80.911909049999963, 35.565756787000055],
            [-80.911955584999987, 35.565126598000063],
            [-80.91199292999994, 35.564510344000041],
            [-80.912025255999936, 35.563817005000033],
            [-80.912043308999955, 35.563294129000042],
            [-80.912056829999983, 35.562719662000063],
            [-80.912063917999944, 35.562135970000043],
            [-80.912064732999966, 35.561628213000063],
            [-80.91205950899996, 35.56107125300008],
            [-80.912036546999957, 35.560163283000065],
            [-80.911992805999944, 35.55948741800006],
            [-80.911950659999945, 35.558593423000048],
            [-80.911875239999972, 35.557570054000053],
            [-80.911841799999934, 35.557174789000044],
            [-80.911782140999946, 35.556572589000041],
            [-80.911693191999973, 35.555864314000075],
            [-80.911586247999935, 35.555083861000071],
            [-80.911472782999965, 35.554193982000072],
            [-80.911423947999936, 35.553857781000033],
            [-80.911492686999964, 35.55355038600004],
            [-80.91158074699996, 35.553148171000032],
            [-80.911718231999942, 35.552514219000045],
            [-80.911821885999984, 35.552037860000041],
            [-80.911916291999944, 35.551613724000049],
            [-80.912029114999939, 35.551099542000031],
            [-80.912162587999944, 35.550500681000074],
            [-80.91227384299998, 35.549989949000064],
            [-80.912365098999942, 35.549579651000045],
            [-80.91246492099998, 35.549124901000027],
            [-80.912720150999974, 35.547972163000054],
            [-80.91284776599997, 35.547373709000055],
            [-80.912945756999989, 35.546905020000054],
            [-80.913032688999976, 35.546485612000026],
            [-80.913156225999955, 35.545923390000041],
            [-80.913289716999941, 35.545287034000069],
            [-80.913402367999936, 35.544774177000079],
            [-80.91350222799997, 35.544363084000054],
            [-80.913657587999978, 35.54369212000006],
            [-80.913814041999956, 35.542976254000052],
            [-80.91397439299999, 35.542225083000062],
            [-80.914084399999979, 35.541723307000041],
            [-80.914307472999951, 35.540639790000057],
            [-80.914391031999969, 35.540260934000059],
            [-80.91448010199997, 35.539849491000041],
            [-80.91461095599999, 35.539259714000025],
            [-80.914701894999951, 35.53884307800007],
            [-80.914851344999988, 35.538169656000036],
            [-80.914941158999966, 35.537751677000074],
            [-80.915056102999984, 35.537227821000045],
            [-80.915185751999957, 35.536639831000059],
            [-80.915353866999965, 35.535879236000028],
            [-80.915501863999964, 35.535227164000048],
            [-80.915684060999979, 35.534465495000063],
            [-80.915762799999982, 35.534118530000057],
            [-80.915938532999974, 35.53332792100008],
            [-80.916088083999966, 35.53262972400006],
            [-80.91625552499994, 35.531871927000054],
            [-80.91639841999995, 35.531197478000024],
            [-80.916495096999938, 35.530718709000041],
            [-80.916578397999956, 35.530308641000033],
            [-80.916766546999952, 35.529460787000062],
            [-80.916855234999957, 35.529053943000065],
            [-80.916965042999948, 35.528554561000078],
            [-80.917052402999957, 35.528154744000062],
            [-80.917164269999944, 35.527654976000065],
            [-80.917345166999951, 35.52684152900008],
            [-80.917438252999943, 35.526418371000034],
            [-80.917566845999943, 35.525821915000051],
            [-80.917706306999946, 35.525145259000055],
            [-80.917808854999976, 35.524620595000044],
            [-80.917905419999954, 35.52410069900003],
            [-80.917853949999937, 35.523393817000056],
            [-80.917988874999935, 35.522849850000057],
            [-80.91809539999997, 35.522325651000074],
            [-80.918238857999938, 35.52176053200003],
            [-80.918333541999971, 35.521255170000074],
            [-80.918411703999936, 35.520700617000045],
            [-80.918482753999967, 35.520172369000079],
            [-80.918547066999963, 35.51968024100006],
            [-80.918616228999952, 35.519017046000045],
            [-80.918660043999978, 35.518506898000055],
            [-80.918712299999981, 35.517843222000067],
            [-80.918754029999945, 35.516994747000069],
            [-80.918707373999951, 35.516300784000066],
            [-80.91888634999998, 35.515580572000033],
            [-80.918939750999982, 35.515002449000065],
            [-80.918965316999959, 35.514688312000033],
            [-80.91902421399999, 35.513973732000068],
            [-80.919101722999983, 35.513140583000052],
            [-80.91917254699996, 35.512494693000065],
            [-80.91958399799995, 35.51166316900003],
            [-80.919919931999971, 35.510983599000042],
            [-80.920166799999947, 35.510482617000036],
            [-80.92052688199999, 35.509772243000043],
            [-80.920766717999982, 35.509288812000079],
            [-80.92116189799998, 35.508474532000037],
            [-80.921483941999952, 35.507826934000036],
            [-80.921771027999966, 35.507255646000033],
            [-80.922115022999947, 35.506563418000042],
            [-80.92247262099994, 35.505845062000049],
            [-80.922819660999949, 35.505133208000075],
            [-80.923107594999976, 35.504543682000076],
            [-80.923303112999974, 35.504147064000051],
            [-80.923458098999959, 35.503827706000038],
            [-80.923696912999958, 35.503345128000035],
            [-80.923915744999988, 35.502886800000056],
            [-80.924267085999986, 35.502122199000041],
            [-80.924500733999935, 35.50159220900008],
            [-80.924794717999987, 35.500899372000049],
            [-80.924997910999934, 35.50041058100004],
            [-80.925240853999981, 35.499793405000048],
            [-80.925571108999975, 35.498909444000049],
            [-80.92580596199997, 35.498246744000028],
            [-80.925977263999982, 35.497740030000045],
            [-80.926098808999939, 35.497366299000078],
            [-80.926222111999948, 35.496974202000047],
            [-80.926359035999951, 35.496524008000051],
            [-80.926472806999982, 35.49613643400005],
            [-80.926691276999975, 35.495351303000064],
            [-80.926870593999979, 35.494665932000032],
            [-80.926998975999936, 35.494141209000077],
            [-80.927154151999957, 35.49346935300008],
            [-80.927258497999958, 35.492982728000072],
            [-80.927417795999986, 35.492186852000032],
            [-80.92752533099997, 35.491602391000072],
            [-80.92763946499997, 35.490913268000043],
            [-80.927695770999946, 35.490546290000054],
            [-80.927778172999979, 35.489966032000041],
            [-80.927832055999943, 35.489552770000046],
            [-80.927887474999977, 35.489090938000061],
            [-80.927943543999959, 35.488555801000075],
            [-80.928006707999941, 35.487837461000026],
            [-80.928055899999947, 35.48722391900003],
            [-80.928093360999981, 35.486645059000068],
            [-80.92813321999995, 35.485763013000053],
            [-80.928158042999939, 35.484741052000061],
            [-80.928164627999934, 35.484039001000042],
            [-80.928168738999943, 35.483484347000058],
            [-80.928175150999948, 35.482159546000048],
            [-80.928174155999955, 35.481566624000038],
            [-80.92817602599996, 35.481172308000055],
            [-80.928188333999969, 35.480543906000037],
            [-80.928197897999951, 35.479528005000077],
            [-80.92819588499998, 35.479138920000025],
            [-80.928186721999964, 35.47839450400005],
            [-80.928191121999987, 35.477245348000054],
            [-80.928204366999978, 35.476736613000071],
            [-80.928227220999986, 35.476238670000043],
            [-80.928248525999948, 35.475767488000031],
            [-80.928276852999943, 35.475364219000028],
            [-80.928303694999954, 35.475022840000065],
            [-80.928347145999965, 35.474554727000054],
            [-80.928391371999965, 35.47415473500007],
            [-80.928426925999986, 35.473809600000038],
            [-80.928489344999946, 35.473270438000043],
            [-80.928548670999987, 35.472720432000074],
            [-80.928637039999956, 35.471916738000061],
            [-80.928730146999953, 35.471137798000029],
            [-80.92879781399995, 35.470535163000079],
            [-80.928846169999986, 35.470083526000053],
            [-80.928886019999936, 35.469694471000025],
            [-80.928946505999988, 35.469036263000078],
            [-80.928990012999975, 35.468515143000047],
            [-80.929049747999954, 35.467659670000046],
            [-80.929076911999971, 35.467167434000032],
            [-80.929102970999963, 35.466583423000031],
            [-80.929122086999939, 35.466000496000049],
            [-80.92913180499994, 35.465548937000051],
            [-80.929138679999937, 35.464949980000029],
            [-80.929131378999955, 35.463927925000064],
            [-80.929118875999961, 35.463380975000064],
            [-80.929101913999943, 35.462842078000051],
            [-80.929088202999935, 35.462467941000057],
            [-80.929059450999944, 35.461910077000027],
            [-80.928984451999952, 35.460795078000046],
            [-80.928919820999965, 35.46005219400007],
            [-80.928872579999961, 35.459583862000045],
            [-80.92882689299995, 35.459171242000025],
            [-80.928729285999964, 35.458383162000075],
            [-80.928659220999975, 35.457875175000027],
            [-80.928559775999986, 35.45721876500005],
            [-80.928444966999962, 35.456520122000029],
            [-80.928344608999964, 35.455952945000035],
            [-80.928258891999974, 35.455488638000077],
            [-80.928161225999986, 35.454986346000055],
            [-80.928058052999972, 35.454486389000067],
            [-80.92783045699997, 35.453474198000038],
            [-80.927687148999951, 35.452883022000037],
            [-80.927516241999967, 35.452220340000054],
            [-80.927335300999971, 35.451553999000055],
            [-80.927224330999934, 35.451165044000049],
            [-80.92708257999999, 35.450686010000027],
            [-80.926987577999967, 35.450372119000065],
            [-80.92676877599996, 35.449663991000079],
            [-80.926626793999958, 35.449229225000067],
            [-80.926457150999966, 35.448729159000038],
            [-80.926220335999972, 35.448059045000036],
            [-80.926090388999967, 35.447702091000053],
            [-80.925965749999989, 35.44736673500006],
            [-80.925682868999957, 35.446630693000031],
            [-80.925421805999974, 35.44597968000005],
            [-80.925083683999958, 35.445166845000074],
            [-80.924860448999937, 35.444652251000036],
            [-80.924456602999953, 35.443761373000029],
            [-80.924218007999968, 35.443254248000073],
            [-80.92403471199998, 35.44286909300007],
            [-80.923791679999965, 35.442375195000068],
            [-80.92325157199997, 35.44132116600008],
            [-80.922787346999939, 35.440536230000077],
            [-80.922652788999983, 35.439986332000046],
            [-80.922697750999987, 35.439283452000041],
            [-80.922706252999944, 35.438763068000071],
            [-80.922710971999948, 35.438292506000039],
            [-80.922708700999976, 35.437553816000047],
            [-80.922701304999975, 35.437093168000047],
            [-80.922686053999939, 35.436557997000079],
            [-80.922634451999954, 35.435223471000029],
            [-80.922602025999936, 35.434638512000049],
            [-80.922517948999939, 35.433469519000028],
            [-80.922454534999986, 35.432776650000051],
            [-80.922414514999957, 35.432414874000074],
            [-80.922340952999946, 35.431762028000037],
            [-80.922227500999952, 35.430888537000044],
            [-80.922157518999938, 35.430400244000054],
            [-80.922106480999958, 35.430061885000043],
            [-80.922014415999968, 35.42948549700003],
            [-80.921921704999988, 35.428952673000026],
            [-80.921846623999954, 35.428546951000044],
            [-80.921720353999945, 35.427906569000072],
            [-80.92147844699997, 35.426788326000064],
            [-80.921366360999968, 35.426311725000062],
            [-80.921262083999977, 35.425860965000027],
            [-80.921133140999984, 35.425327400000072],
            [-80.921000445999937, 35.424808579000057],
            [-80.92087556499996, 35.424338102000036],
            [-80.920703814999968, 35.423709888000076],
            [-80.92043840599996, 35.422795992000033],
            [-80.920276987999955, 35.422269162000077],
            [-80.920120013999963, 35.421771349000039],
            [-80.919977399999937, 35.421337354000059],
            [-80.919780905999971, 35.420751960000075],
            [-80.919519080999976, 35.42000325600003],
            [-80.919262661999937, 35.419304724000028],
            [-80.91902386199996, 35.418676474000051],
            [-80.91889791899996, 35.418353975000059],
            [-80.918776755999943, 35.418051157000036],
            [-80.918541777999963, 35.417477396000038],
            [-80.918275421999965, 35.416844133000041],
            [-80.917952634999949, 35.416105326000036],
            [-80.917694684999958, 35.415536492000058],
            [-80.917427725999971, 35.414965094000024],
            [-80.916997553999977, 35.414072560000079],
            [-80.916537330999972, 35.413156584000035],
            [-80.916225870999938, 35.412559754000029],
            [-80.91595231499997, 35.412048878000064],
            [-80.915628528999946, 35.411457885000061],
            [-80.915297844999941, 35.41085871000007],
            [-80.915012486999956, 35.41035796400007],
            [-80.914676736999979, 35.409784261000027],
            [-80.914213612999959, 35.409006765000072],
            [-80.913980037999977, 35.408603945000038],
            [-80.913699848999954, 35.408119691000024],
            [-80.913225575999945, 35.407321180000054],
            [-80.912936282999965, 35.406828535000045],
            [-80.912631572999942, 35.406311986000048],
            [-80.912366127999974, 35.405860534000055],
            [-80.912022086999968, 35.40527245100003],
            [-80.911778903999959, 35.40486028600003],
            [-80.911433628999987, 35.404271072000029],
            [-80.91124948199996, 35.403958984000042],
            [-80.91109998099995, 35.403707854000061],
            [-80.910777229999951, 35.403159948000052],
            [-80.910394047999944, 35.401249406000034],
            [-80.91031649599995, 35.400784219000059],
            [-80.910240091999981, 35.400306947000047],
            [-80.910170172999983, 35.399838715000044],
            [-80.910006283999962, 35.398785666000038],
            [-80.909901666999986, 35.398161827000024],
            [-80.909708862999935, 35.397342060000028],
            [-80.90954394399995, 35.396696813000062],
            [-80.90949283499998, 35.396099876000051],
            [-80.909397521999949, 35.395672753000042],
            [-80.909305529999983, 35.395093294000048],
            [-80.909189453999943, 35.394413533000034],
            [-80.909106729999962, 35.393924919000028],
            [-80.90902766499994, 35.393504202000031],
            [-80.908873931999949, 35.392906406000066],
            [-80.908702415999983, 35.392325565000078],
            [-80.908610978999945, 35.391960712000071],
            [-80.908463762999986, 35.391421915000024],
            [-80.908311449999985, 35.390884069000037],
            [-80.90815405099994, 35.390347207000048],
            [-80.907991573999936, 35.389811360000067],
            [-80.907837530999984, 35.38902592900007],
            [-80.907691283999952, 35.388568095000039],
            [-80.907489900999963, 35.387956563000046],
            [-80.907244262999939, 35.387241251000034],
            [-80.907069317999969, 35.386752386000069],
            [-80.906890363999935, 35.386265348000052],
            [-80.906667753999955, 35.385678167000037],
            [-80.906485473999965, 35.385208434000049],
            [-80.906353626999987, 35.384878663000052],
            [-80.90614267899997, 35.384367578000024],
            [-80.905780099999959, 35.383524194000074],
            [-80.904968365999935, 35.381718539000076],
            [-80.90472376699995, 35.381190089000029],
            [-80.904334564999942, 35.380398897000077],
            [-80.903734783999937, 35.379245376000029],
            [-80.90329918499998, 35.378450890000067],
            [-80.902756057999966, 35.377467656000078],
            [-80.902685004999967, 35.376981409000052],
            [-80.90259879499996, 35.376428698000041],
            [-80.902524301999961, 35.37597555900004],
            [-80.902438850999943, 35.375489875000028],
            [-80.902292794999937, 35.374732287000029],
            [-80.902130604999968, 35.37395289300008],
            [-80.902008739999985, 35.373396218000039],
            [-80.901853342999971, 35.372723611000026],
            [-80.901715684999942, 35.372163793000027],
            [-80.901547715999982, 35.371515593000026],
            [-80.901298098999973, 35.370619826000052],
            [-80.901092156999937, 35.36992536300005],
            [-80.900877354999977, 35.369236071000046],
            [-80.900700979999954, 35.368692409000062],
            [-80.900547446999951, 35.368235828000024],
            [-80.900400222999963, 35.367809431000069],
            [-80.900285442999973, 35.36748570900005],
            [-80.900112006999962, 35.36700815100005],
            [-80.89992958199997, 35.366519685000071],
            [-80.899731396999982, 35.366004288000056],
            [-80.899543929999936, 35.365536904000066],
            [-80.899277371999972, 35.364899189000027],
            [-80.899110664999967, 35.364506875000075],
            [-80.898890225999935, 35.363999792000072],
            [-80.89869516899995, 35.363563692000071],
            [-80.898234767999952, 35.362992685000052],
            [-80.898192194999979, 35.362363110000047],
            [-80.898462203999941, 35.362067109000066],
            [-80.898579100999939, 35.361704946000032],
            [-80.898739722999949, 35.361187991000065],
            [-80.898927119999939, 35.360587041000031],
            [-80.89925348099996, 35.359474799000054],
            [-80.899387907999937, 35.359016860000054],
            [-80.899534643999971, 35.358513559000073],
            [-80.899789616999954, 35.357587846000058],
            [-80.899927395999953, 35.35705194500008],
            [-80.900045207999938, 35.356558883000048],
            [-80.90021121999996, 35.355842241000062],
            [-80.900395208999953, 35.355007914000055],
            [-80.900606937999953, 35.354010359000029],
            [-80.900724011999955, 35.35342838300005],
            [-80.900874481999949, 35.352642985000045],
            [-80.900996321999969, 35.352004168000065],
            [-80.901095832999943, 35.351445031000026],
            [-80.901200356999937, 35.350799547000065],
            [-80.90134037699994, 35.349857008000072],
            [-80.901444482999977, 35.349078427000052],
            [-80.901723493999953, 35.346734679000065],
            [-80.901785964999988, 35.346137673000044],
            [-80.901855139999952, 35.345344451000074],
            [-80.901919480999936, 35.344426010000063],
            [-80.901979643999937, 35.343348509000066],
            [-80.902011139999956, 35.342787722000025],
            [-80.902039646999981, 35.342148039000051],
            [-80.902057465999974, 35.341526114000033],
            [-80.902064235999944, 35.341142432000026],
            [-80.902075886999967, 35.340611956000032],
            [-80.902082400999973, 35.339642430000026],
            [-80.902075932999935, 35.33903302300007],
            [-80.902065256999947, 35.33854982400004],
            [-80.902055798999982, 35.338246879000053],
            [-80.902033546999974, 35.337539029000027],
            [-80.90200436799995, 35.336853069000028],
            [-80.901734328999964, 35.331919041000049],
            [-80.901704410999969, 35.331374093000079],
            [-80.90167751599995, 35.330836502000068],
            [-80.901614324999969, 35.329740069000025],
            [-80.901576859999977, 35.329099605000067],
            [-80.901538755999979, 35.32854130700008],
            [-80.901508876999969, 35.328040643000065],
            [-80.901475324999979, 35.327470324000046],
            [-80.901444187999971, 35.326935718000072],
            [-80.901408300999947, 35.326375675000065],
            [-80.901379848999966, 35.325845474000062],
            [-80.901346612999987, 35.325223665000067],
            [-80.901306150999972, 35.324581262000038],
            [-80.901278291999972, 35.324091204000069],
            [-80.901243927999985, 35.323565358000053],
            [-80.901209453999968, 35.322975995000036],
            [-80.901188954999952, 35.32263479900007],
            [-80.90117227799999, 35.322329962000026],
            [-80.901140601999941, 35.321786280000026],
            [-80.901119254999969, 35.321440536000068],
            [-80.901065666999955, 35.32051595400003],
            [-80.901030274999982, 35.319976202000078],
            [-80.900989297999956, 35.319310485000074],
            [-80.900941461999935, 35.318478303000063],
            [-80.90087884999997, 35.31740787800004],
            [-80.900847797999972, 35.316815459000054],
            [-80.900801139999942, 35.316058471000076],
            [-80.900777919999939, 35.315692717000047],
            [-80.900756339999987, 35.314919777000057],
            [-80.900923159999934, 35.314151533000029],
            [-80.901305534999949, 35.312462356000026],
            [-80.901498910999976, 35.311671712000077],
            [-80.90167244099996, 35.310958291000077],
            [-80.901808985999935, 35.310370801000033],
            [-80.902056245999972, 35.309253781000052],
            [-80.902253460999987, 35.308362589000069],
            [-80.902353345999984, 35.30793101200004],
            [-80.902473971999939, 35.307414059000052],
            [-80.902562805999935, 35.307033950000061],
            [-80.902753314999984, 35.306201259000034],
            [-80.902899862999959, 35.305575909000027],
            [-80.90301846899996, 35.305061092000074],
            [-80.903220580999971, 35.304164462000074],
            [-80.903358398999956, 35.303550542000039],
            [-80.903498527999943, 35.302898105000054],
            [-80.903634847999967, 35.302230633000079],
            [-80.903763506999951, 35.301564451000047],
            [-80.903839504999951, 35.301138915000024],
            [-80.903914395999948, 35.300689729000055],
            [-80.904023165999945, 35.299982080000063],
            [-80.904069309999954, 35.299661504000028],
            [-80.904194608999944, 35.298722209000061],
            [-80.904282338999963, 35.297957416000031],
            [-80.904338686999949, 35.297388127000033],
            [-80.904385974999968, 35.296852086000058],
            [-80.904454400999953, 35.295890186000065],
            [-80.904491315999962, 35.29520605600004],
            [-80.90450698799998, 35.294838403000028],
            [-80.904521074999934, 35.294458443000053],
            [-80.904534324999986, 35.293988995000063],
            [-80.904540481999959, 35.293695892000073],
            [-80.904548290999969, 35.293210482000063],
            [-80.904550852999989, 35.292445010000051],
            [-80.904545994999978, 35.291980354000032],
            [-80.904539711999973, 35.291634916000078],
            [-80.904530749999935, 35.291280552000046],
            [-80.904510252999955, 35.290772512000046],
            [-80.904466486999979, 35.28998488700006],
            [-80.904424027999937, 35.289324729000043],
            [-80.904375946999949, 35.288700838000068],
            [-80.904314512999974, 35.28801620400003],
            [-80.90426957699998, 35.287585935000038],
            [-80.904215527999952, 35.287112813000078],
            [-80.904153820999966, 35.286599184000067],
            [-80.904088037999941, 35.286110588000042],
            [-80.90394306099995, 35.285134980000066],
            [-80.903861515999949, 35.284634636000078],
            [-80.903785518999939, 35.284208362000072],
            [-80.903651565999951, 35.283489597000028],
            [-80.903439929999934, 35.282453849000035],
            [-80.903289878999942, 35.281784386000027],
            [-80.903138693999949, 35.28114127200007],
            [-80.903047042999958, 35.280764809000061],
            [-80.902948850999962, 35.280373122000071],
            [-80.902790974999959, 35.27978590400005],
            [-80.902455485999951, 35.27861524900004],
            [-80.902252256999986, 35.277952736000032],
            [-80.902097602999959, 35.277479260000064],
            [-80.901887041999942, 35.276852224000038],
            [-80.90149017899995, 35.275738280000041],
            [-80.901219031999972, 35.27501979200008],
            [-80.901038353999979, 35.274556426000061],
            [-80.900760891999937, 35.27386716500007],
            [-80.900444634999985, 35.273109079000051],
            [-80.900025394999943, 35.272153591000063],
            [-80.899802972999964, 35.271603990000074],
            [-80.899773299999936, 35.271036621000064],
            [-80.899757453999939, 35.270499698000037],
            [-80.899717801999941, 35.26965529000006],
            [-80.899688988999969, 35.269203179000044],
            [-80.899647211999934, 35.26865099500003],
            [-80.899611514999947, 35.26823758300003],
            [-80.899558881999951, 35.267709767000042],
            [-80.89946704099998, 35.266921947000071],
            [-80.899400499999956, 35.266425983000033],
            [-80.899304239999935, 35.265773638000042],
            [-80.899220938999974, 35.265256050000062],
            [-80.899127746999966, 35.264727681000068],
            [-80.899034234999988, 35.264235493000058],
            [-80.898942906999935, 35.263782670000069],
            [-80.898808220999967, 35.26315810400007],
            [-80.898589293999976, 35.262753136000072],
            [-80.898673869999982, 35.26226910400004],
            [-80.899083298999983, 35.261971686000038],
            [-80.89975939499999, 35.261291596000035],
            [-80.900287729999945, 35.260742328000049],
            [-80.900769376999961, 35.260223395000025],
            [-80.901364341999965, 35.259566527000061],
            [-80.902017010999941, 35.258824111000024],
            [-80.902511278999953, 35.258239952000054],
            [-80.903094007999982, 35.257524199000045],
            [-80.903541018999988, 35.256958696000027],
            [-80.904001638999944, 35.256359887000031],
            [-80.904540923999946, 35.255624885000032],
            [-80.905331850999971, 35.254491296000026],
            [-80.905833390999987, 35.253738209000062],
            [-80.906219692999969, 35.253126491000046],
            [-80.906491107999955, 35.252684926000029],
            [-80.906784409999943, 35.25219575400007],
            [-80.906996412999945, 35.251875025000061],
            [-80.907510284999944, 35.251382489000036],
            [-80.908206189999987, 35.250723140000048],
            [-80.908725431999983, 35.250229585000056],
            [-80.909368374999985, 35.249624016000041],
            [-80.909894245999965, 35.249129725000046],
            [-80.910390312999937, 35.248672474000045],
            [-80.910953080999946, 35.248143864000042],
            [-80.911275134999983, 35.247834071000057],
            [-80.911777719999975, 35.247340763000068],
            [-80.912129280999977, 35.246988393000038],
            [-80.912603701999956, 35.246502639000028],
            [-80.913225074999957, 35.245837889000029],
            [-80.914131607999934, 35.244828375000054],
            [-80.914960635999989, 35.243850136000049],
            [-80.915714962999971, 35.242905861000054],
            [-80.916219875999957, 35.24225018900006],
            [-80.916475336999952, 35.241907444000049],
            [-80.916733554999951, 35.241554111000028],
            [-80.91699684799994, 35.241187370000034],
            [-80.917350381999938, 35.24068425400003],
            [-80.91761980299998, 35.240291978000073],
            [-80.91801623799995, 35.239700476000053],
            [-80.918209262999937, 35.23940600800006],
            [-80.918452701999968, 35.239026380000041],
            [-80.918877907999956, 35.238346112000045],
            [-80.919211756999971, 35.237794935000068],
            [-80.919615338999961, 35.237106935000043],
            [-80.920039972999973, 35.236342948000072],
            [-80.920557333999966, 35.235362413000075],
            [-80.920874946999959, 35.234734777000028],
            [-80.921075911999935, 35.23432013200005],
            [-80.921384941999975, 35.233659550000027],
            [-80.921556451999948, 35.233280576000027],
            [-80.921805621999965, 35.232712374000073],
            [-80.921984100999964, 35.232293051000056],
            [-80.922226204999959, 35.231703492000065],
            [-80.922419567999953, 35.231213280000077],
            [-80.922563871999955, 35.230835465000041],
            [-80.922735585999988, 35.230372948000024],
            [-80.922876336999934, 35.22998246800006],
            [-80.922982220999984, 35.229680052000049],
            [-80.923171645999957, 35.229120812000076],
            [-80.923414915999956, 35.22860614800004],
            [-80.92387716799999, 35.228034688000037],
            [-80.924176025999941, 35.22765617500005],
            [-80.924705948999986, 35.22696763600004],
            [-80.924982387999989, 35.226599100000044],
            [-80.92541816399995, 35.226003456000058],
            [-80.925697930999945, 35.225612675000036],
            [-80.926098215999957, 35.225043385000049],
            [-80.926361449999945, 35.22465980000004],
            [-80.926731074999964, 35.224107966000076],
            [-80.926966205999975, 35.223747959000036],
            [-80.927233218999959, 35.223329312000033],
            [-80.92755492799995, 35.222813125000073],
            [-80.927818180999964, 35.222379915000033],
            [-80.928234290999967, 35.22167289500004],
            [-80.928467055999988, 35.221267655000077],
            [-80.929079483999942, 35.22017461300004],
            [-80.929680395999981, 35.219021762000068],
            [-80.930070267999952, 35.21823239500003],
            [-80.930399632999979, 35.217532443000039],
            [-80.93073697899996, 35.216850574000034],
            [-80.930911039999955, 35.21649611600003],
            [-80.931186842999978, 35.215908940000077],
            [-80.931658212999935, 35.214857817000052],
            [-80.932283086999973, 35.213498309000045],
            [-80.932524742999988, 35.21297467900007],
            [-80.932963044999951, 35.212049862000072],
            [-80.933312254999976, 35.211338786000056],
            [-80.933691711999984, 35.210522018000063],
            [-80.934036540999955, 35.20977300900006],
            [-80.934479170999964, 35.208839011000066],
            [-80.93475373199999, 35.20824478000003],
            [-80.93499873899998, 35.207735099000047],
            [-80.935357834999934, 35.206971526000075],
            [-80.935615484999971, 35.206392701000027],
            [-80.935953088999952, 35.205698464000079],
            [-80.936195593999969, 35.205186871000024],
            [-80.936518794999984, 35.204488949000051],
            [-80.936963737999974, 35.203530994000062],
            [-80.937246719999962, 35.20290723800008],
            [-80.937395851999952, 35.202571849000037],
            [-80.93758448899996, 35.20213717200005],
            [-80.937806549999948, 35.201610249000055],
            [-80.93797836799996, 35.201190570000051],
            [-80.938108494999938, 35.200862976000053],
            [-80.938341183999967, 35.200257733000058],
            [-80.938493654999945, 35.199846869000055],
            [-80.938656858999934, 35.199392928000066],
            [-80.938786070999981, 35.199022442000057],
            [-80.93900021099995, 35.198381547000054],
            [-80.93920525499999, 35.197731035000061],
            [-80.939445206999949, 35.196925023000063],
            [-80.939570525999954, 35.196480025000028],
            [-80.939763836999987, 35.195756847000041],
            [-80.939868176999937, 35.195345131000067],
            [-80.940028071999961, 35.19467809300005],
            [-80.940133237999987, 35.194215191000069],
            [-80.940304284999968, 35.193423186000075],
            [-80.940396694999947, 35.192963905000056],
            [-80.940518663999967, 35.192311868000047],
            [-80.940609431999974, 35.191782293000074],
            [-80.940710154999977, 35.191122918000076],
            [-80.940845096999965, 35.190121678000025],
            [-80.940937703999964, 35.189288126000065],
            [-80.941008890999967, 35.188456805000044],
            [-80.941054654999959, 35.187803121000059],
            [-80.941091342999982, 35.187185611000075],
            [-80.941118408999955, 35.186628250000069],
            [-80.941133957999966, 35.186232802000063],
            [-80.941146883999977, 35.185576061000063],
            [-80.941148533999979, 35.184295831000043],
            [-80.941120916999978, 35.183284894000053],
            [-80.941066235999983, 35.182338714000025],
            [-80.941027670999972, 35.18181366400006],
            [-80.94099436099998, 35.181411321000041],
            [-80.940947230999939, 35.180899959000044],
            [-80.940860638999936, 35.18014502300008],
            [-80.940710946999957, 35.179045562000056],
            [-80.940604103999988, 35.178356793000034],
            [-80.940529995999952, 35.177929186000028],
            [-80.940434265999954, 35.177362729000038],
            [-80.940526529999943, 35.176906713000051],
            [-80.940760959999977, 35.176078627000038],
            [-80.940964062999967, 35.17534147300006],
            [-80.941227293999987, 35.174556270000039],
            [-80.941567016999954, 35.173581872000057],
            [-80.94182456499999, 35.172818494000069],
            [-80.941997396999966, 35.17228202900003],
            [-80.94214328999999, 35.171865666000031],
            [-80.942303083999946, 35.171470172000056],
            [-80.943071539999949, 35.170719883000061],
            [-80.943478711999944, 35.170348922000073],
            [-80.943978138999967, 35.169895394000037],
            [-80.944335278999972, 35.169564620000074],
            [-80.945070859999987, 35.168882318000044],
            [-80.945751565999956, 35.168264713000042],
            [-80.946495311999968, 35.167585053000039],
            [-80.946872593999956, 35.167248432000065],
            [-80.94734337999995, 35.166820186000052],
            [-80.947913090999975, 35.166284599000051],
            [-80.948348350999936, 35.165861645000064],
            [-80.949092089999965, 35.165164685000036],
            [-80.950515687999939, 35.163827866000076],
            [-80.95142089999996, 35.162946840000075],
            [-80.951776003999953, 35.162592151000069],
            [-80.952213993999976, 35.162144699000066],
            [-80.952612989999977, 35.161730449000061],
            [-80.953112228999942, 35.161198357000046],
            [-80.953830115999949, 35.160410888000058],
            [-80.954477834999977, 35.15967762300005],
            [-80.955140233999941, 35.158904114000052],
            [-80.955644422999967, 35.158298916000035],
            [-80.956150823999963, 35.157674709000048],
            [-80.956830169999989, 35.156809707000036],
            [-80.957187961999978, 35.156339910000042],
            [-80.957383432999961, 35.156076691000067],
            [-80.957610791999969, 35.155765676000044],
            [-80.957900416999962, 35.155363974000068],
            [-80.95859851299997, 35.15438316500007],
            [-80.959079333999966, 35.153688678000037],
            [-80.959819746999983, 35.152590278000048],
            [-80.960423183999978, 35.151745787000038],
            [-80.961630063999962, 35.150065157000029],
            [-80.961988304999977, 35.149564763000058],
            [-80.962701288999938, 35.148533274000044],
            [-80.963197851999951, 35.147814717000074],
            [-80.963596913999936, 35.147225997000078],
            [-80.963890125999967, 35.14680112700006],
            [-80.964278512999954, 35.146224691000043],
            [-80.964544631999956, 35.145819933000041],
            [-80.964916209999956, 35.145247544000028],
            [-80.965753979999988, 35.143918524000071],
            [-80.966155298999979, 35.143263756000067],
            [-80.966458147999958, 35.142755995000073],
            [-80.966721417999963, 35.142306508000047],
            [-80.967020735999938, 35.141785535000054],
            [-80.967424446999985, 35.141077047000067],
            [-80.967699394999954, 35.140572442000064],
            [-80.968110802999945, 35.139820672000042],
            [-80.96842126599995, 35.13924030700008],
            [-80.968658481999967, 35.138793949000046],
            [-80.969874432999973, 35.137099207000063],
            [-80.970064071999957, 35.136834848000035],
            [-80.970572945999947, 35.136116105000042],
            [-80.970896213999936, 35.135650607000059],
            [-80.971348250999938, 35.13498738800007],
            [-80.971647920999942, 35.13453949500007],
            [-80.972994116999985, 35.132489170000042],
            [-80.97514817299998, 35.129151212000068],
            [-80.975351257999989, 35.128836469000078],
            [-80.975586116999978, 35.128467465000028],
            [-80.976858959999959, 35.126245487000062],
            [-80.977097293999975, 35.125908029000072],
            [-80.977329491999967, 35.125567696000076],
            [-80.977555505999987, 35.125224565000053],
            [-80.977775282999971, 35.124878711000065],
            [-80.97798877699995, 35.124530211000035],
            [-80.978195938999988, 35.12417914100007],
            [-80.978396723999936, 35.123825579000027],
            [-80.97870925899997, 35.123247501000037],
            [-80.979050798999936, 35.122585505000075],
            [-80.979271376999975, 35.12215210200003],
            [-80.979476409999961, 35.12166116800006],
            [-80.979732260999981, 35.121031953000056],
            [-80.979825110999968, 35.120883125000034],
            [-80.979910905999986, 35.120731466000052],
            [-80.979989518999957, 35.12057720100006],
            [-80.980060832999982, 35.120420560000071],
            [-80.980124741999987, 35.120261775000074],
            [-80.980181149999964, 35.120101084000055],
            [-80.98022997399994, 35.119938725000054],
            [-80.980271140999946, 35.11977494000007],
            [-80.980304589999946, 35.119609973000024],
            [-80.980781662999959, 35.118909956000039],
            [-80.980976586999986, 35.118457895000063],
            [-80.981268985999975, 35.117760206000071],
            [-80.981565746999934, 35.117005576000054],
            [-80.981697937999968, 35.116654082000025],
            [-80.98185728499999, 35.116217793000033],
            [-80.982075079999959, 35.115607244000046],
            [-80.982246867999947, 35.115104619000078],
            [-80.982555160999937, 35.114126695000039],
            [-80.982735994999985, 35.113527114000078],
            [-80.982969208999975, 35.112720614000068],
            [-80.983159433999958, 35.112039241000048],
            [-80.983388144999935, 35.111160300000051],
            [-80.983572939999988, 35.110433003000026],
            [-80.983670361999941, 35.110032243000035],
            [-80.983808171999954, 35.10943694100007],
            [-80.983891195999945, 35.109061113000053],
            [-80.983992791999981, 35.10857238400007],
            [-80.98411779199995, 35.107932450000078],
            [-80.984239891999948, 35.10723858700004],
            [-80.98482140599998, 35.106356467000069],
            [-80.985248077999984, 35.10578892500007],
            [-80.986333427999966, 35.104346934000034],
            [-80.98709377299997, 35.103380339000068],
            [-80.987544014999969, 35.102786843000047],
            [-80.987829561999945, 35.102396171000066],
            [-80.988124945999971, 35.10199405000003],
            [-80.988451794999946, 35.101541788000077],
            [-80.988672017999988, 35.101232960000061],
            [-80.989019815999939, 35.100759245000063],
            [-80.989620829999978, 35.099977678000073],
            [-80.989900865999971, 35.099610200000029],
            [-80.99053539099998, 35.098763826000038],
            [-80.990838246999942, 35.098352565000027],
            [-80.991155627999944, 35.097914882000055],
            [-80.991493362999961, 35.097457791000068],
            [-80.991711932999976, 35.097158797000077],
            [-80.99240492399997, 35.096223451000071],
            [-80.993248894999965, 35.095075431000055],
            [-80.993966910999973, 35.094105217000049],
            [-80.994569472999956, 35.093307648000064],
            [-80.994938574999935, 35.092811773000051],
            [-80.995384108999986, 35.092200360000049],
            [-80.995721295999942, 35.091758700000071],
            [-80.995927569999935, 35.091486304000057],
            [-80.996195532999934, 35.091142969000032],
            [-80.996616149999966, 35.090594650000071],
            [-80.997022638999965, 35.090047005000031],
            [-80.997415115999956, 35.089500261000069],
            [-80.99761097999999, 35.089222557000028],
            [-80.997883668999975, 35.08884305600003],
            [-80.998382842999945, 35.088165298000035],
            [-80.998655097999972, 35.08781282800004],
            [-80.998976277999986, 35.087390868000057],
            [-80.999178907999976, 35.087120352000056],
            [-81.000189872999954, 35.085748737000074],
            [-81.000716960999966, 35.085013415000049],
            [-81.001081479999982, 35.084490610000046],
            [-81.00167150499999, 35.083633017000068],
            [-81.002078241999982, 35.083026724000035],
            [-81.002381620999984, 35.082559759000048],
            [-81.002734227999952, 35.081999161000056],
            [-81.003341155999976, 35.081008510000061],
            [-81.003801644999953, 35.080231815000047],
            [-81.004283520999934, 35.079392374000065],
            [-81.004711713999939, 35.078609821000043],
            [-81.005310598999984, 35.077456509000058],
            [-81.005643154999973, 35.076785531000041],
            [-81.006033416999969, 35.075958563000029],
            [-81.006433625999989, 35.075076686000045],
            [-81.006800603999977, 35.074230647000036],
            [-81.007157117999952, 35.073384068000053],
            [-81.007363672999986, 35.072879210000053],
            [-81.007717887999945, 35.071986909000032],
            [-81.007999599999948, 35.071259766000026],
            [-81.008252611999978, 35.070589211000026],
            [-81.008639184999936, 35.069505505000052],
            [-81.009008840999968, 35.06835323200005],
            [-81.009357283999975, 35.067132387000072],
            [-81.009655652999982, 35.065937213000041],
            [-81.009968349999951, 35.064471658000059],
            [-81.010235521999959, 35.063184626000066],
            [-81.010388140999964, 35.062343247000058],
            [-81.010518044999969, 35.061506397000073],
            [-81.010605819999967, 35.060881957000049],
            [-81.010716622999951, 35.060200270000053],
            [-81.010845038999946, 35.059330931000034],
            [-81.011190064999937, 35.056709857000044],
            [-81.011378459999946, 35.055299146000038],
            [-81.011917813999958, 35.051227185000073],
            [-81.012408321999942, 35.047681858000033],
            [-81.012638282999944, 35.047333599000069],
            [-81.012882898999976, 35.046953317000032],
            [-81.013281338999946, 35.046316741000055],
            [-81.01354205399997, 35.045889892000048],
            [-81.014059862999943, 35.045039607000035],
            [-81.014545906999956, 35.044213692000028],
            [-81.014881194999987, 35.043622074000041],
            [-81.015077365999957, 35.043284780000079],
            [-81.015341457999966, 35.042827952000039],
            [-81.015781235999953, 35.042025763000026],
            [-81.016379712999935, 35.04087237400006],
            [-81.016840292999973, 35.039923613000042],
            [-81.017252257999985, 35.039009488000033],
            [-81.017579082999987, 35.038248297000052],
            [-81.017807189999985, 35.037698105000061],
            [-81.017976622999981, 35.037274925000077],
            [-81.018125217999966, 35.036892366000075],
            [-81.01833054399998, 35.036347953000075],
            [-81.018675755999936, 35.035399974000029],
            [-81.019023507999975, 35.034407414000043],
            [-81.019204678999984, 35.033870012000079],
            [-81.019460319999951, 35.033046278000029],
            [-81.01980846899994, 35.031825380000043],
            [-81.019988816999955, 35.031144174000076],
            [-81.020151146999979, 35.030478790000075],
            [-81.020327915999985, 35.029711033000069],
            [-81.020467674999963, 35.029064135000056],
            [-81.02073788499996, 35.027754414000071],
            [-81.020989337999936, 35.026460919000044],
            [-81.021171291999963, 35.025556847000075],
            [-81.021420629999966, 35.024371557000052],
            [-81.021670761999985, 35.023160145000077],
            [-81.021828664999987, 35.022434642000064],
            [-81.021970016999944, 35.021745808000048],
            [-81.022188187999973, 35.020652881000046],
            [-81.022281988999964, 35.020230522000077],
            [-81.02245508499999, 35.019395040000063],
            [-81.024180141999977, 35.016826473000037],
            [-81.024684744999945, 35.016078958000037],
            [-81.025515266999946, 35.014871822000032],
            [-81.025989657999958, 35.014169099000071],
            [-81.026544083999966, 35.013336005000042],
            [-81.026906513999961, 35.012805180000043],
            [-81.027495525999939, 35.011941829000079],
            [-81.027976525999975, 35.011242852000066],
            [-81.028272804999972, 35.010811113000045],
            [-81.02908692799997, 35.009636013000033],
            [-81.02949979999994, 35.009017736000033],
            [-81.029739688999939, 35.008645100000024],
            [-81.030144078999967, 35.008030864000034],
            [-81.03045653099997, 35.007565671000066],
            [-81.030834701999936, 35.007028795000053],
            [-81.031328145999964, 35.006322855000064],
            [-81.03173008999994, 35.005734489000076],
            [-81.031971574999943, 35.005376269000067],
            [-81.03269077899995, 35.004334305000043],
            [-81.033907092999982, 35.002496154000028],
            [-81.034373700999936, 35.001759985000035],
            [-81.035021582999946, 35.000689794000039],
            [-81.035336584999982, 35.00015103100003],
            [-81.035834941999951, 34.999267696000061],
            [-81.036183764999976, 34.998623721000058],
            [-81.036781659999974, 34.99747020500007],
            [-81.037324492999971, 34.996335748000035],
            [-81.037855004999983, 34.995123037000042],
            [-81.038234117999934, 34.994194010000058],
            [-81.038571761999947, 34.993312591000063],
            [-81.038745480999978, 34.992838948000042],
            [-81.039079776999984, 34.991888544000062],
            [-81.039424893999978, 34.990803357000061],
            [-81.039772583999934, 34.989582374000065],
            [-81.03998390199996, 34.988770900000077],
            [-81.040100934999941, 34.988273080000056],
            [-81.040287832999979, 34.987434217000043],
            [-81.040501111999959, 34.986379535000026],
            [-81.040625899999952, 34.985686484000041],
            [-81.040734522999969, 34.985001329000056],
            [-81.040857364999965, 34.984146238000051],
            [-81.040967748999947, 34.983274163000033],
            [-81.041058662999944, 34.982351419000054],
            [-81.04114194999994, 34.981203347000076],
            [-81.041174373999979, 34.980628852000052],
            [-81.041200972999945, 34.979965992000075],
            [-81.041216784999961, 34.979200415000037],
            [-81.041216177999956, 34.977946345000078],
            [-81.041198872999985, 34.977167820000034],
            [-81.041181895999955, 34.976789146000044],
            [-81.041129318999936, 34.975780775000032],
            [-81.041098090999981, 34.975305932000026],
            [-81.041023033999977, 34.974356919000058],
            [-81.040902180999979, 34.973258858000065],
            [-81.040726327999948, 34.972013167000057],
            [-81.040593678999983, 34.971203820000028],
            [-81.04045076999995, 34.970429363000051],
            [-81.040320115999975, 34.969766943000025],
            [-81.040078973999982, 34.968654488000027],
            [-81.039799328999948, 34.967535259000044],
            [-81.039475600999936, 34.96639250100003],
            [-81.039234264999948, 34.965602465000075],
            [-81.039079759999936, 34.965116967000029],
            [-81.03884015999995, 34.964409342000067],
            [-81.038406600999963, 34.963207125000054],
            [-81.03811049799998, 34.962438534000057],
            [-81.037935964999974, 34.962014174000046],
            [-81.037685368999973, 34.961415337000062],
            [-81.037337959999945, 34.960590830000058],
            [-81.036630387999935, 34.958973537000077],
            [-81.036300331999939, 34.958191365000062],
            [-81.035884750999969, 34.957235329000071],
            [-81.035618435999936, 34.95660719600005],
            [-81.034974431999956, 34.955104721000055],
            [-81.034208757999977, 34.953274614000065],
            [-81.033906993999949, 34.952573335000068],
            [-81.033672611999975, 34.952046387000053],
            [-81.033411992999959, 34.951471430000026],
            [-81.033253017999982, 34.951118658000041],
            [-81.033029477999946, 34.950634235000052],
            [-81.032812863999936, 34.95017408700005],
            [-81.032660151999949, 34.949843034000025],
            [-81.032343071999946, 34.949115465000034],
            [-81.032093803999942, 34.948556232000044],
            [-81.031888785999968, 34.948062857000025],
            [-81.031514352999977, 34.947148899000069],
            [-81.03120557799997, 34.946421735000058],
            [-81.031000344999939, 34.945953898000027],
            [-81.030837023999936, 34.94556534000003],
            [-81.030372092999983, 34.944465511000033],
            [-81.030085087999964, 34.943808021000052],
            [-81.029426993999948, 34.942348327000047],
            [-81.029230430999974, 34.941844687000071],
            [-81.029012784999964, 34.941305598000042],
            [-81.028641388999972, 34.940428048000058],
            [-81.028085759999954, 34.939169363000076],
            [-81.027728885999977, 34.93834398100006],
            [-81.027465391999954, 34.937586329000055],
            [-81.027824215999942, 34.936861879000048],
            [-81.028282037999986, 34.93597352300003],
            [-81.028513262999979, 34.935511614000063],
            [-81.028834408999955, 34.934912669000028],
            [-81.02954815999999, 34.933528958000068],
            [-81.02985618799994, 34.932952641000043],
            [-81.030317183999955, 34.932074813000042],
            [-81.030520960999979, 34.931675319000078],
            [-81.03087845999994, 34.930998665000061],
            [-81.031373871999961, 34.930022201000043],
            [-81.031820324999956, 34.929075542000078],
            [-81.032350497999971, 34.927862808000043],
            [-81.032824760999972, 34.926671080000062],
            [-81.033257114999969, 34.925468590000037],
            [-81.033647201999941, 34.924256339000067],
            [-81.033994702999962, 34.923035326000047],
            [-81.034295679999957, 34.921823724000035],
            [-81.03456082799994, 34.920571083000027],
            [-81.034778980999988, 34.919329927000035],
            [-81.034953615999939, 34.918084092000072],
            [-81.035084587999961, 34.916834625000035],
            [-81.035171786999967, 34.915582571000073],
            [-81.035215143999949, 34.914328966000028],
            [-81.035214624999981, 34.913074850000044],
            [-81.035170232999974, 34.91182126800004],
            [-81.035082005999982, 34.910569259000056],
            [-81.034950016999971, 34.909319860000039],
            [-81.034774381999966, 34.908074117000069],
            [-81.034555250999972, 34.90683307300003],
            [-81.034289136999973, 34.905580568000062],
            [-81.033987248999949, 34.904369117000044],
            [-81.03363885999994, 34.90314827900005],
            [-81.033247921999987, 34.901936222000074],
            [-81.032814760999941, 34.900733943000034],
            [-81.032339735999983, 34.899542444000076],
            [-81.031808833999946, 34.898329961000059],
            [-81.031265720999954, 34.89719573900004],
            [-81.030667623999989, 34.896042479000073],
            [-81.030029454999976, 34.894903893000048],
            [-81.029351743999939, 34.893780931000038],
            [-81.028635057999963, 34.892674523000039],
            [-81.027848392999942, 34.891540270000064],
            [-81.027087169999959, 34.89051502500007],
            [-81.026257255999951, 34.889463727000077],
            [-81.025390942999934, 34.888432570000077],
            [-81.024488941999948, 34.887422400000048],
            [-81.023552002999963, 34.886434055000052],
            [-81.022526752999966, 34.885414857000058],
            [-81.02157648299999, 34.884526136000034],
            [-81.020539535999944, 34.883608135000031],
            [-81.019470944999966, 34.882715138000037],
            [-81.018371584999954, 34.881847871000048],
            [-81.017242367999984, 34.88100705800008],
            [-81.016003532999946, 34.880137155000057],
            [-81.014898175999974, 34.879407580000077],
            [-81.013685134999946, 34.878650236000055],
            [-81.012446112999953, 34.877921988000026],
            [-81.011182180999981, 34.877223467000078],
            [-81.009894391999978, 34.876555253000049],
            [-81.008474220999972, 34.875865181000052],
            [-81.007251406999956, 34.875311866000061],
            [-81.005898437999974, 34.874737744000072],
            [-81.004525970999964, 34.874195976000067],
            [-81.003135147999956, 34.873687014000041],
            [-81.001727112999959, 34.873211277000053],
            [-81.000164296999969, 34.872726818000046],
            [-80.998864160999972, 34.872361048000073],
            [-80.997411583999963, 34.871987251000064],
            [-80.99594652199994, 34.871648084000071],
            [-80.994470251999985, 34.871343845000069],
            [-80.992983982999988, 34.871074779000026],
            [-80.991322572999934, 34.870816013000024],
            [-80.989643741999942, 34.870608040000036],
            [-80.988132105999966, 34.870461946000034],
            [-80.987537127999985, 34.870409738000035],
            [-80.986442269999941, 34.870399961000032],
            [-80.985434830999964, 34.87036773300008],
            [-80.984530559999939, 34.870314407000023],
            [-80.98362844899998, 34.870270251000079],
            [-80.982401204999974, 34.870218668000064],
            [-80.981855059999987, 34.870199219000028],
            [-80.981352773999959, 34.870199636000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 10,
        "SolutionID": "PI-7",
        "Shape_Length": 2.3001769675427952,
        "Shape_Area": 0.11202781108794944
      }
    },
    {
      "type": "Feature",
      "id": 12,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.838706182999942, 35.222611956000037],
            [-80.838514276999945, 35.222633730000041],
            [-80.83832371799997, 35.222662385000035],
            [-80.838134871999955, 35.222697864000054],
            [-80.837948101999984, 35.222740099000077],
            [-80.837763766999956, 35.222789009000053],
            [-80.837582220999934, 35.222844501000054],
            [-80.837403810999945, 35.222906468000076],
            [-80.837228881999977, 35.22297479000008],
            [-80.837057769999944, 35.223049337000077],
            [-80.83689080299996, 35.223129965000055],
            [-80.836728299999947, 35.223216519000061],
            [-80.836570576999975, 35.223308833000033],
            [-80.83641793399994, 35.223406730000079],
            [-80.836270664999972, 35.223510022000028],
            [-80.836129053999969, 35.223618510000051],
            [-80.835993371999962, 35.223731985000029],
            [-80.835863879999977, 35.223850231000029],
            [-80.835740825999949, 35.223973019000027],
            [-80.835624446999987, 35.224100114000066],
            [-80.835514966999938, 35.224231272000054],
            [-80.835412596999959, 35.224366241000041],
            [-80.835317530999987, 35.224504760000059],
            [-80.835229954999988, 35.224646565000057],
            [-80.83515003399998, 35.224791383000024],
            [-80.83507792499995, 35.224938935000068],
            [-80.835013762999949, 35.225088939000045],
            [-80.834957674999941, 35.225241106000055],
            [-80.834909765999953, 35.225395143000071],
            [-80.834870129999956, 35.225550755000029],
            [-80.834838839999975, 35.225707642000032],
            [-80.834815960999947, 35.225865505000058],
            [-80.834801532999961, 35.226024038000048],
            [-80.834795585999984, 35.226182938000079],
            [-80.834798129999967, 35.226341899000033],
            [-80.859116083999936, 35.791431049000039],
            [-80.859127384999965, 35.791591233000076],
            [-80.859147371999939, 35.791750853000053],
            [-80.859176004999938, 35.791909596000039],
            [-80.859213227999987, 35.792067152000072],
            [-80.859258969999985, 35.792223212000067],
            [-80.859313139999983, 35.792377470000076],
            [-80.859375630999978, 35.792529626000032],
            [-80.859446322999986, 35.792679381000028],
            [-80.859525074999965, 35.792826441000045],
            [-80.859611735999977, 35.79297051900005],
            [-80.859706133999964, 35.793111332000024],
            [-80.859808084999941, 35.793248607000066],
            [-80.859917388999975, 35.793382073000032],
            [-80.860033832999989, 35.793511468000077],
            [-80.860157188999949, 35.79363654000008],
            [-80.860287214999971, 35.793757045000063],
            [-80.860423655999966, 35.793872745000044],
            [-80.860566245999962, 35.793983415000071],
            [-80.860714705999953, 35.79408883800005],
            [-80.860868742999969, 35.794188807000069],
            [-80.861028057999988, 35.794283127000028],
            [-80.861192338999956, 35.794371613000067],
            [-80.861361262999935, 35.794454092000024],
            [-80.861534499999948, 35.794530402000078],
            [-80.861711711999988, 35.794600394000042],
            [-80.861892548999947, 35.794663932000049],
            [-80.862076659999957, 35.794720890000065],
            [-80.86226368399997, 35.794771156000024],
            [-80.862453252999956, 35.794814634000033],
            [-80.862644997999951, 35.794851237000046],
            [-80.862838541999963, 35.794880894000073],
            [-80.863033507999944, 35.794903547000047],
            [-80.863229512999965, 35.794919151000045],
            [-80.863426171999947, 35.794927676000043],
            [-80.863623102999952, 35.794929106000041],
            [-80.86381991899998, 35.79492343700008],
            [-80.864016232999973, 35.794910680000044],
            [-80.864211662999935, 35.794890861000056],
            [-80.864405824999949, 35.794864018000055],
            [-80.864598339999986, 35.79483020300006],
            [-80.864788830999942, 35.794789484000034],
            [-80.864976922999972, 35.794741940000051],
            [-80.865162250999958, 35.794687664000037],
            [-80.865344449999952, 35.794626761000075],
            [-80.865523162999978, 35.794559352000078],
            [-80.865698041999963, 35.794485569000074],
            [-80.865868742999965, 35.794405554000036],
            [-80.866034931999934, 35.794319467000037],
            [-80.866196284999944, 35.794227475000071],
            [-80.866352484999936, 35.794129757000064],
            [-80.866503224999974, 35.79402650600008],
            [-80.866648212999962, 35.793917924000027],
            [-80.86678716199998, 35.793804222000063],
            [-80.866919802999973, 35.793685624000034],
            [-80.867045873999984, 35.793562362000046],
            [-80.867165128999943, 35.79343467700005],
            [-80.867277334999983, 35.793302819000075],
            [-80.867382271999986, 35.793167047000054],
            [-80.867479734999961, 35.793027625000036],
            [-80.867569530999958, 35.792884828000069],
            [-80.867651487999979, 35.792738934000056],
            [-80.867725442999983, 35.792590229000041],
            [-80.867791250999971, 35.792439005000062],
            [-80.867848784999978, 35.792285557000071],
            [-80.867897930999959, 35.792130186000065],
            [-80.867938594999941, 35.791973195000025],
            [-80.86797069499994, 35.791814893000037],
            [-80.867994168999985, 35.791655590000062],
            [-80.868008970999938, 35.791495595000072],
            [-80.868015071999935, 35.791335224000079],
            [-80.868012460999978, 35.791174790000071],
            [-80.843632862999982, 35.22608738200006],
            [-80.843621947999964, 35.225930050000045],
            [-80.843602713999985, 35.225773256000025],
            [-80.843575199999975, 35.225617296000053],
            [-80.843539452999948, 35.225462463000042],
            [-80.843495545999986, 35.225309051000067],
            [-80.843443556999944, 35.225157349000028],
            [-80.843383587999938, 35.225007642000037],
            [-80.843315749999988, 35.224860215000035],
            [-80.84324017199998, 35.22471534500005],
            [-80.843156997999984, 35.224573305000035],
            [-80.843066381999961, 35.224434365000036],
            [-80.842968496999958, 35.22429878500003],
            [-80.842863527999953, 35.224166822000029],
            [-80.842751672999952, 35.224038725000071],
            [-80.842633141999954, 35.22391473600004],
            [-80.842508160999955, 35.223795089000077],
            [-80.842376962999936, 35.223680010000066],
            [-80.84223979799998, 35.223569716000043],
            [-80.842096923999975, 35.223464415000024],
            [-80.841948610999964, 35.223364306000065],
            [-80.841795139999988, 35.223269578000043],
            [-80.841636797999968, 35.223180409000065],
            [-80.84147388599996, 35.223096969000039],
            [-80.841306711999948, 35.223019414000078],
            [-80.841135589999965, 35.222947892000036],
            [-80.840960842999948, 35.222882536000043],
            [-80.840782801999978, 35.222823470000037],
            [-80.84060180299997, 35.222770806000028],
            [-80.840418186999955, 35.222724644000039],
            [-80.840232301999947, 35.222685070000068],
            [-80.840044495999962, 35.222652158000074],
            [-80.839855125999975, 35.222625973000049],
            [-80.839664547999973, 35.222606562000067],
            [-80.839473122999948, 35.22259396100003],
            [-80.839281210999957, 35.222588196000061],
            [-80.839089176999948, 35.222589278000044],
            [-80.838897379999935, 35.222597202000031],
            [-80.838706182999942, 35.222611956000037]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 12,
        "SolutionID": "PI-8:A",
        "Shape_Length": 1.1566151311346675,
        "Shape_Area": 0.0050665596687474983
      }
    },
    {
      "type": "Feature",
      "id": 13,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.838706182999942, 35.222611956000037],
            [-80.838514312999962, 35.222633726000026],
            [-80.838323788999958, 35.222662373000048],
            [-80.838134977999971, 35.222697842000059],
            [-80.837948241999982, 35.222740065000039],
            [-80.837763938999956, 35.222788961000049],
            [-80.837582422999958, 35.22284443500007],
            [-80.837404042999935, 35.222906382000076],
            [-80.837229141999956, 35.222974683000075],
            [-80.837058055999989, 35.223049206000042],
            [-80.836891110999943, 35.223129808000067],
            [-80.836728630999971, 35.223216334000028],
            [-80.836570925999979, 35.223308619000079],
            [-80.836418299999934, 35.223406484000066],
            [-80.836271045999979, 35.223509744000069],
            [-80.836129444999983, 35.223618197000064],
            [-80.835993770999949, 35.223731638000061],
            [-80.835864282999978, 35.223849846000064],
            [-80.83574122999994, 35.223972597000056],
            [-80.835624849999988, 35.224099653000053],
            [-80.835515364999935, 35.224230772000055],
            [-80.835412984999948, 35.224365701000067],
            [-80.835317907999979, 35.224504181000043],
            [-80.83523031499999, 35.224645946000066],
            [-80.835150375999945, 35.224790724000059],
            [-80.835078243999988, 35.22493823700006],
            [-80.835014056999967, 35.225088201000062],
            [-80.834957937999945, 35.22524032900003],
            [-80.834909996999954, 35.225394329000039],
            [-80.834870322999961, 35.225549904000047],
            [-80.834838992999948, 35.225706756000079],
            [-80.834816068999942, 35.225864584000078],
            [-80.834801593999941, 35.226023084000076],
            [-80.834795595999935, 35.226181953000037],
            [-80.834798085999978, 35.226340884000024],
            [-80.834809059999941, 35.226499574000059],
            [-80.880658054999969, 35.701359041000046],
            [-80.880677924999986, 35.701518673000066],
            [-80.880706432999943, 35.701677432000054],
            [-80.880743521999989, 35.701835007000057],
            [-80.880789119999974, 35.701991090000035],
            [-80.880843136999943, 35.702145374000054],
            [-80.88090546899997, 35.702297559000044],
            [-80.880975992999936, 35.702447345000053],
            [-80.881054569999947, 35.702594440000041],
            [-80.881141047999961, 35.702738557000032],
            [-80.881235257999947, 35.702879412000073],
            [-80.881337013999939, 35.703016730000058],
            [-80.881446116999939, 35.703150243000039],
            [-80.881562354999971, 35.703279688000066],
            [-80.881685498999957, 35.703404813000077],
            [-80.881815307999943, 35.703525372000058],
            [-80.881951528999934, 35.703641129000061],
            [-80.882093893999979, 35.703751858000032],
            [-80.882242125999937, 35.703857342000049],
            [-80.882395932999941, 35.703957375000073],
            [-80.88255501499998, 35.704051760000027],
            [-80.882719058999953, 35.704140314000028],
            [-80.882887745999938, 35.704222861000062],
            [-80.883060744999966, 35.704299242000047],
            [-80.883237717999975, 35.704369306000046],
            [-80.883418315999961, 35.704432917000076],
            [-80.883602187999941, 35.704489949000049],
            [-80.88378897299998, 35.704540291000058],
            [-80.88397830599996, 35.704583844000069],
            [-80.88416981499995, 35.704620524000063],
            [-80.884363126999972, 35.704650258000072],
            [-80.884557861999951, 35.704672988000027],
            [-80.88475363799995, 35.704688670000053],
            [-80.88495007399996, 35.704697274000068],
            [-80.885146783999971, 35.704698780000058],
            [-80.885343382999963, 35.704693189000068],
            [-80.885539485999971, 35.70468050900007],
            [-80.885734709999952, 35.704660766000075],
            [-80.885928672999967, 35.704633999000066],
            [-80.886120992999963, 35.704600259000074],
            [-80.886311295999974, 35.704559614000061],
            [-80.886499206999986, 35.704512143000045],
            [-80.886684359999947, 35.704457938000076],
            [-80.886866390999955, 35.704397106000044],
            [-80.887044945999946, 35.704329765000068],
            [-80.887219672999947, 35.704256049000037],
            [-80.887390230999983, 35.704176099000051],
            [-80.887556286999938, 35.704090075000067],
            [-80.887717514999963, 35.703998143000035],
            [-80.887873596999952, 35.703900485000077],
            [-80.888024231999964, 35.703797290000068],
            [-80.888169121999965, 35.70368876200007],
            [-80.888307984999983, 35.703575111000077],
            [-80.888440546999959, 35.703456562000042],
            [-80.888566551999986, 35.703333347000068],
            [-80.88868574899999, 35.703205706000062],
            [-80.888797907999958, 35.703073888000063],
            [-80.888902808999944, 35.702938153000048],
            [-80.889000244999977, 35.702798766000058],
            [-80.889090026999952, 35.702656000000047],
            [-80.889171977999979, 35.702510135000068],
            [-80.889245938999977, 35.702361455000073],
            [-80.889311763999956, 35.702210252000043],
            [-80.889369324999961, 35.702056822000031],
            [-80.889418508999938, 35.701901466000038],
            [-80.889459219999935, 35.701744487000042],
            [-80.889491377999946, 35.701586193000026],
            [-80.889514919999954, 35.701426893000075],
            [-80.889529799999934, 35.701266900000064],
            [-80.889535989999956, 35.701106527000036],
            [-80.889533476999986, 35.700946087000034],
            [-80.889522265999972, 35.700785895000024],
            [-80.843621914999972, 35.225929707000034],
            [-80.843602664999935, 35.225772924000069],
            [-80.843575133999934, 35.225616975000037],
            [-80.843539373999988, 35.22546215400007],
            [-80.843495451999956, 35.225308754000025],
            [-80.843443451999974, 35.22515706400003],
            [-80.843383470999981, 35.225007371000061],
            [-80.843315623999956, 35.224859957000035],
            [-80.843240036999987, 35.224715099000036],
            [-80.843156853999972, 35.224573073000045],
            [-80.843066231999956, 35.224434146000078],
            [-80.842968341999949, 35.224298580000038],
            [-80.842863368999986, 35.22416663000007],
            [-80.842751509999971, 35.224038547000077],
            [-80.842632976999937, 35.22391457100008],
            [-80.84250799299997, 35.223794938000026],
            [-80.84237679599994, 35.223679870000069],
            [-80.842239631999973, 35.223569589000078],
            [-80.842096760999937, 35.223464300000046],
            [-80.841948449999961, 35.223364202000027],
            [-80.841794982999943, 35.22326948500006],
            [-80.841636645999984, 35.223180328000069],
            [-80.841473739999969, 35.223096898000051],
            [-80.841306571999951, 35.223019352000051],
            [-80.84113545699995, 35.222947839000028],
            [-80.840960717999963, 35.222882492000053],
            [-80.840782685999955, 35.222823434000077],
            [-80.840601695999965, 35.222770777000051],
            [-80.840418088999968, 35.222724621000054],
            [-80.840232212999979, 35.222685053000077],
            [-80.840044417999934, 35.22265214600003],
            [-80.839855057999955, 35.222625965000077],
            [-80.83966449199994, 35.222606557000063],
            [-80.839473077999969, 35.222593959000051],
            [-80.839281176999975, 35.222588196000061],
            [-80.839089153999964, 35.222589278000044],
            [-80.838897368999937, 35.222597203000078],
            [-80.838706182999942, 35.222611956000037]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 13,
        "SolutionID": "PI-8:B",
        "Shape_Length": 0.97951908044436087,
        "Shape_Area": 0.0042737567967565115
      }
    },
    {
      "type": "Feature",
      "id": 14,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.838706182999942, 35.222611956000037],
            [-80.838511135999966, 35.222634146000075],
            [-80.838317486999983, 35.222663442000055],
            [-80.838125618999982, 35.222699787000067],
            [-80.837935914999946, 35.222743108000031],
            [-80.837748749999946, 35.222793319000061],
            [-80.83756449599997, 35.222850321000067],
            [-80.837383518999957, 35.222914001000049],
            [-80.837206177999974, 35.222984232000044],
            [-80.837032822999959, 35.223060874000055],
            [-80.836863801999982, 35.223143776000029],
            [-80.836699446999944, 35.223232773000063],
            [-80.836540084999967, 35.22332768900003],
            [-80.83638603299994, 35.223428335000051],
            [-80.836237595999989, 35.223534512000072],
            [-80.836095070999988, 35.22364600800006],
            [-80.835958736999942, 35.223762603000068],
            [-80.835828867999965, 35.223884063000071],
            [-80.835705718999975, 35.224010151000073],
            [-80.835589536999976, 35.224140614000078],
            [-80.835480550999989, 35.224275194000029],
            [-80.835378975999959, 35.224413623000032],
            [-80.83528501699999, 35.224555627000029],
            [-80.835198858999945, 35.224700925000036],
            [-80.835120672999949, 35.22484922700005],
            [-80.835050612999964, 35.225000240000043],
            [-80.834988820999968, 35.225153663000071],
            [-80.83493541699994, 35.225309193000044],
            [-80.834890507999944, 35.225466520000055],
            [-80.834854182999948, 35.22562533200005],
            [-80.834826514999975, 35.225785314000063],
            [-80.834807557999966, 35.225946148000048],
            [-80.802279156999987, 35.583706315000029],
            [-80.802268879999986, 35.583866553000064],
            [-80.802267288999985, 35.584027007000032],
            [-80.80227438999998, 35.584187359000055],
            [-80.802290164999988, 35.584347299000058],
            [-80.802314585999966, 35.584506512000075],
            [-80.802347604999966, 35.584664687000043],
            [-80.802389154999958, 35.584821515000044],
            [-80.80243915799997, 35.584976686000061],
            [-80.802497513999981, 35.585129900000027],
            [-80.802564109999935, 35.585280854000075],
            [-80.802638815999956, 35.585429255000065],
            [-80.80272148399996, 35.585574811000072],
            [-80.802811952999946, 35.585717238000029],
            [-80.802910045999965, 35.585856256000056],
            [-80.803015571999936, 35.585991593000074],
            [-80.803128322999953, 35.586122985000031],
            [-80.803248077999967, 35.586250175000032],
            [-80.803374604999988, 35.586372912000058],
            [-80.803507653999986, 35.586490957000024],
            [-80.803646966999963, 35.586604080000029],
            [-80.803792267999938, 35.586712058000046],
            [-80.803943274999938, 35.586814679000042],
            [-80.804099689999987, 35.586911744000076],
            [-80.804261209999936, 35.587003062000065],
            [-80.804427516999965, 35.587088455000071],
            [-80.80459828499994, 35.587167754000063],
            [-80.804773180999973, 35.587240806000068],
            [-80.80495186099995, 35.587307466000027],
            [-80.805133976999969, 35.587367605000054],
            [-80.805319169999962, 35.587421103000054],
            [-80.805507080999973, 35.587467859000071],
            [-80.805697339999938, 35.587507778000031],
            [-80.805889572999945, 35.587540784000055],
            [-80.80608340699996, 35.587566811000045],
            [-80.806278458999941, 35.587585809000075],
            [-80.806474349999974, 35.587597740000035],
            [-80.80667069499998, 35.587602581000056],
            [-80.806867109999985, 35.587600323000061],
            [-80.807063210999956, 35.587590970000065],
            [-80.807258612999988, 35.587574540000048],
            [-80.807452933999969, 35.587551067000049],
            [-80.807645793999939, 35.587520593000079],
            [-80.807836813999984, 35.587483182000028],
            [-80.808025621999946, 35.587438904000066],
            [-80.808211846999939, 35.587387848000049],
            [-80.808395123999958, 35.587330112000075],
            [-80.80857509599997, 35.587265811000066],
            [-80.808751408999967, 35.587195070000064],
            [-80.80892371799996, 35.587118027000031],
            [-80.809091685999988, 35.587034833000075],
            [-80.809254984999939, 35.586945652000054],
            [-80.809413293999967, 35.586850656000024],
            [-80.809566303999986, 35.586750034000033],
            [-80.809713714999987, 35.586643981000066],
            [-80.80985523999999, 35.586532706000071],
            [-80.809990597999956, 35.586416426000028],
            [-80.810119527999973, 35.586295369000027],
            [-80.810241774999952, 35.586169771000073],
            [-80.810357101999955, 35.586039879000054],
            [-80.810465281999939, 35.585905948000061],
            [-80.810566102999985, 35.585768237000025],
            [-80.810659367999961, 35.585627019000071],
            [-80.810744894999971, 35.585482570000067],
            [-80.810822515999973, 35.585335170000064],
            [-80.810892077999938, 35.585185112000033],
            [-80.810953446999974, 35.585032685000044],
            [-80.811006501999941, 35.584878192000076],
            [-80.811051138999971, 35.584721931000047],
            [-80.811087270999963, 35.584564211000043],
            [-80.811114826999983, 35.584405340000046],
            [-80.811133752999979, 35.584245628000076],
            [-80.843623477999984, 35.226483133000045],
            [-80.84363359699995, 35.22632374300008],
            [-80.843635159999963, 35.226164141000027],
            [-80.843628164999984, 35.226004639000053],
            [-80.843612623999945, 35.225845542000059],
            [-80.843588567999973, 35.225687161000053],
            [-80.843556043999968, 35.22552980200004],
            [-80.843515113999956, 35.225373768000054],
            [-80.843465857999945, 35.225219365000044],
            [-80.843408372999988, 35.225066888000072],
            [-80.843342766999967, 35.224916634000067],
            [-80.843269168999939, 35.224768895000068],
            [-80.843187721999982, 35.224623955000027],
            [-80.843098582999971, 35.224482096000031],
            [-80.843001924999953, 35.224343592000025],
            [-80.84289793399995, 35.224208711000074],
            [-80.842786813999965, 35.224077716000068],
            [-80.842668776999972, 35.22395085900007],
            [-80.842544052999983, 35.223828386000037],
            [-80.842412884999987, 35.22371053300003],
            [-80.84227552599998, 35.223597531000053],
            [-80.842132239999955, 35.223489596000036],
            [-80.841983307999953, 35.223386939000079],
            [-80.841829015999963, 35.223289758000078],
            [-80.841669662999948, 35.223198240000045],
            [-80.841505557999938, 35.223112564000076],
            [-80.84133701899998, 35.223032896000063],
            [-80.84116437199998, 35.222959388000049],
            [-80.840987951999978, 35.222892184000045],
            [-80.840808098999958, 35.22283141500003],
            [-80.84062516299997, 35.222777197000028],
            [-80.840439497999967, 35.222729636000054],
            [-80.840251461999969, 35.222688824000045],
            [-80.840061420999973, 35.222654838000039],
            [-80.839869741999962, 35.222627747000047],
            [-80.839676794999946, 35.22260760100005],
            [-80.839482954999937, 35.222594441000069],
            [-80.839288597999939, 35.222588292000069],
            [-80.839094099999954, 35.222589164000055],
            [-80.83889983499995, 35.22259705700003],
            [-80.838706182999942, 35.222611956000037]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 14,
        "SolutionID": "PI-8:C",
        "Shape_Length": 0.74384210007636664,
        "Shape_Area": 0.0032288649770162497
      }
    },
    {
      "type": "Feature",
      "id": 15,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.008353277999959, 34.987295823000068],
            [-81.00829327799994, 34.987516824000068],
            [-81.008208277999984, 34.987719824000067],
            [-81.008099277999975, 34.987913825000078],
            [-81.008014276999972, 34.98803782400006],
            [-81.00726827799997, 34.989123825000036],
            [-81.006472276999943, 34.990259825000066],
            [-81.006389277999972, 34.990380824000056],
            [-81.005812276999961, 34.991221825000025],
            [-81.005573276999939, 34.991561826000066],
            [-81.005470277999962, 34.991701825000064],
            [-81.005335277999961, 34.991894825000031],
            [-81.005007276999947, 34.99236382600003],
            [-81.004877277999981, 34.992549825000026],
            [-81.004828277999934, 34.992624825000064],
            [-81.004539276999935, 34.993069825000077],
            [-81.004503276999969, 34.993132826000078],
            [-81.004438277999952, 34.99324882500008],
            [-81.004359277999981, 34.993434826000055],
            [-81.004305277999947, 34.993626826000025],
            [-81.004260276999958, 34.994035826000072],
            [-81.004239276999954, 34.994200826000053],
            [-81.00425527699997, 34.994458825000038],
            [-81.004292276999934, 34.994712825000079],
            [-81.004313277999984, 34.994769826000038],
            [-81.004409277999969, 34.995026826000071],
            [-81.004553277999946, 34.995307826000044],
            [-81.004728277999959, 34.995578826000042],
            [-81.00526027799998, 34.996352826000077],
            [-81.005500276999953, 34.996663826000031],
            [-81.005712277999976, 34.996916826000074],
            [-81.006016277999947, 34.99721882700004],
            [-81.006298277999974, 34.997477826000079],
            [-81.006819278999956, 34.997931827000059],
            [-81.007399278999969, 34.998382827000057],
            [-81.008271278999985, 34.999051827000073],
            [-81.008532278999951, 34.999257826000076],
            [-81.00891527899995, 34.999550827000064],
            [-81.009415277999949, 34.999865826000075],
            [-81.009775278999939, 35.000033827000038],
            [-81.009809278999967, 35.000049826000065],
            [-81.010207279999975, 35.000178826000024],
            [-81.010231279999971, 35.00018682700005],
            [-81.012000279999938, 35.000762827000074],
            [-81.012586280999983, 35.000958827000034],
            [-81.013814279999963, 35.001352827000062],
            [-81.014495280999938, 35.001566827000033],
            [-81.014719281999987, 35.001641827000071],
            [-81.014905280999983, 35.001724827000032],
            [-81.015075280999952, 35.00181182700004],
            [-81.01524028099999, 35.001912827000069],
            [-81.015560280999978, 35.002131827000028],
            [-81.015884280999956, 35.002367827000057],
            [-81.01694428199994, 35.003193827000075],
            [-81.017359281999973, 35.003340828000034],
            [-81.017487281999934, 35.003421828000057],
            [-81.017497281999965, 35.003258827000025],
            [-81.01751828099998, 35.003140827000038],
            [-81.017763280999986, 35.001779827000064],
            [-81.018168281999976, 34.999592827000072],
            [-81.01854328099995, 34.997625826000046],
            [-81.018830281999954, 34.996024825000063],
            [-81.018991281999945, 34.995158825000033],
            [-81.019096280999975, 34.994569825000042],
            [-81.019240281999942, 34.993923825000024],
            [-81.019332280999947, 34.993584825000028],
            [-81.019477281999968, 34.993144825000059],
            [-81.019816280999976, 34.992365825000036],
            [-81.020033281999986, 34.991899824000029],
            [-81.020065281999962, 34.991830825000079],
            [-81.020634281999946, 34.990546825000024],
            [-81.020732281999983, 34.990325824000024],
            [-81.020990281999957, 34.989716824000027],
            [-81.021082281999952, 34.989495824000073],
            [-81.021195281999951, 34.989131824000026],
            [-81.021207282999967, 34.989078824000046],
            [-81.02130828199995, 34.988640823000026],
            [-81.021354281999947, 34.988306824000063],
            [-81.021369281999966, 34.988199824000048],
            [-81.021372280999969, 34.988109824000048],
            [-81.021390280999981, 34.987590823000062],
            [-81.02139028199997, 34.987461824000036],
            [-81.02139028199997, 34.987023823000072],
            [-81.02139028199997, 34.986380823000047],
            [-81.021473281999988, 34.986438823000071],
            [-81.021840280999982, 34.986669823000057],
            [-81.02205528199994, 34.986867823000068],
            [-81.02222728299995, 34.987027823000062],
            [-81.022408281999958, 34.987241823000033],
            [-81.022521281999957, 34.987456823000059],
            [-81.02286828299998, 34.987978823000049],
            [-81.022982282999976, 34.988126824000062],
            [-81.023021282999935, 34.988313823000055],
            [-81.023155282999937, 34.988412823000033],
            [-81.023258282999961, 34.988450823000051],
            [-81.023335281999948, 34.988478824000026],
            [-81.023527281999975, 34.98853282400006],
            [-81.023629283999981, 34.988561824000044],
            [-81.02380328299995, 34.988588823000043],
            [-81.023896282999942, 34.988632824000035],
            [-81.024130282999977, 34.988803823000069],
            [-81.02425728299994, 34.988880824000034],
            [-81.024404282999967, 34.988929824000024],
            [-81.024644283999976, 34.988940824000053],
            [-81.024805282999978, 34.98896882400004],
            [-81.024833282999964, 34.988979824000069],
            [-81.025078282999971, 34.989078824000046],
            [-81.025305282999966, 34.989215824000041],
            [-81.025385283999981, 34.989342824000062],
            [-81.025466282999957, 34.98951282400003],
            [-81.025472283999989, 34.989655824000067],
            [-81.025472282999942, 34.989892824000037],
            [-81.025449282999944, 34.989991824000072],
            [-81.025352282999961, 34.990408824000042],
            [-81.025291282999945, 34.990601824000066],
            [-81.025251282999989, 34.990832824000051],
            [-81.025224283999989, 34.99121182500005],
            [-81.025191283999959, 34.99150882400005],
            [-81.025171282999963, 34.991645824000045],
            [-81.025124283999958, 34.991821824000056],
            [-81.02500328299999, 34.992090825000048],
            [-81.02500328299999, 34.992200824000065],
            [-81.025257282999974, 34.992343825000034],
            [-81.025511283999947, 34.992717825000057],
            [-81.025698283999986, 34.992838824000046],
            [-81.025791283999979, 34.992976824000039],
            [-81.026332283999977, 34.993410825000069],
            [-81.026633283999956, 34.993674825000028],
            [-81.027027283999985, 34.993977825000059],
            [-81.027200283999946, 34.994164824000052],
            [-81.027240283999959, 34.994328825000025],
            [-81.02730728399996, 34.99441682500003],
            [-81.027467283999954, 34.994658825000045],
            [-81.027460283999972, 34.994757825000079],
            [-81.027333283999951, 34.994966825000063],
            [-81.027313284999934, 34.995082825000054],
            [-81.027246283999943, 34.995252824000033],
            [-81.027186282999935, 34.995527825000067],
            [-81.026999284999988, 34.995730825000066],
            [-81.026839283999948, 34.995884826000065],
            [-81.026638283999944, 34.996126826000079],
            [-81.026531283999986, 34.996395826000025],
            [-81.026571283999942, 34.996555826000076],
            [-81.026731284999983, 34.996945826000058],
            [-81.02683828399995, 34.997148825000068],
            [-81.026972283999953, 34.997253826000076],
            [-81.02722528399994, 34.997434826000074],
            [-81.027412284999969, 34.997467825000058],
            [-81.027693283999952, 34.997495825000044],
            [-81.027840283999979, 34.997495825000044],
            [-81.028187284999944, 34.997402825000052],
            [-81.028622284999983, 34.997303825000074],
            [-81.028902283999969, 34.997292825000045],
            [-81.029229283999939, 34.997308825000061],
            [-81.02937028499997, 34.997385825000038],
            [-81.029430284999989, 34.997512826000047],
            [-81.029697284999941, 34.997715825000057],
            [-81.029804284999955, 34.997809825000047],
            [-81.030037284999935, 34.998067825000078],
            [-81.030264284999987, 34.998276825000062],
            [-81.030371285999934, 34.998425826000073],
            [-81.030445284999985, 34.998480825000058],
            [-81.030605285999968, 34.998562826000068],
            [-81.030714284999988, 34.998675826000067],
            [-81.030765285999962, 34.998727826000049],
            [-81.030899285999965, 34.998892825000041],
            [-81.031099285999971, 34.99899782500006],
            [-81.031386285999986, 34.999194826000064],
            [-81.031418285999962, 34.99921882600006],
            [-81.031881285999987, 34.998888825000051],
            [-81.032683284999962, 34.998346826000045],
            [-81.033338285999946, 34.997871825000061],
            [-81.033898285999953, 34.997445825000057],
            [-81.03431928599997, 34.997064826000042],
            [-81.034613285999967, 34.996753824000052],
            [-81.034949286999961, 34.996373825000035],
            [-81.034978286999944, 34.996331825000027],
            [-81.035089286999948, 34.996176825000077],
            [-81.035271286999944, 34.995934825000063],
            [-81.03552328699999, 34.995623824000063],
            [-81.035733285999981, 34.995266825000044],
            [-81.035817286999986, 34.995111825000038],
            [-81.035869285999979, 34.995007825000073],
            [-81.03598528699996, 34.994759825000074],
            [-81.036419286999944, 34.993894824000051],
            [-81.038724287999969, 34.989138823000076],
            [-81.038787286999934, 34.989011823000055],
            [-81.038968286999989, 34.988638823000031],
            [-81.039697287999957, 34.987135823000074],
            [-81.040417287999958, 34.987391823000053],
            [-81.040932286999976, 34.987597823000044],
            [-81.041050286999962, 34.987644823000039],
            [-81.041537287999972, 34.98785782300007],
            [-81.041927287999954, 34.988044823000052],
            [-81.042170287999966, 34.988177823000058],
            [-81.04225428899997, 34.988219823000065],
            [-81.042333288999941, 34.988257823000026],
            [-81.042868288999955, 34.988578823000068],
            [-81.043323288999943, 34.988831823000055],
            [-81.043364288999953, 34.988846823000074],
            [-81.043907287999957, 34.989031823000062],
            [-81.044329288999961, 34.989098823000063],
            [-81.044995288999985, 34.989151823000043],
            [-81.045514288999982, 34.989178824000078],
            [-81.046244289999947, 34.98920482300008],
            [-81.046666288999972, 34.989177823000034],
            [-81.047932288999959, 34.989016823000043],
            [-81.048386289999939, 34.989016822000053],
            [-81.048488289999966, 34.989024823000079],
            [-81.048711289999972, 34.989043823000031],
            [-81.048781289999965, 34.989059823000048],
            [-81.04906828999998, 34.989123823000057],
            [-81.049246289999985, 34.989176823000037],
            [-81.049377289999939, 34.989216823000049],
            [-81.050129290999962, 34.989541823000025],
            [-81.051428290999979, 34.990125822000039],
            [-81.052961291999964, 34.990830823000067],
            [-81.053230291999967, 34.990981823000027],
            [-81.053414290999967, 34.991122823000069],
            [-81.053598291999947, 34.991304824000053],
            [-81.053745291999974, 34.991505823000068],
            [-81.053774291999957, 34.99156582300003],
            [-81.053858291999973, 34.991734824000048],
            [-81.053905291999968, 34.991868823000061],
            [-81.053924291999977, 34.992058824000026],
            [-81.053942291999988, 34.992221824000069],
            [-81.053979290999962, 34.992946824000057],
            [-81.054019291999964, 34.993903824000029],
            [-81.054004291999945, 34.994134824000071],
            [-81.05399029199998, 34.994370824000043],
            [-81.053960291999942, 34.994618824000042],
            [-81.053948291999973, 34.994686824000041],
            [-81.053899290999937, 34.994969824000066],
            [-81.053786291999984, 34.995547824000028],
            [-81.05306729199998, 34.998994826000057],
            [-81.055218292999939, 34.999304825000024],
            [-81.055460291999964, 34.999302825000029],
            [-81.05569729299998, 34.999262825000073],
            [-81.055920292999986, 34.99918582500004],
            [-81.05612129299999, 34.999075824000045],
            [-81.05629429399994, 34.998935825000046],
            [-81.056430292999949, 34.998771825000063],
            [-81.05652629299999, 34.99858882500007],
            [-81.056577292999975, 34.998394824000059],
            [-81.056691292999972, 34.997406825000041],
            [-81.056820292999987, 34.997056824000026],
            [-81.056932292999988, 34.996872824000036],
            [-81.057071292999979, 34.996700824000072],
            [-81.057214292999959, 34.996537824000029],
            [-81.057320293999965, 34.996356825000078],
            [-81.057359292999934, 34.996243824000032],
            [-81.057386293999969, 34.996162824000066],
            [-81.057411292999973, 34.995963824000057],
            [-81.057398293999938, 34.995557824000059],
            [-81.057424293999986, 34.995353824000063],
            [-81.057476292999979, 34.995152824000058],
            [-81.057493292999936, 34.995107824000058],
            [-81.057553292999955, 34.994957824000039],
            [-81.058777292999935, 34.993008824000071],
            [-81.05880029399998, 34.992972823000059],
            [-81.059743293999986, 34.991644823000058],
            [-81.059812293999983, 34.99154782200003],
            [-81.06075229399994, 34.991759823000052],
            [-81.062149294999983, 34.992039823000027],
            [-81.062391294999941, 34.99206782400006],
            [-81.06263929399995, 34.992081823000035],
            [-81.062886294999942, 34.992081823000035],
            [-81.063133294999943, 34.99206782300007],
            [-81.063399293999964, 34.992036823000035],
            [-81.063718294999944, 34.992002823000064],
            [-81.063840294999977, 34.991994823000027],
            [-81.064026293999973, 34.991984823000053],
            [-81.064198294999983, 34.991984823000053],
            [-81.064367294999954, 34.991984823000053],
            [-81.064797295999938, 34.991983823000055],
            [-81.065088295999942, 34.991939822000063],
            [-81.065770294999936, 34.991855824000027],
            [-81.066069294999977, 34.991832823000038],
            [-81.066429295999967, 34.991843822000078],
            [-81.066773294999962, 34.991869823000059],
            [-81.067074294999941, 34.991918822000059],
            [-81.067375294999977, 34.991992823000032],
            [-81.067946295999946, 34.992189824000036],
            [-81.068300295999961, 34.992321823000054],
            [-81.068683295999961, 34.992461823000042],
            [-81.068763295999986, 34.992497823000065],
            [-81.069119296999986, 34.992659822000064],
            [-81.069856295999955, 34.992856822000078],
            [-81.070382295999934, 34.992930824000041],
            [-81.071165296999936, 34.992954823000048],
            [-81.075925298999948, 34.993066823000049],
            [-81.079341299999953, 34.993147822000026],
            [-81.08066329899998, 34.993159823000042],
            [-81.081069299999967, 34.993121823000024],
            [-81.081550299999947, 34.992985823000026],
            [-81.082031299999983, 34.992812823000065],
            [-81.082332299999962, 34.992663823000044],
            [-81.082707300999971, 34.992440822000049],
            [-81.08305330099995, 34.992180823000069],
            [-81.083443299999942, 34.99177282200003],
            [-81.083632300999966, 34.991515822000054],
            [-81.083763300999976, 34.991281822000076],
            [-81.083863300999951, 34.991077823000069],
            [-81.083944300999974, 34.990873822000026],
            [-81.083979300999943, 34.99078482200008],
            [-81.084064300999955, 34.990454821000071],
            [-81.084086301999946, 34.990079822000041],
            [-81.084119300999987, 34.988353821000032],
            [-81.084123300999977, 34.988106822000077],
            [-81.084152299999971, 34.986265821000075],
            [-81.084191299999986, 34.985871821000046],
            [-81.084262299999978, 34.98554082000004],
            [-81.084275299999945, 34.985477821000075],
            [-81.084398300999965, 34.985166821000064],
            [-81.084599299999979, 34.984759820000079],
            [-81.084763299999963, 34.984473820000062],
            [-81.085293300999979, 34.983537820000038],
            [-81.085347300999956, 34.983443820000048],
            [-81.08541730099995, 34.98330382000006],
            [-81.085526300999959, 34.982962821000058],
            [-81.085590299999978, 34.982672821000051],
            [-81.085809300999983, 34.981790820000072],
            [-81.085897300999989, 34.981419820000042],
            [-81.084368299999937, 34.980516820000048],
            [-81.083876299999986, 34.98019682000006],
            [-81.082955299999981, 34.979599819000043],
            [-81.082582299999956, 34.979370820000042],
            [-81.083046299999978, 34.979262820000031],
            [-81.083160299999975, 34.979212819000054],
            [-81.083437299999957, 34.979094819000068],
            [-81.082917298999973, 34.978782820000049],
            [-81.080453298999942, 34.977281819000041],
            [-81.079088297999988, 34.976351820000048],
            [-81.077775297999949, 34.97545881800005],
            [-81.077001297999971, 34.974931819000062],
            [-81.074820296999974, 34.973452818000055],
            [-81.074793296999985, 34.973415819000024],
            [-81.074274297999978, 34.973055819000024],
            [-81.074229296999988, 34.973029818000043],
            [-81.074190296999973, 34.972997819000057],
            [-81.073526296999944, 34.972536818000037],
            [-81.07183529699995, 34.97137581800007],
            [-81.071215296999981, 34.970935818000044],
            [-81.070783295999945, 34.970567818000063],
            [-81.070691294999961, 34.970465818000037],
            [-81.070464295999955, 34.970213817000058],
            [-81.070197295999947, 34.969816818000027],
            [-81.069929294999952, 34.969277817000034],
            [-81.069904295999947, 34.969196818000057],
            [-81.069756294999934, 34.968710817000044],
            [-81.069690295999976, 34.968368818000044],
            [-81.069602294999981, 34.967913817000067],
            [-81.069523294999954, 34.967505818000063],
            [-81.069471294999971, 34.967517818000033],
            [-81.068127294999954, 34.968129817000033],
            [-81.067463294999982, 34.96844481800008],
            [-81.06685629499998, 34.968744818000062],
            [-81.065914293999981, 34.969196818000057],
            [-81.065606293999963, 34.969337818000042],
            [-81.065221294999958, 34.969509818000063],
            [-81.065025293999952, 34.969565819000024],
            [-81.064702292999982, 34.969665818000067],
            [-81.064431293999974, 34.969714819000046],
            [-81.064157292999937, 34.969744818000038],
            [-81.063881294999987, 34.969754818000069],
            [-81.063605293999956, 34.969744818000038],
            [-81.063330292999979, 34.969715819000044],
            [-81.063060293999968, 34.969666818000064],
            [-81.062796292999963, 34.969597818000068],
            [-81.060928292999961, 34.969035819000055],
            [-81.060095291999971, 34.968784818000074],
            [-81.058797291999952, 34.968391818000043],
            [-81.058753291999949, 34.968378818000076],
            [-81.058044291999977, 34.96816381800005],
            [-81.057748291999985, 34.96807781800004],
            [-81.056883290999963, 34.967825818000051],
            [-81.056613290999962, 34.967767818000027],
            [-81.056338290999975, 34.967728818000069],
            [-81.056090290999975, 34.967711818000055],
            [-81.055781291999949, 34.967710818000057],
            [-81.05550329099998, 34.967731818000061],
            [-81.054933289999951, 34.967820818000064],
            [-81.054651290999971, 34.967276818000073],
            [-81.053085289999956, 34.964720818000046],
            [-81.051618289999965, 34.962232818000075],
            [-81.050478288999955, 34.960278817000074],
            [-81.049547288999975, 34.958743817000027],
            [-81.049462288999962, 34.958602816000052],
            [-81.048298287999955, 34.956776815000069],
            [-81.048265287999982, 34.956725816000073],
            [-81.048145286999954, 34.956537816000036],
            [-81.04783028899999, 34.956110817000024],
            [-81.047666287999959, 34.955887816000029],
            [-81.04750828799996, 34.955689816000074],
            [-81.047285287999955, 34.955409816000042],
            [-81.046515287999966, 34.95444681500004],
            [-81.045832286999939, 34.953589816000033],
            [-81.04268028599995, 34.949649815000043],
            [-81.040064284999971, 34.946367814000041],
            [-81.038803284999972, 34.944784814000059],
            [-81.038750283999946, 34.944718814000055],
            [-81.038553284999978, 34.944470813000066],
            [-81.038068283999962, 34.943862814000056],
            [-81.037688284999945, 34.943385813000077],
            [-81.036104283999975, 34.94144181300004],
            [-81.035261282999954, 34.940406813000038],
            [-81.033782282999937, 34.938471813000035],
            [-81.034007281999948, 34.938380813000038],
            [-81.034235283999976, 34.938305813000056],
            [-81.034454282999945, 34.938219812000057],
            [-81.03461428199995, 34.938070813000024],
            [-81.035872283999936, 34.936777812000059],
            [-81.036472283999956, 34.936166812000067],
            [-81.037432283999976, 34.935208812000042],
            [-81.039020283999946, 34.933599812000068],
            [-81.040217283999937, 34.934395812000048],
            [-81.042045284999972, 34.932435811000062],
            [-81.042144284999949, 34.932328811000048],
            [-81.042303284999946, 34.932157811000025],
            [-81.042351284999938, 34.93210681000005],
            [-81.042687284999943, 34.931745811000042],
            [-81.042959284999938, 34.931452810000053],
            [-81.043372285999965, 34.931010811000078],
            [-81.044494285999974, 34.931088811000052],
            [-81.045164285999988, 34.931147811000073],
            [-81.04581628599999, 34.931205811000041],
            [-81.046415285999956, 34.93125781100008],
            [-81.048032286999955, 34.93139681100007],
            [-81.04955728799996, 34.931529811000075],
            [-81.049780287999965, 34.93154781000004],
            [-81.050588286999982, 34.931604810000067],
            [-81.050960286999953, 34.931631811000045],
            [-81.052417286999969, 34.931755810000027],
            [-81.052486288999944, 34.931760810000071],
            [-81.054135288999987, 34.931891810000025],
            [-81.054789288999984, 34.931953810000039],
            [-81.054935288999957, 34.931964811000057],
            [-81.055162288999952, 34.93205781000006],
            [-81.055354288999979, 34.932177811000031],
            [-81.055402288999971, 34.932238811000047],
            [-81.055952288999947, 34.932239811000045],
            [-81.058168290999959, 34.932244810000043],
            [-81.057794289999947, 34.931214810000029],
            [-81.057546289999948, 34.930550810000057],
            [-81.05733528899998, 34.929869810000071],
            [-81.057175289999975, 34.929253810000034],
            [-81.057156288999977, 34.929151810000064],
            [-81.057051288999958, 34.928661810000051],
            [-81.05690328899999, 34.927691810000056],
            [-81.05682528899996, 34.927178810000044],
            [-81.056702289999976, 34.926228810000055],
            [-81.056650288999947, 34.925827809000054],
            [-81.056558288999952, 34.925215809000065],
            [-81.056503289999966, 34.924773808000054],
            [-81.056458288999977, 34.924487809000027],
            [-81.056423288999952, 34.92436181000005],
            [-81.056398288999958, 34.924269809000066],
            [-81.056364288999987, 34.924145809000038],
            [-81.056247288999941, 34.923858809000023],
            [-81.056114288999936, 34.923594808000075],
            [-81.05607028899999, 34.923529809000058],
            [-81.05594328899997, 34.923344808000024],
            [-81.055699288999961, 34.923061808000057],
            [-81.055551288999936, 34.922916809000071],
            [-81.055570288999945, 34.922889808000036],
            [-81.05563628799996, 34.92271880900006],
            [-81.055650288999971, 34.922614808000048],
            [-81.055656288999955, 34.922416808000037],
            [-81.055690288999983, 34.92216380800005],
            [-81.055823288999989, 34.921943808000037],
            [-81.056077288999973, 34.921377808000045],
            [-81.056197288999954, 34.921152808000045],
            [-81.05627028899994, 34.921047808000026],
            [-81.056391288999976, 34.920954808000033],
            [-81.056477288999986, 34.92077880800008],
            [-81.056684288999975, 34.920190808000029],
            [-81.056702288999986, 34.92008480800007],
            [-81.056958288999965, 34.920157808000056],
            [-81.058356289999949, 34.920539808000058],
            [-81.061691290999988, 34.921457807000024],
            [-81.06246028999999, 34.921668808000049],
            [-81.062464290999969, 34.921168808000061],
            [-81.062450290999948, 34.920327807000035],
            [-81.062472290999949, 34.920030808000035],
            [-81.062484289999986, 34.91987180700005],
            [-81.062450290999948, 34.919508807000057],
            [-81.062537290999956, 34.91907980700006],
            [-81.06264428999998, 34.918799808000074],
            [-81.06277129099999, 34.918496807000054],
            [-81.062911290999978, 34.918067807000057],
            [-81.062938289999977, 34.917848807000041],
            [-81.06304529099998, 34.917633808000062],
            [-81.063118290999967, 34.917490807000036],
            [-81.063198290999935, 34.917182808000064],
            [-81.063265289999947, 34.916831807000051],
            [-81.063300289999972, 34.916722807000042],
            [-81.063378290999935, 34.916484806000028],
            [-81.063398289999952, 34.916110807000052],
            [-81.063398289999952, 34.915721807000068],
            [-81.063398289999952, 34.915632806000076],
            [-81.063492290999989, 34.915346807000049],
            [-81.063538290999986, 34.914874807000047],
            [-81.06361229099997, 34.914599806000069],
            [-81.063711290999947, 34.914353807000055],
            [-81.063732290999951, 34.91430280600008],
            [-81.063932290999958, 34.913988806000077],
            [-81.064046290999954, 34.913801806000038],
            [-81.064232290999939, 34.913384805000078],
            [-81.064466290999974, 34.912955805000024],
            [-81.064599290999979, 34.912839806000079],
            [-81.064773290999938, 34.91274680600003],
            [-81.064800290999983, 34.912504807000062],
            [-81.064940290999971, 34.91209780500003],
            [-81.065033290999963, 34.91195480600004],
            [-81.065060290999952, 34.91181180500007],
            [-81.065073290999976, 34.911372806000031],
            [-81.065127290999953, 34.911185806000049],
            [-81.065293289999943, 34.910772805000079],
            [-81.065460291999955, 34.91041580600006],
            [-81.065478290999977, 34.910298806000071],
            [-81.064394289999939, 34.91037480500006],
            [-81.063985289999948, 34.91037880600004],
            [-81.063746290999973, 34.910364806000075],
            [-81.063464290999946, 34.910336806000032],
            [-81.063092290999975, 34.910271805000036],
            [-81.062863289999939, 34.910213805000069],
            [-81.062477289999947, 34.910080805000064],
            [-81.062250289999952, 34.909986806000063],
            [-81.061838288999979, 34.909773805000043],
            [-81.061464289999947, 34.909511805000079],
            [-81.06110928999999, 34.909194805000027],
            [-81.061030289999962, 34.909110805000068],
            [-81.060952289999989, 34.909026805000053],
            [-81.060737289999963, 34.908758805000048],
            [-81.060426288999963, 34.908363805000079],
            [-81.06015728999995, 34.90802280500003],
            [-81.060054289999982, 34.907896806000053],
            [-81.059877289999974, 34.907680805000041],
            [-81.059843289999947, 34.907638805000033],
            [-81.059550288999958, 34.907317806000037],
            [-81.05948828999999, 34.907249805000049],
            [-81.059291288999987, 34.907074805000036],
            [-81.05905028799998, 34.906941805000031],
            [-81.058896288999961, 34.906869806000032],
            [-81.058832287999962, 34.906843805000051],
            [-81.058788288999949, 34.906885805000059],
            [-81.058627288999958, 34.907039805000068],
            [-81.057709288999945, 34.907812805000049],
            [-81.057386288999965, 34.908084805000044],
            [-81.056226287999948, 34.908898805000035],
            [-81.055067287999975, 34.909585805000063],
            [-81.054911287999971, 34.909678805000055],
            [-81.053746286999967, 34.910298806000071],
            [-81.05313328799997, 34.910625806000041],
            [-81.048589285999981, 34.913076806000049],
            [-81.047636285999943, 34.913607807000062],
            [-81.047229284999958, 34.913820806000047],
            [-81.046408285999973, 34.91425080700003],
            [-81.045699285999945, 34.914626808000037],
            [-81.044763284999988, 34.913827807000075],
            [-81.041949282999951, 34.91157180600004],
            [-81.040440283999942, 34.910360807000075],
            [-81.039956282999981, 34.909939807000057],
            [-81.039913283999965, 34.909964806000062],
            [-81.039749282999935, 34.910102807000044],
            [-81.039241282999967, 34.910507806000055],
            [-81.038588282999967, 34.909959806000074],
            [-81.037137281999946, 34.91110580600008],
            [-81.035927281999989, 34.912092807000079],
            [-81.035689281999964, 34.912266807000037],
            [-81.035362281999937, 34.912550807000059],
            [-81.034870280999939, 34.912937807000048],
            [-81.034337281999967, 34.913384807000057],
            [-81.034190280999951, 34.91350780700003],
            [-81.033750281999971, 34.914121808000061],
            [-81.033620280999969, 34.91430280700007],
            [-81.034666282999979, 34.915044807000072],
            [-81.034329281999987, 34.915381808000063],
            [-81.032983280999986, 34.916727808000076],
            [-81.032704280999951, 34.916974808000077],
            [-81.032548281999937, 34.917100808000043],
            [-81.031509280999956, 34.917937808000033],
            [-81.031117281999968, 34.91825280900008],
            [-81.030765280999958, 34.918535808000058],
            [-81.029798280999955, 34.919310809000024],
            [-81.028894280999964, 34.918437808000078],
            [-81.028462280999975, 34.918022809000036],
            [-81.02787227999994, 34.917456809000043],
            [-81.027289279999934, 34.91692580800003],
            [-81.026976278999939, 34.918015809000053],
            [-81.026953279999987, 34.918177809000042],
            [-81.026846279999972, 34.918948809000028],
            [-81.026770279999937, 34.919488809000029],
            [-81.023994278999965, 34.918412809000074],
            [-81.022574278999969, 34.917847809000079],
            [-81.02016227699994, 34.916796808000072],
            [-81.019462276999946, 34.917924809000056],
            [-81.018824277999954, 34.919019809000076],
            [-81.018173277999949, 34.920084810000048],
            [-81.015628276999962, 34.918782809000049],
            [-81.014296275999982, 34.918180809000035],
            [-81.013244275999966, 34.917545809000046],
            [-81.012876275999986, 34.918783809000047],
            [-81.011365274999946, 34.918040809000047],
            [-81.011148275999972, 34.917952809000042],
            [-81.010866274999955, 34.917862810000031],
            [-81.009617274999982, 34.917460809000033],
            [-81.009354274999964, 34.918059810000045],
            [-81.009049273999949, 34.918704809000076],
            [-81.00896127499999, 34.918944809000038],
            [-81.008787274999975, 34.919200809000074],
            [-81.008083273999944, 34.918847809000056],
            [-81.007623273999968, 34.918679810000071],
            [-81.006377272999941, 34.918224809000037],
            [-81.005679273999988, 34.917969809000056],
            [-81.003896271999963, 34.917479809000042],
            [-81.001499270999943, 34.916980809000052],
            [-81.000353271999984, 34.916767809000078],
            [-81.000073271999952, 34.916715809000038],
            [-80.999485271999959, 34.916606809000029],
            [-80.999103270999967, 34.916535809000038],
            [-80.995679269999982, 34.915715809000062],
            [-80.992570269999987, 34.915045808000059],
            [-80.992052269999988, 34.914925808000078],
            [-80.990423267999972, 34.914548810000042],
            [-80.989856268999972, 34.914417808000053],
            [-80.986301267999977, 34.913433809000026],
            [-80.984581266999953, 34.913064809000048],
            [-80.984457266999982, 34.913037809000059],
            [-80.983220266999979, 34.912890809000032],
            [-80.982738265999956, 34.912833809000063],
            [-80.98246226599997, 34.913373809000063],
            [-80.982418266999957, 34.913458810000066],
            [-80.982390265999982, 34.913509809000061],
            [-80.981021265999971, 34.916134809000027],
            [-80.98066926599995, 34.916808810000077],
            [-80.98038726599998, 34.917348810000078],
            [-80.979437265999934, 34.919169811000074],
            [-80.97890926599996, 34.92018081100008],
            [-80.978632265999977, 34.920711811000047],
            [-80.975380265999945, 34.926940812000055],
            [-80.97400526499996, 34.929575813000042],
            [-80.973883264999984, 34.929814813000064],
            [-80.97238426399997, 34.932763814000054],
            [-80.972104263999938, 34.933314813000038],
            [-80.971642263999968, 34.934204814000054],
            [-80.971399263999956, 34.934774815000026],
            [-80.97122826399999, 34.935504814000069],
            [-80.971156263999944, 34.936262814000031],
            [-80.971063264999941, 34.93724981500003],
            [-80.97108626399995, 34.937975814000026],
            [-80.971124264999958, 34.938316815000064],
            [-80.97113126499994, 34.938604815000076],
            [-80.971112264999988, 34.938898815000073],
            [-80.97109726399998, 34.939051815000028],
            [-80.971044263999943, 34.939336816000036],
            [-80.970980263999934, 34.939546815000028],
            [-80.970873264999966, 34.939899815000047],
            [-80.970678264999947, 34.940009815000053],
            [-80.970228263999957, 34.940175816000078],
            [-80.971315264999987, 34.940326815000049],
            [-80.971790264999981, 34.940407816000061],
            [-80.972464264999985, 34.940509816000031],
            [-80.973513264999951, 34.942958816000043],
            [-80.974203264999971, 34.944570816000066],
            [-80.974233265999942, 34.944624816000044],
            [-80.974306265999985, 34.944796816000064],
            [-80.974498265999955, 34.945248817000049],
            [-80.974539265999965, 34.945367816000044],
            [-80.974595264999948, 34.945476816000053],
            [-80.974731265999935, 34.945796816000041],
            [-80.975071266999976, 34.94659581600007],
            [-80.97619526699998, 34.949241818000075],
            [-80.976345266999942, 34.949592818000042],
            [-80.976577266999982, 34.950130817000058],
            [-80.976684266999939, 34.950380817000053],
            [-80.97675426699999, 34.950542817000041],
            [-80.980033267999943, 34.95814081900005],
            [-80.982703269999945, 34.964319821000061],
            [-80.983185269999979, 34.965432820000046],
            [-80.983382268999947, 34.965887820000034],
            [-80.984113269999966, 34.967579821000072],
            [-80.984160269999961, 34.967687820000037],
            [-80.984306269999934, 34.968025821000026],
            [-80.984538268999984, 34.96856082000005],
            [-80.984673269999973, 34.968881821000025],
            [-80.984715269999981, 34.968980821000059],
            [-80.985269270999936, 34.970283821000066],
            [-80.98539126999998, 34.970569821000026],
            [-80.986583269999983, 34.973370822000049],
            [-80.98667427099997, 34.973584822000078],
            [-80.986781270999984, 34.973803822000036],
            [-80.986950270999955, 34.974198822000062],
            [-80.987422270999957, 34.975301822000063],
            [-80.987692270999958, 34.975933822000059],
            [-80.987954271999968, 34.976545822000048],
            [-80.988040271999978, 34.976839823000034],
            [-80.988122270999952, 34.977114823000079],
            [-80.988196270999936, 34.977396823000049],
            [-80.988251271999957, 34.977681822000079],
            [-80.988289271999975, 34.977967823000029],
            [-80.988307271999986, 34.97825582300004],
            [-80.988308271999983, 34.978543822000063],
            [-80.988291271999969, 34.978831823000064],
            [-80.988272270999971, 34.97899882300004],
            [-80.988101271999938, 34.97993282300007],
            [-80.987897271999941, 34.980592823000052],
            [-80.987672271999941, 34.981089823000048],
            [-80.987631271999987, 34.981142823000027],
            [-80.987254270999983, 34.981746824000027],
            [-80.986699271999953, 34.982567824000057],
            [-80.983784270999934, 34.986879825000074],
            [-80.983757270999945, 34.986917825000035],
            [-80.983400270999937, 34.987445825000066],
            [-80.984278269999947, 34.987622826000063],
            [-80.986819270999945, 34.988387825000075],
            [-80.987669272999938, 34.988649825000039],
            [-80.989118271999985, 34.989340825000056],
            [-80.989497272999984, 34.989521825000054],
            [-80.989815273999966, 34.989647826000066],
            [-80.989874272999941, 34.989670826000065],
            [-80.989952272999972, 34.989703825000049],
            [-80.99115427299995, 34.990216826000051],
            [-80.993061272999967, 34.991047825000066],
            [-80.993153272999962, 34.991100825000046],
            [-80.993293273999939, 34.991181825000069],
            [-80.993321273999982, 34.991161825000063],
            [-80.993668273999958, 34.990940826000042],
            [-80.993799273999969, 34.990844825000067],
            [-80.993871274999947, 34.990803826000047],
            [-80.993916273999957, 34.990787825000041],
            [-80.993954273999975, 34.990777825000066],
            [-80.99399727399998, 34.990772825000079],
            [-80.994041274999972, 34.990773825000076],
            [-80.994170273999941, 34.990797826000062],
            [-80.994255274999944, 34.990817825000079],
            [-80.994457273999956, 34.990885825000078],
            [-80.994541273999971, 34.990907825000079],
            [-80.994584273999976, 34.990913826000053],
            [-80.994628274999968, 34.990908825000076],
            [-80.994669273999989, 34.990895826000042],
            [-80.994836273999965, 34.990857825000035],
            [-80.994918273999986, 34.990833825000038],
            [-80.994996274999949, 34.990803826000047],
            [-80.995038273999967, 34.990795825000077],
            [-80.995114274999935, 34.990762825000047],
            [-80.995234274999973, 34.990717825000047],
            [-80.995297273999938, 34.990668825000057],
            [-80.995352274999959, 34.990612824000038],
            [-80.995462273999976, 34.990555825000058],
            [-80.995570273999988, 34.990493824000055],
            [-80.995602274999953, 34.990469825000048],
            [-80.995627274999947, 34.990441825000062],
            [-80.995646274999956, 34.990409826000075],
            [-80.995654273999946, 34.99037482600005],
            [-80.995649273999959, 34.990339825000035],
            [-80.99566327499997, 34.990305825000064],
            [-80.995692273999964, 34.990279825000073],
            [-80.995743273999949, 34.990222825000046],
            [-80.995827273999964, 34.99014182500008],
            [-80.996088274999977, 34.990013825000062],
            [-80.996155273999989, 34.989968825000062],
            [-80.996285273999945, 34.98987182500008],
            [-80.996387274999961, 34.989803825000024],
            [-80.99645927499995, 34.989764825000066],
            [-80.996499274999962, 34.989750825000044],
            [-80.996583274999978, 34.989730826000027],
            [-80.996670273999939, 34.989730826000027],
            [-80.996756274999939, 34.989736824000033],
            [-80.996921274999977, 34.989779824000038],
            [-80.996964274999982, 34.989784826000061],
            [-80.997008274999985, 34.989782825000077],
            [-80.997092273999954, 34.989762825000071],
            [-80.997127274999968, 34.989740825000069],
            [-80.997238274999972, 34.989685825000038],
            [-80.997352274999969, 34.989634825000053],
            [-80.997381274999952, 34.989608825000062],
            [-80.997406274999946, 34.989578825000024],
            [-80.997440274999974, 34.989513825000074],
            [-80.99747627499994, 34.98941082500005],
            [-80.997549274999983, 34.989321825000047],
            [-80.997613274999935, 34.989273824000065],
            [-80.997724274999939, 34.989215825000031],
            [-80.997766274999947, 34.989204825000058],
            [-80.99798227499997, 34.989180825000062],
            [-80.998025274999975, 34.989178825000067],
            [-80.998069274999978, 34.989182825000057],
            [-80.998194275999936, 34.989210825000043],
            [-80.99844027499995, 34.989280825000037],
            [-80.998599274999947, 34.989337825000064],
            [-80.998709274999953, 34.989395825000031],
            [-80.998788275999971, 34.989427825000064],
            [-80.998917275999986, 34.989446825000073],
            [-80.999006274999942, 34.989446825000073],
            [-80.999181274999955, 34.989434825000046],
            [-80.999531274999981, 34.98945182500006],
            [-80.999747274999947, 34.989475825000056],
            [-80.999785274999965, 34.989493825000068],
            [-80.999827274999973, 34.989506825000035],
            [-80.999859275999938, 34.989529825000034],
            [-80.999970274999953, 34.989639824000051],
            [-81.000009275999957, 34.989651825000067],
            [-81.000137275999975, 34.989674824000076],
            [-81.000223275999986, 34.989661824000052],
            [-81.000309275999939, 34.989657825000052],
            [-81.000395275999949, 34.989669825000078],
            [-81.000436275999959, 34.989683825000043],
            [-81.000472274999936, 34.989703825000049],
            [-81.000536275999934, 34.989748826000039],
            [-81.000563274999934, 34.989776825000035],
            [-81.000693275999936, 34.989867825000033],
            [-81.000843276999944, 34.989936825000029],
            [-81.000925275999975, 34.989959825000028],
            [-81.001225275999957, 34.989992825000058],
            [-81.001661275999936, 34.989983825000024],
            [-81.001704275999941, 34.989983825000024],
            [-81.001832275999959, 34.98997382500005],
            [-81.002089276999982, 34.989972824000063],
            [-81.002131275999943, 34.989978825000037],
            [-81.002219275999948, 34.989976825000042],
            [-81.002305275999959, 34.989983825000024],
            [-81.002562275999935, 34.990031824000027],
            [-81.002950276999968, 34.990091825000036],
            [-81.003334275999975, 34.990168824000079],
            [-81.003417275999936, 34.990189824000026],
            [-81.003546276999941, 34.990183824000042],
            [-81.003631276999954, 34.990166825000074],
            [-81.003674276999959, 34.99016182400004],
            [-81.003714276999972, 34.990148824000073],
            [-81.003784276999966, 34.990105825000057],
            [-81.003908277999983, 34.990004825000028],
            [-81.00394427699996, 34.989985825000076],
            [-81.004031277999957, 34.989979825000034],
            [-81.004117276999978, 34.989990824000074],
            [-81.004158276999988, 34.990003825000031],
            [-81.004235276999964, 34.990038825000056],
            [-81.004354276999948, 34.990084825000054],
            [-81.004397276999953, 34.990090825000038],
            [-81.004485276999958, 34.990091825000036],
            [-81.004571276999968, 34.990076824000028],
            [-81.004649276999942, 34.990045824000049],
            [-81.004858277999972, 34.98991682500008],
            [-81.004938277999941, 34.989889824000045],
            [-81.005063276999977, 34.989862825000046],
            [-81.005238277999979, 34.989850825000076],
            [-81.005400276999978, 34.989799825000034],
            [-81.005516277999959, 34.989749825000047],
            [-81.00555327699999, 34.98972982500004],
            [-81.005583276999971, 34.989704824000057],
            [-81.005654276999962, 34.989664825000034],
            [-81.005785277999962, 34.989573825000036],
            [-81.005857276999961, 34.989534825000078],
            [-81.006055277999963, 34.989457825000045],
            [-81.006087276999949, 34.989432824000062],
            [-81.006112276999943, 34.989403825000068],
            [-81.006198276999953, 34.989322825000045],
            [-81.006321276999984, 34.989225824000073],
            [-81.006380277999938, 34.989173824000034],
            [-81.006456277999973, 34.989086825000072],
            [-81.006462277999958, 34.98905082400006],
            [-81.006456276999984, 34.989016824000032],
            [-81.006456277999973, 34.988946825000028],
            [-81.006444277999947, 34.988912825000057],
            [-81.006358276999947, 34.988831824000044],
            [-81.006260277999957, 34.988760824000053],
            [-81.006209276999982, 34.988703824000027],
            [-81.006192277999958, 34.988670824000053],
            [-81.006175277999944, 34.988530825000055],
            [-81.006183276999934, 34.98849582500003],
            [-81.006201276999946, 34.988463825000053],
            [-81.006226276999939, 34.988396824000063],
            [-81.006253277999974, 34.988257825000062],
            [-81.006279276999976, 34.987760824000077],
            [-81.00629627699999, 34.987548824000044],
            [-81.006305277999957, 34.987513824000075],
            [-81.006320277999976, 34.987481824000042],
            [-81.006367276999981, 34.98742182400008],
            [-81.006461276999971, 34.987348825000026],
            [-81.00649627699994, 34.987328824000031],
            [-81.006611276999934, 34.987279823000051],
            [-81.006776276999972, 34.987243824000075],
            [-81.006857277999984, 34.987218824000024],
            [-81.006928277999975, 34.987178823000079],
            [-81.007059276999939, 34.987115825000046],
            [-81.007473277999964, 34.98695682400006],
            [-81.007954277999943, 34.986840824000069],
            [-81.008241278999947, 34.986830823000048],
            [-81.008427276999953, 34.986808823000047],
            [-81.008353277999959, 34.987295823000068]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 15,
        "SolutionID": "PI-9",
        "Shape_Length": 0.49852191979557847,
        "Shape_Area": 0.0061678888042735009
      }
    },
    {
      "type": "Feature",
      "id": 16,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.914989253999977, 35.060404843000072],
            [-80.914438253999947, 35.061246843000049],
            [-80.913850254999943, 35.062104843000043],
            [-80.912442252999938, 35.064201844000024],
            [-80.911695253999937, 35.065261844000077],
            [-80.911639252999976, 35.065351844000077],
            [-80.912108252999985, 35.065541844000052],
            [-80.912220252999987, 35.065603845000055],
            [-80.912348253999937, 35.065673844000059],
            [-80.912536252999985, 35.065836844000046],
            [-80.91271025399999, 35.066099845000053],
            [-80.912821254999983, 35.066313845000025],
            [-80.913107254999943, 35.066265844000043],
            [-80.913340253999934, 35.066226844000028],
            [-80.913318253999989, 35.066132845000027],
            [-80.913310253999953, 35.066046844000027],
            [-80.913312253999948, 35.065989844000057],
            [-80.913452253999935, 35.065798844000028],
            [-80.91351025299997, 35.065735844000073],
            [-80.914207254999951, 35.065055844000028],
            [-80.914235253999948, 35.065037844000074],
            [-80.914329253999938, 35.06478084400004],
            [-80.914524253999957, 35.064136844000075],
            [-80.915413254999976, 35.061316843000043],
            [-80.915867253999977, 35.059836843000028],
            [-80.915889253999978, 35.059789843000033],
            [-80.915919254999949, 35.05975284200008],
            [-80.915961254999957, 35.059728843000073],
            [-80.916012253999952, 35.059714843000052],
            [-80.916039254999987, 35.059712843000057],
            [-80.916100253999957, 35.059707843000069],
            [-80.917095254999936, 35.059771843000078],
            [-80.917751255999974, 35.059796843000072],
            [-80.918370255999946, 35.05984284200008],
            [-80.91949825599994, 35.059906842000032],
            [-80.920073255999966, 35.059941843000047],
            [-80.920778255999949, 35.059906842000032],
            [-80.921307255999977, 35.059774843000071],
            [-80.92136725599994, 35.059755843000062],
            [-80.921792255999947, 35.059618843000067],
            [-80.921841256999983, 35.059591842000032],
            [-80.922197256999937, 35.059396842000069],
            [-80.922940256999937, 35.059042842000053],
            [-80.923551255999939, 35.058802843000024],
            [-80.924208256999975, 35.05864284300003],
            [-80.924731256999962, 35.058576842000036],
            [-80.925057256999935, 35.058570843000041],
            [-80.925812256999961, 35.058557842000027],
            [-80.927123256999948, 35.05854484200006],
            [-80.928606257999945, 35.058545842000058],
            [-80.929580257999987, 35.058525842000051],
            [-80.930051257999935, 35.058535842000026],
            [-80.929875258999971, 35.057933842000068],
            [-80.929483257999948, 35.056639842000038],
            [-80.929173257999935, 35.055662841000071],
            [-80.928978257999972, 35.055078841000068],
            [-80.928640257999973, 35.054007842000033],
            [-80.928380257999947, 35.05318284100008],
            [-80.928259257999969, 35.052751841000031],
            [-80.928167257999974, 35.052422841000066],
            [-80.927905257999953, 35.051666841000042],
            [-80.927487256999939, 35.050347840000029],
            [-80.927288256999987, 35.049715841000079],
            [-80.927137257999959, 35.049184841000056],
            [-80.92706325599994, 35.048708840000074],
            [-80.927058257999988, 35.048310841000045],
            [-80.927064256999984, 35.048190840000075],
            [-80.927108256999986, 35.047856840000065],
            [-80.927399256999934, 35.046291839000048],
            [-80.928062256999965, 35.042697839000027],
            [-80.928454256999942, 35.040768838000076],
            [-80.928693256999964, 35.039633838000043],
            [-80.929271257999972, 35.037809838000044],
            [-80.929627256999936, 35.037125838000065],
            [-80.929981257999941, 35.036590837000062],
            [-80.930203257999949, 35.036288837000029],
            [-80.930243257999962, 35.036235837000049],
            [-80.930252257999939, 35.036238838000031],
            [-80.930294257999947, 35.036246837000078],
            [-80.930509257999972, 35.036271837000072],
            [-80.930683256999941, 35.036279837000052],
            [-80.930769256999952, 35.036287837000032],
            [-80.931077257999959, 35.036285837000037],
            [-80.931341257999975, 35.036271838000062],
            [-80.931471257999988, 35.036260838000032],
            [-80.931556257999944, 35.036249837000071],
            [-80.931643257999951, 35.036244837000027],
            [-80.931908257999964, 35.036239838000029],
            [-80.931995256999983, 35.036243838000075],
            [-80.93212325799999, 35.036258838000037],
            [-80.932250257999954, 35.036283837000042],
            [-80.932374257999982, 35.036314838000067],
            [-80.932455258999937, 35.036340837000068],
            [-80.932694258999959, 35.036434837000058],
            [-80.932895257999974, 35.036503837000055],
            [-80.933018257999947, 35.036541837000073],
            [-80.933146258999955, 35.036556837000035],
            [-80.933322257999976, 35.036563837000074],
            [-80.933364257999983, 35.036554838000029],
            [-80.93351925799999, 35.036489837000033],
            [-80.933601257999953, 35.036462837000045],
            [-80.93380925799994, 35.036402837000026],
            [-80.933895258999939, 35.036394837000046],
            [-80.933984257999953, 35.036393838000038],
            [-80.934064258999967, 35.036418838000031],
            [-80.934138258999951, 35.036455837000062],
            [-80.934177258999966, 35.036481837000053],
            [-80.934249258999955, 35.036522838000053],
            [-80.934326257999942, 35.036554838000029],
            [-80.934529257999941, 35.036611838000056],
            [-80.934771258999945, 35.03668583700005],
            [-80.934809258999962, 35.036702837000064],
            [-80.935049258999982, 35.036780837000038],
            [-80.935127258999955, 35.036811837000073],
            [-80.935209258999976, 35.036836837000067],
            [-80.935327258999962, 35.036879837000072],
            [-80.935522258999981, 35.03695983700004],
            [-80.935720258999936, 35.037031837000029],
            [-80.935885258999974, 35.037068837000049],
            [-80.935923258999935, 35.037084837000066],
            [-80.93604425999996, 35.037122837000027],
            [-80.936127259999978, 35.037142837000033],
            [-80.93624725899997, 35.037184837000041],
            [-80.936393258999942, 35.037262837000071],
            [-80.936473259999957, 35.037288837000062],
            [-80.936512259999972, 35.037305837000076],
            [-80.936719258999972, 35.037357837000059],
            [-80.936839258999953, 35.037401837000061],
            [-80.936992258999965, 35.03746983700006],
            [-80.937246258999949, 35.037603837000063],
            [-80.937285258999964, 35.037620838000066],
            [-80.937355260999936, 35.037661838000076],
            [-80.937431259999983, 35.037697837000053],
            [-80.937576258999968, 35.037748837000038],
            [-80.937820259999967, 35.037822838000068],
            [-80.937903258999938, 35.037842838000074],
            [-80.938065259999973, 35.037894838000057],
            [-80.938188259999947, 35.037929838000025],
            [-80.938272260999952, 35.037948837000044],
            [-80.938476259999959, 35.038006837000069],
            [-80.938556259999984, 35.038034838000044],
            [-80.938866259999941, 35.038166837000063],
            [-80.939008260999969, 35.038243838000028],
            [-80.939085260999946, 35.038275837000072],
            [-80.939156259999947, 35.038316837000025],
            [-80.939320260999978, 35.038431837000076],
            [-80.939385259999938, 35.038521837000076],
            [-80.939418260999958, 35.038586837000025],
            [-80.939440259999969, 35.038655838000068],
            [-80.939485259999969, 35.038864837000062],
            [-80.939494259999947, 35.038969838000071],
            [-80.939398259999962, 35.039426838000054],
            [-80.939324260999967, 35.039847838000071],
            [-80.939319259999934, 35.039917838000065],
            [-80.939265260999946, 35.040339838000079],
            [-80.939247260999934, 35.040444837000052],
            [-80.939211259999979, 35.040582838000034],
            [-80.939170259999969, 35.040682838000066],
            [-80.939115259999937, 35.040776838000056],
            [-80.939071259999935, 35.040835838000078],
            [-80.939007259999983, 35.040884838000068],
            [-80.938936259999934, 35.040925838000078],
            [-80.938775260999989, 35.040978838000058],
            [-80.938488259999986, 35.041064838000068],
            [-80.938455259999955, 35.041088838000064],
            [-80.938401260999967, 35.041143838000039],
            [-80.938367260999939, 35.04116583800004],
            [-80.938255259999949, 35.041220838000072],
            [-80.938214259999938, 35.041232838000042],
            [-80.938129259999982, 35.041247838000061],
            [-80.938045259999967, 35.041267838000067],
            [-80.938005259999954, 35.041282838000029],
            [-80.937933259999966, 35.041322838000042],
            [-80.937854259999938, 35.041407838000055],
            [-80.937801259999958, 35.041538838000065],
            [-80.937715259999948, 35.041662839000026],
            [-80.937580259999947, 35.041845838000029],
            [-80.937540259999935, 35.041945838000061],
            [-80.937516259999938, 35.04204783800003],
            [-80.93751825999999, 35.042083838000053],
            [-80.937504259999969, 35.042151839000041],
            [-80.937501259999976, 35.042222838000043],
            [-80.937508259999959, 35.042293838000035],
            [-80.937532258999966, 35.042396839000048],
            [-80.93756725999998, 35.042497838000031],
            [-80.937628260999986, 35.042629838000039],
            [-80.937653259999934, 35.04269683900003],
            [-80.937745259999986, 35.042856839000024],
            [-80.937793259999978, 35.042916838000053],
            [-80.937821260999954, 35.042942838000045],
            [-80.937854259999938, 35.042964838000046],
            [-80.93789425999995, 35.04297783800007],
            [-80.93794625999999, 35.042979839000054],
            [-80.938058260999981, 35.042955838000069],
            [-80.938101259999939, 35.042951839000068],
            [-80.93818725999995, 35.042961839000043],
            [-80.938270259999967, 35.042979838000065],
            [-80.938428260999956, 35.043100839000033],
            [-80.938567259999957, 35.043238838000036],
            [-80.93864425999999, 35.043324838000046],
            [-80.938683259999948, 35.04338683900005],
            [-80.938697259999969, 35.043420838000031],
            [-80.938801259999934, 35.043766839000057],
            [-80.938848259999986, 35.043865839000034],
            [-80.938885260999939, 35.043929838000054],
            [-80.938933260999988, 35.044027838000034],
            [-80.939015259999962, 35.044228838000038],
            [-80.939032260999966, 35.044369839000069],
            [-80.939031260999968, 35.044404838000048],
            [-80.93902425999994, 35.044439839000063],
            [-80.93899726099994, 35.044506839000064],
            [-80.938970260999952, 35.044535838000058],
            [-80.938931260999937, 35.044550839000067],
            [-80.938755260999983, 35.044559838000055],
            [-80.938711259999934, 35.044556838000062],
            [-80.938668260999975, 35.044547839000074],
            [-80.938626259999978, 35.044543839000028],
            [-80.938583260999962, 35.044549839000069],
            [-80.938542259999963, 35.044562839000037],
            [-80.938516259999972, 35.044591839000077],
            [-80.938495260999957, 35.044622839000056],
            [-80.938452259999963, 35.044723839000028],
            [-80.938443259999985, 35.04479483800003],
            [-80.938451259999965, 35.044864838000024],
            [-80.938463260999981, 35.044898839000041],
            [-80.938484260999985, 35.044929839000076],
            [-80.938586260999955, 35.04504483900007],
            [-80.93862126099998, 35.045065839000074],
            [-80.938661260999936, 35.045080839000036],
            [-80.938743259999967, 35.045103839000035],
            [-80.938787260999959, 35.045111839000072],
            [-80.938873260999969, 35.045119839000051],
            [-80.939005259999988, 35.045124839000039],
            [-80.939222260999941, 35.045103838000045],
            [-80.939690260999953, 35.045025839000061],
            [-80.939731261999952, 35.04501583900003],
            [-80.939816259999986, 35.045002839000063],
            [-80.939901259999942, 35.044983839000054],
            [-80.940071260999957, 35.044959839000057],
            [-80.940198261999967, 35.044933839000066],
            [-80.940236260999939, 35.044915839000055],
            [-80.940316260999964, 35.044885839000074],
            [-80.940398260999984, 35.044863838000026],
            [-80.94048326099994, 35.044851838000056],
            [-80.940616260999946, 35.044858839000028],
            [-80.94107126199998, 35.044875839000042],
            [-80.941178261999937, 35.044712839000056],
            [-80.94214526199994, 35.043246838000073],
            [-80.943381261999946, 35.041348838000033],
            [-80.944018261999986, 35.040411838000068],
            [-80.944430261999969, 35.04079383800007],
            [-80.944861261999961, 35.041121838000038],
            [-80.945253262999984, 35.041306838000025],
            [-80.945691261999968, 35.041423838000071],
            [-80.94630226299995, 35.041476838000051],
            [-80.946881262999966, 35.041402838000067],
            [-80.947691262999967, 35.041223838000064],
            [-80.948257263999949, 35.041191838000032],
            [-80.948733262999951, 35.041202837000071],
            [-80.949170262999985, 35.041281837000042],
            [-80.94961426399999, 35.041419837000035],
            [-80.949730262999935, 35.041473837000069],
            [-80.949989263999953, 35.041594838000037],
            [-80.950299263999966, 35.041776838000033],
            [-80.95060226399994, 35.041998837000051],
            [-80.951189263999936, 35.04245983800007],
            [-80.95175726399998, 35.042843837000078],
            [-80.951961263999976, 35.042953837000027],
            [-80.952217263999955, 35.04303883800003],
            [-80.952404264999984, 35.043087838000076],
            [-80.952585264999982, 35.043119838000052],
            [-80.952802263999956, 35.043121838000047],
            [-80.953173264999975, 35.043107838000026],
            [-80.953215263999937, 35.043101838000041],
            [-80.953866264999988, 35.043014838000033],
            [-80.954299264999975, 35.042976838000072],
            [-80.954768265999974, 35.04298183800006],
            [-80.955632264999963, 35.043017838000026],
            [-80.958185264999941, 35.04312483800004],
            [-80.95849926599999, 35.043136838000066],
            [-80.959050265999963, 35.043163838000055],
            [-80.959289266999974, 35.043175838000025],
            [-80.959632265999971, 35.04341983900008],
            [-80.95964326699999, 35.043358838000074],
            [-80.959646266999982, 35.043339838000065],
            [-80.959644266999987, 35.043184838000059],
            [-80.959844265999948, 35.04181883800004],
            [-80.959920265999983, 35.041082837000033],
            [-80.959993266999959, 35.040367837000076],
            [-80.960080265999977, 35.039849837000077],
            [-80.960200265999958, 35.039141837000045],
            [-80.960365266999986, 35.037628836000067],
            [-80.960451266999939, 35.037096837000036],
            [-80.960460265999984, 35.037041837000061],
            [-80.960594266999976, 35.036217836000048],
            [-80.960910266999974, 35.035021835000066],
            [-80.961365265999973, 35.033851835000064],
            [-80.961512266999989, 35.033625836000056],
            [-80.961928266999962, 35.032663835000051],
            [-80.962601266999968, 35.03148983400007],
            [-80.963267266999935, 35.030497835000062],
            [-80.963879266999982, 35.029639834000079],
            [-80.964316267999948, 35.028995834000057],
            [-80.964751267999986, 35.028441835000024],
            [-80.964910266999937, 35.028199834000077],
            [-80.964985266999975, 35.028087835000065],
            [-80.965128266999955, 35.027896834000046],
            [-80.965333267999938, 35.028104834000032],
            [-80.965825267999946, 35.028657835000047],
            [-80.966062267999973, 35.028947834000064],
            [-80.966421267999976, 35.029384834000041],
            [-80.966790267999954, 35.029762835000042],
            [-80.966930268999988, 35.029922835000036],
            [-80.96736526899997, 35.030421835000027],
            [-80.967721267999934, 35.030810835000068],
            [-80.967896266999958, 35.03100583500003],
            [-80.968310268999971, 35.03146683500006],
            [-80.968529267999941, 35.031709835000072],
            [-80.968899267999973, 35.032133835000025],
            [-80.969400267999958, 35.032694835000029],
            [-80.969583268999941, 35.03281783500006],
            [-80.969834268999989, 35.032994835000068],
            [-80.97083626999995, 35.032332835000034],
            [-80.971888269999965, 35.031637835000026],
            [-80.973203268999953, 35.030654834000075],
            [-80.973662269999977, 35.030282834000047],
            [-80.973981269999967, 35.029825835000054],
            [-80.974217268999951, 35.029467834000059],
            [-80.974330269999939, 35.028814834000059],
            [-80.97434426999996, 35.028624834000027],
            [-80.974362269999972, 35.028381834000072],
            [-80.974328269999944, 35.027979834000064],
            [-80.974315268999987, 35.027821834000065],
            [-80.974252269999965, 35.027200833000052],
            [-80.974238269999944, 35.027057834000061],
            [-80.974038269999937, 35.025312833000044],
            [-80.974069269999973, 35.024932834000026],
            [-80.974193269999944, 35.024541834000047],
            [-80.974212269999953, 35.024511834000066],
            [-80.974403269999982, 35.024213833000033],
            [-80.974566269999968, 35.024007833000042],
            [-80.974791269999969, 35.023821832000067],
            [-80.975296269999944, 35.023506833000056],
            [-80.975682269999936, 35.023265833000039],
            [-80.975871269999971, 35.023161833000074],
            [-80.976505270999951, 35.022790833000045],
            [-80.977229269999953, 35.022309832000076],
            [-80.977436270999988, 35.022136833000047],
            [-80.977688270999977, 35.021940832000041],
            [-80.978015270999947, 35.021654833000071],
            [-80.978965271999982, 35.020816833000026],
            [-80.979247270999963, 35.02047783200004],
            [-80.979416270999934, 35.020277832000033],
            [-80.97975827099998, 35.01960383200003],
            [-80.979838270999949, 35.019392832000051],
            [-80.980071270999986, 35.018891832000065],
            [-80.980256270999973, 35.018456832000027],
            [-80.980415271999959, 35.018103832000065],
            [-80.980603271999939, 35.017645831000038],
            [-80.980785270999945, 35.017243832000077],
            [-80.981077271999936, 35.016538831000048],
            [-80.981147271999987, 35.016356831000053],
            [-80.98143227099996, 35.015714831000025],
            [-80.981794271999945, 35.014852831000042],
            [-80.981899270999975, 35.01433583000005],
            [-80.981905271999949, 35.014146831000062],
            [-80.981916272999968, 35.013769831000047],
            [-80.981863271999941, 35.013362831000052],
            [-80.981803271999979, 35.013154831000065],
            [-80.981601270999988, 35.012730830000066],
            [-80.981231271999945, 35.01211083000004],
            [-80.980955270999971, 35.011511830000074],
            [-80.980839270999979, 35.010867830000052],
            [-80.980857270999934, 35.010666830000048],
            [-80.98082027099997, 35.010180830000024],
            [-80.980781270999955, 35.009359830000051],
            [-80.980756270999962, 35.008690829000045],
            [-80.98074926999999, 35.008230830000059],
            [-80.980712270999959, 35.008055829000057],
            [-80.980658270999982, 35.00669982900007],
            [-80.980575269999974, 35.005568829000026],
            [-80.980429270999934, 35.004777828000044],
            [-80.980377270999952, 35.004563829000062],
            [-80.980240270999957, 35.004052828000056],
            [-80.980166269999984, 35.003746829000079],
            [-80.980030270999976, 35.003194828000062],
            [-80.979937269999937, 35.002948828000058],
            [-80.979917270999977, 35.002764828000068],
            [-80.979825269999935, 35.002500828000052],
            [-80.979487270999982, 35.00175082800007],
            [-80.979425269999979, 35.00161182800008],
            [-80.979347269999948, 35.001458828000068],
            [-80.979223270999967, 35.001229828000078],
            [-80.979033269999945, 35.000830828000062],
            [-80.97898626999995, 35.00073082800003],
            [-80.978887269999973, 35.000568828000041],
            [-80.978607268999951, 35.000110828000061],
            [-80.978402269999947, 34.999878827000032],
            [-80.978086269999949, 34.999520828000072],
            [-80.977929269999947, 34.999381828000026],
            [-80.977705269999944, 34.999181828000076],
            [-80.977561269999967, 34.999083827000049],
            [-80.977455268999961, 34.99901182800005],
            [-80.977232269999945, 34.998859827000047],
            [-80.97672326899999, 34.99855382700008],
            [-80.976156268999944, 34.998231827000041],
            [-80.976431268999988, 34.997797827000056],
            [-80.977612268999962, 34.996036827000069],
            [-80.978239268999971, 34.995121827000048],
            [-80.978861268999935, 34.994196827000053],
            [-80.979399269999988, 34.993360826000071],
            [-80.97945926999995, 34.993277827000043],
            [-80.980068269999947, 34.992435827000065],
            [-80.980635268999947, 34.99157582600003],
            [-80.980870268999979, 34.991218826000079],
            [-80.98181526999997, 34.989809826000055],
            [-80.98256226999996, 34.988703825000073],
            [-80.982866269999988, 34.988240825000048],
            [-80.983313270999986, 34.987574825000024],
            [-80.983357270999988, 34.987510825000072],
            [-80.983400270999937, 34.987445825000066],
            [-80.983757270999945, 34.986917825000035],
            [-80.983784270999934, 34.986879825000074],
            [-80.986699271999953, 34.982567824000057],
            [-80.987254270999983, 34.981746824000027],
            [-80.987631271999987, 34.981142823000027],
            [-80.987672271999941, 34.981089823000048],
            [-80.987897271999941, 34.980592823000052],
            [-80.988101271999938, 34.97993282300007],
            [-80.988272270999971, 34.97899882300004],
            [-80.988291271999969, 34.978831823000064],
            [-80.988308271999983, 34.978543822000063],
            [-80.988307271999986, 34.97825582300004],
            [-80.988289271999975, 34.977967823000029],
            [-80.988251271999957, 34.977681822000079],
            [-80.988196270999936, 34.977396823000049],
            [-80.988122270999952, 34.977114823000079],
            [-80.988040271999978, 34.976839823000034],
            [-80.987346271999968, 34.976838823000037],
            [-80.987052270999982, 34.976824822000026],
            [-80.986787270999969, 34.976796823000029],
            [-80.986568270999953, 34.976756823000073],
            [-80.986528270999941, 34.976749822000045],
            [-80.986265270999979, 34.976681823000035],
            [-80.986010271999987, 34.976594823000028],
            [-80.985765270999934, 34.976488823000068],
            [-80.98539126999998, 34.976250822000054],
            [-80.984080269999936, 34.975415822000059],
            [-80.983805269999948, 34.975240823000036],
            [-80.983468269999946, 34.975025823000067],
            [-80.982901269999957, 34.974665822000077],
            [-80.98204026999997, 34.974022822000052],
            [-80.982000269999958, 34.973992822000071],
            [-80.981573268999966, 34.973580822000031],
            [-80.981030268999973, 34.973007822000056],
            [-80.98096426799998, 34.97293882200006],
            [-80.980643269999973, 34.972639822000076],
            [-80.980623268999977, 34.97262382200006],
            [-80.980410268999947, 34.972453822000034],
            [-80.979654269999969, 34.971919822000075],
            [-80.978855267999961, 34.971368822000045],
            [-80.976568267999937, 34.969756821000033],
            [-80.976061267999967, 34.969385822000049],
            [-80.975956267999948, 34.969304821000037],
            [-80.975416267999947, 34.968887821000067],
            [-80.974640266999984, 34.968251821000024],
            [-80.973905266999964, 34.967643821000024],
            [-80.973429266999972, 34.967248820000066],
            [-80.972857266999938, 34.966782820000049],
            [-80.972895265999966, 34.966384821000076],
            [-80.973030265999967, 34.965636821000032],
            [-80.973357265999937, 34.964361820000079],
            [-80.973494266999978, 34.963864820000026],
            [-80.973828265999941, 34.962654820000068],
            [-80.974257265999938, 34.960866820000035],
            [-80.974646265999979, 34.959449819000042],
            [-80.974781265999979, 34.958251819000054],
            [-80.974476266999943, 34.95679381900004],
            [-80.97380226699994, 34.955353818000049],
            [-80.973128265999947, 34.953874818000031],
            [-80.972415265999985, 34.953154817000041],
            [-80.971174264999945, 34.952778817000024],
            [-80.969977264999955, 34.95296281800006],
            [-80.969147263999957, 34.953479818000062],
            [-80.969009263999965, 34.953565818000072],
            [-80.968141264999986, 34.954303818000028],
            [-80.967482264999944, 34.954806818000066],
            [-80.966070263999939, 34.956217819000074],
            [-80.964417263999962, 34.957914819000052],
            [-80.963948263999953, 34.958390819000044],
            [-80.96358526399996, 34.958758820000071],
            [-80.962774262999972, 34.959600820000048],
            [-80.962706263999962, 34.95967182000004],
            [-80.960551262999957, 34.961875820000046],
            [-80.960518261999937, 34.961910820000071],
            [-80.960483262999958, 34.961945820000039],
            [-80.960383262999983, 34.962047820000066],
            [-80.959785262999958, 34.962659820000056],
            [-80.959756262999974, 34.962632821000057],
            [-80.959599261999983, 34.96230182000005],
            [-80.959106261999978, 34.961773820000076],
            [-80.958958261999953, 34.961614820000079],
            [-80.958332261999942, 34.961286820000055],
            [-80.957844261999981, 34.961141821000069],
            [-80.957706260999942, 34.961129820000053],
            [-80.957187260999945, 34.961083820000056],
            [-80.957142260999944, 34.961073820000024],
            [-80.957035260999987, 34.961071821000075],
            [-80.956074260999969, 34.961064821000036],
            [-80.955052259999945, 34.960858820000055],
            [-80.953694260999953, 34.960583820000068],
            [-80.952777260999937, 34.960034820000033],
            [-80.951785259999951, 34.959454820000076],
            [-80.948775258999945, 34.956501820000028],
            [-80.948688258999937, 34.956416819000026],
            [-80.948077258999945, 34.955837820000056],
            [-80.946933257999945, 34.954982819000065],
            [-80.945438257999967, 34.954295820000027],
            [-80.943759256999954, 34.953464819000033],
            [-80.942233255999952, 34.953014819000032],
            [-80.941780256999948, 34.952937819000056],
            [-80.941302255999972, 34.952861819000077],
            [-80.941285255999958, 34.952861819000077],
            [-80.940828255999975, 34.952834819000032],
            [-80.940449255999965, 34.952836818000037],
            [-80.940097255999945, 34.95286081900008],
            [-80.939879255999983, 34.952887819000068],
            [-80.939660255999968, 34.952908819000072],
            [-80.93926225599995, 34.952914819000057],
            [-80.938815255999941, 34.952975819000073],
            [-80.93799325599997, 34.953171819000033],
            [-80.93791525499995, 34.953189819000045],
            [-80.937595254999962, 34.953225819000068],
            [-80.936740254999961, 34.953322820000039],
            [-80.935886254999957, 34.95342081900003],
            [-80.935834254999975, 34.953401819000078],
            [-80.934573253999986, 34.953304819000039],
            [-80.932940253999959, 34.952968820000024],
            [-80.93182225299995, 34.95261682000006],
            [-80.929907251999964, 34.952014820000045],
            [-80.928160251999941, 34.951464820000069],
            [-80.927675251999972, 34.951312819000066],
            [-80.927251251999962, 34.951209819000042],
            [-80.92613425199994, 34.950938820000033],
            [-80.924486250999962, 34.950671818000046],
            [-80.923081250999985, 34.950568819000068],
            [-80.922396250999952, 34.950519819000078],
            [-80.922139249999987, 34.950508820000039],
            [-80.921655250999947, 34.950487820000035],
            [-80.921171249999986, 34.950467819000039],
            [-80.920244249999939, 34.950428819000024],
            [-80.92012724999995, 34.950405819000025],
            [-80.918703248999975, 34.950131819000035],
            [-80.918643249999946, 34.950332820000028],
            [-80.918527248999965, 34.950723819000075],
            [-80.91841224999996, 34.951114819000054],
            [-80.918313248999937, 34.951103819000025],
            [-80.918171248999954, 34.951081819000024],
            [-80.916864248999957, 34.952857820000077],
            [-80.91721124999998, 34.953132820000064],
            [-80.917432248999944, 34.953298820000043],
            [-80.913241247999963, 34.962145822000025],
            [-80.910867247999988, 34.967158823000034],
            [-80.91079624799994, 34.967059823000056],
            [-80.910639246999949, 34.966866823000032],
            [-80.91042824699997, 34.966651823000063],
            [-80.910406246999969, 34.966632823000054],
            [-80.910171246999937, 34.966423823000071],
            [-80.909863246999976, 34.966205823000053],
            [-80.909594246999973, 34.966042823000066],
            [-80.909345246999976, 34.965912823000053],
            [-80.909001246999935, 34.965766823000024],
            [-80.908677246999957, 34.965659823000067],
            [-80.908309246999977, 34.965559823000035],
            [-80.907908246999966, 34.965482823000059],
            [-80.907583246999934, 34.965443823000044],
            [-80.906804246999968, 34.965319823000073],
            [-80.906446246999963, 34.965233823000062],
            [-80.906065245999969, 34.965120823000063],
            [-80.905493245999935, 34.96490882300003],
            [-80.905089245999989, 34.964701823000041],
            [-80.904748246999986, 34.96449982200005],
            [-80.904210245999934, 34.964105823000068],
            [-80.903742245999979, 34.963739823000026],
            [-80.903411245999962, 34.963494823000076],
            [-80.901857244999974, 34.96227882200003],
            [-80.900624243999971, 34.96133582300007],
            [-80.900331243999972, 34.96111082200008],
            [-80.900131243999965, 34.960958823000055],
            [-80.89994924399997, 34.960812822000037],
            [-80.899385244999962, 34.960418823000055],
            [-80.898708242999987, 34.959923822000064],
            [-80.898387243999935, 34.95972282200006],
            [-80.898098243999982, 34.959541823000052],
            [-80.897287243999983, 34.959042822000072],
            [-80.89644924299995, 34.958519821000039],
            [-80.895758242999989, 34.958108821000053],
            [-80.895359241999984, 34.957905823000033],
            [-80.89514724299994, 34.95775382100004],
            [-80.894264242999952, 34.957332822000069],
            [-80.893825242999981, 34.957189821000043],
            [-80.893381241999975, 34.957081822000077],
            [-80.892898241999944, 34.956990821000034],
            [-80.89213724199999, 34.956923822000078],
            [-80.890897241999937, 34.956839822000063],
            [-80.890259241999956, 34.956845822000048],
            [-80.88961723999995, 34.956878821000032],
            [-80.887978240999985, 34.956982822000043],
            [-80.887511239999981, 34.956996822000065],
            [-80.887358239999969, 34.957000822000055],
            [-80.886818240999958, 34.956970822000073],
            [-80.886366239999973, 34.956911821000062],
            [-80.886016239999947, 34.956856822000077],
            [-80.885304239999982, 34.956669822000038],
            [-80.88499823899997, 34.956571822000058],
            [-80.884580239999934, 34.956452822000074],
            [-80.883773238999936, 34.956147822000048],
            [-80.883481238999934, 34.956045822000078],
            [-80.882838239999955, 34.955792822000035],
            [-80.88225923899995, 34.955525822000027],
            [-80.87986923699998, 34.954280822000044],
            [-80.879192236999984, 34.953959821000069],
            [-80.87887823799997, 34.953864822000071],
            [-80.87770123699994, 34.953587822000031],
            [-80.876505236999947, 34.953403821000052],
            [-80.874239235999937, 34.95300282200003],
            [-80.873864235999974, 34.952941821000024],
            [-80.873328235999963, 34.952846822000026],
            [-80.872908235999944, 34.952751821000049],
            [-80.872447234999981, 34.952606821000074],
            [-80.871997234999981, 34.95242182100003],
            [-80.871406235999984, 34.95212482200003],
            [-80.870859234999955, 34.951858822000077],
            [-80.86873323399999, 34.95078482100007],
            [-80.868760234999968, 34.95064182200008],
            [-80.868833234999954, 34.950509821000026],
            [-80.869040234999943, 34.950317821000056],
            [-80.869340234999981, 34.950086820000024],
            [-80.869607235999979, 34.949838821000071],
            [-80.869767234999983, 34.949624821000043],
            [-80.869894234999947, 34.94940482100003],
            [-80.86998723499994, 34.949157821000028],
            [-80.870054234999941, 34.948799820000033],
            [-80.870140234999951, 34.948156820000065],
            [-80.870160234999958, 34.947689820000051],
            [-80.870145234999939, 34.947362820000023],
            [-80.869959234999953, 34.947288821000029],
            [-80.86997923499996, 34.947608821000074],
            [-80.869973234999975, 34.947730820000061],
            [-80.869976234999967, 34.947846820000052],
            [-80.869957234999958, 34.948145821000026],
            [-80.869866233999971, 34.948785821000058],
            [-80.869807234999939, 34.949030822000054],
            [-80.869776234999961, 34.94913482000004],
            [-80.869699234999985, 34.949339821000024],
            [-80.86964523499995, 34.949453821000077],
            [-80.869394233999969, 34.949634821000075],
            [-80.869125234999956, 34.949890821000054],
            [-80.869051233999983, 34.949967822000076],
            [-80.868928233999952, 34.950106821000077],
            [-80.868897233999974, 34.950149820000036],
            [-80.868739234999964, 34.950401821000071],
            [-80.868593234999935, 34.950717821000069],
            [-80.86858323499996, 34.950773821000041],
            [-80.86858323499996, 34.950789821000058],
            [-80.868607233999967, 34.950984821000077],
            [-80.868655233999959, 34.951149821000058],
            [-80.868834233999962, 34.951455821000025],
            [-80.868853233999971, 34.951475821000031],
            [-80.86886823399999, 34.95149882100003],
            [-80.868994234999946, 34.951645822000046],
            [-80.869045234999987, 34.951739821000047],
            [-80.869091234999985, 34.951807821000045],
            [-80.869160233999935, 34.95189182200005],
            [-80.869272234999983, 34.952008822000039],
            [-80.869332234999945, 34.952061821000029],
            [-80.869476234999979, 34.952178821000075],
            [-80.869874234999941, 34.95244282200008],
            [-80.869905234999976, 34.952459821000048],
            [-80.870721233999973, 34.952992821000066],
            [-80.870908235999934, 34.953109821000055],
            [-80.871432234999986, 34.953371821000076],
            [-80.87143823599996, 34.953375822000055],
            [-80.871851235999941, 34.953631822000034],
            [-80.872217235999983, 34.953941822000047],
            [-80.872335235999969, 34.954046822000066],
            [-80.872392235999939, 34.954106822000028],
            [-80.872640235999938, 34.954367822000052],
            [-80.872840235999945, 34.954603822000024],
            [-80.872930235999945, 34.954725822000057],
            [-80.873271235999937, 34.955225822000045],
            [-80.873445235999952, 34.955473822000044],
            [-80.873498235999989, 34.955571822000024],
            [-80.873609235999936, 34.95588082200004],
            [-80.873642236999956, 34.955983822000064],
            [-80.873692235999954, 34.956082822000042],
            [-80.873886236999965, 34.956704822000063],
            [-80.873944236999989, 34.957022823000045],
            [-80.873943236999935, 34.957271822000052],
            [-80.873942235999948, 34.957280823000076],
            [-80.873938235999958, 34.957342822000044],
            [-80.873923236999985, 34.957412822000038],
            [-80.873871236999946, 34.957586823000042],
            [-80.873825236999949, 34.957760822000068],
            [-80.873780236999949, 34.95789882300005],
            [-80.873698235999939, 34.95810182200006],
            [-80.873581236999939, 34.958331823000037],
            [-80.873535235999952, 34.95840882300007],
            [-80.873375235999958, 34.958685823000053],
            [-80.873215235999965, 34.958898824000073],
            [-80.872893235999982, 34.959280823000029],
            [-80.872791235999955, 34.959438823000028],
            [-80.872737236999967, 34.959573823000028],
            [-80.872728235999944, 34.959608823000053],
            [-80.872713235999981, 34.959750823000036],
            [-80.872696235999967, 34.960333823000042],
            [-80.872693236999964, 34.960369823000065],
            [-80.872696235999967, 34.960654824000073],
            [-80.87268723599999, 34.960974823000072],
            [-80.872668236999971, 34.961293823000062],
            [-80.872638235999943, 34.96147082400006],
            [-80.872632235999959, 34.961541823000061],
            [-80.87263523699994, 34.961755823000033],
            [-80.872669235999979, 34.961858823000057],
            [-80.872720235999964, 34.961957823000034],
            [-80.872821236999982, 34.96211582400008],
            [-80.873046235999936, 34.962403824000035],
            [-80.873048235999988, 34.962405824000029],
            [-80.873174236999944, 34.962566824000078],
            [-80.873180235999939, 34.962575824000055],
            [-80.873218235999957, 34.962628823000045],
            [-80.873413235999976, 34.96294882400008],
            [-80.873502236999968, 34.963134824000065],
            [-80.873508236999953, 34.963147824000032],
            [-80.873527236999962, 34.963216824000028],
            [-80.873568236999972, 34.963340824000056],
            [-80.873604236999938, 34.963445824000075],
            [-80.873620236999955, 34.96349282400007],
            [-80.873650235999946, 34.963595824000038],
            [-80.873673236999934, 34.963701824000054],
            [-80.873678236999979, 34.963771824000048],
            [-80.873684235999974, 34.963843824000037],
            [-80.873695236999936, 34.963882824000052],
            [-80.87370423599998, 34.963912824000033],
            [-80.87374523699998, 34.96409682400008],
            [-80.873767236999981, 34.964192824000065],
            [-80.873841236999965, 34.964579823000065],
            [-80.873891236999953, 34.964740824000046],
            [-80.873917236999944, 34.964820824000071],
            [-80.873930236999968, 34.964890824000065],
            [-80.873947236999982, 34.964953824000077],
            [-80.873949237999966, 34.964960824000059],
            [-80.873977235999973, 34.965044824000074],
            [-80.873996236999972, 34.965097824000054],
            [-80.874057235999942, 34.965191824000044],
            [-80.874139236999952, 34.965275825000049],
            [-80.874141236999947, 34.965276824000057],
            [-80.874171236999985, 34.965300824000053],
            [-80.874221236999972, 34.965331824000032],
            [-80.874307237999972, 34.965345825000043],
            [-80.87443923699999, 34.965360824000072],
            [-80.874483236999936, 34.965359824000075],
            [-80.874742236999964, 34.96531682300008],
            [-80.874783236999974, 34.965307824000035],
            [-80.874826236999979, 34.965297823000071],
            [-80.875042236999946, 34.965258824000045],
            [-80.875249236999935, 34.965200824000078],
            [-80.875372237999954, 34.965160823000076],
            [-80.875451236999936, 34.96512982400003],
            [-80.875525237999966, 34.965091824000069],
            [-80.875681236999981, 34.965024823000078],
            [-80.875823237999953, 34.964923825000028],
            [-80.876012237999987, 34.964788824000038],
            [-80.876210237999942, 34.964630824000039],
            [-80.876294237999957, 34.964563823000049],
            [-80.876445237999974, 34.964435825000066],
            [-80.876572237999937, 34.964335824000045],
            [-80.876639237999939, 34.964289824000048],
            [-80.876753237999935, 34.96423582400007],
            [-80.876825236999935, 34.964195824000058],
            [-80.876861237999947, 34.964177824000046],
            [-80.876975237999943, 34.964120824000076],
            [-80.877079236999975, 34.964057824000065],
            [-80.877147237999964, 34.964011824000067],
            [-80.877210237999975, 34.96396182400008],
            [-80.877294237999934, 34.963880823000068],
            [-80.877407237999989, 34.963728824000043],
            [-80.877470237999944, 34.963633824000055],
            [-80.877520237999988, 34.963575824000031],
            [-80.87758023799995, 34.963524823000057],
            [-80.877617237999971, 34.963505823000048],
            [-80.877711237999961, 34.963473823000072],
            [-80.877717238999935, 34.963479824000046],
            [-80.877764237999941, 34.963518824000062],
            [-80.877781236999965, 34.963530823000042],
            [-80.877819236999983, 34.963550823000048],
            [-80.877902236999944, 34.963576824000029],
            [-80.877989237999941, 34.963586823000071],
            [-80.878076236999959, 34.963580825000065],
            [-80.878140237999958, 34.963573823000047],
            [-80.878182238999955, 34.963564824000059],
            [-80.878221237999981, 34.96354982400004],
            [-80.87827823899994, 34.963525823000055],
            [-80.878295237999964, 34.963515824000069],
            [-80.878359238999963, 34.963468823000028],
            [-80.878388236999967, 34.963443824000024],
            [-80.878427237999972, 34.963401823000027],
            [-80.878481237999949, 34.963348823000047],
            [-80.878525237999952, 34.963310823000029],
            [-80.87869823799997, 34.963180824000062],
            [-80.878748237999957, 34.963148824000029],
            [-80.87878823799997, 34.963135824000062],
            [-80.878871236999942, 34.963117824000051],
            [-80.878891237999937, 34.963110824000069],
            [-80.87892323799997, 34.963088824000067],
            [-80.878952237999954, 34.963062824000076],
            [-80.878988237999977, 34.963019824000071],
            [-80.879053237999983, 34.962951824000072],
            [-80.879100238999968, 34.962915824000049],
            [-80.879134237999949, 34.962894824000045],
            [-80.879170237999972, 34.962876824000034],
            [-80.879225237999947, 34.962851823000051],
            [-80.879304237999975, 34.962822823000067],
            [-80.879342237999936, 34.962807823000048],
            [-80.879402238999944, 34.962787823000042],
            [-80.87943723799998, 34.962768823000033],
            [-80.879506238999966, 34.962726824000072],
            [-80.879589238999984, 34.962672823000048],
            [-80.879741238999941, 34.962577823000061],
            [-80.879845238999962, 34.962521824000078],
            [-80.880772238999953, 34.964879824000036],
            [-80.880739238999979, 34.964967823000052],
            [-80.880731238999942, 34.965002824000067],
            [-80.880731238999942, 34.965108824000026],
            [-80.88071623899998, 34.965251824000063],
            [-80.880697238999971, 34.96532082300007],
            [-80.880646238999987, 34.965456824000057],
            [-80.880626237999934, 34.965525825000043],
            [-80.880615238999951, 34.96559482400005],
            [-80.880614238999954, 34.965630824000073],
            [-80.880621238999936, 34.965665824000041],
            [-80.880648237999935, 34.965732824000042],
            [-80.880709238999941, 34.965825824000035],
            [-80.880860237999968, 34.965998824000053],
            [-80.880941237999934, 34.966081824000071],
            [-80.880973238999957, 34.966106824000065],
            [-80.881026238999937, 34.966161824000039],
            [-80.881468238999958, 34.96655482500006],
            [-80.88159823899997, 34.966650824000055],
            [-80.881668239999954, 34.966692824000063],
            [-80.881743238999945, 34.966729824000026],
            [-80.881792238999935, 34.96673882500005],
            [-80.881849238999962, 34.966739825000047],
            [-80.881926238999938, 34.966733825000063],
            [-80.881979239999964, 34.966740824000055],
            [-80.882014238999943, 34.966739825000047],
            [-80.882074238999962, 34.966726824000034],
            [-80.882096238999964, 34.966721824000047],
            [-80.882132238999986, 34.966703824000035],
            [-80.882160239999962, 34.966677825000033],
            [-80.882234239999946, 34.966592824000031],
            [-80.882276239999953, 34.966532824000069],
            [-80.882301239999947, 34.966398824000066],
            [-80.882303239999942, 34.966263824000066],
            [-80.882327238999949, 34.966196823000075],
            [-80.882371239999941, 34.966101823000031],
            [-80.882434239999952, 34.966012824000074],
            [-80.882502238999962, 34.965885824000054],
            [-80.882504239999946, 34.965851824000026],
            [-80.882497238999974, 34.965784824000025],
            [-80.882464238999944, 34.965584824000075],
            [-80.882479238999963, 34.96541482300006],
            [-80.88248423899995, 34.96527982300006],
            [-80.882468238999934, 34.965201824000076],
            [-80.882463239999936, 34.965181824000069],
            [-80.882456238999964, 34.965113824000071],
            [-80.88251723999997, 34.965092824000067],
            [-80.882643239999936, 34.965060824000034],
            [-80.882723238999972, 34.965030824000053],
            [-80.882853239999974, 34.965012823000052],
            [-80.882985239999982, 34.965012823000052],
            [-80.883029239999985, 34.965015824000034],
            [-80.883151238999972, 34.965055824000046],
            [-80.883330239999964, 34.965158824000071],
            [-80.88368023999999, 34.965376823000042],
            [-80.883826239999962, 34.96545582400006],
            [-80.884028239999964, 34.965592824000055],
            [-80.884204239999974, 34.965700824000066],
            [-80.884294239999974, 34.96577882400004],
            [-80.884400239999934, 34.965892824000036],
            [-80.884496240999965, 34.966012824000074],
            [-80.884536240999978, 34.966076824000027],
            [-80.884572239999954, 34.966123824000078],
            [-80.884590239999966, 34.966147824000075],
            [-80.884585239999979, 34.966155823000065],
            [-80.884533239999939, 34.966264824000064],
            [-80.884476239999969, 34.966465824000068],
            [-80.884398239999939, 34.966698825000037],
            [-80.884360239999978, 34.966862824000032],
            [-80.884243239999989, 34.967261825000037],
            [-80.884192240999937, 34.967464824000047],
            [-80.884186239999963, 34.967593825000051],
            [-80.88419523999994, 34.967637823000075],
            [-80.884231240999952, 34.967765824000026],
            [-80.884261239999944, 34.967834824000079],
            [-80.884256239999957, 34.967896825000025],
            [-80.884243239999989, 34.967975824000064],
            [-80.88423623999995, 34.968137825000042],
            [-80.88424023999994, 34.968291824000062],
            [-80.88413424099997, 34.968447824000066],
            [-80.884106239999937, 34.968474824000054],
            [-80.884012239999947, 34.968549825000025],
            [-80.883848239999963, 34.968666824000024],
            [-80.883594239999979, 34.968863825000028],
            [-80.883478238999942, 34.968970825000042],
            [-80.883263239999962, 34.96919682500004],
            [-80.883189239999979, 34.969325824000066],
            [-80.883160239999938, 34.969392824000067],
            [-80.883096240999976, 34.969597825000051],
            [-80.883081240999957, 34.969666824000058],
            [-80.882987238999988, 34.969903824000028],
            [-80.882875239999976, 34.970055825000031],
            [-80.882792238999969, 34.97013882400006],
            [-80.882518238999978, 34.970318825000049],
            [-80.882413239999948, 34.970433824000054],
            [-80.882392239999945, 34.970464826000068],
            [-80.882363238999972, 34.970568825000043],
            [-80.882358239999974, 34.970603824000079],
            [-80.882359239999971, 34.970639825000035],
            [-80.882381239999972, 34.970744825000054],
            [-80.882396239999935, 34.970788825000056],
            [-80.882416239999941, 34.970846825000024],
            [-80.882493239999974, 34.970974825000042],
            [-80.882605239999975, 34.971128825000051],
            [-80.882712239999989, 34.971240826000042],
            [-80.882759239999984, 34.971300825000071],
            [-80.882787239999971, 34.971328825000057],
            [-80.882913239999937, 34.971516826000027],
            [-80.883021239999948, 34.971628825000039],
            [-80.883063239999956, 34.97169182500005],
            [-80.883130239999957, 34.971861825000076],
            [-80.883177239999952, 34.971941826000034],
            [-80.88329324099999, 34.971895825000047],
            [-80.883341239999936, 34.972027825000055],
            [-80.883441239999968, 34.972175826000068],
            [-80.88353423999996, 34.972324825000044],
            [-80.883654239999942, 34.972500825000054],
            [-80.88390124099999, 34.972808825000072],
            [-80.883948240999985, 34.972901826000054],
            [-80.884008239999957, 34.97300582500003],
            [-80.884062239999935, 34.973088825000048],
            [-80.884075240999948, 34.973236825000072],
            [-80.88404823999997, 34.973418826000056],
            [-80.883887239999979, 34.973698826000032],
            [-80.883821240999964, 34.97383082500005],
            [-80.883827240999949, 34.973989826000036],
            [-80.88389424099995, 34.974116826000056],
            [-80.884415240999942, 34.974375825000038],
            [-80.88453524099998, 34.974490825000032],
            [-80.884595240999943, 34.974605826000072],
            [-80.884601240999984, 34.974737825000034],
            [-80.884488239999939, 34.974809826000069],
            [-80.884348240999941, 34.974875825000026],
            [-80.884234239999955, 34.975084826000057],
            [-80.884207239999967, 34.975183826000034],
            [-80.884227239999973, 34.975276826000027],
            [-80.884274239999968, 34.975359827000034],
            [-80.884434240999951, 34.975551827000061],
            [-80.884527239999954, 34.975633826000035],
            [-80.884621240999934, 34.975699826000039],
            [-80.884774240999946, 34.975777826000069],
            [-80.884915241999977, 34.975837826000031],
            [-80.885409240999934, 34.976030826000056],
            [-80.885716239999965, 34.976206826000066],
            [-80.885909240999979, 34.976382826000076],
            [-80.886009240999954, 34.976563826000074],
            [-80.886056240999949, 34.976712826000039],
            [-80.886043240999982, 34.976975827000047],
            [-80.885982240999965, 34.977173826000069],
            [-80.885989240999947, 34.977261827000063],
            [-80.886042241999974, 34.977393826000025],
            [-80.886089240999979, 34.977476827000032],
            [-80.88615624199997, 34.977663826000025],
            [-80.886216240999943, 34.977910827000073],
            [-80.886202240999978, 34.978047827000069],
            [-80.886189240999954, 34.97813082700003],
            [-80.886162240999965, 34.978218827000035],
            [-80.886062240999934, 34.97868582600006],
            [-80.886081240999943, 34.978823827000042],
            [-80.886121240999955, 34.978927827000064],
            [-80.88616824099995, 34.979015827000069],
            [-80.886342240999966, 34.979290827000057],
            [-80.886368241999946, 34.979427827000052],
            [-80.886455240999965, 34.979592826000044],
            [-80.886542240999972, 34.979746827000042],
            [-80.886608240999976, 34.980076827000062],
            [-80.886635241999954, 34.980236826000066],
            [-80.886635240999965, 34.980439827000055],
            [-80.886615240999959, 34.980554827000049],
            [-80.886615240999959, 34.980637827000066],
            [-80.886641241999939, 34.980730827000059],
            [-80.88671524099999, 34.980912827000054],
            [-80.88676824199996, 34.981016827000076],
            [-80.886901240999975, 34.98136882700004],
            [-80.886961241999984, 34.981506827000032],
            [-80.88698124199999, 34.981605827000067],
            [-80.88700824099999, 34.981692827000074],
            [-80.887248241999941, 34.982000828000025],
            [-80.887322240999936, 34.982105828000044],
            [-80.887435241999981, 34.982292828000027],
            [-80.887535241999956, 34.982495828000026],
            [-80.887582241999951, 34.982572828000059],
            [-80.887802242999953, 34.982836827000028],
            [-80.887902241999939, 34.982974827000078],
            [-80.88796924199994, 34.983084827000027],
            [-80.888022241999977, 34.983155828000065],
            [-80.888089241999978, 34.983315828000059],
            [-80.888162241999964, 34.983430828000053],
            [-80.888162241999964, 34.983568828000045],
            [-80.888109242999974, 34.983699828000056],
            [-80.88806224199999, 34.983787827000072],
            [-80.887935241999969, 34.983980827000039],
            [-80.887775241999975, 34.984161828000026],
            [-80.887674240999957, 34.984337827000047],
            [-80.887648240999965, 34.984441828000058],
            [-80.887694241999952, 34.984601828000052],
            [-80.887768241999936, 34.984678828000028],
            [-80.888001241999973, 34.984711828000059],
            [-80.888249242999962, 34.984662828000069],
            [-80.888442242999986, 34.984579828000051],
            [-80.888556241999936, 34.984535828000048],
            [-80.888723241999969, 34.984541828000033],
            [-80.888823241999944, 34.984552828000062],
            [-80.888910242999941, 34.984629827000049],
            [-80.888990242999967, 34.984739828000045],
            [-80.889377241999966, 34.985179828000071],
            [-80.889261242999964, 34.985212827000055],
            [-80.889278241999989, 34.985273828000061],
            [-80.889276242999983, 34.985308828000029],
            [-80.88925524199999, 34.98533782800007],
            [-80.88910524299996, 34.985412828000051],
            [-80.889102242999968, 34.985413828000048],
            [-80.88903424199998, 34.985454828000059],
            [-80.888924241999973, 34.985512828000026],
            [-80.888853242999971, 34.985555828000031],
            [-80.888787241999978, 34.985602828000026],
            [-80.888760241999989, 34.985630828000069],
            [-80.888747242999955, 34.98566482800004],
            [-80.888734242999988, 34.985735828000031],
            [-80.888722241999972, 34.985841828000048],
            [-80.888769241999967, 34.98612982700007],
            [-80.888775242999941, 34.986160829000028],
            [-80.888809242999969, 34.986299828000028],
            [-80.888817241999959, 34.986430829000028],
            [-80.888820241999952, 34.986476827000047],
            [-80.888815242999954, 34.986547828000027],
            [-80.888797242999942, 34.986616828000024],
            [-80.888738242999978, 34.986750828000027],
            [-80.888673241999982, 34.986957828000072],
            [-80.888656241999968, 34.987027828000066],
            [-80.888639241999954, 34.987157828000079],
            [-80.888638241999956, 34.987168828000051],
            [-80.888638241999956, 34.987275828000065],
            [-80.888627241999984, 34.987346828000057],
            [-80.888582242999973, 34.987385829000061],
            [-80.888555242999985, 34.987414828000055],
            [-80.888535242999978, 34.98744582900008],
            [-80.888508241999943, 34.987549828000056],
            [-80.888452241999971, 34.987684829000045],
            [-80.88836224299996, 34.98783682800007],
            [-80.888358241999981, 34.98784482800005],
            [-80.888314241999979, 34.987906829000053],
            [-80.888256242999944, 34.987959828000044],
            [-80.888160241999969, 34.988033828000027],
            [-80.888089242999968, 34.988074829000027],
            [-80.88801424199994, 34.988111829000047],
            [-80.887972241999989, 34.988122829000076],
            [-80.887886242999969, 34.988137828000049],
            [-80.887711241999966, 34.988126829000066],
            [-80.887456241999985, 34.988075829000024],
            [-80.887415241999975, 34.988063828000065],
            [-80.887244241999952, 34.988031829000079],
            [-80.887203241999941, 34.988018829000055],
            [-80.887118241999985, 34.988000828000054],
            [-80.887031241999978, 34.987987829000076],
            [-80.886987240999986, 34.987986828000032],
            [-80.886860241999955, 34.988015829000062],
            [-80.886792241999956, 34.988042829000051],
            [-80.886663241999941, 34.988095828000041],
            [-80.886502241999949, 34.988150829000062],
            [-80.886460241999941, 34.988161829000035],
            [-80.88626224099994, 34.988238829000068],
            [-80.886027241999955, 34.988351828000077],
            [-80.885843241999964, 34.988450829000044],
            [-80.885839240999985, 34.988453829000036],
            [-80.885839240999985, 34.988454829000034],
            [-80.88578824199999, 34.988505829000076],
            [-80.885728240999981, 34.98857182900008],
            [-80.885710241999959, 34.988592829000027],
            [-80.88564624199995, 34.988685829000076],
            [-80.885596241999963, 34.988783828000066],
            [-80.885583240999949, 34.988817828000037],
            [-80.885547240999983, 34.98895582800003],
            [-80.885528241999964, 34.989008829000056],
            [-80.885489240999959, 34.989126829000043],
            [-80.885479241999974, 34.989163829000063],
            [-80.885412240999983, 34.989439829000048],
            [-80.88537624199995, 34.989635829000065],
            [-80.885374241999955, 34.989650829000027],
            [-80.885366240999986, 34.989756828000054],
            [-80.885362241999985, 34.989899829000024],
            [-80.885395241999959, 34.990146829000025],
            [-80.885399241999949, 34.990217829000073],
            [-80.885584240999947, 34.990804829000069],
            [-80.885591241999975, 34.990839829000038],
            [-80.885600240999963, 34.991053830000055],
            [-80.885606240999948, 34.991088829000034],
            [-80.88566124099998, 34.991260830000044],
            [-80.885753242999954, 34.991422829000044],
            [-80.885794240999985, 34.991485830000045],
            [-80.885798241999964, 34.991490829000043],
            [-80.885846241999957, 34.991542830000071],
            [-80.885876240999949, 34.991569829000071],
            [-80.886014241999987, 34.991655829000024],
            [-80.886053241999946, 34.99167183000003],
            [-80.886176241999976, 34.991709830000048],
            [-80.886563241999966, 34.991818830000057],
            [-80.886716240999988, 34.991861829000072],
            [-80.88675624199999, 34.991875829000037],
            [-80.886881241999959, 34.991908829000067],
            [-80.887096241999984, 34.991941829000041],
            [-80.887446241999953, 34.991958829000055],
            [-80.887755242999958, 34.99195282900007],
            [-80.888453241999969, 34.991895830000033],
            [-80.888668241999937, 34.991861829000072],
            [-80.888752242999942, 34.991841829000066],
            [-80.888795242999947, 34.991835829000024],
            [-80.888878241999976, 34.991811830000074],
            [-80.889066243999935, 34.991745829000024],
            [-80.889080242999967, 34.991741829000034],
            [-80.889275242999986, 34.991661829000066],
            [-80.889356242999952, 34.991632829000025],
            [-80.889567242999988, 34.991582830000027],
            [-80.889772243999971, 34.991519830000072],
            [-80.889868242999967, 34.991478829000073],
            [-80.88994224399994, 34.991447830000027],
            [-80.890006242999959, 34.991421829000046],
            [-80.890167242999951, 34.991360830000076],
            [-80.890243242999986, 34.991332829000044],
            [-80.890287242999989, 34.991317829000025],
            [-80.890418242999942, 34.991274829000076],
            [-80.890491243999975, 34.991251830000067],
            [-80.890758243999983, 34.991129829000045],
            [-80.890794243999949, 34.991108829000041],
            [-80.890824243999987, 34.99108282900005],
            [-80.890893242999937, 34.991004829000076],
            [-80.89090224399996, 34.990995829000042],
            [-80.890924242999972, 34.990964829000063],
            [-80.891005242999938, 34.990937829000075],
            [-80.891048242999943, 34.990915829000073],
            [-80.891179242999954, 34.990827829000068],
            [-80.891304242999979, 34.990703830000029],
            [-80.89133424299996, 34.990676829000051],
            [-80.89137124399997, 34.990657829000043],
            [-80.891535242999964, 34.990606829000058],
            [-80.891708242999982, 34.990578829000071],
            [-80.891883242999938, 34.990561829000058],
            [-80.892103243999941, 34.99056082900006],
            [-80.892234243999951, 34.990578829000071],
            [-80.892272243999969, 34.990593829000034],
            [-80.892304243999945, 34.990618829000027],
            [-80.892416243999946, 34.990728828000044],
            [-80.892486242999951, 34.990859829000044],
            [-80.892545243999962, 34.990993829000047],
            [-80.892616243999953, 34.991124829000057],
            [-80.892746243999966, 34.991267829000037],
            [-80.892794243999958, 34.991326829000059],
            [-80.892904243999965, 34.991437829000063],
            [-80.892924243999971, 34.991469829000039],
            [-80.89299624399996, 34.991637829000069],
            [-80.893014243999971, 34.991669829000045],
            [-80.893055243999981, 34.991771829000072],
            [-80.893065243999956, 34.991806829000041],
            [-80.893147243999977, 34.992008830000032],
            [-80.893273243999943, 34.992196829000079],
            [-80.89355924399996, 34.992597829000033],
            [-80.893597243999977, 34.992661829000042],
            [-80.893683243999988, 34.992786830000057],
            [-80.893755243999976, 34.99287582900007],
            [-80.893860243999939, 34.992990829000064],
            [-80.893933244999971, 34.993079830000056],
            [-80.894019244999981, 34.993204829000035],
            [-80.894073243999969, 34.993301829000075],
            [-80.894138244999965, 34.993434830000069],
            [-80.894147243999953, 34.993469830000038],
            [-80.894154244999982, 34.993569830000069],
            [-80.894084243999941, 34.994955830000038],
            [-80.894069243999979, 34.994989830000065],
            [-80.894037244999936, 34.995129830000053],
            [-80.893910243999983, 34.995395830000064],
            [-80.893890243999977, 34.995426831000032],
            [-80.893857244999936, 34.995492830000046],
            [-80.893790243999945, 34.995699830000035],
            [-80.893730244999972, 34.99583283000004],
            [-80.893710244999966, 34.995864830000073],
            [-80.893663243999981, 34.995924830000035],
            [-80.893534244999955, 34.996020830000077],
            [-80.893443243999968, 34.996097830000053],
            [-80.893419244999961, 34.996126831000026],
            [-80.89336024499994, 34.996177830000079],
            [-80.893238243999974, 34.996367829000064],
            [-80.893182244999934, 34.996464830000036],
            [-80.893132243999958, 34.996600830000034],
            [-80.893116243999941, 34.996706830000051],
            [-80.893118244999982, 34.996742830000073],
            [-80.893129243999965, 34.996777830000042],
            [-80.893286243999967, 34.997071831000028],
            [-80.893318244999989, 34.997138831000029],
            [-80.893387243999939, 34.997381830000052],
            [-80.89339724499996, 34.997452830000043],
            [-80.893392244999973, 34.997523830000034],
            [-80.893331243999967, 34.997731830000077],
            [-80.893314244999942, 34.99776483100004],
            [-80.893271244999937, 34.997826830000065],
            [-80.893245243999957, 34.997894830000064],
            [-80.893214243999978, 34.997960831000057],
            [-80.893210243999988, 34.99799683100008],
            [-80.89321724399997, 34.998031831000048],
            [-80.893244243999959, 34.998099830000058],
            [-80.893284243999972, 34.998162830000069],
            [-80.893423244999951, 34.998280831000045],
            [-80.893527243999984, 34.998406831000068],
            [-80.893732244999967, 34.998592830000064],
            [-80.894027244999961, 34.998880830000076],
            [-80.894217245999982, 34.999077831000079],
            [-80.894772244999956, 34.999677831000042],
            [-80.894945243999985, 34.999884831000031],
            [-80.895059243999981, 35.000046832000066],
            [-80.895123244999979, 35.000161831000071],
            [-80.895192245999965, 35.000336831000027],
            [-80.895243244999961, 35.000482831000056],
            [-80.895343244999935, 35.000586831000078],
            [-80.895413244999986, 35.000647831000038],
            [-80.895543245999988, 35.000725831000068],
            [-80.895660245999977, 35.000812831000076],
            [-80.895692245999953, 35.000831831000028],
            [-80.895751245999975, 35.00087583100003],
            [-80.895825245999958, 35.000953831000061],
            [-80.895903245999989, 35.001027831000044],
            [-80.89598624499996, 35.001097832000028],
            [-80.896112244999983, 35.001225831000056],
            [-80.89615824599997, 35.001262831000076],
            [-80.89632824499995, 35.00138983100004],
            [-80.896444244999941, 35.001485832000071],
            [-80.89645524599996, 35.00149483000007],
            [-80.896580244999939, 35.001601831000073],
            [-80.896657245999961, 35.001673831000062],
            [-80.896933244999957, 35.001887831000033],
            [-80.897103245999972, 35.002012831000059],
            [-80.897196245999965, 35.002092831000027],
            [-80.897258246999968, 35.002142831000072],
            [-80.897407244999954, 35.002231831000074],
            [-80.89770124599994, 35.002407831000028],
            [-80.897909244999937, 35.002542831000028],
            [-80.897968245999948, 35.002578831000051],
            [-80.898012245999951, 35.002606831000037],
            [-80.898107246999984, 35.002660832000061],
            [-80.898311246999981, 35.002794832000063],
            [-80.898673245999987, 35.003021831000069],
            [-80.898973246999958, 35.00322383200006],
            [-80.899024245999954, 35.003263832000073],
            [-80.899547245999941, 35.00360283200007],
            [-80.899679246999938, 35.003702831000055],
            [-80.89974624599995, 35.003748832000042],
            [-80.899994246999938, 35.003950831000054],
            [-80.900084246999938, 35.004018832000042],
            [-80.900154246999989, 35.00407283100003],
            [-80.900220246999936, 35.004118832000074],
            [-80.900726246999966, 35.004512832000046],
            [-80.901084245999982, 35.004775832000064],
            [-80.901436246999936, 35.004988832000038],
            [-80.901687247999973, 35.005130832000077],
            [-80.901757246999978, 35.005180831000075],
            [-80.901786246999961, 35.005200832000071],
            [-80.901814246999947, 35.005228832000057],
            [-80.901879247999943, 35.005275831000063],
            [-80.902033247999952, 35.005401831000029],
            [-80.902164246999973, 35.005544832000055],
            [-80.902239247999944, 35.005654832000062],
            [-80.902262247999943, 35.005684832000043],
            [-80.902299246999974, 35.005748832000052],
            [-80.902331247999939, 35.005851832000076],
            [-80.902340247999973, 35.005896832000076],
            [-80.902366247999964, 35.006026832000032],
            [-80.902368247999959, 35.006167832000074],
            [-80.902374247999944, 35.006237832000068],
            [-80.902369247999957, 35.006308832000059],
            [-80.902324247999957, 35.006591832000026],
            [-80.902295246999984, 35.006695833000038],
            [-80.902266247999989, 35.006762832000049],
            [-80.902209246999973, 35.006858832000034],
            [-80.902186246999975, 35.006888832000072],
            [-80.902081246999956, 35.007001832000071],
            [-80.902008246999969, 35.007089832000077],
            [-80.90183824799999, 35.00725383200006],
            [-80.901704247999987, 35.007345832000055],
            [-80.901629247999949, 35.007382832000076],
            [-80.901473247999945, 35.007449832000077],
            [-80.901393247999977, 35.007477832000063],
            [-80.901310246999969, 35.007501832000059],
            [-80.901181245999965, 35.007522832000063],
            [-80.901137247999941, 35.007524832000058],
            [-80.901050246999944, 35.00753583200003],
            [-80.900961247999987, 35.007536833000074],
            [-80.900944247999973, 35.007535833000077],
            [-80.900786247999974, 35.007529832000046],
            [-80.900479246999964, 35.007523832000061],
            [-80.900083246999941, 35.00754683200006],
            [-80.899953245999939, 35.007561832000079],
            [-80.899786246999952, 35.007607832000076],
            [-80.89962524699996, 35.007664833000035],
            [-80.899528246999978, 35.007737832000032],
            [-80.899469246999956, 35.007832832000076],
            [-80.899455246999935, 35.007866833000037],
            [-80.899446245999968, 35.007937833000028],
            [-80.899442246999968, 35.008079832000078],
            [-80.899457246999987, 35.008149832000072],
            [-80.899477246999936, 35.008289833000049],
            [-80.899497246999942, 35.008359833000043],
            [-80.899511246999964, 35.008393833000071],
            [-80.899589245999948, 35.008520832000045],
            [-80.899634246999938, 35.008581833000051],
            [-80.899686245999987, 35.008638833000077],
            [-80.899859245999949, 35.008845832000077],
            [-80.900073246999966, 35.009070833000067],
            [-80.900122247999946, 35.009129833000031],
            [-80.900290246999987, 35.00938083300008],
            [-80.900361247999967, 35.009509832000049],
            [-80.900412247999952, 35.009646832000044],
            [-80.900422246999938, 35.009717833000025],
            [-80.900428246999979, 35.009858832000077],
            [-80.900424246999989, 35.009894833000033],
            [-80.900392246999957, 35.010034832000031],
            [-80.90037624699994, 35.010067833000051],
            [-80.900166247999948, 35.010420833000069],
            [-80.900003246999972, 35.010670833000063],
            [-80.899995246999936, 35.010682832000043],
            [-80.899940246999961, 35.010745833000044],
            [-80.899829247999946, 35.010875833000057],
            [-80.899602246999962, 35.011137833000078],
            [-80.89939224699998, 35.01136583300007],
            [-80.899374246999969, 35.011398833000044],
            [-80.899362246999942, 35.011427833000027],
            [-80.899315246999947, 35.011532834000036],
            [-80.899235246999979, 35.011574833000054],
            [-80.898611246999963, 35.01202683300005],
            [-80.898364246999961, 35.012176833000069],
            [-80.898097247999942, 35.012361833000057],
            [-80.897999246999973, 35.012433833000046],
            [-80.897906245999934, 35.012508834000073],
            [-80.897847245999969, 35.012561833000063],
            [-80.89783224599995, 35.012572833000036],
            [-80.89783224599995, 35.012573833000033],
            [-80.897815246999983, 35.012586834000047],
            [-80.897728246999975, 35.012666833000026],
            [-80.897619245999977, 35.012776834000078],
            [-80.897560245999955, 35.012829833000069],
            [-80.89755824599996, 35.012830833000066],
            [-80.897433246999981, 35.012927833000049],
            [-80.897307245999968, 35.013026834000073],
            [-80.897185245999935, 35.013128834000042],
            [-80.89706924699999, 35.013233833000072],
            [-80.896953245999953, 35.013341833000027],
            [-80.896865246999937, 35.013460834000057],
            [-80.89685224599998, 35.013494833000038],
            [-80.896870245999935, 35.013671833000046],
            [-80.89690524599996, 35.013736834000042],
            [-80.896928246999948, 35.013767834000078],
            [-80.896984245999988, 35.013822833000063],
            [-80.897078245999978, 35.013896834000036],
            [-80.897191245999977, 35.013952834000065],
            [-80.897523246999981, 35.014081834000024],
            [-80.897747246999984, 35.014168834000031],
            [-80.898201246999975, 35.014306833000035],
            [-80.898369246999948, 35.014347834000034],
            [-80.898497246999966, 35.014373834000025],
            [-80.898757246999935, 35.014418834000026],
            [-80.898974246999956, 35.014447834000066],
            [-80.899150246999966, 35.014455834000046],
            [-80.899282246999974, 35.014452834000053],
            [-80.899325246999979, 35.014446834000069],
            [-80.899410246999935, 35.014426833000073],
            [-80.899530246999973, 35.014380834000065],
            [-80.899835246999942, 35.014179834000061],
            [-80.900029246999964, 35.014036833000034],
            [-80.900638246999961, 35.013528833000066],
            [-80.900701246999972, 35.013477833000024],
            [-80.900766246999979, 35.013430833000029],
            [-80.901110246999963, 35.013157833000037],
            [-80.901370247999978, 35.012965833000067],
            [-80.901399247999962, 35.012939833000075],
            [-80.901517246999958, 35.012786833000064],
            [-80.901558246999969, 35.01277383300004],
            [-80.901577246999977, 35.012764832000073],
            [-80.90175624799997, 35.012688833000027],
            [-80.901792247999936, 35.012674833000062],
            [-80.90181324699995, 35.012659833000043],
            [-80.902086247999989, 35.012460833000034],
            [-80.902271248999966, 35.012310833000072],
            [-80.902659247999964, 35.01192483300008],
            [-80.902674247999983, 35.011907832000077],
            [-80.90322724899994, 35.011470833000033],
            [-80.903514247999965, 35.011515834000079],
            [-80.903945248999946, 35.011398833000044],
            [-80.90405624899995, 35.01134383200008],
            [-80.904531248999945, 35.011225832000036],
            [-80.904707248999955, 35.011044834000074],
            [-80.904851247999943, 35.01109983300006],
            [-80.904895247999946, 35.011244832000045],
            [-80.904917247999947, 35.011488833000044],
            [-80.904961248999939, 35.011759833000042],
            [-80.905050247999952, 35.01198583300004],
            [-80.905282247999935, 35.012229833000049],
            [-80.905580248999968, 35.012410834000036],
            [-80.905922247999968, 35.012510832000032],
            [-80.906276248999973, 35.012582833000067],
            [-80.906376248999948, 35.012593833000039],
            [-80.906618249999951, 35.012746833000051],
            [-80.906713248999949, 35.012820833000035],
            [-80.906821248999961, 35.012932833000036],
            [-80.906888248999962, 35.013024833000031],
            [-80.906974248999973, 35.01318583300008],
            [-80.907082248999984, 35.013384833000032],
            [-80.907252248999953, 35.013736834000042],
            [-80.907402248999972, 35.014106834000074],
            [-80.90748524899999, 35.014270833000069],
            [-80.907541248999962, 35.014366833000054],
            [-80.907682249999937, 35.014500833000056],
            [-80.90781624899995, 35.014594833000046],
            [-80.907887249999987, 35.014635834000046],
            [-80.907925248999959, 35.01465283400006],
            [-80.907966250999948, 35.014665834000027],
            [-80.908080249999955, 35.014718833000074],
            [-80.908151248999957, 35.014760834000072],
            [-80.90825424999997, 35.01482883400007],
            [-80.908284249999951, 35.014854833000072],
            [-80.908345249999968, 35.014948833000062],
            [-80.908388249999973, 35.015049834000024],
            [-80.90854324999998, 35.01563983300008],
            [-80.908558249999942, 35.015672834000043],
            [-80.908661249999966, 35.015829834000044],
            [-80.908687249999957, 35.015858834000028],
            [-80.908803249999949, 35.015965833000052],
            [-80.908924249999984, 35.016067834000069],
            [-80.90927125099995, 35.016389834000051],
            [-80.909422250999967, 35.016606833000026],
            [-80.909461249999936, 35.016669833000037],
            [-80.909510249999983, 35.016769834000058],
            [-80.909538249999969, 35.01687783400007],
            [-80.909481249999942, 35.017664834000072],
            [-80.90947424999996, 35.017684834000079],
            [-80.90947024999997, 35.017730835000066],
            [-80.909450249999963, 35.017744834000041],
            [-80.90933924999996, 35.017823834000069],
            [-80.909253250999939, 35.017903834000037],
            [-80.909228250999945, 35.017933834000075],
            [-80.909045249999963, 35.01808683400003],
            [-80.908978249999961, 35.018133835000071],
            [-80.908759250999935, 35.018250834000071],
            [-80.908721249999985, 35.018267834000028],
            [-80.908653249999986, 35.018313834000026],
            [-80.908464249999952, 35.018462834000047],
            [-80.908408249999979, 35.018517834000079],
            [-80.908385249999981, 35.01854783400006],
            [-80.908331249999947, 35.018603834000032],
            [-80.908239249999951, 35.018725834000065],
            [-80.90820524999998, 35.018790834000072],
            [-80.908126249999953, 35.018917835000025],
            [-80.908091249999984, 35.018983835000029],
            [-80.90799224999995, 35.019101835000072],
            [-80.907946249999952, 35.019130834000066],
            [-80.907906250999986, 35.019144834000031],
            [-80.907862249999937, 35.019151835000059],
            [-80.907730249999986, 35.01914783400008],
            [-80.907689249999976, 35.019135835000043],
            [-80.907358249999959, 35.018958835000035],
            [-80.907133249999958, 35.018845834000047],
            [-80.906815249999966, 35.018655834000072],
            [-80.906627248999939, 35.018562834000079],
            [-80.906549249999955, 35.018530834000046],
            [-80.90637924899994, 35.018493834000026],
            [-80.906204248999984, 35.018507835000037],
            [-80.906120249999958, 35.018529835000038],
            [-80.905889248999983, 35.018632835000062],
            [-80.905820248999987, 35.018676834000075],
            [-80.905781249999961, 35.018693834000032],
            [-80.905548248999935, 35.018854834000024],
            [-80.905510249999963, 35.018873834000033],
            [-80.905431249999936, 35.01890383500006],
            [-80.905347249999977, 35.018926834000069],
            [-80.905217249999964, 35.018942835000075],
            [-80.905090248999954, 35.018969834000075],
            [-80.904929248999963, 35.019025835000036],
            [-80.904506248999951, 35.019121834000032],
            [-80.904419247999954, 35.01913283500005],
            [-80.904244247999941, 35.019144834000031],
            [-80.904113248999977, 35.019135835000043],
            [-80.90403024799997, 35.019111834000057],
            [-80.903991248999944, 35.019094834000043],
            [-80.903922248999947, 35.019050834000041],
            [-80.903694248999955, 35.018746835000059],
            [-80.903663248999976, 35.018721834000075],
            [-80.903625247999969, 35.018702835000056],
            [-80.903583247999961, 35.01869483400003],
            [-80.903495248999945, 35.018690835000029],
            [-80.903451248999943, 35.018692834000035],
            [-80.903408248999938, 35.018702835000056],
            [-80.903330247999975, 35.01873583400004],
            [-80.903258248999975, 35.018794834000062],
            [-80.903127248999965, 35.018979835000039],
            [-80.903014247999977, 35.019248835000042],
            [-80.902973248999956, 35.019459835000077],
            [-80.902967247999982, 35.019707835000077],
            [-80.902973248999956, 35.019742835000045],
            [-80.903017247999969, 35.019843834000028],
            [-80.903051248999986, 35.019908834000034],
            [-80.903091248999942, 35.019971835000035],
            [-80.903276248999987, 35.020212835000052],
            [-80.90331324899995, 35.020276835000061],
            [-80.903415248999977, 35.020474835000073],
            [-80.903438248999976, 35.020542836000061],
            [-80.903471248999949, 35.020754835000048],
            [-80.903473247999955, 35.020825835000039],
            [-80.903458248999982, 35.021144836000076],
            [-80.903435248999983, 35.021213835000026],
            [-80.903387247999945, 35.02131283500006],
            [-80.903363248999938, 35.021342835000041],
            [-80.903020248999951, 35.021619835000024],
            [-80.902838248999956, 35.021775835000028],
            [-80.902786248999973, 35.021832835000055],
            [-80.902668247999941, 35.021981836000066],
            [-80.902629247999982, 35.022045836000075],
            [-80.902555248999988, 35.022250835000079],
            [-80.902540248999969, 35.022320835000073],
            [-80.902520248999963, 35.022497835000024],
            [-80.902466247999939, 35.022813835000079],
            [-80.902408248999961, 35.022947836000071],
            [-80.902370248999944, 35.023012835000031],
            [-80.902274247999969, 35.023131835000072],
            [-80.902114248999965, 35.023343836000038],
            [-80.901942247999955, 35.023548835000042],
            [-80.901692247999961, 35.023883836000039],
            [-80.901534247999962, 35.024139835000028],
            [-80.901449247999949, 35.024264836000043],
            [-80.901260247999971, 35.024586836000026],
            [-80.901049247999936, 35.024899836000031],
            [-80.900971247999962, 35.025026836000052],
            [-80.900882248999949, 35.025212836000037],
            [-80.900813247999963, 35.025358836000066],
            [-80.900729247999948, 35.025560836000068],
            [-80.900697247999972, 35.025626836000072],
            [-80.900656247999962, 35.025727837000034],
            [-80.900502247999952, 35.026210836000075],
            [-80.900484247999941, 35.026280836000069],
            [-80.900423247999981, 35.026882837000073],
            [-80.900384247999966, 35.027093837000052],
            [-80.900342247999959, 35.027232837000042],
            [-80.900303247999943, 35.027296836000062],
            [-80.900189246999958, 35.027447837000068],
            [-80.899938247999955, 35.027740837000067],
            [-80.899784247999946, 35.027913837000028],
            [-80.899661247999973, 35.028060837000055],
            [-80.899614247999978, 35.028111838000029],
            [-80.899476247999985, 35.028266837000047],
            [-80.899431247999985, 35.028319837000026],
            [-80.899337247999938, 35.028452837000032],
            [-80.899143247999973, 35.028795836000029],
            [-80.899081247999959, 35.028906837000079],
            [-80.898943247999966, 35.029130837000025],
            [-80.898803246999989, 35.02931183700008],
            [-80.898693246999983, 35.029465837000032],
            [-80.898679247999951, 35.02949983600007],
            [-80.898672247999968, 35.029534838000075],
            [-80.898672247999968, 35.029570837000051],
            [-80.898682247999943, 35.029640837000045],
            [-80.898692247999975, 35.029675836000024],
            [-80.898709246999942, 35.029708837000044],
            [-80.898843246999945, 35.029892837000034],
            [-80.898895247999974, 35.029990837000071],
            [-80.898921246999976, 35.03005883700007],
            [-80.898939247999976, 35.030199836000065],
            [-80.89893124799994, 35.030255838000073],
            [-80.898929247999945, 35.030270838000035],
            [-80.898912247999988, 35.03034083700004],
            [-80.898899247999964, 35.030374836000078],
            [-80.89888224699996, 35.030443837000064],
            [-80.898868246999939, 35.030477837000035],
            [-80.898843247999935, 35.030507838000062],
            [-80.898684248999984, 35.030746837000038],
            [-80.898595247999936, 35.030880837000041],
            [-80.898538247999966, 35.030975838000074],
            [-80.898443247999978, 35.031136837000076],
            [-80.898247247999961, 35.031454837000069],
            [-80.898129247999975, 35.031679838000059],
            [-80.898005246999958, 35.031921838000073],
            [-80.897933246999969, 35.032060837000074],
            [-80.897755247999953, 35.03240783800004],
            [-80.89772224799998, 35.032482838000078],
            [-80.897661246999974, 35.032627838000053],
            [-80.89763224699999, 35.032695837000063],
            [-80.897612246999984, 35.032744837000052],
            [-80.897540246999938, 35.032900838000046],
            [-80.897509246999959, 35.032968838000045],
            [-80.897500246999982, 35.032988837000062],
            [-80.89749024799994, 35.033012838000047],
            [-80.897475247999978, 35.03304883800007],
            [-80.897468246999949, 35.033069837000028],
            [-80.897455248999961, 35.033103838000045],
            [-80.897439246999966, 35.033151838000038],
            [-80.897415246999969, 35.033205838000072],
            [-80.897410246999982, 35.033218838000039],
            [-80.897402247999935, 35.03324083800004],
            [-80.897375247999946, 35.033317838000073],
            [-80.89736224699999, 35.033355838000034],
            [-80.897360246999938, 35.033390838000059],
            [-80.897368247999964, 35.033461837000061],
            [-80.897364247999974, 35.03349683700003],
            [-80.897345246999976, 35.033556838000038],
            [-80.897332247999941, 35.033600838000041],
            [-80.897325247999959, 35.033635838000066],
            [-80.897346246999973, 35.033666838000045],
            [-80.89735924699994, 35.03370183800007],
            [-80.897367247999966, 35.033743838000078],
            [-80.897387247999973, 35.033841838000058],
            [-80.897453247999977, 35.034010838000029],
            [-80.897484247999955, 35.034075838000035],
            [-80.897536246999948, 35.034181838000052],
            [-80.897565246999989, 35.034242838000068],
            [-80.89766624799995, 35.034400838000067],
            [-80.897700247999978, 35.034461839000073],
            [-80.897702247999973, 35.034465838000074],
            [-80.89773024699997, 35.034605839000051],
            [-80.897730247999959, 35.034641839000074],
            [-80.897724247999975, 35.034676838000053],
            [-80.897691247999944, 35.034779839000066],
            [-80.897516247999988, 35.035026839000068],
            [-80.897419246999959, 35.035186838000072],
            [-80.89735124799995, 35.035317838000026],
            [-80.897349247999955, 35.035331838000047],
            [-80.89734324799997, 35.035423838000042],
            [-80.897360246999938, 35.035527838000064],
            [-80.897375247999946, 35.035561838000035],
            [-80.897422246999952, 35.035639838000066],
            [-80.89743324799997, 35.035656838000079],
            [-80.897473247999983, 35.035705838000069],
            [-80.897481246999973, 35.035715839000034],
            [-80.897660247999966, 35.035869839000043],
            [-80.897662247999961, 35.035871839000038],
            [-80.89773024699997, 35.035922839000079],
            [-80.897882247999974, 35.036040839000066],
            [-80.897919247999937, 35.036071838000055],
            [-80.898003246999963, 35.036142838000046],
            [-80.898227247999955, 35.036360839000054],
            [-80.898298246999957, 35.036450839000054],
            [-80.898338247999959, 35.036513839000065],
            [-80.898388247999947, 35.03661383900004],
            [-80.898437246999947, 35.036708839000028],
            [-80.898487247999981, 35.036833839000053],
            [-80.898494247999963, 35.036909839000032],
            [-80.898507247999987, 35.037038839000047],
            [-80.898511247999977, 35.037140839000074],
            [-80.898512247999975, 35.037144839000064],
            [-80.898509247999982, 35.03721783900005],
            [-80.898508248999974, 35.037286839000046],
            [-80.898501247999945, 35.037355839000043],
            [-80.898492247999968, 35.037464838000062],
            [-80.898460247999935, 35.037567839000076],
            [-80.898377246999985, 35.03774783800003],
            [-80.898338247999959, 35.037834839000027],
            [-80.898320247999948, 35.03787483900004],
            [-80.898179247999963, 35.038204839000059],
            [-80.898146247999989, 35.038321839000048],
            [-80.898111247999964, 35.038447838000025],
            [-80.898093247999952, 35.038731839000036],
            [-80.898124246999942, 35.039087840000036],
            [-80.898140247999947, 35.039193839000063],
            [-80.898197246999985, 35.039327839000066],
            [-80.898230248999937, 35.03939383900007],
            [-80.89841324799994, 35.039678839000032],
            [-80.89841624799999, 35.039682839000079],
            [-80.898436247999939, 35.03970884000006],
            [-80.89860424799997, 35.039873839000052],
            [-80.898762248999958, 35.040016839000032],
            [-80.898780248999969, 35.040032839000048],
            [-80.898925247999955, 35.04014483900005],
            [-80.898939247999976, 35.040155839000079],
            [-80.89906224799995, 35.04025684000004],
            [-80.899324247999971, 35.040446840000072],
            [-80.899426247999941, 35.04051283900003],
            [-80.899565247999988, 35.040603839000028],
            [-80.899602247999951, 35.040622840000026],
            [-80.89983024899999, 35.040747840000051],
            [-80.899832248999985, 35.040757839000037],
            [-80.900190248999934, 35.041335839000055],
            [-80.900377248999973, 35.04183684000003],
            [-80.900424247999979, 35.041964839000059],
            [-80.900479247999954, 35.04206884000007],
            [-80.90051724999995, 35.042169840000042],
            [-80.900609248999956, 35.042365840000059],
            [-80.900653249999948, 35.042427840000073],
            [-80.900727248999942, 35.042513839000037],
            [-80.900987248999968, 35.042800840000041],
            [-80.901153248999947, 35.04300784000003],
            [-80.901348248999966, 35.043198840000059],
            [-80.901538249999987, 35.043395840000073],
            [-80.901689248999958, 35.043568840000034],
            [-80.901758248999954, 35.043658840000035],
            [-80.901897249999934, 35.043879840000045],
            [-80.901974249999967, 35.044043840000029],
            [-80.902121248999947, 35.044335841000077],
            [-80.902226249999956, 35.044529841000042],
            [-80.902682249999941, 35.045294840000054],
            [-80.902864249999936, 35.045576840000024],
            [-80.902984249999975, 35.045724840000048],
            [-80.903028249999977, 35.045785840000065],
            [-80.903143249999971, 35.045891840000024],
            [-80.903243250999935, 35.045961840000075],
            [-80.903277249999974, 35.045977840000035],
            [-80.903317249999986, 35.045997840000041],
            [-80.903512249999949, 35.046077840000066],
            [-80.903754250999953, 35.046165841000061],
            [-80.904000250999957, 35.046256840000069],
            [-80.904066249999971, 35.046280840000065],
            [-80.904227249999963, 35.046333840000045],
            [-80.904286249999984, 35.046354840000049],
            [-80.904391250999936, 35.046386840000025],
            [-80.904405249999968, 35.046390840000072],
            [-80.904757250999978, 35.046509841000045],
            [-80.904761250999968, 35.046510841000043],
            [-80.904789249999965, 35.046521840000025],
            [-80.904878249999967, 35.046555840000053],
            [-80.904960249999988, 35.046573841000054],
            [-80.905029249999984, 35.046569840000075],
            [-80.905621250999957, 35.046717840000042],
            [-80.905724250999981, 35.046736840000051],
            [-80.905888250999965, 35.046789841000077],
            [-80.905924250999988, 35.046809841000027],
            [-80.905983250999952, 35.046862841000063],
            [-80.906009250999944, 35.046930841000062],
            [-80.906014250999988, 35.046965841000031],
            [-80.906014250999988, 35.047107840000024],
            [-80.905995250999979, 35.047176841000066],
            [-80.905985250999947, 35.047221841000066],
            [-80.905965250999941, 35.047316841000054],
            [-80.905937250999955, 35.047420840000029],
            [-80.90582225199995, 35.047725841000045],
            [-80.905750249999983, 35.047893840000029],
            [-80.905672249999952, 35.048059841000054],
            [-80.905614250999975, 35.048155841000039],
            [-80.905548250999971, 35.048247841000034],
            [-80.905540250999934, 35.048256841000068],
            [-80.905483250999964, 35.048326841000062],
            [-80.905476250999982, 35.048336841000037],
            [-80.905451250999988, 35.048362841000028],
            [-80.905399249999959, 35.048418841000057],
            [-80.905238249999968, 35.048592840000026],
            [-80.905123249999974, 35.048668841000051],
            [-80.905215250999959, 35.048682841000073],
            [-80.905342250999979, 35.048675841000033],
            [-80.905456250999976, 35.048762842000031],
            [-80.905509251999945, 35.048861840000029],
            [-80.90589025099996, 35.049455841000054],
            [-80.906050250999954, 35.049648841000078],
            [-80.906130251999969, 35.04971984000008],
            [-80.906324250999944, 35.049763841000072],
            [-80.906518250999966, 35.049730841000041],
            [-80.906792250999956, 35.04971984000008],
            [-80.906939250999983, 35.049719841000069],
            [-80.907187251999972, 35.049736842000073],
            [-80.907701250999935, 35.049863841000047],
            [-80.907729251999967, 35.049863841000047],
            [-80.907799250999972, 35.049905841000054],
            [-80.907914250999966, 35.049863841000047],
            [-80.907995250999988, 35.049863841000047],
            [-80.908109250999985, 35.049852841000074],
            [-80.908142251999948, 35.049841841000045],
            [-80.908292251999967, 35.04984784100003],
            [-80.908352251999986, 35.049737841000024],
            [-80.908445250999989, 35.049570841000047],
            [-80.90883125199997, 35.049461841000038],
            [-80.908945251999967, 35.049385842000049],
            [-80.909179250999955, 35.049247841000067],
            [-80.909348251999972, 35.049116840000067],
            [-80.909616251999978, 35.049118841000052],
            [-80.909693251999954, 35.049043841000071],
            [-80.909771251999985, 35.048970841000028],
            [-80.910427251999977, 35.048926840000036],
            [-80.910490251999988, 35.048947841000029],
            [-80.91064725199999, 35.049001841000063],
            [-80.910703251999962, 35.048934841000062],
            [-80.910883252999952, 35.048896841000044],
            [-80.911010251999983, 35.048929840000028],
            [-80.911124252999969, 35.049011841000038],
            [-80.911458252999978, 35.049215840000045],
            [-80.911625252999954, 35.049325841000041],
            [-80.911792251999941, 35.04944684000003],
            [-80.911857252999937, 35.049536841000076],
            [-80.911953252999979, 35.049666841000032],
            [-80.912147252999944, 35.049765841000067],
            [-80.912334253999973, 35.049896840000031],
            [-80.912514252999983, 35.050039841000057],
            [-80.912614252999958, 35.050232841000025],
            [-80.912755252999943, 35.050386840000044],
            [-80.913109252999959, 35.050600841000062],
            [-80.913610252999945, 35.050793841000029],
            [-80.914011253999945, 35.050903842000025],
            [-80.91416325299997, 35.050930841000024],
            [-80.914325252999959, 35.050958841000067],
            [-80.914432253999962, 35.050963841000055],
            [-80.91474025399998, 35.050930840000035],
            [-80.915361253999947, 35.050733841000067],
            [-80.915535253999963, 35.050810841000043],
            [-80.915869253999972, 35.050975841000025],
            [-80.916277253999965, 35.051211841000054],
            [-80.916397253999946, 35.05128884100003],
            [-80.916538254999978, 35.051409841000066],
            [-80.916738254999984, 35.051673841000024],
            [-80.916999253999961, 35.05187684100008],
            [-80.917326254999978, 35.052173841000069],
            [-80.917894254999965, 35.052618841000026],
            [-80.91808025499995, 35.052759842000057],
            [-80.91816225499997, 35.052822841000079],
            [-80.918391253999971, 35.052913841000077],
            [-80.91826825399994, 35.053179842000077],
            [-80.91820725499997, 35.053312841000036],
            [-80.917675254999949, 35.054515842000058],
            [-80.917608253999958, 35.054666842000074],
            [-80.917178253999964, 35.055700842000078],
            [-80.916719253999986, 35.056764842000064],
            [-80.916344253999966, 35.057673842000042],
            [-80.915809253999953, 35.058864843000038],
            [-80.915500254999984, 35.05952084200004],
            [-80.914989253999977, 35.060404843000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 16,
        "SolutionID": "PI-9",
        "Shape_Length": 0.5475532533455143,
        "Shape_Area": 0.0074649896806705677
      }
    },
    {
      "type": "Feature",
      "id": 17,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.838707243999977, 35.292984895000075],
            [-80.839487244999987, 35.293009895000068],
            [-80.840108244999954, 35.293029895000075],
            [-80.840792244999989, 35.293052896000063],
            [-80.841973245999952, 35.293087895000042],
            [-80.842607244999954, 35.293108895000046],
            [-80.842797244999986, 35.29312189500007],
            [-80.842931244999988, 35.293140895000079],
            [-80.843025245999968, 35.293166896000059],
            [-80.843079245999945, 35.293187895000074],
            [-80.843276245999959, 35.293295895000028],
            [-80.843457244999968, 35.293418896000048],
            [-80.843650245999982, 35.293568895000078],
            [-80.843759244999944, 35.29366989500005],
            [-80.843851245999986, 35.293781896000041],
            [-80.843907245999958, 35.293871895000052],
            [-80.843976245999954, 35.294006896000042],
            [-80.84400724599999, 35.294077895000044],
            [-80.844337245999952, 35.29482489600008],
            [-80.844510244999981, 35.295216896000056],
            [-80.844669245999967, 35.295576895000067],
            [-80.844712245999972, 35.295698896000033],
            [-80.844733245999976, 35.295780896000053],
            [-80.844752245999985, 35.295954895000079],
            [-80.844770245999939, 35.296510896000029],
            [-80.844771245999937, 35.296824896000032],
            [-80.844775245999983, 35.297190896000075],
            [-80.844770245999939, 35.297853896000049],
            [-80.844762245999959, 35.297964896000053],
            [-80.844634245999941, 35.299852897000051],
            [-80.844616245999987, 35.300286897000035],
            [-80.84461224599994, 35.300507898000035],
            [-80.844576246999964, 35.301290897000058],
            [-80.844584246999943, 35.301851897000063],
            [-80.844596245999981, 35.302172897000048],
            [-80.844639245999986, 35.303385898000045],
            [-80.844728245999988, 35.305966899000055],
            [-80.844756246999964, 35.306736898000054],
            [-80.844781246999958, 35.307156898000073],
            [-80.844833245999951, 35.307820898000045],
            [-80.84520424699997, 35.30776189900007],
            [-80.845684246999951, 35.307698899000059],
            [-80.846841247999976, 35.307546899000045],
            [-80.847599247999938, 35.30744689900007],
            [-80.848016247999965, 35.307391899000038],
            [-80.84847924799999, 35.307330898000032],
            [-80.848670246999973, 35.306500898000024],
            [-80.849202247999983, 35.30416389800007],
            [-80.849458247999962, 35.303117898000039],
            [-80.849606247999986, 35.302511898000034],
            [-80.849711247999949, 35.302037897000048],
            [-80.850058247999982, 35.300472896000031],
            [-80.85020624699996, 35.299827897000057],
            [-80.850289246999978, 35.299484896000024],
            [-80.850323246999949, 35.299262896000073],
            [-80.850406247999956, 35.298886897000045],
            [-80.851042248999988, 35.296123895000051],
            [-80.851208247999978, 35.295363895000037],
            [-80.85130124799997, 35.29486989600008],
            [-80.851359247999937, 35.294467895000025],
            [-80.851404247999938, 35.294064895000076],
            [-80.85143624799997, 35.293618895000066],
            [-80.851462247999962, 35.293026895000025],
            [-80.851460247999967, 35.292502895000041],
            [-80.851450247999935, 35.292168895000032],
            [-80.851436246999981, 35.291991895000024],
            [-80.851400247999948, 35.291530895000051],
            [-80.851372247999961, 35.291281895000054],
            [-80.851318246999938, 35.290872895000064],
            [-80.851274247999982, 35.290583895000054],
            [-80.851201246999949, 35.290156894000063],
            [-80.851132247999942, 35.289861895000058],
            [-80.850943247999965, 35.289134895000075],
            [-80.850848247999977, 35.288817894000033],
            [-80.850744247999955, 35.288505895000071],
            [-80.850586246999967, 35.288027894000038],
            [-80.850450246999969, 35.287687894000044],
            [-80.85015324799997, 35.286990894000041],
            [-80.850395247999984, 35.286894894000056],
            [-80.850669246999985, 35.286819894000075],
            [-80.850784246999979, 35.286768893000044],
            [-80.850842247999935, 35.286746894000032],
            [-80.851041247999945, 35.286706894000076],
            [-80.851083246999963, 35.286656894000032],
            [-80.851668246999964, 35.286845893000077],
            [-80.851854247999938, 35.286912894000068],
            [-80.851943246999951, 35.286952894000024],
            [-80.852088246999983, 35.287026894000064],
            [-80.852238246999946, 35.287118894000059],
            [-80.852380248999964, 35.287228894000066],
            [-80.852507247999938, 35.287349894000045],
            [-80.852833247999968, 35.287730894000049],
            [-80.85293024799995, 35.287851894000028],
            [-80.853213247999975, 35.288194894000071],
            [-80.853272247999939, 35.288256894000028],
            [-80.853377248999948, 35.28835289400007],
            [-80.853496248999988, 35.28844289400007],
            [-80.853603247999956, 35.288517894000051],
            [-80.853792247999934, 35.288633894000043],
            [-80.854372248999937, 35.288975894000032],
            [-80.856100248999951, 35.289970894000078],
            [-80.856500249999954, 35.290185894000047],
            [-80.857074248999936, 35.290466895000066],
            [-80.857456248999938, 35.290644894000025],
            [-80.857612249999988, 35.290694894000069],
            [-80.857755248999979, 35.290262895000069],
            [-80.857882249999989, 35.28985089400004],
            [-80.857942249999951, 35.289600894000046],
            [-80.858191248999958, 35.288370894000025],
            [-80.858293249999974, 35.287976894000053],
            [-80.859515249999959, 35.288032893000036],
            [-80.860124249999956, 35.288065894000056],
            [-80.860490249999941, 35.28809089400005],
            [-80.86063625099996, 35.288108893000071],
            [-80.860709250999946, 35.288124894000077],
            [-80.860837249999975, 35.288164894000033],
            [-80.860897249999937, 35.288190893000035],
            [-80.861009249999938, 35.288254894000033],
            [-80.861911249999935, 35.288834894000047],
            [-80.862063250999938, 35.288920894000057],
            [-80.862303249999968, 35.289046893000034],
            [-80.862809250999987, 35.289274894000073],
            [-80.863100250999935, 35.289400894000039],
            [-80.86327025099996, 35.289461893000066],
            [-80.863358250999966, 35.289483894000057],
            [-80.863437250999937, 35.289496893000035],
            [-80.863527250999937, 35.289503894000063],
            [-80.863623250999979, 35.289502894000066],
            [-80.863815251999938, 35.289490894000039],
            [-80.86497325199997, 35.289376893000053],
            [-80.865390251999941, 35.289335894000033],
            [-80.865598251999984, 35.28932089400007],
            [-80.865717251999968, 35.289317893000032],
            [-80.865889251999988, 35.289325894000058],
            [-80.866005251999979, 35.289338894000025],
            [-80.866106251999952, 35.289354894000041],
            [-80.866790252999976, 35.289494894000029],
            [-80.866806252999936, 35.28942289400004],
            [-80.866809251999939, 35.289305894000051],
            [-80.866812251999988, 35.289209894000066],
            [-80.86678625199994, 35.289121893000072],
            [-80.866763251999942, 35.288965893000068],
            [-80.866763251999942, 35.288718893000066],
            [-80.866767251999988, 35.288677893000056],
            [-80.866798251999967, 35.288570894000031],
            [-80.866805251999949, 35.288493894000055],
            [-80.866802250999967, 35.288357894000058],
            [-80.866789252999979, 35.288271894000047],
            [-80.866794251999977, 35.288226893000058],
            [-80.866870252999945, 35.288042893000068],
            [-80.866850252999939, 35.287907894000057],
            [-80.86656125199994, 35.287564893000024],
            [-80.866593251999973, 35.287297893000073],
            [-80.866390250999984, 35.287169894000044],
            [-80.866475251999987, 35.287102893000053],
            [-80.866644250999968, 35.286905893000039],
            [-80.86674125199994, 35.286818894000078],
            [-80.866806251999947, 35.286727893000034],
            [-80.866812251999988, 35.286659894000024],
            [-80.866881251999985, 35.286627893000059],
            [-80.866934252999954, 35.286578894000058],
            [-80.867057251999938, 35.286537894000048],
            [-80.867113251999967, 35.286479894000024],
            [-80.867179251999971, 35.28647389300005],
            [-80.867274251999959, 35.286378893000062],
            [-80.867307251999989, 35.286353893000069],
            [-80.867468251999981, 35.286284893000072],
            [-80.867712252999979, 35.286065893000057],
            [-80.867756251999936, 35.285998893000055],
            [-80.867809251999972, 35.285935893000044],
            [-80.867938251999988, 35.28572689300006],
            [-80.86802325299999, 35.285621893000041],
            [-80.868083252999952, 35.285538892000034],
            [-80.868201252999938, 35.285377893000032],
            [-80.868231252999976, 35.285327892000055],
            [-80.868239252999956, 35.28528089200006],
            [-80.868231251999987, 35.285169892000056],
            [-80.868199252999943, 35.285113893000073],
            [-80.868173252999952, 35.285042893000025],
            [-80.868127251999965, 35.284971892000044],
            [-80.868054252999968, 35.28493089300008],
            [-80.868010251999976, 35.284879893000038],
            [-80.868010251999976, 35.284820893000074],
            [-80.868019251999954, 35.28479989300007],
            [-80.868045252999934, 35.284740893000048],
            [-80.868048251999937, 35.28461989300007],
            [-80.868095251999989, 35.284483893000072],
            [-80.86812125199998, 35.284411892000037],
            [-80.86816625299997, 35.284331893000058],
            [-80.868168252999965, 35.284284893000063],
            [-80.868176251999955, 35.284235893000073],
            [-80.868274251999935, 35.284051892000036],
            [-80.868342251999934, 35.283988893000071],
            [-80.86839125299997, 35.283903893000058],
            [-80.868412251999985, 35.28382489300003],
            [-80.86839125299997, 35.283724892000066],
            [-80.868371251999974, 35.283630893000065],
            [-80.868409252999982, 35.283563892000075],
            [-80.868482252999968, 35.283354892000034],
            [-80.86855725199996, 35.283289893000074],
            [-80.868671252999945, 35.283238893000032],
            [-80.868891252999958, 35.283153893000076],
            [-80.868875251999953, 35.283089892000078],
            [-80.868862252999975, 35.283076892000054],
            [-80.868776251999975, 35.282985892000056],
            [-80.868716251999956, 35.282874893000042],
            [-80.868731251999975, 35.282794892000027],
            [-80.868649252999944, 35.282762892000051],
            [-80.868647252999949, 35.282761893000043],
            [-80.868524251999986, 35.282700893000026],
            [-80.868450251999946, 35.282701893000024],
            [-80.868406252999989, 35.282690892000062],
            [-80.868344251999986, 35.282622893000052],
            [-80.868311251999955, 35.282548892000079],
            [-80.86831325299994, 35.282512892000057],
            [-80.868346251999981, 35.282472891000054],
            [-80.868372251999972, 35.282422892000056],
            [-80.868352251999966, 35.282356892000053],
            [-80.868168251999975, 35.282238891000077],
            [-80.868117251999934, 35.282186892000027],
            [-80.868194251999967, 35.281925892000061],
            [-80.868128251999963, 35.28189589200008],
            [-80.867874251999979, 35.281611892000058],
            [-80.867663251999943, 35.281218892000027],
            [-80.867632252999954, 35.28119789200008],
            [-80.867595251999944, 35.281157892000067],
            [-80.867585251999969, 35.281096892000051],
            [-80.86761525199995, 35.280964892000043],
            [-80.867623250999941, 35.280895892000046],
            [-80.867628251999975, 35.280735892000052],
            [-80.86757725199999, 35.280514892000042],
            [-80.867587251999964, 35.280304892000061],
            [-80.867556251999986, 35.280213892000063],
            [-80.86757425199994, 35.280017891000057],
            [-80.867726251999954, 35.279691892000073],
            [-80.867764251999972, 35.279647892000071],
            [-80.868045251999945, 35.279432891000056],
            [-80.868171251999968, 35.279328891000034],
            [-80.86821525199997, 35.279277891000049],
            [-80.868248251999944, 35.279174892000071],
            [-80.868324251999979, 35.279010891000041],
            [-80.868335252999941, 35.278930891000073],
            [-80.868316251999943, 35.27889089100006],
            [-80.868200252999941, 35.278826891000051],
            [-80.868168251999975, 35.278780892000043],
            [-80.868176251999955, 35.278583892000029],
            [-80.868195251999964, 35.278507891000061],
            [-80.868183252999984, 35.278365892000068],
            [-80.868025251999939, 35.278070891000027],
            [-80.868020251999951, 35.278037892000043],
            [-80.867978251999943, 35.277950891000046],
            [-80.867921251999974, 35.277890891000027],
            [-80.867784251999979, 35.277796891000037],
            [-80.867752252999935, 35.277753891000032],
            [-80.867715251999982, 35.277692892000061],
            [-80.867714252999974, 35.277646892000064],
            [-80.867684251999947, 35.277579891000073],
            [-80.86757025199995, 35.277389891000041],
            [-80.867482250999956, 35.277195891000076],
            [-80.86742325199998, 35.277121892000025],
            [-80.86734125199996, 35.27699989100006],
            [-80.86733325199998, 35.27693989100004],
            [-80.867322252999941, 35.276704891000065],
            [-80.86734125199996, 35.276664891000053],
            [-80.867359251999972, 35.276601891000041],
            [-80.867335251999975, 35.276541891000079],
            [-80.867263251999987, 35.276449890000038],
            [-80.86706725099998, 35.276171892000036],
            [-80.866996250999989, 35.276060891000043],
            [-80.867572251999945, 35.276118891000067],
            [-80.868473252999934, 35.276220891000037],
            [-80.86895425199998, 35.276286891000041],
            [-80.869127252999988, 35.276309891000039],
            [-80.869927251999968, 35.276388891000067],
            [-80.870179252999947, 35.276404892000073],
            [-80.870324252999978, 35.276417891000051],
            [-80.870472252999946, 35.276425892000077],
            [-80.870767251999951, 35.27642889100008],
            [-80.871060252999939, 35.276414890000069],
            [-80.871208251999974, 35.276397891000045],
            [-80.871385252999971, 35.276368890000072],
            [-80.871491252999988, 35.276344891000065],
            [-80.871590252999965, 35.276317891000076],
            [-80.871786252999982, 35.276250890000028],
            [-80.871947253999963, 35.276184891000071],
            [-80.872062253999957, 35.276131891000034],
            [-80.872284252999975, 35.276014891000045],
            [-80.872444253999959, 35.275911891000078],
            [-80.872618252999985, 35.275772891000031],
            [-80.872806252999965, 35.27559989100007],
            [-80.872873252999966, 35.275528891000079],
            [-80.872994252999945, 35.275380890000065],
            [-80.873142252999969, 35.275165890000039],
            [-80.873272253999971, 35.274941891000026],
            [-80.873329253999941, 35.27482789000004],
            [-80.873450252999987, 35.274620890000051],
            [-80.873583253999982, 35.274354890000041],
            [-80.873636252999972, 35.27424889100007],
            [-80.873799253999948, 35.273985891000052],
            [-80.874582253999961, 35.272583891000068],
            [-80.874620253999979, 35.272480890000054],
            [-80.874810253999954, 35.272153890000027],
            [-80.874970253999948, 35.271876890000044],
            [-80.875126253999952, 35.271627890000048],
            [-80.875433253999972, 35.27109088900005],
            [-80.875477253999975, 35.271002889000044],
            [-80.875672253999937, 35.270618889000048],
            [-80.875834253999983, 35.270326889000046],
            [-80.876123253999936, 35.269811890000028],
            [-80.876207253999951, 35.269652889000042],
            [-80.876313253999967, 35.269449889000043],
            [-80.876892253999983, 35.268405889000064],
            [-80.877022253999939, 35.268184889000054],
            [-80.877097253999978, 35.268080888000043],
            [-80.877175252999962, 35.268011889000036],
            [-80.877266254999938, 35.267960889000051],
            [-80.877406253999936, 35.267915889000051],
            [-80.877735254999948, 35.267830888000049],
            [-80.877535253999952, 35.267659888000026],
            [-80.876206253999953, 35.266053888000044],
            [-80.875988252999946, 35.266229888000055],
            [-80.875919253999939, 35.266191888000037],
            [-80.875897252999948, 35.266173888000026],
            [-80.875500253999974, 35.265610889000072],
            [-80.875449253999989, 35.265554889000043],
            [-80.875356253999939, 35.265478889000065],
            [-80.875572253999962, 35.26528588900004],
            [-80.87544225299996, 35.265134887000045],
            [-80.87529525399998, 35.264963888000068],
            [-80.874862253999936, 35.265366889000063],
            [-80.874514253999962, 35.265656888000024],
            [-80.874109253999961, 35.266010889000029],
            [-80.873833253999976, 35.266240888000027],
            [-80.873495252999987, 35.266499888000055],
            [-80.873274251999987, 35.266655889000049],
            [-80.872899252999957, 35.266901889000053],
            [-80.872675252999954, 35.267032888000074],
            [-80.87246625299997, 35.267144888000075],
            [-80.872261251999987, 35.26724488900004],
            [-80.872056252999982, 35.267351889000054],
            [-80.871798252999952, 35.267476888000033],
            [-80.871417251999958, 35.26764188900006],
            [-80.871036251999953, 35.267785889000038],
            [-80.870848251999973, 35.267850888000055],
            [-80.870682251999938, 35.267902889000027],
            [-80.870455251999942, 35.267973889000075],
            [-80.870229251999945, 35.268039889000079],
            [-80.870035252999969, 35.268090889000064],
            [-80.869967251999981, 35.267909888000077],
            [-80.86989625199999, 35.267722889000026],
            [-80.869833251999978, 35.267643890000045],
            [-80.869821251999952, 35.267605890000027],
            [-80.869812251999974, 35.267535889000044],
            [-80.869777251999949, 35.267446889000041],
            [-80.869760252999981, 35.267350889000056],
            [-80.86975825199994, 35.267275890000064],
            [-80.869780252999988, 35.267167888000074],
            [-80.869788251999978, 35.267019889000039],
            [-80.869783251999934, 35.266987889000063],
            [-80.869693251999934, 35.266783889000067],
            [-80.869669251999937, 35.266544889000045],
            [-80.86966825199994, 35.26641188800005],
            [-80.869658251999965, 35.266369889000032],
            [-80.869642252999938, 35.266327889000024],
            [-80.869631251999976, 35.266278889000034],
            [-80.869571251999957, 35.266187888000047],
            [-80.869555251999941, 35.26613788800006],
            [-80.869541251999976, 35.266049889000044],
            [-80.869486251999945, 35.265975888000071],
            [-80.869451252999966, 35.265916888000049],
            [-80.869434250999973, 35.265870888000052],
            [-80.869418251999946, 35.265759888000048],
            [-80.869381251999982, 35.265687889000048],
            [-80.869380251999985, 35.265593889000058],
            [-80.869371251999951, 35.265549888000066],
            [-80.869348251999952, 35.265502889000061],
            [-80.869326250999961, 35.265419889000043],
            [-80.869326250999961, 35.265374888000053],
            [-80.869318251999971, 35.265336889000025],
            [-80.869303251999952, 35.265309889000036],
            [-80.86928825199999, 35.265295889000072],
            [-80.869209251999962, 35.265201889000025],
            [-80.869112251999979, 35.265103889000045],
            [-80.869070251999972, 35.265036889000044],
            [-80.869037251999941, 35.264963889000057],
            [-80.869006250999973, 35.264942888000064],
            [-80.86897025199994, 35.26490988900008],
            [-80.868849250999972, 35.264853889000051],
            [-80.868796250999935, 35.264833889000045],
            [-80.868773250999936, 35.26483588800005],
            [-80.868721250999954, 35.264724889000036],
            [-80.868695251999952, 35.264319888000045],
            [-80.868715251999959, 35.264266889000055],
            [-80.868793250999943, 35.264115888000049],
            [-80.868824251999968, 35.263999889000047],
            [-80.868842251999979, 35.263932888000056],
            [-80.868852250999964, 35.263642888000049],
            [-80.868829250999966, 35.263558888000034],
            [-80.868817250999939, 35.263335889000075],
            [-80.868756250999979, 35.263098888000059],
            [-80.868694250999965, 35.262963888000058],
            [-80.868569251999986, 35.26274188800005],
            [-80.86850425199998, 35.262668888000064],
            [-80.868451251999943, 35.262533888000064],
            [-80.86837325099998, 35.262097888000028],
            [-80.868319251999935, 35.261962888000028],
            [-80.868250251999939, 35.261847888000034],
            [-80.868169250999983, 35.261569888000054],
            [-80.868046250999953, 35.261186888000054],
            [-80.868049250999945, 35.26104588700008],
            [-80.868050250999943, 35.261011888000041],
            [-80.868119250999939, 35.260678888000029],
            [-80.868163251999988, 35.260541888000034],
            [-80.868208249999952, 35.260394888000064],
            [-80.86821525199997, 35.260313888000042],
            [-80.868319250999946, 35.260008887000026],
            [-80.868348250999986, 35.25978088800008],
            [-80.868420249999986, 35.259674887000074],
            [-80.868453250999949, 35.259465887000033],
            [-80.868476250999947, 35.259316887000068],
            [-80.86850825099998, 35.259232887000053],
            [-80.868501250999941, 35.259134887000073],
            [-80.868493250999961, 35.258844888000056],
            [-80.868461249999939, 35.258785887000045],
            [-80.868409250999946, 35.258713887000056],
            [-80.86827925099999, 35.258645887000057],
            [-80.868079250999983, 35.25844988700004],
            [-80.868035250999981, 35.258418887000062],
            [-80.867906250999965, 35.258374887000059],
            [-80.867821249999963, 35.25835588700005],
            [-80.86772825099996, 35.258321887000079],
            [-80.867651250999984, 35.258277887000077],
            [-80.867500250999967, 35.25822088700005],
            [-80.867235250999954, 35.25817188700006],
            [-80.867029249999973, 35.258108888000038],
            [-80.866937249999978, 35.258062887000051],
            [-80.866817250999986, 35.258003887000029],
            [-80.866569250999987, 35.257948887000055],
            [-80.866259249999985, 35.25789388700008],
            [-80.865749249999965, 35.257768887000054],
            [-80.865650249999987, 35.257734887000026],
            [-80.865357249999988, 35.257610887000055],
            [-80.865140250999957, 35.257414887000039],
            [-80.865095249999968, 35.257306887000027],
            [-80.864995249999936, 35.257140886000059],
            [-80.864982250999958, 35.257099887000038],
            [-80.86496724899996, 35.257053887000041],
            [-80.864919249999957, 35.256784887000038],
            [-80.864965250999944, 35.256598887000052],
            [-80.864980249999974, 35.256488887000046],
            [-80.864973249999935, 35.256304887000056],
            [-80.86496724999995, 35.25617188700005],
            [-80.864965249999955, 35.256082887000048],
            [-80.864981250999961, 35.255884887000036],
            [-80.864997249999988, 35.255659886000046],
            [-80.865011249999952, 35.255436886000041],
            [-80.865032249999956, 35.255123887000025],
            [-80.865037249999943, 35.25472088600003],
            [-80.865072249999969, 35.254517886000031],
            [-80.865078249999954, 35.254220886000041],
            [-80.865069249999976, 35.253919887000052],
            [-80.865080249999949, 35.253700887000036],
            [-80.865095249999968, 35.253416886000025],
            [-80.865157249999982, 35.253073886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.86243124799995, 35.251880886000038],
            [-80.861236248999944, 35.251518886000042],
            [-80.859896247999984, 35.251112886000044],
            [-80.858236247999969, 35.25041288500006],
            [-80.85721224699995, 35.249820885000076],
            [-80.856508246999965, 35.249355886000046],
            [-80.856477247999976, 35.249634885000035],
            [-80.856421247999947, 35.250138885000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856288245999963, 35.251323887000069],
            [-80.85627724699998, 35.251412886000026],
            [-80.856207247999976, 35.252041886000029],
            [-80.856123246999971, 35.25280988600008],
            [-80.856099246999975, 35.253030886000033],
            [-80.856035246999966, 35.253668886000071],
            [-80.856011247999959, 35.253852886000061],
            [-80.855990246999966, 35.254017887000032],
            [-80.855873246999977, 35.254969887000073],
            [-80.85571424699998, 35.256165887000066],
            [-80.855603246999976, 35.257036887000027],
            [-80.855533246999983, 35.25761288700005],
            [-80.851164245999939, 35.257280888000025],
            [-80.849219245999961, 35.257130888000063],
            [-80.844800243999941, 35.256745888000069],
            [-80.844682243999955, 35.256810888000075],
            [-80.844417242999953, 35.256956887000058],
            [-80.843851242999961, 35.256908888000055],
            [-80.842000242999973, 35.256871888000035],
            [-80.84176824299999, 35.25686988800004],
            [-80.842172242999936, 35.25594288700006],
            [-80.842950242999962, 35.254226887000073],
            [-80.843135243999939, 35.253804887000058],
            [-80.843718242999955, 35.25252088600007],
            [-80.843752242999983, 35.252443887000027],
            [-80.843179243999941, 35.253172887000062],
            [-80.843106242999966, 35.253262886000073],
            [-80.842909243999941, 35.253056887000071],
            [-80.842890242999943, 35.253045887000042],
            [-80.842868243999987, 35.253035887000067],
            [-80.842837242999963, 35.253032887000074],
            [-80.842708243999937, 35.253039887000057],
            [-80.842584242999976, 35.253001887000039],
            [-80.842524242999957, 35.25297888700004],
            [-80.842464241999949, 35.252922888000057],
            [-80.842417242999943, 35.252776887000039],
            [-80.84235724399997, 35.252730887000041],
            [-80.842316242999971, 35.252706887000045],
            [-80.84209524299996, 35.252671887000076],
            [-80.841976241999987, 35.252582886000027],
            [-80.84196424299995, 35.252522886000065],
            [-80.841942242999949, 35.252505887000041],
            [-80.841726242999982, 35.252431887000057],
            [-80.841689242999962, 35.25243088700006],
            [-80.841651242999944, 35.252441887000032],
            [-80.841542242999935, 35.252509887000031],
            [-80.841513242999952, 35.252515887000072],
            [-80.841386242999988, 35.252484887000037],
            [-80.841271242999937, 35.25243088700006],
            [-80.841107242999954, 35.252354887000024],
            [-80.841025241999944, 35.252328887000033],
            [-80.840976242999943, 35.252304886000047],
            [-80.840896242999975, 35.252234887000043],
            [-80.840765241999975, 35.252153887000077],
            [-80.840735242999983, 35.252119887000049],
            [-80.840737241999989, 35.252077886000052],
            [-80.840746241999966, 35.252026887000056],
            [-80.840904242999954, 35.251842887000066],
            [-80.840912242999934, 35.251817887000072],
            [-80.840912242999934, 35.251799887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840861242999949, 35.251709887000061],
            [-80.840819242999942, 35.251696887000037],
            [-80.840728241999955, 35.25169988600004],
            [-80.840577241999938, 35.25173688700005],
            [-80.840539241999977, 35.251742887000034],
            [-80.84051124299998, 35.251735887000052],
            [-80.840284241999939, 35.251610886000037],
            [-80.840202241999975, 35.251569886000027],
            [-80.840011242999935, 35.251404887000035],
            [-80.839948241999934, 35.251377886000057],
            [-80.839798241999972, 35.251291886000047],
            [-80.83960824199994, 35.251191886000072],
            [-80.839568241999984, 35.251164887000073],
            [-80.839530242999956, 35.251145887000064],
            [-80.83941224199998, 35.251117886000031],
            [-80.839334242999939, 35.25108788700004],
            [-80.839271240999949, 35.251063887000043],
            [-80.839197242999944, 35.251012887000059],
            [-80.839146242999959, 35.250957887000027],
            [-80.839083242999948, 35.250900886000068],
            [-80.838688241999989, 35.250760887000069],
            [-80.838459241999942, 35.250722887000052],
            [-80.838398241999982, 35.250726886000052],
            [-80.83826024199999, 35.250779887000078],
            [-80.838234240999952, 35.250810887000057],
            [-80.838214241999935, 35.250844887000028],
            [-80.838207241999953, 35.250883887000043],
            [-80.838193240999942, 35.250902886000063],
            [-80.838158240999974, 35.250923887000056],
            [-80.838116241999955, 35.250933887000031],
            [-80.838111241999968, 35.250967886000069],
            [-80.838059241999986, 35.250979886000039],
            [-80.838017241999978, 35.250947886000063],
            [-80.837993241999982, 35.250786886000071],
            [-80.837964240999952, 35.250747886000056],
            [-80.837849241999947, 35.250714887000072],
            [-80.837624241999947, 35.25067088600008],
            [-80.837565240999936, 35.250641887000029],
            [-80.837534240999958, 35.250613887000043],
            [-80.837438240999973, 35.250445887000069],
            [-80.837375240999961, 35.250219886000025],
            [-80.83735724099995, 35.250086886000076],
            [-80.837355240999955, 35.250032886000042],
            [-80.837347241999964, 35.250006886000051],
            [-80.83733024199995, 35.249991886000032],
            [-80.83729624099999, 35.249977886000067],
            [-80.837069241999984, 35.249979887000052],
            [-80.837000241999988, 35.249976887000059],
            [-80.836825241999975, 35.249969887000077],
            [-80.836493240999971, 35.249861887000066],
            [-80.836440240999934, 35.249836887000072],
            [-80.836357240999973, 35.249764887000026],
            [-80.836235241999987, 35.249620887000049],
            [-80.836173240999983, 35.249530887000049],
            [-80.835993240999983, 35.249530886000059],
            [-80.835851240999943, 35.249538886000039],
            [-80.835869240999955, 35.250261886000033],
            [-80.835898240999938, 35.251096887000074],
            [-80.835909240999968, 35.251327887000059],
            [-80.835934240999961, 35.252153887000077],
            [-80.835966241999984, 35.253233887000079],
            [-80.835975240999971, 35.253517887000044],
            [-80.83599824199996, 35.254170887000043],
            [-80.836014240999987, 35.254684887000053],
            [-80.836017240999979, 35.255018887000062],
            [-80.836028239999962, 35.255357888000049],
            [-80.836031240999944, 35.255731888000071],
            [-80.836058240999989, 35.256368888000054],
            [-80.836077241999988, 35.256569888000058],
            [-80.836113241999954, 35.256884889000048],
            [-80.836151240999982, 35.257066888000054],
            [-80.836165240999946, 35.257132889000047],
            [-80.83564224099996, 35.25727488900003],
            [-80.835225240999989, 35.257382888000052],
            [-80.834986240999967, 35.257455887000049],
            [-80.834550240999988, 35.257463888000075],
            [-80.833150239999952, 35.257486889000063],
            [-80.832710239999983, 35.257490888000063],
            [-80.830825239999967, 35.257526888000029],
            [-80.829041239999981, 35.257557889000054],
            [-80.828213238999979, 35.257568888000037],
            [-80.828094237999949, 35.257554888000072],
            [-80.827989238999976, 35.257526889000076],
            [-80.82787123899999, 35.257475888000045],
            [-80.827816238999958, 35.257444888000066],
            [-80.827764238999976, 35.25740988900003],
            [-80.827193238999939, 35.256962889000079],
            [-80.826423237999961, 35.256360888000074],
            [-80.826317237999945, 35.256279888000051],
            [-80.825728238999943, 35.255793888000028],
            [-80.825577237999937, 35.255679888000031],
            [-80.825542237999969, 35.25565388900003],
            [-80.825369237999951, 35.255560888000048],
            [-80.824601236999968, 35.25519588800006],
            [-80.824251237999988, 35.255017888000054],
            [-80.824152236999964, 35.254965888000072],
            [-80.823278236999954, 35.254477888000054],
            [-80.823098236999954, 35.254382888000066],
            [-80.822443237999948, 35.254015888000026],
            [-80.821951235999961, 35.25375088800007],
            [-80.821747236999954, 35.253641888000061],
            [-80.820744235999939, 35.253133888000036],
            [-80.820698236999988, 35.253104887000063],
            [-80.820616236999967, 35.253038888000049],
            [-80.820580236999945, 35.252998887000047],
            [-80.820322235999981, 35.252680888000043],
            [-80.819446236999966, 35.251645887000052],
            [-80.819399235999981, 35.251599888000044],
            [-80.819359234999979, 35.251555888000041],
            [-80.819225235999966, 35.251390887000071],
            [-80.819101235999938, 35.251262887000053],
            [-80.819068235999964, 35.251239887000054],
            [-80.818978236999953, 35.251194887000054],
            [-80.818796235999969, 35.251048888000071],
            [-80.818523235999976, 35.25084688700008],
            [-80.818018235999943, 35.250461887000029],
            [-80.817826235999974, 35.250647887000071],
            [-80.817725235999944, 35.250860887000044],
            [-80.816908234999971, 35.251338888000078],
            [-80.816900235999981, 35.251355887000045],
            [-80.816885235999962, 35.251371887000062],
            [-80.81670623499997, 35.251475888000073],
            [-80.816385234999984, 35.251682888000062],
            [-80.816044234999936, 35.25187588700004],
            [-80.814760234999937, 35.252586888000053],
            [-80.814574233999963, 35.252683888000035],
            [-80.813609233999955, 35.251501888000064],
            [-80.813116233999949, 35.250908887000037],
            [-80.812976232999972, 35.250721888000044],
            [-80.812722233999978, 35.250834888000043],
            [-80.811786232999964, 35.251219888000037],
            [-80.811703233999935, 35.251257887000065],
            [-80.813732234999975, 35.253674888000035],
            [-80.814041234999934, 35.254043888000069],
            [-80.812714233999941, 35.254667888000029],
            [-80.810940233999986, 35.256222888000025],
            [-80.809988233999945, 35.25734888900007],
            [-80.809322232999989, 35.256752888000051],
            [-80.809298232999936, 35.256626889000074],
            [-80.809286233999956, 35.256589889000054],
            [-80.809283233999963, 35.25653188900003],
            [-80.809296232999941, 35.256448889000069],
            [-80.809328233999963, 35.256370888000049],
            [-80.809346233999975, 35.256300888000055],
            [-80.80936423299994, 35.256255889000045],
            [-80.809368232999987, 35.256132888000025],
            [-80.809411232999935, 35.255846889000054],
            [-80.809390232999988, 35.255646889000047],
            [-80.809371232999979, 35.255370888000073],
            [-80.809377233999953, 35.255175888000053],
            [-80.809395233999965, 35.255109889000039],
            [-80.809396233999962, 35.254971889000046],
            [-80.809405233999939, 35.254811888000063],
            [-80.809411232999935, 35.254674889000057],
            [-80.809356233999949, 35.254613889000041],
            [-80.809368232999987, 35.254484889000025],
            [-80.809516232999954, 35.25431388800007],
            [-80.809563232999949, 35.254225889000054],
            [-80.809605232999957, 35.254127889000074],
            [-80.809603232999962, 35.25407388900004],
            [-80.809586232999948, 35.254017889000067],
            [-80.809386233999987, 35.25379588800007],
            [-80.809348231999934, 35.253665888000057],
            [-80.80934823299998, 35.253577888000052],
            [-80.80945023299995, 35.253498888000024],
            [-80.809503233999976, 35.253472887000044],
            [-80.809692233999954, 35.253405888000032],
            [-80.809734232999972, 35.253386889000069],
            [-80.809812233999935, 35.253342888000077],
            [-80.80986223299999, 35.253279888000066],
            [-80.809865232999982, 35.253256889000056],
            [-80.809841232999986, 35.253181888000029],
            [-80.809832233999941, 35.253130888000044],
            [-80.809835233999934, 35.252996888000041],
            [-80.809884233999981, 35.252810888000056],
            [-80.809887233999973, 35.252697888000057],
            [-80.809883232999937, 35.252639888000033],
            [-80.809878233999939, 35.252480887000047],
            [-80.809884232999934, 35.252389888000039],
            [-80.809854233999943, 35.252150888000074],
            [-80.809818232999987, 35.252086888000065],
            [-80.808134232999976, 35.252817888000038],
            [-80.806454231999965, 35.25354788900006],
            [-80.806222232999971, 35.253657889000067],
            [-80.805931231999978, 35.253780888000051],
            [-80.805632231999937, 35.25388988800006],
            [-80.805328232999955, 35.253986889000032],
            [-80.805212232999963, 35.254019888000073],
            [-80.804808230999981, 35.25411888800005],
            [-80.80448923199998, 35.254179888000067],
            [-80.804275231999952, 35.25421288900003],
            [-80.803904231999979, 35.254276889000039],
            [-80.803190230999974, 35.254400889000067],
            [-80.802004230999955, 35.254605889000061],
            [-80.801055230999964, 35.254818889000035],
            [-80.80076023099997, 35.254908889000035],
            [-80.800487229999987, 35.255014888000062],
            [-80.800187229999949, 35.255154889000039],
            [-80.797969229999978, 35.256567889000053],
            [-80.797614229999965, 35.256772889000047],
            [-80.796552229999975, 35.257453889000033],
            [-80.796364228999948, 35.257592890000069],
            [-80.796147228999985, 35.257737889000055],
            [-80.795921229999976, 35.257872890000044],
            [-80.795687229999942, 35.257998889000078],
            [-80.795444228999941, 35.258113890000061],
            [-80.795195228999944, 35.25821889000008],
            [-80.794940228999963, 35.25831289000007],
            [-80.794768228999942, 35.258348890000036],
            [-80.795164229999955, 35.260706890000051],
            [-80.795179228999984, 35.26089789100007],
            [-80.795163229999957, 35.261404891000041],
            [-80.795147229999941, 35.261872891000053],
            [-80.795123229999945, 35.262356890000035],
            [-80.795083228999943, 35.263037891000067],
            [-80.795061228999941, 35.263513891000059],
            [-80.794990229999939, 35.264926891000073],
            [-80.794946228999947, 35.265762891000065],
            [-80.794896229999949, 35.266730892000055],
            [-80.794874228999959, 35.267168892000029],
            [-80.794834229999935, 35.26747189200006],
            [-80.794786229999943, 35.267692891000024],
            [-80.79466722899997, 35.268247892000034],
            [-80.794326229999967, 35.269861892000051],
            [-80.794087229999946, 35.27099189200004],
            [-80.793788228999972, 35.272438893000071],
            [-80.793525229999943, 35.273675894000064],
            [-80.79349222999997, 35.274038894000057],
            [-80.793491229999972, 35.274128893000068],
            [-80.793487229999982, 35.274525893000032],
            [-80.793495229999962, 35.275346894000052],
            [-80.793511230999968, 35.276558894000061],
            [-80.793511229999979, 35.276915894000069],
            [-80.793527229999938, 35.277978894000057],
            [-80.79352922999999, 35.278731894000032],
            [-80.793530229999988, 35.278933894000033],
            [-80.793531229999985, 35.279478895000068],
            [-80.793540229999962, 35.280777894000039],
            [-80.793546230999937, 35.28105789500006],
            [-80.793552229999989, 35.282143895000047],
            [-80.79358222999997, 35.284347895000053],
            [-80.793606229999966, 35.284676895000075],
            [-80.793613229999949, 35.28478089500004],
            [-80.793662230999985, 35.285056896000071],
            [-80.793731229999935, 35.285330896000062],
            [-80.793796230999988, 35.285532896000063],
            [-80.793849229999978, 35.285670895000067],
            [-80.793971230999944, 35.285944896000046],
            [-80.794056230999956, 35.286117896000064],
            [-80.794322230999967, 35.286661896000055],
            [-80.794510230999947, 35.287037896000072],
            [-80.794722229999934, 35.287514896000062],
            [-80.794809230999988, 35.287708896000026],
            [-80.795913230999986, 35.29046089600007],
            [-80.796380230999944, 35.291642897000031],
            [-80.796585231999984, 35.292145896000079],
            [-80.79680123199995, 35.292762897000046],
            [-80.797156231999963, 35.29366189700005],
            [-80.797560231999967, 35.294716898000047],
            [-80.797782231999975, 35.295235898000044],
            [-80.797880231999955, 35.295453898000062],
            [-80.797989231999964, 35.295644898000035],
            [-80.79809523299997, 35.295821897000053],
            [-80.798322232999965, 35.296145898000077],
            [-80.798511231999953, 35.296419897000078],
            [-80.798686231999966, 35.296675898000046],
            [-80.798764232999986, 35.296779898000068],
            [-80.798892231999957, 35.296939898000062],
            [-80.798945231999937, 35.296913898000071],
            [-80.799011232999987, 35.29688789800008],
            [-80.799091232999956, 35.296854898000049],
            [-80.799053231999949, 35.296806897000067],
            [-80.798936232999949, 35.296651897000061],
            [-80.798777232999953, 35.296425897000063],
            [-80.798720231999937, 35.296337898000047],
            [-80.79865423199999, 35.296226897000054],
            [-80.79860923199999, 35.296135897000056],
            [-80.798585231999937, 35.296069898000042],
            [-80.798578231999954, 35.295994897000071],
            [-80.798591231999978, 35.295910898000045],
            [-80.798602231999951, 35.295877898000072],
            [-80.798630232999983, 35.295839898000054],
            [-80.798685231999968, 35.295801898000036],
            [-80.798763231999942, 35.295767898000065],
            [-80.798851232999937, 35.295738897000035],
            [-80.800308232999953, 35.295325897000055],
            [-80.80021923299995, 35.295112897000024],
            [-80.80018523199999, 35.295002898000064],
            [-80.800150232999954, 35.294835897000041],
            [-80.800127231999966, 35.29467089700006],
            [-80.80012123299997, 35.294529897000075],
            [-80.800142233999964, 35.294341898000027],
            [-80.80016223299998, 35.294227897000042],
            [-80.800159232999988, 35.294132898000043],
            [-80.80014823199997, 35.294060897000065],
            [-80.800131232999945, 35.293988897000077],
            [-80.800062232999949, 35.293842897000047],
            [-80.799991232999957, 35.293748897000057],
            [-80.800572231999979, 35.293490896000037],
            [-80.800803232999954, 35.293393897000044],
            [-80.800893232999954, 35.293354896000039],
            [-80.801039232999983, 35.293305897000039],
            [-80.80114923299999, 35.293280897000045],
            [-80.801277232999951, 35.293260897000039],
            [-80.801553232999936, 35.293238897000037],
            [-80.801804232999984, 35.293210898000041],
            [-80.80198223299999, 35.29319689700003],
            [-80.803008233999947, 35.293154897000079],
            [-80.803857232999974, 35.29313589800006],
            [-80.804604234999942, 35.293107897000027],
            [-80.80508223399994, 35.293069897000066],
            [-80.80510723499998, 35.292979897000066],
            [-80.805273233999969, 35.292778897000062],
            [-80.805865233999953, 35.292433897000024],
            [-80.80581723499995, 35.292381896000052],
            [-80.806126233999976, 35.292203897000036],
            [-80.806137233999948, 35.292215897000062],
            [-80.806639233999988, 35.292739897000047],
            [-80.807695234999983, 35.293815897000059],
            [-80.808090234999952, 35.293555897000033],
            [-80.80827523399995, 35.293425897000077],
            [-80.808383234999951, 35.293332897000028],
            [-80.808527234999985, 35.293187896000063],
            [-80.808783234999964, 35.292902897000033],
            [-80.808931235999978, 35.292725897000025],
            [-80.809113235999973, 35.293121897000049],
            [-80.80950923499995, 35.293936897000037],
            [-80.809574235999946, 35.294061897000063],
            [-80.809604235999984, 35.294101896000029],
            [-80.810329234999983, 35.295052896000072],
            [-80.810565235999945, 35.295403897000028],
            [-80.810679235999942, 35.29555689700004],
            [-80.810998235999989, 35.295984898000029],
            [-80.811415235999959, 35.29654989800008],
            [-80.811476235999976, 35.296612897000045],
            [-80.811426235999988, 35.296685897000032],
            [-80.811387235999973, 35.296773898000026],
            [-80.811333236999985, 35.296965897000064],
            [-80.811292235999986, 35.297203897000031],
            [-80.811266235999938, 35.297512897000047],
            [-80.811262235999948, 35.29773689700005],
            [-80.811277235999967, 35.297873898000034],
            [-80.811300235999965, 35.297946898000077],
            [-80.811336235999988, 35.298025898000049],
            [-80.811428235999983, 35.298144898000032],
            [-80.811547235999967, 35.298084897000024],
            [-80.811699236999971, 35.297997897000073],
            [-80.812005236999937, 35.297806897000044],
            [-80.812363236999943, 35.297590897000077],
            [-80.812575236999976, 35.297455897000077],
            [-80.812728236999988, 35.297348898000052],
            [-80.812840235999943, 35.297262897000053],
            [-80.813025236999977, 35.297098897000069],
            [-80.813350235999962, 35.296757897000077],
            [-80.813540236999984, 35.296546898000031],
            [-80.814588236999953, 35.295408897000073],
            [-80.814841236999939, 35.295131896000044],
            [-80.814842237999983, 35.295131897000033],
            [-80.815505236999968, 35.294407896000052],
            [-80.816797237999936, 35.292993896000041],
            [-80.818259237999939, 35.291372896000041],
            [-80.819421237999961, 35.290067896000039],
            [-80.819785237999952, 35.289653895000072],
            [-80.819954238999969, 35.289466896000079],
            [-80.820091237999975, 35.289562896000064],
            [-80.820182237999973, 35.289642896000032],
            [-80.820255237999959, 35.289710896000031],
            [-80.820288237999989, 35.289744896000059],
            [-80.82032223899995, 35.289811895000071],
            [-80.820466237999938, 35.289992896000058],
            [-80.820521238999959, 35.290038895000066],
            [-80.820640238999943, 35.29013489600004],
            [-80.820656238999959, 35.290156895000052],
            [-80.820738238999979, 35.290213895000079],
            [-80.820823238999935, 35.290301895000027],
            [-80.820899237999981, 35.290404896000041],
            [-80.820926238999959, 35.29046889500006],
            [-80.821007238999982, 35.290565895000043],
            [-80.821072237999942, 35.290600896000058],
            [-80.821127237999974, 35.290643895000073],
            [-80.821177238999951, 35.290722895000044],
            [-80.821215238999969, 35.290820895000024],
            [-80.821296238999935, 35.290869896000061],
            [-80.821313238999949, 35.290986896000049],
            [-80.82131523999999, 35.291006896000056],
            [-80.821291238999947, 35.29117889500003],
            [-80.821276238999985, 35.291231896000056],
            [-80.821247238999945, 35.291386895000073],
            [-80.82119223899997, 35.291578897000079],
            [-80.821183238999936, 35.291633897000054],
            [-80.82119223899997, 35.291739896000024],
            [-80.82120023899995, 35.291768896000065],
            [-80.821258238999974, 35.291886896000051],
            [-80.82128223899997, 35.291925896000066],
            [-80.821338238999942, 35.291996896000057],
            [-80.821405238999944, 35.292021896000051],
            [-80.821888237999985, 35.291381897000065],
            [-80.821987238999952, 35.291318896000064],
            [-80.822103238999944, 35.291253896000057],
            [-80.822413239999946, 35.29110989600008],
            [-80.822642238999947, 35.291015896000033],
            [-80.822913238999945, 35.290904896000029],
            [-80.823361238999951, 35.290703895000036],
            [-80.824732239999946, 35.290088896000043],
            [-80.824957239999947, 35.289992896000058],
            [-80.825413240999978, 35.289798896000036],
            [-80.825929239999937, 35.28958689600006],
            [-80.826196240999934, 35.289480896000043],
            [-80.826889239999957, 35.289158895000071],
            [-80.827601240999968, 35.288836895000031],
            [-80.827716240999962, 35.288770895000027],
            [-80.827798239999936, 35.288733894000075],
            [-80.82812224099996, 35.288613895000026],
            [-80.828284239999959, 35.288571895000075],
            [-80.828428239999937, 35.288543895000032],
            [-80.828596239999968, 35.28852889500007],
            [-80.828805240999941, 35.288527895000072],
            [-80.828916240999945, 35.288521895000031],
            [-80.830113240999935, 35.288534895000055],
            [-80.83076024199994, 35.288548895000076],
            [-80.831239241999981, 35.288565895000033],
            [-80.831374241999981, 35.288576894000073],
            [-80.831566240999962, 35.288600894000069],
            [-80.831758241999978, 35.288641895000069],
            [-80.831852241999968, 35.288668895000058],
            [-80.832039241999951, 35.288733894000075],
            [-80.832363241999985, 35.288879895000036],
            [-80.832495241999936, 35.288947895000035],
            [-80.832649241999945, 35.289034895000043],
            [-80.83275224199997, 35.289099895000049],
            [-80.832814241999984, 35.289145894000058],
            [-80.832875241999943, 35.289198895000027],
            [-80.83321024199995, 35.289551895000045],
            [-80.833283241999936, 35.289614895000057],
            [-80.833391241999948, 35.289690895000035],
            [-80.833510242999978, 35.289747895000062],
            [-80.833633241999962, 35.289786894000031],
            [-80.833872242999973, 35.289840895000054],
            [-80.83399024299996, 35.289850895000029],
            [-80.834105242999954, 35.289843895000047],
            [-80.834183242999984, 35.289828895000028],
            [-80.834314242999938, 35.289788895000072],
            [-80.83518724299995, 35.289437895000049],
            [-80.835446241999989, 35.289323895000052],
            [-80.835778242999936, 35.289176895000026],
            [-80.836322242999984, 35.288950895000028],
            [-80.836474242999941, 35.288895895000053],
            [-80.836548242999982, 35.288877895000041],
            [-80.836658242999988, 35.288877895000041],
            [-80.836720242999945, 35.289095895000059],
            [-80.837017243999981, 35.290042895000056],
            [-80.837087242999985, 35.290238895000073],
            [-80.837187243999949, 35.290499895000039],
            [-80.837371243999939, 35.290950895000037],
            [-80.837646242999938, 35.291582895000033],
            [-80.837845243999936, 35.292037895000078],
            [-80.838223243999948, 35.292899896000051],
            [-80.838256242999989, 35.292988896000054],
            [-80.838361243999941, 35.292980896000074],
            [-80.838707243999977, 35.292984895000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 17,
        "SolutionID": "PI-9",
        "Shape_Length": 0.33892727997000716,
        "Shape_Area": 0.0028604946759830433
      }
    },
    {
      "type": "Feature",
      "id": 18,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.863758245999975, 35.195603874000028],
            [-80.864139246999969, 35.195939874000032],
            [-80.864330245999952, 35.196087874000057],
            [-80.864390245999971, 35.196133874000054],
            [-80.865206246999946, 35.196829874000059],
            [-80.866091246999986, 35.197566874000074],
            [-80.866452246999984, 35.197862874000066],
            [-80.86674624699998, 35.198111874000062],
            [-80.866854247999981, 35.198196874000075],
            [-80.866928246999976, 35.198263874000077],
            [-80.867124247999982, 35.198421874000076],
            [-80.867823247999979, 35.198904875000039],
            [-80.868077246999974, 35.198661874000038],
            [-80.868198247999942, 35.198555875000068],
            [-80.868483247999961, 35.198317874000054],
            [-80.868839247999972, 35.197989875000076],
            [-80.869762248999962, 35.197137874000077],
            [-80.869448247999969, 35.196901874000048],
            [-80.86923724799999, 35.196751874000029],
            [-80.86916724799994, 35.196685873000035],
            [-80.869105246999936, 35.196582874000057],
            [-80.86861924699997, 35.195708874000047],
            [-80.86902024799997, 35.195566874000065],
            [-80.869706247999943, 35.195310874000029],
            [-80.869777248999981, 35.195280873000058],
            [-80.869887247999941, 35.195218874000034],
            [-80.869927247999954, 35.195180874000073],
            [-80.870193247999964, 35.194849874000056],
            [-80.870669247999956, 35.194350873000076],
            [-80.871085248999975, 35.193968874000063],
            [-80.871145247999948, 35.193907873000057],
            [-80.871561247999978, 35.194179873000053],
            [-80.872551249999958, 35.194810874000041],
            [-80.872813248999989, 35.194897873000059],
            [-80.872859248999987, 35.194859873000041],
            [-80.872884248999981, 35.194826874000057],
            [-80.872918248999952, 35.194781874000057],
            [-80.872955248999972, 35.194726874000025],
            [-80.873074248999956, 35.194511873000067],
            [-80.873119248999956, 35.194474873000047],
            [-80.87317724899998, 35.194451873000048],
            [-80.873241247999943, 35.194444873000066],
            [-80.873304248999943, 35.194455873000038],
            [-80.87447224999994, 35.194872873000065],
            [-80.874775249999971, 35.194973873000038],
            [-80.875134249999974, 35.195098874000053],
            [-80.87548524999994, 35.195221873000037],
            [-80.876259248999986, 35.195511874000033],
            [-80.876885249999987, 35.195736873000044],
            [-80.877218249999942, 35.195867873000054],
            [-80.87760824999998, 35.196001873000057],
            [-80.879058250999947, 35.196533873000078],
            [-80.879330251999988, 35.196629873000063],
            [-80.880813251999939, 35.197154874000034],
            [-80.881473251999978, 35.197392874000059],
            [-80.881736251999939, 35.197488873000054],
            [-80.882634251999946, 35.197816873000079],
            [-80.882803251999974, 35.197877874000028],
            [-80.883000251999988, 35.197455873000024],
            [-80.883564251999985, 35.196246873000064],
            [-80.883865251999964, 35.195642873000054],
            [-80.883923251999988, 35.195528874000047],
            [-80.884033252999984, 35.195285872000056],
            [-80.884166251999943, 35.194994873000041],
            [-80.884588251999958, 35.194092872000056],
            [-80.885050251999985, 35.19311887200007],
            [-80.885273251999934, 35.19262787200006],
            [-80.885554252999952, 35.192034872000079],
            [-80.885711252999954, 35.191681872000061],
            [-80.885976251999978, 35.191113872000074],
            [-80.886987252999972, 35.188991871000042],
            [-80.88736325299999, 35.18818587100003],
            [-80.887627253999938, 35.187590872000044],
            [-80.88774325299994, 35.187294871000063],
            [-80.887847252999961, 35.186972870000034],
            [-80.887950252999985, 35.186586870000042],
            [-80.887993252999934, 35.186392871000066],
            [-80.888034252999944, 35.186166871000069],
            [-80.888085252999986, 35.185786871000062],
            [-80.888131252999983, 35.185211871000035],
            [-80.888131253999973, 35.185195871000076],
            [-80.888136252999971, 35.184867870000062],
            [-80.888109253999971, 35.184518870000034],
            [-80.888075252999954, 35.184300870000072],
            [-80.888018252999984, 35.183939871000064],
            [-80.887959251999973, 35.183627870000066],
            [-80.887921252999945, 35.183472871000049],
            [-80.887839252999981, 35.183190870000033],
            [-80.887804251999967, 35.183057871000074],
            [-80.887742252999942, 35.182825870000045],
            [-80.887659252999981, 35.182539870000028],
            [-80.887611251999942, 35.182363871000064],
            [-80.887607252999942, 35.182351870000048],
            [-80.887397252999961, 35.181717870000057],
            [-80.887141251999935, 35.180902870000068],
            [-80.886999252999942, 35.180471870000076],
            [-80.886863252999945, 35.180024870000068],
            [-80.886711251999941, 35.179521870000031],
            [-80.886573252999938, 35.179121870000074],
            [-80.886473251999973, 35.178814869000064],
            [-80.886372252999934, 35.178448870000068],
            [-80.886311251999985, 35.178165869000054],
            [-80.88625725199995, 35.177781869000057],
            [-80.886229251999964, 35.177461869000069],
            [-80.886220251999987, 35.177023869000038],
            [-80.886235251999949, 35.176663869000038],
            [-80.886265250999941, 35.176341870000044],
            [-80.886291251999978, 35.176150868000036],
            [-80.886355251999987, 35.175787869000033],
            [-80.886396252999987, 35.175602868000055],
            [-80.886493252999969, 35.175234868000075],
            [-80.886612251999964, 35.174822869000025],
            [-80.886487251999938, 35.174635868000053],
            [-80.886568251999961, 35.17435486800008],
            [-80.887264251999966, 35.17195786800005],
            [-80.887307251999971, 35.171809867000036],
            [-80.887468251999962, 35.171252868000067],
            [-80.888248251999983, 35.168562866000059],
            [-80.888361251999982, 35.168172867000067],
            [-80.88862525199994, 35.167210867000051],
            [-80.888766251999982, 35.166726867000079],
            [-80.888829251999937, 35.166508867000061],
            [-80.888975251999966, 35.166053866000027],
            [-80.889111250999974, 35.165564866000068],
            [-80.889339251999957, 35.164789866000035],
            [-80.889517251999962, 35.164143866000074],
            [-80.88970225199995, 35.163524866000046],
            [-80.889852251999969, 35.163052866000044],
            [-80.889716251999971, 35.163022866000063],
            [-80.889362252999945, 35.162944866000032],
            [-80.88903725199998, 35.162893866000047],
            [-80.888786251999989, 35.162879866000026],
            [-80.88867025199994, 35.162876866000033],
            [-80.888522251999973, 35.162878866000028],
            [-80.888356251999937, 35.162877866000031],
            [-80.88811025199999, 35.162909866000064],
            [-80.88785225099997, 35.162956866000059],
            [-80.887464251999972, 35.163040867000063],
            [-80.887361250999959, 35.163077866000037],
            [-80.887201251999954, 35.16311786600005],
            [-80.887073250999947, 35.16315486600007],
            [-80.886868250999953, 35.162642866000056],
            [-80.88685725199997, 35.162582865000047],
            [-80.886867251999945, 35.162521866000077],
            [-80.886880251999969, 35.162492867000026],
            [-80.886920251999982, 35.162440865000065],
            [-80.886946250999983, 35.162418866000053],
            [-80.887276251999936, 35.162188865000076],
            [-80.887372251999977, 35.162117866000074],
            [-80.88751825099996, 35.161994866000043],
            [-80.887541251999949, 35.161969867000039],
            [-80.887613251999937, 35.161889866000024],
            [-80.887690250999981, 35.16177486600003],
            [-80.887888251999982, 35.161418866000076],
            [-80.888088251999989, 35.16112486600008],
            [-80.888133251999989, 35.161047866000047],
            [-80.888173251999945, 35.160968866000076],
            [-80.88820825199997, 35.160887865000063],
            [-80.888238250999962, 35.160803865000048],
            [-80.888258251999957, 35.160730866000051],
            [-80.888280251999959, 35.160582865000038],
            [-80.888282251999954, 35.160432865000075],
            [-80.888253250999981, 35.160004865000076],
            [-80.888260250999963, 35.159854866000046],
            [-80.888288250999949, 35.159706865000032],
            [-80.888322251999966, 35.15960186500007],
            [-80.888160251999977, 35.159571866000078],
            [-80.887949251999942, 35.159547866000025],
            [-80.887842250999938, 35.159541865000051],
            [-80.886292250999986, 35.159543865000046],
            [-80.886668251999936, 35.157767865000039],
            [-80.886723251999967, 35.157553865000068],
            [-80.886746250999977, 35.157484865000072],
            [-80.88677525099996, 35.157417864000024],
            [-80.886809250999988, 35.157352865000064],
            [-80.886892250999949, 35.157227865000038],
            [-80.887016250999977, 35.157074865000027],
            [-80.88733425099997, 35.156644865000032],
            [-80.887690250999981, 35.156233865000047],
            [-80.888241250999954, 35.155548864000025],
            [-80.888324251999961, 35.155471864000049],
            [-80.888371251999956, 35.155437865000067],
            [-80.888450250999938, 35.155392864000078],
            [-80.888512250999952, 35.155364864000035],
            [-80.888643250999962, 35.15532186400003],
            [-80.888699251999981, 35.155308864000062],
            [-80.888958251999952, 35.15526886400005],
            [-80.889476251999952, 35.15522786400004],
            [-80.890254251999977, 35.155103865000058],
            [-80.890222251999944, 35.154984864000028],
            [-80.890240251999955, 35.154871864000029],
            [-80.890230251999981, 35.154770864000056],
            [-80.890199251999945, 35.15455886500007],
            [-80.890181251999934, 35.154466863000039],
            [-80.890146251999965, 35.154399865000073],
            [-80.890129251999952, 35.154283864000035],
            [-80.890120251999974, 35.154143864000048],
            [-80.890122252999959, 35.154069865000054],
            [-80.89013625299998, 35.153996864000078],
            [-80.890162251999982, 35.153950864000024],
            [-80.890166250999982, 35.15390986400007],
            [-80.890178251999941, 35.15388086400003],
            [-80.890198251999948, 35.153784864000045],
            [-80.890230251999981, 35.153675864000036],
            [-80.890232251999976, 35.153632864000031],
            [-80.890275251999981, 35.153481864000071],
            [-80.890290251999943, 35.153368865000061],
            [-80.890312251999944, 35.153274864000025],
            [-80.890356251999947, 35.153178863000051],
            [-80.890362251999989, 35.153141864000077],
            [-80.890330251999956, 35.153069863000042],
            [-80.890379250999956, 35.152983863000031],
            [-80.890394251999965, 35.152824863000035],
            [-80.889219250999986, 35.153000864000035],
            [-80.889162251999949, 35.152589864000049],
            [-80.88908225199998, 35.152070863000063],
            [-80.889018250999982, 35.151643863000061],
            [-80.889019250999979, 35.151503863000073],
            [-80.889060250999989, 35.151313864000031],
            [-80.889117251999949, 35.15101786300005],
            [-80.88912725199998, 35.150902863000056],
            [-80.889125251999985, 35.150799863000032],
            [-80.889096250999955, 35.150688864000074],
            [-80.889050251999947, 35.150567863000049],
            [-80.888973250999982, 35.150439863000031],
            [-80.888824251999949, 35.150276863000045],
            [-80.888718250999943, 35.150163863000046],
            [-80.888672250999946, 35.150085863000072],
            [-80.88864425099996, 35.150000863000059],
            [-80.888627250999946, 35.149856864000071],
            [-80.888626250999948, 35.149792863000073],
            [-80.888602250999952, 35.149685863000059],
            [-80.888529250999966, 35.149542863000079],
            [-80.888488250999956, 35.149487863000047],
            [-80.888556250999955, 35.149372863000053],
            [-80.88860325099995, 35.149324863000061],
            [-80.888633250999987, 35.149256862000072],
            [-80.888774251999962, 35.14901386300005],
            [-80.888855251999985, 35.148893863000069],
            [-80.888888250999969, 35.148847863000071],
            [-80.888991251999983, 35.14875186200004],
            [-80.88900825099995, 35.148700863000045],
            [-80.889030250999951, 35.148673862000066],
            [-80.889087251999968, 35.14864986300006],
            [-80.889138250999963, 35.14864986300006],
            [-80.889176250999981, 35.148618863000024],
            [-80.889233250999951, 35.148544863000041],
            [-80.889247250999972, 35.148497862000056],
            [-80.889250250999964, 35.148348863000024],
            [-80.88923325199994, 35.148232862000043],
            [-80.88913925199995, 35.148064863000059],
            [-80.889114250999967, 35.148009862000038],
            [-80.889080250999939, 35.147909863000052],
            [-80.889005250999958, 35.14756886300006],
            [-80.888966250999943, 35.147328862000052],
            [-80.888883250999982, 35.147011863000046],
            [-80.888873251999939, 35.146951863000027],
            [-80.888780250999957, 35.146685863000073],
            [-80.888740249999955, 35.146540862000052],
            [-80.888708250999969, 35.146421863000057],
            [-80.888638250999975, 35.14618786300008],
            [-80.888612250999984, 35.146084862000066],
            [-80.888594250999972, 35.145906862000061],
            [-80.888575250999963, 35.145835862000069],
            [-80.888543251999977, 35.145757862000039],
            [-80.888517250999939, 35.145655862000069],
            [-80.888476250999986, 35.14555886200003],
            [-80.888440250999963, 35.145451863000062],
            [-80.88841125099998, 35.14531786200007],
            [-80.888408250999987, 35.145246862000079],
            [-80.888417250999964, 35.145218863000025],
            [-80.888480250999976, 35.145164862000058],
            [-80.888507249999975, 35.145120863000045],
            [-80.888516250999942, 35.145089862000077],
            [-80.888473250999937, 35.144948862000035],
            [-80.888468250999949, 35.144666862000065],
            [-80.888551250999967, 35.144370862000073],
            [-80.888609250999934, 35.143893862000027],
            [-80.888641250999967, 35.143722861000072],
            [-80.888637250999977, 35.143661861000055],
            [-80.888641251999957, 35.143588862000058],
            [-80.888662250999971, 35.143426862000069],
            [-80.88873425099996, 35.143220861000032],
            [-80.888747250999984, 35.143119861000059],
            [-80.888678250999988, 35.143013862000032],
            [-80.888590249999936, 35.142903862000026],
            [-80.888557250999952, 35.142760862000046],
            [-80.88854624999999, 35.142712862000053],
            [-80.888528249999979, 35.14235786100005],
            [-80.888498250999987, 35.142253862000075],
            [-80.888441250999961, 35.142043861000047],
            [-80.888381250999942, 35.141901862000054],
            [-80.888246250999941, 35.141654861000063],
            [-80.888332249999962, 35.141656862000048],
            [-80.888366250999979, 35.141664862000027],
            [-80.888436249999984, 35.141688862000024],
            [-80.888487250999958, 35.14171286100003],
            [-80.888523250999981, 35.141734861000032],
            [-80.888571250999973, 35.141763861000072],
            [-80.888674250999941, 35.141844861000038],
            [-80.888741250999942, 35.141879862000053],
            [-80.888818250999975, 35.141897861000075],
            [-80.888897250999946, 35.14189986100007],
            [-80.889074250999954, 35.141872861000024],
            [-80.889261250999937, 35.141833861000066],
            [-80.889496251999958, 35.141772862000039],
            [-80.889670250999984, 35.141716861000077],
            [-80.889753250999945, 35.141682862000039],
            [-80.889812250999967, 35.141653861000066],
            [-80.889922250999973, 35.141584861000069],
            [-80.889971250999963, 35.141545862000044],
            [-80.890106250999963, 35.141414862000033],
            [-80.890230251999981, 35.141293861000065],
            [-80.890419250999969, 35.141125861000035],
            [-80.890695250999954, 35.140921861000038],
            [-80.891022250999981, 35.140703861000077],
            [-80.891122250999956, 35.14065486100003],
            [-80.891245251999976, 35.140615862000061],
            [-80.891388251999956, 35.140588861000026],
            [-80.891582251999978, 35.140572861000066],
            [-80.89177725199994, 35.140572861000066],
            [-80.892375250999976, 35.140620861000059],
            [-80.892765251999947, 35.140643861000058],
            [-80.892870251999966, 35.14063886100007],
            [-80.894181251999953, 35.140363861000026],
            [-80.89380625299998, 35.139523861000043],
            [-80.89377325199996, 35.139438861000031],
            [-80.893691252999986, 35.139273861000049],
            [-80.893643251999947, 35.139193860000034],
            [-80.893533251999941, 35.139040861000069],
            [-80.893512251999937, 35.139016861000073],
            [-80.89340525099999, 35.138896861000035],
            [-80.892886251999983, 35.138426861000028],
            [-80.892597251999973, 35.138129861000039],
            [-80.892548250999937, 35.138072861000069],
            [-80.892465251999965, 35.13796786000006],
            [-80.892405250999957, 35.137883861000034],
            [-80.892350251999972, 35.137797860000035],
            [-80.892300250999938, 35.137709861000076],
            [-80.892179250999959, 35.137466860000075],
            [-80.892149251999967, 35.137402860000066],
            [-80.892121250999935, 35.137339861000044],
            [-80.892104251999967, 35.137287860000072],
            [-80.892049251999936, 35.137151860000074],
            [-80.891966251999975, 35.13689086100004],
            [-80.891916251999987, 35.136636860000067],
            [-80.891893251999988, 35.136405859000035],
            [-80.891863250999961, 35.135857861000034],
            [-80.891851251999981, 35.135546860000034],
            [-80.891828250999936, 35.135153861000049],
            [-80.891823250999948, 35.134585860000072],
            [-80.891811251999968, 35.134180860000072],
            [-80.891773250999961, 35.133494860000042],
            [-80.891773250999961, 35.133160859000043],
            [-80.891765249999935, 35.132752860000039],
            [-80.891754250999952, 35.132499860000053],
            [-80.89174725099997, 35.132079859000044],
            [-80.891748250999967, 35.131750859000078],
            [-80.891752251999947, 35.131647859000054],
            [-80.891763250999986, 35.131387860000075],
            [-80.891778250999948, 35.131200859000046],
            [-80.89178825099998, 35.131146859000069],
            [-80.891842250999957, 35.130928859000051],
            [-80.892203251999945, 35.129983859000049],
            [-80.892499251999936, 35.129242859000044],
            [-80.892621249999934, 35.128969859000051],
            [-80.892733251999971, 35.128721858000063],
            [-80.892782250999971, 35.128623858000026],
            [-80.892896251999957, 35.128432858000053],
            [-80.893072250999978, 35.128191859000026],
            [-80.893173250999951, 35.128083858000025],
            [-80.893284250999955, 35.12799185800003],
            [-80.89342125099995, 35.127902858000027],
            [-80.893721250999988, 35.127730858000064],
            [-80.893810250999934, 35.12768685900005],
            [-80.893996251999965, 35.127610858000025],
            [-80.894179251999958, 35.127552858000058],
            [-80.894410251999943, 35.127497858000027],
            [-80.894646251999973, 35.127459858000066],
            [-80.894988251999962, 35.127415858000063],
            [-80.895381251999936, 35.127354858000047],
            [-80.895692251999947, 35.127295859000071],
            [-80.895965251999939, 35.127228858000024],
            [-80.896185252999942, 35.127160858000025],
            [-80.896446251999976, 35.12706085800005],
            [-80.897311251999952, 35.126675857000066],
            [-80.898427252999966, 35.12617485800007],
            [-80.899603252999952, 35.125653857000032],
            [-80.899699252999937, 35.125602858000036],
            [-80.899817252999981, 35.125528857000063],
            [-80.899918252999953, 35.125455858000066],
            [-80.900008253999943, 35.125378858000033],
            [-80.900101252999946, 35.125287857000046],
            [-80.900213252999947, 35.125169858000049],
            [-80.90098425399998, 35.124253858000031],
            [-80.901361253999937, 35.123815857000068],
            [-80.901607253999941, 35.123536856000044],
            [-80.901846253999963, 35.123235858000044],
            [-80.90195625399997, 35.123090857000079],
            [-80.902182253999968, 35.122763857000052],
            [-80.90232025399996, 35.122536857000057],
            [-80.902486253999939, 35.122234856000034],
            [-80.902668253999934, 35.121853857000076],
            [-80.902722253999968, 35.121738857000025],
            [-80.902875254999969, 35.121439857000041],
            [-80.903151253999965, 35.120889857000066],
            [-80.903710253999975, 35.119740856000078],
            [-80.903964253999959, 35.119290856000077],
            [-80.904172253999945, 35.118976856000074],
            [-80.90432425299997, 35.118786856000042],
            [-80.904456254999957, 35.11864885600005],
            [-80.903133253999954, 35.11729785600005],
            [-80.901687253999967, 35.115788856000052],
            [-80.901154253999948, 35.115245855000069],
            [-80.900568251999971, 35.114623855000048],
            [-80.898981251999942, 35.11300585500004],
            [-80.897564251999938, 35.111530855000069],
            [-80.896719250999979, 35.110666855000034],
            [-80.896162250999964, 35.110071854000068],
            [-80.89564625099996, 35.109545855000079],
            [-80.895911251999962, 35.109370855000066],
            [-80.896274250999966, 35.109132854000052],
            [-80.89652425099996, 35.10896785400007],
            [-80.896080250999944, 35.108459853000056],
            [-80.895928250999987, 35.108299854000052],
            [-80.895823250999968, 35.108189854000045],
            [-80.895800250999969, 35.108110854000074],
            [-80.895791250999935, 35.108077854000044],
            [-80.895847250999964, 35.107931855000061],
            [-80.896057249999956, 35.107710854000061],
            [-80.896158250999974, 35.107494854000038],
            [-80.896228250999968, 35.10734685400007],
            [-80.896298250999962, 35.107184854000025],
            [-80.896356251999975, 35.107049854000024],
            [-80.896532251999986, 35.106832853000071],
            [-80.89680225099994, 35.106643854000026],
            [-80.896938250999938, 35.106609853000066],
            [-80.897277251999981, 35.106504854000036],
            [-80.897299251999982, 35.106495853000069],
            [-80.897434251999982, 35.106428854000058],
            [-80.897667251999962, 35.106098854000038],
            [-80.897724251999989, 35.106009853000046],
            [-80.897801251999965, 35.105891854000049],
            [-80.897974251999983, 35.105622853000057],
            [-80.898053251999954, 35.105362853000031],
            [-80.898115251999968, 35.105160854000076],
            [-80.898045251999974, 35.10485985300005],
            [-80.897984251999958, 35.104764853000063],
            [-80.897923250999952, 35.104688853000027],
            [-80.897884251999983, 35.104475854000043],
            [-80.897989251999945, 35.104365853000047],
            [-80.898109251999983, 35.10426685300007],
            [-80.898391251999954, 35.104177853000067],
            [-80.898761252999975, 35.104034852000041],
            [-80.899034251999979, 35.103792853000073],
            [-80.899129251999966, 35.103634853000074],
            [-80.899160250999955, 35.103497853000079],
            [-80.899148251999975, 35.103396853000049],
            [-80.899202250999963, 35.103230853000071],
            [-80.899321251999936, 35.103063852000048],
            [-80.899476251999943, 35.10296085300007],
            [-80.899598251999976, 35.102970853000045],
            [-80.89970225199994, 35.102997853000034],
            [-80.899845251999977, 35.103059852000058],
            [-80.90003225199996, 35.103052853000065],
            [-80.900344250999979, 35.103011853000055],
            [-80.901030251999941, 35.10319385300005],
            [-80.901059252999971, 35.103193852000061],
            [-80.90109825199994, 35.103186853000068],
            [-80.901235251999935, 35.103162852000025],
            [-80.901378251999972, 35.103104853000048],
            [-80.901424252999959, 35.103086853000036],
            [-80.901677252999946, 35.103010853000058],
            [-80.901723252999943, 35.102947853000046],
            [-80.901786251999965, 35.102859853000041],
            [-80.901773251999941, 35.102698853000049],
            [-80.901755251999987, 35.102545853000038],
            [-80.901847252999971, 35.102298852000047],
            [-80.901943252999956, 35.102228852000053],
            [-80.902015251999956, 35.102130853000062],
            [-80.902002251999988, 35.102002853000045],
            [-80.901936252999974, 35.10190685200007],
            [-80.901975252999989, 35.101736852000045],
            [-80.901918252999963, 35.101455853000061],
            [-80.901902252999946, 35.101399852000043],
            [-80.901850251999974, 35.101316852000025],
            [-80.901757252999971, 35.101198852000039],
            [-80.901292252999951, 35.100838852000038],
            [-80.901155251999967, 35.100737852000066],
            [-80.901088251999965, 35.100689852000073],
            [-80.900948251999978, 35.100676853000039],
            [-80.900796251999964, 35.100755851000031],
            [-80.900637251999967, 35.100757852000072],
            [-80.900246251999988, 35.100790852000046],
            [-80.900012251999954, 35.100771853000026],
            [-80.899790251999946, 35.100847853000062],
            [-80.899552251999978, 35.10086685300007],
            [-80.899341251999942, 35.100839852000036],
            [-80.899268252999946, 35.10078885300004],
            [-80.899222251999959, 35.100707852000028],
            [-80.899235251999983, 35.100662852000028],
            [-80.899239251999973, 35.100641852000024],
            [-80.899245251999957, 35.100531852000074],
            [-80.899248252999939, 35.10051085200007],
            [-80.899347251999984, 35.100107853000054],
            [-80.899336250999966, 35.099896852000029],
            [-80.89918725199999, 35.099750852000057],
            [-80.898839251999959, 35.099675852000075],
            [-80.898511250999945, 35.09955085200005],
            [-80.898289250999937, 35.099285852000037],
            [-80.898233251999955, 35.099118852000061],
            [-80.898270250999985, 35.098743852000041],
            [-80.898344251999958, 35.09862785200005],
            [-80.898482250999962, 35.098577851000073],
            [-80.898844250999957, 35.098581852000052],
            [-80.899148251999975, 35.098609852000038],
            [-80.899316250999959, 35.098540852000042],
            [-80.899637251999934, 35.098300852000079],
            [-80.900006251999969, 35.098107852000055],
            [-80.900223251999989, 35.097902851000072],
            [-80.90035125199995, 35.097632852000061],
            [-80.900400252999987, 35.097468851000031],
            [-80.900554251999949, 35.097350852000034],
            [-80.901156252999954, 35.096585851000043],
            [-80.901245251999967, 35.096428851000042],
            [-80.90127025299995, 35.096246852000036],
            [-80.901265251999973, 35.09606885200003],
            [-80.901117251999949, 35.095946851000065],
            [-80.900776251999957, 35.095597851000036],
            [-80.90055025199996, 35.095427851000068],
            [-80.900481250999974, 35.095420851000029],
            [-80.900439251999956, 35.095415851000041],
            [-80.900021251999988, 35.095493851000072],
            [-80.899677251999947, 35.095508851000034],
            [-80.899209251999935, 35.095468851000078],
            [-80.899034251999979, 35.095360851000066],
            [-80.898807250999937, 35.095220850000032],
            [-80.898668249999957, 35.095094852000045],
            [-80.89831725099998, 35.09477785100006],
            [-80.898123249999969, 35.09465085100004],
            [-80.89804325099999, 35.094484851000061],
            [-80.898020251999981, 35.094384851000029],
            [-80.897978250999984, 35.094202852000024],
            [-80.897993250999946, 35.093857851000053],
            [-80.898015250999947, 35.093530850000036],
            [-80.89804325099999, 35.093232851000039],
            [-80.897994250999943, 35.093128851000074],
            [-80.897994250999943, 35.092864851000058],
            [-80.898030250999966, 35.09265785100007],
            [-80.898131250999938, 35.092507850000061],
            [-80.89819825099994, 35.092386851000072],
            [-80.898233250999965, 35.092112850000035],
            [-80.898357249999947, 35.09182885000007],
            [-80.898877250999988, 35.091225850000058],
            [-80.89916225099995, 35.090937851000035],
            [-80.899366251999936, 35.09066585100004],
            [-80.89794225199995, 35.089882850000038],
            [-80.897483250999983, 35.089638850000028],
            [-80.89696024999995, 35.089351850000071],
            [-80.896063249999941, 35.088842850000049],
            [-80.895648248999976, 35.088601850000032],
            [-80.89538024999996, 35.088454849000072],
            [-80.894879249999974, 35.088166849000061],
            [-80.894133248999935, 35.087761850000049],
            [-80.893753248999985, 35.087557849000063],
            [-80.893470248999961, 35.087418850000063],
            [-80.893246248999958, 35.087299849000033],
            [-80.892982248999942, 35.08714585000007],
            [-80.892859248999969, 35.087067850000039],
            [-80.892675248999979, 35.086933849000047],
            [-80.892476248999969, 35.086774849000051],
            [-80.892306248999944, 35.086633850000055],
            [-80.892084247999946, 35.086445849000029],
            [-80.89195824799998, 35.086339850000059],
            [-80.891506248999974, 35.085981849000063],
            [-80.891398247999973, 35.085901849000038],
            [-80.891360248999945, 35.085877849000042],
            [-80.891398248999963, 35.08584785000005],
            [-80.892214248999949, 35.085383850000028],
            [-80.893638248999935, 35.084759850000069],
            [-80.895041249999963, 35.084371849000036],
            [-80.895067249999954, 35.084363849000056],
            [-80.899971251999943, 35.082732849000024],
            [-80.900326250999967, 35.082615848000046],
            [-80.90133425199997, 35.082199848000073],
            [-80.902039250999962, 35.081671848000042],
            [-80.902599251999959, 35.081031848000066],
            [-80.903261251999936, 35.080210848000036],
            [-80.904391251999982, 35.078807848000054],
            [-80.904522251999936, 35.078621847000079],
            [-80.906068252999944, 35.07642684700005],
            [-80.905962251999938, 35.076308847000064],
            [-80.905646252999986, 35.075957847000041],
            [-80.905542251999975, 35.07584184600006],
            [-80.905541251999978, 35.075840847000052],
            [-80.90406625199995, 35.074200846000053],
            [-80.899642249999943, 35.069280846000026],
            [-80.898168249999969, 35.067640846000074],
            [-80.898166249999974, 35.067640846000074],
            [-80.898050248999937, 35.067663845000027],
            [-80.897910249999939, 35.067674846000045],
            [-80.897774248999951, 35.067673845000058],
            [-80.897637249999946, 35.067657846000031],
            [-80.897542248999969, 35.067621846000065],
            [-80.897385249999957, 35.067580846000055],
            [-80.89732424999994, 35.067559846000051],
            [-80.897113248999972, 35.067425846000049],
            [-80.897021249999966, 35.067386845000044],
            [-80.896913247999976, 35.067332845000067],
            [-80.896856248999939, 35.067295845000046],
            [-80.896744248999937, 35.067194845000074],
            [-80.896665248999966, 35.067074846000025],
            [-80.896646248999957, 35.066766844000028],
            [-80.896631248999938, 35.066607845000078],
            [-80.896589248999987, 35.066580845000033],
            [-80.896524248999981, 35.066556845000036],
            [-80.896444248999956, 35.066542845000072],
            [-80.896300248999978, 35.06652884600004],
            [-80.896213247999981, 35.066530845000045],
            [-80.896130247999963, 35.066546846000051],
            [-80.895923248999964, 35.066631844000028],
            [-80.895837249999943, 35.066679845000067],
            [-80.895737248999978, 35.066749845000061],
            [-80.895651248999968, 35.066828845000032],
            [-80.895606248999968, 35.066987845000028],
            [-80.895604248999973, 35.067027845000041],
            [-80.895627248999972, 35.067060845000071],
            [-80.895653248999963, 35.067088845000058],
            [-80.895969248999961, 35.067278845000033],
            [-80.896049248999987, 35.06732084600003],
            [-80.896173247999968, 35.067424845000062],
            [-80.896254248999981, 35.067470845000059],
            [-80.896367248999979, 35.067519845000049],
            [-80.896606248999944, 35.06778584500006],
            [-80.896754248999969, 35.067963845000065],
            [-80.896852247999959, 35.068111845000033],
            [-80.896961249999947, 35.068297845000075],
            [-80.897091248999971, 35.068497846000071],
            [-80.897170249999988, 35.068627846000027],
            [-80.897260248999942, 35.068759845000045],
            [-80.897344248999957, 35.068951845000072],
            [-80.897405249999963, 35.069112846000053],
            [-80.897432249999952, 35.069298846000038],
            [-80.897423248999985, 35.069426846000056],
            [-80.897417248999943, 35.069644846000074],
            [-80.897364249999953, 35.069963846000064],
            [-80.897285248999935, 35.070154846000037],
            [-80.897244249999972, 35.070303846000058],
            [-80.897265249999975, 35.070581846000039],
            [-80.897261248999939, 35.070628846000034],
            [-80.897217249999983, 35.070682846000068],
            [-80.897130248999986, 35.070702847000064],
            [-80.897055248999948, 35.070700846000079],
            [-80.896972249999976, 35.070698847000074],
            [-80.896880248999935, 35.07068184600007],
            [-80.896524248999981, 35.070668846000046],
            [-80.896320248999984, 35.070690846000048],
            [-80.895946248999962, 35.070745845000033],
            [-80.895603248999976, 35.070801845000062],
            [-80.89547024899997, 35.070818847000055],
            [-80.895346248999942, 35.070822846000056],
            [-80.895266248999974, 35.070825846000048],
            [-80.895125248999989, 35.070818846000066],
            [-80.894996248999973, 35.070788846000028],
            [-80.894813248999981, 35.070732846000055],
            [-80.894715248999944, 35.070749846000069],
            [-80.894450248999988, 35.070766846000026],
            [-80.894235248999962, 35.070740846000035],
            [-80.894099248999964, 35.070662846000062],
            [-80.894033248999961, 35.070620846000054],
            [-80.894006247999982, 35.070598846000053],
            [-80.893874247999975, 35.07042384600004],
            [-80.893721248999952, 35.07029684500003],
            [-80.893702248999944, 35.070229846000075],
            [-80.893674247999968, 35.070171846000051],
            [-80.893608247999964, 35.069973846000039],
            [-80.893586247999963, 35.069928845000049],
            [-80.893585248999955, 35.069730846000027],
            [-80.893499246999966, 35.069466846000068],
            [-80.893355247999978, 35.069151846000068],
            [-80.893291248999958, 35.06899084500003],
            [-80.893221248999964, 35.068922846000078],
            [-80.893141248999939, 35.068883846000062],
            [-80.893049246999965, 35.06884784600004],
            [-80.893003248999946, 35.068838845000073],
            [-80.892882247999978, 35.068837846000065],
            [-80.892678247999982, 35.068862846000059],
            [-80.892547247999971, 35.068920846000026],
            [-80.892392247999965, 35.068957845000057],
            [-80.892286247999948, 35.068977845000063],
            [-80.892212247999964, 35.069032845000038],
            [-80.892179247999934, 35.069091845000059],
            [-80.892142247999971, 35.069139846000041],
            [-80.892014247999953, 35.06916284600004],
            [-80.891923246999966, 35.069170845000031],
            [-80.891775247999988, 35.069147846000078],
            [-80.891654247999952, 35.069143846000031],
            [-80.891332247999969, 35.069145846000026],
            [-80.891244247999964, 35.069151846000068],
            [-80.891176248999955, 35.069156846000055],
            [-80.891139246999956, 35.069170846000077],
            [-80.891067247999956, 35.069187845000044],
            [-80.890954247999957, 35.069195846000071],
            [-80.890744247999976, 35.069142846000034],
            [-80.890615247999961, 35.069103846000075],
            [-80.890318246999982, 35.06902384600005],
            [-80.890212247999955, 35.06897884600005],
            [-80.890148246999956, 35.068942846000027],
            [-80.890051246999974, 35.068865846000051],
            [-80.890014246999954, 35.068831845000034],
            [-80.889923247999945, 35.068680845000074],
            [-80.889878246999956, 35.06846884600003],
            [-80.889875246999964, 35.067926846000034],
            [-80.889867246999984, 35.067854846000046],
            [-80.889840246999938, 35.067739845000062],
            [-80.88979724699999, 35.067633846000035],
            [-80.88973324799997, 35.067563845000052],
            [-80.889637246999939, 35.067522846000031],
            [-80.88943024799994, 35.067456845000038],
            [-80.889000245999966, 35.067346846000078],
            [-80.888830246999987, 35.067273845000045],
            [-80.888773246999961, 35.067281846000071],
            [-80.888699246999977, 35.067339846000039],
            [-80.888613246999967, 35.067377846000056],
            [-80.888583245999939, 35.067404846000045],
            [-80.888452245999986, 35.067629846000045],
            [-80.88833124699994, 35.067751846000078],
            [-80.88826624699999, 35.06782684500007],
            [-80.888141246999965, 35.068031845000064],
            [-80.888073246999966, 35.068171846000041],
            [-80.888037245999953, 35.068268845000034],
            [-80.888021245999937, 35.068357846000026],
            [-80.888017246999937, 35.068443846000037],
            [-80.888026246999971, 35.068662846000052],
            [-80.888014246999944, 35.06874584600007],
            [-80.888011246999952, 35.06880284600004],
            [-80.888005246999967, 35.068829846000028],
            [-80.888018245999945, 35.068860845000074],
            [-80.88809024699998, 35.068971846000068],
            [-80.888147246999949, 35.06908784500007],
            [-80.888211246999958, 35.069189846000029],
            [-80.888354246999938, 35.069589846000042],
            [-80.888482246999956, 35.069875847000048],
            [-80.888504246999958, 35.069932846000029],
            [-80.888577246999944, 35.070014846000049],
            [-80.888714246999939, 35.070147846000054],
            [-80.888828246999935, 35.07028084600006],
            [-80.888926246999972, 35.07037484600005],
            [-80.88901724699997, 35.070469845000048],
            [-80.889114245999963, 35.070544847000065],
            [-80.889240247999965, 35.070622847000038],
            [-80.889365246999944, 35.07068984600005],
            [-80.889489246999972, 35.07078784600003],
            [-80.889632246999952, 35.070935846000054],
            [-80.88971924699996, 35.071067846000062],
            [-80.889811247999944, 35.071217846000025],
            [-80.88989524699997, 35.071322847000033],
            [-80.889957246999984, 35.071448846000067],
            [-80.890008246999969, 35.071573847000025],
            [-80.890019247999987, 35.071597847000078],
            [-80.890053246999969, 35.071669847000067],
            [-80.890077247999955, 35.071748846000048],
            [-80.89008724699994, 35.071825846000024],
            [-80.890082247999942, 35.071863846000042],
            [-80.890065246999939, 35.071909847000029],
            [-80.890039247999937, 35.071952846000045],
            [-80.89003024699997, 35.071992846000057],
            [-80.890046246999987, 35.072234847000061],
            [-80.890037247999942, 35.072374847000049],
            [-80.889995246999945, 35.072567847000073],
            [-80.889997247999986, 35.072673846000043],
            [-80.890010246999964, 35.072795846000076],
            [-80.890011246999961, 35.07291084600007],
            [-80.890047246999984, 35.072995846000026],
            [-80.890111247999982, 35.073047847000055],
            [-80.890155247999985, 35.073071847000051],
            [-80.890253247999965, 35.073101847000032],
            [-80.890344246999973, 35.073122846000047],
            [-80.890768246999983, 35.073108847000071],
            [-80.890870247999942, 35.073120847000041],
            [-80.890947247999975, 35.073142847000042],
            [-80.891151247999971, 35.073191847000032],
            [-80.891245246999972, 35.073191847000032],
            [-80.891499247999946, 35.073127846000034],
            [-80.891600247999975, 35.073119847000044],
            [-80.89169624799996, 35.073129847000075],
            [-80.891720248999945, 35.073132846000078],
            [-80.891763246999972, 35.073151847000076],
            [-80.891816247999941, 35.073150847000079],
            [-80.891973247999942, 35.073108847000071],
            [-80.892058247999955, 35.07309884700004],
            [-80.892165248999959, 35.073076847000038],
            [-80.892246247999935, 35.073052847000042],
            [-80.892332247999946, 35.073002847000055],
            [-80.892385248999972, 35.072951846000024],
            [-80.892468247999943, 35.072850846000051],
            [-80.892557247999946, 35.072760846000051],
            [-80.892741247999936, 35.072545847000072],
            [-80.892822248999948, 35.072462846000064],
            [-80.892899247999935, 35.072440846000063],
            [-80.893011247999937, 35.072459846000072],
            [-80.893087248999962, 35.072487846000058],
            [-80.893169247999936, 35.072525846000076],
            [-80.893337248999956, 35.072713846000056],
            [-80.893480248999936, 35.072897847000036],
            [-80.893478247999951, 35.073011846000043],
            [-80.893533248999972, 35.073032846000046],
            [-80.893627248999962, 35.07308684700007],
            [-80.893645248999974, 35.073103846000038],
            [-80.893645248999974, 35.073123846000044],
            [-80.893589248999945, 35.073153847000071],
            [-80.893563247999964, 35.073171847000026],
            [-80.893553247999989, 35.073198847000072],
            [-80.893559248999964, 35.073243847000072],
            [-80.893563248999953, 35.07333884600007],
            [-80.893558247999977, 35.073378846000026],
            [-80.893525248999936, 35.07344084600004],
            [-80.893458248999934, 35.073545847000048],
            [-80.893431248999946, 35.073595846000046],
            [-80.893265247999977, 35.073718847000066],
            [-80.893209247999948, 35.073729847000038],
            [-80.893168247999938, 35.073730847000036],
            [-80.893090247999965, 35.073754847000032],
            [-80.893009248999988, 35.073789847000057],
            [-80.892935247999958, 35.073812847000056],
            [-80.89280324799995, 35.07382984700007],
            [-80.892649248999987, 35.073839847000045],
            [-80.892510247999951, 35.073870846000034],
            [-80.892369247999966, 35.07391584700008],
            [-80.892277247999971, 35.073952847000044],
            [-80.892185247999976, 35.074015847000055],
            [-80.892166247999967, 35.074031847000072],
            [-80.892155247999938, 35.074051847000078],
            [-80.892151247999948, 35.074132847000044],
            [-80.892186248999963, 35.074257847000069],
            [-80.89221024699998, 35.074406847000034],
            [-80.892212247999964, 35.074447846000055],
            [-80.892194247999953, 35.074589847000027],
            [-80.892186248999963, 35.074615847000075],
            [-80.892186247999973, 35.074695847000044],
            [-80.89215724799999, 35.074739847000046],
            [-80.892149248999942, 35.074815847000025],
            [-80.89215024899994, 35.074876847000041],
            [-80.892162247999977, 35.074943847000043],
            [-80.892174247999947, 35.074963847000049],
            [-80.892205247999982, 35.075162848000048],
            [-80.892214247999959, 35.075436847000049],
            [-80.892192247999958, 35.075566847000061],
            [-80.892189248999955, 35.075666848000026],
            [-80.892203248999976, 35.075768848000052],
            [-80.892227247999983, 35.075832847000072],
            [-80.892230247999976, 35.075879847000067],
            [-80.892156247999935, 35.075949847000061],
            [-80.892059247999953, 35.07601384700007],
            [-80.891978247999987, 35.076047847000041],
            [-80.891705247999937, 35.076162847000035],
            [-80.891559247999965, 35.076233847000026],
            [-80.891380247999962, 35.076329848000057],
            [-80.89118224799995, 35.076441847000069],
            [-80.891010247999986, 35.076530848000061],
            [-80.890879248999966, 35.076591848000078],
            [-80.890767246999985, 35.076634847000037],
            [-80.890536247999989, 35.076666848000059],
            [-80.89042724799998, 35.076673847000052],
            [-80.890230247999966, 35.076679847000037],
            [-80.890153246999944, 35.076685848000068],
            [-80.890045247999979, 35.076672847000054],
            [-80.889907247999986, 35.076644847000068],
            [-80.889757247999967, 35.076596847000076],
            [-80.889630247999946, 35.076566848000027],
            [-80.889580246999969, 35.076554847000068],
            [-80.889547247999985, 35.076551848000065],
            [-80.889319247999936, 35.076484847000074],
            [-80.889084247999961, 35.076415848000067],
            [-80.888935246999949, 35.076391848000071],
            [-80.888726246999965, 35.076374847000068],
            [-80.888635247999957, 35.07637784800005],
            [-80.888407247999965, 35.076372847000073],
            [-80.88830424799994, 35.07636584800008],
            [-80.887964245999967, 35.076343847000032],
            [-80.887821247999966, 35.076290848000042],
            [-80.887699246999944, 35.076257848000068],
            [-80.887582246999955, 35.07619784700006],
            [-80.887534246999962, 35.07615684700005],
            [-80.88744924699995, 35.07606684700005],
            [-80.887348246999977, 35.075910847000046],
            [-80.887298245999943, 35.075759848000075],
            [-80.887131246999957, 35.075320848000047],
            [-80.887118246999989, 35.075190847000044],
            [-80.887056246999975, 35.074978847000068],
            [-80.887020246999953, 35.074920847000044],
            [-80.886901246999969, 35.074847847000058],
            [-80.886762246999979, 35.074785847000044],
            [-80.886666246999937, 35.074777848000053],
            [-80.886547246999953, 35.074790847000031],
            [-80.886296246999962, 35.074894848000042],
            [-80.885987245999956, 35.075064847000078],
            [-80.885692245999962, 35.075187847000052],
            [-80.885526245999984, 35.07527884700005],
            [-80.885410245999935, 35.075392848000035],
            [-80.885320245999935, 35.075498848000052],
            [-80.885295245999941, 35.075554848000024],
            [-80.885146245999977, 35.075791847000062],
            [-80.884988246999967, 35.076082848000055],
            [-80.884954246999939, 35.076168848000066],
            [-80.884895246999974, 35.076251847000037],
            [-80.884827245999986, 35.076320847000034],
            [-80.884788245999971, 35.07637784800005],
            [-80.884774245999949, 35.076468848000047],
            [-80.884775245999947, 35.076492848000044],
            [-80.884781245999989, 35.076601848000053],
            [-80.884801245999938, 35.076689848000058],
            [-80.884831245999976, 35.076747848000025],
            [-80.884884245999956, 35.076834848000033],
            [-80.884996245999957, 35.076951848000078],
            [-80.88513024699995, 35.077052847000061],
            [-80.885221246999947, 35.07712884700004],
            [-80.885289245999957, 35.077171848000035],
            [-80.885444245999963, 35.077268848000074],
            [-80.88562924699994, 35.077373848000036],
            [-80.885743246999937, 35.077415848000044],
            [-80.885847245999969, 35.077445848000025],
            [-80.886084246999985, 35.077496848000067],
            [-80.886151246999987, 35.077505848000044],
            [-80.886409245999971, 35.077523848000055],
            [-80.886482245999957, 35.077522848000058],
            [-80.886547246999953, 35.077532847000043],
            [-80.886619245999952, 35.07754884700006],
            [-80.886700245999975, 35.077573847000053],
            [-80.886731246999943, 35.077588847000072],
            [-80.886770245999969, 35.077594848000047],
            [-80.886827247999975, 35.077590847000067],
            [-80.886955246999946, 35.07759384700006],
            [-80.887139246999936, 35.077661848000048],
            [-80.887262245999977, 35.077746848000061],
            [-80.88734324699999, 35.077813848000062],
            [-80.88740024699996, 35.077903848000062],
            [-80.887427246999948, 35.078017847000069],
            [-80.887411246999989, 35.07813784700005],
            [-80.88739224699998, 35.078173848000063],
            [-80.887348247999967, 35.078285848000064],
            [-80.887326247999965, 35.078397848000066],
            [-80.887317245999952, 35.078524848000029],
            [-80.887299245999941, 35.078648848000057],
            [-80.887256246999982, 35.078719847000059],
            [-80.887171247999959, 35.078793848000032],
            [-80.887082245999977, 35.078883848000032],
            [-80.886725247999948, 35.079074848000062],
            [-80.886255246999951, 35.07928584800004],
            [-80.886224245999983, 35.079295848000072],
            [-80.886076245999959, 35.07934184800007],
            [-80.885888246999968, 35.07939084800006],
            [-80.885747246999983, 35.079418848000046],
            [-80.885248245999946, 35.079472849000069],
            [-80.88500324599994, 35.078771849000077],
            [-80.884907246999944, 35.078515848000052],
            [-80.884828245999984, 35.078329848000067],
            [-80.884726245999957, 35.078128848000063],
            [-80.884678245999964, 35.078057847000025],
            [-80.884550244999957, 35.077879848000066],
            [-80.884466246999978, 35.077776848000042],
            [-80.88425224599996, 35.077556848000029],
            [-80.884074245999955, 35.07739084800005],
            [-80.883193244999973, 35.076566848000027],
            [-80.882304244999943, 35.075735848000079],
            [-80.880543244999956, 35.074126847000059],
            [-80.880213244999936, 35.073824847000026],
            [-80.880125244999988, 35.07374384700006],
            [-80.879953243999978, 35.073596847000033],
            [-80.879834243999937, 35.073485847000029],
            [-80.879670244999943, 35.073309847000075],
            [-80.879517243999942, 35.073128847000078],
            [-80.879413244999967, 35.072990847000028],
            [-80.879323243999977, 35.072852847000036],
            [-80.879232243999979, 35.072711847000051],
            [-80.879185243999984, 35.072622847000048],
            [-80.879134243999943, 35.07252784700006],
            [-80.878978243999939, 35.072178847000032],
            [-80.877351242999964, 35.072846847000051],
            [-80.874594243999979, 35.076106847000062],
            [-80.87432824199999, 35.076188848000072],
            [-80.872093242999938, 35.07688384800008],
            [-80.871879241999977, 35.076310848000048],
            [-80.871708241999954, 35.07590084800006],
            [-80.871525241999962, 35.075502848000042],
            [-80.870818241999984, 35.074191848000055],
            [-80.870204241999943, 35.07323384700004],
            [-80.869939241999987, 35.07285784700008],
            [-80.869883240999968, 35.072785848000024],
            [-80.869584241999974, 35.072390848000055],
            [-80.868907240999988, 35.071591847000036],
            [-80.868471240999952, 35.071112847000052],
            [-80.868090240999948, 35.070729847000052],
            [-80.867645240999934, 35.07031084700003],
            [-80.867356239999935, 35.070047847000069],
            [-80.867298239999968, 35.069996847000027],
            [-80.866853240999944, 35.069605847000048],
            [-80.866489239999964, 35.069310847000054],
            [-80.866135239999949, 35.069038847000058],
            [-80.865757239999937, 35.068763847000071],
            [-80.865359239999975, 35.068480846000057],
            [-80.864863239999977, 35.068157847000066],
            [-80.864421238999967, 35.067887847000065],
            [-80.863812239999959, 35.067535846000055],
            [-80.863691238999934, 35.067471847000036],
            [-80.863650238999981, 35.06759084600003],
            [-80.863595239999938, 35.067714847000047],
            [-80.863463238999941, 35.068059847000029],
            [-80.86325723899995, 35.068439847000036],
            [-80.86292323899994, 35.068943847000071],
            [-80.862680238999985, 35.069194847000063],
            [-80.862407239999982, 35.069481846000031],
            [-80.861300238999945, 35.070646846000045],
            [-80.861066237999978, 35.070922847000077],
            [-80.860873237999954, 35.071151847000067],
            [-80.860722237999937, 35.071355848000053],
            [-80.860344238999971, 35.071930848000079],
            [-80.86032223899997, 35.07196484800005],
            [-80.860013238999954, 35.072538847000033],
            [-80.859753238999986, 35.073129848000065],
            [-80.859726237999951, 35.073206848000041],
            [-80.859612238999944, 35.07352884900007],
            [-80.85945623899994, 35.073989848000053],
            [-80.85930923799998, 35.074443848000044],
            [-80.859121237999943, 35.075027848000047],
            [-80.858970237999984, 35.075421849000065],
            [-80.85878323899999, 35.075723848000052],
            [-80.85870523899996, 35.075809848000063],
            [-80.858497237999984, 35.076038848000053],
            [-80.858234237999966, 35.076244848000044],
            [-80.857861238999988, 35.076472849000027],
            [-80.85756923799994, 35.076584849000028],
            [-80.857381237999959, 35.076648849000037],
            [-80.857090237999955, 35.076716848000046],
            [-80.856774237999957, 35.076755849000051],
            [-80.855960237999966, 35.076818849000063],
            [-80.855571236999936, 35.076853849000031],
            [-80.855155237999952, 35.076887848000069],
            [-80.854790236999975, 35.07691784900004],
            [-80.854734236999946, 35.076927848000025],
            [-80.854353236999941, 35.076996849000068],
            [-80.85371923699995, 35.077192849000028],
            [-80.853366236999989, 35.077336848000073],
            [-80.852965235999989, 35.077565849000052],
            [-80.852622235999945, 35.077801850000071],
            [-80.852214235999952, 35.078167849000067],
            [-80.852082235999944, 35.07828685000004],
            [-80.851823235999973, 35.07862085000005],
            [-80.851531235999971, 35.079159849000064],
            [-80.851284235999969, 35.079615849000049],
            [-80.851176235999958, 35.079803850000076],
            [-80.851078236999967, 35.079950849000056],
            [-80.850971235999964, 35.080093850000026],
            [-80.850855235999973, 35.080232850000073],
            [-80.85015823599997, 35.080914850000056],
            [-80.849887236999962, 35.080721850000032],
            [-80.849647234999964, 35.080569850000074],
            [-80.84932523599997, 35.080405850000034],
            [-80.849174234999964, 35.08033985000003],
            [-80.848888235999937, 35.080234850000068],
            [-80.848272235999957, 35.080033849000074],
            [-80.848007234999955, 35.07993084900005],
            [-80.847860234999985, 35.07986285000004],
            [-80.847607235999988, 35.079722850000053],
            [-80.847305234999965, 35.079545850000045],
            [-80.846637234999946, 35.079172850000077],
            [-80.845992233999937, 35.078795850000063],
            [-80.845044234999989, 35.078257850000057],
            [-80.844390233999945, 35.077898850000054],
            [-80.84419623499997, 35.077802849000079],
            [-80.84391823499999, 35.077683850000028],
            [-80.843189232999976, 35.077401849000069],
            [-80.842947233999951, 35.077303849000032],
            [-80.84263223399995, 35.077156850000051],
            [-80.842499233999945, 35.077086849000068],
            [-80.84210023299994, 35.076852849000034],
            [-80.842020232999971, 35.076803849000044],
            [-80.841869232999954, 35.07669284900004],
            [-80.841631232999987, 35.076526849000061],
            [-80.841383232999988, 35.076314850000074],
            [-80.841154233999987, 35.076086849000035],
            [-80.840973233999989, 35.075832848000061],
            [-80.840901233999944, 35.075682849000032],
            [-80.840829232999965, 35.075507849000076],
            [-80.840757232999977, 35.07530084900003],
            [-80.840729231999944, 35.07519484900007],
            [-80.840682232999939, 35.07501884800007],
            [-80.840667232999976, 35.074765848000027],
            [-80.84068823299998, 35.074469849000025],
            [-80.840691232999973, 35.074436849000051],
            [-80.840671232999966, 35.074448848000031],
            [-80.84064723399996, 35.074474849000069],
            [-80.840443231999984, 35.074700850000056],
            [-80.84037723199998, 35.074773849000053],
            [-80.839649232999989, 35.07603984900004],
            [-80.839179232999982, 35.07682784900004],
            [-80.839068232999978, 35.076997849000065],
            [-80.838961232999964, 35.077410850000035],
            [-80.838968231999957, 35.077702850000037],
            [-80.838972231999946, 35.077822849000029],
            [-80.838972231999946, 35.077863850000028],
            [-80.839045232999979, 35.078294849000031],
            [-80.839142232999961, 35.078609849000031],
            [-80.839290231999939, 35.078913850000049],
            [-80.839358232999984, 35.079070849000061],
            [-80.839401231999943, 35.079270850000057],
            [-80.839371232999952, 35.07962985000006],
            [-80.839312231999941, 35.07989384900003],
            [-80.839201232999983, 35.080095850000077],
            [-80.839192231999959, 35.080222850000041],
            [-80.839003232999971, 35.080383850000032],
            [-80.83890323299994, 35.08051185100004],
            [-80.838689232999968, 35.080704850000075],
            [-80.837987232999978, 35.081421851000073],
            [-80.837573231999954, 35.081828851000068],
            [-80.837284231999945, 35.082096850000028],
            [-80.837071231999971, 35.082332850000057],
            [-80.836419231999969, 35.082995850000032],
            [-80.836105231999966, 35.083285851000028],
            [-80.835691231999988, 35.083723851000059],
            [-80.835303231999944, 35.084120850000033],
            [-80.834537231999946, 35.084869851000065],
            [-80.834262230999968, 35.085190852000039],
            [-80.834088231999942, 35.085361852000062],
            [-80.834023230999946, 35.085425851000025],
            [-80.833786230999976, 35.085724851000066],
            [-80.833563230999971, 35.08607685100003],
            [-80.833465230999934, 35.086246851000055],
            [-80.833315231999961, 35.086459852000075],
            [-80.833263230999989, 35.086553852000065],
            [-80.833180230999972, 35.086704851000036],
            [-80.833032231999937, 35.087001852000071],
            [-80.832920230999946, 35.087151852000034],
            [-80.832684230999973, 35.087492852000025],
            [-80.832504230999973, 35.08778385200003],
            [-80.832275230999983, 35.088120852000031],
            [-80.832169230999966, 35.088347852000027],
            [-80.832015230999957, 35.088535852000064],
            [-80.831904230999953, 35.088748852000037],
            [-80.831818229999953, 35.088897853000049],
            [-80.831746230999954, 35.089109852000036],
            [-80.831671230999973, 35.089226853000071],
            [-80.831521230999954, 35.089397853000037],
            [-80.831347230999938, 35.089631852000025],
            [-80.831186230999947, 35.089908853000054],
            [-80.831102230999988, 35.090141852000045],
            [-80.831104230999983, 35.090226853000047],
            [-80.831069230999958, 35.090296852000051],
            [-80.830957229999967, 35.09045485300004],
            [-80.830880230999981, 35.090535853000063],
            [-80.830692229999954, 35.090687853000077],
            [-80.830463230999953, 35.09087085200008],
            [-80.830299230999969, 35.090965852000068],
            [-80.830173229999957, 35.091062854000029],
            [-80.829940231999956, 35.091151853000042],
            [-80.829701230999945, 35.091196853000042],
            [-80.829573229999937, 35.091208853000069],
            [-80.82940623099995, 35.091211853000061],
            [-80.82867822999998, 35.091372853000053],
            [-80.828589229999977, 35.091392854000048],
            [-80.827858229999947, 35.091464853000048],
            [-80.827693230999955, 35.091479853000067],
            [-80.827068229999952, 35.091615853000064],
            [-80.826710229999946, 35.091674853000029],
            [-80.826531228999954, 35.091697853000028],
            [-80.826340229999971, 35.091700853000077],
            [-80.826122229999953, 35.09172585400006],
            [-80.825839228999939, 35.091791853000075],
            [-80.825749228999939, 35.091815853000071],
            [-80.825381228999959, 35.091926853000075],
            [-80.82507622899999, 35.092046853000056],
            [-80.824770229999956, 35.092141853000044],
            [-80.825098228999934, 35.092703853000046],
            [-80.825213229999974, 35.092915852000033],
            [-80.825373228999979, 35.093277854000064],
            [-80.825610228999949, 35.09383185400003],
            [-80.826017229999934, 35.094806854000069],
            [-80.826151229999937, 35.095101853000074],
            [-80.826214229999948, 35.09521185400007],
            [-80.826331229999937, 35.095377853000059],
            [-80.826401228999941, 35.095464854000056],
            [-80.826824228999953, 35.095941854000046],
            [-80.827243229999965, 35.096422855000071],
            [-80.827300229999935, 35.096503854000048],
            [-80.82737222999998, 35.096636854000053],
            [-80.827401230999953, 35.096705854000049],
            [-80.827431229999945, 35.096831855000062],
            [-80.827441229999977, 35.096912855000028],
            [-80.827442229999974, 35.097054854000078],
            [-80.82742922999995, 35.097195855000052],
            [-80.827348228999938, 35.097745855000028],
            [-80.827308230999961, 35.098043855000071],
            [-80.827184229999943, 35.098924854000074],
            [-80.827116229999945, 35.099369855000077],
            [-80.827015230999962, 35.099898855000049],
            [-80.826885230999949, 35.100460855000051],
            [-80.826797230999944, 35.100810855000077],
            [-80.826732230999937, 35.101121855000031],
            [-80.826716229999988, 35.101249855000049],
            [-80.826710229999946, 35.101351855000075],
            [-80.826720229999978, 35.101582855000061],
            [-80.826728229999958, 35.101975856000024],
            [-80.826744229999974, 35.102443855000047],
            [-80.826761230999978, 35.103230856000039],
            [-80.826773230999947, 35.103359856000054],
            [-80.826803229999939, 35.103580856000065],
            [-80.826855230999968, 35.104391856000063],
            [-80.82685823099996, 35.104649856000037],
            [-80.826849230999983, 35.104973856000072],
            [-80.826838230999954, 35.105117856000049],
            [-80.826854229999981, 35.105263855000032],
            [-80.826883229999964, 35.105371856000033],
            [-80.826924230999964, 35.105460856000036],
            [-80.826977229999954, 35.105516856000065],
            [-80.827405230999943, 35.10579685600004],
            [-80.827491230999954, 35.105699856000058],
            [-80.827760229999967, 35.105475855000066],
            [-80.828050230999963, 35.105288855000026],
            [-80.828331230999936, 35.105136856000058],
            [-80.828920230999984, 35.104879855000036],
            [-80.829524230999937, 35.104621856000051],
            [-80.830136231999973, 35.104388855000025],
            [-80.830395231999944, 35.104322856000067],
            [-80.830633230999979, 35.104277856000067],
            [-80.830929230999971, 35.10424785500004],
            [-80.831348230999936, 35.104239856000049],
            [-80.831400231999964, 35.104239856000049],
            [-80.832128231999945, 35.104282856000054],
            [-80.832582231999936, 35.104272855000033],
            [-80.832919232999984, 35.104227855000033],
            [-80.833031231999939, 35.104203855000037],
            [-80.833313232999956, 35.104145856000059],
            [-80.833533231999979, 35.104081855000061],
            [-80.833728231999942, 35.104014855000059],
            [-80.833923231999961, 35.103934855000034],
            [-80.834138231999987, 35.103816855000048],
            [-80.834337232999985, 35.103699856000048],
            [-80.834524232999968, 35.103574855000033],
            [-80.834783232999939, 35.103359855000065],
            [-80.835095231999958, 35.10301185600008],
            [-80.835486231999937, 35.102561855000033],
            [-80.835862232999943, 35.102132855000036],
            [-80.836132232999944, 35.101832854000065],
            [-80.836202231999948, 35.101737854000078],
            [-80.836653232999936, 35.101198855000064],
            [-80.836908232999974, 35.100883855000063],
            [-80.837016233999975, 35.100689855000041],
            [-80.837059232999934, 35.100633855000069],
            [-80.837077232999945, 35.100593855000056],
            [-80.837269233999962, 35.100231854000072],
            [-80.837538233999965, 35.099740854000061],
            [-80.837636233999945, 35.099581855000054],
            [-80.837696233999964, 35.099494854000056],
            [-80.837723232999963, 35.099432854000042],
            [-80.837774233999937, 35.099379855000052],
            [-80.837847232999934, 35.099273854000046],
            [-80.837892232999934, 35.099197854000067],
            [-80.838009233999969, 35.099043854000058],
            [-80.838150233999954, 35.098889854000049],
            [-80.838331232999963, 35.09868285400006],
            [-80.83846723299996, 35.098560854000027],
            [-80.838560233999942, 35.098452855000062],
            [-80.838798232999977, 35.098225854000077],
            [-80.838859232999937, 35.098148854000044],
            [-80.839041233999978, 35.097969854000041],
            [-80.839283232999946, 35.09771485300007],
            [-80.839458233999949, 35.097517855000035],
            [-80.839577232999943, 35.09736285300005],
            [-80.839825233999989, 35.097023854000042],
            [-80.83998623399998, 35.09678785400007],
            [-80.840107233999959, 35.096620854000037],
            [-80.840167232999988, 35.096555853000041],
            [-80.840216233999968, 35.096494853000024],
            [-80.840806233999956, 35.095627852000064],
            [-80.841124233999949, 35.095173853000063],
            [-80.841304234999939, 35.094905853000057],
            [-80.841397234999988, 35.094746853000061],
            [-80.841617234999944, 35.094405853000069],
            [-80.841737233999936, 35.094246853000072],
            [-80.841821233999951, 35.094152853000026],
            [-80.841883233999965, 35.094092853000063],
            [-80.841931233999958, 35.094046853000066],
            [-80.842167234999977, 35.093861853000078],
            [-80.84239123499998, 35.094057853000038],
            [-80.842447234999952, 35.094099853000046],
            [-80.842570233999936, 35.094174853000027],
            [-80.842730234999976, 35.094247852000024],
            [-80.842835234999939, 35.094284853000033],
            [-80.843092234999972, 35.094368853000049],
            [-80.843191234999949, 35.094408853000061],
            [-80.843380233999937, 35.094499853000059],
            [-80.843526234999956, 35.094582853000077],
            [-80.843724234999968, 35.094725853000057],
            [-80.843858234999971, 35.094802853000033],
            [-80.843986234999988, 35.09485985300006],
            [-80.844122234999986, 35.094901853000067],
            [-80.844326235999972, 35.094938853000031],
            [-80.844442234999974, 35.094948853000062],
            [-80.844592233999947, 35.094944853000072],
            [-80.845133235999981, 35.094913853000037],
            [-80.846837235999942, 35.094815853000057],
            [-80.847107235999943, 35.09480385300003],
            [-80.84717823699998, 35.09481085300007],
            [-80.847236235999958, 35.094821853000042],
            [-80.847292235999987, 35.094837853000058],
            [-80.847398235999947, 35.094881853000061],
            [-80.847445235999942, 35.094909853000047],
            [-80.847521235999977, 35.094967853000071],
            [-80.847592234999979, 35.095046853000042],
            [-80.847693235999941, 35.095216853000068],
            [-80.84774123699998, 35.095301853000024],
            [-80.847772235999969, 35.095406853000043],
            [-80.847773235999966, 35.095494853000048],
            [-80.847764235999989, 35.09553885300005],
            [-80.847730235999961, 35.095622853000066],
            [-80.847702235999975, 35.095662854000068],
            [-80.847730235999961, 35.095672853000053],
            [-80.848029235999945, 35.095672853000053],
            [-80.848134235999964, 35.095678853000038],
            [-80.848174235999977, 35.095684854000069],
            [-80.848304236999979, 35.095743853000045],
            [-80.848345235999943, 35.095757853000066],
            [-80.84839423599999, 35.095752853000079],
            [-80.848446236999962, 35.095761853000056],
            [-80.848572235999939, 35.095810853000046],
            [-80.848742236999954, 35.095924853000042],
            [-80.848791236999944, 35.095945853000046],
            [-80.84890523699994, 35.09599585400008],
            [-80.849019235999947, 35.096079852000059],
            [-80.849200235999945, 35.096271853000076],
            [-80.84924323599995, 35.096306853000044],
            [-80.849306236999951, 35.096329852000054],
            [-80.84936223699998, 35.096331853000038],
            [-80.84959123699997, 35.09637585300004],
            [-80.849254236999968, 35.096949853000069],
            [-80.849141236999969, 35.097110853000061],
            [-80.848960236999972, 35.097336854000048],
            [-80.84881923599994, 35.097474854000041],
            [-80.848632236999947, 35.097674853000058],
            [-80.848466235999979, 35.097895853000068],
            [-80.848269236999954, 35.098215854000046],
            [-80.848216235999985, 35.098299854000061],
            [-80.848032235999938, 35.098628854000026],
            [-80.84796623699998, 35.098757853000052],
            [-80.84788423599997, 35.098921854000025],
            [-80.847737235999944, 35.099189854000031],
            [-80.847637235999969, 35.099306854000076],
            [-80.847580236999988, 35.099429853000061],
            [-80.847508236999943, 35.09961785400003],
            [-80.847369235999963, 35.099868854000078],
            [-80.847282235999955, 35.09998885400006],
            [-80.847180236999975, 35.100193854000054],
            [-80.846758235999971, 35.100949855000067],
            [-80.846650235999959, 35.101287854000077],
            [-80.846715235999966, 35.101374855000074],
            [-80.846517236999944, 35.101510855000072],
            [-80.846379236999951, 35.101718855000058],
            [-80.846187235999935, 35.102089855000031],
            [-80.846039235999967, 35.102337854000041],
            [-80.845966235999981, 35.102481855000065],
            [-80.84585423599998, 35.102672854000048],
            [-80.845843235999951, 35.102690854000059],
            [-80.845648235999988, 35.102938855000048],
            [-80.845551234999959, 35.103160854000066],
            [-80.84546323699999, 35.103431855000053],
            [-80.845368234999967, 35.103562855000064],
            [-80.845289236999974, 35.103662854000049],
            [-80.845229235999966, 35.103806854000027],
            [-80.845206235999967, 35.103943855000068],
            [-80.845161234999978, 35.104038855000056],
            [-80.845105235999938, 35.104197855000052],
            [-80.845055235999951, 35.104425855000045],
            [-80.844951235999986, 35.104739855000048],
            [-80.844878235999943, 35.104873854000061],
            [-80.844778235999968, 35.104980855000065],
            [-80.84437223599997, 35.10530585500004],
            [-80.844308235999961, 35.105320855000059],
            [-80.844197235999957, 35.105318855000064],
            [-80.84408323599996, 35.105369856000038],
            [-80.844020235999949, 35.105451856000059],
            [-80.843951234999963, 35.105542855000067],
            [-80.843754234999949, 35.105889856000033],
            [-80.843710234999946, 35.106019856000046],
            [-80.843696234999982, 35.106180856000037],
            [-80.843703235999953, 35.106303855000078],
            [-80.843771234999963, 35.106470855000055],
            [-80.843941235999978, 35.106807856000046],
            [-80.844032235999975, 35.107058856000037],
            [-80.844105235999962, 35.107302856000047],
            [-80.844092234999948, 35.107481856000049],
            [-80.844117235999988, 35.107610855000075],
            [-80.844089235999945, 35.107716856000025],
            [-80.84396323499999, 35.107799856000042],
            [-80.843980234999947, 35.107874856000024],
            [-80.844033235999973, 35.108141856000032],
            [-80.844104235999964, 35.108406856000045],
            [-80.844108234999965, 35.108474856000043],
            [-80.844096235999984, 35.108528856000078],
            [-80.844052234999936, 35.108589856000037],
            [-80.844051235999984, 35.108627856000055],
            [-80.844069234999949, 35.108661856000026],
            [-80.84417723599995, 35.108780856000067],
            [-80.844211235999978, 35.108844856000076],
            [-80.844244235999952, 35.108989855000061],
            [-80.844273235999935, 35.109067856000024],
            [-80.844438234999984, 35.109253857000056],
            [-80.844500235999988, 35.10929085600003],
            [-80.844660236999971, 35.109431856000072],
            [-80.844931235999979, 35.10964685600004],
            [-80.845137235999971, 35.109823856000048],
            [-80.845199235999985, 35.109861856000066],
            [-80.845234235999953, 35.109883855000078],
            [-80.845460236999941, 35.109953856000061],
            [-80.845582235999984, 35.110016857000062],
            [-80.845650235999983, 35.110086856000066],
            [-80.845713236999984, 35.110170856000025],
            [-80.845817235999959, 35.110361856000054],
            [-80.845853236999972, 35.110391856000035],
            [-80.84594823599997, 35.110403857000051],
            [-80.846007235999934, 35.110452856000052],
            [-80.846187236999981, 35.110668856000075],
            [-80.846314236999945, 35.110810857000047],
            [-80.846479235999936, 35.110934857000075],
            [-80.846502235999935, 35.110964857000056],
            [-80.846507236999969, 35.110982856000078],
            [-80.846502236999982, 35.111112856000034],
            [-80.846536235999963, 35.11115785700008],
            [-80.846679235999943, 35.111216857000045],
            [-80.846794236999983, 35.111256857000058],
            [-80.846952236999982, 35.111391856000068],
            [-80.847144235999963, 35.111520856000027],
            [-80.84717823699998, 35.111549857000057],
            [-80.847191236999947, 35.111583856000038],
            [-80.847196236999935, 35.111624856000049],
            [-80.847194237999986, 35.111707857000056],
            [-80.847205236999969, 35.111862857000062],
            [-80.847075236999956, 35.112035857000023],
            [-80.847093236999967, 35.112307856000029],
            [-80.847214236999946, 35.112513857000067],
            [-80.84727623699996, 35.112616856000045],
            [-80.847428236999974, 35.112837857000045],
            [-80.847467236999989, 35.112898857000062],
            [-80.847591236999961, 35.11292185700006],
            [-80.847582237999973, 35.113069857000028],
            [-80.847592235999969, 35.113150857000051],
            [-80.847576236999942, 35.113265857000044],
            [-80.847553235999953, 35.113334857000041],
            [-80.847528236999949, 35.113382857000033],
            [-80.847548236999955, 35.113498857000025],
            [-80.847547236999958, 35.11353385600006],
            [-80.847619236999947, 35.113636857000074],
            [-80.847729236999953, 35.113766856000041],
            [-80.847748236999962, 35.113813857000025],
            [-80.847750236999957, 35.113846856000066],
            [-80.847802236999939, 35.113992857000028],
            [-80.847820236999951, 35.114029857000048],
            [-80.847940237999978, 35.114089857000067],
            [-80.84798223699994, 35.114102857000034],
            [-80.848188236999988, 35.114209858000038],
            [-80.848210236999989, 35.114234857000042],
            [-80.848216236999974, 35.114255856000057],
            [-80.848253236999938, 35.114324858000032],
            [-80.848263236999969, 35.114352857000029],
            [-80.848319236999941, 35.11440785700006],
            [-80.848353236999969, 35.114414857000043],
            [-80.848388236999938, 35.114414857000043],
            [-80.848424236999961, 35.114429857000061],
            [-80.848508236999976, 35.114484857000036],
            [-80.848597237999968, 35.11453485800007],
            [-80.84866823699997, 35.114563857000064],
            [-80.848738237999953, 35.114615858000036],
            [-80.848783236999964, 35.114666857000032],
            [-80.84883623799999, 35.114711857000032],
            [-80.848849237999957, 35.114728857000046],
            [-80.848953236999989, 35.114815858000043],
            [-80.849039236999943, 35.114861857000051],
            [-80.84912623799994, 35.11487685700007],
            [-80.849195236999947, 35.114918856000031],
            [-80.849307237999938, 35.115029857000025],
            [-80.849381237999978, 35.11507285700003],
            [-80.849434236999969, 35.115126857000064],
            [-80.849517237999976, 35.115243858000042],
            [-80.849613236999971, 35.115357858000039],
            [-80.849633237999967, 35.115406857000039],
            [-80.849697237999976, 35.115505857000073],
            [-80.849774238999942, 35.11557885700006],
            [-80.849872236999943, 35.115662857000075],
            [-80.849913236999953, 35.115716857000052],
            [-80.849932236999962, 35.115748857000028],
            [-80.850028237999936, 35.115878857000041],
            [-80.85003323799998, 35.115910857000074],
            [-80.850132237999958, 35.116036858000029],
            [-80.850186237999935, 35.116127857000038],
            [-80.850182237999945, 35.116145857000049],
            [-80.850189237999984, 35.11616385700006],
            [-80.850193237999974, 35.11619685800008],
            [-80.850206236999952, 35.116226857000072],
            [-80.850222237999958, 35.116247858000065],
            [-80.850210237999988, 35.116296858000055],
            [-80.850231237999935, 35.116418857000042],
            [-80.850234237999985, 35.116552857000045],
            [-80.850215237999976, 35.116641858000037],
            [-80.850196237999967, 35.116778857000043],
            [-80.850207237999939, 35.116863857000055],
            [-80.850246237999954, 35.116987857000026],
            [-80.850254237999934, 35.117031858000075],
            [-80.850262237999971, 35.117125858000065],
            [-80.85027023799995, 35.117170858000065],
            [-80.850273238999989, 35.117414857000028],
            [-80.850266236999971, 35.117504858000075],
            [-80.850247237999952, 35.117717858000049],
            [-80.850254237999934, 35.117858857000044],
            [-80.850242237999964, 35.118095857000071],
            [-80.850172237999971, 35.118130858000029],
            [-80.84991423699995, 35.118277858000056],
            [-80.849832238999966, 35.118330858000036],
            [-80.849508237999942, 35.11857285800005],
            [-80.84916723799995, 35.118867858000044],
            [-80.849101237999946, 35.118935858000043],
            [-80.848749237999982, 35.119249858000046],
            [-80.848546236999937, 35.119404858000053],
            [-80.848401237999951, 35.119500857000048],
            [-80.848266236999962, 35.11958185800006],
            [-80.848077237999973, 35.119684858000028],
            [-80.847723237999958, 35.11982985800006],
            [-80.847485236999944, 35.119909858000028],
            [-80.847283236999942, 35.119958859000064],
            [-80.847090236999975, 35.119996858000036],
            [-80.846894236999958, 35.120025858000076],
            [-80.846697236999944, 35.120045858000026],
            [-80.846608236999941, 35.120049858000073],
            [-80.84625123699999, 35.120049858000073],
            [-80.846178236999947, 35.120044858000028],
            [-80.845986236999977, 35.120027858000071],
            [-80.845706236999945, 35.119982859000061],
            [-80.845522236999955, 35.11994185900005],
            [-80.845341236999957, 35.119892858000071],
            [-80.845077235999952, 35.119803858000068],
            [-80.844939235999959, 35.119738858000062],
            [-80.84481423699998, 35.119693858000062],
            [-80.844752236999966, 35.119761858000061],
            [-80.844987236999941, 35.120152859000029],
            [-80.845148236999989, 35.120491858000037],
            [-80.845256235999955, 35.120703859000059],
            [-80.845323236999945, 35.120871859000033],
            [-80.845354236999981, 35.121082858000079],
            [-80.845382236999967, 35.121299858000043],
            [-80.845376235999936, 35.12142385900006],
            [-80.845359236999968, 35.121493858000065],
            [-80.845307236999986, 35.121681859000034],
            [-80.845297236999954, 35.121762858000068],
            [-80.845023235999975, 35.122857859000078],
            [-80.844961235999961, 35.123058859000025],
            [-80.844706235999979, 35.123804860000064],
            [-80.84463823699997, 35.124044859000037],
            [-80.844583236999938, 35.124278859000071],
            [-80.84449523699999, 35.124978860000056],
            [-80.844464236999954, 35.125560860000064],
            [-80.844433236999976, 35.125799860000029],
            [-80.844356236999943, 35.12619686000005],
            [-80.844305235999968, 35.126476860000025],
            [-80.844279236999967, 35.12665786000008],
            [-80.844285236999951, 35.126722860000029],
            [-80.844303235999973, 35.126786860000038],
            [-80.844332236999946, 35.126846860000057],
            [-80.844371236999962, 35.126903861000073],
            [-80.844421236999949, 35.126954860000069],
            [-80.844468236999944, 35.126987860000042],
            [-80.844515236999939, 35.127017860000024],
            [-80.84519023699994, 35.127390861000038],
            [-80.845924236999963, 35.12781386000006],
            [-80.845954236999944, 35.127840860000049],
            [-80.846002236999936, 35.127924860000064],
            [-80.845576236999989, 35.128003859000046],
            [-80.845321236999951, 35.128048860000035],
            [-80.844972236999979, 35.128139859000044],
            [-80.844898236999938, 35.128164860000027],
            [-80.844751236999969, 35.128226860000041],
            [-80.844613236999976, 35.12829486000004],
            [-80.844481236999968, 35.128371860000073],
            [-80.844340235999937, 35.128467860000057],
            [-80.844162236999978, 35.12860986000004],
            [-80.843908236999937, 35.128853861000039],
            [-80.843788236999956, 35.128982860000065],
            [-80.843664235999938, 35.12910786100008],
            [-80.843558236999968, 35.129240861000028],
            [-80.843464236999978, 35.129380860000026],
            [-80.843404235999969, 35.129494860000079],
            [-80.843314235999969, 35.129717860000028],
            [-80.843190235999941, 35.130060860000071],
            [-80.843124236999984, 35.130224860000055],
            [-80.843076236999934, 35.130314861000045],
            [-80.842951235999976, 35.130491861000053],
            [-80.842856236999978, 35.130602860000067],
            [-80.84272423699997, 35.130738861000054],
            [-80.842154235999942, 35.131273862000057],
            [-80.842121235999969, 35.131304861000046],
            [-80.842040235999946, 35.131401861000029],
            [-80.841699236999943, 35.131724861000066],
            [-80.841395235999983, 35.132008861000031],
            [-80.841249236999943, 35.132128861000069],
            [-80.841038235999974, 35.132274861000042],
            [-80.840918236999983, 35.13234686100003],
            [-80.840846235999948, 35.132382861000053],
            [-80.839464235999969, 35.133035861000053],
            [-80.839293234999957, 35.133079862000045],
            [-80.839064234999967, 35.133125862000043],
            [-80.839106235999964, 35.133292861000029],
            [-80.839121235999983, 35.133385861000079],
            [-80.839159235999944, 35.133789862000071],
            [-80.839158235999946, 35.134060862000069],
            [-80.839157235999949, 35.134160862000044],
            [-80.839133234999963, 35.134421862000067],
            [-80.839119235999988, 35.134549862000028],
            [-80.839062235999961, 35.135452862000079],
            [-80.838970236999955, 35.137060862000055],
            [-80.838948235999965, 35.137322862000076],
            [-80.83893123699994, 35.137428862000036],
            [-80.838888235999946, 35.137591862000079],
            [-80.838824235999937, 35.137751862000073],
            [-80.838643235999939, 35.138127862000033],
            [-80.83849223499999, 35.138428863000058],
            [-80.838341235999962, 35.13867986200006],
            [-80.838188235999951, 35.138904862000061],
            [-80.838015234999943, 35.139129863000051],
            [-80.837958234999974, 35.139202863000037],
            [-80.837268235999943, 35.140059863000033],
            [-80.837098235999974, 35.140270863000069],
            [-80.837016234999965, 35.140354863000027],
            [-80.83692023499998, 35.140429863000065],
            [-80.836867234999943, 35.140461862000052],
            [-80.836767234999968, 35.140511863000029],
            [-80.836629235999965, 35.140558863000024],
            [-80.836426234999976, 35.140607863000071],
            [-80.836277235999944, 35.140649864000068],
            [-80.836207233999971, 35.140677863000064],
            [-80.836113234999971, 35.140721863000067],
            [-80.835973234999983, 35.140804863000028],
            [-80.83584723599995, 35.140902863000065],
            [-80.83579423499998, 35.14095386300005],
            [-80.834491233999984, 35.14206086300004],
            [-80.834137234999957, 35.142377863000036],
            [-80.833886234999966, 35.142615864000049],
            [-80.833738234999942, 35.142775864000043],
            [-80.833668233999958, 35.142868864000036],
            [-80.833621234999953, 35.142940863000035],
            [-80.833579233999956, 35.143015863000073],
            [-80.833554233999962, 35.143063863000066],
            [-80.833128234999947, 35.143903864000038],
            [-80.832981234999977, 35.144209864000061],
            [-80.832398234999971, 35.145418864000078],
            [-80.832187233999946, 35.145855864000055],
            [-80.831902233999983, 35.146421864000047],
            [-80.831607233999989, 35.147007865000035],
            [-80.831342234999966, 35.147532864000027],
            [-80.83104423399999, 35.148122865000062],
            [-80.83098423399997, 35.148242865000043],
            [-80.830896234999955, 35.148427865000031],
            [-80.830870234999963, 35.148484865000057],
            [-80.830657233999943, 35.148883865000073],
            [-80.830501233999939, 35.149143865000042],
            [-80.83031323299997, 35.149430866000046],
            [-80.830156232999968, 35.149650865000069],
            [-80.829968233999978, 35.149896866000063],
            [-80.829770232999977, 35.150136865000036],
            [-80.829561232999936, 35.150371865000068],
            [-80.829343233999964, 35.15059986600005],
            [-80.829115232999982, 35.150821866000058],
            [-80.828877232999957, 35.151036865000037],
            [-80.828687233999972, 35.151196866000078],
            [-80.827429232999975, 35.152256866000073],
            [-80.826863231999937, 35.152741866000042],
            [-80.826968232999945, 35.152819867000062],
            [-80.827154232999987, 35.152940866000051],
            [-80.827739232999988, 35.153247866000072],
            [-80.828611233999936, 35.153690866000034],
            [-80.830815234999989, 35.154807866000056],
            [-80.830435234999982, 35.155301866000059],
            [-80.830356232999975, 35.155412866000063],
            [-80.830202234999945, 35.155652866000025],
            [-80.830141233999939, 35.155760867000026],
            [-80.830050234999987, 35.155938867000032],
            [-80.830027233999942, 35.15598486600004],
            [-80.829903232999982, 35.156206866000048],
            [-80.82984823399994, 35.156292867000047],
            [-80.829788232999988, 35.156376866000073],
            [-80.829625233999934, 35.156577867000067],
            [-80.829515232999938, 35.156692867000061],
            [-80.82936323399997, 35.156822867000074],
            [-80.829199233999987, 35.156945867000047],
            [-80.82904423399998, 35.157041867000032],
            [-80.828915233999965, 35.157104867000044],
            [-80.828738233999957, 35.157182867000074],
            [-80.828605233999951, 35.15723386600007],
            [-80.82836223299995, 35.157301867000058],
            [-80.828249233999941, 35.157336867000026],
            [-80.827719232999982, 35.157500868000056],
            [-80.827462233999938, 35.157580867000036],
            [-80.827571232999958, 35.157854867000026],
            [-80.827650232999986, 35.15809686700004],
            [-80.827696232999983, 35.158286867000072],
            [-80.827710233999937, 35.158345867000037],
            [-80.827743233999968, 35.158551867000028],
            [-80.827770233999956, 35.158957867000026],
            [-80.827752233999945, 35.15989386800004],
            [-80.827742232999981, 35.160236867000037],
            [-80.827716233999979, 35.161141868000072],
            [-80.82770923399994, 35.16161186800008],
            [-80.827704232999963, 35.161974868000073],
            [-80.827713233999987, 35.162071868000055],
            [-80.827747233999958, 35.162262869000074],
            [-80.827802232999943, 35.162450868000064],
            [-80.827876232999984, 35.162634868000055],
            [-80.827971233999961, 35.162811868000063],
            [-80.828019233999953, 35.162886869000033],
            [-80.828187232999937, 35.163105868000059],
            [-80.828425233999951, 35.16342486800005],
            [-80.828741233999949, 35.16388286800003],
            [-80.828903234999984, 35.164135868000074],
            [-80.829010233999952, 35.164310869000076],
            [-80.829262233999941, 35.164746868000066],
            [-80.82949823499996, 35.165195869000058],
            [-80.829604234999977, 35.165412869000079],
            [-80.829808233999984, 35.165865868000026],
            [-80.829993234999961, 35.166322868000066],
            [-80.83006123399997, 35.166567869000062],
            [-80.830205234999937, 35.167040869000061],
            [-80.830408234999936, 35.16767186900006],
            [-80.830467234999958, 35.167874869000059],
            [-80.830498234999936, 35.167957870000066],
            [-80.830674234999947, 35.168502870000054],
            [-80.830709234999972, 35.168621869000049],
            [-80.830729234999978, 35.168690870000034],
            [-80.830742234999946, 35.168790870000066],
            [-80.830742234999946, 35.168879869000079],
            [-80.830731234999973, 35.168983870000034],
            [-80.830713234999962, 35.169089869000061],
            [-80.830673234999949, 35.169254870000032],
            [-80.830611234999935, 35.169438870000079],
            [-80.83041323499998, 35.169959870000071],
            [-80.830300233999935, 35.170246869000039],
            [-80.830201234999947, 35.170497870000077],
            [-80.830002234999938, 35.17104487000006],
            [-80.829966234999972, 35.171159869000064],
            [-80.829950234999956, 35.171268870000063],
            [-80.829945233999979, 35.17143186900006],
            [-80.829970234999962, 35.171570870000039],
            [-80.829989234999971, 35.171622871000068],
            [-80.830058234999967, 35.171772870000041],
            [-80.83011823399994, 35.171866870000031],
            [-80.830185234999988, 35.171950870000046],
            [-80.830320234999988, 35.172088870000039],
            [-80.830719235999936, 35.172421870000051],
            [-80.831176234999987, 35.172758870000052],
            [-80.83139723499994, 35.172874870000044],
            [-80.831467234999934, 35.172902871000076],
            [-80.831683235999947, 35.173000870000067],
            [-80.831856234999975, 35.173079870000038],
            [-80.832058234999977, 35.173394871000028],
            [-80.832137235999937, 35.173478870000054],
            [-80.83221423599997, 35.173546870000052],
            [-80.83230023599998, 35.173612870000056],
            [-80.832411235999984, 35.173685870000043],
            [-80.832511236999949, 35.173742870000069],
            [-80.832590235999987, 35.173780871000076],
            [-80.832723235999936, 35.173835870000062],
            [-80.832857235999938, 35.173879870000064],
            [-80.833085235999988, 35.173937871000078],
            [-80.832973235999987, 35.174077870000076],
            [-80.832282235999969, 35.174673870000049],
            [-80.831225235999966, 35.175253870000063],
            [-80.831091235999963, 35.175441871000032],
            [-80.831219234999935, 35.175746870000069],
            [-80.831398234999938, 35.175986871000077],
            [-80.831587234999972, 35.176341871000034],
            [-80.832096235999984, 35.177232871000058],
            [-80.832369235999977, 35.177364871000066],
            [-80.832433236999975, 35.177379870000038],
            [-80.832832235999945, 35.177471872000069],
            [-80.832862235999983, 35.177656871000067],
            [-80.832721235999941, 35.177930871000058],
            [-80.832320236999976, 35.178278871000032],
            [-80.83180523599998, 35.17858687100005],
            [-80.831027235999954, 35.178826871000069],
            [-80.830426235999937, 35.179164871000069],
            [-80.829946234999966, 35.179302872000051],
            [-80.829886234999947, 35.179322872000057],
            [-80.829692234999982, 35.179389871000069],
            [-80.829532233999942, 35.179592872000057],
            [-80.829259234999938, 35.17985387300007],
            [-80.829196234999984, 35.179906872000061],
            [-80.829235235999988, 35.180061872000067],
            [-80.829253235999943, 35.180211872000029],
            [-80.829249234999963, 35.180299873000024],
            [-80.829226234999965, 35.180467872000065],
            [-80.829206234999958, 35.180550872000026],
            [-80.829172234999987, 35.180651872000055],
            [-80.829100234999942, 35.180806872000062],
            [-80.829010234999942, 35.180953872000032],
            [-80.828575235999949, 35.181512872000042],
            [-80.828413234999971, 35.18172687200007],
            [-80.828341234999982, 35.181840873000056],
            [-80.828320234999978, 35.181891872000051],
            [-80.828271234999988, 35.182044872000063],
            [-80.828243234999945, 35.182201872000064],
            [-80.828203234999989, 35.182985872000074],
            [-80.828190234999965, 35.183149873000048],
            [-80.828163235999966, 35.183635873000071],
            [-80.828156234999938, 35.183749873000068],
            [-80.828104234999955, 35.184729873000038],
            [-80.82805723599995, 35.185496873000034],
            [-80.828068234999989, 35.185596873000065],
            [-80.82809823499997, 35.185697873000038],
            [-80.828122234999967, 35.185745872000041],
            [-80.828178235999985, 35.185831873000041],
            [-80.828364234999981, 35.186069874000054],
            [-80.828602234999948, 35.186347874000035],
            [-80.828930234999973, 35.186678874000052],
            [-80.82920123599996, 35.186924873000066],
            [-80.829452235999952, 35.18712587400006],
            [-80.829624235999972, 35.187254873000029],
            [-80.829763235999962, 35.187348873000076],
            [-80.83000423499999, 35.187494874000038],
            [-80.830082234999963, 35.187964874000045],
            [-80.830140235999977, 35.188405874000068],
            [-80.83020023499995, 35.188686874000041],
            [-80.830296235999981, 35.189033874000074],
            [-80.830355234999956, 35.189221874000054],
            [-80.83031523599999, 35.189250874000038],
            [-80.829989234999971, 35.189867874000072],
            [-80.829640235999989, 35.190581874000031],
            [-80.82999723599994, 35.190942874000029],
            [-80.830107235999947, 35.191043874000059],
            [-80.830240235999952, 35.19114787400008],
            [-80.830415235999965, 35.191268874000059],
            [-80.830639235999968, 35.191397874000074],
            [-80.830823235999958, 35.191483874000028],
            [-80.831060236999974, 35.191575873000033],
            [-80.831466235999983, 35.191735874000074],
            [-80.831563236999955, 35.191780874000074],
            [-80.831869236999978, 35.191941875000055],
            [-80.832101236999961, 35.19207487400007],
            [-80.832198236999943, 35.192125874000055],
            [-80.832348235999973, 35.192196875000036],
            [-80.832651236999936, 35.19231887400008],
            [-80.833158236999964, 35.192500874000075],
            [-80.833315236999965, 35.192563875000076],
            [-80.83365123699997, 35.192711875000043],
            [-80.833739236999975, 35.192757874000051],
            [-80.833896237999966, 35.192849874000046],
            [-80.833999236999944, 35.19291987500003],
            [-80.834141237999972, 35.193030875000034],
            [-80.834231237999973, 35.193118874000049],
            [-80.834377236999956, 35.193288874000075],
            [-80.834571237999967, 35.193496875000051],
            [-80.83464923799994, 35.193567874000053],
            [-80.834846236999965, 35.193721874000062],
            [-80.835580236999988, 35.194273874000032],
            [-80.835678236999968, 35.194339875000026],
            [-80.835925238999948, 35.194490875000042],
            [-80.836421237999957, 35.194773874000077],
            [-80.836534237999956, 35.194837874000029],
            [-80.836595237999973, 35.194838875000073],
            [-80.836656238999979, 35.194842875000063],
            [-80.836701237999989, 35.194848875000048],
            [-80.83677623799997, 35.194862875000069],
            [-80.836834237999938, 35.194877875000032],
            [-80.836918237999953, 35.194905875000075],
            [-80.836987238999939, 35.194935875000056],
            [-80.837039238999978, 35.194962875000044],
            [-80.837088237999978, 35.194992874000036],
            [-80.837134238999965, 35.195024875000058],
            [-80.837189237999951, 35.195068874000071],
            [-80.837229238999953, 35.195106875000079],
            [-80.837263237999935, 35.195143875000042],
            [-80.837757238999984, 35.195641875000035],
            [-80.838361238999937, 35.19624787500004],
            [-80.838396237999973, 35.196282875000065],
            [-80.838494238999942, 35.196209875000079],
            [-80.838605237999957, 35.196129875000054],
            [-80.83867523899994, 35.196084875000054],
            [-80.838735238999959, 35.196027875000027],
            [-80.838845238999966, 35.195910875000038],
            [-80.838943238999946, 35.195793875000049],
            [-80.839153238999984, 35.195601875000079],
            [-80.839295238999966, 35.195500874000061],
            [-80.839715238999986, 35.19518187500006],
            [-80.84001023899998, 35.194949874000031],
            [-80.840094238999939, 35.194875874000047],
            [-80.840241238999965, 35.194757874000061],
            [-80.840371239999968, 35.194678874000033],
            [-80.840425238999956, 35.194619874000068],
            [-80.84043423899999, 35.194589875000077],
            [-80.840474238999946, 35.194523875000073],
            [-80.840585239999939, 35.194385874000034],
            [-80.840651238999953, 35.194287874000054],
            [-80.840810239999939, 35.194109874000048],
            [-80.840870238999969, 35.194064874000048],
            [-80.840928238999936, 35.194011874000068],
            [-80.840977239999972, 35.193946874000062],
            [-80.841127239999935, 35.193799874000035],
            [-80.841302238999958, 35.193591874000049],
            [-80.841354239999987, 35.193523875000039],
            [-80.841479238999966, 35.193397874000027],
            [-80.84154123999997, 35.193307875000073],
            [-80.841783239999984, 35.192987874000039],
            [-80.841844239999944, 35.192891875000043],
            [-80.841911239999945, 35.192810874000031],
            [-80.842007239999987, 35.192672874000039],
            [-80.842141239999989, 35.192459874000065],
            [-80.842227239999943, 35.192382874000032],
            [-80.842267238999966, 35.192332874000044],
            [-80.842322239999987, 35.192245873000047],
            [-80.842389238999942, 35.19213187400004],
            [-80.842426239999952, 35.192083874000048],
            [-80.842529239999976, 35.191989874000058],
            [-80.842661238999938, 35.191854874000057],
            [-80.842686239999978, 35.19182087400003],
            [-80.842907239999988, 35.191517873000066],
            [-80.843003240999963, 35.191647874000068],
            [-80.843114239999977, 35.191656874000046],
            [-80.843189239999958, 35.191687874000024],
            [-80.843351239999947, 35.191858874000047],
            [-80.843530240999939, 35.191940874000068],
            [-80.843647239999939, 35.192010874000061],
            [-80.843679239999972, 35.192005873000028],
            [-80.843734239999947, 35.192036874000053],
            [-80.84375523999995, 35.192081873000063],
            [-80.843813239999974, 35.192109874000039],
            [-80.843930239999963, 35.192129874000045],
            [-80.843972239999971, 35.192223874000035],
            [-80.844151239999974, 35.192296873000032],
            [-80.844191240999976, 35.192375874000049],
            [-80.84426123999998, 35.192476874000079],
            [-80.844426240999951, 35.192601874000047],
            [-80.844475240999941, 35.192612874000076],
            [-80.844519240999944, 35.192623874000049],
            [-80.844597240999974, 35.192615874000069],
            [-80.844657240999936, 35.192644874000052],
            [-80.844676240999945, 35.192704875000061],
            [-80.844712240999968, 35.192771873000027],
            [-80.84478323999997, 35.192861873000027],
            [-80.844757240999968, 35.192932874000064],
            [-80.844870239999977, 35.193014874000028],
            [-80.84500423999998, 35.19310687400008],
            [-80.845033240999953, 35.193121874000042],
            [-80.84509824099996, 35.193146874000035],
            [-80.845159239999987, 35.193160874000057],
            [-80.845204240999976, 35.193164874000047],
            [-80.845310239999947, 35.193160874000057],
            [-80.845928240999967, 35.19338487400006],
            [-80.846207239999956, 35.193414874000041],
            [-80.84634024199994, 35.193455874000051],
            [-80.84646123999994, 35.193540874000064],
            [-80.846873240999969, 35.193678875000046],
            [-80.847059241999943, 35.193710874000033],
            [-80.847213241999953, 35.193769874000054],
            [-80.847385241999973, 35.193961874000024],
            [-80.847468241999934, 35.194046875000026],
            [-80.847619240999961, 35.194143874000076],
            [-80.847756241999946, 35.194194874000061],
            [-80.847952241999963, 35.194420874000059],
            [-80.848056241999984, 35.194511875000046],
            [-80.848143240999946, 35.194907875000069],
            [-80.848242241999969, 35.195077874000049],
            [-80.848497240999961, 35.195405875000063],
            [-80.84869224199997, 35.195524874000057],
            [-80.848818241999936, 35.195631874000071],
            [-80.848905241999944, 35.195705875000044],
            [-80.849123241999962, 35.195856874000071],
            [-80.849154240999951, 35.195899875000066],
            [-80.849262241999952, 35.195978875000037],
            [-80.84930424199996, 35.196024874000045],
            [-80.849322241999971, 35.196058875000062],
            [-80.849331241999948, 35.196104874000071],
            [-80.849383242999977, 35.196195875000058],
            [-80.849421241999949, 35.196250875000032],
            [-80.849454242999968, 35.196308875000057],
            [-80.849497241999984, 35.196314874000052],
            [-80.849551241999961, 35.196310875000052],
            [-80.849603241999944, 35.196320874000037],
            [-80.849648241999944, 35.196345875000077],
            [-80.849779241999954, 35.196360875000039],
            [-80.850050241999952, 35.196391874000028],
            [-80.850182242999949, 35.196303875000069],
            [-80.850691241999982, 35.196012875000065],
            [-80.850972242999944, 35.195851874000027],
            [-80.851061242999947, 35.195781874000033],
            [-80.851226241999939, 35.195889874000045],
            [-80.851310242999944, 35.195962874000031],
            [-80.851358242999936, 35.196021874000053],
            [-80.85137924299994, 35.196079874000077],
            [-80.851531242999954, 35.196646874000066],
            [-80.851706241999977, 35.196611874000041],
            [-80.851842241999975, 35.196595875000071],
            [-80.851980241999968, 35.196597875000066],
            [-80.852116242999955, 35.196615874000031],
            [-80.852182242999959, 35.196631874000047],
            [-80.852317242999959, 35.196676875000037],
            [-80.852381242999968, 35.196705874000031],
            [-80.852617242999941, 35.196838875000026],
            [-80.853088243999935, 35.197113874000024],
            [-80.853475243999981, 35.197339874000079],
            [-80.853885243999969, 35.197577875000036],
            [-80.853949243999978, 35.197620874000052],
            [-80.85400924299995, 35.197667875000036],
            [-80.854118242999959, 35.19777087500006],
            [-80.855339242999946, 35.198230874000046],
            [-80.856876243999977, 35.198307874000079],
            [-80.85940424599994, 35.197097874000065],
            [-80.863310245999969, 35.195229873000073],
            [-80.863758245999975, 35.195603874000028]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 18,
        "SolutionID": "PI-9",
        "Shape_Length": 0.56334187918638345,
        "Shape_Area": 0.0070391029187247519
      }
    },
    {
      "type": "Feature",
      "id": 19,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.973200279999958, 35.203534871000045],
            [-80.973377277999987, 35.203538872000024],
            [-80.973579278999978, 35.203528872000049],
            [-80.973694279999961, 35.203508872000043],
            [-80.973797279999985, 35.203478872000062],
            [-80.97390527899995, 35.203437872000052],
            [-80.97400227899999, 35.203385872000069],
            [-80.974147279999954, 35.203286872000035],
            [-80.974296278999986, 35.203148872000043],
            [-80.974393278999969, 35.203044871000031],
            [-80.974604279999937, 35.202799872000071],
            [-80.975083279999978, 35.202207871000041],
            [-80.976735279999957, 35.200204871000039],
            [-80.977002279999965, 35.199891871000034],
            [-80.977156280999964, 35.199730871000042],
            [-80.977413279999951, 35.199496870000075],
            [-80.977638279999951, 35.199317870000073],
            [-80.977791280999952, 35.199207870000066],
            [-80.977998279999952, 35.199081871000033],
            [-80.97822027999996, 35.198968870000044],
            [-80.979358280999975, 35.198437870000078],
            [-80.980368280999983, 35.197976870000048],
            [-80.980608280999945, 35.197859870000059],
            [-80.980732280999973, 35.197792870000058],
            [-80.980839280999987, 35.197727870000051],
            [-80.981013280999946, 35.197612870000057],
            [-80.981193281999936, 35.197466869000039],
            [-80.981354280999938, 35.197310870000024],
            [-80.981531280999945, 35.197121869000057],
            [-80.981663281999943, 35.196952870000075],
            [-80.981802280999943, 35.196734870000057],
            [-80.982626281999956, 35.195294870000055],
            [-80.983124282999938, 35.194390869000074],
            [-80.983286281999938, 35.194117869000024],
            [-80.983360281999978, 35.19391286900003],
            [-80.983487280999952, 35.19362686900007],
            [-80.983628281999984, 35.193238869000027],
            [-80.983804282999984, 35.19283486900008],
            [-80.98401628199997, 35.19238186900003],
            [-80.984750280999947, 35.191110868000067],
            [-80.984911282999974, 35.19085786900007],
            [-80.985076282999955, 35.190620868000053],
            [-80.985163281999974, 35.190516869000078],
            [-80.985303281999961, 35.190370868000059],
            [-80.985608281999987, 35.190106868000044],
            [-80.985961281999948, 35.189818869000078],
            [-80.98639228199994, 35.189479868000035],
            [-80.986745281999958, 35.189195867000024],
            [-80.987261281999963, 35.18877186800006],
            [-80.988205282999957, 35.187994867000043],
            [-80.988690282999983, 35.18758286700006],
            [-80.988978281999948, 35.187301868000077],
            [-80.989149282999961, 35.187107867000066],
            [-80.989339283999982, 35.186867868000036],
            [-80.989857282999935, 35.186124867000046],
            [-80.990416283999934, 35.185294867000039],
            [-80.990584282999976, 35.185021867000046],
            [-80.990676282999971, 35.184833867000066],
            [-80.990734282999938, 35.184686868000028],
            [-80.990784283999972, 35.184536867000077],
            [-80.990863283999943, 35.184227866000072],
            [-80.990899282999976, 35.184018867000077],
            [-80.990965283999969, 35.183644867000055],
            [-80.991111282999952, 35.182871866000028],
            [-80.991220282999961, 35.182304867000028],
            [-80.991522283999984, 35.180483866000031],
            [-80.991537282999957, 35.180294866000054],
            [-80.991538282999954, 35.180248866000056],
            [-80.991527282999982, 35.180098866000037],
            [-80.99150128399998, 35.179945866000025],
            [-80.991467282999963, 35.179802865000056],
            [-80.991380282999955, 35.179513865000047],
            [-80.991127282999969, 35.178819866000026],
            [-80.991046282999946, 35.178565866000042],
            [-80.991013282999972, 35.178416866000077],
            [-80.990998282999954, 35.178289866000057],
            [-80.990999282999951, 35.178194866000069],
            [-80.991009282999983, 35.178087865000066],
            [-80.991017283999952, 35.17803686600007],
            [-80.991030282999986, 35.177959866000037],
            [-80.991062283999952, 35.177815865000071],
            [-80.991105282999968, 35.177663865000056],
            [-80.991165282999987, 35.177476865000074],
            [-80.991251283999986, 35.177231865000067],
            [-80.99137428399996, 35.176933865000024],
            [-80.991456282999934, 35.176712866000059],
            [-80.991495282999949, 35.17657386500008],
            [-80.991511282999966, 35.176489865000065],
            [-80.991519282999946, 35.176411865000034],
            [-80.99151728399994, 35.176318865000042],
            [-80.991446282999959, 35.175761865000027],
            [-80.991399282999964, 35.175430864000077],
            [-80.991367282999988, 35.175289865000025],
            [-80.99132928299997, 35.175168865000046],
            [-80.99128428299997, 35.175078864000056],
            [-80.991222281999967, 35.174979864000079],
            [-80.991143282999985, 35.174880865000034],
            [-80.991000282999948, 35.174742865000042],
            [-80.990841283999941, 35.174608865000039],
            [-80.990683282999953, 35.17448386500007],
            [-80.990900282999974, 35.174288865000051],
            [-80.99091628299999, 35.17427086500004],
            [-80.991004283999985, 35.17417286500006],
            [-80.991111283999942, 35.174002865000034],
            [-80.991209282999989, 35.173730864000049],
            [-80.991236282999978, 35.17357686400004],
            [-80.991319282999939, 35.172868864000066],
            [-80.991312282999957, 35.172691864000058],
            [-80.991282282999975, 35.172347864000074],
            [-80.99125128299994, 35.171912864000035],
            [-80.991225282999949, 35.171779865000076],
            [-80.991182282999944, 35.171662864000041],
            [-80.991113282999947, 35.171541864000062],
            [-80.991040282999961, 35.17144486400008],
            [-80.990921282999977, 35.171330864000026],
            [-80.990810282999973, 35.171251865000045],
            [-80.990572282999949, 35.17111086400007],
            [-80.990177281999934, 35.17098786400004],
            [-80.990068282999971, 35.17093486400006],
            [-80.989959282999962, 35.170870864000051],
            [-80.989836281999942, 35.170790864000026],
            [-80.989390281999988, 35.170458863000079],
            [-80.98908628199996, 35.170215864000056],
            [-80.989025282999989, 35.170145864000062],
            [-80.988957282999934, 35.17004186500003],
            [-80.98880128199994, 35.169781864000072],
            [-80.98851128199999, 35.169387864000043],
            [-80.988428281999973, 35.169246864000058],
            [-80.988404281999976, 35.169153863000076],
            [-80.988395281999942, 35.169064864000063],
            [-80.988424281999983, 35.168967864000024],
            [-80.988477281999963, 35.168872864000036],
            [-80.988551281999946, 35.168768864000072],
            [-80.988663281999948, 35.168650864000028],
            [-80.988691281999934, 35.168632863000028],
            [-80.988876281999978, 35.168510864000041],
            [-80.988974282999948, 35.168465863000051],
            [-80.988976282999943, 35.16857086300007],
            [-80.989049281999939, 35.168538863000038],
            [-80.989114281999946, 35.168564863000029],
            [-80.989192281999976, 35.168628863000038],
            [-80.989257281999983, 35.16865286400008],
            [-80.989361282999937, 35.168675864000079],
            [-80.989514281999959, 35.168665863000058],
            [-80.989581281999961, 35.168645863000052],
            [-80.989665281999976, 35.168601863000049],
            [-80.989760281999963, 35.168532864000042],
            [-80.989791282999988, 35.16851786400008],
            [-80.989829282999949, 35.168508863000056],
            [-80.989865282999972, 35.168509863000054],
            [-80.989904282999987, 35.168519864000075],
            [-80.990264282999988, 35.168646863000049],
            [-80.990392281999959, 35.168653864000078],
            [-80.990449282999975, 35.168610863000026],
            [-80.990462281999953, 35.168591863000074],
            [-80.990602281999941, 35.168629863000035],
            [-80.990975282999955, 35.168732864000049],
            [-80.991261281999982, 35.168504863000067],
            [-80.991373283999962, 35.168414864000056],
            [-80.991713281999978, 35.168327864000048],
            [-80.992002281999987, 35.168382863000033],
            [-80.99259028299997, 35.168636863000074],
            [-80.993156282999962, 35.168989864000025],
            [-80.993700283999942, 35.169243864000066],
            [-80.994310283999937, 35.169470863000072],
            [-80.995098283999937, 35.169652864000057],
            [-80.995753283999989, 35.169770864000043],
            [-80.996363284999973, 35.169788864000054],
            [-80.996962284999938, 35.169688864000079],
            [-80.997351284999979, 35.169470863000072],
            [-80.998072284999978, 35.168844863000061],
            [-80.998372284999959, 35.168391863000068],
            [-80.998483284999963, 35.167846863000079],
            [-80.998749283999985, 35.167030863000036],
            [-80.998982284999954, 35.166486863000046],
            [-80.99965928599994, 35.165596863000076],
            [-81.000103284999966, 35.165324862000034],
            [-81.00108028599999, 35.16502586200005],
            [-81.001923285999965, 35.164925862000075],
            [-81.002789285999938, 35.164798862000055],
            [-81.003131285999984, 35.164789862000077],
            [-81.003943285999981, 35.164672862000032],
            [-81.004368285999988, 35.164655862000075],
            [-81.004295286999934, 35.164123862000054],
            [-81.00416828699997, 35.163645863000056],
            [-81.00414628599998, 35.16350286200003],
            [-81.004038285999968, 35.163198862000058],
            [-81.004010286999971, 35.163176862000057],
            [-81.003968286999964, 35.163173862000065],
            [-81.003929285999959, 35.163182862000042],
            [-81.003491285999985, 35.16349086200006],
            [-81.003449285999977, 35.163440862000073],
            [-81.003375285999937, 35.163376862000064],
            [-81.003001285999972, 35.163108862000058],
            [-81.002696285999946, 35.162889861000053],
            [-81.002535285999954, 35.162764862000074],
            [-81.002423285999953, 35.16264986200008],
            [-81.002349285999969, 35.162554862000036],
            [-81.00226328499997, 35.162416862000043],
            [-81.002091284999949, 35.162122862000047],
            [-81.001964285999975, 35.161886861000028],
            [-81.001920285999972, 35.161813862000031],
            [-81.001886285999944, 35.161758861000067],
            [-81.001721285999963, 35.161519862000034],
            [-81.001635284999963, 35.161425861000055],
            [-81.001510285999984, 35.161307862000058],
            [-81.001428284999974, 35.16123286100003],
            [-81.001285284999938, 35.161118861000034],
            [-81.000376284999959, 35.160446862000072],
            [-80.999666283999943, 35.159926861000031],
            [-80.99943428499995, 35.15976186100005],
            [-80.999117284999954, 35.159547862000068],
            [-80.998460283999975, 35.15911686100003],
            [-80.998348284999963, 35.159049861000028],
            [-80.998254283999984, 35.159003862000077],
            [-80.998180284999989, 35.158980861000032],
            [-80.998103284999956, 35.15896186100008],
            [-80.998032283999976, 35.158950861000051],
            [-80.997765283999968, 35.158933861000037],
            [-80.997311282999988, 35.158934861000034],
            [-80.997043283999972, 35.158942861000071],
            [-80.996858282999938, 35.158957861000033],
            [-80.996686283999964, 35.158978861000037],
            [-80.996508283999958, 35.159006860000034],
            [-80.996170283999959, 35.159072861000027],
            [-80.995443283999975, 35.159239862000049],
            [-80.99530228399999, 35.159268861000044],
            [-80.994948282999985, 35.159337862000029],
            [-80.994623283999942, 35.159389861000079],
            [-80.994249282999988, 35.159442861000059],
            [-80.994049281999935, 35.159461861000068],
            [-80.993764282999962, 35.159481861000074],
            [-80.993258282999989, 35.159507861000066],
            [-80.992967282999984, 35.159512862000042],
            [-80.99267628299998, 35.15950986200005],
            [-80.992233282999962, 35.159479861000079],
            [-80.991962281999974, 35.159448862000033],
            [-80.991767281999955, 35.159414862000062],
            [-80.991595282999981, 35.159379861000048],
            [-80.991150282999968, 35.159269862000031],
            [-80.990565281999977, 35.15911286100004],
            [-80.990333281999938, 35.159042862000035],
            [-80.98973928099997, 35.158845862000078],
            [-80.989565281999944, 35.158786861000067],
            [-80.988595280999959, 35.158455862000039],
            [-80.988349280999955, 35.158374862000073],
            [-80.988328281999941, 35.158367861000045],
            [-80.98746928099996, 35.158086861000072],
            [-80.985365280999986, 35.157384862000072],
            [-80.983882279999989, 35.156879862000039],
            [-80.98536527999994, 35.155597860000057],
            [-80.986323281999944, 35.154782861000058],
            [-80.987484280999979, 35.153781860000038],
            [-80.98881228099998, 35.152635860000032],
            [-80.988904280999975, 35.152545860000032],
            [-80.988967280999987, 35.152477860000033],
            [-80.989031280999939, 35.152382860000046],
            [-80.989072281999938, 35.152284860000066],
            [-80.989092281999945, 35.152195860000063],
            [-80.989096280999945, 35.152097860000026],
            [-80.989087280999968, 35.151937860000032],
            [-80.989078280999934, 35.151782860000026],
            [-80.989084280999975, 35.151713860000029],
            [-80.989101280999989, 35.151633860000061],
            [-80.989143281999986, 35.151533860000029],
            [-80.989202280999962, 35.151442860000031],
            [-80.989302281999983, 35.15134585900006],
            [-80.989494280999963, 35.15118386000006],
            [-80.990072281999971, 35.150713860000053],
            [-80.990716280999948, 35.150189860000069],
            [-80.990807281999935, 35.15012586000006],
            [-80.990879280999934, 35.15008086000006],
            [-80.991066280999974, 35.149982859000033],
            [-80.991157281999961, 35.149940859000026],
            [-80.991623281999978, 35.14976985900006],
            [-80.993163282999944, 35.149237859000038],
            [-80.995045282999968, 35.148574859000064],
            [-80.995525282999949, 35.148380858000053],
            [-80.995650281999986, 35.148330859000055],
            [-80.995983282999987, 35.148201859000039],
            [-80.996171283999956, 35.148127859000056],
            [-80.996523283999977, 35.147971859000052],
            [-80.997820283999943, 35.14740185900007],
            [-80.998388283999986, 35.147162859000048],
            [-80.998700283999938, 35.147045859000059],
            [-80.999220282999943, 35.146881859000075],
            [-80.999561283999981, 35.146802859000047],
            [-80.99986228399996, 35.146763858000043],
            [-81.000486283999976, 35.146714859000042],
            [-81.000807284999951, 35.146678859000076],
            [-81.000985283999967, 35.146640859000058],
            [-81.001119284999959, 35.146597859000053],
            [-81.001244283999938, 35.146546858000079],
            [-81.001424283999938, 35.146453859000076],
            [-81.001554284999941, 35.146362859000078],
            [-81.001644284999941, 35.146289858000046],
            [-81.001733284999943, 35.146207859000071],
            [-81.001852284999984, 35.146089858000039],
            [-81.001926284999968, 35.14601385800006],
            [-81.002028284999938, 35.145906858000046],
            [-81.002076284999987, 35.145855859000051],
            [-81.002000284999951, 35.145801859000073],
            [-81.001979284999948, 35.145793858000047],
            [-81.001962284999934, 35.14578085800008],
            [-81.00189228399995, 35.145722858000056],
            [-81.001868283999954, 35.145692858000075],
            [-81.001841284999955, 35.145658859000037],
            [-81.001804283999945, 35.145591858000046],
            [-81.001794283999971, 35.145542858000056],
            [-81.001791283999978, 35.145496858000058],
            [-81.001815284999964, 35.145438858000034],
            [-81.001856284999974, 35.145325858000035],
            [-81.001958284999944, 35.145168858000034],
            [-81.002097284999934, 35.144989858000031],
            [-81.002130284999964, 35.144931857000074],
            [-81.002105283999981, 35.144842858000061],
            [-81.002064283999971, 35.144797858000061],
            [-81.002030284999989, 35.144768859000067],
            [-81.00199728399997, 35.144746859000065],
            [-81.001874283999939, 35.144658858000071],
            [-81.001803283999948, 35.144598858000052],
            [-81.001717284999984, 35.144537857000046],
            [-81.00156528399998, 35.144402858000035],
            [-81.001502283999969, 35.144342858000073],
            [-81.001452284999971, 35.144295858000078],
            [-81.001328283999953, 35.14416385800007],
            [-81.001219283999944, 35.144030857000075],
            [-81.001158284999974, 35.143928858000038],
            [-81.001077284999951, 35.143827858000066],
            [-81.000954283999988, 35.143663858000025],
            [-81.000747283999942, 35.143368857000041],
            [-81.000523283999939, 35.14304585800005],
            [-81.00045828399999, 35.142951857000071],
            [-81.000383283999952, 35.142839858000059],
            [-81.000298283999939, 35.142743857000028],
            [-81.000132282999971, 35.142537857000036],
            [-81.000009283999987, 35.142383857000027],
            [-80.999883283999964, 35.142247857000029],
            [-80.999784283999986, 35.142151858000034],
            [-80.999671283999987, 35.14204085700004],
            [-80.999500283999964, 35.141871857000069],
            [-80.999292283999978, 35.141682858000024],
            [-80.999102283999946, 35.141518857000051],
            [-80.99881628299994, 35.141310858000054],
            [-80.998686283999973, 35.141218858000059],
            [-80.99855228399997, 35.141146857000024],
            [-80.998346283999979, 35.141054857000029],
            [-80.998100283999975, 35.140964858000075],
            [-80.997907282999961, 35.140883858000052],
            [-80.997636282999963, 35.140748857000062],
            [-80.997527282999954, 35.140678857000069],
            [-80.997270282999978, 35.140813857000069],
            [-80.996684282999979, 35.141092858000036],
            [-80.996678282999937, 35.141080857000077],
            [-80.99664328199998, 35.141009857000029],
            [-80.996629282999947, 35.140945858000066],
            [-80.996652281999957, 35.140887858000042],
            [-80.99671428399995, 35.140784858000075],
            [-80.996758282999963, 35.140740857000026],
            [-80.996816283999976, 35.140690858000028],
            [-80.996819282999979, 35.140607857000077],
            [-80.996792282999934, 35.140556858000025],
            [-80.996605282999951, 35.140540857000076],
            [-80.996535282999957, 35.14050985800003],
            [-80.996465283999953, 35.140469857000028],
            [-80.996389281999939, 35.140443857000037],
            [-80.996309283999949, 35.140393858000039],
            [-80.996165282999982, 35.140277857000058],
            [-80.996031282999979, 35.140157857000077],
            [-80.995979281999951, 35.140132857000026],
            [-80.995825281999942, 35.140130857000031],
            [-80.99575428199995, 35.14010485700004],
            [-80.995674281999982, 35.14010085700005],
            [-80.995594281999956, 35.140101857000047],
            [-80.995447282999976, 35.140093857000068],
            [-80.995361281999976, 35.14008485800008],
            [-80.995286281999938, 35.140089858000067],
            [-80.995266282999978, 35.140099858000042],
            [-80.995151282999984, 35.139980857000069],
            [-80.99509328299996, 35.139855857000043],
            [-80.995059282999989, 35.139716857000053],
            [-80.995050281999966, 35.139620858000058],
            [-80.995047281999973, 35.139593857000079],
            [-80.995099281999956, 35.139486856000076],
            [-80.995599281999944, 35.139535857000055],
            [-80.995816282999954, 35.139662856000029],
            [-80.995919282999978, 35.139643857000067],
            [-80.996063282999955, 35.139602857000057],
            [-80.996283282999968, 35.139563856000052],
            [-80.996482282999978, 35.13954185700004],
            [-80.996688282999969, 35.13953785700005],
            [-80.996868282999969, 35.139523857000029],
            [-80.99694328299995, 35.139546856000038],
            [-80.997011282999949, 35.139595858000064],
            [-80.99713428299998, 35.13950085700003],
            [-80.997298283999953, 35.139396857000065],
            [-80.997377282999935, 35.139308858000049],
            [-80.997452282999973, 35.139257857000075],
            [-80.997508282999945, 35.139227857000037],
            [-80.997534283999983, 35.139166857000077],
            [-80.997541282999975, 35.13903485700007],
            [-80.997549282999955, 35.138925857000061],
            [-80.99754328299997, 35.138855857000067],
            [-80.99756228199999, 35.138691856000037],
            [-80.997579281999947, 35.138643857000034],
            [-80.997609282999974, 35.138598857000034],
            [-80.997661282999957, 35.138534857000025],
            [-80.99769728299998, 35.138458857000046],
            [-80.997721283999965, 35.138397857000029],
            [-80.997756282999944, 35.138343857000052],
            [-80.997856282999976, 35.138289856000029],
            [-80.997944283999971, 35.138273857000058],
            [-80.998029282999937, 35.138228857000058],
            [-80.99805728299998, 35.138182857000061],
            [-80.998189282999988, 35.138088857000071],
            [-80.998361282999952, 35.137906856000029],
            [-80.998386282999945, 35.137909857000068],
            [-80.998428283999942, 35.137926856000036],
            [-80.998330282999973, 35.138031857000044],
            [-80.998211282999989, 35.138175857000078],
            [-80.998195283999962, 35.138283857000033],
            [-80.998373283999967, 35.138145856000051],
            [-80.998422282999968, 35.138105856000038],
            [-80.998471283999947, 35.138069857000062],
            [-80.998529283999972, 35.138073857000052],
            [-80.998574282999982, 35.138114856000072],
            [-80.998979283999972, 35.138647857000024],
            [-81.000386283999944, 35.137829856000053],
            [-81.002630283999963, 35.138692856000034],
            [-81.004470284999968, 35.13774085600005],
            [-81.005633284999988, 35.13557185600007],
            [-81.006634284999961, 35.134798856000032],
            [-81.003779283999961, 35.132147855000028],
            [-81.00333328399995, 35.131712855000046],
            [-81.002831283999967, 35.13123885400006],
            [-81.002203283999961, 35.130662855000026],
            [-81.001639283999964, 35.130119855000032],
            [-81.001144284999953, 35.129652855000074],
            [-81.000007282999945, 35.128617855000073],
            [-80.999079281999968, 35.127769854000064],
            [-80.99899628299994, 35.127693854000029],
            [-80.998573281999938, 35.127327855000033],
            [-80.998134281999967, 35.126956854000071],
            [-80.99788128299997, 35.126753854000071],
            [-80.997569282999962, 35.126488854000058],
            [-80.997444281999947, 35.126374855000051],
            [-80.997358282999983, 35.126284853000072],
            [-80.997278281999968, 35.126179854000043],
            [-80.997210281999969, 35.126062854000054],
            [-80.997141281999973, 35.125909855000032],
            [-80.997106281999947, 35.125815853000063],
            [-80.997073281999974, 35.125704854000048],
            [-80.996987281999964, 35.125312854000072],
            [-80.996946282999943, 35.125059854000028],
            [-80.996836282999936, 35.124463854000055],
            [-80.996783281999967, 35.124138853000034],
            [-80.996725281999943, 35.123649854000064],
            [-80.996668280999984, 35.123342853000054],
            [-80.996659282999985, 35.123288854000066],
            [-80.996559281999964, 35.122940853000046],
            [-80.996519281999952, 35.122827854000036],
            [-80.996474281999951, 35.122724853000079],
            [-80.99640328199996, 35.122593854000058],
            [-80.996365282999989, 35.122537854000029],
            [-80.996212281999988, 35.122350853000057],
            [-80.996127281999975, 35.122256853000067],
            [-80.996034281999982, 35.122173853000049],
            [-80.995913281999947, 35.122076853000067],
            [-80.995606281999983, 35.121851854000056],
            [-80.995535280999945, 35.121790854000039],
            [-80.995483280999963, 35.121736854000062],
            [-80.995442281999942, 35.121676854000043],
            [-80.995408281999971, 35.121614854000029],
            [-80.995354280999948, 35.121454853000046],
            [-80.995003281999971, 35.120029853000062],
            [-80.995007280999971, 35.11999385300004],
            [-80.995027280999977, 35.119952853000029],
            [-80.995314281999981, 35.120105853000041],
            [-80.997256281999967, 35.121148853000079],
            [-80.997688282999945, 35.121372852000036],
            [-80.997950281999977, 35.121484854000073],
            [-80.998235281999939, 35.121583853000061],
            [-80.998466281999981, 35.121644853000078],
            [-80.998703282999941, 35.12169285300007],
            [-80.998846281999988, 35.121715854000058],
            [-80.999084281999956, 35.12174585300005],
            [-80.999758282999949, 35.121814854000036],
            [-81.002567283999952, 35.122081854000044],
            [-81.00383528499998, 35.122201853000035],
            [-81.004973283999959, 35.122310852000055],
            [-81.005339283999945, 35.122334853000041],
            [-81.005708284999969, 35.122336853000036],
            [-81.005978284999969, 35.122325852000074],
            [-81.006274284999961, 35.122295853000026],
            [-81.006644284999936, 35.122236853000061],
            [-81.00953928499996, 35.121673853000061],
            [-81.010808285999985, 35.121417853000025],
            [-81.01119728599997, 35.121342853000044],
            [-81.011676286999943, 35.121257853000031],
            [-81.011746286999937, 35.121234852000043],
            [-81.011776285999986, 35.121219852000024],
            [-81.011843286999976, 35.121167853000031],
            [-81.011352285999976, 35.120982852000054],
            [-81.010935286999938, 35.120812853000075],
            [-81.01063828599996, 35.120670852000046],
            [-81.010511285999939, 35.120600853000042],
            [-81.010262285999943, 35.120446853000033],
            [-81.010119285999963, 35.12034985300005],
            [-81.009878285999946, 35.12016985200006],
            [-81.009636284999942, 35.119978852000031],
            [-81.009098284999936, 35.119543852000049],
            [-81.008549284999958, 35.119093852000049],
            [-81.007782284999962, 35.118464852000045],
            [-81.006984284999987, 35.117824852000069],
            [-81.006536284999981, 35.117465852000066],
            [-81.005935284999964, 35.116967851000027],
            [-81.005774283999983, 35.116822852000041],
            [-81.005602283999963, 35.116651851000029],
            [-81.005451284999936, 35.116474852000067],
            [-81.005326282999988, 35.116305852000039],
            [-81.005311283999959, 35.116284851000046],
            [-81.005244282999968, 35.116185852000058],
            [-81.00512228499997, 35.115990852000039],
            [-81.004982284999983, 35.115739852000047],
            [-81.004854282999986, 35.115448851000053],
            [-81.004789284999958, 35.115260852000063],
            [-81.004738283999984, 35.11507385200008],
            [-81.00467228399998, 35.114709852000033],
            [-81.004449282999985, 35.113187851000077],
            [-81.004432283999961, 35.113068851000037],
            [-81.00440228399998, 35.112704852000036],
            [-81.00440228399998, 35.112604851000071],
            [-81.004412283999955, 35.112442851000026],
            [-81.004435283999953, 35.112270851000062],
            [-81.00445128399997, 35.112188851000042],
            [-81.004518283999971, 35.11194585100003],
            [-81.004573283999946, 35.111807851000037],
            [-81.004613282999969, 35.111706851000065],
            [-81.004640282999958, 35.111653851000028],
            [-81.004700282999977, 35.111537851000037],
            [-81.004810283999973, 35.111355851000042],
            [-81.005259283999976, 35.110684850000041],
            [-81.005475284999989, 35.110372850000033],
            [-81.005560283999955, 35.110243851000064],
            [-81.005790283999943, 35.109896850000041],
            [-81.006067283999982, 35.10949585000003],
            [-81.007452283999953, 35.107426850000024],
            [-81.007936283999982, 35.106713849000073],
            [-81.008052284999962, 35.106533849000073],
            [-81.008173284999941, 35.106303849000028],
            [-81.008285283999953, 35.106019850000052],
            [-81.008335284999987, 35.105842850000045],
            [-81.008340283999985, 35.105816850000053],
            [-81.00836828499996, 35.105677849000074],
            [-81.008381284999984, 35.105589849000069],
            [-81.008394284999952, 35.105340849000072],
            [-81.008385283999985, 35.104959850000057],
            [-81.008331283999951, 35.103512849000026],
            [-81.008317284999976, 35.103193848000046],
            [-81.008308284999941, 35.103088849000073],
            [-81.008294283999987, 35.102919849000045],
            [-81.008258284999954, 35.102677849000031],
            [-81.008204283999987, 35.102408849000028],
            [-81.008052283999973, 35.101751848000049],
            [-81.007955283999934, 35.101361848000067],
            [-81.00764828399997, 35.099961848000078],
            [-81.007576284999971, 35.099681849000035],
            [-81.007535283999971, 35.099542848000056],
            [-81.007435283999939, 35.09927884800004],
            [-81.007301283999936, 35.098977848000061],
            [-81.006613282999979, 35.097577847000025],
            [-81.006018282999946, 35.096339848000071],
            [-81.005854282999962, 35.095997847000035],
            [-81.005163283999934, 35.094582847000027],
            [-81.005054281999946, 35.094386847000067],
            [-81.004978282999957, 35.094218848000025],
            [-81.00493628199996, 35.094083847000036],
            [-81.004918281999949, 35.093952846000036],
            [-81.004926282999975, 35.093835847000037],
            [-81.004947282999979, 35.093731847000072],
            [-81.004986282999937, 35.093633847000035],
            [-81.005041282999969, 35.093532847000063],
            [-81.00514928299998, 35.093404846000055],
            [-81.005299283999989, 35.093276847000027],
            [-81.005658281999956, 35.092988847000072],
            [-81.005830282999966, 35.092840846000058],
            [-81.00595328299994, 35.092709847000037],
            [-81.006050282999979, 35.092592847000049],
            [-81.006120282999973, 35.092493846000025],
            [-81.006278282999972, 35.092233846000056],
            [-81.006462283999952, 35.091790846000038],
            [-81.006990283999983, 35.090421846000027],
            [-81.007379283999967, 35.08946684600005],
            [-81.00755828399997, 35.088980846000027],
            [-81.00761128299996, 35.088883845000055],
            [-81.007557282999983, 35.088825845000031],
            [-81.007469282999978, 35.088699846000054],
            [-81.006809282999939, 35.087657845000024],
            [-81.006568281999989, 35.087265845000047],
            [-81.004596281999966, 35.08406384400007],
            [-81.004449281999939, 35.083831845000077],
            [-81.004376281999953, 35.083857844000079],
            [-81.004269281999939, 35.083873844000038],
            [-81.00404628199999, 35.083869844000048],
            [-81.003144280999948, 35.083810844000027],
            [-81.002668281999945, 35.083786845000077],
            [-81.002121280999972, 35.083770844000071],
            [-81.001378281999962, 35.083755845000042],
            [-81.000652280999986, 35.083747845000062],
            [-81.000531280999951, 35.083751844000062],
            [-81.000331279999955, 35.083767845000068],
            [-81.000093279999987, 35.083811845000071],
            [-80.999028279999948, 35.084103845000072],
            [-80.998537279999937, 35.08424384500006],
            [-80.998271280999973, 35.084334845000058],
            [-80.998177280999982, 35.084372845000075],
            [-80.997987279999961, 35.084460845000024],
            [-80.997814279999943, 35.084554845000071],
            [-80.997119279999936, 35.084944845000052],
            [-80.996377278999944, 35.085362846000066],
            [-80.995933279999974, 35.085612845000071],
            [-80.995579279999959, 35.085801846000038],
            [-80.995277278999936, 35.08595884500005],
            [-80.995074279999983, 35.08605284500004],
            [-80.994942279999975, 35.086106846000064],
            [-80.994703278999964, 35.086189845000035],
            [-80.994559278999986, 35.086226845000056],
            [-80.99450627899995, 35.086240846000067],
            [-80.994314279999969, 35.086273845000051],
            [-80.994142278999959, 35.086291846000051],
            [-80.992358278999973, 35.086443845000076],
            [-80.991758277999963, 35.086443846000066],
            [-80.991121278999969, 35.086468846000059],
            [-80.990679277999959, 35.086480846000029],
            [-80.990253277999955, 35.086478846000034],
            [-80.989957277999963, 35.086463846000072],
            [-80.989657277999981, 35.086435846000029],
            [-80.989217277999956, 35.086387846000036],
            [-80.988907277999942, 35.086349845000029],
            [-80.988696277999964, 35.086323846000028],
            [-80.987782276999951, 35.086203846000046],
            [-80.98695627699999, 35.086088846000052],
            [-80.986611276999952, 35.086049846000037],
            [-80.986384276999956, 35.086032845000034],
            [-80.986206276999951, 35.086018846000059],
            [-80.986107277999963, 35.086013846000071],
            [-80.985759276999943, 35.085997846000055],
            [-80.98475127599994, 35.085949846000062],
            [-80.983787276999976, 35.085894847000077],
            [-80.983556275999945, 35.085883846000058],
            [-80.983240275999947, 35.085868846000039],
            [-80.982855274999963, 35.08586084600006],
            [-80.982749275999936, 35.085858846000065],
            [-80.982600275999971, 35.08586084600006],
            [-80.98162627499994, 35.085873846000027],
            [-80.980192274999979, 35.085891846000038],
            [-80.97920127499998, 35.085921846000076],
            [-80.977797274999944, 35.08597284700005],
            [-80.976396273999967, 35.086022846000049],
            [-80.976218273999962, 35.086026847000028],
            [-80.976027273999989, 35.086024847000033],
            [-80.975935272999948, 35.086012846000074],
            [-80.975811273999966, 35.085978846000046],
            [-80.975692273999982, 35.085929846000056],
            [-80.975625272999935, 35.085893846000033],
            [-80.975557273999982, 35.085849846000031],
            [-80.975019273999976, 35.085443847000079],
            [-80.974728272999982, 35.085238846000038],
            [-80.974556272999962, 35.085138846000063],
            [-80.974433272999988, 35.085078846000044],
            [-80.974289273999943, 35.085024846000067],
            [-80.974091272999942, 35.08497184600003],
            [-80.972702271999935, 35.084673846000044],
            [-80.972748272999979, 35.084487846000059],
            [-80.972806272999946, 35.084137846000033],
            [-80.977656273999969, 35.081346846000031],
            [-80.97842527499995, 35.08090384500008],
            [-80.978360273999954, 35.080898845000036],
            [-80.978174273999969, 35.080874845000039],
            [-80.978013273999977, 35.080860845000075],
            [-80.977654273999974, 35.080856846000074],
            [-80.977500273999965, 35.080879845000027],
            [-80.977392273999953, 35.080891845000053],
            [-80.97727327399997, 35.080897845000038],
            [-80.977112273999978, 35.08091184500006],
            [-80.977014273999941, 35.080955845000062],
            [-80.976626273999955, 35.080994845000077],
            [-80.976304272999982, 35.081035845000031],
            [-80.976232272999937, 35.081044845000065],
            [-80.975256272999957, 35.081027845000051],
            [-80.975022272999979, 35.080995845000075],
            [-80.974787272999947, 35.080934845000058],
            [-80.974547271999938, 35.080951845000072],
            [-80.974340272999939, 35.081039845000078],
            [-80.973985272999982, 35.081253846000038],
            [-80.973634272999959, 35.081370846000027],
            [-80.973571272999948, 35.081391845000041],
            [-80.973330272999988, 35.081374845000028],
            [-80.973163272999955, 35.081303845000036],
            [-80.973069272999965, 35.081166846000031],
            [-80.972969271999943, 35.080913845000055],
            [-80.972908271999984, 35.080621846000042],
            [-80.97289527199996, 35.080402845000037],
            [-80.972821272999965, 35.080264845000045],
            [-80.972687271999973, 35.080055845000061],
            [-80.972206271999937, 35.079632846000038],
            [-80.972107272999949, 35.079577845000074],
            [-80.972007272999974, 35.079522845000042],
            [-80.971938271999989, 35.079484845000025],
            [-80.971664271999941, 35.079314845000056],
            [-80.971477271999959, 35.07917184400003],
            [-80.971330271999989, 35.079022845000054],
            [-80.97124327299997, 35.078868845000045],
            [-80.971142271999952, 35.078769845000068],
            [-80.970903271999987, 35.078673845000026],
            [-80.970814270999938, 35.078638845000057],
            [-80.970661271999973, 35.078489844000046],
            [-80.970594270999982, 35.078341845000068],
            [-80.970513270999959, 35.077995845000032],
            [-80.970366271999978, 35.077841844000034],
            [-80.970219271999952, 35.077786844000059],
            [-80.969651270999975, 35.07770384500003],
            [-80.969423270999982, 35.07761684500008],
            [-80.969203270999969, 35.077506845000073],
            [-80.968728270999975, 35.077324844000032],
            [-80.968540270999938, 35.077215845000069],
            [-80.96838027199999, 35.077105844000073],
            [-80.968226270999935, 35.076929844000063],
            [-80.968139270999984, 35.076670845000024],
            [-80.968206270999985, 35.076368844000058],
            [-80.968192269999975, 35.076148844000045],
            [-80.968192269999975, 35.075879844000042],
            [-80.968226271999981, 35.075549844000079],
            [-80.968185270999982, 35.075324845000068],
            [-80.968165270999975, 35.075241844000061],
            [-80.968112269999949, 35.075109845000043],
            [-80.968005270999981, 35.074911844000042],
            [-80.967804270999977, 35.074703844000055],
            [-80.967664269999943, 35.07447284400007],
            [-80.967503270999941, 35.074246844000072],
            [-80.967262270999981, 35.074010844000043],
            [-80.966827269999953, 35.073686844000065],
            [-80.966312269999946, 35.073351844000058],
            [-80.966052269999977, 35.073153844000046],
            [-80.965657269999951, 35.072862844000042],
            [-80.965316270999949, 35.072680844000047],
            [-80.965055268999947, 35.072598844000026],
            [-80.964714269999945, 35.072554844000024],
            [-80.964400269999942, 35.072543844000052],
            [-80.964039268999954, 35.072549844000037],
            [-80.963430268999957, 35.072609844000056],
            [-80.963243268999975, 35.072587844000054],
            [-80.963022268999964, 35.072500844000047],
            [-80.962755268999956, 35.072313844000064],
            [-80.962414269999954, 35.072032843000045],
            [-80.96208626899994, 35.071670844000039],
            [-80.961819268999989, 35.071329843000058],
            [-80.961498268999947, 35.070873844000062],
            [-80.961270267999964, 35.070515843000067],
            [-80.96092226899998, 35.070087843000067],
            [-80.960595268999953, 35.069653843000026],
            [-80.959812267999951, 35.068575844000065],
            [-80.95966526899997, 35.068355843000063],
            [-80.959457267999937, 35.06822984300004],
            [-80.959384266999962, 35.068185844000027],
            [-80.958943267999985, 35.067998843000055],
            [-80.95866826799994, 35.067817843000057],
            [-80.958301266999968, 35.067504843000052],
            [-80.957859266999947, 35.067141842000069],
            [-80.957572266999989, 35.066949843000032],
            [-80.956910266999955, 35.066569842000035],
            [-80.956428266999978, 35.066262843000061],
            [-80.95630826699994, 35.066190843000072],
            [-80.955833266999946, 35.065877843000067],
            [-80.955399265999972, 35.065701843000056],
            [-80.954416265999953, 35.065251843000055],
            [-80.953974265999989, 35.064976842000078],
            [-80.953788265999947, 35.064888843000062],
            [-80.953764265999951, 35.064917843000046],
            [-80.951581265999948, 35.067502844000046],
            [-80.950209263999966, 35.069366844000058],
            [-80.949195263999968, 35.070717844000058],
            [-80.94872926499994, 35.071338845000071],
            [-80.945504264999954, 35.075655845000028],
            [-80.94433526499995, 35.077262846000053],
            [-80.94401226399998, 35.077705845000025],
            [-80.943672264999975, 35.078112846000067],
            [-80.943050263999964, 35.078883846000053],
            [-80.941912263999939, 35.080391847000044],
            [-80.940955262999978, 35.081690847000061],
            [-80.940404261999959, 35.08246884600004],
            [-80.93980226299999, 35.083316848000038],
            [-80.93833626299994, 35.085304847000032],
            [-80.937341261999961, 35.086628848000032],
            [-80.937166261999948, 35.086857848000079],
            [-80.937153261999981, 35.086873848000039],
            [-80.936255262999964, 35.088041848000046],
            [-80.935204261999957, 35.089504849000036],
            [-80.93420426199998, 35.090785849000042],
            [-80.933658261999938, 35.091616849000047],
            [-80.933445260999974, 35.091952850000041],
            [-80.933152261999965, 35.092635850000079],
            [-80.932853260999934, 35.093496850000065],
            [-80.932723261999968, 35.093871849000038],
            [-80.932478261999961, 35.095227850000072],
            [-80.93228826099994, 35.096596850000026],
            [-80.932139260999975, 35.097968850000029],
            [-80.931967261999944, 35.099375851000048],
            [-80.931797260999986, 35.100652851000063],
            [-80.931789261999938, 35.100714851000077],
            [-80.931746260999944, 35.100960851000025],
            [-80.931062261999955, 35.103240852000056],
            [-80.931147261999968, 35.103332851000062],
            [-80.931237261999968, 35.103429852000033],
            [-80.93116226099994, 35.10367085200005],
            [-80.930769260999966, 35.10464685200003],
            [-80.930326261999937, 35.105568852000033],
            [-80.930261260999941, 35.105685852000079],
            [-80.930094261999955, 35.10598585200006],
            [-80.928914259999942, 35.107856853000044],
            [-80.928455260999954, 35.108583852000038],
            [-80.927860259999989, 35.109572853000032],
            [-80.927636260999975, 35.109910853000031],
            [-80.927375260999952, 35.110335853000038],
            [-80.927095260999977, 35.110769854000068],
            [-80.92684226099999, 35.111139854000044],
            [-80.926421259999984, 35.111734854000076],
            [-80.926207259999956, 35.112129854000045],
            [-80.925346259999969, 35.113436854000042],
            [-80.924321260999989, 35.114891855000053],
            [-80.924448258999973, 35.114955854000073],
            [-80.924926260999939, 35.115217855000026],
            [-80.925855259999935, 35.115687855000033],
            [-80.926225259999967, 35.11585085400003],
            [-80.926477259999956, 35.115978855000037],
            [-80.92703026099997, 35.116215854000075],
            [-80.926151259999983, 35.117638855000052],
            [-80.925591259999976, 35.117942855000024],
            [-80.925399259999949, 35.118038855000066],
            [-80.925369260999958, 35.118314855000051],
            [-80.925371260999952, 35.118368855000028],
            [-80.925366259999976, 35.118409855000039],
            [-80.92535626099999, 35.118446855000059],
            [-80.925343259999977, 35.118475856000032],
            [-80.925149259999955, 35.11878785600004],
            [-80.924423260999959, 35.119914855000047],
            [-80.924088259999962, 35.120448856000053],
            [-80.92422425999996, 35.120509856000069],
            [-80.924330258999987, 35.120556855000075],
            [-80.925054260999957, 35.120870856000067],
            [-80.925799260999952, 35.121182856000075],
            [-80.925978259999965, 35.121257856000057],
            [-80.926403260999962, 35.121450856000024],
            [-80.926587261999941, 35.121545856000068],
            [-80.926823261999971, 35.121688856000048],
            [-80.926984259999983, 35.121806856000035],
            [-80.927142260999972, 35.121944856000027],
            [-80.927244261999988, 35.122043856000062],
            [-80.92737226099996, 35.122189856000034],
            [-80.927471260999937, 35.122321856000042],
            [-80.927561261999983, 35.122461856000029],
            [-80.927588260999983, 35.122516856000061],
            [-80.92794926199997, 35.123170856000058],
            [-80.928210260999947, 35.123640856000065],
            [-80.929135261999988, 35.125349856000071],
            [-80.929356262999988, 35.125736857000049],
            [-80.929477261999978, 35.125939856000059],
            [-80.929698261999988, 35.126345856000057],
            [-80.930038261999982, 35.12694885600007],
            [-80.930370262999986, 35.127549857000076],
            [-80.930738261999977, 35.128242857000032],
            [-80.931406262999985, 35.129450857000052],
            [-80.93151026299995, 35.129631857000049],
            [-80.931552262999958, 35.129705858000079],
            [-80.93202026299997, 35.130569858000058],
            [-80.932140262999951, 35.130785858000024],
            [-80.932361262999962, 35.131222857000068],
            [-80.932390262999945, 35.131262858000071],
            [-80.932415262999939, 35.131281858000079],
            [-80.932444262999979, 35.131290857000067],
            [-80.931495262999988, 35.131622858000071],
            [-80.924902260999943, 35.133981858000027],
            [-80.925713261999988, 35.135429858000066],
            [-80.926146261999975, 35.13612985900005],
            [-80.926227260999951, 35.136259859000063],
            [-80.92677226099994, 35.137033859000042],
            [-80.927027261999967, 35.137360859000069],
            [-80.927295260999983, 35.137691859000029],
            [-80.927530261999948, 35.137962860000073],
            [-80.927753261999953, 35.138202859000046],
            [-80.92868426299998, 35.139148859000045],
            [-80.928969262999942, 35.139418859000045],
            [-80.929300262999959, 35.139707860000044],
            [-80.929602262999936, 35.139942860000076],
            [-80.929771262999964, 35.140074859000038],
            [-80.930476262999946, 35.140588860000037],
            [-80.931834263999974, 35.141499860000067],
            [-80.931568262999974, 35.141695860000027],
            [-80.931397263999941, 35.141826859000048],
            [-80.930721262999953, 35.142357860000061],
            [-80.930622262999975, 35.14243786000003],
            [-80.930593262999935, 35.142460861000075],
            [-80.930102261999934, 35.142848860000072],
            [-80.929307262999941, 35.143476860000078],
            [-80.929227262999973, 35.143551860000059],
            [-80.92896226299996, 35.143754860000058],
            [-80.928466262999962, 35.144145861000027],
            [-80.928184262999935, 35.14436986000004],
            [-80.927977262999946, 35.144547861000035],
            [-80.927877262999971, 35.144637860000046],
            [-80.927783261999934, 35.14472186100005],
            [-80.927617261999956, 35.144880861000047],
            [-80.927518261999978, 35.144987861000061],
            [-80.927111262999972, 35.145450861000029],
            [-80.926973262999979, 35.145629860000042],
            [-80.926929261999987, 35.145689860000061],
            [-80.927054262999945, 35.145742861000031],
            [-80.927100261999954, 35.145787861000031],
            [-80.927185262999956, 35.145908861000066],
            [-80.927342262999957, 35.146079861000032],
            [-80.927359262999971, 35.14611386100006],
            [-80.927369262999946, 35.146152861000076],
            [-80.927444261999938, 35.146262861000025],
            [-80.927574261999951, 35.146475861000056],
            [-80.927646261999939, 35.146560862000058],
            [-80.927690262999988, 35.146595861000037],
            [-80.927751262999948, 35.146678861000055],
            [-80.927756262999935, 35.146781861000079],
            [-80.927740262999976, 35.146871862000069],
            [-80.927737262999983, 35.14689786100007],
            [-80.927782261999937, 35.147092861000033],
            [-80.927798262999943, 35.147131861000048],
            [-80.927811262999967, 35.147163861000024],
            [-80.927829262999978, 35.147234861000072],
            [-80.927814262999959, 35.147331861000055],
            [-80.927816262999954, 35.14737886100005],
            [-80.927842261999956, 35.147446861000049],
            [-80.927860262999957, 35.147510861000058],
            [-80.927857262999964, 35.147661861000074],
            [-80.927860261999967, 35.14768986200005],
            [-80.927877262999971, 35.147782861000053],
            [-80.927886261999959, 35.147803861000057],
            [-80.927912262999939, 35.147866862000058],
            [-80.927958262999937, 35.14793386100007],
            [-80.92799026299997, 35.147990861000039],
            [-80.928038261999973, 35.148157861000072],
            [-80.928064262999953, 35.148185861000059],
            [-80.928094262999934, 35.148205861000065],
            [-80.928138262999937, 35.148220861000027],
            [-80.928204262999941, 35.148263861000032],
            [-80.928229263999981, 35.148285861000033],
            [-80.928277261999938, 35.14834686100005],
            [-80.928330262999964, 35.148393862000034],
            [-80.928363263999984, 35.148409862000051],
            [-80.928383262999944, 35.148426861000075],
            [-80.928400262999958, 35.148450861000072],
            [-80.928409261999946, 35.148497861000067],
            [-80.928429261999952, 35.14852286100006],
            [-80.92850426199999, 35.148556861000031],
            [-80.928594262999979, 35.14857586100004],
            [-80.928619263999963, 35.148596861000044],
            [-80.928706262999981, 35.148642861000042],
            [-80.928940263999948, 35.148684862000039],
            [-80.92899926299998, 35.148718861000077],
            [-80.92905626299995, 35.148771861000057],
            [-80.92913426299998, 35.148877862000063],
            [-80.929186262999963, 35.14894686100007],
            [-80.929280262999953, 35.149060862000056],
            [-80.929337262999979, 35.149157862000038],
            [-80.929389263999951, 35.14926586100006],
            [-80.929437262999954, 35.149332862000051],
            [-80.929519262999975, 35.149382862000039],
            [-80.929594262999956, 35.149413861000028],
            [-80.929664263999939, 35.149437861000024],
            [-80.929710263999937, 35.149466862000054],
            [-80.929743263999967, 35.149517861000049],
            [-80.929750263999949, 35.149577861000068],
            [-80.92978726299998, 35.149642862000064],
            [-80.929850262999935, 35.14968586100008],
            [-80.929952262999961, 35.149782862000052],
            [-80.930005262999941, 35.149800862000063],
            [-80.930070263999937, 35.149849862000053],
            [-80.930133263999949, 35.149908862000075],
            [-80.930178263999949, 35.149938861000066],
            [-80.930221262999964, 35.150003861000073],
            [-80.930234263999978, 35.150070862000064],
            [-80.930225262999954, 35.150144862000047],
            [-80.930201262999958, 35.15019686100004],
            [-80.930160263999937, 35.150341862000062],
            [-80.93015926399994, 35.150389861000065],
            [-80.930193263999968, 35.150521862000062],
            [-80.930184262999944, 35.150558862000025],
            [-80.93011026299996, 35.150717863000068],
            [-80.93006526299996, 35.150785862000077],
            [-80.930062263999957, 35.15091986200008],
            [-80.930051263999985, 35.150963862000026],
            [-80.929970262999973, 35.151158862000045],
            [-80.929972263999957, 35.151244863000045],
            [-80.930066262999958, 35.151560861000064],
            [-80.930091262999952, 35.15161786200008],
            [-80.930136263999941, 35.151688863000061],
            [-80.930203262999953, 35.151836861000049],
            [-80.930211263999979, 35.151869862000069],
            [-80.930212263999977, 35.151906862000033],
            [-80.930235263999975, 35.151926863000028],
            [-80.930297262999943, 35.151929862000031],
            [-80.930343262999941, 35.151941863000047],
            [-80.930365262999942, 35.151948861000051],
            [-80.930480263999982, 35.151970862000042],
            [-80.930548263999981, 35.151999862000025],
            [-80.930636263999986, 35.152075862000061],
            [-80.930683264999971, 35.15213986200007],
            [-80.930750263999983, 35.152195862000042],
            [-80.930820264999966, 35.152220862000036],
            [-80.930914264999956, 35.152275863000057],
            [-80.930965263999951, 35.152321862000065],
            [-80.931001263999974, 35.152384862000076],
            [-80.931064263999986, 35.152473862000079],
            [-80.931111263999981, 35.15254886300005],
            [-80.931155263999983, 35.152604862000032],
            [-80.931217262999951, 35.152637862000063],
            [-80.931283263999944, 35.152664863000041],
            [-80.931346263999956, 35.152681863000055],
            [-80.931393263999951, 35.152717863000078],
            [-80.93142626499997, 35.152755862000049],
            [-80.931504264999944, 35.152811862000078],
            [-80.931565263999971, 35.152847862000044],
            [-80.931694264999976, 35.152929862000065],
            [-80.931750263999959, 35.152996863000055],
            [-80.931828263999989, 35.153006862000041],
            [-80.931883263999964, 35.152996862000066],
            [-80.932022263999954, 35.152992862000076],
            [-80.932207263999942, 35.153030863000026],
            [-80.932234263999987, 35.153042862000063],
            [-80.932284264999964, 35.153084862000071],
            [-80.932338264999942, 35.153170863000071],
            [-80.93251926399995, 35.153453862000049],
            [-80.932558264999955, 35.153571862000035],
            [-80.932606263999958, 35.153744862000053],
            [-80.932702264999989, 35.154020862000039],
            [-80.932720263999954, 35.154195862000051],
            [-80.932777263999981, 35.154289862000041],
            [-80.93279326399994, 35.154359863000025],
            [-80.932778263999978, 35.154433863000065],
            [-80.932794264999984, 35.154504862000067],
            [-80.932833264999942, 35.154561862000037],
            [-80.932836264999935, 35.154604863000031],
            [-80.932808264999949, 35.154718863000028],
            [-80.932821264999973, 35.154768863000072],
            [-80.932850264999956, 35.15480786300003],
            [-80.932897264999951, 35.154889863000051],
            [-80.932897264999951, 35.154919862000042],
            [-80.932881264999935, 35.154954862000068],
            [-80.93284526399998, 35.154970862000027],
            [-80.932821263999983, 35.155005863000042],
            [-80.93282626499996, 35.15504386300006],
            [-80.932897264999951, 35.155187863000037],
            [-80.932874264999953, 35.155242863000069],
            [-80.932888264999974, 35.155319863000045],
            [-80.932882264999989, 35.155409863000045],
            [-80.932834263999951, 35.155503863000035],
            [-80.932840264999982, 35.155578863000073],
            [-80.932839264999984, 35.155633862000059],
            [-80.93283026499995, 35.155672862000074],
            [-80.932846264999966, 35.155779863000078],
            [-80.932836264999935, 35.155914863000078],
            [-80.932856264999941, 35.156065862000048],
            [-80.932844264999972, 35.156137863000026],
            [-80.932816263999939, 35.156217864000041],
            [-80.932831265999937, 35.156288863000043],
            [-80.93282226499997, 35.156327863000058],
            [-80.932780263999973, 35.156399863000047],
            [-80.93279626399999, 35.15648486300006],
            [-80.932775263999986, 35.15658686300003],
            [-80.932757263999974, 35.156708863000063],
            [-80.932759264999959, 35.156740863000039],
            [-80.932765264999944, 35.156875863000039],
            [-80.932784264999952, 35.156996864000064],
            [-80.932794264999984, 35.157145863000039],
            [-80.932787264999945, 35.157249863000061],
            [-80.932716263999964, 35.157477863000054],
            [-80.932708263999984, 35.157518864000053],
            [-80.932705264999981, 35.157711864000078],
            [-80.932696264999947, 35.157785863000072],
            [-80.932688264999967, 35.157817862000059],
            [-80.932693264999955, 35.157864864000032],
            [-80.932685264999975, 35.157904863000056],
            [-80.93265426499994, 35.157938863000027],
            [-80.932622264999964, 35.158036863000063],
            [-80.93260526399996, 35.158149863000062],
            [-80.932613264999986, 35.158221863000051],
            [-80.932630264999943, 35.158309863000056],
            [-80.932598263999978, 35.158491864000041],
            [-80.932585263999954, 35.158619863000069],
            [-80.932585263999954, 35.158752864000064],
            [-80.932585264999943, 35.158775863000074],
            [-80.932575264999969, 35.158827864000045],
            [-80.932549264999977, 35.158887864000064],
            [-80.93251126499996, 35.158929863000026],
            [-80.93243926599996, 35.159053864000043],
            [-80.932380264999949, 35.159119864000047],
            [-80.932337264999944, 35.15915986300007],
            [-80.932309264999958, 35.159209864000047],
            [-80.932281264999972, 35.159280863000049],
            [-80.93222226499995, 35.159386863000066],
            [-80.932217264999963, 35.159466864000024],
            [-80.932198264999954, 35.159539864000067],
            [-80.932152263999967, 35.159593863000055],
            [-80.932034263999981, 35.159704864000048],
            [-80.931906264999952, 35.159979864000036],
            [-80.931832263999979, 35.160167864000073],
            [-80.931822263999948, 35.160247864000041],
            [-80.931820263999953, 35.160343864000026],
            [-80.931744263999974, 35.160472863000052],
            [-80.931675263999978, 35.160630864000041],
            [-80.931521264999958, 35.160847864000061],
            [-80.931500264999954, 35.160925864000035],
            [-80.931500264999954, 35.161028864000059],
            [-80.931445264999979, 35.161069864000069],
            [-80.931361264999964, 35.161169864000044],
            [-80.931305264999935, 35.161287865000077],
            [-80.931240263999939, 35.161464864000038],
            [-80.93121326499994, 35.161524864000057],
            [-80.931194263999942, 35.161570864000055],
            [-80.931137264999961, 35.161729864000051],
            [-80.931113264999965, 35.161765864000074],
            [-80.931087264999974, 35.161811864000072],
            [-80.931063264999977, 35.161856864000072],
            [-80.931108263999988, 35.162135864000049],
            [-80.931100263999951, 35.162221865000049],
            [-80.931071264999957, 35.162283864000074],
            [-80.930966263999949, 35.16236086400005],
            [-80.930932264999967, 35.162363864000042],
            [-80.930907263999984, 35.162358865000044],
            [-80.93083326499999, 35.16235686400006],
            [-80.930786264999938, 35.162384864000046],
            [-80.930752263999977, 35.162397865000059],
            [-80.930660263999982, 35.162404864000052],
            [-80.930564263999941, 35.162422864000064],
            [-80.93051126499995, 35.162439864000078],
            [-80.930469264999942, 35.162469864000059],
            [-80.93046626499995, 35.162502864000032],
            [-80.930485264999959, 35.162563865000038],
            [-80.930494263999947, 35.162636864000035],
            [-80.930498263999937, 35.162764864000053],
            [-80.930505263999976, 35.162782865000054],
            [-80.930579263999959, 35.162924865000036],
            [-80.930573263999975, 35.163009865000049],
            [-80.930546264999975, 35.163100865000047],
            [-80.930535263999957, 35.163128865000033],
            [-80.930502264999973, 35.163206864000074],
            [-80.930494263999947, 35.163329865000037],
            [-80.930448264999939, 35.16340686500007],
            [-80.930418263999968, 35.163439864000054],
            [-80.930451264999988, 35.163543865000065],
            [-80.930467263999958, 35.163575865000041],
            [-80.930490263999957, 35.163641865000045],
            [-80.930497263999939, 35.163746864000075],
            [-80.930502264999973, 35.163767864000079],
            [-80.93051126499995, 35.163807865000024],
            [-80.930538264999939, 35.163855865000073],
            [-80.930603263999956, 35.164000864000059],
            [-80.930709263999972, 35.164376864000076],
            [-80.930775264999966, 35.164445865000062],
            [-80.930862264999973, 35.164664865000077],
            [-80.930965263999951, 35.164826865000066],
            [-80.931136263999974, 35.165003865000074],
            [-80.931276263999962, 35.165127865000045],
            [-80.931418263999944, 35.165240865000044],
            [-80.931529264999938, 35.165292865000026],
            [-80.931560264999973, 35.165317865000077],
            [-80.931609264999963, 35.165397865000045],
            [-80.931636264999952, 35.165476865000073],
            [-80.931725264999955, 35.165603865000037],
            [-80.931819263999955, 35.165680864000024],
            [-80.931941263999988, 35.165742864000038],
            [-80.931984264999983, 35.165759865000041],
            [-80.93213226499995, 35.165843865000056],
            [-80.932266265999942, 35.165908865000063],
            [-80.932338265999988, 35.165929865000066],
            [-80.932410264999987, 35.16592186500003],
            [-80.932453264999936, 35.165906865000068],
            [-80.932517264999944, 35.165898865000031],
            [-80.932586264999941, 35.165897865000034],
            [-80.932696264999947, 35.165929865000066],
            [-80.932761264999954, 35.16595886500005],
            [-80.932900264999944, 35.16605286500004],
            [-80.932966265999937, 35.166115865000052],
            [-80.933039264999934, 35.166217865000078],
            [-80.933064265999974, 35.16628486500008],
            [-80.933070264999969, 35.166335865000065],
            [-80.933104265999987, 35.166403864000074],
            [-80.933110264999982, 35.166479865000042],
            [-80.933183264999968, 35.16657486500003],
            [-80.933208264999962, 35.166627866000056],
            [-80.933326265999938, 35.166827864000027],
            [-80.933372264999946, 35.166969865000056],
            [-80.933379264999985, 35.167043865000039],
            [-80.93343026499997, 35.167134865000037],
            [-80.933433265999952, 35.167206866000072],
            [-80.933455265999953, 35.167281865000064],
            [-80.933501264999961, 35.167334864000054],
            [-80.93356126499998, 35.167425865000041],
            [-80.933580265999979, 35.167445865000047],
            [-80.933597264999946, 35.167572865000068],
            [-80.933631265999963, 35.167649865000044],
            [-80.933812264999972, 35.168193865000035],
            [-80.933902265999961, 35.168569866000041],
            [-80.933945265999967, 35.168696866000062],
            [-80.933958265999934, 35.168800865000037],
            [-80.934061264999968, 35.168947866000053],
            [-80.934071265999989, 35.169026866000024],
            [-80.934102265999968, 35.16911486500004],
            [-80.93416126599999, 35.169173865000062],
            [-80.934309265999957, 35.169402866000041],
            [-80.934368265999979, 35.169442865000065],
            [-80.93448826599996, 35.16948186500008],
            [-80.934562265999944, 35.169496866000031],
            [-80.934634265999989, 35.169602865000059],
            [-80.934632265999937, 35.169691866000051],
            [-80.934606266999936, 35.169719865000047],
            [-80.934536265999952, 35.169753866000065],
            [-80.934505266999963, 35.169790866000028],
            [-80.93443526599998, 35.169902866000029],
            [-80.934359265999944, 35.16994786600003],
            [-80.934251266999979, 35.169975866000073],
            [-80.934231265999983, 35.170002866000061],
            [-80.934222265999949, 35.17007486600005],
            [-80.934221265999952, 35.170106865000037],
            [-80.934194265999963, 35.170144866000044],
            [-80.934149265999963, 35.170184865000067],
            [-80.934122265999974, 35.170259866000038],
            [-80.93412026499999, 35.170292866000068],
            [-80.934140265999986, 35.170332866000024],
            [-80.934185265999986, 35.170375867000075],
            [-80.934197264999966, 35.170398866000028],
            [-80.934205264999946, 35.17043886600004],
            [-80.934204265999938, 35.170473865000076],
            [-80.934211265999977, 35.170503866000047],
            [-80.934246265999946, 35.170571865000056],
            [-80.934282264999979, 35.170620866000036],
            [-80.934358264999958, 35.170670865000034],
            [-80.934431264999944, 35.170700866000061],
            [-80.934511264999969, 35.170748866000054],
            [-80.934618266999962, 35.170828866000079],
            [-80.934770265999987, 35.170922866000069],
            [-80.935068265999973, 35.171196866000059],
            [-80.935108265999986, 35.171245866000049],
            [-80.935241266999981, 35.171359866000046],
            [-80.935308266999982, 35.171394866000071],
            [-80.935396265999941, 35.171455866000031],
            [-80.93549326699997, 35.171542867000028],
            [-80.935609265999972, 35.171715866000056],
            [-80.935627266999973, 35.171774866000078],
            [-80.935658266999951, 35.171880866000038],
            [-80.935708265999949, 35.172101866000048],
            [-80.935725266999953, 35.172150866000038],
            [-80.935794266999949, 35.17229186600008],
            [-80.935849266999981, 35.172467866000034],
            [-80.935868265999943, 35.172498867000058],
            [-80.935387265999964, 35.172418867000033],
            [-80.935372265999945, 35.172635866000064],
            [-80.935243265999986, 35.172788867000065],
            [-80.935198265999986, 35.172841866000056],
            [-80.935030266999945, 35.172876866000024],
            [-80.934936266999955, 35.172893866000038],
            [-80.934724265999989, 35.173003867000034],
            [-80.934544265999989, 35.173203867000041],
            [-80.934516265999946, 35.173282866000079],
            [-80.934648265999954, 35.173328866000077],
            [-80.934696265999946, 35.173344867000026],
            [-80.935335266999971, 35.173567867000031],
            [-80.93544826599998, 35.173607867000044],
            [-80.935826266999982, 35.173738867000054],
            [-80.935824266999987, 35.173817867000025],
            [-80.935792266999954, 35.173972867000032],
            [-80.935727265999958, 35.174363866000078],
            [-80.93571026699999, 35.174488867000036],
            [-80.935617266999941, 35.174799867000047],
            [-80.93554626699995, 35.175141867000036],
            [-80.935502266999947, 35.175469866000071],
            [-80.935548266999945, 35.175661867000031],
            [-80.935552266999935, 35.175912867000079],
            [-80.935788265999975, 35.176121867000063],
            [-80.936080266999966, 35.176456868000059],
            [-80.936380266999947, 35.176710868000043],
            [-80.936487266999961, 35.177093867000053],
            [-80.936584266999944, 35.177407867000056],
            [-80.936576267999953, 35.177471868000055],
            [-80.936567266999987, 35.177618867000035],
            [-80.936578266999959, 35.177741868000055],
            [-80.936614267999971, 35.177852868000059],
            [-80.93668626699997, 35.17798786700007],
            [-80.936712266999962, 35.178076868000062],
            [-80.936772266999981, 35.178358868000032],
            [-80.936831266999945, 35.178529867000066],
            [-80.936846266999964, 35.178671868000038],
            [-80.936875266999948, 35.178729867000072],
            [-80.936914266999963, 35.178784867000047],
            [-80.936980267999957, 35.179046868000057],
            [-80.937112266999975, 35.179381868000064],
            [-80.937133266999979, 35.179420868000079],
            [-80.937289267999972, 35.179708868000034],
            [-80.93747826699996, 35.180034868000064],
            [-80.937676267999962, 35.18028386800006],
            [-80.937689266999939, 35.180316868000034],
            [-80.937729266999952, 35.180365867000035],
            [-80.937895266999988, 35.180613869000069],
            [-80.937946266999973, 35.180695868000043],
            [-80.938146267999969, 35.180975868000075],
            [-80.938325267999971, 35.181287868000027],
            [-80.938400267999953, 35.181481868000049],
            [-80.938473266999949, 35.181729868000048],
            [-80.938500267999984, 35.181779868000035],
            [-80.938586267999938, 35.181907868000053],
            [-80.938585266999951, 35.181967868000072],
            [-80.938603268999941, 35.182048868000038],
            [-80.938597267999967, 35.182191868000075],
            [-80.938625266999964, 35.18229986800003],
            [-80.938636267999982, 35.182371868000075],
            [-80.938706266999986, 35.182493868000051],
            [-80.938733267999964, 35.182624868000062],
            [-80.938736267999957, 35.182663868000077],
            [-80.93878026799996, 35.182816869000078],
            [-80.938816267999982, 35.182959869000058],
            [-80.938857268999982, 35.183068869000067],
            [-80.938885267999979, 35.183141868000064],
            [-80.939066268999966, 35.183290868000029],
            [-80.939153267999984, 35.183355869000025],
            [-80.939223268999967, 35.183481868000058],
            [-80.939257267999949, 35.183569869000053],
            [-80.939266267999983, 35.18358986800007],
            [-80.939274267999963, 35.183600868000042],
            [-80.939305267999941, 35.183642869000039],
            [-80.939423267999985, 35.183613869000055],
            [-80.94061026899999, 35.183033868000052],
            [-80.941002268999966, 35.182851869000046],
            [-80.942387268999937, 35.182180868000046],
            [-80.943420268999944, 35.181668867000042],
            [-80.944092268999952, 35.181332868000027],
            [-80.944740269999954, 35.181016868000029],
            [-80.945522269999969, 35.180659867000031],
            [-80.945768269999974, 35.180558867000059],
            [-80.94589426999994, 35.180509868000058],
            [-80.946297269999945, 35.180370868000068],
            [-80.946767269999953, 35.180226867000044],
            [-80.94709026999999, 35.18014486800007],
            [-80.947570270999961, 35.180040867000059],
            [-80.947793269999977, 35.179997867000054],
            [-80.948394270999984, 35.179903867000064],
            [-80.948703270999943, 35.179869868000026],
            [-80.948898270999962, 35.179852868000069],
            [-80.949078269999973, 35.17984186700005],
            [-80.949879270999986, 35.179824867000036],
            [-80.950099270999942, 35.179823868000028],
            [-80.950344270999949, 35.17982986800007],
            [-80.95069627099997, 35.179852868000069],
            [-80.951155271999937, 35.179902868000056],
            [-80.951522271999977, 35.179949868000051],
            [-80.952146271999936, 35.180038867000064],
            [-80.952932271999941, 35.180151867000063],
            [-80.953709271999969, 35.180238867000071],
            [-80.953993270999945, 35.180274867000037],
            [-80.954908271999955, 35.180391867000026],
            [-80.955670272999953, 35.180494868000039],
            [-80.95614427299995, 35.180552867000074],
            [-80.95668027399995, 35.180609867000044],
            [-80.956616272999952, 35.180943867000053],
            [-80.956419272999938, 35.181978868000044],
            [-80.956399273999978, 35.182114868000042],
            [-80.956390272999954, 35.18224686800005],
            [-80.956395272999941, 35.182441868000069],
            [-80.956411271999968, 35.182540868000046],
            [-80.956443272999934, 35.18265186800005],
            [-80.956492272999981, 35.182771868000032],
            [-80.956562272999975, 35.182897868000055],
            [-80.95662327399998, 35.182987868000055],
            [-80.956715273999976, 35.183095867000077],
            [-80.956760273999976, 35.183139868000069],
            [-80.956906272999959, 35.18326786700004],
            [-80.956996272999959, 35.183334868000031],
            [-80.957197272999963, 35.183469868000032],
            [-80.957478273999982, 35.183645868000042],
            [-80.957593273999976, 35.183723868000072],
            [-80.957797272999983, 35.183870868000042],
            [-80.957946273999937, 35.183996868000065],
            [-80.95807627399995, 35.184119868000039],
            [-80.95825627399995, 35.184314868000058],
            [-80.958388272999969, 35.184480868000037],
            [-80.95854527399996, 35.184698868000055],
            [-80.958866274999934, 35.185178868000037],
            [-80.95895027399996, 35.185318868000024],
            [-80.959039274999952, 35.18548486800006],
            [-80.959094273999938, 35.185601868000049],
            [-80.959110273999954, 35.185636869000064],
            [-80.959156274999941, 35.185763868000038],
            [-80.959181273999945, 35.185857868000028],
            [-80.959213274999968, 35.186036868000031],
            [-80.959224273999951, 35.18618686800005],
            [-80.959213274999968, 35.186351868000031],
            [-80.959177274999945, 35.186510868000028],
            [-80.959137274999989, 35.186634869000045],
            [-80.959084273999963, 35.186779868000031],
            [-80.959067273999949, 35.186824869000077],
            [-80.958685273999947, 35.187774868000076],
            [-80.958088273999977, 35.18918086900004],
            [-80.958049272999972, 35.189290869000047],
            [-80.958002274999956, 35.189442869000061],
            [-80.957972273999985, 35.189582869000049],
            [-80.957970272999944, 35.189620870000056],
            [-80.957962274999943, 35.189789869000037],
            [-80.957976273999975, 35.189952869000024],
            [-80.958011273999944, 35.190139869000063],
            [-80.958201274999965, 35.190897870000072],
            [-80.958581273999982, 35.190854869000077],
            [-80.959013274999961, 35.190837869000063],
            [-80.959412274999977, 35.190862870000046],
            [-80.960459274999948, 35.190970869000068],
            [-80.962255275999951, 35.19116187000003],
            [-80.963643275999971, 35.191294869000046],
            [-80.963726275999989, 35.191302869000026],
            [-80.96380127599997, 35.191344869000034],
            [-80.963817275999986, 35.191419868000025],
            [-80.963817275999986, 35.19158587000004],
            [-80.96380127599997, 35.191926869000042],
            [-80.963867275999974, 35.192225869000026],
            [-80.963925275999941, 35.192408870000065],
            [-80.964042275999986, 35.192641870000045],
            [-80.964150276999987, 35.192790870000067],
            [-80.964283276999936, 35.19297387000006],
            [-80.96449927599997, 35.193148870000073],
            [-80.964648275999934, 35.19324787000005],
            [-80.964948276999962, 35.193381870000053],
            [-80.965249275999952, 35.193467869000074],
            [-80.965332276999959, 35.193527869000036],
            [-80.965405276999945, 35.193571869000039],
            [-80.965467276999959, 35.193598870000073],
            [-80.965546275999941, 35.193631870000047],
            [-80.965736275999973, 35.19372087000005],
            [-80.965840275999938, 35.193782869000074],
            [-80.965952276999985, 35.193856869000058],
            [-80.966066275999935, 35.193940870000063],
            [-80.966178276999983, 35.194032869000068],
            [-80.966381276999982, 35.194244870000034],
            [-80.966470276999985, 35.194364869000026],
            [-80.966628276999984, 35.194626870000036],
            [-80.966690276999941, 35.194767871000067],
            [-80.966779276999944, 35.195069870000054],
            [-80.966813276999972, 35.195225870000058],
            [-80.966880277999962, 35.195539870000061],
            [-80.966912276999949, 35.195694870000068],
            [-80.966944276999982, 35.19584787000008],
            [-80.96697527699996, 35.196000871000024],
            [-80.967007276999936, 35.196153871000035],
            [-80.967040275999977, 35.19630587000006],
            [-80.96707327699994, 35.196457870000074],
            [-80.967106276999971, 35.196611870000027],
            [-80.967140276999942, 35.196767870000031],
            [-80.967174277999959, 35.196927871000071],
            [-80.967191277999973, 35.197009870000045],
            [-80.967208276999941, 35.197090871000057],
            [-80.967242276999968, 35.197254871000041],
            [-80.967277276999937, 35.197417871000027],
            [-80.967312276999962, 35.197578871000076],
            [-80.967347277999977, 35.197737870000026],
            [-80.967415276999986, 35.19805387100007],
            [-80.96744827699996, 35.19820887000003],
            [-80.96748127699999, 35.198362870000039],
            [-80.967515276999961, 35.198517870000046],
            [-80.967548276999935, 35.19867387000005],
            [-80.967614277999985, 35.198987871000043],
            [-80.967645276999974, 35.19914287000006],
            [-80.96767327799995, 35.199296871000058],
            [-80.967693276999967, 35.19944987100007],
            [-80.967704276999939, 35.199602871000025],
            [-80.967708276999986, 35.199756871000034],
            [-80.967709277999973, 35.199909871000045],
            [-80.967708277999975, 35.200061871000059],
            [-80.96770627799998, 35.200213870000027],
            [-80.967703276999941, 35.200363871000036],
            [-80.967701276999946, 35.200513871000055],
            [-80.967698277999943, 35.200811872000031],
            [-80.967697277999946, 35.200959871000066],
            [-80.967696276999959, 35.201109872000075],
            [-80.967695277999951, 35.201259871000047],
            [-80.967694277999954, 35.201409871000067],
            [-80.967695277999951, 35.201555872000029],
            [-80.967693277999956, 35.201699871000073],
            [-80.967689277999966, 35.201841871000056],
            [-80.967683276999935, 35.201979872000038],
            [-80.967676277999942, 35.202116872000033],
            [-80.967672276999963, 35.202246871000057],
            [-80.967670276999968, 35.202373871000077],
            [-80.96766927799996, 35.202497871000048],
            [-80.967670277999957, 35.202614871000037],
            [-80.96767327799995, 35.20272587200003],
            [-80.967678276999948, 35.202828871000065],
            [-80.967682276999938, 35.202922872000045],
            [-80.967685277999976, 35.20301487200004],
            [-80.967685277999976, 35.203095872000063],
            [-80.967686276999984, 35.203170872000044],
            [-80.967688277999969, 35.203234872000053],
            [-80.967696276999959, 35.203287872000033],
            [-80.967728276999935, 35.203361872000073],
            [-80.967760276999968, 35.203436871000065],
            [-80.967945276999956, 35.20343487100007],
            [-80.968256276999966, 35.203428872000075],
            [-80.96853927799998, 35.203415871000061],
            [-80.969007278999982, 35.203412871000069],
            [-80.969541277999951, 35.203398872000037],
            [-80.970135278999976, 35.203403872000024],
            [-80.970511278999936, 35.203393872000049],
            [-80.973200279999958, 35.203534871000045]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 19,
        "SolutionID": "PI-9",
        "Shape_Length": 0.49991025383292759,
        "Shape_Area": 0.0075435018364716907
      }
    },
    {
      "type": "Feature",
      "id": 20,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.798966244999974, 35.51901394500004],
            [-80.798873244999982, 35.519433946000049],
            [-80.798840243999962, 35.519757946000027],
            [-80.798706244999948, 35.520554946000061],
            [-80.799703244999989, 35.520463946000064],
            [-80.801175244999968, 35.520336945000054],
            [-80.80181924599998, 35.520294945000046],
            [-80.805995245999952, 35.520067945000051],
            [-80.807027245999961, 35.519986945000028],
            [-80.80709124699996, 35.519975945000056],
            [-80.807421246999979, 35.519920945000024],
            [-80.807763247999958, 35.519737945000031],
            [-80.808489246999955, 35.519147945000043],
            [-80.808547247999968, 35.519100945000048],
            [-80.80885524699994, 35.518818945000078],
            [-80.809165247999942, 35.518623945000058],
            [-80.809460247999937, 35.518486944000074],
            [-80.809869247999984, 35.518408945000033],
            [-80.810281248999956, 35.51842894400005],
            [-80.810767247999934, 35.518520944000045],
            [-80.811667248999981, 35.518763945000046],
            [-80.814003248999938, 35.519420945000036],
            [-80.814495249999936, 35.519500944000072],
            [-80.814795248999985, 35.519507945000043],
            [-80.815172249999989, 35.519436944000063],
            [-80.815685249999945, 35.519295945000067],
            [-80.816783249999958, 35.518993944000044],
            [-80.817023249999977, 35.51892794500003],
            [-80.81785924999997, 35.518686944000024],
            [-80.818481249999934, 35.518507944000078],
            [-80.818760250999958, 35.518427944000052],
            [-80.819602250999935, 35.518216944000073],
            [-80.821766250999985, 35.517663944000049],
            [-80.822549252999977, 35.517477944000063],
            [-80.824548251999943, 35.517015944000036],
            [-80.825735251999959, 35.516741944000046],
            [-80.825831251999944, 35.516719944000045],
            [-80.826136252999959, 35.51663594300004],
            [-80.82636125199997, 35.516606944000046],
            [-80.826530251999941, 35.516599944000063],
            [-80.826908252999942, 35.516584944000044],
            [-80.827313252999943, 35.516644944000063],
            [-80.827880252999989, 35.516794944000026],
            [-80.828545252999959, 35.516955944000074],
            [-80.829136253999934, 35.517071944000065],
            [-80.82962925399994, 35.517136943000025],
            [-80.829984253999953, 35.517164944000058],
            [-80.831104253999968, 35.517209943000069],
            [-80.833114253999952, 35.517290943000035],
            [-80.833574253999984, 35.517308944000035],
            [-80.83406325499999, 35.517326944000047],
            [-80.834395254999947, 35.51733794300003],
            [-80.836026255999968, 35.517396943000051],
            [-80.836074254999971, 35.517398943000046],
            [-80.836785255999985, 35.517424944000027],
            [-80.837034256999971, 35.517380944000024],
            [-80.83729025599996, 35.517304944000045],
            [-80.837545256999988, 35.517168944000048],
            [-80.837822255999981, 35.516953943000033],
            [-80.838061256999936, 35.516449944000044],
            [-80.838135255999987, 35.516318943000044],
            [-80.838254255999971, 35.516110943000058],
            [-80.838415255999962, 35.515976943000055],
            [-80.838628255999936, 35.515874943000028],
            [-80.838996255999973, 35.515769942000077],
            [-80.839510255999983, 35.515642943000046],
            [-80.838939255999946, 35.51412494300007],
            [-80.838588256999969, 35.513191943000038],
            [-80.838450255999987, 35.512697942000045],
            [-80.838407254999936, 35.512475942000037],
            [-80.838368255999967, 35.512195942000062],
            [-80.838377255999944, 35.511765942000068],
            [-80.838417255999957, 35.511541942000065],
            [-80.83845325599998, 35.511340942000061],
            [-80.838595256999952, 35.510926942000026],
            [-80.838801255999954, 35.51053094100007],
            [-80.839047255999958, 35.510139941000034],
            [-80.839295255999957, 35.509867941000039],
            [-80.839820256999985, 35.509906941000054],
            [-80.840497255999935, 35.509956942000031],
            [-80.841372256999989, 35.51002094100005],
            [-80.845313257999976, 35.510307942000054],
            [-80.845850258999974, 35.510346942000069],
            [-80.852927260999934, 35.510861942000076],
            [-80.855593261999957, 35.511055941000052],
            [-80.855796261999956, 35.511070941000071],
            [-80.859166261999974, 35.511315941000078],
            [-80.860223261999977, 35.511392941000054],
            [-80.861301261999984, 35.511481941000056],
            [-80.861958263999952, 35.51153594200008],
            [-80.862105262999989, 35.51154794100006],
            [-80.865199263999955, 35.511773941000058],
            [-80.865529263999974, 35.511796941000057],
            [-80.865653263999945, 35.511805942000024],
            [-80.865885263999985, 35.511822941000048],
            [-80.866040263999935, 35.511834941000075],
            [-80.874385267999969, 35.512475940000058],
            [-80.874799266999958, 35.512507941000024],
            [-80.890049270999953, 35.513657941000076],
            [-80.890675271999953, 35.513703941000074],
            [-80.906065276999982, 35.514832941000066],
            [-80.909828276999974, 35.515109940000059],
            [-80.91006927799998, 35.514932940000051],
            [-80.910322277999967, 35.514745940000068],
            [-80.91821127999998, 35.508934939000028],
            [-80.930149281999945, 35.500141937000024],
            [-80.936342284999967, 35.49557993600007],
            [-80.940500284999985, 35.492517935000023],
            [-80.947033286999954, 35.487705933000029],
            [-80.946855286999948, 35.487238932000025],
            [-80.946727286999987, 35.486855933000072],
            [-80.946686286999977, 35.486730933000047],
            [-80.946508286999972, 35.486263932000043],
            [-80.946316286999945, 35.485984933000054],
            [-80.946191286999976, 35.485802933000059],
            [-80.945935286999941, 35.485385933000032],
            [-80.94582328599995, 35.48521093200003],
            [-80.94581128599998, 35.485192932000075],
            [-80.94580328699999, 35.485180932000048],
            [-80.945789286999968, 35.485158933000037],
            [-80.945658286999958, 35.484955933000037],
            [-80.945618285999956, 35.484891932000039],
            [-80.945589285999972, 35.484846932000039],
            [-80.945401286999981, 35.484558933000073],
            [-80.945362285999977, 35.484499932000062],
            [-80.945208286999957, 35.484193933000029],
            [-80.945204285999978, 35.484186932000057],
            [-80.945199285999934, 35.484175932000028],
            [-80.945184285999972, 35.484143932000052],
            [-80.945192286999941, 35.484077932000048],
            [-80.945214285999953, 35.483908932000077],
            [-80.945237285999951, 35.48383593300008],
            [-80.945368286999951, 35.483419931000071],
            [-80.945402286999979, 35.483381932000043],
            [-80.945769286999962, 35.482967932000065],
            [-80.946210285999939, 35.482554933000074],
            [-80.946340285999952, 35.482407932000058],
            [-80.946407285999953, 35.482331931000033],
            [-80.946582285999966, 35.482134931000076],
            [-80.94691428699997, 35.481738932000042],
            [-80.947296286999972, 35.481407931000035],
            [-80.947639286999959, 35.480955932000029],
            [-80.947787287999972, 35.480790931000058],
            [-80.947864286999959, 35.480704932000037],
            [-80.947880287999965, 35.48066693100003],
            [-80.947884287999955, 35.480657931000053],
            [-80.948068286999955, 35.480228931000056],
            [-80.948146286999986, 35.48005093200004],
            [-80.948204286999953, 35.479800932000046],
            [-80.948222286999965, 35.479139932000066],
            [-80.94825028799994, 35.478720931000055],
            [-80.948357286999965, 35.478333931000066],
            [-80.948434285999951, 35.478123931000027],
            [-80.948551286999987, 35.477727931000061],
            [-80.948648286999969, 35.477268931000026],
            [-80.948793286999944, 35.476630930000056],
            [-80.949075286999971, 35.475936930000046],
            [-80.949289286999942, 35.475299931000052],
            [-80.949582287999988, 35.474726930000031],
            [-80.949874286999943, 35.474080930000071],
            [-80.950109286999975, 35.473780930000032],
            [-80.950285287999975, 35.473555930000032],
            [-80.950493286999972, 35.473096930000054],
            [-80.95065528799995, 35.472740930000043],
            [-80.950918287999968, 35.472078930000066],
            [-80.951179286999945, 35.471523929000057],
            [-80.951211287999968, 35.471456930000045],
            [-80.95142528699995, 35.470899929000041],
            [-80.951629287999936, 35.470222929000045],
            [-80.951735286999963, 35.46961692900004],
            [-80.951733286999968, 35.469044928000073],
            [-80.951750286999982, 35.46845692900007],
            [-80.951670287999946, 35.468020929000033],
            [-80.951600286999962, 35.467617929000028],
            [-80.951500286999988, 35.467045928000061],
            [-80.95133128699996, 35.466537929000026],
            [-80.951313286999948, 35.466435928000067],
            [-80.951303286999973, 35.466380929000024],
            [-80.951251286999934, 35.466094928000075],
            [-80.951024287999985, 35.465700929000036],
            [-80.950726286999952, 35.465391929000077],
            [-80.950521286999958, 35.46503192800003],
            [-80.950176286999977, 35.464750928000058],
            [-80.949861286999976, 35.464452928000071],
            [-80.949762285999952, 35.464375928000038],
            [-80.949660286999972, 35.46429592800007],
            [-80.949520286999984, 35.464186928000061],
            [-80.949398285999962, 35.464091927000027],
            [-80.94905328699997, 35.463849928000059],
            [-80.948600285999987, 35.463472928000044],
            [-80.948148285999935, 35.463175927000066],
            [-80.948098285999947, 35.463142928000025],
            [-80.947537284999953, 35.462740928000073],
            [-80.947327285999961, 35.462593928000047],
            [-80.947320285999979, 35.46258892700007],
            [-80.946917284999984, 35.462306927000043],
            [-80.946596284999941, 35.462091927000074],
            [-80.946590285999946, 35.462087927000027],
            [-80.946585284999969, 35.462084927000035],
            [-80.946561284999973, 35.462068927000075],
            [-80.946551284999941, 35.462061928000026],
            [-80.946541285999956, 35.462054927000054],
            [-80.946224284999971, 35.461842927000077],
            [-80.946149285999979, 35.461792927000033],
            [-80.94572528499998, 35.461373928000057],
            [-80.945418285999949, 35.461127927000064],
            [-80.945184284999982, 35.460939928000073],
            [-80.944780284999979, 35.460642927000038],
            [-80.944396284999982, 35.460352927000031],
            [-80.944012284999985, 35.460144928000034],
            [-80.943746283999985, 35.459870927000054],
            [-80.943608284999982, 35.459753927000065],
            [-80.943598283999961, 35.459744927000031],
            [-80.943591284999968, 35.459738927000046],
            [-80.943539283999939, 35.459694928000033],
            [-80.943523284999969, 35.459680927000079],
            [-80.943515284999989, 35.459674927000037],
            [-80.943490284999939, 35.459653927000033],
            [-80.943466283999953, 35.45962492700005],
            [-80.94345928499996, 35.45961692700007],
            [-80.943450284999983, 35.459606927000038],
            [-80.943254283999977, 35.459379927000043],
            [-80.943036284999948, 35.45905792800005],
            [-80.943010284999957, 35.458990927000059],
            [-80.942868283999985, 35.458630926000069],
            [-80.942719284999953, 35.458211927000036],
            [-80.942598284999974, 35.457959927000047],
            [-80.942492283999968, 35.457734927000047],
            [-80.942490283999973, 35.457715927000038],
            [-80.942488283999978, 35.457693926000047],
            [-80.942488283999978, 35.457687927000052],
            [-80.942487283999981, 35.457675927000025],
            [-80.942420284999969, 35.456826927000066],
            [-80.942335283999967, 35.456739926000068],
            [-80.942332283999974, 35.456736927000065],
            [-80.942302283999936, 35.45670592700003],
            [-80.942300283999941, 35.456672927000056],
            [-80.942299283999944, 35.456598927000073],
            [-80.942291282999975, 35.456317926000054],
            [-80.942337282999972, 35.45556892500008],
            [-80.942400283999973, 35.455331926000042],
            [-80.94247328299997, 35.455050926000069],
            [-80.94251028299999, 35.454389926000033],
            [-80.942616283999939, 35.45383492600007],
            [-80.94274328399996, 35.453423925000038],
            [-80.943016283999953, 35.453003925000075],
            [-80.943350283999962, 35.45263092600004],
            [-80.94376028399995, 35.452267926000047],
            [-80.944191284999988, 35.451888925000048],
            [-80.944661283999949, 35.45162892500008],
            [-80.944954284999937, 35.451265926000076],
            [-80.945228283999938, 35.450885926000069],
            [-80.945237283999973, 35.450871925000058],
            [-80.945240284999954, 35.450866925000071],
            [-80.945246284999939, 35.450857925000037],
            [-80.945391283999982, 35.450636925000026],
            [-80.945595284999968, 35.450325925000072],
            [-80.94559828499996, 35.450320926000074],
            [-80.945619283999974, 35.450288925000052],
            [-80.94563128499999, 35.450273925000033],
            [-80.94563928499997, 35.450263925000058],
            [-80.945647283999961, 35.450254925000024],
            [-80.945654283999943, 35.450246925000044],
            [-80.94588328399999, 35.449973924000062],
            [-80.945906284999978, 35.449954925000043],
            [-80.946177284999976, 35.44973092500004],
            [-80.946412283999962, 35.449560926000061],
            [-80.946598284999936, 35.449455925000052],
            [-80.947269284999948, 35.449270925000064],
            [-80.947275284999989, 35.449268925000069],
            [-80.947297284999934, 35.449262925000028],
            [-80.947334284999954, 35.449252925000053],
            [-80.947380285999941, 35.449236925000037],
            [-80.947893284999964, 35.449057924000044],
            [-80.948451285999965, 35.44875892500005],
            [-80.949040284999967, 35.448498925000024],
            [-80.949568284999941, 35.448181925000029],
            [-80.949647285999959, 35.448134925000033],
            [-80.950186286999951, 35.447665925000024],
            [-80.950586284999986, 35.447108924000077],
            [-80.950977285999954, 35.44646292300007],
            [-80.951396286999966, 35.445743924000055],
            [-80.95169928699994, 35.44528392400008],
            [-80.951953285999934, 35.444774924000058],
            [-80.952216286999942, 35.444266923000043],
            [-80.952509285999952, 35.443652923000059],
            [-80.952713285999948, 35.443152923000071],
            [-80.95282928599994, 35.442692923000038],
            [-80.952892285999951, 35.442423923000035],
            [-80.952926285999979, 35.442281923000053],
            [-80.952991286999975, 35.441677923000043],
            [-80.952992286999972, 35.441664922000029],
            [-80.95299328699997, 35.441659923000032],
            [-80.953002285999958, 35.441579923000063],
            [-80.953070285999956, 35.44128192200003],
            [-80.95321028699999, 35.441080922000026],
            [-80.953441285999986, 35.44074792300006],
            [-80.953793286999939, 35.440247922000026],
            [-80.954458287999955, 35.439552923000065],
            [-80.955221285999983, 35.438889922000044],
            [-80.956033287999958, 35.438081922000038],
            [-80.956658287999971, 35.437079922000066],
            [-80.956951287999971, 35.436474922000059],
            [-80.957084287999976, 35.436209922000046],
            [-80.957272286999967, 35.435836922000078],
            [-80.957409286999962, 35.435633922000079],
            [-80.957641286999944, 35.435292921000041],
            [-80.95775828699999, 35.435015921000058],
            [-80.957875286999979, 35.434738922000065],
            [-80.957994286999963, 35.434456922000038],
            [-80.958306286999971, 35.433802922000041],
            [-80.958471287999942, 35.433318920000033],
            [-80.958694287999947, 35.432640921000029],
            [-80.958702287999984, 35.432140921000041],
            [-80.958684286999983, 35.432013921000078],
            [-80.958639286999983, 35.431703920000075],
            [-80.958493287999943, 35.431270920000031],
            [-80.958177286999955, 35.430625920000068],
            [-80.957732286999942, 35.429933921000043],
            [-80.95724828699997, 35.429128920000039],
            [-80.957071286999962, 35.428785920000053],
            [-80.956985285999963, 35.428616919000035],
            [-80.956962286999953, 35.428572921000068],
            [-80.956942285999958, 35.428532920000066],
            [-80.95672428599994, 35.427839919000064],
            [-80.95667128599996, 35.427685919000055],
            [-80.956659286999979, 35.427651920000073],
            [-80.956653286999938, 35.42763492000006],
            [-80.956615286999977, 35.427525920000051],
            [-80.956612286999984, 35.427516920000073],
            [-80.956561285999953, 35.427376920000029],
            [-80.956559286999948, 35.427371919000052],
            [-80.956551285999979, 35.427349919000051],
            [-80.956549285999984, 35.427342920000058],
            [-80.956537285999957, 35.427310919000035],
            [-80.956529286999967, 35.427289920000078],
            [-80.956426286999942, 35.427009920000046],
            [-80.956287286999952, 35.426574920000064],
            [-80.956000285999949, 35.425776919000043],
            [-80.955850286999976, 35.425180920000059],
            [-80.955702285999962, 35.424440919000062],
            [-80.955551286999935, 35.423713919000079],
            [-80.955534285999988, 35.423625919000074],
            [-80.95552928699999, 35.423599919000026],
            [-80.955527285999949, 35.423588919000053],
            [-80.955525286999944, 35.423576919000027],
            [-80.955524285999957, 35.423570919000042],
            [-80.955522285999962, 35.423564919000057],
            [-80.955520285999967, 35.423553919000028],
            [-80.955472286999964, 35.423313919000066],
            [-80.955400285999985, 35.422900919000028],
            [-80.955399285999988, 35.422892919000049],
            [-80.955397284999947, 35.422881919000076],
            [-80.955351285999939, 35.422617918000071],
            [-80.955317286999957, 35.42234791900006],
            [-80.955315286999962, 35.422331919000044],
            [-80.955314285999975, 35.42232191800008],
            [-80.955313286999967, 35.422311919000038],
            [-80.95531228599998, 35.422301919000063],
            [-80.955311286999972, 35.422296919000075],
            [-80.95527928599995, 35.422052918000077],
            [-80.955278286999942, 35.422045918000038],
            [-80.955269285999975, 35.421971919000043],
            [-80.955211286999941, 35.421453919000044],
            [-80.955176285999983, 35.420577919000038],
            [-80.955273285999965, 35.420053919000054],
            [-80.955344284999967, 35.419803918000071],
            [-80.955428285999972, 35.419512918000066],
            [-80.955642285999943, 35.418923918000075],
            [-80.955993285999966, 35.418213918000049],
            [-80.956480285999987, 35.417373917000077],
            [-80.957086286999981, 35.416469917000029],
            [-80.957788286999971, 35.415209917000027],
            [-80.958383285999957, 35.41416891700004],
            [-80.958587285999954, 35.413635918000068],
            [-80.958772285999942, 35.413215917000059],
            [-80.958765286999949, 35.412120916000049],
            [-80.958726286999934, 35.411345916000073],
            [-80.95860728599996, 35.410781916000076],
            [-80.958496285999956, 35.410208916000045],
            [-80.958326285999988, 35.40946791600004],
            [-80.95816528499995, 35.408558916000061],
            [-80.957878285999982, 35.407662916000049],
            [-80.957559285999935, 35.406227916000034],
            [-80.95723028499998, 35.404873915000053],
            [-80.956804284999976, 35.403658915000051],
            [-80.95625128599994, 35.402619915000059],
            [-80.95575628499995, 35.401628914000071],
            [-80.955061284999942, 35.401648914000077],
            [-80.954810284999951, 35.401687914000036],
            [-80.954631283999959, 35.401585915000055],
            [-80.954474284999947, 35.401423914000077],
            [-80.954254283999944, 35.401288915000066],
            [-80.954171284999973, 35.401191914000037],
            [-80.954175284999963, 35.40108491400008],
            [-80.953332283999941, 35.401135915000054],
            [-80.953303284999947, 35.401172914000028],
            [-80.953420283999947, 35.40137491400003],
            [-80.953537283999935, 35.40155091400004],
            [-80.953533283999946, 35.401728914000046],
            [-80.953440284999942, 35.401861914000051],
            [-80.953305284999942, 35.401942915000063],
            [-80.953161283999975, 35.401938914000027],
            [-80.953045283999984, 35.401828914000077],
            [-80.95293828299998, 35.401704914000049],
            [-80.952800283999977, 35.401667914000029],
            [-80.952519283999948, 35.401672915000063],
            [-80.952114283999947, 35.401533914000026],
            [-80.951774282999963, 35.40140791500005],
            [-80.951604283999984, 35.401390915000036],
            [-80.951535282999942, 35.401302914000041],
            [-80.951111282999989, 35.401339914000062],
            [-80.950734283999964, 35.40141191500004],
            [-80.95126828399998, 35.402225914000041],
            [-80.951484283999946, 35.402547914000024],
            [-80.951823283999943, 35.403055914000049],
            [-80.952023283999949, 35.403670915000077],
            [-80.952001284999938, 35.404212915000073],
            [-80.953089283999986, 35.404194915000062],
            [-80.953223284999979, 35.403543914000068],
            [-80.953445284999987, 35.403199915000073],
            [-80.95375628499994, 35.403218915000025],
            [-80.953979283999956, 35.404156915000044],
            [-80.95464528499997, 35.405494915000077],
            [-80.955096284999968, 35.405954915000052],
            [-80.955489285999988, 35.40634491600008],
            [-80.95571128499995, 35.407086916000026],
            [-80.955578284999945, 35.407285915000045],
            [-80.955113284999982, 35.407120915000064],
            [-80.954602284999964, 35.406795915000032],
            [-80.954224284999952, 35.406777916000067],
            [-80.95442428399997, 35.407464916000038],
            [-80.954670284999963, 35.40829691600004],
            [-80.954603284999962, 35.409055916000057],
            [-80.954137283999955, 35.409073916000068],
            [-80.954096283999945, 35.409073916000068],
            [-80.954075284999988, 35.408987916000058],
            [-80.954071284999941, 35.408831916000054],
            [-80.954159284999946, 35.408540916000049],
            [-80.954232283999943, 35.408352917000059],
            [-80.954298284999936, 35.408131916000059],
            [-80.954300285999977, 35.408058916000073],
            [-80.954263284999968, 35.407939916000032],
            [-80.953938284999936, 35.407603916000028],
            [-80.953830284999981, 35.407411916000058],
            [-80.953706284999953, 35.407190915000058],
            [-80.953637283999967, 35.407075915000064],
            [-80.953499283999975, 35.406945916000041],
            [-80.953355284999986, 35.406916915000068],
            [-80.953213283999958, 35.40694991600003],
            [-80.953102283999954, 35.40705491600005],
            [-80.953049284999963, 35.407180916000073],
            [-80.953032284999949, 35.407280916000047],
            [-80.953016283999943, 35.407475916000067],
            [-80.952931283999987, 35.407672916000024],
            [-80.952679284999988, 35.40797791600005],
            [-80.952303283999981, 35.408361916000047],
            [-80.95194328499997, 35.408709915000031],
            [-80.951690282999948, 35.408963916000062],
            [-80.951630284999965, 35.409073916000068],
            [-80.951588284999957, 35.409153917000026],
            [-80.951576283999941, 35.409233916000062],
            [-80.951563283999974, 35.409430916000076],
            [-80.951548283999955, 35.40960291600004],
            [-80.951367283999957, 35.40958691600008],
            [-80.951186283999959, 35.409626916000036],
            [-80.950949282999943, 35.409640917000047],
            [-80.950620282999978, 35.409695916000032],
            [-80.950526283999977, 35.409751917000051],
            [-80.950292282999953, 35.409867917000042],
            [-80.950093282999944, 35.409965916000033],
            [-80.94993728299994, 35.410004916000048],
            [-80.949838282999963, 35.410025916000052],
            [-80.94964028399994, 35.410045916000058],
            [-80.949409282999966, 35.410087917000055],
            [-80.949298282999962, 35.410145917000079],
            [-80.949184282999965, 35.41023991600008],
            [-80.949113283999964, 35.410320917000035],
            [-80.949032282999951, 35.410526916000038],
            [-80.94895728299997, 35.410718916000064],
            [-80.948874283999942, 35.41075791600008],
            [-80.948686283999962, 35.410771917000034],
            [-80.948426282999947, 35.410767917000044],
            [-80.948324282999977, 35.410766917000046],
            [-80.94816528399997, 35.410737917000063],
            [-80.948081282999965, 35.41068991700007],
            [-80.94793628299999, 35.410666917000071],
            [-80.947740282999973, 35.410658916000045],
            [-80.947741282999971, 35.410670917000061],
            [-80.947734282999988, 35.410679917000039],
            [-80.947697281999979, 35.410726917000034],
            [-80.947668283999974, 35.410743916000058],
            [-80.947617282999943, 35.410774916000037],
            [-80.947533282999984, 35.410784917000058],
            [-80.947502282999949, 35.410779916000024],
            [-80.947414282999944, 35.410765917000049],
            [-80.947335282999973, 35.41075091700003],
            [-80.947311281999987, 35.410749917000032],
            [-80.946794282999974, 35.41157191700006],
            [-80.946705282999972, 35.411685917000057],
            [-80.946683281999981, 35.411708918000045],
            [-80.946662281999977, 35.411725918000059],
            [-80.946566282999981, 35.411797917000058],
            [-80.946410281999988, 35.41189891700003],
            [-80.945927281999957, 35.412185917000045],
            [-80.945454281999957, 35.412505918000079],
            [-80.945140281999954, 35.412728917000038],
            [-80.944968281999934, 35.412858917000051],
            [-80.94484128199997, 35.412966918000052],
            [-80.944735282999943, 35.413063918000034],
            [-80.944623281999952, 35.413179917000036],
            [-80.944546282999966, 35.413276917000076],
            [-80.944230281999978, 35.413761917000045],
            [-80.943943281999964, 35.414229918000046],
            [-80.943735281999977, 35.41452891800003],
            [-80.943666281999981, 35.414601917000027],
            [-80.943517281999959, 35.414722918000052],
            [-80.943431281999949, 35.414782918000071],
            [-80.942884281999966, 35.41510691700006],
            [-80.942619281999953, 35.415263918000051],
            [-80.94207628099997, 35.415528918000064],
            [-80.941616280999938, 35.415762918000041],
            [-80.94152128099995, 35.415806918000044],
            [-80.941355280999971, 35.415870917000063],
            [-80.941167280999935, 35.415926917000036],
            [-80.940585280999983, 35.416075918000047],
            [-80.940221281999982, 35.416149918000031],
            [-80.940136281999969, 35.416162918000055],
            [-80.939187280999988, 35.416298918000052],
            [-80.939048280999941, 35.416316918000064],
            [-80.937081279999973, 35.416578919000074],
            [-80.936860279999962, 35.416589918000057],
            [-80.93671427999999, 35.416585918000067],
            [-80.935620279999966, 35.41647991800005],
            [-80.935536279999951, 35.416459918000044],
            [-80.935464279999962, 35.416425918000073],
            [-80.935304279999968, 35.416317918000061],
            [-80.935120278999989, 35.416178918000071],
            [-80.934111279999968, 35.415419918000055],
            [-80.934045277999985, 35.415374918000055],
            [-80.933935278999968, 35.41532391800007],
            [-80.93388927899997, 35.415307918000053],
            [-80.933783279999943, 35.415283918000057],
            [-80.933682279999971, 35.415283918000057],
            [-80.933581278999952, 35.415300918000071],
            [-80.933449278999944, 35.415332918000047],
            [-80.933249278999938, 35.415365918000077],
            [-80.93306427999994, 35.415376918000049],
            [-80.932980278999935, 35.41537691700006],
            [-80.932811278999964, 35.415365918000077],
            [-80.932718278999971, 35.415351918000056],
            [-80.932623278999984, 35.415326918000062],
            [-80.932509278999987, 35.415286918000049],
            [-80.932406277999974, 35.415236918000062],
            [-80.932340278999959, 35.415194918000054],
            [-80.932258278999939, 35.415133918000038],
            [-80.932191278999937, 35.415073918000076],
            [-80.932046278999962, 35.41490391800005],
            [-80.931721277999941, 35.414463918000024],
            [-80.931645277999962, 35.414370918000031],
            [-80.931524278999973, 35.414248918000055],
            [-80.931305278999957, 35.414073918000042],
            [-80.930977277999943, 35.413839917000075],
            [-80.930727277999949, 35.413680917000079],
            [-80.930622277999987, 35.41360591800003],
            [-80.930576276999943, 35.413562917000036],
            [-80.930547277999949, 35.413527918000057],
            [-80.930511277999983, 35.413469918000033],
            [-80.930448277999972, 35.413320918000068],
            [-80.930342277999955, 35.41306591700004],
            [-80.930173277999984, 35.412732917000028],
            [-80.930091277999963, 35.412610918000041],
            [-80.930016278999972, 35.412520918000041],
            [-80.92992027799994, 35.412425918000054],
            [-80.929861277999976, 35.412374917000079],
            [-80.929675276999944, 35.41223691700003],
            [-80.929076277999968, 35.411832918000073],
            [-80.928851277999968, 35.411685918000046],
            [-80.928431276999959, 35.411426918000075],
            [-80.928348276999941, 35.411384918000067],
            [-80.927693276999946, 35.411085917000037],
            [-80.926041276999968, 35.410382917000049],
            [-80.925822276999952, 35.410306917000071],
            [-80.926424275999977, 35.408859917000029],
            [-80.926496275999966, 35.408634917000029],
            [-80.926520275999962, 35.408518916000048],
            [-80.926535275999981, 35.40841591700007],
            [-80.926541276999956, 35.408320917000026],
            [-80.926540276999958, 35.408219916000064],
            [-80.926523275999955, 35.408022917000039],
            [-80.926491276999968, 35.407850916000029],
            [-80.926451276999956, 35.407700916000067],
            [-80.926364276999948, 35.407439917000033],
            [-80.92463027499997, 35.402347917000043],
            [-80.92421727499999, 35.401157916000045],
            [-80.923466274999953, 35.398954915000047],
            [-80.923314275999985, 35.398530915000038],
            [-80.923168274999966, 35.398098914000059],
            [-80.923146274999965, 35.398009915000046],
            [-80.923105274999955, 35.397767915000031],
            [-80.923093274999985, 35.397559914000055],
            [-80.923101274999965, 35.397335915000042],
            [-80.923137274999988, 35.397060915000054],
            [-80.923173274999954, 35.396922915000061],
            [-80.92323027499998, 35.396767915000055],
            [-80.923505274999968, 35.396129914000028],
            [-80.923627275999934, 35.39585891400003],
            [-80.923777274999964, 35.395498914000029],
            [-80.923840274999975, 35.395319914000027],
            [-80.923873274999949, 35.39519791400005],
            [-80.923885274999975, 35.395113914000035],
            [-80.923889274999965, 35.39493591400003],
            [-80.92387927599998, 35.39478891400006],
            [-80.92384627499996, 35.394645913000033],
            [-80.923791274999985, 35.394474914000057],
            [-80.923729274999971, 35.394328915000074],
            [-80.923571273999983, 35.394009914000037],
            [-80.922897274999968, 35.392777914000078],
            [-80.922807274999968, 35.39261291400004],
            [-80.922661274999939, 35.39237191400008],
            [-80.922584273999973, 35.392267913000069],
            [-80.922412274999942, 35.392079914000078],
            [-80.921881273999986, 35.391559914000027],
            [-80.921401274999937, 35.391080913000053],
            [-80.921291273999941, 35.390955914000074],
            [-80.921181273999935, 35.39080791300006],
            [-80.921090274999983, 35.390655913000046],
            [-80.921007273999976, 35.390485914000067],
            [-80.920961273999978, 35.390360913000052],
            [-80.920926273999953, 35.39020791300004],
            [-80.920904272999962, 35.39002391300005],
            [-80.920915273999981, 35.389792913000065],
            [-80.920932274999984, 35.389668913000037],
            [-80.920944273999964, 35.389589914000055],
            [-80.92111427399999, 35.389629913000078],
            [-80.925391274999981, 35.390065912000068],
            [-80.925484274999974, 35.390069913000048],
            [-80.925806274999957, 35.390052913000034],
            [-80.925879274999943, 35.390044912000064],
            [-80.926000275999968, 35.39003191300003],
            [-80.926070274999972, 35.390013913000075],
            [-80.926130274999934, 35.390002913000046],
            [-80.926183274999971, 35.389987913000027],
            [-80.926249274999975, 35.389959913000041],
            [-80.926406275999966, 35.389881913000067],
            [-80.926568275999955, 35.389822913000046],
            [-80.926658275999955, 35.389783914000077],
            [-80.926838275999955, 35.389678913000068],
            [-80.926961275999986, 35.389591913000061],
            [-80.927069275999941, 35.389528913000049],
            [-80.927517275999946, 35.389208913000061],
            [-80.927688275999969, 35.389107912000043],
            [-80.927879275999942, 35.388962912000068],
            [-80.928083275999938, 35.388833912000052],
            [-80.928172275999941, 35.388788913000042],
            [-80.928295275999972, 35.388740913000049],
            [-80.928624275999937, 35.388560912000059],
            [-80.928884275999962, 35.388436913000078],
            [-80.929199275999963, 35.388188913000079],
            [-80.929568275999941, 35.387924913000063],
            [-80.929799276999972, 35.387828912000032],
            [-80.930117276999965, 35.387651912000024],
            [-80.930139276999967, 35.38762691200003],
            [-80.930392276999953, 35.387456913000051],
            [-80.930523276999963, 35.387432912000065],
            [-80.930647276999935, 35.387427913000067],
            [-80.93076027699999, 35.387441912000043],
            [-80.93092227599999, 35.387488912000038],
            [-80.931076276999988, 35.387567912000065],
            [-80.931127276999973, 35.387600912000039],
            [-80.931246276999957, 35.387679912000067],
            [-80.931393275999937, 35.387717912000028],
            [-80.931476276999945, 35.387746912000068],
            [-80.931571276999989, 35.387763912000025],
            [-80.931807276999962, 35.387756913000032],
            [-80.931999276999989, 35.387679912000067],
            [-80.932065277999982, 35.387636912000062],
            [-80.932132276999937, 35.387591912000062],
            [-80.932228276999979, 35.387540913000066],
            [-80.932346275999976, 35.387462912000046],
            [-80.932446275999951, 35.387390912000058],
            [-80.932649276999939, 35.387242912000033],
            [-80.932722276999982, 35.387179912000079],
            [-80.93277227699997, 35.387080912000044],
            [-80.932801276999953, 35.386997912000027],
            [-80.93281327699998, 35.38688391200003],
            [-80.932778276999954, 35.386763912000049],
            [-80.932759276999946, 35.386720913000033],
            [-80.932723277999969, 35.38668791300006],
            [-80.932663276999961, 35.386625913000046],
            [-80.932319277999966, 35.386352913000053],
            [-80.932271276999984, 35.386305912000068],
            [-80.932257276999962, 35.386268912000048],
            [-80.93222127699994, 35.386171912000066],
            [-80.932180276999986, 35.385913912000035],
            [-80.932189275999974, 35.385812912000063],
            [-80.932114276999982, 35.385621912000033],
            [-80.932067276999987, 35.385376912000027],
            [-80.93202927699997, 35.385030911000058],
            [-80.93202527699998, 35.384964912000044],
            [-80.932053276999966, 35.384911912000064],
            [-80.932244276999938, 35.384724911000035],
            [-80.932369275999974, 35.384613912000077],
            [-80.932443276999948, 35.384535911000057],
            [-80.932686276999959, 35.384137912000028],
            [-80.932842276999963, 35.383935912000027],
            [-80.933126276999985, 35.38353991200006],
            [-80.933245276999969, 35.383350912000026],
            [-80.933340276999957, 35.383212912000033],
            [-80.933614276999947, 35.382824912000046],
            [-80.93368727699999, 35.382719911000038],
            [-80.933829276999973, 35.382529912000052],
            [-80.933991276999961, 35.382435911000073],
            [-80.934130276999952, 35.382361911000032],
            [-80.934264277999944, 35.382316911000032],
            [-80.934416276999968, 35.382289911000044],
            [-80.934607277999987, 35.382204911000031],
            [-80.934726276999982, 35.382128911000052],
            [-80.934781277999946, 35.382096911000076],
            [-80.93483527799998, 35.382081911000057],
            [-80.934991277999984, 35.382045912000024],
            [-80.935100276999947, 35.382086911000044],
            [-80.935214276999943, 35.382185911000079],
            [-80.935404277999965, 35.382288911000046],
            [-80.935737278999966, 35.382460911000067],
            [-80.935915277999982, 35.382568911000078],
            [-80.936102277999964, 35.382666912000047],
            [-80.936164277999978, 35.382684911000069],
            [-80.936223277999943, 35.382678911000028],
            [-80.936339277999934, 35.382657911000024],
            [-80.936467277999952, 35.382588911000028],
            [-80.936576277999961, 35.382504911000069],
            [-80.936660277999977, 35.382340911000028],
            [-80.936801278999951, 35.382102911000061],
            [-80.936905278999973, 35.382033911000065],
            [-80.937056278999989, 35.381968912000048],
            [-80.937196277999988, 35.381933910000043],
            [-80.93761227799996, 35.381811911000057],
            [-80.938031277999983, 35.38166091100004],
            [-80.938509278999959, 35.381518911000057],
            [-80.938735278999957, 35.381442910000032],
            [-80.938899277999951, 35.381411911000043],
            [-80.939040278999983, 35.381428910000068],
            [-80.939463278999938, 35.381445910000025],
            [-80.93960427899998, 35.381443911000076],
            [-80.939961278999988, 35.381432911000047],
            [-80.940065278999953, 35.38137891100007],
            [-80.940124278999974, 35.381281911000031],
            [-80.94014827899997, 35.381045911000058],
            [-80.940109278999955, 35.380574910000064],
            [-80.940104279999957, 35.38051291000005],
            [-80.94000127999999, 35.380543911000075],
            [-80.939974278999955, 35.380431910000027],
            [-80.939928278999957, 35.380176910000046],
            [-80.939916278999988, 35.380086910000045],
            [-80.939918278999983, 35.379947910000055],
            [-80.93992327999996, 35.379890910000029],
            [-80.940014278999968, 35.379838911000036],
            [-80.940108278999958, 35.379786910000064],
            [-80.940192278999973, 35.379733910000027],
            [-80.940281278999976, 35.379683911000029],
            [-80.940358279999941, 35.379648910000071],
            [-80.940447278999955, 35.379619910000031],
            [-80.940655278999941, 35.379506910000032],
            [-80.940746278999939, 35.379453910000052],
            [-80.940861278999989, 35.379404910000062],
            [-80.940912278999974, 35.379387910000048],
            [-80.940996278999989, 35.379343910000046],
            [-80.941059278999944, 35.379280910000034],
            [-80.941119279999953, 35.379197911000063],
            [-80.941213278999953, 35.379037910000079],
            [-80.941222278999987, 35.378958910000051],
            [-80.941221279999979, 35.378894910000042],
            [-80.941178279999974, 35.378843910000057],
            [-80.941117279999958, 35.378790909000031],
            [-80.941035278999948, 35.378731909000066],
            [-80.940967278999949, 35.37867490900004],
            [-80.940889278999975, 35.378624910000042],
            [-80.940824279999958, 35.378569910000067],
            [-80.940812278999942, 35.378522910000072],
            [-80.940812278999942, 35.378474910000079],
            [-80.940806279999947, 35.378421909000053],
            [-80.940772278999987, 35.378358910000031],
            [-80.940763279999942, 35.37828791000004],
            [-80.940760279999949, 35.378208910000069],
            [-80.940744278999944, 35.378084910000041],
            [-80.940702279999982, 35.378044910000028],
            [-80.940768279999986, 35.378016910000042],
            [-80.940793278999934, 35.377998910000031],
            [-80.940828277999969, 35.377967910000052],
            [-80.94084627899997, 35.377920910000057],
            [-80.940896278999958, 35.377873910000062],
            [-80.94094027999995, 35.377853911000045],
            [-80.94106727999997, 35.377799910000078],
            [-80.941116278999971, 35.37776991000004],
            [-80.941202278999981, 35.377754910000078],
            [-80.941306278999946, 35.377753910000024],
            [-80.941445279999982, 35.377749910000034],
            [-80.941701279999961, 35.377735910000069],
            [-80.941821279999942, 35.37773990900007],
            [-80.941897279999978, 35.377727910000033],
            [-80.941943279999975, 35.377667910000071],
            [-80.942024279999941, 35.377628910000055],
            [-80.94209227999994, 35.377636911000025],
            [-80.942232278999938, 35.37770290900005],
            [-80.942303279999976, 35.377742910000052],
            [-80.942353278999974, 35.377747910000039],
            [-80.942457278999939, 35.377752910000027],
            [-80.942481279999981, 35.377760910000063],
            [-80.942559278999965, 35.377794910000034],
            [-80.942635279999934, 35.377807910000058],
            [-80.94270727899999, 35.377808910000056],
            [-80.942864279999981, 35.377799910000078],
            [-80.942974279999987, 35.377804910000066],
            [-80.943049279999968, 35.377784910000059],
            [-80.943159279999975, 35.37781891000003],
            [-80.943227278999984, 35.377849910000066],
            [-80.943301279999957, 35.377828910000062],
            [-80.94339027999996, 35.377810910000051],
            [-80.943487279999943, 35.377820910000025],
            [-80.943672280999976, 35.377854909000064],
            [-80.943774279999957, 35.377859910000041],
            [-80.943807279999987, 35.377856909000059],
            [-80.943882280999958, 35.377846910000073],
            [-80.943914279999944, 35.377831910000054],
            [-80.94395327999996, 35.377808910000056],
            [-80.943972279999969, 35.377798909000035],
            [-80.94405127999994, 35.377746910000042],
            [-80.94413327999996, 35.377714910000066],
            [-80.94418227999995, 35.377676910000048],
            [-80.944229279999945, 35.377544909000051],
            [-80.944195279999974, 35.377481910000029],
            [-80.944131279999965, 35.377389910000034],
            [-80.944145279999987, 35.377301910000028],
            [-80.944203279999954, 35.377205909000054],
            [-80.944271279999953, 35.377145910000024],
            [-80.944308279999973, 35.377096910000034],
            [-80.944332279999969, 35.37703091000003],
            [-80.94435428099996, 35.376990910000075],
            [-80.944390280999983, 35.376949910000064],
            [-80.944465279999974, 35.376917910000031],
            [-80.94454927999999, 35.376875910000024],
            [-80.944607279999957, 35.376822909000055],
            [-80.944671278999976, 35.376795910000055],
            [-80.944769278999956, 35.376772909000067],
            [-80.944859280999935, 35.376729909000062],
            [-80.944955279999988, 35.376671909000038],
            [-80.945024280999974, 35.376624910000032],
            [-80.945077280999953, 35.376565910000068],
            [-80.945123279999962, 35.37649490900003],
            [-80.945198279999943, 35.376414909000061],
            [-80.945261280999944, 35.376356909000037],
            [-80.945312280999985, 35.37629490900008],
            [-80.945323279999968, 35.376276909000069],
            [-80.945351280999944, 35.376207910000062],
            [-80.945382280999979, 35.376132910000024],
            [-80.945387280999967, 35.376047909000079],
            [-80.945379279999941, 35.375981910000064],
            [-80.945395280999946, 35.375909909000029],
            [-80.945447280999986, 35.375786910000045],
            [-80.945462280999948, 35.375733909000076],
            [-80.945459280999955, 35.375681909000036],
            [-80.945468280999989, 35.375610910000034],
            [-80.945507280999948, 35.375504910000075],
            [-80.94543927999996, 35.375431909000042],
            [-80.94538627999998, 35.375397909000071],
            [-80.945339279999985, 35.375359909000053],
            [-80.945300280999959, 35.375262909000071],
            [-80.945316279999986, 35.375199910000049],
            [-80.945471279999936, 35.375091909000048],
            [-80.945491279999942, 35.37503791000006],
            [-80.945510279999951, 35.374838909000061],
            [-80.945508279999956, 35.374770910000052],
            [-80.94539427999996, 35.374721909000073],
            [-80.94534127999998, 35.374740909000025],
            [-80.945276279999973, 35.374751910000043],
            [-80.945219279999947, 35.374756909000041],
            [-80.945067279999989, 35.374750909000056],
            [-80.944997279999939, 35.374708910000038],
            [-80.944980280999971, 35.374660909000056],
            [-80.944973279999942, 35.374576909000041],
            [-80.944978280999976, 35.374413909000054],
            [-80.944975279999937, 35.374299909000058],
            [-80.944987279999964, 35.374210909000055],
            [-80.945079279999959, 35.373952910000071],
            [-80.945104279999953, 35.373847909000062],
            [-80.945117280999966, 35.373771909000027],
            [-80.945109280999986, 35.373686909000071],
            [-80.945090279999988, 35.373614909000025],
            [-80.944964279999965, 35.373459909000076],
            [-80.944849279999971, 35.373384909000038],
            [-80.944792279999945, 35.373363909000034],
            [-80.944680280999989, 35.373352908000072],
            [-80.944604279999965, 35.373359909000044],
            [-80.944526279999934, 35.373358909000046],
            [-80.944430279999949, 35.373343909000027],
            [-80.944294279999951, 35.373304909000069],
            [-80.944116280999935, 35.373201908000055],
            [-80.944013279999979, 35.373189909000075],
            [-80.94385127999999, 35.373225909000041],
            [-80.943738279999934, 35.373241909000058],
            [-80.94372027999998, 35.373174908000067],
            [-80.943767279999975, 35.373071909000032],
            [-80.943780279999942, 35.373016909000057],
            [-80.943797279999956, 35.372863908000056],
            [-80.943799279999951, 35.372796909000044],
            [-80.943791279999971, 35.372740909000072],
            [-80.943758278999951, 35.372676909000063],
            [-80.943714279999938, 35.37261490800006],
            [-80.943682279999962, 35.372549909000043],
            [-80.943538279999984, 35.372390909000046],
            [-80.943459279999956, 35.372315908000076],
            [-80.943286279999938, 35.372217909000028],
            [-80.943168278999963, 35.372219908000034],
            [-80.943057279999948, 35.372151909000024],
            [-80.943019279999987, 35.372112908000076],
            [-80.943008278999969, 35.372053908000055],
            [-80.943049279999968, 35.372011908000047],
            [-80.943159278999985, 35.37194290900004],
            [-80.94325127999997, 35.371857909000028],
            [-80.943279279999956, 35.371813908000036],
            [-80.943293279999978, 35.371756908000066],
            [-80.943298278999976, 35.371673908000048],
            [-80.94325527899997, 35.371611908000034],
            [-80.94316927999995, 35.371584908000045],
            [-80.943058278999956, 35.371584908000045],
            [-80.942953279999983, 35.371608909000031],
            [-80.942867278999984, 35.371655908000037],
            [-80.942821278999986, 35.371725909000077],
            [-80.942761279999957, 35.371789908000039],
            [-80.942654279999942, 35.371855908000043],
            [-80.942523279999989, 35.371964908000052],
            [-80.94247927899994, 35.37200690800006],
            [-80.942440279999971, 35.372076909000043],
            [-80.942440278999982, 35.37213390900007],
            [-80.942420278999975, 35.372203909000064],
            [-80.94237327999997, 35.372238908000043],
            [-80.942272278999951, 35.372266909000075],
            [-80.942181279999943, 35.372247909000066],
            [-80.942061278999972, 35.372127909000028],
            [-80.941877279999972, 35.371971908000035],
            [-80.941659279999953, 35.371805909000045],
            [-80.941564278999977, 35.371749909000073],
            [-80.941437278999956, 35.371703909000075],
            [-80.94127827799997, 35.371636909000074],
            [-80.941177278999987, 35.371630909000032],
            [-80.941081278999945, 35.371647909000046],
            [-80.940978278999978, 35.371629909000035],
            [-80.940897277999966, 35.371579908000058],
            [-80.940735277999977, 35.371531909000055],
            [-80.940656277999949, 35.371538908000048],
            [-80.940511279999953, 35.371617909000065],
            [-80.940451277999955, 35.371629909000035],
            [-80.940369278999981, 35.371636908000028],
            [-80.940260278999972, 35.37163590800003],
            [-80.940199278999955, 35.371648909000044],
            [-80.940121278999982, 35.371667908000063],
            [-80.939962278999985, 35.371702908000032],
            [-80.939903278999964, 35.371696909000036],
            [-80.939831278999975, 35.371701908000034],
            [-80.939763277999987, 35.371679908000033],
            [-80.939651278999975, 35.371589909000079],
            [-80.939616278999949, 35.371542909000027],
            [-80.939543277999974, 35.371501908000027],
            [-80.939460277999956, 35.37148890900005],
            [-80.939376277999941, 35.371497908000038],
            [-80.939276278999955, 35.37152990900006],
            [-80.939167277999957, 35.371547909000071],
            [-80.939065278999976, 35.371540908000043],
            [-80.938912278999965, 35.37149690800004],
            [-80.938803277999966, 35.37149690800004],
            [-80.938707277999981, 35.37152590900007],
            [-80.938655277999942, 35.37154590800003],
            [-80.938575278999963, 35.371525908000024],
            [-80.938473277999947, 35.371452909000027],
            [-80.93835927799995, 35.371273908000035],
            [-80.938278277999984, 35.371230909000076],
            [-80.938193277999972, 35.371168908000072],
            [-80.938150277999966, 35.371129908000057],
            [-80.938105277999966, 35.371099909000066],
            [-80.938019277999956, 35.371066909000035],
            [-80.937891276999949, 35.371028908000028],
            [-80.937711276999948, 35.370956909000029],
            [-80.937690276999945, 35.370898908000072],
            [-80.937791276999974, 35.370828909000068],
            [-80.93789327799999, 35.370795908000048],
            [-80.938211278999972, 35.370713909000074],
            [-80.93835927799995, 35.370711909000079],
            [-80.939408277999974, 35.370045908000066],
            [-80.939447277999989, 35.369959908000055],
            [-80.939463277999948, 35.36990890900006],
            [-80.939451278999968, 35.369833909000079],
            [-80.939415277999956, 35.369770909000067],
            [-80.939375277999943, 35.369709908000061],
            [-80.939089278999973, 35.369506908000062],
            [-80.938970277999942, 35.369385908000027],
            [-80.938924277999945, 35.369313907000048],
            [-80.938851277999959, 35.369117908000078],
            [-80.938844277999976, 35.369062908000046],
            [-80.938778277999972, 35.368987908000065],
            [-80.939062277999938, 35.368288908000068],
            [-80.939097277999963, 35.368247908000058],
            [-80.939160277999974, 35.36819390800008],
            [-80.939266277999934, 35.368137908000051],
            [-80.939393277999955, 35.368089908000059],
            [-80.939472277999982, 35.368035908000024],
            [-80.939497277999976, 35.367974908000065],
            [-80.939491277999934, 35.367910907000066],
            [-80.939411277999966, 35.36783790700008],
            [-80.939286278999987, 35.367772907000074],
            [-80.938902276999954, 35.367616908000059],
            [-80.938721277999946, 35.367535908000036],
            [-80.938401277999958, 35.367365907000078],
            [-80.938334277999957, 35.36731590800008],
            [-80.938249277999944, 35.367170908000048],
            [-80.938083277999965, 35.367020908000029],
            [-80.937960277999935, 35.366937907000079],
            [-80.937657276999971, 35.366777907000028],
            [-80.937550277999947, 35.366740907000064],
            [-80.937482276999958, 35.366731908000077],
            [-80.937428276999981, 35.366714908000063],
            [-80.937223276999987, 35.366612907000047],
            [-80.936862276999989, 35.366450908000047],
            [-80.936703277999982, 35.36640890700005],
            [-80.936567276999938, 35.366360908000047],
            [-80.936523276999935, 35.366327908000073],
            [-80.936449277999941, 35.366297907000046],
            [-80.93637027699998, 35.366290907000064],
            [-80.936187276999988, 35.36622090700007],
            [-80.936107277999952, 35.366179908000049],
            [-80.936005277999982, 35.366127908000067],
            [-80.935854277999965, 35.366085908000059],
            [-80.935776277999935, 35.366058907000024],
            [-80.935448276999978, 35.36593190800005],
            [-80.935318276999965, 35.365886907000061],
            [-80.935251276999963, 35.365858908000064],
            [-80.935063275999937, 35.365765907000025],
            [-80.935004275999972, 35.365729907000059],
            [-80.934888275999981, 35.365683908000051],
            [-80.934726276999982, 35.365591908000056],
            [-80.934676275999948, 35.365550907000056],
            [-80.934568275999936, 35.365480907000062],
            [-80.934379275999959, 35.365401907000034],
            [-80.934314276999942, 35.365378907000036],
            [-80.934180276999939, 35.365315907000024],
            [-80.934106275999966, 35.36528690800003],
            [-80.934057276999965, 35.365260907000049],
            [-80.933999276999941, 35.36524590700003],
            [-80.933901276999961, 35.365231907000066],
            [-80.933843276999937, 35.365212907000057],
            [-80.933689276999985, 35.365133908000075],
            [-80.933456275999959, 35.364997907000031],
            [-80.933346275999952, 35.364923908000037],
            [-80.933155275999979, 35.364827907000063],
            [-80.933036275999939, 35.364789907000045],
            [-80.932864275999975, 35.364755907000074],
            [-80.932736274999968, 35.36474290700005],
            [-80.93254727499999, 35.364733907000073],
            [-80.932445275999953, 35.36473490700007],
            [-80.932345274999989, 35.364742908000039],
            [-80.932143275999977, 35.364768907000041],
            [-80.932050276999973, 35.364795908000076],
            [-80.932097275999979, 35.364854907000051],
            [-80.932231275999982, 35.364961908000055],
            [-80.93228927499996, 35.365002907000076],
            [-80.932314275999943, 35.365067907000025],
            [-80.932348275999971, 35.365137908000065],
            [-80.932384274999947, 35.365177908000078],
            [-80.93246127599997, 35.365210907000062],
            [-80.932537275999948, 35.36524890700008],
            [-80.93264127599997, 35.365331907000041],
            [-80.932699275999937, 35.36539990700004],
            [-80.93278827599994, 35.36552290700007],
            [-80.932814275999988, 35.365685907000056],
            [-80.932801275999964, 35.365733907000049],
            [-80.93277627599997, 35.365799908000042],
            [-80.932762276999938, 35.365852908000079],
            [-80.932752276999963, 35.365924908000068],
            [-80.93269427599995, 35.366073908000033],
            [-80.932630275999941, 35.366150908000066],
            [-80.932584275999943, 35.366229908000037],
            [-80.932580274999964, 35.366283908000071],
            [-80.932583275999946, 35.366379908000056],
            [-80.932584275999943, 35.366443908000065],
            [-80.93260027599996, 35.366499908000037],
            [-80.932625275999953, 35.36654390700005],
            [-80.932653276999986, 35.366623908000065],
            [-80.932673276999935, 35.366720907000058],
            [-80.932667275999961, 35.366794908000031],
            [-80.932627276999938, 35.367005908000067],
            [-80.93258827599999, 35.367104908000044],
            [-80.93253627699994, 35.367154908000032],
            [-80.93250627599997, 35.367223908000028],
            [-80.932440275999966, 35.367416908000052],
            [-80.932302275999973, 35.367851908000034],
            [-80.932299275999981, 35.367974908000065],
            [-80.932327275999967, 35.368057908000026],
            [-80.932387275999986, 35.368182908000051],
            [-80.932496276999984, 35.368497908000052],
            [-80.932561275999944, 35.36864790900006],
            [-80.93265327599994, 35.368916908000074],
            [-80.932707276999963, 35.369054909000056],
            [-80.932738275999952, 35.369158908000031],
            [-80.932747275999986, 35.369310909000035],
            [-80.932778275999965, 35.369376908000049],
            [-80.932849275999956, 35.369496908000031],
            [-80.932885276999968, 35.369577908000053],
            [-80.932921275999945, 35.369625908000046],
            [-80.93305027599996, 35.369752908000066],
            [-80.933142275999955, 35.369765908000034],
            [-80.933238276999987, 35.36974490800003],
            [-80.933354276999978, 35.369739909000032],
            [-80.933423275999985, 35.369771909000065],
            [-80.933501276999948, 35.369910908000065],
            [-80.933502275999956, 35.369981908000057],
            [-80.933523276999949, 35.37005190900004],
            [-80.93351927599997, 35.370108909000066],
            [-80.933479275999957, 35.370160909000049],
            [-80.933429276999959, 35.370209908000049],
            [-80.933408275999966, 35.370271908000063],
            [-80.933377276999977, 35.370319909000045],
            [-80.933364276999953, 35.370364909000045],
            [-80.93336527699995, 35.370421909000072],
            [-80.933318276999955, 35.370488908000027],
            [-80.933233275999953, 35.370503908000046],
            [-80.933120276999944, 35.37056990800005],
            [-80.933063276999974, 35.370556908000026],
            [-80.932935275999967, 35.370592909000038],
            [-80.932855275999941, 35.370626909000066],
            [-80.932744275999937, 35.370718909000061],
            [-80.932677275999936, 35.370813909000049],
            [-80.932601275999957, 35.370900909000056],
            [-80.932541275999938, 35.370976908000046],
            [-80.932440276999955, 35.371055909000063],
            [-80.932469275999949, 35.370924909000053],
            [-80.932550276999962, 35.370687908000036],
            [-80.932576276999953, 35.370510908000028],
            [-80.932607275999942, 35.370438909000029],
            [-80.932558275999952, 35.370398909000073],
            [-80.932439276999958, 35.370365909000043],
            [-80.932277275999979, 35.370279908000043],
            [-80.932223276999935, 35.370240908000028],
            [-80.932148276999953, 35.370220909000068],
            [-80.932065276999936, 35.370203909000054],
            [-80.93200027599994, 35.370149909000077],
            [-80.931847275999985, 35.370103909000079],
            [-80.931793275999951, 35.370036908000031],
            [-80.931708275999938, 35.369951909000065],
            [-80.931663275999938, 35.369917909000037],
            [-80.93157227599994, 35.369882909000069],
            [-80.931423275999975, 35.369794909000063],
            [-80.931389274999958, 35.369748908000076],
            [-80.931370274999949, 35.369669908000049],
            [-80.931354275999979, 35.369566908000024],
            [-80.93125727599994, 35.369499909000069],
            [-80.931232276999935, 35.369420908000052],
            [-80.931240274999936, 35.369293908000031],
            [-80.93111827499996, 35.369231909000064],
            [-80.930790275999982, 35.369038909000039],
            [-80.930710274999967, 35.369002908000027],
            [-80.93060427599994, 35.368994908000047],
            [-80.930430275999981, 35.369007908000071],
            [-80.930369275999965, 35.369019908000041],
            [-80.930297274999987, 35.369010909000053],
            [-80.930222274999949, 35.368994909000037],
            [-80.930027274999986, 35.368893908000075],
            [-80.929922274999967, 35.36886690800003],
            [-80.929683275999935, 35.368886908000036],
            [-80.929602274999979, 35.368921908000061],
            [-80.92949327599996, 35.368959909000068],
            [-80.929373274999989, 35.368991909000044],
            [-80.929312274999972, 35.369021908000036],
            [-80.929236275999983, 35.369052908000072],
            [-80.929160274999958, 35.369068908000031],
            [-80.928963275999934, 35.369085909000034],
            [-80.928887274999965, 35.36908790800004],
            [-80.928865274999964, 35.369106908000049],
            [-80.928495274999989, 35.369219908000048],
            [-80.928338274999987, 35.369334908000042],
            [-80.928194274999953, 35.369379908000042],
            [-80.928081274999954, 35.369435908000071],
            [-80.92788427499994, 35.369508908000057],
            [-80.92773727499997, 35.369576909000045],
            [-80.927644275999967, 35.36963490800008],
            [-80.927495273999966, 35.369737909000037],
            [-80.927431274999947, 35.369793909000066],
            [-80.927173274999973, 35.369903908000026],
            [-80.927077274999988, 35.369922908000035],
            [-80.927004273999955, 35.370049909000045],
            [-80.92691627499994, 35.37009690800005],
            [-80.926853274999985, 35.370030909000036],
            [-80.926819273999968, 35.369983909000041],
            [-80.926747274999968, 35.370000908000065],
            [-80.926692273999947, 35.370046909000052],
            [-80.92669527399994, 35.370126909000078],
            [-80.926714273999949, 35.370179909000058],
            [-80.926763274999985, 35.370279909000033],
            [-80.926788273999989, 35.370359909000058],
            [-80.92674027399994, 35.370449909000058],
            [-80.926671273999943, 35.370473909000054],
            [-80.926647274999937, 35.370477909000044],
            [-80.926550274999954, 35.370426909000059],
            [-80.926472274999981, 35.370343910000031],
            [-80.926367273999972, 35.370246909000059],
            [-80.926316274999976, 35.37021990900007],
            [-80.92625527499996, 35.370207910000033],
            [-80.925777273999984, 35.370203909000054],
            [-80.925436274999981, 35.370209909000039],
            [-80.92534427399994, 35.370196908000025],
            [-80.925216273999979, 35.370198909000067],
            [-80.925071273999947, 35.370224909000058],
            [-80.924815273999968, 35.370299909000039],
            [-80.924345273999961, 35.370425908000072],
            [-80.924012273999949, 35.370534909000071],
            [-80.923922273999949, 35.370560909000062],
            [-80.923860273999935, 35.370535909000068],
            [-80.923882272999947, 35.370464908000031],
            [-80.923837273999936, 35.370388909000042],
            [-80.923806272999968, 35.37032190900004],
            [-80.923930272999939, 35.370262909000076],
            [-80.923987272999966, 35.370245909000062],
            [-80.924134273999982, 35.370220909000068],
            [-80.924201273999984, 35.370188909000035],
            [-80.924404273999983, 35.370027909000044],
            [-80.924647273999938, 35.369866908000063],
            [-80.924890273999949, 35.369698909000078],
            [-80.925125274999971, 35.369640909000054],
            [-80.925207272999955, 35.369631909000077],
            [-80.925412273999939, 35.369635909000067],
            [-80.925507273999983, 35.369619908000061],
            [-80.925604273999966, 35.369592908000072],
            [-80.925687273999984, 35.369588908000026],
            [-80.925786273999961, 35.369590909000067],
            [-80.925853273999962, 35.369575909000048],
            [-80.925979274999975, 35.369557909000036],
            [-80.926052273999971, 35.369560909000029],
            [-80.92612027499996, 35.369557908000047],
            [-80.926316274999976, 35.369527909000055],
            [-80.926514273999942, 35.369452908000028],
            [-80.926733274999947, 35.369343909000065],
            [-80.926893273999951, 35.369256908000068],
            [-80.927057274999981, 35.369149909000043],
            [-80.927157273999967, 35.369100908000064],
            [-80.927280274999987, 35.369047908000027],
            [-80.927416274999985, 35.369008908000069],
            [-80.927581274999966, 35.36895290800004],
            [-80.927781273999983, 35.368897908000065],
            [-80.928074274999972, 35.368773908000037],
            [-80.928148275999945, 35.368706909000025],
            [-80.92822827599997, 35.368601908000073],
            [-80.928331274999948, 35.368525909000027],
            [-80.928407274999984, 35.368459908000034],
            [-80.928467274999946, 35.368419908000078],
            [-80.928576275999944, 35.36838990800004],
            [-80.928628274999937, 35.368351908000079],
            [-80.928682274999971, 35.368305909000071],
            [-80.928748274999975, 35.368211908000035],
            [-80.928781273999959, 35.368151908000073],
            [-80.928833275999978, 35.368087908000064],
            [-80.928985274999945, 35.368060909000064],
            [-80.929063275999965, 35.368039908000071],
            [-80.929118274999951, 35.368011908000028],
            [-80.92917027499999, 35.367978909000044],
            [-80.929218274999982, 35.367914908000046],
            [-80.92920027599996, 35.367817908000063],
            [-80.929252274999953, 35.367774908000058],
            [-80.929308274999983, 35.367823908000048],
            [-80.92945227499996, 35.367925909000064],
            [-80.929523274999951, 35.367958909000038],
            [-80.929621274999988, 35.367991908000079],
            [-80.929714274999981, 35.368000908000056],
            [-80.929794274999949, 35.36800290900004],
            [-80.929875274999972, 35.368032908000032],
            [-80.929961275999972, 35.368073908000042],
            [-80.930049275999977, 35.368051908000041],
            [-80.93013127599994, 35.368006908000041],
            [-80.93027727599997, 35.367992908000076],
            [-80.930403275999936, 35.367989908000027],
            [-80.930492274999949, 35.36798090800005],
            [-80.930583275999936, 35.36798090800005],
            [-80.930673275999936, 35.367996908000066],
            [-80.930749275999972, 35.367981909000036],
            [-80.931031275999942, 35.367817908000063],
            [-80.93108127499994, 35.367793908000067],
            [-80.931132275999971, 35.367762908000032],
            [-80.931188275999943, 35.367711908000047],
            [-80.931261275999987, 35.367627909000078],
            [-80.931287275999978, 35.367565908000074],
            [-80.93124527599997, 35.367469908000032],
            [-80.931231275999949, 35.36742190700005],
            [-80.931226275999961, 35.36737290800005],
            [-80.93124127599998, 35.367279908000057],
            [-80.931226275999961, 35.36718490800007],
            [-80.931200274999981, 35.367107908000037],
            [-80.931145274999949, 35.366975908000029],
            [-80.93107727499995, 35.366838908000034],
            [-80.930994275999979, 35.366640907000033],
            [-80.930938275999949, 35.36654790800003],
            [-80.930895275999944, 35.366488908000065],
            [-80.930803275999949, 35.366395908000072],
            [-80.930711275999954, 35.36635590800006],
            [-80.930654274999938, 35.366340908000041],
            [-80.930595275999963, 35.366332908000061],
            [-80.930445275999944, 35.366341908000038],
            [-80.930366275999972, 35.366326908000076],
            [-80.930329275999952, 35.366242908000061],
            [-80.930284274999963, 35.36611590800004],
            [-80.930224274999944, 35.365810908000071],
            [-80.930195275999949, 35.365503908000051],
            [-80.930175275999943, 35.365444908000029],
            [-80.930167274999974, 35.365394907000052],
            [-80.930146275999959, 35.36533890700008],
            [-80.930045274999941, 35.365141907000066],
            [-80.93003727599995, 35.36505790700005],
            [-80.930092275999982, 35.364973907000035],
            [-80.930145274999973, 35.36491490700007],
            [-80.930304274999969, 35.364826908000055],
            [-80.93038327499994, 35.364805908000051],
            [-80.930423274999953, 35.364765908000038],
            [-80.930517274999943, 35.364709908000066],
            [-80.930384274999938, 35.364551907000077],
            [-80.930051274999983, 35.364476907000039],
            [-80.931238275999988, 35.363070908000054],
            [-80.930594274999976, 35.36306490700008],
            [-80.929739274999974, 35.362980907000065],
            [-80.928982274999953, 35.362898907000044],
            [-80.928208274999974, 35.362793908000071],
            [-80.927440273999935, 35.362626907000049],
            [-80.92741827399999, 35.362621907000062],
            [-80.926707274999956, 35.362495907000039],
            [-80.926291273999936, 35.362338907000037],
            [-80.92598127499997, 35.362221907000048],
            [-80.925309273999972, 35.362021907000042],
            [-80.924681273999965, 35.361785907000069],
            [-80.924214272999961, 35.361662907000039],
            [-80.923735273999966, 35.361265906000028],
            [-80.923344272999941, 35.360906907000071],
            [-80.922997272999964, 35.360644907000051],
            [-80.922885272999963, 35.360340907000079],
            [-80.922953272999962, 35.360082907000049],
            [-80.923005272999944, 35.359930907000034],
            [-80.923048272999949, 35.359810907000053],
            [-80.923107271999982, 35.359699907000049],
            [-80.923384272999954, 35.359186907000037],
            [-80.923857273999943, 35.358956907000049],
            [-80.924387273999969, 35.358622906000051],
            [-80.924869273999946, 35.358369907000053],
            [-80.925468272999979, 35.358169906000057],
            [-80.926376273999949, 35.357870906000073],
            [-80.927011273999938, 35.357641906000026],
            [-80.92766327399994, 35.357530906000079],
            [-80.929344273999959, 35.357254906000037],
            [-80.931328274999942, 35.357603906000065],
            [-80.932488275999958, 35.357533905000025],
            [-80.93303227499996, 35.357377905000078],
            [-80.933440275999942, 35.357279907000077],
            [-80.933931275999953, 35.357092906000048],
            [-80.934550275999982, 35.356789906000074],
            [-80.935498275999976, 35.356246905000035],
            [-80.936612276999938, 35.355506905000027],
            [-80.938330277999967, 35.35428590500004],
            [-80.93908027699996, 35.353654904000052],
            [-80.939648276999947, 35.35317890500005],
            [-80.940401276999978, 35.352455904000067],
            [-80.94059227799994, 35.35230490500004],
            [-80.941178277999938, 35.351844905000064],
            [-80.941764277999937, 35.351363904000038],
            [-80.942357277999974, 35.351016904000062],
            [-80.942921277999972, 35.350705904000051],
            [-80.943613277999987, 35.350518904000069],
            [-80.94351927799994, 35.349537903000055],
            [-80.942990278999957, 35.348711903000037],
            [-80.942669277999983, 35.348181903000068],
            [-80.942409277999957, 35.347798904000058],
            [-80.94227727699996, 35.347574903000066],
            [-80.942118276999963, 35.347282904000053],
            [-80.941999277999969, 35.34707390300008],
            [-80.941861277999976, 35.346818903000042],
            [-80.941480277999972, 35.346155903000067],
            [-80.941179276999947, 35.345594903000062],
            [-80.941047276999939, 35.345381903000032],
            [-80.940969276999965, 35.345268902000043],
            [-80.94078327699998, 35.345015902000057],
            [-80.940368276999948, 35.344496903000049],
            [-80.939629276999938, 35.343554902000051],
            [-80.939531276999958, 35.343406903000073],
            [-80.939476276999983, 35.343289903000027],
            [-80.939431275999937, 35.34314990200005],
            [-80.93939927699995, 35.34300690200007],
            [-80.93938327799998, 35.342853902000058],
            [-80.93938327799998, 35.34266890300006],
            [-80.93940727599994, 35.342278902000032],
            [-80.939424275999954, 35.342101902000024],
            [-80.939429276999988, 35.341938902000038],
            [-80.939427276999936, 35.341836902000068],
            [-80.939405276999935, 35.341645902000039],
            [-80.939384277999977, 35.341562902000078],
            [-80.939224276999937, 35.341134902000078],
            [-80.938977275999946, 35.340532901000074],
            [-80.938717276999967, 35.339929902000051],
            [-80.938518276999957, 35.339444902000025],
            [-80.938483276999989, 35.339345902000048],
            [-80.938422276999972, 35.339174902000025],
            [-80.938392275999945, 35.33864690200005],
            [-80.938361275999966, 35.338452902000029],
            [-80.938365276999946, 35.338356902000044],
            [-80.938357276999966, 35.338205902000027],
            [-80.938354275999984, 35.338140902000077],
            [-80.938350276999984, 35.337467901000025],
            [-80.938343276999944, 35.33713890100006],
            [-80.938342276999947, 35.337026901000058],
            [-80.938336275999973, 35.336255902000062],
            [-80.938323276999938, 35.336087901000042],
            [-80.938286275999985, 35.335769900000059],
            [-80.938265275999981, 35.335649901000068],
            [-80.938244275999978, 35.335567901000047],
            [-80.938180274999979, 35.335369901000035],
            [-80.938062275999982, 35.33509390100005],
            [-80.937629275999939, 35.334163901000068],
            [-80.937444274999962, 35.333735901000068],
            [-80.937367275999975, 35.333578901000067],
            [-80.937335275999942, 35.333532901000069],
            [-80.93727127599999, 35.33346490100007],
            [-80.937074275999976, 35.333657901000038],
            [-80.93690027599996, 35.333810901000049],
            [-80.936738274999982, 35.333935901000075],
            [-80.936569275999943, 35.334047901000076],
            [-80.936454275999949, 35.33411390100008],
            [-80.936303274999943, 35.334190900000067],
            [-80.936036274999935, 35.334309900000051],
            [-80.935716274999947, 35.334438901000055],
            [-80.934599275999972, 35.334859901000073],
            [-80.933532274999948, 35.335162902000036],
            [-80.933190273999969, 35.335206901000049],
            [-80.932999274999986, 35.335221901000068],
            [-80.932706274999987, 35.335230901000045],
            [-80.932595274999983, 35.335227900000064],
            [-80.932348273999935, 35.335209901000042],
            [-80.931947273999981, 35.335158901000057],
            [-80.931485273999954, 35.335090901000058],
            [-80.931117272999984, 35.335036901000024],
            [-80.930520273999946, 35.334949901000073],
            [-80.92988827299996, 35.334853901000031],
            [-80.929607272999988, 35.33481590100007],
            [-80.929238273999943, 35.334783901000037],
            [-80.929099273999952, 35.33477890100005],
            [-80.928812273999938, 35.334785901000032],
            [-80.928575272999979, 35.334811902000069],
            [-80.92827127299995, 35.334871902000032],
            [-80.928058272999976, 35.334924901000079],
            [-80.927047272999971, 35.335204901000054],
            [-80.92687927299994, 35.335248901000057],
            [-80.926796272999979, 35.335270901000058],
            [-80.926530272999969, 35.335335902000054],
            [-80.926275272999987, 35.335388902000034],
            [-80.926120272999981, 35.335420901000077],
            [-80.925922272999969, 35.335457901000041],
            [-80.925475272999961, 35.335525902000029],
            [-80.924589271999935, 35.335650902000054],
            [-80.923879272999955, 35.335755901000027],
            [-80.922743270999945, 35.335917902000062],
            [-80.922506271999964, 35.335944902000051],
            [-80.922315270999945, 35.33595990200007],
            [-80.922054270999979, 35.335964901000068],
            [-80.921763270999975, 35.335949901000049],
            [-80.921535271999971, 35.335921902000052],
            [-80.921094270999959, 35.335838902000035],
            [-80.920474269999943, 35.335705902000029],
            [-80.919887270999936, 35.335573902000078],
            [-80.919338269999969, 35.335464902000069],
            [-80.919103269999937, 35.33542790100006],
            [-80.918893269999955, 35.335401901000068],
            [-80.918643269999961, 35.335378902000059],
            [-80.918231269999978, 35.335358902000053],
            [-80.917946269999959, 35.335357902000055],
            [-80.917583269999966, 35.335371902000077],
            [-80.917232269999943, 35.335398902000065],
            [-80.917037268999934, 35.335420901000077],
            [-80.916989269999988, 35.335429901000055],
            [-80.916250269999978, 35.335565902000042],
            [-80.91604426899994, 35.335600901000078],
            [-80.915287268999975, 35.335730901000034],
            [-80.915230269999938, 35.335741902000052],
            [-80.914652268999987, 35.335847901000079],
            [-80.914298268999971, 35.335921902000052],
            [-80.913991268999951, 35.336001903000067],
            [-80.913783268999964, 35.335749902000032],
            [-80.913221267999972, 35.335088901000063],
            [-80.912926268999968, 35.334676902000069],
            [-80.912808267999935, 35.33448590100005],
            [-80.912635267999974, 35.334186902000056],
            [-80.912550268999951, 35.334019901000033],
            [-80.912437267999962, 35.333744901000046],
            [-80.912311267999939, 35.33343190100004],
            [-80.912154267999938, 35.333011901000077],
            [-80.912076268999954, 35.332775901000048],
            [-80.911465266999983, 35.333212902000071],
            [-80.910880267999971, 35.333668901000067],
            [-80.910338267999975, 35.334129902000029],
            [-80.909756267999967, 35.334668902000033],
            [-80.908401266999988, 35.33609490200007],
            [-80.908098266999957, 35.336433902000067],
            [-80.907493267999939, 35.337073902000043],
            [-80.907158266999943, 35.337402903000054],
            [-80.906958266999936, 35.337583902000063],
            [-80.906539266999971, 35.337929903000031],
            [-80.906271266999966, 35.338130902000046],
            [-80.905818266999972, 35.338432902000079],
            [-80.905344265999986, 35.338712903000044],
            [-80.904871265999986, 35.338948903000073],
            [-80.904596265999942, 35.339086903000066],
            [-80.904338266999957, 35.339198903000067],
            [-80.904076266999937, 35.33930390300003],
            [-80.90366626499997, 35.339453903000049],
            [-80.903121265999971, 35.339621903000079],
            [-80.902564265999956, 35.339762904000054],
            [-80.901998265999964, 35.339875904000053],
            [-80.901424264999946, 35.339960904000066],
            [-80.900845265999976, 35.340016903000048],
            [-80.900366263999956, 35.340041903000042],
            [-80.899729264999962, 35.340043903000037],
            [-80.899058264999951, 35.340017904000035],
            [-80.89846526499997, 35.339970903000051],
            [-80.89573726399999, 35.33970490400003],
            [-80.894385262999947, 35.33957390300003],
            [-80.894369263999977, 35.339608904000045],
            [-80.894295263999936, 35.339781904000063],
            [-80.893663262999951, 35.339722904000041],
            [-80.89293926299996, 35.339693903000068],
            [-80.892214261999982, 35.339693904000058],
            [-80.891491261999988, 35.339723904000039],
            [-80.890769260999946, 35.339781904000063],
            [-80.890045261999944, 35.339869903000078],
            [-80.88933726099998, 35.33998590300007],
            [-80.888637260999985, 35.340128904000039],
            [-80.887946260999968, 35.34030090400006],
            [-80.887266260999979, 35.340499904000069],
            [-80.886599260999958, 35.340724904000069],
            [-80.885946260999958, 35.340977903000066],
            [-80.885517260999961, 35.341164904000038],
            [-80.885309260999975, 35.341255904000036],
            [-80.884690259999957, 35.341558905000056],
            [-80.884371259999966, 35.341732904000025],
            [-80.883687259999988, 35.342137904000026],
            [-80.883025259999954, 35.34256690400008],
            [-80.882290258999944, 35.343056904000036],
            [-80.881581259999962, 35.343501905000039],
            [-80.881512259999965, 35.343540905000054],
            [-80.881412258999944, 35.343598905000079],
            [-80.881198258999973, 35.343458904000045],
            [-80.879566258999944, 35.342389904000072],
            [-80.87930825899997, 35.342196905000037],
            [-80.879076258999987, 35.341982904000076],
            [-80.878871258999936, 35.341751905000024],
            [-80.87847925899996, 35.341187905000027],
            [-80.877396257999976, 35.33927990400008],
            [-80.877251258999934, 35.339018904000056],
            [-80.876688256999955, 35.338040904000025],
            [-80.875991256999953, 35.336813904000053],
            [-80.875699257999941, 35.336300903000051],
            [-80.875323256999934, 35.335643903000062],
            [-80.874933256999952, 35.334947903000057],
            [-80.874720256999979, 35.334524902000055],
            [-80.874418257999935, 35.333874903000037],
            [-80.874203256999976, 35.333372903000054],
            [-80.874167257999943, 35.333288903000039],
            [-80.87377025699999, 35.332265902000074],
            [-80.873505256999977, 35.331581903000028],
            [-80.873434255999939, 35.33142390200004],
            [-80.873372255999982, 35.331318903000067],
            [-80.873282256999971, 35.331193902000052],
            [-80.873155256999951, 35.331056902000057],
            [-80.873008255999935, 35.330914902000075],
            [-80.87253325599994, 35.330483903000072],
            [-80.872197255999936, 35.330193902000076],
            [-80.871026255999936, 35.329230902000063],
            [-80.87078125499994, 35.329023901000028],
            [-80.86920525499994, 35.327736902000026],
            [-80.869153255999947, 35.327693902000078],
            [-80.868798254999945, 35.327396902000032],
            [-80.868723254999964, 35.327329901000041],
            [-80.868535253999937, 35.327162902000055],
            [-80.867973254999981, 35.326607901000045],
            [-80.86749425499994, 35.326134902000035],
            [-80.86725325499998, 35.325894902000073],
            [-80.867066253999951, 35.325689901000032],
            [-80.866915254999981, 35.325489901000026],
            [-80.866845253999941, 35.325372902000026],
            [-80.866759253999987, 35.325425902000063],
            [-80.86667625399997, 35.32549090200007],
            [-80.86582825399995, 35.326240901000062],
            [-80.865121253999973, 35.32687590200004],
            [-80.864835253999956, 35.327142902000048],
            [-80.864528253999936, 35.327425901000026],
            [-80.86436525299996, 35.32756990200005],
            [-80.864248252999971, 35.327679902000057],
            [-80.863697253999987, 35.328167902000075],
            [-80.86310825299995, 35.328702903000078],
            [-80.863009252999973, 35.328785903000039],
            [-80.862866252999936, 35.328896902000054],
            [-80.86218525299995, 35.329382903000067],
            [-80.862066252999966, 35.329463902000043],
            [-80.861916252999947, 35.329547903000048],
            [-80.861851251999951, 35.329575902000045],
            [-80.861783252999942, 35.329597903000035],
            [-80.861704252999971, 35.329617902000052],
            [-80.861599252999952, 35.329637902000059],
            [-80.861345252999968, 35.32966790200004],
            [-80.86074825299994, 35.329709903000037],
            [-80.85997925199996, 35.329773903000046],
            [-80.858887251999988, 35.329865903000041],
            [-80.858589251999945, 35.329896903000076],
            [-80.858471251999958, 35.329916903000026],
            [-80.858103251999978, 35.330004903000031],
            [-80.857984252999984, 35.330036902000074],
            [-80.857748251999965, 35.330119902000035],
            [-80.857729251999956, 35.330127903000061],
            [-80.857473251999977, 35.330229903000031],
            [-80.856667251999966, 35.330611903000033],
            [-80.856378250999967, 35.330752903000075],
            [-80.856086250999965, 35.330900903000042],
            [-80.85553625099999, 35.331162903000063],
            [-80.855145250999954, 35.331343903000061],
            [-80.854460250999978, 35.331675902000029],
            [-80.854137250999941, 35.33182090400004],
            [-80.853922250999972, 35.331908903000055],
            [-80.853711250999936, 35.331988903000024],
            [-80.853396249999946, 35.332091903000048],
            [-80.853217249999943, 35.332140904000028],
            [-80.852565250999987, 35.332294904000037],
            [-80.852470249999953, 35.332325903000026],
            [-80.852436250999972, 35.332345904000078],
            [-80.852414249999981, 35.332365903000039],
            [-80.85237725099995, 35.332429904000037],
            [-80.852298250999979, 35.332525903000032],
            [-80.851793250999947, 35.333001903000024],
            [-80.851654249999967, 35.333117904000062],
            [-80.851540249999971, 35.333201903000031],
            [-80.851365250999947, 35.333308904000035],
            [-80.851161248999972, 35.333410903000072],
            [-80.850927249999984, 35.333503904000054],
            [-80.850724249999985, 35.333565904000068],
            [-80.85054624899999, 35.333604904000026],
            [-80.849208249999947, 35.333788904000073],
            [-80.848757249999949, 35.33385090400003],
            [-80.848318248999988, 35.333908904000054],
            [-80.846675248999986, 35.334133904000055],
            [-80.845706248999988, 35.334265905000052],
            [-80.845415247999938, 35.334313904000055],
            [-80.845385247999957, 35.334321905000024],
            [-80.84527924799994, 35.334349904000078],
            [-80.844901247999985, 35.334474905000036],
            [-80.844753247999961, 35.334530904000076],
            [-80.844542248999971, 35.334623904000068],
            [-80.844467247999944, 35.334659903000045],
            [-80.844431247999978, 35.334678904000043],
            [-80.844373247999954, 35.334708904000024],
            [-80.843267247999961, 35.335338905000071],
            [-80.843024248999939, 35.335485905000041],
            [-80.842947247999973, 35.335560904000033],
            [-80.842826247999938, 35.335649905000025],
            [-80.842736246999948, 35.33569990500007],
            [-80.842654247999974, 35.335735904000046],
            [-80.842271246999985, 35.335903905000066],
            [-80.841668247999962, 35.336287905000063],
            [-80.840912246999949, 35.336750905000031],
            [-80.84009724799995, 35.337249905000078],
            [-80.839898246999951, 35.337366906000057],
            [-80.839705246999984, 35.33745990500006],
            [-80.839514246999954, 35.337533905000043],
            [-80.839332246999959, 35.33759490500006],
            [-80.839217246999965, 35.337627905000033],
            [-80.839147245999982, 35.337646905000042],
            [-80.839081245999978, 35.337665905000051],
            [-80.838583245999985, 35.337782904000051],
            [-80.838211245999958, 35.337865905000058],
            [-80.836923245999969, 35.338192905000028],
            [-80.835758245999955, 35.338477906000037],
            [-80.835352244999967, 35.338581905000069],
            [-80.834883245999947, 35.338696905000063],
            [-80.834301245999939, 35.338856905000057],
            [-80.833521244999986, 35.339038905000052],
            [-80.833271244999935, 35.339081906000047],
            [-80.83313024499995, 35.339098905000071],
            [-80.832898244999967, 35.339110905000041],
            [-80.832734244999983, 35.339113905000033],
            [-80.832505244999936, 35.339108905000046],
            [-80.831935243999965, 35.339097905000074],
            [-80.82832024399994, 35.339004906000071],
            [-80.827885242999969, 35.33899490500005],
            [-80.826954243999978, 35.338973906000035],
            [-80.824361242999942, 35.338903906000041],
            [-80.824177242999951, 35.338893905000077],
            [-80.824059241999976, 35.338882906000038],
            [-80.823989241999982, 35.338875906000055],
            [-80.823682240999972, 35.338825906000068],
            [-80.822240240999975, 35.338573906000079],
            [-80.821838241999956, 35.338496906000046],
            [-80.82133324199998, 35.338373905000026],
            [-80.821005240999966, 35.338276906000033],
            [-80.820791240999938, 35.338200906000054],
            [-80.820685240999978, 35.338156906000052],
            [-80.820576240999969, 35.338105906000067],
            [-80.820470241999942, 35.338043905000063],
            [-80.820338240999945, 35.337945905000026],
            [-80.82028324099997, 35.337896905000036],
            [-80.820137240999941, 35.33774190500003],
            [-80.820023240999944, 35.337605905000032],
            [-80.819780241999979, 35.337299906000055],
            [-80.819480240999951, 35.336958906000064],
            [-80.819046239999977, 35.336487906000059],
            [-80.81894024099995, 35.336390906000076],
            [-80.818828239999959, 35.336303906000069],
            [-80.818762240999945, 35.336263905000067],
            [-80.81867023999996, 35.336222905000056],
            [-80.818559239999956, 35.33619490600006],
            [-80.818475239999941, 35.336184906000028],
            [-80.818372239999974, 35.336182905000044],
            [-80.818214240999964, 35.336200905000055],
            [-80.818072239999935, 35.336227905000044],
            [-80.817950239999959, 35.336259906000066],
            [-80.817765240999961, 35.336318906000031],
            [-80.817630239999971, 35.33637090600007],
            [-80.817470239999977, 35.336447906000046],
            [-80.81727824099994, 35.336558905000061],
            [-80.816823239999962, 35.336875906000046],
            [-80.816727239999977, 35.336942905000058],
            [-80.816589239999985, 35.337029906000055],
            [-80.816406240999981, 35.33712590600004],
            [-80.816219238999963, 35.337213905000056],
            [-80.816033239999967, 35.337280906000046],
            [-80.814406238999936, 35.337810907000062],
            [-80.813914238999985, 35.337959906000037],
            [-80.813511238999979, 35.338075906000029],
            [-80.813152238999976, 35.33816790700007],
            [-80.812428238999985, 35.338321907000079],
            [-80.811115237999957, 35.33859190700008],
            [-80.810599237999952, 35.33869790600005],
            [-80.809772237999937, 35.338860907000026],
            [-80.808983237999939, 35.339015906000043],
            [-80.808684237999955, 35.339074907000054],
            [-80.808448237999983, 35.33911390600008],
            [-80.808325237999952, 35.339128907000031],
            [-80.808193236999955, 35.339137907000065],
            [-80.808036237999943, 35.339135906000024],
            [-80.807884237999986, 35.339128906000042],
            [-80.807652236999957, 35.33909890700005],
            [-80.807210236999936, 35.339022906000025],
            [-80.805952236999985, 35.338825907000057],
            [-80.804051235999964, 35.338533907000055],
            [-80.803858236999986, 35.338503906000028],
            [-80.802102235999939, 35.338253906000034],
            [-80.801751234999983, 35.338203907000036],
            [-80.801037235999956, 35.338105907000056],
            [-80.799367234999977, 35.337972907000051],
            [-80.798496234999959, 35.337915906000035],
            [-80.796659233999947, 35.337784906000024],
            [-80.796626233999973, 35.338056907000066],
            [-80.796552233999989, 35.338581906000059],
            [-80.796434234999936, 35.339495907000071],
            [-80.796405233999963, 35.339602907000028],
            [-80.796388233999949, 35.339802908000024],
            [-80.796366233999947, 35.33993190700005],
            [-80.796322233999945, 35.340072908000025],
            [-80.796145233999937, 35.340568907000034],
            [-80.795761232999951, 35.34164290700005],
            [-80.795633233999979, 35.342015908000064],
            [-80.795506233999959, 35.342495908000046],
            [-80.795429233999982, 35.342832907000059],
            [-80.795412233999969, 35.342929908000031],
            [-80.795396233999952, 35.343105908000041],
            [-80.79539323399996, 35.343211908000058],
            [-80.795405233999986, 35.343617908000056],
            [-80.795402234999983, 35.343655908000073],
            [-80.795396233999952, 35.343708908000053],
            [-80.795429233999982, 35.344386909000036],
            [-80.795711234999942, 35.34701190800007],
            [-80.795741233999934, 35.347278909000067],
            [-80.795768233999979, 35.347550908000073],
            [-80.79579023499997, 35.34778091000004],
            [-80.795811234999974, 35.348044909000066],
            [-80.795881233999978, 35.348936908000042],
            [-80.795974233999971, 35.350094909000063],
            [-80.795977234999953, 35.350282909000043],
            [-80.795959233999952, 35.350469909000026],
            [-80.795949233999977, 35.350527910000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795912234999946, 35.350689909000039],
            [-80.795924234999973, 35.350762910000071],
            [-80.795845233999955, 35.350827909000031],
            [-80.795817233999969, 35.350859910000054],
            [-80.79579423399997, 35.350894910000079],
            [-80.795246234999979, 35.352010910000047],
            [-80.795148234999942, 35.352198910000027],
            [-80.795143233999966, 35.352207910000061],
            [-80.794993234999936, 35.352494910000075],
            [-80.794889233999982, 35.352679909000074],
            [-80.79472123499994, 35.353003910000041],
            [-80.794592233999936, 35.353298911000024],
            [-80.794308233999971, 35.354033910000055],
            [-80.794259233999981, 35.354203910000024],
            [-80.794232233999935, 35.354376910000042],
            [-80.794228233999945, 35.35450091000007],
            [-80.794243233999964, 35.354664910000054],
            [-80.794266234999952, 35.354800911000041],
            [-80.794301234999978, 35.354924911000069],
            [-80.794327234999969, 35.354984911000031],
            [-80.794382233999954, 35.355082910000078],
            [-80.79446623399997, 35.355181911000045],
            [-80.794587233999948, 35.355283911000072],
            [-80.79467923499999, 35.35533791000006],
            [-80.794827234999957, 35.355424910000067],
            [-80.795254234999959, 35.35565691100004],
            [-80.795571234999954, 35.355819910000037],
            [-80.797256235999953, 35.356629911000027],
            [-80.797561235999979, 35.356782910000049],
            [-80.797742235999976, 35.356880911000076],
            [-80.797908234999966, 35.356993910000028],
            [-80.798015235999969, 35.357091910000065],
            [-80.798130234999974, 35.357221910000078],
            [-80.798263235999968, 35.35740891100005],
            [-80.798339234999958, 35.357540911000058],
            [-80.798605235999958, 35.358067911000035],
            [-80.798719235999954, 35.358270911000034],
            [-80.798848235999969, 35.358548911000071],
            [-80.799051235999968, 35.358940911000047],
            [-80.799117234999983, 35.359049912000046],
            [-80.79924523599999, 35.359229912000046],
            [-80.799348234999968, 35.35935591100008],
            [-80.799633235999977, 35.359705911000049],
            [-80.799779235999949, 35.35991591100003],
            [-80.799856235999982, 35.360064912000041],
            [-80.799902236999969, 35.360178912000038],
            [-80.799913234999963, 35.360216912000055],
            [-80.799935235999953, 35.360268911000048],
            [-80.799952235999967, 35.360326911000072],
            [-80.799622235999948, 35.360418912000057],
            [-80.798988235999957, 35.360611912000024],
            [-80.798501235999936, 35.360765911000044],
            [-80.797442234999949, 35.361090912000066],
            [-80.797181234999982, 35.361181911000074],
            [-80.797062234999942, 35.361228911000069],
            [-80.796106234999968, 35.361662912000043],
            [-80.79504323499998, 35.362153912000053],
            [-80.794044233999955, 35.362620912000068],
            [-80.793893234999985, 35.362692912000057],
            [-80.79375323499994, 35.362753912000073],
            [-80.793432234999955, 35.362907912000026],
            [-80.792583233999949, 35.363296912000067],
            [-80.792412234999972, 35.36336691200006],
            [-80.792199233999952, 35.363451912000073],
            [-80.791902233999963, 35.363558913000077],
            [-80.791545233999955, 35.363679912000066],
            [-80.791312232999985, 35.363761913000076],
            [-80.791192232999947, 35.363803913000027],
            [-80.791017232999934, 35.363860912000064],
            [-80.79080823399994, 35.363930912000058],
            [-80.790297233999979, 35.364097912000034],
            [-80.78977523399999, 35.364281912000024],
            [-80.789337233999959, 35.364425913000048],
            [-80.789110232999974, 35.364524912000036],
            [-80.78895423299997, 35.364602913000056],
            [-80.788622231999966, 35.364780913000061],
            [-80.788296232999983, 35.364966913000046],
            [-80.787528231999943, 35.365370913000049],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365560913000024],
            [-80.787499232999949, 35.365769912000076],
            [-80.78746023399998, 35.365936914000031],
            [-80.787396232999981, 35.366161913000042],
            [-80.792751234999969, 35.368284913000025],
            [-80.794869234999965, 35.369124913000064],
            [-80.79568123599995, 35.369461914000055],
            [-80.795949234999966, 35.370134913000072],
            [-80.796038234999969, 35.370239914000024],
            [-80.796087234999959, 35.370324914000037],
            [-80.796123235999971, 35.370414914000037],
            [-80.796280235999973, 35.371011914000064],
            [-80.796325234999983, 35.371120914000073],
            [-80.796389234999936, 35.371225914000036],
            [-80.796635235999986, 35.371587914000031],
            [-80.796870235999961, 35.371958914000061],
            [-80.796915235999961, 35.372047913000074],
            [-80.796933235999973, 35.372133914000074],
            [-80.796958235999966, 35.372212914000045],
            [-80.796959235999964, 35.37238691400006],
            [-80.796966234999957, 35.372515914000076],
            [-80.796976235999978, 35.372682915000041],
            [-80.796997235999982, 35.372774914000047],
            [-80.797039235999989, 35.372922914000071],
            [-80.797416235999947, 35.373811914000044],
            [-80.797446236999974, 35.373914914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797468235999986, 35.374094914000068],
            [-80.797451236999962, 35.374244915000077],
            [-80.797408235999967, 35.374408914000071],
            [-80.797247235999976, 35.374933914000053],
            [-80.797101235999946, 35.375382914000056],
            [-80.79768123599996, 35.375517915000046],
            [-80.797975236999946, 35.375592915000027],
            [-80.798128235999968, 35.375652915000046],
            [-80.798258235999981, 35.375729914000033],
            [-80.798362236999935, 35.375802915000065],
            [-80.798447236999948, 35.375885915000026],
            [-80.798501236999982, 35.375957915000072],
            [-80.798560236999947, 35.376061915000037],
            [-80.798594236999975, 35.376165915000058],
            [-80.798619236999969, 35.376291915000024],
            [-80.798623236999958, 35.376401914000041],
            [-80.798611236999989, 35.376517915000079],
            [-80.798570235999989, 35.376642916000037],
            [-80.798507235999978, 35.376789915000074],
            [-80.798463236999964, 35.376912915000048],
            [-80.798449235999954, 35.376998915000058],
            [-80.79845323699999, 35.37707391500004],
            [-80.798466235999967, 35.377154915000062],
            [-80.798507236999967, 35.377240915000073],
            [-80.79855123699997, 35.377312914000072],
            [-80.798589235999941, 35.377364916000033],
            [-80.798712236999961, 35.377486914000031],
            [-80.797820236999939, 35.378089914000043],
            [-80.797605236999971, 35.378237916000046],
            [-80.797417236999934, 35.378367915000069],
            [-80.796784235999951, 35.378796915000066],
            [-80.796537235999949, 35.378964916000029],
            [-80.795169236999982, 35.379910915000039],
            [-80.794706235999968, 35.380224916000031],
            [-80.794392234999975, 35.380420916000048],
            [-80.794257234999975, 35.380490915000053],
            [-80.794104235999953, 35.380559916000038],
            [-80.793955235999988, 35.380613916000073],
            [-80.793399234999981, 35.380789916000026],
            [-80.79240923499998, 35.381092915000067],
            [-80.791912235999973, 35.38124491700006],
            [-80.791240234999975, 35.381449916000065],
            [-80.790655234999974, 35.38162791700006],
            [-80.790401233999944, 35.381705916000044],
            [-80.790291234999984, 35.381739916000072],
            [-80.789902233999953, 35.381876916000067],
            [-80.789763233999963, 35.381933916000037],
            [-80.789696233999962, 35.381965917000059],
            [-80.789469233999966, 35.382082917000048],
            [-80.789291233999961, 35.38218691600008],
            [-80.789050233999944, 35.382340916000032],
            [-80.788806233999935, 35.382512917000042],
            [-80.788435233999962, 35.382791916000031],
            [-80.788118233999967, 35.383049916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.786700233999966, 35.384445916000061],
            [-80.785941233999949, 35.385195917000033],
            [-80.785334232999958, 35.385761918000071],
            [-80.784783232999985, 35.386234918000071],
            [-80.784700232999967, 35.386299917000031],
            [-80.78233923199997, 35.38815291700007],
            [-80.78141223199998, 35.388881918000038],
            [-80.779635231999976, 35.390281918000028],
            [-80.779433231999974, 35.390435918000037],
            [-80.779261231999953, 35.390556918000073],
            [-80.77899923199999, 35.390720919000046],
            [-80.778744231999951, 35.390860919000033],
            [-80.778606230999969, 35.390927919000035],
            [-80.778365230999952, 35.391035919000046],
            [-80.778006230999949, 35.391183919000071],
            [-80.778089230999967, 35.391297919000067],
            [-80.780835231999959, 35.395009919000074],
            [-80.782405232999963, 35.397131920000049],
            [-80.783722233999981, 35.398911921000035],
            [-80.781756231999964, 35.399425920000056],
            [-80.781609232999983, 35.399464920000071],
            [-80.78152823299996, 35.399959921000061],
            [-80.781306232999952, 35.400481920000061],
            [-80.780617232999987, 35.402109921000033],
            [-80.78050723299998, 35.402369921000059],
            [-80.779215232999945, 35.405415921000042],
            [-80.778600232999963, 35.406866922000063],
            [-80.778380231999961, 35.407267922000074],
            [-80.779746232999969, 35.409076922000054],
            [-80.778319232999934, 35.409293922000074],
            [-80.774467231999949, 35.409852923000074],
            [-80.77464723199995, 35.412072923000039],
            [-80.77468023199998, 35.412043923000056],
            [-80.774795230999985, 35.411957923000045],
            [-80.774835231999987, 35.411895923000031],
            [-80.774847231999956, 35.411841923000054],
            [-80.774869230999968, 35.411792923000064],
            [-80.774931231999972, 35.41173092300005],
            [-80.775001231999966, 35.41169392300003],
            [-80.775138231999961, 35.411649923000027],
            [-80.775221230999989, 35.411617923000051],
            [-80.775310231999981, 35.41159592300005],
            [-80.775400230999935, 35.411566923000066],
            [-80.775561231999973, 35.411543923000067],
            [-80.77588923199994, 35.411468923000029],
            [-80.775947231999965, 35.411465924000026],
            [-80.776008230999935, 35.411454923000065],
            [-80.776192231999971, 35.411443923000036],
            [-80.776250231999938, 35.411434924000048],
            [-80.776315230999955, 35.411419923000039],
            [-80.776376231999961, 35.411396923000041],
            [-80.776439231999973, 35.411367923000057],
            [-80.776475231999939, 35.411360923000075],
            [-80.776517231999946, 35.411352923000038],
            [-80.776593231999982, 35.411327923000044],
            [-80.776644231999967, 35.411319923000065],
            [-80.776882230999945, 35.411309923000033],
            [-80.776959231999967, 35.41129092400007],
            [-80.777028231999964, 35.411278924000044],
            [-80.777196231999937, 35.411277924000046],
            [-80.777251231999969, 35.411270923000075],
            [-80.777359231999981, 35.411222923000025],
            [-80.777454231999968, 35.411194923000039],
            [-80.777593232999948, 35.411176923000028],
            [-80.77779923199995, 35.411139924000054],
            [-80.777890232999937, 35.411132923000025],
            [-80.778176232999954, 35.41108592300003],
            [-80.778279232999978, 35.411061923000034],
            [-80.778361231999952, 35.411056923000046],
            [-80.778514232999953, 35.411035923000043],
            [-80.778679232999934, 35.411002923000069],
            [-80.778748232999988, 35.410975923000024],
            [-80.778848232999962, 35.410948923000035],
            [-80.779038232999937, 35.410873922000064],
            [-80.779299232999961, 35.410825923000061],
            [-80.779398231999949, 35.410789924000028],
            [-80.779621232999943, 35.410735922000072],
            [-80.77980923299998, 35.410730923000074],
            [-80.77990323299997, 35.41070592300008],
            [-80.779957232999948, 35.410684923000076],
            [-80.780012233999969, 35.410651923000046],
            [-80.780097231999946, 35.410612923000031],
            [-80.780171232999976, 35.41058692200005],
            [-80.780265232999966, 35.410571923000077],
            [-80.780442232999974, 35.410561922000056],
            [-80.780512232999968, 35.410546922000037],
            [-80.780684232999988, 35.410449923000044],
            [-80.780733232999978, 35.410425922000059],
            [-80.780815233999988, 35.410404922000055],
            [-80.780898232999959, 35.410397923000062],
            [-80.781004232999976, 35.410382922000053],
            [-80.781095232999974, 35.410357923000049],
            [-80.781159233999972, 35.410330922000071],
            [-80.78124623299999, 35.410320923000029],
            [-80.781320233999963, 35.410303923000072],
            [-80.781706232999966, 35.410190922000027],
            [-80.781786233999981, 35.410157923000043],
            [-80.781881233999968, 35.410131922000062],
            [-80.781969233999973, 35.410112922000053],
            [-80.782108233999963, 35.410108923000053],
            [-80.782196233999969, 35.410110923000047],
            [-80.782359233999955, 35.410129922000067],
            [-80.78245123399995, 35.410145923000073],
            [-80.782764234999945, 35.410181923000039],
            [-80.783070233999979, 35.410227922000047],
            [-80.783224233999988, 35.410238922000076],
            [-80.783818233999966, 35.410228922000044],
            [-80.783905233999974, 35.410204923000038],
            [-80.784016233999978, 35.410179923000044],
            [-80.784323234999988, 35.410177922000059],
            [-80.784387233999951, 35.410171922000075],
            [-80.78444323399998, 35.410159923000037],
            [-80.78475423499998, 35.410140922000039],
            [-80.784862233999945, 35.410139922000042],
            [-80.784951233999948, 35.41014692300007],
            [-80.785137234999979, 35.410189922000029],
            [-80.785384234999981, 35.410273923000034],
            [-80.785492234999936, 35.410303923000072],
            [-80.785668234999946, 35.410324922000029],
            [-80.785882234999974, 35.410373922000076],
            [-80.785925235999969, 35.410368922000032],
            [-80.786037234999981, 35.410397923000062],
            [-80.786243234999972, 35.410411922000037],
            [-80.786335234999967, 35.410411922000037],
            [-80.786458234999941, 35.410389923000025],
            [-80.786682234999944, 35.410371922000024],
            [-80.787136233999945, 35.410282923000068],
            [-80.787198233999959, 35.410324922000029],
            [-80.787335234999944, 35.410345922000033],
            [-80.787429234999934, 35.410350923000067],
            [-80.787628234999943, 35.410384922000048],
            [-80.78767023499995, 35.410399923000057],
            [-80.787833235999983, 35.410426922000056],
            [-80.787994233999939, 35.410471923000046],
            [-80.788039234999985, 35.410493923000047],
            [-80.788173234999988, 35.410498923000034],
            [-80.788232234999953, 35.410509922000074],
            [-80.788283235999984, 35.410530923000067],
            [-80.788525235999941, 35.410669923000057],
            [-80.788655235999954, 35.410752923000075],
            [-80.788694235999969, 35.410767922000048],
            [-80.788770235999948, 35.41080792300005],
            [-80.788839235999944, 35.410874922000062],
            [-80.789014235999957, 35.410971922000044],
            [-80.789060235999955, 35.410996922000038],
            [-80.789383235999935, 35.411232923000057],
            [-80.789910235999969, 35.411580923000031],
            [-80.790065235999975, 35.411678923000068],
            [-80.79011223599997, 35.411716923000029],
            [-80.790309236999974, 35.411848922000047],
            [-80.790431234999971, 35.411944922000032],
            [-80.790521235999961, 35.412021922000065],
            [-80.79063823599995, 35.41210592200008],
            [-80.790689236999981, 35.412149923000072],
            [-80.79081423699995, 35.41228592300007],
            [-80.790915236999979, 35.412382923000052],
            [-80.790965236999966, 35.412443923000069],
            [-80.791081235999968, 35.412533923000069],
            [-80.791240236999954, 35.412730923000026],
            [-80.79145523599999, 35.41304092300004],
            [-80.791481236999971, 35.413098922000074],
            [-80.791531235999969, 35.413189923000061],
            [-80.791697236999937, 35.413465922000057],
            [-80.791844235999974, 35.413663922000069],
            [-80.791864235999981, 35.413702923000073],
            [-80.792006236999953, 35.413964924000027],
            [-80.792133236999973, 35.414167922000047],
            [-80.792267236999976, 35.414350923000029],
            [-80.792417236999938, 35.414601923000077],
            [-80.792622236999989, 35.414912923000031],
            [-80.792633236999961, 35.414945924000051],
            [-80.792634237999948, 35.414991923000059],
            [-80.79266423699994, 35.415047923000031],
            [-80.792695235999986, 35.415080924000051],
            [-80.792715236999982, 35.415113923000035],
            [-80.792714235999938, 35.415137924000078],
            [-80.792757236999989, 35.415187924000065],
            [-80.792779236999934, 35.41519792400004],
            [-80.792817236999952, 35.415201923000041],
            [-80.79284023699995, 35.415214924000054],
            [-80.792863236999949, 35.415238923000061],
            [-80.792874236999978, 35.41526192300006],
            [-80.792875235999986, 35.415281923000066],
            [-80.792854236999972, 35.415425924000033],
            [-80.792862236999952, 35.415516923000041],
            [-80.792853236999974, 35.415548924000063],
            [-80.792826236999986, 35.415595923000069],
            [-80.792817236999952, 35.415635923000025],
            [-80.792817236999952, 35.41579792400006],
            [-80.792857236999964, 35.415865923000069],
            [-80.792886237999937, 35.415891923000061],
            [-80.792909236999947, 35.415939923000053],
            [-80.792924237999955, 35.415982923000058],
            [-80.792952237999941, 35.416014924000024],
            [-80.792973237999945, 35.416029924000043],
            [-80.793020236999951, 35.41604592300007],
            [-80.793186236999986, 35.416178923000075],
            [-80.793460236999977, 35.41633192300003],
            [-80.79360823799999, 35.416421924000076],
            [-80.793729237999969, 35.416475924000054],
            [-80.79384123799997, 35.416538924000065],
            [-80.793918236999957, 35.416591923000055],
            [-80.794090237999967, 35.41668792300004],
            [-80.794143237999947, 35.41672892400004],
            [-80.794270237999967, 35.416778924000027],
            [-80.794415237999942, 35.416841924000039],
            [-80.794636237999953, 35.416964924000069],
            [-80.794758237999986, 35.417022923000047],
            [-80.794843237999942, 35.417071924000027],
            [-80.79497523799995, 35.417128924000053],
            [-80.795105237999962, 35.417203924000034],
            [-80.79554223699995, 35.41741492400007],
            [-80.795591237999986, 35.417442924000056],
            [-80.795659237999985, 35.417464923000068],
            [-80.795740237999951, 35.417501924000078],
            [-80.795773238999971, 35.417521923000038],
            [-80.795849238999949, 35.417596924000065],
            [-80.796040238999979, 35.417814923000037],
            [-80.796110237999983, 35.417915923000066],
            [-80.796150237999939, 35.417951923000032],
            [-80.796233237999957, 35.417998924000074],
            [-80.796339237999973, 35.418164923000063],
            [-80.796405237999977, 35.41829292400007],
            [-80.796512237999934, 35.418466924000029],
            [-80.79656523899996, 35.418531924000035],
            [-80.796604238999976, 35.418619924000041],
            [-80.796658238999953, 35.418673923000028],
            [-80.796733237999945, 35.418774924000047],
            [-80.796794237999961, 35.41887192400003],
            [-80.796924238999964, 35.41903592400007],
            [-80.797098238999979, 35.419267924000053],
            [-80.797286238999959, 35.41950492400008],
            [-80.797393238999973, 35.419667923000077],
            [-80.797445238999956, 35.419717924000054],
            [-80.797477238999988, 35.419763924000051],
            [-80.797535238999956, 35.419826924000063],
            [-80.797581237999964, 35.419912924000073],
            [-80.797649237999963, 35.419994925000026],
            [-80.797682238999982, 35.420026924000069],
            [-80.797721238999941, 35.420047924000073],
            [-80.79777723899997, 35.420092925000063],
            [-80.797843237999984, 35.420113925000067],
            [-80.797962238999958, 35.420227924000073],
            [-80.797531237999976, 35.420587924000074],
            [-80.797321238999984, 35.420775925000044],
            [-80.796883238999953, 35.421202925000046],
            [-80.794835238999951, 35.42324592500006],
            [-80.793969237999988, 35.424109925000039],
            [-80.793259237999962, 35.424818925000068],
            [-80.792769237999948, 35.425308926000071],
            [-80.79264823799997, 35.425418925000031],
            [-80.792575237999984, 35.425477925000052],
            [-80.792478237999944, 35.425546925000049],
            [-80.792338237999957, 35.425448926000058],
            [-80.792016236999984, 35.425297926000042],
            [-80.791172237999945, 35.424949926000068],
            [-80.791049236999982, 35.424890926000046],
            [-80.790073236999945, 35.424362926000072],
            [-80.78835223699997, 35.423423925000066],
            [-80.788223235999965, 35.423359925000057],
            [-80.788108235999971, 35.423313925000059],
            [-80.788003235999952, 35.423276925000039],
            [-80.787908236999954, 35.423250925000048],
            [-80.78780123599995, 35.423227926000038],
            [-80.787714236999989, 35.423213926000074],
            [-80.787501235999969, 35.423198925000065],
            [-80.787183235999976, 35.423205925000048],
            [-80.785620234999953, 35.423312925000062],
            [-80.78318623499996, 35.423445925000067],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801624241999946, 35.442241929000033],
            [-80.801465241999949, 35.442311929000027],
            [-80.801007240999979, 35.442466928000044],
            [-80.800832240999966, 35.442536929000028],
            [-80.800729240999942, 35.442586928000026],
            [-80.800630241999954, 35.442641928000057],
            [-80.800511240999981, 35.44272292900007],
            [-80.800395241999979, 35.442813929000067],
            [-80.800232240999946, 35.442957928000055],
            [-80.800047239999969, 35.44313992900004],
            [-80.799473240999987, 35.443746930000032],
            [-80.798915239999985, 35.444311929000037],
            [-80.798808239999971, 35.444432929000072],
            [-80.79874523999996, 35.444516929000031],
            [-80.79865523999996, 35.444657929000073],
            [-80.798494239999968, 35.444981929000051],
            [-80.797555239999951, 35.447156930000062],
            [-80.797365239999976, 35.447605930000066],
            [-80.796973239999943, 35.448574930000063],
            [-80.79682324099997, 35.448923930000035],
            [-80.796631240999943, 35.449316930000066],
            [-80.796582239999964, 35.449402931000066],
            [-80.796350239999981, 35.449764930000072],
            [-80.796015238999985, 35.450243931000045],
            [-80.795560239999986, 35.45000893100007],
            [-80.795320239999967, 35.449898931000064],
            [-80.795118239999965, 35.449813931000051],
            [-80.79485023899997, 35.449710930000037],
            [-80.79466623899998, 35.449647931000072],
            [-80.794291238999961, 35.449539931000061],
            [-80.794003239999938, 35.449471930000072],
            [-80.791847238999935, 35.448984931000041],
            [-80.79148923799994, 35.448895931000038],
            [-80.791312237999989, 35.448845930000061],
            [-80.790889238999966, 35.448709930000064],
            [-80.790459237999983, 35.448540931000025],
            [-80.790059238999959, 35.448367931000064],
            [-80.789453237999965, 35.448105930000054],
            [-80.789332237999986, 35.448049930000025],
            [-80.787848236999935, 35.447406931000046],
            [-80.786688236999964, 35.446910930000058],
            [-80.786244236999948, 35.446732931000042],
            [-80.786040236999952, 35.446663931000046],
            [-80.785661236999943, 35.446557931000029],
            [-80.785479235999958, 35.446516931000076],
            [-80.785191235999946, 35.446465930000045],
            [-80.785064236999972, 35.446447930000033],
            [-80.783836236999946, 35.446321931000057],
            [-80.783541235999962, 35.446283931000039],
            [-80.783331235999981, 35.446244930000034],
            [-80.782997235999972, 35.446164930000066],
            [-80.782748235999975, 35.446092930000077],
            [-80.782519234999938, 35.446015930000044],
            [-80.782274235999978, 35.445922930000052],
            [-80.781961235999972, 35.445772930000032],
            [-80.781708235999986, 35.445639931000073],
            [-80.781431234999957, 35.445462930000076],
            [-80.78118623599994, 35.445281930000078],
            [-80.780984234999949, 35.445131930000059],
            [-80.780575234999958, 35.444815930000061],
            [-80.780383234999988, 35.444673930000079],
            [-80.780058234999956, 35.444457930000056],
            [-80.77989123499998, 35.444364930000063],
            [-80.779549234999934, 35.444196930000032],
            [-80.779312234999963, 35.444099931000039],
            [-80.778974234999964, 35.443977930000074],
            [-80.778884234999964, 35.443945929000051],
            [-80.778637233999973, 35.443863930000077],
            [-80.77721223399999, 35.443388930000026],
            [-80.776666232999958, 35.443206929000041],
            [-80.775731232999988, 35.442894929000033],
            [-80.775266233999957, 35.442738929000029],
            [-80.770722231999969, 35.441224930000033],
            [-80.770186231999958, 35.441055930000061],
            [-80.769798231999971, 35.44095193000004],
            [-80.769598231999964, 35.440907929000048],
            [-80.769294230999947, 35.440850929000078],
            [-80.76893523199999, 35.440797930000031],
            [-80.768610230999968, 35.440765930000055],
            [-80.768360230999974, 35.440746929000056],
            [-80.768020231999969, 35.440741930000058],
            [-80.767766231999985, 35.440741929000069],
            [-80.767373230999965, 35.440765930000055],
            [-80.765654229999939, 35.440911929000038],
            [-80.764912229999936, 35.440974930000039],
            [-80.764915230999975, 35.440980929000034],
            [-80.765434229999983, 35.442026930000054],
            [-80.766182230999959, 35.443530930000065],
            [-80.767076230999976, 35.445330930000068],
            [-80.767080230999966, 35.445337930000051],
            [-80.767084230999956, 35.445346931000074],
            [-80.76709723099998, 35.445373930000073],
            [-80.767441230999964, 35.446054930000059],
            [-80.770616232999942, 35.452459932000068],
            [-80.770767232999958, 35.45276393100005],
            [-80.771021232999942, 35.453290932000073],
            [-80.771253232999982, 35.453771932000052],
            [-80.771632232999934, 35.454558932000054],
            [-80.772765232999973, 35.456999933000077],
            [-80.77286023299996, 35.457344932000069],
            [-80.773052233999977, 35.457265932000041],
            [-80.773244233999947, 35.457225933000075],
            [-80.773662232999982, 35.457177933000025],
            [-80.774234232999959, 35.45704093300003],
            [-80.774754232999953, 35.456932933000076],
            [-80.775382233999949, 35.456937932000073],
            [-80.775680234999982, 35.457004933000064],
            [-80.776277234999952, 35.457195933000037],
            [-80.776876234999975, 35.457486933000041],
            [-80.777633234999939, 35.45783193300008],
            [-80.778479234999963, 35.458275933000039],
            [-80.778969235999966, 35.458368933000031],
            [-80.779324235999979, 35.458634932000052],
            [-80.779376234999972, 35.458704933000035],
            [-80.77964723499997, 35.459071933000075],
            [-80.779760235999959, 35.459258933000058],
            [-80.779810235999946, 35.459340933000078],
            [-80.780026235999969, 35.459667933000048],
            [-80.780080235999947, 35.459749933000069],
            [-80.780227235999973, 35.460104933000025],
            [-80.78065623599997, 35.460582933000069],
            [-80.780960235999942, 35.460906934000036],
            [-80.781332236999958, 35.461200934000033],
            [-80.781845235999981, 35.461563934000026],
            [-80.782499235999978, 35.461939934000043],
            [-80.783205236999947, 35.462370934000035],
            [-80.783396236999977, 35.462515933000077],
            [-80.783825235999984, 35.462789933000067],
            [-80.784108236999941, 35.462985934000073],
            [-80.784355236999943, 35.463123934000066],
            [-80.784533237999938, 35.463306934000059],
            [-80.785219237999968, 35.463567934000025],
            [-80.785664237999981, 35.463713934000054],
            [-80.785851236999974, 35.463772934000076],
            [-80.786019237999938, 35.463852934000045],
            [-80.786137237999981, 35.463912934000064],
            [-80.78662623799994, 35.464145934000044],
            [-80.787282237999989, 35.464635934000057],
            [-80.787776237999935, 35.464899934000073],
            [-80.78804723799999, 35.46530993500005],
            [-80.788331238999945, 35.465575934000071],
            [-80.788582237999947, 35.465871934000063],
            [-80.78906423899997, 35.466420935000031],
            [-80.789458237999952, 35.466899934000025],
            [-80.789571239999987, 35.467008935000024],
            [-80.789654238999958, 35.467081934000078],
            [-80.78983723999994, 35.467478935000031],
            [-80.790246238999941, 35.467828935000057],
            [-80.790379238999947, 35.467904934000046],
            [-80.790464239999949, 35.467951934000041],
            [-80.79044523999994, 35.468013935000045],
            [-80.79045623899998, 35.468046935000075],
            [-80.79049723899999, 35.468126935000043],
            [-80.790584239999987, 35.468382934000033],
            [-80.790692239999942, 35.468756935000044],
            [-80.790785238999945, 35.469026934000055],
            [-80.790879239999981, 35.469275935000041],
            [-80.79090623899998, 35.469375934000027],
            [-80.790907239999967, 35.469426935000058],
            [-80.790926238999987, 35.469509935000076],
            [-80.791010239999935, 35.46960593600005],
            [-80.791025239999954, 35.469659935000038],
            [-80.791025239999954, 35.469712935000075],
            [-80.791032239999936, 35.469780935000074],
            [-80.791074239999944, 35.469935935000024],
            [-80.791110239999966, 35.470002935000025],
            [-80.791145238999945, 35.470049935000077],
            [-80.791206239999951, 35.470170935000056],
            [-80.791232238999953, 35.470248935000029],
            [-80.791363239999953, 35.470438935000061],
            [-80.791488239999978, 35.470595935000063],
            [-80.791513238999983, 35.470643935000055],
            [-80.791533239999978, 35.470701935000079],
            [-80.791579239999976, 35.470769935000078],
            [-80.791731238999944, 35.470963935000043],
            [-80.791777239999988, 35.471001935000061],
            [-80.79182123999999, 35.471049936000043],
            [-80.791852239999969, 35.471103935000031],
            [-80.791871239999978, 35.471194935000028],
            [-80.791900239999961, 35.471255935000045],
            [-80.79196823999996, 35.471364935000054],
            [-80.791970239999955, 35.471382936000055],
            [-80.791996239999946, 35.471582935000072],
            [-80.792015238999966, 35.471688935000032],
            [-80.792055239999968, 35.471765935000064],
            [-80.792086239999946, 35.471850936000067],
            [-80.792117239999982, 35.471917935000079],
            [-80.79215623999994, 35.471987936000062],
            [-80.792186239999978, 35.472087935000047],
            [-80.792195239999955, 35.472199936000038],
            [-80.792239239999958, 35.472375936000049],
            [-80.792283239999961, 35.472509936000051],
            [-80.792299240999967, 35.472577935000061],
            [-80.792316239999934, 35.472625936000043],
            [-80.792342240999972, 35.472680936000074],
            [-80.792364238999937, 35.47275793600005],
            [-80.792401239999947, 35.472890935000066],
            [-80.792421239999953, 35.473098936000042],
            [-80.79246724099994, 35.473241936000079],
            [-80.792484239999965, 35.473274936000053],
            [-80.792498240999976, 35.473336935000077],
            [-80.792500239999981, 35.473398935000034],
            [-80.79251524099999, 35.473479936000047],
            [-80.79258023999995, 35.473647936000077],
            [-80.792616238999983, 35.473718936000068],
            [-80.792659239999978, 35.473780936000026],
            [-80.792766240999981, 35.473968935000073],
            [-80.792818239999974, 35.474041935000059],
            [-80.792978239999968, 35.47420293600004],
            [-80.793015240999978, 35.474271936000036],
            [-80.793062240999973, 35.474332935000064],
            [-80.793158240999958, 35.474415936000071],
            [-80.79322123999998, 35.474491936000049],
            [-80.793245239999976, 35.474513935000061],
            [-80.793486239999936, 35.474879935000047],
            [-80.793646240999976, 35.475014936000036],
            [-80.793733240999984, 35.475107937000075],
            [-80.794012240999962, 35.475389936000056],
            [-80.794194241999946, 35.475589936000063],
            [-80.794299240999976, 35.475712937000026],
            [-80.794686240999965, 35.476105936000067],
            [-80.794859240999983, 35.476257936000025],
            [-80.794930240999975, 35.476363936000041],
            [-80.795092240999963, 35.47653293500008],
            [-80.79516124099996, 35.476621936000072],
            [-80.795234240999946, 35.476743936000048],
            [-80.795298240999955, 35.476822937000065],
            [-80.795355240999982, 35.476855937000039],
            [-80.795392240999945, 35.476884936000033],
            [-80.795448240999974, 35.476999937000073],
            [-80.795541240999967, 35.477116937000062],
            [-80.795632240999964, 35.477182936000077],
            [-80.795696241999963, 35.477258936000055],
            [-80.795766240999967, 35.477374936000047],
            [-80.79583224199996, 35.477464937000036],
            [-80.795932241999935, 35.477561937000075],
            [-80.796111241999938, 35.47775593700004],
            [-80.796160240999939, 35.477836937000063],
            [-80.796459241999969, 35.478222936000066],
            [-80.796553241999959, 35.478358936000063],
            [-80.796632241999987, 35.478440937000073],
            [-80.796683241999972, 35.478518937000047],
            [-80.796714240999961, 35.478576937000071],
            [-80.796782241999949, 35.478663936000032],
            [-80.796824241999957, 35.478726937000033],
            [-80.796964241999945, 35.47886993700007],
            [-80.797080241999936, 35.479023937000079],
            [-80.797227241999963, 35.479183938000062],
            [-80.797321241999953, 35.479311937000034],
            [-80.797432241999957, 35.479430937000075],
            [-80.797519242999954, 35.479504937000058],
            [-80.797570241999949, 35.47957593600006],
            [-80.797609241999965, 35.479660936000073],
            [-80.79764824099999, 35.47971493700004],
            [-80.797898241999974, 35.480023936000066],
            [-80.797935241999937, 35.480082937000077],
            [-80.798018241999955, 35.480188937000037],
            [-80.798109241999953, 35.480291937000061],
            [-80.798157241999945, 35.480338936000066],
            [-80.79820024199995, 35.480396936000034],
            [-80.798222241999952, 35.48045393700005],
            [-80.798265241999957, 35.480523937000044],
            [-80.798330241999963, 35.48059293600005],
            [-80.798359242999936, 35.480611936000059],
            [-80.798420242999953, 35.480700938000041],
            [-80.798634242999981, 35.480927937000047],
            [-80.798720242999934, 35.48104993700008],
            [-80.798839242999975, 35.481235936000076],
            [-80.798896241999955, 35.481302937000066],
            [-80.798954242999969, 35.481356937000044],
            [-80.799038242999984, 35.481459937000068],
            [-80.799108242999978, 35.481554937000055],
            [-80.799203242999965, 35.48165493700003],
            [-80.799307241999941, 35.481791937000025],
            [-80.799400243999969, 35.481928937000077],
            [-80.799647242999981, 35.482215937000035],
            [-80.800059242999964, 35.482729938000034],
            [-80.800322241999936, 35.48307993800006],
            [-80.800453243999982, 35.483223937000048],
            [-80.800558242999955, 35.483354938000048],
            [-80.800619242999971, 35.483422938000047],
            [-80.800685243999965, 35.483511938000049],
            [-80.800748242999987, 35.483571938000068],
            [-80.800921242999948, 35.483702938000079],
            [-80.800937243999954, 35.483765938000033],
            [-80.80099824399997, 35.483902938000028],
            [-80.801097242999958, 35.484046937000073],
            [-80.80125824299995, 35.484247937000077],
            [-80.801419243999987, 35.484492938000074],
            [-80.80152424399995, 35.48466793700004],
            [-80.801605243999973, 35.484781937000037],
            [-80.801619243999937, 35.48480293700004],
            [-80.801801242999943, 35.485097938000024],
            [-80.802000243999942, 35.485377938000056],
            [-80.80208324299997, 35.485519939000028],
            [-80.802175242999965, 35.485651938000046],
            [-80.802245243999948, 35.485760938000055],
            [-80.802328242999977, 35.485922938000044],
            [-80.802475243999936, 35.486162938000064],
            [-80.802581242999963, 35.486322938000058],
            [-80.80267224399995, 35.486473938000074],
            [-80.802764243999945, 35.486587938000071],
            [-80.802864243999977, 35.486697938000077],
            [-80.802959243999965, 35.486825938000038],
            [-80.80300224399997, 35.486950938000064],
            [-80.803029243999958, 35.487044937000064],
            [-80.803123243999948, 35.487153939000052],
            [-80.803205242999979, 35.487231938000036],
            [-80.803297244999953, 35.487331938000068],
            [-80.803353244999983, 35.48742893900004],
            [-80.803422243999989, 35.487562938000053],
            [-80.803488244999983, 35.487714938000067],
            [-80.803525243999957, 35.487883938000039],
            [-80.803579243999934, 35.48808593800004],
            [-80.803623243999937, 35.48822793800008],
            [-80.803668243999937, 35.488295938000078],
            [-80.803754244999936, 35.488453938000077],
            [-80.80382024499994, 35.488599939000039],
            [-80.80395824499999, 35.488946938000026],
            [-80.80397824399995, 35.489006939000035],
            [-80.804004244999987, 35.489047939000045],
            [-80.804086243999961, 35.489227939000045],
            [-80.804119244999981, 35.489285939000069],
            [-80.804153243999963, 35.489385938000055],
            [-80.804185243999939, 35.48952293900004],
            [-80.804247244999942, 35.489597939000078],
            [-80.80427324499999, 35.489639938000039],
            [-80.80428524499996, 35.489689938000026],
            [-80.804287244999955, 35.489746939000042],
            [-80.804312244999949, 35.489806939000061],
            [-80.804347244999974, 35.489850939000064],
            [-80.804381243999956, 35.489885939000033],
            [-80.80442824499994, 35.49001393900005],
            [-80.804433245999974, 35.490080939000052],
            [-80.804457243999934, 35.490149938000059],
            [-80.80450624499997, 35.490214939000055],
            [-80.804633243999945, 35.490516938000042],
            [-80.80470924399998, 35.490743939000026],
            [-80.804767244999937, 35.490876939000032],
            [-80.804812244999937, 35.490954939000062],
            [-80.804833244999941, 35.491073939000046],
            [-80.804911244999971, 35.49137293900003],
            [-80.804985244999955, 35.491606939000064],
            [-80.805353244999935, 35.491740939000067],
            [-80.805619244999946, 35.491815939000048],
            [-80.805830245999971, 35.491881938000063],
            [-80.805975244999956, 35.491910939000036],
            [-80.806091244999948, 35.49196093900008],
            [-80.806247244999952, 35.492015939000055],
            [-80.806491244999961, 35.492082939000056],
            [-80.806727244999934, 35.492132940000033],
            [-80.806978245999971, 35.492214938000075],
            [-80.807275245999961, 35.492267940000033],
            [-80.807374245999938, 35.492275939000024],
            [-80.807497245999969, 35.492278939000073],
            [-80.80761324599996, 35.492309939000052],
            [-80.807704245999957, 35.492338939000035],
            [-80.807901245999972, 35.492333939000048],
            [-80.807926244999976, 35.492334939000045],
            [-80.806158245999939, 35.49425093900004],
            [-80.80230024399998, 35.498463941000068],
            [-80.803292244999966, 35.499434941000061],
            [-80.804014244999962, 35.500142941000036],
            [-80.80491924599994, 35.500891941000077],
            [-80.805428244999973, 35.501505941000062],
            [-80.808554246999961, 35.505273942000031],
            [-80.80847524699999, 35.505324942000072],
            [-80.808023246999937, 35.505694942000048],
            [-80.807775246999938, 35.505882942000028],
            [-80.807627246999971, 35.505965942000046],
            [-80.80745924699994, 35.506035943000029],
            [-80.807225245999973, 35.50610994200008],
            [-80.806913245999965, 35.506168942000045],
            [-80.806557246999944, 35.50620394200007],
            [-80.806181245999937, 35.506216942000037],
            [-80.805926245999956, 35.506214942000042],
            [-80.805668244999936, 35.506195942000033],
            [-80.805352245999984, 35.506147942000041],
            [-80.804925244999936, 35.506065942000077],
            [-80.804550245999962, 35.506024942000067],
            [-80.804311245999941, 35.50601994200008],
            [-80.804157245999988, 35.506028942000057],
            [-80.804010245999962, 35.50605394300004],
            [-80.803817244999948, 35.506088943000066],
            [-80.802079244999959, 35.506557943000075],
            [-80.801829244999965, 35.506630943000062],
            [-80.801677244999951, 35.506690942000034],
            [-80.801566244999947, 35.50676294200008],
            [-80.80140724499995, 35.506926943000053],
            [-80.801276243999951, 35.507104943000058],
            [-80.801111244999959, 35.50709394200004],
            [-80.79665724299997, 35.50709394200004],
            [-80.796482242999957, 35.507094942000037],
            [-80.796621242999947, 35.507165943000075],
            [-80.796753242999955, 35.507216942000071],
            [-80.797104242999978, 35.507364943000027],
            [-80.797357242999965, 35.507527943000071],
            [-80.797564243999943, 35.507721943000035],
            [-80.797841243999983, 35.508089943000073],
            [-80.798148243999947, 35.508479943000054],
            [-80.798549243999958, 35.509105943000066],
            [-80.798702243999969, 35.509421943000063],
            [-80.798794243999964, 35.509730943000079],
            [-80.798850243999937, 35.510104943000044],
            [-80.798847243999944, 35.510478943000066],
            [-80.798805243999936, 35.511800943000026],
            [-80.798752243999957, 35.513496944000053],
            [-80.798738244999981, 35.514567944000078],
            [-80.798761243999934, 35.514918944000044],
            [-80.798763243999986, 35.514949945000069],
            [-80.798973243999967, 35.515851944000076],
            [-80.799312244999953, 35.517124945000035],
            [-80.799357244999953, 35.51738494500006],
            [-80.799354244999961, 35.517533944000036],
            [-80.799353243999974, 35.517647944000032],
            [-80.799301244999981, 35.51790694500005],
            [-80.799245244999952, 35.518088945000045],
            [-80.798966244999974, 35.51901394500004]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 20,
        "SolutionID": "PI-9",
        "Shape_Length": 1.0110457878193357,
        "Shape_Area": 0.026569017362350882
      }
    },
    {
      "type": "Feature",
      "id": 21,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 21,
        "SolutionID": "PI-9",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 22,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.923646260999988, 35.131746858000042],
            [-80.92366526099994, 35.131771858000036],
            [-80.924902260999943, 35.133981858000027],
            [-80.931495262999988, 35.131622858000071],
            [-80.932444262999979, 35.131290857000067],
            [-80.932472263999955, 35.131293858000049],
            [-80.932512262999978, 35.131288857000072],
            [-80.932590263999941, 35.131271858000048],
            [-80.932688262999989, 35.131239857000025],
            [-80.933197263999944, 35.131054857000038],
            [-80.933495262999941, 35.130947857000024],
            [-80.934107262999987, 35.130735857000047],
            [-80.934245263999969, 35.130684857000062],
            [-80.934758263999981, 35.13049785700008],
            [-80.934945263999964, 35.13042985800007],
            [-80.935633264999979, 35.130168857000058],
            [-80.935803263999958, 35.130105856000057],
            [-80.936413263999953, 35.129883857000038],
            [-80.937749264999979, 35.129412857000034],
            [-80.937826264999956, 35.12937585700007],
            [-80.937934264999967, 35.129332857000065],
            [-80.938047264999966, 35.129265858000053],
            [-80.93824026499999, 35.129136857000049],
            [-80.938437264999948, 35.128976857000055],
            [-80.938458264999952, 35.128986857000029],
            [-80.938760265999974, 35.129092856000057],
            [-80.939293264999947, 35.129201857000055],
            [-80.939647265999952, 35.129274857000041],
            [-80.939998265999975, 35.12933485700006],
            [-80.94060826599997, 35.129416857000024],
            [-80.941744265999944, 35.12962785700006],
            [-80.941930265999986, 35.129193857000075],
            [-80.942172266999989, 35.128588857000068],
            [-80.942343266999956, 35.128195857000037],
            [-80.942471264999938, 35.127913856000077],
            [-80.942620265999949, 35.127514856000062],
            [-80.942767266999965, 35.127151857000058],
            [-80.943077265999989, 35.126445856000032],
            [-80.943170265999981, 35.126245856000025],
            [-80.943266264999977, 35.12605585600005],
            [-80.943364264999957, 35.125877856000045],
            [-80.943596266999975, 35.125491856000053],
            [-80.943850266999959, 35.125120856000024],
            [-80.944025266999972, 35.124891856000033],
            [-80.944220266999935, 35.124654856000063],
            [-80.944494265999936, 35.12434885600004],
            [-80.944825266999942, 35.124016856000026],
            [-80.944976266999959, 35.123873855000056],
            [-80.945041265999976, 35.123817856000073],
            [-80.945163266999941, 35.123711855000067],
            [-80.945478266999942, 35.123457855000026],
            [-80.945644266999977, 35.123328855000068],
            [-80.945986266999967, 35.123088855000049],
            [-80.946281266999961, 35.122896856000068],
            [-80.946764267999981, 35.122618855000042],
            [-80.946926266999981, 35.122529855000039],
            [-80.94732126699995, 35.122328855000035],
            [-80.947857266999961, 35.122068855000066],
            [-80.94850626799996, 35.121766855000033],
            [-80.948673266999947, 35.121685854000077],
            [-80.949250267999957, 35.121406855000032],
            [-80.94962026799999, 35.121225855000034],
            [-80.950544266999941, 35.120783854000024],
            [-80.950823267999965, 35.120646855000075],
            [-80.95128626799999, 35.120405855000058],
            [-80.952752267999983, 35.119688855000049],
            [-80.95326326899999, 35.119445854000048],
            [-80.953227268999967, 35.119372855000051],
            [-80.953120267999964, 35.119225854000035],
            [-80.95290726799999, 35.118662854000036],
            [-80.952889267999979, 35.118594854000037],
            [-80.952872267999965, 35.118475855000042],
            [-80.952867267999977, 35.118288853000024],
            [-80.952861267999936, 35.118098854000039],
            [-80.95286626799998, 35.117837855000062],
            [-80.952861267999936, 35.117612854000072],
            [-80.952878268999939, 35.117066854000029],
            [-80.952869267999972, 35.116877854000052],
            [-80.952854268999943, 35.116700854000044],
            [-80.952873268999952, 35.116501854000035],
            [-80.952856268999938, 35.116276854000034],
            [-80.952831268999944, 35.116134853000062],
            [-80.952786268999944, 35.115989853000031],
            [-80.952776268999969, 35.115943853000033],
            [-80.952759268999955, 35.115873853000039],
            [-80.952756268999963, 35.115821854000046],
            [-80.952763268999945, 35.115739854000026],
            [-80.952749267999934, 35.115575854000042],
            [-80.95267526899994, 35.115384854000069],
            [-80.952648267999962, 35.115200853000033],
            [-80.952558268999951, 35.114809853000054],
            [-80.952473268999938, 35.114357854000048],
            [-80.952465268999958, 35.114211853000029],
            [-80.952441267999973, 35.11413985300004],
            [-80.952398267999968, 35.113974853000059],
            [-80.952298267999936, 35.113422854000078],
            [-80.952215267999975, 35.113079854000034],
            [-80.952093267999942, 35.112430853000035],
            [-80.952031267999985, 35.112161853000032],
            [-80.951995267999962, 35.111963853000077],
            [-80.951957267999944, 35.111791853000057],
            [-80.951946266999983, 35.11168585300004],
            [-80.951951267999959, 35.111549853000042],
            [-80.951973267999961, 35.111365852000063],
            [-80.952051267999934, 35.110973853000075],
            [-80.952073267999936, 35.11090185200004],
            [-80.952097267999989, 35.110824852000064],
            [-80.95211926799999, 35.110715853000045],
            [-80.952163266999946, 35.110322852000024],
            [-80.952185267999937, 35.109984853000071],
            [-80.952185267999937, 35.109842853000032],
            [-80.952211267999985, 35.109572852000042],
            [-80.952211267999985, 35.109527853000031],
            [-80.952182267999945, 35.109444852000024],
            [-80.951550267999949, 35.108461852000062],
            [-80.951509267999938, 35.108378852000044],
            [-80.951491267999984, 35.108314852000035],
            [-80.951479266999968, 35.108225852000032],
            [-80.951475267999967, 35.108107851000057],
            [-80.951494267999976, 35.107957852000027],
            [-80.951665266999953, 35.107175852000069],
            [-80.951732266999954, 35.106962852000038],
            [-80.95177526699996, 35.106840851000072],
            [-80.951837267999963, 35.106716852000034],
            [-80.952035267999975, 35.106383852000079],
            [-80.952215267999975, 35.106174852000038],
            [-80.95229926799999, 35.10607585200006],
            [-80.952327267999976, 35.106054852000057],
            [-80.952363267999942, 35.106041851000043],
            [-80.952477267999939, 35.106020852000029],
            [-80.952552266999987, 35.106010851000065],
            [-80.953068267999981, 35.105859852000037],
            [-80.953219267999941, 35.105800852000073],
            [-80.95331626799998, 35.105757851000078],
            [-80.953456267999968, 35.105659851000041],
            [-80.953516268999977, 35.105595851000032],
            [-80.953563267999982, 35.105507852000073],
            [-80.953621267999949, 35.105271851000055],
            [-80.953680266999982, 35.105100851000032],
            [-80.953761267999937, 35.104918851000036],
            [-80.953082267999946, 35.103786850000063],
            [-80.952868266999985, 35.10345785100003],
            [-80.952714267999966, 35.103239851000069],
            [-80.952525268999977, 35.10299585100006],
            [-80.952234267999984, 35.10266185100005],
            [-80.952018267999961, 35.102427851000073],
            [-80.951870266999947, 35.102280850000056],
            [-80.951715267999987, 35.102135851000071],
            [-80.950802266999972, 35.101351850000071],
            [-80.950462266999978, 35.101134850000051],
            [-80.949863266999955, 35.100751851000041],
            [-80.948636265999937, 35.100182851000056],
            [-80.946956265999972, 35.099516851000033],
            [-80.944754264999972, 35.098637850000046],
            [-80.945889264999948, 35.097113850000028],
            [-80.94737026599995, 35.094969849000051],
            [-80.947842265999952, 35.09429084900006],
            [-80.948050265999939, 35.09402884900004],
            [-80.948354266999957, 35.093730849000053],
            [-80.949216265999951, 35.093108849000032],
            [-80.950998266999989, 35.091882848000068],
            [-80.952573266999934, 35.090821848000076],
            [-80.952831266999965, 35.090652848000047],
            [-80.95301726699995, 35.090529848000074],
            [-80.953537267999934, 35.090113848000044],
            [-80.954129266999985, 35.089464847000045],
            [-80.954215266999938, 35.089354848000028],
            [-80.954139267999949, 35.089294848000065],
            [-80.953979267999955, 35.089239848000034],
            [-80.953778266999961, 35.089162848000058],
            [-80.953618267999957, 35.089086848000079],
            [-80.953457266999976, 35.088954848000071],
            [-80.95315626699994, 35.088728847000027],
            [-80.952835267999944, 35.088453847000039],
            [-80.952615265999952, 35.088322848000075],
            [-80.952434266999944, 35.088278848000073],
            [-80.952146265999943, 35.088261847000069],
            [-80.952013266999984, 35.088212848000069],
            [-80.951799266999956, 35.088080848000061],
            [-80.951611266999976, 35.088052848000075],
            [-80.951437265999971, 35.088074848000076],
            [-80.951237266999954, 35.088173848000054],
            [-80.950956265999935, 35.088360847000047],
            [-80.950689265999983, 35.088580848000049],
            [-80.950495265999962, 35.08875684800006],
            [-80.950180265999961, 35.089086848000079],
            [-80.95002726599995, 35.089185848000056],
            [-80.949639265999963, 35.08940584700008],
            [-80.949532265999949, 35.089498849000051],
            [-80.94937826599994, 35.089729848000047],
            [-80.949237265999955, 35.089762848000078],
            [-80.949090266999974, 35.089823848000037],
            [-80.948903264999956, 35.089834848000066],
            [-80.948776265999982, 35.089784848000079],
            [-80.948689264999985, 35.089707848000046],
            [-80.948535265999965, 35.089515848000076],
            [-80.948341265999943, 35.089411848000054],
            [-80.948268265999957, 35.089284848000034],
            [-80.948274265999942, 35.089158848000068],
            [-80.948415264999937, 35.089009848000046],
            [-80.948542265999947, 35.088844848000065],
            [-80.948555264999982, 35.088663849000056],
            [-80.948535265999965, 35.08832884800006],
            [-80.948441265999975, 35.087998848000041],
            [-80.948354265999967, 35.087734848000025],
            [-80.948214265999979, 35.08745484800005],
            [-80.94808726499997, 35.087272848000055],
            [-80.948020265999958, 35.087201848000063],
            [-80.947719265999979, 35.086651848000031],
            [-80.947585264999987, 35.086541847000035],
            [-80.947398265999936, 35.086453848000076],
            [-80.947197265999989, 35.086321848000068],
            [-80.947117264999974, 35.086228847000029],
            [-80.947037264999949, 35.086030847000075],
            [-80.947037264999949, 35.085887848000027],
            [-80.947057264999955, 35.085766847000059],
            [-80.947137264999981, 35.08553084700003],
            [-80.947264264999944, 35.085216847000027],
            [-80.94734426499997, 35.085084847000076],
            [-80.947505264999961, 35.084997848000057],
            [-80.947652265999977, 35.084975848000056],
            [-80.947873264999942, 35.084975847000067],
            [-80.948133264999967, 35.085013847000027],
            [-80.948976265999988, 35.085007847000043],
            [-80.949264265999943, 35.085040847000073],
            [-80.949651265999989, 35.085117847000049],
            [-80.949765265999986, 35.085145848000025],
            [-80.950093265999953, 35.085106847000077],
            [-80.950307265999982, 35.084991847000026],
            [-80.950434266999935, 35.084980847000054],
            [-80.950574266999979, 35.084991847000026],
            [-80.950688265999986, 35.085029847000044],
            [-80.95078826699995, 35.085002847000055],
            [-80.950755265999987, 35.084815847000073],
            [-80.950695265999968, 35.084716847000038],
            [-80.950614265999945, 35.084358847000033],
            [-80.950394265999989, 35.08407384700007],
            [-80.950360265999961, 35.083974847000036],
            [-80.950273265999954, 35.08380384700007],
            [-80.950180265999961, 35.083699847000048],
            [-80.950046265999958, 35.083639847000029],
            [-80.94971826699998, 35.083606847000055],
            [-80.949604264999948, 35.083600846000024],
            [-80.949504265999963, 35.083556846000079],
            [-80.949310265999941, 35.08334284700004],
            [-80.948608265999951, 35.082798847000049],
            [-80.948474264999959, 35.082660847000056],
            [-80.948274264999952, 35.082248846000027],
            [-80.948233265999988, 35.082089847000077],
            [-80.94826726499997, 35.081781846000069],
            [-80.948292265999953, 35.081699846000049],
            [-80.948314265999954, 35.08162784600006],
            [-80.948360265999952, 35.081544846000043],
            [-80.94854126499996, 35.081429846000049],
            [-80.948822265999979, 35.08115484700005],
            [-80.948949265999943, 35.08108384600007],
            [-80.949049265999975, 35.081033847000072],
            [-80.949123265999958, 35.08097884700004],
            [-80.949156264999942, 35.080830846000026],
            [-80.949143265999965, 35.080720846000077],
            [-80.948935265999978, 35.080346846000054],
            [-80.948955264999938, 35.080209846000059],
            [-80.948902264999958, 35.080038846000036],
            [-80.948761264999973, 35.079851845000064],
            [-80.948741264999967, 35.079741846000047],
            [-80.94878126499998, 35.07945584600003],
            [-80.948835265999946, 35.079295846000036],
            [-80.948842264999939, 35.079274846000033],
            [-80.949015265999947, 35.079115846000036],
            [-80.949236264999968, 35.079032846000075],
            [-80.94992526499999, 35.078994845000068],
            [-80.950165265999942, 35.07894484600007],
            [-80.950600265999981, 35.078867846000037],
            [-80.950754264999944, 35.07853284600003],
            [-80.950894265999978, 35.078202845000078],
            [-80.950968265999961, 35.077955846000066],
            [-80.950981265999985, 35.077647845000058],
            [-80.950968265999961, 35.077471845000048],
            [-80.95093426599999, 35.07737284600006],
            [-80.950734265999984, 35.077245846000039],
            [-80.950466265999978, 35.077119845000027],
            [-80.950279265999939, 35.076965845000075],
            [-80.950078265999935, 35.076861846000043],
            [-80.949951265999971, 35.07682884500008],
            [-80.949343264999982, 35.076833845000067],
            [-80.949115264999989, 35.076800846000026],
            [-80.948881265999944, 35.076811845000066],
            [-80.948768264999956, 35.076800846000026],
            [-80.948641264999935, 35.076734845000033],
            [-80.948534264999978, 35.076635845000055],
            [-80.948413265999989, 35.076482845000044],
            [-80.948233264999942, 35.076025845000061],
            [-80.948095264999949, 35.075640845000066],
            [-80.948052264999944, 35.075520845000028],
            [-80.948019264999971, 35.075415845000066],
            [-80.94800526399996, 35.075217845000054],
            [-80.948059264999983, 35.075091844000042],
            [-80.94822626499996, 35.074953845000039],
            [-80.948305264999988, 35.074934846000076],
            [-80.948460264999937, 35.074898844000074],
            [-80.948968264999962, 35.074953845000039],
            [-80.94914926499996, 35.074953845000039],
            [-80.949476264999987, 35.074766845000056],
            [-80.949583265999934, 35.074635845000046],
            [-80.949637264999978, 35.074552845000028],
            [-80.949717265999936, 35.074404845000061],
            [-80.949770265999973, 35.074321845000043],
            [-80.949750265999967, 35.074030845000038],
            [-80.949726264999981, 35.073956845000055],
            [-80.949596264999968, 35.073552844000062],
            [-80.949583265999934, 35.07340384400004],
            [-80.94961026499999, 35.073288845000036],
            [-80.949703264999982, 35.073007844000074],
            [-80.949697265999987, 35.072793845000035],
            [-80.949695264999946, 35.072767845000044],
            [-80.949690264999958, 35.072700844000053],
            [-80.94966326499997, 35.072535845000061],
            [-80.94966326499997, 35.072364844000049],
            [-80.94979026499999, 35.07210084500008],
            [-80.949797265999962, 35.071908844000063],
            [-80.949696264999943, 35.07168884500004],
            [-80.949543265999978, 35.07149684400008],
            [-80.94938226499994, 35.071309844000041],
            [-80.949322264999978, 35.071166844000061],
            [-80.949262264999959, 35.070798844000024],
            [-80.949195263999968, 35.070717844000058],
            [-80.94872926499994, 35.071338845000071],
            [-80.945504264999954, 35.075655845000028],
            [-80.94433526499995, 35.077262846000053],
            [-80.94401226399998, 35.077705845000025],
            [-80.943672264999975, 35.078112846000067],
            [-80.943050263999964, 35.078883846000053],
            [-80.941912263999939, 35.080391847000044],
            [-80.940955262999978, 35.081690847000061],
            [-80.940404261999959, 35.08246884600004],
            [-80.93980226299999, 35.083316848000038],
            [-80.93833626299994, 35.085304847000032],
            [-80.937341261999961, 35.086628848000032],
            [-80.937166261999948, 35.086857848000079],
            [-80.937153261999981, 35.086873848000039],
            [-80.936255262999964, 35.088041848000046],
            [-80.935204261999957, 35.089504849000036],
            [-80.93420426199998, 35.090785849000042],
            [-80.933658261999938, 35.091616849000047],
            [-80.933445260999974, 35.091952850000041],
            [-80.933302260999938, 35.091748849000055],
            [-80.933233261999987, 35.091639849000046],
            [-80.932838260999972, 35.091063849000079],
            [-80.932719260999988, 35.090878849000035],
            [-80.932360260999985, 35.09028384800007],
            [-80.93232926099995, 35.090199849000044],
            [-80.932316260999983, 35.090135848000045],
            [-80.932152259999953, 35.089563849000058],
            [-80.932127260999948, 35.089361849000056],
            [-80.932161261999966, 35.088843849000057],
            [-80.932697260999987, 35.086810848000027],
            [-80.932805260999942, 35.086439849000044],
            [-80.933080261999976, 35.085498848000043],
            [-80.933466260999978, 35.083949847000042],
            [-80.933318260999954, 35.083984847000067],
            [-80.932874259999949, 35.084088847000032],
            [-80.932211259999974, 35.084674847000031],
            [-80.932080260999953, 35.084860848000062],
            [-80.931821259999936, 35.085228848000042],
            [-80.931395259999988, 35.08581484900003],
            [-80.930919260999985, 35.086453849000065],
            [-80.930510260999938, 35.086866848000057],
            [-80.929866260999972, 35.087260848000028],
            [-80.929317259999948, 35.087468848000071],
            [-80.928486259999943, 35.087658848000046],
            [-80.927365259999988, 35.087901849000048],
            [-80.926378258999989, 35.088109848000045],
            [-80.925839258999986, 35.088227849000077],
            [-80.924282257999948, 35.088566850000063],
            [-80.923502257999985, 35.088732849000053],
            [-80.922782257999984, 35.088890849000052],
            [-80.922616257999948, 35.088941849000037],
            [-80.922527257999946, 35.088968849000025],
            [-80.922217257999989, 35.089006849000043],
            [-80.921139257999982, 35.089240849000078],
            [-80.920511257999976, 35.089371849000031],
            [-80.92013925699996, 35.089418849000026],
            [-80.91957625699996, 35.089481849000038],
            [-80.919535255999961, 35.08948484900003],
            [-80.918368256999941, 35.08958184800008],
            [-80.91995125699998, 35.091241850000074],
            [-80.919920257999934, 35.091386850000049],
            [-80.919684256999972, 35.092130850000046],
            [-80.918915256999981, 35.094588851000026],
            [-80.918572257999983, 35.095665850000046],
            [-80.918521256999952, 35.095826850000037],
            [-80.918443256999979, 35.095794851000051],
            [-80.918206256999952, 35.095664851000038],
            [-80.918096256999945, 35.095624851000025],
            [-80.918010256999935, 35.095607850000079],
            [-80.917831256999989, 35.095552850000047],
            [-80.917594256999962, 35.095456851000051],
            [-80.91717125699995, 35.09529985000006],
            [-80.917103256999951, 35.095260851000035],
            [-80.917059256999949, 35.095251851000057],
            [-80.916943255999968, 35.09520985000006],
            [-80.916748255999948, 35.095176851000076],
            [-80.916610255999956, 35.095187850000059],
            [-80.916390256999989, 35.095189851000043],
            [-80.916261255999984, 35.095181850000074],
            [-80.915993256999968, 35.095173850000037],
            [-80.915840256999957, 35.095190851000041],
            [-80.915703256999961, 35.095235851000041],
            [-80.915671255999939, 35.095253851000052],
            [-80.915562256999976, 35.09529185100007],
            [-80.915473256999974, 35.095311851000076],
            [-80.915365255999973, 35.095326850000049],
            [-80.915162255999974, 35.095343851000052],
            [-80.914899255999956, 35.095383851000065],
            [-80.914706255999988, 35.095405851000066],
            [-80.914516256999946, 35.095415850000052],
            [-80.914321255999937, 35.095432850000066],
            [-80.914190255999983, 35.095474850000073],
            [-80.913944255999979, 35.09549185000003],
            [-80.913821254999959, 35.095511851000026],
            [-80.913620255999945, 35.095534850000035],
            [-80.913575254999955, 35.095547851000049],
            [-80.913446256999976, 35.095607850000079],
            [-80.91338925499997, 35.095616851000045],
            [-80.913327255999945, 35.095634851000057],
            [-80.913241254999946, 35.095641851000039],
            [-80.913185254999973, 35.095636851000052],
            [-80.913109254999938, 35.095602851000024],
            [-80.912877255999945, 35.095458850000057],
            [-80.912619255999971, 35.095361851000064],
            [-80.912585255999943, 35.095341851000057],
            [-80.912423254999965, 35.095185850000064],
            [-80.912360254999953, 35.095131851000076],
            [-80.91228625499997, 35.095091851000063],
            [-80.912199254999962, 35.095053851000046],
            [-80.911954254999955, 35.094946850000042],
            [-80.911918254999989, 35.094938851000052],
            [-80.911867254999947, 35.094939851000049],
            [-80.911755254999946, 35.094964850000054],
            [-80.911681254999962, 35.094966850000048],
            [-80.911630255999967, 35.094943851000039],
            [-80.911599254999942, 35.09491685100005],
            [-80.911521254999968, 35.094861851000076],
            [-80.911485254999945, 35.094822850000071],
            [-80.911335255999973, 35.094561850000048],
            [-80.911283254999944, 35.09445485100008],
            [-80.911171254999942, 35.09429485000004],
            [-80.911101254999949, 35.094229850000033],
            [-80.911071254999968, 35.094209850000027],
            [-80.911042254999984, 35.094201851000037],
            [-80.91098425499996, 35.094198851000044],
            [-80.910905254999989, 35.094215851000058],
            [-80.910878253999954, 35.094209850000027],
            [-80.910840254999982, 35.094182851000028],
            [-80.910686254999973, 35.094035850000068],
            [-80.910610254999938, 35.093970850000062],
            [-80.910486253999977, 35.093905851000045],
            [-80.910361254999941, 35.093803850000029],
            [-80.910267254999951, 35.093748851000043],
            [-80.910203253999953, 35.093657850000056],
            [-80.91013925499999, 35.093610850000061],
            [-80.91005325499998, 35.093571850000046],
            [-80.909973254999954, 35.093540850000068],
            [-80.90991825499998, 35.093534850000026],
            [-80.909853254999973, 35.093549850000045],
            [-80.909802253999942, 35.093550851000032],
            [-80.909735254999987, 35.093533851000075],
            [-80.909568254999954, 35.093507850000037],
            [-80.909494253999981, 35.093477851000046],
            [-80.909090253999977, 35.093281851000029],
            [-80.909024253999974, 35.093233850000047],
            [-80.908990253999946, 35.093194850000032],
            [-80.908894253999961, 35.093031851000035],
            [-80.90877025399999, 35.092859850000025],
            [-80.908745253999939, 35.092837850000024],
            [-80.908727254999974, 35.092798849000076],
            [-80.90871825399995, 35.092727850000074],
            [-80.908707254999968, 35.092684850000069],
            [-80.908666253999968, 35.092580850000047],
            [-80.908648254999946, 35.092554850000056],
            [-80.908605253999951, 35.092520850000028],
            [-80.908553253999969, 35.092501850000076],
            [-80.908180254999934, 35.092338850000033],
            [-80.907986253999979, 35.092189850000068],
            [-80.907883253999955, 35.091987850000066],
            [-80.90784025399995, 35.091920851000054],
            [-80.90780325299994, 35.091807849000077],
            [-80.90779925399994, 35.091701850000049],
            [-80.90776525299998, 35.091646850000075],
            [-80.907651253999973, 35.091541850000056],
            [-80.907599252999944, 35.091465850000077],
            [-80.907584253999971, 35.091396849000034],
            [-80.907594253999946, 35.091354850000073],
            [-80.907545252999967, 35.091306850000024],
            [-80.907514253999977, 35.091288850000069],
            [-80.907478253999955, 35.09127385000005],
            [-80.907432253999957, 35.091240850000077],
            [-80.907360253999968, 35.091140850000045],
            [-80.907324252999956, 35.091109850000066],
            [-80.907274252999969, 35.091088850000062],
            [-80.907207252999967, 35.091079850000028],
            [-80.907080252999947, 35.091072850000046],
            [-80.907035253999936, 35.091065850000064],
            [-80.906986253999946, 35.091037850000077],
            [-80.906944252999949, 35.091001851000044],
            [-80.906914253999958, 35.090930850000063],
            [-80.906899253999939, 35.090850850000038],
            [-80.906903252999939, 35.090732849000062],
            [-80.906904253999983, 35.090714849000051],
            [-80.906894252999962, 35.090677849000031],
            [-80.903763252999966, 35.091065849000074],
            [-80.903746251999962, 35.091118850000043],
            [-80.903730252999935, 35.091153850000069],
            [-80.903658251999957, 35.091263850000075],
            [-80.903353251999988, 35.091686851000077],
            [-80.903161251999961, 35.091972850000047],
            [-80.903097252999942, 35.092091850000031],
            [-80.90300825199995, 35.092332850000048],
            [-80.90294725199999, 35.092472850000036],
            [-80.902867252999954, 35.092600850000053],
            [-80.902779251999959, 35.092693851000035],
            [-80.902682252999966, 35.092770850000079],
            [-80.902597251999964, 35.092817851000063],
            [-80.902671252999937, 35.092923850000034],
            [-80.902873252999939, 35.093213850000041],
            [-80.902975251999976, 35.093365850000055],
            [-80.90372425299995, 35.094484851000061],
            [-80.903940252999973, 35.094792851000079],
            [-80.904303251999977, 35.095311851000076],
            [-80.904685252999968, 35.095884851000051],
            [-80.905331253999975, 35.096884851000027],
            [-80.905618252999943, 35.097337852000067],
            [-80.905655252999964, 35.097402851000027],
            [-80.905682253999942, 35.097450851000076],
            [-80.905718253999964, 35.097525851000057],
            [-80.905812252999965, 35.097756851000042],
            [-80.90594525299997, 35.098168851000025],
            [-80.906283253999959, 35.099271852000072],
            [-80.906498253999985, 35.099916853000025],
            [-80.90681125399999, 35.100737851000076],
            [-80.907028253999954, 35.101366852000069],
            [-80.907043254999962, 35.101410853000061],
            [-80.907717253999976, 35.103434853000067],
            [-80.907858254999951, 35.103889853000055],
            [-80.90793025399995, 35.104174852000028],
            [-80.907961254999975, 35.104330853000079],
            [-80.907984254999974, 35.104480852000052],
            [-80.908015254999953, 35.104793852000057],
            [-80.908050253999988, 35.105922853000038],
            [-80.908058254999958, 35.106189853000046],
            [-80.908067253999945, 35.106464853000034],
            [-80.908103254999958, 35.107278853000025],
            [-80.908133254999939, 35.107729853000023],
            [-80.90814725499996, 35.107983854000054],
            [-80.908168254999964, 35.10838285300008],
            [-80.908183254999983, 35.108478854000055],
            [-80.908189254999968, 35.108520853000073],
            [-80.908217254999954, 35.108616853000058],
            [-80.908226254999988, 35.108633854000061],
            [-80.908362254999986, 35.108689854000033],
            [-80.908706255999959, 35.108770854000056],
            [-80.909149254999988, 35.108875853000029],
            [-80.909779255999979, 35.109024853000051],
            [-80.910788255999989, 35.109262854000065],
            [-80.911763255999972, 35.109512854000059],
            [-80.911856255999965, 35.109205853000049],
            [-80.912494255999945, 35.109222853000063],
            [-80.912874256999942, 35.109174854000059],
            [-80.913349256999936, 35.109094854000034],
            [-80.913825256999985, 35.109059854000066],
            [-80.915754256999946, 35.109010853000029],
            [-80.915883256999962, 35.109013854000068],
            [-80.91600425699994, 35.109603854000056],
            [-80.916035257999965, 35.109822854000072],
            [-80.916049256999941, 35.110027853000076],
            [-80.916029256999934, 35.110250854000071],
            [-80.915982257999985, 35.110437854000054],
            [-80.915907256999958, 35.110632854000073],
            [-80.915704256999959, 35.110973854000065],
            [-80.915516256999979, 35.111223855000048],
            [-80.915482256999951, 35.111256854000032],
            [-80.915296256999966, 35.111454854000044],
            [-80.915076256999953, 35.111700854000048],
            [-80.914190255999983, 35.112602854000045],
            [-80.913746256999957, 35.113066854000067],
            [-80.91359825699999, 35.113220855000066],
            [-80.913271256999963, 35.113542855000048],
            [-80.913176255999986, 35.113625855000066],
            [-80.913098256999945, 35.113687854000034],
            [-80.91291225599997, 35.113824855000075],
            [-80.912586256999987, 35.114026855000077],
            [-80.911715255999979, 35.114568855000073],
            [-80.911091255999963, 35.114961855000047],
            [-80.910311254999954, 35.115445854000029],
            [-80.909690254999987, 35.115818855000043],
            [-80.909524255999941, 35.115924856000049],
            [-80.909151254999983, 35.116153856000039],
            [-80.908866254999964, 35.116317855000034],
            [-80.908463254999958, 35.116529855000067],
            [-80.907035254999982, 35.117220855000028],
            [-80.906703253999979, 35.117386856000053],
            [-80.905617254999981, 35.117915856000025],
            [-80.90499125499997, 35.118237856000064],
            [-80.904716253999936, 35.118429856000034],
            [-80.905421254999965, 35.119085857000073],
            [-80.90582825499996, 35.119435857000042],
            [-80.90633825499998, 35.119848856000033],
            [-80.90698325599999, 35.120324856000025],
            [-80.907655255999941, 35.120774856000025],
            [-80.90843225499998, 35.121234856000058],
            [-80.909014255999978, 35.121550856000056],
            [-80.909575255999982, 35.121834857000067],
            [-80.910624255999949, 35.122311856000067],
            [-80.910743255999989, 35.122359857000049],
            [-80.912190256999963, 35.122946857000045],
            [-80.912652256999934, 35.123140857000067],
            [-80.912946256999987, 35.123229857000069],
            [-80.913651257999959, 35.123467856000048],
            [-80.914443256999959, 35.123752857000056],
            [-80.914511256999958, 35.123776857000053],
            [-80.91474625799998, 35.123870856000053],
            [-80.91567325699998, 35.124277857000038],
            [-80.916311257999951, 35.124588857000049],
            [-80.916484258999958, 35.124680857000044],
            [-80.916784256999961, 35.124850857000069],
            [-80.916985257999954, 35.124970857000051],
            [-80.917416258999936, 35.125220857000045],
            [-80.917520258999957, 35.125269857000035],
            [-80.917800258999989, 35.12544385700005],
            [-80.918147258999966, 35.125668857000051],
            [-80.918252257999939, 35.125735857000052],
            [-80.918503258999976, 35.125896857000043],
            [-80.918890258999966, 35.126162857000054],
            [-80.919495258999973, 35.126628857000071],
            [-80.920117258999937, 35.127157858000032],
            [-80.920521258999941, 35.12752185800008],
            [-80.920825258999969, 35.12781185700004],
            [-80.921142259999954, 35.12812685800003],
            [-80.921465258999945, 35.128477858000053],
            [-80.921740259999979, 35.128813858000058],
            [-80.92224325899997, 35.129441858000064],
            [-80.922525259999986, 35.129843858000072],
            [-80.922866260999967, 35.130374858000039],
            [-80.923291259999985, 35.131101857000033],
            [-80.923646260999988, 35.131746858000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 22,
        "SolutionID": "PI-9",
        "Shape_Length": 0.25664240026987367,
        "Shape_Area": 0.0017154679767870586
      }
    },
    {
      "type": "Feature",
      "id": 23,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.848390249999966, 35.346195907000038],
              [-80.848159249999981, 35.347309907000067],
              [-80.847949249999942, 35.348165907000066],
              [-80.847929249999936, 35.348248907000027],
              [-80.847877250999943, 35.348437907000061],
              [-80.847779249999974, 35.348772908000058],
              [-80.847656249999943, 35.349198908000062],
              [-80.847441249999974, 35.349936908000075],
              [-80.847037249999971, 35.351197908000074],
              [-80.846737248999943, 35.352039907000062],
              [-80.846420249999937, 35.352860908000025],
              [-80.846202249999976, 35.353388908000056],
              [-80.845978249999973, 35.353908908000051],
              [-80.845800248999979, 35.354302908000079],
              [-80.845062248999966, 35.35594190900008],
              [-80.84378124899996, 35.358747909000044],
              [-80.843527249999966, 35.359337910000079],
              [-80.843446248999953, 35.359552909000058],
              [-80.843366249999974, 35.359770910000066],
              [-80.843182249999984, 35.360289909000073],
              [-80.843027248999988, 35.360826910000071],
              [-80.842946248999965, 35.361143909000077],
              [-80.842781248999984, 35.361898911000026],
              [-80.842690249999976, 35.362449910000066],
              [-80.842658247999964, 35.362696910000068],
              [-80.842633249999949, 35.362896910000075],
              [-80.842595249999988, 35.363328910000064],
              [-80.842582248999975, 35.363592910000079],
              [-80.843247248999944, 35.363508910000064],
              [-80.845008249999978, 35.363285911000048],
              [-80.849229249999951, 35.362753910000038],
              [-80.850188250999963, 35.362592910000046],
              [-80.850817251999956, 35.362449909000077],
              [-80.851128251999967, 35.362367911000035],
              [-80.851740251999956, 35.362183910000056],
              [-80.851837251999939, 35.362149910000028],
              [-80.85204225199999, 35.362081910000029],
              [-80.852340251999976, 35.361972909000031],
              [-80.852634251999973, 35.361856910000029],
              [-80.85292425199998, 35.361734910000052],
              [-80.853158251999957, 35.361623910000048],
              [-80.853314251999961, 35.361550909000073],
              [-80.853458251999939, 35.361483910000061],
              [-80.853598250999937, 35.361418909000065],
              [-80.853786252999953, 35.361317909000036],
              [-80.854139252999971, 35.361129910000045],
              [-80.854660251999974, 35.360815910000042],
              [-80.855160251999962, 35.360479909000048],
              [-80.855636252999943, 35.360121910000032],
              [-80.855865252999934, 35.35993490900006],
              [-80.856088252999939, 35.359742909000033],
              [-80.856304252999962, 35.359545909000076],
              [-80.856513252999946, 35.359344910000061],
              [-80.856764252999938, 35.359087909000039],
              [-80.857258252999941, 35.358572909000031],
              [-80.859667252999941, 35.356071908000047],
              [-80.860474254999986, 35.355230909000056],
              [-80.861398253999937, 35.354271908000044],
              [-80.863931254999954, 35.351639907000049],
              [-80.864436254999987, 35.35112190600006],
              [-80.864608254999951, 35.350945907000039],
              [-80.864791253999954, 35.350768907000031],
              [-80.865117254999973, 35.350454906000039],
              [-80.865509254999949, 35.350102907000064],
              [-80.865969254999982, 35.349717906000024],
              [-80.866274254999951, 35.349474907000058],
              [-80.866586254999959, 35.349236906000044],
              [-80.867227255999978, 35.348777906000066],
              [-80.86755625699999, 35.348555906000058],
              [-80.867856254999936, 35.348365907000073],
              [-80.868223255999965, 35.348134906000041],
              [-80.868391255999938, 35.348037905000069],
              [-80.868740255999967, 35.347840906000044],
              [-80.869094256999972, 35.347650906000069],
              [-80.869816256999968, 35.347288906000074],
              [-80.870183256999951, 35.347116906000053],
              [-80.870932257999982, 35.346791905000032],
              [-80.871312256999943, 35.346639906000064],
              [-80.87169625699994, 35.346493906000035],
              [-80.872083257999975, 35.346353906000047],
              [-80.872474257999954, 35.346220906000042],
              [-80.872869256999934, 35.346094905000029],
              [-80.872944256999972, 35.346071905000031],
              [-80.873266257999944, 35.345974905000048],
              [-80.874069256999974, 35.34575690500003],
              [-80.876076257999955, 35.34524990500006],
              [-80.876019258999975, 35.345217905000027],
              [-80.875943258999939, 35.345167906000029],
              [-80.875864258999968, 35.345129906000068],
              [-80.875750257999982, 35.345025905000057],
              [-80.875687257999971, 35.344985905000044],
              [-80.875622257999964, 35.344963906000032],
              [-80.875587257999939, 35.344944905000034],
              [-80.875529257999972, 35.344871906000037],
              [-80.875524257999984, 35.344767905000026],
              [-80.875464256999976, 35.34471990600008],
              [-80.875423256999966, 35.344658906000063],
              [-80.875308257999961, 35.344568905000074],
              [-80.875266257999954, 35.344523906000063],
              [-80.875219257999959, 35.344473905000029],
              [-80.875209257999984, 35.34442090500005],
              [-80.87505325799998, 35.344253905000073],
              [-80.875013256999978, 35.344196905000047],
              [-80.874999257999946, 35.34417690500004],
              [-80.874941256999989, 35.343930905000036],
              [-80.874909257999946, 35.343850905000068],
              [-80.87487325799998, 35.343790905000048],
              [-80.874815256999966, 35.343730905000029],
              [-80.874748257999954, 35.343679905000045],
              [-80.874684256999956, 35.343617905000031],
              [-80.874663256999952, 35.343544905000044],
              [-80.874619257999939, 35.34348290500003],
              [-80.874568256999964, 35.343425905000061],
              [-80.874513257999979, 35.343380904000071],
              [-80.874460257999942, 35.343310905000067],
              [-80.874426257999971, 35.343250905000048],
              [-80.874362257999962, 35.343200904000071],
              [-80.874302257999943, 35.343145905000029],
              [-80.874254256999961, 35.343042905000061],
              [-80.874214256999949, 35.34298390500004],
              [-80.874105257999986, 35.342870905000041],
              [-80.874044257999969, 35.342833905000077],
              [-80.87400325699997, 35.342813904000025],
              [-80.873904256999936, 35.342722905000073],
              [-80.873830257999941, 35.342671905000032],
              [-80.873765256999945, 35.342647905000035],
              [-80.873615256999983, 35.342565905000072],
              [-80.873546256999987, 35.342515905000027],
              [-80.873480256999983, 35.342439904000059],
              [-80.873373256999969, 35.342395905000046],
              [-80.873302256999978, 35.342352905000041],
              [-80.87312125599999, 35.342258905000051],
              [-80.873042255999962, 35.342206904000079],
              [-80.873025256999938, 35.342136905000075],
              [-80.872946255999977, 35.342116905000069],
              [-80.872855256999969, 35.342115905000071],
              [-80.872780256999988, 35.342124906000038],
              [-80.872658256999955, 35.342158905000076],
              [-80.872555255999941, 35.342139905000067],
              [-80.872447256999976, 35.342135905000077],
              [-80.872362256999963, 35.342124905000048],
              [-80.872265256999981, 35.342121905000056],
              [-80.872159256999964, 35.342112905000079],
              [-80.872060255999941, 35.342082905000041],
              [-80.871981256999959, 35.342043905000025],
              [-80.871842256999969, 35.342006905000062],
              [-80.871672256999943, 35.341942905000053],
              [-80.871579256999951, 35.341940905000058],
              [-80.871466256999952, 35.341946905000043],
              [-80.871404256999938, 35.341969905000042],
              [-80.871306255999968, 35.341994904000046],
              [-80.871229255999936, 35.341969906000031],
              [-80.87114025599999, 35.341918904000067],
              [-80.871089256999937, 35.34190690500003],
              [-80.871035255999971, 35.341932904000032],
              [-80.871018255999957, 35.341954904000033],
              [-80.871008256999971, 35.341978905000076],
              [-80.871007255999984, 35.342089904000034],
              [-80.871004255999935, 35.342128904000049],
              [-80.87099025699996, 35.342157905000079],
              [-80.870901255999968, 35.342230904000075],
              [-80.870752256999936, 35.342310905000033],
              [-80.870561255999974, 35.342391905000056],
              [-80.870284255999934, 35.342478904000075],
              [-80.870222255999977, 35.342508905000045],
              [-80.870180255999969, 35.342529905000049],
              [-80.870096254999964, 35.342599905000043],
              [-80.870048256999951, 35.342670906000023],
              [-80.870020255999975, 35.342766905000076],
              [-80.870004255999959, 35.342870905000041],
              [-80.869980255999963, 35.342920905000028],
              [-80.869934255999965, 35.342972905000067],
              [-80.869884255999978, 35.342997905000061],
              [-80.869795255999975, 35.343013905000078],
              [-80.869633255999986, 35.343019905000062],
              [-80.869533255999954, 35.343055905000028],
              [-80.869312255999944, 35.34316390500004],
              [-80.869262255999956, 35.343177906000051],
              [-80.869195255999955, 35.343206905000045],
              [-80.868991254999969, 35.343324905000031],
              [-80.868879255999957, 35.343345906000025],
              [-80.868802255999981, 35.343367905000036],
              [-80.868693254999982, 35.343412905000037],
              [-80.868482254999947, 35.343525905000035],
              [-80.868427255999961, 35.343563905000053],
              [-80.868366255999945, 35.343592905000037],
              [-80.868285255999979, 35.343613905000041],
              [-80.86814725499994, 35.343634905000044],
              [-80.868058254999937, 35.343668905000072],
              [-80.868035254999938, 35.343723906000037],
              [-80.868016254999986, 35.343745905000048],
              [-80.867997255999967, 35.343777906000071],
              [-80.867976255999963, 35.343826905000071],
              [-80.867947255999979, 35.343867905000025],
              [-80.867900254999938, 35.343897906000052],
              [-80.867791255999975, 35.343930905000036],
              [-80.867679254999985, 35.343977905000031],
              [-80.867460255999958, 35.344049905000077],
              [-80.86738425599998, 35.34413590500003],
              [-80.867295255999977, 35.344206906000068],
              [-80.867208255999969, 35.344249905000027],
              [-80.867145255999958, 35.34427090500003],
              [-80.867091255999981, 35.344303905000061],
              [-80.867064255999935, 35.344332905000044],
              [-80.867023255999982, 35.344349906000048],
              [-80.866867255999978, 35.344359906000079],
              [-80.866831254999965, 35.344366906000062],
              [-80.866766254999959, 35.344402905000038],
              [-80.86673925599996, 35.344405906000077],
              [-80.866661255999986, 35.344374905000052],
              [-80.866613254999947, 35.344377905000044],
              [-80.866544255999941, 35.344393905000061],
              [-80.866425255999957, 35.344379906000029],
              [-80.866328254999985, 35.344399906000035],
              [-80.86619925499997, 35.344437906000053],
              [-80.866057254999987, 35.344455906000064],
              [-80.865926254999977, 35.344465906000039],
              [-80.865780254999947, 35.344486905000053],
              [-80.865643254999952, 35.344541905000028],
              [-80.86550525499996, 35.344567905000076],
              [-80.865444253999954, 35.344585906000077],
              [-80.86536225499998, 35.344593906000057],
              [-80.865189253999972, 35.344615905000069],
              [-80.865083254999945, 35.344632905000026],
              [-80.865010254999959, 35.344654906000073],
              [-80.864953254999989, 35.344679906000067],
              [-80.864922254999954, 35.344697906000079],
              [-80.864792254999941, 35.344716906000031],
              [-80.864661254999987, 35.34472790600006],
              [-80.864562253999964, 35.344750906000058],
              [-80.864455254999939, 35.344807906000028],
              [-80.864371253999934, 35.344836906000069],
              [-80.86433825499995, 35.34489990600008],
              [-80.864261254999974, 35.344933906000051],
              [-80.864232253999944, 35.34494890600007],
              [-80.864131253999972, 35.345000905000063],
              [-80.864091253999959, 35.345022906000054],
              [-80.863818254999956, 35.345113906000051],
              [-80.863573254999949, 35.34523490600003],
              [-80.86350125499996, 35.345254906000036],
              [-80.863424254999984, 35.345259906000024],
              [-80.863376253999945, 35.345255906000034],
              [-80.863278254999955, 35.345296906000044],
              [-80.863202253999987, 35.345317906000048],
              [-80.863101253999957, 35.345361906000051],
              [-80.863076252999974, 35.345378906000064],
              [-80.863052254999957, 35.345393906000027],
              [-80.862985254999955, 35.345419906000075],
              [-80.862891254999965, 35.345445906000066],
              [-80.86273225399998, 35.345493906000058],
              [-80.862652253999954, 35.345520906000047],
              [-80.862589252999953, 35.345536906000063],
              [-80.862462254999969, 35.34556490600005],
              [-80.862298253999938, 35.345589906000043],
              [-80.862180253999952, 35.34562190500003],
              [-80.862106253999968, 35.34564690600007],
              [-80.861981253999943, 35.345694906000062],
              [-80.861898253999982, 35.34573290600008],
              [-80.86174525399997, 35.34579390600004],
              [-80.861717253999984, 35.345805906000066],
              [-80.861567253999965, 35.345853906000059],
              [-80.86144225299995, 35.345948906000046],
              [-80.861316253999973, 35.345996906000039],
              [-80.861182253999971, 35.346016906000045],
              [-80.861102253999945, 35.346025907000069],
              [-80.861003253999968, 35.346000906000029],
              [-80.860979253999972, 35.345979906000025],
              [-80.860884253999984, 35.345913906000078],
              [-80.860767253999938, 35.345847906000074],
              [-80.86069925399994, 35.34579390600004],
              [-80.860557252999968, 35.345697906000055],
              [-80.860373252999977, 35.345572906000029],
              [-80.860295253999936, 35.345520905000058],
              [-80.860162252999942, 35.345421907000059],
              [-80.860094252999943, 35.345374906000075],
              [-80.860045253999942, 35.345337906000054],
              [-80.860010252999984, 35.345313906000058],
              [-80.859939252999936, 35.345258906000026],
              [-80.859772253999949, 35.34513690600005],
              [-80.859689252999942, 35.345086906000063],
              [-80.85963725299996, 35.345058906000077],
              [-80.859461252999949, 35.344967906000079],
              [-80.859083251999948, 35.34478090600004],
              [-80.859014253999987, 35.344733906000044],
              [-80.858969252999941, 35.344701905000079],
              [-80.858871252999961, 35.344640906000052],
              [-80.858827252999959, 35.34462290700003],
              [-80.858675252999944, 35.344549905000065],
              [-80.858551251999984, 35.344497906000072],
              [-80.858490252999957, 35.34444290600004],
              [-80.858208252999987, 35.344294906000073],
              [-80.858118252999986, 35.344230906000064],
              [-80.858035251999979, 35.344189906000054],
              [-80.857950251999966, 35.344166906000055],
              [-80.857902252999963, 35.344158905000029],
              [-80.857850252999981, 35.344147905000057],
              [-80.85779125199997, 35.34412790600004],
              [-80.857742252999969, 35.344134906000079],
              [-80.857694251999988, 35.344120905000068],
              [-80.857617251999955, 35.344056907000038],
              [-80.857526252999946, 35.344004905000077],
              [-80.857328252999935, 35.343986906000055],
              [-80.857149251999942, 35.343946906000042],
              [-80.857051251999962, 35.343928906000031],
              [-80.856992251999941, 35.343908906000024],
              [-80.856923252999934, 35.343891906000067],
              [-80.856863251999982, 35.34387990700003],
              [-80.856812252999987, 35.343873905000066],
              [-80.856751251999981, 35.343856906000042],
              [-80.856699251999942, 35.343834906000041],
              [-80.856516252999938, 35.343791905000046],
              [-80.856365251999989, 35.343717906000052],
              [-80.856302251999978, 35.343714906000059],
              [-80.856277251999984, 35.343700906000038],
              [-80.856190252999966, 35.343674906000047],
              [-80.856041251999955, 35.34364690600006],
              [-80.855951251999954, 35.343624906000059],
              [-80.855898251999974, 35.343621906000067],
              [-80.855843250999953, 35.343610905000048],
              [-80.855794252999942, 35.343606906000048],
              [-80.855721252999956, 35.343597905000024],
              [-80.855625250999935, 35.343589906000034],
              [-80.85551725199997, 35.343578906000062],
              [-80.85547625099997, 35.343575906000069],
              [-80.855434251999952, 35.343579906000059],
              [-80.855320251999956, 35.343578905000072],
              [-80.855299251999952, 35.34356490600004],
              [-80.855281251999941, 35.343545906000031],
              [-80.855241251999985, 35.343535905000067],
              [-80.85519425199999, 35.343530906000069],
              [-80.855165251999949, 35.343520906000037],
              [-80.855027250999967, 35.343499906000034],
              [-80.854992251999988, 35.343487906000064],
              [-80.854908250999983, 35.343480905000035],
              [-80.854783251999947, 35.343448906000049],
              [-80.854733250999971, 35.343446906000054],
              [-80.854693251999947, 35.343434906000027],
              [-80.85464725199995, 35.343432906000032],
              [-80.85459825099997, 35.343439905000025],
              [-80.854546251999977, 35.343436906000079],
              [-80.854499251999982, 35.343427906000045],
              [-80.85440325199994, 35.343390906000025],
              [-80.854296251999983, 35.343358906000049],
              [-80.854239251999957, 35.343334907000042],
              [-80.854192250999972, 35.343324906000078],
              [-80.85414825099997, 35.34332390600008],
              [-80.854066250999949, 35.343299905000038],
              [-80.854022250999947, 35.343279906000078],
              [-80.853975250999952, 35.343265906000056],
              [-80.853920251999966, 35.343258905000027],
              [-80.85385525199996, 35.343265905000067],
              [-80.853795250999951, 35.343260905000079],
              [-80.853543250999962, 35.34318890600008],
              [-80.853445250999982, 35.343150905000073],
              [-80.853301250999948, 35.343121906000079],
              [-80.853205250999963, 35.343084906000058],
              [-80.853153250999981, 35.343052905000036],
              [-80.85306325099998, 35.343017906000057],
              [-80.853003251999951, 35.342970905000072],
              [-80.852959250999959, 35.342944906000071],
              [-80.852909250999971, 35.342923906000067],
              [-80.852849250999952, 35.342902906000063],
              [-80.852769250999984, 35.342867906000038],
              [-80.852701250999985, 35.342840906000049],
              [-80.852651250999941, 35.342818906000048],
              [-80.852623250999955, 35.342801905000044],
              [-80.852573250999967, 35.342761906000078],
              [-80.85247825099998, 35.342707905000054],
              [-80.85239224999998, 35.342643906000035],
              [-80.852332249999961, 35.342605905000028],
              [-80.852176249999957, 35.342532906000031],
              [-80.852128250999954, 35.342493906000072],
              [-80.852068250999935, 35.34246190500005],
              [-80.851877250999962, 35.342332906000024],
              [-80.851678249999964, 35.342167905000053],
              [-80.851593249999951, 35.342143906000047],
              [-80.851541249999968, 35.342121906000045],
              [-80.851495249999971, 35.342107905000034],
              [-80.85144625099997, 35.342049905000067],
              [-80.851392250999936, 35.342006905000062],
              [-80.851354250999975, 35.341989906000038],
              [-80.851237250999986, 35.341932905000078],
              [-80.851070250999953, 35.341832906000036],
              [-80.850754249999966, 35.341687906000061],
              [-80.850596249999967, 35.341647905000059],
              [-80.850426249999941, 35.341607905000046],
              [-80.85039625099995, 35.341590906000079],
              [-80.850304249999965, 35.34157990600005],
              [-80.850251249999985, 35.341564906000031],
              [-80.850136249999935, 35.341554906000056],
              [-80.850061249999953, 35.341536906000044],
              [-80.850022249999938, 35.341509905000066],
              [-80.849859249999952, 35.341476906000025],
              [-80.849737248999986, 35.341447905000052],
              [-80.849630249999962, 35.341426905000048],
              [-80.849529249999989, 35.341417905000071],
              [-80.849463249999985, 35.341393905000075],
              [-80.849323250999987, 35.341356906000044],
              [-80.848987249999936, 35.341150905000063],
              [-80.848983249999947, 35.341217905000065],
              [-80.848968249999984, 35.341562905000046],
              [-80.848878248999938, 35.342863906000048],
              [-80.84881224999998, 35.343577906000064],
              [-80.848761249999939, 35.344030906000057],
              [-80.848647249999942, 35.344846907000033],
              [-80.848617249999961, 35.345005907000029],
              [-80.848531249999951, 35.345423906000065],
              [-80.848390249999966, 35.346195907000038]
            ]
          ],
          [
            [
              [-80.831795245999956, 35.370615912000062],
              [-80.831913246999989, 35.37077391300005],
              [-80.832052245999989, 35.37097291200007],
              [-80.832158245999949, 35.371142913000028],
              [-80.832249245999947, 35.371304912000028],
              [-80.832343245999937, 35.371485912000026],
              [-80.83246524599997, 35.371762913000055],
              [-80.832565246999934, 35.372014913000044],
              [-80.832772245999934, 35.37260191300004],
              [-80.83287824699994, 35.372945913000024],
              [-80.83296824599995, 35.373258913000029],
              [-80.833045246999973, 35.373558913000068],
              [-80.833119246999956, 35.373883913000043],
              [-80.833191246999945, 35.374229912000033],
              [-80.833272246999968, 35.374671914000032],
              [-80.833336245999988, 35.37513391300007],
              [-80.833591246999958, 35.377562913000077],
              [-80.834132247999946, 35.382744916000036],
              [-80.83421724699997, 35.383565915000077],
              [-80.835030248999942, 35.383299914000077],
              [-80.835683247999953, 35.383086915000035],
              [-80.837083247999942, 35.382631915000047],
              [-80.84177724999995, 35.381115914000077],
              [-80.842581249999967, 35.380856915000038],
              [-80.84414124999995, 35.380333913000072],
              [-80.84308324999995, 35.376645913000061],
              [-80.844964250999965, 35.376458913000079],
              [-80.845338249999941, 35.376424913000051],
              [-80.847063251999941, 35.376251913000033],
              [-80.847508251999955, 35.37620791300003],
              [-80.853866253999968, 35.37560691300007],
              [-80.854165252999962, 35.375584913000068],
              [-80.854194252999946, 35.375382912000077],
              [-80.854450252999982, 35.37385091200008],
              [-80.854625252999938, 35.372838912000077],
              [-80.854687252999952, 35.372445912000046],
              [-80.85474725399996, 35.372173912000051],
              [-80.854812252999977, 35.371954912000035],
              [-80.854901253999969, 35.371711911000034],
              [-80.85500725299994, 35.371474912000053],
              [-80.855492252999966, 35.370564912000077],
              [-80.856462252999961, 35.368695911000032],
              [-80.856499253999971, 35.368275912000058],
              [-80.85647325299999, 35.367982912000059],
              [-80.856298252999977, 35.367475911000042],
              [-80.855906252999944, 35.366825911000035],
              [-80.855825252999978, 35.366837910000072],
              [-80.855620252999984, 35.366853911000078],
              [-80.85544925399995, 35.366856910000024],
              [-80.855395252999983, 35.366854911000075],
              [-80.855298252999944, 35.366851911000026],
              [-80.855123251999942, 35.36683891000007],
              [-80.854922252999984, 35.366816910000068],
              [-80.854644252999947, 35.36677891100004],
              [-80.853643252999973, 35.366628911000078],
              [-80.85269525199999, 35.366463911000039],
              [-80.852489251999941, 35.366435911000053],
              [-80.851776251999979, 35.366369911000049],
              [-80.850943251999979, 35.36630591100004],
              [-80.850530251999942, 35.366274910000072],
              [-80.849587250999946, 35.366192910000052],
              [-80.849233250999987, 35.366167911000048],
              [-80.849068250999949, 35.366160911000065],
              [-80.84891425099994, 35.366160911000065],
              [-80.848784250999984, 35.366167911000048],
              [-80.848532250999938, 35.366196911000031],
              [-80.847870250999961, 35.366319911000062],
              [-80.846682249999958, 35.366550911000047],
              [-80.846228249999967, 35.366632911000067],
              [-80.845420249999961, 35.36677891100004],
              [-80.845138248999945, 35.366835910000077],
              [-80.844874250999965, 35.366896911000026],
              [-80.844637249999948, 35.366968911000072],
              [-80.844483249999939, 35.367025911000042],
              [-80.844294249999962, 35.367112911000049],
              [-80.844057249999935, 35.367244911000057],
              [-80.843761249999943, 35.367414912000072],
              [-80.843698249999989, 35.367451912000035],
              [-80.843545248999988, 35.367541911000046],
              [-80.843060249999951, 35.367830911000055],
              [-80.84257624899999, 35.368119912000054],
              [-80.842105248999985, 35.368396911000048],
              [-80.841551248999963, 35.368723912000064],
              [-80.841224248999936, 35.368910911000057],
              [-80.840925248999952, 35.369062911000071],
              [-80.839159248999977, 35.369913912000072],
              [-80.838736248999965, 35.370107912000037],
              [-80.838531247999981, 35.370189912000058],
              [-80.83834924699994, 35.370249912000077],
              [-80.83818324799995, 35.370291912000027],
              [-80.838012247999984, 35.370325913000045],
              [-80.837812247999977, 35.370350912000049],
              [-80.837564247999978, 35.370370912000055],
              [-80.837265247999937, 35.370386912000072],
              [-80.83698224799997, 35.370393912000054],
              [-80.834968247999939, 35.370380913000076],
              [-80.833984246999989, 35.370381912000028],
              [-80.833860247999951, 35.370378912000035],
              [-80.833770245999972, 35.370377913000027],
              [-80.833201246999977, 35.370350912000049],
              [-80.832701246999989, 35.370312912000031],
              [-80.832261245999973, 35.370272912000075],
              [-80.831521245999966, 35.370195912000042],
              [-80.831443245999935, 35.370191912000053],
              [-80.831593245999954, 35.370365912000068],
              [-80.831795245999956, 35.370615912000062]
            ]
          ],
          [
            [
              [-80.836849254999947, 35.497647939000046],
              [-80.837491253999985, 35.49804793900006],
              [-80.837936254999988, 35.498325940000029],
              [-80.83814225499998, 35.498453939000058],
              [-80.837683255999934, 35.49966493900007],
              [-80.83750125499995, 35.500142940000046],
              [-80.83564325399999, 35.504432941000061],
              [-80.83532525399994, 35.505180940000059],
              [-80.833636253999941, 35.509156942000061],
              [-80.83350825499997, 35.509446942000068],
              [-80.833928253999943, 35.509477942000046],
              [-80.834491253999943, 35.509518942000057],
              [-80.839295255999957, 35.509867941000039],
              [-80.839820256999985, 35.509906941000054],
              [-80.840497255999935, 35.509956942000031],
              [-80.841372256999989, 35.51002094100005],
              [-80.845313257999976, 35.510307942000054],
              [-80.845850258999974, 35.510346942000069],
              [-80.852927260999934, 35.510861942000076],
              [-80.855593261999957, 35.511055941000052],
              [-80.855796261999956, 35.511070941000071],
              [-80.859166261999974, 35.511315941000078],
              [-80.860223261999977, 35.511392941000054],
              [-80.861301261999984, 35.511481941000056],
              [-80.861958263999952, 35.51153594200008],
              [-80.862105262999989, 35.51154794100006],
              [-80.865199263999955, 35.511773941000058],
              [-80.865529263999974, 35.511796941000057],
              [-80.865653263999945, 35.511805942000024],
              [-80.865885263999985, 35.511822941000048],
              [-80.865889263999975, 35.511753941000052],
              [-80.865991263999945, 35.51034694100008],
              [-80.866075264999949, 35.509069940000074],
              [-80.866135263999979, 35.508307940000066],
              [-80.866156262999937, 35.508097940000027],
              [-80.866217263999943, 35.507328940000036],
              [-80.86629226499997, 35.506379940000045],
              [-80.866350263999948, 35.50550794000003],
              [-80.866438263999953, 35.504329939000058],
              [-80.866471263999983, 35.503896939000072],
              [-80.866498263999972, 35.503666940000073],
              [-80.866527263999956, 35.503512939000075],
              [-80.866611263999971, 35.503072940000038],
              [-80.866662264999945, 35.502850939000041],
              [-80.866760263999936, 35.502533939000045],
              [-80.866980263999949, 35.50193893900007],
              [-80.867067264999946, 35.501737939000066],
              [-80.86732826299999, 35.501231938000046],
              [-80.867368262999946, 35.501143938000041],
              [-80.867466263999972, 35.500925939000069],
              [-80.867752264999979, 35.500355939000031],
              [-80.867860263999944, 35.500140939000062],
              [-80.867917263999971, 35.500027938000073],
              [-80.868013263999956, 35.499830938000059],
              [-80.868197263999946, 35.499465939000061],
              [-80.869199263999974, 35.49744393800006],
              [-80.869399263999981, 35.497048937000045],
              [-80.869423264999966, 35.497003938000034],
              [-80.870231264999973, 35.495378937000055],
              [-80.870567263999988, 35.49469093700003],
              [-80.870921264999936, 35.49398493700005],
              [-80.871335264999971, 35.493177937000041],
              [-80.871768264999957, 35.492276936000053],
              [-80.873348264999947, 35.489092935000031],
              [-80.873651264999978, 35.488500936000037],
              [-80.873688264999942, 35.488424936000058],
              [-80.873735265999983, 35.489705936000064],
              [-80.873947265999959, 35.489589937000062],
              [-80.874613264999937, 35.489211936000061],
              [-80.874893264999969, 35.489044936000028],
              [-80.875249264999979, 35.488799935000031],
              [-80.875899265999976, 35.48831593500006],
              [-80.87657726599997, 35.487826935000044],
              [-80.877469265999935, 35.487182935000078],
              [-80.877952266999955, 35.486827936000054],
              [-80.878275265999946, 35.48659393500003],
              [-80.878975265999941, 35.48609093500005],
              [-80.879307266999945, 35.48640993500004],
              [-80.879781266999942, 35.486857935000046],
              [-80.879895266999938, 35.486954935000028],
              [-80.880375266999977, 35.487403936000078],
              [-80.880540266999958, 35.487552935000053],
              [-80.880686266999987, 35.487625936000029],
              [-80.880819267999982, 35.487652935000028],
              [-80.880990267999948, 35.487654936000069],
              [-80.881712267999944, 35.487570935000065],
              [-80.882123267999987, 35.487518935000026],
              [-80.882296267999948, 35.487501935000068],
              [-80.882440267999982, 35.487480935000065],
              [-80.882519267999953, 35.487462935000053],
              [-80.882591267999942, 35.487434935000067],
              [-80.882687267999984, 35.48737793500004],
              [-80.88285826799995, 35.48724293500004],
              [-80.883046266999941, 35.487051935000068],
              [-80.883095267999977, 35.486983936000058],
              [-80.883146267999962, 35.48687893500005],
              [-80.883198267999944, 35.486755935000076],
              [-80.883230267999977, 35.48664193500008],
              [-80.883266267999943, 35.486406935000048],
              [-80.883265267999946, 35.486234935000027],
              [-80.883213267999963, 35.486025935000043],
              [-80.883170267999958, 35.485917935000032],
              [-80.883129267999948, 35.485833935000073],
              [-80.883054267999967, 35.485702934000074],
              [-80.883029266999984, 35.485643934000052],
              [-80.883017267999946, 35.485601935000034],
              [-80.883004267999979, 35.485557935000031],
              [-80.882982268999967, 35.485418935000041],
              [-80.882973266999954, 35.485203935000072],
              [-80.88295226799994, 35.484917936000045],
              [-80.882968267999956, 35.484846935000064],
              [-80.882990266999968, 35.48477693500007],
              [-80.88313526799999, 35.484464935000062],
              [-80.883162266999989, 35.484370935000072],
              [-80.883177268999987, 35.484280935000072],
              [-80.883180268999979, 35.484177934000058],
              [-80.883160267999983, 35.484070935000034],
              [-80.883126267999955, 35.483975934000057],
              [-80.883078267999963, 35.483893935000026],
              [-80.883029266999984, 35.483835935000059],
              [-80.882987266999976, 35.483793934000062],
              [-80.882863267999937, 35.483652934000077],
              [-80.882636266999953, 35.483446935000075],
              [-80.883301267999968, 35.482966935000036],
              [-80.883992267999986, 35.482467934000056],
              [-80.884039266999935, 35.482408934000034],
              [-80.884058268999979, 35.482322934000024],
              [-80.884064267999975, 35.482264934000057],
              [-80.88405526799994, 35.48220393400004],
              [-80.884032268999988, 35.482100934000073],
              [-80.883937267999954, 35.481814934000056],
              [-80.884289267999975, 35.48174193400007],
              [-80.884767267999962, 35.481623934000027],
              [-80.885143267999979, 35.481499934000055],
              [-80.885877267999945, 35.481229934000055],
              [-80.88691726899998, 35.480842934000066],
              [-80.887514268999951, 35.480616934000068],
              [-80.888311268999985, 35.480323934000069],
              [-80.888879268999972, 35.48008793300005],
              [-80.889098268999987, 35.47998593300008],
              [-80.889299269999981, 35.479882934000045],
              [-80.889500268999939, 35.479771933000052],
              [-80.889669269999956, 35.479666933000033],
              [-80.889848270999948, 35.479543933000059],
              [-80.889933268999982, 35.479477933000055],
              [-80.890019269999982, 35.479410933000054],
              [-80.890248269999972, 35.47920593300006],
              [-80.890387269999962, 35.47907093300006],
              [-80.890566269999965, 35.478874933000043],
              [-80.890699269999971, 35.478709933000061],
              [-80.890917269999989, 35.478397933000053],
              [-80.890989269999977, 35.478281933000062],
              [-80.891119268999944, 35.478034932000071],
              [-80.891174270999954, 35.477908933000037],
              [-80.891290269999956, 35.477598933000024],
              [-80.891347269999983, 35.47742693400005],
              [-80.891413268999941, 35.477200933000063],
              [-80.891453269999943, 35.476992933000076],
              [-80.891472268999962, 35.476791933000072],
              [-80.891476269999941, 35.47669893300008],
              [-80.891469269999959, 35.476215933000049],
              [-80.891485269999976, 35.475475933000041],
              [-80.891500269999938, 35.474899932000028],
              [-80.891515269999957, 35.474555933000033],
              [-80.891544270999987, 35.473583932000054],
              [-80.891590268999948, 35.471890932000065],
              [-80.891590269999938, 35.47165693200003],
              [-80.891576269999973, 35.471411931000034],
              [-80.891560269999957, 35.471289931000058],
              [-80.891509268999982, 35.471054931000026],
              [-80.891453269999943, 35.47087993100007],
              [-80.891415269999982, 35.470776932000035],
              [-80.891357269999958, 35.470656932000054],
              [-80.891210269999988, 35.470399932000078],
              [-80.890530269999942, 35.469354932000044],
              [-80.889528268999982, 35.467834930000038],
              [-80.889273268999943, 35.467422931000044],
              [-80.889160268999944, 35.467216930000063],
              [-80.889095267999949, 35.467079930000068],
              [-80.888977267999962, 35.466790930000059],
              [-80.888917267999943, 35.466592931000037],
              [-80.888875268999982, 35.466416930000037],
              [-80.888858267999979, 35.46634393000005],
              [-80.888833268999974, 35.466186931000038],
              [-80.888820267999961, 35.466102930000034],
              [-80.888799268999946, 35.465884930000072],
              [-80.888784267999938, 35.465722930000027],
              [-80.888771267999971, 35.465521930000079],
              [-80.888752268999951, 35.464896930000066],
              [-80.888713268999936, 35.463457930000061],
              [-80.888706267999964, 35.463221930000032],
              [-80.888695268999982, 35.46277493000008],
              [-80.888657268999964, 35.461316930000066],
              [-80.888641268999947, 35.460774929000024],
              [-80.888628267999934, 35.46001792900006],
              [-80.88860626799999, 35.45926692900008],
              [-80.888590266999984, 35.458311929000047],
              [-80.888547267999968, 35.456932929000061],
              [-80.88848726699996, 35.454348928000059],
              [-80.88847526699999, 35.454072928000073],
              [-80.888471267999989, 35.453916928000069],
              [-80.888455267999973, 35.453324929000075],
              [-80.888450266999939, 35.453141928000036],
              [-80.888442267999949, 35.452572928000052],
              [-80.888452266999934, 35.452399928000034],
              [-80.888463267999953, 35.452251928000067],
              [-80.888468266999951, 35.452191928000047],
              [-80.888494267999988, 35.452038927000046],
              [-80.888535267999941, 35.451862927000036],
              [-80.888588267999978, 35.451704928000026],
              [-80.888678267999978, 35.451504928000077],
              [-80.888785266999946, 35.451318927000045],
              [-80.888899267999989, 35.451154928000051],
              [-80.889074267999945, 35.450947927000072],
              [-80.889237266999942, 35.450780927000039],
              [-80.889460268999983, 35.450584927000079],
              [-80.890052267999977, 35.450104927000041],
              [-80.891016267999987, 35.449325928000064],
              [-80.891331268999977, 35.44905092700003],
              [-80.891498268999953, 35.448885926000059],
              [-80.891644268999983, 35.448730927000042],
              [-80.891778268999985, 35.448571927000046],
              [-80.891933268999935, 35.448363927000059],
              [-80.89211826899998, 35.44807492700005],
              [-80.892181268999934, 35.447970927000028],
              [-80.89231426799995, 35.447729926000079],
              [-80.89290626899998, 35.446590926000056],
              [-80.893027268999958, 35.446370926000043],
              [-80.893216267999946, 35.446092926000063],
              [-80.893284267999945, 35.446009926000045],
              [-80.893324267999958, 35.445959925000068],
              [-80.893410268999958, 35.445873926000047],
              [-80.893504268999948, 35.445784926000044],
              [-80.893702268999959, 35.445618926000066],
              [-80.893965269999967, 35.44543692600007],
              [-80.894119268999987, 35.445303926000065],
              [-80.894210268999984, 35.445217925000065],
              [-80.89428326899997, 35.445140925000032],
              [-80.894345269999974, 35.445036926000057],
              [-80.894395268999972, 35.44491892700006],
              [-80.894425269999942, 35.44478792600006],
              [-80.894431269999984, 35.444622926000079],
              [-80.89405026899999, 35.444616926000037],
              [-80.893812268999966, 35.444602926000073],
              [-80.893673267999986, 35.444598925000037],
              [-80.893326267999953, 35.444571926000037],
              [-80.892497267999943, 35.444494926000061],
              [-80.890481266999984, 35.444308926000076],
              [-80.890381266999952, 35.44429692600005],
              [-80.890323267999975, 35.444290926000065],
              [-80.888773267999966, 35.444144926000035],
              [-80.886199266999938, 35.443922926000027],
              [-80.88533126599998, 35.443840926000064],
              [-80.884337264999942, 35.443740926000032],
              [-80.883938266999962, 35.443701926000074],
              [-80.883225264999965, 35.443624926000041],
              [-80.881983264999974, 35.443511926000042],
              [-80.880510263999952, 35.443379925000045],
              [-80.879431263999948, 35.443282925000062],
              [-80.878436264999948, 35.443182926000077],
              [-80.877693263999959, 35.443107926000039],
              [-80.877776263999976, 35.443024927000067],
              [-80.877848263999965, 35.442874925000069],
              [-80.878206263999971, 35.442355926000062],
              [-80.878255263999961, 35.442268925000064],
              [-80.878320263999967, 35.442170926000074],
              [-80.878365263999967, 35.442111926000052],
              [-80.878381264999973, 35.442043925000064],
              [-80.878377263999937, 35.44192892600006],
              [-80.878383263999979, 35.441858925000076],
              [-80.878411263999965, 35.441768926000066],
              [-80.878442262999954, 35.441653926000072],
              [-80.878447263999988, 35.441574925000054],
              [-80.87846626399994, 35.441514925000035],
              [-80.878523262999977, 35.441421926000032],
              [-80.878569263999964, 35.441335925000033],
              [-80.87858926499996, 35.441313925000031],
              [-80.878596264999942, 35.441310926000028],
              [-80.878628263999985, 35.441310926000028],
              [-80.878676262999988, 35.441313925000031],
              [-80.878765263999981, 35.441314925000029],
              [-80.878815263999968, 35.441310926000028],
              [-80.878842264999946, 35.441307926000036],
              [-80.878888263999954, 35.441296926000064],
              [-80.878971263999972, 35.441280926000047],
              [-80.879049263999946, 35.441248926000071],
              [-80.879086263999966, 35.44122692600007],
              [-80.87911526399995, 35.441206926000064],
              [-80.879135264999945, 35.441188926000052],
              [-80.879147263999982, 35.441170925000051],
              [-80.879161264999937, 35.441143926000052],
              [-80.879172264999966, 35.441109926000024],
              [-80.87919326399998, 35.441038926000033],
              [-80.879206263999947, 35.440965925000057],
              [-80.879222262999974, 35.440904925000041],
              [-80.879228263999948, 35.440885925000032],
              [-80.879253263999942, 35.440854926000043],
              [-80.879282262999936, 35.440831925000055],
              [-80.879314264999948, 35.440826925000067],
              [-80.879353264999963, 35.440835925000044],
              [-80.879382263999958, 35.440838926000026],
              [-80.879405263999956, 35.440831925000055],
              [-80.879419263999978, 35.440815925000038],
              [-80.879425264999952, 35.440795926000078],
              [-80.87942626499995, 35.440774925000028],
              [-80.879430264999939, 35.440746925000042],
              [-80.879429264999942, 35.440706926000075],
              [-80.879428263999955, 35.440674925000053],
              [-80.879432263999945, 35.440603926000051],
              [-80.879437263999989, 35.440574925000078],
              [-80.879447263999964, 35.440549925000028],
              [-80.879442263999977, 35.440525926000078],
              [-80.879442263999977, 35.440505926000071],
              [-80.879452263999951, 35.440488926000057],
              [-80.879470264999952, 35.440473925000049],
              [-80.879492264999953, 35.440465926000059],
              [-80.879552263999983, 35.44045092600004],
              [-80.879592264999985, 35.44044692600005],
              [-80.879628264999951, 35.440440925000075],
              [-80.87965526399995, 35.440432926000028],
              [-80.879690263999976, 35.440419925000072],
              [-80.879794263999941, 35.440357925000058],
              [-80.879860263999944, 35.440315926000039],
              [-80.879895264999959, 35.440289925000059],
              [-80.879937264999967, 35.440233926000076],
              [-80.879949263999947, 35.44020692600003],
              [-80.879985264999959, 35.440137926000034],
              [-80.880027263999978, 35.440067925000051],
              [-80.880048263999981, 35.440044925000052],
              [-80.880081263999955, 35.440023926000038],
              [-80.880117264999967, 35.439997925000057],
              [-80.880149263999954, 35.439979925000046],
              [-80.880248263999988, 35.439950925000062],
              [-80.880336263999936, 35.439926926000055],
              [-80.880422264999936, 35.439911925000047],
              [-80.880474264999975, 35.439904925000064],
              [-80.880516263999937, 35.439900926000064],
              [-80.880570263999971, 35.439903926000056],
              [-80.88059326399997, 35.439905926000051],
              [-80.880622264999943, 35.439899925000077],
              [-80.880640264999954, 35.439875925000024],
              [-80.880652264999981, 35.439812925000069],
              [-80.880661264999958, 35.439742925000076],
              [-80.880679264999969, 35.439706925000053],
              [-80.880712264999943, 35.43967492400003],
              [-80.880734264999944, 35.439641925000046],
              [-80.880772264999962, 35.43957692400005],
              [-80.88080326499994, 35.439538925000079],
              [-80.880865264999954, 35.439452926000058],
              [-80.880895264999936, 35.439394925000045],
              [-80.880907264999962, 35.439364925000064],
              [-80.880932264999956, 35.439323925000053],
              [-80.880964264999989, 35.439304925000044],
              [-80.880996264999965, 35.43928392600003],
              [-80.881045264999955, 35.43923892600003],
              [-80.881105264999974, 35.439177925000024],
              [-80.881153263999977, 35.439116925000064],
              [-80.881253264999941, 35.438978925000072],
              [-80.881278264999935, 35.438949925000031],
              [-80.88133626399997, 35.438893926000048],
              [-80.881396264999978, 35.438840925000079],
              [-80.881427263999967, 35.438814925000031],
              [-80.881476264999947, 35.438762925000049],
              [-80.881502264999938, 35.438730925000073],
              [-80.881533264999973, 35.438677925000036],
              [-80.881567264999944, 35.438616925000076],
              [-80.881593263999946, 35.43856792400004],
              [-80.881650264999962, 35.438489924000066],
              [-80.881676264999953, 35.438436925000076],
              [-80.881697264999957, 35.438401925000051],
              [-80.881717264999963, 35.438379925000049],
              [-80.881735265999964, 35.438350925000066],
              [-80.881761263999977, 35.438324925000074],
              [-80.88183926499994, 35.438283925000064],
              [-80.881862264999938, 35.438264925000055],
              [-80.881911264999985, 35.438197925000054],
              [-80.881945264999956, 35.438146925000069],
              [-80.881967265999947, 35.438120925000078],
              [-80.882046264999985, 35.438023925000039],
              [-80.882140264999975, 35.437927924000064],
              [-80.882174264999946, 35.437890925000033],
              [-80.882202264999989, 35.437867924000045],
              [-80.882237264999958, 35.437831924000079],
              [-80.882294264999985, 35.437778925000032],
              [-80.882410264999976, 35.437645925000027],
              [-80.882449264999934, 35.437586925000062],
              [-80.882499264999979, 35.43752392500005],
              [-80.882532264999952, 35.43749392400008],
              [-80.882605264999938, 35.437412925000046],
              [-80.882631264999986, 35.437374925000029],
              [-80.882667264999952, 35.437328925000031],
              [-80.882696264999936, 35.437286925000024],
              [-80.882744265999975, 35.437223925000069],
              [-80.882782264999946, 35.437180924000074],
              [-80.882873264999944, 35.43711492500006],
              [-80.882902265999974, 35.437095925000051],
              [-80.882917264999946, 35.43707792500004],
              [-80.882960264999952, 35.437030925000045],
              [-80.882977264999965, 35.437009924000051],
              [-80.882997265999961, 35.43699192400004],
              [-80.883043264999969, 35.436927924000031],
              [-80.883063264999976, 35.43688992400007],
              [-80.883088264999969, 35.436850925000044],
              [-80.883110264999971, 35.436823925000056],
              [-80.883134265999956, 35.436802925000052],
              [-80.88316726499994, 35.436770924000029],
              [-80.883218264999982, 35.436709925000059],
              [-80.883243264999976, 35.436682924000024],
              [-80.883281264999937, 35.436647925000045],
              [-80.883312265999962, 35.436622924000062],
              [-80.883378264999976, 35.436534924000057],
              [-80.88339526599998, 35.43650292500007],
              [-80.883528265999985, 35.436391924000077],
              [-80.883565264999959, 35.43634292400003],
              [-80.883585264999965, 35.436306924000064],
              [-80.883606264999969, 35.436280924000073],
              [-80.883630263999976, 35.436256925000066],
              [-80.883667265999975, 35.436225924000041],
              [-80.883719265999957, 35.436168924000071],
              [-80.883748265999941, 35.436110924000047],
              [-80.883797265999988, 35.436040924000054],
              [-80.883834264999962, 35.436017924000055],
              [-80.883871264999982, 35.435983925000073],
              [-80.883947264999961, 35.43592592400006],
              [-80.883976264999944, 35.43588092400006],
              [-80.88401126499997, 35.435836924000057],
              [-80.884078264999971, 35.435757924000029],
              [-80.884097265999969, 35.435723924000058],
              [-80.884130264999953, 35.435654925000051],
              [-80.884147265999957, 35.435634925000045],
              [-80.884202265999988, 35.435579923000034],
              [-80.884267265999938, 35.43553092500008],
              [-80.884308264999959, 35.43549792400006],
              [-80.884370265999962, 35.435441925000077],
              [-80.884413265999967, 35.435396925000077],
              [-80.884463265999955, 35.435334924000074],
              [-80.88450626599996, 35.435269924000067],
              [-80.884565265999981, 35.435206925000045],
              [-80.884625265999944, 35.43515992500005],
              [-80.884677265999983, 35.435086924000075],
              [-80.884710265999956, 35.435057924000034],
              [-80.884747264999987, 35.435023924000063],
              [-80.884800265999957, 35.434962924000047],
              [-80.884854265999934, 35.434902924000028],
              [-80.884921265999935, 35.434815924000077],
              [-80.884947264999937, 35.434788924000031],
              [-80.884995265999976, 35.434731924000062],
              [-80.885031265999942, 35.434686924000061],
              [-80.885083265999981, 35.434628924000037],
              [-80.885133264999979, 35.434564924000028],
              [-80.88516326599995, 35.434523925000065],
              [-80.885174265999979, 35.434502924000071],
              [-80.88520026599997, 35.434464925000043],
              [-80.885224265999966, 35.43443192400008],
              [-80.885243266999964, 35.434360924000032],
              [-80.88524126599998, 35.434332924000046],
              [-80.885231265999948, 35.434287925000035],
              [-80.885218265999981, 35.434257923000075],
              [-80.885193265999987, 35.434212924000065],
              [-80.885184264999964, 35.434183924000024],
              [-80.885170264999942, 35.43410992400004],
              [-80.885160265999957, 35.434061924000048],
              [-80.885158265999962, 35.434040923000055],
              [-80.885162265999952, 35.434016924000048],
              [-80.885183265999956, 35.43394092300008],
              [-80.885183265999956, 35.433916924000073],
              [-80.885177265999971, 35.433818924000036],
              [-80.885176265999974, 35.433773924000036],
              [-80.885184265999953, 35.433695923000073],
              [-80.885184265999953, 35.433639924000033],
              [-80.885185265999951, 35.433564924000052],
              [-80.885190264999949, 35.433475924000049],
              [-80.885168265999937, 35.433372924000025],
              [-80.885189266999987, 35.43329992300005],
              [-80.885201265999967, 35.433264924000071],
              [-80.88521226499995, 35.433244923000075],
              [-80.885219265999979, 35.433218924000073],
              [-80.88521626499994, 35.433158924000054],
              [-80.885226265999961, 35.432939924000038],
              [-80.885227265999958, 35.432851923000044],
              [-80.885233265999943, 35.432743924000079],
              [-80.885210264999955, 35.432500923000077],
              [-80.885218264999935, 35.432392924000055],
              [-80.885232265999946, 35.432311923000043],
              [-80.885231264999959, 35.432260924000047],
              [-80.885220265999976, 35.432051923000074],
              [-80.885229264999964, 35.431976923000036],
              [-80.885234265999941, 35.431897923000065],
              [-80.88525726499995, 35.431857923000052],
              [-80.885262264999938, 35.431830924000053],
              [-80.885261265999986, 35.43174592400004],
              [-80.885254264999958, 35.431626923000067],
              [-80.885262264999938, 35.431560924000053],
              [-80.885244264999983, 35.431512923000071],
              [-80.885240264999936, 35.431446923000067],
              [-80.885242265999977, 35.431393924000076],
              [-80.88524926499997, 35.431322923000039],
              [-80.885266265999974, 35.431216923000079],
              [-80.885256264999953, 35.431126924000068],
              [-80.885238265999988, 35.431053924000025],
              [-80.885243264999986, 35.430943923000029],
              [-80.885265265999976, 35.430789923000077],
              [-80.885287264999988, 35.430656923000072],
              [-80.883766263999973, 35.42954392300004],
              [-80.883619264999936, 35.429492923000055],
              [-80.883513263999987, 35.42944192300007],
              [-80.883144264999942, 35.429198923000058],
              [-80.882935263999968, 35.429086922000067],
              [-80.882796263999978, 35.429022923000048],
              [-80.882678263999935, 35.428985924000074],
              [-80.882593264999969, 35.428962923000029],
              [-80.882089263999944, 35.428861923000056],
              [-80.88168826499998, 35.428786923000075],
              [-80.881106263999982, 35.428679922000072],
              [-80.88025126399998, 35.428535923000027],
              [-80.879991263999955, 35.428486923000037],
              [-80.87980926399996, 35.428486923000037],
              [-80.879684263999934, 35.428508923000038],
              [-80.879484262999938, 35.428601923000031],
              [-80.878897262999942, 35.428887923000048],
              [-80.878607263999982, 35.429024924000032],
              [-80.877776262999987, 35.429416923000076],
              [-80.87693126299996, 35.429810923000048],
              [-80.876112262999982, 35.428602923000028],
              [-80.876082262999944, 35.42856792300006],
              [-80.876040262999936, 35.428534924000076],
              [-80.875851261999969, 35.428411923000056],
              [-80.875748262999934, 35.428357923000078],
              [-80.875683262999985, 35.428449923000073],
              [-80.875547261999941, 35.428613924000047],
              [-80.875217262999968, 35.428979923000043],
              [-80.874576262999938, 35.42976892300004],
              [-80.874520261999976, 35.429657923000036],
              [-80.87407626199996, 35.42887892300007],
              [-80.873300261999987, 35.427514923000047],
              [-80.872914261999938, 35.426833923000061],
              [-80.871619260999978, 35.424574922000033],
              [-80.871271260999947, 35.42397492200007],
              [-80.871195260999968, 35.423860922000074],
              [-80.871120260999987, 35.423766922000027],
              [-80.871037260999969, 35.423676923000073],
              [-80.870600261999982, 35.42328092200006],
              [-80.870459260999951, 35.423151923000034],
              [-80.870402260999981, 35.42310092200006],
              [-80.869908259999988, 35.422664922000024],
              [-80.869850260999954, 35.422711922000076],
              [-80.869704260999981, 35.422812922000048],
              [-80.869594260999975, 35.422894923000058],
              [-80.869511259999967, 35.422931922000032],
              [-80.86946525999997, 35.422946922000051],
              [-80.86932225999999, 35.423007922000068],
              [-80.869205259999944, 35.42304792200008],
              [-80.869046260999937, 35.423114922000025],
              [-80.868938259999936, 35.423173923000036],
              [-80.868821259999947, 35.42322792300007],
              [-80.868629260999967, 35.42332992200005],
              [-80.868510260999983, 35.423414922000063],
              [-80.868461258999957, 35.42347592200008],
              [-80.868383259999973, 35.423550922000061],
              [-80.868269259999977, 35.423677922000024],
              [-80.868139260999953, 35.423799923000047],
              [-80.86807325999996, 35.423850922000042],
              [-80.868059259999939, 35.423869923000041],
              [-80.868039259999989, 35.423936922000053],
              [-80.868023260999962, 35.423950923000064],
              [-80.867958259999966, 35.423972923000065],
              [-80.86793425999997, 35.423985922000043],
              [-80.867921259999946, 35.424000922000062],
              [-80.867912259999969, 35.424025923000045],
              [-80.867908260999968, 35.424057922000031],
              [-80.867891260999954, 35.424093922000054],
              [-80.867853259999947, 35.424128923000069],
              [-80.867804259999957, 35.424160923000045],
              [-80.867700259999935, 35.424199922000071],
              [-80.867655259999935, 35.424233922000042],
              [-80.867609259999938, 35.424295922000056],
              [-80.86752125999999, 35.424387922000051],
              [-80.867487259999962, 35.424429922000058],
              [-80.867466258999968, 35.424472922000064],
              [-80.86744325899997, 35.424493923000057],
              [-80.867344259999982, 35.424559923000061],
              [-80.867312259999949, 35.42459492200004],
              [-80.867286260999947, 35.424645923000071],
              [-80.867278259999978, 35.424671922000073],
              [-80.867222259999949, 35.424755922000031],
              [-80.86715425899996, 35.424820923000027],
              [-80.867121259999976, 35.42486092200005],
              [-80.867021259999945, 35.424935923000078],
              [-80.866961258999936, 35.424967923000054],
              [-80.866920258999983, 35.425008923000064],
              [-80.866840259999947, 35.425069922000034],
              [-80.866774259999943, 35.425133922000043],
              [-80.866690259999984, 35.425241923000044],
              [-80.866655259999959, 35.425277923000067],
              [-80.866469259999974, 35.42540792300008],
              [-80.866324259999942, 35.425547923000067],
              [-80.866264258999934, 35.425617923000061],
              [-80.866246259999969, 35.425629923000031],
              [-80.866143259999944, 35.425674923000031],
              [-80.86605925899994, 35.425774923000063],
              [-80.866002259999959, 35.425821923000058],
              [-80.865920259999939, 35.425875923000035],
              [-80.865696258999947, 35.426073923000047],
              [-80.865643258999967, 35.426097923000043],
              [-80.865381258999946, 35.426292923000062],
              [-80.865013259999955, 35.425669923000044],
              [-80.864908258999947, 35.425463923000052],
              [-80.864818258999946, 35.425326923000057],
              [-80.864343257999963, 35.424510923000071],
              [-80.863406258999987, 35.422934922000024],
              [-80.863185257999987, 35.422554922000074],
              [-80.862778258999981, 35.421852922000028],
              [-80.862699258999953, 35.42170892300004],
              [-80.86300125799994, 35.421605922000026],
              [-80.863858257999937, 35.421285922000038],
              [-80.864313257999981, 35.421108921000041],
              [-80.865050258999986, 35.420819921000032],
              [-80.865272259999983, 35.420011922000072],
              [-80.865347258999975, 35.419759921000036],
              [-80.865369258999976, 35.419698921000077],
              [-80.865408259999981, 35.419610921000071],
              [-80.865455258999987, 35.419523922000053],
              [-80.865539258999945, 35.419390922000048],
              [-80.86561925999996, 35.419283922000034],
              [-80.865735258999962, 35.419151922000026],
              [-80.865831258999947, 35.419062921000034],
              [-80.866069259999961, 35.418878921000044],
              [-80.866624258999934, 35.418497921000039],
              [-80.867214258999979, 35.418092921000039],
              [-80.868695259999981, 35.417051921000052],
              [-80.869499259999941, 35.416482921000068],
              [-80.869750259999989, 35.416301920000024],
              [-80.870168260999947, 35.416001920000042],
              [-80.870998260999954, 35.415417920000039],
              [-80.871109260999958, 35.415336920000072],
              [-80.871301259999939, 35.415197920000026],
              [-80.871470260999956, 35.415061920000028],
              [-80.871723260999943, 35.414830920000043],
              [-80.872393260999957, 35.414143921000061],
              [-80.872671260999937, 35.413875920000066],
              [-80.873269260999962, 35.413286920000075],
              [-80.873359260999962, 35.413176920000069],
              [-80.873443261999967, 35.413047920000054],
              [-80.873549259999947, 35.41283892000007],
              [-80.873719261999952, 35.412438920000056],
              [-80.874005260999979, 35.411702919000049],
              [-80.87417826099994, 35.411300919000041],
              [-80.874262261999945, 35.411092919000055],
              [-80.874454260999983, 35.410642919000054],
              [-80.874494261999985, 35.410547919000066],
              [-80.875089260999971, 35.409157919000052],
              [-80.87513826199995, 35.409025918000054],
              [-80.875166260999947, 35.408928919000061],
              [-80.875179260999971, 35.408811919000073],
              [-80.875176260999979, 35.408740919000024],
              [-80.875162261999947, 35.408644919000039],
              [-80.875089260999971, 35.408261918000051],
              [-80.874954260999971, 35.407765919000042],
              [-80.87480726199999, 35.407254918000035],
              [-80.874719260999939, 35.406859918000066],
              [-80.874689261999947, 35.406540919000065],
              [-80.874675260999936, 35.406294919000061],
              [-80.874676260999934, 35.406260918000044],
              [-80.874384260999989, 35.40622391800008],
              [-80.873939260999975, 35.40613391800008],
              [-80.87357326099999, 35.405977918000076],
              [-80.873253260999945, 35.405780918000062],
              [-80.873120259999951, 35.405698918000041],
              [-80.872023260999981, 35.405025918000035],
              [-80.871820259999936, 35.404894918000025],
              [-80.871642259999987, 35.404779919000077],
              [-80.871244260999958, 35.404597918000036],
              [-80.870751259999963, 35.404487918000029],
              [-80.870378259999939, 35.404474918000062],
              [-80.869925259999945, 35.404552918000036],
              [-80.869581258999972, 35.404654918000062],
              [-80.869580259999964, 35.404654918000062],
              [-80.869486258999984, 35.404670918000079],
              [-80.869373259999975, 35.404706918000045],
              [-80.869343258999947, 35.404448918000071],
              [-80.869360258999961, 35.404152918000079],
              [-80.869384258999958, 35.403960918000053],
              [-80.869508258999986, 35.402689918000078],
              [-80.869524258999945, 35.402506918000029],
              [-80.869667258999982, 35.401284918000044],
              [-80.869673258999967, 35.40120091700004],
              [-80.869682259999934, 35.401077917000066],
              [-80.869670258999975, 35.400930917000039],
              [-80.86961225899995, 35.400716917000068],
              [-80.869453258999954, 35.400280918000078],
              [-80.869347259999984, 35.399965917000031],
              [-80.86933025899998, 35.399895917000038],
              [-80.869323258999941, 35.399831918000075],
              [-80.869327258999988, 35.399725917000069],
              [-80.869353259999968, 35.399605917000031],
              [-80.869378258999973, 35.39955291800004],
              [-80.86832925799996, 35.399005917000068],
              [-80.867964258999962, 35.398823917000072],
              [-80.867874257999972, 35.398780917000067],
              [-80.867493257999968, 35.398600918000056],
              [-80.867241257999979, 35.398481917000026],
              [-80.866540257999986, 35.398139917000037],
              [-80.86610025799996, 35.397940917000028],
              [-80.865858257999946, 35.397847917000036],
              [-80.865745257999947, 35.397809917000075],
              [-80.865585257999953, 35.397766917000069],
              [-80.865190257999984, 35.397686917000044],
              [-80.865097257999935, 35.397672916000033],
              [-80.864823257999944, 35.397651917000076],
              [-80.864328257999944, 35.39762491700003],
              [-80.864032256999963, 35.39761291700006],
              [-80.864086257999986, 35.396638916000029],
              [-80.864100256999961, 35.396377917000052],
              [-80.864126256999953, 35.396095917000025],
              [-80.864267256999938, 35.39544791600008],
              [-80.864631257999974, 35.394380916000046],
              [-80.864721257999975, 35.394060915000068],
              [-80.86473925699994, 35.39396191700007],
              [-80.864754256999959, 35.393817916000046],
              [-80.864758257999938, 35.393691915000034],
              [-80.864753256999961, 35.393584916000066],
              [-80.864740256999937, 35.393462916000033],
              [-80.864717256999938, 35.393336916000067],
              [-80.864683257999957, 35.393208916000049],
              [-80.864634257999967, 35.393070916000056],
              [-80.864583257999982, 35.392954915000075],
              [-80.864512256999944, 35.392821916000059],
              [-80.864437257999953, 35.392702915000029],
              [-80.864338256999986, 35.392571916000065],
              [-80.864249256999983, 35.392468916000041],
              [-80.864149256999951, 35.392366916000071],
              [-80.864040256999942, 35.392269916000032],
              [-80.863932257999977, 35.392184916000076],
              [-80.863799255999936, 35.392092916000024],
              [-80.863666255999988, 35.392012915000066],
              [-80.863520256999948, 35.391936916000077],
              [-80.86337625699997, 35.391871916000071],
              [-80.863222256999961, 35.391813916000046],
              [-80.863078256999984, 35.391768916000046],
              [-80.862935256999947, 35.391730916000029],
              [-80.862781256999938, 35.391698916000053],
              [-80.862625256999934, 35.391675916000054],
              [-80.862437255999964, 35.391657916000042],
              [-80.862290256999984, 35.391651915000068],
              [-80.862144256999954, 35.391653916000053],
              [-80.862006255999972, 35.391660916000035],
              [-80.861830255999962, 35.391679916000044],
              [-80.86170225699999, 35.391699917000039],
              [-80.861564255999951, 35.391728916000034],
              [-80.861418255999979, 35.391765916000054],
              [-80.861299255999938, 35.391802915000028],
              [-80.86115525699995, 35.391854916000057],
              [-80.861007256999983, 35.391918915000076],
              [-80.860898255999984, 35.391971916000045],
              [-80.860748255999965, 35.392055915000071],
              [-80.860618254999963, 35.392140916000074],
              [-80.860496255999976, 35.392230915000027],
              [-80.860388255999965, 35.392321917000061],
              [-80.860293255999977, 35.392412916000069],
              [-80.860268254999937, 35.39243891600006],
              [-80.860223256999973, 35.392486916000053],
              [-80.860114255999974, 35.392420916000049],
              [-80.858470254999986, 35.39142191600007],
              [-80.85843125599996, 35.391404916000056],
              [-80.858229254999969, 35.39132491600003],
              [-80.858019255999977, 35.391258915000037],
              [-80.857804254999962, 35.391208916000039],
              [-80.857584254999949, 35.391173916000071],
              [-80.857361254999944, 35.391154915000072],
              [-80.857137254999941, 35.391152916000067],
              [-80.856792254999959, 35.391169916000024],
              [-80.855460253999979, 35.391236915000036],
              [-80.855135254999936, 35.391226916000051],
              [-80.854840254999942, 35.391204916000049],
              [-80.854547253999954, 35.391169916000024],
              [-80.854160253999964, 35.391103916000077],
              [-80.854135253999971, 35.391206916000044],
              [-80.853990252999949, 35.391694916000063],
              [-80.853956253999968, 35.391781915000024],
              [-80.853744253999935, 35.392241916000046],
              [-80.853436254999963, 35.392829917000029],
              [-80.853150252999967, 35.393362916000058],
              [-80.85308125399996, 35.393481917000031],
              [-80.853231253999979, 35.393731916000036],
              [-80.853614253999979, 35.394330917000048],
              [-80.854202253999972, 35.395249917000058],
              [-80.854525253999952, 35.39576691700006],
              [-80.854873254999973, 35.39638291600005],
              [-80.855252254999982, 35.397105917000033],
              [-80.855562254999938, 35.397749917000056],
              [-80.855622254999957, 35.397889917000043],
              [-80.855803254999955, 35.39831491700005],
              [-80.855923254999936, 35.398612918000026],
              [-80.856048254999962, 35.398939917000064],
              [-80.856208255999945, 35.399384917000077],
              [-80.85639325599999, 35.399940917000038],
              [-80.856632255999955, 35.400816918000032],
              [-80.85669025499999, 35.401043918000028],
              [-80.856812255999955, 35.401604918000032],
              [-80.85687825499997, 35.401941918000034],
              [-80.857004255999982, 35.40271191800008],
              [-80.857083254999964, 35.403099919000056],
              [-80.857290255999942, 35.404263918000026],
              [-80.857346255999971, 35.404580919000068],
              [-80.857382255999937, 35.404980918000035],
              [-80.857522255999982, 35.405869919000054],
              [-80.857607256999984, 35.406357919000072],
              [-80.857740255999943, 35.407182919000036],
              [-80.857819255999971, 35.407643919000066],
              [-80.85795825699995, 35.408444919000033],
              [-80.858044254999982, 35.408945919000075],
              [-80.857413256999962, 35.409146920000069],
              [-80.856911255999989, 35.409306920000063],
              [-80.856401256999959, 35.409446919000061],
              [-80.856366254999955, 35.409455919000038],
              [-80.855477255999972, 35.409700919000045],
              [-80.855334254999946, 35.40973991900006],
              [-80.85487425599996, 35.40987092000006],
              [-80.85482125599998, 35.409697920000042],
              [-80.854754254999989, 35.40942891900005],
              [-80.854732254999988, 35.409339920000036],
              [-80.854582254999968, 35.408855919000075],
              [-80.854370254999935, 35.408221919000027],
              [-80.854020254999966, 35.407157919000042],
              [-80.85357225499996, 35.405747919000078],
              [-80.851767253999981, 35.399958918000038],
              [-80.851251252999987, 35.39832291700003],
              [-80.851161253999976, 35.39844591800005],
              [-80.851127253999948, 35.398490917000061],
              [-80.850895253999965, 35.398764917000051],
              [-80.850487253999972, 35.399212917000057],
              [-80.848718253999948, 35.401166919000048],
              [-80.847918252999989, 35.402049919000035],
              [-80.847743252999976, 35.402228919000038],
              [-80.847571251999966, 35.402387918000045],
              [-80.847364252999967, 35.402550918000031],
              [-80.847257252999952, 35.402623918000074],
              [-80.847131252999986, 35.402701918000048],
              [-80.846965252999951, 35.402792919000035],
              [-80.846764252999947, 35.402885919000028],
              [-80.846285252999962, 35.403059919000043],
              [-80.845573251999951, 35.403297919000067],
              [-80.845309250999946, 35.40339491900005],
              [-80.843974251999953, 35.403872919000037],
              [-80.843028250999964, 35.404208919000041],
              [-80.841621250999935, 35.40470891800004],
              [-80.841506250999942, 35.404753919000029],
              [-80.841298250999955, 35.404844918000038],
              [-80.839944250999963, 35.405288919000043],
              [-80.83976125099997, 35.405346920000056],
              [-80.839662249999947, 35.405373919000056],
              [-80.839680250999947, 35.405468919000043],
              [-80.840026249999937, 35.406843919000039],
              [-80.840072250999981, 35.407026920000078],
              [-80.840329250999957, 35.408107920000077],
              [-80.840595250999968, 35.409230921000074],
              [-80.840751250999972, 35.409885921000068],
              [-80.840984250999952, 35.410937921000027],
              [-80.841407251999954, 35.410901921000061],
              [-80.842817250999985, 35.410809921000066],
              [-80.842953251999973, 35.410808921000068],
              [-80.842968252999981, 35.410943920000079],
              [-80.843002251999962, 35.41143892100007],
              [-80.843163252999943, 35.413303922000068],
              [-80.843197251999982, 35.413796921000028],
              [-80.843202251999969, 35.413961921000066],
              [-80.84317925299996, 35.414287921000039],
              [-80.843177251999975, 35.414308921000043],
              [-80.843140251999955, 35.414547922000054],
              [-80.843111251999972, 35.414668922000033],
              [-80.842929252999966, 35.415274922000037],
              [-80.842874251999945, 35.415444921000073],
              [-80.842828251999947, 35.415585922000048],
              [-80.842634252999972, 35.416187922000063],
              [-80.842335252999987, 35.417110923000052],
              [-80.842641251999964, 35.417186923000031],
              [-80.842809251999938, 35.417223922000062],
              [-80.843082251999988, 35.417272921000063],
              [-80.843246251999972, 35.417290921000074],
              [-80.843480251999949, 35.417303923000077],
              [-80.843724252999948, 35.417301922000036],
              [-80.844223251999949, 35.417254922000041],
              [-80.844386251999936, 35.417232922000039],
              [-80.844500251999989, 35.417217922000077],
              [-80.84461225299998, 35.417208922000043],
              [-80.844724251999935, 35.417205922000051],
              [-80.844923252999934, 35.417206922000048],
              [-80.845601252999984, 35.417243922000068],
              [-80.845793252999954, 35.417246922000061],
              [-80.845966253999961, 35.417233922000037],
              [-80.846077252999976, 35.41721392200003],
              [-80.846209252999984, 35.417178922000062],
              [-80.846410252999988, 35.417104922000078],
              [-80.846871252999961, 35.416919922000034],
              [-80.846998253999971, 35.416865921000067],
              [-80.847169252999947, 35.416792921000024],
              [-80.84726225299994, 35.416737922000038],
              [-80.84738925399995, 35.41663292100003],
              [-80.848989253999946, 35.416649922000033],
              [-80.848991253999941, 35.416876921000039],
              [-80.848991253999941, 35.416912922000051],
              [-80.848980253999969, 35.417058922000024],
              [-80.848955254999964, 35.41716892200003],
              [-80.848907253999982, 35.417282922000027],
              [-80.84887525399995, 35.417335922000063],
              [-80.848793254999975, 35.417437922000033],
              [-80.848745253999937, 35.417484922000028],
              [-80.84853325399996, 35.417643921000035],
              [-80.848260252999978, 35.418122922000066],
              [-80.848544253999989, 35.418122922000066],
              [-80.848710253999968, 35.418087921000051],
              [-80.848845253999968, 35.418029921000027],
              [-80.848976253999979, 35.418019922000042],
              [-80.84919025399995, 35.417939922000073],
              [-80.849486253999942, 35.417837921000057],
              [-80.849691253999936, 35.417739922000067],
              [-80.849800254999934, 35.417700921000062],
              [-80.849842253999952, 35.417690922000077],
              [-80.849895253999989, 35.417659921000052],
              [-80.85011725399994, 35.417564922000054],
              [-80.85018325499999, 35.417544921000058],
              [-80.850322253999934, 35.418050921000031],
              [-80.850335254999948, 35.418066922000037],
              [-80.850351253999975, 35.418104922000055],
              [-80.850352253999972, 35.418153922000045],
              [-80.850325253999983, 35.418238922000057],
              [-80.850238253999976, 35.418435922000072],
              [-80.850169254999969, 35.41857592100007],
              [-80.84979025399997, 35.419407922000062],
              [-80.849753254999939, 35.419494922000069],
              [-80.849722253999971, 35.419587921000073],
              [-80.849705254999947, 35.419672922000075],
              [-80.849701253999967, 35.419796922000046],
              [-80.849709253999947, 35.419912922000037],
              [-80.84974525399997, 35.420245922000049],
              [-80.849785253999983, 35.420407922000038],
              [-80.849804253999935, 35.420456922000028],
              [-80.849839254999949, 35.420519923000029],
              [-80.85019525499996, 35.420948922000036],
              [-80.850264253999967, 35.421038922000037],
              [-80.850340253999946, 35.421168922000049],
              [-80.85037125499997, 35.421251922000067],
              [-80.850387254999987, 35.42133692200008],
              [-80.850470254999948, 35.42198292300003],
              [-80.849339253999972, 35.422075923000079],
              [-80.849238253999943, 35.422084922000067],
              [-80.84876925399999, 35.422130922000065],
              [-80.848621254999955, 35.42213292200006],
              [-80.848607253999944, 35.422258923000072],
              [-80.848552253999969, 35.422569923000026],
              [-80.848518253999941, 35.422668922000071],
              [-80.848468253999954, 35.422763923000048],
              [-80.848291253999946, 35.423007923000057],
              [-80.847906252999962, 35.423547922000068],
              [-80.847842253999943, 35.423610923000069],
              [-80.847760253999979, 35.42366792200005],
              [-80.847680253999954, 35.423701923000067],
              [-80.847331253999982, 35.423807923000027],
              [-80.847255253999947, 35.423845923000044],
              [-80.847189253999943, 35.423894923000034],
              [-80.847137253999961, 35.423954923000053],
              [-80.84709625399995, 35.424029923000035],
              [-80.847071253999957, 35.424104923000073],
              [-80.847062254999969, 35.424174923000066],
              [-80.847064253999974, 35.42428592300007],
              [-80.847047252999971, 35.424369923000029],
              [-80.847011252999948, 35.424445923000064],
              [-80.846960253999953, 35.424506923000024],
              [-80.846879253999987, 35.424577923000072],
              [-80.84678525399994, 35.424634923000042],
              [-80.846741252999948, 35.424651924000045],
              [-80.846780253999952, 35.424848924000059],
              [-80.846838252999987, 35.425251923000076],
              [-80.846958253999958, 35.425936924000041],
              [-80.848501253999984, 35.425778923000053],
              [-80.848659253999983, 35.425752923000061],
              [-80.848817254999972, 35.425708924000048],
              [-80.848920254999939, 35.425674923000031],
              [-80.851376254999934, 35.42488992400007],
              [-80.852394254999979, 35.424563923000051],
              [-80.854956256999969, 35.423759923000034],
              [-80.855051255999967, 35.423729923000053],
              [-80.855246256999976, 35.423669923000034],
              [-80.856335256999955, 35.423332922000043],
              [-80.857144256999959, 35.423122922000061],
              [-80.857680255999981, 35.422991923000041],
              [-80.858662256999935, 35.422752922000029],
              [-80.859637257999964, 35.422521922000044],
              [-80.861272257999985, 35.425813922000032],
              [-80.862235258999988, 35.427743923000037],
              [-80.862493257999972, 35.428265923000026],
              [-80.863062258999946, 35.429417923000074],
              [-80.86377725899996, 35.430865924000045],
              [-80.863885258999971, 35.43108492500005],
              [-80.864142258999948, 35.431656925000027],
              [-80.864400259999968, 35.43231292400003],
              [-80.864643258999934, 35.43305192400004],
              [-80.864826259999973, 35.433726924000041],
              [-80.864893259999974, 35.434042924000039],
              [-80.864980258999935, 35.434455925000066],
              [-80.865093259999981, 35.435199925000063],
              [-80.865246259999935, 35.436462925000058],
              [-80.865307259999952, 35.436964925000041],
              [-80.865347258999975, 35.437410925000052],
              [-80.865358259999937, 35.437594925000042],
              [-80.865353259999949, 35.438257925000073],
              [-80.86534525999997, 35.43850692500007],
              [-80.865317259999983, 35.439036926000028],
              [-80.865307259999952, 35.439227926000058],
              [-80.865296260999969, 35.439590926000051],
              [-80.865297260999967, 35.439890925000043],
              [-80.865322259999971, 35.440555926000059],
              [-80.865342259999977, 35.440886925000029],
              [-80.86538625999998, 35.441413926000052],
              [-80.865449259999934, 35.441956926000046],
              [-80.865492260999986, 35.442232926000031],
              [-80.863250259999973, 35.442078926000079],
              [-80.859993258999964, 35.441852926000024],
              [-80.858597258999964, 35.441755927000031],
              [-80.857328257999939, 35.441668926000034],
              [-80.856521257999987, 35.441614926000057],
              [-80.854474256999936, 35.441473927000061],
              [-80.853451256999961, 35.441404926000075],
              [-80.852713256999948, 35.441354926000031],
              [-80.852610255999934, 35.441348927000035],
              [-80.852582256999938, 35.441572926000049],
              [-80.852566255999989, 35.441750927000044],
              [-80.852570255999979, 35.441911927000035],
              [-80.852589255999987, 35.442111927000042],
              [-80.852601256999947, 35.442195927000057],
              [-80.852623255999958, 35.443159927000067],
              [-80.852616255999976, 35.443572927000048],
              [-80.852571256999965, 35.443793927000058],
              [-80.852482256999963, 35.443966927000076],
              [-80.852417255999967, 35.444056928000066],
              [-80.85248325699996, 35.444095927000035],
              [-80.852563256999986, 35.444125927000073],
              [-80.852630256999987, 35.444140927000035],
              [-80.852700255999935, 35.444147927000074],
              [-80.855178256999977, 35.444178928000042],
              [-80.85519725599994, 35.444349927000076],
              [-80.855199257999971, 35.445080927000049],
              [-80.855204256999968, 35.445254927000065],
              [-80.855224257999964, 35.445377927000038],
              [-80.855256256999951, 35.445474927000078],
              [-80.855314256999975, 35.445621928000037],
              [-80.855380256999979, 35.445765927000025],
              [-80.855456257999947, 35.445864927000059],
              [-80.855574256999944, 35.446007928000029],
              [-80.855263257999979, 35.446212927000033],
              [-80.855160256999966, 35.446284928000068],
              [-80.855074257999945, 35.446362928000042],
              [-80.85501925799997, 35.446427927000059],
              [-80.854963256999952, 35.446503927000037],
              [-80.854940257999942, 35.446534928000062],
              [-80.85461825699997, 35.447137928000075],
              [-80.85453625699995, 35.447115928000073],
              [-80.854418256999963, 35.44709492800007],
              [-80.854147256999966, 35.447061928000039],
              [-80.853776256999936, 35.447024928000076],
              [-80.85373225699999, 35.447490928000036],
              [-80.853700256999957, 35.447755928000049],
              [-80.853661256999942, 35.448174928000071],
              [-80.853616256999942, 35.448396928000079],
              [-80.853575257999978, 35.448495928000057],
              [-80.853528256999937, 35.448592928000039],
              [-80.853430256999957, 35.448774929000024],
              [-80.853293256999962, 35.448999928000035],
              [-80.854345257999967, 35.449407928000028],
              [-80.854818257999966, 35.449584928000036],
              [-80.855016257999978, 35.449668928000051],
              [-80.855104257999983, 35.44972492800008],
              [-80.855215257999987, 35.449812928000028],
              [-80.855345257999943, 35.449895928000046],
              [-80.855382257999963, 35.449913928000058],
              [-80.855412256999955, 35.449925928000027],
              [-80.855624257999978, 35.449983928000051],
              [-80.855693256999984, 35.450000929000055],
              [-80.855813257999955, 35.450031928000044],
              [-80.855911257999935, 35.450066929000059],
              [-80.856013257999962, 35.45013792900005],
              [-80.856137256999943, 35.450249928000062],
              [-80.856218257999956, 35.450301929000034],
              [-80.856310256999961, 35.450347928000042],
              [-80.856406257999936, 35.450380928000072],
              [-80.856574257999966, 35.45041892900008],
              [-80.856712258999949, 35.450440928000035],
              [-80.856782257999953, 35.450450929000056],
              [-80.856887258999961, 35.450465928000028],
              [-80.857116257999962, 35.450498928000059],
              [-80.85724025899998, 35.450519928000062],
              [-80.85733825899996, 35.450548929000036],
              [-80.857604257999981, 35.450653928000065],
              [-80.857849257999987, 35.450750929000037],
              [-80.858094258999984, 35.450847929000076],
              [-80.858181257999945, 35.450881928000058],
              [-80.858353258999955, 35.450950928000054],
              [-80.858479258999978, 35.451000929000031],
              [-80.858611257999939, 35.451052928000024],
              [-80.858696258999942, 35.451096929000073],
              [-80.858773258999975, 35.451179929000034],
              [-80.858801258999961, 35.451238928000066],
              [-80.858810257999949, 35.451281928000071],
              [-80.858809258999941, 35.451354929000047],
              [-80.858779258999959, 35.451432929000077],
              [-80.858701258999986, 35.45157492900006],
              [-80.858661258999973, 35.451642929000059],
              [-80.858633257999941, 35.451681929000074],
              [-80.858556258999954, 35.451771928000028],
              [-80.858384258999934, 35.451905929000077],
              [-80.858078258999967, 35.452146928000047],
              [-80.858069257999944, 35.452154929000073],
              [-80.857483257999945, 35.452600929000027],
              [-80.857132257999979, 35.452836930000046],
              [-80.856637257999978, 35.452420929000027],
              [-80.856683258999965, 35.452356929000075],
              [-80.856711258999951, 35.452273929000057],
              [-80.85669725799994, 35.452191929000037],
              [-80.85664825899994, 35.452109929000073],
              [-80.856581257999949, 35.452052929000047],
              [-80.856498258999977, 35.452024928000071],
              [-80.856379257999947, 35.452031929000043],
              [-80.856267257999946, 35.452062928000032],
              [-80.856197257999952, 35.452107929000078],
              [-80.856157257999939, 35.452165929000046],
              [-80.855730257999937, 35.452085929000077],
              [-80.855585258999952, 35.452058928000042],
              [-80.855187257999944, 35.45199192900003],
              [-80.855050257999949, 35.451979929000061],
              [-80.854902257999981, 35.451990929000033],
              [-80.854765257999986, 35.452013929000032],
              [-80.854678256999989, 35.452028929000051],
              [-80.853791257999944, 35.452158928000074],
              [-80.852060256999948, 35.452437929000041],
              [-80.851930256999935, 35.452463929000032],
              [-80.851801256999977, 35.452508928000043],
              [-80.851653255999963, 35.452607929000067],
              [-80.851566256999945, 35.452543929000058],
              [-80.851540255999964, 35.452523930000041],
              [-80.851463256999978, 35.452486928000042],
              [-80.851378255999975, 35.452467928000033],
              [-80.851284256999975, 35.452461929000037],
              [-80.851194256999975, 35.452491929000075],
              [-80.851138256999945, 35.452533929000026],
              [-80.851101255999936, 35.452578929000026],
              [-80.851078256999983, 35.452626928000029],
              [-80.85054725599997, 35.452745929000059],
              [-80.850024256999973, 35.452870929000028],
              [-80.850016255999947, 35.452899929000068],
              [-80.849961255999972, 35.45300792900008],
              [-80.849910256999976, 35.453061929000057],
              [-80.849882255999944, 35.453092929000036],
              [-80.849770255999942, 35.453169929000069],
              [-80.849647255999969, 35.453233929000078],
              [-80.849496255999952, 35.453275930000075],
              [-80.849070255999948, 35.453340929000035],
              [-80.848336255999982, 35.45345793000007],
              [-80.848125255999946, 35.453503929000078],
              [-80.848028255999964, 35.453352929000062],
              [-80.847886254999935, 35.453129929000056],
              [-80.847750254999937, 35.452917928000033],
              [-80.84768825499998, 35.452793930000041],
              [-80.847608255999944, 35.452617929000041],
              [-80.847480254999937, 35.452379929000074],
              [-80.84718225499995, 35.451834929000029],
              [-80.847083254999973, 35.451690929000051],
              [-80.847010254999987, 35.451744930000075],
              [-80.846825255999988, 35.451801930000045],
              [-80.846688254999947, 35.451816929000074],
              [-80.846540254999979, 35.451796929000068],
              [-80.846504254999957, 35.451785929000039],
              [-80.846372254999949, 35.451744930000075],
              [-80.846315254999979, 35.451726929000074],
              [-80.846150254999941, 35.451665929000058],
              [-80.845653254999945, 35.451497929000027],
              [-80.845499255999982, 35.451451930000076],
              [-80.84533225499996, 35.451407929000027],
              [-80.845129254999961, 35.45136192800004],
              [-80.844865254999945, 35.451312929000039],
              [-80.844068254999968, 35.451184929000078],
              [-80.843214253999975, 35.451028929000074],
              [-80.842840254999942, 35.453674930000034],
              [-80.842820253999946, 35.453806930000042],
              [-80.842783253999983, 35.454058930000031],
              [-80.842724253999961, 35.454481930000043],
              [-80.842618253999944, 35.455296930000031],
              [-80.842592253999953, 35.45564193000007],
              [-80.842588253999963, 35.45569092900007],
              [-80.842589253999961, 35.456134930000076],
              [-80.842619253999942, 35.456561930000078],
              [-80.842634253999961, 35.456697930000075],
              [-80.842678253999964, 35.457012930000076],
              [-80.84273525399999, 35.457280931000071],
              [-80.842848253999989, 35.45770493100008],
              [-80.842910253999946, 35.457892930000071],
              [-80.843012253999973, 35.458178931000077],
              [-80.843200254999942, 35.458610931000067],
              [-80.84337725499995, 35.45896993100007],
              [-80.84348225399998, 35.459162930000048],
              [-80.843629255999986, 35.459414930000037],
              [-80.844000254999969, 35.45999893100003],
              [-80.844372255999986, 35.460569931000066],
              [-80.84449525499997, 35.460758931000044],
              [-80.845495254999946, 35.46229393200008],
              [-80.845847255999956, 35.462834931000032],
              [-80.846271254999976, 35.463486931000034],
              [-80.847334255999954, 35.465122933000032],
              [-80.847710255999971, 35.465691932000027],
              [-80.847907255999985, 35.466006932000028],
              [-80.849149256999965, 35.467921932000024],
              [-80.849564255999951, 35.468551933000072],
              [-80.849676257999988, 35.468724932000043],
              [-80.849782256999958, 35.468888932000027],
              [-80.849545256999988, 35.469029933000058],
              [-80.849203256999942, 35.469234933000052],
              [-80.843787254999938, 35.471022934000075],
              [-80.84231925499995, 35.471506933000057],
              [-80.840337253999962, 35.472132934000058],
              [-80.833587252999962, 35.474260934000029],
              [-80.831405250999978, 35.474927935000039],
              [-80.830715251999948, 35.475137935000078],
              [-80.830558251999946, 35.475184935000073],
              [-80.83033825199999, 35.478319936000048],
              [-80.830418250999969, 35.47832293600004],
              [-80.830765250999946, 35.478340936000052],
              [-80.831029251999951, 35.478345936000039],
              [-80.831235251999942, 35.478332935000026],
              [-80.831499251999958, 35.478338936000057],
              [-80.831664251999939, 35.478357935000076],
              [-80.831924251999965, 35.478354935000027],
              [-80.832038252999951, 35.478360935000069],
              [-80.832302251999977, 35.478342935000057],
              [-80.832478251999987, 35.478356935000079],
              [-80.832632252999986, 35.478379935000078],
              [-80.832700252999985, 35.478400936000071],
              [-80.832781252999951, 35.478447935000077],
              [-80.832847251999965, 35.478492935000077],
              [-80.832994252999981, 35.478575935000038],
              [-80.833080252999935, 35.478643935000036],
              [-80.83316425199996, 35.47871693500008],
              [-80.833297251999966, 35.478797935000046],
              [-80.833541252999964, 35.478962936000073],
              [-80.833605252999973, 35.478997935000052],
              [-80.833794252999951, 35.479139936000024],
              [-80.833934253999985, 35.479229935000035],
              [-80.834100252999974, 35.47936293500004],
              [-80.834142252999982, 35.479408935000038],
              [-80.834188252999979, 35.479440935000071],
              [-80.834261252999966, 35.479482936000068],
              [-80.834298252999986, 35.479513936000046],
              [-80.834367252999982, 35.47954693500003],
              [-80.834408251999946, 35.479584935000048],
              [-80.834490252999956, 35.479673935000051],
              [-80.834550252999975, 35.47971093600006],
              [-80.834601253999949, 35.479751935000024],
              [-80.83465025299995, 35.47977893500007],
              [-80.834706252999979, 35.479829935000055],
              [-80.835018253999976, 35.480058935000045],
              [-80.835160253999959, 35.480196936000027],
              [-80.835272252999971, 35.480261935000044],
              [-80.835304252999947, 35.480287936000025],
              [-80.835354252999934, 35.480306936000034],
              [-80.835423252999988, 35.480313936000073],
              [-80.83548325299995, 35.480335935000028],
              [-80.835545252999964, 35.480351936000034],
              [-80.835595253999941, 35.480374935000043],
              [-80.835649253999975, 35.480409936000058],
              [-80.835908253999946, 35.480552935000048],
              [-80.835950252999965, 35.480586936000066],
              [-80.835994252999967, 35.480608935000078],
              [-80.836054252999986, 35.480623936000029],
              [-80.836130253999954, 35.480676936000066],
              [-80.836216253999964, 35.480712935000042],
              [-80.836507253999969, 35.480875936000075],
              [-80.836553253999966, 35.480908936000048],
              [-80.836609253999939, 35.480969936000065],
              [-80.836808253999948, 35.481168936000074],
              [-80.83684825399996, 35.481226936000041],
              [-80.836896253999953, 35.481269936000047],
              [-80.83694225399995, 35.481296936000035],
              [-80.836985253999956, 35.481336936000048],
              [-80.837105253999937, 35.481468936000056],
              [-80.837134253999977, 35.481520936000038],
              [-80.837172253999938, 35.481567935000044],
              [-80.837222253999983, 35.481611935000046],
              [-80.83740325499997, 35.481793936000031],
              [-80.837454253999965, 35.481851936000055],
              [-80.837496253999973, 35.481914935000077],
              [-80.837597254999935, 35.482039936000035],
              [-80.837639254999942, 35.48208293600004],
              [-80.83778625399998, 35.482267935000039],
              [-80.837858254999958, 35.482332936000034],
              [-80.837887253999952, 35.482375936000039],
              [-80.837928254999952, 35.482399936000036],
              [-80.838050254999985, 35.482431935000079],
              [-80.838213254999971, 35.482456937000052],
              [-80.838307254999961, 35.48246593600004],
              [-80.838399254999956, 35.482459936000055],
              [-80.838515254999948, 35.48246593600004],
              [-80.838624254999957, 35.482477936000066],
              [-80.838960254999961, 35.48249893600007],
              [-80.839042254999981, 35.48251093600004],
              [-80.839125254999942, 35.482515936000027],
              [-80.839327253999954, 35.482526935000067],
              [-80.839430254999968, 35.482520936000071],
              [-80.839523253999971, 35.482519935000028],
              [-80.839631254999972, 35.482523936000064],
              [-80.839851253999939, 35.482523935000074],
              [-80.840009254999984, 35.482545936000065],
              [-80.840060254999969, 35.482548936000057],
              [-80.840167254999983, 35.482553936000045],
              [-80.84031125599995, 35.48254393600007],
              [-80.840481254999986, 35.482544936000068],
              [-80.840663254999981, 35.482532936000041],
              [-80.840906254999936, 35.482545935000076],
              [-80.840989254999954, 35.482542935000026],
              [-80.841077254999959, 35.482545936000065],
              [-80.841234254999961, 35.482537936000028],
              [-80.84139225499996, 35.482557936000035],
              [-80.841446255999983, 35.482571936000056],
              [-80.841562254999985, 35.482593936000058],
              [-80.84184625499995, 35.482627936000029],
              [-80.841995254999972, 35.482621936000044],
              [-80.842054254999937, 35.482635936000065],
              [-80.842115254999953, 35.482669936000036],
              [-80.842160254999953, 35.482693936000032],
              [-80.842196255999966, 35.482718936000026],
              [-80.842296255999941, 35.482805936000034],
              [-80.84235225599997, 35.482844936000049],
              [-80.84239725599997, 35.482890936000047],
              [-80.842506255999979, 35.482976936000057],
              [-80.842562254999962, 35.483035936000078],
              [-80.842621254999983, 35.483066936000057],
              [-80.842680254999948, 35.483125936000079],
              [-80.842728255999987, 35.48318193700004],
              [-80.842854255999953, 35.483269936000056],
              [-80.842866255999979, 35.483318936000046],
              [-80.842884255999934, 35.483357936000061],
              [-80.84292925699998, 35.483404936000056],
              [-80.843003256999964, 35.483451937000041],
              [-80.843111255999986, 35.483537936000062],
              [-80.843131255999936, 35.483567937000032],
              [-80.843163256999958, 35.483574935000036],
              [-80.843250255999976, 35.483661936000033],
              [-80.843292255999984, 35.483689936000076],
              [-80.843379255999935, 35.483725936000042],
              [-80.843412256999954, 35.483753936000028],
              [-80.843440255999951, 35.483789936000051],
              [-80.843474255999979, 35.483819936000032],
              [-80.843507255999953, 35.483840936000036],
              [-80.843548255999963, 35.48392093700005],
              [-80.843571256999951, 35.483949936000045],
              [-80.843601255999943, 35.483968936000053],
              [-80.843631255999981, 35.483994936000045],
              [-80.843681256999957, 35.484065936000036],
              [-80.843723256999965, 35.484126936000052],
              [-80.843754255999954, 35.484159936000026],
              [-80.843792255999972, 35.48417693600004],
              [-80.843833255999982, 35.484188936000066],
              [-80.843868255999951, 35.484215937000045],
              [-80.843917256999987, 35.484300936000068],
              [-80.843945255999984, 35.484337936000031],
              [-80.843974255999967, 35.484385936000024],
              [-80.844052256999987, 35.484478936000073],
              [-80.844081255999981, 35.484526937000055],
              [-80.844114255999955, 35.484565936000024],
              [-80.844148256999972, 35.484597937000046],
              [-80.844223255999964, 35.484743936000029],
              [-80.844290256999955, 35.484846936000054],
              [-80.844327256999975, 35.484890936000056],
              [-80.844377255999973, 35.484918936000042],
              [-80.844540256999949, 35.48509193600006],
              [-80.844673255999965, 35.485249936000059],
              [-80.844766255999957, 35.485333936000075],
              [-80.844854256999952, 35.485378936000075],
              [-80.844891256999972, 35.485406936000061],
              [-80.844924255999956, 35.485447936000071],
              [-80.844928256999935, 35.48547893600005],
              [-80.844942256999957, 35.485578937000071],
              [-80.844948256999942, 35.485693936000075],
              [-80.844965255999966, 35.485725936000051],
              [-80.845066256999985, 35.485788936000063],
              [-80.84512125599997, 35.485817936000046],
              [-80.84517025699995, 35.48583493600006],
              [-80.845294255999988, 35.485863936000044],
              [-80.845351256999948, 35.485894936000079],
              [-80.845488256999943, 35.485935936000033],
              [-80.845548255999972, 35.485959936000029],
              [-80.84560225599995, 35.485975936000045],
              [-80.845653256999981, 35.485998936000044],
              [-80.845697256999983, 35.486013936000063],
              [-80.845816256999967, 35.486028937000071],
              [-80.845869256999947, 35.486050936000026],
              [-80.845921256999986, 35.486080937000054],
              [-80.845972255999982, 35.486091936000037],
              [-80.846073256999944, 35.486093936000032],
              [-80.846119256999941, 35.486113936000038],
              [-80.846161256999949, 35.486142937000068],
              [-80.84617525699997, 35.486155937000035],
              [-80.846228255999961, 35.486230937000073],
              [-80.846261256999981, 35.486240937000048],
              [-80.846290256999964, 35.48623993700005],
              [-80.84634525599995, 35.486191936000068],
              [-80.846384256999954, 35.48617293600006],
              [-80.846624256999974, 35.486148936000063],
              [-80.846682256999941, 35.486132937000036],
              [-80.846702256999947, 35.486110936000046],
              [-80.846707257999981, 35.486087936000047],
              [-80.846719256999961, 35.48607193600003],
              [-80.846751256999937, 35.486055936000071],
              [-80.846781257999965, 35.486033936000069],
              [-80.846835256999952, 35.486023937000027],
              [-80.84692625699995, 35.486017936000053],
              [-80.847017256999948, 35.486026937000076],
              [-80.847190256999966, 35.486081936000062],
              [-80.84724525699994, 35.486087937000036],
              [-80.847304256999962, 35.486101936000068],
              [-80.847349257999952, 35.486124936000067],
              [-80.847395257999949, 35.486130936000052],
              [-80.847456256999976, 35.486125937000054],
              [-80.847498257999973, 35.486103937000053],
              [-80.847523256999978, 35.486069936000035],
              [-80.847544256999981, 35.486029936000079],
              [-80.847590257999968, 35.485987936000072],
              [-80.847653256999934, 35.485981937000076],
              [-80.847691256999951, 35.485997936000047],
              [-80.847742257999982, 35.486077936000072],
              [-80.847801257999947, 35.486142937000068],
              [-80.847813257999974, 35.486185936000027],
              [-80.847854257999984, 35.48623593700006],
              [-80.847942256999943, 35.486290936000046],
              [-80.847993257999974, 35.486328936000064],
              [-80.848082256999987, 35.486343936000026],
              [-80.848133257999962, 35.486359936000042],
              [-80.848159256999963, 35.486384936000036],
              [-80.848195256999986, 35.486423936000051],
              [-80.848214257999985, 35.486458936000076],
              [-80.848232258999985, 35.486474936000036],
              [-80.848290257999963, 35.486551937000058],
              [-80.848346257999935, 35.486642936000067],
              [-80.848354256999983, 35.486710936000065],
              [-80.848356257999967, 35.486779937000051],
              [-80.848403257999962, 35.486807937000037],
              [-80.84844525799997, 35.486817937000069],
              [-80.848499256999958, 35.486849936000056],
              [-80.848526257999936, 35.486875936000047],
              [-80.848560257999964, 35.486898936000046],
              [-80.848603257999969, 35.486915937000049],
              [-80.848626256999978, 35.486935937000055],
              [-80.848641257999986, 35.486959936000062],
              [-80.848646256999984, 35.486996936000025],
              [-80.848640257999989, 35.48703193700004],
              [-80.848646257999974, 35.487049936000062],
              [-80.848685256999943, 35.487084936000031],
              [-80.84877225799994, 35.487084936000031],
              [-80.848847256999989, 35.48705493600005],
              [-80.848958256999936, 35.487038936000033],
              [-80.849009258999956, 35.487053937000042],
              [-80.84903825799995, 35.487084936000031],
              [-80.849107257999947, 35.487273937000055],
              [-80.849134257999935, 35.48732093600006],
              [-80.849176257999943, 35.487354937000077],
              [-80.849226258999977, 35.487375936000035],
              [-80.849378257999945, 35.487425936000079],
              [-80.849420257999952, 35.487461936000045],
              [-80.849465257999952, 35.487573936000047],
              [-80.849383257999989, 35.487761937000073],
              [-80.849281258999952, 35.487989936000076],
              [-80.849345258999961, 35.488633937000031],
              [-80.849350257999959, 35.488675937000039],
              [-80.84933125799995, 35.489115937000065],
              [-80.849320257999977, 35.489542937000067],
              [-80.849236257999962, 35.491938937000043],
              [-80.849182258999974, 35.493682938000063],
              [-80.848594257999935, 35.493694937000043],
              [-80.848194257999978, 35.493694937000043],
              [-80.84811125799996, 35.493687938000051],
              [-80.847999257999959, 35.493667938000044],
              [-80.847889257999952, 35.493631938000078],
              [-80.847314257999983, 35.493354938000039],
              [-80.846881257999939, 35.493145938000055],
              [-80.846851257999958, 35.49313993800007],
              [-80.84682825799996, 35.493140938000067],
              [-80.846796257999983, 35.493149938000045],
              [-80.846769257999938, 35.493165938000061],
              [-80.845728256999962, 35.494502938000039],
              [-80.845054256999958, 35.495374938000054],
              [-80.844980255999985, 35.495470938000039],
              [-80.844893256999967, 35.495583938000038],
              [-80.844596256999978, 35.495976938000069],
              [-80.84450525799997, 35.496083938000027],
              [-80.844473256999947, 35.496120938000047],
              [-80.844087255999966, 35.496633939000048],
              [-80.843569256999956, 35.49729693900008],
              [-80.843483256999946, 35.497399938000058],
              [-80.842718256999945, 35.496825939000075],
              [-80.841751255999952, 35.496125939000024],
              [-80.841637255999956, 35.496058938000033],
              [-80.841522255999962, 35.496001938000063],
              [-80.841426256999966, 35.495960938000053],
              [-80.841370255999948, 35.495941939000033],
              [-80.841219255999988, 35.495890938000059],
              [-80.839381254999978, 35.495329939000044],
              [-80.839268254999979, 35.495295939000073],
              [-80.837936254999988, 35.495686939000052],
              [-80.837724254999955, 35.495751939000058],
              [-80.837543254999957, 35.495806939000033],
              [-80.836793254999975, 35.496042939000063],
              [-80.836549253999976, 35.496117938000054],
              [-80.835903254999948, 35.496317939000051],
              [-80.835855254999956, 35.496333939000067],
              [-80.83569625399997, 35.496386939000047],
              [-80.835507253999936, 35.496462939000025],
              [-80.835163253999951, 35.49661893900003],
              [-80.835108253999977, 35.496644939000078],
              [-80.834996253999975, 35.49670093800006],
              [-80.834790253999984, 35.496815938000054],
              [-80.834626253999943, 35.496916939000073],
              [-80.834496254999976, 35.497004939000078],
              [-80.834366254999964, 35.497100939000063],
              [-80.834108253999943, 35.497320939000076],
              [-80.834066253999936, 35.497356940000031],
              [-80.833776253999986, 35.49763193900003],
              [-80.833718253999962, 35.497698939000031],
              [-80.833603253999968, 35.497830939000039],
              [-80.833457252999949, 35.498014939000029],
              [-80.833286252999983, 35.498240939000027],
              [-80.834748253999976, 35.497951939000075],
              [-80.836540254999989, 35.497595939000064],
              [-80.83679525499997, 35.497544939000079],
              [-80.836849254999947, 35.497647939000046]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 23,
        "SolutionID": "PI-13",
        "Shape_Length": 0.68663741747813944,
        "Shape_Area": 0.0041894555294706591
      }
    },
    {
      "type": "Feature",
      "id": 24,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.84019224299999, 35.261062888000026],
              [-80.839806243999988, 35.261086889000069],
              [-80.839372242999957, 35.26111288900006],
              [-80.838254241999948, 35.261181888000067],
              [-80.838015241999983, 35.261196889000075],
              [-80.83705624199996, 35.261260888000038],
              [-80.837058242999944, 35.261500889000047],
              [-80.837082240999962, 35.261950889000047],
              [-80.83708924299998, 35.262041889000045],
              [-80.837124241999959, 35.262424888000055],
              [-80.837196241999948, 35.26338288900007],
              [-80.837250242999971, 35.264104889000066],
              [-80.837417241999958, 35.266390890000025],
              [-80.837451241999986, 35.266837890000033],
              [-80.837469240999951, 35.267004891000056],
              [-80.837461241999961, 35.267184889000077],
              [-80.83748424199996, 35.267519890000074],
              [-80.837524242999962, 35.267875890000028],
              [-80.837563241999987, 35.268543891000036],
              [-80.837564241999985, 35.268794890000038],
              [-80.837590241999976, 35.26925889000006],
              [-80.837578242999939, 35.269552891000046],
              [-80.837567241999977, 35.269692891000034],
              [-80.837537241999939, 35.269964891000029],
              [-80.837496242999975, 35.27023489000004],
              [-80.837444242999936, 35.270501891000038],
              [-80.83737924199994, 35.270768891000046],
              [-80.837304242999949, 35.271032891000061],
              [-80.837218241999949, 35.271294892000071],
              [-80.83699524299999, 35.271875891000036],
              [-80.83666524299997, 35.27272589100005],
              [-80.836870242999964, 35.272734891000027],
              [-80.837246241999935, 35.272751891000041],
              [-80.837837243999957, 35.272817892000035],
              [-80.839684242999965, 35.272925891000057],
              [-80.840876243999958, 35.272995891000051],
              [-80.841811243999985, 35.273038891000056],
              [-80.842371243999935, 35.273070892000078],
              [-80.842591244999937, 35.273079892000055],
              [-80.842810243999963, 35.273083891000056],
              [-80.843165243999977, 35.273082891000058],
              [-80.843383244999984, 35.273077891000071],
              [-80.843818244999966, 35.273053890000028],
              [-80.844275243999959, 35.273008891000075],
              [-80.844503244999942, 35.272979891000034],
              [-80.844687243999942, 35.272951892000037],
              [-80.844846244999985, 35.272927891000052],
              [-80.84495824399994, 35.272910891000038],
              [-80.846745244999966, 35.272572891000038],
              [-80.846734244999936, 35.272024890000068],
              [-80.846727244999954, 35.27175789100005],
              [-80.846704244999955, 35.271462891000056],
              [-80.84666124599994, 35.271169890000067],
              [-80.846575245999986, 35.270757891000073],
              [-80.846531244999937, 35.270579890000079],
              [-80.846377245999975, 35.270149890000027],
              [-80.846230244999958, 35.269808891000025],
              [-80.846160244999965, 35.269671890000041],
              [-80.846087244999978, 35.269527890000063],
              [-80.845923244999938, 35.269240890000049],
              [-80.845772244999978, 35.269007890000069],
              [-80.845575244999964, 35.268719891000046],
              [-80.845393243999979, 35.268467890000068],
              [-80.845121244999973, 35.268084890000068],
              [-80.844913244999987, 35.267792890000067],
              [-80.844629243999975, 35.267411891000052],
              [-80.844319243999962, 35.266995889000043],
              [-80.844033243999945, 35.266602890000058],
              [-80.843340242999943, 35.265633890000061],
              [-80.842190242999948, 35.264023889000043],
              [-80.841995243999975, 35.263780889000031],
              [-80.841836242999989, 35.263548890000038],
              [-80.841636243999972, 35.263219889000027],
              [-80.841507241999977, 35.263016889000028],
              [-80.841251243999977, 35.262637889000075],
              [-80.84116024299999, 35.262472889000037],
              [-80.84107424299998, 35.262305889000061],
              [-80.840914242999986, 35.261959888000035],
              [-80.840815242999952, 35.261694889000069],
              [-80.840761242999974, 35.261510888000032],
              [-80.840736242999981, 35.261424889000068],
              [-80.840674242999967, 35.261150889000078],
              [-80.840656242999955, 35.26103488900003],
              [-80.84019224299999, 35.261062888000026]
            ]
          ],
          [
            [
              [-80.857355248999966, 35.280726892000075],
              [-80.85754824899999, 35.280738892000045],
              [-80.857688248999978, 35.280754893000051],
              [-80.857840248999935, 35.28077789200006],
              [-80.858785248999936, 35.280951892000076],
              [-80.859135249999952, 35.280997892000073],
              [-80.859338248999961, 35.281016892000025],
              [-80.860108249999939, 35.281085893000068],
              [-80.860720249999986, 35.281146892000038],
              [-80.861321249999946, 35.281229892000056],
              [-80.862457250999967, 35.281383892000065],
              [-80.862552250999954, 35.280921892000038],
              [-80.862593249999975, 35.280788892000032],
              [-80.862650250999934, 35.280668892000051],
              [-80.862748249999981, 35.280478892000076],
              [-80.862813249999988, 35.280389892000073],
              [-80.862892250999948, 35.280311891000053],
              [-80.862946249999936, 35.280269892000035],
              [-80.863055250999935, 35.280203892000031],
              [-80.863233249999951, 35.280129892000048],
              [-80.863284251999971, 35.280102892000059],
              [-80.863375250999979, 35.28003889200005],
              [-80.863460250999935, 35.279950892000045],
              [-80.863657251999939, 35.27972389100006],
              [-80.863946250999959, 35.279403892000062],
              [-80.864626250999947, 35.278647892000038],
              [-80.864958251999951, 35.278270891000034],
              [-80.865333251999971, 35.277860891000046],
              [-80.865633250999963, 35.277532891000078],
              [-80.865678250999963, 35.277475892000041],
              [-80.865934251999988, 35.277196892000063],
              [-80.866326250999975, 35.276752891000058],
              [-80.866434251999976, 35.276632891000077],
              [-80.866537250999954, 35.276504890000069],
              [-80.866580250999959, 35.276435891000062],
              [-80.866631251999934, 35.276324891000058],
              [-80.866662251999969, 35.276208891000067],
              [-80.866686251999965, 35.276026891000072],
              [-80.866996250999989, 35.276060891000043],
              [-80.866934251999965, 35.275964891000058],
              [-80.866862251999976, 35.275898891000054],
              [-80.866739250999956, 35.275773891000028],
              [-80.86669225199995, 35.275657891000037],
              [-80.866671251999946, 35.275439891000076],
              [-80.866615251999974, 35.275327891000074],
              [-80.866537250999954, 35.275071891000039],
              [-80.866515250999953, 35.274981891000039],
              [-80.866485251999961, 35.274785891000079],
              [-80.866458250999983, 35.274665891000041],
              [-80.866429250999943, 35.274579890000041],
              [-80.866371250999975, 35.274524890000066],
              [-80.866221250999956, 35.274270891000072],
              [-80.866191250999975, 35.274227890000077],
              [-80.866145250999978, 35.274182891000066],
              [-80.866084250999961, 35.274050891000059],
              [-80.86606225099996, 35.273985891000052],
              [-80.865939251999976, 35.27379189100003],
              [-80.86589625199997, 35.273738890000061],
              [-80.865789250999967, 35.273583890000054],
              [-80.86578824999998, 35.273553891000063],
              [-80.865794250999954, 35.273511890000066],
              [-80.865811250999968, 35.273490890000062],
              [-80.865815250999958, 35.273462890000076],
              [-80.865801250999937, 35.273429890000045],
              [-80.865741250999974, 35.273383890000048],
              [-80.865678250999963, 35.273353890000067],
              [-80.865628250999976, 35.273301891000074],
              [-80.865580250999983, 35.273268890000054],
              [-80.865366250999955, 35.273265891000051],
              [-80.865289250999979, 35.273012891000064],
              [-80.865278251999939, 35.272895891000076],
              [-80.865260250999938, 35.272801890000039],
              [-80.865216251999982, 35.272617891000039],
              [-80.865197251999973, 35.272502890000055],
              [-80.865200250999976, 35.272315890000073],
              [-80.865226249999978, 35.272127890000036],
              [-80.865260250999938, 35.271925890000034],
              [-80.86530725099999, 35.271759890000055],
              [-80.865345250999951, 35.271657890000029],
              [-80.865395250999939, 35.271469890000049],
              [-80.865456250999955, 35.271281889000079],
              [-80.865472250999971, 35.27120989000008],
              [-80.865648250999982, 35.270642890000033],
              [-80.865673250999976, 35.270530890000032],
              [-80.86568325099995, 35.270437890000039],
              [-80.865688249999948, 35.270126890000029],
              [-80.865668251999978, 35.269417890000057],
              [-80.865677249999976, 35.269338889000039],
              [-80.865698250999969, 35.269152889000054],
              [-80.865722250999966, 35.26894388900007],
              [-80.86685825099994, 35.268727889000047],
              [-80.869100251999953, 35.268292890000055],
              [-80.869582251999987, 35.268192889000034],
              [-80.870035252999969, 35.268090889000064],
              [-80.869967251999981, 35.267909888000077],
              [-80.86989625199999, 35.267722889000026],
              [-80.869833251999978, 35.267643890000045],
              [-80.869821251999952, 35.267605890000027],
              [-80.869812251999974, 35.267535889000044],
              [-80.869777251999949, 35.267446889000041],
              [-80.869760252999981, 35.267350889000056],
              [-80.86975825199994, 35.267275890000064],
              [-80.869780252999988, 35.267167888000074],
              [-80.869788251999978, 35.267019889000039],
              [-80.869783251999934, 35.266987889000063],
              [-80.869693251999934, 35.266783889000067],
              [-80.869669251999937, 35.266544889000045],
              [-80.86966825199994, 35.26641188800005],
              [-80.869658251999965, 35.266369889000032],
              [-80.869642252999938, 35.266327889000024],
              [-80.869631251999976, 35.266278889000034],
              [-80.869571251999957, 35.266187888000047],
              [-80.869555251999941, 35.26613788800006],
              [-80.869541251999976, 35.266049889000044],
              [-80.869486251999945, 35.265975888000071],
              [-80.869451252999966, 35.265916888000049],
              [-80.869434250999973, 35.265870888000052],
              [-80.869418251999946, 35.265759888000048],
              [-80.869381251999982, 35.265687889000048],
              [-80.869380251999985, 35.265593889000058],
              [-80.869371251999951, 35.265549888000066],
              [-80.869348251999952, 35.265502889000061],
              [-80.869326250999961, 35.265419889000043],
              [-80.869326250999961, 35.265374888000053],
              [-80.869318251999971, 35.265336889000025],
              [-80.869303251999952, 35.265309889000036],
              [-80.86928825199999, 35.265295889000072],
              [-80.869209251999962, 35.265201889000025],
              [-80.869112251999979, 35.265103889000045],
              [-80.869070251999972, 35.265036889000044],
              [-80.869037251999941, 35.264963889000057],
              [-80.869006250999973, 35.264942888000064],
              [-80.86897025199994, 35.26490988900008],
              [-80.868849250999972, 35.264853889000051],
              [-80.868796250999935, 35.264833889000045],
              [-80.868773250999936, 35.26483588800005],
              [-80.868721250999954, 35.264724889000036],
              [-80.868695251999952, 35.264319888000045],
              [-80.868715251999959, 35.264266889000055],
              [-80.868793250999943, 35.264115888000049],
              [-80.868824251999968, 35.263999889000047],
              [-80.868473250999955, 35.264011888000027],
              [-80.86842225099997, 35.264013889000069],
              [-80.867313250999985, 35.264040889000057],
              [-80.86724325199998, 35.264053888000035],
              [-80.866913250999971, 35.264100889000076],
              [-80.866790250999941, 35.264122888000031],
              [-80.866546249999942, 35.264179888000058],
              [-80.866297250999935, 35.264257888000031],
              [-80.865708249999955, 35.264463889000069],
              [-80.865626250999981, 35.264488889000063],
              [-80.865455250999958, 35.264527889000078],
              [-80.865324249999958, 35.264550888000031],
              [-80.865124249999951, 35.264572888000032],
              [-80.865013249999947, 35.264578889000063],
              [-80.864811249999946, 35.264575888000024],
              [-80.864624249999963, 35.26455788800007],
              [-80.863693249999983, 35.264426889000049],
              [-80.863081249999937, 35.264345889000026],
              [-80.860532249999949, 35.264100889000076],
              [-80.859870247999936, 35.264040889000057],
              [-80.858682248999969, 35.263924889000066],
              [-80.857430247999957, 35.26380688900008],
              [-80.857692247999978, 35.262582888000054],
              [-80.857158248999951, 35.262660888000028],
              [-80.856955246999973, 35.262689888000068],
              [-80.855970246999959, 35.262823888000071],
              [-80.854718246999937, 35.262998889000073],
              [-80.854458246999968, 35.264245889000051],
              [-80.854225246999988, 35.265340889000072],
              [-80.854201246999935, 35.265458890000048],
              [-80.853960246999975, 35.266610890000038],
              [-80.853941246999966, 35.266772889000038],
              [-80.85395424699999, 35.267322890000059],
              [-80.853971246999947, 35.268047890000048],
              [-80.853984247999961, 35.268284889000029],
              [-80.854016246999947, 35.268864889000042],
              [-80.854111247999981, 35.270106891000069],
              [-80.854190247999952, 35.271147890000066],
              [-80.854205246999982, 35.271349891000057],
              [-80.854259247999948, 35.271958891000054],
              [-80.854275247999965, 35.272187890000055],
              [-80.854354247999936, 35.272985891000076],
              [-80.854380247999984, 35.273222891000046],
              [-80.854499247999968, 35.274249891000068],
              [-80.854539246999934, 35.274445891000028],
              [-80.854589247999968, 35.274633891000065],
              [-80.854659247999962, 35.274838891000059],
              [-80.854830247999985, 35.275204891000044],
              [-80.854863248999948, 35.275293891000047],
              [-80.855270247999954, 35.276378891000036],
              [-80.855283247999978, 35.276413891000061],
              [-80.855336247999958, 35.276576891000047],
              [-80.855390248999981, 35.276764892000074],
              [-80.855885248999982, 35.278528892000054],
              [-80.856522247999976, 35.280760892000046],
              [-80.856652248999978, 35.28074489200003],
              [-80.857049248999942, 35.280723892000026],
              [-80.857179248999955, 35.280722892000028],
              [-80.857355248999966, 35.280726892000075]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 24,
        "SolutionID": "PI-13",
        "Shape_Length": 0.10273233292782799,
        "Shape_Area": 0.00028156932988079538
      }
    },
    {
      "type": "Feature",
      "id": 25,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.786112233999972, 35.381666916000029],
            [-80.786140232999969, 35.381821916000035],
            [-80.786258232999955, 35.382450917000028],
            [-80.786374233999936, 35.38306491600008],
            [-80.786556232999942, 35.383994917000052],
            [-80.786603233999983, 35.384203917000036],
            [-80.786639233999949, 35.38431891700003],
            [-80.786700233999966, 35.384445916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.788118233999967, 35.383049916000061],
            [-80.788435233999962, 35.382791916000031],
            [-80.788806233999935, 35.382512917000042],
            [-80.788673234999976, 35.382364916000029],
            [-80.788323233999961, 35.382013917000052],
            [-80.788210233999962, 35.381930916000044],
            [-80.788088233999986, 35.381868917000077],
            [-80.787945233999949, 35.381812917000047],
            [-80.787829233999958, 35.381777916000033],
            [-80.787695233999955, 35.381757916000026],
            [-80.787321232999943, 35.381732917000079],
            [-80.787354233999963, 35.381476917000043],
            [-80.787439232999986, 35.380923917000075],
            [-80.787469232999968, 35.380810917000076],
            [-80.787508232999983, 35.380710916000055],
            [-80.787727233999988, 35.380317916000024],
            [-80.787834233999945, 35.380178916000034],
            [-80.787908233999985, 35.380093916000078],
            [-80.788009233999958, 35.379998916000034],
            [-80.788090233999981, 35.37992891600004],
            [-80.788184233999971, 35.379866915000036],
            [-80.788461234999943, 35.379717916000061],
            [-80.788380233999987, 35.379580915000076],
            [-80.788296233999972, 35.379456916000038],
            [-80.788257233999957, 35.379368916000033],
            [-80.788227233999976, 35.379285916000072],
            [-80.788225233999981, 35.379225916000053],
            [-80.788340233999975, 35.378968916000076],
            [-80.789500232999956, 35.376610915000072],
            [-80.789552234999974, 35.376530915000046],
            [-80.789612233999947, 35.376476915000069],
            [-80.789875234999954, 35.376303915000051],
            [-80.790028233999976, 35.376196914000047],
            [-80.790190233999965, 35.376067915000078],
            [-80.789804233999973, 35.375845914000024],
            [-80.789889233999986, 35.375686916000063],
            [-80.790138234999972, 35.375176915000054],
            [-80.790268233999939, 35.374897915000076],
            [-80.790462233999961, 35.374511915000028],
            [-80.790615233999972, 35.374188914000058],
            [-80.790985233999947, 35.373388914000031],
            [-80.791253233999953, 35.373446914000056],
            [-80.791601234999973, 35.373552914000072],
            [-80.791984234999973, 35.373705915000073],
            [-80.792397234999953, 35.373879914000042],
            [-80.792819234999968, 35.374084915000026],
            [-80.793234234999943, 35.374280915000043],
            [-80.793300234999947, 35.37415091500003],
            [-80.793362234999961, 35.374043914000026],
            [-80.793407234999961, 35.373948914000039],
            [-80.79347523499996, 35.373829915000044],
            [-80.793616233999956, 35.373562914000047],
            [-80.793684234999944, 35.373454915000025],
            [-80.793838234999953, 35.373247914000046],
            [-80.79392923499995, 35.373298914000031],
            [-80.794324234999976, 35.373487914000066],
            [-80.794597235999959, 35.373596915000064],
            [-80.795023234999974, 35.373707914000079],
            [-80.795480235999946, 35.373809915000038],
            [-80.795690235999984, 35.373861914000031],
            [-80.79575723499994, 35.37386891400007],
            [-80.795836235999957, 35.373865914000078],
            [-80.795928235999952, 35.373853915000041],
            [-80.796176235999951, 35.373786915000039],
            [-80.796212235999974, 35.373861914000031],
            [-80.796271235999939, 35.373937914000066],
            [-80.796316235999939, 35.373975914000027],
            [-80.796381235999945, 35.374014914000043],
            [-80.796468236999942, 35.37404891500006],
            [-80.796554235999963, 35.374076914000057],
            [-80.796931235999978, 35.374166915000046],
            [-80.797451236999962, 35.374244915000077],
            [-80.797468235999986, 35.374094914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797446236999974, 35.373914914000068],
            [-80.797416235999947, 35.373811914000044],
            [-80.797039235999989, 35.372922914000071],
            [-80.796997235999982, 35.372774914000047],
            [-80.796976235999978, 35.372682915000041],
            [-80.796966234999957, 35.372515914000076],
            [-80.796959235999964, 35.37238691400006],
            [-80.796958235999966, 35.372212914000045],
            [-80.796933235999973, 35.372133914000074],
            [-80.796915235999961, 35.372047913000074],
            [-80.796870235999961, 35.371958914000061],
            [-80.796635235999986, 35.371587914000031],
            [-80.796389234999936, 35.371225914000036],
            [-80.796325234999983, 35.371120914000073],
            [-80.796280235999973, 35.371011914000064],
            [-80.796123235999971, 35.370414914000037],
            [-80.796087234999959, 35.370324914000037],
            [-80.796038234999969, 35.370239914000024],
            [-80.795949234999966, 35.370134913000072],
            [-80.79568123599995, 35.369461914000055],
            [-80.794869234999965, 35.369124913000064],
            [-80.792751234999969, 35.368284913000025],
            [-80.787396232999981, 35.366161913000042],
            [-80.787087232999966, 35.367248913000026],
            [-80.786982232999947, 35.367620913000053],
            [-80.786934232999954, 35.367773913000065],
            [-80.786891231999959, 35.36788791400005],
            [-80.786838232999969, 35.368000914000049],
            [-80.786786232999987, 35.368091913000058],
            [-80.786619231999964, 35.368341913000052],
            [-80.785950232999937, 35.369276914000068],
            [-80.785749231999944, 35.369558914000038],
            [-80.784008231999962, 35.368592913000043],
            [-80.783537231999958, 35.368146913000032],
            [-80.782912231999944, 35.367798913000058],
            [-80.782640230999959, 35.367689914000039],
            [-80.781677230999946, 35.367661914000053],
            [-80.781256230999986, 35.367630914000074],
            [-80.780933230999949, 35.367370913000059],
            [-80.780488229999946, 35.366631914000038],
            [-80.780197229999942, 35.36619591300007],
            [-80.779706229999988, 35.36613991300004],
            [-80.779152230999955, 35.366084913000066],
            [-80.778664230999937, 35.366043913000055],
            [-80.776648228999989, 35.365847913000039],
            [-80.776320228999964, 35.365820914000039],
            [-80.775710229999959, 35.36577991300004],
            [-80.775482228999977, 35.365757913000039],
            [-80.775336229999937, 35.365732913000045],
            [-80.775250228999937, 35.365712913000038],
            [-80.774650228999974, 35.365542914000059],
            [-80.774423227999989, 35.36549391300008],
            [-80.774049228999957, 35.365441913000041],
            [-80.773116228999982, 35.365335913000024],
            [-80.772988227999974, 35.365326913000047],
            [-80.772866227999941, 35.365326914000036],
            [-80.772812228999953, 35.365330914000026],
            [-80.772661228999937, 35.365352914000027],
            [-80.772446228999968, 35.365396913000041],
            [-80.770884227999943, 35.365766913000073],
            [-80.770123227999989, 35.365954914000042],
            [-80.769787226999938, 35.366047914000035],
            [-80.76960222799994, 35.366118913000037],
            [-80.769464227999947, 35.366182913000046],
            [-80.769340227999976, 35.366247914000041],
            [-80.768295227999943, 35.366863914000078],
            [-80.768092226999954, 35.366976914000077],
            [-80.767911225999967, 35.367071914000064],
            [-80.767828227999985, 35.367105914000035],
            [-80.767676226999981, 35.367142914000055],
            [-80.76760522699999, 35.367149914000038],
            [-80.767477227999962, 35.367151914000033],
            [-80.767359226999986, 35.367145914000048],
            [-80.765904225999975, 35.367002914000068],
            [-80.765727225999967, 35.366977914000074],
            [-80.765617225999961, 35.366952913000034],
            [-80.76552722699995, 35.366920914000048],
            [-80.765436226999952, 35.366877913000053],
            [-80.764936225999975, 35.36660091400006],
            [-80.764779225999973, 35.366523914000027],
            [-80.764603225999963, 35.366443914000058],
            [-80.764351225999974, 35.366337914000042],
            [-80.764274225999941, 35.366302914000073],
            [-80.763791225999967, 35.366083914000058],
            [-80.763255225999956, 35.365860913000063],
            [-80.763102224999955, 35.365778914000032],
            [-80.762929225999983, 35.365667914000028],
            [-80.762793224999939, 35.365556914000024],
            [-80.762722224999948, 35.365481914000043],
            [-80.762653225999941, 35.36538891400005],
            [-80.762598225999966, 35.365282913000044],
            [-80.762531224999975, 35.365098914000043],
            [-80.762399225999957, 35.364663914000062],
            [-80.762242224999966, 35.364177913000049],
            [-80.762181225999939, 35.364017914000044],
            [-80.762039224999967, 35.363690914000074],
            [-80.761912224999946, 35.363455913000053],
            [-80.761840224999958, 35.363350913000033],
            [-80.761664224999947, 35.363119914000038],
            [-80.761541224999974, 35.362979913000061],
            [-80.761369224999953, 35.36280791400003],
            [-80.761256225999944, 35.362716914000032],
            [-80.761053223999966, 35.36256291400008],
            [-80.760682224999982, 35.362355913000044],
            [-80.760563224999942, 35.362297913000077],
            [-80.76038022399996, 35.362194913000053],
            [-80.759907224999949, 35.361808913000061],
            [-80.759740223999984, 35.361682914000028],
            [-80.759207224999955, 35.361249914000041],
            [-80.758940223999957, 35.361012913000025],
            [-80.758721223999942, 35.360775913000055],
            [-80.758559223999953, 35.360563913000078],
            [-80.758415223999975, 35.360318913000071],
            [-80.758401223999954, 35.360285913000041],
            [-80.758388224999976, 35.360266913000032],
            [-80.757704223999951, 35.360475913000073],
            [-80.757533222999939, 35.360520912000027],
            [-80.757423223999979, 35.360537912000041],
            [-80.757268222999983, 35.360549913000057],
            [-80.756832223999936, 35.360563913000078],
            [-80.756649223999943, 35.360569913000063],
            [-80.756503222999982, 35.360583913000028],
            [-80.75640322299995, 35.360609913000076],
            [-80.756330222999964, 35.360633914000061],
            [-80.75626822299995, 35.360658913000066],
            [-80.756222222999952, 35.360682913000062],
            [-80.75612122299998, 35.360745913000073],
            [-80.756041222999954, 35.360804913000038],
            [-80.75597522299995, 35.360866913000052],
            [-80.755718223999963, 35.360724914000059],
            [-80.755590222999956, 35.360666913000045],
            [-80.755456222999953, 35.360628913000028],
            [-80.755302222999944, 35.360604913000032],
            [-80.75463922299997, 35.360549913000057],
            [-80.754525222999973, 35.360543913000072],
            [-80.754520222999986, 35.360687914000039],
            [-80.754445222999948, 35.361927914000034],
            [-80.754373222999959, 35.363388914000041],
            [-80.754709222999963, 35.363627913000073],
            [-80.75481122299999, 35.363763913000071],
            [-80.754941221999957, 35.363916914000072],
            [-80.755005222999955, 35.364166914000066],
            [-80.755038221999939, 35.36427791400007],
            [-80.755056221999951, 35.364338914000029],
            [-80.755062222999982, 35.364603914000043],
            [-80.755090223999957, 35.364716914000041],
            [-80.75514222299995, 35.364799914000059],
            [-80.755149223999979, 35.364936914000054],
            [-80.755170222999936, 35.365088914000069],
            [-80.755338222999967, 35.365428914000063],
            [-80.755442222999989, 35.365704914000048],
            [-80.755537223999966, 35.365821914000037],
            [-80.755594222999946, 35.365982914000028],
            [-80.755632223999953, 35.366065914000046],
            [-80.755678223999951, 35.366151915000046],
            [-80.755771222999954, 35.36626691500004],
            [-80.755891223999981, 35.366431914000032],
            [-80.755893223999976, 35.366526914000076],
            [-80.755928223999945, 35.366606914000045],
            [-80.755973222999955, 35.366680914000028],
            [-80.756004222999934, 35.366751915000066],
            [-80.756075223999972, 35.366873915000042],
            [-80.756172223999954, 35.366943914000046],
            [-80.756209223999974, 35.366981915000054],
            [-80.756353223999952, 35.367071914000064],
            [-80.756600223999953, 35.36723891500003],
            [-80.756656223999983, 35.367285914000036],
            [-80.756918222999957, 35.367606914000078],
            [-80.757116223999958, 35.36788391500005],
            [-80.757128223999985, 35.367914915000028],
            [-80.75713022299999, 35.36793291500004],
            [-80.757405223999967, 35.36827091400005],
            [-80.757465223999986, 35.368398915000057],
            [-80.75752722499999, 35.368468915000051],
            [-80.757556223999984, 35.368509915000061],
            [-80.757563223999966, 35.368599915000061],
            [-80.757627224999965, 35.368670914000063],
            [-80.75781322399996, 35.368701915000031],
            [-80.757893223999986, 35.368696914000054],
            [-80.758075223999981, 35.368732914000077],
            [-80.758158223999942, 35.368763915000045],
            [-80.758373223999968, 35.368795914000032],
            [-80.758500223999988, 35.36880691500005],
            [-80.758573223999974, 35.368816915000025],
            [-80.758640223999976, 35.368830914000057],
            [-80.758769223999934, 35.368869915000062],
            [-80.758954224999968, 35.368898915000045],
            [-80.759024223999972, 35.368915915000059],
            [-80.759060224999985, 35.368933914000024],
            [-80.759082224999986, 35.368953915000077],
            [-80.759270224999966, 35.369060914000045],
            [-80.75946722499998, 35.369187915000055],
            [-80.759671224999977, 35.369391914000062],
            [-80.759725224999954, 35.369426915000076],
            [-80.759763224999972, 35.369443915000033],
            [-80.759800224999935, 35.369466915000032],
            [-80.760029223999936, 35.369566915000064],
            [-80.760069223999949, 35.369579915000031],
            [-80.76013622499994, 35.369592915000055],
            [-80.760203224999941, 35.369596915000045],
            [-80.760250223999947, 35.369603915000027],
            [-80.760481224999978, 35.369699915000069],
            [-80.760775224999975, 35.369846915000039],
            [-80.760808224999948, 35.369863914000064],
            [-80.760897224999951, 35.369889915000044],
            [-80.760979224999971, 35.369888914000057],
            [-80.76105122499996, 35.369901915000071],
            [-80.761150225999984, 35.36992891500006],
            [-80.761342224999964, 35.369992915000068],
            [-80.761660225999947, 35.370065915000055],
            [-80.761720224999976, 35.370068915000047],
            [-80.761893225999984, 35.370103915000072],
            [-80.761994225999956, 35.37011691500004],
            [-80.762036224999974, 35.370127916000058],
            [-80.762086225999951, 35.370152914000073],
            [-80.762111225999945, 35.370176914000069],
            [-80.762137225999936, 35.370217914000079],
            [-80.762172225999961, 35.370256915000027],
            [-80.762195224999971, 35.370295914000053],
            [-80.762226225999939, 35.370310915000061],
            [-80.762369224999986, 35.370354914000075],
            [-80.762468224999964, 35.370375915000068],
            [-80.762522225999987, 35.37037491500007],
            [-80.762567225999987, 35.370380915000055],
            [-80.762605224999959, 35.370393915000079],
            [-80.762648225999953, 35.37042391600005],
            [-80.762773225999979, 35.370486915000072],
            [-80.76296122499997, 35.370538915000054],
            [-80.763049225999964, 35.370556915000066],
            [-80.763125225999943, 35.370576915000072],
            [-80.763224226999967, 35.370609915000045],
            [-80.763414226999942, 35.370660914000041],
            [-80.763483225999948, 35.370686915000078],
            [-80.763541225999973, 35.370700914000054],
            [-80.763616225999954, 35.370697914000061],
            [-80.763671225999985, 35.37070591500003],
            [-80.763711225999941, 35.370743915000048],
            [-80.763827225999989, 35.370872914000074],
            [-80.763881225999967, 35.370921915000054],
            [-80.763912225999945, 35.370980915000075],
            [-80.763903225999968, 35.371018915000036],
            [-80.763886225999954, 35.371059915000046],
            [-80.763878224999985, 35.371099915000059],
            [-80.763882226999954, 35.371151915000041],
            [-80.763915225999938, 35.371205914000029],
            [-80.763976225999954, 35.371277915000064],
            [-80.764044225999953, 35.371343914000079],
            [-80.764154226999949, 35.371460915000057],
            [-80.764211225999986, 35.371503915000062],
            [-80.764296226999988, 35.371544915000072],
            [-80.764330226999959, 35.371550915000057],
            [-80.764427225999952, 35.371551916000044],
            [-80.764488226999958, 35.371544915000072],
            [-80.764540225999951, 35.371543915000075],
            [-80.764691225999968, 35.371522915000071],
            [-80.764762225999959, 35.371548915000062],
            [-80.764831225999956, 35.371563915000024],
            [-80.764963225999963, 35.371613915000069],
            [-80.765090225999984, 35.371671916000025],
            [-80.765149225999949, 35.371671916000025],
            [-80.765184225999974, 35.37164791500004],
            [-80.765318226999966, 35.371540915000026],
            [-80.76539622599995, 35.371489915000041],
            [-80.765489225999943, 35.371453915000075],
            [-80.76552722699995, 35.371445915000038],
            [-80.765597226999944, 35.371444915000041],
            [-80.765688225999952, 35.371456916000056],
            [-80.765857226999969, 35.371497915000077],
            [-80.76595122699996, 35.371494915000028],
            [-80.766039226999965, 35.371445915000038],
            [-80.766243226999961, 35.371285915000044],
            [-80.766299225999944, 35.371269915000028],
            [-80.766312226999958, 35.371540914000036],
            [-80.766312225999968, 35.371577915000046],
            [-80.766310226999963, 35.371690916000034],
            [-80.766302226999983, 35.371739915000035],
            [-80.766222226999957, 35.37187691500003],
            [-80.76599622599997, 35.372144915000035],
            [-80.765877226999976, 35.372256916000026],
            [-80.765795225999966, 35.372326915000031],
            [-80.765646225999944, 35.372449915000061],
            [-80.765484226999945, 35.372607916000049],
            [-80.765387226999962, 35.372729915000036],
            [-80.765309226999989, 35.372870915000078],
            [-80.765275225999972, 35.372967915000061],
            [-80.765256225999963, 35.373194915000056],
            [-80.765254226999957, 35.373360916000024],
            [-80.765245225999934, 35.373606915000039],
            [-80.765234226999951, 35.373721915000033],
            [-80.765213226999947, 35.373841915000071],
            [-80.76515122699999, 35.374019915000076],
            [-80.768493227999954, 35.374972915000058],
            [-80.769319227999972, 35.375214916000061],
            [-80.76968822799995, 35.375310916000046],
            [-80.769816228999957, 35.375337916000035],
            [-80.770021228999951, 35.37536891600007],
            [-80.77019822799997, 35.375380915000051],
            [-80.770340227999952, 35.37538091600004],
            [-80.770446227999969, 35.375374916000055],
            [-80.770658227999945, 35.37534691500008],
            [-80.770769227999949, 35.375325916000065],
            [-80.773075227999982, 35.374756915000034],
            [-80.773966229999985, 35.374518915000067],
            [-80.77638922999995, 35.373801914000069],
            [-80.776559229999975, 35.373751915000071],
            [-80.777106230999948, 35.373577915000055],
            [-80.777474229999939, 35.373470914000052],
            [-80.777779229999965, 35.373371915000064],
            [-80.77790023099999, 35.373335914000052],
            [-80.778098230999944, 35.373904915000026],
            [-80.778124230999936, 35.373976915000071],
            [-80.778131229999985, 35.374045916000057],
            [-80.77813722999997, 35.374180915000068],
            [-80.778160230999958, 35.374286915000027],
            [-80.778223229999981, 35.37440091600007],
            [-80.778302230999941, 35.374474915000064],
            [-80.778374230999987, 35.374519914000075],
            [-80.778466229999935, 35.374557916000072],
            [-80.778573230999939, 35.374602915000025],
            [-80.778679230999956, 35.374647915000025],
            [-80.778920230999972, 35.374747915000057],
            [-80.779128230999959, 35.374834915000065],
            [-80.779265230999954, 35.374891915000035],
            [-80.779513229999964, 35.374995915000056],
            [-80.779710230999967, 35.375078915000074],
            [-80.779951230999984, 35.375186915000029],
            [-80.780080230999943, 35.37524591500005],
            [-80.780143230999954, 35.375298916000077],
            [-80.78029823199995, 35.375461915000074],
            [-80.780483230999948, 35.37563691500003],
            [-80.780667231999985, 35.375789915000041],
            [-80.780710230999944, 35.375820916000066],
            [-80.78062423099999, 35.375878915000044],
            [-80.780553230999942, 35.375938916000052],
            [-80.78050923099994, 35.375991916000032],
            [-80.78045623099996, 35.376073915000063],
            [-80.780035231999989, 35.377064916000052],
            [-80.780018230999985, 35.377117915000042],
            [-80.780019230999983, 35.377168916000073],
            [-80.780115230999968, 35.377669916000059],
            [-80.779945230999942, 35.377761916000054],
            [-80.779815230999986, 35.377995915000042],
            [-80.779677230999937, 35.378317916000071],
            [-80.779516230999945, 35.378698916000076],
            [-80.779502230999981, 35.378953916000057],
            [-80.779536230999952, 35.379037917000062],
            [-80.779665231999957, 35.379168916000026],
            [-80.779858231999981, 35.379297916000041],
            [-80.780334230999983, 35.379612916000042],
            [-80.780757231999985, 35.379892916000074],
            [-80.781171230999973, 35.38014991600005],
            [-80.781368231999977, 35.380199916000038],
            [-80.781625231999953, 35.380269917000078],
            [-80.782115231999967, 35.380523917000062],
            [-80.782722232999959, 35.380973917000063],
            [-80.78291623299998, 35.381855917000053],
            [-80.782924231999971, 35.381984917000068],
            [-80.783470232999946, 35.38190591700004],
            [-80.786088232999987, 35.381527916000039],
            [-80.786112233999972, 35.381666916000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 25,
        "SolutionID": "PI-13",
        "Shape_Length": 0.13027682783159319,
        "Shape_Area": 0.00035980798834289686
      }
    },
    {
      "type": "Feature",
      "id": 26,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 26,
        "SolutionID": "PI-13",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 27,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.98128427599994, 35.099731849000079],
              [-80.981229275999965, 35.099747849000039],
              [-80.980348275999972, 35.099871849000067],
              [-80.980235275999974, 35.099881849000042],
              [-80.980057275999968, 35.099919849000059],
              [-80.979861275999951, 35.099943849000056],
              [-80.979615274999958, 35.100004849000072],
              [-80.979719274999979, 35.100013850000039],
              [-80.979811275999964, 35.100026849000074],
              [-80.979883275999953, 35.100043849000031],
              [-80.979944275999969, 35.100064850000024],
              [-80.980004276999978, 35.100091850000069],
              [-80.980242274999966, 35.100276849000068],
              [-80.980272275999937, 35.100313849000031],
              [-80.980302274999985, 35.100360849000026],
              [-80.98039827499997, 35.100484849000054],
              [-80.980445274999965, 35.100534849000042],
              [-80.980506275999971, 35.100637849000066],
              [-80.980670275999955, 35.100846850000039],
              [-80.980767274999948, 35.100970849000078],
              [-80.980970275999937, 35.10120684900005],
              [-80.981194276999986, 35.101567850000038],
              [-80.981237275999945, 35.101644849000024],
              [-80.981450275999975, 35.102099850000059],
              [-80.981690276999984, 35.102573850000056],
              [-80.98188427599996, 35.102985849000049],
              [-80.981987276999973, 35.103185850000045],
              [-80.982041275999961, 35.103301850000037],
              [-80.98216227599994, 35.103493850000064],
              [-80.982339276999937, 35.103848850000077],
              [-80.982440276999966, 35.104011851000053],
              [-80.982500276999986, 35.104129850000049],
              [-80.982564275999948, 35.104240850000053],
              [-80.982731276999971, 35.104445850000047],
              [-80.982879275999949, 35.10457185100006],
              [-80.983021276999978, 35.104711851000047],
              [-80.983097276999956, 35.10476785000003],
              [-80.983196276999934, 35.104871850000052],
              [-80.983248276999973, 35.104894850000051],
              [-80.983285275999947, 35.10490285000003],
              [-80.983317277999959, 35.104917850000049],
              [-80.983346275999963, 35.104948850000028],
              [-80.983408276999967, 35.105030850000048],
              [-80.983505276999949, 35.105147850000037],
              [-80.983984276999934, 35.104920850000042],
              [-80.98551927799997, 35.104178850000039],
              [-80.988597278999976, 35.102694849000045],
              [-80.989889278999954, 35.102071849000026],
              [-80.990593278999938, 35.101729849000037],
              [-80.99134227899998, 35.101368849000039],
              [-80.992704278999952, 35.100710849000052],
              [-80.99364627999995, 35.100255849000064],
              [-80.993753279999964, 35.100204849000079],
              [-80.993901279999989, 35.100132849000033],
              [-80.995232280999971, 35.099490848000073],
              [-80.995877279999945, 35.099177848000068],
              [-80.995757280999953, 35.099076848000038],
              [-80.995633279999936, 35.099023848000058],
              [-80.995410280999977, 35.098935848000053],
              [-80.99512427999997, 35.098810848000028],
              [-80.994712279999987, 35.098648849000028],
              [-80.993709279999962, 35.098216849000039],
              [-80.993390279999971, 35.098100848000058],
              [-80.993081278999966, 35.097970848000045],
              [-80.992826278999985, 35.097856848000049],
              [-80.992619279999985, 35.097769849000031],
              [-80.992291279999961, 35.09764584800007],
              [-80.99213827899996, 35.097571849000076],
              [-80.992005278999954, 35.097516848000055],
              [-80.991844278999963, 35.097457848000033],
              [-80.991806278999945, 35.097443848000069],
              [-80.991740279999988, 35.097421848000067],
              [-80.991700278999986, 35.097407848000046],
              [-80.991488278999952, 35.097337848000052],
              [-80.991267278999942, 35.097259848000078],
              [-80.991058279999947, 35.097193848000074],
              [-80.990794277999953, 35.097117848000039],
              [-80.990565277999963, 35.09707884900007],
              [-80.990397278999978, 35.097058848000074],
              [-80.99028027899999, 35.09705384800003],
              [-80.990097277999951, 35.097032848000026],
              [-80.989853278999988, 35.097036848000073],
              [-80.98977827799996, 35.097029848000034],
              [-80.989674277999939, 35.097010849000071],
              [-80.989646278999942, 35.097006848000035],
              [-80.98977827799996, 35.096211848000053],
              [-80.989850277999949, 35.095984847000068],
              [-80.989861278999967, 35.095904847000043],
              [-80.989462277999962, 35.095862848000024],
              [-80.989366278999967, 35.095866848000071],
              [-80.989273277999985, 35.095886848000077],
              [-80.989184277999982, 35.095921848000046],
              [-80.989077277999968, 35.095984848000057],
              [-80.98893327899998, 35.096093849000056],
              [-80.988381277999963, 35.096900848000075],
              [-80.988029276999953, 35.096894848000034],
              [-80.987917277999941, 35.096889849000036],
              [-80.987839277999967, 35.096893848000036],
              [-80.987755276999962, 35.096911849000037],
              [-80.987671277999937, 35.096939848000034],
              [-80.987585277999983, 35.096974848000059],
              [-80.987366277999968, 35.097073848000036],
              [-80.98694927799994, 35.097251848000042],
              [-80.98613927699995, 35.097625849000053],
              [-80.985938276999946, 35.097722848000046],
              [-80.98565427799997, 35.097879848000048],
              [-80.985555277999936, 35.097947848000047],
              [-80.985468276999939, 35.098000849000073],
              [-80.985398276999945, 35.09801384900004],
              [-80.985378276999938, 35.098081849000039],
              [-80.985279276999961, 35.09818984900005],
              [-80.98502027699999, 35.098554848000049],
              [-80.984898277999946, 35.098706849000052],
              [-80.984834276999948, 35.098812849000069],
              [-80.984777276999978, 35.098922849000076],
              [-80.984691276999968, 35.09902284900005],
              [-80.984556276999967, 35.099142849000032],
              [-80.984402276999958, 35.09922184800007],
              [-80.984207276999939, 35.099299849000033],
              [-80.983901276999973, 35.099382849000051],
              [-80.983749276999959, 35.09941384900003],
              [-80.983502276999957, 35.099456849000035],
              [-80.98335127699994, 35.099474849000046],
              [-80.983128275999945, 35.09949184900006],
              [-80.982710276999967, 35.099552849000077],
              [-80.98237527699996, 35.099592849000032],
              [-80.981839276999949, 35.09967184900006],
              [-80.981688275999943, 35.099698849000049],
              [-80.981545276999952, 35.099715850000052],
              [-80.981473275999974, 35.099684849000027],
              [-80.981428275999974, 35.099680850000027],
              [-80.981377274999943, 35.099688849000074],
              [-80.981323275999955, 35.099710849000076],
              [-80.98128427599994, 35.099731849000079]
            ]
          ],
          [
            [
              [-80.931495262999988, 35.131622858000071],
              [-80.924902260999943, 35.133981858000027],
              [-80.925713261999988, 35.135429858000066],
              [-80.926146261999975, 35.13612985900005],
              [-80.926227260999951, 35.136259859000063],
              [-80.92677226099994, 35.137033859000042],
              [-80.927027261999967, 35.137360859000069],
              [-80.927295260999983, 35.137691859000029],
              [-80.927530261999948, 35.137962860000073],
              [-80.927753261999953, 35.138202859000046],
              [-80.92868426299998, 35.139148859000045],
              [-80.928969262999942, 35.139418859000045],
              [-80.929300262999959, 35.139707860000044],
              [-80.929602262999936, 35.139942860000076],
              [-80.929771262999964, 35.140074859000038],
              [-80.930476262999946, 35.140588860000037],
              [-80.931834263999974, 35.141499860000067],
              [-80.932066263999957, 35.141295860000071],
              [-80.932247262999965, 35.141158860000075],
              [-80.932496263999951, 35.14095585900003],
              [-80.933009262999974, 35.140556860000061],
              [-80.933474263999983, 35.140166860000079],
              [-80.934127264999972, 35.139631859000076],
              [-80.934218263999981, 35.139560860000074],
              [-80.935476264999977, 35.138576859000068],
              [-80.935974263999981, 35.138184859000035],
              [-80.936730264999937, 35.137589859000059],
              [-80.936847264999983, 35.137490859000025],
              [-80.937188264999975, 35.137220859000024],
              [-80.937226263999946, 35.137186858000064],
              [-80.937536264999949, 35.136910859000068],
              [-80.937680264999983, 35.136765858000047],
              [-80.937848264999957, 35.136576859000058],
              [-80.937941265999939, 35.136465858000065],
              [-80.937973265999972, 35.13642685800005],
              [-80.938446265999971, 35.135829858000079],
              [-80.938764264999975, 35.135389859000043],
              [-80.938940264999985, 35.135135859000059],
              [-80.939164264999988, 35.134789858000033],
              [-80.939264265999952, 35.134620858000062],
              [-80.939386264999939, 35.134417857000074],
              [-80.939603265999949, 35.134055858000067],
              [-80.940100264999955, 35.133186857000055],
              [-80.940481265999949, 35.13249485800003],
              [-80.94056726599996, 35.132340858000077],
              [-80.940614265999955, 35.132245857000044],
              [-80.940912264999952, 35.131643858000075],
              [-80.941068265999945, 35.131253858000036],
              [-80.941207265999935, 35.130926857000077],
              [-80.941269265999949, 35.130772857000068],
              [-80.94162526599996, 35.129897856000071],
              [-80.941744265999944, 35.12962785700006],
              [-80.94060826599997, 35.129416857000024],
              [-80.939998265999975, 35.12933485700006],
              [-80.939647265999952, 35.129274857000041],
              [-80.939293264999947, 35.129201857000055],
              [-80.938760265999974, 35.129092856000057],
              [-80.938458264999952, 35.128986857000029],
              [-80.938437264999948, 35.128976857000055],
              [-80.93824026499999, 35.129136857000049],
              [-80.938047264999966, 35.129265858000053],
              [-80.937934264999967, 35.129332857000065],
              [-80.937826264999956, 35.12937585700007],
              [-80.937749264999979, 35.129412857000034],
              [-80.936413263999953, 35.129883857000038],
              [-80.935803263999958, 35.130105856000057],
              [-80.935633264999979, 35.130168857000058],
              [-80.934945263999964, 35.13042985800007],
              [-80.934758263999981, 35.13049785700008],
              [-80.934245263999969, 35.130684857000062],
              [-80.934107262999987, 35.130735857000047],
              [-80.933495262999941, 35.130947857000024],
              [-80.933197263999944, 35.131054857000038],
              [-80.932688262999989, 35.131239857000025],
              [-80.932590263999941, 35.131271858000048],
              [-80.932512262999978, 35.131288857000072],
              [-80.932472263999955, 35.131293858000049],
              [-80.932444262999979, 35.131290857000067],
              [-80.931495262999988, 35.131622858000071]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 27,
        "SolutionID": "PI-13",
        "Shape_Length": 0.083822935731651893,
        "Shape_Area": 0.00017331774297955877
      }
    },
    {
      "type": "Feature",
      "id": 28,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.024136279999937, 34.927384811000024],
            [-81.024932279999973, 34.927890811000054],
            [-81.025501280999947, 34.92829781100005],
            [-81.025539279999975, 34.928337811000063],
            [-81.025093280999954, 34.928587811000057],
            [-81.025029279999956, 34.928619811000033],
            [-81.024642279999966, 34.92881181000007],
            [-81.024270279999939, 34.928972810000062],
            [-81.023771279999949, 34.929178812000032],
            [-81.023602278999988, 34.929252811000026],
            [-81.023612278999963, 34.929337811000039],
            [-81.023653278999973, 34.929448811000043],
            [-81.023726279999948, 34.929646812000044],
            [-81.023757279999984, 34.929710811000064],
            [-81.024001279999936, 34.930093811000063],
            [-81.024367279999979, 34.930668811000032],
            [-81.02484727999996, 34.931557812000051],
            [-81.026196280999955, 34.934051812000064],
            [-81.026811279999947, 34.935203813000044],
            [-81.02743228099996, 34.936360812000032],
            [-81.02777028099996, 34.936989813000025],
            [-81.028437280999981, 34.938245813000037],
            [-81.028586280999946, 34.938512813000045],
            [-81.029078281999944, 34.939436814000032],
            [-81.029416281999943, 34.940070813000034],
            [-81.030100281999978, 34.941320814000051],
            [-81.031107282999983, 34.943250813000077],
            [-81.032554282999968, 34.942715813000063],
            [-81.033798282999953, 34.942269813000053],
            [-81.034973283999989, 34.941847814000027],
            [-81.035892283999942, 34.941517813000075],
            [-81.036104283999975, 34.94144181300004],
            [-81.035261282999954, 34.940406813000038],
            [-81.033782282999937, 34.938471813000035],
            [-81.032356281999967, 34.936629812000035],
            [-81.032174282999961, 34.936395812000058],
            [-81.031547282999952, 34.935575812000025],
            [-81.030718281999953, 34.934493812000028],
            [-81.029021280999984, 34.932496811000078],
            [-81.028546279999944, 34.93191881100006],
            [-81.027373280999939, 34.930494811000074],
            [-81.027557280999986, 34.930377811000028],
            [-81.028851280999959, 34.929472811000039],
            [-81.029815281999959, 34.928844811000033],
            [-81.030823280999982, 34.928151810000031],
            [-81.028831280999952, 34.926827810000077],
            [-81.028541280999946, 34.92663581000005],
            [-81.02693728099996, 34.92558581000003],
            [-81.026309279999964, 34.925174811000034],
            [-81.02605927999997, 34.925010809000071],
            [-81.025394279999944, 34.924571810000032],
            [-81.024792279999986, 34.925188810000066],
            [-81.024597279999966, 34.925388810000072],
            [-81.024555279999959, 34.925450810000029],
            [-81.024470278999956, 34.925571811000054],
            [-81.024366279999981, 34.925765811000076],
            [-81.024287278999964, 34.925967810000031],
            [-81.02422127899996, 34.926217810000026],
            [-81.022337278999942, 34.925961810000047],
            [-81.022313278999945, 34.926004810000052],
            [-81.02222527899994, 34.926243810000074],
            [-81.022259278999968, 34.926411810000047],
            [-81.022325278999972, 34.926745811000046],
            [-81.022527278999974, 34.92672981100003],
            [-81.023930278999956, 34.927502811000068],
            [-81.02397727999994, 34.927302811000061],
            [-81.024136279999937, 34.927384811000024]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 28,
        "SolutionID": "PI-13",
        "Shape_Length": 0.056697500106665487,
        "Shape_Area": 8.1503700274978205e-5
      }
    },
    {
      "type": "Feature",
      "id": 29,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 29,
        "SolutionID": "PI-14",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 30,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.835420236999937, 35.164250868000067],
              [-80.835529235999957, 35.164258868000047],
              [-80.83623623699998, 35.164352868000037],
              [-80.836324235999939, 35.163934868000069],
              [-80.836380235999968, 35.163692867000066],
              [-80.836437235999938, 35.163493868000046],
              [-80.836483236999982, 35.163365868000028],
              [-80.836590236999939, 35.163114868000036],
              [-80.836717236999959, 35.16286986700004],
              [-80.836805236999965, 35.162735867000038],
              [-80.836913236999976, 35.162581868000075],
              [-80.837138235999987, 35.162304867000046],
              [-80.837377235999952, 35.162057867000044],
              [-80.837479235999979, 35.161960868000051],
              [-80.837835236999979, 35.161622867000062],
              [-80.838026236999951, 35.161433868000074],
              [-80.838102236999987, 35.161348867000072],
              [-80.837650235999945, 35.161076867000077],
              [-80.83755423599996, 35.160998867000046],
              [-80.837476235999986, 35.160908868000035],
              [-80.837414235999972, 35.160810867000066],
              [-80.837391235999974, 35.160758868000073],
              [-80.837360236999984, 35.160650867000072],
              [-80.837349236999955, 35.160540868000055],
              [-80.837376235999955, 35.160148868000078],
              [-80.837428236999983, 35.159356868000032],
              [-80.837439235999966, 35.159067867000033],
              [-80.837427236999986, 35.158939867000072],
              [-80.837413235999975, 35.158872867000071],
              [-80.837369235999972, 35.15874186700006],
              [-80.837339236999981, 35.158675867000056],
              [-80.837304235999966, 35.158611867000047],
              [-80.837755235999964, 35.158395867000024],
              [-80.838648236999973, 35.158025867000049],
              [-80.838868236999986, 35.157935866000059],
              [-80.838920236999968, 35.15792086600004],
              [-80.838973236999948, 35.157910867000055],
              [-80.839081236999959, 35.157902867000075],
              [-80.840304236999941, 35.157955866000066],
              [-80.840494236999973, 35.157953866000071],
              [-80.84058923799995, 35.157946866000032],
              [-80.840775236999946, 35.157919866000043],
              [-80.840868236999938, 35.157899867000026],
              [-80.841024236999942, 35.15785586700008],
              [-80.841076236999982, 35.157835867000074],
              [-80.841145236999978, 35.157805867000036],
              [-80.84134023699994, 35.15769186700004],
              [-80.841471236999951, 35.157607867000024],
              [-80.84212023799995, 35.157155866000039],
              [-80.842506237999942, 35.156884866000041],
              [-80.842593237999949, 35.156827866000071],
              [-80.842733238999983, 35.156755867000072],
              [-80.842891237999936, 35.156694866000066],
              [-80.842972236999969, 35.15667086600007],
              [-80.843704237999987, 35.156477866000046],
              [-80.843484237999974, 35.155889866000052],
              [-80.843460237999977, 35.155771866000066],
              [-80.843456237999987, 35.155675866000024],
              [-80.84346323799997, 35.155569866000064],
              [-80.843509238999957, 35.15529086600003],
              [-80.843534236999972, 35.155140866000067],
              [-80.843616237999981, 35.154618866000078],
              [-80.843619237999974, 35.154552866000074],
              [-80.84361023799994, 35.154432866000036],
              [-80.843581237999956, 35.154272865000053],
              [-80.843542237999941, 35.154146866000076],
              [-80.843503237999983, 35.154050866000034],
              [-80.843131237999955, 35.153414866000048],
              [-80.843038237999963, 35.153260866000039],
              [-80.843013237999969, 35.153211866000049],
              [-80.842979237999941, 35.153111866000074],
              [-80.842966237999974, 35.153030866000051],
              [-80.842969237999966, 35.152925865000043],
              [-80.842984237999985, 35.152848865000067],
              [-80.84309223799994, 35.152468866000049],
              [-80.843138237999938, 35.152290866000044],
              [-80.843249237999942, 35.151899865000075],
              [-80.84193223799997, 35.151670866000075],
              [-80.841672237999944, 35.151599865000037],
              [-80.841741236999951, 35.151455866000049],
              [-80.842549237999947, 35.149775865000038],
              [-80.842994236999971, 35.148872864000055],
              [-80.843244237999954, 35.148365864000027],
              [-80.84347923699994, 35.147919865000063],
              [-80.843780237999965, 35.147348864000037],
              [-80.844061236999949, 35.146810864000031],
              [-80.844121236999968, 35.146702864000076],
              [-80.844160236999983, 35.146633865000069],
              [-80.844380236999939, 35.146248864000029],
              [-80.84482223699996, 35.145417863000034],
              [-80.845092238999939, 35.144905864000066],
              [-80.843801237999969, 35.144828864000033],
              [-80.843027235999955, 35.144777864000048],
              [-80.842847236999944, 35.144748864000064],
              [-80.842805236999936, 35.144740864000028],
              [-80.842749237999953, 35.144715863000044],
              [-80.84268723699995, 35.144662864000054],
              [-80.842658236999966, 35.144629864000024],
              [-80.842594236999958, 35.144529863000059],
              [-80.842568236999966, 35.144476864000069],
              [-80.842507237999939, 35.144293864000076],
              [-80.842248235999989, 35.14344886300006],
              [-80.840838235999968, 35.143738863000067],
              [-80.841330236999966, 35.145365864000041],
              [-80.841337236999948, 35.145411864000039],
              [-80.841338236999945, 35.145456864000039],
              [-80.841330236999966, 35.145518863000063],
              [-80.841300236999984, 35.145609863000061],
              [-80.841249236999943, 35.145694864000063],
              [-80.841210236999984, 35.145738864000066],
              [-80.841120236999984, 35.145809864000057],
              [-80.840693236999982, 35.146063864000041],
              [-80.840566236999962, 35.146134864000032],
              [-80.839657235999937, 35.146661865000056],
              [-80.839548236999974, 35.146708864000061],
              [-80.839430235999941, 35.146740864000037],
              [-80.839308235999965, 35.146756865000043],
              [-80.839165236999975, 35.146759864000046],
              [-80.838989235999975, 35.146762864000038],
              [-80.838533235999989, 35.146756865000043],
              [-80.837162235999983, 35.146730865000052],
              [-80.836972235999951, 35.146734865000042],
              [-80.83686423599994, 35.146748864000074],
              [-80.836551235999934, 35.146755865000046],
              [-80.836584235999965, 35.145777864000024],
              [-80.836612235999951, 35.144952864000061],
              [-80.836178235999967, 35.144948863000025],
              [-80.835994235999976, 35.144956864000051],
              [-80.835903234999989, 35.144967863000034],
              [-80.83580223499996, 35.144985863000045],
              [-80.835074234999979, 35.145146864000026],
              [-80.833995234999975, 35.145387864000043],
              [-80.833275234999974, 35.14554886500008],
              [-80.833161234999977, 35.145556864000071],
              [-80.833078234999959, 35.145552864000024],
              [-80.832983234999972, 35.145539864000057],
              [-80.832449233999967, 35.145439864000025],
              [-80.832398234999971, 35.145418864000078],
              [-80.832187233999946, 35.145855864000055],
              [-80.831902233999983, 35.146421864000047],
              [-80.831607233999989, 35.147007865000035],
              [-80.831342234999966, 35.147532864000027],
              [-80.83104423399999, 35.148122865000062],
              [-80.83098423399997, 35.148242865000043],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.830435234999982, 35.155301866000059],
              [-80.830356232999975, 35.155412866000063],
              [-80.830202234999945, 35.155652866000025],
              [-80.830141233999939, 35.155760867000026],
              [-80.830050234999987, 35.155938867000032],
              [-80.830027233999942, 35.15598486600004],
              [-80.829903232999982, 35.156206866000048],
              [-80.82984823399994, 35.156292867000047],
              [-80.829788232999988, 35.156376866000073],
              [-80.829625233999934, 35.156577867000067],
              [-80.829515232999938, 35.156692867000061],
              [-80.82936323399997, 35.156822867000074],
              [-80.829199233999987, 35.156945867000047],
              [-80.82904423399998, 35.157041867000032],
              [-80.828915233999965, 35.157104867000044],
              [-80.828738233999957, 35.157182867000074],
              [-80.828605233999951, 35.15723386600007],
              [-80.82836223299995, 35.157301867000058],
              [-80.828249233999941, 35.157336867000026],
              [-80.827719232999982, 35.157500868000056],
              [-80.827462233999938, 35.157580867000036],
              [-80.827571232999958, 35.157854867000026],
              [-80.827650232999986, 35.15809686700004],
              [-80.827696232999983, 35.158286867000072],
              [-80.827710233999937, 35.158345867000037],
              [-80.827743233999968, 35.158551867000028],
              [-80.827770233999956, 35.158957867000026],
              [-80.827752233999945, 35.15989386800004],
              [-80.828045233999944, 35.159892867000053],
              [-80.828225234999934, 35.159899868000025],
              [-80.828348233999975, 35.159898867000038],
              [-80.828472233999946, 35.159903868000072],
              [-80.82857923399996, 35.159913868000046],
              [-80.828685233999977, 35.159928868000065],
              [-80.828893232999974, 35.159968867000032],
              [-80.829092233999972, 35.160034868000025],
              [-80.82932623399995, 35.160134867000068],
              [-80.829576233999944, 35.160249867000061],
              [-80.830326233999983, 35.160600868000074],
              [-80.83051923499994, 35.160698868000054],
              [-80.830666233999978, 35.160763867000071],
              [-80.830719234999947, 35.160781867000026],
              [-80.830844234999972, 35.160811868000053],
              [-80.830975233999936, 35.160824868000077],
              [-80.831042234999984, 35.160824867000031],
              [-80.831175233999943, 35.16081286800005],
              [-80.831240234999939, 35.160798868000029],
              [-80.831439234999948, 35.160725867000053],
              [-80.831705234999959, 35.160636867000051],
              [-80.831816233999973, 35.160605868000061],
              [-80.831933234999951, 35.160594867000043],
              [-80.832054234999987, 35.160597868000025],
              [-80.832113234999952, 35.16060686700007],
              [-80.832227234999948, 35.160634867000056],
              [-80.83228223499998, 35.16065586700006],
              [-80.832561234999957, 35.160781867000026],
              [-80.832764234999956, 35.160858868000048],
              [-80.832956234999983, 35.160938867000027],
              [-80.833034235999946, 35.160981867000032],
              [-80.833204234999982, 35.161085868000043],
              [-80.833510235999938, 35.16132286800007],
              [-80.833662234999963, 35.161419868000053],
              [-80.833830235999983, 35.161511868000048],
              [-80.834055235999983, 35.161621867000065],
              [-80.834200234999969, 35.161676868000029],
              [-80.834355235999965, 35.161727868000071],
              [-80.834599235999974, 35.161794868000072],
              [-80.835003235999977, 35.161881867000034],
              [-80.835195235999947, 35.161939867000058],
              [-80.835602235999943, 35.162090868000064],
              [-80.835212235999961, 35.162813868000057],
              [-80.835070236999968, 35.163141868000025],
              [-80.834949236999989, 35.163474868000037],
              [-80.83491223599998, 35.163608869000029],
              [-80.834895234999976, 35.163699868000037],
              [-80.834884235999937, 35.16379286800003],
              [-80.834879235999949, 35.163924868000038],
              [-80.834886235999988, 35.164255868000055],
              [-80.835201235999989, 35.164246868000077],
              [-80.835420236999937, 35.164250868000067]
            ]
          ],
          [
            [
              [-80.848621239999943, 35.160053867000045],
              [-80.848429238999984, 35.160086867000075],
              [-80.848378238999942, 35.160136867000062],
              [-80.848328239999944, 35.160222867000073],
              [-80.848265238999943, 35.160381867000069],
              [-80.84821723899995, 35.160547867000048],
              [-80.848125238999955, 35.160935867000035],
              [-80.848042238999938, 35.161174867000057],
              [-80.847805238999968, 35.162039867000033],
              [-80.847735239999963, 35.162192867000044],
              [-80.847662239999977, 35.162273867000067],
              [-80.847590239999988, 35.162335867000024],
              [-80.847472239999945, 35.162391867000053],
              [-80.847333239999955, 35.162467868000078],
              [-80.847224238999956, 35.162566867000066],
              [-80.847197239999957, 35.162688868000032],
              [-80.847276239999985, 35.162894868000024],
              [-80.847338238999953, 35.163021868000044],
              [-80.847417238999981, 35.163208867000037],
              [-80.847508239999968, 35.163490868000054],
              [-80.847533239999962, 35.163626868000051],
              [-80.847550239999975, 35.163715867000064],
              [-80.84757923899997, 35.163995868000029],
              [-80.847568239999987, 35.164178867000032],
              [-80.84753423899997, 35.16429486800007],
              [-80.847483239999974, 35.164350867000053],
              [-80.847374240999955, 35.164424868000026],
              [-80.847271239999941, 35.164481868000053],
              [-80.84714723999997, 35.164556868000034],
              [-80.846916238999938, 35.164672868000025],
              [-80.846805238999934, 35.164716868000028],
              [-80.846599238999943, 35.164768868000067],
              [-80.846483239999941, 35.164873869000076],
              [-80.846403238999983, 35.164972869000053],
              [-80.846237238999947, 35.165209867000044],
              [-80.846110238999984, 35.165433868000036],
              [-80.846002239999962, 35.16560086800007],
              [-80.845842239999968, 35.165724868000041],
              [-80.845646238999961, 35.165910868000026],
              [-80.845548238999982, 35.166031868000061],
              [-80.845303238999975, 35.16633286900003],
              [-80.844993238999962, 35.166751868000063],
              [-80.844855239999958, 35.166869868000049],
              [-80.844814238999959, 35.166901869000071],
              [-80.84473823999997, 35.166962869000031],
              [-80.844526238999947, 35.167093869000041],
              [-80.844499238999958, 35.167197868000073],
              [-80.844523238999955, 35.167270869000049],
              [-80.844571238999947, 35.167323869000029],
              [-80.844687238999938, 35.167453868000052],
              [-80.844807238999977, 35.167543868000052],
              [-80.844935239999984, 35.167614868000044],
              [-80.845010238999976, 35.167643869000074],
              [-80.845129238999959, 35.167678868000053],
              [-80.845234238999979, 35.167701869000041],
              [-80.84547323999999, 35.167770868000048],
              [-80.845704238999986, 35.167821868000033],
              [-80.846247239999968, 35.167886868000039],
              [-80.846418238999945, 35.167920868000067],
              [-80.846583239999973, 35.167979868000032],
              [-80.846710239999936, 35.168044869000028],
              [-80.84680923999997, 35.168127868000056],
              [-80.846832239999969, 35.168182869000077],
              [-80.846850239999981, 35.168322868000075],
              [-80.846921238999983, 35.168515869000032],
              [-80.846998239999948, 35.168810869000026],
              [-80.847046239999941, 35.168955869000058],
              [-80.847071238999945, 35.169083869000076],
              [-80.847036239999966, 35.169162869000047],
              [-80.847014239999965, 35.169187868000051],
              [-80.846705239999949, 35.169308869000076],
              [-80.846559238999987, 35.169401869000069],
              [-80.846480239999948, 35.169506869000031],
              [-80.846335239999974, 35.16964887000006],
              [-80.846270239999967, 35.169753869000033],
              [-80.846191239999939, 35.169845869000028],
              [-80.846141238999962, 35.169931868000049],
              [-80.846031238999956, 35.170143868000025],
              [-80.845909239999969, 35.170322869000074],
              [-80.84580823999994, 35.170482869000068],
              [-80.845607238999946, 35.17074186900004],
              [-80.845527238999978, 35.170833869000035],
              [-80.845462238999971, 35.170895869000049],
              [-80.845419238999966, 35.170975869000074],
              [-80.845377238999959, 35.171085869000024],
              [-80.845375238999964, 35.171120869000049],
              [-80.845366239999976, 35.171262869000032],
              [-80.845402239999942, 35.171350870000026],
              [-80.845493239999939, 35.171470869000075],
              [-80.845615239999972, 35.171590869000056],
              [-80.845713239999952, 35.17166886900003],
              [-80.845848239999953, 35.171757870000079],
              [-80.846308239999985, 35.172079869000072],
              [-80.846414239999945, 35.172169870000062],
              [-80.846527239999944, 35.172234869000079],
              [-80.846581238999988, 35.172306870000057],
              [-80.846597238999948, 35.172361870000032],
              [-80.846598238999945, 35.172422869000059],
              [-80.846587239999963, 35.172574870000062],
              [-80.846527238999954, 35.172756869000068],
              [-80.846521238999969, 35.172778870000059],
              [-80.846515240999963, 35.172865870000066],
              [-80.846525239999949, 35.17298087000006],
              [-80.846529239999938, 35.173151870000027],
              [-80.846519239999964, 35.173358871000062],
              [-80.846520239999961, 35.173422870000024],
              [-80.846527238999954, 35.173693870000079],
              [-80.846524239999951, 35.173891870000034],
              [-80.846525239999949, 35.173914870000033],
              [-80.846540239999968, 35.17405787000007],
              [-80.846560239999974, 35.174218870000061],
              [-80.846569239999951, 35.174387870000032],
              [-80.846572239999944, 35.174553870000068],
              [-80.84658423999997, 35.174743871000032],
              [-80.846580238999934, 35.175028870000062],
              [-80.846578238999939, 35.175133870000025],
              [-80.84658823999996, 35.175236870000049],
              [-80.846627240999965, 35.175519870000073],
              [-80.846647239999982, 35.175726870000062],
              [-80.846650240999963, 35.175872871000024],
              [-80.846634239999958, 35.176122870000029],
              [-80.846540239999968, 35.17658687100004],
              [-80.846519239999964, 35.17679787000003],
              [-80.846515240999963, 35.17696187100006],
              [-80.84649023999998, 35.177163870000072],
              [-80.846455239999955, 35.177553871000043],
              [-80.846402239999975, 35.177758871000037],
              [-80.846393240999987, 35.177791871000068],
              [-80.846257240999989, 35.178479870000047],
              [-80.84622423999997, 35.17862787100006],
              [-80.846196240999973, 35.178967871000054],
              [-80.846590239999955, 35.179050871000072],
              [-80.846894239999983, 35.179122871000061],
              [-80.848875241999963, 35.179629871000031],
              [-80.849413240999979, 35.179767871000024],
              [-80.849676241999987, 35.179830871000036],
              [-80.849830241999939, 35.179873871000041],
              [-80.850018240999987, 35.179909871000064],
              [-80.850435241999946, 35.179954870000074],
              [-80.851363241999934, 35.180022871000062],
              [-80.851201241999945, 35.178971871000044],
              [-80.851162241999987, 35.178714871000068],
              [-80.851130241999954, 35.178544871000042],
              [-80.851088240999957, 35.178278870000042],
              [-80.851057241999968, 35.177973870000073],
              [-80.851035241999966, 35.177737871000033],
              [-80.851018241999952, 35.177363871000068],
              [-80.851016240999968, 35.177188870000066],
              [-80.85102324099995, 35.176714870000069],
              [-80.851034241999969, 35.176368870000033],
              [-80.851045240999952, 35.176164871000026],
              [-80.851030240999989, 35.175930871000048],
              [-80.851042241999949, 35.175758870000038],
              [-80.851092241999936, 35.175047870000071],
              [-80.851134241999944, 35.174351870000066],
              [-80.851177241999949, 35.173800870000036],
              [-80.85121824099997, 35.173354870000026],
              [-80.851233241999978, 35.173038870000028],
              [-80.85123224199998, 35.172761870000045],
              [-80.851213241999972, 35.172469869000054],
              [-80.85116224099994, 35.172079870000061],
              [-80.851042241999949, 35.171274868000069],
              [-80.850945240999977, 35.170531870000048],
              [-80.85090723999997, 35.170224869000037],
              [-80.850734239999952, 35.168817868000076],
              [-80.85065124099998, 35.168206869000073],
              [-80.850499240999966, 35.167339868000056],
              [-80.850442240999939, 35.166706869000052],
              [-80.850399239999945, 35.165665868000076],
              [-80.850267240999983, 35.165027868000038],
              [-80.850175239999942, 35.164590867000072],
              [-80.850123240999949, 35.164422868000031],
              [-80.850053240999955, 35.164259867000055],
              [-80.849966240999947, 35.164102867000054],
              [-80.849862239999936, 35.163952868000024],
              [-80.849679239999944, 35.163732868000068],
              [-80.849584239999956, 35.163596867000024],
              [-80.849501240999984, 35.163455867000039],
              [-80.849430239999947, 35.163310868000053],
              [-80.849360240999943, 35.163123867000024],
              [-80.849317240999937, 35.162971868000056],
              [-80.849245240999949, 35.162686868000037],
              [-80.849155239999959, 35.162336868000068],
              [-80.849053239999989, 35.16193486700007],
              [-80.848916240999984, 35.161179867000044],
              [-80.848833239999976, 35.160465867000028],
              [-80.848785239999984, 35.160046867000062],
              [-80.848621239999943, 35.160053867000045]
            ]
          ],
          [
            [
              [-80.873304248999943, 35.194455873000038],
              [-80.87447224999994, 35.194872873000065],
              [-80.874775249999971, 35.194973873000038],
              [-80.875134249999974, 35.195098874000053],
              [-80.87548524999994, 35.195221873000037],
              [-80.876259248999986, 35.195511874000033],
              [-80.876885249999987, 35.195736873000044],
              [-80.877218249999942, 35.195867873000054],
              [-80.877931249999961, 35.194949872000052],
              [-80.878011249999986, 35.194845874000066],
              [-80.878146250999976, 35.194667873000071],
              [-80.879313250999985, 35.193183873000066],
              [-80.879918250999935, 35.19241687300007],
              [-80.880481250999935, 35.191700872000069],
              [-80.88131225099994, 35.190641872000072],
              [-80.881465250999952, 35.19044787200005],
              [-80.882041250999976, 35.189716872000076],
              [-80.882472251999957, 35.189159872000062],
              [-80.882548250999946, 35.189054872000042],
              [-80.883367251999971, 35.188013872000056],
              [-80.884355251999978, 35.186758871000052],
              [-80.884705251999947, 35.185164870000051],
              [-80.88444825299996, 35.18531487100006],
              [-80.884115252999948, 35.185492871000065],
              [-80.884014251999986, 35.185422871000071],
              [-80.883953251999969, 35.185385871000051],
              [-80.883872251999946, 35.185344871000041],
              [-80.882312250999973, 35.184635871000069],
              [-80.882227250999961, 35.184588871000074],
              [-80.882101250999938, 35.184528871000055],
              [-80.882050250999953, 35.184506870000064],
              [-80.881759250999949, 35.184381871000028],
              [-80.881189250999967, 35.184120871000061],
              [-80.880523250999943, 35.183824870000024],
              [-80.880010250999987, 35.183601870000075],
              [-80.879423250999935, 35.183325870000033],
              [-80.879128249999951, 35.183757871000068],
              [-80.878565249999951, 35.184541871000079],
              [-80.878478249999944, 35.184782871000039],
              [-80.878439250999975, 35.184872871000039],
              [-80.878161250999938, 35.18549887100005],
              [-80.878042249999964, 35.185752871000034],
              [-80.877749249999965, 35.186317872000075],
              [-80.87737924999999, 35.186985872000037],
              [-80.877290249999987, 35.187148871000034],
              [-80.877230249999968, 35.187237872000026],
              [-80.876935249999974, 35.187762872000064],
              [-80.876891249999971, 35.187840872000038],
              [-80.876851249999959, 35.187893871000028],
              [-80.876757249999969, 35.187990872000057],
              [-80.876657249999937, 35.18807187200008],
              [-80.876606249999952, 35.188103872000056],
              [-80.876542248999954, 35.18813687200003],
              [-80.876388249999934, 35.18845187200003],
              [-80.875493249999977, 35.189857872000061],
              [-80.873097248999954, 35.194349873000078],
              [-80.872923248999939, 35.194740873000057],
              [-80.872901249999984, 35.19477687300008],
              [-80.872884248999981, 35.194826874000057],
              [-80.872918248999952, 35.194781874000057],
              [-80.872955248999972, 35.194726874000025],
              [-80.873074248999956, 35.194511873000067],
              [-80.873119248999956, 35.194474873000047],
              [-80.87317724899998, 35.194451873000048],
              [-80.873241247999943, 35.194444873000066],
              [-80.873304248999943, 35.194455873000038]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 30,
        "SolutionID": "PI-14",
        "Shape_Length": 0.1656413586793353,
        "Shape_Area": 0.00034422756152676596
      }
    },
    {
      "type": "Feature",
      "id": 31,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 31,
        "SolutionID": "PI-15",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 32,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.84019224299999, 35.261062888000026],
              [-80.839806243999988, 35.261086889000069],
              [-80.839372242999957, 35.26111288900006],
              [-80.838254241999948, 35.261181888000067],
              [-80.838015241999983, 35.261196889000075],
              [-80.83705624199996, 35.261260888000038],
              [-80.837058242999944, 35.261500889000047],
              [-80.837082240999962, 35.261950889000047],
              [-80.83708924299998, 35.262041889000045],
              [-80.837124241999959, 35.262424888000055],
              [-80.837196241999948, 35.26338288900007],
              [-80.837250242999971, 35.264104889000066],
              [-80.837417241999958, 35.266390890000025],
              [-80.837451241999986, 35.266837890000033],
              [-80.837469240999951, 35.267004891000056],
              [-80.837461241999961, 35.267184889000077],
              [-80.83748424199996, 35.267519890000074],
              [-80.837524242999962, 35.267875890000028],
              [-80.837563241999987, 35.268543891000036],
              [-80.837564241999985, 35.268794890000038],
              [-80.837590241999976, 35.26925889000006],
              [-80.837578242999939, 35.269552891000046],
              [-80.837567241999977, 35.269692891000034],
              [-80.837537241999939, 35.269964891000029],
              [-80.837496242999975, 35.27023489000004],
              [-80.837444242999936, 35.270501891000038],
              [-80.83737924199994, 35.270768891000046],
              [-80.837304242999949, 35.271032891000061],
              [-80.837218241999949, 35.271294892000071],
              [-80.83699524299999, 35.271875891000036],
              [-80.83666524299997, 35.27272589100005],
              [-80.836870242999964, 35.272734891000027],
              [-80.837246241999935, 35.272751891000041],
              [-80.837837243999957, 35.272817892000035],
              [-80.839684242999965, 35.272925891000057],
              [-80.840876243999958, 35.272995891000051],
              [-80.841811243999985, 35.273038891000056],
              [-80.842371243999935, 35.273070892000078],
              [-80.842591244999937, 35.273079892000055],
              [-80.842810243999963, 35.273083891000056],
              [-80.843165243999977, 35.273082891000058],
              [-80.843383244999984, 35.273077891000071],
              [-80.843818244999966, 35.273053890000028],
              [-80.844275243999959, 35.273008891000075],
              [-80.844503244999942, 35.272979891000034],
              [-80.844687243999942, 35.272951892000037],
              [-80.844846244999985, 35.272927891000052],
              [-80.84495824399994, 35.272910891000038],
              [-80.846745244999966, 35.272572891000038],
              [-80.846734244999936, 35.272024890000068],
              [-80.846727244999954, 35.27175789100005],
              [-80.846704244999955, 35.271462891000056],
              [-80.84666124599994, 35.271169890000067],
              [-80.846575245999986, 35.270757891000073],
              [-80.846531244999937, 35.270579890000079],
              [-80.846377245999975, 35.270149890000027],
              [-80.846230244999958, 35.269808891000025],
              [-80.846160244999965, 35.269671890000041],
              [-80.846087244999978, 35.269527890000063],
              [-80.845923244999938, 35.269240890000049],
              [-80.845772244999978, 35.269007890000069],
              [-80.845575244999964, 35.268719891000046],
              [-80.845393243999979, 35.268467890000068],
              [-80.845121244999973, 35.268084890000068],
              [-80.844913244999987, 35.267792890000067],
              [-80.844629243999975, 35.267411891000052],
              [-80.844319243999962, 35.266995889000043],
              [-80.844033243999945, 35.266602890000058],
              [-80.843340242999943, 35.265633890000061],
              [-80.842190242999948, 35.264023889000043],
              [-80.841995243999975, 35.263780889000031],
              [-80.841836242999989, 35.263548890000038],
              [-80.841636243999972, 35.263219889000027],
              [-80.841507241999977, 35.263016889000028],
              [-80.841251243999977, 35.262637889000075],
              [-80.84116024299999, 35.262472889000037],
              [-80.84107424299998, 35.262305889000061],
              [-80.840914242999986, 35.261959888000035],
              [-80.840815242999952, 35.261694889000069],
              [-80.840761242999974, 35.261510888000032],
              [-80.840736242999981, 35.261424889000068],
              [-80.840674242999967, 35.261150889000078],
              [-80.840656242999955, 35.26103488900003],
              [-80.84019224299999, 35.261062888000026]
            ]
          ],
          [
            [
              [-80.857355248999966, 35.280726892000075],
              [-80.85754824899999, 35.280738892000045],
              [-80.857688248999978, 35.280754893000051],
              [-80.857840248999935, 35.28077789200006],
              [-80.858785248999936, 35.280951892000076],
              [-80.859135249999952, 35.280997892000073],
              [-80.859338248999961, 35.281016892000025],
              [-80.860108249999939, 35.281085893000068],
              [-80.860720249999986, 35.281146892000038],
              [-80.861321249999946, 35.281229892000056],
              [-80.862457250999967, 35.281383892000065],
              [-80.862552250999954, 35.280921892000038],
              [-80.862593249999975, 35.280788892000032],
              [-80.862650250999934, 35.280668892000051],
              [-80.862748249999981, 35.280478892000076],
              [-80.862813249999988, 35.280389892000073],
              [-80.862892250999948, 35.280311891000053],
              [-80.862946249999936, 35.280269892000035],
              [-80.863055250999935, 35.280203892000031],
              [-80.863233249999951, 35.280129892000048],
              [-80.863284251999971, 35.280102892000059],
              [-80.863375250999979, 35.28003889200005],
              [-80.863460250999935, 35.279950892000045],
              [-80.863657251999939, 35.27972389100006],
              [-80.863946250999959, 35.279403892000062],
              [-80.864626250999947, 35.278647892000038],
              [-80.864958251999951, 35.278270891000034],
              [-80.865333251999971, 35.277860891000046],
              [-80.865633250999963, 35.277532891000078],
              [-80.865678250999963, 35.277475892000041],
              [-80.865934251999988, 35.277196892000063],
              [-80.866326250999975, 35.276752891000058],
              [-80.866434251999976, 35.276632891000077],
              [-80.866537250999954, 35.276504890000069],
              [-80.866580250999959, 35.276435891000062],
              [-80.866631251999934, 35.276324891000058],
              [-80.866662251999969, 35.276208891000067],
              [-80.866686251999965, 35.276026891000072],
              [-80.866996250999989, 35.276060891000043],
              [-80.866934251999965, 35.275964891000058],
              [-80.866862251999976, 35.275898891000054],
              [-80.866739250999956, 35.275773891000028],
              [-80.86669225199995, 35.275657891000037],
              [-80.866671251999946, 35.275439891000076],
              [-80.866615251999974, 35.275327891000074],
              [-80.866537250999954, 35.275071891000039],
              [-80.866515250999953, 35.274981891000039],
              [-80.866485251999961, 35.274785891000079],
              [-80.866458250999983, 35.274665891000041],
              [-80.866429250999943, 35.274579890000041],
              [-80.866371250999975, 35.274524890000066],
              [-80.866221250999956, 35.274270891000072],
              [-80.866191250999975, 35.274227890000077],
              [-80.866145250999978, 35.274182891000066],
              [-80.866084250999961, 35.274050891000059],
              [-80.86606225099996, 35.273985891000052],
              [-80.865939251999976, 35.27379189100003],
              [-80.86589625199997, 35.273738890000061],
              [-80.865789250999967, 35.273583890000054],
              [-80.86578824999998, 35.273553891000063],
              [-80.865794250999954, 35.273511890000066],
              [-80.865811250999968, 35.273490890000062],
              [-80.865815250999958, 35.273462890000076],
              [-80.865801250999937, 35.273429890000045],
              [-80.865741250999974, 35.273383890000048],
              [-80.865678250999963, 35.273353890000067],
              [-80.865628250999976, 35.273301891000074],
              [-80.865580250999983, 35.273268890000054],
              [-80.865366250999955, 35.273265891000051],
              [-80.865289250999979, 35.273012891000064],
              [-80.865278251999939, 35.272895891000076],
              [-80.865260250999938, 35.272801890000039],
              [-80.865216251999982, 35.272617891000039],
              [-80.865197251999973, 35.272502890000055],
              [-80.865200250999976, 35.272315890000073],
              [-80.865226249999978, 35.272127890000036],
              [-80.865260250999938, 35.271925890000034],
              [-80.86530725099999, 35.271759890000055],
              [-80.865345250999951, 35.271657890000029],
              [-80.865395250999939, 35.271469890000049],
              [-80.865456250999955, 35.271281889000079],
              [-80.865472250999971, 35.27120989000008],
              [-80.865648250999982, 35.270642890000033],
              [-80.865673250999976, 35.270530890000032],
              [-80.86568325099995, 35.270437890000039],
              [-80.865688249999948, 35.270126890000029],
              [-80.865668251999978, 35.269417890000057],
              [-80.865677249999976, 35.269338889000039],
              [-80.865698250999969, 35.269152889000054],
              [-80.865722250999966, 35.26894388900007],
              [-80.86685825099994, 35.268727889000047],
              [-80.869100251999953, 35.268292890000055],
              [-80.869582251999987, 35.268192889000034],
              [-80.870035252999969, 35.268090889000064],
              [-80.869967251999981, 35.267909888000077],
              [-80.86989625199999, 35.267722889000026],
              [-80.869833251999978, 35.267643890000045],
              [-80.869821251999952, 35.267605890000027],
              [-80.869812251999974, 35.267535889000044],
              [-80.869777251999949, 35.267446889000041],
              [-80.869760252999981, 35.267350889000056],
              [-80.86975825199994, 35.267275890000064],
              [-80.869780252999988, 35.267167888000074],
              [-80.869788251999978, 35.267019889000039],
              [-80.869783251999934, 35.266987889000063],
              [-80.869693251999934, 35.266783889000067],
              [-80.869669251999937, 35.266544889000045],
              [-80.86966825199994, 35.26641188800005],
              [-80.869658251999965, 35.266369889000032],
              [-80.869642252999938, 35.266327889000024],
              [-80.869631251999976, 35.266278889000034],
              [-80.869571251999957, 35.266187888000047],
              [-80.869555251999941, 35.26613788800006],
              [-80.869541251999976, 35.266049889000044],
              [-80.869486251999945, 35.265975888000071],
              [-80.869451252999966, 35.265916888000049],
              [-80.869434250999973, 35.265870888000052],
              [-80.869418251999946, 35.265759888000048],
              [-80.869381251999982, 35.265687889000048],
              [-80.869380251999985, 35.265593889000058],
              [-80.869371251999951, 35.265549888000066],
              [-80.869348251999952, 35.265502889000061],
              [-80.869326250999961, 35.265419889000043],
              [-80.869326250999961, 35.265374888000053],
              [-80.869318251999971, 35.265336889000025],
              [-80.869303251999952, 35.265309889000036],
              [-80.86928825199999, 35.265295889000072],
              [-80.869209251999962, 35.265201889000025],
              [-80.869112251999979, 35.265103889000045],
              [-80.869070251999972, 35.265036889000044],
              [-80.869037251999941, 35.264963889000057],
              [-80.869006250999973, 35.264942888000064],
              [-80.86897025199994, 35.26490988900008],
              [-80.868849250999972, 35.264853889000051],
              [-80.868796250999935, 35.264833889000045],
              [-80.868773250999936, 35.26483588800005],
              [-80.868721250999954, 35.264724889000036],
              [-80.868695251999952, 35.264319888000045],
              [-80.868715251999959, 35.264266889000055],
              [-80.868793250999943, 35.264115888000049],
              [-80.868824251999968, 35.263999889000047],
              [-80.868473250999955, 35.264011888000027],
              [-80.86842225099997, 35.264013889000069],
              [-80.867313250999985, 35.264040889000057],
              [-80.86724325199998, 35.264053888000035],
              [-80.866913250999971, 35.264100889000076],
              [-80.866790250999941, 35.264122888000031],
              [-80.866546249999942, 35.264179888000058],
              [-80.866297250999935, 35.264257888000031],
              [-80.865708249999955, 35.264463889000069],
              [-80.865626250999981, 35.264488889000063],
              [-80.865455250999958, 35.264527889000078],
              [-80.865324249999958, 35.264550888000031],
              [-80.865124249999951, 35.264572888000032],
              [-80.865013249999947, 35.264578889000063],
              [-80.864811249999946, 35.264575888000024],
              [-80.864624249999963, 35.26455788800007],
              [-80.863693249999983, 35.264426889000049],
              [-80.863081249999937, 35.264345889000026],
              [-80.860532249999949, 35.264100889000076],
              [-80.859870247999936, 35.264040889000057],
              [-80.858682248999969, 35.263924889000066],
              [-80.857430247999957, 35.26380688900008],
              [-80.857692247999978, 35.262582888000054],
              [-80.857158248999951, 35.262660888000028],
              [-80.856955246999973, 35.262689888000068],
              [-80.855970246999959, 35.262823888000071],
              [-80.854718246999937, 35.262998889000073],
              [-80.854458246999968, 35.264245889000051],
              [-80.854225246999988, 35.265340889000072],
              [-80.854201246999935, 35.265458890000048],
              [-80.853960246999975, 35.266610890000038],
              [-80.853941246999966, 35.266772889000038],
              [-80.85395424699999, 35.267322890000059],
              [-80.853971246999947, 35.268047890000048],
              [-80.853984247999961, 35.268284889000029],
              [-80.854016246999947, 35.268864889000042],
              [-80.854111247999981, 35.270106891000069],
              [-80.854190247999952, 35.271147890000066],
              [-80.854205246999982, 35.271349891000057],
              [-80.854259247999948, 35.271958891000054],
              [-80.854275247999965, 35.272187890000055],
              [-80.854354247999936, 35.272985891000076],
              [-80.854380247999984, 35.273222891000046],
              [-80.854499247999968, 35.274249891000068],
              [-80.854539246999934, 35.274445891000028],
              [-80.854589247999968, 35.274633891000065],
              [-80.854659247999962, 35.274838891000059],
              [-80.854830247999985, 35.275204891000044],
              [-80.854863248999948, 35.275293891000047],
              [-80.855270247999954, 35.276378891000036],
              [-80.855283247999978, 35.276413891000061],
              [-80.855336247999958, 35.276576891000047],
              [-80.855390248999981, 35.276764892000074],
              [-80.855885248999982, 35.278528892000054],
              [-80.856522247999976, 35.280760892000046],
              [-80.856652248999978, 35.28074489200003],
              [-80.857049248999942, 35.280723892000026],
              [-80.857179248999955, 35.280722892000028],
              [-80.857355248999966, 35.280726892000075]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 32,
        "SolutionID": "PI-15",
        "Shape_Length": 0.10273233292782799,
        "Shape_Area": 0.00028156932988079538
      }
    },
    {
      "type": "Feature",
      "id": 33,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.835420236999937, 35.164250868000067],
              [-80.835529235999957, 35.164258868000047],
              [-80.83623623699998, 35.164352868000037],
              [-80.836324235999939, 35.163934868000069],
              [-80.836380235999968, 35.163692867000066],
              [-80.836437235999938, 35.163493868000046],
              [-80.836483236999982, 35.163365868000028],
              [-80.836590236999939, 35.163114868000036],
              [-80.836717236999959, 35.16286986700004],
              [-80.836805236999965, 35.162735867000038],
              [-80.836913236999976, 35.162581868000075],
              [-80.837138235999987, 35.162304867000046],
              [-80.837377235999952, 35.162057867000044],
              [-80.837479235999979, 35.161960868000051],
              [-80.837835236999979, 35.161622867000062],
              [-80.838026236999951, 35.161433868000074],
              [-80.838102236999987, 35.161348867000072],
              [-80.837650235999945, 35.161076867000077],
              [-80.83755423599996, 35.160998867000046],
              [-80.837476235999986, 35.160908868000035],
              [-80.837414235999972, 35.160810867000066],
              [-80.837391235999974, 35.160758868000073],
              [-80.837360236999984, 35.160650867000072],
              [-80.837349236999955, 35.160540868000055],
              [-80.837376235999955, 35.160148868000078],
              [-80.837428236999983, 35.159356868000032],
              [-80.837439235999966, 35.159067867000033],
              [-80.837427236999986, 35.158939867000072],
              [-80.837413235999975, 35.158872867000071],
              [-80.837369235999972, 35.15874186700006],
              [-80.837339236999981, 35.158675867000056],
              [-80.837304235999966, 35.158611867000047],
              [-80.837755235999964, 35.158395867000024],
              [-80.838648236999973, 35.158025867000049],
              [-80.838868236999986, 35.157935866000059],
              [-80.838920236999968, 35.15792086600004],
              [-80.838973236999948, 35.157910867000055],
              [-80.839081236999959, 35.157902867000075],
              [-80.840304236999941, 35.157955866000066],
              [-80.840494236999973, 35.157953866000071],
              [-80.84058923799995, 35.157946866000032],
              [-80.840775236999946, 35.157919866000043],
              [-80.840868236999938, 35.157899867000026],
              [-80.841024236999942, 35.15785586700008],
              [-80.841076236999982, 35.157835867000074],
              [-80.841145236999978, 35.157805867000036],
              [-80.84134023699994, 35.15769186700004],
              [-80.841471236999951, 35.157607867000024],
              [-80.84212023799995, 35.157155866000039],
              [-80.842506237999942, 35.156884866000041],
              [-80.842593237999949, 35.156827866000071],
              [-80.842733238999983, 35.156755867000072],
              [-80.842891237999936, 35.156694866000066],
              [-80.842972236999969, 35.15667086600007],
              [-80.843704237999987, 35.156477866000046],
              [-80.843484237999974, 35.155889866000052],
              [-80.843460237999977, 35.155771866000066],
              [-80.843456237999987, 35.155675866000024],
              [-80.84346323799997, 35.155569866000064],
              [-80.843509238999957, 35.15529086600003],
              [-80.843534236999972, 35.155140866000067],
              [-80.843616237999981, 35.154618866000078],
              [-80.843619237999974, 35.154552866000074],
              [-80.84361023799994, 35.154432866000036],
              [-80.843581237999956, 35.154272865000053],
              [-80.843542237999941, 35.154146866000076],
              [-80.843503237999983, 35.154050866000034],
              [-80.843131237999955, 35.153414866000048],
              [-80.843038237999963, 35.153260866000039],
              [-80.843013237999969, 35.153211866000049],
              [-80.842979237999941, 35.153111866000074],
              [-80.842966237999974, 35.153030866000051],
              [-80.842969237999966, 35.152925865000043],
              [-80.842984237999985, 35.152848865000067],
              [-80.84309223799994, 35.152468866000049],
              [-80.843138237999938, 35.152290866000044],
              [-80.843249237999942, 35.151899865000075],
              [-80.84193223799997, 35.151670866000075],
              [-80.841672237999944, 35.151599865000037],
              [-80.841741236999951, 35.151455866000049],
              [-80.842549237999947, 35.149775865000038],
              [-80.842994236999971, 35.148872864000055],
              [-80.843244237999954, 35.148365864000027],
              [-80.84347923699994, 35.147919865000063],
              [-80.843780237999965, 35.147348864000037],
              [-80.844061236999949, 35.146810864000031],
              [-80.844121236999968, 35.146702864000076],
              [-80.844160236999983, 35.146633865000069],
              [-80.844380236999939, 35.146248864000029],
              [-80.84482223699996, 35.145417863000034],
              [-80.845092238999939, 35.144905864000066],
              [-80.843801237999969, 35.144828864000033],
              [-80.843027235999955, 35.144777864000048],
              [-80.842847236999944, 35.144748864000064],
              [-80.842805236999936, 35.144740864000028],
              [-80.842749237999953, 35.144715863000044],
              [-80.84268723699995, 35.144662864000054],
              [-80.842658236999966, 35.144629864000024],
              [-80.842594236999958, 35.144529863000059],
              [-80.842568236999966, 35.144476864000069],
              [-80.842507237999939, 35.144293864000076],
              [-80.842248235999989, 35.14344886300006],
              [-80.840838235999968, 35.143738863000067],
              [-80.841330236999966, 35.145365864000041],
              [-80.841337236999948, 35.145411864000039],
              [-80.841338236999945, 35.145456864000039],
              [-80.841330236999966, 35.145518863000063],
              [-80.841300236999984, 35.145609863000061],
              [-80.841249236999943, 35.145694864000063],
              [-80.841210236999984, 35.145738864000066],
              [-80.841120236999984, 35.145809864000057],
              [-80.840693236999982, 35.146063864000041],
              [-80.840566236999962, 35.146134864000032],
              [-80.839657235999937, 35.146661865000056],
              [-80.839548236999974, 35.146708864000061],
              [-80.839430235999941, 35.146740864000037],
              [-80.839308235999965, 35.146756865000043],
              [-80.839165236999975, 35.146759864000046],
              [-80.838989235999975, 35.146762864000038],
              [-80.838533235999989, 35.146756865000043],
              [-80.837162235999983, 35.146730865000052],
              [-80.836972235999951, 35.146734865000042],
              [-80.83686423599994, 35.146748864000074],
              [-80.836551235999934, 35.146755865000046],
              [-80.836584235999965, 35.145777864000024],
              [-80.836612235999951, 35.144952864000061],
              [-80.836178235999967, 35.144948863000025],
              [-80.835994235999976, 35.144956864000051],
              [-80.835903234999989, 35.144967863000034],
              [-80.83580223499996, 35.144985863000045],
              [-80.835074234999979, 35.145146864000026],
              [-80.833995234999975, 35.145387864000043],
              [-80.833275234999974, 35.14554886500008],
              [-80.833161234999977, 35.145556864000071],
              [-80.833078234999959, 35.145552864000024],
              [-80.832983234999972, 35.145539864000057],
              [-80.832449233999967, 35.145439864000025],
              [-80.832398234999971, 35.145418864000078],
              [-80.832187233999946, 35.145855864000055],
              [-80.831902233999983, 35.146421864000047],
              [-80.831607233999989, 35.147007865000035],
              [-80.831342234999966, 35.147532864000027],
              [-80.83104423399999, 35.148122865000062],
              [-80.83098423399997, 35.148242865000043],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.830435234999982, 35.155301866000059],
              [-80.830356232999975, 35.155412866000063],
              [-80.830202234999945, 35.155652866000025],
              [-80.830141233999939, 35.155760867000026],
              [-80.830050234999987, 35.155938867000032],
              [-80.830027233999942, 35.15598486600004],
              [-80.829903232999982, 35.156206866000048],
              [-80.82984823399994, 35.156292867000047],
              [-80.829788232999988, 35.156376866000073],
              [-80.829625233999934, 35.156577867000067],
              [-80.829515232999938, 35.156692867000061],
              [-80.82936323399997, 35.156822867000074],
              [-80.829199233999987, 35.156945867000047],
              [-80.82904423399998, 35.157041867000032],
              [-80.828915233999965, 35.157104867000044],
              [-80.828738233999957, 35.157182867000074],
              [-80.828605233999951, 35.15723386600007],
              [-80.82836223299995, 35.157301867000058],
              [-80.828249233999941, 35.157336867000026],
              [-80.827719232999982, 35.157500868000056],
              [-80.827462233999938, 35.157580867000036],
              [-80.827571232999958, 35.157854867000026],
              [-80.827650232999986, 35.15809686700004],
              [-80.827696232999983, 35.158286867000072],
              [-80.827710233999937, 35.158345867000037],
              [-80.827743233999968, 35.158551867000028],
              [-80.827770233999956, 35.158957867000026],
              [-80.827752233999945, 35.15989386800004],
              [-80.828045233999944, 35.159892867000053],
              [-80.828225234999934, 35.159899868000025],
              [-80.828348233999975, 35.159898867000038],
              [-80.828472233999946, 35.159903868000072],
              [-80.82857923399996, 35.159913868000046],
              [-80.828685233999977, 35.159928868000065],
              [-80.828893232999974, 35.159968867000032],
              [-80.829092233999972, 35.160034868000025],
              [-80.82932623399995, 35.160134867000068],
              [-80.829576233999944, 35.160249867000061],
              [-80.830326233999983, 35.160600868000074],
              [-80.83051923499994, 35.160698868000054],
              [-80.830666233999978, 35.160763867000071],
              [-80.830719234999947, 35.160781867000026],
              [-80.830844234999972, 35.160811868000053],
              [-80.830975233999936, 35.160824868000077],
              [-80.831042234999984, 35.160824867000031],
              [-80.831175233999943, 35.16081286800005],
              [-80.831240234999939, 35.160798868000029],
              [-80.831439234999948, 35.160725867000053],
              [-80.831705234999959, 35.160636867000051],
              [-80.831816233999973, 35.160605868000061],
              [-80.831933234999951, 35.160594867000043],
              [-80.832054234999987, 35.160597868000025],
              [-80.832113234999952, 35.16060686700007],
              [-80.832227234999948, 35.160634867000056],
              [-80.83228223499998, 35.16065586700006],
              [-80.832561234999957, 35.160781867000026],
              [-80.832764234999956, 35.160858868000048],
              [-80.832956234999983, 35.160938867000027],
              [-80.833034235999946, 35.160981867000032],
              [-80.833204234999982, 35.161085868000043],
              [-80.833510235999938, 35.16132286800007],
              [-80.833662234999963, 35.161419868000053],
              [-80.833830235999983, 35.161511868000048],
              [-80.834055235999983, 35.161621867000065],
              [-80.834200234999969, 35.161676868000029],
              [-80.834355235999965, 35.161727868000071],
              [-80.834599235999974, 35.161794868000072],
              [-80.835003235999977, 35.161881867000034],
              [-80.835195235999947, 35.161939867000058],
              [-80.835602235999943, 35.162090868000064],
              [-80.835212235999961, 35.162813868000057],
              [-80.835070236999968, 35.163141868000025],
              [-80.834949236999989, 35.163474868000037],
              [-80.83491223599998, 35.163608869000029],
              [-80.834895234999976, 35.163699868000037],
              [-80.834884235999937, 35.16379286800003],
              [-80.834879235999949, 35.163924868000038],
              [-80.834886235999988, 35.164255868000055],
              [-80.835201235999989, 35.164246868000077],
              [-80.835420236999937, 35.164250868000067]
            ]
          ],
          [
            [
              [-80.848621239999943, 35.160053867000045],
              [-80.848429238999984, 35.160086867000075],
              [-80.848378238999942, 35.160136867000062],
              [-80.848328239999944, 35.160222867000073],
              [-80.848265238999943, 35.160381867000069],
              [-80.84821723899995, 35.160547867000048],
              [-80.848125238999955, 35.160935867000035],
              [-80.848042238999938, 35.161174867000057],
              [-80.847805238999968, 35.162039867000033],
              [-80.847735239999963, 35.162192867000044],
              [-80.847662239999977, 35.162273867000067],
              [-80.847590239999988, 35.162335867000024],
              [-80.847472239999945, 35.162391867000053],
              [-80.847333239999955, 35.162467868000078],
              [-80.847224238999956, 35.162566867000066],
              [-80.847197239999957, 35.162688868000032],
              [-80.847276239999985, 35.162894868000024],
              [-80.847338238999953, 35.163021868000044],
              [-80.847417238999981, 35.163208867000037],
              [-80.847508239999968, 35.163490868000054],
              [-80.847533239999962, 35.163626868000051],
              [-80.847550239999975, 35.163715867000064],
              [-80.84757923899997, 35.163995868000029],
              [-80.847568239999987, 35.164178867000032],
              [-80.84753423899997, 35.16429486800007],
              [-80.847483239999974, 35.164350867000053],
              [-80.847374240999955, 35.164424868000026],
              [-80.847271239999941, 35.164481868000053],
              [-80.84714723999997, 35.164556868000034],
              [-80.846916238999938, 35.164672868000025],
              [-80.846805238999934, 35.164716868000028],
              [-80.846599238999943, 35.164768868000067],
              [-80.846483239999941, 35.164873869000076],
              [-80.846403238999983, 35.164972869000053],
              [-80.846237238999947, 35.165209867000044],
              [-80.846110238999984, 35.165433868000036],
              [-80.846002239999962, 35.16560086800007],
              [-80.845842239999968, 35.165724868000041],
              [-80.845646238999961, 35.165910868000026],
              [-80.845548238999982, 35.166031868000061],
              [-80.845303238999975, 35.16633286900003],
              [-80.844993238999962, 35.166751868000063],
              [-80.844855239999958, 35.166869868000049],
              [-80.844814238999959, 35.166901869000071],
              [-80.84473823999997, 35.166962869000031],
              [-80.844526238999947, 35.167093869000041],
              [-80.844499238999958, 35.167197868000073],
              [-80.844523238999955, 35.167270869000049],
              [-80.844571238999947, 35.167323869000029],
              [-80.844687238999938, 35.167453868000052],
              [-80.844807238999977, 35.167543868000052],
              [-80.844935239999984, 35.167614868000044],
              [-80.845010238999976, 35.167643869000074],
              [-80.845129238999959, 35.167678868000053],
              [-80.845234238999979, 35.167701869000041],
              [-80.84547323999999, 35.167770868000048],
              [-80.845704238999986, 35.167821868000033],
              [-80.846247239999968, 35.167886868000039],
              [-80.846418238999945, 35.167920868000067],
              [-80.846583239999973, 35.167979868000032],
              [-80.846710239999936, 35.168044869000028],
              [-80.84680923999997, 35.168127868000056],
              [-80.846832239999969, 35.168182869000077],
              [-80.846850239999981, 35.168322868000075],
              [-80.846921238999983, 35.168515869000032],
              [-80.846998239999948, 35.168810869000026],
              [-80.847046239999941, 35.168955869000058],
              [-80.847071238999945, 35.169083869000076],
              [-80.847036239999966, 35.169162869000047],
              [-80.847014239999965, 35.169187868000051],
              [-80.846705239999949, 35.169308869000076],
              [-80.846559238999987, 35.169401869000069],
              [-80.846480239999948, 35.169506869000031],
              [-80.846335239999974, 35.16964887000006],
              [-80.846270239999967, 35.169753869000033],
              [-80.846191239999939, 35.169845869000028],
              [-80.846141238999962, 35.169931868000049],
              [-80.846031238999956, 35.170143868000025],
              [-80.845909239999969, 35.170322869000074],
              [-80.84580823999994, 35.170482869000068],
              [-80.845607238999946, 35.17074186900004],
              [-80.845527238999978, 35.170833869000035],
              [-80.845462238999971, 35.170895869000049],
              [-80.845419238999966, 35.170975869000074],
              [-80.845377238999959, 35.171085869000024],
              [-80.845375238999964, 35.171120869000049],
              [-80.845366239999976, 35.171262869000032],
              [-80.845402239999942, 35.171350870000026],
              [-80.845493239999939, 35.171470869000075],
              [-80.845615239999972, 35.171590869000056],
              [-80.845713239999952, 35.17166886900003],
              [-80.845848239999953, 35.171757870000079],
              [-80.846308239999985, 35.172079869000072],
              [-80.846414239999945, 35.172169870000062],
              [-80.846527239999944, 35.172234869000079],
              [-80.846581238999988, 35.172306870000057],
              [-80.846597238999948, 35.172361870000032],
              [-80.846598238999945, 35.172422869000059],
              [-80.846587239999963, 35.172574870000062],
              [-80.846527238999954, 35.172756869000068],
              [-80.846521238999969, 35.172778870000059],
              [-80.846515240999963, 35.172865870000066],
              [-80.846525239999949, 35.17298087000006],
              [-80.846529239999938, 35.173151870000027],
              [-80.846519239999964, 35.173358871000062],
              [-80.846520239999961, 35.173422870000024],
              [-80.846527238999954, 35.173693870000079],
              [-80.846524239999951, 35.173891870000034],
              [-80.846525239999949, 35.173914870000033],
              [-80.846540239999968, 35.17405787000007],
              [-80.846560239999974, 35.174218870000061],
              [-80.846569239999951, 35.174387870000032],
              [-80.846572239999944, 35.174553870000068],
              [-80.84658423999997, 35.174743871000032],
              [-80.846580238999934, 35.175028870000062],
              [-80.846578238999939, 35.175133870000025],
              [-80.84658823999996, 35.175236870000049],
              [-80.846627240999965, 35.175519870000073],
              [-80.846647239999982, 35.175726870000062],
              [-80.846650240999963, 35.175872871000024],
              [-80.846634239999958, 35.176122870000029],
              [-80.846540239999968, 35.17658687100004],
              [-80.846519239999964, 35.17679787000003],
              [-80.846515240999963, 35.17696187100006],
              [-80.84649023999998, 35.177163870000072],
              [-80.846455239999955, 35.177553871000043],
              [-80.846402239999975, 35.177758871000037],
              [-80.846393240999987, 35.177791871000068],
              [-80.846257240999989, 35.178479870000047],
              [-80.84622423999997, 35.17862787100006],
              [-80.846196240999973, 35.178967871000054],
              [-80.846590239999955, 35.179050871000072],
              [-80.846894239999983, 35.179122871000061],
              [-80.848875241999963, 35.179629871000031],
              [-80.849413240999979, 35.179767871000024],
              [-80.849676241999987, 35.179830871000036],
              [-80.849830241999939, 35.179873871000041],
              [-80.850018240999987, 35.179909871000064],
              [-80.850435241999946, 35.179954870000074],
              [-80.851363241999934, 35.180022871000062],
              [-80.851201241999945, 35.178971871000044],
              [-80.851162241999987, 35.178714871000068],
              [-80.851130241999954, 35.178544871000042],
              [-80.851088240999957, 35.178278870000042],
              [-80.851057241999968, 35.177973870000073],
              [-80.851035241999966, 35.177737871000033],
              [-80.851018241999952, 35.177363871000068],
              [-80.851016240999968, 35.177188870000066],
              [-80.85102324099995, 35.176714870000069],
              [-80.851034241999969, 35.176368870000033],
              [-80.851045240999952, 35.176164871000026],
              [-80.851030240999989, 35.175930871000048],
              [-80.851042241999949, 35.175758870000038],
              [-80.851092241999936, 35.175047870000071],
              [-80.851134241999944, 35.174351870000066],
              [-80.851177241999949, 35.173800870000036],
              [-80.85121824099997, 35.173354870000026],
              [-80.851233241999978, 35.173038870000028],
              [-80.85123224199998, 35.172761870000045],
              [-80.851213241999972, 35.172469869000054],
              [-80.85116224099994, 35.172079870000061],
              [-80.851042241999949, 35.171274868000069],
              [-80.850945240999977, 35.170531870000048],
              [-80.85090723999997, 35.170224869000037],
              [-80.850734239999952, 35.168817868000076],
              [-80.85065124099998, 35.168206869000073],
              [-80.850499240999966, 35.167339868000056],
              [-80.850442240999939, 35.166706869000052],
              [-80.850399239999945, 35.165665868000076],
              [-80.850267240999983, 35.165027868000038],
              [-80.850175239999942, 35.164590867000072],
              [-80.850123240999949, 35.164422868000031],
              [-80.850053240999955, 35.164259867000055],
              [-80.849966240999947, 35.164102867000054],
              [-80.849862239999936, 35.163952868000024],
              [-80.849679239999944, 35.163732868000068],
              [-80.849584239999956, 35.163596867000024],
              [-80.849501240999984, 35.163455867000039],
              [-80.849430239999947, 35.163310868000053],
              [-80.849360240999943, 35.163123867000024],
              [-80.849317240999937, 35.162971868000056],
              [-80.849245240999949, 35.162686868000037],
              [-80.849155239999959, 35.162336868000068],
              [-80.849053239999989, 35.16193486700007],
              [-80.848916240999984, 35.161179867000044],
              [-80.848833239999976, 35.160465867000028],
              [-80.848785239999984, 35.160046867000062],
              [-80.848621239999943, 35.160053867000045]
            ]
          ],
          [
            [
              [-80.873304248999943, 35.194455873000038],
              [-80.87447224999994, 35.194872873000065],
              [-80.874775249999971, 35.194973873000038],
              [-80.875134249999974, 35.195098874000053],
              [-80.87548524999994, 35.195221873000037],
              [-80.876259248999986, 35.195511874000033],
              [-80.876885249999987, 35.195736873000044],
              [-80.877218249999942, 35.195867873000054],
              [-80.877931249999961, 35.194949872000052],
              [-80.878011249999986, 35.194845874000066],
              [-80.878146250999976, 35.194667873000071],
              [-80.879313250999985, 35.193183873000066],
              [-80.879918250999935, 35.19241687300007],
              [-80.880481250999935, 35.191700872000069],
              [-80.88131225099994, 35.190641872000072],
              [-80.881465250999952, 35.19044787200005],
              [-80.882041250999976, 35.189716872000076],
              [-80.882472251999957, 35.189159872000062],
              [-80.882548250999946, 35.189054872000042],
              [-80.883367251999971, 35.188013872000056],
              [-80.884355251999978, 35.186758871000052],
              [-80.884705251999947, 35.185164870000051],
              [-80.88444825299996, 35.18531487100006],
              [-80.884115252999948, 35.185492871000065],
              [-80.884014251999986, 35.185422871000071],
              [-80.883953251999969, 35.185385871000051],
              [-80.883872251999946, 35.185344871000041],
              [-80.882312250999973, 35.184635871000069],
              [-80.882227250999961, 35.184588871000074],
              [-80.882101250999938, 35.184528871000055],
              [-80.882050250999953, 35.184506870000064],
              [-80.881759250999949, 35.184381871000028],
              [-80.881189250999967, 35.184120871000061],
              [-80.880523250999943, 35.183824870000024],
              [-80.880010250999987, 35.183601870000075],
              [-80.879423250999935, 35.183325870000033],
              [-80.879128249999951, 35.183757871000068],
              [-80.878565249999951, 35.184541871000079],
              [-80.878478249999944, 35.184782871000039],
              [-80.878439250999975, 35.184872871000039],
              [-80.878161250999938, 35.18549887100005],
              [-80.878042249999964, 35.185752871000034],
              [-80.877749249999965, 35.186317872000075],
              [-80.87737924999999, 35.186985872000037],
              [-80.877290249999987, 35.187148871000034],
              [-80.877230249999968, 35.187237872000026],
              [-80.876935249999974, 35.187762872000064],
              [-80.876891249999971, 35.187840872000038],
              [-80.876851249999959, 35.187893871000028],
              [-80.876757249999969, 35.187990872000057],
              [-80.876657249999937, 35.18807187200008],
              [-80.876606249999952, 35.188103872000056],
              [-80.876542248999954, 35.18813687200003],
              [-80.876388249999934, 35.18845187200003],
              [-80.875493249999977, 35.189857872000061],
              [-80.873097248999954, 35.194349873000078],
              [-80.872923248999939, 35.194740873000057],
              [-80.872901249999984, 35.19477687300008],
              [-80.872884248999981, 35.194826874000057],
              [-80.872918248999952, 35.194781874000057],
              [-80.872955248999972, 35.194726874000025],
              [-80.873074248999956, 35.194511873000067],
              [-80.873119248999956, 35.194474873000047],
              [-80.87317724899998, 35.194451873000048],
              [-80.873241247999943, 35.194444873000066],
              [-80.873304248999943, 35.194455873000038]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 33,
        "SolutionID": "PI-15",
        "Shape_Length": 0.1656413586793353,
        "Shape_Area": 0.00034422756152676596
      }
    },
    {
      "type": "Feature",
      "id": 34,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.865290281999989, 35.833248009000044],
            [-80.866989282999953, 35.833409009000036],
            [-80.867179281999938, 35.833430010000029],
            [-80.86736928199997, 35.833461009000075],
            [-80.867738282999937, 35.833551009000075],
            [-80.868317283999943, 35.833812010000031],
            [-80.86886528399998, 35.834113010000067],
            [-80.869390282999973, 35.834316009000077],
            [-80.869931282999971, 35.834485009000048],
            [-80.870761283999968, 35.834722009000075],
            [-80.871330283999953, 35.834856009000077],
            [-80.871908284999961, 35.83495700900005],
            [-80.87249428299998, 35.835023009000054],
            [-80.872688284999981, 35.834715009000035],
            [-80.872925283999962, 35.834277009000061],
            [-80.873143284999969, 35.833833009000045],
            [-80.873400283999956, 35.833167009000078],
            [-80.873505284999965, 35.83283300800008],
            [-80.873630283999944, 35.832386009000061],
            [-80.873723283999936, 35.832055008000054],
            [-80.87381828499997, 35.83171500900005],
            [-80.87406228399999, 35.830631008000069],
            [-80.874173284999983, 35.83013900800006],
            [-80.874322284999948, 35.829476008000029],
            [-80.875106284999958, 35.82601900800006],
            [-80.875112283999954, 35.82597400800006],
            [-80.875432284999988, 35.825995007000074],
            [-80.875992283999949, 35.826012008000077],
            [-80.876200284999982, 35.826026007000053],
            [-80.876581284999986, 35.826081007000028],
            [-80.876832285999967, 35.826139007000052],
            [-80.877136284999949, 35.826224007000064],
            [-80.877438284999982, 35.826317007000057],
            [-80.877816284999938, 35.826425008000058],
            [-80.878078284999958, 35.826494007000065],
            [-80.878311284999938, 35.826530007000031],
            [-80.878414284999963, 35.826542008000047],
            [-80.878517284999987, 35.826548006000053],
            [-80.878994284999976, 35.82654100700006],
            [-80.880145285999959, 35.826425007000068],
            [-80.880403285999989, 35.826387007000051],
            [-80.880658285999971, 35.826334006000025],
            [-80.880962285999942, 35.826245007000068],
            [-80.881260286999975, 35.826142007000044],
            [-80.88187828599996, 35.825848007000047],
            [-80.883345286999941, 35.825080007000054],
            [-80.884558287999937, 35.824450007000053],
            [-80.884818287999963, 35.824305006000031],
            [-80.885069286999965, 35.824151006000079],
            [-80.886879287999989, 35.822959006000076],
            [-80.887264287999983, 35.822734006000076],
            [-80.888171287999967, 35.822260006000079],
            [-80.888454287999934, 35.82211000500007],
            [-80.888441288999957, 35.822084006000068],
            [-80.888441288999957, 35.822062007000056],
            [-80.888362287999939, 35.821907005000071],
            [-80.888336287999948, 35.821819006000055],
            [-80.88829728799999, 35.821753005000062],
            [-80.888257287999977, 35.821665006000046],
            [-80.888258287999975, 35.821643006000045],
            [-80.888231287999986, 35.821599006000042],
            [-80.888219287999959, 35.821533006000038],
            [-80.888179287999947, 35.821467006000034],
            [-80.888128287999962, 35.821247006000078],
            [-80.88804928899998, 35.821070005000024],
            [-80.888037287999964, 35.821004006000067],
            [-80.887945287999969, 35.820762005000063],
            [-80.887894287999984, 35.820564005000051],
            [-80.887867287999939, 35.820520006000038],
            [-80.887842287999945, 35.820410005000042],
            [-80.887802287999989, 35.82034300600003],
            [-80.887751287999947, 35.820167006000077],
            [-80.887698287999967, 35.820057005000024],
            [-80.887672287999976, 35.819947005000074],
            [-80.887646287999985, 35.819903005000072],
            [-80.88760728799997, 35.819771006000053],
            [-80.887581287999978, 35.819727005000061],
            [-80.887452287999963, 35.81926500600008],
            [-80.887425287999974, 35.819220005000034],
            [-80.887387287999957, 35.819110006000074],
            [-80.887294287999964, 35.818956006000064],
            [-80.887242287999982, 35.818824006000057],
            [-80.887123286999952, 35.818603005000057],
            [-80.887058287999935, 35.818449006000037],
            [-80.886912287999962, 35.818206005000036],
            [-80.886714286999961, 35.81794100500008],
            [-80.886371286999974, 35.817367004000062],
            [-80.886331286999962, 35.81730000500005],
            [-80.886278287999971, 35.817190005000043],
            [-80.886212286999978, 35.817102005000038],
            [-80.886138286999937, 35.817085005000024],
            [-80.885535287999971, 35.816948005000029],
            [-80.885787286999971, 35.81679700400008],
            [-80.885954286999947, 35.816717005000044],
            [-80.885907287999942, 35.816660005000074],
            [-80.885516287999963, 35.816107005000049],
            [-80.885410286999956, 35.815975005000041],
            [-80.88521028699995, 35.815753005000033],
            [-80.885164286999952, 35.81568700400004],
            [-80.885051287999943, 35.815576005000025],
            [-80.884964286999946, 35.815515005000066],
            [-80.884843286999967, 35.81545400400006],
            [-80.884548286999973, 35.815330005000078],
            [-80.884306285999969, 35.815262004000033],
            [-80.88427928699997, 35.815262004000033],
            [-80.88422628699999, 35.815239005000024],
            [-80.883957286999987, 35.815182004000064],
            [-80.883607286999961, 35.815080005000027],
            [-80.88359328599995, 35.815074005000042],
            [-80.883385285999964, 35.814984004000053],
            [-80.88325828699999, 35.814929004000078],
            [-80.883124285999941, 35.814862005000066],
            [-80.882990285999938, 35.814778004000061],
            [-80.882884285999978, 35.814689005000048],
            [-80.882784286999936, 35.814590005000071],
            [-80.882737285999951, 35.814534005000041],
            [-80.882684285999972, 35.814446005000036],
            [-80.882675285999937, 35.814419004000058],
            [-80.882619285999965, 35.814248005000024],
            [-80.882620285999963, 35.814182005000077],
            [-80.882648285999949, 35.814094004000026],
            [-80.882650285999944, 35.813963005000062],
            [-80.882624285999952, 35.813875004000067],
            [-80.882585286999984, 35.813808005000055],
            [-80.882511285999954, 35.813742004000062],
            [-80.882451285999934, 35.813708004000034],
            [-80.882411285999979, 35.813697003000073],
            [-80.88224928599999, 35.813695005000056],
            [-80.882032285999969, 35.813759004000076],
            [-80.881924285999958, 35.81376900500004],
            [-80.881897285999969, 35.813758004000078],
            [-80.881789285999957, 35.813746004000052],
            [-80.881575285999986, 35.813656005000041],
            [-80.881254285999944, 35.813439005000077],
            [-80.881120284999952, 35.813328004000027],
            [-80.880927284999984, 35.813134004000062],
            [-80.880821284999968, 35.813046004000057],
            [-80.880701285999976, 35.812913005000041],
            [-80.880487285999948, 35.812713004000045],
            [-80.880288284999949, 35.812492004000035],
            [-80.88020828599997, 35.81242500500008],
            [-80.880101284999967, 35.812315003000037],
            [-80.87998328499998, 35.812178004000032],
            [-80.879929284999946, 35.812115004000077],
            [-80.879829284999971, 35.811983004000069],
            [-80.879697284999963, 35.811762005000048],
            [-80.879658284999948, 35.811652004000052],
            [-80.879632284999957, 35.81160800400005],
            [-80.879632284999957, 35.811586004000048],
            [-80.879421284999978, 35.811189004000028],
            [-80.879421284999978, 35.811167004000026],
            [-80.879395285999976, 35.811123004000024],
            [-80.879395285999976, 35.811101005000069],
            [-80.879369284999939, 35.811056004000079],
            [-80.879343284999948, 35.810968004000074],
            [-80.879265283999985, 35.810792003000074],
            [-80.879265283999985, 35.810770004000062],
            [-80.879239285999972, 35.81072600400006],
            [-80.879226284999959, 35.810638004000054],
            [-80.879200284999968, 35.810572004000051],
            [-80.879189284999939, 35.810418004000041],
            [-80.879124284999989, 35.81022000400003],
            [-80.879124284999989, 35.810176004000027],
            [-80.879095284999948, 35.810111003000031],
            [-80.879085285999963, 35.810088004000079],
            [-80.879085285999963, 35.810066004000078],
            [-80.879059284999983, 35.810022004000075],
            [-80.879059284999983, 35.809978004000072],
            [-80.879020284999967, 35.809868004000066],
            [-80.879021284999965, 35.809824004000063],
            [-80.878995283999984, 35.809736004000058],
            [-80.878949283999987, 35.809648003000063],
            [-80.878816283999981, 35.809493003000057],
            [-80.879039284999976, 35.809495004000041],
            [-80.879066284999965, 35.80948400300008],
            [-80.879309284999977, 35.809475003000045],
            [-80.879336285999955, 35.809465003000071],
            [-80.879444284999977, 35.809466003000068],
            [-80.879660284999943, 35.80942400400005],
            [-80.879741284999966, 35.80942400400005],
            [-80.879795284999943, 35.809403004000046],
            [-80.879876284999966, 35.809393004000071],
            [-80.879974285999936, 35.809359004000044],
            [-80.879985284999975, 35.809355003000064],
            [-80.880148284999962, 35.809242004000055],
            [-80.880284284999959, 35.809188003000031],
            [-80.880690284999957, 35.809104004000062],
            [-80.881014285999981, 35.809074004000024],
            [-80.881075285999941, 35.809061003000068],
            [-80.881203285999959, 35.809032004000073],
            [-80.881392285999937, 35.80902300300005],
            [-80.881419285999982, 35.809034004000068],
            [-80.881473285999959, 35.809034004000068],
            [-80.881500285999948, 35.809045004000041],
            [-80.881554285999982, 35.809046004000038],
            [-80.88166128599994, 35.809069004000037],
            [-80.881715285999974, 35.809091004000038],
            [-80.881823285999985, 35.809114003000047],
            [-80.881957285999988, 35.809165004000079],
            [-80.882279285999971, 35.809316004000038],
            [-80.88230628599996, 35.809316003000049],
            [-80.882371284999977, 35.80933400300006],
            [-80.882427284999949, 35.809350003000077],
            [-80.882508284999972, 35.809351003000074],
            [-80.882616284999983, 35.809330003000071],
            [-80.882724285999984, 35.809282004000067],
            [-80.882806285999948, 35.809228004000033],
            [-80.882942286999935, 35.809191003000024],
            [-80.883103285999937, 35.809192003000078],
            [-80.883251285999961, 35.809226003000049],
            [-80.883275285999957, 35.809228004000033],
            [-80.883373286999984, 35.809238003000075],
            [-80.883399284999939, 35.809250003000045],
            [-80.883615285999952, 35.80925200300004],
            [-80.883723286999953, 35.809231003000036],
            [-80.884044286999938, 35.809111004000044],
            [-80.884224285999949, 35.809115003000045],
            [-80.884251286999984, 35.809126004000063],
            [-80.884345286999974, 35.809138003000044],
            [-80.884506286999965, 35.809194003000073],
            [-80.884573285999977, 35.809233003000031],
            [-80.884813286999986, 35.809444004000056],
            [-80.885041285999989, 35.809567003000041],
            [-80.885121286999947, 35.809633004000034],
            [-80.885195286999988, 35.809716004000052],
            [-80.885253285999966, 35.809849004000057],
            [-80.885253286999955, 35.809871004000058],
            [-80.885305286999937, 35.810003004000066],
            [-80.885358286999974, 35.810091004000071],
            [-80.885418286999936, 35.810168003000058],
            [-80.885551286999942, 35.810274003000075],
            [-80.885672286999977, 35.810341004000065],
            [-80.885840286999951, 35.810414003000062],
            [-80.885974286999954, 35.810448004000079],
            [-80.886055286999976, 35.810449004000077],
            [-80.886109285999964, 35.810427004000076],
            [-80.886217286999965, 35.810428003000027],
            [-80.886325286999977, 35.810468004000029],
            [-80.886371286999974, 35.810512003000042],
            [-80.886417286999972, 35.810627004000025],
            [-80.886469286999954, 35.810804004000033],
            [-80.886481286999981, 35.810892003000049],
            [-80.886494286999948, 35.81091400300005],
            [-80.886492287999943, 35.811111003000065],
            [-80.886477286999934, 35.811199004000059],
            [-80.88646328699997, 35.811221003000071],
            [-80.88646328699997, 35.811265003000074],
            [-80.886421286999962, 35.811396004000073],
            [-80.886412287999974, 35.811466004000067],
            [-80.886405286999945, 35.811527004000027],
            [-80.88639128799997, 35.811549004000028],
            [-80.886390286999983, 35.811703004000037],
            [-80.88643628799997, 35.811764004000054],
            [-80.886476287999983, 35.811797004000027],
            [-80.886563286999944, 35.811836004000043],
            [-80.886671286999956, 35.811859004000041],
            [-80.88672528699999, 35.811860005000028],
            [-80.886752286999979, 35.811871004000068],
            [-80.886941286999956, 35.811873004000063],
            [-80.886968286999945, 35.811862004000034],
            [-80.887211286999957, 35.811864004000029],
            [-80.887238286999946, 35.811854003000064],
            [-80.887292287999969, 35.811854004000054],
            [-80.887454287999958, 35.811801004000074],
            [-80.887834287999965, 35.811650003000068],
            [-80.887970286999973, 35.811586004000048],
            [-80.887997287999951, 35.811586004000048],
            [-80.888078287999974, 35.811554003000026],
            [-80.888132287999952, 35.811543004000043],
            [-80.888375287999963, 35.811546004000036],
            [-80.888510286999974, 35.811519004000047],
            [-80.888680287999989, 35.811455003000049],
            [-80.88889028899996, 35.81137500400007],
            [-80.889052287999959, 35.811360003000061],
            [-80.889133287999982, 35.811366004000035],
            [-80.889348287999951, 35.811423003000073],
            [-80.889507287999947, 35.81142400300007],
            [-80.890051287999938, 35.811319003000051],
            [-80.89033528899995, 35.811207004000039],
            [-80.890349288999971, 35.811202003000062],
            [-80.890457287999936, 35.811175004000063],
            [-80.890499287999944, 35.811152003000075],
            [-80.89062028799998, 35.811094003000051],
            [-80.891163288999962, 35.810880003000079],
            [-80.891190288999951, 35.810880004000069],
            [-80.891407288999972, 35.810794003000069],
            [-80.891434287999971, 35.810794003000069],
            [-80.891542288999972, 35.810757004000038],
            [-80.891868287999955, 35.810623003000046],
            [-80.891983287999949, 35.81056200300003],
            [-80.892492287999971, 35.810294003000024],
            [-80.892595288999985, 35.810230003000072],
            [-80.892817288999936, 35.810155003000034],
            [-80.893131288999939, 35.809972003000041],
            [-80.893690288999949, 35.809564003000048],
            [-80.893844288999958, 35.809319003000041],
            [-80.893950288999974, 35.809220003000064],
            [-80.89403128899994, 35.809155003000058],
            [-80.894366289999937, 35.808807003000027],
            [-80.894701288999954, 35.808508003000043],
            [-80.89500228899999, 35.80819800200004],
            [-80.895220288999951, 35.80800200200008],
            [-80.895247289999986, 35.807992003000038],
            [-80.895277288999978, 35.807968003000042],
            [-80.895465289999947, 35.807829002000062],
            [-80.895651288999943, 35.807715003000055],
            [-80.89587328999994, 35.807580003000055],
            [-80.89628128999999, 35.807359002000055],
            [-80.896552289999988, 35.807230003000029],
            [-80.89685228899998, 35.807030002000033],
            [-80.896933289999936, 35.806975002000058],
            [-80.897137289999989, 35.806856002000075],
            [-80.897278290999964, 35.806796002000056],
            [-80.897490289999951, 35.806706002000055],
            [-80.897747289999984, 35.806604002000029],
            [-80.897801290999951, 35.806572003000042],
            [-80.897883290999971, 35.806539002000079],
            [-80.89791028999997, 35.806518003000065],
            [-80.898253290999946, 35.806371002000049],
            [-80.89827029099996, 35.806359003000068],
            [-80.898103289999938, 35.806221003000076],
            [-80.897964289999948, 35.806093002000068],
            [-80.897889289999966, 35.80601800200003],
            [-80.89786928999996, 35.805999002000078],
            [-80.897784289999947, 35.805900001000055],
            [-80.897757289999959, 35.805862002000026],
            [-80.897726290999969, 35.805819002000078],
            [-80.897698289999937, 35.805727002000026],
            [-80.897667290999948, 35.805513002000055],
            [-80.897624290999943, 35.805381002000047],
            [-80.897567289999984, 35.805265002000056],
            [-80.897492289999946, 35.805150002000062],
            [-80.89738829099997, 35.805021002000046],
            [-80.897260289999963, 35.804900002000068],
            [-80.897129288999963, 35.804789002000064],
            [-80.896710289999987, 35.804533002000028],
            [-80.896548288999952, 35.804458002000047],
            [-80.896332288999986, 35.804390002000048],
            [-80.896104289999982, 35.804357002000074],
            [-80.89587328999994, 35.804361002000064],
            [-80.895647288999953, 35.804402002000074],
            [-80.894622288999983, 35.804646002000027],
            [-80.89389628899994, 35.804819002000045],
            [-80.893444288999945, 35.80494800200006],
            [-80.893011288999958, 35.805116002000034],
            [-80.892603287999975, 35.80532000200003],
            [-80.891788288999976, 35.805778002000068],
            [-80.89152428899996, 35.805903002000036],
            [-80.891239287999952, 35.805994002000034],
            [-80.890941288999954, 35.806048002000068],
            [-80.890637287999937, 35.806064003000074],
            [-80.890203287999952, 35.806060003000027],
            [-80.890285286999983, 35.805337002000044],
            [-80.890304287999982, 35.805171003000055],
            [-80.888422286999969, 35.805468002000055],
            [-80.885737286999984, 35.805882002000033],
            [-80.880462284999965, 35.806713004000073],
            [-80.876020283999935, 35.80740600300004],
            [-80.874125282999955, 35.80770100400008],
            [-80.872434282999961, 35.807965003000049],
            [-80.870543282999961, 35.808261003000041],
            [-80.867345281999974, 35.808769003000066],
            [-80.863241279999954, 35.809400004000054],
            [-80.861495278999939, 35.80967800500008],
            [-80.861261278999962, 35.809715005000044],
            [-80.861251279999976, 35.809876004000046],
            [-80.861244278999948, 35.809989005000034],
            [-80.861083279999946, 35.812982005000038],
            [-80.861082279999948, 35.813007005000031],
            [-80.860996279999938, 35.81431600600007],
            [-80.860939279999968, 35.81555800600006],
            [-80.860928279999939, 35.815744005000056],
            [-80.860903279999945, 35.816144005000069],
            [-80.860862279999935, 35.816833007000071],
            [-80.86080728099995, 35.81810600600005],
            [-80.860726278999948, 35.819379007000066],
            [-80.860662279999985, 35.820657007000079],
            [-80.860511279999969, 35.82320500700007],
            [-80.86030827999997, 35.82703200800006],
            [-80.860249279999948, 35.828310009000063],
            [-80.860208279999938, 35.828909008000039],
            [-80.860161280999989, 35.829587009000079],
            [-80.860096279999937, 35.830863009000041],
            [-80.860008280999978, 35.832138009000062],
            [-80.859935280999935, 35.833657010000024],
            [-80.860377280999955, 35.833577010000056],
            [-80.863907281999957, 35.833259009000074],
            [-80.864598280999985, 35.833239009000067],
            [-80.865290281999989, 35.833248009000044]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 34,
        "SolutionID": "PI-16",
        "Shape_Length": 0.14135986023366917,
        "Shape_Area": 0.00059633801378270422
      }
    },
    {
      "type": "Feature",
      "id": 35,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.809300252999947, 35.598266961000036],
            [-80.809791251999968, 35.598815961000071],
            [-80.810485251999978, 35.599542962000044],
            [-80.811349252999946, 35.600459962000059],
            [-80.811462252999945, 35.60057996300003],
            [-80.811763252999981, 35.600164962000065],
            [-80.813071253999965, 35.598487961000046],
            [-80.813866253999947, 35.597636962000024],
            [-80.814463253999975, 35.597031961000027],
            [-80.814840252999943, 35.59670796100005],
            [-80.816580254999963, 35.595367961000079],
            [-80.817289253999945, 35.594904961000054],
            [-80.821229254999935, 35.592366960000049],
            [-80.822924255999965, 35.591249960000027],
            [-80.823991256999989, 35.590569960000039],
            [-80.825390256999981, 35.58967695900003],
            [-80.826928256999963, 35.588688958000034],
            [-80.82712825699997, 35.588559959000065],
            [-80.827400256999965, 35.588401959000066],
            [-80.827696256999957, 35.588276958000051],
            [-80.82801025699996, 35.588186958000051],
            [-80.82796525699996, 35.588087958000074],
            [-80.827912257999969, 35.58802995800005],
            [-80.827828256999965, 35.587963959000035],
            [-80.827708256999983, 35.587923959000079],
            [-80.827254256999936, 35.587846959000046],
            [-80.826336255999934, 35.58769195800005],
            [-80.825441255999976, 35.58754095900008],
            [-80.824534255999936, 35.587387959000068],
            [-80.824052255999959, 35.587338959000078],
            [-80.823142254999937, 35.587276959000064],
            [-80.822713255999986, 35.587263958000051],
            [-80.82222825499997, 35.587247959000024],
            [-80.820773254999949, 35.587200959000029],
            [-80.820565254999963, 35.587153958000044],
            [-80.82037425499999, 35.587072959000068],
            [-80.820039254999983, 35.586834959000043],
            [-80.819298253999989, 35.586235959000078],
            [-80.817660252999985, 35.584935959000063],
            [-80.816974252999955, 35.584370959000069],
            [-80.816040252999983, 35.583613958000058],
            [-80.815277251999987, 35.583011958000043],
            [-80.81411325199997, 35.582049958000027],
            [-80.816215251999949, 35.580253958000071],
            [-80.816603252999982, 35.579948958000045],
            [-80.818342252999969, 35.578451957000027],
            [-80.819585254999936, 35.577317956000059],
            [-80.820885254999951, 35.576222957000027],
            [-80.821368253999935, 35.575814957000034],
            [-80.822457254999961, 35.574897957000076],
            [-80.823987254999963, 35.573608956000044],
            [-80.824357255999985, 35.573283955000079],
            [-80.824681254999973, 35.572999956000046],
            [-80.825228254999956, 35.572568956000055],
            [-80.825328254999988, 35.572490956000024],
            [-80.825251255999945, 35.572438956000042],
            [-80.825188254999944, 35.572396955000045],
            [-80.825458254999944, 35.57215495500003],
            [-80.825773254999945, 35.571873956000047],
            [-80.824906254999973, 35.57114195500003],
            [-80.825126254999986, 35.570969955000066],
            [-80.825515254999971, 35.570665955000038],
            [-80.824816254999973, 35.57003095500005],
            [-80.823935254999981, 35.569254955000076],
            [-80.823633254999947, 35.568987955000068],
            [-80.823120254999935, 35.568534955000075],
            [-80.822485254999947, 35.569021955000039],
            [-80.822286254999938, 35.569174954000061],
            [-80.822183253999981, 35.569301955000071],
            [-80.822117254999966, 35.569445955000049],
            [-80.822092253999983, 35.569596955000065],
            [-80.822110254999984, 35.569749955000077],
            [-80.822014253999953, 35.570637955000052],
            [-80.821295254999939, 35.571058955000069],
            [-80.821009253999989, 35.571226955000043],
            [-80.820540254999969, 35.571342956000024],
            [-80.820084253999937, 35.571455956000079],
            [-80.819611252999948, 35.571481956000071],
            [-80.819285252999975, 35.571461956000064],
            [-80.818608253999969, 35.571426956000039],
            [-80.818487252999944, 35.571420956000054],
            [-80.817844252999976, 35.571387956000024],
            [-80.817624252999963, 35.57085195500008],
            [-80.817348252999977, 35.570178956000063],
            [-80.817080252999972, 35.569527955000069],
            [-80.815528252999968, 35.569633954000039],
            [-80.813625250999962, 35.569775955000068],
            [-80.811473251999985, 35.570137956000053],
            [-80.811428251999985, 35.571225956000035],
            [-80.811522250999985, 35.571610956000029],
            [-80.811568251999972, 35.571798955000077],
            [-80.811977251999963, 35.572752956000045],
            [-80.812047251999957, 35.575038956000071],
            [-80.812065251999968, 35.575395957000069],
            [-80.81254925099995, 35.576149957000041],
            [-80.81255325099994, 35.576595956000062],
            [-80.812362250999968, 35.577065957000059],
            [-80.811148250999963, 35.578623957000048],
            [-80.810927251999942, 35.578914958000041],
            [-80.81023725099999, 35.579881958000044],
            [-80.809933250999961, 35.580228957000031],
            [-80.809664250999958, 35.580516957000043],
            [-80.809015250999948, 35.581551958000034],
            [-80.807498249999981, 35.582748959000071],
            [-80.807293249999987, 35.582840958000077],
            [-80.806847249999976, 35.582739958000047],
            [-80.806794249999939, 35.583095958000058],
            [-80.806003249999947, 35.582945959000028],
            [-80.805737250999982, 35.582895958000051],
            [-80.805359249999981, 35.58282495800006],
            [-80.804807249999953, 35.582727959000067],
            [-80.804434249999986, 35.582691958000055],
            [-80.803681249999954, 35.582647958000052],
            [-80.803628248999985, 35.582901958000036],
            [-80.803571249999948, 35.583175958000027],
            [-80.80352524999995, 35.58343295800006],
            [-80.80351324999998, 35.583593959000041],
            [-80.803527248999956, 35.583846959000027],
            [-80.803591249999954, 35.584193959000061],
            [-80.803789249999966, 35.585267960000067],
            [-80.803812249999964, 35.585389960000043],
            [-80.803979250999987, 35.586518959000045],
            [-80.804178250999939, 35.587788960000069],
            [-80.804252249999934, 35.588267960000053],
            [-80.804314249999948, 35.58861095900005],
            [-80.804359249999948, 35.588784959000066],
            [-80.804445249999958, 35.589018960000033],
            [-80.804799250999963, 35.589878959000032],
            [-80.805506249999951, 35.591516960000035],
            [-80.806078249999985, 35.592569960000048],
            [-80.806479251999974, 35.593286961000047],
            [-80.806703250999988, 35.593708961000061],
            [-80.807106250999936, 35.594469961000073],
            [-80.806973250999988, 35.595296961000031],
            [-80.806795250999983, 35.596408961000066],
            [-80.806675251999934, 35.596664961000045],
            [-80.806501250999986, 35.596899961000076],
            [-80.805667250999988, 35.597893961000068],
            [-80.805953250999949, 35.597867962000066],
            [-80.806551250999973, 35.597834961000046],
            [-80.806920251999941, 35.597794961000034],
            [-80.807644251999989, 35.597717961000058],
            [-80.808306251999966, 35.597673962000044],
            [-80.808504251999977, 35.597635961000037],
            [-80.808611250999945, 35.597600961000069],
            [-80.808746251999935, 35.59753696100006],
            [-80.809300252999947, 35.598266961000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 35,
        "SolutionID": "PI-16",
        "Shape_Length": 0.11463380495284096,
        "Shape_Area": 0.0003458634226997281
      }
    },
    {
      "type": "Feature",
      "id": 36,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.848390249999966, 35.346195907000038],
              [-80.848159249999981, 35.347309907000067],
              [-80.847949249999942, 35.348165907000066],
              [-80.847929249999936, 35.348248907000027],
              [-80.847877250999943, 35.348437907000061],
              [-80.847779249999974, 35.348772908000058],
              [-80.847656249999943, 35.349198908000062],
              [-80.847441249999974, 35.349936908000075],
              [-80.847037249999971, 35.351197908000074],
              [-80.846737248999943, 35.352039907000062],
              [-80.846420249999937, 35.352860908000025],
              [-80.846202249999976, 35.353388908000056],
              [-80.845978249999973, 35.353908908000051],
              [-80.845800248999979, 35.354302908000079],
              [-80.845062248999966, 35.35594190900008],
              [-80.84378124899996, 35.358747909000044],
              [-80.843527249999966, 35.359337910000079],
              [-80.843446248999953, 35.359552909000058],
              [-80.843366249999974, 35.359770910000066],
              [-80.843182249999984, 35.360289909000073],
              [-80.843027248999988, 35.360826910000071],
              [-80.842946248999965, 35.361143909000077],
              [-80.842781248999984, 35.361898911000026],
              [-80.842690249999976, 35.362449910000066],
              [-80.842658247999964, 35.362696910000068],
              [-80.842633249999949, 35.362896910000075],
              [-80.842595249999988, 35.363328910000064],
              [-80.842582248999975, 35.363592910000079],
              [-80.843247248999944, 35.363508910000064],
              [-80.845008249999978, 35.363285911000048],
              [-80.849229249999951, 35.362753910000038],
              [-80.850188250999963, 35.362592910000046],
              [-80.850817251999956, 35.362449909000077],
              [-80.851128251999967, 35.362367911000035],
              [-80.851740251999956, 35.362183910000056],
              [-80.851837251999939, 35.362149910000028],
              [-80.85204225199999, 35.362081910000029],
              [-80.852340251999976, 35.361972909000031],
              [-80.852634251999973, 35.361856910000029],
              [-80.85292425199998, 35.361734910000052],
              [-80.853158251999957, 35.361623910000048],
              [-80.853314251999961, 35.361550909000073],
              [-80.853458251999939, 35.361483910000061],
              [-80.853598250999937, 35.361418909000065],
              [-80.853786252999953, 35.361317909000036],
              [-80.854139252999971, 35.361129910000045],
              [-80.854660251999974, 35.360815910000042],
              [-80.855160251999962, 35.360479909000048],
              [-80.855636252999943, 35.360121910000032],
              [-80.855865252999934, 35.35993490900006],
              [-80.856088252999939, 35.359742909000033],
              [-80.856304252999962, 35.359545909000076],
              [-80.856513252999946, 35.359344910000061],
              [-80.856764252999938, 35.359087909000039],
              [-80.857258252999941, 35.358572909000031],
              [-80.859667252999941, 35.356071908000047],
              [-80.860474254999986, 35.355230909000056],
              [-80.861398253999937, 35.354271908000044],
              [-80.863931254999954, 35.351639907000049],
              [-80.864436254999987, 35.35112190600006],
              [-80.864608254999951, 35.350945907000039],
              [-80.864791253999954, 35.350768907000031],
              [-80.865117254999973, 35.350454906000039],
              [-80.865509254999949, 35.350102907000064],
              [-80.865969254999982, 35.349717906000024],
              [-80.866274254999951, 35.349474907000058],
              [-80.866586254999959, 35.349236906000044],
              [-80.867227255999978, 35.348777906000066],
              [-80.86755625699999, 35.348555906000058],
              [-80.867856254999936, 35.348365907000073],
              [-80.868223255999965, 35.348134906000041],
              [-80.868391255999938, 35.348037905000069],
              [-80.868740255999967, 35.347840906000044],
              [-80.869094256999972, 35.347650906000069],
              [-80.869816256999968, 35.347288906000074],
              [-80.870183256999951, 35.347116906000053],
              [-80.870932257999982, 35.346791905000032],
              [-80.871312256999943, 35.346639906000064],
              [-80.87169625699994, 35.346493906000035],
              [-80.872083257999975, 35.346353906000047],
              [-80.872474257999954, 35.346220906000042],
              [-80.872869256999934, 35.346094905000029],
              [-80.872944256999972, 35.346071905000031],
              [-80.873266257999944, 35.345974905000048],
              [-80.874069256999974, 35.34575690500003],
              [-80.876076257999955, 35.34524990500006],
              [-80.876019258999975, 35.345217905000027],
              [-80.875943258999939, 35.345167906000029],
              [-80.875864258999968, 35.345129906000068],
              [-80.875750257999982, 35.345025905000057],
              [-80.875687257999971, 35.344985905000044],
              [-80.875622257999964, 35.344963906000032],
              [-80.875587257999939, 35.344944905000034],
              [-80.875529257999972, 35.344871906000037],
              [-80.875524257999984, 35.344767905000026],
              [-80.875464256999976, 35.34471990600008],
              [-80.875423256999966, 35.344658906000063],
              [-80.875308257999961, 35.344568905000074],
              [-80.875266257999954, 35.344523906000063],
              [-80.875219257999959, 35.344473905000029],
              [-80.875209257999984, 35.34442090500005],
              [-80.87505325799998, 35.344253905000073],
              [-80.875013256999978, 35.344196905000047],
              [-80.874999257999946, 35.34417690500004],
              [-80.874941256999989, 35.343930905000036],
              [-80.874909257999946, 35.343850905000068],
              [-80.87487325799998, 35.343790905000048],
              [-80.874815256999966, 35.343730905000029],
              [-80.874748257999954, 35.343679905000045],
              [-80.874684256999956, 35.343617905000031],
              [-80.874663256999952, 35.343544905000044],
              [-80.874619257999939, 35.34348290500003],
              [-80.874568256999964, 35.343425905000061],
              [-80.874513257999979, 35.343380904000071],
              [-80.874460257999942, 35.343310905000067],
              [-80.874426257999971, 35.343250905000048],
              [-80.874362257999962, 35.343200904000071],
              [-80.874302257999943, 35.343145905000029],
              [-80.874254256999961, 35.343042905000061],
              [-80.874214256999949, 35.34298390500004],
              [-80.874105257999986, 35.342870905000041],
              [-80.874044257999969, 35.342833905000077],
              [-80.87400325699997, 35.342813904000025],
              [-80.873904256999936, 35.342722905000073],
              [-80.873830257999941, 35.342671905000032],
              [-80.873765256999945, 35.342647905000035],
              [-80.873615256999983, 35.342565905000072],
              [-80.873546256999987, 35.342515905000027],
              [-80.873480256999983, 35.342439904000059],
              [-80.873373256999969, 35.342395905000046],
              [-80.873302256999978, 35.342352905000041],
              [-80.87312125599999, 35.342258905000051],
              [-80.873042255999962, 35.342206904000079],
              [-80.873025256999938, 35.342136905000075],
              [-80.872946255999977, 35.342116905000069],
              [-80.872855256999969, 35.342115905000071],
              [-80.872780256999988, 35.342124906000038],
              [-80.872658256999955, 35.342158905000076],
              [-80.872555255999941, 35.342139905000067],
              [-80.872447256999976, 35.342135905000077],
              [-80.872362256999963, 35.342124905000048],
              [-80.872265256999981, 35.342121905000056],
              [-80.872159256999964, 35.342112905000079],
              [-80.872060255999941, 35.342082905000041],
              [-80.871981256999959, 35.342043905000025],
              [-80.871842256999969, 35.342006905000062],
              [-80.871672256999943, 35.341942905000053],
              [-80.871579256999951, 35.341940905000058],
              [-80.871466256999952, 35.341946905000043],
              [-80.871404256999938, 35.341969905000042],
              [-80.871306255999968, 35.341994904000046],
              [-80.871229255999936, 35.341969906000031],
              [-80.87114025599999, 35.341918904000067],
              [-80.871089256999937, 35.34190690500003],
              [-80.871035255999971, 35.341932904000032],
              [-80.871018255999957, 35.341954904000033],
              [-80.871008256999971, 35.341978905000076],
              [-80.871007255999984, 35.342089904000034],
              [-80.871004255999935, 35.342128904000049],
              [-80.87099025699996, 35.342157905000079],
              [-80.870901255999968, 35.342230904000075],
              [-80.870752256999936, 35.342310905000033],
              [-80.870561255999974, 35.342391905000056],
              [-80.870284255999934, 35.342478904000075],
              [-80.870222255999977, 35.342508905000045],
              [-80.870180255999969, 35.342529905000049],
              [-80.870096254999964, 35.342599905000043],
              [-80.870048256999951, 35.342670906000023],
              [-80.870020255999975, 35.342766905000076],
              [-80.870004255999959, 35.342870905000041],
              [-80.869980255999963, 35.342920905000028],
              [-80.869934255999965, 35.342972905000067],
              [-80.869884255999978, 35.342997905000061],
              [-80.869795255999975, 35.343013905000078],
              [-80.869633255999986, 35.343019905000062],
              [-80.869533255999954, 35.343055905000028],
              [-80.869312255999944, 35.34316390500004],
              [-80.869262255999956, 35.343177906000051],
              [-80.869195255999955, 35.343206905000045],
              [-80.868991254999969, 35.343324905000031],
              [-80.868879255999957, 35.343345906000025],
              [-80.868802255999981, 35.343367905000036],
              [-80.868693254999982, 35.343412905000037],
              [-80.868482254999947, 35.343525905000035],
              [-80.868427255999961, 35.343563905000053],
              [-80.868366255999945, 35.343592905000037],
              [-80.868285255999979, 35.343613905000041],
              [-80.86814725499994, 35.343634905000044],
              [-80.868058254999937, 35.343668905000072],
              [-80.868035254999938, 35.343723906000037],
              [-80.868016254999986, 35.343745905000048],
              [-80.867997255999967, 35.343777906000071],
              [-80.867976255999963, 35.343826905000071],
              [-80.867947255999979, 35.343867905000025],
              [-80.867900254999938, 35.343897906000052],
              [-80.867791255999975, 35.343930905000036],
              [-80.867679254999985, 35.343977905000031],
              [-80.867460255999958, 35.344049905000077],
              [-80.86738425599998, 35.34413590500003],
              [-80.867295255999977, 35.344206906000068],
              [-80.867208255999969, 35.344249905000027],
              [-80.867145255999958, 35.34427090500003],
              [-80.867091255999981, 35.344303905000061],
              [-80.867064255999935, 35.344332905000044],
              [-80.867023255999982, 35.344349906000048],
              [-80.866867255999978, 35.344359906000079],
              [-80.866831254999965, 35.344366906000062],
              [-80.866766254999959, 35.344402905000038],
              [-80.86673925599996, 35.344405906000077],
              [-80.866661255999986, 35.344374905000052],
              [-80.866613254999947, 35.344377905000044],
              [-80.866544255999941, 35.344393905000061],
              [-80.866425255999957, 35.344379906000029],
              [-80.866328254999985, 35.344399906000035],
              [-80.86619925499997, 35.344437906000053],
              [-80.866057254999987, 35.344455906000064],
              [-80.865926254999977, 35.344465906000039],
              [-80.865780254999947, 35.344486905000053],
              [-80.865643254999952, 35.344541905000028],
              [-80.86550525499996, 35.344567905000076],
              [-80.865444253999954, 35.344585906000077],
              [-80.86536225499998, 35.344593906000057],
              [-80.865189253999972, 35.344615905000069],
              [-80.865083254999945, 35.344632905000026],
              [-80.865010254999959, 35.344654906000073],
              [-80.864953254999989, 35.344679906000067],
              [-80.864922254999954, 35.344697906000079],
              [-80.864792254999941, 35.344716906000031],
              [-80.864661254999987, 35.34472790600006],
              [-80.864562253999964, 35.344750906000058],
              [-80.864455254999939, 35.344807906000028],
              [-80.864371253999934, 35.344836906000069],
              [-80.86433825499995, 35.34489990600008],
              [-80.864261254999974, 35.344933906000051],
              [-80.864232253999944, 35.34494890600007],
              [-80.864131253999972, 35.345000905000063],
              [-80.864091253999959, 35.345022906000054],
              [-80.863818254999956, 35.345113906000051],
              [-80.863573254999949, 35.34523490600003],
              [-80.86350125499996, 35.345254906000036],
              [-80.863424254999984, 35.345259906000024],
              [-80.863376253999945, 35.345255906000034],
              [-80.863278254999955, 35.345296906000044],
              [-80.863202253999987, 35.345317906000048],
              [-80.863101253999957, 35.345361906000051],
              [-80.863076252999974, 35.345378906000064],
              [-80.863052254999957, 35.345393906000027],
              [-80.862985254999955, 35.345419906000075],
              [-80.862891254999965, 35.345445906000066],
              [-80.86273225399998, 35.345493906000058],
              [-80.862652253999954, 35.345520906000047],
              [-80.862589252999953, 35.345536906000063],
              [-80.862462254999969, 35.34556490600005],
              [-80.862298253999938, 35.345589906000043],
              [-80.862180253999952, 35.34562190500003],
              [-80.862106253999968, 35.34564690600007],
              [-80.861981253999943, 35.345694906000062],
              [-80.861898253999982, 35.34573290600008],
              [-80.86174525399997, 35.34579390600004],
              [-80.861717253999984, 35.345805906000066],
              [-80.861567253999965, 35.345853906000059],
              [-80.86144225299995, 35.345948906000046],
              [-80.861316253999973, 35.345996906000039],
              [-80.861182253999971, 35.346016906000045],
              [-80.861102253999945, 35.346025907000069],
              [-80.861003253999968, 35.346000906000029],
              [-80.860979253999972, 35.345979906000025],
              [-80.860884253999984, 35.345913906000078],
              [-80.860767253999938, 35.345847906000074],
              [-80.86069925399994, 35.34579390600004],
              [-80.860557252999968, 35.345697906000055],
              [-80.860373252999977, 35.345572906000029],
              [-80.860295253999936, 35.345520905000058],
              [-80.860162252999942, 35.345421907000059],
              [-80.860094252999943, 35.345374906000075],
              [-80.860045253999942, 35.345337906000054],
              [-80.860010252999984, 35.345313906000058],
              [-80.859939252999936, 35.345258906000026],
              [-80.859772253999949, 35.34513690600005],
              [-80.859689252999942, 35.345086906000063],
              [-80.85963725299996, 35.345058906000077],
              [-80.859461252999949, 35.344967906000079],
              [-80.859083251999948, 35.34478090600004],
              [-80.859014253999987, 35.344733906000044],
              [-80.858969252999941, 35.344701905000079],
              [-80.858871252999961, 35.344640906000052],
              [-80.858827252999959, 35.34462290700003],
              [-80.858675252999944, 35.344549905000065],
              [-80.858551251999984, 35.344497906000072],
              [-80.858490252999957, 35.34444290600004],
              [-80.858208252999987, 35.344294906000073],
              [-80.858118252999986, 35.344230906000064],
              [-80.858035251999979, 35.344189906000054],
              [-80.857950251999966, 35.344166906000055],
              [-80.857902252999963, 35.344158905000029],
              [-80.857850252999981, 35.344147905000057],
              [-80.85779125199997, 35.34412790600004],
              [-80.857742252999969, 35.344134906000079],
              [-80.857694251999988, 35.344120905000068],
              [-80.857617251999955, 35.344056907000038],
              [-80.857526252999946, 35.344004905000077],
              [-80.857328252999935, 35.343986906000055],
              [-80.857149251999942, 35.343946906000042],
              [-80.857051251999962, 35.343928906000031],
              [-80.856992251999941, 35.343908906000024],
              [-80.856923252999934, 35.343891906000067],
              [-80.856863251999982, 35.34387990700003],
              [-80.856812252999987, 35.343873905000066],
              [-80.856751251999981, 35.343856906000042],
              [-80.856699251999942, 35.343834906000041],
              [-80.856516252999938, 35.343791905000046],
              [-80.856365251999989, 35.343717906000052],
              [-80.856302251999978, 35.343714906000059],
              [-80.856277251999984, 35.343700906000038],
              [-80.856190252999966, 35.343674906000047],
              [-80.856041251999955, 35.34364690600006],
              [-80.855951251999954, 35.343624906000059],
              [-80.855898251999974, 35.343621906000067],
              [-80.855843250999953, 35.343610905000048],
              [-80.855794252999942, 35.343606906000048],
              [-80.855721252999956, 35.343597905000024],
              [-80.855625250999935, 35.343589906000034],
              [-80.85551725199997, 35.343578906000062],
              [-80.85547625099997, 35.343575906000069],
              [-80.855434251999952, 35.343579906000059],
              [-80.855320251999956, 35.343578905000072],
              [-80.855299251999952, 35.34356490600004],
              [-80.855281251999941, 35.343545906000031],
              [-80.855241251999985, 35.343535905000067],
              [-80.85519425199999, 35.343530906000069],
              [-80.855165251999949, 35.343520906000037],
              [-80.855027250999967, 35.343499906000034],
              [-80.854992251999988, 35.343487906000064],
              [-80.854908250999983, 35.343480905000035],
              [-80.854783251999947, 35.343448906000049],
              [-80.854733250999971, 35.343446906000054],
              [-80.854693251999947, 35.343434906000027],
              [-80.85464725199995, 35.343432906000032],
              [-80.85459825099997, 35.343439905000025],
              [-80.854546251999977, 35.343436906000079],
              [-80.854499251999982, 35.343427906000045],
              [-80.85440325199994, 35.343390906000025],
              [-80.854296251999983, 35.343358906000049],
              [-80.854239251999957, 35.343334907000042],
              [-80.854192250999972, 35.343324906000078],
              [-80.85414825099997, 35.34332390600008],
              [-80.854066250999949, 35.343299905000038],
              [-80.854022250999947, 35.343279906000078],
              [-80.853975250999952, 35.343265906000056],
              [-80.853920251999966, 35.343258905000027],
              [-80.85385525199996, 35.343265905000067],
              [-80.853795250999951, 35.343260905000079],
              [-80.853543250999962, 35.34318890600008],
              [-80.853445250999982, 35.343150905000073],
              [-80.853301250999948, 35.343121906000079],
              [-80.853205250999963, 35.343084906000058],
              [-80.853153250999981, 35.343052905000036],
              [-80.85306325099998, 35.343017906000057],
              [-80.853003251999951, 35.342970905000072],
              [-80.852959250999959, 35.342944906000071],
              [-80.852909250999971, 35.342923906000067],
              [-80.852849250999952, 35.342902906000063],
              [-80.852769250999984, 35.342867906000038],
              [-80.852701250999985, 35.342840906000049],
              [-80.852651250999941, 35.342818906000048],
              [-80.852623250999955, 35.342801905000044],
              [-80.852573250999967, 35.342761906000078],
              [-80.85247825099998, 35.342707905000054],
              [-80.85239224999998, 35.342643906000035],
              [-80.852332249999961, 35.342605905000028],
              [-80.852176249999957, 35.342532906000031],
              [-80.852128250999954, 35.342493906000072],
              [-80.852068250999935, 35.34246190500005],
              [-80.851877250999962, 35.342332906000024],
              [-80.851678249999964, 35.342167905000053],
              [-80.851593249999951, 35.342143906000047],
              [-80.851541249999968, 35.342121906000045],
              [-80.851495249999971, 35.342107905000034],
              [-80.85144625099997, 35.342049905000067],
              [-80.851392250999936, 35.342006905000062],
              [-80.851354250999975, 35.341989906000038],
              [-80.851237250999986, 35.341932905000078],
              [-80.851070250999953, 35.341832906000036],
              [-80.850754249999966, 35.341687906000061],
              [-80.850596249999967, 35.341647905000059],
              [-80.850426249999941, 35.341607905000046],
              [-80.85039625099995, 35.341590906000079],
              [-80.850304249999965, 35.34157990600005],
              [-80.850251249999985, 35.341564906000031],
              [-80.850136249999935, 35.341554906000056],
              [-80.850061249999953, 35.341536906000044],
              [-80.850022249999938, 35.341509905000066],
              [-80.849859249999952, 35.341476906000025],
              [-80.849737248999986, 35.341447905000052],
              [-80.849630249999962, 35.341426905000048],
              [-80.849529249999989, 35.341417905000071],
              [-80.849463249999985, 35.341393905000075],
              [-80.849323250999987, 35.341356906000044],
              [-80.848987249999936, 35.341150905000063],
              [-80.848983249999947, 35.341217905000065],
              [-80.848968249999984, 35.341562905000046],
              [-80.848878248999938, 35.342863906000048],
              [-80.84881224999998, 35.343577906000064],
              [-80.848761249999939, 35.344030906000057],
              [-80.848647249999942, 35.344846907000033],
              [-80.848617249999961, 35.345005907000029],
              [-80.848531249999951, 35.345423906000065],
              [-80.848390249999966, 35.346195907000038]
            ]
          ],
          [
            [
              [-80.831795245999956, 35.370615912000062],
              [-80.831913246999989, 35.37077391300005],
              [-80.832052245999989, 35.37097291200007],
              [-80.832158245999949, 35.371142913000028],
              [-80.832249245999947, 35.371304912000028],
              [-80.832343245999937, 35.371485912000026],
              [-80.83246524599997, 35.371762913000055],
              [-80.832565246999934, 35.372014913000044],
              [-80.832772245999934, 35.37260191300004],
              [-80.83287824699994, 35.372945913000024],
              [-80.83296824599995, 35.373258913000029],
              [-80.833045246999973, 35.373558913000068],
              [-80.833119246999956, 35.373883913000043],
              [-80.833191246999945, 35.374229912000033],
              [-80.833272246999968, 35.374671914000032],
              [-80.833336245999988, 35.37513391300007],
              [-80.833591246999958, 35.377562913000077],
              [-80.834132247999946, 35.382744916000036],
              [-80.83421724699997, 35.383565915000077],
              [-80.835030248999942, 35.383299914000077],
              [-80.835683247999953, 35.383086915000035],
              [-80.837083247999942, 35.382631915000047],
              [-80.84177724999995, 35.381115914000077],
              [-80.842581249999967, 35.380856915000038],
              [-80.84414124999995, 35.380333913000072],
              [-80.84308324999995, 35.376645913000061],
              [-80.844964250999965, 35.376458913000079],
              [-80.845338249999941, 35.376424913000051],
              [-80.847063251999941, 35.376251913000033],
              [-80.847508251999955, 35.37620791300003],
              [-80.853866253999968, 35.37560691300007],
              [-80.854165252999962, 35.375584913000068],
              [-80.854194252999946, 35.375382912000077],
              [-80.854450252999982, 35.37385091200008],
              [-80.854625252999938, 35.372838912000077],
              [-80.854687252999952, 35.372445912000046],
              [-80.85474725399996, 35.372173912000051],
              [-80.854812252999977, 35.371954912000035],
              [-80.854901253999969, 35.371711911000034],
              [-80.85500725299994, 35.371474912000053],
              [-80.855492252999966, 35.370564912000077],
              [-80.856462252999961, 35.368695911000032],
              [-80.856499253999971, 35.368275912000058],
              [-80.85647325299999, 35.367982912000059],
              [-80.856298252999977, 35.367475911000042],
              [-80.855906252999944, 35.366825911000035],
              [-80.855825252999978, 35.366837910000072],
              [-80.855620252999984, 35.366853911000078],
              [-80.85544925399995, 35.366856910000024],
              [-80.855395252999983, 35.366854911000075],
              [-80.855298252999944, 35.366851911000026],
              [-80.855123251999942, 35.36683891000007],
              [-80.854922252999984, 35.366816910000068],
              [-80.854644252999947, 35.36677891100004],
              [-80.853643252999973, 35.366628911000078],
              [-80.85269525199999, 35.366463911000039],
              [-80.852489251999941, 35.366435911000053],
              [-80.851776251999979, 35.366369911000049],
              [-80.850943251999979, 35.36630591100004],
              [-80.850530251999942, 35.366274910000072],
              [-80.849587250999946, 35.366192910000052],
              [-80.849233250999987, 35.366167911000048],
              [-80.849068250999949, 35.366160911000065],
              [-80.84891425099994, 35.366160911000065],
              [-80.848784250999984, 35.366167911000048],
              [-80.848532250999938, 35.366196911000031],
              [-80.847870250999961, 35.366319911000062],
              [-80.846682249999958, 35.366550911000047],
              [-80.846228249999967, 35.366632911000067],
              [-80.845420249999961, 35.36677891100004],
              [-80.845138248999945, 35.366835910000077],
              [-80.844874250999965, 35.366896911000026],
              [-80.844637249999948, 35.366968911000072],
              [-80.844483249999939, 35.367025911000042],
              [-80.844294249999962, 35.367112911000049],
              [-80.844057249999935, 35.367244911000057],
              [-80.843761249999943, 35.367414912000072],
              [-80.843698249999989, 35.367451912000035],
              [-80.843545248999988, 35.367541911000046],
              [-80.843060249999951, 35.367830911000055],
              [-80.84257624899999, 35.368119912000054],
              [-80.842105248999985, 35.368396911000048],
              [-80.841551248999963, 35.368723912000064],
              [-80.841224248999936, 35.368910911000057],
              [-80.840925248999952, 35.369062911000071],
              [-80.839159248999977, 35.369913912000072],
              [-80.838736248999965, 35.370107912000037],
              [-80.838531247999981, 35.370189912000058],
              [-80.83834924699994, 35.370249912000077],
              [-80.83818324799995, 35.370291912000027],
              [-80.838012247999984, 35.370325913000045],
              [-80.837812247999977, 35.370350912000049],
              [-80.837564247999978, 35.370370912000055],
              [-80.837265247999937, 35.370386912000072],
              [-80.83698224799997, 35.370393912000054],
              [-80.834968247999939, 35.370380913000076],
              [-80.833984246999989, 35.370381912000028],
              [-80.833860247999951, 35.370378912000035],
              [-80.833770245999972, 35.370377913000027],
              [-80.833201246999977, 35.370350912000049],
              [-80.832701246999989, 35.370312912000031],
              [-80.832261245999973, 35.370272912000075],
              [-80.831521245999966, 35.370195912000042],
              [-80.831443245999935, 35.370191912000053],
              [-80.831593245999954, 35.370365912000068],
              [-80.831795245999956, 35.370615912000062]
            ]
          ],
          [
            [
              [-80.836849254999947, 35.497647939000046],
              [-80.837491253999985, 35.49804793900006],
              [-80.837936254999988, 35.498325940000029],
              [-80.83814225499998, 35.498453939000058],
              [-80.837683255999934, 35.49966493900007],
              [-80.83750125499995, 35.500142940000046],
              [-80.83564325399999, 35.504432941000061],
              [-80.83532525399994, 35.505180940000059],
              [-80.833636253999941, 35.509156942000061],
              [-80.83350825499997, 35.509446942000068],
              [-80.833928253999943, 35.509477942000046],
              [-80.834491253999943, 35.509518942000057],
              [-80.839295255999957, 35.509867941000039],
              [-80.839820256999985, 35.509906941000054],
              [-80.840497255999935, 35.509956942000031],
              [-80.841372256999989, 35.51002094100005],
              [-80.845313257999976, 35.510307942000054],
              [-80.845850258999974, 35.510346942000069],
              [-80.852927260999934, 35.510861942000076],
              [-80.855593261999957, 35.511055941000052],
              [-80.855796261999956, 35.511070941000071],
              [-80.859166261999974, 35.511315941000078],
              [-80.860223261999977, 35.511392941000054],
              [-80.861301261999984, 35.511481941000056],
              [-80.861958263999952, 35.51153594200008],
              [-80.862105262999989, 35.51154794100006],
              [-80.865199263999955, 35.511773941000058],
              [-80.865529263999974, 35.511796941000057],
              [-80.865653263999945, 35.511805942000024],
              [-80.865885263999985, 35.511822941000048],
              [-80.865889263999975, 35.511753941000052],
              [-80.865991263999945, 35.51034694100008],
              [-80.866075264999949, 35.509069940000074],
              [-80.866135263999979, 35.508307940000066],
              [-80.866156262999937, 35.508097940000027],
              [-80.866217263999943, 35.507328940000036],
              [-80.86629226499997, 35.506379940000045],
              [-80.866350263999948, 35.50550794000003],
              [-80.866438263999953, 35.504329939000058],
              [-80.866471263999983, 35.503896939000072],
              [-80.866498263999972, 35.503666940000073],
              [-80.866527263999956, 35.503512939000075],
              [-80.866611263999971, 35.503072940000038],
              [-80.866662264999945, 35.502850939000041],
              [-80.866760263999936, 35.502533939000045],
              [-80.866980263999949, 35.50193893900007],
              [-80.867067264999946, 35.501737939000066],
              [-80.86732826299999, 35.501231938000046],
              [-80.867368262999946, 35.501143938000041],
              [-80.867466263999972, 35.500925939000069],
              [-80.867752264999979, 35.500355939000031],
              [-80.867860263999944, 35.500140939000062],
              [-80.867917263999971, 35.500027938000073],
              [-80.868013263999956, 35.499830938000059],
              [-80.868197263999946, 35.499465939000061],
              [-80.869199263999974, 35.49744393800006],
              [-80.869399263999981, 35.497048937000045],
              [-80.869423264999966, 35.497003938000034],
              [-80.870231264999973, 35.495378937000055],
              [-80.870567263999988, 35.49469093700003],
              [-80.870921264999936, 35.49398493700005],
              [-80.871335264999971, 35.493177937000041],
              [-80.871768264999957, 35.492276936000053],
              [-80.873348264999947, 35.489092935000031],
              [-80.873651264999978, 35.488500936000037],
              [-80.873688264999942, 35.488424936000058],
              [-80.873735265999983, 35.489705936000064],
              [-80.873947265999959, 35.489589937000062],
              [-80.874613264999937, 35.489211936000061],
              [-80.874893264999969, 35.489044936000028],
              [-80.875249264999979, 35.488799935000031],
              [-80.875899265999976, 35.48831593500006],
              [-80.87657726599997, 35.487826935000044],
              [-80.877469265999935, 35.487182935000078],
              [-80.877952266999955, 35.486827936000054],
              [-80.878275265999946, 35.48659393500003],
              [-80.878975265999941, 35.48609093500005],
              [-80.879307266999945, 35.48640993500004],
              [-80.879781266999942, 35.486857935000046],
              [-80.879895266999938, 35.486954935000028],
              [-80.880375266999977, 35.487403936000078],
              [-80.880540266999958, 35.487552935000053],
              [-80.880686266999987, 35.487625936000029],
              [-80.880819267999982, 35.487652935000028],
              [-80.880990267999948, 35.487654936000069],
              [-80.881712267999944, 35.487570935000065],
              [-80.882123267999987, 35.487518935000026],
              [-80.882296267999948, 35.487501935000068],
              [-80.882440267999982, 35.487480935000065],
              [-80.882519267999953, 35.487462935000053],
              [-80.882591267999942, 35.487434935000067],
              [-80.882687267999984, 35.48737793500004],
              [-80.88285826799995, 35.48724293500004],
              [-80.883046266999941, 35.487051935000068],
              [-80.883095267999977, 35.486983936000058],
              [-80.883146267999962, 35.48687893500005],
              [-80.883198267999944, 35.486755935000076],
              [-80.883230267999977, 35.48664193500008],
              [-80.883266267999943, 35.486406935000048],
              [-80.883265267999946, 35.486234935000027],
              [-80.883213267999963, 35.486025935000043],
              [-80.883170267999958, 35.485917935000032],
              [-80.883129267999948, 35.485833935000073],
              [-80.883054267999967, 35.485702934000074],
              [-80.883029266999984, 35.485643934000052],
              [-80.883017267999946, 35.485601935000034],
              [-80.883004267999979, 35.485557935000031],
              [-80.882982268999967, 35.485418935000041],
              [-80.882973266999954, 35.485203935000072],
              [-80.88295226799994, 35.484917936000045],
              [-80.882968267999956, 35.484846935000064],
              [-80.882990266999968, 35.48477693500007],
              [-80.88313526799999, 35.484464935000062],
              [-80.883162266999989, 35.484370935000072],
              [-80.883177268999987, 35.484280935000072],
              [-80.883180268999979, 35.484177934000058],
              [-80.883160267999983, 35.484070935000034],
              [-80.883126267999955, 35.483975934000057],
              [-80.883078267999963, 35.483893935000026],
              [-80.883029266999984, 35.483835935000059],
              [-80.882987266999976, 35.483793934000062],
              [-80.882863267999937, 35.483652934000077],
              [-80.882636266999953, 35.483446935000075],
              [-80.883301267999968, 35.482966935000036],
              [-80.883992267999986, 35.482467934000056],
              [-80.884039266999935, 35.482408934000034],
              [-80.884058268999979, 35.482322934000024],
              [-80.884064267999975, 35.482264934000057],
              [-80.88405526799994, 35.48220393400004],
              [-80.884032268999988, 35.482100934000073],
              [-80.883937267999954, 35.481814934000056],
              [-80.884289267999975, 35.48174193400007],
              [-80.884767267999962, 35.481623934000027],
              [-80.885143267999979, 35.481499934000055],
              [-80.885877267999945, 35.481229934000055],
              [-80.88691726899998, 35.480842934000066],
              [-80.887514268999951, 35.480616934000068],
              [-80.888311268999985, 35.480323934000069],
              [-80.888879268999972, 35.48008793300005],
              [-80.889098268999987, 35.47998593300008],
              [-80.889299269999981, 35.479882934000045],
              [-80.889500268999939, 35.479771933000052],
              [-80.889669269999956, 35.479666933000033],
              [-80.889848270999948, 35.479543933000059],
              [-80.889933268999982, 35.479477933000055],
              [-80.890019269999982, 35.479410933000054],
              [-80.890248269999972, 35.47920593300006],
              [-80.890387269999962, 35.47907093300006],
              [-80.890566269999965, 35.478874933000043],
              [-80.890699269999971, 35.478709933000061],
              [-80.890917269999989, 35.478397933000053],
              [-80.890989269999977, 35.478281933000062],
              [-80.891119268999944, 35.478034932000071],
              [-80.891174270999954, 35.477908933000037],
              [-80.891290269999956, 35.477598933000024],
              [-80.891347269999983, 35.47742693400005],
              [-80.891413268999941, 35.477200933000063],
              [-80.891453269999943, 35.476992933000076],
              [-80.891472268999962, 35.476791933000072],
              [-80.891476269999941, 35.47669893300008],
              [-80.891469269999959, 35.476215933000049],
              [-80.891485269999976, 35.475475933000041],
              [-80.891500269999938, 35.474899932000028],
              [-80.891515269999957, 35.474555933000033],
              [-80.891544270999987, 35.473583932000054],
              [-80.891590268999948, 35.471890932000065],
              [-80.891590269999938, 35.47165693200003],
              [-80.891576269999973, 35.471411931000034],
              [-80.891560269999957, 35.471289931000058],
              [-80.891509268999982, 35.471054931000026],
              [-80.891453269999943, 35.47087993100007],
              [-80.891415269999982, 35.470776932000035],
              [-80.891357269999958, 35.470656932000054],
              [-80.891210269999988, 35.470399932000078],
              [-80.890530269999942, 35.469354932000044],
              [-80.889528268999982, 35.467834930000038],
              [-80.889273268999943, 35.467422931000044],
              [-80.889160268999944, 35.467216930000063],
              [-80.889095267999949, 35.467079930000068],
              [-80.888977267999962, 35.466790930000059],
              [-80.888917267999943, 35.466592931000037],
              [-80.888875268999982, 35.466416930000037],
              [-80.888858267999979, 35.46634393000005],
              [-80.888833268999974, 35.466186931000038],
              [-80.888820267999961, 35.466102930000034],
              [-80.888799268999946, 35.465884930000072],
              [-80.888784267999938, 35.465722930000027],
              [-80.888771267999971, 35.465521930000079],
              [-80.888752268999951, 35.464896930000066],
              [-80.888713268999936, 35.463457930000061],
              [-80.888706267999964, 35.463221930000032],
              [-80.888695268999982, 35.46277493000008],
              [-80.888657268999964, 35.461316930000066],
              [-80.888641268999947, 35.460774929000024],
              [-80.888628267999934, 35.46001792900006],
              [-80.88860626799999, 35.45926692900008],
              [-80.888590266999984, 35.458311929000047],
              [-80.888547267999968, 35.456932929000061],
              [-80.88848726699996, 35.454348928000059],
              [-80.88847526699999, 35.454072928000073],
              [-80.888471267999989, 35.453916928000069],
              [-80.888455267999973, 35.453324929000075],
              [-80.888450266999939, 35.453141928000036],
              [-80.888442267999949, 35.452572928000052],
              [-80.888452266999934, 35.452399928000034],
              [-80.888463267999953, 35.452251928000067],
              [-80.888468266999951, 35.452191928000047],
              [-80.888494267999988, 35.452038927000046],
              [-80.888535267999941, 35.451862927000036],
              [-80.888588267999978, 35.451704928000026],
              [-80.888678267999978, 35.451504928000077],
              [-80.888785266999946, 35.451318927000045],
              [-80.888899267999989, 35.451154928000051],
              [-80.889074267999945, 35.450947927000072],
              [-80.889237266999942, 35.450780927000039],
              [-80.889460268999983, 35.450584927000079],
              [-80.890052267999977, 35.450104927000041],
              [-80.891016267999987, 35.449325928000064],
              [-80.891331268999977, 35.44905092700003],
              [-80.891498268999953, 35.448885926000059],
              [-80.891644268999983, 35.448730927000042],
              [-80.891778268999985, 35.448571927000046],
              [-80.891933268999935, 35.448363927000059],
              [-80.89211826899998, 35.44807492700005],
              [-80.892181268999934, 35.447970927000028],
              [-80.89231426799995, 35.447729926000079],
              [-80.89290626899998, 35.446590926000056],
              [-80.893027268999958, 35.446370926000043],
              [-80.893216267999946, 35.446092926000063],
              [-80.893284267999945, 35.446009926000045],
              [-80.893324267999958, 35.445959925000068],
              [-80.893410268999958, 35.445873926000047],
              [-80.893504268999948, 35.445784926000044],
              [-80.893702268999959, 35.445618926000066],
              [-80.893965269999967, 35.44543692600007],
              [-80.894119268999987, 35.445303926000065],
              [-80.894210268999984, 35.445217925000065],
              [-80.89428326899997, 35.445140925000032],
              [-80.894345269999974, 35.445036926000057],
              [-80.894395268999972, 35.44491892700006],
              [-80.894425269999942, 35.44478792600006],
              [-80.894431269999984, 35.444622926000079],
              [-80.89405026899999, 35.444616926000037],
              [-80.893812268999966, 35.444602926000073],
              [-80.893673267999986, 35.444598925000037],
              [-80.893326267999953, 35.444571926000037],
              [-80.892497267999943, 35.444494926000061],
              [-80.890481266999984, 35.444308926000076],
              [-80.890381266999952, 35.44429692600005],
              [-80.890323267999975, 35.444290926000065],
              [-80.888773267999966, 35.444144926000035],
              [-80.886199266999938, 35.443922926000027],
              [-80.88533126599998, 35.443840926000064],
              [-80.884337264999942, 35.443740926000032],
              [-80.883938266999962, 35.443701926000074],
              [-80.883225264999965, 35.443624926000041],
              [-80.881983264999974, 35.443511926000042],
              [-80.880510263999952, 35.443379925000045],
              [-80.879431263999948, 35.443282925000062],
              [-80.878436264999948, 35.443182926000077],
              [-80.877693263999959, 35.443107926000039],
              [-80.877776263999976, 35.443024927000067],
              [-80.877848263999965, 35.442874925000069],
              [-80.878206263999971, 35.442355926000062],
              [-80.878255263999961, 35.442268925000064],
              [-80.878320263999967, 35.442170926000074],
              [-80.878365263999967, 35.442111926000052],
              [-80.878381264999973, 35.442043925000064],
              [-80.878377263999937, 35.44192892600006],
              [-80.878383263999979, 35.441858925000076],
              [-80.878411263999965, 35.441768926000066],
              [-80.878442262999954, 35.441653926000072],
              [-80.878447263999988, 35.441574925000054],
              [-80.87846626399994, 35.441514925000035],
              [-80.878523262999977, 35.441421926000032],
              [-80.878569263999964, 35.441335925000033],
              [-80.87858926499996, 35.441313925000031],
              [-80.878596264999942, 35.441310926000028],
              [-80.878628263999985, 35.441310926000028],
              [-80.878676262999988, 35.441313925000031],
              [-80.878765263999981, 35.441314925000029],
              [-80.878815263999968, 35.441310926000028],
              [-80.878842264999946, 35.441307926000036],
              [-80.878888263999954, 35.441296926000064],
              [-80.878971263999972, 35.441280926000047],
              [-80.879049263999946, 35.441248926000071],
              [-80.879086263999966, 35.44122692600007],
              [-80.87911526399995, 35.441206926000064],
              [-80.879135264999945, 35.441188926000052],
              [-80.879147263999982, 35.441170925000051],
              [-80.879161264999937, 35.441143926000052],
              [-80.879172264999966, 35.441109926000024],
              [-80.87919326399998, 35.441038926000033],
              [-80.879206263999947, 35.440965925000057],
              [-80.879222262999974, 35.440904925000041],
              [-80.879228263999948, 35.440885925000032],
              [-80.879253263999942, 35.440854926000043],
              [-80.879282262999936, 35.440831925000055],
              [-80.879314264999948, 35.440826925000067],
              [-80.879353264999963, 35.440835925000044],
              [-80.879382263999958, 35.440838926000026],
              [-80.879405263999956, 35.440831925000055],
              [-80.879419263999978, 35.440815925000038],
              [-80.879425264999952, 35.440795926000078],
              [-80.87942626499995, 35.440774925000028],
              [-80.879430264999939, 35.440746925000042],
              [-80.879429264999942, 35.440706926000075],
              [-80.879428263999955, 35.440674925000053],
              [-80.879432263999945, 35.440603926000051],
              [-80.879437263999989, 35.440574925000078],
              [-80.879447263999964, 35.440549925000028],
              [-80.879442263999977, 35.440525926000078],
              [-80.879442263999977, 35.440505926000071],
              [-80.879452263999951, 35.440488926000057],
              [-80.879470264999952, 35.440473925000049],
              [-80.879492264999953, 35.440465926000059],
              [-80.879552263999983, 35.44045092600004],
              [-80.879592264999985, 35.44044692600005],
              [-80.879628264999951, 35.440440925000075],
              [-80.87965526399995, 35.440432926000028],
              [-80.879690263999976, 35.440419925000072],
              [-80.879794263999941, 35.440357925000058],
              [-80.879860263999944, 35.440315926000039],
              [-80.879895264999959, 35.440289925000059],
              [-80.879937264999967, 35.440233926000076],
              [-80.879949263999947, 35.44020692600003],
              [-80.879985264999959, 35.440137926000034],
              [-80.880027263999978, 35.440067925000051],
              [-80.880048263999981, 35.440044925000052],
              [-80.880081263999955, 35.440023926000038],
              [-80.880117264999967, 35.439997925000057],
              [-80.880149263999954, 35.439979925000046],
              [-80.880248263999988, 35.439950925000062],
              [-80.880336263999936, 35.439926926000055],
              [-80.880422264999936, 35.439911925000047],
              [-80.880474264999975, 35.439904925000064],
              [-80.880516263999937, 35.439900926000064],
              [-80.880570263999971, 35.439903926000056],
              [-80.88059326399997, 35.439905926000051],
              [-80.880622264999943, 35.439899925000077],
              [-80.880640264999954, 35.439875925000024],
              [-80.880652264999981, 35.439812925000069],
              [-80.880661264999958, 35.439742925000076],
              [-80.880679264999969, 35.439706925000053],
              [-80.880712264999943, 35.43967492400003],
              [-80.880734264999944, 35.439641925000046],
              [-80.880772264999962, 35.43957692400005],
              [-80.88080326499994, 35.439538925000079],
              [-80.880865264999954, 35.439452926000058],
              [-80.880895264999936, 35.439394925000045],
              [-80.880907264999962, 35.439364925000064],
              [-80.880932264999956, 35.439323925000053],
              [-80.880964264999989, 35.439304925000044],
              [-80.880996264999965, 35.43928392600003],
              [-80.881045264999955, 35.43923892600003],
              [-80.881105264999974, 35.439177925000024],
              [-80.881153263999977, 35.439116925000064],
              [-80.881253264999941, 35.438978925000072],
              [-80.881278264999935, 35.438949925000031],
              [-80.88133626399997, 35.438893926000048],
              [-80.881396264999978, 35.438840925000079],
              [-80.881427263999967, 35.438814925000031],
              [-80.881476264999947, 35.438762925000049],
              [-80.881502264999938, 35.438730925000073],
              [-80.881533264999973, 35.438677925000036],
              [-80.881567264999944, 35.438616925000076],
              [-80.881593263999946, 35.43856792400004],
              [-80.881650264999962, 35.438489924000066],
              [-80.881676264999953, 35.438436925000076],
              [-80.881697264999957, 35.438401925000051],
              [-80.881717264999963, 35.438379925000049],
              [-80.881735265999964, 35.438350925000066],
              [-80.881761263999977, 35.438324925000074],
              [-80.88183926499994, 35.438283925000064],
              [-80.881862264999938, 35.438264925000055],
              [-80.881911264999985, 35.438197925000054],
              [-80.881945264999956, 35.438146925000069],
              [-80.881967265999947, 35.438120925000078],
              [-80.882046264999985, 35.438023925000039],
              [-80.882140264999975, 35.437927924000064],
              [-80.882174264999946, 35.437890925000033],
              [-80.882202264999989, 35.437867924000045],
              [-80.882237264999958, 35.437831924000079],
              [-80.882294264999985, 35.437778925000032],
              [-80.882410264999976, 35.437645925000027],
              [-80.882449264999934, 35.437586925000062],
              [-80.882499264999979, 35.43752392500005],
              [-80.882532264999952, 35.43749392400008],
              [-80.882605264999938, 35.437412925000046],
              [-80.882631264999986, 35.437374925000029],
              [-80.882667264999952, 35.437328925000031],
              [-80.882696264999936, 35.437286925000024],
              [-80.882744265999975, 35.437223925000069],
              [-80.882782264999946, 35.437180924000074],
              [-80.882873264999944, 35.43711492500006],
              [-80.882902265999974, 35.437095925000051],
              [-80.882917264999946, 35.43707792500004],
              [-80.882960264999952, 35.437030925000045],
              [-80.882977264999965, 35.437009924000051],
              [-80.882997265999961, 35.43699192400004],
              [-80.883043264999969, 35.436927924000031],
              [-80.883063264999976, 35.43688992400007],
              [-80.883088264999969, 35.436850925000044],
              [-80.883110264999971, 35.436823925000056],
              [-80.883134265999956, 35.436802925000052],
              [-80.88316726499994, 35.436770924000029],
              [-80.883218264999982, 35.436709925000059],
              [-80.883243264999976, 35.436682924000024],
              [-80.883281264999937, 35.436647925000045],
              [-80.883312265999962, 35.436622924000062],
              [-80.883378264999976, 35.436534924000057],
              [-80.88339526599998, 35.43650292500007],
              [-80.883528265999985, 35.436391924000077],
              [-80.883565264999959, 35.43634292400003],
              [-80.883585264999965, 35.436306924000064],
              [-80.883606264999969, 35.436280924000073],
              [-80.883630263999976, 35.436256925000066],
              [-80.883667265999975, 35.436225924000041],
              [-80.883719265999957, 35.436168924000071],
              [-80.883748265999941, 35.436110924000047],
              [-80.883797265999988, 35.436040924000054],
              [-80.883834264999962, 35.436017924000055],
              [-80.883871264999982, 35.435983925000073],
              [-80.883947264999961, 35.43592592400006],
              [-80.883976264999944, 35.43588092400006],
              [-80.88401126499997, 35.435836924000057],
              [-80.884078264999971, 35.435757924000029],
              [-80.884097265999969, 35.435723924000058],
              [-80.884130264999953, 35.435654925000051],
              [-80.884147265999957, 35.435634925000045],
              [-80.884202265999988, 35.435579923000034],
              [-80.884267265999938, 35.43553092500008],
              [-80.884308264999959, 35.43549792400006],
              [-80.884370265999962, 35.435441925000077],
              [-80.884413265999967, 35.435396925000077],
              [-80.884463265999955, 35.435334924000074],
              [-80.88450626599996, 35.435269924000067],
              [-80.884565265999981, 35.435206925000045],
              [-80.884625265999944, 35.43515992500005],
              [-80.884677265999983, 35.435086924000075],
              [-80.884710265999956, 35.435057924000034],
              [-80.884747264999987, 35.435023924000063],
              [-80.884800265999957, 35.434962924000047],
              [-80.884854265999934, 35.434902924000028],
              [-80.884921265999935, 35.434815924000077],
              [-80.884947264999937, 35.434788924000031],
              [-80.884995265999976, 35.434731924000062],
              [-80.885031265999942, 35.434686924000061],
              [-80.885083265999981, 35.434628924000037],
              [-80.885133264999979, 35.434564924000028],
              [-80.88516326599995, 35.434523925000065],
              [-80.885174265999979, 35.434502924000071],
              [-80.88520026599997, 35.434464925000043],
              [-80.885224265999966, 35.43443192400008],
              [-80.885243266999964, 35.434360924000032],
              [-80.88524126599998, 35.434332924000046],
              [-80.885231265999948, 35.434287925000035],
              [-80.885218265999981, 35.434257923000075],
              [-80.885193265999987, 35.434212924000065],
              [-80.885184264999964, 35.434183924000024],
              [-80.885170264999942, 35.43410992400004],
              [-80.885160265999957, 35.434061924000048],
              [-80.885158265999962, 35.434040923000055],
              [-80.885162265999952, 35.434016924000048],
              [-80.885183265999956, 35.43394092300008],
              [-80.885183265999956, 35.433916924000073],
              [-80.885177265999971, 35.433818924000036],
              [-80.885176265999974, 35.433773924000036],
              [-80.885184265999953, 35.433695923000073],
              [-80.885184265999953, 35.433639924000033],
              [-80.885185265999951, 35.433564924000052],
              [-80.885190264999949, 35.433475924000049],
              [-80.885168265999937, 35.433372924000025],
              [-80.885189266999987, 35.43329992300005],
              [-80.885201265999967, 35.433264924000071],
              [-80.88521226499995, 35.433244923000075],
              [-80.885219265999979, 35.433218924000073],
              [-80.88521626499994, 35.433158924000054],
              [-80.885226265999961, 35.432939924000038],
              [-80.885227265999958, 35.432851923000044],
              [-80.885233265999943, 35.432743924000079],
              [-80.885210264999955, 35.432500923000077],
              [-80.885218264999935, 35.432392924000055],
              [-80.885232265999946, 35.432311923000043],
              [-80.885231264999959, 35.432260924000047],
              [-80.885220265999976, 35.432051923000074],
              [-80.885229264999964, 35.431976923000036],
              [-80.885234265999941, 35.431897923000065],
              [-80.88525726499995, 35.431857923000052],
              [-80.885262264999938, 35.431830924000053],
              [-80.885261265999986, 35.43174592400004],
              [-80.885254264999958, 35.431626923000067],
              [-80.885262264999938, 35.431560924000053],
              [-80.885244264999983, 35.431512923000071],
              [-80.885240264999936, 35.431446923000067],
              [-80.885242265999977, 35.431393924000076],
              [-80.88524926499997, 35.431322923000039],
              [-80.885266265999974, 35.431216923000079],
              [-80.885256264999953, 35.431126924000068],
              [-80.885238265999988, 35.431053924000025],
              [-80.885243264999986, 35.430943923000029],
              [-80.885265265999976, 35.430789923000077],
              [-80.885287264999988, 35.430656923000072],
              [-80.883766263999973, 35.42954392300004],
              [-80.883619264999936, 35.429492923000055],
              [-80.883513263999987, 35.42944192300007],
              [-80.883144264999942, 35.429198923000058],
              [-80.882935263999968, 35.429086922000067],
              [-80.882796263999978, 35.429022923000048],
              [-80.882678263999935, 35.428985924000074],
              [-80.882593264999969, 35.428962923000029],
              [-80.882089263999944, 35.428861923000056],
              [-80.88168826499998, 35.428786923000075],
              [-80.881106263999982, 35.428679922000072],
              [-80.88025126399998, 35.428535923000027],
              [-80.879991263999955, 35.428486923000037],
              [-80.87980926399996, 35.428486923000037],
              [-80.879684263999934, 35.428508923000038],
              [-80.879484262999938, 35.428601923000031],
              [-80.878897262999942, 35.428887923000048],
              [-80.878607263999982, 35.429024924000032],
              [-80.877776262999987, 35.429416923000076],
              [-80.87693126299996, 35.429810923000048],
              [-80.876112262999982, 35.428602923000028],
              [-80.876082262999944, 35.42856792300006],
              [-80.876040262999936, 35.428534924000076],
              [-80.875851261999969, 35.428411923000056],
              [-80.875748262999934, 35.428357923000078],
              [-80.875683262999985, 35.428449923000073],
              [-80.875547261999941, 35.428613924000047],
              [-80.875217262999968, 35.428979923000043],
              [-80.874576262999938, 35.42976892300004],
              [-80.874520261999976, 35.429657923000036],
              [-80.87407626199996, 35.42887892300007],
              [-80.873300261999987, 35.427514923000047],
              [-80.872914261999938, 35.426833923000061],
              [-80.871619260999978, 35.424574922000033],
              [-80.871271260999947, 35.42397492200007],
              [-80.871195260999968, 35.423860922000074],
              [-80.871120260999987, 35.423766922000027],
              [-80.871037260999969, 35.423676923000073],
              [-80.870600261999982, 35.42328092200006],
              [-80.870459260999951, 35.423151923000034],
              [-80.870402260999981, 35.42310092200006],
              [-80.869908259999988, 35.422664922000024],
              [-80.869850260999954, 35.422711922000076],
              [-80.869704260999981, 35.422812922000048],
              [-80.869594260999975, 35.422894923000058],
              [-80.869511259999967, 35.422931922000032],
              [-80.86946525999997, 35.422946922000051],
              [-80.86932225999999, 35.423007922000068],
              [-80.869205259999944, 35.42304792200008],
              [-80.869046260999937, 35.423114922000025],
              [-80.868938259999936, 35.423173923000036],
              [-80.868821259999947, 35.42322792300007],
              [-80.868629260999967, 35.42332992200005],
              [-80.868510260999983, 35.423414922000063],
              [-80.868461258999957, 35.42347592200008],
              [-80.868383259999973, 35.423550922000061],
              [-80.868269259999977, 35.423677922000024],
              [-80.868139260999953, 35.423799923000047],
              [-80.86807325999996, 35.423850922000042],
              [-80.868059259999939, 35.423869923000041],
              [-80.868039259999989, 35.423936922000053],
              [-80.868023260999962, 35.423950923000064],
              [-80.867958259999966, 35.423972923000065],
              [-80.86793425999997, 35.423985922000043],
              [-80.867921259999946, 35.424000922000062],
              [-80.867912259999969, 35.424025923000045],
              [-80.867908260999968, 35.424057922000031],
              [-80.867891260999954, 35.424093922000054],
              [-80.867853259999947, 35.424128923000069],
              [-80.867804259999957, 35.424160923000045],
              [-80.867700259999935, 35.424199922000071],
              [-80.867655259999935, 35.424233922000042],
              [-80.867609259999938, 35.424295922000056],
              [-80.86752125999999, 35.424387922000051],
              [-80.867487259999962, 35.424429922000058],
              [-80.867466258999968, 35.424472922000064],
              [-80.86744325899997, 35.424493923000057],
              [-80.867344259999982, 35.424559923000061],
              [-80.867312259999949, 35.42459492200004],
              [-80.867286260999947, 35.424645923000071],
              [-80.867278259999978, 35.424671922000073],
              [-80.867222259999949, 35.424755922000031],
              [-80.86715425899996, 35.424820923000027],
              [-80.867121259999976, 35.42486092200005],
              [-80.867021259999945, 35.424935923000078],
              [-80.866961258999936, 35.424967923000054],
              [-80.866920258999983, 35.425008923000064],
              [-80.866840259999947, 35.425069922000034],
              [-80.866774259999943, 35.425133922000043],
              [-80.866690259999984, 35.425241923000044],
              [-80.866655259999959, 35.425277923000067],
              [-80.866469259999974, 35.42540792300008],
              [-80.866324259999942, 35.425547923000067],
              [-80.866264258999934, 35.425617923000061],
              [-80.866246259999969, 35.425629923000031],
              [-80.866143259999944, 35.425674923000031],
              [-80.86605925899994, 35.425774923000063],
              [-80.866002259999959, 35.425821923000058],
              [-80.865920259999939, 35.425875923000035],
              [-80.865696258999947, 35.426073923000047],
              [-80.865643258999967, 35.426097923000043],
              [-80.865381258999946, 35.426292923000062],
              [-80.865013259999955, 35.425669923000044],
              [-80.864908258999947, 35.425463923000052],
              [-80.864818258999946, 35.425326923000057],
              [-80.864343257999963, 35.424510923000071],
              [-80.863406258999987, 35.422934922000024],
              [-80.863185257999987, 35.422554922000074],
              [-80.862778258999981, 35.421852922000028],
              [-80.862699258999953, 35.42170892300004],
              [-80.86300125799994, 35.421605922000026],
              [-80.863858257999937, 35.421285922000038],
              [-80.864313257999981, 35.421108921000041],
              [-80.865050258999986, 35.420819921000032],
              [-80.865272259999983, 35.420011922000072],
              [-80.865347258999975, 35.419759921000036],
              [-80.865369258999976, 35.419698921000077],
              [-80.865408259999981, 35.419610921000071],
              [-80.865455258999987, 35.419523922000053],
              [-80.865539258999945, 35.419390922000048],
              [-80.86561925999996, 35.419283922000034],
              [-80.865735258999962, 35.419151922000026],
              [-80.865831258999947, 35.419062921000034],
              [-80.866069259999961, 35.418878921000044],
              [-80.866624258999934, 35.418497921000039],
              [-80.867214258999979, 35.418092921000039],
              [-80.868695259999981, 35.417051921000052],
              [-80.869499259999941, 35.416482921000068],
              [-80.869750259999989, 35.416301920000024],
              [-80.870168260999947, 35.416001920000042],
              [-80.870998260999954, 35.415417920000039],
              [-80.871109260999958, 35.415336920000072],
              [-80.871301259999939, 35.415197920000026],
              [-80.871470260999956, 35.415061920000028],
              [-80.871723260999943, 35.414830920000043],
              [-80.872393260999957, 35.414143921000061],
              [-80.872671260999937, 35.413875920000066],
              [-80.873269260999962, 35.413286920000075],
              [-80.873359260999962, 35.413176920000069],
              [-80.873443261999967, 35.413047920000054],
              [-80.873549259999947, 35.41283892000007],
              [-80.873719261999952, 35.412438920000056],
              [-80.874005260999979, 35.411702919000049],
              [-80.87417826099994, 35.411300919000041],
              [-80.874262261999945, 35.411092919000055],
              [-80.874454260999983, 35.410642919000054],
              [-80.874494261999985, 35.410547919000066],
              [-80.875089260999971, 35.409157919000052],
              [-80.87513826199995, 35.409025918000054],
              [-80.875166260999947, 35.408928919000061],
              [-80.875179260999971, 35.408811919000073],
              [-80.875176260999979, 35.408740919000024],
              [-80.875162261999947, 35.408644919000039],
              [-80.875089260999971, 35.408261918000051],
              [-80.874954260999971, 35.407765919000042],
              [-80.87480726199999, 35.407254918000035],
              [-80.874719260999939, 35.406859918000066],
              [-80.874689261999947, 35.406540919000065],
              [-80.874675260999936, 35.406294919000061],
              [-80.874676260999934, 35.406260918000044],
              [-80.874384260999989, 35.40622391800008],
              [-80.873939260999975, 35.40613391800008],
              [-80.87357326099999, 35.405977918000076],
              [-80.873253260999945, 35.405780918000062],
              [-80.873120259999951, 35.405698918000041],
              [-80.872023260999981, 35.405025918000035],
              [-80.871820259999936, 35.404894918000025],
              [-80.871642259999987, 35.404779919000077],
              [-80.871244260999958, 35.404597918000036],
              [-80.870751259999963, 35.404487918000029],
              [-80.870378259999939, 35.404474918000062],
              [-80.869925259999945, 35.404552918000036],
              [-80.869581258999972, 35.404654918000062],
              [-80.869580259999964, 35.404654918000062],
              [-80.869486258999984, 35.404670918000079],
              [-80.869373259999975, 35.404706918000045],
              [-80.869343258999947, 35.404448918000071],
              [-80.869360258999961, 35.404152918000079],
              [-80.869384258999958, 35.403960918000053],
              [-80.869508258999986, 35.402689918000078],
              [-80.869524258999945, 35.402506918000029],
              [-80.869667258999982, 35.401284918000044],
              [-80.869673258999967, 35.40120091700004],
              [-80.869682259999934, 35.401077917000066],
              [-80.869670258999975, 35.400930917000039],
              [-80.86961225899995, 35.400716917000068],
              [-80.869453258999954, 35.400280918000078],
              [-80.869347259999984, 35.399965917000031],
              [-80.86933025899998, 35.399895917000038],
              [-80.869323258999941, 35.399831918000075],
              [-80.869327258999988, 35.399725917000069],
              [-80.869353259999968, 35.399605917000031],
              [-80.869378258999973, 35.39955291800004],
              [-80.86832925799996, 35.399005917000068],
              [-80.867964258999962, 35.398823917000072],
              [-80.867874257999972, 35.398780917000067],
              [-80.867493257999968, 35.398600918000056],
              [-80.867241257999979, 35.398481917000026],
              [-80.866540257999986, 35.398139917000037],
              [-80.86610025799996, 35.397940917000028],
              [-80.865858257999946, 35.397847917000036],
              [-80.865745257999947, 35.397809917000075],
              [-80.865585257999953, 35.397766917000069],
              [-80.865190257999984, 35.397686917000044],
              [-80.865097257999935, 35.397672916000033],
              [-80.864823257999944, 35.397651917000076],
              [-80.864328257999944, 35.39762491700003],
              [-80.864032256999963, 35.39761291700006],
              [-80.864086257999986, 35.396638916000029],
              [-80.864100256999961, 35.396377917000052],
              [-80.864126256999953, 35.396095917000025],
              [-80.864267256999938, 35.39544791600008],
              [-80.864631257999974, 35.394380916000046],
              [-80.864721257999975, 35.394060915000068],
              [-80.86473925699994, 35.39396191700007],
              [-80.864754256999959, 35.393817916000046],
              [-80.864758257999938, 35.393691915000034],
              [-80.864753256999961, 35.393584916000066],
              [-80.864740256999937, 35.393462916000033],
              [-80.864717256999938, 35.393336916000067],
              [-80.864683257999957, 35.393208916000049],
              [-80.864634257999967, 35.393070916000056],
              [-80.864583257999982, 35.392954915000075],
              [-80.864512256999944, 35.392821916000059],
              [-80.864437257999953, 35.392702915000029],
              [-80.864338256999986, 35.392571916000065],
              [-80.864249256999983, 35.392468916000041],
              [-80.864149256999951, 35.392366916000071],
              [-80.864040256999942, 35.392269916000032],
              [-80.863932257999977, 35.392184916000076],
              [-80.863799255999936, 35.392092916000024],
              [-80.863666255999988, 35.392012915000066],
              [-80.863520256999948, 35.391936916000077],
              [-80.86337625699997, 35.391871916000071],
              [-80.863222256999961, 35.391813916000046],
              [-80.863078256999984, 35.391768916000046],
              [-80.862935256999947, 35.391730916000029],
              [-80.862781256999938, 35.391698916000053],
              [-80.862625256999934, 35.391675916000054],
              [-80.862437255999964, 35.391657916000042],
              [-80.862290256999984, 35.391651915000068],
              [-80.862144256999954, 35.391653916000053],
              [-80.862006255999972, 35.391660916000035],
              [-80.861830255999962, 35.391679916000044],
              [-80.86170225699999, 35.391699917000039],
              [-80.861564255999951, 35.391728916000034],
              [-80.861418255999979, 35.391765916000054],
              [-80.861299255999938, 35.391802915000028],
              [-80.86115525699995, 35.391854916000057],
              [-80.861007256999983, 35.391918915000076],
              [-80.860898255999984, 35.391971916000045],
              [-80.860748255999965, 35.392055915000071],
              [-80.860618254999963, 35.392140916000074],
              [-80.860496255999976, 35.392230915000027],
              [-80.860388255999965, 35.392321917000061],
              [-80.860293255999977, 35.392412916000069],
              [-80.860268254999937, 35.39243891600006],
              [-80.860223256999973, 35.392486916000053],
              [-80.860114255999974, 35.392420916000049],
              [-80.858470254999986, 35.39142191600007],
              [-80.85843125599996, 35.391404916000056],
              [-80.858229254999969, 35.39132491600003],
              [-80.858019255999977, 35.391258915000037],
              [-80.857804254999962, 35.391208916000039],
              [-80.857584254999949, 35.391173916000071],
              [-80.857361254999944, 35.391154915000072],
              [-80.857137254999941, 35.391152916000067],
              [-80.856792254999959, 35.391169916000024],
              [-80.855460253999979, 35.391236915000036],
              [-80.855135254999936, 35.391226916000051],
              [-80.854840254999942, 35.391204916000049],
              [-80.854547253999954, 35.391169916000024],
              [-80.854160253999964, 35.391103916000077],
              [-80.854135253999971, 35.391206916000044],
              [-80.853990252999949, 35.391694916000063],
              [-80.853956253999968, 35.391781915000024],
              [-80.853744253999935, 35.392241916000046],
              [-80.853436254999963, 35.392829917000029],
              [-80.853150252999967, 35.393362916000058],
              [-80.85308125399996, 35.393481917000031],
              [-80.853231253999979, 35.393731916000036],
              [-80.853614253999979, 35.394330917000048],
              [-80.854202253999972, 35.395249917000058],
              [-80.854525253999952, 35.39576691700006],
              [-80.854873254999973, 35.39638291600005],
              [-80.855252254999982, 35.397105917000033],
              [-80.855562254999938, 35.397749917000056],
              [-80.855622254999957, 35.397889917000043],
              [-80.855803254999955, 35.39831491700005],
              [-80.855923254999936, 35.398612918000026],
              [-80.856048254999962, 35.398939917000064],
              [-80.856208255999945, 35.399384917000077],
              [-80.85639325599999, 35.399940917000038],
              [-80.856632255999955, 35.400816918000032],
              [-80.85669025499999, 35.401043918000028],
              [-80.856812255999955, 35.401604918000032],
              [-80.85687825499997, 35.401941918000034],
              [-80.857004255999982, 35.40271191800008],
              [-80.857083254999964, 35.403099919000056],
              [-80.857290255999942, 35.404263918000026],
              [-80.857346255999971, 35.404580919000068],
              [-80.857382255999937, 35.404980918000035],
              [-80.857522255999982, 35.405869919000054],
              [-80.857607256999984, 35.406357919000072],
              [-80.857740255999943, 35.407182919000036],
              [-80.857819255999971, 35.407643919000066],
              [-80.85795825699995, 35.408444919000033],
              [-80.858044254999982, 35.408945919000075],
              [-80.857413256999962, 35.409146920000069],
              [-80.856911255999989, 35.409306920000063],
              [-80.856401256999959, 35.409446919000061],
              [-80.856366254999955, 35.409455919000038],
              [-80.855477255999972, 35.409700919000045],
              [-80.855334254999946, 35.40973991900006],
              [-80.85487425599996, 35.40987092000006],
              [-80.85482125599998, 35.409697920000042],
              [-80.854754254999989, 35.40942891900005],
              [-80.854732254999988, 35.409339920000036],
              [-80.854582254999968, 35.408855919000075],
              [-80.854370254999935, 35.408221919000027],
              [-80.854020254999966, 35.407157919000042],
              [-80.85357225499996, 35.405747919000078],
              [-80.851767253999981, 35.399958918000038],
              [-80.851251252999987, 35.39832291700003],
              [-80.851161253999976, 35.39844591800005],
              [-80.851127253999948, 35.398490917000061],
              [-80.850895253999965, 35.398764917000051],
              [-80.850487253999972, 35.399212917000057],
              [-80.848718253999948, 35.401166919000048],
              [-80.847918252999989, 35.402049919000035],
              [-80.847743252999976, 35.402228919000038],
              [-80.847571251999966, 35.402387918000045],
              [-80.847364252999967, 35.402550918000031],
              [-80.847257252999952, 35.402623918000074],
              [-80.847131252999986, 35.402701918000048],
              [-80.846965252999951, 35.402792919000035],
              [-80.846764252999947, 35.402885919000028],
              [-80.846285252999962, 35.403059919000043],
              [-80.845573251999951, 35.403297919000067],
              [-80.845309250999946, 35.40339491900005],
              [-80.843974251999953, 35.403872919000037],
              [-80.843028250999964, 35.404208919000041],
              [-80.841621250999935, 35.40470891800004],
              [-80.841506250999942, 35.404753919000029],
              [-80.841298250999955, 35.404844918000038],
              [-80.839944250999963, 35.405288919000043],
              [-80.83976125099997, 35.405346920000056],
              [-80.839662249999947, 35.405373919000056],
              [-80.839680250999947, 35.405468919000043],
              [-80.840026249999937, 35.406843919000039],
              [-80.840072250999981, 35.407026920000078],
              [-80.840329250999957, 35.408107920000077],
              [-80.840595250999968, 35.409230921000074],
              [-80.840751250999972, 35.409885921000068],
              [-80.840984250999952, 35.410937921000027],
              [-80.841407251999954, 35.410901921000061],
              [-80.842817250999985, 35.410809921000066],
              [-80.842953251999973, 35.410808921000068],
              [-80.842968252999981, 35.410943920000079],
              [-80.843002251999962, 35.41143892100007],
              [-80.843163252999943, 35.413303922000068],
              [-80.843197251999982, 35.413796921000028],
              [-80.843202251999969, 35.413961921000066],
              [-80.84317925299996, 35.414287921000039],
              [-80.843177251999975, 35.414308921000043],
              [-80.843140251999955, 35.414547922000054],
              [-80.843111251999972, 35.414668922000033],
              [-80.842929252999966, 35.415274922000037],
              [-80.842874251999945, 35.415444921000073],
              [-80.842828251999947, 35.415585922000048],
              [-80.842634252999972, 35.416187922000063],
              [-80.842335252999987, 35.417110923000052],
              [-80.842641251999964, 35.417186923000031],
              [-80.842809251999938, 35.417223922000062],
              [-80.843082251999988, 35.417272921000063],
              [-80.843246251999972, 35.417290921000074],
              [-80.843480251999949, 35.417303923000077],
              [-80.843724252999948, 35.417301922000036],
              [-80.844223251999949, 35.417254922000041],
              [-80.844386251999936, 35.417232922000039],
              [-80.844500251999989, 35.417217922000077],
              [-80.84461225299998, 35.417208922000043],
              [-80.844724251999935, 35.417205922000051],
              [-80.844923252999934, 35.417206922000048],
              [-80.845601252999984, 35.417243922000068],
              [-80.845793252999954, 35.417246922000061],
              [-80.845966253999961, 35.417233922000037],
              [-80.846077252999976, 35.41721392200003],
              [-80.846209252999984, 35.417178922000062],
              [-80.846410252999988, 35.417104922000078],
              [-80.846871252999961, 35.416919922000034],
              [-80.846998253999971, 35.416865921000067],
              [-80.847169252999947, 35.416792921000024],
              [-80.84726225299994, 35.416737922000038],
              [-80.84738925399995, 35.41663292100003],
              [-80.848989253999946, 35.416649922000033],
              [-80.848991253999941, 35.416876921000039],
              [-80.848991253999941, 35.416912922000051],
              [-80.848980253999969, 35.417058922000024],
              [-80.848955254999964, 35.41716892200003],
              [-80.848907253999982, 35.417282922000027],
              [-80.84887525399995, 35.417335922000063],
              [-80.848793254999975, 35.417437922000033],
              [-80.848745253999937, 35.417484922000028],
              [-80.84853325399996, 35.417643921000035],
              [-80.848260252999978, 35.418122922000066],
              [-80.848544253999989, 35.418122922000066],
              [-80.848710253999968, 35.418087921000051],
              [-80.848845253999968, 35.418029921000027],
              [-80.848976253999979, 35.418019922000042],
              [-80.84919025399995, 35.417939922000073],
              [-80.849486253999942, 35.417837921000057],
              [-80.849691253999936, 35.417739922000067],
              [-80.849800254999934, 35.417700921000062],
              [-80.849842253999952, 35.417690922000077],
              [-80.849895253999989, 35.417659921000052],
              [-80.85011725399994, 35.417564922000054],
              [-80.85018325499999, 35.417544921000058],
              [-80.850322253999934, 35.418050921000031],
              [-80.850335254999948, 35.418066922000037],
              [-80.850351253999975, 35.418104922000055],
              [-80.850352253999972, 35.418153922000045],
              [-80.850325253999983, 35.418238922000057],
              [-80.850238253999976, 35.418435922000072],
              [-80.850169254999969, 35.41857592100007],
              [-80.84979025399997, 35.419407922000062],
              [-80.849753254999939, 35.419494922000069],
              [-80.849722253999971, 35.419587921000073],
              [-80.849705254999947, 35.419672922000075],
              [-80.849701253999967, 35.419796922000046],
              [-80.849709253999947, 35.419912922000037],
              [-80.84974525399997, 35.420245922000049],
              [-80.849785253999983, 35.420407922000038],
              [-80.849804253999935, 35.420456922000028],
              [-80.849839254999949, 35.420519923000029],
              [-80.85019525499996, 35.420948922000036],
              [-80.850264253999967, 35.421038922000037],
              [-80.850340253999946, 35.421168922000049],
              [-80.85037125499997, 35.421251922000067],
              [-80.850387254999987, 35.42133692200008],
              [-80.850470254999948, 35.42198292300003],
              [-80.849339253999972, 35.422075923000079],
              [-80.849238253999943, 35.422084922000067],
              [-80.84876925399999, 35.422130922000065],
              [-80.848621254999955, 35.42213292200006],
              [-80.848607253999944, 35.422258923000072],
              [-80.848552253999969, 35.422569923000026],
              [-80.848518253999941, 35.422668922000071],
              [-80.848468253999954, 35.422763923000048],
              [-80.848291253999946, 35.423007923000057],
              [-80.847906252999962, 35.423547922000068],
              [-80.847842253999943, 35.423610923000069],
              [-80.847760253999979, 35.42366792200005],
              [-80.847680253999954, 35.423701923000067],
              [-80.847331253999982, 35.423807923000027],
              [-80.847255253999947, 35.423845923000044],
              [-80.847189253999943, 35.423894923000034],
              [-80.847137253999961, 35.423954923000053],
              [-80.84709625399995, 35.424029923000035],
              [-80.847071253999957, 35.424104923000073],
              [-80.847062254999969, 35.424174923000066],
              [-80.847064253999974, 35.42428592300007],
              [-80.847047252999971, 35.424369923000029],
              [-80.847011252999948, 35.424445923000064],
              [-80.846960253999953, 35.424506923000024],
              [-80.846879253999987, 35.424577923000072],
              [-80.84678525399994, 35.424634923000042],
              [-80.846741252999948, 35.424651924000045],
              [-80.846780253999952, 35.424848924000059],
              [-80.846838252999987, 35.425251923000076],
              [-80.846958253999958, 35.425936924000041],
              [-80.848501253999984, 35.425778923000053],
              [-80.848659253999983, 35.425752923000061],
              [-80.848817254999972, 35.425708924000048],
              [-80.848920254999939, 35.425674923000031],
              [-80.851376254999934, 35.42488992400007],
              [-80.852394254999979, 35.424563923000051],
              [-80.854956256999969, 35.423759923000034],
              [-80.855051255999967, 35.423729923000053],
              [-80.855246256999976, 35.423669923000034],
              [-80.856335256999955, 35.423332922000043],
              [-80.857144256999959, 35.423122922000061],
              [-80.857680255999981, 35.422991923000041],
              [-80.858662256999935, 35.422752922000029],
              [-80.859637257999964, 35.422521922000044],
              [-80.861272257999985, 35.425813922000032],
              [-80.862235258999988, 35.427743923000037],
              [-80.862493257999972, 35.428265923000026],
              [-80.863062258999946, 35.429417923000074],
              [-80.86377725899996, 35.430865924000045],
              [-80.863885258999971, 35.43108492500005],
              [-80.864142258999948, 35.431656925000027],
              [-80.864400259999968, 35.43231292400003],
              [-80.864643258999934, 35.43305192400004],
              [-80.864826259999973, 35.433726924000041],
              [-80.864893259999974, 35.434042924000039],
              [-80.864980258999935, 35.434455925000066],
              [-80.865093259999981, 35.435199925000063],
              [-80.865246259999935, 35.436462925000058],
              [-80.865307259999952, 35.436964925000041],
              [-80.865347258999975, 35.437410925000052],
              [-80.865358259999937, 35.437594925000042],
              [-80.865353259999949, 35.438257925000073],
              [-80.86534525999997, 35.43850692500007],
              [-80.865317259999983, 35.439036926000028],
              [-80.865307259999952, 35.439227926000058],
              [-80.865296260999969, 35.439590926000051],
              [-80.865297260999967, 35.439890925000043],
              [-80.865322259999971, 35.440555926000059],
              [-80.865342259999977, 35.440886925000029],
              [-80.86538625999998, 35.441413926000052],
              [-80.865449259999934, 35.441956926000046],
              [-80.865492260999986, 35.442232926000031],
              [-80.863250259999973, 35.442078926000079],
              [-80.859993258999964, 35.441852926000024],
              [-80.858597258999964, 35.441755927000031],
              [-80.857328257999939, 35.441668926000034],
              [-80.856521257999987, 35.441614926000057],
              [-80.854474256999936, 35.441473927000061],
              [-80.853451256999961, 35.441404926000075],
              [-80.852713256999948, 35.441354926000031],
              [-80.852610255999934, 35.441348927000035],
              [-80.852582256999938, 35.441572926000049],
              [-80.852566255999989, 35.441750927000044],
              [-80.852570255999979, 35.441911927000035],
              [-80.852589255999987, 35.442111927000042],
              [-80.852601256999947, 35.442195927000057],
              [-80.852623255999958, 35.443159927000067],
              [-80.852616255999976, 35.443572927000048],
              [-80.852571256999965, 35.443793927000058],
              [-80.852482256999963, 35.443966927000076],
              [-80.852417255999967, 35.444056928000066],
              [-80.85248325699996, 35.444095927000035],
              [-80.852563256999986, 35.444125927000073],
              [-80.852630256999987, 35.444140927000035],
              [-80.852700255999935, 35.444147927000074],
              [-80.855178256999977, 35.444178928000042],
              [-80.85519725599994, 35.444349927000076],
              [-80.855199257999971, 35.445080927000049],
              [-80.855204256999968, 35.445254927000065],
              [-80.855224257999964, 35.445377927000038],
              [-80.855256256999951, 35.445474927000078],
              [-80.855314256999975, 35.445621928000037],
              [-80.855380256999979, 35.445765927000025],
              [-80.855456257999947, 35.445864927000059],
              [-80.855574256999944, 35.446007928000029],
              [-80.855263257999979, 35.446212927000033],
              [-80.855160256999966, 35.446284928000068],
              [-80.855074257999945, 35.446362928000042],
              [-80.85501925799997, 35.446427927000059],
              [-80.854963256999952, 35.446503927000037],
              [-80.854940257999942, 35.446534928000062],
              [-80.85461825699997, 35.447137928000075],
              [-80.85453625699995, 35.447115928000073],
              [-80.854418256999963, 35.44709492800007],
              [-80.854147256999966, 35.447061928000039],
              [-80.853776256999936, 35.447024928000076],
              [-80.85373225699999, 35.447490928000036],
              [-80.853700256999957, 35.447755928000049],
              [-80.853661256999942, 35.448174928000071],
              [-80.853616256999942, 35.448396928000079],
              [-80.853575257999978, 35.448495928000057],
              [-80.853528256999937, 35.448592928000039],
              [-80.853430256999957, 35.448774929000024],
              [-80.853293256999962, 35.448999928000035],
              [-80.854345257999967, 35.449407928000028],
              [-80.854818257999966, 35.449584928000036],
              [-80.855016257999978, 35.449668928000051],
              [-80.855104257999983, 35.44972492800008],
              [-80.855215257999987, 35.449812928000028],
              [-80.855345257999943, 35.449895928000046],
              [-80.855382257999963, 35.449913928000058],
              [-80.855412256999955, 35.449925928000027],
              [-80.855624257999978, 35.449983928000051],
              [-80.855693256999984, 35.450000929000055],
              [-80.855813257999955, 35.450031928000044],
              [-80.855911257999935, 35.450066929000059],
              [-80.856013257999962, 35.45013792900005],
              [-80.856137256999943, 35.450249928000062],
              [-80.856218257999956, 35.450301929000034],
              [-80.856310256999961, 35.450347928000042],
              [-80.856406257999936, 35.450380928000072],
              [-80.856574257999966, 35.45041892900008],
              [-80.856712258999949, 35.450440928000035],
              [-80.856782257999953, 35.450450929000056],
              [-80.856887258999961, 35.450465928000028],
              [-80.857116257999962, 35.450498928000059],
              [-80.85724025899998, 35.450519928000062],
              [-80.85733825899996, 35.450548929000036],
              [-80.857604257999981, 35.450653928000065],
              [-80.857849257999987, 35.450750929000037],
              [-80.858094258999984, 35.450847929000076],
              [-80.858181257999945, 35.450881928000058],
              [-80.858353258999955, 35.450950928000054],
              [-80.858479258999978, 35.451000929000031],
              [-80.858611257999939, 35.451052928000024],
              [-80.858696258999942, 35.451096929000073],
              [-80.858773258999975, 35.451179929000034],
              [-80.858801258999961, 35.451238928000066],
              [-80.858810257999949, 35.451281928000071],
              [-80.858809258999941, 35.451354929000047],
              [-80.858779258999959, 35.451432929000077],
              [-80.858701258999986, 35.45157492900006],
              [-80.858661258999973, 35.451642929000059],
              [-80.858633257999941, 35.451681929000074],
              [-80.858556258999954, 35.451771928000028],
              [-80.858384258999934, 35.451905929000077],
              [-80.858078258999967, 35.452146928000047],
              [-80.858069257999944, 35.452154929000073],
              [-80.857483257999945, 35.452600929000027],
              [-80.857132257999979, 35.452836930000046],
              [-80.856637257999978, 35.452420929000027],
              [-80.856683258999965, 35.452356929000075],
              [-80.856711258999951, 35.452273929000057],
              [-80.85669725799994, 35.452191929000037],
              [-80.85664825899994, 35.452109929000073],
              [-80.856581257999949, 35.452052929000047],
              [-80.856498258999977, 35.452024928000071],
              [-80.856379257999947, 35.452031929000043],
              [-80.856267257999946, 35.452062928000032],
              [-80.856197257999952, 35.452107929000078],
              [-80.856157257999939, 35.452165929000046],
              [-80.855730257999937, 35.452085929000077],
              [-80.855585258999952, 35.452058928000042],
              [-80.855187257999944, 35.45199192900003],
              [-80.855050257999949, 35.451979929000061],
              [-80.854902257999981, 35.451990929000033],
              [-80.854765257999986, 35.452013929000032],
              [-80.854678256999989, 35.452028929000051],
              [-80.853791257999944, 35.452158928000074],
              [-80.852060256999948, 35.452437929000041],
              [-80.851930256999935, 35.452463929000032],
              [-80.851801256999977, 35.452508928000043],
              [-80.851653255999963, 35.452607929000067],
              [-80.851566256999945, 35.452543929000058],
              [-80.851540255999964, 35.452523930000041],
              [-80.851463256999978, 35.452486928000042],
              [-80.851378255999975, 35.452467928000033],
              [-80.851284256999975, 35.452461929000037],
              [-80.851194256999975, 35.452491929000075],
              [-80.851138256999945, 35.452533929000026],
              [-80.851101255999936, 35.452578929000026],
              [-80.851078256999983, 35.452626928000029],
              [-80.85054725599997, 35.452745929000059],
              [-80.850024256999973, 35.452870929000028],
              [-80.850016255999947, 35.452899929000068],
              [-80.849961255999972, 35.45300792900008],
              [-80.849910256999976, 35.453061929000057],
              [-80.849882255999944, 35.453092929000036],
              [-80.849770255999942, 35.453169929000069],
              [-80.849647255999969, 35.453233929000078],
              [-80.849496255999952, 35.453275930000075],
              [-80.849070255999948, 35.453340929000035],
              [-80.848336255999982, 35.45345793000007],
              [-80.848125255999946, 35.453503929000078],
              [-80.848028255999964, 35.453352929000062],
              [-80.847886254999935, 35.453129929000056],
              [-80.847750254999937, 35.452917928000033],
              [-80.84768825499998, 35.452793930000041],
              [-80.847608255999944, 35.452617929000041],
              [-80.847480254999937, 35.452379929000074],
              [-80.84718225499995, 35.451834929000029],
              [-80.847083254999973, 35.451690929000051],
              [-80.847010254999987, 35.451744930000075],
              [-80.846825255999988, 35.451801930000045],
              [-80.846688254999947, 35.451816929000074],
              [-80.846540254999979, 35.451796929000068],
              [-80.846504254999957, 35.451785929000039],
              [-80.846372254999949, 35.451744930000075],
              [-80.846315254999979, 35.451726929000074],
              [-80.846150254999941, 35.451665929000058],
              [-80.845653254999945, 35.451497929000027],
              [-80.845499255999982, 35.451451930000076],
              [-80.84533225499996, 35.451407929000027],
              [-80.845129254999961, 35.45136192800004],
              [-80.844865254999945, 35.451312929000039],
              [-80.844068254999968, 35.451184929000078],
              [-80.843214253999975, 35.451028929000074],
              [-80.842840254999942, 35.453674930000034],
              [-80.842820253999946, 35.453806930000042],
              [-80.842783253999983, 35.454058930000031],
              [-80.842724253999961, 35.454481930000043],
              [-80.842618253999944, 35.455296930000031],
              [-80.842592253999953, 35.45564193000007],
              [-80.842588253999963, 35.45569092900007],
              [-80.842589253999961, 35.456134930000076],
              [-80.842619253999942, 35.456561930000078],
              [-80.842634253999961, 35.456697930000075],
              [-80.842678253999964, 35.457012930000076],
              [-80.84273525399999, 35.457280931000071],
              [-80.842848253999989, 35.45770493100008],
              [-80.842910253999946, 35.457892930000071],
              [-80.843012253999973, 35.458178931000077],
              [-80.843200254999942, 35.458610931000067],
              [-80.84337725499995, 35.45896993100007],
              [-80.84348225399998, 35.459162930000048],
              [-80.843629255999986, 35.459414930000037],
              [-80.844000254999969, 35.45999893100003],
              [-80.844372255999986, 35.460569931000066],
              [-80.84449525499997, 35.460758931000044],
              [-80.845495254999946, 35.46229393200008],
              [-80.845847255999956, 35.462834931000032],
              [-80.846271254999976, 35.463486931000034],
              [-80.847334255999954, 35.465122933000032],
              [-80.847710255999971, 35.465691932000027],
              [-80.847907255999985, 35.466006932000028],
              [-80.849149256999965, 35.467921932000024],
              [-80.849564255999951, 35.468551933000072],
              [-80.849676257999988, 35.468724932000043],
              [-80.849782256999958, 35.468888932000027],
              [-80.849545256999988, 35.469029933000058],
              [-80.849203256999942, 35.469234933000052],
              [-80.843787254999938, 35.471022934000075],
              [-80.84231925499995, 35.471506933000057],
              [-80.840337253999962, 35.472132934000058],
              [-80.833587252999962, 35.474260934000029],
              [-80.831405250999978, 35.474927935000039],
              [-80.830715251999948, 35.475137935000078],
              [-80.830558251999946, 35.475184935000073],
              [-80.83033825199999, 35.478319936000048],
              [-80.830418250999969, 35.47832293600004],
              [-80.830765250999946, 35.478340936000052],
              [-80.831029251999951, 35.478345936000039],
              [-80.831235251999942, 35.478332935000026],
              [-80.831499251999958, 35.478338936000057],
              [-80.831664251999939, 35.478357935000076],
              [-80.831924251999965, 35.478354935000027],
              [-80.832038252999951, 35.478360935000069],
              [-80.832302251999977, 35.478342935000057],
              [-80.832478251999987, 35.478356935000079],
              [-80.832632252999986, 35.478379935000078],
              [-80.832700252999985, 35.478400936000071],
              [-80.832781252999951, 35.478447935000077],
              [-80.832847251999965, 35.478492935000077],
              [-80.832994252999981, 35.478575935000038],
              [-80.833080252999935, 35.478643935000036],
              [-80.83316425199996, 35.47871693500008],
              [-80.833297251999966, 35.478797935000046],
              [-80.833541252999964, 35.478962936000073],
              [-80.833605252999973, 35.478997935000052],
              [-80.833794252999951, 35.479139936000024],
              [-80.833934253999985, 35.479229935000035],
              [-80.834100252999974, 35.47936293500004],
              [-80.834142252999982, 35.479408935000038],
              [-80.834188252999979, 35.479440935000071],
              [-80.834261252999966, 35.479482936000068],
              [-80.834298252999986, 35.479513936000046],
              [-80.834367252999982, 35.47954693500003],
              [-80.834408251999946, 35.479584935000048],
              [-80.834490252999956, 35.479673935000051],
              [-80.834550252999975, 35.47971093600006],
              [-80.834601253999949, 35.479751935000024],
              [-80.83465025299995, 35.47977893500007],
              [-80.834706252999979, 35.479829935000055],
              [-80.835018253999976, 35.480058935000045],
              [-80.835160253999959, 35.480196936000027],
              [-80.835272252999971, 35.480261935000044],
              [-80.835304252999947, 35.480287936000025],
              [-80.835354252999934, 35.480306936000034],
              [-80.835423252999988, 35.480313936000073],
              [-80.83548325299995, 35.480335935000028],
              [-80.835545252999964, 35.480351936000034],
              [-80.835595253999941, 35.480374935000043],
              [-80.835649253999975, 35.480409936000058],
              [-80.835908253999946, 35.480552935000048],
              [-80.835950252999965, 35.480586936000066],
              [-80.835994252999967, 35.480608935000078],
              [-80.836054252999986, 35.480623936000029],
              [-80.836130253999954, 35.480676936000066],
              [-80.836216253999964, 35.480712935000042],
              [-80.836507253999969, 35.480875936000075],
              [-80.836553253999966, 35.480908936000048],
              [-80.836609253999939, 35.480969936000065],
              [-80.836808253999948, 35.481168936000074],
              [-80.83684825399996, 35.481226936000041],
              [-80.836896253999953, 35.481269936000047],
              [-80.83694225399995, 35.481296936000035],
              [-80.836985253999956, 35.481336936000048],
              [-80.837105253999937, 35.481468936000056],
              [-80.837134253999977, 35.481520936000038],
              [-80.837172253999938, 35.481567935000044],
              [-80.837222253999983, 35.481611935000046],
              [-80.83740325499997, 35.481793936000031],
              [-80.837454253999965, 35.481851936000055],
              [-80.837496253999973, 35.481914935000077],
              [-80.837597254999935, 35.482039936000035],
              [-80.837639254999942, 35.48208293600004],
              [-80.83778625399998, 35.482267935000039],
              [-80.837858254999958, 35.482332936000034],
              [-80.837887253999952, 35.482375936000039],
              [-80.837928254999952, 35.482399936000036],
              [-80.838050254999985, 35.482431935000079],
              [-80.838213254999971, 35.482456937000052],
              [-80.838307254999961, 35.48246593600004],
              [-80.838399254999956, 35.482459936000055],
              [-80.838515254999948, 35.48246593600004],
              [-80.838624254999957, 35.482477936000066],
              [-80.838960254999961, 35.48249893600007],
              [-80.839042254999981, 35.48251093600004],
              [-80.839125254999942, 35.482515936000027],
              [-80.839327253999954, 35.482526935000067],
              [-80.839430254999968, 35.482520936000071],
              [-80.839523253999971, 35.482519935000028],
              [-80.839631254999972, 35.482523936000064],
              [-80.839851253999939, 35.482523935000074],
              [-80.840009254999984, 35.482545936000065],
              [-80.840060254999969, 35.482548936000057],
              [-80.840167254999983, 35.482553936000045],
              [-80.84031125599995, 35.48254393600007],
              [-80.840481254999986, 35.482544936000068],
              [-80.840663254999981, 35.482532936000041],
              [-80.840906254999936, 35.482545935000076],
              [-80.840989254999954, 35.482542935000026],
              [-80.841077254999959, 35.482545936000065],
              [-80.841234254999961, 35.482537936000028],
              [-80.84139225499996, 35.482557936000035],
              [-80.841446255999983, 35.482571936000056],
              [-80.841562254999985, 35.482593936000058],
              [-80.84184625499995, 35.482627936000029],
              [-80.841995254999972, 35.482621936000044],
              [-80.842054254999937, 35.482635936000065],
              [-80.842115254999953, 35.482669936000036],
              [-80.842160254999953, 35.482693936000032],
              [-80.842196255999966, 35.482718936000026],
              [-80.842296255999941, 35.482805936000034],
              [-80.84235225599997, 35.482844936000049],
              [-80.84239725599997, 35.482890936000047],
              [-80.842506255999979, 35.482976936000057],
              [-80.842562254999962, 35.483035936000078],
              [-80.842621254999983, 35.483066936000057],
              [-80.842680254999948, 35.483125936000079],
              [-80.842728255999987, 35.48318193700004],
              [-80.842854255999953, 35.483269936000056],
              [-80.842866255999979, 35.483318936000046],
              [-80.842884255999934, 35.483357936000061],
              [-80.84292925699998, 35.483404936000056],
              [-80.843003256999964, 35.483451937000041],
              [-80.843111255999986, 35.483537936000062],
              [-80.843131255999936, 35.483567937000032],
              [-80.843163256999958, 35.483574935000036],
              [-80.843250255999976, 35.483661936000033],
              [-80.843292255999984, 35.483689936000076],
              [-80.843379255999935, 35.483725936000042],
              [-80.843412256999954, 35.483753936000028],
              [-80.843440255999951, 35.483789936000051],
              [-80.843474255999979, 35.483819936000032],
              [-80.843507255999953, 35.483840936000036],
              [-80.843548255999963, 35.48392093700005],
              [-80.843571256999951, 35.483949936000045],
              [-80.843601255999943, 35.483968936000053],
              [-80.843631255999981, 35.483994936000045],
              [-80.843681256999957, 35.484065936000036],
              [-80.843723256999965, 35.484126936000052],
              [-80.843754255999954, 35.484159936000026],
              [-80.843792255999972, 35.48417693600004],
              [-80.843833255999982, 35.484188936000066],
              [-80.843868255999951, 35.484215937000045],
              [-80.843917256999987, 35.484300936000068],
              [-80.843945255999984, 35.484337936000031],
              [-80.843974255999967, 35.484385936000024],
              [-80.844052256999987, 35.484478936000073],
              [-80.844081255999981, 35.484526937000055],
              [-80.844114255999955, 35.484565936000024],
              [-80.844148256999972, 35.484597937000046],
              [-80.844223255999964, 35.484743936000029],
              [-80.844290256999955, 35.484846936000054],
              [-80.844327256999975, 35.484890936000056],
              [-80.844377255999973, 35.484918936000042],
              [-80.844540256999949, 35.48509193600006],
              [-80.844673255999965, 35.485249936000059],
              [-80.844766255999957, 35.485333936000075],
              [-80.844854256999952, 35.485378936000075],
              [-80.844891256999972, 35.485406936000061],
              [-80.844924255999956, 35.485447936000071],
              [-80.844928256999935, 35.48547893600005],
              [-80.844942256999957, 35.485578937000071],
              [-80.844948256999942, 35.485693936000075],
              [-80.844965255999966, 35.485725936000051],
              [-80.845066256999985, 35.485788936000063],
              [-80.84512125599997, 35.485817936000046],
              [-80.84517025699995, 35.48583493600006],
              [-80.845294255999988, 35.485863936000044],
              [-80.845351256999948, 35.485894936000079],
              [-80.845488256999943, 35.485935936000033],
              [-80.845548255999972, 35.485959936000029],
              [-80.84560225599995, 35.485975936000045],
              [-80.845653256999981, 35.485998936000044],
              [-80.845697256999983, 35.486013936000063],
              [-80.845816256999967, 35.486028937000071],
              [-80.845869256999947, 35.486050936000026],
              [-80.845921256999986, 35.486080937000054],
              [-80.845972255999982, 35.486091936000037],
              [-80.846073256999944, 35.486093936000032],
              [-80.846119256999941, 35.486113936000038],
              [-80.846161256999949, 35.486142937000068],
              [-80.84617525699997, 35.486155937000035],
              [-80.846228255999961, 35.486230937000073],
              [-80.846261256999981, 35.486240937000048],
              [-80.846290256999964, 35.48623993700005],
              [-80.84634525599995, 35.486191936000068],
              [-80.846384256999954, 35.48617293600006],
              [-80.846624256999974, 35.486148936000063],
              [-80.846682256999941, 35.486132937000036],
              [-80.846702256999947, 35.486110936000046],
              [-80.846707257999981, 35.486087936000047],
              [-80.846719256999961, 35.48607193600003],
              [-80.846751256999937, 35.486055936000071],
              [-80.846781257999965, 35.486033936000069],
              [-80.846835256999952, 35.486023937000027],
              [-80.84692625699995, 35.486017936000053],
              [-80.847017256999948, 35.486026937000076],
              [-80.847190256999966, 35.486081936000062],
              [-80.84724525699994, 35.486087937000036],
              [-80.847304256999962, 35.486101936000068],
              [-80.847349257999952, 35.486124936000067],
              [-80.847395257999949, 35.486130936000052],
              [-80.847456256999976, 35.486125937000054],
              [-80.847498257999973, 35.486103937000053],
              [-80.847523256999978, 35.486069936000035],
              [-80.847544256999981, 35.486029936000079],
              [-80.847590257999968, 35.485987936000072],
              [-80.847653256999934, 35.485981937000076],
              [-80.847691256999951, 35.485997936000047],
              [-80.847742257999982, 35.486077936000072],
              [-80.847801257999947, 35.486142937000068],
              [-80.847813257999974, 35.486185936000027],
              [-80.847854257999984, 35.48623593700006],
              [-80.847942256999943, 35.486290936000046],
              [-80.847993257999974, 35.486328936000064],
              [-80.848082256999987, 35.486343936000026],
              [-80.848133257999962, 35.486359936000042],
              [-80.848159256999963, 35.486384936000036],
              [-80.848195256999986, 35.486423936000051],
              [-80.848214257999985, 35.486458936000076],
              [-80.848232258999985, 35.486474936000036],
              [-80.848290257999963, 35.486551937000058],
              [-80.848346257999935, 35.486642936000067],
              [-80.848354256999983, 35.486710936000065],
              [-80.848356257999967, 35.486779937000051],
              [-80.848403257999962, 35.486807937000037],
              [-80.84844525799997, 35.486817937000069],
              [-80.848499256999958, 35.486849936000056],
              [-80.848526257999936, 35.486875936000047],
              [-80.848560257999964, 35.486898936000046],
              [-80.848603257999969, 35.486915937000049],
              [-80.848626256999978, 35.486935937000055],
              [-80.848641257999986, 35.486959936000062],
              [-80.848646256999984, 35.486996936000025],
              [-80.848640257999989, 35.48703193700004],
              [-80.848646257999974, 35.487049936000062],
              [-80.848685256999943, 35.487084936000031],
              [-80.84877225799994, 35.487084936000031],
              [-80.848847256999989, 35.48705493600005],
              [-80.848958256999936, 35.487038936000033],
              [-80.849009258999956, 35.487053937000042],
              [-80.84903825799995, 35.487084936000031],
              [-80.849107257999947, 35.487273937000055],
              [-80.849134257999935, 35.48732093600006],
              [-80.849176257999943, 35.487354937000077],
              [-80.849226258999977, 35.487375936000035],
              [-80.849378257999945, 35.487425936000079],
              [-80.849420257999952, 35.487461936000045],
              [-80.849465257999952, 35.487573936000047],
              [-80.849383257999989, 35.487761937000073],
              [-80.849281258999952, 35.487989936000076],
              [-80.849345258999961, 35.488633937000031],
              [-80.849350257999959, 35.488675937000039],
              [-80.84933125799995, 35.489115937000065],
              [-80.849320257999977, 35.489542937000067],
              [-80.849236257999962, 35.491938937000043],
              [-80.849182258999974, 35.493682938000063],
              [-80.848594257999935, 35.493694937000043],
              [-80.848194257999978, 35.493694937000043],
              [-80.84811125799996, 35.493687938000051],
              [-80.847999257999959, 35.493667938000044],
              [-80.847889257999952, 35.493631938000078],
              [-80.847314257999983, 35.493354938000039],
              [-80.846881257999939, 35.493145938000055],
              [-80.846851257999958, 35.49313993800007],
              [-80.84682825799996, 35.493140938000067],
              [-80.846796257999983, 35.493149938000045],
              [-80.846769257999938, 35.493165938000061],
              [-80.845728256999962, 35.494502938000039],
              [-80.845054256999958, 35.495374938000054],
              [-80.844980255999985, 35.495470938000039],
              [-80.844893256999967, 35.495583938000038],
              [-80.844596256999978, 35.495976938000069],
              [-80.84450525799997, 35.496083938000027],
              [-80.844473256999947, 35.496120938000047],
              [-80.844087255999966, 35.496633939000048],
              [-80.843569256999956, 35.49729693900008],
              [-80.843483256999946, 35.497399938000058],
              [-80.842718256999945, 35.496825939000075],
              [-80.841751255999952, 35.496125939000024],
              [-80.841637255999956, 35.496058938000033],
              [-80.841522255999962, 35.496001938000063],
              [-80.841426256999966, 35.495960938000053],
              [-80.841370255999948, 35.495941939000033],
              [-80.841219255999988, 35.495890938000059],
              [-80.839381254999978, 35.495329939000044],
              [-80.839268254999979, 35.495295939000073],
              [-80.837936254999988, 35.495686939000052],
              [-80.837724254999955, 35.495751939000058],
              [-80.837543254999957, 35.495806939000033],
              [-80.836793254999975, 35.496042939000063],
              [-80.836549253999976, 35.496117938000054],
              [-80.835903254999948, 35.496317939000051],
              [-80.835855254999956, 35.496333939000067],
              [-80.83569625399997, 35.496386939000047],
              [-80.835507253999936, 35.496462939000025],
              [-80.835163253999951, 35.49661893900003],
              [-80.835108253999977, 35.496644939000078],
              [-80.834996253999975, 35.49670093800006],
              [-80.834790253999984, 35.496815938000054],
              [-80.834626253999943, 35.496916939000073],
              [-80.834496254999976, 35.497004939000078],
              [-80.834366254999964, 35.497100939000063],
              [-80.834108253999943, 35.497320939000076],
              [-80.834066253999936, 35.497356940000031],
              [-80.833776253999986, 35.49763193900003],
              [-80.833718253999962, 35.497698939000031],
              [-80.833603253999968, 35.497830939000039],
              [-80.833457252999949, 35.498014939000029],
              [-80.833286252999983, 35.498240939000027],
              [-80.834748253999976, 35.497951939000075],
              [-80.836540254999989, 35.497595939000064],
              [-80.83679525499997, 35.497544939000079],
              [-80.836849254999947, 35.497647939000046]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 36,
        "SolutionID": "PI-16",
        "Shape_Length": 0.68663741747813944,
        "Shape_Area": 0.0041894555294706591
      }
    },
    {
      "type": "Feature",
      "id": 37,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.786112233999972, 35.381666916000029],
            [-80.786140232999969, 35.381821916000035],
            [-80.786258232999955, 35.382450917000028],
            [-80.786374233999936, 35.38306491600008],
            [-80.786556232999942, 35.383994917000052],
            [-80.786603233999983, 35.384203917000036],
            [-80.786639233999949, 35.38431891700003],
            [-80.786700233999966, 35.384445916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.788118233999967, 35.383049916000061],
            [-80.788435233999962, 35.382791916000031],
            [-80.788806233999935, 35.382512917000042],
            [-80.788673234999976, 35.382364916000029],
            [-80.788323233999961, 35.382013917000052],
            [-80.788210233999962, 35.381930916000044],
            [-80.788088233999986, 35.381868917000077],
            [-80.787945233999949, 35.381812917000047],
            [-80.787829233999958, 35.381777916000033],
            [-80.787695233999955, 35.381757916000026],
            [-80.787321232999943, 35.381732917000079],
            [-80.787354233999963, 35.381476917000043],
            [-80.787439232999986, 35.380923917000075],
            [-80.787469232999968, 35.380810917000076],
            [-80.787508232999983, 35.380710916000055],
            [-80.787727233999988, 35.380317916000024],
            [-80.787834233999945, 35.380178916000034],
            [-80.787908233999985, 35.380093916000078],
            [-80.788009233999958, 35.379998916000034],
            [-80.788090233999981, 35.37992891600004],
            [-80.788184233999971, 35.379866915000036],
            [-80.788461234999943, 35.379717916000061],
            [-80.788380233999987, 35.379580915000076],
            [-80.788296233999972, 35.379456916000038],
            [-80.788257233999957, 35.379368916000033],
            [-80.788227233999976, 35.379285916000072],
            [-80.788225233999981, 35.379225916000053],
            [-80.788340233999975, 35.378968916000076],
            [-80.789500232999956, 35.376610915000072],
            [-80.789552234999974, 35.376530915000046],
            [-80.789612233999947, 35.376476915000069],
            [-80.789875234999954, 35.376303915000051],
            [-80.790028233999976, 35.376196914000047],
            [-80.790190233999965, 35.376067915000078],
            [-80.789804233999973, 35.375845914000024],
            [-80.789889233999986, 35.375686916000063],
            [-80.790138234999972, 35.375176915000054],
            [-80.790268233999939, 35.374897915000076],
            [-80.790462233999961, 35.374511915000028],
            [-80.790615233999972, 35.374188914000058],
            [-80.790985233999947, 35.373388914000031],
            [-80.791253233999953, 35.373446914000056],
            [-80.791601234999973, 35.373552914000072],
            [-80.791984234999973, 35.373705915000073],
            [-80.792397234999953, 35.373879914000042],
            [-80.792819234999968, 35.374084915000026],
            [-80.793234234999943, 35.374280915000043],
            [-80.793300234999947, 35.37415091500003],
            [-80.793362234999961, 35.374043914000026],
            [-80.793407234999961, 35.373948914000039],
            [-80.79347523499996, 35.373829915000044],
            [-80.793616233999956, 35.373562914000047],
            [-80.793684234999944, 35.373454915000025],
            [-80.793838234999953, 35.373247914000046],
            [-80.79392923499995, 35.373298914000031],
            [-80.794324234999976, 35.373487914000066],
            [-80.794597235999959, 35.373596915000064],
            [-80.795023234999974, 35.373707914000079],
            [-80.795480235999946, 35.373809915000038],
            [-80.795690235999984, 35.373861914000031],
            [-80.79575723499994, 35.37386891400007],
            [-80.795836235999957, 35.373865914000078],
            [-80.795928235999952, 35.373853915000041],
            [-80.796176235999951, 35.373786915000039],
            [-80.796212235999974, 35.373861914000031],
            [-80.796271235999939, 35.373937914000066],
            [-80.796316235999939, 35.373975914000027],
            [-80.796381235999945, 35.374014914000043],
            [-80.796468236999942, 35.37404891500006],
            [-80.796554235999963, 35.374076914000057],
            [-80.796931235999978, 35.374166915000046],
            [-80.797451236999962, 35.374244915000077],
            [-80.797468235999986, 35.374094914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797446236999974, 35.373914914000068],
            [-80.797416235999947, 35.373811914000044],
            [-80.797039235999989, 35.372922914000071],
            [-80.796997235999982, 35.372774914000047],
            [-80.796976235999978, 35.372682915000041],
            [-80.796966234999957, 35.372515914000076],
            [-80.796959235999964, 35.37238691400006],
            [-80.796958235999966, 35.372212914000045],
            [-80.796933235999973, 35.372133914000074],
            [-80.796915235999961, 35.372047913000074],
            [-80.796870235999961, 35.371958914000061],
            [-80.796635235999986, 35.371587914000031],
            [-80.796389234999936, 35.371225914000036],
            [-80.796325234999983, 35.371120914000073],
            [-80.796280235999973, 35.371011914000064],
            [-80.796123235999971, 35.370414914000037],
            [-80.796087234999959, 35.370324914000037],
            [-80.796038234999969, 35.370239914000024],
            [-80.795949234999966, 35.370134913000072],
            [-80.79568123599995, 35.369461914000055],
            [-80.794869234999965, 35.369124913000064],
            [-80.792751234999969, 35.368284913000025],
            [-80.787396232999981, 35.366161913000042],
            [-80.787087232999966, 35.367248913000026],
            [-80.786982232999947, 35.367620913000053],
            [-80.786934232999954, 35.367773913000065],
            [-80.786891231999959, 35.36788791400005],
            [-80.786838232999969, 35.368000914000049],
            [-80.786786232999987, 35.368091913000058],
            [-80.786619231999964, 35.368341913000052],
            [-80.785950232999937, 35.369276914000068],
            [-80.785749231999944, 35.369558914000038],
            [-80.784008231999962, 35.368592913000043],
            [-80.783537231999958, 35.368146913000032],
            [-80.782912231999944, 35.367798913000058],
            [-80.782640230999959, 35.367689914000039],
            [-80.781677230999946, 35.367661914000053],
            [-80.781256230999986, 35.367630914000074],
            [-80.780933230999949, 35.367370913000059],
            [-80.780488229999946, 35.366631914000038],
            [-80.780197229999942, 35.36619591300007],
            [-80.779706229999988, 35.36613991300004],
            [-80.779152230999955, 35.366084913000066],
            [-80.778664230999937, 35.366043913000055],
            [-80.776648228999989, 35.365847913000039],
            [-80.776320228999964, 35.365820914000039],
            [-80.775710229999959, 35.36577991300004],
            [-80.775482228999977, 35.365757913000039],
            [-80.775336229999937, 35.365732913000045],
            [-80.775250228999937, 35.365712913000038],
            [-80.774650228999974, 35.365542914000059],
            [-80.774423227999989, 35.36549391300008],
            [-80.774049228999957, 35.365441913000041],
            [-80.773116228999982, 35.365335913000024],
            [-80.772988227999974, 35.365326913000047],
            [-80.772866227999941, 35.365326914000036],
            [-80.772812228999953, 35.365330914000026],
            [-80.772661228999937, 35.365352914000027],
            [-80.772446228999968, 35.365396913000041],
            [-80.770884227999943, 35.365766913000073],
            [-80.770123227999989, 35.365954914000042],
            [-80.769787226999938, 35.366047914000035],
            [-80.76960222799994, 35.366118913000037],
            [-80.769464227999947, 35.366182913000046],
            [-80.769340227999976, 35.366247914000041],
            [-80.768295227999943, 35.366863914000078],
            [-80.768092226999954, 35.366976914000077],
            [-80.767911225999967, 35.367071914000064],
            [-80.767828227999985, 35.367105914000035],
            [-80.767676226999981, 35.367142914000055],
            [-80.76760522699999, 35.367149914000038],
            [-80.767477227999962, 35.367151914000033],
            [-80.767359226999986, 35.367145914000048],
            [-80.765904225999975, 35.367002914000068],
            [-80.765727225999967, 35.366977914000074],
            [-80.765617225999961, 35.366952913000034],
            [-80.76552722699995, 35.366920914000048],
            [-80.765436226999952, 35.366877913000053],
            [-80.764936225999975, 35.36660091400006],
            [-80.764779225999973, 35.366523914000027],
            [-80.764603225999963, 35.366443914000058],
            [-80.764351225999974, 35.366337914000042],
            [-80.764274225999941, 35.366302914000073],
            [-80.763791225999967, 35.366083914000058],
            [-80.763255225999956, 35.365860913000063],
            [-80.763102224999955, 35.365778914000032],
            [-80.762929225999983, 35.365667914000028],
            [-80.762793224999939, 35.365556914000024],
            [-80.762722224999948, 35.365481914000043],
            [-80.762653225999941, 35.36538891400005],
            [-80.762598225999966, 35.365282913000044],
            [-80.762531224999975, 35.365098914000043],
            [-80.762399225999957, 35.364663914000062],
            [-80.762242224999966, 35.364177913000049],
            [-80.762181225999939, 35.364017914000044],
            [-80.762039224999967, 35.363690914000074],
            [-80.761912224999946, 35.363455913000053],
            [-80.761840224999958, 35.363350913000033],
            [-80.761664224999947, 35.363119914000038],
            [-80.761541224999974, 35.362979913000061],
            [-80.761369224999953, 35.36280791400003],
            [-80.761256225999944, 35.362716914000032],
            [-80.761053223999966, 35.36256291400008],
            [-80.760682224999982, 35.362355913000044],
            [-80.760563224999942, 35.362297913000077],
            [-80.76038022399996, 35.362194913000053],
            [-80.759907224999949, 35.361808913000061],
            [-80.759740223999984, 35.361682914000028],
            [-80.759207224999955, 35.361249914000041],
            [-80.758940223999957, 35.361012913000025],
            [-80.758721223999942, 35.360775913000055],
            [-80.758559223999953, 35.360563913000078],
            [-80.758415223999975, 35.360318913000071],
            [-80.758401223999954, 35.360285913000041],
            [-80.758388224999976, 35.360266913000032],
            [-80.757704223999951, 35.360475913000073],
            [-80.757533222999939, 35.360520912000027],
            [-80.757423223999979, 35.360537912000041],
            [-80.757268222999983, 35.360549913000057],
            [-80.756832223999936, 35.360563913000078],
            [-80.756649223999943, 35.360569913000063],
            [-80.756503222999982, 35.360583913000028],
            [-80.75640322299995, 35.360609913000076],
            [-80.756330222999964, 35.360633914000061],
            [-80.75626822299995, 35.360658913000066],
            [-80.756222222999952, 35.360682913000062],
            [-80.75612122299998, 35.360745913000073],
            [-80.756041222999954, 35.360804913000038],
            [-80.75597522299995, 35.360866913000052],
            [-80.755718223999963, 35.360724914000059],
            [-80.755590222999956, 35.360666913000045],
            [-80.755456222999953, 35.360628913000028],
            [-80.755302222999944, 35.360604913000032],
            [-80.75463922299997, 35.360549913000057],
            [-80.754525222999973, 35.360543913000072],
            [-80.754520222999986, 35.360687914000039],
            [-80.754445222999948, 35.361927914000034],
            [-80.754373222999959, 35.363388914000041],
            [-80.754709222999963, 35.363627913000073],
            [-80.75481122299999, 35.363763913000071],
            [-80.754941221999957, 35.363916914000072],
            [-80.755005222999955, 35.364166914000066],
            [-80.755038221999939, 35.36427791400007],
            [-80.755056221999951, 35.364338914000029],
            [-80.755062222999982, 35.364603914000043],
            [-80.755090223999957, 35.364716914000041],
            [-80.75514222299995, 35.364799914000059],
            [-80.755149223999979, 35.364936914000054],
            [-80.755170222999936, 35.365088914000069],
            [-80.755338222999967, 35.365428914000063],
            [-80.755442222999989, 35.365704914000048],
            [-80.755537223999966, 35.365821914000037],
            [-80.755594222999946, 35.365982914000028],
            [-80.755632223999953, 35.366065914000046],
            [-80.755678223999951, 35.366151915000046],
            [-80.755771222999954, 35.36626691500004],
            [-80.755891223999981, 35.366431914000032],
            [-80.755893223999976, 35.366526914000076],
            [-80.755928223999945, 35.366606914000045],
            [-80.755973222999955, 35.366680914000028],
            [-80.756004222999934, 35.366751915000066],
            [-80.756075223999972, 35.366873915000042],
            [-80.756172223999954, 35.366943914000046],
            [-80.756209223999974, 35.366981915000054],
            [-80.756353223999952, 35.367071914000064],
            [-80.756600223999953, 35.36723891500003],
            [-80.756656223999983, 35.367285914000036],
            [-80.756918222999957, 35.367606914000078],
            [-80.757116223999958, 35.36788391500005],
            [-80.757128223999985, 35.367914915000028],
            [-80.75713022299999, 35.36793291500004],
            [-80.757405223999967, 35.36827091400005],
            [-80.757465223999986, 35.368398915000057],
            [-80.75752722499999, 35.368468915000051],
            [-80.757556223999984, 35.368509915000061],
            [-80.757563223999966, 35.368599915000061],
            [-80.757627224999965, 35.368670914000063],
            [-80.75781322399996, 35.368701915000031],
            [-80.757893223999986, 35.368696914000054],
            [-80.758075223999981, 35.368732914000077],
            [-80.758158223999942, 35.368763915000045],
            [-80.758373223999968, 35.368795914000032],
            [-80.758500223999988, 35.36880691500005],
            [-80.758573223999974, 35.368816915000025],
            [-80.758640223999976, 35.368830914000057],
            [-80.758769223999934, 35.368869915000062],
            [-80.758954224999968, 35.368898915000045],
            [-80.759024223999972, 35.368915915000059],
            [-80.759060224999985, 35.368933914000024],
            [-80.759082224999986, 35.368953915000077],
            [-80.759270224999966, 35.369060914000045],
            [-80.75946722499998, 35.369187915000055],
            [-80.759671224999977, 35.369391914000062],
            [-80.759725224999954, 35.369426915000076],
            [-80.759763224999972, 35.369443915000033],
            [-80.759800224999935, 35.369466915000032],
            [-80.760029223999936, 35.369566915000064],
            [-80.760069223999949, 35.369579915000031],
            [-80.76013622499994, 35.369592915000055],
            [-80.760203224999941, 35.369596915000045],
            [-80.760250223999947, 35.369603915000027],
            [-80.760481224999978, 35.369699915000069],
            [-80.760775224999975, 35.369846915000039],
            [-80.760808224999948, 35.369863914000064],
            [-80.760897224999951, 35.369889915000044],
            [-80.760979224999971, 35.369888914000057],
            [-80.76105122499996, 35.369901915000071],
            [-80.761150225999984, 35.36992891500006],
            [-80.761342224999964, 35.369992915000068],
            [-80.761660225999947, 35.370065915000055],
            [-80.761720224999976, 35.370068915000047],
            [-80.761893225999984, 35.370103915000072],
            [-80.761994225999956, 35.37011691500004],
            [-80.762036224999974, 35.370127916000058],
            [-80.762086225999951, 35.370152914000073],
            [-80.762111225999945, 35.370176914000069],
            [-80.762137225999936, 35.370217914000079],
            [-80.762172225999961, 35.370256915000027],
            [-80.762195224999971, 35.370295914000053],
            [-80.762226225999939, 35.370310915000061],
            [-80.762369224999986, 35.370354914000075],
            [-80.762468224999964, 35.370375915000068],
            [-80.762522225999987, 35.37037491500007],
            [-80.762567225999987, 35.370380915000055],
            [-80.762605224999959, 35.370393915000079],
            [-80.762648225999953, 35.37042391600005],
            [-80.762773225999979, 35.370486915000072],
            [-80.76296122499997, 35.370538915000054],
            [-80.763049225999964, 35.370556915000066],
            [-80.763125225999943, 35.370576915000072],
            [-80.763224226999967, 35.370609915000045],
            [-80.763414226999942, 35.370660914000041],
            [-80.763483225999948, 35.370686915000078],
            [-80.763541225999973, 35.370700914000054],
            [-80.763616225999954, 35.370697914000061],
            [-80.763671225999985, 35.37070591500003],
            [-80.763711225999941, 35.370743915000048],
            [-80.763827225999989, 35.370872914000074],
            [-80.763881225999967, 35.370921915000054],
            [-80.763912225999945, 35.370980915000075],
            [-80.763903225999968, 35.371018915000036],
            [-80.763886225999954, 35.371059915000046],
            [-80.763878224999985, 35.371099915000059],
            [-80.763882226999954, 35.371151915000041],
            [-80.763915225999938, 35.371205914000029],
            [-80.763976225999954, 35.371277915000064],
            [-80.764044225999953, 35.371343914000079],
            [-80.764154226999949, 35.371460915000057],
            [-80.764211225999986, 35.371503915000062],
            [-80.764296226999988, 35.371544915000072],
            [-80.764330226999959, 35.371550915000057],
            [-80.764427225999952, 35.371551916000044],
            [-80.764488226999958, 35.371544915000072],
            [-80.764540225999951, 35.371543915000075],
            [-80.764691225999968, 35.371522915000071],
            [-80.764762225999959, 35.371548915000062],
            [-80.764831225999956, 35.371563915000024],
            [-80.764963225999963, 35.371613915000069],
            [-80.765090225999984, 35.371671916000025],
            [-80.765149225999949, 35.371671916000025],
            [-80.765184225999974, 35.37164791500004],
            [-80.765318226999966, 35.371540915000026],
            [-80.76539622599995, 35.371489915000041],
            [-80.765489225999943, 35.371453915000075],
            [-80.76552722699995, 35.371445915000038],
            [-80.765597226999944, 35.371444915000041],
            [-80.765688225999952, 35.371456916000056],
            [-80.765857226999969, 35.371497915000077],
            [-80.76595122699996, 35.371494915000028],
            [-80.766039226999965, 35.371445915000038],
            [-80.766243226999961, 35.371285915000044],
            [-80.766299225999944, 35.371269915000028],
            [-80.766312226999958, 35.371540914000036],
            [-80.766312225999968, 35.371577915000046],
            [-80.766310226999963, 35.371690916000034],
            [-80.766302226999983, 35.371739915000035],
            [-80.766222226999957, 35.37187691500003],
            [-80.76599622599997, 35.372144915000035],
            [-80.765877226999976, 35.372256916000026],
            [-80.765795225999966, 35.372326915000031],
            [-80.765646225999944, 35.372449915000061],
            [-80.765484226999945, 35.372607916000049],
            [-80.765387226999962, 35.372729915000036],
            [-80.765309226999989, 35.372870915000078],
            [-80.765275225999972, 35.372967915000061],
            [-80.765256225999963, 35.373194915000056],
            [-80.765254226999957, 35.373360916000024],
            [-80.765245225999934, 35.373606915000039],
            [-80.765234226999951, 35.373721915000033],
            [-80.765213226999947, 35.373841915000071],
            [-80.76515122699999, 35.374019915000076],
            [-80.768493227999954, 35.374972915000058],
            [-80.769319227999972, 35.375214916000061],
            [-80.76968822799995, 35.375310916000046],
            [-80.769816228999957, 35.375337916000035],
            [-80.770021228999951, 35.37536891600007],
            [-80.77019822799997, 35.375380915000051],
            [-80.770340227999952, 35.37538091600004],
            [-80.770446227999969, 35.375374916000055],
            [-80.770658227999945, 35.37534691500008],
            [-80.770769227999949, 35.375325916000065],
            [-80.773075227999982, 35.374756915000034],
            [-80.773966229999985, 35.374518915000067],
            [-80.77638922999995, 35.373801914000069],
            [-80.776559229999975, 35.373751915000071],
            [-80.777106230999948, 35.373577915000055],
            [-80.777474229999939, 35.373470914000052],
            [-80.777779229999965, 35.373371915000064],
            [-80.77790023099999, 35.373335914000052],
            [-80.778098230999944, 35.373904915000026],
            [-80.778124230999936, 35.373976915000071],
            [-80.778131229999985, 35.374045916000057],
            [-80.77813722999997, 35.374180915000068],
            [-80.778160230999958, 35.374286915000027],
            [-80.778223229999981, 35.37440091600007],
            [-80.778302230999941, 35.374474915000064],
            [-80.778374230999987, 35.374519914000075],
            [-80.778466229999935, 35.374557916000072],
            [-80.778573230999939, 35.374602915000025],
            [-80.778679230999956, 35.374647915000025],
            [-80.778920230999972, 35.374747915000057],
            [-80.779128230999959, 35.374834915000065],
            [-80.779265230999954, 35.374891915000035],
            [-80.779513229999964, 35.374995915000056],
            [-80.779710230999967, 35.375078915000074],
            [-80.779951230999984, 35.375186915000029],
            [-80.780080230999943, 35.37524591500005],
            [-80.780143230999954, 35.375298916000077],
            [-80.78029823199995, 35.375461915000074],
            [-80.780483230999948, 35.37563691500003],
            [-80.780667231999985, 35.375789915000041],
            [-80.780710230999944, 35.375820916000066],
            [-80.78062423099999, 35.375878915000044],
            [-80.780553230999942, 35.375938916000052],
            [-80.78050923099994, 35.375991916000032],
            [-80.78045623099996, 35.376073915000063],
            [-80.780035231999989, 35.377064916000052],
            [-80.780018230999985, 35.377117915000042],
            [-80.780019230999983, 35.377168916000073],
            [-80.780115230999968, 35.377669916000059],
            [-80.779945230999942, 35.377761916000054],
            [-80.779815230999986, 35.377995915000042],
            [-80.779677230999937, 35.378317916000071],
            [-80.779516230999945, 35.378698916000076],
            [-80.779502230999981, 35.378953916000057],
            [-80.779536230999952, 35.379037917000062],
            [-80.779665231999957, 35.379168916000026],
            [-80.779858231999981, 35.379297916000041],
            [-80.780334230999983, 35.379612916000042],
            [-80.780757231999985, 35.379892916000074],
            [-80.781171230999973, 35.38014991600005],
            [-80.781368231999977, 35.380199916000038],
            [-80.781625231999953, 35.380269917000078],
            [-80.782115231999967, 35.380523917000062],
            [-80.782722232999959, 35.380973917000063],
            [-80.78291623299998, 35.381855917000053],
            [-80.782924231999971, 35.381984917000068],
            [-80.783470232999946, 35.38190591700004],
            [-80.786088232999987, 35.381527916000039],
            [-80.786112233999972, 35.381666916000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 37,
        "SolutionID": "PI-16",
        "Shape_Length": 0.13027682783159319,
        "Shape_Area": 0.00035980798834289686
      }
    },
    {
      "type": "Feature",
      "id": 38,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.84019224299999, 35.261062888000026],
              [-80.839806243999988, 35.261086889000069],
              [-80.839372242999957, 35.26111288900006],
              [-80.838254241999948, 35.261181888000067],
              [-80.838015241999983, 35.261196889000075],
              [-80.83705624199996, 35.261260888000038],
              [-80.837058242999944, 35.261500889000047],
              [-80.837082240999962, 35.261950889000047],
              [-80.83708924299998, 35.262041889000045],
              [-80.837124241999959, 35.262424888000055],
              [-80.837196241999948, 35.26338288900007],
              [-80.837250242999971, 35.264104889000066],
              [-80.837417241999958, 35.266390890000025],
              [-80.837451241999986, 35.266837890000033],
              [-80.837469240999951, 35.267004891000056],
              [-80.837461241999961, 35.267184889000077],
              [-80.83748424199996, 35.267519890000074],
              [-80.837524242999962, 35.267875890000028],
              [-80.837563241999987, 35.268543891000036],
              [-80.837564241999985, 35.268794890000038],
              [-80.837590241999976, 35.26925889000006],
              [-80.837578242999939, 35.269552891000046],
              [-80.837567241999977, 35.269692891000034],
              [-80.837537241999939, 35.269964891000029],
              [-80.837496242999975, 35.27023489000004],
              [-80.837444242999936, 35.270501891000038],
              [-80.83737924199994, 35.270768891000046],
              [-80.837304242999949, 35.271032891000061],
              [-80.837218241999949, 35.271294892000071],
              [-80.83699524299999, 35.271875891000036],
              [-80.83666524299997, 35.27272589100005],
              [-80.836870242999964, 35.272734891000027],
              [-80.837246241999935, 35.272751891000041],
              [-80.837837243999957, 35.272817892000035],
              [-80.839684242999965, 35.272925891000057],
              [-80.840876243999958, 35.272995891000051],
              [-80.841811243999985, 35.273038891000056],
              [-80.842371243999935, 35.273070892000078],
              [-80.842591244999937, 35.273079892000055],
              [-80.842810243999963, 35.273083891000056],
              [-80.843165243999977, 35.273082891000058],
              [-80.843383244999984, 35.273077891000071],
              [-80.843818244999966, 35.273053890000028],
              [-80.844275243999959, 35.273008891000075],
              [-80.844503244999942, 35.272979891000034],
              [-80.844687243999942, 35.272951892000037],
              [-80.844846244999985, 35.272927891000052],
              [-80.84495824399994, 35.272910891000038],
              [-80.846745244999966, 35.272572891000038],
              [-80.846734244999936, 35.272024890000068],
              [-80.846727244999954, 35.27175789100005],
              [-80.846704244999955, 35.271462891000056],
              [-80.84666124599994, 35.271169890000067],
              [-80.846575245999986, 35.270757891000073],
              [-80.846531244999937, 35.270579890000079],
              [-80.846377245999975, 35.270149890000027],
              [-80.846230244999958, 35.269808891000025],
              [-80.846160244999965, 35.269671890000041],
              [-80.846087244999978, 35.269527890000063],
              [-80.845923244999938, 35.269240890000049],
              [-80.845772244999978, 35.269007890000069],
              [-80.845575244999964, 35.268719891000046],
              [-80.845393243999979, 35.268467890000068],
              [-80.845121244999973, 35.268084890000068],
              [-80.844913244999987, 35.267792890000067],
              [-80.844629243999975, 35.267411891000052],
              [-80.844319243999962, 35.266995889000043],
              [-80.844033243999945, 35.266602890000058],
              [-80.843340242999943, 35.265633890000061],
              [-80.842190242999948, 35.264023889000043],
              [-80.841995243999975, 35.263780889000031],
              [-80.841836242999989, 35.263548890000038],
              [-80.841636243999972, 35.263219889000027],
              [-80.841507241999977, 35.263016889000028],
              [-80.841251243999977, 35.262637889000075],
              [-80.84116024299999, 35.262472889000037],
              [-80.84107424299998, 35.262305889000061],
              [-80.840914242999986, 35.261959888000035],
              [-80.840815242999952, 35.261694889000069],
              [-80.840761242999974, 35.261510888000032],
              [-80.840736242999981, 35.261424889000068],
              [-80.840674242999967, 35.261150889000078],
              [-80.840656242999955, 35.26103488900003],
              [-80.84019224299999, 35.261062888000026]
            ]
          ],
          [
            [
              [-80.857355248999966, 35.280726892000075],
              [-80.85754824899999, 35.280738892000045],
              [-80.857688248999978, 35.280754893000051],
              [-80.857840248999935, 35.28077789200006],
              [-80.858785248999936, 35.280951892000076],
              [-80.859135249999952, 35.280997892000073],
              [-80.859338248999961, 35.281016892000025],
              [-80.860108249999939, 35.281085893000068],
              [-80.860720249999986, 35.281146892000038],
              [-80.861321249999946, 35.281229892000056],
              [-80.862457250999967, 35.281383892000065],
              [-80.862552250999954, 35.280921892000038],
              [-80.862593249999975, 35.280788892000032],
              [-80.862650250999934, 35.280668892000051],
              [-80.862748249999981, 35.280478892000076],
              [-80.862813249999988, 35.280389892000073],
              [-80.862892250999948, 35.280311891000053],
              [-80.862946249999936, 35.280269892000035],
              [-80.863055250999935, 35.280203892000031],
              [-80.863233249999951, 35.280129892000048],
              [-80.863284251999971, 35.280102892000059],
              [-80.863375250999979, 35.28003889200005],
              [-80.863460250999935, 35.279950892000045],
              [-80.863657251999939, 35.27972389100006],
              [-80.863946250999959, 35.279403892000062],
              [-80.864626250999947, 35.278647892000038],
              [-80.864958251999951, 35.278270891000034],
              [-80.865333251999971, 35.277860891000046],
              [-80.865633250999963, 35.277532891000078],
              [-80.865678250999963, 35.277475892000041],
              [-80.865934251999988, 35.277196892000063],
              [-80.866326250999975, 35.276752891000058],
              [-80.866434251999976, 35.276632891000077],
              [-80.866537250999954, 35.276504890000069],
              [-80.866580250999959, 35.276435891000062],
              [-80.866631251999934, 35.276324891000058],
              [-80.866662251999969, 35.276208891000067],
              [-80.866686251999965, 35.276026891000072],
              [-80.866996250999989, 35.276060891000043],
              [-80.866934251999965, 35.275964891000058],
              [-80.866862251999976, 35.275898891000054],
              [-80.866739250999956, 35.275773891000028],
              [-80.86669225199995, 35.275657891000037],
              [-80.866671251999946, 35.275439891000076],
              [-80.866615251999974, 35.275327891000074],
              [-80.866537250999954, 35.275071891000039],
              [-80.866515250999953, 35.274981891000039],
              [-80.866485251999961, 35.274785891000079],
              [-80.866458250999983, 35.274665891000041],
              [-80.866429250999943, 35.274579890000041],
              [-80.866371250999975, 35.274524890000066],
              [-80.866221250999956, 35.274270891000072],
              [-80.866191250999975, 35.274227890000077],
              [-80.866145250999978, 35.274182891000066],
              [-80.866084250999961, 35.274050891000059],
              [-80.86606225099996, 35.273985891000052],
              [-80.865939251999976, 35.27379189100003],
              [-80.86589625199997, 35.273738890000061],
              [-80.865789250999967, 35.273583890000054],
              [-80.86578824999998, 35.273553891000063],
              [-80.865794250999954, 35.273511890000066],
              [-80.865811250999968, 35.273490890000062],
              [-80.865815250999958, 35.273462890000076],
              [-80.865801250999937, 35.273429890000045],
              [-80.865741250999974, 35.273383890000048],
              [-80.865678250999963, 35.273353890000067],
              [-80.865628250999976, 35.273301891000074],
              [-80.865580250999983, 35.273268890000054],
              [-80.865366250999955, 35.273265891000051],
              [-80.865289250999979, 35.273012891000064],
              [-80.865278251999939, 35.272895891000076],
              [-80.865260250999938, 35.272801890000039],
              [-80.865216251999982, 35.272617891000039],
              [-80.865197251999973, 35.272502890000055],
              [-80.865200250999976, 35.272315890000073],
              [-80.865226249999978, 35.272127890000036],
              [-80.865260250999938, 35.271925890000034],
              [-80.86530725099999, 35.271759890000055],
              [-80.865345250999951, 35.271657890000029],
              [-80.865395250999939, 35.271469890000049],
              [-80.865456250999955, 35.271281889000079],
              [-80.865472250999971, 35.27120989000008],
              [-80.865648250999982, 35.270642890000033],
              [-80.865673250999976, 35.270530890000032],
              [-80.86568325099995, 35.270437890000039],
              [-80.865688249999948, 35.270126890000029],
              [-80.865668251999978, 35.269417890000057],
              [-80.865677249999976, 35.269338889000039],
              [-80.865698250999969, 35.269152889000054],
              [-80.865722250999966, 35.26894388900007],
              [-80.86685825099994, 35.268727889000047],
              [-80.869100251999953, 35.268292890000055],
              [-80.869582251999987, 35.268192889000034],
              [-80.870035252999969, 35.268090889000064],
              [-80.869967251999981, 35.267909888000077],
              [-80.86989625199999, 35.267722889000026],
              [-80.869833251999978, 35.267643890000045],
              [-80.869821251999952, 35.267605890000027],
              [-80.869812251999974, 35.267535889000044],
              [-80.869777251999949, 35.267446889000041],
              [-80.869760252999981, 35.267350889000056],
              [-80.86975825199994, 35.267275890000064],
              [-80.869780252999988, 35.267167888000074],
              [-80.869788251999978, 35.267019889000039],
              [-80.869783251999934, 35.266987889000063],
              [-80.869693251999934, 35.266783889000067],
              [-80.869669251999937, 35.266544889000045],
              [-80.86966825199994, 35.26641188800005],
              [-80.869658251999965, 35.266369889000032],
              [-80.869642252999938, 35.266327889000024],
              [-80.869631251999976, 35.266278889000034],
              [-80.869571251999957, 35.266187888000047],
              [-80.869555251999941, 35.26613788800006],
              [-80.869541251999976, 35.266049889000044],
              [-80.869486251999945, 35.265975888000071],
              [-80.869451252999966, 35.265916888000049],
              [-80.869434250999973, 35.265870888000052],
              [-80.869418251999946, 35.265759888000048],
              [-80.869381251999982, 35.265687889000048],
              [-80.869380251999985, 35.265593889000058],
              [-80.869371251999951, 35.265549888000066],
              [-80.869348251999952, 35.265502889000061],
              [-80.869326250999961, 35.265419889000043],
              [-80.869326250999961, 35.265374888000053],
              [-80.869318251999971, 35.265336889000025],
              [-80.869303251999952, 35.265309889000036],
              [-80.86928825199999, 35.265295889000072],
              [-80.869209251999962, 35.265201889000025],
              [-80.869112251999979, 35.265103889000045],
              [-80.869070251999972, 35.265036889000044],
              [-80.869037251999941, 35.264963889000057],
              [-80.869006250999973, 35.264942888000064],
              [-80.86897025199994, 35.26490988900008],
              [-80.868849250999972, 35.264853889000051],
              [-80.868796250999935, 35.264833889000045],
              [-80.868773250999936, 35.26483588800005],
              [-80.868721250999954, 35.264724889000036],
              [-80.868695251999952, 35.264319888000045],
              [-80.868715251999959, 35.264266889000055],
              [-80.868793250999943, 35.264115888000049],
              [-80.868824251999968, 35.263999889000047],
              [-80.868473250999955, 35.264011888000027],
              [-80.86842225099997, 35.264013889000069],
              [-80.867313250999985, 35.264040889000057],
              [-80.86724325199998, 35.264053888000035],
              [-80.866913250999971, 35.264100889000076],
              [-80.866790250999941, 35.264122888000031],
              [-80.866546249999942, 35.264179888000058],
              [-80.866297250999935, 35.264257888000031],
              [-80.865708249999955, 35.264463889000069],
              [-80.865626250999981, 35.264488889000063],
              [-80.865455250999958, 35.264527889000078],
              [-80.865324249999958, 35.264550888000031],
              [-80.865124249999951, 35.264572888000032],
              [-80.865013249999947, 35.264578889000063],
              [-80.864811249999946, 35.264575888000024],
              [-80.864624249999963, 35.26455788800007],
              [-80.863693249999983, 35.264426889000049],
              [-80.863081249999937, 35.264345889000026],
              [-80.860532249999949, 35.264100889000076],
              [-80.859870247999936, 35.264040889000057],
              [-80.858682248999969, 35.263924889000066],
              [-80.857430247999957, 35.26380688900008],
              [-80.857692247999978, 35.262582888000054],
              [-80.857158248999951, 35.262660888000028],
              [-80.856955246999973, 35.262689888000068],
              [-80.855970246999959, 35.262823888000071],
              [-80.854718246999937, 35.262998889000073],
              [-80.854458246999968, 35.264245889000051],
              [-80.854225246999988, 35.265340889000072],
              [-80.854201246999935, 35.265458890000048],
              [-80.853960246999975, 35.266610890000038],
              [-80.853941246999966, 35.266772889000038],
              [-80.85395424699999, 35.267322890000059],
              [-80.853971246999947, 35.268047890000048],
              [-80.853984247999961, 35.268284889000029],
              [-80.854016246999947, 35.268864889000042],
              [-80.854111247999981, 35.270106891000069],
              [-80.854190247999952, 35.271147890000066],
              [-80.854205246999982, 35.271349891000057],
              [-80.854259247999948, 35.271958891000054],
              [-80.854275247999965, 35.272187890000055],
              [-80.854354247999936, 35.272985891000076],
              [-80.854380247999984, 35.273222891000046],
              [-80.854499247999968, 35.274249891000068],
              [-80.854539246999934, 35.274445891000028],
              [-80.854589247999968, 35.274633891000065],
              [-80.854659247999962, 35.274838891000059],
              [-80.854830247999985, 35.275204891000044],
              [-80.854863248999948, 35.275293891000047],
              [-80.855270247999954, 35.276378891000036],
              [-80.855283247999978, 35.276413891000061],
              [-80.855336247999958, 35.276576891000047],
              [-80.855390248999981, 35.276764892000074],
              [-80.855885248999982, 35.278528892000054],
              [-80.856522247999976, 35.280760892000046],
              [-80.856652248999978, 35.28074489200003],
              [-80.857049248999942, 35.280723892000026],
              [-80.857179248999955, 35.280722892000028],
              [-80.857355248999966, 35.280726892000075]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 38,
        "SolutionID": "PI-16",
        "Shape_Length": 0.10273233292782799,
        "Shape_Area": 0.00028156932988079538
      }
    },
    {
      "type": "Feature",
      "id": 39,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 39,
        "SolutionID": "PI-16",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 40,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.885594247999961, 35.112545855000064],
              [-80.885866247999957, 35.112567855000066],
              [-80.886136247999957, 35.112605855000027],
              [-80.886436248999985, 35.11267485500008],
              [-80.886740248999956, 35.112760855000033],
              [-80.886889247999989, 35.112810855000077],
              [-80.887378248999937, 35.11303885500007],
              [-80.888287249999962, 35.113464855000075],
              [-80.888543249999941, 35.113565856000037],
              [-80.888704248999943, 35.113618855000027],
              [-80.888961249999966, 35.11368785500008],
              [-80.889137248999987, 35.113723856000036],
              [-80.889406249999979, 35.113765855000054],
              [-80.889609248999989, 35.113784855000063],
              [-80.889888249999956, 35.113794856000027],
              [-80.890181249999955, 35.113786855000058],
              [-80.890315249999958, 35.113775855000029],
              [-80.890578249999976, 35.113741856000047],
              [-80.890868249999983, 35.113684856000077],
              [-80.891066249999938, 35.113632855000048],
              [-80.892878250999956, 35.113047855000048],
              [-80.895826251999949, 35.112095854000074],
              [-80.897564251999938, 35.111530855000069],
              [-80.896719250999979, 35.110666855000034],
              [-80.896162250999964, 35.110071854000068],
              [-80.89564625099996, 35.109545855000079],
              [-80.89504625099994, 35.108949854000059],
              [-80.894750249999959, 35.108670854000025],
              [-80.894612250999955, 35.108540853000079],
              [-80.894282249999947, 35.108259855000028],
              [-80.893983250999952, 35.108012853000048],
              [-80.89370624999998, 35.107793855000068],
              [-80.893288249999955, 35.107474854000031],
              [-80.892614249999951, 35.107009854000069],
              [-80.892283249999934, 35.106794854000043],
              [-80.891868249999959, 35.106539854000061],
              [-80.891496249999989, 35.106321854000043],
              [-80.891394249999962, 35.106264854000074],
              [-80.891090248999944, 35.106093854000051],
              [-80.890726249999943, 35.105898854000031],
              [-80.890400249999971, 35.10573785400004],
              [-80.889666248999958, 35.105396854000048],
              [-80.889144248999969, 35.105173854000043],
              [-80.88542924799998, 35.103713854000034],
              [-80.885276247999968, 35.103653854000072],
              [-80.884399246999976, 35.103289853000035],
              [-80.884233246999941, 35.103227854000068],
              [-80.882886246999988, 35.102723854000033],
              [-80.882819246999986, 35.102698853000049],
              [-80.881755246999944, 35.102214854000067],
              [-80.880874246999952, 35.10176385300008],
              [-80.880831246999946, 35.101921854000068],
              [-80.880791245999944, 35.102167853000026],
              [-80.880737246999956, 35.102581853000061],
              [-80.880700246999936, 35.102996854000025],
              [-80.880681245999938, 35.10347485300008],
              [-80.880679245999943, 35.103620854000042],
              [-80.880687245999979, 35.104036853000025],
              [-80.880709245999981, 35.104403853000065],
              [-80.880738245999964, 35.104606853000064],
              [-80.880784245999962, 35.104933853000034],
              [-80.880810245999953, 35.105070853000029],
              [-80.880888246999973, 35.105476853000027],
              [-80.880978245999984, 35.105845853000062],
              [-80.881056245999957, 35.106132854000066],
              [-80.88144824699998, 35.107475854000029],
              [-80.881449245999988, 35.107476855000073],
              [-80.881612246999964, 35.108011855000029],
              [-80.88206024699997, 35.109521855000025],
              [-80.882392246999984, 35.110643854000045],
              [-80.882548246999988, 35.111185855000031],
              [-80.882801246999975, 35.112107855000033],
              [-80.882891246999975, 35.112474856000063],
              [-80.883015247999936, 35.112977855000054],
              [-80.883681247999959, 35.112819856000044],
              [-80.883994247999965, 35.112745855000071],
              [-80.884339247999947, 35.112673855000025],
              [-80.88467824899999, 35.112609855000073],
              [-80.884859246999952, 35.112582856000074],
              [-80.885195247999945, 35.112545855000064],
              [-80.88532024899996, 35.112541855000075],
              [-80.885594247999961, 35.112545855000064]
            ]
          ],
          [
            [
              [-80.877847246999977, 35.140237862000049],
              [-80.877965247999953, 35.140263861000051],
              [-80.878077246999965, 35.140306861000056],
              [-80.87879924799995, 35.14068386100007],
              [-80.879001248999941, 35.140768862000073],
              [-80.879326247999984, 35.140877861000035],
              [-80.879784247999964, 35.141011862000028],
              [-80.880254248999961, 35.141130861000079],
              [-80.880397247999952, 35.141162862000044],
              [-80.880752248999954, 35.141224861000069],
              [-80.880818248999958, 35.141232862000038],
              [-80.880951247999974, 35.141234862000033],
              [-80.881022247999965, 35.141229862000046],
              [-80.881146248999983, 35.141206862000047],
              [-80.881264248999969, 35.141167861000042],
              [-80.881388247999951, 35.141105861000028],
              [-80.881524248999938, 35.141014861000031],
              [-80.882396248999953, 35.140340861000027],
              [-80.882554248999952, 35.140214861000061],
              [-80.882872247999956, 35.139961861000074],
              [-80.882983249999938, 35.139872862000061],
              [-80.883187248999945, 35.139664861000028],
              [-80.883373249999977, 35.139446860000078],
              [-80.883518248999962, 35.139251861000048],
              [-80.883567248999952, 35.139178861000062],
              [-80.883694248999973, 35.13898886100003],
              [-80.884173248999957, 35.138269861000026],
              [-80.884322248999979, 35.138044861000026],
              [-80.884382248999941, 35.137930861000029],
              [-80.884414248999974, 35.137833861000047],
              [-80.884433248999983, 35.137723860000051],
              [-80.884431248999988, 35.137639861000025],
              [-80.884420248999959, 35.137586861000045],
              [-80.884325248999971, 35.137285861000066],
              [-80.883926248999956, 35.137379860000067],
              [-80.881651248999958, 35.137909861000026],
              [-80.881465247999984, 35.137942861000056],
              [-80.881322248999936, 35.137962861000062],
              [-80.881037247999984, 35.137989861000051],
              [-80.880823247999956, 35.138000862000069],
              [-80.880532247999952, 35.137995861000036],
              [-80.880216247999954, 35.137969861000045],
              [-80.87935424899996, 35.137846861000071],
              [-80.879336247999959, 35.137455861000035],
              [-80.879337247999956, 35.137165861000028],
              [-80.879351247999978, 35.136860861000059],
              [-80.879376247999971, 35.136596861000044],
              [-80.879409247999945, 35.136390861000052],
              [-80.879424247999964, 35.136298860000068],
              [-80.879474247999951, 35.136068861000069],
              [-80.879560247999962, 35.13574286000005],
              [-80.87965524699996, 35.135455860000036],
              [-80.87973324799998, 35.135252861000026],
              [-80.879882247999944, 35.134914860000038],
              [-80.879984247999971, 35.13471786000008],
              [-80.880769247999979, 35.133287860000053],
              [-80.88134724799994, 35.132229860000052],
              [-80.881690248999973, 35.131604859000049],
              [-80.882025247999934, 35.130995859000052],
              [-80.882175247999953, 35.130714859000079],
              [-80.882516247999945, 35.130087859000071],
              [-80.882002247999935, 35.129917859000045],
              [-80.88009324799998, 35.129274859000077],
              [-80.878580245999956, 35.128784859000064],
              [-80.877672246999964, 35.12847885900004],
              [-80.877149245999988, 35.128293859000053],
              [-80.876974246999964, 35.128235858000039],
              [-80.876753246999954, 35.128162859000042],
              [-80.876567245999979, 35.128430858000058],
              [-80.876368246999959, 35.128743858000064],
              [-80.876268245999938, 35.128919859000064],
              [-80.876187246999962, 35.129064859000039],
              [-80.876104245999954, 35.129227860000071],
              [-80.875951245999943, 35.12960085900005],
              [-80.875605246999953, 35.130485859000032],
              [-80.875540245999957, 35.130655859000058],
              [-80.875472245999958, 35.130853860000059],
              [-80.875391245999936, 35.131141860000071],
              [-80.87535924599996, 35.131286860000046],
              [-80.875327245999983, 35.131462860000056],
              [-80.875280245999988, 35.131880859000034],
              [-80.875265245999969, 35.132090861000052],
              [-80.875299246999987, 35.132696860000067],
              [-80.875729246999981, 35.134780860000035],
              [-80.875831246999951, 35.135244861000047],
              [-80.875987245999966, 35.135956860000078],
              [-80.876031245999968, 35.136155860000031],
              [-80.876338245999989, 35.137725861000035],
              [-80.876684247999947, 35.137719861000051],
              [-80.876692246999937, 35.137760861000061],
              [-80.876797247999946, 35.138950861000069],
              [-80.877116246999947, 35.140341862000071],
              [-80.877527246999989, 35.140337861000035],
              [-80.877534246999971, 35.140328862000047],
              [-80.877592247999985, 35.140281861000062],
              [-80.877664246999984, 35.140249862000076],
              [-80.877702247999935, 35.140238862000047],
              [-80.877783246999968, 35.140230861000077],
              [-80.877847246999977, 35.140237862000049]
            ]
          ],
          [
            [
              [-80.876997248999942, 35.156601865000027],
              [-80.878228247999971, 35.156763865000073],
              [-80.881161249999934, 35.158181866000064],
              [-80.88131924999999, 35.157614865000028],
              [-80.882423248999942, 35.157614865000028],
              [-80.883306249999976, 35.157356865000054],
              [-80.884758250999937, 35.156891865000034],
              [-80.885388249999949, 35.156479865000051],
              [-80.886082250999948, 35.156582865000075],
              [-80.887016250999977, 35.157074865000027],
              [-80.88733425099997, 35.156644865000032],
              [-80.887690250999981, 35.156233865000047],
              [-80.888241250999954, 35.155548864000025],
              [-80.888324251999961, 35.155471864000049],
              [-80.888371251999956, 35.155437865000067],
              [-80.888450250999938, 35.155392864000078],
              [-80.888512250999952, 35.155364864000035],
              [-80.888643250999962, 35.15532186400003],
              [-80.888699251999981, 35.155308864000062],
              [-80.888958251999952, 35.15526886400005],
              [-80.889476251999952, 35.15522786400004],
              [-80.890254251999977, 35.155103865000058],
              [-80.890222251999944, 35.154984864000028],
              [-80.890240251999955, 35.154871864000029],
              [-80.890230251999981, 35.154770864000056],
              [-80.890199251999945, 35.15455886500007],
              [-80.890181251999934, 35.154466863000039],
              [-80.890146251999965, 35.154399865000073],
              [-80.890129251999952, 35.154283864000035],
              [-80.890120251999974, 35.154143864000048],
              [-80.890122252999959, 35.154069865000054],
              [-80.89013625299998, 35.153996864000078],
              [-80.890162251999982, 35.153950864000024],
              [-80.890166250999982, 35.15390986400007],
              [-80.890178251999941, 35.15388086400003],
              [-80.890198251999948, 35.153784864000045],
              [-80.890230251999981, 35.153675864000036],
              [-80.890232251999976, 35.153632864000031],
              [-80.890275251999981, 35.153481864000071],
              [-80.890290251999943, 35.153368865000061],
              [-80.890312251999944, 35.153274864000025],
              [-80.890356251999947, 35.153178863000051],
              [-80.890362251999989, 35.153141864000077],
              [-80.890330251999956, 35.153069863000042],
              [-80.890379250999956, 35.152983863000031],
              [-80.890394251999965, 35.152824863000035],
              [-80.889219250999986, 35.153000864000035],
              [-80.889162251999949, 35.152589864000049],
              [-80.88908225199998, 35.152070863000063],
              [-80.887997251999934, 35.152233864000038],
              [-80.887832250999963, 35.152245863000076],
              [-80.887690250999981, 35.152248864000057],
              [-80.887505250999936, 35.152238864000026],
              [-80.887414250999939, 35.152227864000054],
              [-80.886958250999953, 35.152147864000028],
              [-80.886497249999934, 35.152061864000075],
              [-80.886617249999972, 35.151582863000044],
              [-80.886653250999984, 35.151522863000025],
              [-80.886678250999978, 35.151497864000078],
              [-80.886768250999978, 35.151424863000045],
              [-80.886904251999965, 35.15129586300003],
              [-80.886939250999944, 35.151238864000049],
              [-80.886957250999956, 35.151175864000038],
              [-80.886956250999958, 35.15111186300004],
              [-80.886935249999965, 35.151049863000026],
              [-80.886894250999944, 35.150977863000037],
              [-80.886837250999974, 35.150916863000077],
              [-80.886755250999954, 35.150860863000048],
              [-80.886669250999944, 35.150824863000025],
              [-80.886576249999962, 35.15080286400007],
              [-80.88579925099998, 35.150763863000066],
              [-80.884878248999939, 35.15072886300004],
              [-80.884762249999937, 35.15072886400003],
              [-80.884648249999941, 35.150717863000068],
              [-80.884455249999974, 35.150690864000069],
              [-80.884386250999967, 35.150651864000054],
              [-80.884374248999961, 35.150613864000036],
              [-80.884346249999965, 35.150553864000074],
              [-80.884259249999957, 35.149364864000063],
              [-80.88425424899998, 35.149302863000059],
              [-80.882522248999976, 35.149411864000058],
              [-80.882484248999958, 35.149004863000073],
              [-80.882439248999958, 35.148679863000041],
              [-80.882184249999966, 35.148689864000062],
              [-80.882013247999964, 35.148683863000031],
              [-80.881864249999978, 35.148670864000053],
              [-80.88142124899997, 35.148582863000058],
              [-80.881265248999966, 35.148566863000042],
              [-80.881113248999952, 35.148557863000065],
              [-80.880983248999939, 35.14855986300006],
              [-80.880723248999971, 35.148595863000025],
              [-80.880521248999969, 35.148633863000043],
              [-80.880086247999941, 35.14873186300008],
              [-80.879972248999934, 35.148775863000026],
              [-80.879881248999936, 35.148844863000079],
              [-80.879820248999977, 35.14891586300007],
              [-80.879777248999972, 35.14900186400007],
              [-80.879734247999977, 35.149230863000071],
              [-80.879696247999959, 35.149309863000042],
              [-80.879641247999984, 35.149368863000063],
              [-80.879558248999956, 35.14942986300008],
              [-80.879466247999972, 35.14947486300008],
              [-80.87936624799994, 35.149500863000071],
              [-80.879266247999965, 35.149511863000043],
              [-80.87916624799999, 35.149503864000053],
              [-80.879062248999958, 35.149484864000044],
              [-80.878957247999949, 35.14943786300006],
              [-80.878852247999987, 35.149365863000071],
              [-80.878769248999959, 35.149315863000027],
              [-80.878658247999965, 35.149278863000063],
              [-80.878530248999937, 35.149260863000052],
              [-80.878491247999989, 35.149488864000034],
              [-80.878293247999977, 35.15082586300008],
              [-80.878148248999935, 35.151652864000027],
              [-80.877857247999941, 35.152932864000036],
              [-80.877299247999986, 35.15284086500003],
              [-80.876997248999942, 35.156601865000027]
            ]
          ],
          [
            [
              [-80.835420236999937, 35.164250868000067],
              [-80.835529235999957, 35.164258868000047],
              [-80.83623623699998, 35.164352868000037],
              [-80.836324235999939, 35.163934868000069],
              [-80.836380235999968, 35.163692867000066],
              [-80.836437235999938, 35.163493868000046],
              [-80.836483236999982, 35.163365868000028],
              [-80.836590236999939, 35.163114868000036],
              [-80.836717236999959, 35.16286986700004],
              [-80.836805236999965, 35.162735867000038],
              [-80.836913236999976, 35.162581868000075],
              [-80.837138235999987, 35.162304867000046],
              [-80.837377235999952, 35.162057867000044],
              [-80.837479235999979, 35.161960868000051],
              [-80.837835236999979, 35.161622867000062],
              [-80.838026236999951, 35.161433868000074],
              [-80.838102236999987, 35.161348867000072],
              [-80.837650235999945, 35.161076867000077],
              [-80.83755423599996, 35.160998867000046],
              [-80.837476235999986, 35.160908868000035],
              [-80.837414235999972, 35.160810867000066],
              [-80.837391235999974, 35.160758868000073],
              [-80.837360236999984, 35.160650867000072],
              [-80.837349236999955, 35.160540868000055],
              [-80.837376235999955, 35.160148868000078],
              [-80.837428236999983, 35.159356868000032],
              [-80.837439235999966, 35.159067867000033],
              [-80.837427236999986, 35.158939867000072],
              [-80.837413235999975, 35.158872867000071],
              [-80.837369235999972, 35.15874186700006],
              [-80.837339236999981, 35.158675867000056],
              [-80.837304235999966, 35.158611867000047],
              [-80.837755235999964, 35.158395867000024],
              [-80.838648236999973, 35.158025867000049],
              [-80.838868236999986, 35.157935866000059],
              [-80.838920236999968, 35.15792086600004],
              [-80.838973236999948, 35.157910867000055],
              [-80.839081236999959, 35.157902867000075],
              [-80.840304236999941, 35.157955866000066],
              [-80.840494236999973, 35.157953866000071],
              [-80.84058923799995, 35.157946866000032],
              [-80.840775236999946, 35.157919866000043],
              [-80.840868236999938, 35.157899867000026],
              [-80.841024236999942, 35.15785586700008],
              [-80.841076236999982, 35.157835867000074],
              [-80.841145236999978, 35.157805867000036],
              [-80.84134023699994, 35.15769186700004],
              [-80.841471236999951, 35.157607867000024],
              [-80.84212023799995, 35.157155866000039],
              [-80.842506237999942, 35.156884866000041],
              [-80.842593237999949, 35.156827866000071],
              [-80.842733238999983, 35.156755867000072],
              [-80.842891237999936, 35.156694866000066],
              [-80.842972236999969, 35.15667086600007],
              [-80.843704237999987, 35.156477866000046],
              [-80.843484237999974, 35.155889866000052],
              [-80.843460237999977, 35.155771866000066],
              [-80.843456237999987, 35.155675866000024],
              [-80.84346323799997, 35.155569866000064],
              [-80.843509238999957, 35.15529086600003],
              [-80.843534236999972, 35.155140866000067],
              [-80.843616237999981, 35.154618866000078],
              [-80.843619237999974, 35.154552866000074],
              [-80.84361023799994, 35.154432866000036],
              [-80.843581237999956, 35.154272865000053],
              [-80.843542237999941, 35.154146866000076],
              [-80.843503237999983, 35.154050866000034],
              [-80.843131237999955, 35.153414866000048],
              [-80.843038237999963, 35.153260866000039],
              [-80.843013237999969, 35.153211866000049],
              [-80.842979237999941, 35.153111866000074],
              [-80.842966237999974, 35.153030866000051],
              [-80.842969237999966, 35.152925865000043],
              [-80.842984237999985, 35.152848865000067],
              [-80.84309223799994, 35.152468866000049],
              [-80.843138237999938, 35.152290866000044],
              [-80.843249237999942, 35.151899865000075],
              [-80.84193223799997, 35.151670866000075],
              [-80.841672237999944, 35.151599865000037],
              [-80.841741236999951, 35.151455866000049],
              [-80.842549237999947, 35.149775865000038],
              [-80.842994236999971, 35.148872864000055],
              [-80.843244237999954, 35.148365864000027],
              [-80.84347923699994, 35.147919865000063],
              [-80.843780237999965, 35.147348864000037],
              [-80.844061236999949, 35.146810864000031],
              [-80.844121236999968, 35.146702864000076],
              [-80.844160236999983, 35.146633865000069],
              [-80.844380236999939, 35.146248864000029],
              [-80.84482223699996, 35.145417863000034],
              [-80.845092238999939, 35.144905864000066],
              [-80.843801237999969, 35.144828864000033],
              [-80.843027235999955, 35.144777864000048],
              [-80.842847236999944, 35.144748864000064],
              [-80.842805236999936, 35.144740864000028],
              [-80.842749237999953, 35.144715863000044],
              [-80.84268723699995, 35.144662864000054],
              [-80.842658236999966, 35.144629864000024],
              [-80.842594236999958, 35.144529863000059],
              [-80.842568236999966, 35.144476864000069],
              [-80.842507237999939, 35.144293864000076],
              [-80.842248235999989, 35.14344886300006],
              [-80.840838235999968, 35.143738863000067],
              [-80.841330236999966, 35.145365864000041],
              [-80.841337236999948, 35.145411864000039],
              [-80.841338236999945, 35.145456864000039],
              [-80.841330236999966, 35.145518863000063],
              [-80.841300236999984, 35.145609863000061],
              [-80.841249236999943, 35.145694864000063],
              [-80.841210236999984, 35.145738864000066],
              [-80.841120236999984, 35.145809864000057],
              [-80.840693236999982, 35.146063864000041],
              [-80.840566236999962, 35.146134864000032],
              [-80.839657235999937, 35.146661865000056],
              [-80.839548236999974, 35.146708864000061],
              [-80.839430235999941, 35.146740864000037],
              [-80.839308235999965, 35.146756865000043],
              [-80.839165236999975, 35.146759864000046],
              [-80.838989235999975, 35.146762864000038],
              [-80.838533235999989, 35.146756865000043],
              [-80.837162235999983, 35.146730865000052],
              [-80.836972235999951, 35.146734865000042],
              [-80.83686423599994, 35.146748864000074],
              [-80.836551235999934, 35.146755865000046],
              [-80.836584235999965, 35.145777864000024],
              [-80.836612235999951, 35.144952864000061],
              [-80.836178235999967, 35.144948863000025],
              [-80.835994235999976, 35.144956864000051],
              [-80.835903234999989, 35.144967863000034],
              [-80.83580223499996, 35.144985863000045],
              [-80.835074234999979, 35.145146864000026],
              [-80.833995234999975, 35.145387864000043],
              [-80.833275234999974, 35.14554886500008],
              [-80.833161234999977, 35.145556864000071],
              [-80.833078234999959, 35.145552864000024],
              [-80.832983234999972, 35.145539864000057],
              [-80.832449233999967, 35.145439864000025],
              [-80.832398234999971, 35.145418864000078],
              [-80.832187233999946, 35.145855864000055],
              [-80.831902233999983, 35.146421864000047],
              [-80.831607233999989, 35.147007865000035],
              [-80.831342234999966, 35.147532864000027],
              [-80.83104423399999, 35.148122865000062],
              [-80.83098423399997, 35.148242865000043],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.830435234999982, 35.155301866000059],
              [-80.830356232999975, 35.155412866000063],
              [-80.830202234999945, 35.155652866000025],
              [-80.830141233999939, 35.155760867000026],
              [-80.830050234999987, 35.155938867000032],
              [-80.830027233999942, 35.15598486600004],
              [-80.829903232999982, 35.156206866000048],
              [-80.82984823399994, 35.156292867000047],
              [-80.829788232999988, 35.156376866000073],
              [-80.829625233999934, 35.156577867000067],
              [-80.829515232999938, 35.156692867000061],
              [-80.82936323399997, 35.156822867000074],
              [-80.829199233999987, 35.156945867000047],
              [-80.82904423399998, 35.157041867000032],
              [-80.828915233999965, 35.157104867000044],
              [-80.828738233999957, 35.157182867000074],
              [-80.828605233999951, 35.15723386600007],
              [-80.82836223299995, 35.157301867000058],
              [-80.828249233999941, 35.157336867000026],
              [-80.827719232999982, 35.157500868000056],
              [-80.827462233999938, 35.157580867000036],
              [-80.827571232999958, 35.157854867000026],
              [-80.827650232999986, 35.15809686700004],
              [-80.827696232999983, 35.158286867000072],
              [-80.827710233999937, 35.158345867000037],
              [-80.827743233999968, 35.158551867000028],
              [-80.827770233999956, 35.158957867000026],
              [-80.827752233999945, 35.15989386800004],
              [-80.828045233999944, 35.159892867000053],
              [-80.828225234999934, 35.159899868000025],
              [-80.828348233999975, 35.159898867000038],
              [-80.828472233999946, 35.159903868000072],
              [-80.82857923399996, 35.159913868000046],
              [-80.828685233999977, 35.159928868000065],
              [-80.828893232999974, 35.159968867000032],
              [-80.829092233999972, 35.160034868000025],
              [-80.82932623399995, 35.160134867000068],
              [-80.829576233999944, 35.160249867000061],
              [-80.830326233999983, 35.160600868000074],
              [-80.83051923499994, 35.160698868000054],
              [-80.830666233999978, 35.160763867000071],
              [-80.830719234999947, 35.160781867000026],
              [-80.830844234999972, 35.160811868000053],
              [-80.830975233999936, 35.160824868000077],
              [-80.831042234999984, 35.160824867000031],
              [-80.831175233999943, 35.16081286800005],
              [-80.831240234999939, 35.160798868000029],
              [-80.831439234999948, 35.160725867000053],
              [-80.831705234999959, 35.160636867000051],
              [-80.831816233999973, 35.160605868000061],
              [-80.831933234999951, 35.160594867000043],
              [-80.832054234999987, 35.160597868000025],
              [-80.832113234999952, 35.16060686700007],
              [-80.832227234999948, 35.160634867000056],
              [-80.83228223499998, 35.16065586700006],
              [-80.832561234999957, 35.160781867000026],
              [-80.832764234999956, 35.160858868000048],
              [-80.832956234999983, 35.160938867000027],
              [-80.833034235999946, 35.160981867000032],
              [-80.833204234999982, 35.161085868000043],
              [-80.833510235999938, 35.16132286800007],
              [-80.833662234999963, 35.161419868000053],
              [-80.833830235999983, 35.161511868000048],
              [-80.834055235999983, 35.161621867000065],
              [-80.834200234999969, 35.161676868000029],
              [-80.834355235999965, 35.161727868000071],
              [-80.834599235999974, 35.161794868000072],
              [-80.835003235999977, 35.161881867000034],
              [-80.835195235999947, 35.161939867000058],
              [-80.835602235999943, 35.162090868000064],
              [-80.835212235999961, 35.162813868000057],
              [-80.835070236999968, 35.163141868000025],
              [-80.834949236999989, 35.163474868000037],
              [-80.83491223599998, 35.163608869000029],
              [-80.834895234999976, 35.163699868000037],
              [-80.834884235999937, 35.16379286800003],
              [-80.834879235999949, 35.163924868000038],
              [-80.834886235999988, 35.164255868000055],
              [-80.835201235999989, 35.164246868000077],
              [-80.835420236999937, 35.164250868000067]
            ]
          ],
          [
            [
              [-80.848621239999943, 35.160053867000045],
              [-80.848429238999984, 35.160086867000075],
              [-80.848378238999942, 35.160136867000062],
              [-80.848328239999944, 35.160222867000073],
              [-80.848265238999943, 35.160381867000069],
              [-80.84821723899995, 35.160547867000048],
              [-80.848125238999955, 35.160935867000035],
              [-80.848042238999938, 35.161174867000057],
              [-80.847805238999968, 35.162039867000033],
              [-80.847735239999963, 35.162192867000044],
              [-80.847662239999977, 35.162273867000067],
              [-80.847590239999988, 35.162335867000024],
              [-80.847472239999945, 35.162391867000053],
              [-80.847333239999955, 35.162467868000078],
              [-80.847224238999956, 35.162566867000066],
              [-80.847197239999957, 35.162688868000032],
              [-80.847276239999985, 35.162894868000024],
              [-80.847338238999953, 35.163021868000044],
              [-80.847417238999981, 35.163208867000037],
              [-80.847508239999968, 35.163490868000054],
              [-80.847533239999962, 35.163626868000051],
              [-80.847550239999975, 35.163715867000064],
              [-80.84757923899997, 35.163995868000029],
              [-80.847568239999987, 35.164178867000032],
              [-80.84753423899997, 35.16429486800007],
              [-80.847483239999974, 35.164350867000053],
              [-80.847374240999955, 35.164424868000026],
              [-80.847271239999941, 35.164481868000053],
              [-80.84714723999997, 35.164556868000034],
              [-80.846916238999938, 35.164672868000025],
              [-80.846805238999934, 35.164716868000028],
              [-80.846599238999943, 35.164768868000067],
              [-80.846483239999941, 35.164873869000076],
              [-80.846403238999983, 35.164972869000053],
              [-80.846237238999947, 35.165209867000044],
              [-80.846110238999984, 35.165433868000036],
              [-80.846002239999962, 35.16560086800007],
              [-80.845842239999968, 35.165724868000041],
              [-80.845646238999961, 35.165910868000026],
              [-80.845548238999982, 35.166031868000061],
              [-80.845303238999975, 35.16633286900003],
              [-80.844993238999962, 35.166751868000063],
              [-80.844855239999958, 35.166869868000049],
              [-80.844814238999959, 35.166901869000071],
              [-80.84473823999997, 35.166962869000031],
              [-80.844526238999947, 35.167093869000041],
              [-80.844499238999958, 35.167197868000073],
              [-80.844523238999955, 35.167270869000049],
              [-80.844571238999947, 35.167323869000029],
              [-80.844687238999938, 35.167453868000052],
              [-80.844807238999977, 35.167543868000052],
              [-80.844935239999984, 35.167614868000044],
              [-80.845010238999976, 35.167643869000074],
              [-80.845129238999959, 35.167678868000053],
              [-80.845234238999979, 35.167701869000041],
              [-80.84547323999999, 35.167770868000048],
              [-80.845704238999986, 35.167821868000033],
              [-80.846247239999968, 35.167886868000039],
              [-80.846418238999945, 35.167920868000067],
              [-80.846583239999973, 35.167979868000032],
              [-80.846710239999936, 35.168044869000028],
              [-80.84680923999997, 35.168127868000056],
              [-80.846832239999969, 35.168182869000077],
              [-80.846850239999981, 35.168322868000075],
              [-80.846921238999983, 35.168515869000032],
              [-80.846998239999948, 35.168810869000026],
              [-80.847046239999941, 35.168955869000058],
              [-80.847071238999945, 35.169083869000076],
              [-80.847036239999966, 35.169162869000047],
              [-80.847014239999965, 35.169187868000051],
              [-80.846705239999949, 35.169308869000076],
              [-80.846559238999987, 35.169401869000069],
              [-80.846480239999948, 35.169506869000031],
              [-80.846335239999974, 35.16964887000006],
              [-80.846270239999967, 35.169753869000033],
              [-80.846191239999939, 35.169845869000028],
              [-80.846141238999962, 35.169931868000049],
              [-80.846031238999956, 35.170143868000025],
              [-80.845909239999969, 35.170322869000074],
              [-80.84580823999994, 35.170482869000068],
              [-80.845607238999946, 35.17074186900004],
              [-80.845527238999978, 35.170833869000035],
              [-80.845462238999971, 35.170895869000049],
              [-80.845419238999966, 35.170975869000074],
              [-80.845377238999959, 35.171085869000024],
              [-80.845375238999964, 35.171120869000049],
              [-80.845366239999976, 35.171262869000032],
              [-80.845402239999942, 35.171350870000026],
              [-80.845493239999939, 35.171470869000075],
              [-80.845615239999972, 35.171590869000056],
              [-80.845713239999952, 35.17166886900003],
              [-80.845848239999953, 35.171757870000079],
              [-80.846308239999985, 35.172079869000072],
              [-80.846414239999945, 35.172169870000062],
              [-80.846527239999944, 35.172234869000079],
              [-80.846581238999988, 35.172306870000057],
              [-80.846597238999948, 35.172361870000032],
              [-80.846598238999945, 35.172422869000059],
              [-80.846587239999963, 35.172574870000062],
              [-80.846527238999954, 35.172756869000068],
              [-80.846521238999969, 35.172778870000059],
              [-80.846515240999963, 35.172865870000066],
              [-80.846525239999949, 35.17298087000006],
              [-80.846529239999938, 35.173151870000027],
              [-80.846519239999964, 35.173358871000062],
              [-80.846520239999961, 35.173422870000024],
              [-80.846527238999954, 35.173693870000079],
              [-80.846524239999951, 35.173891870000034],
              [-80.846525239999949, 35.173914870000033],
              [-80.846540239999968, 35.17405787000007],
              [-80.846560239999974, 35.174218870000061],
              [-80.846569239999951, 35.174387870000032],
              [-80.846572239999944, 35.174553870000068],
              [-80.84658423999997, 35.174743871000032],
              [-80.846580238999934, 35.175028870000062],
              [-80.846578238999939, 35.175133870000025],
              [-80.84658823999996, 35.175236870000049],
              [-80.846627240999965, 35.175519870000073],
              [-80.846647239999982, 35.175726870000062],
              [-80.846650240999963, 35.175872871000024],
              [-80.846634239999958, 35.176122870000029],
              [-80.846540239999968, 35.17658687100004],
              [-80.846519239999964, 35.17679787000003],
              [-80.846515240999963, 35.17696187100006],
              [-80.84649023999998, 35.177163870000072],
              [-80.846455239999955, 35.177553871000043],
              [-80.846402239999975, 35.177758871000037],
              [-80.846393240999987, 35.177791871000068],
              [-80.846257240999989, 35.178479870000047],
              [-80.84622423999997, 35.17862787100006],
              [-80.846196240999973, 35.178967871000054],
              [-80.846590239999955, 35.179050871000072],
              [-80.846894239999983, 35.179122871000061],
              [-80.848875241999963, 35.179629871000031],
              [-80.849413240999979, 35.179767871000024],
              [-80.849676241999987, 35.179830871000036],
              [-80.849830241999939, 35.179873871000041],
              [-80.850018240999987, 35.179909871000064],
              [-80.850435241999946, 35.179954870000074],
              [-80.851363241999934, 35.180022871000062],
              [-80.851201241999945, 35.178971871000044],
              [-80.851162241999987, 35.178714871000068],
              [-80.851130241999954, 35.178544871000042],
              [-80.851088240999957, 35.178278870000042],
              [-80.851057241999968, 35.177973870000073],
              [-80.851035241999966, 35.177737871000033],
              [-80.851018241999952, 35.177363871000068],
              [-80.851016240999968, 35.177188870000066],
              [-80.85102324099995, 35.176714870000069],
              [-80.851034241999969, 35.176368870000033],
              [-80.851045240999952, 35.176164871000026],
              [-80.851030240999989, 35.175930871000048],
              [-80.851042241999949, 35.175758870000038],
              [-80.851092241999936, 35.175047870000071],
              [-80.851134241999944, 35.174351870000066],
              [-80.851177241999949, 35.173800870000036],
              [-80.85121824099997, 35.173354870000026],
              [-80.851233241999978, 35.173038870000028],
              [-80.85123224199998, 35.172761870000045],
              [-80.851213241999972, 35.172469869000054],
              [-80.85116224099994, 35.172079870000061],
              [-80.851042241999949, 35.171274868000069],
              [-80.850945240999977, 35.170531870000048],
              [-80.85090723999997, 35.170224869000037],
              [-80.850734239999952, 35.168817868000076],
              [-80.85065124099998, 35.168206869000073],
              [-80.850499240999966, 35.167339868000056],
              [-80.850442240999939, 35.166706869000052],
              [-80.850399239999945, 35.165665868000076],
              [-80.850267240999983, 35.165027868000038],
              [-80.850175239999942, 35.164590867000072],
              [-80.850123240999949, 35.164422868000031],
              [-80.850053240999955, 35.164259867000055],
              [-80.849966240999947, 35.164102867000054],
              [-80.849862239999936, 35.163952868000024],
              [-80.849679239999944, 35.163732868000068],
              [-80.849584239999956, 35.163596867000024],
              [-80.849501240999984, 35.163455867000039],
              [-80.849430239999947, 35.163310868000053],
              [-80.849360240999943, 35.163123867000024],
              [-80.849317240999937, 35.162971868000056],
              [-80.849245240999949, 35.162686868000037],
              [-80.849155239999959, 35.162336868000068],
              [-80.849053239999989, 35.16193486700007],
              [-80.848916240999984, 35.161179867000044],
              [-80.848833239999976, 35.160465867000028],
              [-80.848785239999984, 35.160046867000062],
              [-80.848621239999943, 35.160053867000045]
            ]
          ],
          [
            [
              [-80.87909824999997, 35.17587486900004],
              [-80.879178249999939, 35.176164870000036],
              [-80.879403248999949, 35.177497869000035],
              [-80.879433249999977, 35.177954869000075],
              [-80.879583249999939, 35.177997869000023],
              [-80.879658249999977, 35.178013870000029],
              [-80.880131249999977, 35.178139869000063],
              [-80.880662249999943, 35.178292870000064],
              [-80.880826250999974, 35.178339870000059],
              [-80.881113250999988, 35.178405869000073],
              [-80.883076251999967, 35.178925870000057],
              [-80.883477250999988, 35.179038869000067],
              [-80.883782251999946, 35.179119870000079],
              [-80.884355251999978, 35.179287870000053],
              [-80.884808251999971, 35.179418870000063],
              [-80.88501925099996, 35.17947987000008],
              [-80.886461251999947, 35.179905869000038],
              [-80.886632252999959, 35.179956870000069],
              [-80.886863252999945, 35.180024870000068],
              [-80.886711251999941, 35.179521870000031],
              [-80.886573252999938, 35.179121870000074],
              [-80.886473251999973, 35.178814869000064],
              [-80.886372252999934, 35.178448870000068],
              [-80.886311251999985, 35.178165869000054],
              [-80.88625725199995, 35.177781869000057],
              [-80.886229251999964, 35.177461869000069],
              [-80.886220251999987, 35.177023869000038],
              [-80.886235251999949, 35.176663869000038],
              [-80.886265250999941, 35.176341870000044],
              [-80.886291251999978, 35.176150868000036],
              [-80.886355251999987, 35.175787869000033],
              [-80.886396252999987, 35.175602868000055],
              [-80.886493252999969, 35.175234868000075],
              [-80.886612251999964, 35.174822869000025],
              [-80.886487251999938, 35.174635868000053],
              [-80.886305251999943, 35.174362869000049],
              [-80.885668251999959, 35.173406868000029],
              [-80.885296251999989, 35.173723869000071],
              [-80.885047251999936, 35.175446868000051],
              [-80.884485251999934, 35.175479869000071],
              [-80.882464250999988, 35.175618869000061],
              [-80.882239250999987, 35.175635869000075],
              [-80.879283249999958, 35.175851869000041],
              [-80.879165248999982, 35.175357869000038],
              [-80.878895248999982, 35.174230869000041],
              [-80.878608249999957, 35.173082869000041],
              [-80.878463248999935, 35.172462869000071],
              [-80.878434249999941, 35.172290868000061],
              [-80.878423248999979, 35.17221986800007],
              [-80.878247248999969, 35.171485868000048],
              [-80.878211247999957, 35.171375868000041],
              [-80.878139248999958, 35.171105868000041],
              [-80.878080248999936, 35.170795868000027],
              [-80.878065249999963, 35.170673867000062],
              [-80.878044249999959, 35.170259868000073],
              [-80.878309248999983, 35.170221868000056],
              [-80.880204249999963, 35.169954868000048],
              [-80.880244248999986, 35.169941868000024],
              [-80.880307249999987, 35.169906868000055],
              [-80.880339248999974, 35.169882868000059],
              [-80.880394248999949, 35.169823868000037],
              [-80.88054724999995, 35.169597868000039],
              [-80.88067424999997, 35.169390867000061],
              [-80.880702250999946, 35.169337868000071],
              [-80.880743249999966, 35.169227868000064],
              [-80.880918249999979, 35.168487867000067],
              [-80.880935249999936, 35.168443868000054],
              [-80.880958248999946, 35.168400868000049],
              [-80.880986249999978, 35.168360867000047],
              [-80.881057249999969, 35.168286868000052],
              [-80.881099249999977, 35.168253867000033],
              [-80.881179248999956, 35.168206868000027],
              [-80.881787249999945, 35.167918868000072],
              [-80.882064249999985, 35.16779286700006],
              [-80.882143249999956, 35.167762868000068],
              [-80.881197249999957, 35.166249867000033],
              [-80.880654249999964, 35.165551867000033],
              [-80.880497248999973, 35.165349867000032],
              [-80.878297247999967, 35.161949867000033],
              [-80.878251248999959, 35.161843866000027],
              [-80.877297247999934, 35.159649866000052],
              [-80.877109248999943, 35.158869865000042],
              [-80.876994248999949, 35.158315866000066],
              [-80.876897247999977, 35.15784986500006],
              [-80.876927247999959, 35.158309865000035],
              [-80.876965247999976, 35.158885866000048],
              [-80.877197247999959, 35.162949866000076],
              [-80.877455248999979, 35.165107867000074],
              [-80.877471247999949, 35.165368867000041],
              [-80.877585247999946, 35.167158867000069],
              [-80.877639248999969, 35.168020867000052],
              [-80.877723248999985, 35.16907586800005],
              [-80.877762248999943, 35.169558867000035],
              [-80.877857248999987, 35.17027986800008],
              [-80.877906248999977, 35.170687869000062],
              [-80.878021248999971, 35.171408868000071],
              [-80.878213248999941, 35.17231786900004],
              [-80.878624249999973, 35.174153869000065],
              [-80.879059249999955, 35.175718869000036],
              [-80.879081249999956, 35.175812870000073],
              [-80.87909824999997, 35.17587486900004]
            ]
          ],
          [
            [
              [-80.873304248999943, 35.194455873000038],
              [-80.87447224999994, 35.194872873000065],
              [-80.874775249999971, 35.194973873000038],
              [-80.875134249999974, 35.195098874000053],
              [-80.87548524999994, 35.195221873000037],
              [-80.876259248999986, 35.195511874000033],
              [-80.876885249999987, 35.195736873000044],
              [-80.877218249999942, 35.195867873000054],
              [-80.877931249999961, 35.194949872000052],
              [-80.878011249999986, 35.194845874000066],
              [-80.878146250999976, 35.194667873000071],
              [-80.879313250999985, 35.193183873000066],
              [-80.879918250999935, 35.19241687300007],
              [-80.880481250999935, 35.191700872000069],
              [-80.88131225099994, 35.190641872000072],
              [-80.881465250999952, 35.19044787200005],
              [-80.882041250999976, 35.189716872000076],
              [-80.882472251999957, 35.189159872000062],
              [-80.882548250999946, 35.189054872000042],
              [-80.883367251999971, 35.188013872000056],
              [-80.884355251999978, 35.186758871000052],
              [-80.884705251999947, 35.185164870000051],
              [-80.88444825299996, 35.18531487100006],
              [-80.884115252999948, 35.185492871000065],
              [-80.884014251999986, 35.185422871000071],
              [-80.883953251999969, 35.185385871000051],
              [-80.883872251999946, 35.185344871000041],
              [-80.882312250999973, 35.184635871000069],
              [-80.882227250999961, 35.184588871000074],
              [-80.882101250999938, 35.184528871000055],
              [-80.882050250999953, 35.184506870000064],
              [-80.881759250999949, 35.184381871000028],
              [-80.881189250999967, 35.184120871000061],
              [-80.880523250999943, 35.183824870000024],
              [-80.880010250999987, 35.183601870000075],
              [-80.879423250999935, 35.183325870000033],
              [-80.879128249999951, 35.183757871000068],
              [-80.878565249999951, 35.184541871000079],
              [-80.878478249999944, 35.184782871000039],
              [-80.878439250999975, 35.184872871000039],
              [-80.878161250999938, 35.18549887100005],
              [-80.878042249999964, 35.185752871000034],
              [-80.877749249999965, 35.186317872000075],
              [-80.87737924999999, 35.186985872000037],
              [-80.877290249999987, 35.187148871000034],
              [-80.877230249999968, 35.187237872000026],
              [-80.876935249999974, 35.187762872000064],
              [-80.876891249999971, 35.187840872000038],
              [-80.876851249999959, 35.187893871000028],
              [-80.876757249999969, 35.187990872000057],
              [-80.876657249999937, 35.18807187200008],
              [-80.876606249999952, 35.188103872000056],
              [-80.876542248999954, 35.18813687200003],
              [-80.876388249999934, 35.18845187200003],
              [-80.875493249999977, 35.189857872000061],
              [-80.873097248999954, 35.194349873000078],
              [-80.872923248999939, 35.194740873000057],
              [-80.872901249999984, 35.19477687300008],
              [-80.872884248999981, 35.194826874000057],
              [-80.872918248999952, 35.194781874000057],
              [-80.872955248999972, 35.194726874000025],
              [-80.873074248999956, 35.194511873000067],
              [-80.873119248999956, 35.194474873000047],
              [-80.87317724899998, 35.194451873000048],
              [-80.873241247999943, 35.194444873000066],
              [-80.873304248999943, 35.194455873000038]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 40,
        "SolutionID": "PI-16",
        "Shape_Length": 0.36209032382917383,
        "Shape_Area": 0.00065399302543622546
      }
    },
    {
      "type": "Feature",
      "id": 41,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.98128427599994, 35.099731849000079],
              [-80.981229275999965, 35.099747849000039],
              [-80.980348275999972, 35.099871849000067],
              [-80.980235275999974, 35.099881849000042],
              [-80.980057275999968, 35.099919849000059],
              [-80.979861275999951, 35.099943849000056],
              [-80.979615274999958, 35.100004849000072],
              [-80.979719274999979, 35.100013850000039],
              [-80.979811275999964, 35.100026849000074],
              [-80.979883275999953, 35.100043849000031],
              [-80.979944275999969, 35.100064850000024],
              [-80.980004276999978, 35.100091850000069],
              [-80.980242274999966, 35.100276849000068],
              [-80.980272275999937, 35.100313849000031],
              [-80.980302274999985, 35.100360849000026],
              [-80.98039827499997, 35.100484849000054],
              [-80.980445274999965, 35.100534849000042],
              [-80.980506275999971, 35.100637849000066],
              [-80.980670275999955, 35.100846850000039],
              [-80.980767274999948, 35.100970849000078],
              [-80.980970275999937, 35.10120684900005],
              [-80.981194276999986, 35.101567850000038],
              [-80.981237275999945, 35.101644849000024],
              [-80.981450275999975, 35.102099850000059],
              [-80.981690276999984, 35.102573850000056],
              [-80.98188427599996, 35.102985849000049],
              [-80.981987276999973, 35.103185850000045],
              [-80.982041275999961, 35.103301850000037],
              [-80.98216227599994, 35.103493850000064],
              [-80.982339276999937, 35.103848850000077],
              [-80.982440276999966, 35.104011851000053],
              [-80.982500276999986, 35.104129850000049],
              [-80.982564275999948, 35.104240850000053],
              [-80.982731276999971, 35.104445850000047],
              [-80.982879275999949, 35.10457185100006],
              [-80.983021276999978, 35.104711851000047],
              [-80.983097276999956, 35.10476785000003],
              [-80.983196276999934, 35.104871850000052],
              [-80.983248276999973, 35.104894850000051],
              [-80.983285275999947, 35.10490285000003],
              [-80.983317277999959, 35.104917850000049],
              [-80.983346275999963, 35.104948850000028],
              [-80.983408276999967, 35.105030850000048],
              [-80.983505276999949, 35.105147850000037],
              [-80.983984276999934, 35.104920850000042],
              [-80.98551927799997, 35.104178850000039],
              [-80.988597278999976, 35.102694849000045],
              [-80.989889278999954, 35.102071849000026],
              [-80.990593278999938, 35.101729849000037],
              [-80.99134227899998, 35.101368849000039],
              [-80.992704278999952, 35.100710849000052],
              [-80.99364627999995, 35.100255849000064],
              [-80.993753279999964, 35.100204849000079],
              [-80.993901279999989, 35.100132849000033],
              [-80.995232280999971, 35.099490848000073],
              [-80.995877279999945, 35.099177848000068],
              [-80.995757280999953, 35.099076848000038],
              [-80.995633279999936, 35.099023848000058],
              [-80.995410280999977, 35.098935848000053],
              [-80.99512427999997, 35.098810848000028],
              [-80.994712279999987, 35.098648849000028],
              [-80.993709279999962, 35.098216849000039],
              [-80.993390279999971, 35.098100848000058],
              [-80.993081278999966, 35.097970848000045],
              [-80.992826278999985, 35.097856848000049],
              [-80.992619279999985, 35.097769849000031],
              [-80.992291279999961, 35.09764584800007],
              [-80.99213827899996, 35.097571849000076],
              [-80.992005278999954, 35.097516848000055],
              [-80.991844278999963, 35.097457848000033],
              [-80.991806278999945, 35.097443848000069],
              [-80.991740279999988, 35.097421848000067],
              [-80.991700278999986, 35.097407848000046],
              [-80.991488278999952, 35.097337848000052],
              [-80.991267278999942, 35.097259848000078],
              [-80.991058279999947, 35.097193848000074],
              [-80.990794277999953, 35.097117848000039],
              [-80.990565277999963, 35.09707884900007],
              [-80.990397278999978, 35.097058848000074],
              [-80.99028027899999, 35.09705384800003],
              [-80.990097277999951, 35.097032848000026],
              [-80.989853278999988, 35.097036848000073],
              [-80.98977827799996, 35.097029848000034],
              [-80.989674277999939, 35.097010849000071],
              [-80.989646278999942, 35.097006848000035],
              [-80.98977827799996, 35.096211848000053],
              [-80.989850277999949, 35.095984847000068],
              [-80.989861278999967, 35.095904847000043],
              [-80.989462277999962, 35.095862848000024],
              [-80.989366278999967, 35.095866848000071],
              [-80.989273277999985, 35.095886848000077],
              [-80.989184277999982, 35.095921848000046],
              [-80.989077277999968, 35.095984848000057],
              [-80.98893327899998, 35.096093849000056],
              [-80.988381277999963, 35.096900848000075],
              [-80.988029276999953, 35.096894848000034],
              [-80.987917277999941, 35.096889849000036],
              [-80.987839277999967, 35.096893848000036],
              [-80.987755276999962, 35.096911849000037],
              [-80.987671277999937, 35.096939848000034],
              [-80.987585277999983, 35.096974848000059],
              [-80.987366277999968, 35.097073848000036],
              [-80.98694927799994, 35.097251848000042],
              [-80.98613927699995, 35.097625849000053],
              [-80.985938276999946, 35.097722848000046],
              [-80.98565427799997, 35.097879848000048],
              [-80.985555277999936, 35.097947848000047],
              [-80.985468276999939, 35.098000849000073],
              [-80.985398276999945, 35.09801384900004],
              [-80.985378276999938, 35.098081849000039],
              [-80.985279276999961, 35.09818984900005],
              [-80.98502027699999, 35.098554848000049],
              [-80.984898277999946, 35.098706849000052],
              [-80.984834276999948, 35.098812849000069],
              [-80.984777276999978, 35.098922849000076],
              [-80.984691276999968, 35.09902284900005],
              [-80.984556276999967, 35.099142849000032],
              [-80.984402276999958, 35.09922184800007],
              [-80.984207276999939, 35.099299849000033],
              [-80.983901276999973, 35.099382849000051],
              [-80.983749276999959, 35.09941384900003],
              [-80.983502276999957, 35.099456849000035],
              [-80.98335127699994, 35.099474849000046],
              [-80.983128275999945, 35.09949184900006],
              [-80.982710276999967, 35.099552849000077],
              [-80.98237527699996, 35.099592849000032],
              [-80.981839276999949, 35.09967184900006],
              [-80.981688275999943, 35.099698849000049],
              [-80.981545276999952, 35.099715850000052],
              [-80.981473275999974, 35.099684849000027],
              [-80.981428275999974, 35.099680850000027],
              [-80.981377274999943, 35.099688849000074],
              [-80.981323275999955, 35.099710849000076],
              [-80.98128427599994, 35.099731849000079]
            ]
          ],
          [
            [
              [-80.931495262999988, 35.131622858000071],
              [-80.924902260999943, 35.133981858000027],
              [-80.925713261999988, 35.135429858000066],
              [-80.926146261999975, 35.13612985900005],
              [-80.926227260999951, 35.136259859000063],
              [-80.92677226099994, 35.137033859000042],
              [-80.927027261999967, 35.137360859000069],
              [-80.927295260999983, 35.137691859000029],
              [-80.927530261999948, 35.137962860000073],
              [-80.927753261999953, 35.138202859000046],
              [-80.92868426299998, 35.139148859000045],
              [-80.928969262999942, 35.139418859000045],
              [-80.929300262999959, 35.139707860000044],
              [-80.929602262999936, 35.139942860000076],
              [-80.929771262999964, 35.140074859000038],
              [-80.930476262999946, 35.140588860000037],
              [-80.931834263999974, 35.141499860000067],
              [-80.932066263999957, 35.141295860000071],
              [-80.932247262999965, 35.141158860000075],
              [-80.932496263999951, 35.14095585900003],
              [-80.933009262999974, 35.140556860000061],
              [-80.933474263999983, 35.140166860000079],
              [-80.934127264999972, 35.139631859000076],
              [-80.934218263999981, 35.139560860000074],
              [-80.935476264999977, 35.138576859000068],
              [-80.935974263999981, 35.138184859000035],
              [-80.936730264999937, 35.137589859000059],
              [-80.936847264999983, 35.137490859000025],
              [-80.937188264999975, 35.137220859000024],
              [-80.937226263999946, 35.137186858000064],
              [-80.937536264999949, 35.136910859000068],
              [-80.937680264999983, 35.136765858000047],
              [-80.937848264999957, 35.136576859000058],
              [-80.937941265999939, 35.136465858000065],
              [-80.937973265999972, 35.13642685800005],
              [-80.938446265999971, 35.135829858000079],
              [-80.938764264999975, 35.135389859000043],
              [-80.938940264999985, 35.135135859000059],
              [-80.939164264999988, 35.134789858000033],
              [-80.939264265999952, 35.134620858000062],
              [-80.939386264999939, 35.134417857000074],
              [-80.939603265999949, 35.134055858000067],
              [-80.940100264999955, 35.133186857000055],
              [-80.940481265999949, 35.13249485800003],
              [-80.94056726599996, 35.132340858000077],
              [-80.940614265999955, 35.132245857000044],
              [-80.940912264999952, 35.131643858000075],
              [-80.941068265999945, 35.131253858000036],
              [-80.941207265999935, 35.130926857000077],
              [-80.941269265999949, 35.130772857000068],
              [-80.94162526599996, 35.129897856000071],
              [-80.941744265999944, 35.12962785700006],
              [-80.94060826599997, 35.129416857000024],
              [-80.939998265999975, 35.12933485700006],
              [-80.939647265999952, 35.129274857000041],
              [-80.939293264999947, 35.129201857000055],
              [-80.938760265999974, 35.129092856000057],
              [-80.938458264999952, 35.128986857000029],
              [-80.938437264999948, 35.128976857000055],
              [-80.93824026499999, 35.129136857000049],
              [-80.938047264999966, 35.129265858000053],
              [-80.937934264999967, 35.129332857000065],
              [-80.937826264999956, 35.12937585700007],
              [-80.937749264999979, 35.129412857000034],
              [-80.936413263999953, 35.129883857000038],
              [-80.935803263999958, 35.130105856000057],
              [-80.935633264999979, 35.130168857000058],
              [-80.934945263999964, 35.13042985800007],
              [-80.934758263999981, 35.13049785700008],
              [-80.934245263999969, 35.130684857000062],
              [-80.934107262999987, 35.130735857000047],
              [-80.933495262999941, 35.130947857000024],
              [-80.933197263999944, 35.131054857000038],
              [-80.932688262999989, 35.131239857000025],
              [-80.932590263999941, 35.131271858000048],
              [-80.932512262999978, 35.131288857000072],
              [-80.932472263999955, 35.131293858000049],
              [-80.932444262999979, 35.131290857000067],
              [-80.931495262999988, 35.131622858000071]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 41,
        "SolutionID": "PI-16",
        "Shape_Length": 0.083822935731651893,
        "Shape_Area": 0.00017331774297955877
      }
    },
    {
      "type": "Feature",
      "id": 42,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.024136279999937, 34.927384811000024],
            [-81.024932279999973, 34.927890811000054],
            [-81.025501280999947, 34.92829781100005],
            [-81.025539279999975, 34.928337811000063],
            [-81.025093280999954, 34.928587811000057],
            [-81.025029279999956, 34.928619811000033],
            [-81.024642279999966, 34.92881181000007],
            [-81.024270279999939, 34.928972810000062],
            [-81.023771279999949, 34.929178812000032],
            [-81.023602278999988, 34.929252811000026],
            [-81.023612278999963, 34.929337811000039],
            [-81.023653278999973, 34.929448811000043],
            [-81.023726279999948, 34.929646812000044],
            [-81.023757279999984, 34.929710811000064],
            [-81.024001279999936, 34.930093811000063],
            [-81.024367279999979, 34.930668811000032],
            [-81.02484727999996, 34.931557812000051],
            [-81.026196280999955, 34.934051812000064],
            [-81.026811279999947, 34.935203813000044],
            [-81.02743228099996, 34.936360812000032],
            [-81.02777028099996, 34.936989813000025],
            [-81.028437280999981, 34.938245813000037],
            [-81.028586280999946, 34.938512813000045],
            [-81.029078281999944, 34.939436814000032],
            [-81.029416281999943, 34.940070813000034],
            [-81.030100281999978, 34.941320814000051],
            [-81.031107282999983, 34.943250813000077],
            [-81.032554282999968, 34.942715813000063],
            [-81.033798282999953, 34.942269813000053],
            [-81.034973283999989, 34.941847814000027],
            [-81.035892283999942, 34.941517813000075],
            [-81.036104283999975, 34.94144181300004],
            [-81.035261282999954, 34.940406813000038],
            [-81.033782282999937, 34.938471813000035],
            [-81.032356281999967, 34.936629812000035],
            [-81.032174282999961, 34.936395812000058],
            [-81.031547282999952, 34.935575812000025],
            [-81.030718281999953, 34.934493812000028],
            [-81.029021280999984, 34.932496811000078],
            [-81.028546279999944, 34.93191881100006],
            [-81.027373280999939, 34.930494811000074],
            [-81.027557280999986, 34.930377811000028],
            [-81.028851280999959, 34.929472811000039],
            [-81.029815281999959, 34.928844811000033],
            [-81.030823280999982, 34.928151810000031],
            [-81.028831280999952, 34.926827810000077],
            [-81.028541280999946, 34.92663581000005],
            [-81.02693728099996, 34.92558581000003],
            [-81.026309279999964, 34.925174811000034],
            [-81.02605927999997, 34.925010809000071],
            [-81.025394279999944, 34.924571810000032],
            [-81.024792279999986, 34.925188810000066],
            [-81.024597279999966, 34.925388810000072],
            [-81.024555279999959, 34.925450810000029],
            [-81.024470278999956, 34.925571811000054],
            [-81.024366279999981, 34.925765811000076],
            [-81.024287278999964, 34.925967810000031],
            [-81.02422127899996, 34.926217810000026],
            [-81.022337278999942, 34.925961810000047],
            [-81.022313278999945, 34.926004810000052],
            [-81.02222527899994, 34.926243810000074],
            [-81.022259278999968, 34.926411810000047],
            [-81.022325278999972, 34.926745811000046],
            [-81.022527278999974, 34.92672981100003],
            [-81.023930278999956, 34.927502811000068],
            [-81.02397727999994, 34.927302811000061],
            [-81.024136279999937, 34.927384811000024]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 42,
        "SolutionID": "PI-16",
        "Shape_Length": 0.056697500106665487,
        "Shape_Area": 8.1503700274978205e-5
      }
    },
    {
      "type": "Feature",
      "id": 43,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.848390249999966, 35.346195907000038],
              [-80.848159249999981, 35.347309907000067],
              [-80.847949249999942, 35.348165907000066],
              [-80.847929249999936, 35.348248907000027],
              [-80.847877250999943, 35.348437907000061],
              [-80.847779249999974, 35.348772908000058],
              [-80.847656249999943, 35.349198908000062],
              [-80.847441249999974, 35.349936908000075],
              [-80.847037249999971, 35.351197908000074],
              [-80.846737248999943, 35.352039907000062],
              [-80.846420249999937, 35.352860908000025],
              [-80.846202249999976, 35.353388908000056],
              [-80.845978249999973, 35.353908908000051],
              [-80.845800248999979, 35.354302908000079],
              [-80.845062248999966, 35.35594190900008],
              [-80.84378124899996, 35.358747909000044],
              [-80.843527249999966, 35.359337910000079],
              [-80.843446248999953, 35.359552909000058],
              [-80.843366249999974, 35.359770910000066],
              [-80.843182249999984, 35.360289909000073],
              [-80.843027248999988, 35.360826910000071],
              [-80.842946248999965, 35.361143909000077],
              [-80.842781248999984, 35.361898911000026],
              [-80.842690249999976, 35.362449910000066],
              [-80.842658247999964, 35.362696910000068],
              [-80.842633249999949, 35.362896910000075],
              [-80.842595249999988, 35.363328910000064],
              [-80.842582248999975, 35.363592910000079],
              [-80.843247248999944, 35.363508910000064],
              [-80.845008249999978, 35.363285911000048],
              [-80.849229249999951, 35.362753910000038],
              [-80.850188250999963, 35.362592910000046],
              [-80.850817251999956, 35.362449909000077],
              [-80.851128251999967, 35.362367911000035],
              [-80.851740251999956, 35.362183910000056],
              [-80.851837251999939, 35.362149910000028],
              [-80.85204225199999, 35.362081910000029],
              [-80.852340251999976, 35.361972909000031],
              [-80.852634251999973, 35.361856910000029],
              [-80.85292425199998, 35.361734910000052],
              [-80.853158251999957, 35.361623910000048],
              [-80.853314251999961, 35.361550909000073],
              [-80.853458251999939, 35.361483910000061],
              [-80.853598250999937, 35.361418909000065],
              [-80.853786252999953, 35.361317909000036],
              [-80.854139252999971, 35.361129910000045],
              [-80.854660251999974, 35.360815910000042],
              [-80.855160251999962, 35.360479909000048],
              [-80.855636252999943, 35.360121910000032],
              [-80.855865252999934, 35.35993490900006],
              [-80.856088252999939, 35.359742909000033],
              [-80.856304252999962, 35.359545909000076],
              [-80.856513252999946, 35.359344910000061],
              [-80.856764252999938, 35.359087909000039],
              [-80.857258252999941, 35.358572909000031],
              [-80.859667252999941, 35.356071908000047],
              [-80.860474254999986, 35.355230909000056],
              [-80.861398253999937, 35.354271908000044],
              [-80.863931254999954, 35.351639907000049],
              [-80.864436254999987, 35.35112190600006],
              [-80.864608254999951, 35.350945907000039],
              [-80.864791253999954, 35.350768907000031],
              [-80.865117254999973, 35.350454906000039],
              [-80.865509254999949, 35.350102907000064],
              [-80.865969254999982, 35.349717906000024],
              [-80.866274254999951, 35.349474907000058],
              [-80.866586254999959, 35.349236906000044],
              [-80.867227255999978, 35.348777906000066],
              [-80.86755625699999, 35.348555906000058],
              [-80.867856254999936, 35.348365907000073],
              [-80.868223255999965, 35.348134906000041],
              [-80.868391255999938, 35.348037905000069],
              [-80.868740255999967, 35.347840906000044],
              [-80.869094256999972, 35.347650906000069],
              [-80.869816256999968, 35.347288906000074],
              [-80.870183256999951, 35.347116906000053],
              [-80.870932257999982, 35.346791905000032],
              [-80.871312256999943, 35.346639906000064],
              [-80.87169625699994, 35.346493906000035],
              [-80.872083257999975, 35.346353906000047],
              [-80.872474257999954, 35.346220906000042],
              [-80.872869256999934, 35.346094905000029],
              [-80.872944256999972, 35.346071905000031],
              [-80.873266257999944, 35.345974905000048],
              [-80.874069256999974, 35.34575690500003],
              [-80.876076257999955, 35.34524990500006],
              [-80.876019258999975, 35.345217905000027],
              [-80.875943258999939, 35.345167906000029],
              [-80.875864258999968, 35.345129906000068],
              [-80.875750257999982, 35.345025905000057],
              [-80.875687257999971, 35.344985905000044],
              [-80.875622257999964, 35.344963906000032],
              [-80.875587257999939, 35.344944905000034],
              [-80.875529257999972, 35.344871906000037],
              [-80.875524257999984, 35.344767905000026],
              [-80.875464256999976, 35.34471990600008],
              [-80.875423256999966, 35.344658906000063],
              [-80.875308257999961, 35.344568905000074],
              [-80.875266257999954, 35.344523906000063],
              [-80.875219257999959, 35.344473905000029],
              [-80.875209257999984, 35.34442090500005],
              [-80.87505325799998, 35.344253905000073],
              [-80.875013256999978, 35.344196905000047],
              [-80.874999257999946, 35.34417690500004],
              [-80.874941256999989, 35.343930905000036],
              [-80.874909257999946, 35.343850905000068],
              [-80.87487325799998, 35.343790905000048],
              [-80.874815256999966, 35.343730905000029],
              [-80.874748257999954, 35.343679905000045],
              [-80.874684256999956, 35.343617905000031],
              [-80.874663256999952, 35.343544905000044],
              [-80.874619257999939, 35.34348290500003],
              [-80.874568256999964, 35.343425905000061],
              [-80.874513257999979, 35.343380904000071],
              [-80.874460257999942, 35.343310905000067],
              [-80.874426257999971, 35.343250905000048],
              [-80.874362257999962, 35.343200904000071],
              [-80.874302257999943, 35.343145905000029],
              [-80.874254256999961, 35.343042905000061],
              [-80.874214256999949, 35.34298390500004],
              [-80.874105257999986, 35.342870905000041],
              [-80.874044257999969, 35.342833905000077],
              [-80.87400325699997, 35.342813904000025],
              [-80.873904256999936, 35.342722905000073],
              [-80.873830257999941, 35.342671905000032],
              [-80.873765256999945, 35.342647905000035],
              [-80.873615256999983, 35.342565905000072],
              [-80.873546256999987, 35.342515905000027],
              [-80.873480256999983, 35.342439904000059],
              [-80.873373256999969, 35.342395905000046],
              [-80.873302256999978, 35.342352905000041],
              [-80.87312125599999, 35.342258905000051],
              [-80.873042255999962, 35.342206904000079],
              [-80.873025256999938, 35.342136905000075],
              [-80.872946255999977, 35.342116905000069],
              [-80.872855256999969, 35.342115905000071],
              [-80.872780256999988, 35.342124906000038],
              [-80.872658256999955, 35.342158905000076],
              [-80.872555255999941, 35.342139905000067],
              [-80.872447256999976, 35.342135905000077],
              [-80.872362256999963, 35.342124905000048],
              [-80.872265256999981, 35.342121905000056],
              [-80.872159256999964, 35.342112905000079],
              [-80.872060255999941, 35.342082905000041],
              [-80.871981256999959, 35.342043905000025],
              [-80.871842256999969, 35.342006905000062],
              [-80.871672256999943, 35.341942905000053],
              [-80.871579256999951, 35.341940905000058],
              [-80.871466256999952, 35.341946905000043],
              [-80.871404256999938, 35.341969905000042],
              [-80.871306255999968, 35.341994904000046],
              [-80.871229255999936, 35.341969906000031],
              [-80.87114025599999, 35.341918904000067],
              [-80.871089256999937, 35.34190690500003],
              [-80.871035255999971, 35.341932904000032],
              [-80.871018255999957, 35.341954904000033],
              [-80.871008256999971, 35.341978905000076],
              [-80.871007255999984, 35.342089904000034],
              [-80.871004255999935, 35.342128904000049],
              [-80.87099025699996, 35.342157905000079],
              [-80.870901255999968, 35.342230904000075],
              [-80.870752256999936, 35.342310905000033],
              [-80.870561255999974, 35.342391905000056],
              [-80.870284255999934, 35.342478904000075],
              [-80.870222255999977, 35.342508905000045],
              [-80.870180255999969, 35.342529905000049],
              [-80.870096254999964, 35.342599905000043],
              [-80.870048256999951, 35.342670906000023],
              [-80.870020255999975, 35.342766905000076],
              [-80.870004255999959, 35.342870905000041],
              [-80.869980255999963, 35.342920905000028],
              [-80.869934255999965, 35.342972905000067],
              [-80.869884255999978, 35.342997905000061],
              [-80.869795255999975, 35.343013905000078],
              [-80.869633255999986, 35.343019905000062],
              [-80.869533255999954, 35.343055905000028],
              [-80.869312255999944, 35.34316390500004],
              [-80.869262255999956, 35.343177906000051],
              [-80.869195255999955, 35.343206905000045],
              [-80.868991254999969, 35.343324905000031],
              [-80.868879255999957, 35.343345906000025],
              [-80.868802255999981, 35.343367905000036],
              [-80.868693254999982, 35.343412905000037],
              [-80.868482254999947, 35.343525905000035],
              [-80.868427255999961, 35.343563905000053],
              [-80.868366255999945, 35.343592905000037],
              [-80.868285255999979, 35.343613905000041],
              [-80.86814725499994, 35.343634905000044],
              [-80.868058254999937, 35.343668905000072],
              [-80.868035254999938, 35.343723906000037],
              [-80.868016254999986, 35.343745905000048],
              [-80.867997255999967, 35.343777906000071],
              [-80.867976255999963, 35.343826905000071],
              [-80.867947255999979, 35.343867905000025],
              [-80.867900254999938, 35.343897906000052],
              [-80.867791255999975, 35.343930905000036],
              [-80.867679254999985, 35.343977905000031],
              [-80.867460255999958, 35.344049905000077],
              [-80.86738425599998, 35.34413590500003],
              [-80.867295255999977, 35.344206906000068],
              [-80.867208255999969, 35.344249905000027],
              [-80.867145255999958, 35.34427090500003],
              [-80.867091255999981, 35.344303905000061],
              [-80.867064255999935, 35.344332905000044],
              [-80.867023255999982, 35.344349906000048],
              [-80.866867255999978, 35.344359906000079],
              [-80.866831254999965, 35.344366906000062],
              [-80.866766254999959, 35.344402905000038],
              [-80.86673925599996, 35.344405906000077],
              [-80.866661255999986, 35.344374905000052],
              [-80.866613254999947, 35.344377905000044],
              [-80.866544255999941, 35.344393905000061],
              [-80.866425255999957, 35.344379906000029],
              [-80.866328254999985, 35.344399906000035],
              [-80.86619925499997, 35.344437906000053],
              [-80.866057254999987, 35.344455906000064],
              [-80.865926254999977, 35.344465906000039],
              [-80.865780254999947, 35.344486905000053],
              [-80.865643254999952, 35.344541905000028],
              [-80.86550525499996, 35.344567905000076],
              [-80.865444253999954, 35.344585906000077],
              [-80.86536225499998, 35.344593906000057],
              [-80.865189253999972, 35.344615905000069],
              [-80.865083254999945, 35.344632905000026],
              [-80.865010254999959, 35.344654906000073],
              [-80.864953254999989, 35.344679906000067],
              [-80.864922254999954, 35.344697906000079],
              [-80.864792254999941, 35.344716906000031],
              [-80.864661254999987, 35.34472790600006],
              [-80.864562253999964, 35.344750906000058],
              [-80.864455254999939, 35.344807906000028],
              [-80.864371253999934, 35.344836906000069],
              [-80.86433825499995, 35.34489990600008],
              [-80.864261254999974, 35.344933906000051],
              [-80.864232253999944, 35.34494890600007],
              [-80.864131253999972, 35.345000905000063],
              [-80.864091253999959, 35.345022906000054],
              [-80.863818254999956, 35.345113906000051],
              [-80.863573254999949, 35.34523490600003],
              [-80.86350125499996, 35.345254906000036],
              [-80.863424254999984, 35.345259906000024],
              [-80.863376253999945, 35.345255906000034],
              [-80.863278254999955, 35.345296906000044],
              [-80.863202253999987, 35.345317906000048],
              [-80.863101253999957, 35.345361906000051],
              [-80.863076252999974, 35.345378906000064],
              [-80.863052254999957, 35.345393906000027],
              [-80.862985254999955, 35.345419906000075],
              [-80.862891254999965, 35.345445906000066],
              [-80.86273225399998, 35.345493906000058],
              [-80.862652253999954, 35.345520906000047],
              [-80.862589252999953, 35.345536906000063],
              [-80.862462254999969, 35.34556490600005],
              [-80.862298253999938, 35.345589906000043],
              [-80.862180253999952, 35.34562190500003],
              [-80.862106253999968, 35.34564690600007],
              [-80.861981253999943, 35.345694906000062],
              [-80.861898253999982, 35.34573290600008],
              [-80.86174525399997, 35.34579390600004],
              [-80.861717253999984, 35.345805906000066],
              [-80.861567253999965, 35.345853906000059],
              [-80.86144225299995, 35.345948906000046],
              [-80.861316253999973, 35.345996906000039],
              [-80.861182253999971, 35.346016906000045],
              [-80.861102253999945, 35.346025907000069],
              [-80.861003253999968, 35.346000906000029],
              [-80.860979253999972, 35.345979906000025],
              [-80.860884253999984, 35.345913906000078],
              [-80.860767253999938, 35.345847906000074],
              [-80.86069925399994, 35.34579390600004],
              [-80.860557252999968, 35.345697906000055],
              [-80.860373252999977, 35.345572906000029],
              [-80.860295253999936, 35.345520905000058],
              [-80.860162252999942, 35.345421907000059],
              [-80.860094252999943, 35.345374906000075],
              [-80.860045253999942, 35.345337906000054],
              [-80.860010252999984, 35.345313906000058],
              [-80.859939252999936, 35.345258906000026],
              [-80.859772253999949, 35.34513690600005],
              [-80.859689252999942, 35.345086906000063],
              [-80.85963725299996, 35.345058906000077],
              [-80.859461252999949, 35.344967906000079],
              [-80.859083251999948, 35.34478090600004],
              [-80.859014253999987, 35.344733906000044],
              [-80.858969252999941, 35.344701905000079],
              [-80.858871252999961, 35.344640906000052],
              [-80.858827252999959, 35.34462290700003],
              [-80.858675252999944, 35.344549905000065],
              [-80.858551251999984, 35.344497906000072],
              [-80.858490252999957, 35.34444290600004],
              [-80.858208252999987, 35.344294906000073],
              [-80.858118252999986, 35.344230906000064],
              [-80.858035251999979, 35.344189906000054],
              [-80.857950251999966, 35.344166906000055],
              [-80.857902252999963, 35.344158905000029],
              [-80.857850252999981, 35.344147905000057],
              [-80.85779125199997, 35.34412790600004],
              [-80.857742252999969, 35.344134906000079],
              [-80.857694251999988, 35.344120905000068],
              [-80.857617251999955, 35.344056907000038],
              [-80.857526252999946, 35.344004905000077],
              [-80.857328252999935, 35.343986906000055],
              [-80.857149251999942, 35.343946906000042],
              [-80.857051251999962, 35.343928906000031],
              [-80.856992251999941, 35.343908906000024],
              [-80.856923252999934, 35.343891906000067],
              [-80.856863251999982, 35.34387990700003],
              [-80.856812252999987, 35.343873905000066],
              [-80.856751251999981, 35.343856906000042],
              [-80.856699251999942, 35.343834906000041],
              [-80.856516252999938, 35.343791905000046],
              [-80.856365251999989, 35.343717906000052],
              [-80.856302251999978, 35.343714906000059],
              [-80.856277251999984, 35.343700906000038],
              [-80.856190252999966, 35.343674906000047],
              [-80.856041251999955, 35.34364690600006],
              [-80.855951251999954, 35.343624906000059],
              [-80.855898251999974, 35.343621906000067],
              [-80.855843250999953, 35.343610905000048],
              [-80.855794252999942, 35.343606906000048],
              [-80.855721252999956, 35.343597905000024],
              [-80.855625250999935, 35.343589906000034],
              [-80.85551725199997, 35.343578906000062],
              [-80.85547625099997, 35.343575906000069],
              [-80.855434251999952, 35.343579906000059],
              [-80.855320251999956, 35.343578905000072],
              [-80.855299251999952, 35.34356490600004],
              [-80.855281251999941, 35.343545906000031],
              [-80.855241251999985, 35.343535905000067],
              [-80.85519425199999, 35.343530906000069],
              [-80.855165251999949, 35.343520906000037],
              [-80.855027250999967, 35.343499906000034],
              [-80.854992251999988, 35.343487906000064],
              [-80.854908250999983, 35.343480905000035],
              [-80.854783251999947, 35.343448906000049],
              [-80.854733250999971, 35.343446906000054],
              [-80.854693251999947, 35.343434906000027],
              [-80.85464725199995, 35.343432906000032],
              [-80.85459825099997, 35.343439905000025],
              [-80.854546251999977, 35.343436906000079],
              [-80.854499251999982, 35.343427906000045],
              [-80.85440325199994, 35.343390906000025],
              [-80.854296251999983, 35.343358906000049],
              [-80.854239251999957, 35.343334907000042],
              [-80.854192250999972, 35.343324906000078],
              [-80.85414825099997, 35.34332390600008],
              [-80.854066250999949, 35.343299905000038],
              [-80.854022250999947, 35.343279906000078],
              [-80.853975250999952, 35.343265906000056],
              [-80.853920251999966, 35.343258905000027],
              [-80.85385525199996, 35.343265905000067],
              [-80.853795250999951, 35.343260905000079],
              [-80.853543250999962, 35.34318890600008],
              [-80.853445250999982, 35.343150905000073],
              [-80.853301250999948, 35.343121906000079],
              [-80.853205250999963, 35.343084906000058],
              [-80.853153250999981, 35.343052905000036],
              [-80.85306325099998, 35.343017906000057],
              [-80.853003251999951, 35.342970905000072],
              [-80.852959250999959, 35.342944906000071],
              [-80.852909250999971, 35.342923906000067],
              [-80.852849250999952, 35.342902906000063],
              [-80.852769250999984, 35.342867906000038],
              [-80.852701250999985, 35.342840906000049],
              [-80.852651250999941, 35.342818906000048],
              [-80.852623250999955, 35.342801905000044],
              [-80.852573250999967, 35.342761906000078],
              [-80.85247825099998, 35.342707905000054],
              [-80.85239224999998, 35.342643906000035],
              [-80.852332249999961, 35.342605905000028],
              [-80.852176249999957, 35.342532906000031],
              [-80.852128250999954, 35.342493906000072],
              [-80.852068250999935, 35.34246190500005],
              [-80.851877250999962, 35.342332906000024],
              [-80.851678249999964, 35.342167905000053],
              [-80.851593249999951, 35.342143906000047],
              [-80.851541249999968, 35.342121906000045],
              [-80.851495249999971, 35.342107905000034],
              [-80.85144625099997, 35.342049905000067],
              [-80.851392250999936, 35.342006905000062],
              [-80.851354250999975, 35.341989906000038],
              [-80.851237250999986, 35.341932905000078],
              [-80.851070250999953, 35.341832906000036],
              [-80.850754249999966, 35.341687906000061],
              [-80.850596249999967, 35.341647905000059],
              [-80.850426249999941, 35.341607905000046],
              [-80.85039625099995, 35.341590906000079],
              [-80.850304249999965, 35.34157990600005],
              [-80.850251249999985, 35.341564906000031],
              [-80.850136249999935, 35.341554906000056],
              [-80.850061249999953, 35.341536906000044],
              [-80.850022249999938, 35.341509905000066],
              [-80.849859249999952, 35.341476906000025],
              [-80.849737248999986, 35.341447905000052],
              [-80.849630249999962, 35.341426905000048],
              [-80.849529249999989, 35.341417905000071],
              [-80.849463249999985, 35.341393905000075],
              [-80.849323250999987, 35.341356906000044],
              [-80.848987249999936, 35.341150905000063],
              [-80.848983249999947, 35.341217905000065],
              [-80.848968249999984, 35.341562905000046],
              [-80.848878248999938, 35.342863906000048],
              [-80.84881224999998, 35.343577906000064],
              [-80.848761249999939, 35.344030906000057],
              [-80.848647249999942, 35.344846907000033],
              [-80.848617249999961, 35.345005907000029],
              [-80.848531249999951, 35.345423906000065],
              [-80.848390249999966, 35.346195907000038]
            ]
          ],
          [
            [
              [-80.853436254999963, 35.392829917000029],
              [-80.853150252999967, 35.393362916000058],
              [-80.85308125399996, 35.393481917000031],
              [-80.853231253999979, 35.393731916000036],
              [-80.853614253999979, 35.394330917000048],
              [-80.854202253999972, 35.395249917000058],
              [-80.854525253999952, 35.39576691700006],
              [-80.854873254999973, 35.39638291600005],
              [-80.855252254999982, 35.397105917000033],
              [-80.855562254999938, 35.397749917000056],
              [-80.855622254999957, 35.397889917000043],
              [-80.855803254999955, 35.39831491700005],
              [-80.855923254999936, 35.398612918000026],
              [-80.856048254999962, 35.398939917000064],
              [-80.856208255999945, 35.399384917000077],
              [-80.85639325599999, 35.399940917000038],
              [-80.856632255999955, 35.400816918000032],
              [-80.85669025499999, 35.401043918000028],
              [-80.856812255999955, 35.401604918000032],
              [-80.85687825499997, 35.401941918000034],
              [-80.857004255999982, 35.40271191800008],
              [-80.857083254999964, 35.403099919000056],
              [-80.857290255999942, 35.404263918000026],
              [-80.857346255999971, 35.404580919000068],
              [-80.857382255999937, 35.404980918000035],
              [-80.857522255999982, 35.405869919000054],
              [-80.857607256999984, 35.406357919000072],
              [-80.857740255999943, 35.407182919000036],
              [-80.857819255999971, 35.407643919000066],
              [-80.85795825699995, 35.408444919000033],
              [-80.858044254999982, 35.408945919000075],
              [-80.858560255999976, 35.408778920000032],
              [-80.858993255999962, 35.40861792000004],
              [-80.85912125699997, 35.408569919000058],
              [-80.860589256999958, 35.40806591900008],
              [-80.861389257999974, 35.407762919000049],
              [-80.862660257999948, 35.407268919000046],
              [-80.864276257999961, 35.406637920000037],
              [-80.865443257999971, 35.406179918000078],
              [-80.865694257999962, 35.406089919000067],
              [-80.867250258999945, 35.405501919000073],
              [-80.867728258999989, 35.405315918000042],
              [-80.867927259999988, 35.405242919000045],
              [-80.868115258999978, 35.405168919000062],
              [-80.868650259999981, 35.404971918000058],
              [-80.868991258999984, 35.404838919000042],
              [-80.869373259999975, 35.404706918000045],
              [-80.869343258999947, 35.404448918000071],
              [-80.869360258999961, 35.404152918000079],
              [-80.869384258999958, 35.403960918000053],
              [-80.869508258999986, 35.402689918000078],
              [-80.869524258999945, 35.402506918000029],
              [-80.869667258999982, 35.401284918000044],
              [-80.869673258999967, 35.40120091700004],
              [-80.869682259999934, 35.401077917000066],
              [-80.869670258999975, 35.400930917000039],
              [-80.86961225899995, 35.400716917000068],
              [-80.869453258999954, 35.400280918000078],
              [-80.869347259999984, 35.399965917000031],
              [-80.86933025899998, 35.399895917000038],
              [-80.869323258999941, 35.399831918000075],
              [-80.869327258999988, 35.399725917000069],
              [-80.869353259999968, 35.399605917000031],
              [-80.869378258999973, 35.39955291800004],
              [-80.86832925799996, 35.399005917000068],
              [-80.867964258999962, 35.398823917000072],
              [-80.867874257999972, 35.398780917000067],
              [-80.867493257999968, 35.398600918000056],
              [-80.867241257999979, 35.398481917000026],
              [-80.866540257999986, 35.398139917000037],
              [-80.86610025799996, 35.397940917000028],
              [-80.865858257999946, 35.397847917000036],
              [-80.865745257999947, 35.397809917000075],
              [-80.865585257999953, 35.397766917000069],
              [-80.865190257999984, 35.397686917000044],
              [-80.865097257999935, 35.397672916000033],
              [-80.864823257999944, 35.397651917000076],
              [-80.864328257999944, 35.39762491700003],
              [-80.864032256999963, 35.39761291700006],
              [-80.864086257999986, 35.396638916000029],
              [-80.864100256999961, 35.396377917000052],
              [-80.864126256999953, 35.396095917000025],
              [-80.864267256999938, 35.39544791600008],
              [-80.864631257999974, 35.394380916000046],
              [-80.864721257999975, 35.394060915000068],
              [-80.86473925699994, 35.39396191700007],
              [-80.864754256999959, 35.393817916000046],
              [-80.864758257999938, 35.393691915000034],
              [-80.864753256999961, 35.393584916000066],
              [-80.864740256999937, 35.393462916000033],
              [-80.864717256999938, 35.393336916000067],
              [-80.864683257999957, 35.393208916000049],
              [-80.864634257999967, 35.393070916000056],
              [-80.864583257999982, 35.392954915000075],
              [-80.864512256999944, 35.392821916000059],
              [-80.864437257999953, 35.392702915000029],
              [-80.864338256999986, 35.392571916000065],
              [-80.864249256999983, 35.392468916000041],
              [-80.864149256999951, 35.392366916000071],
              [-80.864040256999942, 35.392269916000032],
              [-80.863932257999977, 35.392184916000076],
              [-80.863799255999936, 35.392092916000024],
              [-80.863666255999988, 35.392012915000066],
              [-80.863520256999948, 35.391936916000077],
              [-80.86337625699997, 35.391871916000071],
              [-80.863222256999961, 35.391813916000046],
              [-80.863078256999984, 35.391768916000046],
              [-80.862935256999947, 35.391730916000029],
              [-80.862781256999938, 35.391698916000053],
              [-80.862625256999934, 35.391675916000054],
              [-80.862437255999964, 35.391657916000042],
              [-80.862290256999984, 35.391651915000068],
              [-80.862144256999954, 35.391653916000053],
              [-80.862006255999972, 35.391660916000035],
              [-80.861830255999962, 35.391679916000044],
              [-80.86170225699999, 35.391699917000039],
              [-80.861564255999951, 35.391728916000034],
              [-80.861418255999979, 35.391765916000054],
              [-80.861299255999938, 35.391802915000028],
              [-80.86115525699995, 35.391854916000057],
              [-80.861007256999983, 35.391918915000076],
              [-80.860898255999984, 35.391971916000045],
              [-80.860748255999965, 35.392055915000071],
              [-80.860618254999963, 35.392140916000074],
              [-80.860496255999976, 35.392230915000027],
              [-80.860388255999965, 35.392321917000061],
              [-80.860293255999977, 35.392412916000069],
              [-80.860268254999937, 35.39243891600006],
              [-80.860223256999973, 35.392486916000053],
              [-80.860114255999974, 35.392420916000049],
              [-80.858470254999986, 35.39142191600007],
              [-80.85843125599996, 35.391404916000056],
              [-80.858229254999969, 35.39132491600003],
              [-80.858019255999977, 35.391258915000037],
              [-80.857804254999962, 35.391208916000039],
              [-80.857584254999949, 35.391173916000071],
              [-80.857361254999944, 35.391154915000072],
              [-80.857137254999941, 35.391152916000067],
              [-80.856792254999959, 35.391169916000024],
              [-80.855460253999979, 35.391236915000036],
              [-80.855135254999936, 35.391226916000051],
              [-80.854840254999942, 35.391204916000049],
              [-80.854547253999954, 35.391169916000024],
              [-80.854160253999964, 35.391103916000077],
              [-80.854135253999971, 35.391206916000044],
              [-80.853990252999949, 35.391694916000063],
              [-80.853956253999968, 35.391781915000024],
              [-80.853744253999935, 35.392241916000046],
              [-80.853436254999963, 35.392829917000029]
            ]
          ],
          [
            [
              [-80.836849254999947, 35.497647939000046],
              [-80.837491253999985, 35.49804793900006],
              [-80.837936254999988, 35.498325940000029],
              [-80.83814225499998, 35.498453939000058],
              [-80.837683255999934, 35.49966493900007],
              [-80.83750125499995, 35.500142940000046],
              [-80.83564325399999, 35.504432941000061],
              [-80.83532525399994, 35.505180940000059],
              [-80.833636253999941, 35.509156942000061],
              [-80.83350825499997, 35.509446942000068],
              [-80.833928253999943, 35.509477942000046],
              [-80.834491253999943, 35.509518942000057],
              [-80.839295255999957, 35.509867941000039],
              [-80.839319255999953, 35.509838942000044],
              [-80.839439255999935, 35.509703942000044],
              [-80.839576255999987, 35.509563942000057],
              [-80.839723255999957, 35.509423942000069],
              [-80.839860256999941, 35.509305942000026],
              [-80.840079256999957, 35.509137942000052],
              [-80.840255255999978, 35.509016941000027],
              [-80.840465256999948, 35.508879942000078],
              [-80.840644256999951, 35.508772941000075],
              [-80.840934256999958, 35.508621942000048],
              [-80.841179255999975, 35.50849794100003],
              [-80.841463256999987, 35.508365941000079],
              [-80.841708256999937, 35.508238942000048],
              [-80.842241256999955, 35.507972940000059],
              [-80.842491256999949, 35.507841941000038],
              [-80.842755255999975, 35.507679941000049],
              [-80.843018256999983, 35.507488941000076],
              [-80.843177256999979, 35.507359941000061],
              [-80.843333256999983, 35.507226942000045],
              [-80.843696256999976, 35.506903941000076],
              [-80.844911256999978, 35.505796941000028],
              [-80.845122257999947, 35.505610940000054],
              [-80.845467256999939, 35.50528494100007],
              [-80.845592257999954, 35.505159941000045],
              [-80.845852258999969, 35.50488794000006],
              [-80.845943257999977, 35.504776940000056],
              [-80.846088257999952, 35.504572941000049],
              [-80.846217257999967, 35.504350940000052],
              [-80.846737257999962, 35.503288940000061],
              [-80.846781257999965, 35.503196939000077],
              [-80.847066257999984, 35.502543940000066],
              [-80.847347257999957, 35.501917940000055],
              [-80.847458256999971, 35.501660939000033],
              [-80.84811125899995, 35.500119939000058],
              [-80.848480258999984, 35.499261939000064],
              [-80.847584257999983, 35.499083939000059],
              [-80.846508256999982, 35.498867939000036],
              [-80.845364257999961, 35.498630939000066],
              [-80.845221257999981, 35.498589939000055],
              [-80.845046256999979, 35.498522939000054],
              [-80.844956257999968, 35.498479939000049],
              [-80.844834256999945, 35.498412940000037],
              [-80.844736257999955, 35.498343939000051],
              [-80.844364256999938, 35.49806393800003],
              [-80.844129256999963, 35.49788594000006],
              [-80.843483256999946, 35.497399938000058],
              [-80.842718256999945, 35.496825939000075],
              [-80.841751255999952, 35.496125939000024],
              [-80.841637255999956, 35.496058938000033],
              [-80.841522255999962, 35.496001938000063],
              [-80.841426256999966, 35.495960938000053],
              [-80.841370255999948, 35.495941939000033],
              [-80.841219255999988, 35.495890938000059],
              [-80.839381254999978, 35.495329939000044],
              [-80.839268254999979, 35.495295939000073],
              [-80.837936254999988, 35.495686939000052],
              [-80.837724254999955, 35.495751939000058],
              [-80.837543254999957, 35.495806939000033],
              [-80.836793254999975, 35.496042939000063],
              [-80.836549253999976, 35.496117938000054],
              [-80.835903254999948, 35.496317939000051],
              [-80.835855254999956, 35.496333939000067],
              [-80.83569625399997, 35.496386939000047],
              [-80.835507253999936, 35.496462939000025],
              [-80.835163253999951, 35.49661893900003],
              [-80.835108253999977, 35.496644939000078],
              [-80.834996253999975, 35.49670093800006],
              [-80.834790253999984, 35.496815938000054],
              [-80.834626253999943, 35.496916939000073],
              [-80.834496254999976, 35.497004939000078],
              [-80.834366254999964, 35.497100939000063],
              [-80.834108253999943, 35.497320939000076],
              [-80.834066253999936, 35.497356940000031],
              [-80.833776253999986, 35.49763193900003],
              [-80.833718253999962, 35.497698939000031],
              [-80.833603253999968, 35.497830939000039],
              [-80.833457252999949, 35.498014939000029],
              [-80.833286252999983, 35.498240939000027],
              [-80.834748253999976, 35.497951939000075],
              [-80.836540254999989, 35.497595939000064],
              [-80.83679525499997, 35.497544939000079],
              [-80.836849254999947, 35.497647939000046]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 43,
        "SolutionID": "PI-17",
        "Shape_Length": 0.20716841795047053,
        "Shape_Area": 0.00063260745413133723
      }
    },
    {
      "type": "Feature",
      "id": 44,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 44,
        "SolutionID": "PI-17",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 45,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.835420236999937, 35.164250868000067],
              [-80.835529235999957, 35.164258868000047],
              [-80.83623623699998, 35.164352868000037],
              [-80.836324235999939, 35.163934868000069],
              [-80.836380235999968, 35.163692867000066],
              [-80.836437235999938, 35.163493868000046],
              [-80.836483236999982, 35.163365868000028],
              [-80.836590236999939, 35.163114868000036],
              [-80.836717236999959, 35.16286986700004],
              [-80.836805236999965, 35.162735867000038],
              [-80.836913236999976, 35.162581868000075],
              [-80.837138235999987, 35.162304867000046],
              [-80.837377235999952, 35.162057867000044],
              [-80.837479235999979, 35.161960868000051],
              [-80.837835236999979, 35.161622867000062],
              [-80.838026236999951, 35.161433868000074],
              [-80.838102236999987, 35.161348867000072],
              [-80.837650235999945, 35.161076867000077],
              [-80.83755423599996, 35.160998867000046],
              [-80.837476235999986, 35.160908868000035],
              [-80.837414235999972, 35.160810867000066],
              [-80.837391235999974, 35.160758868000073],
              [-80.837360236999984, 35.160650867000072],
              [-80.837349236999955, 35.160540868000055],
              [-80.837376235999955, 35.160148868000078],
              [-80.837428236999983, 35.159356868000032],
              [-80.837439235999966, 35.159067867000033],
              [-80.837427236999986, 35.158939867000072],
              [-80.837413235999975, 35.158872867000071],
              [-80.837369235999972, 35.15874186700006],
              [-80.837339236999981, 35.158675867000056],
              [-80.837304235999966, 35.158611867000047],
              [-80.837755235999964, 35.158395867000024],
              [-80.838648236999973, 35.158025867000049],
              [-80.838868236999986, 35.157935866000059],
              [-80.838920236999968, 35.15792086600004],
              [-80.838973236999948, 35.157910867000055],
              [-80.839081236999959, 35.157902867000075],
              [-80.840304236999941, 35.157955866000066],
              [-80.840494236999973, 35.157953866000071],
              [-80.84058923799995, 35.157946866000032],
              [-80.840775236999946, 35.157919866000043],
              [-80.840868236999938, 35.157899867000026],
              [-80.841024236999942, 35.15785586700008],
              [-80.841076236999982, 35.157835867000074],
              [-80.841145236999978, 35.157805867000036],
              [-80.84134023699994, 35.15769186700004],
              [-80.841471236999951, 35.157607867000024],
              [-80.84212023799995, 35.157155866000039],
              [-80.842506237999942, 35.156884866000041],
              [-80.842593237999949, 35.156827866000071],
              [-80.842733238999983, 35.156755867000072],
              [-80.842891237999936, 35.156694866000066],
              [-80.842972236999969, 35.15667086600007],
              [-80.843704237999987, 35.156477866000046],
              [-80.843484237999974, 35.155889866000052],
              [-80.843460237999977, 35.155771866000066],
              [-80.843456237999987, 35.155675866000024],
              [-80.84346323799997, 35.155569866000064],
              [-80.843509238999957, 35.15529086600003],
              [-80.843534236999972, 35.155140866000067],
              [-80.843616237999981, 35.154618866000078],
              [-80.843619237999974, 35.154552866000074],
              [-80.84361023799994, 35.154432866000036],
              [-80.843581237999956, 35.154272865000053],
              [-80.843542237999941, 35.154146866000076],
              [-80.843503237999983, 35.154050866000034],
              [-80.843131237999955, 35.153414866000048],
              [-80.843038237999963, 35.153260866000039],
              [-80.843013237999969, 35.153211866000049],
              [-80.842979237999941, 35.153111866000074],
              [-80.842966237999974, 35.153030866000051],
              [-80.842969237999966, 35.152925865000043],
              [-80.842984237999985, 35.152848865000067],
              [-80.84309223799994, 35.152468866000049],
              [-80.843138237999938, 35.152290866000044],
              [-80.843249237999942, 35.151899865000075],
              [-80.84193223799997, 35.151670866000075],
              [-80.841672237999944, 35.151599865000037],
              [-80.841741236999951, 35.151455866000049],
              [-80.842549237999947, 35.149775865000038],
              [-80.842994236999971, 35.148872864000055],
              [-80.843244237999954, 35.148365864000027],
              [-80.84347923699994, 35.147919865000063],
              [-80.843780237999965, 35.147348864000037],
              [-80.844061236999949, 35.146810864000031],
              [-80.844121236999968, 35.146702864000076],
              [-80.844160236999983, 35.146633865000069],
              [-80.844380236999939, 35.146248864000029],
              [-80.84482223699996, 35.145417863000034],
              [-80.845092238999939, 35.144905864000066],
              [-80.843801237999969, 35.144828864000033],
              [-80.843027235999955, 35.144777864000048],
              [-80.842847236999944, 35.144748864000064],
              [-80.842805236999936, 35.144740864000028],
              [-80.842749237999953, 35.144715863000044],
              [-80.84268723699995, 35.144662864000054],
              [-80.842658236999966, 35.144629864000024],
              [-80.842594236999958, 35.144529863000059],
              [-80.842568236999966, 35.144476864000069],
              [-80.842507237999939, 35.144293864000076],
              [-80.842248235999989, 35.14344886300006],
              [-80.840838235999968, 35.143738863000067],
              [-80.841330236999966, 35.145365864000041],
              [-80.841337236999948, 35.145411864000039],
              [-80.841338236999945, 35.145456864000039],
              [-80.841330236999966, 35.145518863000063],
              [-80.841300236999984, 35.145609863000061],
              [-80.841249236999943, 35.145694864000063],
              [-80.841210236999984, 35.145738864000066],
              [-80.841120236999984, 35.145809864000057],
              [-80.840693236999982, 35.146063864000041],
              [-80.840566236999962, 35.146134864000032],
              [-80.839657235999937, 35.146661865000056],
              [-80.839548236999974, 35.146708864000061],
              [-80.839430235999941, 35.146740864000037],
              [-80.839308235999965, 35.146756865000043],
              [-80.839165236999975, 35.146759864000046],
              [-80.838989235999975, 35.146762864000038],
              [-80.838533235999989, 35.146756865000043],
              [-80.837162235999983, 35.146730865000052],
              [-80.836972235999951, 35.146734865000042],
              [-80.83686423599994, 35.146748864000074],
              [-80.836551235999934, 35.146755865000046],
              [-80.836584235999965, 35.145777864000024],
              [-80.836612235999951, 35.144952864000061],
              [-80.836178235999967, 35.144948863000025],
              [-80.835994235999976, 35.144956864000051],
              [-80.835903234999989, 35.144967863000034],
              [-80.83580223499996, 35.144985863000045],
              [-80.835074234999979, 35.145146864000026],
              [-80.833995234999975, 35.145387864000043],
              [-80.833275234999974, 35.14554886500008],
              [-80.833161234999977, 35.145556864000071],
              [-80.833078234999959, 35.145552864000024],
              [-80.832983234999972, 35.145539864000057],
              [-80.832449233999967, 35.145439864000025],
              [-80.832398234999971, 35.145418864000078],
              [-80.832187233999946, 35.145855864000055],
              [-80.831902233999983, 35.146421864000047],
              [-80.831607233999989, 35.147007865000035],
              [-80.831342234999966, 35.147532864000027],
              [-80.83104423399999, 35.148122865000062],
              [-80.83098423399997, 35.148242865000043],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.830435234999982, 35.155301866000059],
              [-80.830356232999975, 35.155412866000063],
              [-80.830202234999945, 35.155652866000025],
              [-80.830141233999939, 35.155760867000026],
              [-80.830050234999987, 35.155938867000032],
              [-80.830027233999942, 35.15598486600004],
              [-80.829903232999982, 35.156206866000048],
              [-80.82984823399994, 35.156292867000047],
              [-80.829788232999988, 35.156376866000073],
              [-80.829625233999934, 35.156577867000067],
              [-80.829515232999938, 35.156692867000061],
              [-80.82936323399997, 35.156822867000074],
              [-80.829199233999987, 35.156945867000047],
              [-80.82904423399998, 35.157041867000032],
              [-80.828915233999965, 35.157104867000044],
              [-80.828738233999957, 35.157182867000074],
              [-80.828605233999951, 35.15723386600007],
              [-80.82836223299995, 35.157301867000058],
              [-80.828249233999941, 35.157336867000026],
              [-80.827719232999982, 35.157500868000056],
              [-80.827462233999938, 35.157580867000036],
              [-80.827571232999958, 35.157854867000026],
              [-80.827650232999986, 35.15809686700004],
              [-80.827696232999983, 35.158286867000072],
              [-80.827710233999937, 35.158345867000037],
              [-80.827743233999968, 35.158551867000028],
              [-80.827770233999956, 35.158957867000026],
              [-80.827752233999945, 35.15989386800004],
              [-80.828045233999944, 35.159892867000053],
              [-80.828225234999934, 35.159899868000025],
              [-80.828348233999975, 35.159898867000038],
              [-80.828472233999946, 35.159903868000072],
              [-80.82857923399996, 35.159913868000046],
              [-80.828685233999977, 35.159928868000065],
              [-80.828893232999974, 35.159968867000032],
              [-80.829092233999972, 35.160034868000025],
              [-80.82932623399995, 35.160134867000068],
              [-80.829576233999944, 35.160249867000061],
              [-80.830326233999983, 35.160600868000074],
              [-80.83051923499994, 35.160698868000054],
              [-80.830666233999978, 35.160763867000071],
              [-80.830719234999947, 35.160781867000026],
              [-80.830844234999972, 35.160811868000053],
              [-80.830975233999936, 35.160824868000077],
              [-80.831042234999984, 35.160824867000031],
              [-80.831175233999943, 35.16081286800005],
              [-80.831240234999939, 35.160798868000029],
              [-80.831439234999948, 35.160725867000053],
              [-80.831705234999959, 35.160636867000051],
              [-80.831816233999973, 35.160605868000061],
              [-80.831933234999951, 35.160594867000043],
              [-80.832054234999987, 35.160597868000025],
              [-80.832113234999952, 35.16060686700007],
              [-80.832227234999948, 35.160634867000056],
              [-80.83228223499998, 35.16065586700006],
              [-80.832561234999957, 35.160781867000026],
              [-80.832764234999956, 35.160858868000048],
              [-80.832956234999983, 35.160938867000027],
              [-80.833034235999946, 35.160981867000032],
              [-80.833204234999982, 35.161085868000043],
              [-80.833510235999938, 35.16132286800007],
              [-80.833662234999963, 35.161419868000053],
              [-80.833830235999983, 35.161511868000048],
              [-80.834055235999983, 35.161621867000065],
              [-80.834200234999969, 35.161676868000029],
              [-80.834355235999965, 35.161727868000071],
              [-80.834599235999974, 35.161794868000072],
              [-80.835003235999977, 35.161881867000034],
              [-80.835195235999947, 35.161939867000058],
              [-80.835602235999943, 35.162090868000064],
              [-80.835212235999961, 35.162813868000057],
              [-80.835070236999968, 35.163141868000025],
              [-80.834949236999989, 35.163474868000037],
              [-80.83491223599998, 35.163608869000029],
              [-80.834895234999976, 35.163699868000037],
              [-80.834884235999937, 35.16379286800003],
              [-80.834879235999949, 35.163924868000038],
              [-80.834886235999988, 35.164255868000055],
              [-80.835201235999989, 35.164246868000077],
              [-80.835420236999937, 35.164250868000067]
            ]
          ],
          [
            [
              [-80.873304248999943, 35.194455873000038],
              [-80.87447224999994, 35.194872873000065],
              [-80.874775249999971, 35.194973873000038],
              [-80.875134249999974, 35.195098874000053],
              [-80.87548524999994, 35.195221873000037],
              [-80.876259248999986, 35.195511874000033],
              [-80.876885249999987, 35.195736873000044],
              [-80.877218249999942, 35.195867873000054],
              [-80.877931249999961, 35.194949872000052],
              [-80.878011249999986, 35.194845874000066],
              [-80.878146250999976, 35.194667873000071],
              [-80.879313250999985, 35.193183873000066],
              [-80.879918250999935, 35.19241687300007],
              [-80.880481250999935, 35.191700872000069],
              [-80.88131225099994, 35.190641872000072],
              [-80.881465250999952, 35.19044787200005],
              [-80.882041250999976, 35.189716872000076],
              [-80.882472251999957, 35.189159872000062],
              [-80.882548250999946, 35.189054872000042],
              [-80.883367251999971, 35.188013872000056],
              [-80.884355251999978, 35.186758871000052],
              [-80.884705251999947, 35.185164870000051],
              [-80.88444825299996, 35.18531487100006],
              [-80.884115252999948, 35.185492871000065],
              [-80.884014251999986, 35.185422871000071],
              [-80.883953251999969, 35.185385871000051],
              [-80.883872251999946, 35.185344871000041],
              [-80.882312250999973, 35.184635871000069],
              [-80.882227250999961, 35.184588871000074],
              [-80.882101250999938, 35.184528871000055],
              [-80.882050250999953, 35.184506870000064],
              [-80.881759250999949, 35.184381871000028],
              [-80.881189250999967, 35.184120871000061],
              [-80.880523250999943, 35.183824870000024],
              [-80.880010250999987, 35.183601870000075],
              [-80.879423250999935, 35.183325870000033],
              [-80.879128249999951, 35.183757871000068],
              [-80.878565249999951, 35.184541871000079],
              [-80.878478249999944, 35.184782871000039],
              [-80.878439250999975, 35.184872871000039],
              [-80.878161250999938, 35.18549887100005],
              [-80.878042249999964, 35.185752871000034],
              [-80.877749249999965, 35.186317872000075],
              [-80.87737924999999, 35.186985872000037],
              [-80.877290249999987, 35.187148871000034],
              [-80.877230249999968, 35.187237872000026],
              [-80.876935249999974, 35.187762872000064],
              [-80.876891249999971, 35.187840872000038],
              [-80.876851249999959, 35.187893871000028],
              [-80.876757249999969, 35.187990872000057],
              [-80.876657249999937, 35.18807187200008],
              [-80.876606249999952, 35.188103872000056],
              [-80.876542248999954, 35.18813687200003],
              [-80.876388249999934, 35.18845187200003],
              [-80.875493249999977, 35.189857872000061],
              [-80.873097248999954, 35.194349873000078],
              [-80.872923248999939, 35.194740873000057],
              [-80.872901249999984, 35.19477687300008],
              [-80.872884248999981, 35.194826874000057],
              [-80.872918248999952, 35.194781874000057],
              [-80.872955248999972, 35.194726874000025],
              [-80.873074248999956, 35.194511873000067],
              [-80.873119248999956, 35.194474873000047],
              [-80.87317724899998, 35.194451873000048],
              [-80.873241247999943, 35.194444873000066],
              [-80.873304248999943, 35.194455873000038]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 45,
        "SolutionID": "PI-17",
        "Shape_Length": 0.11583756977668389,
        "Shape_Area": 0.00026689251738583715
      }
    },
    {
      "type": "Feature",
      "id": 46,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.832115234999947, 35.15546686700003],
              [-80.833490235999989, 35.156163866000043],
              [-80.833650233999947, 35.156252867000035],
              [-80.833796234999966, 35.156315866000057],
              [-80.833949234999977, 35.156365867000034],
              [-80.834134234999965, 35.156406867000044],
              [-80.834298234999949, 35.15642686700005],
              [-80.834352234999983, 35.15643086700004],
              [-80.834517234999964, 35.156431867000038],
              [-80.834654235999949, 35.156421867000063],
              [-80.834816235999938, 35.156397867000067],
              [-80.834975234999945, 35.156359866000059],
              [-80.835156234999943, 35.156297866000045],
              [-80.836218235999979, 35.15593186600006],
              [-80.836137236999946, 35.155678866000073],
              [-80.836108235999973, 35.155539866000026],
              [-80.836098235999941, 35.155399866000039],
              [-80.83607623599994, 35.15375986600003],
              [-80.836074235999945, 35.15360886600007],
              [-80.836078234999945, 35.153456865000067],
              [-80.836089235999964, 35.153303866000044],
              [-80.836106235999978, 35.15315186600003],
              [-80.836130234999985, 35.152999867000062],
              [-80.836160235999955, 35.152848866000056],
              [-80.836239235999983, 35.152548866000075],
              [-80.836344234999956, 35.152254866000078],
              [-80.836458235999942, 35.15198786600007],
              [-80.836896234999983, 35.150815865000027],
              [-80.836686235999935, 35.150737865000053],
              [-80.834452234999958, 35.149793865000049],
              [-80.833001234999983, 35.149180865000062],
              [-80.831918233999943, 35.148723866000068],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057]
            ]
          ],
          [
            [
              [-80.948870923999948, 35.199005597000053],
              [-80.94847731699997, 35.199015541000051],
              [-80.948084077999965, 35.199032681000062],
              [-80.947691402999965, 35.199057005000043],
              [-80.947299482999938, 35.199088503000041],
              [-80.946908515999951, 35.199127159000057],
              [-80.946518694999952, 35.199172955000051],
              [-80.946130212999947, 35.199225866000063],
              [-80.945743263999987, 35.199285867000071],
              [-80.945358036999949, 35.199352928000053],
              [-80.944974725999941, 35.199427016000072],
              [-80.944593519999955, 35.199508094000066],
              [-80.94421460999996, 35.199596123000049],
              [-80.94383818099999, 35.199691057000052],
              [-80.943464420999987, 35.199792851000041],
              [-80.943093516999966, 35.199901452000063],
              [-80.942725649999943, 35.200016809000033],
              [-80.942361005999942, 35.200138863000063],
              [-80.941999762999956, 35.20026755400005],
              [-80.941642101999946, 35.200402818000043],
              [-80.941288199999974, 35.200544588000071],
              [-80.940938231999951, 35.200692794000076],
              [-80.940592372999959, 35.200847361000058],
              [-80.940250793999951, 35.201008214000069],
              [-80.93991366299997, 35.201175273000047],
              [-80.939581149999981, 35.201348454000026],
              [-80.939253417999964, 35.201527672000054],
              [-80.938930629999959, 35.201712837000059],
              [-80.938612947999957, 35.201903859000026],
              [-80.938300525999978, 35.202100641000072],
              [-80.937993522999989, 35.202303088000065],
              [-80.937692089999985, 35.20251109600008],
              [-80.937396374999935, 35.202724566000029],
              [-80.937106526999969, 35.202943388000051],
              [-80.93682268799995, 35.203167457000063],
              [-80.936545000999956, 35.203396659000077],
              [-80.936273600999982, 35.203630881000038],
              [-80.936008625999989, 35.203870008000024],
              [-80.935750204999977, 35.204113921000044],
              [-80.935498467999935, 35.204362497000034],
              [-80.935253538999973, 35.204615614000033],
              [-80.935015538999949, 35.204873148000047],
              [-80.934784587999957, 35.205134969000028],
              [-80.934560798999939, 35.205400947000044],
              [-80.934344284999952, 35.205670951000059],
              [-80.934135151999953, 35.205944847000069],
              [-80.933933502999935, 35.206222500000024],
              [-80.933739439999954, 35.20650377000004],
              [-80.933553059999952, 35.206788518000053],
              [-80.933374452999942, 35.207076604000065],
              [-80.93320370899994, 35.207367884000064],
              [-80.933040913999946, 35.207662213000049],
              [-80.932886146999977, 35.207959445000029],
              [-80.932739485999946, 35.208259435000059],
              [-80.932601002999945, 35.208562030000053],
              [-80.932470766999984, 35.20886708200004],
              [-80.932348843999989, 35.209174441000073],
              [-80.932235293999952, 35.209483952000028],
              [-80.932130171999972, 35.209795462000045],
              [-80.932033531999934, 35.210108818000037],
              [-80.931945421999956, 35.210423863000074],
              [-80.931865884999979, 35.210740440000052],
              [-80.931794959999934, 35.21105839300003],
              [-80.931732684999986, 35.211377564000031],
              [-80.931679087999953, 35.211697794000031],
              [-80.931634197999983, 35.212018924000063],
              [-80.93159803399999, 35.212340797000024],
              [-80.931570618999956, 35.212663249000059],
              [-80.931551962999947, 35.212986124000054],
              [-80.931542075999971, 35.213309259000027],
              [-80.931540963999964, 35.21363249500007],
              [-80.931548626999984, 35.213955670000075],
              [-80.931565061999947, 35.214278626000066],
              [-80.93159025999995, 35.21460120100005],
              [-80.931624208999949, 35.214923235000072],
              [-80.931666890999963, 35.21524456800006],
              [-80.931718287999956, 35.215565041000048],
              [-80.93177837199994, 35.215884495000068],
              [-80.931847113999936, 35.216202771000042],
              [-80.931924478999974, 35.216519712000036],
              [-80.932010430999981, 35.216835158000038],
              [-80.932104926999955, 35.217148955000027],
              [-80.932207916999971, 35.217460946000074],
              [-80.932319352999968, 35.217770977000043],
              [-80.93243917999996, 35.21807889400003],
              [-80.932567335999977, 35.218384542000024],
              [-80.932703759999981, 35.218687772000067],
              [-80.932848383999954, 35.21898843200006],
              [-80.933001134999984, 35.219286373000045],
              [-80.933161937999955, 35.219581448000042],
              [-80.933330712999975, 35.219873509000024],
              [-80.933507375999966, 35.220162412000036],
              [-80.933691840999984, 35.220448013000066],
              [-80.93388401499999, 35.220730169000035],
              [-80.934083801999975, 35.221008743000027],
              [-80.934291104999943, 35.221283593000067],
              [-80.93450581999997, 35.221554586000025],
              [-80.934727840999983, 35.221821586000033],
              [-80.934957055999973, 35.222084460000076],
              [-80.935193351999942, 35.222343078000051],
              [-80.935436613999968, 35.222597310000026],
              [-80.935686718999989, 35.222847032000061],
              [-80.93594354399994, 35.223092119000057],
              [-80.936206958999946, 35.223332449000054],
              [-80.93647683599994, 35.223567904000049],
              [-80.936753040999974, 35.22379836600004],
              [-80.937035435999974, 35.224023720000048],
              [-80.937323879999951, 35.224243855000054],
              [-80.937618231999977, 35.224458661000028],
              [-80.937918342999978, 35.224668032000068],
              [-80.938224066999965, 35.224871864000079],
              [-80.938535248999983, 35.22507005500006],
              [-80.938851736999936, 35.225262508000071],
              [-80.939173374999939, 35.225449126000058],
              [-80.939499998999963, 35.225629817000026],
              [-80.939831450999975, 35.225804491000076],
              [-80.940167563999978, 35.225973062000037],
              [-80.940508171999966, 35.226135445000068],
              [-80.940853104999974, 35.22629156000005],
              [-80.941202192999981, 35.226441330000057],
              [-80.941555261999952, 35.226584679000041],
              [-80.941912136999974, 35.22672153700006],
              [-80.942272638999953, 35.226851837000027],
              [-80.942636591999985, 35.22697551300007],
              [-80.943003812999962, 35.227092504000041],
              [-80.943374119999987, 35.22720275100005],
              [-80.943747329999951, 35.227306201000033],
              [-80.944123256999944, 35.22740280000005],
              [-80.944501714999944, 35.227492503000065],
              [-80.944882514999961, 35.227575263000062],
              [-80.945265466999956, 35.227651040000069],
              [-80.945650382999986, 35.227719797000077],
              [-80.946037070999978, 35.22778149800007],
              [-80.946425339999962, 35.227836115000059],
              [-80.946814993999965, 35.227883618000078],
              [-80.94720584199996, 35.227923986000064],
              [-80.947597689999952, 35.22795719700008],
              [-80.947990341999969, 35.227983236000057],
              [-80.948383604999947, 35.228002088000039],
              [-80.948777280999934, 35.228013745000055],
              [-80.949171176999982, 35.228018202000044],
              [-80.949565095999958, 35.228015456000037],
              [-80.949958841999944, 35.228005507000034],
              [-80.950352220999946, 35.227988363000065],
              [-80.950745034999954, 35.227964029000077],
              [-80.951137091999954, 35.227932521000071],
              [-80.95152819499998, 35.227893850000044],
              [-80.951918150999973, 35.227848040000026],
              [-80.952306765999936, 35.227795109000056],
              [-80.952693845999988, 35.227735088000031],
              [-80.953079199999934, 35.227668003000076],
              [-80.953462636999973, 35.227593890000037],
              [-80.953843964999976, 35.227512784000055],
              [-80.954222994999952, 35.227424727000027],
              [-80.954599539999947, 35.227329760000032],
              [-80.954973412999948, 35.227227933000052],
              [-80.955344425999954, 35.22711929500008],
              [-80.955712395999967, 35.227003900000057],
              [-80.95607714099998, 35.226881806000051],
              [-80.956438479999974, 35.226753074000044],
              [-80.956796231999988, 35.22661776700005],
              [-80.95715021999996, 35.226475952000044],
              [-80.957500268999979, 35.22632770000007],
              [-80.957846204999953, 35.226173085000028],
              [-80.958187854999949, 35.22601218300008],
              [-80.958525049999935, 35.225845074000063],
              [-80.958857623999961, 35.225671842000054],
              [-80.959185408999986, 35.225492571000075],
              [-80.959508244999938, 35.225307352000073],
              [-80.95982596999994, 35.225116277000041],
              [-80.960138427999937, 35.224919439000075],
              [-80.960445460999949, 35.224716937000039],
              [-80.960746917999984, 35.224508872000058],
              [-80.961042650999957, 35.224295347000066],
              [-80.961332510999966, 35.224076467000032],
              [-80.96161635499999, 35.223852343000033],
              [-80.961894041999983, 35.223623083000064],
              [-80.962165433999985, 35.223388804000024],
              [-80.962430395999945, 35.223149621000061],
              [-80.962688797999988, 35.222905652000065],
              [-80.962940508999964, 35.222657020000042],
              [-80.96318540599998, 35.222403846000077],
              [-80.963423367999951, 35.222146259000056],
              [-80.963654275999943, 35.221884384000077],
              [-80.963878013999988, 35.221618352000064],
              [-80.964094472999989, 35.221348295000041],
              [-80.96430354499995, 35.221074348000059],
              [-80.964505126999939, 35.220796646000053],
              [-80.964699117999942, 35.220515328000033],
              [-80.964885420999963, 35.220230532000073],
              [-80.96506394499994, 35.219942401000026],
              [-80.965234600999963, 35.219651076000048],
              [-80.965397303999964, 35.219356705000052],
              [-80.965551973999936, 35.219059431000062],
              [-80.965698533999955, 35.218759402000046],
              [-80.96583691099994, 35.218456769000056],
              [-80.965967036999984, 35.218151681000052],
              [-80.96608884699998, 35.217844289000027],
              [-80.966202279999948, 35.217534747000059],
              [-80.966307281999946, 35.217223207000075],
              [-80.966403798999977, 35.216909824000027],
              [-80.966491781999935, 35.216594755000074],
              [-80.966571190999957, 35.216278155000055],
              [-80.966641983999978, 35.21596018300005],
              [-80.966704125999968, 35.215640994000069],
              [-80.96675758799995, 35.215320749000057],
              [-80.966802341999937, 35.21499960500006],
              [-80.96683836699998, 35.214677723000079],
              [-80.966865643999938, 35.214355262000026],
              [-80.96688415999995, 35.214032382000028],
              [-80.966893905999939, 35.213709244000029],
              [-80.966894878999938, 35.213386008000043],
              [-80.966887074999988, 35.213062835000073],
              [-80.96687050099996, 35.21273988400003],
              [-80.966845163999949, 35.212417317000074],
              [-80.966811075999942, 35.212095292000072],
              [-80.966768256999956, 35.211773971000071],
              [-80.96671672399998, 35.211453512000048],
              [-80.966656506999982, 35.211134076000064],
              [-80.966587632999961, 35.210815819000061],
              [-80.96651013799999, 35.210498901000051],
              [-80.966424059999952, 35.210183478000033],
              [-80.966329439999981, 35.209869708000042],
              [-80.966226328999937, 35.209557745000041],
              [-80.966114774999937, 35.209247745000027],
              [-80.965994833999957, 35.208939862000079],
              [-80.965866566999978, 35.208634249000056],
              [-80.965730036999958, 35.208331055000031],
              [-80.965585309999938, 35.208030434000079],
              [-80.965432460999978, 35.207732534000058],
              [-80.965271564999966, 35.207437502000062],
              [-80.965102700999978, 35.207145485000069],
              [-80.964925953999966, 35.206856628000025],
              [-80.964741410999977, 35.206571074000067],
              [-80.964549162999958, 35.206288966000045],
              [-80.964349306999964, 35.206010442000036],
              [-80.964141941999969, 35.205735641000047],
              [-80.963927169999977, 35.20546470000005],
              [-80.963705098999981, 35.205197754000039],
              [-80.963475838999955, 35.20493493400005],
              [-80.963239502999954, 35.204676371000062],
              [-80.962996207999936, 35.204422193000028],
              [-80.962746075999974, 35.204172527000026],
              [-80.96248922999996, 35.203927496000063],
              [-80.962225799999942, 35.203687223000031],
              [-80.961955913999986, 35.203451825000059],
              [-80.961679706999973, 35.203221420000034],
              [-80.961397315999989, 35.202996123000048],
              [-80.961108881999962, 35.202776045000064],
              [-80.960814546999984, 35.202561294000077],
              [-80.960514457999977, 35.20235198000006],
              [-80.960208762999969, 35.202148203000036],
              [-80.959897615999978, 35.201950067000041],
              [-80.959581167999943, 35.201757669000074],
              [-80.959259577999944, 35.201571104000038],
              [-80.958933005999938, 35.201390465000031],
              [-80.958601611999939, 35.201215844000046],
              [-80.958265563999987, 35.201047324000058],
              [-80.957925024999952, 35.200884990000077],
              [-80.957580165999957, 35.200728923000042],
              [-80.957231158999946, 35.20057920000005],
              [-80.956878174999986, 35.200435895000055],
              [-80.956521389999978, 35.200299080000036],
              [-80.956160982999961, 35.20016882200008],
              [-80.955797128999961, 35.200045186000068],
              [-80.955430010999976, 35.199928235000073],
              [-80.955059811999945, 35.199818023000034],
              [-80.954686712999944, 35.199714609000068],
              [-80.954310901999975, 35.199618042000054],
              [-80.953932561999977, 35.199528370000053],
              [-80.953551883999978, 35.199445637000053],
              [-80.953169056999968, 35.199369885000067],
              [-80.952784268999949, 35.199301153000079],
              [-80.952397709999957, 35.199239472000045],
              [-80.952009573999987, 35.199184876000061],
              [-80.951620053999989, 35.199137388000054],
              [-80.951229341999976, 35.199097035000079],
              [-80.950837630999956, 35.199063835000061],
              [-80.950445116999958, 35.199037806000035],
              [-80.950051993999978, 35.199018960000046],
              [-80.949658457999988, 35.199007307000045],
              [-80.949264701999937, 35.199002851000046],
              [-80.948870923999948, 35.199005597000053]
            ]
          ],
          [
            [
              [-80.839260585999966, 35.210655677000034],
              [-80.838866909999979, 35.210665264000056],
              [-80.838473591999957, 35.210682047000034],
              [-80.838080827999988, 35.210706015000028],
              [-80.837688809999975, 35.210737158000029],
              [-80.837297734999936, 35.210775460000036],
              [-80.836907796999981, 35.21082090200008],
              [-80.836519188999944, 35.210873461000062],
              [-80.836132102999954, 35.210933112000077],
              [-80.835746731999961, 35.210999823000066],
              [-80.835363265999945, 35.21107356400006],
              [-80.834981896999977, 35.211154296000075],
              [-80.834602814999982, 35.211241981000057],
              [-80.834226204999936, 35.211336574000029],
              [-80.833852253999964, 35.211438029000078],
              [-80.833481150999944, 35.211546294000073],
              [-80.83311307699995, 35.211661316000061],
              [-80.83274821699996, 35.211783041000047],
              [-80.832386748999966, 35.211911403000045],
              [-80.832028854999976, 35.212046343000054],
              [-80.831674712999984, 35.212187792000066],
              [-80.83132449499999, 35.212335680000024],
              [-80.830978378999987, 35.212489934000075],
              [-80.83063653399995, 35.212650477000068],
              [-80.830299130999947, 35.212817229000052],
              [-80.829966336999973, 35.212990109000032],
              [-80.829638317999979, 35.213169029000028],
              [-80.829315234999967, 35.213353901000062],
              [-80.828997249999986, 35.213544635000062],
              [-80.828684519999968, 35.213741133000042],
              [-80.828377199999977, 35.213943301000029],
              [-80.828075444999968, 35.214151036000032],
              [-80.82777939999994, 35.214364237000041],
              [-80.827489216999936, 35.214582796000059],
              [-80.827205035999953, 35.214806607000071],
              [-80.826927000999945, 35.215035557000078],
              [-80.826655247999952, 35.215269533000026],
              [-80.826389911999968, 35.215508419000059],
              [-80.82613112699994, 35.215752096000074],
              [-80.825879019999945, 35.216000444000031],
              [-80.825633714999981, 35.216253339000048],
              [-80.825395334999939, 35.216510656000025],
              [-80.825163997999937, 35.216772267000067],
              [-80.82493981999994, 35.21703804200007],
              [-80.82472291199997, 35.217307849000065],
              [-80.824513379999985, 35.217581555000038],
              [-80.824311328999954, 35.217859024000063],
              [-80.824116859999947, 35.218140118000065],
              [-80.82393006999996, 35.218424697000046],
              [-80.823751049999942, 35.21871261900003],
              [-80.823579889999962, 35.219003744000076],
              [-80.823416675999965, 35.219297925000035],
              [-80.823261486999968, 35.219595016000028],
              [-80.823114400999941, 35.219894872000054],
              [-80.822975490999966, 35.220197341000073],
              [-80.822844825999937, 35.220502275000058],
              [-80.822722471999953, 35.220809522000025],
              [-80.822608489999936, 35.221118930000046],
              [-80.822502932999953, 35.221430344000055],
              [-80.822405857999968, 35.221743611000079],
              [-80.822317311999939, 35.222058575000062],
              [-80.82223733699999, 35.222375079000074],
              [-80.822165973999972, 35.222692967000057],
              [-80.822103259999949, 35.223012081000036],
              [-80.822049224999944, 35.223332262000042],
              [-80.822003895999956, 35.223653350000063],
              [-80.821967294999979, 35.223975189000043],
              [-80.821939440999984, 35.224297616000058],
              [-80.821920347999935, 35.224620473000073],
              [-80.821910024999966, 35.224943599000028],
              [-80.821908476999965, 35.225266833000035],
              [-80.82191570699996, 35.22559001500008],
              [-80.821931708999955, 35.225912984000047],
              [-80.821956476999958, 35.226235581000026],
              [-80.821989996999946, 35.226557645000071],
              [-80.822032252999975, 35.226879016000055],
              [-80.822083225999961, 35.227199535000068],
              [-80.822142887999973, 35.227519043000029],
              [-80.822211211999957, 35.227837381000029],
              [-80.82228816199995, 35.228154390000043],
              [-80.822373700999947, 35.228469913000026],
              [-80.822467787999983, 35.228783796000073],
              [-80.822570371999973, 35.229095879000056],
              [-80.822681406999948, 35.229406010000048],
              [-80.822800836999988, 35.229714035000029],
              [-80.822928599999955, 35.230019799000047],
              [-80.82306463499998, 35.230323152000039],
              [-80.823208874999978, 35.230623942000079],
              [-80.82336124699998, 35.230922021000026],
              [-80.823521674999938, 35.231217241000024],
              [-80.823690080999938, 35.231509454000047],
              [-80.82386637999997, 35.231798516000026],
              [-80.824050486999965, 35.232084283000063],
              [-80.824242308999942, 35.232366614000057],
              [-80.824441748999959, 35.232645367000032],
              [-80.824648710999952, 35.232920405000073],
              [-80.824863092999976, 35.233191592000026],
              [-80.825084784999945, 35.233458792000079],
              [-80.825313678999976, 35.233721873000036],
              [-80.825549660999968, 35.233980705000079],
              [-80.825792614999955, 35.234235157000057],
              [-80.826042418999975, 35.234485105000033],
              [-80.826298949999966, 35.234730424000077],
              [-80.826562079999974, 35.234970993000047],
              [-80.826831678999952, 35.23520669100003],
              [-80.827107611999963, 35.235437403000049],
              [-80.827389743999959, 35.235663012000032],
              [-80.827677932999961, 35.235883408000063],
              [-80.827972036999938, 35.236098480000067],
              [-80.828271908999966, 35.236308123000072],
              [-80.828577401999951, 35.236512231000063],
              [-80.828888361999987, 35.236710705000064],
              [-80.829204635999986, 35.236903444000063],
              [-80.82952606799995, 35.237090353000042],
              [-80.829852495999944, 35.237271339000074],
              [-80.830183759999954, 35.237446314000067],
              [-80.830519692999985, 35.237615188000063],
              [-80.830860130999952, 35.237777880000067],
              [-80.831204903999947, 35.237934307000046],
              [-80.83155383899998, 35.238084393000065],
              [-80.831906764999985, 35.238228062000076],
              [-80.832263506999936, 35.238365243000032],
              [-80.832623884999975, 35.238495870000065],
              [-80.832987722999974, 35.23861987500004],
              [-80.83335483999997, 35.238737198000024],
              [-80.833725050999988, 35.238847781000061],
              [-80.834098174999951, 35.238951568000061],
              [-80.834474024999963, 35.239048508000053],
              [-80.83485241599999, 35.239138553000032],
              [-80.835233158999984, 35.239221659000066],
              [-80.835616062999975, 35.23929778300004],
              [-80.836000941999941, 35.239366889000053],
              [-80.836387601999945, 35.239428940000039],
              [-80.83677585199996, 35.239483908000068],
              [-80.83716549799999, 35.239531765000038],
              [-80.837556347999964, 35.239572487000032],
              [-80.837948205999965, 35.239606052000056],
              [-80.838340879999976, 35.239632447000076],
              [-80.838734172999978, 35.239651655000046],
              [-80.83912788899994, 35.239663670000027],
              [-80.839521834999971, 35.239668483000059],
              [-80.839915813999937, 35.239666094000029],
              [-80.840309629999979, 35.239656503000049],
              [-80.840703087999941, 35.239639715000067],
              [-80.841095989999985, 35.239615738000055],
              [-80.841488145999961, 35.239584584000056],
              [-80.841879356999982, 35.239546268000026],
              [-80.842269429999988, 35.239500811000028],
              [-80.842658170999982, 35.239448233000076],
              [-80.843045386999961, 35.239388563000034],
              [-80.843430886999954, 35.239321828000072],
              [-80.843814477999956, 35.239248063000048],
              [-80.844195969999987, 35.239167302000055],
              [-80.844575171999963, 35.239079588000038],
              [-80.844951898999966, 35.238984963000064],
              [-80.845325961999947, 35.23888347500008],
              [-80.845697173999952, 35.238775174000068],
              [-80.846065351999982, 35.238660113000037],
              [-80.846430312999985, 35.238538350000056],
              [-80.846791876999987, 35.238409945000058],
              [-80.847149861999981, 35.238274962000048],
              [-80.847504090999962, 35.238133469000047],
              [-80.847854389999952, 35.237985535000064],
              [-80.848200582999937, 35.237831233000065],
              [-80.848542497999972, 35.237670642000069],
              [-80.848879966999959, 35.237503839000055],
              [-80.849212820999981, 35.237330908000047],
              [-80.849540894999961, 35.237151935000043],
              [-80.849864025999977, 35.236967009000068],
              [-80.850182053999958, 35.23677622200006],
              [-80.850494820999984, 35.236579668000047],
              [-80.850802170999941, 35.236377445000073],
              [-80.85110395099997, 35.236169654000037],
              [-80.851400012999989, 35.235956397000052],
              [-80.851690208999969, 35.235737781000068],
              [-80.851974394999957, 35.23551391400008],
              [-80.852252429999965, 35.23528490700005],
              [-80.852524175999974, 35.235050874000024],
              [-80.852789497999936, 35.234811932000071],
              [-80.853048264999984, 35.234568198000034],
              [-80.853300346999958, 35.234319794000044],
              [-80.853545619999977, 35.234066843000051],
              [-80.853783962999955, 35.233809472000075],
              [-80.854015255999968, 35.233547807000036],
              [-80.854239383999982, 35.233281979000026],
              [-80.854456236999965, 35.23301211900008],
              [-80.85466570799997, 35.232738362000077],
              [-80.85486769299996, 35.232460843000069],
              [-80.855062088999944, 35.232179701000064],
              [-80.855248801999949, 35.231895075000068],
              [-80.855427739999982, 35.23160710600007],
              [-80.855598812999972, 35.231315937000034],
              [-80.855761934999975, 35.231021714000065],
              [-80.855917026999975, 35.230724581000061],
              [-80.856064010999944, 35.230424686000049],
              [-80.85620281599995, 35.230122179000034],
              [-80.856333370999948, 35.229817209000032],
              [-80.856455611999934, 35.229509929000073],
              [-80.856569477999983, 35.229200490000039],
              [-80.856674913999939, 35.228889046000063],
              [-80.856771865999974, 35.228575751000051],
              [-80.856860285999971, 35.228260763000037],
              [-80.856940131999977, 35.227944236000042],
              [-80.857011363999959, 35.227626328000042],
              [-80.857073943999978, 35.227307197000073],
              [-80.85712784499998, 35.226987000000065],
              [-80.857173036999939, 35.226665898000078],
              [-80.857209500999943, 35.226344049000033],
              [-80.857237214999941, 35.226021614000047],
              [-80.857256168999982, 35.225698752000028],
              [-80.857266351999954, 35.225375624000037],
              [-80.857267758999967, 35.225052389000041],
              [-80.857260389999965, 35.224729209000031],
              [-80.857244246999983, 35.224406244000079],
              [-80.857219340999961, 35.224083655000072],
              [-80.857185681999965, 35.223761600000046],
              [-80.857143287999975, 35.223440241000048],
              [-80.857092179999938, 35.223119736000058],
              [-80.857032383999979, 35.222800246000077],
              [-80.856963927999971, 35.222481928000036],
              [-80.856886848999977, 35.222164940000027],
              [-80.856801182999959, 35.221849440000028],
              [-80.856706971999984, 35.221535585000026],
              [-80.856604265999977, 35.221223529000042],
              [-80.856493112999942, 35.220913429000063],
              [-80.85637356899997, 35.220605438000064],
              [-80.856245694999984, 35.220299709000074],
              [-80.856109552999953, 35.219996392000041],
              [-80.855965210999955, 35.219695641000044],
              [-80.855812740999966, 35.219397603000061],
              [-80.855652217999989, 35.219102425000074],
              [-80.855483722999963, 35.21881025600004],
              [-80.855307339999968, 35.21852124000003],
              [-80.855123154999944, 35.218235519000075],
              [-80.854931258999954, 35.217953237000074],
              [-80.854731749999985, 35.217674533000036],
              [-80.854524724999976, 35.217399545000035],
              [-80.854310286999976, 35.217128411000033],
              [-80.854088542999989, 35.216861263000055],
              [-80.853859603999979, 35.216598237000028],
              [-80.853623581999955, 35.216339460000029],
              [-80.853380594999976, 35.216085062000047],
              [-80.853130762999967, 35.215835170000048],
              [-80.852874209999982, 35.215589907000037],
              [-80.852611065999952, 35.215349395000032],
              [-80.852341457999955, 35.215113753000026],
              [-80.852065521999975, 35.214883099000076],
              [-80.851783393999938, 35.214657546000069],
              [-80.851495214999943, 35.21443720700006],
              [-80.851201125999978, 35.214222190000044],
              [-80.850901276999934, 35.214012604000061],
              [-80.850595811999938, 35.213808551000056],
              [-80.850284886999987, 35.213610134000078],
              [-80.849968652999962, 35.213417449000076],
              [-80.849647267999956, 35.213230594000038],
              [-80.84932089199998, 35.213049659000035],
              [-80.848989686999971, 35.21287473700005],
              [-80.848653816999956, 35.212705913000036],
              [-80.848313447999942, 35.212543271000072],
              [-80.84796874999995, 35.21238689200004],
              [-80.847619893999934, 35.212236853000036],
              [-80.847267053999985, 35.212093229000061],
              [-80.846910401999935, 35.211956091000047],
              [-80.846550117999982, 35.211825506000025],
              [-80.84618637899996, 35.211701541000025],
              [-80.845819365999944, 35.211584257000027],
              [-80.845449261999988, 35.211473710000064],
              [-80.845076249999977, 35.211369958000034],
              [-80.844700514999943, 35.211273051000035],
              [-80.844322241999976, 35.211183036000079],
              [-80.843941620999942, 35.21109995900008],
              [-80.843558840999947, 35.211023860000068],
              [-80.843174089999934, 35.210954779000076],
              [-80.842787559999977, 35.210892748000049],
              [-80.842399441999987, 35.210837800000036],
              [-80.842009929999961, 35.210789959000067],
              [-80.84161921599997, 35.210749252000028],
              [-80.841227494999941, 35.210715697000069],
              [-80.840834959999938, 35.210689312000056],
              [-80.840441805999944, 35.210670110000024],
              [-80.840048229999979, 35.210658101000035],
              [-80.839654423999946, 35.21065328800006],
              [-80.839260585999966, 35.210655677000034]
            ]
          ],
          [
            [
              [-80.761552743999971, 35.311923829000079],
              [-80.761158568999974, 35.311933163000049],
              [-80.76076474599995, 35.311949693000031],
              [-80.760371469999939, 35.311973408000028],
              [-80.759978934999936, 35.31200429900008],
              [-80.759587336999971, 35.312042349000023],
              [-80.759196869999982, 35.31208754000005],
              [-80.758807727999965, 35.312139848000072],
              [-80.758420103999981, 35.312199249000059],
              [-80.758034189999989, 35.312265711000066],
              [-80.75765017599997, 35.312339205000058],
              [-80.757268255999975, 35.312419691000059],
              [-80.756888617999948, 35.312507130000029],
              [-80.756511448999959, 35.312601480000069],
              [-80.756136935999962, 35.312702692000073],
              [-80.755765267999948, 35.312810717000048],
              [-80.755396625999936, 35.312925501000052],
              [-80.755031195999948, 35.313046989000043],
              [-80.754669155999977, 35.313175117000071],
              [-80.754310686999986, 35.313309824000044],
              [-80.753955968999946, 35.313451043000043],
              [-80.753605174999961, 35.313598704000071],
              [-80.753258480999989, 35.313752732000069],
              [-80.752916057999983, 35.313913053000078],
              [-80.752578075999963, 35.314079586000048],
              [-80.752244701999984, 35.314252248000059],
              [-80.751916103999974, 35.314430955000034],
              [-80.751592441999946, 35.314615616000026],
              [-80.751273879999985, 35.314806142000066],
              [-80.75096057199994, 35.315002436000043],
              [-80.750652677999938, 35.315204403000052],
              [-80.750350347999984, 35.315411940000047],
              [-80.75005373099998, 35.31562494700006],
              [-80.749762977999978, 35.315843316000041],
              [-80.749478229999966, 35.316066940000042],
              [-80.749199629999964, 35.316295708000041],
              [-80.748927314999946, 35.316529505000062],
              [-80.748661421999941, 35.316768216000071],
              [-80.748402081999984, 35.317011724000054],
              [-80.748149423999962, 35.317259905000071],
              [-80.747903571999984, 35.317512638000039],
              [-80.747664648999944, 35.317769797000039],
              [-80.747432774999936, 35.318031255000051],
              [-80.747208063999949, 35.318296881000038],
              [-80.746990627999935, 35.318566544000078],
              [-80.746780574999946, 35.318840111000043],
              [-80.746578006999982, 35.319117445000074],
              [-80.746383027999968, 35.31939840900003],
              [-80.746195732999979, 35.319682862000036],
              [-80.746016214999941, 35.319970665000028],
              [-80.745844563999981, 35.320261674000051],
              [-80.745680865999987, 35.320555745000036],
              [-80.745525199999975, 35.320852732000048],
              [-80.745377644999962, 35.321152488000052],
              [-80.745238272999984, 35.321454862000053],
              [-80.745107153999982, 35.32175970600008],
              [-80.744984353999939, 35.322066870000072],
              [-80.744869933999951, 35.322376199000075],
              [-80.744763948999946, 35.322687539000071],
              [-80.744666452999979, 35.323000739000065],
              [-80.744577495999977, 35.323315640000033],
              [-80.744497118999959, 35.323632087000078],
              [-80.744425363999937, 35.323949924000033],
              [-80.744362266999985, 35.324268992000043],
              [-80.744307858999946, 35.324589132000028],
              [-80.744262166999988, 35.324910186000068],
              [-80.744225211999947, 35.32523199600007],
              [-80.744197015999987, 35.325554399000055],
              [-80.744177589999936, 35.325877238000032],
              [-80.74416694599995, 35.326200351000068],
              [-80.744165086999942, 35.326523578000035],
              [-80.744172016999983, 35.326846759000034],
              [-80.744187730999954, 35.327169733000062],
              [-80.744212219999952, 35.327492340000049],
              [-80.744245474999957, 35.327814420000038],
              [-80.744287476999943, 35.328135812000028],
              [-80.744338206999942, 35.328456358000039],
              [-80.744397637999953, 35.32877589900005],
              [-80.744465741999988, 35.329094275000045],
              [-80.744542484999954, 35.329411328000049],
              [-80.744627828999967, 35.329726900000026],
              [-80.744721732999949, 35.330040838000059],
              [-80.744824146999974, 35.330352981000033],
              [-80.744935022999982, 35.330663178000066],
              [-80.745054306999975, 35.330971274000035],
              [-80.745181936999984, 35.331277115000034],
              [-80.745317850999982, 35.33158054900008],
              [-80.745461982999984, 35.331881428000031],
              [-80.745614259999968, 35.332179599000028],
              [-80.745774605999941, 35.332474916000024],
              [-80.745942941999942, 35.332767233000027],
              [-80.746119184999941, 35.333056402000068],
              [-80.746303246999958, 35.333342283000036],
              [-80.746495037999978, 35.333624731000043],
              [-80.746694459999958, 35.333903608000071],
              [-80.746901416999947, 35.334178774000065],
              [-80.747115805999954, 35.334450094000033],
              [-80.747337518999984, 35.334717432000048],
              [-80.747566445999951, 35.334980655000038],
              [-80.747802473999968, 35.335239633000072],
              [-80.748045486999956, 35.335494238000024],
              [-80.748295363999944, 35.335744341000066],
              [-80.748551979999945, 35.335989821000055],
              [-80.74881520699995, 35.336230554000053],
              [-80.749084915999958, 35.336466422000058],
              [-80.749360971999977, 35.336697306000076],
              [-80.749643239999955, 35.336923093000053],
              [-80.749931576999984, 35.337143670000046],
              [-80.750225840999974, 35.337358927000025],
              [-80.750525885999934, 35.337568759000078],
              [-80.750831563999952, 35.337773060000075],
              [-80.751142720999951, 35.33797172900006],
              [-80.751459204999946, 35.338164668000047],
              [-80.751780857999961, 35.338351780000039],
              [-80.752107518999935, 35.338532973000042],
              [-80.752439027999969, 35.338708157000042],
              [-80.752775217999954, 35.338877245000049],
              [-80.753115924999975, 35.339040152000052],
              [-80.753460976999975, 35.339196798000046],
              [-80.753810202999944, 35.339347105000058],
              [-80.754163430999938, 35.339490999000077],
              [-80.75452048599999, 35.339628406000031],
              [-80.754881186999967, 35.33975926200003],
              [-80.755245359999947, 35.339883499000052],
              [-80.755612820999943, 35.340001056000062],
              [-80.755983386999958, 35.340111874000058],
              [-80.756356875999984, 35.340215900000032],
              [-80.756733100999952, 35.340313079000055],
              [-80.757111877999989, 35.340403366000032],
              [-80.757493014999966, 35.340486715000054],
              [-80.757876322999948, 35.340563083000063],
              [-80.758261613999935, 35.340632435000032],
              [-80.758648696999956, 35.340694734000067],
              [-80.75903737799996, 35.340749950000031],
              [-80.759427462999952, 35.34079805600004],
              [-80.759818759999973, 35.340839028000062],
              [-80.760211073999983, 35.340872845000035],
              [-80.760604210999986, 35.340899492000062],
              [-80.760997975999942, 35.340918952000038],
              [-80.761392170999954, 35.340931220000073],
              [-80.761786601999972, 35.340936286000044],
              [-80.762181073999955, 35.340934150000066],
              [-80.762575388999949, 35.340924812000026],
              [-80.762969351999971, 35.34090827700004],
              [-80.76336276699999, 35.340884552000034],
              [-80.763755440999944, 35.340853652000078],
              [-80.764147174999948, 35.340815588000055],
              [-80.764537776999987, 35.340770382000073],
              [-80.764927051999962, 35.340718055000025],
              [-80.765314806999982, 35.340658634000079],
              [-80.765700850999963, 35.340592148000042],
              [-80.766084989999968, 35.340518631000066],
              [-80.766467033999959, 35.34043811600003],
              [-80.766846791999967, 35.340350648000026],
              [-80.76722407699998, 35.340256267000029],
              [-80.767598702999976, 35.340155021000044],
              [-80.767970480999963, 35.340046960000052],
              [-80.768339227999945, 35.339932137000062],
              [-80.768704758999945, 35.339810611000075],
              [-80.76906689499998, 35.339682441000036],
              [-80.769425455999965, 35.339547691000064],
              [-80.769780260999937, 35.339406427000029],
              [-80.770131136999964, 35.339258721000078],
              [-80.770477907999975, 35.339104645000077],
              [-80.770820402999959, 35.338944276000063],
              [-80.771158449999973, 35.338777693000054],
              [-80.771491883999943, 35.338604979000024],
              [-80.77182053699994, 35.338426220000031],
              [-80.772144246999972, 35.338241505000042],
              [-80.772462852999979, 35.338050925000061],
              [-80.772776196999985, 35.337854576000041],
              [-80.77308412299999, 35.337652554000044],
              [-80.773386476999974, 35.337444960000028],
              [-80.773683111999958, 35.337231898000027],
              [-80.773973876999946, 35.337013471000034],
              [-80.774258630999952, 35.336789791000058],
              [-80.774537230999954, 35.336560967000025],
              [-80.774809538999989, 35.336327113000038],
              [-80.775075419999951, 35.336088345000064],
              [-80.775334740999938, 35.335844782000038],
              [-80.77558737399994, 35.335596545000044],
              [-80.775833193999972, 35.335343756000043],
              [-80.776072079999949, 35.335086543000045],
              [-80.776303909999967, 35.334825031000037],
              [-80.776528571999961, 35.334559351000053],
              [-80.776745952999988, 35.334289635000061],
              [-80.776955945999987, 35.334016018000057],
              [-80.777158446999977, 35.333738634000042],
              [-80.777353353999956, 35.333457622000026],
              [-80.777540570999975, 35.333173121000073],
              [-80.777720006999971, 35.332885272000055],
              [-80.777891570999941, 35.332594218000054],
              [-80.778055176999942, 35.332300105000058],
              [-80.778210745999957, 35.332003077000024],
              [-80.778358199999957, 35.331703282000035],
              [-80.778497465999976, 35.331400869000049],
              [-80.778628474999948, 35.331095989000062],
              [-80.778751161999935, 35.330788792000078],
              [-80.778865464999967, 35.33047943300005],
              [-80.77897132999999, 35.330168061000052],
              [-80.779068701999961, 35.329854835000049],
              [-80.779157531999942, 35.329539909000061],
              [-80.77923777999996, 35.329223439000032],
              [-80.779309403999946, 35.328905583000051],
              [-80.779372366999951, 35.32858649700006],
              [-80.779426640999986, 35.328266341000074],
              [-80.779472195999972, 35.327945273000068],
              [-80.779509011999949, 35.327623454000047],
              [-80.779537068999957, 35.327301042000045],
              [-80.779556354999954, 35.326978198000063],
              [-80.779566858999942, 35.326655082000059],
              [-80.779568576999964, 35.326331854000045],
              [-80.779561506999983, 35.326008676000072],
              [-80.779545652999957, 35.325685706000058],
              [-80.779521022999973, 35.325363106000054],
              [-80.779487629999949, 35.325041035000027],
              [-80.77944549099999, 35.324719655000024],
              [-80.779394624999952, 35.324399123000035],
              [-80.779335058999948, 35.324079600000061],
              [-80.779266822999944, 35.323761244000025],
              [-80.779189949999989, 35.323444212000027],
              [-80.779104477999965, 35.323128663000034],
              [-80.779010449999987, 35.322814753000046],
              [-80.778907913999944, 35.322502637000071],
              [-80.778796918999944, 35.322192470000061],
              [-80.778677520999963, 35.321884408000074],
              [-80.778549779999935, 35.321578602000045],
              [-80.778413757999942, 35.321275203000027],
              [-80.778269522999949, 35.320974365000041],
              [-80.778117147999978, 35.320676234000075],
              [-80.777956707999977, 35.320380959000033],
              [-80.77778828199996, 35.320088686000076],
              [-80.777611954999941, 35.319799562000071],
              [-80.777427812999974, 35.319513728000061],
              [-80.777235948999987, 35.319231328000058],
              [-80.777036456999951, 35.31895250000008],
              [-80.776829436999947, 35.318677384000068],
              [-80.776614990999974, 35.318406116000062],
              [-80.776393225999982, 35.318138832000045],
              [-80.77616425399998, 35.317875662000063],
              [-80.775928184999941, 35.317616739000073],
              [-80.775685137999972, 35.31736218900005],
              [-80.775435233999985, 35.317112141000052],
              [-80.775178595999989, 35.316866718000028],
              [-80.774915353999972, 35.316626041000063],
              [-80.774645635999946, 35.316390230000025],
              [-80.774369576999959, 35.316159403000029],
              [-80.774087312999939, 35.315933673000075],
              [-80.773798984999985, 35.315713152000058],
              [-80.773504736999939, 35.315497950000065],
              [-80.773204713999974, 35.315288175000035],
              [-80.772899063999944, 35.315083929000025],
              [-80.772587941999973, 35.314885316000073],
              [-80.772271497999952, 35.314692431000026],
              [-80.771949891999952, 35.314505373000031],
              [-80.77162328299994, 35.314324232000047],
              [-80.771291831999974, 35.314149100000066],
              [-80.770955704999949, 35.313980064000077],
              [-80.770615067999984, 35.313817206000067],
              [-80.770270090999986, 35.313660608000077],
              [-80.769920943999978, 35.313510347000033],
              [-80.769567800999937, 35.31336649900004],
              [-80.769210836999946, 35.313229134000039],
              [-80.768850228999952, 35.313098320000051],
              [-80.768486155999938, 35.31297412400005],
              [-80.768118797999989, 35.312856606000025],
              [-80.767748339999969, 35.312745823000057],
              [-80.767374961999963, 35.312641833000043],
              [-80.766998851999972, 35.312544686000024],
              [-80.766620194999973, 35.312454430000059],
              [-80.766239179999957, 35.312371110000072],
              [-80.765855995999971, 35.312294767000026],
              [-80.765470832999938, 35.312225439000031],
              [-80.765083879999963, 35.312163161000058],
              [-80.764695331999974, 35.312107965000052],
              [-80.764305380999986, 35.312059875000045],
              [-80.763914219999947, 35.312018917000046],
              [-80.763522042999966, 35.311985112000059],
              [-80.763129044999971, 35.31195847500004],
              [-80.762735419999956, 35.311939020000068],
              [-80.762341365999987, 35.311926758000027],
              [-80.761947074999966, 35.311921693000045],
              [-80.761552743999971, 35.311923829000079]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 46,
        "SolutionID": "PI-18",
        "Shape_Length": 0.33216810588908169,
        "Shape_Area": 0.002464658322986495
      }
    },
    {
      "type": "Feature",
      "id": 47,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.848390249999966, 35.346195907000038],
              [-80.848159249999981, 35.347309907000067],
              [-80.847949249999942, 35.348165907000066],
              [-80.847929249999936, 35.348248907000027],
              [-80.847877250999943, 35.348437907000061],
              [-80.847779249999974, 35.348772908000058],
              [-80.847656249999943, 35.349198908000062],
              [-80.847441249999974, 35.349936908000075],
              [-80.847037249999971, 35.351197908000074],
              [-80.846737248999943, 35.352039907000062],
              [-80.846420249999937, 35.352860908000025],
              [-80.846202249999976, 35.353388908000056],
              [-80.845978249999973, 35.353908908000051],
              [-80.845800248999979, 35.354302908000079],
              [-80.845062248999966, 35.35594190900008],
              [-80.84378124899996, 35.358747909000044],
              [-80.843527249999966, 35.359337910000079],
              [-80.843446248999953, 35.359552909000058],
              [-80.843366249999974, 35.359770910000066],
              [-80.843182249999984, 35.360289909000073],
              [-80.843027248999988, 35.360826910000071],
              [-80.842946248999965, 35.361143909000077],
              [-80.842781248999984, 35.361898911000026],
              [-80.842690249999976, 35.362449910000066],
              [-80.842658247999964, 35.362696910000068],
              [-80.842633249999949, 35.362896910000075],
              [-80.842595249999988, 35.363328910000064],
              [-80.842582248999975, 35.363592910000079],
              [-80.843247248999944, 35.363508910000064],
              [-80.845008249999978, 35.363285911000048],
              [-80.849229249999951, 35.362753910000038],
              [-80.850188250999963, 35.362592910000046],
              [-80.850817251999956, 35.362449909000077],
              [-80.851128251999967, 35.362367911000035],
              [-80.851740251999956, 35.362183910000056],
              [-80.851837251999939, 35.362149910000028],
              [-80.85204225199999, 35.362081910000029],
              [-80.852340251999976, 35.361972909000031],
              [-80.852634251999973, 35.361856910000029],
              [-80.85292425199998, 35.361734910000052],
              [-80.853158251999957, 35.361623910000048],
              [-80.853314251999961, 35.361550909000073],
              [-80.853458251999939, 35.361483910000061],
              [-80.853598250999937, 35.361418909000065],
              [-80.853786252999953, 35.361317909000036],
              [-80.854139252999971, 35.361129910000045],
              [-80.854660251999974, 35.360815910000042],
              [-80.855160251999962, 35.360479909000048],
              [-80.855636252999943, 35.360121910000032],
              [-80.855865252999934, 35.35993490900006],
              [-80.856088252999939, 35.359742909000033],
              [-80.856304252999962, 35.359545909000076],
              [-80.856513252999946, 35.359344910000061],
              [-80.856764252999938, 35.359087909000039],
              [-80.857258252999941, 35.358572909000031],
              [-80.859667252999941, 35.356071908000047],
              [-80.860474254999986, 35.355230909000056],
              [-80.861398253999937, 35.354271908000044],
              [-80.863931254999954, 35.351639907000049],
              [-80.864436254999987, 35.35112190600006],
              [-80.864608254999951, 35.350945907000039],
              [-80.864791253999954, 35.350768907000031],
              [-80.865117254999973, 35.350454906000039],
              [-80.865509254999949, 35.350102907000064],
              [-80.865969254999982, 35.349717906000024],
              [-80.866274254999951, 35.349474907000058],
              [-80.866586254999959, 35.349236906000044],
              [-80.867227255999978, 35.348777906000066],
              [-80.86755625699999, 35.348555906000058],
              [-80.867856254999936, 35.348365907000073],
              [-80.868223255999965, 35.348134906000041],
              [-80.868391255999938, 35.348037905000069],
              [-80.868740255999967, 35.347840906000044],
              [-80.869094256999972, 35.347650906000069],
              [-80.869816256999968, 35.347288906000074],
              [-80.870183256999951, 35.347116906000053],
              [-80.870932257999982, 35.346791905000032],
              [-80.871312256999943, 35.346639906000064],
              [-80.87169625699994, 35.346493906000035],
              [-80.872083257999975, 35.346353906000047],
              [-80.872474257999954, 35.346220906000042],
              [-80.872869256999934, 35.346094905000029],
              [-80.872944256999972, 35.346071905000031],
              [-80.873266257999944, 35.345974905000048],
              [-80.874069256999974, 35.34575690500003],
              [-80.876076257999955, 35.34524990500006],
              [-80.876019258999975, 35.345217905000027],
              [-80.875943258999939, 35.345167906000029],
              [-80.875864258999968, 35.345129906000068],
              [-80.875750257999982, 35.345025905000057],
              [-80.875687257999971, 35.344985905000044],
              [-80.875622257999964, 35.344963906000032],
              [-80.875587257999939, 35.344944905000034],
              [-80.875529257999972, 35.344871906000037],
              [-80.875524257999984, 35.344767905000026],
              [-80.875464256999976, 35.34471990600008],
              [-80.875423256999966, 35.344658906000063],
              [-80.875308257999961, 35.344568905000074],
              [-80.875266257999954, 35.344523906000063],
              [-80.875219257999959, 35.344473905000029],
              [-80.875209257999984, 35.34442090500005],
              [-80.87505325799998, 35.344253905000073],
              [-80.875013256999978, 35.344196905000047],
              [-80.874999257999946, 35.34417690500004],
              [-80.874941256999989, 35.343930905000036],
              [-80.874909257999946, 35.343850905000068],
              [-80.87487325799998, 35.343790905000048],
              [-80.874815256999966, 35.343730905000029],
              [-80.874748257999954, 35.343679905000045],
              [-80.874684256999956, 35.343617905000031],
              [-80.874663256999952, 35.343544905000044],
              [-80.874619257999939, 35.34348290500003],
              [-80.874568256999964, 35.343425905000061],
              [-80.874513257999979, 35.343380904000071],
              [-80.874460257999942, 35.343310905000067],
              [-80.874426257999971, 35.343250905000048],
              [-80.874362257999962, 35.343200904000071],
              [-80.874302257999943, 35.343145905000029],
              [-80.874254256999961, 35.343042905000061],
              [-80.874214256999949, 35.34298390500004],
              [-80.874105257999986, 35.342870905000041],
              [-80.874044257999969, 35.342833905000077],
              [-80.87400325699997, 35.342813904000025],
              [-80.873904256999936, 35.342722905000073],
              [-80.873830257999941, 35.342671905000032],
              [-80.873765256999945, 35.342647905000035],
              [-80.873615256999983, 35.342565905000072],
              [-80.873546256999987, 35.342515905000027],
              [-80.873480256999983, 35.342439904000059],
              [-80.873373256999969, 35.342395905000046],
              [-80.873302256999978, 35.342352905000041],
              [-80.87312125599999, 35.342258905000051],
              [-80.873042255999962, 35.342206904000079],
              [-80.873025256999938, 35.342136905000075],
              [-80.872946255999977, 35.342116905000069],
              [-80.872855256999969, 35.342115905000071],
              [-80.872780256999988, 35.342124906000038],
              [-80.872658256999955, 35.342158905000076],
              [-80.872555255999941, 35.342139905000067],
              [-80.872447256999976, 35.342135905000077],
              [-80.872362256999963, 35.342124905000048],
              [-80.872265256999981, 35.342121905000056],
              [-80.872159256999964, 35.342112905000079],
              [-80.872060255999941, 35.342082905000041],
              [-80.871981256999959, 35.342043905000025],
              [-80.871842256999969, 35.342006905000062],
              [-80.871672256999943, 35.341942905000053],
              [-80.871579256999951, 35.341940905000058],
              [-80.871466256999952, 35.341946905000043],
              [-80.871404256999938, 35.341969905000042],
              [-80.871306255999968, 35.341994904000046],
              [-80.871229255999936, 35.341969906000031],
              [-80.87114025599999, 35.341918904000067],
              [-80.871089256999937, 35.34190690500003],
              [-80.871035255999971, 35.341932904000032],
              [-80.871018255999957, 35.341954904000033],
              [-80.871008256999971, 35.341978905000076],
              [-80.871007255999984, 35.342089904000034],
              [-80.871004255999935, 35.342128904000049],
              [-80.87099025699996, 35.342157905000079],
              [-80.870901255999968, 35.342230904000075],
              [-80.870752256999936, 35.342310905000033],
              [-80.870561255999974, 35.342391905000056],
              [-80.870284255999934, 35.342478904000075],
              [-80.870222255999977, 35.342508905000045],
              [-80.870180255999969, 35.342529905000049],
              [-80.870096254999964, 35.342599905000043],
              [-80.870048256999951, 35.342670906000023],
              [-80.870020255999975, 35.342766905000076],
              [-80.870004255999959, 35.342870905000041],
              [-80.869980255999963, 35.342920905000028],
              [-80.869934255999965, 35.342972905000067],
              [-80.869884255999978, 35.342997905000061],
              [-80.869795255999975, 35.343013905000078],
              [-80.869633255999986, 35.343019905000062],
              [-80.869533255999954, 35.343055905000028],
              [-80.869312255999944, 35.34316390500004],
              [-80.869262255999956, 35.343177906000051],
              [-80.869195255999955, 35.343206905000045],
              [-80.868991254999969, 35.343324905000031],
              [-80.868879255999957, 35.343345906000025],
              [-80.868802255999981, 35.343367905000036],
              [-80.868693254999982, 35.343412905000037],
              [-80.868482254999947, 35.343525905000035],
              [-80.868427255999961, 35.343563905000053],
              [-80.868366255999945, 35.343592905000037],
              [-80.868285255999979, 35.343613905000041],
              [-80.86814725499994, 35.343634905000044],
              [-80.868058254999937, 35.343668905000072],
              [-80.868035254999938, 35.343723906000037],
              [-80.868016254999986, 35.343745905000048],
              [-80.867997255999967, 35.343777906000071],
              [-80.867976255999963, 35.343826905000071],
              [-80.867947255999979, 35.343867905000025],
              [-80.867900254999938, 35.343897906000052],
              [-80.867791255999975, 35.343930905000036],
              [-80.867679254999985, 35.343977905000031],
              [-80.867460255999958, 35.344049905000077],
              [-80.86738425599998, 35.34413590500003],
              [-80.867295255999977, 35.344206906000068],
              [-80.867208255999969, 35.344249905000027],
              [-80.867145255999958, 35.34427090500003],
              [-80.867091255999981, 35.344303905000061],
              [-80.867064255999935, 35.344332905000044],
              [-80.867023255999982, 35.344349906000048],
              [-80.866867255999978, 35.344359906000079],
              [-80.866831254999965, 35.344366906000062],
              [-80.866766254999959, 35.344402905000038],
              [-80.86673925599996, 35.344405906000077],
              [-80.866661255999986, 35.344374905000052],
              [-80.866613254999947, 35.344377905000044],
              [-80.866544255999941, 35.344393905000061],
              [-80.866425255999957, 35.344379906000029],
              [-80.866328254999985, 35.344399906000035],
              [-80.86619925499997, 35.344437906000053],
              [-80.866057254999987, 35.344455906000064],
              [-80.865926254999977, 35.344465906000039],
              [-80.865780254999947, 35.344486905000053],
              [-80.865643254999952, 35.344541905000028],
              [-80.86550525499996, 35.344567905000076],
              [-80.865444253999954, 35.344585906000077],
              [-80.86536225499998, 35.344593906000057],
              [-80.865189253999972, 35.344615905000069],
              [-80.865083254999945, 35.344632905000026],
              [-80.865010254999959, 35.344654906000073],
              [-80.864953254999989, 35.344679906000067],
              [-80.864922254999954, 35.344697906000079],
              [-80.864792254999941, 35.344716906000031],
              [-80.864661254999987, 35.34472790600006],
              [-80.864562253999964, 35.344750906000058],
              [-80.864455254999939, 35.344807906000028],
              [-80.864371253999934, 35.344836906000069],
              [-80.86433825499995, 35.34489990600008],
              [-80.864261254999974, 35.344933906000051],
              [-80.864232253999944, 35.34494890600007],
              [-80.864131253999972, 35.345000905000063],
              [-80.864091253999959, 35.345022906000054],
              [-80.863818254999956, 35.345113906000051],
              [-80.863573254999949, 35.34523490600003],
              [-80.86350125499996, 35.345254906000036],
              [-80.863424254999984, 35.345259906000024],
              [-80.863376253999945, 35.345255906000034],
              [-80.863278254999955, 35.345296906000044],
              [-80.863202253999987, 35.345317906000048],
              [-80.863101253999957, 35.345361906000051],
              [-80.863076252999974, 35.345378906000064],
              [-80.863052254999957, 35.345393906000027],
              [-80.862985254999955, 35.345419906000075],
              [-80.862891254999965, 35.345445906000066],
              [-80.86273225399998, 35.345493906000058],
              [-80.862652253999954, 35.345520906000047],
              [-80.862589252999953, 35.345536906000063],
              [-80.862462254999969, 35.34556490600005],
              [-80.862298253999938, 35.345589906000043],
              [-80.862180253999952, 35.34562190500003],
              [-80.862106253999968, 35.34564690600007],
              [-80.861981253999943, 35.345694906000062],
              [-80.861898253999982, 35.34573290600008],
              [-80.86174525399997, 35.34579390600004],
              [-80.861717253999984, 35.345805906000066],
              [-80.861567253999965, 35.345853906000059],
              [-80.86144225299995, 35.345948906000046],
              [-80.861316253999973, 35.345996906000039],
              [-80.861182253999971, 35.346016906000045],
              [-80.861102253999945, 35.346025907000069],
              [-80.861003253999968, 35.346000906000029],
              [-80.860979253999972, 35.345979906000025],
              [-80.860884253999984, 35.345913906000078],
              [-80.860767253999938, 35.345847906000074],
              [-80.86069925399994, 35.34579390600004],
              [-80.860557252999968, 35.345697906000055],
              [-80.860373252999977, 35.345572906000029],
              [-80.860295253999936, 35.345520905000058],
              [-80.860162252999942, 35.345421907000059],
              [-80.860094252999943, 35.345374906000075],
              [-80.860045253999942, 35.345337906000054],
              [-80.860010252999984, 35.345313906000058],
              [-80.859939252999936, 35.345258906000026],
              [-80.859772253999949, 35.34513690600005],
              [-80.859689252999942, 35.345086906000063],
              [-80.85963725299996, 35.345058906000077],
              [-80.859461252999949, 35.344967906000079],
              [-80.859083251999948, 35.34478090600004],
              [-80.859014253999987, 35.344733906000044],
              [-80.858969252999941, 35.344701905000079],
              [-80.858871252999961, 35.344640906000052],
              [-80.858827252999959, 35.34462290700003],
              [-80.858675252999944, 35.344549905000065],
              [-80.858551251999984, 35.344497906000072],
              [-80.858490252999957, 35.34444290600004],
              [-80.858208252999987, 35.344294906000073],
              [-80.858118252999986, 35.344230906000064],
              [-80.858035251999979, 35.344189906000054],
              [-80.857950251999966, 35.344166906000055],
              [-80.857902252999963, 35.344158905000029],
              [-80.857850252999981, 35.344147905000057],
              [-80.85779125199997, 35.34412790600004],
              [-80.857742252999969, 35.344134906000079],
              [-80.857694251999988, 35.344120905000068],
              [-80.857617251999955, 35.344056907000038],
              [-80.857526252999946, 35.344004905000077],
              [-80.857328252999935, 35.343986906000055],
              [-80.857149251999942, 35.343946906000042],
              [-80.857051251999962, 35.343928906000031],
              [-80.856992251999941, 35.343908906000024],
              [-80.856923252999934, 35.343891906000067],
              [-80.856863251999982, 35.34387990700003],
              [-80.856812252999987, 35.343873905000066],
              [-80.856751251999981, 35.343856906000042],
              [-80.856699251999942, 35.343834906000041],
              [-80.856516252999938, 35.343791905000046],
              [-80.856365251999989, 35.343717906000052],
              [-80.856302251999978, 35.343714906000059],
              [-80.856277251999984, 35.343700906000038],
              [-80.856190252999966, 35.343674906000047],
              [-80.856041251999955, 35.34364690600006],
              [-80.855951251999954, 35.343624906000059],
              [-80.855898251999974, 35.343621906000067],
              [-80.855843250999953, 35.343610905000048],
              [-80.855794252999942, 35.343606906000048],
              [-80.855721252999956, 35.343597905000024],
              [-80.855625250999935, 35.343589906000034],
              [-80.85551725199997, 35.343578906000062],
              [-80.85547625099997, 35.343575906000069],
              [-80.855434251999952, 35.343579906000059],
              [-80.855320251999956, 35.343578905000072],
              [-80.855299251999952, 35.34356490600004],
              [-80.855281251999941, 35.343545906000031],
              [-80.855241251999985, 35.343535905000067],
              [-80.85519425199999, 35.343530906000069],
              [-80.855165251999949, 35.343520906000037],
              [-80.855027250999967, 35.343499906000034],
              [-80.854992251999988, 35.343487906000064],
              [-80.854908250999983, 35.343480905000035],
              [-80.854783251999947, 35.343448906000049],
              [-80.854733250999971, 35.343446906000054],
              [-80.854693251999947, 35.343434906000027],
              [-80.85464725199995, 35.343432906000032],
              [-80.85459825099997, 35.343439905000025],
              [-80.854546251999977, 35.343436906000079],
              [-80.854499251999982, 35.343427906000045],
              [-80.85440325199994, 35.343390906000025],
              [-80.854296251999983, 35.343358906000049],
              [-80.854239251999957, 35.343334907000042],
              [-80.854192250999972, 35.343324906000078],
              [-80.85414825099997, 35.34332390600008],
              [-80.854066250999949, 35.343299905000038],
              [-80.854022250999947, 35.343279906000078],
              [-80.853975250999952, 35.343265906000056],
              [-80.853920251999966, 35.343258905000027],
              [-80.85385525199996, 35.343265905000067],
              [-80.853795250999951, 35.343260905000079],
              [-80.853543250999962, 35.34318890600008],
              [-80.853445250999982, 35.343150905000073],
              [-80.853301250999948, 35.343121906000079],
              [-80.853205250999963, 35.343084906000058],
              [-80.853153250999981, 35.343052905000036],
              [-80.85306325099998, 35.343017906000057],
              [-80.853003251999951, 35.342970905000072],
              [-80.852959250999959, 35.342944906000071],
              [-80.852909250999971, 35.342923906000067],
              [-80.852849250999952, 35.342902906000063],
              [-80.852769250999984, 35.342867906000038],
              [-80.852701250999985, 35.342840906000049],
              [-80.852651250999941, 35.342818906000048],
              [-80.852623250999955, 35.342801905000044],
              [-80.852573250999967, 35.342761906000078],
              [-80.85247825099998, 35.342707905000054],
              [-80.85239224999998, 35.342643906000035],
              [-80.852332249999961, 35.342605905000028],
              [-80.852176249999957, 35.342532906000031],
              [-80.852128250999954, 35.342493906000072],
              [-80.852068250999935, 35.34246190500005],
              [-80.851877250999962, 35.342332906000024],
              [-80.851678249999964, 35.342167905000053],
              [-80.851593249999951, 35.342143906000047],
              [-80.851541249999968, 35.342121906000045],
              [-80.851495249999971, 35.342107905000034],
              [-80.85144625099997, 35.342049905000067],
              [-80.851392250999936, 35.342006905000062],
              [-80.851354250999975, 35.341989906000038],
              [-80.851237250999986, 35.341932905000078],
              [-80.851070250999953, 35.341832906000036],
              [-80.850754249999966, 35.341687906000061],
              [-80.850596249999967, 35.341647905000059],
              [-80.850426249999941, 35.341607905000046],
              [-80.85039625099995, 35.341590906000079],
              [-80.850304249999965, 35.34157990600005],
              [-80.850251249999985, 35.341564906000031],
              [-80.850136249999935, 35.341554906000056],
              [-80.850061249999953, 35.341536906000044],
              [-80.850022249999938, 35.341509905000066],
              [-80.849859249999952, 35.341476906000025],
              [-80.849737248999986, 35.341447905000052],
              [-80.849630249999962, 35.341426905000048],
              [-80.849529249999989, 35.341417905000071],
              [-80.849463249999985, 35.341393905000075],
              [-80.849323250999987, 35.341356906000044],
              [-80.848987249999936, 35.341150905000063],
              [-80.848983249999947, 35.341217905000065],
              [-80.848968249999984, 35.341562905000046],
              [-80.848878248999938, 35.342863906000048],
              [-80.84881224999998, 35.343577906000064],
              [-80.848761249999939, 35.344030906000057],
              [-80.848647249999942, 35.344846907000033],
              [-80.848617249999961, 35.345005907000029],
              [-80.848531249999951, 35.345423906000065],
              [-80.848390249999966, 35.346195907000038]
            ]
          ],
          [
            [
              [-80.831795245999956, 35.370615912000062],
              [-80.831913246999989, 35.37077391300005],
              [-80.832052245999989, 35.37097291200007],
              [-80.832158245999949, 35.371142913000028],
              [-80.832249245999947, 35.371304912000028],
              [-80.832343245999937, 35.371485912000026],
              [-80.83246524599997, 35.371762913000055],
              [-80.832565246999934, 35.372014913000044],
              [-80.832772245999934, 35.37260191300004],
              [-80.83287824699994, 35.372945913000024],
              [-80.83296824599995, 35.373258913000029],
              [-80.833045246999973, 35.373558913000068],
              [-80.833119246999956, 35.373883913000043],
              [-80.833191246999945, 35.374229912000033],
              [-80.833272246999968, 35.374671914000032],
              [-80.833336245999988, 35.37513391300007],
              [-80.833591246999958, 35.377562913000077],
              [-80.834132247999946, 35.382744916000036],
              [-80.83421724699997, 35.383565915000077],
              [-80.835030248999942, 35.383299914000077],
              [-80.835683247999953, 35.383086915000035],
              [-80.837083247999942, 35.382631915000047],
              [-80.84177724999995, 35.381115914000077],
              [-80.842581249999967, 35.380856915000038],
              [-80.84414124999995, 35.380333913000072],
              [-80.84308324999995, 35.376645913000061],
              [-80.844964250999965, 35.376458913000079],
              [-80.845338249999941, 35.376424913000051],
              [-80.847063251999941, 35.376251913000033],
              [-80.847508251999955, 35.37620791300003],
              [-80.853866253999968, 35.37560691300007],
              [-80.854165252999962, 35.375584913000068],
              [-80.854194252999946, 35.375382912000077],
              [-80.854450252999982, 35.37385091200008],
              [-80.854625252999938, 35.372838912000077],
              [-80.854687252999952, 35.372445912000046],
              [-80.85474725399996, 35.372173912000051],
              [-80.854812252999977, 35.371954912000035],
              [-80.854901253999969, 35.371711911000034],
              [-80.85500725299994, 35.371474912000053],
              [-80.855492252999966, 35.370564912000077],
              [-80.856462252999961, 35.368695911000032],
              [-80.856499253999971, 35.368275912000058],
              [-80.85647325299999, 35.367982912000059],
              [-80.856298252999977, 35.367475911000042],
              [-80.855906252999944, 35.366825911000035],
              [-80.855825252999978, 35.366837910000072],
              [-80.855620252999984, 35.366853911000078],
              [-80.85544925399995, 35.366856910000024],
              [-80.855395252999983, 35.366854911000075],
              [-80.855298252999944, 35.366851911000026],
              [-80.855123251999942, 35.36683891000007],
              [-80.854922252999984, 35.366816910000068],
              [-80.854644252999947, 35.36677891100004],
              [-80.853643252999973, 35.366628911000078],
              [-80.85269525199999, 35.366463911000039],
              [-80.852489251999941, 35.366435911000053],
              [-80.851776251999979, 35.366369911000049],
              [-80.850943251999979, 35.36630591100004],
              [-80.850530251999942, 35.366274910000072],
              [-80.849587250999946, 35.366192910000052],
              [-80.849233250999987, 35.366167911000048],
              [-80.849068250999949, 35.366160911000065],
              [-80.84891425099994, 35.366160911000065],
              [-80.848784250999984, 35.366167911000048],
              [-80.848532250999938, 35.366196911000031],
              [-80.847870250999961, 35.366319911000062],
              [-80.846682249999958, 35.366550911000047],
              [-80.846228249999967, 35.366632911000067],
              [-80.845420249999961, 35.36677891100004],
              [-80.845138248999945, 35.366835910000077],
              [-80.844874250999965, 35.366896911000026],
              [-80.844637249999948, 35.366968911000072],
              [-80.844483249999939, 35.367025911000042],
              [-80.844294249999962, 35.367112911000049],
              [-80.844057249999935, 35.367244911000057],
              [-80.843761249999943, 35.367414912000072],
              [-80.843698249999989, 35.367451912000035],
              [-80.843545248999988, 35.367541911000046],
              [-80.843060249999951, 35.367830911000055],
              [-80.84257624899999, 35.368119912000054],
              [-80.842105248999985, 35.368396911000048],
              [-80.841551248999963, 35.368723912000064],
              [-80.841224248999936, 35.368910911000057],
              [-80.840925248999952, 35.369062911000071],
              [-80.839159248999977, 35.369913912000072],
              [-80.838736248999965, 35.370107912000037],
              [-80.838531247999981, 35.370189912000058],
              [-80.83834924699994, 35.370249912000077],
              [-80.83818324799995, 35.370291912000027],
              [-80.838012247999984, 35.370325913000045],
              [-80.837812247999977, 35.370350912000049],
              [-80.837564247999978, 35.370370912000055],
              [-80.837265247999937, 35.370386912000072],
              [-80.83698224799997, 35.370393912000054],
              [-80.834968247999939, 35.370380913000076],
              [-80.833984246999989, 35.370381912000028],
              [-80.833860247999951, 35.370378912000035],
              [-80.833770245999972, 35.370377913000027],
              [-80.833201246999977, 35.370350912000049],
              [-80.832701246999989, 35.370312912000031],
              [-80.832261245999973, 35.370272912000075],
              [-80.831521245999966, 35.370195912000042],
              [-80.831443245999935, 35.370191912000053],
              [-80.831593245999954, 35.370365912000068],
              [-80.831795245999956, 35.370615912000062]
            ]
          ],
          [
            [
              [-80.836849254999947, 35.497647939000046],
              [-80.837491253999985, 35.49804793900006],
              [-80.837936254999988, 35.498325940000029],
              [-80.83814225499998, 35.498453939000058],
              [-80.837683255999934, 35.49966493900007],
              [-80.83750125499995, 35.500142940000046],
              [-80.83564325399999, 35.504432941000061],
              [-80.83532525399994, 35.505180940000059],
              [-80.833636253999941, 35.509156942000061],
              [-80.83350825499997, 35.509446942000068],
              [-80.833928253999943, 35.509477942000046],
              [-80.834491253999943, 35.509518942000057],
              [-80.839295255999957, 35.509867941000039],
              [-80.839820256999985, 35.509906941000054],
              [-80.840497255999935, 35.509956942000031],
              [-80.841372256999989, 35.51002094100005],
              [-80.845313257999976, 35.510307942000054],
              [-80.845850258999974, 35.510346942000069],
              [-80.852927260999934, 35.510861942000076],
              [-80.855593261999957, 35.511055941000052],
              [-80.855796261999956, 35.511070941000071],
              [-80.859166261999974, 35.511315941000078],
              [-80.860223261999977, 35.511392941000054],
              [-80.861301261999984, 35.511481941000056],
              [-80.861958263999952, 35.51153594200008],
              [-80.862105262999989, 35.51154794100006],
              [-80.865199263999955, 35.511773941000058],
              [-80.865529263999974, 35.511796941000057],
              [-80.865653263999945, 35.511805942000024],
              [-80.865885263999985, 35.511822941000048],
              [-80.865889263999975, 35.511753941000052],
              [-80.865991263999945, 35.51034694100008],
              [-80.866075264999949, 35.509069940000074],
              [-80.866135263999979, 35.508307940000066],
              [-80.866156262999937, 35.508097940000027],
              [-80.866217263999943, 35.507328940000036],
              [-80.86629226499997, 35.506379940000045],
              [-80.866350263999948, 35.50550794000003],
              [-80.866438263999953, 35.504329939000058],
              [-80.866471263999983, 35.503896939000072],
              [-80.866498263999972, 35.503666940000073],
              [-80.866527263999956, 35.503512939000075],
              [-80.866611263999971, 35.503072940000038],
              [-80.866662264999945, 35.502850939000041],
              [-80.866760263999936, 35.502533939000045],
              [-80.866980263999949, 35.50193893900007],
              [-80.867067264999946, 35.501737939000066],
              [-80.86732826299999, 35.501231938000046],
              [-80.867368262999946, 35.501143938000041],
              [-80.867466263999972, 35.500925939000069],
              [-80.867752264999979, 35.500355939000031],
              [-80.867860263999944, 35.500140939000062],
              [-80.867917263999971, 35.500027938000073],
              [-80.868013263999956, 35.499830938000059],
              [-80.868197263999946, 35.499465939000061],
              [-80.869199263999974, 35.49744393800006],
              [-80.869399263999981, 35.497048937000045],
              [-80.869423264999966, 35.497003938000034],
              [-80.870231264999973, 35.495378937000055],
              [-80.870567263999988, 35.49469093700003],
              [-80.870921264999936, 35.49398493700005],
              [-80.871335264999971, 35.493177937000041],
              [-80.871768264999957, 35.492276936000053],
              [-80.873348264999947, 35.489092935000031],
              [-80.873651264999978, 35.488500936000037],
              [-80.873688264999942, 35.488424936000058],
              [-80.873735265999983, 35.489705936000064],
              [-80.873947265999959, 35.489589937000062],
              [-80.874613264999937, 35.489211936000061],
              [-80.874893264999969, 35.489044936000028],
              [-80.875249264999979, 35.488799935000031],
              [-80.875899265999976, 35.48831593500006],
              [-80.87657726599997, 35.487826935000044],
              [-80.877469265999935, 35.487182935000078],
              [-80.877952266999955, 35.486827936000054],
              [-80.878275265999946, 35.48659393500003],
              [-80.878975265999941, 35.48609093500005],
              [-80.879307266999945, 35.48640993500004],
              [-80.879781266999942, 35.486857935000046],
              [-80.879895266999938, 35.486954935000028],
              [-80.880375266999977, 35.487403936000078],
              [-80.880540266999958, 35.487552935000053],
              [-80.880686266999987, 35.487625936000029],
              [-80.880819267999982, 35.487652935000028],
              [-80.880990267999948, 35.487654936000069],
              [-80.881712267999944, 35.487570935000065],
              [-80.882123267999987, 35.487518935000026],
              [-80.882296267999948, 35.487501935000068],
              [-80.882440267999982, 35.487480935000065],
              [-80.882519267999953, 35.487462935000053],
              [-80.882591267999942, 35.487434935000067],
              [-80.882687267999984, 35.48737793500004],
              [-80.88285826799995, 35.48724293500004],
              [-80.883046266999941, 35.487051935000068],
              [-80.883095267999977, 35.486983936000058],
              [-80.883146267999962, 35.48687893500005],
              [-80.883198267999944, 35.486755935000076],
              [-80.883230267999977, 35.48664193500008],
              [-80.883266267999943, 35.486406935000048],
              [-80.883265267999946, 35.486234935000027],
              [-80.883213267999963, 35.486025935000043],
              [-80.883170267999958, 35.485917935000032],
              [-80.883129267999948, 35.485833935000073],
              [-80.883054267999967, 35.485702934000074],
              [-80.883029266999984, 35.485643934000052],
              [-80.883017267999946, 35.485601935000034],
              [-80.883004267999979, 35.485557935000031],
              [-80.882982268999967, 35.485418935000041],
              [-80.882973266999954, 35.485203935000072],
              [-80.88295226799994, 35.484917936000045],
              [-80.882968267999956, 35.484846935000064],
              [-80.882990266999968, 35.48477693500007],
              [-80.88313526799999, 35.484464935000062],
              [-80.883162266999989, 35.484370935000072],
              [-80.883177268999987, 35.484280935000072],
              [-80.883180268999979, 35.484177934000058],
              [-80.883160267999983, 35.484070935000034],
              [-80.883126267999955, 35.483975934000057],
              [-80.883078267999963, 35.483893935000026],
              [-80.883029266999984, 35.483835935000059],
              [-80.882987266999976, 35.483793934000062],
              [-80.882863267999937, 35.483652934000077],
              [-80.882636266999953, 35.483446935000075],
              [-80.883301267999968, 35.482966935000036],
              [-80.883992267999986, 35.482467934000056],
              [-80.884039266999935, 35.482408934000034],
              [-80.884058268999979, 35.482322934000024],
              [-80.884064267999975, 35.482264934000057],
              [-80.88405526799994, 35.48220393400004],
              [-80.884032268999988, 35.482100934000073],
              [-80.883937267999954, 35.481814934000056],
              [-80.884289267999975, 35.48174193400007],
              [-80.884767267999962, 35.481623934000027],
              [-80.885143267999979, 35.481499934000055],
              [-80.885877267999945, 35.481229934000055],
              [-80.88691726899998, 35.480842934000066],
              [-80.887514268999951, 35.480616934000068],
              [-80.888311268999985, 35.480323934000069],
              [-80.888879268999972, 35.48008793300005],
              [-80.889098268999987, 35.47998593300008],
              [-80.889299269999981, 35.479882934000045],
              [-80.889500268999939, 35.479771933000052],
              [-80.889669269999956, 35.479666933000033],
              [-80.889848270999948, 35.479543933000059],
              [-80.889933268999982, 35.479477933000055],
              [-80.890019269999982, 35.479410933000054],
              [-80.890248269999972, 35.47920593300006],
              [-80.890387269999962, 35.47907093300006],
              [-80.890566269999965, 35.478874933000043],
              [-80.890699269999971, 35.478709933000061],
              [-80.890917269999989, 35.478397933000053],
              [-80.890989269999977, 35.478281933000062],
              [-80.891119268999944, 35.478034932000071],
              [-80.891174270999954, 35.477908933000037],
              [-80.891290269999956, 35.477598933000024],
              [-80.891347269999983, 35.47742693400005],
              [-80.891413268999941, 35.477200933000063],
              [-80.891453269999943, 35.476992933000076],
              [-80.891472268999962, 35.476791933000072],
              [-80.891476269999941, 35.47669893300008],
              [-80.891469269999959, 35.476215933000049],
              [-80.891485269999976, 35.475475933000041],
              [-80.891500269999938, 35.474899932000028],
              [-80.891515269999957, 35.474555933000033],
              [-80.891544270999987, 35.473583932000054],
              [-80.891590268999948, 35.471890932000065],
              [-80.891590269999938, 35.47165693200003],
              [-80.891576269999973, 35.471411931000034],
              [-80.891560269999957, 35.471289931000058],
              [-80.891509268999982, 35.471054931000026],
              [-80.891453269999943, 35.47087993100007],
              [-80.891415269999982, 35.470776932000035],
              [-80.891357269999958, 35.470656932000054],
              [-80.891210269999988, 35.470399932000078],
              [-80.890530269999942, 35.469354932000044],
              [-80.889528268999982, 35.467834930000038],
              [-80.889273268999943, 35.467422931000044],
              [-80.889160268999944, 35.467216930000063],
              [-80.889095267999949, 35.467079930000068],
              [-80.888977267999962, 35.466790930000059],
              [-80.888917267999943, 35.466592931000037],
              [-80.888875268999982, 35.466416930000037],
              [-80.888858267999979, 35.46634393000005],
              [-80.888833268999974, 35.466186931000038],
              [-80.888820267999961, 35.466102930000034],
              [-80.888799268999946, 35.465884930000072],
              [-80.888784267999938, 35.465722930000027],
              [-80.888771267999971, 35.465521930000079],
              [-80.888752268999951, 35.464896930000066],
              [-80.888713268999936, 35.463457930000061],
              [-80.888706267999964, 35.463221930000032],
              [-80.888695268999982, 35.46277493000008],
              [-80.888657268999964, 35.461316930000066],
              [-80.888641268999947, 35.460774929000024],
              [-80.888628267999934, 35.46001792900006],
              [-80.88860626799999, 35.45926692900008],
              [-80.888590266999984, 35.458311929000047],
              [-80.888547267999968, 35.456932929000061],
              [-80.88848726699996, 35.454348928000059],
              [-80.88847526699999, 35.454072928000073],
              [-80.888471267999989, 35.453916928000069],
              [-80.888455267999973, 35.453324929000075],
              [-80.888450266999939, 35.453141928000036],
              [-80.888442267999949, 35.452572928000052],
              [-80.888452266999934, 35.452399928000034],
              [-80.888463267999953, 35.452251928000067],
              [-80.888468266999951, 35.452191928000047],
              [-80.888494267999988, 35.452038927000046],
              [-80.888535267999941, 35.451862927000036],
              [-80.888588267999978, 35.451704928000026],
              [-80.888678267999978, 35.451504928000077],
              [-80.888785266999946, 35.451318927000045],
              [-80.888899267999989, 35.451154928000051],
              [-80.889074267999945, 35.450947927000072],
              [-80.889237266999942, 35.450780927000039],
              [-80.889460268999983, 35.450584927000079],
              [-80.890052267999977, 35.450104927000041],
              [-80.891016267999987, 35.449325928000064],
              [-80.891331268999977, 35.44905092700003],
              [-80.891498268999953, 35.448885926000059],
              [-80.891644268999983, 35.448730927000042],
              [-80.891778268999985, 35.448571927000046],
              [-80.891933268999935, 35.448363927000059],
              [-80.89211826899998, 35.44807492700005],
              [-80.892181268999934, 35.447970927000028],
              [-80.89231426799995, 35.447729926000079],
              [-80.89290626899998, 35.446590926000056],
              [-80.893027268999958, 35.446370926000043],
              [-80.893216267999946, 35.446092926000063],
              [-80.893284267999945, 35.446009926000045],
              [-80.893324267999958, 35.445959925000068],
              [-80.893410268999958, 35.445873926000047],
              [-80.893504268999948, 35.445784926000044],
              [-80.893702268999959, 35.445618926000066],
              [-80.893965269999967, 35.44543692600007],
              [-80.894119268999987, 35.445303926000065],
              [-80.894210268999984, 35.445217925000065],
              [-80.89428326899997, 35.445140925000032],
              [-80.894345269999974, 35.445036926000057],
              [-80.894395268999972, 35.44491892700006],
              [-80.894425269999942, 35.44478792600006],
              [-80.894431269999984, 35.444622926000079],
              [-80.89405026899999, 35.444616926000037],
              [-80.893812268999966, 35.444602926000073],
              [-80.893673267999986, 35.444598925000037],
              [-80.893326267999953, 35.444571926000037],
              [-80.892497267999943, 35.444494926000061],
              [-80.890481266999984, 35.444308926000076],
              [-80.890381266999952, 35.44429692600005],
              [-80.890323267999975, 35.444290926000065],
              [-80.888773267999966, 35.444144926000035],
              [-80.886199266999938, 35.443922926000027],
              [-80.88533126599998, 35.443840926000064],
              [-80.884337264999942, 35.443740926000032],
              [-80.883938266999962, 35.443701926000074],
              [-80.883225264999965, 35.443624926000041],
              [-80.881983264999974, 35.443511926000042],
              [-80.880510263999952, 35.443379925000045],
              [-80.879431263999948, 35.443282925000062],
              [-80.878436264999948, 35.443182926000077],
              [-80.877693263999959, 35.443107926000039],
              [-80.877776263999976, 35.443024927000067],
              [-80.877848263999965, 35.442874925000069],
              [-80.878206263999971, 35.442355926000062],
              [-80.878255263999961, 35.442268925000064],
              [-80.878320263999967, 35.442170926000074],
              [-80.878365263999967, 35.442111926000052],
              [-80.878381264999973, 35.442043925000064],
              [-80.878377263999937, 35.44192892600006],
              [-80.878383263999979, 35.441858925000076],
              [-80.878411263999965, 35.441768926000066],
              [-80.878442262999954, 35.441653926000072],
              [-80.878447263999988, 35.441574925000054],
              [-80.87846626399994, 35.441514925000035],
              [-80.878523262999977, 35.441421926000032],
              [-80.878569263999964, 35.441335925000033],
              [-80.87858926499996, 35.441313925000031],
              [-80.878596264999942, 35.441310926000028],
              [-80.878628263999985, 35.441310926000028],
              [-80.878676262999988, 35.441313925000031],
              [-80.878765263999981, 35.441314925000029],
              [-80.878815263999968, 35.441310926000028],
              [-80.878842264999946, 35.441307926000036],
              [-80.878888263999954, 35.441296926000064],
              [-80.878971263999972, 35.441280926000047],
              [-80.879049263999946, 35.441248926000071],
              [-80.879086263999966, 35.44122692600007],
              [-80.87911526399995, 35.441206926000064],
              [-80.879135264999945, 35.441188926000052],
              [-80.879147263999982, 35.441170925000051],
              [-80.879161264999937, 35.441143926000052],
              [-80.879172264999966, 35.441109926000024],
              [-80.87919326399998, 35.441038926000033],
              [-80.879206263999947, 35.440965925000057],
              [-80.879222262999974, 35.440904925000041],
              [-80.879228263999948, 35.440885925000032],
              [-80.879253263999942, 35.440854926000043],
              [-80.879282262999936, 35.440831925000055],
              [-80.879314264999948, 35.440826925000067],
              [-80.879353264999963, 35.440835925000044],
              [-80.879382263999958, 35.440838926000026],
              [-80.879405263999956, 35.440831925000055],
              [-80.879419263999978, 35.440815925000038],
              [-80.879425264999952, 35.440795926000078],
              [-80.87942626499995, 35.440774925000028],
              [-80.879430264999939, 35.440746925000042],
              [-80.879429264999942, 35.440706926000075],
              [-80.879428263999955, 35.440674925000053],
              [-80.879432263999945, 35.440603926000051],
              [-80.879437263999989, 35.440574925000078],
              [-80.879447263999964, 35.440549925000028],
              [-80.879442263999977, 35.440525926000078],
              [-80.879442263999977, 35.440505926000071],
              [-80.879452263999951, 35.440488926000057],
              [-80.879470264999952, 35.440473925000049],
              [-80.879492264999953, 35.440465926000059],
              [-80.879552263999983, 35.44045092600004],
              [-80.879592264999985, 35.44044692600005],
              [-80.879628264999951, 35.440440925000075],
              [-80.87965526399995, 35.440432926000028],
              [-80.879690263999976, 35.440419925000072],
              [-80.879794263999941, 35.440357925000058],
              [-80.879860263999944, 35.440315926000039],
              [-80.879895264999959, 35.440289925000059],
              [-80.879937264999967, 35.440233926000076],
              [-80.879949263999947, 35.44020692600003],
              [-80.879985264999959, 35.440137926000034],
              [-80.880027263999978, 35.440067925000051],
              [-80.880048263999981, 35.440044925000052],
              [-80.880081263999955, 35.440023926000038],
              [-80.880117264999967, 35.439997925000057],
              [-80.880149263999954, 35.439979925000046],
              [-80.880248263999988, 35.439950925000062],
              [-80.880336263999936, 35.439926926000055],
              [-80.880422264999936, 35.439911925000047],
              [-80.880474264999975, 35.439904925000064],
              [-80.880516263999937, 35.439900926000064],
              [-80.880570263999971, 35.439903926000056],
              [-80.88059326399997, 35.439905926000051],
              [-80.880622264999943, 35.439899925000077],
              [-80.880640264999954, 35.439875925000024],
              [-80.880652264999981, 35.439812925000069],
              [-80.880661264999958, 35.439742925000076],
              [-80.880679264999969, 35.439706925000053],
              [-80.880712264999943, 35.43967492400003],
              [-80.880734264999944, 35.439641925000046],
              [-80.880772264999962, 35.43957692400005],
              [-80.88080326499994, 35.439538925000079],
              [-80.880865264999954, 35.439452926000058],
              [-80.880895264999936, 35.439394925000045],
              [-80.880907264999962, 35.439364925000064],
              [-80.880932264999956, 35.439323925000053],
              [-80.880964264999989, 35.439304925000044],
              [-80.880996264999965, 35.43928392600003],
              [-80.881045264999955, 35.43923892600003],
              [-80.881105264999974, 35.439177925000024],
              [-80.881153263999977, 35.439116925000064],
              [-80.881253264999941, 35.438978925000072],
              [-80.881278264999935, 35.438949925000031],
              [-80.88133626399997, 35.438893926000048],
              [-80.881396264999978, 35.438840925000079],
              [-80.881427263999967, 35.438814925000031],
              [-80.881476264999947, 35.438762925000049],
              [-80.881502264999938, 35.438730925000073],
              [-80.881533264999973, 35.438677925000036],
              [-80.881567264999944, 35.438616925000076],
              [-80.881593263999946, 35.43856792400004],
              [-80.881650264999962, 35.438489924000066],
              [-80.881676264999953, 35.438436925000076],
              [-80.881697264999957, 35.438401925000051],
              [-80.881717264999963, 35.438379925000049],
              [-80.881735265999964, 35.438350925000066],
              [-80.881761263999977, 35.438324925000074],
              [-80.88183926499994, 35.438283925000064],
              [-80.881862264999938, 35.438264925000055],
              [-80.881911264999985, 35.438197925000054],
              [-80.881945264999956, 35.438146925000069],
              [-80.881967265999947, 35.438120925000078],
              [-80.882046264999985, 35.438023925000039],
              [-80.882140264999975, 35.437927924000064],
              [-80.882174264999946, 35.437890925000033],
              [-80.882202264999989, 35.437867924000045],
              [-80.882237264999958, 35.437831924000079],
              [-80.882294264999985, 35.437778925000032],
              [-80.882410264999976, 35.437645925000027],
              [-80.882449264999934, 35.437586925000062],
              [-80.882499264999979, 35.43752392500005],
              [-80.882532264999952, 35.43749392400008],
              [-80.882605264999938, 35.437412925000046],
              [-80.882631264999986, 35.437374925000029],
              [-80.882667264999952, 35.437328925000031],
              [-80.882696264999936, 35.437286925000024],
              [-80.882744265999975, 35.437223925000069],
              [-80.882782264999946, 35.437180924000074],
              [-80.882873264999944, 35.43711492500006],
              [-80.882902265999974, 35.437095925000051],
              [-80.882917264999946, 35.43707792500004],
              [-80.882960264999952, 35.437030925000045],
              [-80.882977264999965, 35.437009924000051],
              [-80.882997265999961, 35.43699192400004],
              [-80.883043264999969, 35.436927924000031],
              [-80.883063264999976, 35.43688992400007],
              [-80.883088264999969, 35.436850925000044],
              [-80.883110264999971, 35.436823925000056],
              [-80.883134265999956, 35.436802925000052],
              [-80.88316726499994, 35.436770924000029],
              [-80.883218264999982, 35.436709925000059],
              [-80.883243264999976, 35.436682924000024],
              [-80.883281264999937, 35.436647925000045],
              [-80.883312265999962, 35.436622924000062],
              [-80.883378264999976, 35.436534924000057],
              [-80.88339526599998, 35.43650292500007],
              [-80.883528265999985, 35.436391924000077],
              [-80.883565264999959, 35.43634292400003],
              [-80.883585264999965, 35.436306924000064],
              [-80.883606264999969, 35.436280924000073],
              [-80.883630263999976, 35.436256925000066],
              [-80.883667265999975, 35.436225924000041],
              [-80.883719265999957, 35.436168924000071],
              [-80.883748265999941, 35.436110924000047],
              [-80.883797265999988, 35.436040924000054],
              [-80.883834264999962, 35.436017924000055],
              [-80.883871264999982, 35.435983925000073],
              [-80.883947264999961, 35.43592592400006],
              [-80.883976264999944, 35.43588092400006],
              [-80.88401126499997, 35.435836924000057],
              [-80.884078264999971, 35.435757924000029],
              [-80.884097265999969, 35.435723924000058],
              [-80.884130264999953, 35.435654925000051],
              [-80.884147265999957, 35.435634925000045],
              [-80.884202265999988, 35.435579923000034],
              [-80.884267265999938, 35.43553092500008],
              [-80.884308264999959, 35.43549792400006],
              [-80.884370265999962, 35.435441925000077],
              [-80.884413265999967, 35.435396925000077],
              [-80.884463265999955, 35.435334924000074],
              [-80.88450626599996, 35.435269924000067],
              [-80.884565265999981, 35.435206925000045],
              [-80.884625265999944, 35.43515992500005],
              [-80.884677265999983, 35.435086924000075],
              [-80.884710265999956, 35.435057924000034],
              [-80.884747264999987, 35.435023924000063],
              [-80.884800265999957, 35.434962924000047],
              [-80.884854265999934, 35.434902924000028],
              [-80.884921265999935, 35.434815924000077],
              [-80.884947264999937, 35.434788924000031],
              [-80.884995265999976, 35.434731924000062],
              [-80.885031265999942, 35.434686924000061],
              [-80.885083265999981, 35.434628924000037],
              [-80.885133264999979, 35.434564924000028],
              [-80.88516326599995, 35.434523925000065],
              [-80.885174265999979, 35.434502924000071],
              [-80.88520026599997, 35.434464925000043],
              [-80.885224265999966, 35.43443192400008],
              [-80.885243266999964, 35.434360924000032],
              [-80.88524126599998, 35.434332924000046],
              [-80.885231265999948, 35.434287925000035],
              [-80.885218265999981, 35.434257923000075],
              [-80.885193265999987, 35.434212924000065],
              [-80.885184264999964, 35.434183924000024],
              [-80.885170264999942, 35.43410992400004],
              [-80.885160265999957, 35.434061924000048],
              [-80.885158265999962, 35.434040923000055],
              [-80.885162265999952, 35.434016924000048],
              [-80.885183265999956, 35.43394092300008],
              [-80.885183265999956, 35.433916924000073],
              [-80.885177265999971, 35.433818924000036],
              [-80.885176265999974, 35.433773924000036],
              [-80.885184265999953, 35.433695923000073],
              [-80.885184265999953, 35.433639924000033],
              [-80.885185265999951, 35.433564924000052],
              [-80.885190264999949, 35.433475924000049],
              [-80.885168265999937, 35.433372924000025],
              [-80.885189266999987, 35.43329992300005],
              [-80.885201265999967, 35.433264924000071],
              [-80.88521226499995, 35.433244923000075],
              [-80.885219265999979, 35.433218924000073],
              [-80.88521626499994, 35.433158924000054],
              [-80.885226265999961, 35.432939924000038],
              [-80.885227265999958, 35.432851923000044],
              [-80.885233265999943, 35.432743924000079],
              [-80.885210264999955, 35.432500923000077],
              [-80.885218264999935, 35.432392924000055],
              [-80.885232265999946, 35.432311923000043],
              [-80.885231264999959, 35.432260924000047],
              [-80.885220265999976, 35.432051923000074],
              [-80.885229264999964, 35.431976923000036],
              [-80.885234265999941, 35.431897923000065],
              [-80.88525726499995, 35.431857923000052],
              [-80.885262264999938, 35.431830924000053],
              [-80.885261265999986, 35.43174592400004],
              [-80.885254264999958, 35.431626923000067],
              [-80.885262264999938, 35.431560924000053],
              [-80.885244264999983, 35.431512923000071],
              [-80.885240264999936, 35.431446923000067],
              [-80.885242265999977, 35.431393924000076],
              [-80.88524926499997, 35.431322923000039],
              [-80.885266265999974, 35.431216923000079],
              [-80.885256264999953, 35.431126924000068],
              [-80.885238265999988, 35.431053924000025],
              [-80.885243264999986, 35.430943923000029],
              [-80.885265265999976, 35.430789923000077],
              [-80.885287264999988, 35.430656923000072],
              [-80.883766263999973, 35.42954392300004],
              [-80.883619264999936, 35.429492923000055],
              [-80.883513263999987, 35.42944192300007],
              [-80.883144264999942, 35.429198923000058],
              [-80.882935263999968, 35.429086922000067],
              [-80.882796263999978, 35.429022923000048],
              [-80.882678263999935, 35.428985924000074],
              [-80.882593264999969, 35.428962923000029],
              [-80.882089263999944, 35.428861923000056],
              [-80.88168826499998, 35.428786923000075],
              [-80.881106263999982, 35.428679922000072],
              [-80.88025126399998, 35.428535923000027],
              [-80.879991263999955, 35.428486923000037],
              [-80.87980926399996, 35.428486923000037],
              [-80.879684263999934, 35.428508923000038],
              [-80.879484262999938, 35.428601923000031],
              [-80.878897262999942, 35.428887923000048],
              [-80.878607263999982, 35.429024924000032],
              [-80.877776262999987, 35.429416923000076],
              [-80.87693126299996, 35.429810923000048],
              [-80.876112262999982, 35.428602923000028],
              [-80.876082262999944, 35.42856792300006],
              [-80.876040262999936, 35.428534924000076],
              [-80.875851261999969, 35.428411923000056],
              [-80.875748262999934, 35.428357923000078],
              [-80.875683262999985, 35.428449923000073],
              [-80.875547261999941, 35.428613924000047],
              [-80.875217262999968, 35.428979923000043],
              [-80.874576262999938, 35.42976892300004],
              [-80.874520261999976, 35.429657923000036],
              [-80.87407626199996, 35.42887892300007],
              [-80.873300261999987, 35.427514923000047],
              [-80.872914261999938, 35.426833923000061],
              [-80.871619260999978, 35.424574922000033],
              [-80.871271260999947, 35.42397492200007],
              [-80.871195260999968, 35.423860922000074],
              [-80.871120260999987, 35.423766922000027],
              [-80.871037260999969, 35.423676923000073],
              [-80.870600261999982, 35.42328092200006],
              [-80.870459260999951, 35.423151923000034],
              [-80.870402260999981, 35.42310092200006],
              [-80.869908259999988, 35.422664922000024],
              [-80.869850260999954, 35.422711922000076],
              [-80.869704260999981, 35.422812922000048],
              [-80.869594260999975, 35.422894923000058],
              [-80.869511259999967, 35.422931922000032],
              [-80.86946525999997, 35.422946922000051],
              [-80.86932225999999, 35.423007922000068],
              [-80.869205259999944, 35.42304792200008],
              [-80.869046260999937, 35.423114922000025],
              [-80.868938259999936, 35.423173923000036],
              [-80.868821259999947, 35.42322792300007],
              [-80.868629260999967, 35.42332992200005],
              [-80.868510260999983, 35.423414922000063],
              [-80.868461258999957, 35.42347592200008],
              [-80.868383259999973, 35.423550922000061],
              [-80.868269259999977, 35.423677922000024],
              [-80.868139260999953, 35.423799923000047],
              [-80.86807325999996, 35.423850922000042],
              [-80.868059259999939, 35.423869923000041],
              [-80.868039259999989, 35.423936922000053],
              [-80.868023260999962, 35.423950923000064],
              [-80.867958259999966, 35.423972923000065],
              [-80.86793425999997, 35.423985922000043],
              [-80.867921259999946, 35.424000922000062],
              [-80.867912259999969, 35.424025923000045],
              [-80.867908260999968, 35.424057922000031],
              [-80.867891260999954, 35.424093922000054],
              [-80.867853259999947, 35.424128923000069],
              [-80.867804259999957, 35.424160923000045],
              [-80.867700259999935, 35.424199922000071],
              [-80.867655259999935, 35.424233922000042],
              [-80.867609259999938, 35.424295922000056],
              [-80.86752125999999, 35.424387922000051],
              [-80.867487259999962, 35.424429922000058],
              [-80.867466258999968, 35.424472922000064],
              [-80.86744325899997, 35.424493923000057],
              [-80.867344259999982, 35.424559923000061],
              [-80.867312259999949, 35.42459492200004],
              [-80.867286260999947, 35.424645923000071],
              [-80.867278259999978, 35.424671922000073],
              [-80.867222259999949, 35.424755922000031],
              [-80.86715425899996, 35.424820923000027],
              [-80.867121259999976, 35.42486092200005],
              [-80.867021259999945, 35.424935923000078],
              [-80.866961258999936, 35.424967923000054],
              [-80.866920258999983, 35.425008923000064],
              [-80.866840259999947, 35.425069922000034],
              [-80.866774259999943, 35.425133922000043],
              [-80.866690259999984, 35.425241923000044],
              [-80.866655259999959, 35.425277923000067],
              [-80.866469259999974, 35.42540792300008],
              [-80.866324259999942, 35.425547923000067],
              [-80.866264258999934, 35.425617923000061],
              [-80.866246259999969, 35.425629923000031],
              [-80.866143259999944, 35.425674923000031],
              [-80.86605925899994, 35.425774923000063],
              [-80.866002259999959, 35.425821923000058],
              [-80.865920259999939, 35.425875923000035],
              [-80.865696258999947, 35.426073923000047],
              [-80.865643258999967, 35.426097923000043],
              [-80.865381258999946, 35.426292923000062],
              [-80.865013259999955, 35.425669923000044],
              [-80.864908258999947, 35.425463923000052],
              [-80.864818258999946, 35.425326923000057],
              [-80.864343257999963, 35.424510923000071],
              [-80.863406258999987, 35.422934922000024],
              [-80.863185257999987, 35.422554922000074],
              [-80.862778258999981, 35.421852922000028],
              [-80.862699258999953, 35.42170892300004],
              [-80.86300125799994, 35.421605922000026],
              [-80.863858257999937, 35.421285922000038],
              [-80.864313257999981, 35.421108921000041],
              [-80.865050258999986, 35.420819921000032],
              [-80.865272259999983, 35.420011922000072],
              [-80.865347258999975, 35.419759921000036],
              [-80.865369258999976, 35.419698921000077],
              [-80.865408259999981, 35.419610921000071],
              [-80.865455258999987, 35.419523922000053],
              [-80.865539258999945, 35.419390922000048],
              [-80.86561925999996, 35.419283922000034],
              [-80.865735258999962, 35.419151922000026],
              [-80.865831258999947, 35.419062921000034],
              [-80.866069259999961, 35.418878921000044],
              [-80.866624258999934, 35.418497921000039],
              [-80.867214258999979, 35.418092921000039],
              [-80.868695259999981, 35.417051921000052],
              [-80.869499259999941, 35.416482921000068],
              [-80.869750259999989, 35.416301920000024],
              [-80.870168260999947, 35.416001920000042],
              [-80.870998260999954, 35.415417920000039],
              [-80.871109260999958, 35.415336920000072],
              [-80.871301259999939, 35.415197920000026],
              [-80.871470260999956, 35.415061920000028],
              [-80.871723260999943, 35.414830920000043],
              [-80.872393260999957, 35.414143921000061],
              [-80.872671260999937, 35.413875920000066],
              [-80.873269260999962, 35.413286920000075],
              [-80.873359260999962, 35.413176920000069],
              [-80.873443261999967, 35.413047920000054],
              [-80.873549259999947, 35.41283892000007],
              [-80.873719261999952, 35.412438920000056],
              [-80.874005260999979, 35.411702919000049],
              [-80.87417826099994, 35.411300919000041],
              [-80.874262261999945, 35.411092919000055],
              [-80.874454260999983, 35.410642919000054],
              [-80.874494261999985, 35.410547919000066],
              [-80.875089260999971, 35.409157919000052],
              [-80.87513826199995, 35.409025918000054],
              [-80.875166260999947, 35.408928919000061],
              [-80.875179260999971, 35.408811919000073],
              [-80.875176260999979, 35.408740919000024],
              [-80.875162261999947, 35.408644919000039],
              [-80.875089260999971, 35.408261918000051],
              [-80.874954260999971, 35.407765919000042],
              [-80.87480726199999, 35.407254918000035],
              [-80.874719260999939, 35.406859918000066],
              [-80.874689261999947, 35.406540919000065],
              [-80.874675260999936, 35.406294919000061],
              [-80.874676260999934, 35.406260918000044],
              [-80.874384260999989, 35.40622391800008],
              [-80.873939260999975, 35.40613391800008],
              [-80.87357326099999, 35.405977918000076],
              [-80.873253260999945, 35.405780918000062],
              [-80.873120259999951, 35.405698918000041],
              [-80.872023260999981, 35.405025918000035],
              [-80.871820259999936, 35.404894918000025],
              [-80.871642259999987, 35.404779919000077],
              [-80.871244260999958, 35.404597918000036],
              [-80.870751259999963, 35.404487918000029],
              [-80.870378259999939, 35.404474918000062],
              [-80.869925259999945, 35.404552918000036],
              [-80.869581258999972, 35.404654918000062],
              [-80.869580259999964, 35.404654918000062],
              [-80.869486258999984, 35.404670918000079],
              [-80.869373259999975, 35.404706918000045],
              [-80.869343258999947, 35.404448918000071],
              [-80.869360258999961, 35.404152918000079],
              [-80.869384258999958, 35.403960918000053],
              [-80.869508258999986, 35.402689918000078],
              [-80.869524258999945, 35.402506918000029],
              [-80.869667258999982, 35.401284918000044],
              [-80.869673258999967, 35.40120091700004],
              [-80.869682259999934, 35.401077917000066],
              [-80.869670258999975, 35.400930917000039],
              [-80.86961225899995, 35.400716917000068],
              [-80.869453258999954, 35.400280918000078],
              [-80.869347259999984, 35.399965917000031],
              [-80.86933025899998, 35.399895917000038],
              [-80.869323258999941, 35.399831918000075],
              [-80.869327258999988, 35.399725917000069],
              [-80.869353259999968, 35.399605917000031],
              [-80.869378258999973, 35.39955291800004],
              [-80.86832925799996, 35.399005917000068],
              [-80.867964258999962, 35.398823917000072],
              [-80.867874257999972, 35.398780917000067],
              [-80.867493257999968, 35.398600918000056],
              [-80.867241257999979, 35.398481917000026],
              [-80.866540257999986, 35.398139917000037],
              [-80.86610025799996, 35.397940917000028],
              [-80.865858257999946, 35.397847917000036],
              [-80.865745257999947, 35.397809917000075],
              [-80.865585257999953, 35.397766917000069],
              [-80.865190257999984, 35.397686917000044],
              [-80.865097257999935, 35.397672916000033],
              [-80.864823257999944, 35.397651917000076],
              [-80.864328257999944, 35.39762491700003],
              [-80.864032256999963, 35.39761291700006],
              [-80.864086257999986, 35.396638916000029],
              [-80.864100256999961, 35.396377917000052],
              [-80.864126256999953, 35.396095917000025],
              [-80.864267256999938, 35.39544791600008],
              [-80.864631257999974, 35.394380916000046],
              [-80.864721257999975, 35.394060915000068],
              [-80.86473925699994, 35.39396191700007],
              [-80.864754256999959, 35.393817916000046],
              [-80.864758257999938, 35.393691915000034],
              [-80.864753256999961, 35.393584916000066],
              [-80.864740256999937, 35.393462916000033],
              [-80.864717256999938, 35.393336916000067],
              [-80.864683257999957, 35.393208916000049],
              [-80.864634257999967, 35.393070916000056],
              [-80.864583257999982, 35.392954915000075],
              [-80.864512256999944, 35.392821916000059],
              [-80.864437257999953, 35.392702915000029],
              [-80.864338256999986, 35.392571916000065],
              [-80.864249256999983, 35.392468916000041],
              [-80.864149256999951, 35.392366916000071],
              [-80.864040256999942, 35.392269916000032],
              [-80.863932257999977, 35.392184916000076],
              [-80.863799255999936, 35.392092916000024],
              [-80.863666255999988, 35.392012915000066],
              [-80.863520256999948, 35.391936916000077],
              [-80.86337625699997, 35.391871916000071],
              [-80.863222256999961, 35.391813916000046],
              [-80.863078256999984, 35.391768916000046],
              [-80.862935256999947, 35.391730916000029],
              [-80.862781256999938, 35.391698916000053],
              [-80.862625256999934, 35.391675916000054],
              [-80.862437255999964, 35.391657916000042],
              [-80.862290256999984, 35.391651915000068],
              [-80.862144256999954, 35.391653916000053],
              [-80.862006255999972, 35.391660916000035],
              [-80.861830255999962, 35.391679916000044],
              [-80.86170225699999, 35.391699917000039],
              [-80.861564255999951, 35.391728916000034],
              [-80.861418255999979, 35.391765916000054],
              [-80.861299255999938, 35.391802915000028],
              [-80.86115525699995, 35.391854916000057],
              [-80.861007256999983, 35.391918915000076],
              [-80.860898255999984, 35.391971916000045],
              [-80.860748255999965, 35.392055915000071],
              [-80.860618254999963, 35.392140916000074],
              [-80.860496255999976, 35.392230915000027],
              [-80.860388255999965, 35.392321917000061],
              [-80.860293255999977, 35.392412916000069],
              [-80.860268254999937, 35.39243891600006],
              [-80.860223256999973, 35.392486916000053],
              [-80.860114255999974, 35.392420916000049],
              [-80.858470254999986, 35.39142191600007],
              [-80.85843125599996, 35.391404916000056],
              [-80.858229254999969, 35.39132491600003],
              [-80.858019255999977, 35.391258915000037],
              [-80.857804254999962, 35.391208916000039],
              [-80.857584254999949, 35.391173916000071],
              [-80.857361254999944, 35.391154915000072],
              [-80.857137254999941, 35.391152916000067],
              [-80.856792254999959, 35.391169916000024],
              [-80.855460253999979, 35.391236915000036],
              [-80.855135254999936, 35.391226916000051],
              [-80.854840254999942, 35.391204916000049],
              [-80.854547253999954, 35.391169916000024],
              [-80.854160253999964, 35.391103916000077],
              [-80.854135253999971, 35.391206916000044],
              [-80.853990252999949, 35.391694916000063],
              [-80.853956253999968, 35.391781915000024],
              [-80.853744253999935, 35.392241916000046],
              [-80.853436254999963, 35.392829917000029],
              [-80.853150252999967, 35.393362916000058],
              [-80.85308125399996, 35.393481917000031],
              [-80.853231253999979, 35.393731916000036],
              [-80.853614253999979, 35.394330917000048],
              [-80.854202253999972, 35.395249917000058],
              [-80.854525253999952, 35.39576691700006],
              [-80.854873254999973, 35.39638291600005],
              [-80.855252254999982, 35.397105917000033],
              [-80.855562254999938, 35.397749917000056],
              [-80.855622254999957, 35.397889917000043],
              [-80.855803254999955, 35.39831491700005],
              [-80.855923254999936, 35.398612918000026],
              [-80.856048254999962, 35.398939917000064],
              [-80.856208255999945, 35.399384917000077],
              [-80.85639325599999, 35.399940917000038],
              [-80.856632255999955, 35.400816918000032],
              [-80.85669025499999, 35.401043918000028],
              [-80.856812255999955, 35.401604918000032],
              [-80.85687825499997, 35.401941918000034],
              [-80.857004255999982, 35.40271191800008],
              [-80.857083254999964, 35.403099919000056],
              [-80.857290255999942, 35.404263918000026],
              [-80.857346255999971, 35.404580919000068],
              [-80.857382255999937, 35.404980918000035],
              [-80.857522255999982, 35.405869919000054],
              [-80.857607256999984, 35.406357919000072],
              [-80.857740255999943, 35.407182919000036],
              [-80.857819255999971, 35.407643919000066],
              [-80.85795825699995, 35.408444919000033],
              [-80.858044254999982, 35.408945919000075],
              [-80.857413256999962, 35.409146920000069],
              [-80.856911255999989, 35.409306920000063],
              [-80.856401256999959, 35.409446919000061],
              [-80.856366254999955, 35.409455919000038],
              [-80.855477255999972, 35.409700919000045],
              [-80.855334254999946, 35.40973991900006],
              [-80.85487425599996, 35.40987092000006],
              [-80.85482125599998, 35.409697920000042],
              [-80.854754254999989, 35.40942891900005],
              [-80.854732254999988, 35.409339920000036],
              [-80.854582254999968, 35.408855919000075],
              [-80.854370254999935, 35.408221919000027],
              [-80.854020254999966, 35.407157919000042],
              [-80.85357225499996, 35.405747919000078],
              [-80.851767253999981, 35.399958918000038],
              [-80.851251252999987, 35.39832291700003],
              [-80.851161253999976, 35.39844591800005],
              [-80.851127253999948, 35.398490917000061],
              [-80.850895253999965, 35.398764917000051],
              [-80.850487253999972, 35.399212917000057],
              [-80.848718253999948, 35.401166919000048],
              [-80.847918252999989, 35.402049919000035],
              [-80.847743252999976, 35.402228919000038],
              [-80.847571251999966, 35.402387918000045],
              [-80.847364252999967, 35.402550918000031],
              [-80.847257252999952, 35.402623918000074],
              [-80.847131252999986, 35.402701918000048],
              [-80.846965252999951, 35.402792919000035],
              [-80.846764252999947, 35.402885919000028],
              [-80.846285252999962, 35.403059919000043],
              [-80.845573251999951, 35.403297919000067],
              [-80.845309250999946, 35.40339491900005],
              [-80.843974251999953, 35.403872919000037],
              [-80.843028250999964, 35.404208919000041],
              [-80.841621250999935, 35.40470891800004],
              [-80.841506250999942, 35.404753919000029],
              [-80.841298250999955, 35.404844918000038],
              [-80.839944250999963, 35.405288919000043],
              [-80.83976125099997, 35.405346920000056],
              [-80.839662249999947, 35.405373919000056],
              [-80.839680250999947, 35.405468919000043],
              [-80.840026249999937, 35.406843919000039],
              [-80.840072250999981, 35.407026920000078],
              [-80.840329250999957, 35.408107920000077],
              [-80.840595250999968, 35.409230921000074],
              [-80.840751250999972, 35.409885921000068],
              [-80.840984250999952, 35.410937921000027],
              [-80.841407251999954, 35.410901921000061],
              [-80.842817250999985, 35.410809921000066],
              [-80.842953251999973, 35.410808921000068],
              [-80.842968252999981, 35.410943920000079],
              [-80.843002251999962, 35.41143892100007],
              [-80.843163252999943, 35.413303922000068],
              [-80.843197251999982, 35.413796921000028],
              [-80.843202251999969, 35.413961921000066],
              [-80.84317925299996, 35.414287921000039],
              [-80.843177251999975, 35.414308921000043],
              [-80.843140251999955, 35.414547922000054],
              [-80.843111251999972, 35.414668922000033],
              [-80.842929252999966, 35.415274922000037],
              [-80.842874251999945, 35.415444921000073],
              [-80.842828251999947, 35.415585922000048],
              [-80.842634252999972, 35.416187922000063],
              [-80.842335252999987, 35.417110923000052],
              [-80.842641251999964, 35.417186923000031],
              [-80.842809251999938, 35.417223922000062],
              [-80.843082251999988, 35.417272921000063],
              [-80.843246251999972, 35.417290921000074],
              [-80.843480251999949, 35.417303923000077],
              [-80.843724252999948, 35.417301922000036],
              [-80.844223251999949, 35.417254922000041],
              [-80.844386251999936, 35.417232922000039],
              [-80.844500251999989, 35.417217922000077],
              [-80.84461225299998, 35.417208922000043],
              [-80.844724251999935, 35.417205922000051],
              [-80.844923252999934, 35.417206922000048],
              [-80.845601252999984, 35.417243922000068],
              [-80.845793252999954, 35.417246922000061],
              [-80.845966253999961, 35.417233922000037],
              [-80.846077252999976, 35.41721392200003],
              [-80.846209252999984, 35.417178922000062],
              [-80.846410252999988, 35.417104922000078],
              [-80.846871252999961, 35.416919922000034],
              [-80.846998253999971, 35.416865921000067],
              [-80.847169252999947, 35.416792921000024],
              [-80.84726225299994, 35.416737922000038],
              [-80.84738925399995, 35.41663292100003],
              [-80.848989253999946, 35.416649922000033],
              [-80.848991253999941, 35.416876921000039],
              [-80.848991253999941, 35.416912922000051],
              [-80.848980253999969, 35.417058922000024],
              [-80.848955254999964, 35.41716892200003],
              [-80.848907253999982, 35.417282922000027],
              [-80.84887525399995, 35.417335922000063],
              [-80.848793254999975, 35.417437922000033],
              [-80.848745253999937, 35.417484922000028],
              [-80.84853325399996, 35.417643921000035],
              [-80.848260252999978, 35.418122922000066],
              [-80.848544253999989, 35.418122922000066],
              [-80.848710253999968, 35.418087921000051],
              [-80.848845253999968, 35.418029921000027],
              [-80.848976253999979, 35.418019922000042],
              [-80.84919025399995, 35.417939922000073],
              [-80.849486253999942, 35.417837921000057],
              [-80.849691253999936, 35.417739922000067],
              [-80.849800254999934, 35.417700921000062],
              [-80.849842253999952, 35.417690922000077],
              [-80.849895253999989, 35.417659921000052],
              [-80.85011725399994, 35.417564922000054],
              [-80.85018325499999, 35.417544921000058],
              [-80.850322253999934, 35.418050921000031],
              [-80.850335254999948, 35.418066922000037],
              [-80.850351253999975, 35.418104922000055],
              [-80.850352253999972, 35.418153922000045],
              [-80.850325253999983, 35.418238922000057],
              [-80.850238253999976, 35.418435922000072],
              [-80.850169254999969, 35.41857592100007],
              [-80.84979025399997, 35.419407922000062],
              [-80.849753254999939, 35.419494922000069],
              [-80.849722253999971, 35.419587921000073],
              [-80.849705254999947, 35.419672922000075],
              [-80.849701253999967, 35.419796922000046],
              [-80.849709253999947, 35.419912922000037],
              [-80.84974525399997, 35.420245922000049],
              [-80.849785253999983, 35.420407922000038],
              [-80.849804253999935, 35.420456922000028],
              [-80.849839254999949, 35.420519923000029],
              [-80.85019525499996, 35.420948922000036],
              [-80.850264253999967, 35.421038922000037],
              [-80.850340253999946, 35.421168922000049],
              [-80.85037125499997, 35.421251922000067],
              [-80.850387254999987, 35.42133692200008],
              [-80.850470254999948, 35.42198292300003],
              [-80.849339253999972, 35.422075923000079],
              [-80.849238253999943, 35.422084922000067],
              [-80.84876925399999, 35.422130922000065],
              [-80.848621254999955, 35.42213292200006],
              [-80.848607253999944, 35.422258923000072],
              [-80.848552253999969, 35.422569923000026],
              [-80.848518253999941, 35.422668922000071],
              [-80.848468253999954, 35.422763923000048],
              [-80.848291253999946, 35.423007923000057],
              [-80.847906252999962, 35.423547922000068],
              [-80.847842253999943, 35.423610923000069],
              [-80.847760253999979, 35.42366792200005],
              [-80.847680253999954, 35.423701923000067],
              [-80.847331253999982, 35.423807923000027],
              [-80.847255253999947, 35.423845923000044],
              [-80.847189253999943, 35.423894923000034],
              [-80.847137253999961, 35.423954923000053],
              [-80.84709625399995, 35.424029923000035],
              [-80.847071253999957, 35.424104923000073],
              [-80.847062254999969, 35.424174923000066],
              [-80.847064253999974, 35.42428592300007],
              [-80.847047252999971, 35.424369923000029],
              [-80.847011252999948, 35.424445923000064],
              [-80.846960253999953, 35.424506923000024],
              [-80.846879253999987, 35.424577923000072],
              [-80.84678525399994, 35.424634923000042],
              [-80.846741252999948, 35.424651924000045],
              [-80.846780253999952, 35.424848924000059],
              [-80.846838252999987, 35.425251923000076],
              [-80.846958253999958, 35.425936924000041],
              [-80.848501253999984, 35.425778923000053],
              [-80.848659253999983, 35.425752923000061],
              [-80.848817254999972, 35.425708924000048],
              [-80.848920254999939, 35.425674923000031],
              [-80.851376254999934, 35.42488992400007],
              [-80.852394254999979, 35.424563923000051],
              [-80.854956256999969, 35.423759923000034],
              [-80.855051255999967, 35.423729923000053],
              [-80.855246256999976, 35.423669923000034],
              [-80.856335256999955, 35.423332922000043],
              [-80.857144256999959, 35.423122922000061],
              [-80.857680255999981, 35.422991923000041],
              [-80.858662256999935, 35.422752922000029],
              [-80.859637257999964, 35.422521922000044],
              [-80.861272257999985, 35.425813922000032],
              [-80.862235258999988, 35.427743923000037],
              [-80.862493257999972, 35.428265923000026],
              [-80.863062258999946, 35.429417923000074],
              [-80.86377725899996, 35.430865924000045],
              [-80.863885258999971, 35.43108492500005],
              [-80.864142258999948, 35.431656925000027],
              [-80.864400259999968, 35.43231292400003],
              [-80.864643258999934, 35.43305192400004],
              [-80.864826259999973, 35.433726924000041],
              [-80.864893259999974, 35.434042924000039],
              [-80.864980258999935, 35.434455925000066],
              [-80.865093259999981, 35.435199925000063],
              [-80.865246259999935, 35.436462925000058],
              [-80.865307259999952, 35.436964925000041],
              [-80.865347258999975, 35.437410925000052],
              [-80.865358259999937, 35.437594925000042],
              [-80.865353259999949, 35.438257925000073],
              [-80.86534525999997, 35.43850692500007],
              [-80.865317259999983, 35.439036926000028],
              [-80.865307259999952, 35.439227926000058],
              [-80.865296260999969, 35.439590926000051],
              [-80.865297260999967, 35.439890925000043],
              [-80.865322259999971, 35.440555926000059],
              [-80.865342259999977, 35.440886925000029],
              [-80.86538625999998, 35.441413926000052],
              [-80.865449259999934, 35.441956926000046],
              [-80.865492260999986, 35.442232926000031],
              [-80.863250259999973, 35.442078926000079],
              [-80.859993258999964, 35.441852926000024],
              [-80.858597258999964, 35.441755927000031],
              [-80.857328257999939, 35.441668926000034],
              [-80.856521257999987, 35.441614926000057],
              [-80.854474256999936, 35.441473927000061],
              [-80.853451256999961, 35.441404926000075],
              [-80.852713256999948, 35.441354926000031],
              [-80.852610255999934, 35.441348927000035],
              [-80.852582256999938, 35.441572926000049],
              [-80.852566255999989, 35.441750927000044],
              [-80.852570255999979, 35.441911927000035],
              [-80.852589255999987, 35.442111927000042],
              [-80.852601256999947, 35.442195927000057],
              [-80.852623255999958, 35.443159927000067],
              [-80.852616255999976, 35.443572927000048],
              [-80.852571256999965, 35.443793927000058],
              [-80.852482256999963, 35.443966927000076],
              [-80.852417255999967, 35.444056928000066],
              [-80.85248325699996, 35.444095927000035],
              [-80.852563256999986, 35.444125927000073],
              [-80.852630256999987, 35.444140927000035],
              [-80.852700255999935, 35.444147927000074],
              [-80.855178256999977, 35.444178928000042],
              [-80.85519725599994, 35.444349927000076],
              [-80.855199257999971, 35.445080927000049],
              [-80.855204256999968, 35.445254927000065],
              [-80.855224257999964, 35.445377927000038],
              [-80.855256256999951, 35.445474927000078],
              [-80.855314256999975, 35.445621928000037],
              [-80.855380256999979, 35.445765927000025],
              [-80.855456257999947, 35.445864927000059],
              [-80.855574256999944, 35.446007928000029],
              [-80.855263257999979, 35.446212927000033],
              [-80.855160256999966, 35.446284928000068],
              [-80.855074257999945, 35.446362928000042],
              [-80.85501925799997, 35.446427927000059],
              [-80.854963256999952, 35.446503927000037],
              [-80.854940257999942, 35.446534928000062],
              [-80.85461825699997, 35.447137928000075],
              [-80.85453625699995, 35.447115928000073],
              [-80.854418256999963, 35.44709492800007],
              [-80.854147256999966, 35.447061928000039],
              [-80.853776256999936, 35.447024928000076],
              [-80.85373225699999, 35.447490928000036],
              [-80.853700256999957, 35.447755928000049],
              [-80.853661256999942, 35.448174928000071],
              [-80.853616256999942, 35.448396928000079],
              [-80.853575257999978, 35.448495928000057],
              [-80.853528256999937, 35.448592928000039],
              [-80.853430256999957, 35.448774929000024],
              [-80.853293256999962, 35.448999928000035],
              [-80.854345257999967, 35.449407928000028],
              [-80.854818257999966, 35.449584928000036],
              [-80.855016257999978, 35.449668928000051],
              [-80.855104257999983, 35.44972492800008],
              [-80.855215257999987, 35.449812928000028],
              [-80.855345257999943, 35.449895928000046],
              [-80.855382257999963, 35.449913928000058],
              [-80.855412256999955, 35.449925928000027],
              [-80.855624257999978, 35.449983928000051],
              [-80.855693256999984, 35.450000929000055],
              [-80.855813257999955, 35.450031928000044],
              [-80.855911257999935, 35.450066929000059],
              [-80.856013257999962, 35.45013792900005],
              [-80.856137256999943, 35.450249928000062],
              [-80.856218257999956, 35.450301929000034],
              [-80.856310256999961, 35.450347928000042],
              [-80.856406257999936, 35.450380928000072],
              [-80.856574257999966, 35.45041892900008],
              [-80.856712258999949, 35.450440928000035],
              [-80.856782257999953, 35.450450929000056],
              [-80.856887258999961, 35.450465928000028],
              [-80.857116257999962, 35.450498928000059],
              [-80.85724025899998, 35.450519928000062],
              [-80.85733825899996, 35.450548929000036],
              [-80.857604257999981, 35.450653928000065],
              [-80.857849257999987, 35.450750929000037],
              [-80.858094258999984, 35.450847929000076],
              [-80.858181257999945, 35.450881928000058],
              [-80.858353258999955, 35.450950928000054],
              [-80.858479258999978, 35.451000929000031],
              [-80.858611257999939, 35.451052928000024],
              [-80.858696258999942, 35.451096929000073],
              [-80.858773258999975, 35.451179929000034],
              [-80.858801258999961, 35.451238928000066],
              [-80.858810257999949, 35.451281928000071],
              [-80.858809258999941, 35.451354929000047],
              [-80.858779258999959, 35.451432929000077],
              [-80.858701258999986, 35.45157492900006],
              [-80.858661258999973, 35.451642929000059],
              [-80.858633257999941, 35.451681929000074],
              [-80.858556258999954, 35.451771928000028],
              [-80.858384258999934, 35.451905929000077],
              [-80.858078258999967, 35.452146928000047],
              [-80.858069257999944, 35.452154929000073],
              [-80.857483257999945, 35.452600929000027],
              [-80.857132257999979, 35.452836930000046],
              [-80.856637257999978, 35.452420929000027],
              [-80.856683258999965, 35.452356929000075],
              [-80.856711258999951, 35.452273929000057],
              [-80.85669725799994, 35.452191929000037],
              [-80.85664825899994, 35.452109929000073],
              [-80.856581257999949, 35.452052929000047],
              [-80.856498258999977, 35.452024928000071],
              [-80.856379257999947, 35.452031929000043],
              [-80.856267257999946, 35.452062928000032],
              [-80.856197257999952, 35.452107929000078],
              [-80.856157257999939, 35.452165929000046],
              [-80.855730257999937, 35.452085929000077],
              [-80.855585258999952, 35.452058928000042],
              [-80.855187257999944, 35.45199192900003],
              [-80.855050257999949, 35.451979929000061],
              [-80.854902257999981, 35.451990929000033],
              [-80.854765257999986, 35.452013929000032],
              [-80.854678256999989, 35.452028929000051],
              [-80.853791257999944, 35.452158928000074],
              [-80.852060256999948, 35.452437929000041],
              [-80.851930256999935, 35.452463929000032],
              [-80.851801256999977, 35.452508928000043],
              [-80.851653255999963, 35.452607929000067],
              [-80.851566256999945, 35.452543929000058],
              [-80.851540255999964, 35.452523930000041],
              [-80.851463256999978, 35.452486928000042],
              [-80.851378255999975, 35.452467928000033],
              [-80.851284256999975, 35.452461929000037],
              [-80.851194256999975, 35.452491929000075],
              [-80.851138256999945, 35.452533929000026],
              [-80.851101255999936, 35.452578929000026],
              [-80.851078256999983, 35.452626928000029],
              [-80.85054725599997, 35.452745929000059],
              [-80.850024256999973, 35.452870929000028],
              [-80.850016255999947, 35.452899929000068],
              [-80.849961255999972, 35.45300792900008],
              [-80.849910256999976, 35.453061929000057],
              [-80.849882255999944, 35.453092929000036],
              [-80.849770255999942, 35.453169929000069],
              [-80.849647255999969, 35.453233929000078],
              [-80.849496255999952, 35.453275930000075],
              [-80.849070255999948, 35.453340929000035],
              [-80.848336255999982, 35.45345793000007],
              [-80.848125255999946, 35.453503929000078],
              [-80.848028255999964, 35.453352929000062],
              [-80.847886254999935, 35.453129929000056],
              [-80.847750254999937, 35.452917928000033],
              [-80.84768825499998, 35.452793930000041],
              [-80.847608255999944, 35.452617929000041],
              [-80.847480254999937, 35.452379929000074],
              [-80.84718225499995, 35.451834929000029],
              [-80.847083254999973, 35.451690929000051],
              [-80.847010254999987, 35.451744930000075],
              [-80.846825255999988, 35.451801930000045],
              [-80.846688254999947, 35.451816929000074],
              [-80.846540254999979, 35.451796929000068],
              [-80.846504254999957, 35.451785929000039],
              [-80.846372254999949, 35.451744930000075],
              [-80.846315254999979, 35.451726929000074],
              [-80.846150254999941, 35.451665929000058],
              [-80.845653254999945, 35.451497929000027],
              [-80.845499255999982, 35.451451930000076],
              [-80.84533225499996, 35.451407929000027],
              [-80.845129254999961, 35.45136192800004],
              [-80.844865254999945, 35.451312929000039],
              [-80.844068254999968, 35.451184929000078],
              [-80.843214253999975, 35.451028929000074],
              [-80.842840254999942, 35.453674930000034],
              [-80.842820253999946, 35.453806930000042],
              [-80.842783253999983, 35.454058930000031],
              [-80.842724253999961, 35.454481930000043],
              [-80.842618253999944, 35.455296930000031],
              [-80.842592253999953, 35.45564193000007],
              [-80.842588253999963, 35.45569092900007],
              [-80.842589253999961, 35.456134930000076],
              [-80.842619253999942, 35.456561930000078],
              [-80.842634253999961, 35.456697930000075],
              [-80.842678253999964, 35.457012930000076],
              [-80.84273525399999, 35.457280931000071],
              [-80.842848253999989, 35.45770493100008],
              [-80.842910253999946, 35.457892930000071],
              [-80.843012253999973, 35.458178931000077],
              [-80.843200254999942, 35.458610931000067],
              [-80.84337725499995, 35.45896993100007],
              [-80.84348225399998, 35.459162930000048],
              [-80.843629255999986, 35.459414930000037],
              [-80.844000254999969, 35.45999893100003],
              [-80.844372255999986, 35.460569931000066],
              [-80.84449525499997, 35.460758931000044],
              [-80.845495254999946, 35.46229393200008],
              [-80.845847255999956, 35.462834931000032],
              [-80.846271254999976, 35.463486931000034],
              [-80.847334255999954, 35.465122933000032],
              [-80.847710255999971, 35.465691932000027],
              [-80.847907255999985, 35.466006932000028],
              [-80.849149256999965, 35.467921932000024],
              [-80.849564255999951, 35.468551933000072],
              [-80.849676257999988, 35.468724932000043],
              [-80.849782256999958, 35.468888932000027],
              [-80.849545256999988, 35.469029933000058],
              [-80.849203256999942, 35.469234933000052],
              [-80.843787254999938, 35.471022934000075],
              [-80.84231925499995, 35.471506933000057],
              [-80.840337253999962, 35.472132934000058],
              [-80.833587252999962, 35.474260934000029],
              [-80.831405250999978, 35.474927935000039],
              [-80.830715251999948, 35.475137935000078],
              [-80.830558251999946, 35.475184935000073],
              [-80.83033825199999, 35.478319936000048],
              [-80.830418250999969, 35.47832293600004],
              [-80.830765250999946, 35.478340936000052],
              [-80.831029251999951, 35.478345936000039],
              [-80.831235251999942, 35.478332935000026],
              [-80.831499251999958, 35.478338936000057],
              [-80.831664251999939, 35.478357935000076],
              [-80.831924251999965, 35.478354935000027],
              [-80.832038252999951, 35.478360935000069],
              [-80.832302251999977, 35.478342935000057],
              [-80.832478251999987, 35.478356935000079],
              [-80.832632252999986, 35.478379935000078],
              [-80.832700252999985, 35.478400936000071],
              [-80.832781252999951, 35.478447935000077],
              [-80.832847251999965, 35.478492935000077],
              [-80.832994252999981, 35.478575935000038],
              [-80.833080252999935, 35.478643935000036],
              [-80.83316425199996, 35.47871693500008],
              [-80.833297251999966, 35.478797935000046],
              [-80.833541252999964, 35.478962936000073],
              [-80.833605252999973, 35.478997935000052],
              [-80.833794252999951, 35.479139936000024],
              [-80.833934253999985, 35.479229935000035],
              [-80.834100252999974, 35.47936293500004],
              [-80.834142252999982, 35.479408935000038],
              [-80.834188252999979, 35.479440935000071],
              [-80.834261252999966, 35.479482936000068],
              [-80.834298252999986, 35.479513936000046],
              [-80.834367252999982, 35.47954693500003],
              [-80.834408251999946, 35.479584935000048],
              [-80.834490252999956, 35.479673935000051],
              [-80.834550252999975, 35.47971093600006],
              [-80.834601253999949, 35.479751935000024],
              [-80.83465025299995, 35.47977893500007],
              [-80.834706252999979, 35.479829935000055],
              [-80.835018253999976, 35.480058935000045],
              [-80.835160253999959, 35.480196936000027],
              [-80.835272252999971, 35.480261935000044],
              [-80.835304252999947, 35.480287936000025],
              [-80.835354252999934, 35.480306936000034],
              [-80.835423252999988, 35.480313936000073],
              [-80.83548325299995, 35.480335935000028],
              [-80.835545252999964, 35.480351936000034],
              [-80.835595253999941, 35.480374935000043],
              [-80.835649253999975, 35.480409936000058],
              [-80.835908253999946, 35.480552935000048],
              [-80.835950252999965, 35.480586936000066],
              [-80.835994252999967, 35.480608935000078],
              [-80.836054252999986, 35.480623936000029],
              [-80.836130253999954, 35.480676936000066],
              [-80.836216253999964, 35.480712935000042],
              [-80.836507253999969, 35.480875936000075],
              [-80.836553253999966, 35.480908936000048],
              [-80.836609253999939, 35.480969936000065],
              [-80.836808253999948, 35.481168936000074],
              [-80.83684825399996, 35.481226936000041],
              [-80.836896253999953, 35.481269936000047],
              [-80.83694225399995, 35.481296936000035],
              [-80.836985253999956, 35.481336936000048],
              [-80.837105253999937, 35.481468936000056],
              [-80.837134253999977, 35.481520936000038],
              [-80.837172253999938, 35.481567935000044],
              [-80.837222253999983, 35.481611935000046],
              [-80.83740325499997, 35.481793936000031],
              [-80.837454253999965, 35.481851936000055],
              [-80.837496253999973, 35.481914935000077],
              [-80.837597254999935, 35.482039936000035],
              [-80.837639254999942, 35.48208293600004],
              [-80.83778625399998, 35.482267935000039],
              [-80.837858254999958, 35.482332936000034],
              [-80.837887253999952, 35.482375936000039],
              [-80.837928254999952, 35.482399936000036],
              [-80.838050254999985, 35.482431935000079],
              [-80.838213254999971, 35.482456937000052],
              [-80.838307254999961, 35.48246593600004],
              [-80.838399254999956, 35.482459936000055],
              [-80.838515254999948, 35.48246593600004],
              [-80.838624254999957, 35.482477936000066],
              [-80.838960254999961, 35.48249893600007],
              [-80.839042254999981, 35.48251093600004],
              [-80.839125254999942, 35.482515936000027],
              [-80.839327253999954, 35.482526935000067],
              [-80.839430254999968, 35.482520936000071],
              [-80.839523253999971, 35.482519935000028],
              [-80.839631254999972, 35.482523936000064],
              [-80.839851253999939, 35.482523935000074],
              [-80.840009254999984, 35.482545936000065],
              [-80.840060254999969, 35.482548936000057],
              [-80.840167254999983, 35.482553936000045],
              [-80.84031125599995, 35.48254393600007],
              [-80.840481254999986, 35.482544936000068],
              [-80.840663254999981, 35.482532936000041],
              [-80.840906254999936, 35.482545935000076],
              [-80.840989254999954, 35.482542935000026],
              [-80.841077254999959, 35.482545936000065],
              [-80.841234254999961, 35.482537936000028],
              [-80.84139225499996, 35.482557936000035],
              [-80.841446255999983, 35.482571936000056],
              [-80.841562254999985, 35.482593936000058],
              [-80.84184625499995, 35.482627936000029],
              [-80.841995254999972, 35.482621936000044],
              [-80.842054254999937, 35.482635936000065],
              [-80.842115254999953, 35.482669936000036],
              [-80.842160254999953, 35.482693936000032],
              [-80.842196255999966, 35.482718936000026],
              [-80.842296255999941, 35.482805936000034],
              [-80.84235225599997, 35.482844936000049],
              [-80.84239725599997, 35.482890936000047],
              [-80.842506255999979, 35.482976936000057],
              [-80.842562254999962, 35.483035936000078],
              [-80.842621254999983, 35.483066936000057],
              [-80.842680254999948, 35.483125936000079],
              [-80.842728255999987, 35.48318193700004],
              [-80.842854255999953, 35.483269936000056],
              [-80.842866255999979, 35.483318936000046],
              [-80.842884255999934, 35.483357936000061],
              [-80.84292925699998, 35.483404936000056],
              [-80.843003256999964, 35.483451937000041],
              [-80.843111255999986, 35.483537936000062],
              [-80.843131255999936, 35.483567937000032],
              [-80.843163256999958, 35.483574935000036],
              [-80.843250255999976, 35.483661936000033],
              [-80.843292255999984, 35.483689936000076],
              [-80.843379255999935, 35.483725936000042],
              [-80.843412256999954, 35.483753936000028],
              [-80.843440255999951, 35.483789936000051],
              [-80.843474255999979, 35.483819936000032],
              [-80.843507255999953, 35.483840936000036],
              [-80.843548255999963, 35.48392093700005],
              [-80.843571256999951, 35.483949936000045],
              [-80.843601255999943, 35.483968936000053],
              [-80.843631255999981, 35.483994936000045],
              [-80.843681256999957, 35.484065936000036],
              [-80.843723256999965, 35.484126936000052],
              [-80.843754255999954, 35.484159936000026],
              [-80.843792255999972, 35.48417693600004],
              [-80.843833255999982, 35.484188936000066],
              [-80.843868255999951, 35.484215937000045],
              [-80.843917256999987, 35.484300936000068],
              [-80.843945255999984, 35.484337936000031],
              [-80.843974255999967, 35.484385936000024],
              [-80.844052256999987, 35.484478936000073],
              [-80.844081255999981, 35.484526937000055],
              [-80.844114255999955, 35.484565936000024],
              [-80.844148256999972, 35.484597937000046],
              [-80.844223255999964, 35.484743936000029],
              [-80.844290256999955, 35.484846936000054],
              [-80.844327256999975, 35.484890936000056],
              [-80.844377255999973, 35.484918936000042],
              [-80.844540256999949, 35.48509193600006],
              [-80.844673255999965, 35.485249936000059],
              [-80.844766255999957, 35.485333936000075],
              [-80.844854256999952, 35.485378936000075],
              [-80.844891256999972, 35.485406936000061],
              [-80.844924255999956, 35.485447936000071],
              [-80.844928256999935, 35.48547893600005],
              [-80.844942256999957, 35.485578937000071],
              [-80.844948256999942, 35.485693936000075],
              [-80.844965255999966, 35.485725936000051],
              [-80.845066256999985, 35.485788936000063],
              [-80.84512125599997, 35.485817936000046],
              [-80.84517025699995, 35.48583493600006],
              [-80.845294255999988, 35.485863936000044],
              [-80.845351256999948, 35.485894936000079],
              [-80.845488256999943, 35.485935936000033],
              [-80.845548255999972, 35.485959936000029],
              [-80.84560225599995, 35.485975936000045],
              [-80.845653256999981, 35.485998936000044],
              [-80.845697256999983, 35.486013936000063],
              [-80.845816256999967, 35.486028937000071],
              [-80.845869256999947, 35.486050936000026],
              [-80.845921256999986, 35.486080937000054],
              [-80.845972255999982, 35.486091936000037],
              [-80.846073256999944, 35.486093936000032],
              [-80.846119256999941, 35.486113936000038],
              [-80.846161256999949, 35.486142937000068],
              [-80.84617525699997, 35.486155937000035],
              [-80.846228255999961, 35.486230937000073],
              [-80.846261256999981, 35.486240937000048],
              [-80.846290256999964, 35.48623993700005],
              [-80.84634525599995, 35.486191936000068],
              [-80.846384256999954, 35.48617293600006],
              [-80.846624256999974, 35.486148936000063],
              [-80.846682256999941, 35.486132937000036],
              [-80.846702256999947, 35.486110936000046],
              [-80.846707257999981, 35.486087936000047],
              [-80.846719256999961, 35.48607193600003],
              [-80.846751256999937, 35.486055936000071],
              [-80.846781257999965, 35.486033936000069],
              [-80.846835256999952, 35.486023937000027],
              [-80.84692625699995, 35.486017936000053],
              [-80.847017256999948, 35.486026937000076],
              [-80.847190256999966, 35.486081936000062],
              [-80.84724525699994, 35.486087937000036],
              [-80.847304256999962, 35.486101936000068],
              [-80.847349257999952, 35.486124936000067],
              [-80.847395257999949, 35.486130936000052],
              [-80.847456256999976, 35.486125937000054],
              [-80.847498257999973, 35.486103937000053],
              [-80.847523256999978, 35.486069936000035],
              [-80.847544256999981, 35.486029936000079],
              [-80.847590257999968, 35.485987936000072],
              [-80.847653256999934, 35.485981937000076],
              [-80.847691256999951, 35.485997936000047],
              [-80.847742257999982, 35.486077936000072],
              [-80.847801257999947, 35.486142937000068],
              [-80.847813257999974, 35.486185936000027],
              [-80.847854257999984, 35.48623593700006],
              [-80.847942256999943, 35.486290936000046],
              [-80.847993257999974, 35.486328936000064],
              [-80.848082256999987, 35.486343936000026],
              [-80.848133257999962, 35.486359936000042],
              [-80.848159256999963, 35.486384936000036],
              [-80.848195256999986, 35.486423936000051],
              [-80.848214257999985, 35.486458936000076],
              [-80.848232258999985, 35.486474936000036],
              [-80.848290257999963, 35.486551937000058],
              [-80.848346257999935, 35.486642936000067],
              [-80.848354256999983, 35.486710936000065],
              [-80.848356257999967, 35.486779937000051],
              [-80.848403257999962, 35.486807937000037],
              [-80.84844525799997, 35.486817937000069],
              [-80.848499256999958, 35.486849936000056],
              [-80.848526257999936, 35.486875936000047],
              [-80.848560257999964, 35.486898936000046],
              [-80.848603257999969, 35.486915937000049],
              [-80.848626256999978, 35.486935937000055],
              [-80.848641257999986, 35.486959936000062],
              [-80.848646256999984, 35.486996936000025],
              [-80.848640257999989, 35.48703193700004],
              [-80.848646257999974, 35.487049936000062],
              [-80.848685256999943, 35.487084936000031],
              [-80.84877225799994, 35.487084936000031],
              [-80.848847256999989, 35.48705493600005],
              [-80.848958256999936, 35.487038936000033],
              [-80.849009258999956, 35.487053937000042],
              [-80.84903825799995, 35.487084936000031],
              [-80.849107257999947, 35.487273937000055],
              [-80.849134257999935, 35.48732093600006],
              [-80.849176257999943, 35.487354937000077],
              [-80.849226258999977, 35.487375936000035],
              [-80.849378257999945, 35.487425936000079],
              [-80.849420257999952, 35.487461936000045],
              [-80.849465257999952, 35.487573936000047],
              [-80.849383257999989, 35.487761937000073],
              [-80.849281258999952, 35.487989936000076],
              [-80.849345258999961, 35.488633937000031],
              [-80.849350257999959, 35.488675937000039],
              [-80.84933125799995, 35.489115937000065],
              [-80.849320257999977, 35.489542937000067],
              [-80.849236257999962, 35.491938937000043],
              [-80.849182258999974, 35.493682938000063],
              [-80.848594257999935, 35.493694937000043],
              [-80.848194257999978, 35.493694937000043],
              [-80.84811125799996, 35.493687938000051],
              [-80.847999257999959, 35.493667938000044],
              [-80.847889257999952, 35.493631938000078],
              [-80.847314257999983, 35.493354938000039],
              [-80.846881257999939, 35.493145938000055],
              [-80.846851257999958, 35.49313993800007],
              [-80.84682825799996, 35.493140938000067],
              [-80.846796257999983, 35.493149938000045],
              [-80.846769257999938, 35.493165938000061],
              [-80.845728256999962, 35.494502938000039],
              [-80.845054256999958, 35.495374938000054],
              [-80.844980255999985, 35.495470938000039],
              [-80.844893256999967, 35.495583938000038],
              [-80.844596256999978, 35.495976938000069],
              [-80.84450525799997, 35.496083938000027],
              [-80.844473256999947, 35.496120938000047],
              [-80.844087255999966, 35.496633939000048],
              [-80.843569256999956, 35.49729693900008],
              [-80.843483256999946, 35.497399938000058],
              [-80.842718256999945, 35.496825939000075],
              [-80.841751255999952, 35.496125939000024],
              [-80.841637255999956, 35.496058938000033],
              [-80.841522255999962, 35.496001938000063],
              [-80.841426256999966, 35.495960938000053],
              [-80.841370255999948, 35.495941939000033],
              [-80.841219255999988, 35.495890938000059],
              [-80.839381254999978, 35.495329939000044],
              [-80.839268254999979, 35.495295939000073],
              [-80.837936254999988, 35.495686939000052],
              [-80.837724254999955, 35.495751939000058],
              [-80.837543254999957, 35.495806939000033],
              [-80.836793254999975, 35.496042939000063],
              [-80.836549253999976, 35.496117938000054],
              [-80.835903254999948, 35.496317939000051],
              [-80.835855254999956, 35.496333939000067],
              [-80.83569625399997, 35.496386939000047],
              [-80.835507253999936, 35.496462939000025],
              [-80.835163253999951, 35.49661893900003],
              [-80.835108253999977, 35.496644939000078],
              [-80.834996253999975, 35.49670093800006],
              [-80.834790253999984, 35.496815938000054],
              [-80.834626253999943, 35.496916939000073],
              [-80.834496254999976, 35.497004939000078],
              [-80.834366254999964, 35.497100939000063],
              [-80.834108253999943, 35.497320939000076],
              [-80.834066253999936, 35.497356940000031],
              [-80.833776253999986, 35.49763193900003],
              [-80.833718253999962, 35.497698939000031],
              [-80.833603253999968, 35.497830939000039],
              [-80.833457252999949, 35.498014939000029],
              [-80.833286252999983, 35.498240939000027],
              [-80.834748253999976, 35.497951939000075],
              [-80.836540254999989, 35.497595939000064],
              [-80.83679525499997, 35.497544939000079],
              [-80.836849254999947, 35.497647939000046]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 47,
        "SolutionID": "PI-19",
        "Shape_Length": 0.68663741747813944,
        "Shape_Area": 0.0041894555294706591
      }
    },
    {
      "type": "Feature",
      "id": 48,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.809300252999947, 35.598266961000036],
            [-80.809791251999968, 35.598815961000071],
            [-80.810485251999978, 35.599542962000044],
            [-80.811349252999946, 35.600459962000059],
            [-80.811462252999945, 35.60057996300003],
            [-80.811763252999981, 35.600164962000065],
            [-80.813071253999965, 35.598487961000046],
            [-80.813866253999947, 35.597636962000024],
            [-80.814463253999975, 35.597031961000027],
            [-80.814840252999943, 35.59670796100005],
            [-80.816580254999963, 35.595367961000079],
            [-80.817289253999945, 35.594904961000054],
            [-80.821229254999935, 35.592366960000049],
            [-80.822924255999965, 35.591249960000027],
            [-80.823991256999989, 35.590569960000039],
            [-80.825390256999981, 35.58967695900003],
            [-80.826928256999963, 35.588688958000034],
            [-80.82712825699997, 35.588559959000065],
            [-80.827400256999965, 35.588401959000066],
            [-80.827696256999957, 35.588276958000051],
            [-80.82801025699996, 35.588186958000051],
            [-80.82796525699996, 35.588087958000074],
            [-80.827912257999969, 35.58802995800005],
            [-80.827828256999965, 35.587963959000035],
            [-80.827708256999983, 35.587923959000079],
            [-80.827254256999936, 35.587846959000046],
            [-80.826336255999934, 35.58769195800005],
            [-80.825441255999976, 35.58754095900008],
            [-80.824534255999936, 35.587387959000068],
            [-80.824052255999959, 35.587338959000078],
            [-80.823142254999937, 35.587276959000064],
            [-80.822713255999986, 35.587263958000051],
            [-80.82222825499997, 35.587247959000024],
            [-80.820773254999949, 35.587200959000029],
            [-80.820565254999963, 35.587153958000044],
            [-80.82037425499999, 35.587072959000068],
            [-80.820039254999983, 35.586834959000043],
            [-80.819298253999989, 35.586235959000078],
            [-80.817660252999985, 35.584935959000063],
            [-80.816974252999955, 35.584370959000069],
            [-80.816040252999983, 35.583613958000058],
            [-80.815277251999987, 35.583011958000043],
            [-80.81411325199997, 35.582049958000027],
            [-80.816215251999949, 35.580253958000071],
            [-80.816603252999982, 35.579948958000045],
            [-80.818342252999969, 35.578451957000027],
            [-80.819585254999936, 35.577317956000059],
            [-80.820885254999951, 35.576222957000027],
            [-80.821368253999935, 35.575814957000034],
            [-80.822457254999961, 35.574897957000076],
            [-80.823987254999963, 35.573608956000044],
            [-80.824357255999985, 35.573283955000079],
            [-80.824681254999973, 35.572999956000046],
            [-80.825228254999956, 35.572568956000055],
            [-80.825328254999988, 35.572490956000024],
            [-80.825251255999945, 35.572438956000042],
            [-80.825188254999944, 35.572396955000045],
            [-80.825458254999944, 35.57215495500003],
            [-80.825773254999945, 35.571873956000047],
            [-80.824906254999973, 35.57114195500003],
            [-80.825126254999986, 35.570969955000066],
            [-80.825515254999971, 35.570665955000038],
            [-80.824816254999973, 35.57003095500005],
            [-80.823935254999981, 35.569254955000076],
            [-80.823633254999947, 35.568987955000068],
            [-80.823120254999935, 35.568534955000075],
            [-80.822485254999947, 35.569021955000039],
            [-80.822286254999938, 35.569174954000061],
            [-80.822183253999981, 35.569301955000071],
            [-80.822117254999966, 35.569445955000049],
            [-80.822092253999983, 35.569596955000065],
            [-80.822110254999984, 35.569749955000077],
            [-80.822014253999953, 35.570637955000052],
            [-80.821295254999939, 35.571058955000069],
            [-80.821009253999989, 35.571226955000043],
            [-80.820540254999969, 35.571342956000024],
            [-80.820084253999937, 35.571455956000079],
            [-80.819611252999948, 35.571481956000071],
            [-80.819285252999975, 35.571461956000064],
            [-80.818608253999969, 35.571426956000039],
            [-80.818487252999944, 35.571420956000054],
            [-80.817844252999976, 35.571387956000024],
            [-80.817624252999963, 35.57085195500008],
            [-80.817348252999977, 35.570178956000063],
            [-80.817080252999972, 35.569527955000069],
            [-80.815528252999968, 35.569633954000039],
            [-80.813625250999962, 35.569775955000068],
            [-80.811473251999985, 35.570137956000053],
            [-80.811428251999985, 35.571225956000035],
            [-80.811522250999985, 35.571610956000029],
            [-80.811568251999972, 35.571798955000077],
            [-80.811977251999963, 35.572752956000045],
            [-80.812047251999957, 35.575038956000071],
            [-80.812065251999968, 35.575395957000069],
            [-80.81254925099995, 35.576149957000041],
            [-80.81255325099994, 35.576595956000062],
            [-80.812362250999968, 35.577065957000059],
            [-80.811148250999963, 35.578623957000048],
            [-80.810927251999942, 35.578914958000041],
            [-80.81023725099999, 35.579881958000044],
            [-80.809933250999961, 35.580228957000031],
            [-80.809664250999958, 35.580516957000043],
            [-80.809015250999948, 35.581551958000034],
            [-80.807498249999981, 35.582748959000071],
            [-80.807293249999987, 35.582840958000077],
            [-80.806847249999976, 35.582739958000047],
            [-80.806794249999939, 35.583095958000058],
            [-80.806003249999947, 35.582945959000028],
            [-80.805737250999982, 35.582895958000051],
            [-80.805359249999981, 35.58282495800006],
            [-80.804807249999953, 35.582727959000067],
            [-80.804434249999986, 35.582691958000055],
            [-80.803681249999954, 35.582647958000052],
            [-80.803628248999985, 35.582901958000036],
            [-80.803571249999948, 35.583175958000027],
            [-80.80352524999995, 35.58343295800006],
            [-80.80351324999998, 35.583593959000041],
            [-80.803527248999956, 35.583846959000027],
            [-80.803591249999954, 35.584193959000061],
            [-80.803789249999966, 35.585267960000067],
            [-80.803812249999964, 35.585389960000043],
            [-80.803979250999987, 35.586518959000045],
            [-80.804178250999939, 35.587788960000069],
            [-80.804252249999934, 35.588267960000053],
            [-80.804314249999948, 35.58861095900005],
            [-80.804359249999948, 35.588784959000066],
            [-80.804445249999958, 35.589018960000033],
            [-80.804799250999963, 35.589878959000032],
            [-80.805506249999951, 35.591516960000035],
            [-80.806078249999985, 35.592569960000048],
            [-80.806479251999974, 35.593286961000047],
            [-80.806703250999988, 35.593708961000061],
            [-80.807106250999936, 35.594469961000073],
            [-80.806973250999988, 35.595296961000031],
            [-80.806795250999983, 35.596408961000066],
            [-80.806675251999934, 35.596664961000045],
            [-80.806501250999986, 35.596899961000076],
            [-80.805667250999988, 35.597893961000068],
            [-80.805953250999949, 35.597867962000066],
            [-80.806551250999973, 35.597834961000046],
            [-80.806920251999941, 35.597794961000034],
            [-80.807644251999989, 35.597717961000058],
            [-80.808306251999966, 35.597673962000044],
            [-80.808504251999977, 35.597635961000037],
            [-80.808611250999945, 35.597600961000069],
            [-80.808746251999935, 35.59753696100006],
            [-80.809300252999947, 35.598266961000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 48,
        "SolutionID": "PI-19",
        "Shape_Length": 0.11463380495284096,
        "Shape_Area": 0.0003458634226997281
      }
    },
    {
      "type": "Feature",
      "id": 49,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.786112233999972, 35.381666916000029],
            [-80.786140232999969, 35.381821916000035],
            [-80.786258232999955, 35.382450917000028],
            [-80.786374233999936, 35.38306491600008],
            [-80.786556232999942, 35.383994917000052],
            [-80.786603233999983, 35.384203917000036],
            [-80.786639233999949, 35.38431891700003],
            [-80.786700233999966, 35.384445916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.788118233999967, 35.383049916000061],
            [-80.788435233999962, 35.382791916000031],
            [-80.788806233999935, 35.382512917000042],
            [-80.788673234999976, 35.382364916000029],
            [-80.788323233999961, 35.382013917000052],
            [-80.788210233999962, 35.381930916000044],
            [-80.788088233999986, 35.381868917000077],
            [-80.787945233999949, 35.381812917000047],
            [-80.787829233999958, 35.381777916000033],
            [-80.787695233999955, 35.381757916000026],
            [-80.787321232999943, 35.381732917000079],
            [-80.787354233999963, 35.381476917000043],
            [-80.787439232999986, 35.380923917000075],
            [-80.787469232999968, 35.380810917000076],
            [-80.787508232999983, 35.380710916000055],
            [-80.787727233999988, 35.380317916000024],
            [-80.787834233999945, 35.380178916000034],
            [-80.787908233999985, 35.380093916000078],
            [-80.788009233999958, 35.379998916000034],
            [-80.788090233999981, 35.37992891600004],
            [-80.788184233999971, 35.379866915000036],
            [-80.788461234999943, 35.379717916000061],
            [-80.788380233999987, 35.379580915000076],
            [-80.788296233999972, 35.379456916000038],
            [-80.788257233999957, 35.379368916000033],
            [-80.788227233999976, 35.379285916000072],
            [-80.788225233999981, 35.379225916000053],
            [-80.788340233999975, 35.378968916000076],
            [-80.789500232999956, 35.376610915000072],
            [-80.789552234999974, 35.376530915000046],
            [-80.789612233999947, 35.376476915000069],
            [-80.789875234999954, 35.376303915000051],
            [-80.790028233999976, 35.376196914000047],
            [-80.790190233999965, 35.376067915000078],
            [-80.789804233999973, 35.375845914000024],
            [-80.789889233999986, 35.375686916000063],
            [-80.790138234999972, 35.375176915000054],
            [-80.790268233999939, 35.374897915000076],
            [-80.790462233999961, 35.374511915000028],
            [-80.790615233999972, 35.374188914000058],
            [-80.790985233999947, 35.373388914000031],
            [-80.791253233999953, 35.373446914000056],
            [-80.791601234999973, 35.373552914000072],
            [-80.791984234999973, 35.373705915000073],
            [-80.792397234999953, 35.373879914000042],
            [-80.792819234999968, 35.374084915000026],
            [-80.793234234999943, 35.374280915000043],
            [-80.793300234999947, 35.37415091500003],
            [-80.793362234999961, 35.374043914000026],
            [-80.793407234999961, 35.373948914000039],
            [-80.79347523499996, 35.373829915000044],
            [-80.793616233999956, 35.373562914000047],
            [-80.793684234999944, 35.373454915000025],
            [-80.793838234999953, 35.373247914000046],
            [-80.79392923499995, 35.373298914000031],
            [-80.794324234999976, 35.373487914000066],
            [-80.794597235999959, 35.373596915000064],
            [-80.795023234999974, 35.373707914000079],
            [-80.795480235999946, 35.373809915000038],
            [-80.795690235999984, 35.373861914000031],
            [-80.79575723499994, 35.37386891400007],
            [-80.795836235999957, 35.373865914000078],
            [-80.795928235999952, 35.373853915000041],
            [-80.796176235999951, 35.373786915000039],
            [-80.796212235999974, 35.373861914000031],
            [-80.796271235999939, 35.373937914000066],
            [-80.796316235999939, 35.373975914000027],
            [-80.796381235999945, 35.374014914000043],
            [-80.796468236999942, 35.37404891500006],
            [-80.796554235999963, 35.374076914000057],
            [-80.796931235999978, 35.374166915000046],
            [-80.797451236999962, 35.374244915000077],
            [-80.797468235999986, 35.374094914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797446236999974, 35.373914914000068],
            [-80.797416235999947, 35.373811914000044],
            [-80.797039235999989, 35.372922914000071],
            [-80.796997235999982, 35.372774914000047],
            [-80.796976235999978, 35.372682915000041],
            [-80.796966234999957, 35.372515914000076],
            [-80.796959235999964, 35.37238691400006],
            [-80.796958235999966, 35.372212914000045],
            [-80.796933235999973, 35.372133914000074],
            [-80.796915235999961, 35.372047913000074],
            [-80.796870235999961, 35.371958914000061],
            [-80.796635235999986, 35.371587914000031],
            [-80.796389234999936, 35.371225914000036],
            [-80.796325234999983, 35.371120914000073],
            [-80.796280235999973, 35.371011914000064],
            [-80.796123235999971, 35.370414914000037],
            [-80.796087234999959, 35.370324914000037],
            [-80.796038234999969, 35.370239914000024],
            [-80.795949234999966, 35.370134913000072],
            [-80.79568123599995, 35.369461914000055],
            [-80.794869234999965, 35.369124913000064],
            [-80.792751234999969, 35.368284913000025],
            [-80.787396232999981, 35.366161913000042],
            [-80.78746023399998, 35.365936914000031],
            [-80.787499232999949, 35.365769912000076],
            [-80.787528231999943, 35.365560913000024],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365370913000049],
            [-80.787515231999976, 35.365296912000076],
            [-80.78746823299997, 35.365193913000041],
            [-80.787434232999942, 35.365136913000072],
            [-80.78733323299997, 35.36499591300003],
            [-80.787164232999942, 35.364813913000035],
            [-80.786843232999956, 35.364509913000063],
            [-80.786228232999974, 35.363946913000063],
            [-80.785901231999958, 35.363641912000048],
            [-80.785673230999976, 35.363427912000077],
            [-80.785653232999948, 35.363408912000068],
            [-80.785209231999943, 35.363019912000027],
            [-80.785015231999978, 35.362886912000079],
            [-80.784919230999947, 35.362834913000029],
            [-80.784824231999949, 35.362786912000047],
            [-80.784727230999977, 35.362745912000037],
            [-80.784521231999975, 35.36267291200005],
            [-80.784015230999955, 35.362511912000059],
            [-80.783693230999972, 35.362400912000055],
            [-80.78360823099996, 35.36236591200003],
            [-80.783520230999954, 35.362316913000029],
            [-80.783401230999971, 35.362239912000064],
            [-80.783296230999952, 35.362153912000053],
            [-80.783255230999941, 35.362111912000046],
            [-80.783183230999953, 35.362019912000051],
            [-80.783102230999987, 35.361894913000071],
            [-80.783040230999973, 35.361755912000035],
            [-80.782968231999973, 35.361545912000054],
            [-80.78286923099995, 35.361213912000039],
            [-80.782534230999943, 35.36002691200008],
            [-80.78248623099995, 35.359857911000063],
            [-80.782435231999955, 35.359680911000055],
            [-80.782271230999982, 35.359118911000053],
            [-80.782153229999949, 35.358707911000067],
            [-80.781467229999976, 35.356426911000028],
            [-80.781132229999969, 35.355362911000043],
            [-80.780525229999967, 35.355518911000047],
            [-80.780020228999945, 35.355639911000026],
            [-80.779747229999941, 35.355694911000057],
            [-80.779208229999938, 35.355766911000046],
            [-80.77907222999994, 35.355785911000055],
            [-80.778780229999938, 35.355854912000041],
            [-80.778653228999985, 35.35591491100007],
            [-80.778530229999944, 35.355980911000074],
            [-80.778436229999954, 35.355919911000058],
            [-80.778358229999981, 35.355882911000037],
            [-80.778247228999987, 35.35583691000005],
            [-80.778114228999982, 35.355802911000069],
            [-80.778012229999945, 35.355781911000065],
            [-80.777915228999973, 35.355771911000033],
            [-80.777805228999966, 35.35577191200008],
            [-80.776886228999956, 35.355741911000052],
            [-80.776779228999942, 35.35573491100007],
            [-80.776598228999944, 35.355728911000028],
            [-80.776287228999934, 35.355709911000076],
            [-80.77615222899999, 35.355696912000042],
            [-80.776018228999988, 35.355664911000076],
            [-80.775921229999938, 35.355631911000046],
            [-80.775818227999935, 35.355589911000038],
            [-80.775584228999946, 35.355476911000039],
            [-80.775428228999942, 35.355682911000031],
            [-80.775363227999947, 35.355757912000058],
            [-80.775271227999951, 35.355843911000079],
            [-80.775162227999942, 35.355931912000074],
            [-80.775071228999934, 35.355989912000041],
            [-80.774928228999954, 35.35605791100005],
            [-80.774363226999981, 35.356238912000038],
            [-80.773851228999945, 35.356408912000063],
            [-80.77362822799995, 35.356475912000064],
            [-80.773437227999977, 35.356527911000057],
            [-80.772983227999987, 35.356627912000079],
            [-80.772871227999985, 35.356659911000065],
            [-80.772721227999966, 35.356722911000077],
            [-80.772614227999952, 35.35679691100006],
            [-80.772542227999963, 35.356852911000033],
            [-80.772467227999982, 35.356917912000029],
            [-80.772381227999972, 35.356999912000049],
            [-80.772214227999939, 35.357337912000048],
            [-80.772014227999989, 35.357743912000046],
            [-80.771708227999966, 35.357743912000046],
            [-80.771611227999983, 35.35772391200004],
            [-80.771543227999985, 35.357720912000048],
            [-80.771355227999948, 35.357726912000032],
            [-80.771312227999942, 35.357734912000069],
            [-80.771209226999986, 35.357742912000049],
            [-80.771131226999955, 35.357714911000073],
            [-80.770820226999945, 35.357631911000055],
            [-80.770316227999956, 35.357484912000075],
            [-80.770228226999961, 35.357432912000036],
            [-80.770173226999987, 35.357414912000024],
            [-80.770094226999959, 35.357409912000037],
            [-80.769884226999977, 35.357348912000077],
            [-80.769669226999952, 35.35726991100006],
            [-80.769544227999972, 35.357215912000072],
            [-80.769444226999951, 35.357217912000067],
            [-80.769360226999936, 35.357193911000024],
            [-80.769289227999934, 35.35718791100004],
            [-80.769198226999947, 35.357192911000027],
            [-80.769110226999942, 35.357228912000039],
            [-80.769026226999983, 35.357292912000048],
            [-80.768978226999934, 35.357319911000047],
            [-80.768900226999961, 35.357324912000024],
            [-80.768833226999959, 35.357300911000038],
            [-80.768754226999988, 35.357238912000071],
            [-80.768700226999954, 35.35720191200005],
            [-80.76867522699996, 35.357189912000024],
            [-80.768627226999968, 35.357171912000069],
            [-80.768477225999959, 35.35715691200005],
            [-80.768375225999989, 35.357162912000035],
            [-80.768340226999953, 35.357182911000052],
            [-80.76821722699998, 35.357192912000073],
            [-80.768163225999956, 35.357221911000067],
            [-80.768112225999971, 35.357264911000073],
            [-80.76805322599995, 35.357295912000041],
            [-80.767950225999982, 35.357323912000027],
            [-80.767849225999953, 35.357378912000058],
            [-80.767762226999935, 35.357413912000027],
            [-80.767722226999979, 35.357443912000065],
            [-80.767628225999943, 35.357467912000061],
            [-80.767493225999942, 35.357510912000066],
            [-80.767402225999945, 35.357548912000027],
            [-80.76738022699999, 35.357558912000059],
            [-80.767304225999965, 35.357595912000079],
            [-80.767113225999935, 35.357642912000074],
            [-80.767048226999975, 35.357675912000047],
            [-80.766941225999972, 35.357696912000051],
            [-80.766830226999957, 35.35772791200003],
            [-80.766719225999964, 35.357753911000032],
            [-80.766628225999966, 35.357779912000069],
            [-80.766554225999982, 35.357807911000066],
            [-80.766478226999936, 35.357856912000045],
            [-80.766418226999974, 35.357886911000037],
            [-80.76636822599994, 35.35789591200006],
            [-80.766195225999979, 35.357910912000079],
            [-80.765942224999947, 35.357911912000077],
            [-80.765792225999974, 35.35790391200004],
            [-80.765244225999936, 35.357900912000048],
            [-80.765122224999971, 35.357898912000053],
            [-80.764939225999967, 35.357885912000029],
            [-80.764764225999954, 35.357877912000049],
            [-80.764663224999936, 35.357878913000036],
            [-80.764642225999978, 35.35784691200007],
            [-80.764595225999983, 35.357799912000075],
            [-80.764570224999943, 35.357763912000053],
            [-80.76437122599998, 35.35754391200004],
            [-80.764146224999934, 35.357371912000076],
            [-80.764119224999945, 35.357319912000037],
            [-80.764126224999984, 35.357122912000079],
            [-80.764110224999968, 35.357065912000053],
            [-80.764061225999967, 35.357022913000037],
            [-80.764013224999985, 35.357020912000053],
            [-80.763924225999972, 35.357056912000075],
            [-80.763852224999937, 35.357065912000053],
            [-80.763590225999963, 35.356995912000059],
            [-80.763529224999957, 35.356974912000055],
            [-80.763495224999986, 35.356951911000067],
            [-80.763427224999987, 35.356920912000078],
            [-80.763364223999986, 35.356917912000029],
            [-80.763264224999944, 35.356955912000046],
            [-80.763046224999982, 35.357016912000063],
            [-80.762963224999964, 35.35702991200003],
            [-80.762824223999985, 35.357036912000069],
            [-80.762706223999942, 35.357028912000033],
            [-80.762598224999977, 35.357031912000025],
            [-80.762408224999945, 35.356995912000059],
            [-80.762142224999934, 35.356984913000076],
            [-80.761959223999952, 35.356960912000034],
            [-80.761931224999955, 35.356952912000054],
            [-80.76180922399999, 35.356866912000044],
            [-80.761756224999942, 35.356781912000031],
            [-80.761723224999969, 35.356701912000062],
            [-80.761638224999956, 35.356609912000067],
            [-80.761603223999941, 35.35655691200003],
            [-80.761580223999943, 35.356496912000068],
            [-80.761541224999974, 35.356308912000031],
            [-80.76151222499999, 35.356242912000027],
            [-80.761402223999937, 35.356166912000049],
            [-80.761369223999964, 35.356114912000066],
            [-80.761337223999988, 35.356017912000027],
            [-80.761298223999972, 35.355955911000024],
            [-80.761166223999965, 35.355834913000024],
            [-80.761054223999963, 35.355716912000048],
            [-80.760973224999987, 35.35558891200003],
            [-80.760881224999935, 35.355510912000057],
            [-80.760714222999979, 35.355399912000053],
            [-80.760482223999986, 35.355223912000042],
            [-80.760350223999978, 35.355145911000079],
            [-80.760193223999977, 35.35504091200005],
            [-80.760144223999987, 35.354996912000047],
            [-80.759973223999964, 35.354719912000064],
            [-80.759941223999988, 35.354604911000024],
            [-80.759929223999961, 35.354543911000064],
            [-80.759893222999949, 35.354456911000057],
            [-80.759801223999943, 35.354311912000071],
            [-80.759702223999966, 35.354212912000037],
            [-80.759653223999976, 35.354174912000076],
            [-80.759508223999944, 35.35409891200004],
            [-80.759420223999939, 35.354042911000079],
            [-80.759374223999941, 35.353999912000063],
            [-80.759308222999948, 35.353984911000055],
            [-80.759211222999966, 35.353995912000073],
            [-80.75912722399994, 35.353998912000066],
            [-80.759015223999938, 35.353983912000047],
            [-80.758641223999973, 35.353990912000029],
            [-80.758576223999967, 35.353984911000055],
            [-80.758514223999953, 35.353973911000026],
            [-80.758400222999967, 35.353920911000046],
            [-80.758264223999959, 35.353829911000048],
            [-80.758124223999971, 35.353766912000026],
            [-80.757989222999981, 35.35369391100005],
            [-80.757935222999947, 35.353661911000074],
            [-80.757722223999963, 35.353592912000067],
            [-80.756895222999958, 35.355332911000062],
            [-80.756975222999984, 35.355382912000039],
            [-80.757042223999974, 35.35544591200005],
            [-80.757111222999981, 35.355528911000079],
            [-80.757148222999945, 35.355591912000079],
            [-80.757189222999955, 35.355699913000024],
            [-80.757200222999984, 35.355742912000039],
            [-80.757221222999988, 35.355999912000073],
            [-80.757227222999973, 35.356234912000048],
            [-80.757230222999965, 35.356904912000061],
            [-80.757251223999958, 35.357193912000071],
            [-80.757315222999978, 35.357582912000055],
            [-80.757344222999961, 35.35771191200007],
            [-80.757398222999939, 35.357876912000052],
            [-80.75744522399998, 35.357977912000024],
            [-80.757504222999955, 35.358072912000068],
            [-80.75755122399994, 35.358134913000072],
            [-80.75791822399998, 35.358618913000043],
            [-80.757988222999984, 35.358722913000065],
            [-80.758055224999964, 35.358840912000062],
            [-80.75809822399998, 35.358950912000068],
            [-80.758136223999941, 35.359106913000062],
            [-80.758272224999985, 35.359861913000032],
            [-80.758321223999985, 35.360067914000069],
            [-80.758388224999976, 35.360266913000032],
            [-80.757704223999951, 35.360475913000073],
            [-80.757533222999939, 35.360520912000027],
            [-80.757423223999979, 35.360537912000041],
            [-80.757268222999983, 35.360549913000057],
            [-80.756832223999936, 35.360563913000078],
            [-80.756649223999943, 35.360569913000063],
            [-80.756503222999982, 35.360583913000028],
            [-80.75640322299995, 35.360609913000076],
            [-80.756330222999964, 35.360633914000061],
            [-80.75626822299995, 35.360658913000066],
            [-80.756222222999952, 35.360682913000062],
            [-80.75612122299998, 35.360745913000073],
            [-80.756041222999954, 35.360804913000038],
            [-80.75597522299995, 35.360866913000052],
            [-80.755718223999963, 35.360724914000059],
            [-80.755590222999956, 35.360666913000045],
            [-80.755456222999953, 35.360628913000028],
            [-80.755302222999944, 35.360604913000032],
            [-80.75463922299997, 35.360549913000057],
            [-80.754525222999973, 35.360543913000072],
            [-80.754520222999986, 35.360687914000039],
            [-80.754445222999948, 35.361927914000034],
            [-80.754373222999959, 35.363388914000041],
            [-80.754709222999963, 35.363627913000073],
            [-80.75481122299999, 35.363763913000071],
            [-80.754941221999957, 35.363916914000072],
            [-80.755005222999955, 35.364166914000066],
            [-80.755038221999939, 35.36427791400007],
            [-80.755056221999951, 35.364338914000029],
            [-80.755062222999982, 35.364603914000043],
            [-80.755090223999957, 35.364716914000041],
            [-80.75514222299995, 35.364799914000059],
            [-80.755149223999979, 35.364936914000054],
            [-80.755170222999936, 35.365088914000069],
            [-80.755338222999967, 35.365428914000063],
            [-80.755442222999989, 35.365704914000048],
            [-80.755537223999966, 35.365821914000037],
            [-80.755594222999946, 35.365982914000028],
            [-80.755632223999953, 35.366065914000046],
            [-80.755678223999951, 35.366151915000046],
            [-80.755771222999954, 35.36626691500004],
            [-80.755891223999981, 35.366431914000032],
            [-80.755893223999976, 35.366526914000076],
            [-80.755928223999945, 35.366606914000045],
            [-80.755973222999955, 35.366680914000028],
            [-80.756004222999934, 35.366751915000066],
            [-80.756075223999972, 35.366873915000042],
            [-80.756172223999954, 35.366943914000046],
            [-80.756209223999974, 35.366981915000054],
            [-80.756353223999952, 35.367071914000064],
            [-80.756600223999953, 35.36723891500003],
            [-80.756656223999983, 35.367285914000036],
            [-80.756918222999957, 35.367606914000078],
            [-80.757116223999958, 35.36788391500005],
            [-80.757128223999985, 35.367914915000028],
            [-80.75713022299999, 35.36793291500004],
            [-80.757405223999967, 35.36827091400005],
            [-80.757465223999986, 35.368398915000057],
            [-80.75752722499999, 35.368468915000051],
            [-80.757556223999984, 35.368509915000061],
            [-80.757563223999966, 35.368599915000061],
            [-80.757627224999965, 35.368670914000063],
            [-80.75781322399996, 35.368701915000031],
            [-80.757893223999986, 35.368696914000054],
            [-80.758075223999981, 35.368732914000077],
            [-80.758158223999942, 35.368763915000045],
            [-80.758373223999968, 35.368795914000032],
            [-80.758500223999988, 35.36880691500005],
            [-80.758573223999974, 35.368816915000025],
            [-80.758640223999976, 35.368830914000057],
            [-80.758769223999934, 35.368869915000062],
            [-80.758954224999968, 35.368898915000045],
            [-80.759024223999972, 35.368915915000059],
            [-80.759060224999985, 35.368933914000024],
            [-80.759082224999986, 35.368953915000077],
            [-80.759270224999966, 35.369060914000045],
            [-80.75946722499998, 35.369187915000055],
            [-80.759671224999977, 35.369391914000062],
            [-80.759725224999954, 35.369426915000076],
            [-80.759763224999972, 35.369443915000033],
            [-80.759800224999935, 35.369466915000032],
            [-80.760029223999936, 35.369566915000064],
            [-80.760069223999949, 35.369579915000031],
            [-80.76013622499994, 35.369592915000055],
            [-80.760203224999941, 35.369596915000045],
            [-80.760250223999947, 35.369603915000027],
            [-80.760481224999978, 35.369699915000069],
            [-80.760775224999975, 35.369846915000039],
            [-80.760808224999948, 35.369863914000064],
            [-80.760897224999951, 35.369889915000044],
            [-80.760979224999971, 35.369888914000057],
            [-80.76105122499996, 35.369901915000071],
            [-80.761150225999984, 35.36992891500006],
            [-80.761342224999964, 35.369992915000068],
            [-80.761660225999947, 35.370065915000055],
            [-80.761720224999976, 35.370068915000047],
            [-80.761893225999984, 35.370103915000072],
            [-80.761994225999956, 35.37011691500004],
            [-80.762036224999974, 35.370127916000058],
            [-80.762086225999951, 35.370152914000073],
            [-80.762111225999945, 35.370176914000069],
            [-80.762137225999936, 35.370217914000079],
            [-80.762172225999961, 35.370256915000027],
            [-80.762195224999971, 35.370295914000053],
            [-80.762226225999939, 35.370310915000061],
            [-80.762369224999986, 35.370354914000075],
            [-80.762468224999964, 35.370375915000068],
            [-80.762522225999987, 35.37037491500007],
            [-80.762567225999987, 35.370380915000055],
            [-80.762605224999959, 35.370393915000079],
            [-80.762648225999953, 35.37042391600005],
            [-80.762773225999979, 35.370486915000072],
            [-80.76296122499997, 35.370538915000054],
            [-80.763049225999964, 35.370556915000066],
            [-80.763125225999943, 35.370576915000072],
            [-80.763224226999967, 35.370609915000045],
            [-80.763414226999942, 35.370660914000041],
            [-80.763483225999948, 35.370686915000078],
            [-80.763541225999973, 35.370700914000054],
            [-80.763616225999954, 35.370697914000061],
            [-80.763671225999985, 35.37070591500003],
            [-80.763711225999941, 35.370743915000048],
            [-80.763827225999989, 35.370872914000074],
            [-80.763881225999967, 35.370921915000054],
            [-80.763912225999945, 35.370980915000075],
            [-80.763903225999968, 35.371018915000036],
            [-80.763886225999954, 35.371059915000046],
            [-80.763878224999985, 35.371099915000059],
            [-80.763882226999954, 35.371151915000041],
            [-80.763915225999938, 35.371205914000029],
            [-80.763976225999954, 35.371277915000064],
            [-80.764044225999953, 35.371343914000079],
            [-80.764154226999949, 35.371460915000057],
            [-80.764211225999986, 35.371503915000062],
            [-80.764296226999988, 35.371544915000072],
            [-80.764330226999959, 35.371550915000057],
            [-80.764427225999952, 35.371551916000044],
            [-80.764488226999958, 35.371544915000072],
            [-80.764540225999951, 35.371543915000075],
            [-80.764691225999968, 35.371522915000071],
            [-80.764762225999959, 35.371548915000062],
            [-80.764831225999956, 35.371563915000024],
            [-80.764963225999963, 35.371613915000069],
            [-80.765090225999984, 35.371671916000025],
            [-80.765149225999949, 35.371671916000025],
            [-80.765184225999974, 35.37164791500004],
            [-80.765318226999966, 35.371540915000026],
            [-80.76539622599995, 35.371489915000041],
            [-80.765489225999943, 35.371453915000075],
            [-80.76552722699995, 35.371445915000038],
            [-80.765597226999944, 35.371444915000041],
            [-80.765688225999952, 35.371456916000056],
            [-80.765857226999969, 35.371497915000077],
            [-80.76595122699996, 35.371494915000028],
            [-80.766039226999965, 35.371445915000038],
            [-80.766243226999961, 35.371285915000044],
            [-80.766299225999944, 35.371269915000028],
            [-80.766312226999958, 35.371540914000036],
            [-80.766312225999968, 35.371577915000046],
            [-80.766310226999963, 35.371690916000034],
            [-80.766302226999983, 35.371739915000035],
            [-80.766222226999957, 35.37187691500003],
            [-80.76599622599997, 35.372144915000035],
            [-80.765877226999976, 35.372256916000026],
            [-80.765795225999966, 35.372326915000031],
            [-80.765646225999944, 35.372449915000061],
            [-80.765484226999945, 35.372607916000049],
            [-80.765387226999962, 35.372729915000036],
            [-80.765309226999989, 35.372870915000078],
            [-80.765275225999972, 35.372967915000061],
            [-80.765256225999963, 35.373194915000056],
            [-80.765254226999957, 35.373360916000024],
            [-80.765245225999934, 35.373606915000039],
            [-80.765234226999951, 35.373721915000033],
            [-80.765213226999947, 35.373841915000071],
            [-80.76515122699999, 35.374019915000076],
            [-80.768493227999954, 35.374972915000058],
            [-80.769319227999972, 35.375214916000061],
            [-80.76968822799995, 35.375310916000046],
            [-80.769816228999957, 35.375337916000035],
            [-80.770021228999951, 35.37536891600007],
            [-80.77019822799997, 35.375380915000051],
            [-80.770340227999952, 35.37538091600004],
            [-80.770446227999969, 35.375374916000055],
            [-80.770658227999945, 35.37534691500008],
            [-80.770769227999949, 35.375325916000065],
            [-80.773075227999982, 35.374756915000034],
            [-80.773966229999985, 35.374518915000067],
            [-80.77638922999995, 35.373801914000069],
            [-80.776559229999975, 35.373751915000071],
            [-80.777106230999948, 35.373577915000055],
            [-80.777474229999939, 35.373470914000052],
            [-80.777779229999965, 35.373371915000064],
            [-80.77790023099999, 35.373335914000052],
            [-80.778098230999944, 35.373904915000026],
            [-80.778124230999936, 35.373976915000071],
            [-80.778131229999985, 35.374045916000057],
            [-80.77813722999997, 35.374180915000068],
            [-80.778160230999958, 35.374286915000027],
            [-80.778223229999981, 35.37440091600007],
            [-80.778302230999941, 35.374474915000064],
            [-80.778374230999987, 35.374519914000075],
            [-80.778466229999935, 35.374557916000072],
            [-80.778573230999939, 35.374602915000025],
            [-80.778679230999956, 35.374647915000025],
            [-80.778920230999972, 35.374747915000057],
            [-80.779128230999959, 35.374834915000065],
            [-80.779265230999954, 35.374891915000035],
            [-80.779513229999964, 35.374995915000056],
            [-80.779710230999967, 35.375078915000074],
            [-80.779951230999984, 35.375186915000029],
            [-80.780080230999943, 35.37524591500005],
            [-80.780143230999954, 35.375298916000077],
            [-80.78029823199995, 35.375461915000074],
            [-80.780483230999948, 35.37563691500003],
            [-80.780667231999985, 35.375789915000041],
            [-80.780710230999944, 35.375820916000066],
            [-80.78062423099999, 35.375878915000044],
            [-80.780553230999942, 35.375938916000052],
            [-80.78050923099994, 35.375991916000032],
            [-80.78045623099996, 35.376073915000063],
            [-80.780035231999989, 35.377064916000052],
            [-80.780018230999985, 35.377117915000042],
            [-80.780019230999983, 35.377168916000073],
            [-80.780115230999968, 35.377669916000059],
            [-80.779945230999942, 35.377761916000054],
            [-80.779815230999986, 35.377995915000042],
            [-80.779677230999937, 35.378317916000071],
            [-80.779516230999945, 35.378698916000076],
            [-80.779502230999981, 35.378953916000057],
            [-80.779536230999952, 35.379037917000062],
            [-80.779665231999957, 35.379168916000026],
            [-80.779858231999981, 35.379297916000041],
            [-80.780334230999983, 35.379612916000042],
            [-80.780757231999985, 35.379892916000074],
            [-80.781171230999973, 35.38014991600005],
            [-80.781368231999977, 35.380199916000038],
            [-80.781625231999953, 35.380269917000078],
            [-80.782115231999967, 35.380523917000062],
            [-80.782722232999959, 35.380973917000063],
            [-80.78291623299998, 35.381855917000053],
            [-80.782924231999971, 35.381984917000068],
            [-80.783470232999946, 35.38190591700004],
            [-80.786088232999987, 35.381527916000039],
            [-80.786112233999972, 35.381666916000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 49,
        "SolutionID": "PI-19",
        "Shape_Length": 0.14193478810757026,
        "Shape_Area": 0.00060945957730885395
      }
    },
    {
      "type": "Feature",
      "id": 50,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.84019224299999, 35.261062888000026],
              [-80.839806243999988, 35.261086889000069],
              [-80.839372242999957, 35.26111288900006],
              [-80.838254241999948, 35.261181888000067],
              [-80.838015241999983, 35.261196889000075],
              [-80.83705624199996, 35.261260888000038],
              [-80.837058242999944, 35.261500889000047],
              [-80.837082240999962, 35.261950889000047],
              [-80.83708924299998, 35.262041889000045],
              [-80.837124241999959, 35.262424888000055],
              [-80.837196241999948, 35.26338288900007],
              [-80.837250242999971, 35.264104889000066],
              [-80.837417241999958, 35.266390890000025],
              [-80.837451241999986, 35.266837890000033],
              [-80.837469240999951, 35.267004891000056],
              [-80.837461241999961, 35.267184889000077],
              [-80.83748424199996, 35.267519890000074],
              [-80.837524242999962, 35.267875890000028],
              [-80.837563241999987, 35.268543891000036],
              [-80.837564241999985, 35.268794890000038],
              [-80.837590241999976, 35.26925889000006],
              [-80.837578242999939, 35.269552891000046],
              [-80.837567241999977, 35.269692891000034],
              [-80.837537241999939, 35.269964891000029],
              [-80.837496242999975, 35.27023489000004],
              [-80.837444242999936, 35.270501891000038],
              [-80.83737924199994, 35.270768891000046],
              [-80.837304242999949, 35.271032891000061],
              [-80.837218241999949, 35.271294892000071],
              [-80.83699524299999, 35.271875891000036],
              [-80.83666524299997, 35.27272589100005],
              [-80.836870242999964, 35.272734891000027],
              [-80.837246241999935, 35.272751891000041],
              [-80.837837243999957, 35.272817892000035],
              [-80.839684242999965, 35.272925891000057],
              [-80.840876243999958, 35.272995891000051],
              [-80.841811243999985, 35.273038891000056],
              [-80.842371243999935, 35.273070892000078],
              [-80.842591244999937, 35.273079892000055],
              [-80.842810243999963, 35.273083891000056],
              [-80.843165243999977, 35.273082891000058],
              [-80.843383244999984, 35.273077891000071],
              [-80.843818244999966, 35.273053890000028],
              [-80.844275243999959, 35.273008891000075],
              [-80.844503244999942, 35.272979891000034],
              [-80.844687243999942, 35.272951892000037],
              [-80.844846244999985, 35.272927891000052],
              [-80.84495824399994, 35.272910891000038],
              [-80.846745244999966, 35.272572891000038],
              [-80.846734244999936, 35.272024890000068],
              [-80.846727244999954, 35.27175789100005],
              [-80.846704244999955, 35.271462891000056],
              [-80.84666124599994, 35.271169890000067],
              [-80.846575245999986, 35.270757891000073],
              [-80.846531244999937, 35.270579890000079],
              [-80.846377245999975, 35.270149890000027],
              [-80.846230244999958, 35.269808891000025],
              [-80.846160244999965, 35.269671890000041],
              [-80.846087244999978, 35.269527890000063],
              [-80.845923244999938, 35.269240890000049],
              [-80.845772244999978, 35.269007890000069],
              [-80.845575244999964, 35.268719891000046],
              [-80.845393243999979, 35.268467890000068],
              [-80.845121244999973, 35.268084890000068],
              [-80.844913244999987, 35.267792890000067],
              [-80.844629243999975, 35.267411891000052],
              [-80.844319243999962, 35.266995889000043],
              [-80.844033243999945, 35.266602890000058],
              [-80.843340242999943, 35.265633890000061],
              [-80.842190242999948, 35.264023889000043],
              [-80.841995243999975, 35.263780889000031],
              [-80.841836242999989, 35.263548890000038],
              [-80.841636243999972, 35.263219889000027],
              [-80.841507241999977, 35.263016889000028],
              [-80.841251243999977, 35.262637889000075],
              [-80.84116024299999, 35.262472889000037],
              [-80.84107424299998, 35.262305889000061],
              [-80.840914242999986, 35.261959888000035],
              [-80.840815242999952, 35.261694889000069],
              [-80.840761242999974, 35.261510888000032],
              [-80.840736242999981, 35.261424889000068],
              [-80.840674242999967, 35.261150889000078],
              [-80.840656242999955, 35.26103488900003],
              [-80.84019224299999, 35.261062888000026]
            ]
          ],
          [
            [
              [-80.857355248999966, 35.280726892000075],
              [-80.85754824899999, 35.280738892000045],
              [-80.857688248999978, 35.280754893000051],
              [-80.857840248999935, 35.28077789200006],
              [-80.858785248999936, 35.280951892000076],
              [-80.859135249999952, 35.280997892000073],
              [-80.859338248999961, 35.281016892000025],
              [-80.860108249999939, 35.281085893000068],
              [-80.860720249999986, 35.281146892000038],
              [-80.861321249999946, 35.281229892000056],
              [-80.862457250999967, 35.281383892000065],
              [-80.862552250999954, 35.280921892000038],
              [-80.862593249999975, 35.280788892000032],
              [-80.862650250999934, 35.280668892000051],
              [-80.862748249999981, 35.280478892000076],
              [-80.862813249999988, 35.280389892000073],
              [-80.862892250999948, 35.280311891000053],
              [-80.862946249999936, 35.280269892000035],
              [-80.863055250999935, 35.280203892000031],
              [-80.863233249999951, 35.280129892000048],
              [-80.863284251999971, 35.280102892000059],
              [-80.863375250999979, 35.28003889200005],
              [-80.863460250999935, 35.279950892000045],
              [-80.863657251999939, 35.27972389100006],
              [-80.863946250999959, 35.279403892000062],
              [-80.864626250999947, 35.278647892000038],
              [-80.864958251999951, 35.278270891000034],
              [-80.865333251999971, 35.277860891000046],
              [-80.865633250999963, 35.277532891000078],
              [-80.865678250999963, 35.277475892000041],
              [-80.865934251999988, 35.277196892000063],
              [-80.866326250999975, 35.276752891000058],
              [-80.866434251999976, 35.276632891000077],
              [-80.866537250999954, 35.276504890000069],
              [-80.866580250999959, 35.276435891000062],
              [-80.866631251999934, 35.276324891000058],
              [-80.866662251999969, 35.276208891000067],
              [-80.866686251999965, 35.276026891000072],
              [-80.866996250999989, 35.276060891000043],
              [-80.866934251999965, 35.275964891000058],
              [-80.866862251999976, 35.275898891000054],
              [-80.866739250999956, 35.275773891000028],
              [-80.86669225199995, 35.275657891000037],
              [-80.866671251999946, 35.275439891000076],
              [-80.866615251999974, 35.275327891000074],
              [-80.866537250999954, 35.275071891000039],
              [-80.866515250999953, 35.274981891000039],
              [-80.866485251999961, 35.274785891000079],
              [-80.866458250999983, 35.274665891000041],
              [-80.866429250999943, 35.274579890000041],
              [-80.866371250999975, 35.274524890000066],
              [-80.866221250999956, 35.274270891000072],
              [-80.866191250999975, 35.274227890000077],
              [-80.866145250999978, 35.274182891000066],
              [-80.866084250999961, 35.274050891000059],
              [-80.86606225099996, 35.273985891000052],
              [-80.865939251999976, 35.27379189100003],
              [-80.86589625199997, 35.273738890000061],
              [-80.865789250999967, 35.273583890000054],
              [-80.86578824999998, 35.273553891000063],
              [-80.865794250999954, 35.273511890000066],
              [-80.865811250999968, 35.273490890000062],
              [-80.865815250999958, 35.273462890000076],
              [-80.865801250999937, 35.273429890000045],
              [-80.865741250999974, 35.273383890000048],
              [-80.865678250999963, 35.273353890000067],
              [-80.865628250999976, 35.273301891000074],
              [-80.865580250999983, 35.273268890000054],
              [-80.865366250999955, 35.273265891000051],
              [-80.865289250999979, 35.273012891000064],
              [-80.865278251999939, 35.272895891000076],
              [-80.865260250999938, 35.272801890000039],
              [-80.865216251999982, 35.272617891000039],
              [-80.865197251999973, 35.272502890000055],
              [-80.865200250999976, 35.272315890000073],
              [-80.865226249999978, 35.272127890000036],
              [-80.865260250999938, 35.271925890000034],
              [-80.86530725099999, 35.271759890000055],
              [-80.865345250999951, 35.271657890000029],
              [-80.865395250999939, 35.271469890000049],
              [-80.865456250999955, 35.271281889000079],
              [-80.865472250999971, 35.27120989000008],
              [-80.865648250999982, 35.270642890000033],
              [-80.865673250999976, 35.270530890000032],
              [-80.86568325099995, 35.270437890000039],
              [-80.865688249999948, 35.270126890000029],
              [-80.865668251999978, 35.269417890000057],
              [-80.865677249999976, 35.269338889000039],
              [-80.865698250999969, 35.269152889000054],
              [-80.865722250999966, 35.26894388900007],
              [-80.86685825099994, 35.268727889000047],
              [-80.869100251999953, 35.268292890000055],
              [-80.869582251999987, 35.268192889000034],
              [-80.870035252999969, 35.268090889000064],
              [-80.869967251999981, 35.267909888000077],
              [-80.86989625199999, 35.267722889000026],
              [-80.869833251999978, 35.267643890000045],
              [-80.869821251999952, 35.267605890000027],
              [-80.869812251999974, 35.267535889000044],
              [-80.869777251999949, 35.267446889000041],
              [-80.869760252999981, 35.267350889000056],
              [-80.86975825199994, 35.267275890000064],
              [-80.869780252999988, 35.267167888000074],
              [-80.869788251999978, 35.267019889000039],
              [-80.869783251999934, 35.266987889000063],
              [-80.869693251999934, 35.266783889000067],
              [-80.869669251999937, 35.266544889000045],
              [-80.86966825199994, 35.26641188800005],
              [-80.869658251999965, 35.266369889000032],
              [-80.869642252999938, 35.266327889000024],
              [-80.869631251999976, 35.266278889000034],
              [-80.869571251999957, 35.266187888000047],
              [-80.869555251999941, 35.26613788800006],
              [-80.869541251999976, 35.266049889000044],
              [-80.869486251999945, 35.265975888000071],
              [-80.869451252999966, 35.265916888000049],
              [-80.869434250999973, 35.265870888000052],
              [-80.869418251999946, 35.265759888000048],
              [-80.869381251999982, 35.265687889000048],
              [-80.869380251999985, 35.265593889000058],
              [-80.869371251999951, 35.265549888000066],
              [-80.869348251999952, 35.265502889000061],
              [-80.869326250999961, 35.265419889000043],
              [-80.869326250999961, 35.265374888000053],
              [-80.869318251999971, 35.265336889000025],
              [-80.869303251999952, 35.265309889000036],
              [-80.86928825199999, 35.265295889000072],
              [-80.869209251999962, 35.265201889000025],
              [-80.869112251999979, 35.265103889000045],
              [-80.869070251999972, 35.265036889000044],
              [-80.869037251999941, 35.264963889000057],
              [-80.869006250999973, 35.264942888000064],
              [-80.86897025199994, 35.26490988900008],
              [-80.868849250999972, 35.264853889000051],
              [-80.868796250999935, 35.264833889000045],
              [-80.868773250999936, 35.26483588800005],
              [-80.868721250999954, 35.264724889000036],
              [-80.868695251999952, 35.264319888000045],
              [-80.868715251999959, 35.264266889000055],
              [-80.868793250999943, 35.264115888000049],
              [-80.868824251999968, 35.263999889000047],
              [-80.868473250999955, 35.264011888000027],
              [-80.86842225099997, 35.264013889000069],
              [-80.867313250999985, 35.264040889000057],
              [-80.86724325199998, 35.264053888000035],
              [-80.866913250999971, 35.264100889000076],
              [-80.866790250999941, 35.264122888000031],
              [-80.866546249999942, 35.264179888000058],
              [-80.866297250999935, 35.264257888000031],
              [-80.865708249999955, 35.264463889000069],
              [-80.865626250999981, 35.264488889000063],
              [-80.865455250999958, 35.264527889000078],
              [-80.865324249999958, 35.264550888000031],
              [-80.865124249999951, 35.264572888000032],
              [-80.865013249999947, 35.264578889000063],
              [-80.864811249999946, 35.264575888000024],
              [-80.864624249999963, 35.26455788800007],
              [-80.863693249999983, 35.264426889000049],
              [-80.863081249999937, 35.264345889000026],
              [-80.860532249999949, 35.264100889000076],
              [-80.859870247999936, 35.264040889000057],
              [-80.858682248999969, 35.263924889000066],
              [-80.857430247999957, 35.26380688900008],
              [-80.857692247999978, 35.262582888000054],
              [-80.857158248999951, 35.262660888000028],
              [-80.856955246999973, 35.262689888000068],
              [-80.855970246999959, 35.262823888000071],
              [-80.854718246999937, 35.262998889000073],
              [-80.854458246999968, 35.264245889000051],
              [-80.854225246999988, 35.265340889000072],
              [-80.854201246999935, 35.265458890000048],
              [-80.853960246999975, 35.266610890000038],
              [-80.853941246999966, 35.266772889000038],
              [-80.85395424699999, 35.267322890000059],
              [-80.853971246999947, 35.268047890000048],
              [-80.853984247999961, 35.268284889000029],
              [-80.854016246999947, 35.268864889000042],
              [-80.854111247999981, 35.270106891000069],
              [-80.854190247999952, 35.271147890000066],
              [-80.854205246999982, 35.271349891000057],
              [-80.854259247999948, 35.271958891000054],
              [-80.854275247999965, 35.272187890000055],
              [-80.854354247999936, 35.272985891000076],
              [-80.854380247999984, 35.273222891000046],
              [-80.854499247999968, 35.274249891000068],
              [-80.854539246999934, 35.274445891000028],
              [-80.854589247999968, 35.274633891000065],
              [-80.854659247999962, 35.274838891000059],
              [-80.854830247999985, 35.275204891000044],
              [-80.854863248999948, 35.275293891000047],
              [-80.855270247999954, 35.276378891000036],
              [-80.855283247999978, 35.276413891000061],
              [-80.855336247999958, 35.276576891000047],
              [-80.855390248999981, 35.276764892000074],
              [-80.855885248999982, 35.278528892000054],
              [-80.856522247999976, 35.280760892000046],
              [-80.856652248999978, 35.28074489200003],
              [-80.857049248999942, 35.280723892000026],
              [-80.857179248999955, 35.280722892000028],
              [-80.857355248999966, 35.280726892000075]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 50,
        "SolutionID": "PI-19",
        "Shape_Length": 0.10273233292782799,
        "Shape_Area": 0.00028156932988079538
      }
    },
    {
      "type": "Feature",
      "id": 51,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846441242999958, 35.224962880000078],
            [-80.846707242999969, 35.225181880000036],
            [-80.846800241999972, 35.225262881000049],
            [-80.846927242999982, 35.225386881000077],
            [-80.847145242999943, 35.225589881000076],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 51,
        "SolutionID": "PI-19",
        "Shape_Length": 0.1369548044217565,
        "Shape_Area": 0.00063136372835199126
      }
    },
    {
      "type": "Feature",
      "id": 52,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.885594247999961, 35.112545855000064],
              [-80.885866247999957, 35.112567855000066],
              [-80.886136247999957, 35.112605855000027],
              [-80.886436248999985, 35.11267485500008],
              [-80.886740248999956, 35.112760855000033],
              [-80.886889247999989, 35.112810855000077],
              [-80.887378248999937, 35.11303885500007],
              [-80.888287249999962, 35.113464855000075],
              [-80.888543249999941, 35.113565856000037],
              [-80.888704248999943, 35.113618855000027],
              [-80.888961249999966, 35.11368785500008],
              [-80.889137248999987, 35.113723856000036],
              [-80.889406249999979, 35.113765855000054],
              [-80.889609248999989, 35.113784855000063],
              [-80.889888249999956, 35.113794856000027],
              [-80.890181249999955, 35.113786855000058],
              [-80.890315249999958, 35.113775855000029],
              [-80.890578249999976, 35.113741856000047],
              [-80.890868249999983, 35.113684856000077],
              [-80.891066249999938, 35.113632855000048],
              [-80.892878250999956, 35.113047855000048],
              [-80.895826251999949, 35.112095854000074],
              [-80.897564251999938, 35.111530855000069],
              [-80.896719250999979, 35.110666855000034],
              [-80.896162250999964, 35.110071854000068],
              [-80.89564625099996, 35.109545855000079],
              [-80.89504625099994, 35.108949854000059],
              [-80.894750249999959, 35.108670854000025],
              [-80.894612250999955, 35.108540853000079],
              [-80.894282249999947, 35.108259855000028],
              [-80.893983250999952, 35.108012853000048],
              [-80.89370624999998, 35.107793855000068],
              [-80.893288249999955, 35.107474854000031],
              [-80.892614249999951, 35.107009854000069],
              [-80.892283249999934, 35.106794854000043],
              [-80.891868249999959, 35.106539854000061],
              [-80.891496249999989, 35.106321854000043],
              [-80.891394249999962, 35.106264854000074],
              [-80.891090248999944, 35.106093854000051],
              [-80.890726249999943, 35.105898854000031],
              [-80.890400249999971, 35.10573785400004],
              [-80.889666248999958, 35.105396854000048],
              [-80.889144248999969, 35.105173854000043],
              [-80.88542924799998, 35.103713854000034],
              [-80.885276247999968, 35.103653854000072],
              [-80.884399246999976, 35.103289853000035],
              [-80.884233246999941, 35.103227854000068],
              [-80.882886246999988, 35.102723854000033],
              [-80.882819246999986, 35.102698853000049],
              [-80.881755246999944, 35.102214854000067],
              [-80.880874246999952, 35.10176385300008],
              [-80.880831246999946, 35.101921854000068],
              [-80.880791245999944, 35.102167853000026],
              [-80.880737246999956, 35.102581853000061],
              [-80.880700246999936, 35.102996854000025],
              [-80.880681245999938, 35.10347485300008],
              [-80.880679245999943, 35.103620854000042],
              [-80.880687245999979, 35.104036853000025],
              [-80.880709245999981, 35.104403853000065],
              [-80.880738245999964, 35.104606853000064],
              [-80.880784245999962, 35.104933853000034],
              [-80.880810245999953, 35.105070853000029],
              [-80.880888246999973, 35.105476853000027],
              [-80.880978245999984, 35.105845853000062],
              [-80.881056245999957, 35.106132854000066],
              [-80.88144824699998, 35.107475854000029],
              [-80.881449245999988, 35.107476855000073],
              [-80.881612246999964, 35.108011855000029],
              [-80.88206024699997, 35.109521855000025],
              [-80.882392246999984, 35.110643854000045],
              [-80.882548246999988, 35.111185855000031],
              [-80.882801246999975, 35.112107855000033],
              [-80.882891246999975, 35.112474856000063],
              [-80.883015247999936, 35.112977855000054],
              [-80.883681247999959, 35.112819856000044],
              [-80.883994247999965, 35.112745855000071],
              [-80.884339247999947, 35.112673855000025],
              [-80.88467824899999, 35.112609855000073],
              [-80.884859246999952, 35.112582856000074],
              [-80.885195247999945, 35.112545855000064],
              [-80.88532024899996, 35.112541855000075],
              [-80.885594247999961, 35.112545855000064]
            ]
          ],
          [
            [
              [-80.877847246999977, 35.140237862000049],
              [-80.877965247999953, 35.140263861000051],
              [-80.878077246999965, 35.140306861000056],
              [-80.87879924799995, 35.14068386100007],
              [-80.879001248999941, 35.140768862000073],
              [-80.879326247999984, 35.140877861000035],
              [-80.879784247999964, 35.141011862000028],
              [-80.880254248999961, 35.141130861000079],
              [-80.880397247999952, 35.141162862000044],
              [-80.880752248999954, 35.141224861000069],
              [-80.880818248999958, 35.141232862000038],
              [-80.880951247999974, 35.141234862000033],
              [-80.881022247999965, 35.141229862000046],
              [-80.881146248999983, 35.141206862000047],
              [-80.881264248999969, 35.141167861000042],
              [-80.881388247999951, 35.141105861000028],
              [-80.881524248999938, 35.141014861000031],
              [-80.882396248999953, 35.140340861000027],
              [-80.882554248999952, 35.140214861000061],
              [-80.882872247999956, 35.139961861000074],
              [-80.882983249999938, 35.139872862000061],
              [-80.883187248999945, 35.139664861000028],
              [-80.883373249999977, 35.139446860000078],
              [-80.883518248999962, 35.139251861000048],
              [-80.883567248999952, 35.139178861000062],
              [-80.883694248999973, 35.13898886100003],
              [-80.884173248999957, 35.138269861000026],
              [-80.884322248999979, 35.138044861000026],
              [-80.884382248999941, 35.137930861000029],
              [-80.884414248999974, 35.137833861000047],
              [-80.884433248999983, 35.137723860000051],
              [-80.884431248999988, 35.137639861000025],
              [-80.884420248999959, 35.137586861000045],
              [-80.884325248999971, 35.137285861000066],
              [-80.883926248999956, 35.137379860000067],
              [-80.881651248999958, 35.137909861000026],
              [-80.881465247999984, 35.137942861000056],
              [-80.881322248999936, 35.137962861000062],
              [-80.881037247999984, 35.137989861000051],
              [-80.880823247999956, 35.138000862000069],
              [-80.880532247999952, 35.137995861000036],
              [-80.880216247999954, 35.137969861000045],
              [-80.87935424899996, 35.137846861000071],
              [-80.879336247999959, 35.137455861000035],
              [-80.879337247999956, 35.137165861000028],
              [-80.879351247999978, 35.136860861000059],
              [-80.879376247999971, 35.136596861000044],
              [-80.879409247999945, 35.136390861000052],
              [-80.879424247999964, 35.136298860000068],
              [-80.879474247999951, 35.136068861000069],
              [-80.879560247999962, 35.13574286000005],
              [-80.87965524699996, 35.135455860000036],
              [-80.87973324799998, 35.135252861000026],
              [-80.879882247999944, 35.134914860000038],
              [-80.879984247999971, 35.13471786000008],
              [-80.880769247999979, 35.133287860000053],
              [-80.88134724799994, 35.132229860000052],
              [-80.881690248999973, 35.131604859000049],
              [-80.882025247999934, 35.130995859000052],
              [-80.882175247999953, 35.130714859000079],
              [-80.882516247999945, 35.130087859000071],
              [-80.882002247999935, 35.129917859000045],
              [-80.88009324799998, 35.129274859000077],
              [-80.878580245999956, 35.128784859000064],
              [-80.877672246999964, 35.12847885900004],
              [-80.877149245999988, 35.128293859000053],
              [-80.876974246999964, 35.128235858000039],
              [-80.876753246999954, 35.128162859000042],
              [-80.876567245999979, 35.128430858000058],
              [-80.876368246999959, 35.128743858000064],
              [-80.876268245999938, 35.128919859000064],
              [-80.876187246999962, 35.129064859000039],
              [-80.876104245999954, 35.129227860000071],
              [-80.875951245999943, 35.12960085900005],
              [-80.875605246999953, 35.130485859000032],
              [-80.875540245999957, 35.130655859000058],
              [-80.875472245999958, 35.130853860000059],
              [-80.875391245999936, 35.131141860000071],
              [-80.87535924599996, 35.131286860000046],
              [-80.875327245999983, 35.131462860000056],
              [-80.875280245999988, 35.131880859000034],
              [-80.875265245999969, 35.132090861000052],
              [-80.875299246999987, 35.132696860000067],
              [-80.875729246999981, 35.134780860000035],
              [-80.875831246999951, 35.135244861000047],
              [-80.875987245999966, 35.135956860000078],
              [-80.876031245999968, 35.136155860000031],
              [-80.876338245999989, 35.137725861000035],
              [-80.876684247999947, 35.137719861000051],
              [-80.876692246999937, 35.137760861000061],
              [-80.876797247999946, 35.138950861000069],
              [-80.877116246999947, 35.140341862000071],
              [-80.877527246999989, 35.140337861000035],
              [-80.877534246999971, 35.140328862000047],
              [-80.877592247999985, 35.140281861000062],
              [-80.877664246999984, 35.140249862000076],
              [-80.877702247999935, 35.140238862000047],
              [-80.877783246999968, 35.140230861000077],
              [-80.877847246999977, 35.140237862000049]
            ]
          ],
          [
            [
              [-80.876997248999942, 35.156601865000027],
              [-80.878228247999971, 35.156763865000073],
              [-80.881161249999934, 35.158181866000064],
              [-80.88131924999999, 35.157614865000028],
              [-80.882423248999942, 35.157614865000028],
              [-80.883306249999976, 35.157356865000054],
              [-80.884758250999937, 35.156891865000034],
              [-80.885388249999949, 35.156479865000051],
              [-80.886082250999948, 35.156582865000075],
              [-80.887016250999977, 35.157074865000027],
              [-80.88733425099997, 35.156644865000032],
              [-80.887690250999981, 35.156233865000047],
              [-80.888241250999954, 35.155548864000025],
              [-80.888324251999961, 35.155471864000049],
              [-80.888371251999956, 35.155437865000067],
              [-80.888450250999938, 35.155392864000078],
              [-80.888512250999952, 35.155364864000035],
              [-80.888643250999962, 35.15532186400003],
              [-80.888699251999981, 35.155308864000062],
              [-80.888958251999952, 35.15526886400005],
              [-80.889476251999952, 35.15522786400004],
              [-80.890254251999977, 35.155103865000058],
              [-80.890222251999944, 35.154984864000028],
              [-80.890240251999955, 35.154871864000029],
              [-80.890230251999981, 35.154770864000056],
              [-80.890199251999945, 35.15455886500007],
              [-80.890181251999934, 35.154466863000039],
              [-80.890146251999965, 35.154399865000073],
              [-80.890129251999952, 35.154283864000035],
              [-80.890120251999974, 35.154143864000048],
              [-80.890122252999959, 35.154069865000054],
              [-80.89013625299998, 35.153996864000078],
              [-80.890162251999982, 35.153950864000024],
              [-80.890166250999982, 35.15390986400007],
              [-80.890178251999941, 35.15388086400003],
              [-80.890198251999948, 35.153784864000045],
              [-80.890230251999981, 35.153675864000036],
              [-80.890232251999976, 35.153632864000031],
              [-80.890275251999981, 35.153481864000071],
              [-80.890290251999943, 35.153368865000061],
              [-80.890312251999944, 35.153274864000025],
              [-80.890356251999947, 35.153178863000051],
              [-80.890362251999989, 35.153141864000077],
              [-80.890330251999956, 35.153069863000042],
              [-80.890379250999956, 35.152983863000031],
              [-80.890394251999965, 35.152824863000035],
              [-80.889219250999986, 35.153000864000035],
              [-80.889162251999949, 35.152589864000049],
              [-80.88908225199998, 35.152070863000063],
              [-80.887997251999934, 35.152233864000038],
              [-80.887832250999963, 35.152245863000076],
              [-80.887690250999981, 35.152248864000057],
              [-80.887505250999936, 35.152238864000026],
              [-80.887414250999939, 35.152227864000054],
              [-80.886958250999953, 35.152147864000028],
              [-80.886497249999934, 35.152061864000075],
              [-80.886617249999972, 35.151582863000044],
              [-80.886653250999984, 35.151522863000025],
              [-80.886678250999978, 35.151497864000078],
              [-80.886768250999978, 35.151424863000045],
              [-80.886904251999965, 35.15129586300003],
              [-80.886939250999944, 35.151238864000049],
              [-80.886957250999956, 35.151175864000038],
              [-80.886956250999958, 35.15111186300004],
              [-80.886935249999965, 35.151049863000026],
              [-80.886894250999944, 35.150977863000037],
              [-80.886837250999974, 35.150916863000077],
              [-80.886755250999954, 35.150860863000048],
              [-80.886669250999944, 35.150824863000025],
              [-80.886576249999962, 35.15080286400007],
              [-80.88579925099998, 35.150763863000066],
              [-80.884878248999939, 35.15072886300004],
              [-80.884762249999937, 35.15072886400003],
              [-80.884648249999941, 35.150717863000068],
              [-80.884455249999974, 35.150690864000069],
              [-80.884386250999967, 35.150651864000054],
              [-80.884374248999961, 35.150613864000036],
              [-80.884346249999965, 35.150553864000074],
              [-80.884259249999957, 35.149364864000063],
              [-80.88425424899998, 35.149302863000059],
              [-80.882522248999976, 35.149411864000058],
              [-80.882484248999958, 35.149004863000073],
              [-80.882439248999958, 35.148679863000041],
              [-80.882184249999966, 35.148689864000062],
              [-80.882013247999964, 35.148683863000031],
              [-80.881864249999978, 35.148670864000053],
              [-80.88142124899997, 35.148582863000058],
              [-80.881265248999966, 35.148566863000042],
              [-80.881113248999952, 35.148557863000065],
              [-80.880983248999939, 35.14855986300006],
              [-80.880723248999971, 35.148595863000025],
              [-80.880521248999969, 35.148633863000043],
              [-80.880086247999941, 35.14873186300008],
              [-80.879972248999934, 35.148775863000026],
              [-80.879881248999936, 35.148844863000079],
              [-80.879820248999977, 35.14891586300007],
              [-80.879777248999972, 35.14900186400007],
              [-80.879734247999977, 35.149230863000071],
              [-80.879696247999959, 35.149309863000042],
              [-80.879641247999984, 35.149368863000063],
              [-80.879558248999956, 35.14942986300008],
              [-80.879466247999972, 35.14947486300008],
              [-80.87936624799994, 35.149500863000071],
              [-80.879266247999965, 35.149511863000043],
              [-80.87916624799999, 35.149503864000053],
              [-80.879062248999958, 35.149484864000044],
              [-80.878957247999949, 35.14943786300006],
              [-80.878852247999987, 35.149365863000071],
              [-80.878769248999959, 35.149315863000027],
              [-80.878658247999965, 35.149278863000063],
              [-80.878530248999937, 35.149260863000052],
              [-80.878491247999989, 35.149488864000034],
              [-80.878293247999977, 35.15082586300008],
              [-80.878148248999935, 35.151652864000027],
              [-80.877857247999941, 35.152932864000036],
              [-80.877299247999986, 35.15284086500003],
              [-80.876997248999942, 35.156601865000027]
            ]
          ],
          [
            [
              [-80.835420236999937, 35.164250868000067],
              [-80.835529235999957, 35.164258868000047],
              [-80.83623623699998, 35.164352868000037],
              [-80.836324235999939, 35.163934868000069],
              [-80.836380235999968, 35.163692867000066],
              [-80.836437235999938, 35.163493868000046],
              [-80.836483236999982, 35.163365868000028],
              [-80.836590236999939, 35.163114868000036],
              [-80.836717236999959, 35.16286986700004],
              [-80.836805236999965, 35.162735867000038],
              [-80.836913236999976, 35.162581868000075],
              [-80.837138235999987, 35.162304867000046],
              [-80.837377235999952, 35.162057867000044],
              [-80.837479235999979, 35.161960868000051],
              [-80.837835236999979, 35.161622867000062],
              [-80.838026236999951, 35.161433868000074],
              [-80.838102236999987, 35.161348867000072],
              [-80.837650235999945, 35.161076867000077],
              [-80.83755423599996, 35.160998867000046],
              [-80.837476235999986, 35.160908868000035],
              [-80.837414235999972, 35.160810867000066],
              [-80.837391235999974, 35.160758868000073],
              [-80.837360236999984, 35.160650867000072],
              [-80.837349236999955, 35.160540868000055],
              [-80.837376235999955, 35.160148868000078],
              [-80.837428236999983, 35.159356868000032],
              [-80.837439235999966, 35.159067867000033],
              [-80.837427236999986, 35.158939867000072],
              [-80.837413235999975, 35.158872867000071],
              [-80.837369235999972, 35.15874186700006],
              [-80.837339236999981, 35.158675867000056],
              [-80.837304235999966, 35.158611867000047],
              [-80.837755235999964, 35.158395867000024],
              [-80.838648236999973, 35.158025867000049],
              [-80.838868236999986, 35.157935866000059],
              [-80.838920236999968, 35.15792086600004],
              [-80.838973236999948, 35.157910867000055],
              [-80.839081236999959, 35.157902867000075],
              [-80.840304236999941, 35.157955866000066],
              [-80.840494236999973, 35.157953866000071],
              [-80.84058923799995, 35.157946866000032],
              [-80.840775236999946, 35.157919866000043],
              [-80.840868236999938, 35.157899867000026],
              [-80.841024236999942, 35.15785586700008],
              [-80.841076236999982, 35.157835867000074],
              [-80.841145236999978, 35.157805867000036],
              [-80.84134023699994, 35.15769186700004],
              [-80.841471236999951, 35.157607867000024],
              [-80.84212023799995, 35.157155866000039],
              [-80.842506237999942, 35.156884866000041],
              [-80.842593237999949, 35.156827866000071],
              [-80.842733238999983, 35.156755867000072],
              [-80.842891237999936, 35.156694866000066],
              [-80.842972236999969, 35.15667086600007],
              [-80.843704237999987, 35.156477866000046],
              [-80.843484237999974, 35.155889866000052],
              [-80.843460237999977, 35.155771866000066],
              [-80.843456237999987, 35.155675866000024],
              [-80.84346323799997, 35.155569866000064],
              [-80.843509238999957, 35.15529086600003],
              [-80.843534236999972, 35.155140866000067],
              [-80.843616237999981, 35.154618866000078],
              [-80.843619237999974, 35.154552866000074],
              [-80.84361023799994, 35.154432866000036],
              [-80.843581237999956, 35.154272865000053],
              [-80.843542237999941, 35.154146866000076],
              [-80.843503237999983, 35.154050866000034],
              [-80.843131237999955, 35.153414866000048],
              [-80.843038237999963, 35.153260866000039],
              [-80.843013237999969, 35.153211866000049],
              [-80.842979237999941, 35.153111866000074],
              [-80.842966237999974, 35.153030866000051],
              [-80.842969237999966, 35.152925865000043],
              [-80.842984237999985, 35.152848865000067],
              [-80.84309223799994, 35.152468866000049],
              [-80.843138237999938, 35.152290866000044],
              [-80.843249237999942, 35.151899865000075],
              [-80.84193223799997, 35.151670866000075],
              [-80.841672237999944, 35.151599865000037],
              [-80.841741236999951, 35.151455866000049],
              [-80.842549237999947, 35.149775865000038],
              [-80.842994236999971, 35.148872864000055],
              [-80.843244237999954, 35.148365864000027],
              [-80.84347923699994, 35.147919865000063],
              [-80.843780237999965, 35.147348864000037],
              [-80.844061236999949, 35.146810864000031],
              [-80.844121236999968, 35.146702864000076],
              [-80.844160236999983, 35.146633865000069],
              [-80.844380236999939, 35.146248864000029],
              [-80.84482223699996, 35.145417863000034],
              [-80.845092238999939, 35.144905864000066],
              [-80.843801237999969, 35.144828864000033],
              [-80.843027235999955, 35.144777864000048],
              [-80.842847236999944, 35.144748864000064],
              [-80.842805236999936, 35.144740864000028],
              [-80.842749237999953, 35.144715863000044],
              [-80.84268723699995, 35.144662864000054],
              [-80.842658236999966, 35.144629864000024],
              [-80.842594236999958, 35.144529863000059],
              [-80.842568236999966, 35.144476864000069],
              [-80.842507237999939, 35.144293864000076],
              [-80.842248235999989, 35.14344886300006],
              [-80.840838235999968, 35.143738863000067],
              [-80.841330236999966, 35.145365864000041],
              [-80.841337236999948, 35.145411864000039],
              [-80.841338236999945, 35.145456864000039],
              [-80.841330236999966, 35.145518863000063],
              [-80.841300236999984, 35.145609863000061],
              [-80.841249236999943, 35.145694864000063],
              [-80.841210236999984, 35.145738864000066],
              [-80.841120236999984, 35.145809864000057],
              [-80.840693236999982, 35.146063864000041],
              [-80.840566236999962, 35.146134864000032],
              [-80.839657235999937, 35.146661865000056],
              [-80.839548236999974, 35.146708864000061],
              [-80.839430235999941, 35.146740864000037],
              [-80.839308235999965, 35.146756865000043],
              [-80.839165236999975, 35.146759864000046],
              [-80.838989235999975, 35.146762864000038],
              [-80.838533235999989, 35.146756865000043],
              [-80.837162235999983, 35.146730865000052],
              [-80.836972235999951, 35.146734865000042],
              [-80.83686423599994, 35.146748864000074],
              [-80.836551235999934, 35.146755865000046],
              [-80.836584235999965, 35.145777864000024],
              [-80.836612235999951, 35.144952864000061],
              [-80.836178235999967, 35.144948863000025],
              [-80.835994235999976, 35.144956864000051],
              [-80.835903234999989, 35.144967863000034],
              [-80.83580223499996, 35.144985863000045],
              [-80.835074234999979, 35.145146864000026],
              [-80.833995234999975, 35.145387864000043],
              [-80.833275234999974, 35.14554886500008],
              [-80.833161234999977, 35.145556864000071],
              [-80.833078234999959, 35.145552864000024],
              [-80.832983234999972, 35.145539864000057],
              [-80.832449233999967, 35.145439864000025],
              [-80.832398234999971, 35.145418864000078],
              [-80.832187233999946, 35.145855864000055],
              [-80.831902233999983, 35.146421864000047],
              [-80.831607233999989, 35.147007865000035],
              [-80.831342234999966, 35.147532864000027],
              [-80.83104423399999, 35.148122865000062],
              [-80.83098423399997, 35.148242865000043],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.830435234999982, 35.155301866000059],
              [-80.830356232999975, 35.155412866000063],
              [-80.830202234999945, 35.155652866000025],
              [-80.830141233999939, 35.155760867000026],
              [-80.830050234999987, 35.155938867000032],
              [-80.830027233999942, 35.15598486600004],
              [-80.829903232999982, 35.156206866000048],
              [-80.82984823399994, 35.156292867000047],
              [-80.829788232999988, 35.156376866000073],
              [-80.829625233999934, 35.156577867000067],
              [-80.829515232999938, 35.156692867000061],
              [-80.82936323399997, 35.156822867000074],
              [-80.829199233999987, 35.156945867000047],
              [-80.82904423399998, 35.157041867000032],
              [-80.828915233999965, 35.157104867000044],
              [-80.828738233999957, 35.157182867000074],
              [-80.828605233999951, 35.15723386600007],
              [-80.82836223299995, 35.157301867000058],
              [-80.828249233999941, 35.157336867000026],
              [-80.827719232999982, 35.157500868000056],
              [-80.827462233999938, 35.157580867000036],
              [-80.827571232999958, 35.157854867000026],
              [-80.827650232999986, 35.15809686700004],
              [-80.827696232999983, 35.158286867000072],
              [-80.827710233999937, 35.158345867000037],
              [-80.827743233999968, 35.158551867000028],
              [-80.827770233999956, 35.158957867000026],
              [-80.827752233999945, 35.15989386800004],
              [-80.828045233999944, 35.159892867000053],
              [-80.828225234999934, 35.159899868000025],
              [-80.828348233999975, 35.159898867000038],
              [-80.828472233999946, 35.159903868000072],
              [-80.82857923399996, 35.159913868000046],
              [-80.828685233999977, 35.159928868000065],
              [-80.828893232999974, 35.159968867000032],
              [-80.829092233999972, 35.160034868000025],
              [-80.82932623399995, 35.160134867000068],
              [-80.829576233999944, 35.160249867000061],
              [-80.830326233999983, 35.160600868000074],
              [-80.83051923499994, 35.160698868000054],
              [-80.830666233999978, 35.160763867000071],
              [-80.830719234999947, 35.160781867000026],
              [-80.830844234999972, 35.160811868000053],
              [-80.830975233999936, 35.160824868000077],
              [-80.831042234999984, 35.160824867000031],
              [-80.831175233999943, 35.16081286800005],
              [-80.831240234999939, 35.160798868000029],
              [-80.831439234999948, 35.160725867000053],
              [-80.831705234999959, 35.160636867000051],
              [-80.831816233999973, 35.160605868000061],
              [-80.831933234999951, 35.160594867000043],
              [-80.832054234999987, 35.160597868000025],
              [-80.832113234999952, 35.16060686700007],
              [-80.832227234999948, 35.160634867000056],
              [-80.83228223499998, 35.16065586700006],
              [-80.832561234999957, 35.160781867000026],
              [-80.832764234999956, 35.160858868000048],
              [-80.832956234999983, 35.160938867000027],
              [-80.833034235999946, 35.160981867000032],
              [-80.833204234999982, 35.161085868000043],
              [-80.833510235999938, 35.16132286800007],
              [-80.833662234999963, 35.161419868000053],
              [-80.833830235999983, 35.161511868000048],
              [-80.834055235999983, 35.161621867000065],
              [-80.834200234999969, 35.161676868000029],
              [-80.834355235999965, 35.161727868000071],
              [-80.834599235999974, 35.161794868000072],
              [-80.835003235999977, 35.161881867000034],
              [-80.835195235999947, 35.161939867000058],
              [-80.835602235999943, 35.162090868000064],
              [-80.835212235999961, 35.162813868000057],
              [-80.835070236999968, 35.163141868000025],
              [-80.834949236999989, 35.163474868000037],
              [-80.83491223599998, 35.163608869000029],
              [-80.834895234999976, 35.163699868000037],
              [-80.834884235999937, 35.16379286800003],
              [-80.834879235999949, 35.163924868000038],
              [-80.834886235999988, 35.164255868000055],
              [-80.835201235999989, 35.164246868000077],
              [-80.835420236999937, 35.164250868000067]
            ]
          ],
          [
            [
              [-80.848621239999943, 35.160053867000045],
              [-80.848429238999984, 35.160086867000075],
              [-80.848378238999942, 35.160136867000062],
              [-80.848328239999944, 35.160222867000073],
              [-80.848265238999943, 35.160381867000069],
              [-80.84821723899995, 35.160547867000048],
              [-80.848125238999955, 35.160935867000035],
              [-80.848042238999938, 35.161174867000057],
              [-80.847805238999968, 35.162039867000033],
              [-80.847735239999963, 35.162192867000044],
              [-80.847662239999977, 35.162273867000067],
              [-80.847590239999988, 35.162335867000024],
              [-80.847472239999945, 35.162391867000053],
              [-80.847333239999955, 35.162467868000078],
              [-80.847224238999956, 35.162566867000066],
              [-80.847197239999957, 35.162688868000032],
              [-80.847276239999985, 35.162894868000024],
              [-80.847338238999953, 35.163021868000044],
              [-80.847417238999981, 35.163208867000037],
              [-80.847508239999968, 35.163490868000054],
              [-80.847533239999962, 35.163626868000051],
              [-80.847550239999975, 35.163715867000064],
              [-80.84757923899997, 35.163995868000029],
              [-80.847568239999987, 35.164178867000032],
              [-80.84753423899997, 35.16429486800007],
              [-80.847483239999974, 35.164350867000053],
              [-80.847374240999955, 35.164424868000026],
              [-80.847271239999941, 35.164481868000053],
              [-80.84714723999997, 35.164556868000034],
              [-80.846916238999938, 35.164672868000025],
              [-80.846805238999934, 35.164716868000028],
              [-80.846599238999943, 35.164768868000067],
              [-80.846483239999941, 35.164873869000076],
              [-80.846403238999983, 35.164972869000053],
              [-80.846237238999947, 35.165209867000044],
              [-80.846110238999984, 35.165433868000036],
              [-80.846002239999962, 35.16560086800007],
              [-80.845842239999968, 35.165724868000041],
              [-80.845646238999961, 35.165910868000026],
              [-80.845548238999982, 35.166031868000061],
              [-80.845303238999975, 35.16633286900003],
              [-80.844993238999962, 35.166751868000063],
              [-80.844855239999958, 35.166869868000049],
              [-80.844814238999959, 35.166901869000071],
              [-80.84473823999997, 35.166962869000031],
              [-80.844526238999947, 35.167093869000041],
              [-80.844499238999958, 35.167197868000073],
              [-80.844523238999955, 35.167270869000049],
              [-80.844571238999947, 35.167323869000029],
              [-80.844687238999938, 35.167453868000052],
              [-80.844807238999977, 35.167543868000052],
              [-80.844935239999984, 35.167614868000044],
              [-80.845010238999976, 35.167643869000074],
              [-80.845129238999959, 35.167678868000053],
              [-80.845234238999979, 35.167701869000041],
              [-80.84547323999999, 35.167770868000048],
              [-80.845704238999986, 35.167821868000033],
              [-80.846247239999968, 35.167886868000039],
              [-80.846418238999945, 35.167920868000067],
              [-80.846583239999973, 35.167979868000032],
              [-80.846710239999936, 35.168044869000028],
              [-80.84680923999997, 35.168127868000056],
              [-80.846832239999969, 35.168182869000077],
              [-80.846850239999981, 35.168322868000075],
              [-80.846921238999983, 35.168515869000032],
              [-80.846998239999948, 35.168810869000026],
              [-80.847046239999941, 35.168955869000058],
              [-80.847071238999945, 35.169083869000076],
              [-80.847036239999966, 35.169162869000047],
              [-80.847014239999965, 35.169187868000051],
              [-80.846705239999949, 35.169308869000076],
              [-80.846559238999987, 35.169401869000069],
              [-80.846480239999948, 35.169506869000031],
              [-80.846335239999974, 35.16964887000006],
              [-80.846270239999967, 35.169753869000033],
              [-80.846191239999939, 35.169845869000028],
              [-80.846141238999962, 35.169931868000049],
              [-80.846031238999956, 35.170143868000025],
              [-80.845909239999969, 35.170322869000074],
              [-80.84580823999994, 35.170482869000068],
              [-80.845607238999946, 35.17074186900004],
              [-80.845527238999978, 35.170833869000035],
              [-80.845462238999971, 35.170895869000049],
              [-80.845419238999966, 35.170975869000074],
              [-80.845377238999959, 35.171085869000024],
              [-80.845375238999964, 35.171120869000049],
              [-80.845366239999976, 35.171262869000032],
              [-80.845402239999942, 35.171350870000026],
              [-80.845493239999939, 35.171470869000075],
              [-80.845615239999972, 35.171590869000056],
              [-80.845713239999952, 35.17166886900003],
              [-80.845848239999953, 35.171757870000079],
              [-80.846308239999985, 35.172079869000072],
              [-80.846414239999945, 35.172169870000062],
              [-80.846527239999944, 35.172234869000079],
              [-80.846581238999988, 35.172306870000057],
              [-80.846597238999948, 35.172361870000032],
              [-80.846598238999945, 35.172422869000059],
              [-80.846587239999963, 35.172574870000062],
              [-80.846527238999954, 35.172756869000068],
              [-80.846521238999969, 35.172778870000059],
              [-80.846515240999963, 35.172865870000066],
              [-80.846525239999949, 35.17298087000006],
              [-80.846529239999938, 35.173151870000027],
              [-80.846519239999964, 35.173358871000062],
              [-80.846520239999961, 35.173422870000024],
              [-80.846527238999954, 35.173693870000079],
              [-80.846524239999951, 35.173891870000034],
              [-80.846525239999949, 35.173914870000033],
              [-80.846540239999968, 35.17405787000007],
              [-80.846560239999974, 35.174218870000061],
              [-80.846569239999951, 35.174387870000032],
              [-80.846572239999944, 35.174553870000068],
              [-80.84658423999997, 35.174743871000032],
              [-80.846580238999934, 35.175028870000062],
              [-80.846578238999939, 35.175133870000025],
              [-80.84658823999996, 35.175236870000049],
              [-80.846627240999965, 35.175519870000073],
              [-80.846647239999982, 35.175726870000062],
              [-80.846650240999963, 35.175872871000024],
              [-80.846634239999958, 35.176122870000029],
              [-80.846540239999968, 35.17658687100004],
              [-80.846519239999964, 35.17679787000003],
              [-80.846515240999963, 35.17696187100006],
              [-80.84649023999998, 35.177163870000072],
              [-80.846455239999955, 35.177553871000043],
              [-80.846402239999975, 35.177758871000037],
              [-80.846393240999987, 35.177791871000068],
              [-80.846257240999989, 35.178479870000047],
              [-80.84622423999997, 35.17862787100006],
              [-80.846196240999973, 35.178967871000054],
              [-80.846590239999955, 35.179050871000072],
              [-80.846894239999983, 35.179122871000061],
              [-80.848875241999963, 35.179629871000031],
              [-80.849413240999979, 35.179767871000024],
              [-80.849676241999987, 35.179830871000036],
              [-80.849830241999939, 35.179873871000041],
              [-80.850018240999987, 35.179909871000064],
              [-80.850435241999946, 35.179954870000074],
              [-80.851363241999934, 35.180022871000062],
              [-80.851201241999945, 35.178971871000044],
              [-80.851162241999987, 35.178714871000068],
              [-80.851130241999954, 35.178544871000042],
              [-80.851088240999957, 35.178278870000042],
              [-80.851057241999968, 35.177973870000073],
              [-80.851035241999966, 35.177737871000033],
              [-80.851018241999952, 35.177363871000068],
              [-80.851016240999968, 35.177188870000066],
              [-80.85102324099995, 35.176714870000069],
              [-80.851034241999969, 35.176368870000033],
              [-80.851045240999952, 35.176164871000026],
              [-80.851030240999989, 35.175930871000048],
              [-80.851042241999949, 35.175758870000038],
              [-80.851092241999936, 35.175047870000071],
              [-80.851134241999944, 35.174351870000066],
              [-80.851177241999949, 35.173800870000036],
              [-80.85121824099997, 35.173354870000026],
              [-80.851233241999978, 35.173038870000028],
              [-80.85123224199998, 35.172761870000045],
              [-80.851213241999972, 35.172469869000054],
              [-80.85116224099994, 35.172079870000061],
              [-80.851042241999949, 35.171274868000069],
              [-80.850945240999977, 35.170531870000048],
              [-80.85090723999997, 35.170224869000037],
              [-80.850734239999952, 35.168817868000076],
              [-80.85065124099998, 35.168206869000073],
              [-80.850499240999966, 35.167339868000056],
              [-80.850442240999939, 35.166706869000052],
              [-80.850399239999945, 35.165665868000076],
              [-80.850267240999983, 35.165027868000038],
              [-80.850175239999942, 35.164590867000072],
              [-80.850123240999949, 35.164422868000031],
              [-80.850053240999955, 35.164259867000055],
              [-80.849966240999947, 35.164102867000054],
              [-80.849862239999936, 35.163952868000024],
              [-80.849679239999944, 35.163732868000068],
              [-80.849584239999956, 35.163596867000024],
              [-80.849501240999984, 35.163455867000039],
              [-80.849430239999947, 35.163310868000053],
              [-80.849360240999943, 35.163123867000024],
              [-80.849317240999937, 35.162971868000056],
              [-80.849245240999949, 35.162686868000037],
              [-80.849155239999959, 35.162336868000068],
              [-80.849053239999989, 35.16193486700007],
              [-80.848916240999984, 35.161179867000044],
              [-80.848833239999976, 35.160465867000028],
              [-80.848785239999984, 35.160046867000062],
              [-80.848621239999943, 35.160053867000045]
            ]
          ],
          [
            [
              [-80.87909824999997, 35.17587486900004],
              [-80.879178249999939, 35.176164870000036],
              [-80.879403248999949, 35.177497869000035],
              [-80.879433249999977, 35.177954869000075],
              [-80.879583249999939, 35.177997869000023],
              [-80.879658249999977, 35.178013870000029],
              [-80.880131249999977, 35.178139869000063],
              [-80.880662249999943, 35.178292870000064],
              [-80.880826250999974, 35.178339870000059],
              [-80.881113250999988, 35.178405869000073],
              [-80.883076251999967, 35.178925870000057],
              [-80.883477250999988, 35.179038869000067],
              [-80.883782251999946, 35.179119870000079],
              [-80.884355251999978, 35.179287870000053],
              [-80.884808251999971, 35.179418870000063],
              [-80.88501925099996, 35.17947987000008],
              [-80.886461251999947, 35.179905869000038],
              [-80.886632252999959, 35.179956870000069],
              [-80.886863252999945, 35.180024870000068],
              [-80.886711251999941, 35.179521870000031],
              [-80.886573252999938, 35.179121870000074],
              [-80.886473251999973, 35.178814869000064],
              [-80.886372252999934, 35.178448870000068],
              [-80.886311251999985, 35.178165869000054],
              [-80.88625725199995, 35.177781869000057],
              [-80.886229251999964, 35.177461869000069],
              [-80.886220251999987, 35.177023869000038],
              [-80.886235251999949, 35.176663869000038],
              [-80.886265250999941, 35.176341870000044],
              [-80.886291251999978, 35.176150868000036],
              [-80.886355251999987, 35.175787869000033],
              [-80.886396252999987, 35.175602868000055],
              [-80.886493252999969, 35.175234868000075],
              [-80.886612251999964, 35.174822869000025],
              [-80.886487251999938, 35.174635868000053],
              [-80.886305251999943, 35.174362869000049],
              [-80.885668251999959, 35.173406868000029],
              [-80.885296251999989, 35.173723869000071],
              [-80.885047251999936, 35.175446868000051],
              [-80.884485251999934, 35.175479869000071],
              [-80.882464250999988, 35.175618869000061],
              [-80.882239250999987, 35.175635869000075],
              [-80.879283249999958, 35.175851869000041],
              [-80.879165248999982, 35.175357869000038],
              [-80.878895248999982, 35.174230869000041],
              [-80.878608249999957, 35.173082869000041],
              [-80.878463248999935, 35.172462869000071],
              [-80.878434249999941, 35.172290868000061],
              [-80.878423248999979, 35.17221986800007],
              [-80.878247248999969, 35.171485868000048],
              [-80.878211247999957, 35.171375868000041],
              [-80.878139248999958, 35.171105868000041],
              [-80.878080248999936, 35.170795868000027],
              [-80.878065249999963, 35.170673867000062],
              [-80.878044249999959, 35.170259868000073],
              [-80.878309248999983, 35.170221868000056],
              [-80.880204249999963, 35.169954868000048],
              [-80.880244248999986, 35.169941868000024],
              [-80.880307249999987, 35.169906868000055],
              [-80.880339248999974, 35.169882868000059],
              [-80.880394248999949, 35.169823868000037],
              [-80.88054724999995, 35.169597868000039],
              [-80.88067424999997, 35.169390867000061],
              [-80.880702250999946, 35.169337868000071],
              [-80.880743249999966, 35.169227868000064],
              [-80.880918249999979, 35.168487867000067],
              [-80.880935249999936, 35.168443868000054],
              [-80.880958248999946, 35.168400868000049],
              [-80.880986249999978, 35.168360867000047],
              [-80.881057249999969, 35.168286868000052],
              [-80.881099249999977, 35.168253867000033],
              [-80.881179248999956, 35.168206868000027],
              [-80.881787249999945, 35.167918868000072],
              [-80.882064249999985, 35.16779286700006],
              [-80.882143249999956, 35.167762868000068],
              [-80.881197249999957, 35.166249867000033],
              [-80.880654249999964, 35.165551867000033],
              [-80.880497248999973, 35.165349867000032],
              [-80.878297247999967, 35.161949867000033],
              [-80.878251248999959, 35.161843866000027],
              [-80.877297247999934, 35.159649866000052],
              [-80.877109248999943, 35.158869865000042],
              [-80.876994248999949, 35.158315866000066],
              [-80.876897247999977, 35.15784986500006],
              [-80.876927247999959, 35.158309865000035],
              [-80.876965247999976, 35.158885866000048],
              [-80.877197247999959, 35.162949866000076],
              [-80.877455248999979, 35.165107867000074],
              [-80.877471247999949, 35.165368867000041],
              [-80.877585247999946, 35.167158867000069],
              [-80.877639248999969, 35.168020867000052],
              [-80.877723248999985, 35.16907586800005],
              [-80.877762248999943, 35.169558867000035],
              [-80.877857248999987, 35.17027986800008],
              [-80.877906248999977, 35.170687869000062],
              [-80.878021248999971, 35.171408868000071],
              [-80.878213248999941, 35.17231786900004],
              [-80.878624249999973, 35.174153869000065],
              [-80.879059249999955, 35.175718869000036],
              [-80.879081249999956, 35.175812870000073],
              [-80.87909824999997, 35.17587486900004]
            ]
          ],
          [
            [
              [-80.873304248999943, 35.194455873000038],
              [-80.87447224999994, 35.194872873000065],
              [-80.874775249999971, 35.194973873000038],
              [-80.875134249999974, 35.195098874000053],
              [-80.87548524999994, 35.195221873000037],
              [-80.876259248999986, 35.195511874000033],
              [-80.876885249999987, 35.195736873000044],
              [-80.877218249999942, 35.195867873000054],
              [-80.877931249999961, 35.194949872000052],
              [-80.878011249999986, 35.194845874000066],
              [-80.878146250999976, 35.194667873000071],
              [-80.879313250999985, 35.193183873000066],
              [-80.879918250999935, 35.19241687300007],
              [-80.880481250999935, 35.191700872000069],
              [-80.88131225099994, 35.190641872000072],
              [-80.881465250999952, 35.19044787200005],
              [-80.882041250999976, 35.189716872000076],
              [-80.882472251999957, 35.189159872000062],
              [-80.882548250999946, 35.189054872000042],
              [-80.883367251999971, 35.188013872000056],
              [-80.884355251999978, 35.186758871000052],
              [-80.884705251999947, 35.185164870000051],
              [-80.88444825299996, 35.18531487100006],
              [-80.884115252999948, 35.185492871000065],
              [-80.884014251999986, 35.185422871000071],
              [-80.883953251999969, 35.185385871000051],
              [-80.883872251999946, 35.185344871000041],
              [-80.882312250999973, 35.184635871000069],
              [-80.882227250999961, 35.184588871000074],
              [-80.882101250999938, 35.184528871000055],
              [-80.882050250999953, 35.184506870000064],
              [-80.881759250999949, 35.184381871000028],
              [-80.881189250999967, 35.184120871000061],
              [-80.880523250999943, 35.183824870000024],
              [-80.880010250999987, 35.183601870000075],
              [-80.879423250999935, 35.183325870000033],
              [-80.879128249999951, 35.183757871000068],
              [-80.878565249999951, 35.184541871000079],
              [-80.878478249999944, 35.184782871000039],
              [-80.878439250999975, 35.184872871000039],
              [-80.878161250999938, 35.18549887100005],
              [-80.878042249999964, 35.185752871000034],
              [-80.877749249999965, 35.186317872000075],
              [-80.87737924999999, 35.186985872000037],
              [-80.877290249999987, 35.187148871000034],
              [-80.877230249999968, 35.187237872000026],
              [-80.876935249999974, 35.187762872000064],
              [-80.876891249999971, 35.187840872000038],
              [-80.876851249999959, 35.187893871000028],
              [-80.876757249999969, 35.187990872000057],
              [-80.876657249999937, 35.18807187200008],
              [-80.876606249999952, 35.188103872000056],
              [-80.876542248999954, 35.18813687200003],
              [-80.876388249999934, 35.18845187200003],
              [-80.875493249999977, 35.189857872000061],
              [-80.873097248999954, 35.194349873000078],
              [-80.872923248999939, 35.194740873000057],
              [-80.872901249999984, 35.19477687300008],
              [-80.872884248999981, 35.194826874000057],
              [-80.872918248999952, 35.194781874000057],
              [-80.872955248999972, 35.194726874000025],
              [-80.873074248999956, 35.194511873000067],
              [-80.873119248999956, 35.194474873000047],
              [-80.87317724899998, 35.194451873000048],
              [-80.873241247999943, 35.194444873000066],
              [-80.873304248999943, 35.194455873000038]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 52,
        "SolutionID": "PI-19",
        "Shape_Length": 0.36209032382917383,
        "Shape_Area": 0.00065399302543622546
      }
    },
    {
      "type": "Feature",
      "id": 53,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.98128427599994, 35.099731849000079],
              [-80.981229275999965, 35.099747849000039],
              [-80.980348275999972, 35.099871849000067],
              [-80.980235275999974, 35.099881849000042],
              [-80.980057275999968, 35.099919849000059],
              [-80.979861275999951, 35.099943849000056],
              [-80.979615274999958, 35.100004849000072],
              [-80.979719274999979, 35.100013850000039],
              [-80.979811275999964, 35.100026849000074],
              [-80.979883275999953, 35.100043849000031],
              [-80.979944275999969, 35.100064850000024],
              [-80.980004276999978, 35.100091850000069],
              [-80.980242274999966, 35.100276849000068],
              [-80.980272275999937, 35.100313849000031],
              [-80.980302274999985, 35.100360849000026],
              [-80.98039827499997, 35.100484849000054],
              [-80.980445274999965, 35.100534849000042],
              [-80.980506275999971, 35.100637849000066],
              [-80.980670275999955, 35.100846850000039],
              [-80.980767274999948, 35.100970849000078],
              [-80.980970275999937, 35.10120684900005],
              [-80.981194276999986, 35.101567850000038],
              [-80.981237275999945, 35.101644849000024],
              [-80.981450275999975, 35.102099850000059],
              [-80.981690276999984, 35.102573850000056],
              [-80.98188427599996, 35.102985849000049],
              [-80.981987276999973, 35.103185850000045],
              [-80.982041275999961, 35.103301850000037],
              [-80.98216227599994, 35.103493850000064],
              [-80.982339276999937, 35.103848850000077],
              [-80.982440276999966, 35.104011851000053],
              [-80.982500276999986, 35.104129850000049],
              [-80.982564275999948, 35.104240850000053],
              [-80.982731276999971, 35.104445850000047],
              [-80.982879275999949, 35.10457185100006],
              [-80.983021276999978, 35.104711851000047],
              [-80.983097276999956, 35.10476785000003],
              [-80.983196276999934, 35.104871850000052],
              [-80.983248276999973, 35.104894850000051],
              [-80.983285275999947, 35.10490285000003],
              [-80.983317277999959, 35.104917850000049],
              [-80.983346275999963, 35.104948850000028],
              [-80.983408276999967, 35.105030850000048],
              [-80.983505276999949, 35.105147850000037],
              [-80.983984276999934, 35.104920850000042],
              [-80.98551927799997, 35.104178850000039],
              [-80.988597278999976, 35.102694849000045],
              [-80.989889278999954, 35.102071849000026],
              [-80.990593278999938, 35.101729849000037],
              [-80.99134227899998, 35.101368849000039],
              [-80.992704278999952, 35.100710849000052],
              [-80.99364627999995, 35.100255849000064],
              [-80.993753279999964, 35.100204849000079],
              [-80.993901279999989, 35.100132849000033],
              [-80.995232280999971, 35.099490848000073],
              [-80.995877279999945, 35.099177848000068],
              [-80.995757280999953, 35.099076848000038],
              [-80.995633279999936, 35.099023848000058],
              [-80.995410280999977, 35.098935848000053],
              [-80.99512427999997, 35.098810848000028],
              [-80.994712279999987, 35.098648849000028],
              [-80.993709279999962, 35.098216849000039],
              [-80.993390279999971, 35.098100848000058],
              [-80.993081278999966, 35.097970848000045],
              [-80.992826278999985, 35.097856848000049],
              [-80.992619279999985, 35.097769849000031],
              [-80.992291279999961, 35.09764584800007],
              [-80.99213827899996, 35.097571849000076],
              [-80.992005278999954, 35.097516848000055],
              [-80.991844278999963, 35.097457848000033],
              [-80.991806278999945, 35.097443848000069],
              [-80.991740279999988, 35.097421848000067],
              [-80.991700278999986, 35.097407848000046],
              [-80.991488278999952, 35.097337848000052],
              [-80.991267278999942, 35.097259848000078],
              [-80.991058279999947, 35.097193848000074],
              [-80.990794277999953, 35.097117848000039],
              [-80.990565277999963, 35.09707884900007],
              [-80.990397278999978, 35.097058848000074],
              [-80.99028027899999, 35.09705384800003],
              [-80.990097277999951, 35.097032848000026],
              [-80.989853278999988, 35.097036848000073],
              [-80.98977827799996, 35.097029848000034],
              [-80.989674277999939, 35.097010849000071],
              [-80.989646278999942, 35.097006848000035],
              [-80.98977827799996, 35.096211848000053],
              [-80.989850277999949, 35.095984847000068],
              [-80.989861278999967, 35.095904847000043],
              [-80.989462277999962, 35.095862848000024],
              [-80.989366278999967, 35.095866848000071],
              [-80.989273277999985, 35.095886848000077],
              [-80.989184277999982, 35.095921848000046],
              [-80.989077277999968, 35.095984848000057],
              [-80.98893327899998, 35.096093849000056],
              [-80.988381277999963, 35.096900848000075],
              [-80.988029276999953, 35.096894848000034],
              [-80.987917277999941, 35.096889849000036],
              [-80.987839277999967, 35.096893848000036],
              [-80.987755276999962, 35.096911849000037],
              [-80.987671277999937, 35.096939848000034],
              [-80.987585277999983, 35.096974848000059],
              [-80.987366277999968, 35.097073848000036],
              [-80.98694927799994, 35.097251848000042],
              [-80.98613927699995, 35.097625849000053],
              [-80.985938276999946, 35.097722848000046],
              [-80.98565427799997, 35.097879848000048],
              [-80.985555277999936, 35.097947848000047],
              [-80.985468276999939, 35.098000849000073],
              [-80.985398276999945, 35.09801384900004],
              [-80.985378276999938, 35.098081849000039],
              [-80.985279276999961, 35.09818984900005],
              [-80.98502027699999, 35.098554848000049],
              [-80.984898277999946, 35.098706849000052],
              [-80.984834276999948, 35.098812849000069],
              [-80.984777276999978, 35.098922849000076],
              [-80.984691276999968, 35.09902284900005],
              [-80.984556276999967, 35.099142849000032],
              [-80.984402276999958, 35.09922184800007],
              [-80.984207276999939, 35.099299849000033],
              [-80.983901276999973, 35.099382849000051],
              [-80.983749276999959, 35.09941384900003],
              [-80.983502276999957, 35.099456849000035],
              [-80.98335127699994, 35.099474849000046],
              [-80.983128275999945, 35.09949184900006],
              [-80.982710276999967, 35.099552849000077],
              [-80.98237527699996, 35.099592849000032],
              [-80.981839276999949, 35.09967184900006],
              [-80.981688275999943, 35.099698849000049],
              [-80.981545276999952, 35.099715850000052],
              [-80.981473275999974, 35.099684849000027],
              [-80.981428275999974, 35.099680850000027],
              [-80.981377274999943, 35.099688849000074],
              [-80.981323275999955, 35.099710849000076],
              [-80.98128427599994, 35.099731849000079]
            ]
          ],
          [
            [
              [-80.931495262999988, 35.131622858000071],
              [-80.924902260999943, 35.133981858000027],
              [-80.925713261999988, 35.135429858000066],
              [-80.926146261999975, 35.13612985900005],
              [-80.926227260999951, 35.136259859000063],
              [-80.92677226099994, 35.137033859000042],
              [-80.927027261999967, 35.137360859000069],
              [-80.927295260999983, 35.137691859000029],
              [-80.927530261999948, 35.137962860000073],
              [-80.927753261999953, 35.138202859000046],
              [-80.92868426299998, 35.139148859000045],
              [-80.928969262999942, 35.139418859000045],
              [-80.929300262999959, 35.139707860000044],
              [-80.929602262999936, 35.139942860000076],
              [-80.929771262999964, 35.140074859000038],
              [-80.930476262999946, 35.140588860000037],
              [-80.931834263999974, 35.141499860000067],
              [-80.932066263999957, 35.141295860000071],
              [-80.932247262999965, 35.141158860000075],
              [-80.932496263999951, 35.14095585900003],
              [-80.933009262999974, 35.140556860000061],
              [-80.933474263999983, 35.140166860000079],
              [-80.934127264999972, 35.139631859000076],
              [-80.934218263999981, 35.139560860000074],
              [-80.935476264999977, 35.138576859000068],
              [-80.935974263999981, 35.138184859000035],
              [-80.936730264999937, 35.137589859000059],
              [-80.936847264999983, 35.137490859000025],
              [-80.937188264999975, 35.137220859000024],
              [-80.937226263999946, 35.137186858000064],
              [-80.937536264999949, 35.136910859000068],
              [-80.937680264999983, 35.136765858000047],
              [-80.937848264999957, 35.136576859000058],
              [-80.937941265999939, 35.136465858000065],
              [-80.937973265999972, 35.13642685800005],
              [-80.938446265999971, 35.135829858000079],
              [-80.938764264999975, 35.135389859000043],
              [-80.938940264999985, 35.135135859000059],
              [-80.939164264999988, 35.134789858000033],
              [-80.939264265999952, 35.134620858000062],
              [-80.939386264999939, 35.134417857000074],
              [-80.939603265999949, 35.134055858000067],
              [-80.940100264999955, 35.133186857000055],
              [-80.940481265999949, 35.13249485800003],
              [-80.94056726599996, 35.132340858000077],
              [-80.940614265999955, 35.132245857000044],
              [-80.940912264999952, 35.131643858000075],
              [-80.941068265999945, 35.131253858000036],
              [-80.941207265999935, 35.130926857000077],
              [-80.941269265999949, 35.130772857000068],
              [-80.94162526599996, 35.129897856000071],
              [-80.941744265999944, 35.12962785700006],
              [-80.94060826599997, 35.129416857000024],
              [-80.939998265999975, 35.12933485700006],
              [-80.939647265999952, 35.129274857000041],
              [-80.939293264999947, 35.129201857000055],
              [-80.938760265999974, 35.129092856000057],
              [-80.938458264999952, 35.128986857000029],
              [-80.938437264999948, 35.128976857000055],
              [-80.93824026499999, 35.129136857000049],
              [-80.938047264999966, 35.129265858000053],
              [-80.937934264999967, 35.129332857000065],
              [-80.937826264999956, 35.12937585700007],
              [-80.937749264999979, 35.129412857000034],
              [-80.936413263999953, 35.129883857000038],
              [-80.935803263999958, 35.130105856000057],
              [-80.935633264999979, 35.130168857000058],
              [-80.934945263999964, 35.13042985800007],
              [-80.934758263999981, 35.13049785700008],
              [-80.934245263999969, 35.130684857000062],
              [-80.934107262999987, 35.130735857000047],
              [-80.933495262999941, 35.130947857000024],
              [-80.933197263999944, 35.131054857000038],
              [-80.932688262999989, 35.131239857000025],
              [-80.932590263999941, 35.131271858000048],
              [-80.932512262999978, 35.131288857000072],
              [-80.932472263999955, 35.131293858000049],
              [-80.932444262999979, 35.131290857000067],
              [-80.931495262999988, 35.131622858000071]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 53,
        "SolutionID": "PI-19",
        "Shape_Length": 0.083822935731651893,
        "Shape_Area": 0.00017331774297955877
      }
    },
    {
      "type": "Feature",
      "id": 54,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.024136279999937, 34.927384811000024],
            [-81.024932279999973, 34.927890811000054],
            [-81.025501280999947, 34.92829781100005],
            [-81.025539279999975, 34.928337811000063],
            [-81.025093280999954, 34.928587811000057],
            [-81.025029279999956, 34.928619811000033],
            [-81.024642279999966, 34.92881181000007],
            [-81.024270279999939, 34.928972810000062],
            [-81.023771279999949, 34.929178812000032],
            [-81.023602278999988, 34.929252811000026],
            [-81.023612278999963, 34.929337811000039],
            [-81.023653278999973, 34.929448811000043],
            [-81.023726279999948, 34.929646812000044],
            [-81.023757279999984, 34.929710811000064],
            [-81.024001279999936, 34.930093811000063],
            [-81.024367279999979, 34.930668811000032],
            [-81.02484727999996, 34.931557812000051],
            [-81.026196280999955, 34.934051812000064],
            [-81.026811279999947, 34.935203813000044],
            [-81.02743228099996, 34.936360812000032],
            [-81.02777028099996, 34.936989813000025],
            [-81.028437280999981, 34.938245813000037],
            [-81.028586280999946, 34.938512813000045],
            [-81.029078281999944, 34.939436814000032],
            [-81.029416281999943, 34.940070813000034],
            [-81.030100281999978, 34.941320814000051],
            [-81.031107282999983, 34.943250813000077],
            [-81.032554282999968, 34.942715813000063],
            [-81.033798282999953, 34.942269813000053],
            [-81.034973283999989, 34.941847814000027],
            [-81.035892283999942, 34.941517813000075],
            [-81.036104283999975, 34.94144181300004],
            [-81.035261282999954, 34.940406813000038],
            [-81.033782282999937, 34.938471813000035],
            [-81.032356281999967, 34.936629812000035],
            [-81.032174282999961, 34.936395812000058],
            [-81.031547282999952, 34.935575812000025],
            [-81.030718281999953, 34.934493812000028],
            [-81.029021280999984, 34.932496811000078],
            [-81.028546279999944, 34.93191881100006],
            [-81.027373280999939, 34.930494811000074],
            [-81.027557280999986, 34.930377811000028],
            [-81.028851280999959, 34.929472811000039],
            [-81.029815281999959, 34.928844811000033],
            [-81.030823280999982, 34.928151810000031],
            [-81.028831280999952, 34.926827810000077],
            [-81.028541280999946, 34.92663581000005],
            [-81.02693728099996, 34.92558581000003],
            [-81.026309279999964, 34.925174811000034],
            [-81.02605927999997, 34.925010809000071],
            [-81.025394279999944, 34.924571810000032],
            [-81.024792279999986, 34.925188810000066],
            [-81.024597279999966, 34.925388810000072],
            [-81.024555279999959, 34.925450810000029],
            [-81.024470278999956, 34.925571811000054],
            [-81.024366279999981, 34.925765811000076],
            [-81.024287278999964, 34.925967810000031],
            [-81.02422127899996, 34.926217810000026],
            [-81.022337278999942, 34.925961810000047],
            [-81.022313278999945, 34.926004810000052],
            [-81.02222527899994, 34.926243810000074],
            [-81.022259278999968, 34.926411810000047],
            [-81.022325278999972, 34.926745811000046],
            [-81.022527278999974, 34.92672981100003],
            [-81.023930278999956, 34.927502811000068],
            [-81.02397727999994, 34.927302811000061],
            [-81.024136279999937, 34.927384811000024]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 54,
        "SolutionID": "PI-19",
        "Shape_Length": 0.056697500106665487,
        "Shape_Area": 8.1503700274978205e-5
      }
    },
    {
      "type": "Feature",
      "id": 55,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.008353277999959, 34.987295823000068],
            [-81.00829327799994, 34.987516824000068],
            [-81.008208277999984, 34.987719824000067],
            [-81.008099277999975, 34.987913825000078],
            [-81.008014276999972, 34.98803782400006],
            [-81.00726827799997, 34.989123825000036],
            [-81.006472276999943, 34.990259825000066],
            [-81.006389277999972, 34.990380824000056],
            [-81.005812276999961, 34.991221825000025],
            [-81.005573276999939, 34.991561826000066],
            [-81.005470277999962, 34.991701825000064],
            [-81.005335277999961, 34.991894825000031],
            [-81.005007276999947, 34.99236382600003],
            [-81.004877277999981, 34.992549825000026],
            [-81.004828277999934, 34.992624825000064],
            [-81.004539276999935, 34.993069825000077],
            [-81.004503276999969, 34.993132826000078],
            [-81.004438277999952, 34.99324882500008],
            [-81.004359277999981, 34.993434826000055],
            [-81.004305277999947, 34.993626826000025],
            [-81.004260276999958, 34.994035826000072],
            [-81.004239276999954, 34.994200826000053],
            [-81.00425527699997, 34.994458825000038],
            [-81.004292276999934, 34.994712825000079],
            [-81.004313277999984, 34.994769826000038],
            [-81.004409277999969, 34.995026826000071],
            [-81.004553277999946, 34.995307826000044],
            [-81.004728277999959, 34.995578826000042],
            [-81.00526027799998, 34.996352826000077],
            [-81.005500276999953, 34.996663826000031],
            [-81.005712277999976, 34.996916826000074],
            [-81.006016277999947, 34.99721882700004],
            [-81.006298277999974, 34.997477826000079],
            [-81.006819278999956, 34.997931827000059],
            [-81.007399278999969, 34.998382827000057],
            [-81.008271278999985, 34.999051827000073],
            [-81.008532278999951, 34.999257826000076],
            [-81.00891527899995, 34.999550827000064],
            [-81.009415277999949, 34.999865826000075],
            [-81.009775278999939, 35.000033827000038],
            [-81.009809278999967, 35.000049826000065],
            [-81.010207279999975, 35.000178826000024],
            [-81.010231279999971, 35.00018682700005],
            [-81.012000279999938, 35.000762827000074],
            [-81.012586280999983, 35.000958827000034],
            [-81.013814279999963, 35.001352827000062],
            [-81.014495280999938, 35.001566827000033],
            [-81.014719281999987, 35.001641827000071],
            [-81.014905280999983, 35.001724827000032],
            [-81.015075280999952, 35.00181182700004],
            [-81.01524028099999, 35.001912827000069],
            [-81.015560280999978, 35.002131827000028],
            [-81.015884280999956, 35.002367827000057],
            [-81.01694428199994, 35.003193827000075],
            [-81.017359281999973, 35.003340828000034],
            [-81.017487281999934, 35.003421828000057],
            [-81.017497281999965, 35.003258827000025],
            [-81.01751828099998, 35.003140827000038],
            [-81.017763280999986, 35.001779827000064],
            [-81.018168281999976, 34.999592827000072],
            [-81.01854328099995, 34.997625826000046],
            [-81.018830281999954, 34.996024825000063],
            [-81.018991281999945, 34.995158825000033],
            [-81.019096280999975, 34.994569825000042],
            [-81.019240281999942, 34.993923825000024],
            [-81.019332280999947, 34.993584825000028],
            [-81.019477281999968, 34.993144825000059],
            [-81.019816280999976, 34.992365825000036],
            [-81.020033281999986, 34.991899824000029],
            [-81.020065281999962, 34.991830825000079],
            [-81.020634281999946, 34.990546825000024],
            [-81.020732281999983, 34.990325824000024],
            [-81.020990281999957, 34.989716824000027],
            [-81.021082281999952, 34.989495824000073],
            [-81.021195281999951, 34.989131824000026],
            [-81.021207282999967, 34.989078824000046],
            [-81.02130828199995, 34.988640823000026],
            [-81.021354281999947, 34.988306824000063],
            [-81.021369281999966, 34.988199824000048],
            [-81.021372280999969, 34.988109824000048],
            [-81.021390280999981, 34.987590823000062],
            [-81.02139028199997, 34.987461824000036],
            [-81.02139028199997, 34.987023823000072],
            [-81.02139028199997, 34.986380823000047],
            [-81.021473281999988, 34.986438823000071],
            [-81.021840280999982, 34.986669823000057],
            [-81.02205528199994, 34.986867823000068],
            [-81.02222728299995, 34.987027823000062],
            [-81.022408281999958, 34.987241823000033],
            [-81.022521281999957, 34.987456823000059],
            [-81.02286828299998, 34.987978823000049],
            [-81.022982282999976, 34.988126824000062],
            [-81.023021282999935, 34.988313823000055],
            [-81.023155282999937, 34.988412823000033],
            [-81.023258282999961, 34.988450823000051],
            [-81.023335281999948, 34.988478824000026],
            [-81.023527281999975, 34.98853282400006],
            [-81.023629283999981, 34.988561824000044],
            [-81.02380328299995, 34.988588823000043],
            [-81.023896282999942, 34.988632824000035],
            [-81.024130282999977, 34.988803823000069],
            [-81.02425728299994, 34.988880824000034],
            [-81.024404282999967, 34.988929824000024],
            [-81.024644283999976, 34.988940824000053],
            [-81.024805282999978, 34.98896882400004],
            [-81.024833282999964, 34.988979824000069],
            [-81.025078282999971, 34.989078824000046],
            [-81.025305282999966, 34.989215824000041],
            [-81.025385283999981, 34.989342824000062],
            [-81.025466282999957, 34.98951282400003],
            [-81.025472283999989, 34.989655824000067],
            [-81.025472282999942, 34.989892824000037],
            [-81.025449282999944, 34.989991824000072],
            [-81.025352282999961, 34.990408824000042],
            [-81.025291282999945, 34.990601824000066],
            [-81.025251282999989, 34.990832824000051],
            [-81.025224283999989, 34.99121182500005],
            [-81.025191283999959, 34.99150882400005],
            [-81.025171282999963, 34.991645824000045],
            [-81.025124283999958, 34.991821824000056],
            [-81.02500328299999, 34.992090825000048],
            [-81.02500328299999, 34.992200824000065],
            [-81.025257282999974, 34.992343825000034],
            [-81.025511283999947, 34.992717825000057],
            [-81.025698283999986, 34.992838824000046],
            [-81.025791283999979, 34.992976824000039],
            [-81.026332283999977, 34.993410825000069],
            [-81.026633283999956, 34.993674825000028],
            [-81.027027283999985, 34.993977825000059],
            [-81.027200283999946, 34.994164824000052],
            [-81.027240283999959, 34.994328825000025],
            [-81.02730728399996, 34.99441682500003],
            [-81.027467283999954, 34.994658825000045],
            [-81.027460283999972, 34.994757825000079],
            [-81.027333283999951, 34.994966825000063],
            [-81.027313284999934, 34.995082825000054],
            [-81.027246283999943, 34.995252824000033],
            [-81.027186282999935, 34.995527825000067],
            [-81.026999284999988, 34.995730825000066],
            [-81.026839283999948, 34.995884826000065],
            [-81.026638283999944, 34.996126826000079],
            [-81.026531283999986, 34.996395826000025],
            [-81.026571283999942, 34.996555826000076],
            [-81.026731284999983, 34.996945826000058],
            [-81.02683828399995, 34.997148825000068],
            [-81.026972283999953, 34.997253826000076],
            [-81.02722528399994, 34.997434826000074],
            [-81.027412284999969, 34.997467825000058],
            [-81.027693283999952, 34.997495825000044],
            [-81.027840283999979, 34.997495825000044],
            [-81.028187284999944, 34.997402825000052],
            [-81.028622284999983, 34.997303825000074],
            [-81.028902283999969, 34.997292825000045],
            [-81.029229283999939, 34.997308825000061],
            [-81.02937028499997, 34.997385825000038],
            [-81.029430284999989, 34.997512826000047],
            [-81.029697284999941, 34.997715825000057],
            [-81.029804284999955, 34.997809825000047],
            [-81.030037284999935, 34.998067825000078],
            [-81.030264284999987, 34.998276825000062],
            [-81.030371285999934, 34.998425826000073],
            [-81.030445284999985, 34.998480825000058],
            [-81.030605285999968, 34.998562826000068],
            [-81.030714284999988, 34.998675826000067],
            [-81.030765285999962, 34.998727826000049],
            [-81.030899285999965, 34.998892825000041],
            [-81.031099285999971, 34.99899782500006],
            [-81.031386285999986, 34.999194826000064],
            [-81.031418285999962, 34.99921882600006],
            [-81.031881285999987, 34.998888825000051],
            [-81.032683284999962, 34.998346826000045],
            [-81.033338285999946, 34.997871825000061],
            [-81.033898285999953, 34.997445825000057],
            [-81.03431928599997, 34.997064826000042],
            [-81.034613285999967, 34.996753824000052],
            [-81.034949286999961, 34.996373825000035],
            [-81.034978286999944, 34.996331825000027],
            [-81.035089286999948, 34.996176825000077],
            [-81.035271286999944, 34.995934825000063],
            [-81.03552328699999, 34.995623824000063],
            [-81.035733285999981, 34.995266825000044],
            [-81.035817286999986, 34.995111825000038],
            [-81.035869285999979, 34.995007825000073],
            [-81.03598528699996, 34.994759825000074],
            [-81.036419286999944, 34.993894824000051],
            [-81.038724287999969, 34.989138823000076],
            [-81.038787286999934, 34.989011823000055],
            [-81.038968286999989, 34.988638823000031],
            [-81.039697287999957, 34.987135823000074],
            [-81.040417287999958, 34.987391823000053],
            [-81.040932286999976, 34.987597823000044],
            [-81.041050286999962, 34.987644823000039],
            [-81.041537287999972, 34.98785782300007],
            [-81.041927287999954, 34.988044823000052],
            [-81.042170287999966, 34.988177823000058],
            [-81.04225428899997, 34.988219823000065],
            [-81.042333288999941, 34.988257823000026],
            [-81.042868288999955, 34.988578823000068],
            [-81.043323288999943, 34.988831823000055],
            [-81.043364288999953, 34.988846823000074],
            [-81.043907287999957, 34.989031823000062],
            [-81.044329288999961, 34.989098823000063],
            [-81.044995288999985, 34.989151823000043],
            [-81.045514288999982, 34.989178824000078],
            [-81.046244289999947, 34.98920482300008],
            [-81.046666288999972, 34.989177823000034],
            [-81.047932288999959, 34.989016823000043],
            [-81.048386289999939, 34.989016822000053],
            [-81.048488289999966, 34.989024823000079],
            [-81.048711289999972, 34.989043823000031],
            [-81.048781289999965, 34.989059823000048],
            [-81.04906828999998, 34.989123823000057],
            [-81.049246289999985, 34.989176823000037],
            [-81.049377289999939, 34.989216823000049],
            [-81.050129290999962, 34.989541823000025],
            [-81.051428290999979, 34.990125822000039],
            [-81.052961291999964, 34.990830823000067],
            [-81.053230291999967, 34.990981823000027],
            [-81.053414290999967, 34.991122823000069],
            [-81.053598291999947, 34.991304824000053],
            [-81.053745291999974, 34.991505823000068],
            [-81.053774291999957, 34.99156582300003],
            [-81.053858291999973, 34.991734824000048],
            [-81.053905291999968, 34.991868823000061],
            [-81.053924291999977, 34.992058824000026],
            [-81.053942291999988, 34.992221824000069],
            [-81.053979290999962, 34.992946824000057],
            [-81.054019291999964, 34.993903824000029],
            [-81.054004291999945, 34.994134824000071],
            [-81.05399029199998, 34.994370824000043],
            [-81.053960291999942, 34.994618824000042],
            [-81.053948291999973, 34.994686824000041],
            [-81.053899290999937, 34.994969824000066],
            [-81.053786291999984, 34.995547824000028],
            [-81.05306729199998, 34.998994826000057],
            [-81.055218292999939, 34.999304825000024],
            [-81.055460291999964, 34.999302825000029],
            [-81.05569729299998, 34.999262825000073],
            [-81.055920292999986, 34.99918582500004],
            [-81.05612129299999, 34.999075824000045],
            [-81.05629429399994, 34.998935825000046],
            [-81.056430292999949, 34.998771825000063],
            [-81.05652629299999, 34.99858882500007],
            [-81.056577292999975, 34.998394824000059],
            [-81.056691292999972, 34.997406825000041],
            [-81.056820292999987, 34.997056824000026],
            [-81.056932292999988, 34.996872824000036],
            [-81.057071292999979, 34.996700824000072],
            [-81.057214292999959, 34.996537824000029],
            [-81.057320293999965, 34.996356825000078],
            [-81.057359292999934, 34.996243824000032],
            [-81.057386293999969, 34.996162824000066],
            [-81.057411292999973, 34.995963824000057],
            [-81.057398293999938, 34.995557824000059],
            [-81.057424293999986, 34.995353824000063],
            [-81.057476292999979, 34.995152824000058],
            [-81.057493292999936, 34.995107824000058],
            [-81.057553292999955, 34.994957824000039],
            [-81.058777292999935, 34.993008824000071],
            [-81.05880029399998, 34.992972823000059],
            [-81.059743293999986, 34.991644823000058],
            [-81.059812293999983, 34.99154782200003],
            [-81.06075229399994, 34.991759823000052],
            [-81.062149294999983, 34.992039823000027],
            [-81.062391294999941, 34.99206782400006],
            [-81.06263929399995, 34.992081823000035],
            [-81.062886294999942, 34.992081823000035],
            [-81.063133294999943, 34.99206782300007],
            [-81.063399293999964, 34.992036823000035],
            [-81.063718294999944, 34.992002823000064],
            [-81.063840294999977, 34.991994823000027],
            [-81.064026293999973, 34.991984823000053],
            [-81.064198294999983, 34.991984823000053],
            [-81.064367294999954, 34.991984823000053],
            [-81.064797295999938, 34.991983823000055],
            [-81.065088295999942, 34.991939822000063],
            [-81.065770294999936, 34.991855824000027],
            [-81.066069294999977, 34.991832823000038],
            [-81.066429295999967, 34.991843822000078],
            [-81.066773294999962, 34.991869823000059],
            [-81.067074294999941, 34.991918822000059],
            [-81.067375294999977, 34.991992823000032],
            [-81.067946295999946, 34.992189824000036],
            [-81.068300295999961, 34.992321823000054],
            [-81.068683295999961, 34.992461823000042],
            [-81.068763295999986, 34.992497823000065],
            [-81.069119296999986, 34.992659822000064],
            [-81.069856295999955, 34.992856822000078],
            [-81.070382295999934, 34.992930824000041],
            [-81.071165296999936, 34.992954823000048],
            [-81.075925298999948, 34.993066823000049],
            [-81.079341299999953, 34.993147822000026],
            [-81.08066329899998, 34.993159823000042],
            [-81.081069299999967, 34.993121823000024],
            [-81.081550299999947, 34.992985823000026],
            [-81.082031299999983, 34.992812823000065],
            [-81.082332299999962, 34.992663823000044],
            [-81.082707300999971, 34.992440822000049],
            [-81.08305330099995, 34.992180823000069],
            [-81.083443299999942, 34.99177282200003],
            [-81.083632300999966, 34.991515822000054],
            [-81.083763300999976, 34.991281822000076],
            [-81.083863300999951, 34.991077823000069],
            [-81.083944300999974, 34.990873822000026],
            [-81.083979300999943, 34.99078482200008],
            [-81.084064300999955, 34.990454821000071],
            [-81.084086301999946, 34.990079822000041],
            [-81.084119300999987, 34.988353821000032],
            [-81.084123300999977, 34.988106822000077],
            [-81.084152299999971, 34.986265821000075],
            [-81.084191299999986, 34.985871821000046],
            [-81.084262299999978, 34.98554082000004],
            [-81.084275299999945, 34.985477821000075],
            [-81.084398300999965, 34.985166821000064],
            [-81.084599299999979, 34.984759820000079],
            [-81.084763299999963, 34.984473820000062],
            [-81.085293300999979, 34.983537820000038],
            [-81.085347300999956, 34.983443820000048],
            [-81.08541730099995, 34.98330382000006],
            [-81.085526300999959, 34.982962821000058],
            [-81.085590299999978, 34.982672821000051],
            [-81.085809300999983, 34.981790820000072],
            [-81.085897300999989, 34.981419820000042],
            [-81.084368299999937, 34.980516820000048],
            [-81.083876299999986, 34.98019682000006],
            [-81.082955299999981, 34.979599819000043],
            [-81.082582299999956, 34.979370820000042],
            [-81.083046299999978, 34.979262820000031],
            [-81.083160299999975, 34.979212819000054],
            [-81.083437299999957, 34.979094819000068],
            [-81.082917298999973, 34.978782820000049],
            [-81.080453298999942, 34.977281819000041],
            [-81.079088297999988, 34.976351820000048],
            [-81.077775297999949, 34.97545881800005],
            [-81.077001297999971, 34.974931819000062],
            [-81.074820296999974, 34.973452818000055],
            [-81.074793296999985, 34.973415819000024],
            [-81.074274297999978, 34.973055819000024],
            [-81.074229296999988, 34.973029818000043],
            [-81.074190296999973, 34.972997819000057],
            [-81.073526296999944, 34.972536818000037],
            [-81.07183529699995, 34.97137581800007],
            [-81.071215296999981, 34.970935818000044],
            [-81.070783295999945, 34.970567818000063],
            [-81.070691294999961, 34.970465818000037],
            [-81.070464295999955, 34.970213817000058],
            [-81.070197295999947, 34.969816818000027],
            [-81.069929294999952, 34.969277817000034],
            [-81.069904295999947, 34.969196818000057],
            [-81.069756294999934, 34.968710817000044],
            [-81.069690295999976, 34.968368818000044],
            [-81.069602294999981, 34.967913817000067],
            [-81.069523294999954, 34.967505818000063],
            [-81.069471294999971, 34.967517818000033],
            [-81.068127294999954, 34.968129817000033],
            [-81.067463294999982, 34.96844481800008],
            [-81.06685629499998, 34.968744818000062],
            [-81.065914293999981, 34.969196818000057],
            [-81.065606293999963, 34.969337818000042],
            [-81.065221294999958, 34.969509818000063],
            [-81.065025293999952, 34.969565819000024],
            [-81.064702292999982, 34.969665818000067],
            [-81.064431293999974, 34.969714819000046],
            [-81.064157292999937, 34.969744818000038],
            [-81.063881294999987, 34.969754818000069],
            [-81.063605293999956, 34.969744818000038],
            [-81.063330292999979, 34.969715819000044],
            [-81.063060293999968, 34.969666818000064],
            [-81.062796292999963, 34.969597818000068],
            [-81.060928292999961, 34.969035819000055],
            [-81.060095291999971, 34.968784818000074],
            [-81.058797291999952, 34.968391818000043],
            [-81.058753291999949, 34.968378818000076],
            [-81.058044291999977, 34.96816381800005],
            [-81.057748291999985, 34.96807781800004],
            [-81.056883290999963, 34.967825818000051],
            [-81.056613290999962, 34.967767818000027],
            [-81.056338290999975, 34.967728818000069],
            [-81.056090290999975, 34.967711818000055],
            [-81.055781291999949, 34.967710818000057],
            [-81.05550329099998, 34.967731818000061],
            [-81.054933289999951, 34.967820818000064],
            [-81.054651290999971, 34.967276818000073],
            [-81.053085289999956, 34.964720818000046],
            [-81.051618289999965, 34.962232818000075],
            [-81.050478288999955, 34.960278817000074],
            [-81.049547288999975, 34.958743817000027],
            [-81.049462288999962, 34.958602816000052],
            [-81.048298287999955, 34.956776815000069],
            [-81.048265287999982, 34.956725816000073],
            [-81.048145286999954, 34.956537816000036],
            [-81.04783028899999, 34.956110817000024],
            [-81.047666287999959, 34.955887816000029],
            [-81.04750828799996, 34.955689816000074],
            [-81.047285287999955, 34.955409816000042],
            [-81.046515287999966, 34.95444681500004],
            [-81.045832286999939, 34.953589816000033],
            [-81.04268028599995, 34.949649815000043],
            [-81.040064284999971, 34.946367814000041],
            [-81.038803284999972, 34.944784814000059],
            [-81.038750283999946, 34.944718814000055],
            [-81.038553284999978, 34.944470813000066],
            [-81.038068283999962, 34.943862814000056],
            [-81.037688284999945, 34.943385813000077],
            [-81.036104283999975, 34.94144181300004],
            [-81.035261282999954, 34.940406813000038],
            [-81.033782282999937, 34.938471813000035],
            [-81.034007281999948, 34.938380813000038],
            [-81.034235283999976, 34.938305813000056],
            [-81.034454282999945, 34.938219812000057],
            [-81.03461428199995, 34.938070813000024],
            [-81.035872283999936, 34.936777812000059],
            [-81.036472283999956, 34.936166812000067],
            [-81.037432283999976, 34.935208812000042],
            [-81.039020283999946, 34.933599812000068],
            [-81.040217283999937, 34.934395812000048],
            [-81.042045284999972, 34.932435811000062],
            [-81.042144284999949, 34.932328811000048],
            [-81.042303284999946, 34.932157811000025],
            [-81.042351284999938, 34.93210681000005],
            [-81.042687284999943, 34.931745811000042],
            [-81.042959284999938, 34.931452810000053],
            [-81.043372285999965, 34.931010811000078],
            [-81.044494285999974, 34.931088811000052],
            [-81.045164285999988, 34.931147811000073],
            [-81.04581628599999, 34.931205811000041],
            [-81.046415285999956, 34.93125781100008],
            [-81.048032286999955, 34.93139681100007],
            [-81.04955728799996, 34.931529811000075],
            [-81.049780287999965, 34.93154781000004],
            [-81.050588286999982, 34.931604810000067],
            [-81.050960286999953, 34.931631811000045],
            [-81.052417286999969, 34.931755810000027],
            [-81.052486288999944, 34.931760810000071],
            [-81.054135288999987, 34.931891810000025],
            [-81.054789288999984, 34.931953810000039],
            [-81.054935288999957, 34.931964811000057],
            [-81.055162288999952, 34.93205781000006],
            [-81.055354288999979, 34.932177811000031],
            [-81.055402288999971, 34.932238811000047],
            [-81.055952288999947, 34.932239811000045],
            [-81.058168290999959, 34.932244810000043],
            [-81.057794289999947, 34.931214810000029],
            [-81.057546289999948, 34.930550810000057],
            [-81.05733528899998, 34.929869810000071],
            [-81.057175289999975, 34.929253810000034],
            [-81.057156288999977, 34.929151810000064],
            [-81.057051288999958, 34.928661810000051],
            [-81.05690328899999, 34.927691810000056],
            [-81.05682528899996, 34.927178810000044],
            [-81.056702289999976, 34.926228810000055],
            [-81.056650288999947, 34.925827809000054],
            [-81.056558288999952, 34.925215809000065],
            [-81.056503289999966, 34.924773808000054],
            [-81.056458288999977, 34.924487809000027],
            [-81.056423288999952, 34.92436181000005],
            [-81.056398288999958, 34.924269809000066],
            [-81.056364288999987, 34.924145809000038],
            [-81.056247288999941, 34.923858809000023],
            [-81.056114288999936, 34.923594808000075],
            [-81.05607028899999, 34.923529809000058],
            [-81.05594328899997, 34.923344808000024],
            [-81.055699288999961, 34.923061808000057],
            [-81.055551288999936, 34.922916809000071],
            [-81.055570288999945, 34.922889808000036],
            [-81.05563628799996, 34.92271880900006],
            [-81.055650288999971, 34.922614808000048],
            [-81.055656288999955, 34.922416808000037],
            [-81.055690288999983, 34.92216380800005],
            [-81.055823288999989, 34.921943808000037],
            [-81.056077288999973, 34.921377808000045],
            [-81.056197288999954, 34.921152808000045],
            [-81.05627028899994, 34.921047808000026],
            [-81.056391288999976, 34.920954808000033],
            [-81.056477288999986, 34.92077880800008],
            [-81.056684288999975, 34.920190808000029],
            [-81.056702288999986, 34.92008480800007],
            [-81.056958288999965, 34.920157808000056],
            [-81.058356289999949, 34.920539808000058],
            [-81.061691290999988, 34.921457807000024],
            [-81.06246028999999, 34.921668808000049],
            [-81.062464290999969, 34.921168808000061],
            [-81.062450290999948, 34.920327807000035],
            [-81.062472290999949, 34.920030808000035],
            [-81.062484289999986, 34.91987180700005],
            [-81.062450290999948, 34.919508807000057],
            [-81.062537290999956, 34.91907980700006],
            [-81.06264428999998, 34.918799808000074],
            [-81.06277129099999, 34.918496807000054],
            [-81.062911290999978, 34.918067807000057],
            [-81.062938289999977, 34.917848807000041],
            [-81.06304529099998, 34.917633808000062],
            [-81.063118290999967, 34.917490807000036],
            [-81.063198290999935, 34.917182808000064],
            [-81.063265289999947, 34.916831807000051],
            [-81.063300289999972, 34.916722807000042],
            [-81.063378290999935, 34.916484806000028],
            [-81.063398289999952, 34.916110807000052],
            [-81.063398289999952, 34.915721807000068],
            [-81.063398289999952, 34.915632806000076],
            [-81.063492290999989, 34.915346807000049],
            [-81.063538290999986, 34.914874807000047],
            [-81.06361229099997, 34.914599806000069],
            [-81.063711290999947, 34.914353807000055],
            [-81.063732290999951, 34.91430280600008],
            [-81.063932290999958, 34.913988806000077],
            [-81.064046290999954, 34.913801806000038],
            [-81.064232290999939, 34.913384805000078],
            [-81.064466290999974, 34.912955805000024],
            [-81.064599290999979, 34.912839806000079],
            [-81.064773290999938, 34.91274680600003],
            [-81.064800290999983, 34.912504807000062],
            [-81.064940290999971, 34.91209780500003],
            [-81.065033290999963, 34.91195480600004],
            [-81.065060290999952, 34.91181180500007],
            [-81.065073290999976, 34.911372806000031],
            [-81.065127290999953, 34.911185806000049],
            [-81.065293289999943, 34.910772805000079],
            [-81.065460291999955, 34.91041580600006],
            [-81.065478290999977, 34.910298806000071],
            [-81.064394289999939, 34.91037480500006],
            [-81.063985289999948, 34.91037880600004],
            [-81.063746290999973, 34.910364806000075],
            [-81.063464290999946, 34.910336806000032],
            [-81.063092290999975, 34.910271805000036],
            [-81.062863289999939, 34.910213805000069],
            [-81.062477289999947, 34.910080805000064],
            [-81.062250289999952, 34.909986806000063],
            [-81.061838288999979, 34.909773805000043],
            [-81.061464289999947, 34.909511805000079],
            [-81.06110928999999, 34.909194805000027],
            [-81.061030289999962, 34.909110805000068],
            [-81.060952289999989, 34.909026805000053],
            [-81.060737289999963, 34.908758805000048],
            [-81.060426288999963, 34.908363805000079],
            [-81.06015728999995, 34.90802280500003],
            [-81.060054289999982, 34.907896806000053],
            [-81.059877289999974, 34.907680805000041],
            [-81.059843289999947, 34.907638805000033],
            [-81.059550288999958, 34.907317806000037],
            [-81.05948828999999, 34.907249805000049],
            [-81.059291288999987, 34.907074805000036],
            [-81.05905028799998, 34.906941805000031],
            [-81.058896288999961, 34.906869806000032],
            [-81.058832287999962, 34.906843805000051],
            [-81.058788288999949, 34.906885805000059],
            [-81.058627288999958, 34.907039805000068],
            [-81.057709288999945, 34.907812805000049],
            [-81.057386288999965, 34.908084805000044],
            [-81.056226287999948, 34.908898805000035],
            [-81.055067287999975, 34.909585805000063],
            [-81.054911287999971, 34.909678805000055],
            [-81.053746286999967, 34.910298806000071],
            [-81.05313328799997, 34.910625806000041],
            [-81.048589285999981, 34.913076806000049],
            [-81.047636285999943, 34.913607807000062],
            [-81.047229284999958, 34.913820806000047],
            [-81.046408285999973, 34.91425080700003],
            [-81.045699285999945, 34.914626808000037],
            [-81.044763284999988, 34.913827807000075],
            [-81.041949282999951, 34.91157180600004],
            [-81.040440283999942, 34.910360807000075],
            [-81.039956282999981, 34.909939807000057],
            [-81.039913283999965, 34.909964806000062],
            [-81.039749282999935, 34.910102807000044],
            [-81.039241282999967, 34.910507806000055],
            [-81.038588282999967, 34.909959806000074],
            [-81.037137281999946, 34.91110580600008],
            [-81.035927281999989, 34.912092807000079],
            [-81.035689281999964, 34.912266807000037],
            [-81.035362281999937, 34.912550807000059],
            [-81.034870280999939, 34.912937807000048],
            [-81.034337281999967, 34.913384807000057],
            [-81.034190280999951, 34.91350780700003],
            [-81.033750281999971, 34.914121808000061],
            [-81.033620280999969, 34.91430280700007],
            [-81.034666282999979, 34.915044807000072],
            [-81.034329281999987, 34.915381808000063],
            [-81.032983280999986, 34.916727808000076],
            [-81.032704280999951, 34.916974808000077],
            [-81.032548281999937, 34.917100808000043],
            [-81.031509280999956, 34.917937808000033],
            [-81.031117281999968, 34.91825280900008],
            [-81.030765280999958, 34.918535808000058],
            [-81.029798280999955, 34.919310809000024],
            [-81.028894280999964, 34.918437808000078],
            [-81.028462280999975, 34.918022809000036],
            [-81.02787227999994, 34.917456809000043],
            [-81.027289279999934, 34.91692580800003],
            [-81.026976278999939, 34.918015809000053],
            [-81.026953279999987, 34.918177809000042],
            [-81.026846279999972, 34.918948809000028],
            [-81.026770279999937, 34.919488809000029],
            [-81.023994278999965, 34.918412809000074],
            [-81.022574278999969, 34.917847809000079],
            [-81.02016227699994, 34.916796808000072],
            [-81.019462276999946, 34.917924809000056],
            [-81.018824277999954, 34.919019809000076],
            [-81.018173277999949, 34.920084810000048],
            [-81.015628276999962, 34.918782809000049],
            [-81.014296275999982, 34.918180809000035],
            [-81.013244275999966, 34.917545809000046],
            [-81.012876275999986, 34.918783809000047],
            [-81.011365274999946, 34.918040809000047],
            [-81.011148275999972, 34.917952809000042],
            [-81.010866274999955, 34.917862810000031],
            [-81.009617274999982, 34.917460809000033],
            [-81.009354274999964, 34.918059810000045],
            [-81.009049273999949, 34.918704809000076],
            [-81.00896127499999, 34.918944809000038],
            [-81.008787274999975, 34.919200809000074],
            [-81.008083273999944, 34.918847809000056],
            [-81.007623273999968, 34.918679810000071],
            [-81.006377272999941, 34.918224809000037],
            [-81.005679273999988, 34.917969809000056],
            [-81.003896271999963, 34.917479809000042],
            [-81.001499270999943, 34.916980809000052],
            [-81.000353271999984, 34.916767809000078],
            [-81.000073271999952, 34.916715809000038],
            [-80.999485271999959, 34.916606809000029],
            [-80.999103270999967, 34.916535809000038],
            [-80.995679269999982, 34.915715809000062],
            [-80.992570269999987, 34.915045808000059],
            [-80.992052269999988, 34.914925808000078],
            [-80.990423267999972, 34.914548810000042],
            [-80.989856268999972, 34.914417808000053],
            [-80.986301267999977, 34.913433809000026],
            [-80.984581266999953, 34.913064809000048],
            [-80.984457266999982, 34.913037809000059],
            [-80.983220266999979, 34.912890809000032],
            [-80.982738265999956, 34.912833809000063],
            [-80.98246226599997, 34.913373809000063],
            [-80.982418266999957, 34.913458810000066],
            [-80.982390265999982, 34.913509809000061],
            [-80.981021265999971, 34.916134809000027],
            [-80.98066926599995, 34.916808810000077],
            [-80.98038726599998, 34.917348810000078],
            [-80.979437265999934, 34.919169811000074],
            [-80.97890926599996, 34.92018081100008],
            [-80.978632265999977, 34.920711811000047],
            [-80.975380265999945, 34.926940812000055],
            [-80.97400526499996, 34.929575813000042],
            [-80.973883264999984, 34.929814813000064],
            [-80.97238426399997, 34.932763814000054],
            [-80.972104263999938, 34.933314813000038],
            [-80.971642263999968, 34.934204814000054],
            [-80.971399263999956, 34.934774815000026],
            [-80.97122826399999, 34.935504814000069],
            [-80.971156263999944, 34.936262814000031],
            [-80.971063264999941, 34.93724981500003],
            [-80.97108626399995, 34.937975814000026],
            [-80.971124264999958, 34.938316815000064],
            [-80.97113126499994, 34.938604815000076],
            [-80.971112264999988, 34.938898815000073],
            [-80.97109726399998, 34.939051815000028],
            [-80.971044263999943, 34.939336816000036],
            [-80.970980263999934, 34.939546815000028],
            [-80.970873264999966, 34.939899815000047],
            [-80.970678264999947, 34.940009815000053],
            [-80.970228263999957, 34.940175816000078],
            [-80.971315264999987, 34.940326815000049],
            [-80.971790264999981, 34.940407816000061],
            [-80.972464264999985, 34.940509816000031],
            [-80.973513264999951, 34.942958816000043],
            [-80.974203264999971, 34.944570816000066],
            [-80.974233265999942, 34.944624816000044],
            [-80.974306265999985, 34.944796816000064],
            [-80.974498265999955, 34.945248817000049],
            [-80.974539265999965, 34.945367816000044],
            [-80.974595264999948, 34.945476816000053],
            [-80.974731265999935, 34.945796816000041],
            [-80.975071266999976, 34.94659581600007],
            [-80.97619526699998, 34.949241818000075],
            [-80.976345266999942, 34.949592818000042],
            [-80.976577266999982, 34.950130817000058],
            [-80.976684266999939, 34.950380817000053],
            [-80.97675426699999, 34.950542817000041],
            [-80.980033267999943, 34.95814081900005],
            [-80.982703269999945, 34.964319821000061],
            [-80.983185269999979, 34.965432820000046],
            [-80.983382268999947, 34.965887820000034],
            [-80.984113269999966, 34.967579821000072],
            [-80.984160269999961, 34.967687820000037],
            [-80.984306269999934, 34.968025821000026],
            [-80.984538268999984, 34.96856082000005],
            [-80.984673269999973, 34.968881821000025],
            [-80.984715269999981, 34.968980821000059],
            [-80.985269270999936, 34.970283821000066],
            [-80.98539126999998, 34.970569821000026],
            [-80.986583269999983, 34.973370822000049],
            [-80.98667427099997, 34.973584822000078],
            [-80.986781270999984, 34.973803822000036],
            [-80.986950270999955, 34.974198822000062],
            [-80.987422270999957, 34.975301822000063],
            [-80.987692270999958, 34.975933822000059],
            [-80.987954271999968, 34.976545822000048],
            [-80.988040271999978, 34.976839823000034],
            [-80.988122270999952, 34.977114823000079],
            [-80.988196270999936, 34.977396823000049],
            [-80.988251271999957, 34.977681822000079],
            [-80.988289271999975, 34.977967823000029],
            [-80.988307271999986, 34.97825582300004],
            [-80.988308271999983, 34.978543822000063],
            [-80.988291271999969, 34.978831823000064],
            [-80.988272270999971, 34.97899882300004],
            [-80.988101271999938, 34.97993282300007],
            [-80.987897271999941, 34.980592823000052],
            [-80.987672271999941, 34.981089823000048],
            [-80.987631271999987, 34.981142823000027],
            [-80.987254270999983, 34.981746824000027],
            [-80.986699271999953, 34.982567824000057],
            [-80.983784270999934, 34.986879825000074],
            [-80.983757270999945, 34.986917825000035],
            [-80.983400270999937, 34.987445825000066],
            [-80.984278269999947, 34.987622826000063],
            [-80.986819270999945, 34.988387825000075],
            [-80.987669272999938, 34.988649825000039],
            [-80.989118271999985, 34.989340825000056],
            [-80.989497272999984, 34.989521825000054],
            [-80.989815273999966, 34.989647826000066],
            [-80.989874272999941, 34.989670826000065],
            [-80.989952272999972, 34.989703825000049],
            [-80.99115427299995, 34.990216826000051],
            [-80.993061272999967, 34.991047825000066],
            [-80.993153272999962, 34.991100825000046],
            [-80.993293273999939, 34.991181825000069],
            [-80.993321273999982, 34.991161825000063],
            [-80.993668273999958, 34.990940826000042],
            [-80.993799273999969, 34.990844825000067],
            [-80.993871274999947, 34.990803826000047],
            [-80.993916273999957, 34.990787825000041],
            [-80.993954273999975, 34.990777825000066],
            [-80.99399727399998, 34.990772825000079],
            [-80.994041274999972, 34.990773825000076],
            [-80.994170273999941, 34.990797826000062],
            [-80.994255274999944, 34.990817825000079],
            [-80.994457273999956, 34.990885825000078],
            [-80.994541273999971, 34.990907825000079],
            [-80.994584273999976, 34.990913826000053],
            [-80.994628274999968, 34.990908825000076],
            [-80.994669273999989, 34.990895826000042],
            [-80.994836273999965, 34.990857825000035],
            [-80.994918273999986, 34.990833825000038],
            [-80.994996274999949, 34.990803826000047],
            [-80.995038273999967, 34.990795825000077],
            [-80.995114274999935, 34.990762825000047],
            [-80.995234274999973, 34.990717825000047],
            [-80.995297273999938, 34.990668825000057],
            [-80.995352274999959, 34.990612824000038],
            [-80.995462273999976, 34.990555825000058],
            [-80.995570273999988, 34.990493824000055],
            [-80.995602274999953, 34.990469825000048],
            [-80.995627274999947, 34.990441825000062],
            [-80.995646274999956, 34.990409826000075],
            [-80.995654273999946, 34.99037482600005],
            [-80.995649273999959, 34.990339825000035],
            [-80.99566327499997, 34.990305825000064],
            [-80.995692273999964, 34.990279825000073],
            [-80.995743273999949, 34.990222825000046],
            [-80.995827273999964, 34.99014182500008],
            [-80.996088274999977, 34.990013825000062],
            [-80.996155273999989, 34.989968825000062],
            [-80.996285273999945, 34.98987182500008],
            [-80.996387274999961, 34.989803825000024],
            [-80.99645927499995, 34.989764825000066],
            [-80.996499274999962, 34.989750825000044],
            [-80.996583274999978, 34.989730826000027],
            [-80.996670273999939, 34.989730826000027],
            [-80.996756274999939, 34.989736824000033],
            [-80.996921274999977, 34.989779824000038],
            [-80.996964274999982, 34.989784826000061],
            [-80.997008274999985, 34.989782825000077],
            [-80.997092273999954, 34.989762825000071],
            [-80.997127274999968, 34.989740825000069],
            [-80.997238274999972, 34.989685825000038],
            [-80.997352274999969, 34.989634825000053],
            [-80.997381274999952, 34.989608825000062],
            [-80.997406274999946, 34.989578825000024],
            [-80.997440274999974, 34.989513825000074],
            [-80.99747627499994, 34.98941082500005],
            [-80.997549274999983, 34.989321825000047],
            [-80.997613274999935, 34.989273824000065],
            [-80.997724274999939, 34.989215825000031],
            [-80.997766274999947, 34.989204825000058],
            [-80.99798227499997, 34.989180825000062],
            [-80.998025274999975, 34.989178825000067],
            [-80.998069274999978, 34.989182825000057],
            [-80.998194275999936, 34.989210825000043],
            [-80.99844027499995, 34.989280825000037],
            [-80.998599274999947, 34.989337825000064],
            [-80.998709274999953, 34.989395825000031],
            [-80.998788275999971, 34.989427825000064],
            [-80.998917275999986, 34.989446825000073],
            [-80.999006274999942, 34.989446825000073],
            [-80.999181274999955, 34.989434825000046],
            [-80.999531274999981, 34.98945182500006],
            [-80.999747274999947, 34.989475825000056],
            [-80.999785274999965, 34.989493825000068],
            [-80.999827274999973, 34.989506825000035],
            [-80.999859275999938, 34.989529825000034],
            [-80.999970274999953, 34.989639824000051],
            [-81.000009275999957, 34.989651825000067],
            [-81.000137275999975, 34.989674824000076],
            [-81.000223275999986, 34.989661824000052],
            [-81.000309275999939, 34.989657825000052],
            [-81.000395275999949, 34.989669825000078],
            [-81.000436275999959, 34.989683825000043],
            [-81.000472274999936, 34.989703825000049],
            [-81.000536275999934, 34.989748826000039],
            [-81.000563274999934, 34.989776825000035],
            [-81.000693275999936, 34.989867825000033],
            [-81.000843276999944, 34.989936825000029],
            [-81.000925275999975, 34.989959825000028],
            [-81.001225275999957, 34.989992825000058],
            [-81.001661275999936, 34.989983825000024],
            [-81.001704275999941, 34.989983825000024],
            [-81.001832275999959, 34.98997382500005],
            [-81.002089276999982, 34.989972824000063],
            [-81.002131275999943, 34.989978825000037],
            [-81.002219275999948, 34.989976825000042],
            [-81.002305275999959, 34.989983825000024],
            [-81.002562275999935, 34.990031824000027],
            [-81.002950276999968, 34.990091825000036],
            [-81.003334275999975, 34.990168824000079],
            [-81.003417275999936, 34.990189824000026],
            [-81.003546276999941, 34.990183824000042],
            [-81.003631276999954, 34.990166825000074],
            [-81.003674276999959, 34.99016182400004],
            [-81.003714276999972, 34.990148824000073],
            [-81.003784276999966, 34.990105825000057],
            [-81.003908277999983, 34.990004825000028],
            [-81.00394427699996, 34.989985825000076],
            [-81.004031277999957, 34.989979825000034],
            [-81.004117276999978, 34.989990824000074],
            [-81.004158276999988, 34.990003825000031],
            [-81.004235276999964, 34.990038825000056],
            [-81.004354276999948, 34.990084825000054],
            [-81.004397276999953, 34.990090825000038],
            [-81.004485276999958, 34.990091825000036],
            [-81.004571276999968, 34.990076824000028],
            [-81.004649276999942, 34.990045824000049],
            [-81.004858277999972, 34.98991682500008],
            [-81.004938277999941, 34.989889824000045],
            [-81.005063276999977, 34.989862825000046],
            [-81.005238277999979, 34.989850825000076],
            [-81.005400276999978, 34.989799825000034],
            [-81.005516277999959, 34.989749825000047],
            [-81.00555327699999, 34.98972982500004],
            [-81.005583276999971, 34.989704824000057],
            [-81.005654276999962, 34.989664825000034],
            [-81.005785277999962, 34.989573825000036],
            [-81.005857276999961, 34.989534825000078],
            [-81.006055277999963, 34.989457825000045],
            [-81.006087276999949, 34.989432824000062],
            [-81.006112276999943, 34.989403825000068],
            [-81.006198276999953, 34.989322825000045],
            [-81.006321276999984, 34.989225824000073],
            [-81.006380277999938, 34.989173824000034],
            [-81.006456277999973, 34.989086825000072],
            [-81.006462277999958, 34.98905082400006],
            [-81.006456276999984, 34.989016824000032],
            [-81.006456277999973, 34.988946825000028],
            [-81.006444277999947, 34.988912825000057],
            [-81.006358276999947, 34.988831824000044],
            [-81.006260277999957, 34.988760824000053],
            [-81.006209276999982, 34.988703824000027],
            [-81.006192277999958, 34.988670824000053],
            [-81.006175277999944, 34.988530825000055],
            [-81.006183276999934, 34.98849582500003],
            [-81.006201276999946, 34.988463825000053],
            [-81.006226276999939, 34.988396824000063],
            [-81.006253277999974, 34.988257825000062],
            [-81.006279276999976, 34.987760824000077],
            [-81.00629627699999, 34.987548824000044],
            [-81.006305277999957, 34.987513824000075],
            [-81.006320277999976, 34.987481824000042],
            [-81.006367276999981, 34.98742182400008],
            [-81.006461276999971, 34.987348825000026],
            [-81.00649627699994, 34.987328824000031],
            [-81.006611276999934, 34.987279823000051],
            [-81.006776276999972, 34.987243824000075],
            [-81.006857277999984, 34.987218824000024],
            [-81.006928277999975, 34.987178823000079],
            [-81.007059276999939, 34.987115825000046],
            [-81.007473277999964, 34.98695682400006],
            [-81.007954277999943, 34.986840824000069],
            [-81.008241278999947, 34.986830823000048],
            [-81.008427276999953, 34.986808823000047],
            [-81.008353277999959, 34.987295823000068]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 55,
        "SolutionID": "PI-20",
        "Shape_Length": 0.49852191979557847,
        "Shape_Area": 0.0061678888042735009
      }
    },
    {
      "type": "Feature",
      "id": 56,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.797538249999945, 35.626347967000072],
            [-80.797167249999973, 35.626824968000051],
            [-80.794627249999962, 35.630095968000035],
            [-80.793573248999962, 35.631453969000063],
            [-80.79317924999998, 35.631961969000031],
            [-80.792655248999949, 35.632635970000024],
            [-80.791064247999941, 35.634599970000068],
            [-80.790859248999936, 35.634851970000057],
            [-80.790150248999964, 35.635698970000078],
            [-80.789974247999965, 35.635901970000077],
            [-80.789846247999947, 35.636049970000045],
            [-80.789689248999935, 35.636249970000051],
            [-80.788830248999943, 35.63738397000003],
            [-80.788456247999989, 35.637876970000036],
            [-80.788073247999989, 35.638402970000072],
            [-80.787824247999936, 35.638682971000037],
            [-80.787584246999984, 35.638936971000078],
            [-80.787020246999987, 35.639338971000029],
            [-80.786669246999963, 35.639588970000034],
            [-80.785770247999949, 35.640209971000047],
            [-80.785010246999946, 35.640725971000052],
            [-80.784033246999968, 35.641335972000036],
            [-80.783336245999976, 35.64175397200006],
            [-80.782984246999945, 35.641930972000068],
            [-80.782613245999983, 35.642081972000028],
            [-80.782089245999941, 35.642280972000037],
            [-80.782241245999955, 35.642560972000069],
            [-80.782656246999977, 35.643256972000074],
            [-80.782836246999977, 35.643559972000048],
            [-80.783048246999954, 35.644018972000026],
            [-80.783196245999989, 35.644477972000061],
            [-80.783306246999985, 35.645088972000053],
            [-80.783341246999953, 35.645568973000024],
            [-80.783318245999965, 35.646594973000049],
            [-80.784882246999985, 35.646617973000048],
            [-80.78551424799997, 35.646720973000072],
            [-80.786010247999968, 35.646890973000041],
            [-80.786712247999958, 35.647229974000027],
            [-80.78760424799998, 35.647803973000066],
            [-80.788155248999942, 35.648168972000065],
            [-80.788284248999958, 35.648254973000064],
            [-80.788696247999951, 35.648610973000075],
            [-80.789044249999961, 35.649020974000052],
            [-80.789503248999949, 35.64971997300006],
            [-80.790036249999957, 35.65046297400005],
            [-80.790294249999988, 35.650775974000055],
            [-80.79063924899998, 35.651066974000059],
            [-80.790932249999969, 35.651236974000028],
            [-80.790983249999954, 35.651265974000069],
            [-80.791414249999946, 35.651410973000054],
            [-80.791900249999969, 35.651509973000032],
            [-80.792301250999969, 35.651525973000048],
            [-80.793920250999975, 35.651605973000073],
            [-80.795796250999956, 35.651708973000041],
            [-80.796274250999943, 35.651774973000045],
            [-80.796810251999943, 35.651924973000064],
            [-80.797364251999966, 35.652194974000054],
            [-80.798383251999951, 35.652808973000049],
            [-80.799057251999955, 35.653213974000039],
            [-80.799337252999976, 35.653382973000078],
            [-80.799911251999958, 35.653782973000034],
            [-80.800560252999958, 35.654267974000049],
            [-80.80085625199996, 35.654513974000054],
            [-80.801335252999934, 35.654994974000033],
            [-80.801527252999961, 35.655287974000032],
            [-80.801647251999952, 35.655589974000065],
            [-80.801773252999965, 35.655943974000024],
            [-80.80263725399999, 35.658361975000048],
            [-80.803037253999946, 35.659269975000029],
            [-80.803468252999949, 35.658966974000066],
            [-80.806310253999982, 35.656971974000044],
            [-80.806594254999936, 35.656736974000069],
            [-80.806824254999981, 35.656503974000032],
            [-80.807006254999976, 35.656251974000043],
            [-80.807148253999969, 35.655955974000051],
            [-80.807482254999968, 35.65513397400008],
            [-80.807704254999976, 35.654542974000037],
            [-80.808107254999982, 35.653673973000025],
            [-80.808794254999952, 35.652220974000045],
            [-80.808911254999941, 35.651835973000061],
            [-80.808982253999943, 35.651489973000025],
            [-80.808990254999969, 35.651182973000061],
            [-80.809028254999987, 35.650428972000043],
            [-80.808981254999935, 35.649700972000062],
            [-80.808991254999967, 35.648748972000078],
            [-80.809017253999968, 35.648167972000067],
            [-80.809081254999967, 35.647527972000034],
            [-80.809213254999975, 35.646439972000053],
            [-80.809250255999984, 35.645998972000029],
            [-80.809240254999963, 35.645519972000045],
            [-80.809202254999946, 35.645120972000029],
            [-80.809199253999964, 35.645085971000071],
            [-80.809084254999959, 35.644411971000068],
            [-80.809000253999955, 35.643919971000059],
            [-80.808833254999968, 35.643072971000038],
            [-80.808706253999958, 35.641605971000047],
            [-80.808669254999984, 35.641331971000056],
            [-80.808649253999988, 35.640814970000065],
            [-80.808653254999967, 35.640296971000055],
            [-80.808659253999963, 35.640240970000036],
            [-80.808697253999981, 35.639895970000055],
            [-80.808710253999948, 35.639785970000048],
            [-80.808777253999949, 35.639183970000033],
            [-80.808857254999964, 35.638192970000034],
            [-80.809119254999985, 35.637167969000075],
            [-80.809217253999975, 35.636788970000055],
            [-80.809389252999949, 35.636248970000054],
            [-80.809430253999949, 35.636122970000031],
            [-80.809967254999947, 35.634437969000032],
            [-80.810036253999954, 35.634221969000066],
            [-80.810196254999937, 35.633669969000039],
            [-80.810454254999968, 35.632988969000053],
            [-80.810747254999967, 35.632428968000056],
            [-80.810800253999957, 35.632346969000025],
            [-80.810921254999982, 35.632163969000032],
            [-80.811213254999984, 35.63193596900004],
            [-80.811668254999972, 35.631654969000067],
            [-80.812058254999954, 35.631426969000074],
            [-80.812835254999982, 35.630979969000066],
            [-80.813322254999946, 35.630716968000058],
            [-80.81366225499994, 35.630545968000035],
            [-80.813981254999987, 35.630438969000068],
            [-80.814323254999977, 35.630337968000049],
            [-80.814417255999956, 35.630317969000032],
            [-80.814836254999989, 35.630227968000042],
            [-80.81545925599994, 35.630084968000062],
            [-80.815855255999963, 35.629937968000036],
            [-80.816265255999951, 35.629739967000035],
            [-80.81645025499995, 35.629628968000077],
            [-80.816770256999973, 35.629415968000046],
            [-80.816813255999989, 35.629386967000073],
            [-80.817136256999959, 35.629139968000061],
            [-80.817489255999988, 35.628840967000031],
            [-80.81763925599995, 35.62873096800007],
            [-80.818058256999961, 35.628422967000063],
            [-80.818396256999961, 35.628136968000035],
            [-80.81860325599996, 35.62794296800007],
            [-80.818940256999952, 35.627592967000055],
            [-80.81898625599996, 35.62754196700007],
            [-80.819535256999984, 35.62692596800008],
            [-80.82007725699998, 35.626348967000069],
            [-80.820262256999968, 35.62619896700005],
            [-80.820486255999981, 35.626080967000064],
            [-80.820530256999973, 35.626065968000034],
            [-80.820750256999986, 35.625987967000071],
            [-80.821077256999956, 35.625937967000027],
            [-80.821272256999976, 35.625938967000025],
            [-80.821633257999963, 35.625942966000025],
            [-80.821697257999972, 35.625944967000066],
            [-80.822165256999938, 35.625959967000028],
            [-80.822501256999942, 35.625948967000056],
            [-80.822806256999968, 35.625905967000051],
            [-80.822968257999946, 35.62583896700005],
            [-80.823030256999971, 35.625812967000059],
            [-80.823192257999949, 35.625722967000058],
            [-80.823254257999963, 35.625687967000033],
            [-80.823586257999978, 35.625472967000064],
            [-80.82381825799996, 35.625372966000043],
            [-80.824199257999965, 35.625369966000051],
            [-80.824042257999963, 35.625932968000029],
            [-80.823940256999947, 35.626503967000076],
            [-80.823892257999944, 35.627078968000035],
            [-80.823899257999983, 35.627655967000067],
            [-80.823962257999938, 35.628230968000025],
            [-80.824170256999935, 35.629556968000031],
            [-80.824247257999957, 35.630045968000047],
            [-80.824363258999938, 35.63078796800005],
            [-80.824451258999943, 35.631346968000059],
            [-80.824517257999958, 35.63165796800007],
            [-80.824618257999987, 35.631963968000036],
            [-80.824782257999971, 35.632384969000043],
            [-80.824976258999982, 35.63279796900008],
            [-80.825229258999968, 35.633188969000059],
            [-80.825537258999987, 35.63355296900005],
            [-80.825727258999962, 35.633759968000049],
            [-80.825850258999935, 35.633893968000052],
            [-80.825959258999944, 35.633991968000032],
            [-80.826201258999959, 35.634208969000042],
            [-80.826587257999961, 35.634495968000067],
            [-80.827005258999975, 35.634750968000048],
            [-80.828022259999955, 35.635313969000038],
            [-80.829737260999934, 35.636177969000073],
            [-80.831265261999988, 35.636947969000062],
            [-80.831722260999982, 35.637178969000047],
            [-80.834923261999961, 35.638797969000052],
            [-80.835476262999975, 35.63910596900007],
            [-80.835890261999964, 35.639361969000049],
            [-80.836225261999971, 35.639582970000049],
            [-80.836622262999981, 35.639868969000077],
            [-80.836823262999985, 35.640022970000075],
            [-80.837125261999972, 35.639734970000063],
            [-80.837926262999986, 35.638954969000054],
            [-80.838166262999948, 35.638653969000075],
            [-80.838569262999954, 35.638204969000071],
            [-80.837736262999954, 35.637913969000067],
            [-80.837293263999982, 35.637596968000025],
            [-80.836961262999978, 35.637210968000034],
            [-80.83682526299998, 35.63689296900003],
            [-80.83683726199996, 35.635960968000063],
            [-80.837164262999977, 35.635313969000038],
            [-80.837209261999988, 35.635302968000076],
            [-80.837462262999964, 35.635073969000075],
            [-80.837742261999949, 35.634638968000047],
            [-80.837770262999982, 35.633859968000024],
            [-80.83810626199994, 35.633218968000051],
            [-80.838134261999983, 35.632325968000032],
            [-80.83844226299999, 35.631615967000073],
            [-80.838498262999963, 35.630905968000036],
            [-80.839171262999969, 35.629965967000032],
            [-80.839451262999944, 35.629187967000064],
            [-80.840040262999935, 35.628454967000039],
            [-80.840657262999969, 35.627400966000039],
            [-80.841062262999969, 35.627193967000039],
            [-80.842003262999981, 35.626713966000068],
            [-80.843013262999989, 35.626323966000029],
            [-80.843405263999955, 35.626437967000072],
            [-80.843910263999987, 35.626369966000027],
            [-80.844443264999938, 35.626804966000066],
            [-80.844892264999942, 35.62694196700005],
            [-80.845089263999967, 35.62719396600005],
            [-80.845201264999957, 35.627605966000033],
            [-80.845493264999959, 35.627784967000025],
            [-80.845987265999952, 35.628086966000069],
            [-80.846576264999953, 35.628154967000057],
            [-80.847305264999989, 35.628017966000073],
            [-80.84783826599994, 35.627809966000029],
            [-80.848399265999944, 35.627443967000033],
            [-80.849184265999952, 35.627259967000043],
            [-80.849745265999957, 35.626984967000055],
            [-80.850222266999936, 35.626892966000071],
            [-80.850326264999978, 35.626916966000067],
            [-80.850727266999968, 35.627007966000065],
            [-80.851175266999974, 35.626961967000057],
            [-80.851596265999945, 35.62714496600006],
            [-80.85190526599996, 35.627167966000059],
            [-80.852083266999955, 35.627047966000077],
            [-80.852222265999956, 35.626953966000031],
            [-80.852858265999942, 35.626525966000031],
            [-80.853840266999953, 35.626731966000079],
            [-80.854317267999988, 35.627143965000073],
            [-80.854317267999988, 35.627441966000049],
            [-80.854626266999958, 35.62764796600004],
            [-80.855103266999947, 35.627784966000036],
            [-80.856758267999965, 35.627761966000037],
            [-80.858020267999962, 35.627875966000033],
            [-80.859086268999988, 35.627805966000039],
            [-80.859507268999948, 35.628080966000027],
            [-80.860040268999967, 35.628607966000061],
            [-80.860377268999969, 35.628721967000047],
            [-80.860658268999941, 35.628996965000056],
            [-80.861387269999966, 35.629408966000028],
            [-80.861724269999968, 35.629408966000028],
            [-80.861696268999935, 35.629110967000031],
            [-80.861527268999964, 35.628537966000067],
            [-80.862046268999961, 35.628482966000036],
            [-80.86238426999995, 35.628447966000067],
            [-80.86220426899996, 35.627896966000037],
            [-80.861904269999968, 35.627117966000071],
            [-80.861563269999976, 35.626348965000034],
            [-80.86105226899997, 35.62526596500004],
            [-80.860435268999936, 35.623983965000036],
            [-80.859050267999976, 35.621109964000027],
            [-80.858616267999935, 35.620219965000047],
            [-80.858222267999963, 35.61931796500005],
            [-80.85786726799995, 35.618404965000025],
            [-80.857648267999934, 35.61760696500005],
            [-80.85749526799998, 35.616798963000065],
            [-80.857409267999969, 35.615983964000065],
            [-80.857404266999936, 35.615849964000063],
            [-80.857395267999948, 35.615631963000055],
            [-80.857387267999968, 35.615278963000037],
            [-80.857415267999954, 35.614588963000074],
            [-80.857484266999961, 35.613900964000038],
            [-80.85759326799996, 35.613215964000062],
            [-80.857844267999951, 35.611846962000072],
            [-80.857964267999989, 35.611159962000045],
            [-80.858404266999969, 35.608766962000061],
            [-80.858583267999961, 35.607736962000047],
            [-80.858626267999966, 35.607498962000079],
            [-80.858781267999973, 35.606648961000076],
            [-80.85887626799996, 35.606128961000024],
            [-80.858946267999954, 35.605744962000074],
            [-80.859142266999982, 35.604623961000073],
            [-80.859186266999984, 35.604374961000076],
            [-80.859623266999961, 35.601958960000047],
            [-80.859737267999947, 35.601332960000036],
            [-80.859881267999981, 35.600533960000064],
            [-80.85992026699995, 35.600317960000041],
            [-80.859942266999951, 35.600196960000062],
            [-80.860087266999983, 35.59934996000004],
            [-80.860201266999979, 35.598435960000074],
            [-80.86024226699999, 35.59791596000008],
            [-80.860036266999941, 35.597577960000024],
            [-80.859989266999946, 35.597501959000056],
            [-80.85971726799994, 35.597055960000034],
            [-80.859182265999948, 35.596964959000047],
            [-80.859122266999975, 35.596957959000065],
            [-80.858495265999977, 35.596882959000027],
            [-80.858099266999943, 35.596875960000034],
            [-80.857656265999935, 35.596915959000057],
            [-80.857088265999948, 35.597020959000076],
            [-80.856837265999957, 35.597066960000063],
            [-80.856413265999947, 35.597138960000052],
            [-80.85401126499994, 35.597547960000043],
            [-80.853614264999976, 35.597615961000031],
            [-80.853360264999935, 35.59765795900006],
            [-80.853067265999982, 35.597706960000039],
            [-80.852130265999961, 35.597862960000043],
            [-80.851497263999988, 35.59797696000004],
            [-80.851082264999945, 35.598050959000034],
            [-80.85100926399997, 35.598061960000052],
            [-80.850782264999964, 35.59809996000007],
            [-80.849611263999975, 35.598290960000043],
            [-80.848863262999942, 35.598429960000033],
            [-80.848495263999951, 35.598498960000029],
            [-80.84809426399994, 35.598623960000054],
            [-80.847742263999976, 35.598747961000072],
            [-80.847378262999939, 35.598962961000041],
            [-80.847065262999934, 35.597857960000056],
            [-80.847013262999951, 35.597464959000035],
            [-80.847006262999969, 35.59741796000003],
            [-80.846973263999985, 35.597160960000053],
            [-80.846959262999974, 35.596695960000034],
            [-80.847410262999972, 35.594780959000047],
            [-80.847508263999941, 35.594320960000061],
            [-80.84759126299997, 35.593535959000064],
            [-80.847662262999961, 35.592866959000048],
            [-80.847709262999956, 35.592576959000041],
            [-80.84780826299999, 35.591952959000025],
            [-80.847924262999982, 35.591314959000044],
            [-80.848652262999963, 35.590042958000026],
            [-80.848867263999978, 35.589678959000025],
            [-80.849063262999948, 35.589343958000029],
            [-80.849520263999977, 35.58825495800005],
            [-80.850096263999944, 35.586989957000071],
            [-80.850478263999946, 35.586149958000078],
            [-80.850722263999955, 35.585724957000025],
            [-80.850771263999945, 35.585638958000061],
            [-80.850913263999985, 35.585392957000067],
            [-80.851216264999948, 35.584673957000064],
            [-80.851370263999968, 35.584235957000033],
            [-80.851921263999941, 35.582738956000071],
            [-80.853051263999987, 35.57965495600007],
            [-80.853115263999939, 35.579479956000057],
            [-80.854240265999977, 35.579783956000028],
            [-80.854866263999952, 35.579843956000047],
            [-80.855495264999945, 35.579857956000069],
            [-80.856176264999988, 35.579849956000032],
            [-80.856406264999976, 35.57984695600004],
            [-80.856291264999982, 35.579416956000046],
            [-80.856100264999952, 35.578571956000076],
            [-80.85596626499995, 35.577735956000026],
            [-80.855884264999986, 35.576894955000057],
            [-80.855860263999944, 35.576090955000041],
            [-80.855886263999935, 35.575286955000024],
            [-80.855930264999984, 35.57470995500006],
            [-80.856004264999967, 35.574135955000031],
            [-80.856036264999943, 35.573961955000073],
            [-80.856109265999976, 35.573564955000052],
            [-80.856315264999978, 35.572702954000079],
            [-80.856545264999966, 35.571844954000028],
            [-80.857805264999968, 35.567320953000035],
            [-80.858190264999962, 35.565940953000052],
            [-80.858417264999957, 35.565096952000033],
            [-80.858601265999937, 35.564256952000051],
            [-80.858738264999943, 35.563410953000073],
            [-80.858816264999973, 35.562575952000032],
            [-80.858835264999982, 35.561737951000055],
            [-80.858806263999952, 35.560895952000067],
            [-80.858803264999949, 35.560865952000029],
            [-80.858715263999954, 35.560055952000027],
            [-80.858594263999976, 35.559215952000045],
            [-80.858429264999984, 35.558015951000073],
            [-80.85824426399995, 35.556694951000054],
            [-80.858121264999966, 35.555858951000062],
            [-80.858012263999967, 35.555020951000074],
            [-80.857940263999978, 35.554280951000067],
            [-80.857909263999943, 35.553537950000077],
            [-80.857907263999948, 35.553400951000071],
            [-80.857815263999953, 35.553342950000058],
            [-80.857687263999935, 35.553266950000079],
            [-80.857192262999945, 35.55297395000008],
            [-80.856943263999938, 35.552862950000076],
            [-80.856814263999979, 35.55280595000005],
            [-80.856565263999983, 35.552721950000034],
            [-80.856387262999988, 35.552724950000027],
            [-80.856323263999968, 35.552725949000035],
            [-80.856065262999948, 35.552751950000072],
            [-80.855826262999983, 35.55280594900006],
            [-80.855750262999948, 35.552836951000074],
            [-80.855588262999959, 35.552903950000029],
            [-80.855431262999957, 35.553037950000032],
            [-80.855300262999947, 35.553184951000048],
            [-80.855226263999953, 35.553283951000026],
            [-80.854231263999964, 35.55483195000005],
            [-80.853220262999969, 35.556420951000064],
            [-80.852988262999986, 35.556782951000059],
            [-80.85185426299995, 35.558549952000078],
            [-80.851524262999988, 35.559075952000057],
            [-80.851128262999964, 35.559704952000061],
            [-80.850820261999957, 35.560195952000072],
            [-80.850397262999934, 35.560869952000076],
            [-80.84954626199999, 35.560000952000053],
            [-80.848957261999942, 35.55953295200004],
            [-80.847870260999969, 35.558813952000037],
            [-80.847758261999957, 35.558740951000061],
            [-80.847066260999952, 35.558295952000037],
            [-80.844986260999974, 35.556956952000064],
            [-80.844927260999953, 35.556919951000054],
            [-80.844263259999934, 35.556495951000045],
            [-80.843609260999983, 35.556079951000072],
            [-80.843255259999978, 35.555853951000074],
            [-80.842700259999958, 35.555528952000031],
            [-80.842598258999942, 35.55546895100008],
            [-80.842348258999948, 35.555348951000042],
            [-80.841956259999961, 35.555211951000047],
            [-80.841318258999934, 35.554988951000041],
            [-80.842014258999939, 35.554010951000066],
            [-80.842992258999971, 35.55263995100006],
            [-80.843709259999969, 35.551632951000045],
            [-80.845140259999937, 35.549636950000036],
            [-80.845444259999965, 35.54916895000008],
            [-80.845520259999944, 35.549055950000024],
            [-80.845750259999988, 35.548712950000038],
            [-80.846099260999949, 35.548042950000024],
            [-80.846170259999951, 35.547905950000029],
            [-80.846312259999934, 35.547396949000074],
            [-80.846329260999937, 35.547315950000041],
            [-80.846914260999938, 35.544508949000033],
            [-80.847037260999969, 35.543914948000065],
            [-80.847164259999943, 35.543309948000058],
            [-80.84718626099999, 35.543205949000026],
            [-80.844841258999963, 35.54293394900003],
            [-80.843306258999974, 35.542755949000025],
            [-80.842928258999962, 35.542690948000029],
            [-80.842416258999947, 35.542575949000025],
            [-80.84188525899998, 35.542390948000048],
            [-80.841461258999971, 35.542186948000051],
            [-80.841221258999951, 35.54205594900003],
            [-80.839996257999985, 35.541386949000071],
            [-80.839104257999963, 35.540898948000063],
            [-80.838679256999967, 35.54066694800008],
            [-80.837516256999947, 35.540031948000035],
            [-80.836538256999972, 35.539522948000069],
            [-80.835818256999971, 35.539148948000047],
            [-80.835524255999985, 35.538995948000036],
            [-80.834772255999951, 35.538589948000038],
            [-80.834267255999976, 35.538317948000042],
            [-80.833720256999982, 35.538065948000053],
            [-80.831704254999977, 35.537382947000026],
            [-80.831434254999976, 35.537287948000028],
            [-80.830289255999958, 35.536884948000079],
            [-80.829960254999946, 35.536769948000028],
            [-80.829544254999973, 35.536594948000072],
            [-80.829356254999936, 35.536493948000043],
            [-80.829153254999937, 35.536385948000031],
            [-80.828790254999944, 35.536143947000028],
            [-80.825585252999986, 35.533805948000065],
            [-80.825333253999986, 35.533669947000078],
            [-80.825007252999967, 35.533526947000041],
            [-80.82465625399999, 35.533434947000046],
            [-80.824289252999961, 35.533396947000028],
            [-80.823920252999983, 35.53341494700004],
            [-80.823562252999977, 35.533485948000077],
            [-80.822973252999986, 35.533651948000056],
            [-80.821921252999971, 35.534051948000069],
            [-80.822061252999958, 35.534273948000077],
            [-80.822210251999934, 35.534684948000063],
            [-80.822329252999964, 35.536182948000032],
            [-80.821096252999951, 35.538748948000034],
            [-80.820828251999956, 35.539452948000076],
            [-80.821253252999952, 35.541539949000025],
            [-80.821365252999954, 35.542204949000052],
            [-80.822348252999973, 35.544287949000079],
            [-80.822966252999947, 35.546004950000054],
            [-80.822884252999984, 35.546709950000036],
            [-80.822778253999957, 35.547632950000036],
            [-80.823126253999988, 35.548178951000068],
            [-80.825044254999966, 35.550400951000029],
            [-80.826111253999954, 35.552736952000032],
            [-80.827081254999939, 35.55919795300008],
            [-80.827387255999952, 35.56128495400003],
            [-80.827285255999982, 35.561169952000057],
            [-80.826845254999967, 35.560760953000056],
            [-80.826299253999935, 35.560393952000027],
            [-80.825990253999976, 35.56022695300004],
            [-80.825544254999954, 35.559986953000077],
            [-80.82469725499999, 35.559638953000047],
            [-80.824286253999958, 35.559469953000075],
            [-80.823939253999981, 35.559326953000038],
            [-80.822714253999948, 35.558821953000063],
            [-80.821127252999986, 35.558168952000074],
            [-80.821044253999958, 35.558145953000064],
            [-80.820690253999942, 35.558043953000038],
            [-80.820027252999978, 35.557921953000061],
            [-80.81922025199998, 35.557872953000071],
            [-80.81805325199997, 35.557937953000078],
            [-80.817417251999984, 35.557972953000046],
            [-80.817053251999937, 35.55799395300005],
            [-80.816286251999941, 35.558036952000066],
            [-80.812109250999981, 35.558289953000042],
            [-80.81152825099997, 35.55653095200006],
            [-80.811394250999967, 35.556284952000055],
            [-80.811232250999979, 35.556051953000065],
            [-80.811042250999947, 35.555831952000062],
            [-80.810640249999949, 35.555930953000029],
            [-80.810121250999941, 35.555977953000024],
            [-80.807084249999946, 35.555812952000053],
            [-80.806164248999949, 35.555762953000055],
            [-80.805742247999945, 35.555402952000065],
            [-80.804448248999961, 35.55361595200003],
            [-80.804256248999934, 35.552997952000055],
            [-80.804050247999953, 35.552782953000076],
            [-80.803924247999987, 35.552427953000063],
            [-80.803918247999945, 35.552272952000067],
            [-80.803918248999935, 35.551712952000059],
            [-80.803582247999941, 35.551047952000033],
            [-80.803105247999952, 35.550543951000066],
            [-80.802594247999934, 35.55017795200007],
            [-80.80220924799994, 35.549902951000036],
            [-80.801638246999971, 35.549286952000045],
            [-80.801035246999959, 35.548635951000051],
            [-80.800107245999982, 35.547635951000075],
            [-80.800079246999985, 35.547428951000029],
            [-80.799967246999984, 35.547428951000029],
            [-80.799659245999976, 35.54713195100004],
            [-80.799463245999959, 35.546764951000057],
            [-80.798875245999966, 35.546192950000034],
            [-80.798707245999935, 35.545344951000061],
            [-80.79842724599996, 35.54472695100003],
            [-80.798118245999945, 35.544542951000039],
            [-80.798146245999988, 35.545413951000057],
            [-80.798432245999948, 35.546873952000055],
            [-80.79855024699998, 35.548630951000064],
            [-80.798537246999956, 35.549115952000079],
            [-80.79852524599994, 35.54952095200008],
            [-80.79816524599994, 35.550683952000043],
            [-80.797804245999941, 35.551482952000072],
            [-80.797704246999956, 35.552523953000048],
            [-80.797624245999941, 35.55246995300007],
            [-80.79468624499998, 35.552331953000078],
            [-80.790759244999947, 35.552073952000057],
            [-80.790825243999961, 35.55248595300003],
            [-80.790896243999953, 35.552933953000036],
            [-80.790905243999987, 35.553364953000028],
            [-80.790865243999974, 35.554176953000024],
            [-80.790853243999948, 35.554417953000041],
            [-80.790829243999951, 35.554897953000079],
            [-80.790825243999961, 35.55498095300004],
            [-80.790785243999949, 35.555796953000026],
            [-80.790756243999965, 35.556406953000078],
            [-80.790718243999947, 35.557194953000078],
            [-80.79063124399994, 35.558578953000051],
            [-80.790615244999969, 35.558840954000061],
            [-80.790581244999942, 35.559372954000025],
            [-80.790502244999971, 35.559650953000073],
            [-80.790370244999963, 35.559915955000065],
            [-80.790188243999978, 35.560159954000028],
            [-80.78996224499997, 35.560376954000048],
            [-80.78969624399997, 35.560562954000034],
            [-80.789398243999983, 35.560711955000045],
            [-80.789074243999949, 35.560820954000064],
            [-80.788333243999944, 35.561009954000042],
            [-80.787831242999971, 35.56043995400006],
            [-80.786952243999963, 35.559527954000032],
            [-80.786161242999981, 35.558761954000033],
            [-80.786107242999947, 35.558709954000051],
            [-80.786100243999954, 35.558702954000069],
            [-80.783882241999947, 35.556554953000045],
            [-80.782918241999937, 35.555656954000028],
            [-80.782306240999958, 35.555162953000035],
            [-80.781769241999939, 35.554854953000074],
            [-80.781340241999942, 35.554631953000069],
            [-80.780041240999935, 35.554092953000065],
            [-80.779657239999949, 35.553933954000058],
            [-80.778547239999966, 35.55349095300005],
            [-80.777846239999974, 35.553210954000065],
            [-80.777419239999972, 35.553022953000038],
            [-80.777047239999945, 35.552858954000044],
            [-80.776752239999951, 35.552709953000033],
            [-80.776464239999939, 35.552558953000073],
            [-80.77599123899995, 35.55224495300007],
            [-80.775843239999972, 35.552135953000061],
            [-80.775034239999968, 35.552906953000047],
            [-80.773326238999971, 35.554570954000042],
            [-80.773261238999964, 35.554634954000051],
            [-80.772721238999964, 35.555161955000074],
            [-80.770296237999958, 35.557496954000044],
            [-80.77176523899999, 35.557804955000051],
            [-80.772146237999948, 35.557893954000065],
            [-80.772456238999951, 35.558004955000058],
            [-80.77265123899997, 35.558114955000065],
            [-80.772729238999943, 35.558158955000067],
            [-80.772971238999958, 35.558343954000065],
            [-80.773161239999979, 35.558524955000053],
            [-80.774567238999964, 35.560057954000058],
            [-80.774737239999979, 35.560418955000046],
            [-80.774842239999941, 35.560863955000059],
            [-80.774844239999936, 35.560872955000036],
            [-80.774907240999937, 35.561269955000057],
            [-80.774914238999941, 35.562063955000042],
            [-80.77500723899999, 35.565925956000058],
            [-80.774997239999948, 35.566571956000075],
            [-80.775040240999942, 35.567026956000063],
            [-80.775153239999952, 35.567360956000073],
            [-80.775246240999934, 35.567636956000058],
            [-80.775255240999968, 35.567654956000069],
            [-80.775479239999981, 35.568093956000041],
            [-80.774349239999935, 35.568667957000059],
            [-80.772997238999949, 35.569364956000072],
            [-80.771199239999987, 35.570289958000046],
            [-80.767211237999959, 35.572343957000044],
            [-80.766514237999957, 35.572710958000073],
            [-80.765951238999946, 35.573007957000073],
            [-80.763015236999934, 35.574506958000029],
            [-80.762530236999964, 35.574796958000036],
            [-80.762133236999944, 35.575105959000041],
            [-80.761827235999988, 35.575479959000063],
            [-80.761642235999943, 35.575890959000048],
            [-80.761528236999936, 35.576228958000058],
            [-80.761456236999948, 35.576631959000053],
            [-80.761479236999946, 35.57835895900007],
            [-80.761510237999971, 35.579859960000078],
            [-80.761572235999949, 35.581222959000058],
            [-80.761790236999957, 35.582882960000063],
            [-80.761857236999958, 35.583430960000044],
            [-80.76199123799995, 35.584537960000034],
            [-80.761927236999952, 35.584940961000029],
            [-80.761738237999964, 35.585490960000072],
            [-80.761522236999951, 35.585804960000075],
            [-80.761103236999986, 35.586210960000074],
            [-80.76058123599995, 35.586540961000026],
            [-80.757725235999942, 35.587940961000072],
            [-80.757543236999936, 35.588101961000064],
            [-80.757503235999934, 35.588147961000061],
            [-80.75736223499996, 35.588308962000042],
            [-80.757240235999973, 35.58863296100003],
            [-80.757231235999939, 35.588773962000062],
            [-80.757108235999965, 35.590717962000042],
            [-80.757022235999955, 35.592407963000028],
            [-80.756529235999949, 35.592176963000043],
            [-80.754372235999938, 35.591189962000044],
            [-80.75291223499994, 35.59051596200004],
            [-80.752501233999965, 35.590300963000061],
            [-80.750763233999976, 35.589393962000031],
            [-80.750642234999987, 35.589307962000078],
            [-80.751008233999983, 35.59080896200004],
            [-80.752109234999978, 35.595311964000075],
            [-80.752476234999961, 35.596812963000048],
            [-80.752711235999982, 35.59776796400007],
            [-80.753769234999936, 35.602074964000053],
            [-80.753908234999983, 35.602647965000074],
            [-80.754328235999935, 35.604366965000054],
            [-80.754468236999969, 35.604939965000028],
            [-80.754599236999979, 35.605476965000037],
            [-80.754994236999948, 35.607089966000046],
            [-80.755126236999956, 35.607627965000063],
            [-80.755185236999978, 35.607869966000067],
            [-80.755364236999981, 35.608595966000053],
            [-80.755424236999943, 35.608838966000064],
            [-80.755589235999935, 35.609514966000063],
            [-80.756084237999971, 35.611542966000059],
            [-80.756249236999963, 35.612218967000047],
            [-80.756266236999977, 35.612288966000051],
            [-80.756353237999974, 35.612643966000064],
            [-80.757242236999957, 35.616271967000046],
            [-80.757434237999973, 35.617052968000053],
            [-80.758230237999953, 35.620300968000038],
            [-80.759135237999942, 35.62399496900008],
            [-80.759190238999963, 35.624277968000058],
            [-80.759324239999955, 35.624967969000068],
            [-80.759346238999967, 35.625128969000059],
            [-80.759387237999988, 35.625414969000076],
            [-80.759588237999935, 35.625389969000025],
            [-80.75975023899997, 35.625356969000052],
            [-80.759884239999963, 35.625346970000066],
            [-80.759911238999962, 35.625335969000048],
            [-80.759992238999985, 35.625335969000048],
            [-80.760019238999973, 35.625324969000076],
            [-80.760126238999987, 35.625313968000057],
            [-80.760234238999942, 35.625313969000047],
            [-80.760665238999934, 35.625269969000044],
            [-80.761176239999941, 35.625270970000031],
            [-80.761714238999957, 35.625314968000055],
            [-80.761822239999958, 35.625336969000045],
            [-80.761876239999935, 35.625336969000045],
            [-80.762091239999961, 35.625380969000048],
            [-80.762360239999964, 35.625381969000046],
            [-80.762630239999964, 35.625348969000072],
            [-80.762656239999956, 35.625337969000043],
            [-80.762798238999949, 35.625315969000042],
            [-80.762818238999955, 35.625304969000069],
            [-80.763006238999935, 35.625282969000068],
            [-80.763114239999936, 35.62529396900004],
            [-80.763276239999982, 35.625294968000048],
            [-80.763329239999962, 35.625305969000067],
            [-80.763428239999939, 35.625305969000067],
            [-80.763518239999939, 35.625305969000067],
            [-80.763679239999988, 35.625272968000047],
            [-80.764051239999958, 35.625134969000044],
            [-80.764191239999946, 35.625042968000059],
            [-80.764318240999955, 35.624945969000066],
            [-80.764747239999963, 35.62447596800007],
            [-80.765150240999958, 35.624084968000034],
            [-80.765306240999962, 35.62395396800008],
            [-80.765474239999946, 35.623833968000042],
            [-80.765818240999977, 35.623548969000069],
            [-80.765981240999963, 35.623433969000075],
            [-80.766250239999977, 35.623269968000045],
            [-80.766520240999967, 35.623127968000063],
            [-80.766749240999957, 35.623013968000066],
            [-80.767099240999983, 35.622871969000073],
            [-80.767396240999972, 35.622768969000049],
            [-80.767557240999963, 35.622730968000042],
            [-80.767611241999987, 35.62270896800004],
            [-80.767665241999964, 35.62270896800004],
            [-80.767968240999949, 35.622638968000047],
            [-80.768203240999981, 35.622550968000041],
            [-80.768419240999947, 35.622458968000046],
            [-80.768554240999947, 35.62238196800007],
            [-80.76863524099997, 35.62234896700005],
            [-80.769015241999966, 35.622108968000077],
            [-80.769451240999956, 35.621813969000073],
            [-80.770146241999953, 35.621260968000058],
            [-80.770180240999935, 35.621244968000042],
            [-80.770335241999987, 35.621118967000029],
            [-80.770827241999939, 35.620763968000063],
            [-80.770928241999968, 35.620681968000042],
            [-80.771144240999945, 35.620527968000033],
            [-80.771609242999943, 35.620227967000062],
            [-80.772574242999951, 35.619532968000044],
            [-80.772952241999974, 35.619314967000037],
            [-80.77353124199999, 35.619035967000059],
            [-80.773881241999959, 35.618894967000074],
            [-80.774508241999968, 35.618692968000062],
            [-80.77520124299997, 35.618420967000077],
            [-80.775390242999947, 35.618365968000035],
            [-80.775498241999969, 35.618344968000031],
            [-80.775632242999961, 35.618333967000069],
            [-80.775740243999962, 35.618312967000065],
            [-80.77579424399994, 35.618312967000065],
            [-80.776171243999954, 35.618252967000046],
            [-80.776305243999957, 35.618214967000029],
            [-80.776575243999957, 35.618116967000049],
            [-80.776803242999961, 35.618051967000042],
            [-80.776992243999985, 35.617975967000064],
            [-80.777430242999969, 35.617762967000033],
            [-80.777882243999954, 35.617478966000078],
            [-80.778104243999962, 35.617303966000065],
            [-80.778327243999968, 35.617106967000041],
            [-80.778538242999957, 35.61689296600008],
            [-80.77889324399996, 35.616899967000052],
            [-80.779108243999985, 35.61687796700005],
            [-80.779162243999963, 35.616867966000029],
            [-80.779243243999986, 35.616834966000056],
            [-80.779378243999986, 35.61674696700004],
            [-80.779485243999943, 35.616703967000035],
            [-80.779539243999977, 35.61669796700005],
            [-80.779593243999955, 35.616709967000077],
            [-80.779862243999958, 35.616869966000024],
            [-80.780318243999943, 35.617040967000037],
            [-80.780533244999958, 35.617134967000027],
            [-80.780560244999947, 35.617156967000028],
            [-80.780614244999981, 35.617179967000027],
            [-80.780956243999981, 35.617372967000051],
            [-80.781030244999954, 35.617400967000037],
            [-80.781345244999955, 35.617560966000042],
            [-80.781581244999984, 35.617654966000032],
            [-80.781607244999975, 35.617676967000079],
            [-80.781795244999955, 35.617753966000066],
            [-80.781822244999944, 35.617775966000067],
            [-80.781930244999955, 35.617820967000057],
            [-80.781956244999947, 35.617842967000058],
            [-80.782144244999984, 35.617919967000034],
            [-80.782171244999972, 35.617941967000036],
            [-80.782381244999954, 35.618037966000031],
            [-80.782173244999967, 35.619086967000044],
            [-80.783507245999942, 35.619818967000072],
            [-80.783906245999958, 35.62003796700003],
            [-80.783860244999971, 35.620113967000066],
            [-80.78458124499997, 35.620474967000064],
            [-80.785551246999944, 35.620960968000077],
            [-80.785923246999971, 35.621210967000025],
            [-80.786164245999942, 35.621408968000026],
            [-80.786913246999973, 35.622134967000079],
            [-80.789107246999947, 35.62426296700005],
            [-80.789297247999968, 35.624446968000029],
            [-80.789595247999955, 35.62474996800006],
            [-80.789627247999988, 35.624795968000058],
            [-80.790775247999989, 35.624161968000067],
            [-80.791031247999967, 35.624052968000058],
            [-80.791256247999968, 35.623978968000074],
            [-80.791534247999948, 35.623930968000025],
            [-80.791900247999934, 35.623934968000071],
            [-80.792709248999984, 35.62401096800005],
            [-80.792957248999983, 35.624024967000025],
            [-80.793182247999937, 35.624003967000078],
            [-80.793514248999941, 35.623945967000054],
            [-80.793680248999976, 35.62390896800008],
            [-80.794617249999988, 35.623700968000037],
            [-80.795057248999967, 35.623632968000038],
            [-80.795508248999965, 35.623591968000028],
            [-80.795798249999962, 35.623578967000071],
            [-80.796261249999986, 35.623588968000035],
            [-80.796452249999959, 35.623626968000053],
            [-80.796748249999951, 35.62368796700008],
            [-80.797127248999971, 35.62382296800007],
            [-80.798057248999953, 35.624290967000036],
            [-80.798381249999977, 35.624453967000079],
            [-80.798670250999976, 35.624598968000043],
            [-80.798833250999962, 35.624680968000064],
            [-80.798715250999976, 35.624832968000078],
            [-80.79856424999997, 35.625026967000053],
            [-80.798350249999942, 35.625302968000028],
            [-80.797988249999946, 35.625768968000045],
            [-80.797538249999945, 35.626347967000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 56,
        "SolutionID": "PI-20",
        "Shape_Length": 0.57771039241510891,
        "Shape_Area": 0.0076690575638891003
      }
    },
    {
      "type": "Feature",
      "id": 57,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.838707243999977, 35.292984895000075],
            [-80.839487244999987, 35.293009895000068],
            [-80.840108244999954, 35.293029895000075],
            [-80.840792244999989, 35.293052896000063],
            [-80.841973245999952, 35.293087895000042],
            [-80.842607244999954, 35.293108895000046],
            [-80.842797244999986, 35.29312189500007],
            [-80.842931244999988, 35.293140895000079],
            [-80.843025245999968, 35.293166896000059],
            [-80.843079245999945, 35.293187895000074],
            [-80.843276245999959, 35.293295895000028],
            [-80.843457244999968, 35.293418896000048],
            [-80.843650245999982, 35.293568895000078],
            [-80.843759244999944, 35.29366989500005],
            [-80.843851245999986, 35.293781896000041],
            [-80.843907245999958, 35.293871895000052],
            [-80.843976245999954, 35.294006896000042],
            [-80.84400724599999, 35.294077895000044],
            [-80.844337245999952, 35.29482489600008],
            [-80.844510244999981, 35.295216896000056],
            [-80.844669245999967, 35.295576895000067],
            [-80.844712245999972, 35.295698896000033],
            [-80.844733245999976, 35.295780896000053],
            [-80.844752245999985, 35.295954895000079],
            [-80.844770245999939, 35.296510896000029],
            [-80.844771245999937, 35.296824896000032],
            [-80.844775245999983, 35.297190896000075],
            [-80.844770245999939, 35.297853896000049],
            [-80.844762245999959, 35.297964896000053],
            [-80.844634245999941, 35.299852897000051],
            [-80.844616245999987, 35.300286897000035],
            [-80.84461224599994, 35.300507898000035],
            [-80.844576246999964, 35.301290897000058],
            [-80.844584246999943, 35.301851897000063],
            [-80.844596245999981, 35.302172897000048],
            [-80.844639245999986, 35.303385898000045],
            [-80.844728245999988, 35.305966899000055],
            [-80.844756246999964, 35.306736898000054],
            [-80.844781246999958, 35.307156898000073],
            [-80.844833245999951, 35.307820898000045],
            [-80.84520424699997, 35.30776189900007],
            [-80.845684246999951, 35.307698899000059],
            [-80.846841247999976, 35.307546899000045],
            [-80.847599247999938, 35.30744689900007],
            [-80.848016247999965, 35.307391899000038],
            [-80.84847924799999, 35.307330898000032],
            [-80.848670246999973, 35.306500898000024],
            [-80.849202247999983, 35.30416389800007],
            [-80.849458247999962, 35.303117898000039],
            [-80.849606247999986, 35.302511898000034],
            [-80.849711247999949, 35.302037897000048],
            [-80.850058247999982, 35.300472896000031],
            [-80.85020624699996, 35.299827897000057],
            [-80.850289246999978, 35.299484896000024],
            [-80.850323246999949, 35.299262896000073],
            [-80.850406247999956, 35.298886897000045],
            [-80.851042248999988, 35.296123895000051],
            [-80.851208247999978, 35.295363895000037],
            [-80.85130124799997, 35.29486989600008],
            [-80.851359247999937, 35.294467895000025],
            [-80.851404247999938, 35.294064895000076],
            [-80.85143624799997, 35.293618895000066],
            [-80.851462247999962, 35.293026895000025],
            [-80.851460247999967, 35.292502895000041],
            [-80.851450247999935, 35.292168895000032],
            [-80.851436246999981, 35.291991895000024],
            [-80.851400247999948, 35.291530895000051],
            [-80.851372247999961, 35.291281895000054],
            [-80.851318246999938, 35.290872895000064],
            [-80.851274247999982, 35.290583895000054],
            [-80.851201246999949, 35.290156894000063],
            [-80.851132247999942, 35.289861895000058],
            [-80.850943247999965, 35.289134895000075],
            [-80.850848247999977, 35.288817894000033],
            [-80.850744247999955, 35.288505895000071],
            [-80.850586246999967, 35.288027894000038],
            [-80.850450246999969, 35.287687894000044],
            [-80.85015324799997, 35.286990894000041],
            [-80.850395247999984, 35.286894894000056],
            [-80.850669246999985, 35.286819894000075],
            [-80.850784246999979, 35.286768893000044],
            [-80.850842247999935, 35.286746894000032],
            [-80.851041247999945, 35.286706894000076],
            [-80.851083246999963, 35.286656894000032],
            [-80.851668246999964, 35.286845893000077],
            [-80.851854247999938, 35.286912894000068],
            [-80.851943246999951, 35.286952894000024],
            [-80.852088246999983, 35.287026894000064],
            [-80.852238246999946, 35.287118894000059],
            [-80.852380248999964, 35.287228894000066],
            [-80.852507247999938, 35.287349894000045],
            [-80.852833247999968, 35.287730894000049],
            [-80.85293024799995, 35.287851894000028],
            [-80.853213247999975, 35.288194894000071],
            [-80.853272247999939, 35.288256894000028],
            [-80.853377248999948, 35.28835289400007],
            [-80.853496248999988, 35.28844289400007],
            [-80.853603247999956, 35.288517894000051],
            [-80.853792247999934, 35.288633894000043],
            [-80.854372248999937, 35.288975894000032],
            [-80.856100248999951, 35.289970894000078],
            [-80.856500249999954, 35.290185894000047],
            [-80.857074248999936, 35.290466895000066],
            [-80.857456248999938, 35.290644894000025],
            [-80.857612249999988, 35.290694894000069],
            [-80.857755248999979, 35.290262895000069],
            [-80.857882249999989, 35.28985089400004],
            [-80.857942249999951, 35.289600894000046],
            [-80.858191248999958, 35.288370894000025],
            [-80.858293249999974, 35.287976894000053],
            [-80.859515249999959, 35.288032893000036],
            [-80.860124249999956, 35.288065894000056],
            [-80.860490249999941, 35.28809089400005],
            [-80.86063625099996, 35.288108893000071],
            [-80.860709250999946, 35.288124894000077],
            [-80.860837249999975, 35.288164894000033],
            [-80.860897249999937, 35.288190893000035],
            [-80.861009249999938, 35.288254894000033],
            [-80.861911249999935, 35.288834894000047],
            [-80.862063250999938, 35.288920894000057],
            [-80.862303249999968, 35.289046893000034],
            [-80.862809250999987, 35.289274894000073],
            [-80.863100250999935, 35.289400894000039],
            [-80.86327025099996, 35.289461893000066],
            [-80.863358250999966, 35.289483894000057],
            [-80.863437250999937, 35.289496893000035],
            [-80.863527250999937, 35.289503894000063],
            [-80.863623250999979, 35.289502894000066],
            [-80.863815251999938, 35.289490894000039],
            [-80.86497325199997, 35.289376893000053],
            [-80.865390251999941, 35.289335894000033],
            [-80.865598251999984, 35.28932089400007],
            [-80.865717251999968, 35.289317893000032],
            [-80.865889251999988, 35.289325894000058],
            [-80.866005251999979, 35.289338894000025],
            [-80.866106251999952, 35.289354894000041],
            [-80.866790252999976, 35.289494894000029],
            [-80.866806252999936, 35.28942289400004],
            [-80.866809251999939, 35.289305894000051],
            [-80.866812251999988, 35.289209894000066],
            [-80.86678625199994, 35.289121893000072],
            [-80.866763251999942, 35.288965893000068],
            [-80.866763251999942, 35.288718893000066],
            [-80.866767251999988, 35.288677893000056],
            [-80.866798251999967, 35.288570894000031],
            [-80.866805251999949, 35.288493894000055],
            [-80.866802250999967, 35.288357894000058],
            [-80.866789252999979, 35.288271894000047],
            [-80.866794251999977, 35.288226893000058],
            [-80.866870252999945, 35.288042893000068],
            [-80.866850252999939, 35.287907894000057],
            [-80.86656125199994, 35.287564893000024],
            [-80.866593251999973, 35.287297893000073],
            [-80.866390250999984, 35.287169894000044],
            [-80.866475251999987, 35.287102893000053],
            [-80.866644250999968, 35.286905893000039],
            [-80.86674125199994, 35.286818894000078],
            [-80.866806251999947, 35.286727893000034],
            [-80.866812251999988, 35.286659894000024],
            [-80.866881251999985, 35.286627893000059],
            [-80.866934252999954, 35.286578894000058],
            [-80.867057251999938, 35.286537894000048],
            [-80.867113251999967, 35.286479894000024],
            [-80.867179251999971, 35.28647389300005],
            [-80.867274251999959, 35.286378893000062],
            [-80.867307251999989, 35.286353893000069],
            [-80.867468251999981, 35.286284893000072],
            [-80.867712252999979, 35.286065893000057],
            [-80.867756251999936, 35.285998893000055],
            [-80.867809251999972, 35.285935893000044],
            [-80.867938251999988, 35.28572689300006],
            [-80.86802325299999, 35.285621893000041],
            [-80.868083252999952, 35.285538892000034],
            [-80.868201252999938, 35.285377893000032],
            [-80.868231252999976, 35.285327892000055],
            [-80.868239252999956, 35.28528089200006],
            [-80.868231251999987, 35.285169892000056],
            [-80.868199252999943, 35.285113893000073],
            [-80.868173252999952, 35.285042893000025],
            [-80.868127251999965, 35.284971892000044],
            [-80.868054252999968, 35.28493089300008],
            [-80.868010251999976, 35.284879893000038],
            [-80.868010251999976, 35.284820893000074],
            [-80.868019251999954, 35.28479989300007],
            [-80.868045252999934, 35.284740893000048],
            [-80.868048251999937, 35.28461989300007],
            [-80.868095251999989, 35.284483893000072],
            [-80.86812125199998, 35.284411892000037],
            [-80.86816625299997, 35.284331893000058],
            [-80.868168252999965, 35.284284893000063],
            [-80.868176251999955, 35.284235893000073],
            [-80.868274251999935, 35.284051892000036],
            [-80.868342251999934, 35.283988893000071],
            [-80.86839125299997, 35.283903893000058],
            [-80.868412251999985, 35.28382489300003],
            [-80.86839125299997, 35.283724892000066],
            [-80.868371251999974, 35.283630893000065],
            [-80.868409252999982, 35.283563892000075],
            [-80.868482252999968, 35.283354892000034],
            [-80.86855725199996, 35.283289893000074],
            [-80.868671252999945, 35.283238893000032],
            [-80.868891252999958, 35.283153893000076],
            [-80.868875251999953, 35.283089892000078],
            [-80.868862252999975, 35.283076892000054],
            [-80.868776251999975, 35.282985892000056],
            [-80.868716251999956, 35.282874893000042],
            [-80.868731251999975, 35.282794892000027],
            [-80.868649252999944, 35.282762892000051],
            [-80.868647252999949, 35.282761893000043],
            [-80.868524251999986, 35.282700893000026],
            [-80.868450251999946, 35.282701893000024],
            [-80.868406252999989, 35.282690892000062],
            [-80.868344251999986, 35.282622893000052],
            [-80.868311251999955, 35.282548892000079],
            [-80.86831325299994, 35.282512892000057],
            [-80.868346251999981, 35.282472891000054],
            [-80.868372251999972, 35.282422892000056],
            [-80.868352251999966, 35.282356892000053],
            [-80.868168251999975, 35.282238891000077],
            [-80.868117251999934, 35.282186892000027],
            [-80.868194251999967, 35.281925892000061],
            [-80.868128251999963, 35.28189589200008],
            [-80.867874251999979, 35.281611892000058],
            [-80.867663251999943, 35.281218892000027],
            [-80.867632252999954, 35.28119789200008],
            [-80.867595251999944, 35.281157892000067],
            [-80.867585251999969, 35.281096892000051],
            [-80.86761525199995, 35.280964892000043],
            [-80.867623250999941, 35.280895892000046],
            [-80.867628251999975, 35.280735892000052],
            [-80.86757725199999, 35.280514892000042],
            [-80.867587251999964, 35.280304892000061],
            [-80.867556251999986, 35.280213892000063],
            [-80.86757425199994, 35.280017891000057],
            [-80.867726251999954, 35.279691892000073],
            [-80.867764251999972, 35.279647892000071],
            [-80.868045251999945, 35.279432891000056],
            [-80.868171251999968, 35.279328891000034],
            [-80.86821525199997, 35.279277891000049],
            [-80.868248251999944, 35.279174892000071],
            [-80.868324251999979, 35.279010891000041],
            [-80.868335252999941, 35.278930891000073],
            [-80.868316251999943, 35.27889089100006],
            [-80.868200252999941, 35.278826891000051],
            [-80.868168251999975, 35.278780892000043],
            [-80.868176251999955, 35.278583892000029],
            [-80.868195251999964, 35.278507891000061],
            [-80.868183252999984, 35.278365892000068],
            [-80.868025251999939, 35.278070891000027],
            [-80.868020251999951, 35.278037892000043],
            [-80.867978251999943, 35.277950891000046],
            [-80.867921251999974, 35.277890891000027],
            [-80.867784251999979, 35.277796891000037],
            [-80.867752252999935, 35.277753891000032],
            [-80.867715251999982, 35.277692892000061],
            [-80.867714252999974, 35.277646892000064],
            [-80.867684251999947, 35.277579891000073],
            [-80.86757025199995, 35.277389891000041],
            [-80.867482250999956, 35.277195891000076],
            [-80.86742325199998, 35.277121892000025],
            [-80.86734125199996, 35.27699989100006],
            [-80.86733325199998, 35.27693989100004],
            [-80.867322252999941, 35.276704891000065],
            [-80.86734125199996, 35.276664891000053],
            [-80.867359251999972, 35.276601891000041],
            [-80.867335251999975, 35.276541891000079],
            [-80.867263251999987, 35.276449890000038],
            [-80.86706725099998, 35.276171892000036],
            [-80.866996250999989, 35.276060891000043],
            [-80.867572251999945, 35.276118891000067],
            [-80.868473252999934, 35.276220891000037],
            [-80.86895425199998, 35.276286891000041],
            [-80.869127252999988, 35.276309891000039],
            [-80.869927251999968, 35.276388891000067],
            [-80.870179252999947, 35.276404892000073],
            [-80.870324252999978, 35.276417891000051],
            [-80.870472252999946, 35.276425892000077],
            [-80.870767251999951, 35.27642889100008],
            [-80.871060252999939, 35.276414890000069],
            [-80.871208251999974, 35.276397891000045],
            [-80.871385252999971, 35.276368890000072],
            [-80.871491252999988, 35.276344891000065],
            [-80.871590252999965, 35.276317891000076],
            [-80.871786252999982, 35.276250890000028],
            [-80.871947253999963, 35.276184891000071],
            [-80.872062253999957, 35.276131891000034],
            [-80.872284252999975, 35.276014891000045],
            [-80.872444253999959, 35.275911891000078],
            [-80.872618252999985, 35.275772891000031],
            [-80.872806252999965, 35.27559989100007],
            [-80.872873252999966, 35.275528891000079],
            [-80.872994252999945, 35.275380890000065],
            [-80.873142252999969, 35.275165890000039],
            [-80.873272253999971, 35.274941891000026],
            [-80.873329253999941, 35.27482789000004],
            [-80.873450252999987, 35.274620890000051],
            [-80.873583253999982, 35.274354890000041],
            [-80.873636252999972, 35.27424889100007],
            [-80.873799253999948, 35.273985891000052],
            [-80.874582253999961, 35.272583891000068],
            [-80.874620253999979, 35.272480890000054],
            [-80.874810253999954, 35.272153890000027],
            [-80.874970253999948, 35.271876890000044],
            [-80.875126253999952, 35.271627890000048],
            [-80.875433253999972, 35.27109088900005],
            [-80.875477253999975, 35.271002889000044],
            [-80.875672253999937, 35.270618889000048],
            [-80.875834253999983, 35.270326889000046],
            [-80.876123253999936, 35.269811890000028],
            [-80.876207253999951, 35.269652889000042],
            [-80.876313253999967, 35.269449889000043],
            [-80.876892253999983, 35.268405889000064],
            [-80.877022253999939, 35.268184889000054],
            [-80.877097253999978, 35.268080888000043],
            [-80.877175252999962, 35.268011889000036],
            [-80.877266254999938, 35.267960889000051],
            [-80.877406253999936, 35.267915889000051],
            [-80.877735254999948, 35.267830888000049],
            [-80.877535253999952, 35.267659888000026],
            [-80.876206253999953, 35.266053888000044],
            [-80.875988252999946, 35.266229888000055],
            [-80.875919253999939, 35.266191888000037],
            [-80.875897252999948, 35.266173888000026],
            [-80.875500253999974, 35.265610889000072],
            [-80.875449253999989, 35.265554889000043],
            [-80.875356253999939, 35.265478889000065],
            [-80.875572253999962, 35.26528588900004],
            [-80.87544225299996, 35.265134887000045],
            [-80.87529525399998, 35.264963888000068],
            [-80.874862253999936, 35.265366889000063],
            [-80.874514253999962, 35.265656888000024],
            [-80.874109253999961, 35.266010889000029],
            [-80.873833253999976, 35.266240888000027],
            [-80.873495252999987, 35.266499888000055],
            [-80.873274251999987, 35.266655889000049],
            [-80.872899252999957, 35.266901889000053],
            [-80.872675252999954, 35.267032888000074],
            [-80.87246625299997, 35.267144888000075],
            [-80.872261251999987, 35.26724488900004],
            [-80.872056252999982, 35.267351889000054],
            [-80.871798252999952, 35.267476888000033],
            [-80.871417251999958, 35.26764188900006],
            [-80.871036251999953, 35.267785889000038],
            [-80.870848251999973, 35.267850888000055],
            [-80.870682251999938, 35.267902889000027],
            [-80.870455251999942, 35.267973889000075],
            [-80.870229251999945, 35.268039889000079],
            [-80.870035252999969, 35.268090889000064],
            [-80.869967251999981, 35.267909888000077],
            [-80.86989625199999, 35.267722889000026],
            [-80.869833251999978, 35.267643890000045],
            [-80.869821251999952, 35.267605890000027],
            [-80.869812251999974, 35.267535889000044],
            [-80.869777251999949, 35.267446889000041],
            [-80.869760252999981, 35.267350889000056],
            [-80.86975825199994, 35.267275890000064],
            [-80.869780252999988, 35.267167888000074],
            [-80.869788251999978, 35.267019889000039],
            [-80.869783251999934, 35.266987889000063],
            [-80.869693251999934, 35.266783889000067],
            [-80.869669251999937, 35.266544889000045],
            [-80.86966825199994, 35.26641188800005],
            [-80.869658251999965, 35.266369889000032],
            [-80.869642252999938, 35.266327889000024],
            [-80.869631251999976, 35.266278889000034],
            [-80.869571251999957, 35.266187888000047],
            [-80.869555251999941, 35.26613788800006],
            [-80.869541251999976, 35.266049889000044],
            [-80.869486251999945, 35.265975888000071],
            [-80.869451252999966, 35.265916888000049],
            [-80.869434250999973, 35.265870888000052],
            [-80.869418251999946, 35.265759888000048],
            [-80.869381251999982, 35.265687889000048],
            [-80.869380251999985, 35.265593889000058],
            [-80.869371251999951, 35.265549888000066],
            [-80.869348251999952, 35.265502889000061],
            [-80.869326250999961, 35.265419889000043],
            [-80.869326250999961, 35.265374888000053],
            [-80.869318251999971, 35.265336889000025],
            [-80.869303251999952, 35.265309889000036],
            [-80.86928825199999, 35.265295889000072],
            [-80.869209251999962, 35.265201889000025],
            [-80.869112251999979, 35.265103889000045],
            [-80.869070251999972, 35.265036889000044],
            [-80.869037251999941, 35.264963889000057],
            [-80.869006250999973, 35.264942888000064],
            [-80.86897025199994, 35.26490988900008],
            [-80.868849250999972, 35.264853889000051],
            [-80.868796250999935, 35.264833889000045],
            [-80.868773250999936, 35.26483588800005],
            [-80.868721250999954, 35.264724889000036],
            [-80.868695251999952, 35.264319888000045],
            [-80.868715251999959, 35.264266889000055],
            [-80.868793250999943, 35.264115888000049],
            [-80.868824251999968, 35.263999889000047],
            [-80.868842251999979, 35.263932888000056],
            [-80.868852250999964, 35.263642888000049],
            [-80.868829250999966, 35.263558888000034],
            [-80.868817250999939, 35.263335889000075],
            [-80.868756250999979, 35.263098888000059],
            [-80.868694250999965, 35.262963888000058],
            [-80.868569251999986, 35.26274188800005],
            [-80.86850425199998, 35.262668888000064],
            [-80.868451251999943, 35.262533888000064],
            [-80.86837325099998, 35.262097888000028],
            [-80.868319251999935, 35.261962888000028],
            [-80.868250251999939, 35.261847888000034],
            [-80.868169250999983, 35.261569888000054],
            [-80.868046250999953, 35.261186888000054],
            [-80.868049250999945, 35.26104588700008],
            [-80.868050250999943, 35.261011888000041],
            [-80.868119250999939, 35.260678888000029],
            [-80.868163251999988, 35.260541888000034],
            [-80.868208249999952, 35.260394888000064],
            [-80.86821525199997, 35.260313888000042],
            [-80.868319250999946, 35.260008887000026],
            [-80.868348250999986, 35.25978088800008],
            [-80.868420249999986, 35.259674887000074],
            [-80.868453250999949, 35.259465887000033],
            [-80.868476250999947, 35.259316887000068],
            [-80.86850825099998, 35.259232887000053],
            [-80.868501250999941, 35.259134887000073],
            [-80.868493250999961, 35.258844888000056],
            [-80.868461249999939, 35.258785887000045],
            [-80.868409250999946, 35.258713887000056],
            [-80.86827925099999, 35.258645887000057],
            [-80.868079250999983, 35.25844988700004],
            [-80.868035250999981, 35.258418887000062],
            [-80.867906250999965, 35.258374887000059],
            [-80.867821249999963, 35.25835588700005],
            [-80.86772825099996, 35.258321887000079],
            [-80.867651250999984, 35.258277887000077],
            [-80.867500250999967, 35.25822088700005],
            [-80.867235250999954, 35.25817188700006],
            [-80.867029249999973, 35.258108888000038],
            [-80.866937249999978, 35.258062887000051],
            [-80.866817250999986, 35.258003887000029],
            [-80.866569250999987, 35.257948887000055],
            [-80.866259249999985, 35.25789388700008],
            [-80.865749249999965, 35.257768887000054],
            [-80.865650249999987, 35.257734887000026],
            [-80.865357249999988, 35.257610887000055],
            [-80.865140250999957, 35.257414887000039],
            [-80.865095249999968, 35.257306887000027],
            [-80.864995249999936, 35.257140886000059],
            [-80.864982250999958, 35.257099887000038],
            [-80.86496724899996, 35.257053887000041],
            [-80.864919249999957, 35.256784887000038],
            [-80.864965250999944, 35.256598887000052],
            [-80.864980249999974, 35.256488887000046],
            [-80.864973249999935, 35.256304887000056],
            [-80.86496724999995, 35.25617188700005],
            [-80.864965249999955, 35.256082887000048],
            [-80.864981250999961, 35.255884887000036],
            [-80.864997249999988, 35.255659886000046],
            [-80.865011249999952, 35.255436886000041],
            [-80.865032249999956, 35.255123887000025],
            [-80.865037249999943, 35.25472088600003],
            [-80.865072249999969, 35.254517886000031],
            [-80.865078249999954, 35.254220886000041],
            [-80.865069249999976, 35.253919887000052],
            [-80.865080249999949, 35.253700887000036],
            [-80.865095249999968, 35.253416886000025],
            [-80.865157249999982, 35.253073886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.86243124799995, 35.251880886000038],
            [-80.861236248999944, 35.251518886000042],
            [-80.859896247999984, 35.251112886000044],
            [-80.858236247999969, 35.25041288500006],
            [-80.85721224699995, 35.249820885000076],
            [-80.856508246999965, 35.249355886000046],
            [-80.856477247999976, 35.249634885000035],
            [-80.856421247999947, 35.250138885000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856288245999963, 35.251323887000069],
            [-80.85627724699998, 35.251412886000026],
            [-80.856207247999976, 35.252041886000029],
            [-80.856123246999971, 35.25280988600008],
            [-80.856099246999975, 35.253030886000033],
            [-80.856035246999966, 35.253668886000071],
            [-80.856011247999959, 35.253852886000061],
            [-80.855990246999966, 35.254017887000032],
            [-80.855873246999977, 35.254969887000073],
            [-80.85571424699998, 35.256165887000066],
            [-80.855603246999976, 35.257036887000027],
            [-80.855533246999983, 35.25761288700005],
            [-80.851164245999939, 35.257280888000025],
            [-80.849219245999961, 35.257130888000063],
            [-80.844800243999941, 35.256745888000069],
            [-80.844682243999955, 35.256810888000075],
            [-80.844417242999953, 35.256956887000058],
            [-80.843851242999961, 35.256908888000055],
            [-80.842000242999973, 35.256871888000035],
            [-80.84176824299999, 35.25686988800004],
            [-80.842172242999936, 35.25594288700006],
            [-80.842950242999962, 35.254226887000073],
            [-80.843135243999939, 35.253804887000058],
            [-80.843718242999955, 35.25252088600007],
            [-80.843752242999983, 35.252443887000027],
            [-80.843179243999941, 35.253172887000062],
            [-80.843106242999966, 35.253262886000073],
            [-80.842909243999941, 35.253056887000071],
            [-80.842890242999943, 35.253045887000042],
            [-80.842868243999987, 35.253035887000067],
            [-80.842837242999963, 35.253032887000074],
            [-80.842708243999937, 35.253039887000057],
            [-80.842584242999976, 35.253001887000039],
            [-80.842524242999957, 35.25297888700004],
            [-80.842464241999949, 35.252922888000057],
            [-80.842417242999943, 35.252776887000039],
            [-80.84235724399997, 35.252730887000041],
            [-80.842316242999971, 35.252706887000045],
            [-80.84209524299996, 35.252671887000076],
            [-80.841976241999987, 35.252582886000027],
            [-80.84196424299995, 35.252522886000065],
            [-80.841942242999949, 35.252505887000041],
            [-80.841726242999982, 35.252431887000057],
            [-80.841689242999962, 35.25243088700006],
            [-80.841651242999944, 35.252441887000032],
            [-80.841542242999935, 35.252509887000031],
            [-80.841513242999952, 35.252515887000072],
            [-80.841386242999988, 35.252484887000037],
            [-80.841271242999937, 35.25243088700006],
            [-80.841107242999954, 35.252354887000024],
            [-80.841025241999944, 35.252328887000033],
            [-80.840976242999943, 35.252304886000047],
            [-80.840896242999975, 35.252234887000043],
            [-80.840765241999975, 35.252153887000077],
            [-80.840735242999983, 35.252119887000049],
            [-80.840737241999989, 35.252077886000052],
            [-80.840746241999966, 35.252026887000056],
            [-80.840904242999954, 35.251842887000066],
            [-80.840912242999934, 35.251817887000072],
            [-80.840912242999934, 35.251799887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840861242999949, 35.251709887000061],
            [-80.840819242999942, 35.251696887000037],
            [-80.840728241999955, 35.25169988600004],
            [-80.840577241999938, 35.25173688700005],
            [-80.840539241999977, 35.251742887000034],
            [-80.84051124299998, 35.251735887000052],
            [-80.840284241999939, 35.251610886000037],
            [-80.840202241999975, 35.251569886000027],
            [-80.840011242999935, 35.251404887000035],
            [-80.839948241999934, 35.251377886000057],
            [-80.839798241999972, 35.251291886000047],
            [-80.83960824199994, 35.251191886000072],
            [-80.839568241999984, 35.251164887000073],
            [-80.839530242999956, 35.251145887000064],
            [-80.83941224199998, 35.251117886000031],
            [-80.839334242999939, 35.25108788700004],
            [-80.839271240999949, 35.251063887000043],
            [-80.839197242999944, 35.251012887000059],
            [-80.839146242999959, 35.250957887000027],
            [-80.839083242999948, 35.250900886000068],
            [-80.838688241999989, 35.250760887000069],
            [-80.838459241999942, 35.250722887000052],
            [-80.838398241999982, 35.250726886000052],
            [-80.83826024199999, 35.250779887000078],
            [-80.838234240999952, 35.250810887000057],
            [-80.838214241999935, 35.250844887000028],
            [-80.838207241999953, 35.250883887000043],
            [-80.838193240999942, 35.250902886000063],
            [-80.838158240999974, 35.250923887000056],
            [-80.838116241999955, 35.250933887000031],
            [-80.838111241999968, 35.250967886000069],
            [-80.838059241999986, 35.250979886000039],
            [-80.838017241999978, 35.250947886000063],
            [-80.837993241999982, 35.250786886000071],
            [-80.837964240999952, 35.250747886000056],
            [-80.837849241999947, 35.250714887000072],
            [-80.837624241999947, 35.25067088600008],
            [-80.837565240999936, 35.250641887000029],
            [-80.837534240999958, 35.250613887000043],
            [-80.837438240999973, 35.250445887000069],
            [-80.837375240999961, 35.250219886000025],
            [-80.83735724099995, 35.250086886000076],
            [-80.837355240999955, 35.250032886000042],
            [-80.837347241999964, 35.250006886000051],
            [-80.83733024199995, 35.249991886000032],
            [-80.83729624099999, 35.249977886000067],
            [-80.837069241999984, 35.249979887000052],
            [-80.837000241999988, 35.249976887000059],
            [-80.836825241999975, 35.249969887000077],
            [-80.836493240999971, 35.249861887000066],
            [-80.836440240999934, 35.249836887000072],
            [-80.836357240999973, 35.249764887000026],
            [-80.836235241999987, 35.249620887000049],
            [-80.836173240999983, 35.249530887000049],
            [-80.835993240999983, 35.249530886000059],
            [-80.835851240999943, 35.249538886000039],
            [-80.835869240999955, 35.250261886000033],
            [-80.835898240999938, 35.251096887000074],
            [-80.835909240999968, 35.251327887000059],
            [-80.835934240999961, 35.252153887000077],
            [-80.835966241999984, 35.253233887000079],
            [-80.835975240999971, 35.253517887000044],
            [-80.83599824199996, 35.254170887000043],
            [-80.836014240999987, 35.254684887000053],
            [-80.836017240999979, 35.255018887000062],
            [-80.836028239999962, 35.255357888000049],
            [-80.836031240999944, 35.255731888000071],
            [-80.836058240999989, 35.256368888000054],
            [-80.836077241999988, 35.256569888000058],
            [-80.836113241999954, 35.256884889000048],
            [-80.836151240999982, 35.257066888000054],
            [-80.836165240999946, 35.257132889000047],
            [-80.83564224099996, 35.25727488900003],
            [-80.835225240999989, 35.257382888000052],
            [-80.834986240999967, 35.257455887000049],
            [-80.834550240999988, 35.257463888000075],
            [-80.833150239999952, 35.257486889000063],
            [-80.832710239999983, 35.257490888000063],
            [-80.830825239999967, 35.257526888000029],
            [-80.829041239999981, 35.257557889000054],
            [-80.828213238999979, 35.257568888000037],
            [-80.828094237999949, 35.257554888000072],
            [-80.827989238999976, 35.257526889000076],
            [-80.82787123899999, 35.257475888000045],
            [-80.827816238999958, 35.257444888000066],
            [-80.827764238999976, 35.25740988900003],
            [-80.827193238999939, 35.256962889000079],
            [-80.826423237999961, 35.256360888000074],
            [-80.826317237999945, 35.256279888000051],
            [-80.825728238999943, 35.255793888000028],
            [-80.825577237999937, 35.255679888000031],
            [-80.825542237999969, 35.25565388900003],
            [-80.825369237999951, 35.255560888000048],
            [-80.824601236999968, 35.25519588800006],
            [-80.824251237999988, 35.255017888000054],
            [-80.824152236999964, 35.254965888000072],
            [-80.823278236999954, 35.254477888000054],
            [-80.823098236999954, 35.254382888000066],
            [-80.822443237999948, 35.254015888000026],
            [-80.821951235999961, 35.25375088800007],
            [-80.821747236999954, 35.253641888000061],
            [-80.820744235999939, 35.253133888000036],
            [-80.820698236999988, 35.253104887000063],
            [-80.820616236999967, 35.253038888000049],
            [-80.820580236999945, 35.252998887000047],
            [-80.820322235999981, 35.252680888000043],
            [-80.819446236999966, 35.251645887000052],
            [-80.819399235999981, 35.251599888000044],
            [-80.819359234999979, 35.251555888000041],
            [-80.819225235999966, 35.251390887000071],
            [-80.819101235999938, 35.251262887000053],
            [-80.819068235999964, 35.251239887000054],
            [-80.818978236999953, 35.251194887000054],
            [-80.818796235999969, 35.251048888000071],
            [-80.818523235999976, 35.25084688700008],
            [-80.818018235999943, 35.250461887000029],
            [-80.817826235999974, 35.250647887000071],
            [-80.817725235999944, 35.250860887000044],
            [-80.816908234999971, 35.251338888000078],
            [-80.816900235999981, 35.251355887000045],
            [-80.816885235999962, 35.251371887000062],
            [-80.81670623499997, 35.251475888000073],
            [-80.816385234999984, 35.251682888000062],
            [-80.816044234999936, 35.25187588700004],
            [-80.814760234999937, 35.252586888000053],
            [-80.814574233999963, 35.252683888000035],
            [-80.813609233999955, 35.251501888000064],
            [-80.813116233999949, 35.250908887000037],
            [-80.812976232999972, 35.250721888000044],
            [-80.812722233999978, 35.250834888000043],
            [-80.811786232999964, 35.251219888000037],
            [-80.811703233999935, 35.251257887000065],
            [-80.813732234999975, 35.253674888000035],
            [-80.814041234999934, 35.254043888000069],
            [-80.812714233999941, 35.254667888000029],
            [-80.810940233999986, 35.256222888000025],
            [-80.809988233999945, 35.25734888900007],
            [-80.809322232999989, 35.256752888000051],
            [-80.809298232999936, 35.256626889000074],
            [-80.809286233999956, 35.256589889000054],
            [-80.809283233999963, 35.25653188900003],
            [-80.809296232999941, 35.256448889000069],
            [-80.809328233999963, 35.256370888000049],
            [-80.809346233999975, 35.256300888000055],
            [-80.80936423299994, 35.256255889000045],
            [-80.809368232999987, 35.256132888000025],
            [-80.809411232999935, 35.255846889000054],
            [-80.809390232999988, 35.255646889000047],
            [-80.809371232999979, 35.255370888000073],
            [-80.809377233999953, 35.255175888000053],
            [-80.809395233999965, 35.255109889000039],
            [-80.809396233999962, 35.254971889000046],
            [-80.809405233999939, 35.254811888000063],
            [-80.809411232999935, 35.254674889000057],
            [-80.809356233999949, 35.254613889000041],
            [-80.809368232999987, 35.254484889000025],
            [-80.809516232999954, 35.25431388800007],
            [-80.809563232999949, 35.254225889000054],
            [-80.809605232999957, 35.254127889000074],
            [-80.809603232999962, 35.25407388900004],
            [-80.809586232999948, 35.254017889000067],
            [-80.809386233999987, 35.25379588800007],
            [-80.809348231999934, 35.253665888000057],
            [-80.80934823299998, 35.253577888000052],
            [-80.80945023299995, 35.253498888000024],
            [-80.809503233999976, 35.253472887000044],
            [-80.809692233999954, 35.253405888000032],
            [-80.809734232999972, 35.253386889000069],
            [-80.809812233999935, 35.253342888000077],
            [-80.80986223299999, 35.253279888000066],
            [-80.809865232999982, 35.253256889000056],
            [-80.809841232999986, 35.253181888000029],
            [-80.809832233999941, 35.253130888000044],
            [-80.809835233999934, 35.252996888000041],
            [-80.809884233999981, 35.252810888000056],
            [-80.809887233999973, 35.252697888000057],
            [-80.809883232999937, 35.252639888000033],
            [-80.809878233999939, 35.252480887000047],
            [-80.809884232999934, 35.252389888000039],
            [-80.809854233999943, 35.252150888000074],
            [-80.809818232999987, 35.252086888000065],
            [-80.808134232999976, 35.252817888000038],
            [-80.806454231999965, 35.25354788900006],
            [-80.806222232999971, 35.253657889000067],
            [-80.805931231999978, 35.253780888000051],
            [-80.805632231999937, 35.25388988800006],
            [-80.805328232999955, 35.253986889000032],
            [-80.805212232999963, 35.254019888000073],
            [-80.804808230999981, 35.25411888800005],
            [-80.80448923199998, 35.254179888000067],
            [-80.804275231999952, 35.25421288900003],
            [-80.803904231999979, 35.254276889000039],
            [-80.803190230999974, 35.254400889000067],
            [-80.802004230999955, 35.254605889000061],
            [-80.801055230999964, 35.254818889000035],
            [-80.80076023099997, 35.254908889000035],
            [-80.800487229999987, 35.255014888000062],
            [-80.800187229999949, 35.255154889000039],
            [-80.797969229999978, 35.256567889000053],
            [-80.797614229999965, 35.256772889000047],
            [-80.796552229999975, 35.257453889000033],
            [-80.796364228999948, 35.257592890000069],
            [-80.796147228999985, 35.257737889000055],
            [-80.795921229999976, 35.257872890000044],
            [-80.795687229999942, 35.257998889000078],
            [-80.795444228999941, 35.258113890000061],
            [-80.795195228999944, 35.25821889000008],
            [-80.794940228999963, 35.25831289000007],
            [-80.794768228999942, 35.258348890000036],
            [-80.795164229999955, 35.260706890000051],
            [-80.795179228999984, 35.26089789100007],
            [-80.795163229999957, 35.261404891000041],
            [-80.795147229999941, 35.261872891000053],
            [-80.795123229999945, 35.262356890000035],
            [-80.795083228999943, 35.263037891000067],
            [-80.795061228999941, 35.263513891000059],
            [-80.794990229999939, 35.264926891000073],
            [-80.794946228999947, 35.265762891000065],
            [-80.794896229999949, 35.266730892000055],
            [-80.794874228999959, 35.267168892000029],
            [-80.794834229999935, 35.26747189200006],
            [-80.794786229999943, 35.267692891000024],
            [-80.79466722899997, 35.268247892000034],
            [-80.794326229999967, 35.269861892000051],
            [-80.794087229999946, 35.27099189200004],
            [-80.793788228999972, 35.272438893000071],
            [-80.793525229999943, 35.273675894000064],
            [-80.79349222999997, 35.274038894000057],
            [-80.793491229999972, 35.274128893000068],
            [-80.793487229999982, 35.274525893000032],
            [-80.793495229999962, 35.275346894000052],
            [-80.793511230999968, 35.276558894000061],
            [-80.793511229999979, 35.276915894000069],
            [-80.793527229999938, 35.277978894000057],
            [-80.79352922999999, 35.278731894000032],
            [-80.793530229999988, 35.278933894000033],
            [-80.793531229999985, 35.279478895000068],
            [-80.793540229999962, 35.280777894000039],
            [-80.793546230999937, 35.28105789500006],
            [-80.793552229999989, 35.282143895000047],
            [-80.79358222999997, 35.284347895000053],
            [-80.793606229999966, 35.284676895000075],
            [-80.793613229999949, 35.28478089500004],
            [-80.793662230999985, 35.285056896000071],
            [-80.793731229999935, 35.285330896000062],
            [-80.793796230999988, 35.285532896000063],
            [-80.793849229999978, 35.285670895000067],
            [-80.793971230999944, 35.285944896000046],
            [-80.794056230999956, 35.286117896000064],
            [-80.794322230999967, 35.286661896000055],
            [-80.794510230999947, 35.287037896000072],
            [-80.794722229999934, 35.287514896000062],
            [-80.794809230999988, 35.287708896000026],
            [-80.795913230999986, 35.29046089600007],
            [-80.796380230999944, 35.291642897000031],
            [-80.796585231999984, 35.292145896000079],
            [-80.79680123199995, 35.292762897000046],
            [-80.797156231999963, 35.29366189700005],
            [-80.797560231999967, 35.294716898000047],
            [-80.797782231999975, 35.295235898000044],
            [-80.797880231999955, 35.295453898000062],
            [-80.797989231999964, 35.295644898000035],
            [-80.79809523299997, 35.295821897000053],
            [-80.798322232999965, 35.296145898000077],
            [-80.798511231999953, 35.296419897000078],
            [-80.798686231999966, 35.296675898000046],
            [-80.798764232999986, 35.296779898000068],
            [-80.798892231999957, 35.296939898000062],
            [-80.798945231999937, 35.296913898000071],
            [-80.799011232999987, 35.29688789800008],
            [-80.799091232999956, 35.296854898000049],
            [-80.799053231999949, 35.296806897000067],
            [-80.798936232999949, 35.296651897000061],
            [-80.798777232999953, 35.296425897000063],
            [-80.798720231999937, 35.296337898000047],
            [-80.79865423199999, 35.296226897000054],
            [-80.79860923199999, 35.296135897000056],
            [-80.798585231999937, 35.296069898000042],
            [-80.798578231999954, 35.295994897000071],
            [-80.798591231999978, 35.295910898000045],
            [-80.798602231999951, 35.295877898000072],
            [-80.798630232999983, 35.295839898000054],
            [-80.798685231999968, 35.295801898000036],
            [-80.798763231999942, 35.295767898000065],
            [-80.798851232999937, 35.295738897000035],
            [-80.800308232999953, 35.295325897000055],
            [-80.80021923299995, 35.295112897000024],
            [-80.80018523199999, 35.295002898000064],
            [-80.800150232999954, 35.294835897000041],
            [-80.800127231999966, 35.29467089700006],
            [-80.80012123299997, 35.294529897000075],
            [-80.800142233999964, 35.294341898000027],
            [-80.80016223299998, 35.294227897000042],
            [-80.800159232999988, 35.294132898000043],
            [-80.80014823199997, 35.294060897000065],
            [-80.800131232999945, 35.293988897000077],
            [-80.800062232999949, 35.293842897000047],
            [-80.799991232999957, 35.293748897000057],
            [-80.800572231999979, 35.293490896000037],
            [-80.800803232999954, 35.293393897000044],
            [-80.800893232999954, 35.293354896000039],
            [-80.801039232999983, 35.293305897000039],
            [-80.80114923299999, 35.293280897000045],
            [-80.801277232999951, 35.293260897000039],
            [-80.801553232999936, 35.293238897000037],
            [-80.801804232999984, 35.293210898000041],
            [-80.80198223299999, 35.29319689700003],
            [-80.803008233999947, 35.293154897000079],
            [-80.803857232999974, 35.29313589800006],
            [-80.804604234999942, 35.293107897000027],
            [-80.80508223399994, 35.293069897000066],
            [-80.80510723499998, 35.292979897000066],
            [-80.805273233999969, 35.292778897000062],
            [-80.805865233999953, 35.292433897000024],
            [-80.80581723499995, 35.292381896000052],
            [-80.806126233999976, 35.292203897000036],
            [-80.806137233999948, 35.292215897000062],
            [-80.806639233999988, 35.292739897000047],
            [-80.807695234999983, 35.293815897000059],
            [-80.808090234999952, 35.293555897000033],
            [-80.80827523399995, 35.293425897000077],
            [-80.808383234999951, 35.293332897000028],
            [-80.808527234999985, 35.293187896000063],
            [-80.808783234999964, 35.292902897000033],
            [-80.808931235999978, 35.292725897000025],
            [-80.809113235999973, 35.293121897000049],
            [-80.80950923499995, 35.293936897000037],
            [-80.809574235999946, 35.294061897000063],
            [-80.809604235999984, 35.294101896000029],
            [-80.810329234999983, 35.295052896000072],
            [-80.810565235999945, 35.295403897000028],
            [-80.810679235999942, 35.29555689700004],
            [-80.810998235999989, 35.295984898000029],
            [-80.811415235999959, 35.29654989800008],
            [-80.811476235999976, 35.296612897000045],
            [-80.811426235999988, 35.296685897000032],
            [-80.811387235999973, 35.296773898000026],
            [-80.811333236999985, 35.296965897000064],
            [-80.811292235999986, 35.297203897000031],
            [-80.811266235999938, 35.297512897000047],
            [-80.811262235999948, 35.29773689700005],
            [-80.811277235999967, 35.297873898000034],
            [-80.811300235999965, 35.297946898000077],
            [-80.811336235999988, 35.298025898000049],
            [-80.811428235999983, 35.298144898000032],
            [-80.811547235999967, 35.298084897000024],
            [-80.811699236999971, 35.297997897000073],
            [-80.812005236999937, 35.297806897000044],
            [-80.812363236999943, 35.297590897000077],
            [-80.812575236999976, 35.297455897000077],
            [-80.812728236999988, 35.297348898000052],
            [-80.812840235999943, 35.297262897000053],
            [-80.813025236999977, 35.297098897000069],
            [-80.813350235999962, 35.296757897000077],
            [-80.813540236999984, 35.296546898000031],
            [-80.814588236999953, 35.295408897000073],
            [-80.814841236999939, 35.295131896000044],
            [-80.814842237999983, 35.295131897000033],
            [-80.815505236999968, 35.294407896000052],
            [-80.816797237999936, 35.292993896000041],
            [-80.818259237999939, 35.291372896000041],
            [-80.819421237999961, 35.290067896000039],
            [-80.819785237999952, 35.289653895000072],
            [-80.819954238999969, 35.289466896000079],
            [-80.820091237999975, 35.289562896000064],
            [-80.820182237999973, 35.289642896000032],
            [-80.820255237999959, 35.289710896000031],
            [-80.820288237999989, 35.289744896000059],
            [-80.82032223899995, 35.289811895000071],
            [-80.820466237999938, 35.289992896000058],
            [-80.820521238999959, 35.290038895000066],
            [-80.820640238999943, 35.29013489600004],
            [-80.820656238999959, 35.290156895000052],
            [-80.820738238999979, 35.290213895000079],
            [-80.820823238999935, 35.290301895000027],
            [-80.820899237999981, 35.290404896000041],
            [-80.820926238999959, 35.29046889500006],
            [-80.821007238999982, 35.290565895000043],
            [-80.821072237999942, 35.290600896000058],
            [-80.821127237999974, 35.290643895000073],
            [-80.821177238999951, 35.290722895000044],
            [-80.821215238999969, 35.290820895000024],
            [-80.821296238999935, 35.290869896000061],
            [-80.821313238999949, 35.290986896000049],
            [-80.82131523999999, 35.291006896000056],
            [-80.821291238999947, 35.29117889500003],
            [-80.821276238999985, 35.291231896000056],
            [-80.821247238999945, 35.291386895000073],
            [-80.82119223899997, 35.291578897000079],
            [-80.821183238999936, 35.291633897000054],
            [-80.82119223899997, 35.291739896000024],
            [-80.82120023899995, 35.291768896000065],
            [-80.821258238999974, 35.291886896000051],
            [-80.82128223899997, 35.291925896000066],
            [-80.821338238999942, 35.291996896000057],
            [-80.821405238999944, 35.292021896000051],
            [-80.821888237999985, 35.291381897000065],
            [-80.821987238999952, 35.291318896000064],
            [-80.822103238999944, 35.291253896000057],
            [-80.822413239999946, 35.29110989600008],
            [-80.822642238999947, 35.291015896000033],
            [-80.822913238999945, 35.290904896000029],
            [-80.823361238999951, 35.290703895000036],
            [-80.824732239999946, 35.290088896000043],
            [-80.824957239999947, 35.289992896000058],
            [-80.825413240999978, 35.289798896000036],
            [-80.825929239999937, 35.28958689600006],
            [-80.826196240999934, 35.289480896000043],
            [-80.826889239999957, 35.289158895000071],
            [-80.827601240999968, 35.288836895000031],
            [-80.827716240999962, 35.288770895000027],
            [-80.827798239999936, 35.288733894000075],
            [-80.82812224099996, 35.288613895000026],
            [-80.828284239999959, 35.288571895000075],
            [-80.828428239999937, 35.288543895000032],
            [-80.828596239999968, 35.28852889500007],
            [-80.828805240999941, 35.288527895000072],
            [-80.828916240999945, 35.288521895000031],
            [-80.830113240999935, 35.288534895000055],
            [-80.83076024199994, 35.288548895000076],
            [-80.831239241999981, 35.288565895000033],
            [-80.831374241999981, 35.288576894000073],
            [-80.831566240999962, 35.288600894000069],
            [-80.831758241999978, 35.288641895000069],
            [-80.831852241999968, 35.288668895000058],
            [-80.832039241999951, 35.288733894000075],
            [-80.832363241999985, 35.288879895000036],
            [-80.832495241999936, 35.288947895000035],
            [-80.832649241999945, 35.289034895000043],
            [-80.83275224199997, 35.289099895000049],
            [-80.832814241999984, 35.289145894000058],
            [-80.832875241999943, 35.289198895000027],
            [-80.83321024199995, 35.289551895000045],
            [-80.833283241999936, 35.289614895000057],
            [-80.833391241999948, 35.289690895000035],
            [-80.833510242999978, 35.289747895000062],
            [-80.833633241999962, 35.289786894000031],
            [-80.833872242999973, 35.289840895000054],
            [-80.83399024299996, 35.289850895000029],
            [-80.834105242999954, 35.289843895000047],
            [-80.834183242999984, 35.289828895000028],
            [-80.834314242999938, 35.289788895000072],
            [-80.83518724299995, 35.289437895000049],
            [-80.835446241999989, 35.289323895000052],
            [-80.835778242999936, 35.289176895000026],
            [-80.836322242999984, 35.288950895000028],
            [-80.836474242999941, 35.288895895000053],
            [-80.836548242999982, 35.288877895000041],
            [-80.836658242999988, 35.288877895000041],
            [-80.836720242999945, 35.289095895000059],
            [-80.837017243999981, 35.290042895000056],
            [-80.837087242999985, 35.290238895000073],
            [-80.837187243999949, 35.290499895000039],
            [-80.837371243999939, 35.290950895000037],
            [-80.837646242999938, 35.291582895000033],
            [-80.837845243999936, 35.292037895000078],
            [-80.838223243999948, 35.292899896000051],
            [-80.838256242999989, 35.292988896000054],
            [-80.838361243999941, 35.292980896000074],
            [-80.838707243999977, 35.292984895000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 57,
        "SolutionID": "PI-20",
        "Shape_Length": 0.33892727997000716,
        "Shape_Area": 0.0028604946759830433
      }
    },
    {
      "type": "Feature",
      "id": 58,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.863758245999975, 35.195603874000028],
            [-80.864139246999969, 35.195939874000032],
            [-80.864330245999952, 35.196087874000057],
            [-80.864390245999971, 35.196133874000054],
            [-80.865206246999946, 35.196829874000059],
            [-80.866091246999986, 35.197566874000074],
            [-80.866452246999984, 35.197862874000066],
            [-80.86674624699998, 35.198111874000062],
            [-80.866854247999981, 35.198196874000075],
            [-80.866928246999976, 35.198263874000077],
            [-80.867124247999982, 35.198421874000076],
            [-80.867823247999979, 35.198904875000039],
            [-80.868077246999974, 35.198661874000038],
            [-80.868198247999942, 35.198555875000068],
            [-80.868483247999961, 35.198317874000054],
            [-80.868839247999972, 35.197989875000076],
            [-80.869762248999962, 35.197137874000077],
            [-80.869448247999969, 35.196901874000048],
            [-80.86923724799999, 35.196751874000029],
            [-80.86916724799994, 35.196685873000035],
            [-80.869105246999936, 35.196582874000057],
            [-80.86861924699997, 35.195708874000047],
            [-80.86902024799997, 35.195566874000065],
            [-80.869706247999943, 35.195310874000029],
            [-80.869777248999981, 35.195280873000058],
            [-80.869887247999941, 35.195218874000034],
            [-80.869927247999954, 35.195180874000073],
            [-80.870193247999964, 35.194849874000056],
            [-80.870669247999956, 35.194350873000076],
            [-80.871085248999975, 35.193968874000063],
            [-80.871145247999948, 35.193907873000057],
            [-80.871561247999978, 35.194179873000053],
            [-80.872551249999958, 35.194810874000041],
            [-80.872813248999989, 35.194897873000059],
            [-80.872859248999987, 35.194859873000041],
            [-80.872884248999981, 35.194826874000057],
            [-80.872918248999952, 35.194781874000057],
            [-80.872955248999972, 35.194726874000025],
            [-80.873074248999956, 35.194511873000067],
            [-80.873119248999956, 35.194474873000047],
            [-80.87317724899998, 35.194451873000048],
            [-80.873241247999943, 35.194444873000066],
            [-80.873304248999943, 35.194455873000038],
            [-80.87447224999994, 35.194872873000065],
            [-80.874775249999971, 35.194973873000038],
            [-80.875134249999974, 35.195098874000053],
            [-80.87548524999994, 35.195221873000037],
            [-80.876259248999986, 35.195511874000033],
            [-80.876885249999987, 35.195736873000044],
            [-80.877218249999942, 35.195867873000054],
            [-80.87760824999998, 35.196001873000057],
            [-80.879058250999947, 35.196533873000078],
            [-80.879330251999988, 35.196629873000063],
            [-80.880813251999939, 35.197154874000034],
            [-80.881473251999978, 35.197392874000059],
            [-80.881736251999939, 35.197488873000054],
            [-80.882634251999946, 35.197816873000079],
            [-80.882803251999974, 35.197877874000028],
            [-80.883000251999988, 35.197455873000024],
            [-80.883564251999985, 35.196246873000064],
            [-80.883865251999964, 35.195642873000054],
            [-80.883923251999988, 35.195528874000047],
            [-80.884033252999984, 35.195285872000056],
            [-80.884166251999943, 35.194994873000041],
            [-80.884588251999958, 35.194092872000056],
            [-80.885050251999985, 35.19311887200007],
            [-80.885273251999934, 35.19262787200006],
            [-80.885554252999952, 35.192034872000079],
            [-80.885711252999954, 35.191681872000061],
            [-80.885976251999978, 35.191113872000074],
            [-80.886987252999972, 35.188991871000042],
            [-80.88736325299999, 35.18818587100003],
            [-80.887627253999938, 35.187590872000044],
            [-80.88774325299994, 35.187294871000063],
            [-80.887847252999961, 35.186972870000034],
            [-80.887950252999985, 35.186586870000042],
            [-80.887993252999934, 35.186392871000066],
            [-80.888034252999944, 35.186166871000069],
            [-80.888085252999986, 35.185786871000062],
            [-80.888131252999983, 35.185211871000035],
            [-80.888131253999973, 35.185195871000076],
            [-80.888136252999971, 35.184867870000062],
            [-80.888109253999971, 35.184518870000034],
            [-80.888075252999954, 35.184300870000072],
            [-80.888018252999984, 35.183939871000064],
            [-80.887959251999973, 35.183627870000066],
            [-80.887921252999945, 35.183472871000049],
            [-80.887839252999981, 35.183190870000033],
            [-80.887804251999967, 35.183057871000074],
            [-80.887742252999942, 35.182825870000045],
            [-80.887659252999981, 35.182539870000028],
            [-80.887611251999942, 35.182363871000064],
            [-80.887607252999942, 35.182351870000048],
            [-80.887397252999961, 35.181717870000057],
            [-80.887141251999935, 35.180902870000068],
            [-80.886999252999942, 35.180471870000076],
            [-80.886863252999945, 35.180024870000068],
            [-80.886711251999941, 35.179521870000031],
            [-80.886573252999938, 35.179121870000074],
            [-80.886473251999973, 35.178814869000064],
            [-80.886372252999934, 35.178448870000068],
            [-80.886311251999985, 35.178165869000054],
            [-80.88625725199995, 35.177781869000057],
            [-80.886229251999964, 35.177461869000069],
            [-80.886220251999987, 35.177023869000038],
            [-80.886235251999949, 35.176663869000038],
            [-80.886265250999941, 35.176341870000044],
            [-80.886291251999978, 35.176150868000036],
            [-80.886355251999987, 35.175787869000033],
            [-80.886396252999987, 35.175602868000055],
            [-80.886493252999969, 35.175234868000075],
            [-80.886612251999964, 35.174822869000025],
            [-80.886487251999938, 35.174635868000053],
            [-80.886568251999961, 35.17435486800008],
            [-80.887264251999966, 35.17195786800005],
            [-80.887307251999971, 35.171809867000036],
            [-80.887468251999962, 35.171252868000067],
            [-80.888248251999983, 35.168562866000059],
            [-80.888361251999982, 35.168172867000067],
            [-80.88862525199994, 35.167210867000051],
            [-80.888766251999982, 35.166726867000079],
            [-80.888829251999937, 35.166508867000061],
            [-80.888975251999966, 35.166053866000027],
            [-80.889111250999974, 35.165564866000068],
            [-80.889339251999957, 35.164789866000035],
            [-80.889517251999962, 35.164143866000074],
            [-80.88970225199995, 35.163524866000046],
            [-80.889852251999969, 35.163052866000044],
            [-80.889716251999971, 35.163022866000063],
            [-80.889362252999945, 35.162944866000032],
            [-80.88903725199998, 35.162893866000047],
            [-80.888786251999989, 35.162879866000026],
            [-80.88867025199994, 35.162876866000033],
            [-80.888522251999973, 35.162878866000028],
            [-80.888356251999937, 35.162877866000031],
            [-80.88811025199999, 35.162909866000064],
            [-80.88785225099997, 35.162956866000059],
            [-80.887464251999972, 35.163040867000063],
            [-80.887361250999959, 35.163077866000037],
            [-80.887201251999954, 35.16311786600005],
            [-80.887073250999947, 35.16315486600007],
            [-80.886868250999953, 35.162642866000056],
            [-80.88685725199997, 35.162582865000047],
            [-80.886867251999945, 35.162521866000077],
            [-80.886880251999969, 35.162492867000026],
            [-80.886920251999982, 35.162440865000065],
            [-80.886946250999983, 35.162418866000053],
            [-80.887276251999936, 35.162188865000076],
            [-80.887372251999977, 35.162117866000074],
            [-80.88751825099996, 35.161994866000043],
            [-80.887541251999949, 35.161969867000039],
            [-80.887613251999937, 35.161889866000024],
            [-80.887690250999981, 35.16177486600003],
            [-80.887888251999982, 35.161418866000076],
            [-80.888088251999989, 35.16112486600008],
            [-80.888133251999989, 35.161047866000047],
            [-80.888173251999945, 35.160968866000076],
            [-80.88820825199997, 35.160887865000063],
            [-80.888238250999962, 35.160803865000048],
            [-80.888258251999957, 35.160730866000051],
            [-80.888280251999959, 35.160582865000038],
            [-80.888282251999954, 35.160432865000075],
            [-80.888253250999981, 35.160004865000076],
            [-80.888260250999963, 35.159854866000046],
            [-80.888288250999949, 35.159706865000032],
            [-80.888322251999966, 35.15960186500007],
            [-80.888160251999977, 35.159571866000078],
            [-80.887949251999942, 35.159547866000025],
            [-80.887842250999938, 35.159541865000051],
            [-80.886292250999986, 35.159543865000046],
            [-80.886668251999936, 35.157767865000039],
            [-80.886723251999967, 35.157553865000068],
            [-80.886746250999977, 35.157484865000072],
            [-80.88677525099996, 35.157417864000024],
            [-80.886809250999988, 35.157352865000064],
            [-80.886892250999949, 35.157227865000038],
            [-80.887016250999977, 35.157074865000027],
            [-80.88733425099997, 35.156644865000032],
            [-80.887690250999981, 35.156233865000047],
            [-80.888241250999954, 35.155548864000025],
            [-80.888324251999961, 35.155471864000049],
            [-80.888371251999956, 35.155437865000067],
            [-80.888450250999938, 35.155392864000078],
            [-80.888512250999952, 35.155364864000035],
            [-80.888643250999962, 35.15532186400003],
            [-80.888699251999981, 35.155308864000062],
            [-80.888958251999952, 35.15526886400005],
            [-80.889476251999952, 35.15522786400004],
            [-80.890254251999977, 35.155103865000058],
            [-80.890222251999944, 35.154984864000028],
            [-80.890240251999955, 35.154871864000029],
            [-80.890230251999981, 35.154770864000056],
            [-80.890199251999945, 35.15455886500007],
            [-80.890181251999934, 35.154466863000039],
            [-80.890146251999965, 35.154399865000073],
            [-80.890129251999952, 35.154283864000035],
            [-80.890120251999974, 35.154143864000048],
            [-80.890122252999959, 35.154069865000054],
            [-80.89013625299998, 35.153996864000078],
            [-80.890162251999982, 35.153950864000024],
            [-80.890166250999982, 35.15390986400007],
            [-80.890178251999941, 35.15388086400003],
            [-80.890198251999948, 35.153784864000045],
            [-80.890230251999981, 35.153675864000036],
            [-80.890232251999976, 35.153632864000031],
            [-80.890275251999981, 35.153481864000071],
            [-80.890290251999943, 35.153368865000061],
            [-80.890312251999944, 35.153274864000025],
            [-80.890356251999947, 35.153178863000051],
            [-80.890362251999989, 35.153141864000077],
            [-80.890330251999956, 35.153069863000042],
            [-80.890379250999956, 35.152983863000031],
            [-80.890394251999965, 35.152824863000035],
            [-80.889219250999986, 35.153000864000035],
            [-80.889162251999949, 35.152589864000049],
            [-80.88908225199998, 35.152070863000063],
            [-80.889018250999982, 35.151643863000061],
            [-80.889019250999979, 35.151503863000073],
            [-80.889060250999989, 35.151313864000031],
            [-80.889117251999949, 35.15101786300005],
            [-80.88912725199998, 35.150902863000056],
            [-80.889125251999985, 35.150799863000032],
            [-80.889096250999955, 35.150688864000074],
            [-80.889050251999947, 35.150567863000049],
            [-80.888973250999982, 35.150439863000031],
            [-80.888824251999949, 35.150276863000045],
            [-80.888718250999943, 35.150163863000046],
            [-80.888672250999946, 35.150085863000072],
            [-80.88864425099996, 35.150000863000059],
            [-80.888627250999946, 35.149856864000071],
            [-80.888626250999948, 35.149792863000073],
            [-80.888602250999952, 35.149685863000059],
            [-80.888529250999966, 35.149542863000079],
            [-80.888488250999956, 35.149487863000047],
            [-80.888556250999955, 35.149372863000053],
            [-80.88860325099995, 35.149324863000061],
            [-80.888633250999987, 35.149256862000072],
            [-80.888774251999962, 35.14901386300005],
            [-80.888855251999985, 35.148893863000069],
            [-80.888888250999969, 35.148847863000071],
            [-80.888991251999983, 35.14875186200004],
            [-80.88900825099995, 35.148700863000045],
            [-80.889030250999951, 35.148673862000066],
            [-80.889087251999968, 35.14864986300006],
            [-80.889138250999963, 35.14864986300006],
            [-80.889176250999981, 35.148618863000024],
            [-80.889233250999951, 35.148544863000041],
            [-80.889247250999972, 35.148497862000056],
            [-80.889250250999964, 35.148348863000024],
            [-80.88923325199994, 35.148232862000043],
            [-80.88913925199995, 35.148064863000059],
            [-80.889114250999967, 35.148009862000038],
            [-80.889080250999939, 35.147909863000052],
            [-80.889005250999958, 35.14756886300006],
            [-80.888966250999943, 35.147328862000052],
            [-80.888883250999982, 35.147011863000046],
            [-80.888873251999939, 35.146951863000027],
            [-80.888780250999957, 35.146685863000073],
            [-80.888740249999955, 35.146540862000052],
            [-80.888708250999969, 35.146421863000057],
            [-80.888638250999975, 35.14618786300008],
            [-80.888612250999984, 35.146084862000066],
            [-80.888594250999972, 35.145906862000061],
            [-80.888575250999963, 35.145835862000069],
            [-80.888543251999977, 35.145757862000039],
            [-80.888517250999939, 35.145655862000069],
            [-80.888476250999986, 35.14555886200003],
            [-80.888440250999963, 35.145451863000062],
            [-80.88841125099998, 35.14531786200007],
            [-80.888408250999987, 35.145246862000079],
            [-80.888417250999964, 35.145218863000025],
            [-80.888480250999976, 35.145164862000058],
            [-80.888507249999975, 35.145120863000045],
            [-80.888516250999942, 35.145089862000077],
            [-80.888473250999937, 35.144948862000035],
            [-80.888468250999949, 35.144666862000065],
            [-80.888551250999967, 35.144370862000073],
            [-80.888609250999934, 35.143893862000027],
            [-80.888641250999967, 35.143722861000072],
            [-80.888637250999977, 35.143661861000055],
            [-80.888641251999957, 35.143588862000058],
            [-80.888662250999971, 35.143426862000069],
            [-80.88873425099996, 35.143220861000032],
            [-80.888747250999984, 35.143119861000059],
            [-80.888678250999988, 35.143013862000032],
            [-80.888590249999936, 35.142903862000026],
            [-80.888557250999952, 35.142760862000046],
            [-80.88854624999999, 35.142712862000053],
            [-80.888528249999979, 35.14235786100005],
            [-80.888498250999987, 35.142253862000075],
            [-80.888441250999961, 35.142043861000047],
            [-80.888381250999942, 35.141901862000054],
            [-80.888246250999941, 35.141654861000063],
            [-80.888332249999962, 35.141656862000048],
            [-80.888366250999979, 35.141664862000027],
            [-80.888436249999984, 35.141688862000024],
            [-80.888487250999958, 35.14171286100003],
            [-80.888523250999981, 35.141734861000032],
            [-80.888571250999973, 35.141763861000072],
            [-80.888674250999941, 35.141844861000038],
            [-80.888741250999942, 35.141879862000053],
            [-80.888818250999975, 35.141897861000075],
            [-80.888897250999946, 35.14189986100007],
            [-80.889074250999954, 35.141872861000024],
            [-80.889261250999937, 35.141833861000066],
            [-80.889496251999958, 35.141772862000039],
            [-80.889670250999984, 35.141716861000077],
            [-80.889753250999945, 35.141682862000039],
            [-80.889812250999967, 35.141653861000066],
            [-80.889922250999973, 35.141584861000069],
            [-80.889971250999963, 35.141545862000044],
            [-80.890106250999963, 35.141414862000033],
            [-80.890230251999981, 35.141293861000065],
            [-80.890419250999969, 35.141125861000035],
            [-80.890695250999954, 35.140921861000038],
            [-80.891022250999981, 35.140703861000077],
            [-80.891122250999956, 35.14065486100003],
            [-80.891245251999976, 35.140615862000061],
            [-80.891388251999956, 35.140588861000026],
            [-80.891582251999978, 35.140572861000066],
            [-80.89177725199994, 35.140572861000066],
            [-80.892375250999976, 35.140620861000059],
            [-80.892765251999947, 35.140643861000058],
            [-80.892870251999966, 35.14063886100007],
            [-80.894181251999953, 35.140363861000026],
            [-80.89380625299998, 35.139523861000043],
            [-80.89377325199996, 35.139438861000031],
            [-80.893691252999986, 35.139273861000049],
            [-80.893643251999947, 35.139193860000034],
            [-80.893533251999941, 35.139040861000069],
            [-80.893512251999937, 35.139016861000073],
            [-80.89340525099999, 35.138896861000035],
            [-80.892886251999983, 35.138426861000028],
            [-80.892597251999973, 35.138129861000039],
            [-80.892548250999937, 35.138072861000069],
            [-80.892465251999965, 35.13796786000006],
            [-80.892405250999957, 35.137883861000034],
            [-80.892350251999972, 35.137797860000035],
            [-80.892300250999938, 35.137709861000076],
            [-80.892179250999959, 35.137466860000075],
            [-80.892149251999967, 35.137402860000066],
            [-80.892121250999935, 35.137339861000044],
            [-80.892104251999967, 35.137287860000072],
            [-80.892049251999936, 35.137151860000074],
            [-80.891966251999975, 35.13689086100004],
            [-80.891916251999987, 35.136636860000067],
            [-80.891893251999988, 35.136405859000035],
            [-80.891863250999961, 35.135857861000034],
            [-80.891851251999981, 35.135546860000034],
            [-80.891828250999936, 35.135153861000049],
            [-80.891823250999948, 35.134585860000072],
            [-80.891811251999968, 35.134180860000072],
            [-80.891773250999961, 35.133494860000042],
            [-80.891773250999961, 35.133160859000043],
            [-80.891765249999935, 35.132752860000039],
            [-80.891754250999952, 35.132499860000053],
            [-80.89174725099997, 35.132079859000044],
            [-80.891748250999967, 35.131750859000078],
            [-80.891752251999947, 35.131647859000054],
            [-80.891763250999986, 35.131387860000075],
            [-80.891778250999948, 35.131200859000046],
            [-80.89178825099998, 35.131146859000069],
            [-80.891842250999957, 35.130928859000051],
            [-80.892203251999945, 35.129983859000049],
            [-80.892499251999936, 35.129242859000044],
            [-80.892621249999934, 35.128969859000051],
            [-80.892733251999971, 35.128721858000063],
            [-80.892782250999971, 35.128623858000026],
            [-80.892896251999957, 35.128432858000053],
            [-80.893072250999978, 35.128191859000026],
            [-80.893173250999951, 35.128083858000025],
            [-80.893284250999955, 35.12799185800003],
            [-80.89342125099995, 35.127902858000027],
            [-80.893721250999988, 35.127730858000064],
            [-80.893810250999934, 35.12768685900005],
            [-80.893996251999965, 35.127610858000025],
            [-80.894179251999958, 35.127552858000058],
            [-80.894410251999943, 35.127497858000027],
            [-80.894646251999973, 35.127459858000066],
            [-80.894988251999962, 35.127415858000063],
            [-80.895381251999936, 35.127354858000047],
            [-80.895692251999947, 35.127295859000071],
            [-80.895965251999939, 35.127228858000024],
            [-80.896185252999942, 35.127160858000025],
            [-80.896446251999976, 35.12706085800005],
            [-80.897311251999952, 35.126675857000066],
            [-80.898427252999966, 35.12617485800007],
            [-80.899603252999952, 35.125653857000032],
            [-80.899699252999937, 35.125602858000036],
            [-80.899817252999981, 35.125528857000063],
            [-80.899918252999953, 35.125455858000066],
            [-80.900008253999943, 35.125378858000033],
            [-80.900101252999946, 35.125287857000046],
            [-80.900213252999947, 35.125169858000049],
            [-80.90098425399998, 35.124253858000031],
            [-80.901361253999937, 35.123815857000068],
            [-80.901607253999941, 35.123536856000044],
            [-80.901846253999963, 35.123235858000044],
            [-80.90195625399997, 35.123090857000079],
            [-80.902182253999968, 35.122763857000052],
            [-80.90232025399996, 35.122536857000057],
            [-80.902486253999939, 35.122234856000034],
            [-80.902668253999934, 35.121853857000076],
            [-80.902722253999968, 35.121738857000025],
            [-80.902875254999969, 35.121439857000041],
            [-80.903151253999965, 35.120889857000066],
            [-80.903710253999975, 35.119740856000078],
            [-80.903964253999959, 35.119290856000077],
            [-80.904172253999945, 35.118976856000074],
            [-80.90432425299997, 35.118786856000042],
            [-80.904456254999957, 35.11864885600005],
            [-80.903133253999954, 35.11729785600005],
            [-80.901687253999967, 35.115788856000052],
            [-80.901154253999948, 35.115245855000069],
            [-80.900568251999971, 35.114623855000048],
            [-80.898981251999942, 35.11300585500004],
            [-80.897564251999938, 35.111530855000069],
            [-80.896719250999979, 35.110666855000034],
            [-80.896162250999964, 35.110071854000068],
            [-80.89564625099996, 35.109545855000079],
            [-80.895911251999962, 35.109370855000066],
            [-80.896274250999966, 35.109132854000052],
            [-80.89652425099996, 35.10896785400007],
            [-80.896080250999944, 35.108459853000056],
            [-80.895928250999987, 35.108299854000052],
            [-80.895823250999968, 35.108189854000045],
            [-80.895800250999969, 35.108110854000074],
            [-80.895791250999935, 35.108077854000044],
            [-80.895847250999964, 35.107931855000061],
            [-80.896057249999956, 35.107710854000061],
            [-80.896158250999974, 35.107494854000038],
            [-80.896228250999968, 35.10734685400007],
            [-80.896298250999962, 35.107184854000025],
            [-80.896356251999975, 35.107049854000024],
            [-80.896532251999986, 35.106832853000071],
            [-80.89680225099994, 35.106643854000026],
            [-80.896938250999938, 35.106609853000066],
            [-80.897277251999981, 35.106504854000036],
            [-80.897299251999982, 35.106495853000069],
            [-80.897434251999982, 35.106428854000058],
            [-80.897667251999962, 35.106098854000038],
            [-80.897724251999989, 35.106009853000046],
            [-80.897801251999965, 35.105891854000049],
            [-80.897974251999983, 35.105622853000057],
            [-80.898053251999954, 35.105362853000031],
            [-80.898115251999968, 35.105160854000076],
            [-80.898045251999974, 35.10485985300005],
            [-80.897984251999958, 35.104764853000063],
            [-80.897923250999952, 35.104688853000027],
            [-80.897884251999983, 35.104475854000043],
            [-80.897989251999945, 35.104365853000047],
            [-80.898109251999983, 35.10426685300007],
            [-80.898391251999954, 35.104177853000067],
            [-80.898761252999975, 35.104034852000041],
            [-80.899034251999979, 35.103792853000073],
            [-80.899129251999966, 35.103634853000074],
            [-80.899160250999955, 35.103497853000079],
            [-80.899148251999975, 35.103396853000049],
            [-80.899202250999963, 35.103230853000071],
            [-80.899321251999936, 35.103063852000048],
            [-80.899476251999943, 35.10296085300007],
            [-80.899598251999976, 35.102970853000045],
            [-80.89970225199994, 35.102997853000034],
            [-80.899845251999977, 35.103059852000058],
            [-80.90003225199996, 35.103052853000065],
            [-80.900344250999979, 35.103011853000055],
            [-80.901030251999941, 35.10319385300005],
            [-80.901059252999971, 35.103193852000061],
            [-80.90109825199994, 35.103186853000068],
            [-80.901235251999935, 35.103162852000025],
            [-80.901378251999972, 35.103104853000048],
            [-80.901424252999959, 35.103086853000036],
            [-80.901677252999946, 35.103010853000058],
            [-80.901723252999943, 35.102947853000046],
            [-80.901786251999965, 35.102859853000041],
            [-80.901773251999941, 35.102698853000049],
            [-80.901755251999987, 35.102545853000038],
            [-80.901847252999971, 35.102298852000047],
            [-80.901943252999956, 35.102228852000053],
            [-80.902015251999956, 35.102130853000062],
            [-80.902002251999988, 35.102002853000045],
            [-80.901936252999974, 35.10190685200007],
            [-80.901975252999989, 35.101736852000045],
            [-80.901918252999963, 35.101455853000061],
            [-80.901902252999946, 35.101399852000043],
            [-80.901850251999974, 35.101316852000025],
            [-80.901757252999971, 35.101198852000039],
            [-80.901292252999951, 35.100838852000038],
            [-80.901155251999967, 35.100737852000066],
            [-80.901088251999965, 35.100689852000073],
            [-80.900948251999978, 35.100676853000039],
            [-80.900796251999964, 35.100755851000031],
            [-80.900637251999967, 35.100757852000072],
            [-80.900246251999988, 35.100790852000046],
            [-80.900012251999954, 35.100771853000026],
            [-80.899790251999946, 35.100847853000062],
            [-80.899552251999978, 35.10086685300007],
            [-80.899341251999942, 35.100839852000036],
            [-80.899268252999946, 35.10078885300004],
            [-80.899222251999959, 35.100707852000028],
            [-80.899235251999983, 35.100662852000028],
            [-80.899239251999973, 35.100641852000024],
            [-80.899245251999957, 35.100531852000074],
            [-80.899248252999939, 35.10051085200007],
            [-80.899347251999984, 35.100107853000054],
            [-80.899336250999966, 35.099896852000029],
            [-80.89918725199999, 35.099750852000057],
            [-80.898839251999959, 35.099675852000075],
            [-80.898511250999945, 35.09955085200005],
            [-80.898289250999937, 35.099285852000037],
            [-80.898233251999955, 35.099118852000061],
            [-80.898270250999985, 35.098743852000041],
            [-80.898344251999958, 35.09862785200005],
            [-80.898482250999962, 35.098577851000073],
            [-80.898844250999957, 35.098581852000052],
            [-80.899148251999975, 35.098609852000038],
            [-80.899316250999959, 35.098540852000042],
            [-80.899637251999934, 35.098300852000079],
            [-80.900006251999969, 35.098107852000055],
            [-80.900223251999989, 35.097902851000072],
            [-80.90035125199995, 35.097632852000061],
            [-80.900400252999987, 35.097468851000031],
            [-80.900554251999949, 35.097350852000034],
            [-80.901156252999954, 35.096585851000043],
            [-80.901245251999967, 35.096428851000042],
            [-80.90127025299995, 35.096246852000036],
            [-80.901265251999973, 35.09606885200003],
            [-80.901117251999949, 35.095946851000065],
            [-80.900776251999957, 35.095597851000036],
            [-80.90055025199996, 35.095427851000068],
            [-80.900481250999974, 35.095420851000029],
            [-80.900439251999956, 35.095415851000041],
            [-80.900021251999988, 35.095493851000072],
            [-80.899677251999947, 35.095508851000034],
            [-80.899209251999935, 35.095468851000078],
            [-80.899034251999979, 35.095360851000066],
            [-80.898807250999937, 35.095220850000032],
            [-80.898668249999957, 35.095094852000045],
            [-80.89831725099998, 35.09477785100006],
            [-80.898123249999969, 35.09465085100004],
            [-80.89804325099999, 35.094484851000061],
            [-80.898020251999981, 35.094384851000029],
            [-80.897978250999984, 35.094202852000024],
            [-80.897993250999946, 35.093857851000053],
            [-80.898015250999947, 35.093530850000036],
            [-80.89804325099999, 35.093232851000039],
            [-80.897994250999943, 35.093128851000074],
            [-80.897994250999943, 35.092864851000058],
            [-80.898030250999966, 35.09265785100007],
            [-80.898131250999938, 35.092507850000061],
            [-80.89819825099994, 35.092386851000072],
            [-80.898233250999965, 35.092112850000035],
            [-80.898357249999947, 35.09182885000007],
            [-80.898877250999988, 35.091225850000058],
            [-80.89916225099995, 35.090937851000035],
            [-80.899366251999936, 35.09066585100004],
            [-80.89794225199995, 35.089882850000038],
            [-80.897483250999983, 35.089638850000028],
            [-80.89696024999995, 35.089351850000071],
            [-80.896063249999941, 35.088842850000049],
            [-80.895648248999976, 35.088601850000032],
            [-80.89538024999996, 35.088454849000072],
            [-80.894879249999974, 35.088166849000061],
            [-80.894133248999935, 35.087761850000049],
            [-80.893753248999985, 35.087557849000063],
            [-80.893470248999961, 35.087418850000063],
            [-80.893246248999958, 35.087299849000033],
            [-80.892982248999942, 35.08714585000007],
            [-80.892859248999969, 35.087067850000039],
            [-80.892675248999979, 35.086933849000047],
            [-80.892476248999969, 35.086774849000051],
            [-80.892306248999944, 35.086633850000055],
            [-80.892084247999946, 35.086445849000029],
            [-80.89195824799998, 35.086339850000059],
            [-80.891506248999974, 35.085981849000063],
            [-80.891398247999973, 35.085901849000038],
            [-80.891360248999945, 35.085877849000042],
            [-80.891398248999963, 35.08584785000005],
            [-80.892214248999949, 35.085383850000028],
            [-80.893638248999935, 35.084759850000069],
            [-80.895041249999963, 35.084371849000036],
            [-80.895067249999954, 35.084363849000056],
            [-80.899971251999943, 35.082732849000024],
            [-80.900326250999967, 35.082615848000046],
            [-80.90133425199997, 35.082199848000073],
            [-80.902039250999962, 35.081671848000042],
            [-80.902599251999959, 35.081031848000066],
            [-80.903261251999936, 35.080210848000036],
            [-80.904391251999982, 35.078807848000054],
            [-80.904522251999936, 35.078621847000079],
            [-80.906068252999944, 35.07642684700005],
            [-80.905962251999938, 35.076308847000064],
            [-80.905646252999986, 35.075957847000041],
            [-80.905542251999975, 35.07584184600006],
            [-80.905541251999978, 35.075840847000052],
            [-80.90406625199995, 35.074200846000053],
            [-80.899642249999943, 35.069280846000026],
            [-80.898168249999969, 35.067640846000074],
            [-80.898166249999974, 35.067640846000074],
            [-80.898050248999937, 35.067663845000027],
            [-80.897910249999939, 35.067674846000045],
            [-80.897774248999951, 35.067673845000058],
            [-80.897637249999946, 35.067657846000031],
            [-80.897542248999969, 35.067621846000065],
            [-80.897385249999957, 35.067580846000055],
            [-80.89732424999994, 35.067559846000051],
            [-80.897113248999972, 35.067425846000049],
            [-80.897021249999966, 35.067386845000044],
            [-80.896913247999976, 35.067332845000067],
            [-80.896856248999939, 35.067295845000046],
            [-80.896744248999937, 35.067194845000074],
            [-80.896665248999966, 35.067074846000025],
            [-80.896646248999957, 35.066766844000028],
            [-80.896631248999938, 35.066607845000078],
            [-80.896589248999987, 35.066580845000033],
            [-80.896524248999981, 35.066556845000036],
            [-80.896444248999956, 35.066542845000072],
            [-80.896300248999978, 35.06652884600004],
            [-80.896213247999981, 35.066530845000045],
            [-80.896130247999963, 35.066546846000051],
            [-80.895923248999964, 35.066631844000028],
            [-80.895837249999943, 35.066679845000067],
            [-80.895737248999978, 35.066749845000061],
            [-80.895651248999968, 35.066828845000032],
            [-80.895606248999968, 35.066987845000028],
            [-80.895604248999973, 35.067027845000041],
            [-80.895627248999972, 35.067060845000071],
            [-80.895653248999963, 35.067088845000058],
            [-80.895969248999961, 35.067278845000033],
            [-80.896049248999987, 35.06732084600003],
            [-80.896173247999968, 35.067424845000062],
            [-80.896254248999981, 35.067470845000059],
            [-80.896367248999979, 35.067519845000049],
            [-80.896606248999944, 35.06778584500006],
            [-80.896754248999969, 35.067963845000065],
            [-80.896852247999959, 35.068111845000033],
            [-80.896961249999947, 35.068297845000075],
            [-80.897091248999971, 35.068497846000071],
            [-80.897170249999988, 35.068627846000027],
            [-80.897260248999942, 35.068759845000045],
            [-80.897344248999957, 35.068951845000072],
            [-80.897405249999963, 35.069112846000053],
            [-80.897432249999952, 35.069298846000038],
            [-80.897423248999985, 35.069426846000056],
            [-80.897417248999943, 35.069644846000074],
            [-80.897364249999953, 35.069963846000064],
            [-80.897285248999935, 35.070154846000037],
            [-80.897244249999972, 35.070303846000058],
            [-80.897265249999975, 35.070581846000039],
            [-80.897261248999939, 35.070628846000034],
            [-80.897217249999983, 35.070682846000068],
            [-80.897130248999986, 35.070702847000064],
            [-80.897055248999948, 35.070700846000079],
            [-80.896972249999976, 35.070698847000074],
            [-80.896880248999935, 35.07068184600007],
            [-80.896524248999981, 35.070668846000046],
            [-80.896320248999984, 35.070690846000048],
            [-80.895946248999962, 35.070745845000033],
            [-80.895603248999976, 35.070801845000062],
            [-80.89547024899997, 35.070818847000055],
            [-80.895346248999942, 35.070822846000056],
            [-80.895266248999974, 35.070825846000048],
            [-80.895125248999989, 35.070818846000066],
            [-80.894996248999973, 35.070788846000028],
            [-80.894813248999981, 35.070732846000055],
            [-80.894715248999944, 35.070749846000069],
            [-80.894450248999988, 35.070766846000026],
            [-80.894235248999962, 35.070740846000035],
            [-80.894099248999964, 35.070662846000062],
            [-80.894033248999961, 35.070620846000054],
            [-80.894006247999982, 35.070598846000053],
            [-80.893874247999975, 35.07042384600004],
            [-80.893721248999952, 35.07029684500003],
            [-80.893702248999944, 35.070229846000075],
            [-80.893674247999968, 35.070171846000051],
            [-80.893608247999964, 35.069973846000039],
            [-80.893586247999963, 35.069928845000049],
            [-80.893585248999955, 35.069730846000027],
            [-80.893499246999966, 35.069466846000068],
            [-80.893355247999978, 35.069151846000068],
            [-80.893291248999958, 35.06899084500003],
            [-80.893221248999964, 35.068922846000078],
            [-80.893141248999939, 35.068883846000062],
            [-80.893049246999965, 35.06884784600004],
            [-80.893003248999946, 35.068838845000073],
            [-80.892882247999978, 35.068837846000065],
            [-80.892678247999982, 35.068862846000059],
            [-80.892547247999971, 35.068920846000026],
            [-80.892392247999965, 35.068957845000057],
            [-80.892286247999948, 35.068977845000063],
            [-80.892212247999964, 35.069032845000038],
            [-80.892179247999934, 35.069091845000059],
            [-80.892142247999971, 35.069139846000041],
            [-80.892014247999953, 35.06916284600004],
            [-80.891923246999966, 35.069170845000031],
            [-80.891775247999988, 35.069147846000078],
            [-80.891654247999952, 35.069143846000031],
            [-80.891332247999969, 35.069145846000026],
            [-80.891244247999964, 35.069151846000068],
            [-80.891176248999955, 35.069156846000055],
            [-80.891139246999956, 35.069170846000077],
            [-80.891067247999956, 35.069187845000044],
            [-80.890954247999957, 35.069195846000071],
            [-80.890744247999976, 35.069142846000034],
            [-80.890615247999961, 35.069103846000075],
            [-80.890318246999982, 35.06902384600005],
            [-80.890212247999955, 35.06897884600005],
            [-80.890148246999956, 35.068942846000027],
            [-80.890051246999974, 35.068865846000051],
            [-80.890014246999954, 35.068831845000034],
            [-80.889923247999945, 35.068680845000074],
            [-80.889878246999956, 35.06846884600003],
            [-80.889875246999964, 35.067926846000034],
            [-80.889867246999984, 35.067854846000046],
            [-80.889840246999938, 35.067739845000062],
            [-80.88979724699999, 35.067633846000035],
            [-80.88973324799997, 35.067563845000052],
            [-80.889637246999939, 35.067522846000031],
            [-80.88943024799994, 35.067456845000038],
            [-80.889000245999966, 35.067346846000078],
            [-80.888830246999987, 35.067273845000045],
            [-80.888773246999961, 35.067281846000071],
            [-80.888699246999977, 35.067339846000039],
            [-80.888613246999967, 35.067377846000056],
            [-80.888583245999939, 35.067404846000045],
            [-80.888452245999986, 35.067629846000045],
            [-80.88833124699994, 35.067751846000078],
            [-80.88826624699999, 35.06782684500007],
            [-80.888141246999965, 35.068031845000064],
            [-80.888073246999966, 35.068171846000041],
            [-80.888037245999953, 35.068268845000034],
            [-80.888021245999937, 35.068357846000026],
            [-80.888017246999937, 35.068443846000037],
            [-80.888026246999971, 35.068662846000052],
            [-80.888014246999944, 35.06874584600007],
            [-80.888011246999952, 35.06880284600004],
            [-80.888005246999967, 35.068829846000028],
            [-80.888018245999945, 35.068860845000074],
            [-80.88809024699998, 35.068971846000068],
            [-80.888147246999949, 35.06908784500007],
            [-80.888211246999958, 35.069189846000029],
            [-80.888354246999938, 35.069589846000042],
            [-80.888482246999956, 35.069875847000048],
            [-80.888504246999958, 35.069932846000029],
            [-80.888577246999944, 35.070014846000049],
            [-80.888714246999939, 35.070147846000054],
            [-80.888828246999935, 35.07028084600006],
            [-80.888926246999972, 35.07037484600005],
            [-80.88901724699997, 35.070469845000048],
            [-80.889114245999963, 35.070544847000065],
            [-80.889240247999965, 35.070622847000038],
            [-80.889365246999944, 35.07068984600005],
            [-80.889489246999972, 35.07078784600003],
            [-80.889632246999952, 35.070935846000054],
            [-80.88971924699996, 35.071067846000062],
            [-80.889811247999944, 35.071217846000025],
            [-80.88989524699997, 35.071322847000033],
            [-80.889957246999984, 35.071448846000067],
            [-80.890008246999969, 35.071573847000025],
            [-80.890019247999987, 35.071597847000078],
            [-80.890053246999969, 35.071669847000067],
            [-80.890077247999955, 35.071748846000048],
            [-80.89008724699994, 35.071825846000024],
            [-80.890082247999942, 35.071863846000042],
            [-80.890065246999939, 35.071909847000029],
            [-80.890039247999937, 35.071952846000045],
            [-80.89003024699997, 35.071992846000057],
            [-80.890046246999987, 35.072234847000061],
            [-80.890037247999942, 35.072374847000049],
            [-80.889995246999945, 35.072567847000073],
            [-80.889997247999986, 35.072673846000043],
            [-80.890010246999964, 35.072795846000076],
            [-80.890011246999961, 35.07291084600007],
            [-80.890047246999984, 35.072995846000026],
            [-80.890111247999982, 35.073047847000055],
            [-80.890155247999985, 35.073071847000051],
            [-80.890253247999965, 35.073101847000032],
            [-80.890344246999973, 35.073122846000047],
            [-80.890768246999983, 35.073108847000071],
            [-80.890870247999942, 35.073120847000041],
            [-80.890947247999975, 35.073142847000042],
            [-80.891151247999971, 35.073191847000032],
            [-80.891245246999972, 35.073191847000032],
            [-80.891499247999946, 35.073127846000034],
            [-80.891600247999975, 35.073119847000044],
            [-80.89169624799996, 35.073129847000075],
            [-80.891720248999945, 35.073132846000078],
            [-80.891763246999972, 35.073151847000076],
            [-80.891816247999941, 35.073150847000079],
            [-80.891973247999942, 35.073108847000071],
            [-80.892058247999955, 35.07309884700004],
            [-80.892165248999959, 35.073076847000038],
            [-80.892246247999935, 35.073052847000042],
            [-80.892332247999946, 35.073002847000055],
            [-80.892385248999972, 35.072951846000024],
            [-80.892468247999943, 35.072850846000051],
            [-80.892557247999946, 35.072760846000051],
            [-80.892741247999936, 35.072545847000072],
            [-80.892822248999948, 35.072462846000064],
            [-80.892899247999935, 35.072440846000063],
            [-80.893011247999937, 35.072459846000072],
            [-80.893087248999962, 35.072487846000058],
            [-80.893169247999936, 35.072525846000076],
            [-80.893337248999956, 35.072713846000056],
            [-80.893480248999936, 35.072897847000036],
            [-80.893478247999951, 35.073011846000043],
            [-80.893533248999972, 35.073032846000046],
            [-80.893627248999962, 35.07308684700007],
            [-80.893645248999974, 35.073103846000038],
            [-80.893645248999974, 35.073123846000044],
            [-80.893589248999945, 35.073153847000071],
            [-80.893563247999964, 35.073171847000026],
            [-80.893553247999989, 35.073198847000072],
            [-80.893559248999964, 35.073243847000072],
            [-80.893563248999953, 35.07333884600007],
            [-80.893558247999977, 35.073378846000026],
            [-80.893525248999936, 35.07344084600004],
            [-80.893458248999934, 35.073545847000048],
            [-80.893431248999946, 35.073595846000046],
            [-80.893265247999977, 35.073718847000066],
            [-80.893209247999948, 35.073729847000038],
            [-80.893168247999938, 35.073730847000036],
            [-80.893090247999965, 35.073754847000032],
            [-80.893009248999988, 35.073789847000057],
            [-80.892935247999958, 35.073812847000056],
            [-80.89280324799995, 35.07382984700007],
            [-80.892649248999987, 35.073839847000045],
            [-80.892510247999951, 35.073870846000034],
            [-80.892369247999966, 35.07391584700008],
            [-80.892277247999971, 35.073952847000044],
            [-80.892185247999976, 35.074015847000055],
            [-80.892166247999967, 35.074031847000072],
            [-80.892155247999938, 35.074051847000078],
            [-80.892151247999948, 35.074132847000044],
            [-80.892186248999963, 35.074257847000069],
            [-80.89221024699998, 35.074406847000034],
            [-80.892212247999964, 35.074447846000055],
            [-80.892194247999953, 35.074589847000027],
            [-80.892186248999963, 35.074615847000075],
            [-80.892186247999973, 35.074695847000044],
            [-80.89215724799999, 35.074739847000046],
            [-80.892149248999942, 35.074815847000025],
            [-80.89215024899994, 35.074876847000041],
            [-80.892162247999977, 35.074943847000043],
            [-80.892174247999947, 35.074963847000049],
            [-80.892205247999982, 35.075162848000048],
            [-80.892214247999959, 35.075436847000049],
            [-80.892192247999958, 35.075566847000061],
            [-80.892189248999955, 35.075666848000026],
            [-80.892203248999976, 35.075768848000052],
            [-80.892227247999983, 35.075832847000072],
            [-80.892230247999976, 35.075879847000067],
            [-80.892156247999935, 35.075949847000061],
            [-80.892059247999953, 35.07601384700007],
            [-80.891978247999987, 35.076047847000041],
            [-80.891705247999937, 35.076162847000035],
            [-80.891559247999965, 35.076233847000026],
            [-80.891380247999962, 35.076329848000057],
            [-80.89118224799995, 35.076441847000069],
            [-80.891010247999986, 35.076530848000061],
            [-80.890879248999966, 35.076591848000078],
            [-80.890767246999985, 35.076634847000037],
            [-80.890536247999989, 35.076666848000059],
            [-80.89042724799998, 35.076673847000052],
            [-80.890230247999966, 35.076679847000037],
            [-80.890153246999944, 35.076685848000068],
            [-80.890045247999979, 35.076672847000054],
            [-80.889907247999986, 35.076644847000068],
            [-80.889757247999967, 35.076596847000076],
            [-80.889630247999946, 35.076566848000027],
            [-80.889580246999969, 35.076554847000068],
            [-80.889547247999985, 35.076551848000065],
            [-80.889319247999936, 35.076484847000074],
            [-80.889084247999961, 35.076415848000067],
            [-80.888935246999949, 35.076391848000071],
            [-80.888726246999965, 35.076374847000068],
            [-80.888635247999957, 35.07637784800005],
            [-80.888407247999965, 35.076372847000073],
            [-80.88830424799994, 35.07636584800008],
            [-80.887964245999967, 35.076343847000032],
            [-80.887821247999966, 35.076290848000042],
            [-80.887699246999944, 35.076257848000068],
            [-80.887582246999955, 35.07619784700006],
            [-80.887534246999962, 35.07615684700005],
            [-80.88744924699995, 35.07606684700005],
            [-80.887348246999977, 35.075910847000046],
            [-80.887298245999943, 35.075759848000075],
            [-80.887131246999957, 35.075320848000047],
            [-80.887118246999989, 35.075190847000044],
            [-80.887056246999975, 35.074978847000068],
            [-80.887020246999953, 35.074920847000044],
            [-80.886901246999969, 35.074847847000058],
            [-80.886762246999979, 35.074785847000044],
            [-80.886666246999937, 35.074777848000053],
            [-80.886547246999953, 35.074790847000031],
            [-80.886296246999962, 35.074894848000042],
            [-80.885987245999956, 35.075064847000078],
            [-80.885692245999962, 35.075187847000052],
            [-80.885526245999984, 35.07527884700005],
            [-80.885410245999935, 35.075392848000035],
            [-80.885320245999935, 35.075498848000052],
            [-80.885295245999941, 35.075554848000024],
            [-80.885146245999977, 35.075791847000062],
            [-80.884988246999967, 35.076082848000055],
            [-80.884954246999939, 35.076168848000066],
            [-80.884895246999974, 35.076251847000037],
            [-80.884827245999986, 35.076320847000034],
            [-80.884788245999971, 35.07637784800005],
            [-80.884774245999949, 35.076468848000047],
            [-80.884775245999947, 35.076492848000044],
            [-80.884781245999989, 35.076601848000053],
            [-80.884801245999938, 35.076689848000058],
            [-80.884831245999976, 35.076747848000025],
            [-80.884884245999956, 35.076834848000033],
            [-80.884996245999957, 35.076951848000078],
            [-80.88513024699995, 35.077052847000061],
            [-80.885221246999947, 35.07712884700004],
            [-80.885289245999957, 35.077171848000035],
            [-80.885444245999963, 35.077268848000074],
            [-80.88562924699994, 35.077373848000036],
            [-80.885743246999937, 35.077415848000044],
            [-80.885847245999969, 35.077445848000025],
            [-80.886084246999985, 35.077496848000067],
            [-80.886151246999987, 35.077505848000044],
            [-80.886409245999971, 35.077523848000055],
            [-80.886482245999957, 35.077522848000058],
            [-80.886547246999953, 35.077532847000043],
            [-80.886619245999952, 35.07754884700006],
            [-80.886700245999975, 35.077573847000053],
            [-80.886731246999943, 35.077588847000072],
            [-80.886770245999969, 35.077594848000047],
            [-80.886827247999975, 35.077590847000067],
            [-80.886955246999946, 35.07759384700006],
            [-80.887139246999936, 35.077661848000048],
            [-80.887262245999977, 35.077746848000061],
            [-80.88734324699999, 35.077813848000062],
            [-80.88740024699996, 35.077903848000062],
            [-80.887427246999948, 35.078017847000069],
            [-80.887411246999989, 35.07813784700005],
            [-80.88739224699998, 35.078173848000063],
            [-80.887348247999967, 35.078285848000064],
            [-80.887326247999965, 35.078397848000066],
            [-80.887317245999952, 35.078524848000029],
            [-80.887299245999941, 35.078648848000057],
            [-80.887256246999982, 35.078719847000059],
            [-80.887171247999959, 35.078793848000032],
            [-80.887082245999977, 35.078883848000032],
            [-80.886725247999948, 35.079074848000062],
            [-80.886255246999951, 35.07928584800004],
            [-80.886224245999983, 35.079295848000072],
            [-80.886076245999959, 35.07934184800007],
            [-80.885888246999968, 35.07939084800006],
            [-80.885747246999983, 35.079418848000046],
            [-80.885248245999946, 35.079472849000069],
            [-80.88500324599994, 35.078771849000077],
            [-80.884907246999944, 35.078515848000052],
            [-80.884828245999984, 35.078329848000067],
            [-80.884726245999957, 35.078128848000063],
            [-80.884678245999964, 35.078057847000025],
            [-80.884550244999957, 35.077879848000066],
            [-80.884466246999978, 35.077776848000042],
            [-80.88425224599996, 35.077556848000029],
            [-80.884074245999955, 35.07739084800005],
            [-80.883193244999973, 35.076566848000027],
            [-80.882304244999943, 35.075735848000079],
            [-80.880543244999956, 35.074126847000059],
            [-80.880213244999936, 35.073824847000026],
            [-80.880125244999988, 35.07374384700006],
            [-80.879953243999978, 35.073596847000033],
            [-80.879834243999937, 35.073485847000029],
            [-80.879670244999943, 35.073309847000075],
            [-80.879517243999942, 35.073128847000078],
            [-80.879413244999967, 35.072990847000028],
            [-80.879323243999977, 35.072852847000036],
            [-80.879232243999979, 35.072711847000051],
            [-80.879185243999984, 35.072622847000048],
            [-80.879134243999943, 35.07252784700006],
            [-80.878978243999939, 35.072178847000032],
            [-80.877351242999964, 35.072846847000051],
            [-80.874594243999979, 35.076106847000062],
            [-80.87432824199999, 35.076188848000072],
            [-80.872093242999938, 35.07688384800008],
            [-80.871879241999977, 35.076310848000048],
            [-80.871708241999954, 35.07590084800006],
            [-80.871525241999962, 35.075502848000042],
            [-80.870818241999984, 35.074191848000055],
            [-80.870204241999943, 35.07323384700004],
            [-80.869939241999987, 35.07285784700008],
            [-80.869883240999968, 35.072785848000024],
            [-80.869584241999974, 35.072390848000055],
            [-80.868907240999988, 35.071591847000036],
            [-80.868471240999952, 35.071112847000052],
            [-80.868090240999948, 35.070729847000052],
            [-80.867645240999934, 35.07031084700003],
            [-80.867356239999935, 35.070047847000069],
            [-80.867298239999968, 35.069996847000027],
            [-80.866853240999944, 35.069605847000048],
            [-80.866489239999964, 35.069310847000054],
            [-80.866135239999949, 35.069038847000058],
            [-80.865757239999937, 35.068763847000071],
            [-80.865359239999975, 35.068480846000057],
            [-80.864863239999977, 35.068157847000066],
            [-80.864421238999967, 35.067887847000065],
            [-80.863812239999959, 35.067535846000055],
            [-80.863691238999934, 35.067471847000036],
            [-80.863650238999981, 35.06759084600003],
            [-80.863595239999938, 35.067714847000047],
            [-80.863463238999941, 35.068059847000029],
            [-80.86325723899995, 35.068439847000036],
            [-80.86292323899994, 35.068943847000071],
            [-80.862680238999985, 35.069194847000063],
            [-80.862407239999982, 35.069481846000031],
            [-80.861300238999945, 35.070646846000045],
            [-80.861066237999978, 35.070922847000077],
            [-80.860873237999954, 35.071151847000067],
            [-80.860722237999937, 35.071355848000053],
            [-80.860344238999971, 35.071930848000079],
            [-80.86032223899997, 35.07196484800005],
            [-80.860013238999954, 35.072538847000033],
            [-80.859753238999986, 35.073129848000065],
            [-80.859726237999951, 35.073206848000041],
            [-80.859612238999944, 35.07352884900007],
            [-80.85945623899994, 35.073989848000053],
            [-80.85930923799998, 35.074443848000044],
            [-80.859121237999943, 35.075027848000047],
            [-80.858970237999984, 35.075421849000065],
            [-80.85878323899999, 35.075723848000052],
            [-80.85870523899996, 35.075809848000063],
            [-80.858497237999984, 35.076038848000053],
            [-80.858234237999966, 35.076244848000044],
            [-80.857861238999988, 35.076472849000027],
            [-80.85756923799994, 35.076584849000028],
            [-80.857381237999959, 35.076648849000037],
            [-80.857090237999955, 35.076716848000046],
            [-80.856774237999957, 35.076755849000051],
            [-80.855960237999966, 35.076818849000063],
            [-80.855571236999936, 35.076853849000031],
            [-80.855155237999952, 35.076887848000069],
            [-80.854790236999975, 35.07691784900004],
            [-80.854734236999946, 35.076927848000025],
            [-80.854353236999941, 35.076996849000068],
            [-80.85371923699995, 35.077192849000028],
            [-80.853366236999989, 35.077336848000073],
            [-80.852965235999989, 35.077565849000052],
            [-80.852622235999945, 35.077801850000071],
            [-80.852214235999952, 35.078167849000067],
            [-80.852082235999944, 35.07828685000004],
            [-80.851823235999973, 35.07862085000005],
            [-80.851531235999971, 35.079159849000064],
            [-80.851284235999969, 35.079615849000049],
            [-80.851176235999958, 35.079803850000076],
            [-80.851078236999967, 35.079950849000056],
            [-80.850971235999964, 35.080093850000026],
            [-80.850855235999973, 35.080232850000073],
            [-80.85015823599997, 35.080914850000056],
            [-80.849887236999962, 35.080721850000032],
            [-80.849647234999964, 35.080569850000074],
            [-80.84932523599997, 35.080405850000034],
            [-80.849174234999964, 35.08033985000003],
            [-80.848888235999937, 35.080234850000068],
            [-80.848272235999957, 35.080033849000074],
            [-80.848007234999955, 35.07993084900005],
            [-80.847860234999985, 35.07986285000004],
            [-80.847607235999988, 35.079722850000053],
            [-80.847305234999965, 35.079545850000045],
            [-80.846637234999946, 35.079172850000077],
            [-80.845992233999937, 35.078795850000063],
            [-80.845044234999989, 35.078257850000057],
            [-80.844390233999945, 35.077898850000054],
            [-80.84419623499997, 35.077802849000079],
            [-80.84391823499999, 35.077683850000028],
            [-80.843189232999976, 35.077401849000069],
            [-80.842947233999951, 35.077303849000032],
            [-80.84263223399995, 35.077156850000051],
            [-80.842499233999945, 35.077086849000068],
            [-80.84210023299994, 35.076852849000034],
            [-80.842020232999971, 35.076803849000044],
            [-80.841869232999954, 35.07669284900004],
            [-80.841631232999987, 35.076526849000061],
            [-80.841383232999988, 35.076314850000074],
            [-80.841154233999987, 35.076086849000035],
            [-80.840973233999989, 35.075832848000061],
            [-80.840901233999944, 35.075682849000032],
            [-80.840829232999965, 35.075507849000076],
            [-80.840757232999977, 35.07530084900003],
            [-80.840729231999944, 35.07519484900007],
            [-80.840682232999939, 35.07501884800007],
            [-80.840667232999976, 35.074765848000027],
            [-80.84068823299998, 35.074469849000025],
            [-80.840691232999973, 35.074436849000051],
            [-80.840671232999966, 35.074448848000031],
            [-80.84064723399996, 35.074474849000069],
            [-80.840443231999984, 35.074700850000056],
            [-80.84037723199998, 35.074773849000053],
            [-80.839649232999989, 35.07603984900004],
            [-80.839179232999982, 35.07682784900004],
            [-80.839068232999978, 35.076997849000065],
            [-80.838961232999964, 35.077410850000035],
            [-80.838968231999957, 35.077702850000037],
            [-80.838972231999946, 35.077822849000029],
            [-80.838972231999946, 35.077863850000028],
            [-80.839045232999979, 35.078294849000031],
            [-80.839142232999961, 35.078609849000031],
            [-80.839290231999939, 35.078913850000049],
            [-80.839358232999984, 35.079070849000061],
            [-80.839401231999943, 35.079270850000057],
            [-80.839371232999952, 35.07962985000006],
            [-80.839312231999941, 35.07989384900003],
            [-80.839201232999983, 35.080095850000077],
            [-80.839192231999959, 35.080222850000041],
            [-80.839003232999971, 35.080383850000032],
            [-80.83890323299994, 35.08051185100004],
            [-80.838689232999968, 35.080704850000075],
            [-80.837987232999978, 35.081421851000073],
            [-80.837573231999954, 35.081828851000068],
            [-80.837284231999945, 35.082096850000028],
            [-80.837071231999971, 35.082332850000057],
            [-80.836419231999969, 35.082995850000032],
            [-80.836105231999966, 35.083285851000028],
            [-80.835691231999988, 35.083723851000059],
            [-80.835303231999944, 35.084120850000033],
            [-80.834537231999946, 35.084869851000065],
            [-80.834262230999968, 35.085190852000039],
            [-80.834088231999942, 35.085361852000062],
            [-80.834023230999946, 35.085425851000025],
            [-80.833786230999976, 35.085724851000066],
            [-80.833563230999971, 35.08607685100003],
            [-80.833465230999934, 35.086246851000055],
            [-80.833315231999961, 35.086459852000075],
            [-80.833263230999989, 35.086553852000065],
            [-80.833180230999972, 35.086704851000036],
            [-80.833032231999937, 35.087001852000071],
            [-80.832920230999946, 35.087151852000034],
            [-80.832684230999973, 35.087492852000025],
            [-80.832504230999973, 35.08778385200003],
            [-80.832275230999983, 35.088120852000031],
            [-80.832169230999966, 35.088347852000027],
            [-80.832015230999957, 35.088535852000064],
            [-80.831904230999953, 35.088748852000037],
            [-80.831818229999953, 35.088897853000049],
            [-80.831746230999954, 35.089109852000036],
            [-80.831671230999973, 35.089226853000071],
            [-80.831521230999954, 35.089397853000037],
            [-80.831347230999938, 35.089631852000025],
            [-80.831186230999947, 35.089908853000054],
            [-80.831102230999988, 35.090141852000045],
            [-80.831104230999983, 35.090226853000047],
            [-80.831069230999958, 35.090296852000051],
            [-80.830957229999967, 35.09045485300004],
            [-80.830880230999981, 35.090535853000063],
            [-80.830692229999954, 35.090687853000077],
            [-80.830463230999953, 35.09087085200008],
            [-80.830299230999969, 35.090965852000068],
            [-80.830173229999957, 35.091062854000029],
            [-80.829940231999956, 35.091151853000042],
            [-80.829701230999945, 35.091196853000042],
            [-80.829573229999937, 35.091208853000069],
            [-80.82940623099995, 35.091211853000061],
            [-80.82867822999998, 35.091372853000053],
            [-80.828589229999977, 35.091392854000048],
            [-80.827858229999947, 35.091464853000048],
            [-80.827693230999955, 35.091479853000067],
            [-80.827068229999952, 35.091615853000064],
            [-80.826710229999946, 35.091674853000029],
            [-80.826531228999954, 35.091697853000028],
            [-80.826340229999971, 35.091700853000077],
            [-80.826122229999953, 35.09172585400006],
            [-80.825839228999939, 35.091791853000075],
            [-80.825749228999939, 35.091815853000071],
            [-80.825381228999959, 35.091926853000075],
            [-80.82507622899999, 35.092046853000056],
            [-80.824770229999956, 35.092141853000044],
            [-80.825098228999934, 35.092703853000046],
            [-80.825213229999974, 35.092915852000033],
            [-80.825373228999979, 35.093277854000064],
            [-80.825610228999949, 35.09383185400003],
            [-80.826017229999934, 35.094806854000069],
            [-80.826151229999937, 35.095101853000074],
            [-80.826214229999948, 35.09521185400007],
            [-80.826331229999937, 35.095377853000059],
            [-80.826401228999941, 35.095464854000056],
            [-80.826824228999953, 35.095941854000046],
            [-80.827243229999965, 35.096422855000071],
            [-80.827300229999935, 35.096503854000048],
            [-80.82737222999998, 35.096636854000053],
            [-80.827401230999953, 35.096705854000049],
            [-80.827431229999945, 35.096831855000062],
            [-80.827441229999977, 35.096912855000028],
            [-80.827442229999974, 35.097054854000078],
            [-80.82742922999995, 35.097195855000052],
            [-80.827348228999938, 35.097745855000028],
            [-80.827308230999961, 35.098043855000071],
            [-80.827184229999943, 35.098924854000074],
            [-80.827116229999945, 35.099369855000077],
            [-80.827015230999962, 35.099898855000049],
            [-80.826885230999949, 35.100460855000051],
            [-80.826797230999944, 35.100810855000077],
            [-80.826732230999937, 35.101121855000031],
            [-80.826716229999988, 35.101249855000049],
            [-80.826710229999946, 35.101351855000075],
            [-80.826720229999978, 35.101582855000061],
            [-80.826728229999958, 35.101975856000024],
            [-80.826744229999974, 35.102443855000047],
            [-80.826761230999978, 35.103230856000039],
            [-80.826773230999947, 35.103359856000054],
            [-80.826803229999939, 35.103580856000065],
            [-80.826855230999968, 35.104391856000063],
            [-80.82685823099996, 35.104649856000037],
            [-80.826849230999983, 35.104973856000072],
            [-80.826838230999954, 35.105117856000049],
            [-80.826854229999981, 35.105263855000032],
            [-80.826883229999964, 35.105371856000033],
            [-80.826924230999964, 35.105460856000036],
            [-80.826977229999954, 35.105516856000065],
            [-80.827405230999943, 35.10579685600004],
            [-80.827491230999954, 35.105699856000058],
            [-80.827760229999967, 35.105475855000066],
            [-80.828050230999963, 35.105288855000026],
            [-80.828331230999936, 35.105136856000058],
            [-80.828920230999984, 35.104879855000036],
            [-80.829524230999937, 35.104621856000051],
            [-80.830136231999973, 35.104388855000025],
            [-80.830395231999944, 35.104322856000067],
            [-80.830633230999979, 35.104277856000067],
            [-80.830929230999971, 35.10424785500004],
            [-80.831348230999936, 35.104239856000049],
            [-80.831400231999964, 35.104239856000049],
            [-80.832128231999945, 35.104282856000054],
            [-80.832582231999936, 35.104272855000033],
            [-80.832919232999984, 35.104227855000033],
            [-80.833031231999939, 35.104203855000037],
            [-80.833313232999956, 35.104145856000059],
            [-80.833533231999979, 35.104081855000061],
            [-80.833728231999942, 35.104014855000059],
            [-80.833923231999961, 35.103934855000034],
            [-80.834138231999987, 35.103816855000048],
            [-80.834337232999985, 35.103699856000048],
            [-80.834524232999968, 35.103574855000033],
            [-80.834783232999939, 35.103359855000065],
            [-80.835095231999958, 35.10301185600008],
            [-80.835486231999937, 35.102561855000033],
            [-80.835862232999943, 35.102132855000036],
            [-80.836132232999944, 35.101832854000065],
            [-80.836202231999948, 35.101737854000078],
            [-80.836653232999936, 35.101198855000064],
            [-80.836908232999974, 35.100883855000063],
            [-80.837016233999975, 35.100689855000041],
            [-80.837059232999934, 35.100633855000069],
            [-80.837077232999945, 35.100593855000056],
            [-80.837269233999962, 35.100231854000072],
            [-80.837538233999965, 35.099740854000061],
            [-80.837636233999945, 35.099581855000054],
            [-80.837696233999964, 35.099494854000056],
            [-80.837723232999963, 35.099432854000042],
            [-80.837774233999937, 35.099379855000052],
            [-80.837847232999934, 35.099273854000046],
            [-80.837892232999934, 35.099197854000067],
            [-80.838009233999969, 35.099043854000058],
            [-80.838150233999954, 35.098889854000049],
            [-80.838331232999963, 35.09868285400006],
            [-80.83846723299996, 35.098560854000027],
            [-80.838560233999942, 35.098452855000062],
            [-80.838798232999977, 35.098225854000077],
            [-80.838859232999937, 35.098148854000044],
            [-80.839041233999978, 35.097969854000041],
            [-80.839283232999946, 35.09771485300007],
            [-80.839458233999949, 35.097517855000035],
            [-80.839577232999943, 35.09736285300005],
            [-80.839825233999989, 35.097023854000042],
            [-80.83998623399998, 35.09678785400007],
            [-80.840107233999959, 35.096620854000037],
            [-80.840167232999988, 35.096555853000041],
            [-80.840216233999968, 35.096494853000024],
            [-80.840806233999956, 35.095627852000064],
            [-80.841124233999949, 35.095173853000063],
            [-80.841304234999939, 35.094905853000057],
            [-80.841397234999988, 35.094746853000061],
            [-80.841617234999944, 35.094405853000069],
            [-80.841737233999936, 35.094246853000072],
            [-80.841821233999951, 35.094152853000026],
            [-80.841883233999965, 35.094092853000063],
            [-80.841931233999958, 35.094046853000066],
            [-80.842167234999977, 35.093861853000078],
            [-80.84239123499998, 35.094057853000038],
            [-80.842447234999952, 35.094099853000046],
            [-80.842570233999936, 35.094174853000027],
            [-80.842730234999976, 35.094247852000024],
            [-80.842835234999939, 35.094284853000033],
            [-80.843092234999972, 35.094368853000049],
            [-80.843191234999949, 35.094408853000061],
            [-80.843380233999937, 35.094499853000059],
            [-80.843526234999956, 35.094582853000077],
            [-80.843724234999968, 35.094725853000057],
            [-80.843858234999971, 35.094802853000033],
            [-80.843986234999988, 35.09485985300006],
            [-80.844122234999986, 35.094901853000067],
            [-80.844326235999972, 35.094938853000031],
            [-80.844442234999974, 35.094948853000062],
            [-80.844592233999947, 35.094944853000072],
            [-80.845133235999981, 35.094913853000037],
            [-80.846837235999942, 35.094815853000057],
            [-80.847107235999943, 35.09480385300003],
            [-80.84717823699998, 35.09481085300007],
            [-80.847236235999958, 35.094821853000042],
            [-80.847292235999987, 35.094837853000058],
            [-80.847398235999947, 35.094881853000061],
            [-80.847445235999942, 35.094909853000047],
            [-80.847521235999977, 35.094967853000071],
            [-80.847592234999979, 35.095046853000042],
            [-80.847693235999941, 35.095216853000068],
            [-80.84774123699998, 35.095301853000024],
            [-80.847772235999969, 35.095406853000043],
            [-80.847773235999966, 35.095494853000048],
            [-80.847764235999989, 35.09553885300005],
            [-80.847730235999961, 35.095622853000066],
            [-80.847702235999975, 35.095662854000068],
            [-80.847730235999961, 35.095672853000053],
            [-80.848029235999945, 35.095672853000053],
            [-80.848134235999964, 35.095678853000038],
            [-80.848174235999977, 35.095684854000069],
            [-80.848304236999979, 35.095743853000045],
            [-80.848345235999943, 35.095757853000066],
            [-80.84839423599999, 35.095752853000079],
            [-80.848446236999962, 35.095761853000056],
            [-80.848572235999939, 35.095810853000046],
            [-80.848742236999954, 35.095924853000042],
            [-80.848791236999944, 35.095945853000046],
            [-80.84890523699994, 35.09599585400008],
            [-80.849019235999947, 35.096079852000059],
            [-80.849200235999945, 35.096271853000076],
            [-80.84924323599995, 35.096306853000044],
            [-80.849306236999951, 35.096329852000054],
            [-80.84936223699998, 35.096331853000038],
            [-80.84959123699997, 35.09637585300004],
            [-80.849254236999968, 35.096949853000069],
            [-80.849141236999969, 35.097110853000061],
            [-80.848960236999972, 35.097336854000048],
            [-80.84881923599994, 35.097474854000041],
            [-80.848632236999947, 35.097674853000058],
            [-80.848466235999979, 35.097895853000068],
            [-80.848269236999954, 35.098215854000046],
            [-80.848216235999985, 35.098299854000061],
            [-80.848032235999938, 35.098628854000026],
            [-80.84796623699998, 35.098757853000052],
            [-80.84788423599997, 35.098921854000025],
            [-80.847737235999944, 35.099189854000031],
            [-80.847637235999969, 35.099306854000076],
            [-80.847580236999988, 35.099429853000061],
            [-80.847508236999943, 35.09961785400003],
            [-80.847369235999963, 35.099868854000078],
            [-80.847282235999955, 35.09998885400006],
            [-80.847180236999975, 35.100193854000054],
            [-80.846758235999971, 35.100949855000067],
            [-80.846650235999959, 35.101287854000077],
            [-80.846715235999966, 35.101374855000074],
            [-80.846517236999944, 35.101510855000072],
            [-80.846379236999951, 35.101718855000058],
            [-80.846187235999935, 35.102089855000031],
            [-80.846039235999967, 35.102337854000041],
            [-80.845966235999981, 35.102481855000065],
            [-80.84585423599998, 35.102672854000048],
            [-80.845843235999951, 35.102690854000059],
            [-80.845648235999988, 35.102938855000048],
            [-80.845551234999959, 35.103160854000066],
            [-80.84546323699999, 35.103431855000053],
            [-80.845368234999967, 35.103562855000064],
            [-80.845289236999974, 35.103662854000049],
            [-80.845229235999966, 35.103806854000027],
            [-80.845206235999967, 35.103943855000068],
            [-80.845161234999978, 35.104038855000056],
            [-80.845105235999938, 35.104197855000052],
            [-80.845055235999951, 35.104425855000045],
            [-80.844951235999986, 35.104739855000048],
            [-80.844878235999943, 35.104873854000061],
            [-80.844778235999968, 35.104980855000065],
            [-80.84437223599997, 35.10530585500004],
            [-80.844308235999961, 35.105320855000059],
            [-80.844197235999957, 35.105318855000064],
            [-80.84408323599996, 35.105369856000038],
            [-80.844020235999949, 35.105451856000059],
            [-80.843951234999963, 35.105542855000067],
            [-80.843754234999949, 35.105889856000033],
            [-80.843710234999946, 35.106019856000046],
            [-80.843696234999982, 35.106180856000037],
            [-80.843703235999953, 35.106303855000078],
            [-80.843771234999963, 35.106470855000055],
            [-80.843941235999978, 35.106807856000046],
            [-80.844032235999975, 35.107058856000037],
            [-80.844105235999962, 35.107302856000047],
            [-80.844092234999948, 35.107481856000049],
            [-80.844117235999988, 35.107610855000075],
            [-80.844089235999945, 35.107716856000025],
            [-80.84396323499999, 35.107799856000042],
            [-80.843980234999947, 35.107874856000024],
            [-80.844033235999973, 35.108141856000032],
            [-80.844104235999964, 35.108406856000045],
            [-80.844108234999965, 35.108474856000043],
            [-80.844096235999984, 35.108528856000078],
            [-80.844052234999936, 35.108589856000037],
            [-80.844051235999984, 35.108627856000055],
            [-80.844069234999949, 35.108661856000026],
            [-80.84417723599995, 35.108780856000067],
            [-80.844211235999978, 35.108844856000076],
            [-80.844244235999952, 35.108989855000061],
            [-80.844273235999935, 35.109067856000024],
            [-80.844438234999984, 35.109253857000056],
            [-80.844500235999988, 35.10929085600003],
            [-80.844660236999971, 35.109431856000072],
            [-80.844931235999979, 35.10964685600004],
            [-80.845137235999971, 35.109823856000048],
            [-80.845199235999985, 35.109861856000066],
            [-80.845234235999953, 35.109883855000078],
            [-80.845460236999941, 35.109953856000061],
            [-80.845582235999984, 35.110016857000062],
            [-80.845650235999983, 35.110086856000066],
            [-80.845713236999984, 35.110170856000025],
            [-80.845817235999959, 35.110361856000054],
            [-80.845853236999972, 35.110391856000035],
            [-80.84594823599997, 35.110403857000051],
            [-80.846007235999934, 35.110452856000052],
            [-80.846187236999981, 35.110668856000075],
            [-80.846314236999945, 35.110810857000047],
            [-80.846479235999936, 35.110934857000075],
            [-80.846502235999935, 35.110964857000056],
            [-80.846507236999969, 35.110982856000078],
            [-80.846502236999982, 35.111112856000034],
            [-80.846536235999963, 35.11115785700008],
            [-80.846679235999943, 35.111216857000045],
            [-80.846794236999983, 35.111256857000058],
            [-80.846952236999982, 35.111391856000068],
            [-80.847144235999963, 35.111520856000027],
            [-80.84717823699998, 35.111549857000057],
            [-80.847191236999947, 35.111583856000038],
            [-80.847196236999935, 35.111624856000049],
            [-80.847194237999986, 35.111707857000056],
            [-80.847205236999969, 35.111862857000062],
            [-80.847075236999956, 35.112035857000023],
            [-80.847093236999967, 35.112307856000029],
            [-80.847214236999946, 35.112513857000067],
            [-80.84727623699996, 35.112616856000045],
            [-80.847428236999974, 35.112837857000045],
            [-80.847467236999989, 35.112898857000062],
            [-80.847591236999961, 35.11292185700006],
            [-80.847582237999973, 35.113069857000028],
            [-80.847592235999969, 35.113150857000051],
            [-80.847576236999942, 35.113265857000044],
            [-80.847553235999953, 35.113334857000041],
            [-80.847528236999949, 35.113382857000033],
            [-80.847548236999955, 35.113498857000025],
            [-80.847547236999958, 35.11353385600006],
            [-80.847619236999947, 35.113636857000074],
            [-80.847729236999953, 35.113766856000041],
            [-80.847748236999962, 35.113813857000025],
            [-80.847750236999957, 35.113846856000066],
            [-80.847802236999939, 35.113992857000028],
            [-80.847820236999951, 35.114029857000048],
            [-80.847940237999978, 35.114089857000067],
            [-80.84798223699994, 35.114102857000034],
            [-80.848188236999988, 35.114209858000038],
            [-80.848210236999989, 35.114234857000042],
            [-80.848216236999974, 35.114255856000057],
            [-80.848253236999938, 35.114324858000032],
            [-80.848263236999969, 35.114352857000029],
            [-80.848319236999941, 35.11440785700006],
            [-80.848353236999969, 35.114414857000043],
            [-80.848388236999938, 35.114414857000043],
            [-80.848424236999961, 35.114429857000061],
            [-80.848508236999976, 35.114484857000036],
            [-80.848597237999968, 35.11453485800007],
            [-80.84866823699997, 35.114563857000064],
            [-80.848738237999953, 35.114615858000036],
            [-80.848783236999964, 35.114666857000032],
            [-80.84883623799999, 35.114711857000032],
            [-80.848849237999957, 35.114728857000046],
            [-80.848953236999989, 35.114815858000043],
            [-80.849039236999943, 35.114861857000051],
            [-80.84912623799994, 35.11487685700007],
            [-80.849195236999947, 35.114918856000031],
            [-80.849307237999938, 35.115029857000025],
            [-80.849381237999978, 35.11507285700003],
            [-80.849434236999969, 35.115126857000064],
            [-80.849517237999976, 35.115243858000042],
            [-80.849613236999971, 35.115357858000039],
            [-80.849633237999967, 35.115406857000039],
            [-80.849697237999976, 35.115505857000073],
            [-80.849774238999942, 35.11557885700006],
            [-80.849872236999943, 35.115662857000075],
            [-80.849913236999953, 35.115716857000052],
            [-80.849932236999962, 35.115748857000028],
            [-80.850028237999936, 35.115878857000041],
            [-80.85003323799998, 35.115910857000074],
            [-80.850132237999958, 35.116036858000029],
            [-80.850186237999935, 35.116127857000038],
            [-80.850182237999945, 35.116145857000049],
            [-80.850189237999984, 35.11616385700006],
            [-80.850193237999974, 35.11619685800008],
            [-80.850206236999952, 35.116226857000072],
            [-80.850222237999958, 35.116247858000065],
            [-80.850210237999988, 35.116296858000055],
            [-80.850231237999935, 35.116418857000042],
            [-80.850234237999985, 35.116552857000045],
            [-80.850215237999976, 35.116641858000037],
            [-80.850196237999967, 35.116778857000043],
            [-80.850207237999939, 35.116863857000055],
            [-80.850246237999954, 35.116987857000026],
            [-80.850254237999934, 35.117031858000075],
            [-80.850262237999971, 35.117125858000065],
            [-80.85027023799995, 35.117170858000065],
            [-80.850273238999989, 35.117414857000028],
            [-80.850266236999971, 35.117504858000075],
            [-80.850247237999952, 35.117717858000049],
            [-80.850254237999934, 35.117858857000044],
            [-80.850242237999964, 35.118095857000071],
            [-80.850172237999971, 35.118130858000029],
            [-80.84991423699995, 35.118277858000056],
            [-80.849832238999966, 35.118330858000036],
            [-80.849508237999942, 35.11857285800005],
            [-80.84916723799995, 35.118867858000044],
            [-80.849101237999946, 35.118935858000043],
            [-80.848749237999982, 35.119249858000046],
            [-80.848546236999937, 35.119404858000053],
            [-80.848401237999951, 35.119500857000048],
            [-80.848266236999962, 35.11958185800006],
            [-80.848077237999973, 35.119684858000028],
            [-80.847723237999958, 35.11982985800006],
            [-80.847485236999944, 35.119909858000028],
            [-80.847283236999942, 35.119958859000064],
            [-80.847090236999975, 35.119996858000036],
            [-80.846894236999958, 35.120025858000076],
            [-80.846697236999944, 35.120045858000026],
            [-80.846608236999941, 35.120049858000073],
            [-80.84625123699999, 35.120049858000073],
            [-80.846178236999947, 35.120044858000028],
            [-80.845986236999977, 35.120027858000071],
            [-80.845706236999945, 35.119982859000061],
            [-80.845522236999955, 35.11994185900005],
            [-80.845341236999957, 35.119892858000071],
            [-80.845077235999952, 35.119803858000068],
            [-80.844939235999959, 35.119738858000062],
            [-80.84481423699998, 35.119693858000062],
            [-80.844752236999966, 35.119761858000061],
            [-80.844987236999941, 35.120152859000029],
            [-80.845148236999989, 35.120491858000037],
            [-80.845256235999955, 35.120703859000059],
            [-80.845323236999945, 35.120871859000033],
            [-80.845354236999981, 35.121082858000079],
            [-80.845382236999967, 35.121299858000043],
            [-80.845376235999936, 35.12142385900006],
            [-80.845359236999968, 35.121493858000065],
            [-80.845307236999986, 35.121681859000034],
            [-80.845297236999954, 35.121762858000068],
            [-80.845023235999975, 35.122857859000078],
            [-80.844961235999961, 35.123058859000025],
            [-80.844706235999979, 35.123804860000064],
            [-80.84463823699997, 35.124044859000037],
            [-80.844583236999938, 35.124278859000071],
            [-80.84449523699999, 35.124978860000056],
            [-80.844464236999954, 35.125560860000064],
            [-80.844433236999976, 35.125799860000029],
            [-80.844356236999943, 35.12619686000005],
            [-80.844305235999968, 35.126476860000025],
            [-80.844279236999967, 35.12665786000008],
            [-80.844285236999951, 35.126722860000029],
            [-80.844303235999973, 35.126786860000038],
            [-80.844332236999946, 35.126846860000057],
            [-80.844371236999962, 35.126903861000073],
            [-80.844421236999949, 35.126954860000069],
            [-80.844468236999944, 35.126987860000042],
            [-80.844515236999939, 35.127017860000024],
            [-80.84519023699994, 35.127390861000038],
            [-80.845924236999963, 35.12781386000006],
            [-80.845954236999944, 35.127840860000049],
            [-80.846002236999936, 35.127924860000064],
            [-80.845576236999989, 35.128003859000046],
            [-80.845321236999951, 35.128048860000035],
            [-80.844972236999979, 35.128139859000044],
            [-80.844898236999938, 35.128164860000027],
            [-80.844751236999969, 35.128226860000041],
            [-80.844613236999976, 35.12829486000004],
            [-80.844481236999968, 35.128371860000073],
            [-80.844340235999937, 35.128467860000057],
            [-80.844162236999978, 35.12860986000004],
            [-80.843908236999937, 35.128853861000039],
            [-80.843788236999956, 35.128982860000065],
            [-80.843664235999938, 35.12910786100008],
            [-80.843558236999968, 35.129240861000028],
            [-80.843464236999978, 35.129380860000026],
            [-80.843404235999969, 35.129494860000079],
            [-80.843314235999969, 35.129717860000028],
            [-80.843190235999941, 35.130060860000071],
            [-80.843124236999984, 35.130224860000055],
            [-80.843076236999934, 35.130314861000045],
            [-80.842951235999976, 35.130491861000053],
            [-80.842856236999978, 35.130602860000067],
            [-80.84272423699997, 35.130738861000054],
            [-80.842154235999942, 35.131273862000057],
            [-80.842121235999969, 35.131304861000046],
            [-80.842040235999946, 35.131401861000029],
            [-80.841699236999943, 35.131724861000066],
            [-80.841395235999983, 35.132008861000031],
            [-80.841249236999943, 35.132128861000069],
            [-80.841038235999974, 35.132274861000042],
            [-80.840918236999983, 35.13234686100003],
            [-80.840846235999948, 35.132382861000053],
            [-80.839464235999969, 35.133035861000053],
            [-80.839293234999957, 35.133079862000045],
            [-80.839064234999967, 35.133125862000043],
            [-80.839106235999964, 35.133292861000029],
            [-80.839121235999983, 35.133385861000079],
            [-80.839159235999944, 35.133789862000071],
            [-80.839158235999946, 35.134060862000069],
            [-80.839157235999949, 35.134160862000044],
            [-80.839133234999963, 35.134421862000067],
            [-80.839119235999988, 35.134549862000028],
            [-80.839062235999961, 35.135452862000079],
            [-80.838970236999955, 35.137060862000055],
            [-80.838948235999965, 35.137322862000076],
            [-80.83893123699994, 35.137428862000036],
            [-80.838888235999946, 35.137591862000079],
            [-80.838824235999937, 35.137751862000073],
            [-80.838643235999939, 35.138127862000033],
            [-80.83849223499999, 35.138428863000058],
            [-80.838341235999962, 35.13867986200006],
            [-80.838188235999951, 35.138904862000061],
            [-80.838015234999943, 35.139129863000051],
            [-80.837958234999974, 35.139202863000037],
            [-80.837268235999943, 35.140059863000033],
            [-80.837098235999974, 35.140270863000069],
            [-80.837016234999965, 35.140354863000027],
            [-80.83692023499998, 35.140429863000065],
            [-80.836867234999943, 35.140461862000052],
            [-80.836767234999968, 35.140511863000029],
            [-80.836629235999965, 35.140558863000024],
            [-80.836426234999976, 35.140607863000071],
            [-80.836277235999944, 35.140649864000068],
            [-80.836207233999971, 35.140677863000064],
            [-80.836113234999971, 35.140721863000067],
            [-80.835973234999983, 35.140804863000028],
            [-80.83584723599995, 35.140902863000065],
            [-80.83579423499998, 35.14095386300005],
            [-80.834491233999984, 35.14206086300004],
            [-80.834137234999957, 35.142377863000036],
            [-80.833886234999966, 35.142615864000049],
            [-80.833738234999942, 35.142775864000043],
            [-80.833668233999958, 35.142868864000036],
            [-80.833621234999953, 35.142940863000035],
            [-80.833579233999956, 35.143015863000073],
            [-80.833554233999962, 35.143063863000066],
            [-80.833128234999947, 35.143903864000038],
            [-80.832981234999977, 35.144209864000061],
            [-80.832398234999971, 35.145418864000078],
            [-80.832187233999946, 35.145855864000055],
            [-80.831902233999983, 35.146421864000047],
            [-80.831607233999989, 35.147007865000035],
            [-80.831342234999966, 35.147532864000027],
            [-80.83104423399999, 35.148122865000062],
            [-80.83098423399997, 35.148242865000043],
            [-80.830896234999955, 35.148427865000031],
            [-80.830870234999963, 35.148484865000057],
            [-80.830657233999943, 35.148883865000073],
            [-80.830501233999939, 35.149143865000042],
            [-80.83031323299997, 35.149430866000046],
            [-80.830156232999968, 35.149650865000069],
            [-80.829968233999978, 35.149896866000063],
            [-80.829770232999977, 35.150136865000036],
            [-80.829561232999936, 35.150371865000068],
            [-80.829343233999964, 35.15059986600005],
            [-80.829115232999982, 35.150821866000058],
            [-80.828877232999957, 35.151036865000037],
            [-80.828687233999972, 35.151196866000078],
            [-80.827429232999975, 35.152256866000073],
            [-80.826863231999937, 35.152741866000042],
            [-80.826968232999945, 35.152819867000062],
            [-80.827154232999987, 35.152940866000051],
            [-80.827739232999988, 35.153247866000072],
            [-80.828611233999936, 35.153690866000034],
            [-80.830815234999989, 35.154807866000056],
            [-80.830435234999982, 35.155301866000059],
            [-80.830356232999975, 35.155412866000063],
            [-80.830202234999945, 35.155652866000025],
            [-80.830141233999939, 35.155760867000026],
            [-80.830050234999987, 35.155938867000032],
            [-80.830027233999942, 35.15598486600004],
            [-80.829903232999982, 35.156206866000048],
            [-80.82984823399994, 35.156292867000047],
            [-80.829788232999988, 35.156376866000073],
            [-80.829625233999934, 35.156577867000067],
            [-80.829515232999938, 35.156692867000061],
            [-80.82936323399997, 35.156822867000074],
            [-80.829199233999987, 35.156945867000047],
            [-80.82904423399998, 35.157041867000032],
            [-80.828915233999965, 35.157104867000044],
            [-80.828738233999957, 35.157182867000074],
            [-80.828605233999951, 35.15723386600007],
            [-80.82836223299995, 35.157301867000058],
            [-80.828249233999941, 35.157336867000026],
            [-80.827719232999982, 35.157500868000056],
            [-80.827462233999938, 35.157580867000036],
            [-80.827571232999958, 35.157854867000026],
            [-80.827650232999986, 35.15809686700004],
            [-80.827696232999983, 35.158286867000072],
            [-80.827710233999937, 35.158345867000037],
            [-80.827743233999968, 35.158551867000028],
            [-80.827770233999956, 35.158957867000026],
            [-80.827752233999945, 35.15989386800004],
            [-80.827742232999981, 35.160236867000037],
            [-80.827716233999979, 35.161141868000072],
            [-80.82770923399994, 35.16161186800008],
            [-80.827704232999963, 35.161974868000073],
            [-80.827713233999987, 35.162071868000055],
            [-80.827747233999958, 35.162262869000074],
            [-80.827802232999943, 35.162450868000064],
            [-80.827876232999984, 35.162634868000055],
            [-80.827971233999961, 35.162811868000063],
            [-80.828019233999953, 35.162886869000033],
            [-80.828187232999937, 35.163105868000059],
            [-80.828425233999951, 35.16342486800005],
            [-80.828741233999949, 35.16388286800003],
            [-80.828903234999984, 35.164135868000074],
            [-80.829010233999952, 35.164310869000076],
            [-80.829262233999941, 35.164746868000066],
            [-80.82949823499996, 35.165195869000058],
            [-80.829604234999977, 35.165412869000079],
            [-80.829808233999984, 35.165865868000026],
            [-80.829993234999961, 35.166322868000066],
            [-80.83006123399997, 35.166567869000062],
            [-80.830205234999937, 35.167040869000061],
            [-80.830408234999936, 35.16767186900006],
            [-80.830467234999958, 35.167874869000059],
            [-80.830498234999936, 35.167957870000066],
            [-80.830674234999947, 35.168502870000054],
            [-80.830709234999972, 35.168621869000049],
            [-80.830729234999978, 35.168690870000034],
            [-80.830742234999946, 35.168790870000066],
            [-80.830742234999946, 35.168879869000079],
            [-80.830731234999973, 35.168983870000034],
            [-80.830713234999962, 35.169089869000061],
            [-80.830673234999949, 35.169254870000032],
            [-80.830611234999935, 35.169438870000079],
            [-80.83041323499998, 35.169959870000071],
            [-80.830300233999935, 35.170246869000039],
            [-80.830201234999947, 35.170497870000077],
            [-80.830002234999938, 35.17104487000006],
            [-80.829966234999972, 35.171159869000064],
            [-80.829950234999956, 35.171268870000063],
            [-80.829945233999979, 35.17143186900006],
            [-80.829970234999962, 35.171570870000039],
            [-80.829989234999971, 35.171622871000068],
            [-80.830058234999967, 35.171772870000041],
            [-80.83011823399994, 35.171866870000031],
            [-80.830185234999988, 35.171950870000046],
            [-80.830320234999988, 35.172088870000039],
            [-80.830719235999936, 35.172421870000051],
            [-80.831176234999987, 35.172758870000052],
            [-80.83139723499994, 35.172874870000044],
            [-80.831467234999934, 35.172902871000076],
            [-80.831683235999947, 35.173000870000067],
            [-80.831856234999975, 35.173079870000038],
            [-80.832058234999977, 35.173394871000028],
            [-80.832137235999937, 35.173478870000054],
            [-80.83221423599997, 35.173546870000052],
            [-80.83230023599998, 35.173612870000056],
            [-80.832411235999984, 35.173685870000043],
            [-80.832511236999949, 35.173742870000069],
            [-80.832590235999987, 35.173780871000076],
            [-80.832723235999936, 35.173835870000062],
            [-80.832857235999938, 35.173879870000064],
            [-80.833085235999988, 35.173937871000078],
            [-80.832973235999987, 35.174077870000076],
            [-80.832282235999969, 35.174673870000049],
            [-80.831225235999966, 35.175253870000063],
            [-80.831091235999963, 35.175441871000032],
            [-80.831219234999935, 35.175746870000069],
            [-80.831398234999938, 35.175986871000077],
            [-80.831587234999972, 35.176341871000034],
            [-80.832096235999984, 35.177232871000058],
            [-80.832369235999977, 35.177364871000066],
            [-80.832433236999975, 35.177379870000038],
            [-80.832832235999945, 35.177471872000069],
            [-80.832862235999983, 35.177656871000067],
            [-80.832721235999941, 35.177930871000058],
            [-80.832320236999976, 35.178278871000032],
            [-80.83180523599998, 35.17858687100005],
            [-80.831027235999954, 35.178826871000069],
            [-80.830426235999937, 35.179164871000069],
            [-80.829946234999966, 35.179302872000051],
            [-80.829886234999947, 35.179322872000057],
            [-80.829692234999982, 35.179389871000069],
            [-80.829532233999942, 35.179592872000057],
            [-80.829259234999938, 35.17985387300007],
            [-80.829196234999984, 35.179906872000061],
            [-80.829235235999988, 35.180061872000067],
            [-80.829253235999943, 35.180211872000029],
            [-80.829249234999963, 35.180299873000024],
            [-80.829226234999965, 35.180467872000065],
            [-80.829206234999958, 35.180550872000026],
            [-80.829172234999987, 35.180651872000055],
            [-80.829100234999942, 35.180806872000062],
            [-80.829010234999942, 35.180953872000032],
            [-80.828575235999949, 35.181512872000042],
            [-80.828413234999971, 35.18172687200007],
            [-80.828341234999982, 35.181840873000056],
            [-80.828320234999978, 35.181891872000051],
            [-80.828271234999988, 35.182044872000063],
            [-80.828243234999945, 35.182201872000064],
            [-80.828203234999989, 35.182985872000074],
            [-80.828190234999965, 35.183149873000048],
            [-80.828163235999966, 35.183635873000071],
            [-80.828156234999938, 35.183749873000068],
            [-80.828104234999955, 35.184729873000038],
            [-80.82805723599995, 35.185496873000034],
            [-80.828068234999989, 35.185596873000065],
            [-80.82809823499997, 35.185697873000038],
            [-80.828122234999967, 35.185745872000041],
            [-80.828178235999985, 35.185831873000041],
            [-80.828364234999981, 35.186069874000054],
            [-80.828602234999948, 35.186347874000035],
            [-80.828930234999973, 35.186678874000052],
            [-80.82920123599996, 35.186924873000066],
            [-80.829452235999952, 35.18712587400006],
            [-80.829624235999972, 35.187254873000029],
            [-80.829763235999962, 35.187348873000076],
            [-80.83000423499999, 35.187494874000038],
            [-80.830082234999963, 35.187964874000045],
            [-80.830140235999977, 35.188405874000068],
            [-80.83020023499995, 35.188686874000041],
            [-80.830296235999981, 35.189033874000074],
            [-80.830355234999956, 35.189221874000054],
            [-80.83031523599999, 35.189250874000038],
            [-80.829989234999971, 35.189867874000072],
            [-80.829640235999989, 35.190581874000031],
            [-80.82999723599994, 35.190942874000029],
            [-80.830107235999947, 35.191043874000059],
            [-80.830240235999952, 35.19114787400008],
            [-80.830415235999965, 35.191268874000059],
            [-80.830639235999968, 35.191397874000074],
            [-80.830823235999958, 35.191483874000028],
            [-80.831060236999974, 35.191575873000033],
            [-80.831466235999983, 35.191735874000074],
            [-80.831563236999955, 35.191780874000074],
            [-80.831869236999978, 35.191941875000055],
            [-80.832101236999961, 35.19207487400007],
            [-80.832198236999943, 35.192125874000055],
            [-80.832348235999973, 35.192196875000036],
            [-80.832651236999936, 35.19231887400008],
            [-80.833158236999964, 35.192500874000075],
            [-80.833315236999965, 35.192563875000076],
            [-80.83365123699997, 35.192711875000043],
            [-80.833739236999975, 35.192757874000051],
            [-80.833896237999966, 35.192849874000046],
            [-80.833999236999944, 35.19291987500003],
            [-80.834141237999972, 35.193030875000034],
            [-80.834231237999973, 35.193118874000049],
            [-80.834377236999956, 35.193288874000075],
            [-80.834571237999967, 35.193496875000051],
            [-80.83464923799994, 35.193567874000053],
            [-80.834846236999965, 35.193721874000062],
            [-80.835580236999988, 35.194273874000032],
            [-80.835678236999968, 35.194339875000026],
            [-80.835925238999948, 35.194490875000042],
            [-80.836421237999957, 35.194773874000077],
            [-80.836534237999956, 35.194837874000029],
            [-80.836595237999973, 35.194838875000073],
            [-80.836656238999979, 35.194842875000063],
            [-80.836701237999989, 35.194848875000048],
            [-80.83677623799997, 35.194862875000069],
            [-80.836834237999938, 35.194877875000032],
            [-80.836918237999953, 35.194905875000075],
            [-80.836987238999939, 35.194935875000056],
            [-80.837039238999978, 35.194962875000044],
            [-80.837088237999978, 35.194992874000036],
            [-80.837134238999965, 35.195024875000058],
            [-80.837189237999951, 35.195068874000071],
            [-80.837229238999953, 35.195106875000079],
            [-80.837263237999935, 35.195143875000042],
            [-80.837757238999984, 35.195641875000035],
            [-80.838361238999937, 35.19624787500004],
            [-80.838396237999973, 35.196282875000065],
            [-80.838494238999942, 35.196209875000079],
            [-80.838605237999957, 35.196129875000054],
            [-80.83867523899994, 35.196084875000054],
            [-80.838735238999959, 35.196027875000027],
            [-80.838845238999966, 35.195910875000038],
            [-80.838943238999946, 35.195793875000049],
            [-80.839153238999984, 35.195601875000079],
            [-80.839295238999966, 35.195500874000061],
            [-80.839715238999986, 35.19518187500006],
            [-80.84001023899998, 35.194949874000031],
            [-80.840094238999939, 35.194875874000047],
            [-80.840241238999965, 35.194757874000061],
            [-80.840371239999968, 35.194678874000033],
            [-80.840425238999956, 35.194619874000068],
            [-80.84043423899999, 35.194589875000077],
            [-80.840474238999946, 35.194523875000073],
            [-80.840585239999939, 35.194385874000034],
            [-80.840651238999953, 35.194287874000054],
            [-80.840810239999939, 35.194109874000048],
            [-80.840870238999969, 35.194064874000048],
            [-80.840928238999936, 35.194011874000068],
            [-80.840977239999972, 35.193946874000062],
            [-80.841127239999935, 35.193799874000035],
            [-80.841302238999958, 35.193591874000049],
            [-80.841354239999987, 35.193523875000039],
            [-80.841479238999966, 35.193397874000027],
            [-80.84154123999997, 35.193307875000073],
            [-80.841783239999984, 35.192987874000039],
            [-80.841844239999944, 35.192891875000043],
            [-80.841911239999945, 35.192810874000031],
            [-80.842007239999987, 35.192672874000039],
            [-80.842141239999989, 35.192459874000065],
            [-80.842227239999943, 35.192382874000032],
            [-80.842267238999966, 35.192332874000044],
            [-80.842322239999987, 35.192245873000047],
            [-80.842389238999942, 35.19213187400004],
            [-80.842426239999952, 35.192083874000048],
            [-80.842529239999976, 35.191989874000058],
            [-80.842661238999938, 35.191854874000057],
            [-80.842686239999978, 35.19182087400003],
            [-80.842907239999988, 35.191517873000066],
            [-80.843003240999963, 35.191647874000068],
            [-80.843114239999977, 35.191656874000046],
            [-80.843189239999958, 35.191687874000024],
            [-80.843351239999947, 35.191858874000047],
            [-80.843530240999939, 35.191940874000068],
            [-80.843647239999939, 35.192010874000061],
            [-80.843679239999972, 35.192005873000028],
            [-80.843734239999947, 35.192036874000053],
            [-80.84375523999995, 35.192081873000063],
            [-80.843813239999974, 35.192109874000039],
            [-80.843930239999963, 35.192129874000045],
            [-80.843972239999971, 35.192223874000035],
            [-80.844151239999974, 35.192296873000032],
            [-80.844191240999976, 35.192375874000049],
            [-80.84426123999998, 35.192476874000079],
            [-80.844426240999951, 35.192601874000047],
            [-80.844475240999941, 35.192612874000076],
            [-80.844519240999944, 35.192623874000049],
            [-80.844597240999974, 35.192615874000069],
            [-80.844657240999936, 35.192644874000052],
            [-80.844676240999945, 35.192704875000061],
            [-80.844712240999968, 35.192771873000027],
            [-80.84478323999997, 35.192861873000027],
            [-80.844757240999968, 35.192932874000064],
            [-80.844870239999977, 35.193014874000028],
            [-80.84500423999998, 35.19310687400008],
            [-80.845033240999953, 35.193121874000042],
            [-80.84509824099996, 35.193146874000035],
            [-80.845159239999987, 35.193160874000057],
            [-80.845204240999976, 35.193164874000047],
            [-80.845310239999947, 35.193160874000057],
            [-80.845928240999967, 35.19338487400006],
            [-80.846207239999956, 35.193414874000041],
            [-80.84634024199994, 35.193455874000051],
            [-80.84646123999994, 35.193540874000064],
            [-80.846873240999969, 35.193678875000046],
            [-80.847059241999943, 35.193710874000033],
            [-80.847213241999953, 35.193769874000054],
            [-80.847385241999973, 35.193961874000024],
            [-80.847468241999934, 35.194046875000026],
            [-80.847619240999961, 35.194143874000076],
            [-80.847756241999946, 35.194194874000061],
            [-80.847952241999963, 35.194420874000059],
            [-80.848056241999984, 35.194511875000046],
            [-80.848143240999946, 35.194907875000069],
            [-80.848242241999969, 35.195077874000049],
            [-80.848497240999961, 35.195405875000063],
            [-80.84869224199997, 35.195524874000057],
            [-80.848818241999936, 35.195631874000071],
            [-80.848905241999944, 35.195705875000044],
            [-80.849123241999962, 35.195856874000071],
            [-80.849154240999951, 35.195899875000066],
            [-80.849262241999952, 35.195978875000037],
            [-80.84930424199996, 35.196024874000045],
            [-80.849322241999971, 35.196058875000062],
            [-80.849331241999948, 35.196104874000071],
            [-80.849383242999977, 35.196195875000058],
            [-80.849421241999949, 35.196250875000032],
            [-80.849454242999968, 35.196308875000057],
            [-80.849497241999984, 35.196314874000052],
            [-80.849551241999961, 35.196310875000052],
            [-80.849603241999944, 35.196320874000037],
            [-80.849648241999944, 35.196345875000077],
            [-80.849779241999954, 35.196360875000039],
            [-80.850050241999952, 35.196391874000028],
            [-80.850182242999949, 35.196303875000069],
            [-80.850691241999982, 35.196012875000065],
            [-80.850972242999944, 35.195851874000027],
            [-80.851061242999947, 35.195781874000033],
            [-80.851226241999939, 35.195889874000045],
            [-80.851310242999944, 35.195962874000031],
            [-80.851358242999936, 35.196021874000053],
            [-80.85137924299994, 35.196079874000077],
            [-80.851531242999954, 35.196646874000066],
            [-80.851706241999977, 35.196611874000041],
            [-80.851842241999975, 35.196595875000071],
            [-80.851980241999968, 35.196597875000066],
            [-80.852116242999955, 35.196615874000031],
            [-80.852182242999959, 35.196631874000047],
            [-80.852317242999959, 35.196676875000037],
            [-80.852381242999968, 35.196705874000031],
            [-80.852617242999941, 35.196838875000026],
            [-80.853088243999935, 35.197113874000024],
            [-80.853475243999981, 35.197339874000079],
            [-80.853885243999969, 35.197577875000036],
            [-80.853949243999978, 35.197620874000052],
            [-80.85400924299995, 35.197667875000036],
            [-80.854118242999959, 35.19777087500006],
            [-80.855339242999946, 35.198230874000046],
            [-80.856876243999977, 35.198307874000079],
            [-80.85940424599994, 35.197097874000065],
            [-80.863310245999969, 35.195229873000073],
            [-80.863758245999975, 35.195603874000028]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 58,
        "SolutionID": "PI-20",
        "Shape_Length": 0.56334187918638345,
        "Shape_Area": 0.0070391029187247519
      }
    },
    {
      "type": "Feature",
      "id": 59,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.973200279999958, 35.203534871000045],
            [-80.973377277999987, 35.203538872000024],
            [-80.973579278999978, 35.203528872000049],
            [-80.973694279999961, 35.203508872000043],
            [-80.973797279999985, 35.203478872000062],
            [-80.97390527899995, 35.203437872000052],
            [-80.97400227899999, 35.203385872000069],
            [-80.974147279999954, 35.203286872000035],
            [-80.974296278999986, 35.203148872000043],
            [-80.974393278999969, 35.203044871000031],
            [-80.974604279999937, 35.202799872000071],
            [-80.975083279999978, 35.202207871000041],
            [-80.976735279999957, 35.200204871000039],
            [-80.977002279999965, 35.199891871000034],
            [-80.977156280999964, 35.199730871000042],
            [-80.977413279999951, 35.199496870000075],
            [-80.977638279999951, 35.199317870000073],
            [-80.977791280999952, 35.199207870000066],
            [-80.977998279999952, 35.199081871000033],
            [-80.97822027999996, 35.198968870000044],
            [-80.979358280999975, 35.198437870000078],
            [-80.980368280999983, 35.197976870000048],
            [-80.980608280999945, 35.197859870000059],
            [-80.980732280999973, 35.197792870000058],
            [-80.980839280999987, 35.197727870000051],
            [-80.981013280999946, 35.197612870000057],
            [-80.981193281999936, 35.197466869000039],
            [-80.981354280999938, 35.197310870000024],
            [-80.981531280999945, 35.197121869000057],
            [-80.981663281999943, 35.196952870000075],
            [-80.981802280999943, 35.196734870000057],
            [-80.982626281999956, 35.195294870000055],
            [-80.983124282999938, 35.194390869000074],
            [-80.983286281999938, 35.194117869000024],
            [-80.983360281999978, 35.19391286900003],
            [-80.983487280999952, 35.19362686900007],
            [-80.983628281999984, 35.193238869000027],
            [-80.983804282999984, 35.19283486900008],
            [-80.98401628199997, 35.19238186900003],
            [-80.984750280999947, 35.191110868000067],
            [-80.984911282999974, 35.19085786900007],
            [-80.985076282999955, 35.190620868000053],
            [-80.985163281999974, 35.190516869000078],
            [-80.985303281999961, 35.190370868000059],
            [-80.985608281999987, 35.190106868000044],
            [-80.985961281999948, 35.189818869000078],
            [-80.98639228199994, 35.189479868000035],
            [-80.986745281999958, 35.189195867000024],
            [-80.987261281999963, 35.18877186800006],
            [-80.988205282999957, 35.187994867000043],
            [-80.988690282999983, 35.18758286700006],
            [-80.988978281999948, 35.187301868000077],
            [-80.989149282999961, 35.187107867000066],
            [-80.989339283999982, 35.186867868000036],
            [-80.989857282999935, 35.186124867000046],
            [-80.990416283999934, 35.185294867000039],
            [-80.990584282999976, 35.185021867000046],
            [-80.990676282999971, 35.184833867000066],
            [-80.990734282999938, 35.184686868000028],
            [-80.990784283999972, 35.184536867000077],
            [-80.990863283999943, 35.184227866000072],
            [-80.990899282999976, 35.184018867000077],
            [-80.990965283999969, 35.183644867000055],
            [-80.991111282999952, 35.182871866000028],
            [-80.991220282999961, 35.182304867000028],
            [-80.991522283999984, 35.180483866000031],
            [-80.991537282999957, 35.180294866000054],
            [-80.991538282999954, 35.180248866000056],
            [-80.991527282999982, 35.180098866000037],
            [-80.99150128399998, 35.179945866000025],
            [-80.991467282999963, 35.179802865000056],
            [-80.991380282999955, 35.179513865000047],
            [-80.991127282999969, 35.178819866000026],
            [-80.991046282999946, 35.178565866000042],
            [-80.991013282999972, 35.178416866000077],
            [-80.990998282999954, 35.178289866000057],
            [-80.990999282999951, 35.178194866000069],
            [-80.991009282999983, 35.178087865000066],
            [-80.991017283999952, 35.17803686600007],
            [-80.991030282999986, 35.177959866000037],
            [-80.991062283999952, 35.177815865000071],
            [-80.991105282999968, 35.177663865000056],
            [-80.991165282999987, 35.177476865000074],
            [-80.991251283999986, 35.177231865000067],
            [-80.99137428399996, 35.176933865000024],
            [-80.991456282999934, 35.176712866000059],
            [-80.991495282999949, 35.17657386500008],
            [-80.991511282999966, 35.176489865000065],
            [-80.991519282999946, 35.176411865000034],
            [-80.99151728399994, 35.176318865000042],
            [-80.991446282999959, 35.175761865000027],
            [-80.991399282999964, 35.175430864000077],
            [-80.991367282999988, 35.175289865000025],
            [-80.99132928299997, 35.175168865000046],
            [-80.99128428299997, 35.175078864000056],
            [-80.991222281999967, 35.174979864000079],
            [-80.991143282999985, 35.174880865000034],
            [-80.991000282999948, 35.174742865000042],
            [-80.990841283999941, 35.174608865000039],
            [-80.990683282999953, 35.17448386500007],
            [-80.990900282999974, 35.174288865000051],
            [-80.99091628299999, 35.17427086500004],
            [-80.991004283999985, 35.17417286500006],
            [-80.991111283999942, 35.174002865000034],
            [-80.991209282999989, 35.173730864000049],
            [-80.991236282999978, 35.17357686400004],
            [-80.991319282999939, 35.172868864000066],
            [-80.991312282999957, 35.172691864000058],
            [-80.991282282999975, 35.172347864000074],
            [-80.99125128299994, 35.171912864000035],
            [-80.991225282999949, 35.171779865000076],
            [-80.991182282999944, 35.171662864000041],
            [-80.991113282999947, 35.171541864000062],
            [-80.991040282999961, 35.17144486400008],
            [-80.990921282999977, 35.171330864000026],
            [-80.990810282999973, 35.171251865000045],
            [-80.990572282999949, 35.17111086400007],
            [-80.990177281999934, 35.17098786400004],
            [-80.990068282999971, 35.17093486400006],
            [-80.989959282999962, 35.170870864000051],
            [-80.989836281999942, 35.170790864000026],
            [-80.989390281999988, 35.170458863000079],
            [-80.98908628199996, 35.170215864000056],
            [-80.989025282999989, 35.170145864000062],
            [-80.988957282999934, 35.17004186500003],
            [-80.98880128199994, 35.169781864000072],
            [-80.98851128199999, 35.169387864000043],
            [-80.988428281999973, 35.169246864000058],
            [-80.988404281999976, 35.169153863000076],
            [-80.988395281999942, 35.169064864000063],
            [-80.988424281999983, 35.168967864000024],
            [-80.988477281999963, 35.168872864000036],
            [-80.988551281999946, 35.168768864000072],
            [-80.988663281999948, 35.168650864000028],
            [-80.988691281999934, 35.168632863000028],
            [-80.988876281999978, 35.168510864000041],
            [-80.988974282999948, 35.168465863000051],
            [-80.988976282999943, 35.16857086300007],
            [-80.989049281999939, 35.168538863000038],
            [-80.989114281999946, 35.168564863000029],
            [-80.989192281999976, 35.168628863000038],
            [-80.989257281999983, 35.16865286400008],
            [-80.989361282999937, 35.168675864000079],
            [-80.989514281999959, 35.168665863000058],
            [-80.989581281999961, 35.168645863000052],
            [-80.989665281999976, 35.168601863000049],
            [-80.989760281999963, 35.168532864000042],
            [-80.989791282999988, 35.16851786400008],
            [-80.989829282999949, 35.168508863000056],
            [-80.989865282999972, 35.168509863000054],
            [-80.989904282999987, 35.168519864000075],
            [-80.990264282999988, 35.168646863000049],
            [-80.990392281999959, 35.168653864000078],
            [-80.990449282999975, 35.168610863000026],
            [-80.990462281999953, 35.168591863000074],
            [-80.990602281999941, 35.168629863000035],
            [-80.990975282999955, 35.168732864000049],
            [-80.991261281999982, 35.168504863000067],
            [-80.991373283999962, 35.168414864000056],
            [-80.991713281999978, 35.168327864000048],
            [-80.992002281999987, 35.168382863000033],
            [-80.99259028299997, 35.168636863000074],
            [-80.993156282999962, 35.168989864000025],
            [-80.993700283999942, 35.169243864000066],
            [-80.994310283999937, 35.169470863000072],
            [-80.995098283999937, 35.169652864000057],
            [-80.995753283999989, 35.169770864000043],
            [-80.996363284999973, 35.169788864000054],
            [-80.996962284999938, 35.169688864000079],
            [-80.997351284999979, 35.169470863000072],
            [-80.998072284999978, 35.168844863000061],
            [-80.998372284999959, 35.168391863000068],
            [-80.998483284999963, 35.167846863000079],
            [-80.998749283999985, 35.167030863000036],
            [-80.998982284999954, 35.166486863000046],
            [-80.99965928599994, 35.165596863000076],
            [-81.000103284999966, 35.165324862000034],
            [-81.00108028599999, 35.16502586200005],
            [-81.001923285999965, 35.164925862000075],
            [-81.002789285999938, 35.164798862000055],
            [-81.003131285999984, 35.164789862000077],
            [-81.003943285999981, 35.164672862000032],
            [-81.004368285999988, 35.164655862000075],
            [-81.004295286999934, 35.164123862000054],
            [-81.00416828699997, 35.163645863000056],
            [-81.00414628599998, 35.16350286200003],
            [-81.004038285999968, 35.163198862000058],
            [-81.004010286999971, 35.163176862000057],
            [-81.003968286999964, 35.163173862000065],
            [-81.003929285999959, 35.163182862000042],
            [-81.003491285999985, 35.16349086200006],
            [-81.003449285999977, 35.163440862000073],
            [-81.003375285999937, 35.163376862000064],
            [-81.003001285999972, 35.163108862000058],
            [-81.002696285999946, 35.162889861000053],
            [-81.002535285999954, 35.162764862000074],
            [-81.002423285999953, 35.16264986200008],
            [-81.002349285999969, 35.162554862000036],
            [-81.00226328499997, 35.162416862000043],
            [-81.002091284999949, 35.162122862000047],
            [-81.001964285999975, 35.161886861000028],
            [-81.001920285999972, 35.161813862000031],
            [-81.001886285999944, 35.161758861000067],
            [-81.001721285999963, 35.161519862000034],
            [-81.001635284999963, 35.161425861000055],
            [-81.001510285999984, 35.161307862000058],
            [-81.001428284999974, 35.16123286100003],
            [-81.001285284999938, 35.161118861000034],
            [-81.000376284999959, 35.160446862000072],
            [-80.999666283999943, 35.159926861000031],
            [-80.99943428499995, 35.15976186100005],
            [-80.999117284999954, 35.159547862000068],
            [-80.998460283999975, 35.15911686100003],
            [-80.998348284999963, 35.159049861000028],
            [-80.998254283999984, 35.159003862000077],
            [-80.998180284999989, 35.158980861000032],
            [-80.998103284999956, 35.15896186100008],
            [-80.998032283999976, 35.158950861000051],
            [-80.997765283999968, 35.158933861000037],
            [-80.997311282999988, 35.158934861000034],
            [-80.997043283999972, 35.158942861000071],
            [-80.996858282999938, 35.158957861000033],
            [-80.996686283999964, 35.158978861000037],
            [-80.996508283999958, 35.159006860000034],
            [-80.996170283999959, 35.159072861000027],
            [-80.995443283999975, 35.159239862000049],
            [-80.99530228399999, 35.159268861000044],
            [-80.994948282999985, 35.159337862000029],
            [-80.994623283999942, 35.159389861000079],
            [-80.994249282999988, 35.159442861000059],
            [-80.994049281999935, 35.159461861000068],
            [-80.993764282999962, 35.159481861000074],
            [-80.993258282999989, 35.159507861000066],
            [-80.992967282999984, 35.159512862000042],
            [-80.99267628299998, 35.15950986200005],
            [-80.992233282999962, 35.159479861000079],
            [-80.991962281999974, 35.159448862000033],
            [-80.991767281999955, 35.159414862000062],
            [-80.991595282999981, 35.159379861000048],
            [-80.991150282999968, 35.159269862000031],
            [-80.990565281999977, 35.15911286100004],
            [-80.990333281999938, 35.159042862000035],
            [-80.98973928099997, 35.158845862000078],
            [-80.989565281999944, 35.158786861000067],
            [-80.988595280999959, 35.158455862000039],
            [-80.988349280999955, 35.158374862000073],
            [-80.988328281999941, 35.158367861000045],
            [-80.98746928099996, 35.158086861000072],
            [-80.985365280999986, 35.157384862000072],
            [-80.983882279999989, 35.156879862000039],
            [-80.98536527999994, 35.155597860000057],
            [-80.986323281999944, 35.154782861000058],
            [-80.987484280999979, 35.153781860000038],
            [-80.98881228099998, 35.152635860000032],
            [-80.988904280999975, 35.152545860000032],
            [-80.988967280999987, 35.152477860000033],
            [-80.989031280999939, 35.152382860000046],
            [-80.989072281999938, 35.152284860000066],
            [-80.989092281999945, 35.152195860000063],
            [-80.989096280999945, 35.152097860000026],
            [-80.989087280999968, 35.151937860000032],
            [-80.989078280999934, 35.151782860000026],
            [-80.989084280999975, 35.151713860000029],
            [-80.989101280999989, 35.151633860000061],
            [-80.989143281999986, 35.151533860000029],
            [-80.989202280999962, 35.151442860000031],
            [-80.989302281999983, 35.15134585900006],
            [-80.989494280999963, 35.15118386000006],
            [-80.990072281999971, 35.150713860000053],
            [-80.990716280999948, 35.150189860000069],
            [-80.990807281999935, 35.15012586000006],
            [-80.990879280999934, 35.15008086000006],
            [-80.991066280999974, 35.149982859000033],
            [-80.991157281999961, 35.149940859000026],
            [-80.991623281999978, 35.14976985900006],
            [-80.993163282999944, 35.149237859000038],
            [-80.995045282999968, 35.148574859000064],
            [-80.995525282999949, 35.148380858000053],
            [-80.995650281999986, 35.148330859000055],
            [-80.995983282999987, 35.148201859000039],
            [-80.996171283999956, 35.148127859000056],
            [-80.996523283999977, 35.147971859000052],
            [-80.997820283999943, 35.14740185900007],
            [-80.998388283999986, 35.147162859000048],
            [-80.998700283999938, 35.147045859000059],
            [-80.999220282999943, 35.146881859000075],
            [-80.999561283999981, 35.146802859000047],
            [-80.99986228399996, 35.146763858000043],
            [-81.000486283999976, 35.146714859000042],
            [-81.000807284999951, 35.146678859000076],
            [-81.000985283999967, 35.146640859000058],
            [-81.001119284999959, 35.146597859000053],
            [-81.001244283999938, 35.146546858000079],
            [-81.001424283999938, 35.146453859000076],
            [-81.001554284999941, 35.146362859000078],
            [-81.001644284999941, 35.146289858000046],
            [-81.001733284999943, 35.146207859000071],
            [-81.001852284999984, 35.146089858000039],
            [-81.001926284999968, 35.14601385800006],
            [-81.002028284999938, 35.145906858000046],
            [-81.002076284999987, 35.145855859000051],
            [-81.002000284999951, 35.145801859000073],
            [-81.001979284999948, 35.145793858000047],
            [-81.001962284999934, 35.14578085800008],
            [-81.00189228399995, 35.145722858000056],
            [-81.001868283999954, 35.145692858000075],
            [-81.001841284999955, 35.145658859000037],
            [-81.001804283999945, 35.145591858000046],
            [-81.001794283999971, 35.145542858000056],
            [-81.001791283999978, 35.145496858000058],
            [-81.001815284999964, 35.145438858000034],
            [-81.001856284999974, 35.145325858000035],
            [-81.001958284999944, 35.145168858000034],
            [-81.002097284999934, 35.144989858000031],
            [-81.002130284999964, 35.144931857000074],
            [-81.002105283999981, 35.144842858000061],
            [-81.002064283999971, 35.144797858000061],
            [-81.002030284999989, 35.144768859000067],
            [-81.00199728399997, 35.144746859000065],
            [-81.001874283999939, 35.144658858000071],
            [-81.001803283999948, 35.144598858000052],
            [-81.001717284999984, 35.144537857000046],
            [-81.00156528399998, 35.144402858000035],
            [-81.001502283999969, 35.144342858000073],
            [-81.001452284999971, 35.144295858000078],
            [-81.001328283999953, 35.14416385800007],
            [-81.001219283999944, 35.144030857000075],
            [-81.001158284999974, 35.143928858000038],
            [-81.001077284999951, 35.143827858000066],
            [-81.000954283999988, 35.143663858000025],
            [-81.000747283999942, 35.143368857000041],
            [-81.000523283999939, 35.14304585800005],
            [-81.00045828399999, 35.142951857000071],
            [-81.000383283999952, 35.142839858000059],
            [-81.000298283999939, 35.142743857000028],
            [-81.000132282999971, 35.142537857000036],
            [-81.000009283999987, 35.142383857000027],
            [-80.999883283999964, 35.142247857000029],
            [-80.999784283999986, 35.142151858000034],
            [-80.999671283999987, 35.14204085700004],
            [-80.999500283999964, 35.141871857000069],
            [-80.999292283999978, 35.141682858000024],
            [-80.999102283999946, 35.141518857000051],
            [-80.99881628299994, 35.141310858000054],
            [-80.998686283999973, 35.141218858000059],
            [-80.99855228399997, 35.141146857000024],
            [-80.998346283999979, 35.141054857000029],
            [-80.998100283999975, 35.140964858000075],
            [-80.997907282999961, 35.140883858000052],
            [-80.997636282999963, 35.140748857000062],
            [-80.997527282999954, 35.140678857000069],
            [-80.997270282999978, 35.140813857000069],
            [-80.996684282999979, 35.141092858000036],
            [-80.996678282999937, 35.141080857000077],
            [-80.99664328199998, 35.141009857000029],
            [-80.996629282999947, 35.140945858000066],
            [-80.996652281999957, 35.140887858000042],
            [-80.99671428399995, 35.140784858000075],
            [-80.996758282999963, 35.140740857000026],
            [-80.996816283999976, 35.140690858000028],
            [-80.996819282999979, 35.140607857000077],
            [-80.996792282999934, 35.140556858000025],
            [-80.996605282999951, 35.140540857000076],
            [-80.996535282999957, 35.14050985800003],
            [-80.996465283999953, 35.140469857000028],
            [-80.996389281999939, 35.140443857000037],
            [-80.996309283999949, 35.140393858000039],
            [-80.996165282999982, 35.140277857000058],
            [-80.996031282999979, 35.140157857000077],
            [-80.995979281999951, 35.140132857000026],
            [-80.995825281999942, 35.140130857000031],
            [-80.99575428199995, 35.14010485700004],
            [-80.995674281999982, 35.14010085700005],
            [-80.995594281999956, 35.140101857000047],
            [-80.995447282999976, 35.140093857000068],
            [-80.995361281999976, 35.14008485800008],
            [-80.995286281999938, 35.140089858000067],
            [-80.995266282999978, 35.140099858000042],
            [-80.995151282999984, 35.139980857000069],
            [-80.99509328299996, 35.139855857000043],
            [-80.995059282999989, 35.139716857000053],
            [-80.995050281999966, 35.139620858000058],
            [-80.995047281999973, 35.139593857000079],
            [-80.995099281999956, 35.139486856000076],
            [-80.995599281999944, 35.139535857000055],
            [-80.995816282999954, 35.139662856000029],
            [-80.995919282999978, 35.139643857000067],
            [-80.996063282999955, 35.139602857000057],
            [-80.996283282999968, 35.139563856000052],
            [-80.996482282999978, 35.13954185700004],
            [-80.996688282999969, 35.13953785700005],
            [-80.996868282999969, 35.139523857000029],
            [-80.99694328299995, 35.139546856000038],
            [-80.997011282999949, 35.139595858000064],
            [-80.99713428299998, 35.13950085700003],
            [-80.997298283999953, 35.139396857000065],
            [-80.997377282999935, 35.139308858000049],
            [-80.997452282999973, 35.139257857000075],
            [-80.997508282999945, 35.139227857000037],
            [-80.997534283999983, 35.139166857000077],
            [-80.997541282999975, 35.13903485700007],
            [-80.997549282999955, 35.138925857000061],
            [-80.99754328299997, 35.138855857000067],
            [-80.99756228199999, 35.138691856000037],
            [-80.997579281999947, 35.138643857000034],
            [-80.997609282999974, 35.138598857000034],
            [-80.997661282999957, 35.138534857000025],
            [-80.99769728299998, 35.138458857000046],
            [-80.997721283999965, 35.138397857000029],
            [-80.997756282999944, 35.138343857000052],
            [-80.997856282999976, 35.138289856000029],
            [-80.997944283999971, 35.138273857000058],
            [-80.998029282999937, 35.138228857000058],
            [-80.99805728299998, 35.138182857000061],
            [-80.998189282999988, 35.138088857000071],
            [-80.998361282999952, 35.137906856000029],
            [-80.998386282999945, 35.137909857000068],
            [-80.998428283999942, 35.137926856000036],
            [-80.998330282999973, 35.138031857000044],
            [-80.998211282999989, 35.138175857000078],
            [-80.998195283999962, 35.138283857000033],
            [-80.998373283999967, 35.138145856000051],
            [-80.998422282999968, 35.138105856000038],
            [-80.998471283999947, 35.138069857000062],
            [-80.998529283999972, 35.138073857000052],
            [-80.998574282999982, 35.138114856000072],
            [-80.998979283999972, 35.138647857000024],
            [-81.000386283999944, 35.137829856000053],
            [-81.002630283999963, 35.138692856000034],
            [-81.004470284999968, 35.13774085600005],
            [-81.005633284999988, 35.13557185600007],
            [-81.006634284999961, 35.134798856000032],
            [-81.003779283999961, 35.132147855000028],
            [-81.00333328399995, 35.131712855000046],
            [-81.002831283999967, 35.13123885400006],
            [-81.002203283999961, 35.130662855000026],
            [-81.001639283999964, 35.130119855000032],
            [-81.001144284999953, 35.129652855000074],
            [-81.000007282999945, 35.128617855000073],
            [-80.999079281999968, 35.127769854000064],
            [-80.99899628299994, 35.127693854000029],
            [-80.998573281999938, 35.127327855000033],
            [-80.998134281999967, 35.126956854000071],
            [-80.99788128299997, 35.126753854000071],
            [-80.997569282999962, 35.126488854000058],
            [-80.997444281999947, 35.126374855000051],
            [-80.997358282999983, 35.126284853000072],
            [-80.997278281999968, 35.126179854000043],
            [-80.997210281999969, 35.126062854000054],
            [-80.997141281999973, 35.125909855000032],
            [-80.997106281999947, 35.125815853000063],
            [-80.997073281999974, 35.125704854000048],
            [-80.996987281999964, 35.125312854000072],
            [-80.996946282999943, 35.125059854000028],
            [-80.996836282999936, 35.124463854000055],
            [-80.996783281999967, 35.124138853000034],
            [-80.996725281999943, 35.123649854000064],
            [-80.996668280999984, 35.123342853000054],
            [-80.996659282999985, 35.123288854000066],
            [-80.996559281999964, 35.122940853000046],
            [-80.996519281999952, 35.122827854000036],
            [-80.996474281999951, 35.122724853000079],
            [-80.99640328199996, 35.122593854000058],
            [-80.996365282999989, 35.122537854000029],
            [-80.996212281999988, 35.122350853000057],
            [-80.996127281999975, 35.122256853000067],
            [-80.996034281999982, 35.122173853000049],
            [-80.995913281999947, 35.122076853000067],
            [-80.995606281999983, 35.121851854000056],
            [-80.995535280999945, 35.121790854000039],
            [-80.995483280999963, 35.121736854000062],
            [-80.995442281999942, 35.121676854000043],
            [-80.995408281999971, 35.121614854000029],
            [-80.995354280999948, 35.121454853000046],
            [-80.995003281999971, 35.120029853000062],
            [-80.995007280999971, 35.11999385300004],
            [-80.995027280999977, 35.119952853000029],
            [-80.995314281999981, 35.120105853000041],
            [-80.997256281999967, 35.121148853000079],
            [-80.997688282999945, 35.121372852000036],
            [-80.997950281999977, 35.121484854000073],
            [-80.998235281999939, 35.121583853000061],
            [-80.998466281999981, 35.121644853000078],
            [-80.998703282999941, 35.12169285300007],
            [-80.998846281999988, 35.121715854000058],
            [-80.999084281999956, 35.12174585300005],
            [-80.999758282999949, 35.121814854000036],
            [-81.002567283999952, 35.122081854000044],
            [-81.00383528499998, 35.122201853000035],
            [-81.004973283999959, 35.122310852000055],
            [-81.005339283999945, 35.122334853000041],
            [-81.005708284999969, 35.122336853000036],
            [-81.005978284999969, 35.122325852000074],
            [-81.006274284999961, 35.122295853000026],
            [-81.006644284999936, 35.122236853000061],
            [-81.00953928499996, 35.121673853000061],
            [-81.010808285999985, 35.121417853000025],
            [-81.01119728599997, 35.121342853000044],
            [-81.011676286999943, 35.121257853000031],
            [-81.011746286999937, 35.121234852000043],
            [-81.011776285999986, 35.121219852000024],
            [-81.011843286999976, 35.121167853000031],
            [-81.011352285999976, 35.120982852000054],
            [-81.010935286999938, 35.120812853000075],
            [-81.01063828599996, 35.120670852000046],
            [-81.010511285999939, 35.120600853000042],
            [-81.010262285999943, 35.120446853000033],
            [-81.010119285999963, 35.12034985300005],
            [-81.009878285999946, 35.12016985200006],
            [-81.009636284999942, 35.119978852000031],
            [-81.009098284999936, 35.119543852000049],
            [-81.008549284999958, 35.119093852000049],
            [-81.007782284999962, 35.118464852000045],
            [-81.006984284999987, 35.117824852000069],
            [-81.006536284999981, 35.117465852000066],
            [-81.005935284999964, 35.116967851000027],
            [-81.005774283999983, 35.116822852000041],
            [-81.005602283999963, 35.116651851000029],
            [-81.005451284999936, 35.116474852000067],
            [-81.005326282999988, 35.116305852000039],
            [-81.005311283999959, 35.116284851000046],
            [-81.005244282999968, 35.116185852000058],
            [-81.00512228499997, 35.115990852000039],
            [-81.004982284999983, 35.115739852000047],
            [-81.004854282999986, 35.115448851000053],
            [-81.004789284999958, 35.115260852000063],
            [-81.004738283999984, 35.11507385200008],
            [-81.00467228399998, 35.114709852000033],
            [-81.004449282999985, 35.113187851000077],
            [-81.004432283999961, 35.113068851000037],
            [-81.00440228399998, 35.112704852000036],
            [-81.00440228399998, 35.112604851000071],
            [-81.004412283999955, 35.112442851000026],
            [-81.004435283999953, 35.112270851000062],
            [-81.00445128399997, 35.112188851000042],
            [-81.004518283999971, 35.11194585100003],
            [-81.004573283999946, 35.111807851000037],
            [-81.004613282999969, 35.111706851000065],
            [-81.004640282999958, 35.111653851000028],
            [-81.004700282999977, 35.111537851000037],
            [-81.004810283999973, 35.111355851000042],
            [-81.005259283999976, 35.110684850000041],
            [-81.005475284999989, 35.110372850000033],
            [-81.005560283999955, 35.110243851000064],
            [-81.005790283999943, 35.109896850000041],
            [-81.006067283999982, 35.10949585000003],
            [-81.007452283999953, 35.107426850000024],
            [-81.007936283999982, 35.106713849000073],
            [-81.008052284999962, 35.106533849000073],
            [-81.008173284999941, 35.106303849000028],
            [-81.008285283999953, 35.106019850000052],
            [-81.008335284999987, 35.105842850000045],
            [-81.008340283999985, 35.105816850000053],
            [-81.00836828499996, 35.105677849000074],
            [-81.008381284999984, 35.105589849000069],
            [-81.008394284999952, 35.105340849000072],
            [-81.008385283999985, 35.104959850000057],
            [-81.008331283999951, 35.103512849000026],
            [-81.008317284999976, 35.103193848000046],
            [-81.008308284999941, 35.103088849000073],
            [-81.008294283999987, 35.102919849000045],
            [-81.008258284999954, 35.102677849000031],
            [-81.008204283999987, 35.102408849000028],
            [-81.008052283999973, 35.101751848000049],
            [-81.007955283999934, 35.101361848000067],
            [-81.00764828399997, 35.099961848000078],
            [-81.007576284999971, 35.099681849000035],
            [-81.007535283999971, 35.099542848000056],
            [-81.007435283999939, 35.09927884800004],
            [-81.007301283999936, 35.098977848000061],
            [-81.006613282999979, 35.097577847000025],
            [-81.006018282999946, 35.096339848000071],
            [-81.005854282999962, 35.095997847000035],
            [-81.005163283999934, 35.094582847000027],
            [-81.005054281999946, 35.094386847000067],
            [-81.004978282999957, 35.094218848000025],
            [-81.00493628199996, 35.094083847000036],
            [-81.004918281999949, 35.093952846000036],
            [-81.004926282999975, 35.093835847000037],
            [-81.004947282999979, 35.093731847000072],
            [-81.004986282999937, 35.093633847000035],
            [-81.005041282999969, 35.093532847000063],
            [-81.00514928299998, 35.093404846000055],
            [-81.005299283999989, 35.093276847000027],
            [-81.005658281999956, 35.092988847000072],
            [-81.005830282999966, 35.092840846000058],
            [-81.00595328299994, 35.092709847000037],
            [-81.006050282999979, 35.092592847000049],
            [-81.006120282999973, 35.092493846000025],
            [-81.006278282999972, 35.092233846000056],
            [-81.006462283999952, 35.091790846000038],
            [-81.006990283999983, 35.090421846000027],
            [-81.007379283999967, 35.08946684600005],
            [-81.00755828399997, 35.088980846000027],
            [-81.00761128299996, 35.088883845000055],
            [-81.007557282999983, 35.088825845000031],
            [-81.007469282999978, 35.088699846000054],
            [-81.006809282999939, 35.087657845000024],
            [-81.006568281999989, 35.087265845000047],
            [-81.004596281999966, 35.08406384400007],
            [-81.004449281999939, 35.083831845000077],
            [-81.004376281999953, 35.083857844000079],
            [-81.004269281999939, 35.083873844000038],
            [-81.00404628199999, 35.083869844000048],
            [-81.003144280999948, 35.083810844000027],
            [-81.002668281999945, 35.083786845000077],
            [-81.002121280999972, 35.083770844000071],
            [-81.001378281999962, 35.083755845000042],
            [-81.000652280999986, 35.083747845000062],
            [-81.000531280999951, 35.083751844000062],
            [-81.000331279999955, 35.083767845000068],
            [-81.000093279999987, 35.083811845000071],
            [-80.999028279999948, 35.084103845000072],
            [-80.998537279999937, 35.08424384500006],
            [-80.998271280999973, 35.084334845000058],
            [-80.998177280999982, 35.084372845000075],
            [-80.997987279999961, 35.084460845000024],
            [-80.997814279999943, 35.084554845000071],
            [-80.997119279999936, 35.084944845000052],
            [-80.996377278999944, 35.085362846000066],
            [-80.995933279999974, 35.085612845000071],
            [-80.995579279999959, 35.085801846000038],
            [-80.995277278999936, 35.08595884500005],
            [-80.995074279999983, 35.08605284500004],
            [-80.994942279999975, 35.086106846000064],
            [-80.994703278999964, 35.086189845000035],
            [-80.994559278999986, 35.086226845000056],
            [-80.99450627899995, 35.086240846000067],
            [-80.994314279999969, 35.086273845000051],
            [-80.994142278999959, 35.086291846000051],
            [-80.992358278999973, 35.086443845000076],
            [-80.991758277999963, 35.086443846000066],
            [-80.991121278999969, 35.086468846000059],
            [-80.990679277999959, 35.086480846000029],
            [-80.990253277999955, 35.086478846000034],
            [-80.989957277999963, 35.086463846000072],
            [-80.989657277999981, 35.086435846000029],
            [-80.989217277999956, 35.086387846000036],
            [-80.988907277999942, 35.086349845000029],
            [-80.988696277999964, 35.086323846000028],
            [-80.987782276999951, 35.086203846000046],
            [-80.98695627699999, 35.086088846000052],
            [-80.986611276999952, 35.086049846000037],
            [-80.986384276999956, 35.086032845000034],
            [-80.986206276999951, 35.086018846000059],
            [-80.986107277999963, 35.086013846000071],
            [-80.985759276999943, 35.085997846000055],
            [-80.98475127599994, 35.085949846000062],
            [-80.983787276999976, 35.085894847000077],
            [-80.983556275999945, 35.085883846000058],
            [-80.983240275999947, 35.085868846000039],
            [-80.982855274999963, 35.08586084600006],
            [-80.982749275999936, 35.085858846000065],
            [-80.982600275999971, 35.08586084600006],
            [-80.98162627499994, 35.085873846000027],
            [-80.980192274999979, 35.085891846000038],
            [-80.97920127499998, 35.085921846000076],
            [-80.977797274999944, 35.08597284700005],
            [-80.976396273999967, 35.086022846000049],
            [-80.976218273999962, 35.086026847000028],
            [-80.976027273999989, 35.086024847000033],
            [-80.975935272999948, 35.086012846000074],
            [-80.975811273999966, 35.085978846000046],
            [-80.975692273999982, 35.085929846000056],
            [-80.975625272999935, 35.085893846000033],
            [-80.975557273999982, 35.085849846000031],
            [-80.975019273999976, 35.085443847000079],
            [-80.974728272999982, 35.085238846000038],
            [-80.974556272999962, 35.085138846000063],
            [-80.974433272999988, 35.085078846000044],
            [-80.974289273999943, 35.085024846000067],
            [-80.974091272999942, 35.08497184600003],
            [-80.972702271999935, 35.084673846000044],
            [-80.972748272999979, 35.084487846000059],
            [-80.972806272999946, 35.084137846000033],
            [-80.977656273999969, 35.081346846000031],
            [-80.97842527499995, 35.08090384500008],
            [-80.978360273999954, 35.080898845000036],
            [-80.978174273999969, 35.080874845000039],
            [-80.978013273999977, 35.080860845000075],
            [-80.977654273999974, 35.080856846000074],
            [-80.977500273999965, 35.080879845000027],
            [-80.977392273999953, 35.080891845000053],
            [-80.97727327399997, 35.080897845000038],
            [-80.977112273999978, 35.08091184500006],
            [-80.977014273999941, 35.080955845000062],
            [-80.976626273999955, 35.080994845000077],
            [-80.976304272999982, 35.081035845000031],
            [-80.976232272999937, 35.081044845000065],
            [-80.975256272999957, 35.081027845000051],
            [-80.975022272999979, 35.080995845000075],
            [-80.974787272999947, 35.080934845000058],
            [-80.974547271999938, 35.080951845000072],
            [-80.974340272999939, 35.081039845000078],
            [-80.973985272999982, 35.081253846000038],
            [-80.973634272999959, 35.081370846000027],
            [-80.973571272999948, 35.081391845000041],
            [-80.973330272999988, 35.081374845000028],
            [-80.973163272999955, 35.081303845000036],
            [-80.973069272999965, 35.081166846000031],
            [-80.972969271999943, 35.080913845000055],
            [-80.972908271999984, 35.080621846000042],
            [-80.97289527199996, 35.080402845000037],
            [-80.972821272999965, 35.080264845000045],
            [-80.972687271999973, 35.080055845000061],
            [-80.972206271999937, 35.079632846000038],
            [-80.972107272999949, 35.079577845000074],
            [-80.972007272999974, 35.079522845000042],
            [-80.971938271999989, 35.079484845000025],
            [-80.971664271999941, 35.079314845000056],
            [-80.971477271999959, 35.07917184400003],
            [-80.971330271999989, 35.079022845000054],
            [-80.97124327299997, 35.078868845000045],
            [-80.971142271999952, 35.078769845000068],
            [-80.970903271999987, 35.078673845000026],
            [-80.970814270999938, 35.078638845000057],
            [-80.970661271999973, 35.078489844000046],
            [-80.970594270999982, 35.078341845000068],
            [-80.970513270999959, 35.077995845000032],
            [-80.970366271999978, 35.077841844000034],
            [-80.970219271999952, 35.077786844000059],
            [-80.969651270999975, 35.07770384500003],
            [-80.969423270999982, 35.07761684500008],
            [-80.969203270999969, 35.077506845000073],
            [-80.968728270999975, 35.077324844000032],
            [-80.968540270999938, 35.077215845000069],
            [-80.96838027199999, 35.077105844000073],
            [-80.968226270999935, 35.076929844000063],
            [-80.968139270999984, 35.076670845000024],
            [-80.968206270999985, 35.076368844000058],
            [-80.968192269999975, 35.076148844000045],
            [-80.968192269999975, 35.075879844000042],
            [-80.968226271999981, 35.075549844000079],
            [-80.968185270999982, 35.075324845000068],
            [-80.968165270999975, 35.075241844000061],
            [-80.968112269999949, 35.075109845000043],
            [-80.968005270999981, 35.074911844000042],
            [-80.967804270999977, 35.074703844000055],
            [-80.967664269999943, 35.07447284400007],
            [-80.967503270999941, 35.074246844000072],
            [-80.967262270999981, 35.074010844000043],
            [-80.966827269999953, 35.073686844000065],
            [-80.966312269999946, 35.073351844000058],
            [-80.966052269999977, 35.073153844000046],
            [-80.965657269999951, 35.072862844000042],
            [-80.965316270999949, 35.072680844000047],
            [-80.965055268999947, 35.072598844000026],
            [-80.964714269999945, 35.072554844000024],
            [-80.964400269999942, 35.072543844000052],
            [-80.964039268999954, 35.072549844000037],
            [-80.963430268999957, 35.072609844000056],
            [-80.963243268999975, 35.072587844000054],
            [-80.963022268999964, 35.072500844000047],
            [-80.962755268999956, 35.072313844000064],
            [-80.962414269999954, 35.072032843000045],
            [-80.96208626899994, 35.071670844000039],
            [-80.961819268999989, 35.071329843000058],
            [-80.961498268999947, 35.070873844000062],
            [-80.961270267999964, 35.070515843000067],
            [-80.96092226899998, 35.070087843000067],
            [-80.960595268999953, 35.069653843000026],
            [-80.959812267999951, 35.068575844000065],
            [-80.95966526899997, 35.068355843000063],
            [-80.959457267999937, 35.06822984300004],
            [-80.959384266999962, 35.068185844000027],
            [-80.958943267999985, 35.067998843000055],
            [-80.95866826799994, 35.067817843000057],
            [-80.958301266999968, 35.067504843000052],
            [-80.957859266999947, 35.067141842000069],
            [-80.957572266999989, 35.066949843000032],
            [-80.956910266999955, 35.066569842000035],
            [-80.956428266999978, 35.066262843000061],
            [-80.95630826699994, 35.066190843000072],
            [-80.955833266999946, 35.065877843000067],
            [-80.955399265999972, 35.065701843000056],
            [-80.954416265999953, 35.065251843000055],
            [-80.953974265999989, 35.064976842000078],
            [-80.953788265999947, 35.064888843000062],
            [-80.953764265999951, 35.064917843000046],
            [-80.951581265999948, 35.067502844000046],
            [-80.950209263999966, 35.069366844000058],
            [-80.949195263999968, 35.070717844000058],
            [-80.94872926499994, 35.071338845000071],
            [-80.945504264999954, 35.075655845000028],
            [-80.94433526499995, 35.077262846000053],
            [-80.94401226399998, 35.077705845000025],
            [-80.943672264999975, 35.078112846000067],
            [-80.943050263999964, 35.078883846000053],
            [-80.941912263999939, 35.080391847000044],
            [-80.940955262999978, 35.081690847000061],
            [-80.940404261999959, 35.08246884600004],
            [-80.93980226299999, 35.083316848000038],
            [-80.93833626299994, 35.085304847000032],
            [-80.937341261999961, 35.086628848000032],
            [-80.937166261999948, 35.086857848000079],
            [-80.937153261999981, 35.086873848000039],
            [-80.936255262999964, 35.088041848000046],
            [-80.935204261999957, 35.089504849000036],
            [-80.93420426199998, 35.090785849000042],
            [-80.933658261999938, 35.091616849000047],
            [-80.933445260999974, 35.091952850000041],
            [-80.933152261999965, 35.092635850000079],
            [-80.932853260999934, 35.093496850000065],
            [-80.932723261999968, 35.093871849000038],
            [-80.932478261999961, 35.095227850000072],
            [-80.93228826099994, 35.096596850000026],
            [-80.932139260999975, 35.097968850000029],
            [-80.931967261999944, 35.099375851000048],
            [-80.931797260999986, 35.100652851000063],
            [-80.931789261999938, 35.100714851000077],
            [-80.931746260999944, 35.100960851000025],
            [-80.931062261999955, 35.103240852000056],
            [-80.931147261999968, 35.103332851000062],
            [-80.931237261999968, 35.103429852000033],
            [-80.93116226099994, 35.10367085200005],
            [-80.930769260999966, 35.10464685200003],
            [-80.930326261999937, 35.105568852000033],
            [-80.930261260999941, 35.105685852000079],
            [-80.930094261999955, 35.10598585200006],
            [-80.928914259999942, 35.107856853000044],
            [-80.928455260999954, 35.108583852000038],
            [-80.927860259999989, 35.109572853000032],
            [-80.927636260999975, 35.109910853000031],
            [-80.927375260999952, 35.110335853000038],
            [-80.927095260999977, 35.110769854000068],
            [-80.92684226099999, 35.111139854000044],
            [-80.926421259999984, 35.111734854000076],
            [-80.926207259999956, 35.112129854000045],
            [-80.925346259999969, 35.113436854000042],
            [-80.924321260999989, 35.114891855000053],
            [-80.924448258999973, 35.114955854000073],
            [-80.924926260999939, 35.115217855000026],
            [-80.925855259999935, 35.115687855000033],
            [-80.926225259999967, 35.11585085400003],
            [-80.926477259999956, 35.115978855000037],
            [-80.92703026099997, 35.116215854000075],
            [-80.926151259999983, 35.117638855000052],
            [-80.925591259999976, 35.117942855000024],
            [-80.925399259999949, 35.118038855000066],
            [-80.925369260999958, 35.118314855000051],
            [-80.925371260999952, 35.118368855000028],
            [-80.925366259999976, 35.118409855000039],
            [-80.92535626099999, 35.118446855000059],
            [-80.925343259999977, 35.118475856000032],
            [-80.925149259999955, 35.11878785600004],
            [-80.924423260999959, 35.119914855000047],
            [-80.924088259999962, 35.120448856000053],
            [-80.92422425999996, 35.120509856000069],
            [-80.924330258999987, 35.120556855000075],
            [-80.925054260999957, 35.120870856000067],
            [-80.925799260999952, 35.121182856000075],
            [-80.925978259999965, 35.121257856000057],
            [-80.926403260999962, 35.121450856000024],
            [-80.926587261999941, 35.121545856000068],
            [-80.926823261999971, 35.121688856000048],
            [-80.926984259999983, 35.121806856000035],
            [-80.927142260999972, 35.121944856000027],
            [-80.927244261999988, 35.122043856000062],
            [-80.92737226099996, 35.122189856000034],
            [-80.927471260999937, 35.122321856000042],
            [-80.927561261999983, 35.122461856000029],
            [-80.927588260999983, 35.122516856000061],
            [-80.92794926199997, 35.123170856000058],
            [-80.928210260999947, 35.123640856000065],
            [-80.929135261999988, 35.125349856000071],
            [-80.929356262999988, 35.125736857000049],
            [-80.929477261999978, 35.125939856000059],
            [-80.929698261999988, 35.126345856000057],
            [-80.930038261999982, 35.12694885600007],
            [-80.930370262999986, 35.127549857000076],
            [-80.930738261999977, 35.128242857000032],
            [-80.931406262999985, 35.129450857000052],
            [-80.93151026299995, 35.129631857000049],
            [-80.931552262999958, 35.129705858000079],
            [-80.93202026299997, 35.130569858000058],
            [-80.932140262999951, 35.130785858000024],
            [-80.932361262999962, 35.131222857000068],
            [-80.932390262999945, 35.131262858000071],
            [-80.932415262999939, 35.131281858000079],
            [-80.932444262999979, 35.131290857000067],
            [-80.931495262999988, 35.131622858000071],
            [-80.924902260999943, 35.133981858000027],
            [-80.925713261999988, 35.135429858000066],
            [-80.926146261999975, 35.13612985900005],
            [-80.926227260999951, 35.136259859000063],
            [-80.92677226099994, 35.137033859000042],
            [-80.927027261999967, 35.137360859000069],
            [-80.927295260999983, 35.137691859000029],
            [-80.927530261999948, 35.137962860000073],
            [-80.927753261999953, 35.138202859000046],
            [-80.92868426299998, 35.139148859000045],
            [-80.928969262999942, 35.139418859000045],
            [-80.929300262999959, 35.139707860000044],
            [-80.929602262999936, 35.139942860000076],
            [-80.929771262999964, 35.140074859000038],
            [-80.930476262999946, 35.140588860000037],
            [-80.931834263999974, 35.141499860000067],
            [-80.931568262999974, 35.141695860000027],
            [-80.931397263999941, 35.141826859000048],
            [-80.930721262999953, 35.142357860000061],
            [-80.930622262999975, 35.14243786000003],
            [-80.930593262999935, 35.142460861000075],
            [-80.930102261999934, 35.142848860000072],
            [-80.929307262999941, 35.143476860000078],
            [-80.929227262999973, 35.143551860000059],
            [-80.92896226299996, 35.143754860000058],
            [-80.928466262999962, 35.144145861000027],
            [-80.928184262999935, 35.14436986000004],
            [-80.927977262999946, 35.144547861000035],
            [-80.927877262999971, 35.144637860000046],
            [-80.927783261999934, 35.14472186100005],
            [-80.927617261999956, 35.144880861000047],
            [-80.927518261999978, 35.144987861000061],
            [-80.927111262999972, 35.145450861000029],
            [-80.926973262999979, 35.145629860000042],
            [-80.926929261999987, 35.145689860000061],
            [-80.927054262999945, 35.145742861000031],
            [-80.927100261999954, 35.145787861000031],
            [-80.927185262999956, 35.145908861000066],
            [-80.927342262999957, 35.146079861000032],
            [-80.927359262999971, 35.14611386100006],
            [-80.927369262999946, 35.146152861000076],
            [-80.927444261999938, 35.146262861000025],
            [-80.927574261999951, 35.146475861000056],
            [-80.927646261999939, 35.146560862000058],
            [-80.927690262999988, 35.146595861000037],
            [-80.927751262999948, 35.146678861000055],
            [-80.927756262999935, 35.146781861000079],
            [-80.927740262999976, 35.146871862000069],
            [-80.927737262999983, 35.14689786100007],
            [-80.927782261999937, 35.147092861000033],
            [-80.927798262999943, 35.147131861000048],
            [-80.927811262999967, 35.147163861000024],
            [-80.927829262999978, 35.147234861000072],
            [-80.927814262999959, 35.147331861000055],
            [-80.927816262999954, 35.14737886100005],
            [-80.927842261999956, 35.147446861000049],
            [-80.927860262999957, 35.147510861000058],
            [-80.927857262999964, 35.147661861000074],
            [-80.927860261999967, 35.14768986200005],
            [-80.927877262999971, 35.147782861000053],
            [-80.927886261999959, 35.147803861000057],
            [-80.927912262999939, 35.147866862000058],
            [-80.927958262999937, 35.14793386100007],
            [-80.92799026299997, 35.147990861000039],
            [-80.928038261999973, 35.148157861000072],
            [-80.928064262999953, 35.148185861000059],
            [-80.928094262999934, 35.148205861000065],
            [-80.928138262999937, 35.148220861000027],
            [-80.928204262999941, 35.148263861000032],
            [-80.928229263999981, 35.148285861000033],
            [-80.928277261999938, 35.14834686100005],
            [-80.928330262999964, 35.148393862000034],
            [-80.928363263999984, 35.148409862000051],
            [-80.928383262999944, 35.148426861000075],
            [-80.928400262999958, 35.148450861000072],
            [-80.928409261999946, 35.148497861000067],
            [-80.928429261999952, 35.14852286100006],
            [-80.92850426199999, 35.148556861000031],
            [-80.928594262999979, 35.14857586100004],
            [-80.928619263999963, 35.148596861000044],
            [-80.928706262999981, 35.148642861000042],
            [-80.928940263999948, 35.148684862000039],
            [-80.92899926299998, 35.148718861000077],
            [-80.92905626299995, 35.148771861000057],
            [-80.92913426299998, 35.148877862000063],
            [-80.929186262999963, 35.14894686100007],
            [-80.929280262999953, 35.149060862000056],
            [-80.929337262999979, 35.149157862000038],
            [-80.929389263999951, 35.14926586100006],
            [-80.929437262999954, 35.149332862000051],
            [-80.929519262999975, 35.149382862000039],
            [-80.929594262999956, 35.149413861000028],
            [-80.929664263999939, 35.149437861000024],
            [-80.929710263999937, 35.149466862000054],
            [-80.929743263999967, 35.149517861000049],
            [-80.929750263999949, 35.149577861000068],
            [-80.92978726299998, 35.149642862000064],
            [-80.929850262999935, 35.14968586100008],
            [-80.929952262999961, 35.149782862000052],
            [-80.930005262999941, 35.149800862000063],
            [-80.930070263999937, 35.149849862000053],
            [-80.930133263999949, 35.149908862000075],
            [-80.930178263999949, 35.149938861000066],
            [-80.930221262999964, 35.150003861000073],
            [-80.930234263999978, 35.150070862000064],
            [-80.930225262999954, 35.150144862000047],
            [-80.930201262999958, 35.15019686100004],
            [-80.930160263999937, 35.150341862000062],
            [-80.93015926399994, 35.150389861000065],
            [-80.930193263999968, 35.150521862000062],
            [-80.930184262999944, 35.150558862000025],
            [-80.93011026299996, 35.150717863000068],
            [-80.93006526299996, 35.150785862000077],
            [-80.930062263999957, 35.15091986200008],
            [-80.930051263999985, 35.150963862000026],
            [-80.929970262999973, 35.151158862000045],
            [-80.929972263999957, 35.151244863000045],
            [-80.930066262999958, 35.151560861000064],
            [-80.930091262999952, 35.15161786200008],
            [-80.930136263999941, 35.151688863000061],
            [-80.930203262999953, 35.151836861000049],
            [-80.930211263999979, 35.151869862000069],
            [-80.930212263999977, 35.151906862000033],
            [-80.930235263999975, 35.151926863000028],
            [-80.930297262999943, 35.151929862000031],
            [-80.930343262999941, 35.151941863000047],
            [-80.930365262999942, 35.151948861000051],
            [-80.930480263999982, 35.151970862000042],
            [-80.930548263999981, 35.151999862000025],
            [-80.930636263999986, 35.152075862000061],
            [-80.930683264999971, 35.15213986200007],
            [-80.930750263999983, 35.152195862000042],
            [-80.930820264999966, 35.152220862000036],
            [-80.930914264999956, 35.152275863000057],
            [-80.930965263999951, 35.152321862000065],
            [-80.931001263999974, 35.152384862000076],
            [-80.931064263999986, 35.152473862000079],
            [-80.931111263999981, 35.15254886300005],
            [-80.931155263999983, 35.152604862000032],
            [-80.931217262999951, 35.152637862000063],
            [-80.931283263999944, 35.152664863000041],
            [-80.931346263999956, 35.152681863000055],
            [-80.931393263999951, 35.152717863000078],
            [-80.93142626499997, 35.152755862000049],
            [-80.931504264999944, 35.152811862000078],
            [-80.931565263999971, 35.152847862000044],
            [-80.931694264999976, 35.152929862000065],
            [-80.931750263999959, 35.152996863000055],
            [-80.931828263999989, 35.153006862000041],
            [-80.931883263999964, 35.152996862000066],
            [-80.932022263999954, 35.152992862000076],
            [-80.932207263999942, 35.153030863000026],
            [-80.932234263999987, 35.153042862000063],
            [-80.932284264999964, 35.153084862000071],
            [-80.932338264999942, 35.153170863000071],
            [-80.93251926399995, 35.153453862000049],
            [-80.932558264999955, 35.153571862000035],
            [-80.932606263999958, 35.153744862000053],
            [-80.932702264999989, 35.154020862000039],
            [-80.932720263999954, 35.154195862000051],
            [-80.932777263999981, 35.154289862000041],
            [-80.93279326399994, 35.154359863000025],
            [-80.932778263999978, 35.154433863000065],
            [-80.932794264999984, 35.154504862000067],
            [-80.932833264999942, 35.154561862000037],
            [-80.932836264999935, 35.154604863000031],
            [-80.932808264999949, 35.154718863000028],
            [-80.932821264999973, 35.154768863000072],
            [-80.932850264999956, 35.15480786300003],
            [-80.932897264999951, 35.154889863000051],
            [-80.932897264999951, 35.154919862000042],
            [-80.932881264999935, 35.154954862000068],
            [-80.93284526399998, 35.154970862000027],
            [-80.932821263999983, 35.155005863000042],
            [-80.93282626499996, 35.15504386300006],
            [-80.932897264999951, 35.155187863000037],
            [-80.932874264999953, 35.155242863000069],
            [-80.932888264999974, 35.155319863000045],
            [-80.932882264999989, 35.155409863000045],
            [-80.932834263999951, 35.155503863000035],
            [-80.932840264999982, 35.155578863000073],
            [-80.932839264999984, 35.155633862000059],
            [-80.93283026499995, 35.155672862000074],
            [-80.932846264999966, 35.155779863000078],
            [-80.932836264999935, 35.155914863000078],
            [-80.932856264999941, 35.156065862000048],
            [-80.932844264999972, 35.156137863000026],
            [-80.932816263999939, 35.156217864000041],
            [-80.932831265999937, 35.156288863000043],
            [-80.93282226499997, 35.156327863000058],
            [-80.932780263999973, 35.156399863000047],
            [-80.93279626399999, 35.15648486300006],
            [-80.932775263999986, 35.15658686300003],
            [-80.932757263999974, 35.156708863000063],
            [-80.932759264999959, 35.156740863000039],
            [-80.932765264999944, 35.156875863000039],
            [-80.932784264999952, 35.156996864000064],
            [-80.932794264999984, 35.157145863000039],
            [-80.932787264999945, 35.157249863000061],
            [-80.932716263999964, 35.157477863000054],
            [-80.932708263999984, 35.157518864000053],
            [-80.932705264999981, 35.157711864000078],
            [-80.932696264999947, 35.157785863000072],
            [-80.932688264999967, 35.157817862000059],
            [-80.932693264999955, 35.157864864000032],
            [-80.932685264999975, 35.157904863000056],
            [-80.93265426499994, 35.157938863000027],
            [-80.932622264999964, 35.158036863000063],
            [-80.93260526399996, 35.158149863000062],
            [-80.932613264999986, 35.158221863000051],
            [-80.932630264999943, 35.158309863000056],
            [-80.932598263999978, 35.158491864000041],
            [-80.932585263999954, 35.158619863000069],
            [-80.932585263999954, 35.158752864000064],
            [-80.932585264999943, 35.158775863000074],
            [-80.932575264999969, 35.158827864000045],
            [-80.932549264999977, 35.158887864000064],
            [-80.93251126499996, 35.158929863000026],
            [-80.93243926599996, 35.159053864000043],
            [-80.932380264999949, 35.159119864000047],
            [-80.932337264999944, 35.15915986300007],
            [-80.932309264999958, 35.159209864000047],
            [-80.932281264999972, 35.159280863000049],
            [-80.93222226499995, 35.159386863000066],
            [-80.932217264999963, 35.159466864000024],
            [-80.932198264999954, 35.159539864000067],
            [-80.932152263999967, 35.159593863000055],
            [-80.932034263999981, 35.159704864000048],
            [-80.931906264999952, 35.159979864000036],
            [-80.931832263999979, 35.160167864000073],
            [-80.931822263999948, 35.160247864000041],
            [-80.931820263999953, 35.160343864000026],
            [-80.931744263999974, 35.160472863000052],
            [-80.931675263999978, 35.160630864000041],
            [-80.931521264999958, 35.160847864000061],
            [-80.931500264999954, 35.160925864000035],
            [-80.931500264999954, 35.161028864000059],
            [-80.931445264999979, 35.161069864000069],
            [-80.931361264999964, 35.161169864000044],
            [-80.931305264999935, 35.161287865000077],
            [-80.931240263999939, 35.161464864000038],
            [-80.93121326499994, 35.161524864000057],
            [-80.931194263999942, 35.161570864000055],
            [-80.931137264999961, 35.161729864000051],
            [-80.931113264999965, 35.161765864000074],
            [-80.931087264999974, 35.161811864000072],
            [-80.931063264999977, 35.161856864000072],
            [-80.931108263999988, 35.162135864000049],
            [-80.931100263999951, 35.162221865000049],
            [-80.931071264999957, 35.162283864000074],
            [-80.930966263999949, 35.16236086400005],
            [-80.930932264999967, 35.162363864000042],
            [-80.930907263999984, 35.162358865000044],
            [-80.93083326499999, 35.16235686400006],
            [-80.930786264999938, 35.162384864000046],
            [-80.930752263999977, 35.162397865000059],
            [-80.930660263999982, 35.162404864000052],
            [-80.930564263999941, 35.162422864000064],
            [-80.93051126499995, 35.162439864000078],
            [-80.930469264999942, 35.162469864000059],
            [-80.93046626499995, 35.162502864000032],
            [-80.930485264999959, 35.162563865000038],
            [-80.930494263999947, 35.162636864000035],
            [-80.930498263999937, 35.162764864000053],
            [-80.930505263999976, 35.162782865000054],
            [-80.930579263999959, 35.162924865000036],
            [-80.930573263999975, 35.163009865000049],
            [-80.930546264999975, 35.163100865000047],
            [-80.930535263999957, 35.163128865000033],
            [-80.930502264999973, 35.163206864000074],
            [-80.930494263999947, 35.163329865000037],
            [-80.930448264999939, 35.16340686500007],
            [-80.930418263999968, 35.163439864000054],
            [-80.930451264999988, 35.163543865000065],
            [-80.930467263999958, 35.163575865000041],
            [-80.930490263999957, 35.163641865000045],
            [-80.930497263999939, 35.163746864000075],
            [-80.930502264999973, 35.163767864000079],
            [-80.93051126499995, 35.163807865000024],
            [-80.930538264999939, 35.163855865000073],
            [-80.930603263999956, 35.164000864000059],
            [-80.930709263999972, 35.164376864000076],
            [-80.930775264999966, 35.164445865000062],
            [-80.930862264999973, 35.164664865000077],
            [-80.930965263999951, 35.164826865000066],
            [-80.931136263999974, 35.165003865000074],
            [-80.931276263999962, 35.165127865000045],
            [-80.931418263999944, 35.165240865000044],
            [-80.931529264999938, 35.165292865000026],
            [-80.931560264999973, 35.165317865000077],
            [-80.931609264999963, 35.165397865000045],
            [-80.931636264999952, 35.165476865000073],
            [-80.931725264999955, 35.165603865000037],
            [-80.931819263999955, 35.165680864000024],
            [-80.931941263999988, 35.165742864000038],
            [-80.931984264999983, 35.165759865000041],
            [-80.93213226499995, 35.165843865000056],
            [-80.932266265999942, 35.165908865000063],
            [-80.932338265999988, 35.165929865000066],
            [-80.932410264999987, 35.16592186500003],
            [-80.932453264999936, 35.165906865000068],
            [-80.932517264999944, 35.165898865000031],
            [-80.932586264999941, 35.165897865000034],
            [-80.932696264999947, 35.165929865000066],
            [-80.932761264999954, 35.16595886500005],
            [-80.932900264999944, 35.16605286500004],
            [-80.932966265999937, 35.166115865000052],
            [-80.933039264999934, 35.166217865000078],
            [-80.933064265999974, 35.16628486500008],
            [-80.933070264999969, 35.166335865000065],
            [-80.933104265999987, 35.166403864000074],
            [-80.933110264999982, 35.166479865000042],
            [-80.933183264999968, 35.16657486500003],
            [-80.933208264999962, 35.166627866000056],
            [-80.933326265999938, 35.166827864000027],
            [-80.933372264999946, 35.166969865000056],
            [-80.933379264999985, 35.167043865000039],
            [-80.93343026499997, 35.167134865000037],
            [-80.933433265999952, 35.167206866000072],
            [-80.933455265999953, 35.167281865000064],
            [-80.933501264999961, 35.167334864000054],
            [-80.93356126499998, 35.167425865000041],
            [-80.933580265999979, 35.167445865000047],
            [-80.933597264999946, 35.167572865000068],
            [-80.933631265999963, 35.167649865000044],
            [-80.933812264999972, 35.168193865000035],
            [-80.933902265999961, 35.168569866000041],
            [-80.933945265999967, 35.168696866000062],
            [-80.933958265999934, 35.168800865000037],
            [-80.934061264999968, 35.168947866000053],
            [-80.934071265999989, 35.169026866000024],
            [-80.934102265999968, 35.16911486500004],
            [-80.93416126599999, 35.169173865000062],
            [-80.934309265999957, 35.169402866000041],
            [-80.934368265999979, 35.169442865000065],
            [-80.93448826599996, 35.16948186500008],
            [-80.934562265999944, 35.169496866000031],
            [-80.934634265999989, 35.169602865000059],
            [-80.934632265999937, 35.169691866000051],
            [-80.934606266999936, 35.169719865000047],
            [-80.934536265999952, 35.169753866000065],
            [-80.934505266999963, 35.169790866000028],
            [-80.93443526599998, 35.169902866000029],
            [-80.934359265999944, 35.16994786600003],
            [-80.934251266999979, 35.169975866000073],
            [-80.934231265999983, 35.170002866000061],
            [-80.934222265999949, 35.17007486600005],
            [-80.934221265999952, 35.170106865000037],
            [-80.934194265999963, 35.170144866000044],
            [-80.934149265999963, 35.170184865000067],
            [-80.934122265999974, 35.170259866000038],
            [-80.93412026499999, 35.170292866000068],
            [-80.934140265999986, 35.170332866000024],
            [-80.934185265999986, 35.170375867000075],
            [-80.934197264999966, 35.170398866000028],
            [-80.934205264999946, 35.17043886600004],
            [-80.934204265999938, 35.170473865000076],
            [-80.934211265999977, 35.170503866000047],
            [-80.934246265999946, 35.170571865000056],
            [-80.934282264999979, 35.170620866000036],
            [-80.934358264999958, 35.170670865000034],
            [-80.934431264999944, 35.170700866000061],
            [-80.934511264999969, 35.170748866000054],
            [-80.934618266999962, 35.170828866000079],
            [-80.934770265999987, 35.170922866000069],
            [-80.935068265999973, 35.171196866000059],
            [-80.935108265999986, 35.171245866000049],
            [-80.935241266999981, 35.171359866000046],
            [-80.935308266999982, 35.171394866000071],
            [-80.935396265999941, 35.171455866000031],
            [-80.93549326699997, 35.171542867000028],
            [-80.935609265999972, 35.171715866000056],
            [-80.935627266999973, 35.171774866000078],
            [-80.935658266999951, 35.171880866000038],
            [-80.935708265999949, 35.172101866000048],
            [-80.935725266999953, 35.172150866000038],
            [-80.935794266999949, 35.17229186600008],
            [-80.935849266999981, 35.172467866000034],
            [-80.935868265999943, 35.172498867000058],
            [-80.935387265999964, 35.172418867000033],
            [-80.935372265999945, 35.172635866000064],
            [-80.935243265999986, 35.172788867000065],
            [-80.935198265999986, 35.172841866000056],
            [-80.935030266999945, 35.172876866000024],
            [-80.934936266999955, 35.172893866000038],
            [-80.934724265999989, 35.173003867000034],
            [-80.934544265999989, 35.173203867000041],
            [-80.934516265999946, 35.173282866000079],
            [-80.934648265999954, 35.173328866000077],
            [-80.934696265999946, 35.173344867000026],
            [-80.935335266999971, 35.173567867000031],
            [-80.93544826599998, 35.173607867000044],
            [-80.935826266999982, 35.173738867000054],
            [-80.935824266999987, 35.173817867000025],
            [-80.935792266999954, 35.173972867000032],
            [-80.935727265999958, 35.174363866000078],
            [-80.93571026699999, 35.174488867000036],
            [-80.935617266999941, 35.174799867000047],
            [-80.93554626699995, 35.175141867000036],
            [-80.935502266999947, 35.175469866000071],
            [-80.935548266999945, 35.175661867000031],
            [-80.935552266999935, 35.175912867000079],
            [-80.935788265999975, 35.176121867000063],
            [-80.936080266999966, 35.176456868000059],
            [-80.936380266999947, 35.176710868000043],
            [-80.936487266999961, 35.177093867000053],
            [-80.936584266999944, 35.177407867000056],
            [-80.936576267999953, 35.177471868000055],
            [-80.936567266999987, 35.177618867000035],
            [-80.936578266999959, 35.177741868000055],
            [-80.936614267999971, 35.177852868000059],
            [-80.93668626699997, 35.17798786700007],
            [-80.936712266999962, 35.178076868000062],
            [-80.936772266999981, 35.178358868000032],
            [-80.936831266999945, 35.178529867000066],
            [-80.936846266999964, 35.178671868000038],
            [-80.936875266999948, 35.178729867000072],
            [-80.936914266999963, 35.178784867000047],
            [-80.936980267999957, 35.179046868000057],
            [-80.937112266999975, 35.179381868000064],
            [-80.937133266999979, 35.179420868000079],
            [-80.937289267999972, 35.179708868000034],
            [-80.93747826699996, 35.180034868000064],
            [-80.937676267999962, 35.18028386800006],
            [-80.937689266999939, 35.180316868000034],
            [-80.937729266999952, 35.180365867000035],
            [-80.937895266999988, 35.180613869000069],
            [-80.937946266999973, 35.180695868000043],
            [-80.938146267999969, 35.180975868000075],
            [-80.938325267999971, 35.181287868000027],
            [-80.938400267999953, 35.181481868000049],
            [-80.938473266999949, 35.181729868000048],
            [-80.938500267999984, 35.181779868000035],
            [-80.938586267999938, 35.181907868000053],
            [-80.938585266999951, 35.181967868000072],
            [-80.938603268999941, 35.182048868000038],
            [-80.938597267999967, 35.182191868000075],
            [-80.938625266999964, 35.18229986800003],
            [-80.938636267999982, 35.182371868000075],
            [-80.938706266999986, 35.182493868000051],
            [-80.938733267999964, 35.182624868000062],
            [-80.938736267999957, 35.182663868000077],
            [-80.93878026799996, 35.182816869000078],
            [-80.938816267999982, 35.182959869000058],
            [-80.938857268999982, 35.183068869000067],
            [-80.938885267999979, 35.183141868000064],
            [-80.939066268999966, 35.183290868000029],
            [-80.939153267999984, 35.183355869000025],
            [-80.939223268999967, 35.183481868000058],
            [-80.939257267999949, 35.183569869000053],
            [-80.939266267999983, 35.18358986800007],
            [-80.939274267999963, 35.183600868000042],
            [-80.939305267999941, 35.183642869000039],
            [-80.939423267999985, 35.183613869000055],
            [-80.94061026899999, 35.183033868000052],
            [-80.941002268999966, 35.182851869000046],
            [-80.942387268999937, 35.182180868000046],
            [-80.943420268999944, 35.181668867000042],
            [-80.944092268999952, 35.181332868000027],
            [-80.944740269999954, 35.181016868000029],
            [-80.945522269999969, 35.180659867000031],
            [-80.945768269999974, 35.180558867000059],
            [-80.94589426999994, 35.180509868000058],
            [-80.946297269999945, 35.180370868000068],
            [-80.946767269999953, 35.180226867000044],
            [-80.94709026999999, 35.18014486800007],
            [-80.947570270999961, 35.180040867000059],
            [-80.947793269999977, 35.179997867000054],
            [-80.948394270999984, 35.179903867000064],
            [-80.948703270999943, 35.179869868000026],
            [-80.948898270999962, 35.179852868000069],
            [-80.949078269999973, 35.17984186700005],
            [-80.949879270999986, 35.179824867000036],
            [-80.950099270999942, 35.179823868000028],
            [-80.950344270999949, 35.17982986800007],
            [-80.95069627099997, 35.179852868000069],
            [-80.951155271999937, 35.179902868000056],
            [-80.951522271999977, 35.179949868000051],
            [-80.952146271999936, 35.180038867000064],
            [-80.952932271999941, 35.180151867000063],
            [-80.953709271999969, 35.180238867000071],
            [-80.953993270999945, 35.180274867000037],
            [-80.954908271999955, 35.180391867000026],
            [-80.955670272999953, 35.180494868000039],
            [-80.95614427299995, 35.180552867000074],
            [-80.95668027399995, 35.180609867000044],
            [-80.956616272999952, 35.180943867000053],
            [-80.956419272999938, 35.181978868000044],
            [-80.956399273999978, 35.182114868000042],
            [-80.956390272999954, 35.18224686800005],
            [-80.956395272999941, 35.182441868000069],
            [-80.956411271999968, 35.182540868000046],
            [-80.956443272999934, 35.18265186800005],
            [-80.956492272999981, 35.182771868000032],
            [-80.956562272999975, 35.182897868000055],
            [-80.95662327399998, 35.182987868000055],
            [-80.956715273999976, 35.183095867000077],
            [-80.956760273999976, 35.183139868000069],
            [-80.956906272999959, 35.18326786700004],
            [-80.956996272999959, 35.183334868000031],
            [-80.957197272999963, 35.183469868000032],
            [-80.957478273999982, 35.183645868000042],
            [-80.957593273999976, 35.183723868000072],
            [-80.957797272999983, 35.183870868000042],
            [-80.957946273999937, 35.183996868000065],
            [-80.95807627399995, 35.184119868000039],
            [-80.95825627399995, 35.184314868000058],
            [-80.958388272999969, 35.184480868000037],
            [-80.95854527399996, 35.184698868000055],
            [-80.958866274999934, 35.185178868000037],
            [-80.95895027399996, 35.185318868000024],
            [-80.959039274999952, 35.18548486800006],
            [-80.959094273999938, 35.185601868000049],
            [-80.959110273999954, 35.185636869000064],
            [-80.959156274999941, 35.185763868000038],
            [-80.959181273999945, 35.185857868000028],
            [-80.959213274999968, 35.186036868000031],
            [-80.959224273999951, 35.18618686800005],
            [-80.959213274999968, 35.186351868000031],
            [-80.959177274999945, 35.186510868000028],
            [-80.959137274999989, 35.186634869000045],
            [-80.959084273999963, 35.186779868000031],
            [-80.959067273999949, 35.186824869000077],
            [-80.958685273999947, 35.187774868000076],
            [-80.958088273999977, 35.18918086900004],
            [-80.958049272999972, 35.189290869000047],
            [-80.958002274999956, 35.189442869000061],
            [-80.957972273999985, 35.189582869000049],
            [-80.957970272999944, 35.189620870000056],
            [-80.957962274999943, 35.189789869000037],
            [-80.957976273999975, 35.189952869000024],
            [-80.958011273999944, 35.190139869000063],
            [-80.958201274999965, 35.190897870000072],
            [-80.958581273999982, 35.190854869000077],
            [-80.959013274999961, 35.190837869000063],
            [-80.959412274999977, 35.190862870000046],
            [-80.960459274999948, 35.190970869000068],
            [-80.962255275999951, 35.19116187000003],
            [-80.963643275999971, 35.191294869000046],
            [-80.963726275999989, 35.191302869000026],
            [-80.96380127599997, 35.191344869000034],
            [-80.963817275999986, 35.191419868000025],
            [-80.963817275999986, 35.19158587000004],
            [-80.96380127599997, 35.191926869000042],
            [-80.963867275999974, 35.192225869000026],
            [-80.963925275999941, 35.192408870000065],
            [-80.964042275999986, 35.192641870000045],
            [-80.964150276999987, 35.192790870000067],
            [-80.964283276999936, 35.19297387000006],
            [-80.96449927599997, 35.193148870000073],
            [-80.964648275999934, 35.19324787000005],
            [-80.964948276999962, 35.193381870000053],
            [-80.965249275999952, 35.193467869000074],
            [-80.965332276999959, 35.193527869000036],
            [-80.965405276999945, 35.193571869000039],
            [-80.965467276999959, 35.193598870000073],
            [-80.965546275999941, 35.193631870000047],
            [-80.965736275999973, 35.19372087000005],
            [-80.965840275999938, 35.193782869000074],
            [-80.965952276999985, 35.193856869000058],
            [-80.966066275999935, 35.193940870000063],
            [-80.966178276999983, 35.194032869000068],
            [-80.966381276999982, 35.194244870000034],
            [-80.966470276999985, 35.194364869000026],
            [-80.966628276999984, 35.194626870000036],
            [-80.966690276999941, 35.194767871000067],
            [-80.966779276999944, 35.195069870000054],
            [-80.966813276999972, 35.195225870000058],
            [-80.966880277999962, 35.195539870000061],
            [-80.966912276999949, 35.195694870000068],
            [-80.966944276999982, 35.19584787000008],
            [-80.96697527699996, 35.196000871000024],
            [-80.967007276999936, 35.196153871000035],
            [-80.967040275999977, 35.19630587000006],
            [-80.96707327699994, 35.196457870000074],
            [-80.967106276999971, 35.196611870000027],
            [-80.967140276999942, 35.196767870000031],
            [-80.967174277999959, 35.196927871000071],
            [-80.967191277999973, 35.197009870000045],
            [-80.967208276999941, 35.197090871000057],
            [-80.967242276999968, 35.197254871000041],
            [-80.967277276999937, 35.197417871000027],
            [-80.967312276999962, 35.197578871000076],
            [-80.967347277999977, 35.197737870000026],
            [-80.967415276999986, 35.19805387100007],
            [-80.96744827699996, 35.19820887000003],
            [-80.96748127699999, 35.198362870000039],
            [-80.967515276999961, 35.198517870000046],
            [-80.967548276999935, 35.19867387000005],
            [-80.967614277999985, 35.198987871000043],
            [-80.967645276999974, 35.19914287000006],
            [-80.96767327799995, 35.199296871000058],
            [-80.967693276999967, 35.19944987100007],
            [-80.967704276999939, 35.199602871000025],
            [-80.967708276999986, 35.199756871000034],
            [-80.967709277999973, 35.199909871000045],
            [-80.967708277999975, 35.200061871000059],
            [-80.96770627799998, 35.200213870000027],
            [-80.967703276999941, 35.200363871000036],
            [-80.967701276999946, 35.200513871000055],
            [-80.967698277999943, 35.200811872000031],
            [-80.967697277999946, 35.200959871000066],
            [-80.967696276999959, 35.201109872000075],
            [-80.967695277999951, 35.201259871000047],
            [-80.967694277999954, 35.201409871000067],
            [-80.967695277999951, 35.201555872000029],
            [-80.967693277999956, 35.201699871000073],
            [-80.967689277999966, 35.201841871000056],
            [-80.967683276999935, 35.201979872000038],
            [-80.967676277999942, 35.202116872000033],
            [-80.967672276999963, 35.202246871000057],
            [-80.967670276999968, 35.202373871000077],
            [-80.96766927799996, 35.202497871000048],
            [-80.967670277999957, 35.202614871000037],
            [-80.96767327799995, 35.20272587200003],
            [-80.967678276999948, 35.202828871000065],
            [-80.967682276999938, 35.202922872000045],
            [-80.967685277999976, 35.20301487200004],
            [-80.967685277999976, 35.203095872000063],
            [-80.967686276999984, 35.203170872000044],
            [-80.967688277999969, 35.203234872000053],
            [-80.967696276999959, 35.203287872000033],
            [-80.967728276999935, 35.203361872000073],
            [-80.967760276999968, 35.203436871000065],
            [-80.967945276999956, 35.20343487100007],
            [-80.968256276999966, 35.203428872000075],
            [-80.96853927799998, 35.203415871000061],
            [-80.969007278999982, 35.203412871000069],
            [-80.969541277999951, 35.203398872000037],
            [-80.970135278999976, 35.203403872000024],
            [-80.970511278999936, 35.203393872000049],
            [-80.973200279999958, 35.203534871000045]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 59,
        "SolutionID": "PI-20",
        "Shape_Length": 0.49991025383292759,
        "Shape_Area": 0.0075435018364716907
      }
    },
    {
      "type": "Feature",
      "id": 60,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.798966244999974, 35.51901394500004],
            [-80.798873244999982, 35.519433946000049],
            [-80.798840243999962, 35.519757946000027],
            [-80.798706244999948, 35.520554946000061],
            [-80.799703244999989, 35.520463946000064],
            [-80.801175244999968, 35.520336945000054],
            [-80.80181924599998, 35.520294945000046],
            [-80.805995245999952, 35.520067945000051],
            [-80.807027245999961, 35.519986945000028],
            [-80.80709124699996, 35.519975945000056],
            [-80.807421246999979, 35.519920945000024],
            [-80.807763247999958, 35.519737945000031],
            [-80.808489246999955, 35.519147945000043],
            [-80.808547247999968, 35.519100945000048],
            [-80.80885524699994, 35.518818945000078],
            [-80.809165247999942, 35.518623945000058],
            [-80.809460247999937, 35.518486944000074],
            [-80.809869247999984, 35.518408945000033],
            [-80.810281248999956, 35.51842894400005],
            [-80.810767247999934, 35.518520944000045],
            [-80.811667248999981, 35.518763945000046],
            [-80.814003248999938, 35.519420945000036],
            [-80.814495249999936, 35.519500944000072],
            [-80.814795248999985, 35.519507945000043],
            [-80.815172249999989, 35.519436944000063],
            [-80.815685249999945, 35.519295945000067],
            [-80.816783249999958, 35.518993944000044],
            [-80.817023249999977, 35.51892794500003],
            [-80.81785924999997, 35.518686944000024],
            [-80.818481249999934, 35.518507944000078],
            [-80.818760250999958, 35.518427944000052],
            [-80.819602250999935, 35.518216944000073],
            [-80.821766250999985, 35.517663944000049],
            [-80.822549252999977, 35.517477944000063],
            [-80.824548251999943, 35.517015944000036],
            [-80.825735251999959, 35.516741944000046],
            [-80.825831251999944, 35.516719944000045],
            [-80.826136252999959, 35.51663594300004],
            [-80.82636125199997, 35.516606944000046],
            [-80.826530251999941, 35.516599944000063],
            [-80.826908252999942, 35.516584944000044],
            [-80.827313252999943, 35.516644944000063],
            [-80.827880252999989, 35.516794944000026],
            [-80.828545252999959, 35.516955944000074],
            [-80.829136253999934, 35.517071944000065],
            [-80.82962925399994, 35.517136943000025],
            [-80.829984253999953, 35.517164944000058],
            [-80.831104253999968, 35.517209943000069],
            [-80.833114253999952, 35.517290943000035],
            [-80.833574253999984, 35.517308944000035],
            [-80.83406325499999, 35.517326944000047],
            [-80.834395254999947, 35.51733794300003],
            [-80.836026255999968, 35.517396943000051],
            [-80.836074254999971, 35.517398943000046],
            [-80.836785255999985, 35.517424944000027],
            [-80.837034256999971, 35.517380944000024],
            [-80.83729025599996, 35.517304944000045],
            [-80.837545256999988, 35.517168944000048],
            [-80.837822255999981, 35.516953943000033],
            [-80.838061256999936, 35.516449944000044],
            [-80.838135255999987, 35.516318943000044],
            [-80.838254255999971, 35.516110943000058],
            [-80.838415255999962, 35.515976943000055],
            [-80.838628255999936, 35.515874943000028],
            [-80.838996255999973, 35.515769942000077],
            [-80.839510255999983, 35.515642943000046],
            [-80.838939255999946, 35.51412494300007],
            [-80.838588256999969, 35.513191943000038],
            [-80.838450255999987, 35.512697942000045],
            [-80.838407254999936, 35.512475942000037],
            [-80.838368255999967, 35.512195942000062],
            [-80.838377255999944, 35.511765942000068],
            [-80.838417255999957, 35.511541942000065],
            [-80.83845325599998, 35.511340942000061],
            [-80.838595256999952, 35.510926942000026],
            [-80.838801255999954, 35.51053094100007],
            [-80.839047255999958, 35.510139941000034],
            [-80.839295255999957, 35.509867941000039],
            [-80.839820256999985, 35.509906941000054],
            [-80.840497255999935, 35.509956942000031],
            [-80.841372256999989, 35.51002094100005],
            [-80.845313257999976, 35.510307942000054],
            [-80.845850258999974, 35.510346942000069],
            [-80.852927260999934, 35.510861942000076],
            [-80.855593261999957, 35.511055941000052],
            [-80.855796261999956, 35.511070941000071],
            [-80.859166261999974, 35.511315941000078],
            [-80.860223261999977, 35.511392941000054],
            [-80.861301261999984, 35.511481941000056],
            [-80.861958263999952, 35.51153594200008],
            [-80.862105262999989, 35.51154794100006],
            [-80.865199263999955, 35.511773941000058],
            [-80.865529263999974, 35.511796941000057],
            [-80.865653263999945, 35.511805942000024],
            [-80.865885263999985, 35.511822941000048],
            [-80.866040263999935, 35.511834941000075],
            [-80.874385267999969, 35.512475940000058],
            [-80.874799266999958, 35.512507941000024],
            [-80.890049270999953, 35.513657941000076],
            [-80.890675271999953, 35.513703941000074],
            [-80.906065276999982, 35.514832941000066],
            [-80.909828276999974, 35.515109940000059],
            [-80.91006927799998, 35.514932940000051],
            [-80.910322277999967, 35.514745940000068],
            [-80.91821127999998, 35.508934939000028],
            [-80.930149281999945, 35.500141937000024],
            [-80.936342284999967, 35.49557993600007],
            [-80.940500284999985, 35.492517935000023],
            [-80.947033286999954, 35.487705933000029],
            [-80.946855286999948, 35.487238932000025],
            [-80.946727286999987, 35.486855933000072],
            [-80.946686286999977, 35.486730933000047],
            [-80.946508286999972, 35.486263932000043],
            [-80.946316286999945, 35.485984933000054],
            [-80.946191286999976, 35.485802933000059],
            [-80.945935286999941, 35.485385933000032],
            [-80.94582328599995, 35.48521093200003],
            [-80.94581128599998, 35.485192932000075],
            [-80.94580328699999, 35.485180932000048],
            [-80.945789286999968, 35.485158933000037],
            [-80.945658286999958, 35.484955933000037],
            [-80.945618285999956, 35.484891932000039],
            [-80.945589285999972, 35.484846932000039],
            [-80.945401286999981, 35.484558933000073],
            [-80.945362285999977, 35.484499932000062],
            [-80.945208286999957, 35.484193933000029],
            [-80.945204285999978, 35.484186932000057],
            [-80.945199285999934, 35.484175932000028],
            [-80.945184285999972, 35.484143932000052],
            [-80.945192286999941, 35.484077932000048],
            [-80.945214285999953, 35.483908932000077],
            [-80.945237285999951, 35.48383593300008],
            [-80.945368286999951, 35.483419931000071],
            [-80.945402286999979, 35.483381932000043],
            [-80.945769286999962, 35.482967932000065],
            [-80.946210285999939, 35.482554933000074],
            [-80.946340285999952, 35.482407932000058],
            [-80.946407285999953, 35.482331931000033],
            [-80.946582285999966, 35.482134931000076],
            [-80.94691428699997, 35.481738932000042],
            [-80.947296286999972, 35.481407931000035],
            [-80.947639286999959, 35.480955932000029],
            [-80.947787287999972, 35.480790931000058],
            [-80.947864286999959, 35.480704932000037],
            [-80.947880287999965, 35.48066693100003],
            [-80.947884287999955, 35.480657931000053],
            [-80.948068286999955, 35.480228931000056],
            [-80.948146286999986, 35.48005093200004],
            [-80.948204286999953, 35.479800932000046],
            [-80.948222286999965, 35.479139932000066],
            [-80.94825028799994, 35.478720931000055],
            [-80.948357286999965, 35.478333931000066],
            [-80.948434285999951, 35.478123931000027],
            [-80.948551286999987, 35.477727931000061],
            [-80.948648286999969, 35.477268931000026],
            [-80.948793286999944, 35.476630930000056],
            [-80.949075286999971, 35.475936930000046],
            [-80.949289286999942, 35.475299931000052],
            [-80.949582287999988, 35.474726930000031],
            [-80.949874286999943, 35.474080930000071],
            [-80.950109286999975, 35.473780930000032],
            [-80.950285287999975, 35.473555930000032],
            [-80.950493286999972, 35.473096930000054],
            [-80.95065528799995, 35.472740930000043],
            [-80.950918287999968, 35.472078930000066],
            [-80.951179286999945, 35.471523929000057],
            [-80.951211287999968, 35.471456930000045],
            [-80.95142528699995, 35.470899929000041],
            [-80.951629287999936, 35.470222929000045],
            [-80.951735286999963, 35.46961692900004],
            [-80.951733286999968, 35.469044928000073],
            [-80.951750286999982, 35.46845692900007],
            [-80.951670287999946, 35.468020929000033],
            [-80.951600286999962, 35.467617929000028],
            [-80.951500286999988, 35.467045928000061],
            [-80.95133128699996, 35.466537929000026],
            [-80.951313286999948, 35.466435928000067],
            [-80.951303286999973, 35.466380929000024],
            [-80.951251286999934, 35.466094928000075],
            [-80.951024287999985, 35.465700929000036],
            [-80.950726286999952, 35.465391929000077],
            [-80.950521286999958, 35.46503192800003],
            [-80.950176286999977, 35.464750928000058],
            [-80.949861286999976, 35.464452928000071],
            [-80.949762285999952, 35.464375928000038],
            [-80.949660286999972, 35.46429592800007],
            [-80.949520286999984, 35.464186928000061],
            [-80.949398285999962, 35.464091927000027],
            [-80.94905328699997, 35.463849928000059],
            [-80.948600285999987, 35.463472928000044],
            [-80.948148285999935, 35.463175927000066],
            [-80.948098285999947, 35.463142928000025],
            [-80.947537284999953, 35.462740928000073],
            [-80.947327285999961, 35.462593928000047],
            [-80.947320285999979, 35.46258892700007],
            [-80.946917284999984, 35.462306927000043],
            [-80.946596284999941, 35.462091927000074],
            [-80.946590285999946, 35.462087927000027],
            [-80.946585284999969, 35.462084927000035],
            [-80.946561284999973, 35.462068927000075],
            [-80.946551284999941, 35.462061928000026],
            [-80.946541285999956, 35.462054927000054],
            [-80.946224284999971, 35.461842927000077],
            [-80.946149285999979, 35.461792927000033],
            [-80.94572528499998, 35.461373928000057],
            [-80.945418285999949, 35.461127927000064],
            [-80.945184284999982, 35.460939928000073],
            [-80.944780284999979, 35.460642927000038],
            [-80.944396284999982, 35.460352927000031],
            [-80.944012284999985, 35.460144928000034],
            [-80.943746283999985, 35.459870927000054],
            [-80.943608284999982, 35.459753927000065],
            [-80.943598283999961, 35.459744927000031],
            [-80.943591284999968, 35.459738927000046],
            [-80.943539283999939, 35.459694928000033],
            [-80.943523284999969, 35.459680927000079],
            [-80.943515284999989, 35.459674927000037],
            [-80.943490284999939, 35.459653927000033],
            [-80.943466283999953, 35.45962492700005],
            [-80.94345928499996, 35.45961692700007],
            [-80.943450284999983, 35.459606927000038],
            [-80.943254283999977, 35.459379927000043],
            [-80.943036284999948, 35.45905792800005],
            [-80.943010284999957, 35.458990927000059],
            [-80.942868283999985, 35.458630926000069],
            [-80.942719284999953, 35.458211927000036],
            [-80.942598284999974, 35.457959927000047],
            [-80.942492283999968, 35.457734927000047],
            [-80.942490283999973, 35.457715927000038],
            [-80.942488283999978, 35.457693926000047],
            [-80.942488283999978, 35.457687927000052],
            [-80.942487283999981, 35.457675927000025],
            [-80.942420284999969, 35.456826927000066],
            [-80.942335283999967, 35.456739926000068],
            [-80.942332283999974, 35.456736927000065],
            [-80.942302283999936, 35.45670592700003],
            [-80.942300283999941, 35.456672927000056],
            [-80.942299283999944, 35.456598927000073],
            [-80.942291282999975, 35.456317926000054],
            [-80.942337282999972, 35.45556892500008],
            [-80.942400283999973, 35.455331926000042],
            [-80.94247328299997, 35.455050926000069],
            [-80.94251028299999, 35.454389926000033],
            [-80.942616283999939, 35.45383492600007],
            [-80.94274328399996, 35.453423925000038],
            [-80.943016283999953, 35.453003925000075],
            [-80.943350283999962, 35.45263092600004],
            [-80.94376028399995, 35.452267926000047],
            [-80.944191284999988, 35.451888925000048],
            [-80.944661283999949, 35.45162892500008],
            [-80.944954284999937, 35.451265926000076],
            [-80.945228283999938, 35.450885926000069],
            [-80.945237283999973, 35.450871925000058],
            [-80.945240284999954, 35.450866925000071],
            [-80.945246284999939, 35.450857925000037],
            [-80.945391283999982, 35.450636925000026],
            [-80.945595284999968, 35.450325925000072],
            [-80.94559828499996, 35.450320926000074],
            [-80.945619283999974, 35.450288925000052],
            [-80.94563128499999, 35.450273925000033],
            [-80.94563928499997, 35.450263925000058],
            [-80.945647283999961, 35.450254925000024],
            [-80.945654283999943, 35.450246925000044],
            [-80.94588328399999, 35.449973924000062],
            [-80.945906284999978, 35.449954925000043],
            [-80.946177284999976, 35.44973092500004],
            [-80.946412283999962, 35.449560926000061],
            [-80.946598284999936, 35.449455925000052],
            [-80.947269284999948, 35.449270925000064],
            [-80.947275284999989, 35.449268925000069],
            [-80.947297284999934, 35.449262925000028],
            [-80.947334284999954, 35.449252925000053],
            [-80.947380285999941, 35.449236925000037],
            [-80.947893284999964, 35.449057924000044],
            [-80.948451285999965, 35.44875892500005],
            [-80.949040284999967, 35.448498925000024],
            [-80.949568284999941, 35.448181925000029],
            [-80.949647285999959, 35.448134925000033],
            [-80.950186286999951, 35.447665925000024],
            [-80.950586284999986, 35.447108924000077],
            [-80.950977285999954, 35.44646292300007],
            [-80.951396286999966, 35.445743924000055],
            [-80.95169928699994, 35.44528392400008],
            [-80.951953285999934, 35.444774924000058],
            [-80.952216286999942, 35.444266923000043],
            [-80.952509285999952, 35.443652923000059],
            [-80.952713285999948, 35.443152923000071],
            [-80.95282928599994, 35.442692923000038],
            [-80.952892285999951, 35.442423923000035],
            [-80.952926285999979, 35.442281923000053],
            [-80.952991286999975, 35.441677923000043],
            [-80.952992286999972, 35.441664922000029],
            [-80.95299328699997, 35.441659923000032],
            [-80.953002285999958, 35.441579923000063],
            [-80.953070285999956, 35.44128192200003],
            [-80.95321028699999, 35.441080922000026],
            [-80.953441285999986, 35.44074792300006],
            [-80.953793286999939, 35.440247922000026],
            [-80.954458287999955, 35.439552923000065],
            [-80.955221285999983, 35.438889922000044],
            [-80.956033287999958, 35.438081922000038],
            [-80.956658287999971, 35.437079922000066],
            [-80.956951287999971, 35.436474922000059],
            [-80.957084287999976, 35.436209922000046],
            [-80.957272286999967, 35.435836922000078],
            [-80.957409286999962, 35.435633922000079],
            [-80.957641286999944, 35.435292921000041],
            [-80.95775828699999, 35.435015921000058],
            [-80.957875286999979, 35.434738922000065],
            [-80.957994286999963, 35.434456922000038],
            [-80.958306286999971, 35.433802922000041],
            [-80.958471287999942, 35.433318920000033],
            [-80.958694287999947, 35.432640921000029],
            [-80.958702287999984, 35.432140921000041],
            [-80.958684286999983, 35.432013921000078],
            [-80.958639286999983, 35.431703920000075],
            [-80.958493287999943, 35.431270920000031],
            [-80.958177286999955, 35.430625920000068],
            [-80.957732286999942, 35.429933921000043],
            [-80.95724828699997, 35.429128920000039],
            [-80.957071286999962, 35.428785920000053],
            [-80.956985285999963, 35.428616919000035],
            [-80.956962286999953, 35.428572921000068],
            [-80.956942285999958, 35.428532920000066],
            [-80.95672428599994, 35.427839919000064],
            [-80.95667128599996, 35.427685919000055],
            [-80.956659286999979, 35.427651920000073],
            [-80.956653286999938, 35.42763492000006],
            [-80.956615286999977, 35.427525920000051],
            [-80.956612286999984, 35.427516920000073],
            [-80.956561285999953, 35.427376920000029],
            [-80.956559286999948, 35.427371919000052],
            [-80.956551285999979, 35.427349919000051],
            [-80.956549285999984, 35.427342920000058],
            [-80.956537285999957, 35.427310919000035],
            [-80.956529286999967, 35.427289920000078],
            [-80.956426286999942, 35.427009920000046],
            [-80.956287286999952, 35.426574920000064],
            [-80.956000285999949, 35.425776919000043],
            [-80.955850286999976, 35.425180920000059],
            [-80.955702285999962, 35.424440919000062],
            [-80.955551286999935, 35.423713919000079],
            [-80.955534285999988, 35.423625919000074],
            [-80.95552928699999, 35.423599919000026],
            [-80.955527285999949, 35.423588919000053],
            [-80.955525286999944, 35.423576919000027],
            [-80.955524285999957, 35.423570919000042],
            [-80.955522285999962, 35.423564919000057],
            [-80.955520285999967, 35.423553919000028],
            [-80.955472286999964, 35.423313919000066],
            [-80.955400285999985, 35.422900919000028],
            [-80.955399285999988, 35.422892919000049],
            [-80.955397284999947, 35.422881919000076],
            [-80.955351285999939, 35.422617918000071],
            [-80.955317286999957, 35.42234791900006],
            [-80.955315286999962, 35.422331919000044],
            [-80.955314285999975, 35.42232191800008],
            [-80.955313286999967, 35.422311919000038],
            [-80.95531228599998, 35.422301919000063],
            [-80.955311286999972, 35.422296919000075],
            [-80.95527928599995, 35.422052918000077],
            [-80.955278286999942, 35.422045918000038],
            [-80.955269285999975, 35.421971919000043],
            [-80.955211286999941, 35.421453919000044],
            [-80.955176285999983, 35.420577919000038],
            [-80.955273285999965, 35.420053919000054],
            [-80.955344284999967, 35.419803918000071],
            [-80.955428285999972, 35.419512918000066],
            [-80.955642285999943, 35.418923918000075],
            [-80.955993285999966, 35.418213918000049],
            [-80.956480285999987, 35.417373917000077],
            [-80.957086286999981, 35.416469917000029],
            [-80.957788286999971, 35.415209917000027],
            [-80.958383285999957, 35.41416891700004],
            [-80.958587285999954, 35.413635918000068],
            [-80.958772285999942, 35.413215917000059],
            [-80.958765286999949, 35.412120916000049],
            [-80.958726286999934, 35.411345916000073],
            [-80.95860728599996, 35.410781916000076],
            [-80.958496285999956, 35.410208916000045],
            [-80.958326285999988, 35.40946791600004],
            [-80.95816528499995, 35.408558916000061],
            [-80.957878285999982, 35.407662916000049],
            [-80.957559285999935, 35.406227916000034],
            [-80.95723028499998, 35.404873915000053],
            [-80.956804284999976, 35.403658915000051],
            [-80.95625128599994, 35.402619915000059],
            [-80.95575628499995, 35.401628914000071],
            [-80.955061284999942, 35.401648914000077],
            [-80.954810284999951, 35.401687914000036],
            [-80.954631283999959, 35.401585915000055],
            [-80.954474284999947, 35.401423914000077],
            [-80.954254283999944, 35.401288915000066],
            [-80.954171284999973, 35.401191914000037],
            [-80.954175284999963, 35.40108491400008],
            [-80.953332283999941, 35.401135915000054],
            [-80.953303284999947, 35.401172914000028],
            [-80.953420283999947, 35.40137491400003],
            [-80.953537283999935, 35.40155091400004],
            [-80.953533283999946, 35.401728914000046],
            [-80.953440284999942, 35.401861914000051],
            [-80.953305284999942, 35.401942915000063],
            [-80.953161283999975, 35.401938914000027],
            [-80.953045283999984, 35.401828914000077],
            [-80.95293828299998, 35.401704914000049],
            [-80.952800283999977, 35.401667914000029],
            [-80.952519283999948, 35.401672915000063],
            [-80.952114283999947, 35.401533914000026],
            [-80.951774282999963, 35.40140791500005],
            [-80.951604283999984, 35.401390915000036],
            [-80.951535282999942, 35.401302914000041],
            [-80.951111282999989, 35.401339914000062],
            [-80.950734283999964, 35.40141191500004],
            [-80.95126828399998, 35.402225914000041],
            [-80.951484283999946, 35.402547914000024],
            [-80.951823283999943, 35.403055914000049],
            [-80.952023283999949, 35.403670915000077],
            [-80.952001284999938, 35.404212915000073],
            [-80.953089283999986, 35.404194915000062],
            [-80.953223284999979, 35.403543914000068],
            [-80.953445284999987, 35.403199915000073],
            [-80.95375628499994, 35.403218915000025],
            [-80.953979283999956, 35.404156915000044],
            [-80.95464528499997, 35.405494915000077],
            [-80.955096284999968, 35.405954915000052],
            [-80.955489285999988, 35.40634491600008],
            [-80.95571128499995, 35.407086916000026],
            [-80.955578284999945, 35.407285915000045],
            [-80.955113284999982, 35.407120915000064],
            [-80.954602284999964, 35.406795915000032],
            [-80.954224284999952, 35.406777916000067],
            [-80.95442428399997, 35.407464916000038],
            [-80.954670284999963, 35.40829691600004],
            [-80.954603284999962, 35.409055916000057],
            [-80.954137283999955, 35.409073916000068],
            [-80.954096283999945, 35.409073916000068],
            [-80.954075284999988, 35.408987916000058],
            [-80.954071284999941, 35.408831916000054],
            [-80.954159284999946, 35.408540916000049],
            [-80.954232283999943, 35.408352917000059],
            [-80.954298284999936, 35.408131916000059],
            [-80.954300285999977, 35.408058916000073],
            [-80.954263284999968, 35.407939916000032],
            [-80.953938284999936, 35.407603916000028],
            [-80.953830284999981, 35.407411916000058],
            [-80.953706284999953, 35.407190915000058],
            [-80.953637283999967, 35.407075915000064],
            [-80.953499283999975, 35.406945916000041],
            [-80.953355284999986, 35.406916915000068],
            [-80.953213283999958, 35.40694991600003],
            [-80.953102283999954, 35.40705491600005],
            [-80.953049284999963, 35.407180916000073],
            [-80.953032284999949, 35.407280916000047],
            [-80.953016283999943, 35.407475916000067],
            [-80.952931283999987, 35.407672916000024],
            [-80.952679284999988, 35.40797791600005],
            [-80.952303283999981, 35.408361916000047],
            [-80.95194328499997, 35.408709915000031],
            [-80.951690282999948, 35.408963916000062],
            [-80.951630284999965, 35.409073916000068],
            [-80.951588284999957, 35.409153917000026],
            [-80.951576283999941, 35.409233916000062],
            [-80.951563283999974, 35.409430916000076],
            [-80.951548283999955, 35.40960291600004],
            [-80.951367283999957, 35.40958691600008],
            [-80.951186283999959, 35.409626916000036],
            [-80.950949282999943, 35.409640917000047],
            [-80.950620282999978, 35.409695916000032],
            [-80.950526283999977, 35.409751917000051],
            [-80.950292282999953, 35.409867917000042],
            [-80.950093282999944, 35.409965916000033],
            [-80.94993728299994, 35.410004916000048],
            [-80.949838282999963, 35.410025916000052],
            [-80.94964028399994, 35.410045916000058],
            [-80.949409282999966, 35.410087917000055],
            [-80.949298282999962, 35.410145917000079],
            [-80.949184282999965, 35.41023991600008],
            [-80.949113283999964, 35.410320917000035],
            [-80.949032282999951, 35.410526916000038],
            [-80.94895728299997, 35.410718916000064],
            [-80.948874283999942, 35.41075791600008],
            [-80.948686283999962, 35.410771917000034],
            [-80.948426282999947, 35.410767917000044],
            [-80.948324282999977, 35.410766917000046],
            [-80.94816528399997, 35.410737917000063],
            [-80.948081282999965, 35.41068991700007],
            [-80.94793628299999, 35.410666917000071],
            [-80.947740282999973, 35.410658916000045],
            [-80.947741282999971, 35.410670917000061],
            [-80.947734282999988, 35.410679917000039],
            [-80.947697281999979, 35.410726917000034],
            [-80.947668283999974, 35.410743916000058],
            [-80.947617282999943, 35.410774916000037],
            [-80.947533282999984, 35.410784917000058],
            [-80.947502282999949, 35.410779916000024],
            [-80.947414282999944, 35.410765917000049],
            [-80.947335282999973, 35.41075091700003],
            [-80.947311281999987, 35.410749917000032],
            [-80.946794282999974, 35.41157191700006],
            [-80.946705282999972, 35.411685917000057],
            [-80.946683281999981, 35.411708918000045],
            [-80.946662281999977, 35.411725918000059],
            [-80.946566282999981, 35.411797917000058],
            [-80.946410281999988, 35.41189891700003],
            [-80.945927281999957, 35.412185917000045],
            [-80.945454281999957, 35.412505918000079],
            [-80.945140281999954, 35.412728917000038],
            [-80.944968281999934, 35.412858917000051],
            [-80.94484128199997, 35.412966918000052],
            [-80.944735282999943, 35.413063918000034],
            [-80.944623281999952, 35.413179917000036],
            [-80.944546282999966, 35.413276917000076],
            [-80.944230281999978, 35.413761917000045],
            [-80.943943281999964, 35.414229918000046],
            [-80.943735281999977, 35.41452891800003],
            [-80.943666281999981, 35.414601917000027],
            [-80.943517281999959, 35.414722918000052],
            [-80.943431281999949, 35.414782918000071],
            [-80.942884281999966, 35.41510691700006],
            [-80.942619281999953, 35.415263918000051],
            [-80.94207628099997, 35.415528918000064],
            [-80.941616280999938, 35.415762918000041],
            [-80.94152128099995, 35.415806918000044],
            [-80.941355280999971, 35.415870917000063],
            [-80.941167280999935, 35.415926917000036],
            [-80.940585280999983, 35.416075918000047],
            [-80.940221281999982, 35.416149918000031],
            [-80.940136281999969, 35.416162918000055],
            [-80.939187280999988, 35.416298918000052],
            [-80.939048280999941, 35.416316918000064],
            [-80.937081279999973, 35.416578919000074],
            [-80.936860279999962, 35.416589918000057],
            [-80.93671427999999, 35.416585918000067],
            [-80.935620279999966, 35.41647991800005],
            [-80.935536279999951, 35.416459918000044],
            [-80.935464279999962, 35.416425918000073],
            [-80.935304279999968, 35.416317918000061],
            [-80.935120278999989, 35.416178918000071],
            [-80.934111279999968, 35.415419918000055],
            [-80.934045277999985, 35.415374918000055],
            [-80.933935278999968, 35.41532391800007],
            [-80.93388927899997, 35.415307918000053],
            [-80.933783279999943, 35.415283918000057],
            [-80.933682279999971, 35.415283918000057],
            [-80.933581278999952, 35.415300918000071],
            [-80.933449278999944, 35.415332918000047],
            [-80.933249278999938, 35.415365918000077],
            [-80.93306427999994, 35.415376918000049],
            [-80.932980278999935, 35.41537691700006],
            [-80.932811278999964, 35.415365918000077],
            [-80.932718278999971, 35.415351918000056],
            [-80.932623278999984, 35.415326918000062],
            [-80.932509278999987, 35.415286918000049],
            [-80.932406277999974, 35.415236918000062],
            [-80.932340278999959, 35.415194918000054],
            [-80.932258278999939, 35.415133918000038],
            [-80.932191278999937, 35.415073918000076],
            [-80.932046278999962, 35.41490391800005],
            [-80.931721277999941, 35.414463918000024],
            [-80.931645277999962, 35.414370918000031],
            [-80.931524278999973, 35.414248918000055],
            [-80.931305278999957, 35.414073918000042],
            [-80.930977277999943, 35.413839917000075],
            [-80.930727277999949, 35.413680917000079],
            [-80.930622277999987, 35.41360591800003],
            [-80.930576276999943, 35.413562917000036],
            [-80.930547277999949, 35.413527918000057],
            [-80.930511277999983, 35.413469918000033],
            [-80.930448277999972, 35.413320918000068],
            [-80.930342277999955, 35.41306591700004],
            [-80.930173277999984, 35.412732917000028],
            [-80.930091277999963, 35.412610918000041],
            [-80.930016278999972, 35.412520918000041],
            [-80.92992027799994, 35.412425918000054],
            [-80.929861277999976, 35.412374917000079],
            [-80.929675276999944, 35.41223691700003],
            [-80.929076277999968, 35.411832918000073],
            [-80.928851277999968, 35.411685918000046],
            [-80.928431276999959, 35.411426918000075],
            [-80.928348276999941, 35.411384918000067],
            [-80.927693276999946, 35.411085917000037],
            [-80.926041276999968, 35.410382917000049],
            [-80.925822276999952, 35.410306917000071],
            [-80.926424275999977, 35.408859917000029],
            [-80.926496275999966, 35.408634917000029],
            [-80.926520275999962, 35.408518916000048],
            [-80.926535275999981, 35.40841591700007],
            [-80.926541276999956, 35.408320917000026],
            [-80.926540276999958, 35.408219916000064],
            [-80.926523275999955, 35.408022917000039],
            [-80.926491276999968, 35.407850916000029],
            [-80.926451276999956, 35.407700916000067],
            [-80.926364276999948, 35.407439917000033],
            [-80.92463027499997, 35.402347917000043],
            [-80.92421727499999, 35.401157916000045],
            [-80.923466274999953, 35.398954915000047],
            [-80.923314275999985, 35.398530915000038],
            [-80.923168274999966, 35.398098914000059],
            [-80.923146274999965, 35.398009915000046],
            [-80.923105274999955, 35.397767915000031],
            [-80.923093274999985, 35.397559914000055],
            [-80.923101274999965, 35.397335915000042],
            [-80.923137274999988, 35.397060915000054],
            [-80.923173274999954, 35.396922915000061],
            [-80.92323027499998, 35.396767915000055],
            [-80.923505274999968, 35.396129914000028],
            [-80.923627275999934, 35.39585891400003],
            [-80.923777274999964, 35.395498914000029],
            [-80.923840274999975, 35.395319914000027],
            [-80.923873274999949, 35.39519791400005],
            [-80.923885274999975, 35.395113914000035],
            [-80.923889274999965, 35.39493591400003],
            [-80.92387927599998, 35.39478891400006],
            [-80.92384627499996, 35.394645913000033],
            [-80.923791274999985, 35.394474914000057],
            [-80.923729274999971, 35.394328915000074],
            [-80.923571273999983, 35.394009914000037],
            [-80.922897274999968, 35.392777914000078],
            [-80.922807274999968, 35.39261291400004],
            [-80.922661274999939, 35.39237191400008],
            [-80.922584273999973, 35.392267913000069],
            [-80.922412274999942, 35.392079914000078],
            [-80.921881273999986, 35.391559914000027],
            [-80.921401274999937, 35.391080913000053],
            [-80.921291273999941, 35.390955914000074],
            [-80.921181273999935, 35.39080791300006],
            [-80.921090274999983, 35.390655913000046],
            [-80.921007273999976, 35.390485914000067],
            [-80.920961273999978, 35.390360913000052],
            [-80.920926273999953, 35.39020791300004],
            [-80.920904272999962, 35.39002391300005],
            [-80.920915273999981, 35.389792913000065],
            [-80.920932274999984, 35.389668913000037],
            [-80.920944273999964, 35.389589914000055],
            [-80.92111427399999, 35.389629913000078],
            [-80.925391274999981, 35.390065912000068],
            [-80.925484274999974, 35.390069913000048],
            [-80.925806274999957, 35.390052913000034],
            [-80.925879274999943, 35.390044912000064],
            [-80.926000275999968, 35.39003191300003],
            [-80.926070274999972, 35.390013913000075],
            [-80.926130274999934, 35.390002913000046],
            [-80.926183274999971, 35.389987913000027],
            [-80.926249274999975, 35.389959913000041],
            [-80.926406275999966, 35.389881913000067],
            [-80.926568275999955, 35.389822913000046],
            [-80.926658275999955, 35.389783914000077],
            [-80.926838275999955, 35.389678913000068],
            [-80.926961275999986, 35.389591913000061],
            [-80.927069275999941, 35.389528913000049],
            [-80.927517275999946, 35.389208913000061],
            [-80.927688275999969, 35.389107912000043],
            [-80.927879275999942, 35.388962912000068],
            [-80.928083275999938, 35.388833912000052],
            [-80.928172275999941, 35.388788913000042],
            [-80.928295275999972, 35.388740913000049],
            [-80.928624275999937, 35.388560912000059],
            [-80.928884275999962, 35.388436913000078],
            [-80.929199275999963, 35.388188913000079],
            [-80.929568275999941, 35.387924913000063],
            [-80.929799276999972, 35.387828912000032],
            [-80.930117276999965, 35.387651912000024],
            [-80.930139276999967, 35.38762691200003],
            [-80.930392276999953, 35.387456913000051],
            [-80.930523276999963, 35.387432912000065],
            [-80.930647276999935, 35.387427913000067],
            [-80.93076027699999, 35.387441912000043],
            [-80.93092227599999, 35.387488912000038],
            [-80.931076276999988, 35.387567912000065],
            [-80.931127276999973, 35.387600912000039],
            [-80.931246276999957, 35.387679912000067],
            [-80.931393275999937, 35.387717912000028],
            [-80.931476276999945, 35.387746912000068],
            [-80.931571276999989, 35.387763912000025],
            [-80.931807276999962, 35.387756913000032],
            [-80.931999276999989, 35.387679912000067],
            [-80.932065277999982, 35.387636912000062],
            [-80.932132276999937, 35.387591912000062],
            [-80.932228276999979, 35.387540913000066],
            [-80.932346275999976, 35.387462912000046],
            [-80.932446275999951, 35.387390912000058],
            [-80.932649276999939, 35.387242912000033],
            [-80.932722276999982, 35.387179912000079],
            [-80.93277227699997, 35.387080912000044],
            [-80.932801276999953, 35.386997912000027],
            [-80.93281327699998, 35.38688391200003],
            [-80.932778276999954, 35.386763912000049],
            [-80.932759276999946, 35.386720913000033],
            [-80.932723277999969, 35.38668791300006],
            [-80.932663276999961, 35.386625913000046],
            [-80.932319277999966, 35.386352913000053],
            [-80.932271276999984, 35.386305912000068],
            [-80.932257276999962, 35.386268912000048],
            [-80.93222127699994, 35.386171912000066],
            [-80.932180276999986, 35.385913912000035],
            [-80.932189275999974, 35.385812912000063],
            [-80.932114276999982, 35.385621912000033],
            [-80.932067276999987, 35.385376912000027],
            [-80.93202927699997, 35.385030911000058],
            [-80.93202527699998, 35.384964912000044],
            [-80.932053276999966, 35.384911912000064],
            [-80.932244276999938, 35.384724911000035],
            [-80.932369275999974, 35.384613912000077],
            [-80.932443276999948, 35.384535911000057],
            [-80.932686276999959, 35.384137912000028],
            [-80.932842276999963, 35.383935912000027],
            [-80.933126276999985, 35.38353991200006],
            [-80.933245276999969, 35.383350912000026],
            [-80.933340276999957, 35.383212912000033],
            [-80.933614276999947, 35.382824912000046],
            [-80.93368727699999, 35.382719911000038],
            [-80.933829276999973, 35.382529912000052],
            [-80.933991276999961, 35.382435911000073],
            [-80.934130276999952, 35.382361911000032],
            [-80.934264277999944, 35.382316911000032],
            [-80.934416276999968, 35.382289911000044],
            [-80.934607277999987, 35.382204911000031],
            [-80.934726276999982, 35.382128911000052],
            [-80.934781277999946, 35.382096911000076],
            [-80.93483527799998, 35.382081911000057],
            [-80.934991277999984, 35.382045912000024],
            [-80.935100276999947, 35.382086911000044],
            [-80.935214276999943, 35.382185911000079],
            [-80.935404277999965, 35.382288911000046],
            [-80.935737278999966, 35.382460911000067],
            [-80.935915277999982, 35.382568911000078],
            [-80.936102277999964, 35.382666912000047],
            [-80.936164277999978, 35.382684911000069],
            [-80.936223277999943, 35.382678911000028],
            [-80.936339277999934, 35.382657911000024],
            [-80.936467277999952, 35.382588911000028],
            [-80.936576277999961, 35.382504911000069],
            [-80.936660277999977, 35.382340911000028],
            [-80.936801278999951, 35.382102911000061],
            [-80.936905278999973, 35.382033911000065],
            [-80.937056278999989, 35.381968912000048],
            [-80.937196277999988, 35.381933910000043],
            [-80.93761227799996, 35.381811911000057],
            [-80.938031277999983, 35.38166091100004],
            [-80.938509278999959, 35.381518911000057],
            [-80.938735278999957, 35.381442910000032],
            [-80.938899277999951, 35.381411911000043],
            [-80.939040278999983, 35.381428910000068],
            [-80.939463278999938, 35.381445910000025],
            [-80.93960427899998, 35.381443911000076],
            [-80.939961278999988, 35.381432911000047],
            [-80.940065278999953, 35.38137891100007],
            [-80.940124278999974, 35.381281911000031],
            [-80.94014827899997, 35.381045911000058],
            [-80.940109278999955, 35.380574910000064],
            [-80.940104279999957, 35.38051291000005],
            [-80.94000127999999, 35.380543911000075],
            [-80.939974278999955, 35.380431910000027],
            [-80.939928278999957, 35.380176910000046],
            [-80.939916278999988, 35.380086910000045],
            [-80.939918278999983, 35.379947910000055],
            [-80.93992327999996, 35.379890910000029],
            [-80.940014278999968, 35.379838911000036],
            [-80.940108278999958, 35.379786910000064],
            [-80.940192278999973, 35.379733910000027],
            [-80.940281278999976, 35.379683911000029],
            [-80.940358279999941, 35.379648910000071],
            [-80.940447278999955, 35.379619910000031],
            [-80.940655278999941, 35.379506910000032],
            [-80.940746278999939, 35.379453910000052],
            [-80.940861278999989, 35.379404910000062],
            [-80.940912278999974, 35.379387910000048],
            [-80.940996278999989, 35.379343910000046],
            [-80.941059278999944, 35.379280910000034],
            [-80.941119279999953, 35.379197911000063],
            [-80.941213278999953, 35.379037910000079],
            [-80.941222278999987, 35.378958910000051],
            [-80.941221279999979, 35.378894910000042],
            [-80.941178279999974, 35.378843910000057],
            [-80.941117279999958, 35.378790909000031],
            [-80.941035278999948, 35.378731909000066],
            [-80.940967278999949, 35.37867490900004],
            [-80.940889278999975, 35.378624910000042],
            [-80.940824279999958, 35.378569910000067],
            [-80.940812278999942, 35.378522910000072],
            [-80.940812278999942, 35.378474910000079],
            [-80.940806279999947, 35.378421909000053],
            [-80.940772278999987, 35.378358910000031],
            [-80.940763279999942, 35.37828791000004],
            [-80.940760279999949, 35.378208910000069],
            [-80.940744278999944, 35.378084910000041],
            [-80.940702279999982, 35.378044910000028],
            [-80.940768279999986, 35.378016910000042],
            [-80.940793278999934, 35.377998910000031],
            [-80.940828277999969, 35.377967910000052],
            [-80.94084627899997, 35.377920910000057],
            [-80.940896278999958, 35.377873910000062],
            [-80.94094027999995, 35.377853911000045],
            [-80.94106727999997, 35.377799910000078],
            [-80.941116278999971, 35.37776991000004],
            [-80.941202278999981, 35.377754910000078],
            [-80.941306278999946, 35.377753910000024],
            [-80.941445279999982, 35.377749910000034],
            [-80.941701279999961, 35.377735910000069],
            [-80.941821279999942, 35.37773990900007],
            [-80.941897279999978, 35.377727910000033],
            [-80.941943279999975, 35.377667910000071],
            [-80.942024279999941, 35.377628910000055],
            [-80.94209227999994, 35.377636911000025],
            [-80.942232278999938, 35.37770290900005],
            [-80.942303279999976, 35.377742910000052],
            [-80.942353278999974, 35.377747910000039],
            [-80.942457278999939, 35.377752910000027],
            [-80.942481279999981, 35.377760910000063],
            [-80.942559278999965, 35.377794910000034],
            [-80.942635279999934, 35.377807910000058],
            [-80.94270727899999, 35.377808910000056],
            [-80.942864279999981, 35.377799910000078],
            [-80.942974279999987, 35.377804910000066],
            [-80.943049279999968, 35.377784910000059],
            [-80.943159279999975, 35.37781891000003],
            [-80.943227278999984, 35.377849910000066],
            [-80.943301279999957, 35.377828910000062],
            [-80.94339027999996, 35.377810910000051],
            [-80.943487279999943, 35.377820910000025],
            [-80.943672280999976, 35.377854909000064],
            [-80.943774279999957, 35.377859910000041],
            [-80.943807279999987, 35.377856909000059],
            [-80.943882280999958, 35.377846910000073],
            [-80.943914279999944, 35.377831910000054],
            [-80.94395327999996, 35.377808910000056],
            [-80.943972279999969, 35.377798909000035],
            [-80.94405127999994, 35.377746910000042],
            [-80.94413327999996, 35.377714910000066],
            [-80.94418227999995, 35.377676910000048],
            [-80.944229279999945, 35.377544909000051],
            [-80.944195279999974, 35.377481910000029],
            [-80.944131279999965, 35.377389910000034],
            [-80.944145279999987, 35.377301910000028],
            [-80.944203279999954, 35.377205909000054],
            [-80.944271279999953, 35.377145910000024],
            [-80.944308279999973, 35.377096910000034],
            [-80.944332279999969, 35.37703091000003],
            [-80.94435428099996, 35.376990910000075],
            [-80.944390280999983, 35.376949910000064],
            [-80.944465279999974, 35.376917910000031],
            [-80.94454927999999, 35.376875910000024],
            [-80.944607279999957, 35.376822909000055],
            [-80.944671278999976, 35.376795910000055],
            [-80.944769278999956, 35.376772909000067],
            [-80.944859280999935, 35.376729909000062],
            [-80.944955279999988, 35.376671909000038],
            [-80.945024280999974, 35.376624910000032],
            [-80.945077280999953, 35.376565910000068],
            [-80.945123279999962, 35.37649490900003],
            [-80.945198279999943, 35.376414909000061],
            [-80.945261280999944, 35.376356909000037],
            [-80.945312280999985, 35.37629490900008],
            [-80.945323279999968, 35.376276909000069],
            [-80.945351280999944, 35.376207910000062],
            [-80.945382280999979, 35.376132910000024],
            [-80.945387280999967, 35.376047909000079],
            [-80.945379279999941, 35.375981910000064],
            [-80.945395280999946, 35.375909909000029],
            [-80.945447280999986, 35.375786910000045],
            [-80.945462280999948, 35.375733909000076],
            [-80.945459280999955, 35.375681909000036],
            [-80.945468280999989, 35.375610910000034],
            [-80.945507280999948, 35.375504910000075],
            [-80.94543927999996, 35.375431909000042],
            [-80.94538627999998, 35.375397909000071],
            [-80.945339279999985, 35.375359909000053],
            [-80.945300280999959, 35.375262909000071],
            [-80.945316279999986, 35.375199910000049],
            [-80.945471279999936, 35.375091909000048],
            [-80.945491279999942, 35.37503791000006],
            [-80.945510279999951, 35.374838909000061],
            [-80.945508279999956, 35.374770910000052],
            [-80.94539427999996, 35.374721909000073],
            [-80.94534127999998, 35.374740909000025],
            [-80.945276279999973, 35.374751910000043],
            [-80.945219279999947, 35.374756909000041],
            [-80.945067279999989, 35.374750909000056],
            [-80.944997279999939, 35.374708910000038],
            [-80.944980280999971, 35.374660909000056],
            [-80.944973279999942, 35.374576909000041],
            [-80.944978280999976, 35.374413909000054],
            [-80.944975279999937, 35.374299909000058],
            [-80.944987279999964, 35.374210909000055],
            [-80.945079279999959, 35.373952910000071],
            [-80.945104279999953, 35.373847909000062],
            [-80.945117280999966, 35.373771909000027],
            [-80.945109280999986, 35.373686909000071],
            [-80.945090279999988, 35.373614909000025],
            [-80.944964279999965, 35.373459909000076],
            [-80.944849279999971, 35.373384909000038],
            [-80.944792279999945, 35.373363909000034],
            [-80.944680280999989, 35.373352908000072],
            [-80.944604279999965, 35.373359909000044],
            [-80.944526279999934, 35.373358909000046],
            [-80.944430279999949, 35.373343909000027],
            [-80.944294279999951, 35.373304909000069],
            [-80.944116280999935, 35.373201908000055],
            [-80.944013279999979, 35.373189909000075],
            [-80.94385127999999, 35.373225909000041],
            [-80.943738279999934, 35.373241909000058],
            [-80.94372027999998, 35.373174908000067],
            [-80.943767279999975, 35.373071909000032],
            [-80.943780279999942, 35.373016909000057],
            [-80.943797279999956, 35.372863908000056],
            [-80.943799279999951, 35.372796909000044],
            [-80.943791279999971, 35.372740909000072],
            [-80.943758278999951, 35.372676909000063],
            [-80.943714279999938, 35.37261490800006],
            [-80.943682279999962, 35.372549909000043],
            [-80.943538279999984, 35.372390909000046],
            [-80.943459279999956, 35.372315908000076],
            [-80.943286279999938, 35.372217909000028],
            [-80.943168278999963, 35.372219908000034],
            [-80.943057279999948, 35.372151909000024],
            [-80.943019279999987, 35.372112908000076],
            [-80.943008278999969, 35.372053908000055],
            [-80.943049279999968, 35.372011908000047],
            [-80.943159278999985, 35.37194290900004],
            [-80.94325127999997, 35.371857909000028],
            [-80.943279279999956, 35.371813908000036],
            [-80.943293279999978, 35.371756908000066],
            [-80.943298278999976, 35.371673908000048],
            [-80.94325527899997, 35.371611908000034],
            [-80.94316927999995, 35.371584908000045],
            [-80.943058278999956, 35.371584908000045],
            [-80.942953279999983, 35.371608909000031],
            [-80.942867278999984, 35.371655908000037],
            [-80.942821278999986, 35.371725909000077],
            [-80.942761279999957, 35.371789908000039],
            [-80.942654279999942, 35.371855908000043],
            [-80.942523279999989, 35.371964908000052],
            [-80.94247927899994, 35.37200690800006],
            [-80.942440279999971, 35.372076909000043],
            [-80.942440278999982, 35.37213390900007],
            [-80.942420278999975, 35.372203909000064],
            [-80.94237327999997, 35.372238908000043],
            [-80.942272278999951, 35.372266909000075],
            [-80.942181279999943, 35.372247909000066],
            [-80.942061278999972, 35.372127909000028],
            [-80.941877279999972, 35.371971908000035],
            [-80.941659279999953, 35.371805909000045],
            [-80.941564278999977, 35.371749909000073],
            [-80.941437278999956, 35.371703909000075],
            [-80.94127827799997, 35.371636909000074],
            [-80.941177278999987, 35.371630909000032],
            [-80.941081278999945, 35.371647909000046],
            [-80.940978278999978, 35.371629909000035],
            [-80.940897277999966, 35.371579908000058],
            [-80.940735277999977, 35.371531909000055],
            [-80.940656277999949, 35.371538908000048],
            [-80.940511279999953, 35.371617909000065],
            [-80.940451277999955, 35.371629909000035],
            [-80.940369278999981, 35.371636908000028],
            [-80.940260278999972, 35.37163590800003],
            [-80.940199278999955, 35.371648909000044],
            [-80.940121278999982, 35.371667908000063],
            [-80.939962278999985, 35.371702908000032],
            [-80.939903278999964, 35.371696909000036],
            [-80.939831278999975, 35.371701908000034],
            [-80.939763277999987, 35.371679908000033],
            [-80.939651278999975, 35.371589909000079],
            [-80.939616278999949, 35.371542909000027],
            [-80.939543277999974, 35.371501908000027],
            [-80.939460277999956, 35.37148890900005],
            [-80.939376277999941, 35.371497908000038],
            [-80.939276278999955, 35.37152990900006],
            [-80.939167277999957, 35.371547909000071],
            [-80.939065278999976, 35.371540908000043],
            [-80.938912278999965, 35.37149690800004],
            [-80.938803277999966, 35.37149690800004],
            [-80.938707277999981, 35.37152590900007],
            [-80.938655277999942, 35.37154590800003],
            [-80.938575278999963, 35.371525908000024],
            [-80.938473277999947, 35.371452909000027],
            [-80.93835927799995, 35.371273908000035],
            [-80.938278277999984, 35.371230909000076],
            [-80.938193277999972, 35.371168908000072],
            [-80.938150277999966, 35.371129908000057],
            [-80.938105277999966, 35.371099909000066],
            [-80.938019277999956, 35.371066909000035],
            [-80.937891276999949, 35.371028908000028],
            [-80.937711276999948, 35.370956909000029],
            [-80.937690276999945, 35.370898908000072],
            [-80.937791276999974, 35.370828909000068],
            [-80.93789327799999, 35.370795908000048],
            [-80.938211278999972, 35.370713909000074],
            [-80.93835927799995, 35.370711909000079],
            [-80.939408277999974, 35.370045908000066],
            [-80.939447277999989, 35.369959908000055],
            [-80.939463277999948, 35.36990890900006],
            [-80.939451278999968, 35.369833909000079],
            [-80.939415277999956, 35.369770909000067],
            [-80.939375277999943, 35.369709908000061],
            [-80.939089278999973, 35.369506908000062],
            [-80.938970277999942, 35.369385908000027],
            [-80.938924277999945, 35.369313907000048],
            [-80.938851277999959, 35.369117908000078],
            [-80.938844277999976, 35.369062908000046],
            [-80.938778277999972, 35.368987908000065],
            [-80.939062277999938, 35.368288908000068],
            [-80.939097277999963, 35.368247908000058],
            [-80.939160277999974, 35.36819390800008],
            [-80.939266277999934, 35.368137908000051],
            [-80.939393277999955, 35.368089908000059],
            [-80.939472277999982, 35.368035908000024],
            [-80.939497277999976, 35.367974908000065],
            [-80.939491277999934, 35.367910907000066],
            [-80.939411277999966, 35.36783790700008],
            [-80.939286278999987, 35.367772907000074],
            [-80.938902276999954, 35.367616908000059],
            [-80.938721277999946, 35.367535908000036],
            [-80.938401277999958, 35.367365907000078],
            [-80.938334277999957, 35.36731590800008],
            [-80.938249277999944, 35.367170908000048],
            [-80.938083277999965, 35.367020908000029],
            [-80.937960277999935, 35.366937907000079],
            [-80.937657276999971, 35.366777907000028],
            [-80.937550277999947, 35.366740907000064],
            [-80.937482276999958, 35.366731908000077],
            [-80.937428276999981, 35.366714908000063],
            [-80.937223276999987, 35.366612907000047],
            [-80.936862276999989, 35.366450908000047],
            [-80.936703277999982, 35.36640890700005],
            [-80.936567276999938, 35.366360908000047],
            [-80.936523276999935, 35.366327908000073],
            [-80.936449277999941, 35.366297907000046],
            [-80.93637027699998, 35.366290907000064],
            [-80.936187276999988, 35.36622090700007],
            [-80.936107277999952, 35.366179908000049],
            [-80.936005277999982, 35.366127908000067],
            [-80.935854277999965, 35.366085908000059],
            [-80.935776277999935, 35.366058907000024],
            [-80.935448276999978, 35.36593190800005],
            [-80.935318276999965, 35.365886907000061],
            [-80.935251276999963, 35.365858908000064],
            [-80.935063275999937, 35.365765907000025],
            [-80.935004275999972, 35.365729907000059],
            [-80.934888275999981, 35.365683908000051],
            [-80.934726276999982, 35.365591908000056],
            [-80.934676275999948, 35.365550907000056],
            [-80.934568275999936, 35.365480907000062],
            [-80.934379275999959, 35.365401907000034],
            [-80.934314276999942, 35.365378907000036],
            [-80.934180276999939, 35.365315907000024],
            [-80.934106275999966, 35.36528690800003],
            [-80.934057276999965, 35.365260907000049],
            [-80.933999276999941, 35.36524590700003],
            [-80.933901276999961, 35.365231907000066],
            [-80.933843276999937, 35.365212907000057],
            [-80.933689276999985, 35.365133908000075],
            [-80.933456275999959, 35.364997907000031],
            [-80.933346275999952, 35.364923908000037],
            [-80.933155275999979, 35.364827907000063],
            [-80.933036275999939, 35.364789907000045],
            [-80.932864275999975, 35.364755907000074],
            [-80.932736274999968, 35.36474290700005],
            [-80.93254727499999, 35.364733907000073],
            [-80.932445275999953, 35.36473490700007],
            [-80.932345274999989, 35.364742908000039],
            [-80.932143275999977, 35.364768907000041],
            [-80.932050276999973, 35.364795908000076],
            [-80.932097275999979, 35.364854907000051],
            [-80.932231275999982, 35.364961908000055],
            [-80.93228927499996, 35.365002907000076],
            [-80.932314275999943, 35.365067907000025],
            [-80.932348275999971, 35.365137908000065],
            [-80.932384274999947, 35.365177908000078],
            [-80.93246127599997, 35.365210907000062],
            [-80.932537275999948, 35.36524890700008],
            [-80.93264127599997, 35.365331907000041],
            [-80.932699275999937, 35.36539990700004],
            [-80.93278827599994, 35.36552290700007],
            [-80.932814275999988, 35.365685907000056],
            [-80.932801275999964, 35.365733907000049],
            [-80.93277627599997, 35.365799908000042],
            [-80.932762276999938, 35.365852908000079],
            [-80.932752276999963, 35.365924908000068],
            [-80.93269427599995, 35.366073908000033],
            [-80.932630275999941, 35.366150908000066],
            [-80.932584275999943, 35.366229908000037],
            [-80.932580274999964, 35.366283908000071],
            [-80.932583275999946, 35.366379908000056],
            [-80.932584275999943, 35.366443908000065],
            [-80.93260027599996, 35.366499908000037],
            [-80.932625275999953, 35.36654390700005],
            [-80.932653276999986, 35.366623908000065],
            [-80.932673276999935, 35.366720907000058],
            [-80.932667275999961, 35.366794908000031],
            [-80.932627276999938, 35.367005908000067],
            [-80.93258827599999, 35.367104908000044],
            [-80.93253627699994, 35.367154908000032],
            [-80.93250627599997, 35.367223908000028],
            [-80.932440275999966, 35.367416908000052],
            [-80.932302275999973, 35.367851908000034],
            [-80.932299275999981, 35.367974908000065],
            [-80.932327275999967, 35.368057908000026],
            [-80.932387275999986, 35.368182908000051],
            [-80.932496276999984, 35.368497908000052],
            [-80.932561275999944, 35.36864790900006],
            [-80.93265327599994, 35.368916908000074],
            [-80.932707276999963, 35.369054909000056],
            [-80.932738275999952, 35.369158908000031],
            [-80.932747275999986, 35.369310909000035],
            [-80.932778275999965, 35.369376908000049],
            [-80.932849275999956, 35.369496908000031],
            [-80.932885276999968, 35.369577908000053],
            [-80.932921275999945, 35.369625908000046],
            [-80.93305027599996, 35.369752908000066],
            [-80.933142275999955, 35.369765908000034],
            [-80.933238276999987, 35.36974490800003],
            [-80.933354276999978, 35.369739909000032],
            [-80.933423275999985, 35.369771909000065],
            [-80.933501276999948, 35.369910908000065],
            [-80.933502275999956, 35.369981908000057],
            [-80.933523276999949, 35.37005190900004],
            [-80.93351927599997, 35.370108909000066],
            [-80.933479275999957, 35.370160909000049],
            [-80.933429276999959, 35.370209908000049],
            [-80.933408275999966, 35.370271908000063],
            [-80.933377276999977, 35.370319909000045],
            [-80.933364276999953, 35.370364909000045],
            [-80.93336527699995, 35.370421909000072],
            [-80.933318276999955, 35.370488908000027],
            [-80.933233275999953, 35.370503908000046],
            [-80.933120276999944, 35.37056990800005],
            [-80.933063276999974, 35.370556908000026],
            [-80.932935275999967, 35.370592909000038],
            [-80.932855275999941, 35.370626909000066],
            [-80.932744275999937, 35.370718909000061],
            [-80.932677275999936, 35.370813909000049],
            [-80.932601275999957, 35.370900909000056],
            [-80.932541275999938, 35.370976908000046],
            [-80.932440276999955, 35.371055909000063],
            [-80.932469275999949, 35.370924909000053],
            [-80.932550276999962, 35.370687908000036],
            [-80.932576276999953, 35.370510908000028],
            [-80.932607275999942, 35.370438909000029],
            [-80.932558275999952, 35.370398909000073],
            [-80.932439276999958, 35.370365909000043],
            [-80.932277275999979, 35.370279908000043],
            [-80.932223276999935, 35.370240908000028],
            [-80.932148276999953, 35.370220909000068],
            [-80.932065276999936, 35.370203909000054],
            [-80.93200027599994, 35.370149909000077],
            [-80.931847275999985, 35.370103909000079],
            [-80.931793275999951, 35.370036908000031],
            [-80.931708275999938, 35.369951909000065],
            [-80.931663275999938, 35.369917909000037],
            [-80.93157227599994, 35.369882909000069],
            [-80.931423275999975, 35.369794909000063],
            [-80.931389274999958, 35.369748908000076],
            [-80.931370274999949, 35.369669908000049],
            [-80.931354275999979, 35.369566908000024],
            [-80.93125727599994, 35.369499909000069],
            [-80.931232276999935, 35.369420908000052],
            [-80.931240274999936, 35.369293908000031],
            [-80.93111827499996, 35.369231909000064],
            [-80.930790275999982, 35.369038909000039],
            [-80.930710274999967, 35.369002908000027],
            [-80.93060427599994, 35.368994908000047],
            [-80.930430275999981, 35.369007908000071],
            [-80.930369275999965, 35.369019908000041],
            [-80.930297274999987, 35.369010909000053],
            [-80.930222274999949, 35.368994909000037],
            [-80.930027274999986, 35.368893908000075],
            [-80.929922274999967, 35.36886690800003],
            [-80.929683275999935, 35.368886908000036],
            [-80.929602274999979, 35.368921908000061],
            [-80.92949327599996, 35.368959909000068],
            [-80.929373274999989, 35.368991909000044],
            [-80.929312274999972, 35.369021908000036],
            [-80.929236275999983, 35.369052908000072],
            [-80.929160274999958, 35.369068908000031],
            [-80.928963275999934, 35.369085909000034],
            [-80.928887274999965, 35.36908790800004],
            [-80.928865274999964, 35.369106908000049],
            [-80.928495274999989, 35.369219908000048],
            [-80.928338274999987, 35.369334908000042],
            [-80.928194274999953, 35.369379908000042],
            [-80.928081274999954, 35.369435908000071],
            [-80.92788427499994, 35.369508908000057],
            [-80.92773727499997, 35.369576909000045],
            [-80.927644275999967, 35.36963490800008],
            [-80.927495273999966, 35.369737909000037],
            [-80.927431274999947, 35.369793909000066],
            [-80.927173274999973, 35.369903908000026],
            [-80.927077274999988, 35.369922908000035],
            [-80.927004273999955, 35.370049909000045],
            [-80.92691627499994, 35.37009690800005],
            [-80.926853274999985, 35.370030909000036],
            [-80.926819273999968, 35.369983909000041],
            [-80.926747274999968, 35.370000908000065],
            [-80.926692273999947, 35.370046909000052],
            [-80.92669527399994, 35.370126909000078],
            [-80.926714273999949, 35.370179909000058],
            [-80.926763274999985, 35.370279909000033],
            [-80.926788273999989, 35.370359909000058],
            [-80.92674027399994, 35.370449909000058],
            [-80.926671273999943, 35.370473909000054],
            [-80.926647274999937, 35.370477909000044],
            [-80.926550274999954, 35.370426909000059],
            [-80.926472274999981, 35.370343910000031],
            [-80.926367273999972, 35.370246909000059],
            [-80.926316274999976, 35.37021990900007],
            [-80.92625527499996, 35.370207910000033],
            [-80.925777273999984, 35.370203909000054],
            [-80.925436274999981, 35.370209909000039],
            [-80.92534427399994, 35.370196908000025],
            [-80.925216273999979, 35.370198909000067],
            [-80.925071273999947, 35.370224909000058],
            [-80.924815273999968, 35.370299909000039],
            [-80.924345273999961, 35.370425908000072],
            [-80.924012273999949, 35.370534909000071],
            [-80.923922273999949, 35.370560909000062],
            [-80.923860273999935, 35.370535909000068],
            [-80.923882272999947, 35.370464908000031],
            [-80.923837273999936, 35.370388909000042],
            [-80.923806272999968, 35.37032190900004],
            [-80.923930272999939, 35.370262909000076],
            [-80.923987272999966, 35.370245909000062],
            [-80.924134273999982, 35.370220909000068],
            [-80.924201273999984, 35.370188909000035],
            [-80.924404273999983, 35.370027909000044],
            [-80.924647273999938, 35.369866908000063],
            [-80.924890273999949, 35.369698909000078],
            [-80.925125274999971, 35.369640909000054],
            [-80.925207272999955, 35.369631909000077],
            [-80.925412273999939, 35.369635909000067],
            [-80.925507273999983, 35.369619908000061],
            [-80.925604273999966, 35.369592908000072],
            [-80.925687273999984, 35.369588908000026],
            [-80.925786273999961, 35.369590909000067],
            [-80.925853273999962, 35.369575909000048],
            [-80.925979274999975, 35.369557909000036],
            [-80.926052273999971, 35.369560909000029],
            [-80.92612027499996, 35.369557908000047],
            [-80.926316274999976, 35.369527909000055],
            [-80.926514273999942, 35.369452908000028],
            [-80.926733274999947, 35.369343909000065],
            [-80.926893273999951, 35.369256908000068],
            [-80.927057274999981, 35.369149909000043],
            [-80.927157273999967, 35.369100908000064],
            [-80.927280274999987, 35.369047908000027],
            [-80.927416274999985, 35.369008908000069],
            [-80.927581274999966, 35.36895290800004],
            [-80.927781273999983, 35.368897908000065],
            [-80.928074274999972, 35.368773908000037],
            [-80.928148275999945, 35.368706909000025],
            [-80.92822827599997, 35.368601908000073],
            [-80.928331274999948, 35.368525909000027],
            [-80.928407274999984, 35.368459908000034],
            [-80.928467274999946, 35.368419908000078],
            [-80.928576275999944, 35.36838990800004],
            [-80.928628274999937, 35.368351908000079],
            [-80.928682274999971, 35.368305909000071],
            [-80.928748274999975, 35.368211908000035],
            [-80.928781273999959, 35.368151908000073],
            [-80.928833275999978, 35.368087908000064],
            [-80.928985274999945, 35.368060909000064],
            [-80.929063275999965, 35.368039908000071],
            [-80.929118274999951, 35.368011908000028],
            [-80.92917027499999, 35.367978909000044],
            [-80.929218274999982, 35.367914908000046],
            [-80.92920027599996, 35.367817908000063],
            [-80.929252274999953, 35.367774908000058],
            [-80.929308274999983, 35.367823908000048],
            [-80.92945227499996, 35.367925909000064],
            [-80.929523274999951, 35.367958909000038],
            [-80.929621274999988, 35.367991908000079],
            [-80.929714274999981, 35.368000908000056],
            [-80.929794274999949, 35.36800290900004],
            [-80.929875274999972, 35.368032908000032],
            [-80.929961275999972, 35.368073908000042],
            [-80.930049275999977, 35.368051908000041],
            [-80.93013127599994, 35.368006908000041],
            [-80.93027727599997, 35.367992908000076],
            [-80.930403275999936, 35.367989908000027],
            [-80.930492274999949, 35.36798090800005],
            [-80.930583275999936, 35.36798090800005],
            [-80.930673275999936, 35.367996908000066],
            [-80.930749275999972, 35.367981909000036],
            [-80.931031275999942, 35.367817908000063],
            [-80.93108127499994, 35.367793908000067],
            [-80.931132275999971, 35.367762908000032],
            [-80.931188275999943, 35.367711908000047],
            [-80.931261275999987, 35.367627909000078],
            [-80.931287275999978, 35.367565908000074],
            [-80.93124527599997, 35.367469908000032],
            [-80.931231275999949, 35.36742190700005],
            [-80.931226275999961, 35.36737290800005],
            [-80.93124127599998, 35.367279908000057],
            [-80.931226275999961, 35.36718490800007],
            [-80.931200274999981, 35.367107908000037],
            [-80.931145274999949, 35.366975908000029],
            [-80.93107727499995, 35.366838908000034],
            [-80.930994275999979, 35.366640907000033],
            [-80.930938275999949, 35.36654790800003],
            [-80.930895275999944, 35.366488908000065],
            [-80.930803275999949, 35.366395908000072],
            [-80.930711275999954, 35.36635590800006],
            [-80.930654274999938, 35.366340908000041],
            [-80.930595275999963, 35.366332908000061],
            [-80.930445275999944, 35.366341908000038],
            [-80.930366275999972, 35.366326908000076],
            [-80.930329275999952, 35.366242908000061],
            [-80.930284274999963, 35.36611590800004],
            [-80.930224274999944, 35.365810908000071],
            [-80.930195275999949, 35.365503908000051],
            [-80.930175275999943, 35.365444908000029],
            [-80.930167274999974, 35.365394907000052],
            [-80.930146275999959, 35.36533890700008],
            [-80.930045274999941, 35.365141907000066],
            [-80.93003727599995, 35.36505790700005],
            [-80.930092275999982, 35.364973907000035],
            [-80.930145274999973, 35.36491490700007],
            [-80.930304274999969, 35.364826908000055],
            [-80.93038327499994, 35.364805908000051],
            [-80.930423274999953, 35.364765908000038],
            [-80.930517274999943, 35.364709908000066],
            [-80.930384274999938, 35.364551907000077],
            [-80.930051274999983, 35.364476907000039],
            [-80.931238275999988, 35.363070908000054],
            [-80.930594274999976, 35.36306490700008],
            [-80.929739274999974, 35.362980907000065],
            [-80.928982274999953, 35.362898907000044],
            [-80.928208274999974, 35.362793908000071],
            [-80.927440273999935, 35.362626907000049],
            [-80.92741827399999, 35.362621907000062],
            [-80.926707274999956, 35.362495907000039],
            [-80.926291273999936, 35.362338907000037],
            [-80.92598127499997, 35.362221907000048],
            [-80.925309273999972, 35.362021907000042],
            [-80.924681273999965, 35.361785907000069],
            [-80.924214272999961, 35.361662907000039],
            [-80.923735273999966, 35.361265906000028],
            [-80.923344272999941, 35.360906907000071],
            [-80.922997272999964, 35.360644907000051],
            [-80.922885272999963, 35.360340907000079],
            [-80.922953272999962, 35.360082907000049],
            [-80.923005272999944, 35.359930907000034],
            [-80.923048272999949, 35.359810907000053],
            [-80.923107271999982, 35.359699907000049],
            [-80.923384272999954, 35.359186907000037],
            [-80.923857273999943, 35.358956907000049],
            [-80.924387273999969, 35.358622906000051],
            [-80.924869273999946, 35.358369907000053],
            [-80.925468272999979, 35.358169906000057],
            [-80.926376273999949, 35.357870906000073],
            [-80.927011273999938, 35.357641906000026],
            [-80.92766327399994, 35.357530906000079],
            [-80.929344273999959, 35.357254906000037],
            [-80.931328274999942, 35.357603906000065],
            [-80.932488275999958, 35.357533905000025],
            [-80.93303227499996, 35.357377905000078],
            [-80.933440275999942, 35.357279907000077],
            [-80.933931275999953, 35.357092906000048],
            [-80.934550275999982, 35.356789906000074],
            [-80.935498275999976, 35.356246905000035],
            [-80.936612276999938, 35.355506905000027],
            [-80.938330277999967, 35.35428590500004],
            [-80.93908027699996, 35.353654904000052],
            [-80.939648276999947, 35.35317890500005],
            [-80.940401276999978, 35.352455904000067],
            [-80.94059227799994, 35.35230490500004],
            [-80.941178277999938, 35.351844905000064],
            [-80.941764277999937, 35.351363904000038],
            [-80.942357277999974, 35.351016904000062],
            [-80.942921277999972, 35.350705904000051],
            [-80.943613277999987, 35.350518904000069],
            [-80.94351927799994, 35.349537903000055],
            [-80.942990278999957, 35.348711903000037],
            [-80.942669277999983, 35.348181903000068],
            [-80.942409277999957, 35.347798904000058],
            [-80.94227727699996, 35.347574903000066],
            [-80.942118276999963, 35.347282904000053],
            [-80.941999277999969, 35.34707390300008],
            [-80.941861277999976, 35.346818903000042],
            [-80.941480277999972, 35.346155903000067],
            [-80.941179276999947, 35.345594903000062],
            [-80.941047276999939, 35.345381903000032],
            [-80.940969276999965, 35.345268902000043],
            [-80.94078327699998, 35.345015902000057],
            [-80.940368276999948, 35.344496903000049],
            [-80.939629276999938, 35.343554902000051],
            [-80.939531276999958, 35.343406903000073],
            [-80.939476276999983, 35.343289903000027],
            [-80.939431275999937, 35.34314990200005],
            [-80.93939927699995, 35.34300690200007],
            [-80.93938327799998, 35.342853902000058],
            [-80.93938327799998, 35.34266890300006],
            [-80.93940727599994, 35.342278902000032],
            [-80.939424275999954, 35.342101902000024],
            [-80.939429276999988, 35.341938902000038],
            [-80.939427276999936, 35.341836902000068],
            [-80.939405276999935, 35.341645902000039],
            [-80.939384277999977, 35.341562902000078],
            [-80.939224276999937, 35.341134902000078],
            [-80.938977275999946, 35.340532901000074],
            [-80.938717276999967, 35.339929902000051],
            [-80.938518276999957, 35.339444902000025],
            [-80.938483276999989, 35.339345902000048],
            [-80.938422276999972, 35.339174902000025],
            [-80.938392275999945, 35.33864690200005],
            [-80.938361275999966, 35.338452902000029],
            [-80.938365276999946, 35.338356902000044],
            [-80.938357276999966, 35.338205902000027],
            [-80.938354275999984, 35.338140902000077],
            [-80.938350276999984, 35.337467901000025],
            [-80.938343276999944, 35.33713890100006],
            [-80.938342276999947, 35.337026901000058],
            [-80.938336275999973, 35.336255902000062],
            [-80.938323276999938, 35.336087901000042],
            [-80.938286275999985, 35.335769900000059],
            [-80.938265275999981, 35.335649901000068],
            [-80.938244275999978, 35.335567901000047],
            [-80.938180274999979, 35.335369901000035],
            [-80.938062275999982, 35.33509390100005],
            [-80.937629275999939, 35.334163901000068],
            [-80.937444274999962, 35.333735901000068],
            [-80.937367275999975, 35.333578901000067],
            [-80.937335275999942, 35.333532901000069],
            [-80.93727127599999, 35.33346490100007],
            [-80.937074275999976, 35.333657901000038],
            [-80.93690027599996, 35.333810901000049],
            [-80.936738274999982, 35.333935901000075],
            [-80.936569275999943, 35.334047901000076],
            [-80.936454275999949, 35.33411390100008],
            [-80.936303274999943, 35.334190900000067],
            [-80.936036274999935, 35.334309900000051],
            [-80.935716274999947, 35.334438901000055],
            [-80.934599275999972, 35.334859901000073],
            [-80.933532274999948, 35.335162902000036],
            [-80.933190273999969, 35.335206901000049],
            [-80.932999274999986, 35.335221901000068],
            [-80.932706274999987, 35.335230901000045],
            [-80.932595274999983, 35.335227900000064],
            [-80.932348273999935, 35.335209901000042],
            [-80.931947273999981, 35.335158901000057],
            [-80.931485273999954, 35.335090901000058],
            [-80.931117272999984, 35.335036901000024],
            [-80.930520273999946, 35.334949901000073],
            [-80.92988827299996, 35.334853901000031],
            [-80.929607272999988, 35.33481590100007],
            [-80.929238273999943, 35.334783901000037],
            [-80.929099273999952, 35.33477890100005],
            [-80.928812273999938, 35.334785901000032],
            [-80.928575272999979, 35.334811902000069],
            [-80.92827127299995, 35.334871902000032],
            [-80.928058272999976, 35.334924901000079],
            [-80.927047272999971, 35.335204901000054],
            [-80.92687927299994, 35.335248901000057],
            [-80.926796272999979, 35.335270901000058],
            [-80.926530272999969, 35.335335902000054],
            [-80.926275272999987, 35.335388902000034],
            [-80.926120272999981, 35.335420901000077],
            [-80.925922272999969, 35.335457901000041],
            [-80.925475272999961, 35.335525902000029],
            [-80.924589271999935, 35.335650902000054],
            [-80.923879272999955, 35.335755901000027],
            [-80.922743270999945, 35.335917902000062],
            [-80.922506271999964, 35.335944902000051],
            [-80.922315270999945, 35.33595990200007],
            [-80.922054270999979, 35.335964901000068],
            [-80.921763270999975, 35.335949901000049],
            [-80.921535271999971, 35.335921902000052],
            [-80.921094270999959, 35.335838902000035],
            [-80.920474269999943, 35.335705902000029],
            [-80.919887270999936, 35.335573902000078],
            [-80.919338269999969, 35.335464902000069],
            [-80.919103269999937, 35.33542790100006],
            [-80.918893269999955, 35.335401901000068],
            [-80.918643269999961, 35.335378902000059],
            [-80.918231269999978, 35.335358902000053],
            [-80.917946269999959, 35.335357902000055],
            [-80.917583269999966, 35.335371902000077],
            [-80.917232269999943, 35.335398902000065],
            [-80.917037268999934, 35.335420901000077],
            [-80.916989269999988, 35.335429901000055],
            [-80.916250269999978, 35.335565902000042],
            [-80.91604426899994, 35.335600901000078],
            [-80.915287268999975, 35.335730901000034],
            [-80.915230269999938, 35.335741902000052],
            [-80.914652268999987, 35.335847901000079],
            [-80.914298268999971, 35.335921902000052],
            [-80.913991268999951, 35.336001903000067],
            [-80.913783268999964, 35.335749902000032],
            [-80.913221267999972, 35.335088901000063],
            [-80.912926268999968, 35.334676902000069],
            [-80.912808267999935, 35.33448590100005],
            [-80.912635267999974, 35.334186902000056],
            [-80.912550268999951, 35.334019901000033],
            [-80.912437267999962, 35.333744901000046],
            [-80.912311267999939, 35.33343190100004],
            [-80.912154267999938, 35.333011901000077],
            [-80.912076268999954, 35.332775901000048],
            [-80.911465266999983, 35.333212902000071],
            [-80.910880267999971, 35.333668901000067],
            [-80.910338267999975, 35.334129902000029],
            [-80.909756267999967, 35.334668902000033],
            [-80.908401266999988, 35.33609490200007],
            [-80.908098266999957, 35.336433902000067],
            [-80.907493267999939, 35.337073902000043],
            [-80.907158266999943, 35.337402903000054],
            [-80.906958266999936, 35.337583902000063],
            [-80.906539266999971, 35.337929903000031],
            [-80.906271266999966, 35.338130902000046],
            [-80.905818266999972, 35.338432902000079],
            [-80.905344265999986, 35.338712903000044],
            [-80.904871265999986, 35.338948903000073],
            [-80.904596265999942, 35.339086903000066],
            [-80.904338266999957, 35.339198903000067],
            [-80.904076266999937, 35.33930390300003],
            [-80.90366626499997, 35.339453903000049],
            [-80.903121265999971, 35.339621903000079],
            [-80.902564265999956, 35.339762904000054],
            [-80.901998265999964, 35.339875904000053],
            [-80.901424264999946, 35.339960904000066],
            [-80.900845265999976, 35.340016903000048],
            [-80.900366263999956, 35.340041903000042],
            [-80.899729264999962, 35.340043903000037],
            [-80.899058264999951, 35.340017904000035],
            [-80.89846526499997, 35.339970903000051],
            [-80.89573726399999, 35.33970490400003],
            [-80.894385262999947, 35.33957390300003],
            [-80.894369263999977, 35.339608904000045],
            [-80.894295263999936, 35.339781904000063],
            [-80.893663262999951, 35.339722904000041],
            [-80.89293926299996, 35.339693903000068],
            [-80.892214261999982, 35.339693904000058],
            [-80.891491261999988, 35.339723904000039],
            [-80.890769260999946, 35.339781904000063],
            [-80.890045261999944, 35.339869903000078],
            [-80.88933726099998, 35.33998590300007],
            [-80.888637260999985, 35.340128904000039],
            [-80.887946260999968, 35.34030090400006],
            [-80.887266260999979, 35.340499904000069],
            [-80.886599260999958, 35.340724904000069],
            [-80.885946260999958, 35.340977903000066],
            [-80.885517260999961, 35.341164904000038],
            [-80.885309260999975, 35.341255904000036],
            [-80.884690259999957, 35.341558905000056],
            [-80.884371259999966, 35.341732904000025],
            [-80.883687259999988, 35.342137904000026],
            [-80.883025259999954, 35.34256690400008],
            [-80.882290258999944, 35.343056904000036],
            [-80.881581259999962, 35.343501905000039],
            [-80.881512259999965, 35.343540905000054],
            [-80.881412258999944, 35.343598905000079],
            [-80.881198258999973, 35.343458904000045],
            [-80.879566258999944, 35.342389904000072],
            [-80.87930825899997, 35.342196905000037],
            [-80.879076258999987, 35.341982904000076],
            [-80.878871258999936, 35.341751905000024],
            [-80.87847925899996, 35.341187905000027],
            [-80.877396257999976, 35.33927990400008],
            [-80.877251258999934, 35.339018904000056],
            [-80.876688256999955, 35.338040904000025],
            [-80.875991256999953, 35.336813904000053],
            [-80.875699257999941, 35.336300903000051],
            [-80.875323256999934, 35.335643903000062],
            [-80.874933256999952, 35.334947903000057],
            [-80.874720256999979, 35.334524902000055],
            [-80.874418257999935, 35.333874903000037],
            [-80.874203256999976, 35.333372903000054],
            [-80.874167257999943, 35.333288903000039],
            [-80.87377025699999, 35.332265902000074],
            [-80.873505256999977, 35.331581903000028],
            [-80.873434255999939, 35.33142390200004],
            [-80.873372255999982, 35.331318903000067],
            [-80.873282256999971, 35.331193902000052],
            [-80.873155256999951, 35.331056902000057],
            [-80.873008255999935, 35.330914902000075],
            [-80.87253325599994, 35.330483903000072],
            [-80.872197255999936, 35.330193902000076],
            [-80.871026255999936, 35.329230902000063],
            [-80.87078125499994, 35.329023901000028],
            [-80.86920525499994, 35.327736902000026],
            [-80.869153255999947, 35.327693902000078],
            [-80.868798254999945, 35.327396902000032],
            [-80.868723254999964, 35.327329901000041],
            [-80.868535253999937, 35.327162902000055],
            [-80.867973254999981, 35.326607901000045],
            [-80.86749425499994, 35.326134902000035],
            [-80.86725325499998, 35.325894902000073],
            [-80.867066253999951, 35.325689901000032],
            [-80.866915254999981, 35.325489901000026],
            [-80.866845253999941, 35.325372902000026],
            [-80.866759253999987, 35.325425902000063],
            [-80.86667625399997, 35.32549090200007],
            [-80.86582825399995, 35.326240901000062],
            [-80.865121253999973, 35.32687590200004],
            [-80.864835253999956, 35.327142902000048],
            [-80.864528253999936, 35.327425901000026],
            [-80.86436525299996, 35.32756990200005],
            [-80.864248252999971, 35.327679902000057],
            [-80.863697253999987, 35.328167902000075],
            [-80.86310825299995, 35.328702903000078],
            [-80.863009252999973, 35.328785903000039],
            [-80.862866252999936, 35.328896902000054],
            [-80.86218525299995, 35.329382903000067],
            [-80.862066252999966, 35.329463902000043],
            [-80.861916252999947, 35.329547903000048],
            [-80.861851251999951, 35.329575902000045],
            [-80.861783252999942, 35.329597903000035],
            [-80.861704252999971, 35.329617902000052],
            [-80.861599252999952, 35.329637902000059],
            [-80.861345252999968, 35.32966790200004],
            [-80.86074825299994, 35.329709903000037],
            [-80.85997925199996, 35.329773903000046],
            [-80.858887251999988, 35.329865903000041],
            [-80.858589251999945, 35.329896903000076],
            [-80.858471251999958, 35.329916903000026],
            [-80.858103251999978, 35.330004903000031],
            [-80.857984252999984, 35.330036902000074],
            [-80.857748251999965, 35.330119902000035],
            [-80.857729251999956, 35.330127903000061],
            [-80.857473251999977, 35.330229903000031],
            [-80.856667251999966, 35.330611903000033],
            [-80.856378250999967, 35.330752903000075],
            [-80.856086250999965, 35.330900903000042],
            [-80.85553625099999, 35.331162903000063],
            [-80.855145250999954, 35.331343903000061],
            [-80.854460250999978, 35.331675902000029],
            [-80.854137250999941, 35.33182090400004],
            [-80.853922250999972, 35.331908903000055],
            [-80.853711250999936, 35.331988903000024],
            [-80.853396249999946, 35.332091903000048],
            [-80.853217249999943, 35.332140904000028],
            [-80.852565250999987, 35.332294904000037],
            [-80.852470249999953, 35.332325903000026],
            [-80.852436250999972, 35.332345904000078],
            [-80.852414249999981, 35.332365903000039],
            [-80.85237725099995, 35.332429904000037],
            [-80.852298250999979, 35.332525903000032],
            [-80.851793250999947, 35.333001903000024],
            [-80.851654249999967, 35.333117904000062],
            [-80.851540249999971, 35.333201903000031],
            [-80.851365250999947, 35.333308904000035],
            [-80.851161248999972, 35.333410903000072],
            [-80.850927249999984, 35.333503904000054],
            [-80.850724249999985, 35.333565904000068],
            [-80.85054624899999, 35.333604904000026],
            [-80.849208249999947, 35.333788904000073],
            [-80.848757249999949, 35.33385090400003],
            [-80.848318248999988, 35.333908904000054],
            [-80.846675248999986, 35.334133904000055],
            [-80.845706248999988, 35.334265905000052],
            [-80.845415247999938, 35.334313904000055],
            [-80.845385247999957, 35.334321905000024],
            [-80.84527924799994, 35.334349904000078],
            [-80.844901247999985, 35.334474905000036],
            [-80.844753247999961, 35.334530904000076],
            [-80.844542248999971, 35.334623904000068],
            [-80.844467247999944, 35.334659903000045],
            [-80.844431247999978, 35.334678904000043],
            [-80.844373247999954, 35.334708904000024],
            [-80.843267247999961, 35.335338905000071],
            [-80.843024248999939, 35.335485905000041],
            [-80.842947247999973, 35.335560904000033],
            [-80.842826247999938, 35.335649905000025],
            [-80.842736246999948, 35.33569990500007],
            [-80.842654247999974, 35.335735904000046],
            [-80.842271246999985, 35.335903905000066],
            [-80.841668247999962, 35.336287905000063],
            [-80.840912246999949, 35.336750905000031],
            [-80.84009724799995, 35.337249905000078],
            [-80.839898246999951, 35.337366906000057],
            [-80.839705246999984, 35.33745990500006],
            [-80.839514246999954, 35.337533905000043],
            [-80.839332246999959, 35.33759490500006],
            [-80.839217246999965, 35.337627905000033],
            [-80.839147245999982, 35.337646905000042],
            [-80.839081245999978, 35.337665905000051],
            [-80.838583245999985, 35.337782904000051],
            [-80.838211245999958, 35.337865905000058],
            [-80.836923245999969, 35.338192905000028],
            [-80.835758245999955, 35.338477906000037],
            [-80.835352244999967, 35.338581905000069],
            [-80.834883245999947, 35.338696905000063],
            [-80.834301245999939, 35.338856905000057],
            [-80.833521244999986, 35.339038905000052],
            [-80.833271244999935, 35.339081906000047],
            [-80.83313024499995, 35.339098905000071],
            [-80.832898244999967, 35.339110905000041],
            [-80.832734244999983, 35.339113905000033],
            [-80.832505244999936, 35.339108905000046],
            [-80.831935243999965, 35.339097905000074],
            [-80.82832024399994, 35.339004906000071],
            [-80.827885242999969, 35.33899490500005],
            [-80.826954243999978, 35.338973906000035],
            [-80.824361242999942, 35.338903906000041],
            [-80.824177242999951, 35.338893905000077],
            [-80.824059241999976, 35.338882906000038],
            [-80.823989241999982, 35.338875906000055],
            [-80.823682240999972, 35.338825906000068],
            [-80.822240240999975, 35.338573906000079],
            [-80.821838241999956, 35.338496906000046],
            [-80.82133324199998, 35.338373905000026],
            [-80.821005240999966, 35.338276906000033],
            [-80.820791240999938, 35.338200906000054],
            [-80.820685240999978, 35.338156906000052],
            [-80.820576240999969, 35.338105906000067],
            [-80.820470241999942, 35.338043905000063],
            [-80.820338240999945, 35.337945905000026],
            [-80.82028324099997, 35.337896905000036],
            [-80.820137240999941, 35.33774190500003],
            [-80.820023240999944, 35.337605905000032],
            [-80.819780241999979, 35.337299906000055],
            [-80.819480240999951, 35.336958906000064],
            [-80.819046239999977, 35.336487906000059],
            [-80.81894024099995, 35.336390906000076],
            [-80.818828239999959, 35.336303906000069],
            [-80.818762240999945, 35.336263905000067],
            [-80.81867023999996, 35.336222905000056],
            [-80.818559239999956, 35.33619490600006],
            [-80.818475239999941, 35.336184906000028],
            [-80.818372239999974, 35.336182905000044],
            [-80.818214240999964, 35.336200905000055],
            [-80.818072239999935, 35.336227905000044],
            [-80.817950239999959, 35.336259906000066],
            [-80.817765240999961, 35.336318906000031],
            [-80.817630239999971, 35.33637090600007],
            [-80.817470239999977, 35.336447906000046],
            [-80.81727824099994, 35.336558905000061],
            [-80.816823239999962, 35.336875906000046],
            [-80.816727239999977, 35.336942905000058],
            [-80.816589239999985, 35.337029906000055],
            [-80.816406240999981, 35.33712590600004],
            [-80.816219238999963, 35.337213905000056],
            [-80.816033239999967, 35.337280906000046],
            [-80.814406238999936, 35.337810907000062],
            [-80.813914238999985, 35.337959906000037],
            [-80.813511238999979, 35.338075906000029],
            [-80.813152238999976, 35.33816790700007],
            [-80.812428238999985, 35.338321907000079],
            [-80.811115237999957, 35.33859190700008],
            [-80.810599237999952, 35.33869790600005],
            [-80.809772237999937, 35.338860907000026],
            [-80.808983237999939, 35.339015906000043],
            [-80.808684237999955, 35.339074907000054],
            [-80.808448237999983, 35.33911390600008],
            [-80.808325237999952, 35.339128907000031],
            [-80.808193236999955, 35.339137907000065],
            [-80.808036237999943, 35.339135906000024],
            [-80.807884237999986, 35.339128906000042],
            [-80.807652236999957, 35.33909890700005],
            [-80.807210236999936, 35.339022906000025],
            [-80.805952236999985, 35.338825907000057],
            [-80.804051235999964, 35.338533907000055],
            [-80.803858236999986, 35.338503906000028],
            [-80.802102235999939, 35.338253906000034],
            [-80.801751234999983, 35.338203907000036],
            [-80.801037235999956, 35.338105907000056],
            [-80.799367234999977, 35.337972907000051],
            [-80.798496234999959, 35.337915906000035],
            [-80.796659233999947, 35.337784906000024],
            [-80.796626233999973, 35.338056907000066],
            [-80.796552233999989, 35.338581906000059],
            [-80.796434234999936, 35.339495907000071],
            [-80.796405233999963, 35.339602907000028],
            [-80.796388233999949, 35.339802908000024],
            [-80.796366233999947, 35.33993190700005],
            [-80.796322233999945, 35.340072908000025],
            [-80.796145233999937, 35.340568907000034],
            [-80.795761232999951, 35.34164290700005],
            [-80.795633233999979, 35.342015908000064],
            [-80.795506233999959, 35.342495908000046],
            [-80.795429233999982, 35.342832907000059],
            [-80.795412233999969, 35.342929908000031],
            [-80.795396233999952, 35.343105908000041],
            [-80.79539323399996, 35.343211908000058],
            [-80.795405233999986, 35.343617908000056],
            [-80.795402234999983, 35.343655908000073],
            [-80.795396233999952, 35.343708908000053],
            [-80.795429233999982, 35.344386909000036],
            [-80.795711234999942, 35.34701190800007],
            [-80.795741233999934, 35.347278909000067],
            [-80.795768233999979, 35.347550908000073],
            [-80.79579023499997, 35.34778091000004],
            [-80.795811234999974, 35.348044909000066],
            [-80.795881233999978, 35.348936908000042],
            [-80.795974233999971, 35.350094909000063],
            [-80.795977234999953, 35.350282909000043],
            [-80.795959233999952, 35.350469909000026],
            [-80.795949233999977, 35.350527910000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795912234999946, 35.350689909000039],
            [-80.795924234999973, 35.350762910000071],
            [-80.795845233999955, 35.350827909000031],
            [-80.795817233999969, 35.350859910000054],
            [-80.79579423399997, 35.350894910000079],
            [-80.795246234999979, 35.352010910000047],
            [-80.795148234999942, 35.352198910000027],
            [-80.795143233999966, 35.352207910000061],
            [-80.794993234999936, 35.352494910000075],
            [-80.794889233999982, 35.352679909000074],
            [-80.79472123499994, 35.353003910000041],
            [-80.794592233999936, 35.353298911000024],
            [-80.794308233999971, 35.354033910000055],
            [-80.794259233999981, 35.354203910000024],
            [-80.794232233999935, 35.354376910000042],
            [-80.794228233999945, 35.35450091000007],
            [-80.794243233999964, 35.354664910000054],
            [-80.794266234999952, 35.354800911000041],
            [-80.794301234999978, 35.354924911000069],
            [-80.794327234999969, 35.354984911000031],
            [-80.794382233999954, 35.355082910000078],
            [-80.79446623399997, 35.355181911000045],
            [-80.794587233999948, 35.355283911000072],
            [-80.79467923499999, 35.35533791000006],
            [-80.794827234999957, 35.355424910000067],
            [-80.795254234999959, 35.35565691100004],
            [-80.795571234999954, 35.355819910000037],
            [-80.797256235999953, 35.356629911000027],
            [-80.797561235999979, 35.356782910000049],
            [-80.797742235999976, 35.356880911000076],
            [-80.797908234999966, 35.356993910000028],
            [-80.798015235999969, 35.357091910000065],
            [-80.798130234999974, 35.357221910000078],
            [-80.798263235999968, 35.35740891100005],
            [-80.798339234999958, 35.357540911000058],
            [-80.798605235999958, 35.358067911000035],
            [-80.798719235999954, 35.358270911000034],
            [-80.798848235999969, 35.358548911000071],
            [-80.799051235999968, 35.358940911000047],
            [-80.799117234999983, 35.359049912000046],
            [-80.79924523599999, 35.359229912000046],
            [-80.799348234999968, 35.35935591100008],
            [-80.799633235999977, 35.359705911000049],
            [-80.799779235999949, 35.35991591100003],
            [-80.799856235999982, 35.360064912000041],
            [-80.799902236999969, 35.360178912000038],
            [-80.799913234999963, 35.360216912000055],
            [-80.799935235999953, 35.360268911000048],
            [-80.799952235999967, 35.360326911000072],
            [-80.799622235999948, 35.360418912000057],
            [-80.798988235999957, 35.360611912000024],
            [-80.798501235999936, 35.360765911000044],
            [-80.797442234999949, 35.361090912000066],
            [-80.797181234999982, 35.361181911000074],
            [-80.797062234999942, 35.361228911000069],
            [-80.796106234999968, 35.361662912000043],
            [-80.79504323499998, 35.362153912000053],
            [-80.794044233999955, 35.362620912000068],
            [-80.793893234999985, 35.362692912000057],
            [-80.79375323499994, 35.362753912000073],
            [-80.793432234999955, 35.362907912000026],
            [-80.792583233999949, 35.363296912000067],
            [-80.792412234999972, 35.36336691200006],
            [-80.792199233999952, 35.363451912000073],
            [-80.791902233999963, 35.363558913000077],
            [-80.791545233999955, 35.363679912000066],
            [-80.791312232999985, 35.363761913000076],
            [-80.791192232999947, 35.363803913000027],
            [-80.791017232999934, 35.363860912000064],
            [-80.79080823399994, 35.363930912000058],
            [-80.790297233999979, 35.364097912000034],
            [-80.78977523399999, 35.364281912000024],
            [-80.789337233999959, 35.364425913000048],
            [-80.789110232999974, 35.364524912000036],
            [-80.78895423299997, 35.364602913000056],
            [-80.788622231999966, 35.364780913000061],
            [-80.788296232999983, 35.364966913000046],
            [-80.787528231999943, 35.365370913000049],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365560913000024],
            [-80.787499232999949, 35.365769912000076],
            [-80.78746023399998, 35.365936914000031],
            [-80.787396232999981, 35.366161913000042],
            [-80.792751234999969, 35.368284913000025],
            [-80.794869234999965, 35.369124913000064],
            [-80.79568123599995, 35.369461914000055],
            [-80.795949234999966, 35.370134913000072],
            [-80.796038234999969, 35.370239914000024],
            [-80.796087234999959, 35.370324914000037],
            [-80.796123235999971, 35.370414914000037],
            [-80.796280235999973, 35.371011914000064],
            [-80.796325234999983, 35.371120914000073],
            [-80.796389234999936, 35.371225914000036],
            [-80.796635235999986, 35.371587914000031],
            [-80.796870235999961, 35.371958914000061],
            [-80.796915235999961, 35.372047913000074],
            [-80.796933235999973, 35.372133914000074],
            [-80.796958235999966, 35.372212914000045],
            [-80.796959235999964, 35.37238691400006],
            [-80.796966234999957, 35.372515914000076],
            [-80.796976235999978, 35.372682915000041],
            [-80.796997235999982, 35.372774914000047],
            [-80.797039235999989, 35.372922914000071],
            [-80.797416235999947, 35.373811914000044],
            [-80.797446236999974, 35.373914914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797468235999986, 35.374094914000068],
            [-80.797451236999962, 35.374244915000077],
            [-80.797408235999967, 35.374408914000071],
            [-80.797247235999976, 35.374933914000053],
            [-80.797101235999946, 35.375382914000056],
            [-80.79768123599996, 35.375517915000046],
            [-80.797975236999946, 35.375592915000027],
            [-80.798128235999968, 35.375652915000046],
            [-80.798258235999981, 35.375729914000033],
            [-80.798362236999935, 35.375802915000065],
            [-80.798447236999948, 35.375885915000026],
            [-80.798501236999982, 35.375957915000072],
            [-80.798560236999947, 35.376061915000037],
            [-80.798594236999975, 35.376165915000058],
            [-80.798619236999969, 35.376291915000024],
            [-80.798623236999958, 35.376401914000041],
            [-80.798611236999989, 35.376517915000079],
            [-80.798570235999989, 35.376642916000037],
            [-80.798507235999978, 35.376789915000074],
            [-80.798463236999964, 35.376912915000048],
            [-80.798449235999954, 35.376998915000058],
            [-80.79845323699999, 35.37707391500004],
            [-80.798466235999967, 35.377154915000062],
            [-80.798507236999967, 35.377240915000073],
            [-80.79855123699997, 35.377312914000072],
            [-80.798589235999941, 35.377364916000033],
            [-80.798712236999961, 35.377486914000031],
            [-80.797820236999939, 35.378089914000043],
            [-80.797605236999971, 35.378237916000046],
            [-80.797417236999934, 35.378367915000069],
            [-80.796784235999951, 35.378796915000066],
            [-80.796537235999949, 35.378964916000029],
            [-80.795169236999982, 35.379910915000039],
            [-80.794706235999968, 35.380224916000031],
            [-80.794392234999975, 35.380420916000048],
            [-80.794257234999975, 35.380490915000053],
            [-80.794104235999953, 35.380559916000038],
            [-80.793955235999988, 35.380613916000073],
            [-80.793399234999981, 35.380789916000026],
            [-80.79240923499998, 35.381092915000067],
            [-80.791912235999973, 35.38124491700006],
            [-80.791240234999975, 35.381449916000065],
            [-80.790655234999974, 35.38162791700006],
            [-80.790401233999944, 35.381705916000044],
            [-80.790291234999984, 35.381739916000072],
            [-80.789902233999953, 35.381876916000067],
            [-80.789763233999963, 35.381933916000037],
            [-80.789696233999962, 35.381965917000059],
            [-80.789469233999966, 35.382082917000048],
            [-80.789291233999961, 35.38218691600008],
            [-80.789050233999944, 35.382340916000032],
            [-80.788806233999935, 35.382512917000042],
            [-80.788435233999962, 35.382791916000031],
            [-80.788118233999967, 35.383049916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.786700233999966, 35.384445916000061],
            [-80.785941233999949, 35.385195917000033],
            [-80.785334232999958, 35.385761918000071],
            [-80.784783232999985, 35.386234918000071],
            [-80.784700232999967, 35.386299917000031],
            [-80.78233923199997, 35.38815291700007],
            [-80.78141223199998, 35.388881918000038],
            [-80.779635231999976, 35.390281918000028],
            [-80.779433231999974, 35.390435918000037],
            [-80.779261231999953, 35.390556918000073],
            [-80.77899923199999, 35.390720919000046],
            [-80.778744231999951, 35.390860919000033],
            [-80.778606230999969, 35.390927919000035],
            [-80.778365230999952, 35.391035919000046],
            [-80.778006230999949, 35.391183919000071],
            [-80.778089230999967, 35.391297919000067],
            [-80.780835231999959, 35.395009919000074],
            [-80.782405232999963, 35.397131920000049],
            [-80.783722233999981, 35.398911921000035],
            [-80.781756231999964, 35.399425920000056],
            [-80.781609232999983, 35.399464920000071],
            [-80.78152823299996, 35.399959921000061],
            [-80.781306232999952, 35.400481920000061],
            [-80.780617232999987, 35.402109921000033],
            [-80.78050723299998, 35.402369921000059],
            [-80.779215232999945, 35.405415921000042],
            [-80.778600232999963, 35.406866922000063],
            [-80.778380231999961, 35.407267922000074],
            [-80.779746232999969, 35.409076922000054],
            [-80.778319232999934, 35.409293922000074],
            [-80.774467231999949, 35.409852923000074],
            [-80.77464723199995, 35.412072923000039],
            [-80.77468023199998, 35.412043923000056],
            [-80.774795230999985, 35.411957923000045],
            [-80.774835231999987, 35.411895923000031],
            [-80.774847231999956, 35.411841923000054],
            [-80.774869230999968, 35.411792923000064],
            [-80.774931231999972, 35.41173092300005],
            [-80.775001231999966, 35.41169392300003],
            [-80.775138231999961, 35.411649923000027],
            [-80.775221230999989, 35.411617923000051],
            [-80.775310231999981, 35.41159592300005],
            [-80.775400230999935, 35.411566923000066],
            [-80.775561231999973, 35.411543923000067],
            [-80.77588923199994, 35.411468923000029],
            [-80.775947231999965, 35.411465924000026],
            [-80.776008230999935, 35.411454923000065],
            [-80.776192231999971, 35.411443923000036],
            [-80.776250231999938, 35.411434924000048],
            [-80.776315230999955, 35.411419923000039],
            [-80.776376231999961, 35.411396923000041],
            [-80.776439231999973, 35.411367923000057],
            [-80.776475231999939, 35.411360923000075],
            [-80.776517231999946, 35.411352923000038],
            [-80.776593231999982, 35.411327923000044],
            [-80.776644231999967, 35.411319923000065],
            [-80.776882230999945, 35.411309923000033],
            [-80.776959231999967, 35.41129092400007],
            [-80.777028231999964, 35.411278924000044],
            [-80.777196231999937, 35.411277924000046],
            [-80.777251231999969, 35.411270923000075],
            [-80.777359231999981, 35.411222923000025],
            [-80.777454231999968, 35.411194923000039],
            [-80.777593232999948, 35.411176923000028],
            [-80.77779923199995, 35.411139924000054],
            [-80.777890232999937, 35.411132923000025],
            [-80.778176232999954, 35.41108592300003],
            [-80.778279232999978, 35.411061923000034],
            [-80.778361231999952, 35.411056923000046],
            [-80.778514232999953, 35.411035923000043],
            [-80.778679232999934, 35.411002923000069],
            [-80.778748232999988, 35.410975923000024],
            [-80.778848232999962, 35.410948923000035],
            [-80.779038232999937, 35.410873922000064],
            [-80.779299232999961, 35.410825923000061],
            [-80.779398231999949, 35.410789924000028],
            [-80.779621232999943, 35.410735922000072],
            [-80.77980923299998, 35.410730923000074],
            [-80.77990323299997, 35.41070592300008],
            [-80.779957232999948, 35.410684923000076],
            [-80.780012233999969, 35.410651923000046],
            [-80.780097231999946, 35.410612923000031],
            [-80.780171232999976, 35.41058692200005],
            [-80.780265232999966, 35.410571923000077],
            [-80.780442232999974, 35.410561922000056],
            [-80.780512232999968, 35.410546922000037],
            [-80.780684232999988, 35.410449923000044],
            [-80.780733232999978, 35.410425922000059],
            [-80.780815233999988, 35.410404922000055],
            [-80.780898232999959, 35.410397923000062],
            [-80.781004232999976, 35.410382922000053],
            [-80.781095232999974, 35.410357923000049],
            [-80.781159233999972, 35.410330922000071],
            [-80.78124623299999, 35.410320923000029],
            [-80.781320233999963, 35.410303923000072],
            [-80.781706232999966, 35.410190922000027],
            [-80.781786233999981, 35.410157923000043],
            [-80.781881233999968, 35.410131922000062],
            [-80.781969233999973, 35.410112922000053],
            [-80.782108233999963, 35.410108923000053],
            [-80.782196233999969, 35.410110923000047],
            [-80.782359233999955, 35.410129922000067],
            [-80.78245123399995, 35.410145923000073],
            [-80.782764234999945, 35.410181923000039],
            [-80.783070233999979, 35.410227922000047],
            [-80.783224233999988, 35.410238922000076],
            [-80.783818233999966, 35.410228922000044],
            [-80.783905233999974, 35.410204923000038],
            [-80.784016233999978, 35.410179923000044],
            [-80.784323234999988, 35.410177922000059],
            [-80.784387233999951, 35.410171922000075],
            [-80.78444323399998, 35.410159923000037],
            [-80.78475423499998, 35.410140922000039],
            [-80.784862233999945, 35.410139922000042],
            [-80.784951233999948, 35.41014692300007],
            [-80.785137234999979, 35.410189922000029],
            [-80.785384234999981, 35.410273923000034],
            [-80.785492234999936, 35.410303923000072],
            [-80.785668234999946, 35.410324922000029],
            [-80.785882234999974, 35.410373922000076],
            [-80.785925235999969, 35.410368922000032],
            [-80.786037234999981, 35.410397923000062],
            [-80.786243234999972, 35.410411922000037],
            [-80.786335234999967, 35.410411922000037],
            [-80.786458234999941, 35.410389923000025],
            [-80.786682234999944, 35.410371922000024],
            [-80.787136233999945, 35.410282923000068],
            [-80.787198233999959, 35.410324922000029],
            [-80.787335234999944, 35.410345922000033],
            [-80.787429234999934, 35.410350923000067],
            [-80.787628234999943, 35.410384922000048],
            [-80.78767023499995, 35.410399923000057],
            [-80.787833235999983, 35.410426922000056],
            [-80.787994233999939, 35.410471923000046],
            [-80.788039234999985, 35.410493923000047],
            [-80.788173234999988, 35.410498923000034],
            [-80.788232234999953, 35.410509922000074],
            [-80.788283235999984, 35.410530923000067],
            [-80.788525235999941, 35.410669923000057],
            [-80.788655235999954, 35.410752923000075],
            [-80.788694235999969, 35.410767922000048],
            [-80.788770235999948, 35.41080792300005],
            [-80.788839235999944, 35.410874922000062],
            [-80.789014235999957, 35.410971922000044],
            [-80.789060235999955, 35.410996922000038],
            [-80.789383235999935, 35.411232923000057],
            [-80.789910235999969, 35.411580923000031],
            [-80.790065235999975, 35.411678923000068],
            [-80.79011223599997, 35.411716923000029],
            [-80.790309236999974, 35.411848922000047],
            [-80.790431234999971, 35.411944922000032],
            [-80.790521235999961, 35.412021922000065],
            [-80.79063823599995, 35.41210592200008],
            [-80.790689236999981, 35.412149923000072],
            [-80.79081423699995, 35.41228592300007],
            [-80.790915236999979, 35.412382923000052],
            [-80.790965236999966, 35.412443923000069],
            [-80.791081235999968, 35.412533923000069],
            [-80.791240236999954, 35.412730923000026],
            [-80.79145523599999, 35.41304092300004],
            [-80.791481236999971, 35.413098922000074],
            [-80.791531235999969, 35.413189923000061],
            [-80.791697236999937, 35.413465922000057],
            [-80.791844235999974, 35.413663922000069],
            [-80.791864235999981, 35.413702923000073],
            [-80.792006236999953, 35.413964924000027],
            [-80.792133236999973, 35.414167922000047],
            [-80.792267236999976, 35.414350923000029],
            [-80.792417236999938, 35.414601923000077],
            [-80.792622236999989, 35.414912923000031],
            [-80.792633236999961, 35.414945924000051],
            [-80.792634237999948, 35.414991923000059],
            [-80.79266423699994, 35.415047923000031],
            [-80.792695235999986, 35.415080924000051],
            [-80.792715236999982, 35.415113923000035],
            [-80.792714235999938, 35.415137924000078],
            [-80.792757236999989, 35.415187924000065],
            [-80.792779236999934, 35.41519792400004],
            [-80.792817236999952, 35.415201923000041],
            [-80.79284023699995, 35.415214924000054],
            [-80.792863236999949, 35.415238923000061],
            [-80.792874236999978, 35.41526192300006],
            [-80.792875235999986, 35.415281923000066],
            [-80.792854236999972, 35.415425924000033],
            [-80.792862236999952, 35.415516923000041],
            [-80.792853236999974, 35.415548924000063],
            [-80.792826236999986, 35.415595923000069],
            [-80.792817236999952, 35.415635923000025],
            [-80.792817236999952, 35.41579792400006],
            [-80.792857236999964, 35.415865923000069],
            [-80.792886237999937, 35.415891923000061],
            [-80.792909236999947, 35.415939923000053],
            [-80.792924237999955, 35.415982923000058],
            [-80.792952237999941, 35.416014924000024],
            [-80.792973237999945, 35.416029924000043],
            [-80.793020236999951, 35.41604592300007],
            [-80.793186236999986, 35.416178923000075],
            [-80.793460236999977, 35.41633192300003],
            [-80.79360823799999, 35.416421924000076],
            [-80.793729237999969, 35.416475924000054],
            [-80.79384123799997, 35.416538924000065],
            [-80.793918236999957, 35.416591923000055],
            [-80.794090237999967, 35.41668792300004],
            [-80.794143237999947, 35.41672892400004],
            [-80.794270237999967, 35.416778924000027],
            [-80.794415237999942, 35.416841924000039],
            [-80.794636237999953, 35.416964924000069],
            [-80.794758237999986, 35.417022923000047],
            [-80.794843237999942, 35.417071924000027],
            [-80.79497523799995, 35.417128924000053],
            [-80.795105237999962, 35.417203924000034],
            [-80.79554223699995, 35.41741492400007],
            [-80.795591237999986, 35.417442924000056],
            [-80.795659237999985, 35.417464923000068],
            [-80.795740237999951, 35.417501924000078],
            [-80.795773238999971, 35.417521923000038],
            [-80.795849238999949, 35.417596924000065],
            [-80.796040238999979, 35.417814923000037],
            [-80.796110237999983, 35.417915923000066],
            [-80.796150237999939, 35.417951923000032],
            [-80.796233237999957, 35.417998924000074],
            [-80.796339237999973, 35.418164923000063],
            [-80.796405237999977, 35.41829292400007],
            [-80.796512237999934, 35.418466924000029],
            [-80.79656523899996, 35.418531924000035],
            [-80.796604238999976, 35.418619924000041],
            [-80.796658238999953, 35.418673923000028],
            [-80.796733237999945, 35.418774924000047],
            [-80.796794237999961, 35.41887192400003],
            [-80.796924238999964, 35.41903592400007],
            [-80.797098238999979, 35.419267924000053],
            [-80.797286238999959, 35.41950492400008],
            [-80.797393238999973, 35.419667923000077],
            [-80.797445238999956, 35.419717924000054],
            [-80.797477238999988, 35.419763924000051],
            [-80.797535238999956, 35.419826924000063],
            [-80.797581237999964, 35.419912924000073],
            [-80.797649237999963, 35.419994925000026],
            [-80.797682238999982, 35.420026924000069],
            [-80.797721238999941, 35.420047924000073],
            [-80.79777723899997, 35.420092925000063],
            [-80.797843237999984, 35.420113925000067],
            [-80.797962238999958, 35.420227924000073],
            [-80.797531237999976, 35.420587924000074],
            [-80.797321238999984, 35.420775925000044],
            [-80.796883238999953, 35.421202925000046],
            [-80.794835238999951, 35.42324592500006],
            [-80.793969237999988, 35.424109925000039],
            [-80.793259237999962, 35.424818925000068],
            [-80.792769237999948, 35.425308926000071],
            [-80.79264823799997, 35.425418925000031],
            [-80.792575237999984, 35.425477925000052],
            [-80.792478237999944, 35.425546925000049],
            [-80.792338237999957, 35.425448926000058],
            [-80.792016236999984, 35.425297926000042],
            [-80.791172237999945, 35.424949926000068],
            [-80.791049236999982, 35.424890926000046],
            [-80.790073236999945, 35.424362926000072],
            [-80.78835223699997, 35.423423925000066],
            [-80.788223235999965, 35.423359925000057],
            [-80.788108235999971, 35.423313925000059],
            [-80.788003235999952, 35.423276925000039],
            [-80.787908236999954, 35.423250925000048],
            [-80.78780123599995, 35.423227926000038],
            [-80.787714236999989, 35.423213926000074],
            [-80.787501235999969, 35.423198925000065],
            [-80.787183235999976, 35.423205925000048],
            [-80.785620234999953, 35.423312925000062],
            [-80.78318623499996, 35.423445925000067],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801624241999946, 35.442241929000033],
            [-80.801465241999949, 35.442311929000027],
            [-80.801007240999979, 35.442466928000044],
            [-80.800832240999966, 35.442536929000028],
            [-80.800729240999942, 35.442586928000026],
            [-80.800630241999954, 35.442641928000057],
            [-80.800511240999981, 35.44272292900007],
            [-80.800395241999979, 35.442813929000067],
            [-80.800232240999946, 35.442957928000055],
            [-80.800047239999969, 35.44313992900004],
            [-80.799473240999987, 35.443746930000032],
            [-80.798915239999985, 35.444311929000037],
            [-80.798808239999971, 35.444432929000072],
            [-80.79874523999996, 35.444516929000031],
            [-80.79865523999996, 35.444657929000073],
            [-80.798494239999968, 35.444981929000051],
            [-80.797555239999951, 35.447156930000062],
            [-80.797365239999976, 35.447605930000066],
            [-80.796973239999943, 35.448574930000063],
            [-80.79682324099997, 35.448923930000035],
            [-80.796631240999943, 35.449316930000066],
            [-80.796582239999964, 35.449402931000066],
            [-80.796350239999981, 35.449764930000072],
            [-80.796015238999985, 35.450243931000045],
            [-80.795560239999986, 35.45000893100007],
            [-80.795320239999967, 35.449898931000064],
            [-80.795118239999965, 35.449813931000051],
            [-80.79485023899997, 35.449710930000037],
            [-80.79466623899998, 35.449647931000072],
            [-80.794291238999961, 35.449539931000061],
            [-80.794003239999938, 35.449471930000072],
            [-80.791847238999935, 35.448984931000041],
            [-80.79148923799994, 35.448895931000038],
            [-80.791312237999989, 35.448845930000061],
            [-80.790889238999966, 35.448709930000064],
            [-80.790459237999983, 35.448540931000025],
            [-80.790059238999959, 35.448367931000064],
            [-80.789453237999965, 35.448105930000054],
            [-80.789332237999986, 35.448049930000025],
            [-80.787848236999935, 35.447406931000046],
            [-80.786688236999964, 35.446910930000058],
            [-80.786244236999948, 35.446732931000042],
            [-80.786040236999952, 35.446663931000046],
            [-80.785661236999943, 35.446557931000029],
            [-80.785479235999958, 35.446516931000076],
            [-80.785191235999946, 35.446465930000045],
            [-80.785064236999972, 35.446447930000033],
            [-80.783836236999946, 35.446321931000057],
            [-80.783541235999962, 35.446283931000039],
            [-80.783331235999981, 35.446244930000034],
            [-80.782997235999972, 35.446164930000066],
            [-80.782748235999975, 35.446092930000077],
            [-80.782519234999938, 35.446015930000044],
            [-80.782274235999978, 35.445922930000052],
            [-80.781961235999972, 35.445772930000032],
            [-80.781708235999986, 35.445639931000073],
            [-80.781431234999957, 35.445462930000076],
            [-80.78118623599994, 35.445281930000078],
            [-80.780984234999949, 35.445131930000059],
            [-80.780575234999958, 35.444815930000061],
            [-80.780383234999988, 35.444673930000079],
            [-80.780058234999956, 35.444457930000056],
            [-80.77989123499998, 35.444364930000063],
            [-80.779549234999934, 35.444196930000032],
            [-80.779312234999963, 35.444099931000039],
            [-80.778974234999964, 35.443977930000074],
            [-80.778884234999964, 35.443945929000051],
            [-80.778637233999973, 35.443863930000077],
            [-80.77721223399999, 35.443388930000026],
            [-80.776666232999958, 35.443206929000041],
            [-80.775731232999988, 35.442894929000033],
            [-80.775266233999957, 35.442738929000029],
            [-80.770722231999969, 35.441224930000033],
            [-80.770186231999958, 35.441055930000061],
            [-80.769798231999971, 35.44095193000004],
            [-80.769598231999964, 35.440907929000048],
            [-80.769294230999947, 35.440850929000078],
            [-80.76893523199999, 35.440797930000031],
            [-80.768610230999968, 35.440765930000055],
            [-80.768360230999974, 35.440746929000056],
            [-80.768020231999969, 35.440741930000058],
            [-80.767766231999985, 35.440741929000069],
            [-80.767373230999965, 35.440765930000055],
            [-80.765654229999939, 35.440911929000038],
            [-80.764912229999936, 35.440974930000039],
            [-80.764915230999975, 35.440980929000034],
            [-80.765434229999983, 35.442026930000054],
            [-80.766182230999959, 35.443530930000065],
            [-80.767076230999976, 35.445330930000068],
            [-80.767080230999966, 35.445337930000051],
            [-80.767084230999956, 35.445346931000074],
            [-80.76709723099998, 35.445373930000073],
            [-80.767441230999964, 35.446054930000059],
            [-80.770616232999942, 35.452459932000068],
            [-80.770767232999958, 35.45276393100005],
            [-80.771021232999942, 35.453290932000073],
            [-80.771253232999982, 35.453771932000052],
            [-80.771632232999934, 35.454558932000054],
            [-80.772765232999973, 35.456999933000077],
            [-80.77286023299996, 35.457344932000069],
            [-80.773052233999977, 35.457265932000041],
            [-80.773244233999947, 35.457225933000075],
            [-80.773662232999982, 35.457177933000025],
            [-80.774234232999959, 35.45704093300003],
            [-80.774754232999953, 35.456932933000076],
            [-80.775382233999949, 35.456937932000073],
            [-80.775680234999982, 35.457004933000064],
            [-80.776277234999952, 35.457195933000037],
            [-80.776876234999975, 35.457486933000041],
            [-80.777633234999939, 35.45783193300008],
            [-80.778479234999963, 35.458275933000039],
            [-80.778969235999966, 35.458368933000031],
            [-80.779324235999979, 35.458634932000052],
            [-80.779376234999972, 35.458704933000035],
            [-80.77964723499997, 35.459071933000075],
            [-80.779760235999959, 35.459258933000058],
            [-80.779810235999946, 35.459340933000078],
            [-80.780026235999969, 35.459667933000048],
            [-80.780080235999947, 35.459749933000069],
            [-80.780227235999973, 35.460104933000025],
            [-80.78065623599997, 35.460582933000069],
            [-80.780960235999942, 35.460906934000036],
            [-80.781332236999958, 35.461200934000033],
            [-80.781845235999981, 35.461563934000026],
            [-80.782499235999978, 35.461939934000043],
            [-80.783205236999947, 35.462370934000035],
            [-80.783396236999977, 35.462515933000077],
            [-80.783825235999984, 35.462789933000067],
            [-80.784108236999941, 35.462985934000073],
            [-80.784355236999943, 35.463123934000066],
            [-80.784533237999938, 35.463306934000059],
            [-80.785219237999968, 35.463567934000025],
            [-80.785664237999981, 35.463713934000054],
            [-80.785851236999974, 35.463772934000076],
            [-80.786019237999938, 35.463852934000045],
            [-80.786137237999981, 35.463912934000064],
            [-80.78662623799994, 35.464145934000044],
            [-80.787282237999989, 35.464635934000057],
            [-80.787776237999935, 35.464899934000073],
            [-80.78804723799999, 35.46530993500005],
            [-80.788331238999945, 35.465575934000071],
            [-80.788582237999947, 35.465871934000063],
            [-80.78906423899997, 35.466420935000031],
            [-80.789458237999952, 35.466899934000025],
            [-80.789571239999987, 35.467008935000024],
            [-80.789654238999958, 35.467081934000078],
            [-80.78983723999994, 35.467478935000031],
            [-80.790246238999941, 35.467828935000057],
            [-80.790379238999947, 35.467904934000046],
            [-80.790464239999949, 35.467951934000041],
            [-80.79044523999994, 35.468013935000045],
            [-80.79045623899998, 35.468046935000075],
            [-80.79049723899999, 35.468126935000043],
            [-80.790584239999987, 35.468382934000033],
            [-80.790692239999942, 35.468756935000044],
            [-80.790785238999945, 35.469026934000055],
            [-80.790879239999981, 35.469275935000041],
            [-80.79090623899998, 35.469375934000027],
            [-80.790907239999967, 35.469426935000058],
            [-80.790926238999987, 35.469509935000076],
            [-80.791010239999935, 35.46960593600005],
            [-80.791025239999954, 35.469659935000038],
            [-80.791025239999954, 35.469712935000075],
            [-80.791032239999936, 35.469780935000074],
            [-80.791074239999944, 35.469935935000024],
            [-80.791110239999966, 35.470002935000025],
            [-80.791145238999945, 35.470049935000077],
            [-80.791206239999951, 35.470170935000056],
            [-80.791232238999953, 35.470248935000029],
            [-80.791363239999953, 35.470438935000061],
            [-80.791488239999978, 35.470595935000063],
            [-80.791513238999983, 35.470643935000055],
            [-80.791533239999978, 35.470701935000079],
            [-80.791579239999976, 35.470769935000078],
            [-80.791731238999944, 35.470963935000043],
            [-80.791777239999988, 35.471001935000061],
            [-80.79182123999999, 35.471049936000043],
            [-80.791852239999969, 35.471103935000031],
            [-80.791871239999978, 35.471194935000028],
            [-80.791900239999961, 35.471255935000045],
            [-80.79196823999996, 35.471364935000054],
            [-80.791970239999955, 35.471382936000055],
            [-80.791996239999946, 35.471582935000072],
            [-80.792015238999966, 35.471688935000032],
            [-80.792055239999968, 35.471765935000064],
            [-80.792086239999946, 35.471850936000067],
            [-80.792117239999982, 35.471917935000079],
            [-80.79215623999994, 35.471987936000062],
            [-80.792186239999978, 35.472087935000047],
            [-80.792195239999955, 35.472199936000038],
            [-80.792239239999958, 35.472375936000049],
            [-80.792283239999961, 35.472509936000051],
            [-80.792299240999967, 35.472577935000061],
            [-80.792316239999934, 35.472625936000043],
            [-80.792342240999972, 35.472680936000074],
            [-80.792364238999937, 35.47275793600005],
            [-80.792401239999947, 35.472890935000066],
            [-80.792421239999953, 35.473098936000042],
            [-80.79246724099994, 35.473241936000079],
            [-80.792484239999965, 35.473274936000053],
            [-80.792498240999976, 35.473336935000077],
            [-80.792500239999981, 35.473398935000034],
            [-80.79251524099999, 35.473479936000047],
            [-80.79258023999995, 35.473647936000077],
            [-80.792616238999983, 35.473718936000068],
            [-80.792659239999978, 35.473780936000026],
            [-80.792766240999981, 35.473968935000073],
            [-80.792818239999974, 35.474041935000059],
            [-80.792978239999968, 35.47420293600004],
            [-80.793015240999978, 35.474271936000036],
            [-80.793062240999973, 35.474332935000064],
            [-80.793158240999958, 35.474415936000071],
            [-80.79322123999998, 35.474491936000049],
            [-80.793245239999976, 35.474513935000061],
            [-80.793486239999936, 35.474879935000047],
            [-80.793646240999976, 35.475014936000036],
            [-80.793733240999984, 35.475107937000075],
            [-80.794012240999962, 35.475389936000056],
            [-80.794194241999946, 35.475589936000063],
            [-80.794299240999976, 35.475712937000026],
            [-80.794686240999965, 35.476105936000067],
            [-80.794859240999983, 35.476257936000025],
            [-80.794930240999975, 35.476363936000041],
            [-80.795092240999963, 35.47653293500008],
            [-80.79516124099996, 35.476621936000072],
            [-80.795234240999946, 35.476743936000048],
            [-80.795298240999955, 35.476822937000065],
            [-80.795355240999982, 35.476855937000039],
            [-80.795392240999945, 35.476884936000033],
            [-80.795448240999974, 35.476999937000073],
            [-80.795541240999967, 35.477116937000062],
            [-80.795632240999964, 35.477182936000077],
            [-80.795696241999963, 35.477258936000055],
            [-80.795766240999967, 35.477374936000047],
            [-80.79583224199996, 35.477464937000036],
            [-80.795932241999935, 35.477561937000075],
            [-80.796111241999938, 35.47775593700004],
            [-80.796160240999939, 35.477836937000063],
            [-80.796459241999969, 35.478222936000066],
            [-80.796553241999959, 35.478358936000063],
            [-80.796632241999987, 35.478440937000073],
            [-80.796683241999972, 35.478518937000047],
            [-80.796714240999961, 35.478576937000071],
            [-80.796782241999949, 35.478663936000032],
            [-80.796824241999957, 35.478726937000033],
            [-80.796964241999945, 35.47886993700007],
            [-80.797080241999936, 35.479023937000079],
            [-80.797227241999963, 35.479183938000062],
            [-80.797321241999953, 35.479311937000034],
            [-80.797432241999957, 35.479430937000075],
            [-80.797519242999954, 35.479504937000058],
            [-80.797570241999949, 35.47957593600006],
            [-80.797609241999965, 35.479660936000073],
            [-80.79764824099999, 35.47971493700004],
            [-80.797898241999974, 35.480023936000066],
            [-80.797935241999937, 35.480082937000077],
            [-80.798018241999955, 35.480188937000037],
            [-80.798109241999953, 35.480291937000061],
            [-80.798157241999945, 35.480338936000066],
            [-80.79820024199995, 35.480396936000034],
            [-80.798222241999952, 35.48045393700005],
            [-80.798265241999957, 35.480523937000044],
            [-80.798330241999963, 35.48059293600005],
            [-80.798359242999936, 35.480611936000059],
            [-80.798420242999953, 35.480700938000041],
            [-80.798634242999981, 35.480927937000047],
            [-80.798720242999934, 35.48104993700008],
            [-80.798839242999975, 35.481235936000076],
            [-80.798896241999955, 35.481302937000066],
            [-80.798954242999969, 35.481356937000044],
            [-80.799038242999984, 35.481459937000068],
            [-80.799108242999978, 35.481554937000055],
            [-80.799203242999965, 35.48165493700003],
            [-80.799307241999941, 35.481791937000025],
            [-80.799400243999969, 35.481928937000077],
            [-80.799647242999981, 35.482215937000035],
            [-80.800059242999964, 35.482729938000034],
            [-80.800322241999936, 35.48307993800006],
            [-80.800453243999982, 35.483223937000048],
            [-80.800558242999955, 35.483354938000048],
            [-80.800619242999971, 35.483422938000047],
            [-80.800685243999965, 35.483511938000049],
            [-80.800748242999987, 35.483571938000068],
            [-80.800921242999948, 35.483702938000079],
            [-80.800937243999954, 35.483765938000033],
            [-80.80099824399997, 35.483902938000028],
            [-80.801097242999958, 35.484046937000073],
            [-80.80125824299995, 35.484247937000077],
            [-80.801419243999987, 35.484492938000074],
            [-80.80152424399995, 35.48466793700004],
            [-80.801605243999973, 35.484781937000037],
            [-80.801619243999937, 35.48480293700004],
            [-80.801801242999943, 35.485097938000024],
            [-80.802000243999942, 35.485377938000056],
            [-80.80208324299997, 35.485519939000028],
            [-80.802175242999965, 35.485651938000046],
            [-80.802245243999948, 35.485760938000055],
            [-80.802328242999977, 35.485922938000044],
            [-80.802475243999936, 35.486162938000064],
            [-80.802581242999963, 35.486322938000058],
            [-80.80267224399995, 35.486473938000074],
            [-80.802764243999945, 35.486587938000071],
            [-80.802864243999977, 35.486697938000077],
            [-80.802959243999965, 35.486825938000038],
            [-80.80300224399997, 35.486950938000064],
            [-80.803029243999958, 35.487044937000064],
            [-80.803123243999948, 35.487153939000052],
            [-80.803205242999979, 35.487231938000036],
            [-80.803297244999953, 35.487331938000068],
            [-80.803353244999983, 35.48742893900004],
            [-80.803422243999989, 35.487562938000053],
            [-80.803488244999983, 35.487714938000067],
            [-80.803525243999957, 35.487883938000039],
            [-80.803579243999934, 35.48808593800004],
            [-80.803623243999937, 35.48822793800008],
            [-80.803668243999937, 35.488295938000078],
            [-80.803754244999936, 35.488453938000077],
            [-80.80382024499994, 35.488599939000039],
            [-80.80395824499999, 35.488946938000026],
            [-80.80397824399995, 35.489006939000035],
            [-80.804004244999987, 35.489047939000045],
            [-80.804086243999961, 35.489227939000045],
            [-80.804119244999981, 35.489285939000069],
            [-80.804153243999963, 35.489385938000055],
            [-80.804185243999939, 35.48952293900004],
            [-80.804247244999942, 35.489597939000078],
            [-80.80427324499999, 35.489639938000039],
            [-80.80428524499996, 35.489689938000026],
            [-80.804287244999955, 35.489746939000042],
            [-80.804312244999949, 35.489806939000061],
            [-80.804347244999974, 35.489850939000064],
            [-80.804381243999956, 35.489885939000033],
            [-80.80442824499994, 35.49001393900005],
            [-80.804433245999974, 35.490080939000052],
            [-80.804457243999934, 35.490149938000059],
            [-80.80450624499997, 35.490214939000055],
            [-80.804633243999945, 35.490516938000042],
            [-80.80470924399998, 35.490743939000026],
            [-80.804767244999937, 35.490876939000032],
            [-80.804812244999937, 35.490954939000062],
            [-80.804833244999941, 35.491073939000046],
            [-80.804911244999971, 35.49137293900003],
            [-80.804985244999955, 35.491606939000064],
            [-80.805353244999935, 35.491740939000067],
            [-80.805619244999946, 35.491815939000048],
            [-80.805830245999971, 35.491881938000063],
            [-80.805975244999956, 35.491910939000036],
            [-80.806091244999948, 35.49196093900008],
            [-80.806247244999952, 35.492015939000055],
            [-80.806491244999961, 35.492082939000056],
            [-80.806727244999934, 35.492132940000033],
            [-80.806978245999971, 35.492214938000075],
            [-80.807275245999961, 35.492267940000033],
            [-80.807374245999938, 35.492275939000024],
            [-80.807497245999969, 35.492278939000073],
            [-80.80761324599996, 35.492309939000052],
            [-80.807704245999957, 35.492338939000035],
            [-80.807901245999972, 35.492333939000048],
            [-80.807926244999976, 35.492334939000045],
            [-80.806158245999939, 35.49425093900004],
            [-80.80230024399998, 35.498463941000068],
            [-80.803292244999966, 35.499434941000061],
            [-80.804014244999962, 35.500142941000036],
            [-80.80491924599994, 35.500891941000077],
            [-80.805428244999973, 35.501505941000062],
            [-80.808554246999961, 35.505273942000031],
            [-80.80847524699999, 35.505324942000072],
            [-80.808023246999937, 35.505694942000048],
            [-80.807775246999938, 35.505882942000028],
            [-80.807627246999971, 35.505965942000046],
            [-80.80745924699994, 35.506035943000029],
            [-80.807225245999973, 35.50610994200008],
            [-80.806913245999965, 35.506168942000045],
            [-80.806557246999944, 35.50620394200007],
            [-80.806181245999937, 35.506216942000037],
            [-80.805926245999956, 35.506214942000042],
            [-80.805668244999936, 35.506195942000033],
            [-80.805352245999984, 35.506147942000041],
            [-80.804925244999936, 35.506065942000077],
            [-80.804550245999962, 35.506024942000067],
            [-80.804311245999941, 35.50601994200008],
            [-80.804157245999988, 35.506028942000057],
            [-80.804010245999962, 35.50605394300004],
            [-80.803817244999948, 35.506088943000066],
            [-80.802079244999959, 35.506557943000075],
            [-80.801829244999965, 35.506630943000062],
            [-80.801677244999951, 35.506690942000034],
            [-80.801566244999947, 35.50676294200008],
            [-80.80140724499995, 35.506926943000053],
            [-80.801276243999951, 35.507104943000058],
            [-80.801111244999959, 35.50709394200004],
            [-80.79665724299997, 35.50709394200004],
            [-80.796482242999957, 35.507094942000037],
            [-80.796621242999947, 35.507165943000075],
            [-80.796753242999955, 35.507216942000071],
            [-80.797104242999978, 35.507364943000027],
            [-80.797357242999965, 35.507527943000071],
            [-80.797564243999943, 35.507721943000035],
            [-80.797841243999983, 35.508089943000073],
            [-80.798148243999947, 35.508479943000054],
            [-80.798549243999958, 35.509105943000066],
            [-80.798702243999969, 35.509421943000063],
            [-80.798794243999964, 35.509730943000079],
            [-80.798850243999937, 35.510104943000044],
            [-80.798847243999944, 35.510478943000066],
            [-80.798805243999936, 35.511800943000026],
            [-80.798752243999957, 35.513496944000053],
            [-80.798738244999981, 35.514567944000078],
            [-80.798761243999934, 35.514918944000044],
            [-80.798763243999986, 35.514949945000069],
            [-80.798973243999967, 35.515851944000076],
            [-80.799312244999953, 35.517124945000035],
            [-80.799357244999953, 35.51738494500006],
            [-80.799354244999961, 35.517533944000036],
            [-80.799353243999974, 35.517647944000032],
            [-80.799301244999981, 35.51790694500005],
            [-80.799245244999952, 35.518088945000045],
            [-80.798966244999974, 35.51901394500004]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 60,
        "SolutionID": "PI-20",
        "Shape_Length": 1.0110457878193357,
        "Shape_Area": 0.026569017362350882
      }
    },
    {
      "type": "Feature",
      "id": 61,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.786112233999972, 35.381666916000029],
            [-80.786140232999969, 35.381821916000035],
            [-80.786258232999955, 35.382450917000028],
            [-80.786374233999936, 35.38306491600008],
            [-80.786556232999942, 35.383994917000052],
            [-80.786603233999983, 35.384203917000036],
            [-80.786639233999949, 35.38431891700003],
            [-80.786700233999966, 35.384445916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.788118233999967, 35.383049916000061],
            [-80.788435233999962, 35.382791916000031],
            [-80.788806233999935, 35.382512917000042],
            [-80.788673234999976, 35.382364916000029],
            [-80.788323233999961, 35.382013917000052],
            [-80.788210233999962, 35.381930916000044],
            [-80.788088233999986, 35.381868917000077],
            [-80.787945233999949, 35.381812917000047],
            [-80.787829233999958, 35.381777916000033],
            [-80.787695233999955, 35.381757916000026],
            [-80.787321232999943, 35.381732917000079],
            [-80.787354233999963, 35.381476917000043],
            [-80.787439232999986, 35.380923917000075],
            [-80.787469232999968, 35.380810917000076],
            [-80.787508232999983, 35.380710916000055],
            [-80.787727233999988, 35.380317916000024],
            [-80.787834233999945, 35.380178916000034],
            [-80.787908233999985, 35.380093916000078],
            [-80.788009233999958, 35.379998916000034],
            [-80.788090233999981, 35.37992891600004],
            [-80.788184233999971, 35.379866915000036],
            [-80.788461234999943, 35.379717916000061],
            [-80.788380233999987, 35.379580915000076],
            [-80.788296233999972, 35.379456916000038],
            [-80.788257233999957, 35.379368916000033],
            [-80.788227233999976, 35.379285916000072],
            [-80.788225233999981, 35.379225916000053],
            [-80.788340233999975, 35.378968916000076],
            [-80.789500232999956, 35.376610915000072],
            [-80.789552234999974, 35.376530915000046],
            [-80.789612233999947, 35.376476915000069],
            [-80.789875234999954, 35.376303915000051],
            [-80.790028233999976, 35.376196914000047],
            [-80.790190233999965, 35.376067915000078],
            [-80.789804233999973, 35.375845914000024],
            [-80.789889233999986, 35.375686916000063],
            [-80.790138234999972, 35.375176915000054],
            [-80.790268233999939, 35.374897915000076],
            [-80.790462233999961, 35.374511915000028],
            [-80.790615233999972, 35.374188914000058],
            [-80.790985233999947, 35.373388914000031],
            [-80.791253233999953, 35.373446914000056],
            [-80.791601234999973, 35.373552914000072],
            [-80.791984234999973, 35.373705915000073],
            [-80.792397234999953, 35.373879914000042],
            [-80.792819234999968, 35.374084915000026],
            [-80.793234234999943, 35.374280915000043],
            [-80.793300234999947, 35.37415091500003],
            [-80.793362234999961, 35.374043914000026],
            [-80.793407234999961, 35.373948914000039],
            [-80.79347523499996, 35.373829915000044],
            [-80.793616233999956, 35.373562914000047],
            [-80.793684234999944, 35.373454915000025],
            [-80.793838234999953, 35.373247914000046],
            [-80.79392923499995, 35.373298914000031],
            [-80.794324234999976, 35.373487914000066],
            [-80.794597235999959, 35.373596915000064],
            [-80.795023234999974, 35.373707914000079],
            [-80.795480235999946, 35.373809915000038],
            [-80.795690235999984, 35.373861914000031],
            [-80.79575723499994, 35.37386891400007],
            [-80.795836235999957, 35.373865914000078],
            [-80.795928235999952, 35.373853915000041],
            [-80.796176235999951, 35.373786915000039],
            [-80.796212235999974, 35.373861914000031],
            [-80.796271235999939, 35.373937914000066],
            [-80.796316235999939, 35.373975914000027],
            [-80.796381235999945, 35.374014914000043],
            [-80.796468236999942, 35.37404891500006],
            [-80.796554235999963, 35.374076914000057],
            [-80.796931235999978, 35.374166915000046],
            [-80.797451236999962, 35.374244915000077],
            [-80.797468235999986, 35.374094914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797446236999974, 35.373914914000068],
            [-80.797416235999947, 35.373811914000044],
            [-80.797039235999989, 35.372922914000071],
            [-80.796997235999982, 35.372774914000047],
            [-80.796976235999978, 35.372682915000041],
            [-80.796966234999957, 35.372515914000076],
            [-80.796959235999964, 35.37238691400006],
            [-80.796958235999966, 35.372212914000045],
            [-80.796933235999973, 35.372133914000074],
            [-80.796915235999961, 35.372047913000074],
            [-80.796870235999961, 35.371958914000061],
            [-80.796635235999986, 35.371587914000031],
            [-80.796389234999936, 35.371225914000036],
            [-80.796325234999983, 35.371120914000073],
            [-80.796280235999973, 35.371011914000064],
            [-80.796123235999971, 35.370414914000037],
            [-80.796087234999959, 35.370324914000037],
            [-80.796038234999969, 35.370239914000024],
            [-80.795949234999966, 35.370134913000072],
            [-80.79568123599995, 35.369461914000055],
            [-80.794869234999965, 35.369124913000064],
            [-80.792751234999969, 35.368284913000025],
            [-80.787396232999981, 35.366161913000042],
            [-80.78746023399998, 35.365936914000031],
            [-80.787499232999949, 35.365769912000076],
            [-80.787528231999943, 35.365560913000024],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365370913000049],
            [-80.787515231999976, 35.365296912000076],
            [-80.78746823299997, 35.365193913000041],
            [-80.787434232999942, 35.365136913000072],
            [-80.78733323299997, 35.36499591300003],
            [-80.787164232999942, 35.364813913000035],
            [-80.786843232999956, 35.364509913000063],
            [-80.786228232999974, 35.363946913000063],
            [-80.785901231999958, 35.363641912000048],
            [-80.785673230999976, 35.363427912000077],
            [-80.785653232999948, 35.363408912000068],
            [-80.785209231999943, 35.363019912000027],
            [-80.785015231999978, 35.362886912000079],
            [-80.784919230999947, 35.362834913000029],
            [-80.784824231999949, 35.362786912000047],
            [-80.784727230999977, 35.362745912000037],
            [-80.784521231999975, 35.36267291200005],
            [-80.784015230999955, 35.362511912000059],
            [-80.783693230999972, 35.362400912000055],
            [-80.78360823099996, 35.36236591200003],
            [-80.783520230999954, 35.362316913000029],
            [-80.783401230999971, 35.362239912000064],
            [-80.783296230999952, 35.362153912000053],
            [-80.783255230999941, 35.362111912000046],
            [-80.783183230999953, 35.362019912000051],
            [-80.783102230999987, 35.361894913000071],
            [-80.783040230999973, 35.361755912000035],
            [-80.782968231999973, 35.361545912000054],
            [-80.78286923099995, 35.361213912000039],
            [-80.782534230999943, 35.36002691200008],
            [-80.78248623099995, 35.359857911000063],
            [-80.782435231999955, 35.359680911000055],
            [-80.782271230999982, 35.359118911000053],
            [-80.782153229999949, 35.358707911000067],
            [-80.781467229999976, 35.356426911000028],
            [-80.781132229999969, 35.355362911000043],
            [-80.780525229999967, 35.355518911000047],
            [-80.780020228999945, 35.355639911000026],
            [-80.779747229999941, 35.355694911000057],
            [-80.779208229999938, 35.355766911000046],
            [-80.77907222999994, 35.355785911000055],
            [-80.778780229999938, 35.355854912000041],
            [-80.778653228999985, 35.35591491100007],
            [-80.778530229999944, 35.355980911000074],
            [-80.778436229999954, 35.355919911000058],
            [-80.778358229999981, 35.355882911000037],
            [-80.778247228999987, 35.35583691000005],
            [-80.778114228999982, 35.355802911000069],
            [-80.778012229999945, 35.355781911000065],
            [-80.777915228999973, 35.355771911000033],
            [-80.777805228999966, 35.35577191200008],
            [-80.776886228999956, 35.355741911000052],
            [-80.776779228999942, 35.35573491100007],
            [-80.776598228999944, 35.355728911000028],
            [-80.776287228999934, 35.355709911000076],
            [-80.77615222899999, 35.355696912000042],
            [-80.776018228999988, 35.355664911000076],
            [-80.775921229999938, 35.355631911000046],
            [-80.775818227999935, 35.355589911000038],
            [-80.775584228999946, 35.355476911000039],
            [-80.775428228999942, 35.355682911000031],
            [-80.775363227999947, 35.355757912000058],
            [-80.775271227999951, 35.355843911000079],
            [-80.775162227999942, 35.355931912000074],
            [-80.775071228999934, 35.355989912000041],
            [-80.774928228999954, 35.35605791100005],
            [-80.774363226999981, 35.356238912000038],
            [-80.773851228999945, 35.356408912000063],
            [-80.77362822799995, 35.356475912000064],
            [-80.773437227999977, 35.356527911000057],
            [-80.772983227999987, 35.356627912000079],
            [-80.772871227999985, 35.356659911000065],
            [-80.772721227999966, 35.356722911000077],
            [-80.772614227999952, 35.35679691100006],
            [-80.772542227999963, 35.356852911000033],
            [-80.772467227999982, 35.356917912000029],
            [-80.772381227999972, 35.356999912000049],
            [-80.772214227999939, 35.357337912000048],
            [-80.772014227999989, 35.357743912000046],
            [-80.771708227999966, 35.357743912000046],
            [-80.771611227999983, 35.35772391200004],
            [-80.771543227999985, 35.357720912000048],
            [-80.771355227999948, 35.357726912000032],
            [-80.771312227999942, 35.357734912000069],
            [-80.771209226999986, 35.357742912000049],
            [-80.771131226999955, 35.357714911000073],
            [-80.770820226999945, 35.357631911000055],
            [-80.770316227999956, 35.357484912000075],
            [-80.770228226999961, 35.357432912000036],
            [-80.770173226999987, 35.357414912000024],
            [-80.770094226999959, 35.357409912000037],
            [-80.769884226999977, 35.357348912000077],
            [-80.769669226999952, 35.35726991100006],
            [-80.769544227999972, 35.357215912000072],
            [-80.769444226999951, 35.357217912000067],
            [-80.769360226999936, 35.357193911000024],
            [-80.769289227999934, 35.35718791100004],
            [-80.769198226999947, 35.357192911000027],
            [-80.769110226999942, 35.357228912000039],
            [-80.769026226999983, 35.357292912000048],
            [-80.768978226999934, 35.357319911000047],
            [-80.768900226999961, 35.357324912000024],
            [-80.768833226999959, 35.357300911000038],
            [-80.768754226999988, 35.357238912000071],
            [-80.768700226999954, 35.35720191200005],
            [-80.76867522699996, 35.357189912000024],
            [-80.768627226999968, 35.357171912000069],
            [-80.768477225999959, 35.35715691200005],
            [-80.768375225999989, 35.357162912000035],
            [-80.768340226999953, 35.357182911000052],
            [-80.76821722699998, 35.357192912000073],
            [-80.768163225999956, 35.357221911000067],
            [-80.768112225999971, 35.357264911000073],
            [-80.76805322599995, 35.357295912000041],
            [-80.767950225999982, 35.357323912000027],
            [-80.767849225999953, 35.357378912000058],
            [-80.767762226999935, 35.357413912000027],
            [-80.767722226999979, 35.357443912000065],
            [-80.767628225999943, 35.357467912000061],
            [-80.767493225999942, 35.357510912000066],
            [-80.767402225999945, 35.357548912000027],
            [-80.76738022699999, 35.357558912000059],
            [-80.767304225999965, 35.357595912000079],
            [-80.767113225999935, 35.357642912000074],
            [-80.767048226999975, 35.357675912000047],
            [-80.766941225999972, 35.357696912000051],
            [-80.766830226999957, 35.35772791200003],
            [-80.766719225999964, 35.357753911000032],
            [-80.766628225999966, 35.357779912000069],
            [-80.766554225999982, 35.357807911000066],
            [-80.766478226999936, 35.357856912000045],
            [-80.766418226999974, 35.357886911000037],
            [-80.76636822599994, 35.35789591200006],
            [-80.766195225999979, 35.357910912000079],
            [-80.765942224999947, 35.357911912000077],
            [-80.765792225999974, 35.35790391200004],
            [-80.765244225999936, 35.357900912000048],
            [-80.765122224999971, 35.357898912000053],
            [-80.764939225999967, 35.357885912000029],
            [-80.764764225999954, 35.357877912000049],
            [-80.764663224999936, 35.357878913000036],
            [-80.764642225999978, 35.35784691200007],
            [-80.764595225999983, 35.357799912000075],
            [-80.764570224999943, 35.357763912000053],
            [-80.76437122599998, 35.35754391200004],
            [-80.764146224999934, 35.357371912000076],
            [-80.764119224999945, 35.357319912000037],
            [-80.764126224999984, 35.357122912000079],
            [-80.764110224999968, 35.357065912000053],
            [-80.764061225999967, 35.357022913000037],
            [-80.764013224999985, 35.357020912000053],
            [-80.763924225999972, 35.357056912000075],
            [-80.763852224999937, 35.357065912000053],
            [-80.763590225999963, 35.356995912000059],
            [-80.763529224999957, 35.356974912000055],
            [-80.763495224999986, 35.356951911000067],
            [-80.763427224999987, 35.356920912000078],
            [-80.763364223999986, 35.356917912000029],
            [-80.763264224999944, 35.356955912000046],
            [-80.763046224999982, 35.357016912000063],
            [-80.762963224999964, 35.35702991200003],
            [-80.762824223999985, 35.357036912000069],
            [-80.762706223999942, 35.357028912000033],
            [-80.762598224999977, 35.357031912000025],
            [-80.762408224999945, 35.356995912000059],
            [-80.762142224999934, 35.356984913000076],
            [-80.761959223999952, 35.356960912000034],
            [-80.761931224999955, 35.356952912000054],
            [-80.76180922399999, 35.356866912000044],
            [-80.761756224999942, 35.356781912000031],
            [-80.761723224999969, 35.356701912000062],
            [-80.761638224999956, 35.356609912000067],
            [-80.761603223999941, 35.35655691200003],
            [-80.761580223999943, 35.356496912000068],
            [-80.761541224999974, 35.356308912000031],
            [-80.76151222499999, 35.356242912000027],
            [-80.761402223999937, 35.356166912000049],
            [-80.761369223999964, 35.356114912000066],
            [-80.761337223999988, 35.356017912000027],
            [-80.761298223999972, 35.355955911000024],
            [-80.761166223999965, 35.355834913000024],
            [-80.761054223999963, 35.355716912000048],
            [-80.760973224999987, 35.35558891200003],
            [-80.760881224999935, 35.355510912000057],
            [-80.760714222999979, 35.355399912000053],
            [-80.760482223999986, 35.355223912000042],
            [-80.760350223999978, 35.355145911000079],
            [-80.760193223999977, 35.35504091200005],
            [-80.760144223999987, 35.354996912000047],
            [-80.759973223999964, 35.354719912000064],
            [-80.759941223999988, 35.354604911000024],
            [-80.759929223999961, 35.354543911000064],
            [-80.759893222999949, 35.354456911000057],
            [-80.759801223999943, 35.354311912000071],
            [-80.759702223999966, 35.354212912000037],
            [-80.759653223999976, 35.354174912000076],
            [-80.759508223999944, 35.35409891200004],
            [-80.759420223999939, 35.354042911000079],
            [-80.759374223999941, 35.353999912000063],
            [-80.759308222999948, 35.353984911000055],
            [-80.759211222999966, 35.353995912000073],
            [-80.75912722399994, 35.353998912000066],
            [-80.759015223999938, 35.353983912000047],
            [-80.758641223999973, 35.353990912000029],
            [-80.758576223999967, 35.353984911000055],
            [-80.758514223999953, 35.353973911000026],
            [-80.758400222999967, 35.353920911000046],
            [-80.758264223999959, 35.353829911000048],
            [-80.758124223999971, 35.353766912000026],
            [-80.757989222999981, 35.35369391100005],
            [-80.757935222999947, 35.353661911000074],
            [-80.757722223999963, 35.353592912000067],
            [-80.756895222999958, 35.355332911000062],
            [-80.756975222999984, 35.355382912000039],
            [-80.757042223999974, 35.35544591200005],
            [-80.757111222999981, 35.355528911000079],
            [-80.757148222999945, 35.355591912000079],
            [-80.757189222999955, 35.355699913000024],
            [-80.757200222999984, 35.355742912000039],
            [-80.757221222999988, 35.355999912000073],
            [-80.757227222999973, 35.356234912000048],
            [-80.757230222999965, 35.356904912000061],
            [-80.757251223999958, 35.357193912000071],
            [-80.757315222999978, 35.357582912000055],
            [-80.757344222999961, 35.35771191200007],
            [-80.757398222999939, 35.357876912000052],
            [-80.75744522399998, 35.357977912000024],
            [-80.757504222999955, 35.358072912000068],
            [-80.75755122399994, 35.358134913000072],
            [-80.75791822399998, 35.358618913000043],
            [-80.757988222999984, 35.358722913000065],
            [-80.758055224999964, 35.358840912000062],
            [-80.75809822399998, 35.358950912000068],
            [-80.758136223999941, 35.359106913000062],
            [-80.758272224999985, 35.359861913000032],
            [-80.758321223999985, 35.360067914000069],
            [-80.758388224999976, 35.360266913000032],
            [-80.757704223999951, 35.360475913000073],
            [-80.757533222999939, 35.360520912000027],
            [-80.757423223999979, 35.360537912000041],
            [-80.757268222999983, 35.360549913000057],
            [-80.756832223999936, 35.360563913000078],
            [-80.756649223999943, 35.360569913000063],
            [-80.756503222999982, 35.360583913000028],
            [-80.75640322299995, 35.360609913000076],
            [-80.756330222999964, 35.360633914000061],
            [-80.75626822299995, 35.360658913000066],
            [-80.756222222999952, 35.360682913000062],
            [-80.75612122299998, 35.360745913000073],
            [-80.756041222999954, 35.360804913000038],
            [-80.75597522299995, 35.360866913000052],
            [-80.755718223999963, 35.360724914000059],
            [-80.755590222999956, 35.360666913000045],
            [-80.755456222999953, 35.360628913000028],
            [-80.755302222999944, 35.360604913000032],
            [-80.75463922299997, 35.360549913000057],
            [-80.754525222999973, 35.360543913000072],
            [-80.754520222999986, 35.360687914000039],
            [-80.754445222999948, 35.361927914000034],
            [-80.754373222999959, 35.363388914000041],
            [-80.754709222999963, 35.363627913000073],
            [-80.75481122299999, 35.363763913000071],
            [-80.754941221999957, 35.363916914000072],
            [-80.755005222999955, 35.364166914000066],
            [-80.755038221999939, 35.36427791400007],
            [-80.755056221999951, 35.364338914000029],
            [-80.755062222999982, 35.364603914000043],
            [-80.755090223999957, 35.364716914000041],
            [-80.75514222299995, 35.364799914000059],
            [-80.755149223999979, 35.364936914000054],
            [-80.755170222999936, 35.365088914000069],
            [-80.755338222999967, 35.365428914000063],
            [-80.755442222999989, 35.365704914000048],
            [-80.755537223999966, 35.365821914000037],
            [-80.755594222999946, 35.365982914000028],
            [-80.755632223999953, 35.366065914000046],
            [-80.755678223999951, 35.366151915000046],
            [-80.755771222999954, 35.36626691500004],
            [-80.755891223999981, 35.366431914000032],
            [-80.755893223999976, 35.366526914000076],
            [-80.755928223999945, 35.366606914000045],
            [-80.755973222999955, 35.366680914000028],
            [-80.756004222999934, 35.366751915000066],
            [-80.756075223999972, 35.366873915000042],
            [-80.756172223999954, 35.366943914000046],
            [-80.756209223999974, 35.366981915000054],
            [-80.756353223999952, 35.367071914000064],
            [-80.756600223999953, 35.36723891500003],
            [-80.756656223999983, 35.367285914000036],
            [-80.756918222999957, 35.367606914000078],
            [-80.757116223999958, 35.36788391500005],
            [-80.757128223999985, 35.367914915000028],
            [-80.75713022299999, 35.36793291500004],
            [-80.757405223999967, 35.36827091400005],
            [-80.757465223999986, 35.368398915000057],
            [-80.75752722499999, 35.368468915000051],
            [-80.757556223999984, 35.368509915000061],
            [-80.757563223999966, 35.368599915000061],
            [-80.757627224999965, 35.368670914000063],
            [-80.75781322399996, 35.368701915000031],
            [-80.757893223999986, 35.368696914000054],
            [-80.758075223999981, 35.368732914000077],
            [-80.758158223999942, 35.368763915000045],
            [-80.758373223999968, 35.368795914000032],
            [-80.758500223999988, 35.36880691500005],
            [-80.758573223999974, 35.368816915000025],
            [-80.758640223999976, 35.368830914000057],
            [-80.758769223999934, 35.368869915000062],
            [-80.758954224999968, 35.368898915000045],
            [-80.759024223999972, 35.368915915000059],
            [-80.759060224999985, 35.368933914000024],
            [-80.759082224999986, 35.368953915000077],
            [-80.759270224999966, 35.369060914000045],
            [-80.75946722499998, 35.369187915000055],
            [-80.759671224999977, 35.369391914000062],
            [-80.759725224999954, 35.369426915000076],
            [-80.759763224999972, 35.369443915000033],
            [-80.759800224999935, 35.369466915000032],
            [-80.760029223999936, 35.369566915000064],
            [-80.760069223999949, 35.369579915000031],
            [-80.76013622499994, 35.369592915000055],
            [-80.760203224999941, 35.369596915000045],
            [-80.760250223999947, 35.369603915000027],
            [-80.760481224999978, 35.369699915000069],
            [-80.760775224999975, 35.369846915000039],
            [-80.760808224999948, 35.369863914000064],
            [-80.760897224999951, 35.369889915000044],
            [-80.760979224999971, 35.369888914000057],
            [-80.76105122499996, 35.369901915000071],
            [-80.761150225999984, 35.36992891500006],
            [-80.761342224999964, 35.369992915000068],
            [-80.761660225999947, 35.370065915000055],
            [-80.761720224999976, 35.370068915000047],
            [-80.761893225999984, 35.370103915000072],
            [-80.761994225999956, 35.37011691500004],
            [-80.762036224999974, 35.370127916000058],
            [-80.762086225999951, 35.370152914000073],
            [-80.762111225999945, 35.370176914000069],
            [-80.762137225999936, 35.370217914000079],
            [-80.762172225999961, 35.370256915000027],
            [-80.762195224999971, 35.370295914000053],
            [-80.762226225999939, 35.370310915000061],
            [-80.762369224999986, 35.370354914000075],
            [-80.762468224999964, 35.370375915000068],
            [-80.762522225999987, 35.37037491500007],
            [-80.762567225999987, 35.370380915000055],
            [-80.762605224999959, 35.370393915000079],
            [-80.762648225999953, 35.37042391600005],
            [-80.762773225999979, 35.370486915000072],
            [-80.76296122499997, 35.370538915000054],
            [-80.763049225999964, 35.370556915000066],
            [-80.763125225999943, 35.370576915000072],
            [-80.763224226999967, 35.370609915000045],
            [-80.763414226999942, 35.370660914000041],
            [-80.763483225999948, 35.370686915000078],
            [-80.763541225999973, 35.370700914000054],
            [-80.763616225999954, 35.370697914000061],
            [-80.763671225999985, 35.37070591500003],
            [-80.763711225999941, 35.370743915000048],
            [-80.763827225999989, 35.370872914000074],
            [-80.763881225999967, 35.370921915000054],
            [-80.763912225999945, 35.370980915000075],
            [-80.763903225999968, 35.371018915000036],
            [-80.763886225999954, 35.371059915000046],
            [-80.763878224999985, 35.371099915000059],
            [-80.763882226999954, 35.371151915000041],
            [-80.763915225999938, 35.371205914000029],
            [-80.763976225999954, 35.371277915000064],
            [-80.764044225999953, 35.371343914000079],
            [-80.764154226999949, 35.371460915000057],
            [-80.764211225999986, 35.371503915000062],
            [-80.764296226999988, 35.371544915000072],
            [-80.764330226999959, 35.371550915000057],
            [-80.764427225999952, 35.371551916000044],
            [-80.764488226999958, 35.371544915000072],
            [-80.764540225999951, 35.371543915000075],
            [-80.764691225999968, 35.371522915000071],
            [-80.764762225999959, 35.371548915000062],
            [-80.764831225999956, 35.371563915000024],
            [-80.764963225999963, 35.371613915000069],
            [-80.765090225999984, 35.371671916000025],
            [-80.765149225999949, 35.371671916000025],
            [-80.765184225999974, 35.37164791500004],
            [-80.765318226999966, 35.371540915000026],
            [-80.76539622599995, 35.371489915000041],
            [-80.765489225999943, 35.371453915000075],
            [-80.76552722699995, 35.371445915000038],
            [-80.765597226999944, 35.371444915000041],
            [-80.765688225999952, 35.371456916000056],
            [-80.765857226999969, 35.371497915000077],
            [-80.76595122699996, 35.371494915000028],
            [-80.766039226999965, 35.371445915000038],
            [-80.766243226999961, 35.371285915000044],
            [-80.766299225999944, 35.371269915000028],
            [-80.766312226999958, 35.371540914000036],
            [-80.766312225999968, 35.371577915000046],
            [-80.766310226999963, 35.371690916000034],
            [-80.766302226999983, 35.371739915000035],
            [-80.766222226999957, 35.37187691500003],
            [-80.76599622599997, 35.372144915000035],
            [-80.765877226999976, 35.372256916000026],
            [-80.765795225999966, 35.372326915000031],
            [-80.765646225999944, 35.372449915000061],
            [-80.765484226999945, 35.372607916000049],
            [-80.765387226999962, 35.372729915000036],
            [-80.765309226999989, 35.372870915000078],
            [-80.765275225999972, 35.372967915000061],
            [-80.765256225999963, 35.373194915000056],
            [-80.765254226999957, 35.373360916000024],
            [-80.765245225999934, 35.373606915000039],
            [-80.765234226999951, 35.373721915000033],
            [-80.765213226999947, 35.373841915000071],
            [-80.76515122699999, 35.374019915000076],
            [-80.768493227999954, 35.374972915000058],
            [-80.769319227999972, 35.375214916000061],
            [-80.76968822799995, 35.375310916000046],
            [-80.769816228999957, 35.375337916000035],
            [-80.770021228999951, 35.37536891600007],
            [-80.77019822799997, 35.375380915000051],
            [-80.770340227999952, 35.37538091600004],
            [-80.770446227999969, 35.375374916000055],
            [-80.770658227999945, 35.37534691500008],
            [-80.770769227999949, 35.375325916000065],
            [-80.773075227999982, 35.374756915000034],
            [-80.773966229999985, 35.374518915000067],
            [-80.77638922999995, 35.373801914000069],
            [-80.776559229999975, 35.373751915000071],
            [-80.777106230999948, 35.373577915000055],
            [-80.777474229999939, 35.373470914000052],
            [-80.777779229999965, 35.373371915000064],
            [-80.77790023099999, 35.373335914000052],
            [-80.778098230999944, 35.373904915000026],
            [-80.778124230999936, 35.373976915000071],
            [-80.778131229999985, 35.374045916000057],
            [-80.77813722999997, 35.374180915000068],
            [-80.778160230999958, 35.374286915000027],
            [-80.778223229999981, 35.37440091600007],
            [-80.778302230999941, 35.374474915000064],
            [-80.778374230999987, 35.374519914000075],
            [-80.778466229999935, 35.374557916000072],
            [-80.778573230999939, 35.374602915000025],
            [-80.778679230999956, 35.374647915000025],
            [-80.778920230999972, 35.374747915000057],
            [-80.779128230999959, 35.374834915000065],
            [-80.779265230999954, 35.374891915000035],
            [-80.779513229999964, 35.374995915000056],
            [-80.779710230999967, 35.375078915000074],
            [-80.779951230999984, 35.375186915000029],
            [-80.780080230999943, 35.37524591500005],
            [-80.780143230999954, 35.375298916000077],
            [-80.78029823199995, 35.375461915000074],
            [-80.780483230999948, 35.37563691500003],
            [-80.780667231999985, 35.375789915000041],
            [-80.780710230999944, 35.375820916000066],
            [-80.78062423099999, 35.375878915000044],
            [-80.780553230999942, 35.375938916000052],
            [-80.78050923099994, 35.375991916000032],
            [-80.78045623099996, 35.376073915000063],
            [-80.780035231999989, 35.377064916000052],
            [-80.780018230999985, 35.377117915000042],
            [-80.780019230999983, 35.377168916000073],
            [-80.780115230999968, 35.377669916000059],
            [-80.779945230999942, 35.377761916000054],
            [-80.779815230999986, 35.377995915000042],
            [-80.779677230999937, 35.378317916000071],
            [-80.779516230999945, 35.378698916000076],
            [-80.779502230999981, 35.378953916000057],
            [-80.779536230999952, 35.379037917000062],
            [-80.779665231999957, 35.379168916000026],
            [-80.779858231999981, 35.379297916000041],
            [-80.780334230999983, 35.379612916000042],
            [-80.780757231999985, 35.379892916000074],
            [-80.781171230999973, 35.38014991600005],
            [-80.781368231999977, 35.380199916000038],
            [-80.781625231999953, 35.380269917000078],
            [-80.782115231999967, 35.380523917000062],
            [-80.782722232999959, 35.380973917000063],
            [-80.78291623299998, 35.381855917000053],
            [-80.782924231999971, 35.381984917000068],
            [-80.783470232999946, 35.38190591700004],
            [-80.786088232999987, 35.381527916000039],
            [-80.786112233999972, 35.381666916000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 61,
        "SolutionID": "PI-20",
        "Shape_Length": 0.14193478810757026,
        "Shape_Area": 0.00060945957730885395
      }
    },
    {
      "type": "Feature",
      "id": 62,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859762246999935, 35.231423882000058],
            [-80.859888246999958, 35.231495881000058],
            [-80.861329246999958, 35.232291882000027],
            [-80.861285246999955, 35.232345882000061],
            [-80.862306247999982, 35.232909882000058],
            [-80.862477247999948, 35.232999881000069],
            [-80.862530247999985, 35.233033882000029],
            [-80.862583247999964, 35.23306688200006],
            [-80.862777247999986, 35.233204882000052],
            [-80.863081247999958, 35.233428882000055],
            [-80.863217247999955, 35.233570882000038],
            [-80.863351247999958, 35.233726882000042],
            [-80.863470247999942, 35.233863882000037],
            [-80.863508248999949, 35.233868882000024],
            [-80.863571247999971, 35.233877882000058],
            [-80.863650247999942, 35.233888882000031],
            [-80.863632247999988, 35.233910882000032],
            [-80.863696248999986, 35.234048882000025],
            [-80.864598247999936, 35.235481882000045],
            [-80.865396248999957, 35.236748882000029],
            [-80.865424248999943, 35.236790883000026],
            [-80.866160248999961, 35.236767882000038],
            [-80.866405248999968, 35.236757883000053],
            [-80.866329249999978, 35.236606883000036],
            [-80.866192249999983, 35.23617788200005],
            [-80.866127248999987, 35.236102882000068],
            [-80.866031248999946, 35.235702883000044],
            [-80.866012249999983, 35.235396882000032],
            [-80.866034248999938, 35.235125882000034],
            [-80.866147248999937, 35.235003883000047],
            [-80.866248249999956, 35.234939882000049],
            [-80.866715248999981, 35.234828882000045],
            [-80.867349248999972, 35.234706882000069],
            [-80.867635249999978, 35.234669882000048],
            [-80.867853249999939, 35.234619882000061],
            [-80.868006249999951, 35.234561881000047],
            [-80.86820524999996, 35.234516882000037],
            [-80.868293249999965, 35.234490882000046],
            [-80.868416250999985, 35.234433882000076],
            [-80.86849824899997, 35.234375882000052],
            [-80.86858424899998, 35.234294882000029],
            [-80.868787249999968, 35.234027882000078],
            [-80.868846250999979, 35.233896881000078],
            [-80.868868249999935, 35.233662881000043],
            [-80.868900249999967, 35.233579882000072],
            [-80.868960250999976, 35.233080882000024],
            [-80.868967249999969, 35.232938881000052],
            [-80.868920249999974, 35.232752882000057],
            [-80.868922250999958, 35.232680882000068],
            [-80.86895224999995, 35.232563882000079],
            [-80.868962249999981, 35.232406882000078],
            [-80.868957249999937, 35.231838881000044],
            [-80.868973249999954, 35.231733881000025],
            [-80.868978249999941, 35.231362881000052],
            [-80.869004249999989, 35.23116088200004],
            [-80.869013249999966, 35.230671881000035],
            [-80.869013248999977, 35.230605881000031],
            [-80.869254249999983, 35.230734880000057],
            [-80.869599249999965, 35.23088288100007],
            [-80.869849248999969, 35.230964881000034],
            [-80.870147249999945, 35.231052882000029],
            [-80.870450249999976, 35.231122881000033],
            [-80.870489249999935, 35.231128881000075],
            [-80.870759249999935, 35.231174881000072],
            [-80.871454250999989, 35.231253882000033],
            [-80.871809250999945, 35.231286881000074],
            [-80.872035250999943, 35.231315882000047],
            [-80.872373250999942, 35.231371882000076],
            [-80.872706251999944, 35.23144188100008],
            [-80.873035250999976, 35.231526882000026],
            [-80.873358249999967, 35.231624882000062],
            [-80.873674250999954, 35.231737882000061],
            [-80.873982251999962, 35.23186288200003],
            [-80.874282250999954, 35.232001881000031],
            [-80.874573251999948, 35.232153881000045],
            [-80.876258250999967, 35.233108881000078],
            [-80.876351251999949, 35.232443882000041],
            [-80.87639625199995, 35.232240881000052],
            [-80.876465251999946, 35.232029881000074],
            [-80.876544251999974, 35.231848881000076],
            [-80.876561251999988, 35.231816881000043],
            [-80.876652251999985, 35.231636881000043],
            [-80.87675225199996, 35.231515881000064],
            [-80.876808251999989, 35.231462881000027],
            [-80.876959251999949, 35.231341881000048],
            [-80.877033252999979, 35.231288881000069],
            [-80.878124252999953, 35.230558880000046],
            [-80.878277252999965, 35.23044888000004],
            [-80.878310251999949, 35.230414881000058],
            [-80.878342252999971, 35.230366881000066],
            [-80.878376251999953, 35.230278881000061],
            [-80.878389251999977, 35.230224882000073],
            [-80.878393252999956, 35.23014788100005],
            [-80.878363252999975, 35.230048881000073],
            [-80.878326252999955, 35.229900881000049],
            [-80.878309252999941, 35.229790881000042],
            [-80.87831225299999, 35.229680880000046],
            [-80.878337252999984, 35.229572880000035],
            [-80.87835725299999, 35.229519881000044],
            [-80.878382252999984, 35.229468881000059],
            [-80.878534252999941, 35.229230880000046],
            [-80.878581252999936, 35.229131881000058],
            [-80.878670252999939, 35.22890588000007],
            [-80.878735251999956, 35.228695880000032],
            [-80.878829252999935, 35.22833488100008],
            [-80.878857251999989, 35.228168880000055],
            [-80.878881252999975, 35.22794588000005],
            [-80.879135252999959, 35.227955881000071],
            [-80.879270252999959, 35.227975880000031],
            [-80.879399251999985, 35.228011880000054],
            [-80.879520251999963, 35.228064880000034],
            [-80.879602251999984, 35.228111880000029],
            [-80.879850252999972, 35.228288881000026],
            [-80.879984252999975, 35.228369880000059],
            [-80.880280252999967, 35.228509881000036],
            [-80.880417251999972, 35.228570880000063],
            [-80.881646252999985, 35.229120880000039],
            [-80.881712253999979, 35.229155880000064],
            [-80.881835253999952, 35.229237880000028],
            [-80.881943252999974, 35.229332880000072],
            [-80.881991253999956, 35.229383880000057],
            [-80.882082253999954, 35.229495880000059],
            [-80.882123253999964, 35.22956688000005],
            [-80.882681253999976, 35.229517881000049],
            [-80.882779253999956, 35.229493881000053],
            [-80.882868253999959, 35.22945388100004],
            [-80.882944253999938, 35.229398880000076],
            [-80.882977253999968, 35.22936788100003],
            [-80.883006253999952, 35.229332880000072],
            [-80.883050253999954, 35.229257880000034],
            [-80.88326925399997, 35.228648880000037],
            [-80.88336725399995, 35.228394880000053],
            [-80.883411253999952, 35.228328880000049],
            [-80.883439252999949, 35.228298880000068],
            [-80.883507253999937, 35.228248880000024],
            [-80.883587254999952, 35.228213880000055],
            [-80.883675253999968, 35.228193880000049],
            [-80.883721253999965, 35.228189880000059],
            [-80.884292253999945, 35.22821188000006],
            [-80.884366253999985, 35.226960880000036],
            [-80.884397253999964, 35.226141879000068],
            [-80.884412252999937, 35.225874880000049],
            [-80.884430253999938, 35.225667879000071],
            [-80.884460253999976, 35.225061879000066],
            [-80.884498253999936, 35.224464880000028],
            [-80.884517253999945, 35.224073880000049],
            [-80.883242253999981, 35.223998879000078],
            [-80.883254252999961, 35.223740879000047],
            [-80.88324025299994, 35.223150878000069],
            [-80.88323225399995, 35.222530879000033],
            [-80.883236253999939, 35.222248880000052],
            [-80.883239253999989, 35.222025878000068],
            [-80.883259252999949, 35.221486879000054],
            [-80.883286253999984, 35.22115487800005],
            [-80.883340252999972, 35.22064087800004],
            [-80.883361252999975, 35.220450878000065],
            [-80.883543252999971, 35.218775878000031],
            [-80.883587253999963, 35.218376878000072],
            [-80.883683253999948, 35.21752987800005],
            [-80.883756252999945, 35.216968877000056],
            [-80.883782253999982, 35.216727878000029],
            [-80.883789253999964, 35.216587878000041],
            [-80.88377925399999, 35.216411878000031],
            [-80.883766252999976, 35.216323878000026],
            [-80.883730253999943, 35.216172877000076],
            [-80.883433252999964, 35.216243878000057],
            [-80.882885252999984, 35.216365877000044],
            [-80.882334252999954, 35.216480877000038],
            [-80.882032252999977, 35.216538877000062],
            [-80.881855252999969, 35.216577877000077],
            [-80.881677252999964, 35.216611878000037],
            [-80.88152925299994, 35.216635877000044],
            [-80.881186252999953, 35.216681877000042],
            [-80.880997251999986, 35.216699878000043],
            [-80.88011925099994, 35.21674987800003],
            [-80.877609250999967, 35.216874878000056],
            [-80.876907250999977, 35.216909878000024],
            [-80.876664251999955, 35.216931878000025],
            [-80.876424250999946, 35.216969878000043],
            [-80.876230250999981, 35.217013878000046],
            [-80.876015251999945, 35.217078878000052],
            [-80.875911249999945, 35.217117878000067],
            [-80.875232250999943, 35.217403878000027],
            [-80.875097250999943, 35.217455877000077],
            [-80.874859249999986, 35.217534878000038],
            [-80.87468125099997, 35.217584879000071],
            [-80.874541250999982, 35.217618878000053],
            [-80.874257249999971, 35.217673878000028],
            [-80.874004249999984, 35.217712878000043],
            [-80.873784249999972, 35.217733878000047],
            [-80.87321424999999, 35.217763878000028],
            [-80.87259125099996, 35.217795878000061],
            [-80.87227624999997, 35.217811878000077],
            [-80.872173249999946, 35.217812878000075],
            [-80.871971249999945, 35.217806878000033],
            [-80.871785249999959, 35.217789878000076],
            [-80.87163524999994, 35.217768878000072],
            [-80.871420249999971, 35.217725879000056],
            [-80.871348248999936, 35.217708878000053],
            [-80.871071248999954, 35.217624878000038],
            [-80.87085024999999, 35.217536878000033],
            [-80.869924248999951, 35.217089878000024],
            [-80.869534248999969, 35.216892878000067],
            [-80.868918248999989, 35.216588878000039],
            [-80.868845249999936, 35.216555879000055],
            [-80.868573248999951, 35.216446878000056],
            [-80.868434248999961, 35.216398878000064],
            [-80.86825824899995, 35.216346877000035],
            [-80.868142248999959, 35.216311878000056],
            [-80.867993248999937, 35.216274878000036],
            [-80.867819248999979, 35.216236878000075],
            [-80.867637248999984, 35.216200878000052],
            [-80.867209247999938, 35.21614487800008],
            [-80.867000247999954, 35.216116878000037],
            [-80.866383247999977, 35.216028879000078],
            [-80.866076248999946, 35.215930878000052],
            [-80.865764247999948, 35.215807878000078],
            [-80.865592247999984, 35.216079878000073],
            [-80.86548924799996, 35.216210878000027],
            [-80.865275247999989, 35.216454878000036],
            [-80.865101247999974, 35.216621878000069],
            [-80.864987246999988, 35.216717878000054],
            [-80.864793246999966, 35.216867879000063],
            [-80.862778246999937, 35.215929879000043],
            [-80.861465246999956, 35.215315878000069],
            [-80.86072824699994, 35.214969878000034],
            [-80.860254245999954, 35.21476087800005],
            [-80.860051246999944, 35.214677878000032],
            [-80.859361246999981, 35.214362878000031],
            [-80.857916245999945, 35.21368787800003],
            [-80.858035245999986, 35.21354887900003],
            [-80.858358244999977, 35.213152878000074],
            [-80.858832245999963, 35.212582877000045],
            [-80.858667245999982, 35.212483878000057],
            [-80.857743245999984, 35.213592878000043],
            [-80.857696244999943, 35.213648878000072],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853228243999979, 35.211989878000054],
            [-80.852833244999943, 35.212235878000058],
            [-80.852520243999948, 35.212424878000036],
            [-80.852079243999981, 35.212699877000034],
            [-80.851379243999986, 35.213132878000067],
            [-80.850923243999944, 35.213411878000045],
            [-80.850397242999975, 35.213738878000072],
            [-80.85006924299995, 35.213943878000066],
            [-80.849892241999953, 35.214053879000062],
            [-80.849755242999947, 35.214138878000028],
            [-80.84899524299999, 35.214612879000072],
            [-80.848618242999976, 35.214841879000062],
            [-80.84793724299999, 35.215264879000074],
            [-80.846675241999947, 35.21604787900003],
            [-80.84703824199994, 35.216436879000071],
            [-80.847395242999937, 35.21682787900005],
            [-80.847620242999938, 35.217063879000079],
            [-80.848028242999987, 35.217509879000033],
            [-80.848161242999936, 35.217662879000045],
            [-80.848274242999935, 35.217801880000025],
            [-80.848681242999987, 35.218344879000028],
            [-80.848825242999965, 35.21854687900003],
            [-80.848906242999988, 35.218659879000029],
            [-80.848801241999979, 35.218703879000032],
            [-80.848548242999982, 35.21879688000007],
            [-80.848432241999944, 35.218829879000054],
            [-80.848287242999959, 35.218871879000062],
            [-80.846978242999967, 35.219241880000027],
            [-80.84679324199999, 35.219287879000035],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.848263242999963, 35.220648880000056],
            [-80.848176242999955, 35.220701880000036],
            [-80.848100241999987, 35.220746880000036],
            [-80.848056243999963, 35.220773879000035],
            [-80.847960243999978, 35.220831880000048],
            [-80.847585242999969, 35.221055880000051],
            [-80.84672524299998, 35.221481880000056],
            [-80.846407241999941, 35.221622880000041],
            [-80.844256241999972, 35.223000881000075],
            [-80.844212241999969, 35.223028881000062],
            [-80.844798241999968, 35.223544880000077],
            [-80.845257241999946, 35.223980881000045],
            [-80.845511241999986, 35.224206880000054],
            [-80.846231241999988, 35.224818881000033],
            [-80.846323241999983, 35.224890881000078],
            [-80.846382241999947, 35.224926880000055],
            [-80.846695242999942, 35.224715881000066],
            [-80.846959242999958, 35.224528881000026],
            [-80.847507242999939, 35.224164880000046],
            [-80.847782242999983, 35.223976880000066],
            [-80.847962242999984, 35.223846881000043],
            [-80.84852224399998, 35.223463880000054],
            [-80.848963242999957, 35.223154880000038],
            [-80.849025471999937, 35.223105616000055],
            [-80.848938263999969, 35.227147163000041],
            [-80.848887242999979, 35.227102881000064],
            [-80.84878624299995, 35.227008882000064],
            [-80.848639243999969, 35.226895881000075],
            [-80.848597243999961, 35.226866882000024],
            [-80.848276243999976, 35.226571881000041],
            [-80.847683243999938, 35.226054881000039],
            [-80.847448242999974, 35.22584388100006],
            [-80.846972242999982, 35.226176881000072],
            [-80.846622242999956, 35.226421882000068],
            [-80.84640624299999, 35.226583881000067],
            [-80.846361243999979, 35.226622881000026],
            [-80.846228242999985, 35.226719881000065],
            [-80.845785241999977, 35.227026882000075],
            [-80.845486241999936, 35.227213881000068],
            [-80.845310242999972, 35.227338882000026],
            [-80.844301241999972, 35.228049881000061],
            [-80.844168242999956, 35.228137882000055],
            [-80.844465242999945, 35.228398881000032],
            [-80.845141241999954, 35.228975882000043],
            [-80.845665241999939, 35.229426882000041],
            [-80.845744242999956, 35.229494881000051],
            [-80.847024242999964, 35.23059788200004],
            [-80.84778324399997, 35.231251882000038],
            [-80.848430242999939, 35.231808882000053],
            [-80.849990243999969, 35.233008882000036],
            [-80.850660244999972, 35.23352488200004],
            [-80.85111124499997, 35.23383988300003],
            [-80.852425245999939, 35.234757882000054],
            [-80.85260124499996, 35.234880883000073],
            [-80.852876244999948, 35.235073883000041],
            [-80.853034244999947, 35.235153883000066],
            [-80.853419245999987, 35.235316882000063],
            [-80.853506244999949, 35.235353883000073],
            [-80.853684244999954, 35.235418882000033],
            [-80.853750245999947, 35.235447883000063],
            [-80.853872244999934, 35.235496882000064],
            [-80.853970244999971, 35.235529883000027],
            [-80.854217245999962, 35.235597882000036],
            [-80.854268245999947, 35.235608883000054],
            [-80.854366245999984, 35.235632883000051],
            [-80.854471245999946, 35.235652882000068],
            [-80.854499245999989, 35.235659883000039],
            [-80.854927245999988, 35.235736883000072],
            [-80.855039246999979, 35.235762883000064],
            [-80.855541245999973, 35.235873883000068],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859762246999935, 35.231423882000058]
          ],
          [
            [-80.849440243999936, 35.230804882000029],
            [-80.849286244999973, 35.230664881000052],
            [-80.849086243999977, 35.23050788200004],
            [-80.848384243999988, 35.229897882000046],
            [-80.848232243999973, 35.229775882000069],
            [-80.848268243999939, 35.229751882000073],
            [-80.848292243999936, 35.22974288100005],
            [-80.848892448999948, 35.229270301000042],
            [-80.848881873999972, 35.229760354000064],
            [-80.850423099999944, 35.22978259000007],
            [-80.850287243999958, 35.22988188100004],
            [-80.849696243999972, 35.230548881000061],
            [-80.849440243999936, 35.230804882000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 62,
        "SolutionID": "PI-20",
        "Shape_Length": 0.15065350736014169,
        "Shape_Area": 0.00062485170308429656
      }
    },
    {
      "type": "Feature",
      "id": 63,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.941782168999964, 35.250049313000034],
            [-80.942176022999945, 35.250039389000051],
            [-80.942569509999942, 35.250022270000045],
            [-80.942962431999945, 35.249997962000066],
            [-80.943354598999974, 35.249966479000079],
            [-80.943745810999985, 35.249927834000061],
            [-80.944135876999951, 35.249882049000064],
            [-80.944524600999955, 35.249829143000056],
            [-80.944911791999971, 35.249769147000052],
            [-80.945297256999936, 35.24970208700006],
            [-80.945680804999938, 35.24962800000003],
            [-80.946062243999961, 35.249546918000078],
            [-80.946441384999957, 35.249458885000024],
            [-80.946818040999972, 35.249363943000048],
            [-80.947192023999946, 35.249262140000042],
            [-80.947563147999972, 35.249153527000033],
            [-80.947931229999938, 35.24903815600004],
            [-80.948296083999935, 35.248916086000065],
            [-80.94865753299996, 35.248787377000042],
            [-80.949015394999947, 35.248652094000079],
            [-80.94936949199996, 35.248510302000057],
            [-80.949719650999953, 35.248362074000056],
            [-80.950065693999989, 35.248207481000065],
            [-80.950407451999979, 35.248046602000045],
            [-80.95074475499996, 35.247879515000079],
            [-80.951077434999945, 35.247706305000065],
            [-80.951405326999975, 35.247527056000024],
            [-80.951728267999954, 35.247341858000027],
            [-80.952046096999936, 35.247150804000057],
            [-80.95235865799998, 35.24695398700004],
            [-80.952665793999984, 35.246751506000066],
            [-80.952967351999973, 35.246543461000044],
            [-80.953263185999958, 35.246329955000078],
            [-80.953553143999955, 35.24611109500006],
            [-80.953837086999954, 35.245886990000031],
            [-80.954114869999955, 35.245657749000031],
            [-80.954386357999965, 35.245423488000029],
            [-80.954651414999944, 35.245184323000046],
            [-80.954909909999969, 35.244940371000041],
            [-80.955161712999939, 35.244691756000066],
            [-80.955406700999959, 35.244438599000034],
            [-80.955644751999955, 35.244181028000071],
            [-80.955875746999936, 35.243919169000037],
            [-80.956099570999982, 35.243653153000025],
            [-80.956316113999947, 35.243383110000025],
            [-80.95652526899994, 35.243109177000065],
            [-80.956726931999981, 35.242831489000025],
            [-80.956921001999945, 35.242550184000038],
            [-80.957107382999936, 35.242265402000044],
            [-80.957285982999963, 35.24197728300004],
            [-80.957456712999942, 35.241685971000038],
            [-80.957619487999978, 35.241391610000051],
            [-80.957774227999948, 35.241094347000057],
            [-80.957920855999987, 35.240794330000028],
            [-80.958059299999945, 35.240491706000057],
            [-80.958189490999985, 35.240186627000071],
            [-80.95831136299995, 35.239879245000054],
            [-80.958424855999965, 35.239569711000058],
            [-80.958529915999975, 35.239258178000057],
            [-80.958626488999982, 35.238944803000038],
            [-80.958714525999937, 35.238629741000068],
            [-80.958793985999989, 35.238313147000042],
            [-80.958864828999936, 35.237995181000031],
            [-80.95892701799994, 35.237675997000053],
            [-80.958980524999959, 35.237355756000056],
            [-80.959025321999945, 35.237034616000074],
            [-80.959061387999952, 35.236712738000051],
            [-80.959088701999974, 35.236390279000034],
            [-80.959107254999935, 35.23606740200006],
            [-80.959117034999963, 35.23574426600004],
            [-80.959118037999986, 35.235421031000044],
            [-80.959110263999946, 35.235097858000074],
            [-80.959093714999938, 35.234774908000077],
            [-80.959068401999957, 35.234452340000075],
            [-80.959034335999945, 35.234130314000026],
            [-80.958991535999985, 35.233808992000036],
            [-80.958940019999943, 35.233488530000045],
            [-80.958879816999968, 35.233169091000036],
            [-80.95881095499999, 35.232850831000064],
            [-80.958733468999981, 35.23253390900004],
            [-80.958647396999936, 35.232218482000064],
            [-80.95855278199997, 35.231904707000069],
            [-80.958449672999961, 35.231592738000074],
            [-80.958338117999972, 35.231282732000068],
            [-80.958218173999967, 35.23097484300007],
            [-80.958089901999983, 35.230669222000074],
            [-80.957953362999945, 35.230366020000076],
            [-80.957808626999963, 35.230065391000039],
            [-80.957655763999981, 35.229767482000057],
            [-80.957494852999957, 35.229472441000041],
            [-80.957325970999989, 35.229180414000041],
            [-80.957149203999961, 35.228891547000046],
            [-80.956964637999988, 35.228605982000033],
            [-80.956772364999949, 35.228323862000025],
            [-80.956572481999956, 35.228045326000029],
            [-80.956365086999938, 35.227770513000053],
            [-80.956150282999943, 35.227499560000069],
            [-80.955928176999976, 35.227232600000036],
            [-80.955698879999943, 35.226969766000025],
            [-80.955462504999957, 35.226711189000071],
            [-80.955219167999985, 35.226456996000024],
            [-80.954968991999976, 35.226207315000067],
            [-80.954712100999984, 35.225962269000036],
            [-80.954448621999973, 35.22572197900007],
            [-80.954178685999977, 35.22548656400005],
            [-80.953902425999956, 35.225256143000024],
            [-80.953619979999985, 35.225030828000058],
            [-80.953331488999936, 35.224810732000037],
            [-80.953037094999956, 35.22459596300007],
            [-80.95273694499997, 35.224386630000026],
            [-80.952431186999945, 35.224182834000032],
            [-80.95211997399997, 35.223984679000068],
            [-80.951803458999962, 35.223792261000028],
            [-80.951481799999954, 35.22360567700008],
            [-80.95115515599997, 35.223425017000068],
            [-80.950823689999936, 35.223250375000077],
            [-80.950487566999982, 35.223081834000027],
            [-80.950146950999965, 35.222919479000041],
            [-80.949802013999943, 35.222763390000068],
            [-80.949452926999982, 35.222613645000024],
            [-80.949099860999979, 35.222470318000035],
            [-80.948742992999939, 35.222333481000078],
            [-80.94838249999998, 35.222203200000024],
            [-80.94801855999998, 35.222079541000028],
            [-80.947651353999959, 35.221962567000048],
            [-80.94728106499997, 35.221852331000036],
            [-80.946907874999965, 35.22174889300004],
            [-80.946531971999946, 35.221652303000042],
            [-80.946153537999976, 35.221562606000077],
            [-80.945772764999958, 35.221479850000037],
            [-80.94538984199994, 35.221404074000077],
            [-80.945004955999934, 35.221335316000079],
            [-80.944618298999956, 35.221273611000072],
            [-80.944230062999964, 35.221218990000068],
            [-80.943840441999953, 35.221171477000041],
            [-80.943449627999939, 35.221131099000047],
            [-80.943057813999985, 35.221097874000066],
            [-80.94266519699994, 35.221071820000077],
            [-80.942271968999989, 35.221052949000068],
            [-80.941878327999973, 35.221041270000057],
            [-80.941484465999963, 35.221036790000028],
            [-80.94109057999998, 35.221039510000026],
            [-80.940696865999939, 35.221049429000061],
            [-80.94030351899994, 35.221066543000063],
            [-80.939910735999945, 35.221090841000034],
            [-80.939518706999934, 35.221122315000059],
            [-80.939127630999963, 35.221160945000065],
            [-80.93873769999999, 35.22120671600004],
            [-80.938349107999954, 35.221259602000032],
            [-80.937962047999974, 35.221319578000077],
            [-80.937576710999963, 35.221386614000039],
            [-80.937193288999936, 35.221460677000039],
            [-80.936811971999987, 35.221541730000069],
            [-80.936432950999972, 35.221629734000032],
            [-80.936056410999981, 35.221724644000062],
            [-80.935682539999959, 35.221826413000031],
            [-80.935311524999975, 35.221934991000069],
            [-80.934943547999978, 35.222050323000076],
            [-80.934578793999947, 35.222172354000065],
            [-80.934217440999987, 35.222301020000032],
            [-80.933859669999947, 35.222436261000041],
            [-80.933505658999934, 35.222578008000028],
            [-80.933155581999983, 35.222726191000049],
            [-80.93280961499994, 35.222880735000047],
            [-80.932467927999937, 35.223041566000063],
            [-80.932130689999951, 35.223208602000057],
            [-80.931798069999957, 35.223381761000041],
            [-80.93147023299997, 35.223560957000075],
            [-80.931147339999939, 35.223746101000074],
            [-80.930829552999967, 35.223937102000036],
            [-80.93051702799994, 35.224133864000066],
            [-80.93020992299995, 35.224336289000064],
            [-80.929908387999944, 35.224544278000053],
            [-80.929612572999986, 35.224757728000043],
            [-80.929322625999987, 35.224976531000038],
            [-80.929038688999981, 35.225200580000035],
            [-80.928760903999944, 35.225429764000069],
            [-80.928489408999951, 35.225663968000049],
            [-80.928224338999939, 35.225903077000055],
            [-80.927965824999944, 35.226146972000038],
            [-80.927713995999966, 35.226395531000037],
            [-80.92746897699999, 35.226648632000035],
            [-80.92723088799994, 35.226906149000058],
            [-80.926999848999969, 35.227167954000038],
            [-80.926775974999941, 35.227433918000031],
            [-80.926559375999943, 35.227703907000034],
            [-80.92635015999997, 35.227977789000079],
            [-80.926148429999955, 35.228255427000079],
            [-80.925954287999957, 35.228536684000062],
            [-80.925767829999984, 35.228821419000042],
            [-80.925589146999982, 35.229109492000077],
            [-80.925418328999967, 35.229400760000033],
            [-80.925255461999939, 35.229695077000031],
            [-80.92510062499997, 35.22999229900006],
            [-80.924953894999987, 35.230292278000036],
            [-80.924815345999946, 35.230594864000068],
            [-80.924685045999979, 35.230899906000047],
            [-80.924563059999969, 35.231207256000062],
            [-80.92444944999994, 35.231516759000044],
            [-80.92434426899996, 35.231828261000032],
            [-80.924247572999946, 35.232141609000053],
            [-80.924159409999959, 35.232456648000039],
            [-80.924079819999974, 35.232773218000034],
            [-80.924008845999936, 35.233091166000065],
            [-80.923946523999973, 35.233410331000073],
            [-80.923892881999961, 35.233730557000058],
            [-80.923847948999935, 35.234051683000075],
            [-80.923811744999966, 35.234373552000079],
            [-80.923784290999947, 35.234696002000078],
            [-80.923765598999978, 35.235018874000048],
            [-80.923755678999953, 35.235342008000032],
            [-80.923754534999944, 35.235665242000039],
            [-80.923762168999986, 35.235988417000044],
            [-80.92377857799994, 35.236311373000035],
            [-80.923803751999969, 35.236633948000076],
            [-80.923837679999963, 35.23695598300003],
            [-80.923880342999951, 35.237277318000054],
            [-80.923931722999953, 35.237597793000077],
            [-80.923991791999981, 35.237917250000066],
            [-80.92406052299998, 35.238235530000054],
            [-80.924137878999943, 35.238552474000073],
            [-80.924223823999966, 35.238867924000033],
            [-80.924318314999937, 35.239181727000073],
            [-80.924421302999974, 35.239493723000066],
            [-80.924532738999972, 35.239803760000029],
            [-80.924652569999978, 35.240111684000055],
            [-80.924780730999942, 35.240417339000032],
            [-80.924917162999975, 35.240720577000047],
            [-80.925061796999955, 35.241021246000059],
            [-80.925214560999962, 35.241319195000074],
            [-80.925375378999945, 35.241614279000032],
            [-80.925544171999945, 35.241906350000079],
            [-80.925720854999952, 35.242195263000042],
            [-80.925905342999954, 35.242480875000069],
            [-80.92609754199998, 35.242763043000025],
            [-80.926297356999953, 35.243041628000071],
            [-80.926504688999955, 35.24331649100003],
            [-80.926719436999974, 35.243587497000078],
            [-80.92694149099998, 35.243854510000062],
            [-80.927170742999976, 35.244117398000071],
            [-80.927407078999977, 35.244376030000069],
            [-80.927650381999968, 35.244630277000056],
            [-80.927900530999977, 35.244880014000046],
            [-80.928157401999954, 35.245125117000043],
            [-80.928420865999954, 35.245365463000041],
            [-80.928690792999987, 35.245600935000027],
            [-80.928967050999972, 35.245831413000076],
            [-80.929249499999969, 35.246056784000075],
            [-80.929538001999958, 35.246276937000061],
            [-80.929832411999939, 35.246491761000073],
            [-80.930132583999978, 35.246701152000071],
            [-80.930438371999969, 35.246905002000062],
            [-80.930749618999982, 35.247103213000059],
            [-80.931066174999955, 35.247295686000029],
            [-80.931387880999978, 35.247482323000042],
            [-80.93171457699998, 35.247663035000073],
            [-80.932046101999958, 35.247837730000072],
            [-80.932382288999975, 35.248006321000048],
            [-80.932722973999944, 35.248168726000074],
            [-80.933067985999969, 35.24832486300005],
            [-80.933417153999983, 35.248474654000063],
            [-80.93377030399995, 35.248618026000031],
            [-80.934127262999937, 35.248754907000034],
            [-80.934487849999982, 35.248885229000052],
            [-80.934851888999958, 35.24900892800008],
            [-80.93521919899996, 35.249125942000035],
            [-80.935589595999943, 35.249236213000074],
            [-80.935962896999968, 35.249339686000042],
            [-80.936338915999954, 35.249436309000032],
            [-80.936717467999983, 35.249526036000077],
            [-80.937098362999961, 35.249608820000049],
            [-80.937481410999965, 35.249684622000075],
            [-80.937866424999982, 35.249753403000057],
            [-80.93825321199995, 35.249815129000069],
            [-80.938641579999967, 35.249869770000032],
            [-80.939031335999971, 35.249917298000071],
            [-80.939422285999967, 35.249957691000077],
            [-80.939814235999961, 35.249990927000056],
            [-80.940206991999958, 35.250016991000052],
            [-80.940600359999962, 35.250035868000055],
            [-80.940994140999976, 35.250047552000069],
            [-80.941388142999983, 35.250052033000031],
            [-80.941782168999964, 35.250049313000034]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 63,
        "SolutionID": "PI-24:C",
        "Shape_Length": 0.10137199688095928,
        "Shape_Area": 0.00080584671362595361
      }
    },
    {
      "type": "Feature",
      "id": 64,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.968977640999981, 35.263870349000058],
            [-80.969371557999978, 35.263860338000029],
            [-80.969765105999954, 35.263843130000055],
            [-80.970158086999959, 35.26381873400004],
            [-80.970550309999965, 35.263787163000075],
            [-80.970941575999973, 35.263748429000032],
            [-80.971331691999978, 35.263702557000045],
            [-80.971720464999976, 35.263649564000048],
            [-80.97210770199996, 35.263589481000054],
            [-80.972493209999982, 35.263522335000062],
            [-80.972876797999959, 35.263448161000042],
            [-80.973258274999978, 35.26336699400008],
            [-80.973637450999945, 35.263278876000072],
            [-80.974014137999973, 35.263183850000075],
            [-80.97438815199996, 35.263081963000047],
            [-80.974759301999939, 35.262973267000064],
            [-80.975127406999945, 35.262857813000039],
            [-80.975492282999937, 35.262735662000068],
            [-80.975853749999942, 35.262606872000049],
            [-80.976211627999987, 35.262471508000033],
            [-80.976565737999977, 35.26232963700005],
            [-80.976915905999988, 35.262181330000033],
            [-80.977261956999939, 35.26202666000006],
            [-80.977603718999944, 35.261865705000048],
            [-80.977941022999971, 35.26169854300008],
            [-80.978273701999967, 35.261525258000063],
            [-80.978601588999936, 35.261345936000055],
            [-80.978924522999989, 35.26116066600008],
            [-80.979242341999964, 35.260969541000065],
            [-80.979554890999964, 35.26077265400005],
            [-80.979862010999966, 35.260570104000067],
            [-80.980163550999976, 35.260361992000071],
            [-80.980459362999966, 35.260148421000054],
            [-80.980749297999978, 35.259929495000051],
            [-80.981033213999979, 35.259705327000063],
            [-80.981310967999946, 35.259476024000037],
            [-80.981582423999953, 35.259241703000043],
            [-80.981847445999961, 35.25900247900006],
            [-80.98210590299999, 35.258758470000032],
            [-80.982357665999984, 35.258509798000034],
            [-80.982602610999948, 35.258256587000062],
            [-80.982840615999976, 35.257998963000034],
            [-80.983071562999953, 35.257737053000028],
            [-80.983295335999969, 35.257470986000044],
            [-80.983511825999983, 35.257200896000029],
            [-80.983720924999943, 35.256926917000044],
            [-80.983922529999973, 35.256649184000025],
            [-80.984116537999967, 35.256367836000038],
            [-80.984302854999953, 35.256083012000033],
            [-80.984481389999985, 35.255794854000044],
            [-80.984652051999944, 35.255503504000046],
            [-80.984814755999935, 35.255209108000031],
            [-80.984969422999939, 35.254911811000056],
            [-80.985115975999975, 35.254611761000035],
            [-80.985254341999962, 35.25430910700004],
            [-80.985384452999938, 35.254004000000066],
            [-80.985506242999975, 35.253696590000061],
            [-80.985619651999968, 35.253387032000035],
            [-80.985724625999978, 35.253075476000049],
            [-80.985821109999961, 35.252762080000025],
            [-80.985909056999958, 35.252446999000028],
            [-80.98598842499996, 35.252130388000069],
            [-80.986059173999934, 35.251812406000056],
            [-80.986121266999987, 35.251493209000046],
            [-80.986174675999962, 35.251172957000051],
            [-80.986219372999983, 35.25085180800005],
            [-80.986255336999989, 35.250529922000055],
            [-80.986282548999952, 35.250207458000034],
            [-80.986300996999944, 35.249884578000035],
            [-80.986310669999966, 35.249561440000036],
            [-80.986311565999983, 35.24923820600003],
            [-80.986303681999971, 35.248915035000039],
            [-80.986287022999988, 35.248592089000056],
            [-80.986261597999942, 35.248269527000048],
            [-80.986227418999988, 35.247947510000074],
            [-80.986184503999937, 35.247626198000034],
            [-80.986132871999985, 35.247305749000077],
            [-80.986072551999939, 35.24698632500008],
            [-80.986003571999959, 35.246668081000053],
            [-80.985925967999947, 35.246351177000065],
            [-80.985839775999978, 35.246035770000049],
            [-80.985745039999983, 35.245722017000048],
            [-80.985641808999958, 35.245410072000027],
            [-80.985530131999951, 35.24510009100004],
            [-80.98541006499994, 35.244792229000041],
            [-80.985281667999971, 35.244486638000069],
            [-80.985145005999982, 35.244183468000074],
            [-80.985000143999969, 35.243882872000029],
            [-80.98484715699999, 35.243584998000074],
            [-80.984686118999946, 35.243289993000076],
            [-80.984517110999946, 35.242998005000061],
            [-80.984340217999943, 35.242709178000041],
            [-80.984155524999949, 35.242423656000028],
            [-80.983963124999946, 35.242141580000066],
            [-80.983763115999977, 35.241863090000038],
            [-80.983555593999938, 35.241588324000077],
            [-80.983340661999989, 35.24131741900004],
            [-80.983118429999934, 35.241050510000036],
            [-80.982889005999937, 35.240787728000043],
            [-80.982652503999986, 35.24052920500003],
            [-80.982409041999972, 35.240275067000027],
            [-80.982158739999988, 35.240025443000036],
            [-80.981901722999964, 35.239780455000073],
            [-80.981638118999967, 35.239540225000042],
            [-80.981368058999976, 35.239304871000058],
            [-80.981091674999959, 35.239074512000059],
            [-80.980809105999981, 35.238849262000031],
            [-80.980520492999972, 35.238629231000061],
            [-80.980225976999975, 35.238414529000067],
            [-80.97992570699995, 35.238205264000044],
            [-80.979619827999954, 35.238001537000059],
            [-80.979308495999987, 35.237803452000037],
            [-80.978991863999966, 35.237611106000031],
            [-80.978670086999955, 35.237424594000061],
            [-80.978343327999937, 35.237244009000051],
            [-80.978011747999972, 35.237069441000074],
            [-80.977675510999973, 35.236900976000072],
            [-80.977334782999947, 35.236738698000067],
            [-80.97698973599995, 35.236582687000066],
            [-80.976640538999959, 35.236433021000039],
            [-80.976287365999951, 35.236289774000056],
            [-80.975930390999963, 35.23615301600006],
            [-80.975569793999966, 35.236022817000048],
            [-80.975205750999976, 35.235899240000037],
            [-80.974838442999953, 35.235782348000043],
            [-80.974468054999988, 35.235672197000042],
            [-80.97409476699994, 35.235568843000067],
            [-80.973718767999969, 35.235472337000033],
            [-80.97334023999997, 35.235382726000068],
            [-80.972959374999959, 35.235300055000039],
            [-80.972576360999938, 35.235224365000079],
            [-80.972191386999953, 35.235155694000071],
            [-80.971804643999974, 35.235094076000053],
            [-80.971416323999961, 35.235039542000038],
            [-80.971026620999965, 35.234992117000047],
            [-80.970635726999944, 35.234951827000032],
            [-80.970243835999952, 35.234918690000029],
            [-80.969851142999971, 35.234892724000076],
            [-80.96945784199994, 35.234873941000046],
            [-80.969064129999936, 35.23486235200005],
            [-80.968670199999963, 35.234857959000067],
            [-80.968276248999985, 35.234860768000033],
            [-80.967882470999939, 35.234870776000037],
            [-80.96748906299996, 35.234887978000074],
            [-80.967096220999963, 35.234912365000071],
            [-80.966704135999976, 35.234943926000028],
            [-80.96631300599995, 35.234982645000059],
            [-80.965923024999938, 35.235028502000034],
            [-80.965534383999966, 35.235081475000072],
            [-80.96514727899995, 35.23514153900004],
            [-80.964761898999939, 35.235208661000058],
            [-80.964378435999947, 35.235282810000058],
            [-80.963997081999935, 35.235363949000032],
            [-80.963618025999949, 35.235452038000062],
            [-80.963241453999956, 35.235547032000056],
            [-80.962867553999956, 35.235648885000046],
            [-80.962496511999973, 35.235757546000059],
            [-80.962128510999946, 35.235872960000052],
            [-80.961763735999966, 35.235995073000026],
            [-80.96140236399998, 35.236123820000046],
            [-80.961044577999985, 35.236259141000062],
            [-80.960690553999939, 35.236400967000066],
            [-80.96034046699998, 35.236549228000058],
            [-80.959994492999954, 35.236703851000073],
            [-80.959652800999947, 35.236864757000035],
            [-80.959315561999972, 35.237031869000077],
            [-80.958982942999967, 35.237205102000075],
            [-80.958655109999938, 35.237384372000065],
            [-80.958332223999946, 35.237569588000042],
            [-80.958014447999972, 35.237760660000049],
            [-80.957701934999989, 35.237957491000031],
            [-80.957394844999953, 35.238159985000038],
            [-80.957093328999974, 35.238368041000058],
            [-80.956797534999964, 35.238581557000032],
            [-80.95650761099995, 35.238800425000079],
            [-80.956223700999942, 35.239024537000034],
            [-80.955945945999986, 35.239253783000038],
            [-80.955674482999939, 35.239488048000055],
            [-80.955409446999965, 35.239727216000063],
            [-80.955150970999966, 35.239971168000068],
            [-80.954899182999952, 35.240219783000043],
            [-80.954654205999987, 35.240472939000028],
            [-80.954416161999973, 35.240730509000059],
            [-80.954185171999939, 35.240992365000068],
            [-80.95396134799995, 35.241258378000055],
            [-80.95374480299995, 35.241528416000051],
            [-80.953535642999952, 35.241802344000064],
            [-80.95333397099995, 35.242080027000043],
            [-80.953139890999978, 35.242361327000026],
            [-80.952953495999964, 35.242646103000027],
            [-80.952774878999946, 35.242934216000037],
            [-80.952604128999951, 35.243225521000056],
            [-80.952441332999967, 35.243519875000061],
            [-80.952286567999977, 35.243817131000071],
            [-80.952139913999986, 35.24411714200005],
            [-80.952001441999982, 35.244419758000049],
            [-80.951871221999966, 35.244724829000063],
            [-80.951749317999941, 35.245032206000076],
            [-80.951635791999934, 35.245341733000032],
            [-80.951530696999953, 35.245653259000051],
            [-80.951434089999964, 35.245966628000076],
            [-80.951346015999945, 35.246281685000042],
            [-80.951266518999944, 35.246598273000075],
            [-80.951195638999934, 35.246916235000072],
            [-80.95113341199999, 35.247235414000045],
            [-80.951079867999965, 35.247555652000074],
            [-80.951035034999961, 35.247876787000052],
            [-80.950998931999948, 35.248198663000039],
            [-80.950971580999976, 35.248521118000042],
            [-80.950952993999977, 35.248843994000026],
            [-80.950943179999967, 35.249167129000057],
            [-80.950942143999953, 35.249490363000064],
            [-80.950949886999979, 35.249813536000033],
            [-80.950966406999953, 35.250136487000077],
            [-80.950991691999945, 35.250459056000068],
            [-80.951025732999938, 35.25078108200006],
            [-80.95106851099996, 35.251102407000076],
            [-80.951120006999986, 35.251422870000056],
            [-80.951180192999971, 35.251742313000079],
            [-80.951249041999972, 35.252060576000076],
            [-80.951326516999984, 35.252377502000058],
            [-80.951412581999989, 35.252692932000059],
            [-80.951507192999941, 35.253006713000048],
            [-80.951610302999939, 35.253318685000067],
            [-80.951721861999943, 35.253628697000067],
            [-80.951841814999966, 35.253936593000049],
            [-80.951970100999972, 35.254242219000048],
            [-80.952106656999945, 35.25454542500006],
            [-80.952251415999967, 35.25484606100008],
            [-80.952404305999949, 35.255143975000067],
            [-80.952565249999964, 35.255439022000076],
            [-80.952734168999939, 35.255731055000069],
            [-80.952910977999977, 35.256019927000068],
            [-80.953095591999954, 35.256305497000028],
            [-80.953287917999944, 35.256587621000051],
            [-80.953487859999939, 35.256866161000062],
            [-80.953695318999962, 35.257140977000063],
            [-80.953910193999945, 35.257411933000071],
            [-80.954132375999961, 35.257678896000073],
            [-80.95436175399999, 35.257941732000063],
            [-80.954598216999955, 35.258200310000063],
            [-80.954841645999977, 35.258454502000063],
            [-80.955091920999962, 35.258704182000031],
            [-80.955348916999981, 35.258949227000073],
            [-80.955612504999976, 35.259189513000024],
            [-80.955882557999985, 35.259424924000029],
            [-80.956158938999977, 35.259655339000062],
            [-80.956441511999969, 35.259880647000045],
            [-80.956730135999976, 35.260100734000048],
            [-80.957024667999974, 35.260315492000075],
            [-80.957324960999983, 35.260524814000064],
            [-80.957630867999967, 35.260728595000046],
            [-80.957942234999962, 35.260926736000044],
            [-80.958258907999948, 35.261119137000037],
            [-80.958580731999973, 35.261305702000072],
            [-80.958907542999953, 35.261486339000044],
            [-80.959239182999966, 35.261660959000039],
            [-80.95957548399997, 35.26182947500007],
            [-80.959916279999959, 35.261991803000058],
            [-80.960261402999947, 35.262147862000063],
            [-80.960610679999945, 35.262297574000058],
            [-80.960963937999963, 35.262440866000077],
            [-80.961321002999966, 35.262577666000027],
            [-80.961681694999982, 35.262707908000039],
            [-80.962045837999938, 35.262831524000035],
            [-80.962413248999951, 35.262948456000061],
            [-80.962783744999967, 35.263058643000079],
            [-80.963157143999979, 35.263162032000025],
            [-80.963533258999973, 35.263258570000062],
            [-80.963911905999964, 35.263348212000039],
            [-80.964292892999936, 35.263430911000057],
            [-80.964676031999943, 35.263506626000037],
            [-80.965061133999939, 35.263575321000076],
            [-80.965448006999964, 35.263636959000053],
            [-80.965836458999945, 35.263691513000026],
            [-80.966226296999935, 35.263738954000075],
            [-80.966617326999938, 35.263779259000046],
            [-80.967009354999959, 35.263812406000056],
            [-80.967402185999958, 35.263838382000074],
            [-80.967795626999987, 35.263857171000041],
            [-80.968189478999989, 35.263868765000041],
            [-80.968583548999959, 35.263873158000024],
            [-80.968977640999981, 35.263870349000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 64,
        "SolutionID": "PI-24:B",
        "Shape_Length": 0.10138177064812892,
        "Shape_Area": 0.00080598167560924888
      }
    },
    {
      "type": "Feature",
      "id": 65,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.847901108999963, 35.248791792000077],
            [-80.849658050999949, 35.245883333000052],
            [-80.844529676999969, 35.243634053000051],
            [-80.842725248999955, 35.246775272000036],
            [-80.847901108999963, 35.248791792000077]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 65,
        "SolutionID": "PI-26:C",
        "Shape_Length": 0.018175296087929869,
        "Shape_Area": 1.9382347470710674e-5
      }
    },
    {
      "type": "Feature",
      "id": 66,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.850132900999938, 35.244409674000053],
            [-80.855736123999975, 35.239212873000042],
            [-80.852269722999949, 35.236420425000063],
            [-80.845906739999975, 35.241617405000056],
            [-80.850132900999938, 35.244409674000053]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 66,
        "SolutionID": "PI-26:A",
        "Shape_Length": 0.025374332342091167,
        "Shape_Area": 3.6695669707580035e-5
      }
    },
    {
      "type": "Feature",
      "id": 67,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.853599300999974, 35.221331784000029],
            [-80.850085413999977, 35.218228373000045],
            [-80.846998892999977, 35.220284396000068],
            [-80.850892658999953, 35.223387728000034],
            [-80.853599300999974, 35.221331784000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 67,
        "SolutionID": "PI-26:B",
        "Shape_Length": 0.016774855561971024,
        "Shape_Area": 1.6604174645375607e-5
      }
    },
    {
      "type": "Feature",
      "id": 68,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.798966244999974, 35.51901394500004],
            [-80.798873244999982, 35.519433946000049],
            [-80.798840243999962, 35.519757946000027],
            [-80.798706244999948, 35.520554946000061],
            [-80.799703244999989, 35.520463946000064],
            [-80.801175244999968, 35.520336945000054],
            [-80.80181924599998, 35.520294945000046],
            [-80.805995245999952, 35.520067945000051],
            [-80.807027245999961, 35.519986945000028],
            [-80.80709124699996, 35.519975945000056],
            [-80.807421246999979, 35.519920945000024],
            [-80.807763247999958, 35.519737945000031],
            [-80.808489246999955, 35.519147945000043],
            [-80.808547247999968, 35.519100945000048],
            [-80.80885524699994, 35.518818945000078],
            [-80.809165247999942, 35.518623945000058],
            [-80.809460247999937, 35.518486944000074],
            [-80.809869247999984, 35.518408945000033],
            [-80.810281248999956, 35.51842894400005],
            [-80.810767247999934, 35.518520944000045],
            [-80.811667248999981, 35.518763945000046],
            [-80.814003248999938, 35.519420945000036],
            [-80.814495249999936, 35.519500944000072],
            [-80.814795248999985, 35.519507945000043],
            [-80.815172249999989, 35.519436944000063],
            [-80.815685249999945, 35.519295945000067],
            [-80.816783249999958, 35.518993944000044],
            [-80.817023249999977, 35.51892794500003],
            [-80.81785924999997, 35.518686944000024],
            [-80.818481249999934, 35.518507944000078],
            [-80.818760250999958, 35.518427944000052],
            [-80.819602250999935, 35.518216944000073],
            [-80.821766250999985, 35.517663944000049],
            [-80.822549252999977, 35.517477944000063],
            [-80.824548251999943, 35.517015944000036],
            [-80.825735251999959, 35.516741944000046],
            [-80.825831251999944, 35.516719944000045],
            [-80.826136252999959, 35.51663594300004],
            [-80.82636125199997, 35.516606944000046],
            [-80.826530251999941, 35.516599944000063],
            [-80.826908252999942, 35.516584944000044],
            [-80.827313252999943, 35.516644944000063],
            [-80.827880252999989, 35.516794944000026],
            [-80.828545252999959, 35.516955944000074],
            [-80.829136253999934, 35.517071944000065],
            [-80.82962925399994, 35.517136943000025],
            [-80.829984253999953, 35.517164944000058],
            [-80.831104253999968, 35.517209943000069],
            [-80.833114253999952, 35.517290943000035],
            [-80.833574253999984, 35.517308944000035],
            [-80.83406325499999, 35.517326944000047],
            [-80.834395254999947, 35.51733794300003],
            [-80.836026255999968, 35.517396943000051],
            [-80.836074254999971, 35.517398943000046],
            [-80.836785255999985, 35.517424944000027],
            [-80.837034256999971, 35.517380944000024],
            [-80.83729025599996, 35.517304944000045],
            [-80.837545256999988, 35.517168944000048],
            [-80.837822255999981, 35.516953943000033],
            [-80.838061256999936, 35.516449944000044],
            [-80.838135255999987, 35.516318943000044],
            [-80.838254255999971, 35.516110943000058],
            [-80.838415255999962, 35.515976943000055],
            [-80.838628255999936, 35.515874943000028],
            [-80.838996255999973, 35.515769942000077],
            [-80.839510255999983, 35.515642943000046],
            [-80.838939255999946, 35.51412494300007],
            [-80.838588256999969, 35.513191943000038],
            [-80.838450255999987, 35.512697942000045],
            [-80.838407254999936, 35.512475942000037],
            [-80.838368255999967, 35.512195942000062],
            [-80.838377255999944, 35.511765942000068],
            [-80.838417255999957, 35.511541942000065],
            [-80.83845325599998, 35.511340942000061],
            [-80.838595256999952, 35.510926942000026],
            [-80.838801255999954, 35.51053094100007],
            [-80.839047255999958, 35.510139941000034],
            [-80.839295255999957, 35.509867941000039],
            [-80.839820256999985, 35.509906941000054],
            [-80.840497255999935, 35.509956942000031],
            [-80.841372256999989, 35.51002094100005],
            [-80.845313257999976, 35.510307942000054],
            [-80.845850258999974, 35.510346942000069],
            [-80.852927260999934, 35.510861942000076],
            [-80.855593261999957, 35.511055941000052],
            [-80.855796261999956, 35.511070941000071],
            [-80.859166261999974, 35.511315941000078],
            [-80.860223261999977, 35.511392941000054],
            [-80.861301261999984, 35.511481941000056],
            [-80.861958263999952, 35.51153594200008],
            [-80.862105262999989, 35.51154794100006],
            [-80.865199263999955, 35.511773941000058],
            [-80.865529263999974, 35.511796941000057],
            [-80.865653263999945, 35.511805942000024],
            [-80.865885263999985, 35.511822941000048],
            [-80.866040263999935, 35.511834941000075],
            [-80.874385267999969, 35.512475940000058],
            [-80.874799266999958, 35.512507941000024],
            [-80.890049270999953, 35.513657941000076],
            [-80.890675271999953, 35.513703941000074],
            [-80.906065276999982, 35.514832941000066],
            [-80.909828276999974, 35.515109940000059],
            [-80.91006927799998, 35.514932940000051],
            [-80.910322277999967, 35.514745940000068],
            [-80.91821127999998, 35.508934939000028],
            [-80.930149281999945, 35.500141937000024],
            [-80.936342284999967, 35.49557993600007],
            [-80.940500284999985, 35.492517935000023],
            [-80.947033286999954, 35.487705933000029],
            [-80.946855286999948, 35.487238932000025],
            [-80.946727286999987, 35.486855933000072],
            [-80.946686286999977, 35.486730933000047],
            [-80.946508286999972, 35.486263932000043],
            [-80.946316286999945, 35.485984933000054],
            [-80.946191286999976, 35.485802933000059],
            [-80.945935286999941, 35.485385933000032],
            [-80.94582328599995, 35.48521093200003],
            [-80.94581128599998, 35.485192932000075],
            [-80.94580328699999, 35.485180932000048],
            [-80.945789286999968, 35.485158933000037],
            [-80.945658286999958, 35.484955933000037],
            [-80.945618285999956, 35.484891932000039],
            [-80.945589285999972, 35.484846932000039],
            [-80.945401286999981, 35.484558933000073],
            [-80.945362285999977, 35.484499932000062],
            [-80.945208286999957, 35.484193933000029],
            [-80.945204285999978, 35.484186932000057],
            [-80.945199285999934, 35.484175932000028],
            [-80.945184285999972, 35.484143932000052],
            [-80.945192286999941, 35.484077932000048],
            [-80.945214285999953, 35.483908932000077],
            [-80.945237285999951, 35.48383593300008],
            [-80.945368286999951, 35.483419931000071],
            [-80.945402286999979, 35.483381932000043],
            [-80.945769286999962, 35.482967932000065],
            [-80.946210285999939, 35.482554933000074],
            [-80.946340285999952, 35.482407932000058],
            [-80.946407285999953, 35.482331931000033],
            [-80.946582285999966, 35.482134931000076],
            [-80.94691428699997, 35.481738932000042],
            [-80.947296286999972, 35.481407931000035],
            [-80.947639286999959, 35.480955932000029],
            [-80.947787287999972, 35.480790931000058],
            [-80.947864286999959, 35.480704932000037],
            [-80.947880287999965, 35.48066693100003],
            [-80.947884287999955, 35.480657931000053],
            [-80.948068286999955, 35.480228931000056],
            [-80.948146286999986, 35.48005093200004],
            [-80.948204286999953, 35.479800932000046],
            [-80.948222286999965, 35.479139932000066],
            [-80.94825028799994, 35.478720931000055],
            [-80.948357286999965, 35.478333931000066],
            [-80.948434285999951, 35.478123931000027],
            [-80.948551286999987, 35.477727931000061],
            [-80.948648286999969, 35.477268931000026],
            [-80.948793286999944, 35.476630930000056],
            [-80.949075286999971, 35.475936930000046],
            [-80.949289286999942, 35.475299931000052],
            [-80.949582287999988, 35.474726930000031],
            [-80.949874286999943, 35.474080930000071],
            [-80.950109286999975, 35.473780930000032],
            [-80.950285287999975, 35.473555930000032],
            [-80.950493286999972, 35.473096930000054],
            [-80.95065528799995, 35.472740930000043],
            [-80.950918287999968, 35.472078930000066],
            [-80.951179286999945, 35.471523929000057],
            [-80.951211287999968, 35.471456930000045],
            [-80.95142528699995, 35.470899929000041],
            [-80.951629287999936, 35.470222929000045],
            [-80.951735286999963, 35.46961692900004],
            [-80.951733286999968, 35.469044928000073],
            [-80.951750286999982, 35.46845692900007],
            [-80.951670287999946, 35.468020929000033],
            [-80.951600286999962, 35.467617929000028],
            [-80.951500286999988, 35.467045928000061],
            [-80.95133128699996, 35.466537929000026],
            [-80.951313286999948, 35.466435928000067],
            [-80.951303286999973, 35.466380929000024],
            [-80.951251286999934, 35.466094928000075],
            [-80.951024287999985, 35.465700929000036],
            [-80.950726286999952, 35.465391929000077],
            [-80.950521286999958, 35.46503192800003],
            [-80.950176286999977, 35.464750928000058],
            [-80.949861286999976, 35.464452928000071],
            [-80.949762285999952, 35.464375928000038],
            [-80.949660286999972, 35.46429592800007],
            [-80.949520286999984, 35.464186928000061],
            [-80.949398285999962, 35.464091927000027],
            [-80.94905328699997, 35.463849928000059],
            [-80.948600285999987, 35.463472928000044],
            [-80.948148285999935, 35.463175927000066],
            [-80.948098285999947, 35.463142928000025],
            [-80.947537284999953, 35.462740928000073],
            [-80.947327285999961, 35.462593928000047],
            [-80.947320285999979, 35.46258892700007],
            [-80.946917284999984, 35.462306927000043],
            [-80.946596284999941, 35.462091927000074],
            [-80.946590285999946, 35.462087927000027],
            [-80.946585284999969, 35.462084927000035],
            [-80.946561284999973, 35.462068927000075],
            [-80.946551284999941, 35.462061928000026],
            [-80.946541285999956, 35.462054927000054],
            [-80.946224284999971, 35.461842927000077],
            [-80.946149285999979, 35.461792927000033],
            [-80.94572528499998, 35.461373928000057],
            [-80.945418285999949, 35.461127927000064],
            [-80.945184284999982, 35.460939928000073],
            [-80.944780284999979, 35.460642927000038],
            [-80.944396284999982, 35.460352927000031],
            [-80.944012284999985, 35.460144928000034],
            [-80.943746283999985, 35.459870927000054],
            [-80.943608284999982, 35.459753927000065],
            [-80.943598283999961, 35.459744927000031],
            [-80.943591284999968, 35.459738927000046],
            [-80.943539283999939, 35.459694928000033],
            [-80.943523284999969, 35.459680927000079],
            [-80.943515284999989, 35.459674927000037],
            [-80.943490284999939, 35.459653927000033],
            [-80.943466283999953, 35.45962492700005],
            [-80.94345928499996, 35.45961692700007],
            [-80.943450284999983, 35.459606927000038],
            [-80.943254283999977, 35.459379927000043],
            [-80.943036284999948, 35.45905792800005],
            [-80.943010284999957, 35.458990927000059],
            [-80.942868283999985, 35.458630926000069],
            [-80.942719284999953, 35.458211927000036],
            [-80.942598284999974, 35.457959927000047],
            [-80.942492283999968, 35.457734927000047],
            [-80.942490283999973, 35.457715927000038],
            [-80.942488283999978, 35.457693926000047],
            [-80.942488283999978, 35.457687927000052],
            [-80.942487283999981, 35.457675927000025],
            [-80.942420284999969, 35.456826927000066],
            [-80.942335283999967, 35.456739926000068],
            [-80.942332283999974, 35.456736927000065],
            [-80.942302283999936, 35.45670592700003],
            [-80.942300283999941, 35.456672927000056],
            [-80.942299283999944, 35.456598927000073],
            [-80.942291282999975, 35.456317926000054],
            [-80.942337282999972, 35.45556892500008],
            [-80.942400283999973, 35.455331926000042],
            [-80.94247328299997, 35.455050926000069],
            [-80.94251028299999, 35.454389926000033],
            [-80.942616283999939, 35.45383492600007],
            [-80.94274328399996, 35.453423925000038],
            [-80.943016283999953, 35.453003925000075],
            [-80.943350283999962, 35.45263092600004],
            [-80.94376028399995, 35.452267926000047],
            [-80.944191284999988, 35.451888925000048],
            [-80.944661283999949, 35.45162892500008],
            [-80.944954284999937, 35.451265926000076],
            [-80.945228283999938, 35.450885926000069],
            [-80.945237283999973, 35.450871925000058],
            [-80.945240284999954, 35.450866925000071],
            [-80.945246284999939, 35.450857925000037],
            [-80.945391283999982, 35.450636925000026],
            [-80.945595284999968, 35.450325925000072],
            [-80.94559828499996, 35.450320926000074],
            [-80.945619283999974, 35.450288925000052],
            [-80.94563128499999, 35.450273925000033],
            [-80.94563928499997, 35.450263925000058],
            [-80.945647283999961, 35.450254925000024],
            [-80.945654283999943, 35.450246925000044],
            [-80.94588328399999, 35.449973924000062],
            [-80.945906284999978, 35.449954925000043],
            [-80.946177284999976, 35.44973092500004],
            [-80.946412283999962, 35.449560926000061],
            [-80.946598284999936, 35.449455925000052],
            [-80.947269284999948, 35.449270925000064],
            [-80.947275284999989, 35.449268925000069],
            [-80.947297284999934, 35.449262925000028],
            [-80.947334284999954, 35.449252925000053],
            [-80.947380285999941, 35.449236925000037],
            [-80.947893284999964, 35.449057924000044],
            [-80.948451285999965, 35.44875892500005],
            [-80.949040284999967, 35.448498925000024],
            [-80.949568284999941, 35.448181925000029],
            [-80.949647285999959, 35.448134925000033],
            [-80.950186286999951, 35.447665925000024],
            [-80.950586284999986, 35.447108924000077],
            [-80.950977285999954, 35.44646292300007],
            [-80.951396286999966, 35.445743924000055],
            [-80.95169928699994, 35.44528392400008],
            [-80.951953285999934, 35.444774924000058],
            [-80.952216286999942, 35.444266923000043],
            [-80.952509285999952, 35.443652923000059],
            [-80.952713285999948, 35.443152923000071],
            [-80.95282928599994, 35.442692923000038],
            [-80.952892285999951, 35.442423923000035],
            [-80.952926285999979, 35.442281923000053],
            [-80.952991286999975, 35.441677923000043],
            [-80.952992286999972, 35.441664922000029],
            [-80.95299328699997, 35.441659923000032],
            [-80.953002285999958, 35.441579923000063],
            [-80.953070285999956, 35.44128192200003],
            [-80.95321028699999, 35.441080922000026],
            [-80.953441285999986, 35.44074792300006],
            [-80.953793286999939, 35.440247922000026],
            [-80.954458287999955, 35.439552923000065],
            [-80.955221285999983, 35.438889922000044],
            [-80.956033287999958, 35.438081922000038],
            [-80.956658287999971, 35.437079922000066],
            [-80.956951287999971, 35.436474922000059],
            [-80.957084287999976, 35.436209922000046],
            [-80.957272286999967, 35.435836922000078],
            [-80.957409286999962, 35.435633922000079],
            [-80.957641286999944, 35.435292921000041],
            [-80.95775828699999, 35.435015921000058],
            [-80.957875286999979, 35.434738922000065],
            [-80.957994286999963, 35.434456922000038],
            [-80.958306286999971, 35.433802922000041],
            [-80.958471287999942, 35.433318920000033],
            [-80.958694287999947, 35.432640921000029],
            [-80.958702287999984, 35.432140921000041],
            [-80.958684286999983, 35.432013921000078],
            [-80.958639286999983, 35.431703920000075],
            [-80.958493287999943, 35.431270920000031],
            [-80.958177286999955, 35.430625920000068],
            [-80.957732286999942, 35.429933921000043],
            [-80.95724828699997, 35.429128920000039],
            [-80.957071286999962, 35.428785920000053],
            [-80.956985285999963, 35.428616919000035],
            [-80.956962286999953, 35.428572921000068],
            [-80.956942285999958, 35.428532920000066],
            [-80.95672428599994, 35.427839919000064],
            [-80.95667128599996, 35.427685919000055],
            [-80.956659286999979, 35.427651920000073],
            [-80.956653286999938, 35.42763492000006],
            [-80.956615286999977, 35.427525920000051],
            [-80.956612286999984, 35.427516920000073],
            [-80.956561285999953, 35.427376920000029],
            [-80.956559286999948, 35.427371919000052],
            [-80.956551285999979, 35.427349919000051],
            [-80.956549285999984, 35.427342920000058],
            [-80.956537285999957, 35.427310919000035],
            [-80.956529286999967, 35.427289920000078],
            [-80.956426286999942, 35.427009920000046],
            [-80.956287286999952, 35.426574920000064],
            [-80.956000285999949, 35.425776919000043],
            [-80.955850286999976, 35.425180920000059],
            [-80.955702285999962, 35.424440919000062],
            [-80.955551286999935, 35.423713919000079],
            [-80.955534285999988, 35.423625919000074],
            [-80.95552928699999, 35.423599919000026],
            [-80.955527285999949, 35.423588919000053],
            [-80.955525286999944, 35.423576919000027],
            [-80.955524285999957, 35.423570919000042],
            [-80.955522285999962, 35.423564919000057],
            [-80.955520285999967, 35.423553919000028],
            [-80.955472286999964, 35.423313919000066],
            [-80.955400285999985, 35.422900919000028],
            [-80.955399285999988, 35.422892919000049],
            [-80.955397284999947, 35.422881919000076],
            [-80.955351285999939, 35.422617918000071],
            [-80.955317286999957, 35.42234791900006],
            [-80.955315286999962, 35.422331919000044],
            [-80.955314285999975, 35.42232191800008],
            [-80.955313286999967, 35.422311919000038],
            [-80.95531228599998, 35.422301919000063],
            [-80.955311286999972, 35.422296919000075],
            [-80.95527928599995, 35.422052918000077],
            [-80.955278286999942, 35.422045918000038],
            [-80.955269285999975, 35.421971919000043],
            [-80.955211286999941, 35.421453919000044],
            [-80.955176285999983, 35.420577919000038],
            [-80.955273285999965, 35.420053919000054],
            [-80.955344284999967, 35.419803918000071],
            [-80.955428285999972, 35.419512918000066],
            [-80.955642285999943, 35.418923918000075],
            [-80.955993285999966, 35.418213918000049],
            [-80.956480285999987, 35.417373917000077],
            [-80.957086286999981, 35.416469917000029],
            [-80.957788286999971, 35.415209917000027],
            [-80.958383285999957, 35.41416891700004],
            [-80.958587285999954, 35.413635918000068],
            [-80.958772285999942, 35.413215917000059],
            [-80.958765286999949, 35.412120916000049],
            [-80.958726286999934, 35.411345916000073],
            [-80.95860728599996, 35.410781916000076],
            [-80.958496285999956, 35.410208916000045],
            [-80.958326285999988, 35.40946791600004],
            [-80.95816528499995, 35.408558916000061],
            [-80.957878285999982, 35.407662916000049],
            [-80.957559285999935, 35.406227916000034],
            [-80.95723028499998, 35.404873915000053],
            [-80.956804284999976, 35.403658915000051],
            [-80.95625128599994, 35.402619915000059],
            [-80.95575628499995, 35.401628914000071],
            [-80.955061284999942, 35.401648914000077],
            [-80.954810284999951, 35.401687914000036],
            [-80.954631283999959, 35.401585915000055],
            [-80.954474284999947, 35.401423914000077],
            [-80.954254283999944, 35.401288915000066],
            [-80.954171284999973, 35.401191914000037],
            [-80.954175284999963, 35.40108491400008],
            [-80.953332283999941, 35.401135915000054],
            [-80.953303284999947, 35.401172914000028],
            [-80.953420283999947, 35.40137491400003],
            [-80.953537283999935, 35.40155091400004],
            [-80.953533283999946, 35.401728914000046],
            [-80.953440284999942, 35.401861914000051],
            [-80.953305284999942, 35.401942915000063],
            [-80.953161283999975, 35.401938914000027],
            [-80.953045283999984, 35.401828914000077],
            [-80.95293828299998, 35.401704914000049],
            [-80.952800283999977, 35.401667914000029],
            [-80.952519283999948, 35.401672915000063],
            [-80.952114283999947, 35.401533914000026],
            [-80.951774282999963, 35.40140791500005],
            [-80.951604283999984, 35.401390915000036],
            [-80.951535282999942, 35.401302914000041],
            [-80.951111282999989, 35.401339914000062],
            [-80.950734283999964, 35.40141191500004],
            [-80.95126828399998, 35.402225914000041],
            [-80.951484283999946, 35.402547914000024],
            [-80.951823283999943, 35.403055914000049],
            [-80.952023283999949, 35.403670915000077],
            [-80.952001284999938, 35.404212915000073],
            [-80.953089283999986, 35.404194915000062],
            [-80.953223284999979, 35.403543914000068],
            [-80.953445284999987, 35.403199915000073],
            [-80.95375628499994, 35.403218915000025],
            [-80.953979283999956, 35.404156915000044],
            [-80.95464528499997, 35.405494915000077],
            [-80.955096284999968, 35.405954915000052],
            [-80.955489285999988, 35.40634491600008],
            [-80.95571128499995, 35.407086916000026],
            [-80.955578284999945, 35.407285915000045],
            [-80.955113284999982, 35.407120915000064],
            [-80.954602284999964, 35.406795915000032],
            [-80.954224284999952, 35.406777916000067],
            [-80.95442428399997, 35.407464916000038],
            [-80.954670284999963, 35.40829691600004],
            [-80.954603284999962, 35.409055916000057],
            [-80.954137283999955, 35.409073916000068],
            [-80.954096283999945, 35.409073916000068],
            [-80.954075284999988, 35.408987916000058],
            [-80.954071284999941, 35.408831916000054],
            [-80.954159284999946, 35.408540916000049],
            [-80.954232283999943, 35.408352917000059],
            [-80.954298284999936, 35.408131916000059],
            [-80.954300285999977, 35.408058916000073],
            [-80.954263284999968, 35.407939916000032],
            [-80.953938284999936, 35.407603916000028],
            [-80.953830284999981, 35.407411916000058],
            [-80.953706284999953, 35.407190915000058],
            [-80.953637283999967, 35.407075915000064],
            [-80.953499283999975, 35.406945916000041],
            [-80.953355284999986, 35.406916915000068],
            [-80.953213283999958, 35.40694991600003],
            [-80.953102283999954, 35.40705491600005],
            [-80.953049284999963, 35.407180916000073],
            [-80.953032284999949, 35.407280916000047],
            [-80.953016283999943, 35.407475916000067],
            [-80.952931283999987, 35.407672916000024],
            [-80.952679284999988, 35.40797791600005],
            [-80.952303283999981, 35.408361916000047],
            [-80.95194328499997, 35.408709915000031],
            [-80.951690282999948, 35.408963916000062],
            [-80.951630284999965, 35.409073916000068],
            [-80.951588284999957, 35.409153917000026],
            [-80.951576283999941, 35.409233916000062],
            [-80.951563283999974, 35.409430916000076],
            [-80.951548283999955, 35.40960291600004],
            [-80.951367283999957, 35.40958691600008],
            [-80.951186283999959, 35.409626916000036],
            [-80.950949282999943, 35.409640917000047],
            [-80.950620282999978, 35.409695916000032],
            [-80.950526283999977, 35.409751917000051],
            [-80.950292282999953, 35.409867917000042],
            [-80.950093282999944, 35.409965916000033],
            [-80.94993728299994, 35.410004916000048],
            [-80.949838282999963, 35.410025916000052],
            [-80.94964028399994, 35.410045916000058],
            [-80.949409282999966, 35.410087917000055],
            [-80.949298282999962, 35.410145917000079],
            [-80.949184282999965, 35.41023991600008],
            [-80.949113283999964, 35.410320917000035],
            [-80.949032282999951, 35.410526916000038],
            [-80.94895728299997, 35.410718916000064],
            [-80.948874283999942, 35.41075791600008],
            [-80.948686283999962, 35.410771917000034],
            [-80.948426282999947, 35.410767917000044],
            [-80.948324282999977, 35.410766917000046],
            [-80.94816528399997, 35.410737917000063],
            [-80.948081282999965, 35.41068991700007],
            [-80.94793628299999, 35.410666917000071],
            [-80.947740282999973, 35.410658916000045],
            [-80.947741282999971, 35.410670917000061],
            [-80.947734282999988, 35.410679917000039],
            [-80.947697281999979, 35.410726917000034],
            [-80.947668283999974, 35.410743916000058],
            [-80.947617282999943, 35.410774916000037],
            [-80.947533282999984, 35.410784917000058],
            [-80.947502282999949, 35.410779916000024],
            [-80.947414282999944, 35.410765917000049],
            [-80.947335282999973, 35.41075091700003],
            [-80.947311281999987, 35.410749917000032],
            [-80.946794282999974, 35.41157191700006],
            [-80.946705282999972, 35.411685917000057],
            [-80.946683281999981, 35.411708918000045],
            [-80.946662281999977, 35.411725918000059],
            [-80.946566282999981, 35.411797917000058],
            [-80.946410281999988, 35.41189891700003],
            [-80.945927281999957, 35.412185917000045],
            [-80.945454281999957, 35.412505918000079],
            [-80.945140281999954, 35.412728917000038],
            [-80.944968281999934, 35.412858917000051],
            [-80.94484128199997, 35.412966918000052],
            [-80.944735282999943, 35.413063918000034],
            [-80.944623281999952, 35.413179917000036],
            [-80.944546282999966, 35.413276917000076],
            [-80.944230281999978, 35.413761917000045],
            [-80.943943281999964, 35.414229918000046],
            [-80.943735281999977, 35.41452891800003],
            [-80.943666281999981, 35.414601917000027],
            [-80.943517281999959, 35.414722918000052],
            [-80.943431281999949, 35.414782918000071],
            [-80.942884281999966, 35.41510691700006],
            [-80.942619281999953, 35.415263918000051],
            [-80.94207628099997, 35.415528918000064],
            [-80.941616280999938, 35.415762918000041],
            [-80.94152128099995, 35.415806918000044],
            [-80.941355280999971, 35.415870917000063],
            [-80.941167280999935, 35.415926917000036],
            [-80.940585280999983, 35.416075918000047],
            [-80.940221281999982, 35.416149918000031],
            [-80.940136281999969, 35.416162918000055],
            [-80.939187280999988, 35.416298918000052],
            [-80.939048280999941, 35.416316918000064],
            [-80.937081279999973, 35.416578919000074],
            [-80.936860279999962, 35.416589918000057],
            [-80.93671427999999, 35.416585918000067],
            [-80.935620279999966, 35.41647991800005],
            [-80.935536279999951, 35.416459918000044],
            [-80.935464279999962, 35.416425918000073],
            [-80.935304279999968, 35.416317918000061],
            [-80.935120278999989, 35.416178918000071],
            [-80.934111279999968, 35.415419918000055],
            [-80.934045277999985, 35.415374918000055],
            [-80.933935278999968, 35.41532391800007],
            [-80.93388927899997, 35.415307918000053],
            [-80.933783279999943, 35.415283918000057],
            [-80.933682279999971, 35.415283918000057],
            [-80.933581278999952, 35.415300918000071],
            [-80.933449278999944, 35.415332918000047],
            [-80.933249278999938, 35.415365918000077],
            [-80.93306427999994, 35.415376918000049],
            [-80.932980278999935, 35.41537691700006],
            [-80.932811278999964, 35.415365918000077],
            [-80.932718278999971, 35.415351918000056],
            [-80.932623278999984, 35.415326918000062],
            [-80.932509278999987, 35.415286918000049],
            [-80.932406277999974, 35.415236918000062],
            [-80.932340278999959, 35.415194918000054],
            [-80.932258278999939, 35.415133918000038],
            [-80.932191278999937, 35.415073918000076],
            [-80.932046278999962, 35.41490391800005],
            [-80.931721277999941, 35.414463918000024],
            [-80.931645277999962, 35.414370918000031],
            [-80.931524278999973, 35.414248918000055],
            [-80.931305278999957, 35.414073918000042],
            [-80.930977277999943, 35.413839917000075],
            [-80.930727277999949, 35.413680917000079],
            [-80.930622277999987, 35.41360591800003],
            [-80.930576276999943, 35.413562917000036],
            [-80.930547277999949, 35.413527918000057],
            [-80.930511277999983, 35.413469918000033],
            [-80.930448277999972, 35.413320918000068],
            [-80.930342277999955, 35.41306591700004],
            [-80.930173277999984, 35.412732917000028],
            [-80.930091277999963, 35.412610918000041],
            [-80.930016278999972, 35.412520918000041],
            [-80.92992027799994, 35.412425918000054],
            [-80.929861277999976, 35.412374917000079],
            [-80.929675276999944, 35.41223691700003],
            [-80.929076277999968, 35.411832918000073],
            [-80.928851277999968, 35.411685918000046],
            [-80.928431276999959, 35.411426918000075],
            [-80.928348276999941, 35.411384918000067],
            [-80.927693276999946, 35.411085917000037],
            [-80.926041276999968, 35.410382917000049],
            [-80.925822276999952, 35.410306917000071],
            [-80.926424275999977, 35.408859917000029],
            [-80.926496275999966, 35.408634917000029],
            [-80.926520275999962, 35.408518916000048],
            [-80.926535275999981, 35.40841591700007],
            [-80.926541276999956, 35.408320917000026],
            [-80.926540276999958, 35.408219916000064],
            [-80.926523275999955, 35.408022917000039],
            [-80.926491276999968, 35.407850916000029],
            [-80.926451276999956, 35.407700916000067],
            [-80.926364276999948, 35.407439917000033],
            [-80.92463027499997, 35.402347917000043],
            [-80.92421727499999, 35.401157916000045],
            [-80.923466274999953, 35.398954915000047],
            [-80.923314275999985, 35.398530915000038],
            [-80.923168274999966, 35.398098914000059],
            [-80.923146274999965, 35.398009915000046],
            [-80.923105274999955, 35.397767915000031],
            [-80.923093274999985, 35.397559914000055],
            [-80.923101274999965, 35.397335915000042],
            [-80.923137274999988, 35.397060915000054],
            [-80.923173274999954, 35.396922915000061],
            [-80.92323027499998, 35.396767915000055],
            [-80.923505274999968, 35.396129914000028],
            [-80.923627275999934, 35.39585891400003],
            [-80.923777274999964, 35.395498914000029],
            [-80.923840274999975, 35.395319914000027],
            [-80.923873274999949, 35.39519791400005],
            [-80.923885274999975, 35.395113914000035],
            [-80.923889274999965, 35.39493591400003],
            [-80.92387927599998, 35.39478891400006],
            [-80.92384627499996, 35.394645913000033],
            [-80.923791274999985, 35.394474914000057],
            [-80.923729274999971, 35.394328915000074],
            [-80.923571273999983, 35.394009914000037],
            [-80.922897274999968, 35.392777914000078],
            [-80.922807274999968, 35.39261291400004],
            [-80.922661274999939, 35.39237191400008],
            [-80.922584273999973, 35.392267913000069],
            [-80.922412274999942, 35.392079914000078],
            [-80.921881273999986, 35.391559914000027],
            [-80.921401274999937, 35.391080913000053],
            [-80.921291273999941, 35.390955914000074],
            [-80.921181273999935, 35.39080791300006],
            [-80.921090274999983, 35.390655913000046],
            [-80.921007273999976, 35.390485914000067],
            [-80.920961273999978, 35.390360913000052],
            [-80.920926273999953, 35.39020791300004],
            [-80.920904272999962, 35.39002391300005],
            [-80.920915273999981, 35.389792913000065],
            [-80.920932274999984, 35.389668913000037],
            [-80.920944273999964, 35.389589914000055],
            [-80.92111427399999, 35.389629913000078],
            [-80.925391274999981, 35.390065912000068],
            [-80.925484274999974, 35.390069913000048],
            [-80.925806274999957, 35.390052913000034],
            [-80.925879274999943, 35.390044912000064],
            [-80.926000275999968, 35.39003191300003],
            [-80.926070274999972, 35.390013913000075],
            [-80.926130274999934, 35.390002913000046],
            [-80.926183274999971, 35.389987913000027],
            [-80.926249274999975, 35.389959913000041],
            [-80.926406275999966, 35.389881913000067],
            [-80.926568275999955, 35.389822913000046],
            [-80.926658275999955, 35.389783914000077],
            [-80.926838275999955, 35.389678913000068],
            [-80.926961275999986, 35.389591913000061],
            [-80.927069275999941, 35.389528913000049],
            [-80.927517275999946, 35.389208913000061],
            [-80.927688275999969, 35.389107912000043],
            [-80.927879275999942, 35.388962912000068],
            [-80.928083275999938, 35.388833912000052],
            [-80.928172275999941, 35.388788913000042],
            [-80.928295275999972, 35.388740913000049],
            [-80.928624275999937, 35.388560912000059],
            [-80.928884275999962, 35.388436913000078],
            [-80.929199275999963, 35.388188913000079],
            [-80.929568275999941, 35.387924913000063],
            [-80.929799276999972, 35.387828912000032],
            [-80.930117276999965, 35.387651912000024],
            [-80.930139276999967, 35.38762691200003],
            [-80.930392276999953, 35.387456913000051],
            [-80.930523276999963, 35.387432912000065],
            [-80.930647276999935, 35.387427913000067],
            [-80.93076027699999, 35.387441912000043],
            [-80.93092227599999, 35.387488912000038],
            [-80.931076276999988, 35.387567912000065],
            [-80.931127276999973, 35.387600912000039],
            [-80.931246276999957, 35.387679912000067],
            [-80.931393275999937, 35.387717912000028],
            [-80.931476276999945, 35.387746912000068],
            [-80.931571276999989, 35.387763912000025],
            [-80.931807276999962, 35.387756913000032],
            [-80.931999276999989, 35.387679912000067],
            [-80.932065277999982, 35.387636912000062],
            [-80.932132276999937, 35.387591912000062],
            [-80.932228276999979, 35.387540913000066],
            [-80.932346275999976, 35.387462912000046],
            [-80.932446275999951, 35.387390912000058],
            [-80.932649276999939, 35.387242912000033],
            [-80.932722276999982, 35.387179912000079],
            [-80.93277227699997, 35.387080912000044],
            [-80.932801276999953, 35.386997912000027],
            [-80.93281327699998, 35.38688391200003],
            [-80.932778276999954, 35.386763912000049],
            [-80.932759276999946, 35.386720913000033],
            [-80.932723277999969, 35.38668791300006],
            [-80.932663276999961, 35.386625913000046],
            [-80.932319277999966, 35.386352913000053],
            [-80.932271276999984, 35.386305912000068],
            [-80.932257276999962, 35.386268912000048],
            [-80.93222127699994, 35.386171912000066],
            [-80.932180276999986, 35.385913912000035],
            [-80.932189275999974, 35.385812912000063],
            [-80.932114276999982, 35.385621912000033],
            [-80.932067276999987, 35.385376912000027],
            [-80.93202927699997, 35.385030911000058],
            [-80.93202527699998, 35.384964912000044],
            [-80.932053276999966, 35.384911912000064],
            [-80.932244276999938, 35.384724911000035],
            [-80.932369275999974, 35.384613912000077],
            [-80.932443276999948, 35.384535911000057],
            [-80.932686276999959, 35.384137912000028],
            [-80.932842276999963, 35.383935912000027],
            [-80.933126276999985, 35.38353991200006],
            [-80.933245276999969, 35.383350912000026],
            [-80.933340276999957, 35.383212912000033],
            [-80.933614276999947, 35.382824912000046],
            [-80.93368727699999, 35.382719911000038],
            [-80.933829276999973, 35.382529912000052],
            [-80.933991276999961, 35.382435911000073],
            [-80.934130276999952, 35.382361911000032],
            [-80.934264277999944, 35.382316911000032],
            [-80.934416276999968, 35.382289911000044],
            [-80.934607277999987, 35.382204911000031],
            [-80.934726276999982, 35.382128911000052],
            [-80.934781277999946, 35.382096911000076],
            [-80.93483527799998, 35.382081911000057],
            [-80.934991277999984, 35.382045912000024],
            [-80.935100276999947, 35.382086911000044],
            [-80.935214276999943, 35.382185911000079],
            [-80.935404277999965, 35.382288911000046],
            [-80.935737278999966, 35.382460911000067],
            [-80.935915277999982, 35.382568911000078],
            [-80.936102277999964, 35.382666912000047],
            [-80.936164277999978, 35.382684911000069],
            [-80.936223277999943, 35.382678911000028],
            [-80.936339277999934, 35.382657911000024],
            [-80.936467277999952, 35.382588911000028],
            [-80.936576277999961, 35.382504911000069],
            [-80.936660277999977, 35.382340911000028],
            [-80.936801278999951, 35.382102911000061],
            [-80.936905278999973, 35.382033911000065],
            [-80.937056278999989, 35.381968912000048],
            [-80.937196277999988, 35.381933910000043],
            [-80.93761227799996, 35.381811911000057],
            [-80.938031277999983, 35.38166091100004],
            [-80.938509278999959, 35.381518911000057],
            [-80.938735278999957, 35.381442910000032],
            [-80.938899277999951, 35.381411911000043],
            [-80.939040278999983, 35.381428910000068],
            [-80.939463278999938, 35.381445910000025],
            [-80.93960427899998, 35.381443911000076],
            [-80.939961278999988, 35.381432911000047],
            [-80.940065278999953, 35.38137891100007],
            [-80.940124278999974, 35.381281911000031],
            [-80.94014827899997, 35.381045911000058],
            [-80.940109278999955, 35.380574910000064],
            [-80.940104279999957, 35.38051291000005],
            [-80.94000127999999, 35.380543911000075],
            [-80.939974278999955, 35.380431910000027],
            [-80.939928278999957, 35.380176910000046],
            [-80.939916278999988, 35.380086910000045],
            [-80.939918278999983, 35.379947910000055],
            [-80.93992327999996, 35.379890910000029],
            [-80.940014278999968, 35.379838911000036],
            [-80.940108278999958, 35.379786910000064],
            [-80.940192278999973, 35.379733910000027],
            [-80.940281278999976, 35.379683911000029],
            [-80.940358279999941, 35.379648910000071],
            [-80.940447278999955, 35.379619910000031],
            [-80.940655278999941, 35.379506910000032],
            [-80.940746278999939, 35.379453910000052],
            [-80.940861278999989, 35.379404910000062],
            [-80.940912278999974, 35.379387910000048],
            [-80.940996278999989, 35.379343910000046],
            [-80.941059278999944, 35.379280910000034],
            [-80.941119279999953, 35.379197911000063],
            [-80.941213278999953, 35.379037910000079],
            [-80.941222278999987, 35.378958910000051],
            [-80.941221279999979, 35.378894910000042],
            [-80.941178279999974, 35.378843910000057],
            [-80.941117279999958, 35.378790909000031],
            [-80.941035278999948, 35.378731909000066],
            [-80.940967278999949, 35.37867490900004],
            [-80.940889278999975, 35.378624910000042],
            [-80.940824279999958, 35.378569910000067],
            [-80.940812278999942, 35.378522910000072],
            [-80.940812278999942, 35.378474910000079],
            [-80.940806279999947, 35.378421909000053],
            [-80.940772278999987, 35.378358910000031],
            [-80.940763279999942, 35.37828791000004],
            [-80.940760279999949, 35.378208910000069],
            [-80.940744278999944, 35.378084910000041],
            [-80.940702279999982, 35.378044910000028],
            [-80.940768279999986, 35.378016910000042],
            [-80.940793278999934, 35.377998910000031],
            [-80.940828277999969, 35.377967910000052],
            [-80.94084627899997, 35.377920910000057],
            [-80.940896278999958, 35.377873910000062],
            [-80.94094027999995, 35.377853911000045],
            [-80.94106727999997, 35.377799910000078],
            [-80.941116278999971, 35.37776991000004],
            [-80.941202278999981, 35.377754910000078],
            [-80.941306278999946, 35.377753910000024],
            [-80.941445279999982, 35.377749910000034],
            [-80.941701279999961, 35.377735910000069],
            [-80.941821279999942, 35.37773990900007],
            [-80.941897279999978, 35.377727910000033],
            [-80.941943279999975, 35.377667910000071],
            [-80.942024279999941, 35.377628910000055],
            [-80.94209227999994, 35.377636911000025],
            [-80.942232278999938, 35.37770290900005],
            [-80.942303279999976, 35.377742910000052],
            [-80.942353278999974, 35.377747910000039],
            [-80.942457278999939, 35.377752910000027],
            [-80.942481279999981, 35.377760910000063],
            [-80.942559278999965, 35.377794910000034],
            [-80.942635279999934, 35.377807910000058],
            [-80.94270727899999, 35.377808910000056],
            [-80.942864279999981, 35.377799910000078],
            [-80.942974279999987, 35.377804910000066],
            [-80.943049279999968, 35.377784910000059],
            [-80.943159279999975, 35.37781891000003],
            [-80.943227278999984, 35.377849910000066],
            [-80.943301279999957, 35.377828910000062],
            [-80.94339027999996, 35.377810910000051],
            [-80.943487279999943, 35.377820910000025],
            [-80.943672280999976, 35.377854909000064],
            [-80.943774279999957, 35.377859910000041],
            [-80.943807279999987, 35.377856909000059],
            [-80.943882280999958, 35.377846910000073],
            [-80.943914279999944, 35.377831910000054],
            [-80.94395327999996, 35.377808910000056],
            [-80.943972279999969, 35.377798909000035],
            [-80.94405127999994, 35.377746910000042],
            [-80.94413327999996, 35.377714910000066],
            [-80.94418227999995, 35.377676910000048],
            [-80.944229279999945, 35.377544909000051],
            [-80.944195279999974, 35.377481910000029],
            [-80.944131279999965, 35.377389910000034],
            [-80.944145279999987, 35.377301910000028],
            [-80.944203279999954, 35.377205909000054],
            [-80.944271279999953, 35.377145910000024],
            [-80.944308279999973, 35.377096910000034],
            [-80.944332279999969, 35.37703091000003],
            [-80.94435428099996, 35.376990910000075],
            [-80.944390280999983, 35.376949910000064],
            [-80.944465279999974, 35.376917910000031],
            [-80.94454927999999, 35.376875910000024],
            [-80.944607279999957, 35.376822909000055],
            [-80.944671278999976, 35.376795910000055],
            [-80.944769278999956, 35.376772909000067],
            [-80.944859280999935, 35.376729909000062],
            [-80.944955279999988, 35.376671909000038],
            [-80.945024280999974, 35.376624910000032],
            [-80.945077280999953, 35.376565910000068],
            [-80.945123279999962, 35.37649490900003],
            [-80.945198279999943, 35.376414909000061],
            [-80.945261280999944, 35.376356909000037],
            [-80.945312280999985, 35.37629490900008],
            [-80.945323279999968, 35.376276909000069],
            [-80.945351280999944, 35.376207910000062],
            [-80.945382280999979, 35.376132910000024],
            [-80.945387280999967, 35.376047909000079],
            [-80.945379279999941, 35.375981910000064],
            [-80.945395280999946, 35.375909909000029],
            [-80.945447280999986, 35.375786910000045],
            [-80.945462280999948, 35.375733909000076],
            [-80.945459280999955, 35.375681909000036],
            [-80.945468280999989, 35.375610910000034],
            [-80.945507280999948, 35.375504910000075],
            [-80.94543927999996, 35.375431909000042],
            [-80.94538627999998, 35.375397909000071],
            [-80.945339279999985, 35.375359909000053],
            [-80.945300280999959, 35.375262909000071],
            [-80.945316279999986, 35.375199910000049],
            [-80.945471279999936, 35.375091909000048],
            [-80.945491279999942, 35.37503791000006],
            [-80.945510279999951, 35.374838909000061],
            [-80.945508279999956, 35.374770910000052],
            [-80.94539427999996, 35.374721909000073],
            [-80.94534127999998, 35.374740909000025],
            [-80.945276279999973, 35.374751910000043],
            [-80.945219279999947, 35.374756909000041],
            [-80.945067279999989, 35.374750909000056],
            [-80.944997279999939, 35.374708910000038],
            [-80.944980280999971, 35.374660909000056],
            [-80.944973279999942, 35.374576909000041],
            [-80.944978280999976, 35.374413909000054],
            [-80.944975279999937, 35.374299909000058],
            [-80.944987279999964, 35.374210909000055],
            [-80.945079279999959, 35.373952910000071],
            [-80.945104279999953, 35.373847909000062],
            [-80.945117280999966, 35.373771909000027],
            [-80.945109280999986, 35.373686909000071],
            [-80.945090279999988, 35.373614909000025],
            [-80.944964279999965, 35.373459909000076],
            [-80.944849279999971, 35.373384909000038],
            [-80.944792279999945, 35.373363909000034],
            [-80.944680280999989, 35.373352908000072],
            [-80.944604279999965, 35.373359909000044],
            [-80.944526279999934, 35.373358909000046],
            [-80.944430279999949, 35.373343909000027],
            [-80.944294279999951, 35.373304909000069],
            [-80.944116280999935, 35.373201908000055],
            [-80.944013279999979, 35.373189909000075],
            [-80.94385127999999, 35.373225909000041],
            [-80.943738279999934, 35.373241909000058],
            [-80.94372027999998, 35.373174908000067],
            [-80.943767279999975, 35.373071909000032],
            [-80.943780279999942, 35.373016909000057],
            [-80.943797279999956, 35.372863908000056],
            [-80.943799279999951, 35.372796909000044],
            [-80.943791279999971, 35.372740909000072],
            [-80.943758278999951, 35.372676909000063],
            [-80.943714279999938, 35.37261490800006],
            [-80.943682279999962, 35.372549909000043],
            [-80.943538279999984, 35.372390909000046],
            [-80.943459279999956, 35.372315908000076],
            [-80.943286279999938, 35.372217909000028],
            [-80.943168278999963, 35.372219908000034],
            [-80.943057279999948, 35.372151909000024],
            [-80.943019279999987, 35.372112908000076],
            [-80.943008278999969, 35.372053908000055],
            [-80.943049279999968, 35.372011908000047],
            [-80.943159278999985, 35.37194290900004],
            [-80.94325127999997, 35.371857909000028],
            [-80.943279279999956, 35.371813908000036],
            [-80.943293279999978, 35.371756908000066],
            [-80.943298278999976, 35.371673908000048],
            [-80.94325527899997, 35.371611908000034],
            [-80.94316927999995, 35.371584908000045],
            [-80.943058278999956, 35.371584908000045],
            [-80.942953279999983, 35.371608909000031],
            [-80.942867278999984, 35.371655908000037],
            [-80.942821278999986, 35.371725909000077],
            [-80.942761279999957, 35.371789908000039],
            [-80.942654279999942, 35.371855908000043],
            [-80.942523279999989, 35.371964908000052],
            [-80.94247927899994, 35.37200690800006],
            [-80.942440279999971, 35.372076909000043],
            [-80.942440278999982, 35.37213390900007],
            [-80.942420278999975, 35.372203909000064],
            [-80.94237327999997, 35.372238908000043],
            [-80.942272278999951, 35.372266909000075],
            [-80.942181279999943, 35.372247909000066],
            [-80.942061278999972, 35.372127909000028],
            [-80.941877279999972, 35.371971908000035],
            [-80.941659279999953, 35.371805909000045],
            [-80.941564278999977, 35.371749909000073],
            [-80.941437278999956, 35.371703909000075],
            [-80.94127827799997, 35.371636909000074],
            [-80.941177278999987, 35.371630909000032],
            [-80.941081278999945, 35.371647909000046],
            [-80.940978278999978, 35.371629909000035],
            [-80.940897277999966, 35.371579908000058],
            [-80.940735277999977, 35.371531909000055],
            [-80.940656277999949, 35.371538908000048],
            [-80.940511279999953, 35.371617909000065],
            [-80.940451277999955, 35.371629909000035],
            [-80.940369278999981, 35.371636908000028],
            [-80.940260278999972, 35.37163590800003],
            [-80.940199278999955, 35.371648909000044],
            [-80.940121278999982, 35.371667908000063],
            [-80.939962278999985, 35.371702908000032],
            [-80.939903278999964, 35.371696909000036],
            [-80.939831278999975, 35.371701908000034],
            [-80.939763277999987, 35.371679908000033],
            [-80.939651278999975, 35.371589909000079],
            [-80.939616278999949, 35.371542909000027],
            [-80.939543277999974, 35.371501908000027],
            [-80.939460277999956, 35.37148890900005],
            [-80.939376277999941, 35.371497908000038],
            [-80.939276278999955, 35.37152990900006],
            [-80.939167277999957, 35.371547909000071],
            [-80.939065278999976, 35.371540908000043],
            [-80.938912278999965, 35.37149690800004],
            [-80.938803277999966, 35.37149690800004],
            [-80.938707277999981, 35.37152590900007],
            [-80.938655277999942, 35.37154590800003],
            [-80.938575278999963, 35.371525908000024],
            [-80.938473277999947, 35.371452909000027],
            [-80.93835927799995, 35.371273908000035],
            [-80.938278277999984, 35.371230909000076],
            [-80.938193277999972, 35.371168908000072],
            [-80.938150277999966, 35.371129908000057],
            [-80.938105277999966, 35.371099909000066],
            [-80.938019277999956, 35.371066909000035],
            [-80.937891276999949, 35.371028908000028],
            [-80.937711276999948, 35.370956909000029],
            [-80.937690276999945, 35.370898908000072],
            [-80.937791276999974, 35.370828909000068],
            [-80.93789327799999, 35.370795908000048],
            [-80.938211278999972, 35.370713909000074],
            [-80.93835927799995, 35.370711909000079],
            [-80.939408277999974, 35.370045908000066],
            [-80.939447277999989, 35.369959908000055],
            [-80.939463277999948, 35.36990890900006],
            [-80.939451278999968, 35.369833909000079],
            [-80.939415277999956, 35.369770909000067],
            [-80.939375277999943, 35.369709908000061],
            [-80.939089278999973, 35.369506908000062],
            [-80.938970277999942, 35.369385908000027],
            [-80.938924277999945, 35.369313907000048],
            [-80.938851277999959, 35.369117908000078],
            [-80.938844277999976, 35.369062908000046],
            [-80.938778277999972, 35.368987908000065],
            [-80.939062277999938, 35.368288908000068],
            [-80.939097277999963, 35.368247908000058],
            [-80.939160277999974, 35.36819390800008],
            [-80.939266277999934, 35.368137908000051],
            [-80.939393277999955, 35.368089908000059],
            [-80.939472277999982, 35.368035908000024],
            [-80.939497277999976, 35.367974908000065],
            [-80.939491277999934, 35.367910907000066],
            [-80.939411277999966, 35.36783790700008],
            [-80.939286278999987, 35.367772907000074],
            [-80.938902276999954, 35.367616908000059],
            [-80.938721277999946, 35.367535908000036],
            [-80.938401277999958, 35.367365907000078],
            [-80.938334277999957, 35.36731590800008],
            [-80.938249277999944, 35.367170908000048],
            [-80.938083277999965, 35.367020908000029],
            [-80.937960277999935, 35.366937907000079],
            [-80.937657276999971, 35.366777907000028],
            [-80.937550277999947, 35.366740907000064],
            [-80.937482276999958, 35.366731908000077],
            [-80.937428276999981, 35.366714908000063],
            [-80.937223276999987, 35.366612907000047],
            [-80.936862276999989, 35.366450908000047],
            [-80.936703277999982, 35.36640890700005],
            [-80.936567276999938, 35.366360908000047],
            [-80.936523276999935, 35.366327908000073],
            [-80.936449277999941, 35.366297907000046],
            [-80.93637027699998, 35.366290907000064],
            [-80.936187276999988, 35.36622090700007],
            [-80.936107277999952, 35.366179908000049],
            [-80.936005277999982, 35.366127908000067],
            [-80.935854277999965, 35.366085908000059],
            [-80.935776277999935, 35.366058907000024],
            [-80.935448276999978, 35.36593190800005],
            [-80.935318276999965, 35.365886907000061],
            [-80.935251276999963, 35.365858908000064],
            [-80.935063275999937, 35.365765907000025],
            [-80.935004275999972, 35.365729907000059],
            [-80.934888275999981, 35.365683908000051],
            [-80.934726276999982, 35.365591908000056],
            [-80.934676275999948, 35.365550907000056],
            [-80.934568275999936, 35.365480907000062],
            [-80.934379275999959, 35.365401907000034],
            [-80.934314276999942, 35.365378907000036],
            [-80.934180276999939, 35.365315907000024],
            [-80.934106275999966, 35.36528690800003],
            [-80.934057276999965, 35.365260907000049],
            [-80.933999276999941, 35.36524590700003],
            [-80.933901276999961, 35.365231907000066],
            [-80.933843276999937, 35.365212907000057],
            [-80.933689276999985, 35.365133908000075],
            [-80.933456275999959, 35.364997907000031],
            [-80.933346275999952, 35.364923908000037],
            [-80.933155275999979, 35.364827907000063],
            [-80.933036275999939, 35.364789907000045],
            [-80.932864275999975, 35.364755907000074],
            [-80.932736274999968, 35.36474290700005],
            [-80.93254727499999, 35.364733907000073],
            [-80.932445275999953, 35.36473490700007],
            [-80.932345274999989, 35.364742908000039],
            [-80.932143275999977, 35.364768907000041],
            [-80.932050276999973, 35.364795908000076],
            [-80.932097275999979, 35.364854907000051],
            [-80.932231275999982, 35.364961908000055],
            [-80.93228927499996, 35.365002907000076],
            [-80.932314275999943, 35.365067907000025],
            [-80.932348275999971, 35.365137908000065],
            [-80.932384274999947, 35.365177908000078],
            [-80.93246127599997, 35.365210907000062],
            [-80.932537275999948, 35.36524890700008],
            [-80.93264127599997, 35.365331907000041],
            [-80.932699275999937, 35.36539990700004],
            [-80.93278827599994, 35.36552290700007],
            [-80.932814275999988, 35.365685907000056],
            [-80.932801275999964, 35.365733907000049],
            [-80.93277627599997, 35.365799908000042],
            [-80.932762276999938, 35.365852908000079],
            [-80.932752276999963, 35.365924908000068],
            [-80.93269427599995, 35.366073908000033],
            [-80.932630275999941, 35.366150908000066],
            [-80.932584275999943, 35.366229908000037],
            [-80.932580274999964, 35.366283908000071],
            [-80.932583275999946, 35.366379908000056],
            [-80.932584275999943, 35.366443908000065],
            [-80.93260027599996, 35.366499908000037],
            [-80.932625275999953, 35.36654390700005],
            [-80.932653276999986, 35.366623908000065],
            [-80.932673276999935, 35.366720907000058],
            [-80.932667275999961, 35.366794908000031],
            [-80.932627276999938, 35.367005908000067],
            [-80.93258827599999, 35.367104908000044],
            [-80.93253627699994, 35.367154908000032],
            [-80.93250627599997, 35.367223908000028],
            [-80.932440275999966, 35.367416908000052],
            [-80.932302275999973, 35.367851908000034],
            [-80.932299275999981, 35.367974908000065],
            [-80.932327275999967, 35.368057908000026],
            [-80.932387275999986, 35.368182908000051],
            [-80.932496276999984, 35.368497908000052],
            [-80.932561275999944, 35.36864790900006],
            [-80.93265327599994, 35.368916908000074],
            [-80.932707276999963, 35.369054909000056],
            [-80.932738275999952, 35.369158908000031],
            [-80.932747275999986, 35.369310909000035],
            [-80.932778275999965, 35.369376908000049],
            [-80.932849275999956, 35.369496908000031],
            [-80.932885276999968, 35.369577908000053],
            [-80.932921275999945, 35.369625908000046],
            [-80.93305027599996, 35.369752908000066],
            [-80.933142275999955, 35.369765908000034],
            [-80.933238276999987, 35.36974490800003],
            [-80.933354276999978, 35.369739909000032],
            [-80.933423275999985, 35.369771909000065],
            [-80.933501276999948, 35.369910908000065],
            [-80.933502275999956, 35.369981908000057],
            [-80.933523276999949, 35.37005190900004],
            [-80.93351927599997, 35.370108909000066],
            [-80.933479275999957, 35.370160909000049],
            [-80.933429276999959, 35.370209908000049],
            [-80.933408275999966, 35.370271908000063],
            [-80.933377276999977, 35.370319909000045],
            [-80.933364276999953, 35.370364909000045],
            [-80.93336527699995, 35.370421909000072],
            [-80.933318276999955, 35.370488908000027],
            [-80.933233275999953, 35.370503908000046],
            [-80.933120276999944, 35.37056990800005],
            [-80.933063276999974, 35.370556908000026],
            [-80.932935275999967, 35.370592909000038],
            [-80.932855275999941, 35.370626909000066],
            [-80.932744275999937, 35.370718909000061],
            [-80.932677275999936, 35.370813909000049],
            [-80.932601275999957, 35.370900909000056],
            [-80.932541275999938, 35.370976908000046],
            [-80.932440276999955, 35.371055909000063],
            [-80.932469275999949, 35.370924909000053],
            [-80.932550276999962, 35.370687908000036],
            [-80.932576276999953, 35.370510908000028],
            [-80.932607275999942, 35.370438909000029],
            [-80.932558275999952, 35.370398909000073],
            [-80.932439276999958, 35.370365909000043],
            [-80.932277275999979, 35.370279908000043],
            [-80.932223276999935, 35.370240908000028],
            [-80.932148276999953, 35.370220909000068],
            [-80.932065276999936, 35.370203909000054],
            [-80.93200027599994, 35.370149909000077],
            [-80.931847275999985, 35.370103909000079],
            [-80.931793275999951, 35.370036908000031],
            [-80.931708275999938, 35.369951909000065],
            [-80.931663275999938, 35.369917909000037],
            [-80.93157227599994, 35.369882909000069],
            [-80.931423275999975, 35.369794909000063],
            [-80.931389274999958, 35.369748908000076],
            [-80.931370274999949, 35.369669908000049],
            [-80.931354275999979, 35.369566908000024],
            [-80.93125727599994, 35.369499909000069],
            [-80.931232276999935, 35.369420908000052],
            [-80.931240274999936, 35.369293908000031],
            [-80.93111827499996, 35.369231909000064],
            [-80.930790275999982, 35.369038909000039],
            [-80.930710274999967, 35.369002908000027],
            [-80.93060427599994, 35.368994908000047],
            [-80.930430275999981, 35.369007908000071],
            [-80.930369275999965, 35.369019908000041],
            [-80.930297274999987, 35.369010909000053],
            [-80.930222274999949, 35.368994909000037],
            [-80.930027274999986, 35.368893908000075],
            [-80.929922274999967, 35.36886690800003],
            [-80.929683275999935, 35.368886908000036],
            [-80.929602274999979, 35.368921908000061],
            [-80.92949327599996, 35.368959909000068],
            [-80.929373274999989, 35.368991909000044],
            [-80.929312274999972, 35.369021908000036],
            [-80.929236275999983, 35.369052908000072],
            [-80.929160274999958, 35.369068908000031],
            [-80.928963275999934, 35.369085909000034],
            [-80.928887274999965, 35.36908790800004],
            [-80.928865274999964, 35.369106908000049],
            [-80.928495274999989, 35.369219908000048],
            [-80.928338274999987, 35.369334908000042],
            [-80.928194274999953, 35.369379908000042],
            [-80.928081274999954, 35.369435908000071],
            [-80.92788427499994, 35.369508908000057],
            [-80.92773727499997, 35.369576909000045],
            [-80.927644275999967, 35.36963490800008],
            [-80.927495273999966, 35.369737909000037],
            [-80.927431274999947, 35.369793909000066],
            [-80.927173274999973, 35.369903908000026],
            [-80.927077274999988, 35.369922908000035],
            [-80.927004273999955, 35.370049909000045],
            [-80.92691627499994, 35.37009690800005],
            [-80.926853274999985, 35.370030909000036],
            [-80.926819273999968, 35.369983909000041],
            [-80.926747274999968, 35.370000908000065],
            [-80.926692273999947, 35.370046909000052],
            [-80.92669527399994, 35.370126909000078],
            [-80.926714273999949, 35.370179909000058],
            [-80.926763274999985, 35.370279909000033],
            [-80.926788273999989, 35.370359909000058],
            [-80.92674027399994, 35.370449909000058],
            [-80.926671273999943, 35.370473909000054],
            [-80.926647274999937, 35.370477909000044],
            [-80.926550274999954, 35.370426909000059],
            [-80.926472274999981, 35.370343910000031],
            [-80.926367273999972, 35.370246909000059],
            [-80.926316274999976, 35.37021990900007],
            [-80.92625527499996, 35.370207910000033],
            [-80.925777273999984, 35.370203909000054],
            [-80.925436274999981, 35.370209909000039],
            [-80.92534427399994, 35.370196908000025],
            [-80.925216273999979, 35.370198909000067],
            [-80.925071273999947, 35.370224909000058],
            [-80.924815273999968, 35.370299909000039],
            [-80.924345273999961, 35.370425908000072],
            [-80.924012273999949, 35.370534909000071],
            [-80.923922273999949, 35.370560909000062],
            [-80.923860273999935, 35.370535909000068],
            [-80.923882272999947, 35.370464908000031],
            [-80.923837273999936, 35.370388909000042],
            [-80.923806272999968, 35.37032190900004],
            [-80.923930272999939, 35.370262909000076],
            [-80.923987272999966, 35.370245909000062],
            [-80.924134273999982, 35.370220909000068],
            [-80.924201273999984, 35.370188909000035],
            [-80.924404273999983, 35.370027909000044],
            [-80.924647273999938, 35.369866908000063],
            [-80.924890273999949, 35.369698909000078],
            [-80.925125274999971, 35.369640909000054],
            [-80.925207272999955, 35.369631909000077],
            [-80.925412273999939, 35.369635909000067],
            [-80.925507273999983, 35.369619908000061],
            [-80.925604273999966, 35.369592908000072],
            [-80.925687273999984, 35.369588908000026],
            [-80.925786273999961, 35.369590909000067],
            [-80.925853273999962, 35.369575909000048],
            [-80.925979274999975, 35.369557909000036],
            [-80.926052273999971, 35.369560909000029],
            [-80.92612027499996, 35.369557908000047],
            [-80.926316274999976, 35.369527909000055],
            [-80.926514273999942, 35.369452908000028],
            [-80.926733274999947, 35.369343909000065],
            [-80.926893273999951, 35.369256908000068],
            [-80.927057274999981, 35.369149909000043],
            [-80.927157273999967, 35.369100908000064],
            [-80.927280274999987, 35.369047908000027],
            [-80.927416274999985, 35.369008908000069],
            [-80.927581274999966, 35.36895290800004],
            [-80.927781273999983, 35.368897908000065],
            [-80.928074274999972, 35.368773908000037],
            [-80.928148275999945, 35.368706909000025],
            [-80.92822827599997, 35.368601908000073],
            [-80.928331274999948, 35.368525909000027],
            [-80.928407274999984, 35.368459908000034],
            [-80.928467274999946, 35.368419908000078],
            [-80.928576275999944, 35.36838990800004],
            [-80.928628274999937, 35.368351908000079],
            [-80.928682274999971, 35.368305909000071],
            [-80.928748274999975, 35.368211908000035],
            [-80.928781273999959, 35.368151908000073],
            [-80.928833275999978, 35.368087908000064],
            [-80.928985274999945, 35.368060909000064],
            [-80.929063275999965, 35.368039908000071],
            [-80.929118274999951, 35.368011908000028],
            [-80.92917027499999, 35.367978909000044],
            [-80.929218274999982, 35.367914908000046],
            [-80.92920027599996, 35.367817908000063],
            [-80.929252274999953, 35.367774908000058],
            [-80.929308274999983, 35.367823908000048],
            [-80.92945227499996, 35.367925909000064],
            [-80.929523274999951, 35.367958909000038],
            [-80.929621274999988, 35.367991908000079],
            [-80.929714274999981, 35.368000908000056],
            [-80.929794274999949, 35.36800290900004],
            [-80.929875274999972, 35.368032908000032],
            [-80.929961275999972, 35.368073908000042],
            [-80.930049275999977, 35.368051908000041],
            [-80.93013127599994, 35.368006908000041],
            [-80.93027727599997, 35.367992908000076],
            [-80.930403275999936, 35.367989908000027],
            [-80.930492274999949, 35.36798090800005],
            [-80.930583275999936, 35.36798090800005],
            [-80.930673275999936, 35.367996908000066],
            [-80.930749275999972, 35.367981909000036],
            [-80.931031275999942, 35.367817908000063],
            [-80.93108127499994, 35.367793908000067],
            [-80.931132275999971, 35.367762908000032],
            [-80.931188275999943, 35.367711908000047],
            [-80.931261275999987, 35.367627909000078],
            [-80.931287275999978, 35.367565908000074],
            [-80.93124527599997, 35.367469908000032],
            [-80.931231275999949, 35.36742190700005],
            [-80.931226275999961, 35.36737290800005],
            [-80.93124127599998, 35.367279908000057],
            [-80.931226275999961, 35.36718490800007],
            [-80.931200274999981, 35.367107908000037],
            [-80.931145274999949, 35.366975908000029],
            [-80.93107727499995, 35.366838908000034],
            [-80.930994275999979, 35.366640907000033],
            [-80.930938275999949, 35.36654790800003],
            [-80.930895275999944, 35.366488908000065],
            [-80.930803275999949, 35.366395908000072],
            [-80.930711275999954, 35.36635590800006],
            [-80.930654274999938, 35.366340908000041],
            [-80.930595275999963, 35.366332908000061],
            [-80.930445275999944, 35.366341908000038],
            [-80.930366275999972, 35.366326908000076],
            [-80.930329275999952, 35.366242908000061],
            [-80.930284274999963, 35.36611590800004],
            [-80.930224274999944, 35.365810908000071],
            [-80.930195275999949, 35.365503908000051],
            [-80.930175275999943, 35.365444908000029],
            [-80.930167274999974, 35.365394907000052],
            [-80.930146275999959, 35.36533890700008],
            [-80.930045274999941, 35.365141907000066],
            [-80.93003727599995, 35.36505790700005],
            [-80.930092275999982, 35.364973907000035],
            [-80.930145274999973, 35.36491490700007],
            [-80.930304274999969, 35.364826908000055],
            [-80.93038327499994, 35.364805908000051],
            [-80.930423274999953, 35.364765908000038],
            [-80.930517274999943, 35.364709908000066],
            [-80.930384274999938, 35.364551907000077],
            [-80.930051274999983, 35.364476907000039],
            [-80.931238275999988, 35.363070908000054],
            [-80.930594274999976, 35.36306490700008],
            [-80.929739274999974, 35.362980907000065],
            [-80.928982274999953, 35.362898907000044],
            [-80.928208274999974, 35.362793908000071],
            [-80.927440273999935, 35.362626907000049],
            [-80.92741827399999, 35.362621907000062],
            [-80.926707274999956, 35.362495907000039],
            [-80.926291273999936, 35.362338907000037],
            [-80.92598127499997, 35.362221907000048],
            [-80.925309273999972, 35.362021907000042],
            [-80.924681273999965, 35.361785907000069],
            [-80.924214272999961, 35.361662907000039],
            [-80.923735273999966, 35.361265906000028],
            [-80.923344272999941, 35.360906907000071],
            [-80.922997272999964, 35.360644907000051],
            [-80.922885272999963, 35.360340907000079],
            [-80.922953272999962, 35.360082907000049],
            [-80.923005272999944, 35.359930907000034],
            [-80.923048272999949, 35.359810907000053],
            [-80.923107271999982, 35.359699907000049],
            [-80.923384272999954, 35.359186907000037],
            [-80.923857273999943, 35.358956907000049],
            [-80.924387273999969, 35.358622906000051],
            [-80.924869273999946, 35.358369907000053],
            [-80.925468272999979, 35.358169906000057],
            [-80.926376273999949, 35.357870906000073],
            [-80.927011273999938, 35.357641906000026],
            [-80.92766327399994, 35.357530906000079],
            [-80.929344273999959, 35.357254906000037],
            [-80.931328274999942, 35.357603906000065],
            [-80.932488275999958, 35.357533905000025],
            [-80.93303227499996, 35.357377905000078],
            [-80.933440275999942, 35.357279907000077],
            [-80.933931275999953, 35.357092906000048],
            [-80.934550275999982, 35.356789906000074],
            [-80.935498275999976, 35.356246905000035],
            [-80.936612276999938, 35.355506905000027],
            [-80.938330277999967, 35.35428590500004],
            [-80.93908027699996, 35.353654904000052],
            [-80.939648276999947, 35.35317890500005],
            [-80.940401276999978, 35.352455904000067],
            [-80.94059227799994, 35.35230490500004],
            [-80.941178277999938, 35.351844905000064],
            [-80.941764277999937, 35.351363904000038],
            [-80.942357277999974, 35.351016904000062],
            [-80.942921277999972, 35.350705904000051],
            [-80.943613277999987, 35.350518904000069],
            [-80.94351927799994, 35.349537903000055],
            [-80.942990278999957, 35.348711903000037],
            [-80.942669277999983, 35.348181903000068],
            [-80.942409277999957, 35.347798904000058],
            [-80.94227727699996, 35.347574903000066],
            [-80.942118276999963, 35.347282904000053],
            [-80.941999277999969, 35.34707390300008],
            [-80.941861277999976, 35.346818903000042],
            [-80.941480277999972, 35.346155903000067],
            [-80.941179276999947, 35.345594903000062],
            [-80.941047276999939, 35.345381903000032],
            [-80.940969276999965, 35.345268902000043],
            [-80.94078327699998, 35.345015902000057],
            [-80.940368276999948, 35.344496903000049],
            [-80.939629276999938, 35.343554902000051],
            [-80.939531276999958, 35.343406903000073],
            [-80.939476276999983, 35.343289903000027],
            [-80.939431275999937, 35.34314990200005],
            [-80.93939927699995, 35.34300690200007],
            [-80.93938327799998, 35.342853902000058],
            [-80.93938327799998, 35.34266890300006],
            [-80.93940727599994, 35.342278902000032],
            [-80.939424275999954, 35.342101902000024],
            [-80.939429276999988, 35.341938902000038],
            [-80.939427276999936, 35.341836902000068],
            [-80.939405276999935, 35.341645902000039],
            [-80.939384277999977, 35.341562902000078],
            [-80.939224276999937, 35.341134902000078],
            [-80.938977275999946, 35.340532901000074],
            [-80.938717276999967, 35.339929902000051],
            [-80.938518276999957, 35.339444902000025],
            [-80.938483276999989, 35.339345902000048],
            [-80.938422276999972, 35.339174902000025],
            [-80.938392275999945, 35.33864690200005],
            [-80.938361275999966, 35.338452902000029],
            [-80.938365276999946, 35.338356902000044],
            [-80.938357276999966, 35.338205902000027],
            [-80.938354275999984, 35.338140902000077],
            [-80.938350276999984, 35.337467901000025],
            [-80.938343276999944, 35.33713890100006],
            [-80.938342276999947, 35.337026901000058],
            [-80.938336275999973, 35.336255902000062],
            [-80.938323276999938, 35.336087901000042],
            [-80.938286275999985, 35.335769900000059],
            [-80.938265275999981, 35.335649901000068],
            [-80.938244275999978, 35.335567901000047],
            [-80.938180274999979, 35.335369901000035],
            [-80.938062275999982, 35.33509390100005],
            [-80.937629275999939, 35.334163901000068],
            [-80.937444274999962, 35.333735901000068],
            [-80.937367275999975, 35.333578901000067],
            [-80.937335275999942, 35.333532901000069],
            [-80.93727127599999, 35.33346490100007],
            [-80.937074275999976, 35.333657901000038],
            [-80.93690027599996, 35.333810901000049],
            [-80.936738274999982, 35.333935901000075],
            [-80.936569275999943, 35.334047901000076],
            [-80.936454275999949, 35.33411390100008],
            [-80.936303274999943, 35.334190900000067],
            [-80.936036274999935, 35.334309900000051],
            [-80.935716274999947, 35.334438901000055],
            [-80.934599275999972, 35.334859901000073],
            [-80.933532274999948, 35.335162902000036],
            [-80.933190273999969, 35.335206901000049],
            [-80.932999274999986, 35.335221901000068],
            [-80.932706274999987, 35.335230901000045],
            [-80.932595274999983, 35.335227900000064],
            [-80.932348273999935, 35.335209901000042],
            [-80.931947273999981, 35.335158901000057],
            [-80.931485273999954, 35.335090901000058],
            [-80.931117272999984, 35.335036901000024],
            [-80.930520273999946, 35.334949901000073],
            [-80.92988827299996, 35.334853901000031],
            [-80.929607272999988, 35.33481590100007],
            [-80.929238273999943, 35.334783901000037],
            [-80.929099273999952, 35.33477890100005],
            [-80.928812273999938, 35.334785901000032],
            [-80.928575272999979, 35.334811902000069],
            [-80.92827127299995, 35.334871902000032],
            [-80.928058272999976, 35.334924901000079],
            [-80.927047272999971, 35.335204901000054],
            [-80.92687927299994, 35.335248901000057],
            [-80.926796272999979, 35.335270901000058],
            [-80.926530272999969, 35.335335902000054],
            [-80.926275272999987, 35.335388902000034],
            [-80.926120272999981, 35.335420901000077],
            [-80.925922272999969, 35.335457901000041],
            [-80.925475272999961, 35.335525902000029],
            [-80.924589271999935, 35.335650902000054],
            [-80.923879272999955, 35.335755901000027],
            [-80.922743270999945, 35.335917902000062],
            [-80.922506271999964, 35.335944902000051],
            [-80.922315270999945, 35.33595990200007],
            [-80.922054270999979, 35.335964901000068],
            [-80.921763270999975, 35.335949901000049],
            [-80.921535271999971, 35.335921902000052],
            [-80.921094270999959, 35.335838902000035],
            [-80.920474269999943, 35.335705902000029],
            [-80.919887270999936, 35.335573902000078],
            [-80.919338269999969, 35.335464902000069],
            [-80.919103269999937, 35.33542790100006],
            [-80.918893269999955, 35.335401901000068],
            [-80.918643269999961, 35.335378902000059],
            [-80.918231269999978, 35.335358902000053],
            [-80.917946269999959, 35.335357902000055],
            [-80.917583269999966, 35.335371902000077],
            [-80.917232269999943, 35.335398902000065],
            [-80.917037268999934, 35.335420901000077],
            [-80.916989269999988, 35.335429901000055],
            [-80.916250269999978, 35.335565902000042],
            [-80.91604426899994, 35.335600901000078],
            [-80.915287268999975, 35.335730901000034],
            [-80.915230269999938, 35.335741902000052],
            [-80.914652268999987, 35.335847901000079],
            [-80.914298268999971, 35.335921902000052],
            [-80.913991268999951, 35.336001903000067],
            [-80.913783268999964, 35.335749902000032],
            [-80.913221267999972, 35.335088901000063],
            [-80.912926268999968, 35.334676902000069],
            [-80.912808267999935, 35.33448590100005],
            [-80.912635267999974, 35.334186902000056],
            [-80.912550268999951, 35.334019901000033],
            [-80.912437267999962, 35.333744901000046],
            [-80.912311267999939, 35.33343190100004],
            [-80.912154267999938, 35.333011901000077],
            [-80.912076268999954, 35.332775901000048],
            [-80.911465266999983, 35.333212902000071],
            [-80.910880267999971, 35.333668901000067],
            [-80.910338267999975, 35.334129902000029],
            [-80.909756267999967, 35.334668902000033],
            [-80.908401266999988, 35.33609490200007],
            [-80.908098266999957, 35.336433902000067],
            [-80.907493267999939, 35.337073902000043],
            [-80.907158266999943, 35.337402903000054],
            [-80.906958266999936, 35.337583902000063],
            [-80.906539266999971, 35.337929903000031],
            [-80.906271266999966, 35.338130902000046],
            [-80.905818266999972, 35.338432902000079],
            [-80.905344265999986, 35.338712903000044],
            [-80.904871265999986, 35.338948903000073],
            [-80.904596265999942, 35.339086903000066],
            [-80.904338266999957, 35.339198903000067],
            [-80.904076266999937, 35.33930390300003],
            [-80.90366626499997, 35.339453903000049],
            [-80.903121265999971, 35.339621903000079],
            [-80.902564265999956, 35.339762904000054],
            [-80.901998265999964, 35.339875904000053],
            [-80.901424264999946, 35.339960904000066],
            [-80.900845265999976, 35.340016903000048],
            [-80.900366263999956, 35.340041903000042],
            [-80.899729264999962, 35.340043903000037],
            [-80.899058264999951, 35.340017904000035],
            [-80.89846526499997, 35.339970903000051],
            [-80.89573726399999, 35.33970490400003],
            [-80.894385262999947, 35.33957390300003],
            [-80.894369263999977, 35.339608904000045],
            [-80.894295263999936, 35.339781904000063],
            [-80.893663262999951, 35.339722904000041],
            [-80.89293926299996, 35.339693903000068],
            [-80.892214261999982, 35.339693904000058],
            [-80.891491261999988, 35.339723904000039],
            [-80.890769260999946, 35.339781904000063],
            [-80.890045261999944, 35.339869903000078],
            [-80.88933726099998, 35.33998590300007],
            [-80.888637260999985, 35.340128904000039],
            [-80.887946260999968, 35.34030090400006],
            [-80.887266260999979, 35.340499904000069],
            [-80.886599260999958, 35.340724904000069],
            [-80.885946260999958, 35.340977903000066],
            [-80.885517260999961, 35.341164904000038],
            [-80.885309260999975, 35.341255904000036],
            [-80.884690259999957, 35.341558905000056],
            [-80.884371259999966, 35.341732904000025],
            [-80.883687259999988, 35.342137904000026],
            [-80.883025259999954, 35.34256690400008],
            [-80.882290258999944, 35.343056904000036],
            [-80.881581259999962, 35.343501905000039],
            [-80.881512259999965, 35.343540905000054],
            [-80.881412258999944, 35.343598905000079],
            [-80.881198258999973, 35.343458904000045],
            [-80.879566258999944, 35.342389904000072],
            [-80.87930825899997, 35.342196905000037],
            [-80.879076258999987, 35.341982904000076],
            [-80.878871258999936, 35.341751905000024],
            [-80.87847925899996, 35.341187905000027],
            [-80.877396257999976, 35.33927990400008],
            [-80.877251258999934, 35.339018904000056],
            [-80.876688256999955, 35.338040904000025],
            [-80.875991256999953, 35.336813904000053],
            [-80.875699257999941, 35.336300903000051],
            [-80.875323256999934, 35.335643903000062],
            [-80.874933256999952, 35.334947903000057],
            [-80.874720256999979, 35.334524902000055],
            [-80.874418257999935, 35.333874903000037],
            [-80.874203256999976, 35.333372903000054],
            [-80.874167257999943, 35.333288903000039],
            [-80.87377025699999, 35.332265902000074],
            [-80.873505256999977, 35.331581903000028],
            [-80.873434255999939, 35.33142390200004],
            [-80.873372255999982, 35.331318903000067],
            [-80.873282256999971, 35.331193902000052],
            [-80.873155256999951, 35.331056902000057],
            [-80.873008255999935, 35.330914902000075],
            [-80.87253325599994, 35.330483903000072],
            [-80.872197255999936, 35.330193902000076],
            [-80.871026255999936, 35.329230902000063],
            [-80.87078125499994, 35.329023901000028],
            [-80.86920525499994, 35.327736902000026],
            [-80.869153255999947, 35.327693902000078],
            [-80.868798254999945, 35.327396902000032],
            [-80.868723254999964, 35.327329901000041],
            [-80.868535253999937, 35.327162902000055],
            [-80.867973254999981, 35.326607901000045],
            [-80.86749425499994, 35.326134902000035],
            [-80.86725325499998, 35.325894902000073],
            [-80.867066253999951, 35.325689901000032],
            [-80.866915254999981, 35.325489901000026],
            [-80.866845253999941, 35.325372902000026],
            [-80.866759253999987, 35.325425902000063],
            [-80.86667625399997, 35.32549090200007],
            [-80.86582825399995, 35.326240901000062],
            [-80.865121253999973, 35.32687590200004],
            [-80.864835253999956, 35.327142902000048],
            [-80.864528253999936, 35.327425901000026],
            [-80.86436525299996, 35.32756990200005],
            [-80.864248252999971, 35.327679902000057],
            [-80.863697253999987, 35.328167902000075],
            [-80.86310825299995, 35.328702903000078],
            [-80.863009252999973, 35.328785903000039],
            [-80.862866252999936, 35.328896902000054],
            [-80.86218525299995, 35.329382903000067],
            [-80.862066252999966, 35.329463902000043],
            [-80.861916252999947, 35.329547903000048],
            [-80.861851251999951, 35.329575902000045],
            [-80.861783252999942, 35.329597903000035],
            [-80.861704252999971, 35.329617902000052],
            [-80.861599252999952, 35.329637902000059],
            [-80.861345252999968, 35.32966790200004],
            [-80.86074825299994, 35.329709903000037],
            [-80.85997925199996, 35.329773903000046],
            [-80.858887251999988, 35.329865903000041],
            [-80.858589251999945, 35.329896903000076],
            [-80.858471251999958, 35.329916903000026],
            [-80.858103251999978, 35.330004903000031],
            [-80.857984252999984, 35.330036902000074],
            [-80.857748251999965, 35.330119902000035],
            [-80.857729251999956, 35.330127903000061],
            [-80.857473251999977, 35.330229903000031],
            [-80.856667251999966, 35.330611903000033],
            [-80.856378250999967, 35.330752903000075],
            [-80.856086250999965, 35.330900903000042],
            [-80.85553625099999, 35.331162903000063],
            [-80.855145250999954, 35.331343903000061],
            [-80.854460250999978, 35.331675902000029],
            [-80.854137250999941, 35.33182090400004],
            [-80.853922250999972, 35.331908903000055],
            [-80.853711250999936, 35.331988903000024],
            [-80.853396249999946, 35.332091903000048],
            [-80.853217249999943, 35.332140904000028],
            [-80.852565250999987, 35.332294904000037],
            [-80.852470249999953, 35.332325903000026],
            [-80.852436250999972, 35.332345904000078],
            [-80.852414249999981, 35.332365903000039],
            [-80.85237725099995, 35.332429904000037],
            [-80.852298250999979, 35.332525903000032],
            [-80.851793250999947, 35.333001903000024],
            [-80.851654249999967, 35.333117904000062],
            [-80.851540249999971, 35.333201903000031],
            [-80.851365250999947, 35.333308904000035],
            [-80.851161248999972, 35.333410903000072],
            [-80.850927249999984, 35.333503904000054],
            [-80.850724249999985, 35.333565904000068],
            [-80.85054624899999, 35.333604904000026],
            [-80.849208249999947, 35.333788904000073],
            [-80.848757249999949, 35.33385090400003],
            [-80.848318248999988, 35.333908904000054],
            [-80.846675248999986, 35.334133904000055],
            [-80.845706248999988, 35.334265905000052],
            [-80.845415247999938, 35.334313904000055],
            [-80.845385247999957, 35.334321905000024],
            [-80.84527924799994, 35.334349904000078],
            [-80.844901247999985, 35.334474905000036],
            [-80.844753247999961, 35.334530904000076],
            [-80.844542248999971, 35.334623904000068],
            [-80.844467247999944, 35.334659903000045],
            [-80.844431247999978, 35.334678904000043],
            [-80.844373247999954, 35.334708904000024],
            [-80.843267247999961, 35.335338905000071],
            [-80.843024248999939, 35.335485905000041],
            [-80.842947247999973, 35.335560904000033],
            [-80.842826247999938, 35.335649905000025],
            [-80.842736246999948, 35.33569990500007],
            [-80.842654247999974, 35.335735904000046],
            [-80.842271246999985, 35.335903905000066],
            [-80.841668247999962, 35.336287905000063],
            [-80.840912246999949, 35.336750905000031],
            [-80.84009724799995, 35.337249905000078],
            [-80.839898246999951, 35.337366906000057],
            [-80.839705246999984, 35.33745990500006],
            [-80.839514246999954, 35.337533905000043],
            [-80.839332246999959, 35.33759490500006],
            [-80.839217246999965, 35.337627905000033],
            [-80.839147245999982, 35.337646905000042],
            [-80.839081245999978, 35.337665905000051],
            [-80.838583245999985, 35.337782904000051],
            [-80.838211245999958, 35.337865905000058],
            [-80.836923245999969, 35.338192905000028],
            [-80.835758245999955, 35.338477906000037],
            [-80.835352244999967, 35.338581905000069],
            [-80.834883245999947, 35.338696905000063],
            [-80.834301245999939, 35.338856905000057],
            [-80.833521244999986, 35.339038905000052],
            [-80.833271244999935, 35.339081906000047],
            [-80.83313024499995, 35.339098905000071],
            [-80.832898244999967, 35.339110905000041],
            [-80.832734244999983, 35.339113905000033],
            [-80.832505244999936, 35.339108905000046],
            [-80.831935243999965, 35.339097905000074],
            [-80.82832024399994, 35.339004906000071],
            [-80.827885242999969, 35.33899490500005],
            [-80.826954243999978, 35.338973906000035],
            [-80.824361242999942, 35.338903906000041],
            [-80.824177242999951, 35.338893905000077],
            [-80.824059241999976, 35.338882906000038],
            [-80.823989241999982, 35.338875906000055],
            [-80.823682240999972, 35.338825906000068],
            [-80.822240240999975, 35.338573906000079],
            [-80.821838241999956, 35.338496906000046],
            [-80.82133324199998, 35.338373905000026],
            [-80.821005240999966, 35.338276906000033],
            [-80.820791240999938, 35.338200906000054],
            [-80.820685240999978, 35.338156906000052],
            [-80.820576240999969, 35.338105906000067],
            [-80.820470241999942, 35.338043905000063],
            [-80.820338240999945, 35.337945905000026],
            [-80.82028324099997, 35.337896905000036],
            [-80.820137240999941, 35.33774190500003],
            [-80.820023240999944, 35.337605905000032],
            [-80.819780241999979, 35.337299906000055],
            [-80.819480240999951, 35.336958906000064],
            [-80.819046239999977, 35.336487906000059],
            [-80.81894024099995, 35.336390906000076],
            [-80.818828239999959, 35.336303906000069],
            [-80.818762240999945, 35.336263905000067],
            [-80.81867023999996, 35.336222905000056],
            [-80.818559239999956, 35.33619490600006],
            [-80.818475239999941, 35.336184906000028],
            [-80.818372239999974, 35.336182905000044],
            [-80.818214240999964, 35.336200905000055],
            [-80.818072239999935, 35.336227905000044],
            [-80.817950239999959, 35.336259906000066],
            [-80.817765240999961, 35.336318906000031],
            [-80.817630239999971, 35.33637090600007],
            [-80.817470239999977, 35.336447906000046],
            [-80.81727824099994, 35.336558905000061],
            [-80.816823239999962, 35.336875906000046],
            [-80.816727239999977, 35.336942905000058],
            [-80.816589239999985, 35.337029906000055],
            [-80.816406240999981, 35.33712590600004],
            [-80.816219238999963, 35.337213905000056],
            [-80.816033239999967, 35.337280906000046],
            [-80.814406238999936, 35.337810907000062],
            [-80.813914238999985, 35.337959906000037],
            [-80.813511238999979, 35.338075906000029],
            [-80.813152238999976, 35.33816790700007],
            [-80.812428238999985, 35.338321907000079],
            [-80.811115237999957, 35.33859190700008],
            [-80.810599237999952, 35.33869790600005],
            [-80.809772237999937, 35.338860907000026],
            [-80.808983237999939, 35.339015906000043],
            [-80.808684237999955, 35.339074907000054],
            [-80.808448237999983, 35.33911390600008],
            [-80.808325237999952, 35.339128907000031],
            [-80.808193236999955, 35.339137907000065],
            [-80.808036237999943, 35.339135906000024],
            [-80.807884237999986, 35.339128906000042],
            [-80.807652236999957, 35.33909890700005],
            [-80.807210236999936, 35.339022906000025],
            [-80.805952236999985, 35.338825907000057],
            [-80.804051235999964, 35.338533907000055],
            [-80.803858236999986, 35.338503906000028],
            [-80.802102235999939, 35.338253906000034],
            [-80.801751234999983, 35.338203907000036],
            [-80.801037235999956, 35.338105907000056],
            [-80.799367234999977, 35.337972907000051],
            [-80.798496234999959, 35.337915906000035],
            [-80.796659233999947, 35.337784906000024],
            [-80.796626233999973, 35.338056907000066],
            [-80.796552233999989, 35.338581906000059],
            [-80.796434234999936, 35.339495907000071],
            [-80.796405233999963, 35.339602907000028],
            [-80.796388233999949, 35.339802908000024],
            [-80.796366233999947, 35.33993190700005],
            [-80.796322233999945, 35.340072908000025],
            [-80.796145233999937, 35.340568907000034],
            [-80.795761232999951, 35.34164290700005],
            [-80.795633233999979, 35.342015908000064],
            [-80.795506233999959, 35.342495908000046],
            [-80.795429233999982, 35.342832907000059],
            [-80.795412233999969, 35.342929908000031],
            [-80.795396233999952, 35.343105908000041],
            [-80.79539323399996, 35.343211908000058],
            [-80.795405233999986, 35.343617908000056],
            [-80.795402234999983, 35.343655908000073],
            [-80.795396233999952, 35.343708908000053],
            [-80.795429233999982, 35.344386909000036],
            [-80.795711234999942, 35.34701190800007],
            [-80.795741233999934, 35.347278909000067],
            [-80.795768233999979, 35.347550908000073],
            [-80.79579023499997, 35.34778091000004],
            [-80.795811234999974, 35.348044909000066],
            [-80.795881233999978, 35.348936908000042],
            [-80.795974233999971, 35.350094909000063],
            [-80.795977234999953, 35.350282909000043],
            [-80.795959233999952, 35.350469909000026],
            [-80.795949233999977, 35.350527910000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795912234999946, 35.350689909000039],
            [-80.795924234999973, 35.350762910000071],
            [-80.795845233999955, 35.350827909000031],
            [-80.795817233999969, 35.350859910000054],
            [-80.79579423399997, 35.350894910000079],
            [-80.795246234999979, 35.352010910000047],
            [-80.795148234999942, 35.352198910000027],
            [-80.795143233999966, 35.352207910000061],
            [-80.794993234999936, 35.352494910000075],
            [-80.794889233999982, 35.352679909000074],
            [-80.79472123499994, 35.353003910000041],
            [-80.794592233999936, 35.353298911000024],
            [-80.794308233999971, 35.354033910000055],
            [-80.794259233999981, 35.354203910000024],
            [-80.794232233999935, 35.354376910000042],
            [-80.794228233999945, 35.35450091000007],
            [-80.794243233999964, 35.354664910000054],
            [-80.794266234999952, 35.354800911000041],
            [-80.794301234999978, 35.354924911000069],
            [-80.794327234999969, 35.354984911000031],
            [-80.794382233999954, 35.355082910000078],
            [-80.79446623399997, 35.355181911000045],
            [-80.794587233999948, 35.355283911000072],
            [-80.79467923499999, 35.35533791000006],
            [-80.794827234999957, 35.355424910000067],
            [-80.795254234999959, 35.35565691100004],
            [-80.795571234999954, 35.355819910000037],
            [-80.797256235999953, 35.356629911000027],
            [-80.797561235999979, 35.356782910000049],
            [-80.797742235999976, 35.356880911000076],
            [-80.797908234999966, 35.356993910000028],
            [-80.798015235999969, 35.357091910000065],
            [-80.798130234999974, 35.357221910000078],
            [-80.798263235999968, 35.35740891100005],
            [-80.798339234999958, 35.357540911000058],
            [-80.798605235999958, 35.358067911000035],
            [-80.798719235999954, 35.358270911000034],
            [-80.798848235999969, 35.358548911000071],
            [-80.799051235999968, 35.358940911000047],
            [-80.799117234999983, 35.359049912000046],
            [-80.79924523599999, 35.359229912000046],
            [-80.799348234999968, 35.35935591100008],
            [-80.799633235999977, 35.359705911000049],
            [-80.799779235999949, 35.35991591100003],
            [-80.799856235999982, 35.360064912000041],
            [-80.799902236999969, 35.360178912000038],
            [-80.799913234999963, 35.360216912000055],
            [-80.799935235999953, 35.360268911000048],
            [-80.799952235999967, 35.360326911000072],
            [-80.799622235999948, 35.360418912000057],
            [-80.798988235999957, 35.360611912000024],
            [-80.798501235999936, 35.360765911000044],
            [-80.797442234999949, 35.361090912000066],
            [-80.797181234999982, 35.361181911000074],
            [-80.797062234999942, 35.361228911000069],
            [-80.796106234999968, 35.361662912000043],
            [-80.79504323499998, 35.362153912000053],
            [-80.794044233999955, 35.362620912000068],
            [-80.793893234999985, 35.362692912000057],
            [-80.79375323499994, 35.362753912000073],
            [-80.793432234999955, 35.362907912000026],
            [-80.792583233999949, 35.363296912000067],
            [-80.792412234999972, 35.36336691200006],
            [-80.792199233999952, 35.363451912000073],
            [-80.791902233999963, 35.363558913000077],
            [-80.791545233999955, 35.363679912000066],
            [-80.791312232999985, 35.363761913000076],
            [-80.791192232999947, 35.363803913000027],
            [-80.791017232999934, 35.363860912000064],
            [-80.79080823399994, 35.363930912000058],
            [-80.790297233999979, 35.364097912000034],
            [-80.78977523399999, 35.364281912000024],
            [-80.789337233999959, 35.364425913000048],
            [-80.789110232999974, 35.364524912000036],
            [-80.78895423299997, 35.364602913000056],
            [-80.788622231999966, 35.364780913000061],
            [-80.788296232999983, 35.364966913000046],
            [-80.787528231999943, 35.365370913000049],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365560913000024],
            [-80.787499232999949, 35.365769912000076],
            [-80.78746023399998, 35.365936914000031],
            [-80.787396232999981, 35.366161913000042],
            [-80.792751234999969, 35.368284913000025],
            [-80.794869234999965, 35.369124913000064],
            [-80.79568123599995, 35.369461914000055],
            [-80.795949234999966, 35.370134913000072],
            [-80.796038234999969, 35.370239914000024],
            [-80.796087234999959, 35.370324914000037],
            [-80.796123235999971, 35.370414914000037],
            [-80.796280235999973, 35.371011914000064],
            [-80.796325234999983, 35.371120914000073],
            [-80.796389234999936, 35.371225914000036],
            [-80.796635235999986, 35.371587914000031],
            [-80.796870235999961, 35.371958914000061],
            [-80.796915235999961, 35.372047913000074],
            [-80.796933235999973, 35.372133914000074],
            [-80.796958235999966, 35.372212914000045],
            [-80.796959235999964, 35.37238691400006],
            [-80.796966234999957, 35.372515914000076],
            [-80.796976235999978, 35.372682915000041],
            [-80.796997235999982, 35.372774914000047],
            [-80.797039235999989, 35.372922914000071],
            [-80.797416235999947, 35.373811914000044],
            [-80.797446236999974, 35.373914914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797468235999986, 35.374094914000068],
            [-80.797451236999962, 35.374244915000077],
            [-80.797408235999967, 35.374408914000071],
            [-80.797247235999976, 35.374933914000053],
            [-80.797101235999946, 35.375382914000056],
            [-80.79768123599996, 35.375517915000046],
            [-80.797975236999946, 35.375592915000027],
            [-80.798128235999968, 35.375652915000046],
            [-80.798258235999981, 35.375729914000033],
            [-80.798362236999935, 35.375802915000065],
            [-80.798447236999948, 35.375885915000026],
            [-80.798501236999982, 35.375957915000072],
            [-80.798560236999947, 35.376061915000037],
            [-80.798594236999975, 35.376165915000058],
            [-80.798619236999969, 35.376291915000024],
            [-80.798623236999958, 35.376401914000041],
            [-80.798611236999989, 35.376517915000079],
            [-80.798570235999989, 35.376642916000037],
            [-80.798507235999978, 35.376789915000074],
            [-80.798463236999964, 35.376912915000048],
            [-80.798449235999954, 35.376998915000058],
            [-80.79845323699999, 35.37707391500004],
            [-80.798466235999967, 35.377154915000062],
            [-80.798507236999967, 35.377240915000073],
            [-80.79855123699997, 35.377312914000072],
            [-80.798589235999941, 35.377364916000033],
            [-80.798712236999961, 35.377486914000031],
            [-80.797820236999939, 35.378089914000043],
            [-80.797605236999971, 35.378237916000046],
            [-80.797417236999934, 35.378367915000069],
            [-80.796784235999951, 35.378796915000066],
            [-80.796537235999949, 35.378964916000029],
            [-80.795169236999982, 35.379910915000039],
            [-80.794706235999968, 35.380224916000031],
            [-80.794392234999975, 35.380420916000048],
            [-80.794257234999975, 35.380490915000053],
            [-80.794104235999953, 35.380559916000038],
            [-80.793955235999988, 35.380613916000073],
            [-80.793399234999981, 35.380789916000026],
            [-80.79240923499998, 35.381092915000067],
            [-80.791912235999973, 35.38124491700006],
            [-80.791240234999975, 35.381449916000065],
            [-80.790655234999974, 35.38162791700006],
            [-80.790401233999944, 35.381705916000044],
            [-80.790291234999984, 35.381739916000072],
            [-80.789902233999953, 35.381876916000067],
            [-80.789763233999963, 35.381933916000037],
            [-80.789696233999962, 35.381965917000059],
            [-80.789469233999966, 35.382082917000048],
            [-80.789291233999961, 35.38218691600008],
            [-80.789050233999944, 35.382340916000032],
            [-80.788806233999935, 35.382512917000042],
            [-80.788435233999962, 35.382791916000031],
            [-80.788118233999967, 35.383049916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.786700233999966, 35.384445916000061],
            [-80.785941233999949, 35.385195917000033],
            [-80.785334232999958, 35.385761918000071],
            [-80.784783232999985, 35.386234918000071],
            [-80.784700232999967, 35.386299917000031],
            [-80.78233923199997, 35.38815291700007],
            [-80.78141223199998, 35.388881918000038],
            [-80.779635231999976, 35.390281918000028],
            [-80.779433231999974, 35.390435918000037],
            [-80.779261231999953, 35.390556918000073],
            [-80.77899923199999, 35.390720919000046],
            [-80.778744231999951, 35.390860919000033],
            [-80.778606230999969, 35.390927919000035],
            [-80.778365230999952, 35.391035919000046],
            [-80.778006230999949, 35.391183919000071],
            [-80.778089230999967, 35.391297919000067],
            [-80.780835231999959, 35.395009919000074],
            [-80.782405232999963, 35.397131920000049],
            [-80.783722233999981, 35.398911921000035],
            [-80.781756231999964, 35.399425920000056],
            [-80.781609232999983, 35.399464920000071],
            [-80.78152823299996, 35.399959921000061],
            [-80.781306232999952, 35.400481920000061],
            [-80.780617232999987, 35.402109921000033],
            [-80.78050723299998, 35.402369921000059],
            [-80.779215232999945, 35.405415921000042],
            [-80.778600232999963, 35.406866922000063],
            [-80.778380231999961, 35.407267922000074],
            [-80.779746232999969, 35.409076922000054],
            [-80.778319232999934, 35.409293922000074],
            [-80.774467231999949, 35.409852923000074],
            [-80.77464723199995, 35.412072923000039],
            [-80.77468023199998, 35.412043923000056],
            [-80.774795230999985, 35.411957923000045],
            [-80.774835231999987, 35.411895923000031],
            [-80.774847231999956, 35.411841923000054],
            [-80.774869230999968, 35.411792923000064],
            [-80.774931231999972, 35.41173092300005],
            [-80.775001231999966, 35.41169392300003],
            [-80.775138231999961, 35.411649923000027],
            [-80.775221230999989, 35.411617923000051],
            [-80.775310231999981, 35.41159592300005],
            [-80.775400230999935, 35.411566923000066],
            [-80.775561231999973, 35.411543923000067],
            [-80.77588923199994, 35.411468923000029],
            [-80.775947231999965, 35.411465924000026],
            [-80.776008230999935, 35.411454923000065],
            [-80.776192231999971, 35.411443923000036],
            [-80.776250231999938, 35.411434924000048],
            [-80.776315230999955, 35.411419923000039],
            [-80.776376231999961, 35.411396923000041],
            [-80.776439231999973, 35.411367923000057],
            [-80.776475231999939, 35.411360923000075],
            [-80.776517231999946, 35.411352923000038],
            [-80.776593231999982, 35.411327923000044],
            [-80.776644231999967, 35.411319923000065],
            [-80.776882230999945, 35.411309923000033],
            [-80.776959231999967, 35.41129092400007],
            [-80.777028231999964, 35.411278924000044],
            [-80.777196231999937, 35.411277924000046],
            [-80.777251231999969, 35.411270923000075],
            [-80.777359231999981, 35.411222923000025],
            [-80.777454231999968, 35.411194923000039],
            [-80.777593232999948, 35.411176923000028],
            [-80.77779923199995, 35.411139924000054],
            [-80.777890232999937, 35.411132923000025],
            [-80.778176232999954, 35.41108592300003],
            [-80.778279232999978, 35.411061923000034],
            [-80.778361231999952, 35.411056923000046],
            [-80.778514232999953, 35.411035923000043],
            [-80.778679232999934, 35.411002923000069],
            [-80.778748232999988, 35.410975923000024],
            [-80.778848232999962, 35.410948923000035],
            [-80.779038232999937, 35.410873922000064],
            [-80.779299232999961, 35.410825923000061],
            [-80.779398231999949, 35.410789924000028],
            [-80.779621232999943, 35.410735922000072],
            [-80.77980923299998, 35.410730923000074],
            [-80.77990323299997, 35.41070592300008],
            [-80.779957232999948, 35.410684923000076],
            [-80.780012233999969, 35.410651923000046],
            [-80.780097231999946, 35.410612923000031],
            [-80.780171232999976, 35.41058692200005],
            [-80.780265232999966, 35.410571923000077],
            [-80.780442232999974, 35.410561922000056],
            [-80.780512232999968, 35.410546922000037],
            [-80.780684232999988, 35.410449923000044],
            [-80.780733232999978, 35.410425922000059],
            [-80.780815233999988, 35.410404922000055],
            [-80.780898232999959, 35.410397923000062],
            [-80.781004232999976, 35.410382922000053],
            [-80.781095232999974, 35.410357923000049],
            [-80.781159233999972, 35.410330922000071],
            [-80.78124623299999, 35.410320923000029],
            [-80.781320233999963, 35.410303923000072],
            [-80.781706232999966, 35.410190922000027],
            [-80.781786233999981, 35.410157923000043],
            [-80.781881233999968, 35.410131922000062],
            [-80.781969233999973, 35.410112922000053],
            [-80.782108233999963, 35.410108923000053],
            [-80.782196233999969, 35.410110923000047],
            [-80.782359233999955, 35.410129922000067],
            [-80.78245123399995, 35.410145923000073],
            [-80.782764234999945, 35.410181923000039],
            [-80.783070233999979, 35.410227922000047],
            [-80.783224233999988, 35.410238922000076],
            [-80.783818233999966, 35.410228922000044],
            [-80.783905233999974, 35.410204923000038],
            [-80.784016233999978, 35.410179923000044],
            [-80.784323234999988, 35.410177922000059],
            [-80.784387233999951, 35.410171922000075],
            [-80.78444323399998, 35.410159923000037],
            [-80.78475423499998, 35.410140922000039],
            [-80.784862233999945, 35.410139922000042],
            [-80.784951233999948, 35.41014692300007],
            [-80.785137234999979, 35.410189922000029],
            [-80.785384234999981, 35.410273923000034],
            [-80.785492234999936, 35.410303923000072],
            [-80.785668234999946, 35.410324922000029],
            [-80.785882234999974, 35.410373922000076],
            [-80.785925235999969, 35.410368922000032],
            [-80.786037234999981, 35.410397923000062],
            [-80.786243234999972, 35.410411922000037],
            [-80.786335234999967, 35.410411922000037],
            [-80.786458234999941, 35.410389923000025],
            [-80.786682234999944, 35.410371922000024],
            [-80.787136233999945, 35.410282923000068],
            [-80.787198233999959, 35.410324922000029],
            [-80.787335234999944, 35.410345922000033],
            [-80.787429234999934, 35.410350923000067],
            [-80.787628234999943, 35.410384922000048],
            [-80.78767023499995, 35.410399923000057],
            [-80.787833235999983, 35.410426922000056],
            [-80.787994233999939, 35.410471923000046],
            [-80.788039234999985, 35.410493923000047],
            [-80.788173234999988, 35.410498923000034],
            [-80.788232234999953, 35.410509922000074],
            [-80.788283235999984, 35.410530923000067],
            [-80.788525235999941, 35.410669923000057],
            [-80.788655235999954, 35.410752923000075],
            [-80.788694235999969, 35.410767922000048],
            [-80.788770235999948, 35.41080792300005],
            [-80.788839235999944, 35.410874922000062],
            [-80.789014235999957, 35.410971922000044],
            [-80.789060235999955, 35.410996922000038],
            [-80.789383235999935, 35.411232923000057],
            [-80.789910235999969, 35.411580923000031],
            [-80.790065235999975, 35.411678923000068],
            [-80.79011223599997, 35.411716923000029],
            [-80.790309236999974, 35.411848922000047],
            [-80.790431234999971, 35.411944922000032],
            [-80.790521235999961, 35.412021922000065],
            [-80.79063823599995, 35.41210592200008],
            [-80.790689236999981, 35.412149923000072],
            [-80.79081423699995, 35.41228592300007],
            [-80.790915236999979, 35.412382923000052],
            [-80.790965236999966, 35.412443923000069],
            [-80.791081235999968, 35.412533923000069],
            [-80.791240236999954, 35.412730923000026],
            [-80.79145523599999, 35.41304092300004],
            [-80.791481236999971, 35.413098922000074],
            [-80.791531235999969, 35.413189923000061],
            [-80.791697236999937, 35.413465922000057],
            [-80.791844235999974, 35.413663922000069],
            [-80.791864235999981, 35.413702923000073],
            [-80.792006236999953, 35.413964924000027],
            [-80.792133236999973, 35.414167922000047],
            [-80.792267236999976, 35.414350923000029],
            [-80.792417236999938, 35.414601923000077],
            [-80.792622236999989, 35.414912923000031],
            [-80.792633236999961, 35.414945924000051],
            [-80.792634237999948, 35.414991923000059],
            [-80.79266423699994, 35.415047923000031],
            [-80.792695235999986, 35.415080924000051],
            [-80.792715236999982, 35.415113923000035],
            [-80.792714235999938, 35.415137924000078],
            [-80.792757236999989, 35.415187924000065],
            [-80.792779236999934, 35.41519792400004],
            [-80.792817236999952, 35.415201923000041],
            [-80.79284023699995, 35.415214924000054],
            [-80.792863236999949, 35.415238923000061],
            [-80.792874236999978, 35.41526192300006],
            [-80.792875235999986, 35.415281923000066],
            [-80.792854236999972, 35.415425924000033],
            [-80.792862236999952, 35.415516923000041],
            [-80.792853236999974, 35.415548924000063],
            [-80.792826236999986, 35.415595923000069],
            [-80.792817236999952, 35.415635923000025],
            [-80.792817236999952, 35.41579792400006],
            [-80.792857236999964, 35.415865923000069],
            [-80.792886237999937, 35.415891923000061],
            [-80.792909236999947, 35.415939923000053],
            [-80.792924237999955, 35.415982923000058],
            [-80.792952237999941, 35.416014924000024],
            [-80.792973237999945, 35.416029924000043],
            [-80.793020236999951, 35.41604592300007],
            [-80.793186236999986, 35.416178923000075],
            [-80.793460236999977, 35.41633192300003],
            [-80.79360823799999, 35.416421924000076],
            [-80.793729237999969, 35.416475924000054],
            [-80.79384123799997, 35.416538924000065],
            [-80.793918236999957, 35.416591923000055],
            [-80.794090237999967, 35.41668792300004],
            [-80.794143237999947, 35.41672892400004],
            [-80.794270237999967, 35.416778924000027],
            [-80.794415237999942, 35.416841924000039],
            [-80.794636237999953, 35.416964924000069],
            [-80.794758237999986, 35.417022923000047],
            [-80.794843237999942, 35.417071924000027],
            [-80.79497523799995, 35.417128924000053],
            [-80.795105237999962, 35.417203924000034],
            [-80.79554223699995, 35.41741492400007],
            [-80.795591237999986, 35.417442924000056],
            [-80.795659237999985, 35.417464923000068],
            [-80.795740237999951, 35.417501924000078],
            [-80.795773238999971, 35.417521923000038],
            [-80.795849238999949, 35.417596924000065],
            [-80.796040238999979, 35.417814923000037],
            [-80.796110237999983, 35.417915923000066],
            [-80.796150237999939, 35.417951923000032],
            [-80.796233237999957, 35.417998924000074],
            [-80.796339237999973, 35.418164923000063],
            [-80.796405237999977, 35.41829292400007],
            [-80.796512237999934, 35.418466924000029],
            [-80.79656523899996, 35.418531924000035],
            [-80.796604238999976, 35.418619924000041],
            [-80.796658238999953, 35.418673923000028],
            [-80.796733237999945, 35.418774924000047],
            [-80.796794237999961, 35.41887192400003],
            [-80.796924238999964, 35.41903592400007],
            [-80.797098238999979, 35.419267924000053],
            [-80.797286238999959, 35.41950492400008],
            [-80.797393238999973, 35.419667923000077],
            [-80.797445238999956, 35.419717924000054],
            [-80.797477238999988, 35.419763924000051],
            [-80.797535238999956, 35.419826924000063],
            [-80.797581237999964, 35.419912924000073],
            [-80.797649237999963, 35.419994925000026],
            [-80.797682238999982, 35.420026924000069],
            [-80.797721238999941, 35.420047924000073],
            [-80.79777723899997, 35.420092925000063],
            [-80.797843237999984, 35.420113925000067],
            [-80.797962238999958, 35.420227924000073],
            [-80.797531237999976, 35.420587924000074],
            [-80.797321238999984, 35.420775925000044],
            [-80.796883238999953, 35.421202925000046],
            [-80.794835238999951, 35.42324592500006],
            [-80.793969237999988, 35.424109925000039],
            [-80.793259237999962, 35.424818925000068],
            [-80.792769237999948, 35.425308926000071],
            [-80.79264823799997, 35.425418925000031],
            [-80.792575237999984, 35.425477925000052],
            [-80.792478237999944, 35.425546925000049],
            [-80.792338237999957, 35.425448926000058],
            [-80.792016236999984, 35.425297926000042],
            [-80.791172237999945, 35.424949926000068],
            [-80.791049236999982, 35.424890926000046],
            [-80.790073236999945, 35.424362926000072],
            [-80.78835223699997, 35.423423925000066],
            [-80.788223235999965, 35.423359925000057],
            [-80.788108235999971, 35.423313925000059],
            [-80.788003235999952, 35.423276925000039],
            [-80.787908236999954, 35.423250925000048],
            [-80.78780123599995, 35.423227926000038],
            [-80.787714236999989, 35.423213926000074],
            [-80.787501235999969, 35.423198925000065],
            [-80.787183235999976, 35.423205925000048],
            [-80.785620234999953, 35.423312925000062],
            [-80.78318623499996, 35.423445925000067],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801624241999946, 35.442241929000033],
            [-80.801465241999949, 35.442311929000027],
            [-80.801007240999979, 35.442466928000044],
            [-80.800832240999966, 35.442536929000028],
            [-80.800729240999942, 35.442586928000026],
            [-80.800630241999954, 35.442641928000057],
            [-80.800511240999981, 35.44272292900007],
            [-80.800395241999979, 35.442813929000067],
            [-80.800232240999946, 35.442957928000055],
            [-80.800047239999969, 35.44313992900004],
            [-80.799473240999987, 35.443746930000032],
            [-80.798915239999985, 35.444311929000037],
            [-80.798808239999971, 35.444432929000072],
            [-80.79874523999996, 35.444516929000031],
            [-80.79865523999996, 35.444657929000073],
            [-80.798494239999968, 35.444981929000051],
            [-80.797555239999951, 35.447156930000062],
            [-80.797365239999976, 35.447605930000066],
            [-80.796973239999943, 35.448574930000063],
            [-80.79682324099997, 35.448923930000035],
            [-80.796631240999943, 35.449316930000066],
            [-80.796582239999964, 35.449402931000066],
            [-80.796350239999981, 35.449764930000072],
            [-80.796015238999985, 35.450243931000045],
            [-80.795560239999986, 35.45000893100007],
            [-80.795320239999967, 35.449898931000064],
            [-80.795118239999965, 35.449813931000051],
            [-80.79485023899997, 35.449710930000037],
            [-80.79466623899998, 35.449647931000072],
            [-80.794291238999961, 35.449539931000061],
            [-80.794003239999938, 35.449471930000072],
            [-80.791847238999935, 35.448984931000041],
            [-80.79148923799994, 35.448895931000038],
            [-80.791312237999989, 35.448845930000061],
            [-80.790889238999966, 35.448709930000064],
            [-80.790459237999983, 35.448540931000025],
            [-80.790059238999959, 35.448367931000064],
            [-80.789453237999965, 35.448105930000054],
            [-80.789332237999986, 35.448049930000025],
            [-80.787848236999935, 35.447406931000046],
            [-80.786688236999964, 35.446910930000058],
            [-80.786244236999948, 35.446732931000042],
            [-80.786040236999952, 35.446663931000046],
            [-80.785661236999943, 35.446557931000029],
            [-80.785479235999958, 35.446516931000076],
            [-80.785191235999946, 35.446465930000045],
            [-80.785064236999972, 35.446447930000033],
            [-80.783836236999946, 35.446321931000057],
            [-80.783541235999962, 35.446283931000039],
            [-80.783331235999981, 35.446244930000034],
            [-80.782997235999972, 35.446164930000066],
            [-80.782748235999975, 35.446092930000077],
            [-80.782519234999938, 35.446015930000044],
            [-80.782274235999978, 35.445922930000052],
            [-80.781961235999972, 35.445772930000032],
            [-80.781708235999986, 35.445639931000073],
            [-80.781431234999957, 35.445462930000076],
            [-80.78118623599994, 35.445281930000078],
            [-80.780984234999949, 35.445131930000059],
            [-80.780575234999958, 35.444815930000061],
            [-80.780383234999988, 35.444673930000079],
            [-80.780058234999956, 35.444457930000056],
            [-80.77989123499998, 35.444364930000063],
            [-80.779549234999934, 35.444196930000032],
            [-80.779312234999963, 35.444099931000039],
            [-80.778974234999964, 35.443977930000074],
            [-80.778884234999964, 35.443945929000051],
            [-80.778637233999973, 35.443863930000077],
            [-80.77721223399999, 35.443388930000026],
            [-80.776666232999958, 35.443206929000041],
            [-80.775731232999988, 35.442894929000033],
            [-80.775266233999957, 35.442738929000029],
            [-80.770722231999969, 35.441224930000033],
            [-80.770186231999958, 35.441055930000061],
            [-80.769798231999971, 35.44095193000004],
            [-80.769598231999964, 35.440907929000048],
            [-80.769294230999947, 35.440850929000078],
            [-80.76893523199999, 35.440797930000031],
            [-80.768610230999968, 35.440765930000055],
            [-80.768360230999974, 35.440746929000056],
            [-80.768020231999969, 35.440741930000058],
            [-80.767766231999985, 35.440741929000069],
            [-80.767373230999965, 35.440765930000055],
            [-80.765654229999939, 35.440911929000038],
            [-80.764912229999936, 35.440974930000039],
            [-80.764915230999975, 35.440980929000034],
            [-80.765434229999983, 35.442026930000054],
            [-80.766182230999959, 35.443530930000065],
            [-80.767076230999976, 35.445330930000068],
            [-80.767080230999966, 35.445337930000051],
            [-80.767084230999956, 35.445346931000074],
            [-80.76709723099998, 35.445373930000073],
            [-80.767441230999964, 35.446054930000059],
            [-80.770616232999942, 35.452459932000068],
            [-80.770767232999958, 35.45276393100005],
            [-80.771021232999942, 35.453290932000073],
            [-80.771253232999982, 35.453771932000052],
            [-80.771632232999934, 35.454558932000054],
            [-80.772765232999973, 35.456999933000077],
            [-80.77286023299996, 35.457344932000069],
            [-80.773052233999977, 35.457265932000041],
            [-80.773244233999947, 35.457225933000075],
            [-80.773662232999982, 35.457177933000025],
            [-80.774234232999959, 35.45704093300003],
            [-80.774754232999953, 35.456932933000076],
            [-80.775382233999949, 35.456937932000073],
            [-80.775680234999982, 35.457004933000064],
            [-80.776277234999952, 35.457195933000037],
            [-80.776876234999975, 35.457486933000041],
            [-80.777633234999939, 35.45783193300008],
            [-80.778479234999963, 35.458275933000039],
            [-80.778969235999966, 35.458368933000031],
            [-80.779324235999979, 35.458634932000052],
            [-80.779376234999972, 35.458704933000035],
            [-80.77964723499997, 35.459071933000075],
            [-80.779760235999959, 35.459258933000058],
            [-80.779810235999946, 35.459340933000078],
            [-80.780026235999969, 35.459667933000048],
            [-80.780080235999947, 35.459749933000069],
            [-80.780227235999973, 35.460104933000025],
            [-80.78065623599997, 35.460582933000069],
            [-80.780960235999942, 35.460906934000036],
            [-80.781332236999958, 35.461200934000033],
            [-80.781845235999981, 35.461563934000026],
            [-80.782499235999978, 35.461939934000043],
            [-80.783205236999947, 35.462370934000035],
            [-80.783396236999977, 35.462515933000077],
            [-80.783825235999984, 35.462789933000067],
            [-80.784108236999941, 35.462985934000073],
            [-80.784355236999943, 35.463123934000066],
            [-80.784533237999938, 35.463306934000059],
            [-80.785219237999968, 35.463567934000025],
            [-80.785664237999981, 35.463713934000054],
            [-80.785851236999974, 35.463772934000076],
            [-80.786019237999938, 35.463852934000045],
            [-80.786137237999981, 35.463912934000064],
            [-80.78662623799994, 35.464145934000044],
            [-80.787282237999989, 35.464635934000057],
            [-80.787776237999935, 35.464899934000073],
            [-80.78804723799999, 35.46530993500005],
            [-80.788331238999945, 35.465575934000071],
            [-80.788582237999947, 35.465871934000063],
            [-80.78906423899997, 35.466420935000031],
            [-80.789458237999952, 35.466899934000025],
            [-80.789571239999987, 35.467008935000024],
            [-80.789654238999958, 35.467081934000078],
            [-80.78983723999994, 35.467478935000031],
            [-80.790246238999941, 35.467828935000057],
            [-80.790379238999947, 35.467904934000046],
            [-80.790464239999949, 35.467951934000041],
            [-80.79044523999994, 35.468013935000045],
            [-80.79045623899998, 35.468046935000075],
            [-80.79049723899999, 35.468126935000043],
            [-80.790584239999987, 35.468382934000033],
            [-80.790692239999942, 35.468756935000044],
            [-80.790785238999945, 35.469026934000055],
            [-80.790879239999981, 35.469275935000041],
            [-80.79090623899998, 35.469375934000027],
            [-80.790907239999967, 35.469426935000058],
            [-80.790926238999987, 35.469509935000076],
            [-80.791010239999935, 35.46960593600005],
            [-80.791025239999954, 35.469659935000038],
            [-80.791025239999954, 35.469712935000075],
            [-80.791032239999936, 35.469780935000074],
            [-80.791074239999944, 35.469935935000024],
            [-80.791110239999966, 35.470002935000025],
            [-80.791145238999945, 35.470049935000077],
            [-80.791206239999951, 35.470170935000056],
            [-80.791232238999953, 35.470248935000029],
            [-80.791363239999953, 35.470438935000061],
            [-80.791488239999978, 35.470595935000063],
            [-80.791513238999983, 35.470643935000055],
            [-80.791533239999978, 35.470701935000079],
            [-80.791579239999976, 35.470769935000078],
            [-80.791731238999944, 35.470963935000043],
            [-80.791777239999988, 35.471001935000061],
            [-80.79182123999999, 35.471049936000043],
            [-80.791852239999969, 35.471103935000031],
            [-80.791871239999978, 35.471194935000028],
            [-80.791900239999961, 35.471255935000045],
            [-80.79196823999996, 35.471364935000054],
            [-80.791970239999955, 35.471382936000055],
            [-80.791996239999946, 35.471582935000072],
            [-80.792015238999966, 35.471688935000032],
            [-80.792055239999968, 35.471765935000064],
            [-80.792086239999946, 35.471850936000067],
            [-80.792117239999982, 35.471917935000079],
            [-80.79215623999994, 35.471987936000062],
            [-80.792186239999978, 35.472087935000047],
            [-80.792195239999955, 35.472199936000038],
            [-80.792239239999958, 35.472375936000049],
            [-80.792283239999961, 35.472509936000051],
            [-80.792299240999967, 35.472577935000061],
            [-80.792316239999934, 35.472625936000043],
            [-80.792342240999972, 35.472680936000074],
            [-80.792364238999937, 35.47275793600005],
            [-80.792401239999947, 35.472890935000066],
            [-80.792421239999953, 35.473098936000042],
            [-80.79246724099994, 35.473241936000079],
            [-80.792484239999965, 35.473274936000053],
            [-80.792498240999976, 35.473336935000077],
            [-80.792500239999981, 35.473398935000034],
            [-80.79251524099999, 35.473479936000047],
            [-80.79258023999995, 35.473647936000077],
            [-80.792616238999983, 35.473718936000068],
            [-80.792659239999978, 35.473780936000026],
            [-80.792766240999981, 35.473968935000073],
            [-80.792818239999974, 35.474041935000059],
            [-80.792978239999968, 35.47420293600004],
            [-80.793015240999978, 35.474271936000036],
            [-80.793062240999973, 35.474332935000064],
            [-80.793158240999958, 35.474415936000071],
            [-80.79322123999998, 35.474491936000049],
            [-80.793245239999976, 35.474513935000061],
            [-80.793486239999936, 35.474879935000047],
            [-80.793646240999976, 35.475014936000036],
            [-80.793733240999984, 35.475107937000075],
            [-80.794012240999962, 35.475389936000056],
            [-80.794194241999946, 35.475589936000063],
            [-80.794299240999976, 35.475712937000026],
            [-80.794686240999965, 35.476105936000067],
            [-80.794859240999983, 35.476257936000025],
            [-80.794930240999975, 35.476363936000041],
            [-80.795092240999963, 35.47653293500008],
            [-80.79516124099996, 35.476621936000072],
            [-80.795234240999946, 35.476743936000048],
            [-80.795298240999955, 35.476822937000065],
            [-80.795355240999982, 35.476855937000039],
            [-80.795392240999945, 35.476884936000033],
            [-80.795448240999974, 35.476999937000073],
            [-80.795541240999967, 35.477116937000062],
            [-80.795632240999964, 35.477182936000077],
            [-80.795696241999963, 35.477258936000055],
            [-80.795766240999967, 35.477374936000047],
            [-80.79583224199996, 35.477464937000036],
            [-80.795932241999935, 35.477561937000075],
            [-80.796111241999938, 35.47775593700004],
            [-80.796160240999939, 35.477836937000063],
            [-80.796459241999969, 35.478222936000066],
            [-80.796553241999959, 35.478358936000063],
            [-80.796632241999987, 35.478440937000073],
            [-80.796683241999972, 35.478518937000047],
            [-80.796714240999961, 35.478576937000071],
            [-80.796782241999949, 35.478663936000032],
            [-80.796824241999957, 35.478726937000033],
            [-80.796964241999945, 35.47886993700007],
            [-80.797080241999936, 35.479023937000079],
            [-80.797227241999963, 35.479183938000062],
            [-80.797321241999953, 35.479311937000034],
            [-80.797432241999957, 35.479430937000075],
            [-80.797519242999954, 35.479504937000058],
            [-80.797570241999949, 35.47957593600006],
            [-80.797609241999965, 35.479660936000073],
            [-80.79764824099999, 35.47971493700004],
            [-80.797898241999974, 35.480023936000066],
            [-80.797935241999937, 35.480082937000077],
            [-80.798018241999955, 35.480188937000037],
            [-80.798109241999953, 35.480291937000061],
            [-80.798157241999945, 35.480338936000066],
            [-80.79820024199995, 35.480396936000034],
            [-80.798222241999952, 35.48045393700005],
            [-80.798265241999957, 35.480523937000044],
            [-80.798330241999963, 35.48059293600005],
            [-80.798359242999936, 35.480611936000059],
            [-80.798420242999953, 35.480700938000041],
            [-80.798634242999981, 35.480927937000047],
            [-80.798720242999934, 35.48104993700008],
            [-80.798839242999975, 35.481235936000076],
            [-80.798896241999955, 35.481302937000066],
            [-80.798954242999969, 35.481356937000044],
            [-80.799038242999984, 35.481459937000068],
            [-80.799108242999978, 35.481554937000055],
            [-80.799203242999965, 35.48165493700003],
            [-80.799307241999941, 35.481791937000025],
            [-80.799400243999969, 35.481928937000077],
            [-80.799647242999981, 35.482215937000035],
            [-80.800059242999964, 35.482729938000034],
            [-80.800322241999936, 35.48307993800006],
            [-80.800453243999982, 35.483223937000048],
            [-80.800558242999955, 35.483354938000048],
            [-80.800619242999971, 35.483422938000047],
            [-80.800685243999965, 35.483511938000049],
            [-80.800748242999987, 35.483571938000068],
            [-80.800921242999948, 35.483702938000079],
            [-80.800937243999954, 35.483765938000033],
            [-80.80099824399997, 35.483902938000028],
            [-80.801097242999958, 35.484046937000073],
            [-80.80125824299995, 35.484247937000077],
            [-80.801419243999987, 35.484492938000074],
            [-80.80152424399995, 35.48466793700004],
            [-80.801605243999973, 35.484781937000037],
            [-80.801619243999937, 35.48480293700004],
            [-80.801801242999943, 35.485097938000024],
            [-80.802000243999942, 35.485377938000056],
            [-80.80208324299997, 35.485519939000028],
            [-80.802175242999965, 35.485651938000046],
            [-80.802245243999948, 35.485760938000055],
            [-80.802328242999977, 35.485922938000044],
            [-80.802475243999936, 35.486162938000064],
            [-80.802581242999963, 35.486322938000058],
            [-80.80267224399995, 35.486473938000074],
            [-80.802764243999945, 35.486587938000071],
            [-80.802864243999977, 35.486697938000077],
            [-80.802959243999965, 35.486825938000038],
            [-80.80300224399997, 35.486950938000064],
            [-80.803029243999958, 35.487044937000064],
            [-80.803123243999948, 35.487153939000052],
            [-80.803205242999979, 35.487231938000036],
            [-80.803297244999953, 35.487331938000068],
            [-80.803353244999983, 35.48742893900004],
            [-80.803422243999989, 35.487562938000053],
            [-80.803488244999983, 35.487714938000067],
            [-80.803525243999957, 35.487883938000039],
            [-80.803579243999934, 35.48808593800004],
            [-80.803623243999937, 35.48822793800008],
            [-80.803668243999937, 35.488295938000078],
            [-80.803754244999936, 35.488453938000077],
            [-80.80382024499994, 35.488599939000039],
            [-80.80395824499999, 35.488946938000026],
            [-80.80397824399995, 35.489006939000035],
            [-80.804004244999987, 35.489047939000045],
            [-80.804086243999961, 35.489227939000045],
            [-80.804119244999981, 35.489285939000069],
            [-80.804153243999963, 35.489385938000055],
            [-80.804185243999939, 35.48952293900004],
            [-80.804247244999942, 35.489597939000078],
            [-80.80427324499999, 35.489639938000039],
            [-80.80428524499996, 35.489689938000026],
            [-80.804287244999955, 35.489746939000042],
            [-80.804312244999949, 35.489806939000061],
            [-80.804347244999974, 35.489850939000064],
            [-80.804381243999956, 35.489885939000033],
            [-80.80442824499994, 35.49001393900005],
            [-80.804433245999974, 35.490080939000052],
            [-80.804457243999934, 35.490149938000059],
            [-80.80450624499997, 35.490214939000055],
            [-80.804633243999945, 35.490516938000042],
            [-80.80470924399998, 35.490743939000026],
            [-80.804767244999937, 35.490876939000032],
            [-80.804812244999937, 35.490954939000062],
            [-80.804833244999941, 35.491073939000046],
            [-80.804911244999971, 35.49137293900003],
            [-80.804985244999955, 35.491606939000064],
            [-80.805353244999935, 35.491740939000067],
            [-80.805619244999946, 35.491815939000048],
            [-80.805830245999971, 35.491881938000063],
            [-80.805975244999956, 35.491910939000036],
            [-80.806091244999948, 35.49196093900008],
            [-80.806247244999952, 35.492015939000055],
            [-80.806491244999961, 35.492082939000056],
            [-80.806727244999934, 35.492132940000033],
            [-80.806978245999971, 35.492214938000075],
            [-80.807275245999961, 35.492267940000033],
            [-80.807374245999938, 35.492275939000024],
            [-80.807497245999969, 35.492278939000073],
            [-80.80761324599996, 35.492309939000052],
            [-80.807704245999957, 35.492338939000035],
            [-80.807901245999972, 35.492333939000048],
            [-80.807926244999976, 35.492334939000045],
            [-80.806158245999939, 35.49425093900004],
            [-80.80230024399998, 35.498463941000068],
            [-80.803292244999966, 35.499434941000061],
            [-80.804014244999962, 35.500142941000036],
            [-80.80491924599994, 35.500891941000077],
            [-80.805428244999973, 35.501505941000062],
            [-80.808554246999961, 35.505273942000031],
            [-80.80847524699999, 35.505324942000072],
            [-80.808023246999937, 35.505694942000048],
            [-80.807775246999938, 35.505882942000028],
            [-80.807627246999971, 35.505965942000046],
            [-80.80745924699994, 35.506035943000029],
            [-80.807225245999973, 35.50610994200008],
            [-80.806913245999965, 35.506168942000045],
            [-80.806557246999944, 35.50620394200007],
            [-80.806181245999937, 35.506216942000037],
            [-80.805926245999956, 35.506214942000042],
            [-80.805668244999936, 35.506195942000033],
            [-80.805352245999984, 35.506147942000041],
            [-80.804925244999936, 35.506065942000077],
            [-80.804550245999962, 35.506024942000067],
            [-80.804311245999941, 35.50601994200008],
            [-80.804157245999988, 35.506028942000057],
            [-80.804010245999962, 35.50605394300004],
            [-80.803817244999948, 35.506088943000066],
            [-80.802079244999959, 35.506557943000075],
            [-80.801829244999965, 35.506630943000062],
            [-80.801677244999951, 35.506690942000034],
            [-80.801566244999947, 35.50676294200008],
            [-80.80140724499995, 35.506926943000053],
            [-80.801276243999951, 35.507104943000058],
            [-80.801111244999959, 35.50709394200004],
            [-80.79665724299997, 35.50709394200004],
            [-80.796482242999957, 35.507094942000037],
            [-80.796621242999947, 35.507165943000075],
            [-80.796753242999955, 35.507216942000071],
            [-80.797104242999978, 35.507364943000027],
            [-80.797357242999965, 35.507527943000071],
            [-80.797564243999943, 35.507721943000035],
            [-80.797841243999983, 35.508089943000073],
            [-80.798148243999947, 35.508479943000054],
            [-80.798549243999958, 35.509105943000066],
            [-80.798702243999969, 35.509421943000063],
            [-80.798794243999964, 35.509730943000079],
            [-80.798850243999937, 35.510104943000044],
            [-80.798847243999944, 35.510478943000066],
            [-80.798805243999936, 35.511800943000026],
            [-80.798752243999957, 35.513496944000053],
            [-80.798738244999981, 35.514567944000078],
            [-80.798761243999934, 35.514918944000044],
            [-80.798763243999986, 35.514949945000069],
            [-80.798973243999967, 35.515851944000076],
            [-80.799312244999953, 35.517124945000035],
            [-80.799357244999953, 35.51738494500006],
            [-80.799354244999961, 35.517533944000036],
            [-80.799353243999974, 35.517647944000032],
            [-80.799301244999981, 35.51790694500005],
            [-80.799245244999952, 35.518088945000045],
            [-80.798966244999974, 35.51901394500004]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 68,
        "SolutionID": "PI-29:J",
        "Shape_Length": 1.0110457878193357,
        "Shape_Area": 0.026569017362350882
      }
    },
    {
      "type": "Feature",
      "id": 69,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.798966244999974, 35.51901394500004],
            [-80.798873244999982, 35.519433946000049],
            [-80.798840243999962, 35.519757946000027],
            [-80.798706244999948, 35.520554946000061],
            [-80.799703244999989, 35.520463946000064],
            [-80.801175244999968, 35.520336945000054],
            [-80.80181924599998, 35.520294945000046],
            [-80.805995245999952, 35.520067945000051],
            [-80.807027245999961, 35.519986945000028],
            [-80.80709124699996, 35.519975945000056],
            [-80.807421246999979, 35.519920945000024],
            [-80.807763247999958, 35.519737945000031],
            [-80.808489246999955, 35.519147945000043],
            [-80.808547247999968, 35.519100945000048],
            [-80.80885524699994, 35.518818945000078],
            [-80.809165247999942, 35.518623945000058],
            [-80.809460247999937, 35.518486944000074],
            [-80.809869247999984, 35.518408945000033],
            [-80.810281248999956, 35.51842894400005],
            [-80.810767247999934, 35.518520944000045],
            [-80.811667248999981, 35.518763945000046],
            [-80.814003248999938, 35.519420945000036],
            [-80.814495249999936, 35.519500944000072],
            [-80.814795248999985, 35.519507945000043],
            [-80.815172249999989, 35.519436944000063],
            [-80.815685249999945, 35.519295945000067],
            [-80.816783249999958, 35.518993944000044],
            [-80.817023249999977, 35.51892794500003],
            [-80.81785924999997, 35.518686944000024],
            [-80.818481249999934, 35.518507944000078],
            [-80.818760250999958, 35.518427944000052],
            [-80.819602250999935, 35.518216944000073],
            [-80.821766250999985, 35.517663944000049],
            [-80.822549252999977, 35.517477944000063],
            [-80.824548251999943, 35.517015944000036],
            [-80.825735251999959, 35.516741944000046],
            [-80.825831251999944, 35.516719944000045],
            [-80.826136252999959, 35.51663594300004],
            [-80.82636125199997, 35.516606944000046],
            [-80.826530251999941, 35.516599944000063],
            [-80.826908252999942, 35.516584944000044],
            [-80.827313252999943, 35.516644944000063],
            [-80.827880252999989, 35.516794944000026],
            [-80.828545252999959, 35.516955944000074],
            [-80.829136253999934, 35.517071944000065],
            [-80.82962925399994, 35.517136943000025],
            [-80.829984253999953, 35.517164944000058],
            [-80.831104253999968, 35.517209943000069],
            [-80.833114253999952, 35.517290943000035],
            [-80.833574253999984, 35.517308944000035],
            [-80.83406325499999, 35.517326944000047],
            [-80.834395254999947, 35.51733794300003],
            [-80.836026255999968, 35.517396943000051],
            [-80.836074254999971, 35.517398943000046],
            [-80.836785255999985, 35.517424944000027],
            [-80.837034256999971, 35.517380944000024],
            [-80.83729025599996, 35.517304944000045],
            [-80.837545256999988, 35.517168944000048],
            [-80.837822255999981, 35.516953943000033],
            [-80.838061256999936, 35.516449944000044],
            [-80.838135255999987, 35.516318943000044],
            [-80.838254255999971, 35.516110943000058],
            [-80.838415255999962, 35.515976943000055],
            [-80.838628255999936, 35.515874943000028],
            [-80.838996255999973, 35.515769942000077],
            [-80.839510255999983, 35.515642943000046],
            [-80.838939255999946, 35.51412494300007],
            [-80.838588256999969, 35.513191943000038],
            [-80.838450255999987, 35.512697942000045],
            [-80.838407254999936, 35.512475942000037],
            [-80.838368255999967, 35.512195942000062],
            [-80.838377255999944, 35.511765942000068],
            [-80.838417255999957, 35.511541942000065],
            [-80.83845325599998, 35.511340942000061],
            [-80.838595256999952, 35.510926942000026],
            [-80.838801255999954, 35.51053094100007],
            [-80.839047255999958, 35.510139941000034],
            [-80.839295255999957, 35.509867941000039],
            [-80.839820256999985, 35.509906941000054],
            [-80.840497255999935, 35.509956942000031],
            [-80.841372256999989, 35.51002094100005],
            [-80.845313257999976, 35.510307942000054],
            [-80.845850258999974, 35.510346942000069],
            [-80.852927260999934, 35.510861942000076],
            [-80.855593261999957, 35.511055941000052],
            [-80.855796261999956, 35.511070941000071],
            [-80.859166261999974, 35.511315941000078],
            [-80.860223261999977, 35.511392941000054],
            [-80.861301261999984, 35.511481941000056],
            [-80.861958263999952, 35.51153594200008],
            [-80.862105262999989, 35.51154794100006],
            [-80.865199263999955, 35.511773941000058],
            [-80.865529263999974, 35.511796941000057],
            [-80.865653263999945, 35.511805942000024],
            [-80.865885263999985, 35.511822941000048],
            [-80.866040263999935, 35.511834941000075],
            [-80.874385267999969, 35.512475940000058],
            [-80.874799266999958, 35.512507941000024],
            [-80.890049270999953, 35.513657941000076],
            [-80.890675271999953, 35.513703941000074],
            [-80.906065276999982, 35.514832941000066],
            [-80.909828276999974, 35.515109940000059],
            [-80.91006927799998, 35.514932940000051],
            [-80.910322277999967, 35.514745940000068],
            [-80.91821127999998, 35.508934939000028],
            [-80.930149281999945, 35.500141937000024],
            [-80.936342284999967, 35.49557993600007],
            [-80.940500284999985, 35.492517935000023],
            [-80.947033286999954, 35.487705933000029],
            [-80.946855286999948, 35.487238932000025],
            [-80.946727286999987, 35.486855933000072],
            [-80.946686286999977, 35.486730933000047],
            [-80.946508286999972, 35.486263932000043],
            [-80.946316286999945, 35.485984933000054],
            [-80.946191286999976, 35.485802933000059],
            [-80.945935286999941, 35.485385933000032],
            [-80.94582328599995, 35.48521093200003],
            [-80.94581128599998, 35.485192932000075],
            [-80.94580328699999, 35.485180932000048],
            [-80.945789286999968, 35.485158933000037],
            [-80.945658286999958, 35.484955933000037],
            [-80.945618285999956, 35.484891932000039],
            [-80.945589285999972, 35.484846932000039],
            [-80.945401286999981, 35.484558933000073],
            [-80.945362285999977, 35.484499932000062],
            [-80.945208286999957, 35.484193933000029],
            [-80.945204285999978, 35.484186932000057],
            [-80.945199285999934, 35.484175932000028],
            [-80.945184285999972, 35.484143932000052],
            [-80.945192286999941, 35.484077932000048],
            [-80.945214285999953, 35.483908932000077],
            [-80.945237285999951, 35.48383593300008],
            [-80.945368286999951, 35.483419931000071],
            [-80.945402286999979, 35.483381932000043],
            [-80.945769286999962, 35.482967932000065],
            [-80.946210285999939, 35.482554933000074],
            [-80.946340285999952, 35.482407932000058],
            [-80.946407285999953, 35.482331931000033],
            [-80.946582285999966, 35.482134931000076],
            [-80.94691428699997, 35.481738932000042],
            [-80.947296286999972, 35.481407931000035],
            [-80.947639286999959, 35.480955932000029],
            [-80.947787287999972, 35.480790931000058],
            [-80.947864286999959, 35.480704932000037],
            [-80.947880287999965, 35.48066693100003],
            [-80.947884287999955, 35.480657931000053],
            [-80.948068286999955, 35.480228931000056],
            [-80.948146286999986, 35.48005093200004],
            [-80.948204286999953, 35.479800932000046],
            [-80.948222286999965, 35.479139932000066],
            [-80.94825028799994, 35.478720931000055],
            [-80.948357286999965, 35.478333931000066],
            [-80.948434285999951, 35.478123931000027],
            [-80.948551286999987, 35.477727931000061],
            [-80.948648286999969, 35.477268931000026],
            [-80.948793286999944, 35.476630930000056],
            [-80.949075286999971, 35.475936930000046],
            [-80.949289286999942, 35.475299931000052],
            [-80.949582287999988, 35.474726930000031],
            [-80.949874286999943, 35.474080930000071],
            [-80.950109286999975, 35.473780930000032],
            [-80.950285287999975, 35.473555930000032],
            [-80.950493286999972, 35.473096930000054],
            [-80.95065528799995, 35.472740930000043],
            [-80.950918287999968, 35.472078930000066],
            [-80.951179286999945, 35.471523929000057],
            [-80.951211287999968, 35.471456930000045],
            [-80.95142528699995, 35.470899929000041],
            [-80.951629287999936, 35.470222929000045],
            [-80.951735286999963, 35.46961692900004],
            [-80.951733286999968, 35.469044928000073],
            [-80.951750286999982, 35.46845692900007],
            [-80.951670287999946, 35.468020929000033],
            [-80.951600286999962, 35.467617929000028],
            [-80.951500286999988, 35.467045928000061],
            [-80.95133128699996, 35.466537929000026],
            [-80.951313286999948, 35.466435928000067],
            [-80.951303286999973, 35.466380929000024],
            [-80.951251286999934, 35.466094928000075],
            [-80.951024287999985, 35.465700929000036],
            [-80.950726286999952, 35.465391929000077],
            [-80.950521286999958, 35.46503192800003],
            [-80.950176286999977, 35.464750928000058],
            [-80.949861286999976, 35.464452928000071],
            [-80.949762285999952, 35.464375928000038],
            [-80.949660286999972, 35.46429592800007],
            [-80.949520286999984, 35.464186928000061],
            [-80.949398285999962, 35.464091927000027],
            [-80.94905328699997, 35.463849928000059],
            [-80.948600285999987, 35.463472928000044],
            [-80.948148285999935, 35.463175927000066],
            [-80.948098285999947, 35.463142928000025],
            [-80.947537284999953, 35.462740928000073],
            [-80.947327285999961, 35.462593928000047],
            [-80.947320285999979, 35.46258892700007],
            [-80.946917284999984, 35.462306927000043],
            [-80.946596284999941, 35.462091927000074],
            [-80.946590285999946, 35.462087927000027],
            [-80.946585284999969, 35.462084927000035],
            [-80.946561284999973, 35.462068927000075],
            [-80.946551284999941, 35.462061928000026],
            [-80.946541285999956, 35.462054927000054],
            [-80.946224284999971, 35.461842927000077],
            [-80.946149285999979, 35.461792927000033],
            [-80.94572528499998, 35.461373928000057],
            [-80.945418285999949, 35.461127927000064],
            [-80.945184284999982, 35.460939928000073],
            [-80.944780284999979, 35.460642927000038],
            [-80.944396284999982, 35.460352927000031],
            [-80.944012284999985, 35.460144928000034],
            [-80.943746283999985, 35.459870927000054],
            [-80.943608284999982, 35.459753927000065],
            [-80.943598283999961, 35.459744927000031],
            [-80.943591284999968, 35.459738927000046],
            [-80.943539283999939, 35.459694928000033],
            [-80.943523284999969, 35.459680927000079],
            [-80.943515284999989, 35.459674927000037],
            [-80.943490284999939, 35.459653927000033],
            [-80.943466283999953, 35.45962492700005],
            [-80.94345928499996, 35.45961692700007],
            [-80.943450284999983, 35.459606927000038],
            [-80.943254283999977, 35.459379927000043],
            [-80.943036284999948, 35.45905792800005],
            [-80.943010284999957, 35.458990927000059],
            [-80.942868283999985, 35.458630926000069],
            [-80.942719284999953, 35.458211927000036],
            [-80.942598284999974, 35.457959927000047],
            [-80.942492283999968, 35.457734927000047],
            [-80.942490283999973, 35.457715927000038],
            [-80.942488283999978, 35.457693926000047],
            [-80.942488283999978, 35.457687927000052],
            [-80.942487283999981, 35.457675927000025],
            [-80.942420284999969, 35.456826927000066],
            [-80.942335283999967, 35.456739926000068],
            [-80.942332283999974, 35.456736927000065],
            [-80.942302283999936, 35.45670592700003],
            [-80.942300283999941, 35.456672927000056],
            [-80.942299283999944, 35.456598927000073],
            [-80.942291282999975, 35.456317926000054],
            [-80.942337282999972, 35.45556892500008],
            [-80.942400283999973, 35.455331926000042],
            [-80.94247328299997, 35.455050926000069],
            [-80.94251028299999, 35.454389926000033],
            [-80.942616283999939, 35.45383492600007],
            [-80.94274328399996, 35.453423925000038],
            [-80.943016283999953, 35.453003925000075],
            [-80.943350283999962, 35.45263092600004],
            [-80.94376028399995, 35.452267926000047],
            [-80.944191284999988, 35.451888925000048],
            [-80.944661283999949, 35.45162892500008],
            [-80.944954284999937, 35.451265926000076],
            [-80.945228283999938, 35.450885926000069],
            [-80.945237283999973, 35.450871925000058],
            [-80.945240284999954, 35.450866925000071],
            [-80.945246284999939, 35.450857925000037],
            [-80.945391283999982, 35.450636925000026],
            [-80.945595284999968, 35.450325925000072],
            [-80.94559828499996, 35.450320926000074],
            [-80.945619283999974, 35.450288925000052],
            [-80.94563128499999, 35.450273925000033],
            [-80.94563928499997, 35.450263925000058],
            [-80.945647283999961, 35.450254925000024],
            [-80.945654283999943, 35.450246925000044],
            [-80.94588328399999, 35.449973924000062],
            [-80.945906284999978, 35.449954925000043],
            [-80.946177284999976, 35.44973092500004],
            [-80.946412283999962, 35.449560926000061],
            [-80.946598284999936, 35.449455925000052],
            [-80.947269284999948, 35.449270925000064],
            [-80.947275284999989, 35.449268925000069],
            [-80.947297284999934, 35.449262925000028],
            [-80.947334284999954, 35.449252925000053],
            [-80.947380285999941, 35.449236925000037],
            [-80.947893284999964, 35.449057924000044],
            [-80.948451285999965, 35.44875892500005],
            [-80.949040284999967, 35.448498925000024],
            [-80.949568284999941, 35.448181925000029],
            [-80.949647285999959, 35.448134925000033],
            [-80.950186286999951, 35.447665925000024],
            [-80.950586284999986, 35.447108924000077],
            [-80.950977285999954, 35.44646292300007],
            [-80.951396286999966, 35.445743924000055],
            [-80.95169928699994, 35.44528392400008],
            [-80.951953285999934, 35.444774924000058],
            [-80.952216286999942, 35.444266923000043],
            [-80.952509285999952, 35.443652923000059],
            [-80.952713285999948, 35.443152923000071],
            [-80.95282928599994, 35.442692923000038],
            [-80.952892285999951, 35.442423923000035],
            [-80.952926285999979, 35.442281923000053],
            [-80.952991286999975, 35.441677923000043],
            [-80.952992286999972, 35.441664922000029],
            [-80.95299328699997, 35.441659923000032],
            [-80.953002285999958, 35.441579923000063],
            [-80.953070285999956, 35.44128192200003],
            [-80.95321028699999, 35.441080922000026],
            [-80.953441285999986, 35.44074792300006],
            [-80.953793286999939, 35.440247922000026],
            [-80.954458287999955, 35.439552923000065],
            [-80.955221285999983, 35.438889922000044],
            [-80.956033287999958, 35.438081922000038],
            [-80.956658287999971, 35.437079922000066],
            [-80.956951287999971, 35.436474922000059],
            [-80.957084287999976, 35.436209922000046],
            [-80.957272286999967, 35.435836922000078],
            [-80.957409286999962, 35.435633922000079],
            [-80.957641286999944, 35.435292921000041],
            [-80.95775828699999, 35.435015921000058],
            [-80.957875286999979, 35.434738922000065],
            [-80.957994286999963, 35.434456922000038],
            [-80.958306286999971, 35.433802922000041],
            [-80.958471287999942, 35.433318920000033],
            [-80.958694287999947, 35.432640921000029],
            [-80.958702287999984, 35.432140921000041],
            [-80.958684286999983, 35.432013921000078],
            [-80.958639286999983, 35.431703920000075],
            [-80.958493287999943, 35.431270920000031],
            [-80.958177286999955, 35.430625920000068],
            [-80.957732286999942, 35.429933921000043],
            [-80.95724828699997, 35.429128920000039],
            [-80.957071286999962, 35.428785920000053],
            [-80.956985285999963, 35.428616919000035],
            [-80.956962286999953, 35.428572921000068],
            [-80.956942285999958, 35.428532920000066],
            [-80.95672428599994, 35.427839919000064],
            [-80.95667128599996, 35.427685919000055],
            [-80.956659286999979, 35.427651920000073],
            [-80.956653286999938, 35.42763492000006],
            [-80.956615286999977, 35.427525920000051],
            [-80.956612286999984, 35.427516920000073],
            [-80.956561285999953, 35.427376920000029],
            [-80.956559286999948, 35.427371919000052],
            [-80.956551285999979, 35.427349919000051],
            [-80.956549285999984, 35.427342920000058],
            [-80.956537285999957, 35.427310919000035],
            [-80.956529286999967, 35.427289920000078],
            [-80.956426286999942, 35.427009920000046],
            [-80.956287286999952, 35.426574920000064],
            [-80.956000285999949, 35.425776919000043],
            [-80.955850286999976, 35.425180920000059],
            [-80.955702285999962, 35.424440919000062],
            [-80.955551286999935, 35.423713919000079],
            [-80.955534285999988, 35.423625919000074],
            [-80.95552928699999, 35.423599919000026],
            [-80.955527285999949, 35.423588919000053],
            [-80.955525286999944, 35.423576919000027],
            [-80.955524285999957, 35.423570919000042],
            [-80.955522285999962, 35.423564919000057],
            [-80.955520285999967, 35.423553919000028],
            [-80.955472286999964, 35.423313919000066],
            [-80.955400285999985, 35.422900919000028],
            [-80.955399285999988, 35.422892919000049],
            [-80.955397284999947, 35.422881919000076],
            [-80.955351285999939, 35.422617918000071],
            [-80.955317286999957, 35.42234791900006],
            [-80.955315286999962, 35.422331919000044],
            [-80.955314285999975, 35.42232191800008],
            [-80.955313286999967, 35.422311919000038],
            [-80.95531228599998, 35.422301919000063],
            [-80.955311286999972, 35.422296919000075],
            [-80.95527928599995, 35.422052918000077],
            [-80.955278286999942, 35.422045918000038],
            [-80.955269285999975, 35.421971919000043],
            [-80.955211286999941, 35.421453919000044],
            [-80.955176285999983, 35.420577919000038],
            [-80.955273285999965, 35.420053919000054],
            [-80.955344284999967, 35.419803918000071],
            [-80.955428285999972, 35.419512918000066],
            [-80.955642285999943, 35.418923918000075],
            [-80.955993285999966, 35.418213918000049],
            [-80.956480285999987, 35.417373917000077],
            [-80.957086286999981, 35.416469917000029],
            [-80.957788286999971, 35.415209917000027],
            [-80.958383285999957, 35.41416891700004],
            [-80.958587285999954, 35.413635918000068],
            [-80.958772285999942, 35.413215917000059],
            [-80.958765286999949, 35.412120916000049],
            [-80.958726286999934, 35.411345916000073],
            [-80.95860728599996, 35.410781916000076],
            [-80.958496285999956, 35.410208916000045],
            [-80.958326285999988, 35.40946791600004],
            [-80.95816528499995, 35.408558916000061],
            [-80.957878285999982, 35.407662916000049],
            [-80.957559285999935, 35.406227916000034],
            [-80.95723028499998, 35.404873915000053],
            [-80.956804284999976, 35.403658915000051],
            [-80.95625128599994, 35.402619915000059],
            [-80.95575628499995, 35.401628914000071],
            [-80.955061284999942, 35.401648914000077],
            [-80.954810284999951, 35.401687914000036],
            [-80.954631283999959, 35.401585915000055],
            [-80.954474284999947, 35.401423914000077],
            [-80.954254283999944, 35.401288915000066],
            [-80.954171284999973, 35.401191914000037],
            [-80.954175284999963, 35.40108491400008],
            [-80.953332283999941, 35.401135915000054],
            [-80.953303284999947, 35.401172914000028],
            [-80.953420283999947, 35.40137491400003],
            [-80.953537283999935, 35.40155091400004],
            [-80.953533283999946, 35.401728914000046],
            [-80.953440284999942, 35.401861914000051],
            [-80.953305284999942, 35.401942915000063],
            [-80.953161283999975, 35.401938914000027],
            [-80.953045283999984, 35.401828914000077],
            [-80.95293828299998, 35.401704914000049],
            [-80.952800283999977, 35.401667914000029],
            [-80.952519283999948, 35.401672915000063],
            [-80.952114283999947, 35.401533914000026],
            [-80.951774282999963, 35.40140791500005],
            [-80.951604283999984, 35.401390915000036],
            [-80.951535282999942, 35.401302914000041],
            [-80.951111282999989, 35.401339914000062],
            [-80.950734283999964, 35.40141191500004],
            [-80.95126828399998, 35.402225914000041],
            [-80.951484283999946, 35.402547914000024],
            [-80.951823283999943, 35.403055914000049],
            [-80.952023283999949, 35.403670915000077],
            [-80.952001284999938, 35.404212915000073],
            [-80.953089283999986, 35.404194915000062],
            [-80.953223284999979, 35.403543914000068],
            [-80.953445284999987, 35.403199915000073],
            [-80.95375628499994, 35.403218915000025],
            [-80.953979283999956, 35.404156915000044],
            [-80.95464528499997, 35.405494915000077],
            [-80.955096284999968, 35.405954915000052],
            [-80.955489285999988, 35.40634491600008],
            [-80.95571128499995, 35.407086916000026],
            [-80.955578284999945, 35.407285915000045],
            [-80.955113284999982, 35.407120915000064],
            [-80.954602284999964, 35.406795915000032],
            [-80.954224284999952, 35.406777916000067],
            [-80.95442428399997, 35.407464916000038],
            [-80.954670284999963, 35.40829691600004],
            [-80.954603284999962, 35.409055916000057],
            [-80.954137283999955, 35.409073916000068],
            [-80.954096283999945, 35.409073916000068],
            [-80.954075284999988, 35.408987916000058],
            [-80.954071284999941, 35.408831916000054],
            [-80.954159284999946, 35.408540916000049],
            [-80.954232283999943, 35.408352917000059],
            [-80.954298284999936, 35.408131916000059],
            [-80.954300285999977, 35.408058916000073],
            [-80.954263284999968, 35.407939916000032],
            [-80.953938284999936, 35.407603916000028],
            [-80.953830284999981, 35.407411916000058],
            [-80.953706284999953, 35.407190915000058],
            [-80.953637283999967, 35.407075915000064],
            [-80.953499283999975, 35.406945916000041],
            [-80.953355284999986, 35.406916915000068],
            [-80.953213283999958, 35.40694991600003],
            [-80.953102283999954, 35.40705491600005],
            [-80.953049284999963, 35.407180916000073],
            [-80.953032284999949, 35.407280916000047],
            [-80.953016283999943, 35.407475916000067],
            [-80.952931283999987, 35.407672916000024],
            [-80.952679284999988, 35.40797791600005],
            [-80.952303283999981, 35.408361916000047],
            [-80.95194328499997, 35.408709915000031],
            [-80.951690282999948, 35.408963916000062],
            [-80.951630284999965, 35.409073916000068],
            [-80.951588284999957, 35.409153917000026],
            [-80.951576283999941, 35.409233916000062],
            [-80.951563283999974, 35.409430916000076],
            [-80.951548283999955, 35.40960291600004],
            [-80.951367283999957, 35.40958691600008],
            [-80.951186283999959, 35.409626916000036],
            [-80.950949282999943, 35.409640917000047],
            [-80.950620282999978, 35.409695916000032],
            [-80.950526283999977, 35.409751917000051],
            [-80.950292282999953, 35.409867917000042],
            [-80.950093282999944, 35.409965916000033],
            [-80.94993728299994, 35.410004916000048],
            [-80.949838282999963, 35.410025916000052],
            [-80.94964028399994, 35.410045916000058],
            [-80.949409282999966, 35.410087917000055],
            [-80.949298282999962, 35.410145917000079],
            [-80.949184282999965, 35.41023991600008],
            [-80.949113283999964, 35.410320917000035],
            [-80.949032282999951, 35.410526916000038],
            [-80.94895728299997, 35.410718916000064],
            [-80.948874283999942, 35.41075791600008],
            [-80.948686283999962, 35.410771917000034],
            [-80.948426282999947, 35.410767917000044],
            [-80.948324282999977, 35.410766917000046],
            [-80.94816528399997, 35.410737917000063],
            [-80.948081282999965, 35.41068991700007],
            [-80.94793628299999, 35.410666917000071],
            [-80.947740282999973, 35.410658916000045],
            [-80.947741282999971, 35.410670917000061],
            [-80.947734282999988, 35.410679917000039],
            [-80.947697281999979, 35.410726917000034],
            [-80.947668283999974, 35.410743916000058],
            [-80.947617282999943, 35.410774916000037],
            [-80.947533282999984, 35.410784917000058],
            [-80.947502282999949, 35.410779916000024],
            [-80.947414282999944, 35.410765917000049],
            [-80.947335282999973, 35.41075091700003],
            [-80.947311281999987, 35.410749917000032],
            [-80.946794282999974, 35.41157191700006],
            [-80.946705282999972, 35.411685917000057],
            [-80.946683281999981, 35.411708918000045],
            [-80.946662281999977, 35.411725918000059],
            [-80.946566282999981, 35.411797917000058],
            [-80.946410281999988, 35.41189891700003],
            [-80.945927281999957, 35.412185917000045],
            [-80.945454281999957, 35.412505918000079],
            [-80.945140281999954, 35.412728917000038],
            [-80.944968281999934, 35.412858917000051],
            [-80.94484128199997, 35.412966918000052],
            [-80.944735282999943, 35.413063918000034],
            [-80.944623281999952, 35.413179917000036],
            [-80.944546282999966, 35.413276917000076],
            [-80.944230281999978, 35.413761917000045],
            [-80.943943281999964, 35.414229918000046],
            [-80.943735281999977, 35.41452891800003],
            [-80.943666281999981, 35.414601917000027],
            [-80.943517281999959, 35.414722918000052],
            [-80.943431281999949, 35.414782918000071],
            [-80.942884281999966, 35.41510691700006],
            [-80.942619281999953, 35.415263918000051],
            [-80.94207628099997, 35.415528918000064],
            [-80.941616280999938, 35.415762918000041],
            [-80.94152128099995, 35.415806918000044],
            [-80.941355280999971, 35.415870917000063],
            [-80.941167280999935, 35.415926917000036],
            [-80.940585280999983, 35.416075918000047],
            [-80.940221281999982, 35.416149918000031],
            [-80.940136281999969, 35.416162918000055],
            [-80.939187280999988, 35.416298918000052],
            [-80.939048280999941, 35.416316918000064],
            [-80.937081279999973, 35.416578919000074],
            [-80.936860279999962, 35.416589918000057],
            [-80.93671427999999, 35.416585918000067],
            [-80.935620279999966, 35.41647991800005],
            [-80.935536279999951, 35.416459918000044],
            [-80.935464279999962, 35.416425918000073],
            [-80.935304279999968, 35.416317918000061],
            [-80.935120278999989, 35.416178918000071],
            [-80.934111279999968, 35.415419918000055],
            [-80.934045277999985, 35.415374918000055],
            [-80.933935278999968, 35.41532391800007],
            [-80.93388927899997, 35.415307918000053],
            [-80.933783279999943, 35.415283918000057],
            [-80.933682279999971, 35.415283918000057],
            [-80.933581278999952, 35.415300918000071],
            [-80.933449278999944, 35.415332918000047],
            [-80.933249278999938, 35.415365918000077],
            [-80.93306427999994, 35.415376918000049],
            [-80.932980278999935, 35.41537691700006],
            [-80.932811278999964, 35.415365918000077],
            [-80.932718278999971, 35.415351918000056],
            [-80.932623278999984, 35.415326918000062],
            [-80.932509278999987, 35.415286918000049],
            [-80.932406277999974, 35.415236918000062],
            [-80.932340278999959, 35.415194918000054],
            [-80.932258278999939, 35.415133918000038],
            [-80.932191278999937, 35.415073918000076],
            [-80.932046278999962, 35.41490391800005],
            [-80.931721277999941, 35.414463918000024],
            [-80.931645277999962, 35.414370918000031],
            [-80.931524278999973, 35.414248918000055],
            [-80.931305278999957, 35.414073918000042],
            [-80.930977277999943, 35.413839917000075],
            [-80.930727277999949, 35.413680917000079],
            [-80.930622277999987, 35.41360591800003],
            [-80.930576276999943, 35.413562917000036],
            [-80.930547277999949, 35.413527918000057],
            [-80.930511277999983, 35.413469918000033],
            [-80.930448277999972, 35.413320918000068],
            [-80.930342277999955, 35.41306591700004],
            [-80.930173277999984, 35.412732917000028],
            [-80.930091277999963, 35.412610918000041],
            [-80.930016278999972, 35.412520918000041],
            [-80.92992027799994, 35.412425918000054],
            [-80.929861277999976, 35.412374917000079],
            [-80.929675276999944, 35.41223691700003],
            [-80.929076277999968, 35.411832918000073],
            [-80.928851277999968, 35.411685918000046],
            [-80.928431276999959, 35.411426918000075],
            [-80.928348276999941, 35.411384918000067],
            [-80.927693276999946, 35.411085917000037],
            [-80.926041276999968, 35.410382917000049],
            [-80.925822276999952, 35.410306917000071],
            [-80.926424275999977, 35.408859917000029],
            [-80.926496275999966, 35.408634917000029],
            [-80.926520275999962, 35.408518916000048],
            [-80.926535275999981, 35.40841591700007],
            [-80.926541276999956, 35.408320917000026],
            [-80.926540276999958, 35.408219916000064],
            [-80.926523275999955, 35.408022917000039],
            [-80.926491276999968, 35.407850916000029],
            [-80.926451276999956, 35.407700916000067],
            [-80.926364276999948, 35.407439917000033],
            [-80.92463027499997, 35.402347917000043],
            [-80.92421727499999, 35.401157916000045],
            [-80.923466274999953, 35.398954915000047],
            [-80.923314275999985, 35.398530915000038],
            [-80.923168274999966, 35.398098914000059],
            [-80.923146274999965, 35.398009915000046],
            [-80.923105274999955, 35.397767915000031],
            [-80.923093274999985, 35.397559914000055],
            [-80.923101274999965, 35.397335915000042],
            [-80.923137274999988, 35.397060915000054],
            [-80.923173274999954, 35.396922915000061],
            [-80.92323027499998, 35.396767915000055],
            [-80.923505274999968, 35.396129914000028],
            [-80.923627275999934, 35.39585891400003],
            [-80.923777274999964, 35.395498914000029],
            [-80.923840274999975, 35.395319914000027],
            [-80.923873274999949, 35.39519791400005],
            [-80.923885274999975, 35.395113914000035],
            [-80.923889274999965, 35.39493591400003],
            [-80.92387927599998, 35.39478891400006],
            [-80.92384627499996, 35.394645913000033],
            [-80.923791274999985, 35.394474914000057],
            [-80.923729274999971, 35.394328915000074],
            [-80.923571273999983, 35.394009914000037],
            [-80.922897274999968, 35.392777914000078],
            [-80.922807274999968, 35.39261291400004],
            [-80.922661274999939, 35.39237191400008],
            [-80.922584273999973, 35.392267913000069],
            [-80.922412274999942, 35.392079914000078],
            [-80.921881273999986, 35.391559914000027],
            [-80.921401274999937, 35.391080913000053],
            [-80.921291273999941, 35.390955914000074],
            [-80.921181273999935, 35.39080791300006],
            [-80.921090274999983, 35.390655913000046],
            [-80.921007273999976, 35.390485914000067],
            [-80.920961273999978, 35.390360913000052],
            [-80.920926273999953, 35.39020791300004],
            [-80.920904272999962, 35.39002391300005],
            [-80.920915273999981, 35.389792913000065],
            [-80.920932274999984, 35.389668913000037],
            [-80.920944273999964, 35.389589914000055],
            [-80.92111427399999, 35.389629913000078],
            [-80.925391274999981, 35.390065912000068],
            [-80.925484274999974, 35.390069913000048],
            [-80.925806274999957, 35.390052913000034],
            [-80.925879274999943, 35.390044912000064],
            [-80.926000275999968, 35.39003191300003],
            [-80.926070274999972, 35.390013913000075],
            [-80.926130274999934, 35.390002913000046],
            [-80.926183274999971, 35.389987913000027],
            [-80.926249274999975, 35.389959913000041],
            [-80.926406275999966, 35.389881913000067],
            [-80.926568275999955, 35.389822913000046],
            [-80.926658275999955, 35.389783914000077],
            [-80.926838275999955, 35.389678913000068],
            [-80.926961275999986, 35.389591913000061],
            [-80.927069275999941, 35.389528913000049],
            [-80.927517275999946, 35.389208913000061],
            [-80.927688275999969, 35.389107912000043],
            [-80.927879275999942, 35.388962912000068],
            [-80.928083275999938, 35.388833912000052],
            [-80.928172275999941, 35.388788913000042],
            [-80.928295275999972, 35.388740913000049],
            [-80.928624275999937, 35.388560912000059],
            [-80.928884275999962, 35.388436913000078],
            [-80.929199275999963, 35.388188913000079],
            [-80.929568275999941, 35.387924913000063],
            [-80.929799276999972, 35.387828912000032],
            [-80.930117276999965, 35.387651912000024],
            [-80.930139276999967, 35.38762691200003],
            [-80.930392276999953, 35.387456913000051],
            [-80.930523276999963, 35.387432912000065],
            [-80.930647276999935, 35.387427913000067],
            [-80.93076027699999, 35.387441912000043],
            [-80.93092227599999, 35.387488912000038],
            [-80.931076276999988, 35.387567912000065],
            [-80.931127276999973, 35.387600912000039],
            [-80.931246276999957, 35.387679912000067],
            [-80.931393275999937, 35.387717912000028],
            [-80.931476276999945, 35.387746912000068],
            [-80.931571276999989, 35.387763912000025],
            [-80.931807276999962, 35.387756913000032],
            [-80.931999276999989, 35.387679912000067],
            [-80.932065277999982, 35.387636912000062],
            [-80.932132276999937, 35.387591912000062],
            [-80.932228276999979, 35.387540913000066],
            [-80.932346275999976, 35.387462912000046],
            [-80.932446275999951, 35.387390912000058],
            [-80.932649276999939, 35.387242912000033],
            [-80.932722276999982, 35.387179912000079],
            [-80.93277227699997, 35.387080912000044],
            [-80.932801276999953, 35.386997912000027],
            [-80.93281327699998, 35.38688391200003],
            [-80.932778276999954, 35.386763912000049],
            [-80.932759276999946, 35.386720913000033],
            [-80.932723277999969, 35.38668791300006],
            [-80.932663276999961, 35.386625913000046],
            [-80.932319277999966, 35.386352913000053],
            [-80.932271276999984, 35.386305912000068],
            [-80.932257276999962, 35.386268912000048],
            [-80.93222127699994, 35.386171912000066],
            [-80.932180276999986, 35.385913912000035],
            [-80.932189275999974, 35.385812912000063],
            [-80.932114276999982, 35.385621912000033],
            [-80.932067276999987, 35.385376912000027],
            [-80.93202927699997, 35.385030911000058],
            [-80.93202527699998, 35.384964912000044],
            [-80.932053276999966, 35.384911912000064],
            [-80.932244276999938, 35.384724911000035],
            [-80.932369275999974, 35.384613912000077],
            [-80.932443276999948, 35.384535911000057],
            [-80.932686276999959, 35.384137912000028],
            [-80.932842276999963, 35.383935912000027],
            [-80.933126276999985, 35.38353991200006],
            [-80.933245276999969, 35.383350912000026],
            [-80.933340276999957, 35.383212912000033],
            [-80.933614276999947, 35.382824912000046],
            [-80.93368727699999, 35.382719911000038],
            [-80.933829276999973, 35.382529912000052],
            [-80.933991276999961, 35.382435911000073],
            [-80.934130276999952, 35.382361911000032],
            [-80.934264277999944, 35.382316911000032],
            [-80.934416276999968, 35.382289911000044],
            [-80.934607277999987, 35.382204911000031],
            [-80.934726276999982, 35.382128911000052],
            [-80.934781277999946, 35.382096911000076],
            [-80.93483527799998, 35.382081911000057],
            [-80.934991277999984, 35.382045912000024],
            [-80.935100276999947, 35.382086911000044],
            [-80.935214276999943, 35.382185911000079],
            [-80.935404277999965, 35.382288911000046],
            [-80.935737278999966, 35.382460911000067],
            [-80.935915277999982, 35.382568911000078],
            [-80.936102277999964, 35.382666912000047],
            [-80.936164277999978, 35.382684911000069],
            [-80.936223277999943, 35.382678911000028],
            [-80.936339277999934, 35.382657911000024],
            [-80.936467277999952, 35.382588911000028],
            [-80.936576277999961, 35.382504911000069],
            [-80.936660277999977, 35.382340911000028],
            [-80.936801278999951, 35.382102911000061],
            [-80.936905278999973, 35.382033911000065],
            [-80.937056278999989, 35.381968912000048],
            [-80.937196277999988, 35.381933910000043],
            [-80.93761227799996, 35.381811911000057],
            [-80.938031277999983, 35.38166091100004],
            [-80.938509278999959, 35.381518911000057],
            [-80.938735278999957, 35.381442910000032],
            [-80.938899277999951, 35.381411911000043],
            [-80.939040278999983, 35.381428910000068],
            [-80.939463278999938, 35.381445910000025],
            [-80.93960427899998, 35.381443911000076],
            [-80.939961278999988, 35.381432911000047],
            [-80.940065278999953, 35.38137891100007],
            [-80.940124278999974, 35.381281911000031],
            [-80.94014827899997, 35.381045911000058],
            [-80.940109278999955, 35.380574910000064],
            [-80.940104279999957, 35.38051291000005],
            [-80.94000127999999, 35.380543911000075],
            [-80.939974278999955, 35.380431910000027],
            [-80.939928278999957, 35.380176910000046],
            [-80.939916278999988, 35.380086910000045],
            [-80.939918278999983, 35.379947910000055],
            [-80.93992327999996, 35.379890910000029],
            [-80.940014278999968, 35.379838911000036],
            [-80.940108278999958, 35.379786910000064],
            [-80.940192278999973, 35.379733910000027],
            [-80.940281278999976, 35.379683911000029],
            [-80.940358279999941, 35.379648910000071],
            [-80.940447278999955, 35.379619910000031],
            [-80.940655278999941, 35.379506910000032],
            [-80.940746278999939, 35.379453910000052],
            [-80.940861278999989, 35.379404910000062],
            [-80.940912278999974, 35.379387910000048],
            [-80.940996278999989, 35.379343910000046],
            [-80.941059278999944, 35.379280910000034],
            [-80.941119279999953, 35.379197911000063],
            [-80.941213278999953, 35.379037910000079],
            [-80.941222278999987, 35.378958910000051],
            [-80.941221279999979, 35.378894910000042],
            [-80.941178279999974, 35.378843910000057],
            [-80.941117279999958, 35.378790909000031],
            [-80.941035278999948, 35.378731909000066],
            [-80.940967278999949, 35.37867490900004],
            [-80.940889278999975, 35.378624910000042],
            [-80.940824279999958, 35.378569910000067],
            [-80.940812278999942, 35.378522910000072],
            [-80.940812278999942, 35.378474910000079],
            [-80.940806279999947, 35.378421909000053],
            [-80.940772278999987, 35.378358910000031],
            [-80.940763279999942, 35.37828791000004],
            [-80.940760279999949, 35.378208910000069],
            [-80.940744278999944, 35.378084910000041],
            [-80.940702279999982, 35.378044910000028],
            [-80.940768279999986, 35.378016910000042],
            [-80.940793278999934, 35.377998910000031],
            [-80.940828277999969, 35.377967910000052],
            [-80.94084627899997, 35.377920910000057],
            [-80.940896278999958, 35.377873910000062],
            [-80.94094027999995, 35.377853911000045],
            [-80.94106727999997, 35.377799910000078],
            [-80.941116278999971, 35.37776991000004],
            [-80.941202278999981, 35.377754910000078],
            [-80.941306278999946, 35.377753910000024],
            [-80.941445279999982, 35.377749910000034],
            [-80.941701279999961, 35.377735910000069],
            [-80.941821279999942, 35.37773990900007],
            [-80.941897279999978, 35.377727910000033],
            [-80.941943279999975, 35.377667910000071],
            [-80.942024279999941, 35.377628910000055],
            [-80.94209227999994, 35.377636911000025],
            [-80.942232278999938, 35.37770290900005],
            [-80.942303279999976, 35.377742910000052],
            [-80.942353278999974, 35.377747910000039],
            [-80.942457278999939, 35.377752910000027],
            [-80.942481279999981, 35.377760910000063],
            [-80.942559278999965, 35.377794910000034],
            [-80.942635279999934, 35.377807910000058],
            [-80.94270727899999, 35.377808910000056],
            [-80.942864279999981, 35.377799910000078],
            [-80.942974279999987, 35.377804910000066],
            [-80.943049279999968, 35.377784910000059],
            [-80.943159279999975, 35.37781891000003],
            [-80.943227278999984, 35.377849910000066],
            [-80.943301279999957, 35.377828910000062],
            [-80.94339027999996, 35.377810910000051],
            [-80.943487279999943, 35.377820910000025],
            [-80.943672280999976, 35.377854909000064],
            [-80.943774279999957, 35.377859910000041],
            [-80.943807279999987, 35.377856909000059],
            [-80.943882280999958, 35.377846910000073],
            [-80.943914279999944, 35.377831910000054],
            [-80.94395327999996, 35.377808910000056],
            [-80.943972279999969, 35.377798909000035],
            [-80.94405127999994, 35.377746910000042],
            [-80.94413327999996, 35.377714910000066],
            [-80.94418227999995, 35.377676910000048],
            [-80.944229279999945, 35.377544909000051],
            [-80.944195279999974, 35.377481910000029],
            [-80.944131279999965, 35.377389910000034],
            [-80.944145279999987, 35.377301910000028],
            [-80.944203279999954, 35.377205909000054],
            [-80.944271279999953, 35.377145910000024],
            [-80.944308279999973, 35.377096910000034],
            [-80.944332279999969, 35.37703091000003],
            [-80.94435428099996, 35.376990910000075],
            [-80.944390280999983, 35.376949910000064],
            [-80.944465279999974, 35.376917910000031],
            [-80.94454927999999, 35.376875910000024],
            [-80.944607279999957, 35.376822909000055],
            [-80.944671278999976, 35.376795910000055],
            [-80.944769278999956, 35.376772909000067],
            [-80.944859280999935, 35.376729909000062],
            [-80.944955279999988, 35.376671909000038],
            [-80.945024280999974, 35.376624910000032],
            [-80.945077280999953, 35.376565910000068],
            [-80.945123279999962, 35.37649490900003],
            [-80.945198279999943, 35.376414909000061],
            [-80.945261280999944, 35.376356909000037],
            [-80.945312280999985, 35.37629490900008],
            [-80.945323279999968, 35.376276909000069],
            [-80.945351280999944, 35.376207910000062],
            [-80.945382280999979, 35.376132910000024],
            [-80.945387280999967, 35.376047909000079],
            [-80.945379279999941, 35.375981910000064],
            [-80.945395280999946, 35.375909909000029],
            [-80.945447280999986, 35.375786910000045],
            [-80.945462280999948, 35.375733909000076],
            [-80.945459280999955, 35.375681909000036],
            [-80.945468280999989, 35.375610910000034],
            [-80.945507280999948, 35.375504910000075],
            [-80.94543927999996, 35.375431909000042],
            [-80.94538627999998, 35.375397909000071],
            [-80.945339279999985, 35.375359909000053],
            [-80.945300280999959, 35.375262909000071],
            [-80.945316279999986, 35.375199910000049],
            [-80.945471279999936, 35.375091909000048],
            [-80.945491279999942, 35.37503791000006],
            [-80.945510279999951, 35.374838909000061],
            [-80.945508279999956, 35.374770910000052],
            [-80.94539427999996, 35.374721909000073],
            [-80.94534127999998, 35.374740909000025],
            [-80.945276279999973, 35.374751910000043],
            [-80.945219279999947, 35.374756909000041],
            [-80.945067279999989, 35.374750909000056],
            [-80.944997279999939, 35.374708910000038],
            [-80.944980280999971, 35.374660909000056],
            [-80.944973279999942, 35.374576909000041],
            [-80.944978280999976, 35.374413909000054],
            [-80.944975279999937, 35.374299909000058],
            [-80.944987279999964, 35.374210909000055],
            [-80.945079279999959, 35.373952910000071],
            [-80.945104279999953, 35.373847909000062],
            [-80.945117280999966, 35.373771909000027],
            [-80.945109280999986, 35.373686909000071],
            [-80.945090279999988, 35.373614909000025],
            [-80.944964279999965, 35.373459909000076],
            [-80.944849279999971, 35.373384909000038],
            [-80.944792279999945, 35.373363909000034],
            [-80.944680280999989, 35.373352908000072],
            [-80.944604279999965, 35.373359909000044],
            [-80.944526279999934, 35.373358909000046],
            [-80.944430279999949, 35.373343909000027],
            [-80.944294279999951, 35.373304909000069],
            [-80.944116280999935, 35.373201908000055],
            [-80.944013279999979, 35.373189909000075],
            [-80.94385127999999, 35.373225909000041],
            [-80.943738279999934, 35.373241909000058],
            [-80.94372027999998, 35.373174908000067],
            [-80.943767279999975, 35.373071909000032],
            [-80.943780279999942, 35.373016909000057],
            [-80.943797279999956, 35.372863908000056],
            [-80.943799279999951, 35.372796909000044],
            [-80.943791279999971, 35.372740909000072],
            [-80.943758278999951, 35.372676909000063],
            [-80.943714279999938, 35.37261490800006],
            [-80.943682279999962, 35.372549909000043],
            [-80.943538279999984, 35.372390909000046],
            [-80.943459279999956, 35.372315908000076],
            [-80.943286279999938, 35.372217909000028],
            [-80.943168278999963, 35.372219908000034],
            [-80.943057279999948, 35.372151909000024],
            [-80.943019279999987, 35.372112908000076],
            [-80.943008278999969, 35.372053908000055],
            [-80.943049279999968, 35.372011908000047],
            [-80.943159278999985, 35.37194290900004],
            [-80.94325127999997, 35.371857909000028],
            [-80.943279279999956, 35.371813908000036],
            [-80.943293279999978, 35.371756908000066],
            [-80.943298278999976, 35.371673908000048],
            [-80.94325527899997, 35.371611908000034],
            [-80.94316927999995, 35.371584908000045],
            [-80.943058278999956, 35.371584908000045],
            [-80.942953279999983, 35.371608909000031],
            [-80.942867278999984, 35.371655908000037],
            [-80.942821278999986, 35.371725909000077],
            [-80.942761279999957, 35.371789908000039],
            [-80.942654279999942, 35.371855908000043],
            [-80.942523279999989, 35.371964908000052],
            [-80.94247927899994, 35.37200690800006],
            [-80.942440279999971, 35.372076909000043],
            [-80.942440278999982, 35.37213390900007],
            [-80.942420278999975, 35.372203909000064],
            [-80.94237327999997, 35.372238908000043],
            [-80.942272278999951, 35.372266909000075],
            [-80.942181279999943, 35.372247909000066],
            [-80.942061278999972, 35.372127909000028],
            [-80.941877279999972, 35.371971908000035],
            [-80.941659279999953, 35.371805909000045],
            [-80.941564278999977, 35.371749909000073],
            [-80.941437278999956, 35.371703909000075],
            [-80.94127827799997, 35.371636909000074],
            [-80.941177278999987, 35.371630909000032],
            [-80.941081278999945, 35.371647909000046],
            [-80.940978278999978, 35.371629909000035],
            [-80.940897277999966, 35.371579908000058],
            [-80.940735277999977, 35.371531909000055],
            [-80.940656277999949, 35.371538908000048],
            [-80.940511279999953, 35.371617909000065],
            [-80.940451277999955, 35.371629909000035],
            [-80.940369278999981, 35.371636908000028],
            [-80.940260278999972, 35.37163590800003],
            [-80.940199278999955, 35.371648909000044],
            [-80.940121278999982, 35.371667908000063],
            [-80.939962278999985, 35.371702908000032],
            [-80.939903278999964, 35.371696909000036],
            [-80.939831278999975, 35.371701908000034],
            [-80.939763277999987, 35.371679908000033],
            [-80.939651278999975, 35.371589909000079],
            [-80.939616278999949, 35.371542909000027],
            [-80.939543277999974, 35.371501908000027],
            [-80.939460277999956, 35.37148890900005],
            [-80.939376277999941, 35.371497908000038],
            [-80.939276278999955, 35.37152990900006],
            [-80.939167277999957, 35.371547909000071],
            [-80.939065278999976, 35.371540908000043],
            [-80.938912278999965, 35.37149690800004],
            [-80.938803277999966, 35.37149690800004],
            [-80.938707277999981, 35.37152590900007],
            [-80.938655277999942, 35.37154590800003],
            [-80.938575278999963, 35.371525908000024],
            [-80.938473277999947, 35.371452909000027],
            [-80.93835927799995, 35.371273908000035],
            [-80.938278277999984, 35.371230909000076],
            [-80.938193277999972, 35.371168908000072],
            [-80.938150277999966, 35.371129908000057],
            [-80.938105277999966, 35.371099909000066],
            [-80.938019277999956, 35.371066909000035],
            [-80.937891276999949, 35.371028908000028],
            [-80.937711276999948, 35.370956909000029],
            [-80.937690276999945, 35.370898908000072],
            [-80.937791276999974, 35.370828909000068],
            [-80.93789327799999, 35.370795908000048],
            [-80.938211278999972, 35.370713909000074],
            [-80.93835927799995, 35.370711909000079],
            [-80.939408277999974, 35.370045908000066],
            [-80.939447277999989, 35.369959908000055],
            [-80.939463277999948, 35.36990890900006],
            [-80.939451278999968, 35.369833909000079],
            [-80.939415277999956, 35.369770909000067],
            [-80.939375277999943, 35.369709908000061],
            [-80.939089278999973, 35.369506908000062],
            [-80.938970277999942, 35.369385908000027],
            [-80.938924277999945, 35.369313907000048],
            [-80.938851277999959, 35.369117908000078],
            [-80.938844277999976, 35.369062908000046],
            [-80.938778277999972, 35.368987908000065],
            [-80.939062277999938, 35.368288908000068],
            [-80.939097277999963, 35.368247908000058],
            [-80.939160277999974, 35.36819390800008],
            [-80.939266277999934, 35.368137908000051],
            [-80.939393277999955, 35.368089908000059],
            [-80.939472277999982, 35.368035908000024],
            [-80.939497277999976, 35.367974908000065],
            [-80.939491277999934, 35.367910907000066],
            [-80.939411277999966, 35.36783790700008],
            [-80.939286278999987, 35.367772907000074],
            [-80.938902276999954, 35.367616908000059],
            [-80.938721277999946, 35.367535908000036],
            [-80.938401277999958, 35.367365907000078],
            [-80.938334277999957, 35.36731590800008],
            [-80.938249277999944, 35.367170908000048],
            [-80.938083277999965, 35.367020908000029],
            [-80.937960277999935, 35.366937907000079],
            [-80.937657276999971, 35.366777907000028],
            [-80.937550277999947, 35.366740907000064],
            [-80.937482276999958, 35.366731908000077],
            [-80.937428276999981, 35.366714908000063],
            [-80.937223276999987, 35.366612907000047],
            [-80.936862276999989, 35.366450908000047],
            [-80.936703277999982, 35.36640890700005],
            [-80.936567276999938, 35.366360908000047],
            [-80.936523276999935, 35.366327908000073],
            [-80.936449277999941, 35.366297907000046],
            [-80.93637027699998, 35.366290907000064],
            [-80.936187276999988, 35.36622090700007],
            [-80.936107277999952, 35.366179908000049],
            [-80.936005277999982, 35.366127908000067],
            [-80.935854277999965, 35.366085908000059],
            [-80.935776277999935, 35.366058907000024],
            [-80.935448276999978, 35.36593190800005],
            [-80.935318276999965, 35.365886907000061],
            [-80.935251276999963, 35.365858908000064],
            [-80.935063275999937, 35.365765907000025],
            [-80.935004275999972, 35.365729907000059],
            [-80.934888275999981, 35.365683908000051],
            [-80.934726276999982, 35.365591908000056],
            [-80.934676275999948, 35.365550907000056],
            [-80.934568275999936, 35.365480907000062],
            [-80.934379275999959, 35.365401907000034],
            [-80.934314276999942, 35.365378907000036],
            [-80.934180276999939, 35.365315907000024],
            [-80.934106275999966, 35.36528690800003],
            [-80.934057276999965, 35.365260907000049],
            [-80.933999276999941, 35.36524590700003],
            [-80.933901276999961, 35.365231907000066],
            [-80.933843276999937, 35.365212907000057],
            [-80.933689276999985, 35.365133908000075],
            [-80.933456275999959, 35.364997907000031],
            [-80.933346275999952, 35.364923908000037],
            [-80.933155275999979, 35.364827907000063],
            [-80.933036275999939, 35.364789907000045],
            [-80.932864275999975, 35.364755907000074],
            [-80.932736274999968, 35.36474290700005],
            [-80.93254727499999, 35.364733907000073],
            [-80.932445275999953, 35.36473490700007],
            [-80.932345274999989, 35.364742908000039],
            [-80.932143275999977, 35.364768907000041],
            [-80.932050276999973, 35.364795908000076],
            [-80.932097275999979, 35.364854907000051],
            [-80.932231275999982, 35.364961908000055],
            [-80.93228927499996, 35.365002907000076],
            [-80.932314275999943, 35.365067907000025],
            [-80.932348275999971, 35.365137908000065],
            [-80.932384274999947, 35.365177908000078],
            [-80.93246127599997, 35.365210907000062],
            [-80.932537275999948, 35.36524890700008],
            [-80.93264127599997, 35.365331907000041],
            [-80.932699275999937, 35.36539990700004],
            [-80.93278827599994, 35.36552290700007],
            [-80.932814275999988, 35.365685907000056],
            [-80.932801275999964, 35.365733907000049],
            [-80.93277627599997, 35.365799908000042],
            [-80.932762276999938, 35.365852908000079],
            [-80.932752276999963, 35.365924908000068],
            [-80.93269427599995, 35.366073908000033],
            [-80.932630275999941, 35.366150908000066],
            [-80.932584275999943, 35.366229908000037],
            [-80.932580274999964, 35.366283908000071],
            [-80.932583275999946, 35.366379908000056],
            [-80.932584275999943, 35.366443908000065],
            [-80.93260027599996, 35.366499908000037],
            [-80.932625275999953, 35.36654390700005],
            [-80.932653276999986, 35.366623908000065],
            [-80.932673276999935, 35.366720907000058],
            [-80.932667275999961, 35.366794908000031],
            [-80.932627276999938, 35.367005908000067],
            [-80.93258827599999, 35.367104908000044],
            [-80.93253627699994, 35.367154908000032],
            [-80.93250627599997, 35.367223908000028],
            [-80.932440275999966, 35.367416908000052],
            [-80.932302275999973, 35.367851908000034],
            [-80.932299275999981, 35.367974908000065],
            [-80.932327275999967, 35.368057908000026],
            [-80.932387275999986, 35.368182908000051],
            [-80.932496276999984, 35.368497908000052],
            [-80.932561275999944, 35.36864790900006],
            [-80.93265327599994, 35.368916908000074],
            [-80.932707276999963, 35.369054909000056],
            [-80.932738275999952, 35.369158908000031],
            [-80.932747275999986, 35.369310909000035],
            [-80.932778275999965, 35.369376908000049],
            [-80.932849275999956, 35.369496908000031],
            [-80.932885276999968, 35.369577908000053],
            [-80.932921275999945, 35.369625908000046],
            [-80.93305027599996, 35.369752908000066],
            [-80.933142275999955, 35.369765908000034],
            [-80.933238276999987, 35.36974490800003],
            [-80.933354276999978, 35.369739909000032],
            [-80.933423275999985, 35.369771909000065],
            [-80.933501276999948, 35.369910908000065],
            [-80.933502275999956, 35.369981908000057],
            [-80.933523276999949, 35.37005190900004],
            [-80.93351927599997, 35.370108909000066],
            [-80.933479275999957, 35.370160909000049],
            [-80.933429276999959, 35.370209908000049],
            [-80.933408275999966, 35.370271908000063],
            [-80.933377276999977, 35.370319909000045],
            [-80.933364276999953, 35.370364909000045],
            [-80.93336527699995, 35.370421909000072],
            [-80.933318276999955, 35.370488908000027],
            [-80.933233275999953, 35.370503908000046],
            [-80.933120276999944, 35.37056990800005],
            [-80.933063276999974, 35.370556908000026],
            [-80.932935275999967, 35.370592909000038],
            [-80.932855275999941, 35.370626909000066],
            [-80.932744275999937, 35.370718909000061],
            [-80.932677275999936, 35.370813909000049],
            [-80.932601275999957, 35.370900909000056],
            [-80.932541275999938, 35.370976908000046],
            [-80.932440276999955, 35.371055909000063],
            [-80.932469275999949, 35.370924909000053],
            [-80.932550276999962, 35.370687908000036],
            [-80.932576276999953, 35.370510908000028],
            [-80.932607275999942, 35.370438909000029],
            [-80.932558275999952, 35.370398909000073],
            [-80.932439276999958, 35.370365909000043],
            [-80.932277275999979, 35.370279908000043],
            [-80.932223276999935, 35.370240908000028],
            [-80.932148276999953, 35.370220909000068],
            [-80.932065276999936, 35.370203909000054],
            [-80.93200027599994, 35.370149909000077],
            [-80.931847275999985, 35.370103909000079],
            [-80.931793275999951, 35.370036908000031],
            [-80.931708275999938, 35.369951909000065],
            [-80.931663275999938, 35.369917909000037],
            [-80.93157227599994, 35.369882909000069],
            [-80.931423275999975, 35.369794909000063],
            [-80.931389274999958, 35.369748908000076],
            [-80.931370274999949, 35.369669908000049],
            [-80.931354275999979, 35.369566908000024],
            [-80.93125727599994, 35.369499909000069],
            [-80.931232276999935, 35.369420908000052],
            [-80.931240274999936, 35.369293908000031],
            [-80.93111827499996, 35.369231909000064],
            [-80.930790275999982, 35.369038909000039],
            [-80.930710274999967, 35.369002908000027],
            [-80.93060427599994, 35.368994908000047],
            [-80.930430275999981, 35.369007908000071],
            [-80.930369275999965, 35.369019908000041],
            [-80.930297274999987, 35.369010909000053],
            [-80.930222274999949, 35.368994909000037],
            [-80.930027274999986, 35.368893908000075],
            [-80.929922274999967, 35.36886690800003],
            [-80.929683275999935, 35.368886908000036],
            [-80.929602274999979, 35.368921908000061],
            [-80.92949327599996, 35.368959909000068],
            [-80.929373274999989, 35.368991909000044],
            [-80.929312274999972, 35.369021908000036],
            [-80.929236275999983, 35.369052908000072],
            [-80.929160274999958, 35.369068908000031],
            [-80.928963275999934, 35.369085909000034],
            [-80.928887274999965, 35.36908790800004],
            [-80.928865274999964, 35.369106908000049],
            [-80.928495274999989, 35.369219908000048],
            [-80.928338274999987, 35.369334908000042],
            [-80.928194274999953, 35.369379908000042],
            [-80.928081274999954, 35.369435908000071],
            [-80.92788427499994, 35.369508908000057],
            [-80.92773727499997, 35.369576909000045],
            [-80.927644275999967, 35.36963490800008],
            [-80.927495273999966, 35.369737909000037],
            [-80.927431274999947, 35.369793909000066],
            [-80.927173274999973, 35.369903908000026],
            [-80.927077274999988, 35.369922908000035],
            [-80.927004273999955, 35.370049909000045],
            [-80.92691627499994, 35.37009690800005],
            [-80.926853274999985, 35.370030909000036],
            [-80.926819273999968, 35.369983909000041],
            [-80.926747274999968, 35.370000908000065],
            [-80.926692273999947, 35.370046909000052],
            [-80.92669527399994, 35.370126909000078],
            [-80.926714273999949, 35.370179909000058],
            [-80.926763274999985, 35.370279909000033],
            [-80.926788273999989, 35.370359909000058],
            [-80.92674027399994, 35.370449909000058],
            [-80.926671273999943, 35.370473909000054],
            [-80.926647274999937, 35.370477909000044],
            [-80.926550274999954, 35.370426909000059],
            [-80.926472274999981, 35.370343910000031],
            [-80.926367273999972, 35.370246909000059],
            [-80.926316274999976, 35.37021990900007],
            [-80.92625527499996, 35.370207910000033],
            [-80.925777273999984, 35.370203909000054],
            [-80.925436274999981, 35.370209909000039],
            [-80.92534427399994, 35.370196908000025],
            [-80.925216273999979, 35.370198909000067],
            [-80.925071273999947, 35.370224909000058],
            [-80.924815273999968, 35.370299909000039],
            [-80.924345273999961, 35.370425908000072],
            [-80.924012273999949, 35.370534909000071],
            [-80.923922273999949, 35.370560909000062],
            [-80.923860273999935, 35.370535909000068],
            [-80.923882272999947, 35.370464908000031],
            [-80.923837273999936, 35.370388909000042],
            [-80.923806272999968, 35.37032190900004],
            [-80.923930272999939, 35.370262909000076],
            [-80.923987272999966, 35.370245909000062],
            [-80.924134273999982, 35.370220909000068],
            [-80.924201273999984, 35.370188909000035],
            [-80.924404273999983, 35.370027909000044],
            [-80.924647273999938, 35.369866908000063],
            [-80.924890273999949, 35.369698909000078],
            [-80.925125274999971, 35.369640909000054],
            [-80.925207272999955, 35.369631909000077],
            [-80.925412273999939, 35.369635909000067],
            [-80.925507273999983, 35.369619908000061],
            [-80.925604273999966, 35.369592908000072],
            [-80.925687273999984, 35.369588908000026],
            [-80.925786273999961, 35.369590909000067],
            [-80.925853273999962, 35.369575909000048],
            [-80.925979274999975, 35.369557909000036],
            [-80.926052273999971, 35.369560909000029],
            [-80.92612027499996, 35.369557908000047],
            [-80.926316274999976, 35.369527909000055],
            [-80.926514273999942, 35.369452908000028],
            [-80.926733274999947, 35.369343909000065],
            [-80.926893273999951, 35.369256908000068],
            [-80.927057274999981, 35.369149909000043],
            [-80.927157273999967, 35.369100908000064],
            [-80.927280274999987, 35.369047908000027],
            [-80.927416274999985, 35.369008908000069],
            [-80.927581274999966, 35.36895290800004],
            [-80.927781273999983, 35.368897908000065],
            [-80.928074274999972, 35.368773908000037],
            [-80.928148275999945, 35.368706909000025],
            [-80.92822827599997, 35.368601908000073],
            [-80.928331274999948, 35.368525909000027],
            [-80.928407274999984, 35.368459908000034],
            [-80.928467274999946, 35.368419908000078],
            [-80.928576275999944, 35.36838990800004],
            [-80.928628274999937, 35.368351908000079],
            [-80.928682274999971, 35.368305909000071],
            [-80.928748274999975, 35.368211908000035],
            [-80.928781273999959, 35.368151908000073],
            [-80.928833275999978, 35.368087908000064],
            [-80.928985274999945, 35.368060909000064],
            [-80.929063275999965, 35.368039908000071],
            [-80.929118274999951, 35.368011908000028],
            [-80.92917027499999, 35.367978909000044],
            [-80.929218274999982, 35.367914908000046],
            [-80.92920027599996, 35.367817908000063],
            [-80.929252274999953, 35.367774908000058],
            [-80.929308274999983, 35.367823908000048],
            [-80.92945227499996, 35.367925909000064],
            [-80.929523274999951, 35.367958909000038],
            [-80.929621274999988, 35.367991908000079],
            [-80.929714274999981, 35.368000908000056],
            [-80.929794274999949, 35.36800290900004],
            [-80.929875274999972, 35.368032908000032],
            [-80.929961275999972, 35.368073908000042],
            [-80.930049275999977, 35.368051908000041],
            [-80.93013127599994, 35.368006908000041],
            [-80.93027727599997, 35.367992908000076],
            [-80.930403275999936, 35.367989908000027],
            [-80.930492274999949, 35.36798090800005],
            [-80.930583275999936, 35.36798090800005],
            [-80.930673275999936, 35.367996908000066],
            [-80.930749275999972, 35.367981909000036],
            [-80.931031275999942, 35.367817908000063],
            [-80.93108127499994, 35.367793908000067],
            [-80.931132275999971, 35.367762908000032],
            [-80.931188275999943, 35.367711908000047],
            [-80.931261275999987, 35.367627909000078],
            [-80.931287275999978, 35.367565908000074],
            [-80.93124527599997, 35.367469908000032],
            [-80.931231275999949, 35.36742190700005],
            [-80.931226275999961, 35.36737290800005],
            [-80.93124127599998, 35.367279908000057],
            [-80.931226275999961, 35.36718490800007],
            [-80.931200274999981, 35.367107908000037],
            [-80.931145274999949, 35.366975908000029],
            [-80.93107727499995, 35.366838908000034],
            [-80.930994275999979, 35.366640907000033],
            [-80.930938275999949, 35.36654790800003],
            [-80.930895275999944, 35.366488908000065],
            [-80.930803275999949, 35.366395908000072],
            [-80.930711275999954, 35.36635590800006],
            [-80.930654274999938, 35.366340908000041],
            [-80.930595275999963, 35.366332908000061],
            [-80.930445275999944, 35.366341908000038],
            [-80.930366275999972, 35.366326908000076],
            [-80.930329275999952, 35.366242908000061],
            [-80.930284274999963, 35.36611590800004],
            [-80.930224274999944, 35.365810908000071],
            [-80.930195275999949, 35.365503908000051],
            [-80.930175275999943, 35.365444908000029],
            [-80.930167274999974, 35.365394907000052],
            [-80.930146275999959, 35.36533890700008],
            [-80.930045274999941, 35.365141907000066],
            [-80.93003727599995, 35.36505790700005],
            [-80.930092275999982, 35.364973907000035],
            [-80.930145274999973, 35.36491490700007],
            [-80.930304274999969, 35.364826908000055],
            [-80.93038327499994, 35.364805908000051],
            [-80.930423274999953, 35.364765908000038],
            [-80.930517274999943, 35.364709908000066],
            [-80.930384274999938, 35.364551907000077],
            [-80.930051274999983, 35.364476907000039],
            [-80.931238275999988, 35.363070908000054],
            [-80.930594274999976, 35.36306490700008],
            [-80.929739274999974, 35.362980907000065],
            [-80.928982274999953, 35.362898907000044],
            [-80.928208274999974, 35.362793908000071],
            [-80.927440273999935, 35.362626907000049],
            [-80.92741827399999, 35.362621907000062],
            [-80.926707274999956, 35.362495907000039],
            [-80.926291273999936, 35.362338907000037],
            [-80.92598127499997, 35.362221907000048],
            [-80.925309273999972, 35.362021907000042],
            [-80.924681273999965, 35.361785907000069],
            [-80.924214272999961, 35.361662907000039],
            [-80.923735273999966, 35.361265906000028],
            [-80.923344272999941, 35.360906907000071],
            [-80.922997272999964, 35.360644907000051],
            [-80.922885272999963, 35.360340907000079],
            [-80.922953272999962, 35.360082907000049],
            [-80.923005272999944, 35.359930907000034],
            [-80.923048272999949, 35.359810907000053],
            [-80.923107271999982, 35.359699907000049],
            [-80.923384272999954, 35.359186907000037],
            [-80.923857273999943, 35.358956907000049],
            [-80.924387273999969, 35.358622906000051],
            [-80.924869273999946, 35.358369907000053],
            [-80.925468272999979, 35.358169906000057],
            [-80.926376273999949, 35.357870906000073],
            [-80.927011273999938, 35.357641906000026],
            [-80.92766327399994, 35.357530906000079],
            [-80.929344273999959, 35.357254906000037],
            [-80.931328274999942, 35.357603906000065],
            [-80.932488275999958, 35.357533905000025],
            [-80.93303227499996, 35.357377905000078],
            [-80.933440275999942, 35.357279907000077],
            [-80.933931275999953, 35.357092906000048],
            [-80.934550275999982, 35.356789906000074],
            [-80.935498275999976, 35.356246905000035],
            [-80.936612276999938, 35.355506905000027],
            [-80.938330277999967, 35.35428590500004],
            [-80.93908027699996, 35.353654904000052],
            [-80.939648276999947, 35.35317890500005],
            [-80.940401276999978, 35.352455904000067],
            [-80.94059227799994, 35.35230490500004],
            [-80.941178277999938, 35.351844905000064],
            [-80.941764277999937, 35.351363904000038],
            [-80.942357277999974, 35.351016904000062],
            [-80.942921277999972, 35.350705904000051],
            [-80.943613277999987, 35.350518904000069],
            [-80.94351927799994, 35.349537903000055],
            [-80.942990278999957, 35.348711903000037],
            [-80.942669277999983, 35.348181903000068],
            [-80.942409277999957, 35.347798904000058],
            [-80.94227727699996, 35.347574903000066],
            [-80.942118276999963, 35.347282904000053],
            [-80.941999277999969, 35.34707390300008],
            [-80.941861277999976, 35.346818903000042],
            [-80.941480277999972, 35.346155903000067],
            [-80.941179276999947, 35.345594903000062],
            [-80.941047276999939, 35.345381903000032],
            [-80.940969276999965, 35.345268902000043],
            [-80.94078327699998, 35.345015902000057],
            [-80.940368276999948, 35.344496903000049],
            [-80.939629276999938, 35.343554902000051],
            [-80.939531276999958, 35.343406903000073],
            [-80.939476276999983, 35.343289903000027],
            [-80.939431275999937, 35.34314990200005],
            [-80.93939927699995, 35.34300690200007],
            [-80.93938327799998, 35.342853902000058],
            [-80.93938327799998, 35.34266890300006],
            [-80.93940727599994, 35.342278902000032],
            [-80.939424275999954, 35.342101902000024],
            [-80.939429276999988, 35.341938902000038],
            [-80.939427276999936, 35.341836902000068],
            [-80.939405276999935, 35.341645902000039],
            [-80.939384277999977, 35.341562902000078],
            [-80.939224276999937, 35.341134902000078],
            [-80.938977275999946, 35.340532901000074],
            [-80.938717276999967, 35.339929902000051],
            [-80.938518276999957, 35.339444902000025],
            [-80.938483276999989, 35.339345902000048],
            [-80.938422276999972, 35.339174902000025],
            [-80.938392275999945, 35.33864690200005],
            [-80.938361275999966, 35.338452902000029],
            [-80.938365276999946, 35.338356902000044],
            [-80.938357276999966, 35.338205902000027],
            [-80.938354275999984, 35.338140902000077],
            [-80.938350276999984, 35.337467901000025],
            [-80.938343276999944, 35.33713890100006],
            [-80.938342276999947, 35.337026901000058],
            [-80.938336275999973, 35.336255902000062],
            [-80.938323276999938, 35.336087901000042],
            [-80.938286275999985, 35.335769900000059],
            [-80.938265275999981, 35.335649901000068],
            [-80.938244275999978, 35.335567901000047],
            [-80.938180274999979, 35.335369901000035],
            [-80.938062275999982, 35.33509390100005],
            [-80.937629275999939, 35.334163901000068],
            [-80.937444274999962, 35.333735901000068],
            [-80.937367275999975, 35.333578901000067],
            [-80.937335275999942, 35.333532901000069],
            [-80.93727127599999, 35.33346490100007],
            [-80.937074275999976, 35.333657901000038],
            [-80.93690027599996, 35.333810901000049],
            [-80.936738274999982, 35.333935901000075],
            [-80.936569275999943, 35.334047901000076],
            [-80.936454275999949, 35.33411390100008],
            [-80.936303274999943, 35.334190900000067],
            [-80.936036274999935, 35.334309900000051],
            [-80.935716274999947, 35.334438901000055],
            [-80.934599275999972, 35.334859901000073],
            [-80.933532274999948, 35.335162902000036],
            [-80.933190273999969, 35.335206901000049],
            [-80.932999274999986, 35.335221901000068],
            [-80.932706274999987, 35.335230901000045],
            [-80.932595274999983, 35.335227900000064],
            [-80.932348273999935, 35.335209901000042],
            [-80.931947273999981, 35.335158901000057],
            [-80.931485273999954, 35.335090901000058],
            [-80.931117272999984, 35.335036901000024],
            [-80.930520273999946, 35.334949901000073],
            [-80.92988827299996, 35.334853901000031],
            [-80.929607272999988, 35.33481590100007],
            [-80.929238273999943, 35.334783901000037],
            [-80.929099273999952, 35.33477890100005],
            [-80.928812273999938, 35.334785901000032],
            [-80.928575272999979, 35.334811902000069],
            [-80.92827127299995, 35.334871902000032],
            [-80.928058272999976, 35.334924901000079],
            [-80.927047272999971, 35.335204901000054],
            [-80.92687927299994, 35.335248901000057],
            [-80.926796272999979, 35.335270901000058],
            [-80.926530272999969, 35.335335902000054],
            [-80.926275272999987, 35.335388902000034],
            [-80.926120272999981, 35.335420901000077],
            [-80.925922272999969, 35.335457901000041],
            [-80.925475272999961, 35.335525902000029],
            [-80.924589271999935, 35.335650902000054],
            [-80.923879272999955, 35.335755901000027],
            [-80.922743270999945, 35.335917902000062],
            [-80.922506271999964, 35.335944902000051],
            [-80.922315270999945, 35.33595990200007],
            [-80.922054270999979, 35.335964901000068],
            [-80.921763270999975, 35.335949901000049],
            [-80.921535271999971, 35.335921902000052],
            [-80.921094270999959, 35.335838902000035],
            [-80.920474269999943, 35.335705902000029],
            [-80.919887270999936, 35.335573902000078],
            [-80.919338269999969, 35.335464902000069],
            [-80.919103269999937, 35.33542790100006],
            [-80.918893269999955, 35.335401901000068],
            [-80.918643269999961, 35.335378902000059],
            [-80.918231269999978, 35.335358902000053],
            [-80.917946269999959, 35.335357902000055],
            [-80.917583269999966, 35.335371902000077],
            [-80.917232269999943, 35.335398902000065],
            [-80.917037268999934, 35.335420901000077],
            [-80.916989269999988, 35.335429901000055],
            [-80.916250269999978, 35.335565902000042],
            [-80.91604426899994, 35.335600901000078],
            [-80.915287268999975, 35.335730901000034],
            [-80.915230269999938, 35.335741902000052],
            [-80.914652268999987, 35.335847901000079],
            [-80.914298268999971, 35.335921902000052],
            [-80.913991268999951, 35.336001903000067],
            [-80.913783268999964, 35.335749902000032],
            [-80.913221267999972, 35.335088901000063],
            [-80.912926268999968, 35.334676902000069],
            [-80.912808267999935, 35.33448590100005],
            [-80.912635267999974, 35.334186902000056],
            [-80.912550268999951, 35.334019901000033],
            [-80.912437267999962, 35.333744901000046],
            [-80.912311267999939, 35.33343190100004],
            [-80.912154267999938, 35.333011901000077],
            [-80.912076268999954, 35.332775901000048],
            [-80.911465266999983, 35.333212902000071],
            [-80.910880267999971, 35.333668901000067],
            [-80.910338267999975, 35.334129902000029],
            [-80.909756267999967, 35.334668902000033],
            [-80.908401266999988, 35.33609490200007],
            [-80.908098266999957, 35.336433902000067],
            [-80.907493267999939, 35.337073902000043],
            [-80.907158266999943, 35.337402903000054],
            [-80.906958266999936, 35.337583902000063],
            [-80.906539266999971, 35.337929903000031],
            [-80.906271266999966, 35.338130902000046],
            [-80.905818266999972, 35.338432902000079],
            [-80.905344265999986, 35.338712903000044],
            [-80.904871265999986, 35.338948903000073],
            [-80.904596265999942, 35.339086903000066],
            [-80.904338266999957, 35.339198903000067],
            [-80.904076266999937, 35.33930390300003],
            [-80.90366626499997, 35.339453903000049],
            [-80.903121265999971, 35.339621903000079],
            [-80.902564265999956, 35.339762904000054],
            [-80.901998265999964, 35.339875904000053],
            [-80.901424264999946, 35.339960904000066],
            [-80.900845265999976, 35.340016903000048],
            [-80.900366263999956, 35.340041903000042],
            [-80.899729264999962, 35.340043903000037],
            [-80.899058264999951, 35.340017904000035],
            [-80.89846526499997, 35.339970903000051],
            [-80.89573726399999, 35.33970490400003],
            [-80.894385262999947, 35.33957390300003],
            [-80.894369263999977, 35.339608904000045],
            [-80.894295263999936, 35.339781904000063],
            [-80.893663262999951, 35.339722904000041],
            [-80.89293926299996, 35.339693903000068],
            [-80.892214261999982, 35.339693904000058],
            [-80.891491261999988, 35.339723904000039],
            [-80.890769260999946, 35.339781904000063],
            [-80.890045261999944, 35.339869903000078],
            [-80.88933726099998, 35.33998590300007],
            [-80.888637260999985, 35.340128904000039],
            [-80.887946260999968, 35.34030090400006],
            [-80.887266260999979, 35.340499904000069],
            [-80.886599260999958, 35.340724904000069],
            [-80.885946260999958, 35.340977903000066],
            [-80.885517260999961, 35.341164904000038],
            [-80.885309260999975, 35.341255904000036],
            [-80.884690259999957, 35.341558905000056],
            [-80.884371259999966, 35.341732904000025],
            [-80.883687259999988, 35.342137904000026],
            [-80.883025259999954, 35.34256690400008],
            [-80.882290258999944, 35.343056904000036],
            [-80.881581259999962, 35.343501905000039],
            [-80.881512259999965, 35.343540905000054],
            [-80.881412258999944, 35.343598905000079],
            [-80.881198258999973, 35.343458904000045],
            [-80.879566258999944, 35.342389904000072],
            [-80.87930825899997, 35.342196905000037],
            [-80.879076258999987, 35.341982904000076],
            [-80.878871258999936, 35.341751905000024],
            [-80.87847925899996, 35.341187905000027],
            [-80.877396257999976, 35.33927990400008],
            [-80.877251258999934, 35.339018904000056],
            [-80.876688256999955, 35.338040904000025],
            [-80.875991256999953, 35.336813904000053],
            [-80.875699257999941, 35.336300903000051],
            [-80.875323256999934, 35.335643903000062],
            [-80.874933256999952, 35.334947903000057],
            [-80.874720256999979, 35.334524902000055],
            [-80.874418257999935, 35.333874903000037],
            [-80.874203256999976, 35.333372903000054],
            [-80.874167257999943, 35.333288903000039],
            [-80.87377025699999, 35.332265902000074],
            [-80.873505256999977, 35.331581903000028],
            [-80.873434255999939, 35.33142390200004],
            [-80.873372255999982, 35.331318903000067],
            [-80.873282256999971, 35.331193902000052],
            [-80.873155256999951, 35.331056902000057],
            [-80.873008255999935, 35.330914902000075],
            [-80.87253325599994, 35.330483903000072],
            [-80.872197255999936, 35.330193902000076],
            [-80.871026255999936, 35.329230902000063],
            [-80.87078125499994, 35.329023901000028],
            [-80.86920525499994, 35.327736902000026],
            [-80.869153255999947, 35.327693902000078],
            [-80.868798254999945, 35.327396902000032],
            [-80.868723254999964, 35.327329901000041],
            [-80.868535253999937, 35.327162902000055],
            [-80.867973254999981, 35.326607901000045],
            [-80.86749425499994, 35.326134902000035],
            [-80.86725325499998, 35.325894902000073],
            [-80.867066253999951, 35.325689901000032],
            [-80.866915254999981, 35.325489901000026],
            [-80.866845253999941, 35.325372902000026],
            [-80.866759253999987, 35.325425902000063],
            [-80.86667625399997, 35.32549090200007],
            [-80.86582825399995, 35.326240901000062],
            [-80.865121253999973, 35.32687590200004],
            [-80.864835253999956, 35.327142902000048],
            [-80.864528253999936, 35.327425901000026],
            [-80.86436525299996, 35.32756990200005],
            [-80.864248252999971, 35.327679902000057],
            [-80.863697253999987, 35.328167902000075],
            [-80.86310825299995, 35.328702903000078],
            [-80.863009252999973, 35.328785903000039],
            [-80.862866252999936, 35.328896902000054],
            [-80.86218525299995, 35.329382903000067],
            [-80.862066252999966, 35.329463902000043],
            [-80.861916252999947, 35.329547903000048],
            [-80.861851251999951, 35.329575902000045],
            [-80.861783252999942, 35.329597903000035],
            [-80.861704252999971, 35.329617902000052],
            [-80.861599252999952, 35.329637902000059],
            [-80.861345252999968, 35.32966790200004],
            [-80.86074825299994, 35.329709903000037],
            [-80.85997925199996, 35.329773903000046],
            [-80.858887251999988, 35.329865903000041],
            [-80.858589251999945, 35.329896903000076],
            [-80.858471251999958, 35.329916903000026],
            [-80.858103251999978, 35.330004903000031],
            [-80.857984252999984, 35.330036902000074],
            [-80.857748251999965, 35.330119902000035],
            [-80.857729251999956, 35.330127903000061],
            [-80.857473251999977, 35.330229903000031],
            [-80.856667251999966, 35.330611903000033],
            [-80.856378250999967, 35.330752903000075],
            [-80.856086250999965, 35.330900903000042],
            [-80.85553625099999, 35.331162903000063],
            [-80.855145250999954, 35.331343903000061],
            [-80.854460250999978, 35.331675902000029],
            [-80.854137250999941, 35.33182090400004],
            [-80.853922250999972, 35.331908903000055],
            [-80.853711250999936, 35.331988903000024],
            [-80.853396249999946, 35.332091903000048],
            [-80.853217249999943, 35.332140904000028],
            [-80.852565250999987, 35.332294904000037],
            [-80.852470249999953, 35.332325903000026],
            [-80.852436250999972, 35.332345904000078],
            [-80.852414249999981, 35.332365903000039],
            [-80.85237725099995, 35.332429904000037],
            [-80.852298250999979, 35.332525903000032],
            [-80.851793250999947, 35.333001903000024],
            [-80.851654249999967, 35.333117904000062],
            [-80.851540249999971, 35.333201903000031],
            [-80.851365250999947, 35.333308904000035],
            [-80.851161248999972, 35.333410903000072],
            [-80.850927249999984, 35.333503904000054],
            [-80.850724249999985, 35.333565904000068],
            [-80.85054624899999, 35.333604904000026],
            [-80.849208249999947, 35.333788904000073],
            [-80.848757249999949, 35.33385090400003],
            [-80.848318248999988, 35.333908904000054],
            [-80.846675248999986, 35.334133904000055],
            [-80.845706248999988, 35.334265905000052],
            [-80.845415247999938, 35.334313904000055],
            [-80.845385247999957, 35.334321905000024],
            [-80.84527924799994, 35.334349904000078],
            [-80.844901247999985, 35.334474905000036],
            [-80.844753247999961, 35.334530904000076],
            [-80.844542248999971, 35.334623904000068],
            [-80.844467247999944, 35.334659903000045],
            [-80.844431247999978, 35.334678904000043],
            [-80.844373247999954, 35.334708904000024],
            [-80.843267247999961, 35.335338905000071],
            [-80.843024248999939, 35.335485905000041],
            [-80.842947247999973, 35.335560904000033],
            [-80.842826247999938, 35.335649905000025],
            [-80.842736246999948, 35.33569990500007],
            [-80.842654247999974, 35.335735904000046],
            [-80.842271246999985, 35.335903905000066],
            [-80.841668247999962, 35.336287905000063],
            [-80.840912246999949, 35.336750905000031],
            [-80.84009724799995, 35.337249905000078],
            [-80.839898246999951, 35.337366906000057],
            [-80.839705246999984, 35.33745990500006],
            [-80.839514246999954, 35.337533905000043],
            [-80.839332246999959, 35.33759490500006],
            [-80.839217246999965, 35.337627905000033],
            [-80.839147245999982, 35.337646905000042],
            [-80.839081245999978, 35.337665905000051],
            [-80.838583245999985, 35.337782904000051],
            [-80.838211245999958, 35.337865905000058],
            [-80.836923245999969, 35.338192905000028],
            [-80.835758245999955, 35.338477906000037],
            [-80.835352244999967, 35.338581905000069],
            [-80.834883245999947, 35.338696905000063],
            [-80.834301245999939, 35.338856905000057],
            [-80.833521244999986, 35.339038905000052],
            [-80.833271244999935, 35.339081906000047],
            [-80.83313024499995, 35.339098905000071],
            [-80.832898244999967, 35.339110905000041],
            [-80.832734244999983, 35.339113905000033],
            [-80.832505244999936, 35.339108905000046],
            [-80.831935243999965, 35.339097905000074],
            [-80.82832024399994, 35.339004906000071],
            [-80.827885242999969, 35.33899490500005],
            [-80.826954243999978, 35.338973906000035],
            [-80.824361242999942, 35.338903906000041],
            [-80.824177242999951, 35.338893905000077],
            [-80.824059241999976, 35.338882906000038],
            [-80.823989241999982, 35.338875906000055],
            [-80.823682240999972, 35.338825906000068],
            [-80.822240240999975, 35.338573906000079],
            [-80.821838241999956, 35.338496906000046],
            [-80.82133324199998, 35.338373905000026],
            [-80.821005240999966, 35.338276906000033],
            [-80.820791240999938, 35.338200906000054],
            [-80.820685240999978, 35.338156906000052],
            [-80.820576240999969, 35.338105906000067],
            [-80.820470241999942, 35.338043905000063],
            [-80.820338240999945, 35.337945905000026],
            [-80.82028324099997, 35.337896905000036],
            [-80.820137240999941, 35.33774190500003],
            [-80.820023240999944, 35.337605905000032],
            [-80.819780241999979, 35.337299906000055],
            [-80.819480240999951, 35.336958906000064],
            [-80.819046239999977, 35.336487906000059],
            [-80.81894024099995, 35.336390906000076],
            [-80.818828239999959, 35.336303906000069],
            [-80.818762240999945, 35.336263905000067],
            [-80.81867023999996, 35.336222905000056],
            [-80.818559239999956, 35.33619490600006],
            [-80.818475239999941, 35.336184906000028],
            [-80.818372239999974, 35.336182905000044],
            [-80.818214240999964, 35.336200905000055],
            [-80.818072239999935, 35.336227905000044],
            [-80.817950239999959, 35.336259906000066],
            [-80.817765240999961, 35.336318906000031],
            [-80.817630239999971, 35.33637090600007],
            [-80.817470239999977, 35.336447906000046],
            [-80.81727824099994, 35.336558905000061],
            [-80.816823239999962, 35.336875906000046],
            [-80.816727239999977, 35.336942905000058],
            [-80.816589239999985, 35.337029906000055],
            [-80.816406240999981, 35.33712590600004],
            [-80.816219238999963, 35.337213905000056],
            [-80.816033239999967, 35.337280906000046],
            [-80.814406238999936, 35.337810907000062],
            [-80.813914238999985, 35.337959906000037],
            [-80.813511238999979, 35.338075906000029],
            [-80.813152238999976, 35.33816790700007],
            [-80.812428238999985, 35.338321907000079],
            [-80.811115237999957, 35.33859190700008],
            [-80.810599237999952, 35.33869790600005],
            [-80.809772237999937, 35.338860907000026],
            [-80.808983237999939, 35.339015906000043],
            [-80.808684237999955, 35.339074907000054],
            [-80.808448237999983, 35.33911390600008],
            [-80.808325237999952, 35.339128907000031],
            [-80.808193236999955, 35.339137907000065],
            [-80.808036237999943, 35.339135906000024],
            [-80.807884237999986, 35.339128906000042],
            [-80.807652236999957, 35.33909890700005],
            [-80.807210236999936, 35.339022906000025],
            [-80.805952236999985, 35.338825907000057],
            [-80.804051235999964, 35.338533907000055],
            [-80.803858236999986, 35.338503906000028],
            [-80.802102235999939, 35.338253906000034],
            [-80.801751234999983, 35.338203907000036],
            [-80.801037235999956, 35.338105907000056],
            [-80.799367234999977, 35.337972907000051],
            [-80.798496234999959, 35.337915906000035],
            [-80.796659233999947, 35.337784906000024],
            [-80.796626233999973, 35.338056907000066],
            [-80.796552233999989, 35.338581906000059],
            [-80.796434234999936, 35.339495907000071],
            [-80.796405233999963, 35.339602907000028],
            [-80.796388233999949, 35.339802908000024],
            [-80.796366233999947, 35.33993190700005],
            [-80.796322233999945, 35.340072908000025],
            [-80.796145233999937, 35.340568907000034],
            [-80.795761232999951, 35.34164290700005],
            [-80.795633233999979, 35.342015908000064],
            [-80.795506233999959, 35.342495908000046],
            [-80.795429233999982, 35.342832907000059],
            [-80.795412233999969, 35.342929908000031],
            [-80.795396233999952, 35.343105908000041],
            [-80.79539323399996, 35.343211908000058],
            [-80.795405233999986, 35.343617908000056],
            [-80.795402234999983, 35.343655908000073],
            [-80.795396233999952, 35.343708908000053],
            [-80.795429233999982, 35.344386909000036],
            [-80.795711234999942, 35.34701190800007],
            [-80.795741233999934, 35.347278909000067],
            [-80.795768233999979, 35.347550908000073],
            [-80.79579023499997, 35.34778091000004],
            [-80.795811234999974, 35.348044909000066],
            [-80.795881233999978, 35.348936908000042],
            [-80.795974233999971, 35.350094909000063],
            [-80.795977234999953, 35.350282909000043],
            [-80.795959233999952, 35.350469909000026],
            [-80.795949233999977, 35.350527910000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795912234999946, 35.350689909000039],
            [-80.795924234999973, 35.350762910000071],
            [-80.795845233999955, 35.350827909000031],
            [-80.795817233999969, 35.350859910000054],
            [-80.79579423399997, 35.350894910000079],
            [-80.795246234999979, 35.352010910000047],
            [-80.795148234999942, 35.352198910000027],
            [-80.795143233999966, 35.352207910000061],
            [-80.794993234999936, 35.352494910000075],
            [-80.794889233999982, 35.352679909000074],
            [-80.79472123499994, 35.353003910000041],
            [-80.794592233999936, 35.353298911000024],
            [-80.794308233999971, 35.354033910000055],
            [-80.794259233999981, 35.354203910000024],
            [-80.794232233999935, 35.354376910000042],
            [-80.794228233999945, 35.35450091000007],
            [-80.794243233999964, 35.354664910000054],
            [-80.794266234999952, 35.354800911000041],
            [-80.794301234999978, 35.354924911000069],
            [-80.794327234999969, 35.354984911000031],
            [-80.794382233999954, 35.355082910000078],
            [-80.79446623399997, 35.355181911000045],
            [-80.794587233999948, 35.355283911000072],
            [-80.79467923499999, 35.35533791000006],
            [-80.794827234999957, 35.355424910000067],
            [-80.795254234999959, 35.35565691100004],
            [-80.795571234999954, 35.355819910000037],
            [-80.797256235999953, 35.356629911000027],
            [-80.797561235999979, 35.356782910000049],
            [-80.797742235999976, 35.356880911000076],
            [-80.797908234999966, 35.356993910000028],
            [-80.798015235999969, 35.357091910000065],
            [-80.798130234999974, 35.357221910000078],
            [-80.798263235999968, 35.35740891100005],
            [-80.798339234999958, 35.357540911000058],
            [-80.798605235999958, 35.358067911000035],
            [-80.798719235999954, 35.358270911000034],
            [-80.798848235999969, 35.358548911000071],
            [-80.799051235999968, 35.358940911000047],
            [-80.799117234999983, 35.359049912000046],
            [-80.79924523599999, 35.359229912000046],
            [-80.799348234999968, 35.35935591100008],
            [-80.799633235999977, 35.359705911000049],
            [-80.799779235999949, 35.35991591100003],
            [-80.799856235999982, 35.360064912000041],
            [-80.799902236999969, 35.360178912000038],
            [-80.799913234999963, 35.360216912000055],
            [-80.799935235999953, 35.360268911000048],
            [-80.799952235999967, 35.360326911000072],
            [-80.799622235999948, 35.360418912000057],
            [-80.798988235999957, 35.360611912000024],
            [-80.798501235999936, 35.360765911000044],
            [-80.797442234999949, 35.361090912000066],
            [-80.797181234999982, 35.361181911000074],
            [-80.797062234999942, 35.361228911000069],
            [-80.796106234999968, 35.361662912000043],
            [-80.79504323499998, 35.362153912000053],
            [-80.794044233999955, 35.362620912000068],
            [-80.793893234999985, 35.362692912000057],
            [-80.79375323499994, 35.362753912000073],
            [-80.793432234999955, 35.362907912000026],
            [-80.792583233999949, 35.363296912000067],
            [-80.792412234999972, 35.36336691200006],
            [-80.792199233999952, 35.363451912000073],
            [-80.791902233999963, 35.363558913000077],
            [-80.791545233999955, 35.363679912000066],
            [-80.791312232999985, 35.363761913000076],
            [-80.791192232999947, 35.363803913000027],
            [-80.791017232999934, 35.363860912000064],
            [-80.79080823399994, 35.363930912000058],
            [-80.790297233999979, 35.364097912000034],
            [-80.78977523399999, 35.364281912000024],
            [-80.789337233999959, 35.364425913000048],
            [-80.789110232999974, 35.364524912000036],
            [-80.78895423299997, 35.364602913000056],
            [-80.788622231999966, 35.364780913000061],
            [-80.788296232999983, 35.364966913000046],
            [-80.787528231999943, 35.365370913000049],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365560913000024],
            [-80.787499232999949, 35.365769912000076],
            [-80.78746023399998, 35.365936914000031],
            [-80.787396232999981, 35.366161913000042],
            [-80.792751234999969, 35.368284913000025],
            [-80.794869234999965, 35.369124913000064],
            [-80.79568123599995, 35.369461914000055],
            [-80.795949234999966, 35.370134913000072],
            [-80.796038234999969, 35.370239914000024],
            [-80.796087234999959, 35.370324914000037],
            [-80.796123235999971, 35.370414914000037],
            [-80.796280235999973, 35.371011914000064],
            [-80.796325234999983, 35.371120914000073],
            [-80.796389234999936, 35.371225914000036],
            [-80.796635235999986, 35.371587914000031],
            [-80.796870235999961, 35.371958914000061],
            [-80.796915235999961, 35.372047913000074],
            [-80.796933235999973, 35.372133914000074],
            [-80.796958235999966, 35.372212914000045],
            [-80.796959235999964, 35.37238691400006],
            [-80.796966234999957, 35.372515914000076],
            [-80.796976235999978, 35.372682915000041],
            [-80.796997235999982, 35.372774914000047],
            [-80.797039235999989, 35.372922914000071],
            [-80.797416235999947, 35.373811914000044],
            [-80.797446236999974, 35.373914914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797468235999986, 35.374094914000068],
            [-80.797451236999962, 35.374244915000077],
            [-80.797408235999967, 35.374408914000071],
            [-80.797247235999976, 35.374933914000053],
            [-80.797101235999946, 35.375382914000056],
            [-80.79768123599996, 35.375517915000046],
            [-80.797975236999946, 35.375592915000027],
            [-80.798128235999968, 35.375652915000046],
            [-80.798258235999981, 35.375729914000033],
            [-80.798362236999935, 35.375802915000065],
            [-80.798447236999948, 35.375885915000026],
            [-80.798501236999982, 35.375957915000072],
            [-80.798560236999947, 35.376061915000037],
            [-80.798594236999975, 35.376165915000058],
            [-80.798619236999969, 35.376291915000024],
            [-80.798623236999958, 35.376401914000041],
            [-80.798611236999989, 35.376517915000079],
            [-80.798570235999989, 35.376642916000037],
            [-80.798507235999978, 35.376789915000074],
            [-80.798463236999964, 35.376912915000048],
            [-80.798449235999954, 35.376998915000058],
            [-80.79845323699999, 35.37707391500004],
            [-80.798466235999967, 35.377154915000062],
            [-80.798507236999967, 35.377240915000073],
            [-80.79855123699997, 35.377312914000072],
            [-80.798589235999941, 35.377364916000033],
            [-80.798712236999961, 35.377486914000031],
            [-80.797820236999939, 35.378089914000043],
            [-80.797605236999971, 35.378237916000046],
            [-80.797417236999934, 35.378367915000069],
            [-80.796784235999951, 35.378796915000066],
            [-80.796537235999949, 35.378964916000029],
            [-80.795169236999982, 35.379910915000039],
            [-80.794706235999968, 35.380224916000031],
            [-80.794392234999975, 35.380420916000048],
            [-80.794257234999975, 35.380490915000053],
            [-80.794104235999953, 35.380559916000038],
            [-80.793955235999988, 35.380613916000073],
            [-80.793399234999981, 35.380789916000026],
            [-80.79240923499998, 35.381092915000067],
            [-80.791912235999973, 35.38124491700006],
            [-80.791240234999975, 35.381449916000065],
            [-80.790655234999974, 35.38162791700006],
            [-80.790401233999944, 35.381705916000044],
            [-80.790291234999984, 35.381739916000072],
            [-80.789902233999953, 35.381876916000067],
            [-80.789763233999963, 35.381933916000037],
            [-80.789696233999962, 35.381965917000059],
            [-80.789469233999966, 35.382082917000048],
            [-80.789291233999961, 35.38218691600008],
            [-80.789050233999944, 35.382340916000032],
            [-80.788806233999935, 35.382512917000042],
            [-80.788435233999962, 35.382791916000031],
            [-80.788118233999967, 35.383049916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.786700233999966, 35.384445916000061],
            [-80.785941233999949, 35.385195917000033],
            [-80.785334232999958, 35.385761918000071],
            [-80.784783232999985, 35.386234918000071],
            [-80.784700232999967, 35.386299917000031],
            [-80.78233923199997, 35.38815291700007],
            [-80.78141223199998, 35.388881918000038],
            [-80.779635231999976, 35.390281918000028],
            [-80.779433231999974, 35.390435918000037],
            [-80.779261231999953, 35.390556918000073],
            [-80.77899923199999, 35.390720919000046],
            [-80.778744231999951, 35.390860919000033],
            [-80.778606230999969, 35.390927919000035],
            [-80.778365230999952, 35.391035919000046],
            [-80.778006230999949, 35.391183919000071],
            [-80.778089230999967, 35.391297919000067],
            [-80.780835231999959, 35.395009919000074],
            [-80.782405232999963, 35.397131920000049],
            [-80.783722233999981, 35.398911921000035],
            [-80.781756231999964, 35.399425920000056],
            [-80.781609232999983, 35.399464920000071],
            [-80.78152823299996, 35.399959921000061],
            [-80.781306232999952, 35.400481920000061],
            [-80.780617232999987, 35.402109921000033],
            [-80.78050723299998, 35.402369921000059],
            [-80.779215232999945, 35.405415921000042],
            [-80.778600232999963, 35.406866922000063],
            [-80.778380231999961, 35.407267922000074],
            [-80.779746232999969, 35.409076922000054],
            [-80.778319232999934, 35.409293922000074],
            [-80.774467231999949, 35.409852923000074],
            [-80.77464723199995, 35.412072923000039],
            [-80.77468023199998, 35.412043923000056],
            [-80.774795230999985, 35.411957923000045],
            [-80.774835231999987, 35.411895923000031],
            [-80.774847231999956, 35.411841923000054],
            [-80.774869230999968, 35.411792923000064],
            [-80.774931231999972, 35.41173092300005],
            [-80.775001231999966, 35.41169392300003],
            [-80.775138231999961, 35.411649923000027],
            [-80.775221230999989, 35.411617923000051],
            [-80.775310231999981, 35.41159592300005],
            [-80.775400230999935, 35.411566923000066],
            [-80.775561231999973, 35.411543923000067],
            [-80.77588923199994, 35.411468923000029],
            [-80.775947231999965, 35.411465924000026],
            [-80.776008230999935, 35.411454923000065],
            [-80.776192231999971, 35.411443923000036],
            [-80.776250231999938, 35.411434924000048],
            [-80.776315230999955, 35.411419923000039],
            [-80.776376231999961, 35.411396923000041],
            [-80.776439231999973, 35.411367923000057],
            [-80.776475231999939, 35.411360923000075],
            [-80.776517231999946, 35.411352923000038],
            [-80.776593231999982, 35.411327923000044],
            [-80.776644231999967, 35.411319923000065],
            [-80.776882230999945, 35.411309923000033],
            [-80.776959231999967, 35.41129092400007],
            [-80.777028231999964, 35.411278924000044],
            [-80.777196231999937, 35.411277924000046],
            [-80.777251231999969, 35.411270923000075],
            [-80.777359231999981, 35.411222923000025],
            [-80.777454231999968, 35.411194923000039],
            [-80.777593232999948, 35.411176923000028],
            [-80.77779923199995, 35.411139924000054],
            [-80.777890232999937, 35.411132923000025],
            [-80.778176232999954, 35.41108592300003],
            [-80.778279232999978, 35.411061923000034],
            [-80.778361231999952, 35.411056923000046],
            [-80.778514232999953, 35.411035923000043],
            [-80.778679232999934, 35.411002923000069],
            [-80.778748232999988, 35.410975923000024],
            [-80.778848232999962, 35.410948923000035],
            [-80.779038232999937, 35.410873922000064],
            [-80.779299232999961, 35.410825923000061],
            [-80.779398231999949, 35.410789924000028],
            [-80.779621232999943, 35.410735922000072],
            [-80.77980923299998, 35.410730923000074],
            [-80.77990323299997, 35.41070592300008],
            [-80.779957232999948, 35.410684923000076],
            [-80.780012233999969, 35.410651923000046],
            [-80.780097231999946, 35.410612923000031],
            [-80.780171232999976, 35.41058692200005],
            [-80.780265232999966, 35.410571923000077],
            [-80.780442232999974, 35.410561922000056],
            [-80.780512232999968, 35.410546922000037],
            [-80.780684232999988, 35.410449923000044],
            [-80.780733232999978, 35.410425922000059],
            [-80.780815233999988, 35.410404922000055],
            [-80.780898232999959, 35.410397923000062],
            [-80.781004232999976, 35.410382922000053],
            [-80.781095232999974, 35.410357923000049],
            [-80.781159233999972, 35.410330922000071],
            [-80.78124623299999, 35.410320923000029],
            [-80.781320233999963, 35.410303923000072],
            [-80.781706232999966, 35.410190922000027],
            [-80.781786233999981, 35.410157923000043],
            [-80.781881233999968, 35.410131922000062],
            [-80.781969233999973, 35.410112922000053],
            [-80.782108233999963, 35.410108923000053],
            [-80.782196233999969, 35.410110923000047],
            [-80.782359233999955, 35.410129922000067],
            [-80.78245123399995, 35.410145923000073],
            [-80.782764234999945, 35.410181923000039],
            [-80.783070233999979, 35.410227922000047],
            [-80.783224233999988, 35.410238922000076],
            [-80.783818233999966, 35.410228922000044],
            [-80.783905233999974, 35.410204923000038],
            [-80.784016233999978, 35.410179923000044],
            [-80.784323234999988, 35.410177922000059],
            [-80.784387233999951, 35.410171922000075],
            [-80.78444323399998, 35.410159923000037],
            [-80.78475423499998, 35.410140922000039],
            [-80.784862233999945, 35.410139922000042],
            [-80.784951233999948, 35.41014692300007],
            [-80.785137234999979, 35.410189922000029],
            [-80.785384234999981, 35.410273923000034],
            [-80.785492234999936, 35.410303923000072],
            [-80.785668234999946, 35.410324922000029],
            [-80.785882234999974, 35.410373922000076],
            [-80.785925235999969, 35.410368922000032],
            [-80.786037234999981, 35.410397923000062],
            [-80.786243234999972, 35.410411922000037],
            [-80.786335234999967, 35.410411922000037],
            [-80.786458234999941, 35.410389923000025],
            [-80.786682234999944, 35.410371922000024],
            [-80.787136233999945, 35.410282923000068],
            [-80.787198233999959, 35.410324922000029],
            [-80.787335234999944, 35.410345922000033],
            [-80.787429234999934, 35.410350923000067],
            [-80.787628234999943, 35.410384922000048],
            [-80.78767023499995, 35.410399923000057],
            [-80.787833235999983, 35.410426922000056],
            [-80.787994233999939, 35.410471923000046],
            [-80.788039234999985, 35.410493923000047],
            [-80.788173234999988, 35.410498923000034],
            [-80.788232234999953, 35.410509922000074],
            [-80.788283235999984, 35.410530923000067],
            [-80.788525235999941, 35.410669923000057],
            [-80.788655235999954, 35.410752923000075],
            [-80.788694235999969, 35.410767922000048],
            [-80.788770235999948, 35.41080792300005],
            [-80.788839235999944, 35.410874922000062],
            [-80.789014235999957, 35.410971922000044],
            [-80.789060235999955, 35.410996922000038],
            [-80.789383235999935, 35.411232923000057],
            [-80.789910235999969, 35.411580923000031],
            [-80.790065235999975, 35.411678923000068],
            [-80.79011223599997, 35.411716923000029],
            [-80.790309236999974, 35.411848922000047],
            [-80.790431234999971, 35.411944922000032],
            [-80.790521235999961, 35.412021922000065],
            [-80.79063823599995, 35.41210592200008],
            [-80.790689236999981, 35.412149923000072],
            [-80.79081423699995, 35.41228592300007],
            [-80.790915236999979, 35.412382923000052],
            [-80.790965236999966, 35.412443923000069],
            [-80.791081235999968, 35.412533923000069],
            [-80.791240236999954, 35.412730923000026],
            [-80.79145523599999, 35.41304092300004],
            [-80.791481236999971, 35.413098922000074],
            [-80.791531235999969, 35.413189923000061],
            [-80.791697236999937, 35.413465922000057],
            [-80.791844235999974, 35.413663922000069],
            [-80.791864235999981, 35.413702923000073],
            [-80.792006236999953, 35.413964924000027],
            [-80.792133236999973, 35.414167922000047],
            [-80.792267236999976, 35.414350923000029],
            [-80.792417236999938, 35.414601923000077],
            [-80.792622236999989, 35.414912923000031],
            [-80.792633236999961, 35.414945924000051],
            [-80.792634237999948, 35.414991923000059],
            [-80.79266423699994, 35.415047923000031],
            [-80.792695235999986, 35.415080924000051],
            [-80.792715236999982, 35.415113923000035],
            [-80.792714235999938, 35.415137924000078],
            [-80.792757236999989, 35.415187924000065],
            [-80.792779236999934, 35.41519792400004],
            [-80.792817236999952, 35.415201923000041],
            [-80.79284023699995, 35.415214924000054],
            [-80.792863236999949, 35.415238923000061],
            [-80.792874236999978, 35.41526192300006],
            [-80.792875235999986, 35.415281923000066],
            [-80.792854236999972, 35.415425924000033],
            [-80.792862236999952, 35.415516923000041],
            [-80.792853236999974, 35.415548924000063],
            [-80.792826236999986, 35.415595923000069],
            [-80.792817236999952, 35.415635923000025],
            [-80.792817236999952, 35.41579792400006],
            [-80.792857236999964, 35.415865923000069],
            [-80.792886237999937, 35.415891923000061],
            [-80.792909236999947, 35.415939923000053],
            [-80.792924237999955, 35.415982923000058],
            [-80.792952237999941, 35.416014924000024],
            [-80.792973237999945, 35.416029924000043],
            [-80.793020236999951, 35.41604592300007],
            [-80.793186236999986, 35.416178923000075],
            [-80.793460236999977, 35.41633192300003],
            [-80.79360823799999, 35.416421924000076],
            [-80.793729237999969, 35.416475924000054],
            [-80.79384123799997, 35.416538924000065],
            [-80.793918236999957, 35.416591923000055],
            [-80.794090237999967, 35.41668792300004],
            [-80.794143237999947, 35.41672892400004],
            [-80.794270237999967, 35.416778924000027],
            [-80.794415237999942, 35.416841924000039],
            [-80.794636237999953, 35.416964924000069],
            [-80.794758237999986, 35.417022923000047],
            [-80.794843237999942, 35.417071924000027],
            [-80.79497523799995, 35.417128924000053],
            [-80.795105237999962, 35.417203924000034],
            [-80.79554223699995, 35.41741492400007],
            [-80.795591237999986, 35.417442924000056],
            [-80.795659237999985, 35.417464923000068],
            [-80.795740237999951, 35.417501924000078],
            [-80.795773238999971, 35.417521923000038],
            [-80.795849238999949, 35.417596924000065],
            [-80.796040238999979, 35.417814923000037],
            [-80.796110237999983, 35.417915923000066],
            [-80.796150237999939, 35.417951923000032],
            [-80.796233237999957, 35.417998924000074],
            [-80.796339237999973, 35.418164923000063],
            [-80.796405237999977, 35.41829292400007],
            [-80.796512237999934, 35.418466924000029],
            [-80.79656523899996, 35.418531924000035],
            [-80.796604238999976, 35.418619924000041],
            [-80.796658238999953, 35.418673923000028],
            [-80.796733237999945, 35.418774924000047],
            [-80.796794237999961, 35.41887192400003],
            [-80.796924238999964, 35.41903592400007],
            [-80.797098238999979, 35.419267924000053],
            [-80.797286238999959, 35.41950492400008],
            [-80.797393238999973, 35.419667923000077],
            [-80.797445238999956, 35.419717924000054],
            [-80.797477238999988, 35.419763924000051],
            [-80.797535238999956, 35.419826924000063],
            [-80.797581237999964, 35.419912924000073],
            [-80.797649237999963, 35.419994925000026],
            [-80.797682238999982, 35.420026924000069],
            [-80.797721238999941, 35.420047924000073],
            [-80.79777723899997, 35.420092925000063],
            [-80.797843237999984, 35.420113925000067],
            [-80.797962238999958, 35.420227924000073],
            [-80.797531237999976, 35.420587924000074],
            [-80.797321238999984, 35.420775925000044],
            [-80.796883238999953, 35.421202925000046],
            [-80.794835238999951, 35.42324592500006],
            [-80.793969237999988, 35.424109925000039],
            [-80.793259237999962, 35.424818925000068],
            [-80.792769237999948, 35.425308926000071],
            [-80.79264823799997, 35.425418925000031],
            [-80.792575237999984, 35.425477925000052],
            [-80.792478237999944, 35.425546925000049],
            [-80.792338237999957, 35.425448926000058],
            [-80.792016236999984, 35.425297926000042],
            [-80.791172237999945, 35.424949926000068],
            [-80.791049236999982, 35.424890926000046],
            [-80.790073236999945, 35.424362926000072],
            [-80.78835223699997, 35.423423925000066],
            [-80.788223235999965, 35.423359925000057],
            [-80.788108235999971, 35.423313925000059],
            [-80.788003235999952, 35.423276925000039],
            [-80.787908236999954, 35.423250925000048],
            [-80.78780123599995, 35.423227926000038],
            [-80.787714236999989, 35.423213926000074],
            [-80.787501235999969, 35.423198925000065],
            [-80.787183235999976, 35.423205925000048],
            [-80.785620234999953, 35.423312925000062],
            [-80.78318623499996, 35.423445925000067],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801624241999946, 35.442241929000033],
            [-80.801465241999949, 35.442311929000027],
            [-80.801007240999979, 35.442466928000044],
            [-80.800832240999966, 35.442536929000028],
            [-80.800729240999942, 35.442586928000026],
            [-80.800630241999954, 35.442641928000057],
            [-80.800511240999981, 35.44272292900007],
            [-80.800395241999979, 35.442813929000067],
            [-80.800232240999946, 35.442957928000055],
            [-80.800047239999969, 35.44313992900004],
            [-80.799473240999987, 35.443746930000032],
            [-80.798915239999985, 35.444311929000037],
            [-80.798808239999971, 35.444432929000072],
            [-80.79874523999996, 35.444516929000031],
            [-80.79865523999996, 35.444657929000073],
            [-80.798494239999968, 35.444981929000051],
            [-80.797555239999951, 35.447156930000062],
            [-80.797365239999976, 35.447605930000066],
            [-80.796973239999943, 35.448574930000063],
            [-80.79682324099997, 35.448923930000035],
            [-80.796631240999943, 35.449316930000066],
            [-80.796582239999964, 35.449402931000066],
            [-80.796350239999981, 35.449764930000072],
            [-80.796015238999985, 35.450243931000045],
            [-80.795560239999986, 35.45000893100007],
            [-80.795320239999967, 35.449898931000064],
            [-80.795118239999965, 35.449813931000051],
            [-80.79485023899997, 35.449710930000037],
            [-80.79466623899998, 35.449647931000072],
            [-80.794291238999961, 35.449539931000061],
            [-80.794003239999938, 35.449471930000072],
            [-80.791847238999935, 35.448984931000041],
            [-80.79148923799994, 35.448895931000038],
            [-80.791312237999989, 35.448845930000061],
            [-80.790889238999966, 35.448709930000064],
            [-80.790459237999983, 35.448540931000025],
            [-80.790059238999959, 35.448367931000064],
            [-80.789453237999965, 35.448105930000054],
            [-80.789332237999986, 35.448049930000025],
            [-80.787848236999935, 35.447406931000046],
            [-80.786688236999964, 35.446910930000058],
            [-80.786244236999948, 35.446732931000042],
            [-80.786040236999952, 35.446663931000046],
            [-80.785661236999943, 35.446557931000029],
            [-80.785479235999958, 35.446516931000076],
            [-80.785191235999946, 35.446465930000045],
            [-80.785064236999972, 35.446447930000033],
            [-80.783836236999946, 35.446321931000057],
            [-80.783541235999962, 35.446283931000039],
            [-80.783331235999981, 35.446244930000034],
            [-80.782997235999972, 35.446164930000066],
            [-80.782748235999975, 35.446092930000077],
            [-80.782519234999938, 35.446015930000044],
            [-80.782274235999978, 35.445922930000052],
            [-80.781961235999972, 35.445772930000032],
            [-80.781708235999986, 35.445639931000073],
            [-80.781431234999957, 35.445462930000076],
            [-80.78118623599994, 35.445281930000078],
            [-80.780984234999949, 35.445131930000059],
            [-80.780575234999958, 35.444815930000061],
            [-80.780383234999988, 35.444673930000079],
            [-80.780058234999956, 35.444457930000056],
            [-80.77989123499998, 35.444364930000063],
            [-80.779549234999934, 35.444196930000032],
            [-80.779312234999963, 35.444099931000039],
            [-80.778974234999964, 35.443977930000074],
            [-80.778884234999964, 35.443945929000051],
            [-80.778637233999973, 35.443863930000077],
            [-80.77721223399999, 35.443388930000026],
            [-80.776666232999958, 35.443206929000041],
            [-80.775731232999988, 35.442894929000033],
            [-80.775266233999957, 35.442738929000029],
            [-80.770722231999969, 35.441224930000033],
            [-80.770186231999958, 35.441055930000061],
            [-80.769798231999971, 35.44095193000004],
            [-80.769598231999964, 35.440907929000048],
            [-80.769294230999947, 35.440850929000078],
            [-80.76893523199999, 35.440797930000031],
            [-80.768610230999968, 35.440765930000055],
            [-80.768360230999974, 35.440746929000056],
            [-80.768020231999969, 35.440741930000058],
            [-80.767766231999985, 35.440741929000069],
            [-80.767373230999965, 35.440765930000055],
            [-80.765654229999939, 35.440911929000038],
            [-80.764912229999936, 35.440974930000039],
            [-80.764915230999975, 35.440980929000034],
            [-80.765434229999983, 35.442026930000054],
            [-80.766182230999959, 35.443530930000065],
            [-80.767076230999976, 35.445330930000068],
            [-80.767080230999966, 35.445337930000051],
            [-80.767084230999956, 35.445346931000074],
            [-80.76709723099998, 35.445373930000073],
            [-80.767441230999964, 35.446054930000059],
            [-80.770616232999942, 35.452459932000068],
            [-80.770767232999958, 35.45276393100005],
            [-80.771021232999942, 35.453290932000073],
            [-80.771253232999982, 35.453771932000052],
            [-80.771632232999934, 35.454558932000054],
            [-80.772765232999973, 35.456999933000077],
            [-80.77286023299996, 35.457344932000069],
            [-80.773052233999977, 35.457265932000041],
            [-80.773244233999947, 35.457225933000075],
            [-80.773662232999982, 35.457177933000025],
            [-80.774234232999959, 35.45704093300003],
            [-80.774754232999953, 35.456932933000076],
            [-80.775382233999949, 35.456937932000073],
            [-80.775680234999982, 35.457004933000064],
            [-80.776277234999952, 35.457195933000037],
            [-80.776876234999975, 35.457486933000041],
            [-80.777633234999939, 35.45783193300008],
            [-80.778479234999963, 35.458275933000039],
            [-80.778969235999966, 35.458368933000031],
            [-80.779324235999979, 35.458634932000052],
            [-80.779376234999972, 35.458704933000035],
            [-80.77964723499997, 35.459071933000075],
            [-80.779760235999959, 35.459258933000058],
            [-80.779810235999946, 35.459340933000078],
            [-80.780026235999969, 35.459667933000048],
            [-80.780080235999947, 35.459749933000069],
            [-80.780227235999973, 35.460104933000025],
            [-80.78065623599997, 35.460582933000069],
            [-80.780960235999942, 35.460906934000036],
            [-80.781332236999958, 35.461200934000033],
            [-80.781845235999981, 35.461563934000026],
            [-80.782499235999978, 35.461939934000043],
            [-80.783205236999947, 35.462370934000035],
            [-80.783396236999977, 35.462515933000077],
            [-80.783825235999984, 35.462789933000067],
            [-80.784108236999941, 35.462985934000073],
            [-80.784355236999943, 35.463123934000066],
            [-80.784533237999938, 35.463306934000059],
            [-80.785219237999968, 35.463567934000025],
            [-80.785664237999981, 35.463713934000054],
            [-80.785851236999974, 35.463772934000076],
            [-80.786019237999938, 35.463852934000045],
            [-80.786137237999981, 35.463912934000064],
            [-80.78662623799994, 35.464145934000044],
            [-80.787282237999989, 35.464635934000057],
            [-80.787776237999935, 35.464899934000073],
            [-80.78804723799999, 35.46530993500005],
            [-80.788331238999945, 35.465575934000071],
            [-80.788582237999947, 35.465871934000063],
            [-80.78906423899997, 35.466420935000031],
            [-80.789458237999952, 35.466899934000025],
            [-80.789571239999987, 35.467008935000024],
            [-80.789654238999958, 35.467081934000078],
            [-80.78983723999994, 35.467478935000031],
            [-80.790246238999941, 35.467828935000057],
            [-80.790379238999947, 35.467904934000046],
            [-80.790464239999949, 35.467951934000041],
            [-80.79044523999994, 35.468013935000045],
            [-80.79045623899998, 35.468046935000075],
            [-80.79049723899999, 35.468126935000043],
            [-80.790584239999987, 35.468382934000033],
            [-80.790692239999942, 35.468756935000044],
            [-80.790785238999945, 35.469026934000055],
            [-80.790879239999981, 35.469275935000041],
            [-80.79090623899998, 35.469375934000027],
            [-80.790907239999967, 35.469426935000058],
            [-80.790926238999987, 35.469509935000076],
            [-80.791010239999935, 35.46960593600005],
            [-80.791025239999954, 35.469659935000038],
            [-80.791025239999954, 35.469712935000075],
            [-80.791032239999936, 35.469780935000074],
            [-80.791074239999944, 35.469935935000024],
            [-80.791110239999966, 35.470002935000025],
            [-80.791145238999945, 35.470049935000077],
            [-80.791206239999951, 35.470170935000056],
            [-80.791232238999953, 35.470248935000029],
            [-80.791363239999953, 35.470438935000061],
            [-80.791488239999978, 35.470595935000063],
            [-80.791513238999983, 35.470643935000055],
            [-80.791533239999978, 35.470701935000079],
            [-80.791579239999976, 35.470769935000078],
            [-80.791731238999944, 35.470963935000043],
            [-80.791777239999988, 35.471001935000061],
            [-80.79182123999999, 35.471049936000043],
            [-80.791852239999969, 35.471103935000031],
            [-80.791871239999978, 35.471194935000028],
            [-80.791900239999961, 35.471255935000045],
            [-80.79196823999996, 35.471364935000054],
            [-80.791970239999955, 35.471382936000055],
            [-80.791996239999946, 35.471582935000072],
            [-80.792015238999966, 35.471688935000032],
            [-80.792055239999968, 35.471765935000064],
            [-80.792086239999946, 35.471850936000067],
            [-80.792117239999982, 35.471917935000079],
            [-80.79215623999994, 35.471987936000062],
            [-80.792186239999978, 35.472087935000047],
            [-80.792195239999955, 35.472199936000038],
            [-80.792239239999958, 35.472375936000049],
            [-80.792283239999961, 35.472509936000051],
            [-80.792299240999967, 35.472577935000061],
            [-80.792316239999934, 35.472625936000043],
            [-80.792342240999972, 35.472680936000074],
            [-80.792364238999937, 35.47275793600005],
            [-80.792401239999947, 35.472890935000066],
            [-80.792421239999953, 35.473098936000042],
            [-80.79246724099994, 35.473241936000079],
            [-80.792484239999965, 35.473274936000053],
            [-80.792498240999976, 35.473336935000077],
            [-80.792500239999981, 35.473398935000034],
            [-80.79251524099999, 35.473479936000047],
            [-80.79258023999995, 35.473647936000077],
            [-80.792616238999983, 35.473718936000068],
            [-80.792659239999978, 35.473780936000026],
            [-80.792766240999981, 35.473968935000073],
            [-80.792818239999974, 35.474041935000059],
            [-80.792978239999968, 35.47420293600004],
            [-80.793015240999978, 35.474271936000036],
            [-80.793062240999973, 35.474332935000064],
            [-80.793158240999958, 35.474415936000071],
            [-80.79322123999998, 35.474491936000049],
            [-80.793245239999976, 35.474513935000061],
            [-80.793486239999936, 35.474879935000047],
            [-80.793646240999976, 35.475014936000036],
            [-80.793733240999984, 35.475107937000075],
            [-80.794012240999962, 35.475389936000056],
            [-80.794194241999946, 35.475589936000063],
            [-80.794299240999976, 35.475712937000026],
            [-80.794686240999965, 35.476105936000067],
            [-80.794859240999983, 35.476257936000025],
            [-80.794930240999975, 35.476363936000041],
            [-80.795092240999963, 35.47653293500008],
            [-80.79516124099996, 35.476621936000072],
            [-80.795234240999946, 35.476743936000048],
            [-80.795298240999955, 35.476822937000065],
            [-80.795355240999982, 35.476855937000039],
            [-80.795392240999945, 35.476884936000033],
            [-80.795448240999974, 35.476999937000073],
            [-80.795541240999967, 35.477116937000062],
            [-80.795632240999964, 35.477182936000077],
            [-80.795696241999963, 35.477258936000055],
            [-80.795766240999967, 35.477374936000047],
            [-80.79583224199996, 35.477464937000036],
            [-80.795932241999935, 35.477561937000075],
            [-80.796111241999938, 35.47775593700004],
            [-80.796160240999939, 35.477836937000063],
            [-80.796459241999969, 35.478222936000066],
            [-80.796553241999959, 35.478358936000063],
            [-80.796632241999987, 35.478440937000073],
            [-80.796683241999972, 35.478518937000047],
            [-80.796714240999961, 35.478576937000071],
            [-80.796782241999949, 35.478663936000032],
            [-80.796824241999957, 35.478726937000033],
            [-80.796964241999945, 35.47886993700007],
            [-80.797080241999936, 35.479023937000079],
            [-80.797227241999963, 35.479183938000062],
            [-80.797321241999953, 35.479311937000034],
            [-80.797432241999957, 35.479430937000075],
            [-80.797519242999954, 35.479504937000058],
            [-80.797570241999949, 35.47957593600006],
            [-80.797609241999965, 35.479660936000073],
            [-80.79764824099999, 35.47971493700004],
            [-80.797898241999974, 35.480023936000066],
            [-80.797935241999937, 35.480082937000077],
            [-80.798018241999955, 35.480188937000037],
            [-80.798109241999953, 35.480291937000061],
            [-80.798157241999945, 35.480338936000066],
            [-80.79820024199995, 35.480396936000034],
            [-80.798222241999952, 35.48045393700005],
            [-80.798265241999957, 35.480523937000044],
            [-80.798330241999963, 35.48059293600005],
            [-80.798359242999936, 35.480611936000059],
            [-80.798420242999953, 35.480700938000041],
            [-80.798634242999981, 35.480927937000047],
            [-80.798720242999934, 35.48104993700008],
            [-80.798839242999975, 35.481235936000076],
            [-80.798896241999955, 35.481302937000066],
            [-80.798954242999969, 35.481356937000044],
            [-80.799038242999984, 35.481459937000068],
            [-80.799108242999978, 35.481554937000055],
            [-80.799203242999965, 35.48165493700003],
            [-80.799307241999941, 35.481791937000025],
            [-80.799400243999969, 35.481928937000077],
            [-80.799647242999981, 35.482215937000035],
            [-80.800059242999964, 35.482729938000034],
            [-80.800322241999936, 35.48307993800006],
            [-80.800453243999982, 35.483223937000048],
            [-80.800558242999955, 35.483354938000048],
            [-80.800619242999971, 35.483422938000047],
            [-80.800685243999965, 35.483511938000049],
            [-80.800748242999987, 35.483571938000068],
            [-80.800921242999948, 35.483702938000079],
            [-80.800937243999954, 35.483765938000033],
            [-80.80099824399997, 35.483902938000028],
            [-80.801097242999958, 35.484046937000073],
            [-80.80125824299995, 35.484247937000077],
            [-80.801419243999987, 35.484492938000074],
            [-80.80152424399995, 35.48466793700004],
            [-80.801605243999973, 35.484781937000037],
            [-80.801619243999937, 35.48480293700004],
            [-80.801801242999943, 35.485097938000024],
            [-80.802000243999942, 35.485377938000056],
            [-80.80208324299997, 35.485519939000028],
            [-80.802175242999965, 35.485651938000046],
            [-80.802245243999948, 35.485760938000055],
            [-80.802328242999977, 35.485922938000044],
            [-80.802475243999936, 35.486162938000064],
            [-80.802581242999963, 35.486322938000058],
            [-80.80267224399995, 35.486473938000074],
            [-80.802764243999945, 35.486587938000071],
            [-80.802864243999977, 35.486697938000077],
            [-80.802959243999965, 35.486825938000038],
            [-80.80300224399997, 35.486950938000064],
            [-80.803029243999958, 35.487044937000064],
            [-80.803123243999948, 35.487153939000052],
            [-80.803205242999979, 35.487231938000036],
            [-80.803297244999953, 35.487331938000068],
            [-80.803353244999983, 35.48742893900004],
            [-80.803422243999989, 35.487562938000053],
            [-80.803488244999983, 35.487714938000067],
            [-80.803525243999957, 35.487883938000039],
            [-80.803579243999934, 35.48808593800004],
            [-80.803623243999937, 35.48822793800008],
            [-80.803668243999937, 35.488295938000078],
            [-80.803754244999936, 35.488453938000077],
            [-80.80382024499994, 35.488599939000039],
            [-80.80395824499999, 35.488946938000026],
            [-80.80397824399995, 35.489006939000035],
            [-80.804004244999987, 35.489047939000045],
            [-80.804086243999961, 35.489227939000045],
            [-80.804119244999981, 35.489285939000069],
            [-80.804153243999963, 35.489385938000055],
            [-80.804185243999939, 35.48952293900004],
            [-80.804247244999942, 35.489597939000078],
            [-80.80427324499999, 35.489639938000039],
            [-80.80428524499996, 35.489689938000026],
            [-80.804287244999955, 35.489746939000042],
            [-80.804312244999949, 35.489806939000061],
            [-80.804347244999974, 35.489850939000064],
            [-80.804381243999956, 35.489885939000033],
            [-80.80442824499994, 35.49001393900005],
            [-80.804433245999974, 35.490080939000052],
            [-80.804457243999934, 35.490149938000059],
            [-80.80450624499997, 35.490214939000055],
            [-80.804633243999945, 35.490516938000042],
            [-80.80470924399998, 35.490743939000026],
            [-80.804767244999937, 35.490876939000032],
            [-80.804812244999937, 35.490954939000062],
            [-80.804833244999941, 35.491073939000046],
            [-80.804911244999971, 35.49137293900003],
            [-80.804985244999955, 35.491606939000064],
            [-80.805353244999935, 35.491740939000067],
            [-80.805619244999946, 35.491815939000048],
            [-80.805830245999971, 35.491881938000063],
            [-80.805975244999956, 35.491910939000036],
            [-80.806091244999948, 35.49196093900008],
            [-80.806247244999952, 35.492015939000055],
            [-80.806491244999961, 35.492082939000056],
            [-80.806727244999934, 35.492132940000033],
            [-80.806978245999971, 35.492214938000075],
            [-80.807275245999961, 35.492267940000033],
            [-80.807374245999938, 35.492275939000024],
            [-80.807497245999969, 35.492278939000073],
            [-80.80761324599996, 35.492309939000052],
            [-80.807704245999957, 35.492338939000035],
            [-80.807901245999972, 35.492333939000048],
            [-80.807926244999976, 35.492334939000045],
            [-80.806158245999939, 35.49425093900004],
            [-80.80230024399998, 35.498463941000068],
            [-80.803292244999966, 35.499434941000061],
            [-80.804014244999962, 35.500142941000036],
            [-80.80491924599994, 35.500891941000077],
            [-80.805428244999973, 35.501505941000062],
            [-80.808554246999961, 35.505273942000031],
            [-80.80847524699999, 35.505324942000072],
            [-80.808023246999937, 35.505694942000048],
            [-80.807775246999938, 35.505882942000028],
            [-80.807627246999971, 35.505965942000046],
            [-80.80745924699994, 35.506035943000029],
            [-80.807225245999973, 35.50610994200008],
            [-80.806913245999965, 35.506168942000045],
            [-80.806557246999944, 35.50620394200007],
            [-80.806181245999937, 35.506216942000037],
            [-80.805926245999956, 35.506214942000042],
            [-80.805668244999936, 35.506195942000033],
            [-80.805352245999984, 35.506147942000041],
            [-80.804925244999936, 35.506065942000077],
            [-80.804550245999962, 35.506024942000067],
            [-80.804311245999941, 35.50601994200008],
            [-80.804157245999988, 35.506028942000057],
            [-80.804010245999962, 35.50605394300004],
            [-80.803817244999948, 35.506088943000066],
            [-80.802079244999959, 35.506557943000075],
            [-80.801829244999965, 35.506630943000062],
            [-80.801677244999951, 35.506690942000034],
            [-80.801566244999947, 35.50676294200008],
            [-80.80140724499995, 35.506926943000053],
            [-80.801276243999951, 35.507104943000058],
            [-80.801111244999959, 35.50709394200004],
            [-80.79665724299997, 35.50709394200004],
            [-80.796482242999957, 35.507094942000037],
            [-80.796621242999947, 35.507165943000075],
            [-80.796753242999955, 35.507216942000071],
            [-80.797104242999978, 35.507364943000027],
            [-80.797357242999965, 35.507527943000071],
            [-80.797564243999943, 35.507721943000035],
            [-80.797841243999983, 35.508089943000073],
            [-80.798148243999947, 35.508479943000054],
            [-80.798549243999958, 35.509105943000066],
            [-80.798702243999969, 35.509421943000063],
            [-80.798794243999964, 35.509730943000079],
            [-80.798850243999937, 35.510104943000044],
            [-80.798847243999944, 35.510478943000066],
            [-80.798805243999936, 35.511800943000026],
            [-80.798752243999957, 35.513496944000053],
            [-80.798738244999981, 35.514567944000078],
            [-80.798761243999934, 35.514918944000044],
            [-80.798763243999986, 35.514949945000069],
            [-80.798973243999967, 35.515851944000076],
            [-80.799312244999953, 35.517124945000035],
            [-80.799357244999953, 35.51738494500006],
            [-80.799354244999961, 35.517533944000036],
            [-80.799353243999974, 35.517647944000032],
            [-80.799301244999981, 35.51790694500005],
            [-80.799245244999952, 35.518088945000045],
            [-80.798966244999974, 35.51901394500004]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 69,
        "SolutionID": "PI-29:K",
        "Shape_Length": 1.0110457878193357,
        "Shape_Area": 0.026569017362350882
      }
    },
    {
      "type": "Feature",
      "id": 70,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801884240999982, 35.44207392900006],
            [-80.80211424099997, 35.44190192800005],
            [-80.802210240999955, 35.441819928000029],
            [-80.802300241999944, 35.441733928000076],
            [-80.80238424099997, 35.441631928000049],
            [-80.802459241999941, 35.441510928000071],
            [-80.802495240999974, 35.441435929000079],
            [-80.802540241999964, 35.441315928000051],
            [-80.802577241999984, 35.441136929000038],
            [-80.802586240999972, 35.44105292900008],
            [-80.802589241999954, 35.44095192900005],
            [-80.802580241999976, 35.440864928000053],
            [-80.802557241999978, 35.44072692900005],
            [-80.802483240999948, 35.440525929000046],
            [-80.802323241999943, 35.440196928000034],
            [-80.802072241999952, 35.43972592800003],
            [-80.802036240999939, 35.439648928000054],
            [-80.802011241999935, 35.439583928000047],
            [-80.801995240999986, 35.439516929000035],
            [-80.801985240999954, 35.439439929000059],
            [-80.801992241999983, 35.439321928000027],
            [-80.802007241999945, 35.43925292800003],
            [-80.802042240999981, 35.43915492800005],
            [-80.802094240999963, 35.439034928000069],
            [-80.802369240999951, 35.438474928000062],
            [-80.802770240999962, 35.437699928000029],
            [-80.802866241999936, 35.437485928000058],
            [-80.80288724199994, 35.437415927000075],
            [-80.802903240999967, 35.43734192800008],
            [-80.802913240999942, 35.437245928000038],
            [-80.802923241999963, 35.437049928000079],
            [-80.802923240999974, 35.436732927000037],
            [-80.802930241999945, 35.436538927000072],
            [-80.802982241999985, 35.43576592800008],
            [-80.803027240999938, 35.435273927000026],
            [-80.803044241999942, 35.435174927000048],
            [-80.803086241999949, 35.435019927000042],
            [-80.803152240999964, 35.434849927000073],
            [-80.803447240999958, 35.434206927000048],
            [-80.803523240999937, 35.434061927000073],
            [-80.803585240999951, 35.433958927000049],
            [-80.803898240999956, 35.433915927000044],
            [-80.804082241999936, 35.433901927000079],
            [-80.804412241999955, 35.433887927000058],
            [-80.805324241999983, 35.433862926000074],
            [-80.80542724299994, 35.433866927000054],
            [-80.805519241999946, 35.433875927000031],
            [-80.805692241999964, 35.433912927000051],
            [-80.805800241999975, 35.433944926000038],
            [-80.805975241999988, 35.434008926000047],
            [-80.806141241999967, 35.434076927000035],
            [-80.806785241999989, 35.434366927000042],
            [-80.806876242999977, 35.434414927000034],
            [-80.806966242999977, 35.434470927000064],
            [-80.807097242999987, 35.434571927000036],
            [-80.807159241999955, 35.434628927000063],
            [-80.807499241999949, 35.434965927000064],
            [-80.807645242999968, 35.435096927000075],
            [-80.807833242999948, 35.435243927000045],
            [-80.807969242999945, 35.435340928000073],
            [-80.808096242999966, 35.435418928000047],
            [-80.808213243999944, 35.435479928000063],
            [-80.808344242999965, 35.435530927000059],
            [-80.808476241999983, 35.435570927000072],
            [-80.808729242999959, 35.435619927000062],
            [-80.808861241999978, 35.435635927000078],
            [-80.809127242999978, 35.435655927000028],
            [-80.809522243999936, 35.435666927000057],
            [-80.811476242999959, 35.435651928000027],
            [-80.812396242999966, 35.435649927000043],
            [-80.812504244999957, 35.435655928000074],
            [-80.81258124499999, 35.435664927000062],
            [-80.812659244999963, 35.435679926000034],
            [-80.81272024499998, 35.43569192700005],
            [-80.812766243999988, 35.435705927000072],
            [-80.812925243999985, 35.435768927000026],
            [-80.813029244999939, 35.435821927000063],
            [-80.813146243999938, 35.435892926000065],
            [-80.813729244999934, 35.436321927000051],
            [-80.81416524399998, 35.436623927000028],
            [-80.814621243999966, 35.436932927000043],
            [-80.815332245999969, 35.437424927000052],
            [-80.815596244999938, 35.437616928000068],
            [-80.816460245999963, 35.438277928000048],
            [-80.817083245999982, 35.438754927000048],
            [-80.817160245999958, 35.438804928000025],
            [-80.817248245999963, 35.438852928000074],
            [-80.817330244999937, 35.438890928000035],
            [-80.817407245999959, 35.438917927000034],
            [-80.817525244999956, 35.438950927000064],
            [-80.818124245999968, 35.439054928000076],
            [-80.819434245999958, 35.439276927000037],
            [-80.819753246999937, 35.439329927000074],
            [-80.819819246999941, 35.439339927000049],
            [-80.820044246999942, 35.439377928000056],
            [-80.820264246999955, 35.439408928000034],
            [-80.820643245999975, 35.439449927000055],
            [-80.820777245999977, 35.439455928000029],
            [-80.820961246999957, 35.439448927000058],
            [-80.821068246999971, 35.439434928000026],
            [-80.821290246999979, 35.439386927000044],
            [-80.82155324699994, 35.439303927000026],
            [-80.821690246999935, 35.439249927000049],
            [-80.821788247999962, 35.439202928000043],
            [-80.822049246999939, 35.439049927000042],
            [-80.822417246999976, 35.43880292700004],
            [-80.822680247999983, 35.438611927000068],
            [-80.822989246999953, 35.438371927000048],
            [-80.823108247999983, 35.438284927000041],
            [-80.823246247999975, 35.438208927000062],
            [-80.823377246999939, 35.438150928000027],
            [-80.823437247999948, 35.438132927000026],
            [-80.823518247999971, 35.438116927000067],
            [-80.823664246999954, 35.43810092700005],
            [-80.823744246999979, 35.438098927000055],
            [-80.823890246999952, 35.438087927000026],
            [-80.824608247999947, 35.43806392700003],
            [-80.825630247999982, 35.438009927000053],
            [-80.825892247999946, 35.43800292700007],
            [-80.826112247999959, 35.438003927000068],
            [-80.826749248999988, 35.438036927000041],
            [-80.827722248999976, 35.438097927000058],
            [-80.82858424899996, 35.438141926000071],
            [-80.829130248999945, 35.438168927000049],
            [-80.829640248999965, 35.438195927000038],
            [-80.830510248999985, 35.438230927000063],
            [-80.83078224999997, 35.438235926000061],
            [-80.831099249999966, 35.438230927000063],
            [-80.831442249999952, 35.43820592700007],
            [-80.833077250999963, 35.43800692700006],
            [-80.83336725099997, 35.437955927000075],
            [-80.833484250999959, 35.437927926000043],
            [-80.833701250999979, 35.437866926000027],
            [-80.833823250999956, 35.437824926000076],
            [-80.834115249999968, 35.437709926000025],
            [-80.837341251999987, 35.436322926000059],
            [-80.837782250999965, 35.436139926000067],
            [-80.838763251999978, 35.435721926000042],
            [-80.839565251999943, 35.435386926000035],
            [-80.839914251999971, 35.435254926000027],
            [-80.840163251999968, 35.435171925000077],
            [-80.840438251999956, 35.43510292600007],
            [-80.840649251999935, 35.435058925000078],
            [-80.840744252999968, 35.435042925000062],
            [-80.841009251999935, 35.434998925000059],
            [-80.841462252999975, 35.434923926000067],
            [-80.842266253999981, 35.434801925000045],
            [-80.843766253999945, 35.434598926000035],
            [-80.843311252999968, 35.430975925000041],
            [-80.843245252999964, 35.43045892400005],
            [-80.843157252999958, 35.429770924000024],
            [-80.843108252999968, 35.429379924000045],
            [-80.842731252999954, 35.426376924000067],
            [-80.842470251999941, 35.424276924000026],
            [-80.842410252999969, 35.423828924000077],
            [-80.842359251999937, 35.423395923000044],
            [-80.842022251999936, 35.420758922000061],
            [-80.841518251999958, 35.42077392300007],
            [-80.840916251999943, 35.420779922000065],
            [-80.840720251999983, 35.420785923000039],
            [-80.840471251999986, 35.420794923000074],
            [-80.839921250999964, 35.420802923000053],
            [-80.839768250999953, 35.420809923000036],
            [-80.839591251999934, 35.420804923000048],
            [-80.838141250999968, 35.420829923000042],
            [-80.83801724999995, 35.420828923000045],
            [-80.83790725099999, 35.420821922000073],
            [-80.837905249999949, 35.420150923000051],
            [-80.837899249999964, 35.419699923000053],
            [-80.837898249999967, 35.419598922000034],
            [-80.837895250999964, 35.41891292300005],
            [-80.837863249999941, 35.41829092200004],
            [-80.837853250999956, 35.418128922000051],
            [-80.83783324999996, 35.418042922000041],
            [-80.837797250999984, 35.417961922000075],
            [-80.837741250999954, 35.417882923000036],
            [-80.837687249999988, 35.417822922000028],
            [-80.837620249999986, 35.41776092200007],
            [-80.837542250999945, 35.417700922000051],
            [-80.837373250999974, 35.417586922000055],
            [-80.837210250999988, 35.41749492200006],
            [-80.83704524999996, 35.417418923000071],
            [-80.836866249999957, 35.417353922000075],
            [-80.836670249999941, 35.417298922000043],
            [-80.836422250999988, 35.417251922000048],
            [-80.836254249999968, 35.417207923000035],
            [-80.836127248999958, 35.417162922000045],
            [-80.836050249999971, 35.417130922000069],
            [-80.835898250999946, 35.417051922000041],
            [-80.83574324999995, 35.416956922000054],
            [-80.835325249999983, 35.416652922000026],
            [-80.834822248999956, 35.416281922000053],
            [-80.834733249999942, 35.41618492200007],
            [-80.834559249999984, 35.416219922000039],
            [-80.83441524999995, 35.416253921000077],
            [-80.834290249999981, 35.416276921000076],
            [-80.834028248999971, 35.416309922000039],
            [-80.83390124899995, 35.416318922000073],
            [-80.833719249999945, 35.416325922000055],
            [-80.833444248999967, 35.416320922000068],
            [-80.833330248999971, 35.416312921000042],
            [-80.833058248999976, 35.416280922000055],
            [-80.832852248999984, 35.416253922000067],
            [-80.831426248999946, 35.416030923000051],
            [-80.830704247999961, 35.415924922000045],
            [-80.830544248999956, 35.415890922000074],
            [-80.830366247999962, 35.415838923000024],
            [-80.83025824799995, 35.415800922000074],
            [-80.830179247999979, 35.415764922000051],
            [-80.829704248999974, 35.41554792200003],
            [-80.826966246999973, 35.414242923000074],
            [-80.825667246999956, 35.413637922000078],
            [-80.825323246999972, 35.413482922000071],
            [-80.82503924599996, 35.413359922000041],
            [-80.824910245999945, 35.413311922000048],
            [-80.82476524599997, 35.413263922000056],
            [-80.824657245999958, 35.413236922000067],
            [-80.82455824699997, 35.413216921000071],
            [-80.824254245999953, 35.413182922000033],
            [-80.824005246999945, 35.413178922000043],
            [-80.823595245999968, 35.413189922000072],
            [-80.823586245999934, 35.412828921000028],
            [-80.823592245999976, 35.412791922000054],
            [-80.823634245999983, 35.412702922000051],
            [-80.823836245999985, 35.412447922000069],
            [-80.823937245999957, 35.412493921000078],
            [-80.82407924599994, 35.412539921000075],
            [-80.824373245999936, 35.412576921000039],
            [-80.824629246999962, 35.412557922000076],
            [-80.825027245999934, 35.412387921000061],
            [-80.825116246999983, 35.412311922000072],
            [-80.825386246999983, 35.412083921000033],
            [-80.825456245999987, 35.411992921000035],
            [-80.825518245999945, 35.411921922000033],
            [-80.825603246999947, 35.411802922000049],
            [-80.825691245999963, 35.411699921000036],
            [-80.825915245999965, 35.411493921000044],
            [-80.826253246999954, 35.411218922000046],
            [-80.826417246999938, 35.411093921000031],
            [-80.826588246999961, 35.41096592100007],
            [-80.827086246999954, 35.410636921000048],
            [-80.827356246999955, 35.410461921000035],
            [-80.827703246999988, 35.410292921000064],
            [-80.828345247999948, 35.410416921000035],
            [-80.828668246999939, 35.410341921000054],
            [-80.82850224799995, 35.410285921000025],
            [-80.828572247999944, 35.410150921000024],
            [-80.828565246999972, 35.410053920000053],
            [-80.828441246999944, 35.409985921000043],
            [-80.828251246999969, 35.409846920000064],
            [-80.827957246999972, 35.409669921000045],
            [-80.827779246999967, 35.409645921000049],
            [-80.827607247999936, 35.409637921000069],
            [-80.827474246999941, 35.409605921000036],
            [-80.827344247999974, 35.409534921000045],
            [-80.827197246999958, 35.40939792100005],
            [-80.827009247999968, 35.409364921000076],
            [-80.826825246999988, 35.40927492000003],
            [-80.826775246999944, 35.409254920000024],
            [-80.826590245999967, 35.409181921000027],
            [-80.826456246999953, 35.409130921000042],
            [-80.826333247999969, 35.409100921000061],
            [-80.826205246999962, 35.409184921000076],
            [-80.826118246999954, 35.409210921000067],
            [-80.826033246999941, 35.409265921000042],
            [-80.825974246999976, 35.40925092100008],
            [-80.825920246999942, 35.409322921000069],
            [-80.825821246999965, 35.409309921000045],
            [-80.825713245999964, 35.409281921000058],
            [-80.825626245999956, 35.409193920000064],
            [-80.825567246999981, 35.409192921000056],
            [-80.825434245999986, 35.409198921000041],
            [-80.825407246999987, 35.40916092100008],
            [-80.825378245999957, 35.409089921000032],
            [-80.82531324699994, 35.409011921000058],
            [-80.825231245999987, 35.408946920000062],
            [-80.825136246999989, 35.408881921000045],
            [-80.825089245999948, 35.408843920000038],
            [-80.825061246999951, 35.408813920000057],
            [-80.825029245999986, 35.408751920000043],
            [-80.824983246999977, 35.40869192100007],
            [-80.824943245999975, 35.408667920000028],
            [-80.824862245999952, 35.408597920000034],
            [-80.824778245999937, 35.408548921000033],
            [-80.824756245999936, 35.408530921000079],
            [-80.824674246999962, 35.408430921000047],
            [-80.824627245999977, 35.408384921000049],
            [-80.824533246999977, 35.408348921000027],
            [-80.824508246999983, 35.40833192100007],
            [-80.824456246999944, 35.408316921000051],
            [-80.82433724599997, 35.408284921000075],
            [-80.824298246999945, 35.40827192100005],
            [-80.824242246999972, 35.408208921000039],
            [-80.824212245999945, 35.408188921000033],
            [-80.824150244999942, 35.408175920000076],
            [-80.824074245999952, 35.408133921000058],
            [-80.824002245999964, 35.408075921000034],
            [-80.823929245999977, 35.408061921000069],
            [-80.823736245999953, 35.408062920000077],
            [-80.823675245999937, 35.408055921000027],
            [-80.823640246999958, 35.408037921000073],
            [-80.823605245999943, 35.40801392000003],
            [-80.823543245999986, 35.407989920000034],
            [-80.823416245999965, 35.407969920000028],
            [-80.823346245999971, 35.407950921000065],
            [-80.823244245999945, 35.407907920000071],
            [-80.823187245999975, 35.407898921000026],
            [-80.823125245999961, 35.407895921000033],
            [-80.823051245999977, 35.407885921000059],
            [-80.82295624599999, 35.40782592100004],
            [-80.822865244999946, 35.407784921000029],
            [-80.822706245999939, 35.40772392100007],
            [-80.822534245999975, 35.407680921000065],
            [-80.822286244999987, 35.407673921000026],
            [-80.822170245999985, 35.407642920000058],
            [-80.822194244999935, 35.406974920000039],
            [-80.823301244999982, 35.407001920000027],
            [-80.824343245999955, 35.406828920000066],
            [-80.824958246999984, 35.40694392000006],
            [-80.825408245999938, 35.407040920000043],
            [-80.826118246999954, 35.407310920000043],
            [-80.826379246999977, 35.407599921000042],
            [-80.826539246999971, 35.408115921000046],
            [-80.826547246999951, 35.408147920000033],
            [-80.826658246999955, 35.408113921000052],
            [-80.826786245999983, 35.408030921000034],
            [-80.826925246999963, 35.407922921000079],
            [-80.826946246999967, 35.407472921000078],
            [-80.826919245999989, 35.407352920000051],
            [-80.826848246999987, 35.407226920000028],
            [-80.826804246999984, 35.407187920000069],
            [-80.826821245999952, 35.407126920000053],
            [-80.826864246999946, 35.407095920000074],
            [-80.826950245999967, 35.407083921000037],
            [-80.827041246999954, 35.407027920000075],
            [-80.827077246999977, 35.406986921000055],
            [-80.827155245999961, 35.406868920000079],
            [-80.827187246999983, 35.406676920000052],
            [-80.827155246999951, 35.406596921000073],
            [-80.827129246999959, 35.406546920000039],
            [-80.827171246999967, 35.406494920000057],
            [-80.827250246999938, 35.406443919000026],
            [-80.827421247999951, 35.406380920000061],
            [-80.827572246999978, 35.40624192000007],
            [-80.827627246999953, 35.40620092000006],
            [-80.827786246999949, 35.406162920000043],
            [-80.827871246999962, 35.406115920000047],
            [-80.827956245999985, 35.406033920000027],
            [-80.828042246999985, 35.40601292000008],
            [-80.828163246999964, 35.405909921000045],
            [-80.828218246999938, 35.405883920000065],
            [-80.828282246999947, 35.405825920000041],
            [-80.826808246999974, 35.405978920000052],
            [-80.824498245999962, 35.406123920000027],
            [-80.823040244999959, 35.406209920000038],
            [-80.821729245999961, 35.406117920000042],
            [-80.821688245999951, 35.404494920000047],
            [-80.824117245999958, 35.404702920000034],
            [-80.825130245999958, 35.404788920000044],
            [-80.82425124599996, 35.40253892000004],
            [-80.823949245999984, 35.401745919000064],
            [-80.82430024599995, 35.401596919000042],
            [-80.82439024599995, 35.401549919000047],
            [-80.824444244999938, 35.401523919000056],
            [-80.824506245999942, 35.401479919000053],
            [-80.824565244999974, 35.401423919000024],
            [-80.824617246999935, 35.401351919000035],
            [-80.82464624499994, 35.401289920000067],
            [-80.824668245999987, 35.401193919000036],
            [-80.824664244999951, 35.400959919000059],
            [-80.824669245999985, 35.400924919000033],
            [-80.82467924599996, 35.400855919000037],
            [-80.824716244999934, 35.40075291900007],
            [-80.824747245999959, 35.400689920000048],
            [-80.824793244999967, 35.400619919000064],
            [-80.82485924599996, 35.400540919000036],
            [-80.824961245999987, 35.400440918000072],
            [-80.825070244999949, 35.400354919000051],
            [-80.825163246999978, 35.400296919000027],
            [-80.825284245999967, 35.400236919000065],
            [-80.82536524599999, 35.400207919000025],
            [-80.82548924699995, 35.400178919000041],
            [-80.825601245999962, 35.400161919000027],
            [-80.826261245999945, 35.400091919000033],
            [-80.826695246999975, 35.40005491900007],
            [-80.826887246999945, 35.400046919000033],
            [-80.827476246999936, 35.400055918000078],
            [-80.82791724599997, 35.40005091900008],
            [-80.828092245999983, 35.400044919000038],
            [-80.82836724699996, 35.400022919000037],
            [-80.828532246999941, 35.399996919000046],
            [-80.828834246999975, 35.399938919000078],
            [-80.82990224699995, 35.399668919000078],
            [-80.830563247999976, 35.399501918000055],
            [-80.830833247999976, 35.399446918000024],
            [-80.831026247999944, 35.399420919000079],
            [-80.831396247999976, 35.399392918000046],
            [-80.831870247999973, 35.399371918000043],
            [-80.832127247999949, 35.39936091900006],
            [-80.832456247999971, 35.399347918000046],
            [-80.832770246999985, 35.399340919000053],
            [-80.832826247999947, 35.399339919000056],
            [-80.833041248999962, 35.399341919000051],
            [-80.833207247999951, 35.399342918000059],
            [-80.834438248999959, 35.399363919000052],
            [-80.834691248999945, 35.39936091900006],
            [-80.834785247999946, 35.399352918000034],
            [-80.834948248999979, 35.399328918000037],
            [-80.835088248999966, 35.399301919000038],
            [-80.835198248999973, 35.399264919000075],
            [-80.835584248999965, 35.399148918000037],
            [-80.835904249999942, 35.399061919000076],
            [-80.836271248999935, 35.39895591800007],
            [-80.836443248999956, 35.398916918000054],
            [-80.836934248999967, 35.398790918000032],
            [-80.837606248999975, 35.398602918000051],
            [-80.837694248999981, 35.39857691800006],
            [-80.837802248999935, 35.398553918000061],
            [-80.835745248999956, 35.394827917000043],
            [-80.835853247999978, 35.394793917000072],
            [-80.835822248999989, 35.39474591700008],
            [-80.835678247999965, 35.394488918000036],
            [-80.835588248999954, 35.394304917000056],
            [-80.835505248999937, 35.394109917000037],
            [-80.835418248999986, 35.393888917000027],
            [-80.835356248999972, 35.393697917000054],
            [-80.835308248999979, 35.393524917000036],
            [-80.835264248999977, 35.393330917000071],
            [-80.835210248999942, 35.393006917000037],
            [-80.835177248999969, 35.392735917000039],
            [-80.83498224799996, 35.390832916000079],
            [-80.834856247999937, 35.389648917000045],
            [-80.834780247999959, 35.388921917000062],
            [-80.834395246999975, 35.385270916000024],
            [-80.83421724699997, 35.383565915000077],
            [-80.834132247999946, 35.382744916000036],
            [-80.833855247999963, 35.382726915000035],
            [-80.830422245999955, 35.382975915000031],
            [-80.829186245999949, 35.382619915000078],
            [-80.829051245999949, 35.383109915000034],
            [-80.827987245999964, 35.382702915000039],
            [-80.827469245999964, 35.382502914000042],
            [-80.824864244999958, 35.381501914000069],
            [-80.824807244999988, 35.381477915000062],
            [-80.824647244999937, 35.381089916000064],
            [-80.823587243999953, 35.380902915000036],
            [-80.822677243999976, 35.381303915000046],
            [-80.821949243999939, 35.380241914000067],
            [-80.82044024399994, 35.37961791500004],
            [-80.820651243999976, 35.37920091400008],
            [-80.820707242999958, 35.379063914000028],
            [-80.820765243999972, 35.378872914000056],
            [-80.820803242999943, 35.378719915000033],
            [-80.820825243999934, 35.378599914000063],
            [-80.820952242999965, 35.377691914000025],
            [-80.820985243999985, 35.377457914000047],
            [-80.82125724399998, 35.375657914000044],
            [-80.821311243999958, 35.375296914000046],
            [-80.821367242999941, 35.374976914000058],
            [-80.821413242999938, 35.374778913000057],
            [-80.821597242999985, 35.374239914000043],
            [-80.821786243999952, 35.37372991400008],
            [-80.822651242999939, 35.371220913000059],
            [-80.822971243999973, 35.370305913000038],
            [-80.823497243999952, 35.368723913000053],
            [-80.823544243999947, 35.368586912000069],
            [-80.823581243999968, 35.36847791200006],
            [-80.823084242999983, 35.368316913000058],
            [-80.822667243999945, 35.368186912000056],
            [-80.82238324299999, 35.368080912000039],
            [-80.82229724299998, 35.368043912000076],
            [-80.822049242999981, 35.367916912000055],
            [-80.821798242999989, 35.36776791300008],
            [-80.821636242999944, 35.367665912000064],
            [-80.821447242999966, 35.367531912000061],
            [-80.821356242999968, 35.367457913000067],
            [-80.821271241999966, 35.367378912000049],
            [-80.821205242999952, 35.367308912000055],
            [-80.821127242999978, 35.367209912000078],
            [-80.821045242999958, 35.36707791200007],
            [-80.820945243999972, 35.366886912000041],
            [-80.820480242999963, 35.365889912000057],
            [-80.820044242999984, 35.364992912000048],
            [-80.819964241999969, 35.364811911000061],
            [-80.819914242999971, 35.364670912000065],
            [-80.819809242999952, 35.364244911000071],
            [-80.819725241999947, 35.363875911000036],
            [-80.819679242999939, 35.363688911000054],
            [-80.819434241999943, 35.362555911000072],
            [-80.819363242999941, 35.362115912000036],
            [-80.81933724199996, 35.361828911000032],
            [-80.819332242999963, 35.361634910000078],
            [-80.81934524199994, 35.361422911000034],
            [-80.819376241999976, 35.361236911000049],
            [-80.819399241999974, 35.361132911000027],
            [-80.819502241999942, 35.360799911000072],
            [-80.820103241999959, 35.359102910000047],
            [-80.820282242999951, 35.358619911000062],
            [-80.821008242999937, 35.356584909000048],
            [-80.821633242999951, 35.354880910000077],
            [-80.822000242999934, 35.353847908000034],
            [-80.822092242999986, 35.35358990900005],
            [-80.82199024199997, 35.353575908000039],
            [-80.819878241999959, 35.353283909000027],
            [-80.818695240999944, 35.35312090900004],
            [-80.81843724099997, 35.353082909000079],
            [-80.818320240999981, 35.353065909000065],
            [-80.816618240999958, 35.352815909000071],
            [-80.816466239999954, 35.35280190900005],
            [-80.816077240999959, 35.352782909000041],
            [-80.815359240999953, 35.352799909000055],
            [-80.814659240999958, 35.352814908000028],
            [-80.814466239999945, 35.352815909000071],
            [-80.813530239999977, 35.352821909000056],
            [-80.813099239999985, 35.352823909000051],
            [-80.81292523999997, 35.352814909000074],
            [-80.812786238999934, 35.352798909000057],
            [-80.812755239999944, 35.352792909000073],
            [-80.812670239999989, 35.352777909000054],
            [-80.812554238999951, 35.352746909000075],
            [-80.812450239999976, 35.352706909000062],
            [-80.812290239999982, 35.352627909000034],
            [-80.81217423999999, 35.352558909000038],
            [-80.812024238999982, 35.352457910000055],
            [-80.812005238999973, 35.352444909000042],
            [-80.811414238999987, 35.351992910000035],
            [-80.809430237999948, 35.350458908000064],
            [-80.809256237999989, 35.350333909000028],
            [-80.809033238999973, 35.350206909000065],
            [-80.808720237999978, 35.350061909000033],
            [-80.808264237999936, 35.349880909000035],
            [-80.808008237999957, 35.349778909000065],
            [-80.807635236999943, 35.34961690800003],
            [-80.807354237999959, 35.349474909000037],
            [-80.807130237999957, 35.349346909000076],
            [-80.806880236999973, 35.34919190800008],
            [-80.806110237999974, 35.348682908000058],
            [-80.805530236999971, 35.348298908000061],
            [-80.804695235999986, 35.347726908000027],
            [-80.804401236999979, 35.347514908000051],
            [-80.804163235999965, 35.347328909000055],
            [-80.803519235999943, 35.346791908000057],
            [-80.803417236999962, 35.346711908000032],
            [-80.80327123699999, 35.346609909000051],
            [-80.803108236999947, 35.346517908000067],
            [-80.802934235999942, 35.346439908000036],
            [-80.802844235999942, 35.346406909000052],
            [-80.802659235999954, 35.346350908000034],
            [-80.802534236999975, 35.346331909000071],
            [-80.80240523599997, 35.346326908000037],
            [-80.802277236999942, 35.346338909000053],
            [-80.802200235999976, 35.34635890800007],
            [-80.802065236999965, 35.346406909000052],
            [-80.802000235999969, 35.346436909000033],
            [-80.801460235999969, 35.34673790800008],
            [-80.80129823599998, 35.346823909000079],
            [-80.80113223699999, 35.346905908000053],
            [-80.800644235999982, 35.347132908000049],
            [-80.800531236999973, 35.347202909000032],
            [-80.800431235999952, 35.347284908000063],
            [-80.800387235999949, 35.347329909000052],
            [-80.800313235999965, 35.347426909000035],
            [-80.800264235999975, 35.347540909000031],
            [-80.800247234999972, 35.347600908000061],
            [-80.800231234999956, 35.347687908000069],
            [-80.800200236999956, 35.348138909000056],
            [-80.800170235999985, 35.348313909000069],
            [-80.800128234999988, 35.348457909000047],
            [-80.800059235999981, 35.348616909000043],
            [-80.800019235999969, 35.348689909000029],
            [-80.799939234999954, 35.34881290900006],
            [-80.799800234999964, 35.348990909000065],
            [-80.799637234999977, 35.349157909000041],
            [-80.799522235999973, 35.349258909000071],
            [-80.799389234999978, 35.34936091000003],
            [-80.799097234999977, 35.34956390900004],
            [-80.799015234999956, 35.349614910000071],
            [-80.79890123499996, 35.349686910000059],
            [-80.798798234999936, 35.349742910000032],
            [-80.798584234999964, 35.349844909000069],
            [-80.798510234999981, 35.349873909000053],
            [-80.798395234999987, 35.349918909000053],
            [-80.79807623399995, 35.350027909000062],
            [-80.796915234999972, 35.350367910000045],
            [-80.796557233999977, 35.350476909000065],
            [-80.796413234999989, 35.350524910000047],
            [-80.796272233999957, 35.350577909000037],
            [-80.796138234999944, 35.350633909000067],
            [-80.796001234999949, 35.350709909000045],
            [-80.795924234999973, 35.350762910000071],
            [-80.795912234999946, 35.350689909000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795949233999977, 35.350527910000039],
            [-80.795959233999952, 35.350469909000026],
            [-80.795977234999953, 35.350282909000043],
            [-80.795974233999971, 35.350094909000063],
            [-80.795881233999978, 35.348936908000042],
            [-80.795811234999974, 35.348044909000066],
            [-80.79579023499997, 35.34778091000004],
            [-80.795768233999979, 35.347550908000073],
            [-80.795365232999984, 35.347463909000055],
            [-80.795313233999934, 35.347462909000058],
            [-80.795079233999957, 35.347531909000054],
            [-80.794742232999965, 35.347648908000053],
            [-80.794396232999986, 35.347785909000038],
            [-80.794194233999974, 35.347860909000076],
            [-80.793805232999944, 35.347961909000048],
            [-80.793681233999962, 35.348007909000046],
            [-80.793561233999981, 35.348052909000046],
            [-80.793481232999966, 35.348111909000067],
            [-80.793427233999978, 35.348128909000025],
            [-80.793330233999939, 35.348124909000035],
            [-80.793259233999947, 35.348141908000059],
            [-80.793205232999981, 35.348192908000044],
            [-80.793177233999984, 35.348246909000068],
            [-80.793156232999934, 35.34830290900004],
            [-80.793099233999953, 35.348358909000069],
            [-80.792876232999959, 35.348461909000036],
            [-80.79278523399995, 35.348503909000044],
            [-80.792728232999934, 35.348552909000034],
            [-80.792639233999978, 35.348686909000037],
            [-80.792618232999985, 35.348720909000065],
            [-80.792545232999942, 35.348761909000075],
            [-80.792410232999941, 35.348776909000037],
            [-80.792336233999947, 35.348825909000027],
            [-80.792088233999948, 35.349014909000061],
            [-80.792037232999974, 35.349057909000067],
            [-80.792030232999934, 35.349153909000051],
            [-80.792041232999964, 35.349227909000035],
            [-80.792027232999942, 35.349301910000065],
            [-80.791905233999955, 35.34936490900003],
            [-80.791796233999946, 35.349421909000057],
            [-80.791677233999962, 35.349436910000065],
            [-80.791628232999983, 35.349408909000033],
            [-80.791300232999959, 35.349251909000031],
            [-80.790929232999986, 35.349220910000042],
            [-80.790785232999951, 35.34920591000008],
            [-80.790728232999982, 35.349206909000031],
            [-80.790683232999982, 35.349202909000041],
            [-80.790602232999959, 35.349200910000036],
            [-80.79043623299998, 35.349163909000026],
            [-80.790316232999942, 35.349165910000067],
            [-80.790245231999961, 35.349170909000065],
            [-80.790079233999961, 35.349142910000069],
            [-80.789986232999979, 35.34912791000005],
            [-80.789866231999952, 35.349129909000055],
            [-80.78970923299994, 35.349163909000026],
            [-80.789579232999984, 35.349173909000058],
            [-80.789253232999954, 35.34921391000006],
            [-80.789066231999982, 35.349224910000032],
            [-80.789050232999955, 35.349226909000038],
            [-80.788833231999945, 35.349177909000048],
            [-80.788738231999957, 35.349150909000059],
            [-80.788629232999938, 35.349138910000079],
            [-80.788576231999969, 35.349119909000024],
            [-80.788442231999966, 35.349136910000027],
            [-80.78839923299995, 35.34917290900006],
            [-80.788326231999974, 35.349220909000053],
            [-80.788286231999962, 35.34927490900003],
            [-80.788262231999965, 35.349285909000059],
            [-80.78817023199997, 35.349309909000056],
            [-80.788124231999973, 35.349320909000028],
            [-80.78800223199994, 35.349345909000078],
            [-80.787899231999972, 35.349359910000032],
            [-80.787821231999942, 35.349380910000036],
            [-80.787651231999973, 35.349416910000059],
            [-80.787347231999945, 35.349421909000057],
            [-80.787111231999972, 35.34943891000006],
            [-80.78705523299999, 35.349437910000063],
            [-80.787052231999951, 35.349416910000059],
            [-80.786997231999976, 35.349391909000076],
            [-80.786909230999981, 35.349271909000038],
            [-80.786837231999982, 35.34921791000005],
            [-80.786804231999952, 35.349174909000055],
            [-80.786783231999948, 35.349120910000067],
            [-80.78676823099994, 35.34909490900003],
            [-80.786734230999969, 35.349056909000069],
            [-80.78669223199995, 35.349030909000078],
            [-80.786635230999934, 35.349001910000027],
            [-80.786592230999986, 35.348987910000062],
            [-80.786466231999952, 35.348971910000046],
            [-80.786432231999981, 35.348958909000032],
            [-80.786395231999961, 35.348925909000059],
            [-80.786330230999965, 35.348731910000026],
            [-80.786308230999964, 35.348686909000037],
            [-80.786285230999965, 35.348653909000063],
            [-80.786243230999958, 35.348606909000068],
            [-80.786187230999985, 35.348528909000038],
            [-80.786167230999979, 35.348489910000069],
            [-80.786155231999942, 35.348431909000055],
            [-80.786173230999964, 35.348308910000071],
            [-80.786185230999934, 35.34820091000006],
            [-80.786199231999944, 35.348174909000079],
            [-80.786276230999988, 35.348138909000056],
            [-80.786294231999989, 35.348125909000032],
            [-80.786330231999955, 35.348079909000035],
            [-80.78633623099995, 35.348029910000037],
            [-80.786325230999978, 35.347981909000055],
            [-80.786250231999986, 35.347914909000053],
            [-80.786112231999937, 35.347856910000075],
            [-80.786039231999951, 35.347809909000034],
            [-80.785922231999962, 35.347707909000064],
            [-80.785845230999939, 35.347634909000078],
            [-80.785823230999938, 35.347593909000068],
            [-80.785764230999973, 35.34754790900007],
            [-80.785726231999945, 35.347522909000077],
            [-80.785653231999959, 35.347477909000077],
            [-80.785610230999964, 35.347471908000045],
            [-80.785531230999936, 35.347423909000042],
            [-80.785412230999952, 35.347346909000066],
            [-80.785363230999963, 35.347309909000046],
            [-80.785263231999977, 35.347208909000074],
            [-80.785092230999965, 35.347076909000066],
            [-80.785050230999957, 35.347024909000027],
            [-80.785024230999966, 35.346980909000024],
            [-80.785023230999968, 35.346957909000025],
            [-80.78502623199995, 35.34693991000006],
            [-80.785057230999939, 35.34689890900006],
            [-80.785093230999962, 35.346860910000032],
            [-80.785114230999966, 35.34682790800008],
            [-80.78514323099995, 35.346801909000078],
            [-80.785166230999948, 35.346793909000041],
            [-80.785246230999974, 35.346818909000035],
            [-80.785278231999939, 35.346858909000048],
            [-80.78530723099999, 35.346888909000029],
            [-80.785359231999962, 35.346918909000067],
            [-80.78540523099997, 35.346939909000071],
            [-80.785457230999953, 35.346940909000068],
            [-80.785572231999936, 35.346921909000059],
            [-80.785632230999965, 35.346892909000076],
            [-80.785678230999963, 35.346841909000034],
            [-80.785736230999987, 35.346786909000059],
            [-80.785825231999979, 35.346744909000051],
            [-80.785893230999989, 35.346698909000054],
            [-80.78596823099997, 35.346658909000041],
            [-80.786011230999975, 35.346623909000073],
            [-80.786160231999986, 35.34645690900004],
            [-80.786337230999948, 35.346312909000062],
            [-80.786360231999936, 35.346279909000032],
            [-80.786393231999966, 35.346238909000078],
            [-80.786450230999947, 35.346171909000077],
            [-80.786453230999939, 35.346123909000028],
            [-80.786435231999974, 35.346099909000031],
            [-80.786409231999983, 35.346084909000069],
            [-80.786348231999966, 35.346041909000064],
            [-80.786305231999961, 35.346037909000074],
            [-80.786277231999975, 35.346036908000031],
            [-80.786177231999943, 35.346012908000034],
            [-80.786146230999975, 35.346009909000031],
            [-80.786069231999988, 35.346000909000054],
            [-80.786021231999939, 35.34598790900003],
            [-80.78592723099996, 35.345967909000024],
            [-80.785843230999944, 35.345936909000045],
            [-80.785817230999953, 35.345936908000056],
            [-80.78576523199996, 35.345912908000059],
            [-80.785698230999969, 35.345871909000039],
            [-80.785618230999944, 35.345820909000054],
            [-80.78558923099996, 35.34584890900004],
            [-80.785514231999969, 35.345919908000042],
            [-80.785382229999982, 35.346015909000073],
            [-80.785310230999983, 35.346058909000078],
            [-80.785199230999979, 35.346115909000048],
            [-80.785044230999972, 35.346174909000069],
            [-80.783826230999978, 35.346490909000067],
            [-80.783532230999981, 35.346565909000049],
            [-80.783137230999955, 35.346672908000073],
            [-80.782472230999986, 35.346840909000036],
            [-80.782352229999958, 35.346886909000034],
            [-80.782273229999987, 35.346925909000049],
            [-80.782146229999967, 35.347002909000025],
            [-80.78203222999997, 35.347093910000069],
            [-80.781965229999969, 35.347158909000029],
            [-80.781877229999964, 35.347265910000033],
            [-80.781830230999958, 35.347340909000025],
            [-80.781778229999986, 35.347444910000036],
            [-80.781755228999941, 35.347509909000053],
            [-80.781726229999947, 35.34764190900006],
            [-80.78171722999997, 35.347774910000055],
            [-80.781743229999961, 35.348070910000047],
            [-80.781740229999969, 35.348199910000062],
            [-80.781730230999983, 35.348263910000071],
            [-80.78170922999999, 35.34835190900003],
            [-80.781667230999972, 35.348468909000076],
            [-80.781605229999968, 35.348579910000069],
            [-80.781567229999951, 35.348631909000062],
            [-80.781524229999945, 35.348681909000049],
            [-80.78143223099994, 35.348771910000039],
            [-80.781396229999984, 35.348801909000031],
            [-80.781276229999946, 35.348884910000038],
            [-80.78121122999994, 35.348920909000071],
            [-80.781143229999941, 35.348952909000047],
            [-80.780984229999945, 35.349008910000066],
            [-80.780830229999935, 35.349042909000048],
            [-80.780726228999981, 35.349054909000074],
            [-80.780750228999977, 35.349314909000043],
            [-80.780752229999962, 35.349426910000034],
            [-80.780726228999981, 35.349653910000029],
            [-80.780659230999959, 35.349889910000059],
            [-80.780604229999938, 35.350016910000079],
            [-80.780545228999983, 35.350122910000039],
            [-80.780432229999974, 35.350285910000025],
            [-80.780334228999948, 35.350403910000068],
            [-80.780286229999945, 35.350453910000056],
            [-80.78004722999998, 35.35065891000005],
            [-80.779662229999985, 35.350975910000045],
            [-80.778906229999961, 35.351591910000025],
            [-80.778793228999973, 35.35168390900003],
            [-80.778473228999985, 35.352039911000077],
            [-80.779326228999935, 35.352551910000045],
            [-80.779735229999972, 35.352796910000052],
            [-80.779979228999935, 35.35294691100006],
            [-80.780123229999958, 35.353041910000059],
            [-80.780252229999974, 35.353140911000025],
            [-80.780310229999941, 35.353193911000062],
            [-80.780414229999963, 35.353309911000053],
            [-80.780457229999968, 35.35337091100007],
            [-80.780496229999983, 35.353435911000076],
            [-80.780559229999938, 35.353570911000077],
            [-80.780811230999973, 35.354363911000064],
            [-80.781132229999969, 35.355362911000043],
            [-80.780525229999967, 35.355518911000047],
            [-80.780020228999945, 35.355639911000026],
            [-80.779747229999941, 35.355694911000057],
            [-80.779208229999938, 35.355766911000046],
            [-80.77907222999994, 35.355785911000055],
            [-80.778780229999938, 35.355854912000041],
            [-80.778653228999985, 35.35591491100007],
            [-80.778530229999944, 35.355980911000074],
            [-80.778436229999954, 35.355919911000058],
            [-80.778358229999981, 35.355882911000037],
            [-80.778247228999987, 35.35583691000005],
            [-80.778114228999982, 35.355802911000069],
            [-80.778012229999945, 35.355781911000065],
            [-80.777915228999973, 35.355771911000033],
            [-80.777805228999966, 35.35577191200008],
            [-80.776886228999956, 35.355741911000052],
            [-80.776779228999942, 35.35573491100007],
            [-80.776598228999944, 35.355728911000028],
            [-80.776287228999934, 35.355709911000076],
            [-80.77615222899999, 35.355696912000042],
            [-80.776018228999988, 35.355664911000076],
            [-80.775921229999938, 35.355631911000046],
            [-80.775818227999935, 35.355589911000038],
            [-80.775584228999946, 35.355476911000039],
            [-80.775428228999942, 35.355682911000031],
            [-80.775363227999947, 35.355757912000058],
            [-80.775271227999951, 35.355843911000079],
            [-80.775162227999942, 35.355931912000074],
            [-80.775071228999934, 35.355989912000041],
            [-80.774928228999954, 35.35605791100005],
            [-80.774363226999981, 35.356238912000038],
            [-80.773851228999945, 35.356408912000063],
            [-80.77362822799995, 35.356475912000064],
            [-80.773437227999977, 35.356527911000057],
            [-80.772983227999987, 35.356627912000079],
            [-80.772871227999985, 35.356659911000065],
            [-80.772721227999966, 35.356722911000077],
            [-80.772614227999952, 35.35679691100006],
            [-80.772542227999963, 35.356852911000033],
            [-80.772467227999982, 35.356917912000029],
            [-80.772381227999972, 35.356999912000049],
            [-80.772214227999939, 35.357337912000048],
            [-80.772014227999989, 35.357743912000046],
            [-80.771708227999966, 35.357743912000046],
            [-80.771611227999983, 35.35772391200004],
            [-80.771543227999985, 35.357720912000048],
            [-80.771355227999948, 35.357726912000032],
            [-80.771312227999942, 35.357734912000069],
            [-80.771209226999986, 35.357742912000049],
            [-80.771131226999955, 35.357714911000073],
            [-80.770820226999945, 35.357631911000055],
            [-80.770316227999956, 35.357484912000075],
            [-80.770228226999961, 35.357432912000036],
            [-80.770173226999987, 35.357414912000024],
            [-80.770094226999959, 35.357409912000037],
            [-80.769884226999977, 35.357348912000077],
            [-80.769669226999952, 35.35726991100006],
            [-80.769544227999972, 35.357215912000072],
            [-80.769444226999951, 35.357217912000067],
            [-80.769360226999936, 35.357193911000024],
            [-80.769289227999934, 35.35718791100004],
            [-80.769198226999947, 35.357192911000027],
            [-80.769110226999942, 35.357228912000039],
            [-80.769026226999983, 35.357292912000048],
            [-80.768978226999934, 35.357319911000047],
            [-80.768900226999961, 35.357324912000024],
            [-80.768833226999959, 35.357300911000038],
            [-80.768754226999988, 35.357238912000071],
            [-80.768700226999954, 35.35720191200005],
            [-80.76867522699996, 35.357189912000024],
            [-80.768627226999968, 35.357171912000069],
            [-80.768477225999959, 35.35715691200005],
            [-80.768375225999989, 35.357162912000035],
            [-80.768340226999953, 35.357182911000052],
            [-80.76821722699998, 35.357192912000073],
            [-80.768163225999956, 35.357221911000067],
            [-80.768112225999971, 35.357264911000073],
            [-80.76805322599995, 35.357295912000041],
            [-80.767950225999982, 35.357323912000027],
            [-80.767849225999953, 35.357378912000058],
            [-80.767762226999935, 35.357413912000027],
            [-80.767722226999979, 35.357443912000065],
            [-80.767628225999943, 35.357467912000061],
            [-80.767493225999942, 35.357510912000066],
            [-80.767402225999945, 35.357548912000027],
            [-80.76738022699999, 35.357558912000059],
            [-80.767304225999965, 35.357595912000079],
            [-80.767113225999935, 35.357642912000074],
            [-80.767048226999975, 35.357675912000047],
            [-80.766941225999972, 35.357696912000051],
            [-80.766830226999957, 35.35772791200003],
            [-80.766719225999964, 35.357753911000032],
            [-80.766628225999966, 35.357779912000069],
            [-80.766554225999982, 35.357807911000066],
            [-80.766478226999936, 35.357856912000045],
            [-80.766418226999974, 35.357886911000037],
            [-80.76636822599994, 35.35789591200006],
            [-80.766195225999979, 35.357910912000079],
            [-80.765942224999947, 35.357911912000077],
            [-80.765792225999974, 35.35790391200004],
            [-80.765244225999936, 35.357900912000048],
            [-80.765122224999971, 35.357898912000053],
            [-80.764939225999967, 35.357885912000029],
            [-80.764764225999954, 35.357877912000049],
            [-80.764663224999936, 35.357878913000036],
            [-80.764642225999978, 35.35784691200007],
            [-80.764595225999983, 35.357799912000075],
            [-80.764570224999943, 35.357763912000053],
            [-80.76437122599998, 35.35754391200004],
            [-80.764146224999934, 35.357371912000076],
            [-80.764119224999945, 35.357319912000037],
            [-80.764126224999984, 35.357122912000079],
            [-80.764110224999968, 35.357065912000053],
            [-80.764061225999967, 35.357022913000037],
            [-80.764013224999985, 35.357020912000053],
            [-80.763924225999972, 35.357056912000075],
            [-80.763852224999937, 35.357065912000053],
            [-80.763590225999963, 35.356995912000059],
            [-80.763529224999957, 35.356974912000055],
            [-80.763495224999986, 35.356951911000067],
            [-80.763427224999987, 35.356920912000078],
            [-80.763364223999986, 35.356917912000029],
            [-80.763264224999944, 35.356955912000046],
            [-80.763046224999982, 35.357016912000063],
            [-80.762963224999964, 35.35702991200003],
            [-80.762824223999985, 35.357036912000069],
            [-80.762706223999942, 35.357028912000033],
            [-80.762598224999977, 35.357031912000025],
            [-80.762408224999945, 35.356995912000059],
            [-80.762142224999934, 35.356984913000076],
            [-80.761959223999952, 35.356960912000034],
            [-80.761931224999955, 35.356952912000054],
            [-80.76180922399999, 35.356866912000044],
            [-80.761756224999942, 35.356781912000031],
            [-80.761723224999969, 35.356701912000062],
            [-80.761638224999956, 35.356609912000067],
            [-80.761603223999941, 35.35655691200003],
            [-80.761580223999943, 35.356496912000068],
            [-80.761541224999974, 35.356308912000031],
            [-80.76151222499999, 35.356242912000027],
            [-80.761402223999937, 35.356166912000049],
            [-80.761369223999964, 35.356114912000066],
            [-80.761337223999988, 35.356017912000027],
            [-80.761298223999972, 35.355955911000024],
            [-80.761166223999965, 35.355834913000024],
            [-80.761054223999963, 35.355716912000048],
            [-80.760973224999987, 35.35558891200003],
            [-80.760881224999935, 35.355510912000057],
            [-80.760714222999979, 35.355399912000053],
            [-80.760482223999986, 35.355223912000042],
            [-80.760350223999978, 35.355145911000079],
            [-80.760193223999977, 35.35504091200005],
            [-80.760144223999987, 35.354996912000047],
            [-80.759973223999964, 35.354719912000064],
            [-80.759941223999988, 35.354604911000024],
            [-80.759929223999961, 35.354543911000064],
            [-80.759893222999949, 35.354456911000057],
            [-80.759801223999943, 35.354311912000071],
            [-80.759702223999966, 35.354212912000037],
            [-80.759653223999976, 35.354174912000076],
            [-80.759508223999944, 35.35409891200004],
            [-80.759420223999939, 35.354042911000079],
            [-80.759374223999941, 35.353999912000063],
            [-80.759308222999948, 35.353984911000055],
            [-80.759211222999966, 35.353995912000073],
            [-80.75912722399994, 35.353998912000066],
            [-80.759015223999938, 35.353983912000047],
            [-80.758641223999973, 35.353990912000029],
            [-80.758576223999967, 35.353984911000055],
            [-80.758514223999953, 35.353973911000026],
            [-80.758400222999967, 35.353920911000046],
            [-80.758264223999959, 35.353829911000048],
            [-80.758124223999971, 35.353766912000026],
            [-80.757989222999981, 35.35369391100005],
            [-80.757935222999947, 35.353661911000074],
            [-80.757722223999963, 35.353592912000067],
            [-80.756895222999958, 35.355332911000062],
            [-80.756975222999984, 35.355382912000039],
            [-80.757042223999974, 35.35544591200005],
            [-80.757111222999981, 35.355528911000079],
            [-80.757148222999945, 35.355591912000079],
            [-80.757189222999955, 35.355699913000024],
            [-80.757200222999984, 35.355742912000039],
            [-80.757221222999988, 35.355999912000073],
            [-80.757227222999973, 35.356234912000048],
            [-80.757230222999965, 35.356904912000061],
            [-80.757251223999958, 35.357193912000071],
            [-80.757315222999978, 35.357582912000055],
            [-80.757344222999961, 35.35771191200007],
            [-80.757398222999939, 35.357876912000052],
            [-80.75744522399998, 35.357977912000024],
            [-80.757504222999955, 35.358072912000068],
            [-80.75755122399994, 35.358134913000072],
            [-80.75791822399998, 35.358618913000043],
            [-80.757988222999984, 35.358722913000065],
            [-80.758055224999964, 35.358840912000062],
            [-80.75809822399998, 35.358950912000068],
            [-80.758136223999941, 35.359106913000062],
            [-80.758272224999985, 35.359861913000032],
            [-80.758321223999985, 35.360067914000069],
            [-80.758388224999976, 35.360266913000032],
            [-80.757704223999951, 35.360475913000073],
            [-80.757533222999939, 35.360520912000027],
            [-80.757423223999979, 35.360537912000041],
            [-80.757268222999983, 35.360549913000057],
            [-80.756832223999936, 35.360563913000078],
            [-80.756649223999943, 35.360569913000063],
            [-80.756503222999982, 35.360583913000028],
            [-80.75640322299995, 35.360609913000076],
            [-80.756330222999964, 35.360633914000061],
            [-80.75626822299995, 35.360658913000066],
            [-80.756222222999952, 35.360682913000062],
            [-80.75612122299998, 35.360745913000073],
            [-80.756041222999954, 35.360804913000038],
            [-80.75597522299995, 35.360866913000052],
            [-80.755718223999963, 35.360724914000059],
            [-80.755590222999956, 35.360666913000045],
            [-80.755456222999953, 35.360628913000028],
            [-80.755302222999944, 35.360604913000032],
            [-80.75463922299997, 35.360549913000057],
            [-80.754525222999973, 35.360543913000072],
            [-80.754520222999986, 35.360687914000039],
            [-80.754445222999948, 35.361927914000034],
            [-80.754373222999959, 35.363388914000041],
            [-80.75434622299997, 35.363337913000066],
            [-80.754311222999945, 35.363236914000026],
            [-80.754278222999972, 35.363192914000024],
            [-80.754163222999978, 35.363087914000062],
            [-80.75413322299994, 35.362908914000059],
            [-80.754118222999978, 35.36275891400004],
            [-80.754101222999964, 35.362592913000071],
            [-80.754033222999965, 35.36249291300004],
            [-80.753938222999977, 35.362309914000036],
            [-80.753869222999981, 35.362146913000061],
            [-80.753719221999972, 35.361930913000037],
            [-80.75366022299994, 35.361871913000073],
            [-80.753497221999964, 35.361757913000076],
            [-80.753433222999945, 35.361678913000048],
            [-80.753361221999967, 35.361527914000078],
            [-80.753212222999935, 35.361037913000075],
            [-80.753172222999979, 35.360888913000053],
            [-80.753123222999989, 35.360854913000026],
            [-80.753080221999937, 35.360824913000044],
            [-80.75297722199997, 35.360790913000073],
            [-80.752928222999969, 35.360680913000067],
            [-80.75288722199997, 35.360642913000049],
            [-80.752749222999967, 35.360674912000036],
            [-80.752716221999947, 35.360656913000071],
            [-80.752649221999945, 35.360601913000039],
            [-80.752634222999973, 35.360547914000051],
            [-80.752625221999949, 35.360464913000044],
            [-80.752577222999946, 35.360438913000053],
            [-80.752550221999968, 35.360393913000053],
            [-80.752559221999945, 35.360274913000069],
            [-80.75245522299997, 35.360198913000033],
            [-80.752425221999943, 35.360136913000076],
            [-80.752456221999978, 35.360082913000042],
            [-80.752433221999979, 35.360031913000057],
            [-80.752407221999988, 35.360008913000058],
            [-80.752330221999955, 35.359967913000048],
            [-80.752256221999971, 35.359939913000062],
            [-80.752234220999981, 35.359906913000032],
            [-80.752207221999981, 35.359841913000025],
            [-80.75214822199996, 35.359791913000038],
            [-80.752067221999937, 35.359771913000031],
            [-80.752004221999982, 35.359724912000047],
            [-80.751958221999985, 35.359618913000077],
            [-80.751929221999944, 35.359582913000054],
            [-80.751862221999943, 35.359551913000075],
            [-80.751775222999981, 35.359537913000054],
            [-80.751662221999936, 35.359554913000068],
            [-80.751459220999948, 35.359601912000073],
            [-80.751272220999965, 35.35969691300005],
            [-80.751214220999941, 35.35970091300004],
            [-80.751120220999951, 35.359725913000034],
            [-80.751074221999943, 35.359798913000077],
            [-80.751021220999974, 35.359864913000024],
            [-80.750938221999945, 35.359910914000068],
            [-80.750830221999934, 35.359941913000057],
            [-80.75079722199996, 35.359966913000051],
            [-80.750709220999966, 35.360098913000058],
            [-80.750652220999939, 35.360153913000033],
            [-80.750587220999989, 35.360186913000064],
            [-80.750515220999944, 35.360211913000057],
            [-80.750479220999978, 35.36020691300007],
            [-80.750438220999968, 35.360179913000024],
            [-80.750430220999988, 35.360144913000056],
            [-80.750407221999978, 35.360102913000048],
            [-80.750378220999949, 35.360076913000057],
            [-80.750334220999946, 35.360068913000077],
            [-80.750254221999967, 35.360087913000029],
            [-80.75016322099998, 35.360094913000069],
            [-80.750109220999946, 35.360107913000036],
            [-80.750080220999962, 35.360137913000074],
            [-80.750067220999938, 35.360188913000059],
            [-80.750042221999934, 35.360221913000032],
            [-80.749977220999938, 35.36022291300003],
            [-80.749806220999972, 35.360087914000076],
            [-80.749790220999955, 35.360068912000031],
            [-80.749765221999951, 35.360037913000042],
            [-80.749756220999984, 35.359942913000054],
            [-80.749773220999941, 35.359873913000058],
            [-80.749848221999969, 35.359812913000042],
            [-80.749954220999939, 35.359686913000075],
            [-80.749952220999944, 35.359587913000041],
            [-80.749917220999976, 35.359483913000076],
            [-80.749508221999974, 35.359537914000043],
            [-80.749271220999958, 35.360198913000033],
            [-80.749227219999966, 35.360320913000066],
            [-80.749097220999943, 35.360666913000045],
            [-80.748972220999974, 35.361028913000041],
            [-80.748891220999951, 35.361332914000059],
            [-80.748516220999988, 35.36313791300006],
            [-80.748481221999953, 35.363323915000024],
            [-80.74846421999996, 35.363438914000028],
            [-80.748439220999956, 35.36371491500006],
            [-80.74844122099995, 35.363876914000059],
            [-80.748453220999977, 35.364010913000072],
            [-80.748483219999969, 35.364101914000059],
            [-80.748509220999949, 35.364146914000059],
            [-80.748544220999975, 35.364204914000027],
            [-80.74878322099994, 35.364533915000038],
            [-80.749163221999936, 35.365033914000037],
            [-80.749257220999937, 35.365147914000033],
            [-80.749353220999978, 35.365251915000044],
            [-80.749437221999983, 35.365329914000029],
            [-80.74952222099995, 35.365400915000066],
            [-80.749615220999942, 35.365471914000068],
            [-80.749773221999988, 35.365582914000072],
            [-80.750467221999941, 35.366032915000062],
            [-80.750822221999954, 35.366257914000073],
            [-80.751324221999937, 35.366585914000041],
            [-80.751570220999952, 35.366760914000054],
            [-80.751754222999978, 35.366902914000036],
            [-80.751951221999946, 35.367064914000025],
            [-80.753261222999981, 35.368192915000066],
            [-80.753441221999935, 35.368350915000065],
            [-80.753610222999953, 35.368499915000029],
            [-80.753765223999949, 35.368644915000061],
            [-80.753924222999956, 35.368809914000053],
            [-80.754036222999957, 35.368941914000061],
            [-80.754104222999956, 35.369028914000069],
            [-80.754661222999971, 35.369731915000045],
            [-80.754806222999946, 35.36989991400003],
            [-80.754820222999967, 35.369914915000038],
            [-80.754943222999941, 35.37004891600003],
            [-80.755072223999946, 35.370178915000054],
            [-80.755259222999939, 35.370342915000037],
            [-80.755346223999936, 35.370418915000073],
            [-80.755503223999938, 35.370547915000031],
            [-80.755752223999934, 35.370739915000058],
            [-80.755916223999975, 35.370850915000062],
            [-80.756042223999941, 35.370922915000051],
            [-80.756167222999977, 35.370981915000073],
            [-80.756448223999939, 35.371092915000077],
            [-80.756769224999971, 35.371210915000063],
            [-80.757642224999984, 35.37149691500008],
            [-80.757912224999984, 35.371590916000059],
            [-80.758124223999971, 35.371671916000025],
            [-80.758287224999947, 35.371744915000079],
            [-80.758408224999982, 35.371803915000044],
            [-80.758753224999964, 35.371988915000031],
            [-80.759122224999942, 35.372199915000067],
            [-80.760663224999973, 35.373083915000052],
            [-80.760788225999988, 35.373154915000043],
            [-80.761050224999963, 35.373292916000025],
            [-80.761253225999951, 35.373385915000028],
            [-80.761498224999968, 35.37348591500006],
            [-80.761660225999947, 35.373536916000035],
            [-80.761860225999953, 35.373586915000033],
            [-80.76206022599996, 35.373625915000048],
            [-80.76228522599996, 35.373654916000078],
            [-80.762413225999978, 35.373664915000063],
            [-80.762701224999944, 35.373676916000079],
            [-80.763549225999952, 35.373701915000026],
            [-80.76381622599996, 35.373716916000035],
            [-80.763809226999967, 35.373917916000039],
            [-80.76376722599997, 35.374666916000024],
            [-80.763745226999959, 35.375284916000055],
            [-80.763745225999969, 35.375447916000041],
            [-80.763751225999954, 35.375572916000067],
            [-80.76376722599997, 35.375679916000024],
            [-80.763809225999978, 35.37580891500005],
            [-80.763851225999986, 35.375893916000052],
            [-80.76391422599994, 35.375989916000037],
            [-80.763960225999938, 35.376044916000069],
            [-80.764049225999941, 35.376135916000067],
            [-80.76432622599998, 35.376371917000029],
            [-80.764437226999974, 35.376472915000079],
            [-80.764577225999972, 35.376613916000053],
            [-80.764893226999959, 35.376974916000052],
            [-80.765012226999943, 35.377129916000058],
            [-80.765062225999941, 35.377215916000068],
            [-80.765101226999946, 35.377325917000064],
            [-80.765108225999938, 35.377367916000026],
            [-80.765105226999935, 35.37745491700008],
            [-80.765091226999971, 35.377509916000065],
            [-80.765068225999983, 35.377571917000068],
            [-80.764712225999972, 35.378458916000056],
            [-80.764426225999955, 35.379101917000071],
            [-80.764205226999934, 35.379921916000058],
            [-80.764516226999945, 35.380110917000025],
            [-80.765045226999973, 35.38040791700007],
            [-80.765262226999937, 35.380521917000067],
            [-80.76538422699997, 35.380577917000039],
            [-80.765542227999958, 35.380639918000043],
            [-80.766142226999989, 35.380764916000032],
            [-80.76722222799998, 35.38096691700008],
            [-80.767465226999946, 35.381007917000034],
            [-80.767294227999969, 35.381187917000034],
            [-80.767138227999965, 35.381352917000072],
            [-80.767141227999957, 35.381392917000028],
            [-80.767172227999936, 35.381468917000063],
            [-80.767196227999989, 35.38148491700008],
            [-80.767226226999981, 35.381484916000034],
            [-80.767242227999986, 35.381470917000058],
            [-80.767261227999938, 35.381444917000067],
            [-80.767282227999942, 35.381432917000041],
            [-80.76731622799997, 35.381432917000041],
            [-80.767365226999971, 35.381440917000077],
            [-80.767450227999973, 35.381485916000031],
            [-80.767540226999984, 35.381503917000032],
            [-80.767589227999963, 35.38151891800004],
            [-80.767651227999977, 35.381531918000064],
            [-80.767699227999969, 35.381551917000024],
            [-80.767741226999988, 35.381580917000065],
            [-80.767769227999963, 35.381617918000074],
            [-80.767799226999955, 35.38167691700005],
            [-80.767805227999986, 35.381719917000055],
            [-80.767822227999943, 35.381761917000063],
            [-80.767850227999986, 35.381806917000063],
            [-80.767887228999939, 35.381843917000026],
            [-80.768015227999967, 35.381934917000024],
            [-80.76807122799994, 35.381956917000025],
            [-80.768138227999941, 35.381964918000051],
            [-80.768183227999941, 35.381976917000031],
            [-80.768225227999949, 35.381997917000035],
            [-80.76824722799995, 35.382015917000047],
            [-80.768270227999949, 35.382045918000074],
            [-80.768312227999957, 35.382138917000077],
            [-80.76833722799995, 35.382152917000042],
            [-80.768391227999984, 35.382171917000051],
            [-80.768413227999986, 35.382184917000075],
            [-80.768434226999943, 35.382210917000066],
            [-80.768455227999937, 35.382242917000042],
            [-80.768501227999934, 35.382320917000072],
            [-80.768624228999954, 35.38241991700005],
            [-80.768710227999975, 35.382480917000066],
            [-80.768774228999973, 35.382515917000035],
            [-80.768828227999961, 35.382555917000047],
            [-80.765032226999949, 35.38225791800005],
            [-80.764714226999956, 35.382243917000039],
            [-80.764380225999957, 35.38221291800005],
            [-80.76418722599999, 35.382200918000024],
            [-80.762911226999961, 35.382090917000028],
            [-80.762538225999947, 35.382063917000039],
            [-80.762475225999935, 35.382058917000052],
            [-80.76213422699999, 35.382029917000068],
            [-80.758996223999986, 35.381910917000027],
            [-80.75799322599994, 35.381873917000064],
            [-80.757786223999972, 35.381827917000066],
            [-80.756790224999975, 35.381607918000043],
            [-80.756204224999976, 35.381560917000058],
            [-80.755313223999963, 35.381490918000054],
            [-80.755083223999975, 35.381472917000053],
            [-80.754348223999955, 35.381414917000029],
            [-80.754133223999986, 35.381955917000028],
            [-80.753949223999939, 35.382437918000051],
            [-80.753920223999955, 35.382639918000052],
            [-80.753919223999958, 35.382720918000075],
            [-80.753928223999935, 35.382809918000078],
            [-80.753954222999937, 35.382957918000045],
            [-80.75398622299997, 35.383062917000075],
            [-80.75402322399998, 35.383160917000055],
            [-80.754077224999946, 35.383269918000053],
            [-80.754236222999964, 35.383474918000047],
            [-80.754280223999956, 35.383525918000032],
            [-80.754214223999952, 35.383592917000044],
            [-80.754183222999984, 35.38361691800003],
            [-80.754115223999975, 35.38364191800008],
            [-80.753983223999967, 35.383670918000064],
            [-80.753815223999936, 35.383679918000041],
            [-80.753677223999944, 35.383676918000049],
            [-80.753574222999987, 35.383630918000051],
            [-80.75350122399999, 35.383574918000079],
            [-80.753466223999965, 35.383540918000051],
            [-80.75337022399998, 35.383369919000074],
            [-80.753305222999984, 35.383291918000054],
            [-80.753261222999981, 35.38322991800004],
            [-80.753146222999987, 35.383164917000045],
            [-80.753111223999952, 35.383140918000038],
            [-80.753080223999973, 35.38309891800003],
            [-80.753008222999938, 35.383028918000036],
            [-80.75286022399996, 35.382932918000051],
            [-80.752699222999979, 35.38285391800008],
            [-80.75259422299996, 35.382818918000055],
            [-80.752530223999941, 35.382782918000032],
            [-80.752487222999946, 35.382773917000065],
            [-80.752424221999945, 35.382770917000073],
            [-80.752334222999934, 35.382807917000036],
            [-80.752217222999946, 35.382843918000049],
            [-80.752053222999962, 35.382880918000069],
            [-80.751977222999983, 35.382888917000059],
            [-80.751883222999936, 35.382931918000054],
            [-80.751812223999934, 35.382945917000029],
            [-80.751771223999981, 35.382960918000038],
            [-80.751687222999976, 35.382978919000038],
            [-80.751631222999947, 35.383010918000025],
            [-80.75156922299999, 35.383038918000068],
            [-80.751523223999982, 35.383041917000071],
            [-80.751452222999944, 35.383063917000072],
            [-80.751408223999988, 35.383093918000043],
            [-80.751381222999953, 35.383134919000042],
            [-80.75120622299994, 35.383249918000047],
            [-80.751155221999966, 35.383293918000049],
            [-80.751105222999968, 35.383365918000038],
            [-80.75107122299994, 35.383414918000028],
            [-80.751065222999955, 35.383445919000053],
            [-80.75101822299996, 35.383471917000065],
            [-80.750955222999949, 35.383493917000067],
            [-80.750904222999964, 35.383523918000037],
            [-80.750600222999935, 35.383678918000044],
            [-80.750586222999971, 35.38369891800005],
            [-80.750578222999934, 35.383724917000052],
            [-80.750550223999937, 35.383763917000067],
            [-80.750501222999958, 35.38379291900003],
            [-80.750466222999989, 35.383828918000063],
            [-80.75043722199996, 35.383866918000024],
            [-80.750411222999958, 35.383917919000055],
            [-80.750383222999972, 35.38401791800004],
            [-80.750301221999962, 35.384250918000077],
            [-80.750287222999987, 35.384320918000071],
            [-80.75026722299998, 35.384354918000042],
            [-80.750248222999971, 35.384421918000044],
            [-80.750225221999983, 35.38448291900005],
            [-80.750170221999952, 35.384571919000052],
            [-80.750154222999981, 35.384591919000059],
            [-80.750101223999934, 35.384535919000029],
            [-80.750060222999934, 35.384514919000026],
            [-80.750007221999965, 35.384517918000029],
            [-80.749966222999944, 35.38453191800005],
            [-80.749910221999983, 35.384539919000076],
            [-80.749746222999988, 35.384537918000035],
            [-80.749719222999943, 35.384546918000069],
            [-80.749670222999953, 35.384573918000058],
            [-80.749633222999989, 35.384607918000029],
            [-80.749553222999964, 35.38456891800007],
            [-80.749509222999961, 35.384556919000033],
            [-80.749413222999976, 35.384550919000048],
            [-80.749230221999937, 35.384573918000058],
            [-80.749129222999954, 35.384582918000035],
            [-80.749065222999945, 35.384582918000035],
            [-80.749002221999945, 35.384577919000037],
            [-80.748897221999982, 35.384550918000059],
            [-80.748835221999968, 35.384540919000074],
            [-80.748748221999961, 35.384534918000043],
            [-80.748706221999953, 35.384544918000074],
            [-80.748648221999986, 35.384566918000075],
            [-80.748622221999938, 35.384586918000025],
            [-80.748581221999984, 35.384606918000031],
            [-80.74856422199997, 35.384640918000059],
            [-80.748562221999975, 35.384902919000069],
            [-80.748557222999978, 35.384937918000048],
            [-80.74852722199995, 35.384980919000043],
            [-80.748496221999972, 35.384997918000067],
            [-80.748451221999971, 35.385012918000029],
            [-80.748379221999983, 35.385068919000048],
            [-80.748014221999938, 35.385116918000051],
            [-80.747691221999958, 35.385121919000028],
            [-80.747088221999945, 35.385080919000075],
            [-80.746836221999956, 35.384939918000043],
            [-80.746293221999963, 35.385017919000063],
            [-80.746035221999989, 35.385230918000047],
            [-80.74558222099995, 35.385604919000059],
            [-80.744273220999958, 35.38610791900004],
            [-80.743674219999946, 35.386386919000074],
            [-80.743657219999989, 35.386400919000039],
            [-80.742878219999966, 35.387049919000049],
            [-80.740548218999947, 35.388335920000031],
            [-80.740063219999968, 35.388412919000075],
            [-80.739325219999955, 35.388169920000053],
            [-80.738301219999983, 35.387615919000041],
            [-80.737701218999973, 35.387533919000077],
            [-80.736936218999972, 35.387563920000048],
            [-80.736740218999955, 35.387170920000074],
            [-80.736406218999946, 35.38703491900003],
            [-80.735903218999965, 35.386627920000024],
            [-80.735678217999975, 35.386581920000026],
            [-80.734868217999974, 35.386832920000074],
            [-80.73458921799994, 35.387129919000074],
            [-80.734113217999948, 35.387200919000065],
            [-80.733804216999943, 35.387109919000068],
            [-80.733440217999942, 35.386811919000024],
            [-80.733355216999939, 35.386071919000074],
            [-80.733047217999967, 35.385845920000065],
            [-80.732601217999957, 35.385664919000078],
            [-80.732347217999973, 35.385618919000024],
            [-80.731760216999987, 35.386077919000059],
            [-80.731733216999942, 35.386347919000059],
            [-80.731314216999976, 35.386833919000026],
            [-80.730894215999967, 35.386832920000074],
            [-80.73069821699994, 35.386444920000031],
            [-80.730418216999965, 35.386236920000044],
            [-80.730111216999944, 35.386308920000033],
            [-80.729720217999954, 35.386532919000047],
            [-80.729443216999982, 35.387011920000077],
            [-80.729387215999964, 35.387109920000057],
            [-80.728772215999982, 35.387227920000043],
            [-80.728601216999948, 35.387107920000062],
            [-80.728462215999969, 35.386511920000032],
            [-80.728239216999953, 35.386493919000031],
            [-80.727818215999946, 35.386880920000067],
            [-80.72792921599995, 35.387087920000056],
            [-80.727707215999942, 35.387312920000056],
            [-80.72737021599994, 35.387293919000058],
            [-80.726895215999946, 35.38706792000005],
            [-80.726742214999945, 35.387074920000032],
            [-80.726504215999967, 35.387084919000074],
            [-80.725806214999977, 35.388210919000073],
            [-80.724856214999988, 35.388983921000033],
            [-80.724296215999971, 35.389306920000024],
            [-80.724298214999976, 35.390037921000044],
            [-80.724521214999982, 35.390707920000068],
            [-80.724690215999942, 35.390932921000058],
            [-80.724858215999973, 35.391571921000036],
            [-80.724802215999944, 35.39196792100006],
            [-80.724607215999981, 35.39210292100006],
            [-80.724590215999967, 35.392189920000078],
            [-80.72446621499995, 35.392904922000071],
            [-80.724310214999946, 35.393088921000071],
            [-80.72421421599995, 35.39320192200006],
            [-80.72368221499994, 35.393434921000051],
            [-80.723544214999947, 35.394029921000026],
            [-80.72354321499995, 35.394780922000052],
            [-80.723750215999985, 35.395116921000067],
            [-80.723825214999977, 35.395238921000043],
            [-80.724468215999934, 35.395862922000049],
            [-80.724607215999981, 35.396108922000053],
            [-80.72454421499998, 35.396288922000053],
            [-80.724441215999946, 35.396592922000025],
            [-80.724550215999955, 35.397647923000079],
            [-80.724777214999961, 35.398054922000028],
            [-80.724719214999936, 35.399451923000072],
            [-80.724917215999938, 35.399749922000069],
            [-80.725506216999975, 35.400273923000043],
            [-80.725309215999971, 35.401148923000051],
            [-80.725391215999935, 35.401581923000037],
            [-80.725756216999969, 35.40199692300007],
            [-80.726595216999954, 35.40259392300004],
            [-80.727322217999983, 35.402868924000074],
            [-80.728022217999978, 35.403392923000069],
            [-80.72822021799999, 35.403868924000051],
            [-80.728582217999985, 35.405456924000077],
            [-80.728668217999939, 35.406782924000026],
            [-80.728865216999964, 35.40708092400007],
            [-80.729480217999935, 35.407676924000043],
            [-80.729790217999948, 35.408234924000055],
            [-80.730013217999954, 35.408635924000066],
            [-80.730015217999949, 35.410608925000076],
            [-80.730125217999955, 35.410906925000063],
            [-80.730658218999963, 35.411655925000048],
            [-80.731835217999958, 35.413031925000041],
            [-80.738042219999954, 35.413284925000028],
            [-80.737980220999987, 35.413656925000055],
            [-80.737154219999979, 35.418635926000036],
            [-80.737707220999937, 35.419270926000024],
            [-80.737474219999967, 35.419382926000026],
            [-80.737633220999953, 35.419623926000043],
            [-80.738305220999962, 35.420384926000054],
            [-80.740289222999934, 35.42344792800003],
            [-80.740354221999951, 35.423670927000046],
            [-80.740281220999975, 35.42391292700006],
            [-80.740030221999973, 35.424192927000036],
            [-80.739768221999952, 35.424368927000046],
            [-80.739644221999981, 35.424592928000038],
            [-80.739650220999977, 35.424600927000029],
            [-80.739730221999935, 35.424701927000058],
            [-80.739890220999939, 35.424554928000077],
            [-80.739970221999954, 35.424497928000051],
            [-80.740139220999936, 35.424396928000078],
            [-80.740465221999955, 35.424244927000075],
            [-80.740795221999974, 35.424102927000035],
            [-80.741058221999936, 35.42439392700004],
            [-80.741393221999942, 35.424764927000069],
            [-80.743227222999963, 35.426441928000031],
            [-80.74395522399999, 35.427143928000078],
            [-80.745188222999957, 35.428326927000057],
            [-80.745333223999978, 35.428484928000046],
            [-80.746953223999981, 35.43024992900007],
            [-80.747193223999943, 35.430600929000036],
            [-80.74766322499994, 35.431314928000063],
            [-80.747728224999946, 35.43141392900003],
            [-80.748239224999963, 35.43253992800004],
            [-80.748306224999965, 35.432526928000073],
            [-80.748703223999939, 35.433533928000031],
            [-80.749139224999965, 35.434600929000055],
            [-80.749167224999951, 35.434852929000044],
            [-80.749251224999966, 35.434897929000044],
            [-80.749301225999943, 35.435264929000027],
            [-80.749385224999969, 35.435676929000067],
            [-80.749498223999979, 35.43672992900008],
            [-80.749750225999946, 35.436865930000067],
            [-80.749795224999957, 35.436890929000072],
            [-80.750368225999978, 35.437841929000058],
            [-80.750680225999986, 35.438360930000044],
            [-80.750898225999947, 35.438963930000057],
            [-80.750980225999967, 35.439008930000057],
            [-80.750981225999965, 35.439163931000053],
            [-80.751335225999981, 35.439988930000027],
            [-80.751417225999944, 35.440032930000029],
            [-80.752048226999989, 35.44195293100006],
            [-80.754523227999982, 35.44176893000008],
            [-80.756917227999963, 35.44159293000007],
            [-80.759107227999948, 35.441420930000049],
            [-80.759263228999941, 35.441409930000077],
            [-80.760386227999959, 35.441333929000052],
            [-80.761181228999988, 35.44127093000003],
            [-80.76214822899999, 35.441197929000055],
            [-80.762788228999966, 35.441160930000024],
            [-80.762864229999934, 35.441153931000031],
            [-80.763665229999958, 35.44109093000003],
            [-80.764040229999978, 35.441053930000066],
            [-80.764126228999942, 35.44104593000003],
            [-80.764470230999962, 35.441011930000059],
            [-80.764912229999936, 35.440974930000039],
            [-80.764113229999964, 35.439276930000062],
            [-80.763694229999942, 35.438387929000044],
            [-80.763283229999956, 35.437512929000036],
            [-80.762634228999957, 35.436135929000045],
            [-80.762526228999945, 35.435905929000057],
            [-80.761990229999981, 35.43480992800005],
            [-80.761837229999969, 35.434496929000034],
            [-80.761793228999977, 35.434405928000047],
            [-80.761330228999952, 35.433460928000045],
            [-80.761039228999948, 35.432866929000056],
            [-80.76100822799998, 35.432802928000058],
            [-80.760786228999962, 35.432349928000065],
            [-80.760611228999949, 35.431990927000072],
            [-80.759857227999987, 35.430450927000038],
            [-80.759806227999945, 35.430345928000065],
            [-80.759778227999959, 35.430288928000039],
            [-80.759770227999979, 35.430272928000079],
            [-80.759763226999951, 35.430258928000057],
            [-80.759245228999987, 35.429196927000078],
            [-80.759224227999937, 35.429153928000062],
            [-80.759208227999977, 35.429120928000032],
            [-80.759189228999958, 35.429081927000027],
            [-80.759177227999942, 35.429057928000077],
            [-80.75916622799997, 35.429035927000029],
            [-80.759088228999985, 35.428876927000033],
            [-80.758368227999938, 35.427329927000073],
            [-80.757311226999946, 35.424879927000063],
            [-80.756779226999981, 35.423656926000035],
            [-80.756889226999988, 35.423635926000031],
            [-80.757135226999935, 35.423599927000055],
            [-80.757363226999985, 35.423571926000079],
            [-80.757572226999969, 35.423547926000026],
            [-80.757801227999948, 35.423532926000064],
            [-80.758003226999961, 35.423542927000028],
            [-80.758107227999972, 35.423558927000045],
            [-80.758384227999954, 35.423632926000039],
            [-80.758597226999939, 35.423707926000077],
            [-80.759359227999937, 35.423979926000072],
            [-80.759459227999969, 35.424013927000033],
            [-80.759709227999963, 35.424099926000054],
            [-80.759922227999937, 35.424161926000068],
            [-80.760158227999966, 35.42421792600004],
            [-80.760354228999972, 35.424253926000063],
            [-80.761396227999967, 35.424417926000046],
            [-80.762391228999945, 35.424575926000045],
            [-80.763091227999951, 35.424680926000065],
            [-80.763994228999934, 35.424795926000058],
            [-80.764143228999956, 35.424798926000051],
            [-80.764277228999958, 35.424782926000034],
            [-80.76438922899996, 35.424755926000046],
            [-80.76442222999998, 35.424742926000079],
            [-80.764482228999952, 35.424720926000077],
            [-80.764608229999965, 35.424658926000063],
            [-80.764717228999984, 35.424591926000062],
            [-80.765250229999936, 35.424208926000063],
            [-80.76659922999994, 35.423237925000024],
            [-80.767255229999989, 35.422774926000045],
            [-80.767478228999948, 35.422621926000033],
            [-80.767711230999964, 35.422484926000038],
            [-80.767921229999956, 35.42237992500003],
            [-80.768084228999953, 35.422308925000038],
            [-80.768270229999985, 35.422237926000037],
            [-80.768991230999973, 35.42197692600007],
            [-80.770048230999976, 35.421581925000055],
            [-80.77202123099994, 35.420857925000064],
            [-80.772284230999958, 35.420768925000061],
            [-80.772451230999934, 35.420721925000066],
            [-80.77271923099994, 35.420670925000024],
            [-80.772828230999949, 35.420655926000052],
            [-80.773100230999944, 35.420629925000071],
            [-80.773363231999951, 35.420621925000034],
            [-80.773641231999989, 35.420631925000066],
            [-80.774048231999984, 35.420669925000027],
            [-80.774852231999944, 35.420764925000071],
            [-80.775293231999967, 35.420820925000044],
            [-80.775522232999947, 35.420853925000074],
            [-80.775693231999981, 35.420892925000032],
            [-80.775908231999949, 35.420958925000036],
            [-80.776345232999972, 35.421141925000029],
            [-80.776672232999942, 35.421298925000031],
            [-80.776899231999948, 35.421420925000064],
            [-80.777019232999976, 35.421493926000039],
            [-80.777140232999955, 35.421573925000075],
            [-80.777360232999968, 35.421735925000064],
            [-80.777430231999972, 35.421794925000029],
            [-80.777555232999987, 35.421910925000077],
            [-80.777687232999938, 35.422057926000036],
            [-80.777826232999985, 35.422243925000032],
            [-80.77797523299995, 35.422479925000061],
            [-80.778001232999941, 35.422513925000032],
            [-80.778067232999945, 35.422584925000024],
            [-80.77813923299999, 35.422644926000032],
            [-80.778223231999959, 35.422696925000025],
            [-80.778313232999949, 35.422739926000077],
            [-80.778394233999961, 35.422766925000076],
            [-80.778982233999955, 35.422926926000059],
            [-80.779789232999974, 35.423130926000056],
            [-80.780754234999961, 35.423381926000047],
            [-80.781175233999988, 35.423475925000048],
            [-80.781472233999978, 35.423525926000025],
            [-80.781540233999976, 35.42353092500008],
            [-80.781783233999988, 35.42352792500003],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 70,
        "SolutionID": "PI-29:H",
        "Shape_Length": 0.57205739004563882,
        "Shape_Area": 0.0071016357909662936
      }
    },
    {
      "type": "Feature",
      "id": 71,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801884240999982, 35.44207392900006],
            [-80.80211424099997, 35.44190192800005],
            [-80.802210240999955, 35.441819928000029],
            [-80.802300241999944, 35.441733928000076],
            [-80.80238424099997, 35.441631928000049],
            [-80.802459241999941, 35.441510928000071],
            [-80.802495240999974, 35.441435929000079],
            [-80.802540241999964, 35.441315928000051],
            [-80.802577241999984, 35.441136929000038],
            [-80.802586240999972, 35.44105292900008],
            [-80.802589241999954, 35.44095192900005],
            [-80.802580241999976, 35.440864928000053],
            [-80.802557241999978, 35.44072692900005],
            [-80.802483240999948, 35.440525929000046],
            [-80.802323241999943, 35.440196928000034],
            [-80.802072241999952, 35.43972592800003],
            [-80.802036240999939, 35.439648928000054],
            [-80.802011241999935, 35.439583928000047],
            [-80.801995240999986, 35.439516929000035],
            [-80.801985240999954, 35.439439929000059],
            [-80.801992241999983, 35.439321928000027],
            [-80.802007241999945, 35.43925292800003],
            [-80.802042240999981, 35.43915492800005],
            [-80.802094240999963, 35.439034928000069],
            [-80.802369240999951, 35.438474928000062],
            [-80.802770240999962, 35.437699928000029],
            [-80.802866241999936, 35.437485928000058],
            [-80.80288724199994, 35.437415927000075],
            [-80.802903240999967, 35.43734192800008],
            [-80.802913240999942, 35.437245928000038],
            [-80.802923241999963, 35.437049928000079],
            [-80.802923240999974, 35.436732927000037],
            [-80.802930241999945, 35.436538927000072],
            [-80.802982241999985, 35.43576592800008],
            [-80.803027240999938, 35.435273927000026],
            [-80.803044241999942, 35.435174927000048],
            [-80.803086241999949, 35.435019927000042],
            [-80.803152240999964, 35.434849927000073],
            [-80.803447240999958, 35.434206927000048],
            [-80.803523240999937, 35.434061927000073],
            [-80.803585240999951, 35.433958927000049],
            [-80.803898240999956, 35.433915927000044],
            [-80.804082241999936, 35.433901927000079],
            [-80.804412241999955, 35.433887927000058],
            [-80.805324241999983, 35.433862926000074],
            [-80.80542724299994, 35.433866927000054],
            [-80.805519241999946, 35.433875927000031],
            [-80.805692241999964, 35.433912927000051],
            [-80.805800241999975, 35.433944926000038],
            [-80.805975241999988, 35.434008926000047],
            [-80.806141241999967, 35.434076927000035],
            [-80.806785241999989, 35.434366927000042],
            [-80.806876242999977, 35.434414927000034],
            [-80.806966242999977, 35.434470927000064],
            [-80.807097242999987, 35.434571927000036],
            [-80.807159241999955, 35.434628927000063],
            [-80.807499241999949, 35.434965927000064],
            [-80.807645242999968, 35.435096927000075],
            [-80.807833242999948, 35.435243927000045],
            [-80.807969242999945, 35.435340928000073],
            [-80.808096242999966, 35.435418928000047],
            [-80.808213243999944, 35.435479928000063],
            [-80.808344242999965, 35.435530927000059],
            [-80.808476241999983, 35.435570927000072],
            [-80.808729242999959, 35.435619927000062],
            [-80.808861241999978, 35.435635927000078],
            [-80.809127242999978, 35.435655927000028],
            [-80.809522243999936, 35.435666927000057],
            [-80.811476242999959, 35.435651928000027],
            [-80.812396242999966, 35.435649927000043],
            [-80.812504244999957, 35.435655928000074],
            [-80.81258124499999, 35.435664927000062],
            [-80.812659244999963, 35.435679926000034],
            [-80.81272024499998, 35.43569192700005],
            [-80.812766243999988, 35.435705927000072],
            [-80.812925243999985, 35.435768927000026],
            [-80.813029244999939, 35.435821927000063],
            [-80.813146243999938, 35.435892926000065],
            [-80.813729244999934, 35.436321927000051],
            [-80.81416524399998, 35.436623927000028],
            [-80.814621243999966, 35.436932927000043],
            [-80.815332245999969, 35.437424927000052],
            [-80.815596244999938, 35.437616928000068],
            [-80.816460245999963, 35.438277928000048],
            [-80.817083245999982, 35.438754927000048],
            [-80.817160245999958, 35.438804928000025],
            [-80.817248245999963, 35.438852928000074],
            [-80.817330244999937, 35.438890928000035],
            [-80.817407245999959, 35.438917927000034],
            [-80.817525244999956, 35.438950927000064],
            [-80.818124245999968, 35.439054928000076],
            [-80.819434245999958, 35.439276927000037],
            [-80.819753246999937, 35.439329927000074],
            [-80.819819246999941, 35.439339927000049],
            [-80.820044246999942, 35.439377928000056],
            [-80.820264246999955, 35.439408928000034],
            [-80.820643245999975, 35.439449927000055],
            [-80.820777245999977, 35.439455928000029],
            [-80.820961246999957, 35.439448927000058],
            [-80.821068246999971, 35.439434928000026],
            [-80.821290246999979, 35.439386927000044],
            [-80.82155324699994, 35.439303927000026],
            [-80.821690246999935, 35.439249927000049],
            [-80.821788247999962, 35.439202928000043],
            [-80.822049246999939, 35.439049927000042],
            [-80.822417246999976, 35.43880292700004],
            [-80.822680247999983, 35.438611927000068],
            [-80.822989246999953, 35.438371927000048],
            [-80.823108247999983, 35.438284927000041],
            [-80.823246247999975, 35.438208927000062],
            [-80.823377246999939, 35.438150928000027],
            [-80.823437247999948, 35.438132927000026],
            [-80.823518247999971, 35.438116927000067],
            [-80.823664246999954, 35.43810092700005],
            [-80.823744246999979, 35.438098927000055],
            [-80.823890246999952, 35.438087927000026],
            [-80.824608247999947, 35.43806392700003],
            [-80.825630247999982, 35.438009927000053],
            [-80.825892247999946, 35.43800292700007],
            [-80.826112247999959, 35.438003927000068],
            [-80.826749248999988, 35.438036927000041],
            [-80.827722248999976, 35.438097927000058],
            [-80.82858424899996, 35.438141926000071],
            [-80.829130248999945, 35.438168927000049],
            [-80.829640248999965, 35.438195927000038],
            [-80.830510248999985, 35.438230927000063],
            [-80.83078224999997, 35.438235926000061],
            [-80.831099249999966, 35.438230927000063],
            [-80.831442249999952, 35.43820592700007],
            [-80.833077250999963, 35.43800692700006],
            [-80.83336725099997, 35.437955927000075],
            [-80.833484250999959, 35.437927926000043],
            [-80.833701250999979, 35.437866926000027],
            [-80.833823250999956, 35.437824926000076],
            [-80.834115249999968, 35.437709926000025],
            [-80.837341251999987, 35.436322926000059],
            [-80.837782250999965, 35.436139926000067],
            [-80.838763251999978, 35.435721926000042],
            [-80.839565251999943, 35.435386926000035],
            [-80.839914251999971, 35.435254926000027],
            [-80.840163251999968, 35.435171925000077],
            [-80.840438251999956, 35.43510292600007],
            [-80.840649251999935, 35.435058925000078],
            [-80.840744252999968, 35.435042925000062],
            [-80.841009251999935, 35.434998925000059],
            [-80.841462252999975, 35.434923926000067],
            [-80.842266253999981, 35.434801925000045],
            [-80.843766253999945, 35.434598926000035],
            [-80.843311252999968, 35.430975925000041],
            [-80.843245252999964, 35.43045892400005],
            [-80.843157252999958, 35.429770924000024],
            [-80.843108252999968, 35.429379924000045],
            [-80.842731252999954, 35.426376924000067],
            [-80.842470251999941, 35.424276924000026],
            [-80.842410252999969, 35.423828924000077],
            [-80.842359251999937, 35.423395923000044],
            [-80.842022251999936, 35.420758922000061],
            [-80.841518251999958, 35.42077392300007],
            [-80.840916251999943, 35.420779922000065],
            [-80.840720251999983, 35.420785923000039],
            [-80.840471251999986, 35.420794923000074],
            [-80.839921250999964, 35.420802923000053],
            [-80.839768250999953, 35.420809923000036],
            [-80.839591251999934, 35.420804923000048],
            [-80.838141250999968, 35.420829923000042],
            [-80.83801724999995, 35.420828923000045],
            [-80.83790725099999, 35.420821922000073],
            [-80.837905249999949, 35.420150923000051],
            [-80.837899249999964, 35.419699923000053],
            [-80.837898249999967, 35.419598922000034],
            [-80.837895250999964, 35.41891292300005],
            [-80.837863249999941, 35.41829092200004],
            [-80.837853250999956, 35.418128922000051],
            [-80.83783324999996, 35.418042922000041],
            [-80.837797250999984, 35.417961922000075],
            [-80.837741250999954, 35.417882923000036],
            [-80.837687249999988, 35.417822922000028],
            [-80.837620249999986, 35.41776092200007],
            [-80.837542250999945, 35.417700922000051],
            [-80.837373250999974, 35.417586922000055],
            [-80.837210250999988, 35.41749492200006],
            [-80.83704524999996, 35.417418923000071],
            [-80.836866249999957, 35.417353922000075],
            [-80.836670249999941, 35.417298922000043],
            [-80.836422250999988, 35.417251922000048],
            [-80.836254249999968, 35.417207923000035],
            [-80.836127248999958, 35.417162922000045],
            [-80.836050249999971, 35.417130922000069],
            [-80.835898250999946, 35.417051922000041],
            [-80.83574324999995, 35.416956922000054],
            [-80.835325249999983, 35.416652922000026],
            [-80.834822248999956, 35.416281922000053],
            [-80.834733249999942, 35.41618492200007],
            [-80.834559249999984, 35.416219922000039],
            [-80.83441524999995, 35.416253921000077],
            [-80.834290249999981, 35.416276921000076],
            [-80.834028248999971, 35.416309922000039],
            [-80.83390124899995, 35.416318922000073],
            [-80.833719249999945, 35.416325922000055],
            [-80.833444248999967, 35.416320922000068],
            [-80.833330248999971, 35.416312921000042],
            [-80.833058248999976, 35.416280922000055],
            [-80.832852248999984, 35.416253922000067],
            [-80.831426248999946, 35.416030923000051],
            [-80.830704247999961, 35.415924922000045],
            [-80.830544248999956, 35.415890922000074],
            [-80.830366247999962, 35.415838923000024],
            [-80.83025824799995, 35.415800922000074],
            [-80.830179247999979, 35.415764922000051],
            [-80.829704248999974, 35.41554792200003],
            [-80.826966246999973, 35.414242923000074],
            [-80.825667246999956, 35.413637922000078],
            [-80.825323246999972, 35.413482922000071],
            [-80.82503924599996, 35.413359922000041],
            [-80.824910245999945, 35.413311922000048],
            [-80.82476524599997, 35.413263922000056],
            [-80.824657245999958, 35.413236922000067],
            [-80.82455824699997, 35.413216921000071],
            [-80.824254245999953, 35.413182922000033],
            [-80.824005246999945, 35.413178922000043],
            [-80.823595245999968, 35.413189922000072],
            [-80.823586245999934, 35.412828921000028],
            [-80.823592245999976, 35.412791922000054],
            [-80.823634245999983, 35.412702922000051],
            [-80.823836245999985, 35.412447922000069],
            [-80.823937245999957, 35.412493921000078],
            [-80.82407924599994, 35.412539921000075],
            [-80.824373245999936, 35.412576921000039],
            [-80.824629246999962, 35.412557922000076],
            [-80.825027245999934, 35.412387921000061],
            [-80.825116246999983, 35.412311922000072],
            [-80.825386246999983, 35.412083921000033],
            [-80.825456245999987, 35.411992921000035],
            [-80.825518245999945, 35.411921922000033],
            [-80.825603246999947, 35.411802922000049],
            [-80.825691245999963, 35.411699921000036],
            [-80.825915245999965, 35.411493921000044],
            [-80.826253246999954, 35.411218922000046],
            [-80.826417246999938, 35.411093921000031],
            [-80.826588246999961, 35.41096592100007],
            [-80.827086246999954, 35.410636921000048],
            [-80.827356246999955, 35.410461921000035],
            [-80.827703246999988, 35.410292921000064],
            [-80.828345247999948, 35.410416921000035],
            [-80.828668246999939, 35.410341921000054],
            [-80.82850224799995, 35.410285921000025],
            [-80.828572247999944, 35.410150921000024],
            [-80.828565246999972, 35.410053920000053],
            [-80.828441246999944, 35.409985921000043],
            [-80.828251246999969, 35.409846920000064],
            [-80.827957246999972, 35.409669921000045],
            [-80.827779246999967, 35.409645921000049],
            [-80.827607247999936, 35.409637921000069],
            [-80.827474246999941, 35.409605921000036],
            [-80.827344247999974, 35.409534921000045],
            [-80.827197246999958, 35.40939792100005],
            [-80.827009247999968, 35.409364921000076],
            [-80.826825246999988, 35.40927492000003],
            [-80.826775246999944, 35.409254920000024],
            [-80.826590245999967, 35.409181921000027],
            [-80.826456246999953, 35.409130921000042],
            [-80.826333247999969, 35.409100921000061],
            [-80.826205246999962, 35.409184921000076],
            [-80.826118246999954, 35.409210921000067],
            [-80.826033246999941, 35.409265921000042],
            [-80.825974246999976, 35.40925092100008],
            [-80.825920246999942, 35.409322921000069],
            [-80.825821246999965, 35.409309921000045],
            [-80.825713245999964, 35.409281921000058],
            [-80.825626245999956, 35.409193920000064],
            [-80.825567246999981, 35.409192921000056],
            [-80.825434245999986, 35.409198921000041],
            [-80.825407246999987, 35.40916092100008],
            [-80.825378245999957, 35.409089921000032],
            [-80.82531324699994, 35.409011921000058],
            [-80.825231245999987, 35.408946920000062],
            [-80.825136246999989, 35.408881921000045],
            [-80.825089245999948, 35.408843920000038],
            [-80.825061246999951, 35.408813920000057],
            [-80.825029245999986, 35.408751920000043],
            [-80.824983246999977, 35.40869192100007],
            [-80.824943245999975, 35.408667920000028],
            [-80.824862245999952, 35.408597920000034],
            [-80.824778245999937, 35.408548921000033],
            [-80.824756245999936, 35.408530921000079],
            [-80.824674246999962, 35.408430921000047],
            [-80.824627245999977, 35.408384921000049],
            [-80.824533246999977, 35.408348921000027],
            [-80.824508246999983, 35.40833192100007],
            [-80.824456246999944, 35.408316921000051],
            [-80.82433724599997, 35.408284921000075],
            [-80.824298246999945, 35.40827192100005],
            [-80.824242246999972, 35.408208921000039],
            [-80.824212245999945, 35.408188921000033],
            [-80.824150244999942, 35.408175920000076],
            [-80.824074245999952, 35.408133921000058],
            [-80.824002245999964, 35.408075921000034],
            [-80.823929245999977, 35.408061921000069],
            [-80.823736245999953, 35.408062920000077],
            [-80.823675245999937, 35.408055921000027],
            [-80.823640246999958, 35.408037921000073],
            [-80.823605245999943, 35.40801392000003],
            [-80.823543245999986, 35.407989920000034],
            [-80.823416245999965, 35.407969920000028],
            [-80.823346245999971, 35.407950921000065],
            [-80.823244245999945, 35.407907920000071],
            [-80.823187245999975, 35.407898921000026],
            [-80.823125245999961, 35.407895921000033],
            [-80.823051245999977, 35.407885921000059],
            [-80.82295624599999, 35.40782592100004],
            [-80.822865244999946, 35.407784921000029],
            [-80.822706245999939, 35.40772392100007],
            [-80.822534245999975, 35.407680921000065],
            [-80.822286244999987, 35.407673921000026],
            [-80.822170245999985, 35.407642920000058],
            [-80.822194244999935, 35.406974920000039],
            [-80.823301244999982, 35.407001920000027],
            [-80.824343245999955, 35.406828920000066],
            [-80.824958246999984, 35.40694392000006],
            [-80.825408245999938, 35.407040920000043],
            [-80.826118246999954, 35.407310920000043],
            [-80.826379246999977, 35.407599921000042],
            [-80.826539246999971, 35.408115921000046],
            [-80.826547246999951, 35.408147920000033],
            [-80.826658246999955, 35.408113921000052],
            [-80.826786245999983, 35.408030921000034],
            [-80.826925246999963, 35.407922921000079],
            [-80.826946246999967, 35.407472921000078],
            [-80.826919245999989, 35.407352920000051],
            [-80.826848246999987, 35.407226920000028],
            [-80.826804246999984, 35.407187920000069],
            [-80.826821245999952, 35.407126920000053],
            [-80.826864246999946, 35.407095920000074],
            [-80.826950245999967, 35.407083921000037],
            [-80.827041246999954, 35.407027920000075],
            [-80.827077246999977, 35.406986921000055],
            [-80.827155245999961, 35.406868920000079],
            [-80.827187246999983, 35.406676920000052],
            [-80.827155246999951, 35.406596921000073],
            [-80.827129246999959, 35.406546920000039],
            [-80.827171246999967, 35.406494920000057],
            [-80.827250246999938, 35.406443919000026],
            [-80.827421247999951, 35.406380920000061],
            [-80.827572246999978, 35.40624192000007],
            [-80.827627246999953, 35.40620092000006],
            [-80.827786246999949, 35.406162920000043],
            [-80.827871246999962, 35.406115920000047],
            [-80.827956245999985, 35.406033920000027],
            [-80.828042246999985, 35.40601292000008],
            [-80.828163246999964, 35.405909921000045],
            [-80.828218246999938, 35.405883920000065],
            [-80.828282246999947, 35.405825920000041],
            [-80.826808246999974, 35.405978920000052],
            [-80.824498245999962, 35.406123920000027],
            [-80.823040244999959, 35.406209920000038],
            [-80.821729245999961, 35.406117920000042],
            [-80.821688245999951, 35.404494920000047],
            [-80.824117245999958, 35.404702920000034],
            [-80.825130245999958, 35.404788920000044],
            [-80.82425124599996, 35.40253892000004],
            [-80.823949245999984, 35.401745919000064],
            [-80.82430024599995, 35.401596919000042],
            [-80.82439024599995, 35.401549919000047],
            [-80.824444244999938, 35.401523919000056],
            [-80.824506245999942, 35.401479919000053],
            [-80.824565244999974, 35.401423919000024],
            [-80.824617246999935, 35.401351919000035],
            [-80.82464624499994, 35.401289920000067],
            [-80.824668245999987, 35.401193919000036],
            [-80.824664244999951, 35.400959919000059],
            [-80.824669245999985, 35.400924919000033],
            [-80.82467924599996, 35.400855919000037],
            [-80.824716244999934, 35.40075291900007],
            [-80.824747245999959, 35.400689920000048],
            [-80.824793244999967, 35.400619919000064],
            [-80.82485924599996, 35.400540919000036],
            [-80.824961245999987, 35.400440918000072],
            [-80.825070244999949, 35.400354919000051],
            [-80.825163246999978, 35.400296919000027],
            [-80.825284245999967, 35.400236919000065],
            [-80.82536524599999, 35.400207919000025],
            [-80.82548924699995, 35.400178919000041],
            [-80.825601245999962, 35.400161919000027],
            [-80.826261245999945, 35.400091919000033],
            [-80.826695246999975, 35.40005491900007],
            [-80.826887246999945, 35.400046919000033],
            [-80.827476246999936, 35.400055918000078],
            [-80.82791724599997, 35.40005091900008],
            [-80.828092245999983, 35.400044919000038],
            [-80.82836724699996, 35.400022919000037],
            [-80.828532246999941, 35.399996919000046],
            [-80.828834246999975, 35.399938919000078],
            [-80.82990224699995, 35.399668919000078],
            [-80.830563247999976, 35.399501918000055],
            [-80.830833247999976, 35.399446918000024],
            [-80.831026247999944, 35.399420919000079],
            [-80.831396247999976, 35.399392918000046],
            [-80.831870247999973, 35.399371918000043],
            [-80.832127247999949, 35.39936091900006],
            [-80.832456247999971, 35.399347918000046],
            [-80.832770246999985, 35.399340919000053],
            [-80.832826247999947, 35.399339919000056],
            [-80.833041248999962, 35.399341919000051],
            [-80.833207247999951, 35.399342918000059],
            [-80.834438248999959, 35.399363919000052],
            [-80.834691248999945, 35.39936091900006],
            [-80.834785247999946, 35.399352918000034],
            [-80.834948248999979, 35.399328918000037],
            [-80.835088248999966, 35.399301919000038],
            [-80.835198248999973, 35.399264919000075],
            [-80.835584248999965, 35.399148918000037],
            [-80.835904249999942, 35.399061919000076],
            [-80.836271248999935, 35.39895591800007],
            [-80.836443248999956, 35.398916918000054],
            [-80.836934248999967, 35.398790918000032],
            [-80.837606248999975, 35.398602918000051],
            [-80.837694248999981, 35.39857691800006],
            [-80.837802248999935, 35.398553918000061],
            [-80.835745248999956, 35.394827917000043],
            [-80.835853247999978, 35.394793917000072],
            [-80.835822248999989, 35.39474591700008],
            [-80.835678247999965, 35.394488918000036],
            [-80.835588248999954, 35.394304917000056],
            [-80.835505248999937, 35.394109917000037],
            [-80.835418248999986, 35.393888917000027],
            [-80.835356248999972, 35.393697917000054],
            [-80.835308248999979, 35.393524917000036],
            [-80.835264248999977, 35.393330917000071],
            [-80.835210248999942, 35.393006917000037],
            [-80.835177248999969, 35.392735917000039],
            [-80.83498224799996, 35.390832916000079],
            [-80.834856247999937, 35.389648917000045],
            [-80.834780247999959, 35.388921917000062],
            [-80.834395246999975, 35.385270916000024],
            [-80.83421724699997, 35.383565915000077],
            [-80.834132247999946, 35.382744916000036],
            [-80.833855247999963, 35.382726915000035],
            [-80.830422245999955, 35.382975915000031],
            [-80.829186245999949, 35.382619915000078],
            [-80.829051245999949, 35.383109915000034],
            [-80.827987245999964, 35.382702915000039],
            [-80.827469245999964, 35.382502914000042],
            [-80.824864244999958, 35.381501914000069],
            [-80.824807244999988, 35.381477915000062],
            [-80.824647244999937, 35.381089916000064],
            [-80.823587243999953, 35.380902915000036],
            [-80.822677243999976, 35.381303915000046],
            [-80.821949243999939, 35.380241914000067],
            [-80.82044024399994, 35.37961791500004],
            [-80.820651243999976, 35.37920091400008],
            [-80.820707242999958, 35.379063914000028],
            [-80.820765243999972, 35.378872914000056],
            [-80.820803242999943, 35.378719915000033],
            [-80.820825243999934, 35.378599914000063],
            [-80.820952242999965, 35.377691914000025],
            [-80.820985243999985, 35.377457914000047],
            [-80.82125724399998, 35.375657914000044],
            [-80.821311243999958, 35.375296914000046],
            [-80.821367242999941, 35.374976914000058],
            [-80.821413242999938, 35.374778913000057],
            [-80.821597242999985, 35.374239914000043],
            [-80.821786243999952, 35.37372991400008],
            [-80.822651242999939, 35.371220913000059],
            [-80.822971243999973, 35.370305913000038],
            [-80.823497243999952, 35.368723913000053],
            [-80.823544243999947, 35.368586912000069],
            [-80.823581243999968, 35.36847791200006],
            [-80.823084242999983, 35.368316913000058],
            [-80.822667243999945, 35.368186912000056],
            [-80.82238324299999, 35.368080912000039],
            [-80.82229724299998, 35.368043912000076],
            [-80.822049242999981, 35.367916912000055],
            [-80.821798242999989, 35.36776791300008],
            [-80.821636242999944, 35.367665912000064],
            [-80.821447242999966, 35.367531912000061],
            [-80.821356242999968, 35.367457913000067],
            [-80.821271241999966, 35.367378912000049],
            [-80.821205242999952, 35.367308912000055],
            [-80.821127242999978, 35.367209912000078],
            [-80.821045242999958, 35.36707791200007],
            [-80.820945243999972, 35.366886912000041],
            [-80.820480242999963, 35.365889912000057],
            [-80.820044242999984, 35.364992912000048],
            [-80.819964241999969, 35.364811911000061],
            [-80.819914242999971, 35.364670912000065],
            [-80.819809242999952, 35.364244911000071],
            [-80.819725241999947, 35.363875911000036],
            [-80.819679242999939, 35.363688911000054],
            [-80.819434241999943, 35.362555911000072],
            [-80.819363242999941, 35.362115912000036],
            [-80.81933724199996, 35.361828911000032],
            [-80.819332242999963, 35.361634910000078],
            [-80.81934524199994, 35.361422911000034],
            [-80.819376241999976, 35.361236911000049],
            [-80.819399241999974, 35.361132911000027],
            [-80.819502241999942, 35.360799911000072],
            [-80.820103241999959, 35.359102910000047],
            [-80.820282242999951, 35.358619911000062],
            [-80.821008242999937, 35.356584909000048],
            [-80.821633242999951, 35.354880910000077],
            [-80.822000242999934, 35.353847908000034],
            [-80.822092242999986, 35.35358990900005],
            [-80.82199024199997, 35.353575908000039],
            [-80.819878241999959, 35.353283909000027],
            [-80.818695240999944, 35.35312090900004],
            [-80.81843724099997, 35.353082909000079],
            [-80.818320240999981, 35.353065909000065],
            [-80.816618240999958, 35.352815909000071],
            [-80.816466239999954, 35.35280190900005],
            [-80.816077240999959, 35.352782909000041],
            [-80.815359240999953, 35.352799909000055],
            [-80.814659240999958, 35.352814908000028],
            [-80.814466239999945, 35.352815909000071],
            [-80.813530239999977, 35.352821909000056],
            [-80.813099239999985, 35.352823909000051],
            [-80.81292523999997, 35.352814909000074],
            [-80.812786238999934, 35.352798909000057],
            [-80.812755239999944, 35.352792909000073],
            [-80.812670239999989, 35.352777909000054],
            [-80.812554238999951, 35.352746909000075],
            [-80.812450239999976, 35.352706909000062],
            [-80.812290239999982, 35.352627909000034],
            [-80.81217423999999, 35.352558909000038],
            [-80.812024238999982, 35.352457910000055],
            [-80.812005238999973, 35.352444909000042],
            [-80.811414238999987, 35.351992910000035],
            [-80.809430237999948, 35.350458908000064],
            [-80.809256237999989, 35.350333909000028],
            [-80.809033238999973, 35.350206909000065],
            [-80.808720237999978, 35.350061909000033],
            [-80.808264237999936, 35.349880909000035],
            [-80.808008237999957, 35.349778909000065],
            [-80.807635236999943, 35.34961690800003],
            [-80.807354237999959, 35.349474909000037],
            [-80.807130237999957, 35.349346909000076],
            [-80.806880236999973, 35.34919190800008],
            [-80.806110237999974, 35.348682908000058],
            [-80.805530236999971, 35.348298908000061],
            [-80.804695235999986, 35.347726908000027],
            [-80.804401236999979, 35.347514908000051],
            [-80.804163235999965, 35.347328909000055],
            [-80.803519235999943, 35.346791908000057],
            [-80.803417236999962, 35.346711908000032],
            [-80.80327123699999, 35.346609909000051],
            [-80.803108236999947, 35.346517908000067],
            [-80.802934235999942, 35.346439908000036],
            [-80.802844235999942, 35.346406909000052],
            [-80.802659235999954, 35.346350908000034],
            [-80.802534236999975, 35.346331909000071],
            [-80.80240523599997, 35.346326908000037],
            [-80.802277236999942, 35.346338909000053],
            [-80.802200235999976, 35.34635890800007],
            [-80.802065236999965, 35.346406909000052],
            [-80.802000235999969, 35.346436909000033],
            [-80.801460235999969, 35.34673790800008],
            [-80.80129823599998, 35.346823909000079],
            [-80.80113223699999, 35.346905908000053],
            [-80.800644235999982, 35.347132908000049],
            [-80.800531236999973, 35.347202909000032],
            [-80.800431235999952, 35.347284908000063],
            [-80.800387235999949, 35.347329909000052],
            [-80.800313235999965, 35.347426909000035],
            [-80.800264235999975, 35.347540909000031],
            [-80.800247234999972, 35.347600908000061],
            [-80.800231234999956, 35.347687908000069],
            [-80.800200236999956, 35.348138909000056],
            [-80.800170235999985, 35.348313909000069],
            [-80.800128234999988, 35.348457909000047],
            [-80.800059235999981, 35.348616909000043],
            [-80.800019235999969, 35.348689909000029],
            [-80.799939234999954, 35.34881290900006],
            [-80.799800234999964, 35.348990909000065],
            [-80.799637234999977, 35.349157909000041],
            [-80.799522235999973, 35.349258909000071],
            [-80.799389234999978, 35.34936091000003],
            [-80.799097234999977, 35.34956390900004],
            [-80.799015234999956, 35.349614910000071],
            [-80.79890123499996, 35.349686910000059],
            [-80.798798234999936, 35.349742910000032],
            [-80.798584234999964, 35.349844909000069],
            [-80.798510234999981, 35.349873909000053],
            [-80.798395234999987, 35.349918909000053],
            [-80.79807623399995, 35.350027909000062],
            [-80.796915234999972, 35.350367910000045],
            [-80.796557233999977, 35.350476909000065],
            [-80.796413234999989, 35.350524910000047],
            [-80.796272233999957, 35.350577909000037],
            [-80.796138234999944, 35.350633909000067],
            [-80.796001234999949, 35.350709909000045],
            [-80.795924234999973, 35.350762910000071],
            [-80.795912234999946, 35.350689909000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795949233999977, 35.350527910000039],
            [-80.795959233999952, 35.350469909000026],
            [-80.795977234999953, 35.350282909000043],
            [-80.795974233999971, 35.350094909000063],
            [-80.795881233999978, 35.348936908000042],
            [-80.795811234999974, 35.348044909000066],
            [-80.79579023499997, 35.34778091000004],
            [-80.795768233999979, 35.347550908000073],
            [-80.795365232999984, 35.347463909000055],
            [-80.795313233999934, 35.347462909000058],
            [-80.795079233999957, 35.347531909000054],
            [-80.794742232999965, 35.347648908000053],
            [-80.794396232999986, 35.347785909000038],
            [-80.794194233999974, 35.347860909000076],
            [-80.793805232999944, 35.347961909000048],
            [-80.793681233999962, 35.348007909000046],
            [-80.793561233999981, 35.348052909000046],
            [-80.793481232999966, 35.348111909000067],
            [-80.793427233999978, 35.348128909000025],
            [-80.793330233999939, 35.348124909000035],
            [-80.793259233999947, 35.348141908000059],
            [-80.793205232999981, 35.348192908000044],
            [-80.793177233999984, 35.348246909000068],
            [-80.793156232999934, 35.34830290900004],
            [-80.793099233999953, 35.348358909000069],
            [-80.792876232999959, 35.348461909000036],
            [-80.79278523399995, 35.348503909000044],
            [-80.792728232999934, 35.348552909000034],
            [-80.792639233999978, 35.348686909000037],
            [-80.792618232999985, 35.348720909000065],
            [-80.792545232999942, 35.348761909000075],
            [-80.792410232999941, 35.348776909000037],
            [-80.792336233999947, 35.348825909000027],
            [-80.792088233999948, 35.349014909000061],
            [-80.792037232999974, 35.349057909000067],
            [-80.792030232999934, 35.349153909000051],
            [-80.792041232999964, 35.349227909000035],
            [-80.792027232999942, 35.349301910000065],
            [-80.791905233999955, 35.34936490900003],
            [-80.791796233999946, 35.349421909000057],
            [-80.791677233999962, 35.349436910000065],
            [-80.791628232999983, 35.349408909000033],
            [-80.791300232999959, 35.349251909000031],
            [-80.790929232999986, 35.349220910000042],
            [-80.790785232999951, 35.34920591000008],
            [-80.790728232999982, 35.349206909000031],
            [-80.790683232999982, 35.349202909000041],
            [-80.790602232999959, 35.349200910000036],
            [-80.79043623299998, 35.349163909000026],
            [-80.790316232999942, 35.349165910000067],
            [-80.790245231999961, 35.349170909000065],
            [-80.790079233999961, 35.349142910000069],
            [-80.789986232999979, 35.34912791000005],
            [-80.789866231999952, 35.349129909000055],
            [-80.78970923299994, 35.349163909000026],
            [-80.789579232999984, 35.349173909000058],
            [-80.789253232999954, 35.34921391000006],
            [-80.789066231999982, 35.349224910000032],
            [-80.789050232999955, 35.349226909000038],
            [-80.788833231999945, 35.349177909000048],
            [-80.788738231999957, 35.349150909000059],
            [-80.788629232999938, 35.349138910000079],
            [-80.788576231999969, 35.349119909000024],
            [-80.788442231999966, 35.349136910000027],
            [-80.78839923299995, 35.34917290900006],
            [-80.788326231999974, 35.349220909000053],
            [-80.788286231999962, 35.34927490900003],
            [-80.788262231999965, 35.349285909000059],
            [-80.78817023199997, 35.349309909000056],
            [-80.788124231999973, 35.349320909000028],
            [-80.78800223199994, 35.349345909000078],
            [-80.787899231999972, 35.349359910000032],
            [-80.787821231999942, 35.349380910000036],
            [-80.787651231999973, 35.349416910000059],
            [-80.787347231999945, 35.349421909000057],
            [-80.787111231999972, 35.34943891000006],
            [-80.78705523299999, 35.349437910000063],
            [-80.787052231999951, 35.349416910000059],
            [-80.786997231999976, 35.349391909000076],
            [-80.786909230999981, 35.349271909000038],
            [-80.786837231999982, 35.34921791000005],
            [-80.786804231999952, 35.349174909000055],
            [-80.786783231999948, 35.349120910000067],
            [-80.78676823099994, 35.34909490900003],
            [-80.786734230999969, 35.349056909000069],
            [-80.78669223199995, 35.349030909000078],
            [-80.786635230999934, 35.349001910000027],
            [-80.786592230999986, 35.348987910000062],
            [-80.786466231999952, 35.348971910000046],
            [-80.786432231999981, 35.348958909000032],
            [-80.786395231999961, 35.348925909000059],
            [-80.786330230999965, 35.348731910000026],
            [-80.786308230999964, 35.348686909000037],
            [-80.786285230999965, 35.348653909000063],
            [-80.786243230999958, 35.348606909000068],
            [-80.786187230999985, 35.348528909000038],
            [-80.786167230999979, 35.348489910000069],
            [-80.786155231999942, 35.348431909000055],
            [-80.786173230999964, 35.348308910000071],
            [-80.786185230999934, 35.34820091000006],
            [-80.786199231999944, 35.348174909000079],
            [-80.786276230999988, 35.348138909000056],
            [-80.786294231999989, 35.348125909000032],
            [-80.786330231999955, 35.348079909000035],
            [-80.78633623099995, 35.348029910000037],
            [-80.786325230999978, 35.347981909000055],
            [-80.786250231999986, 35.347914909000053],
            [-80.786112231999937, 35.347856910000075],
            [-80.786039231999951, 35.347809909000034],
            [-80.785922231999962, 35.347707909000064],
            [-80.785845230999939, 35.347634909000078],
            [-80.785823230999938, 35.347593909000068],
            [-80.785764230999973, 35.34754790900007],
            [-80.785726231999945, 35.347522909000077],
            [-80.785653231999959, 35.347477909000077],
            [-80.785610230999964, 35.347471908000045],
            [-80.785531230999936, 35.347423909000042],
            [-80.785412230999952, 35.347346909000066],
            [-80.785363230999963, 35.347309909000046],
            [-80.785263231999977, 35.347208909000074],
            [-80.785092230999965, 35.347076909000066],
            [-80.785050230999957, 35.347024909000027],
            [-80.785024230999966, 35.346980909000024],
            [-80.785023230999968, 35.346957909000025],
            [-80.78502623199995, 35.34693991000006],
            [-80.785057230999939, 35.34689890900006],
            [-80.785093230999962, 35.346860910000032],
            [-80.785114230999966, 35.34682790800008],
            [-80.78514323099995, 35.346801909000078],
            [-80.785166230999948, 35.346793909000041],
            [-80.785246230999974, 35.346818909000035],
            [-80.785278231999939, 35.346858909000048],
            [-80.78530723099999, 35.346888909000029],
            [-80.785359231999962, 35.346918909000067],
            [-80.78540523099997, 35.346939909000071],
            [-80.785457230999953, 35.346940909000068],
            [-80.785572231999936, 35.346921909000059],
            [-80.785632230999965, 35.346892909000076],
            [-80.785678230999963, 35.346841909000034],
            [-80.785736230999987, 35.346786909000059],
            [-80.785825231999979, 35.346744909000051],
            [-80.785893230999989, 35.346698909000054],
            [-80.78596823099997, 35.346658909000041],
            [-80.786011230999975, 35.346623909000073],
            [-80.786160231999986, 35.34645690900004],
            [-80.786337230999948, 35.346312909000062],
            [-80.786360231999936, 35.346279909000032],
            [-80.786393231999966, 35.346238909000078],
            [-80.786450230999947, 35.346171909000077],
            [-80.786453230999939, 35.346123909000028],
            [-80.786435231999974, 35.346099909000031],
            [-80.786409231999983, 35.346084909000069],
            [-80.786348231999966, 35.346041909000064],
            [-80.786305231999961, 35.346037909000074],
            [-80.786277231999975, 35.346036908000031],
            [-80.786177231999943, 35.346012908000034],
            [-80.786146230999975, 35.346009909000031],
            [-80.786069231999988, 35.346000909000054],
            [-80.786021231999939, 35.34598790900003],
            [-80.78592723099996, 35.345967909000024],
            [-80.785843230999944, 35.345936909000045],
            [-80.785817230999953, 35.345936908000056],
            [-80.78576523199996, 35.345912908000059],
            [-80.785698230999969, 35.345871909000039],
            [-80.785618230999944, 35.345820909000054],
            [-80.78558923099996, 35.34584890900004],
            [-80.785514231999969, 35.345919908000042],
            [-80.785382229999982, 35.346015909000073],
            [-80.785310230999983, 35.346058909000078],
            [-80.785199230999979, 35.346115909000048],
            [-80.785044230999972, 35.346174909000069],
            [-80.783826230999978, 35.346490909000067],
            [-80.783532230999981, 35.346565909000049],
            [-80.783137230999955, 35.346672908000073],
            [-80.782472230999986, 35.346840909000036],
            [-80.782352229999958, 35.346886909000034],
            [-80.782273229999987, 35.346925909000049],
            [-80.782146229999967, 35.347002909000025],
            [-80.78203222999997, 35.347093910000069],
            [-80.781965229999969, 35.347158909000029],
            [-80.781877229999964, 35.347265910000033],
            [-80.781830230999958, 35.347340909000025],
            [-80.781778229999986, 35.347444910000036],
            [-80.781755228999941, 35.347509909000053],
            [-80.781726229999947, 35.34764190900006],
            [-80.78171722999997, 35.347774910000055],
            [-80.781743229999961, 35.348070910000047],
            [-80.781740229999969, 35.348199910000062],
            [-80.781730230999983, 35.348263910000071],
            [-80.78170922999999, 35.34835190900003],
            [-80.781667230999972, 35.348468909000076],
            [-80.781605229999968, 35.348579910000069],
            [-80.781567229999951, 35.348631909000062],
            [-80.781524229999945, 35.348681909000049],
            [-80.78143223099994, 35.348771910000039],
            [-80.781396229999984, 35.348801909000031],
            [-80.781276229999946, 35.348884910000038],
            [-80.78121122999994, 35.348920909000071],
            [-80.781143229999941, 35.348952909000047],
            [-80.780984229999945, 35.349008910000066],
            [-80.780830229999935, 35.349042909000048],
            [-80.780726228999981, 35.349054909000074],
            [-80.780750228999977, 35.349314909000043],
            [-80.780752229999962, 35.349426910000034],
            [-80.780726228999981, 35.349653910000029],
            [-80.780659230999959, 35.349889910000059],
            [-80.780604229999938, 35.350016910000079],
            [-80.780545228999983, 35.350122910000039],
            [-80.780432229999974, 35.350285910000025],
            [-80.780334228999948, 35.350403910000068],
            [-80.780286229999945, 35.350453910000056],
            [-80.78004722999998, 35.35065891000005],
            [-80.779662229999985, 35.350975910000045],
            [-80.778906229999961, 35.351591910000025],
            [-80.778793228999973, 35.35168390900003],
            [-80.778473228999985, 35.352039911000077],
            [-80.779326228999935, 35.352551910000045],
            [-80.779735229999972, 35.352796910000052],
            [-80.779979228999935, 35.35294691100006],
            [-80.780123229999958, 35.353041910000059],
            [-80.780252229999974, 35.353140911000025],
            [-80.780310229999941, 35.353193911000062],
            [-80.780414229999963, 35.353309911000053],
            [-80.780457229999968, 35.35337091100007],
            [-80.780496229999983, 35.353435911000076],
            [-80.780559229999938, 35.353570911000077],
            [-80.780811230999973, 35.354363911000064],
            [-80.781132229999969, 35.355362911000043],
            [-80.780525229999967, 35.355518911000047],
            [-80.780020228999945, 35.355639911000026],
            [-80.779747229999941, 35.355694911000057],
            [-80.779208229999938, 35.355766911000046],
            [-80.77907222999994, 35.355785911000055],
            [-80.778780229999938, 35.355854912000041],
            [-80.778653228999985, 35.35591491100007],
            [-80.778530229999944, 35.355980911000074],
            [-80.778436229999954, 35.355919911000058],
            [-80.778358229999981, 35.355882911000037],
            [-80.778247228999987, 35.35583691000005],
            [-80.778114228999982, 35.355802911000069],
            [-80.778012229999945, 35.355781911000065],
            [-80.777915228999973, 35.355771911000033],
            [-80.777805228999966, 35.35577191200008],
            [-80.776886228999956, 35.355741911000052],
            [-80.776779228999942, 35.35573491100007],
            [-80.776598228999944, 35.355728911000028],
            [-80.776287228999934, 35.355709911000076],
            [-80.77615222899999, 35.355696912000042],
            [-80.776018228999988, 35.355664911000076],
            [-80.775921229999938, 35.355631911000046],
            [-80.775818227999935, 35.355589911000038],
            [-80.775584228999946, 35.355476911000039],
            [-80.775428228999942, 35.355682911000031],
            [-80.775363227999947, 35.355757912000058],
            [-80.775271227999951, 35.355843911000079],
            [-80.775162227999942, 35.355931912000074],
            [-80.775071228999934, 35.355989912000041],
            [-80.774928228999954, 35.35605791100005],
            [-80.774363226999981, 35.356238912000038],
            [-80.773851228999945, 35.356408912000063],
            [-80.77362822799995, 35.356475912000064],
            [-80.773437227999977, 35.356527911000057],
            [-80.772983227999987, 35.356627912000079],
            [-80.772871227999985, 35.356659911000065],
            [-80.772721227999966, 35.356722911000077],
            [-80.772614227999952, 35.35679691100006],
            [-80.772542227999963, 35.356852911000033],
            [-80.772467227999982, 35.356917912000029],
            [-80.772381227999972, 35.356999912000049],
            [-80.772214227999939, 35.357337912000048],
            [-80.772014227999989, 35.357743912000046],
            [-80.771708227999966, 35.357743912000046],
            [-80.771611227999983, 35.35772391200004],
            [-80.771543227999985, 35.357720912000048],
            [-80.771355227999948, 35.357726912000032],
            [-80.771312227999942, 35.357734912000069],
            [-80.771209226999986, 35.357742912000049],
            [-80.771131226999955, 35.357714911000073],
            [-80.770820226999945, 35.357631911000055],
            [-80.770316227999956, 35.357484912000075],
            [-80.770228226999961, 35.357432912000036],
            [-80.770173226999987, 35.357414912000024],
            [-80.770094226999959, 35.357409912000037],
            [-80.769884226999977, 35.357348912000077],
            [-80.769669226999952, 35.35726991100006],
            [-80.769544227999972, 35.357215912000072],
            [-80.769444226999951, 35.357217912000067],
            [-80.769360226999936, 35.357193911000024],
            [-80.769289227999934, 35.35718791100004],
            [-80.769198226999947, 35.357192911000027],
            [-80.769110226999942, 35.357228912000039],
            [-80.769026226999983, 35.357292912000048],
            [-80.768978226999934, 35.357319911000047],
            [-80.768900226999961, 35.357324912000024],
            [-80.768833226999959, 35.357300911000038],
            [-80.768754226999988, 35.357238912000071],
            [-80.768700226999954, 35.35720191200005],
            [-80.76867522699996, 35.357189912000024],
            [-80.768627226999968, 35.357171912000069],
            [-80.768477225999959, 35.35715691200005],
            [-80.768375225999989, 35.357162912000035],
            [-80.768340226999953, 35.357182911000052],
            [-80.76821722699998, 35.357192912000073],
            [-80.768163225999956, 35.357221911000067],
            [-80.768112225999971, 35.357264911000073],
            [-80.76805322599995, 35.357295912000041],
            [-80.767950225999982, 35.357323912000027],
            [-80.767849225999953, 35.357378912000058],
            [-80.767762226999935, 35.357413912000027],
            [-80.767722226999979, 35.357443912000065],
            [-80.767628225999943, 35.357467912000061],
            [-80.767493225999942, 35.357510912000066],
            [-80.767402225999945, 35.357548912000027],
            [-80.76738022699999, 35.357558912000059],
            [-80.767304225999965, 35.357595912000079],
            [-80.767113225999935, 35.357642912000074],
            [-80.767048226999975, 35.357675912000047],
            [-80.766941225999972, 35.357696912000051],
            [-80.766830226999957, 35.35772791200003],
            [-80.766719225999964, 35.357753911000032],
            [-80.766628225999966, 35.357779912000069],
            [-80.766554225999982, 35.357807911000066],
            [-80.766478226999936, 35.357856912000045],
            [-80.766418226999974, 35.357886911000037],
            [-80.76636822599994, 35.35789591200006],
            [-80.766195225999979, 35.357910912000079],
            [-80.765942224999947, 35.357911912000077],
            [-80.765792225999974, 35.35790391200004],
            [-80.765244225999936, 35.357900912000048],
            [-80.765122224999971, 35.357898912000053],
            [-80.764939225999967, 35.357885912000029],
            [-80.764764225999954, 35.357877912000049],
            [-80.764663224999936, 35.357878913000036],
            [-80.764642225999978, 35.35784691200007],
            [-80.764595225999983, 35.357799912000075],
            [-80.764570224999943, 35.357763912000053],
            [-80.76437122599998, 35.35754391200004],
            [-80.764146224999934, 35.357371912000076],
            [-80.764119224999945, 35.357319912000037],
            [-80.764126224999984, 35.357122912000079],
            [-80.764110224999968, 35.357065912000053],
            [-80.764061225999967, 35.357022913000037],
            [-80.764013224999985, 35.357020912000053],
            [-80.763924225999972, 35.357056912000075],
            [-80.763852224999937, 35.357065912000053],
            [-80.763590225999963, 35.356995912000059],
            [-80.763529224999957, 35.356974912000055],
            [-80.763495224999986, 35.356951911000067],
            [-80.763427224999987, 35.356920912000078],
            [-80.763364223999986, 35.356917912000029],
            [-80.763264224999944, 35.356955912000046],
            [-80.763046224999982, 35.357016912000063],
            [-80.762963224999964, 35.35702991200003],
            [-80.762824223999985, 35.357036912000069],
            [-80.762706223999942, 35.357028912000033],
            [-80.762598224999977, 35.357031912000025],
            [-80.762408224999945, 35.356995912000059],
            [-80.762142224999934, 35.356984913000076],
            [-80.761959223999952, 35.356960912000034],
            [-80.761931224999955, 35.356952912000054],
            [-80.76180922399999, 35.356866912000044],
            [-80.761756224999942, 35.356781912000031],
            [-80.761723224999969, 35.356701912000062],
            [-80.761638224999956, 35.356609912000067],
            [-80.761603223999941, 35.35655691200003],
            [-80.761580223999943, 35.356496912000068],
            [-80.761541224999974, 35.356308912000031],
            [-80.76151222499999, 35.356242912000027],
            [-80.761402223999937, 35.356166912000049],
            [-80.761369223999964, 35.356114912000066],
            [-80.761337223999988, 35.356017912000027],
            [-80.761298223999972, 35.355955911000024],
            [-80.761166223999965, 35.355834913000024],
            [-80.761054223999963, 35.355716912000048],
            [-80.760973224999987, 35.35558891200003],
            [-80.760881224999935, 35.355510912000057],
            [-80.760714222999979, 35.355399912000053],
            [-80.760482223999986, 35.355223912000042],
            [-80.760350223999978, 35.355145911000079],
            [-80.760193223999977, 35.35504091200005],
            [-80.760144223999987, 35.354996912000047],
            [-80.759973223999964, 35.354719912000064],
            [-80.759941223999988, 35.354604911000024],
            [-80.759929223999961, 35.354543911000064],
            [-80.759893222999949, 35.354456911000057],
            [-80.759801223999943, 35.354311912000071],
            [-80.759702223999966, 35.354212912000037],
            [-80.759653223999976, 35.354174912000076],
            [-80.759508223999944, 35.35409891200004],
            [-80.759420223999939, 35.354042911000079],
            [-80.759374223999941, 35.353999912000063],
            [-80.759308222999948, 35.353984911000055],
            [-80.759211222999966, 35.353995912000073],
            [-80.75912722399994, 35.353998912000066],
            [-80.759015223999938, 35.353983912000047],
            [-80.758641223999973, 35.353990912000029],
            [-80.758576223999967, 35.353984911000055],
            [-80.758514223999953, 35.353973911000026],
            [-80.758400222999967, 35.353920911000046],
            [-80.758264223999959, 35.353829911000048],
            [-80.758124223999971, 35.353766912000026],
            [-80.757989222999981, 35.35369391100005],
            [-80.757935222999947, 35.353661911000074],
            [-80.757722223999963, 35.353592912000067],
            [-80.756895222999958, 35.355332911000062],
            [-80.756975222999984, 35.355382912000039],
            [-80.757042223999974, 35.35544591200005],
            [-80.757111222999981, 35.355528911000079],
            [-80.757148222999945, 35.355591912000079],
            [-80.757189222999955, 35.355699913000024],
            [-80.757200222999984, 35.355742912000039],
            [-80.757221222999988, 35.355999912000073],
            [-80.757227222999973, 35.356234912000048],
            [-80.757230222999965, 35.356904912000061],
            [-80.757251223999958, 35.357193912000071],
            [-80.757315222999978, 35.357582912000055],
            [-80.757344222999961, 35.35771191200007],
            [-80.757398222999939, 35.357876912000052],
            [-80.75744522399998, 35.357977912000024],
            [-80.757504222999955, 35.358072912000068],
            [-80.75755122399994, 35.358134913000072],
            [-80.75791822399998, 35.358618913000043],
            [-80.757988222999984, 35.358722913000065],
            [-80.758055224999964, 35.358840912000062],
            [-80.75809822399998, 35.358950912000068],
            [-80.758136223999941, 35.359106913000062],
            [-80.758272224999985, 35.359861913000032],
            [-80.758321223999985, 35.360067914000069],
            [-80.758388224999976, 35.360266913000032],
            [-80.757704223999951, 35.360475913000073],
            [-80.757533222999939, 35.360520912000027],
            [-80.757423223999979, 35.360537912000041],
            [-80.757268222999983, 35.360549913000057],
            [-80.756832223999936, 35.360563913000078],
            [-80.756649223999943, 35.360569913000063],
            [-80.756503222999982, 35.360583913000028],
            [-80.75640322299995, 35.360609913000076],
            [-80.756330222999964, 35.360633914000061],
            [-80.75626822299995, 35.360658913000066],
            [-80.756222222999952, 35.360682913000062],
            [-80.75612122299998, 35.360745913000073],
            [-80.756041222999954, 35.360804913000038],
            [-80.75597522299995, 35.360866913000052],
            [-80.755718223999963, 35.360724914000059],
            [-80.755590222999956, 35.360666913000045],
            [-80.755456222999953, 35.360628913000028],
            [-80.755302222999944, 35.360604913000032],
            [-80.75463922299997, 35.360549913000057],
            [-80.754525222999973, 35.360543913000072],
            [-80.754520222999986, 35.360687914000039],
            [-80.754445222999948, 35.361927914000034],
            [-80.754373222999959, 35.363388914000041],
            [-80.75434622299997, 35.363337913000066],
            [-80.754311222999945, 35.363236914000026],
            [-80.754278222999972, 35.363192914000024],
            [-80.754163222999978, 35.363087914000062],
            [-80.75413322299994, 35.362908914000059],
            [-80.754118222999978, 35.36275891400004],
            [-80.754101222999964, 35.362592913000071],
            [-80.754033222999965, 35.36249291300004],
            [-80.753938222999977, 35.362309914000036],
            [-80.753869222999981, 35.362146913000061],
            [-80.753719221999972, 35.361930913000037],
            [-80.75366022299994, 35.361871913000073],
            [-80.753497221999964, 35.361757913000076],
            [-80.753433222999945, 35.361678913000048],
            [-80.753361221999967, 35.361527914000078],
            [-80.753212222999935, 35.361037913000075],
            [-80.753172222999979, 35.360888913000053],
            [-80.753123222999989, 35.360854913000026],
            [-80.753080221999937, 35.360824913000044],
            [-80.75297722199997, 35.360790913000073],
            [-80.752928222999969, 35.360680913000067],
            [-80.75288722199997, 35.360642913000049],
            [-80.752749222999967, 35.360674912000036],
            [-80.752716221999947, 35.360656913000071],
            [-80.752649221999945, 35.360601913000039],
            [-80.752634222999973, 35.360547914000051],
            [-80.752625221999949, 35.360464913000044],
            [-80.752577222999946, 35.360438913000053],
            [-80.752550221999968, 35.360393913000053],
            [-80.752559221999945, 35.360274913000069],
            [-80.75245522299997, 35.360198913000033],
            [-80.752425221999943, 35.360136913000076],
            [-80.752456221999978, 35.360082913000042],
            [-80.752433221999979, 35.360031913000057],
            [-80.752407221999988, 35.360008913000058],
            [-80.752330221999955, 35.359967913000048],
            [-80.752256221999971, 35.359939913000062],
            [-80.752234220999981, 35.359906913000032],
            [-80.752207221999981, 35.359841913000025],
            [-80.75214822199996, 35.359791913000038],
            [-80.752067221999937, 35.359771913000031],
            [-80.752004221999982, 35.359724912000047],
            [-80.751958221999985, 35.359618913000077],
            [-80.751929221999944, 35.359582913000054],
            [-80.751862221999943, 35.359551913000075],
            [-80.751775222999981, 35.359537913000054],
            [-80.751662221999936, 35.359554913000068],
            [-80.751459220999948, 35.359601912000073],
            [-80.751272220999965, 35.35969691300005],
            [-80.751214220999941, 35.35970091300004],
            [-80.751120220999951, 35.359725913000034],
            [-80.751074221999943, 35.359798913000077],
            [-80.751021220999974, 35.359864913000024],
            [-80.750938221999945, 35.359910914000068],
            [-80.750830221999934, 35.359941913000057],
            [-80.75079722199996, 35.359966913000051],
            [-80.750709220999966, 35.360098913000058],
            [-80.750652220999939, 35.360153913000033],
            [-80.750587220999989, 35.360186913000064],
            [-80.750515220999944, 35.360211913000057],
            [-80.750479220999978, 35.36020691300007],
            [-80.750438220999968, 35.360179913000024],
            [-80.750430220999988, 35.360144913000056],
            [-80.750407221999978, 35.360102913000048],
            [-80.750378220999949, 35.360076913000057],
            [-80.750334220999946, 35.360068913000077],
            [-80.750254221999967, 35.360087913000029],
            [-80.75016322099998, 35.360094913000069],
            [-80.750109220999946, 35.360107913000036],
            [-80.750080220999962, 35.360137913000074],
            [-80.750067220999938, 35.360188913000059],
            [-80.750042221999934, 35.360221913000032],
            [-80.749977220999938, 35.36022291300003],
            [-80.749806220999972, 35.360087914000076],
            [-80.749790220999955, 35.360068912000031],
            [-80.749765221999951, 35.360037913000042],
            [-80.749756220999984, 35.359942913000054],
            [-80.749773220999941, 35.359873913000058],
            [-80.749848221999969, 35.359812913000042],
            [-80.749954220999939, 35.359686913000075],
            [-80.749952220999944, 35.359587913000041],
            [-80.749917220999976, 35.359483913000076],
            [-80.749508221999974, 35.359537914000043],
            [-80.749271220999958, 35.360198913000033],
            [-80.749227219999966, 35.360320913000066],
            [-80.749097220999943, 35.360666913000045],
            [-80.748972220999974, 35.361028913000041],
            [-80.748891220999951, 35.361332914000059],
            [-80.748516220999988, 35.36313791300006],
            [-80.748481221999953, 35.363323915000024],
            [-80.74846421999996, 35.363438914000028],
            [-80.748439220999956, 35.36371491500006],
            [-80.74844122099995, 35.363876914000059],
            [-80.748453220999977, 35.364010913000072],
            [-80.748483219999969, 35.364101914000059],
            [-80.748509220999949, 35.364146914000059],
            [-80.748544220999975, 35.364204914000027],
            [-80.74878322099994, 35.364533915000038],
            [-80.749163221999936, 35.365033914000037],
            [-80.749257220999937, 35.365147914000033],
            [-80.749353220999978, 35.365251915000044],
            [-80.749437221999983, 35.365329914000029],
            [-80.74952222099995, 35.365400915000066],
            [-80.749615220999942, 35.365471914000068],
            [-80.749773221999988, 35.365582914000072],
            [-80.750467221999941, 35.366032915000062],
            [-80.750822221999954, 35.366257914000073],
            [-80.751324221999937, 35.366585914000041],
            [-80.751570220999952, 35.366760914000054],
            [-80.751754222999978, 35.366902914000036],
            [-80.751951221999946, 35.367064914000025],
            [-80.753261222999981, 35.368192915000066],
            [-80.753441221999935, 35.368350915000065],
            [-80.753610222999953, 35.368499915000029],
            [-80.753765223999949, 35.368644915000061],
            [-80.753924222999956, 35.368809914000053],
            [-80.754036222999957, 35.368941914000061],
            [-80.754104222999956, 35.369028914000069],
            [-80.754661222999971, 35.369731915000045],
            [-80.754806222999946, 35.36989991400003],
            [-80.754820222999967, 35.369914915000038],
            [-80.754943222999941, 35.37004891600003],
            [-80.755072223999946, 35.370178915000054],
            [-80.755259222999939, 35.370342915000037],
            [-80.755346223999936, 35.370418915000073],
            [-80.755503223999938, 35.370547915000031],
            [-80.755752223999934, 35.370739915000058],
            [-80.755916223999975, 35.370850915000062],
            [-80.756042223999941, 35.370922915000051],
            [-80.756167222999977, 35.370981915000073],
            [-80.756448223999939, 35.371092915000077],
            [-80.756769224999971, 35.371210915000063],
            [-80.757642224999984, 35.37149691500008],
            [-80.757912224999984, 35.371590916000059],
            [-80.758124223999971, 35.371671916000025],
            [-80.758287224999947, 35.371744915000079],
            [-80.758408224999982, 35.371803915000044],
            [-80.758753224999964, 35.371988915000031],
            [-80.759122224999942, 35.372199915000067],
            [-80.760663224999973, 35.373083915000052],
            [-80.760788225999988, 35.373154915000043],
            [-80.761050224999963, 35.373292916000025],
            [-80.761253225999951, 35.373385915000028],
            [-80.761498224999968, 35.37348591500006],
            [-80.761660225999947, 35.373536916000035],
            [-80.761860225999953, 35.373586915000033],
            [-80.76206022599996, 35.373625915000048],
            [-80.76228522599996, 35.373654916000078],
            [-80.762413225999978, 35.373664915000063],
            [-80.762701224999944, 35.373676916000079],
            [-80.763549225999952, 35.373701915000026],
            [-80.76381622599996, 35.373716916000035],
            [-80.763809226999967, 35.373917916000039],
            [-80.76376722599997, 35.374666916000024],
            [-80.763745226999959, 35.375284916000055],
            [-80.763745225999969, 35.375447916000041],
            [-80.763751225999954, 35.375572916000067],
            [-80.76376722599997, 35.375679916000024],
            [-80.763809225999978, 35.37580891500005],
            [-80.763851225999986, 35.375893916000052],
            [-80.76391422599994, 35.375989916000037],
            [-80.763960225999938, 35.376044916000069],
            [-80.764049225999941, 35.376135916000067],
            [-80.76432622599998, 35.376371917000029],
            [-80.764437226999974, 35.376472915000079],
            [-80.764577225999972, 35.376613916000053],
            [-80.764893226999959, 35.376974916000052],
            [-80.765012226999943, 35.377129916000058],
            [-80.765062225999941, 35.377215916000068],
            [-80.765101226999946, 35.377325917000064],
            [-80.765108225999938, 35.377367916000026],
            [-80.765105226999935, 35.37745491700008],
            [-80.765091226999971, 35.377509916000065],
            [-80.765068225999983, 35.377571917000068],
            [-80.764712225999972, 35.378458916000056],
            [-80.764426225999955, 35.379101917000071],
            [-80.764205226999934, 35.379921916000058],
            [-80.764516226999945, 35.380110917000025],
            [-80.765045226999973, 35.38040791700007],
            [-80.765262226999937, 35.380521917000067],
            [-80.76538422699997, 35.380577917000039],
            [-80.765542227999958, 35.380639918000043],
            [-80.766142226999989, 35.380764916000032],
            [-80.76722222799998, 35.38096691700008],
            [-80.767465226999946, 35.381007917000034],
            [-80.767294227999969, 35.381187917000034],
            [-80.767138227999965, 35.381352917000072],
            [-80.767141227999957, 35.381392917000028],
            [-80.767172227999936, 35.381468917000063],
            [-80.767196227999989, 35.38148491700008],
            [-80.767226226999981, 35.381484916000034],
            [-80.767242227999986, 35.381470917000058],
            [-80.767261227999938, 35.381444917000067],
            [-80.767282227999942, 35.381432917000041],
            [-80.76731622799997, 35.381432917000041],
            [-80.767365226999971, 35.381440917000077],
            [-80.767450227999973, 35.381485916000031],
            [-80.767540226999984, 35.381503917000032],
            [-80.767589227999963, 35.38151891800004],
            [-80.767651227999977, 35.381531918000064],
            [-80.767699227999969, 35.381551917000024],
            [-80.767741226999988, 35.381580917000065],
            [-80.767769227999963, 35.381617918000074],
            [-80.767799226999955, 35.38167691700005],
            [-80.767805227999986, 35.381719917000055],
            [-80.767822227999943, 35.381761917000063],
            [-80.767850227999986, 35.381806917000063],
            [-80.767887228999939, 35.381843917000026],
            [-80.768015227999967, 35.381934917000024],
            [-80.76807122799994, 35.381956917000025],
            [-80.768138227999941, 35.381964918000051],
            [-80.768183227999941, 35.381976917000031],
            [-80.768225227999949, 35.381997917000035],
            [-80.76824722799995, 35.382015917000047],
            [-80.768270227999949, 35.382045918000074],
            [-80.768312227999957, 35.382138917000077],
            [-80.76833722799995, 35.382152917000042],
            [-80.768391227999984, 35.382171917000051],
            [-80.768413227999986, 35.382184917000075],
            [-80.768434226999943, 35.382210917000066],
            [-80.768455227999937, 35.382242917000042],
            [-80.768501227999934, 35.382320917000072],
            [-80.768624228999954, 35.38241991700005],
            [-80.768710227999975, 35.382480917000066],
            [-80.768774228999973, 35.382515917000035],
            [-80.768828227999961, 35.382555917000047],
            [-80.765032226999949, 35.38225791800005],
            [-80.764714226999956, 35.382243917000039],
            [-80.764380225999957, 35.38221291800005],
            [-80.76418722599999, 35.382200918000024],
            [-80.762911226999961, 35.382090917000028],
            [-80.762538225999947, 35.382063917000039],
            [-80.762475225999935, 35.382058917000052],
            [-80.76213422699999, 35.382029917000068],
            [-80.758996223999986, 35.381910917000027],
            [-80.75799322599994, 35.381873917000064],
            [-80.757786223999972, 35.381827917000066],
            [-80.756790224999975, 35.381607918000043],
            [-80.756204224999976, 35.381560917000058],
            [-80.755313223999963, 35.381490918000054],
            [-80.755083223999975, 35.381472917000053],
            [-80.754348223999955, 35.381414917000029],
            [-80.754133223999986, 35.381955917000028],
            [-80.753949223999939, 35.382437918000051],
            [-80.753920223999955, 35.382639918000052],
            [-80.753919223999958, 35.382720918000075],
            [-80.753928223999935, 35.382809918000078],
            [-80.753954222999937, 35.382957918000045],
            [-80.75398622299997, 35.383062917000075],
            [-80.75402322399998, 35.383160917000055],
            [-80.754077224999946, 35.383269918000053],
            [-80.754236222999964, 35.383474918000047],
            [-80.754280223999956, 35.383525918000032],
            [-80.754214223999952, 35.383592917000044],
            [-80.754183222999984, 35.38361691800003],
            [-80.754115223999975, 35.38364191800008],
            [-80.753983223999967, 35.383670918000064],
            [-80.753815223999936, 35.383679918000041],
            [-80.753677223999944, 35.383676918000049],
            [-80.753574222999987, 35.383630918000051],
            [-80.75350122399999, 35.383574918000079],
            [-80.753466223999965, 35.383540918000051],
            [-80.75337022399998, 35.383369919000074],
            [-80.753305222999984, 35.383291918000054],
            [-80.753261222999981, 35.38322991800004],
            [-80.753146222999987, 35.383164917000045],
            [-80.753111223999952, 35.383140918000038],
            [-80.753080223999973, 35.38309891800003],
            [-80.753008222999938, 35.383028918000036],
            [-80.75286022399996, 35.382932918000051],
            [-80.752699222999979, 35.38285391800008],
            [-80.75259422299996, 35.382818918000055],
            [-80.752530223999941, 35.382782918000032],
            [-80.752487222999946, 35.382773917000065],
            [-80.752424221999945, 35.382770917000073],
            [-80.752334222999934, 35.382807917000036],
            [-80.752217222999946, 35.382843918000049],
            [-80.752053222999962, 35.382880918000069],
            [-80.751977222999983, 35.382888917000059],
            [-80.751883222999936, 35.382931918000054],
            [-80.751812223999934, 35.382945917000029],
            [-80.751771223999981, 35.382960918000038],
            [-80.751687222999976, 35.382978919000038],
            [-80.751631222999947, 35.383010918000025],
            [-80.75156922299999, 35.383038918000068],
            [-80.751523223999982, 35.383041917000071],
            [-80.751452222999944, 35.383063917000072],
            [-80.751408223999988, 35.383093918000043],
            [-80.751381222999953, 35.383134919000042],
            [-80.75120622299994, 35.383249918000047],
            [-80.751155221999966, 35.383293918000049],
            [-80.751105222999968, 35.383365918000038],
            [-80.75107122299994, 35.383414918000028],
            [-80.751065222999955, 35.383445919000053],
            [-80.75101822299996, 35.383471917000065],
            [-80.750955222999949, 35.383493917000067],
            [-80.750904222999964, 35.383523918000037],
            [-80.750600222999935, 35.383678918000044],
            [-80.750586222999971, 35.38369891800005],
            [-80.750578222999934, 35.383724917000052],
            [-80.750550223999937, 35.383763917000067],
            [-80.750501222999958, 35.38379291900003],
            [-80.750466222999989, 35.383828918000063],
            [-80.75043722199996, 35.383866918000024],
            [-80.750411222999958, 35.383917919000055],
            [-80.750383222999972, 35.38401791800004],
            [-80.750301221999962, 35.384250918000077],
            [-80.750287222999987, 35.384320918000071],
            [-80.75026722299998, 35.384354918000042],
            [-80.750248222999971, 35.384421918000044],
            [-80.750225221999983, 35.38448291900005],
            [-80.750170221999952, 35.384571919000052],
            [-80.750154222999981, 35.384591919000059],
            [-80.750101223999934, 35.384535919000029],
            [-80.750060222999934, 35.384514919000026],
            [-80.750007221999965, 35.384517918000029],
            [-80.749966222999944, 35.38453191800005],
            [-80.749910221999983, 35.384539919000076],
            [-80.749746222999988, 35.384537918000035],
            [-80.749719222999943, 35.384546918000069],
            [-80.749670222999953, 35.384573918000058],
            [-80.749633222999989, 35.384607918000029],
            [-80.749553222999964, 35.38456891800007],
            [-80.749509222999961, 35.384556919000033],
            [-80.749413222999976, 35.384550919000048],
            [-80.749230221999937, 35.384573918000058],
            [-80.749129222999954, 35.384582918000035],
            [-80.749065222999945, 35.384582918000035],
            [-80.749002221999945, 35.384577919000037],
            [-80.748897221999982, 35.384550918000059],
            [-80.748835221999968, 35.384540919000074],
            [-80.748748221999961, 35.384534918000043],
            [-80.748706221999953, 35.384544918000074],
            [-80.748648221999986, 35.384566918000075],
            [-80.748622221999938, 35.384586918000025],
            [-80.748581221999984, 35.384606918000031],
            [-80.74856422199997, 35.384640918000059],
            [-80.748562221999975, 35.384902919000069],
            [-80.748557222999978, 35.384937918000048],
            [-80.74852722199995, 35.384980919000043],
            [-80.748496221999972, 35.384997918000067],
            [-80.748451221999971, 35.385012918000029],
            [-80.748379221999983, 35.385068919000048],
            [-80.748014221999938, 35.385116918000051],
            [-80.747691221999958, 35.385121919000028],
            [-80.747088221999945, 35.385080919000075],
            [-80.746836221999956, 35.384939918000043],
            [-80.746293221999963, 35.385017919000063],
            [-80.746035221999989, 35.385230918000047],
            [-80.74558222099995, 35.385604919000059],
            [-80.744273220999958, 35.38610791900004],
            [-80.743674219999946, 35.386386919000074],
            [-80.743657219999989, 35.386400919000039],
            [-80.742878219999966, 35.387049919000049],
            [-80.740548218999947, 35.388335920000031],
            [-80.740063219999968, 35.388412919000075],
            [-80.739325219999955, 35.388169920000053],
            [-80.738301219999983, 35.387615919000041],
            [-80.737701218999973, 35.387533919000077],
            [-80.736936218999972, 35.387563920000048],
            [-80.736740218999955, 35.387170920000074],
            [-80.736406218999946, 35.38703491900003],
            [-80.735903218999965, 35.386627920000024],
            [-80.735678217999975, 35.386581920000026],
            [-80.734868217999974, 35.386832920000074],
            [-80.73458921799994, 35.387129919000074],
            [-80.734113217999948, 35.387200919000065],
            [-80.733804216999943, 35.387109919000068],
            [-80.733440217999942, 35.386811919000024],
            [-80.733355216999939, 35.386071919000074],
            [-80.733047217999967, 35.385845920000065],
            [-80.732601217999957, 35.385664919000078],
            [-80.732347217999973, 35.385618919000024],
            [-80.731760216999987, 35.386077919000059],
            [-80.731733216999942, 35.386347919000059],
            [-80.731314216999976, 35.386833919000026],
            [-80.730894215999967, 35.386832920000074],
            [-80.73069821699994, 35.386444920000031],
            [-80.730418216999965, 35.386236920000044],
            [-80.730111216999944, 35.386308920000033],
            [-80.729720217999954, 35.386532919000047],
            [-80.729443216999982, 35.387011920000077],
            [-80.729387215999964, 35.387109920000057],
            [-80.728772215999982, 35.387227920000043],
            [-80.728601216999948, 35.387107920000062],
            [-80.728462215999969, 35.386511920000032],
            [-80.728239216999953, 35.386493919000031],
            [-80.727818215999946, 35.386880920000067],
            [-80.72792921599995, 35.387087920000056],
            [-80.727707215999942, 35.387312920000056],
            [-80.72737021599994, 35.387293919000058],
            [-80.726895215999946, 35.38706792000005],
            [-80.726742214999945, 35.387074920000032],
            [-80.726504215999967, 35.387084919000074],
            [-80.725806214999977, 35.388210919000073],
            [-80.724856214999988, 35.388983921000033],
            [-80.724296215999971, 35.389306920000024],
            [-80.724298214999976, 35.390037921000044],
            [-80.724521214999982, 35.390707920000068],
            [-80.724690215999942, 35.390932921000058],
            [-80.724858215999973, 35.391571921000036],
            [-80.724802215999944, 35.39196792100006],
            [-80.724607215999981, 35.39210292100006],
            [-80.724590215999967, 35.392189920000078],
            [-80.72446621499995, 35.392904922000071],
            [-80.724310214999946, 35.393088921000071],
            [-80.72421421599995, 35.39320192200006],
            [-80.72368221499994, 35.393434921000051],
            [-80.723544214999947, 35.394029921000026],
            [-80.72354321499995, 35.394780922000052],
            [-80.723750215999985, 35.395116921000067],
            [-80.723825214999977, 35.395238921000043],
            [-80.724468215999934, 35.395862922000049],
            [-80.724607215999981, 35.396108922000053],
            [-80.72454421499998, 35.396288922000053],
            [-80.724441215999946, 35.396592922000025],
            [-80.724550215999955, 35.397647923000079],
            [-80.724777214999961, 35.398054922000028],
            [-80.724719214999936, 35.399451923000072],
            [-80.724917215999938, 35.399749922000069],
            [-80.725506216999975, 35.400273923000043],
            [-80.725309215999971, 35.401148923000051],
            [-80.725391215999935, 35.401581923000037],
            [-80.725756216999969, 35.40199692300007],
            [-80.726595216999954, 35.40259392300004],
            [-80.727322217999983, 35.402868924000074],
            [-80.728022217999978, 35.403392923000069],
            [-80.72822021799999, 35.403868924000051],
            [-80.728582217999985, 35.405456924000077],
            [-80.728668217999939, 35.406782924000026],
            [-80.728865216999964, 35.40708092400007],
            [-80.729480217999935, 35.407676924000043],
            [-80.729790217999948, 35.408234924000055],
            [-80.730013217999954, 35.408635924000066],
            [-80.730015217999949, 35.410608925000076],
            [-80.730125217999955, 35.410906925000063],
            [-80.730658218999963, 35.411655925000048],
            [-80.731835217999958, 35.413031925000041],
            [-80.738042219999954, 35.413284925000028],
            [-80.737980220999987, 35.413656925000055],
            [-80.737154219999979, 35.418635926000036],
            [-80.737707220999937, 35.419270926000024],
            [-80.737474219999967, 35.419382926000026],
            [-80.737633220999953, 35.419623926000043],
            [-80.738305220999962, 35.420384926000054],
            [-80.740289222999934, 35.42344792800003],
            [-80.740354221999951, 35.423670927000046],
            [-80.740281220999975, 35.42391292700006],
            [-80.740030221999973, 35.424192927000036],
            [-80.739768221999952, 35.424368927000046],
            [-80.739644221999981, 35.424592928000038],
            [-80.739650220999977, 35.424600927000029],
            [-80.739730221999935, 35.424701927000058],
            [-80.739890220999939, 35.424554928000077],
            [-80.739970221999954, 35.424497928000051],
            [-80.740139220999936, 35.424396928000078],
            [-80.740465221999955, 35.424244927000075],
            [-80.740795221999974, 35.424102927000035],
            [-80.741058221999936, 35.42439392700004],
            [-80.741393221999942, 35.424764927000069],
            [-80.743227222999963, 35.426441928000031],
            [-80.74395522399999, 35.427143928000078],
            [-80.745188222999957, 35.428326927000057],
            [-80.745333223999978, 35.428484928000046],
            [-80.746953223999981, 35.43024992900007],
            [-80.747193223999943, 35.430600929000036],
            [-80.74766322499994, 35.431314928000063],
            [-80.747728224999946, 35.43141392900003],
            [-80.748239224999963, 35.43253992800004],
            [-80.748306224999965, 35.432526928000073],
            [-80.748703223999939, 35.433533928000031],
            [-80.749139224999965, 35.434600929000055],
            [-80.749167224999951, 35.434852929000044],
            [-80.749251224999966, 35.434897929000044],
            [-80.749301225999943, 35.435264929000027],
            [-80.749385224999969, 35.435676929000067],
            [-80.749498223999979, 35.43672992900008],
            [-80.749750225999946, 35.436865930000067],
            [-80.749795224999957, 35.436890929000072],
            [-80.750368225999978, 35.437841929000058],
            [-80.750680225999986, 35.438360930000044],
            [-80.750898225999947, 35.438963930000057],
            [-80.750980225999967, 35.439008930000057],
            [-80.750981225999965, 35.439163931000053],
            [-80.751335225999981, 35.439988930000027],
            [-80.751417225999944, 35.440032930000029],
            [-80.752048226999989, 35.44195293100006],
            [-80.754523227999982, 35.44176893000008],
            [-80.756917227999963, 35.44159293000007],
            [-80.759107227999948, 35.441420930000049],
            [-80.759263228999941, 35.441409930000077],
            [-80.760386227999959, 35.441333929000052],
            [-80.761181228999988, 35.44127093000003],
            [-80.76214822899999, 35.441197929000055],
            [-80.762788228999966, 35.441160930000024],
            [-80.762864229999934, 35.441153931000031],
            [-80.763665229999958, 35.44109093000003],
            [-80.764040229999978, 35.441053930000066],
            [-80.764126228999942, 35.44104593000003],
            [-80.764470230999962, 35.441011930000059],
            [-80.764912229999936, 35.440974930000039],
            [-80.764113229999964, 35.439276930000062],
            [-80.763694229999942, 35.438387929000044],
            [-80.763283229999956, 35.437512929000036],
            [-80.762634228999957, 35.436135929000045],
            [-80.762526228999945, 35.435905929000057],
            [-80.761990229999981, 35.43480992800005],
            [-80.761837229999969, 35.434496929000034],
            [-80.761793228999977, 35.434405928000047],
            [-80.761330228999952, 35.433460928000045],
            [-80.761039228999948, 35.432866929000056],
            [-80.76100822799998, 35.432802928000058],
            [-80.760786228999962, 35.432349928000065],
            [-80.760611228999949, 35.431990927000072],
            [-80.759857227999987, 35.430450927000038],
            [-80.759806227999945, 35.430345928000065],
            [-80.759778227999959, 35.430288928000039],
            [-80.759770227999979, 35.430272928000079],
            [-80.759763226999951, 35.430258928000057],
            [-80.759245228999987, 35.429196927000078],
            [-80.759224227999937, 35.429153928000062],
            [-80.759208227999977, 35.429120928000032],
            [-80.759189228999958, 35.429081927000027],
            [-80.759177227999942, 35.429057928000077],
            [-80.75916622799997, 35.429035927000029],
            [-80.759088228999985, 35.428876927000033],
            [-80.758368227999938, 35.427329927000073],
            [-80.757311226999946, 35.424879927000063],
            [-80.756779226999981, 35.423656926000035],
            [-80.756889226999988, 35.423635926000031],
            [-80.757135226999935, 35.423599927000055],
            [-80.757363226999985, 35.423571926000079],
            [-80.757572226999969, 35.423547926000026],
            [-80.757801227999948, 35.423532926000064],
            [-80.758003226999961, 35.423542927000028],
            [-80.758107227999972, 35.423558927000045],
            [-80.758384227999954, 35.423632926000039],
            [-80.758597226999939, 35.423707926000077],
            [-80.759359227999937, 35.423979926000072],
            [-80.759459227999969, 35.424013927000033],
            [-80.759709227999963, 35.424099926000054],
            [-80.759922227999937, 35.424161926000068],
            [-80.760158227999966, 35.42421792600004],
            [-80.760354228999972, 35.424253926000063],
            [-80.761396227999967, 35.424417926000046],
            [-80.762391228999945, 35.424575926000045],
            [-80.763091227999951, 35.424680926000065],
            [-80.763994228999934, 35.424795926000058],
            [-80.764143228999956, 35.424798926000051],
            [-80.764277228999958, 35.424782926000034],
            [-80.76438922899996, 35.424755926000046],
            [-80.76442222999998, 35.424742926000079],
            [-80.764482228999952, 35.424720926000077],
            [-80.764608229999965, 35.424658926000063],
            [-80.764717228999984, 35.424591926000062],
            [-80.765250229999936, 35.424208926000063],
            [-80.76659922999994, 35.423237925000024],
            [-80.767255229999989, 35.422774926000045],
            [-80.767478228999948, 35.422621926000033],
            [-80.767711230999964, 35.422484926000038],
            [-80.767921229999956, 35.42237992500003],
            [-80.768084228999953, 35.422308925000038],
            [-80.768270229999985, 35.422237926000037],
            [-80.768991230999973, 35.42197692600007],
            [-80.770048230999976, 35.421581925000055],
            [-80.77202123099994, 35.420857925000064],
            [-80.772284230999958, 35.420768925000061],
            [-80.772451230999934, 35.420721925000066],
            [-80.77271923099994, 35.420670925000024],
            [-80.772828230999949, 35.420655926000052],
            [-80.773100230999944, 35.420629925000071],
            [-80.773363231999951, 35.420621925000034],
            [-80.773641231999989, 35.420631925000066],
            [-80.774048231999984, 35.420669925000027],
            [-80.774852231999944, 35.420764925000071],
            [-80.775293231999967, 35.420820925000044],
            [-80.775522232999947, 35.420853925000074],
            [-80.775693231999981, 35.420892925000032],
            [-80.775908231999949, 35.420958925000036],
            [-80.776345232999972, 35.421141925000029],
            [-80.776672232999942, 35.421298925000031],
            [-80.776899231999948, 35.421420925000064],
            [-80.777019232999976, 35.421493926000039],
            [-80.777140232999955, 35.421573925000075],
            [-80.777360232999968, 35.421735925000064],
            [-80.777430231999972, 35.421794925000029],
            [-80.777555232999987, 35.421910925000077],
            [-80.777687232999938, 35.422057926000036],
            [-80.777826232999985, 35.422243925000032],
            [-80.77797523299995, 35.422479925000061],
            [-80.778001232999941, 35.422513925000032],
            [-80.778067232999945, 35.422584925000024],
            [-80.77813923299999, 35.422644926000032],
            [-80.778223231999959, 35.422696925000025],
            [-80.778313232999949, 35.422739926000077],
            [-80.778394233999961, 35.422766925000076],
            [-80.778982233999955, 35.422926926000059],
            [-80.779789232999974, 35.423130926000056],
            [-80.780754234999961, 35.423381926000047],
            [-80.781175233999988, 35.423475925000048],
            [-80.781472233999978, 35.423525926000025],
            [-80.781540233999976, 35.42353092500008],
            [-80.781783233999988, 35.42352792500003],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 71,
        "SolutionID": "PI-29:I",
        "Shape_Length": 0.57205739004563882,
        "Shape_Area": 0.0071016357909662936
      }
    },
    {
      "type": "Feature",
      "id": 72,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.923646260999988, 35.131746858000042],
            [-80.92366526099994, 35.131771858000036],
            [-80.924902260999943, 35.133981858000027],
            [-80.931495262999988, 35.131622858000071],
            [-80.932444262999979, 35.131290857000067],
            [-80.932472263999955, 35.131293858000049],
            [-80.932512262999978, 35.131288857000072],
            [-80.932590263999941, 35.131271858000048],
            [-80.932688262999989, 35.131239857000025],
            [-80.933197263999944, 35.131054857000038],
            [-80.933495262999941, 35.130947857000024],
            [-80.934107262999987, 35.130735857000047],
            [-80.934245263999969, 35.130684857000062],
            [-80.934758263999981, 35.13049785700008],
            [-80.934945263999964, 35.13042985800007],
            [-80.935633264999979, 35.130168857000058],
            [-80.935803263999958, 35.130105856000057],
            [-80.936413263999953, 35.129883857000038],
            [-80.937749264999979, 35.129412857000034],
            [-80.937826264999956, 35.12937585700007],
            [-80.937934264999967, 35.129332857000065],
            [-80.938047264999966, 35.129265858000053],
            [-80.93824026499999, 35.129136857000049],
            [-80.938437264999948, 35.128976857000055],
            [-80.938458264999952, 35.128986857000029],
            [-80.938760265999974, 35.129092856000057],
            [-80.939293264999947, 35.129201857000055],
            [-80.939647265999952, 35.129274857000041],
            [-80.939998265999975, 35.12933485700006],
            [-80.94060826599997, 35.129416857000024],
            [-80.941744265999944, 35.12962785700006],
            [-80.941930265999986, 35.129193857000075],
            [-80.942172266999989, 35.128588857000068],
            [-80.942343266999956, 35.128195857000037],
            [-80.942471264999938, 35.127913856000077],
            [-80.942620265999949, 35.127514856000062],
            [-80.942767266999965, 35.127151857000058],
            [-80.943077265999989, 35.126445856000032],
            [-80.943170265999981, 35.126245856000025],
            [-80.943266264999977, 35.12605585600005],
            [-80.943364264999957, 35.125877856000045],
            [-80.943596266999975, 35.125491856000053],
            [-80.943850266999959, 35.125120856000024],
            [-80.944025266999972, 35.124891856000033],
            [-80.944220266999935, 35.124654856000063],
            [-80.944494265999936, 35.12434885600004],
            [-80.944825266999942, 35.124016856000026],
            [-80.944976266999959, 35.123873855000056],
            [-80.945041265999976, 35.123817856000073],
            [-80.945163266999941, 35.123711855000067],
            [-80.945478266999942, 35.123457855000026],
            [-80.945644266999977, 35.123328855000068],
            [-80.945986266999967, 35.123088855000049],
            [-80.946281266999961, 35.122896856000068],
            [-80.946764267999981, 35.122618855000042],
            [-80.946926266999981, 35.122529855000039],
            [-80.94732126699995, 35.122328855000035],
            [-80.947857266999961, 35.122068855000066],
            [-80.94850626799996, 35.121766855000033],
            [-80.948673266999947, 35.121685854000077],
            [-80.949250267999957, 35.121406855000032],
            [-80.94962026799999, 35.121225855000034],
            [-80.950544266999941, 35.120783854000024],
            [-80.950823267999965, 35.120646855000075],
            [-80.95128626799999, 35.120405855000058],
            [-80.952752267999983, 35.119688855000049],
            [-80.95326326899999, 35.119445854000048],
            [-80.953227268999967, 35.119372855000051],
            [-80.953120267999964, 35.119225854000035],
            [-80.95290726799999, 35.118662854000036],
            [-80.952889267999979, 35.118594854000037],
            [-80.952872267999965, 35.118475855000042],
            [-80.952867267999977, 35.118288853000024],
            [-80.952861267999936, 35.118098854000039],
            [-80.95286626799998, 35.117837855000062],
            [-80.952861267999936, 35.117612854000072],
            [-80.952878268999939, 35.117066854000029],
            [-80.952869267999972, 35.116877854000052],
            [-80.952854268999943, 35.116700854000044],
            [-80.952873268999952, 35.116501854000035],
            [-80.952856268999938, 35.116276854000034],
            [-80.952831268999944, 35.116134853000062],
            [-80.952786268999944, 35.115989853000031],
            [-80.952776268999969, 35.115943853000033],
            [-80.952759268999955, 35.115873853000039],
            [-80.952756268999963, 35.115821854000046],
            [-80.952763268999945, 35.115739854000026],
            [-80.952749267999934, 35.115575854000042],
            [-80.95267526899994, 35.115384854000069],
            [-80.952648267999962, 35.115200853000033],
            [-80.952558268999951, 35.114809853000054],
            [-80.952473268999938, 35.114357854000048],
            [-80.952465268999958, 35.114211853000029],
            [-80.952441267999973, 35.11413985300004],
            [-80.952398267999968, 35.113974853000059],
            [-80.952298267999936, 35.113422854000078],
            [-80.952215267999975, 35.113079854000034],
            [-80.952093267999942, 35.112430853000035],
            [-80.952031267999985, 35.112161853000032],
            [-80.951995267999962, 35.111963853000077],
            [-80.951957267999944, 35.111791853000057],
            [-80.951946266999983, 35.11168585300004],
            [-80.951951267999959, 35.111549853000042],
            [-80.951973267999961, 35.111365852000063],
            [-80.952051267999934, 35.110973853000075],
            [-80.952073267999936, 35.11090185200004],
            [-80.952097267999989, 35.110824852000064],
            [-80.95211926799999, 35.110715853000045],
            [-80.952163266999946, 35.110322852000024],
            [-80.952185267999937, 35.109984853000071],
            [-80.952185267999937, 35.109842853000032],
            [-80.952211267999985, 35.109572852000042],
            [-80.952211267999985, 35.109527853000031],
            [-80.952182267999945, 35.109444852000024],
            [-80.951550267999949, 35.108461852000062],
            [-80.951509267999938, 35.108378852000044],
            [-80.951491267999984, 35.108314852000035],
            [-80.951479266999968, 35.108225852000032],
            [-80.951475267999967, 35.108107851000057],
            [-80.951494267999976, 35.107957852000027],
            [-80.951665266999953, 35.107175852000069],
            [-80.951732266999954, 35.106962852000038],
            [-80.95177526699996, 35.106840851000072],
            [-80.951837267999963, 35.106716852000034],
            [-80.952035267999975, 35.106383852000079],
            [-80.952215267999975, 35.106174852000038],
            [-80.95229926799999, 35.10607585200006],
            [-80.952327267999976, 35.106054852000057],
            [-80.952363267999942, 35.106041851000043],
            [-80.952477267999939, 35.106020852000029],
            [-80.952552266999987, 35.106010851000065],
            [-80.953068267999981, 35.105859852000037],
            [-80.953219267999941, 35.105800852000073],
            [-80.95331626799998, 35.105757851000078],
            [-80.953456267999968, 35.105659851000041],
            [-80.953516268999977, 35.105595851000032],
            [-80.953563267999982, 35.105507852000073],
            [-80.953621267999949, 35.105271851000055],
            [-80.953680266999982, 35.105100851000032],
            [-80.953761267999937, 35.104918851000036],
            [-80.953082267999946, 35.103786850000063],
            [-80.952868266999985, 35.10345785100003],
            [-80.952714267999966, 35.103239851000069],
            [-80.952525268999977, 35.10299585100006],
            [-80.952234267999984, 35.10266185100005],
            [-80.952018267999961, 35.102427851000073],
            [-80.951870266999947, 35.102280850000056],
            [-80.951715267999987, 35.102135851000071],
            [-80.950802266999972, 35.101351850000071],
            [-80.950462266999978, 35.101134850000051],
            [-80.949863266999955, 35.100751851000041],
            [-80.948636265999937, 35.100182851000056],
            [-80.946956265999972, 35.099516851000033],
            [-80.944754264999972, 35.098637850000046],
            [-80.945889264999948, 35.097113850000028],
            [-80.94737026599995, 35.094969849000051],
            [-80.947842265999952, 35.09429084900006],
            [-80.948050265999939, 35.09402884900004],
            [-80.948354266999957, 35.093730849000053],
            [-80.949216265999951, 35.093108849000032],
            [-80.950998266999989, 35.091882848000068],
            [-80.952573266999934, 35.090821848000076],
            [-80.952831266999965, 35.090652848000047],
            [-80.95301726699995, 35.090529848000074],
            [-80.953537267999934, 35.090113848000044],
            [-80.954129266999985, 35.089464847000045],
            [-80.954215266999938, 35.089354848000028],
            [-80.954139267999949, 35.089294848000065],
            [-80.953979267999955, 35.089239848000034],
            [-80.953778266999961, 35.089162848000058],
            [-80.953618267999957, 35.089086848000079],
            [-80.953457266999976, 35.088954848000071],
            [-80.95315626699994, 35.088728847000027],
            [-80.952835267999944, 35.088453847000039],
            [-80.952615265999952, 35.088322848000075],
            [-80.952434266999944, 35.088278848000073],
            [-80.952146265999943, 35.088261847000069],
            [-80.952013266999984, 35.088212848000069],
            [-80.951799266999956, 35.088080848000061],
            [-80.951611266999976, 35.088052848000075],
            [-80.951437265999971, 35.088074848000076],
            [-80.951237266999954, 35.088173848000054],
            [-80.950956265999935, 35.088360847000047],
            [-80.950689265999983, 35.088580848000049],
            [-80.950495265999962, 35.08875684800006],
            [-80.950180265999961, 35.089086848000079],
            [-80.95002726599995, 35.089185848000056],
            [-80.949639265999963, 35.08940584700008],
            [-80.949532265999949, 35.089498849000051],
            [-80.94937826599994, 35.089729848000047],
            [-80.949237265999955, 35.089762848000078],
            [-80.949090266999974, 35.089823848000037],
            [-80.948903264999956, 35.089834848000066],
            [-80.948776265999982, 35.089784848000079],
            [-80.948689264999985, 35.089707848000046],
            [-80.948535265999965, 35.089515848000076],
            [-80.948341265999943, 35.089411848000054],
            [-80.948268265999957, 35.089284848000034],
            [-80.948274265999942, 35.089158848000068],
            [-80.948415264999937, 35.089009848000046],
            [-80.948542265999947, 35.088844848000065],
            [-80.948555264999982, 35.088663849000056],
            [-80.948535265999965, 35.08832884800006],
            [-80.948441265999975, 35.087998848000041],
            [-80.948354265999967, 35.087734848000025],
            [-80.948214265999979, 35.08745484800005],
            [-80.94808726499997, 35.087272848000055],
            [-80.948020265999958, 35.087201848000063],
            [-80.947719265999979, 35.086651848000031],
            [-80.947585264999987, 35.086541847000035],
            [-80.947398265999936, 35.086453848000076],
            [-80.947197265999989, 35.086321848000068],
            [-80.947117264999974, 35.086228847000029],
            [-80.947037264999949, 35.086030847000075],
            [-80.947037264999949, 35.085887848000027],
            [-80.947057264999955, 35.085766847000059],
            [-80.947137264999981, 35.08553084700003],
            [-80.947264264999944, 35.085216847000027],
            [-80.94734426499997, 35.085084847000076],
            [-80.947505264999961, 35.084997848000057],
            [-80.947652265999977, 35.084975848000056],
            [-80.947873264999942, 35.084975847000067],
            [-80.948133264999967, 35.085013847000027],
            [-80.948976265999988, 35.085007847000043],
            [-80.949264265999943, 35.085040847000073],
            [-80.949651265999989, 35.085117847000049],
            [-80.949765265999986, 35.085145848000025],
            [-80.950093265999953, 35.085106847000077],
            [-80.950307265999982, 35.084991847000026],
            [-80.950434266999935, 35.084980847000054],
            [-80.950574266999979, 35.084991847000026],
            [-80.950688265999986, 35.085029847000044],
            [-80.95078826699995, 35.085002847000055],
            [-80.950755265999987, 35.084815847000073],
            [-80.950695265999968, 35.084716847000038],
            [-80.950614265999945, 35.084358847000033],
            [-80.950394265999989, 35.08407384700007],
            [-80.950360265999961, 35.083974847000036],
            [-80.950273265999954, 35.08380384700007],
            [-80.950180265999961, 35.083699847000048],
            [-80.950046265999958, 35.083639847000029],
            [-80.94971826699998, 35.083606847000055],
            [-80.949604264999948, 35.083600846000024],
            [-80.949504265999963, 35.083556846000079],
            [-80.949310265999941, 35.08334284700004],
            [-80.948608265999951, 35.082798847000049],
            [-80.948474264999959, 35.082660847000056],
            [-80.948274264999952, 35.082248846000027],
            [-80.948233265999988, 35.082089847000077],
            [-80.94826726499997, 35.081781846000069],
            [-80.948292265999953, 35.081699846000049],
            [-80.948314265999954, 35.08162784600006],
            [-80.948360265999952, 35.081544846000043],
            [-80.94854126499996, 35.081429846000049],
            [-80.948822265999979, 35.08115484700005],
            [-80.948949265999943, 35.08108384600007],
            [-80.949049265999975, 35.081033847000072],
            [-80.949123265999958, 35.08097884700004],
            [-80.949156264999942, 35.080830846000026],
            [-80.949143265999965, 35.080720846000077],
            [-80.948935265999978, 35.080346846000054],
            [-80.948955264999938, 35.080209846000059],
            [-80.948902264999958, 35.080038846000036],
            [-80.948761264999973, 35.079851845000064],
            [-80.948741264999967, 35.079741846000047],
            [-80.94878126499998, 35.07945584600003],
            [-80.948835265999946, 35.079295846000036],
            [-80.948842264999939, 35.079274846000033],
            [-80.949015265999947, 35.079115846000036],
            [-80.949236264999968, 35.079032846000075],
            [-80.94992526499999, 35.078994845000068],
            [-80.950165265999942, 35.07894484600007],
            [-80.950600265999981, 35.078867846000037],
            [-80.950754264999944, 35.07853284600003],
            [-80.950894265999978, 35.078202845000078],
            [-80.950968265999961, 35.077955846000066],
            [-80.950981265999985, 35.077647845000058],
            [-80.950968265999961, 35.077471845000048],
            [-80.95093426599999, 35.07737284600006],
            [-80.950734265999984, 35.077245846000039],
            [-80.950466265999978, 35.077119845000027],
            [-80.950279265999939, 35.076965845000075],
            [-80.950078265999935, 35.076861846000043],
            [-80.949951265999971, 35.07682884500008],
            [-80.949343264999982, 35.076833845000067],
            [-80.949115264999989, 35.076800846000026],
            [-80.948881265999944, 35.076811845000066],
            [-80.948768264999956, 35.076800846000026],
            [-80.948641264999935, 35.076734845000033],
            [-80.948534264999978, 35.076635845000055],
            [-80.948413265999989, 35.076482845000044],
            [-80.948233264999942, 35.076025845000061],
            [-80.948095264999949, 35.075640845000066],
            [-80.948052264999944, 35.075520845000028],
            [-80.948019264999971, 35.075415845000066],
            [-80.94800526399996, 35.075217845000054],
            [-80.948059264999983, 35.075091844000042],
            [-80.94822626499996, 35.074953845000039],
            [-80.948305264999988, 35.074934846000076],
            [-80.948460264999937, 35.074898844000074],
            [-80.948968264999962, 35.074953845000039],
            [-80.94914926499996, 35.074953845000039],
            [-80.949476264999987, 35.074766845000056],
            [-80.949583265999934, 35.074635845000046],
            [-80.949637264999978, 35.074552845000028],
            [-80.949717265999936, 35.074404845000061],
            [-80.949770265999973, 35.074321845000043],
            [-80.949750265999967, 35.074030845000038],
            [-80.949726264999981, 35.073956845000055],
            [-80.949596264999968, 35.073552844000062],
            [-80.949583265999934, 35.07340384400004],
            [-80.94961026499999, 35.073288845000036],
            [-80.949703264999982, 35.073007844000074],
            [-80.949697265999987, 35.072793845000035],
            [-80.949695264999946, 35.072767845000044],
            [-80.949690264999958, 35.072700844000053],
            [-80.94966326499997, 35.072535845000061],
            [-80.94966326499997, 35.072364844000049],
            [-80.94979026499999, 35.07210084500008],
            [-80.949797265999962, 35.071908844000063],
            [-80.949696264999943, 35.07168884500004],
            [-80.949543265999978, 35.07149684400008],
            [-80.94938226499994, 35.071309844000041],
            [-80.949322264999978, 35.071166844000061],
            [-80.949262264999959, 35.070798844000024],
            [-80.949195263999968, 35.070717844000058],
            [-80.94872926499994, 35.071338845000071],
            [-80.945504264999954, 35.075655845000028],
            [-80.94433526499995, 35.077262846000053],
            [-80.94401226399998, 35.077705845000025],
            [-80.943672264999975, 35.078112846000067],
            [-80.943050263999964, 35.078883846000053],
            [-80.941912263999939, 35.080391847000044],
            [-80.940955262999978, 35.081690847000061],
            [-80.940404261999959, 35.08246884600004],
            [-80.93980226299999, 35.083316848000038],
            [-80.93833626299994, 35.085304847000032],
            [-80.937341261999961, 35.086628848000032],
            [-80.937166261999948, 35.086857848000079],
            [-80.937153261999981, 35.086873848000039],
            [-80.936255262999964, 35.088041848000046],
            [-80.935204261999957, 35.089504849000036],
            [-80.93420426199998, 35.090785849000042],
            [-80.933658261999938, 35.091616849000047],
            [-80.933445260999974, 35.091952850000041],
            [-80.933302260999938, 35.091748849000055],
            [-80.933233261999987, 35.091639849000046],
            [-80.932838260999972, 35.091063849000079],
            [-80.932719260999988, 35.090878849000035],
            [-80.932360260999985, 35.09028384800007],
            [-80.93232926099995, 35.090199849000044],
            [-80.932316260999983, 35.090135848000045],
            [-80.932152259999953, 35.089563849000058],
            [-80.932127260999948, 35.089361849000056],
            [-80.932161261999966, 35.088843849000057],
            [-80.932697260999987, 35.086810848000027],
            [-80.932805260999942, 35.086439849000044],
            [-80.933080261999976, 35.085498848000043],
            [-80.933466260999978, 35.083949847000042],
            [-80.933318260999954, 35.083984847000067],
            [-80.932874259999949, 35.084088847000032],
            [-80.932211259999974, 35.084674847000031],
            [-80.932080260999953, 35.084860848000062],
            [-80.931821259999936, 35.085228848000042],
            [-80.931395259999988, 35.08581484900003],
            [-80.930919260999985, 35.086453849000065],
            [-80.930510260999938, 35.086866848000057],
            [-80.929866260999972, 35.087260848000028],
            [-80.929317259999948, 35.087468848000071],
            [-80.928486259999943, 35.087658848000046],
            [-80.927365259999988, 35.087901849000048],
            [-80.926378258999989, 35.088109848000045],
            [-80.925839258999986, 35.088227849000077],
            [-80.924282257999948, 35.088566850000063],
            [-80.923502257999985, 35.088732849000053],
            [-80.922782257999984, 35.088890849000052],
            [-80.922616257999948, 35.088941849000037],
            [-80.922527257999946, 35.088968849000025],
            [-80.922217257999989, 35.089006849000043],
            [-80.921139257999982, 35.089240849000078],
            [-80.920511257999976, 35.089371849000031],
            [-80.92013925699996, 35.089418849000026],
            [-80.91957625699996, 35.089481849000038],
            [-80.919535255999961, 35.08948484900003],
            [-80.918368256999941, 35.08958184800008],
            [-80.91995125699998, 35.091241850000074],
            [-80.919920257999934, 35.091386850000049],
            [-80.919684256999972, 35.092130850000046],
            [-80.918915256999981, 35.094588851000026],
            [-80.918572257999983, 35.095665850000046],
            [-80.918521256999952, 35.095826850000037],
            [-80.918443256999979, 35.095794851000051],
            [-80.918206256999952, 35.095664851000038],
            [-80.918096256999945, 35.095624851000025],
            [-80.918010256999935, 35.095607850000079],
            [-80.917831256999989, 35.095552850000047],
            [-80.917594256999962, 35.095456851000051],
            [-80.91717125699995, 35.09529985000006],
            [-80.917103256999951, 35.095260851000035],
            [-80.917059256999949, 35.095251851000057],
            [-80.916943255999968, 35.09520985000006],
            [-80.916748255999948, 35.095176851000076],
            [-80.916610255999956, 35.095187850000059],
            [-80.916390256999989, 35.095189851000043],
            [-80.916261255999984, 35.095181850000074],
            [-80.915993256999968, 35.095173850000037],
            [-80.915840256999957, 35.095190851000041],
            [-80.915703256999961, 35.095235851000041],
            [-80.915671255999939, 35.095253851000052],
            [-80.915562256999976, 35.09529185100007],
            [-80.915473256999974, 35.095311851000076],
            [-80.915365255999973, 35.095326850000049],
            [-80.915162255999974, 35.095343851000052],
            [-80.914899255999956, 35.095383851000065],
            [-80.914706255999988, 35.095405851000066],
            [-80.914516256999946, 35.095415850000052],
            [-80.914321255999937, 35.095432850000066],
            [-80.914190255999983, 35.095474850000073],
            [-80.913944255999979, 35.09549185000003],
            [-80.913821254999959, 35.095511851000026],
            [-80.913620255999945, 35.095534850000035],
            [-80.913575254999955, 35.095547851000049],
            [-80.913446256999976, 35.095607850000079],
            [-80.91338925499997, 35.095616851000045],
            [-80.913327255999945, 35.095634851000057],
            [-80.913241254999946, 35.095641851000039],
            [-80.913185254999973, 35.095636851000052],
            [-80.913109254999938, 35.095602851000024],
            [-80.912877255999945, 35.095458850000057],
            [-80.912619255999971, 35.095361851000064],
            [-80.912585255999943, 35.095341851000057],
            [-80.912423254999965, 35.095185850000064],
            [-80.912360254999953, 35.095131851000076],
            [-80.91228625499997, 35.095091851000063],
            [-80.912199254999962, 35.095053851000046],
            [-80.911954254999955, 35.094946850000042],
            [-80.911918254999989, 35.094938851000052],
            [-80.911867254999947, 35.094939851000049],
            [-80.911755254999946, 35.094964850000054],
            [-80.911681254999962, 35.094966850000048],
            [-80.911630255999967, 35.094943851000039],
            [-80.911599254999942, 35.09491685100005],
            [-80.911521254999968, 35.094861851000076],
            [-80.911485254999945, 35.094822850000071],
            [-80.911335255999973, 35.094561850000048],
            [-80.911283254999944, 35.09445485100008],
            [-80.911171254999942, 35.09429485000004],
            [-80.911101254999949, 35.094229850000033],
            [-80.911071254999968, 35.094209850000027],
            [-80.911042254999984, 35.094201851000037],
            [-80.91098425499996, 35.094198851000044],
            [-80.910905254999989, 35.094215851000058],
            [-80.910878253999954, 35.094209850000027],
            [-80.910840254999982, 35.094182851000028],
            [-80.910686254999973, 35.094035850000068],
            [-80.910610254999938, 35.093970850000062],
            [-80.910486253999977, 35.093905851000045],
            [-80.910361254999941, 35.093803850000029],
            [-80.910267254999951, 35.093748851000043],
            [-80.910203253999953, 35.093657850000056],
            [-80.91013925499999, 35.093610850000061],
            [-80.91005325499998, 35.093571850000046],
            [-80.909973254999954, 35.093540850000068],
            [-80.90991825499998, 35.093534850000026],
            [-80.909853254999973, 35.093549850000045],
            [-80.909802253999942, 35.093550851000032],
            [-80.909735254999987, 35.093533851000075],
            [-80.909568254999954, 35.093507850000037],
            [-80.909494253999981, 35.093477851000046],
            [-80.909090253999977, 35.093281851000029],
            [-80.909024253999974, 35.093233850000047],
            [-80.908990253999946, 35.093194850000032],
            [-80.908894253999961, 35.093031851000035],
            [-80.90877025399999, 35.092859850000025],
            [-80.908745253999939, 35.092837850000024],
            [-80.908727254999974, 35.092798849000076],
            [-80.90871825399995, 35.092727850000074],
            [-80.908707254999968, 35.092684850000069],
            [-80.908666253999968, 35.092580850000047],
            [-80.908648254999946, 35.092554850000056],
            [-80.908605253999951, 35.092520850000028],
            [-80.908553253999969, 35.092501850000076],
            [-80.908180254999934, 35.092338850000033],
            [-80.907986253999979, 35.092189850000068],
            [-80.907883253999955, 35.091987850000066],
            [-80.90784025399995, 35.091920851000054],
            [-80.90780325299994, 35.091807849000077],
            [-80.90779925399994, 35.091701850000049],
            [-80.90776525299998, 35.091646850000075],
            [-80.907651253999973, 35.091541850000056],
            [-80.907599252999944, 35.091465850000077],
            [-80.907584253999971, 35.091396849000034],
            [-80.907594253999946, 35.091354850000073],
            [-80.907545252999967, 35.091306850000024],
            [-80.907514253999977, 35.091288850000069],
            [-80.907478253999955, 35.09127385000005],
            [-80.907432253999957, 35.091240850000077],
            [-80.907360253999968, 35.091140850000045],
            [-80.907324252999956, 35.091109850000066],
            [-80.907274252999969, 35.091088850000062],
            [-80.907207252999967, 35.091079850000028],
            [-80.907080252999947, 35.091072850000046],
            [-80.907035253999936, 35.091065850000064],
            [-80.906986253999946, 35.091037850000077],
            [-80.906944252999949, 35.091001851000044],
            [-80.906914253999958, 35.090930850000063],
            [-80.906899253999939, 35.090850850000038],
            [-80.906903252999939, 35.090732849000062],
            [-80.906904253999983, 35.090714849000051],
            [-80.906894252999962, 35.090677849000031],
            [-80.903763252999966, 35.091065849000074],
            [-80.903746251999962, 35.091118850000043],
            [-80.903730252999935, 35.091153850000069],
            [-80.903658251999957, 35.091263850000075],
            [-80.903353251999988, 35.091686851000077],
            [-80.903161251999961, 35.091972850000047],
            [-80.903097252999942, 35.092091850000031],
            [-80.90300825199995, 35.092332850000048],
            [-80.90294725199999, 35.092472850000036],
            [-80.902867252999954, 35.092600850000053],
            [-80.902779251999959, 35.092693851000035],
            [-80.902682252999966, 35.092770850000079],
            [-80.902597251999964, 35.092817851000063],
            [-80.902671252999937, 35.092923850000034],
            [-80.902873252999939, 35.093213850000041],
            [-80.902975251999976, 35.093365850000055],
            [-80.90372425299995, 35.094484851000061],
            [-80.903940252999973, 35.094792851000079],
            [-80.904303251999977, 35.095311851000076],
            [-80.904685252999968, 35.095884851000051],
            [-80.905331253999975, 35.096884851000027],
            [-80.905618252999943, 35.097337852000067],
            [-80.905655252999964, 35.097402851000027],
            [-80.905682253999942, 35.097450851000076],
            [-80.905718253999964, 35.097525851000057],
            [-80.905812252999965, 35.097756851000042],
            [-80.90594525299997, 35.098168851000025],
            [-80.906283253999959, 35.099271852000072],
            [-80.906498253999985, 35.099916853000025],
            [-80.90681125399999, 35.100737851000076],
            [-80.907028253999954, 35.101366852000069],
            [-80.907043254999962, 35.101410853000061],
            [-80.907717253999976, 35.103434853000067],
            [-80.907858254999951, 35.103889853000055],
            [-80.90793025399995, 35.104174852000028],
            [-80.907961254999975, 35.104330853000079],
            [-80.907984254999974, 35.104480852000052],
            [-80.908015254999953, 35.104793852000057],
            [-80.908050253999988, 35.105922853000038],
            [-80.908058254999958, 35.106189853000046],
            [-80.908067253999945, 35.106464853000034],
            [-80.908103254999958, 35.107278853000025],
            [-80.908133254999939, 35.107729853000023],
            [-80.90814725499996, 35.107983854000054],
            [-80.908168254999964, 35.10838285300008],
            [-80.908183254999983, 35.108478854000055],
            [-80.908189254999968, 35.108520853000073],
            [-80.908217254999954, 35.108616853000058],
            [-80.908226254999988, 35.108633854000061],
            [-80.908362254999986, 35.108689854000033],
            [-80.908706255999959, 35.108770854000056],
            [-80.909149254999988, 35.108875853000029],
            [-80.909779255999979, 35.109024853000051],
            [-80.910788255999989, 35.109262854000065],
            [-80.911763255999972, 35.109512854000059],
            [-80.911856255999965, 35.109205853000049],
            [-80.912494255999945, 35.109222853000063],
            [-80.912874256999942, 35.109174854000059],
            [-80.913349256999936, 35.109094854000034],
            [-80.913825256999985, 35.109059854000066],
            [-80.915754256999946, 35.109010853000029],
            [-80.915883256999962, 35.109013854000068],
            [-80.91600425699994, 35.109603854000056],
            [-80.916035257999965, 35.109822854000072],
            [-80.916049256999941, 35.110027853000076],
            [-80.916029256999934, 35.110250854000071],
            [-80.915982257999985, 35.110437854000054],
            [-80.915907256999958, 35.110632854000073],
            [-80.915704256999959, 35.110973854000065],
            [-80.915516256999979, 35.111223855000048],
            [-80.915482256999951, 35.111256854000032],
            [-80.915296256999966, 35.111454854000044],
            [-80.915076256999953, 35.111700854000048],
            [-80.914190255999983, 35.112602854000045],
            [-80.913746256999957, 35.113066854000067],
            [-80.91359825699999, 35.113220855000066],
            [-80.913271256999963, 35.113542855000048],
            [-80.913176255999986, 35.113625855000066],
            [-80.913098256999945, 35.113687854000034],
            [-80.91291225599997, 35.113824855000075],
            [-80.912586256999987, 35.114026855000077],
            [-80.911715255999979, 35.114568855000073],
            [-80.911091255999963, 35.114961855000047],
            [-80.910311254999954, 35.115445854000029],
            [-80.909690254999987, 35.115818855000043],
            [-80.909524255999941, 35.115924856000049],
            [-80.909151254999983, 35.116153856000039],
            [-80.908866254999964, 35.116317855000034],
            [-80.908463254999958, 35.116529855000067],
            [-80.907035254999982, 35.117220855000028],
            [-80.906703253999979, 35.117386856000053],
            [-80.905617254999981, 35.117915856000025],
            [-80.90499125499997, 35.118237856000064],
            [-80.904716253999936, 35.118429856000034],
            [-80.905421254999965, 35.119085857000073],
            [-80.90582825499996, 35.119435857000042],
            [-80.90633825499998, 35.119848856000033],
            [-80.90698325599999, 35.120324856000025],
            [-80.907655255999941, 35.120774856000025],
            [-80.90843225499998, 35.121234856000058],
            [-80.909014255999978, 35.121550856000056],
            [-80.909575255999982, 35.121834857000067],
            [-80.910624255999949, 35.122311856000067],
            [-80.910743255999989, 35.122359857000049],
            [-80.912190256999963, 35.122946857000045],
            [-80.912652256999934, 35.123140857000067],
            [-80.912946256999987, 35.123229857000069],
            [-80.913651257999959, 35.123467856000048],
            [-80.914443256999959, 35.123752857000056],
            [-80.914511256999958, 35.123776857000053],
            [-80.91474625799998, 35.123870856000053],
            [-80.91567325699998, 35.124277857000038],
            [-80.916311257999951, 35.124588857000049],
            [-80.916484258999958, 35.124680857000044],
            [-80.916784256999961, 35.124850857000069],
            [-80.916985257999954, 35.124970857000051],
            [-80.917416258999936, 35.125220857000045],
            [-80.917520258999957, 35.125269857000035],
            [-80.917800258999989, 35.12544385700005],
            [-80.918147258999966, 35.125668857000051],
            [-80.918252257999939, 35.125735857000052],
            [-80.918503258999976, 35.125896857000043],
            [-80.918890258999966, 35.126162857000054],
            [-80.919495258999973, 35.126628857000071],
            [-80.920117258999937, 35.127157858000032],
            [-80.920521258999941, 35.12752185800008],
            [-80.920825258999969, 35.12781185700004],
            [-80.921142259999954, 35.12812685800003],
            [-80.921465258999945, 35.128477858000053],
            [-80.921740259999979, 35.128813858000058],
            [-80.92224325899997, 35.129441858000064],
            [-80.922525259999986, 35.129843858000072],
            [-80.922866260999967, 35.130374858000039],
            [-80.923291259999985, 35.131101857000033],
            [-80.923646260999988, 35.131746858000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 72,
        "SolutionID": "PI-28:A",
        "Shape_Length": 0.25664240026987367,
        "Shape_Area": 0.0017154679767870586
      }
    },
    {
      "type": "Feature",
      "id": 73,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.914989253999977, 35.060404843000072],
            [-80.914438253999947, 35.061246843000049],
            [-80.913850254999943, 35.062104843000043],
            [-80.912442252999938, 35.064201844000024],
            [-80.911695253999937, 35.065261844000077],
            [-80.911639252999976, 35.065351844000077],
            [-80.912108252999985, 35.065541844000052],
            [-80.912220252999987, 35.065603845000055],
            [-80.912348253999937, 35.065673844000059],
            [-80.912536252999985, 35.065836844000046],
            [-80.91271025399999, 35.066099845000053],
            [-80.912821254999983, 35.066313845000025],
            [-80.913107254999943, 35.066265844000043],
            [-80.913340253999934, 35.066226844000028],
            [-80.913318253999989, 35.066132845000027],
            [-80.913310253999953, 35.066046844000027],
            [-80.913312253999948, 35.065989844000057],
            [-80.913452253999935, 35.065798844000028],
            [-80.91351025299997, 35.065735844000073],
            [-80.914207254999951, 35.065055844000028],
            [-80.914235253999948, 35.065037844000074],
            [-80.914329253999938, 35.06478084400004],
            [-80.914524253999957, 35.064136844000075],
            [-80.915413254999976, 35.061316843000043],
            [-80.915867253999977, 35.059836843000028],
            [-80.915889253999978, 35.059789843000033],
            [-80.915919254999949, 35.05975284200008],
            [-80.915961254999957, 35.059728843000073],
            [-80.916012253999952, 35.059714843000052],
            [-80.916039254999987, 35.059712843000057],
            [-80.916100253999957, 35.059707843000069],
            [-80.917095254999936, 35.059771843000078],
            [-80.917751255999974, 35.059796843000072],
            [-80.918370255999946, 35.05984284200008],
            [-80.91949825599994, 35.059906842000032],
            [-80.920073255999966, 35.059941843000047],
            [-80.920778255999949, 35.059906842000032],
            [-80.921307255999977, 35.059774843000071],
            [-80.92136725599994, 35.059755843000062],
            [-80.921792255999947, 35.059618843000067],
            [-80.921841256999983, 35.059591842000032],
            [-80.922197256999937, 35.059396842000069],
            [-80.922940256999937, 35.059042842000053],
            [-80.923551255999939, 35.058802843000024],
            [-80.924208256999975, 35.05864284300003],
            [-80.924731256999962, 35.058576842000036],
            [-80.925057256999935, 35.058570843000041],
            [-80.925812256999961, 35.058557842000027],
            [-80.927123256999948, 35.05854484200006],
            [-80.928606257999945, 35.058545842000058],
            [-80.929580257999987, 35.058525842000051],
            [-80.930051257999935, 35.058535842000026],
            [-80.929875258999971, 35.057933842000068],
            [-80.929483257999948, 35.056639842000038],
            [-80.929173257999935, 35.055662841000071],
            [-80.928978257999972, 35.055078841000068],
            [-80.928640257999973, 35.054007842000033],
            [-80.928380257999947, 35.05318284100008],
            [-80.928259257999969, 35.052751841000031],
            [-80.928167257999974, 35.052422841000066],
            [-80.927905257999953, 35.051666841000042],
            [-80.927487256999939, 35.050347840000029],
            [-80.927288256999987, 35.049715841000079],
            [-80.927137257999959, 35.049184841000056],
            [-80.92706325599994, 35.048708840000074],
            [-80.927058257999988, 35.048310841000045],
            [-80.927064256999984, 35.048190840000075],
            [-80.927108256999986, 35.047856840000065],
            [-80.927399256999934, 35.046291839000048],
            [-80.928062256999965, 35.042697839000027],
            [-80.928454256999942, 35.040768838000076],
            [-80.928693256999964, 35.039633838000043],
            [-80.929271257999972, 35.037809838000044],
            [-80.929627256999936, 35.037125838000065],
            [-80.929981257999941, 35.036590837000062],
            [-80.930203257999949, 35.036288837000029],
            [-80.930243257999962, 35.036235837000049],
            [-80.930252257999939, 35.036238838000031],
            [-80.930294257999947, 35.036246837000078],
            [-80.930509257999972, 35.036271837000072],
            [-80.930683256999941, 35.036279837000052],
            [-80.930769256999952, 35.036287837000032],
            [-80.931077257999959, 35.036285837000037],
            [-80.931341257999975, 35.036271838000062],
            [-80.931471257999988, 35.036260838000032],
            [-80.931556257999944, 35.036249837000071],
            [-80.931643257999951, 35.036244837000027],
            [-80.931908257999964, 35.036239838000029],
            [-80.931995256999983, 35.036243838000075],
            [-80.93212325799999, 35.036258838000037],
            [-80.932250257999954, 35.036283837000042],
            [-80.932374257999982, 35.036314838000067],
            [-80.932455258999937, 35.036340837000068],
            [-80.932694258999959, 35.036434837000058],
            [-80.932895257999974, 35.036503837000055],
            [-80.933018257999947, 35.036541837000073],
            [-80.933146258999955, 35.036556837000035],
            [-80.933322257999976, 35.036563837000074],
            [-80.933364257999983, 35.036554838000029],
            [-80.93351925799999, 35.036489837000033],
            [-80.933601257999953, 35.036462837000045],
            [-80.93380925799994, 35.036402837000026],
            [-80.933895258999939, 35.036394837000046],
            [-80.933984257999953, 35.036393838000038],
            [-80.934064258999967, 35.036418838000031],
            [-80.934138258999951, 35.036455837000062],
            [-80.934177258999966, 35.036481837000053],
            [-80.934249258999955, 35.036522838000053],
            [-80.934326257999942, 35.036554838000029],
            [-80.934529257999941, 35.036611838000056],
            [-80.934771258999945, 35.03668583700005],
            [-80.934809258999962, 35.036702837000064],
            [-80.935049258999982, 35.036780837000038],
            [-80.935127258999955, 35.036811837000073],
            [-80.935209258999976, 35.036836837000067],
            [-80.935327258999962, 35.036879837000072],
            [-80.935522258999981, 35.03695983700004],
            [-80.935720258999936, 35.037031837000029],
            [-80.935885258999974, 35.037068837000049],
            [-80.935923258999935, 35.037084837000066],
            [-80.93604425999996, 35.037122837000027],
            [-80.936127259999978, 35.037142837000033],
            [-80.93624725899997, 35.037184837000041],
            [-80.936393258999942, 35.037262837000071],
            [-80.936473259999957, 35.037288837000062],
            [-80.936512259999972, 35.037305837000076],
            [-80.936719258999972, 35.037357837000059],
            [-80.936839258999953, 35.037401837000061],
            [-80.936992258999965, 35.03746983700006],
            [-80.937246258999949, 35.037603837000063],
            [-80.937285258999964, 35.037620838000066],
            [-80.937355260999936, 35.037661838000076],
            [-80.937431259999983, 35.037697837000053],
            [-80.937576258999968, 35.037748837000038],
            [-80.937820259999967, 35.037822838000068],
            [-80.937903258999938, 35.037842838000074],
            [-80.938065259999973, 35.037894838000057],
            [-80.938188259999947, 35.037929838000025],
            [-80.938272260999952, 35.037948837000044],
            [-80.938476259999959, 35.038006837000069],
            [-80.938556259999984, 35.038034838000044],
            [-80.938866259999941, 35.038166837000063],
            [-80.939008260999969, 35.038243838000028],
            [-80.939085260999946, 35.038275837000072],
            [-80.939156259999947, 35.038316837000025],
            [-80.939320260999978, 35.038431837000076],
            [-80.939385259999938, 35.038521837000076],
            [-80.939418260999958, 35.038586837000025],
            [-80.939440259999969, 35.038655838000068],
            [-80.939485259999969, 35.038864837000062],
            [-80.939494259999947, 35.038969838000071],
            [-80.939398259999962, 35.039426838000054],
            [-80.939324260999967, 35.039847838000071],
            [-80.939319259999934, 35.039917838000065],
            [-80.939265260999946, 35.040339838000079],
            [-80.939247260999934, 35.040444837000052],
            [-80.939211259999979, 35.040582838000034],
            [-80.939170259999969, 35.040682838000066],
            [-80.939115259999937, 35.040776838000056],
            [-80.939071259999935, 35.040835838000078],
            [-80.939007259999983, 35.040884838000068],
            [-80.938936259999934, 35.040925838000078],
            [-80.938775260999989, 35.040978838000058],
            [-80.938488259999986, 35.041064838000068],
            [-80.938455259999955, 35.041088838000064],
            [-80.938401260999967, 35.041143838000039],
            [-80.938367260999939, 35.04116583800004],
            [-80.938255259999949, 35.041220838000072],
            [-80.938214259999938, 35.041232838000042],
            [-80.938129259999982, 35.041247838000061],
            [-80.938045259999967, 35.041267838000067],
            [-80.938005259999954, 35.041282838000029],
            [-80.937933259999966, 35.041322838000042],
            [-80.937854259999938, 35.041407838000055],
            [-80.937801259999958, 35.041538838000065],
            [-80.937715259999948, 35.041662839000026],
            [-80.937580259999947, 35.041845838000029],
            [-80.937540259999935, 35.041945838000061],
            [-80.937516259999938, 35.04204783800003],
            [-80.93751825999999, 35.042083838000053],
            [-80.937504259999969, 35.042151839000041],
            [-80.937501259999976, 35.042222838000043],
            [-80.937508259999959, 35.042293838000035],
            [-80.937532258999966, 35.042396839000048],
            [-80.93756725999998, 35.042497838000031],
            [-80.937628260999986, 35.042629838000039],
            [-80.937653259999934, 35.04269683900003],
            [-80.937745259999986, 35.042856839000024],
            [-80.937793259999978, 35.042916838000053],
            [-80.937821260999954, 35.042942838000045],
            [-80.937854259999938, 35.042964838000046],
            [-80.93789425999995, 35.04297783800007],
            [-80.93794625999999, 35.042979839000054],
            [-80.938058260999981, 35.042955838000069],
            [-80.938101259999939, 35.042951839000068],
            [-80.93818725999995, 35.042961839000043],
            [-80.938270259999967, 35.042979838000065],
            [-80.938428260999956, 35.043100839000033],
            [-80.938567259999957, 35.043238838000036],
            [-80.93864425999999, 35.043324838000046],
            [-80.938683259999948, 35.04338683900005],
            [-80.938697259999969, 35.043420838000031],
            [-80.938801259999934, 35.043766839000057],
            [-80.938848259999986, 35.043865839000034],
            [-80.938885260999939, 35.043929838000054],
            [-80.938933260999988, 35.044027838000034],
            [-80.939015259999962, 35.044228838000038],
            [-80.939032260999966, 35.044369839000069],
            [-80.939031260999968, 35.044404838000048],
            [-80.93902425999994, 35.044439839000063],
            [-80.93899726099994, 35.044506839000064],
            [-80.938970260999952, 35.044535838000058],
            [-80.938931260999937, 35.044550839000067],
            [-80.938755260999983, 35.044559838000055],
            [-80.938711259999934, 35.044556838000062],
            [-80.938668260999975, 35.044547839000074],
            [-80.938626259999978, 35.044543839000028],
            [-80.938583260999962, 35.044549839000069],
            [-80.938542259999963, 35.044562839000037],
            [-80.938516259999972, 35.044591839000077],
            [-80.938495260999957, 35.044622839000056],
            [-80.938452259999963, 35.044723839000028],
            [-80.938443259999985, 35.04479483800003],
            [-80.938451259999965, 35.044864838000024],
            [-80.938463260999981, 35.044898839000041],
            [-80.938484260999985, 35.044929839000076],
            [-80.938586260999955, 35.04504483900007],
            [-80.93862126099998, 35.045065839000074],
            [-80.938661260999936, 35.045080839000036],
            [-80.938743259999967, 35.045103839000035],
            [-80.938787260999959, 35.045111839000072],
            [-80.938873260999969, 35.045119839000051],
            [-80.939005259999988, 35.045124839000039],
            [-80.939222260999941, 35.045103838000045],
            [-80.939690260999953, 35.045025839000061],
            [-80.939731261999952, 35.04501583900003],
            [-80.939816259999986, 35.045002839000063],
            [-80.939901259999942, 35.044983839000054],
            [-80.940071260999957, 35.044959839000057],
            [-80.940198261999967, 35.044933839000066],
            [-80.940236260999939, 35.044915839000055],
            [-80.940316260999964, 35.044885839000074],
            [-80.940398260999984, 35.044863838000026],
            [-80.94048326099994, 35.044851838000056],
            [-80.940616260999946, 35.044858839000028],
            [-80.94107126199998, 35.044875839000042],
            [-80.941178261999937, 35.044712839000056],
            [-80.94214526199994, 35.043246838000073],
            [-80.943381261999946, 35.041348838000033],
            [-80.944018261999986, 35.040411838000068],
            [-80.944430261999969, 35.04079383800007],
            [-80.944861261999961, 35.041121838000038],
            [-80.945253262999984, 35.041306838000025],
            [-80.945691261999968, 35.041423838000071],
            [-80.94630226299995, 35.041476838000051],
            [-80.946881262999966, 35.041402838000067],
            [-80.947691262999967, 35.041223838000064],
            [-80.948257263999949, 35.041191838000032],
            [-80.948733262999951, 35.041202837000071],
            [-80.949170262999985, 35.041281837000042],
            [-80.94961426399999, 35.041419837000035],
            [-80.949730262999935, 35.041473837000069],
            [-80.949989263999953, 35.041594838000037],
            [-80.950299263999966, 35.041776838000033],
            [-80.95060226399994, 35.041998837000051],
            [-80.951189263999936, 35.04245983800007],
            [-80.95175726399998, 35.042843837000078],
            [-80.951961263999976, 35.042953837000027],
            [-80.952217263999955, 35.04303883800003],
            [-80.952404264999984, 35.043087838000076],
            [-80.952585264999982, 35.043119838000052],
            [-80.952802263999956, 35.043121838000047],
            [-80.953173264999975, 35.043107838000026],
            [-80.953215263999937, 35.043101838000041],
            [-80.953866264999988, 35.043014838000033],
            [-80.954299264999975, 35.042976838000072],
            [-80.954768265999974, 35.04298183800006],
            [-80.955632264999963, 35.043017838000026],
            [-80.958185264999941, 35.04312483800004],
            [-80.95849926599999, 35.043136838000066],
            [-80.959050265999963, 35.043163838000055],
            [-80.959289266999974, 35.043175838000025],
            [-80.959632265999971, 35.04341983900008],
            [-80.95964326699999, 35.043358838000074],
            [-80.959646266999982, 35.043339838000065],
            [-80.959644266999987, 35.043184838000059],
            [-80.959844265999948, 35.04181883800004],
            [-80.959920265999983, 35.041082837000033],
            [-80.959993266999959, 35.040367837000076],
            [-80.960080265999977, 35.039849837000077],
            [-80.960200265999958, 35.039141837000045],
            [-80.960365266999986, 35.037628836000067],
            [-80.960451266999939, 35.037096837000036],
            [-80.960460265999984, 35.037041837000061],
            [-80.960594266999976, 35.036217836000048],
            [-80.960910266999974, 35.035021835000066],
            [-80.961365265999973, 35.033851835000064],
            [-80.961512266999989, 35.033625836000056],
            [-80.961928266999962, 35.032663835000051],
            [-80.962601266999968, 35.03148983400007],
            [-80.963267266999935, 35.030497835000062],
            [-80.963879266999982, 35.029639834000079],
            [-80.964316267999948, 35.028995834000057],
            [-80.964751267999986, 35.028441835000024],
            [-80.964910266999937, 35.028199834000077],
            [-80.964985266999975, 35.028087835000065],
            [-80.965128266999955, 35.027896834000046],
            [-80.965333267999938, 35.028104834000032],
            [-80.965825267999946, 35.028657835000047],
            [-80.966062267999973, 35.028947834000064],
            [-80.966421267999976, 35.029384834000041],
            [-80.966790267999954, 35.029762835000042],
            [-80.966930268999988, 35.029922835000036],
            [-80.96736526899997, 35.030421835000027],
            [-80.967721267999934, 35.030810835000068],
            [-80.967896266999958, 35.03100583500003],
            [-80.968310268999971, 35.03146683500006],
            [-80.968529267999941, 35.031709835000072],
            [-80.968899267999973, 35.032133835000025],
            [-80.969400267999958, 35.032694835000029],
            [-80.969583268999941, 35.03281783500006],
            [-80.969834268999989, 35.032994835000068],
            [-80.97083626999995, 35.032332835000034],
            [-80.971888269999965, 35.031637835000026],
            [-80.973203268999953, 35.030654834000075],
            [-80.973662269999977, 35.030282834000047],
            [-80.973981269999967, 35.029825835000054],
            [-80.974217268999951, 35.029467834000059],
            [-80.974330269999939, 35.028814834000059],
            [-80.97434426999996, 35.028624834000027],
            [-80.974362269999972, 35.028381834000072],
            [-80.974328269999944, 35.027979834000064],
            [-80.974315268999987, 35.027821834000065],
            [-80.974252269999965, 35.027200833000052],
            [-80.974238269999944, 35.027057834000061],
            [-80.974038269999937, 35.025312833000044],
            [-80.974069269999973, 35.024932834000026],
            [-80.974193269999944, 35.024541834000047],
            [-80.974212269999953, 35.024511834000066],
            [-80.974403269999982, 35.024213833000033],
            [-80.974566269999968, 35.024007833000042],
            [-80.974791269999969, 35.023821832000067],
            [-80.975296269999944, 35.023506833000056],
            [-80.975682269999936, 35.023265833000039],
            [-80.975871269999971, 35.023161833000074],
            [-80.976505270999951, 35.022790833000045],
            [-80.977229269999953, 35.022309832000076],
            [-80.977436270999988, 35.022136833000047],
            [-80.977688270999977, 35.021940832000041],
            [-80.978015270999947, 35.021654833000071],
            [-80.978965271999982, 35.020816833000026],
            [-80.979247270999963, 35.02047783200004],
            [-80.979416270999934, 35.020277832000033],
            [-80.97975827099998, 35.01960383200003],
            [-80.979838270999949, 35.019392832000051],
            [-80.980071270999986, 35.018891832000065],
            [-80.980256270999973, 35.018456832000027],
            [-80.980415271999959, 35.018103832000065],
            [-80.980603271999939, 35.017645831000038],
            [-80.980785270999945, 35.017243832000077],
            [-80.981077271999936, 35.016538831000048],
            [-80.981147271999987, 35.016356831000053],
            [-80.98143227099996, 35.015714831000025],
            [-80.981794271999945, 35.014852831000042],
            [-80.981899270999975, 35.01433583000005],
            [-80.981905271999949, 35.014146831000062],
            [-80.981916272999968, 35.013769831000047],
            [-80.981863271999941, 35.013362831000052],
            [-80.981803271999979, 35.013154831000065],
            [-80.981601270999988, 35.012730830000066],
            [-80.981231271999945, 35.01211083000004],
            [-80.980955270999971, 35.011511830000074],
            [-80.980839270999979, 35.010867830000052],
            [-80.980857270999934, 35.010666830000048],
            [-80.98082027099997, 35.010180830000024],
            [-80.980781270999955, 35.009359830000051],
            [-80.980756270999962, 35.008690829000045],
            [-80.98074926999999, 35.008230830000059],
            [-80.980712270999959, 35.008055829000057],
            [-80.980658270999982, 35.00669982900007],
            [-80.980575269999974, 35.005568829000026],
            [-80.980429270999934, 35.004777828000044],
            [-80.980377270999952, 35.004563829000062],
            [-80.980240270999957, 35.004052828000056],
            [-80.980166269999984, 35.003746829000079],
            [-80.980030270999976, 35.003194828000062],
            [-80.979937269999937, 35.002948828000058],
            [-80.979917270999977, 35.002764828000068],
            [-80.979825269999935, 35.002500828000052],
            [-80.979487270999982, 35.00175082800007],
            [-80.979425269999979, 35.00161182800008],
            [-80.979347269999948, 35.001458828000068],
            [-80.979223270999967, 35.001229828000078],
            [-80.979033269999945, 35.000830828000062],
            [-80.97898626999995, 35.00073082800003],
            [-80.978887269999973, 35.000568828000041],
            [-80.978607268999951, 35.000110828000061],
            [-80.978402269999947, 34.999878827000032],
            [-80.978086269999949, 34.999520828000072],
            [-80.977929269999947, 34.999381828000026],
            [-80.977705269999944, 34.999181828000076],
            [-80.977561269999967, 34.999083827000049],
            [-80.977455268999961, 34.99901182800005],
            [-80.977232269999945, 34.998859827000047],
            [-80.97672326899999, 34.99855382700008],
            [-80.976156268999944, 34.998231827000041],
            [-80.976431268999988, 34.997797827000056],
            [-80.977612268999962, 34.996036827000069],
            [-80.978239268999971, 34.995121827000048],
            [-80.978861268999935, 34.994196827000053],
            [-80.979399269999988, 34.993360826000071],
            [-80.97945926999995, 34.993277827000043],
            [-80.980068269999947, 34.992435827000065],
            [-80.980635268999947, 34.99157582600003],
            [-80.980870268999979, 34.991218826000079],
            [-80.98181526999997, 34.989809826000055],
            [-80.98256226999996, 34.988703825000073],
            [-80.982866269999988, 34.988240825000048],
            [-80.983313270999986, 34.987574825000024],
            [-80.983357270999988, 34.987510825000072],
            [-80.983400270999937, 34.987445825000066],
            [-80.983757270999945, 34.986917825000035],
            [-80.983784270999934, 34.986879825000074],
            [-80.986699271999953, 34.982567824000057],
            [-80.987254270999983, 34.981746824000027],
            [-80.987631271999987, 34.981142823000027],
            [-80.987672271999941, 34.981089823000048],
            [-80.987897271999941, 34.980592823000052],
            [-80.988101271999938, 34.97993282300007],
            [-80.988272270999971, 34.97899882300004],
            [-80.988291271999969, 34.978831823000064],
            [-80.988308271999983, 34.978543822000063],
            [-80.988307271999986, 34.97825582300004],
            [-80.988289271999975, 34.977967823000029],
            [-80.988251271999957, 34.977681822000079],
            [-80.988196270999936, 34.977396823000049],
            [-80.988122270999952, 34.977114823000079],
            [-80.988040271999978, 34.976839823000034],
            [-80.987346271999968, 34.976838823000037],
            [-80.987052270999982, 34.976824822000026],
            [-80.986787270999969, 34.976796823000029],
            [-80.986568270999953, 34.976756823000073],
            [-80.986528270999941, 34.976749822000045],
            [-80.986265270999979, 34.976681823000035],
            [-80.986010271999987, 34.976594823000028],
            [-80.985765270999934, 34.976488823000068],
            [-80.98539126999998, 34.976250822000054],
            [-80.984080269999936, 34.975415822000059],
            [-80.983805269999948, 34.975240823000036],
            [-80.983468269999946, 34.975025823000067],
            [-80.982901269999957, 34.974665822000077],
            [-80.98204026999997, 34.974022822000052],
            [-80.982000269999958, 34.973992822000071],
            [-80.981573268999966, 34.973580822000031],
            [-80.981030268999973, 34.973007822000056],
            [-80.98096426799998, 34.97293882200006],
            [-80.980643269999973, 34.972639822000076],
            [-80.980623268999977, 34.97262382200006],
            [-80.980410268999947, 34.972453822000034],
            [-80.979654269999969, 34.971919822000075],
            [-80.978855267999961, 34.971368822000045],
            [-80.976568267999937, 34.969756821000033],
            [-80.976061267999967, 34.969385822000049],
            [-80.975956267999948, 34.969304821000037],
            [-80.975416267999947, 34.968887821000067],
            [-80.974640266999984, 34.968251821000024],
            [-80.973905266999964, 34.967643821000024],
            [-80.973429266999972, 34.967248820000066],
            [-80.972857266999938, 34.966782820000049],
            [-80.972895265999966, 34.966384821000076],
            [-80.973030265999967, 34.965636821000032],
            [-80.973357265999937, 34.964361820000079],
            [-80.973494266999978, 34.963864820000026],
            [-80.973828265999941, 34.962654820000068],
            [-80.974257265999938, 34.960866820000035],
            [-80.974646265999979, 34.959449819000042],
            [-80.974781265999979, 34.958251819000054],
            [-80.974476266999943, 34.95679381900004],
            [-80.97380226699994, 34.955353818000049],
            [-80.973128265999947, 34.953874818000031],
            [-80.972415265999985, 34.953154817000041],
            [-80.971174264999945, 34.952778817000024],
            [-80.969977264999955, 34.95296281800006],
            [-80.969147263999957, 34.953479818000062],
            [-80.969009263999965, 34.953565818000072],
            [-80.968141264999986, 34.954303818000028],
            [-80.967482264999944, 34.954806818000066],
            [-80.966070263999939, 34.956217819000074],
            [-80.964417263999962, 34.957914819000052],
            [-80.963948263999953, 34.958390819000044],
            [-80.96358526399996, 34.958758820000071],
            [-80.962774262999972, 34.959600820000048],
            [-80.962706263999962, 34.95967182000004],
            [-80.960551262999957, 34.961875820000046],
            [-80.960518261999937, 34.961910820000071],
            [-80.960483262999958, 34.961945820000039],
            [-80.960383262999983, 34.962047820000066],
            [-80.959785262999958, 34.962659820000056],
            [-80.959756262999974, 34.962632821000057],
            [-80.959599261999983, 34.96230182000005],
            [-80.959106261999978, 34.961773820000076],
            [-80.958958261999953, 34.961614820000079],
            [-80.958332261999942, 34.961286820000055],
            [-80.957844261999981, 34.961141821000069],
            [-80.957706260999942, 34.961129820000053],
            [-80.957187260999945, 34.961083820000056],
            [-80.957142260999944, 34.961073820000024],
            [-80.957035260999987, 34.961071821000075],
            [-80.956074260999969, 34.961064821000036],
            [-80.955052259999945, 34.960858820000055],
            [-80.953694260999953, 34.960583820000068],
            [-80.952777260999937, 34.960034820000033],
            [-80.951785259999951, 34.959454820000076],
            [-80.948775258999945, 34.956501820000028],
            [-80.948688258999937, 34.956416819000026],
            [-80.948077258999945, 34.955837820000056],
            [-80.946933257999945, 34.954982819000065],
            [-80.945438257999967, 34.954295820000027],
            [-80.943759256999954, 34.953464819000033],
            [-80.942233255999952, 34.953014819000032],
            [-80.941780256999948, 34.952937819000056],
            [-80.941302255999972, 34.952861819000077],
            [-80.941285255999958, 34.952861819000077],
            [-80.940828255999975, 34.952834819000032],
            [-80.940449255999965, 34.952836818000037],
            [-80.940097255999945, 34.95286081900008],
            [-80.939879255999983, 34.952887819000068],
            [-80.939660255999968, 34.952908819000072],
            [-80.93926225599995, 34.952914819000057],
            [-80.938815255999941, 34.952975819000073],
            [-80.93799325599997, 34.953171819000033],
            [-80.93791525499995, 34.953189819000045],
            [-80.937595254999962, 34.953225819000068],
            [-80.936740254999961, 34.953322820000039],
            [-80.935886254999957, 34.95342081900003],
            [-80.935834254999975, 34.953401819000078],
            [-80.934573253999986, 34.953304819000039],
            [-80.932940253999959, 34.952968820000024],
            [-80.93182225299995, 34.95261682000006],
            [-80.929907251999964, 34.952014820000045],
            [-80.928160251999941, 34.951464820000069],
            [-80.927675251999972, 34.951312819000066],
            [-80.927251251999962, 34.951209819000042],
            [-80.92613425199994, 34.950938820000033],
            [-80.924486250999962, 34.950671818000046],
            [-80.923081250999985, 34.950568819000068],
            [-80.922396250999952, 34.950519819000078],
            [-80.922139249999987, 34.950508820000039],
            [-80.921655250999947, 34.950487820000035],
            [-80.921171249999986, 34.950467819000039],
            [-80.920244249999939, 34.950428819000024],
            [-80.92012724999995, 34.950405819000025],
            [-80.918703248999975, 34.950131819000035],
            [-80.918643249999946, 34.950332820000028],
            [-80.918527248999965, 34.950723819000075],
            [-80.91841224999996, 34.951114819000054],
            [-80.918313248999937, 34.951103819000025],
            [-80.918171248999954, 34.951081819000024],
            [-80.916864248999957, 34.952857820000077],
            [-80.91721124999998, 34.953132820000064],
            [-80.917432248999944, 34.953298820000043],
            [-80.913241247999963, 34.962145822000025],
            [-80.910867247999988, 34.967158823000034],
            [-80.91079624799994, 34.967059823000056],
            [-80.910639246999949, 34.966866823000032],
            [-80.91042824699997, 34.966651823000063],
            [-80.910406246999969, 34.966632823000054],
            [-80.910171246999937, 34.966423823000071],
            [-80.909863246999976, 34.966205823000053],
            [-80.909594246999973, 34.966042823000066],
            [-80.909345246999976, 34.965912823000053],
            [-80.909001246999935, 34.965766823000024],
            [-80.908677246999957, 34.965659823000067],
            [-80.908309246999977, 34.965559823000035],
            [-80.907908246999966, 34.965482823000059],
            [-80.907583246999934, 34.965443823000044],
            [-80.906804246999968, 34.965319823000073],
            [-80.906446246999963, 34.965233823000062],
            [-80.906065245999969, 34.965120823000063],
            [-80.905493245999935, 34.96490882300003],
            [-80.905089245999989, 34.964701823000041],
            [-80.904748246999986, 34.96449982200005],
            [-80.904210245999934, 34.964105823000068],
            [-80.903742245999979, 34.963739823000026],
            [-80.903411245999962, 34.963494823000076],
            [-80.901857244999974, 34.96227882200003],
            [-80.900624243999971, 34.96133582300007],
            [-80.900331243999972, 34.96111082200008],
            [-80.900131243999965, 34.960958823000055],
            [-80.89994924399997, 34.960812822000037],
            [-80.899385244999962, 34.960418823000055],
            [-80.898708242999987, 34.959923822000064],
            [-80.898387243999935, 34.95972282200006],
            [-80.898098243999982, 34.959541823000052],
            [-80.897287243999983, 34.959042822000072],
            [-80.89644924299995, 34.958519821000039],
            [-80.895758242999989, 34.958108821000053],
            [-80.895359241999984, 34.957905823000033],
            [-80.89514724299994, 34.95775382100004],
            [-80.894264242999952, 34.957332822000069],
            [-80.893825242999981, 34.957189821000043],
            [-80.893381241999975, 34.957081822000077],
            [-80.892898241999944, 34.956990821000034],
            [-80.89213724199999, 34.956923822000078],
            [-80.890897241999937, 34.956839822000063],
            [-80.890259241999956, 34.956845822000048],
            [-80.88961723999995, 34.956878821000032],
            [-80.887978240999985, 34.956982822000043],
            [-80.887511239999981, 34.956996822000065],
            [-80.887358239999969, 34.957000822000055],
            [-80.886818240999958, 34.956970822000073],
            [-80.886366239999973, 34.956911821000062],
            [-80.886016239999947, 34.956856822000077],
            [-80.885304239999982, 34.956669822000038],
            [-80.88499823899997, 34.956571822000058],
            [-80.884580239999934, 34.956452822000074],
            [-80.883773238999936, 34.956147822000048],
            [-80.883481238999934, 34.956045822000078],
            [-80.882838239999955, 34.955792822000035],
            [-80.88225923899995, 34.955525822000027],
            [-80.87986923699998, 34.954280822000044],
            [-80.879192236999984, 34.953959821000069],
            [-80.87887823799997, 34.953864822000071],
            [-80.87770123699994, 34.953587822000031],
            [-80.876505236999947, 34.953403821000052],
            [-80.874239235999937, 34.95300282200003],
            [-80.873864235999974, 34.952941821000024],
            [-80.873328235999963, 34.952846822000026],
            [-80.872908235999944, 34.952751821000049],
            [-80.872447234999981, 34.952606821000074],
            [-80.871997234999981, 34.95242182100003],
            [-80.871406235999984, 34.95212482200003],
            [-80.870859234999955, 34.951858822000077],
            [-80.86873323399999, 34.95078482100007],
            [-80.868760234999968, 34.95064182200008],
            [-80.868833234999954, 34.950509821000026],
            [-80.869040234999943, 34.950317821000056],
            [-80.869340234999981, 34.950086820000024],
            [-80.869607235999979, 34.949838821000071],
            [-80.869767234999983, 34.949624821000043],
            [-80.869894234999947, 34.94940482100003],
            [-80.86998723499994, 34.949157821000028],
            [-80.870054234999941, 34.948799820000033],
            [-80.870140234999951, 34.948156820000065],
            [-80.870160234999958, 34.947689820000051],
            [-80.870145234999939, 34.947362820000023],
            [-80.869959234999953, 34.947288821000029],
            [-80.86997923499996, 34.947608821000074],
            [-80.869973234999975, 34.947730820000061],
            [-80.869976234999967, 34.947846820000052],
            [-80.869957234999958, 34.948145821000026],
            [-80.869866233999971, 34.948785821000058],
            [-80.869807234999939, 34.949030822000054],
            [-80.869776234999961, 34.94913482000004],
            [-80.869699234999985, 34.949339821000024],
            [-80.86964523499995, 34.949453821000077],
            [-80.869394233999969, 34.949634821000075],
            [-80.869125234999956, 34.949890821000054],
            [-80.869051233999983, 34.949967822000076],
            [-80.868928233999952, 34.950106821000077],
            [-80.868897233999974, 34.950149820000036],
            [-80.868739234999964, 34.950401821000071],
            [-80.868593234999935, 34.950717821000069],
            [-80.86858323499996, 34.950773821000041],
            [-80.86858323499996, 34.950789821000058],
            [-80.868607233999967, 34.950984821000077],
            [-80.868655233999959, 34.951149821000058],
            [-80.868834233999962, 34.951455821000025],
            [-80.868853233999971, 34.951475821000031],
            [-80.86886823399999, 34.95149882100003],
            [-80.868994234999946, 34.951645822000046],
            [-80.869045234999987, 34.951739821000047],
            [-80.869091234999985, 34.951807821000045],
            [-80.869160233999935, 34.95189182200005],
            [-80.869272234999983, 34.952008822000039],
            [-80.869332234999945, 34.952061821000029],
            [-80.869476234999979, 34.952178821000075],
            [-80.869874234999941, 34.95244282200008],
            [-80.869905234999976, 34.952459821000048],
            [-80.870721233999973, 34.952992821000066],
            [-80.870908235999934, 34.953109821000055],
            [-80.871432234999986, 34.953371821000076],
            [-80.87143823599996, 34.953375822000055],
            [-80.871851235999941, 34.953631822000034],
            [-80.872217235999983, 34.953941822000047],
            [-80.872335235999969, 34.954046822000066],
            [-80.872392235999939, 34.954106822000028],
            [-80.872640235999938, 34.954367822000052],
            [-80.872840235999945, 34.954603822000024],
            [-80.872930235999945, 34.954725822000057],
            [-80.873271235999937, 34.955225822000045],
            [-80.873445235999952, 34.955473822000044],
            [-80.873498235999989, 34.955571822000024],
            [-80.873609235999936, 34.95588082200004],
            [-80.873642236999956, 34.955983822000064],
            [-80.873692235999954, 34.956082822000042],
            [-80.873886236999965, 34.956704822000063],
            [-80.873944236999989, 34.957022823000045],
            [-80.873943236999935, 34.957271822000052],
            [-80.873942235999948, 34.957280823000076],
            [-80.873938235999958, 34.957342822000044],
            [-80.873923236999985, 34.957412822000038],
            [-80.873871236999946, 34.957586823000042],
            [-80.873825236999949, 34.957760822000068],
            [-80.873780236999949, 34.95789882300005],
            [-80.873698235999939, 34.95810182200006],
            [-80.873581236999939, 34.958331823000037],
            [-80.873535235999952, 34.95840882300007],
            [-80.873375235999958, 34.958685823000053],
            [-80.873215235999965, 34.958898824000073],
            [-80.872893235999982, 34.959280823000029],
            [-80.872791235999955, 34.959438823000028],
            [-80.872737236999967, 34.959573823000028],
            [-80.872728235999944, 34.959608823000053],
            [-80.872713235999981, 34.959750823000036],
            [-80.872696235999967, 34.960333823000042],
            [-80.872693236999964, 34.960369823000065],
            [-80.872696235999967, 34.960654824000073],
            [-80.87268723599999, 34.960974823000072],
            [-80.872668236999971, 34.961293823000062],
            [-80.872638235999943, 34.96147082400006],
            [-80.872632235999959, 34.961541823000061],
            [-80.87263523699994, 34.961755823000033],
            [-80.872669235999979, 34.961858823000057],
            [-80.872720235999964, 34.961957823000034],
            [-80.872821236999982, 34.96211582400008],
            [-80.873046235999936, 34.962403824000035],
            [-80.873048235999988, 34.962405824000029],
            [-80.873174236999944, 34.962566824000078],
            [-80.873180235999939, 34.962575824000055],
            [-80.873218235999957, 34.962628823000045],
            [-80.873413235999976, 34.96294882400008],
            [-80.873502236999968, 34.963134824000065],
            [-80.873508236999953, 34.963147824000032],
            [-80.873527236999962, 34.963216824000028],
            [-80.873568236999972, 34.963340824000056],
            [-80.873604236999938, 34.963445824000075],
            [-80.873620236999955, 34.96349282400007],
            [-80.873650235999946, 34.963595824000038],
            [-80.873673236999934, 34.963701824000054],
            [-80.873678236999979, 34.963771824000048],
            [-80.873684235999974, 34.963843824000037],
            [-80.873695236999936, 34.963882824000052],
            [-80.87370423599998, 34.963912824000033],
            [-80.87374523699998, 34.96409682400008],
            [-80.873767236999981, 34.964192824000065],
            [-80.873841236999965, 34.964579823000065],
            [-80.873891236999953, 34.964740824000046],
            [-80.873917236999944, 34.964820824000071],
            [-80.873930236999968, 34.964890824000065],
            [-80.873947236999982, 34.964953824000077],
            [-80.873949237999966, 34.964960824000059],
            [-80.873977235999973, 34.965044824000074],
            [-80.873996236999972, 34.965097824000054],
            [-80.874057235999942, 34.965191824000044],
            [-80.874139236999952, 34.965275825000049],
            [-80.874141236999947, 34.965276824000057],
            [-80.874171236999985, 34.965300824000053],
            [-80.874221236999972, 34.965331824000032],
            [-80.874307237999972, 34.965345825000043],
            [-80.87443923699999, 34.965360824000072],
            [-80.874483236999936, 34.965359824000075],
            [-80.874742236999964, 34.96531682300008],
            [-80.874783236999974, 34.965307824000035],
            [-80.874826236999979, 34.965297823000071],
            [-80.875042236999946, 34.965258824000045],
            [-80.875249236999935, 34.965200824000078],
            [-80.875372237999954, 34.965160823000076],
            [-80.875451236999936, 34.96512982400003],
            [-80.875525237999966, 34.965091824000069],
            [-80.875681236999981, 34.965024823000078],
            [-80.875823237999953, 34.964923825000028],
            [-80.876012237999987, 34.964788824000038],
            [-80.876210237999942, 34.964630824000039],
            [-80.876294237999957, 34.964563823000049],
            [-80.876445237999974, 34.964435825000066],
            [-80.876572237999937, 34.964335824000045],
            [-80.876639237999939, 34.964289824000048],
            [-80.876753237999935, 34.96423582400007],
            [-80.876825236999935, 34.964195824000058],
            [-80.876861237999947, 34.964177824000046],
            [-80.876975237999943, 34.964120824000076],
            [-80.877079236999975, 34.964057824000065],
            [-80.877147237999964, 34.964011824000067],
            [-80.877210237999975, 34.96396182400008],
            [-80.877294237999934, 34.963880823000068],
            [-80.877407237999989, 34.963728824000043],
            [-80.877470237999944, 34.963633824000055],
            [-80.877520237999988, 34.963575824000031],
            [-80.87758023799995, 34.963524823000057],
            [-80.877617237999971, 34.963505823000048],
            [-80.877711237999961, 34.963473823000072],
            [-80.877717238999935, 34.963479824000046],
            [-80.877764237999941, 34.963518824000062],
            [-80.877781236999965, 34.963530823000042],
            [-80.877819236999983, 34.963550823000048],
            [-80.877902236999944, 34.963576824000029],
            [-80.877989237999941, 34.963586823000071],
            [-80.878076236999959, 34.963580825000065],
            [-80.878140237999958, 34.963573823000047],
            [-80.878182238999955, 34.963564824000059],
            [-80.878221237999981, 34.96354982400004],
            [-80.87827823899994, 34.963525823000055],
            [-80.878295237999964, 34.963515824000069],
            [-80.878359238999963, 34.963468823000028],
            [-80.878388236999967, 34.963443824000024],
            [-80.878427237999972, 34.963401823000027],
            [-80.878481237999949, 34.963348823000047],
            [-80.878525237999952, 34.963310823000029],
            [-80.87869823799997, 34.963180824000062],
            [-80.878748237999957, 34.963148824000029],
            [-80.87878823799997, 34.963135824000062],
            [-80.878871236999942, 34.963117824000051],
            [-80.878891237999937, 34.963110824000069],
            [-80.87892323799997, 34.963088824000067],
            [-80.878952237999954, 34.963062824000076],
            [-80.878988237999977, 34.963019824000071],
            [-80.879053237999983, 34.962951824000072],
            [-80.879100238999968, 34.962915824000049],
            [-80.879134237999949, 34.962894824000045],
            [-80.879170237999972, 34.962876824000034],
            [-80.879225237999947, 34.962851823000051],
            [-80.879304237999975, 34.962822823000067],
            [-80.879342237999936, 34.962807823000048],
            [-80.879402238999944, 34.962787823000042],
            [-80.87943723799998, 34.962768823000033],
            [-80.879506238999966, 34.962726824000072],
            [-80.879589238999984, 34.962672823000048],
            [-80.879741238999941, 34.962577823000061],
            [-80.879845238999962, 34.962521824000078],
            [-80.880772238999953, 34.964879824000036],
            [-80.880739238999979, 34.964967823000052],
            [-80.880731238999942, 34.965002824000067],
            [-80.880731238999942, 34.965108824000026],
            [-80.88071623899998, 34.965251824000063],
            [-80.880697238999971, 34.96532082300007],
            [-80.880646238999987, 34.965456824000057],
            [-80.880626237999934, 34.965525825000043],
            [-80.880615238999951, 34.96559482400005],
            [-80.880614238999954, 34.965630824000073],
            [-80.880621238999936, 34.965665824000041],
            [-80.880648237999935, 34.965732824000042],
            [-80.880709238999941, 34.965825824000035],
            [-80.880860237999968, 34.965998824000053],
            [-80.880941237999934, 34.966081824000071],
            [-80.880973238999957, 34.966106824000065],
            [-80.881026238999937, 34.966161824000039],
            [-80.881468238999958, 34.96655482500006],
            [-80.88159823899997, 34.966650824000055],
            [-80.881668239999954, 34.966692824000063],
            [-80.881743238999945, 34.966729824000026],
            [-80.881792238999935, 34.96673882500005],
            [-80.881849238999962, 34.966739825000047],
            [-80.881926238999938, 34.966733825000063],
            [-80.881979239999964, 34.966740824000055],
            [-80.882014238999943, 34.966739825000047],
            [-80.882074238999962, 34.966726824000034],
            [-80.882096238999964, 34.966721824000047],
            [-80.882132238999986, 34.966703824000035],
            [-80.882160239999962, 34.966677825000033],
            [-80.882234239999946, 34.966592824000031],
            [-80.882276239999953, 34.966532824000069],
            [-80.882301239999947, 34.966398824000066],
            [-80.882303239999942, 34.966263824000066],
            [-80.882327238999949, 34.966196823000075],
            [-80.882371239999941, 34.966101823000031],
            [-80.882434239999952, 34.966012824000074],
            [-80.882502238999962, 34.965885824000054],
            [-80.882504239999946, 34.965851824000026],
            [-80.882497238999974, 34.965784824000025],
            [-80.882464238999944, 34.965584824000075],
            [-80.882479238999963, 34.96541482300006],
            [-80.88248423899995, 34.96527982300006],
            [-80.882468238999934, 34.965201824000076],
            [-80.882463239999936, 34.965181824000069],
            [-80.882456238999964, 34.965113824000071],
            [-80.88251723999997, 34.965092824000067],
            [-80.882643239999936, 34.965060824000034],
            [-80.882723238999972, 34.965030824000053],
            [-80.882853239999974, 34.965012823000052],
            [-80.882985239999982, 34.965012823000052],
            [-80.883029239999985, 34.965015824000034],
            [-80.883151238999972, 34.965055824000046],
            [-80.883330239999964, 34.965158824000071],
            [-80.88368023999999, 34.965376823000042],
            [-80.883826239999962, 34.96545582400006],
            [-80.884028239999964, 34.965592824000055],
            [-80.884204239999974, 34.965700824000066],
            [-80.884294239999974, 34.96577882400004],
            [-80.884400239999934, 34.965892824000036],
            [-80.884496240999965, 34.966012824000074],
            [-80.884536240999978, 34.966076824000027],
            [-80.884572239999954, 34.966123824000078],
            [-80.884590239999966, 34.966147824000075],
            [-80.884585239999979, 34.966155823000065],
            [-80.884533239999939, 34.966264824000064],
            [-80.884476239999969, 34.966465824000068],
            [-80.884398239999939, 34.966698825000037],
            [-80.884360239999978, 34.966862824000032],
            [-80.884243239999989, 34.967261825000037],
            [-80.884192240999937, 34.967464824000047],
            [-80.884186239999963, 34.967593825000051],
            [-80.88419523999994, 34.967637823000075],
            [-80.884231240999952, 34.967765824000026],
            [-80.884261239999944, 34.967834824000079],
            [-80.884256239999957, 34.967896825000025],
            [-80.884243239999989, 34.967975824000064],
            [-80.88423623999995, 34.968137825000042],
            [-80.88424023999994, 34.968291824000062],
            [-80.88413424099997, 34.968447824000066],
            [-80.884106239999937, 34.968474824000054],
            [-80.884012239999947, 34.968549825000025],
            [-80.883848239999963, 34.968666824000024],
            [-80.883594239999979, 34.968863825000028],
            [-80.883478238999942, 34.968970825000042],
            [-80.883263239999962, 34.96919682500004],
            [-80.883189239999979, 34.969325824000066],
            [-80.883160239999938, 34.969392824000067],
            [-80.883096240999976, 34.969597825000051],
            [-80.883081240999957, 34.969666824000058],
            [-80.882987238999988, 34.969903824000028],
            [-80.882875239999976, 34.970055825000031],
            [-80.882792238999969, 34.97013882400006],
            [-80.882518238999978, 34.970318825000049],
            [-80.882413239999948, 34.970433824000054],
            [-80.882392239999945, 34.970464826000068],
            [-80.882363238999972, 34.970568825000043],
            [-80.882358239999974, 34.970603824000079],
            [-80.882359239999971, 34.970639825000035],
            [-80.882381239999972, 34.970744825000054],
            [-80.882396239999935, 34.970788825000056],
            [-80.882416239999941, 34.970846825000024],
            [-80.882493239999974, 34.970974825000042],
            [-80.882605239999975, 34.971128825000051],
            [-80.882712239999989, 34.971240826000042],
            [-80.882759239999984, 34.971300825000071],
            [-80.882787239999971, 34.971328825000057],
            [-80.882913239999937, 34.971516826000027],
            [-80.883021239999948, 34.971628825000039],
            [-80.883063239999956, 34.97169182500005],
            [-80.883130239999957, 34.971861825000076],
            [-80.883177239999952, 34.971941826000034],
            [-80.88329324099999, 34.971895825000047],
            [-80.883341239999936, 34.972027825000055],
            [-80.883441239999968, 34.972175826000068],
            [-80.88353423999996, 34.972324825000044],
            [-80.883654239999942, 34.972500825000054],
            [-80.88390124099999, 34.972808825000072],
            [-80.883948240999985, 34.972901826000054],
            [-80.884008239999957, 34.97300582500003],
            [-80.884062239999935, 34.973088825000048],
            [-80.884075240999948, 34.973236825000072],
            [-80.88404823999997, 34.973418826000056],
            [-80.883887239999979, 34.973698826000032],
            [-80.883821240999964, 34.97383082500005],
            [-80.883827240999949, 34.973989826000036],
            [-80.88389424099995, 34.974116826000056],
            [-80.884415240999942, 34.974375825000038],
            [-80.88453524099998, 34.974490825000032],
            [-80.884595240999943, 34.974605826000072],
            [-80.884601240999984, 34.974737825000034],
            [-80.884488239999939, 34.974809826000069],
            [-80.884348240999941, 34.974875825000026],
            [-80.884234239999955, 34.975084826000057],
            [-80.884207239999967, 34.975183826000034],
            [-80.884227239999973, 34.975276826000027],
            [-80.884274239999968, 34.975359827000034],
            [-80.884434240999951, 34.975551827000061],
            [-80.884527239999954, 34.975633826000035],
            [-80.884621240999934, 34.975699826000039],
            [-80.884774240999946, 34.975777826000069],
            [-80.884915241999977, 34.975837826000031],
            [-80.885409240999934, 34.976030826000056],
            [-80.885716239999965, 34.976206826000066],
            [-80.885909240999979, 34.976382826000076],
            [-80.886009240999954, 34.976563826000074],
            [-80.886056240999949, 34.976712826000039],
            [-80.886043240999982, 34.976975827000047],
            [-80.885982240999965, 34.977173826000069],
            [-80.885989240999947, 34.977261827000063],
            [-80.886042241999974, 34.977393826000025],
            [-80.886089240999979, 34.977476827000032],
            [-80.88615624199997, 34.977663826000025],
            [-80.886216240999943, 34.977910827000073],
            [-80.886202240999978, 34.978047827000069],
            [-80.886189240999954, 34.97813082700003],
            [-80.886162240999965, 34.978218827000035],
            [-80.886062240999934, 34.97868582600006],
            [-80.886081240999943, 34.978823827000042],
            [-80.886121240999955, 34.978927827000064],
            [-80.88616824099995, 34.979015827000069],
            [-80.886342240999966, 34.979290827000057],
            [-80.886368241999946, 34.979427827000052],
            [-80.886455240999965, 34.979592826000044],
            [-80.886542240999972, 34.979746827000042],
            [-80.886608240999976, 34.980076827000062],
            [-80.886635241999954, 34.980236826000066],
            [-80.886635240999965, 34.980439827000055],
            [-80.886615240999959, 34.980554827000049],
            [-80.886615240999959, 34.980637827000066],
            [-80.886641241999939, 34.980730827000059],
            [-80.88671524099999, 34.980912827000054],
            [-80.88676824199996, 34.981016827000076],
            [-80.886901240999975, 34.98136882700004],
            [-80.886961241999984, 34.981506827000032],
            [-80.88698124199999, 34.981605827000067],
            [-80.88700824099999, 34.981692827000074],
            [-80.887248241999941, 34.982000828000025],
            [-80.887322240999936, 34.982105828000044],
            [-80.887435241999981, 34.982292828000027],
            [-80.887535241999956, 34.982495828000026],
            [-80.887582241999951, 34.982572828000059],
            [-80.887802242999953, 34.982836827000028],
            [-80.887902241999939, 34.982974827000078],
            [-80.88796924199994, 34.983084827000027],
            [-80.888022241999977, 34.983155828000065],
            [-80.888089241999978, 34.983315828000059],
            [-80.888162241999964, 34.983430828000053],
            [-80.888162241999964, 34.983568828000045],
            [-80.888109242999974, 34.983699828000056],
            [-80.88806224199999, 34.983787827000072],
            [-80.887935241999969, 34.983980827000039],
            [-80.887775241999975, 34.984161828000026],
            [-80.887674240999957, 34.984337827000047],
            [-80.887648240999965, 34.984441828000058],
            [-80.887694241999952, 34.984601828000052],
            [-80.887768241999936, 34.984678828000028],
            [-80.888001241999973, 34.984711828000059],
            [-80.888249242999962, 34.984662828000069],
            [-80.888442242999986, 34.984579828000051],
            [-80.888556241999936, 34.984535828000048],
            [-80.888723241999969, 34.984541828000033],
            [-80.888823241999944, 34.984552828000062],
            [-80.888910242999941, 34.984629827000049],
            [-80.888990242999967, 34.984739828000045],
            [-80.889377241999966, 34.985179828000071],
            [-80.889261242999964, 34.985212827000055],
            [-80.889278241999989, 34.985273828000061],
            [-80.889276242999983, 34.985308828000029],
            [-80.88925524199999, 34.98533782800007],
            [-80.88910524299996, 34.985412828000051],
            [-80.889102242999968, 34.985413828000048],
            [-80.88903424199998, 34.985454828000059],
            [-80.888924241999973, 34.985512828000026],
            [-80.888853242999971, 34.985555828000031],
            [-80.888787241999978, 34.985602828000026],
            [-80.888760241999989, 34.985630828000069],
            [-80.888747242999955, 34.98566482800004],
            [-80.888734242999988, 34.985735828000031],
            [-80.888722241999972, 34.985841828000048],
            [-80.888769241999967, 34.98612982700007],
            [-80.888775242999941, 34.986160829000028],
            [-80.888809242999969, 34.986299828000028],
            [-80.888817241999959, 34.986430829000028],
            [-80.888820241999952, 34.986476827000047],
            [-80.888815242999954, 34.986547828000027],
            [-80.888797242999942, 34.986616828000024],
            [-80.888738242999978, 34.986750828000027],
            [-80.888673241999982, 34.986957828000072],
            [-80.888656241999968, 34.987027828000066],
            [-80.888639241999954, 34.987157828000079],
            [-80.888638241999956, 34.987168828000051],
            [-80.888638241999956, 34.987275828000065],
            [-80.888627241999984, 34.987346828000057],
            [-80.888582242999973, 34.987385829000061],
            [-80.888555242999985, 34.987414828000055],
            [-80.888535242999978, 34.98744582900008],
            [-80.888508241999943, 34.987549828000056],
            [-80.888452241999971, 34.987684829000045],
            [-80.88836224299996, 34.98783682800007],
            [-80.888358241999981, 34.98784482800005],
            [-80.888314241999979, 34.987906829000053],
            [-80.888256242999944, 34.987959828000044],
            [-80.888160241999969, 34.988033828000027],
            [-80.888089242999968, 34.988074829000027],
            [-80.88801424199994, 34.988111829000047],
            [-80.887972241999989, 34.988122829000076],
            [-80.887886242999969, 34.988137828000049],
            [-80.887711241999966, 34.988126829000066],
            [-80.887456241999985, 34.988075829000024],
            [-80.887415241999975, 34.988063828000065],
            [-80.887244241999952, 34.988031829000079],
            [-80.887203241999941, 34.988018829000055],
            [-80.887118241999985, 34.988000828000054],
            [-80.887031241999978, 34.987987829000076],
            [-80.886987240999986, 34.987986828000032],
            [-80.886860241999955, 34.988015829000062],
            [-80.886792241999956, 34.988042829000051],
            [-80.886663241999941, 34.988095828000041],
            [-80.886502241999949, 34.988150829000062],
            [-80.886460241999941, 34.988161829000035],
            [-80.88626224099994, 34.988238829000068],
            [-80.886027241999955, 34.988351828000077],
            [-80.885843241999964, 34.988450829000044],
            [-80.885839240999985, 34.988453829000036],
            [-80.885839240999985, 34.988454829000034],
            [-80.88578824199999, 34.988505829000076],
            [-80.885728240999981, 34.98857182900008],
            [-80.885710241999959, 34.988592829000027],
            [-80.88564624199995, 34.988685829000076],
            [-80.885596241999963, 34.988783828000066],
            [-80.885583240999949, 34.988817828000037],
            [-80.885547240999983, 34.98895582800003],
            [-80.885528241999964, 34.989008829000056],
            [-80.885489240999959, 34.989126829000043],
            [-80.885479241999974, 34.989163829000063],
            [-80.885412240999983, 34.989439829000048],
            [-80.88537624199995, 34.989635829000065],
            [-80.885374241999955, 34.989650829000027],
            [-80.885366240999986, 34.989756828000054],
            [-80.885362241999985, 34.989899829000024],
            [-80.885395241999959, 34.990146829000025],
            [-80.885399241999949, 34.990217829000073],
            [-80.885584240999947, 34.990804829000069],
            [-80.885591241999975, 34.990839829000038],
            [-80.885600240999963, 34.991053830000055],
            [-80.885606240999948, 34.991088829000034],
            [-80.88566124099998, 34.991260830000044],
            [-80.885753242999954, 34.991422829000044],
            [-80.885794240999985, 34.991485830000045],
            [-80.885798241999964, 34.991490829000043],
            [-80.885846241999957, 34.991542830000071],
            [-80.885876240999949, 34.991569829000071],
            [-80.886014241999987, 34.991655829000024],
            [-80.886053241999946, 34.99167183000003],
            [-80.886176241999976, 34.991709830000048],
            [-80.886563241999966, 34.991818830000057],
            [-80.886716240999988, 34.991861829000072],
            [-80.88675624199999, 34.991875829000037],
            [-80.886881241999959, 34.991908829000067],
            [-80.887096241999984, 34.991941829000041],
            [-80.887446241999953, 34.991958829000055],
            [-80.887755242999958, 34.99195282900007],
            [-80.888453241999969, 34.991895830000033],
            [-80.888668241999937, 34.991861829000072],
            [-80.888752242999942, 34.991841829000066],
            [-80.888795242999947, 34.991835829000024],
            [-80.888878241999976, 34.991811830000074],
            [-80.889066243999935, 34.991745829000024],
            [-80.889080242999967, 34.991741829000034],
            [-80.889275242999986, 34.991661829000066],
            [-80.889356242999952, 34.991632829000025],
            [-80.889567242999988, 34.991582830000027],
            [-80.889772243999971, 34.991519830000072],
            [-80.889868242999967, 34.991478829000073],
            [-80.88994224399994, 34.991447830000027],
            [-80.890006242999959, 34.991421829000046],
            [-80.890167242999951, 34.991360830000076],
            [-80.890243242999986, 34.991332829000044],
            [-80.890287242999989, 34.991317829000025],
            [-80.890418242999942, 34.991274829000076],
            [-80.890491243999975, 34.991251830000067],
            [-80.890758243999983, 34.991129829000045],
            [-80.890794243999949, 34.991108829000041],
            [-80.890824243999987, 34.99108282900005],
            [-80.890893242999937, 34.991004829000076],
            [-80.89090224399996, 34.990995829000042],
            [-80.890924242999972, 34.990964829000063],
            [-80.891005242999938, 34.990937829000075],
            [-80.891048242999943, 34.990915829000073],
            [-80.891179242999954, 34.990827829000068],
            [-80.891304242999979, 34.990703830000029],
            [-80.89133424299996, 34.990676829000051],
            [-80.89137124399997, 34.990657829000043],
            [-80.891535242999964, 34.990606829000058],
            [-80.891708242999982, 34.990578829000071],
            [-80.891883242999938, 34.990561829000058],
            [-80.892103243999941, 34.99056082900006],
            [-80.892234243999951, 34.990578829000071],
            [-80.892272243999969, 34.990593829000034],
            [-80.892304243999945, 34.990618829000027],
            [-80.892416243999946, 34.990728828000044],
            [-80.892486242999951, 34.990859829000044],
            [-80.892545243999962, 34.990993829000047],
            [-80.892616243999953, 34.991124829000057],
            [-80.892746243999966, 34.991267829000037],
            [-80.892794243999958, 34.991326829000059],
            [-80.892904243999965, 34.991437829000063],
            [-80.892924243999971, 34.991469829000039],
            [-80.89299624399996, 34.991637829000069],
            [-80.893014243999971, 34.991669829000045],
            [-80.893055243999981, 34.991771829000072],
            [-80.893065243999956, 34.991806829000041],
            [-80.893147243999977, 34.992008830000032],
            [-80.893273243999943, 34.992196829000079],
            [-80.89355924399996, 34.992597829000033],
            [-80.893597243999977, 34.992661829000042],
            [-80.893683243999988, 34.992786830000057],
            [-80.893755243999976, 34.99287582900007],
            [-80.893860243999939, 34.992990829000064],
            [-80.893933244999971, 34.993079830000056],
            [-80.894019244999981, 34.993204829000035],
            [-80.894073243999969, 34.993301829000075],
            [-80.894138244999965, 34.993434830000069],
            [-80.894147243999953, 34.993469830000038],
            [-80.894154244999982, 34.993569830000069],
            [-80.894084243999941, 34.994955830000038],
            [-80.894069243999979, 34.994989830000065],
            [-80.894037244999936, 34.995129830000053],
            [-80.893910243999983, 34.995395830000064],
            [-80.893890243999977, 34.995426831000032],
            [-80.893857244999936, 34.995492830000046],
            [-80.893790243999945, 34.995699830000035],
            [-80.893730244999972, 34.99583283000004],
            [-80.893710244999966, 34.995864830000073],
            [-80.893663243999981, 34.995924830000035],
            [-80.893534244999955, 34.996020830000077],
            [-80.893443243999968, 34.996097830000053],
            [-80.893419244999961, 34.996126831000026],
            [-80.89336024499994, 34.996177830000079],
            [-80.893238243999974, 34.996367829000064],
            [-80.893182244999934, 34.996464830000036],
            [-80.893132243999958, 34.996600830000034],
            [-80.893116243999941, 34.996706830000051],
            [-80.893118244999982, 34.996742830000073],
            [-80.893129243999965, 34.996777830000042],
            [-80.893286243999967, 34.997071831000028],
            [-80.893318244999989, 34.997138831000029],
            [-80.893387243999939, 34.997381830000052],
            [-80.89339724499996, 34.997452830000043],
            [-80.893392244999973, 34.997523830000034],
            [-80.893331243999967, 34.997731830000077],
            [-80.893314244999942, 34.99776483100004],
            [-80.893271244999937, 34.997826830000065],
            [-80.893245243999957, 34.997894830000064],
            [-80.893214243999978, 34.997960831000057],
            [-80.893210243999988, 34.99799683100008],
            [-80.89321724399997, 34.998031831000048],
            [-80.893244243999959, 34.998099830000058],
            [-80.893284243999972, 34.998162830000069],
            [-80.893423244999951, 34.998280831000045],
            [-80.893527243999984, 34.998406831000068],
            [-80.893732244999967, 34.998592830000064],
            [-80.894027244999961, 34.998880830000076],
            [-80.894217245999982, 34.999077831000079],
            [-80.894772244999956, 34.999677831000042],
            [-80.894945243999985, 34.999884831000031],
            [-80.895059243999981, 35.000046832000066],
            [-80.895123244999979, 35.000161831000071],
            [-80.895192245999965, 35.000336831000027],
            [-80.895243244999961, 35.000482831000056],
            [-80.895343244999935, 35.000586831000078],
            [-80.895413244999986, 35.000647831000038],
            [-80.895543245999988, 35.000725831000068],
            [-80.895660245999977, 35.000812831000076],
            [-80.895692245999953, 35.000831831000028],
            [-80.895751245999975, 35.00087583100003],
            [-80.895825245999958, 35.000953831000061],
            [-80.895903245999989, 35.001027831000044],
            [-80.89598624499996, 35.001097832000028],
            [-80.896112244999983, 35.001225831000056],
            [-80.89615824599997, 35.001262831000076],
            [-80.89632824499995, 35.00138983100004],
            [-80.896444244999941, 35.001485832000071],
            [-80.89645524599996, 35.00149483000007],
            [-80.896580244999939, 35.001601831000073],
            [-80.896657245999961, 35.001673831000062],
            [-80.896933244999957, 35.001887831000033],
            [-80.897103245999972, 35.002012831000059],
            [-80.897196245999965, 35.002092831000027],
            [-80.897258246999968, 35.002142831000072],
            [-80.897407244999954, 35.002231831000074],
            [-80.89770124599994, 35.002407831000028],
            [-80.897909244999937, 35.002542831000028],
            [-80.897968245999948, 35.002578831000051],
            [-80.898012245999951, 35.002606831000037],
            [-80.898107246999984, 35.002660832000061],
            [-80.898311246999981, 35.002794832000063],
            [-80.898673245999987, 35.003021831000069],
            [-80.898973246999958, 35.00322383200006],
            [-80.899024245999954, 35.003263832000073],
            [-80.899547245999941, 35.00360283200007],
            [-80.899679246999938, 35.003702831000055],
            [-80.89974624599995, 35.003748832000042],
            [-80.899994246999938, 35.003950831000054],
            [-80.900084246999938, 35.004018832000042],
            [-80.900154246999989, 35.00407283100003],
            [-80.900220246999936, 35.004118832000074],
            [-80.900726246999966, 35.004512832000046],
            [-80.901084245999982, 35.004775832000064],
            [-80.901436246999936, 35.004988832000038],
            [-80.901687247999973, 35.005130832000077],
            [-80.901757246999978, 35.005180831000075],
            [-80.901786246999961, 35.005200832000071],
            [-80.901814246999947, 35.005228832000057],
            [-80.901879247999943, 35.005275831000063],
            [-80.902033247999952, 35.005401831000029],
            [-80.902164246999973, 35.005544832000055],
            [-80.902239247999944, 35.005654832000062],
            [-80.902262247999943, 35.005684832000043],
            [-80.902299246999974, 35.005748832000052],
            [-80.902331247999939, 35.005851832000076],
            [-80.902340247999973, 35.005896832000076],
            [-80.902366247999964, 35.006026832000032],
            [-80.902368247999959, 35.006167832000074],
            [-80.902374247999944, 35.006237832000068],
            [-80.902369247999957, 35.006308832000059],
            [-80.902324247999957, 35.006591832000026],
            [-80.902295246999984, 35.006695833000038],
            [-80.902266247999989, 35.006762832000049],
            [-80.902209246999973, 35.006858832000034],
            [-80.902186246999975, 35.006888832000072],
            [-80.902081246999956, 35.007001832000071],
            [-80.902008246999969, 35.007089832000077],
            [-80.90183824799999, 35.00725383200006],
            [-80.901704247999987, 35.007345832000055],
            [-80.901629247999949, 35.007382832000076],
            [-80.901473247999945, 35.007449832000077],
            [-80.901393247999977, 35.007477832000063],
            [-80.901310246999969, 35.007501832000059],
            [-80.901181245999965, 35.007522832000063],
            [-80.901137247999941, 35.007524832000058],
            [-80.901050246999944, 35.00753583200003],
            [-80.900961247999987, 35.007536833000074],
            [-80.900944247999973, 35.007535833000077],
            [-80.900786247999974, 35.007529832000046],
            [-80.900479246999964, 35.007523832000061],
            [-80.900083246999941, 35.00754683200006],
            [-80.899953245999939, 35.007561832000079],
            [-80.899786246999952, 35.007607832000076],
            [-80.89962524699996, 35.007664833000035],
            [-80.899528246999978, 35.007737832000032],
            [-80.899469246999956, 35.007832832000076],
            [-80.899455246999935, 35.007866833000037],
            [-80.899446245999968, 35.007937833000028],
            [-80.899442246999968, 35.008079832000078],
            [-80.899457246999987, 35.008149832000072],
            [-80.899477246999936, 35.008289833000049],
            [-80.899497246999942, 35.008359833000043],
            [-80.899511246999964, 35.008393833000071],
            [-80.899589245999948, 35.008520832000045],
            [-80.899634246999938, 35.008581833000051],
            [-80.899686245999987, 35.008638833000077],
            [-80.899859245999949, 35.008845832000077],
            [-80.900073246999966, 35.009070833000067],
            [-80.900122247999946, 35.009129833000031],
            [-80.900290246999987, 35.00938083300008],
            [-80.900361247999967, 35.009509832000049],
            [-80.900412247999952, 35.009646832000044],
            [-80.900422246999938, 35.009717833000025],
            [-80.900428246999979, 35.009858832000077],
            [-80.900424246999989, 35.009894833000033],
            [-80.900392246999957, 35.010034832000031],
            [-80.90037624699994, 35.010067833000051],
            [-80.900166247999948, 35.010420833000069],
            [-80.900003246999972, 35.010670833000063],
            [-80.899995246999936, 35.010682832000043],
            [-80.899940246999961, 35.010745833000044],
            [-80.899829247999946, 35.010875833000057],
            [-80.899602246999962, 35.011137833000078],
            [-80.89939224699998, 35.01136583300007],
            [-80.899374246999969, 35.011398833000044],
            [-80.899362246999942, 35.011427833000027],
            [-80.899315246999947, 35.011532834000036],
            [-80.899235246999979, 35.011574833000054],
            [-80.898611246999963, 35.01202683300005],
            [-80.898364246999961, 35.012176833000069],
            [-80.898097247999942, 35.012361833000057],
            [-80.897999246999973, 35.012433833000046],
            [-80.897906245999934, 35.012508834000073],
            [-80.897847245999969, 35.012561833000063],
            [-80.89783224599995, 35.012572833000036],
            [-80.89783224599995, 35.012573833000033],
            [-80.897815246999983, 35.012586834000047],
            [-80.897728246999975, 35.012666833000026],
            [-80.897619245999977, 35.012776834000078],
            [-80.897560245999955, 35.012829833000069],
            [-80.89755824599996, 35.012830833000066],
            [-80.897433246999981, 35.012927833000049],
            [-80.897307245999968, 35.013026834000073],
            [-80.897185245999935, 35.013128834000042],
            [-80.89706924699999, 35.013233833000072],
            [-80.896953245999953, 35.013341833000027],
            [-80.896865246999937, 35.013460834000057],
            [-80.89685224599998, 35.013494833000038],
            [-80.896870245999935, 35.013671833000046],
            [-80.89690524599996, 35.013736834000042],
            [-80.896928246999948, 35.013767834000078],
            [-80.896984245999988, 35.013822833000063],
            [-80.897078245999978, 35.013896834000036],
            [-80.897191245999977, 35.013952834000065],
            [-80.897523246999981, 35.014081834000024],
            [-80.897747246999984, 35.014168834000031],
            [-80.898201246999975, 35.014306833000035],
            [-80.898369246999948, 35.014347834000034],
            [-80.898497246999966, 35.014373834000025],
            [-80.898757246999935, 35.014418834000026],
            [-80.898974246999956, 35.014447834000066],
            [-80.899150246999966, 35.014455834000046],
            [-80.899282246999974, 35.014452834000053],
            [-80.899325246999979, 35.014446834000069],
            [-80.899410246999935, 35.014426833000073],
            [-80.899530246999973, 35.014380834000065],
            [-80.899835246999942, 35.014179834000061],
            [-80.900029246999964, 35.014036833000034],
            [-80.900638246999961, 35.013528833000066],
            [-80.900701246999972, 35.013477833000024],
            [-80.900766246999979, 35.013430833000029],
            [-80.901110246999963, 35.013157833000037],
            [-80.901370247999978, 35.012965833000067],
            [-80.901399247999962, 35.012939833000075],
            [-80.901517246999958, 35.012786833000064],
            [-80.901558246999969, 35.01277383300004],
            [-80.901577246999977, 35.012764832000073],
            [-80.90175624799997, 35.012688833000027],
            [-80.901792247999936, 35.012674833000062],
            [-80.90181324699995, 35.012659833000043],
            [-80.902086247999989, 35.012460833000034],
            [-80.902271248999966, 35.012310833000072],
            [-80.902659247999964, 35.01192483300008],
            [-80.902674247999983, 35.011907832000077],
            [-80.90322724899994, 35.011470833000033],
            [-80.903514247999965, 35.011515834000079],
            [-80.903945248999946, 35.011398833000044],
            [-80.90405624899995, 35.01134383200008],
            [-80.904531248999945, 35.011225832000036],
            [-80.904707248999955, 35.011044834000074],
            [-80.904851247999943, 35.01109983300006],
            [-80.904895247999946, 35.011244832000045],
            [-80.904917247999947, 35.011488833000044],
            [-80.904961248999939, 35.011759833000042],
            [-80.905050247999952, 35.01198583300004],
            [-80.905282247999935, 35.012229833000049],
            [-80.905580248999968, 35.012410834000036],
            [-80.905922247999968, 35.012510832000032],
            [-80.906276248999973, 35.012582833000067],
            [-80.906376248999948, 35.012593833000039],
            [-80.906618249999951, 35.012746833000051],
            [-80.906713248999949, 35.012820833000035],
            [-80.906821248999961, 35.012932833000036],
            [-80.906888248999962, 35.013024833000031],
            [-80.906974248999973, 35.01318583300008],
            [-80.907082248999984, 35.013384833000032],
            [-80.907252248999953, 35.013736834000042],
            [-80.907402248999972, 35.014106834000074],
            [-80.90748524899999, 35.014270833000069],
            [-80.907541248999962, 35.014366833000054],
            [-80.907682249999937, 35.014500833000056],
            [-80.90781624899995, 35.014594833000046],
            [-80.907887249999987, 35.014635834000046],
            [-80.907925248999959, 35.01465283400006],
            [-80.907966250999948, 35.014665834000027],
            [-80.908080249999955, 35.014718833000074],
            [-80.908151248999957, 35.014760834000072],
            [-80.90825424999997, 35.01482883400007],
            [-80.908284249999951, 35.014854833000072],
            [-80.908345249999968, 35.014948833000062],
            [-80.908388249999973, 35.015049834000024],
            [-80.90854324999998, 35.01563983300008],
            [-80.908558249999942, 35.015672834000043],
            [-80.908661249999966, 35.015829834000044],
            [-80.908687249999957, 35.015858834000028],
            [-80.908803249999949, 35.015965833000052],
            [-80.908924249999984, 35.016067834000069],
            [-80.90927125099995, 35.016389834000051],
            [-80.909422250999967, 35.016606833000026],
            [-80.909461249999936, 35.016669833000037],
            [-80.909510249999983, 35.016769834000058],
            [-80.909538249999969, 35.01687783400007],
            [-80.909481249999942, 35.017664834000072],
            [-80.90947424999996, 35.017684834000079],
            [-80.90947024999997, 35.017730835000066],
            [-80.909450249999963, 35.017744834000041],
            [-80.90933924999996, 35.017823834000069],
            [-80.909253250999939, 35.017903834000037],
            [-80.909228250999945, 35.017933834000075],
            [-80.909045249999963, 35.01808683400003],
            [-80.908978249999961, 35.018133835000071],
            [-80.908759250999935, 35.018250834000071],
            [-80.908721249999985, 35.018267834000028],
            [-80.908653249999986, 35.018313834000026],
            [-80.908464249999952, 35.018462834000047],
            [-80.908408249999979, 35.018517834000079],
            [-80.908385249999981, 35.01854783400006],
            [-80.908331249999947, 35.018603834000032],
            [-80.908239249999951, 35.018725834000065],
            [-80.90820524999998, 35.018790834000072],
            [-80.908126249999953, 35.018917835000025],
            [-80.908091249999984, 35.018983835000029],
            [-80.90799224999995, 35.019101835000072],
            [-80.907946249999952, 35.019130834000066],
            [-80.907906250999986, 35.019144834000031],
            [-80.907862249999937, 35.019151835000059],
            [-80.907730249999986, 35.01914783400008],
            [-80.907689249999976, 35.019135835000043],
            [-80.907358249999959, 35.018958835000035],
            [-80.907133249999958, 35.018845834000047],
            [-80.906815249999966, 35.018655834000072],
            [-80.906627248999939, 35.018562834000079],
            [-80.906549249999955, 35.018530834000046],
            [-80.90637924899994, 35.018493834000026],
            [-80.906204248999984, 35.018507835000037],
            [-80.906120249999958, 35.018529835000038],
            [-80.905889248999983, 35.018632835000062],
            [-80.905820248999987, 35.018676834000075],
            [-80.905781249999961, 35.018693834000032],
            [-80.905548248999935, 35.018854834000024],
            [-80.905510249999963, 35.018873834000033],
            [-80.905431249999936, 35.01890383500006],
            [-80.905347249999977, 35.018926834000069],
            [-80.905217249999964, 35.018942835000075],
            [-80.905090248999954, 35.018969834000075],
            [-80.904929248999963, 35.019025835000036],
            [-80.904506248999951, 35.019121834000032],
            [-80.904419247999954, 35.01913283500005],
            [-80.904244247999941, 35.019144834000031],
            [-80.904113248999977, 35.019135835000043],
            [-80.90403024799997, 35.019111834000057],
            [-80.903991248999944, 35.019094834000043],
            [-80.903922248999947, 35.019050834000041],
            [-80.903694248999955, 35.018746835000059],
            [-80.903663248999976, 35.018721834000075],
            [-80.903625247999969, 35.018702835000056],
            [-80.903583247999961, 35.01869483400003],
            [-80.903495248999945, 35.018690835000029],
            [-80.903451248999943, 35.018692834000035],
            [-80.903408248999938, 35.018702835000056],
            [-80.903330247999975, 35.01873583400004],
            [-80.903258248999975, 35.018794834000062],
            [-80.903127248999965, 35.018979835000039],
            [-80.903014247999977, 35.019248835000042],
            [-80.902973248999956, 35.019459835000077],
            [-80.902967247999982, 35.019707835000077],
            [-80.902973248999956, 35.019742835000045],
            [-80.903017247999969, 35.019843834000028],
            [-80.903051248999986, 35.019908834000034],
            [-80.903091248999942, 35.019971835000035],
            [-80.903276248999987, 35.020212835000052],
            [-80.90331324899995, 35.020276835000061],
            [-80.903415248999977, 35.020474835000073],
            [-80.903438248999976, 35.020542836000061],
            [-80.903471248999949, 35.020754835000048],
            [-80.903473247999955, 35.020825835000039],
            [-80.903458248999982, 35.021144836000076],
            [-80.903435248999983, 35.021213835000026],
            [-80.903387247999945, 35.02131283500006],
            [-80.903363248999938, 35.021342835000041],
            [-80.903020248999951, 35.021619835000024],
            [-80.902838248999956, 35.021775835000028],
            [-80.902786248999973, 35.021832835000055],
            [-80.902668247999941, 35.021981836000066],
            [-80.902629247999982, 35.022045836000075],
            [-80.902555248999988, 35.022250835000079],
            [-80.902540248999969, 35.022320835000073],
            [-80.902520248999963, 35.022497835000024],
            [-80.902466247999939, 35.022813835000079],
            [-80.902408248999961, 35.022947836000071],
            [-80.902370248999944, 35.023012835000031],
            [-80.902274247999969, 35.023131835000072],
            [-80.902114248999965, 35.023343836000038],
            [-80.901942247999955, 35.023548835000042],
            [-80.901692247999961, 35.023883836000039],
            [-80.901534247999962, 35.024139835000028],
            [-80.901449247999949, 35.024264836000043],
            [-80.901260247999971, 35.024586836000026],
            [-80.901049247999936, 35.024899836000031],
            [-80.900971247999962, 35.025026836000052],
            [-80.900882248999949, 35.025212836000037],
            [-80.900813247999963, 35.025358836000066],
            [-80.900729247999948, 35.025560836000068],
            [-80.900697247999972, 35.025626836000072],
            [-80.900656247999962, 35.025727837000034],
            [-80.900502247999952, 35.026210836000075],
            [-80.900484247999941, 35.026280836000069],
            [-80.900423247999981, 35.026882837000073],
            [-80.900384247999966, 35.027093837000052],
            [-80.900342247999959, 35.027232837000042],
            [-80.900303247999943, 35.027296836000062],
            [-80.900189246999958, 35.027447837000068],
            [-80.899938247999955, 35.027740837000067],
            [-80.899784247999946, 35.027913837000028],
            [-80.899661247999973, 35.028060837000055],
            [-80.899614247999978, 35.028111838000029],
            [-80.899476247999985, 35.028266837000047],
            [-80.899431247999985, 35.028319837000026],
            [-80.899337247999938, 35.028452837000032],
            [-80.899143247999973, 35.028795836000029],
            [-80.899081247999959, 35.028906837000079],
            [-80.898943247999966, 35.029130837000025],
            [-80.898803246999989, 35.02931183700008],
            [-80.898693246999983, 35.029465837000032],
            [-80.898679247999951, 35.02949983600007],
            [-80.898672247999968, 35.029534838000075],
            [-80.898672247999968, 35.029570837000051],
            [-80.898682247999943, 35.029640837000045],
            [-80.898692247999975, 35.029675836000024],
            [-80.898709246999942, 35.029708837000044],
            [-80.898843246999945, 35.029892837000034],
            [-80.898895247999974, 35.029990837000071],
            [-80.898921246999976, 35.03005883700007],
            [-80.898939247999976, 35.030199836000065],
            [-80.89893124799994, 35.030255838000073],
            [-80.898929247999945, 35.030270838000035],
            [-80.898912247999988, 35.03034083700004],
            [-80.898899247999964, 35.030374836000078],
            [-80.89888224699996, 35.030443837000064],
            [-80.898868246999939, 35.030477837000035],
            [-80.898843247999935, 35.030507838000062],
            [-80.898684248999984, 35.030746837000038],
            [-80.898595247999936, 35.030880837000041],
            [-80.898538247999966, 35.030975838000074],
            [-80.898443247999978, 35.031136837000076],
            [-80.898247247999961, 35.031454837000069],
            [-80.898129247999975, 35.031679838000059],
            [-80.898005246999958, 35.031921838000073],
            [-80.897933246999969, 35.032060837000074],
            [-80.897755247999953, 35.03240783800004],
            [-80.89772224799998, 35.032482838000078],
            [-80.897661246999974, 35.032627838000053],
            [-80.89763224699999, 35.032695837000063],
            [-80.897612246999984, 35.032744837000052],
            [-80.897540246999938, 35.032900838000046],
            [-80.897509246999959, 35.032968838000045],
            [-80.897500246999982, 35.032988837000062],
            [-80.89749024799994, 35.033012838000047],
            [-80.897475247999978, 35.03304883800007],
            [-80.897468246999949, 35.033069837000028],
            [-80.897455248999961, 35.033103838000045],
            [-80.897439246999966, 35.033151838000038],
            [-80.897415246999969, 35.033205838000072],
            [-80.897410246999982, 35.033218838000039],
            [-80.897402247999935, 35.03324083800004],
            [-80.897375247999946, 35.033317838000073],
            [-80.89736224699999, 35.033355838000034],
            [-80.897360246999938, 35.033390838000059],
            [-80.897368247999964, 35.033461837000061],
            [-80.897364247999974, 35.03349683700003],
            [-80.897345246999976, 35.033556838000038],
            [-80.897332247999941, 35.033600838000041],
            [-80.897325247999959, 35.033635838000066],
            [-80.897346246999973, 35.033666838000045],
            [-80.89735924699994, 35.03370183800007],
            [-80.897367247999966, 35.033743838000078],
            [-80.897387247999973, 35.033841838000058],
            [-80.897453247999977, 35.034010838000029],
            [-80.897484247999955, 35.034075838000035],
            [-80.897536246999948, 35.034181838000052],
            [-80.897565246999989, 35.034242838000068],
            [-80.89766624799995, 35.034400838000067],
            [-80.897700247999978, 35.034461839000073],
            [-80.897702247999973, 35.034465838000074],
            [-80.89773024699997, 35.034605839000051],
            [-80.897730247999959, 35.034641839000074],
            [-80.897724247999975, 35.034676838000053],
            [-80.897691247999944, 35.034779839000066],
            [-80.897516247999988, 35.035026839000068],
            [-80.897419246999959, 35.035186838000072],
            [-80.89735124799995, 35.035317838000026],
            [-80.897349247999955, 35.035331838000047],
            [-80.89734324799997, 35.035423838000042],
            [-80.897360246999938, 35.035527838000064],
            [-80.897375247999946, 35.035561838000035],
            [-80.897422246999952, 35.035639838000066],
            [-80.89743324799997, 35.035656838000079],
            [-80.897473247999983, 35.035705838000069],
            [-80.897481246999973, 35.035715839000034],
            [-80.897660247999966, 35.035869839000043],
            [-80.897662247999961, 35.035871839000038],
            [-80.89773024699997, 35.035922839000079],
            [-80.897882247999974, 35.036040839000066],
            [-80.897919247999937, 35.036071838000055],
            [-80.898003246999963, 35.036142838000046],
            [-80.898227247999955, 35.036360839000054],
            [-80.898298246999957, 35.036450839000054],
            [-80.898338247999959, 35.036513839000065],
            [-80.898388247999947, 35.03661383900004],
            [-80.898437246999947, 35.036708839000028],
            [-80.898487247999981, 35.036833839000053],
            [-80.898494247999963, 35.036909839000032],
            [-80.898507247999987, 35.037038839000047],
            [-80.898511247999977, 35.037140839000074],
            [-80.898512247999975, 35.037144839000064],
            [-80.898509247999982, 35.03721783900005],
            [-80.898508248999974, 35.037286839000046],
            [-80.898501247999945, 35.037355839000043],
            [-80.898492247999968, 35.037464838000062],
            [-80.898460247999935, 35.037567839000076],
            [-80.898377246999985, 35.03774783800003],
            [-80.898338247999959, 35.037834839000027],
            [-80.898320247999948, 35.03787483900004],
            [-80.898179247999963, 35.038204839000059],
            [-80.898146247999989, 35.038321839000048],
            [-80.898111247999964, 35.038447838000025],
            [-80.898093247999952, 35.038731839000036],
            [-80.898124246999942, 35.039087840000036],
            [-80.898140247999947, 35.039193839000063],
            [-80.898197246999985, 35.039327839000066],
            [-80.898230248999937, 35.03939383900007],
            [-80.89841324799994, 35.039678839000032],
            [-80.89841624799999, 35.039682839000079],
            [-80.898436247999939, 35.03970884000006],
            [-80.89860424799997, 35.039873839000052],
            [-80.898762248999958, 35.040016839000032],
            [-80.898780248999969, 35.040032839000048],
            [-80.898925247999955, 35.04014483900005],
            [-80.898939247999976, 35.040155839000079],
            [-80.89906224799995, 35.04025684000004],
            [-80.899324247999971, 35.040446840000072],
            [-80.899426247999941, 35.04051283900003],
            [-80.899565247999988, 35.040603839000028],
            [-80.899602247999951, 35.040622840000026],
            [-80.89983024899999, 35.040747840000051],
            [-80.899832248999985, 35.040757839000037],
            [-80.900190248999934, 35.041335839000055],
            [-80.900377248999973, 35.04183684000003],
            [-80.900424247999979, 35.041964839000059],
            [-80.900479247999954, 35.04206884000007],
            [-80.90051724999995, 35.042169840000042],
            [-80.900609248999956, 35.042365840000059],
            [-80.900653249999948, 35.042427840000073],
            [-80.900727248999942, 35.042513839000037],
            [-80.900987248999968, 35.042800840000041],
            [-80.901153248999947, 35.04300784000003],
            [-80.901348248999966, 35.043198840000059],
            [-80.901538249999987, 35.043395840000073],
            [-80.901689248999958, 35.043568840000034],
            [-80.901758248999954, 35.043658840000035],
            [-80.901897249999934, 35.043879840000045],
            [-80.901974249999967, 35.044043840000029],
            [-80.902121248999947, 35.044335841000077],
            [-80.902226249999956, 35.044529841000042],
            [-80.902682249999941, 35.045294840000054],
            [-80.902864249999936, 35.045576840000024],
            [-80.902984249999975, 35.045724840000048],
            [-80.903028249999977, 35.045785840000065],
            [-80.903143249999971, 35.045891840000024],
            [-80.903243250999935, 35.045961840000075],
            [-80.903277249999974, 35.045977840000035],
            [-80.903317249999986, 35.045997840000041],
            [-80.903512249999949, 35.046077840000066],
            [-80.903754250999953, 35.046165841000061],
            [-80.904000250999957, 35.046256840000069],
            [-80.904066249999971, 35.046280840000065],
            [-80.904227249999963, 35.046333840000045],
            [-80.904286249999984, 35.046354840000049],
            [-80.904391250999936, 35.046386840000025],
            [-80.904405249999968, 35.046390840000072],
            [-80.904757250999978, 35.046509841000045],
            [-80.904761250999968, 35.046510841000043],
            [-80.904789249999965, 35.046521840000025],
            [-80.904878249999967, 35.046555840000053],
            [-80.904960249999988, 35.046573841000054],
            [-80.905029249999984, 35.046569840000075],
            [-80.905621250999957, 35.046717840000042],
            [-80.905724250999981, 35.046736840000051],
            [-80.905888250999965, 35.046789841000077],
            [-80.905924250999988, 35.046809841000027],
            [-80.905983250999952, 35.046862841000063],
            [-80.906009250999944, 35.046930841000062],
            [-80.906014250999988, 35.046965841000031],
            [-80.906014250999988, 35.047107840000024],
            [-80.905995250999979, 35.047176841000066],
            [-80.905985250999947, 35.047221841000066],
            [-80.905965250999941, 35.047316841000054],
            [-80.905937250999955, 35.047420840000029],
            [-80.90582225199995, 35.047725841000045],
            [-80.905750249999983, 35.047893840000029],
            [-80.905672249999952, 35.048059841000054],
            [-80.905614250999975, 35.048155841000039],
            [-80.905548250999971, 35.048247841000034],
            [-80.905540250999934, 35.048256841000068],
            [-80.905483250999964, 35.048326841000062],
            [-80.905476250999982, 35.048336841000037],
            [-80.905451250999988, 35.048362841000028],
            [-80.905399249999959, 35.048418841000057],
            [-80.905238249999968, 35.048592840000026],
            [-80.905123249999974, 35.048668841000051],
            [-80.905215250999959, 35.048682841000073],
            [-80.905342250999979, 35.048675841000033],
            [-80.905456250999976, 35.048762842000031],
            [-80.905509251999945, 35.048861840000029],
            [-80.90589025099996, 35.049455841000054],
            [-80.906050250999954, 35.049648841000078],
            [-80.906130251999969, 35.04971984000008],
            [-80.906324250999944, 35.049763841000072],
            [-80.906518250999966, 35.049730841000041],
            [-80.906792250999956, 35.04971984000008],
            [-80.906939250999983, 35.049719841000069],
            [-80.907187251999972, 35.049736842000073],
            [-80.907701250999935, 35.049863841000047],
            [-80.907729251999967, 35.049863841000047],
            [-80.907799250999972, 35.049905841000054],
            [-80.907914250999966, 35.049863841000047],
            [-80.907995250999988, 35.049863841000047],
            [-80.908109250999985, 35.049852841000074],
            [-80.908142251999948, 35.049841841000045],
            [-80.908292251999967, 35.04984784100003],
            [-80.908352251999986, 35.049737841000024],
            [-80.908445250999989, 35.049570841000047],
            [-80.90883125199997, 35.049461841000038],
            [-80.908945251999967, 35.049385842000049],
            [-80.909179250999955, 35.049247841000067],
            [-80.909348251999972, 35.049116840000067],
            [-80.909616251999978, 35.049118841000052],
            [-80.909693251999954, 35.049043841000071],
            [-80.909771251999985, 35.048970841000028],
            [-80.910427251999977, 35.048926840000036],
            [-80.910490251999988, 35.048947841000029],
            [-80.91064725199999, 35.049001841000063],
            [-80.910703251999962, 35.048934841000062],
            [-80.910883252999952, 35.048896841000044],
            [-80.911010251999983, 35.048929840000028],
            [-80.911124252999969, 35.049011841000038],
            [-80.911458252999978, 35.049215840000045],
            [-80.911625252999954, 35.049325841000041],
            [-80.911792251999941, 35.04944684000003],
            [-80.911857252999937, 35.049536841000076],
            [-80.911953252999979, 35.049666841000032],
            [-80.912147252999944, 35.049765841000067],
            [-80.912334253999973, 35.049896840000031],
            [-80.912514252999983, 35.050039841000057],
            [-80.912614252999958, 35.050232841000025],
            [-80.912755252999943, 35.050386840000044],
            [-80.913109252999959, 35.050600841000062],
            [-80.913610252999945, 35.050793841000029],
            [-80.914011253999945, 35.050903842000025],
            [-80.91416325299997, 35.050930841000024],
            [-80.914325252999959, 35.050958841000067],
            [-80.914432253999962, 35.050963841000055],
            [-80.91474025399998, 35.050930840000035],
            [-80.915361253999947, 35.050733841000067],
            [-80.915535253999963, 35.050810841000043],
            [-80.915869253999972, 35.050975841000025],
            [-80.916277253999965, 35.051211841000054],
            [-80.916397253999946, 35.05128884100003],
            [-80.916538254999978, 35.051409841000066],
            [-80.916738254999984, 35.051673841000024],
            [-80.916999253999961, 35.05187684100008],
            [-80.917326254999978, 35.052173841000069],
            [-80.917894254999965, 35.052618841000026],
            [-80.91808025499995, 35.052759842000057],
            [-80.91816225499997, 35.052822841000079],
            [-80.918391253999971, 35.052913841000077],
            [-80.91826825399994, 35.053179842000077],
            [-80.91820725499997, 35.053312841000036],
            [-80.917675254999949, 35.054515842000058],
            [-80.917608253999958, 35.054666842000074],
            [-80.917178253999964, 35.055700842000078],
            [-80.916719253999986, 35.056764842000064],
            [-80.916344253999966, 35.057673842000042],
            [-80.915809253999953, 35.058864843000038],
            [-80.915500254999984, 35.05952084200004],
            [-80.914989253999977, 35.060404843000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 73,
        "SolutionID": "PI-28:B",
        "Shape_Length": 0.5475532533455143,
        "Shape_Area": 0.0074649896806705677
      }
    },
    {
      "type": "Feature",
      "id": 74,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.914989253999977, 35.060404843000072],
            [-80.914438253999947, 35.061246843000049],
            [-80.913850254999943, 35.062104843000043],
            [-80.912442252999938, 35.064201844000024],
            [-80.911695253999937, 35.065261844000077],
            [-80.911639252999976, 35.065351844000077],
            [-80.912108252999985, 35.065541844000052],
            [-80.912220252999987, 35.065603845000055],
            [-80.912348253999937, 35.065673844000059],
            [-80.912536252999985, 35.065836844000046],
            [-80.91271025399999, 35.066099845000053],
            [-80.912821254999983, 35.066313845000025],
            [-80.913107254999943, 35.066265844000043],
            [-80.913340253999934, 35.066226844000028],
            [-80.913318253999989, 35.066132845000027],
            [-80.913310253999953, 35.066046844000027],
            [-80.913312253999948, 35.065989844000057],
            [-80.913452253999935, 35.065798844000028],
            [-80.91351025299997, 35.065735844000073],
            [-80.914207254999951, 35.065055844000028],
            [-80.914235253999948, 35.065037844000074],
            [-80.914329253999938, 35.06478084400004],
            [-80.914524253999957, 35.064136844000075],
            [-80.915413254999976, 35.061316843000043],
            [-80.915867253999977, 35.059836843000028],
            [-80.915889253999978, 35.059789843000033],
            [-80.915919254999949, 35.05975284200008],
            [-80.915961254999957, 35.059728843000073],
            [-80.916012253999952, 35.059714843000052],
            [-80.916039254999987, 35.059712843000057],
            [-80.916100253999957, 35.059707843000069],
            [-80.917095254999936, 35.059771843000078],
            [-80.917751255999974, 35.059796843000072],
            [-80.918370255999946, 35.05984284200008],
            [-80.91949825599994, 35.059906842000032],
            [-80.920073255999966, 35.059941843000047],
            [-80.920778255999949, 35.059906842000032],
            [-80.921307255999977, 35.059774843000071],
            [-80.92136725599994, 35.059755843000062],
            [-80.921792255999947, 35.059618843000067],
            [-80.921841256999983, 35.059591842000032],
            [-80.922197256999937, 35.059396842000069],
            [-80.922940256999937, 35.059042842000053],
            [-80.923551255999939, 35.058802843000024],
            [-80.924208256999975, 35.05864284300003],
            [-80.924731256999962, 35.058576842000036],
            [-80.925057256999935, 35.058570843000041],
            [-80.925812256999961, 35.058557842000027],
            [-80.927123256999948, 35.05854484200006],
            [-80.928606257999945, 35.058545842000058],
            [-80.929580257999987, 35.058525842000051],
            [-80.930051257999935, 35.058535842000026],
            [-80.929875258999971, 35.057933842000068],
            [-80.929483257999948, 35.056639842000038],
            [-80.929173257999935, 35.055662841000071],
            [-80.928978257999972, 35.055078841000068],
            [-80.928640257999973, 35.054007842000033],
            [-80.928380257999947, 35.05318284100008],
            [-80.928259257999969, 35.052751841000031],
            [-80.928167257999974, 35.052422841000066],
            [-80.927905257999953, 35.051666841000042],
            [-80.927487256999939, 35.050347840000029],
            [-80.927288256999987, 35.049715841000079],
            [-80.927137257999959, 35.049184841000056],
            [-80.92706325599994, 35.048708840000074],
            [-80.927058257999988, 35.048310841000045],
            [-80.927064256999984, 35.048190840000075],
            [-80.927108256999986, 35.047856840000065],
            [-80.927399256999934, 35.046291839000048],
            [-80.928062256999965, 35.042697839000027],
            [-80.928454256999942, 35.040768838000076],
            [-80.928693256999964, 35.039633838000043],
            [-80.929271257999972, 35.037809838000044],
            [-80.929627256999936, 35.037125838000065],
            [-80.929981257999941, 35.036590837000062],
            [-80.930203257999949, 35.036288837000029],
            [-80.930243257999962, 35.036235837000049],
            [-80.930252257999939, 35.036238838000031],
            [-80.930294257999947, 35.036246837000078],
            [-80.930509257999972, 35.036271837000072],
            [-80.930683256999941, 35.036279837000052],
            [-80.930769256999952, 35.036287837000032],
            [-80.931077257999959, 35.036285837000037],
            [-80.931341257999975, 35.036271838000062],
            [-80.931471257999988, 35.036260838000032],
            [-80.931556257999944, 35.036249837000071],
            [-80.931643257999951, 35.036244837000027],
            [-80.931908257999964, 35.036239838000029],
            [-80.931995256999983, 35.036243838000075],
            [-80.93212325799999, 35.036258838000037],
            [-80.932250257999954, 35.036283837000042],
            [-80.932374257999982, 35.036314838000067],
            [-80.932455258999937, 35.036340837000068],
            [-80.932694258999959, 35.036434837000058],
            [-80.932895257999974, 35.036503837000055],
            [-80.933018257999947, 35.036541837000073],
            [-80.933146258999955, 35.036556837000035],
            [-80.933322257999976, 35.036563837000074],
            [-80.933364257999983, 35.036554838000029],
            [-80.93351925799999, 35.036489837000033],
            [-80.933601257999953, 35.036462837000045],
            [-80.93380925799994, 35.036402837000026],
            [-80.933895258999939, 35.036394837000046],
            [-80.933984257999953, 35.036393838000038],
            [-80.934064258999967, 35.036418838000031],
            [-80.934138258999951, 35.036455837000062],
            [-80.934177258999966, 35.036481837000053],
            [-80.934249258999955, 35.036522838000053],
            [-80.934326257999942, 35.036554838000029],
            [-80.934529257999941, 35.036611838000056],
            [-80.934771258999945, 35.03668583700005],
            [-80.934809258999962, 35.036702837000064],
            [-80.935049258999982, 35.036780837000038],
            [-80.935127258999955, 35.036811837000073],
            [-80.935209258999976, 35.036836837000067],
            [-80.935327258999962, 35.036879837000072],
            [-80.935522258999981, 35.03695983700004],
            [-80.935720258999936, 35.037031837000029],
            [-80.935885258999974, 35.037068837000049],
            [-80.935923258999935, 35.037084837000066],
            [-80.93604425999996, 35.037122837000027],
            [-80.936127259999978, 35.037142837000033],
            [-80.93624725899997, 35.037184837000041],
            [-80.936393258999942, 35.037262837000071],
            [-80.936473259999957, 35.037288837000062],
            [-80.936512259999972, 35.037305837000076],
            [-80.936719258999972, 35.037357837000059],
            [-80.936839258999953, 35.037401837000061],
            [-80.936992258999965, 35.03746983700006],
            [-80.937246258999949, 35.037603837000063],
            [-80.937285258999964, 35.037620838000066],
            [-80.937355260999936, 35.037661838000076],
            [-80.937431259999983, 35.037697837000053],
            [-80.937576258999968, 35.037748837000038],
            [-80.937820259999967, 35.037822838000068],
            [-80.937903258999938, 35.037842838000074],
            [-80.938065259999973, 35.037894838000057],
            [-80.938188259999947, 35.037929838000025],
            [-80.938272260999952, 35.037948837000044],
            [-80.938476259999959, 35.038006837000069],
            [-80.938556259999984, 35.038034838000044],
            [-80.938866259999941, 35.038166837000063],
            [-80.939008260999969, 35.038243838000028],
            [-80.939085260999946, 35.038275837000072],
            [-80.939156259999947, 35.038316837000025],
            [-80.939320260999978, 35.038431837000076],
            [-80.939385259999938, 35.038521837000076],
            [-80.939418260999958, 35.038586837000025],
            [-80.939440259999969, 35.038655838000068],
            [-80.939485259999969, 35.038864837000062],
            [-80.939494259999947, 35.038969838000071],
            [-80.939398259999962, 35.039426838000054],
            [-80.939324260999967, 35.039847838000071],
            [-80.939319259999934, 35.039917838000065],
            [-80.939265260999946, 35.040339838000079],
            [-80.939247260999934, 35.040444837000052],
            [-80.939211259999979, 35.040582838000034],
            [-80.939170259999969, 35.040682838000066],
            [-80.939115259999937, 35.040776838000056],
            [-80.939071259999935, 35.040835838000078],
            [-80.939007259999983, 35.040884838000068],
            [-80.938936259999934, 35.040925838000078],
            [-80.938775260999989, 35.040978838000058],
            [-80.938488259999986, 35.041064838000068],
            [-80.938455259999955, 35.041088838000064],
            [-80.938401260999967, 35.041143838000039],
            [-80.938367260999939, 35.04116583800004],
            [-80.938255259999949, 35.041220838000072],
            [-80.938214259999938, 35.041232838000042],
            [-80.938129259999982, 35.041247838000061],
            [-80.938045259999967, 35.041267838000067],
            [-80.938005259999954, 35.041282838000029],
            [-80.937933259999966, 35.041322838000042],
            [-80.937854259999938, 35.041407838000055],
            [-80.937801259999958, 35.041538838000065],
            [-80.937715259999948, 35.041662839000026],
            [-80.937580259999947, 35.041845838000029],
            [-80.937540259999935, 35.041945838000061],
            [-80.937516259999938, 35.04204783800003],
            [-80.93751825999999, 35.042083838000053],
            [-80.937504259999969, 35.042151839000041],
            [-80.937501259999976, 35.042222838000043],
            [-80.937508259999959, 35.042293838000035],
            [-80.937532258999966, 35.042396839000048],
            [-80.93756725999998, 35.042497838000031],
            [-80.937628260999986, 35.042629838000039],
            [-80.937653259999934, 35.04269683900003],
            [-80.937745259999986, 35.042856839000024],
            [-80.937793259999978, 35.042916838000053],
            [-80.937821260999954, 35.042942838000045],
            [-80.937854259999938, 35.042964838000046],
            [-80.93789425999995, 35.04297783800007],
            [-80.93794625999999, 35.042979839000054],
            [-80.938058260999981, 35.042955838000069],
            [-80.938101259999939, 35.042951839000068],
            [-80.93818725999995, 35.042961839000043],
            [-80.938270259999967, 35.042979838000065],
            [-80.938428260999956, 35.043100839000033],
            [-80.938567259999957, 35.043238838000036],
            [-80.93864425999999, 35.043324838000046],
            [-80.938683259999948, 35.04338683900005],
            [-80.938697259999969, 35.043420838000031],
            [-80.938801259999934, 35.043766839000057],
            [-80.938848259999986, 35.043865839000034],
            [-80.938885260999939, 35.043929838000054],
            [-80.938933260999988, 35.044027838000034],
            [-80.939015259999962, 35.044228838000038],
            [-80.939032260999966, 35.044369839000069],
            [-80.939031260999968, 35.044404838000048],
            [-80.93902425999994, 35.044439839000063],
            [-80.93899726099994, 35.044506839000064],
            [-80.938970260999952, 35.044535838000058],
            [-80.938931260999937, 35.044550839000067],
            [-80.938755260999983, 35.044559838000055],
            [-80.938711259999934, 35.044556838000062],
            [-80.938668260999975, 35.044547839000074],
            [-80.938626259999978, 35.044543839000028],
            [-80.938583260999962, 35.044549839000069],
            [-80.938542259999963, 35.044562839000037],
            [-80.938516259999972, 35.044591839000077],
            [-80.938495260999957, 35.044622839000056],
            [-80.938452259999963, 35.044723839000028],
            [-80.938443259999985, 35.04479483800003],
            [-80.938451259999965, 35.044864838000024],
            [-80.938463260999981, 35.044898839000041],
            [-80.938484260999985, 35.044929839000076],
            [-80.938586260999955, 35.04504483900007],
            [-80.93862126099998, 35.045065839000074],
            [-80.938661260999936, 35.045080839000036],
            [-80.938743259999967, 35.045103839000035],
            [-80.938787260999959, 35.045111839000072],
            [-80.938873260999969, 35.045119839000051],
            [-80.939005259999988, 35.045124839000039],
            [-80.939222260999941, 35.045103838000045],
            [-80.939690260999953, 35.045025839000061],
            [-80.939731261999952, 35.04501583900003],
            [-80.939816259999986, 35.045002839000063],
            [-80.939901259999942, 35.044983839000054],
            [-80.940071260999957, 35.044959839000057],
            [-80.940198261999967, 35.044933839000066],
            [-80.940236260999939, 35.044915839000055],
            [-80.940316260999964, 35.044885839000074],
            [-80.940398260999984, 35.044863838000026],
            [-80.94048326099994, 35.044851838000056],
            [-80.940616260999946, 35.044858839000028],
            [-80.94107126199998, 35.044875839000042],
            [-80.941178261999937, 35.044712839000056],
            [-80.94214526199994, 35.043246838000073],
            [-80.943381261999946, 35.041348838000033],
            [-80.944018261999986, 35.040411838000068],
            [-80.944430261999969, 35.04079383800007],
            [-80.944861261999961, 35.041121838000038],
            [-80.945253262999984, 35.041306838000025],
            [-80.945691261999968, 35.041423838000071],
            [-80.94630226299995, 35.041476838000051],
            [-80.946881262999966, 35.041402838000067],
            [-80.947691262999967, 35.041223838000064],
            [-80.948257263999949, 35.041191838000032],
            [-80.948733262999951, 35.041202837000071],
            [-80.949170262999985, 35.041281837000042],
            [-80.94961426399999, 35.041419837000035],
            [-80.949730262999935, 35.041473837000069],
            [-80.949989263999953, 35.041594838000037],
            [-80.950299263999966, 35.041776838000033],
            [-80.95060226399994, 35.041998837000051],
            [-80.951189263999936, 35.04245983800007],
            [-80.95175726399998, 35.042843837000078],
            [-80.951961263999976, 35.042953837000027],
            [-80.952217263999955, 35.04303883800003],
            [-80.952404264999984, 35.043087838000076],
            [-80.952585264999982, 35.043119838000052],
            [-80.952802263999956, 35.043121838000047],
            [-80.953173264999975, 35.043107838000026],
            [-80.953215263999937, 35.043101838000041],
            [-80.953866264999988, 35.043014838000033],
            [-80.954299264999975, 35.042976838000072],
            [-80.954768265999974, 35.04298183800006],
            [-80.955632264999963, 35.043017838000026],
            [-80.958185264999941, 35.04312483800004],
            [-80.95849926599999, 35.043136838000066],
            [-80.959050265999963, 35.043163838000055],
            [-80.959289266999974, 35.043175838000025],
            [-80.959632265999971, 35.04341983900008],
            [-80.95964326699999, 35.043358838000074],
            [-80.959646266999982, 35.043339838000065],
            [-80.959644266999987, 35.043184838000059],
            [-80.959844265999948, 35.04181883800004],
            [-80.959920265999983, 35.041082837000033],
            [-80.959993266999959, 35.040367837000076],
            [-80.960080265999977, 35.039849837000077],
            [-80.960200265999958, 35.039141837000045],
            [-80.960365266999986, 35.037628836000067],
            [-80.960451266999939, 35.037096837000036],
            [-80.960460265999984, 35.037041837000061],
            [-80.960594266999976, 35.036217836000048],
            [-80.960910266999974, 35.035021835000066],
            [-80.961365265999973, 35.033851835000064],
            [-80.961512266999989, 35.033625836000056],
            [-80.961928266999962, 35.032663835000051],
            [-80.962601266999968, 35.03148983400007],
            [-80.963267266999935, 35.030497835000062],
            [-80.963879266999982, 35.029639834000079],
            [-80.964316267999948, 35.028995834000057],
            [-80.964751267999986, 35.028441835000024],
            [-80.964910266999937, 35.028199834000077],
            [-80.964985266999975, 35.028087835000065],
            [-80.965128266999955, 35.027896834000046],
            [-80.965333267999938, 35.028104834000032],
            [-80.965825267999946, 35.028657835000047],
            [-80.966062267999973, 35.028947834000064],
            [-80.966421267999976, 35.029384834000041],
            [-80.966790267999954, 35.029762835000042],
            [-80.966930268999988, 35.029922835000036],
            [-80.96736526899997, 35.030421835000027],
            [-80.967721267999934, 35.030810835000068],
            [-80.967896266999958, 35.03100583500003],
            [-80.968310268999971, 35.03146683500006],
            [-80.968529267999941, 35.031709835000072],
            [-80.968899267999973, 35.032133835000025],
            [-80.969400267999958, 35.032694835000029],
            [-80.969583268999941, 35.03281783500006],
            [-80.969834268999989, 35.032994835000068],
            [-80.97083626999995, 35.032332835000034],
            [-80.971888269999965, 35.031637835000026],
            [-80.973203268999953, 35.030654834000075],
            [-80.973662269999977, 35.030282834000047],
            [-80.973981269999967, 35.029825835000054],
            [-80.974217268999951, 35.029467834000059],
            [-80.974330269999939, 35.028814834000059],
            [-80.97434426999996, 35.028624834000027],
            [-80.974362269999972, 35.028381834000072],
            [-80.974328269999944, 35.027979834000064],
            [-80.974315268999987, 35.027821834000065],
            [-80.974252269999965, 35.027200833000052],
            [-80.974238269999944, 35.027057834000061],
            [-80.974038269999937, 35.025312833000044],
            [-80.974069269999973, 35.024932834000026],
            [-80.974193269999944, 35.024541834000047],
            [-80.974212269999953, 35.024511834000066],
            [-80.974403269999982, 35.024213833000033],
            [-80.974566269999968, 35.024007833000042],
            [-80.974791269999969, 35.023821832000067],
            [-80.975296269999944, 35.023506833000056],
            [-80.975682269999936, 35.023265833000039],
            [-80.975871269999971, 35.023161833000074],
            [-80.976505270999951, 35.022790833000045],
            [-80.977229269999953, 35.022309832000076],
            [-80.977436270999988, 35.022136833000047],
            [-80.977688270999977, 35.021940832000041],
            [-80.978015270999947, 35.021654833000071],
            [-80.978965271999982, 35.020816833000026],
            [-80.979247270999963, 35.02047783200004],
            [-80.979416270999934, 35.020277832000033],
            [-80.97975827099998, 35.01960383200003],
            [-80.979838270999949, 35.019392832000051],
            [-80.980071270999986, 35.018891832000065],
            [-80.980256270999973, 35.018456832000027],
            [-80.980415271999959, 35.018103832000065],
            [-80.980603271999939, 35.017645831000038],
            [-80.980785270999945, 35.017243832000077],
            [-80.981077271999936, 35.016538831000048],
            [-80.981147271999987, 35.016356831000053],
            [-80.98143227099996, 35.015714831000025],
            [-80.981794271999945, 35.014852831000042],
            [-80.981899270999975, 35.01433583000005],
            [-80.981905271999949, 35.014146831000062],
            [-80.981916272999968, 35.013769831000047],
            [-80.981863271999941, 35.013362831000052],
            [-80.981803271999979, 35.013154831000065],
            [-80.981601270999988, 35.012730830000066],
            [-80.981231271999945, 35.01211083000004],
            [-80.980955270999971, 35.011511830000074],
            [-80.980839270999979, 35.010867830000052],
            [-80.980857270999934, 35.010666830000048],
            [-80.98082027099997, 35.010180830000024],
            [-80.980781270999955, 35.009359830000051],
            [-80.980756270999962, 35.008690829000045],
            [-80.98074926999999, 35.008230830000059],
            [-80.980712270999959, 35.008055829000057],
            [-80.980658270999982, 35.00669982900007],
            [-80.980575269999974, 35.005568829000026],
            [-80.980429270999934, 35.004777828000044],
            [-80.980377270999952, 35.004563829000062],
            [-80.980240270999957, 35.004052828000056],
            [-80.980166269999984, 35.003746829000079],
            [-80.980030270999976, 35.003194828000062],
            [-80.979937269999937, 35.002948828000058],
            [-80.979917270999977, 35.002764828000068],
            [-80.979825269999935, 35.002500828000052],
            [-80.979487270999982, 35.00175082800007],
            [-80.979425269999979, 35.00161182800008],
            [-80.979347269999948, 35.001458828000068],
            [-80.979223270999967, 35.001229828000078],
            [-80.979033269999945, 35.000830828000062],
            [-80.97898626999995, 35.00073082800003],
            [-80.978887269999973, 35.000568828000041],
            [-80.978607268999951, 35.000110828000061],
            [-80.978402269999947, 34.999878827000032],
            [-80.978086269999949, 34.999520828000072],
            [-80.977929269999947, 34.999381828000026],
            [-80.977705269999944, 34.999181828000076],
            [-80.977561269999967, 34.999083827000049],
            [-80.977455268999961, 34.99901182800005],
            [-80.977232269999945, 34.998859827000047],
            [-80.97672326899999, 34.99855382700008],
            [-80.976156268999944, 34.998231827000041],
            [-80.976431268999988, 34.997797827000056],
            [-80.977612268999962, 34.996036827000069],
            [-80.978239268999971, 34.995121827000048],
            [-80.978861268999935, 34.994196827000053],
            [-80.979399269999988, 34.993360826000071],
            [-80.97945926999995, 34.993277827000043],
            [-80.980068269999947, 34.992435827000065],
            [-80.980635268999947, 34.99157582600003],
            [-80.980870268999979, 34.991218826000079],
            [-80.98181526999997, 34.989809826000055],
            [-80.98256226999996, 34.988703825000073],
            [-80.982866269999988, 34.988240825000048],
            [-80.983313270999986, 34.987574825000024],
            [-80.983357270999988, 34.987510825000072],
            [-80.983400270999937, 34.987445825000066],
            [-80.983757270999945, 34.986917825000035],
            [-80.983784270999934, 34.986879825000074],
            [-80.986699271999953, 34.982567824000057],
            [-80.987254270999983, 34.981746824000027],
            [-80.987631271999987, 34.981142823000027],
            [-80.987672271999941, 34.981089823000048],
            [-80.987897271999941, 34.980592823000052],
            [-80.988101271999938, 34.97993282300007],
            [-80.988272270999971, 34.97899882300004],
            [-80.988291271999969, 34.978831823000064],
            [-80.988308271999983, 34.978543822000063],
            [-80.988307271999986, 34.97825582300004],
            [-80.988289271999975, 34.977967823000029],
            [-80.988251271999957, 34.977681822000079],
            [-80.988196270999936, 34.977396823000049],
            [-80.988122270999952, 34.977114823000079],
            [-80.988040271999978, 34.976839823000034],
            [-80.987346271999968, 34.976838823000037],
            [-80.987052270999982, 34.976824822000026],
            [-80.986787270999969, 34.976796823000029],
            [-80.986568270999953, 34.976756823000073],
            [-80.986528270999941, 34.976749822000045],
            [-80.986265270999979, 34.976681823000035],
            [-80.986010271999987, 34.976594823000028],
            [-80.985765270999934, 34.976488823000068],
            [-80.98539126999998, 34.976250822000054],
            [-80.984080269999936, 34.975415822000059],
            [-80.983805269999948, 34.975240823000036],
            [-80.983468269999946, 34.975025823000067],
            [-80.982901269999957, 34.974665822000077],
            [-80.98204026999997, 34.974022822000052],
            [-80.982000269999958, 34.973992822000071],
            [-80.981573268999966, 34.973580822000031],
            [-80.981030268999973, 34.973007822000056],
            [-80.98096426799998, 34.97293882200006],
            [-80.980643269999973, 34.972639822000076],
            [-80.980623268999977, 34.97262382200006],
            [-80.980410268999947, 34.972453822000034],
            [-80.979654269999969, 34.971919822000075],
            [-80.978855267999961, 34.971368822000045],
            [-80.976568267999937, 34.969756821000033],
            [-80.976061267999967, 34.969385822000049],
            [-80.975956267999948, 34.969304821000037],
            [-80.975416267999947, 34.968887821000067],
            [-80.974640266999984, 34.968251821000024],
            [-80.973905266999964, 34.967643821000024],
            [-80.973429266999972, 34.967248820000066],
            [-80.972857266999938, 34.966782820000049],
            [-80.972895265999966, 34.966384821000076],
            [-80.973030265999967, 34.965636821000032],
            [-80.973357265999937, 34.964361820000079],
            [-80.973494266999978, 34.963864820000026],
            [-80.973828265999941, 34.962654820000068],
            [-80.974257265999938, 34.960866820000035],
            [-80.974646265999979, 34.959449819000042],
            [-80.974781265999979, 34.958251819000054],
            [-80.974476266999943, 34.95679381900004],
            [-80.97380226699994, 34.955353818000049],
            [-80.973128265999947, 34.953874818000031],
            [-80.972415265999985, 34.953154817000041],
            [-80.971174264999945, 34.952778817000024],
            [-80.969977264999955, 34.95296281800006],
            [-80.969147263999957, 34.953479818000062],
            [-80.969009263999965, 34.953565818000072],
            [-80.968141264999986, 34.954303818000028],
            [-80.967482264999944, 34.954806818000066],
            [-80.966070263999939, 34.956217819000074],
            [-80.964417263999962, 34.957914819000052],
            [-80.963948263999953, 34.958390819000044],
            [-80.96358526399996, 34.958758820000071],
            [-80.962774262999972, 34.959600820000048],
            [-80.962706263999962, 34.95967182000004],
            [-80.960551262999957, 34.961875820000046],
            [-80.960518261999937, 34.961910820000071],
            [-80.960483262999958, 34.961945820000039],
            [-80.960383262999983, 34.962047820000066],
            [-80.959785262999958, 34.962659820000056],
            [-80.959756262999974, 34.962632821000057],
            [-80.959599261999983, 34.96230182000005],
            [-80.959106261999978, 34.961773820000076],
            [-80.958958261999953, 34.961614820000079],
            [-80.958332261999942, 34.961286820000055],
            [-80.957844261999981, 34.961141821000069],
            [-80.957706260999942, 34.961129820000053],
            [-80.957187260999945, 34.961083820000056],
            [-80.957142260999944, 34.961073820000024],
            [-80.957035260999987, 34.961071821000075],
            [-80.956074260999969, 34.961064821000036],
            [-80.955052259999945, 34.960858820000055],
            [-80.953694260999953, 34.960583820000068],
            [-80.952777260999937, 34.960034820000033],
            [-80.951785259999951, 34.959454820000076],
            [-80.948775258999945, 34.956501820000028],
            [-80.948688258999937, 34.956416819000026],
            [-80.948077258999945, 34.955837820000056],
            [-80.946933257999945, 34.954982819000065],
            [-80.945438257999967, 34.954295820000027],
            [-80.943759256999954, 34.953464819000033],
            [-80.942233255999952, 34.953014819000032],
            [-80.941780256999948, 34.952937819000056],
            [-80.941302255999972, 34.952861819000077],
            [-80.941285255999958, 34.952861819000077],
            [-80.940828255999975, 34.952834819000032],
            [-80.940449255999965, 34.952836818000037],
            [-80.940097255999945, 34.95286081900008],
            [-80.939879255999983, 34.952887819000068],
            [-80.939660255999968, 34.952908819000072],
            [-80.93926225599995, 34.952914819000057],
            [-80.938815255999941, 34.952975819000073],
            [-80.93799325599997, 34.953171819000033],
            [-80.93791525499995, 34.953189819000045],
            [-80.937595254999962, 34.953225819000068],
            [-80.936740254999961, 34.953322820000039],
            [-80.935886254999957, 34.95342081900003],
            [-80.935834254999975, 34.953401819000078],
            [-80.934573253999986, 34.953304819000039],
            [-80.932940253999959, 34.952968820000024],
            [-80.93182225299995, 34.95261682000006],
            [-80.929907251999964, 34.952014820000045],
            [-80.928160251999941, 34.951464820000069],
            [-80.927675251999972, 34.951312819000066],
            [-80.927251251999962, 34.951209819000042],
            [-80.92613425199994, 34.950938820000033],
            [-80.924486250999962, 34.950671818000046],
            [-80.923081250999985, 34.950568819000068],
            [-80.922396250999952, 34.950519819000078],
            [-80.922139249999987, 34.950508820000039],
            [-80.921655250999947, 34.950487820000035],
            [-80.921171249999986, 34.950467819000039],
            [-80.920244249999939, 34.950428819000024],
            [-80.92012724999995, 34.950405819000025],
            [-80.918703248999975, 34.950131819000035],
            [-80.918643249999946, 34.950332820000028],
            [-80.918527248999965, 34.950723819000075],
            [-80.91841224999996, 34.951114819000054],
            [-80.918313248999937, 34.951103819000025],
            [-80.918171248999954, 34.951081819000024],
            [-80.916864248999957, 34.952857820000077],
            [-80.91721124999998, 34.953132820000064],
            [-80.917432248999944, 34.953298820000043],
            [-80.913241247999963, 34.962145822000025],
            [-80.910867247999988, 34.967158823000034],
            [-80.91079624799994, 34.967059823000056],
            [-80.910639246999949, 34.966866823000032],
            [-80.91042824699997, 34.966651823000063],
            [-80.910406246999969, 34.966632823000054],
            [-80.910171246999937, 34.966423823000071],
            [-80.909863246999976, 34.966205823000053],
            [-80.909594246999973, 34.966042823000066],
            [-80.909345246999976, 34.965912823000053],
            [-80.909001246999935, 34.965766823000024],
            [-80.908677246999957, 34.965659823000067],
            [-80.908309246999977, 34.965559823000035],
            [-80.907908246999966, 34.965482823000059],
            [-80.907583246999934, 34.965443823000044],
            [-80.906804246999968, 34.965319823000073],
            [-80.906446246999963, 34.965233823000062],
            [-80.906065245999969, 34.965120823000063],
            [-80.905493245999935, 34.96490882300003],
            [-80.905089245999989, 34.964701823000041],
            [-80.904748246999986, 34.96449982200005],
            [-80.904210245999934, 34.964105823000068],
            [-80.903742245999979, 34.963739823000026],
            [-80.903411245999962, 34.963494823000076],
            [-80.901857244999974, 34.96227882200003],
            [-80.900624243999971, 34.96133582300007],
            [-80.900331243999972, 34.96111082200008],
            [-80.900131243999965, 34.960958823000055],
            [-80.89994924399997, 34.960812822000037],
            [-80.899385244999962, 34.960418823000055],
            [-80.898708242999987, 34.959923822000064],
            [-80.898387243999935, 34.95972282200006],
            [-80.898098243999982, 34.959541823000052],
            [-80.897287243999983, 34.959042822000072],
            [-80.89644924299995, 34.958519821000039],
            [-80.895758242999989, 34.958108821000053],
            [-80.895359241999984, 34.957905823000033],
            [-80.89514724299994, 34.95775382100004],
            [-80.894264242999952, 34.957332822000069],
            [-80.893825242999981, 34.957189821000043],
            [-80.893381241999975, 34.957081822000077],
            [-80.892898241999944, 34.956990821000034],
            [-80.89213724199999, 34.956923822000078],
            [-80.890897241999937, 34.956839822000063],
            [-80.890259241999956, 34.956845822000048],
            [-80.88961723999995, 34.956878821000032],
            [-80.887978240999985, 34.956982822000043],
            [-80.887511239999981, 34.956996822000065],
            [-80.887358239999969, 34.957000822000055],
            [-80.886818240999958, 34.956970822000073],
            [-80.886366239999973, 34.956911821000062],
            [-80.886016239999947, 34.956856822000077],
            [-80.885304239999982, 34.956669822000038],
            [-80.88499823899997, 34.956571822000058],
            [-80.884580239999934, 34.956452822000074],
            [-80.883773238999936, 34.956147822000048],
            [-80.883481238999934, 34.956045822000078],
            [-80.882838239999955, 34.955792822000035],
            [-80.88225923899995, 34.955525822000027],
            [-80.87986923699998, 34.954280822000044],
            [-80.879192236999984, 34.953959821000069],
            [-80.87887823799997, 34.953864822000071],
            [-80.87770123699994, 34.953587822000031],
            [-80.876505236999947, 34.953403821000052],
            [-80.874239235999937, 34.95300282200003],
            [-80.873864235999974, 34.952941821000024],
            [-80.873328235999963, 34.952846822000026],
            [-80.872908235999944, 34.952751821000049],
            [-80.872447234999981, 34.952606821000074],
            [-80.871997234999981, 34.95242182100003],
            [-80.871406235999984, 34.95212482200003],
            [-80.870859234999955, 34.951858822000077],
            [-80.86873323399999, 34.95078482100007],
            [-80.868760234999968, 34.95064182200008],
            [-80.868833234999954, 34.950509821000026],
            [-80.869040234999943, 34.950317821000056],
            [-80.869340234999981, 34.950086820000024],
            [-80.869607235999979, 34.949838821000071],
            [-80.869767234999983, 34.949624821000043],
            [-80.869894234999947, 34.94940482100003],
            [-80.86998723499994, 34.949157821000028],
            [-80.870054234999941, 34.948799820000033],
            [-80.870140234999951, 34.948156820000065],
            [-80.870160234999958, 34.947689820000051],
            [-80.870145234999939, 34.947362820000023],
            [-80.869959234999953, 34.947288821000029],
            [-80.86997923499996, 34.947608821000074],
            [-80.869973234999975, 34.947730820000061],
            [-80.869976234999967, 34.947846820000052],
            [-80.869957234999958, 34.948145821000026],
            [-80.869866233999971, 34.948785821000058],
            [-80.869807234999939, 34.949030822000054],
            [-80.869776234999961, 34.94913482000004],
            [-80.869699234999985, 34.949339821000024],
            [-80.86964523499995, 34.949453821000077],
            [-80.869394233999969, 34.949634821000075],
            [-80.869125234999956, 34.949890821000054],
            [-80.869051233999983, 34.949967822000076],
            [-80.868928233999952, 34.950106821000077],
            [-80.868897233999974, 34.950149820000036],
            [-80.868739234999964, 34.950401821000071],
            [-80.868593234999935, 34.950717821000069],
            [-80.86858323499996, 34.950773821000041],
            [-80.86858323499996, 34.950789821000058],
            [-80.868607233999967, 34.950984821000077],
            [-80.868655233999959, 34.951149821000058],
            [-80.868834233999962, 34.951455821000025],
            [-80.868853233999971, 34.951475821000031],
            [-80.86886823399999, 34.95149882100003],
            [-80.868994234999946, 34.951645822000046],
            [-80.869045234999987, 34.951739821000047],
            [-80.869091234999985, 34.951807821000045],
            [-80.869160233999935, 34.95189182200005],
            [-80.869272234999983, 34.952008822000039],
            [-80.869332234999945, 34.952061821000029],
            [-80.869476234999979, 34.952178821000075],
            [-80.869874234999941, 34.95244282200008],
            [-80.869905234999976, 34.952459821000048],
            [-80.870721233999973, 34.952992821000066],
            [-80.870908235999934, 34.953109821000055],
            [-80.871432234999986, 34.953371821000076],
            [-80.87143823599996, 34.953375822000055],
            [-80.871851235999941, 34.953631822000034],
            [-80.872217235999983, 34.953941822000047],
            [-80.872335235999969, 34.954046822000066],
            [-80.872392235999939, 34.954106822000028],
            [-80.872640235999938, 34.954367822000052],
            [-80.872840235999945, 34.954603822000024],
            [-80.872930235999945, 34.954725822000057],
            [-80.873271235999937, 34.955225822000045],
            [-80.873445235999952, 34.955473822000044],
            [-80.873498235999989, 34.955571822000024],
            [-80.873609235999936, 34.95588082200004],
            [-80.873642236999956, 34.955983822000064],
            [-80.873692235999954, 34.956082822000042],
            [-80.873886236999965, 34.956704822000063],
            [-80.873944236999989, 34.957022823000045],
            [-80.873943236999935, 34.957271822000052],
            [-80.873942235999948, 34.957280823000076],
            [-80.873938235999958, 34.957342822000044],
            [-80.873923236999985, 34.957412822000038],
            [-80.873871236999946, 34.957586823000042],
            [-80.873825236999949, 34.957760822000068],
            [-80.873780236999949, 34.95789882300005],
            [-80.873698235999939, 34.95810182200006],
            [-80.873581236999939, 34.958331823000037],
            [-80.873535235999952, 34.95840882300007],
            [-80.873375235999958, 34.958685823000053],
            [-80.873215235999965, 34.958898824000073],
            [-80.872893235999982, 34.959280823000029],
            [-80.872791235999955, 34.959438823000028],
            [-80.872737236999967, 34.959573823000028],
            [-80.872728235999944, 34.959608823000053],
            [-80.872713235999981, 34.959750823000036],
            [-80.872696235999967, 34.960333823000042],
            [-80.872693236999964, 34.960369823000065],
            [-80.872696235999967, 34.960654824000073],
            [-80.87268723599999, 34.960974823000072],
            [-80.872668236999971, 34.961293823000062],
            [-80.872638235999943, 34.96147082400006],
            [-80.872632235999959, 34.961541823000061],
            [-80.87263523699994, 34.961755823000033],
            [-80.872669235999979, 34.961858823000057],
            [-80.872720235999964, 34.961957823000034],
            [-80.872821236999982, 34.96211582400008],
            [-80.873046235999936, 34.962403824000035],
            [-80.873048235999988, 34.962405824000029],
            [-80.873174236999944, 34.962566824000078],
            [-80.873180235999939, 34.962575824000055],
            [-80.873218235999957, 34.962628823000045],
            [-80.873413235999976, 34.96294882400008],
            [-80.873502236999968, 34.963134824000065],
            [-80.873508236999953, 34.963147824000032],
            [-80.873527236999962, 34.963216824000028],
            [-80.873568236999972, 34.963340824000056],
            [-80.873604236999938, 34.963445824000075],
            [-80.873620236999955, 34.96349282400007],
            [-80.873650235999946, 34.963595824000038],
            [-80.873673236999934, 34.963701824000054],
            [-80.873678236999979, 34.963771824000048],
            [-80.873684235999974, 34.963843824000037],
            [-80.873695236999936, 34.963882824000052],
            [-80.87370423599998, 34.963912824000033],
            [-80.87374523699998, 34.96409682400008],
            [-80.873767236999981, 34.964192824000065],
            [-80.873841236999965, 34.964579823000065],
            [-80.873891236999953, 34.964740824000046],
            [-80.873917236999944, 34.964820824000071],
            [-80.873930236999968, 34.964890824000065],
            [-80.873947236999982, 34.964953824000077],
            [-80.873949237999966, 34.964960824000059],
            [-80.873977235999973, 34.965044824000074],
            [-80.873996236999972, 34.965097824000054],
            [-80.874057235999942, 34.965191824000044],
            [-80.874139236999952, 34.965275825000049],
            [-80.874141236999947, 34.965276824000057],
            [-80.874171236999985, 34.965300824000053],
            [-80.874221236999972, 34.965331824000032],
            [-80.874307237999972, 34.965345825000043],
            [-80.87443923699999, 34.965360824000072],
            [-80.874483236999936, 34.965359824000075],
            [-80.874742236999964, 34.96531682300008],
            [-80.874783236999974, 34.965307824000035],
            [-80.874826236999979, 34.965297823000071],
            [-80.875042236999946, 34.965258824000045],
            [-80.875249236999935, 34.965200824000078],
            [-80.875372237999954, 34.965160823000076],
            [-80.875451236999936, 34.96512982400003],
            [-80.875525237999966, 34.965091824000069],
            [-80.875681236999981, 34.965024823000078],
            [-80.875823237999953, 34.964923825000028],
            [-80.876012237999987, 34.964788824000038],
            [-80.876210237999942, 34.964630824000039],
            [-80.876294237999957, 34.964563823000049],
            [-80.876445237999974, 34.964435825000066],
            [-80.876572237999937, 34.964335824000045],
            [-80.876639237999939, 34.964289824000048],
            [-80.876753237999935, 34.96423582400007],
            [-80.876825236999935, 34.964195824000058],
            [-80.876861237999947, 34.964177824000046],
            [-80.876975237999943, 34.964120824000076],
            [-80.877079236999975, 34.964057824000065],
            [-80.877147237999964, 34.964011824000067],
            [-80.877210237999975, 34.96396182400008],
            [-80.877294237999934, 34.963880823000068],
            [-80.877407237999989, 34.963728824000043],
            [-80.877470237999944, 34.963633824000055],
            [-80.877520237999988, 34.963575824000031],
            [-80.87758023799995, 34.963524823000057],
            [-80.877617237999971, 34.963505823000048],
            [-80.877711237999961, 34.963473823000072],
            [-80.877717238999935, 34.963479824000046],
            [-80.877764237999941, 34.963518824000062],
            [-80.877781236999965, 34.963530823000042],
            [-80.877819236999983, 34.963550823000048],
            [-80.877902236999944, 34.963576824000029],
            [-80.877989237999941, 34.963586823000071],
            [-80.878076236999959, 34.963580825000065],
            [-80.878140237999958, 34.963573823000047],
            [-80.878182238999955, 34.963564824000059],
            [-80.878221237999981, 34.96354982400004],
            [-80.87827823899994, 34.963525823000055],
            [-80.878295237999964, 34.963515824000069],
            [-80.878359238999963, 34.963468823000028],
            [-80.878388236999967, 34.963443824000024],
            [-80.878427237999972, 34.963401823000027],
            [-80.878481237999949, 34.963348823000047],
            [-80.878525237999952, 34.963310823000029],
            [-80.87869823799997, 34.963180824000062],
            [-80.878748237999957, 34.963148824000029],
            [-80.87878823799997, 34.963135824000062],
            [-80.878871236999942, 34.963117824000051],
            [-80.878891237999937, 34.963110824000069],
            [-80.87892323799997, 34.963088824000067],
            [-80.878952237999954, 34.963062824000076],
            [-80.878988237999977, 34.963019824000071],
            [-80.879053237999983, 34.962951824000072],
            [-80.879100238999968, 34.962915824000049],
            [-80.879134237999949, 34.962894824000045],
            [-80.879170237999972, 34.962876824000034],
            [-80.879225237999947, 34.962851823000051],
            [-80.879304237999975, 34.962822823000067],
            [-80.879342237999936, 34.962807823000048],
            [-80.879402238999944, 34.962787823000042],
            [-80.87943723799998, 34.962768823000033],
            [-80.879506238999966, 34.962726824000072],
            [-80.879589238999984, 34.962672823000048],
            [-80.879741238999941, 34.962577823000061],
            [-80.879845238999962, 34.962521824000078],
            [-80.880772238999953, 34.964879824000036],
            [-80.880739238999979, 34.964967823000052],
            [-80.880731238999942, 34.965002824000067],
            [-80.880731238999942, 34.965108824000026],
            [-80.88071623899998, 34.965251824000063],
            [-80.880697238999971, 34.96532082300007],
            [-80.880646238999987, 34.965456824000057],
            [-80.880626237999934, 34.965525825000043],
            [-80.880615238999951, 34.96559482400005],
            [-80.880614238999954, 34.965630824000073],
            [-80.880621238999936, 34.965665824000041],
            [-80.880648237999935, 34.965732824000042],
            [-80.880709238999941, 34.965825824000035],
            [-80.880860237999968, 34.965998824000053],
            [-80.880941237999934, 34.966081824000071],
            [-80.880973238999957, 34.966106824000065],
            [-80.881026238999937, 34.966161824000039],
            [-80.881468238999958, 34.96655482500006],
            [-80.88159823899997, 34.966650824000055],
            [-80.881668239999954, 34.966692824000063],
            [-80.881743238999945, 34.966729824000026],
            [-80.881792238999935, 34.96673882500005],
            [-80.881849238999962, 34.966739825000047],
            [-80.881926238999938, 34.966733825000063],
            [-80.881979239999964, 34.966740824000055],
            [-80.882014238999943, 34.966739825000047],
            [-80.882074238999962, 34.966726824000034],
            [-80.882096238999964, 34.966721824000047],
            [-80.882132238999986, 34.966703824000035],
            [-80.882160239999962, 34.966677825000033],
            [-80.882234239999946, 34.966592824000031],
            [-80.882276239999953, 34.966532824000069],
            [-80.882301239999947, 34.966398824000066],
            [-80.882303239999942, 34.966263824000066],
            [-80.882327238999949, 34.966196823000075],
            [-80.882371239999941, 34.966101823000031],
            [-80.882434239999952, 34.966012824000074],
            [-80.882502238999962, 34.965885824000054],
            [-80.882504239999946, 34.965851824000026],
            [-80.882497238999974, 34.965784824000025],
            [-80.882464238999944, 34.965584824000075],
            [-80.882479238999963, 34.96541482300006],
            [-80.88248423899995, 34.96527982300006],
            [-80.882468238999934, 34.965201824000076],
            [-80.882463239999936, 34.965181824000069],
            [-80.882456238999964, 34.965113824000071],
            [-80.88251723999997, 34.965092824000067],
            [-80.882643239999936, 34.965060824000034],
            [-80.882723238999972, 34.965030824000053],
            [-80.882853239999974, 34.965012823000052],
            [-80.882985239999982, 34.965012823000052],
            [-80.883029239999985, 34.965015824000034],
            [-80.883151238999972, 34.965055824000046],
            [-80.883330239999964, 34.965158824000071],
            [-80.88368023999999, 34.965376823000042],
            [-80.883826239999962, 34.96545582400006],
            [-80.884028239999964, 34.965592824000055],
            [-80.884204239999974, 34.965700824000066],
            [-80.884294239999974, 34.96577882400004],
            [-80.884400239999934, 34.965892824000036],
            [-80.884496240999965, 34.966012824000074],
            [-80.884536240999978, 34.966076824000027],
            [-80.884572239999954, 34.966123824000078],
            [-80.884590239999966, 34.966147824000075],
            [-80.884585239999979, 34.966155823000065],
            [-80.884533239999939, 34.966264824000064],
            [-80.884476239999969, 34.966465824000068],
            [-80.884398239999939, 34.966698825000037],
            [-80.884360239999978, 34.966862824000032],
            [-80.884243239999989, 34.967261825000037],
            [-80.884192240999937, 34.967464824000047],
            [-80.884186239999963, 34.967593825000051],
            [-80.88419523999994, 34.967637823000075],
            [-80.884231240999952, 34.967765824000026],
            [-80.884261239999944, 34.967834824000079],
            [-80.884256239999957, 34.967896825000025],
            [-80.884243239999989, 34.967975824000064],
            [-80.88423623999995, 34.968137825000042],
            [-80.88424023999994, 34.968291824000062],
            [-80.88413424099997, 34.968447824000066],
            [-80.884106239999937, 34.968474824000054],
            [-80.884012239999947, 34.968549825000025],
            [-80.883848239999963, 34.968666824000024],
            [-80.883594239999979, 34.968863825000028],
            [-80.883478238999942, 34.968970825000042],
            [-80.883263239999962, 34.96919682500004],
            [-80.883189239999979, 34.969325824000066],
            [-80.883160239999938, 34.969392824000067],
            [-80.883096240999976, 34.969597825000051],
            [-80.883081240999957, 34.969666824000058],
            [-80.882987238999988, 34.969903824000028],
            [-80.882875239999976, 34.970055825000031],
            [-80.882792238999969, 34.97013882400006],
            [-80.882518238999978, 34.970318825000049],
            [-80.882413239999948, 34.970433824000054],
            [-80.882392239999945, 34.970464826000068],
            [-80.882363238999972, 34.970568825000043],
            [-80.882358239999974, 34.970603824000079],
            [-80.882359239999971, 34.970639825000035],
            [-80.882381239999972, 34.970744825000054],
            [-80.882396239999935, 34.970788825000056],
            [-80.882416239999941, 34.970846825000024],
            [-80.882493239999974, 34.970974825000042],
            [-80.882605239999975, 34.971128825000051],
            [-80.882712239999989, 34.971240826000042],
            [-80.882759239999984, 34.971300825000071],
            [-80.882787239999971, 34.971328825000057],
            [-80.882913239999937, 34.971516826000027],
            [-80.883021239999948, 34.971628825000039],
            [-80.883063239999956, 34.97169182500005],
            [-80.883130239999957, 34.971861825000076],
            [-80.883177239999952, 34.971941826000034],
            [-80.88329324099999, 34.971895825000047],
            [-80.883341239999936, 34.972027825000055],
            [-80.883441239999968, 34.972175826000068],
            [-80.88353423999996, 34.972324825000044],
            [-80.883654239999942, 34.972500825000054],
            [-80.88390124099999, 34.972808825000072],
            [-80.883948240999985, 34.972901826000054],
            [-80.884008239999957, 34.97300582500003],
            [-80.884062239999935, 34.973088825000048],
            [-80.884075240999948, 34.973236825000072],
            [-80.88404823999997, 34.973418826000056],
            [-80.883887239999979, 34.973698826000032],
            [-80.883821240999964, 34.97383082500005],
            [-80.883827240999949, 34.973989826000036],
            [-80.88389424099995, 34.974116826000056],
            [-80.884415240999942, 34.974375825000038],
            [-80.88453524099998, 34.974490825000032],
            [-80.884595240999943, 34.974605826000072],
            [-80.884601240999984, 34.974737825000034],
            [-80.884488239999939, 34.974809826000069],
            [-80.884348240999941, 34.974875825000026],
            [-80.884234239999955, 34.975084826000057],
            [-80.884207239999967, 34.975183826000034],
            [-80.884227239999973, 34.975276826000027],
            [-80.884274239999968, 34.975359827000034],
            [-80.884434240999951, 34.975551827000061],
            [-80.884527239999954, 34.975633826000035],
            [-80.884621240999934, 34.975699826000039],
            [-80.884774240999946, 34.975777826000069],
            [-80.884915241999977, 34.975837826000031],
            [-80.885409240999934, 34.976030826000056],
            [-80.885716239999965, 34.976206826000066],
            [-80.885909240999979, 34.976382826000076],
            [-80.886009240999954, 34.976563826000074],
            [-80.886056240999949, 34.976712826000039],
            [-80.886043240999982, 34.976975827000047],
            [-80.885982240999965, 34.977173826000069],
            [-80.885989240999947, 34.977261827000063],
            [-80.886042241999974, 34.977393826000025],
            [-80.886089240999979, 34.977476827000032],
            [-80.88615624199997, 34.977663826000025],
            [-80.886216240999943, 34.977910827000073],
            [-80.886202240999978, 34.978047827000069],
            [-80.886189240999954, 34.97813082700003],
            [-80.886162240999965, 34.978218827000035],
            [-80.886062240999934, 34.97868582600006],
            [-80.886081240999943, 34.978823827000042],
            [-80.886121240999955, 34.978927827000064],
            [-80.88616824099995, 34.979015827000069],
            [-80.886342240999966, 34.979290827000057],
            [-80.886368241999946, 34.979427827000052],
            [-80.886455240999965, 34.979592826000044],
            [-80.886542240999972, 34.979746827000042],
            [-80.886608240999976, 34.980076827000062],
            [-80.886635241999954, 34.980236826000066],
            [-80.886635240999965, 34.980439827000055],
            [-80.886615240999959, 34.980554827000049],
            [-80.886615240999959, 34.980637827000066],
            [-80.886641241999939, 34.980730827000059],
            [-80.88671524099999, 34.980912827000054],
            [-80.88676824199996, 34.981016827000076],
            [-80.886901240999975, 34.98136882700004],
            [-80.886961241999984, 34.981506827000032],
            [-80.88698124199999, 34.981605827000067],
            [-80.88700824099999, 34.981692827000074],
            [-80.887248241999941, 34.982000828000025],
            [-80.887322240999936, 34.982105828000044],
            [-80.887435241999981, 34.982292828000027],
            [-80.887535241999956, 34.982495828000026],
            [-80.887582241999951, 34.982572828000059],
            [-80.887802242999953, 34.982836827000028],
            [-80.887902241999939, 34.982974827000078],
            [-80.88796924199994, 34.983084827000027],
            [-80.888022241999977, 34.983155828000065],
            [-80.888089241999978, 34.983315828000059],
            [-80.888162241999964, 34.983430828000053],
            [-80.888162241999964, 34.983568828000045],
            [-80.888109242999974, 34.983699828000056],
            [-80.88806224199999, 34.983787827000072],
            [-80.887935241999969, 34.983980827000039],
            [-80.887775241999975, 34.984161828000026],
            [-80.887674240999957, 34.984337827000047],
            [-80.887648240999965, 34.984441828000058],
            [-80.887694241999952, 34.984601828000052],
            [-80.887768241999936, 34.984678828000028],
            [-80.888001241999973, 34.984711828000059],
            [-80.888249242999962, 34.984662828000069],
            [-80.888442242999986, 34.984579828000051],
            [-80.888556241999936, 34.984535828000048],
            [-80.888723241999969, 34.984541828000033],
            [-80.888823241999944, 34.984552828000062],
            [-80.888910242999941, 34.984629827000049],
            [-80.888990242999967, 34.984739828000045],
            [-80.889377241999966, 34.985179828000071],
            [-80.889261242999964, 34.985212827000055],
            [-80.889278241999989, 34.985273828000061],
            [-80.889276242999983, 34.985308828000029],
            [-80.88925524199999, 34.98533782800007],
            [-80.88910524299996, 34.985412828000051],
            [-80.889102242999968, 34.985413828000048],
            [-80.88903424199998, 34.985454828000059],
            [-80.888924241999973, 34.985512828000026],
            [-80.888853242999971, 34.985555828000031],
            [-80.888787241999978, 34.985602828000026],
            [-80.888760241999989, 34.985630828000069],
            [-80.888747242999955, 34.98566482800004],
            [-80.888734242999988, 34.985735828000031],
            [-80.888722241999972, 34.985841828000048],
            [-80.888769241999967, 34.98612982700007],
            [-80.888775242999941, 34.986160829000028],
            [-80.888809242999969, 34.986299828000028],
            [-80.888817241999959, 34.986430829000028],
            [-80.888820241999952, 34.986476827000047],
            [-80.888815242999954, 34.986547828000027],
            [-80.888797242999942, 34.986616828000024],
            [-80.888738242999978, 34.986750828000027],
            [-80.888673241999982, 34.986957828000072],
            [-80.888656241999968, 34.987027828000066],
            [-80.888639241999954, 34.987157828000079],
            [-80.888638241999956, 34.987168828000051],
            [-80.888638241999956, 34.987275828000065],
            [-80.888627241999984, 34.987346828000057],
            [-80.888582242999973, 34.987385829000061],
            [-80.888555242999985, 34.987414828000055],
            [-80.888535242999978, 34.98744582900008],
            [-80.888508241999943, 34.987549828000056],
            [-80.888452241999971, 34.987684829000045],
            [-80.88836224299996, 34.98783682800007],
            [-80.888358241999981, 34.98784482800005],
            [-80.888314241999979, 34.987906829000053],
            [-80.888256242999944, 34.987959828000044],
            [-80.888160241999969, 34.988033828000027],
            [-80.888089242999968, 34.988074829000027],
            [-80.88801424199994, 34.988111829000047],
            [-80.887972241999989, 34.988122829000076],
            [-80.887886242999969, 34.988137828000049],
            [-80.887711241999966, 34.988126829000066],
            [-80.887456241999985, 34.988075829000024],
            [-80.887415241999975, 34.988063828000065],
            [-80.887244241999952, 34.988031829000079],
            [-80.887203241999941, 34.988018829000055],
            [-80.887118241999985, 34.988000828000054],
            [-80.887031241999978, 34.987987829000076],
            [-80.886987240999986, 34.987986828000032],
            [-80.886860241999955, 34.988015829000062],
            [-80.886792241999956, 34.988042829000051],
            [-80.886663241999941, 34.988095828000041],
            [-80.886502241999949, 34.988150829000062],
            [-80.886460241999941, 34.988161829000035],
            [-80.88626224099994, 34.988238829000068],
            [-80.886027241999955, 34.988351828000077],
            [-80.885843241999964, 34.988450829000044],
            [-80.885839240999985, 34.988453829000036],
            [-80.885839240999985, 34.988454829000034],
            [-80.88578824199999, 34.988505829000076],
            [-80.885728240999981, 34.98857182900008],
            [-80.885710241999959, 34.988592829000027],
            [-80.88564624199995, 34.988685829000076],
            [-80.885596241999963, 34.988783828000066],
            [-80.885583240999949, 34.988817828000037],
            [-80.885547240999983, 34.98895582800003],
            [-80.885528241999964, 34.989008829000056],
            [-80.885489240999959, 34.989126829000043],
            [-80.885479241999974, 34.989163829000063],
            [-80.885412240999983, 34.989439829000048],
            [-80.88537624199995, 34.989635829000065],
            [-80.885374241999955, 34.989650829000027],
            [-80.885366240999986, 34.989756828000054],
            [-80.885362241999985, 34.989899829000024],
            [-80.885395241999959, 34.990146829000025],
            [-80.885399241999949, 34.990217829000073],
            [-80.885584240999947, 34.990804829000069],
            [-80.885591241999975, 34.990839829000038],
            [-80.885600240999963, 34.991053830000055],
            [-80.885606240999948, 34.991088829000034],
            [-80.88566124099998, 34.991260830000044],
            [-80.885753242999954, 34.991422829000044],
            [-80.885794240999985, 34.991485830000045],
            [-80.885798241999964, 34.991490829000043],
            [-80.885846241999957, 34.991542830000071],
            [-80.885876240999949, 34.991569829000071],
            [-80.886014241999987, 34.991655829000024],
            [-80.886053241999946, 34.99167183000003],
            [-80.886176241999976, 34.991709830000048],
            [-80.886563241999966, 34.991818830000057],
            [-80.886716240999988, 34.991861829000072],
            [-80.88675624199999, 34.991875829000037],
            [-80.886881241999959, 34.991908829000067],
            [-80.887096241999984, 34.991941829000041],
            [-80.887446241999953, 34.991958829000055],
            [-80.887755242999958, 34.99195282900007],
            [-80.888453241999969, 34.991895830000033],
            [-80.888668241999937, 34.991861829000072],
            [-80.888752242999942, 34.991841829000066],
            [-80.888795242999947, 34.991835829000024],
            [-80.888878241999976, 34.991811830000074],
            [-80.889066243999935, 34.991745829000024],
            [-80.889080242999967, 34.991741829000034],
            [-80.889275242999986, 34.991661829000066],
            [-80.889356242999952, 34.991632829000025],
            [-80.889567242999988, 34.991582830000027],
            [-80.889772243999971, 34.991519830000072],
            [-80.889868242999967, 34.991478829000073],
            [-80.88994224399994, 34.991447830000027],
            [-80.890006242999959, 34.991421829000046],
            [-80.890167242999951, 34.991360830000076],
            [-80.890243242999986, 34.991332829000044],
            [-80.890287242999989, 34.991317829000025],
            [-80.890418242999942, 34.991274829000076],
            [-80.890491243999975, 34.991251830000067],
            [-80.890758243999983, 34.991129829000045],
            [-80.890794243999949, 34.991108829000041],
            [-80.890824243999987, 34.99108282900005],
            [-80.890893242999937, 34.991004829000076],
            [-80.89090224399996, 34.990995829000042],
            [-80.890924242999972, 34.990964829000063],
            [-80.891005242999938, 34.990937829000075],
            [-80.891048242999943, 34.990915829000073],
            [-80.891179242999954, 34.990827829000068],
            [-80.891304242999979, 34.990703830000029],
            [-80.89133424299996, 34.990676829000051],
            [-80.89137124399997, 34.990657829000043],
            [-80.891535242999964, 34.990606829000058],
            [-80.891708242999982, 34.990578829000071],
            [-80.891883242999938, 34.990561829000058],
            [-80.892103243999941, 34.99056082900006],
            [-80.892234243999951, 34.990578829000071],
            [-80.892272243999969, 34.990593829000034],
            [-80.892304243999945, 34.990618829000027],
            [-80.892416243999946, 34.990728828000044],
            [-80.892486242999951, 34.990859829000044],
            [-80.892545243999962, 34.990993829000047],
            [-80.892616243999953, 34.991124829000057],
            [-80.892746243999966, 34.991267829000037],
            [-80.892794243999958, 34.991326829000059],
            [-80.892904243999965, 34.991437829000063],
            [-80.892924243999971, 34.991469829000039],
            [-80.89299624399996, 34.991637829000069],
            [-80.893014243999971, 34.991669829000045],
            [-80.893055243999981, 34.991771829000072],
            [-80.893065243999956, 34.991806829000041],
            [-80.893147243999977, 34.992008830000032],
            [-80.893273243999943, 34.992196829000079],
            [-80.89355924399996, 34.992597829000033],
            [-80.893597243999977, 34.992661829000042],
            [-80.893683243999988, 34.992786830000057],
            [-80.893755243999976, 34.99287582900007],
            [-80.893860243999939, 34.992990829000064],
            [-80.893933244999971, 34.993079830000056],
            [-80.894019244999981, 34.993204829000035],
            [-80.894073243999969, 34.993301829000075],
            [-80.894138244999965, 34.993434830000069],
            [-80.894147243999953, 34.993469830000038],
            [-80.894154244999982, 34.993569830000069],
            [-80.894084243999941, 34.994955830000038],
            [-80.894069243999979, 34.994989830000065],
            [-80.894037244999936, 34.995129830000053],
            [-80.893910243999983, 34.995395830000064],
            [-80.893890243999977, 34.995426831000032],
            [-80.893857244999936, 34.995492830000046],
            [-80.893790243999945, 34.995699830000035],
            [-80.893730244999972, 34.99583283000004],
            [-80.893710244999966, 34.995864830000073],
            [-80.893663243999981, 34.995924830000035],
            [-80.893534244999955, 34.996020830000077],
            [-80.893443243999968, 34.996097830000053],
            [-80.893419244999961, 34.996126831000026],
            [-80.89336024499994, 34.996177830000079],
            [-80.893238243999974, 34.996367829000064],
            [-80.893182244999934, 34.996464830000036],
            [-80.893132243999958, 34.996600830000034],
            [-80.893116243999941, 34.996706830000051],
            [-80.893118244999982, 34.996742830000073],
            [-80.893129243999965, 34.996777830000042],
            [-80.893286243999967, 34.997071831000028],
            [-80.893318244999989, 34.997138831000029],
            [-80.893387243999939, 34.997381830000052],
            [-80.89339724499996, 34.997452830000043],
            [-80.893392244999973, 34.997523830000034],
            [-80.893331243999967, 34.997731830000077],
            [-80.893314244999942, 34.99776483100004],
            [-80.893271244999937, 34.997826830000065],
            [-80.893245243999957, 34.997894830000064],
            [-80.893214243999978, 34.997960831000057],
            [-80.893210243999988, 34.99799683100008],
            [-80.89321724399997, 34.998031831000048],
            [-80.893244243999959, 34.998099830000058],
            [-80.893284243999972, 34.998162830000069],
            [-80.893423244999951, 34.998280831000045],
            [-80.893527243999984, 34.998406831000068],
            [-80.893732244999967, 34.998592830000064],
            [-80.894027244999961, 34.998880830000076],
            [-80.894217245999982, 34.999077831000079],
            [-80.894772244999956, 34.999677831000042],
            [-80.894945243999985, 34.999884831000031],
            [-80.895059243999981, 35.000046832000066],
            [-80.895123244999979, 35.000161831000071],
            [-80.895192245999965, 35.000336831000027],
            [-80.895243244999961, 35.000482831000056],
            [-80.895343244999935, 35.000586831000078],
            [-80.895413244999986, 35.000647831000038],
            [-80.895543245999988, 35.000725831000068],
            [-80.895660245999977, 35.000812831000076],
            [-80.895692245999953, 35.000831831000028],
            [-80.895751245999975, 35.00087583100003],
            [-80.895825245999958, 35.000953831000061],
            [-80.895903245999989, 35.001027831000044],
            [-80.89598624499996, 35.001097832000028],
            [-80.896112244999983, 35.001225831000056],
            [-80.89615824599997, 35.001262831000076],
            [-80.89632824499995, 35.00138983100004],
            [-80.896444244999941, 35.001485832000071],
            [-80.89645524599996, 35.00149483000007],
            [-80.896580244999939, 35.001601831000073],
            [-80.896657245999961, 35.001673831000062],
            [-80.896933244999957, 35.001887831000033],
            [-80.897103245999972, 35.002012831000059],
            [-80.897196245999965, 35.002092831000027],
            [-80.897258246999968, 35.002142831000072],
            [-80.897407244999954, 35.002231831000074],
            [-80.89770124599994, 35.002407831000028],
            [-80.897909244999937, 35.002542831000028],
            [-80.897968245999948, 35.002578831000051],
            [-80.898012245999951, 35.002606831000037],
            [-80.898107246999984, 35.002660832000061],
            [-80.898311246999981, 35.002794832000063],
            [-80.898673245999987, 35.003021831000069],
            [-80.898973246999958, 35.00322383200006],
            [-80.899024245999954, 35.003263832000073],
            [-80.899547245999941, 35.00360283200007],
            [-80.899679246999938, 35.003702831000055],
            [-80.89974624599995, 35.003748832000042],
            [-80.899994246999938, 35.003950831000054],
            [-80.900084246999938, 35.004018832000042],
            [-80.900154246999989, 35.00407283100003],
            [-80.900220246999936, 35.004118832000074],
            [-80.900726246999966, 35.004512832000046],
            [-80.901084245999982, 35.004775832000064],
            [-80.901436246999936, 35.004988832000038],
            [-80.901687247999973, 35.005130832000077],
            [-80.901757246999978, 35.005180831000075],
            [-80.901786246999961, 35.005200832000071],
            [-80.901814246999947, 35.005228832000057],
            [-80.901879247999943, 35.005275831000063],
            [-80.902033247999952, 35.005401831000029],
            [-80.902164246999973, 35.005544832000055],
            [-80.902239247999944, 35.005654832000062],
            [-80.902262247999943, 35.005684832000043],
            [-80.902299246999974, 35.005748832000052],
            [-80.902331247999939, 35.005851832000076],
            [-80.902340247999973, 35.005896832000076],
            [-80.902366247999964, 35.006026832000032],
            [-80.902368247999959, 35.006167832000074],
            [-80.902374247999944, 35.006237832000068],
            [-80.902369247999957, 35.006308832000059],
            [-80.902324247999957, 35.006591832000026],
            [-80.902295246999984, 35.006695833000038],
            [-80.902266247999989, 35.006762832000049],
            [-80.902209246999973, 35.006858832000034],
            [-80.902186246999975, 35.006888832000072],
            [-80.902081246999956, 35.007001832000071],
            [-80.902008246999969, 35.007089832000077],
            [-80.90183824799999, 35.00725383200006],
            [-80.901704247999987, 35.007345832000055],
            [-80.901629247999949, 35.007382832000076],
            [-80.901473247999945, 35.007449832000077],
            [-80.901393247999977, 35.007477832000063],
            [-80.901310246999969, 35.007501832000059],
            [-80.901181245999965, 35.007522832000063],
            [-80.901137247999941, 35.007524832000058],
            [-80.901050246999944, 35.00753583200003],
            [-80.900961247999987, 35.007536833000074],
            [-80.900944247999973, 35.007535833000077],
            [-80.900786247999974, 35.007529832000046],
            [-80.900479246999964, 35.007523832000061],
            [-80.900083246999941, 35.00754683200006],
            [-80.899953245999939, 35.007561832000079],
            [-80.899786246999952, 35.007607832000076],
            [-80.89962524699996, 35.007664833000035],
            [-80.899528246999978, 35.007737832000032],
            [-80.899469246999956, 35.007832832000076],
            [-80.899455246999935, 35.007866833000037],
            [-80.899446245999968, 35.007937833000028],
            [-80.899442246999968, 35.008079832000078],
            [-80.899457246999987, 35.008149832000072],
            [-80.899477246999936, 35.008289833000049],
            [-80.899497246999942, 35.008359833000043],
            [-80.899511246999964, 35.008393833000071],
            [-80.899589245999948, 35.008520832000045],
            [-80.899634246999938, 35.008581833000051],
            [-80.899686245999987, 35.008638833000077],
            [-80.899859245999949, 35.008845832000077],
            [-80.900073246999966, 35.009070833000067],
            [-80.900122247999946, 35.009129833000031],
            [-80.900290246999987, 35.00938083300008],
            [-80.900361247999967, 35.009509832000049],
            [-80.900412247999952, 35.009646832000044],
            [-80.900422246999938, 35.009717833000025],
            [-80.900428246999979, 35.009858832000077],
            [-80.900424246999989, 35.009894833000033],
            [-80.900392246999957, 35.010034832000031],
            [-80.90037624699994, 35.010067833000051],
            [-80.900166247999948, 35.010420833000069],
            [-80.900003246999972, 35.010670833000063],
            [-80.899995246999936, 35.010682832000043],
            [-80.899940246999961, 35.010745833000044],
            [-80.899829247999946, 35.010875833000057],
            [-80.899602246999962, 35.011137833000078],
            [-80.89939224699998, 35.01136583300007],
            [-80.899374246999969, 35.011398833000044],
            [-80.899362246999942, 35.011427833000027],
            [-80.899315246999947, 35.011532834000036],
            [-80.899235246999979, 35.011574833000054],
            [-80.898611246999963, 35.01202683300005],
            [-80.898364246999961, 35.012176833000069],
            [-80.898097247999942, 35.012361833000057],
            [-80.897999246999973, 35.012433833000046],
            [-80.897906245999934, 35.012508834000073],
            [-80.897847245999969, 35.012561833000063],
            [-80.89783224599995, 35.012572833000036],
            [-80.89783224599995, 35.012573833000033],
            [-80.897815246999983, 35.012586834000047],
            [-80.897728246999975, 35.012666833000026],
            [-80.897619245999977, 35.012776834000078],
            [-80.897560245999955, 35.012829833000069],
            [-80.89755824599996, 35.012830833000066],
            [-80.897433246999981, 35.012927833000049],
            [-80.897307245999968, 35.013026834000073],
            [-80.897185245999935, 35.013128834000042],
            [-80.89706924699999, 35.013233833000072],
            [-80.896953245999953, 35.013341833000027],
            [-80.896865246999937, 35.013460834000057],
            [-80.89685224599998, 35.013494833000038],
            [-80.896870245999935, 35.013671833000046],
            [-80.89690524599996, 35.013736834000042],
            [-80.896928246999948, 35.013767834000078],
            [-80.896984245999988, 35.013822833000063],
            [-80.897078245999978, 35.013896834000036],
            [-80.897191245999977, 35.013952834000065],
            [-80.897523246999981, 35.014081834000024],
            [-80.897747246999984, 35.014168834000031],
            [-80.898201246999975, 35.014306833000035],
            [-80.898369246999948, 35.014347834000034],
            [-80.898497246999966, 35.014373834000025],
            [-80.898757246999935, 35.014418834000026],
            [-80.898974246999956, 35.014447834000066],
            [-80.899150246999966, 35.014455834000046],
            [-80.899282246999974, 35.014452834000053],
            [-80.899325246999979, 35.014446834000069],
            [-80.899410246999935, 35.014426833000073],
            [-80.899530246999973, 35.014380834000065],
            [-80.899835246999942, 35.014179834000061],
            [-80.900029246999964, 35.014036833000034],
            [-80.900638246999961, 35.013528833000066],
            [-80.900701246999972, 35.013477833000024],
            [-80.900766246999979, 35.013430833000029],
            [-80.901110246999963, 35.013157833000037],
            [-80.901370247999978, 35.012965833000067],
            [-80.901399247999962, 35.012939833000075],
            [-80.901517246999958, 35.012786833000064],
            [-80.901558246999969, 35.01277383300004],
            [-80.901577246999977, 35.012764832000073],
            [-80.90175624799997, 35.012688833000027],
            [-80.901792247999936, 35.012674833000062],
            [-80.90181324699995, 35.012659833000043],
            [-80.902086247999989, 35.012460833000034],
            [-80.902271248999966, 35.012310833000072],
            [-80.902659247999964, 35.01192483300008],
            [-80.902674247999983, 35.011907832000077],
            [-80.90322724899994, 35.011470833000033],
            [-80.903514247999965, 35.011515834000079],
            [-80.903945248999946, 35.011398833000044],
            [-80.90405624899995, 35.01134383200008],
            [-80.904531248999945, 35.011225832000036],
            [-80.904707248999955, 35.011044834000074],
            [-80.904851247999943, 35.01109983300006],
            [-80.904895247999946, 35.011244832000045],
            [-80.904917247999947, 35.011488833000044],
            [-80.904961248999939, 35.011759833000042],
            [-80.905050247999952, 35.01198583300004],
            [-80.905282247999935, 35.012229833000049],
            [-80.905580248999968, 35.012410834000036],
            [-80.905922247999968, 35.012510832000032],
            [-80.906276248999973, 35.012582833000067],
            [-80.906376248999948, 35.012593833000039],
            [-80.906618249999951, 35.012746833000051],
            [-80.906713248999949, 35.012820833000035],
            [-80.906821248999961, 35.012932833000036],
            [-80.906888248999962, 35.013024833000031],
            [-80.906974248999973, 35.01318583300008],
            [-80.907082248999984, 35.013384833000032],
            [-80.907252248999953, 35.013736834000042],
            [-80.907402248999972, 35.014106834000074],
            [-80.90748524899999, 35.014270833000069],
            [-80.907541248999962, 35.014366833000054],
            [-80.907682249999937, 35.014500833000056],
            [-80.90781624899995, 35.014594833000046],
            [-80.907887249999987, 35.014635834000046],
            [-80.907925248999959, 35.01465283400006],
            [-80.907966250999948, 35.014665834000027],
            [-80.908080249999955, 35.014718833000074],
            [-80.908151248999957, 35.014760834000072],
            [-80.90825424999997, 35.01482883400007],
            [-80.908284249999951, 35.014854833000072],
            [-80.908345249999968, 35.014948833000062],
            [-80.908388249999973, 35.015049834000024],
            [-80.90854324999998, 35.01563983300008],
            [-80.908558249999942, 35.015672834000043],
            [-80.908661249999966, 35.015829834000044],
            [-80.908687249999957, 35.015858834000028],
            [-80.908803249999949, 35.015965833000052],
            [-80.908924249999984, 35.016067834000069],
            [-80.90927125099995, 35.016389834000051],
            [-80.909422250999967, 35.016606833000026],
            [-80.909461249999936, 35.016669833000037],
            [-80.909510249999983, 35.016769834000058],
            [-80.909538249999969, 35.01687783400007],
            [-80.909481249999942, 35.017664834000072],
            [-80.90947424999996, 35.017684834000079],
            [-80.90947024999997, 35.017730835000066],
            [-80.909450249999963, 35.017744834000041],
            [-80.90933924999996, 35.017823834000069],
            [-80.909253250999939, 35.017903834000037],
            [-80.909228250999945, 35.017933834000075],
            [-80.909045249999963, 35.01808683400003],
            [-80.908978249999961, 35.018133835000071],
            [-80.908759250999935, 35.018250834000071],
            [-80.908721249999985, 35.018267834000028],
            [-80.908653249999986, 35.018313834000026],
            [-80.908464249999952, 35.018462834000047],
            [-80.908408249999979, 35.018517834000079],
            [-80.908385249999981, 35.01854783400006],
            [-80.908331249999947, 35.018603834000032],
            [-80.908239249999951, 35.018725834000065],
            [-80.90820524999998, 35.018790834000072],
            [-80.908126249999953, 35.018917835000025],
            [-80.908091249999984, 35.018983835000029],
            [-80.90799224999995, 35.019101835000072],
            [-80.907946249999952, 35.019130834000066],
            [-80.907906250999986, 35.019144834000031],
            [-80.907862249999937, 35.019151835000059],
            [-80.907730249999986, 35.01914783400008],
            [-80.907689249999976, 35.019135835000043],
            [-80.907358249999959, 35.018958835000035],
            [-80.907133249999958, 35.018845834000047],
            [-80.906815249999966, 35.018655834000072],
            [-80.906627248999939, 35.018562834000079],
            [-80.906549249999955, 35.018530834000046],
            [-80.90637924899994, 35.018493834000026],
            [-80.906204248999984, 35.018507835000037],
            [-80.906120249999958, 35.018529835000038],
            [-80.905889248999983, 35.018632835000062],
            [-80.905820248999987, 35.018676834000075],
            [-80.905781249999961, 35.018693834000032],
            [-80.905548248999935, 35.018854834000024],
            [-80.905510249999963, 35.018873834000033],
            [-80.905431249999936, 35.01890383500006],
            [-80.905347249999977, 35.018926834000069],
            [-80.905217249999964, 35.018942835000075],
            [-80.905090248999954, 35.018969834000075],
            [-80.904929248999963, 35.019025835000036],
            [-80.904506248999951, 35.019121834000032],
            [-80.904419247999954, 35.01913283500005],
            [-80.904244247999941, 35.019144834000031],
            [-80.904113248999977, 35.019135835000043],
            [-80.90403024799997, 35.019111834000057],
            [-80.903991248999944, 35.019094834000043],
            [-80.903922248999947, 35.019050834000041],
            [-80.903694248999955, 35.018746835000059],
            [-80.903663248999976, 35.018721834000075],
            [-80.903625247999969, 35.018702835000056],
            [-80.903583247999961, 35.01869483400003],
            [-80.903495248999945, 35.018690835000029],
            [-80.903451248999943, 35.018692834000035],
            [-80.903408248999938, 35.018702835000056],
            [-80.903330247999975, 35.01873583400004],
            [-80.903258248999975, 35.018794834000062],
            [-80.903127248999965, 35.018979835000039],
            [-80.903014247999977, 35.019248835000042],
            [-80.902973248999956, 35.019459835000077],
            [-80.902967247999982, 35.019707835000077],
            [-80.902973248999956, 35.019742835000045],
            [-80.903017247999969, 35.019843834000028],
            [-80.903051248999986, 35.019908834000034],
            [-80.903091248999942, 35.019971835000035],
            [-80.903276248999987, 35.020212835000052],
            [-80.90331324899995, 35.020276835000061],
            [-80.903415248999977, 35.020474835000073],
            [-80.903438248999976, 35.020542836000061],
            [-80.903471248999949, 35.020754835000048],
            [-80.903473247999955, 35.020825835000039],
            [-80.903458248999982, 35.021144836000076],
            [-80.903435248999983, 35.021213835000026],
            [-80.903387247999945, 35.02131283500006],
            [-80.903363248999938, 35.021342835000041],
            [-80.903020248999951, 35.021619835000024],
            [-80.902838248999956, 35.021775835000028],
            [-80.902786248999973, 35.021832835000055],
            [-80.902668247999941, 35.021981836000066],
            [-80.902629247999982, 35.022045836000075],
            [-80.902555248999988, 35.022250835000079],
            [-80.902540248999969, 35.022320835000073],
            [-80.902520248999963, 35.022497835000024],
            [-80.902466247999939, 35.022813835000079],
            [-80.902408248999961, 35.022947836000071],
            [-80.902370248999944, 35.023012835000031],
            [-80.902274247999969, 35.023131835000072],
            [-80.902114248999965, 35.023343836000038],
            [-80.901942247999955, 35.023548835000042],
            [-80.901692247999961, 35.023883836000039],
            [-80.901534247999962, 35.024139835000028],
            [-80.901449247999949, 35.024264836000043],
            [-80.901260247999971, 35.024586836000026],
            [-80.901049247999936, 35.024899836000031],
            [-80.900971247999962, 35.025026836000052],
            [-80.900882248999949, 35.025212836000037],
            [-80.900813247999963, 35.025358836000066],
            [-80.900729247999948, 35.025560836000068],
            [-80.900697247999972, 35.025626836000072],
            [-80.900656247999962, 35.025727837000034],
            [-80.900502247999952, 35.026210836000075],
            [-80.900484247999941, 35.026280836000069],
            [-80.900423247999981, 35.026882837000073],
            [-80.900384247999966, 35.027093837000052],
            [-80.900342247999959, 35.027232837000042],
            [-80.900303247999943, 35.027296836000062],
            [-80.900189246999958, 35.027447837000068],
            [-80.899938247999955, 35.027740837000067],
            [-80.899784247999946, 35.027913837000028],
            [-80.899661247999973, 35.028060837000055],
            [-80.899614247999978, 35.028111838000029],
            [-80.899476247999985, 35.028266837000047],
            [-80.899431247999985, 35.028319837000026],
            [-80.899337247999938, 35.028452837000032],
            [-80.899143247999973, 35.028795836000029],
            [-80.899081247999959, 35.028906837000079],
            [-80.898943247999966, 35.029130837000025],
            [-80.898803246999989, 35.02931183700008],
            [-80.898693246999983, 35.029465837000032],
            [-80.898679247999951, 35.02949983600007],
            [-80.898672247999968, 35.029534838000075],
            [-80.898672247999968, 35.029570837000051],
            [-80.898682247999943, 35.029640837000045],
            [-80.898692247999975, 35.029675836000024],
            [-80.898709246999942, 35.029708837000044],
            [-80.898843246999945, 35.029892837000034],
            [-80.898895247999974, 35.029990837000071],
            [-80.898921246999976, 35.03005883700007],
            [-80.898939247999976, 35.030199836000065],
            [-80.89893124799994, 35.030255838000073],
            [-80.898929247999945, 35.030270838000035],
            [-80.898912247999988, 35.03034083700004],
            [-80.898899247999964, 35.030374836000078],
            [-80.89888224699996, 35.030443837000064],
            [-80.898868246999939, 35.030477837000035],
            [-80.898843247999935, 35.030507838000062],
            [-80.898684248999984, 35.030746837000038],
            [-80.898595247999936, 35.030880837000041],
            [-80.898538247999966, 35.030975838000074],
            [-80.898443247999978, 35.031136837000076],
            [-80.898247247999961, 35.031454837000069],
            [-80.898129247999975, 35.031679838000059],
            [-80.898005246999958, 35.031921838000073],
            [-80.897933246999969, 35.032060837000074],
            [-80.897755247999953, 35.03240783800004],
            [-80.89772224799998, 35.032482838000078],
            [-80.897661246999974, 35.032627838000053],
            [-80.89763224699999, 35.032695837000063],
            [-80.897612246999984, 35.032744837000052],
            [-80.897540246999938, 35.032900838000046],
            [-80.897509246999959, 35.032968838000045],
            [-80.897500246999982, 35.032988837000062],
            [-80.89749024799994, 35.033012838000047],
            [-80.897475247999978, 35.03304883800007],
            [-80.897468246999949, 35.033069837000028],
            [-80.897455248999961, 35.033103838000045],
            [-80.897439246999966, 35.033151838000038],
            [-80.897415246999969, 35.033205838000072],
            [-80.897410246999982, 35.033218838000039],
            [-80.897402247999935, 35.03324083800004],
            [-80.897375247999946, 35.033317838000073],
            [-80.89736224699999, 35.033355838000034],
            [-80.897360246999938, 35.033390838000059],
            [-80.897368247999964, 35.033461837000061],
            [-80.897364247999974, 35.03349683700003],
            [-80.897345246999976, 35.033556838000038],
            [-80.897332247999941, 35.033600838000041],
            [-80.897325247999959, 35.033635838000066],
            [-80.897346246999973, 35.033666838000045],
            [-80.89735924699994, 35.03370183800007],
            [-80.897367247999966, 35.033743838000078],
            [-80.897387247999973, 35.033841838000058],
            [-80.897453247999977, 35.034010838000029],
            [-80.897484247999955, 35.034075838000035],
            [-80.897536246999948, 35.034181838000052],
            [-80.897565246999989, 35.034242838000068],
            [-80.89766624799995, 35.034400838000067],
            [-80.897700247999978, 35.034461839000073],
            [-80.897702247999973, 35.034465838000074],
            [-80.89773024699997, 35.034605839000051],
            [-80.897730247999959, 35.034641839000074],
            [-80.897724247999975, 35.034676838000053],
            [-80.897691247999944, 35.034779839000066],
            [-80.897516247999988, 35.035026839000068],
            [-80.897419246999959, 35.035186838000072],
            [-80.89735124799995, 35.035317838000026],
            [-80.897349247999955, 35.035331838000047],
            [-80.89734324799997, 35.035423838000042],
            [-80.897360246999938, 35.035527838000064],
            [-80.897375247999946, 35.035561838000035],
            [-80.897422246999952, 35.035639838000066],
            [-80.89743324799997, 35.035656838000079],
            [-80.897473247999983, 35.035705838000069],
            [-80.897481246999973, 35.035715839000034],
            [-80.897660247999966, 35.035869839000043],
            [-80.897662247999961, 35.035871839000038],
            [-80.89773024699997, 35.035922839000079],
            [-80.897882247999974, 35.036040839000066],
            [-80.897919247999937, 35.036071838000055],
            [-80.898003246999963, 35.036142838000046],
            [-80.898227247999955, 35.036360839000054],
            [-80.898298246999957, 35.036450839000054],
            [-80.898338247999959, 35.036513839000065],
            [-80.898388247999947, 35.03661383900004],
            [-80.898437246999947, 35.036708839000028],
            [-80.898487247999981, 35.036833839000053],
            [-80.898494247999963, 35.036909839000032],
            [-80.898507247999987, 35.037038839000047],
            [-80.898511247999977, 35.037140839000074],
            [-80.898512247999975, 35.037144839000064],
            [-80.898509247999982, 35.03721783900005],
            [-80.898508248999974, 35.037286839000046],
            [-80.898501247999945, 35.037355839000043],
            [-80.898492247999968, 35.037464838000062],
            [-80.898460247999935, 35.037567839000076],
            [-80.898377246999985, 35.03774783800003],
            [-80.898338247999959, 35.037834839000027],
            [-80.898320247999948, 35.03787483900004],
            [-80.898179247999963, 35.038204839000059],
            [-80.898146247999989, 35.038321839000048],
            [-80.898111247999964, 35.038447838000025],
            [-80.898093247999952, 35.038731839000036],
            [-80.898124246999942, 35.039087840000036],
            [-80.898140247999947, 35.039193839000063],
            [-80.898197246999985, 35.039327839000066],
            [-80.898230248999937, 35.03939383900007],
            [-80.89841324799994, 35.039678839000032],
            [-80.89841624799999, 35.039682839000079],
            [-80.898436247999939, 35.03970884000006],
            [-80.89860424799997, 35.039873839000052],
            [-80.898762248999958, 35.040016839000032],
            [-80.898780248999969, 35.040032839000048],
            [-80.898925247999955, 35.04014483900005],
            [-80.898939247999976, 35.040155839000079],
            [-80.89906224799995, 35.04025684000004],
            [-80.899324247999971, 35.040446840000072],
            [-80.899426247999941, 35.04051283900003],
            [-80.899565247999988, 35.040603839000028],
            [-80.899602247999951, 35.040622840000026],
            [-80.89983024899999, 35.040747840000051],
            [-80.899832248999985, 35.040757839000037],
            [-80.900190248999934, 35.041335839000055],
            [-80.900377248999973, 35.04183684000003],
            [-80.900424247999979, 35.041964839000059],
            [-80.900479247999954, 35.04206884000007],
            [-80.90051724999995, 35.042169840000042],
            [-80.900609248999956, 35.042365840000059],
            [-80.900653249999948, 35.042427840000073],
            [-80.900727248999942, 35.042513839000037],
            [-80.900987248999968, 35.042800840000041],
            [-80.901153248999947, 35.04300784000003],
            [-80.901348248999966, 35.043198840000059],
            [-80.901538249999987, 35.043395840000073],
            [-80.901689248999958, 35.043568840000034],
            [-80.901758248999954, 35.043658840000035],
            [-80.901897249999934, 35.043879840000045],
            [-80.901974249999967, 35.044043840000029],
            [-80.902121248999947, 35.044335841000077],
            [-80.902226249999956, 35.044529841000042],
            [-80.902682249999941, 35.045294840000054],
            [-80.902864249999936, 35.045576840000024],
            [-80.902984249999975, 35.045724840000048],
            [-80.903028249999977, 35.045785840000065],
            [-80.903143249999971, 35.045891840000024],
            [-80.903243250999935, 35.045961840000075],
            [-80.903277249999974, 35.045977840000035],
            [-80.903317249999986, 35.045997840000041],
            [-80.903512249999949, 35.046077840000066],
            [-80.903754250999953, 35.046165841000061],
            [-80.904000250999957, 35.046256840000069],
            [-80.904066249999971, 35.046280840000065],
            [-80.904227249999963, 35.046333840000045],
            [-80.904286249999984, 35.046354840000049],
            [-80.904391250999936, 35.046386840000025],
            [-80.904405249999968, 35.046390840000072],
            [-80.904757250999978, 35.046509841000045],
            [-80.904761250999968, 35.046510841000043],
            [-80.904789249999965, 35.046521840000025],
            [-80.904878249999967, 35.046555840000053],
            [-80.904960249999988, 35.046573841000054],
            [-80.905029249999984, 35.046569840000075],
            [-80.905621250999957, 35.046717840000042],
            [-80.905724250999981, 35.046736840000051],
            [-80.905888250999965, 35.046789841000077],
            [-80.905924250999988, 35.046809841000027],
            [-80.905983250999952, 35.046862841000063],
            [-80.906009250999944, 35.046930841000062],
            [-80.906014250999988, 35.046965841000031],
            [-80.906014250999988, 35.047107840000024],
            [-80.905995250999979, 35.047176841000066],
            [-80.905985250999947, 35.047221841000066],
            [-80.905965250999941, 35.047316841000054],
            [-80.905937250999955, 35.047420840000029],
            [-80.90582225199995, 35.047725841000045],
            [-80.905750249999983, 35.047893840000029],
            [-80.905672249999952, 35.048059841000054],
            [-80.905614250999975, 35.048155841000039],
            [-80.905548250999971, 35.048247841000034],
            [-80.905540250999934, 35.048256841000068],
            [-80.905483250999964, 35.048326841000062],
            [-80.905476250999982, 35.048336841000037],
            [-80.905451250999988, 35.048362841000028],
            [-80.905399249999959, 35.048418841000057],
            [-80.905238249999968, 35.048592840000026],
            [-80.905123249999974, 35.048668841000051],
            [-80.905215250999959, 35.048682841000073],
            [-80.905342250999979, 35.048675841000033],
            [-80.905456250999976, 35.048762842000031],
            [-80.905509251999945, 35.048861840000029],
            [-80.90589025099996, 35.049455841000054],
            [-80.906050250999954, 35.049648841000078],
            [-80.906130251999969, 35.04971984000008],
            [-80.906324250999944, 35.049763841000072],
            [-80.906518250999966, 35.049730841000041],
            [-80.906792250999956, 35.04971984000008],
            [-80.906939250999983, 35.049719841000069],
            [-80.907187251999972, 35.049736842000073],
            [-80.907701250999935, 35.049863841000047],
            [-80.907729251999967, 35.049863841000047],
            [-80.907799250999972, 35.049905841000054],
            [-80.907914250999966, 35.049863841000047],
            [-80.907995250999988, 35.049863841000047],
            [-80.908109250999985, 35.049852841000074],
            [-80.908142251999948, 35.049841841000045],
            [-80.908292251999967, 35.04984784100003],
            [-80.908352251999986, 35.049737841000024],
            [-80.908445250999989, 35.049570841000047],
            [-80.90883125199997, 35.049461841000038],
            [-80.908945251999967, 35.049385842000049],
            [-80.909179250999955, 35.049247841000067],
            [-80.909348251999972, 35.049116840000067],
            [-80.909616251999978, 35.049118841000052],
            [-80.909693251999954, 35.049043841000071],
            [-80.909771251999985, 35.048970841000028],
            [-80.910427251999977, 35.048926840000036],
            [-80.910490251999988, 35.048947841000029],
            [-80.91064725199999, 35.049001841000063],
            [-80.910703251999962, 35.048934841000062],
            [-80.910883252999952, 35.048896841000044],
            [-80.911010251999983, 35.048929840000028],
            [-80.911124252999969, 35.049011841000038],
            [-80.911458252999978, 35.049215840000045],
            [-80.911625252999954, 35.049325841000041],
            [-80.911792251999941, 35.04944684000003],
            [-80.911857252999937, 35.049536841000076],
            [-80.911953252999979, 35.049666841000032],
            [-80.912147252999944, 35.049765841000067],
            [-80.912334253999973, 35.049896840000031],
            [-80.912514252999983, 35.050039841000057],
            [-80.912614252999958, 35.050232841000025],
            [-80.912755252999943, 35.050386840000044],
            [-80.913109252999959, 35.050600841000062],
            [-80.913610252999945, 35.050793841000029],
            [-80.914011253999945, 35.050903842000025],
            [-80.91416325299997, 35.050930841000024],
            [-80.914325252999959, 35.050958841000067],
            [-80.914432253999962, 35.050963841000055],
            [-80.91474025399998, 35.050930840000035],
            [-80.915361253999947, 35.050733841000067],
            [-80.915535253999963, 35.050810841000043],
            [-80.915869253999972, 35.050975841000025],
            [-80.916277253999965, 35.051211841000054],
            [-80.916397253999946, 35.05128884100003],
            [-80.916538254999978, 35.051409841000066],
            [-80.916738254999984, 35.051673841000024],
            [-80.916999253999961, 35.05187684100008],
            [-80.917326254999978, 35.052173841000069],
            [-80.917894254999965, 35.052618841000026],
            [-80.91808025499995, 35.052759842000057],
            [-80.91816225499997, 35.052822841000079],
            [-80.918391253999971, 35.052913841000077],
            [-80.91826825399994, 35.053179842000077],
            [-80.91820725499997, 35.053312841000036],
            [-80.917675254999949, 35.054515842000058],
            [-80.917608253999958, 35.054666842000074],
            [-80.917178253999964, 35.055700842000078],
            [-80.916719253999986, 35.056764842000064],
            [-80.916344253999966, 35.057673842000042],
            [-80.915809253999953, 35.058864843000038],
            [-80.915500254999984, 35.05952084200004],
            [-80.914989253999977, 35.060404843000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 74,
        "SolutionID": "PI-28:D",
        "Shape_Length": 0.5475532533455143,
        "Shape_Area": 0.0074649896806705677
      }
    },
    {
      "type": "Feature",
      "id": 75,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.008353277999959, 34.987295823000068],
            [-81.00829327799994, 34.987516824000068],
            [-81.008208277999984, 34.987719824000067],
            [-81.008099277999975, 34.987913825000078],
            [-81.008014276999972, 34.98803782400006],
            [-81.00726827799997, 34.989123825000036],
            [-81.006472276999943, 34.990259825000066],
            [-81.006389277999972, 34.990380824000056],
            [-81.005812276999961, 34.991221825000025],
            [-81.005573276999939, 34.991561826000066],
            [-81.005470277999962, 34.991701825000064],
            [-81.005335277999961, 34.991894825000031],
            [-81.005007276999947, 34.99236382600003],
            [-81.004877277999981, 34.992549825000026],
            [-81.004828277999934, 34.992624825000064],
            [-81.004539276999935, 34.993069825000077],
            [-81.004503276999969, 34.993132826000078],
            [-81.004438277999952, 34.99324882500008],
            [-81.004359277999981, 34.993434826000055],
            [-81.004305277999947, 34.993626826000025],
            [-81.004260276999958, 34.994035826000072],
            [-81.004239276999954, 34.994200826000053],
            [-81.00425527699997, 34.994458825000038],
            [-81.004292276999934, 34.994712825000079],
            [-81.004313277999984, 34.994769826000038],
            [-81.004409277999969, 34.995026826000071],
            [-81.004553277999946, 34.995307826000044],
            [-81.004728277999959, 34.995578826000042],
            [-81.00526027799998, 34.996352826000077],
            [-81.005500276999953, 34.996663826000031],
            [-81.005712277999976, 34.996916826000074],
            [-81.006016277999947, 34.99721882700004],
            [-81.006298277999974, 34.997477826000079],
            [-81.006819278999956, 34.997931827000059],
            [-81.007399278999969, 34.998382827000057],
            [-81.008271278999985, 34.999051827000073],
            [-81.008532278999951, 34.999257826000076],
            [-81.00891527899995, 34.999550827000064],
            [-81.009415277999949, 34.999865826000075],
            [-81.009775278999939, 35.000033827000038],
            [-81.009809278999967, 35.000049826000065],
            [-81.010207279999975, 35.000178826000024],
            [-81.010231279999971, 35.00018682700005],
            [-81.012000279999938, 35.000762827000074],
            [-81.012586280999983, 35.000958827000034],
            [-81.013814279999963, 35.001352827000062],
            [-81.014495280999938, 35.001566827000033],
            [-81.014719281999987, 35.001641827000071],
            [-81.014905280999983, 35.001724827000032],
            [-81.015075280999952, 35.00181182700004],
            [-81.01524028099999, 35.001912827000069],
            [-81.015560280999978, 35.002131827000028],
            [-81.015884280999956, 35.002367827000057],
            [-81.01694428199994, 35.003193827000075],
            [-81.017359281999973, 35.003340828000034],
            [-81.017487281999934, 35.003421828000057],
            [-81.017497281999965, 35.003258827000025],
            [-81.01751828099998, 35.003140827000038],
            [-81.017763280999986, 35.001779827000064],
            [-81.018168281999976, 34.999592827000072],
            [-81.01854328099995, 34.997625826000046],
            [-81.018830281999954, 34.996024825000063],
            [-81.018991281999945, 34.995158825000033],
            [-81.019096280999975, 34.994569825000042],
            [-81.019240281999942, 34.993923825000024],
            [-81.019332280999947, 34.993584825000028],
            [-81.019477281999968, 34.993144825000059],
            [-81.019816280999976, 34.992365825000036],
            [-81.020033281999986, 34.991899824000029],
            [-81.020065281999962, 34.991830825000079],
            [-81.020634281999946, 34.990546825000024],
            [-81.020732281999983, 34.990325824000024],
            [-81.020990281999957, 34.989716824000027],
            [-81.021082281999952, 34.989495824000073],
            [-81.021195281999951, 34.989131824000026],
            [-81.021207282999967, 34.989078824000046],
            [-81.02130828199995, 34.988640823000026],
            [-81.021354281999947, 34.988306824000063],
            [-81.021369281999966, 34.988199824000048],
            [-81.021372280999969, 34.988109824000048],
            [-81.021390280999981, 34.987590823000062],
            [-81.02139028199997, 34.987461824000036],
            [-81.02139028199997, 34.987023823000072],
            [-81.02139028199997, 34.986380823000047],
            [-81.021473281999988, 34.986438823000071],
            [-81.021840280999982, 34.986669823000057],
            [-81.02205528199994, 34.986867823000068],
            [-81.02222728299995, 34.987027823000062],
            [-81.022408281999958, 34.987241823000033],
            [-81.022521281999957, 34.987456823000059],
            [-81.02286828299998, 34.987978823000049],
            [-81.022982282999976, 34.988126824000062],
            [-81.023021282999935, 34.988313823000055],
            [-81.023155282999937, 34.988412823000033],
            [-81.023258282999961, 34.988450823000051],
            [-81.023335281999948, 34.988478824000026],
            [-81.023527281999975, 34.98853282400006],
            [-81.023629283999981, 34.988561824000044],
            [-81.02380328299995, 34.988588823000043],
            [-81.023896282999942, 34.988632824000035],
            [-81.024130282999977, 34.988803823000069],
            [-81.02425728299994, 34.988880824000034],
            [-81.024404282999967, 34.988929824000024],
            [-81.024644283999976, 34.988940824000053],
            [-81.024805282999978, 34.98896882400004],
            [-81.024833282999964, 34.988979824000069],
            [-81.025078282999971, 34.989078824000046],
            [-81.025305282999966, 34.989215824000041],
            [-81.025385283999981, 34.989342824000062],
            [-81.025466282999957, 34.98951282400003],
            [-81.025472283999989, 34.989655824000067],
            [-81.025472282999942, 34.989892824000037],
            [-81.025449282999944, 34.989991824000072],
            [-81.025352282999961, 34.990408824000042],
            [-81.025291282999945, 34.990601824000066],
            [-81.025251282999989, 34.990832824000051],
            [-81.025224283999989, 34.99121182500005],
            [-81.025191283999959, 34.99150882400005],
            [-81.025171282999963, 34.991645824000045],
            [-81.025124283999958, 34.991821824000056],
            [-81.02500328299999, 34.992090825000048],
            [-81.02500328299999, 34.992200824000065],
            [-81.025257282999974, 34.992343825000034],
            [-81.025511283999947, 34.992717825000057],
            [-81.025698283999986, 34.992838824000046],
            [-81.025791283999979, 34.992976824000039],
            [-81.026332283999977, 34.993410825000069],
            [-81.026633283999956, 34.993674825000028],
            [-81.027027283999985, 34.993977825000059],
            [-81.027200283999946, 34.994164824000052],
            [-81.027240283999959, 34.994328825000025],
            [-81.02730728399996, 34.99441682500003],
            [-81.027467283999954, 34.994658825000045],
            [-81.027460283999972, 34.994757825000079],
            [-81.027333283999951, 34.994966825000063],
            [-81.027313284999934, 34.995082825000054],
            [-81.027246283999943, 34.995252824000033],
            [-81.027186282999935, 34.995527825000067],
            [-81.026999284999988, 34.995730825000066],
            [-81.026839283999948, 34.995884826000065],
            [-81.026638283999944, 34.996126826000079],
            [-81.026531283999986, 34.996395826000025],
            [-81.026571283999942, 34.996555826000076],
            [-81.026731284999983, 34.996945826000058],
            [-81.02683828399995, 34.997148825000068],
            [-81.026972283999953, 34.997253826000076],
            [-81.02722528399994, 34.997434826000074],
            [-81.027412284999969, 34.997467825000058],
            [-81.027693283999952, 34.997495825000044],
            [-81.027840283999979, 34.997495825000044],
            [-81.028187284999944, 34.997402825000052],
            [-81.028622284999983, 34.997303825000074],
            [-81.028902283999969, 34.997292825000045],
            [-81.029229283999939, 34.997308825000061],
            [-81.02937028499997, 34.997385825000038],
            [-81.029430284999989, 34.997512826000047],
            [-81.029697284999941, 34.997715825000057],
            [-81.029804284999955, 34.997809825000047],
            [-81.030037284999935, 34.998067825000078],
            [-81.030264284999987, 34.998276825000062],
            [-81.030371285999934, 34.998425826000073],
            [-81.030445284999985, 34.998480825000058],
            [-81.030605285999968, 34.998562826000068],
            [-81.030714284999988, 34.998675826000067],
            [-81.030765285999962, 34.998727826000049],
            [-81.030899285999965, 34.998892825000041],
            [-81.031099285999971, 34.99899782500006],
            [-81.031386285999986, 34.999194826000064],
            [-81.031418285999962, 34.99921882600006],
            [-81.031881285999987, 34.998888825000051],
            [-81.032683284999962, 34.998346826000045],
            [-81.033338285999946, 34.997871825000061],
            [-81.033898285999953, 34.997445825000057],
            [-81.03431928599997, 34.997064826000042],
            [-81.034613285999967, 34.996753824000052],
            [-81.034949286999961, 34.996373825000035],
            [-81.034978286999944, 34.996331825000027],
            [-81.035089286999948, 34.996176825000077],
            [-81.035271286999944, 34.995934825000063],
            [-81.03552328699999, 34.995623824000063],
            [-81.035733285999981, 34.995266825000044],
            [-81.035817286999986, 34.995111825000038],
            [-81.035869285999979, 34.995007825000073],
            [-81.03598528699996, 34.994759825000074],
            [-81.036419286999944, 34.993894824000051],
            [-81.038724287999969, 34.989138823000076],
            [-81.038787286999934, 34.989011823000055],
            [-81.038968286999989, 34.988638823000031],
            [-81.039697287999957, 34.987135823000074],
            [-81.040417287999958, 34.987391823000053],
            [-81.040932286999976, 34.987597823000044],
            [-81.041050286999962, 34.987644823000039],
            [-81.041537287999972, 34.98785782300007],
            [-81.041927287999954, 34.988044823000052],
            [-81.042170287999966, 34.988177823000058],
            [-81.04225428899997, 34.988219823000065],
            [-81.042333288999941, 34.988257823000026],
            [-81.042868288999955, 34.988578823000068],
            [-81.043323288999943, 34.988831823000055],
            [-81.043364288999953, 34.988846823000074],
            [-81.043907287999957, 34.989031823000062],
            [-81.044329288999961, 34.989098823000063],
            [-81.044995288999985, 34.989151823000043],
            [-81.045514288999982, 34.989178824000078],
            [-81.046244289999947, 34.98920482300008],
            [-81.046666288999972, 34.989177823000034],
            [-81.047932288999959, 34.989016823000043],
            [-81.048386289999939, 34.989016822000053],
            [-81.048488289999966, 34.989024823000079],
            [-81.048711289999972, 34.989043823000031],
            [-81.048781289999965, 34.989059823000048],
            [-81.04906828999998, 34.989123823000057],
            [-81.049246289999985, 34.989176823000037],
            [-81.049377289999939, 34.989216823000049],
            [-81.050129290999962, 34.989541823000025],
            [-81.051428290999979, 34.990125822000039],
            [-81.052961291999964, 34.990830823000067],
            [-81.053230291999967, 34.990981823000027],
            [-81.053414290999967, 34.991122823000069],
            [-81.053598291999947, 34.991304824000053],
            [-81.053745291999974, 34.991505823000068],
            [-81.053774291999957, 34.99156582300003],
            [-81.053858291999973, 34.991734824000048],
            [-81.053905291999968, 34.991868823000061],
            [-81.053924291999977, 34.992058824000026],
            [-81.053942291999988, 34.992221824000069],
            [-81.053979290999962, 34.992946824000057],
            [-81.054019291999964, 34.993903824000029],
            [-81.054004291999945, 34.994134824000071],
            [-81.05399029199998, 34.994370824000043],
            [-81.053960291999942, 34.994618824000042],
            [-81.053948291999973, 34.994686824000041],
            [-81.053899290999937, 34.994969824000066],
            [-81.053786291999984, 34.995547824000028],
            [-81.05306729199998, 34.998994826000057],
            [-81.055218292999939, 34.999304825000024],
            [-81.055460291999964, 34.999302825000029],
            [-81.05569729299998, 34.999262825000073],
            [-81.055920292999986, 34.99918582500004],
            [-81.05612129299999, 34.999075824000045],
            [-81.05629429399994, 34.998935825000046],
            [-81.056430292999949, 34.998771825000063],
            [-81.05652629299999, 34.99858882500007],
            [-81.056577292999975, 34.998394824000059],
            [-81.056691292999972, 34.997406825000041],
            [-81.056820292999987, 34.997056824000026],
            [-81.056932292999988, 34.996872824000036],
            [-81.057071292999979, 34.996700824000072],
            [-81.057214292999959, 34.996537824000029],
            [-81.057320293999965, 34.996356825000078],
            [-81.057359292999934, 34.996243824000032],
            [-81.057386293999969, 34.996162824000066],
            [-81.057411292999973, 34.995963824000057],
            [-81.057398293999938, 34.995557824000059],
            [-81.057424293999986, 34.995353824000063],
            [-81.057476292999979, 34.995152824000058],
            [-81.057493292999936, 34.995107824000058],
            [-81.057553292999955, 34.994957824000039],
            [-81.058777292999935, 34.993008824000071],
            [-81.05880029399998, 34.992972823000059],
            [-81.059743293999986, 34.991644823000058],
            [-81.059812293999983, 34.99154782200003],
            [-81.06075229399994, 34.991759823000052],
            [-81.062149294999983, 34.992039823000027],
            [-81.062391294999941, 34.99206782400006],
            [-81.06263929399995, 34.992081823000035],
            [-81.062886294999942, 34.992081823000035],
            [-81.063133294999943, 34.99206782300007],
            [-81.063399293999964, 34.992036823000035],
            [-81.063718294999944, 34.992002823000064],
            [-81.063840294999977, 34.991994823000027],
            [-81.064026293999973, 34.991984823000053],
            [-81.064198294999983, 34.991984823000053],
            [-81.064367294999954, 34.991984823000053],
            [-81.064797295999938, 34.991983823000055],
            [-81.065088295999942, 34.991939822000063],
            [-81.065770294999936, 34.991855824000027],
            [-81.066069294999977, 34.991832823000038],
            [-81.066429295999967, 34.991843822000078],
            [-81.066773294999962, 34.991869823000059],
            [-81.067074294999941, 34.991918822000059],
            [-81.067375294999977, 34.991992823000032],
            [-81.067946295999946, 34.992189824000036],
            [-81.068300295999961, 34.992321823000054],
            [-81.068683295999961, 34.992461823000042],
            [-81.068763295999986, 34.992497823000065],
            [-81.069119296999986, 34.992659822000064],
            [-81.069856295999955, 34.992856822000078],
            [-81.070382295999934, 34.992930824000041],
            [-81.071165296999936, 34.992954823000048],
            [-81.075925298999948, 34.993066823000049],
            [-81.079341299999953, 34.993147822000026],
            [-81.08066329899998, 34.993159823000042],
            [-81.081069299999967, 34.993121823000024],
            [-81.081550299999947, 34.992985823000026],
            [-81.082031299999983, 34.992812823000065],
            [-81.082332299999962, 34.992663823000044],
            [-81.082707300999971, 34.992440822000049],
            [-81.08305330099995, 34.992180823000069],
            [-81.083443299999942, 34.99177282200003],
            [-81.083632300999966, 34.991515822000054],
            [-81.083763300999976, 34.991281822000076],
            [-81.083863300999951, 34.991077823000069],
            [-81.083944300999974, 34.990873822000026],
            [-81.083979300999943, 34.99078482200008],
            [-81.084064300999955, 34.990454821000071],
            [-81.084086301999946, 34.990079822000041],
            [-81.084119300999987, 34.988353821000032],
            [-81.084123300999977, 34.988106822000077],
            [-81.084152299999971, 34.986265821000075],
            [-81.084191299999986, 34.985871821000046],
            [-81.084262299999978, 34.98554082000004],
            [-81.084275299999945, 34.985477821000075],
            [-81.084398300999965, 34.985166821000064],
            [-81.084599299999979, 34.984759820000079],
            [-81.084763299999963, 34.984473820000062],
            [-81.085293300999979, 34.983537820000038],
            [-81.085347300999956, 34.983443820000048],
            [-81.08541730099995, 34.98330382000006],
            [-81.085526300999959, 34.982962821000058],
            [-81.085590299999978, 34.982672821000051],
            [-81.085809300999983, 34.981790820000072],
            [-81.085897300999989, 34.981419820000042],
            [-81.084368299999937, 34.980516820000048],
            [-81.083876299999986, 34.98019682000006],
            [-81.082955299999981, 34.979599819000043],
            [-81.082582299999956, 34.979370820000042],
            [-81.083046299999978, 34.979262820000031],
            [-81.083160299999975, 34.979212819000054],
            [-81.083437299999957, 34.979094819000068],
            [-81.082917298999973, 34.978782820000049],
            [-81.080453298999942, 34.977281819000041],
            [-81.079088297999988, 34.976351820000048],
            [-81.077775297999949, 34.97545881800005],
            [-81.077001297999971, 34.974931819000062],
            [-81.074820296999974, 34.973452818000055],
            [-81.074793296999985, 34.973415819000024],
            [-81.074274297999978, 34.973055819000024],
            [-81.074229296999988, 34.973029818000043],
            [-81.074190296999973, 34.972997819000057],
            [-81.073526296999944, 34.972536818000037],
            [-81.07183529699995, 34.97137581800007],
            [-81.071215296999981, 34.970935818000044],
            [-81.070783295999945, 34.970567818000063],
            [-81.070691294999961, 34.970465818000037],
            [-81.070464295999955, 34.970213817000058],
            [-81.070197295999947, 34.969816818000027],
            [-81.069929294999952, 34.969277817000034],
            [-81.069904295999947, 34.969196818000057],
            [-81.069756294999934, 34.968710817000044],
            [-81.069690295999976, 34.968368818000044],
            [-81.069602294999981, 34.967913817000067],
            [-81.069523294999954, 34.967505818000063],
            [-81.069471294999971, 34.967517818000033],
            [-81.068127294999954, 34.968129817000033],
            [-81.067463294999982, 34.96844481800008],
            [-81.06685629499998, 34.968744818000062],
            [-81.065914293999981, 34.969196818000057],
            [-81.065606293999963, 34.969337818000042],
            [-81.065221294999958, 34.969509818000063],
            [-81.065025293999952, 34.969565819000024],
            [-81.064702292999982, 34.969665818000067],
            [-81.064431293999974, 34.969714819000046],
            [-81.064157292999937, 34.969744818000038],
            [-81.063881294999987, 34.969754818000069],
            [-81.063605293999956, 34.969744818000038],
            [-81.063330292999979, 34.969715819000044],
            [-81.063060293999968, 34.969666818000064],
            [-81.062796292999963, 34.969597818000068],
            [-81.060928292999961, 34.969035819000055],
            [-81.060095291999971, 34.968784818000074],
            [-81.058797291999952, 34.968391818000043],
            [-81.058753291999949, 34.968378818000076],
            [-81.058044291999977, 34.96816381800005],
            [-81.057748291999985, 34.96807781800004],
            [-81.056883290999963, 34.967825818000051],
            [-81.056613290999962, 34.967767818000027],
            [-81.056338290999975, 34.967728818000069],
            [-81.056090290999975, 34.967711818000055],
            [-81.055781291999949, 34.967710818000057],
            [-81.05550329099998, 34.967731818000061],
            [-81.054933289999951, 34.967820818000064],
            [-81.054651290999971, 34.967276818000073],
            [-81.053085289999956, 34.964720818000046],
            [-81.051618289999965, 34.962232818000075],
            [-81.050478288999955, 34.960278817000074],
            [-81.049547288999975, 34.958743817000027],
            [-81.049462288999962, 34.958602816000052],
            [-81.048298287999955, 34.956776815000069],
            [-81.048265287999982, 34.956725816000073],
            [-81.048145286999954, 34.956537816000036],
            [-81.04783028899999, 34.956110817000024],
            [-81.047666287999959, 34.955887816000029],
            [-81.04750828799996, 34.955689816000074],
            [-81.047285287999955, 34.955409816000042],
            [-81.046515287999966, 34.95444681500004],
            [-81.045832286999939, 34.953589816000033],
            [-81.04268028599995, 34.949649815000043],
            [-81.040064284999971, 34.946367814000041],
            [-81.038803284999972, 34.944784814000059],
            [-81.038750283999946, 34.944718814000055],
            [-81.038553284999978, 34.944470813000066],
            [-81.038068283999962, 34.943862814000056],
            [-81.037688284999945, 34.943385813000077],
            [-81.036104283999975, 34.94144181300004],
            [-81.035261282999954, 34.940406813000038],
            [-81.033782282999937, 34.938471813000035],
            [-81.034007281999948, 34.938380813000038],
            [-81.034235283999976, 34.938305813000056],
            [-81.034454282999945, 34.938219812000057],
            [-81.03461428199995, 34.938070813000024],
            [-81.035872283999936, 34.936777812000059],
            [-81.036472283999956, 34.936166812000067],
            [-81.037432283999976, 34.935208812000042],
            [-81.039020283999946, 34.933599812000068],
            [-81.040217283999937, 34.934395812000048],
            [-81.042045284999972, 34.932435811000062],
            [-81.042144284999949, 34.932328811000048],
            [-81.042303284999946, 34.932157811000025],
            [-81.042351284999938, 34.93210681000005],
            [-81.042687284999943, 34.931745811000042],
            [-81.042959284999938, 34.931452810000053],
            [-81.043372285999965, 34.931010811000078],
            [-81.044494285999974, 34.931088811000052],
            [-81.045164285999988, 34.931147811000073],
            [-81.04581628599999, 34.931205811000041],
            [-81.046415285999956, 34.93125781100008],
            [-81.048032286999955, 34.93139681100007],
            [-81.04955728799996, 34.931529811000075],
            [-81.049780287999965, 34.93154781000004],
            [-81.050588286999982, 34.931604810000067],
            [-81.050960286999953, 34.931631811000045],
            [-81.052417286999969, 34.931755810000027],
            [-81.052486288999944, 34.931760810000071],
            [-81.054135288999987, 34.931891810000025],
            [-81.054789288999984, 34.931953810000039],
            [-81.054935288999957, 34.931964811000057],
            [-81.055162288999952, 34.93205781000006],
            [-81.055354288999979, 34.932177811000031],
            [-81.055402288999971, 34.932238811000047],
            [-81.055952288999947, 34.932239811000045],
            [-81.058168290999959, 34.932244810000043],
            [-81.057794289999947, 34.931214810000029],
            [-81.057546289999948, 34.930550810000057],
            [-81.05733528899998, 34.929869810000071],
            [-81.057175289999975, 34.929253810000034],
            [-81.057156288999977, 34.929151810000064],
            [-81.057051288999958, 34.928661810000051],
            [-81.05690328899999, 34.927691810000056],
            [-81.05682528899996, 34.927178810000044],
            [-81.056702289999976, 34.926228810000055],
            [-81.056650288999947, 34.925827809000054],
            [-81.056558288999952, 34.925215809000065],
            [-81.056503289999966, 34.924773808000054],
            [-81.056458288999977, 34.924487809000027],
            [-81.056423288999952, 34.92436181000005],
            [-81.056398288999958, 34.924269809000066],
            [-81.056364288999987, 34.924145809000038],
            [-81.056247288999941, 34.923858809000023],
            [-81.056114288999936, 34.923594808000075],
            [-81.05607028899999, 34.923529809000058],
            [-81.05594328899997, 34.923344808000024],
            [-81.055699288999961, 34.923061808000057],
            [-81.055551288999936, 34.922916809000071],
            [-81.055570288999945, 34.922889808000036],
            [-81.05563628799996, 34.92271880900006],
            [-81.055650288999971, 34.922614808000048],
            [-81.055656288999955, 34.922416808000037],
            [-81.055690288999983, 34.92216380800005],
            [-81.055823288999989, 34.921943808000037],
            [-81.056077288999973, 34.921377808000045],
            [-81.056197288999954, 34.921152808000045],
            [-81.05627028899994, 34.921047808000026],
            [-81.056391288999976, 34.920954808000033],
            [-81.056477288999986, 34.92077880800008],
            [-81.056684288999975, 34.920190808000029],
            [-81.056702288999986, 34.92008480800007],
            [-81.056958288999965, 34.920157808000056],
            [-81.058356289999949, 34.920539808000058],
            [-81.061691290999988, 34.921457807000024],
            [-81.06246028999999, 34.921668808000049],
            [-81.062464290999969, 34.921168808000061],
            [-81.062450290999948, 34.920327807000035],
            [-81.062472290999949, 34.920030808000035],
            [-81.062484289999986, 34.91987180700005],
            [-81.062450290999948, 34.919508807000057],
            [-81.062537290999956, 34.91907980700006],
            [-81.06264428999998, 34.918799808000074],
            [-81.06277129099999, 34.918496807000054],
            [-81.062911290999978, 34.918067807000057],
            [-81.062938289999977, 34.917848807000041],
            [-81.06304529099998, 34.917633808000062],
            [-81.063118290999967, 34.917490807000036],
            [-81.063198290999935, 34.917182808000064],
            [-81.063265289999947, 34.916831807000051],
            [-81.063300289999972, 34.916722807000042],
            [-81.063378290999935, 34.916484806000028],
            [-81.063398289999952, 34.916110807000052],
            [-81.063398289999952, 34.915721807000068],
            [-81.063398289999952, 34.915632806000076],
            [-81.063492290999989, 34.915346807000049],
            [-81.063538290999986, 34.914874807000047],
            [-81.06361229099997, 34.914599806000069],
            [-81.063711290999947, 34.914353807000055],
            [-81.063732290999951, 34.91430280600008],
            [-81.063932290999958, 34.913988806000077],
            [-81.064046290999954, 34.913801806000038],
            [-81.064232290999939, 34.913384805000078],
            [-81.064466290999974, 34.912955805000024],
            [-81.064599290999979, 34.912839806000079],
            [-81.064773290999938, 34.91274680600003],
            [-81.064800290999983, 34.912504807000062],
            [-81.064940290999971, 34.91209780500003],
            [-81.065033290999963, 34.91195480600004],
            [-81.065060290999952, 34.91181180500007],
            [-81.065073290999976, 34.911372806000031],
            [-81.065127290999953, 34.911185806000049],
            [-81.065293289999943, 34.910772805000079],
            [-81.065460291999955, 34.91041580600006],
            [-81.065478290999977, 34.910298806000071],
            [-81.064394289999939, 34.91037480500006],
            [-81.063985289999948, 34.91037880600004],
            [-81.063746290999973, 34.910364806000075],
            [-81.063464290999946, 34.910336806000032],
            [-81.063092290999975, 34.910271805000036],
            [-81.062863289999939, 34.910213805000069],
            [-81.062477289999947, 34.910080805000064],
            [-81.062250289999952, 34.909986806000063],
            [-81.061838288999979, 34.909773805000043],
            [-81.061464289999947, 34.909511805000079],
            [-81.06110928999999, 34.909194805000027],
            [-81.061030289999962, 34.909110805000068],
            [-81.060952289999989, 34.909026805000053],
            [-81.060737289999963, 34.908758805000048],
            [-81.060426288999963, 34.908363805000079],
            [-81.06015728999995, 34.90802280500003],
            [-81.060054289999982, 34.907896806000053],
            [-81.059877289999974, 34.907680805000041],
            [-81.059843289999947, 34.907638805000033],
            [-81.059550288999958, 34.907317806000037],
            [-81.05948828999999, 34.907249805000049],
            [-81.059291288999987, 34.907074805000036],
            [-81.05905028799998, 34.906941805000031],
            [-81.058896288999961, 34.906869806000032],
            [-81.058832287999962, 34.906843805000051],
            [-81.058788288999949, 34.906885805000059],
            [-81.058627288999958, 34.907039805000068],
            [-81.057709288999945, 34.907812805000049],
            [-81.057386288999965, 34.908084805000044],
            [-81.056226287999948, 34.908898805000035],
            [-81.055067287999975, 34.909585805000063],
            [-81.054911287999971, 34.909678805000055],
            [-81.053746286999967, 34.910298806000071],
            [-81.05313328799997, 34.910625806000041],
            [-81.048589285999981, 34.913076806000049],
            [-81.047636285999943, 34.913607807000062],
            [-81.047229284999958, 34.913820806000047],
            [-81.046408285999973, 34.91425080700003],
            [-81.045699285999945, 34.914626808000037],
            [-81.044763284999988, 34.913827807000075],
            [-81.041949282999951, 34.91157180600004],
            [-81.040440283999942, 34.910360807000075],
            [-81.039956282999981, 34.909939807000057],
            [-81.039913283999965, 34.909964806000062],
            [-81.039749282999935, 34.910102807000044],
            [-81.039241282999967, 34.910507806000055],
            [-81.038588282999967, 34.909959806000074],
            [-81.037137281999946, 34.91110580600008],
            [-81.035927281999989, 34.912092807000079],
            [-81.035689281999964, 34.912266807000037],
            [-81.035362281999937, 34.912550807000059],
            [-81.034870280999939, 34.912937807000048],
            [-81.034337281999967, 34.913384807000057],
            [-81.034190280999951, 34.91350780700003],
            [-81.033750281999971, 34.914121808000061],
            [-81.033620280999969, 34.91430280700007],
            [-81.034666282999979, 34.915044807000072],
            [-81.034329281999987, 34.915381808000063],
            [-81.032983280999986, 34.916727808000076],
            [-81.032704280999951, 34.916974808000077],
            [-81.032548281999937, 34.917100808000043],
            [-81.031509280999956, 34.917937808000033],
            [-81.031117281999968, 34.91825280900008],
            [-81.030765280999958, 34.918535808000058],
            [-81.029798280999955, 34.919310809000024],
            [-81.028894280999964, 34.918437808000078],
            [-81.028462280999975, 34.918022809000036],
            [-81.02787227999994, 34.917456809000043],
            [-81.027289279999934, 34.91692580800003],
            [-81.026976278999939, 34.918015809000053],
            [-81.026953279999987, 34.918177809000042],
            [-81.026846279999972, 34.918948809000028],
            [-81.026770279999937, 34.919488809000029],
            [-81.023994278999965, 34.918412809000074],
            [-81.022574278999969, 34.917847809000079],
            [-81.02016227699994, 34.916796808000072],
            [-81.019462276999946, 34.917924809000056],
            [-81.018824277999954, 34.919019809000076],
            [-81.018173277999949, 34.920084810000048],
            [-81.015628276999962, 34.918782809000049],
            [-81.014296275999982, 34.918180809000035],
            [-81.013244275999966, 34.917545809000046],
            [-81.012876275999986, 34.918783809000047],
            [-81.011365274999946, 34.918040809000047],
            [-81.011148275999972, 34.917952809000042],
            [-81.010866274999955, 34.917862810000031],
            [-81.009617274999982, 34.917460809000033],
            [-81.009354274999964, 34.918059810000045],
            [-81.009049273999949, 34.918704809000076],
            [-81.00896127499999, 34.918944809000038],
            [-81.008787274999975, 34.919200809000074],
            [-81.008083273999944, 34.918847809000056],
            [-81.007623273999968, 34.918679810000071],
            [-81.006377272999941, 34.918224809000037],
            [-81.005679273999988, 34.917969809000056],
            [-81.003896271999963, 34.917479809000042],
            [-81.001499270999943, 34.916980809000052],
            [-81.000353271999984, 34.916767809000078],
            [-81.000073271999952, 34.916715809000038],
            [-80.999485271999959, 34.916606809000029],
            [-80.999103270999967, 34.916535809000038],
            [-80.995679269999982, 34.915715809000062],
            [-80.992570269999987, 34.915045808000059],
            [-80.992052269999988, 34.914925808000078],
            [-80.990423267999972, 34.914548810000042],
            [-80.989856268999972, 34.914417808000053],
            [-80.986301267999977, 34.913433809000026],
            [-80.984581266999953, 34.913064809000048],
            [-80.984457266999982, 34.913037809000059],
            [-80.983220266999979, 34.912890809000032],
            [-80.982738265999956, 34.912833809000063],
            [-80.98246226599997, 34.913373809000063],
            [-80.982418266999957, 34.913458810000066],
            [-80.982390265999982, 34.913509809000061],
            [-80.981021265999971, 34.916134809000027],
            [-80.98066926599995, 34.916808810000077],
            [-80.98038726599998, 34.917348810000078],
            [-80.979437265999934, 34.919169811000074],
            [-80.97890926599996, 34.92018081100008],
            [-80.978632265999977, 34.920711811000047],
            [-80.975380265999945, 34.926940812000055],
            [-80.97400526499996, 34.929575813000042],
            [-80.973883264999984, 34.929814813000064],
            [-80.97238426399997, 34.932763814000054],
            [-80.972104263999938, 34.933314813000038],
            [-80.971642263999968, 34.934204814000054],
            [-80.971399263999956, 34.934774815000026],
            [-80.97122826399999, 34.935504814000069],
            [-80.971156263999944, 34.936262814000031],
            [-80.971063264999941, 34.93724981500003],
            [-80.97108626399995, 34.937975814000026],
            [-80.971124264999958, 34.938316815000064],
            [-80.97113126499994, 34.938604815000076],
            [-80.971112264999988, 34.938898815000073],
            [-80.97109726399998, 34.939051815000028],
            [-80.971044263999943, 34.939336816000036],
            [-80.970980263999934, 34.939546815000028],
            [-80.970873264999966, 34.939899815000047],
            [-80.970678264999947, 34.940009815000053],
            [-80.970228263999957, 34.940175816000078],
            [-80.971315264999987, 34.940326815000049],
            [-80.971790264999981, 34.940407816000061],
            [-80.972464264999985, 34.940509816000031],
            [-80.973513264999951, 34.942958816000043],
            [-80.974203264999971, 34.944570816000066],
            [-80.974233265999942, 34.944624816000044],
            [-80.974306265999985, 34.944796816000064],
            [-80.974498265999955, 34.945248817000049],
            [-80.974539265999965, 34.945367816000044],
            [-80.974595264999948, 34.945476816000053],
            [-80.974731265999935, 34.945796816000041],
            [-80.975071266999976, 34.94659581600007],
            [-80.97619526699998, 34.949241818000075],
            [-80.976345266999942, 34.949592818000042],
            [-80.976577266999982, 34.950130817000058],
            [-80.976684266999939, 34.950380817000053],
            [-80.97675426699999, 34.950542817000041],
            [-80.980033267999943, 34.95814081900005],
            [-80.982703269999945, 34.964319821000061],
            [-80.983185269999979, 34.965432820000046],
            [-80.983382268999947, 34.965887820000034],
            [-80.984113269999966, 34.967579821000072],
            [-80.984160269999961, 34.967687820000037],
            [-80.984306269999934, 34.968025821000026],
            [-80.984538268999984, 34.96856082000005],
            [-80.984673269999973, 34.968881821000025],
            [-80.984715269999981, 34.968980821000059],
            [-80.985269270999936, 34.970283821000066],
            [-80.98539126999998, 34.970569821000026],
            [-80.986583269999983, 34.973370822000049],
            [-80.98667427099997, 34.973584822000078],
            [-80.986781270999984, 34.973803822000036],
            [-80.986950270999955, 34.974198822000062],
            [-80.987422270999957, 34.975301822000063],
            [-80.987692270999958, 34.975933822000059],
            [-80.987954271999968, 34.976545822000048],
            [-80.988040271999978, 34.976839823000034],
            [-80.988122270999952, 34.977114823000079],
            [-80.988196270999936, 34.977396823000049],
            [-80.988251271999957, 34.977681822000079],
            [-80.988289271999975, 34.977967823000029],
            [-80.988307271999986, 34.97825582300004],
            [-80.988308271999983, 34.978543822000063],
            [-80.988291271999969, 34.978831823000064],
            [-80.988272270999971, 34.97899882300004],
            [-80.988101271999938, 34.97993282300007],
            [-80.987897271999941, 34.980592823000052],
            [-80.987672271999941, 34.981089823000048],
            [-80.987631271999987, 34.981142823000027],
            [-80.987254270999983, 34.981746824000027],
            [-80.986699271999953, 34.982567824000057],
            [-80.983784270999934, 34.986879825000074],
            [-80.983757270999945, 34.986917825000035],
            [-80.983400270999937, 34.987445825000066],
            [-80.984278269999947, 34.987622826000063],
            [-80.986819270999945, 34.988387825000075],
            [-80.987669272999938, 34.988649825000039],
            [-80.989118271999985, 34.989340825000056],
            [-80.989497272999984, 34.989521825000054],
            [-80.989815273999966, 34.989647826000066],
            [-80.989874272999941, 34.989670826000065],
            [-80.989952272999972, 34.989703825000049],
            [-80.99115427299995, 34.990216826000051],
            [-80.993061272999967, 34.991047825000066],
            [-80.993153272999962, 34.991100825000046],
            [-80.993293273999939, 34.991181825000069],
            [-80.993321273999982, 34.991161825000063],
            [-80.993668273999958, 34.990940826000042],
            [-80.993799273999969, 34.990844825000067],
            [-80.993871274999947, 34.990803826000047],
            [-80.993916273999957, 34.990787825000041],
            [-80.993954273999975, 34.990777825000066],
            [-80.99399727399998, 34.990772825000079],
            [-80.994041274999972, 34.990773825000076],
            [-80.994170273999941, 34.990797826000062],
            [-80.994255274999944, 34.990817825000079],
            [-80.994457273999956, 34.990885825000078],
            [-80.994541273999971, 34.990907825000079],
            [-80.994584273999976, 34.990913826000053],
            [-80.994628274999968, 34.990908825000076],
            [-80.994669273999989, 34.990895826000042],
            [-80.994836273999965, 34.990857825000035],
            [-80.994918273999986, 34.990833825000038],
            [-80.994996274999949, 34.990803826000047],
            [-80.995038273999967, 34.990795825000077],
            [-80.995114274999935, 34.990762825000047],
            [-80.995234274999973, 34.990717825000047],
            [-80.995297273999938, 34.990668825000057],
            [-80.995352274999959, 34.990612824000038],
            [-80.995462273999976, 34.990555825000058],
            [-80.995570273999988, 34.990493824000055],
            [-80.995602274999953, 34.990469825000048],
            [-80.995627274999947, 34.990441825000062],
            [-80.995646274999956, 34.990409826000075],
            [-80.995654273999946, 34.99037482600005],
            [-80.995649273999959, 34.990339825000035],
            [-80.99566327499997, 34.990305825000064],
            [-80.995692273999964, 34.990279825000073],
            [-80.995743273999949, 34.990222825000046],
            [-80.995827273999964, 34.99014182500008],
            [-80.996088274999977, 34.990013825000062],
            [-80.996155273999989, 34.989968825000062],
            [-80.996285273999945, 34.98987182500008],
            [-80.996387274999961, 34.989803825000024],
            [-80.99645927499995, 34.989764825000066],
            [-80.996499274999962, 34.989750825000044],
            [-80.996583274999978, 34.989730826000027],
            [-80.996670273999939, 34.989730826000027],
            [-80.996756274999939, 34.989736824000033],
            [-80.996921274999977, 34.989779824000038],
            [-80.996964274999982, 34.989784826000061],
            [-80.997008274999985, 34.989782825000077],
            [-80.997092273999954, 34.989762825000071],
            [-80.997127274999968, 34.989740825000069],
            [-80.997238274999972, 34.989685825000038],
            [-80.997352274999969, 34.989634825000053],
            [-80.997381274999952, 34.989608825000062],
            [-80.997406274999946, 34.989578825000024],
            [-80.997440274999974, 34.989513825000074],
            [-80.99747627499994, 34.98941082500005],
            [-80.997549274999983, 34.989321825000047],
            [-80.997613274999935, 34.989273824000065],
            [-80.997724274999939, 34.989215825000031],
            [-80.997766274999947, 34.989204825000058],
            [-80.99798227499997, 34.989180825000062],
            [-80.998025274999975, 34.989178825000067],
            [-80.998069274999978, 34.989182825000057],
            [-80.998194275999936, 34.989210825000043],
            [-80.99844027499995, 34.989280825000037],
            [-80.998599274999947, 34.989337825000064],
            [-80.998709274999953, 34.989395825000031],
            [-80.998788275999971, 34.989427825000064],
            [-80.998917275999986, 34.989446825000073],
            [-80.999006274999942, 34.989446825000073],
            [-80.999181274999955, 34.989434825000046],
            [-80.999531274999981, 34.98945182500006],
            [-80.999747274999947, 34.989475825000056],
            [-80.999785274999965, 34.989493825000068],
            [-80.999827274999973, 34.989506825000035],
            [-80.999859275999938, 34.989529825000034],
            [-80.999970274999953, 34.989639824000051],
            [-81.000009275999957, 34.989651825000067],
            [-81.000137275999975, 34.989674824000076],
            [-81.000223275999986, 34.989661824000052],
            [-81.000309275999939, 34.989657825000052],
            [-81.000395275999949, 34.989669825000078],
            [-81.000436275999959, 34.989683825000043],
            [-81.000472274999936, 34.989703825000049],
            [-81.000536275999934, 34.989748826000039],
            [-81.000563274999934, 34.989776825000035],
            [-81.000693275999936, 34.989867825000033],
            [-81.000843276999944, 34.989936825000029],
            [-81.000925275999975, 34.989959825000028],
            [-81.001225275999957, 34.989992825000058],
            [-81.001661275999936, 34.989983825000024],
            [-81.001704275999941, 34.989983825000024],
            [-81.001832275999959, 34.98997382500005],
            [-81.002089276999982, 34.989972824000063],
            [-81.002131275999943, 34.989978825000037],
            [-81.002219275999948, 34.989976825000042],
            [-81.002305275999959, 34.989983825000024],
            [-81.002562275999935, 34.990031824000027],
            [-81.002950276999968, 34.990091825000036],
            [-81.003334275999975, 34.990168824000079],
            [-81.003417275999936, 34.990189824000026],
            [-81.003546276999941, 34.990183824000042],
            [-81.003631276999954, 34.990166825000074],
            [-81.003674276999959, 34.99016182400004],
            [-81.003714276999972, 34.990148824000073],
            [-81.003784276999966, 34.990105825000057],
            [-81.003908277999983, 34.990004825000028],
            [-81.00394427699996, 34.989985825000076],
            [-81.004031277999957, 34.989979825000034],
            [-81.004117276999978, 34.989990824000074],
            [-81.004158276999988, 34.990003825000031],
            [-81.004235276999964, 34.990038825000056],
            [-81.004354276999948, 34.990084825000054],
            [-81.004397276999953, 34.990090825000038],
            [-81.004485276999958, 34.990091825000036],
            [-81.004571276999968, 34.990076824000028],
            [-81.004649276999942, 34.990045824000049],
            [-81.004858277999972, 34.98991682500008],
            [-81.004938277999941, 34.989889824000045],
            [-81.005063276999977, 34.989862825000046],
            [-81.005238277999979, 34.989850825000076],
            [-81.005400276999978, 34.989799825000034],
            [-81.005516277999959, 34.989749825000047],
            [-81.00555327699999, 34.98972982500004],
            [-81.005583276999971, 34.989704824000057],
            [-81.005654276999962, 34.989664825000034],
            [-81.005785277999962, 34.989573825000036],
            [-81.005857276999961, 34.989534825000078],
            [-81.006055277999963, 34.989457825000045],
            [-81.006087276999949, 34.989432824000062],
            [-81.006112276999943, 34.989403825000068],
            [-81.006198276999953, 34.989322825000045],
            [-81.006321276999984, 34.989225824000073],
            [-81.006380277999938, 34.989173824000034],
            [-81.006456277999973, 34.989086825000072],
            [-81.006462277999958, 34.98905082400006],
            [-81.006456276999984, 34.989016824000032],
            [-81.006456277999973, 34.988946825000028],
            [-81.006444277999947, 34.988912825000057],
            [-81.006358276999947, 34.988831824000044],
            [-81.006260277999957, 34.988760824000053],
            [-81.006209276999982, 34.988703824000027],
            [-81.006192277999958, 34.988670824000053],
            [-81.006175277999944, 34.988530825000055],
            [-81.006183276999934, 34.98849582500003],
            [-81.006201276999946, 34.988463825000053],
            [-81.006226276999939, 34.988396824000063],
            [-81.006253277999974, 34.988257825000062],
            [-81.006279276999976, 34.987760824000077],
            [-81.00629627699999, 34.987548824000044],
            [-81.006305277999957, 34.987513824000075],
            [-81.006320277999976, 34.987481824000042],
            [-81.006367276999981, 34.98742182400008],
            [-81.006461276999971, 34.987348825000026],
            [-81.00649627699994, 34.987328824000031],
            [-81.006611276999934, 34.987279823000051],
            [-81.006776276999972, 34.987243824000075],
            [-81.006857277999984, 34.987218824000024],
            [-81.006928277999975, 34.987178823000079],
            [-81.007059276999939, 34.987115825000046],
            [-81.007473277999964, 34.98695682400006],
            [-81.007954277999943, 34.986840824000069],
            [-81.008241278999947, 34.986830823000048],
            [-81.008427276999953, 34.986808823000047],
            [-81.008353277999959, 34.987295823000068]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 75,
        "SolutionID": "PI-28:E",
        "Shape_Length": 0.49852191979557847,
        "Shape_Area": 0.0061678888042735009
      }
    },
    {
      "type": "Feature",
      "id": 76,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.852819257999954, 35.473559934000036],
            [-80.852986258999977, 35.47381693400007],
            [-80.853483257999983, 35.474582933000079],
            [-80.853982258999963, 35.47534093400003],
            [-80.854070258999968, 35.475303933000077],
            [-80.855484259999969, 35.474881933000063],
            [-80.855609258999948, 35.474847934000024],
            [-80.856376258999944, 35.474620934000029],
            [-80.856454258999975, 35.474588933000064],
            [-80.856551259999947, 35.474534933000029],
            [-80.856625258999941, 35.474479934000044],
            [-80.856700258999979, 35.474407933000066],
            [-80.856831258999989, 35.474246934000064],
            [-80.857323259999987, 35.473587933000033],
            [-80.858555259999946, 35.472049933000051],
            [-80.858859259999974, 35.471678933000078],
            [-80.859587259999955, 35.470757933000073],
            [-80.859659259999944, 35.47067693200006],
            [-80.859740259999967, 35.470594933000029],
            [-80.859936259999984, 35.47041493200004],
            [-80.860587260999978, 35.46987693300008],
            [-80.861382259999971, 35.469229932000076],
            [-80.861648260999971, 35.469003932000078],
            [-80.861939260999975, 35.468763933000048],
            [-80.862597260999962, 35.468205932000046],
            [-80.86277026099998, 35.468048932000045],
            [-80.862910260999968, 35.467912931000058],
            [-80.863084260999983, 35.467733932000044],
            [-80.863848260999987, 35.466925931000048],
            [-80.864282261999961, 35.466468932000055],
            [-80.865034260999948, 35.465675931000078],
            [-80.865391261999946, 35.465307931000041],
            [-80.865659261999951, 35.465019931000029],
            [-80.866516261999948, 35.464119931000027],
            [-80.866650260999961, 35.463985931000025],
            [-80.866743261999943, 35.463890931000037],
            [-80.866997261999984, 35.46360793000008],
            [-80.86712726199994, 35.463426931000072],
            [-80.86720126199998, 35.463313930000027],
            [-80.867253261999963, 35.463210931000049],
            [-80.867282261999947, 35.463106931000027],
            [-80.867327261999947, 35.462902930000041],
            [-80.867350261999945, 35.46274993000003],
            [-80.867438261999951, 35.462236931000064],
            [-80.867688261999945, 35.46078293000005],
            [-80.867819261999955, 35.460062930000049],
            [-80.867909262999945, 35.459682930000042],
            [-80.867919261999987, 35.459550929000045],
            [-80.867917260999945, 35.459465930000079],
            [-80.867895261999934, 35.459389930000043],
            [-80.867853260999937, 35.45930793000008],
            [-80.867798261999951, 35.459242930000073],
            [-80.867660261999958, 35.459110930000065],
            [-80.867505260999963, 35.458938930000045],
            [-80.867457262999949, 35.458870930000046],
            [-80.867261260999953, 35.458522929000026],
            [-80.868609262999939, 35.458204929000033],
            [-80.869342261999975, 35.460051930000077],
            [-80.869840262999958, 35.461323930000049],
            [-80.870135262999952, 35.462077930000078],
            [-80.870704262999936, 35.463521931000059],
            [-80.870926263999934, 35.464067931000045],
            [-80.871044262999987, 35.464371931000073],
            [-80.871620262999954, 35.46433993100004],
            [-80.872769262999952, 35.464240931000063],
            [-80.873311263999938, 35.464183931000036],
            [-80.87382426399995, 35.464119931000027],
            [-80.874321263999946, 35.464045931000044],
            [-80.874803263999979, 35.463965930000029],
            [-80.875119263999977, 35.463909931000046],
            [-80.875420263999956, 35.463845931000037],
            [-80.875926265999965, 35.463736931000028],
            [-80.876267263999978, 35.463663930000052],
            [-80.876377264999974, 35.463636931000053],
            [-80.876471264999964, 35.463613931000054],
            [-80.876691264999977, 35.463557930000036],
            [-80.880429265999965, 35.462590929000044],
            [-80.881941265999956, 35.462200930000051],
            [-80.882991265999976, 35.461922930000071],
            [-80.883411266999985, 35.461811929000078],
            [-80.884241266999936, 35.461591930000054],
            [-80.884644266999942, 35.461492930000077],
            [-80.884859266999968, 35.461451930000067],
            [-80.88508326699997, 35.461420929000042],
            [-80.885334266999962, 35.461400929000035],
            [-80.88586326799998, 35.461378930000024],
            [-80.887085266999975, 35.461347929000056],
            [-80.887678267999945, 35.461323930000049],
            [-80.888012268999944, 35.461314929000025],
            [-80.888506267999958, 35.461311930000079],
            [-80.888657268999964, 35.461316930000066],
            [-80.888641268999947, 35.460774929000024],
            [-80.888628267999934, 35.46001792900006],
            [-80.88860626799999, 35.45926692900008],
            [-80.888590266999984, 35.458311929000047],
            [-80.888547267999968, 35.456932929000061],
            [-80.88848726699996, 35.454348928000059],
            [-80.88847526699999, 35.454072928000073],
            [-80.888471267999989, 35.453916928000069],
            [-80.888455267999973, 35.453324929000075],
            [-80.888450266999939, 35.453141928000036],
            [-80.888442267999949, 35.452572928000052],
            [-80.888452266999934, 35.452399928000034],
            [-80.888463267999953, 35.452251928000067],
            [-80.888468266999951, 35.452191928000047],
            [-80.888494267999988, 35.452038927000046],
            [-80.888535267999941, 35.451862927000036],
            [-80.888588267999978, 35.451704928000026],
            [-80.888678267999978, 35.451504928000077],
            [-80.888785266999946, 35.451318927000045],
            [-80.888899267999989, 35.451154928000051],
            [-80.889074267999945, 35.450947927000072],
            [-80.889237266999942, 35.450780927000039],
            [-80.889460268999983, 35.450584927000079],
            [-80.890052267999977, 35.450104927000041],
            [-80.891016267999987, 35.449325928000064],
            [-80.891331268999977, 35.44905092700003],
            [-80.891498268999953, 35.448885926000059],
            [-80.891644268999983, 35.448730927000042],
            [-80.891778268999985, 35.448571927000046],
            [-80.891933268999935, 35.448363927000059],
            [-80.89211826899998, 35.44807492700005],
            [-80.892181268999934, 35.447970927000028],
            [-80.89231426799995, 35.447729926000079],
            [-80.89290626899998, 35.446590926000056],
            [-80.893027268999958, 35.446370926000043],
            [-80.893216267999946, 35.446092926000063],
            [-80.893284267999945, 35.446009926000045],
            [-80.893324267999958, 35.445959925000068],
            [-80.893410268999958, 35.445873926000047],
            [-80.893504268999948, 35.445784926000044],
            [-80.893702268999959, 35.445618926000066],
            [-80.893965269999967, 35.44543692600007],
            [-80.894119268999987, 35.445303926000065],
            [-80.894210268999984, 35.445217925000065],
            [-80.89428326899997, 35.445140925000032],
            [-80.894345269999974, 35.445036926000057],
            [-80.894395268999972, 35.44491892700006],
            [-80.894425269999942, 35.44478792600006],
            [-80.894431269999984, 35.444622926000079],
            [-80.89405026899999, 35.444616926000037],
            [-80.893812268999966, 35.444602926000073],
            [-80.893673267999986, 35.444598925000037],
            [-80.893326267999953, 35.444571926000037],
            [-80.892497267999943, 35.444494926000061],
            [-80.890481266999984, 35.444308926000076],
            [-80.890381266999952, 35.44429692600005],
            [-80.890323267999975, 35.444290926000065],
            [-80.888773267999966, 35.444144926000035],
            [-80.886199266999938, 35.443922926000027],
            [-80.88533126599998, 35.443840926000064],
            [-80.884337264999942, 35.443740926000032],
            [-80.883938266999962, 35.443701926000074],
            [-80.883225264999965, 35.443624926000041],
            [-80.881983264999974, 35.443511926000042],
            [-80.882002264999983, 35.443299926000066],
            [-80.882058264999955, 35.442792926000038],
            [-80.88208526599999, 35.442630926000049],
            [-80.88213826499998, 35.442419926000071],
            [-80.882425264999938, 35.441855926000073],
            [-80.882527264999965, 35.441679926000063],
            [-80.88261926499996, 35.441543926000065],
            [-80.882703264999975, 35.441448926000078],
            [-80.882857264999984, 35.441301925000062],
            [-80.88312926499998, 35.441067925000027],
            [-80.883789265999951, 35.440537925000058],
            [-80.883961264999982, 35.44039392600007],
            [-80.884389265999971, 35.440049925000039],
            [-80.884546265999973, 35.439931925000053],
            [-80.884713265999949, 35.439825925000036],
            [-80.884958265999956, 35.439697925000075],
            [-80.885825265999983, 35.439289925000026],
            [-80.887003266999955, 35.438751925000076],
            [-80.887411266999948, 35.438560925000047],
            [-80.887894266999979, 35.438335925000047],
            [-80.88847926699998, 35.438069924000047],
            [-80.888955265999982, 35.437847924000039],
            [-80.889150266999934, 35.43777992400004],
            [-80.889359267999964, 35.437712925000028],
            [-80.889622266999936, 35.437638925000044],
            [-80.89032926699997, 35.437463924000042],
            [-80.890459266999983, 35.437706924000054],
            [-80.890530266999974, 35.437798925000038],
            [-80.890602267999952, 35.43786992400004],
            [-80.89069726699995, 35.437942924000026],
            [-80.890859267999986, 35.438051925000025],
            [-80.891156267999975, 35.43822992500003],
            [-80.891675267999972, 35.438625924000064],
            [-80.891853267999977, 35.438768925000034],
            [-80.892416267999977, 35.439207924000073],
            [-80.892559267999957, 35.439306925000039],
            [-80.892717266999966, 35.439397925000037],
            [-80.892911268999967, 35.439497926000058],
            [-80.893172267999944, 35.439602925000031],
            [-80.89336026899997, 35.439657925000063],
            [-80.89352226799997, 35.439699924000024],
            [-80.893717268999978, 35.439737925000031],
            [-80.893946267999979, 35.439764925000077],
            [-80.894069267999953, 35.439770925000062],
            [-80.894191268999975, 35.439779925000039],
            [-80.894323268999983, 35.43977992400005],
            [-80.894529269999964, 35.439767925000069],
            [-80.894753268999978, 35.439742925000076],
            [-80.89498526899996, 35.439699924000024],
            [-80.895271268999977, 35.439620925000042],
            [-80.895463268999947, 35.439558924000039],
            [-80.895615268999961, 35.439503924000064],
            [-80.895747269999958, 35.439444925000032],
            [-80.895887268999957, 35.439375925000036],
            [-80.896132268999963, 35.439226924000025],
            [-80.896365268999944, 35.439053924000063],
            [-80.896501268999941, 35.438947925000036],
            [-80.896603268999968, 35.438848925000059],
            [-80.896829269999955, 35.438617925000074],
            [-80.896926269999938, 35.438500925000028],
            [-80.897179268999935, 35.438623924000069],
            [-80.897738268999944, 35.438909925000075],
            [-80.898390269999936, 35.439234924000061],
            [-80.898690269999975, 35.439372925000043],
            [-80.898967269999957, 35.439499924000074],
            [-80.899237269999958, 35.439650925000024],
            [-80.899299269999972, 35.439698924000027],
            [-80.899798269999962, 35.44017692500006],
            [-80.899922269999934, 35.440269925000052],
            [-80.900030270999935, 35.440338924000059],
            [-80.900348269999938, 35.440525925000031],
            [-80.900605269999971, 35.440256925000028],
            [-80.900813270999947, 35.440029924000044],
            [-80.901003270999979, 35.439824925000039],
            [-80.901319269999988, 35.439469924000036],
            [-80.90176827099998, 35.438947924000047],
            [-80.901984270999947, 35.438678925000033],
            [-80.903657271999975, 35.436492924000049],
            [-80.905586271999937, 35.433950923000054],
            [-80.906032271999948, 35.433361923000064],
            [-80.906301271999951, 35.433034923000037],
            [-80.906385272999955, 35.432942923000041],
            [-80.906530270999951, 35.432795923000072],
            [-80.90668427199995, 35.432651923000037],
            [-80.906601272999978, 35.432582922000051],
            [-80.906136271999969, 35.432125923000058],
            [-80.905550270999981, 35.431510923000076],
            [-80.905257270999982, 35.431140922000054],
            [-80.905174270999964, 35.431015922000029],
            [-80.905141270999934, 35.430954923000058],
            [-80.905074270999989, 35.430831922000039],
            [-80.904940271999976, 35.430524923000064],
            [-80.904918271999975, 35.430436922000069],
            [-80.904899270999977, 35.430287923000037],
            [-80.904980271999989, 35.429844922000029],
            [-80.904992271999959, 35.429724923000038],
            [-80.904999271999941, 35.429646923000064],
            [-80.904992271999959, 35.42950292200004],
            [-80.904955270999949, 35.429441922000024],
            [-80.904902270999969, 35.429407922000053],
            [-80.904329270999938, 35.429245922000064],
            [-80.903384270999936, 35.428937922000046],
            [-80.90316427099998, 35.428873921000047],
            [-80.902892269999938, 35.428812921000031],
            [-80.902526270999942, 35.428764922000028],
            [-80.902377269999988, 35.428722922000077],
            [-80.902221269999984, 35.42863992100007],
            [-80.902084269999989, 35.42853892100004],
            [-80.901894270999946, 35.428363922000074],
            [-80.901736269999958, 35.428202922000025],
            [-80.901670269999954, 35.428111922000028],
            [-80.901614269999982, 35.427961922000065],
            [-80.90160427099994, 35.427870922000068],
            [-80.901606270999935, 35.427776922000078],
            [-80.901660269999979, 35.427544921000049],
            [-80.901785270999937, 35.427099921000035],
            [-80.901821269999971, 35.42694292200008],
            [-80.901834269999938, 35.426822921000053],
            [-80.901825269999961, 35.426665922000041],
            [-80.901779270999953, 35.42653092200004],
            [-80.901691269999958, 35.426423922000026],
            [-80.90146926999995, 35.426241921000042],
            [-80.901392269999974, 35.426161922000063],
            [-80.901356269999951, 35.426107922000028],
            [-80.901209270999971, 35.425800922000064],
            [-80.901099270999964, 35.42561892100008],
            [-80.900967269999967, 35.425415921000024],
            [-80.900707269999941, 35.425070921000042],
            [-80.900433269999951, 35.424790922000057],
            [-80.899734269999954, 35.424129922000077],
            [-80.899609269999985, 35.424034921000043],
            [-80.899557268999956, 35.424000921000072],
            [-80.899350268999967, 35.423901921000038],
            [-80.899242268999956, 35.423825921000059],
            [-80.899159268999938, 35.423743921000039],
            [-80.899002268999936, 35.423516921000044],
            [-80.898847268999987, 35.423321921000024],
            [-80.898731268999938, 35.423173921000057],
            [-80.898635268999953, 35.423024921000035],
            [-80.898590268999953, 35.422927921000053],
            [-80.898507269999982, 35.422714921000079],
            [-80.898453268999958, 35.422592920000056],
            [-80.89832526899994, 35.422359921000066],
            [-80.898144268999943, 35.422075921000044],
            [-80.898119267999959, 35.422029921000046],
            [-80.898072268999954, 35.421906921000073],
            [-80.89785526899999, 35.421181921000027],
            [-80.897673268999938, 35.420657921000043],
            [-80.897615268999971, 35.420464921000075],
            [-80.897516267999947, 35.420051920000049],
            [-80.897448268999938, 35.419809920000034],
            [-80.897401267999953, 35.419662921000054],
            [-80.897249267999939, 35.419186920000072],
            [-80.897209268999973, 35.41904892000008],
            [-80.897182267999938, 35.418916921000061],
            [-80.89712426799997, 35.41811492000005],
            [-80.897051267999984, 35.417468920000033],
            [-80.896930267999949, 35.416196919000072],
            [-80.896826267999984, 35.415310920000024],
            [-80.896777267999937, 35.414841919000025],
            [-80.896744267999964, 35.414623920000054],
            [-80.896699267999963, 35.414419919000068],
            [-80.896631268999954, 35.414208919000032],
            [-80.896002266999972, 35.412630919000037],
            [-80.895593267999971, 35.411500918000058],
            [-80.895461267999963, 35.411102919000029],
            [-80.895427267999935, 35.410933918000069],
            [-80.895401267999944, 35.410650919000034],
            [-80.895383267999989, 35.410327918000064],
            [-80.895340267999984, 35.409913919000076],
            [-80.895088266999949, 35.408264918000043],
            [-80.894943266999974, 35.407780918000071],
            [-80.894698266999967, 35.407017918000065],
            [-80.89463726699995, 35.406751918000055],
            [-80.894342266999956, 35.406788918000075],
            [-80.894012266999937, 35.406842918000052],
            [-80.893231266999976, 35.407013918000075],
            [-80.892936265999936, 35.407065918000058],
            [-80.892664266999986, 35.407092918000046],
            [-80.892348265999942, 35.407104918000073],
            [-80.892147266999984, 35.407099918000029],
            [-80.891853265999941, 35.407084918000066],
            [-80.890871265999976, 35.407020918000057],
            [-80.890816265999945, 35.407043918000056],
            [-80.890640265999934, 35.40712191800003],
            [-80.890550265999934, 35.407186918000036],
            [-80.890416264999942, 35.407290918000058],
            [-80.890188265999939, 35.407477918000041],
            [-80.890042265999966, 35.407600918000071],
            [-80.889646264999953, 35.407823919000066],
            [-80.889366265999968, 35.408061919000033],
            [-80.889190264999968, 35.408245918000034],
            [-80.889084265999941, 35.40832591800006],
            [-80.888616264999939, 35.408774918000063],
            [-80.888579265999965, 35.408817918000068],
            [-80.888359264999963, 35.409032918000037],
            [-80.888231265999934, 35.409181918000058],
            [-80.888156264999964, 35.409327919000077],
            [-80.888100264999935, 35.409588918000054],
            [-80.887942264999936, 35.410201919000031],
            [-80.887857265999969, 35.410627919000035],
            [-80.887693265999985, 35.411291919000064],
            [-80.887672264999935, 35.411391920000028],
            [-80.887586264999982, 35.411803920000068],
            [-80.887458264999964, 35.412276919000078],
            [-80.88740026499994, 35.412578919000055],
            [-80.887336264999988, 35.412699919000033],
            [-80.887374264999949, 35.412753920000057],
            [-80.887364265999963, 35.412840919000075],
            [-80.887337263999939, 35.412952920000066],
            [-80.887255265999954, 35.413138919000062],
            [-80.887225264999984, 35.413367919000052],
            [-80.887158265999972, 35.413649920000069],
            [-80.887096265999958, 35.413954919000048],
            [-80.887018265999984, 35.414285920000054],
            [-80.887008264999963, 35.414314920000038],
            [-80.886910264999983, 35.414701920000027],
            [-80.886810264999951, 35.415173920000029],
            [-80.886728264999988, 35.41557892000003],
            [-80.88665726499994, 35.415856921000056],
            [-80.886567265999986, 35.416251921000026],
            [-80.886422264999965, 35.416872921000049],
            [-80.886418263999985, 35.416944920000049],
            [-80.886340263999955, 35.417234920000055],
            [-80.886342264999939, 35.417279920000055],
            [-80.886319265999987, 35.417402921000075],
            [-80.886291264999954, 35.417530921000036],
            [-80.886189264999985, 35.417876920000026],
            [-80.886143264999987, 35.418259920000025],
            [-80.886101264999979, 35.418616920000034],
            [-80.886119264999934, 35.41867392000006],
            [-80.88612326599997, 35.418728921000024],
            [-80.886107263999975, 35.418789920000052],
            [-80.886162265999985, 35.419326921000049],
            [-80.88618026599994, 35.419479920000072],
            [-80.886265264999963, 35.419836921000069],
            [-80.88632626499998, 35.419941920000042],
            [-80.886364264999941, 35.420043921000058],
            [-80.886421264999967, 35.42015992100005],
            [-80.886520265999934, 35.420305921000079],
            [-80.886580264999964, 35.420393920000038],
            [-80.886630265999941, 35.420484920000035],
            [-80.886651265999944, 35.420592921000036],
            [-80.886680265999985, 35.420646921000071],
            [-80.886724265999987, 35.420709921000025],
            [-80.886799264999979, 35.420814922000034],
            [-80.88682526599996, 35.420839921000038],
            [-80.886915264999971, 35.420978921000028],
            [-80.887100265999948, 35.421175921000042],
            [-80.887312265999981, 35.421418922000043],
            [-80.887342264999972, 35.421464921000052],
            [-80.887370265999948, 35.421496921000028],
            [-80.887395264999952, 35.421539921000033],
            [-80.887520265999967, 35.421690921000049],
            [-80.887608265999972, 35.421807922000028],
            [-80.887660265999955, 35.421884922000061],
            [-80.887704265999957, 35.421942921000038],
            [-80.887769265999964, 35.422012921000032],
            [-80.887818266999943, 35.422083921000024],
            [-80.887884265999958, 35.422137922000047],
            [-80.887925264999978, 35.42218992100004],
            [-80.887942265999982, 35.422219921000078],
            [-80.887961264999944, 35.422252921000052],
            [-80.888002265999944, 35.422295922000046],
            [-80.88802626599994, 35.422322921000045],
            [-80.888069265999945, 35.422373922000077],
            [-80.888105265999968, 35.422437921000039],
            [-80.888169266999967, 35.422512920000031],
            [-80.888239265999971, 35.422599922000074],
            [-80.888284265999971, 35.422659922000037],
            [-80.888337265999951, 35.422724922000043],
            [-80.888391264999939, 35.422798921000037],
            [-80.88843426599999, 35.422853922000058],
            [-80.888468265999961, 35.422904921000054],
            [-80.888561265999954, 35.423001921000036],
            [-80.88858926599994, 35.423045921000039],
            [-80.88874326499996, 35.423211922000064],
            [-80.888766266999937, 35.423251921000031],
            [-80.88881026599995, 35.42329992100008],
            [-80.88885926599994, 35.423364922000076],
            [-80.888899265999953, 35.423414921000074],
            [-80.888941265999961, 35.423473921000038],
            [-80.889034265999953, 35.423582922000037],
            [-80.889036265999948, 35.42358592100004],
            [-80.889066265999986, 35.423641922000058],
            [-80.889084265999941, 35.423668921000058],
            [-80.88910726599994, 35.423706921000075],
            [-80.889126266999938, 35.423755922000055],
            [-80.88914026599997, 35.423803921000058],
            [-80.889151265999942, 35.423858921000033],
            [-80.889155266999978, 35.423927922000075],
            [-80.88915226599994, 35.423987922000038],
            [-80.889159266999968, 35.424104922000026],
            [-80.889186266999957, 35.424240922000024],
            [-80.889199265999935, 35.424350921000041],
            [-80.889200266999978, 35.424407921000068],
            [-80.889187266999954, 35.424535922000075],
            [-80.889184266999962, 35.424608922000061],
            [-80.889173265999943, 35.424670922000075],
            [-80.889165265999964, 35.424775921000048],
            [-80.889134265999985, 35.424920922000069],
            [-80.889127265999946, 35.42495892200003],
            [-80.889057266999941, 35.425216922000061],
            [-80.889049266999962, 35.425263922000056],
            [-80.889045266999972, 35.425315922000038],
            [-80.889038265999943, 35.42536692200008],
            [-80.889026265999973, 35.425418921000073],
            [-80.889011265999955, 35.425475922000032],
            [-80.889003265999975, 35.425523921000035],
            [-80.888975265999989, 35.425625922000052],
            [-80.888951266999982, 35.425689922000061],
            [-80.888932265999983, 35.425758922000057],
            [-80.888922266999941, 35.425823922000063],
            [-80.888910266999972, 35.425886922000075],
            [-80.888876265999954, 35.425946922000037],
            [-80.888852265999958, 35.426001922000069],
            [-80.888834266999936, 35.426060922000033],
            [-80.888812265999945, 35.42612192200005],
            [-80.88879426699998, 35.426182922000066],
            [-80.888778265999974, 35.426254922000055],
            [-80.888772266999979, 35.426318922000064],
            [-80.888762265999958, 35.426362922000067],
            [-80.888747265999939, 35.426421922000031],
            [-80.888719266999942, 35.426500922000059],
            [-80.888617266999972, 35.426652922000073],
            [-80.888578265999968, 35.426697922000074],
            [-80.888516265999954, 35.426753922000046],
            [-80.888403265999955, 35.426920922000079],
            [-80.888359265999952, 35.426982922000036],
            [-80.888310265999962, 35.42704492200005],
            [-80.888262266999959, 35.427092923000032],
            [-80.888252265999938, 35.427103922000072],
            [-80.88818626699998, 35.42716792300007],
            [-80.888120266999977, 35.427244922000057],
            [-80.888058265999973, 35.427343922000034],
            [-80.887967265999976, 35.42746892200006],
            [-80.887926265999965, 35.427497923000033],
            [-80.887892265999938, 35.427536922000058],
            [-80.887825264999947, 35.427616922000027],
            [-80.887794266999947, 35.427662922000025],
            [-80.887779265999939, 35.427686922000078],
            [-80.887718266999968, 35.427766923000036],
            [-80.887663265999947, 35.427831923000042],
            [-80.887622266999983, 35.42786992300006],
            [-80.887565266999957, 35.427931923000074],
            [-80.887503266999943, 35.428003922000073],
            [-80.88743326499997, 35.428080923000039],
            [-80.887327265999943, 35.428217922000044],
            [-80.88726126499995, 35.428292922000026],
            [-80.887228265999966, 35.428331922000041],
            [-80.887104265999938, 35.428453922000074],
            [-80.887033265999946, 35.428551922000054],
            [-80.88696026599996, 35.428636923000056],
            [-80.886880265999935, 35.428701923000062],
            [-80.886792265999986, 35.428792922000071],
            [-80.886749265999981, 35.42883392300007],
            [-80.886669265999956, 35.428907923000054],
            [-80.886571265999976, 35.429015922000076],
            [-80.886487265999961, 35.429104923000068],
            [-80.88645026599994, 35.429152923000061],
            [-80.886392265999973, 35.429208922000043],
            [-80.886291265999944, 35.429308923000065],
            [-80.886044265999942, 35.429497923000042],
            [-80.886031265999975, 35.429513924000048],
            [-80.88593926599998, 35.429643923000071],
            [-80.885652265999965, 35.429926923000039],
            [-80.885543265999956, 35.430076923000058],
            [-80.88547426599996, 35.430141923000065],
            [-80.885445264999987, 35.430199923000032],
            [-80.885405265999964, 35.430268923000028],
            [-80.885359264999977, 35.430356922000044],
            [-80.885320264999962, 35.430483923000054],
            [-80.885287264999988, 35.430656923000072],
            [-80.883766263999973, 35.42954392300004],
            [-80.883619264999936, 35.429492923000055],
            [-80.883513263999987, 35.42944192300007],
            [-80.883144264999942, 35.429198923000058],
            [-80.882935263999968, 35.429086922000067],
            [-80.882796263999978, 35.429022923000048],
            [-80.882678263999935, 35.428985924000074],
            [-80.882593264999969, 35.428962923000029],
            [-80.882089263999944, 35.428861923000056],
            [-80.88168826499998, 35.428786923000075],
            [-80.881106263999982, 35.428679922000072],
            [-80.88025126399998, 35.428535923000027],
            [-80.879991263999955, 35.428486923000037],
            [-80.87980926399996, 35.428486923000037],
            [-80.879684263999934, 35.428508923000038],
            [-80.879484262999938, 35.428601923000031],
            [-80.878897262999942, 35.428887923000048],
            [-80.878607263999982, 35.429024924000032],
            [-80.877776262999987, 35.429416923000076],
            [-80.87693126299996, 35.429810923000048],
            [-80.876112262999982, 35.428602923000028],
            [-80.876082262999944, 35.42856792300006],
            [-80.876040262999936, 35.428534924000076],
            [-80.875851261999969, 35.428411923000056],
            [-80.875748262999934, 35.428357923000078],
            [-80.875683262999985, 35.428449923000073],
            [-80.875547261999941, 35.428613924000047],
            [-80.875217262999968, 35.428979923000043],
            [-80.874576262999938, 35.42976892300004],
            [-80.874520261999976, 35.429657923000036],
            [-80.87407626199996, 35.42887892300007],
            [-80.873300261999987, 35.427514923000047],
            [-80.872914261999938, 35.426833923000061],
            [-80.871619260999978, 35.424574922000033],
            [-80.871271260999947, 35.42397492200007],
            [-80.871195260999968, 35.423860922000074],
            [-80.871120260999987, 35.423766922000027],
            [-80.871037260999969, 35.423676923000073],
            [-80.870600261999982, 35.42328092200006],
            [-80.870459260999951, 35.423151923000034],
            [-80.870402260999981, 35.42310092200006],
            [-80.869908259999988, 35.422664922000024],
            [-80.869850260999954, 35.422711922000076],
            [-80.869704260999981, 35.422812922000048],
            [-80.869594260999975, 35.422894923000058],
            [-80.869511259999967, 35.422931922000032],
            [-80.86946525999997, 35.422946922000051],
            [-80.86932225999999, 35.423007922000068],
            [-80.869205259999944, 35.42304792200008],
            [-80.869046260999937, 35.423114922000025],
            [-80.868938259999936, 35.423173923000036],
            [-80.868821259999947, 35.42322792300007],
            [-80.868629260999967, 35.42332992200005],
            [-80.868510260999983, 35.423414922000063],
            [-80.868461258999957, 35.42347592200008],
            [-80.868383259999973, 35.423550922000061],
            [-80.868269259999977, 35.423677922000024],
            [-80.868139260999953, 35.423799923000047],
            [-80.86807325999996, 35.423850922000042],
            [-80.868059259999939, 35.423869923000041],
            [-80.868039259999989, 35.423936922000053],
            [-80.868023260999962, 35.423950923000064],
            [-80.867958259999966, 35.423972923000065],
            [-80.86793425999997, 35.423985922000043],
            [-80.867921259999946, 35.424000922000062],
            [-80.867912259999969, 35.424025923000045],
            [-80.867908260999968, 35.424057922000031],
            [-80.867891260999954, 35.424093922000054],
            [-80.867853259999947, 35.424128923000069],
            [-80.867804259999957, 35.424160923000045],
            [-80.867700259999935, 35.424199922000071],
            [-80.867655259999935, 35.424233922000042],
            [-80.867609259999938, 35.424295922000056],
            [-80.86752125999999, 35.424387922000051],
            [-80.867487259999962, 35.424429922000058],
            [-80.867466258999968, 35.424472922000064],
            [-80.86744325899997, 35.424493923000057],
            [-80.867344259999982, 35.424559923000061],
            [-80.867312259999949, 35.42459492200004],
            [-80.867286260999947, 35.424645923000071],
            [-80.867278259999978, 35.424671922000073],
            [-80.867222259999949, 35.424755922000031],
            [-80.86715425899996, 35.424820923000027],
            [-80.867121259999976, 35.42486092200005],
            [-80.867021259999945, 35.424935923000078],
            [-80.866961258999936, 35.424967923000054],
            [-80.866920258999983, 35.425008923000064],
            [-80.866840259999947, 35.425069922000034],
            [-80.866774259999943, 35.425133922000043],
            [-80.866690259999984, 35.425241923000044],
            [-80.866655259999959, 35.425277923000067],
            [-80.866469259999974, 35.42540792300008],
            [-80.866324259999942, 35.425547923000067],
            [-80.866264258999934, 35.425617923000061],
            [-80.866246259999969, 35.425629923000031],
            [-80.866143259999944, 35.425674923000031],
            [-80.86605925899994, 35.425774923000063],
            [-80.866002259999959, 35.425821923000058],
            [-80.865920259999939, 35.425875923000035],
            [-80.865696258999947, 35.426073923000047],
            [-80.865643258999967, 35.426097923000043],
            [-80.865381258999946, 35.426292923000062],
            [-80.866147259999934, 35.42758492300004],
            [-80.866836259999957, 35.428731924000033],
            [-80.867008259999977, 35.429026923000038],
            [-80.867411259999983, 35.429765924000037],
            [-80.867613260999974, 35.430152924000026],
            [-80.867915260999951, 35.430779924000035],
            [-80.86806925999997, 35.431119924000029],
            [-80.868338260999963, 35.431763924000052],
            [-80.868550260999939, 35.432338924000078],
            [-80.868727260999947, 35.432868923000058],
            [-80.868858260999957, 35.433295925000039],
            [-80.869062260999954, 35.434065925000027],
            [-80.869193260999964, 35.434641925000051],
            [-80.869296260999988, 35.435184925000044],
            [-80.869373260999964, 35.435648925000066],
            [-80.869440260999966, 35.436185924000029],
            [-80.869496260999938, 35.436748925000074],
            [-80.869536260999951, 35.437391926000032],
            [-80.869542260999935, 35.43790292500006],
            [-80.869544260999987, 35.438511925000057],
            [-80.869545261999974, 35.438629926000033],
            [-80.869520260999934, 35.439503926000043],
            [-80.869475260999934, 35.44062692600005],
            [-80.869443260999958, 35.441717926000024],
            [-80.869424261999939, 35.44229292600005],
            [-80.869422261999944, 35.442379927000047],
            [-80.869421261999946, 35.442492926000057],
            [-80.86842526099997, 35.442434926000033],
            [-80.867756260999954, 35.442391926000028],
            [-80.866634259999955, 35.442312926000056],
            [-80.865492260999986, 35.442232926000031],
            [-80.863250259999973, 35.442078926000079],
            [-80.859993258999964, 35.441852926000024],
            [-80.858597258999964, 35.441755927000031],
            [-80.857328257999939, 35.441668926000034],
            [-80.856521257999987, 35.441614926000057],
            [-80.854474256999936, 35.441473927000061],
            [-80.853451256999961, 35.441404926000075],
            [-80.852713256999948, 35.441354926000031],
            [-80.852610255999934, 35.441348927000035],
            [-80.852582256999938, 35.441572926000049],
            [-80.852566255999989, 35.441750927000044],
            [-80.852570255999979, 35.441911927000035],
            [-80.852589255999987, 35.442111927000042],
            [-80.852601256999947, 35.442195927000057],
            [-80.852623255999958, 35.443159927000067],
            [-80.852616255999976, 35.443572927000048],
            [-80.852571256999965, 35.443793927000058],
            [-80.852482256999963, 35.443966927000076],
            [-80.852417255999967, 35.444056928000066],
            [-80.85248325699996, 35.444095927000035],
            [-80.852563256999986, 35.444125927000073],
            [-80.852630256999987, 35.444140927000035],
            [-80.852700255999935, 35.444147927000074],
            [-80.855178256999977, 35.444178928000042],
            [-80.85519725599994, 35.444349927000076],
            [-80.855199257999971, 35.445080927000049],
            [-80.855204256999968, 35.445254927000065],
            [-80.855224257999964, 35.445377927000038],
            [-80.855256256999951, 35.445474927000078],
            [-80.855314256999975, 35.445621928000037],
            [-80.855380256999979, 35.445765927000025],
            [-80.855456257999947, 35.445864927000059],
            [-80.855574256999944, 35.446007928000029],
            [-80.855263257999979, 35.446212927000033],
            [-80.855160256999966, 35.446284928000068],
            [-80.855074257999945, 35.446362928000042],
            [-80.85501925799997, 35.446427927000059],
            [-80.854963256999952, 35.446503927000037],
            [-80.854940257999942, 35.446534928000062],
            [-80.85461825699997, 35.447137928000075],
            [-80.85453625699995, 35.447115928000073],
            [-80.854418256999963, 35.44709492800007],
            [-80.854147256999966, 35.447061928000039],
            [-80.853776256999936, 35.447024928000076],
            [-80.85373225699999, 35.447490928000036],
            [-80.853700256999957, 35.447755928000049],
            [-80.853661256999942, 35.448174928000071],
            [-80.853616256999942, 35.448396928000079],
            [-80.853575257999978, 35.448495928000057],
            [-80.853528256999937, 35.448592928000039],
            [-80.853430256999957, 35.448774929000024],
            [-80.853293256999962, 35.448999928000035],
            [-80.854345257999967, 35.449407928000028],
            [-80.854818257999966, 35.449584928000036],
            [-80.855016257999978, 35.449668928000051],
            [-80.855104257999983, 35.44972492800008],
            [-80.855215257999987, 35.449812928000028],
            [-80.855345257999943, 35.449895928000046],
            [-80.855382257999963, 35.449913928000058],
            [-80.855412256999955, 35.449925928000027],
            [-80.855624257999978, 35.449983928000051],
            [-80.855693256999984, 35.450000929000055],
            [-80.855813257999955, 35.450031928000044],
            [-80.855911257999935, 35.450066929000059],
            [-80.856013257999962, 35.45013792900005],
            [-80.856137256999943, 35.450249928000062],
            [-80.856218257999956, 35.450301929000034],
            [-80.856310256999961, 35.450347928000042],
            [-80.856406257999936, 35.450380928000072],
            [-80.856574257999966, 35.45041892900008],
            [-80.856712258999949, 35.450440928000035],
            [-80.856782257999953, 35.450450929000056],
            [-80.856887258999961, 35.450465928000028],
            [-80.857116257999962, 35.450498928000059],
            [-80.85724025899998, 35.450519928000062],
            [-80.85733825899996, 35.450548929000036],
            [-80.857604257999981, 35.450653928000065],
            [-80.857849257999987, 35.450750929000037],
            [-80.858094258999984, 35.450847929000076],
            [-80.858181257999945, 35.450881928000058],
            [-80.858353258999955, 35.450950928000054],
            [-80.858479258999978, 35.451000929000031],
            [-80.858611257999939, 35.451052928000024],
            [-80.858696258999942, 35.451096929000073],
            [-80.858773258999975, 35.451179929000034],
            [-80.858801258999961, 35.451238928000066],
            [-80.858810257999949, 35.451281928000071],
            [-80.858809258999941, 35.451354929000047],
            [-80.858779258999959, 35.451432929000077],
            [-80.858701258999986, 35.45157492900006],
            [-80.858661258999973, 35.451642929000059],
            [-80.858633257999941, 35.451681929000074],
            [-80.858556258999954, 35.451771928000028],
            [-80.858384258999934, 35.451905929000077],
            [-80.858078258999967, 35.452146928000047],
            [-80.858088258999942, 35.452152929000079],
            [-80.858227258999989, 35.452220928000031],
            [-80.85835525899995, 35.452292929000066],
            [-80.858382258999939, 35.45231792900006],
            [-80.858394259999955, 35.452346929000043],
            [-80.858410258999982, 35.45236292900006],
            [-80.858430258999988, 35.452372929000035],
            [-80.858450258999937, 35.452386929000056],
            [-80.858462257999975, 35.452406929000063],
            [-80.858457258999977, 35.452456928000061],
            [-80.858465258999956, 35.452520929000059],
            [-80.858402257999956, 35.452611929000057],
            [-80.858354258999952, 35.452697929000067],
            [-80.858352258999957, 35.452799929000037],
            [-80.85821625899996, 35.452968929000065],
            [-80.858183258999986, 35.45301592900006],
            [-80.858104257999969, 35.453059929000062],
            [-80.858095258999981, 35.453076929000076],
            [-80.858083257999965, 35.453130928000064],
            [-80.857941257999983, 35.453259929000069],
            [-80.857838258999948, 35.453364929000031],
            [-80.857763258999967, 35.453447929000049],
            [-80.857587258999956, 35.453581929000052],
            [-80.857480257999953, 35.453691929000058],
            [-80.857382257999973, 35.453746930000079],
            [-80.857278258999941, 35.453789929000038],
            [-80.857115257999965, 35.453896929000052],
            [-80.856993257999989, 35.453989928000055],
            [-80.856956258999958, 35.454031929000053],
            [-80.856907257999978, 35.454043930000068],
            [-80.856823257999963, 35.454093929000067],
            [-80.856746257999987, 35.454151929000034],
            [-80.856666258999951, 35.454182930000059],
            [-80.85660325799995, 35.45422092900003],
            [-80.856576257999961, 35.454232930000046],
            [-80.85646825799995, 35.454281929000047],
            [-80.856408256999941, 35.454312929000025],
            [-80.856336257999942, 35.454357929000025],
            [-80.856210257999976, 35.45440392900008],
            [-80.856112257999939, 35.454395930000032],
            [-80.855983257999981, 35.454352930000027],
            [-80.85589725899996, 35.454332929000032],
            [-80.855823257999987, 35.454299929000058],
            [-80.855728257999942, 35.454264929000033],
            [-80.855572257999938, 35.454240930000026],
            [-80.855538257999967, 35.454219930000079],
            [-80.85548825799998, 35.454188929000054],
            [-80.855450256999973, 35.45415992900007],
            [-80.855410257999949, 35.454120929000055],
            [-80.855365257999949, 35.454096930000048],
            [-80.85530425799999, 35.454072929000063],
            [-80.855212257999938, 35.45402092900008],
            [-80.855112257999963, 35.453952929000025],
            [-80.85503125799994, 35.453952929000025],
            [-80.854999257999964, 35.453947930000027],
            [-80.854917257999944, 35.453895929000055],
            [-80.854861257999971, 35.453887930000064],
            [-80.854774256999974, 35.453890930000057],
            [-80.854733257999953, 35.453899930000034],
            [-80.85467125699995, 35.453919929000051],
            [-80.854590257999973, 35.453951929000027],
            [-80.854461257999958, 35.45403292900005],
            [-80.854393257999959, 35.454082929000037],
            [-80.854302256999972, 35.454168930000037],
            [-80.854233257999965, 35.454191930000036],
            [-80.854185256999983, 35.45421292900005],
            [-80.854143256999976, 35.454238930000031],
            [-80.854114256999935, 35.454280930000039],
            [-80.854030256999977, 35.454344929000058],
            [-80.853953256999944, 35.45435193000003],
            [-80.853865257999985, 35.454391930000043],
            [-80.853768256999956, 35.454485930000033],
            [-80.853720257999953, 35.454521929000066],
            [-80.853684256999941, 35.454535929000031],
            [-80.853655257999947, 35.454525929000056],
            [-80.853604256999972, 35.454489930000079],
            [-80.853538257999958, 35.454463929000042],
            [-80.853467256999977, 35.454463930000031],
            [-80.853409256999953, 35.454482929000051],
            [-80.853318256999955, 35.454505930000039],
            [-80.853268256999968, 35.454506930000036],
            [-80.853217256999983, 35.454475930000058],
            [-80.853175256999975, 35.454460929000049],
            [-80.853151256999979, 35.454478929000061],
            [-80.853130256999975, 35.454505929000049],
            [-80.853123256999936, 35.45453193000003],
            [-80.853088257999957, 35.45454692900006],
            [-80.853027257999941, 35.454545930000052],
            [-80.852902256999982, 35.454550930000039],
            [-80.852798256999961, 35.45454692900006],
            [-80.852728256999967, 35.454554929000039],
            [-80.852651256999934, 35.454575929000043],
            [-80.852550256999962, 35.454593929000055],
            [-80.85248725699995, 35.454683929000055],
            [-80.852394256999958, 35.454745929000069],
            [-80.85225825699996, 35.454827930000079],
            [-80.852186256999971, 35.45485793000006],
            [-80.852151257999935, 35.454888930000038],
            [-80.852085256999942, 35.454975929000057],
            [-80.852027256999975, 35.454988929000024],
            [-80.851968255999964, 35.454975929000057],
            [-80.851873256999966, 35.45494393000007],
            [-80.851764256999957, 35.454901930000062],
            [-80.851666256999977, 35.454885929000056],
            [-80.851610255999958, 35.454886929000054],
            [-80.851533255999982, 35.454864930000042],
            [-80.851420256999972, 35.454806930000075],
            [-80.851348256999984, 35.454810929000075],
            [-80.851273256999946, 35.454838930000051],
            [-80.851214256999981, 35.454871929000035],
            [-80.851115256999947, 35.454886929000054],
            [-80.851038256999971, 35.454892929000039],
            [-80.850933256999951, 35.454931929000054],
            [-80.85087725599999, 35.454967929000077],
            [-80.850826256999937, 35.45500892900003],
            [-80.850673255999936, 35.455110930000046],
            [-80.850576255999954, 35.455142929000033],
            [-80.850531255999954, 35.455151930000056],
            [-80.850485255999956, 35.455237929000077],
            [-80.850426255999935, 35.455308930000058],
            [-80.850373255999955, 35.455354929000066],
            [-80.85031425699998, 35.455372930000067],
            [-80.850234255999965, 35.455365930000028],
            [-80.850157255999989, 35.455348930000071],
            [-80.849993255999948, 35.455402930000048],
            [-80.849965256999951, 35.455436930000076],
            [-80.849887255999988, 35.455508930000065],
            [-80.849794255999939, 35.455576929000074],
            [-80.849698254999964, 35.455636930000026],
            [-80.849615256999982, 35.455670929000064],
            [-80.84951525699995, 35.455702930000029],
            [-80.849428255999953, 35.455719930000043],
            [-80.849288255999966, 35.455748930000027],
            [-80.84918425699999, 35.455775930000073],
            [-80.848985255999935, 35.455434930000024],
            [-80.848728255999958, 35.455441930000063],
            [-80.847972255999935, 35.45546193000007],
            [-80.845616255999971, 35.455526930000076],
            [-80.843305254999962, 35.456000930000073],
            [-80.843292253999948, 35.455675930000041],
            [-80.843178254999941, 35.455671930000051],
            [-80.842592253999953, 35.45564193000007],
            [-80.842588253999963, 35.45569092900007],
            [-80.842589253999961, 35.456134930000076],
            [-80.842619253999942, 35.456561930000078],
            [-80.842634253999961, 35.456697930000075],
            [-80.842678253999964, 35.457012930000076],
            [-80.84273525399999, 35.457280931000071],
            [-80.842848253999989, 35.45770493100008],
            [-80.842910253999946, 35.457892930000071],
            [-80.843012253999973, 35.458178931000077],
            [-80.843200254999942, 35.458610931000067],
            [-80.84337725499995, 35.45896993100007],
            [-80.84348225399998, 35.459162930000048],
            [-80.843629255999986, 35.459414930000037],
            [-80.844000254999969, 35.45999893100003],
            [-80.844372255999986, 35.460569931000066],
            [-80.84449525499997, 35.460758931000044],
            [-80.845495254999946, 35.46229393200008],
            [-80.845847255999956, 35.462834931000032],
            [-80.846271254999976, 35.463486931000034],
            [-80.847334255999954, 35.465122933000032],
            [-80.847710255999971, 35.465691932000027],
            [-80.847907255999985, 35.466006932000028],
            [-80.849149256999965, 35.467921932000024],
            [-80.849564255999951, 35.468551933000072],
            [-80.849676257999988, 35.468724932000043],
            [-80.849782256999958, 35.468888932000027],
            [-80.84992725699999, 35.469113933000074],
            [-80.850340256999971, 35.469752933000052],
            [-80.850636256999962, 35.470196933000068],
            [-80.851113257999941, 35.470936933000075],
            [-80.851448257999948, 35.47145293300008],
            [-80.851274257999989, 35.471530933000054],
            [-80.850646256999937, 35.471756933000052],
            [-80.850926256999969, 35.472168933000034],
            [-80.850540256999977, 35.472359933000064],
            [-80.851369257999977, 35.473427933000039],
            [-80.852327257999946, 35.473215934000052],
            [-80.852429256999983, 35.473199933000046],
            [-80.852565256999981, 35.47316793400006],
            [-80.852819257999954, 35.473559934000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 76,
        "SolutionID": "PI-29:D",
        "Shape_Length": 0.30026092396181592,
        "Shape_Area": 0.001586272549385112
      }
    },
    {
      "type": "Feature",
      "id": 77,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.798966244999974, 35.51901394500004],
            [-80.798873244999982, 35.519433946000049],
            [-80.798840243999962, 35.519757946000027],
            [-80.798706244999948, 35.520554946000061],
            [-80.799703244999989, 35.520463946000064],
            [-80.801175244999968, 35.520336945000054],
            [-80.80181924599998, 35.520294945000046],
            [-80.805995245999952, 35.520067945000051],
            [-80.807027245999961, 35.519986945000028],
            [-80.80709124699996, 35.519975945000056],
            [-80.807421246999979, 35.519920945000024],
            [-80.807763247999958, 35.519737945000031],
            [-80.808489246999955, 35.519147945000043],
            [-80.808547247999968, 35.519100945000048],
            [-80.80885524699994, 35.518818945000078],
            [-80.809165247999942, 35.518623945000058],
            [-80.809460247999937, 35.518486944000074],
            [-80.809869247999984, 35.518408945000033],
            [-80.810281248999956, 35.51842894400005],
            [-80.810767247999934, 35.518520944000045],
            [-80.811667248999981, 35.518763945000046],
            [-80.814003248999938, 35.519420945000036],
            [-80.814495249999936, 35.519500944000072],
            [-80.814795248999985, 35.519507945000043],
            [-80.815172249999989, 35.519436944000063],
            [-80.815685249999945, 35.519295945000067],
            [-80.816783249999958, 35.518993944000044],
            [-80.817023249999977, 35.51892794500003],
            [-80.81785924999997, 35.518686944000024],
            [-80.818481249999934, 35.518507944000078],
            [-80.818760250999958, 35.518427944000052],
            [-80.819602250999935, 35.518216944000073],
            [-80.821766250999985, 35.517663944000049],
            [-80.822549252999977, 35.517477944000063],
            [-80.824548251999943, 35.517015944000036],
            [-80.825735251999959, 35.516741944000046],
            [-80.825831251999944, 35.516719944000045],
            [-80.826136252999959, 35.51663594300004],
            [-80.82636125199997, 35.516606944000046],
            [-80.826530251999941, 35.516599944000063],
            [-80.826908252999942, 35.516584944000044],
            [-80.827313252999943, 35.516644944000063],
            [-80.827880252999989, 35.516794944000026],
            [-80.828545252999959, 35.516955944000074],
            [-80.829136253999934, 35.517071944000065],
            [-80.82962925399994, 35.517136943000025],
            [-80.829984253999953, 35.517164944000058],
            [-80.831104253999968, 35.517209943000069],
            [-80.833114253999952, 35.517290943000035],
            [-80.833574253999984, 35.517308944000035],
            [-80.83406325499999, 35.517326944000047],
            [-80.834395254999947, 35.51733794300003],
            [-80.836026255999968, 35.517396943000051],
            [-80.836074254999971, 35.517398943000046],
            [-80.836785255999985, 35.517424944000027],
            [-80.837034256999971, 35.517380944000024],
            [-80.83729025599996, 35.517304944000045],
            [-80.837545256999988, 35.517168944000048],
            [-80.837822255999981, 35.516953943000033],
            [-80.838061256999936, 35.516449944000044],
            [-80.838135255999987, 35.516318943000044],
            [-80.838254255999971, 35.516110943000058],
            [-80.838415255999962, 35.515976943000055],
            [-80.838628255999936, 35.515874943000028],
            [-80.838996255999973, 35.515769942000077],
            [-80.839510255999983, 35.515642943000046],
            [-80.838939255999946, 35.51412494300007],
            [-80.838588256999969, 35.513191943000038],
            [-80.838450255999987, 35.512697942000045],
            [-80.838407254999936, 35.512475942000037],
            [-80.838368255999967, 35.512195942000062],
            [-80.838377255999944, 35.511765942000068],
            [-80.838417255999957, 35.511541942000065],
            [-80.83845325599998, 35.511340942000061],
            [-80.838595256999952, 35.510926942000026],
            [-80.838801255999954, 35.51053094100007],
            [-80.839047255999958, 35.510139941000034],
            [-80.839295255999957, 35.509867941000039],
            [-80.839820256999985, 35.509906941000054],
            [-80.840497255999935, 35.509956942000031],
            [-80.841372256999989, 35.51002094100005],
            [-80.845313257999976, 35.510307942000054],
            [-80.845850258999974, 35.510346942000069],
            [-80.852927260999934, 35.510861942000076],
            [-80.855593261999957, 35.511055941000052],
            [-80.855796261999956, 35.511070941000071],
            [-80.859166261999974, 35.511315941000078],
            [-80.860223261999977, 35.511392941000054],
            [-80.861301261999984, 35.511481941000056],
            [-80.861958263999952, 35.51153594200008],
            [-80.862105262999989, 35.51154794100006],
            [-80.865199263999955, 35.511773941000058],
            [-80.865529263999974, 35.511796941000057],
            [-80.865653263999945, 35.511805942000024],
            [-80.865885263999985, 35.511822941000048],
            [-80.866040263999935, 35.511834941000075],
            [-80.874385267999969, 35.512475940000058],
            [-80.874799266999958, 35.512507941000024],
            [-80.890049270999953, 35.513657941000076],
            [-80.890675271999953, 35.513703941000074],
            [-80.906065276999982, 35.514832941000066],
            [-80.909828276999974, 35.515109940000059],
            [-80.91006927799998, 35.514932940000051],
            [-80.910322277999967, 35.514745940000068],
            [-80.91821127999998, 35.508934939000028],
            [-80.930149281999945, 35.500141937000024],
            [-80.936342284999967, 35.49557993600007],
            [-80.940500284999985, 35.492517935000023],
            [-80.947033286999954, 35.487705933000029],
            [-80.946855286999948, 35.487238932000025],
            [-80.946727286999987, 35.486855933000072],
            [-80.946686286999977, 35.486730933000047],
            [-80.946508286999972, 35.486263932000043],
            [-80.946316286999945, 35.485984933000054],
            [-80.946191286999976, 35.485802933000059],
            [-80.945935286999941, 35.485385933000032],
            [-80.94582328599995, 35.48521093200003],
            [-80.94581128599998, 35.485192932000075],
            [-80.94580328699999, 35.485180932000048],
            [-80.945789286999968, 35.485158933000037],
            [-80.945658286999958, 35.484955933000037],
            [-80.945618285999956, 35.484891932000039],
            [-80.945589285999972, 35.484846932000039],
            [-80.945401286999981, 35.484558933000073],
            [-80.945362285999977, 35.484499932000062],
            [-80.945208286999957, 35.484193933000029],
            [-80.945204285999978, 35.484186932000057],
            [-80.945199285999934, 35.484175932000028],
            [-80.945184285999972, 35.484143932000052],
            [-80.945192286999941, 35.484077932000048],
            [-80.945214285999953, 35.483908932000077],
            [-80.945237285999951, 35.48383593300008],
            [-80.945368286999951, 35.483419931000071],
            [-80.945402286999979, 35.483381932000043],
            [-80.945769286999962, 35.482967932000065],
            [-80.946210285999939, 35.482554933000074],
            [-80.946340285999952, 35.482407932000058],
            [-80.946407285999953, 35.482331931000033],
            [-80.946582285999966, 35.482134931000076],
            [-80.94691428699997, 35.481738932000042],
            [-80.947296286999972, 35.481407931000035],
            [-80.947639286999959, 35.480955932000029],
            [-80.947787287999972, 35.480790931000058],
            [-80.947864286999959, 35.480704932000037],
            [-80.947880287999965, 35.48066693100003],
            [-80.947884287999955, 35.480657931000053],
            [-80.948068286999955, 35.480228931000056],
            [-80.948146286999986, 35.48005093200004],
            [-80.948204286999953, 35.479800932000046],
            [-80.948222286999965, 35.479139932000066],
            [-80.94825028799994, 35.478720931000055],
            [-80.948357286999965, 35.478333931000066],
            [-80.948434285999951, 35.478123931000027],
            [-80.948551286999987, 35.477727931000061],
            [-80.948648286999969, 35.477268931000026],
            [-80.948793286999944, 35.476630930000056],
            [-80.949075286999971, 35.475936930000046],
            [-80.949289286999942, 35.475299931000052],
            [-80.949582287999988, 35.474726930000031],
            [-80.949874286999943, 35.474080930000071],
            [-80.950109286999975, 35.473780930000032],
            [-80.950285287999975, 35.473555930000032],
            [-80.950493286999972, 35.473096930000054],
            [-80.95065528799995, 35.472740930000043],
            [-80.950918287999968, 35.472078930000066],
            [-80.951179286999945, 35.471523929000057],
            [-80.951211287999968, 35.471456930000045],
            [-80.95142528699995, 35.470899929000041],
            [-80.951629287999936, 35.470222929000045],
            [-80.951735286999963, 35.46961692900004],
            [-80.951733286999968, 35.469044928000073],
            [-80.951750286999982, 35.46845692900007],
            [-80.951670287999946, 35.468020929000033],
            [-80.951600286999962, 35.467617929000028],
            [-80.951500286999988, 35.467045928000061],
            [-80.95133128699996, 35.466537929000026],
            [-80.951313286999948, 35.466435928000067],
            [-80.951303286999973, 35.466380929000024],
            [-80.951251286999934, 35.466094928000075],
            [-80.951024287999985, 35.465700929000036],
            [-80.950726286999952, 35.465391929000077],
            [-80.950521286999958, 35.46503192800003],
            [-80.950176286999977, 35.464750928000058],
            [-80.949861286999976, 35.464452928000071],
            [-80.949762285999952, 35.464375928000038],
            [-80.949660286999972, 35.46429592800007],
            [-80.949520286999984, 35.464186928000061],
            [-80.949398285999962, 35.464091927000027],
            [-80.94905328699997, 35.463849928000059],
            [-80.948600285999987, 35.463472928000044],
            [-80.948148285999935, 35.463175927000066],
            [-80.948098285999947, 35.463142928000025],
            [-80.947537284999953, 35.462740928000073],
            [-80.947327285999961, 35.462593928000047],
            [-80.947320285999979, 35.46258892700007],
            [-80.946917284999984, 35.462306927000043],
            [-80.946596284999941, 35.462091927000074],
            [-80.946590285999946, 35.462087927000027],
            [-80.946585284999969, 35.462084927000035],
            [-80.946561284999973, 35.462068927000075],
            [-80.946551284999941, 35.462061928000026],
            [-80.946541285999956, 35.462054927000054],
            [-80.946224284999971, 35.461842927000077],
            [-80.946149285999979, 35.461792927000033],
            [-80.94572528499998, 35.461373928000057],
            [-80.945418285999949, 35.461127927000064],
            [-80.945184284999982, 35.460939928000073],
            [-80.944780284999979, 35.460642927000038],
            [-80.944396284999982, 35.460352927000031],
            [-80.944012284999985, 35.460144928000034],
            [-80.943746283999985, 35.459870927000054],
            [-80.943608284999982, 35.459753927000065],
            [-80.943598283999961, 35.459744927000031],
            [-80.943591284999968, 35.459738927000046],
            [-80.943539283999939, 35.459694928000033],
            [-80.943523284999969, 35.459680927000079],
            [-80.943515284999989, 35.459674927000037],
            [-80.943490284999939, 35.459653927000033],
            [-80.943466283999953, 35.45962492700005],
            [-80.94345928499996, 35.45961692700007],
            [-80.943450284999983, 35.459606927000038],
            [-80.943254283999977, 35.459379927000043],
            [-80.943036284999948, 35.45905792800005],
            [-80.943010284999957, 35.458990927000059],
            [-80.942868283999985, 35.458630926000069],
            [-80.942719284999953, 35.458211927000036],
            [-80.942598284999974, 35.457959927000047],
            [-80.942492283999968, 35.457734927000047],
            [-80.942490283999973, 35.457715927000038],
            [-80.942488283999978, 35.457693926000047],
            [-80.942488283999978, 35.457687927000052],
            [-80.942487283999981, 35.457675927000025],
            [-80.942420284999969, 35.456826927000066],
            [-80.942335283999967, 35.456739926000068],
            [-80.942332283999974, 35.456736927000065],
            [-80.942302283999936, 35.45670592700003],
            [-80.942300283999941, 35.456672927000056],
            [-80.942299283999944, 35.456598927000073],
            [-80.942291282999975, 35.456317926000054],
            [-80.942337282999972, 35.45556892500008],
            [-80.942400283999973, 35.455331926000042],
            [-80.94247328299997, 35.455050926000069],
            [-80.94251028299999, 35.454389926000033],
            [-80.942616283999939, 35.45383492600007],
            [-80.94274328399996, 35.453423925000038],
            [-80.943016283999953, 35.453003925000075],
            [-80.943350283999962, 35.45263092600004],
            [-80.94376028399995, 35.452267926000047],
            [-80.944191284999988, 35.451888925000048],
            [-80.944661283999949, 35.45162892500008],
            [-80.944954284999937, 35.451265926000076],
            [-80.945228283999938, 35.450885926000069],
            [-80.945237283999973, 35.450871925000058],
            [-80.945240284999954, 35.450866925000071],
            [-80.945246284999939, 35.450857925000037],
            [-80.945391283999982, 35.450636925000026],
            [-80.945595284999968, 35.450325925000072],
            [-80.94559828499996, 35.450320926000074],
            [-80.945619283999974, 35.450288925000052],
            [-80.94563128499999, 35.450273925000033],
            [-80.94563928499997, 35.450263925000058],
            [-80.945647283999961, 35.450254925000024],
            [-80.945654283999943, 35.450246925000044],
            [-80.94588328399999, 35.449973924000062],
            [-80.945906284999978, 35.449954925000043],
            [-80.946177284999976, 35.44973092500004],
            [-80.946412283999962, 35.449560926000061],
            [-80.946598284999936, 35.449455925000052],
            [-80.947269284999948, 35.449270925000064],
            [-80.947275284999989, 35.449268925000069],
            [-80.947297284999934, 35.449262925000028],
            [-80.947334284999954, 35.449252925000053],
            [-80.947380285999941, 35.449236925000037],
            [-80.947893284999964, 35.449057924000044],
            [-80.948451285999965, 35.44875892500005],
            [-80.949040284999967, 35.448498925000024],
            [-80.949568284999941, 35.448181925000029],
            [-80.949647285999959, 35.448134925000033],
            [-80.950186286999951, 35.447665925000024],
            [-80.950586284999986, 35.447108924000077],
            [-80.950977285999954, 35.44646292300007],
            [-80.951396286999966, 35.445743924000055],
            [-80.95169928699994, 35.44528392400008],
            [-80.951953285999934, 35.444774924000058],
            [-80.952216286999942, 35.444266923000043],
            [-80.952509285999952, 35.443652923000059],
            [-80.952713285999948, 35.443152923000071],
            [-80.95282928599994, 35.442692923000038],
            [-80.952892285999951, 35.442423923000035],
            [-80.952926285999979, 35.442281923000053],
            [-80.952991286999975, 35.441677923000043],
            [-80.952992286999972, 35.441664922000029],
            [-80.95299328699997, 35.441659923000032],
            [-80.953002285999958, 35.441579923000063],
            [-80.953070285999956, 35.44128192200003],
            [-80.95321028699999, 35.441080922000026],
            [-80.953441285999986, 35.44074792300006],
            [-80.953793286999939, 35.440247922000026],
            [-80.954458287999955, 35.439552923000065],
            [-80.955221285999983, 35.438889922000044],
            [-80.956033287999958, 35.438081922000038],
            [-80.956658287999971, 35.437079922000066],
            [-80.956951287999971, 35.436474922000059],
            [-80.957084287999976, 35.436209922000046],
            [-80.957272286999967, 35.435836922000078],
            [-80.957409286999962, 35.435633922000079],
            [-80.957641286999944, 35.435292921000041],
            [-80.95775828699999, 35.435015921000058],
            [-80.957875286999979, 35.434738922000065],
            [-80.957994286999963, 35.434456922000038],
            [-80.958306286999971, 35.433802922000041],
            [-80.958471287999942, 35.433318920000033],
            [-80.958694287999947, 35.432640921000029],
            [-80.958702287999984, 35.432140921000041],
            [-80.958684286999983, 35.432013921000078],
            [-80.958639286999983, 35.431703920000075],
            [-80.958493287999943, 35.431270920000031],
            [-80.958177286999955, 35.430625920000068],
            [-80.957732286999942, 35.429933921000043],
            [-80.95724828699997, 35.429128920000039],
            [-80.957071286999962, 35.428785920000053],
            [-80.956985285999963, 35.428616919000035],
            [-80.956962286999953, 35.428572921000068],
            [-80.956942285999958, 35.428532920000066],
            [-80.95672428599994, 35.427839919000064],
            [-80.95667128599996, 35.427685919000055],
            [-80.956659286999979, 35.427651920000073],
            [-80.956653286999938, 35.42763492000006],
            [-80.956615286999977, 35.427525920000051],
            [-80.956612286999984, 35.427516920000073],
            [-80.956561285999953, 35.427376920000029],
            [-80.956559286999948, 35.427371919000052],
            [-80.956551285999979, 35.427349919000051],
            [-80.956549285999984, 35.427342920000058],
            [-80.956537285999957, 35.427310919000035],
            [-80.956529286999967, 35.427289920000078],
            [-80.956426286999942, 35.427009920000046],
            [-80.956287286999952, 35.426574920000064],
            [-80.956000285999949, 35.425776919000043],
            [-80.955850286999976, 35.425180920000059],
            [-80.955702285999962, 35.424440919000062],
            [-80.955551286999935, 35.423713919000079],
            [-80.955534285999988, 35.423625919000074],
            [-80.95552928699999, 35.423599919000026],
            [-80.955527285999949, 35.423588919000053],
            [-80.955525286999944, 35.423576919000027],
            [-80.955524285999957, 35.423570919000042],
            [-80.955522285999962, 35.423564919000057],
            [-80.955520285999967, 35.423553919000028],
            [-80.955472286999964, 35.423313919000066],
            [-80.955400285999985, 35.422900919000028],
            [-80.955399285999988, 35.422892919000049],
            [-80.955397284999947, 35.422881919000076],
            [-80.955351285999939, 35.422617918000071],
            [-80.955317286999957, 35.42234791900006],
            [-80.955315286999962, 35.422331919000044],
            [-80.955314285999975, 35.42232191800008],
            [-80.955313286999967, 35.422311919000038],
            [-80.95531228599998, 35.422301919000063],
            [-80.955311286999972, 35.422296919000075],
            [-80.95527928599995, 35.422052918000077],
            [-80.955278286999942, 35.422045918000038],
            [-80.955269285999975, 35.421971919000043],
            [-80.955211286999941, 35.421453919000044],
            [-80.955176285999983, 35.420577919000038],
            [-80.955273285999965, 35.420053919000054],
            [-80.955344284999967, 35.419803918000071],
            [-80.955428285999972, 35.419512918000066],
            [-80.955642285999943, 35.418923918000075],
            [-80.955993285999966, 35.418213918000049],
            [-80.956480285999987, 35.417373917000077],
            [-80.957086286999981, 35.416469917000029],
            [-80.957788286999971, 35.415209917000027],
            [-80.958383285999957, 35.41416891700004],
            [-80.958587285999954, 35.413635918000068],
            [-80.958772285999942, 35.413215917000059],
            [-80.958765286999949, 35.412120916000049],
            [-80.958726286999934, 35.411345916000073],
            [-80.95860728599996, 35.410781916000076],
            [-80.958496285999956, 35.410208916000045],
            [-80.958326285999988, 35.40946791600004],
            [-80.95816528499995, 35.408558916000061],
            [-80.957878285999982, 35.407662916000049],
            [-80.957559285999935, 35.406227916000034],
            [-80.95723028499998, 35.404873915000053],
            [-80.956804284999976, 35.403658915000051],
            [-80.95625128599994, 35.402619915000059],
            [-80.95575628499995, 35.401628914000071],
            [-80.955061284999942, 35.401648914000077],
            [-80.954810284999951, 35.401687914000036],
            [-80.954631283999959, 35.401585915000055],
            [-80.954474284999947, 35.401423914000077],
            [-80.954254283999944, 35.401288915000066],
            [-80.954171284999973, 35.401191914000037],
            [-80.954175284999963, 35.40108491400008],
            [-80.953332283999941, 35.401135915000054],
            [-80.953303284999947, 35.401172914000028],
            [-80.953420283999947, 35.40137491400003],
            [-80.953537283999935, 35.40155091400004],
            [-80.953533283999946, 35.401728914000046],
            [-80.953440284999942, 35.401861914000051],
            [-80.953305284999942, 35.401942915000063],
            [-80.953161283999975, 35.401938914000027],
            [-80.953045283999984, 35.401828914000077],
            [-80.95293828299998, 35.401704914000049],
            [-80.952800283999977, 35.401667914000029],
            [-80.952519283999948, 35.401672915000063],
            [-80.952114283999947, 35.401533914000026],
            [-80.951774282999963, 35.40140791500005],
            [-80.951604283999984, 35.401390915000036],
            [-80.951535282999942, 35.401302914000041],
            [-80.951111282999989, 35.401339914000062],
            [-80.950734283999964, 35.40141191500004],
            [-80.95126828399998, 35.402225914000041],
            [-80.951484283999946, 35.402547914000024],
            [-80.951823283999943, 35.403055914000049],
            [-80.952023283999949, 35.403670915000077],
            [-80.952001284999938, 35.404212915000073],
            [-80.953089283999986, 35.404194915000062],
            [-80.953223284999979, 35.403543914000068],
            [-80.953445284999987, 35.403199915000073],
            [-80.95375628499994, 35.403218915000025],
            [-80.953979283999956, 35.404156915000044],
            [-80.95464528499997, 35.405494915000077],
            [-80.955096284999968, 35.405954915000052],
            [-80.955489285999988, 35.40634491600008],
            [-80.95571128499995, 35.407086916000026],
            [-80.955578284999945, 35.407285915000045],
            [-80.955113284999982, 35.407120915000064],
            [-80.954602284999964, 35.406795915000032],
            [-80.954224284999952, 35.406777916000067],
            [-80.95442428399997, 35.407464916000038],
            [-80.954670284999963, 35.40829691600004],
            [-80.954603284999962, 35.409055916000057],
            [-80.954137283999955, 35.409073916000068],
            [-80.954096283999945, 35.409073916000068],
            [-80.954075284999988, 35.408987916000058],
            [-80.954071284999941, 35.408831916000054],
            [-80.954159284999946, 35.408540916000049],
            [-80.954232283999943, 35.408352917000059],
            [-80.954298284999936, 35.408131916000059],
            [-80.954300285999977, 35.408058916000073],
            [-80.954263284999968, 35.407939916000032],
            [-80.953938284999936, 35.407603916000028],
            [-80.953830284999981, 35.407411916000058],
            [-80.953706284999953, 35.407190915000058],
            [-80.953637283999967, 35.407075915000064],
            [-80.953499283999975, 35.406945916000041],
            [-80.953355284999986, 35.406916915000068],
            [-80.953213283999958, 35.40694991600003],
            [-80.953102283999954, 35.40705491600005],
            [-80.953049284999963, 35.407180916000073],
            [-80.953032284999949, 35.407280916000047],
            [-80.953016283999943, 35.407475916000067],
            [-80.952931283999987, 35.407672916000024],
            [-80.952679284999988, 35.40797791600005],
            [-80.952303283999981, 35.408361916000047],
            [-80.95194328499997, 35.408709915000031],
            [-80.951690282999948, 35.408963916000062],
            [-80.951630284999965, 35.409073916000068],
            [-80.951588284999957, 35.409153917000026],
            [-80.951576283999941, 35.409233916000062],
            [-80.951563283999974, 35.409430916000076],
            [-80.951548283999955, 35.40960291600004],
            [-80.951367283999957, 35.40958691600008],
            [-80.951186283999959, 35.409626916000036],
            [-80.950949282999943, 35.409640917000047],
            [-80.950620282999978, 35.409695916000032],
            [-80.950526283999977, 35.409751917000051],
            [-80.950292282999953, 35.409867917000042],
            [-80.950093282999944, 35.409965916000033],
            [-80.94993728299994, 35.410004916000048],
            [-80.949838282999963, 35.410025916000052],
            [-80.94964028399994, 35.410045916000058],
            [-80.949409282999966, 35.410087917000055],
            [-80.949298282999962, 35.410145917000079],
            [-80.949184282999965, 35.41023991600008],
            [-80.949113283999964, 35.410320917000035],
            [-80.949032282999951, 35.410526916000038],
            [-80.94895728299997, 35.410718916000064],
            [-80.948874283999942, 35.41075791600008],
            [-80.948686283999962, 35.410771917000034],
            [-80.948426282999947, 35.410767917000044],
            [-80.948324282999977, 35.410766917000046],
            [-80.94816528399997, 35.410737917000063],
            [-80.948081282999965, 35.41068991700007],
            [-80.94793628299999, 35.410666917000071],
            [-80.947740282999973, 35.410658916000045],
            [-80.947741282999971, 35.410670917000061],
            [-80.947734282999988, 35.410679917000039],
            [-80.947697281999979, 35.410726917000034],
            [-80.947668283999974, 35.410743916000058],
            [-80.947617282999943, 35.410774916000037],
            [-80.947533282999984, 35.410784917000058],
            [-80.947502282999949, 35.410779916000024],
            [-80.947414282999944, 35.410765917000049],
            [-80.947335282999973, 35.41075091700003],
            [-80.947311281999987, 35.410749917000032],
            [-80.946794282999974, 35.41157191700006],
            [-80.946705282999972, 35.411685917000057],
            [-80.946683281999981, 35.411708918000045],
            [-80.946662281999977, 35.411725918000059],
            [-80.946566282999981, 35.411797917000058],
            [-80.946410281999988, 35.41189891700003],
            [-80.945927281999957, 35.412185917000045],
            [-80.945454281999957, 35.412505918000079],
            [-80.945140281999954, 35.412728917000038],
            [-80.944968281999934, 35.412858917000051],
            [-80.94484128199997, 35.412966918000052],
            [-80.944735282999943, 35.413063918000034],
            [-80.944623281999952, 35.413179917000036],
            [-80.944546282999966, 35.413276917000076],
            [-80.944230281999978, 35.413761917000045],
            [-80.943943281999964, 35.414229918000046],
            [-80.943735281999977, 35.41452891800003],
            [-80.943666281999981, 35.414601917000027],
            [-80.943517281999959, 35.414722918000052],
            [-80.943431281999949, 35.414782918000071],
            [-80.942884281999966, 35.41510691700006],
            [-80.942619281999953, 35.415263918000051],
            [-80.94207628099997, 35.415528918000064],
            [-80.941616280999938, 35.415762918000041],
            [-80.94152128099995, 35.415806918000044],
            [-80.941355280999971, 35.415870917000063],
            [-80.941167280999935, 35.415926917000036],
            [-80.940585280999983, 35.416075918000047],
            [-80.940221281999982, 35.416149918000031],
            [-80.940136281999969, 35.416162918000055],
            [-80.939187280999988, 35.416298918000052],
            [-80.939048280999941, 35.416316918000064],
            [-80.937081279999973, 35.416578919000074],
            [-80.936860279999962, 35.416589918000057],
            [-80.93671427999999, 35.416585918000067],
            [-80.935620279999966, 35.41647991800005],
            [-80.935536279999951, 35.416459918000044],
            [-80.935464279999962, 35.416425918000073],
            [-80.935304279999968, 35.416317918000061],
            [-80.935120278999989, 35.416178918000071],
            [-80.934111279999968, 35.415419918000055],
            [-80.934045277999985, 35.415374918000055],
            [-80.933935278999968, 35.41532391800007],
            [-80.93388927899997, 35.415307918000053],
            [-80.933783279999943, 35.415283918000057],
            [-80.933682279999971, 35.415283918000057],
            [-80.933581278999952, 35.415300918000071],
            [-80.933449278999944, 35.415332918000047],
            [-80.933249278999938, 35.415365918000077],
            [-80.93306427999994, 35.415376918000049],
            [-80.932980278999935, 35.41537691700006],
            [-80.932811278999964, 35.415365918000077],
            [-80.932718278999971, 35.415351918000056],
            [-80.932623278999984, 35.415326918000062],
            [-80.932509278999987, 35.415286918000049],
            [-80.932406277999974, 35.415236918000062],
            [-80.932340278999959, 35.415194918000054],
            [-80.932258278999939, 35.415133918000038],
            [-80.932191278999937, 35.415073918000076],
            [-80.932046278999962, 35.41490391800005],
            [-80.931721277999941, 35.414463918000024],
            [-80.931645277999962, 35.414370918000031],
            [-80.931524278999973, 35.414248918000055],
            [-80.931305278999957, 35.414073918000042],
            [-80.930977277999943, 35.413839917000075],
            [-80.930727277999949, 35.413680917000079],
            [-80.930622277999987, 35.41360591800003],
            [-80.930576276999943, 35.413562917000036],
            [-80.930547277999949, 35.413527918000057],
            [-80.930511277999983, 35.413469918000033],
            [-80.930448277999972, 35.413320918000068],
            [-80.930342277999955, 35.41306591700004],
            [-80.930173277999984, 35.412732917000028],
            [-80.930091277999963, 35.412610918000041],
            [-80.930016278999972, 35.412520918000041],
            [-80.92992027799994, 35.412425918000054],
            [-80.929861277999976, 35.412374917000079],
            [-80.929675276999944, 35.41223691700003],
            [-80.929076277999968, 35.411832918000073],
            [-80.928851277999968, 35.411685918000046],
            [-80.928431276999959, 35.411426918000075],
            [-80.928348276999941, 35.411384918000067],
            [-80.927693276999946, 35.411085917000037],
            [-80.926041276999968, 35.410382917000049],
            [-80.925822276999952, 35.410306917000071],
            [-80.926424275999977, 35.408859917000029],
            [-80.926496275999966, 35.408634917000029],
            [-80.926520275999962, 35.408518916000048],
            [-80.926535275999981, 35.40841591700007],
            [-80.926541276999956, 35.408320917000026],
            [-80.926540276999958, 35.408219916000064],
            [-80.926523275999955, 35.408022917000039],
            [-80.926491276999968, 35.407850916000029],
            [-80.926451276999956, 35.407700916000067],
            [-80.926364276999948, 35.407439917000033],
            [-80.92463027499997, 35.402347917000043],
            [-80.92421727499999, 35.401157916000045],
            [-80.923466274999953, 35.398954915000047],
            [-80.923314275999985, 35.398530915000038],
            [-80.923168274999966, 35.398098914000059],
            [-80.923146274999965, 35.398009915000046],
            [-80.923105274999955, 35.397767915000031],
            [-80.923093274999985, 35.397559914000055],
            [-80.923101274999965, 35.397335915000042],
            [-80.923137274999988, 35.397060915000054],
            [-80.923173274999954, 35.396922915000061],
            [-80.92323027499998, 35.396767915000055],
            [-80.923505274999968, 35.396129914000028],
            [-80.923627275999934, 35.39585891400003],
            [-80.923777274999964, 35.395498914000029],
            [-80.923840274999975, 35.395319914000027],
            [-80.923873274999949, 35.39519791400005],
            [-80.923885274999975, 35.395113914000035],
            [-80.923889274999965, 35.39493591400003],
            [-80.92387927599998, 35.39478891400006],
            [-80.92384627499996, 35.394645913000033],
            [-80.923791274999985, 35.394474914000057],
            [-80.923729274999971, 35.394328915000074],
            [-80.923571273999983, 35.394009914000037],
            [-80.922897274999968, 35.392777914000078],
            [-80.922807274999968, 35.39261291400004],
            [-80.922661274999939, 35.39237191400008],
            [-80.922584273999973, 35.392267913000069],
            [-80.922412274999942, 35.392079914000078],
            [-80.921881273999986, 35.391559914000027],
            [-80.921401274999937, 35.391080913000053],
            [-80.921291273999941, 35.390955914000074],
            [-80.921181273999935, 35.39080791300006],
            [-80.921090274999983, 35.390655913000046],
            [-80.921007273999976, 35.390485914000067],
            [-80.920961273999978, 35.390360913000052],
            [-80.920926273999953, 35.39020791300004],
            [-80.920904272999962, 35.39002391300005],
            [-80.920915273999981, 35.389792913000065],
            [-80.920932274999984, 35.389668913000037],
            [-80.920944273999964, 35.389589914000055],
            [-80.92111427399999, 35.389629913000078],
            [-80.925391274999981, 35.390065912000068],
            [-80.925484274999974, 35.390069913000048],
            [-80.925806274999957, 35.390052913000034],
            [-80.925879274999943, 35.390044912000064],
            [-80.926000275999968, 35.39003191300003],
            [-80.926070274999972, 35.390013913000075],
            [-80.926130274999934, 35.390002913000046],
            [-80.926183274999971, 35.389987913000027],
            [-80.926249274999975, 35.389959913000041],
            [-80.926406275999966, 35.389881913000067],
            [-80.926568275999955, 35.389822913000046],
            [-80.926658275999955, 35.389783914000077],
            [-80.926838275999955, 35.389678913000068],
            [-80.926961275999986, 35.389591913000061],
            [-80.927069275999941, 35.389528913000049],
            [-80.927517275999946, 35.389208913000061],
            [-80.927688275999969, 35.389107912000043],
            [-80.927879275999942, 35.388962912000068],
            [-80.928083275999938, 35.388833912000052],
            [-80.928172275999941, 35.388788913000042],
            [-80.928295275999972, 35.388740913000049],
            [-80.928624275999937, 35.388560912000059],
            [-80.928884275999962, 35.388436913000078],
            [-80.929199275999963, 35.388188913000079],
            [-80.929568275999941, 35.387924913000063],
            [-80.929799276999972, 35.387828912000032],
            [-80.930117276999965, 35.387651912000024],
            [-80.930139276999967, 35.38762691200003],
            [-80.930392276999953, 35.387456913000051],
            [-80.930523276999963, 35.387432912000065],
            [-80.930647276999935, 35.387427913000067],
            [-80.93076027699999, 35.387441912000043],
            [-80.93092227599999, 35.387488912000038],
            [-80.931076276999988, 35.387567912000065],
            [-80.931127276999973, 35.387600912000039],
            [-80.931246276999957, 35.387679912000067],
            [-80.931393275999937, 35.387717912000028],
            [-80.931476276999945, 35.387746912000068],
            [-80.931571276999989, 35.387763912000025],
            [-80.931807276999962, 35.387756913000032],
            [-80.931999276999989, 35.387679912000067],
            [-80.932065277999982, 35.387636912000062],
            [-80.932132276999937, 35.387591912000062],
            [-80.932228276999979, 35.387540913000066],
            [-80.932346275999976, 35.387462912000046],
            [-80.932446275999951, 35.387390912000058],
            [-80.932649276999939, 35.387242912000033],
            [-80.932722276999982, 35.387179912000079],
            [-80.93277227699997, 35.387080912000044],
            [-80.932801276999953, 35.386997912000027],
            [-80.93281327699998, 35.38688391200003],
            [-80.932778276999954, 35.386763912000049],
            [-80.932759276999946, 35.386720913000033],
            [-80.932723277999969, 35.38668791300006],
            [-80.932663276999961, 35.386625913000046],
            [-80.932319277999966, 35.386352913000053],
            [-80.932271276999984, 35.386305912000068],
            [-80.932257276999962, 35.386268912000048],
            [-80.93222127699994, 35.386171912000066],
            [-80.932180276999986, 35.385913912000035],
            [-80.932189275999974, 35.385812912000063],
            [-80.932114276999982, 35.385621912000033],
            [-80.932067276999987, 35.385376912000027],
            [-80.93202927699997, 35.385030911000058],
            [-80.93202527699998, 35.384964912000044],
            [-80.932053276999966, 35.384911912000064],
            [-80.932244276999938, 35.384724911000035],
            [-80.932369275999974, 35.384613912000077],
            [-80.932443276999948, 35.384535911000057],
            [-80.932686276999959, 35.384137912000028],
            [-80.932842276999963, 35.383935912000027],
            [-80.933126276999985, 35.38353991200006],
            [-80.933245276999969, 35.383350912000026],
            [-80.933340276999957, 35.383212912000033],
            [-80.933614276999947, 35.382824912000046],
            [-80.93368727699999, 35.382719911000038],
            [-80.933829276999973, 35.382529912000052],
            [-80.933991276999961, 35.382435911000073],
            [-80.934130276999952, 35.382361911000032],
            [-80.934264277999944, 35.382316911000032],
            [-80.934416276999968, 35.382289911000044],
            [-80.934607277999987, 35.382204911000031],
            [-80.934726276999982, 35.382128911000052],
            [-80.934781277999946, 35.382096911000076],
            [-80.93483527799998, 35.382081911000057],
            [-80.934991277999984, 35.382045912000024],
            [-80.935100276999947, 35.382086911000044],
            [-80.935214276999943, 35.382185911000079],
            [-80.935404277999965, 35.382288911000046],
            [-80.935737278999966, 35.382460911000067],
            [-80.935915277999982, 35.382568911000078],
            [-80.936102277999964, 35.382666912000047],
            [-80.936164277999978, 35.382684911000069],
            [-80.936223277999943, 35.382678911000028],
            [-80.936339277999934, 35.382657911000024],
            [-80.936467277999952, 35.382588911000028],
            [-80.936576277999961, 35.382504911000069],
            [-80.936660277999977, 35.382340911000028],
            [-80.936801278999951, 35.382102911000061],
            [-80.936905278999973, 35.382033911000065],
            [-80.937056278999989, 35.381968912000048],
            [-80.937196277999988, 35.381933910000043],
            [-80.93761227799996, 35.381811911000057],
            [-80.938031277999983, 35.38166091100004],
            [-80.938509278999959, 35.381518911000057],
            [-80.938735278999957, 35.381442910000032],
            [-80.938899277999951, 35.381411911000043],
            [-80.939040278999983, 35.381428910000068],
            [-80.939463278999938, 35.381445910000025],
            [-80.93960427899998, 35.381443911000076],
            [-80.939961278999988, 35.381432911000047],
            [-80.940065278999953, 35.38137891100007],
            [-80.940124278999974, 35.381281911000031],
            [-80.94014827899997, 35.381045911000058],
            [-80.940109278999955, 35.380574910000064],
            [-80.940104279999957, 35.38051291000005],
            [-80.94000127999999, 35.380543911000075],
            [-80.939974278999955, 35.380431910000027],
            [-80.939928278999957, 35.380176910000046],
            [-80.939916278999988, 35.380086910000045],
            [-80.939918278999983, 35.379947910000055],
            [-80.93992327999996, 35.379890910000029],
            [-80.940014278999968, 35.379838911000036],
            [-80.940108278999958, 35.379786910000064],
            [-80.940192278999973, 35.379733910000027],
            [-80.940281278999976, 35.379683911000029],
            [-80.940358279999941, 35.379648910000071],
            [-80.940447278999955, 35.379619910000031],
            [-80.940655278999941, 35.379506910000032],
            [-80.940746278999939, 35.379453910000052],
            [-80.940861278999989, 35.379404910000062],
            [-80.940912278999974, 35.379387910000048],
            [-80.940996278999989, 35.379343910000046],
            [-80.941059278999944, 35.379280910000034],
            [-80.941119279999953, 35.379197911000063],
            [-80.941213278999953, 35.379037910000079],
            [-80.941222278999987, 35.378958910000051],
            [-80.941221279999979, 35.378894910000042],
            [-80.941178279999974, 35.378843910000057],
            [-80.941117279999958, 35.378790909000031],
            [-80.941035278999948, 35.378731909000066],
            [-80.940967278999949, 35.37867490900004],
            [-80.940889278999975, 35.378624910000042],
            [-80.940824279999958, 35.378569910000067],
            [-80.940812278999942, 35.378522910000072],
            [-80.940812278999942, 35.378474910000079],
            [-80.940806279999947, 35.378421909000053],
            [-80.940772278999987, 35.378358910000031],
            [-80.940763279999942, 35.37828791000004],
            [-80.940760279999949, 35.378208910000069],
            [-80.940744278999944, 35.378084910000041],
            [-80.940702279999982, 35.378044910000028],
            [-80.940768279999986, 35.378016910000042],
            [-80.940793278999934, 35.377998910000031],
            [-80.940828277999969, 35.377967910000052],
            [-80.94084627899997, 35.377920910000057],
            [-80.940896278999958, 35.377873910000062],
            [-80.94094027999995, 35.377853911000045],
            [-80.94106727999997, 35.377799910000078],
            [-80.941116278999971, 35.37776991000004],
            [-80.941202278999981, 35.377754910000078],
            [-80.941306278999946, 35.377753910000024],
            [-80.941445279999982, 35.377749910000034],
            [-80.941701279999961, 35.377735910000069],
            [-80.941821279999942, 35.37773990900007],
            [-80.941897279999978, 35.377727910000033],
            [-80.941943279999975, 35.377667910000071],
            [-80.942024279999941, 35.377628910000055],
            [-80.94209227999994, 35.377636911000025],
            [-80.942232278999938, 35.37770290900005],
            [-80.942303279999976, 35.377742910000052],
            [-80.942353278999974, 35.377747910000039],
            [-80.942457278999939, 35.377752910000027],
            [-80.942481279999981, 35.377760910000063],
            [-80.942559278999965, 35.377794910000034],
            [-80.942635279999934, 35.377807910000058],
            [-80.94270727899999, 35.377808910000056],
            [-80.942864279999981, 35.377799910000078],
            [-80.942974279999987, 35.377804910000066],
            [-80.943049279999968, 35.377784910000059],
            [-80.943159279999975, 35.37781891000003],
            [-80.943227278999984, 35.377849910000066],
            [-80.943301279999957, 35.377828910000062],
            [-80.94339027999996, 35.377810910000051],
            [-80.943487279999943, 35.377820910000025],
            [-80.943672280999976, 35.377854909000064],
            [-80.943774279999957, 35.377859910000041],
            [-80.943807279999987, 35.377856909000059],
            [-80.943882280999958, 35.377846910000073],
            [-80.943914279999944, 35.377831910000054],
            [-80.94395327999996, 35.377808910000056],
            [-80.943972279999969, 35.377798909000035],
            [-80.94405127999994, 35.377746910000042],
            [-80.94413327999996, 35.377714910000066],
            [-80.94418227999995, 35.377676910000048],
            [-80.944229279999945, 35.377544909000051],
            [-80.944195279999974, 35.377481910000029],
            [-80.944131279999965, 35.377389910000034],
            [-80.944145279999987, 35.377301910000028],
            [-80.944203279999954, 35.377205909000054],
            [-80.944271279999953, 35.377145910000024],
            [-80.944308279999973, 35.377096910000034],
            [-80.944332279999969, 35.37703091000003],
            [-80.94435428099996, 35.376990910000075],
            [-80.944390280999983, 35.376949910000064],
            [-80.944465279999974, 35.376917910000031],
            [-80.94454927999999, 35.376875910000024],
            [-80.944607279999957, 35.376822909000055],
            [-80.944671278999976, 35.376795910000055],
            [-80.944769278999956, 35.376772909000067],
            [-80.944859280999935, 35.376729909000062],
            [-80.944955279999988, 35.376671909000038],
            [-80.945024280999974, 35.376624910000032],
            [-80.945077280999953, 35.376565910000068],
            [-80.945123279999962, 35.37649490900003],
            [-80.945198279999943, 35.376414909000061],
            [-80.945261280999944, 35.376356909000037],
            [-80.945312280999985, 35.37629490900008],
            [-80.945323279999968, 35.376276909000069],
            [-80.945351280999944, 35.376207910000062],
            [-80.945382280999979, 35.376132910000024],
            [-80.945387280999967, 35.376047909000079],
            [-80.945379279999941, 35.375981910000064],
            [-80.945395280999946, 35.375909909000029],
            [-80.945447280999986, 35.375786910000045],
            [-80.945462280999948, 35.375733909000076],
            [-80.945459280999955, 35.375681909000036],
            [-80.945468280999989, 35.375610910000034],
            [-80.945507280999948, 35.375504910000075],
            [-80.94543927999996, 35.375431909000042],
            [-80.94538627999998, 35.375397909000071],
            [-80.945339279999985, 35.375359909000053],
            [-80.945300280999959, 35.375262909000071],
            [-80.945316279999986, 35.375199910000049],
            [-80.945471279999936, 35.375091909000048],
            [-80.945491279999942, 35.37503791000006],
            [-80.945510279999951, 35.374838909000061],
            [-80.945508279999956, 35.374770910000052],
            [-80.94539427999996, 35.374721909000073],
            [-80.94534127999998, 35.374740909000025],
            [-80.945276279999973, 35.374751910000043],
            [-80.945219279999947, 35.374756909000041],
            [-80.945067279999989, 35.374750909000056],
            [-80.944997279999939, 35.374708910000038],
            [-80.944980280999971, 35.374660909000056],
            [-80.944973279999942, 35.374576909000041],
            [-80.944978280999976, 35.374413909000054],
            [-80.944975279999937, 35.374299909000058],
            [-80.944987279999964, 35.374210909000055],
            [-80.945079279999959, 35.373952910000071],
            [-80.945104279999953, 35.373847909000062],
            [-80.945117280999966, 35.373771909000027],
            [-80.945109280999986, 35.373686909000071],
            [-80.945090279999988, 35.373614909000025],
            [-80.944964279999965, 35.373459909000076],
            [-80.944849279999971, 35.373384909000038],
            [-80.944792279999945, 35.373363909000034],
            [-80.944680280999989, 35.373352908000072],
            [-80.944604279999965, 35.373359909000044],
            [-80.944526279999934, 35.373358909000046],
            [-80.944430279999949, 35.373343909000027],
            [-80.944294279999951, 35.373304909000069],
            [-80.944116280999935, 35.373201908000055],
            [-80.944013279999979, 35.373189909000075],
            [-80.94385127999999, 35.373225909000041],
            [-80.943738279999934, 35.373241909000058],
            [-80.94372027999998, 35.373174908000067],
            [-80.943767279999975, 35.373071909000032],
            [-80.943780279999942, 35.373016909000057],
            [-80.943797279999956, 35.372863908000056],
            [-80.943799279999951, 35.372796909000044],
            [-80.943791279999971, 35.372740909000072],
            [-80.943758278999951, 35.372676909000063],
            [-80.943714279999938, 35.37261490800006],
            [-80.943682279999962, 35.372549909000043],
            [-80.943538279999984, 35.372390909000046],
            [-80.943459279999956, 35.372315908000076],
            [-80.943286279999938, 35.372217909000028],
            [-80.943168278999963, 35.372219908000034],
            [-80.943057279999948, 35.372151909000024],
            [-80.943019279999987, 35.372112908000076],
            [-80.943008278999969, 35.372053908000055],
            [-80.943049279999968, 35.372011908000047],
            [-80.943159278999985, 35.37194290900004],
            [-80.94325127999997, 35.371857909000028],
            [-80.943279279999956, 35.371813908000036],
            [-80.943293279999978, 35.371756908000066],
            [-80.943298278999976, 35.371673908000048],
            [-80.94325527899997, 35.371611908000034],
            [-80.94316927999995, 35.371584908000045],
            [-80.943058278999956, 35.371584908000045],
            [-80.942953279999983, 35.371608909000031],
            [-80.942867278999984, 35.371655908000037],
            [-80.942821278999986, 35.371725909000077],
            [-80.942761279999957, 35.371789908000039],
            [-80.942654279999942, 35.371855908000043],
            [-80.942523279999989, 35.371964908000052],
            [-80.94247927899994, 35.37200690800006],
            [-80.942440279999971, 35.372076909000043],
            [-80.942440278999982, 35.37213390900007],
            [-80.942420278999975, 35.372203909000064],
            [-80.94237327999997, 35.372238908000043],
            [-80.942272278999951, 35.372266909000075],
            [-80.942181279999943, 35.372247909000066],
            [-80.942061278999972, 35.372127909000028],
            [-80.941877279999972, 35.371971908000035],
            [-80.941659279999953, 35.371805909000045],
            [-80.941564278999977, 35.371749909000073],
            [-80.941437278999956, 35.371703909000075],
            [-80.94127827799997, 35.371636909000074],
            [-80.941177278999987, 35.371630909000032],
            [-80.941081278999945, 35.371647909000046],
            [-80.940978278999978, 35.371629909000035],
            [-80.940897277999966, 35.371579908000058],
            [-80.940735277999977, 35.371531909000055],
            [-80.940656277999949, 35.371538908000048],
            [-80.940511279999953, 35.371617909000065],
            [-80.940451277999955, 35.371629909000035],
            [-80.940369278999981, 35.371636908000028],
            [-80.940260278999972, 35.37163590800003],
            [-80.940199278999955, 35.371648909000044],
            [-80.940121278999982, 35.371667908000063],
            [-80.939962278999985, 35.371702908000032],
            [-80.939903278999964, 35.371696909000036],
            [-80.939831278999975, 35.371701908000034],
            [-80.939763277999987, 35.371679908000033],
            [-80.939651278999975, 35.371589909000079],
            [-80.939616278999949, 35.371542909000027],
            [-80.939543277999974, 35.371501908000027],
            [-80.939460277999956, 35.37148890900005],
            [-80.939376277999941, 35.371497908000038],
            [-80.939276278999955, 35.37152990900006],
            [-80.939167277999957, 35.371547909000071],
            [-80.939065278999976, 35.371540908000043],
            [-80.938912278999965, 35.37149690800004],
            [-80.938803277999966, 35.37149690800004],
            [-80.938707277999981, 35.37152590900007],
            [-80.938655277999942, 35.37154590800003],
            [-80.938575278999963, 35.371525908000024],
            [-80.938473277999947, 35.371452909000027],
            [-80.93835927799995, 35.371273908000035],
            [-80.938278277999984, 35.371230909000076],
            [-80.938193277999972, 35.371168908000072],
            [-80.938150277999966, 35.371129908000057],
            [-80.938105277999966, 35.371099909000066],
            [-80.938019277999956, 35.371066909000035],
            [-80.937891276999949, 35.371028908000028],
            [-80.937711276999948, 35.370956909000029],
            [-80.937690276999945, 35.370898908000072],
            [-80.937791276999974, 35.370828909000068],
            [-80.93789327799999, 35.370795908000048],
            [-80.938211278999972, 35.370713909000074],
            [-80.93835927799995, 35.370711909000079],
            [-80.939408277999974, 35.370045908000066],
            [-80.939447277999989, 35.369959908000055],
            [-80.939463277999948, 35.36990890900006],
            [-80.939451278999968, 35.369833909000079],
            [-80.939415277999956, 35.369770909000067],
            [-80.939375277999943, 35.369709908000061],
            [-80.939089278999973, 35.369506908000062],
            [-80.938970277999942, 35.369385908000027],
            [-80.938924277999945, 35.369313907000048],
            [-80.938851277999959, 35.369117908000078],
            [-80.938844277999976, 35.369062908000046],
            [-80.938778277999972, 35.368987908000065],
            [-80.939062277999938, 35.368288908000068],
            [-80.939097277999963, 35.368247908000058],
            [-80.939160277999974, 35.36819390800008],
            [-80.939266277999934, 35.368137908000051],
            [-80.939393277999955, 35.368089908000059],
            [-80.939472277999982, 35.368035908000024],
            [-80.939497277999976, 35.367974908000065],
            [-80.939491277999934, 35.367910907000066],
            [-80.939411277999966, 35.36783790700008],
            [-80.939286278999987, 35.367772907000074],
            [-80.938902276999954, 35.367616908000059],
            [-80.938721277999946, 35.367535908000036],
            [-80.938401277999958, 35.367365907000078],
            [-80.938334277999957, 35.36731590800008],
            [-80.938249277999944, 35.367170908000048],
            [-80.938083277999965, 35.367020908000029],
            [-80.937960277999935, 35.366937907000079],
            [-80.937657276999971, 35.366777907000028],
            [-80.937550277999947, 35.366740907000064],
            [-80.937482276999958, 35.366731908000077],
            [-80.937428276999981, 35.366714908000063],
            [-80.937223276999987, 35.366612907000047],
            [-80.936862276999989, 35.366450908000047],
            [-80.936703277999982, 35.36640890700005],
            [-80.936567276999938, 35.366360908000047],
            [-80.936523276999935, 35.366327908000073],
            [-80.936449277999941, 35.366297907000046],
            [-80.93637027699998, 35.366290907000064],
            [-80.936187276999988, 35.36622090700007],
            [-80.936107277999952, 35.366179908000049],
            [-80.936005277999982, 35.366127908000067],
            [-80.935854277999965, 35.366085908000059],
            [-80.935776277999935, 35.366058907000024],
            [-80.935448276999978, 35.36593190800005],
            [-80.935318276999965, 35.365886907000061],
            [-80.935251276999963, 35.365858908000064],
            [-80.935063275999937, 35.365765907000025],
            [-80.935004275999972, 35.365729907000059],
            [-80.934888275999981, 35.365683908000051],
            [-80.934726276999982, 35.365591908000056],
            [-80.934676275999948, 35.365550907000056],
            [-80.934568275999936, 35.365480907000062],
            [-80.934379275999959, 35.365401907000034],
            [-80.934314276999942, 35.365378907000036],
            [-80.934180276999939, 35.365315907000024],
            [-80.934106275999966, 35.36528690800003],
            [-80.934057276999965, 35.365260907000049],
            [-80.933999276999941, 35.36524590700003],
            [-80.933901276999961, 35.365231907000066],
            [-80.933843276999937, 35.365212907000057],
            [-80.933689276999985, 35.365133908000075],
            [-80.933456275999959, 35.364997907000031],
            [-80.933346275999952, 35.364923908000037],
            [-80.933155275999979, 35.364827907000063],
            [-80.933036275999939, 35.364789907000045],
            [-80.932864275999975, 35.364755907000074],
            [-80.932736274999968, 35.36474290700005],
            [-80.93254727499999, 35.364733907000073],
            [-80.932445275999953, 35.36473490700007],
            [-80.932345274999989, 35.364742908000039],
            [-80.932143275999977, 35.364768907000041],
            [-80.932050276999973, 35.364795908000076],
            [-80.932097275999979, 35.364854907000051],
            [-80.932231275999982, 35.364961908000055],
            [-80.93228927499996, 35.365002907000076],
            [-80.932314275999943, 35.365067907000025],
            [-80.932348275999971, 35.365137908000065],
            [-80.932384274999947, 35.365177908000078],
            [-80.93246127599997, 35.365210907000062],
            [-80.932537275999948, 35.36524890700008],
            [-80.93264127599997, 35.365331907000041],
            [-80.932699275999937, 35.36539990700004],
            [-80.93278827599994, 35.36552290700007],
            [-80.932814275999988, 35.365685907000056],
            [-80.932801275999964, 35.365733907000049],
            [-80.93277627599997, 35.365799908000042],
            [-80.932762276999938, 35.365852908000079],
            [-80.932752276999963, 35.365924908000068],
            [-80.93269427599995, 35.366073908000033],
            [-80.932630275999941, 35.366150908000066],
            [-80.932584275999943, 35.366229908000037],
            [-80.932580274999964, 35.366283908000071],
            [-80.932583275999946, 35.366379908000056],
            [-80.932584275999943, 35.366443908000065],
            [-80.93260027599996, 35.366499908000037],
            [-80.932625275999953, 35.36654390700005],
            [-80.932653276999986, 35.366623908000065],
            [-80.932673276999935, 35.366720907000058],
            [-80.932667275999961, 35.366794908000031],
            [-80.932627276999938, 35.367005908000067],
            [-80.93258827599999, 35.367104908000044],
            [-80.93253627699994, 35.367154908000032],
            [-80.93250627599997, 35.367223908000028],
            [-80.932440275999966, 35.367416908000052],
            [-80.932302275999973, 35.367851908000034],
            [-80.932299275999981, 35.367974908000065],
            [-80.932327275999967, 35.368057908000026],
            [-80.932387275999986, 35.368182908000051],
            [-80.932496276999984, 35.368497908000052],
            [-80.932561275999944, 35.36864790900006],
            [-80.93265327599994, 35.368916908000074],
            [-80.932707276999963, 35.369054909000056],
            [-80.932738275999952, 35.369158908000031],
            [-80.932747275999986, 35.369310909000035],
            [-80.932778275999965, 35.369376908000049],
            [-80.932849275999956, 35.369496908000031],
            [-80.932885276999968, 35.369577908000053],
            [-80.932921275999945, 35.369625908000046],
            [-80.93305027599996, 35.369752908000066],
            [-80.933142275999955, 35.369765908000034],
            [-80.933238276999987, 35.36974490800003],
            [-80.933354276999978, 35.369739909000032],
            [-80.933423275999985, 35.369771909000065],
            [-80.933501276999948, 35.369910908000065],
            [-80.933502275999956, 35.369981908000057],
            [-80.933523276999949, 35.37005190900004],
            [-80.93351927599997, 35.370108909000066],
            [-80.933479275999957, 35.370160909000049],
            [-80.933429276999959, 35.370209908000049],
            [-80.933408275999966, 35.370271908000063],
            [-80.933377276999977, 35.370319909000045],
            [-80.933364276999953, 35.370364909000045],
            [-80.93336527699995, 35.370421909000072],
            [-80.933318276999955, 35.370488908000027],
            [-80.933233275999953, 35.370503908000046],
            [-80.933120276999944, 35.37056990800005],
            [-80.933063276999974, 35.370556908000026],
            [-80.932935275999967, 35.370592909000038],
            [-80.932855275999941, 35.370626909000066],
            [-80.932744275999937, 35.370718909000061],
            [-80.932677275999936, 35.370813909000049],
            [-80.932601275999957, 35.370900909000056],
            [-80.932541275999938, 35.370976908000046],
            [-80.932440276999955, 35.371055909000063],
            [-80.932469275999949, 35.370924909000053],
            [-80.932550276999962, 35.370687908000036],
            [-80.932576276999953, 35.370510908000028],
            [-80.932607275999942, 35.370438909000029],
            [-80.932558275999952, 35.370398909000073],
            [-80.932439276999958, 35.370365909000043],
            [-80.932277275999979, 35.370279908000043],
            [-80.932223276999935, 35.370240908000028],
            [-80.932148276999953, 35.370220909000068],
            [-80.932065276999936, 35.370203909000054],
            [-80.93200027599994, 35.370149909000077],
            [-80.931847275999985, 35.370103909000079],
            [-80.931793275999951, 35.370036908000031],
            [-80.931708275999938, 35.369951909000065],
            [-80.931663275999938, 35.369917909000037],
            [-80.93157227599994, 35.369882909000069],
            [-80.931423275999975, 35.369794909000063],
            [-80.931389274999958, 35.369748908000076],
            [-80.931370274999949, 35.369669908000049],
            [-80.931354275999979, 35.369566908000024],
            [-80.93125727599994, 35.369499909000069],
            [-80.931232276999935, 35.369420908000052],
            [-80.931240274999936, 35.369293908000031],
            [-80.93111827499996, 35.369231909000064],
            [-80.930790275999982, 35.369038909000039],
            [-80.930710274999967, 35.369002908000027],
            [-80.93060427599994, 35.368994908000047],
            [-80.930430275999981, 35.369007908000071],
            [-80.930369275999965, 35.369019908000041],
            [-80.930297274999987, 35.369010909000053],
            [-80.930222274999949, 35.368994909000037],
            [-80.930027274999986, 35.368893908000075],
            [-80.929922274999967, 35.36886690800003],
            [-80.929683275999935, 35.368886908000036],
            [-80.929602274999979, 35.368921908000061],
            [-80.92949327599996, 35.368959909000068],
            [-80.929373274999989, 35.368991909000044],
            [-80.929312274999972, 35.369021908000036],
            [-80.929236275999983, 35.369052908000072],
            [-80.929160274999958, 35.369068908000031],
            [-80.928963275999934, 35.369085909000034],
            [-80.928887274999965, 35.36908790800004],
            [-80.928865274999964, 35.369106908000049],
            [-80.928495274999989, 35.369219908000048],
            [-80.928338274999987, 35.369334908000042],
            [-80.928194274999953, 35.369379908000042],
            [-80.928081274999954, 35.369435908000071],
            [-80.92788427499994, 35.369508908000057],
            [-80.92773727499997, 35.369576909000045],
            [-80.927644275999967, 35.36963490800008],
            [-80.927495273999966, 35.369737909000037],
            [-80.927431274999947, 35.369793909000066],
            [-80.927173274999973, 35.369903908000026],
            [-80.927077274999988, 35.369922908000035],
            [-80.927004273999955, 35.370049909000045],
            [-80.92691627499994, 35.37009690800005],
            [-80.926853274999985, 35.370030909000036],
            [-80.926819273999968, 35.369983909000041],
            [-80.926747274999968, 35.370000908000065],
            [-80.926692273999947, 35.370046909000052],
            [-80.92669527399994, 35.370126909000078],
            [-80.926714273999949, 35.370179909000058],
            [-80.926763274999985, 35.370279909000033],
            [-80.926788273999989, 35.370359909000058],
            [-80.92674027399994, 35.370449909000058],
            [-80.926671273999943, 35.370473909000054],
            [-80.926647274999937, 35.370477909000044],
            [-80.926550274999954, 35.370426909000059],
            [-80.926472274999981, 35.370343910000031],
            [-80.926367273999972, 35.370246909000059],
            [-80.926316274999976, 35.37021990900007],
            [-80.92625527499996, 35.370207910000033],
            [-80.925777273999984, 35.370203909000054],
            [-80.925436274999981, 35.370209909000039],
            [-80.92534427399994, 35.370196908000025],
            [-80.925216273999979, 35.370198909000067],
            [-80.925071273999947, 35.370224909000058],
            [-80.924815273999968, 35.370299909000039],
            [-80.924345273999961, 35.370425908000072],
            [-80.924012273999949, 35.370534909000071],
            [-80.923922273999949, 35.370560909000062],
            [-80.923860273999935, 35.370535909000068],
            [-80.923882272999947, 35.370464908000031],
            [-80.923837273999936, 35.370388909000042],
            [-80.923806272999968, 35.37032190900004],
            [-80.923930272999939, 35.370262909000076],
            [-80.923987272999966, 35.370245909000062],
            [-80.924134273999982, 35.370220909000068],
            [-80.924201273999984, 35.370188909000035],
            [-80.924404273999983, 35.370027909000044],
            [-80.924647273999938, 35.369866908000063],
            [-80.924890273999949, 35.369698909000078],
            [-80.925125274999971, 35.369640909000054],
            [-80.925207272999955, 35.369631909000077],
            [-80.925412273999939, 35.369635909000067],
            [-80.925507273999983, 35.369619908000061],
            [-80.925604273999966, 35.369592908000072],
            [-80.925687273999984, 35.369588908000026],
            [-80.925786273999961, 35.369590909000067],
            [-80.925853273999962, 35.369575909000048],
            [-80.925979274999975, 35.369557909000036],
            [-80.926052273999971, 35.369560909000029],
            [-80.92612027499996, 35.369557908000047],
            [-80.926316274999976, 35.369527909000055],
            [-80.926514273999942, 35.369452908000028],
            [-80.926733274999947, 35.369343909000065],
            [-80.926893273999951, 35.369256908000068],
            [-80.927057274999981, 35.369149909000043],
            [-80.927157273999967, 35.369100908000064],
            [-80.927280274999987, 35.369047908000027],
            [-80.927416274999985, 35.369008908000069],
            [-80.927581274999966, 35.36895290800004],
            [-80.927781273999983, 35.368897908000065],
            [-80.928074274999972, 35.368773908000037],
            [-80.928148275999945, 35.368706909000025],
            [-80.92822827599997, 35.368601908000073],
            [-80.928331274999948, 35.368525909000027],
            [-80.928407274999984, 35.368459908000034],
            [-80.928467274999946, 35.368419908000078],
            [-80.928576275999944, 35.36838990800004],
            [-80.928628274999937, 35.368351908000079],
            [-80.928682274999971, 35.368305909000071],
            [-80.928748274999975, 35.368211908000035],
            [-80.928781273999959, 35.368151908000073],
            [-80.928833275999978, 35.368087908000064],
            [-80.928985274999945, 35.368060909000064],
            [-80.929063275999965, 35.368039908000071],
            [-80.929118274999951, 35.368011908000028],
            [-80.92917027499999, 35.367978909000044],
            [-80.929218274999982, 35.367914908000046],
            [-80.92920027599996, 35.367817908000063],
            [-80.929252274999953, 35.367774908000058],
            [-80.929308274999983, 35.367823908000048],
            [-80.92945227499996, 35.367925909000064],
            [-80.929523274999951, 35.367958909000038],
            [-80.929621274999988, 35.367991908000079],
            [-80.929714274999981, 35.368000908000056],
            [-80.929794274999949, 35.36800290900004],
            [-80.929875274999972, 35.368032908000032],
            [-80.929961275999972, 35.368073908000042],
            [-80.930049275999977, 35.368051908000041],
            [-80.93013127599994, 35.368006908000041],
            [-80.93027727599997, 35.367992908000076],
            [-80.930403275999936, 35.367989908000027],
            [-80.930492274999949, 35.36798090800005],
            [-80.930583275999936, 35.36798090800005],
            [-80.930673275999936, 35.367996908000066],
            [-80.930749275999972, 35.367981909000036],
            [-80.931031275999942, 35.367817908000063],
            [-80.93108127499994, 35.367793908000067],
            [-80.931132275999971, 35.367762908000032],
            [-80.931188275999943, 35.367711908000047],
            [-80.931261275999987, 35.367627909000078],
            [-80.931287275999978, 35.367565908000074],
            [-80.93124527599997, 35.367469908000032],
            [-80.931231275999949, 35.36742190700005],
            [-80.931226275999961, 35.36737290800005],
            [-80.93124127599998, 35.367279908000057],
            [-80.931226275999961, 35.36718490800007],
            [-80.931200274999981, 35.367107908000037],
            [-80.931145274999949, 35.366975908000029],
            [-80.93107727499995, 35.366838908000034],
            [-80.930994275999979, 35.366640907000033],
            [-80.930938275999949, 35.36654790800003],
            [-80.930895275999944, 35.366488908000065],
            [-80.930803275999949, 35.366395908000072],
            [-80.930711275999954, 35.36635590800006],
            [-80.930654274999938, 35.366340908000041],
            [-80.930595275999963, 35.366332908000061],
            [-80.930445275999944, 35.366341908000038],
            [-80.930366275999972, 35.366326908000076],
            [-80.930329275999952, 35.366242908000061],
            [-80.930284274999963, 35.36611590800004],
            [-80.930224274999944, 35.365810908000071],
            [-80.930195275999949, 35.365503908000051],
            [-80.930175275999943, 35.365444908000029],
            [-80.930167274999974, 35.365394907000052],
            [-80.930146275999959, 35.36533890700008],
            [-80.930045274999941, 35.365141907000066],
            [-80.93003727599995, 35.36505790700005],
            [-80.930092275999982, 35.364973907000035],
            [-80.930145274999973, 35.36491490700007],
            [-80.930304274999969, 35.364826908000055],
            [-80.93038327499994, 35.364805908000051],
            [-80.930423274999953, 35.364765908000038],
            [-80.930517274999943, 35.364709908000066],
            [-80.930384274999938, 35.364551907000077],
            [-80.930051274999983, 35.364476907000039],
            [-80.931238275999988, 35.363070908000054],
            [-80.930594274999976, 35.36306490700008],
            [-80.929739274999974, 35.362980907000065],
            [-80.928982274999953, 35.362898907000044],
            [-80.928208274999974, 35.362793908000071],
            [-80.927440273999935, 35.362626907000049],
            [-80.92741827399999, 35.362621907000062],
            [-80.926707274999956, 35.362495907000039],
            [-80.926291273999936, 35.362338907000037],
            [-80.92598127499997, 35.362221907000048],
            [-80.925309273999972, 35.362021907000042],
            [-80.924681273999965, 35.361785907000069],
            [-80.924214272999961, 35.361662907000039],
            [-80.923735273999966, 35.361265906000028],
            [-80.923344272999941, 35.360906907000071],
            [-80.922997272999964, 35.360644907000051],
            [-80.922885272999963, 35.360340907000079],
            [-80.922953272999962, 35.360082907000049],
            [-80.923005272999944, 35.359930907000034],
            [-80.923048272999949, 35.359810907000053],
            [-80.923107271999982, 35.359699907000049],
            [-80.923384272999954, 35.359186907000037],
            [-80.923857273999943, 35.358956907000049],
            [-80.924387273999969, 35.358622906000051],
            [-80.924869273999946, 35.358369907000053],
            [-80.925468272999979, 35.358169906000057],
            [-80.926376273999949, 35.357870906000073],
            [-80.927011273999938, 35.357641906000026],
            [-80.92766327399994, 35.357530906000079],
            [-80.929344273999959, 35.357254906000037],
            [-80.931328274999942, 35.357603906000065],
            [-80.932488275999958, 35.357533905000025],
            [-80.93303227499996, 35.357377905000078],
            [-80.933440275999942, 35.357279907000077],
            [-80.933931275999953, 35.357092906000048],
            [-80.934550275999982, 35.356789906000074],
            [-80.935498275999976, 35.356246905000035],
            [-80.936612276999938, 35.355506905000027],
            [-80.938330277999967, 35.35428590500004],
            [-80.93908027699996, 35.353654904000052],
            [-80.939648276999947, 35.35317890500005],
            [-80.940401276999978, 35.352455904000067],
            [-80.94059227799994, 35.35230490500004],
            [-80.941178277999938, 35.351844905000064],
            [-80.941764277999937, 35.351363904000038],
            [-80.942357277999974, 35.351016904000062],
            [-80.942921277999972, 35.350705904000051],
            [-80.943613277999987, 35.350518904000069],
            [-80.94351927799994, 35.349537903000055],
            [-80.942990278999957, 35.348711903000037],
            [-80.942669277999983, 35.348181903000068],
            [-80.942409277999957, 35.347798904000058],
            [-80.94227727699996, 35.347574903000066],
            [-80.942118276999963, 35.347282904000053],
            [-80.941999277999969, 35.34707390300008],
            [-80.941861277999976, 35.346818903000042],
            [-80.941480277999972, 35.346155903000067],
            [-80.941179276999947, 35.345594903000062],
            [-80.941047276999939, 35.345381903000032],
            [-80.940969276999965, 35.345268902000043],
            [-80.94078327699998, 35.345015902000057],
            [-80.940368276999948, 35.344496903000049],
            [-80.939629276999938, 35.343554902000051],
            [-80.939531276999958, 35.343406903000073],
            [-80.939476276999983, 35.343289903000027],
            [-80.939431275999937, 35.34314990200005],
            [-80.93939927699995, 35.34300690200007],
            [-80.93938327799998, 35.342853902000058],
            [-80.93938327799998, 35.34266890300006],
            [-80.93940727599994, 35.342278902000032],
            [-80.939424275999954, 35.342101902000024],
            [-80.939429276999988, 35.341938902000038],
            [-80.939427276999936, 35.341836902000068],
            [-80.939405276999935, 35.341645902000039],
            [-80.939384277999977, 35.341562902000078],
            [-80.939224276999937, 35.341134902000078],
            [-80.938977275999946, 35.340532901000074],
            [-80.938717276999967, 35.339929902000051],
            [-80.938518276999957, 35.339444902000025],
            [-80.938483276999989, 35.339345902000048],
            [-80.938422276999972, 35.339174902000025],
            [-80.938392275999945, 35.33864690200005],
            [-80.938361275999966, 35.338452902000029],
            [-80.938365276999946, 35.338356902000044],
            [-80.938357276999966, 35.338205902000027],
            [-80.938354275999984, 35.338140902000077],
            [-80.938350276999984, 35.337467901000025],
            [-80.938343276999944, 35.33713890100006],
            [-80.938342276999947, 35.337026901000058],
            [-80.938336275999973, 35.336255902000062],
            [-80.938323276999938, 35.336087901000042],
            [-80.938286275999985, 35.335769900000059],
            [-80.938265275999981, 35.335649901000068],
            [-80.938244275999978, 35.335567901000047],
            [-80.938180274999979, 35.335369901000035],
            [-80.938062275999982, 35.33509390100005],
            [-80.937629275999939, 35.334163901000068],
            [-80.937444274999962, 35.333735901000068],
            [-80.937367275999975, 35.333578901000067],
            [-80.937335275999942, 35.333532901000069],
            [-80.93727127599999, 35.33346490100007],
            [-80.937074275999976, 35.333657901000038],
            [-80.93690027599996, 35.333810901000049],
            [-80.936738274999982, 35.333935901000075],
            [-80.936569275999943, 35.334047901000076],
            [-80.936454275999949, 35.33411390100008],
            [-80.936303274999943, 35.334190900000067],
            [-80.936036274999935, 35.334309900000051],
            [-80.935716274999947, 35.334438901000055],
            [-80.934599275999972, 35.334859901000073],
            [-80.933532274999948, 35.335162902000036],
            [-80.933190273999969, 35.335206901000049],
            [-80.932999274999986, 35.335221901000068],
            [-80.932706274999987, 35.335230901000045],
            [-80.932595274999983, 35.335227900000064],
            [-80.932348273999935, 35.335209901000042],
            [-80.931947273999981, 35.335158901000057],
            [-80.931485273999954, 35.335090901000058],
            [-80.931117272999984, 35.335036901000024],
            [-80.930520273999946, 35.334949901000073],
            [-80.92988827299996, 35.334853901000031],
            [-80.929607272999988, 35.33481590100007],
            [-80.929238273999943, 35.334783901000037],
            [-80.929099273999952, 35.33477890100005],
            [-80.928812273999938, 35.334785901000032],
            [-80.928575272999979, 35.334811902000069],
            [-80.92827127299995, 35.334871902000032],
            [-80.928058272999976, 35.334924901000079],
            [-80.927047272999971, 35.335204901000054],
            [-80.92687927299994, 35.335248901000057],
            [-80.926796272999979, 35.335270901000058],
            [-80.926530272999969, 35.335335902000054],
            [-80.926275272999987, 35.335388902000034],
            [-80.926120272999981, 35.335420901000077],
            [-80.925922272999969, 35.335457901000041],
            [-80.925475272999961, 35.335525902000029],
            [-80.924589271999935, 35.335650902000054],
            [-80.923879272999955, 35.335755901000027],
            [-80.922743270999945, 35.335917902000062],
            [-80.922506271999964, 35.335944902000051],
            [-80.922315270999945, 35.33595990200007],
            [-80.922054270999979, 35.335964901000068],
            [-80.921763270999975, 35.335949901000049],
            [-80.921535271999971, 35.335921902000052],
            [-80.921094270999959, 35.335838902000035],
            [-80.920474269999943, 35.335705902000029],
            [-80.919887270999936, 35.335573902000078],
            [-80.919338269999969, 35.335464902000069],
            [-80.919103269999937, 35.33542790100006],
            [-80.918893269999955, 35.335401901000068],
            [-80.918643269999961, 35.335378902000059],
            [-80.918231269999978, 35.335358902000053],
            [-80.917946269999959, 35.335357902000055],
            [-80.917583269999966, 35.335371902000077],
            [-80.917232269999943, 35.335398902000065],
            [-80.917037268999934, 35.335420901000077],
            [-80.916989269999988, 35.335429901000055],
            [-80.916250269999978, 35.335565902000042],
            [-80.91604426899994, 35.335600901000078],
            [-80.915287268999975, 35.335730901000034],
            [-80.915230269999938, 35.335741902000052],
            [-80.914652268999987, 35.335847901000079],
            [-80.914298268999971, 35.335921902000052],
            [-80.913991268999951, 35.336001903000067],
            [-80.913783268999964, 35.335749902000032],
            [-80.913221267999972, 35.335088901000063],
            [-80.912926268999968, 35.334676902000069],
            [-80.912808267999935, 35.33448590100005],
            [-80.912635267999974, 35.334186902000056],
            [-80.912550268999951, 35.334019901000033],
            [-80.912437267999962, 35.333744901000046],
            [-80.912311267999939, 35.33343190100004],
            [-80.912154267999938, 35.333011901000077],
            [-80.912076268999954, 35.332775901000048],
            [-80.911465266999983, 35.333212902000071],
            [-80.910880267999971, 35.333668901000067],
            [-80.910338267999975, 35.334129902000029],
            [-80.909756267999967, 35.334668902000033],
            [-80.908401266999988, 35.33609490200007],
            [-80.908098266999957, 35.336433902000067],
            [-80.907493267999939, 35.337073902000043],
            [-80.907158266999943, 35.337402903000054],
            [-80.906958266999936, 35.337583902000063],
            [-80.906539266999971, 35.337929903000031],
            [-80.906271266999966, 35.338130902000046],
            [-80.905818266999972, 35.338432902000079],
            [-80.905344265999986, 35.338712903000044],
            [-80.904871265999986, 35.338948903000073],
            [-80.904596265999942, 35.339086903000066],
            [-80.904338266999957, 35.339198903000067],
            [-80.904076266999937, 35.33930390300003],
            [-80.90366626499997, 35.339453903000049],
            [-80.903121265999971, 35.339621903000079],
            [-80.902564265999956, 35.339762904000054],
            [-80.901998265999964, 35.339875904000053],
            [-80.901424264999946, 35.339960904000066],
            [-80.900845265999976, 35.340016903000048],
            [-80.900366263999956, 35.340041903000042],
            [-80.899729264999962, 35.340043903000037],
            [-80.899058264999951, 35.340017904000035],
            [-80.89846526499997, 35.339970903000051],
            [-80.89573726399999, 35.33970490400003],
            [-80.894385262999947, 35.33957390300003],
            [-80.894369263999977, 35.339608904000045],
            [-80.894295263999936, 35.339781904000063],
            [-80.893663262999951, 35.339722904000041],
            [-80.89293926299996, 35.339693903000068],
            [-80.892214261999982, 35.339693904000058],
            [-80.891491261999988, 35.339723904000039],
            [-80.890769260999946, 35.339781904000063],
            [-80.890045261999944, 35.339869903000078],
            [-80.88933726099998, 35.33998590300007],
            [-80.888637260999985, 35.340128904000039],
            [-80.887946260999968, 35.34030090400006],
            [-80.887266260999979, 35.340499904000069],
            [-80.886599260999958, 35.340724904000069],
            [-80.885946260999958, 35.340977903000066],
            [-80.885517260999961, 35.341164904000038],
            [-80.885309260999975, 35.341255904000036],
            [-80.884690259999957, 35.341558905000056],
            [-80.884371259999966, 35.341732904000025],
            [-80.883687259999988, 35.342137904000026],
            [-80.883025259999954, 35.34256690400008],
            [-80.882290258999944, 35.343056904000036],
            [-80.881581259999962, 35.343501905000039],
            [-80.881512259999965, 35.343540905000054],
            [-80.881412258999944, 35.343598905000079],
            [-80.881198258999973, 35.343458904000045],
            [-80.879566258999944, 35.342389904000072],
            [-80.87930825899997, 35.342196905000037],
            [-80.879076258999987, 35.341982904000076],
            [-80.878871258999936, 35.341751905000024],
            [-80.87847925899996, 35.341187905000027],
            [-80.877396257999976, 35.33927990400008],
            [-80.877251258999934, 35.339018904000056],
            [-80.876688256999955, 35.338040904000025],
            [-80.875991256999953, 35.336813904000053],
            [-80.875699257999941, 35.336300903000051],
            [-80.875323256999934, 35.335643903000062],
            [-80.874933256999952, 35.334947903000057],
            [-80.874720256999979, 35.334524902000055],
            [-80.874418257999935, 35.333874903000037],
            [-80.874203256999976, 35.333372903000054],
            [-80.874167257999943, 35.333288903000039],
            [-80.87377025699999, 35.332265902000074],
            [-80.873505256999977, 35.331581903000028],
            [-80.873434255999939, 35.33142390200004],
            [-80.873372255999982, 35.331318903000067],
            [-80.873282256999971, 35.331193902000052],
            [-80.873155256999951, 35.331056902000057],
            [-80.873008255999935, 35.330914902000075],
            [-80.87253325599994, 35.330483903000072],
            [-80.872197255999936, 35.330193902000076],
            [-80.871026255999936, 35.329230902000063],
            [-80.87078125499994, 35.329023901000028],
            [-80.86920525499994, 35.327736902000026],
            [-80.869153255999947, 35.327693902000078],
            [-80.868798254999945, 35.327396902000032],
            [-80.868723254999964, 35.327329901000041],
            [-80.868535253999937, 35.327162902000055],
            [-80.867973254999981, 35.326607901000045],
            [-80.86749425499994, 35.326134902000035],
            [-80.86725325499998, 35.325894902000073],
            [-80.867066253999951, 35.325689901000032],
            [-80.866915254999981, 35.325489901000026],
            [-80.866845253999941, 35.325372902000026],
            [-80.866759253999987, 35.325425902000063],
            [-80.86667625399997, 35.32549090200007],
            [-80.86582825399995, 35.326240901000062],
            [-80.865121253999973, 35.32687590200004],
            [-80.864835253999956, 35.327142902000048],
            [-80.864528253999936, 35.327425901000026],
            [-80.86436525299996, 35.32756990200005],
            [-80.864248252999971, 35.327679902000057],
            [-80.863697253999987, 35.328167902000075],
            [-80.86310825299995, 35.328702903000078],
            [-80.863009252999973, 35.328785903000039],
            [-80.862866252999936, 35.328896902000054],
            [-80.86218525299995, 35.329382903000067],
            [-80.862066252999966, 35.329463902000043],
            [-80.861916252999947, 35.329547903000048],
            [-80.861851251999951, 35.329575902000045],
            [-80.861783252999942, 35.329597903000035],
            [-80.861704252999971, 35.329617902000052],
            [-80.861599252999952, 35.329637902000059],
            [-80.861345252999968, 35.32966790200004],
            [-80.86074825299994, 35.329709903000037],
            [-80.85997925199996, 35.329773903000046],
            [-80.858887251999988, 35.329865903000041],
            [-80.858589251999945, 35.329896903000076],
            [-80.858471251999958, 35.329916903000026],
            [-80.858103251999978, 35.330004903000031],
            [-80.857984252999984, 35.330036902000074],
            [-80.857748251999965, 35.330119902000035],
            [-80.857729251999956, 35.330127903000061],
            [-80.857473251999977, 35.330229903000031],
            [-80.856667251999966, 35.330611903000033],
            [-80.856378250999967, 35.330752903000075],
            [-80.856086250999965, 35.330900903000042],
            [-80.85553625099999, 35.331162903000063],
            [-80.855145250999954, 35.331343903000061],
            [-80.854460250999978, 35.331675902000029],
            [-80.854137250999941, 35.33182090400004],
            [-80.853922250999972, 35.331908903000055],
            [-80.853711250999936, 35.331988903000024],
            [-80.853396249999946, 35.332091903000048],
            [-80.853217249999943, 35.332140904000028],
            [-80.852565250999987, 35.332294904000037],
            [-80.852470249999953, 35.332325903000026],
            [-80.852436250999972, 35.332345904000078],
            [-80.852414249999981, 35.332365903000039],
            [-80.85237725099995, 35.332429904000037],
            [-80.852298250999979, 35.332525903000032],
            [-80.851793250999947, 35.333001903000024],
            [-80.851654249999967, 35.333117904000062],
            [-80.851540249999971, 35.333201903000031],
            [-80.851365250999947, 35.333308904000035],
            [-80.851161248999972, 35.333410903000072],
            [-80.850927249999984, 35.333503904000054],
            [-80.850724249999985, 35.333565904000068],
            [-80.85054624899999, 35.333604904000026],
            [-80.849208249999947, 35.333788904000073],
            [-80.848757249999949, 35.33385090400003],
            [-80.848318248999988, 35.333908904000054],
            [-80.846675248999986, 35.334133904000055],
            [-80.845706248999988, 35.334265905000052],
            [-80.845415247999938, 35.334313904000055],
            [-80.845385247999957, 35.334321905000024],
            [-80.84527924799994, 35.334349904000078],
            [-80.844901247999985, 35.334474905000036],
            [-80.844753247999961, 35.334530904000076],
            [-80.844542248999971, 35.334623904000068],
            [-80.844467247999944, 35.334659903000045],
            [-80.844431247999978, 35.334678904000043],
            [-80.844373247999954, 35.334708904000024],
            [-80.843267247999961, 35.335338905000071],
            [-80.843024248999939, 35.335485905000041],
            [-80.842947247999973, 35.335560904000033],
            [-80.842826247999938, 35.335649905000025],
            [-80.842736246999948, 35.33569990500007],
            [-80.842654247999974, 35.335735904000046],
            [-80.842271246999985, 35.335903905000066],
            [-80.841668247999962, 35.336287905000063],
            [-80.840912246999949, 35.336750905000031],
            [-80.84009724799995, 35.337249905000078],
            [-80.839898246999951, 35.337366906000057],
            [-80.839705246999984, 35.33745990500006],
            [-80.839514246999954, 35.337533905000043],
            [-80.839332246999959, 35.33759490500006],
            [-80.839217246999965, 35.337627905000033],
            [-80.839147245999982, 35.337646905000042],
            [-80.839081245999978, 35.337665905000051],
            [-80.838583245999985, 35.337782904000051],
            [-80.838211245999958, 35.337865905000058],
            [-80.836923245999969, 35.338192905000028],
            [-80.835758245999955, 35.338477906000037],
            [-80.835352244999967, 35.338581905000069],
            [-80.834883245999947, 35.338696905000063],
            [-80.834301245999939, 35.338856905000057],
            [-80.833521244999986, 35.339038905000052],
            [-80.833271244999935, 35.339081906000047],
            [-80.83313024499995, 35.339098905000071],
            [-80.832898244999967, 35.339110905000041],
            [-80.832734244999983, 35.339113905000033],
            [-80.832505244999936, 35.339108905000046],
            [-80.831935243999965, 35.339097905000074],
            [-80.82832024399994, 35.339004906000071],
            [-80.827885242999969, 35.33899490500005],
            [-80.826954243999978, 35.338973906000035],
            [-80.824361242999942, 35.338903906000041],
            [-80.824177242999951, 35.338893905000077],
            [-80.824059241999976, 35.338882906000038],
            [-80.823989241999982, 35.338875906000055],
            [-80.823682240999972, 35.338825906000068],
            [-80.822240240999975, 35.338573906000079],
            [-80.821838241999956, 35.338496906000046],
            [-80.82133324199998, 35.338373905000026],
            [-80.821005240999966, 35.338276906000033],
            [-80.820791240999938, 35.338200906000054],
            [-80.820685240999978, 35.338156906000052],
            [-80.820576240999969, 35.338105906000067],
            [-80.820470241999942, 35.338043905000063],
            [-80.820338240999945, 35.337945905000026],
            [-80.82028324099997, 35.337896905000036],
            [-80.820137240999941, 35.33774190500003],
            [-80.820023240999944, 35.337605905000032],
            [-80.819780241999979, 35.337299906000055],
            [-80.819480240999951, 35.336958906000064],
            [-80.819046239999977, 35.336487906000059],
            [-80.81894024099995, 35.336390906000076],
            [-80.818828239999959, 35.336303906000069],
            [-80.818762240999945, 35.336263905000067],
            [-80.81867023999996, 35.336222905000056],
            [-80.818559239999956, 35.33619490600006],
            [-80.818475239999941, 35.336184906000028],
            [-80.818372239999974, 35.336182905000044],
            [-80.818214240999964, 35.336200905000055],
            [-80.818072239999935, 35.336227905000044],
            [-80.817950239999959, 35.336259906000066],
            [-80.817765240999961, 35.336318906000031],
            [-80.817630239999971, 35.33637090600007],
            [-80.817470239999977, 35.336447906000046],
            [-80.81727824099994, 35.336558905000061],
            [-80.816823239999962, 35.336875906000046],
            [-80.816727239999977, 35.336942905000058],
            [-80.816589239999985, 35.337029906000055],
            [-80.816406240999981, 35.33712590600004],
            [-80.816219238999963, 35.337213905000056],
            [-80.816033239999967, 35.337280906000046],
            [-80.814406238999936, 35.337810907000062],
            [-80.813914238999985, 35.337959906000037],
            [-80.813511238999979, 35.338075906000029],
            [-80.813152238999976, 35.33816790700007],
            [-80.812428238999985, 35.338321907000079],
            [-80.811115237999957, 35.33859190700008],
            [-80.810599237999952, 35.33869790600005],
            [-80.809772237999937, 35.338860907000026],
            [-80.808983237999939, 35.339015906000043],
            [-80.808684237999955, 35.339074907000054],
            [-80.808448237999983, 35.33911390600008],
            [-80.808325237999952, 35.339128907000031],
            [-80.808193236999955, 35.339137907000065],
            [-80.808036237999943, 35.339135906000024],
            [-80.807884237999986, 35.339128906000042],
            [-80.807652236999957, 35.33909890700005],
            [-80.807210236999936, 35.339022906000025],
            [-80.805952236999985, 35.338825907000057],
            [-80.804051235999964, 35.338533907000055],
            [-80.803858236999986, 35.338503906000028],
            [-80.802102235999939, 35.338253906000034],
            [-80.801751234999983, 35.338203907000036],
            [-80.801037235999956, 35.338105907000056],
            [-80.799367234999977, 35.337972907000051],
            [-80.798496234999959, 35.337915906000035],
            [-80.796659233999947, 35.337784906000024],
            [-80.796626233999973, 35.338056907000066],
            [-80.796552233999989, 35.338581906000059],
            [-80.796434234999936, 35.339495907000071],
            [-80.796405233999963, 35.339602907000028],
            [-80.796388233999949, 35.339802908000024],
            [-80.796366233999947, 35.33993190700005],
            [-80.796322233999945, 35.340072908000025],
            [-80.796145233999937, 35.340568907000034],
            [-80.795761232999951, 35.34164290700005],
            [-80.795633233999979, 35.342015908000064],
            [-80.795506233999959, 35.342495908000046],
            [-80.795429233999982, 35.342832907000059],
            [-80.795412233999969, 35.342929908000031],
            [-80.795396233999952, 35.343105908000041],
            [-80.79539323399996, 35.343211908000058],
            [-80.795405233999986, 35.343617908000056],
            [-80.795402234999983, 35.343655908000073],
            [-80.795396233999952, 35.343708908000053],
            [-80.795429233999982, 35.344386909000036],
            [-80.795711234999942, 35.34701190800007],
            [-80.795741233999934, 35.347278909000067],
            [-80.795768233999979, 35.347550908000073],
            [-80.79579023499997, 35.34778091000004],
            [-80.795811234999974, 35.348044909000066],
            [-80.795881233999978, 35.348936908000042],
            [-80.795974233999971, 35.350094909000063],
            [-80.795977234999953, 35.350282909000043],
            [-80.795959233999952, 35.350469909000026],
            [-80.795949233999977, 35.350527910000039],
            [-80.795915233999949, 35.35065891000005],
            [-80.795912234999946, 35.350689909000039],
            [-80.795924234999973, 35.350762910000071],
            [-80.795845233999955, 35.350827909000031],
            [-80.795817233999969, 35.350859910000054],
            [-80.79579423399997, 35.350894910000079],
            [-80.795246234999979, 35.352010910000047],
            [-80.795148234999942, 35.352198910000027],
            [-80.795143233999966, 35.352207910000061],
            [-80.794993234999936, 35.352494910000075],
            [-80.794889233999982, 35.352679909000074],
            [-80.79472123499994, 35.353003910000041],
            [-80.794592233999936, 35.353298911000024],
            [-80.794308233999971, 35.354033910000055],
            [-80.794259233999981, 35.354203910000024],
            [-80.794232233999935, 35.354376910000042],
            [-80.794228233999945, 35.35450091000007],
            [-80.794243233999964, 35.354664910000054],
            [-80.794266234999952, 35.354800911000041],
            [-80.794301234999978, 35.354924911000069],
            [-80.794327234999969, 35.354984911000031],
            [-80.794382233999954, 35.355082910000078],
            [-80.79446623399997, 35.355181911000045],
            [-80.794587233999948, 35.355283911000072],
            [-80.79467923499999, 35.35533791000006],
            [-80.794827234999957, 35.355424910000067],
            [-80.795254234999959, 35.35565691100004],
            [-80.795571234999954, 35.355819910000037],
            [-80.797256235999953, 35.356629911000027],
            [-80.797561235999979, 35.356782910000049],
            [-80.797742235999976, 35.356880911000076],
            [-80.797908234999966, 35.356993910000028],
            [-80.798015235999969, 35.357091910000065],
            [-80.798130234999974, 35.357221910000078],
            [-80.798263235999968, 35.35740891100005],
            [-80.798339234999958, 35.357540911000058],
            [-80.798605235999958, 35.358067911000035],
            [-80.798719235999954, 35.358270911000034],
            [-80.798848235999969, 35.358548911000071],
            [-80.799051235999968, 35.358940911000047],
            [-80.799117234999983, 35.359049912000046],
            [-80.79924523599999, 35.359229912000046],
            [-80.799348234999968, 35.35935591100008],
            [-80.799633235999977, 35.359705911000049],
            [-80.799779235999949, 35.35991591100003],
            [-80.799856235999982, 35.360064912000041],
            [-80.799902236999969, 35.360178912000038],
            [-80.799913234999963, 35.360216912000055],
            [-80.799935235999953, 35.360268911000048],
            [-80.799952235999967, 35.360326911000072],
            [-80.799622235999948, 35.360418912000057],
            [-80.798988235999957, 35.360611912000024],
            [-80.798501235999936, 35.360765911000044],
            [-80.797442234999949, 35.361090912000066],
            [-80.797181234999982, 35.361181911000074],
            [-80.797062234999942, 35.361228911000069],
            [-80.796106234999968, 35.361662912000043],
            [-80.79504323499998, 35.362153912000053],
            [-80.794044233999955, 35.362620912000068],
            [-80.793893234999985, 35.362692912000057],
            [-80.79375323499994, 35.362753912000073],
            [-80.793432234999955, 35.362907912000026],
            [-80.792583233999949, 35.363296912000067],
            [-80.792412234999972, 35.36336691200006],
            [-80.792199233999952, 35.363451912000073],
            [-80.791902233999963, 35.363558913000077],
            [-80.791545233999955, 35.363679912000066],
            [-80.791312232999985, 35.363761913000076],
            [-80.791192232999947, 35.363803913000027],
            [-80.791017232999934, 35.363860912000064],
            [-80.79080823399994, 35.363930912000058],
            [-80.790297233999979, 35.364097912000034],
            [-80.78977523399999, 35.364281912000024],
            [-80.789337233999959, 35.364425913000048],
            [-80.789110232999974, 35.364524912000036],
            [-80.78895423299997, 35.364602913000056],
            [-80.788622231999966, 35.364780913000061],
            [-80.788296232999983, 35.364966913000046],
            [-80.787528231999943, 35.365370913000049],
            [-80.787531232999982, 35.365484914000035],
            [-80.787528231999943, 35.365560913000024],
            [-80.787499232999949, 35.365769912000076],
            [-80.78746023399998, 35.365936914000031],
            [-80.787396232999981, 35.366161913000042],
            [-80.792751234999969, 35.368284913000025],
            [-80.794869234999965, 35.369124913000064],
            [-80.79568123599995, 35.369461914000055],
            [-80.795949234999966, 35.370134913000072],
            [-80.796038234999969, 35.370239914000024],
            [-80.796087234999959, 35.370324914000037],
            [-80.796123235999971, 35.370414914000037],
            [-80.796280235999973, 35.371011914000064],
            [-80.796325234999983, 35.371120914000073],
            [-80.796389234999936, 35.371225914000036],
            [-80.796635235999986, 35.371587914000031],
            [-80.796870235999961, 35.371958914000061],
            [-80.796915235999961, 35.372047913000074],
            [-80.796933235999973, 35.372133914000074],
            [-80.796958235999966, 35.372212914000045],
            [-80.796959235999964, 35.37238691400006],
            [-80.796966234999957, 35.372515914000076],
            [-80.796976235999978, 35.372682915000041],
            [-80.796997235999982, 35.372774914000047],
            [-80.797039235999989, 35.372922914000071],
            [-80.797416235999947, 35.373811914000044],
            [-80.797446236999974, 35.373914914000068],
            [-80.797462235999944, 35.374016914000038],
            [-80.797468235999986, 35.374094914000068],
            [-80.797451236999962, 35.374244915000077],
            [-80.797408235999967, 35.374408914000071],
            [-80.797247235999976, 35.374933914000053],
            [-80.797101235999946, 35.375382914000056],
            [-80.79768123599996, 35.375517915000046],
            [-80.797975236999946, 35.375592915000027],
            [-80.798128235999968, 35.375652915000046],
            [-80.798258235999981, 35.375729914000033],
            [-80.798362236999935, 35.375802915000065],
            [-80.798447236999948, 35.375885915000026],
            [-80.798501236999982, 35.375957915000072],
            [-80.798560236999947, 35.376061915000037],
            [-80.798594236999975, 35.376165915000058],
            [-80.798619236999969, 35.376291915000024],
            [-80.798623236999958, 35.376401914000041],
            [-80.798611236999989, 35.376517915000079],
            [-80.798570235999989, 35.376642916000037],
            [-80.798507235999978, 35.376789915000074],
            [-80.798463236999964, 35.376912915000048],
            [-80.798449235999954, 35.376998915000058],
            [-80.79845323699999, 35.37707391500004],
            [-80.798466235999967, 35.377154915000062],
            [-80.798507236999967, 35.377240915000073],
            [-80.79855123699997, 35.377312914000072],
            [-80.798589235999941, 35.377364916000033],
            [-80.798712236999961, 35.377486914000031],
            [-80.797820236999939, 35.378089914000043],
            [-80.797605236999971, 35.378237916000046],
            [-80.797417236999934, 35.378367915000069],
            [-80.796784235999951, 35.378796915000066],
            [-80.796537235999949, 35.378964916000029],
            [-80.795169236999982, 35.379910915000039],
            [-80.794706235999968, 35.380224916000031],
            [-80.794392234999975, 35.380420916000048],
            [-80.794257234999975, 35.380490915000053],
            [-80.794104235999953, 35.380559916000038],
            [-80.793955235999988, 35.380613916000073],
            [-80.793399234999981, 35.380789916000026],
            [-80.79240923499998, 35.381092915000067],
            [-80.791912235999973, 35.38124491700006],
            [-80.791240234999975, 35.381449916000065],
            [-80.790655234999974, 35.38162791700006],
            [-80.790401233999944, 35.381705916000044],
            [-80.790291234999984, 35.381739916000072],
            [-80.789902233999953, 35.381876916000067],
            [-80.789763233999963, 35.381933916000037],
            [-80.789696233999962, 35.381965917000059],
            [-80.789469233999966, 35.382082917000048],
            [-80.789291233999961, 35.38218691600008],
            [-80.789050233999944, 35.382340916000032],
            [-80.788806233999935, 35.382512917000042],
            [-80.788435233999962, 35.382791916000031],
            [-80.788118233999967, 35.383049916000061],
            [-80.787773233999985, 35.383361917000059],
            [-80.786700233999966, 35.384445916000061],
            [-80.785941233999949, 35.385195917000033],
            [-80.785334232999958, 35.385761918000071],
            [-80.784783232999985, 35.386234918000071],
            [-80.784700232999967, 35.386299917000031],
            [-80.78233923199997, 35.38815291700007],
            [-80.78141223199998, 35.388881918000038],
            [-80.779635231999976, 35.390281918000028],
            [-80.779433231999974, 35.390435918000037],
            [-80.779261231999953, 35.390556918000073],
            [-80.77899923199999, 35.390720919000046],
            [-80.778744231999951, 35.390860919000033],
            [-80.778606230999969, 35.390927919000035],
            [-80.778365230999952, 35.391035919000046],
            [-80.778006230999949, 35.391183919000071],
            [-80.778089230999967, 35.391297919000067],
            [-80.780835231999959, 35.395009919000074],
            [-80.782405232999963, 35.397131920000049],
            [-80.783722233999981, 35.398911921000035],
            [-80.781756231999964, 35.399425920000056],
            [-80.781609232999983, 35.399464920000071],
            [-80.78152823299996, 35.399959921000061],
            [-80.781306232999952, 35.400481920000061],
            [-80.780617232999987, 35.402109921000033],
            [-80.78050723299998, 35.402369921000059],
            [-80.779215232999945, 35.405415921000042],
            [-80.778600232999963, 35.406866922000063],
            [-80.778380231999961, 35.407267922000074],
            [-80.779746232999969, 35.409076922000054],
            [-80.778319232999934, 35.409293922000074],
            [-80.774467231999949, 35.409852923000074],
            [-80.77464723199995, 35.412072923000039],
            [-80.77468023199998, 35.412043923000056],
            [-80.774795230999985, 35.411957923000045],
            [-80.774835231999987, 35.411895923000031],
            [-80.774847231999956, 35.411841923000054],
            [-80.774869230999968, 35.411792923000064],
            [-80.774931231999972, 35.41173092300005],
            [-80.775001231999966, 35.41169392300003],
            [-80.775138231999961, 35.411649923000027],
            [-80.775221230999989, 35.411617923000051],
            [-80.775310231999981, 35.41159592300005],
            [-80.775400230999935, 35.411566923000066],
            [-80.775561231999973, 35.411543923000067],
            [-80.77588923199994, 35.411468923000029],
            [-80.775947231999965, 35.411465924000026],
            [-80.776008230999935, 35.411454923000065],
            [-80.776192231999971, 35.411443923000036],
            [-80.776250231999938, 35.411434924000048],
            [-80.776315230999955, 35.411419923000039],
            [-80.776376231999961, 35.411396923000041],
            [-80.776439231999973, 35.411367923000057],
            [-80.776475231999939, 35.411360923000075],
            [-80.776517231999946, 35.411352923000038],
            [-80.776593231999982, 35.411327923000044],
            [-80.776644231999967, 35.411319923000065],
            [-80.776882230999945, 35.411309923000033],
            [-80.776959231999967, 35.41129092400007],
            [-80.777028231999964, 35.411278924000044],
            [-80.777196231999937, 35.411277924000046],
            [-80.777251231999969, 35.411270923000075],
            [-80.777359231999981, 35.411222923000025],
            [-80.777454231999968, 35.411194923000039],
            [-80.777593232999948, 35.411176923000028],
            [-80.77779923199995, 35.411139924000054],
            [-80.777890232999937, 35.411132923000025],
            [-80.778176232999954, 35.41108592300003],
            [-80.778279232999978, 35.411061923000034],
            [-80.778361231999952, 35.411056923000046],
            [-80.778514232999953, 35.411035923000043],
            [-80.778679232999934, 35.411002923000069],
            [-80.778748232999988, 35.410975923000024],
            [-80.778848232999962, 35.410948923000035],
            [-80.779038232999937, 35.410873922000064],
            [-80.779299232999961, 35.410825923000061],
            [-80.779398231999949, 35.410789924000028],
            [-80.779621232999943, 35.410735922000072],
            [-80.77980923299998, 35.410730923000074],
            [-80.77990323299997, 35.41070592300008],
            [-80.779957232999948, 35.410684923000076],
            [-80.780012233999969, 35.410651923000046],
            [-80.780097231999946, 35.410612923000031],
            [-80.780171232999976, 35.41058692200005],
            [-80.780265232999966, 35.410571923000077],
            [-80.780442232999974, 35.410561922000056],
            [-80.780512232999968, 35.410546922000037],
            [-80.780684232999988, 35.410449923000044],
            [-80.780733232999978, 35.410425922000059],
            [-80.780815233999988, 35.410404922000055],
            [-80.780898232999959, 35.410397923000062],
            [-80.781004232999976, 35.410382922000053],
            [-80.781095232999974, 35.410357923000049],
            [-80.781159233999972, 35.410330922000071],
            [-80.78124623299999, 35.410320923000029],
            [-80.781320233999963, 35.410303923000072],
            [-80.781706232999966, 35.410190922000027],
            [-80.781786233999981, 35.410157923000043],
            [-80.781881233999968, 35.410131922000062],
            [-80.781969233999973, 35.410112922000053],
            [-80.782108233999963, 35.410108923000053],
            [-80.782196233999969, 35.410110923000047],
            [-80.782359233999955, 35.410129922000067],
            [-80.78245123399995, 35.410145923000073],
            [-80.782764234999945, 35.410181923000039],
            [-80.783070233999979, 35.410227922000047],
            [-80.783224233999988, 35.410238922000076],
            [-80.783818233999966, 35.410228922000044],
            [-80.783905233999974, 35.410204923000038],
            [-80.784016233999978, 35.410179923000044],
            [-80.784323234999988, 35.410177922000059],
            [-80.784387233999951, 35.410171922000075],
            [-80.78444323399998, 35.410159923000037],
            [-80.78475423499998, 35.410140922000039],
            [-80.784862233999945, 35.410139922000042],
            [-80.784951233999948, 35.41014692300007],
            [-80.785137234999979, 35.410189922000029],
            [-80.785384234999981, 35.410273923000034],
            [-80.785492234999936, 35.410303923000072],
            [-80.785668234999946, 35.410324922000029],
            [-80.785882234999974, 35.410373922000076],
            [-80.785925235999969, 35.410368922000032],
            [-80.786037234999981, 35.410397923000062],
            [-80.786243234999972, 35.410411922000037],
            [-80.786335234999967, 35.410411922000037],
            [-80.786458234999941, 35.410389923000025],
            [-80.786682234999944, 35.410371922000024],
            [-80.787136233999945, 35.410282923000068],
            [-80.787198233999959, 35.410324922000029],
            [-80.787335234999944, 35.410345922000033],
            [-80.787429234999934, 35.410350923000067],
            [-80.787628234999943, 35.410384922000048],
            [-80.78767023499995, 35.410399923000057],
            [-80.787833235999983, 35.410426922000056],
            [-80.787994233999939, 35.410471923000046],
            [-80.788039234999985, 35.410493923000047],
            [-80.788173234999988, 35.410498923000034],
            [-80.788232234999953, 35.410509922000074],
            [-80.788283235999984, 35.410530923000067],
            [-80.788525235999941, 35.410669923000057],
            [-80.788655235999954, 35.410752923000075],
            [-80.788694235999969, 35.410767922000048],
            [-80.788770235999948, 35.41080792300005],
            [-80.788839235999944, 35.410874922000062],
            [-80.789014235999957, 35.410971922000044],
            [-80.789060235999955, 35.410996922000038],
            [-80.789383235999935, 35.411232923000057],
            [-80.789910235999969, 35.411580923000031],
            [-80.790065235999975, 35.411678923000068],
            [-80.79011223599997, 35.411716923000029],
            [-80.790309236999974, 35.411848922000047],
            [-80.790431234999971, 35.411944922000032],
            [-80.790521235999961, 35.412021922000065],
            [-80.79063823599995, 35.41210592200008],
            [-80.790689236999981, 35.412149923000072],
            [-80.79081423699995, 35.41228592300007],
            [-80.790915236999979, 35.412382923000052],
            [-80.790965236999966, 35.412443923000069],
            [-80.791081235999968, 35.412533923000069],
            [-80.791240236999954, 35.412730923000026],
            [-80.79145523599999, 35.41304092300004],
            [-80.791481236999971, 35.413098922000074],
            [-80.791531235999969, 35.413189923000061],
            [-80.791697236999937, 35.413465922000057],
            [-80.791844235999974, 35.413663922000069],
            [-80.791864235999981, 35.413702923000073],
            [-80.792006236999953, 35.413964924000027],
            [-80.792133236999973, 35.414167922000047],
            [-80.792267236999976, 35.414350923000029],
            [-80.792417236999938, 35.414601923000077],
            [-80.792622236999989, 35.414912923000031],
            [-80.792633236999961, 35.414945924000051],
            [-80.792634237999948, 35.414991923000059],
            [-80.79266423699994, 35.415047923000031],
            [-80.792695235999986, 35.415080924000051],
            [-80.792715236999982, 35.415113923000035],
            [-80.792714235999938, 35.415137924000078],
            [-80.792757236999989, 35.415187924000065],
            [-80.792779236999934, 35.41519792400004],
            [-80.792817236999952, 35.415201923000041],
            [-80.79284023699995, 35.415214924000054],
            [-80.792863236999949, 35.415238923000061],
            [-80.792874236999978, 35.41526192300006],
            [-80.792875235999986, 35.415281923000066],
            [-80.792854236999972, 35.415425924000033],
            [-80.792862236999952, 35.415516923000041],
            [-80.792853236999974, 35.415548924000063],
            [-80.792826236999986, 35.415595923000069],
            [-80.792817236999952, 35.415635923000025],
            [-80.792817236999952, 35.41579792400006],
            [-80.792857236999964, 35.415865923000069],
            [-80.792886237999937, 35.415891923000061],
            [-80.792909236999947, 35.415939923000053],
            [-80.792924237999955, 35.415982923000058],
            [-80.792952237999941, 35.416014924000024],
            [-80.792973237999945, 35.416029924000043],
            [-80.793020236999951, 35.41604592300007],
            [-80.793186236999986, 35.416178923000075],
            [-80.793460236999977, 35.41633192300003],
            [-80.79360823799999, 35.416421924000076],
            [-80.793729237999969, 35.416475924000054],
            [-80.79384123799997, 35.416538924000065],
            [-80.793918236999957, 35.416591923000055],
            [-80.794090237999967, 35.41668792300004],
            [-80.794143237999947, 35.41672892400004],
            [-80.794270237999967, 35.416778924000027],
            [-80.794415237999942, 35.416841924000039],
            [-80.794636237999953, 35.416964924000069],
            [-80.794758237999986, 35.417022923000047],
            [-80.794843237999942, 35.417071924000027],
            [-80.79497523799995, 35.417128924000053],
            [-80.795105237999962, 35.417203924000034],
            [-80.79554223699995, 35.41741492400007],
            [-80.795591237999986, 35.417442924000056],
            [-80.795659237999985, 35.417464923000068],
            [-80.795740237999951, 35.417501924000078],
            [-80.795773238999971, 35.417521923000038],
            [-80.795849238999949, 35.417596924000065],
            [-80.796040238999979, 35.417814923000037],
            [-80.796110237999983, 35.417915923000066],
            [-80.796150237999939, 35.417951923000032],
            [-80.796233237999957, 35.417998924000074],
            [-80.796339237999973, 35.418164923000063],
            [-80.796405237999977, 35.41829292400007],
            [-80.796512237999934, 35.418466924000029],
            [-80.79656523899996, 35.418531924000035],
            [-80.796604238999976, 35.418619924000041],
            [-80.796658238999953, 35.418673923000028],
            [-80.796733237999945, 35.418774924000047],
            [-80.796794237999961, 35.41887192400003],
            [-80.796924238999964, 35.41903592400007],
            [-80.797098238999979, 35.419267924000053],
            [-80.797286238999959, 35.41950492400008],
            [-80.797393238999973, 35.419667923000077],
            [-80.797445238999956, 35.419717924000054],
            [-80.797477238999988, 35.419763924000051],
            [-80.797535238999956, 35.419826924000063],
            [-80.797581237999964, 35.419912924000073],
            [-80.797649237999963, 35.419994925000026],
            [-80.797682238999982, 35.420026924000069],
            [-80.797721238999941, 35.420047924000073],
            [-80.79777723899997, 35.420092925000063],
            [-80.797843237999984, 35.420113925000067],
            [-80.797962238999958, 35.420227924000073],
            [-80.797531237999976, 35.420587924000074],
            [-80.797321238999984, 35.420775925000044],
            [-80.796883238999953, 35.421202925000046],
            [-80.794835238999951, 35.42324592500006],
            [-80.793969237999988, 35.424109925000039],
            [-80.793259237999962, 35.424818925000068],
            [-80.792769237999948, 35.425308926000071],
            [-80.79264823799997, 35.425418925000031],
            [-80.792575237999984, 35.425477925000052],
            [-80.792478237999944, 35.425546925000049],
            [-80.792338237999957, 35.425448926000058],
            [-80.792016236999984, 35.425297926000042],
            [-80.791172237999945, 35.424949926000068],
            [-80.791049236999982, 35.424890926000046],
            [-80.790073236999945, 35.424362926000072],
            [-80.78835223699997, 35.423423925000066],
            [-80.788223235999965, 35.423359925000057],
            [-80.788108235999971, 35.423313925000059],
            [-80.788003235999952, 35.423276925000039],
            [-80.787908236999954, 35.423250925000048],
            [-80.78780123599995, 35.423227926000038],
            [-80.787714236999989, 35.423213926000074],
            [-80.787501235999969, 35.423198925000065],
            [-80.787183235999976, 35.423205925000048],
            [-80.785620234999953, 35.423312925000062],
            [-80.78318623499996, 35.423445925000067],
            [-80.782262233999973, 35.423502926000026],
            [-80.782229233999942, 35.423585925000054],
            [-80.782178234999947, 35.42366592500008],
            [-80.781731234999938, 35.424234925000064],
            [-80.781337233999977, 35.424761926000031],
            [-80.78129123399998, 35.424828926000032],
            [-80.781258233999949, 35.424886926000056],
            [-80.781232233999958, 35.424950926000065],
            [-80.781188233999956, 35.425121926000031],
            [-80.781189233999953, 35.425291926000057],
            [-80.781241234999982, 35.42565492500006],
            [-80.78125023399997, 35.42577792600008],
            [-80.781249233999972, 35.425934926000025],
            [-80.78124223399999, 35.425995926000041],
            [-80.781200234999972, 35.426171926000052],
            [-80.781159233999972, 35.426314927000078],
            [-80.781109234999974, 35.426457926000069],
            [-80.780977233999977, 35.426776926000059],
            [-80.780819232999988, 35.427078926000036],
            [-80.780092233999937, 35.428122927000061],
            [-80.779736233999984, 35.42868992700005],
            [-80.77968623299995, 35.428779927000051],
            [-80.779657233999956, 35.428869927000051],
            [-80.779644233999989, 35.428952927000068],
            [-80.779642233999937, 35.429011926000044],
            [-80.779650233999973, 35.429093926000064],
            [-80.779664234999984, 35.429152926000029],
            [-80.779687233999937, 35.429214927000032],
            [-80.77973123299995, 35.429298927000048],
            [-80.779771233999952, 35.429353927000079],
            [-80.779900234999957, 35.429502927000044],
            [-80.780061233999959, 35.429671926000026],
            [-80.780122233999975, 35.429743927000061],
            [-80.780211234999967, 35.429870926000035],
            [-80.780311233999953, 35.430049927000027],
            [-80.780323233999979, 35.43008592700005],
            [-80.780612233999989, 35.429998927000042],
            [-80.780795233999982, 35.429908927000042],
            [-80.780893233999961, 35.429871927000079],
            [-80.780977233999977, 35.429829927000071],
            [-80.781106234999982, 35.429809927000065],
            [-80.781305233999944, 35.429727927000044],
            [-80.781398233999937, 35.42967692600007],
            [-80.78155323499999, 35.429602927000076],
            [-80.781666233999943, 35.42958292700007],
            [-80.781946235999953, 35.429495926000072],
            [-80.78201923499995, 35.429488927000079],
            [-80.782067234999943, 35.429491927000072],
            [-80.782146234999971, 35.429507927000031],
            [-80.782243234999953, 35.429514926000024],
            [-80.782363233999945, 35.429495926000072],
            [-80.782414234999976, 35.429491927000072],
            [-80.78248823499996, 35.429492927000069],
            [-80.782526235999967, 35.429479927000045],
            [-80.782613234999985, 35.429534926000031],
            [-80.782853233999958, 35.429648927000073],
            [-80.782938234999961, 35.429679926000063],
            [-80.783012235999934, 35.429736927000079],
            [-80.783116234999966, 35.429786927000066],
            [-80.78332923499994, 35.42986892700003],
            [-80.783421234999935, 35.429926926000064],
            [-80.78377123599995, 35.430131927000048],
            [-80.783855235999965, 35.430170927000063],
            [-80.783909234999953, 35.430204926000044],
            [-80.784041234999961, 35.430252927000026],
            [-80.784115234999945, 35.430305927000063],
            [-80.784192234999978, 35.430340927000032],
            [-80.784306235999964, 35.430380927000044],
            [-80.784386233999953, 35.43043192600004],
            [-80.784483233999936, 35.430477927000027],
            [-80.784972234999941, 35.43073892700005],
            [-80.785097234999967, 35.430795927000077],
            [-80.785202235999975, 35.430851927000049],
            [-80.785434235999958, 35.430958927000063],
            [-80.78552723599995, 35.431007927000053],
            [-80.785625234999941, 35.431044927000073],
            [-80.785728235999954, 35.431089927000073],
            [-80.785802235999938, 35.431145927000046],
            [-80.785848236999982, 35.431202927000072],
            [-80.78589823599998, 35.431274927000061],
            [-80.785950235999962, 35.43134692700005],
            [-80.786080235999975, 35.431591927000056],
            [-80.786178235999955, 35.431744927000068],
            [-80.786210235999988, 35.431820927000047],
            [-80.786228235999943, 35.431901927000069],
            [-80.786253236999983, 35.431978927000046],
            [-80.786345235999988, 35.432166927000026],
            [-80.78645023699994, 35.432343927000034],
            [-80.786550236999972, 35.432488927000065],
            [-80.786655235999945, 35.432671928000048],
            [-80.786684236999974, 35.432729927000025],
            [-80.786734236999962, 35.432780928000057],
            [-80.78679223599994, 35.432855927000048],
            [-80.786816235999936, 35.43291892700006],
            [-80.786967235999953, 35.433142927000063],
            [-80.787142236999955, 35.433431928000061],
            [-80.787191235999956, 35.433497927000076],
            [-80.787266235999937, 35.433583928000076],
            [-80.787321235999968, 35.433658927000067],
            [-80.787354235999942, 35.433731927000053],
            [-80.787389236999957, 35.433791927000073],
            [-80.787585235999984, 35.434086927000067],
            [-80.78766623599995, 35.434196927000073],
            [-80.787772236999956, 35.434363928000039],
            [-80.787786236999978, 35.434427928000048],
            [-80.787820235999959, 35.434493928000052],
            [-80.78787123699999, 35.434565927000051],
            [-80.787905236999961, 35.434622928000067],
            [-80.788051236999934, 35.43475692800007],
            [-80.788086236999959, 35.43480992800005],
            [-80.788101236999978, 35.434855928000047],
            [-80.788133235999965, 35.43490792800003],
            [-80.788214236999977, 35.435007928000061],
            [-80.788266237999949, 35.435051928000064],
            [-80.788386236999941, 35.435109929000077],
            [-80.788447236999957, 35.435145928000054],
            [-80.788497236999945, 35.435186928000064],
            [-80.788532237999959, 35.435233928000059],
            [-80.788594237999973, 35.43528692700005],
            [-80.788659235999944, 35.435328927000057],
            [-80.788729236999984, 35.435361928000077],
            [-80.788903236999943, 35.435459928000057],
            [-80.789105236999944, 35.435558928000034],
            [-80.78914023699997, 35.435576928000046],
            [-80.789213236999956, 35.435625928000036],
            [-80.789255236999963, 35.43567992800007],
            [-80.789328237999939, 35.435749928000064],
            [-80.789412237999954, 35.435786928000027],
            [-80.789492236999934, 35.435850928000036],
            [-80.789611237999964, 35.435926928000072],
            [-80.789649236999935, 35.435967928000025],
            [-80.789749236999967, 35.436125927000035],
            [-80.789797237999949, 35.436178928000061],
            [-80.789833236999982, 35.436204927000063],
            [-80.789903237999965, 35.436302928000032],
            [-80.790024236999955, 35.436427928000057],
            [-80.790074236999942, 35.436509928000078],
            [-80.790105236999977, 35.436633928000049],
            [-80.790131236999969, 35.436696927000071],
            [-80.790168237999978, 35.436747928000045],
            [-80.790240237999967, 35.436866928000029],
            [-80.790315236999959, 35.437066928000036],
            [-80.790410237999936, 35.437274928000079],
            [-80.790428237999947, 35.43734592800007],
            [-80.790485237999974, 35.43748492900005],
            [-80.790532236999979, 35.437558928000044],
            [-80.790606237999953, 35.437727928000072],
            [-80.790673236999965, 35.437864928000067],
            [-80.790763236999965, 35.438072928000054],
            [-80.790824237999971, 35.438168929000028],
            [-80.790864236999937, 35.438302928000041],
            [-80.790886237999985, 35.438340928000059],
            [-80.790936237999972, 35.43840392900006],
            [-80.790953237999986, 35.438438928000039],
            [-80.790962237999963, 35.438499929000045],
            [-80.790983237999967, 35.438544928000056],
            [-80.791036236999958, 35.438599929000077],
            [-80.791082237999944, 35.438666928000032],
            [-80.791090238999971, 35.438701928000057],
            [-80.791113238999969, 35.438755929000024],
            [-80.791151238999987, 35.43879092800006],
            [-80.791210238999952, 35.43883592800006],
            [-80.791232237999964, 35.438874928000075],
            [-80.791235237999956, 35.438908928000046],
            [-80.791197237999938, 35.439029929000071],
            [-80.791194237999946, 35.439067929000032],
            [-80.791198237999936, 35.439095929000075],
            [-80.791277237999964, 35.439166929000066],
            [-80.791313237999987, 35.439221928000052],
            [-80.791360237999982, 35.439267928000049],
            [-80.791429236999988, 35.439314928000044],
            [-80.791592237999964, 35.43939492800007],
            [-80.791712238999935, 35.439420928000061],
            [-80.791813238999964, 35.439428928000041],
            [-80.791924237999979, 35.439424928000051],
            [-80.792104238999968, 35.439397929000052],
            [-80.792281238999976, 35.439348928000072],
            [-80.792829238999957, 35.439222929000039],
            [-80.793257238999956, 35.439135928000042],
            [-80.793760238999937, 35.43901992800005],
            [-80.79393123899996, 35.43896692900006],
            [-80.794125238999982, 35.438942928000074],
            [-80.794264238999972, 35.438936928000032],
            [-80.794375238999976, 35.438938928000027],
            [-80.794492238999965, 35.438946928000064],
            [-80.794599238999979, 35.438971929000047],
            [-80.79470723899999, 35.43901592800006],
            [-80.794980238999983, 35.439136928000039],
            [-80.795125239999948, 35.439192928000068],
            [-80.795398238999951, 35.439317929000026],
            [-80.795496239999977, 35.439374928000063],
            [-80.795606238999937, 35.439423928000053],
            [-80.795710239999948, 35.439482928000075],
            [-80.795815238999978, 35.439531928000065],
            [-80.796166238999945, 35.43975092900007],
            [-80.796441239999979, 35.43989792800005],
            [-80.796554239999978, 35.439971928000034],
            [-80.796658239999942, 35.440015929000026],
            [-80.796773238999947, 35.440070928000068],
            [-80.796859239999947, 35.440126928000041],
            [-80.796914239999978, 35.440173929000025],
            [-80.797056239999961, 35.44026892800008],
            [-80.797167239999965, 35.440367928000057],
            [-80.797280239999964, 35.440454928000065],
            [-80.797404239999935, 35.440548929000045],
            [-80.79749623899994, 35.440621929000031],
            [-80.797734240999944, 35.440813929000058],
            [-80.797797239999966, 35.440852928000027],
            [-80.79786323999997, 35.440933929000039],
            [-80.797902239999985, 35.440958929000033],
            [-80.797939239999948, 35.440976929000044],
            [-80.798205239999959, 35.441208929000027],
            [-80.798232238999958, 35.441237928000078],
            [-80.798245239999972, 35.441267929000048],
            [-80.798281239999937, 35.441317929000036],
            [-80.798347239999941, 35.441362929000036],
            [-80.798404240999957, 35.441394929000069],
            [-80.798486238999942, 35.441489928000067],
            [-80.798535240999968, 35.441530928000077],
            [-80.798608239999965, 35.441579929000056],
            [-80.798638240999935, 35.441592929000024],
            [-80.798693240999967, 35.441616929000077],
            [-80.798979240999984, 35.441715929000054],
            [-80.799074239999982, 35.441729928000029],
            [-80.799182240999983, 35.441736929000058],
            [-80.799366240999973, 35.441757929000062],
            [-80.799472240999989, 35.441757929000062],
            [-80.799634240999978, 35.441781928000069],
            [-80.799813240999981, 35.441798929000072],
            [-80.799854240999935, 35.441799929000069],
            [-80.799959239999964, 35.441800928000077],
            [-80.800082240999984, 35.441808929000047],
            [-80.800211239999953, 35.441828929000053],
            [-80.80042924199995, 35.441836929000033],
            [-80.800504240999942, 35.441858928000045],
            [-80.800622240999985, 35.441904928000042],
            [-80.800668240999983, 35.441907928000035],
            [-80.800697240999966, 35.441898928000057],
            [-80.800736240999981, 35.441899928000055],
            [-80.800795240999946, 35.441893929000059],
            [-80.800876241999958, 35.441878928000051],
            [-80.800979240999936, 35.441871929000058],
            [-80.801067240999942, 35.441885928000033],
            [-80.80118024099994, 35.441916929000058],
            [-80.801333240999952, 35.44197992800008],
            [-80.801535240999954, 35.442048929000066],
            [-80.801599240999963, 35.44207792900005],
            [-80.801659241999971, 35.442112928000029],
            [-80.801734240999963, 35.442170929000042],
            [-80.801624241999946, 35.442241929000033],
            [-80.801465241999949, 35.442311929000027],
            [-80.801007240999979, 35.442466928000044],
            [-80.800832240999966, 35.442536929000028],
            [-80.800729240999942, 35.442586928000026],
            [-80.800630241999954, 35.442641928000057],
            [-80.800511240999981, 35.44272292900007],
            [-80.800395241999979, 35.442813929000067],
            [-80.800232240999946, 35.442957928000055],
            [-80.800047239999969, 35.44313992900004],
            [-80.799473240999987, 35.443746930000032],
            [-80.798915239999985, 35.444311929000037],
            [-80.798808239999971, 35.444432929000072],
            [-80.79874523999996, 35.444516929000031],
            [-80.79865523999996, 35.444657929000073],
            [-80.798494239999968, 35.444981929000051],
            [-80.797555239999951, 35.447156930000062],
            [-80.797365239999976, 35.447605930000066],
            [-80.796973239999943, 35.448574930000063],
            [-80.79682324099997, 35.448923930000035],
            [-80.796631240999943, 35.449316930000066],
            [-80.796582239999964, 35.449402931000066],
            [-80.796350239999981, 35.449764930000072],
            [-80.796015238999985, 35.450243931000045],
            [-80.795560239999986, 35.45000893100007],
            [-80.795320239999967, 35.449898931000064],
            [-80.795118239999965, 35.449813931000051],
            [-80.79485023899997, 35.449710930000037],
            [-80.79466623899998, 35.449647931000072],
            [-80.794291238999961, 35.449539931000061],
            [-80.794003239999938, 35.449471930000072],
            [-80.791847238999935, 35.448984931000041],
            [-80.79148923799994, 35.448895931000038],
            [-80.791312237999989, 35.448845930000061],
            [-80.790889238999966, 35.448709930000064],
            [-80.790459237999983, 35.448540931000025],
            [-80.790059238999959, 35.448367931000064],
            [-80.789453237999965, 35.448105930000054],
            [-80.789332237999986, 35.448049930000025],
            [-80.787848236999935, 35.447406931000046],
            [-80.786688236999964, 35.446910930000058],
            [-80.786244236999948, 35.446732931000042],
            [-80.786040236999952, 35.446663931000046],
            [-80.785661236999943, 35.446557931000029],
            [-80.785479235999958, 35.446516931000076],
            [-80.785191235999946, 35.446465930000045],
            [-80.785064236999972, 35.446447930000033],
            [-80.783836236999946, 35.446321931000057],
            [-80.783541235999962, 35.446283931000039],
            [-80.783331235999981, 35.446244930000034],
            [-80.782997235999972, 35.446164930000066],
            [-80.782748235999975, 35.446092930000077],
            [-80.782519234999938, 35.446015930000044],
            [-80.782274235999978, 35.445922930000052],
            [-80.781961235999972, 35.445772930000032],
            [-80.781708235999986, 35.445639931000073],
            [-80.781431234999957, 35.445462930000076],
            [-80.78118623599994, 35.445281930000078],
            [-80.780984234999949, 35.445131930000059],
            [-80.780575234999958, 35.444815930000061],
            [-80.780383234999988, 35.444673930000079],
            [-80.780058234999956, 35.444457930000056],
            [-80.77989123499998, 35.444364930000063],
            [-80.779549234999934, 35.444196930000032],
            [-80.779312234999963, 35.444099931000039],
            [-80.778974234999964, 35.443977930000074],
            [-80.778884234999964, 35.443945929000051],
            [-80.778637233999973, 35.443863930000077],
            [-80.77721223399999, 35.443388930000026],
            [-80.776666232999958, 35.443206929000041],
            [-80.775731232999988, 35.442894929000033],
            [-80.775266233999957, 35.442738929000029],
            [-80.770722231999969, 35.441224930000033],
            [-80.770186231999958, 35.441055930000061],
            [-80.769798231999971, 35.44095193000004],
            [-80.769598231999964, 35.440907929000048],
            [-80.769294230999947, 35.440850929000078],
            [-80.76893523199999, 35.440797930000031],
            [-80.768610230999968, 35.440765930000055],
            [-80.768360230999974, 35.440746929000056],
            [-80.768020231999969, 35.440741930000058],
            [-80.767766231999985, 35.440741929000069],
            [-80.767373230999965, 35.440765930000055],
            [-80.765654229999939, 35.440911929000038],
            [-80.764912229999936, 35.440974930000039],
            [-80.764915230999975, 35.440980929000034],
            [-80.765434229999983, 35.442026930000054],
            [-80.766182230999959, 35.443530930000065],
            [-80.767076230999976, 35.445330930000068],
            [-80.767080230999966, 35.445337930000051],
            [-80.767084230999956, 35.445346931000074],
            [-80.76709723099998, 35.445373930000073],
            [-80.767441230999964, 35.446054930000059],
            [-80.770616232999942, 35.452459932000068],
            [-80.770767232999958, 35.45276393100005],
            [-80.771021232999942, 35.453290932000073],
            [-80.771253232999982, 35.453771932000052],
            [-80.771632232999934, 35.454558932000054],
            [-80.772765232999973, 35.456999933000077],
            [-80.77286023299996, 35.457344932000069],
            [-80.773052233999977, 35.457265932000041],
            [-80.773244233999947, 35.457225933000075],
            [-80.773662232999982, 35.457177933000025],
            [-80.774234232999959, 35.45704093300003],
            [-80.774754232999953, 35.456932933000076],
            [-80.775382233999949, 35.456937932000073],
            [-80.775680234999982, 35.457004933000064],
            [-80.776277234999952, 35.457195933000037],
            [-80.776876234999975, 35.457486933000041],
            [-80.777633234999939, 35.45783193300008],
            [-80.778479234999963, 35.458275933000039],
            [-80.778969235999966, 35.458368933000031],
            [-80.779324235999979, 35.458634932000052],
            [-80.779376234999972, 35.458704933000035],
            [-80.77964723499997, 35.459071933000075],
            [-80.779760235999959, 35.459258933000058],
            [-80.779810235999946, 35.459340933000078],
            [-80.780026235999969, 35.459667933000048],
            [-80.780080235999947, 35.459749933000069],
            [-80.780227235999973, 35.460104933000025],
            [-80.78065623599997, 35.460582933000069],
            [-80.780960235999942, 35.460906934000036],
            [-80.781332236999958, 35.461200934000033],
            [-80.781845235999981, 35.461563934000026],
            [-80.782499235999978, 35.461939934000043],
            [-80.783205236999947, 35.462370934000035],
            [-80.783396236999977, 35.462515933000077],
            [-80.783825235999984, 35.462789933000067],
            [-80.784108236999941, 35.462985934000073],
            [-80.784355236999943, 35.463123934000066],
            [-80.784533237999938, 35.463306934000059],
            [-80.785219237999968, 35.463567934000025],
            [-80.785664237999981, 35.463713934000054],
            [-80.785851236999974, 35.463772934000076],
            [-80.786019237999938, 35.463852934000045],
            [-80.786137237999981, 35.463912934000064],
            [-80.78662623799994, 35.464145934000044],
            [-80.787282237999989, 35.464635934000057],
            [-80.787776237999935, 35.464899934000073],
            [-80.78804723799999, 35.46530993500005],
            [-80.788331238999945, 35.465575934000071],
            [-80.788582237999947, 35.465871934000063],
            [-80.78906423899997, 35.466420935000031],
            [-80.789458237999952, 35.466899934000025],
            [-80.789571239999987, 35.467008935000024],
            [-80.789654238999958, 35.467081934000078],
            [-80.78983723999994, 35.467478935000031],
            [-80.790246238999941, 35.467828935000057],
            [-80.790379238999947, 35.467904934000046],
            [-80.790464239999949, 35.467951934000041],
            [-80.79044523999994, 35.468013935000045],
            [-80.79045623899998, 35.468046935000075],
            [-80.79049723899999, 35.468126935000043],
            [-80.790584239999987, 35.468382934000033],
            [-80.790692239999942, 35.468756935000044],
            [-80.790785238999945, 35.469026934000055],
            [-80.790879239999981, 35.469275935000041],
            [-80.79090623899998, 35.469375934000027],
            [-80.790907239999967, 35.469426935000058],
            [-80.790926238999987, 35.469509935000076],
            [-80.791010239999935, 35.46960593600005],
            [-80.791025239999954, 35.469659935000038],
            [-80.791025239999954, 35.469712935000075],
            [-80.791032239999936, 35.469780935000074],
            [-80.791074239999944, 35.469935935000024],
            [-80.791110239999966, 35.470002935000025],
            [-80.791145238999945, 35.470049935000077],
            [-80.791206239999951, 35.470170935000056],
            [-80.791232238999953, 35.470248935000029],
            [-80.791363239999953, 35.470438935000061],
            [-80.791488239999978, 35.470595935000063],
            [-80.791513238999983, 35.470643935000055],
            [-80.791533239999978, 35.470701935000079],
            [-80.791579239999976, 35.470769935000078],
            [-80.791731238999944, 35.470963935000043],
            [-80.791777239999988, 35.471001935000061],
            [-80.79182123999999, 35.471049936000043],
            [-80.791852239999969, 35.471103935000031],
            [-80.791871239999978, 35.471194935000028],
            [-80.791900239999961, 35.471255935000045],
            [-80.79196823999996, 35.471364935000054],
            [-80.791970239999955, 35.471382936000055],
            [-80.791996239999946, 35.471582935000072],
            [-80.792015238999966, 35.471688935000032],
            [-80.792055239999968, 35.471765935000064],
            [-80.792086239999946, 35.471850936000067],
            [-80.792117239999982, 35.471917935000079],
            [-80.79215623999994, 35.471987936000062],
            [-80.792186239999978, 35.472087935000047],
            [-80.792195239999955, 35.472199936000038],
            [-80.792239239999958, 35.472375936000049],
            [-80.792283239999961, 35.472509936000051],
            [-80.792299240999967, 35.472577935000061],
            [-80.792316239999934, 35.472625936000043],
            [-80.792342240999972, 35.472680936000074],
            [-80.792364238999937, 35.47275793600005],
            [-80.792401239999947, 35.472890935000066],
            [-80.792421239999953, 35.473098936000042],
            [-80.79246724099994, 35.473241936000079],
            [-80.792484239999965, 35.473274936000053],
            [-80.792498240999976, 35.473336935000077],
            [-80.792500239999981, 35.473398935000034],
            [-80.79251524099999, 35.473479936000047],
            [-80.79258023999995, 35.473647936000077],
            [-80.792616238999983, 35.473718936000068],
            [-80.792659239999978, 35.473780936000026],
            [-80.792766240999981, 35.473968935000073],
            [-80.792818239999974, 35.474041935000059],
            [-80.792978239999968, 35.47420293600004],
            [-80.793015240999978, 35.474271936000036],
            [-80.793062240999973, 35.474332935000064],
            [-80.793158240999958, 35.474415936000071],
            [-80.79322123999998, 35.474491936000049],
            [-80.793245239999976, 35.474513935000061],
            [-80.793486239999936, 35.474879935000047],
            [-80.793646240999976, 35.475014936000036],
            [-80.793733240999984, 35.475107937000075],
            [-80.794012240999962, 35.475389936000056],
            [-80.794194241999946, 35.475589936000063],
            [-80.794299240999976, 35.475712937000026],
            [-80.794686240999965, 35.476105936000067],
            [-80.794859240999983, 35.476257936000025],
            [-80.794930240999975, 35.476363936000041],
            [-80.795092240999963, 35.47653293500008],
            [-80.79516124099996, 35.476621936000072],
            [-80.795234240999946, 35.476743936000048],
            [-80.795298240999955, 35.476822937000065],
            [-80.795355240999982, 35.476855937000039],
            [-80.795392240999945, 35.476884936000033],
            [-80.795448240999974, 35.476999937000073],
            [-80.795541240999967, 35.477116937000062],
            [-80.795632240999964, 35.477182936000077],
            [-80.795696241999963, 35.477258936000055],
            [-80.795766240999967, 35.477374936000047],
            [-80.79583224199996, 35.477464937000036],
            [-80.795932241999935, 35.477561937000075],
            [-80.796111241999938, 35.47775593700004],
            [-80.796160240999939, 35.477836937000063],
            [-80.796459241999969, 35.478222936000066],
            [-80.796553241999959, 35.478358936000063],
            [-80.796632241999987, 35.478440937000073],
            [-80.796683241999972, 35.478518937000047],
            [-80.796714240999961, 35.478576937000071],
            [-80.796782241999949, 35.478663936000032],
            [-80.796824241999957, 35.478726937000033],
            [-80.796964241999945, 35.47886993700007],
            [-80.797080241999936, 35.479023937000079],
            [-80.797227241999963, 35.479183938000062],
            [-80.797321241999953, 35.479311937000034],
            [-80.797432241999957, 35.479430937000075],
            [-80.797519242999954, 35.479504937000058],
            [-80.797570241999949, 35.47957593600006],
            [-80.797609241999965, 35.479660936000073],
            [-80.79764824099999, 35.47971493700004],
            [-80.797898241999974, 35.480023936000066],
            [-80.797935241999937, 35.480082937000077],
            [-80.798018241999955, 35.480188937000037],
            [-80.798109241999953, 35.480291937000061],
            [-80.798157241999945, 35.480338936000066],
            [-80.79820024199995, 35.480396936000034],
            [-80.798222241999952, 35.48045393700005],
            [-80.798265241999957, 35.480523937000044],
            [-80.798330241999963, 35.48059293600005],
            [-80.798359242999936, 35.480611936000059],
            [-80.798420242999953, 35.480700938000041],
            [-80.798634242999981, 35.480927937000047],
            [-80.798720242999934, 35.48104993700008],
            [-80.798839242999975, 35.481235936000076],
            [-80.798896241999955, 35.481302937000066],
            [-80.798954242999969, 35.481356937000044],
            [-80.799038242999984, 35.481459937000068],
            [-80.799108242999978, 35.481554937000055],
            [-80.799203242999965, 35.48165493700003],
            [-80.799307241999941, 35.481791937000025],
            [-80.799400243999969, 35.481928937000077],
            [-80.799647242999981, 35.482215937000035],
            [-80.800059242999964, 35.482729938000034],
            [-80.800322241999936, 35.48307993800006],
            [-80.800453243999982, 35.483223937000048],
            [-80.800558242999955, 35.483354938000048],
            [-80.800619242999971, 35.483422938000047],
            [-80.800685243999965, 35.483511938000049],
            [-80.800748242999987, 35.483571938000068],
            [-80.800921242999948, 35.483702938000079],
            [-80.800937243999954, 35.483765938000033],
            [-80.80099824399997, 35.483902938000028],
            [-80.801097242999958, 35.484046937000073],
            [-80.80125824299995, 35.484247937000077],
            [-80.801419243999987, 35.484492938000074],
            [-80.80152424399995, 35.48466793700004],
            [-80.801605243999973, 35.484781937000037],
            [-80.801619243999937, 35.48480293700004],
            [-80.801801242999943, 35.485097938000024],
            [-80.802000243999942, 35.485377938000056],
            [-80.80208324299997, 35.485519939000028],
            [-80.802175242999965, 35.485651938000046],
            [-80.802245243999948, 35.485760938000055],
            [-80.802328242999977, 35.485922938000044],
            [-80.802475243999936, 35.486162938000064],
            [-80.802581242999963, 35.486322938000058],
            [-80.80267224399995, 35.486473938000074],
            [-80.802764243999945, 35.486587938000071],
            [-80.802864243999977, 35.486697938000077],
            [-80.802959243999965, 35.486825938000038],
            [-80.80300224399997, 35.486950938000064],
            [-80.803029243999958, 35.487044937000064],
            [-80.803123243999948, 35.487153939000052],
            [-80.803205242999979, 35.487231938000036],
            [-80.803297244999953, 35.487331938000068],
            [-80.803353244999983, 35.48742893900004],
            [-80.803422243999989, 35.487562938000053],
            [-80.803488244999983, 35.487714938000067],
            [-80.803525243999957, 35.487883938000039],
            [-80.803579243999934, 35.48808593800004],
            [-80.803623243999937, 35.48822793800008],
            [-80.803668243999937, 35.488295938000078],
            [-80.803754244999936, 35.488453938000077],
            [-80.80382024499994, 35.488599939000039],
            [-80.80395824499999, 35.488946938000026],
            [-80.80397824399995, 35.489006939000035],
            [-80.804004244999987, 35.489047939000045],
            [-80.804086243999961, 35.489227939000045],
            [-80.804119244999981, 35.489285939000069],
            [-80.804153243999963, 35.489385938000055],
            [-80.804185243999939, 35.48952293900004],
            [-80.804247244999942, 35.489597939000078],
            [-80.80427324499999, 35.489639938000039],
            [-80.80428524499996, 35.489689938000026],
            [-80.804287244999955, 35.489746939000042],
            [-80.804312244999949, 35.489806939000061],
            [-80.804347244999974, 35.489850939000064],
            [-80.804381243999956, 35.489885939000033],
            [-80.80442824499994, 35.49001393900005],
            [-80.804433245999974, 35.490080939000052],
            [-80.804457243999934, 35.490149938000059],
            [-80.80450624499997, 35.490214939000055],
            [-80.804633243999945, 35.490516938000042],
            [-80.80470924399998, 35.490743939000026],
            [-80.804767244999937, 35.490876939000032],
            [-80.804812244999937, 35.490954939000062],
            [-80.804833244999941, 35.491073939000046],
            [-80.804911244999971, 35.49137293900003],
            [-80.804985244999955, 35.491606939000064],
            [-80.805353244999935, 35.491740939000067],
            [-80.805619244999946, 35.491815939000048],
            [-80.805830245999971, 35.491881938000063],
            [-80.805975244999956, 35.491910939000036],
            [-80.806091244999948, 35.49196093900008],
            [-80.806247244999952, 35.492015939000055],
            [-80.806491244999961, 35.492082939000056],
            [-80.806727244999934, 35.492132940000033],
            [-80.806978245999971, 35.492214938000075],
            [-80.807275245999961, 35.492267940000033],
            [-80.807374245999938, 35.492275939000024],
            [-80.807497245999969, 35.492278939000073],
            [-80.80761324599996, 35.492309939000052],
            [-80.807704245999957, 35.492338939000035],
            [-80.807901245999972, 35.492333939000048],
            [-80.807926244999976, 35.492334939000045],
            [-80.806158245999939, 35.49425093900004],
            [-80.80230024399998, 35.498463941000068],
            [-80.803292244999966, 35.499434941000061],
            [-80.804014244999962, 35.500142941000036],
            [-80.80491924599994, 35.500891941000077],
            [-80.805428244999973, 35.501505941000062],
            [-80.808554246999961, 35.505273942000031],
            [-80.80847524699999, 35.505324942000072],
            [-80.808023246999937, 35.505694942000048],
            [-80.807775246999938, 35.505882942000028],
            [-80.807627246999971, 35.505965942000046],
            [-80.80745924699994, 35.506035943000029],
            [-80.807225245999973, 35.50610994200008],
            [-80.806913245999965, 35.506168942000045],
            [-80.806557246999944, 35.50620394200007],
            [-80.806181245999937, 35.506216942000037],
            [-80.805926245999956, 35.506214942000042],
            [-80.805668244999936, 35.506195942000033],
            [-80.805352245999984, 35.506147942000041],
            [-80.804925244999936, 35.506065942000077],
            [-80.804550245999962, 35.506024942000067],
            [-80.804311245999941, 35.50601994200008],
            [-80.804157245999988, 35.506028942000057],
            [-80.804010245999962, 35.50605394300004],
            [-80.803817244999948, 35.506088943000066],
            [-80.802079244999959, 35.506557943000075],
            [-80.801829244999965, 35.506630943000062],
            [-80.801677244999951, 35.506690942000034],
            [-80.801566244999947, 35.50676294200008],
            [-80.80140724499995, 35.506926943000053],
            [-80.801276243999951, 35.507104943000058],
            [-80.801111244999959, 35.50709394200004],
            [-80.79665724299997, 35.50709394200004],
            [-80.796482242999957, 35.507094942000037],
            [-80.796621242999947, 35.507165943000075],
            [-80.796753242999955, 35.507216942000071],
            [-80.797104242999978, 35.507364943000027],
            [-80.797357242999965, 35.507527943000071],
            [-80.797564243999943, 35.507721943000035],
            [-80.797841243999983, 35.508089943000073],
            [-80.798148243999947, 35.508479943000054],
            [-80.798549243999958, 35.509105943000066],
            [-80.798702243999969, 35.509421943000063],
            [-80.798794243999964, 35.509730943000079],
            [-80.798850243999937, 35.510104943000044],
            [-80.798847243999944, 35.510478943000066],
            [-80.798805243999936, 35.511800943000026],
            [-80.798752243999957, 35.513496944000053],
            [-80.798738244999981, 35.514567944000078],
            [-80.798761243999934, 35.514918944000044],
            [-80.798763243999986, 35.514949945000069],
            [-80.798973243999967, 35.515851944000076],
            [-80.799312244999953, 35.517124945000035],
            [-80.799357244999953, 35.51738494500006],
            [-80.799354244999961, 35.517533944000036],
            [-80.799353243999974, 35.517647944000032],
            [-80.799301244999981, 35.51790694500005],
            [-80.799245244999952, 35.518088945000045],
            [-80.798966244999974, 35.51901394500004]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 77,
        "SolutionID": "PI-29:L",
        "Shape_Length": 1.0110457878193357,
        "Shape_Area": 0.026569017362350882
      }
    },
    {
      "type": "Feature",
      "id": 78,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.797538249999945, 35.626347967000072],
            [-80.797167249999973, 35.626824968000051],
            [-80.794627249999962, 35.630095968000035],
            [-80.793573248999962, 35.631453969000063],
            [-80.79317924999998, 35.631961969000031],
            [-80.792655248999949, 35.632635970000024],
            [-80.791064247999941, 35.634599970000068],
            [-80.790859248999936, 35.634851970000057],
            [-80.790150248999964, 35.635698970000078],
            [-80.789974247999965, 35.635901970000077],
            [-80.789846247999947, 35.636049970000045],
            [-80.789689248999935, 35.636249970000051],
            [-80.788830248999943, 35.63738397000003],
            [-80.788456247999989, 35.637876970000036],
            [-80.788073247999989, 35.638402970000072],
            [-80.787824247999936, 35.638682971000037],
            [-80.787584246999984, 35.638936971000078],
            [-80.787020246999987, 35.639338971000029],
            [-80.786669246999963, 35.639588970000034],
            [-80.785770247999949, 35.640209971000047],
            [-80.785010246999946, 35.640725971000052],
            [-80.784033246999968, 35.641335972000036],
            [-80.783336245999976, 35.64175397200006],
            [-80.782984246999945, 35.641930972000068],
            [-80.782613245999983, 35.642081972000028],
            [-80.782089245999941, 35.642280972000037],
            [-80.782241245999955, 35.642560972000069],
            [-80.782656246999977, 35.643256972000074],
            [-80.782836246999977, 35.643559972000048],
            [-80.783048246999954, 35.644018972000026],
            [-80.783196245999989, 35.644477972000061],
            [-80.783306246999985, 35.645088972000053],
            [-80.783341246999953, 35.645568973000024],
            [-80.783318245999965, 35.646594973000049],
            [-80.784882246999985, 35.646617973000048],
            [-80.78551424799997, 35.646720973000072],
            [-80.786010247999968, 35.646890973000041],
            [-80.786712247999958, 35.647229974000027],
            [-80.78760424799998, 35.647803973000066],
            [-80.788155248999942, 35.648168972000065],
            [-80.788284248999958, 35.648254973000064],
            [-80.788696247999951, 35.648610973000075],
            [-80.789044249999961, 35.649020974000052],
            [-80.789503248999949, 35.64971997300006],
            [-80.790036249999957, 35.65046297400005],
            [-80.790294249999988, 35.650775974000055],
            [-80.79063924899998, 35.651066974000059],
            [-80.790932249999969, 35.651236974000028],
            [-80.790983249999954, 35.651265974000069],
            [-80.791414249999946, 35.651410973000054],
            [-80.791900249999969, 35.651509973000032],
            [-80.792301250999969, 35.651525973000048],
            [-80.793920250999975, 35.651605973000073],
            [-80.795796250999956, 35.651708973000041],
            [-80.796274250999943, 35.651774973000045],
            [-80.796810251999943, 35.651924973000064],
            [-80.797364251999966, 35.652194974000054],
            [-80.798383251999951, 35.652808973000049],
            [-80.799057251999955, 35.653213974000039],
            [-80.799337252999976, 35.653382973000078],
            [-80.799911251999958, 35.653782973000034],
            [-80.800560252999958, 35.654267974000049],
            [-80.80085625199996, 35.654513974000054],
            [-80.801335252999934, 35.654994974000033],
            [-80.801527252999961, 35.655287974000032],
            [-80.801647251999952, 35.655589974000065],
            [-80.801773252999965, 35.655943974000024],
            [-80.80263725399999, 35.658361975000048],
            [-80.803037253999946, 35.659269975000029],
            [-80.803468252999949, 35.658966974000066],
            [-80.806310253999982, 35.656971974000044],
            [-80.806594254999936, 35.656736974000069],
            [-80.806824254999981, 35.656503974000032],
            [-80.807006254999976, 35.656251974000043],
            [-80.807148253999969, 35.655955974000051],
            [-80.807482254999968, 35.65513397400008],
            [-80.807704254999976, 35.654542974000037],
            [-80.808107254999982, 35.653673973000025],
            [-80.808794254999952, 35.652220974000045],
            [-80.808911254999941, 35.651835973000061],
            [-80.808982253999943, 35.651489973000025],
            [-80.808990254999969, 35.651182973000061],
            [-80.809028254999987, 35.650428972000043],
            [-80.808981254999935, 35.649700972000062],
            [-80.808991254999967, 35.648748972000078],
            [-80.809017253999968, 35.648167972000067],
            [-80.809081254999967, 35.647527972000034],
            [-80.809213254999975, 35.646439972000053],
            [-80.809250255999984, 35.645998972000029],
            [-80.809240254999963, 35.645519972000045],
            [-80.809202254999946, 35.645120972000029],
            [-80.809199253999964, 35.645085971000071],
            [-80.809084254999959, 35.644411971000068],
            [-80.809000253999955, 35.643919971000059],
            [-80.808833254999968, 35.643072971000038],
            [-80.808706253999958, 35.641605971000047],
            [-80.808669254999984, 35.641331971000056],
            [-80.808649253999988, 35.640814970000065],
            [-80.808653254999967, 35.640296971000055],
            [-80.808659253999963, 35.640240970000036],
            [-80.808697253999981, 35.639895970000055],
            [-80.808710253999948, 35.639785970000048],
            [-80.808777253999949, 35.639183970000033],
            [-80.808857254999964, 35.638192970000034],
            [-80.809119254999985, 35.637167969000075],
            [-80.809217253999975, 35.636788970000055],
            [-80.809389252999949, 35.636248970000054],
            [-80.809430253999949, 35.636122970000031],
            [-80.809967254999947, 35.634437969000032],
            [-80.810036253999954, 35.634221969000066],
            [-80.810196254999937, 35.633669969000039],
            [-80.810454254999968, 35.632988969000053],
            [-80.810747254999967, 35.632428968000056],
            [-80.810800253999957, 35.632346969000025],
            [-80.810921254999982, 35.632163969000032],
            [-80.811213254999984, 35.63193596900004],
            [-80.811668254999972, 35.631654969000067],
            [-80.812058254999954, 35.631426969000074],
            [-80.812835254999982, 35.630979969000066],
            [-80.813322254999946, 35.630716968000058],
            [-80.81366225499994, 35.630545968000035],
            [-80.813981254999987, 35.630438969000068],
            [-80.814323254999977, 35.630337968000049],
            [-80.814417255999956, 35.630317969000032],
            [-80.814836254999989, 35.630227968000042],
            [-80.81545925599994, 35.630084968000062],
            [-80.815855255999963, 35.629937968000036],
            [-80.816265255999951, 35.629739967000035],
            [-80.81645025499995, 35.629628968000077],
            [-80.816770256999973, 35.629415968000046],
            [-80.816813255999989, 35.629386967000073],
            [-80.817136256999959, 35.629139968000061],
            [-80.817489255999988, 35.628840967000031],
            [-80.81763925599995, 35.62873096800007],
            [-80.818058256999961, 35.628422967000063],
            [-80.818396256999961, 35.628136968000035],
            [-80.81860325599996, 35.62794296800007],
            [-80.818940256999952, 35.627592967000055],
            [-80.81898625599996, 35.62754196700007],
            [-80.819535256999984, 35.62692596800008],
            [-80.82007725699998, 35.626348967000069],
            [-80.820262256999968, 35.62619896700005],
            [-80.820486255999981, 35.626080967000064],
            [-80.820530256999973, 35.626065968000034],
            [-80.820750256999986, 35.625987967000071],
            [-80.821077256999956, 35.625937967000027],
            [-80.821272256999976, 35.625938967000025],
            [-80.821633257999963, 35.625942966000025],
            [-80.821697257999972, 35.625944967000066],
            [-80.822165256999938, 35.625959967000028],
            [-80.822501256999942, 35.625948967000056],
            [-80.822806256999968, 35.625905967000051],
            [-80.822968257999946, 35.62583896700005],
            [-80.823030256999971, 35.625812967000059],
            [-80.823192257999949, 35.625722967000058],
            [-80.823254257999963, 35.625687967000033],
            [-80.823586257999978, 35.625472967000064],
            [-80.82381825799996, 35.625372966000043],
            [-80.824199257999965, 35.625369966000051],
            [-80.824042257999963, 35.625932968000029],
            [-80.823940256999947, 35.626503967000076],
            [-80.823892257999944, 35.627078968000035],
            [-80.823899257999983, 35.627655967000067],
            [-80.823962257999938, 35.628230968000025],
            [-80.824170256999935, 35.629556968000031],
            [-80.824247257999957, 35.630045968000047],
            [-80.824363258999938, 35.63078796800005],
            [-80.824451258999943, 35.631346968000059],
            [-80.824517257999958, 35.63165796800007],
            [-80.824618257999987, 35.631963968000036],
            [-80.824782257999971, 35.632384969000043],
            [-80.824976258999982, 35.63279796900008],
            [-80.825229258999968, 35.633188969000059],
            [-80.825537258999987, 35.63355296900005],
            [-80.825727258999962, 35.633759968000049],
            [-80.825850258999935, 35.633893968000052],
            [-80.825959258999944, 35.633991968000032],
            [-80.826201258999959, 35.634208969000042],
            [-80.826587257999961, 35.634495968000067],
            [-80.827005258999975, 35.634750968000048],
            [-80.828022259999955, 35.635313969000038],
            [-80.829737260999934, 35.636177969000073],
            [-80.831265261999988, 35.636947969000062],
            [-80.831722260999982, 35.637178969000047],
            [-80.834923261999961, 35.638797969000052],
            [-80.835476262999975, 35.63910596900007],
            [-80.835890261999964, 35.639361969000049],
            [-80.836225261999971, 35.639582970000049],
            [-80.836622262999981, 35.639868969000077],
            [-80.836823262999985, 35.640022970000075],
            [-80.837125261999972, 35.639734970000063],
            [-80.837926262999986, 35.638954969000054],
            [-80.838166262999948, 35.638653969000075],
            [-80.838569262999954, 35.638204969000071],
            [-80.837736262999954, 35.637913969000067],
            [-80.837293263999982, 35.637596968000025],
            [-80.836961262999978, 35.637210968000034],
            [-80.83682526299998, 35.63689296900003],
            [-80.83683726199996, 35.635960968000063],
            [-80.837164262999977, 35.635313969000038],
            [-80.837209261999988, 35.635302968000076],
            [-80.837462262999964, 35.635073969000075],
            [-80.837742261999949, 35.634638968000047],
            [-80.837770262999982, 35.633859968000024],
            [-80.83810626199994, 35.633218968000051],
            [-80.838134261999983, 35.632325968000032],
            [-80.83844226299999, 35.631615967000073],
            [-80.838498262999963, 35.630905968000036],
            [-80.839171262999969, 35.629965967000032],
            [-80.839451262999944, 35.629187967000064],
            [-80.840040262999935, 35.628454967000039],
            [-80.840657262999969, 35.627400966000039],
            [-80.841062262999969, 35.627193967000039],
            [-80.842003262999981, 35.626713966000068],
            [-80.843013262999989, 35.626323966000029],
            [-80.843405263999955, 35.626437967000072],
            [-80.843910263999987, 35.626369966000027],
            [-80.844443264999938, 35.626804966000066],
            [-80.844892264999942, 35.62694196700005],
            [-80.845089263999967, 35.62719396600005],
            [-80.845201264999957, 35.627605966000033],
            [-80.845493264999959, 35.627784967000025],
            [-80.845987265999952, 35.628086966000069],
            [-80.846576264999953, 35.628154967000057],
            [-80.847305264999989, 35.628017966000073],
            [-80.84783826599994, 35.627809966000029],
            [-80.848399265999944, 35.627443967000033],
            [-80.849184265999952, 35.627259967000043],
            [-80.849745265999957, 35.626984967000055],
            [-80.850222266999936, 35.626892966000071],
            [-80.850326264999978, 35.626916966000067],
            [-80.850727266999968, 35.627007966000065],
            [-80.851175266999974, 35.626961967000057],
            [-80.851596265999945, 35.62714496600006],
            [-80.85190526599996, 35.627167966000059],
            [-80.852083266999955, 35.627047966000077],
            [-80.852222265999956, 35.626953966000031],
            [-80.852858265999942, 35.626525966000031],
            [-80.853840266999953, 35.626731966000079],
            [-80.854317267999988, 35.627143965000073],
            [-80.854317267999988, 35.627441966000049],
            [-80.854626266999958, 35.62764796600004],
            [-80.855103266999947, 35.627784966000036],
            [-80.856758267999965, 35.627761966000037],
            [-80.858020267999962, 35.627875966000033],
            [-80.859086268999988, 35.627805966000039],
            [-80.859507268999948, 35.628080966000027],
            [-80.860040268999967, 35.628607966000061],
            [-80.860377268999969, 35.628721967000047],
            [-80.860658268999941, 35.628996965000056],
            [-80.861387269999966, 35.629408966000028],
            [-80.861724269999968, 35.629408966000028],
            [-80.861696268999935, 35.629110967000031],
            [-80.861527268999964, 35.628537966000067],
            [-80.862046268999961, 35.628482966000036],
            [-80.86238426999995, 35.628447966000067],
            [-80.86220426899996, 35.627896966000037],
            [-80.861904269999968, 35.627117966000071],
            [-80.861563269999976, 35.626348965000034],
            [-80.86105226899997, 35.62526596500004],
            [-80.860435268999936, 35.623983965000036],
            [-80.859050267999976, 35.621109964000027],
            [-80.858616267999935, 35.620219965000047],
            [-80.858222267999963, 35.61931796500005],
            [-80.85786726799995, 35.618404965000025],
            [-80.857648267999934, 35.61760696500005],
            [-80.85749526799998, 35.616798963000065],
            [-80.857409267999969, 35.615983964000065],
            [-80.857404266999936, 35.615849964000063],
            [-80.857395267999948, 35.615631963000055],
            [-80.857387267999968, 35.615278963000037],
            [-80.857415267999954, 35.614588963000074],
            [-80.857484266999961, 35.613900964000038],
            [-80.85759326799996, 35.613215964000062],
            [-80.857844267999951, 35.611846962000072],
            [-80.857964267999989, 35.611159962000045],
            [-80.858404266999969, 35.608766962000061],
            [-80.858583267999961, 35.607736962000047],
            [-80.858626267999966, 35.607498962000079],
            [-80.858781267999973, 35.606648961000076],
            [-80.85887626799996, 35.606128961000024],
            [-80.858946267999954, 35.605744962000074],
            [-80.859142266999982, 35.604623961000073],
            [-80.859186266999984, 35.604374961000076],
            [-80.859623266999961, 35.601958960000047],
            [-80.859737267999947, 35.601332960000036],
            [-80.859881267999981, 35.600533960000064],
            [-80.85992026699995, 35.600317960000041],
            [-80.859942266999951, 35.600196960000062],
            [-80.860087266999983, 35.59934996000004],
            [-80.860201266999979, 35.598435960000074],
            [-80.86024226699999, 35.59791596000008],
            [-80.860036266999941, 35.597577960000024],
            [-80.859989266999946, 35.597501959000056],
            [-80.85971726799994, 35.597055960000034],
            [-80.859182265999948, 35.596964959000047],
            [-80.859122266999975, 35.596957959000065],
            [-80.858495265999977, 35.596882959000027],
            [-80.858099266999943, 35.596875960000034],
            [-80.857656265999935, 35.596915959000057],
            [-80.857088265999948, 35.597020959000076],
            [-80.856837265999957, 35.597066960000063],
            [-80.856413265999947, 35.597138960000052],
            [-80.85401126499994, 35.597547960000043],
            [-80.853614264999976, 35.597615961000031],
            [-80.853360264999935, 35.59765795900006],
            [-80.853067265999982, 35.597706960000039],
            [-80.852130265999961, 35.597862960000043],
            [-80.851497263999988, 35.59797696000004],
            [-80.851082264999945, 35.598050959000034],
            [-80.85100926399997, 35.598061960000052],
            [-80.850782264999964, 35.59809996000007],
            [-80.849611263999975, 35.598290960000043],
            [-80.848863262999942, 35.598429960000033],
            [-80.848495263999951, 35.598498960000029],
            [-80.84809426399994, 35.598623960000054],
            [-80.847742263999976, 35.598747961000072],
            [-80.847378262999939, 35.598962961000041],
            [-80.847065262999934, 35.597857960000056],
            [-80.847013262999951, 35.597464959000035],
            [-80.847006262999969, 35.59741796000003],
            [-80.846973263999985, 35.597160960000053],
            [-80.846959262999974, 35.596695960000034],
            [-80.847410262999972, 35.594780959000047],
            [-80.847508263999941, 35.594320960000061],
            [-80.84759126299997, 35.593535959000064],
            [-80.847662262999961, 35.592866959000048],
            [-80.847709262999956, 35.592576959000041],
            [-80.84780826299999, 35.591952959000025],
            [-80.847924262999982, 35.591314959000044],
            [-80.848652262999963, 35.590042958000026],
            [-80.848867263999978, 35.589678959000025],
            [-80.849063262999948, 35.589343958000029],
            [-80.849520263999977, 35.58825495800005],
            [-80.850096263999944, 35.586989957000071],
            [-80.850478263999946, 35.586149958000078],
            [-80.850722263999955, 35.585724957000025],
            [-80.850771263999945, 35.585638958000061],
            [-80.850913263999985, 35.585392957000067],
            [-80.851216264999948, 35.584673957000064],
            [-80.851370263999968, 35.584235957000033],
            [-80.851921263999941, 35.582738956000071],
            [-80.853051263999987, 35.57965495600007],
            [-80.853115263999939, 35.579479956000057],
            [-80.854240265999977, 35.579783956000028],
            [-80.854866263999952, 35.579843956000047],
            [-80.855495264999945, 35.579857956000069],
            [-80.856176264999988, 35.579849956000032],
            [-80.856406264999976, 35.57984695600004],
            [-80.856291264999982, 35.579416956000046],
            [-80.856100264999952, 35.578571956000076],
            [-80.85596626499995, 35.577735956000026],
            [-80.855884264999986, 35.576894955000057],
            [-80.855860263999944, 35.576090955000041],
            [-80.855886263999935, 35.575286955000024],
            [-80.855930264999984, 35.57470995500006],
            [-80.856004264999967, 35.574135955000031],
            [-80.856036264999943, 35.573961955000073],
            [-80.856109265999976, 35.573564955000052],
            [-80.856315264999978, 35.572702954000079],
            [-80.856545264999966, 35.571844954000028],
            [-80.857805264999968, 35.567320953000035],
            [-80.858190264999962, 35.565940953000052],
            [-80.858417264999957, 35.565096952000033],
            [-80.858601265999937, 35.564256952000051],
            [-80.858738264999943, 35.563410953000073],
            [-80.858816264999973, 35.562575952000032],
            [-80.858835264999982, 35.561737951000055],
            [-80.858806263999952, 35.560895952000067],
            [-80.858803264999949, 35.560865952000029],
            [-80.858715263999954, 35.560055952000027],
            [-80.858594263999976, 35.559215952000045],
            [-80.858429264999984, 35.558015951000073],
            [-80.85824426399995, 35.556694951000054],
            [-80.858121264999966, 35.555858951000062],
            [-80.858012263999967, 35.555020951000074],
            [-80.857940263999978, 35.554280951000067],
            [-80.857909263999943, 35.553537950000077],
            [-80.857907263999948, 35.553400951000071],
            [-80.857815263999953, 35.553342950000058],
            [-80.857687263999935, 35.553266950000079],
            [-80.857192262999945, 35.55297395000008],
            [-80.856943263999938, 35.552862950000076],
            [-80.856814263999979, 35.55280595000005],
            [-80.856565263999983, 35.552721950000034],
            [-80.856387262999988, 35.552724950000027],
            [-80.856323263999968, 35.552725949000035],
            [-80.856065262999948, 35.552751950000072],
            [-80.855826262999983, 35.55280594900006],
            [-80.855750262999948, 35.552836951000074],
            [-80.855588262999959, 35.552903950000029],
            [-80.855431262999957, 35.553037950000032],
            [-80.855300262999947, 35.553184951000048],
            [-80.855226263999953, 35.553283951000026],
            [-80.854231263999964, 35.55483195000005],
            [-80.853220262999969, 35.556420951000064],
            [-80.852988262999986, 35.556782951000059],
            [-80.85185426299995, 35.558549952000078],
            [-80.851524262999988, 35.559075952000057],
            [-80.851128262999964, 35.559704952000061],
            [-80.850820261999957, 35.560195952000072],
            [-80.850397262999934, 35.560869952000076],
            [-80.84954626199999, 35.560000952000053],
            [-80.848957261999942, 35.55953295200004],
            [-80.847870260999969, 35.558813952000037],
            [-80.847758261999957, 35.558740951000061],
            [-80.847066260999952, 35.558295952000037],
            [-80.844986260999974, 35.556956952000064],
            [-80.844927260999953, 35.556919951000054],
            [-80.844263259999934, 35.556495951000045],
            [-80.843609260999983, 35.556079951000072],
            [-80.843255259999978, 35.555853951000074],
            [-80.842700259999958, 35.555528952000031],
            [-80.842598258999942, 35.55546895100008],
            [-80.842348258999948, 35.555348951000042],
            [-80.841956259999961, 35.555211951000047],
            [-80.841318258999934, 35.554988951000041],
            [-80.842014258999939, 35.554010951000066],
            [-80.842992258999971, 35.55263995100006],
            [-80.843709259999969, 35.551632951000045],
            [-80.845140259999937, 35.549636950000036],
            [-80.845444259999965, 35.54916895000008],
            [-80.845520259999944, 35.549055950000024],
            [-80.845750259999988, 35.548712950000038],
            [-80.846099260999949, 35.548042950000024],
            [-80.846170259999951, 35.547905950000029],
            [-80.846312259999934, 35.547396949000074],
            [-80.846329260999937, 35.547315950000041],
            [-80.846914260999938, 35.544508949000033],
            [-80.847037260999969, 35.543914948000065],
            [-80.847164259999943, 35.543309948000058],
            [-80.84718626099999, 35.543205949000026],
            [-80.844841258999963, 35.54293394900003],
            [-80.843306258999974, 35.542755949000025],
            [-80.842928258999962, 35.542690948000029],
            [-80.842416258999947, 35.542575949000025],
            [-80.84188525899998, 35.542390948000048],
            [-80.841461258999971, 35.542186948000051],
            [-80.841221258999951, 35.54205594900003],
            [-80.839996257999985, 35.541386949000071],
            [-80.839104257999963, 35.540898948000063],
            [-80.838679256999967, 35.54066694800008],
            [-80.837516256999947, 35.540031948000035],
            [-80.836538256999972, 35.539522948000069],
            [-80.835818256999971, 35.539148948000047],
            [-80.835524255999985, 35.538995948000036],
            [-80.834772255999951, 35.538589948000038],
            [-80.834267255999976, 35.538317948000042],
            [-80.833720256999982, 35.538065948000053],
            [-80.831704254999977, 35.537382947000026],
            [-80.831434254999976, 35.537287948000028],
            [-80.830289255999958, 35.536884948000079],
            [-80.829960254999946, 35.536769948000028],
            [-80.829544254999973, 35.536594948000072],
            [-80.829356254999936, 35.536493948000043],
            [-80.829153254999937, 35.536385948000031],
            [-80.828790254999944, 35.536143947000028],
            [-80.825585252999986, 35.533805948000065],
            [-80.825333253999986, 35.533669947000078],
            [-80.825007252999967, 35.533526947000041],
            [-80.82465625399999, 35.533434947000046],
            [-80.824289252999961, 35.533396947000028],
            [-80.823920252999983, 35.53341494700004],
            [-80.823562252999977, 35.533485948000077],
            [-80.822973252999986, 35.533651948000056],
            [-80.821921252999971, 35.534051948000069],
            [-80.822061252999958, 35.534273948000077],
            [-80.822210251999934, 35.534684948000063],
            [-80.822329252999964, 35.536182948000032],
            [-80.821096252999951, 35.538748948000034],
            [-80.820828251999956, 35.539452948000076],
            [-80.821253252999952, 35.541539949000025],
            [-80.821365252999954, 35.542204949000052],
            [-80.822348252999973, 35.544287949000079],
            [-80.822966252999947, 35.546004950000054],
            [-80.822884252999984, 35.546709950000036],
            [-80.822778253999957, 35.547632950000036],
            [-80.823126253999988, 35.548178951000068],
            [-80.825044254999966, 35.550400951000029],
            [-80.826111253999954, 35.552736952000032],
            [-80.827081254999939, 35.55919795300008],
            [-80.827387255999952, 35.56128495400003],
            [-80.827285255999982, 35.561169952000057],
            [-80.826845254999967, 35.560760953000056],
            [-80.826299253999935, 35.560393952000027],
            [-80.825990253999976, 35.56022695300004],
            [-80.825544254999954, 35.559986953000077],
            [-80.82469725499999, 35.559638953000047],
            [-80.824286253999958, 35.559469953000075],
            [-80.823939253999981, 35.559326953000038],
            [-80.822714253999948, 35.558821953000063],
            [-80.821127252999986, 35.558168952000074],
            [-80.821044253999958, 35.558145953000064],
            [-80.820690253999942, 35.558043953000038],
            [-80.820027252999978, 35.557921953000061],
            [-80.81922025199998, 35.557872953000071],
            [-80.81805325199997, 35.557937953000078],
            [-80.817417251999984, 35.557972953000046],
            [-80.817053251999937, 35.55799395300005],
            [-80.816286251999941, 35.558036952000066],
            [-80.812109250999981, 35.558289953000042],
            [-80.81152825099997, 35.55653095200006],
            [-80.811394250999967, 35.556284952000055],
            [-80.811232250999979, 35.556051953000065],
            [-80.811042250999947, 35.555831952000062],
            [-80.810640249999949, 35.555930953000029],
            [-80.810121250999941, 35.555977953000024],
            [-80.807084249999946, 35.555812952000053],
            [-80.806164248999949, 35.555762953000055],
            [-80.805742247999945, 35.555402952000065],
            [-80.804448248999961, 35.55361595200003],
            [-80.804256248999934, 35.552997952000055],
            [-80.804050247999953, 35.552782953000076],
            [-80.803924247999987, 35.552427953000063],
            [-80.803918247999945, 35.552272952000067],
            [-80.803918248999935, 35.551712952000059],
            [-80.803582247999941, 35.551047952000033],
            [-80.803105247999952, 35.550543951000066],
            [-80.802594247999934, 35.55017795200007],
            [-80.80220924799994, 35.549902951000036],
            [-80.801638246999971, 35.549286952000045],
            [-80.801035246999959, 35.548635951000051],
            [-80.800107245999982, 35.547635951000075],
            [-80.800079246999985, 35.547428951000029],
            [-80.799967246999984, 35.547428951000029],
            [-80.799659245999976, 35.54713195100004],
            [-80.799463245999959, 35.546764951000057],
            [-80.798875245999966, 35.546192950000034],
            [-80.798707245999935, 35.545344951000061],
            [-80.79842724599996, 35.54472695100003],
            [-80.798118245999945, 35.544542951000039],
            [-80.798146245999988, 35.545413951000057],
            [-80.798432245999948, 35.546873952000055],
            [-80.79855024699998, 35.548630951000064],
            [-80.798537246999956, 35.549115952000079],
            [-80.79852524599994, 35.54952095200008],
            [-80.79816524599994, 35.550683952000043],
            [-80.797804245999941, 35.551482952000072],
            [-80.797704246999956, 35.552523953000048],
            [-80.797624245999941, 35.55246995300007],
            [-80.79468624499998, 35.552331953000078],
            [-80.790759244999947, 35.552073952000057],
            [-80.790825243999961, 35.55248595300003],
            [-80.790896243999953, 35.552933953000036],
            [-80.790905243999987, 35.553364953000028],
            [-80.790865243999974, 35.554176953000024],
            [-80.790853243999948, 35.554417953000041],
            [-80.790829243999951, 35.554897953000079],
            [-80.790825243999961, 35.55498095300004],
            [-80.790785243999949, 35.555796953000026],
            [-80.790756243999965, 35.556406953000078],
            [-80.790718243999947, 35.557194953000078],
            [-80.79063124399994, 35.558578953000051],
            [-80.790615244999969, 35.558840954000061],
            [-80.790581244999942, 35.559372954000025],
            [-80.790502244999971, 35.559650953000073],
            [-80.790370244999963, 35.559915955000065],
            [-80.790188243999978, 35.560159954000028],
            [-80.78996224499997, 35.560376954000048],
            [-80.78969624399997, 35.560562954000034],
            [-80.789398243999983, 35.560711955000045],
            [-80.789074243999949, 35.560820954000064],
            [-80.788333243999944, 35.561009954000042],
            [-80.787831242999971, 35.56043995400006],
            [-80.786952243999963, 35.559527954000032],
            [-80.786161242999981, 35.558761954000033],
            [-80.786107242999947, 35.558709954000051],
            [-80.786100243999954, 35.558702954000069],
            [-80.783882241999947, 35.556554953000045],
            [-80.782918241999937, 35.555656954000028],
            [-80.782306240999958, 35.555162953000035],
            [-80.781769241999939, 35.554854953000074],
            [-80.781340241999942, 35.554631953000069],
            [-80.780041240999935, 35.554092953000065],
            [-80.779657239999949, 35.553933954000058],
            [-80.778547239999966, 35.55349095300005],
            [-80.777846239999974, 35.553210954000065],
            [-80.777419239999972, 35.553022953000038],
            [-80.777047239999945, 35.552858954000044],
            [-80.776752239999951, 35.552709953000033],
            [-80.776464239999939, 35.552558953000073],
            [-80.77599123899995, 35.55224495300007],
            [-80.775843239999972, 35.552135953000061],
            [-80.775034239999968, 35.552906953000047],
            [-80.773326238999971, 35.554570954000042],
            [-80.773261238999964, 35.554634954000051],
            [-80.772721238999964, 35.555161955000074],
            [-80.770296237999958, 35.557496954000044],
            [-80.77176523899999, 35.557804955000051],
            [-80.772146237999948, 35.557893954000065],
            [-80.772456238999951, 35.558004955000058],
            [-80.77265123899997, 35.558114955000065],
            [-80.772729238999943, 35.558158955000067],
            [-80.772971238999958, 35.558343954000065],
            [-80.773161239999979, 35.558524955000053],
            [-80.774567238999964, 35.560057954000058],
            [-80.774737239999979, 35.560418955000046],
            [-80.774842239999941, 35.560863955000059],
            [-80.774844239999936, 35.560872955000036],
            [-80.774907240999937, 35.561269955000057],
            [-80.774914238999941, 35.562063955000042],
            [-80.77500723899999, 35.565925956000058],
            [-80.774997239999948, 35.566571956000075],
            [-80.775040240999942, 35.567026956000063],
            [-80.775153239999952, 35.567360956000073],
            [-80.775246240999934, 35.567636956000058],
            [-80.775255240999968, 35.567654956000069],
            [-80.775479239999981, 35.568093956000041],
            [-80.774349239999935, 35.568667957000059],
            [-80.772997238999949, 35.569364956000072],
            [-80.771199239999987, 35.570289958000046],
            [-80.767211237999959, 35.572343957000044],
            [-80.766514237999957, 35.572710958000073],
            [-80.765951238999946, 35.573007957000073],
            [-80.763015236999934, 35.574506958000029],
            [-80.762530236999964, 35.574796958000036],
            [-80.762133236999944, 35.575105959000041],
            [-80.761827235999988, 35.575479959000063],
            [-80.761642235999943, 35.575890959000048],
            [-80.761528236999936, 35.576228958000058],
            [-80.761456236999948, 35.576631959000053],
            [-80.761479236999946, 35.57835895900007],
            [-80.761510237999971, 35.579859960000078],
            [-80.761572235999949, 35.581222959000058],
            [-80.761790236999957, 35.582882960000063],
            [-80.761857236999958, 35.583430960000044],
            [-80.76199123799995, 35.584537960000034],
            [-80.761927236999952, 35.584940961000029],
            [-80.761738237999964, 35.585490960000072],
            [-80.761522236999951, 35.585804960000075],
            [-80.761103236999986, 35.586210960000074],
            [-80.76058123599995, 35.586540961000026],
            [-80.757725235999942, 35.587940961000072],
            [-80.757543236999936, 35.588101961000064],
            [-80.757503235999934, 35.588147961000061],
            [-80.75736223499996, 35.588308962000042],
            [-80.757240235999973, 35.58863296100003],
            [-80.757231235999939, 35.588773962000062],
            [-80.757108235999965, 35.590717962000042],
            [-80.757022235999955, 35.592407963000028],
            [-80.756529235999949, 35.592176963000043],
            [-80.754372235999938, 35.591189962000044],
            [-80.75291223499994, 35.59051596200004],
            [-80.752501233999965, 35.590300963000061],
            [-80.750763233999976, 35.589393962000031],
            [-80.750642234999987, 35.589307962000078],
            [-80.751008233999983, 35.59080896200004],
            [-80.752109234999978, 35.595311964000075],
            [-80.752476234999961, 35.596812963000048],
            [-80.752711235999982, 35.59776796400007],
            [-80.753769234999936, 35.602074964000053],
            [-80.753908234999983, 35.602647965000074],
            [-80.754328235999935, 35.604366965000054],
            [-80.754468236999969, 35.604939965000028],
            [-80.754599236999979, 35.605476965000037],
            [-80.754994236999948, 35.607089966000046],
            [-80.755126236999956, 35.607627965000063],
            [-80.755185236999978, 35.607869966000067],
            [-80.755364236999981, 35.608595966000053],
            [-80.755424236999943, 35.608838966000064],
            [-80.755589235999935, 35.609514966000063],
            [-80.756084237999971, 35.611542966000059],
            [-80.756249236999963, 35.612218967000047],
            [-80.756266236999977, 35.612288966000051],
            [-80.756353237999974, 35.612643966000064],
            [-80.757242236999957, 35.616271967000046],
            [-80.757434237999973, 35.617052968000053],
            [-80.758230237999953, 35.620300968000038],
            [-80.759135237999942, 35.62399496900008],
            [-80.759190238999963, 35.624277968000058],
            [-80.759324239999955, 35.624967969000068],
            [-80.759346238999967, 35.625128969000059],
            [-80.759387237999988, 35.625414969000076],
            [-80.759588237999935, 35.625389969000025],
            [-80.75975023899997, 35.625356969000052],
            [-80.759884239999963, 35.625346970000066],
            [-80.759911238999962, 35.625335969000048],
            [-80.759992238999985, 35.625335969000048],
            [-80.760019238999973, 35.625324969000076],
            [-80.760126238999987, 35.625313968000057],
            [-80.760234238999942, 35.625313969000047],
            [-80.760665238999934, 35.625269969000044],
            [-80.761176239999941, 35.625270970000031],
            [-80.761714238999957, 35.625314968000055],
            [-80.761822239999958, 35.625336969000045],
            [-80.761876239999935, 35.625336969000045],
            [-80.762091239999961, 35.625380969000048],
            [-80.762360239999964, 35.625381969000046],
            [-80.762630239999964, 35.625348969000072],
            [-80.762656239999956, 35.625337969000043],
            [-80.762798238999949, 35.625315969000042],
            [-80.762818238999955, 35.625304969000069],
            [-80.763006238999935, 35.625282969000068],
            [-80.763114239999936, 35.62529396900004],
            [-80.763276239999982, 35.625294968000048],
            [-80.763329239999962, 35.625305969000067],
            [-80.763428239999939, 35.625305969000067],
            [-80.763518239999939, 35.625305969000067],
            [-80.763679239999988, 35.625272968000047],
            [-80.764051239999958, 35.625134969000044],
            [-80.764191239999946, 35.625042968000059],
            [-80.764318240999955, 35.624945969000066],
            [-80.764747239999963, 35.62447596800007],
            [-80.765150240999958, 35.624084968000034],
            [-80.765306240999962, 35.62395396800008],
            [-80.765474239999946, 35.623833968000042],
            [-80.765818240999977, 35.623548969000069],
            [-80.765981240999963, 35.623433969000075],
            [-80.766250239999977, 35.623269968000045],
            [-80.766520240999967, 35.623127968000063],
            [-80.766749240999957, 35.623013968000066],
            [-80.767099240999983, 35.622871969000073],
            [-80.767396240999972, 35.622768969000049],
            [-80.767557240999963, 35.622730968000042],
            [-80.767611241999987, 35.62270896800004],
            [-80.767665241999964, 35.62270896800004],
            [-80.767968240999949, 35.622638968000047],
            [-80.768203240999981, 35.622550968000041],
            [-80.768419240999947, 35.622458968000046],
            [-80.768554240999947, 35.62238196800007],
            [-80.76863524099997, 35.62234896700005],
            [-80.769015241999966, 35.622108968000077],
            [-80.769451240999956, 35.621813969000073],
            [-80.770146241999953, 35.621260968000058],
            [-80.770180240999935, 35.621244968000042],
            [-80.770335241999987, 35.621118967000029],
            [-80.770827241999939, 35.620763968000063],
            [-80.770928241999968, 35.620681968000042],
            [-80.771144240999945, 35.620527968000033],
            [-80.771609242999943, 35.620227967000062],
            [-80.772574242999951, 35.619532968000044],
            [-80.772952241999974, 35.619314967000037],
            [-80.77353124199999, 35.619035967000059],
            [-80.773881241999959, 35.618894967000074],
            [-80.774508241999968, 35.618692968000062],
            [-80.77520124299997, 35.618420967000077],
            [-80.775390242999947, 35.618365968000035],
            [-80.775498241999969, 35.618344968000031],
            [-80.775632242999961, 35.618333967000069],
            [-80.775740243999962, 35.618312967000065],
            [-80.77579424399994, 35.618312967000065],
            [-80.776171243999954, 35.618252967000046],
            [-80.776305243999957, 35.618214967000029],
            [-80.776575243999957, 35.618116967000049],
            [-80.776803242999961, 35.618051967000042],
            [-80.776992243999985, 35.617975967000064],
            [-80.777430242999969, 35.617762967000033],
            [-80.777882243999954, 35.617478966000078],
            [-80.778104243999962, 35.617303966000065],
            [-80.778327243999968, 35.617106967000041],
            [-80.778538242999957, 35.61689296600008],
            [-80.77889324399996, 35.616899967000052],
            [-80.779108243999985, 35.61687796700005],
            [-80.779162243999963, 35.616867966000029],
            [-80.779243243999986, 35.616834966000056],
            [-80.779378243999986, 35.61674696700004],
            [-80.779485243999943, 35.616703967000035],
            [-80.779539243999977, 35.61669796700005],
            [-80.779593243999955, 35.616709967000077],
            [-80.779862243999958, 35.616869966000024],
            [-80.780318243999943, 35.617040967000037],
            [-80.780533244999958, 35.617134967000027],
            [-80.780560244999947, 35.617156967000028],
            [-80.780614244999981, 35.617179967000027],
            [-80.780956243999981, 35.617372967000051],
            [-80.781030244999954, 35.617400967000037],
            [-80.781345244999955, 35.617560966000042],
            [-80.781581244999984, 35.617654966000032],
            [-80.781607244999975, 35.617676967000079],
            [-80.781795244999955, 35.617753966000066],
            [-80.781822244999944, 35.617775966000067],
            [-80.781930244999955, 35.617820967000057],
            [-80.781956244999947, 35.617842967000058],
            [-80.782144244999984, 35.617919967000034],
            [-80.782171244999972, 35.617941967000036],
            [-80.782381244999954, 35.618037966000031],
            [-80.782173244999967, 35.619086967000044],
            [-80.783507245999942, 35.619818967000072],
            [-80.783906245999958, 35.62003796700003],
            [-80.783860244999971, 35.620113967000066],
            [-80.78458124499997, 35.620474967000064],
            [-80.785551246999944, 35.620960968000077],
            [-80.785923246999971, 35.621210967000025],
            [-80.786164245999942, 35.621408968000026],
            [-80.786913246999973, 35.622134967000079],
            [-80.789107246999947, 35.62426296700005],
            [-80.789297247999968, 35.624446968000029],
            [-80.789595247999955, 35.62474996800006],
            [-80.789627247999988, 35.624795968000058],
            [-80.790775247999989, 35.624161968000067],
            [-80.791031247999967, 35.624052968000058],
            [-80.791256247999968, 35.623978968000074],
            [-80.791534247999948, 35.623930968000025],
            [-80.791900247999934, 35.623934968000071],
            [-80.792709248999984, 35.62401096800005],
            [-80.792957248999983, 35.624024967000025],
            [-80.793182247999937, 35.624003967000078],
            [-80.793514248999941, 35.623945967000054],
            [-80.793680248999976, 35.62390896800008],
            [-80.794617249999988, 35.623700968000037],
            [-80.795057248999967, 35.623632968000038],
            [-80.795508248999965, 35.623591968000028],
            [-80.795798249999962, 35.623578967000071],
            [-80.796261249999986, 35.623588968000035],
            [-80.796452249999959, 35.623626968000053],
            [-80.796748249999951, 35.62368796700008],
            [-80.797127248999971, 35.62382296800007],
            [-80.798057248999953, 35.624290967000036],
            [-80.798381249999977, 35.624453967000079],
            [-80.798670250999976, 35.624598968000043],
            [-80.798833250999962, 35.624680968000064],
            [-80.798715250999976, 35.624832968000078],
            [-80.79856424999997, 35.625026967000053],
            [-80.798350249999942, 35.625302968000028],
            [-80.797988249999946, 35.625768968000045],
            [-80.797538249999945, 35.626347967000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 78,
        "SolutionID": "PI-30:A",
        "Shape_Length": 0.57771039241510891,
        "Shape_Area": 0.0076690575638891003
      }
    },
    {
      "type": "Feature",
      "id": 79,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.886904289999961, 35.842971010000042],
            [-80.887375288999976, 35.843023010000024],
            [-80.88749828899995, 35.843045011000072],
            [-80.888178288999939, 35.843159011000068],
            [-80.889546289999942, 35.843444010000042],
            [-80.890237290999949, 35.843572011000049],
            [-80.892643290999956, 35.844049010000049],
            [-80.892959290999954, 35.844070010000053],
            [-80.893697289999977, 35.844138011000041],
            [-80.894623291999949, 35.844217010000079],
            [-80.89538429199996, 35.844283010000026],
            [-80.896657291999986, 35.844382010000061],
            [-80.897476291999965, 35.844461010000032],
            [-80.898068292999938, 35.84449101000007],
            [-80.89852029299999, 35.844465011000068],
            [-80.898883292999983, 35.844413010000039],
            [-80.899495292999973, 35.844272010000054],
            [-80.899995292999961, 35.844198010000071],
            [-80.900496292999946, 35.844170010000028],
            [-80.901070292999975, 35.844148010000026],
            [-80.901685293999947, 35.844132010000067],
            [-80.902088292999963, 35.84411201000006],
            [-80.901961292999943, 35.843102010000052],
            [-80.901964293999981, 35.842789010000047],
            [-80.902027293999936, 35.842481009000039],
            [-80.902149293999969, 35.842185010000037],
            [-80.902326293999977, 35.841907009000067],
            [-80.904292293999958, 35.839121009000053],
            [-80.904431294999938, 35.838920009000049],
            [-80.904947293999953, 35.838175008000064],
            [-80.905830293999941, 35.836520008000036],
            [-80.906051293999951, 35.836064008000051],
            [-80.906185293999954, 35.835808008000072],
            [-80.906608294999955, 35.834977008000067],
            [-80.90680529399998, 35.834673008000038],
            [-80.907053293999979, 35.834396008000056],
            [-80.907373294999957, 35.834111008000036],
            [-80.908153294999977, 35.833417007000037],
            [-80.908389294999949, 35.833228008000049],
            [-80.908590294999954, 35.833015007000029],
            [-80.90875329499994, 35.83278100800004],
            [-80.908875294999973, 35.832532008000044],
            [-80.909250295999982, 35.831297007000046],
            [-80.909334294999951, 35.830951007000067],
            [-80.909369294999976, 35.830600007000044],
            [-80.90935329499996, 35.830247007000025],
            [-80.909287294999956, 35.829898007000054],
            [-80.90912829499996, 35.829402007000056],
            [-80.908925293999971, 35.82891700700003],
            [-80.908677294999961, 35.828446006000036],
            [-80.908124293999947, 35.827572007000072],
            [-80.910433294999962, 35.82756600600004],
            [-80.914584295999987, 35.827556006000066],
            [-80.914979296999945, 35.827556006000066],
            [-80.91692829699997, 35.827551006000078],
            [-80.917113297999947, 35.827568006000035],
            [-80.917355296999972, 35.82762600600006],
            [-80.917505296999934, 35.827676006000047],
            [-80.917816296999945, 35.827877006000051],
            [-80.918110296999942, 35.828025006000075],
            [-80.918253296999978, 35.828085007000027],
            [-80.918366297999967, 35.82817000600005],
            [-80.918423296999947, 35.828150005000055],
            [-80.918522297999971, 35.828185006000069],
            [-80.917827297999963, 35.826600006000035],
            [-80.917506296999989, 35.825925005000045],
            [-80.915392296999983, 35.821300005000069],
            [-80.915325296999981, 35.821153005000042],
            [-80.914913294999963, 35.820236005000027],
            [-80.91485729599998, 35.820112005000055],
            [-80.914543295999977, 35.819442005000042],
            [-80.914391295999962, 35.819118004000075],
            [-80.91428329699994, 35.818886004000035],
            [-80.913712295999972, 35.817659004000063],
            [-80.911894294999968, 35.813754003000042],
            [-80.911869294999974, 35.813700003000065],
            [-80.911524293999946, 35.812879003000035],
            [-80.911225293999962, 35.812045003000037],
            [-80.91121829399998, 35.812016003000053],
            [-80.911137294999946, 35.811690002000034],
            [-80.910969294999973, 35.811011003000033],
            [-80.910931294999955, 35.81086500300006],
            [-80.910788294999975, 35.810222003000035],
            [-80.910612294999964, 35.809482003000028],
            [-80.910307294999939, 35.80814900200005],
            [-80.91018629499996, 35.807672001000071],
            [-80.910073294999961, 35.807358003000047],
            [-80.910197292999953, 35.807336002000056],
            [-80.910318293999978, 35.807356002000063],
            [-80.91045629499996, 35.807452002000048],
            [-80.910611293999978, 35.807625002000066],
            [-80.91092129499998, 35.807942003000051],
            [-80.911119293999946, 35.808123002000059],
            [-80.911476294999943, 35.808342002000074],
            [-80.911678294999945, 35.808464002000051],
            [-80.912112294999986, 35.808661002000065],
            [-80.913108294999972, 35.809131002000072],
            [-80.913209294999945, 35.809179002000064],
            [-80.913386294999952, 35.80926300200008],
            [-80.913611294999953, 35.809369002000039],
            [-80.913792294999951, 35.809457002000045],
            [-80.914021294999941, 35.809567002000051],
            [-80.915374295999982, 35.810221003000038],
            [-80.918958296999961, 35.810007002000077],
            [-80.919282296999938, 35.809997002000046],
            [-80.919602296999983, 35.809952002000045],
            [-80.919898296999975, 35.809877002000064],
            [-80.920768296999938, 35.80963500200005],
            [-80.921713297999986, 35.809372002000032],
            [-80.921925297999962, 35.809313002000067],
            [-80.92230529699998, 35.809196002000078],
            [-80.922429296999951, 35.809175001000028],
            [-80.922717297999952, 35.809135001000072],
            [-80.922942297999953, 35.809112002000063],
            [-80.924487296999985, 35.809053002000041],
            [-80.925999298999955, 35.808995002000074],
            [-80.927654299999972, 35.808975002000068],
            [-80.928589299999942, 35.808976002000065],
            [-80.929310299999941, 35.808984002000045],
            [-80.929702299999974, 35.809000001000072],
            [-80.930245299999967, 35.808827001000054],
            [-80.930602299999975, 35.808713001000058],
            [-80.931758300999945, 35.808344002000069],
            [-80.932172299999934, 35.808212001000072],
            [-80.932245300999966, 35.808189001000073],
            [-80.933222300999944, 35.807878002000052],
            [-80.93371130099996, 35.807722001000059],
            [-80.934037301999979, 35.807635001000051],
            [-80.934373300999937, 35.807580001000076],
            [-80.934714300999985, 35.80755600100008],
            [-80.935266301999945, 35.807543001000056],
            [-80.935581301999946, 35.807535001000076],
            [-80.935767300999942, 35.807529001000034],
            [-80.93770230299998, 35.807479001000047],
            [-80.93923330299998, 35.807440001000032],
            [-80.939348302999974, 35.807395001000032],
            [-80.940165302999958, 35.807075001000044],
            [-80.94025830399994, 35.807031001000041],
            [-80.940425302999984, 35.806927001000076],
            [-80.942032303999952, 35.805771001000039],
            [-80.942192303999946, 35.805673001000059],
            [-80.942830303999983, 35.80538400000006],
            [-80.943460304999974, 35.805087000000071],
            [-80.942922302999989, 35.80446100000006],
            [-80.941666303999966, 35.802613000000065],
            [-80.941677302999949, 35.802562999000031],
            [-80.941671302999964, 35.802132999000037],
            [-80.941571302999989, 35.801982999000074],
            [-80.941357302999961, 35.801664000000073],
            [-80.941302302999986, 35.801582000000053],
            [-80.941171302999976, 35.801385000000039],
            [-80.941040302999966, 35.801190000000076],
            [-80.940983303999985, 35.80109199900005],
            [-80.940685301999963, 35.800571000000048],
            [-80.939987302999953, 35.799357999000051],
            [-80.939497302999939, 35.798721999000065],
            [-80.93981230199995, 35.798598999000035],
            [-80.939556301999971, 35.79815300000007],
            [-80.939496302999942, 35.798047999000062],
            [-80.938864301999956, 35.796947998000064],
            [-80.93805230199996, 35.795684999000059],
            [-80.937532300999976, 35.794957998000029],
            [-80.937208301999988, 35.794630999000049],
            [-80.93695330099996, 35.794420998000078],
            [-80.936930300999961, 35.794399998000074],
            [-80.936749300999963, 35.794301998000037],
            [-80.936512301999983, 35.79415799800006],
            [-80.936131300999989, 35.79395899900004],
            [-80.936073300999965, 35.793928998000069],
            [-80.935972299999946, 35.793875998000033],
            [-80.935840299999938, 35.793818998000063],
            [-80.934477299999969, 35.794169999000076],
            [-80.932565299999965, 35.794649999000058],
            [-80.932339299999967, 35.794706998000038],
            [-80.929046299999982, 35.795535999000037],
            [-80.927693298999941, 35.795870999000044],
            [-80.926338296999972, 35.796217999000078],
            [-80.924978296999939, 35.796554998000033],
            [-80.922388296999941, 35.797213000000056],
            [-80.921762296999987, 35.797372000000053],
            [-80.918763295999952, 35.798125000000027],
            [-80.916060295999955, 35.798812000000055],
            [-80.913233293999951, 35.799521000000027],
            [-80.910396293999952, 35.800240000000031],
            [-80.908165292999968, 35.800797001000035],
            [-80.90788629299999, 35.800867000000039],
            [-80.905240291999974, 35.801537001000042],
            [-80.904938292999987, 35.801276001000076],
            [-80.90461929199995, 35.801001001000031],
            [-80.903258291999975, 35.799825001000045],
            [-80.902378290999934, 35.799072000000024],
            [-80.90156129099995, 35.798375000000078],
            [-80.900529289999952, 35.79751600000003],
            [-80.899975289999986, 35.797055000000057],
            [-80.899536289999958, 35.797340000000077],
            [-80.899059289999968, 35.797580000000039],
            [-80.898550289999946, 35.797773001000053],
            [-80.898030288999962, 35.797995000000071],
            [-80.897475290999978, 35.798232000000041],
            [-80.896723289999954, 35.79855400100007],
            [-80.895872289999943, 35.798926000000051],
            [-80.895672288999947, 35.799002001000076],
            [-80.895524289999969, 35.799059001000046],
            [-80.895159288999935, 35.799157001000026],
            [-80.894782289999966, 35.799220001000037],
            [-80.894399288999978, 35.799247000000037],
            [-80.893844288999958, 35.799264002000029],
            [-80.893323287999976, 35.799293001000024],
            [-80.892872287999978, 35.79930900100004],
            [-80.892576287999987, 35.799312001000033],
            [-80.892066287999967, 35.799312001000033],
            [-80.891811287999985, 35.799307001000045],
            [-80.890721286999963, 35.799285000000054],
            [-80.888613287999988, 35.799196001000041],
            [-80.887180285999989, 35.799141001000066],
            [-80.88581828599996, 35.799089002000073],
            [-80.882987283999967, 35.798956001000079],
            [-80.882063284999958, 35.798912001000076],
            [-80.879896283999983, 35.798810001000049],
            [-80.878031283999974, 35.798739001000058],
            [-80.876782282999955, 35.798694002000047],
            [-80.876723283999979, 35.797390002000043],
            [-80.876685283999961, 35.796561001000043],
            [-80.876656282999988, 35.796489001000054],
            [-80.876592282999979, 35.796330001000058],
            [-80.876515282999947, 35.796132001000046],
            [-80.876244282999949, 35.795437001000039],
            [-80.87612728299996, 35.795071001000053],
            [-80.875316282999961, 35.79532400100004],
            [-80.875068283999951, 35.795384001000059],
            [-80.874921282999935, 35.795386000000065],
            [-80.874139282999977, 35.795374001000027],
            [-80.873881281999957, 35.795358001000068],
            [-80.873687281999935, 35.795341001000054],
            [-80.873573282999985, 35.795298000000059],
            [-80.873508281999989, 35.795220001000075],
            [-80.872831280999947, 35.793851000000075],
            [-80.872262280999962, 35.792693000000043],
            [-80.871676281999953, 35.791501000000039],
            [-80.870976280999969, 35.790076000000056],
            [-80.869713280999974, 35.790470999000036],
            [-80.868278280999959, 35.790962000000036],
            [-80.867871279999974, 35.791097000000036],
            [-80.867456280999988, 35.791216000000077],
            [-80.867046279999954, 35.791317000000049],
            [-80.86675527999995, 35.791378000000066],
            [-80.866462279999951, 35.791432000000043],
            [-80.866059279999945, 35.791475001000038],
            [-80.865705279999986, 35.791512000000068],
            [-80.865477278999947, 35.791512000000068],
            [-80.865249279999944, 35.791497000000049],
            [-80.864462278999952, 35.791385001000037],
            [-80.86369327999995, 35.791314000000057],
            [-80.863564278999945, 35.791303001000074],
            [-80.862765278999973, 35.791233000000034],
            [-80.862670277999939, 35.791226000000051],
            [-80.862649278999982, 35.791225001000043],
            [-80.862482277999959, 35.791211000000033],
            [-80.862445278999985, 35.791207001000032],
            [-80.862223278999977, 35.791188000000034],
            [-80.862018278999983, 35.791171001000066],
            [-80.861078277999979, 35.791090001000043],
            [-80.860893277999935, 35.791074001000027],
            [-80.86084227799995, 35.791070001000037],
            [-80.859967278999989, 35.790989001000071],
            [-80.859483277999971, 35.790944000000025],
            [-80.859278276999987, 35.790925000000072],
            [-80.857112276999942, 35.79072300100006],
            [-80.856424277999963, 35.790659000000062],
            [-80.856107276999978, 35.791618001000074],
            [-80.855776277999951, 35.792621001000043],
            [-80.855669276999947, 35.792946001000075],
            [-80.855444276999947, 35.793627001000061],
            [-80.854825276999975, 35.795501002000037],
            [-80.854791276999947, 35.79563500200004],
            [-80.854780276999975, 35.795677001000058],
            [-80.854756276999979, 35.795883002000039],
            [-80.854769276999946, 35.796580001000052],
            [-80.854773276999936, 35.796764002000032],
            [-80.854773276999936, 35.797094002000051],
            [-80.854773275999946, 35.797420002000024],
            [-80.854773276999936, 35.797551001000045],
            [-80.854751275999945, 35.797888002000036],
            [-80.854718275999971, 35.798127002000058],
            [-80.854683276999936, 35.798393002000068],
            [-80.854677276999951, 35.798594002000073],
            [-80.854724277999935, 35.798757003000048],
            [-80.854829275999975, 35.79892600200003],
            [-80.855008276999968, 35.799101003000033],
            [-80.853871276999939, 35.799801002000038],
            [-80.853677275999985, 35.799602002000029],
            [-80.853516276999983, 35.799404002000074],
            [-80.853462275999959, 35.799316003000058],
            [-80.85343527699996, 35.799294002000067],
            [-80.85330827599995, 35.799074002000054],
            [-80.853213276999952, 35.798964003000037],
            [-80.853119276999962, 35.798876002000043],
            [-80.852870276999965, 35.798683002000075],
            [-80.852749276999987, 35.798628002000044],
            [-80.852587275999952, 35.798578003000046],
            [-80.85247927599994, 35.798556002000055],
            [-80.852290276999952, 35.798533002000056],
            [-80.85218227699994, 35.798533002000056],
            [-80.85215627599996, 35.798522003000073],
            [-80.85191227699994, 35.798510002000057],
            [-80.850941275999958, 35.798420003000047],
            [-80.850677274999953, 35.798386002000029],
            [-80.850510274999976, 35.798364003000074],
            [-80.850240275999965, 35.798319003000074],
            [-80.850213275999977, 35.798308002000056],
            [-80.849889275999942, 35.798252002000027],
            [-80.849781275999987, 35.798219003000042],
            [-80.849377275999984, 35.798136002000035],
            [-80.849355274999937, 35.798140002000025],
            [-80.849269274999983, 35.798119002000078],
            [-80.84905327499996, 35.79808500300004],
            [-80.848972274999937, 35.798063002000049],
            [-80.848676274999946, 35.798019002000046],
            [-80.848487275999958, 35.797974002000046],
            [-80.848379274999957, 35.797963002000074],
            [-80.848001273999955, 35.797885002000044],
            [-80.847840274999953, 35.79784100300003],
            [-80.847758273999943, 35.797829002000071],
            [-80.847543274999964, 35.79777400200004],
            [-80.847165273999963, 35.797696002000066],
            [-80.846949274999986, 35.797630002000062],
            [-80.846680273999937, 35.797574002000033],
            [-80.846626273999959, 35.797552002000032],
            [-80.846599273999971, 35.797552002000032],
            [-80.846491274999948, 35.797519003000048],
            [-80.846410273999936, 35.797507003000078],
            [-80.846167273999981, 35.797441002000028],
            [-80.845979273999944, 35.797407002000057],
            [-80.845925273999967, 35.797385003000045],
            [-80.84570927499999, 35.797341002000053],
            [-80.845601274999979, 35.797330002000024],
            [-80.84557427499999, 35.797318002000054],
            [-80.845520274999956, 35.797318002000054],
            [-80.845358273999977, 35.797285002000024],
            [-80.845169273999943, 35.797273003000043],
            [-80.845142273999954, 35.797262002000025],
            [-80.845007273999954, 35.797262002000025],
            [-80.844954273999974, 35.797251002000053],
            [-80.844832273999941, 35.797258002000035],
            [-80.844338273999938, 35.797266002000072],
            [-80.844090273999939, 35.797292003000052],
            [-80.843792273999952, 35.797379002000071],
            [-80.84366227299995, 35.797403002000067],
            [-80.84355627299999, 35.797423002000073],
            [-80.843442272999937, 35.797433002000048],
            [-80.843415273999938, 35.797444003000066],
            [-80.843361272999971, 35.797444002000077],
            [-80.843334272999982, 35.797455002000049],
            [-80.843280273999937, 35.797455002000049],
            [-80.843253272999959, 35.797466003000068],
            [-80.84309127299997, 35.797487003000072],
            [-80.84295627299997, 35.797498002000054],
            [-80.842740273999937, 35.79753000200003],
            [-80.842686273999959, 35.79753000200003],
            [-80.842659272999981, 35.79754100200006],
            [-80.842422272999954, 35.797573003000025],
            [-80.842362273999981, 35.797573002000036],
            [-80.842335272999946, 35.797584003000054],
            [-80.842281272999969, 35.797584003000054],
            [-80.842065272999946, 35.79761600300003],
            [-80.841930272999946, 35.79762700200007],
            [-80.841903271999968, 35.797638003000031],
            [-80.841849273999969, 35.797638002000042],
            [-80.841822272999934, 35.797649002000071],
            [-80.841768272999957, 35.79764900300006],
            [-80.841498272999956, 35.797703003000038],
            [-80.84128227299999, 35.797735003000071],
            [-80.839877271999967, 35.798017002000051],
            [-80.839796271999944, 35.798017002000051],
            [-80.839668272999972, 35.798038002000055],
            [-80.839607271999967, 35.798038003000045],
            [-80.839290271999971, 35.798092003000079],
            [-80.839148271999989, 35.798092003000079],
            [-80.839095271999952, 35.79808100200006],
            [-80.839041271999974, 35.798058003000051],
            [-80.838960272999941, 35.797998002000043],
            [-80.83889327199995, 35.797921003000056],
            [-80.838839271999973, 35.797827002000076],
            [-80.838812271999984, 35.797739003000061],
            [-80.838812272999974, 35.797673003000057],
            [-80.838772271999972, 35.797553002000029],
            [-80.838806272999989, 35.79746500300007],
            [-80.838814271999979, 35.797333002000073],
            [-80.838827271999946, 35.797294003000047],
            [-80.838827272999936, 35.797223003000056],
            [-80.838841271999968, 35.797163002000048],
            [-80.838841271999968, 35.797047002000056],
            [-80.838855271999989, 35.796943003000024],
            [-80.838849271999948, 35.796718002000034],
            [-80.838857271999984, 35.796586003000073],
            [-80.838870272999941, 35.796564002000025],
            [-80.838871271999949, 35.796425002000035],
            [-80.838535271999945, 35.796477002000074],
            [-80.837410271999943, 35.796653002000028],
            [-80.83709627199994, 35.796703003000061],
            [-80.836652271999981, 35.796773002000066],
            [-80.836474270999986, 35.796801002000052],
            [-80.835777270999984, 35.796912002000056],
            [-80.83542227099997, 35.796968003000075],
            [-80.835078270999986, 35.797030003000032],
            [-80.834954270999958, 35.79706800200006],
            [-80.832880270999965, 35.797988003000057],
            [-80.830258269999945, 35.799152003000074],
            [-80.829772268999989, 35.799367003000043],
            [-80.828690269999981, 35.799844004000079],
            [-80.82809526799997, 35.798388003000071],
            [-80.828029268999956, 35.798166003000063],
            [-80.828013268999939, 35.79793800300007],
            [-80.828041268999982, 35.797019003000059],
            [-80.828056268999944, 35.796720002000029],
            [-80.828104269999983, 35.796331003000034],
            [-80.828112268999973, 35.796297003000063],
            [-80.828209267999966, 35.79595000300003],
            [-80.828368268999952, 35.79558200200006],
            [-80.828580267999939, 35.795232002000034],
            [-80.828652268999974, 35.795132002000059],
            [-80.828841268999952, 35.794904003000056],
            [-80.829148268999973, 35.794604002000028],
            [-80.829497268999944, 35.79433600200008],
            [-80.830835268999977, 35.793422002000057],
            [-80.831053268999938, 35.793266002000053],
            [-80.831307268999979, 35.793084001000068],
            [-80.831739269999957, 35.792713001000038],
            [-80.832127269999944, 35.792310002000079],
            [-80.832371269999953, 35.792002002000061],
            [-80.832468269999936, 35.791880002000028],
            [-80.832499269999971, 35.791832002000035],
            [-80.832758269999943, 35.791426001000048],
            [-80.832919269999934, 35.791096001000028],
            [-80.833033270999977, 35.790753001000041],
            [-80.833097269999939, 35.790401001000077],
            [-80.833104269999978, 35.790139001000057],
            [-80.833116270999938, 35.78971800100004],
            [-80.832928268999979, 35.789717001000042],
            [-80.832874269999934, 35.789706002000059],
            [-80.832712269999945, 35.78970600100007],
            [-80.832685269999956, 35.789695001000041],
            [-80.83260426999999, 35.789695001000041],
            [-80.832442268999955, 35.789672001000042],
            [-80.832415269999956, 35.78966100100007],
            [-80.832200269999987, 35.78962800100004],
            [-80.832092268999986, 35.789594001000069],
            [-80.832065269999987, 35.789594001000069],
            [-80.832011269999953, 35.789572001000067],
            [-80.831930269999987, 35.789561001000038],
            [-80.831714268999974, 35.789494001000037],
            [-80.831633268999951, 35.789461001000063],
            [-80.831606268999963, 35.789461001000063],
            [-80.831445269999961, 35.789395001000059],
            [-80.831148268999982, 35.789295001000028],
            [-80.830825268999945, 35.789228001000026],
            [-80.83071726899999, 35.789217001000054],
            [-80.830555268999944, 35.789184001000024],
            [-80.830447269999979, 35.789150001000053],
            [-80.830420269999934, 35.789150001000053],
            [-80.829881268999941, 35.788929000000053],
            [-80.829666268999972, 35.788819001000036],
            [-80.829396267999982, 35.788659001000042],
            [-80.828992268999968, 35.788460002000079],
            [-80.828857268999968, 35.788410001000045],
            [-80.82883026799999, 35.788410001000045],
            [-80.828696267999987, 35.78835500100007],
            [-80.828669267999942, 35.78835500100007],
            [-80.828588268999965, 35.78832200100004],
            [-80.828561268999977, 35.788322002000029],
            [-80.828480267999964, 35.788288001000069],
            [-80.828453267999976, 35.788288001000069],
            [-80.828237268999942, 35.788200001000064],
            [-80.828076267999961, 35.788106001000074],
            [-80.827887267999984, 35.787952002000054],
            [-80.827740267999957, 35.787803000000054],
            [-80.827704267999934, 35.787759000000051],
            [-80.827632268999935, 35.787671001000035],
            [-80.827498267999943, 35.787451001000079],
            [-80.827498268999989, 35.787429001000078],
            [-80.827458268999976, 35.787363001000074],
            [-80.827444267999965, 35.78729700100007],
            [-80.827378267999961, 35.78712100000007],
            [-80.82727026699996, 35.786945001000049],
            [-80.827210267999988, 35.786879001000045],
            [-80.827051267999934, 35.786749000000043],
            [-80.826874267999983, 35.786582001000056],
            [-80.826779267999939, 35.78647200100005],
            [-80.826537267999981, 35.78625100000005],
            [-80.826524267999957, 35.786229001000038],
            [-80.826302267999949, 35.786058001000072],
            [-80.825992267999936, 35.785865000000058],
            [-80.825803267999959, 35.78576600100007],
            [-80.825777267999968, 35.785744001000069],
            [-80.825669267999956, 35.785700000000077],
            [-80.825642266999978, 35.785678001000065],
            [-80.825372267999967, 35.78553400100003],
            [-80.82486126699996, 35.785302000000058],
            [-80.824457267999946, 35.785070000000076],
            [-80.824295266999968, 35.784966001000043],
            [-80.824194266999939, 35.784872001000053],
            [-80.824154266999983, 35.784817000000032],
            [-80.824107267999977, 35.784707001000072],
            [-80.824020265999934, 35.784553000000074],
            [-80.823980266999968, 35.784509000000071],
            [-80.823865266999974, 35.784426000000053],
            [-80.823744265999949, 35.784365001000026],
            [-80.823582266999949, 35.78432100100008],
            [-80.823474266999938, 35.784321000000034],
            [-80.823312265999959, 35.784298000000035],
            [-80.82323226699998, 35.784265001000051],
            [-80.823124266999969, 35.784193000000073],
            [-80.82300326699999, 35.784072001000027],
            [-80.822916266999982, 35.784012000000075],
            [-80.822801266999988, 35.783967000000075],
            [-80.822774265999954, 35.783967000000075],
            [-80.822693265999987, 35.783934001000034],
            [-80.822666266999988, 35.783934001000034],
            [-80.822558265999987, 35.783901000000071],
            [-80.822424265999985, 35.78384600000004],
            [-80.822343266999951, 35.783823000000041],
            [-80.822154266999974, 35.783746000000065],
            [-80.822127265999939, 35.783724000000063],
            [-80.822046265999973, 35.783691000000033],
            [-80.821885266999971, 35.783602001000077],
            [-80.821669266999947, 35.783464000000038],
            [-80.821642265999969, 35.783437001000038],
            [-80.821562265999944, 35.783387000000062],
            [-80.821266265999952, 35.783145000000047],
            [-80.821077265999975, 35.783013001000029],
            [-80.820781264999937, 35.782825000000059],
            [-80.820754266999984, 35.782841001000065],
            [-80.82072726499996, 35.782829999000057],
            [-80.82037726599998, 35.782637000000079],
            [-80.820240265999985, 35.782569000000024],
            [-80.820134264999979, 35.782515999000054],
            [-80.819811264999942, 35.782328000000064],
            [-80.819569264999984, 35.782200999000054],
            [-80.819219264999958, 35.781986001000064],
            [-80.819057265999959, 35.781903000000057],
            [-80.818707264999944, 35.78169900000006],
            [-80.818546264999952, 35.781589000000054],
            [-80.817988263999951, 35.78095600000006],
            [-80.81785326499994, 35.780844999000067],
            [-80.817813264999984, 35.780800999000064],
            [-80.817753264999965, 35.780763000000036],
            [-80.817423264999945, 35.780504000000064],
            [-80.817174263999959, 35.780344000000071],
            [-80.817032263999977, 35.780238000000054],
            [-80.816792264999947, 35.780165000000068],
            [-80.81654726499994, 35.78009000000003],
            [-80.816246264999961, 35.780056000000059],
            [-80.816019263999976, 35.780024999000034],
            [-80.814941264999959, 35.780024000000026],
            [-80.813113262999934, 35.779871000000071],
            [-80.812636262999945, 35.779855000000055],
            [-80.811976262999963, 35.779914000000076],
            [-80.811890262999952, 35.779949000000045],
            [-80.811771262999969, 35.779997999000045],
            [-80.811486262999949, 35.78025400000007],
            [-80.811436262999962, 35.780270999000038],
            [-80.811290262999989, 35.780319000000077],
            [-80.810998262999988, 35.780391000000066],
            [-80.810417262999977, 35.780542000000025],
            [-80.810034261999988, 35.780582000000038],
            [-80.809832262999976, 35.780585000000031],
            [-80.809034262999944, 35.780518000000029],
            [-80.807973261999962, 35.780083000000047],
            [-80.807082261999938, 35.779825999000025],
            [-80.806356260999962, 35.779521000000045],
            [-80.806190260999983, 35.77952300000004],
            [-80.806022260999953, 35.779391001000079],
            [-80.805856261999963, 35.779392999000038],
            [-80.805687260999946, 35.779260000000079],
            [-80.805409260999966, 35.779219000000069],
            [-80.80434926099997, 35.77878400000003],
            [-80.803622259999941, 35.778434000000061],
            [-80.802219259999958, 35.777554000000066],
            [-80.80079625999997, 35.776782000000026],
            [-80.80076325999994, 35.776764000000071],
            [-80.799927259999947, 35.77646100000004],
            [-80.798732258999962, 35.776168999000049],
            [-80.798537258999943, 35.776121000000046],
            [-80.798150258999954, 35.776125999000044],
            [-80.797982257999934, 35.776038999000036],
            [-80.795796256999949, 35.776111000000071],
            [-80.795766257999958, 35.776186000000052],
            [-80.79557325799999, 35.776668000000029],
            [-80.795399257999975, 35.777267000000052],
            [-80.795288256999982, 35.777874999000062],
            [-80.794870258999936, 35.780608000000029],
            [-80.794840257999965, 35.78095200100006],
            [-80.794855257999984, 35.781296001000044],
            [-80.794915258999936, 35.781637001000036],
            [-80.795019257999968, 35.781971000000055],
            [-80.795166258999984, 35.782294001000025],
            [-80.795372257999986, 35.782680001000074],
            [-80.795944257999963, 35.783758001000024],
            [-80.796432258999971, 35.784705001000077],
            [-80.796710259999941, 35.785181002000058],
            [-80.797032258999934, 35.785621002000028],
            [-80.797041258999968, 35.785633002000054],
            [-80.798715258999948, 35.787695003000067],
            [-80.798928259999968, 35.78792300200007],
            [-80.799177259999965, 35.78812600200007],
            [-80.79945725999994, 35.788299002000031],
            [-80.800875260999987, 35.789060002000042],
            [-80.802329260999954, 35.789839002000065],
            [-80.802677261999975, 35.79001700300006],
            [-80.802738259999956, 35.79005800300007],
            [-80.802993260999983, 35.790231002000041],
            [-80.803269260999969, 35.79047900300003],
            [-80.803501260999951, 35.79075600300007],
            [-80.803684260999944, 35.791055002000064],
            [-80.80375326099994, 35.79121000300006],
            [-80.804108260999953, 35.792007003000037],
            [-80.804574260999971, 35.793159004000074],
            [-80.805068261999963, 35.794380003000072],
            [-80.805195261999984, 35.794769003000056],
            [-80.80526826199997, 35.795167003000074],
            [-80.805288261999976, 35.795569003000026],
            [-80.80523526099995, 35.798694004000026],
            [-80.805219262999969, 35.799961004000068],
            [-80.805215260999944, 35.800180005000072],
            [-80.805199261999974, 35.801279004000037],
            [-80.80517826199997, 35.802844005000054],
            [-80.80517426199998, 35.80315300500007],
            [-80.805155261999971, 35.804808005000041],
            [-80.805147262999981, 35.805518005000067],
            [-80.805132262999962, 35.806900006000035],
            [-80.80590626299994, 35.80699200600003],
            [-80.806625262999944, 35.807127005000041],
            [-80.807692262999979, 35.807369006000044],
            [-80.807720262999965, 35.80764500600003],
            [-80.807792262999953, 35.807964006000077],
            [-80.807815262999952, 35.808116006000034],
            [-80.807832262999966, 35.808496006000041],
            [-80.807760263999967, 35.809030006000057],
            [-80.807706263999989, 35.809526006000056],
            [-80.807625262999977, 35.81007500700008],
            [-80.80755626399997, 35.810639006000031],
            [-80.807530263999979, 35.810853006000059],
            [-80.807423262999976, 35.81152400600007],
            [-80.807379262999973, 35.812035007000077],
            [-80.807375263999973, 35.812712007000073],
            [-80.807366263999938, 35.812925007000047],
            [-80.807324263999988, 35.813961008000035],
            [-80.80729026399996, 35.814277007000044],
            [-80.807266263999963, 35.814502007000044],
            [-80.807248262999963, 35.814553008000075],
            [-80.807099263999987, 35.814992008000047],
            [-80.806985263999934, 35.815259007000066],
            [-80.806948263999971, 35.815344007000078],
            [-80.806826262999948, 35.815524008000068],
            [-80.806243263999988, 35.816398008000078],
            [-80.806166262999966, 35.816491008000071],
            [-80.805997262999938, 35.81669800800006],
            [-80.805659262999939, 35.817023008000035],
            [-80.80561626299999, 35.817053008000073],
            [-80.805070262999948, 35.817443008000055],
            [-80.804453262999971, 35.817840008000076],
            [-80.803818262999982, 35.818283008000037],
            [-80.803570262999983, 35.81845000800007],
            [-80.803348261999986, 35.818602008000028],
            [-80.802888262999943, 35.818906008000056],
            [-80.802681261999965, 35.819070009000029],
            [-80.802498262999961, 35.819214009000063],
            [-80.802265262999981, 35.819480008000028],
            [-80.801995262999981, 35.819933009000067],
            [-80.801781261999963, 35.820377009000026],
            [-80.801535261999959, 35.820943009000075],
            [-80.801292262999937, 35.821540009000046],
            [-80.801187262999974, 35.821826009000063],
            [-80.800993261999963, 35.822351009000045],
            [-80.800914262999981, 35.822588010000061],
            [-80.800662261999946, 35.823445010000057],
            [-80.800510261999989, 35.82376701000004],
            [-80.800264260999938, 35.824021009000035],
            [-80.800077261999945, 35.824128010000038],
            [-80.799922261999939, 35.824217010000041],
            [-80.799476261999985, 35.824345010000059],
            [-80.799030261999974, 35.824435010000059],
            [-80.798643261999985, 35.824507010000048],
            [-80.798490260999984, 35.824535010000034],
            [-80.797386260999986, 35.824864010000056],
            [-80.79706926199998, 35.824959009000054],
            [-80.796291260999965, 35.82523701000008],
            [-80.795392260999961, 35.825577009000028],
            [-80.794622260999972, 35.825848010000072],
            [-80.793961258999957, 35.825956010000027],
            [-80.793232260999957, 35.825982011000065],
            [-80.792522259999942, 35.825985010000068],
            [-80.791971259999968, 35.826024010000026],
            [-80.791452258999982, 35.826191011000049],
            [-80.790814258999944, 35.82652801100005],
            [-80.789126258999943, 35.82750401100003],
            [-80.788490258999957, 35.827909012000077],
            [-80.788059258999965, 35.828311011000039],
            [-80.78779125899996, 35.828863011000067],
            [-80.787741257999983, 35.829138012000044],
            [-80.78773425899999, 35.830572011000072],
            [-80.787731258999941, 35.831209011000055],
            [-80.787729257999956, 35.831558012000073],
            [-80.78774225799998, 35.832805012000051],
            [-80.78774625899996, 35.833157012000072],
            [-80.787797258999944, 35.833772012000054],
            [-80.787909259999935, 35.834189012000024],
            [-80.788116258999935, 35.835107013000027],
            [-80.788195258999963, 35.835692013000028],
            [-80.788198258999955, 35.836278013000026],
            [-80.788131258999954, 35.837063013000034],
            [-80.788127258999964, 35.837178013000027],
            [-80.788128258999961, 35.837198012000044],
            [-80.789039258999935, 35.837674013000026],
            [-80.789874258999987, 35.838110013000062],
            [-80.792979260999971, 35.839869013000055],
            [-80.793469261999974, 35.840160013000059],
            [-80.793931260999955, 35.840371013000038],
            [-80.794410260999939, 35.840547014000038],
            [-80.795039260999943, 35.840721014000053],
            [-80.795879261999971, 35.84092101300007],
            [-80.796857261999946, 35.841155013000048],
            [-80.797559261999936, 35.841327014000058],
            [-80.799655262999977, 35.841840013000024],
            [-80.800501262999944, 35.842038013000035],
            [-80.801978263999956, 35.84239901400008],
            [-80.802436263999937, 35.842511014000024],
            [-80.803099263999968, 35.84268501300005],
            [-80.80388226499997, 35.842874013000028],
            [-80.804096264999941, 35.842928014000051],
            [-80.804605263999974, 35.842981013000042],
            [-80.805171264999956, 35.842959013000041],
            [-80.805802264999954, 35.842855013000076],
            [-80.806665264999936, 35.842693014000076],
            [-80.808018265999976, 35.842449013000078],
            [-80.808757265999986, 35.842363014000057],
            [-80.809925265999937, 35.842298013000061],
            [-80.810946266999963, 35.842236013000047],
            [-80.811487266999961, 35.842194013000039],
            [-80.812727265999968, 35.842128013000035],
            [-80.813452266999946, 35.842158013000073],
            [-80.814235267999948, 35.842196013000034],
            [-80.815429267999946, 35.842237013000044],
            [-80.815796266999939, 35.842266012000039],
            [-80.816302267999959, 35.842357013000026],
            [-80.816822267999953, 35.842450013000075],
            [-80.817754267999987, 35.842616013000054],
            [-80.817925268999943, 35.842650013000025],
            [-80.818850267999949, 35.84282401300004],
            [-80.819278267999948, 35.842940013000032],
            [-80.819716268999969, 35.843177014000048],
            [-80.820083268999952, 35.843471013000055],
            [-80.820174268999949, 35.843544013000042],
            [-80.821022269999958, 35.844266013000038],
            [-80.821471269999961, 35.843843013000026],
            [-80.821668269999975, 35.843710013000077],
            [-80.821786269999961, 35.843630013000052],
            [-80.822127269999953, 35.843448013000057],
            [-80.82250326999997, 35.843294013000047],
            [-80.822857269999986, 35.843188013000031],
            [-80.822898269999939, 35.843176013000061],
            [-80.823307269999987, 35.843097013000033],
            [-80.823725269999954, 35.843057013000077],
            [-80.824422270999946, 35.84301901300006],
            [-80.824465269999962, 35.84301501200008],
            [-80.825115270999959, 35.842951012000071],
            [-80.825803270999984, 35.842855013000076],
            [-80.826267270999949, 35.842772013000058],
            [-80.826718270999947, 35.842651013000079],
            [-80.827151270999934, 35.842492012000037],
            [-80.827562271999966, 35.842298013000061],
            [-80.82917127199994, 35.841078011000036],
            [-80.829469271999983, 35.840829012000029],
            [-80.829713271999935, 35.840545013000053],
            [-80.829899271999977, 35.840231012000061],
            [-80.830396271999973, 35.839098012000079],
            [-80.830415270999936, 35.83905001100004],
            [-80.830761270999972, 35.838194011000041],
            [-80.830846271999974, 35.837831011000048],
            [-80.830880271999945, 35.837463012000057],
            [-80.83087427199996, 35.837413011000024],
            [-80.83086227199999, 35.837093011000036],
            [-80.830843271999981, 35.836831011000072],
            [-80.830876271999955, 35.836570011000049],
            [-80.83096027199997, 35.836317011000062],
            [-80.83119727199994, 35.835891010000068],
            [-80.831258271999957, 35.835780010000065],
            [-80.831501272999958, 35.835224011000037],
            [-80.831528270999968, 35.835054011000068],
            [-80.831506271999956, 35.834884011000042],
            [-80.83147427199998, 35.834717011000066],
            [-80.831020271999989, 35.83234501000004],
            [-80.833146271999965, 35.832339011000045],
            [-80.834865272999934, 35.832335010000065],
            [-80.835204273999977, 35.832325010000034],
            [-80.835274272999982, 35.832320011000036],
            [-80.835542272999987, 35.832299010000042],
            [-80.837973274999968, 35.832046010000056],
            [-80.838119272999961, 35.832038010000076],
            [-80.841664274999971, 35.832105010000078],
            [-80.841890275999958, 35.832131010000069],
            [-80.842102274999945, 35.832199010000068],
            [-80.842289274999985, 35.832306009000035],
            [-80.842441275999988, 35.832444010000074],
            [-80.842549275999943, 35.832608010000058],
            [-80.842627274999984, 35.83276901000005],
            [-80.842841274999955, 35.833210010000073],
            [-80.842960275999985, 35.833405010000035],
            [-80.84312027499999, 35.833581010000046],
            [-80.843315275999942, 35.833730010000068],
            [-80.843970275999936, 35.834168010000042],
            [-80.844076276999942, 35.834239010000033],
            [-80.844405275999975, 35.834385010000062],
            [-80.844759275999934, 35.834483010000042],
            [-80.845129276999955, 35.83452901000004],
            [-80.850804278999988, 35.83465601000006],
            [-80.850763278999978, 35.835790011000029],
            [-80.852767278999977, 35.835787010000047],
            [-80.855145278999942, 35.835783010000057],
            [-80.855423278999979, 35.835771011000077],
            [-80.855700279999951, 35.835740010000052],
            [-80.856051279999974, 35.835653010000044],
            [-80.85637627899996, 35.835514011000043],
            [-80.857462279999936, 35.834882010000058],
            [-80.858711279999966, 35.834120010000049],
            [-80.859179279999978, 35.833871009000063],
            [-80.859568279999962, 35.833747009000035],
            [-80.859760279999989, 35.83370000900004],
            [-80.859935280999935, 35.833657010000024],
            [-80.860377280999955, 35.833577010000056],
            [-80.863907281999957, 35.833259009000074],
            [-80.864598280999985, 35.833239009000067],
            [-80.865290281999989, 35.833248009000044],
            [-80.866989282999953, 35.833409009000036],
            [-80.867179281999938, 35.833430010000029],
            [-80.86736928199997, 35.833461009000075],
            [-80.867738282999937, 35.833551009000075],
            [-80.868317283999943, 35.833812010000031],
            [-80.86886528399998, 35.834113010000067],
            [-80.869390282999973, 35.834316009000077],
            [-80.869931282999971, 35.834485009000048],
            [-80.870761283999968, 35.834722009000075],
            [-80.871330283999953, 35.834856009000077],
            [-80.871908284999961, 35.83495700900005],
            [-80.87249428299998, 35.835023009000054],
            [-80.872688284999981, 35.834715009000035],
            [-80.873401283999954, 35.835285008000028],
            [-80.873588283999936, 35.835515009000062],
            [-80.873724283999934, 35.835768010000038],
            [-80.873805283999957, 35.836037009000052],
            [-80.874094284999956, 35.837341010000046],
            [-80.874501285999941, 35.837346010000033],
            [-80.874688284999934, 35.837343009000051],
            [-80.874867285999983, 35.837311010000064],
            [-80.875062285999945, 35.837250010000048],
            [-80.877347285999974, 35.837247010000056],
            [-80.878843285999949, 35.837247009000066],
            [-80.878888286999938, 35.838263010000048],
            [-80.880219286999989, 35.838213010000061],
            [-80.880287286999987, 35.838234009000075],
            [-80.880310286999986, 35.838275009000029],
            [-80.880351286999939, 35.839101010000036],
            [-80.880471286999978, 35.842044011000041],
            [-80.880483287999937, 35.842826010000067],
            [-80.881153286999961, 35.842808011000045],
            [-80.881779286999972, 35.842846011000063],
            [-80.882162287999961, 35.842857010000046],
            [-80.882653288999961, 35.842872010000065],
            [-80.883520287999943, 35.842892010000071],
            [-80.884743287999981, 35.842939010000066],
            [-80.885901288999946, 35.842967010000052],
            [-80.886362287999987, 35.842953010000031],
            [-80.886904289999961, 35.842971010000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 79,
        "SolutionID": "PI-30:B",
        "Shape_Length": 0.49691866838517318,
        "Shape_Area": 0.0055389564186813867
      }
    },
    {
      "type": "Feature",
      "id": 80,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.797538249999945, 35.626347967000072],
            [-80.797167249999973, 35.626824968000051],
            [-80.794627249999962, 35.630095968000035],
            [-80.793573248999962, 35.631453969000063],
            [-80.79317924999998, 35.631961969000031],
            [-80.792655248999949, 35.632635970000024],
            [-80.791064247999941, 35.634599970000068],
            [-80.790859248999936, 35.634851970000057],
            [-80.790150248999964, 35.635698970000078],
            [-80.789974247999965, 35.635901970000077],
            [-80.789846247999947, 35.636049970000045],
            [-80.789689248999935, 35.636249970000051],
            [-80.788830248999943, 35.63738397000003],
            [-80.788456247999989, 35.637876970000036],
            [-80.788073247999989, 35.638402970000072],
            [-80.787824247999936, 35.638682971000037],
            [-80.787584246999984, 35.638936971000078],
            [-80.787020246999987, 35.639338971000029],
            [-80.786669246999963, 35.639588970000034],
            [-80.785770247999949, 35.640209971000047],
            [-80.785010246999946, 35.640725971000052],
            [-80.784033246999968, 35.641335972000036],
            [-80.783336245999976, 35.64175397200006],
            [-80.782984246999945, 35.641930972000068],
            [-80.782613245999983, 35.642081972000028],
            [-80.782089245999941, 35.642280972000037],
            [-80.782241245999955, 35.642560972000069],
            [-80.782656246999977, 35.643256972000074],
            [-80.782836246999977, 35.643559972000048],
            [-80.783048246999954, 35.644018972000026],
            [-80.783196245999989, 35.644477972000061],
            [-80.783306246999985, 35.645088972000053],
            [-80.783341246999953, 35.645568973000024],
            [-80.783318245999965, 35.646594973000049],
            [-80.784882246999985, 35.646617973000048],
            [-80.78551424799997, 35.646720973000072],
            [-80.786010247999968, 35.646890973000041],
            [-80.786712247999958, 35.647229974000027],
            [-80.78760424799998, 35.647803973000066],
            [-80.788155248999942, 35.648168972000065],
            [-80.788284248999958, 35.648254973000064],
            [-80.788696247999951, 35.648610973000075],
            [-80.789044249999961, 35.649020974000052],
            [-80.789503248999949, 35.64971997300006],
            [-80.790036249999957, 35.65046297400005],
            [-80.790294249999988, 35.650775974000055],
            [-80.79063924899998, 35.651066974000059],
            [-80.790932249999969, 35.651236974000028],
            [-80.790983249999954, 35.651265974000069],
            [-80.791414249999946, 35.651410973000054],
            [-80.791900249999969, 35.651509973000032],
            [-80.792301250999969, 35.651525973000048],
            [-80.793920250999975, 35.651605973000073],
            [-80.795796250999956, 35.651708973000041],
            [-80.796274250999943, 35.651774973000045],
            [-80.796810251999943, 35.651924973000064],
            [-80.797364251999966, 35.652194974000054],
            [-80.798383251999951, 35.652808973000049],
            [-80.799057251999955, 35.653213974000039],
            [-80.799337252999976, 35.653382973000078],
            [-80.799911251999958, 35.653782973000034],
            [-80.800560252999958, 35.654267974000049],
            [-80.80085625199996, 35.654513974000054],
            [-80.801335252999934, 35.654994974000033],
            [-80.801527252999961, 35.655287974000032],
            [-80.801647251999952, 35.655589974000065],
            [-80.801773252999965, 35.655943974000024],
            [-80.80263725399999, 35.658361975000048],
            [-80.803037253999946, 35.659269975000029],
            [-80.803468252999949, 35.658966974000066],
            [-80.806310253999982, 35.656971974000044],
            [-80.806594254999936, 35.656736974000069],
            [-80.806824254999981, 35.656503974000032],
            [-80.807006254999976, 35.656251974000043],
            [-80.807148253999969, 35.655955974000051],
            [-80.807482254999968, 35.65513397400008],
            [-80.807704254999976, 35.654542974000037],
            [-80.808107254999982, 35.653673973000025],
            [-80.808794254999952, 35.652220974000045],
            [-80.808911254999941, 35.651835973000061],
            [-80.808982253999943, 35.651489973000025],
            [-80.808990254999969, 35.651182973000061],
            [-80.809028254999987, 35.650428972000043],
            [-80.808981254999935, 35.649700972000062],
            [-80.808991254999967, 35.648748972000078],
            [-80.809017253999968, 35.648167972000067],
            [-80.809081254999967, 35.647527972000034],
            [-80.809213254999975, 35.646439972000053],
            [-80.809250255999984, 35.645998972000029],
            [-80.809240254999963, 35.645519972000045],
            [-80.809202254999946, 35.645120972000029],
            [-80.809199253999964, 35.645085971000071],
            [-80.809084254999959, 35.644411971000068],
            [-80.809000253999955, 35.643919971000059],
            [-80.808833254999968, 35.643072971000038],
            [-80.808706253999958, 35.641605971000047],
            [-80.808669254999984, 35.641331971000056],
            [-80.808649253999988, 35.640814970000065],
            [-80.808653254999967, 35.640296971000055],
            [-80.808659253999963, 35.640240970000036],
            [-80.808697253999981, 35.639895970000055],
            [-80.808710253999948, 35.639785970000048],
            [-80.808777253999949, 35.639183970000033],
            [-80.808857254999964, 35.638192970000034],
            [-80.809119254999985, 35.637167969000075],
            [-80.809217253999975, 35.636788970000055],
            [-80.809389252999949, 35.636248970000054],
            [-80.809430253999949, 35.636122970000031],
            [-80.809967254999947, 35.634437969000032],
            [-80.810036253999954, 35.634221969000066],
            [-80.810196254999937, 35.633669969000039],
            [-80.810454254999968, 35.632988969000053],
            [-80.810747254999967, 35.632428968000056],
            [-80.810800253999957, 35.632346969000025],
            [-80.810921254999982, 35.632163969000032],
            [-80.811213254999984, 35.63193596900004],
            [-80.811668254999972, 35.631654969000067],
            [-80.812058254999954, 35.631426969000074],
            [-80.812835254999982, 35.630979969000066],
            [-80.813322254999946, 35.630716968000058],
            [-80.81366225499994, 35.630545968000035],
            [-80.813981254999987, 35.630438969000068],
            [-80.814323254999977, 35.630337968000049],
            [-80.814417255999956, 35.630317969000032],
            [-80.814836254999989, 35.630227968000042],
            [-80.81545925599994, 35.630084968000062],
            [-80.815855255999963, 35.629937968000036],
            [-80.816265255999951, 35.629739967000035],
            [-80.81645025499995, 35.629628968000077],
            [-80.816770256999973, 35.629415968000046],
            [-80.816813255999989, 35.629386967000073],
            [-80.817136256999959, 35.629139968000061],
            [-80.817489255999988, 35.628840967000031],
            [-80.81763925599995, 35.62873096800007],
            [-80.818058256999961, 35.628422967000063],
            [-80.818396256999961, 35.628136968000035],
            [-80.81860325599996, 35.62794296800007],
            [-80.818940256999952, 35.627592967000055],
            [-80.81898625599996, 35.62754196700007],
            [-80.819535256999984, 35.62692596800008],
            [-80.82007725699998, 35.626348967000069],
            [-80.820262256999968, 35.62619896700005],
            [-80.820486255999981, 35.626080967000064],
            [-80.820530256999973, 35.626065968000034],
            [-80.820750256999986, 35.625987967000071],
            [-80.821077256999956, 35.625937967000027],
            [-80.821272256999976, 35.625938967000025],
            [-80.821633257999963, 35.625942966000025],
            [-80.821697257999972, 35.625944967000066],
            [-80.822165256999938, 35.625959967000028],
            [-80.822501256999942, 35.625948967000056],
            [-80.822806256999968, 35.625905967000051],
            [-80.822968257999946, 35.62583896700005],
            [-80.823030256999971, 35.625812967000059],
            [-80.823192257999949, 35.625722967000058],
            [-80.823254257999963, 35.625687967000033],
            [-80.823586257999978, 35.625472967000064],
            [-80.82381825799996, 35.625372966000043],
            [-80.824199257999965, 35.625369966000051],
            [-80.824042257999963, 35.625932968000029],
            [-80.823940256999947, 35.626503967000076],
            [-80.823892257999944, 35.627078968000035],
            [-80.823899257999983, 35.627655967000067],
            [-80.823962257999938, 35.628230968000025],
            [-80.824170256999935, 35.629556968000031],
            [-80.824247257999957, 35.630045968000047],
            [-80.824363258999938, 35.63078796800005],
            [-80.824451258999943, 35.631346968000059],
            [-80.824517257999958, 35.63165796800007],
            [-80.824618257999987, 35.631963968000036],
            [-80.824782257999971, 35.632384969000043],
            [-80.824976258999982, 35.63279796900008],
            [-80.825229258999968, 35.633188969000059],
            [-80.825537258999987, 35.63355296900005],
            [-80.825727258999962, 35.633759968000049],
            [-80.825850258999935, 35.633893968000052],
            [-80.825959258999944, 35.633991968000032],
            [-80.826201258999959, 35.634208969000042],
            [-80.826587257999961, 35.634495968000067],
            [-80.827005258999975, 35.634750968000048],
            [-80.828022259999955, 35.635313969000038],
            [-80.829737260999934, 35.636177969000073],
            [-80.831265261999988, 35.636947969000062],
            [-80.831722260999982, 35.637178969000047],
            [-80.834923261999961, 35.638797969000052],
            [-80.835476262999975, 35.63910596900007],
            [-80.835890261999964, 35.639361969000049],
            [-80.836225261999971, 35.639582970000049],
            [-80.836622262999981, 35.639868969000077],
            [-80.836823262999985, 35.640022970000075],
            [-80.837125261999972, 35.639734970000063],
            [-80.837926262999986, 35.638954969000054],
            [-80.838166262999948, 35.638653969000075],
            [-80.838569262999954, 35.638204969000071],
            [-80.837736262999954, 35.637913969000067],
            [-80.837293263999982, 35.637596968000025],
            [-80.836961262999978, 35.637210968000034],
            [-80.83682526299998, 35.63689296900003],
            [-80.83683726199996, 35.635960968000063],
            [-80.837164262999977, 35.635313969000038],
            [-80.837209261999988, 35.635302968000076],
            [-80.837462262999964, 35.635073969000075],
            [-80.837742261999949, 35.634638968000047],
            [-80.837770262999982, 35.633859968000024],
            [-80.83810626199994, 35.633218968000051],
            [-80.838134261999983, 35.632325968000032],
            [-80.83844226299999, 35.631615967000073],
            [-80.838498262999963, 35.630905968000036],
            [-80.839171262999969, 35.629965967000032],
            [-80.839451262999944, 35.629187967000064],
            [-80.840040262999935, 35.628454967000039],
            [-80.840657262999969, 35.627400966000039],
            [-80.841062262999969, 35.627193967000039],
            [-80.842003262999981, 35.626713966000068],
            [-80.843013262999989, 35.626323966000029],
            [-80.843405263999955, 35.626437967000072],
            [-80.843910263999987, 35.626369966000027],
            [-80.844443264999938, 35.626804966000066],
            [-80.844892264999942, 35.62694196700005],
            [-80.845089263999967, 35.62719396600005],
            [-80.845201264999957, 35.627605966000033],
            [-80.845493264999959, 35.627784967000025],
            [-80.845987265999952, 35.628086966000069],
            [-80.846576264999953, 35.628154967000057],
            [-80.847305264999989, 35.628017966000073],
            [-80.84783826599994, 35.627809966000029],
            [-80.848399265999944, 35.627443967000033],
            [-80.849184265999952, 35.627259967000043],
            [-80.849745265999957, 35.626984967000055],
            [-80.850222266999936, 35.626892966000071],
            [-80.850326264999978, 35.626916966000067],
            [-80.850727266999968, 35.627007966000065],
            [-80.851175266999974, 35.626961967000057],
            [-80.851596265999945, 35.62714496600006],
            [-80.85190526599996, 35.627167966000059],
            [-80.852083266999955, 35.627047966000077],
            [-80.852222265999956, 35.626953966000031],
            [-80.852858265999942, 35.626525966000031],
            [-80.853840266999953, 35.626731966000079],
            [-80.854317267999988, 35.627143965000073],
            [-80.854317267999988, 35.627441966000049],
            [-80.854626266999958, 35.62764796600004],
            [-80.855103266999947, 35.627784966000036],
            [-80.856758267999965, 35.627761966000037],
            [-80.858020267999962, 35.627875966000033],
            [-80.859086268999988, 35.627805966000039],
            [-80.859507268999948, 35.628080966000027],
            [-80.860040268999967, 35.628607966000061],
            [-80.860377268999969, 35.628721967000047],
            [-80.860658268999941, 35.628996965000056],
            [-80.861387269999966, 35.629408966000028],
            [-80.861724269999968, 35.629408966000028],
            [-80.861696268999935, 35.629110967000031],
            [-80.861527268999964, 35.628537966000067],
            [-80.862046268999961, 35.628482966000036],
            [-80.86238426999995, 35.628447966000067],
            [-80.86220426899996, 35.627896966000037],
            [-80.861904269999968, 35.627117966000071],
            [-80.861563269999976, 35.626348965000034],
            [-80.86105226899997, 35.62526596500004],
            [-80.860435268999936, 35.623983965000036],
            [-80.859050267999976, 35.621109964000027],
            [-80.858616267999935, 35.620219965000047],
            [-80.858222267999963, 35.61931796500005],
            [-80.85786726799995, 35.618404965000025],
            [-80.857648267999934, 35.61760696500005],
            [-80.85749526799998, 35.616798963000065],
            [-80.857409267999969, 35.615983964000065],
            [-80.857404266999936, 35.615849964000063],
            [-80.857395267999948, 35.615631963000055],
            [-80.857387267999968, 35.615278963000037],
            [-80.857415267999954, 35.614588963000074],
            [-80.857484266999961, 35.613900964000038],
            [-80.85759326799996, 35.613215964000062],
            [-80.857844267999951, 35.611846962000072],
            [-80.857964267999989, 35.611159962000045],
            [-80.858404266999969, 35.608766962000061],
            [-80.858583267999961, 35.607736962000047],
            [-80.858626267999966, 35.607498962000079],
            [-80.858781267999973, 35.606648961000076],
            [-80.85887626799996, 35.606128961000024],
            [-80.858946267999954, 35.605744962000074],
            [-80.859142266999982, 35.604623961000073],
            [-80.859186266999984, 35.604374961000076],
            [-80.859623266999961, 35.601958960000047],
            [-80.859737267999947, 35.601332960000036],
            [-80.859881267999981, 35.600533960000064],
            [-80.85992026699995, 35.600317960000041],
            [-80.859942266999951, 35.600196960000062],
            [-80.860087266999983, 35.59934996000004],
            [-80.860201266999979, 35.598435960000074],
            [-80.86024226699999, 35.59791596000008],
            [-80.860036266999941, 35.597577960000024],
            [-80.859989266999946, 35.597501959000056],
            [-80.85971726799994, 35.597055960000034],
            [-80.859182265999948, 35.596964959000047],
            [-80.859122266999975, 35.596957959000065],
            [-80.858495265999977, 35.596882959000027],
            [-80.858099266999943, 35.596875960000034],
            [-80.857656265999935, 35.596915959000057],
            [-80.857088265999948, 35.597020959000076],
            [-80.856837265999957, 35.597066960000063],
            [-80.856413265999947, 35.597138960000052],
            [-80.85401126499994, 35.597547960000043],
            [-80.853614264999976, 35.597615961000031],
            [-80.853360264999935, 35.59765795900006],
            [-80.853067265999982, 35.597706960000039],
            [-80.852130265999961, 35.597862960000043],
            [-80.851497263999988, 35.59797696000004],
            [-80.851082264999945, 35.598050959000034],
            [-80.85100926399997, 35.598061960000052],
            [-80.850782264999964, 35.59809996000007],
            [-80.849611263999975, 35.598290960000043],
            [-80.848863262999942, 35.598429960000033],
            [-80.848495263999951, 35.598498960000029],
            [-80.84809426399994, 35.598623960000054],
            [-80.847742263999976, 35.598747961000072],
            [-80.847378262999939, 35.598962961000041],
            [-80.847065262999934, 35.597857960000056],
            [-80.847013262999951, 35.597464959000035],
            [-80.847006262999969, 35.59741796000003],
            [-80.846973263999985, 35.597160960000053],
            [-80.846959262999974, 35.596695960000034],
            [-80.847410262999972, 35.594780959000047],
            [-80.847508263999941, 35.594320960000061],
            [-80.84759126299997, 35.593535959000064],
            [-80.847662262999961, 35.592866959000048],
            [-80.847709262999956, 35.592576959000041],
            [-80.84780826299999, 35.591952959000025],
            [-80.847924262999982, 35.591314959000044],
            [-80.848652262999963, 35.590042958000026],
            [-80.848867263999978, 35.589678959000025],
            [-80.849063262999948, 35.589343958000029],
            [-80.849520263999977, 35.58825495800005],
            [-80.850096263999944, 35.586989957000071],
            [-80.850478263999946, 35.586149958000078],
            [-80.850722263999955, 35.585724957000025],
            [-80.850771263999945, 35.585638958000061],
            [-80.850913263999985, 35.585392957000067],
            [-80.851216264999948, 35.584673957000064],
            [-80.851370263999968, 35.584235957000033],
            [-80.851921263999941, 35.582738956000071],
            [-80.853051263999987, 35.57965495600007],
            [-80.853115263999939, 35.579479956000057],
            [-80.854240265999977, 35.579783956000028],
            [-80.854866263999952, 35.579843956000047],
            [-80.855495264999945, 35.579857956000069],
            [-80.856176264999988, 35.579849956000032],
            [-80.856406264999976, 35.57984695600004],
            [-80.856291264999982, 35.579416956000046],
            [-80.856100264999952, 35.578571956000076],
            [-80.85596626499995, 35.577735956000026],
            [-80.855884264999986, 35.576894955000057],
            [-80.855860263999944, 35.576090955000041],
            [-80.855886263999935, 35.575286955000024],
            [-80.855930264999984, 35.57470995500006],
            [-80.856004264999967, 35.574135955000031],
            [-80.856036264999943, 35.573961955000073],
            [-80.856109265999976, 35.573564955000052],
            [-80.856315264999978, 35.572702954000079],
            [-80.856545264999966, 35.571844954000028],
            [-80.857805264999968, 35.567320953000035],
            [-80.858190264999962, 35.565940953000052],
            [-80.858417264999957, 35.565096952000033],
            [-80.858601265999937, 35.564256952000051],
            [-80.858738264999943, 35.563410953000073],
            [-80.858816264999973, 35.562575952000032],
            [-80.858835264999982, 35.561737951000055],
            [-80.858806263999952, 35.560895952000067],
            [-80.858803264999949, 35.560865952000029],
            [-80.858715263999954, 35.560055952000027],
            [-80.858594263999976, 35.559215952000045],
            [-80.858429264999984, 35.558015951000073],
            [-80.85824426399995, 35.556694951000054],
            [-80.858121264999966, 35.555858951000062],
            [-80.858012263999967, 35.555020951000074],
            [-80.857940263999978, 35.554280951000067],
            [-80.857909263999943, 35.553537950000077],
            [-80.857907263999948, 35.553400951000071],
            [-80.857815263999953, 35.553342950000058],
            [-80.857687263999935, 35.553266950000079],
            [-80.857192262999945, 35.55297395000008],
            [-80.856943263999938, 35.552862950000076],
            [-80.856814263999979, 35.55280595000005],
            [-80.856565263999983, 35.552721950000034],
            [-80.856387262999988, 35.552724950000027],
            [-80.856323263999968, 35.552725949000035],
            [-80.856065262999948, 35.552751950000072],
            [-80.855826262999983, 35.55280594900006],
            [-80.855750262999948, 35.552836951000074],
            [-80.855588262999959, 35.552903950000029],
            [-80.855431262999957, 35.553037950000032],
            [-80.855300262999947, 35.553184951000048],
            [-80.855226263999953, 35.553283951000026],
            [-80.854231263999964, 35.55483195000005],
            [-80.853220262999969, 35.556420951000064],
            [-80.852988262999986, 35.556782951000059],
            [-80.85185426299995, 35.558549952000078],
            [-80.851524262999988, 35.559075952000057],
            [-80.851128262999964, 35.559704952000061],
            [-80.850820261999957, 35.560195952000072],
            [-80.850397262999934, 35.560869952000076],
            [-80.84954626199999, 35.560000952000053],
            [-80.848957261999942, 35.55953295200004],
            [-80.847870260999969, 35.558813952000037],
            [-80.847758261999957, 35.558740951000061],
            [-80.847066260999952, 35.558295952000037],
            [-80.844986260999974, 35.556956952000064],
            [-80.844927260999953, 35.556919951000054],
            [-80.844263259999934, 35.556495951000045],
            [-80.843609260999983, 35.556079951000072],
            [-80.843255259999978, 35.555853951000074],
            [-80.842700259999958, 35.555528952000031],
            [-80.842598258999942, 35.55546895100008],
            [-80.842348258999948, 35.555348951000042],
            [-80.841956259999961, 35.555211951000047],
            [-80.841318258999934, 35.554988951000041],
            [-80.842014258999939, 35.554010951000066],
            [-80.842992258999971, 35.55263995100006],
            [-80.843709259999969, 35.551632951000045],
            [-80.845140259999937, 35.549636950000036],
            [-80.845444259999965, 35.54916895000008],
            [-80.845520259999944, 35.549055950000024],
            [-80.845750259999988, 35.548712950000038],
            [-80.846099260999949, 35.548042950000024],
            [-80.846170259999951, 35.547905950000029],
            [-80.846312259999934, 35.547396949000074],
            [-80.846329260999937, 35.547315950000041],
            [-80.846914260999938, 35.544508949000033],
            [-80.847037260999969, 35.543914948000065],
            [-80.847164259999943, 35.543309948000058],
            [-80.84718626099999, 35.543205949000026],
            [-80.844841258999963, 35.54293394900003],
            [-80.843306258999974, 35.542755949000025],
            [-80.842928258999962, 35.542690948000029],
            [-80.842416258999947, 35.542575949000025],
            [-80.84188525899998, 35.542390948000048],
            [-80.841461258999971, 35.542186948000051],
            [-80.841221258999951, 35.54205594900003],
            [-80.839996257999985, 35.541386949000071],
            [-80.839104257999963, 35.540898948000063],
            [-80.838679256999967, 35.54066694800008],
            [-80.837516256999947, 35.540031948000035],
            [-80.836538256999972, 35.539522948000069],
            [-80.835818256999971, 35.539148948000047],
            [-80.835524255999985, 35.538995948000036],
            [-80.834772255999951, 35.538589948000038],
            [-80.834267255999976, 35.538317948000042],
            [-80.833720256999982, 35.538065948000053],
            [-80.831704254999977, 35.537382947000026],
            [-80.831434254999976, 35.537287948000028],
            [-80.830289255999958, 35.536884948000079],
            [-80.829960254999946, 35.536769948000028],
            [-80.829544254999973, 35.536594948000072],
            [-80.829356254999936, 35.536493948000043],
            [-80.829153254999937, 35.536385948000031],
            [-80.828790254999944, 35.536143947000028],
            [-80.825585252999986, 35.533805948000065],
            [-80.825333253999986, 35.533669947000078],
            [-80.825007252999967, 35.533526947000041],
            [-80.82465625399999, 35.533434947000046],
            [-80.824289252999961, 35.533396947000028],
            [-80.823920252999983, 35.53341494700004],
            [-80.823562252999977, 35.533485948000077],
            [-80.822973252999986, 35.533651948000056],
            [-80.821921252999971, 35.534051948000069],
            [-80.822061252999958, 35.534273948000077],
            [-80.822210251999934, 35.534684948000063],
            [-80.822329252999964, 35.536182948000032],
            [-80.821096252999951, 35.538748948000034],
            [-80.820828251999956, 35.539452948000076],
            [-80.821253252999952, 35.541539949000025],
            [-80.821365252999954, 35.542204949000052],
            [-80.822348252999973, 35.544287949000079],
            [-80.822966252999947, 35.546004950000054],
            [-80.822884252999984, 35.546709950000036],
            [-80.822778253999957, 35.547632950000036],
            [-80.823126253999988, 35.548178951000068],
            [-80.825044254999966, 35.550400951000029],
            [-80.826111253999954, 35.552736952000032],
            [-80.827081254999939, 35.55919795300008],
            [-80.827387255999952, 35.56128495400003],
            [-80.827285255999982, 35.561169952000057],
            [-80.826845254999967, 35.560760953000056],
            [-80.826299253999935, 35.560393952000027],
            [-80.825990253999976, 35.56022695300004],
            [-80.825544254999954, 35.559986953000077],
            [-80.82469725499999, 35.559638953000047],
            [-80.824286253999958, 35.559469953000075],
            [-80.823939253999981, 35.559326953000038],
            [-80.822714253999948, 35.558821953000063],
            [-80.821127252999986, 35.558168952000074],
            [-80.821044253999958, 35.558145953000064],
            [-80.820690253999942, 35.558043953000038],
            [-80.820027252999978, 35.557921953000061],
            [-80.81922025199998, 35.557872953000071],
            [-80.81805325199997, 35.557937953000078],
            [-80.817417251999984, 35.557972953000046],
            [-80.817053251999937, 35.55799395300005],
            [-80.816286251999941, 35.558036952000066],
            [-80.812109250999981, 35.558289953000042],
            [-80.81152825099997, 35.55653095200006],
            [-80.811394250999967, 35.556284952000055],
            [-80.811232250999979, 35.556051953000065],
            [-80.811042250999947, 35.555831952000062],
            [-80.810640249999949, 35.555930953000029],
            [-80.810121250999941, 35.555977953000024],
            [-80.807084249999946, 35.555812952000053],
            [-80.806164248999949, 35.555762953000055],
            [-80.805742247999945, 35.555402952000065],
            [-80.804448248999961, 35.55361595200003],
            [-80.804256248999934, 35.552997952000055],
            [-80.804050247999953, 35.552782953000076],
            [-80.803924247999987, 35.552427953000063],
            [-80.803918247999945, 35.552272952000067],
            [-80.803918248999935, 35.551712952000059],
            [-80.803582247999941, 35.551047952000033],
            [-80.803105247999952, 35.550543951000066],
            [-80.802594247999934, 35.55017795200007],
            [-80.80220924799994, 35.549902951000036],
            [-80.801638246999971, 35.549286952000045],
            [-80.801035246999959, 35.548635951000051],
            [-80.800107245999982, 35.547635951000075],
            [-80.800079246999985, 35.547428951000029],
            [-80.799967246999984, 35.547428951000029],
            [-80.799659245999976, 35.54713195100004],
            [-80.799463245999959, 35.546764951000057],
            [-80.798875245999966, 35.546192950000034],
            [-80.798707245999935, 35.545344951000061],
            [-80.79842724599996, 35.54472695100003],
            [-80.798118245999945, 35.544542951000039],
            [-80.798146245999988, 35.545413951000057],
            [-80.798432245999948, 35.546873952000055],
            [-80.79855024699998, 35.548630951000064],
            [-80.798537246999956, 35.549115952000079],
            [-80.79852524599994, 35.54952095200008],
            [-80.79816524599994, 35.550683952000043],
            [-80.797804245999941, 35.551482952000072],
            [-80.797704246999956, 35.552523953000048],
            [-80.797624245999941, 35.55246995300007],
            [-80.79468624499998, 35.552331953000078],
            [-80.790759244999947, 35.552073952000057],
            [-80.790825243999961, 35.55248595300003],
            [-80.790896243999953, 35.552933953000036],
            [-80.790905243999987, 35.553364953000028],
            [-80.790865243999974, 35.554176953000024],
            [-80.790853243999948, 35.554417953000041],
            [-80.790829243999951, 35.554897953000079],
            [-80.790825243999961, 35.55498095300004],
            [-80.790785243999949, 35.555796953000026],
            [-80.790756243999965, 35.556406953000078],
            [-80.790718243999947, 35.557194953000078],
            [-80.79063124399994, 35.558578953000051],
            [-80.790615244999969, 35.558840954000061],
            [-80.790581244999942, 35.559372954000025],
            [-80.790502244999971, 35.559650953000073],
            [-80.790370244999963, 35.559915955000065],
            [-80.790188243999978, 35.560159954000028],
            [-80.78996224499997, 35.560376954000048],
            [-80.78969624399997, 35.560562954000034],
            [-80.789398243999983, 35.560711955000045],
            [-80.789074243999949, 35.560820954000064],
            [-80.788333243999944, 35.561009954000042],
            [-80.787831242999971, 35.56043995400006],
            [-80.786952243999963, 35.559527954000032],
            [-80.786161242999981, 35.558761954000033],
            [-80.786107242999947, 35.558709954000051],
            [-80.786100243999954, 35.558702954000069],
            [-80.783882241999947, 35.556554953000045],
            [-80.782918241999937, 35.555656954000028],
            [-80.782306240999958, 35.555162953000035],
            [-80.781769241999939, 35.554854953000074],
            [-80.781340241999942, 35.554631953000069],
            [-80.780041240999935, 35.554092953000065],
            [-80.779657239999949, 35.553933954000058],
            [-80.778547239999966, 35.55349095300005],
            [-80.777846239999974, 35.553210954000065],
            [-80.777419239999972, 35.553022953000038],
            [-80.777047239999945, 35.552858954000044],
            [-80.776752239999951, 35.552709953000033],
            [-80.776464239999939, 35.552558953000073],
            [-80.77599123899995, 35.55224495300007],
            [-80.775843239999972, 35.552135953000061],
            [-80.775034239999968, 35.552906953000047],
            [-80.773326238999971, 35.554570954000042],
            [-80.773261238999964, 35.554634954000051],
            [-80.772721238999964, 35.555161955000074],
            [-80.770296237999958, 35.557496954000044],
            [-80.77176523899999, 35.557804955000051],
            [-80.772146237999948, 35.557893954000065],
            [-80.772456238999951, 35.558004955000058],
            [-80.77265123899997, 35.558114955000065],
            [-80.772729238999943, 35.558158955000067],
            [-80.772971238999958, 35.558343954000065],
            [-80.773161239999979, 35.558524955000053],
            [-80.774567238999964, 35.560057954000058],
            [-80.774737239999979, 35.560418955000046],
            [-80.774842239999941, 35.560863955000059],
            [-80.774844239999936, 35.560872955000036],
            [-80.774907240999937, 35.561269955000057],
            [-80.774914238999941, 35.562063955000042],
            [-80.77500723899999, 35.565925956000058],
            [-80.774997239999948, 35.566571956000075],
            [-80.775040240999942, 35.567026956000063],
            [-80.775153239999952, 35.567360956000073],
            [-80.775246240999934, 35.567636956000058],
            [-80.775255240999968, 35.567654956000069],
            [-80.775479239999981, 35.568093956000041],
            [-80.774349239999935, 35.568667957000059],
            [-80.772997238999949, 35.569364956000072],
            [-80.771199239999987, 35.570289958000046],
            [-80.767211237999959, 35.572343957000044],
            [-80.766514237999957, 35.572710958000073],
            [-80.765951238999946, 35.573007957000073],
            [-80.763015236999934, 35.574506958000029],
            [-80.762530236999964, 35.574796958000036],
            [-80.762133236999944, 35.575105959000041],
            [-80.761827235999988, 35.575479959000063],
            [-80.761642235999943, 35.575890959000048],
            [-80.761528236999936, 35.576228958000058],
            [-80.761456236999948, 35.576631959000053],
            [-80.761479236999946, 35.57835895900007],
            [-80.761510237999971, 35.579859960000078],
            [-80.761572235999949, 35.581222959000058],
            [-80.761790236999957, 35.582882960000063],
            [-80.761857236999958, 35.583430960000044],
            [-80.76199123799995, 35.584537960000034],
            [-80.761927236999952, 35.584940961000029],
            [-80.761738237999964, 35.585490960000072],
            [-80.761522236999951, 35.585804960000075],
            [-80.761103236999986, 35.586210960000074],
            [-80.76058123599995, 35.586540961000026],
            [-80.757725235999942, 35.587940961000072],
            [-80.757543236999936, 35.588101961000064],
            [-80.757503235999934, 35.588147961000061],
            [-80.75736223499996, 35.588308962000042],
            [-80.757240235999973, 35.58863296100003],
            [-80.757231235999939, 35.588773962000062],
            [-80.757108235999965, 35.590717962000042],
            [-80.757022235999955, 35.592407963000028],
            [-80.756529235999949, 35.592176963000043],
            [-80.754372235999938, 35.591189962000044],
            [-80.75291223499994, 35.59051596200004],
            [-80.752501233999965, 35.590300963000061],
            [-80.750763233999976, 35.589393962000031],
            [-80.750642234999987, 35.589307962000078],
            [-80.751008233999983, 35.59080896200004],
            [-80.752109234999978, 35.595311964000075],
            [-80.752476234999961, 35.596812963000048],
            [-80.752711235999982, 35.59776796400007],
            [-80.753769234999936, 35.602074964000053],
            [-80.753908234999983, 35.602647965000074],
            [-80.754328235999935, 35.604366965000054],
            [-80.754468236999969, 35.604939965000028],
            [-80.754599236999979, 35.605476965000037],
            [-80.754994236999948, 35.607089966000046],
            [-80.755126236999956, 35.607627965000063],
            [-80.755185236999978, 35.607869966000067],
            [-80.755364236999981, 35.608595966000053],
            [-80.755424236999943, 35.608838966000064],
            [-80.755589235999935, 35.609514966000063],
            [-80.756084237999971, 35.611542966000059],
            [-80.756249236999963, 35.612218967000047],
            [-80.756266236999977, 35.612288966000051],
            [-80.756353237999974, 35.612643966000064],
            [-80.757242236999957, 35.616271967000046],
            [-80.757434237999973, 35.617052968000053],
            [-80.758230237999953, 35.620300968000038],
            [-80.759135237999942, 35.62399496900008],
            [-80.759190238999963, 35.624277968000058],
            [-80.759324239999955, 35.624967969000068],
            [-80.759346238999967, 35.625128969000059],
            [-80.759387237999988, 35.625414969000076],
            [-80.759588237999935, 35.625389969000025],
            [-80.75975023899997, 35.625356969000052],
            [-80.759884239999963, 35.625346970000066],
            [-80.759911238999962, 35.625335969000048],
            [-80.759992238999985, 35.625335969000048],
            [-80.760019238999973, 35.625324969000076],
            [-80.760126238999987, 35.625313968000057],
            [-80.760234238999942, 35.625313969000047],
            [-80.760665238999934, 35.625269969000044],
            [-80.761176239999941, 35.625270970000031],
            [-80.761714238999957, 35.625314968000055],
            [-80.761822239999958, 35.625336969000045],
            [-80.761876239999935, 35.625336969000045],
            [-80.762091239999961, 35.625380969000048],
            [-80.762360239999964, 35.625381969000046],
            [-80.762630239999964, 35.625348969000072],
            [-80.762656239999956, 35.625337969000043],
            [-80.762798238999949, 35.625315969000042],
            [-80.762818238999955, 35.625304969000069],
            [-80.763006238999935, 35.625282969000068],
            [-80.763114239999936, 35.62529396900004],
            [-80.763276239999982, 35.625294968000048],
            [-80.763329239999962, 35.625305969000067],
            [-80.763428239999939, 35.625305969000067],
            [-80.763518239999939, 35.625305969000067],
            [-80.763679239999988, 35.625272968000047],
            [-80.764051239999958, 35.625134969000044],
            [-80.764191239999946, 35.625042968000059],
            [-80.764318240999955, 35.624945969000066],
            [-80.764747239999963, 35.62447596800007],
            [-80.765150240999958, 35.624084968000034],
            [-80.765306240999962, 35.62395396800008],
            [-80.765474239999946, 35.623833968000042],
            [-80.765818240999977, 35.623548969000069],
            [-80.765981240999963, 35.623433969000075],
            [-80.766250239999977, 35.623269968000045],
            [-80.766520240999967, 35.623127968000063],
            [-80.766749240999957, 35.623013968000066],
            [-80.767099240999983, 35.622871969000073],
            [-80.767396240999972, 35.622768969000049],
            [-80.767557240999963, 35.622730968000042],
            [-80.767611241999987, 35.62270896800004],
            [-80.767665241999964, 35.62270896800004],
            [-80.767968240999949, 35.622638968000047],
            [-80.768203240999981, 35.622550968000041],
            [-80.768419240999947, 35.622458968000046],
            [-80.768554240999947, 35.62238196800007],
            [-80.76863524099997, 35.62234896700005],
            [-80.769015241999966, 35.622108968000077],
            [-80.769451240999956, 35.621813969000073],
            [-80.770146241999953, 35.621260968000058],
            [-80.770180240999935, 35.621244968000042],
            [-80.770335241999987, 35.621118967000029],
            [-80.770827241999939, 35.620763968000063],
            [-80.770928241999968, 35.620681968000042],
            [-80.771144240999945, 35.620527968000033],
            [-80.771609242999943, 35.620227967000062],
            [-80.772574242999951, 35.619532968000044],
            [-80.772952241999974, 35.619314967000037],
            [-80.77353124199999, 35.619035967000059],
            [-80.773881241999959, 35.618894967000074],
            [-80.774508241999968, 35.618692968000062],
            [-80.77520124299997, 35.618420967000077],
            [-80.775390242999947, 35.618365968000035],
            [-80.775498241999969, 35.618344968000031],
            [-80.775632242999961, 35.618333967000069],
            [-80.775740243999962, 35.618312967000065],
            [-80.77579424399994, 35.618312967000065],
            [-80.776171243999954, 35.618252967000046],
            [-80.776305243999957, 35.618214967000029],
            [-80.776575243999957, 35.618116967000049],
            [-80.776803242999961, 35.618051967000042],
            [-80.776992243999985, 35.617975967000064],
            [-80.777430242999969, 35.617762967000033],
            [-80.777882243999954, 35.617478966000078],
            [-80.778104243999962, 35.617303966000065],
            [-80.778327243999968, 35.617106967000041],
            [-80.778538242999957, 35.61689296600008],
            [-80.77889324399996, 35.616899967000052],
            [-80.779108243999985, 35.61687796700005],
            [-80.779162243999963, 35.616867966000029],
            [-80.779243243999986, 35.616834966000056],
            [-80.779378243999986, 35.61674696700004],
            [-80.779485243999943, 35.616703967000035],
            [-80.779539243999977, 35.61669796700005],
            [-80.779593243999955, 35.616709967000077],
            [-80.779862243999958, 35.616869966000024],
            [-80.780318243999943, 35.617040967000037],
            [-80.780533244999958, 35.617134967000027],
            [-80.780560244999947, 35.617156967000028],
            [-80.780614244999981, 35.617179967000027],
            [-80.780956243999981, 35.617372967000051],
            [-80.781030244999954, 35.617400967000037],
            [-80.781345244999955, 35.617560966000042],
            [-80.781581244999984, 35.617654966000032],
            [-80.781607244999975, 35.617676967000079],
            [-80.781795244999955, 35.617753966000066],
            [-80.781822244999944, 35.617775966000067],
            [-80.781930244999955, 35.617820967000057],
            [-80.781956244999947, 35.617842967000058],
            [-80.782144244999984, 35.617919967000034],
            [-80.782171244999972, 35.617941967000036],
            [-80.782381244999954, 35.618037966000031],
            [-80.782173244999967, 35.619086967000044],
            [-80.783507245999942, 35.619818967000072],
            [-80.783906245999958, 35.62003796700003],
            [-80.783860244999971, 35.620113967000066],
            [-80.78458124499997, 35.620474967000064],
            [-80.785551246999944, 35.620960968000077],
            [-80.785923246999971, 35.621210967000025],
            [-80.786164245999942, 35.621408968000026],
            [-80.786913246999973, 35.622134967000079],
            [-80.789107246999947, 35.62426296700005],
            [-80.789297247999968, 35.624446968000029],
            [-80.789595247999955, 35.62474996800006],
            [-80.789627247999988, 35.624795968000058],
            [-80.790775247999989, 35.624161968000067],
            [-80.791031247999967, 35.624052968000058],
            [-80.791256247999968, 35.623978968000074],
            [-80.791534247999948, 35.623930968000025],
            [-80.791900247999934, 35.623934968000071],
            [-80.792709248999984, 35.62401096800005],
            [-80.792957248999983, 35.624024967000025],
            [-80.793182247999937, 35.624003967000078],
            [-80.793514248999941, 35.623945967000054],
            [-80.793680248999976, 35.62390896800008],
            [-80.794617249999988, 35.623700968000037],
            [-80.795057248999967, 35.623632968000038],
            [-80.795508248999965, 35.623591968000028],
            [-80.795798249999962, 35.623578967000071],
            [-80.796261249999986, 35.623588968000035],
            [-80.796452249999959, 35.623626968000053],
            [-80.796748249999951, 35.62368796700008],
            [-80.797127248999971, 35.62382296800007],
            [-80.798057248999953, 35.624290967000036],
            [-80.798381249999977, 35.624453967000079],
            [-80.798670250999976, 35.624598968000043],
            [-80.798833250999962, 35.624680968000064],
            [-80.798715250999976, 35.624832968000078],
            [-80.79856424999997, 35.625026967000053],
            [-80.798350249999942, 35.625302968000028],
            [-80.797988249999946, 35.625768968000045],
            [-80.797538249999945, 35.626347967000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 80,
        "SolutionID": "PI-30:D",
        "Shape_Length": 0.57771039241510891,
        "Shape_Area": 0.0076690575638891003
      }
    },
    {
      "type": "Feature",
      "id": 81,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.886904289999961, 35.842971010000042],
            [-80.887375288999976, 35.843023010000024],
            [-80.88749828899995, 35.843045011000072],
            [-80.888178288999939, 35.843159011000068],
            [-80.889546289999942, 35.843444010000042],
            [-80.890237290999949, 35.843572011000049],
            [-80.892643290999956, 35.844049010000049],
            [-80.892959290999954, 35.844070010000053],
            [-80.893697289999977, 35.844138011000041],
            [-80.894623291999949, 35.844217010000079],
            [-80.89538429199996, 35.844283010000026],
            [-80.896657291999986, 35.844382010000061],
            [-80.897476291999965, 35.844461010000032],
            [-80.898068292999938, 35.84449101000007],
            [-80.89852029299999, 35.844465011000068],
            [-80.898883292999983, 35.844413010000039],
            [-80.899495292999973, 35.844272010000054],
            [-80.899995292999961, 35.844198010000071],
            [-80.900496292999946, 35.844170010000028],
            [-80.901070292999975, 35.844148010000026],
            [-80.901685293999947, 35.844132010000067],
            [-80.902088292999963, 35.84411201000006],
            [-80.901961292999943, 35.843102010000052],
            [-80.901964293999981, 35.842789010000047],
            [-80.902027293999936, 35.842481009000039],
            [-80.902149293999969, 35.842185010000037],
            [-80.902326293999977, 35.841907009000067],
            [-80.904292293999958, 35.839121009000053],
            [-80.904431294999938, 35.838920009000049],
            [-80.904947293999953, 35.838175008000064],
            [-80.905830293999941, 35.836520008000036],
            [-80.906051293999951, 35.836064008000051],
            [-80.906185293999954, 35.835808008000072],
            [-80.906608294999955, 35.834977008000067],
            [-80.90680529399998, 35.834673008000038],
            [-80.907053293999979, 35.834396008000056],
            [-80.907373294999957, 35.834111008000036],
            [-80.908153294999977, 35.833417007000037],
            [-80.908389294999949, 35.833228008000049],
            [-80.908590294999954, 35.833015007000029],
            [-80.90875329499994, 35.83278100800004],
            [-80.908875294999973, 35.832532008000044],
            [-80.909250295999982, 35.831297007000046],
            [-80.909334294999951, 35.830951007000067],
            [-80.909369294999976, 35.830600007000044],
            [-80.90935329499996, 35.830247007000025],
            [-80.909287294999956, 35.829898007000054],
            [-80.90912829499996, 35.829402007000056],
            [-80.908925293999971, 35.82891700700003],
            [-80.908677294999961, 35.828446006000036],
            [-80.908124293999947, 35.827572007000072],
            [-80.910433294999962, 35.82756600600004],
            [-80.914584295999987, 35.827556006000066],
            [-80.914979296999945, 35.827556006000066],
            [-80.91692829699997, 35.827551006000078],
            [-80.917113297999947, 35.827568006000035],
            [-80.917355296999972, 35.82762600600006],
            [-80.917505296999934, 35.827676006000047],
            [-80.917816296999945, 35.827877006000051],
            [-80.918110296999942, 35.828025006000075],
            [-80.918253296999978, 35.828085007000027],
            [-80.918366297999967, 35.82817000600005],
            [-80.918423296999947, 35.828150005000055],
            [-80.918522297999971, 35.828185006000069],
            [-80.917827297999963, 35.826600006000035],
            [-80.917506296999989, 35.825925005000045],
            [-80.915392296999983, 35.821300005000069],
            [-80.915325296999981, 35.821153005000042],
            [-80.914913294999963, 35.820236005000027],
            [-80.91485729599998, 35.820112005000055],
            [-80.914543295999977, 35.819442005000042],
            [-80.914391295999962, 35.819118004000075],
            [-80.91428329699994, 35.818886004000035],
            [-80.913712295999972, 35.817659004000063],
            [-80.911894294999968, 35.813754003000042],
            [-80.911869294999974, 35.813700003000065],
            [-80.911524293999946, 35.812879003000035],
            [-80.911225293999962, 35.812045003000037],
            [-80.91121829399998, 35.812016003000053],
            [-80.911137294999946, 35.811690002000034],
            [-80.910969294999973, 35.811011003000033],
            [-80.910931294999955, 35.81086500300006],
            [-80.910788294999975, 35.810222003000035],
            [-80.910612294999964, 35.809482003000028],
            [-80.910307294999939, 35.80814900200005],
            [-80.91018629499996, 35.807672001000071],
            [-80.910073294999961, 35.807358003000047],
            [-80.910197292999953, 35.807336002000056],
            [-80.910318293999978, 35.807356002000063],
            [-80.91045629499996, 35.807452002000048],
            [-80.910611293999978, 35.807625002000066],
            [-80.91092129499998, 35.807942003000051],
            [-80.911119293999946, 35.808123002000059],
            [-80.911476294999943, 35.808342002000074],
            [-80.911678294999945, 35.808464002000051],
            [-80.912112294999986, 35.808661002000065],
            [-80.913108294999972, 35.809131002000072],
            [-80.913209294999945, 35.809179002000064],
            [-80.913386294999952, 35.80926300200008],
            [-80.913611294999953, 35.809369002000039],
            [-80.913792294999951, 35.809457002000045],
            [-80.914021294999941, 35.809567002000051],
            [-80.915374295999982, 35.810221003000038],
            [-80.918958296999961, 35.810007002000077],
            [-80.919282296999938, 35.809997002000046],
            [-80.919602296999983, 35.809952002000045],
            [-80.919898296999975, 35.809877002000064],
            [-80.920768296999938, 35.80963500200005],
            [-80.921713297999986, 35.809372002000032],
            [-80.921925297999962, 35.809313002000067],
            [-80.92230529699998, 35.809196002000078],
            [-80.922429296999951, 35.809175001000028],
            [-80.922717297999952, 35.809135001000072],
            [-80.922942297999953, 35.809112002000063],
            [-80.924487296999985, 35.809053002000041],
            [-80.925999298999955, 35.808995002000074],
            [-80.927654299999972, 35.808975002000068],
            [-80.928589299999942, 35.808976002000065],
            [-80.929310299999941, 35.808984002000045],
            [-80.929702299999974, 35.809000001000072],
            [-80.930245299999967, 35.808827001000054],
            [-80.930602299999975, 35.808713001000058],
            [-80.931758300999945, 35.808344002000069],
            [-80.932172299999934, 35.808212001000072],
            [-80.932245300999966, 35.808189001000073],
            [-80.933222300999944, 35.807878002000052],
            [-80.93371130099996, 35.807722001000059],
            [-80.934037301999979, 35.807635001000051],
            [-80.934373300999937, 35.807580001000076],
            [-80.934714300999985, 35.80755600100008],
            [-80.935266301999945, 35.807543001000056],
            [-80.935581301999946, 35.807535001000076],
            [-80.935767300999942, 35.807529001000034],
            [-80.93770230299998, 35.807479001000047],
            [-80.93923330299998, 35.807440001000032],
            [-80.939348302999974, 35.807395001000032],
            [-80.940165302999958, 35.807075001000044],
            [-80.94025830399994, 35.807031001000041],
            [-80.940425302999984, 35.806927001000076],
            [-80.942032303999952, 35.805771001000039],
            [-80.942192303999946, 35.805673001000059],
            [-80.942830303999983, 35.80538400000006],
            [-80.943460304999974, 35.805087000000071],
            [-80.942922302999989, 35.80446100000006],
            [-80.941666303999966, 35.802613000000065],
            [-80.941677302999949, 35.802562999000031],
            [-80.941671302999964, 35.802132999000037],
            [-80.941571302999989, 35.801982999000074],
            [-80.941357302999961, 35.801664000000073],
            [-80.941302302999986, 35.801582000000053],
            [-80.941171302999976, 35.801385000000039],
            [-80.941040302999966, 35.801190000000076],
            [-80.940983303999985, 35.80109199900005],
            [-80.940685301999963, 35.800571000000048],
            [-80.939987302999953, 35.799357999000051],
            [-80.939497302999939, 35.798721999000065],
            [-80.93981230199995, 35.798598999000035],
            [-80.939556301999971, 35.79815300000007],
            [-80.939496302999942, 35.798047999000062],
            [-80.938864301999956, 35.796947998000064],
            [-80.93805230199996, 35.795684999000059],
            [-80.937532300999976, 35.794957998000029],
            [-80.937208301999988, 35.794630999000049],
            [-80.93695330099996, 35.794420998000078],
            [-80.936930300999961, 35.794399998000074],
            [-80.936749300999963, 35.794301998000037],
            [-80.936512301999983, 35.79415799800006],
            [-80.936131300999989, 35.79395899900004],
            [-80.936073300999965, 35.793928998000069],
            [-80.935972299999946, 35.793875998000033],
            [-80.935840299999938, 35.793818998000063],
            [-80.934477299999969, 35.794169999000076],
            [-80.932565299999965, 35.794649999000058],
            [-80.932339299999967, 35.794706998000038],
            [-80.929046299999982, 35.795535999000037],
            [-80.927693298999941, 35.795870999000044],
            [-80.926338296999972, 35.796217999000078],
            [-80.924978296999939, 35.796554998000033],
            [-80.922388296999941, 35.797213000000056],
            [-80.921762296999987, 35.797372000000053],
            [-80.918763295999952, 35.798125000000027],
            [-80.916060295999955, 35.798812000000055],
            [-80.913233293999951, 35.799521000000027],
            [-80.910396293999952, 35.800240000000031],
            [-80.908165292999968, 35.800797001000035],
            [-80.90788629299999, 35.800867000000039],
            [-80.905240291999974, 35.801537001000042],
            [-80.904938292999987, 35.801276001000076],
            [-80.90461929199995, 35.801001001000031],
            [-80.903258291999975, 35.799825001000045],
            [-80.902378290999934, 35.799072000000024],
            [-80.90156129099995, 35.798375000000078],
            [-80.900529289999952, 35.79751600000003],
            [-80.899975289999986, 35.797055000000057],
            [-80.899536289999958, 35.797340000000077],
            [-80.899059289999968, 35.797580000000039],
            [-80.898550289999946, 35.797773001000053],
            [-80.898030288999962, 35.797995000000071],
            [-80.897475290999978, 35.798232000000041],
            [-80.896723289999954, 35.79855400100007],
            [-80.895872289999943, 35.798926000000051],
            [-80.895672288999947, 35.799002001000076],
            [-80.895524289999969, 35.799059001000046],
            [-80.895159288999935, 35.799157001000026],
            [-80.894782289999966, 35.799220001000037],
            [-80.894399288999978, 35.799247000000037],
            [-80.893844288999958, 35.799264002000029],
            [-80.893323287999976, 35.799293001000024],
            [-80.892872287999978, 35.79930900100004],
            [-80.892576287999987, 35.799312001000033],
            [-80.892066287999967, 35.799312001000033],
            [-80.891811287999985, 35.799307001000045],
            [-80.890721286999963, 35.799285000000054],
            [-80.888613287999988, 35.799196001000041],
            [-80.887180285999989, 35.799141001000066],
            [-80.88581828599996, 35.799089002000073],
            [-80.882987283999967, 35.798956001000079],
            [-80.882063284999958, 35.798912001000076],
            [-80.879896283999983, 35.798810001000049],
            [-80.878031283999974, 35.798739001000058],
            [-80.876782282999955, 35.798694002000047],
            [-80.876723283999979, 35.797390002000043],
            [-80.876685283999961, 35.796561001000043],
            [-80.876656282999988, 35.796489001000054],
            [-80.876592282999979, 35.796330001000058],
            [-80.876515282999947, 35.796132001000046],
            [-80.876244282999949, 35.795437001000039],
            [-80.87612728299996, 35.795071001000053],
            [-80.875316282999961, 35.79532400100004],
            [-80.875068283999951, 35.795384001000059],
            [-80.874921282999935, 35.795386000000065],
            [-80.874139282999977, 35.795374001000027],
            [-80.873881281999957, 35.795358001000068],
            [-80.873687281999935, 35.795341001000054],
            [-80.873573282999985, 35.795298000000059],
            [-80.873508281999989, 35.795220001000075],
            [-80.872831280999947, 35.793851000000075],
            [-80.872262280999962, 35.792693000000043],
            [-80.871676281999953, 35.791501000000039],
            [-80.870976280999969, 35.790076000000056],
            [-80.869713280999974, 35.790470999000036],
            [-80.868278280999959, 35.790962000000036],
            [-80.867871279999974, 35.791097000000036],
            [-80.867456280999988, 35.791216000000077],
            [-80.867046279999954, 35.791317000000049],
            [-80.86675527999995, 35.791378000000066],
            [-80.866462279999951, 35.791432000000043],
            [-80.866059279999945, 35.791475001000038],
            [-80.865705279999986, 35.791512000000068],
            [-80.865477278999947, 35.791512000000068],
            [-80.865249279999944, 35.791497000000049],
            [-80.864462278999952, 35.791385001000037],
            [-80.86369327999995, 35.791314000000057],
            [-80.863564278999945, 35.791303001000074],
            [-80.862765278999973, 35.791233000000034],
            [-80.862670277999939, 35.791226000000051],
            [-80.862649278999982, 35.791225001000043],
            [-80.862482277999959, 35.791211000000033],
            [-80.862445278999985, 35.791207001000032],
            [-80.862223278999977, 35.791188000000034],
            [-80.862018278999983, 35.791171001000066],
            [-80.861078277999979, 35.791090001000043],
            [-80.860893277999935, 35.791074001000027],
            [-80.86084227799995, 35.791070001000037],
            [-80.859967278999989, 35.790989001000071],
            [-80.859483277999971, 35.790944000000025],
            [-80.859278276999987, 35.790925000000072],
            [-80.857112276999942, 35.79072300100006],
            [-80.856424277999963, 35.790659000000062],
            [-80.856107276999978, 35.791618001000074],
            [-80.855776277999951, 35.792621001000043],
            [-80.855669276999947, 35.792946001000075],
            [-80.855444276999947, 35.793627001000061],
            [-80.854825276999975, 35.795501002000037],
            [-80.854791276999947, 35.79563500200004],
            [-80.854780276999975, 35.795677001000058],
            [-80.854756276999979, 35.795883002000039],
            [-80.854769276999946, 35.796580001000052],
            [-80.854773276999936, 35.796764002000032],
            [-80.854773276999936, 35.797094002000051],
            [-80.854773275999946, 35.797420002000024],
            [-80.854773276999936, 35.797551001000045],
            [-80.854751275999945, 35.797888002000036],
            [-80.854718275999971, 35.798127002000058],
            [-80.854683276999936, 35.798393002000068],
            [-80.854677276999951, 35.798594002000073],
            [-80.854724277999935, 35.798757003000048],
            [-80.854829275999975, 35.79892600200003],
            [-80.855008276999968, 35.799101003000033],
            [-80.853871276999939, 35.799801002000038],
            [-80.853677275999985, 35.799602002000029],
            [-80.853516276999983, 35.799404002000074],
            [-80.853462275999959, 35.799316003000058],
            [-80.85343527699996, 35.799294002000067],
            [-80.85330827599995, 35.799074002000054],
            [-80.853213276999952, 35.798964003000037],
            [-80.853119276999962, 35.798876002000043],
            [-80.852870276999965, 35.798683002000075],
            [-80.852749276999987, 35.798628002000044],
            [-80.852587275999952, 35.798578003000046],
            [-80.85247927599994, 35.798556002000055],
            [-80.852290276999952, 35.798533002000056],
            [-80.85218227699994, 35.798533002000056],
            [-80.85215627599996, 35.798522003000073],
            [-80.85191227699994, 35.798510002000057],
            [-80.850941275999958, 35.798420003000047],
            [-80.850677274999953, 35.798386002000029],
            [-80.850510274999976, 35.798364003000074],
            [-80.850240275999965, 35.798319003000074],
            [-80.850213275999977, 35.798308002000056],
            [-80.849889275999942, 35.798252002000027],
            [-80.849781275999987, 35.798219003000042],
            [-80.849377275999984, 35.798136002000035],
            [-80.849355274999937, 35.798140002000025],
            [-80.849269274999983, 35.798119002000078],
            [-80.84905327499996, 35.79808500300004],
            [-80.848972274999937, 35.798063002000049],
            [-80.848676274999946, 35.798019002000046],
            [-80.848487275999958, 35.797974002000046],
            [-80.848379274999957, 35.797963002000074],
            [-80.848001273999955, 35.797885002000044],
            [-80.847840274999953, 35.79784100300003],
            [-80.847758273999943, 35.797829002000071],
            [-80.847543274999964, 35.79777400200004],
            [-80.847165273999963, 35.797696002000066],
            [-80.846949274999986, 35.797630002000062],
            [-80.846680273999937, 35.797574002000033],
            [-80.846626273999959, 35.797552002000032],
            [-80.846599273999971, 35.797552002000032],
            [-80.846491274999948, 35.797519003000048],
            [-80.846410273999936, 35.797507003000078],
            [-80.846167273999981, 35.797441002000028],
            [-80.845979273999944, 35.797407002000057],
            [-80.845925273999967, 35.797385003000045],
            [-80.84570927499999, 35.797341002000053],
            [-80.845601274999979, 35.797330002000024],
            [-80.84557427499999, 35.797318002000054],
            [-80.845520274999956, 35.797318002000054],
            [-80.845358273999977, 35.797285002000024],
            [-80.845169273999943, 35.797273003000043],
            [-80.845142273999954, 35.797262002000025],
            [-80.845007273999954, 35.797262002000025],
            [-80.844954273999974, 35.797251002000053],
            [-80.844832273999941, 35.797258002000035],
            [-80.844338273999938, 35.797266002000072],
            [-80.844090273999939, 35.797292003000052],
            [-80.843792273999952, 35.797379002000071],
            [-80.84366227299995, 35.797403002000067],
            [-80.84355627299999, 35.797423002000073],
            [-80.843442272999937, 35.797433002000048],
            [-80.843415273999938, 35.797444003000066],
            [-80.843361272999971, 35.797444002000077],
            [-80.843334272999982, 35.797455002000049],
            [-80.843280273999937, 35.797455002000049],
            [-80.843253272999959, 35.797466003000068],
            [-80.84309127299997, 35.797487003000072],
            [-80.84295627299997, 35.797498002000054],
            [-80.842740273999937, 35.79753000200003],
            [-80.842686273999959, 35.79753000200003],
            [-80.842659272999981, 35.79754100200006],
            [-80.842422272999954, 35.797573003000025],
            [-80.842362273999981, 35.797573002000036],
            [-80.842335272999946, 35.797584003000054],
            [-80.842281272999969, 35.797584003000054],
            [-80.842065272999946, 35.79761600300003],
            [-80.841930272999946, 35.79762700200007],
            [-80.841903271999968, 35.797638003000031],
            [-80.841849273999969, 35.797638002000042],
            [-80.841822272999934, 35.797649002000071],
            [-80.841768272999957, 35.79764900300006],
            [-80.841498272999956, 35.797703003000038],
            [-80.84128227299999, 35.797735003000071],
            [-80.839877271999967, 35.798017002000051],
            [-80.839796271999944, 35.798017002000051],
            [-80.839668272999972, 35.798038002000055],
            [-80.839607271999967, 35.798038003000045],
            [-80.839290271999971, 35.798092003000079],
            [-80.839148271999989, 35.798092003000079],
            [-80.839095271999952, 35.79808100200006],
            [-80.839041271999974, 35.798058003000051],
            [-80.838960272999941, 35.797998002000043],
            [-80.83889327199995, 35.797921003000056],
            [-80.838839271999973, 35.797827002000076],
            [-80.838812271999984, 35.797739003000061],
            [-80.838812272999974, 35.797673003000057],
            [-80.838772271999972, 35.797553002000029],
            [-80.838806272999989, 35.79746500300007],
            [-80.838814271999979, 35.797333002000073],
            [-80.838827271999946, 35.797294003000047],
            [-80.838827272999936, 35.797223003000056],
            [-80.838841271999968, 35.797163002000048],
            [-80.838841271999968, 35.797047002000056],
            [-80.838855271999989, 35.796943003000024],
            [-80.838849271999948, 35.796718002000034],
            [-80.838857271999984, 35.796586003000073],
            [-80.838870272999941, 35.796564002000025],
            [-80.838871271999949, 35.796425002000035],
            [-80.838535271999945, 35.796477002000074],
            [-80.837410271999943, 35.796653002000028],
            [-80.83709627199994, 35.796703003000061],
            [-80.836652271999981, 35.796773002000066],
            [-80.836474270999986, 35.796801002000052],
            [-80.835777270999984, 35.796912002000056],
            [-80.83542227099997, 35.796968003000075],
            [-80.835078270999986, 35.797030003000032],
            [-80.834954270999958, 35.79706800200006],
            [-80.832880270999965, 35.797988003000057],
            [-80.830258269999945, 35.799152003000074],
            [-80.829772268999989, 35.799367003000043],
            [-80.828690269999981, 35.799844004000079],
            [-80.82809526799997, 35.798388003000071],
            [-80.828029268999956, 35.798166003000063],
            [-80.828013268999939, 35.79793800300007],
            [-80.828041268999982, 35.797019003000059],
            [-80.828056268999944, 35.796720002000029],
            [-80.828104269999983, 35.796331003000034],
            [-80.828112268999973, 35.796297003000063],
            [-80.828209267999966, 35.79595000300003],
            [-80.828368268999952, 35.79558200200006],
            [-80.828580267999939, 35.795232002000034],
            [-80.828652268999974, 35.795132002000059],
            [-80.828841268999952, 35.794904003000056],
            [-80.829148268999973, 35.794604002000028],
            [-80.829497268999944, 35.79433600200008],
            [-80.830835268999977, 35.793422002000057],
            [-80.831053268999938, 35.793266002000053],
            [-80.831307268999979, 35.793084001000068],
            [-80.831739269999957, 35.792713001000038],
            [-80.832127269999944, 35.792310002000079],
            [-80.832371269999953, 35.792002002000061],
            [-80.832468269999936, 35.791880002000028],
            [-80.832499269999971, 35.791832002000035],
            [-80.832758269999943, 35.791426001000048],
            [-80.832919269999934, 35.791096001000028],
            [-80.833033270999977, 35.790753001000041],
            [-80.833097269999939, 35.790401001000077],
            [-80.833104269999978, 35.790139001000057],
            [-80.833116270999938, 35.78971800100004],
            [-80.832928268999979, 35.789717001000042],
            [-80.832874269999934, 35.789706002000059],
            [-80.832712269999945, 35.78970600100007],
            [-80.832685269999956, 35.789695001000041],
            [-80.83260426999999, 35.789695001000041],
            [-80.832442268999955, 35.789672001000042],
            [-80.832415269999956, 35.78966100100007],
            [-80.832200269999987, 35.78962800100004],
            [-80.832092268999986, 35.789594001000069],
            [-80.832065269999987, 35.789594001000069],
            [-80.832011269999953, 35.789572001000067],
            [-80.831930269999987, 35.789561001000038],
            [-80.831714268999974, 35.789494001000037],
            [-80.831633268999951, 35.789461001000063],
            [-80.831606268999963, 35.789461001000063],
            [-80.831445269999961, 35.789395001000059],
            [-80.831148268999982, 35.789295001000028],
            [-80.830825268999945, 35.789228001000026],
            [-80.83071726899999, 35.789217001000054],
            [-80.830555268999944, 35.789184001000024],
            [-80.830447269999979, 35.789150001000053],
            [-80.830420269999934, 35.789150001000053],
            [-80.829881268999941, 35.788929000000053],
            [-80.829666268999972, 35.788819001000036],
            [-80.829396267999982, 35.788659001000042],
            [-80.828992268999968, 35.788460002000079],
            [-80.828857268999968, 35.788410001000045],
            [-80.82883026799999, 35.788410001000045],
            [-80.828696267999987, 35.78835500100007],
            [-80.828669267999942, 35.78835500100007],
            [-80.828588268999965, 35.78832200100004],
            [-80.828561268999977, 35.788322002000029],
            [-80.828480267999964, 35.788288001000069],
            [-80.828453267999976, 35.788288001000069],
            [-80.828237268999942, 35.788200001000064],
            [-80.828076267999961, 35.788106001000074],
            [-80.827887267999984, 35.787952002000054],
            [-80.827740267999957, 35.787803000000054],
            [-80.827704267999934, 35.787759000000051],
            [-80.827632268999935, 35.787671001000035],
            [-80.827498267999943, 35.787451001000079],
            [-80.827498268999989, 35.787429001000078],
            [-80.827458268999976, 35.787363001000074],
            [-80.827444267999965, 35.78729700100007],
            [-80.827378267999961, 35.78712100000007],
            [-80.82727026699996, 35.786945001000049],
            [-80.827210267999988, 35.786879001000045],
            [-80.827051267999934, 35.786749000000043],
            [-80.826874267999983, 35.786582001000056],
            [-80.826779267999939, 35.78647200100005],
            [-80.826537267999981, 35.78625100000005],
            [-80.826524267999957, 35.786229001000038],
            [-80.826302267999949, 35.786058001000072],
            [-80.825992267999936, 35.785865000000058],
            [-80.825803267999959, 35.78576600100007],
            [-80.825777267999968, 35.785744001000069],
            [-80.825669267999956, 35.785700000000077],
            [-80.825642266999978, 35.785678001000065],
            [-80.825372267999967, 35.78553400100003],
            [-80.82486126699996, 35.785302000000058],
            [-80.824457267999946, 35.785070000000076],
            [-80.824295266999968, 35.784966001000043],
            [-80.824194266999939, 35.784872001000053],
            [-80.824154266999983, 35.784817000000032],
            [-80.824107267999977, 35.784707001000072],
            [-80.824020265999934, 35.784553000000074],
            [-80.823980266999968, 35.784509000000071],
            [-80.823865266999974, 35.784426000000053],
            [-80.823744265999949, 35.784365001000026],
            [-80.823582266999949, 35.78432100100008],
            [-80.823474266999938, 35.784321000000034],
            [-80.823312265999959, 35.784298000000035],
            [-80.82323226699998, 35.784265001000051],
            [-80.823124266999969, 35.784193000000073],
            [-80.82300326699999, 35.784072001000027],
            [-80.822916266999982, 35.784012000000075],
            [-80.822801266999988, 35.783967000000075],
            [-80.822774265999954, 35.783967000000075],
            [-80.822693265999987, 35.783934001000034],
            [-80.822666266999988, 35.783934001000034],
            [-80.822558265999987, 35.783901000000071],
            [-80.822424265999985, 35.78384600000004],
            [-80.822343266999951, 35.783823000000041],
            [-80.822154266999974, 35.783746000000065],
            [-80.822127265999939, 35.783724000000063],
            [-80.822046265999973, 35.783691000000033],
            [-80.821885266999971, 35.783602001000077],
            [-80.821669266999947, 35.783464000000038],
            [-80.821642265999969, 35.783437001000038],
            [-80.821562265999944, 35.783387000000062],
            [-80.821266265999952, 35.783145000000047],
            [-80.821077265999975, 35.783013001000029],
            [-80.820781264999937, 35.782825000000059],
            [-80.820754266999984, 35.782841001000065],
            [-80.82072726499996, 35.782829999000057],
            [-80.82037726599998, 35.782637000000079],
            [-80.820240265999985, 35.782569000000024],
            [-80.820134264999979, 35.782515999000054],
            [-80.819811264999942, 35.782328000000064],
            [-80.819569264999984, 35.782200999000054],
            [-80.819219264999958, 35.781986001000064],
            [-80.819057265999959, 35.781903000000057],
            [-80.818707264999944, 35.78169900000006],
            [-80.818546264999952, 35.781589000000054],
            [-80.817988263999951, 35.78095600000006],
            [-80.81785326499994, 35.780844999000067],
            [-80.817813264999984, 35.780800999000064],
            [-80.817753264999965, 35.780763000000036],
            [-80.817423264999945, 35.780504000000064],
            [-80.817174263999959, 35.780344000000071],
            [-80.817032263999977, 35.780238000000054],
            [-80.816792264999947, 35.780165000000068],
            [-80.81654726499994, 35.78009000000003],
            [-80.816246264999961, 35.780056000000059],
            [-80.816019263999976, 35.780024999000034],
            [-80.814941264999959, 35.780024000000026],
            [-80.813113262999934, 35.779871000000071],
            [-80.812636262999945, 35.779855000000055],
            [-80.811976262999963, 35.779914000000076],
            [-80.811890262999952, 35.779949000000045],
            [-80.811771262999969, 35.779997999000045],
            [-80.811486262999949, 35.78025400000007],
            [-80.811436262999962, 35.780270999000038],
            [-80.811290262999989, 35.780319000000077],
            [-80.810998262999988, 35.780391000000066],
            [-80.810417262999977, 35.780542000000025],
            [-80.810034261999988, 35.780582000000038],
            [-80.809832262999976, 35.780585000000031],
            [-80.809034262999944, 35.780518000000029],
            [-80.807973261999962, 35.780083000000047],
            [-80.807082261999938, 35.779825999000025],
            [-80.806356260999962, 35.779521000000045],
            [-80.806190260999983, 35.77952300000004],
            [-80.806022260999953, 35.779391001000079],
            [-80.805856261999963, 35.779392999000038],
            [-80.805687260999946, 35.779260000000079],
            [-80.805409260999966, 35.779219000000069],
            [-80.80434926099997, 35.77878400000003],
            [-80.803622259999941, 35.778434000000061],
            [-80.802219259999958, 35.777554000000066],
            [-80.80079625999997, 35.776782000000026],
            [-80.80076325999994, 35.776764000000071],
            [-80.799927259999947, 35.77646100000004],
            [-80.798732258999962, 35.776168999000049],
            [-80.798537258999943, 35.776121000000046],
            [-80.798150258999954, 35.776125999000044],
            [-80.797982257999934, 35.776038999000036],
            [-80.795796256999949, 35.776111000000071],
            [-80.795766257999958, 35.776186000000052],
            [-80.79557325799999, 35.776668000000029],
            [-80.795399257999975, 35.777267000000052],
            [-80.795288256999982, 35.777874999000062],
            [-80.794870258999936, 35.780608000000029],
            [-80.794840257999965, 35.78095200100006],
            [-80.794855257999984, 35.781296001000044],
            [-80.794915258999936, 35.781637001000036],
            [-80.795019257999968, 35.781971000000055],
            [-80.795166258999984, 35.782294001000025],
            [-80.795372257999986, 35.782680001000074],
            [-80.795944257999963, 35.783758001000024],
            [-80.796432258999971, 35.784705001000077],
            [-80.796710259999941, 35.785181002000058],
            [-80.797032258999934, 35.785621002000028],
            [-80.797041258999968, 35.785633002000054],
            [-80.798715258999948, 35.787695003000067],
            [-80.798928259999968, 35.78792300200007],
            [-80.799177259999965, 35.78812600200007],
            [-80.79945725999994, 35.788299002000031],
            [-80.800875260999987, 35.789060002000042],
            [-80.802329260999954, 35.789839002000065],
            [-80.802677261999975, 35.79001700300006],
            [-80.802738259999956, 35.79005800300007],
            [-80.802993260999983, 35.790231002000041],
            [-80.803269260999969, 35.79047900300003],
            [-80.803501260999951, 35.79075600300007],
            [-80.803684260999944, 35.791055002000064],
            [-80.80375326099994, 35.79121000300006],
            [-80.804108260999953, 35.792007003000037],
            [-80.804574260999971, 35.793159004000074],
            [-80.805068261999963, 35.794380003000072],
            [-80.805195261999984, 35.794769003000056],
            [-80.80526826199997, 35.795167003000074],
            [-80.805288261999976, 35.795569003000026],
            [-80.80523526099995, 35.798694004000026],
            [-80.805219262999969, 35.799961004000068],
            [-80.805215260999944, 35.800180005000072],
            [-80.805199261999974, 35.801279004000037],
            [-80.80517826199997, 35.802844005000054],
            [-80.80517426199998, 35.80315300500007],
            [-80.805155261999971, 35.804808005000041],
            [-80.805147262999981, 35.805518005000067],
            [-80.805132262999962, 35.806900006000035],
            [-80.80590626299994, 35.80699200600003],
            [-80.806625262999944, 35.807127005000041],
            [-80.807692262999979, 35.807369006000044],
            [-80.807720262999965, 35.80764500600003],
            [-80.807792262999953, 35.807964006000077],
            [-80.807815262999952, 35.808116006000034],
            [-80.807832262999966, 35.808496006000041],
            [-80.807760263999967, 35.809030006000057],
            [-80.807706263999989, 35.809526006000056],
            [-80.807625262999977, 35.81007500700008],
            [-80.80755626399997, 35.810639006000031],
            [-80.807530263999979, 35.810853006000059],
            [-80.807423262999976, 35.81152400600007],
            [-80.807379262999973, 35.812035007000077],
            [-80.807375263999973, 35.812712007000073],
            [-80.807366263999938, 35.812925007000047],
            [-80.807324263999988, 35.813961008000035],
            [-80.80729026399996, 35.814277007000044],
            [-80.807266263999963, 35.814502007000044],
            [-80.807248262999963, 35.814553008000075],
            [-80.807099263999987, 35.814992008000047],
            [-80.806985263999934, 35.815259007000066],
            [-80.806948263999971, 35.815344007000078],
            [-80.806826262999948, 35.815524008000068],
            [-80.806243263999988, 35.816398008000078],
            [-80.806166262999966, 35.816491008000071],
            [-80.805997262999938, 35.81669800800006],
            [-80.805659262999939, 35.817023008000035],
            [-80.80561626299999, 35.817053008000073],
            [-80.805070262999948, 35.817443008000055],
            [-80.804453262999971, 35.817840008000076],
            [-80.803818262999982, 35.818283008000037],
            [-80.803570262999983, 35.81845000800007],
            [-80.803348261999986, 35.818602008000028],
            [-80.802888262999943, 35.818906008000056],
            [-80.802681261999965, 35.819070009000029],
            [-80.802498262999961, 35.819214009000063],
            [-80.802265262999981, 35.819480008000028],
            [-80.801995262999981, 35.819933009000067],
            [-80.801781261999963, 35.820377009000026],
            [-80.801535261999959, 35.820943009000075],
            [-80.801292262999937, 35.821540009000046],
            [-80.801187262999974, 35.821826009000063],
            [-80.800993261999963, 35.822351009000045],
            [-80.800914262999981, 35.822588010000061],
            [-80.800662261999946, 35.823445010000057],
            [-80.800510261999989, 35.82376701000004],
            [-80.800264260999938, 35.824021009000035],
            [-80.800077261999945, 35.824128010000038],
            [-80.799922261999939, 35.824217010000041],
            [-80.799476261999985, 35.824345010000059],
            [-80.799030261999974, 35.824435010000059],
            [-80.798643261999985, 35.824507010000048],
            [-80.798490260999984, 35.824535010000034],
            [-80.797386260999986, 35.824864010000056],
            [-80.79706926199998, 35.824959009000054],
            [-80.796291260999965, 35.82523701000008],
            [-80.795392260999961, 35.825577009000028],
            [-80.794622260999972, 35.825848010000072],
            [-80.793961258999957, 35.825956010000027],
            [-80.793232260999957, 35.825982011000065],
            [-80.792522259999942, 35.825985010000068],
            [-80.791971259999968, 35.826024010000026],
            [-80.791452258999982, 35.826191011000049],
            [-80.790814258999944, 35.82652801100005],
            [-80.789126258999943, 35.82750401100003],
            [-80.788490258999957, 35.827909012000077],
            [-80.788059258999965, 35.828311011000039],
            [-80.78779125899996, 35.828863011000067],
            [-80.787741257999983, 35.829138012000044],
            [-80.78773425899999, 35.830572011000072],
            [-80.787731258999941, 35.831209011000055],
            [-80.787729257999956, 35.831558012000073],
            [-80.78774225799998, 35.832805012000051],
            [-80.78774625899996, 35.833157012000072],
            [-80.787797258999944, 35.833772012000054],
            [-80.787909259999935, 35.834189012000024],
            [-80.788116258999935, 35.835107013000027],
            [-80.788195258999963, 35.835692013000028],
            [-80.788198258999955, 35.836278013000026],
            [-80.788131258999954, 35.837063013000034],
            [-80.788127258999964, 35.837178013000027],
            [-80.788128258999961, 35.837198012000044],
            [-80.789039258999935, 35.837674013000026],
            [-80.789874258999987, 35.838110013000062],
            [-80.792979260999971, 35.839869013000055],
            [-80.793469261999974, 35.840160013000059],
            [-80.793931260999955, 35.840371013000038],
            [-80.794410260999939, 35.840547014000038],
            [-80.795039260999943, 35.840721014000053],
            [-80.795879261999971, 35.84092101300007],
            [-80.796857261999946, 35.841155013000048],
            [-80.797559261999936, 35.841327014000058],
            [-80.799655262999977, 35.841840013000024],
            [-80.800501262999944, 35.842038013000035],
            [-80.801978263999956, 35.84239901400008],
            [-80.802436263999937, 35.842511014000024],
            [-80.803099263999968, 35.84268501300005],
            [-80.80388226499997, 35.842874013000028],
            [-80.804096264999941, 35.842928014000051],
            [-80.804605263999974, 35.842981013000042],
            [-80.805171264999956, 35.842959013000041],
            [-80.805802264999954, 35.842855013000076],
            [-80.806665264999936, 35.842693014000076],
            [-80.808018265999976, 35.842449013000078],
            [-80.808757265999986, 35.842363014000057],
            [-80.809925265999937, 35.842298013000061],
            [-80.810946266999963, 35.842236013000047],
            [-80.811487266999961, 35.842194013000039],
            [-80.812727265999968, 35.842128013000035],
            [-80.813452266999946, 35.842158013000073],
            [-80.814235267999948, 35.842196013000034],
            [-80.815429267999946, 35.842237013000044],
            [-80.815796266999939, 35.842266012000039],
            [-80.816302267999959, 35.842357013000026],
            [-80.816822267999953, 35.842450013000075],
            [-80.817754267999987, 35.842616013000054],
            [-80.817925268999943, 35.842650013000025],
            [-80.818850267999949, 35.84282401300004],
            [-80.819278267999948, 35.842940013000032],
            [-80.819716268999969, 35.843177014000048],
            [-80.820083268999952, 35.843471013000055],
            [-80.820174268999949, 35.843544013000042],
            [-80.821022269999958, 35.844266013000038],
            [-80.821471269999961, 35.843843013000026],
            [-80.821668269999975, 35.843710013000077],
            [-80.821786269999961, 35.843630013000052],
            [-80.822127269999953, 35.843448013000057],
            [-80.82250326999997, 35.843294013000047],
            [-80.822857269999986, 35.843188013000031],
            [-80.822898269999939, 35.843176013000061],
            [-80.823307269999987, 35.843097013000033],
            [-80.823725269999954, 35.843057013000077],
            [-80.824422270999946, 35.84301901300006],
            [-80.824465269999962, 35.84301501200008],
            [-80.825115270999959, 35.842951012000071],
            [-80.825803270999984, 35.842855013000076],
            [-80.826267270999949, 35.842772013000058],
            [-80.826718270999947, 35.842651013000079],
            [-80.827151270999934, 35.842492012000037],
            [-80.827562271999966, 35.842298013000061],
            [-80.82917127199994, 35.841078011000036],
            [-80.829469271999983, 35.840829012000029],
            [-80.829713271999935, 35.840545013000053],
            [-80.829899271999977, 35.840231012000061],
            [-80.830396271999973, 35.839098012000079],
            [-80.830415270999936, 35.83905001100004],
            [-80.830761270999972, 35.838194011000041],
            [-80.830846271999974, 35.837831011000048],
            [-80.830880271999945, 35.837463012000057],
            [-80.83087427199996, 35.837413011000024],
            [-80.83086227199999, 35.837093011000036],
            [-80.830843271999981, 35.836831011000072],
            [-80.830876271999955, 35.836570011000049],
            [-80.83096027199997, 35.836317011000062],
            [-80.83119727199994, 35.835891010000068],
            [-80.831258271999957, 35.835780010000065],
            [-80.831501272999958, 35.835224011000037],
            [-80.831528270999968, 35.835054011000068],
            [-80.831506271999956, 35.834884011000042],
            [-80.83147427199998, 35.834717011000066],
            [-80.831020271999989, 35.83234501000004],
            [-80.833146271999965, 35.832339011000045],
            [-80.834865272999934, 35.832335010000065],
            [-80.835204273999977, 35.832325010000034],
            [-80.835274272999982, 35.832320011000036],
            [-80.835542272999987, 35.832299010000042],
            [-80.837973274999968, 35.832046010000056],
            [-80.838119272999961, 35.832038010000076],
            [-80.841664274999971, 35.832105010000078],
            [-80.841890275999958, 35.832131010000069],
            [-80.842102274999945, 35.832199010000068],
            [-80.842289274999985, 35.832306009000035],
            [-80.842441275999988, 35.832444010000074],
            [-80.842549275999943, 35.832608010000058],
            [-80.842627274999984, 35.83276901000005],
            [-80.842841274999955, 35.833210010000073],
            [-80.842960275999985, 35.833405010000035],
            [-80.84312027499999, 35.833581010000046],
            [-80.843315275999942, 35.833730010000068],
            [-80.843970275999936, 35.834168010000042],
            [-80.844076276999942, 35.834239010000033],
            [-80.844405275999975, 35.834385010000062],
            [-80.844759275999934, 35.834483010000042],
            [-80.845129276999955, 35.83452901000004],
            [-80.850804278999988, 35.83465601000006],
            [-80.850763278999978, 35.835790011000029],
            [-80.852767278999977, 35.835787010000047],
            [-80.855145278999942, 35.835783010000057],
            [-80.855423278999979, 35.835771011000077],
            [-80.855700279999951, 35.835740010000052],
            [-80.856051279999974, 35.835653010000044],
            [-80.85637627899996, 35.835514011000043],
            [-80.857462279999936, 35.834882010000058],
            [-80.858711279999966, 35.834120010000049],
            [-80.859179279999978, 35.833871009000063],
            [-80.859568279999962, 35.833747009000035],
            [-80.859760279999989, 35.83370000900004],
            [-80.859935280999935, 35.833657010000024],
            [-80.860377280999955, 35.833577010000056],
            [-80.863907281999957, 35.833259009000074],
            [-80.864598280999985, 35.833239009000067],
            [-80.865290281999989, 35.833248009000044],
            [-80.866989282999953, 35.833409009000036],
            [-80.867179281999938, 35.833430010000029],
            [-80.86736928199997, 35.833461009000075],
            [-80.867738282999937, 35.833551009000075],
            [-80.868317283999943, 35.833812010000031],
            [-80.86886528399998, 35.834113010000067],
            [-80.869390282999973, 35.834316009000077],
            [-80.869931282999971, 35.834485009000048],
            [-80.870761283999968, 35.834722009000075],
            [-80.871330283999953, 35.834856009000077],
            [-80.871908284999961, 35.83495700900005],
            [-80.87249428299998, 35.835023009000054],
            [-80.872688284999981, 35.834715009000035],
            [-80.873401283999954, 35.835285008000028],
            [-80.873588283999936, 35.835515009000062],
            [-80.873724283999934, 35.835768010000038],
            [-80.873805283999957, 35.836037009000052],
            [-80.874094284999956, 35.837341010000046],
            [-80.874501285999941, 35.837346010000033],
            [-80.874688284999934, 35.837343009000051],
            [-80.874867285999983, 35.837311010000064],
            [-80.875062285999945, 35.837250010000048],
            [-80.877347285999974, 35.837247010000056],
            [-80.878843285999949, 35.837247009000066],
            [-80.878888286999938, 35.838263010000048],
            [-80.880219286999989, 35.838213010000061],
            [-80.880287286999987, 35.838234009000075],
            [-80.880310286999986, 35.838275009000029],
            [-80.880351286999939, 35.839101010000036],
            [-80.880471286999978, 35.842044011000041],
            [-80.880483287999937, 35.842826010000067],
            [-80.881153286999961, 35.842808011000045],
            [-80.881779286999972, 35.842846011000063],
            [-80.882162287999961, 35.842857010000046],
            [-80.882653288999961, 35.842872010000065],
            [-80.883520287999943, 35.842892010000071],
            [-80.884743287999981, 35.842939010000066],
            [-80.885901288999946, 35.842967010000052],
            [-80.886362287999987, 35.842953010000031],
            [-80.886904289999961, 35.842971010000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 81,
        "SolutionID": "PI-30:E",
        "Shape_Length": 0.49691866838517318,
        "Shape_Area": 0.0055389564186813867
      }
    },
    {
      "type": "Feature",
      "id": 82,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.949565095999958, 35.228015456000037],
            [-80.949958841999944, 35.228005507000034],
            [-80.950352220999946, 35.227988363000065],
            [-80.950745034999954, 35.227964029000077],
            [-80.951137091999954, 35.227932521000071],
            [-80.95152819499998, 35.227893850000044],
            [-80.951918150999973, 35.227848040000026],
            [-80.952306765999936, 35.227795109000056],
            [-80.952693845999988, 35.227735088000031],
            [-80.953079199999934, 35.227668003000076],
            [-80.953462636999973, 35.227593890000037],
            [-80.953843964999976, 35.227512784000055],
            [-80.954222994999952, 35.227424727000027],
            [-80.954599539999947, 35.227329760000032],
            [-80.954973412999948, 35.227227933000052],
            [-80.955344425999954, 35.22711929500008],
            [-80.955712395999967, 35.227003900000057],
            [-80.95607714099998, 35.226881806000051],
            [-80.956438479999974, 35.226753074000044],
            [-80.956796231999988, 35.22661776700005],
            [-80.95715021999996, 35.226475952000044],
            [-80.957500268999979, 35.22632770000007],
            [-80.957846204999953, 35.226173085000028],
            [-80.958187854999949, 35.22601218300008],
            [-80.958525049999935, 35.225845074000063],
            [-80.958857623999961, 35.225671842000054],
            [-80.959185408999986, 35.225492571000075],
            [-80.959508244999938, 35.225307352000073],
            [-80.95982596999994, 35.225116277000041],
            [-80.960138427999937, 35.224919439000075],
            [-80.960445460999949, 35.224716937000039],
            [-80.960746917999984, 35.224508872000058],
            [-80.961042650999957, 35.224295347000066],
            [-80.961332510999966, 35.224076467000032],
            [-80.96161635499999, 35.223852343000033],
            [-80.961894041999983, 35.223623083000064],
            [-80.962165433999985, 35.223388804000024],
            [-80.962430395999945, 35.223149621000061],
            [-80.962688797999988, 35.222905652000065],
            [-80.962940508999964, 35.222657020000042],
            [-80.96318540599998, 35.222403846000077],
            [-80.963423367999951, 35.222146259000056],
            [-80.963654275999943, 35.221884384000077],
            [-80.963878013999988, 35.221618352000064],
            [-80.964094472999989, 35.221348295000041],
            [-80.96430354499995, 35.221074348000059],
            [-80.964505126999939, 35.220796646000053],
            [-80.964699117999942, 35.220515328000033],
            [-80.964885420999963, 35.220230532000073],
            [-80.96506394499994, 35.219942401000026],
            [-80.965234600999963, 35.219651076000048],
            [-80.965397303999964, 35.219356705000052],
            [-80.965551973999936, 35.219059431000062],
            [-80.965698533999955, 35.218759402000046],
            [-80.96583691099994, 35.218456769000056],
            [-80.965967036999984, 35.218151681000052],
            [-80.96608884699998, 35.217844289000027],
            [-80.966202279999948, 35.217534747000059],
            [-80.966307281999946, 35.217223207000075],
            [-80.966403798999977, 35.216909824000027],
            [-80.966491781999935, 35.216594755000074],
            [-80.966571190999957, 35.216278155000055],
            [-80.966641983999978, 35.21596018300005],
            [-80.966704125999968, 35.215640994000069],
            [-80.96675758799995, 35.215320749000057],
            [-80.966802341999937, 35.21499960500006],
            [-80.96683836699998, 35.214677723000079],
            [-80.966865643999938, 35.214355262000026],
            [-80.96688415999995, 35.214032382000028],
            [-80.966893905999939, 35.213709244000029],
            [-80.966894878999938, 35.213386008000043],
            [-80.966887074999988, 35.213062835000073],
            [-80.96687050099996, 35.21273988400003],
            [-80.966845163999949, 35.212417317000074],
            [-80.966811075999942, 35.212095292000072],
            [-80.966768256999956, 35.211773971000071],
            [-80.96671672399998, 35.211453512000048],
            [-80.966656506999982, 35.211134076000064],
            [-80.966587632999961, 35.210815819000061],
            [-80.96651013799999, 35.210498901000051],
            [-80.966424059999952, 35.210183478000033],
            [-80.966329439999981, 35.209869708000042],
            [-80.966226328999937, 35.209557745000041],
            [-80.966114774999937, 35.209247745000027],
            [-80.965994833999957, 35.208939862000079],
            [-80.965866566999978, 35.208634249000056],
            [-80.965730036999958, 35.208331055000031],
            [-80.965585309999938, 35.208030434000079],
            [-80.965432460999978, 35.207732534000058],
            [-80.965271564999966, 35.207437502000062],
            [-80.965102700999978, 35.207145485000069],
            [-80.964925953999966, 35.206856628000025],
            [-80.964741410999977, 35.206571074000067],
            [-80.964549162999958, 35.206288966000045],
            [-80.964349306999964, 35.206010442000036],
            [-80.964141941999969, 35.205735641000047],
            [-80.963927169999977, 35.20546470000005],
            [-80.963705098999981, 35.205197754000039],
            [-80.963475838999955, 35.20493493400005],
            [-80.963239502999954, 35.204676371000062],
            [-80.962996207999936, 35.204422193000028],
            [-80.962746075999974, 35.204172527000026],
            [-80.96248922999996, 35.203927496000063],
            [-80.962225799999942, 35.203687223000031],
            [-80.961955913999986, 35.203451825000059],
            [-80.961679706999973, 35.203221420000034],
            [-80.961397315999989, 35.202996123000048],
            [-80.961108881999962, 35.202776045000064],
            [-80.960814546999984, 35.202561294000077],
            [-80.960514457999977, 35.20235198000006],
            [-80.960208762999969, 35.202148203000036],
            [-80.959897615999978, 35.201950067000041],
            [-80.959581167999943, 35.201757669000074],
            [-80.959259577999944, 35.201571104000038],
            [-80.958933005999938, 35.201390465000031],
            [-80.958601611999939, 35.201215844000046],
            [-80.958265563999987, 35.201047324000058],
            [-80.957925024999952, 35.200884990000077],
            [-80.957580165999957, 35.200728923000042],
            [-80.957231158999946, 35.20057920000005],
            [-80.956878174999986, 35.200435895000055],
            [-80.956521389999978, 35.200299080000036],
            [-80.956160982999961, 35.20016882200008],
            [-80.955797128999961, 35.200045186000068],
            [-80.955430010999976, 35.199928235000073],
            [-80.955059811999945, 35.199818023000034],
            [-80.954686712999944, 35.199714609000068],
            [-80.954310901999975, 35.199618042000054],
            [-80.953932561999977, 35.199528370000053],
            [-80.953551883999978, 35.199445637000053],
            [-80.953169056999968, 35.199369885000067],
            [-80.952784268999949, 35.199301153000079],
            [-80.952397709999957, 35.199239472000045],
            [-80.952009573999987, 35.199184876000061],
            [-80.951620053999989, 35.199137388000054],
            [-80.951229341999976, 35.199097035000079],
            [-80.950837630999956, 35.199063835000061],
            [-80.950445116999958, 35.199037806000035],
            [-80.950051993999978, 35.199018960000046],
            [-80.949658457999988, 35.199007307000045],
            [-80.949264701999937, 35.199002851000046],
            [-80.948870923999948, 35.199005597000053],
            [-80.94847731699997, 35.199015541000051],
            [-80.948084077999965, 35.199032681000062],
            [-80.947691402999965, 35.199057005000043],
            [-80.947299482999938, 35.199088503000041],
            [-80.946908515999951, 35.199127159000057],
            [-80.946518694999952, 35.199172955000051],
            [-80.946130212999947, 35.199225866000063],
            [-80.945743263999987, 35.199285867000071],
            [-80.945358036999949, 35.199352928000053],
            [-80.944974725999941, 35.199427016000072],
            [-80.944593519999955, 35.199508094000066],
            [-80.94421460999996, 35.199596123000049],
            [-80.94383818099999, 35.199691057000052],
            [-80.943464420999987, 35.199792851000041],
            [-80.943093516999966, 35.199901452000063],
            [-80.942725649999943, 35.200016809000033],
            [-80.942361005999942, 35.200138863000063],
            [-80.941999762999956, 35.20026755400005],
            [-80.941642101999946, 35.200402818000043],
            [-80.941288199999974, 35.200544588000071],
            [-80.940938231999951, 35.200692794000076],
            [-80.940592372999959, 35.200847361000058],
            [-80.940250793999951, 35.201008214000069],
            [-80.93991366299997, 35.201175273000047],
            [-80.939581149999981, 35.201348454000026],
            [-80.939253417999964, 35.201527672000054],
            [-80.938930629999959, 35.201712837000059],
            [-80.938612947999957, 35.201903859000026],
            [-80.938300525999978, 35.202100641000072],
            [-80.937993522999989, 35.202303088000065],
            [-80.937692089999985, 35.20251109600008],
            [-80.937396374999935, 35.202724566000029],
            [-80.937106526999969, 35.202943388000051],
            [-80.93682268799995, 35.203167457000063],
            [-80.936545000999956, 35.203396659000077],
            [-80.936273600999982, 35.203630881000038],
            [-80.936008625999989, 35.203870008000024],
            [-80.935750204999977, 35.204113921000044],
            [-80.935498467999935, 35.204362497000034],
            [-80.935253538999973, 35.204615614000033],
            [-80.935015538999949, 35.204873148000047],
            [-80.934784587999957, 35.205134969000028],
            [-80.934560798999939, 35.205400947000044],
            [-80.934344284999952, 35.205670951000059],
            [-80.934135151999953, 35.205944847000069],
            [-80.933933502999935, 35.206222500000024],
            [-80.933739439999954, 35.20650377000004],
            [-80.933553059999952, 35.206788518000053],
            [-80.933374452999942, 35.207076604000065],
            [-80.93320370899994, 35.207367884000064],
            [-80.933040913999946, 35.207662213000049],
            [-80.932886146999977, 35.207959445000029],
            [-80.932739485999946, 35.208259435000059],
            [-80.932601002999945, 35.208562030000053],
            [-80.932470766999984, 35.20886708200004],
            [-80.932348843999989, 35.209174441000073],
            [-80.932235293999952, 35.209483952000028],
            [-80.932130171999972, 35.209795462000045],
            [-80.932033531999934, 35.210108818000037],
            [-80.931945421999956, 35.210423863000074],
            [-80.931865884999979, 35.210740440000052],
            [-80.931794959999934, 35.21105839300003],
            [-80.931732684999986, 35.211377564000031],
            [-80.931679087999953, 35.211697794000031],
            [-80.931634197999983, 35.212018924000063],
            [-80.93159803399999, 35.212340797000024],
            [-80.931570618999956, 35.212663249000059],
            [-80.931551962999947, 35.212986124000054],
            [-80.931542075999971, 35.213309259000027],
            [-80.931540963999964, 35.21363249500007],
            [-80.931548626999984, 35.213955670000075],
            [-80.931565061999947, 35.214278626000066],
            [-80.93159025999995, 35.21460120100005],
            [-80.931624208999949, 35.214923235000072],
            [-80.931666890999963, 35.21524456800006],
            [-80.931718287999956, 35.215565041000048],
            [-80.93177837199994, 35.215884495000068],
            [-80.931847113999936, 35.216202771000042],
            [-80.931924478999974, 35.216519712000036],
            [-80.932010430999981, 35.216835158000038],
            [-80.932104926999955, 35.217148955000027],
            [-80.932207916999971, 35.217460946000074],
            [-80.932319352999968, 35.217770977000043],
            [-80.93243917999996, 35.21807889400003],
            [-80.932567335999977, 35.218384542000024],
            [-80.932703759999981, 35.218687772000067],
            [-80.932848383999954, 35.21898843200006],
            [-80.933001134999984, 35.219286373000045],
            [-80.933161937999955, 35.219581448000042],
            [-80.933330712999975, 35.219873509000024],
            [-80.933507375999966, 35.220162412000036],
            [-80.933691840999984, 35.220448013000066],
            [-80.93388401499999, 35.220730169000035],
            [-80.934083801999975, 35.221008743000027],
            [-80.934291104999943, 35.221283593000067],
            [-80.93450581999997, 35.221554586000025],
            [-80.934727840999983, 35.221821586000033],
            [-80.934957055999973, 35.222084460000076],
            [-80.935193351999942, 35.222343078000051],
            [-80.935436613999968, 35.222597310000026],
            [-80.935686718999989, 35.222847032000061],
            [-80.93594354399994, 35.223092119000057],
            [-80.936206958999946, 35.223332449000054],
            [-80.93647683599994, 35.223567904000049],
            [-80.936753040999974, 35.22379836600004],
            [-80.937035435999974, 35.224023720000048],
            [-80.937323879999951, 35.224243855000054],
            [-80.937618231999977, 35.224458661000028],
            [-80.937918342999978, 35.224668032000068],
            [-80.938224066999965, 35.224871864000079],
            [-80.938535248999983, 35.22507005500006],
            [-80.938851736999936, 35.225262508000071],
            [-80.939173374999939, 35.225449126000058],
            [-80.939499998999963, 35.225629817000026],
            [-80.939831450999975, 35.225804491000076],
            [-80.940167563999978, 35.225973062000037],
            [-80.940508171999966, 35.226135445000068],
            [-80.940853104999974, 35.22629156000005],
            [-80.941202192999981, 35.226441330000057],
            [-80.941555261999952, 35.226584679000041],
            [-80.941912136999974, 35.22672153700006],
            [-80.942272638999953, 35.226851837000027],
            [-80.942636591999985, 35.22697551300007],
            [-80.943003812999962, 35.227092504000041],
            [-80.943374119999987, 35.22720275100005],
            [-80.943747329999951, 35.227306201000033],
            [-80.944123256999944, 35.22740280000005],
            [-80.944501714999944, 35.227492503000065],
            [-80.944882514999961, 35.227575263000062],
            [-80.945265466999956, 35.227651040000069],
            [-80.945650382999986, 35.227719797000077],
            [-80.946037070999978, 35.22778149800007],
            [-80.946425339999962, 35.227836115000059],
            [-80.946814993999965, 35.227883618000078],
            [-80.94720584199996, 35.227923986000064],
            [-80.947597689999952, 35.22795719700008],
            [-80.947990341999969, 35.227983236000057],
            [-80.948383604999947, 35.228002088000039],
            [-80.948777280999934, 35.228013745000055],
            [-80.949171176999982, 35.228018202000044],
            [-80.949565095999958, 35.228015456000037]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 82,
        "SolutionID": "PI-31:A",
        "Shape_Length": 0.10135641818995575,
        "Shape_Area": 0.00080563155948119814
      }
    },
    {
      "type": "Feature",
      "id": 83,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.762181073999955, 35.340934150000066],
            [-80.762575388999949, 35.340924812000026],
            [-80.762969351999971, 35.34090827700004],
            [-80.76336276699999, 35.340884552000034],
            [-80.763755440999944, 35.340853652000078],
            [-80.764147174999948, 35.340815588000055],
            [-80.764537776999987, 35.340770382000073],
            [-80.764927051999962, 35.340718055000025],
            [-80.765314806999982, 35.340658634000079],
            [-80.765700850999963, 35.340592148000042],
            [-80.766084989999968, 35.340518631000066],
            [-80.766467033999959, 35.34043811600003],
            [-80.766846791999967, 35.340350648000026],
            [-80.76722407699998, 35.340256267000029],
            [-80.767598702999976, 35.340155021000044],
            [-80.767970480999963, 35.340046960000052],
            [-80.768339227999945, 35.339932137000062],
            [-80.768704758999945, 35.339810611000075],
            [-80.76906689499998, 35.339682441000036],
            [-80.769425455999965, 35.339547691000064],
            [-80.769780260999937, 35.339406427000029],
            [-80.770131136999964, 35.339258721000078],
            [-80.770477907999975, 35.339104645000077],
            [-80.770820402999959, 35.338944276000063],
            [-80.771158449999973, 35.338777693000054],
            [-80.771491883999943, 35.338604979000024],
            [-80.77182053699994, 35.338426220000031],
            [-80.772144246999972, 35.338241505000042],
            [-80.772462852999979, 35.338050925000061],
            [-80.772776196999985, 35.337854576000041],
            [-80.77308412299999, 35.337652554000044],
            [-80.773386476999974, 35.337444960000028],
            [-80.773683111999958, 35.337231898000027],
            [-80.773973876999946, 35.337013471000034],
            [-80.774258630999952, 35.336789791000058],
            [-80.774537230999954, 35.336560967000025],
            [-80.774809538999989, 35.336327113000038],
            [-80.775075419999951, 35.336088345000064],
            [-80.775334740999938, 35.335844782000038],
            [-80.77558737399994, 35.335596545000044],
            [-80.775833193999972, 35.335343756000043],
            [-80.776072079999949, 35.335086543000045],
            [-80.776303909999967, 35.334825031000037],
            [-80.776528571999961, 35.334559351000053],
            [-80.776745952999988, 35.334289635000061],
            [-80.776955945999987, 35.334016018000057],
            [-80.777158446999977, 35.333738634000042],
            [-80.777353353999956, 35.333457622000026],
            [-80.777540570999975, 35.333173121000073],
            [-80.777720006999971, 35.332885272000055],
            [-80.777891570999941, 35.332594218000054],
            [-80.778055176999942, 35.332300105000058],
            [-80.778210745999957, 35.332003077000024],
            [-80.778358199999957, 35.331703282000035],
            [-80.778497465999976, 35.331400869000049],
            [-80.778628474999948, 35.331095989000062],
            [-80.778751161999935, 35.330788792000078],
            [-80.778865464999967, 35.33047943300005],
            [-80.77897132999999, 35.330168061000052],
            [-80.779068701999961, 35.329854835000049],
            [-80.779157531999942, 35.329539909000061],
            [-80.77923777999996, 35.329223439000032],
            [-80.779309403999946, 35.328905583000051],
            [-80.779372366999951, 35.32858649700006],
            [-80.779426640999986, 35.328266341000074],
            [-80.779472195999972, 35.327945273000068],
            [-80.779509011999949, 35.327623454000047],
            [-80.779537068999957, 35.327301042000045],
            [-80.779556354999954, 35.326978198000063],
            [-80.779566858999942, 35.326655082000059],
            [-80.779568576999964, 35.326331854000045],
            [-80.779561506999983, 35.326008676000072],
            [-80.779545652999957, 35.325685706000058],
            [-80.779521022999973, 35.325363106000054],
            [-80.779487629999949, 35.325041035000027],
            [-80.77944549099999, 35.324719655000024],
            [-80.779394624999952, 35.324399123000035],
            [-80.779335058999948, 35.324079600000061],
            [-80.779266822999944, 35.323761244000025],
            [-80.779189949999989, 35.323444212000027],
            [-80.779104477999965, 35.323128663000034],
            [-80.779010449999987, 35.322814753000046],
            [-80.778907913999944, 35.322502637000071],
            [-80.778796918999944, 35.322192470000061],
            [-80.778677520999963, 35.321884408000074],
            [-80.778549779999935, 35.321578602000045],
            [-80.778413757999942, 35.321275203000027],
            [-80.778269522999949, 35.320974365000041],
            [-80.778117147999978, 35.320676234000075],
            [-80.777956707999977, 35.320380959000033],
            [-80.77778828199996, 35.320088686000076],
            [-80.777611954999941, 35.319799562000071],
            [-80.777427812999974, 35.319513728000061],
            [-80.777235948999987, 35.319231328000058],
            [-80.777036456999951, 35.31895250000008],
            [-80.776829436999947, 35.318677384000068],
            [-80.776614990999974, 35.318406116000062],
            [-80.776393225999982, 35.318138832000045],
            [-80.77616425399998, 35.317875662000063],
            [-80.775928184999941, 35.317616739000073],
            [-80.775685137999972, 35.31736218900005],
            [-80.775435233999985, 35.317112141000052],
            [-80.775178595999989, 35.316866718000028],
            [-80.774915353999972, 35.316626041000063],
            [-80.774645635999946, 35.316390230000025],
            [-80.774369576999959, 35.316159403000029],
            [-80.774087312999939, 35.315933673000075],
            [-80.773798984999985, 35.315713152000058],
            [-80.773504736999939, 35.315497950000065],
            [-80.773204713999974, 35.315288175000035],
            [-80.772899063999944, 35.315083929000025],
            [-80.772587941999973, 35.314885316000073],
            [-80.772271497999952, 35.314692431000026],
            [-80.771949891999952, 35.314505373000031],
            [-80.77162328299994, 35.314324232000047],
            [-80.771291831999974, 35.314149100000066],
            [-80.770955704999949, 35.313980064000077],
            [-80.770615067999984, 35.313817206000067],
            [-80.770270090999986, 35.313660608000077],
            [-80.769920943999978, 35.313510347000033],
            [-80.769567800999937, 35.31336649900004],
            [-80.769210836999946, 35.313229134000039],
            [-80.768850228999952, 35.313098320000051],
            [-80.768486155999938, 35.31297412400005],
            [-80.768118797999989, 35.312856606000025],
            [-80.767748339999969, 35.312745823000057],
            [-80.767374961999963, 35.312641833000043],
            [-80.766998851999972, 35.312544686000024],
            [-80.766620194999973, 35.312454430000059],
            [-80.766239179999957, 35.312371110000072],
            [-80.765855995999971, 35.312294767000026],
            [-80.765470832999938, 35.312225439000031],
            [-80.765083879999963, 35.312163161000058],
            [-80.764695331999974, 35.312107965000052],
            [-80.764305380999986, 35.312059875000045],
            [-80.763914219999947, 35.312018917000046],
            [-80.763522042999966, 35.311985112000059],
            [-80.763129044999971, 35.31195847500004],
            [-80.762735419999956, 35.311939020000068],
            [-80.762341365999987, 35.311926758000027],
            [-80.761947074999966, 35.311921693000045],
            [-80.761552743999971, 35.311923829000079],
            [-80.761158568999974, 35.311933163000049],
            [-80.76076474599995, 35.311949693000031],
            [-80.760371469999939, 35.311973408000028],
            [-80.759978934999936, 35.31200429900008],
            [-80.759587336999971, 35.312042349000023],
            [-80.759196869999982, 35.31208754000005],
            [-80.758807727999965, 35.312139848000072],
            [-80.758420103999981, 35.312199249000059],
            [-80.758034189999989, 35.312265711000066],
            [-80.75765017599997, 35.312339205000058],
            [-80.757268255999975, 35.312419691000059],
            [-80.756888617999948, 35.312507130000029],
            [-80.756511448999959, 35.312601480000069],
            [-80.756136935999962, 35.312702692000073],
            [-80.755765267999948, 35.312810717000048],
            [-80.755396625999936, 35.312925501000052],
            [-80.755031195999948, 35.313046989000043],
            [-80.754669155999977, 35.313175117000071],
            [-80.754310686999986, 35.313309824000044],
            [-80.753955968999946, 35.313451043000043],
            [-80.753605174999961, 35.313598704000071],
            [-80.753258480999989, 35.313752732000069],
            [-80.752916057999983, 35.313913053000078],
            [-80.752578075999963, 35.314079586000048],
            [-80.752244701999984, 35.314252248000059],
            [-80.751916103999974, 35.314430955000034],
            [-80.751592441999946, 35.314615616000026],
            [-80.751273879999985, 35.314806142000066],
            [-80.75096057199994, 35.315002436000043],
            [-80.750652677999938, 35.315204403000052],
            [-80.750350347999984, 35.315411940000047],
            [-80.75005373099998, 35.31562494700006],
            [-80.749762977999978, 35.315843316000041],
            [-80.749478229999966, 35.316066940000042],
            [-80.749199629999964, 35.316295708000041],
            [-80.748927314999946, 35.316529505000062],
            [-80.748661421999941, 35.316768216000071],
            [-80.748402081999984, 35.317011724000054],
            [-80.748149423999962, 35.317259905000071],
            [-80.747903571999984, 35.317512638000039],
            [-80.747664648999944, 35.317769797000039],
            [-80.747432774999936, 35.318031255000051],
            [-80.747208063999949, 35.318296881000038],
            [-80.746990627999935, 35.318566544000078],
            [-80.746780574999946, 35.318840111000043],
            [-80.746578006999982, 35.319117445000074],
            [-80.746383027999968, 35.31939840900003],
            [-80.746195732999979, 35.319682862000036],
            [-80.746016214999941, 35.319970665000028],
            [-80.745844563999981, 35.320261674000051],
            [-80.745680865999987, 35.320555745000036],
            [-80.745525199999975, 35.320852732000048],
            [-80.745377644999962, 35.321152488000052],
            [-80.745238272999984, 35.321454862000053],
            [-80.745107153999982, 35.32175970600008],
            [-80.744984353999939, 35.322066870000072],
            [-80.744869933999951, 35.322376199000075],
            [-80.744763948999946, 35.322687539000071],
            [-80.744666452999979, 35.323000739000065],
            [-80.744577495999977, 35.323315640000033],
            [-80.744497118999959, 35.323632087000078],
            [-80.744425363999937, 35.323949924000033],
            [-80.744362266999985, 35.324268992000043],
            [-80.744307858999946, 35.324589132000028],
            [-80.744262166999988, 35.324910186000068],
            [-80.744225211999947, 35.32523199600007],
            [-80.744197015999987, 35.325554399000055],
            [-80.744177589999936, 35.325877238000032],
            [-80.74416694599995, 35.326200351000068],
            [-80.744165086999942, 35.326523578000035],
            [-80.744172016999983, 35.326846759000034],
            [-80.744187730999954, 35.327169733000062],
            [-80.744212219999952, 35.327492340000049],
            [-80.744245474999957, 35.327814420000038],
            [-80.744287476999943, 35.328135812000028],
            [-80.744338206999942, 35.328456358000039],
            [-80.744397637999953, 35.32877589900005],
            [-80.744465741999988, 35.329094275000045],
            [-80.744542484999954, 35.329411328000049],
            [-80.744627828999967, 35.329726900000026],
            [-80.744721732999949, 35.330040838000059],
            [-80.744824146999974, 35.330352981000033],
            [-80.744935022999982, 35.330663178000066],
            [-80.745054306999975, 35.330971274000035],
            [-80.745181936999984, 35.331277115000034],
            [-80.745317850999982, 35.33158054900008],
            [-80.745461982999984, 35.331881428000031],
            [-80.745614259999968, 35.332179599000028],
            [-80.745774605999941, 35.332474916000024],
            [-80.745942941999942, 35.332767233000027],
            [-80.746119184999941, 35.333056402000068],
            [-80.746303246999958, 35.333342283000036],
            [-80.746495037999978, 35.333624731000043],
            [-80.746694459999958, 35.333903608000071],
            [-80.746901416999947, 35.334178774000065],
            [-80.747115805999954, 35.334450094000033],
            [-80.747337518999984, 35.334717432000048],
            [-80.747566445999951, 35.334980655000038],
            [-80.747802473999968, 35.335239633000072],
            [-80.748045486999956, 35.335494238000024],
            [-80.748295363999944, 35.335744341000066],
            [-80.748551979999945, 35.335989821000055],
            [-80.74881520699995, 35.336230554000053],
            [-80.749084915999958, 35.336466422000058],
            [-80.749360971999977, 35.336697306000076],
            [-80.749643239999955, 35.336923093000053],
            [-80.749931576999984, 35.337143670000046],
            [-80.750225840999974, 35.337358927000025],
            [-80.750525885999934, 35.337568759000078],
            [-80.750831563999952, 35.337773060000075],
            [-80.751142720999951, 35.33797172900006],
            [-80.751459204999946, 35.338164668000047],
            [-80.751780857999961, 35.338351780000039],
            [-80.752107518999935, 35.338532973000042],
            [-80.752439027999969, 35.338708157000042],
            [-80.752775217999954, 35.338877245000049],
            [-80.753115924999975, 35.339040152000052],
            [-80.753460976999975, 35.339196798000046],
            [-80.753810202999944, 35.339347105000058],
            [-80.754163430999938, 35.339490999000077],
            [-80.75452048599999, 35.339628406000031],
            [-80.754881186999967, 35.33975926200003],
            [-80.755245359999947, 35.339883499000052],
            [-80.755612820999943, 35.340001056000062],
            [-80.755983386999958, 35.340111874000058],
            [-80.756356875999984, 35.340215900000032],
            [-80.756733100999952, 35.340313079000055],
            [-80.757111877999989, 35.340403366000032],
            [-80.757493014999966, 35.340486715000054],
            [-80.757876322999948, 35.340563083000063],
            [-80.758261613999935, 35.340632435000032],
            [-80.758648696999956, 35.340694734000067],
            [-80.75903737799996, 35.340749950000031],
            [-80.759427462999952, 35.34079805600004],
            [-80.759818759999973, 35.340839028000062],
            [-80.760211073999983, 35.340872845000035],
            [-80.760604210999986, 35.340899492000062],
            [-80.760997975999942, 35.340918952000038],
            [-80.761392170999954, 35.340931220000073],
            [-80.761786601999972, 35.340936286000044],
            [-80.762181073999955, 35.340934150000066]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 83,
        "SolutionID": "PI-31:C",
        "Shape_Length": 0.10143628817853881,
        "Shape_Area": 0.00080673415273239718
      }
    },
    {
      "type": "Feature",
      "id": 84,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.878118240999981, 35.142075007000074],
            [-80.878030287999934, 35.142078742000024],
            [-80.877942820999976, 35.142087193000066],
            [-80.877856213999962, 35.142100325000058],
            [-80.877770837999947, 35.142118082000025],
            [-80.877687057999935, 35.14214038700004],
            [-80.877605233999986, 35.142167146000077],
            [-80.87752571599998, 35.142198243000053],
            [-80.877448843999957, 35.142233545000067],
            [-80.877374946999964, 35.142272901000069],
            [-80.877304341999945, 35.142316143000073],
            [-80.877237330999947, 35.142363086000046],
            [-80.877174201999935, 35.142413528000077],
            [-80.877115222999976, 35.142467254000053],
            [-80.877060648999986, 35.142524033000029],
            [-80.877010711999958, 35.142583622000075],
            [-80.876965626999947, 35.142645766000044],
            [-80.87692558699996, 35.142710200000067],
            [-80.876890762999949, 35.142776646000073],
            [-80.876861302999941, 35.14284482100004],
            [-80.876837334999948, 35.142914432000055],
            [-80.876818961999959, 35.142985182000075],
            [-80.876806260999956, 35.143056769000054],
            [-80.876799285999937, 35.143128883000031],
            [-80.87679806899996, 35.143201218000058],
            [-80.876802614999974, 35.14327346400006],
            [-80.876812902999973, 35.143345311000076],
            [-80.876825450999945, 35.143403143000057],
            [-80.876974032999954, 35.14399621900003],
            [-80.877136616999962, 35.144650327000079],
            [-80.877149947999953, 35.144733961000043],
            [-80.877153632999978, 35.144755442000076],
            [-80.877165666999986, 35.144811209000068],
            [-80.877303046999941, 35.145364897000036],
            [-80.877307000999963, 35.145380270000032],
            [-80.877309019999984, 35.145387734000053],
            [-80.877436526999986, 35.145851338000057],
            [-80.877541594999968, 35.146256417000075],
            [-80.877543116999959, 35.146262207000063],
            [-80.877545482999949, 35.146270928000035],
            [-80.87756512499999, 35.14634195900004],
            [-80.877575262999983, 35.146387283000024],
            [-80.877582160999964, 35.146428855000067],
            [-80.877588000999936, 35.146484115000078],
            [-80.877592333999985, 35.146539347000044],
            [-80.877592116999949, 35.146576089000064],
            [-80.87759006899995, 35.146614649000071],
            [-80.877569667999978, 35.146706695000034],
            [-80.877569034999965, 35.146709573000066],
            [-80.877560480999989, 35.14675391600008],
            [-80.877539888999934, 35.146876761000044],
            [-80.877537866999944, 35.14688936400006],
            [-80.877489379999986, 35.147205472000053],
            [-80.877488989999961, 35.147208044000024],
            [-80.877433130999975, 35.147579231000066],
            [-80.877433001999975, 35.147580093000045],
            [-80.877342440999939, 35.148185782000041],
            [-80.877306215999965, 35.148421216000031],
            [-80.877251505999936, 35.148730801000056],
            [-80.877246748999937, 35.148760549000031],
            [-80.877195388999951, 35.149119451000047],
            [-80.877158621999968, 35.14933548700003],
            [-80.877154546999975, 35.149361713000076],
            [-80.877130216999944, 35.149534758000073],
            [-80.877085910999938, 35.149815766000074],
            [-80.877084914999955, 35.149822239000059],
            [-80.87703897199998, 35.150127773000065],
            [-80.877038574999972, 35.150130439000066],
            [-80.87699564899998, 35.150421728000026],
            [-80.876994979999949, 35.150426347000064],
            [-80.876958541999954, 35.150682649000032],
            [-80.876925734999986, 35.150867963000053],
            [-80.876924870999972, 35.150872922000076],
            [-80.87688185199994, 35.151123904000031],
            [-80.876880297999946, 35.151133259000062],
            [-80.876851540999951, 35.151312075000078],
            [-80.876819025999964, 35.151481305000061],
            [-80.87679841399995, 35.151578375000042],
            [-80.876724754999941, 35.151897889000054],
            [-80.876680584999974, 35.152080734000037],
            [-80.876676463999956, 35.15209856000007],
            [-80.876605996999956, 35.15241760400005],
            [-80.876555481999958, 35.152638501000069],
            [-80.87647659299995, 35.152970150000044],
            [-80.876474809999934, 35.152977785000076],
            [-80.876400369999942, 35.153302852000024],
            [-80.876357519999942, 35.153485440000054],
            [-80.876356774999977, 35.153488641000024],
            [-80.876295561999939, 35.15375367300004],
            [-80.87623532899994, 35.154012734000048],
            [-80.876234577999981, 35.154015986000047],
            [-80.876174280999976, 35.154279603000077],
            [-80.876172277999956, 35.154288564000069],
            [-80.876166292999983, 35.154318299000067],
            [-80.876111433999938, 35.154616033000025],
            [-80.876067270999954, 35.154841804000057],
            [-80.876008187999957, 35.155107615000077],
            [-80.876007441999946, 35.155111000000034],
            [-80.876006321999967, 35.15511619800003],
            [-80.875959526999964, 35.155336550000072],
            [-80.875954834999959, 35.155360149000046],
            [-80.875882898999976, 35.155748301000074],
            [-80.875880470999959, 35.155761974000029],
            [-80.875838577999957, 35.156008712000073],
            [-80.875792709999985, 35.156262162000075],
            [-80.875787959999968, 35.156291014000033],
            [-80.875761074999957, 35.156472284000074],
            [-80.875758428999973, 35.156484062000061],
            [-80.875757239999984, 35.156489435000026],
            [-80.875744535999956, 35.156561021000073],
            [-80.875738592999937, 35.156617352000069],
            [-80.875730291999957, 35.15672924100005],
            [-80.875729257999978, 35.156745025000077],
            [-80.875728039999956, 35.156817360000048],
            [-80.87573069299998, 35.156868905000067],
            [-80.875742048999939, 35.15701094700006],
            [-80.875743941999986, 35.157031648000043],
            [-80.87575239399996, 35.157093166000038],
            [-80.875769131999959, 35.157190465000042],
            [-80.875770968999973, 35.157200793000072],
            [-80.875777784999968, 35.157234371000072],
            [-80.875799246999975, 35.157330913000067],
            [-80.875808420999988, 35.157368476000045],
            [-80.875811416999966, 35.157379463000041],
            [-80.875838159999944, 35.157475235000049],
            [-80.875852504999955, 35.157521914000029],
            [-80.875884240999937, 35.157616462000078],
            [-80.875888518999943, 35.15762892500004],
            [-80.875889236999967, 35.157630961000052],
            [-80.87598885899996, 35.157912706000047],
            [-80.875994245999948, 35.157927552000046],
            [-80.87616752699995, 35.158393148000073],
            [-80.876238217999969, 35.158596231000047],
            [-80.876239057999953, 35.158598633000054],
            [-80.876255234999974, 35.158641433000071],
            [-80.876376568999945, 35.158940874000052],
            [-80.876522619999946, 35.159321761000058],
            [-80.876693885999941, 35.159776968000074],
            [-80.876802855999983, 35.160075797000047],
            [-80.876921309999943, 35.160402936000025],
            [-80.876926969999943, 35.160418166000056],
            [-80.877037945999973, 35.160709240000074],
            [-80.877105625999945, 35.160896489000038],
            [-80.877089589999969, 35.160992742000076],
            [-80.877085595999972, 35.161019061000047],
            [-80.87708182099999, 35.161050714000055],
            [-80.877076430999978, 35.16110377900003],
            [-80.877072497999961, 35.161130353000033],
            [-80.877071093999973, 35.161140206000027],
            [-80.877070859999947, 35.161141920000034],
            [-80.877066882999941, 35.161171374000048],
            [-80.877056657999958, 35.161223557000028],
            [-80.877047176999952, 35.161261226000079],
            [-80.877042778999964, 35.161273818000041],
            [-80.877019896999968, 35.161330596000028],
            [-80.877015214999972, 35.161342438000077],
            [-80.876991241999974, 35.161412049000035],
            [-80.87698693699997, 35.161426747000064],
            [-80.876923476999934, 35.161650107000071],
            [-80.876919375999989, 35.161665008000057],
            [-80.876859821999972, 35.161888537000038],
            [-80.876858359999972, 35.161894089000043],
            [-80.876766392999969, 35.162247972000046],
            [-80.876761701999953, 35.162266827000053],
            [-80.876733987999955, 35.162383368000064],
            [-80.876730169999973, 35.162400112000057],
            [-80.876720272999989, 35.162452681000048],
            [-80.876708006999934, 35.162530133000075],
            [-80.876700442999947, 35.162565650000033],
            [-80.87665822799994, 35.162735961000067],
            [-80.87665605899997, 35.162744898000028],
            [-80.876586500999963, 35.163037848000045],
            [-80.876502243999937, 35.163387535000027],
            [-80.876436118999948, 35.163657594000028],
            [-80.87643084299998, 35.163680376000059],
            [-80.876427754999952, 35.163695085000029],
            [-80.876369440999952, 35.163984550000066],
            [-80.87636095199997, 35.164033454000048],
            [-80.87632963599998, 35.164248068000063],
            [-80.876328507999972, 35.164256041000044],
            [-80.876325262999956, 35.16428262200003],
            [-80.876308042999938, 35.164443202000029],
            [-80.876304354999945, 35.164487979000057],
            [-80.876298179999935, 35.164595252000026],
            [-80.876298136999935, 35.164596009000036],
            [-80.876296717999935, 35.16465590100006],
            [-80.876297853999972, 35.164777951000076],
            [-80.87629805499995, 35.164790393000033],
            [-80.876298409999947, 35.164802377000058],
            [-80.876319058999968, 35.165372303000026],
            [-80.876322970999979, 35.16542981300006],
            [-80.876377632999947, 35.165976966000073],
            [-80.87639252699995, 35.166177076000054],
            [-80.876393581999935, 35.166190017000076],
            [-80.876418432999969, 35.166469946000063],
            [-80.876418611999952, 35.166471935000061],
            [-80.876442741999938, 35.16673713800003],
            [-80.876445798999953, 35.166942611000024],
            [-80.87644593899995, 35.166950002000078],
            [-80.87644633299999, 35.166963054000064],
            [-80.876455033999946, 35.16719560000007],
            [-80.876457503999973, 35.167236694000053],
            [-80.876490694999973, 35.167637287000048],
            [-80.876536981999948, 35.168280807000031],
            [-80.876563390999934, 35.168668039000067],
            [-80.876566072999935, 35.168699367000045],
            [-80.876573918999952, 35.16875731500005],
            [-80.876605856999959, 35.168947408000065],
            [-80.876618311999948, 35.169119900000055],
            [-80.876618951999944, 35.169128228000034],
            [-80.876640520999956, 35.169393111000034],
            [-80.87668012599994, 35.170000087000062],
            [-80.876680937999936, 35.170011428000066],
            [-80.876693433999947, 35.170172036000054],
            [-80.876698216999955, 35.170294962000071],
            [-80.876698431999955, 35.170300090000069],
            [-80.87670467199996, 35.170440103000033],
            [-80.876709946999938, 35.170573944000068],
            [-80.87671174999997, 35.170605624000075],
            [-80.876721845999953, 35.170741274000079],
            [-80.876724125999942, 35.170766982000032],
            [-80.876725204999957, 35.170776927000077],
            [-80.876739181999938, 35.170899461000033],
            [-80.87674745999999, 35.170956196000077],
            [-80.876775011999939, 35.171111041000074],
            [-80.876775946999942, 35.171116208000058],
            [-80.876780630999974, 35.171139941000035],
            [-80.87681319099994, 35.171294250000074],
            [-80.876819350999938, 35.171321416000069],
            [-80.87685664199995, 35.171475193000049],
            [-80.876861789999964, 35.171495434000065],
            [-80.876863954999976, 35.171503424000036],
            [-80.876880995999954, 35.171565224000062],
            [-80.876892215999987, 35.171602841000038],
            [-80.876934407999954, 35.171734290000074],
            [-80.877035790999969, 35.172154876000036],
            [-80.877094276999969, 35.172399964000078],
            [-80.877129152999942, 35.172611601000028],
            [-80.877132074999963, 35.172628362000069],
            [-80.877141092999977, 35.172671592000029],
            [-80.87728590599994, 35.173291578000033],
            [-80.877287299999978, 35.173297459000025],
            [-80.877329867999947, 35.173474513000031],
            [-80.877332223999986, 35.173484092000024],
            [-80.877576307999959, 35.174454076000075],
            [-80.877576570999963, 35.174455121000051],
            [-80.877621559999966, 35.174633036000046],
            [-80.87774219399995, 35.175129054000024],
            [-80.877878935999945, 35.175714153000058],
            [-80.877880019999964, 35.175718737000068],
            [-80.878019055999971, 35.176300358000049],
            [-80.878158715999973, 35.176896395000028],
            [-80.878159058999984, 35.176897852000025],
            [-80.878214139999955, 35.177131234000058],
            [-80.878236916999981, 35.177256294000074],
            [-80.878256870999962, 35.177390010000067],
            [-80.878272467999977, 35.177525861000049],
            [-80.878279202999977, 35.17760415500004],
            [-80.878313053999989, 35.17807242400005],
            [-80.878338897999981, 35.178528736000032],
            [-80.878338520999989, 35.178731847000051],
            [-80.878335842999945, 35.178921570000057],
            [-80.878325422999978, 35.179051712000046],
            [-80.878308064999942, 35.179176157000029],
            [-80.87830775499998, 35.179178402000048],
            [-80.878302862999988, 35.179221812000037],
            [-80.878296002999946, 35.179299323000066],
            [-80.878293918999987, 35.179328028000043],
            [-80.878293310999936, 35.179340180000054],
            [-80.878280751999966, 35.179630309000061],
            [-80.878280168999936, 35.179647568000064],
            [-80.878273017999959, 35.179942422000067],
            [-80.878272790999972, 35.179973032000078],
            [-80.878277272999981, 35.180445878000057],
            [-80.878277308999941, 35.180449064000072],
            [-80.87828022299999, 35.180673415000058],
            [-80.878277221999952, 35.180743847000031],
            [-80.87826991999998, 35.18082141900004],
            [-80.878257896999969, 35.180910630000028],
            [-80.878253599999937, 35.18093755700005],
            [-80.878253258999962, 35.180939024000054],
            [-80.878246765999961, 35.180959405000067],
            [-80.878233528999942, 35.180998303000024],
            [-80.878118692999976, 35.181373309000037],
            [-80.877987197999971, 35.18178522900007],
            [-80.87798455099994, 35.181793655000035],
            [-80.87785527799997, 35.182212012000036],
            [-80.87785345399999, 35.182217983000044],
            [-80.877852294999968, 35.182221857000059],
            [-80.877747970999962, 35.182572954000079],
            [-80.87773799699994, 35.182609153000044],
            [-80.87762474699997, 35.183054756000047],
            [-80.877541397999948, 35.183339502000024],
            [-80.87747174499998, 35.183562330000029],
            [-80.877469004999966, 35.183571249000067],
            [-80.877466849999962, 35.183578493000027],
            [-80.877426496999988, 35.183716177000065],
            [-80.877310601999966, 35.184106046000068],
            [-80.877228740999954, 35.184339349000027],
            [-80.877220030999979, 35.184365359000026],
            [-80.87719229399994, 35.184452333000024],
            [-80.87708669899996, 35.184688966000067],
            [-80.876966720999974, 35.184956090000071],
            [-80.876958896999952, 35.184973979000063],
            [-80.87689617999996, 35.185121375000051],
            [-80.876834801999962, 35.185254802000031],
            [-80.876797137999972, 35.185330830000055],
            [-80.876677418999975, 35.185565569000062],
            [-80.876514946999976, 35.185875630000055],
            [-80.876474258999963, 35.185951120000027],
            [-80.876271378999945, 35.186309885000071],
            [-80.876261632999956, 35.186327519000031],
            [-80.87614819099997, 35.186537685000076],
            [-80.87612571599999, 35.186581885000066],
            [-80.876096239999981, 35.186650060000034],
            [-80.876072257999965, 35.186719671000048],
            [-80.876053874999968, 35.186790420000079],
            [-80.876041165999936, 35.186862006000069],
            [-80.876034186999959, 35.186934120000046],
            [-80.876032968999937, 35.187006454000027],
            [-80.87603751599994, 35.187078700000029],
            [-80.876047808999942, 35.187150546000055],
            [-80.876063803999955, 35.187221687000033],
            [-80.876085430999979, 35.187291815000037],
            [-80.876112599999942, 35.187360633000026],
            [-80.876145192999957, 35.187427844000069],
            [-80.876183070999957, 35.187493161000077],
            [-80.876226071999952, 35.187556305000044],
            [-80.876274009999975, 35.18761700400006],
            [-80.876326681999956, 35.18767500000007],
            [-80.876383861999955, 35.187730044000034],
            [-80.876445305999937, 35.187781899000072],
            [-80.876510747999987, 35.187830344000076],
            [-80.876579909999975, 35.187875172000076],
            [-80.876652495999963, 35.187916190000067],
            [-80.876728192999963, 35.187953223000079],
            [-80.876806679999959, 35.187986113000079],
            [-80.876887617999955, 35.188014718000034],
            [-80.876970661999962, 35.188038916000039],
            [-80.877055455999937, 35.188058602000069],
            [-80.877141636999966, 35.188073694000025],
            [-80.877180880999958, 35.188078982000036],
            [-80.877805572999989, 35.188155390000077],
            [-80.877809174999982, 35.18815582600007],
            [-80.878332846999967, 35.188218688000063],
            [-80.878334757999937, 35.188218916000039],
            [-80.878848190999975, 35.188279930000078],
            [-80.879330318999962, 35.188337992000072],
            [-80.879341800999953, 35.188339334000034],
            [-80.879594011999984, 35.188367887000027],
            [-80.879600746999984, 35.188369010000031],
            [-80.87969592799999, 35.188383333000047],
            [-80.879701051999973, 35.18838409500006],
            [-80.879770296999936, 35.188392768000028],
            [-80.879866488999937, 35.188402722000035],
            [-80.879884444999959, 35.188404480000031],
            [-80.879940800999975, 35.188408700000025],
            [-80.87999135299998, 35.188411607000035],
            [-80.880025430999979, 35.188413812000078],
            [-80.880068666999989, 35.188416037000025],
            [-80.880110096999942, 35.18841709600008],
            [-80.880206648999945, 35.188418343000023],
            [-80.88025332999996, 35.188418280000064],
            [-80.880274752999981, 35.188417808000054],
            [-80.880379851999976, 35.188414799000043],
            [-80.880446432999975, 35.188411536000046],
            [-80.880448170999955, 35.188411415000076],
            [-80.880552335999937, 35.188404069000057],
            [-80.880616606999979, 35.188398258000063],
            [-80.880720395999958, 35.188386797000078],
            [-80.88074190399999, 35.188384276000079],
            [-80.880792016999976, 35.188377268000067],
            [-80.880894864999959, 35.188361241000052],
            [-80.880931405999945, 35.188355114000046],
            [-80.880958801999952, 35.188349949000042],
            [-80.881060718999947, 35.188329814000042],
            [-80.881118747999949, 35.188317219000055],
            [-80.88112452699994, 35.18831584000003],
            [-80.881225232999952, 35.188291599000024],
            [-80.881296318999944, 35.188272715000039],
            [-80.881405219999976, 35.188241018000042],
            [-80.881412180999973, 35.188238975000047],
            [-80.881474303999937, 35.188219138000079],
            [-80.881576432999964, 35.18818411400008],
            [-80.881596179999974, 35.188177189000044],
            [-80.881626564999976, 35.188165928000046],
            [-80.881726941999943, 35.18812749500006],
            [-80.881776117999948, 35.188107656000057],
            [-80.881794750999973, 35.188099597000075],
            [-80.881892233999963, 35.188056628000027],
            [-80.88194102999995, 35.188034034000054],
            [-80.882036480999943, 35.187987662000069],
            [-80.882045965999964, 35.187983011000028],
            [-80.88209309399997, 35.187958578000064],
            [-80.882185665999941, 35.187908357000026],
            [-80.882212473999971, 35.187893430000031],
            [-80.882238947999952, 35.187877911000044],
            [-80.882315094999967, 35.187832125000057],
            [-80.882593270999962, 35.187673112000027],
            [-80.883020502999955, 35.187436757000057],
            [-80.883026117999975, 35.187433634000058],
            [-80.883390475999988, 35.187229861000048],
            [-80.883391383999935, 35.187229353000077],
            [-80.884144407999941, 35.186807466000062],
            [-80.884653635999939, 35.186523574000034],
            [-80.884849483999972, 35.186418788000026],
            [-80.885181684999964, 35.18624150800008],
            [-80.885217031999957, 35.186221985000032],
            [-80.885224411999957, 35.186217738000039],
            [-80.885665388999939, 35.185962152000059],
            [-80.885681355999964, 35.185952753000038],
            [-80.885874514999955, 35.185837295000056],
            [-80.885889482999971, 35.18582821800004],
            [-80.886136191999981, 35.185676424000064],
            [-80.886168516999987, 35.185655899000039],
            [-80.886189123999941, 35.185642124000026],
            [-80.886547614999984, 35.185397613000077],
            [-80.886632619999943, 35.185348094000062],
            [-80.886884092999935, 35.185482514000057],
            [-80.887126035999984, 35.185630856000046],
            [-80.887190478999969, 35.185670728000048],
            [-80.887201649999952, 35.185677565000049],
            [-80.887214714999971, 35.185685377000027],
            [-80.887347958999953, 35.185764048000067],
            [-80.887656867999965, 35.185947609000038],
            [-80.887672259999988, 35.18595662000007],
            [-80.88800062699994, 35.186146031000078],
            [-80.888336385999935, 35.18634452200007],
            [-80.888633895999988, 35.186523386000033],
            [-80.888638234999974, 35.186525983000024],
            [-80.889211809999949, 35.18686793300003],
            [-80.889576356999953, 35.187085814000056],
            [-80.889581914999951, 35.187089118000074],
            [-80.889746853999952, 35.187186644000064],
            [-80.890101622999964, 35.187397296000029],
            [-80.890240716999983, 35.187484287000075],
            [-80.890278477999971, 35.187511769000025],
            [-80.890349963999938, 35.187638954000079],
            [-80.890565915999957, 35.188038955000025],
            [-80.890568916999939, 35.188044474000037],
            [-80.890734375999955, 35.188346477000039],
            [-80.890741324999965, 35.188358953000034],
            [-80.890871645999937, 35.188589139000044],
            [-80.890877431999968, 35.188599224000029],
            [-80.891033465999953, 35.188867709000078],
            [-80.891052662999982, 35.188899424000056],
            [-80.891063559999964, 35.188916364000079],
            [-80.891108675999988, 35.188985050000042],
            [-80.891140790999941, 35.189031248000049],
            [-80.891152294999983, 35.189046624000071],
            [-80.891202647999989, 35.189112708000039],
            [-80.891237027999978, 35.18915558100008],
            [-80.891290958999946, 35.189219550000075],
            [-80.891293025999971, 35.189221995000025],
            [-80.89134043699994, 35.189274524000041],
            [-80.891399327999977, 35.189335893000077],
            [-80.891404600999977, 35.189341353000032],
            [-80.891443547999984, 35.189379614000075],
            [-80.891483240999946, 35.189416867000034],
            [-80.891695801999958, 35.189628922000054],
            [-80.891829800999972, 35.189766782000049],
            [-80.891835584999967, 35.189772692000076],
            [-80.89196786499997, 35.189906886000074],
            [-80.891969812999946, 35.189908858000024],
            [-80.892249465999953, 35.190191227000071],
            [-80.892501312999968, 35.190446593000047],
            [-80.892512696999972, 35.190484188000028],
            [-80.892532574999962, 35.190552317000027],
            [-80.892536718999963, 35.190566126000078],
            [-80.892622399999937, 35.190843954000059],
            [-80.89262467399999, 35.190851226000063],
            [-80.892686986999934, 35.191047664000052],
            [-80.892696292999972, 35.191075506000061],
            [-80.892699342999947, 35.191084054000044],
            [-80.892820235999977, 35.191417931000046],
            [-80.89285203999998, 35.191507007000041],
            [-80.892853975999969, 35.191512380000063],
            [-80.892898903999935, 35.191635890000043],
            [-80.892673454999965, 35.191680026000029],
            [-80.892435005999971, 35.191724911000051],
            [-80.892402037999943, 35.191731476000029],
            [-80.89219243399998, 35.191775521000068],
            [-80.892153108999935, 35.191784307000034],
            [-80.892117423999935, 35.191793200000063],
            [-80.892052260999947, 35.191810249000071],
            [-80.892004118999978, 35.191823673000044],
            [-80.891978542999937, 35.191831490000027],
            [-80.891908196999964, 35.191853661000039],
            [-80.891899733999935, 35.191856355000027],
            [-80.891818874999956, 35.191882354000029],
            [-80.891771043999938, 35.191898611000056],
            [-80.891761254999949, 35.191902158000062],
            [-80.891667201999951, 35.191936609000038],
            [-80.89159742999999, 35.191964168000027],
            [-80.891595543999983, 35.191964970000072],
            [-80.891510736999976, 35.192001106000077],
            [-80.891468112999974, 35.192020075000073],
            [-80.891376752999975, 35.192062498000041],
            [-80.89134434999994, 35.192078038000034],
            [-80.891335484999956, 35.192082465000055],
            [-80.891250553999953, 35.192125245000057],
            [-80.891185483999948, 35.192160182000066],
            [-80.891184690999978, 35.19216063500005],
            [-80.891123636999964, 35.192195576000074],
            [-80.891049478999946, 35.192236184000024],
            [-80.891027398999938, 35.192248535000033],
            [-80.890991582999959, 35.192269725000074],
            [-80.890909060999945, 35.192320255000027],
            [-80.890874235999945, 35.19234231400003],
            [-80.890868789999956, 35.192345901000067],
            [-80.89082592699998, 35.19237428100007],
            [-80.89077199999997, 35.192411949000075],
            [-80.890685597999948, 35.192475592000051],
            [-80.890677926999956, 35.192481286000032],
            [-80.890614766999988, 35.192531736000035],
            [-80.890578913999946, 35.192563533000055],
            [-80.890502022999954, 35.192634471000076],
            [-80.890404915999966, 35.192722693000064],
            [-80.890374913999949, 35.192750896000064],
            [-80.890361201999951, 35.192764453000052],
            [-80.890195443999971, 35.19293102000006],
            [-80.890179099999955, 35.192947774000061],
            [-80.889841463999971, 35.19330082700003],
            [-80.889516804999971, 35.193634237000026],
            [-80.889510044999952, 35.193641236000076],
            [-80.889233193999985, 35.193930302000069],
            [-80.888507906999962, 35.194664928000066],
            [-80.887744299999952, 35.195432062000066],
            [-80.887731718999987, 35.195444895000037],
            [-80.887107448999984, 35.196091463000073],
            [-80.886773115999972, 35.196436475000041],
            [-80.886267687999975, 35.196955756000079],
            [-80.886236020999945, 35.19698961000006],
            [-80.886221632999934, 35.197005933000071],
            [-80.886150168999961, 35.197088578000034],
            [-80.886115659999973, 35.197130485000059],
            [-80.886048995999943, 35.197215573000051],
            [-80.886047929999961, 35.197216935000029],
            [-80.886016809999944, 35.197258802000079],
            [-80.885954946999959, 35.197346334000031],
            [-80.885940959999971, 35.197366615000078],
            [-80.885938052999961, 35.197370957000032],
            [-80.885913248999941, 35.197408216000042],
            [-80.88590357999999, 35.197423006000065],
            [-80.885824137999975, 35.197546767000063],
            [-80.885806701999968, 35.197574896000049],
            [-80.885731760999988, 35.19770019200007],
            [-80.885721712999953, 35.197717366000063],
            [-80.885712443999978, 35.197733915000072],
            [-80.885642575999952, 35.197861420000038],
            [-80.885627277999959, 35.197890441000027],
            [-80.885561908999989, 35.198019482000063],
            [-80.885551634999956, 35.198040359000061],
            [-80.885545873999945, 35.198052631000053],
            [-80.885485569999958, 35.198183421000067],
            [-80.885478535999937, 35.198199031000058],
            [-80.885464221999939, 35.198231546000045],
            [-80.885447751999948, 35.198271306000038],
            [-80.885336102999986, 35.198558629000047],
            [-80.885335894999969, 35.198559163000027],
            [-80.885327226999948, 35.19858238900008],
            [-80.885304423999969, 35.198645975000034],
            [-80.884860630999981, 35.198809119000032],
            [-80.884395280999968, 35.198977845000059],
            [-80.883714540999961, 35.199223848000031],
            [-80.883709360999944, 35.19922573100007],
            [-80.883102607999945, 35.199447497000051],
            [-80.882495982999956, 35.199660438000024],
            [-80.882486644999972, 35.19966375100006],
            [-80.882469863999972, 35.19966987600003],
            [-80.882237771999939, 35.199756165000053],
            [-80.882174979999945, 35.199781139000038],
            [-80.88216317399997, 35.19978621000007],
            [-80.882112900999971, 35.199808061000056],
            [-80.882047782999962, 35.199838296000053],
            [-80.882039533999944, 35.199842411000077],
            [-80.881976098999985, 35.199874315000045],
            [-80.881910400999971, 35.199909558000058],
            [-80.881889667999985, 35.199921642000049],
            [-80.881829941999968, 35.199957152000025],
            [-80.881780022999976, 35.199988313000063],
            [-80.881757678999975, 35.200003271000071],
            [-80.88170082299996, 35.200042171000064],
            [-80.881656110999984, 35.200074158000064],
            [-80.881631058999972, 35.200093378000076],
            [-80.881577622999941, 35.200135432000025],
            [-80.881539503999988, 35.200166657000068],
            [-80.881510171999935, 35.200192476000041],
            [-80.881460438999966, 35.200237679000054],
            [-80.881430752999961, 35.200265587000047],
            [-80.881395941999983, 35.200300889000061],
            [-80.881350457999986, 35.200349003000042],
            [-80.881330658999957, 35.20037048100005],
            [-80.881296953999936, 35.200409744000069],
            [-80.88125570599999, 35.200460082000063],
            [-80.88123944199998, 35.200480409000079],
            [-80.881203708999976, 35.200528844000075],
            [-80.881166983999947, 35.200581631000034],
            [-80.881157602999963, 35.200595341000053],
            [-80.881124617999944, 35.200647598000046],
            [-80.881092127999977, 35.200702608000029],
            [-80.881085047999989, 35.200714785000059],
            [-80.881051803999981, 35.200777895000044],
            [-80.881024644999968, 35.200834199000042],
            [-80.881023042999971, 35.200837536000051],
            [-80.880993565999972, 35.200905711000075],
            [-80.880991337999944, 35.200911508000047],
            [-80.880969241999935, 35.200969563000058],
            [-80.880949189999967, 35.201027802000056],
            [-80.880933356999947, 35.201079118000052],
            [-80.880931653999937, 35.201084694000031],
            [-80.88091327099994, 35.201155444000051],
            [-80.880912791999947, 35.201157653000053],
            [-80.880900439999948, 35.201214870000058],
            [-80.880889816999968, 35.201273049000065],
            [-80.880883773999983, 35.201313450000043],
            [-80.880882170999939, 35.201324648000025],
            [-80.880879205999975, 35.201348652000036],
            [-80.880870440999956, 35.201428257000032],
            [-80.880866430999959, 35.201476367000055],
            [-80.880865035999989, 35.201538325000058],
            [-80.880870715999947, 35.202026035000074],
            [-80.880870895999976, 35.202036412000041],
            [-80.880870978999951, 35.202039733000049],
            [-80.880880704999981, 35.20240334600004],
            [-80.880880167999976, 35.202452509000068],
            [-80.88087776399999, 35.202490167000065],
            [-80.880873404999988, 35.202524607000043],
            [-80.880866519999984, 35.202561380000077],
            [-80.880854417999956, 35.202610668000034],
            [-80.88075493599996, 35.202968365000061],
            [-80.880749617999982, 35.202988335000043],
            [-80.880722756999944, 35.203093871000078],
            [-80.88071729699999, 35.203116501000068],
            [-80.880689932999985, 35.203236472000071],
            [-80.880688070999952, 35.203244810000058],
            [-80.880684456999973, 35.203262152000036],
            [-80.880663208999977, 35.203369204000069],
            [-80.88065482799999, 35.203418364000072],
            [-80.880640417999984, 35.203519353000047],
            [-80.880639706999943, 35.203524437000056],
            [-80.880639219999978, 35.203528041000027],
            [-80.880618536999975, 35.203683635000061],
            [-80.880617730999973, 35.203689869000073],
            [-80.880585833999987, 35.203943135000031],
            [-80.880518452999979, 35.204433527000049],
            [-80.880518400999961, 35.204433908000055],
            [-80.880517705999978, 35.204439087000026],
            [-80.880489472999955, 35.204654342000026],
            [-80.880483190999939, 35.204721277000033],
            [-80.880481975999942, 35.204793611000071],
            [-80.880482382999958, 35.204806980000058],
            [-80.880483811999966, 35.204844746000049],
            [-80.880487956999957, 35.204903623000064],
            [-80.880490176999956, 35.204923114000053],
            [-80.88049668299999, 35.20497522800008],
            [-80.880504760999941, 35.205027582000071],
            [-80.880513862999976, 35.205071148000059],
            [-80.88052593499998, 35.205122717000052],
            [-80.880532835999986, 35.205150290000063],
            [-80.880551025999978, 35.205210434000037],
            [-80.880568091999976, 35.20526078100005],
            [-80.88057153799997, 35.205270766000069],
            [-80.880598716999941, 35.205339582000079],
            [-80.880601260999981, 35.205345322000028],
            [-80.880623327999956, 35.205394674000047],
            [-80.880653386999938, 35.205456144000038],
            [-80.880670076999934, 35.205486282000038],
            [-80.880697400999964, 35.205533722000041],
            [-80.880718599999966, 35.20556889900007],
            [-80.880722141999968, 35.205574484000067],
            [-80.880724459999954, 35.205578113000058],
            [-80.880761523999979, 35.205632380000054],
            [-80.880796676999978, 35.205680614000073],
            [-80.880799083999989, 35.205683902000033],
            [-80.880847035999977, 35.20574460000006],
            [-80.880897437999977, 35.205800236000073],
            [-80.880928941999969, 35.205832825000073],
            [-80.880969174999962, 35.205875740000067],
            [-80.880983040999979, 35.205890278000027],
            [-80.88101544999995, 35.205923678000033],
            [-80.881020596999974, 35.205929618000027],
            [-80.881056244999968, 35.205976879000048],
            [-80.881059276999963, 35.20599203200004],
            [-80.881127540999955, 35.20634601900008],
            [-80.881167131999973, 35.206567358000029],
            [-80.881167668999979, 35.206570334000048],
            [-80.881275846999984, 35.207163856000079],
            [-80.881317134999961, 35.207397723000042],
            [-80.881318308999937, 35.207404236000059],
            [-80.881326128999945, 35.207442244000049],
            [-80.881339479999951, 35.207500891000052],
            [-80.881347663999975, 35.207534023000051],
            [-80.881357289999983, 35.20756762700006],
            [-80.881370567999966, 35.207610935000048],
            [-80.881382579999979, 35.207647458000054],
            [-80.881386566999936, 35.207658587000026],
            [-80.88139937599999, 35.207693655000071],
            [-80.881416625999975, 35.207737536000025],
            [-80.881435223999972, 35.207781674000046],
            [-80.881441165999945, 35.207795480000073],
            [-80.881451710999954, 35.207818653000061],
            [-80.88147225199998, 35.207862304000059],
            [-80.881494313999951, 35.207906341000069],
            [-80.881502031999958, 35.207920576000049],
            [-80.881525618999945, 35.207963266000036],
            [-80.881555792999961, 35.208014346000027],
            [-80.881556357999955, 35.208015242000045],
            [-80.881606462999969, 35.208094616000039],
            [-80.88163842199998, 35.208142330000044],
            [-80.881728412999962, 35.208269179000069],
            [-80.881738241999983, 35.208282809000025],
            [-80.881821148999961, 35.208395912000071],
            [-80.881902902999968, 35.208514057000059],
            [-80.881906829999934, 35.208544749000055],
            [-80.881957449999959, 35.20887580200008],
            [-80.881961604999958, 35.208900680000056],
            [-80.88199283199998, 35.209073102000048],
            [-80.881939473999978, 35.209098866000033],
            [-80.881934107999939, 35.209101471000054],
            [-80.881774623999945, 35.209179297000048],
            [-80.881767535999984, 35.209182780000049],
            [-80.881763440999976, 35.209184814000025],
            [-80.88168951199998, 35.209221691000039],
            [-80.881638757999951, 35.209248301000059],
            [-80.881559125999956, 35.20929213800008],
            [-80.881540019999989, 35.209302852000064],
            [-80.881500820999975, 35.209326111000053],
            [-80.88142378699996, 35.209373569000036],
            [-80.881392324999979, 35.209393555000077],
            [-80.881363219999969, 35.209413167000037],
            [-80.881289890999938, 35.20946400400004],
            [-80.881251932999987, 35.209491335000052],
            [-80.881233913999949, 35.209505057000058],
            [-80.881164010999953, 35.209559275000061],
            [-80.88111885099994, 35.209595997000065],
            [-80.881113090999975, 35.209600938000051],
            [-80.881047153999987, 35.209657844000048],
            [-80.880997197999989, 35.209703426000033],
            [-80.880934963999948, 35.209763480000049],
            [-80.880931654999983, 35.209766684000044],
            [-80.880887859999973, 35.209811591000062],
            [-80.880829874999961, 35.209874326000033],
            [-80.880819053999971, 35.209886200000028],
            [-80.880787440999939, 35.209922911000035],
            [-80.880733696999982, 35.209988099000043],
            [-80.880715333999945, 35.210010978000071],
            [-80.88069457499995, 35.210038427000029],
            [-80.880645071999936, 35.210106070000052],
            [-80.880620711999939, 35.210140765000062],
            [-80.880618943999934, 35.210143401000039],
            [-80.880522377999966, 35.210287808000032],
            [-80.880488252999953, 35.210342378000064],
            [-80.880395380999971, 35.21050161200003],
            [-80.880391203999977, 35.210508840000045],
            [-80.880388591999974, 35.210513430000049],
            [-80.880381366999984, 35.21052620200004],
            [-80.880213573999981, 35.210801390000029],
            [-80.880199007999977, 35.210826037000061],
            [-80.880190310999978, 35.210841537000078],
            [-80.880116808999958, 35.210975262000034],
            [-80.880109722999975, 35.210985820000076],
            [-80.880092762999936, 35.211008204000052],
            [-80.880071923999935, 35.211032733000025],
            [-80.880043297999975, 35.211063304000049],
            [-80.88002735799995, 35.211080679000077],
            [-80.880014138999968, 35.211095647000036],
            [-80.880004140999972, 35.211107169000059],
            [-80.879970628999956, 35.21113108000003],
            [-80.879845915999965, 35.211224095000034],
            [-80.879585006999946, 35.211406886000077],
            [-80.879571055999975, 35.211416797000027],
            [-80.879307826999934, 35.211606388000064],
            [-80.879289171999972, 35.211620077000077],
            [-80.879257006999978, 35.211644931000023],
            [-80.879096713999957, 35.21177292200008],
            [-80.879065697999977, 35.21179851200003],
            [-80.879006669999967, 35.211852237000073],
            [-80.879000021999957, 35.211858752000069],
            [-80.878936178999936, 35.211921806000078],
            [-80.878895807999982, 35.211963698000034],
            [-80.878835108999965, 35.212029910000069],
            [-80.878827508999962, 35.212038283000027],
            [-80.878793209999969, 35.212078262000034],
            [-80.878737030999957, 35.212146923000034],
            [-80.878721350999967, 35.212166534000062],
            [-80.878702178999959, 35.212191818000065],
            [-80.878649688999985, 35.212263169000039],
            [-80.878623738999977, 35.212300029000062],
            [-80.878622951999944, 35.212301213000046],
            [-80.878584858999943, 35.212354150000067],
            [-80.878565629999969, 35.212381773000061],
            [-80.878550028999939, 35.212405637000074],
            [-80.878504523999936, 35.212477340000078],
            [-80.878480051999986, 35.212517909000042],
            [-80.878472445999989, 35.21253143000007],
            [-80.878431728999942, 35.212605117000066],
            [-80.878404956999987, 35.212657046000061],
            [-80.878378255999962, 35.212712759000055],
            [-80.878253787999938, 35.212949839000032],
            [-80.878239999999948, 35.212945270000034],
            [-80.87818557899999, 35.212926334000031],
            [-80.878134533999969, 35.212907681000047],
            [-80.878080828999941, 35.212886921000063],
            [-80.878027037999971, 35.212865471000043],
            [-80.877973981999958, 35.212843153000051],
            [-80.87792008699995, 35.212819812000077],
            [-80.877868940999974, 35.212796568000044],
            [-80.877819461999934, 35.212773301000027],
            [-80.877770242999986, 35.212748886000043],
            [-80.877719608999939, 35.212723013000073],
            [-80.877671144999965, 35.212697091000052],
            [-80.87762134899998, 35.212669571000049],
            [-80.877573989999973, 35.212642384000048],
            [-80.877526730999989, 35.212614146000078],
            [-80.877479605999952, 35.212585005000051],
            [-80.877434181999945, 35.212555877000057],
            [-80.877386738999974, 35.21252424000005],
            [-80.877341023999975, 35.212492990000044],
            [-80.877298368999959, 35.212462523000056],
            [-80.877255341999955, 35.212430741000048],
            [-80.877211663999958, 35.21239720300008],
            [-80.877169974999958, 35.212364259000026],
            [-80.877127923999979, 35.212329564000072],
            [-80.877085389999934, 35.212293613000043],
            [-80.877046923999956, 35.212259826000036],
            [-80.877006991999963, 35.212223375000065],
            [-80.876969928999983, 35.212188552000043],
            [-80.876933109999982, 35.212152210000056],
            [-80.876893788999951, 35.212112425000043],
            [-80.876858126999934, 35.21207505600006],
            [-80.876822039999979, 35.212035728000046],
            [-80.87678680099998, 35.211996170000077],
            [-80.876756226999987, 35.211960392000037],
            [-80.876722792999942, 35.211919231000024],
            [-80.876689054999986, 35.211876935000078],
            [-80.876657500999954, 35.211835368000038],
            [-80.876629310999988, 35.211796679000031],
            [-80.876599685999963, 35.211754574000054],
            [-80.876570751999964, 35.211711646000026],
            [-80.876542559999962, 35.211668282000062],
            [-80.876515388999962, 35.211624592000078],
            [-80.876490364999938, 35.211582800000031],
            [-80.876466093999966, 35.211539826000035],
            [-80.876441410999973, 35.211494493000032],
            [-80.876418932999968, 35.211451093000051],
            [-80.876396897999939, 35.21140629100006],
            [-80.876375836999955, 35.21136137600007],
            [-80.876355979999971, 35.211316476000036],
            [-80.876336422999941, 35.211269937000054],
            [-80.876318811999965, 35.211225563000028],
            [-80.876301274999946, 35.211178097000072],
            [-80.876284343999941, 35.211130277000052],
            [-80.876269641999954, 35.211085321000041],
            [-80.876256891999958, 35.211043453000059],
            [-80.876225085999977, 35.21092578400004],
            [-80.876210042999958, 35.210875685000076],
            [-80.876177348999988, 35.210776800000076],
            [-80.876171638999949, 35.210760056000026],
            [-80.876171067999962, 35.210758433000024],
            [-80.876136143999986, 35.210659584000041],
            [-80.87612769499998, 35.210636606000037],
            [-80.876090280999961, 35.210538709000048],
            [-80.876085018999959, 35.210525244000053],
            [-80.87604593399999, 35.210427375000052],
            [-80.876037423999946, 35.210406754000076],
            [-80.875996124999972, 35.210309834000043],
            [-80.87599174099995, 35.210299704000079],
            [-80.875987773999952, 35.210290796000038],
            [-80.875944255999968, 35.210194370000067],
            [-80.875936139999965, 35.210176844000046],
            [-80.875890680999987, 35.210081133000074],
            [-80.875883678999969, 35.210066691000065],
            [-80.875836561999961, 35.20997146600007],
            [-80.875825145999954, 35.209949100000074],
            [-80.875775537999971, 35.209854828000061],
            [-80.875773438999943, 35.209850859000028],
            [-80.875766769999984, 35.209838527000045],
            [-80.875715219999961, 35.20974474500008],
            [-80.875706829999956, 35.209729781000078],
            [-80.875653622999948, 35.209636709000051],
            [-80.87564077899998, 35.209614868000074],
            [-80.875585082999976, 35.209522753000044],
            [-80.875576007999939, 35.209508021000033],
            [-80.875518653999961, 35.209416618000034],
            [-80.875517743999978, 35.209415170000057],
            [-80.875507407999976, 35.20939908500003],
            [-80.875448123999945, 35.209308626000052],
            [-80.875437156999965, 35.209292226000059],
            [-80.875375936999944, 35.209202486000038],
            [-80.875365929999987, 35.209188072000074],
            [-80.875342565999972, 35.209154999000077],
            [-80.875330863999977, 35.209138755000026],
            [-80.875282912999978, 35.209078055000077],
            [-80.875230226999975, 35.209020059000068],
            [-80.875173032999953, 35.208965015000047],
            [-80.875111575999938, 35.208913160000066],
            [-80.875046116999954, 35.208864713000025],
            [-80.874976937999975, 35.208819885000025],
            [-80.87490433499994, 35.208778865000056],
            [-80.87482861899997, 35.208741832000044],
            [-80.874750112999948, 35.208708941000054],
            [-80.874669154999935, 35.208680335000054],
            [-80.874586090999969, 35.208656135000069],
            [-80.87450127599999, 35.20863644700006],
            [-80.874415072999966, 35.208621353000069],
            [-80.874327852999954, 35.20861091900008],
            [-80.874239986999953, 35.208605188000035],
            [-80.874151852999944, 35.208604187000049],
            [-80.874063828999965, 35.208607918000041],
            [-80.873976289999973, 35.208616367000047],
            [-80.873889610999981, 35.208629496000071],
            [-80.873804163999978, 35.20864724900008],
            [-80.873720314999957, 35.20866955200006],
            [-80.873638422999989, 35.208696308000071],
            [-80.873558838999941, 35.208727402000079],
            [-80.87348190199998, 35.208762701000069],
            [-80.873407942999961, 35.208802055000035],
            [-80.873337277999951, 35.208845294000071],
            [-80.873270209999987, 35.208892234000075],
            [-80.873207026999978, 35.20894267400007],
            [-80.873154155999941, 35.208990450000044],
            [-80.872490806999963, 35.209626240000034],
            [-80.872145780999972, 35.209955947000026],
            [-80.87214342499999, 35.209958205000078],
            [-80.871582107999984, 35.210497518000068],
            [-80.871577017999982, 35.21050243600007],
            [-80.871564387999967, 35.210514900000078],
            [-80.871351905999973, 35.210727735000034],
            [-80.871347555999989, 35.210732115000042],
            [-80.870909170999937, 35.211175829000069],
            [-80.869842725999945, 35.212170769000068],
            [-80.869824813999969, 35.212187827000037],
            [-80.869817732999934, 35.212194768000074],
            [-80.869793716999936, 35.212218509000024],
            [-80.869723522999948, 35.212193704000072],
            [-80.869698710999955, 35.212185946000034],
            [-80.869640542999946, 35.212168295000026],
            [-80.869582287999947, 35.212151850000055],
            [-80.86953888499994, 35.212141159000055],
            [-80.869478569999956, 35.212127204000069],
            [-80.869437155999947, 35.212118203000045],
            [-80.869361715999958, 35.212104729000032],
            [-80.869300947999989, 35.212095362000071],
            [-80.869290181999986, 35.212093740000057],
            [-80.869202958999949, 35.212083302000053],
            [-80.869189018999975, 35.212082075000069],
            [-80.869126672999982, 35.212076855000078],
            [-80.869052743999987, 35.212072348000049],
            [-80.869020814999942, 35.212071436000031],
            [-80.868761425999935, 35.212066560000039],
            [-80.868227884999953, 35.212055644000031],
            [-80.867815630999985, 35.212046784000052],
            [-80.867378412999983, 35.212032867000062],
            [-80.867353272999935, 35.212032259000068],
            [-80.866844098999934, 35.212023858000066],
            [-80.866791692999982, 35.212023830000078],
            [-80.866703664999989, 35.212027557000056],
            [-80.866616120999936, 35.212036000000069],
            [-80.86652943699994, 35.212049124000032],
            [-80.866443985999979, 35.212066872000037],
            [-80.866360130999965, 35.21208917000007],
            [-80.866278232999946, 35.212115921000077],
            [-80.866198642999962, 35.212147010000024],
            [-80.866121698999962, 35.212182305000056],
            [-80.866047733999949, 35.212221653000029],
            [-80.865977061999956, 35.21226488800005],
            [-80.865909986999952, 35.212311824000039],
            [-80.865846795999971, 35.212362260000077],
            [-80.865787758999943, 35.21241597900007],
            [-80.865733129999967, 35.212472752000053],
            [-80.865683142999956, 35.212532337000027],
            [-80.865638010999987, 35.212594476000049],
            [-80.865597926999953, 35.212658905000069],
            [-80.865563062999968, 35.21272534700006],
            [-80.865533568999979, 35.212793519000058],
            [-80.865509570999961, 35.212863127000048],
            [-80.865491171999963, 35.212933875000033],
            [-80.865478449999955, 35.213005459000044],
            [-80.865471988999957, 35.213068999000029],
            [-80.865459591999979, 35.213255738000043],
            [-80.865459351999959, 35.213257679000037],
            [-80.865457204999984, 35.213268978000031],
            [-80.865456408999989, 35.213272042000028],
            [-80.865455181999948, 35.213275563000025],
            [-80.86545246899999, 35.213281993000066],
            [-80.865451169999972, 35.213284542000054],
            [-80.865445532999956, 35.213293934000035],
            [-80.865340298999968, 35.213449324000067],
            [-80.865338687999952, 35.213451710000072],
            [-80.865326675999938, 35.213469952000025],
            [-80.865178309999976, 35.213700417000041],
            [-80.865152316999968, 35.213739857000064],
            [-80.865118036999945, 35.213795572000038],
            [-80.865110079999965, 35.213809723000054],
            [-80.865083820999985, 35.213857305000033],
            [-80.865056913999979, 35.213909596000065],
            [-80.865036846999942, 35.213954387000058],
            [-80.865016209999965, 35.214003714000057],
            [-80.865006781999966, 35.214027094000073],
            [-80.864987466999935, 35.214081651000072],
            [-80.864971321999974, 35.214132053000071],
            [-80.864966637999942, 35.214147105000052],
            [-80.864948237999954, 35.214217853000036],
            [-80.864945699999964, 35.214229839000041],
            [-80.86493515799998, 35.214281302000074],
            [-80.864924973999962, 35.214340899000035],
            [-80.864921065999965, 35.214373800000033],
            [-80.864915837999945, 35.214425866000056],
            [-80.864912755999967, 35.214465078000046],
            [-80.864911295999946, 35.214513049000061],
            [-80.864911089999964, 35.214565039000036],
            [-80.864911319999976, 35.214589402000058],
            [-80.86491585899995, 35.21466164800006],
            [-80.864922511999964, 35.214712336000048],
            [-80.864931306999949, 35.214767391000066],
            [-80.864932523999983, 35.214774805000047],
            [-80.864616292999983, 35.215225273000044],
            [-80.864604472999986, 35.215242465000074],
            [-80.864586988999974, 35.215269296000031],
            [-80.864422533999971, 35.215530258000058],
            [-80.86437802599994, 35.215585396000051],
            [-80.864306105999958, 35.215668514000072],
            [-80.864296581999952, 35.215679666000028],
            [-80.864255882999942, 35.215727953000055],
            [-80.864215619999982, 35.215772068000035],
            [-80.86417268699995, 35.215814918000035],
            [-80.864110282999945, 35.215872662000038],
            [-80.864045389999944, 35.215928413000029],
            [-80.863978347999989, 35.215981893000048],
            [-80.86390830299996, 35.216033725000045],
            [-80.863861928999938, 35.216065712000045],
            [-80.863606986999969, 35.216211638000061],
            [-80.863260965999984, 35.21640592600005],
            [-80.863252344999978, 35.216410808000035],
            [-80.86325089799999, 35.216411635000043],
            [-80.862820084999953, 35.216658276000032],
            [-80.862808259999952, 35.216665123000041],
            [-80.862802002999956, 35.21666878800005],
            [-80.862183665999964, 35.217013062000035],
            [-80.862182613999948, 35.217013648000034],
            [-80.861740629999986, 35.217260230000079],
            [-80.861733379999976, 35.217264303000036],
            [-80.860971889999973, 35.217695115000026],
            [-80.860967210999945, 35.217697774000044],
            [-80.860963547999972, 35.217699872000026],
            [-80.860920480999937, 35.217724632000056],
            [-80.860908689999974, 35.217731097000069],
            [-80.860506100999942, 35.217955540000048],
            [-80.860502688999986, 35.21795744800005],
            [-80.859814724999978, 35.218343517000051],
            [-80.859251536999977, 35.218657820000033],
            [-80.859239810999952, 35.218664438000076],
            [-80.859228698999971, 35.218670850000024],
            [-80.858694919999948, 35.21898215300007],
            [-80.858109249999984, 35.219317642000078],
            [-80.858040380999967, 35.219359836000024],
            [-80.858030560999964, 35.219366325000067],
            [-80.857800754999971, 35.219519630000036],
            [-80.857779926999967, 35.219533812000066],
            [-80.857477676999963, 35.219743858000072],
            [-80.857372120999969, 35.219816912000056],
            [-80.857366621999972, 35.219820738000067],
            [-80.856914277999977, 35.220137214000033],
            [-80.856301686999984, 35.220561046000057],
            [-80.856292275999976, 35.220567617000029],
            [-80.855913519999945, 35.220834578000051],
            [-80.855564611999966, 35.221079958000075],
            [-80.855398071999957, 35.221196736000024],
            [-80.855395314999953, 35.22119867400005],
            [-80.855300823999983, 35.221265294000034],
            [-80.854891286999987, 35.221551220000038],
            [-80.854808097999978, 35.221608724000077],
            [-80.85480604299994, 35.221610147000035],
            [-80.854164254999944, 35.222055569000077],
            [-80.854161790999967, 35.222057283000026],
            [-80.853337694999937, 35.222632008000062],
            [-80.852950857999986, 35.222895914000048],
            [-80.852875553999979, 35.222946517000025],
            [-80.852840838999953, 35.222970660000044],
            [-80.852586481999936, 35.223153703000037],
            [-80.852576366999983, 35.223153982000042],
            [-80.852482678999934, 35.223157707000041],
            [-80.852434696999978, 35.223160320000034],
            [-80.852406661999964, 35.223162502000037],
            [-80.852313353999989, 35.223170572000072],
            [-80.852253831999974, 35.223176823000074],
            [-80.85223752099995, 35.223178924000024],
            [-80.852144865999946, 35.223191335000024],
            [-80.852074478999953, 35.223202348000029],
            [-80.852069274999963, 35.223203289000026],
            [-80.851977556999941, 35.223220037000033],
            [-80.851904090999938, 35.223235243000033],
            [-80.851814141999967, 35.22325608500006],
            [-80.851807344999941, 35.22325767600006],
            [-80.851747110999952, 35.223273176000077],
            [-80.851651432999972, 35.223299833000056],
            [-80.851627796999935, 35.223306620000074],
            [-80.85157706199999, 35.223322580000058],
            [-80.851487874999975, 35.223352342000055],
            [-80.851456695999957, 35.22336312300007],
            [-80.85142067299995, 35.223376537000036],
            [-80.851333805999957, 35.223410158000036],
            [-80.851290220999942, 35.223427822000076],
            [-80.851274385999943, 35.223434644000065],
            [-80.851189273999978, 35.223471903000075],
            [-80.851128147999987, 35.223500367000042],
            [-80.851116274999981, 35.223506305000058],
            [-80.851034603999949, 35.223547631000031],
            [-80.850978998999949, 35.223577343000045],
            [-80.850899638999977, 35.223622070000033],
            [-80.850893133999989, 35.223625758000026],
            [-80.850837769999941, 35.223659105000024],
            [-80.850761286999955, 35.223707681000064],
            [-80.850745960999973, 35.223717560000068],
            [-80.850701986999979, 35.223747609000043],
            [-80.850629483999967, 35.223799331000066],
            [-80.850606364999976, 35.223816209000063],
            [-80.85059586899996, 35.223824135000029],
            [-80.850556367999957, 35.223854281000058],
            [-80.850503654999955, 35.223896784000033],
            [-80.850485453999966, 35.223912595000058],
            [-80.85041733199995, 35.223972950000075],
            [-80.850376477999987, 35.22401085100006],
            [-80.850375072999952, 35.224012219000031],
            [-80.850310631999946, 35.224075038000024],
            [-80.850265612999976, 35.224121392000029],
            [-80.850205423999967, 35.22418689400007],
            [-80.850197200999958, 35.224195938000037],
            [-80.850163566999981, 35.224235074000035],
            [-80.850107623999975, 35.224303261000045],
            [-80.850091250999981, 35.224323702000049],
            [-80.850070234999976, 35.224351477000027],
            [-80.850018534999947, 35.224422116000028],
            [-80.849994400999947, 35.224456475000068],
            [-80.849981596999953, 35.224475938000069],
            [-80.849934962999953, 35.224548560000073],
            [-80.849907664999989, 35.224593520000042],
            [-80.84990367599994, 35.224600545000044],
            [-80.849861827999973, 35.22467492800007],
            [-80.849837216999958, 35.224721448000025],
            [-80.849799320999978, 35.224797829000067],
            [-80.849793038999962, 35.224810723000076],
            [-80.849774108999952, 35.224852783000074],
            [-80.84975318499994, 35.224902341000075],
            [-80.849742602999982, 35.22492844900006],
            [-80.849728628999969, 35.224966805000065],
            [-80.849677609999958, 35.22511667200007],
            [-80.849667568999962, 35.22514792100003],
            [-80.849663456999963, 35.225161910000054],
            [-80.849652216999971, 35.225201279000032],
            [-80.849649985999974, 35.225207455000032],
            [-80.849647164999965, 35.22521379300008],
            [-80.849643456999956, 35.225220852000064],
            [-80.849640217999934, 35.225226113000076],
            [-80.849638421999941, 35.225228645000072],
            [-80.849631928999941, 35.225236566000035],
            [-80.849627217999966, 35.225241723000067],
            [-80.849621817999946, 35.225246866000077],
            [-80.849606057999949, 35.225260400000025],
            [-80.849592605999987, 35.225270695000063],
            [-80.849567011999966, 35.22528813200006],
            [-80.84955819299995, 35.225293718000046],
            [-80.849541461999934, 35.225304488000063],
            [-80.849525251999978, 35.225315264000074],
            [-80.849178630999972, 35.225549347000026],
            [-80.849174209999944, 35.225552346000029],
            [-80.849105773999952, 35.225598965000074],
            [-80.849096550999946, 35.225605304000055],
            [-80.848768756999959, 35.225832686000047],
            [-80.848766546999968, 35.22583422200006],
            [-80.848561128999961, 35.225977336000028],
            [-80.848427630999936, 35.226065185000039],
            [-80.848371405999956, 35.226104306000025],
            [-80.848077312999976, 35.22632055400004],
            [-80.847884000999954, 35.226450254000042],
            [-80.847829804999947, 35.226488632000041],
            [-80.847827925999979, 35.226490039000055],
            [-80.847777812999936, 35.226527619000024],
            [-80.847572327999956, 35.226669148000042],
            [-80.847478548999959, 35.226731460000053],
            [-80.847234212999979, 35.226888073000055],
            [-80.847223675999942, 35.226894897000079],
            [-80.847199128999989, 35.226911348000044],
            [-80.847025444999986, 35.227030569000078],
            [-80.847021277999943, 35.22703344100006],
            [-80.845961149999937, 35.227767118000031],
            [-80.845922764999955, 35.227794720000077],
            [-80.845859549999943, 35.22784514600005],
            [-80.845800488999942, 35.227898855000035],
            [-80.845745835999935, 35.227955619000056],
            [-80.845695823999961, 35.228015195000069],
            [-80.845650667999962, 35.228077327000051],
            [-80.845610560999944, 35.228141749000031],
            [-80.845575673999974, 35.228208185000028],
            [-80.845546157999934, 35.22827635200008],
            [-80.845522137999978, 35.228345956000055],
            [-80.845503717999975, 35.228416701000071],
            [-80.845490975999951, 35.228488283000047],
            [-80.84548396699995, 35.228560395000045],
            [-80.84548271999995, 35.228632728000036],
            [-80.84548724299998, 35.228704975000028],
            [-80.845497513999987, 35.228776823000032],
            [-80.845513489999973, 35.228847967000036],
            [-80.845535101999985, 35.228918101000033],
            [-80.845562258999962, 35.228986924000026],
            [-80.845594843999947, 35.229054144000031],
            [-80.845632716999944, 35.229119470000057],
            [-80.84567571599996, 35.229182624000032],
            [-80.845723655999961, 35.229243335000035],
            [-80.845776332999947, 35.229301344000078],
            [-80.845833521999964, 35.229356402000064],
            [-80.845894976999944, 35.229408272000057],
            [-80.845960434999938, 35.229456733000063],
            [-80.846029614999964, 35.229501578000054],
            [-80.846102222999946, 35.229542615000071],
            [-80.846177944999965, 35.229579667000053],
            [-80.84625645899996, 35.229612576000079],
            [-80.84633742799997, 35.229641201000049],
            [-80.846420504999969, 35.22966542000006],
            [-80.84650533599995, 35.229685128000028],
            [-80.846591554999975, 35.229700242000035],
            [-80.846678794999946, 35.229710696000041],
            [-80.846766680999963, 35.229716446000054],
            [-80.846854836999967, 35.229717467000057],
            [-80.846942885999965, 35.229713756000024],
            [-80.847030450999966, 35.229705327000033],
            [-80.847117154999978, 35.229692217000036],
            [-80.847202630999959, 35.229674482000064],
            [-80.847286507999968, 35.229652199000043],
            [-80.84736842999996, 35.229625461000069],
            [-80.847448044999965, 35.229594384000052],
            [-80.847525012999938, 35.229559101000063],
            [-80.847599002999971, 35.229519763000042],
            [-80.847669699999983, 35.229476539000075],
            [-80.847698413999979, 35.229457216000071],
            [-80.848756468999966, 35.228724964000037],
            [-80.848910761999946, 35.228619052000056],
            [-80.849146622999967, 35.228467868000052],
            [-80.849157161999983, 35.228461043000038],
            [-80.84916510499994, 35.228455806000056],
            [-80.84927728699995, 35.228381265000053],
            [-80.849295604999952, 35.228368871000043],
            [-80.849531936999938, 35.228206094000029],
            [-80.849572772999977, 35.22817679800005],
            [-80.849574651999944, 35.228175392000026],
            [-80.84961835699994, 35.22814261700006],
            [-80.849806556999965, 35.228016343000036],
            [-80.849852846999966, 35.227983824000034],
            [-80.850142176999952, 35.227771076000067],
            [-80.850261629999977, 35.227692468000043],
            [-80.850290728999937, 35.227672763000044],
            [-80.850509426999963, 35.227520396000045],
            [-80.850831521999964, 35.227296963000072],
            [-80.850893122999935, 35.227255000000071],
            [-80.851221265999982, 35.227033393000056],
            [-80.851232378999953, 35.227026353000042],
            [-80.851249111999948, 35.227015582000035],
            [-80.851269788999957, 35.227001776000066],
            [-80.851343719999988, 35.226951408000048],
            [-80.851390137999942, 35.226918286000057],
            [-80.851402036999957, 35.226909289000048],
            [-80.851472262999948, 35.226855541000077],
            [-80.851523574999987, 35.226814111000067],
            [-80.851527404999956, 35.226810835000038],
            [-80.851593926999954, 35.226753708000047],
            [-80.851640551999935, 35.226711545000057],
            [-80.851698466999949, 35.226656383000034],
            [-80.85170706699995, 35.226648110000042],
            [-80.851755774999958, 35.226597896000044],
            [-80.851808605999963, 35.226540063000073],
            [-80.851814545999957, 35.226533510000024],
            [-80.851848520999965, 35.226493958000049],
            [-80.851897943999973, 35.226433659000065],
            [-80.851913975999935, 35.22641363300005],
            [-80.851940379999974, 35.226378430000068],
            [-80.851946871999985, 35.226369399000077],
            [-80.851950664999947, 35.22636409100005],
            [-80.851988795999944, 35.226310375000025],
            [-80.852003750999984, 35.226288752000073],
            [-80.852027257999964, 35.226252260000024],
            [-80.85206709299996, 35.226187528000025],
            [-80.852083686999947, 35.22615959500007],
            [-80.852101698999945, 35.22612678400003],
            [-80.852136743999949, 35.226060063000034],
            [-80.852153611999938, 35.226026436000041],
            [-80.852165035999974, 35.226001686000075],
            [-80.852195305999942, 35.225933663000035],
            [-80.852213390999964, 35.225890245000073],
            [-80.852221479999969, 35.225868665000064],
            [-80.852246685999944, 35.225798888000043],
            [-80.852262610999958, 35.225750862000041],
            [-80.852266721999968, 35.225736874000063],
            [-80.852280389999976, 35.225689000000045],
            [-80.852311035999946, 35.225598973000046],
            [-80.852321143999973, 35.225578597000037],
            [-80.852335222999955, 35.225553570000045],
            [-80.852347829999985, 35.225533938000069],
            [-80.852362208999978, 35.225514291000025],
            [-80.852378554999973, 35.225494366000078],
            [-80.85239527799996, 35.225476167000068],
            [-80.852415888999985, 35.225456074000078],
            [-80.852424187999986, 35.225448720000031],
            [-80.852440831999957, 35.225436847000026],
            [-80.852456679999989, 35.225426782000056],
            [-80.85247710699997, 35.225415269000052],
            [-80.852494684999954, 35.225406374000045],
            [-80.852513301999977, 35.225398225000049],
            [-80.852536891999989, 35.225389095000025],
            [-80.852555299999949, 35.225382952000075],
            [-80.852580140999976, 35.225376031000053],
            [-80.852599830999964, 35.225371468000048],
            [-80.852616968999939, 35.225368339000056],
            [-80.852633847999982, 35.225366078000036],
            [-80.852651170999934, 35.225364580000075],
            [-80.852665420999983, 35.225364013000046],
            [-80.852751320999971, 35.225364930000069],
            [-80.852752236999947, 35.225364976000037],
            [-80.852769250999984, 35.225365718000035],
            [-80.852850862999958, 35.225366822000069],
            [-80.852908339999942, 35.225366170000029],
            [-80.852914878999968, 35.22536608300004],
            [-80.853002922999963, 35.225362367000059],
            [-80.853026140999987, 35.225360594000051],
            [-80.853083244999937, 35.225355825000065],
            [-80.853147586999967, 35.225349165000068],
            [-80.853211932999955, 35.225339894000058],
            [-80.853268656999944, 35.225330553000049],
            [-80.853291009999964, 35.225326709000058],
            [-80.853376478999962, 35.225308969000025],
            [-80.853384964999975, 35.225306939000063],
            [-80.853440477999982, 35.225293492000048],
            [-80.853515863999974, 35.225273236000078],
            [-80.85356126299996, 35.22525904400004],
            [-80.853614448999963, 35.225241513000071],
            [-80.853650965999975, 35.225228961000028],
            [-80.853721614999984, 35.225201643000048],
            [-80.85377303599995, 35.225180244000057],
            [-80.853781995999952, 35.225176481000062],
            [-80.853858956999943, 35.225141195000049],
            [-80.853882106999947, 35.225129486000071],
            [-80.853930931999969, 35.225104230000056],
            [-80.853981763999968, 35.225076597000054],
            [-80.854029829999945, 35.225047853000035],
            [-80.854081561999976, 35.225015456000051],
            [-80.854104185999972, 35.225000973000078],
            [-80.854154627999947, 35.224966279000057],
            [-80.854252973999962, 35.224895208000078],
            [-80.85460029099994, 35.224645266000039],
            [-80.854661849999957, 35.224603898000055],
            [-80.854669550999972, 35.224598683000067],
            [-80.855065840999941, 35.224328325000045],
            [-80.855077015999939, 35.224320618000036],
            [-80.855905446999941, 35.223742861000062],
            [-80.856544977999988, 35.223298998000075],
            [-80.856629673999976, 35.223240452000027],
            [-80.856634733999954, 35.22323693800007],
            [-80.857049272999973, 35.222947514000055],
            [-80.857054213999959, 35.222944047000055],
            [-80.857149794999941, 35.222876659000065],
            [-80.857315701999937, 35.222760323000045],
            [-80.857317194999951, 35.222759274000055],
            [-80.857667407999941, 35.22251297300005],
            [-80.857668519999947, 35.222512191000078],
            [-80.858043147999979, 35.222248136000076],
            [-80.85865384899995, 35.22182560400006],
            [-80.858659497999952, 35.221821674000068],
            [-80.859111920999965, 35.221505140000033],
            [-80.859215764999988, 35.221433269000045],
            [-80.859217846999968, 35.221431825000025],
            [-80.859510812999986, 35.22122822800003],
            [-80.859691859999941, 35.221107449000044],
            [-80.86024176899997, 35.220792440000025],
            [-80.860251067999968, 35.220787066000071],
            [-80.860778151999966, 35.220479665000028],
            [-80.861331275999987, 35.22017097500003],
            [-80.861334132999957, 35.220169375000069],
            [-80.862021827999968, 35.219783454000037],
            [-80.862414097999988, 35.219564763000051],
            [-80.862418072999958, 35.219562619000044],
            [-80.862447676999977, 35.219546192000053],
            [-80.862451338999961, 35.21954409500006],
            [-80.862505298999963, 35.219513071000051],
            [-80.863258998999981, 35.219086662000052],
            [-80.863696828999934, 35.21884239600007],
            [-80.864327881999941, 35.218491039000071],
            [-80.864340863999985, 35.218483719000062],
            [-80.864354133999939, 35.218476046000035],
            [-80.864367519999973, 35.218468205000079],
            [-80.864787384999943, 35.218227830000046],
            [-80.865133353999965, 35.218033568000067],
            [-80.865141975999961, 35.218028686000025],
            [-80.86514331099994, 35.218027923000079],
            [-80.865452923999953, 35.217850702000078],
            [-80.865522265999971, 35.21780823000006],
            [-80.865549021999982, 35.217790251000054],
            [-80.865659735999941, 35.217713884000034],
            [-80.865695095999968, 35.217688620000047],
            [-80.865801294999983, 35.217610033000028],
            [-80.865806258999953, 35.217606341000078],
            [-80.865838130999975, 35.217581729000074],
            [-80.865941177999957, 35.21749952700003],
            [-80.865972499999941, 35.217473704000042],
            [-80.86597633699995, 35.217470420000041],
            [-80.866075681999973, 35.217385069000045],
            [-80.866109406999954, 35.217355001000044],
            [-80.866205050999952, 35.217266500000051],
            [-80.86622652799997, 35.217246132000071],
            [-80.866237766999973, 35.217235061000054],
            [-80.866316067999946, 35.217156905000024],
            [-80.866353721999985, 35.217117537000036],
            [-80.866427495999972, 35.21703670200003],
            [-80.866433235999978, 35.217030367000064],
            [-80.866457174999937, 35.21700287200008],
            [-80.86650760699996, 35.216943039000057],
            [-80.866587352999943, 35.216850871000076],
            [-80.866612032999967, 35.216821346000074],
            [-80.86669289799994, 35.216721164000035],
            [-80.866703790999964, 35.216707449000069],
            [-80.866719737999972, 35.21668653200004],
            [-80.866742477999935, 35.216655951000064],
            [-80.866771665999977, 35.216614729000071],
            [-80.866789147999953, 35.216587897000068],
            [-80.866966015999935, 35.216307227000073],
            [-80.867296927999973, 35.215835823000077],
            [-80.86730662399998, 35.215821775000052],
            [-80.867378697999982, 35.21571554500008],
            [-80.867380822999962, 35.215712401000076],
            [-80.867405012999939, 35.215674780000029],
            [-80.86743379699999, 35.215627847000064],
            [-80.86744969099999, 35.215601039000035],
            [-80.867483370999935, 35.215537065000035],
            [-80.867506809999952, 35.215488379000078],
            [-80.867507993999936, 35.21548591100003],
            [-80.867537486999936, 35.215417739000031],
            [-80.867540815999973, 35.215409039000065],
            [-80.867559756999981, 35.215358823000031],
            [-80.867580424999971, 35.215297914000075],
            [-80.867589114999987, 35.215267285000039],
            [-80.867602729999987, 35.215216004000069],
            [-80.86761243899997, 35.215175885000065],
            [-80.867622271999949, 35.215123790000064],
            [-80.867630563999967, 35.215071677000026],
            [-80.867633450999961, 35.215052187000026],
            [-80.867640153999957, 35.214984865000076],
            [-80.867643417999943, 35.214932370000042],
            [-80.867643702999942, 35.214927578000072],
            [-80.867644928999937, 35.214855244000034],
            [-80.86764123599994, 35.21479164200008],
            [-80.867627175999985, 35.214640941000027],
            [-80.867626327999972, 35.214632297000037],
            [-80.867626161999965, 35.214631074000067],
            [-80.867702082999983, 35.214513136000051],
            [-80.867816599999969, 35.214344031000053],
            [-80.867818210999985, 35.214341644000058],
            [-80.867847594999944, 35.214295463000042],
            [-80.867869073999941, 35.214259673000072],
            [-80.868158205999976, 35.214265887000067],
            [-80.868159896999941, 35.214265922000038],
            [-80.868555565999941, 35.214274017000037],
            [-80.868588935999981, 35.214310755000042],
            [-80.868646128999956, 35.214365802000032],
            [-80.86870758799995, 35.214417660000038],
            [-80.868773046999934, 35.214466109000057],
            [-80.868842226999959, 35.214510941000071],
            [-80.868914832999963, 35.214551964000066],
            [-80.868990551999957, 35.214589002000025],
            [-80.869069060999948, 35.214621897000029],
            [-80.869150021999985, 35.214650507000044],
            [-80.869233090999955, 35.214674710000054],
            [-80.869317910999939, 35.214694401000031],
            [-80.869404118999967, 35.214709499000037],
            [-80.869491345999961, 35.214719937000041],
            [-80.869579216999966, 35.214725671000053],
            [-80.869667356999969, 35.214726675000065],
            [-80.869755388999977, 35.214722948000031],
            [-80.869842934999951, 35.214714502000049],
            [-80.869929619999937, 35.21470137600005],
            [-80.870015074999969, 35.214683624000031],
            [-80.870098930999973, 35.214661325000066],
            [-80.870128849999958, 35.214652140000055],
            [-80.870585120999976, 35.214506990000075],
            [-80.870637100999943, 35.214489421000053],
            [-80.870641635999959, 35.214487788000042],
            [-80.870653538999989, 35.214483482000048],
            [-80.870724802999973, 35.214455625000028],
            [-80.870801636999943, 35.21442329000007],
            [-80.870805428999972, 35.214421688000073],
            [-80.870871647999934, 35.214391640000031],
            [-80.870945887999937, 35.214355681000029],
            [-80.870956611999986, 35.21435043200006],
            [-80.871018465999953, 35.214317915000038],
            [-80.871089826999935, 35.214278107000041],
            [-80.871101937999981, 35.214271272000076],
            [-80.871152671999937, 35.214240837000034],
            [-80.87122089199994, 35.214197872000057],
            [-80.871240827999941, 35.214185070000042],
            [-80.871290919999979, 35.214150612000026],
            [-80.87135570099997, 35.214103806000026],
            [-80.871372682999947, 35.214091325000027],
            [-80.871412059999955, 35.214060675000042],
            [-80.871473424999976, 35.21401094600003],
            [-80.871497234999936, 35.213991157000066],
            [-80.871556268999939, 35.213937436000037],
            [-80.871563350999963, 35.213930494000067],
            [-80.87187821699996, 35.213619249000033],
            [-80.872949810999955, 35.212619481000047],
            [-80.872967722999988, 35.212602422000032],
            [-80.872984702999986, 35.212585578000073],
            [-80.873437945999967, 35.212126813000054],
            [-80.873639494999964, 35.211924926000052],
            [-80.873729957999956, 35.21183800700004],
            [-80.873744709999983, 35.211877939000033],
            [-80.873752980999939, 35.211899525000035],
            [-80.873778452999943, 35.211963712000056],
            [-80.873785827999939, 35.211981769000033],
            [-80.87381269399998, 35.212045707000073],
            [-80.873818985999947, 35.212060346000044],
            [-80.873819913999967, 35.212062450000076],
            [-80.873847875999957, 35.212125683000068],
            [-80.873856939999939, 35.212145578000047],
            [-80.873886576999951, 35.21220878400004],
            [-80.873894600999961, 35.212225489000048],
            [-80.873925338999982, 35.212287992000029],
            [-80.873934765999934, 35.212306670000032],
            [-80.873966886999938, 35.21236869300003],
            [-80.873972046999938, 35.212378522000051],
            [-80.873976746999972, 35.212387256000056],
            [-80.874010253999984, 35.212448799000072],
            [-80.874017956999978, 35.212462688000073],
            [-80.874052574999951, 35.212523986000065],
            [-80.874065763999965, 35.212546661000033],
            [-80.874102039999968, 35.212607245000072],
            [-80.874111178999954, 35.212622221000061],
            [-80.874148558999934, 35.212682332000043],
            [-80.874151715999972, 35.21268737500003],
            [-80.874159900999985, 35.212700166000047],
            [-80.874198663999948, 35.212759795000068],
            [-80.874208407999959, 35.212774514000046],
            [-80.874248277999982, 35.212833669000076],
            [-80.874260468999978, 35.212851371000056],
            [-80.874301432999971, 35.212909594000052],
            [-80.874311856999952, 35.212924152000028],
            [-80.874351621999949, 35.212978728000053],
            [-80.87435408999994, 35.21298210100008],
            [-80.874364401999969, 35.212995911000064],
            [-80.874410447999935, 35.213056572000028],
            [-80.874426749999941, 35.213077521000059],
            [-80.87447186199995, 35.213134076000074],
            [-80.874478057999966, 35.213141773000075],
            [-80.874523434999958, 35.213197638000054],
            [-80.874538575999964, 35.213215882000043],
            [-80.874541396999973, 35.213219196000068],
            [-80.87458871399997, 35.213274570000067],
            [-80.874604141999953, 35.213292251000041],
            [-80.874652836999985, 35.213346917000024],
            [-80.874664221999979, 35.213359511000078],
            [-80.874713739999947, 35.213413477000074],
            [-80.874729298999966, 35.213430102000075],
            [-80.874779911999951, 35.213483137000026],
            [-80.874787407999975, 35.213490920000027],
            [-80.874794215999941, 35.213497867000058],
            [-80.874845930999982, 35.213550195000039],
            [-80.874856340999941, 35.213560598000072],
            [-80.874908878999975, 35.213612456000078],
            [-80.874930133999953, 35.213632923000034],
            [-80.874984325999947, 35.213683841000034],
            [-80.874997127999961, 35.213695698000038],
            [-80.87505213999998, 35.213745915000061],
            [-80.875058060999947, 35.213751286000047],
            [-80.875069576999977, 35.213761528000077],
            [-80.87512568599999, 35.213810814000055],
            [-80.875143532999971, 35.213826193000045],
            [-80.875200735999954, 35.213874543000031],
            [-80.875212114999954, 35.213884046000032],
            [-80.875270143999956, 35.213931926000043],
            [-80.875290873999973, 35.213948664000043],
            [-80.87534999199994, 35.213995381000075],
            [-80.875363995999976, 35.214006290000043],
            [-80.875424219999957, 35.214052534000075],
            [-80.875443381999958, 35.214066965000029],
            [-80.875504416999945, 35.21411205000004],
            [-80.875521228999958, 35.214124262000041],
            [-80.875583362999976, 35.21416864300005],
            [-80.875598839999952, 35.214179530000024],
            [-80.875605590999953, 35.21418417600006],
            [-80.875668815999973, 35.21422739500008],
            [-80.875681461999989, 35.214235934000044],
            [-80.875745227999971, 35.214278456000045],
            [-80.875765316999946, 35.214291594000031],
            [-80.875830177999944, 35.214333186000033],
            [-80.875848933999976, 35.214344998000058],
            [-80.875914326999975, 35.214385435000054],
            [-80.875925267999946, 35.214392129000032],
            [-80.875932085999978, 35.214396230000034],
            [-80.875998580999976, 35.214435963000028],
            [-80.876019326999938, 35.214448114000049],
            [-80.876086913999984, 35.214486914000076],
            [-80.876106599999957, 35.214498003000074],
            [-80.876174719999938, 35.214535652000052],
            [-80.876191647999974, 35.214544856000032],
            [-80.87626030499996, 35.214581578000036],
            [-80.87626873499994, 35.214586050000037],
            [-80.876283829999977, 35.214593878000073],
            [-80.876353858999948, 35.21462966100006],
            [-80.876369026999953, 35.214637297000024],
            [-80.876439314999971, 35.214672164000035],
            [-80.876466408999988, 35.214685252000038],
            [-80.876538065999966, 35.214718950000076],
            [-80.876555211999971, 35.214726877000032],
            [-80.87662684299994, 35.214759430000072],
            [-80.876628062999941, 35.214759984000068],
            [-80.87665072599998, 35.214770027000043],
            [-80.876723728999934, 35.214801643000044],
            [-80.87673797299999, 35.214807724000025],
            [-80.876810955999986, 35.21483842400005],
            [-80.876836655999966, 35.214848952000068],
            [-80.876910728999974, 35.214878491000036],
            [-80.876925497999935, 35.214884291000033],
            [-80.876999549999937, 35.214912914000024],
            [-80.877000685999974, 35.214913353000043],
            [-80.877025556999968, 35.214922691000027],
            [-80.877100697999936, 35.21495015000005],
            [-80.877122838999981, 35.214958048000028],
            [-80.877199074999965, 35.214984574000027],
            [-80.877220551999983, 35.214991868000027],
            [-80.877296476999959, 35.215017027000044],
            [-80.877308952999954, 35.215021102000037],
            [-80.877318743999979, 35.215024218000053],
            [-80.877395763999971, 35.215048443000057],
            [-80.877415670999937, 35.21505455700003],
            [-80.877492665999966, 35.215077637000036],
            [-80.87751590299996, 35.215084406000074],
            [-80.877593428999944, 35.215106333000051],
            [-80.877619653999943, 35.215113505000033],
            [-80.87769826899995, 35.215134270000078],
            [-80.877702178999982, 35.215135298000064],
            [-80.877720930999942, 35.215140075000079],
            [-80.877823373999945, 35.215165508000041],
            [-80.87787614299998, 35.215177652000079],
            [-80.878557868999962, 35.215322336000042],
            [-80.878571170999976, 35.21532510000003],
            [-80.878657380999982, 35.215340191000053],
            [-80.878744609999956, 35.215350622000074],
            [-80.87883248199995, 35.215356350000036],
            [-80.878920622999942, 35.215357347000065],
            [-80.879008655999939, 35.215353613000048],
            [-80.879096200999982, 35.215345160000027],
            [-80.879182884999977, 35.215332028000034],
            [-80.879268338999964, 35.215314269000032],
            [-80.879352192999988, 35.215291964000073],
            [-80.879434089999961, 35.215265204000048],
            [-80.879513678999956, 35.215234106000025],
            [-80.879590618999941, 35.215198803000078],
            [-80.879664580999986, 35.21515944500004],
            [-80.879735247999974, 35.215116202000047],
            [-80.879802316999985, 35.215069259000074],
            [-80.879865500999983, 35.215018816000054],
            [-80.879924529999982, 35.214965090000078],
            [-80.87997915699998, 35.214908304000062],
            [-80.880159654999943, 35.214707492000059],
            [-80.88019933299995, 35.214666098000066],
            [-80.880224640999984, 35.214638838000042],
            [-80.880244638999955, 35.214615986000069],
            [-80.88029327299995, 35.21455889300006],
            [-80.880323252999972, 35.214522154000065],
            [-80.880342700999961, 35.214496495000049],
            [-80.880386800999986, 35.214436496000076],
            [-80.880412475999947, 35.214400010000077],
            [-80.880427279999935, 35.214377398000067],
            [-80.880466594999973, 35.214315640000052],
            [-80.880491863999964, 35.214273818000038],
            [-80.880500309999945, 35.21425877300004],
            [-80.880535395999971, 35.214195019000044],
            [-80.880561800999942, 35.214143618000037],
            [-80.880570013999943, 35.21412599100006],
            [-80.880585788999952, 35.214091248000045],
            [-80.880694584999958, 35.213889172000052],
            [-80.880708049999953, 35.213862659000029],
            [-80.880860989999974, 35.213571331000026],
            [-80.880877450999947, 35.213538536000044],
            [-80.88089948399994, 35.213492562000056],
            [-80.880910865999965, 35.213471962000028],
            [-80.880922854999937, 35.21345307100006],
            [-80.880955433999986, 35.213407794000034],
            [-80.880969827999934, 35.213387117000025],
            [-80.880991714999936, 35.213357366000025],
            [-80.881009649999953, 35.213335444000052],
            [-80.881027396999968, 35.213316084000041],
            [-80.881110873999944, 35.21324942900003],
            [-80.881341155999962, 35.213083564000044],
            [-80.881610941999952, 35.212894551000034],
            [-80.881642300999943, 35.212871881000069],
            [-80.881760570999973, 35.212783671000068],
            [-80.881763905999946, 35.212781395000036],
            [-80.881809827999973, 35.212748586000032],
            [-80.88181558499997, 35.212744256000065],
            [-80.881874001999961, 35.212700065000035],
            [-80.881931424999948, 35.212653952000039],
            [-80.881945209999969, 35.212642025000036],
            [-80.881999634999943, 35.212594230000036],
            [-80.882044875999952, 35.212552429000027],
            [-80.882054646999961, 35.212542818000031],
            [-80.882105383999942, 35.212492333000057],
            [-80.882150229999979, 35.212445163000041],
            [-80.882163448999961, 35.212430195000024],
            [-80.882194350999953, 35.212394582000059],
            [-80.882227068999953, 35.212359640000045],
            [-80.882243008999978, 35.212342265000075],
            [-80.882263962999957, 35.212318289000052],
            [-80.882322912999939, 35.212248897000052],
            [-80.882351934999974, 35.21221328300004],
            [-80.882361855999989, 35.212200387000053],
            [-80.882416286999955, 35.212128545000041],
            [-80.88245148499999, 35.212079295000024],
            [-80.882452219999948, 35.212078201000054],
            [-80.88250239499996, 35.212003447000029],
            [-80.882541730999947, 35.211940107000032],
            [-80.882550425999966, 35.211924606000025],
            [-80.882634884999959, 35.211770939000075],
            [-80.882799400999943, 35.211501114000043],
            [-80.882813965999958, 35.211476468000058],
            [-80.882816577999961, 35.211471877000065],
            [-80.882828776999986, 35.211450309000043],
            [-80.882902068999954, 35.211324639000054],
            [-80.882968023999979, 35.211226002000046],
            [-80.88298528699994, 35.21120241400007],
            [-80.882998225999984, 35.211186719000068],
            [-80.883011459999977, 35.211172401000056],
            [-80.883025115999942, 35.211159223000038],
            [-80.883040520999941, 35.211145927000075],
            [-80.883056993999958, 35.211133149000034],
            [-80.883072048999963, 35.211122713000066],
            [-80.883089501999962, 35.211111961000029],
            [-80.883114416999945, 35.211098245000073],
            [-80.883157031999986, 35.211076988000059],
            [-80.883308230999944, 35.211003204000065],
            [-80.883595729999968, 35.210864380000032],
            [-80.883778296999935, 35.210777225000072],
            [-80.88379654199997, 35.210768358000053],
            [-80.883870496999975, 35.210728997000047],
            [-80.883941157999971, 35.210685752000074],
            [-80.883959030999961, 35.210673842000062],
            [-80.88400188899999, 35.210644777000027],
            [-80.884051079999949, 35.210609742000031],
            [-80.884090704999949, 35.21057888100006],
            [-80.88413014799994, 35.210546891000035],
            [-80.884153699999956, 35.210527307000064],
            [-80.884212722999962, 35.210473579000052],
            [-80.884223905999988, 35.210462559000064],
            [-80.884259089999944, 35.210427429000049],
            [-80.884302520999938, 35.210381667000036],
            [-80.884328361999962, 35.210351895000031],
            [-80.884359862999986, 35.210314304000065],
            [-80.884383993999961, 35.210284484000056],
            [-80.884429110999974, 35.210222338000051],
            [-80.88443440399999, 35.210214396000026],
            [-80.884460824999962, 35.210174364000068],
            [-80.88449559999998, 35.210117870000033],
            [-80.884530446999975, 35.210051423000039],
            [-80.884549756999945, 35.210008403000074],
            [-80.884575147999954, 35.209948007000037],
            [-80.884585313999935, 35.209922850000055],
            [-80.884599616999935, 35.209883467000054],
            [-80.884620511999969, 35.209821765000072],
            [-80.884630189999939, 35.209791536000068],
            [-80.884643645999972, 35.209742132000031],
            [-80.884656896999957, 35.209687649000045],
            [-80.884661822999988, 35.209666303000063],
            [-80.884669638999981, 35.209626309000043],
            [-80.884682679999969, 35.209550759000024],
            [-80.884687567999947, 35.209519167000053],
            [-80.884690533999958, 35.209495143000026],
            [-80.884700908999946, 35.209400858000038],
            [-80.884704915999976, 35.209352768000031],
            [-80.88470558399996, 35.209339171000067],
            [-80.884707763999984, 35.209286797000061],
            [-80.884708241999988, 35.209279429000048],
            [-80.884709451999981, 35.209207094000078],
            [-80.884709185999952, 35.209197675000041],
            [-80.884706484999981, 35.209117105000075],
            [-80.884702514999958, 35.209057424000036],
            [-80.884694518999936, 35.208977164000032],
            [-80.884694199999956, 35.208974019000038],
            [-80.884689202999937, 35.208934168000042],
            [-80.88468258599994, 35.208889385000077],
            [-80.884677738999983, 35.208859905000054],
            [-80.884628486999986, 35.208588004000035],
            [-80.884583953999936, 35.208296818000065],
            [-80.884578253999962, 35.208234489000063],
            [-80.884577232999959, 35.208223988000043],
            [-80.884572115999958, 35.208183328000075],
            [-80.884564846999979, 35.208134433000055],
            [-80.884559660999969, 35.208103248000043],
            [-80.884546182999941, 35.208041803000071],
            [-80.884533905999945, 35.207993671000054],
            [-80.884531377999963, 35.207983978000073],
            [-80.884509736999973, 35.207913851000058],
            [-80.88450665199997, 35.207905204000042],
            [-80.884489661999964, 35.207858292000026],
            [-80.884465563999981, 35.207798124000078],
            [-80.884446161999961, 35.20775661600004],
            [-80.88442362699999, 35.207711164000045],
            [-80.884410421999974, 35.207685463000075],
            [-80.88437605699994, 35.207625790000066],
            [-80.884300411999959, 35.207503988000042],
            [-80.88429688399998, 35.207498347000069],
            [-80.884271585999954, 35.207460053000034],
            [-80.884155245999978, 35.207291935000057],
            [-80.884138188999941, 35.207267991000037],
            [-80.884051542999941, 35.207149790000074],
            [-80.883983100999956, 35.207053320000057],
            [-80.883976473999951, 35.207042823000052],
            [-80.883941412999945, 35.206844257000057],
            [-80.883940509999945, 35.206839229000025],
            [-80.883832126999948, 35.206244683000079],
            [-80.883791697999982, 35.206018706000066],
            [-80.883790887999965, 35.206014247000041],
            [-80.88378942199995, 35.206006463000051],
            [-80.883719355999972, 35.205643191000036],
            [-80.883718110999951, 35.205636856000069],
            [-80.883704860999956, 35.205570649000038],
            [-80.883691567999961, 35.205513629000052],
            [-80.883687479999935, 35.205498778000049],
            [-80.883668952999983, 35.205433568000046],
            [-80.883651400999952, 35.205378292000034],
            [-80.883646172999988, 35.205363785000031],
            [-80.883622361999983, 35.205299345000071],
            [-80.883600408999939, 35.205245036000065],
            [-80.883595961999958, 35.205235068000036],
            [-80.883567438999989, 35.205172076000053],
            [-80.883539280999969, 35.205114836000064],
            [-80.883531382999934, 35.205100277000042],
            [-80.883497604999945, 35.205039197000076],
            [-80.883467610999958, 35.204988442000058],
            [-80.883462275999989, 35.204980060000025],
            [-80.883424071999968, 35.204920654000034],
            [-80.883386390999988, 35.204865895000069],
            [-80.883378120999964, 35.204854785000066],
            [-80.883288310999944, 35.204735720000031],
            [-80.883248626999944, 35.204686134000042],
            [-80.883240632999957, 35.204676809000034],
            [-80.883201998999937, 35.20463223400003],
            [-80.883263328999988, 35.204185776000031],
            [-80.883263381999939, 35.204185395000025],
            [-80.883264674999964, 35.204175557000042],
            [-80.883296813999948, 35.203920297000025],
            [-80.883316499999978, 35.203772164000043],
            [-80.88332676999994, 35.203700173000072],
            [-80.883340598999951, 35.203630491000069],
            [-80.883362449999936, 35.203534672000046],
            [-80.883383887999969, 35.203450432000068],
            [-80.883484415999988, 35.203088930000035],
            [-80.883491642999957, 35.203061320000074],
            [-80.883513317999984, 35.202973041000064],
            [-80.883518728999945, 35.202949713000066],
            [-80.883524238999939, 35.202922535000027],
            [-80.883540877999963, 35.202833648000023],
            [-80.88354807199994, 35.202789239000026],
            [-80.883549293999977, 35.202779963000069],
            [-80.883560616999944, 35.202690472000029],
            [-80.883565978999968, 35.202634110000076],
            [-80.88357171499996, 35.202544246000059],
            [-80.883572104999985, 35.20253776800007],
            [-80.883573505999948, 35.20249627700008],
            [-80.883574486999976, 35.202406030000077],
            [-80.883574297999985, 35.20237518700003],
            [-80.883574215999943, 35.202371866000078],
            [-80.883564203999981, 35.201998020000076],
            [-80.883560623999983, 35.201691564000043],
            [-80.883568258999958, 35.201688726000043],
            [-80.884170938999944, 35.201477168000054],
            [-80.884180278999963, 35.201473856000064],
            [-80.884189210999978, 35.201470623000034],
            [-80.884802495999963, 35.201246468000079],
            [-80.885481409999954, 35.201001124000072],
            [-80.885482912999976, 35.201000580000027],
            [-80.885952173999954, 35.200830435000057],
            [-80.885958467999956, 35.20082813700003],
            [-80.886472838999964, 35.200639047000038],
            [-80.886759566999956, 35.200537023000038],
            [-80.886762850999958, 35.200535851000041],
            [-80.886842422999962, 35.200504748000071],
            [-80.886873681999987, 35.200491052000075],
            [-80.886915648999945, 35.200472078000075],
            [-80.886961312999972, 35.200450466000063],
            [-80.887035257999969, 35.200411103000079],
            [-80.88704121699999, 35.200407680000069],
            [-80.887080314999935, 35.200385084000061],
            [-80.887145005999969, 35.200345260000063],
            [-80.887199512999985, 35.200307579000025],
            [-80.887235454999939, 35.200281367000059],
            [-80.887248000999989, 35.200272100000063],
            [-80.887311168999986, 35.200221654000075],
            [-80.887341486999958, 35.20019493500007],
            [-80.887373455999978, 35.200165810000044],
            [-80.887402150999947, 35.200138799000058],
            [-80.887456756999939, 35.200082016000067],
            [-80.887467293999975, 35.200070124000035],
            [-80.887495290999937, 35.200038083000038],
            [-80.887527509999984, 35.199999512000034],
            [-80.887531162999949, 35.199994935000063],
            [-80.887532771999986, 35.199993135000057],
            [-80.887580877999937, 35.199935614000026],
            [-80.887625986999979, 35.199873466000042],
            [-80.887666047999971, 35.19980903000004],
            [-80.887700886999937, 35.199742581000066],
            [-80.887730357999942, 35.199674404000064],
            [-80.887738132999971, 35.199653405000049],
            [-80.887742383999978, 35.199644364000051],
            [-80.887763460999963, 35.19959625100006],
            [-80.887824169999988, 35.199446902000034],
            [-80.887829935999946, 35.199432384000033],
            [-80.887838602999977, 35.199409157000048],
            [-80.887906920999967, 35.199218642000062],
            [-80.888006186999974, 35.198963164000077],
            [-80.888008359999958, 35.198958228000038],
            [-80.888057381999943, 35.198851902000058],
            [-80.888107008999953, 35.198753930000066],
            [-80.888159659999985, 35.198657839000077],
            [-80.888216120999971, 35.198563438000065],
            [-80.888281830999972, 35.198461063000025],
            [-80.888293475999944, 35.198443569000062],
            [-80.88833120999999, 35.198390176000032],
            [-80.888364505999959, 35.198347677000072],
            [-80.888395971999955, 35.198311286000035],
            [-80.888878398999964, 35.197815624000043],
            [-80.888880192999977, 35.197813776000032],
            [-80.889216162999958, 35.197467067000048],
            [-80.889217646999953, 35.197465532000024],
            [-80.889836418999948, 35.196824641000035],
            [-80.89059537199995, 35.196062163000079],
            [-80.890598766999972, 35.196058738000033],
            [-80.891332019999936, 35.195316027000047],
            [-80.891344457999935, 35.19530323400005],
            [-80.891624106999984, 35.195011239000053],
            [-80.891949060999934, 35.194677519000038],
            [-80.891956407999942, 35.194669905000069],
            [-80.892289619999985, 35.194321467000066],
            [-80.892425881999941, 35.19418453600008],
            [-80.892503934999979, 35.194113622000032],
            [-80.892510783999967, 35.194107353000049],
            [-80.892540753999981, 35.194079702000067],
            [-80.892542621999951, 35.194078326000067],
            [-80.892573579999976, 35.194059371000037],
            [-80.892629769999985, 35.19402860200006],
            [-80.892651849999936, 35.194016250000061],
            [-80.892652641999973, 35.194015797000077],
            [-80.89269275099997, 35.193992842000057],
            [-80.892723703999934, 35.193977251000035],
            [-80.892773109999951, 35.193954309000048],
            [-80.892801077999934, 35.193942392000054],
            [-80.892830260999972, 35.193931702000043],
            [-80.892877805999944, 35.193916415000047],
            [-80.892902889999959, 35.193908509000039],
            [-80.89305352599996, 35.193876855000042],
            [-80.893281154999954, 35.193834007000078],
            [-80.893292561999942, 35.193831817000046],
            [-80.893831098999954, 35.193726387000027],
            [-80.894822724999983, 35.193538029000024],
            [-80.89485568799995, 35.19353140700008],
            [-80.895009766999976, 35.193498760000068],
            [-80.895046515999979, 35.193490517000043],
            [-80.895130343999938, 35.193468200000041],
            [-80.895212213999969, 35.193441430000064],
            [-80.895291774999976, 35.193410321000044],
            [-80.895368687999962, 35.193375008000032],
            [-80.895442621999962, 35.193335640000043],
            [-80.895513261999952, 35.193292388000032],
            [-80.895580303999964, 35.193245436000041],
            [-80.895643460999963, 35.193194985000048],
            [-80.895702463999953, 35.193141251000043],
            [-80.895757058999948, 35.193084464000037],
            [-80.895807012999967, 35.193024867000076],
            [-80.895852111999943, 35.192962716000068],
            [-80.895892161999939, 35.19289827700004],
            [-80.895926991999943, 35.192831826000031],
            [-80.89595645299994, 35.192763647000049],
            [-80.895980418999955, 35.192694032000077],
            [-80.895998787999986, 35.192623279000031],
            [-80.89601147999997, 35.192551692000052],
            [-80.896018441999956, 35.192479576000039],
            [-80.896019641999942, 35.192407241000069],
            [-80.896015076999959, 35.192334997000046],
            [-80.896004765999976, 35.192263152000066],
            [-80.895988752999983, 35.192192015000046],
            [-80.895967105999944, 35.192121890000067],
            [-80.895939918999943, 35.192053077000025],
            [-80.895926523999947, 35.192023901000027],
            [-80.895854681999936, 35.19187387900007],
            [-80.895835466999984, 35.191835849000029],
            [-80.895821196999975, 35.191809944000056],
            [-80.895766693999974, 35.19171428900006],
            [-80.895738087999973, 35.19165861700003],
            [-80.895702398999958, 35.191582422000067],
            [-80.895700499999975, 35.191578390000075],
            [-80.895671817999983, 35.191517860000033],
            [-80.895579810999948, 35.191278621000038],
            [-80.895435669999983, 35.190882407000061],
            [-80.895404101999986, 35.19079399900005],
            [-80.895402648999948, 35.190789962000053],
            [-80.895287573999951, 35.190472179000039],
            [-80.895232207999982, 35.190297658000077],
            [-80.895149767999953, 35.190030364000052],
            [-80.895130565999978, 35.189964558000042],
            [-80.895127836999961, 35.189955381000061],
            [-80.895113432999949, 35.189907816000073],
            [-80.89508191699997, 35.189799157000039],
            [-80.895065762999934, 35.189748764000058],
            [-80.895045403999973, 35.189695855000025],
            [-80.895030636999934, 35.189660589000027],
            [-80.895023810999987, 35.189644685000076],
            [-80.895008596999958, 35.189611729000035],
            [-80.894973721999975, 35.189539679000063],
            [-80.894956327999978, 35.189505429000064],
            [-80.894918433999976, 35.189440117000061],
            [-80.89487541799997, 35.189376980000077],
            [-80.894871141999943, 35.18937119900005],
            [-80.89484551299995, 35.189336790000027],
            [-80.894833652999978, 35.189321162000056],
            [-80.894807766999975, 35.18928767400007],
            [-80.89477594799996, 35.189248391000035],
            [-80.894731405999948, 35.189198863000058],
            [-80.89471019399997, 35.189176604000068],
            [-80.894661391999989, 35.189123921000032],
            [-80.894642014999988, 35.189103505000048],
            [-80.894636098999968, 35.189097462000063],
            [-80.894341822999934, 35.188799084000038],
            [-80.894340074999946, 35.188797315000045],
            [-80.894060518999936, 35.188515051000024],
            [-80.893932115999974, 35.188384793000068],
            [-80.893794666999952, 35.188243386000067],
            [-80.893781888999968, 35.188230440000041],
            [-80.893549990999986, 35.187999102000049],
            [-80.893523753999943, 35.187973717000034],
            [-80.89349350599997, 35.187945328000069],
            [-80.893481813999983, 35.187933145000045],
            [-80.893469381999978, 35.187918399000068],
            [-80.893458629999941, 35.187904290000063],
            [-80.893449894999947, 35.187890991000074],
            [-80.893311093999955, 35.18765217300006],
            [-80.893187152999985, 35.187433265000038],
            [-80.893026624999948, 35.187140278000072],
            [-80.892808029999969, 35.186735405000036],
            [-80.892799640999954, 35.186720177000041],
            [-80.892657779999979, 35.186467795000056],
            [-80.892654594999954, 35.186461311000073],
            [-80.892639789999976, 35.186432369000045],
            [-80.892601898999942, 35.186367057000041],
            [-80.892587858999946, 35.186345360000075],
            [-80.892566470999952, 35.186313175000066],
            [-80.892537497999967, 35.186271734000059],
            [-80.892489547999958, 35.186211041000035],
            [-80.892485024999985, 35.186205739000059],
            [-80.892459218999988, 35.186175682000055],
            [-80.892411058999983, 35.186122995000062],
            [-80.892362841999955, 35.186076123000078],
            [-80.89233319799996, 35.186048877000076],
            [-80.892324224999982, 35.186040713000068],
            [-80.89226277399996, 35.185988867000049],
            [-80.892223252999941, 35.185958861000074],
            [-80.892190051999989, 35.185934647000067],
            [-80.892164123999976, 35.185916215000077],
            [-80.892094954999948, 35.185871396000039],
            [-80.892053403999967, 35.185847223000053],
            [-80.892043925999985, 35.18584192600008],
            [-80.891989810999974, 35.185802544000069],
            [-80.891964964999943, 35.185784901000034],
            [-80.891912563999938, 35.185750401000064],
            [-80.891720360999955, 35.18563019700008],
            [-80.891703593999978, 35.185619877000079],
            [-80.891693499999974, 35.185613826000065],
            [-80.891324090999944, 35.185394482000049],
            [-80.891321911999967, 35.18539319100006],
            [-80.891158669999982, 35.185296669000024],
            [-80.890796247999958, 35.185080062000054],
            [-80.890794940999967, 35.185079282000061],
            [-80.890222893999976, 35.184738246000052],
            [-80.889923207999971, 35.184558078000066],
            [-80.889914491999946, 35.184552882000048],
            [-80.889568022999981, 35.184348061000037],
            [-80.889555286999951, 35.184340623000026],
            [-80.889228265999975, 35.184151991000078],
            [-80.888925355999959, 35.183971997000071],
            [-80.888922030999936, 35.183970028000033],
            [-80.888799340999981, 35.183897589000026],
            [-80.888744567999936, 35.183863701000064],
            [-80.888739864999934, 35.183860804000062],
            [-80.888460853999959, 35.183689737000066],
            [-80.888454387999957, 35.183685796000077],
            [-80.888389830999984, 35.183649045000038],
            [-80.887713367999936, 35.183287458000052],
            [-80.887705336999943, 35.183283198000026],
            [-80.887648192999961, 35.183254743000077],
            [-80.887194982999972, 35.183041507000041],
            [-80.887176428999965, 35.183032936000075],
            [-80.887097942999958, 35.183000053000058],
            [-80.88701700699994, 35.182971455000029],
            [-80.886933964999969, 35.182947264000063],
            [-80.886849173999963, 35.182927584000026],
            [-80.88676299499997, 35.182912499000054],
            [-80.886675800999967, 35.182902074000026],
            [-80.886587962999954, 35.182896352000057],
            [-80.886499855999944, 35.182895359000042],
            [-80.886411859999953, 35.182899100000043],
            [-80.886324349999938, 35.182907558000068],
            [-80.886237699999981, 35.182920696000053],
            [-80.886152283999934, 35.182938458000024],
            [-80.886068463999948, 35.182960769000033],
            [-80.885986601999946, 35.182987533000073],
            [-80.885907047999979, 35.183018636000043],
            [-80.885830139999939, 35.183053943000061],
            [-80.885756210999944, 35.183093304000067],
            [-80.885685574999968, 35.183136551000075],
            [-80.885627609999972, 35.183176771000035],
            [-80.885334581999984, 35.183392004000041],
            [-80.885288001999982, 35.18342246800006],
            [-80.885231388999955, 35.183456427000067],
            [-80.885151830999973, 35.183500166000044],
            [-80.885131832999946, 35.183511374000034],
            [-80.88512183399996, 35.183517143000074],
            [-80.884947329999989, 35.183618799000044],
            [-80.884886690999963, 35.183656277000068],
            [-80.884866082999963, 35.183670053000071],
            [-80.884494102999952, 35.183923761000074],
            [-80.884281830999953, 35.184054365000065],
            [-80.884104136999952, 35.184160578000046],
            [-80.883692274999987, 35.184399287000076],
            [-80.883381044999965, 35.184565373000055],
            [-80.883379701999957, 35.184566091000079],
            [-80.883172490999982, 35.184676957000079],
            [-80.883151231999989, 35.18468856700008],
            [-80.882630162999988, 35.184979057000078],
            [-80.882627593999985, 35.184980493000069],
            [-80.881873748999965, 35.185402835000048],
            [-80.881512652999959, 35.185604782000041],
            [-80.881079718999956, 35.185844289000045],
            [-80.881063019999942, 35.185853678000058],
            [-80.881062767999936, 35.18585382200007],
            [-80.880762938999965, 35.186025210000025],
            [-80.880736714999955, 35.186040586000047],
            [-80.880673763999937, 35.186078438000038],
            [-80.880636147999951, 35.18609884500006],
            [-80.880593555999951, 35.186119537000025],
            [-80.880554181999969, 35.186136892000036],
            [-80.880513029999975, 35.186152648000075],
            [-80.880470368999966, 35.186167278000028],
            [-80.880431598999962, 35.186178562000066],
            [-80.880398450999962, 35.186186541000041],
            [-80.880360449999955, 35.18619405000004],
            [-80.880325393999954, 35.18619951200003],
            [-80.880289623999943, 35.186203463000027],
            [-80.880251787999953, 35.186206131000063],
            [-80.880214943999988, 35.186207185000058],
            [-80.880194675999974, 35.186206924000032],
            [-80.880185418999986, 35.186206358000049],
            [-80.880166249999945, 35.186205256000051],
            [-80.880144458999951, 35.186203001000024],
            [-80.880106176999959, 35.18619724000007],
            [-80.880060447999938, 35.186189687000024],
            [-80.880006752999975, 35.186182699000028],
            [-80.879715467999972, 35.186149722000039],
            [-80.879322284999944, 35.186102371000061],
            [-80.879335913999967, 35.186074861000066],
            [-80.879342149999957, 35.186062044000039],
            [-80.87934828799996, 35.186048951000032],
            [-80.879421603999958, 35.185889564000036],
            [-80.879433112999948, 35.185863563000055],
            [-80.879497565999941, 35.185712079000041],
            [-80.879614019999963, 35.185452781000038],
            [-80.87961499499994, 35.185450603000049],
            [-80.879743108999946, 35.185163485000032],
            [-80.879761781999946, 35.185118691000071],
            [-80.879781375999983, 35.185063204000073],
            [-80.879821177999986, 35.18493839000007],
            [-80.879906146999986, 35.184696210000027],
            [-80.879919237999957, 35.184656076000067],
            [-80.879920369999979, 35.184652299000049],
            [-80.880043328999989, 35.184238618000052],
            [-80.880044350999981, 35.184235151000053],
            [-80.880082837999964, 35.184103830000026],
            [-80.880152531999954, 35.183880842000065],
            [-80.880155271999968, 35.183871923000027],
            [-80.880157519999955, 35.18386436000003],
            [-80.880248017999975, 35.183555156000068],
            [-80.880256903999964, 35.183522646000029],
            [-80.880369666999968, 35.183078899000066],
            [-80.880467158999977, 35.182750757000065],
            [-80.880593597999962, 35.182341531000077],
            [-80.880725445999985, 35.181928465000055],
            [-80.880728796999961, 35.181917746000067],
            [-80.880839153999943, 35.181557329000043],
            [-80.880848123999954, 35.18153144200005],
            [-80.880869971999971, 35.181462856000053],
            [-80.88087427499994, 35.181448981000074],
            [-80.880889757999967, 35.181391057000042],
            [-80.880906001999961, 35.181321412000045],
            [-80.880908898999962, 35.181308587000046],
            [-80.880918622999957, 35.181257094000046],
            [-80.880929832999982, 35.181186841000056],
            [-80.880932811999969, 35.181166748000066],
            [-80.880933077999941, 35.181164787000057],
            [-80.880949032999979, 35.181046367000079],
            [-80.880953194999961, 35.181009975000052],
            [-80.880964395999968, 35.18089094100003],
            [-80.88096694099994, 35.180857179000043],
            [-80.880967573999953, 35.180844410000077],
            [-80.880972637999946, 35.180725473000052],
            [-80.880973383999958, 35.180675034000046],
            [-80.880970153999954, 35.180427050000048],
            [-80.880965816999947, 35.17997110500005],
            [-80.880972378999957, 35.179700185000058],
            [-80.880983670999967, 35.179439133000074],
            [-80.880986699999937, 35.179404901000055],
            [-80.881004581999946, 35.179276665000032],
            [-80.881004892999954, 35.17927442000007],
            [-80.881010423999953, 35.179223401000058],
            [-80.881025361999946, 35.179036749000034],
            [-80.881026803999987, 35.179015653000079],
            [-80.881028169999979, 35.178977044000078],
            [-80.881031340999982, 35.178751874000056],
            [-80.881031428999961, 35.178740749000042],
            [-80.881031856999982, 35.178505542000039],
            [-80.881031616999962, 35.178482941000027],
            [-80.881030403999944, 35.178452506000042],
            [-80.881002641999942, 35.177962616000059],
            [-80.88100172999998, 35.177948468000068],
            [-80.88096690499998, 35.177466934000051],
            [-80.880965923999952, 35.17745455000005],
            [-80.88095753999994, 35.177357120000067],
            [-80.880956094999988, 35.177341841000043],
            [-80.88095494199996, 35.177331252000045],
            [-80.880936090999967, 35.177167111000074],
            [-80.880932038999958, 35.177136422000046],
            [-80.880907621999938, 35.176972828000032],
            [-80.880902806999984, 35.176943767000068],
            [-80.880873102999942, 35.176780712000038],
            [-80.880872827999951, 35.176779206000049],
            [-80.880863319999946, 35.176733902000024],
            [-80.880802898999946, 35.176477931000079],
            [-80.88066291399997, 35.175880590000077],
            [-80.880661951999969, 35.175876521000077],
            [-80.880522957999972, 35.175295162000054],
            [-80.880385779999983, 35.174708281000051],
            [-80.880383837999943, 35.174700131000066],
            [-80.880261193999957, 35.174195914000052],
            [-80.880259145999958, 35.17418766000003],
            [-80.880213240999979, 35.174006145000078],
            [-80.87997046299995, 35.173041474000058],
            [-80.879929748999984, 35.17287215500005],
            [-80.879792578999968, 35.172284974000036],
            [-80.879757334999965, 35.17207114200005],
            [-80.879754412999944, 35.172054381000066],
            [-80.879744366999944, 35.172006804000034],
            [-80.879677984999944, 35.171728667000025],
            [-80.879677486999981, 35.171726589000059],
            [-80.879567693999945, 35.17127117900003],
            [-80.879562243999942, 35.171249694000039],
            [-80.879548857999964, 35.171204088000025],
            [-80.879501981999965, 35.171058061000053],
            [-80.879494029999989, 35.171029227000076],
            [-80.879463453999961, 35.170903157000055],
            [-80.879436805999944, 35.17077688300003],
            [-80.879416857999956, 35.170664797000029],
            [-80.879408154999965, 35.170588523000049],
            [-80.879400561999944, 35.170486552000057],
            [-80.879395815999942, 35.170366222000041],
            [-80.879395619999968, 35.170361547000027],
            [-80.879389384999968, 35.170221760000061],
            [-80.879384011999946, 35.17008379400005],
            [-80.879381956999964, 35.170048610000038],
            [-80.879368527999986, 35.169876092000038],
            [-80.879328795999982, 35.169267482000066],
            [-80.879327724999939, 35.169252872000072],
            [-80.879305889999955, 35.168984850000072],
            [-80.879290625999943, 35.168773527000042],
            [-80.879288197999983, 35.168745812000054],
            [-80.879280349999988, 35.168687863000059],
            [-80.879248705999942, 35.168499566000037],
            [-80.87922522599996, 35.168155465000041],
            [-80.879224990999944, 35.168152099000054],
            [-80.879178206999939, 35.167501965000042],
            [-80.879177443999936, 35.167492103000029],
            [-80.879145530999949, 35.167107110000074],
            [-80.879137976999971, 35.166905349000046],
            [-80.879134550999936, 35.166675622000071],
            [-80.879134410999939, 35.166668230000028],
            [-80.87913090699999, 35.166606759000047],
            [-80.87910372999994, 35.166308165000032],
            [-80.879079532999981, 35.166035699000076],
            [-80.87906425999995, 35.165830579000044],
            [-80.879062257999976, 35.165807627000049],
            [-80.879009302999975, 35.165277722000042],
            [-80.878990120999958, 35.164748804000055],
            [-80.878989378999961, 35.164669310000079],
            [-80.878992516999972, 35.164614765000067],
            [-80.879005479999989, 35.164493847000074],
            [-80.879030692999947, 35.164321011000027],
            [-80.879080301999977, 35.164074724000045],
            [-80.879142251999951, 35.163821680000069],
            [-80.879143062999958, 35.163818339000045],
            [-80.879228072999979, 35.163465477000045],
            [-80.879228791999935, 35.163462471000059],
            [-80.879297636999979, 35.163172488000043],
            [-80.879342455999961, 35.162991652000073],
            [-80.879348368999956, 35.162966279000045],
            [-80.879349378999962, 35.162961594000024],
            [-80.879365231999941, 35.16288714500007],
            [-80.879374116999941, 35.162839260000055],
            [-80.879384667999943, 35.162772623000023],
            [-80.879401933999986, 35.162700003000054],
            [-80.879490722999947, 35.162358307000034],
            [-80.879547543999934, 35.162145014000032],
            [-80.879595050999967, 35.16197777900004],
            [-80.879607313999941, 35.161947349000059],
            [-80.879611995999937, 35.161935507000067],
            [-80.879623291999962, 35.161904852000077],
            [-80.879649189999952, 35.161830705000057],
            [-80.879661865999935, 35.161791748000041],
            [-80.879673520999972, 35.161749603000032],
            [-80.879699322999954, 35.161647062000043],
            [-80.879706043999988, 35.161618457000031],
            [-80.87971007699997, 35.161598981000054],
            [-80.879730287999962, 35.161495838000064],
            [-80.879738954999937, 35.161443727000062],
            [-80.879739187999974, 35.161442013000055],
            [-80.879746027999943, 35.161391344000037],
            [-80.87975217099995, 35.161349838000035],
            [-80.879753573999949, 35.161339984000051],
            [-80.879757346999952, 35.161308331000043],
            [-80.879761894999945, 35.161263543000075],
            [-80.879787946999954, 35.161107149000031],
            [-80.879791600999965, 35.161083283000039],
            [-80.879798780999977, 35.161031871000034],
            [-80.879799120999962, 35.161029417000066],
            [-80.879806093999946, 35.160957303000032],
            [-80.879806766999934, 35.160943604000067],
            [-80.87980891899997, 35.160891814000024],
            [-80.87980945999999, 35.160833177000029],
            [-80.879808309999987, 35.160803851000026],
            [-80.879805443999942, 35.160752138000078],
            [-80.879802045999952, 35.160709219000069],
            [-80.87979397099997, 35.160649923000051],
            [-80.879785255999934, 35.160598530000073],
            [-80.87978303899996, 35.160585980000064],
            [-80.879767045999984, 35.160514840000076],
            [-80.879765979999945, 35.160510870000053],
            [-80.879752260999965, 35.160460242000056],
            [-80.879739891999975, 35.16041844700004],
            [-80.879734182999982, 35.160400673000026],
            [-80.879725993999955, 35.160376309000071],
            [-80.879723032999948, 35.160367994000069],
            [-80.879616096999939, 35.160072167000067],
            [-80.879610249999985, 35.160056422000025],
            [-80.879499166999949, 35.159765094000079],
            [-80.879383096999959, 35.159444560000054],
            [-80.879382348999968, 35.159442504000026],
            [-80.879271312999947, 35.159138038000037],
            [-80.879267901999981, 35.159128828000064],
            [-80.879093818999934, 35.158666175000064],
            [-80.879091653999978, 35.158660475000033],
            [-80.878941230999942, 35.158268218000046],
            [-80.878934524999977, 35.158251214000074],
            [-80.878818843999966, 35.157965747000048],
            [-80.878752700999939, 35.157775748000063],
            [-80.878751861999945, 35.157773347000045],
            [-80.87874575799998, 35.157756466000023],
            [-80.878571640999951, 35.157288665000067],
            [-80.878477198999974, 35.15702159600005],
            [-80.878455690999942, 35.156957522000027],
            [-80.878442245999963, 35.156909381000048],
            [-80.878431068999987, 35.156859108000049],
            [-80.878425162999974, 35.156824784000037],
            [-80.878423314999964, 35.156801689000076],
            [-80.878428536999934, 35.156773180000073],
            [-80.878458252999962, 35.156572779000044],
            [-80.878502397999966, 35.156328808000069],
            [-80.87850412399996, 35.156318962000057],
            [-80.87854568399996, 35.156074139000054],
            [-80.878614151999955, 35.155704636000053],
            [-80.878657527999962, 35.155500357000051],
            [-80.878718219999939, 35.155227264000075],
            [-80.878718966999941, 35.155223880000051],
            [-80.878723061999949, 35.155204086000026],
            [-80.878770459999942, 35.154961737000065],
            [-80.878772347999984, 35.154951795000045],
            [-80.878824538999936, 35.154668493000031],
            [-80.87888004399997, 35.154425790000062],
            [-80.878940046999958, 35.154167689000076],
            [-80.878940353999951, 35.15416636100008],
            [-80.879001341999981, 35.153902267000035],
            [-80.87904438399994, 35.153718835000063],
            [-80.879045516999952, 35.15371394400006],
            [-80.879119632999959, 35.15339024900004],
            [-80.879198546999987, 35.153058460000068],
            [-80.879200391999973, 35.153050550000046],
            [-80.879252573999963, 35.152822325000045],
            [-80.879254087999982, 35.152815585000042],
            [-80.879323316999944, 35.152502096000035],
            [-80.879366476999962, 35.15232341400008],
            [-80.879368714999941, 35.152313933000073],
            [-80.879445268999973, 35.151981813000077],
            [-80.879447631999938, 35.151971287000038],
            [-80.879448753999952, 35.151966074000029],
            [-80.879472900999986, 35.151852336000047],
            [-80.879476458999989, 35.151834736000069],
            [-80.879513305999978, 35.151642935000041],
            [-80.879518168999937, 35.151615367000034],
            [-80.879548386999943, 35.151427421000051],
            [-80.879590171999951, 35.151183592000052],
            [-80.879625258999965, 35.15098535900006],
            [-80.879630181999971, 35.150954508000041],
            [-80.879668479999964, 35.150685062000036],
            [-80.879710859999989, 35.150397415000043],
            [-80.879756105999945, 35.150096450000035],
            [-80.87980108499994, 35.149811106000072],
            [-80.879803344999971, 35.149795949000065],
            [-80.879826888999958, 35.149628452000059],
            [-80.879863459999967, 35.149413528000025],
            [-80.879867216999969, 35.149389536000058],
            [-80.879918152999949, 35.149033520000046],
            [-80.879972011999939, 35.14872869900006],
            [-80.879975347999959, 35.148708507000038],
            [-80.88001341599994, 35.148461036000072],
            [-80.880014001999939, 35.148457181000026],
            [-80.880104765999988, 35.147849993000079],
            [-80.880160354999987, 35.14748052300007],
            [-80.880207666999979, 35.147172006000062],
            [-80.880223227999977, 35.147079149000035],
            [-80.880254886999978, 35.146936298000071],
            [-80.880255519999935, 35.146933420000039],
            [-80.880268217999969, 35.14686183300006],
            [-80.880275189999963, 35.146789718000036],
            [-80.880275367999957, 35.14678648000006],
            [-80.880282558999966, 35.146651019000046],
            [-80.880283820999978, 35.146608165000032],
            [-80.880284383999935, 35.14651174100004],
            [-80.880284156999949, 35.146485498000061],
            [-80.880281611999976, 35.146435331000077],
            [-80.880273318999969, 35.146329655000045],
            [-80.880271316999938, 35.146307577000073],
            [-80.880271060999974, 35.146305118000043],
            [-80.880261076999943, 35.146210689000043],
            [-80.880253789999983, 35.14615701300005],
            [-80.880238245999976, 35.14606335700006],
            [-80.88023549899998, 35.146047646000056],
            [-80.880228421999959, 35.146012905000077],
            [-80.880207592999966, 35.14591979100004],
            [-80.880198679999978, 35.145883391000041],
            [-80.880196312999942, 35.145874671000058],
            [-80.880172459999983, 35.145788421000077],
            [-80.880067542999939, 35.145383974000026],
            [-80.880066021999937, 35.145378184000037],
            [-80.880064000999937, 35.145370721000063],
            [-80.879937793999943, 35.144911895000064],
            [-80.879812763999951, 35.144408049000049],
            [-80.879799399999968, 35.144324223000069],
            [-80.879795712999965, 35.144302743000026],
            [-80.879783583999938, 35.144246599000041],
            [-80.879611179999984, 35.143553069000063],
            [-80.879610758999945, 35.143551383000045],
            [-80.879461946999982, 35.142957466000041],
            [-80.879458507999971, 35.142944158000034],
            [-80.879436888999976, 35.142874029000041],
            [-80.879409731999942, 35.142805212000042],
            [-80.879377154999986, 35.142738001000055],
            [-80.879339294999966, 35.142672685000036],
            [-80.879296315999966, 35.142609542000059],
            [-80.879248399999938, 35.142548843000043],
            [-80.879195754999955, 35.142490848000079],
            [-80.87913860499998, 35.142435805000048],
            [-80.879077194999979, 35.142383951000056],
            [-80.879011787999957, 35.142335506000052],
            [-80.878942662999975, 35.142290680000031],
            [-80.878870116999963, 35.14224966300003],
            [-80.878794460999984, 35.142212631000064],
            [-80.878716017999977, 35.142179743000042],
            [-80.87863512399997, 35.142151139000077],
            [-80.878552126999978, 35.142126942000061],
            [-80.878467379999961, 35.142107256000031],
            [-80.878381245999947, 35.142092165000065],
            [-80.87829409699998, 35.142081734000044],
            [-80.878206302999956, 35.142076006000025],
            [-80.878118240999981, 35.142075007000074]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 84,
        "SolutionID": "PI-27:B",
        "Shape_Length": 0.28476750254692107,
        "Shape_Area": 0.000354533866463267
      }
    },
    {
      "type": "Feature",
      "id": 85,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.870346668999957, 35.187505619000035],
            [-80.870351973999959, 35.187509790000036],
            [-80.870587461999946, 35.187693941000077],
            [-80.870647595999969, 35.18773821700006],
            [-80.870678956999939, 35.187759295000035],
            [-80.870751779999978, 35.187806758000079],
            [-80.870822380999982, 35.187855145000071],
            [-80.870880023999973, 35.187892509000051],
            [-80.870967169999972, 35.187945895000041],
            [-80.870973036999942, 35.187949469000046],
            [-80.871018448999962, 35.187975804000075],
            [-80.871117628999968, 35.188030836000053],
            [-80.871144799999968, 35.188045522000039],
            [-80.871180722999952, 35.188063787000033],
            [-80.87126395699994, 35.188104637000038],
            [-80.871303728999976, 35.18812340900007],
            [-80.871322045999989, 35.188131565000049],
            [-80.871403998999938, 35.188167388000068],
            [-80.871617930999946, 35.18826556700003],
            [-80.87183134299994, 35.188367688000028],
            [-80.871979530999965, 35.188441495000063],
            [-80.872012157999961, 35.188457234000055],
            [-80.872327576999965, 35.188604539000039],
            [-80.872582407999971, 35.188723877000029],
            [-80.872951565999983, 35.188902392000045],
            [-80.873339184999963, 35.18909082700003],
            [-80.873624831999962, 35.189230197000029],
            [-80.873897076999981, 35.189369788000079],
            [-80.873959261999971, 35.189399802000025],
            [-80.873966110999959, 35.189402887000028],
            [-80.874146225999937, 35.189483457000051],
            [-80.874174941999968, 35.189495935000025],
            [-80.87472567399999, 35.189728321000075],
            [-80.874735330999954, 35.189732356000036],
            [-80.874765748999948, 35.189744938000047],
            [-80.874792318999937, 35.189755630000036],
            [-80.87480752099998, 35.18976157700007],
            [-80.874814213999969, 35.189764178000075],
            [-80.874834504999967, 35.189771836000034],
            [-80.874849980999954, 35.189777551000077],
            [-80.874872627999935, 35.189785709000034],
            [-80.874903845999938, 35.189796675000025],
            [-80.874941846999945, 35.189809464000064],
            [-80.874945491999938, 35.189810633000036],
            [-80.87496122899995, 35.189815657000054],
            [-80.874988328999962, 35.189824035000072],
            [-80.875004340999965, 35.189828824000074],
            [-80.875033577999943, 35.189837257000079],
            [-80.875065585999948, 35.18984615100004],
            [-80.875088647999974, 35.189852371000029],
            [-80.87509909399995, 35.189855063000039],
            [-80.875131365999948, 35.189863265000042],
            [-80.875178327999947, 35.189874438000061],
            [-80.875211134999972, 35.18988171500007],
            [-80.87523852399994, 35.18988753800005],
            [-80.875268294999955, 35.189893301000041],
            [-80.875301631999946, 35.189899426000068],
            [-80.87534328199996, 35.189906514000029],
            [-80.875366919999976, 35.18991021800008],
            [-80.875488882999946, 35.189930631000038],
            [-80.875520183999981, 35.189935554000044],
            [-80.875561479999988, 35.189941091000037],
            [-80.875569871999971, 35.189942107000036],
            [-80.87561577799994, 35.189947003000043],
            [-80.875681365999981, 35.189951730000075],
            [-80.875701755999955, 35.189952788000028],
            [-80.875724013999957, 35.189953790000061],
            [-80.875812126999961, 35.189954790000058],
            [-80.875828578999972, 35.189954535000027],
            [-80.875916583999981, 35.189950803000045],
            [-80.875938778999966, 35.189949111000033],
            [-80.875959106999971, 35.189947421000056],
            [-80.876020852999943, 35.189941103000024],
            [-80.876029475999985, 35.189940054000033],
            [-80.876033052999958, 35.189939614000025],
            [-80.876093997999988, 35.189930883000045],
            [-80.876101779999942, 35.189929616000029],
            [-80.876127491999966, 35.189925217000052],
            [-80.876174091999985, 35.189916137000068],
            [-80.876194080999937, 35.189911933000076],
            [-80.876232908999953, 35.189903257000026],
            [-80.876283180999962, 35.189890479000042],
            [-80.876291221999963, 35.189888293000024],
            [-80.876324778999958, 35.189878767000039],
            [-80.876344703999962, 35.189872721000029],
            [-80.876356336999947, 35.189869105000071],
            [-80.876418284999943, 35.18984839400008],
            [-80.876437608999936, 35.189841320000028],
            [-80.876456139999959, 35.189834393000069],
            [-80.876516379999941, 35.189810371000078],
            [-80.876535182999987, 35.189802237000038],
            [-80.876545952999948, 35.189797489000057],
            [-80.876603622999937, 35.18977054100003],
            [-80.876610795999966, 35.18976699500007],
            [-80.876611240999978, 35.189766774000077],
            [-80.87667010399997, 35.189735906000067],
            [-80.876680294999971, 35.189730252000061],
            [-80.876695371999972, 35.189721764000069],
            [-80.876725319999935, 35.189704151000058],
            [-80.876731922999966, 35.189700155000025],
            [-80.876771669999982, 35.189675148000049],
            [-80.876784588999953, 35.189666703000057],
            [-80.876785539999958, 35.189666080000052],
            [-80.876852589999942, 35.189619138000069],
            [-80.87686913899995, 35.189606552000043],
            [-80.876880923999977, 35.189597437000032],
            [-80.87692753999994, 35.189559583000062],
            [-80.876938418999941, 35.189550198000063],
            [-80.876949904999947, 35.189540173000069],
            [-80.876998037999954, 35.189495833000024],
            [-80.877014046999989, 35.189479966000079],
            [-80.877024407999954, 35.18946949900004],
            [-80.877063004999968, 35.189428588000055],
            [-80.877071730999944, 35.189418764000038],
            [-80.877079069999979, 35.189410406000036],
            [-80.877120309999952, 35.189360640000075],
            [-80.877121152999962, 35.18935956100006],
            [-80.877125486999944, 35.189353997000069],
            [-80.877164425999979, 35.189300778000074],
            [-80.877172522999956, 35.189288972000043],
            [-80.877177851999988, 35.189281128000061],
            [-80.877196497999989, 35.189252429000078],
            [-80.87719838299995, 35.189249422000046],
            [-80.877198598999939, 35.189249076000067],
            [-80.87720236499996, 35.189243063000049],
            [-80.877223565999941, 35.189207675000034],
            [-80.877240448999942, 35.189176578000058],
            [-80.877324123999983, 35.189038995000033],
            [-80.877333447999945, 35.189034082000035],
            [-80.877375308999945, 35.189011110000024],
            [-80.877428813999984, 35.18897890900007],
            [-80.877479955999945, 35.18894651100004],
            [-80.877497095999956, 35.188935470000047],
            [-80.877558506999947, 35.188892720000069],
            [-80.877605960999972, 35.188857631000076],
            [-80.877611598999977, 35.188853438000024],
            [-80.877672334999943, 35.188805063000075],
            [-80.877725143999953, 35.188760272000025],
            [-80.877727572999959, 35.188758206000045],
            [-80.877786583999978, 35.188704481000059],
            [-80.877788444999965, 35.18870266600004],
            [-80.877837280999984, 35.188654960000065],
            [-80.877890023999953, 35.18859999600005],
            [-80.877893539999945, 35.18859606500007],
            [-80.877938407999977, 35.188545670000053],
            [-80.877984855999955, 35.188490012000045],
            [-80.877992977999952, 35.188479483000037],
            [-80.878033052999967, 35.188426875000062],
            [-80.878070040999944, 35.188375260000043],
            [-80.878110102999983, 35.188310827000066],
            [-80.87811499899999, 35.188302176000036],
            [-80.87836757499997, 35.187850771000058],
            [-80.878429328999971, 35.187742130000061],
            [-80.878465533999986, 35.187689027000033],
            [-80.878469198999937, 35.187683617000062],
            [-80.878509259999987, 35.187619184000027],
            [-80.878520285999969, 35.187599416000069],
            [-80.878609131999951, 35.187435893000043],
            [-80.878632947999961, 35.187389215000053],
            [-80.878662420999945, 35.187321040000029],
            [-80.878686400999982, 35.187251428000025],
            [-80.878704782999989, 35.187180679000051],
            [-80.878717489999985, 35.187109093000061],
            [-80.878724465999937, 35.187036978000037],
            [-80.878725680999935, 35.186964643000067],
            [-80.878721132999942, 35.186892398000055],
            [-80.878710836999971, 35.186820552000029],
            [-80.87869483999998, 35.18674941200004],
            [-80.878673209999988, 35.186679284000036],
            [-80.878646038999989, 35.186610467000037],
            [-80.878613444999985, 35.186543257000039],
            [-80.878575565999938, 35.186477940000032],
            [-80.878532563999954, 35.186414797000054],
            [-80.878484622999963, 35.186354099000027],
            [-80.878431949999936, 35.186296104000064],
            [-80.878374769999937, 35.186241061000032],
            [-80.87831332799999, 35.186189207000041],
            [-80.87824788599994, 35.186140762000036],
            [-80.878178723999952, 35.186095935000026],
            [-80.878106138999954, 35.186054918000025],
            [-80.878030442999943, 35.186017886000059],
            [-80.877951957999983, 35.185984997000048],
            [-80.877871020999976, 35.185956393000026],
            [-80.877787978999947, 35.185932196000067],
            [-80.877703186999952, 35.185912509000048],
            [-80.877617006999969, 35.185897418000025],
            [-80.877529810999988, 35.185886986000071],
            [-80.87744196999995, 35.185881258000052],
            [-80.87735386099996, 35.185880258000054],
            [-80.877265860999955, 35.185883993000061],
            [-80.877178346999983, 35.185892444000046],
            [-80.877091691999965, 35.185905575000049],
            [-80.877006269999981, 35.185923331000026],
            [-80.87692244599998, 35.185945636000042],
            [-80.876840576999939, 35.185972394000032],
            [-80.876761015999989, 35.186003490000076],
            [-80.876684101999956, 35.186038792000033],
            [-80.876610165999978, 35.186078147000046],
            [-80.876539521999973, 35.186121388000061],
            [-80.876472474999957, 35.186168330000044],
            [-80.876409310999975, 35.186218772000075],
            [-80.876350299999956, 35.186272496000072],
            [-80.876295695999943, 35.186329275000048],
            [-80.876245731999973, 35.186388863000047],
            [-80.876200621999942, 35.186451007000073],
            [-80.87616055999996, 35.186515440000051],
            [-80.876149533999978, 35.186535208000066],
            [-80.876085582999963, 35.186652903000038],
            [-80.87605499099999, 35.186697769000034],
            [-80.87605132799996, 35.186703179000062],
            [-80.876011264999988, 35.18676761200004],
            [-80.87600976799996, 35.18677023500004],
            [-80.87592531599995, 35.186918802000037],
            [-80.875921916999971, 35.186924829000077],
            [-80.875746588999959, 35.187238156000035],
            [-80.875744615999963, 35.187239152000075],
            [-80.875680024999951, 35.187273838000067],
            [-80.875658107999982, 35.187286627000049],
            [-80.875604445999954, 35.187318606000076],
            [-80.875555717999987, 35.187349058000052],
            [-80.875513508999973, 35.187377879000053],
            [-80.875463829999944, 35.18741323100005],
            [-80.875438989999964, 35.187431351000043],
            [-80.875392654999985, 35.187467697000045],
            [-80.875349031999974, 35.187503584000069],
            [-80.875289863999967, 35.187477117000071],
            [-80.87503894699995, 35.187348463000035],
            [-80.875013587999945, 35.187335778000033],
            [-80.874714249999954, 35.187189728000078],
            [-80.874712386999988, 35.187188820000074],
            [-80.874322500999938, 35.186999286000059],
            [-80.874319819999982, 35.186997986000051],
            [-80.87394124299999, 35.186814917000049],
            [-80.873925011999972, 35.18680719200006],
            [-80.87366134399997, 35.18668371800004],
            [-80.873659956999973, 35.186683068000036],
            [-80.873360294999941, 35.186543124000025],
            [-80.873218164999969, 35.186472335000076],
            [-80.873197814999969, 35.186462400000039],
            [-80.872963704999961, 35.186350375000075],
            [-80.87294264999997, 35.186340506000079],
            [-80.872706107999988, 35.186231952000071],
            [-80.872700227999985, 35.186229270000069],
            [-80.872681909999983, 35.186221113000045],
            [-80.872617243999969, 35.18619284600004],
            [-80.872594753999977, 35.186181809000061],
            [-80.872552628999983, 35.186158435000038],
            [-80.872520139999949, 35.186138533000076],
            [-80.872465013999943, 35.186100753000062],
            [-80.872439300999986, 35.186083566000036],
            [-80.87240103299996, 35.186058626000033],
            [-80.872227090999957, 35.185922607000066],
            [-80.871970337999983, 35.185706800000048],
            [-80.87168070499996, 35.185462564000034],
            [-80.871454202999985, 35.18526825500004],
            [-80.871452320999936, 35.18526664500007],
            [-80.871181155999977, 35.185034957000028],
            [-80.871170363999966, 35.185025841000027],
            [-80.870940599999983, 35.184833993000041],
            [-80.870435215999976, 35.184406297000066],
            [-80.870252434999941, 35.184243228000071],
            [-80.870237444999987, 35.184230071000059],
            [-80.870123322999973, 35.18413152800008],
            [-80.870120184999962, 35.184128827000052],
            [-80.869996121999975, 35.184022422000055],
            [-80.869983801999979, 35.184011994000059],
            [-80.869790256999977, 35.183850302000053],
            [-80.869525965999969, 35.183627510000065],
            [-80.869302679999976, 35.183435959000064],
            [-80.869298266999976, 35.183432191000065],
            [-80.868979063999973, 35.183160935000046],
            [-80.868972800999984, 35.183155648000024],
            [-80.868573763999962, 35.182821042000057],
            [-80.868568496999956, 35.182816651000053],
            [-80.868339100999947, 35.18262642600007],
            [-80.868137212999954, 35.182452184000056],
            [-80.868125421999935, 35.182442135000031],
            [-80.867890927999952, 35.182244799000046],
            [-80.867874545999939, 35.182230769000057],
            [-80.867867626999953, 35.182224886000029],
            [-80.867631250999978, 35.182025402000079],
            [-80.86762327699995, 35.182018729000049],
            [-80.867369162999978, 35.181807871000046],
            [-80.867362294999964, 35.181802059000063],
            [-80.86763887699999, 35.181527272000039],
            [-80.867753367999967, 35.181414344000075],
            [-80.867925629999945, 35.18125450000008],
            [-80.867929253999989, 35.181251124000028],
            [-80.867964598999947, 35.181218057000024],
            [-80.868030454999939, 35.181159821000051],
            [-80.868094361999965, 35.181107290000057],
            [-80.868155754999975, 35.181060526000067],
            [-80.868350946999954, 35.180924970000035],
            [-80.868586134999987, 35.180766229000028],
            [-80.868780536999964, 35.180639370000051],
            [-80.868782381999949, 35.180638164000072],
            [-80.868790932999957, 35.18063251600006],
            [-80.868954120999945, 35.180523845000039],
            [-80.869289605999938, 35.180302116000064],
            [-80.869291153999939, 35.180301091000047],
            [-80.869755219999945, 35.179993448000062],
            [-80.869775417999961, 35.179979789000072],
            [-80.869944566999948, 35.179863124000065],
            [-80.869955428999958, 35.17985555100006],
            [-80.869993309999984, 35.179828858000064],
            [-80.870023066999977, 35.179807254000025],
            [-80.870060508999984, 35.179778158000033],
            [-80.870107063999967, 35.179740566000078],
            [-80.870132784999953, 35.179719224000053],
            [-80.870184706999964, 35.179672340000025],
            [-80.870227285999988, 35.179631605000054],
            [-80.870234373999949, 35.179624768000053],
            [-80.870276972999989, 35.179581146000032],
            [-80.870316168999977, 35.179538860000036],
            [-80.870328173999951, 35.179525706000049],
            [-80.870378137999978, 35.179466120000029],
            [-80.870397878999938, 35.179440055000043],
            [-80.870432231999985, 35.179393262000076],
            [-80.870457601999988, 35.179357185000072],
            [-80.87046767999999, 35.179341926000063],
            [-80.87049811899999, 35.179294964000064],
            [-80.870528104999948, 35.179245792000074],
            [-80.870562949999965, 35.179179348000048],
            [-80.870571437999956, 35.179161112000031],
            [-80.870594534999952, 35.179110143000059],
            [-80.870615523999959, 35.179060206000031],
            [-80.870623284999965, 35.179039509000063],
            [-80.87063928699996, 35.178995291000035],
            [-80.870655509999949, 35.178946378000035],
            [-80.870659933999946, 35.178931271000067],
            [-80.870676448999973, 35.178873073000034],
            [-80.870690412999977, 35.178817431000027],
            [-80.870694739999976, 35.178796470000066],
            [-80.870706214999984, 35.178737437000052],
            [-80.870714600999975, 35.178686814000059],
            [-80.870719052999959, 35.178648266000039],
            [-80.870724657999972, 35.17858863400005],
            [-80.870727188999979, 35.178555068000037],
            [-80.870727306999981, 35.178552942000067],
            [-80.870733584999982, 35.178437650000035],
            [-80.87073481799996, 35.178402393000056],
            [-80.870736597999951, 35.178288107000071],
            [-80.870741021999947, 35.178056845000071],
            [-80.870747589999951, 35.178012065000075],
            [-80.870755429999974, 35.17797415800004],
            [-80.870765290999941, 35.17793710400008],
            [-80.870776578999937, 35.177902810000035],
            [-80.870791192999945, 35.177866063000067],
            [-80.870808711999985, 35.177827771000068],
            [-80.870818940999982, 35.177808262000042],
            [-80.870911532999969, 35.177664168000035],
            [-80.871122082999989, 35.177350349000051],
            [-80.871318431999953, 35.177062011000032],
            [-80.871570036999969, 35.17669791000003],
            [-80.871914452999988, 35.176200679000033],
            [-80.871916978999934, 35.17619701600006],
            [-80.872208089999958, 35.175772987000073],
            [-80.872416784999984, 35.175472099000046],
            [-80.872552520999989, 35.175303382000038],
            [-80.872562495999944, 35.175290798000049],
            [-80.872581967999963, 35.175265097000079],
            [-80.872791307999989, 35.174980165000079],
            [-80.872816942999975, 35.174943724000059],
            [-80.872847564999972, 35.174895439000068],
            [-80.873047023999959, 35.174561101000052],
            [-80.873159789999988, 35.174372654000024],
            [-80.87327660699998, 35.174188444000038],
            [-80.873300967999967, 35.174148039000045],
            [-80.873304164999979, 35.174142410000059],
            [-80.87341725999994, 35.173941873000047],
            [-80.873436072999937, 35.173906942000031],
            [-80.873481724999976, 35.173818063000056],
            [-80.87349455399999, 35.173792178000042],
            [-80.873494974999971, 35.173791294000068],
            [-80.873526638999977, 35.173724847000074],
            [-80.873555690999979, 35.17365755700007],
            [-80.873556285999939, 35.173656020000067],
            [-80.873582608999982, 35.173587825000027],
            [-80.87359936699994, 35.173540815000024],
            [-80.873636895999937, 35.17342618400005],
            [-80.873643521999952, 35.173405120000041],
            [-80.873651468999981, 35.173377249000055],
            [-80.873670737999987, 35.173305497000058],
            [-80.873672737999982, 35.173297920000039],
            [-80.873695540999961, 35.173210084000061],
            [-80.87370397799998, 35.173174782000046],
            [-80.873706793999986, 35.173161416000028],
            [-80.873724333999974, 35.173075033000032],
            [-80.873729801999957, 35.17304568000003],
            [-80.873742572999959, 35.172970367000062],
            [-80.873746996999955, 35.172941500000036],
            [-80.873751076999952, 35.172906803000046],
            [-80.873758535999968, 35.172830882000028],
            [-80.873761434999949, 35.172793464000051],
            [-80.87376261899999, 35.172764380000046],
            [-80.873764494999989, 35.172688320000077],
            [-80.873764530999949, 35.172645069000055],
            [-80.873760037999944, 35.172573323000051],
            [-80.873748168999953, 35.172457396000027],
            [-80.87374811799998, 35.172456896000028],
            [-80.873737828999936, 35.172385050000059],
            [-80.873735119999935, 35.172370932000035],
            [-80.873721883999963, 35.172304723000025],
            [-80.873708602999955, 35.172247700000071],
            [-80.873707027999956, 35.172241861000032],
            [-80.873687841999981, 35.172171621000075],
            [-80.873668315999964, 35.172108820000062],
            [-80.873644131999981, 35.172039575000042],
            [-80.873643610999977, 35.172038085000054],
            [-80.873623437999981, 35.171985577000044],
            [-80.873603663999972, 35.171938250000039],
            [-80.873596674999987, 35.171921939000072],
            [-80.873572324999941, 35.171870550000051],
            [-80.87354282199999, 35.171812609000028],
            [-80.873534587999984, 35.171796786000073],
            [-80.873506328999952, 35.171747034000077],
            [-80.873472407999941, 35.171690992000038],
            [-80.873462798999981, 35.171675425000046],
            [-80.873425088999966, 35.171619534000058],
            [-80.873386198999981, 35.171565632000068],
            [-80.873380919999988, 35.171558378000043],
            [-80.873357619999979, 35.171527781000066],
            [-80.873351010999954, 35.17151941000003],
            [-80.873335527999984, 35.171500237000032],
            [-80.873287904999984, 35.171442576000061],
            [-80.873278760999938, 35.171431645000041],
            [-80.873235277999981, 35.171383200000037],
            [-80.873182971999938, 35.171328130000063],
            [-80.873173794999957, 35.171318578000069],
            [-80.873135069999989, 35.171280503000048],
            [-80.873079195999935, 35.171228007000025],
            [-80.873060754999983, 35.171211037000035],
            [-80.873014934999958, 35.171171724000033],
            [-80.872954675999949, 35.171122505000028],
            [-80.872939067999937, 35.17110996200006],
            [-80.872895451999966, 35.171076963000075],
            [-80.872831635999944, 35.17103077400003],
            [-80.87280982499999, 35.171015325000042],
            [-80.872768984999936, 35.17098812100005],
            [-80.872701896999956, 35.170945190000054],
            [-80.872673591999956, 35.170927563000077],
            [-80.872633488999952, 35.17090418600003],
            [-80.87256313499995, 35.17086474000007],
            [-80.872530669999946, 35.170847096000045],
            [-80.872491778999972, 35.17082737700008],
            [-80.872423407999975, 35.170794011000055],
            [-80.871997181999973, 35.170582387000024],
            [-80.871288935999985, 35.170229298000038],
            [-80.871263788999954, 35.170217066000077],
            [-80.871228262999978, 35.170200210000075],
            [-80.871205755999938, 35.170189767000068],
            [-80.871176994999985, 35.170177089000049],
            [-80.871108828999979, 35.17014791400004],
            [-80.871059122999952, 35.170127698000044],
            [-80.871017818999974, 35.170112450000033],
            [-80.870947522999984, 35.170087660000036],
            [-80.870907908999982, 35.170074298000031],
            [-80.870866188999969, 35.17006150900005],
            [-80.870793747999983, 35.170040416000063],
            [-80.870758604999935, 35.170030628000063],
            [-80.870660949999944, 35.17000465700005],
            [-80.870654788999957, 35.170003032000068],
            [-80.870588487999953, 35.169987215000049],
            [-80.870493468999939, 35.169966700000032],
            [-80.870474995999984, 35.169962825000027],
            [-80.870419564999963, 35.169952559000023],
            [-80.870322972999986, 35.169936419000067],
            [-80.870292242999938, 35.169931589000043],
            [-80.87025823099998, 35.169926948000068],
            [-80.87016089499997, 35.169914713000026],
            [-80.870107728999983, 35.169908917000043],
            [-80.870085249999988, 35.169906996000066],
            [-80.869987734999938, 35.169899344000044],
            [-80.869922390999989, 35.169895532000055],
            [-80.869873213999938, 35.169894387000056],
            [-80.86971787799996, 35.16989310200006],
            [-80.869700983999962, 35.169873408000058],
            [-80.869652770999949, 35.169820620000053],
            [-80.869595607999941, 35.169765573000063],
            [-80.869534182999985, 35.169713714000068],
            [-80.869468759999961, 35.169665265000049],
            [-80.869399616999942, 35.169620433000034],
            [-80.869327050999971, 35.16957941000004],
            [-80.869251373999987, 35.16954237300007],
            [-80.869172908999985, 35.169509478000066],
            [-80.869091991999937, 35.169480868000051],
            [-80.869008968999935, 35.169456664000052],
            [-80.868924195999966, 35.169436972000028],
            [-80.868838034999953, 35.169421874000079],
            [-80.868750857999942, 35.169411436000075],
            [-80.86866303499994, 35.169405701000073],
            [-80.868574943999988, 35.169404695000026],
            [-80.868486960999974, 35.16940842300005],
            [-80.868399463999936, 35.169416868000042],
            [-80.868312825999965, 35.169429993000051],
            [-80.868227418999936, 35.169447743000035],
            [-80.868143608999958, 35.169470041000068],
            [-80.868061753999939, 35.169496794000054],
            [-80.867982205999965, 35.169527884000047],
            [-80.867905303999976, 35.169563180000068],
            [-80.867831377999948, 35.169602530000077],
            [-80.867760743999952, 35.169645767000077],
            [-80.867693704999965, 35.169692704000056],
            [-80.867630548999955, 35.169743141000026],
            [-80.86757154299994, 35.169796861000066],
            [-80.867516943999988, 35.169853636000028],
            [-80.867466983999975, 35.169913221000058],
            [-80.86742187699997, 35.16997536100007],
            [-80.867381815999977, 35.170039792000068],
            [-80.86734697199995, 35.170106235000048],
            [-80.867317493999963, 35.170174407000047],
            [-80.867293509999968, 35.170244016000026],
            [-80.867275121999967, 35.170314765000057],
            [-80.867262408999977, 35.170386350000058],
            [-80.86725542399995, 35.170458464000035],
            [-80.867254196999966, 35.170530798000073],
            [-80.86725873599994, 35.170603044000075],
            [-80.867269018999934, 35.170674891000033],
            [-80.867285002999949, 35.170746033000057],
            [-80.867306617999986, 35.17081616300004],
            [-80.867333773999974, 35.170884983000064],
            [-80.867366353999955, 35.170952197000076],
            [-80.867404216999944, 35.171017517000053],
            [-80.867447201999937, 35.171080664000044],
            [-80.867495123999959, 35.171141367000075],
            [-80.867499566999982, 35.171146578000048],
            [-80.867667242999971, 35.171342061000075],
            [-80.867715455999985, 35.17139484900008],
            [-80.867725587999985, 35.171405151000044],
            [-80.867785539999943, 35.171465372000057],
            [-80.867832570999951, 35.171510119000061],
            [-80.867893996999953, 35.171561978000057],
            [-80.86790708999996, 35.171572193000031],
            [-80.867960749999952, 35.171613502000071],
            [-80.868013081999948, 35.171651736000058],
            [-80.868031616999986, 35.171664354000029],
            [-80.868088547999946, 35.171702408000044],
            [-80.868139155999984, 35.171734623000077],
            [-80.868166939999981, 35.171751013000062],
            [-80.868227139999988, 35.171785581000051],
            [-80.868271922999952, 35.171810214000061],
            [-80.868310504999954, 35.171829786000046],
            [-80.868373688999952, 35.17186064200007],
            [-80.86841078599997, 35.171878108000044],
            [-80.868453740999939, 35.171896773000071],
            [-80.868519068999944, 35.171923932000027],
            [-80.868554581999945, 35.17193816200006],
            [-80.868635500999972, 35.171966772000076],
            [-80.868655906999948, 35.171973189000028],
            [-80.868716298999971, 35.17199172100004],
            [-80.868778917999975, 35.172009508000031],
            [-80.868842175999987, 35.172024666000027],
            [-80.868912813999941, 35.172040063000054],
            [-80.868934331999981, 35.172044597000024],
            [-80.868947852999952, 35.172047289000034],
            [-80.869008926999982, 35.172059170000068],
            [-80.869081567999956, 35.172071577000054],
            [-80.869125017999977, 35.172077377000051],
            [-80.869185990999938, 35.172084679000079],
            [-80.869226124999955, 35.172088980000069],
            [-80.869296840999937, 35.172095673000058],
            [-80.869300437999982, 35.172096010000075],
            [-80.869388263999952, 35.172101744000031],
            [-80.869441396999946, 35.172102916000028],
            [-80.869527310999956, 35.172103420000042],
            [-80.869562271999939, 35.172103251000067],
            [-80.869573785999989, 35.17210301800003],
            [-80.869788708999977, 35.172104796000042],
            [-80.869791134999957, 35.172104986000079],
            [-80.869818270999986, 35.172108398000034],
            [-80.86984543899996, 35.172112936000076],
            [-80.869867219999946, 35.172117640000067],
            [-80.869911289999948, 35.172129359000053],
            [-80.869912557999953, 35.172129729000062],
            [-80.870600765999939, 35.172472832000039],
            [-80.87060284599994, 35.172473867000065],
            [-80.87103450099994, 35.172688188000052],
            [-80.871043285999974, 35.172692511000037],
            [-80.871068868999942, 35.17270499600005],
            [-80.871065299999941, 35.172726043000068],
            [-80.87105569299996, 35.172773342000028],
            [-80.871040163999965, 35.172833157000071],
            [-80.871028516999957, 35.172876519000056],
            [-80.871006697999974, 35.172943161000035],
            [-80.871002825999938, 35.172953188000065],
            [-80.870993780999981, 35.172972168000058],
            [-80.870964006999941, 35.173030135000033],
            [-80.870873810999967, 35.173190058000046],
            [-80.870764152999982, 35.173362972000064],
            [-80.870749944999943, 35.173386029000028],
            [-80.870629874999963, 35.173586671000066],
            [-80.870629157999986, 35.173587871000052],
            [-80.870455210999978, 35.17387943500006],
            [-80.870290494999949, 35.17410361900005],
            [-80.870145427999944, 35.174283928000079],
            [-80.870135451999943, 35.174296512000069],
            [-80.87009887399995, 35.174346170000035],
            [-80.869866683999987, 35.174680918000035],
            [-80.86986376599998, 35.17468514400008],
            [-80.869572452999989, 35.175109452000072],
            [-80.869228955999972, 35.175605337000036],
            [-80.869228278999969, 35.175606316000028],
            [-80.868974242999968, 35.175973919000057],
            [-80.868970094999952, 35.175979964000078],
            [-80.868769604999954, 35.176274370000044],
            [-80.868766292999965, 35.176279264000073],
            [-80.868765482999947, 35.176280467000026],
            [-80.868547018999948, 35.17660606700008],
            [-80.868535494999946, 35.176623617000075],
            [-80.868412417999934, 35.176815144000045],
            [-80.868384687999935, 35.176860822000037],
            [-80.868366572999946, 35.176893866000057],
            [-80.86832274599999, 35.176977446000024],
            [-80.868306014999973, 35.17701084600003],
            [-80.868299000999968, 35.17702584400007],
            [-80.868259955999974, 35.177111184000069],
            [-80.868240469999989, 35.177156774000025],
            [-80.868205918999934, 35.177243645000033],
            [-80.868202938999957, 35.177251230000024],
            [-80.868186041999934, 35.177298376000067],
            [-80.868157096999937, 35.177386305000027],
            [-80.868150007999986, 35.177408770000056],
            [-80.868141498999989, 35.177438736000056],
            [-80.86811787399995, 35.177527502000032],
            [-80.868107993999956, 35.177568284000074],
            [-80.868105838999952, 35.177578415000028],
            [-80.868087256999956, 35.177668247000042],
            [-80.868077912999979, 35.177721136000059],
            [-80.868064644999947, 35.177811572000053],
            [-80.86806342999995, 35.177820136000037],
            [-80.868059914999947, 35.177849230000049],
            [-80.86805396699998, 35.177906120000046],
            [-80.868050496999956, 35.177949140000067],
            [-80.868049199999973, 35.177983279000046],
            [-80.868043991999969, 35.178254970000069],
            [-80.868043935999935, 35.178258214000039],
            [-80.868043495999984, 35.178286454000045],
            [-80.867600189999962, 35.178580330000045],
            [-80.867263543999968, 35.178802823000069],
            [-80.867259671999989, 35.178805392000072],
            [-80.86709972999995, 35.178911902000038],
            [-80.866901850999966, 35.179041027000039],
            [-80.866900007999959, 35.179042232000029],
            [-80.866884572999936, 35.179052494000075],
            [-80.866633510999975, 35.179221948000077],
            [-80.86661900699994, 35.179231878000053],
            [-80.866393037999956, 35.179388806000077],
            [-80.866355927999962, 35.179415551000034],
            [-80.866346835999934, 35.179422413000054],
            [-80.866244027999983, 35.179500720000078],
            [-80.866207137999936, 35.179529911000031],
            [-80.866107754999973, 35.179611601000033],
            [-80.866090571999962, 35.17962598500003],
            [-80.866073663999941, 35.179640665000079],
            [-80.865978247999976, 35.179725039000061],
            [-80.865952966999942, 35.179748033000067],
            [-80.865906983999935, 35.179791051000052],
            [-80.865723067999966, 35.17996170400005],
            [-80.865702620999969, 35.17998112500004],
            [-80.865696596999953, 35.179987026000049],
            [-80.865567571999975, 35.180114287000038],
            [-80.865564515999949, 35.18011731200005],
            [-80.86513695299999, 35.180542090000074],
            [-80.864985646999969, 35.180689271000062],
            [-80.864780426999971, 35.180887632000065],
            [-80.864778434999948, 35.180889561000072],
            [-80.864566350999951, 35.181095509000045],
            [-80.864565742999957, 35.181096100000047],
            [-80.86451113399994, 35.18115287300003],
            [-80.864461163999977, 35.181212457000072],
            [-80.864416048999942, 35.181274596000037],
            [-80.864375979999977, 35.181339025000057],
            [-80.864341128999968, 35.181405467000047],
            [-80.86431164399994, 35.181473639000046],
            [-80.864287653999952, 35.181543247000036],
            [-80.864269260999947, 35.181613995000077],
            [-80.864256542999954, 35.181685580000078],
            [-80.864249554999958, 35.181757693000066],
            [-80.864248325999938, 35.181830028000036],
            [-80.864252861999944, 35.181902274000038],
            [-80.864263143999949, 35.181974121000053],
            [-80.864279127999964, 35.182045263000077],
            [-80.864294429999973, 35.182094912000025],
            [-80.864309593999963, 35.182162404000053],
            [-80.864331209999989, 35.182232534000036],
            [-80.864358366999966, 35.18230135500005],
            [-80.864390948999983, 35.182368569000062],
            [-80.86442881499994, 35.182433890000027],
            [-80.864471803999947, 35.182497038000065],
            [-80.864519729999984, 35.182557742000029],
            [-80.864572390999967, 35.182615743000042],
            [-80.86462955899998, 35.182670792000067],
            [-80.864672033999966, 35.182707365000056],
            [-80.864953728999978, 35.182939075000036],
            [-80.86544089299997, 35.183351334000065],
            [-80.865450133999957, 35.183359078000024],
            [-80.865704886999936, 35.183570471000053],
            [-80.865933820999942, 35.183763679000037],
            [-80.865950860999988, 35.18377827300003],
            [-80.86595910799997, 35.183785276000037],
            [-80.866191811999954, 35.183981109000058],
            [-80.866397110999969, 35.184158296000078],
            [-80.866415836999977, 35.184174139000049],
            [-80.866618183999947, 35.184341937000056],
            [-80.866609213999936, 35.184344324000051],
            [-80.866527342999973, 35.184371075000058],
            [-80.866447779999987, 35.184402164000062],
            [-80.866370862999986, 35.184437459000037],
            [-80.866296922999936, 35.184476808000056],
            [-80.866226274999974, 35.184520044000067],
            [-80.866159222999954, 35.184566980000056],
            [-80.866096053999968, 35.184617416000037],
            [-80.866037036999955, 35.184671136000077],
            [-80.865982426999949, 35.18472790900006],
            [-80.865932456999985, 35.184787494000034],
            [-80.865887340999961, 35.184849633000056],
            [-80.865847269999961, 35.184914063000065],
            [-80.865812418999951, 35.184980505000055],
            [-80.86578293499997, 35.185048677000054],
            [-80.865758944999982, 35.185118286000034],
            [-80.865740552999966, 35.185189034000075],
            [-80.865727834999973, 35.185260619000076],
            [-80.865720847999967, 35.185332733000052],
            [-80.865719619999936, 35.185405067000033],
            [-80.865724157999978, 35.185477313000035],
            [-80.865734441999962, 35.185549160000051],
            [-80.865750426999966, 35.185620302000075],
            [-80.865772044999972, 35.185690432000058],
            [-80.865799203999984, 35.185759252000025],
            [-80.865831788999969, 35.185826466000037],
            [-80.865869657999951, 35.18589178600007],
            [-80.865912648999938, 35.185954934000051],
            [-80.865960578999989, 35.186015637000025],
            [-80.866013242999941, 35.186073638000039],
            [-80.866070414999967, 35.186128686000075],
            [-80.866131850999977, 35.18618054600006],
            [-80.866197284999942, 35.186228997000057],
            [-80.866266439999947, 35.186273831000051],
            [-80.866339019999941, 35.186314855000035],
            [-80.866414710999948, 35.18635189500003],
            [-80.86649319199995, 35.18638479100008],
            [-80.866574124999943, 35.186413403000074],
            [-80.866657163999946, 35.186437608000062],
            [-80.866741953999963, 35.186457302000065],
            [-80.866828130999977, 35.186472402000049],
            [-80.866915327999948, 35.186482841000043],
            [-80.86700316799994, 35.186488577000034],
            [-80.867091277999975, 35.186489583000025],
            [-80.86717927899997, 35.186485858000026],
            [-80.867266794999978, 35.186477414000024],
            [-80.867343557999959, 35.186465787000031],
            [-80.867377449999935, 35.186464320000027],
            [-80.867389730999946, 35.186463571000047],
            [-80.867497536999963, 35.186465999000063],
            [-80.867575588999955, 35.186473411000065],
            [-80.867632219999962, 35.186479969000061],
            [-80.867668673999958, 35.186483763000069],
            [-80.867683706999969, 35.186486711000043],
            [-80.867688188999978, 35.186487557000078],
            [-80.86775210899998, 35.186498291000078],
            [-80.867766734999975, 35.186500377000073],
            [-80.867775690999963, 35.186501613000075],
            [-80.867848260999949, 35.186509966000074],
            [-80.867936101999987, 35.186515701000076],
            [-80.867951835999975, 35.186516178000034],
            [-80.868001021999987, 35.186519238000074],
            [-80.868040002999976, 35.186521197000047],
            [-80.868128111999965, 35.186522202000049],
            [-80.868162912999935, 35.186521296000024],
            [-80.868259906999981, 35.186517736000042],
            [-80.86829344399996, 35.186516160000053],
            [-80.868375629999946, 35.186511455000073],
            [-80.868395293999981, 35.186510211000041],
            [-80.868482809999989, 35.186501766000049],
            [-80.868510299999969, 35.186498122000046],
            [-80.868539875999943, 35.186493944000063],
            [-80.868577681999966, 35.186489890000075],
            [-80.868604093999977, 35.186486838000064],
            [-80.868690748999938, 35.186473712000065],
            [-80.868755336999982, 35.186460744000044],
            [-80.868841653999937, 35.186441547000072],
            [-80.868862490999959, 35.186436764000064],
            [-80.868902853999941, 35.186426649000055],
            [-80.868979103999948, 35.186406461000047],
            [-80.869022567999934, 35.18639427800008],
            [-80.869029746999956, 35.186392076000061],
            [-80.869244526999978, 35.186571418000028],
            [-80.869509385999947, 35.186797722000051],
            [-80.86973894099998, 35.186994654000046],
            [-80.869746963999944, 35.18700147800007],
            [-80.870041385999968, 35.187249757000075],
            [-80.870042908999949, 35.187251040000035],
            [-80.870317401999955, 35.187481762000061],
            [-80.870346668999957, 35.187505619000035]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 85,
        "SolutionID": "PI-27:E",
        "Shape_Length": 0.072233906743052329,
        "Shape_Area": 8.5259255107112075e-5
      }
    },
    {
      "type": "Feature",
      "id": 86,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.87669740299998, 35.224491932000035],
            [-80.876609361999954, 35.224495666000053],
            [-80.876521805999971, 35.224504116000048],
            [-80.876435110999978, 35.224517247000051],
            [-80.876349647999973, 35.224535002000039],
            [-80.876265783999941, 35.224557307000055],
            [-80.87618387599997, 35.224584064000055],
            [-80.876104276999968, 35.224615160000042],
            [-80.876027326999974, 35.224650461000067],
            [-80.875953353999989, 35.224689815000033],
            [-80.875882676999936, 35.224733056000048],
            [-80.875815596999985, 35.224779997000041],
            [-80.875752402999979, 35.224830439000073],
            [-80.875693363999972, 35.224884162000023],
            [-80.87563873299996, 35.224940940000067],
            [-80.875588744999959, 35.225000528000066],
            [-80.87554361399998, 35.225062671000046],
            [-80.875503531999982, 35.225127103000034],
            [-80.875468670999965, 35.22519354800005],
            [-80.875439179999944, 35.225261722000027],
            [-80.875415186999987, 35.225331332000053],
            [-80.875396793999982, 35.225402081000027],
            [-80.875384078999957, 35.225473667000074],
            [-80.875377095999966, 35.225545780000061],
            [-80.875375875999964, 35.225618114000042],
            [-80.875380424999946, 35.225690360000044],
            [-80.875390722999953, 35.22576220600007],
            [-80.875406723999959, 35.225833345000069],
            [-80.87542836099999, 35.225903474000063],
            [-80.875455541999941, 35.225972291000062],
            [-80.875488150999956, 35.226039502000049],
            [-80.875526045999948, 35.226104819000057],
            [-80.875569066999958, 35.22616796300008],
            [-80.875617026999976, 35.22622866200004],
            [-80.875669723999977, 35.226286658000049],
            [-80.875726929999985, 35.226341701000024],
            [-80.875788402999945, 35.226393556000062],
            [-80.875853874999962, 35.226442002000056],
            [-80.875923069999942, 35.226486829000066],
            [-80.875995689999968, 35.226527848000046],
            [-80.876071422999985, 35.226564882000048],
            [-80.876149945999941, 35.226597771000058],
            [-80.876230921999934, 35.22662637600007],
            [-80.876314005999973, 35.226650575000065],
            [-80.876398839999979, 35.226670261000038],
            [-80.876485061999972, 35.22668535400004],
            [-80.876572301999943, 35.22669578700004],
            [-80.876583771999947, 35.226696804000028],
            [-80.876959056999965, 35.226728769000033],
            [-80.876967013999945, 35.226729427000066],
            [-80.877287451999962, 35.226755143000048],
            [-80.877301735999936, 35.226756225000031],
            [-80.877433407999945, 35.226765632000024],
            [-80.877445978999958, 35.226766482000073],
            [-80.877651148999973, 35.22677955100005],
            [-80.87764509699997, 35.226818801000036],
            [-80.877642883999954, 35.226834005000057],
            [-80.877640672999974, 35.226851364000026],
            [-80.877601469999945, 35.227184728000054],
            [-80.877600864999977, 35.227190003000032],
            [-80.877584207999973, 35.227339121000057],
            [-80.877581776999989, 35.227363969000066],
            [-80.877547947999972, 35.227766465000059],
            [-80.877539095999964, 35.227846603000046],
            [-80.877538533999939, 35.227851827000052],
            [-80.877517650999948, 35.228051421000032],
            [-80.877508154999987, 35.228111922000039],
            [-80.877501845999973, 35.228146691000063],
            [-80.877486808999947, 35.228209400000026],
            [-80.877463157999955, 35.228299438000079],
            [-80.877424682999958, 35.228437579000058],
            [-80.877393049999966, 35.22854086600006],
            [-80.877372306999973, 35.228607035000039],
            [-80.877349815999935, 35.22866786700007],
            [-80.877314728999977, 35.228752975000077],
            [-80.877301716999966, 35.228780244000063],
            [-80.877299069999935, 35.228784573000041],
            [-80.877294406999965, 35.228792274000057],
            [-80.877280073999941, 35.228816177000056],
            [-80.877254487999949, 35.228853452000067],
            [-80.87724906599999, 35.228861427000027],
            [-80.877232368999955, 35.228887016000044],
            [-80.877186320999954, 35.22895986900005],
            [-80.877162935999934, 35.228998714000056],
            [-80.87713198299997, 35.22905707700005],
            [-80.877106924999964, 35.229108306000057],
            [-80.877103016999968, 35.229116389000069],
            [-80.877073526999936, 35.229184563000047],
            [-80.877072355999985, 35.229187593000063],
            [-80.877052080999988, 35.229240351000044],
            [-80.877029257999936, 35.229306931000053],
            [-80.877022484999941, 35.229330463000053],
            [-80.877007809999952, 35.229384050000078],
            [-80.876996191999979, 35.229431267000052],
            [-80.876990501999956, 35.229459377000069],
            [-80.876980319999973, 35.229514042000062],
            [-80.876973294999971, 35.229557517000046],
            [-80.876966312999969, 35.22962963100008],
            [-80.876965268999982, 35.229653635000034],
            [-80.87696194199998, 35.229763613000046],
            [-80.876961767999944, 35.229811943000072],
            [-80.876966318999962, 35.229884188000028],
            [-80.876969144999975, 35.229905833000032],
            [-80.876740347999942, 35.230057015000057],
            [-80.876729747999946, 35.230064093000067],
            [-80.876616010999953, 35.230140820000031],
            [-80.876509415999976, 35.230212175000077],
            [-80.876504366999939, 35.230215572000077],
            [-80.876297857999987, 35.230355183000029],
            [-80.876185065999948, 35.230430163000051],
            [-80.876149067999961, 35.230454961000078],
            [-80.876074926999934, 35.230507867000028],
            [-80.876051742999948, 35.230524798000033],
            [-80.876048066999942, 35.230527556000027],
            [-80.876008010999954, 35.230557720000036],
            [-80.875950870999986, 35.230603377000079],
            [-80.875911125999949, 35.230637074000072],
            [-80.875868449999984, 35.230671167000025],
            [-80.875837824999962, 35.230696435000027],
            [-80.875791350999975, 35.230738117000044],
            [-80.875735621999979, 35.230790739000042],
            [-80.875723052999945, 35.230802781000079],
            [-80.875668417999975, 35.230859559000066],
            [-80.875653377999981, 35.230876624000075],
            [-80.875605259999986, 35.230932332000066],
            [-80.875573692999978, 35.230970545000048],
            [-80.875521892999984, 35.231036160000031],
            [-80.87551850899996, 35.231040470000039],
            [-80.875473373999966, 35.231102612000029],
            [-80.875433288999943, 35.231167044000074],
            [-80.875428144999944, 35.231176131000041],
            [-80.875395976999982, 35.23123365400005],
            [-80.875366256999939, 35.231291012000042],
            [-80.875359495999987, 35.231305450000036],
            [-80.87533969499998, 35.231348636000064],
            [-80.875305758999957, 35.231413554000028],
            [-80.875289710999937, 35.231445636000046],
            [-80.875285080999959, 35.231455458000028],
            [-80.875243594999972, 35.231544730000053],
            [-80.875224307999986, 35.231589042000053],
            [-80.875187328999971, 35.231680075000043],
            [-80.875181752999936, 35.231694115000039],
            [-80.875171996999939, 35.231720368000026],
            [-80.875132415999985, 35.231831824000039],
            [-80.875118175999944, 35.231875181000078],
            [-80.875117601999989, 35.231877088000033],
            [-80.875087535999967, 35.231977404000077],
            [-80.87507433199994, 35.232026187000031],
            [-80.875049864999937, 35.232127332000061],
            [-80.875045247999935, 35.232147391000069],
            [-80.875040779999949, 35.232169065000051],
            [-80.87502107399996, 35.232270824000068],
            [-80.875013075999959, 35.232318917000043],
            [-80.874966724999979, 35.23265205000007],
            [-80.87492247199998, 35.232966059000034],
            [-80.874901746999967, 35.233083918000034],
            [-80.87480723799996, 35.233524529000078],
            [-80.87471652499994, 35.233888489000037],
            [-80.874548743999981, 35.234494399000027],
            [-80.874465388999965, 35.234737644000063],
            [-80.874449045999938, 35.234755191000033],
            [-80.874437558999944, 35.234768163000069],
            [-80.874062555999956, 35.235198124000078],
            [-80.873273924999978, 35.236036616000035],
            [-80.873256392999963, 35.23605567900006],
            [-80.873251529999948, 35.236061121000034],
            [-80.872929114999977, 35.23642428900007],
            [-80.872924960999967, 35.23642899400005],
            [-80.871664383999985, 35.237864653000031],
            [-80.870969751999951, 35.238640151000027],
            [-80.870957310999984, 35.238639125000077],
            [-80.870866985999953, 35.238632049000046],
            [-80.870835266999961, 35.238629875000072],
            [-80.870736599999987, 35.238624071000061],
            [-80.870703016999983, 35.238622441000075],
            [-80.870614849999981, 35.238621436000074],
            [-80.870535340999936, 35.238624595000033],
            [-80.870436390999942, 35.238630934000071],
            [-80.870427842999959, 35.238631505000058],
            [-80.870407529999966, 35.238633040000025],
            [-80.870217793999984, 35.238648568000031],
            [-80.87018014299997, 35.238652090000073],
            [-80.869991341999935, 35.238671955000029],
            [-80.869961734999947, 35.238675345000047],
            [-80.869949462999955, 35.238676911000027],
            [-80.869162159999973, 35.23878045400005],
            [-80.869156354999973, 35.23878122800005],
            [-80.868810180999958, 35.238828026000078],
            [-80.868769680999947, 35.238834024000028],
            [-80.868683186999988, 35.23884795400005],
            [-80.868655052999941, 35.238852742000063],
            [-80.868619281999941, 35.238859576000038],
            [-80.868517869999948, 35.238880151000046],
            [-80.868468160999953, 35.238891067000054],
            [-80.868456332999983, 35.238893912000037],
            [-80.86835640399994, 35.238918356000056],
            [-80.868299830999945, 35.238933318000079],
            [-80.868187510999974, 35.238965282000038],
            [-80.868172029999982, 35.238969774000054],
            [-80.868130236999946, 35.238982784000029],
            [-80.868019967999942, 35.239018839000039],
            [-80.86797983699995, 35.239032580000071],
            [-80.867966345999946, 35.239037482000072],
            [-80.867858115999979, 35.239077399000053],
            [-80.867799244999958, 35.239100533000055],
            [-80.867693350999957, 35.239144764000059],
            [-80.867686095999943, 35.23914781700006],
            [-80.867642760999956, 35.239167020000025],
            [-80.867124590999936, 35.239406778000046],
            [-80.867108033999955, 35.23941456700004],
            [-80.866802653999969, 35.239560581000035],
            [-80.866785576999973, 35.23956888500004],
            [-80.866711586999941, 35.239608234000059],
            [-80.866640891999964, 35.23965146900008],
            [-80.866573793999976, 35.23969840500007],
            [-80.866510582999979, 35.239748841000051],
            [-80.866451526999981, 35.239802560000044],
            [-80.866396879999968, 35.239859334000073],
            [-80.866346875999966, 35.239918918000058],
            [-80.866301728999986, 35.23998105700008],
            [-80.866261631999976, 35.240045486000042],
            [-80.866250183999966, 35.240066010000078],
            [-80.865862138999944, 35.24078094500004],
            [-80.865553296999963, 35.241329355000062],
            [-80.865535823999949, 35.241361734000066],
            [-80.86545891999998, 35.241510640000058],
            [-80.864959750999958, 35.242429238000057],
            [-80.864929097999948, 35.242480525000076],
            [-80.86489680699998, 35.242528911000079],
            [-80.864846658999966, 35.24259735000004],
            [-80.864843736999944, 35.24260135600008],
            [-80.864648378999959, 35.242870536000055],
            [-80.864407883999945, 35.243201258000056],
            [-80.864349278999953, 35.243279978000032],
            [-80.864234891999956, 35.243223152000041],
            [-80.864125314999967, 35.243168251000043],
            [-80.86357382999995, 35.24289002200004],
            [-80.863564957999984, 35.242885586000057],
            [-80.862978738999971, 35.24259491600003],
            [-80.86183125499997, 35.242016564000039],
            [-80.861829720999935, 35.242015792000075],
            [-80.861076730999969, 35.241637396000044],
            [-80.860239339999964, 35.241206982000051],
            [-80.860222981999982, 35.241198706000034],
            [-80.859483866999938, 35.240830733000053],
            [-80.859285957999987, 35.240724716000045],
            [-80.85910374499997, 35.240612144000067],
            [-80.859076629999947, 35.240594129000044],
            [-80.859061992999955, 35.24058315700006],
            [-80.859022385999936, 35.24055461100005],
            [-80.858953188999976, 35.240509774000031],
            [-80.858880565999982, 35.240468745000044],
            [-80.858804826999972, 35.240431702000024],
            [-80.858726296999976, 35.240398801000026],
            [-80.858645313999943, 35.240370184000028],
            [-80.858562221999989, 35.240345974000036],
            [-80.858477377999975, 35.24032627400004],
            [-80.858391143999938, 35.240311169000051],
            [-80.858303891999981, 35.240300723000075],
            [-80.858215993999977, 35.240294981000034],
            [-80.85812782499994, 35.24029396800006],
            [-80.85803976599999, 35.240297688000055],
            [-80.857952190999981, 35.240306125000075],
            [-80.857865475999972, 35.240319242000055],
            [-80.857779992999951, 35.240336984000066],
            [-80.857723849999957, 35.240351904000079],
            [-80.857653349999964, 35.240350308000075],
            [-80.857012034999968, 35.24033094300006],
            [-80.85616840299997, 35.240304324000078],
            [-80.856158081999979, 35.240304031000051],
            [-80.856018368999969, 35.240300501000036],
            [-80.85498492499994, 35.240272707000031],
            [-80.854721789999985, 35.240261836000059],
            [-80.854717011999981, 35.240261646000079],
            [-80.854704671999968, 35.240261218000057],
            [-80.854174206999971, 35.240244846000053],
            [-80.854152302999978, 35.24024431600003],
            [-80.853915216999951, 35.240240164000056],
            [-80.853861292999966, 35.240240105000055],
            [-80.85377323299997, 35.240243821000035],
            [-80.853685657999961, 35.24025225500003],
            [-80.853598942999952, 35.240265370000031],
            [-80.853513457999952, 35.240283109000075],
            [-80.853429570999936, 35.240305397000043],
            [-80.853347640999971, 35.240332139000031],
            [-80.853268017999937, 35.240363220000063],
            [-80.853191041999935, 35.240398506000076],
            [-80.853117044999976, 35.240437847000067],
            [-80.853046340999981, 35.240481074000058],
            [-80.852979235999953, 35.240528002000076],
            [-80.852916014999948, 35.240578432000063],
            [-80.852856948999943, 35.240632144000074],
            [-80.852802291999978, 35.240688911000063],
            [-80.852752277999969, 35.240748490000044],
            [-80.85270712099998, 35.240810624000062],
            [-80.852667011999984, 35.240875049000067],
            [-80.852634036999973, 35.240937514000052],
            [-80.852551664999964, 35.241107745000079],
            [-80.852538747999972, 35.241132408000055],
            [-80.852081349999935, 35.241963989000055],
            [-80.852071050999939, 35.24198319900006],
            [-80.852039152999964, 35.242044258000078],
            [-80.851997162999965, 35.242060647000073],
            [-80.851973354999984, 35.242070984000065],
            [-80.851897631999975, 35.242104664000067],
            [-80.851844461999974, 35.242129612000042],
            [-80.851838266999948, 35.24213269300003],
            [-80.851781132999974, 35.242161278000026],
            [-80.851739381999948, 35.242183038000064],
            [-80.851672927999971, 35.242219096000042],
            [-80.851646874999972, 35.242233594000027],
            [-80.851576169999987, 35.242276821000075],
            [-80.851573792999943, 35.242278380000073],
            [-80.851511359999961, 35.24231941100004],
            [-80.851505908999968, 35.242323012000043],
            [-80.851444857999979, 35.242363564000073],
            [-80.851385576999974, 35.242405332000033],
            [-80.851368155999978, 35.24241857800007],
            [-80.85131109699995, 35.242462735000061],
            [-80.851265294999962, 35.242499917000032],
            [-80.851217179999935, 35.242540889000054],
            [-80.851162167999973, 35.242581667000024],
            [-80.851122926999949, 35.242560972000035],
            [-80.851121862999946, 35.242560411000056],
            [-80.851046126999961, 35.242523363000032],
            [-80.850967597999954, 35.242490457000031],
            [-80.850886614999979, 35.242461835000029],
            [-80.850803522999968, 35.242437619000043],
            [-80.850718678999954, 35.242417914000043],
            [-80.850632443999984, 35.242402803000061],
            [-80.850545190999981, 35.242392352000024],
            [-80.850457290999941, 35.242386604000046],
            [-80.850369119999982, 35.242385585000079],
            [-80.850283170999944, 35.242386899000053],
            [-80.850195108999969, 35.242390614000044],
            [-80.850107531999981, 35.24239904500007],
            [-80.850020812999958, 35.242412157000047],
            [-80.849935325999979, 35.242429894000054],
            [-80.849851434999948, 35.242452179000054],
            [-80.849769500999969, 35.242478919000064],
            [-80.849689874999967, 35.24250999700007],
            [-80.84961289599994, 35.242545281000048],
            [-80.849538894999966, 35.242584620000059],
            [-80.849468187999946, 35.242627845000072],
            [-80.849401077999971, 35.242674771000054],
            [-80.849398676999954, 35.242676569000025],
            [-80.84932704199997, 35.242730336000079],
            [-80.849266218999958, 35.24277896600006],
            [-80.849207148999938, 35.242832677000024],
            [-80.849164048999967, 35.242876784000032],
            [-80.849112222999963, 35.242932692000068],
            [-80.849057728999981, 35.242983716000026],
            [-80.849041756999952, 35.242998947000046],
            [-80.849020628999938, 35.243019992000029],
            [-80.848939599999937, 35.243102759000067],
            [-80.848908279999989, 35.243136028000038],
            [-80.84883094199995, 35.243221487000028],
            [-80.84882872899999, 35.243223939000075],
            [-80.848799460999942, 35.243257778000043],
            [-80.84873315699997, 35.243337570000051],
            [-80.848712405999947, 35.243363308000028],
            [-80.848709595999935, 35.24336691700006],
            [-80.848636097999986, 35.243461705000072],
            [-80.848593746999939, 35.243520228000079],
            [-80.848588825999968, 35.243527597000025],
            [-80.84852054199996, 35.243630780000046],
            [-80.848485350999965, 35.24368783400007],
            [-80.848485147999952, 35.243688189000068],
            [-80.848298299999954, 35.244014486000026],
            [-80.848290411999983, 35.24402783000005],
            [-80.84828308799996, 35.244038875000058],
            [-80.84827665499995, 35.244048691000046],
            [-80.848230534999971, 35.244119930000068],
            [-80.848201182999958, 35.244168062000028],
            [-80.848199353999973, 35.244171266000023],
            [-80.848078021999982, 35.244384725000032],
            [-80.848075367999968, 35.24438942300003],
            [-80.847950740999977, 35.244611385000042],
            [-80.847801368999967, 35.244867398000054],
            [-80.847638290999953, 35.245141599000078],
            [-80.847497962999967, 35.245363891000068],
            [-80.847462557999961, 35.245418030000053],
            [-80.847426564999978, 35.245472119000055],
            [-80.847424432999958, 35.245475337000073],
            [-80.84731069299994, 35.245647624000071],
            [-80.847175057999948, 35.245846406000055],
            [-80.847172413999942, 35.245850301000075],
            [-80.846996308999962, 35.24611085600003],
            [-80.846995478999986, 35.246112086000039],
            [-80.846968328999935, 35.24615452300003],
            [-80.846831419999944, 35.246380284000054],
            [-80.846702402999938, 35.246586391000051],
            [-80.846502256999941, 35.246893761000024],
            [-80.846493029999976, 35.246908186000041],
            [-80.84640258099995, 35.247052155000063],
            [-80.846395732999952, 35.247052368000027],
            [-80.846386521999989, 35.247052005000057],
            [-80.846385039999973, 35.247051948000035],
            [-80.846369755999945, 35.247051431000045],
            [-80.846282038999959, 35.247048875000075],
            [-80.846241289999966, 35.247048194000058],
            [-80.846148687999971, 35.247047795000071],
            [-80.846118279999985, 35.247046761000036],
            [-80.846040424999956, 35.247043131000055],
            [-80.845976551999968, 35.247039814000061],
            [-80.845954549999988, 35.247038820000057],
            [-80.845940342999938, 35.24703833500007],
            [-80.845738388999962, 35.247032318000038],
            [-80.845525082999984, 35.247024284000076],
            [-80.845344921999981, 35.247013466000055],
            [-80.845265794999989, 35.247008493000067],
            [-80.845183290999955, 35.246999353000035],
            [-80.845114277999983, 35.246990391000054],
            [-80.845056241999941, 35.246981382000058],
            [-80.845047037999962, 35.24697963400007],
            [-80.844992005999984, 35.246963691000076],
            [-80.844971241999986, 35.246957832000078],
            [-80.84487119399995, 35.246930345000067],
            [-80.84479182299998, 35.246905606000041],
            [-80.844747648999942, 35.246890541000027],
            [-80.844589576999965, 35.246833883000079],
            [-80.844542505999982, 35.246817873000055],
            [-80.844532416999982, 35.246814662000077],
            [-80.84391637799996, 35.246620919000065],
            [-80.843843371999981, 35.246599909000054],
            [-80.843758524999942, 35.24658019900005],
            [-80.843672287999937, 35.246565084000054],
            [-80.843585030999975, 35.24655462700008],
            [-80.843497126999978, 35.246548874000041],
            [-80.843408951999947, 35.24654785000007],
            [-80.843320884999969, 35.246551560000057],
            [-80.843233301999987, 35.246559986000079],
            [-80.84314657799996, 35.246573093000052],
            [-80.843061084999988, 35.246590825000055],
            [-80.842977188999953, 35.246613106000041],
            [-80.842895247999934, 35.246639841000047],
            [-80.84281561499995, 35.246670914000049],
            [-80.842738628999939, 35.246706194000069],
            [-80.842664620999983, 35.246745528000076],
            [-80.842593905999934, 35.246788749000075],
            [-80.842526788999976, 35.246835672000032],
            [-80.842463557999963, 35.246886095000036],
            [-80.842404479999971, 35.246939803000032],
            [-80.842349811999952, 35.246996565000074],
            [-80.842299785999955, 35.247056139000051],
            [-80.84225461699998, 35.247118269000055],
            [-80.842214497999976, 35.247182690000045],
            [-80.842179599999952, 35.247249125000053],
            [-80.842159349999974, 35.247294302000057],
            [-80.842146854999953, 35.247324168000034],
            [-80.84199681399997, 35.247646966000048],
            [-80.841984716999946, 35.247674047000032],
            [-80.841856511999936, 35.247973076000051],
            [-80.841815155999939, 35.248068619000037],
            [-80.841725662999977, 35.248268943000028],
            [-80.841569127999946, 35.248611525000058],
            [-80.841566554999986, 35.248617203000038],
            [-80.841398568999978, 35.248991067000077],
            [-80.841258958999958, 35.249298545000045],
            [-80.841256376999979, 35.249304279000057],
            [-80.841121129999976, 35.249607258000026],
            [-80.841120210999975, 35.249609323000072],
            [-80.841035591999969, 35.249800059000052],
            [-80.840897053999981, 35.250105242000075],
            [-80.840790998999978, 35.250334528000053],
            [-80.840788086999964, 35.250340882000046],
            [-80.840662033999934, 35.250618529000064],
            [-80.840659578999976, 35.250623980000057],
            [-80.840498181999976, 35.250985271000047],
            [-80.840429748999952, 35.251134926000077],
            [-80.840424617999986, 35.251146339000059],
            [-80.840314853999985, 35.25139465400008],
            [-80.840205530999981, 35.251628603000029],
            [-80.840197972999988, 35.251645173000043],
            [-80.840089808999949, 35.251888208000025],
            [-80.840073848999964, 35.251919939000061],
            [-80.839554655999962, 35.252136269000061],
            [-80.839552749999939, 35.252137007000044],
            [-80.839509719999967, 35.252154445000031],
            [-80.839432727999963, 35.252189723000072],
            [-80.839395022999952, 35.252209054000048],
            [-80.839376902999959, 35.252218689000074],
            [-80.839340592999974, 35.25223869000007],
            [-80.839269871999988, 35.252281909000033],
            [-80.839232361999962, 35.252307360000032],
            [-80.839215972999966, 35.252318896000077],
            [-80.839186358999939, 35.252340365000066],
            [-80.839163285999973, 35.25235800400003],
            [-80.839158570999984, 35.252361694000058],
            [-80.839153654999961, 35.252365203000068],
            [-80.839131253999938, 35.25238156000006],
            [-80.839068015999942, 35.252431982000076],
            [-80.839053000999968, 35.252444973000024],
            [-80.839016762999961, 35.252476837000074],
            [-80.838972693999949, 35.252517552000029],
            [-80.838918019999937, 35.252574313000025],
            [-80.838911174999964, 35.252581987000042],
            [-80.838879989999953, 35.252617266000073],
            [-80.838836801999946, 35.252669165000043],
            [-80.838791627999967, 35.252731294000057],
            [-80.838790947999939, 35.252732303000073],
            [-80.838765290999959, 35.252770462000058],
            [-80.838725845999988, 35.252833872000053],
            [-80.838694191999934, 35.25289358200007],
            [-80.838674450999974, 35.252934039000024],
            [-80.838671201999944, 35.252940763000026],
            [-80.838666068999942, 35.252951655000061],
            [-80.838197458999957, 35.253961777000029],
            [-80.837656247999973, 35.25508805100003],
            [-80.83765561599995, 35.255089369000075],
            [-80.837654811999982, 35.255091051000079],
            [-80.837357399999973, 35.255715286000054],
            [-80.837352880999958, 35.255724904000033],
            [-80.837342152999952, 35.255748054000037],
            [-80.837339345999965, 35.255752412000049],
            [-80.837119813999948, 35.256066800000042],
            [-80.837095077999948, 35.256056888000046],
            [-80.837014085999954, 35.256028257000025],
            [-80.836930983999935, 35.256004031000032],
            [-80.836887350999973, 35.255993279000052],
            [-80.83684834099995, 35.255984253000065],
            [-80.836807118999957, 35.255975290000038],
            [-80.836720871999944, 35.255960170000037],
            [-80.836633605999964, 35.255949709000049],
            [-80.836579608999955, 35.255945610000026],
            [-80.836533479999957, 35.255942875000073],
            [-80.836499563999951, 35.255941216000053],
            [-80.836411378999969, 35.255940186000032],
            [-80.836370908999982, 35.255940800000076],
            [-80.836282831999938, 35.255944505000059],
            [-80.83620655499999, 35.255951569000047],
            [-80.836118281999973, 35.255961839000065],
            [-80.836106964999942, 35.255963197000028],
            [-80.836020229999974, 35.255976299000054],
            [-80.835952708999969, 35.255989890000023],
            [-80.835776031999956, 35.256029442000056],
            [-80.835758047999946, 35.256033577000039],
            [-80.835674139999981, 35.256055853000078],
            [-80.835625881999988, 35.256070975000057],
            [-80.835350858999959, 35.256162144000029],
            [-80.835102027999937, 35.256242679000025],
            [-80.834860615999958, 35.256312568000055],
            [-80.834852442999988, 35.256314959000065],
            [-80.834847501999946, 35.256316428000048],
            [-80.834774066999955, 35.256338390000053],
            [-80.834758574999967, 35.256341537000026],
            [-80.834724207999955, 35.256346931000053],
            [-80.834670043999949, 35.256353344000047],
            [-80.834606550999979, 35.256357732000026],
            [-80.834023393999985, 35.256365722000055],
            [-80.83402100099994, 35.256365756000037],
            [-80.834018344999947, 35.256365799000037],
            [-80.833593565999934, 35.256372923000072],
            [-80.833592234999969, 35.256372946000056],
            [-80.833129468999971, 35.256381081000029],
            [-80.832781602999944, 35.25638366700008],
            [-80.83276892899994, 35.256383809000056],
            [-80.832763618999934, 35.256383898000024],
            [-80.829018317999953, 35.256452679000063],
            [-80.82894823099997, 35.256453323000073],
            [-80.828938516999983, 35.256453441000076],
            [-80.828936332999945, 35.256453476000047],
            [-80.828504142999975, 35.256460582000045],
            [-80.828384477999975, 35.256366495000066],
            [-80.828380152999955, 35.256363109000063],
            [-80.827963537999949, 35.25603843600004],
            [-80.827607657999977, 35.255758197000034],
            [-80.827599386999964, 35.255751739000061],
            [-80.82725639399996, 35.255486189000067],
            [-80.826881956999955, 35.255179392000059],
            [-80.826686510999934, 35.255017122000027],
            [-80.826663060999977, 35.254998124000053],
            [-80.826641698999936, 35.254981599000075],
            [-80.826511038999968, 35.254882709000071],
            [-80.826461584999947, 35.254844892000051],
            [-80.826412407999953, 35.25480908600008],
            [-80.826343216999987, 35.254764230000035],
            [-80.826279585999941, 35.254727956000067],
            [-80.826106641999957, 35.254635298000039],
            [-80.826097652999977, 35.254630524000049],
            [-80.826050954999971, 35.254607022000073],
            [-80.82568320699994, 35.254430279000076],
            [-80.825665227999934, 35.254421790000038],
            [-80.825404743999968, 35.254300977000071],
            [-80.82523742099994, 35.254220496000073],
            [-80.825066101999937, 35.254134032000024],
            [-80.824971203999951, 35.254083502000071],
            [-80.824965425999949, 35.254080442000031],
            [-80.824889689999964, 35.254043378000063],
            [-80.824811160999957, 35.254010455000071],
            [-80.824730175999946, 35.253981815000031],
            [-80.824647079999977, 35.253957582000055],
            [-80.82456222999997, 35.253937858000029],
            [-80.824475987999961, 35.253922729000067],
            [-80.824388725999938, 35.25391225900006],
            [-80.824300815999948, 35.253906492000056],
            [-80.824212632999945, 35.253905454000062],
            [-80.824124556999948, 35.253909149000037],
            [-80.824036963999959, 35.253917562000026],
            [-80.823950228999934, 35.253930655000033],
            [-80.823864723999975, 35.253948373000071],
            [-80.823780814999964, 35.253970641000024],
            [-80.823698860999968, 35.253997363000053],
            [-80.823619212999972, 35.254028424000069],
            [-80.82354221199995, 35.254063691000056],
            [-80.823468187999936, 35.254103013000076],
            [-80.823397456999942, 35.254146223000077],
            [-80.823330322999936, 35.254193135000037],
            [-80.823267073999943, 35.25424354900008],
            [-80.823207977999971, 35.254297247000068],
            [-80.823153290999983, 35.254354000000035],
            [-80.823103246999949, 35.254413566000039],
            [-80.823058058999948, 35.25447568900006],
            [-80.823017920999973, 35.254540104000057],
            [-80.822983004999969, 35.254606533000072],
            [-80.822953458999962, 35.254674694000073],
            [-80.82292941299994, 35.254744294000034],
            [-80.822910966999984, 35.254815035000036],
            [-80.822898200999987, 35.254886614000043],
            [-80.822891169999934, 35.254958724000062],
            [-80.822889902999975, 35.255031057000053],
            [-80.822894406999978, 35.255103304000045],
            [-80.822904661999985, 35.255175154000028],
            [-80.822920622999959, 35.255246301000057],
            [-80.822942222999984, 35.255316438000079],
            [-80.822969368999964, 35.255385267000065],
            [-80.823001945999977, 35.255452492000074],
            [-80.82303981299998, 35.255517825000027],
            [-80.823082807999981, 35.25558098700003],
            [-80.823130746999936, 35.255641707000052],
            [-80.823183425999957, 35.255699725000056],
            [-80.823240616999954, 35.255754793000051],
            [-80.823302077999983, 35.255806675000031],
            [-80.823367542999961, 35.25585514800008],
            [-80.823436733999984, 35.255900005000058],
            [-80.82350357599995, 35.255937997000046],
            [-80.82361214499997, 35.255995807000033],
            [-80.823617922999972, 35.255998865000038],
            [-80.823639693999951, 35.256010090000075],
            [-80.82383708399999, 35.256109714000047],
            [-80.823861617999967, 35.256121803000042],
            [-80.824050434999947, 35.256212622000078],
            [-80.824068804999968, 35.25622130000005],
            [-80.824329490999958, 35.256342208000035],
            [-80.824660030999951, 35.25650107000007],
            [-80.824709490999965, 35.25652756900007],
            [-80.824790905999976, 35.256589189000067],
            [-80.82496757499996, 35.256735871000046],
            [-80.824973849999935, 35.256741047000048],
            [-80.825364932999946, 35.257061488000033],
            [-80.825382107999985, 35.257075311000051],
            [-80.825392306999959, 35.257083291000072],
            [-80.825745023999957, 35.25735637400004],
            [-80.826099304999957, 35.257635361000041],
            [-80.826104359999988, 35.257639320000067],
            [-80.826521341999978, 35.257964284000025],
            [-80.826831821999974, 35.258208406000051],
            [-80.826894634999974, 35.258254782000051],
            [-80.826919724999982, 35.258271750000063],
            [-80.826971304999972, 35.258305781000047],
            [-80.827015408999955, 35.258333669000024],
            [-80.82705300799995, 35.258355638000069],
            [-80.827108430999942, 35.258386862000066],
            [-80.827143455999988, 35.258405941000035],
            [-80.827191107999965, 35.258429899000078],
            [-80.827248966999946, 35.25845765300005],
            [-80.827277055999957, 35.258470758000044],
            [-80.827339772999949, 35.258497473000034],
            [-80.827399499999956, 35.258521304000055],
            [-80.827415317999964, 35.258527511000068],
            [-80.827496308999969, 35.258556148000025],
            [-80.827535049999938, 35.258568070000024],
            [-80.827582546999963, 35.258582009000065],
            [-80.827626906999967, 35.258594318000064],
            [-80.827673670999957, 35.258605795000051],
            [-80.827731764999953, 35.25861911700008],
            [-80.827769856999964, 35.258627361000038],
            [-80.827836572999956, 35.25863948500006],
            [-80.827895416999979, 35.258648902000061],
            [-80.827914947999943, 35.258651905000079],
            [-80.828002215999959, 35.25866237300005],
            [-80.82801485799996, 35.258663494000075],
            [-80.828074441999945, 35.258668549000049],
            [-80.828149715999984, 35.258673191000071],
            [-80.82818204199998, 35.25867412000008],
            [-80.828242657999965, 35.258675265000079],
            [-80.82829851799994, 35.258675371000038],
            [-80.828300701999979, 35.258675337000057],
            [-80.828984411999954, 35.258664096000075],
            [-80.829056065999964, 35.258663437000052],
            [-80.829065778999961, 35.25866331800006],
            [-80.829071090999946, 35.258663230000025],
            [-80.832815012999959, 35.258594475000052],
            [-80.833162226999946, 35.258591894000062],
            [-80.83317489999996, 35.25859175100004],
            [-80.833178888999953, 35.258591686000045],
            [-80.833649330999947, 35.258583416000079],
            [-80.834070930999985, 35.258576346000041],
            [-80.834696978999943, 35.258567768000034],
            [-80.834699371999989, 35.258567733000064],
            [-80.83478745299999, 35.258564031000049],
            [-80.834787626999969, 35.258564018000072],
            [-80.834936211999945, 35.258553749000043],
            [-80.835015631999966, 35.258546309000053],
            [-80.835139825999988, 35.258531602000062],
            [-80.835147829999983, 35.258530634000067],
            [-80.835200501999964, 35.258523254000067],
            [-80.835300660999962, 35.258507536000025],
            [-80.835334725999985, 35.258501815000045],
            [-80.835371382999938, 35.258494814000073],
            [-80.835492002999956, 35.258470312000043],
            [-80.835540854999977, 35.258459587000061],
            [-80.835624765999967, 35.258437312000069],
            [-80.835629706999953, 35.258435843000029],
            [-80.835764324999957, 35.258395582000048],
            [-80.836022387999947, 35.25832087200007],
            [-80.836030561999962, 35.25831848200005],
            [-80.836068502999979, 35.25830674000008],
            [-80.836146775999964, 35.258281406000037],
            [-80.836269631999983, 35.258384767000052],
            [-80.836442854999973, 35.258531287000039],
            [-80.836475184999983, 35.258557721000045],
            [-80.836540659999969, 35.258606187000055],
            [-80.836609859999953, 35.25865103700005],
            [-80.836682488999941, 35.25869207900007],
            [-80.836758233999944, 35.258729137000046],
            [-80.836836772999959, 35.258762052000066],
            [-80.836917766999989, 35.258790683000029],
            [-80.837000870999987, 35.25881490900008],
            [-80.837085729999956, 35.258834623000041],
            [-80.837171977999958, 35.258849743000042],
            [-80.837259247999953, 35.25886020400003],
            [-80.837347164999983, 35.258865962000073],
            [-80.837435351999943, 35.258866990000058],
            [-80.837523432999944, 35.258863285000075],
            [-80.837611029999948, 35.258854863000067],
            [-80.837697766999952, 35.258841760000053],
            [-80.837783274999936, 35.258824031000074],
            [-80.837867185999983, 35.258801755000036],
            [-80.837949139999978, 35.258775023000055],
            [-80.838028786999985, 35.258743953000078],
            [-80.838105786999961, 35.258708676000026],
            [-80.83817980799995, 35.258669344000054],
            [-80.838250534999986, 35.258626126000024],
            [-80.838317663999987, 35.258579206000036],
            [-80.838380906999987, 35.258528785000067],
            [-80.838439994999987, 35.258475080000039],
            [-80.838441197999941, 35.258473909000031],
            [-80.838633527999946, 35.258286634000058],
            [-80.83868195499997, 35.258236614000054],
            [-80.838738081999963, 35.258175072000029],
            [-80.838743128999965, 35.258169502000044],
            [-80.838785955999981, 35.258119046000047],
            [-80.838836731999947, 35.258055527000067],
            [-80.838843940999936, 35.258046410000077],
            [-80.838882507999983, 35.257993974000044],
            [-80.839058464999937, 35.25773902800006],
            [-80.839689752999959, 35.256834940000033],
            [-80.83969971099998, 35.256820428000026],
            [-80.839711874999978, 35.256801971000073],
            [-80.839749444999939, 35.256743648000054],
            [-80.839777405999939, 35.256697685000063],
            [-80.839793239999949, 35.256669018000025],
            [-80.839825380999969, 35.256608551000056],
            [-80.839844449999987, 35.256570784000075],
            [-80.83984977199998, 35.256559483000046],
            [-80.839870270999938, 35.256515247000038],
            [-80.840164663999985, 35.255897306000065],
            [-80.840708052999958, 35.254766430000075],
            [-80.840708684999981, 35.25476511200003],
            [-80.840713817999983, 35.254754220000052],
            [-80.841070956999943, 35.253984331000026],
            [-80.841423483999961, 35.253837444000055],
            [-80.84143151699999, 35.25383406900005],
            [-80.841491298999983, 35.253807152000036],
            [-80.841550146999964, 35.253779043000065],
            [-80.841567358999953, 35.253770681000049],
            [-80.841641373999948, 35.253731347000041],
            [-80.841652990999989, 35.25372464700007],
            [-80.841708489999974, 35.253692275000049],
            [-80.841767593999975, 35.253655754000079],
            [-80.841804881999963, 35.253630458000032],
            [-80.841856571999983, 35.253594091000025],
            [-80.841886406999947, 35.253572465000047],
            [-80.841945718999966, 35.253525369000045],
            [-80.84199317599996, 35.253485307000062],
            [-80.841997100999947, 35.253481980000061],
            [-80.842056181999965, 35.253428273000054],
            [-80.842074385999979, 35.253410210000027],
            [-80.842094881999969, 35.253389428000048],
            [-80.842112249999957, 35.253373601000078],
            [-80.84214013899998, 35.253347366000071],
            [-80.842168066999989, 35.253319325000064],
            [-80.84221798599998, 35.253267495000046],
            [-80.842244729999948, 35.253238774000067],
            [-80.842269040999952, 35.253210867000064],
            [-80.842314989999977, 35.25315635100003],
            [-80.842340708999984, 35.253124684000056],
            [-80.84236666999999, 35.253090115000077],
            [-80.842407541999989, 35.253033385000037],
            [-80.842426753999973, 35.253005823000024],
            [-80.842446140999982, 35.252975991000028],
            [-80.842483055999935, 35.252917031000038],
            [-80.842503790999956, 35.252882442000043],
            [-80.842530059999945, 35.252833592000059],
            [-80.842592040999989, 35.25271035600008],
            [-80.842600671999946, 35.252692772000046],
            [-80.84261218599994, 35.252667835000068],
            [-80.842726181999979, 35.252411678000044],
            [-80.84283602499994, 35.252176600000041],
            [-80.84284459099996, 35.252157759000056],
            [-80.842955997999979, 35.251905707000049],
            [-80.843023621999976, 35.251757810000072],
            [-80.843027181999958, 35.251749932000052],
            [-80.843189112999937, 35.251387421000061],
            [-80.843312478999962, 35.251115678000076],
            [-80.843418535999945, 35.250886370000046],
            [-80.843421465999938, 35.250879977000068],
            [-80.84356319699998, 35.250567738000029],
            [-80.843566659999965, 35.250560019000034],
            [-80.843652530999975, 35.250366450000058],
            [-80.843786027999954, 35.250067371000057],
            [-80.843925128999956, 35.249760991000073],
            [-80.843926720999946, 35.249757465000073],
            [-80.844094213999938, 35.24938467700008],
            [-80.844229954999946, 35.249087583000062],
            [-80.844260068999972, 35.249095881000073],
            [-80.84434491899998, 35.249115590000031],
            [-80.844365380999989, 35.249119617000076],
            [-80.844474728999955, 35.249140393000062],
            [-80.844528237999953, 35.249149626000076],
            [-80.844633017999968, 35.249165892000065],
            [-80.844645285999945, 35.249167748000048],
            [-80.844672867999975, 35.249171571000034],
            [-80.844777026999964, 35.249185098000055],
            [-80.844807411999966, 35.249188753000055],
            [-80.844943710999985, 35.249203851000061],
            [-80.844973004999986, 35.249206828000069],
            [-80.845021095999982, 35.249210563000076],
            [-80.845141310999963, 35.249218118000044],
            [-80.845145864999949, 35.249218398000039],
            [-80.845346584999959, 35.249230451000074],
            [-80.845381846999942, 35.249232188000065],
            [-80.845383166999966, 35.249232239000037],
            [-80.845621225999935, 35.249241204000043],
            [-80.845634111999971, 35.249241639000047],
            [-80.84582439999997, 35.249247309000054],
            [-80.845874505999973, 35.24924991000006],
            [-80.845883151999942, 35.249250336000046],
            [-80.845975655999951, 35.249254649000079],
            [-80.845989011999961, 35.249255217000041],
            [-80.845996309999975, 35.249255482000024],
            [-80.846061407999969, 35.249257696000029],
            [-80.846110144999955, 35.249258629000053],
            [-80.84620674599995, 35.249259045000031],
            [-80.846265709999955, 35.249260763000052],
            [-80.84632438999995, 35.249263074000055],
            [-80.846325873999945, 35.249263131000077],
            [-80.846346505999975, 35.249263370000051],
            [-80.846368749999954, 35.249274816000025],
            [-80.846432857999957, 35.249305813000035],
            [-80.846511390999979, 35.249338722000061],
            [-80.846592379999947, 35.249367347000032],
            [-80.846675476999962, 35.249391566000043],
            [-80.846760327999959, 35.249411273000078],
            [-80.846846567999989, 35.249426387000028],
            [-80.846933828999965, 35.249436841000033],
            [-80.847021736999977, 35.249442591000047],
            [-80.847109913999986, 35.24944361200005],
            [-80.84719798499998, 35.249439901000073],
            [-80.84728556999994, 35.249431471000037],
            [-80.847372295999946, 35.249418361000039],
            [-80.847457791999943, 35.249400626000067],
            [-80.847541689999957, 35.249378343000046],
            [-80.847623631999966, 35.249351605000072],
            [-80.84770326599994, 35.249320528000055],
            [-80.84778025199995, 35.249285245000067],
            [-80.847854259999963, 35.249245907000045],
            [-80.847924973999966, 35.249202683000078],
            [-80.847992088999945, 35.24915575700004],
            [-80.848055319999958, 35.249105331000067],
            [-80.848114393999936, 35.249051621000035],
            [-80.848138847999962, 35.249027168000055],
            [-80.848217412999986, 35.248946273000058],
            [-80.848247624999942, 35.248913961000028],
            [-80.848269293999977, 35.24888917100003],
            [-80.848300019999954, 35.24885297600008],
            [-80.848328373999948, 35.248818189000076],
            [-80.848361219999958, 35.248773942000071],
            [-80.848388263999936, 35.248735514000032],
            [-80.848400582999943, 35.248717628000065],
            [-80.848418080999977, 35.248690797000052],
            [-80.848511856999949, 35.248542110000074],
            [-80.848528095999939, 35.248515489000056],
            [-80.848615630999973, 35.248367035000058],
            [-80.848750392999989, 35.248141327000042],
            [-80.848884228999964, 35.247928289000072],
            [-80.849084837999953, 35.247620195000025],
            [-80.849094975999947, 35.247604317000025],
            [-80.849232904999951, 35.247383961000025],
            [-80.849240659999964, 35.247371373000078],
            [-80.849367997999934, 35.247161386000073],
            [-80.849528196999984, 35.246924355000033],
            [-80.849667084999965, 35.246720793000065],
            [-80.849670560999982, 35.246715669000025],
            [-80.849676143999943, 35.246707298000047],
            [-80.84979328899999, 35.246529846000044],
            [-80.849830551999958, 35.246473843000047],
            [-80.849835202999941, 35.246466791000046],
            [-80.849877558999935, 35.246402021000051],
            [-80.849886714999968, 35.246387772000048],
            [-80.850038964999953, 35.24614658400003],
            [-80.850053442999979, 35.246122960000037],
            [-80.850225799999976, 35.245833144000073],
            [-80.850230199999942, 35.245825676000038],
            [-80.850386043999947, 35.245558555000059],
            [-80.850390019999963, 35.245551679000073],
            [-80.850394502999961, 35.24554377700008],
            [-80.850521966999963, 35.245316751000075],
            [-80.850627181999982, 35.245131635000064],
            [-80.850653712999986, 35.245090653000034],
            [-80.850672868999936, 35.245061769000074],
            [-80.850701709999953, 35.245015746000036],
            [-80.85072697999999, 35.244972992000044],
            [-80.850728033999985, 35.24497118000005],
            [-80.850735844999974, 35.244972460000042],
            [-80.850823100999946, 35.244982911000079],
            [-80.850911003999954, 35.244988659000057],
            [-80.850914602999978, 35.244988793000061],
            [-80.851012386999969, 35.244992336000053],
            [-80.85109695999995, 35.244993219000037],
            [-80.851185025999939, 35.244989505000035],
            [-80.851272606999942, 35.244981073000076],
            [-80.851358366999989, 35.244968132000054],
            [-80.85144956299996, 35.244951851000053],
            [-80.851450522999983, 35.244951680000042],
            [-80.851536012999986, 35.244933941000056],
            [-80.851619905999939, 35.244911655000067],
            [-80.851698230999943, 35.244886201000043],
            [-80.851764967999941, 35.24486250800004],
            [-80.851768578999952, 35.244861221000065],
            [-80.851848206999989, 35.244830141000079],
            [-80.851853779999942, 35.244827766000071],
            [-80.85198504899995, 35.244771502000049],
            [-80.852252351999937, 35.24465772800005],
            [-80.852314986999943, 35.24462931100004],
            [-80.852432614999941, 35.244572548000065],
            [-80.852444809999952, 35.244566592000069],
            [-80.852469017999965, 35.244554338000057],
            [-80.852553722999971, 35.24451044500006],
            [-80.852603516999977, 35.244483358000025],
            [-80.85263899499995, 35.244462406000025],
            [-80.85270204599999, 35.244423882000035],
            [-80.852737274999981, 35.244401606000054],
            [-80.852774192999959, 35.24437656200007],
            [-80.85284343099994, 35.244327867000038],
            [-80.852873620999958, 35.24430598400005],
            [-80.852936843999942, 35.244255555000052],
            [-80.85295118099998, 35.244243157000028],
            [-80.853007978999983, 35.244193267000071],
            [-80.853058656999963, 35.244155702000057],
            [-80.853062475999934, 35.244152859000053],
            [-80.853125698999975, 35.244102431000044],
            [-80.853181822999943, 35.244054636000044],
            [-80.853199097999948, 35.244043162000025],
            [-80.853208117999941, 35.244037233000029],
            [-80.853224884999975, 35.244031337000024],
            [-80.853342539999971, 35.24399057800008],
            [-80.853357154999969, 35.243985431000056],
            [-80.853382659999966, 35.243976043000032],
            [-80.853506933999938, 35.243929025000057],
            [-80.853561054999943, 35.243907332000049],
            [-80.853638033999971, 35.243872046000035],
            [-80.853694143999974, 35.243842738000069],
            [-80.853745422999964, 35.243814471000064],
            [-80.853763312999945, 35.243804437000051],
            [-80.853834018999976, 35.24376120900007],
            [-80.853845169999943, 35.243753835000064],
            [-80.85388723799997, 35.243725709000046],
            [-80.853943194999943, 35.243686155000034],
            [-80.854006416999937, 35.243635726000036],
            [-80.854009690999987, 35.243632927000078],
            [-80.854048572999943, 35.243599582000058],
            [-80.854104364999955, 35.243548667000027],
            [-80.854158871999971, 35.243492067000034],
            [-80.854188548999957, 35.24345909300007],
            [-80.854188699999952, 35.243458926000073],
            [-80.854238714999951, 35.243399347000036],
            [-80.854283873999975, 35.243337212000029],
            [-80.854306046999966, 35.243302889000063],
            [-80.854335664999951, 35.243255029000068],
            [-80.854353599999968, 35.243224927000028],
            [-80.854372693999949, 35.243190054000024],
            [-80.854543126999943, 35.242863799000077],
            [-80.854755859999955, 35.242477017000056],
            [-80.854861186999983, 35.242481369000075],
            [-80.854865964999988, 35.242481560000044],
            [-80.854884799999979, 35.242482174000031],
            [-80.855931416999965, 35.242510323000033],
            [-80.855934084999944, 35.242510393000032],
            [-80.856069975999958, 35.24251382500006],
            [-80.856909578999989, 35.242540317000078],
            [-80.856911793999984, 35.242540385000041],
            [-80.857560428999989, 35.242559971000048],
            [-80.857572818999984, 35.242560298000058],
            [-80.857792524999979, 35.242565272000036],
            [-80.858029048999981, 35.24269197600006],
            [-80.858038245999978, 35.242696858000045],
            [-80.858066685999972, 35.242711418000056],
            [-80.858816608999973, 35.243084775000057],
            [-80.859651630999963, 35.243513976000031],
            [-80.85966319399995, 35.243519853000066],
            [-80.860421226999961, 35.243900785000051],
            [-80.861572129999956, 35.24448086600006],
            [-80.861580482999955, 35.244485042000065],
            [-80.862166449999961, 35.244775589000028],
            [-80.862715281999954, 35.245052480000027],
            [-80.862718812999958, 35.24505425500007],
            [-80.862832333999961, 35.245111134000069],
            [-80.862836689999938, 35.245113308000043],
            [-80.863314130999981, 35.245350491000067],
            [-80.864085701999954, 35.245740674000047],
            [-80.864141078999978, 35.245767208000075],
            [-80.864219615999957, 35.245800105000058],
            [-80.864300606999961, 35.245828718000041],
            [-80.864383705999956, 35.245852925000065],
            [-80.864468557999942, 35.245872620000057],
            [-80.864554797999972, 35.245887721000031],
            [-80.864642056999969, 35.24589816200006],
            [-80.864729961999956, 35.24590390000003],
            [-80.86481813499995, 35.245904908000057],
            [-80.864906201999986, 35.245901183000058],
            [-80.864993781999942, 35.245892741000034],
            [-80.865080500999966, 35.24587961900005],
            [-80.865165988999934, 35.245861871000045],
            [-80.865249878999975, 35.245839575000048],
            [-80.865331810999976, 35.24581282500003],
            [-80.865411434999942, 35.245781737000073],
            [-80.865488409999955, 35.245746442000041],
            [-80.865562405999981, 35.245707094000068],
            [-80.865633106999951, 35.245663859000047],
            [-80.865700209999943, 35.245616924000046],
            [-80.865763425999944, 35.245566489000055],
            [-80.865822486999946, 35.245512770000062],
            [-80.865877137999973, 35.245455997000079],
            [-80.865927144999944, 35.245396413000037],
            [-80.865939632999982, 35.245380129000068],
            [-80.866342199999963, 35.244844902000068],
            [-80.866345428999978, 35.244840587000056],
            [-80.866711578999968, 35.244348744000035],
            [-80.866718815999945, 35.244338908000032],
            [-80.866963186999953, 35.244002842000043],
            [-80.866963776999967, 35.244002028000068],
            [-80.867157970999983, 35.24373444500003],
            [-80.867220659999987, 35.243648890000031],
            [-80.867245185999934, 35.243613992000064],
            [-80.867247721999945, 35.243610207000074],
            [-80.867307635999964, 35.243520424000053],
            [-80.867335558999969, 35.243476247000046],
            [-80.867390401999955, 35.243384484000046],
            [-80.86740003999995, 35.243368016000034],
            [-80.867411257999947, 35.243347913000036],
            [-80.867925523999986, 35.242401481000059],
            [-80.86793550699997, 35.242382639000027],
            [-80.868008871999962, 35.242240578000064],
            [-80.868312566999975, 35.24170127900004],
            [-80.868320284999982, 35.24168731900005],
            [-80.868534390999969, 35.241292831000067],
            [-80.868941646999986, 35.241104391000079],
            [-80.868992864999939, 35.241082998000024],
            [-80.869044752999969, 35.241063861000043],
            [-80.869099571999982, 35.241045936000035],
            [-80.86915492899999, 35.241030183000078],
            [-80.869195800999989, 35.241020185000025],
            [-80.869234466999956, 35.241012341000044],
            [-80.869268641999952, 35.241006836000054],
            [-80.869591617999959, 35.240963174000058],
            [-80.870355144999962, 35.240862757000059],
            [-80.870504166999979, 35.240847078000058],
            [-80.870644143999982, 35.240835622000077],
            [-80.870661084999938, 35.240836948000037],
            [-80.870670691999976, 35.240838179000036],
            [-80.870683437999958, 35.240840462000051],
            [-80.870698094999966, 35.240843786000028],
            [-80.870708475999947, 35.240846667000028],
            [-80.870718055999987, 35.240849880000042],
            [-80.870728758999974, 35.240854065000065],
            [-80.87073847299996, 35.240858478000064],
            [-80.870782738999935, 35.240881363000028],
            [-80.870849504999967, 35.240913717000069],
            [-80.870928039999967, 35.240946610000037],
            [-80.871009027999946, 35.240975218000074],
            [-80.871092124999961, 35.240999420000037],
            [-80.871176971999944, 35.241019110000025],
            [-80.871263207999959, 35.241034207000041],
            [-80.871350463999988, 35.241044643000066],
            [-80.871438363999971, 35.241050376000032],
            [-80.871526531999962, 35.241051379000055],
            [-80.871614592999947, 35.241047650000041],
            [-80.871702166999967, 35.241039203000071],
            [-80.871788878999951, 35.241026076000026],
            [-80.871874360999982, 35.241008323000074],
            [-80.871958243999984, 35.240986022000072],
            [-80.872040168999945, 35.24095926800004],
            [-80.872119785999985, 35.240928175000079],
            [-80.872196753999958, 35.240892877000078],
            [-80.872270741999955, 35.240853524000045],
            [-80.872341435999942, 35.240810285000066],
            [-80.872408529999973, 35.240763346000051],
            [-80.872471738999934, 35.240712907000045],
            [-80.872530790999974, 35.240659185000027],
            [-80.872585433999973, 35.240602409000076],
            [-80.872606949999977, 35.240577785000028],
            [-80.873120028999949, 35.239973235000036],
            [-80.873839758999964, 35.239169692000075],
            [-80.873847263999949, 35.239161231000026],
            [-80.875109479999935, 35.237723659000039],
            [-80.875418815999979, 35.237375214000053],
            [-80.876210896999964, 35.236533031000079],
            [-80.87622842799999, 35.236513968000054],
            [-80.876239915999975, 35.236500996000075],
            [-80.876610104999941, 35.236076541000045],
            [-80.876631589999988, 35.236054266000053],
            [-80.876659290999953, 35.236024525000062],
            [-80.876683441999944, 35.23599678000005],
            [-80.876726640999948, 35.235945497000046],
            [-80.87675248499994, 35.235913654000058],
            [-80.876778373999969, 35.235879151000063],
            [-80.876817052999968, 35.235825421000072],
            [-80.876836300999969, 35.235797780000041],
            [-80.876862532999951, 35.235756817000038],
            [-80.876896418999934, 35.235701099000039],
            [-80.876910272999964, 35.235677630000055],
            [-80.876935841999966, 35.235630110000045],
            [-80.876964936999968, 35.235572406000074],
            [-80.876974231999952, 35.235553481000068],
            [-80.876997230999962, 35.235501596000063],
            [-80.877021275999937, 35.235442823000028],
            [-80.877027768999938, 35.235426534000055],
            [-80.877041031999966, 35.23539022600005],
            [-80.877152186999979, 35.23506582300007],
            [-80.877162917999954, 35.235032520000061],
            [-80.877168991999952, 35.23501153400008],
            [-80.87734755699995, 35.234366610000052],
            [-80.877353720999963, 35.23434317300007],
            [-80.877451061999977, 35.233952568000063],
            [-80.87745721899995, 35.233926241000063],
            [-80.877457985999968, 35.233922694000057],
            [-80.877559302999941, 35.233450272000027],
            [-80.877565886999946, 35.233416522000027],
            [-80.877592290999985, 35.233266338000078],
            [-80.877597177999974, 35.233235466000053],
            [-80.877643712999941, 35.232905192000032],
            [-80.877643936999959, 35.232903594000049],
            [-80.877687032999972, 35.232593779000069],
            [-80.877698040999974, 35.232536932000073],
            [-80.877711543999965, 35.232481096000072],
            [-80.877727512999968, 35.232427810000047],
            [-80.877751903999979, 35.232359124000027],
            [-80.877771677999988, 35.232310441000038],
            [-80.877793127999951, 35.232264282000074],
            [-80.877828247999958, 35.232197092000035],
            [-80.877833567999971, 35.232186458000058],
            [-80.877858688999936, 35.232165160000079],
            [-80.877899663999983, 35.232135921000065],
            [-80.877998507999962, 35.232070211000064],
            [-80.878007109999942, 35.232064445000049],
            [-80.878215385999965, 35.231923637000079],
            [-80.878321429999971, 35.231852650000064],
            [-80.878325378999989, 35.231849996000051],
            [-80.878435808999939, 35.231775498000047],
            [-80.878690287999973, 35.231607342000075],
            [-80.878697824999961, 35.23160232500004],
            [-80.878976810999973, 35.231415240000047],
            [-80.878999189999945, 35.231399899000053],
            [-80.879074725999942, 35.231346967000036],
            [-80.879096920999984, 35.231331066000052],
            [-80.879174870999975, 35.231273976000068],
            [-80.879185041999961, 35.231266451000067],
            [-80.879248238999935, 35.231216009000036],
            [-80.879307278999988, 35.23116228400005],
            [-80.879334532999962, 35.231134914000052],
            [-80.87936724299999, 35.231100971000046],
            [-80.87939462199995, 35.231071561000078],
            [-80.87944461099994, 35.231011972000033],
            [-80.879489742999965, 35.230949827000074],
            [-80.87949363599995, 35.230944004000037],
            [-80.879525733999969, 35.230895640000028],
            [-80.879561922999983, 35.230837031000078],
            [-80.879596782999954, 35.230770585000073],
            [-80.879616734999956, 35.230726056000037],
            [-80.87963207699994, 35.230689405000078],
            [-80.879641613999979, 35.230665758000043],
            [-80.879648652999947, 35.230647052000052],
            [-80.879667289999986, 35.230595922000077],
            [-80.879684241999939, 35.230545017000054],
            [-80.87969875999994, 35.230491225000037],
            [-80.87971173699998, 35.230436748000045],
            [-80.879715610999938, 35.230419789000052],
            [-80.879728322999938, 35.230348204000052],
            [-80.879735301999972, 35.230276090000075],
            [-80.879735652999955, 35.230269455000041],
            [-80.879739456999971, 35.230192447000036],
            [-80.879740320999986, 35.230126747000043],
            [-80.879735768999979, 35.230054503000076],
            [-80.879725466999957, 35.22998265800004],
            [-80.879709460999948, 35.229911518000051],
            [-80.879701780999937, 35.229884352000056],
            [-80.879682108999987, 35.229818540000053],
            [-80.879709919999982, 35.22977215700007],
            [-80.879737961999979, 35.229726291000077],
            [-80.87975312399999, 35.229700674000071],
            [-80.879787982999972, 35.229634228000066],
            [-80.879788265999935, 35.229633635000027],
            [-80.879835388999936, 35.229534883000042],
            [-80.879857091999952, 35.229486048000069],
            [-80.879909159999954, 35.229359741000053],
            [-80.879916660999982, 35.229340993000051],
            [-80.879922178999948, 35.229326428000036],
            [-80.879959219999989, 35.22922623200003],
            [-80.879974760999971, 35.229180713000062],
            [-80.880003616999943, 35.229088660000059],
            [-80.880005469999958, 35.229082679000044],
            [-80.880041547999951, 35.228964863000044],
            [-80.880042625999977, 35.228961315000049],
            [-80.88004830999995, 35.228941729000042],
            [-80.880091866999976, 35.228785323000068],
            [-80.880095480999955, 35.228771968000046],
            [-80.880123444999981, 35.228665502000069],
            [-80.880128347999971, 35.228645981000057],
            [-80.880151769999941, 35.228548287000024],
            [-80.880155960999957, 35.228529998000056],
            [-80.880162422999945, 35.228497675000028],
            [-80.880175297999983, 35.228426708000029],
            [-80.880178953999973, 35.228405096000074],
            [-80.880193794999968, 35.228310512000064],
            [-80.880196387999945, 35.228292862000046],
            [-80.880199887999936, 35.228263882000078],
            [-80.880222936999985, 35.228043531000026],
            [-80.88023280699997, 35.227954151000063],
            [-80.88023511199998, 35.227930426000057],
            [-80.880268880999949, 35.227528491000044],
            [-80.880283851999934, 35.227394424000067],
            [-80.880320817999973, 35.227080003000026],
            [-80.880337754999971, 35.226970118000054],
            [-80.880560339999988, 35.226987342000029],
            [-80.880569694999963, 35.226988039000048],
            [-80.880945426999972, 35.227014945000064],
            [-80.880948960999945, 35.227015194000046],
            [-80.881630774999962, 35.227062532000048],
            [-80.881639709999945, 35.227063128000054],
            [-80.881672036999987, 35.227065194000033],
            [-80.881856447999951, 35.227078146000053],
            [-80.881869131999963, 35.227078988000073],
            [-80.882123211999954, 35.227094851000061],
            [-80.882445578999977, 35.227115526000034],
            [-80.882818851999957, 35.227141225000025],
            [-80.882824366999955, 35.227141596000024],
            [-80.882931217999953, 35.22714859000007],
            [-80.882975587999965, 35.227153853000061],
            [-80.882986509999967, 35.227155004000053],
            [-80.882992593999973, 35.227155780000032],
            [-80.883066738999958, 35.227163785000073],
            [-80.883075362999989, 35.227164692000031],
            [-80.883183912999982, 35.227175828000043],
            [-80.883200564999981, 35.227177451000045],
            [-80.883247450999988, 35.227181095000049],
            [-80.883700535999935, 35.227209767000033],
            [-80.883718967999982, 35.227210829000057],
            [-80.884130781999943, 35.227232229000037],
            [-80.884369803999959, 35.227250166000033],
            [-80.884425688999954, 35.227253401000041],
            [-80.884778970999946, 35.227267793000067],
            [-80.88516638699997, 35.227285747000053],
            [-80.885179783999945, 35.227286313000036],
            [-80.885184615999947, 35.22728649000004],
            [-80.885740113999987, 35.227306041000077],
            [-80.885988939999947, 35.227315927000063],
            [-80.885991536999938, 35.227316028000075],
            [-80.886023973999954, 35.227316944000052],
            [-80.886306618999981, 35.227322130000061],
            [-80.886604506999959, 35.227330306000056],
            [-80.886887827999942, 35.227339207000057],
            [-80.887335295999947, 35.22735371400006],
            [-80.887358739999968, 35.227354306000052],
            [-80.887512802999936, 35.227357097000038],
            [-80.887568157999965, 35.227357167000036],
            [-80.887656202999949, 35.227353426000036],
            [-80.887743760999967, 35.227344968000068],
            [-80.88783045699995, 35.227331829000036],
            [-80.887915920999944, 35.227314064000041],
            [-80.887999784999977, 35.227291753000031],
            [-80.888081690999968, 35.227264987000069],
            [-80.888161287999935, 35.227233884000043],
            [-80.88823823599995, 35.227198575000045],
            [-80.888312203999988, 35.227159212000061],
            [-80.88838287699997, 35.227115964000063],
            [-80.888449951999974, 35.22706901600003],
            [-80.88851313899994, 35.227018569000052],
            [-80.888572170999964, 35.226964839000061],
            [-80.888626793999947, 35.22690805600007],
            [-80.888676773999975, 35.226848462000078],
            [-80.888721896999982, 35.226786314000037],
            [-80.888761968999972, 35.226721878000035],
            [-80.888796819999982, 35.226655429000061],
            [-80.888826298999959, 35.226587252000058],
            [-80.888850280999975, 35.226517639000065],
            [-80.888868663999972, 35.226446888000055],
            [-80.888881366999954, 35.226375301000076],
            [-80.888888337999958, 35.226303187000042],
            [-80.888889544999984, 35.226230852000072],
            [-80.888884984999947, 35.226158608000048],
            [-80.888874675999944, 35.226086763000069],
            [-80.888858661999961, 35.226015625000059],
            [-80.888837012999943, 35.225945499000034],
            [-80.888809819999949, 35.22587668500006],
            [-80.888777201999972, 35.225809478000031],
            [-80.888739295999983, 35.225744165000037],
            [-80.888696265999954, 35.225681026000075],
            [-80.888648294999939, 35.225620332000062],
            [-80.888595588999976, 35.225562341000057],
            [-80.88853837399995, 35.225507304000075],
            [-80.888476895999986, 35.225455455000031],
            [-80.88841141599994, 35.225407016000077],
            [-80.888342215999955, 35.225362195000059],
            [-80.888269590999982, 35.225321184000052],
            [-80.888193852999962, 35.225284159000068],
            [-80.888115325999934, 35.225251277000041],
            [-80.888034346999973, 35.225222680000059],
            [-80.887951261999945, 35.225198490000025],
            [-80.887866426999949, 35.225178810000045],
            [-80.887780202999977, 35.225163727000051],
            [-80.887692963999939, 35.225153302000024],
            [-80.887605079999958, 35.225147581000044],
            [-80.887572281999951, 35.225146659000075],
            [-80.887429947999976, 35.225144080000064],
            [-80.886993385999972, 35.225129927000069],
            [-80.88699173699996, 35.225129874000061],
            [-80.886704343999952, 35.225120845000049],
            [-80.88669783499995, 35.225120653000033],
            [-80.886389239999971, 35.225112184000068],
            [-80.886374315999944, 35.225111842000047],
            [-80.886101740999948, 35.22510684100007],
            [-80.885866715999953, 35.225097503000029],
            [-80.885864118999962, 35.225097402000074],
            [-80.88585928599997, 35.22509722500007],
            [-80.885309205999988, 35.22507786400007],
            [-80.884926329999985, 35.22506012100007],
            [-80.88491716499999, 35.225059722000026],
            [-80.884618386999989, 35.225047550000056],
            [-80.884617755999955, 35.224993720000043],
            [-80.884617574999936, 35.224983356000052],
            [-80.884617453999965, 35.22497863500007],
            [-80.884589907999953, 35.224006860000031],
            [-80.884600241999976, 35.223782281000069],
            [-80.884600958999954, 35.223719577000054],
            [-80.884600948999946, 35.223719147000054],
            [-80.884587095999962, 35.22313248200004],
            [-80.884580611999979, 35.222769769000024],
            [-80.884579661999965, 35.222535204000053],
            [-80.884585920999939, 35.222044937000078],
            [-80.884593549999977, 35.221782924000024],
            [-80.884604807999949, 35.221545226000046],
            [-80.884629638999968, 35.221239547000039],
            [-80.884667953999951, 35.220873974000028],
            [-80.884720497999979, 35.220384886000033],
            [-80.884806869999977, 35.21960255700003],
            [-80.884807092999949, 35.219600513000046],
            [-80.884906433999959, 35.218681728000035],
            [-80.88502329399995, 35.217639610000049],
            [-80.88509546399996, 35.217086239000025],
            [-80.885097739999935, 35.217067250000071],
            [-80.885123988999965, 35.216826835000063],
            [-80.885126130999936, 35.21680471500008],
            [-80.885130513999968, 35.21675288800003],
            [-80.88513229299997, 35.216727692000063],
            [-80.885133403999987, 35.216701260000036],
            [-80.885135826999942, 35.21661305300006],
            [-80.885135925999975, 35.21656715000006],
            [-80.885135855999977, 35.216564298000037],
            [-80.885133532999987, 35.216475934000073],
            [-80.88513046099996, 35.216421464000064],
            [-80.885122840999941, 35.21633341200004],
            [-80.885121426999945, 35.216318491000038],
            [-80.885115657999961, 35.216273519000026],
            [-80.885102186999973, 35.216185788000075],
            [-80.885097650999967, 35.216158914000061],
            [-80.885093320999943, 35.21613688900004],
            [-80.885075120999943, 35.216049918000067],
            [-80.885064131999968, 35.21600340100008],
            [-80.88504730599999, 35.215939540000079],
            [-80.885046618999979, 35.215936943000031],
            [-80.885042864999946, 35.215923276000069],
            [-80.885036481999975, 35.215900703000045],
            [-80.885023349999983, 35.215858120000064],
            [-80.884994866999989, 35.21577291300008],
            [-80.884990108999943, 35.215759036000065],
            [-80.884974737999983, 35.215718269000035],
            [-80.884940986999936, 35.215634517000069],
            [-80.884929171999943, 35.215606469000079],
            [-80.884919040999989, 35.215584177000039],
            [-80.884880295999949, 35.215501649000032],
            [-80.884857815999965, 35.215456732000064],
            [-80.884850564999965, 35.215443347000075],
            [-80.884836291999989, 35.215417462000062],
            [-80.884820842999943, 35.215390423000031],
            [-80.884790639999949, 35.215339365000034],
            [-80.884775441999977, 35.215314475000071],
            [-80.884769783999957, 35.21530559100006],
            [-80.884753574999934, 35.215280423000024],
            [-80.884743134999951, 35.215264529000024],
            [-80.884709893999968, 35.215214893000052],
            [-80.884689492999939, 35.215185505000079],
            [-80.884671904999948, 35.215161045000059],
            [-80.88466538199998, 35.215152071000034],
            [-80.884651528999939, 35.215133627000057],
            [-80.88461469899994, 35.215085651000038],
            [-80.884601616999987, 35.215068943000063],
            [-80.884582648999981, 35.215045192000048],
            [-80.884564132999969, 35.215022617000045],
            [-80.884544334999987, 35.214999108000029],
            [-80.884541824999985, 35.21499613900005],
            [-80.884526993999941, 35.214979001000074],
            [-80.884506648999945, 35.214955959000065],
            [-80.884502951999934, 35.214951793000068],
            [-80.884482610999953, 35.214928979000035],
            [-80.884456268999941, 35.214900417000024],
            [-80.884413654999946, 35.214855736000061],
            [-80.884405827999956, 35.21484760900006],
            [-80.884397306999972, 35.214838936000035],
            [-80.884375593999948, 35.21481706000003],
            [-80.88434762899999, 35.214789795000058],
            [-80.884302268999988, 35.214746990000037],
            [-80.884281549999969, 35.214727889000073],
            [-80.884273604999976, 35.214720795000062],
            [-80.884249957999941, 35.214699865000057],
            [-80.884234494999987, 35.214686406000055],
            [-80.884210579999944, 35.214665937000063],
            [-80.884199876999958, 35.214656880000064],
            [-80.88417568999995, 35.214636645000041],
            [-80.884164786999975, 35.214627629000063],
            [-80.884140324999976, 35.214607629000056],
            [-80.884123871999975, 35.214594405000071],
            [-80.884112805999962, 35.214585760000034],
            [-80.88408751999998, 35.214566230000059],
            [-80.884070534999978, 35.214553336000051],
            [-80.884044978999952, 35.214534266000044],
            [-80.884033475999956, 35.214525781000077],
            [-80.88400764499994, 35.214506946000029],
            [-80.883984455999951, 35.214490423000029],
            [-80.883958078999967, 35.214472055000044],
            [-80.883955353999966, 35.214470161000065],
            [-80.883937252999942, 35.214457846000073],
            [-80.883883683999954, 35.214422038000066],
            [-80.883850327999937, 35.214400451000074],
            [-80.883822861999988, 35.214383245000079],
            [-80.883810678999964, 35.214375702000041],
            [-80.883782939999946, 35.214358729000026],
            [-80.883777388999988, 35.214355351000052],
            [-80.883758374999957, 35.214344050000079],
            [-80.883730087999936, 35.214327544000071],
            [-80.883703151999953, 35.21431222700005],
            [-80.883674601999985, 35.214296411000078],
            [-80.883669368999961, 35.214293527000052],
            [-80.883640539999988, 35.214277717000073],
            [-80.883619109999984, 35.214266204000069],
            [-80.883607782999945, 35.214260310000043],
            [-80.883578411999963, 35.214245194000057],
            [-80.883570635999945, 35.214241222000055],
            [-80.883541268999977, 35.214226335000035],
            [-80.883520543999964, 35.214216040000053],
            [-80.883490909999978, 35.214201616000025],
            [-80.883465031999947, 35.21418933700005],
            [-80.883434848999968, 35.214175379000039],
            [-80.883424829999967, 35.214170791000072],
            [-80.883413655999959, 35.214165782000066],
            [-80.883383205999962, 35.214152286000058],
            [-80.88335520399994, 35.214140222000026],
            [-80.883293493999986, 35.214114389000031],
            [-80.883254155999964, 35.214098577000073],
            [-80.883252057999982, 35.214097770000024],
            [-80.883189552999966, 35.214073780000035],
            [-80.883153789999938, 35.214060570000072],
            [-80.883122278999963, 35.214049381000052],
            [-80.883104902999946, 35.214043330000038],
            [-80.883072837999975, 35.214032380000049],
            [-80.883047107999971, 35.214023847000078],
            [-80.88303648599998, 35.214020471000026],
            [-80.882971832999942, 35.214000179000038],
            [-80.882940858999973, 35.213990813000066],
            [-80.882907988999989, 35.213981247000049],
            [-80.882888780999963, 35.213975791000053],
            [-80.882855920999987, 35.21396668400007],
            [-80.882833652999977, 35.213960688000043],
            [-80.882811935999939, 35.213955179000038],
            [-80.882737562999978, 35.213936871000044],
            [-80.882704918999934, 35.213929202000031],
            [-80.881848538999975, 35.213737561000073],
            [-80.881831293999937, 35.213733802000036],
            [-80.880627767999954, 35.213478470000041],
            [-80.879232983999941, 35.213181901000041],
            [-80.879232379999962, 35.213181772000041],
            [-80.878577203999953, 35.213042723000058],
            [-80.878512358999956, 35.213026624000065],
            [-80.87845820299998, 35.213012320000075],
            [-80.878405403999977, 35.21299738600004],
            [-80.878349973999946, 35.212980770000058],
            [-80.878294051999944, 35.212963181000077],
            [-80.878239999999948, 35.212945270000034],
            [-80.878185594999934, 35.212926339000035],
            [-80.878134533999969, 35.212907679000068],
            [-80.87808081299994, 35.212886916000059],
            [-80.878027037999971, 35.212865471000043],
            [-80.877973981999958, 35.212843153000051],
            [-80.87792008699995, 35.212819812000077],
            [-80.877868940999974, 35.212796568000044],
            [-80.877819461999934, 35.212773301000027],
            [-80.877770242999986, 35.212748886000043],
            [-80.877719608999939, 35.212723013000073],
            [-80.877671144999965, 35.212697091000052],
            [-80.87762134899998, 35.212669571000049],
            [-80.877573989999973, 35.212642384000048],
            [-80.877526730999989, 35.212614146000078],
            [-80.877479593999965, 35.212584998000068],
            [-80.877434181999945, 35.212555878000046],
            [-80.877386748999982, 35.212524247000033],
            [-80.877341023999975, 35.212492990000044],
            [-80.877298368999959, 35.212462523000056],
            [-80.877255341999955, 35.212430741000048],
            [-80.877211663999958, 35.21239720300008],
            [-80.877169974999958, 35.212364259000026],
            [-80.87712793299994, 35.212329571000055],
            [-80.877085389999934, 35.212293612000053],
            [-80.877046915999983, 35.212259818000064],
            [-80.877006983999934, 35.212223368000025],
            [-80.876969919999965, 35.21218854500006],
            [-80.876933113999939, 35.212152217000039],
            [-80.876893797999969, 35.212112433000073],
            [-80.876858126999934, 35.21207505600006],
            [-80.876822039999979, 35.212035728000046],
            [-80.876786806999974, 35.211996176000071],
            [-80.876756226999987, 35.211960390000058],
            [-80.876722782999934, 35.211919219000038],
            [-80.876689053999939, 35.211876936000067],
            [-80.876657505999958, 35.211835375000078],
            [-80.876629310999988, 35.211796679000031],
            [-80.876599685999963, 35.211754574000054],
            [-80.876570743999935, 35.211711635000029],
            [-80.876542550999943, 35.211668271000065],
            [-80.876515394999956, 35.211624604000065],
            [-80.876490371999978, 35.211582813000064],
            [-80.876465300999939, 35.211538423000036],
            [-80.876450428999988, 35.211511311000038],
            [-80.876442471999951, 35.211496537000073],
            [-80.876418926999975, 35.211451081000064],
            [-80.876396897999939, 35.21140629100006],
            [-80.876375836999955, 35.21136137600007],
            [-80.876355979999971, 35.211316476000036],
            [-80.876336422999941, 35.211269937000054],
            [-80.876318811999965, 35.211225563000028],
            [-80.876301274999946, 35.211178097000072],
            [-80.876284343999941, 35.211130277000052],
            [-80.876269641999954, 35.211085321000041],
            [-80.876256891999958, 35.211043453000059],
            [-80.876225085999977, 35.21092578400004],
            [-80.876210042999958, 35.210875685000076],
            [-80.876177348999988, 35.210776800000076],
            [-80.876171638999949, 35.210760056000026],
            [-80.876171067999962, 35.210758433000024],
            [-80.876136143999986, 35.210659584000041],
            [-80.87612769499998, 35.210636606000037],
            [-80.876090280999961, 35.210538709000048],
            [-80.876085011999976, 35.210525226000073],
            [-80.876045924999971, 35.210427357000071],
            [-80.87603742899995, 35.210406771000066],
            [-80.875996131999955, 35.210309851000034],
            [-80.87599174099995, 35.210299704000079],
            [-80.87598776599998, 35.210290779000047],
            [-80.87594424599996, 35.210194353000077],
            [-80.875936153999987, 35.210176878000027],
            [-80.875890699999957, 35.210081167000055],
            [-80.875883672999976, 35.210066675000064],
            [-80.875836553999989, 35.209971450000069],
            [-80.875825145999954, 35.209949100000074],
            [-80.875775537999971, 35.209854828000061],
            [-80.875773438999943, 35.209850859000028],
            [-80.875766769999984, 35.209838527000045],
            [-80.875715219999961, 35.20974474500008],
            [-80.875706829999956, 35.209729781000078],
            [-80.875653622999948, 35.209636709000051],
            [-80.87564077899998, 35.209614868000074],
            [-80.875585082999976, 35.209522753000044],
            [-80.875576011999954, 35.209508028000073],
            [-80.875518657999976, 35.209416623000038],
            [-80.875517743999978, 35.209415169000067],
            [-80.875507403999961, 35.209399075000078],
            [-80.875448119999987, 35.20930861800008],
            [-80.875437146999957, 35.209292211000047],
            [-80.875375923999968, 35.209202471000026],
            [-80.875365927999951, 35.209188072000074],
            [-80.875342563999936, 35.209154999000077],
            [-80.875333363999971, 35.209142176000057],
            [-80.875228768999989, 35.208998601000076],
            [-80.87522626699996, 35.208995180000045],
            [-80.875210849999974, 35.208974699000066],
            [-80.87514383599995, 35.208887797000045],
            [-80.875129846999982, 35.208870036000064],
            [-80.875060905999987, 35.208784309000066],
            [-80.87505256999998, 35.208774069000071],
            [-80.87498252599994, 35.208689047000064],
            [-80.87497231499998, 35.208676829000069],
            [-80.874966430999962, 35.208669944000064],
            [-80.87489418499996, 35.208586100000048],
            [-80.874875887999963, 35.20856537800006],
            [-80.874745326999971, 35.20842106200007],
            [-80.874716822999972, 35.208390672000064],
            [-80.87465962999994, 35.208335628000043],
            [-80.874647017999962, 35.208324421000043],
            [-80.87441976599996, 35.208125266000025],
            [-80.874407617999964, 35.208114758000079],
            [-80.874109616999988, 35.207860362000076],
            [-80.874100249999969, 35.207852445000071],
            [-80.874085739999941, 35.207840303000069],
            [-80.874078614999974, 35.207834385000069],
            [-80.871015245999956, 35.205309150000062],
            [-80.869876498999986, 35.204370030000064],
            [-80.869483599999967, 35.204043366000064],
            [-80.869481508999968, 35.204041632000042],
            [-80.869277766999971, 35.203872982000064],
            [-80.869079186999954, 35.203707880000024],
            [-80.868964351999978, 35.203608752000036],
            [-80.868937374999973, 35.203586121000058],
            [-80.868809992999957, 35.203482286000053],
            [-80.86879517999995, 35.203470396000057],
            [-80.868781202999969, 35.203459505000069],
            [-80.868650267999953, 35.203358931000025],
            [-80.868618531999971, 35.203335319000075],
            [-80.86848348999996, 35.203238013000032],
            [-80.868463752999958, 35.203224066000075],
            [-80.868457302999957, 35.203219626000077],
            [-80.868319822999979, 35.203125564000061],
            [-80.86831794699998, 35.203124282000033],
            [-80.867993123999952, 35.202902859000062],
            [-80.867866547999938, 35.202810242000055],
            [-80.867738530999986, 35.202711673000067],
            [-80.867609543999947, 35.202606996000043],
            [-80.867381264999949, 35.202415464000069],
            [-80.867378108999958, 35.202412825000067],
            [-80.867299600999957, 35.202347395000061],
            [-80.867274272999964, 35.202326836000054],
            [-80.867266032999964, 35.202320377000035],
            [-80.86714757499999, 35.202228310000066],
            [-80.867117553999947, 35.202205676000062],
            [-80.866995817999964, 35.202116638000064],
            [-80.866968629999974, 35.202097280000032],
            [-80.866963655999939, 35.202093851000029],
            [-80.866838644999973, 35.202008068000055],
            [-80.866803202999961, 35.201984573000061],
            [-80.866111712999952, 35.201541798000051],
            [-80.866082959999972, 35.201523889000043],
            [-80.866010367999934, 35.20148286500006],
            [-80.866006350999953, 35.201480758000059],
            [-80.865195053999969, 35.201057003000074],
            [-80.86518737199998, 35.201053021000064],
            [-80.864046255999938, 35.200465817000065],
            [-80.863682465999943, 35.200272534000078],
            [-80.863677719999941, 35.200270024000076],
            [-80.863676217999966, 35.200269234000075],
            [-80.863437591999968, 35.200143978000028],
            [-80.863414713999987, 35.200132233000033],
            [-80.863242543999945, 35.200045799000065],
            [-80.863209385999937, 35.200029683000025],
            [-80.86314761999995, 35.200000633000059],
            [-80.863129454999978, 35.199992243000054],
            [-80.863110976999963, 35.19998401600003],
            [-80.86300416499995, 35.19993733900003],
            [-80.862947065999947, 35.199913797000079],
            [-80.86283812399995, 35.199871505000033],
            [-80.862835209999957, 35.199870376000035],
            [-80.862786107999966, 35.199852406000048],
            [-80.862674738999942, 35.199813814000038],
            [-80.862642895999954, 35.199803171000042],
            [-80.862618897999937, 35.199795657000038],
            [-80.862505672999987, 35.199761219000038],
            [-80.862446618999968, 35.199744524000039],
            [-80.862394420999976, 35.199731819000078],
            [-80.862038810999934, 35.199651644000028],
            [-80.86200620599999, 35.199644651000028],
            [-80.861979411999982, 35.19963943700003],
            [-80.861831909999978, 35.199612032000061],
            [-80.861304658999984, 35.19950263700008],
            [-80.861298727999952, 35.199501417000079],
            [-80.86129841099995, 35.199501353000073],
            [-80.860872944999983, 35.19941484900005],
            [-80.860787070999947, 35.199399810000045],
            [-80.860699862999979, 35.199389365000059],
            [-80.860612007999976, 35.199383625000053],
            [-80.860523883999974, 35.199382613000068],
            [-80.860435868999957, 35.199386335000042],
            [-80.860348338999984, 35.199394774000041],
            [-80.860261667999964, 35.199407893000057],
            [-80.860176227999943, 35.199425637000047],
            [-80.860092384999973, 35.199447930000076],
            [-80.860010496999962, 35.199474677000069],
            [-80.859930916999986, 35.199505762000058],
            [-80.859853981999947, 35.199541053000075],
            [-80.859780024999964, 35.19958039800008],
            [-80.859709360999943, 35.19962362900003],
            [-80.859642292999979, 35.199670562000051],
            [-80.859579107999934, 35.199720995000064],
            [-80.859520075999967, 35.199774711000032],
            [-80.859465450999949, 35.199831481000047],
            [-80.859415466999963, 35.199891063000052],
            [-80.859370336999973, 35.199953200000039],
            [-80.859330254999975, 35.200017627000079],
            [-80.859295390999989, 35.200084068000024],
            [-80.859265895999954, 35.200152238000044],
            [-80.859241896999947, 35.200221845000044],
            [-80.859223494999981, 35.200292592000039],
            [-80.859210769999947, 35.200364176000051],
            [-80.859203774999969, 35.200436289000038],
            [-80.859202540999945, 35.200508623000076],
            [-80.859207073999983, 35.200580869000078],
            [-80.859217353999952, 35.200652717000025],
            [-80.859233336999978, 35.20072385900005],
            [-80.859254952999947, 35.200793991000069],
            [-80.85928211199996, 35.200862812000025],
            [-80.859314696999945, 35.200930027000027],
            [-80.859352567999963, 35.200995350000028],
            [-80.859395562999964, 35.201058499000055],
            [-80.859443495999983, 35.201119205000055],
            [-80.859496164999939, 35.201177209000036],
            [-80.859553342999959, 35.20123226000004],
            [-80.859614785999952, 35.20128412300005],
            [-80.859680228999935, 35.201332577000073],
            [-80.859749393999948, 35.201377415000024],
            [-80.859821982999961, 35.20141844300008],
            [-80.859897684999964, 35.201455487000032],
            [-80.859976177999954, 35.20148838700004],
            [-80.86005712299999, 35.201517003000049],
            [-80.860140175999959, 35.201541213000041],
            [-80.860224979999941, 35.201560911000058],
            [-80.860225295999953, 35.201560975000064],
            [-80.860647646999951, 35.201646847000063],
            [-80.861188102999961, 35.201758982000058],
            [-80.861194033999936, 35.201760200000024],
            [-80.861220827999944, 35.201765416000057],
            [-80.86135487699994, 35.201790321000033],
            [-80.861624559999939, 35.201851124000029],
            [-80.861654770999962, 35.201860313000054],
            [-80.861712211999986, 35.201880217000053],
            [-80.861766502999956, 35.201901294000038],
            [-80.861826626999971, 35.201927568000031],
            [-80.861853509999946, 35.201940212000068],
            [-80.861997732999953, 35.202012616000047],
            [-80.862221863999935, 35.202130265000051],
            [-80.862590722999983, 35.202326244000062],
            [-80.862595470999963, 35.202328754000064],
            [-80.86260716999999, 35.202334844000063],
            [-80.863752713999986, 35.202924331000077],
            [-80.864505724999958, 35.203317646000073],
            [-80.865128356999946, 35.203716335000024],
            [-80.865219589999981, 35.203778940000063],
            [-80.865310246999968, 35.20384524800005],
            [-80.865396833999966, 35.203912544000048],
            [-80.865457262999939, 35.20396291000003],
            [-80.865691810999976, 35.204159705000052],
            [-80.865707632999943, 35.204172761000052],
            [-80.865857176999953, 35.204294122000078],
            [-80.865869839999959, 35.20430426300004],
            [-80.86588265599994, 35.204314263000072],
            [-80.866036030999965, 35.204432358000076],
            [-80.866061237999986, 35.204451279000068],
            [-80.866218168999978, 35.204566111000076],
            [-80.866245594999953, 35.20458564200004],
            [-80.866253920999952, 35.204591364000066],
            [-80.86659595399999, 35.204824522000024],
            [-80.866719260999957, 35.204908887000045],
            [-80.866825256999959, 35.204985265000062],
            [-80.866925950999985, 35.205062612000063],
            [-80.867025457999944, 35.205143727000063],
            [-80.867135707999978, 35.205238899000051],
            [-80.867153289999976, 35.205253795000033],
            [-80.867361785999947, 35.205427145000044],
            [-80.867363867999984, 35.205428872000027],
            [-80.867567604999977, 35.205597521000072],
            [-80.867961374999936, 35.205924913000047],
            [-80.867965210999955, 35.205928091000033],
            [-80.869105968999975, 35.206868887000041],
            [-80.869106147999958, 35.206869035000068],
            [-80.87216602999996, 35.209391445000051],
            [-80.872172282999941, 35.209396677000029],
            [-80.872459508999953, 35.20964187900006],
            [-80.872628214999963, 35.209789731000058],
            [-80.872703682999941, 35.209873152000057],
            [-80.872758756999986, 35.209937069000034],
            [-80.872816671999942, 35.210007371000074],
            [-80.872874380999974, 35.210079134000068],
            [-80.872925359999954, 35.210145244000046],
            [-80.873016525999958, 35.210270390000062],
            [-80.873030277999987, 35.210289857000078],
            [-80.873080995999942, 35.210364202000051],
            [-80.873129153999969, 35.210437686000034],
            [-80.873176371999989, 35.210512942000037],
            [-80.873221029999968, 35.210586804000059],
            [-80.873263695999981, 35.21066144100007],
            [-80.873306653999975, 35.210739595000064],
            [-80.873346101999971, 35.210814564000032],
            [-80.873384088999956, 35.210891338000067],
            [-80.87342195399998, 35.210971075000032],
            [-80.873457225999971, 35.211049231000061],
            [-80.873490072999971, 35.211126326000056],
            [-80.873522345999959, 35.211207140000056],
            [-80.873552798999981, 35.211286831000052],
            [-80.873580411999967, 35.211364993000075],
            [-80.873601826999959, 35.211429767000027],
            [-80.873630703999936, 35.211536620000061],
            [-80.873638939999978, 35.211565282000038],
            [-80.873658861999957, 35.211630702000036],
            [-80.873664699999949, 35.211649191000049],
            [-80.873686008999982, 35.211714358000052],
            [-80.87369268499998, 35.211734050000075],
            [-80.873693467999942, 35.211736267000049],
            [-80.873716448999971, 35.211801180000066],
            [-80.873720902999935, 35.211813497000037],
            [-80.873744709999983, 35.211877939000033],
            [-80.873752980999939, 35.211899525000035],
            [-80.873778452999943, 35.211963712000056],
            [-80.873785827999939, 35.211981769000033],
            [-80.87381269399998, 35.212045707000073],
            [-80.873818985999947, 35.212060346000044],
            [-80.873819913999967, 35.212062450000076],
            [-80.873847875999957, 35.212125683000068],
            [-80.873856939999939, 35.212145578000047],
            [-80.873886576999951, 35.21220878400004],
            [-80.873894600999961, 35.212225489000048],
            [-80.873925338999982, 35.212287992000029],
            [-80.873934779999956, 35.212306695000052],
            [-80.873966902999939, 35.21236871800005],
            [-80.873972048999974, 35.212378522000051],
            [-80.87397453799997, 35.212383173000035],
            [-80.873988237999981, 35.212408610000068],
            [-80.873991989999979, 35.212415515000032],
            [-80.874011795999934, 35.212451621000071],
            [-80.874017956999978, 35.212462688000073],
            [-80.874052574999951, 35.212523986000065],
            [-80.874065749999943, 35.212546637000059],
            [-80.874102022999978, 35.212607221000042],
            [-80.874111176999975, 35.212622221000061],
            [-80.874148556999955, 35.212682332000043],
            [-80.874151713999936, 35.21268737500003],
            [-80.874159913999961, 35.212700189000032],
            [-80.87419867899996, 35.212759818000052],
            [-80.874208407999959, 35.212774514000046],
            [-80.874248277999982, 35.212833669000076],
            [-80.874260468999978, 35.212851371000056],
            [-80.874301432999971, 35.212909594000052],
            [-80.874311856999952, 35.212924152000028],
            [-80.874351621999949, 35.212978728000053],
            [-80.87435408999994, 35.21298210100008],
            [-80.874364392999951, 35.212995899000077],
            [-80.874410438999973, 35.213056562000077],
            [-80.874426761999985, 35.213077536000071],
            [-80.874471872999948, 35.21313408900005],
            [-80.874478067999974, 35.21314178700004],
            [-80.874523444999966, 35.213197650000041],
            [-80.874538575999964, 35.213215881000053],
            [-80.874541384999986, 35.213219180000067],
            [-80.874588702999972, 35.213274556000044],
            [-80.874604141999953, 35.213292251000041],
            [-80.874652836999985, 35.213346917000024],
            [-80.874664221999979, 35.213359511000078],
            [-80.874713739999947, 35.213413477000074],
            [-80.874729298999966, 35.213430102000075],
            [-80.874779911999951, 35.213483137000026],
            [-80.874787407999975, 35.213490920000027],
            [-80.874794197999961, 35.213497849000078],
            [-80.874845910999966, 35.213550177000059],
            [-80.874856341999987, 35.21356060200003],
            [-80.874908881999943, 35.213612461000025],
            [-80.874930147999976, 35.213632940000025],
            [-80.874984339999969, 35.213683856000046],
            [-80.874997127999961, 35.213695698000038],
            [-80.87505213999998, 35.213745915000061],
            [-80.875058060999947, 35.213751286000047],
            [-80.875069592999978, 35.213761543000032],
            [-80.875125700999945, 35.213810827000032],
            [-80.875143514999934, 35.213826176000055],
            [-80.875200717999974, 35.213874528000076],
            [-80.875212114999954, 35.213884046000032],
            [-80.875270143999956, 35.213931926000043],
            [-80.875290873999973, 35.213948664000043],
            [-80.87534999199994, 35.213995381000075],
            [-80.875363995999976, 35.214006290000043],
            [-80.875424219999957, 35.214052534000075],
            [-80.875443381999958, 35.214066965000029],
            [-80.875504416999945, 35.21411205000004],
            [-80.875521228999958, 35.214124262000041],
            [-80.875583362999976, 35.21416864300005],
            [-80.875598839999952, 35.214179530000024],
            [-80.875605590999953, 35.21418417600006],
            [-80.875668815999973, 35.21422739500008],
            [-80.875681440999983, 35.214235920000078],
            [-80.875745206999966, 35.214278444000058],
            [-80.87576533899994, 35.214291610000032],
            [-80.87583019899995, 35.214333200000056],
            [-80.875848933999976, 35.214344998000058],
            [-80.875914326999975, 35.214385435000054],
            [-80.875925267999946, 35.214392129000032],
            [-80.875932085999978, 35.214396230000034],
            [-80.875998580999976, 35.214435963000028],
            [-80.876019326999938, 35.214448114000049],
            [-80.876086913999984, 35.214486914000076],
            [-80.876106599999957, 35.214498003000074],
            [-80.876174719999938, 35.214535652000052],
            [-80.876191647999974, 35.214544856000032],
            [-80.87626030499996, 35.214581578000036],
            [-80.87626873499994, 35.214586050000037],
            [-80.876283829999977, 35.214593878000073],
            [-80.876353858999948, 35.21462966100006],
            [-80.876369026999953, 35.214637297000024],
            [-80.876439314999971, 35.214672164000035],
            [-80.876466408999988, 35.214685252000038],
            [-80.876538065999966, 35.214718950000076],
            [-80.876555211999971, 35.214726877000032],
            [-80.87662684299994, 35.214759430000072],
            [-80.876628062999941, 35.214759984000068],
            [-80.87665072599998, 35.214770027000043],
            [-80.876723728999934, 35.214801643000044],
            [-80.87673797299999, 35.214807724000025],
            [-80.876810955999986, 35.21483842400005],
            [-80.876836655999966, 35.214848952000068],
            [-80.876910728999974, 35.214878491000036],
            [-80.876925527999958, 35.21488430200003],
            [-80.876999579999961, 35.214912923000043],
            [-80.877000685999974, 35.214913351000064],
            [-80.877025526999944, 35.21492267900004],
            [-80.877100667999969, 35.214950140000042],
            [-80.877122838999981, 35.214958048000028],
            [-80.877199074999965, 35.214984574000027],
            [-80.877220551999983, 35.214991868000027],
            [-80.877296476999959, 35.215017027000044],
            [-80.877308952999954, 35.215021102000037],
            [-80.877318743999979, 35.215024218000053],
            [-80.877395763999971, 35.215048443000057],
            [-80.877415670999937, 35.21505455700003],
            [-80.877492665999966, 35.215077637000036],
            [-80.87751590299996, 35.215084406000074],
            [-80.877593428999944, 35.215106333000051],
            [-80.877619653999943, 35.215113505000033],
            [-80.87769826899995, 35.215134270000078],
            [-80.877702178999982, 35.215135298000064],
            [-80.877720930999942, 35.215140075000079],
            [-80.877823373999945, 35.215165508000041],
            [-80.87787614299998, 35.215177652000079],
            [-80.878557566999973, 35.215322273000027],
            [-80.879952426999978, 35.215618859000074],
            [-80.879953116999957, 35.215619006000054],
            [-80.881148379999956, 35.215872585000056],
            [-80.881979791999981, 35.216058639000039],
            [-80.882015789999969, 35.216067500000065],
            [-80.882017147999989, 35.216067877000057],
            [-80.882024882999985, 35.21607012700008],
            [-80.882055836999939, 35.216079842000056],
            [-80.882061118999957, 35.216081646000077],
            [-80.882065968999939, 35.216083369000046],
            [-80.882089818999987, 35.216092522000054],
            [-80.882116897999936, 35.216103859000043],
            [-80.882122783999989, 35.216106467000031],
            [-80.88212939999994, 35.216109527000071],
            [-80.882135759999983, 35.216112622000026],
            [-80.882150925999952, 35.216120310000065],
            [-80.882159882999986, 35.216124920000027],
            [-80.882169866999959, 35.21613039600004],
            [-80.882182223999962, 35.216137241000069],
            [-80.882184772999949, 35.216138729000079],
            [-80.882194213999981, 35.216144506000035],
            [-80.882198729999971, 35.216147334000027],
            [-80.882225329999983, 35.216165115000024],
            [-80.882229670999948, 35.216168139000047],
            [-80.882238241999971, 35.21617438800007],
            [-80.882249515999945, 35.21618280000007],
            [-80.882252428999948, 35.216185051000025],
            [-80.882257838999976, 35.216189474000032],
            [-80.882271221999986, 35.216200670000035],
            [-80.882282015999976, 35.216209909000042],
            [-80.882283412999982, 35.216211146000035],
            [-80.882300337999936, 35.216227118000063],
            [-80.882321257999934, 35.21624905300007],
            [-80.882326855999963, 35.21625533200006],
            [-80.882336552999959, 35.216266314000052],
            [-80.882338390999962, 35.216268496000055],
            [-80.882341635999978, 35.21627256000005],
            [-80.882360059999939, 35.216296561000036],
            [-80.882376768999961, 35.216321512000036],
            [-80.882377055999939, 35.216321957000048],
            [-80.882382676999953, 35.216331461000038],
            [-80.882393305999983, 35.216354103000072],
            [-80.88240590099997, 35.216385359000071],
            [-80.882417464999946, 35.216419955000049],
            [-80.882424709999952, 35.216447456000026],
            [-80.882432920999975, 35.216486704000033],
            [-80.882438038999965, 35.216520039000045],
            [-80.882440704999965, 35.216550859000051],
            [-80.882441669999935, 35.216587579000077],
            [-80.882440624999958, 35.21662556800004],
            [-80.882439357999942, 35.216640540000071],
            [-80.88241534499997, 35.216860402000066],
            [-80.88234332799999, 35.217412452000076],
            [-80.882341345999976, 35.217428798000071],
            [-80.882223333999946, 35.218480892000059],
            [-80.882222936999938, 35.218484492000073],
            [-80.88212348199994, 35.219404052000073],
            [-80.882037048999962, 35.220186690000048],
            [-80.882036758999959, 35.220189355000059],
            [-80.881983928999944, 35.220680953000056],
            [-80.881983678999973, 35.220683309000037],
            [-80.881944118999968, 35.221060652000062],
            [-80.881942145999972, 35.221081851000065],
            [-80.88191519999998, 35.221413424000048],
            [-80.88191366999996, 35.221435606000057],
            [-80.881913231999988, 35.221444022000071],
            [-80.881900848999976, 35.221705291000035],
            [-80.881900216999952, 35.221721815000024],
            [-80.881892121999954, 35.221999505000042],
            [-80.881891809999956, 35.22201433400005],
            [-80.881885343999954, 35.222519641000076],
            [-80.88188527799997, 35.222534891000066],
            [-80.881886274999943, 35.222783354000057],
            [-80.881886411999972, 35.222795898000072],
            [-80.881893042999934, 35.223167485000033],
            [-80.881893139999988, 35.223172254000076],
            [-80.881893149999939, 35.223172686000055],
            [-80.881906300999958, 35.223730375000059],
            [-80.88189585899994, 35.223957100000064],
            [-80.881895139999983, 35.224019803000033],
            [-80.881895260999954, 35.224024525000061],
            [-80.881919014999937, 35.224863479000078],
            [-80.881896710999968, 35.224861912000051],
            [-80.881886458999986, 35.224861225000041],
            [-80.881853471999989, 35.224859116000061],
            [-80.881177910999952, 35.224812212000074],
            [-80.880808634999937, 35.224785768000061],
            [-80.880189823999956, 35.224737883000046],
            [-80.880181736999987, 35.224737278000077],
            [-80.879603610999936, 35.224695422000025],
            [-80.879595992999953, 35.224694888000045],
            [-80.879249376999951, 35.224671418000071],
            [-80.879238916999952, 35.224670743000047],
            [-80.879215176999935, 35.224669289000076],
            [-80.879016110999942, 35.224656332000052],
            [-80.878442673999984, 35.224615313000072],
            [-80.878440384999976, 35.22461515100008],
            [-80.877985849999959, 35.224583275000043],
            [-80.877975395999954, 35.224582576000046],
            [-80.877660815999945, 35.224562537000054],
            [-80.877542575999939, 35.224554090000026],
            [-80.877233264999973, 35.224529267000037],
            [-80.876861967999957, 35.224497643000063],
            [-80.876785554999969, 35.224492932000032],
            [-80.87669740299998, 35.224491932000035]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 86,
        "SolutionID": "PI-27:D",
        "Shape_Length": 0.25953918633563183,
        "Shape_Area": 0.00031236691226104346
      }
    },
    {
      "type": "Feature",
      "id": 87,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.847059767999951, 35.247233025000071],
            [-80.846971699999983, 35.247236737000037],
            [-80.846884116999945, 35.247245166000027],
            [-80.846797392999974, 35.247258276000025],
            [-80.846711899999946, 35.247276010000064],
            [-80.846628003999967, 35.247298293000028],
            [-80.846546063999938, 35.247325031000059],
            [-80.846466430999953, 35.24735610700003],
            [-80.846389445999989, 35.247391389000029],
            [-80.846315438999966, 35.247430725000072],
            [-80.846244725999952, 35.247473949000039],
            [-80.846177610999973, 35.247520873000042],
            [-80.84611438099995, 35.247571299000072],
            [-80.846055304999936, 35.247625008000057],
            [-80.846000638999953, 35.247681772000078],
            [-80.845950614999936, 35.247741348000034],
            [-80.845905447999939, 35.247803479000027],
            [-80.84586533199996, 35.247867901000063],
            [-80.845830436999961, 35.247934337000061],
            [-80.845800912999948, 35.248002504000056],
            [-80.845776887999989, 35.248072108000031],
            [-80.845758463999971, 35.248142852000058],
            [-80.845745718999979, 35.248214434000033],
            [-80.845738707999942, 35.248286546000031],
            [-80.845737461999988, 35.248358879000079],
            [-80.84574198599995, 35.248431125000025],
            [-80.845752259999983, 35.248502974000075],
            [-80.845768239999984, 35.248574117000032],
            [-80.845789857999989, 35.24864425100003],
            [-80.845817020999959, 35.248713074000079],
            [-80.845849613999974, 35.248780293000038],
            [-80.845887495999989, 35.248845619000065],
            [-80.845930505999945, 35.248908773000039],
            [-80.845978457999934, 35.248969484000042],
            [-80.846031147999952, 35.249027492000039],
            [-80.846088350999935, 35.249082550000026],
            [-80.846149820999983, 35.249134420000075],
            [-80.84621529399999, 35.249182881000024],
            [-80.846284491999938, 35.249227725000026],
            [-80.846357116999968, 35.249268761000053],
            [-80.846366797999963, 35.249273810000034],
            [-80.846396407999976, 35.249289103000024],
            [-80.846401329999935, 35.249291879000054],
            [-80.846414453999955, 35.249300086000062],
            [-80.846423116999972, 35.249306223000076],
            [-80.846431174999964, 35.249312619000079],
            [-80.846440633999975, 35.249321106000025],
            [-80.84647601599994, 35.249356379000062],
            [-80.846486696999989, 35.249366894000048],
            [-80.846666051999989, 35.249541246000035],
            [-80.846708980999949, 35.249583468000026],
            [-80.846730628999978, 35.249604230000045],
            [-80.846820474999959, 35.249688279000054],
            [-80.846837218999951, 35.249703652000051],
            [-80.846857417999956, 35.249721453000063],
            [-80.846951383999965, 35.249802464000027],
            [-80.846989852999968, 35.24983430900005],
            [-80.84708765299996, 35.24991205300006],
            [-80.847090456999979, 35.249914275000037],
            [-80.84712325199996, 35.249939347000065],
            [-80.847224618999974, 35.25001429100007],
            [-80.847257297999988, 35.250037680000048],
            [-80.847264632999952, 35.250042725000071],
            [-80.847369268999955, 35.250114181000072],
            [-80.847400796999978, 35.250135061000037],
            [-80.847426575999975, 35.25015161500005],
            [-80.847442332999947, 35.250161580000054],
            [-80.847581941999977, 35.250248533000047],
            [-80.847596521999947, 35.250257487000056],
            [-80.847615791999942, 35.250268941000058],
            [-80.847759232999977, 35.250352629000076],
            [-80.847789923999983, 35.250370016000034],
            [-80.84793579899997, 35.250450232000048],
            [-80.847949090999975, 35.250457448000077],
            [-80.848334842999975, 35.250664187000041],
            [-80.848982976999935, 35.251015761000076],
            [-80.848983985999951, 35.251016308000032],
            [-80.849372352999978, 35.251226560000077],
            [-80.849373626999977, 35.251227248000077],
            [-80.849762618999989, 35.251437318000058],
            [-80.849912816999961, 35.251519567000059],
            [-80.849930017999952, 35.25152883100003],
            [-80.850019531999976, 35.251576244000034],
            [-80.850015187999986, 35.251584775000026],
            [-80.849945315999946, 35.251713692000067],
            [-80.849938891999955, 35.251725736000026],
            [-80.849676378999959, 35.252226016000066],
            [-80.84967151799998, 35.252235399000028],
            [-80.849431772999935, 35.252704140000048],
            [-80.849324282999987, 35.252907754000034],
            [-80.84930650299998, 35.25294309800006],
            [-80.849286924999944, 35.252986676000035],
            [-80.849246133999941, 35.253083713000024],
            [-80.849236187999963, 35.253108302000044],
            [-80.849212163999937, 35.253177907000065],
            [-80.849201109999967, 35.25321757100005],
            [-80.849196239999969, 35.253236653000044],
            [-80.849189310999975, 35.253265743000043],
            [-80.849176649999947, 35.253322961000038],
            [-80.849176210999985, 35.253324952000071],
            [-80.849163468999961, 35.253396534000046],
            [-80.849160577999953, 35.253419764000057],
            [-80.849154084999952, 35.253478032000032],
            [-80.849149967999949, 35.253526914000076],
            [-80.849148889999981, 35.253551763000075],
            [-80.849147149999965, 35.253610417000061],
            [-80.849146983999958, 35.253657901000054],
            [-80.849149305999958, 35.253705349000029],
            [-80.84915370799996, 35.253763908000053],
            [-80.849155913999937, 35.253788705000034],
            [-80.849164152999947, 35.253849046000028],
            [-80.849173438999969, 35.253903489000038],
            [-80.849227957999972, 35.254232777000027],
            [-80.849279441999954, 35.254555689000028],
            [-80.849289039999974, 35.254620658000078],
            [-80.849235674999989, 35.254763676000039],
            [-80.849196035999967, 35.254867821000062],
            [-80.849148128999957, 35.254972002000045],
            [-80.849144773999967, 35.25497937800003],
            [-80.849126917999968, 35.255019061000041],
            [-80.849106013999972, 35.25506458600006],
            [-80.849085185999968, 35.255104625000058],
            [-80.849058799999966, 35.255149391000032],
            [-80.849017053999944, 35.25521397600005],
            [-80.848964826999975, 35.255288251000025],
            [-80.84880127699995, 35.255513887000063],
            [-80.848466069999972, 35.255973028000028],
            [-80.848450896999964, 35.255994347000069],
            [-80.848320860999934, 35.256181833000028],
            [-80.84831518499999, 35.256190097000058],
            [-80.847956661999945, 35.256717489000039],
            [-80.847954252999955, 35.256721049000078],
            [-80.847953601999961, 35.256722015000037],
            [-80.847871552999948, 35.256844048000062],
            [-80.847793060999948, 35.256952718000036],
            [-80.847725915999945, 35.257040880000034],
            [-80.847703665999973, 35.257067705000054],
            [-80.847685948999981, 35.257086927000046],
            [-80.847668518999967, 35.257103856000072],
            [-80.847642789999952, 35.257126288000052],
            [-80.847504904999937, 35.257237509000049],
            [-80.847501665999971, 35.257240130000071],
            [-80.847210519999976, 35.257476564000058],
            [-80.847188177999953, 35.257495142000039],
            [-80.847170720999941, 35.25751028600007],
            [-80.847128020999946, 35.257548038000039],
            [-80.847086395999952, 35.25758660300005],
            [-80.847052658999985, 35.257620720000034],
            [-80.847013391999951, 35.257662083000071],
            [-80.846992456999942, 35.257684730000051],
            [-80.84694965999995, 35.25773515800006],
            [-80.846914636999941, 35.257778976000054],
            [-80.846907403999978, 35.257788123000068],
            [-80.846862232999968, 35.257850255000051],
            [-80.846847234999984, 35.257873140000072],
            [-80.84682027599996, 35.257915460000049],
            [-80.846820562999937, 35.257915583000056],
            [-80.846792174999962, 35.25796116500004],
            [-80.846770484999979, 35.258001025000056],
            [-80.846745612999939, 35.258049269000026],
            [-80.846732404999955, 35.258075845000064],
            [-80.846703445999935, 35.258142557000042],
            [-80.846683919999975, 35.258192550000047],
            [-80.846683352999946, 35.258194005000064],
            [-80.846659324999962, 35.258263610000029],
            [-80.846652769999935, 35.258286306000059],
            [-80.846638839999969, 35.258336900000074],
            [-80.846626969999988, 35.258384948000071],
            [-80.846625063999966, 35.258393858000034],
            [-80.846546992999947, 35.25876788000005],
            [-80.846545895999952, 35.25877321300004],
            [-80.846545179999964, 35.25877674700007],
            [-80.846180493999952, 35.258720627000059],
            [-80.846170277999988, 35.258719089000067],
            [-80.846154941999941, 35.258716904000039],
            [-80.845691970999951, 35.258653202000062],
            [-80.845667385999946, 35.258650010000053],
            [-80.845552314999964, 35.258635963000074],
            [-80.845504965999964, 35.258630885000059],
            [-80.845417048999934, 35.258625134000056],
            [-80.845328859999938, 35.258624111000074],
            [-80.845240780999973, 35.258627822000051],
            [-80.845153184999958, 35.258636249000062],
            [-80.845066447999955, 35.258649358000071],
            [-80.844980942999939, 35.258667091000063],
            [-80.844897034999974, 35.258689373000038],
            [-80.844815082999958, 35.258716109000034],
            [-80.844735437999987, 35.258747184000072],
            [-80.844658441999968, 35.258782465000024],
            [-80.844584422999958, 35.258821800000078],
            [-80.844513699999936, 35.258865022000066],
            [-80.84444657399996, 35.258911946000069],
            [-80.844383333999986, 35.258962370000063],
            [-80.844324248999953, 35.259016078000059],
            [-80.844269573999952, 35.25907284200008],
            [-80.844219541999962, 35.259132416000057],
            [-80.844174367999983, 35.25919454700005],
            [-80.844134243999974, 35.25925896900003],
            [-80.844099342999982, 35.259325404000037],
            [-80.844069813999965, 35.259393570000043],
            [-80.844045782999956, 35.259463174000075],
            [-80.844040180999968, 35.259482429000059],
            [-80.844012544999941, 35.259581324000067],
            [-80.844004514999938, 35.259612057000027],
            [-80.843943471999978, 35.259863067000026],
            [-80.843938675999937, 35.259883823000052],
            [-80.843934531999935, 35.259903788000031],
            [-80.843827599999941, 35.260449153000025],
            [-80.843700834999936, 35.261036065000042],
            [-80.843699427999979, 35.261042694000025],
            [-80.843622364999987, 35.261412575000065],
            [-80.843617920999975, 35.261435336000034],
            [-80.843599293999944, 35.261537529000066],
            [-80.843593203999944, 35.261575403000052],
            [-80.843548250999959, 35.261897881000039],
            [-80.84350690499997, 35.262165810000056],
            [-80.843505453999967, 35.262175563000028],
            [-80.843424435999964, 35.262740850000057],
            [-80.843423713999982, 35.262745994000056],
            [-80.843416698999988, 35.262818105000065],
            [-80.843415450999942, 35.262890438000056],
            [-80.843419972999982, 35.262962684000058],
            [-80.843430246999958, 35.263034533000052],
            [-80.843446227999948, 35.263105676000066],
            [-80.843467847999989, 35.263175810000064],
            [-80.843495013999984, 35.263244634000046],
            [-80.843527610999956, 35.263311853000062],
            [-80.843565497999975, 35.263377180000077],
            [-80.843608512999936, 35.263440334000052],
            [-80.843656471999964, 35.263501046000044],
            [-80.843709169999954, 35.263559056000076],
            [-80.843766380999966, 35.263614114000063],
            [-80.843827860999966, 35.263665985000046],
            [-80.843893344999969, 35.263714447000041],
            [-80.843962553999972, 35.263759293000078],
            [-80.844035190999989, 35.263800330000038],
            [-80.844110943999965, 35.263837384000055],
            [-80.844189489999962, 35.263870294000071],
            [-80.844270491999964, 35.263898920000031],
            [-80.844288112999948, 35.263904055000069],
            [-80.844281820999981, 35.263968757000043],
            [-80.844280572999935, 35.264041090000035],
            [-80.844285095999965, 35.264113336000037],
            [-80.844295370999987, 35.264185184000041],
            [-80.844311352999966, 35.264256328000045],
            [-80.84433297299995, 35.264326461000053],
            [-80.844360140999981, 35.264395285000035],
            [-80.844392738999943, 35.264462504000051],
            [-80.844430626999952, 35.264527830000077],
            [-80.844473643999947, 35.264590984000051],
            [-80.844521603999965, 35.264651696000044],
            [-80.844574303999934, 35.26470970500003],
            [-80.844631515999936, 35.264764763000073],
            [-80.844692997999971, 35.264816633000066],
            [-80.844758482999964, 35.264865095000062],
            [-80.844827692999957, 35.264909940000052],
            [-80.84483759699998, 35.264915880000046],
            [-80.84493269699999, 35.264972369000077],
            [-80.844936185999984, 35.264974434000067],
            [-80.845914279999988, 35.265551513000048],
            [-80.845917250999946, 35.265553261000036],
            [-80.84631812799995, 35.26578842300006],
            [-80.84668976599994, 35.266012057000069],
            [-80.846704385999942, 35.266020732000072],
            [-80.846969275999982, 35.26617569800004],
            [-80.847083629999986, 35.266242959000067],
            [-80.847095245999981, 35.266249716000061],
            [-80.847524158999988, 35.266496405000055],
            [-80.848112620999984, 35.266840050000042],
            [-80.848302979999971, 35.266958221000039],
            [-80.848354077999943, 35.266993833000072],
            [-80.848390386999938, 35.267021314000033],
            [-80.848441438999942, 35.267065145000061],
            [-80.848479220999934, 35.267098298000064],
            [-80.848497563999956, 35.267116189000035],
            [-80.848515046999978, 35.267135110000027],
            [-80.848533074999978, 35.267157277000024],
            [-80.848540754999988, 35.267167344000029],
            [-80.848549066999965, 35.267178104000038],
            [-80.848612877999983, 35.267259652000064],
            [-80.848705031999941, 35.267398017000062],
            [-80.848720489999948, 35.267420592000065],
            [-80.849157934999937, 35.268042315000059],
            [-80.849162999999976, 35.26804945400005],
            [-80.84960616099994, 35.268668555000033],
            [-80.849729576999948, 35.268843444000026],
            [-80.849741629999983, 35.268860182000026],
            [-80.849789596999983, 35.26892089100005],
            [-80.849842302999946, 35.268978898000057],
            [-80.849899521999987, 35.269033954000065],
            [-80.849961009999959, 35.269085821000033],
            [-80.850026501999935, 35.26913428000006],
            [-80.850095717999977, 35.269179122000025],
            [-80.850168362999966, 35.269220156000074],
            [-80.850244123999971, 35.269257206000077],
            [-80.850322678999987, 35.269290112000078],
            [-80.850403687999972, 35.269318734000024],
            [-80.850486806999982, 35.269342950000066],
            [-80.850571679999973, 35.269362655000066],
            [-80.850657941999941, 35.269377766000048],
            [-80.850745224999969, 35.269388217000028],
            [-80.850833153999986, 35.269393964000074],
            [-80.850909413999943, 35.269395122000049],
            [-80.851503501999957, 35.269390352000073],
            [-80.853004322999936, 35.269387015000063],
            [-80.853015751999976, 35.269386950000069],
            [-80.853795221999974, 35.269379790000073],
            [-80.853873441999951, 35.269380691000038],
            [-80.85396153399995, 35.269376975000057],
            [-80.854049140999962, 35.269368541000063],
            [-80.854135885999938, 35.269355426000061],
            [-80.854221401999951, 35.269337686000028],
            [-80.854305318999934, 35.26931539800006],
            [-80.85438727799999, 35.269288655000025],
            [-80.854466928999955, 35.269257574000051],
            [-80.854543930999967, 35.269222287000048],
            [-80.854617953999934, 35.269182945000068],
            [-80.85468868199996, 35.26913971700003],
            [-80.854755809999972, 35.26909278800008],
            [-80.854819050999936, 35.269042359000025],
            [-80.854878135999968, 35.268988646000025],
            [-80.85493280999998, 35.268931878000046],
            [-80.854982839999934, 35.268872299000066],
            [-80.855028011999934, 35.268810164000058],
            [-80.855068131999985, 35.268745739000053],
            [-80.855103027999974, 35.268679300000031],
            [-80.85513255099994, 35.268611132000046],
            [-80.855156573999977, 35.268541526000035],
            [-80.85517499599996, 35.268470781000076],
            [-80.855187734999959, 35.268399198000054],
            [-80.855194738999955, 35.268327086000056],
            [-80.855195976999937, 35.268254752000075],
            [-80.855191444999946, 35.268182507000063],
            [-80.855181159999972, 35.268110660000048],
            [-80.855165166999939, 35.26803951800008],
            [-80.855143535999957, 35.267969386000061],
            [-80.855116357999975, 35.267900565000048],
            [-80.855083749999949, 35.267833349000057],
            [-80.855045851999989, 35.267768026000056],
            [-80.855002825999975, 35.267704876000039],
            [-80.85495485499996, 35.26764416900005],
            [-80.854902145999972, 35.267586164000079],
            [-80.854844924999952, 35.267531111000039],
            [-80.854783435999934, 35.26747924600005],
            [-80.854717941999979, 35.267430789000059],
            [-80.854648724999947, 35.267385949000072],
            [-80.854576078999969, 35.267344918000049],
            [-80.854500317999964, 35.26730787200006],
            [-80.854421763999937, 35.267274968000038],
            [-80.854340754999953, 35.267246349000061],
            [-80.854257636999989, 35.267222136000044],
            [-80.854172765999976, 35.26720243300008],
            [-80.854086503999952, 35.267187325000066],
            [-80.853999223999949, 35.267176876000065],
            [-80.853939677999961, 35.26717246700008],
            [-80.853914251999981, 35.267171050000059],
            [-80.853885870999989, 35.267169714000033],
            [-80.853807741999958, 35.267168574000038],
            [-80.852991281999948, 35.267176074000076],
            [-80.851666019999982, 35.267179021000061],
            [-80.851490387999945, 35.266933668000036],
            [-80.851063336999971, 35.266326744000025],
            [-80.850955926999973, 35.26616548100003],
            [-80.850927560999935, 35.266124952000041],
            [-80.850907395999968, 35.266098362000037],
            [-80.850822068999946, 35.265989318000038],
            [-80.850808055999948, 35.265970948000074],
            [-80.850786666999966, 35.265943801000049],
            [-80.850753918999942, 35.265903538000032],
            [-80.850747500999944, 35.265895719000071],
            [-80.850725392999948, 35.265869033000058],
            [-80.850717083999939, 35.265859122000052],
            [-80.850684771999966, 35.265822628000024],
            [-80.850625513999944, 35.265758496000046],
            [-80.850605120999944, 35.265736984000057],
            [-80.850582906999989, 35.265714734000028],
            [-80.850519798999983, 35.265653179000026],
            [-80.850484795999989, 35.265620374000036],
            [-80.850472835999938, 35.265609741000048],
            [-80.850405879999983, 35.265550993000033],
            [-80.850395831999947, 35.265542272000062],
            [-80.850310394999951, 35.265468920000046],
            [-80.850270918999968, 35.265436406000049],
            [-80.850249856999937, 35.265420118000065],
            [-80.85016206499995, 35.26535367200006],
            [-80.85012076199996, 35.265323671000033],
            [-80.850029120999977, 35.265259804000038],
            [-80.850025997999978, 35.265257634000079],
            [-80.849992784999984, 35.265235362000055],
            [-80.849967277999951, 35.265218806000064],
            [-80.849944271999959, 35.265204202000064],
            [-80.849723923999989, 35.265067415000033],
            [-80.849710929999958, 35.265059449000034],
            [-80.849692241999946, 35.265048337000053],
            [-80.849083833999941, 35.264693048000026],
            [-80.84907592899998, 35.264688467000042],
            [-80.848648888999946, 35.264442858000052],
            [-80.848538925999947, 35.264378180000051],
            [-80.848536128999967, 35.264376539000068],
            [-80.848277186999951, 35.264225055000054],
            [-80.847906249999937, 35.264001845000053],
            [-80.847893037999938, 35.263993997000057],
            [-80.847487018999971, 35.263755819000039],
            [-80.847100301999944, 35.263527659000033],
            [-80.847154564999983, 35.263242571000035],
            [-80.847161531999973, 35.263208617000032],
            [-80.847171737999986, 35.263149013000032],
            [-80.847178748999966, 35.263076901000034],
            [-80.847179992999941, 35.263004567000053],
            [-80.847175467999989, 35.26293232200004],
            [-80.847165190999988, 35.262860474000036],
            [-80.847149206999973, 35.262789331000079],
            [-80.847127582999974, 35.26271919800007],
            [-80.847100413999954, 35.262650375000078],
            [-80.847067814999946, 35.262583156000062],
            [-80.847029925999948, 35.262517831000025],
            [-80.846986907999963, 35.26245467800004],
            [-80.846938946999956, 35.262393967000037],
            [-80.846886246999986, 35.26233595900004],
            [-80.846829034999985, 35.262280902000043],
            [-80.846767554999985, 35.26222903300004],
            [-80.846702070999982, 35.262180571000044],
            [-80.846632861999979, 35.262135727000043],
            [-80.846560224999962, 35.262094691000073],
            [-80.846484471999986, 35.26205764000008],
            [-80.846405926999978, 35.262024731000054],
            [-80.846324925999966, 35.261996107000073],
            [-80.846250801999986, 35.261974507000048],
            [-80.846268779999946, 35.261845507000032],
            [-80.846281882999961, 35.261773606000077],
            [-80.846355876999951, 35.261418400000025],
            [-80.846444918999964, 35.261006083000041],
            [-80.846690148999983, 35.261036182000055],
            [-80.847295030999987, 35.261131474000024],
            [-80.847310954999955, 35.261133901000051],
            [-80.847337673999959, 35.261137611000038],
            [-80.847439890999965, 35.261150937000025],
            [-80.84750044499998, 35.261157681000043],
            [-80.847588364999979, 35.261163431000057],
            [-80.847676554999964, 35.26116445100007],
            [-80.847764637999944, 35.261160740000037],
            [-80.847852236999984, 35.261152310000057],
            [-80.847938973999987, 35.261139200000059],
            [-80.848024482999961, 35.261121464000041],
            [-80.848108392999961, 35.261099180000031],
            [-80.848190345999967, 35.261072442000057],
            [-80.848269990999938, 35.26104136500004],
            [-80.848346987999946, 35.261006081000062],
            [-80.848421006999956, 35.26096674300004],
            [-80.848491729999978, 35.260923519000073],
            [-80.848558854999965, 35.260876593000035],
            [-80.84862209399995, 35.260826167000062],
            [-80.848681176999946, 35.26077245700003],
            [-80.848735849999969, 35.260715692000076],
            [-80.84878587999998, 35.260656115000074],
            [-80.84883105199998, 35.260593982000046],
            [-80.848871172999964, 35.260529559000076],
            [-80.848906069999941, 35.260463123000079],
            [-80.848935594999944, 35.260394956000027],
            [-80.848959620999949, 35.260325351000063],
            [-80.848978045999957, 35.260254606000046],
            [-80.848981923999986, 35.260235986000055],
            [-80.849001675999943, 35.260135999000056],
            [-80.849123464999934, 35.259538182000028],
            [-80.849123667999947, 35.259537181000042],
            [-80.849204469999961, 35.259138295000071],
            [-80.84924711299999, 35.258933973000069],
            [-80.849396559999946, 35.258812607000038],
            [-80.849551497999983, 35.258687629000065],
            [-80.849577078999971, 35.258666430000062],
            [-80.849588119999964, 35.258656918000042],
            [-80.849656564999975, 35.258597243000054],
            [-80.849704651999957, 35.258552998000027],
            [-80.849768848999986, 35.258490643000073],
            [-80.849814211999956, 35.258444090000069],
            [-80.849874160999946, 35.258379051000077],
            [-80.84988341899998, 35.258368884000049],
            [-80.849913421999986, 35.258334166000054],
            [-80.849969402999989, 35.258266668000033],
            [-80.849989427999958, 35.258241809000026],
            [-80.849998115999938, 35.258230554000079],
            [-80.850087582999947, 35.258113078000065],
            [-80.850104058999989, 35.258090867000078],
            [-80.850201236999965, 35.257956325000066],
            [-80.850221242999964, 35.257927658000028],
            [-80.850221892999969, 35.257926693000059],
            [-80.850312465999934, 35.257791976000078],
            [-80.850666625999963, 35.257270979000054],
            [-80.850786357999937, 35.257098342000063],
            [-80.851114920999976, 35.256648286000029],
            [-80.851117104999958, 35.256645283000069],
            [-80.851286297999934, 35.256411851000053],
            [-80.85129531299998, 35.256399226000042],
            [-80.851364004999937, 35.256301532000066],
            [-80.851376062999975, 35.256284016000052],
            [-80.851387274999979, 35.256267029000071],
            [-80.851452005999988, 35.256166877000055],
            [-80.851474629999984, 35.25613024300003],
            [-80.85152570799994, 35.256043583000064],
            [-80.851531987999977, 35.256032780000055],
            [-80.851551941999958, 35.255996266000068],
            [-80.851596613999959, 35.255910390000054],
            [-80.851611552999941, 35.255880467000054],
            [-80.851618260999942, 35.255866157000071],
            [-80.851650981999967, 35.25579489200004],
            [-80.851654103999977, 35.255788023000036],
            [-80.851671843999952, 35.255748594000067],
            [-80.85173187099997, 35.255618048000031],
            [-80.851754915999948, 35.255563683000048],
            [-80.851757353999972, 35.255557347000035],
            [-80.85180977899995, 35.255419594000045],
            [-80.851812004999942, 35.255413687000043],
            [-80.851867492999986, 35.255264966000027],
            [-80.851880792999964, 35.255233851000071],
            [-80.851893028999939, 35.255203854000058],
            [-80.85190625599995, 35.255167702000051],
            [-80.851923798999962, 35.255116592000036],
            [-80.851934594999989, 35.255083139000078],
            [-80.851952156999971, 35.255016276000049],
            [-80.851963811999951, 35.254964112000039],
            [-80.851964670999962, 35.254960230000052],
            [-80.85197741199994, 35.25488864700003],
            [-80.851977673999954, 35.254886722000037],
            [-80.851984843999958, 35.25483371100006],
            [-80.851991586999986, 35.254763524000055],
            [-80.851992496999969, 35.254743805000032],
            [-80.851994365999985, 35.254690874000062],
            [-80.851994695999963, 35.254638259000046],
            [-80.851993614999969, 35.254610055000057],
            [-80.851990716999978, 35.254556283000056],
            [-80.851987268999949, 35.254512242000033],
            [-80.851982240999973, 35.25447209500004],
            [-80.851954521999971, 35.25428450000004],
            [-80.851952928999935, 35.254274129000066],
            [-80.851900175999958, 35.253943329000037],
            [-80.851899294999953, 35.253937915000051],
            [-80.851855933999957, 35.253676066000025],
            [-80.851908272999935, 35.253576916000043],
            [-80.85191430399999, 35.253565310000056],
            [-80.852154606999989, 35.253095448000067],
            [-80.852411482999969, 35.252605883000058],
            [-80.852484322999942, 35.252471485000058],
            [-80.852496451999968, 35.252448401000038],
            [-80.852573416999974, 35.252297221000049],
            [-80.852574932999971, 35.252294231000064],
            [-80.852660551999975, 35.252124657000024],
            [-80.852772852999976, 35.251925136000068],
            [-80.85293859899997, 35.251648472000056],
            [-80.852948690999938, 35.251631252000038],
            [-80.852983580999989, 35.251564814000062],
            [-80.853013098999952, 35.251496646000078],
            [-80.853037118999964, 35.251427041000056],
            [-80.853055538999968, 35.251356295000051],
            [-80.853068277999967, 35.251284713000075],
            [-80.853075281999963, 35.251212600000031],
            [-80.853076520999934, 35.25114026600005],
            [-80.853071991999968, 35.251068021000037],
            [-80.853061710999953, 35.250996174000079],
            [-80.853045723999969, 35.250925031000065],
            [-80.853024097999935, 35.250854899000046],
            [-80.852996927999982, 35.250786077000043],
            [-80.852964328999974, 35.250718860000063],
            [-80.852926440999966, 35.250653537000062],
            [-80.852883424999959, 35.250590385000066],
            [-80.852835465999988, 35.250529677000031],
            [-80.852782768999987, 35.250471671000071],
            [-80.852725560999943, 35.250416617000042],
            [-80.852664086999937, 35.250364751000063],
            [-80.852598608999983, 35.250316292000036],
            [-80.852529406999963, 35.25027145100006],
            [-80.852460292999979, 35.250232276000077],
            [-80.851864608999961, 35.24991665500005],
            [-80.851861092999968, 35.249914798000077],
            [-80.851843902999974, 35.24990590200008],
            [-80.851610471999948, 35.249787096000034],
            [-80.851400711999986, 35.249675994000029],
            [-80.851255481999942, 35.249596466000071],
            [-80.851248283999951, 35.249592552000024],
            [-80.850856328999953, 35.249380884000061],
            [-80.850469106999981, 35.249171254000032],
            [-80.849818371999959, 35.24881827300004],
            [-80.849812129999975, 35.248814907000053],
            [-80.849429928999939, 35.248610073000066],
            [-80.849306166999952, 35.248542017000034],
            [-80.849195042999952, 35.24847718500007],
            [-80.849080089999973, 35.248405589000072],
            [-80.849078098999939, 35.248404311000058],
            [-80.849009381999963, 35.248357385000077],
            [-80.848945781999987, 35.248310364000076],
            [-80.848885109999969, 35.248262135000061],
            [-80.848828865999963, 35.248213647000057],
            [-80.848768059999941, 35.248156766000079],
            [-80.848733325999945, 35.248122606000038],
            [-80.848728365999989, 35.248117758000035],
            [-80.848551902999986, 35.247946220000074],
            [-80.848499506999985, 35.247893987000054],
            [-80.848455393999984, 35.247852187000035],
            [-80.848453498999959, 35.247850483000036],
            [-80.848392513999954, 35.247795765000035],
            [-80.848335042999963, 35.247747266000033],
            [-80.848269939999966, 35.247695589000045],
            [-80.848267835999934, 35.247693923000043],
            [-80.848213672999975, 35.24765338800006],
            [-80.848145009999939, 35.247604741000032],
            [-80.848133698999959, 35.247596815000065],
            [-80.848081146999959, 35.247562216000063],
            [-80.848008934999939, 35.247517059000074],
            [-80.847992289999979, 35.247506814000076],
            [-80.847955249999984, 35.247485168000026],
            [-80.84790067299997, 35.247454398000059],
            [-80.847865088999981, 35.247435009000071],
            [-80.847855407999987, 35.247429962000069],
            [-80.847802866999984, 35.247402824000062],
            [-80.847736807999979, 35.247370821000061],
            [-80.847658275999947, 35.247337913000024],
            [-80.847577289999947, 35.247309289000043],
            [-80.847494193999978, 35.247285071000078],
            [-80.84740934499996, 35.247265363000054],
            [-80.847323105999976, 35.247250251000025],
            [-80.847235847999968, 35.247239797000077],
            [-80.847147942999982, 35.247234047000063],
            [-80.847059767999951, 35.247233025000071]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 87,
        "SolutionID": "PI-27:A",
        "Shape_Length": 0.071132086909179895,
        "Shape_Area": 8.4273900083641162e-5
      }
    },
    {
      "type": "Feature",
      "id": 88,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.850970798999981, 35.209630277000031],
            [-80.850882772999967, 35.209633992000079],
            [-80.85079522999996, 35.209642424000037],
            [-80.850708545999964, 35.209655536000071],
            [-80.850623093999957, 35.209673273000078],
            [-80.850539236999964, 35.209695560000057],
            [-80.850457336999966, 35.209722300000067],
            [-80.850377741999978, 35.209753379000063],
            [-80.850300793999963, 35.20978866300004],
            [-80.850226822999957, 35.209828002000052],
            [-80.850156144999971, 35.209871228000054],
            [-80.850089062999984, 35.209918155000025],
            [-80.850025864999964, 35.209968583000034],
            [-80.849966818999974, 35.210022295000044],
            [-80.84991217999999, 35.210079061000044],
            [-80.849862182999971, 35.210138638000046],
            [-80.849817039999948, 35.210200772000064],
            [-80.849776944999974, 35.21026519600008],
            [-80.849742069999934, 35.210331633000067],
            [-80.849712562999969, 35.210399801000051],
            [-80.849688551999975, 35.210469407000062],
            [-80.849670139999944, 35.210540152000078],
            [-80.84965740399997, 35.210611734000054],
            [-80.849650399999973, 35.210683847000041],
            [-80.849649157999977, 35.210756181000079],
            [-80.849653682999985, 35.210828427000024],
            [-80.849663955999972, 35.210900275000029],
            [-80.849679931999958, 35.210971419000032],
            [-80.849701542999981, 35.21104155200004],
            [-80.849728696999989, 35.211110375000032],
            [-80.849761278999949, 35.211177593000059],
            [-80.849799145999953, 35.211242918000039],
            [-80.849842138999975, 35.211306071000024],
            [-80.849890071999937, 35.211366781000038],
            [-80.849935513999981, 35.211417284000049],
            [-80.849938283999961, 35.211420186000055],
            [-80.84994078699998, 35.211423248000074],
            [-80.849953482999979, 35.211438500000042],
            [-80.850006151999935, 35.211496507000049],
            [-80.850063330999944, 35.211551563000057],
            [-80.850124775999973, 35.211603431000071],
            [-80.850190220999934, 35.211651890000041],
            [-80.850259388999973, 35.211696733000053],
            [-80.85033198299999, 35.211737767000045],
            [-80.85040768999994, 35.211774817000048],
            [-80.85048618899998, 35.211807723000049],
            [-80.850567141999989, 35.211836345000052],
            [-80.85065020199994, 35.211860561000037],
            [-80.850735014999941, 35.211880266000037],
            [-80.850821215999986, 35.211895377000076],
            [-80.850908436999987, 35.211905828000056],
            [-80.850996304999967, 35.211911576000034],
            [-80.851084440999955, 35.211912594000069],
            [-80.851118294999935, 35.21191116600005],
            [-80.851227367999968, 35.21191458100003],
            [-80.851238534999936, 35.211914892000038],
            [-80.851260110999988, 35.211915420000025],
            [-80.851344199999971, 35.211977402000059],
            [-80.852086459999953, 35.212534506000054],
            [-80.852444462999983, 35.212814709000043],
            [-80.852503765999984, 35.212858448000077],
            [-80.852843422999968, 35.21309442900008],
            [-80.853160191999962, 35.213342811000075],
            [-80.853189236999981, 35.213364925000064],
            [-80.853512796999951, 35.213604094000061],
            [-80.853513583999984, 35.213604674000067],
            [-80.854049338999971, 35.214000064000061],
            [-80.854695074999938, 35.214490553000076],
            [-80.854701713999987, 35.214495562000025],
            [-80.854980504999958, 35.214704497000071],
            [-80.854993182999976, 35.214713878000055],
            [-80.855386272999965, 35.215001058000041],
            [-80.85575983199999, 35.215282703000071],
            [-80.85578944599996, 35.215305547000071],
            [-80.855815448999977, 35.215325087000053],
            [-80.855942019999986, 35.215417727000045],
            [-80.855969535999975, 35.21543732400005],
            [-80.85597275899994, 35.215439550000042],
            [-80.856102887999953, 35.215529158000038],
            [-80.856134524999959, 35.215550282000038],
            [-80.856267928999955, 35.215636634000077],
            [-80.856302243999949, 35.215658124000072],
            [-80.856374843999959, 35.215699154000049],
            [-80.856432298999948, 35.215727765000054],
            [-80.857023546999983, 35.21600587000006],
            [-80.857027664999976, 35.216007800000057],
            [-80.857644531999938, 35.216295587000047],
            [-80.857645571999967, 35.216296071000045],
            [-80.858276531999934, 35.216589820000024],
            [-80.858281255999941, 35.216592009000067],
            [-80.858769071999973, 35.216816986000026],
            [-80.859139604999939, 35.216989478000073],
            [-80.859142931999941, 35.216991022000059],
            [-80.859815762999972, 35.21730216900005],
            [-80.860488547999978, 35.217615188000025],
            [-80.861056253999948, 35.21787971200007],
            [-80.861059058999956, 35.217881015000046],
            [-80.861751068999979, 35.218201658000055],
            [-80.862271833999955, 35.21844636000003],
            [-80.862335062999989, 35.218476856000052],
            [-80.862380496999947, 35.218515196000055],
            [-80.862445955999988, 35.218563648000043],
            [-80.862515135999956, 35.218608484000072],
            [-80.86258774199996, 35.218649510000034],
            [-80.862663461999944, 35.218686553000055],
            [-80.862741971999981, 35.218719451000027],
            [-80.862822935999986, 35.218748065000057],
            [-80.862906006999935, 35.21877227300007],
            [-80.862990829999944, 35.218791969000051],
            [-80.86307704099994, 35.218807071000072],
            [-80.86316427099996, 35.218817513000033],
            [-80.863252146999969, 35.21882325200005],
            [-80.863340290999986, 35.218824261000066],
            [-80.863428327999941, 35.218820538000045],
            [-80.863515878999976, 35.218812097000068],
            [-80.863602568999966, 35.218798975000027],
            [-80.863688029999935, 35.218781228000068],
            [-80.863771891999988, 35.218758933000061],
            [-80.86385379699999, 35.218732184000032],
            [-80.863933394999947, 35.218701097000064],
            [-80.864010345999986, 35.218665803000079],
            [-80.864084317999982, 35.218626455000049],
            [-80.864154995999968, 35.218583221000074],
            [-80.864222077999955, 35.218536286000074],
            [-80.86428527399994, 35.218485852000072],
            [-80.864344314999983, 35.218432134000068],
            [-80.864398948999963, 35.218375361000028],
            [-80.864448940999978, 35.218315778000033],
            [-80.86449407799995, 35.218253639000068],
            [-80.864534165999942, 35.218189210000048],
            [-80.864569032999952, 35.218122768000057],
            [-80.864598528999977, 35.218054597000048],
            [-80.864622528999973, 35.217984989000058],
            [-80.86464093099994, 35.217914241000074],
            [-80.864653653999937, 35.217842657000062],
            [-80.864660645999948, 35.217770544000075],
            [-80.864661873999978, 35.217698209000048],
            [-80.864657335999937, 35.217625964000035],
            [-80.864647048999984, 35.217554117000077],
            [-80.864631058999976, 35.217482976000042],
            [-80.864609431999952, 35.217412845000069],
            [-80.864582262999988, 35.217344026000035],
            [-80.864574380999954, 35.217326547000027],
            [-80.864561754999954, 35.217299260000061],
            [-80.864537042999984, 35.217249525000057],
            [-80.86449915999998, 35.217184204000034],
            [-80.864484789999949, 35.217162005000034],
            [-80.86446744999995, 35.217135936000034],
            [-80.864438812999936, 35.217094988000042],
            [-80.864390864999962, 35.217034284000079],
            [-80.864352476999954, 35.21699125300006],
            [-80.86433045299998, 35.216967775000057],
            [-80.864316157999951, 35.216952805000062],
            [-80.864258965999966, 35.216897756000037],
            [-80.864197508999951, 35.216845895000063],
            [-80.864132050999956, 35.216797442000029],
            [-80.864062870999987, 35.216752608000036],
            [-80.863990265999973, 35.216711582000073],
            [-80.863945562999959, 35.21668905100006],
            [-80.863630370999942, 35.216537034000055],
            [-80.863617176999981, 35.21653075200004],
            [-80.863086277999969, 35.21628129000004],
            [-80.863079219999975, 35.216277997000077],
            [-80.862385091999954, 35.215956375000076],
            [-80.861818415999949, 35.21569233200006],
            [-80.86181765699996, 35.215691979000042],
            [-80.86114297499995, 35.21537808000005],
            [-80.861139916999946, 35.21537666100005],
            [-80.860467232999952, 35.215065584000058],
            [-80.860096018999968, 35.214892777000045],
            [-80.860091314999977, 35.214890598000068],
            [-80.859603518999961, 35.214665632000049],
            [-80.858975449999946, 35.214373231000025],
            [-80.85836117499997, 35.214086654000027],
            [-80.857858274999955, 35.213850109000077],
            [-80.857818970999972, 35.21382466600005],
            [-80.857720035999989, 35.213756540000077],
            [-80.857621790999985, 35.21368463400006],
            [-80.857599415999971, 35.213667374000067],
            [-80.857588140999951, 35.213658775000056],
            [-80.857201140999962, 35.213367002000041],
            [-80.857185480999988, 35.213355380000053],
            [-80.856790879999949, 35.213067101000036],
            [-80.856521722999958, 35.212865389000058],
            [-80.855872200999954, 35.212372033000065],
            [-80.855857914999945, 35.212361337000061],
            [-80.855315364999967, 35.211960939000051],
            [-80.855006918999948, 35.211732946000041],
            [-80.854675190999956, 35.211472837000031],
            [-80.854614989999959, 35.211428396000031],
            [-80.854274859999975, 35.211192090000054],
            [-80.853935523999951, 35.210926502000063],
            [-80.853914930999963, 35.210910719000026],
            [-80.853157797999984, 35.210342461000039],
            [-80.853148804999989, 35.210335772000064],
            [-80.853049227999975, 35.21026237600006],
            [-80.852721021999969, 35.210019320000072],
            [-80.852714686999946, 35.210014311000066],
            [-80.852649241999984, 35.209965852000039],
            [-80.852580073999945, 35.209921011000063],
            [-80.852507480999975, 35.20987997800006],
            [-80.852431773999967, 35.209842931000026],
            [-80.852353275999974, 35.209810026000071],
            [-80.852272324999944, 35.209781405000058],
            [-80.852189265999982, 35.209757190000062],
            [-80.85210445499996, 35.209737485000062],
            [-80.852018254999962, 35.209722376000059],
            [-80.851931036999986, 35.209711925000079],
            [-80.851843171999974, 35.209706179000079],
            [-80.851755036999975, 35.209705160000055],
            [-80.851667009999971, 35.209708876000036],
            [-80.851627170999961, 35.209712442000068],
            [-80.851495953999972, 35.209709229000055],
            [-80.851488085999961, 35.209706525000058],
            [-80.851405026999942, 35.209682309000073],
            [-80.851320216999966, 35.209662604000073],
            [-80.851234016999967, 35.209647494000023],
            [-80.851146798999935, 35.209637043000043],
            [-80.85105893399998, 35.209631296000055],
            [-80.850970798999981, 35.209630277000031]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 88,
        "SolutionID": "PI-27:C",
        "Shape_Length": 0.03633825410683425,
        "Shape_Area": 3.8232270381942593e-5
      }
    },
    {
      "type": "Feature",
      "id": 89,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.872474979999936, 35.223844967000048],
            [-80.86697269299998, 35.217777184000056],
            [-80.861197073999961, 35.22086452800005],
            [-80.867531240999938, 35.227087404000031],
            [-80.872474979999936, 35.223844967000048]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 89,
        "SolutionID": "PI-27:H",
        "Shape_Length": 0.029531751905939825,
        "Shape_Area": 5.1667533878179768e-5
      }
    },
    {
      "type": "Feature",
      "id": 90,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.867716318999953, 35.224790691000067],
            [-80.873285391999957, 35.224368073000051],
            [-80.870576934999974, 35.219147312000075],
            [-80.867168541999945, 35.220564409000076],
            [-80.867716318999953, 35.224790691000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 90,
        "SolutionID": "PI-27:M",
        "Shape_Length": 0.019419469823459018,
        "Shape_Area": 2.2700262466598466e-5
      }
    },
    {
      "type": "Feature",
      "id": 91,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.87772401899997, 35.167635770000061],
              [-80.877876241999957, 35.167629863000059],
              [-80.87802781399995, 35.167616894000048],
              [-80.878178248999973, 35.16759690400005],
              [-80.87832706599994, 35.167569957000069],
              [-80.878473787999951, 35.167536140000038],
              [-80.878617944999974, 35.167495559000031],
              [-80.878759074999948, 35.167448347000061],
              [-80.878896726999983, 35.167394655000066],
              [-80.879030457999988, 35.167334652000079],
              [-80.879159840999989, 35.167268534000073],
              [-80.879284460999941, 35.167196510000053],
              [-80.879403918999969, 35.167118812000069],
              [-80.879517831999976, 35.167035689000045],
              [-80.879625835999946, 35.166947406000077],
              [-80.879727582999976, 35.16685424800005],
              [-80.87982274999996, 35.166756511000074],
              [-80.879911030999949, 35.166654510000058],
              [-80.879992141999935, 35.166548571000078],
              [-80.880065823999985, 35.166439033000074],
              [-80.880131841999969, 35.166326248000075],
              [-80.88018998199999, 35.166210576000026],
              [-80.880240060999938, 35.166092389000028],
              [-80.880281915999944, 35.165972064000073],
              [-80.880315414999984, 35.165849987000058],
              [-80.88034044799997, 35.165726550000045],
              [-80.880356937999977, 35.165602147000072],
              [-80.880364830999952, 35.16547717800006],
              [-80.880364099999952, 35.165352043000041],
              [-80.88035475099997, 35.165227141000059],
              [-80.88033681099995, 35.16510287400007],
              [-80.880310336999969, 35.16497963900008],
              [-80.88027541699995, 35.164857831000063],
              [-80.880232159999935, 35.164737841000033],
              [-80.880180707999955, 35.164620053000078],
              [-80.880121220999968, 35.164504844000078],
              [-80.880053893999957, 35.164392583000051],
              [-80.879978938999955, 35.164283630000057],
              [-80.879896598999949, 35.164178333000052],
              [-80.879807136999943, 35.164077030000044],
              [-80.879710838999983, 35.163980046000063],
              [-80.879608013999984, 35.16388769100007],
              [-80.879498990999934, 35.163800261000063],
              [-80.879384119999941, 35.163718036000034],
              [-80.879263766999941, 35.163641280000036],
              [-80.879138319999981, 35.163570237000044],
              [-80.879008179999971, 35.163505136000026],
              [-80.878873763999934, 35.163446186000044],
              [-80.878735500999937, 35.163393574000054],
              [-80.878593835999936, 35.163347471000066],
              [-80.878449220999983, 35.16330802300007],
              [-80.878302119999944, 35.163275357000032],
              [-80.878153004999945, 35.163249576000055],
              [-80.878002351999953, 35.163230765000037],
              [-80.877850644999967, 35.163218983000036],
              [-80.877698368999972, 35.163214268000047],
              [-80.877546011999982, 35.163216634000037],
              [-80.877394061999951, 35.163226076000058],
              [-80.877243005999958, 35.163242561000061],
              [-80.877093326999955, 35.163266038000074],
              [-80.87694550599997, 35.163296431000049],
              [-80.876800013999969, 35.163333642000055],
              [-80.876657319999936, 35.163377554000078],
              [-80.876517878999948, 35.163428023000051],
              [-80.87638213799994, 35.163484892000042],
              [-80.876250532999961, 35.163547974000039],
              [-80.876123483999947, 35.163617070000043],
              [-80.876001398999961, 35.163691958000072],
              [-80.87588466699998, 35.163772397000059],
              [-80.87577366499994, 35.16385813100004],
              [-80.875668745999974, 35.163948885000025],
              [-80.875570247999974, 35.164044367000031],
              [-80.875478484999974, 35.164144273000034],
              [-80.875393752999969, 35.164248281000027],
              [-80.875316321999946, 35.164356060000046],
              [-80.875246437999976, 35.164467263000063],
              [-80.875184328999978, 35.164581535000025],
              [-80.875130190999982, 35.164698509000061],
              [-80.875084199999947, 35.164817811000034],
              [-80.875046500999986, 35.164939058000073],
              [-80.875017214999957, 35.165061864000052],
              [-80.874996435999947, 35.165185832000077],
              [-80.874984232999964, 35.165310568000052],
              [-80.874980641999969, 35.165435670000079],
              [-80.874985675999937, 35.165560739000057],
              [-80.874999317999936, 35.165685374000077],
              [-80.875021525999955, 35.165809175000049],
              [-80.875052226999969, 35.165931747000059],
              [-80.875091323999982, 35.166052696000065],
              [-80.875138690999961, 35.166171635000069],
              [-80.875194176999969, 35.166288182000073],
              [-80.875257603999955, 35.166401965000034],
              [-80.875328768999964, 35.166512620000049],
              [-80.875407443999961, 35.166619791000073],
              [-80.875493375999952, 35.166723135000041],
              [-80.87558629199998, 35.166822321000041],
              [-80.875685891999979, 35.166917032000072],
              [-80.875791857999957, 35.167006964000052],
              [-80.875903850999975, 35.167091829000071],
              [-80.876021510999976, 35.16717135500005],
              [-80.876144461999957, 35.167245287000071],
              [-80.87627230999999, 35.167313390000061],
              [-80.876404645999969, 35.167375442000036],
              [-80.876541044999954, 35.16743124900006],
              [-80.87668107099995, 35.167480627000032],
              [-80.87682427499999, 35.167523423000034],
              [-80.876970197999981, 35.16755949700007],
              [-80.877118371999984, 35.167588733000059],
              [-80.877268323999942, 35.167611039000064],
              [-80.877419572999941, 35.167626342000062],
              [-80.877571632999945, 35.167634595000038],
              [-80.87772401899997, 35.167635770000061]
            ]
          ],
          [
            [
              [-80.879761082999948, 35.180237323000028],
              [-80.879913328999976, 35.180231414000048],
              [-80.880064923999953, 35.180218441000079],
              [-80.88021538199996, 35.180198449000045],
              [-80.880364221999969, 35.180171500000029],
              [-80.880510964999985, 35.18013768000003],
              [-80.880655142999956, 35.180097097000044],
              [-80.880796293999936, 35.180049883000038],
              [-80.880933965999986, 35.179996189000065],
              [-80.881067715999961, 35.179936184000042],
              [-80.881197117999989, 35.179870063000067],
              [-80.881321754999988, 35.179798037000069],
              [-80.88144122999995, 35.179720337000049],
              [-80.881555157999969, 35.179637212000046],
              [-80.881663175999961, 35.179548928000031],
              [-80.881764936999957, 35.179455768000025],
              [-80.881860116999974, 35.17935803000006],
              [-80.881948407999971, 35.179256028000054],
              [-80.882029528999965, 35.179150088000029],
              [-80.882103219999976, 35.179040549000035],
              [-80.882169244999943, 35.178927763000047],
              [-80.882227391999947, 35.178812090000065],
              [-80.882277474999967, 35.178693902000077],
              [-80.882319333999988, 35.178573577000066],
              [-80.88235283399996, 35.178451500000051],
              [-80.882377868999981, 35.178328062000048],
              [-80.882394357999942, 35.178203660000065],
              [-80.882402248999938, 35.178078690000063],
              [-80.88240151499997, 35.177953555000045],
              [-80.882392160999984, 35.177828654000052],
              [-80.88237421499997, 35.177704387000063],
              [-80.882347734999939, 35.177581153000062],
              [-80.882312805999959, 35.177459346000035],
              [-80.882269538999935, 35.177339357000051],
              [-80.882218075999958, 35.177221570000029],
              [-80.882158576999984, 35.177106362000075],
              [-80.882091235999951, 35.176994103000027],
              [-80.882016266999983, 35.176885151000079],
              [-80.881933911999965, 35.176779855000063],
              [-80.881844433999959, 35.176678555000024],
              [-80.881748118999951, 35.176581572000032],
              [-80.881645275999972, 35.176489219000075],
              [-80.881536232999963, 35.176401791000046],
              [-80.881421341999953, 35.176319568000054],
              [-80.881300968999938, 35.176242814000034],
              [-80.881175500999973, 35.176171773000078],
              [-80.881045338999968, 35.176106675000028],
              [-80.880910900999936, 35.176047727000025],
              [-80.880772614999955, 35.17599511800006],
              [-80.88063092699997, 35.17594901700005],
              [-80.880486288999975, 35.175909571000034],
              [-80.880339163999963, 35.175876908000077],
              [-80.88019002499999, 35.175851130000069],
              [-80.880039348999958, 35.17583232100003],
              [-80.879887616999952, 35.175820542000054],
              [-80.879735317999973, 35.175815829000044],
              [-80.879582937999942, 35.175818198000059],
              [-80.879430964999983, 35.175827642000058],
              [-80.87927988499996, 35.17584413000003],
              [-80.879130183999962, 35.175867609000079],
              [-80.878982340999983, 35.175898005000079],
              [-80.878836827999976, 35.175935218000063],
              [-80.878694111999948, 35.175979132000066],
              [-80.878554650999945, 35.176029604000064],
              [-80.878418890999967, 35.176086474000044],
              [-80.878287266999962, 35.176149559000066],
              [-80.878160199999968, 35.176218657000049],
              [-80.878038097999934, 35.176293547000057],
              [-80.877921349999951, 35.17637398800008],
              [-80.877810332999957, 35.17645972300005],
              [-80.877705399999968, 35.17655047900007],
              [-80.877606888999935, 35.176645962000066],
              [-80.877515114999937, 35.176745870000047],
              [-80.877430371999935, 35.176849879000031],
              [-80.877352930999962, 35.176957659000038],
              [-80.877283039999952, 35.177068863000045],
              [-80.877220923999971, 35.177183136000053],
              [-80.877166780999971, 35.177300111000079],
              [-80.877120784999988, 35.177419413000052],
              [-80.877083082999945, 35.17754066100008],
              [-80.877053795999984, 35.177663467000059],
              [-80.877033016999974, 35.177787436000074],
              [-80.87702081499998, 35.17791217100006],
              [-80.877017226999953, 35.178037273000029],
              [-80.877022263999947, 35.178162342000064],
              [-80.87703591199994, 35.178286976000038],
              [-80.877058125999952, 35.178410777000067],
              [-80.877088834999938, 35.17853334800003],
              [-80.877127940999969, 35.178654296000047],
              [-80.877175318999946, 35.178773234000062],
              [-80.877230815999951, 35.178889780000077],
              [-80.877294255999971, 35.179003562000048],
              [-80.877365434999945, 35.179114215000027],
              [-80.877444123999965, 35.179221384000073],
              [-80.877530071999956, 35.179324727000051],
              [-80.877623003999986, 35.179423912000061],
              [-80.877722622999954, 35.179518621000057],
              [-80.877828606999969, 35.179608551000058],
              [-80.877940618999958, 35.179693414000042],
              [-80.878058299999964, 35.179772937000052],
              [-80.878181271999949, 35.179846867000037],
              [-80.878309140999988, 35.179914967000059],
              [-80.878441498999962, 35.179977018000045],
              [-80.878577920999987, 35.180032822000044],
              [-80.878717969999968, 35.180082198000036],
              [-80.878861196999935, 35.180124991000071],
              [-80.879007142999967, 35.180161063000071],
              [-80.879155340999944, 35.180190296000035],
              [-80.879305316999989, 35.180212600000061],
              [-80.879456588999972, 35.180227900000034],
              [-80.87960867399994, 35.180236151000031],
              [-80.879761082999948, 35.180237323000028]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 91,
        "SolutionID": "PI-27:K",
        "Shape_Length": 0.03088101089436834,
        "Shape_Area": 3.7387127526443371e-5
      }
    },
    {
      "type": "Feature",
      "id": 92,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.871478825999986, 35.176912602000073],
            [-80.871631065999964, 35.176906703000043],
            [-80.871782655999937, 35.176893741000072],
            [-80.871933109999986, 35.176873759000046],
            [-80.87208194699997, 35.176846820000037],
            [-80.87222868799995, 35.176813010000046],
            [-80.872372863999942, 35.176772437000068],
            [-80.872514013999989, 35.17672523300007],
            [-80.872651684999937, 35.176671548000058],
            [-80.872785435999958, 35.176611552000054],
            [-80.872914838999975, 35.176545440000041],
            [-80.873039478999942, 35.176473423000061],
            [-80.873158955999941, 35.176395731000071],
            [-80.873272887999974, 35.17631261400004],
            [-80.87338091099997, 35.176224337000065],
            [-80.873482677999959, 35.176131184000042],
            [-80.87357786299998, 35.176033453000059],
            [-80.87366616199995, 35.175931457000047],
            [-80.873747289999983, 35.175825521000036],
            [-80.873820988999967, 35.175715988000036],
            [-80.873887021999963, 35.175603206000062],
            [-80.873945178999975, 35.175487537000038],
            [-80.873995271999945, 35.175369353000065],
            [-80.874037140999974, 35.175249030000032],
            [-80.87407065299999, 35.175126955000053],
            [-80.874095698999952, 35.175003520000075],
            [-80.874112199999956, 35.174879118000035],
            [-80.874120102999939, 35.17475414900008],
            [-80.874119381999947, 35.174629014000061],
            [-80.874110040999938, 35.174504112000079],
            [-80.874092108999946, 35.174379844000043],
            [-80.874065641999948, 35.174256608000064],
            [-80.87403072699999, 35.174134799000058],
            [-80.873987473999989, 35.174014806000059],
            [-80.873936024999978, 35.173897016000069],
            [-80.873876540999959, 35.173781804000043],
            [-80.873809213999948, 35.173669540000049],
            [-80.873734258999946, 35.17356058200005],
            [-80.87365191799995, 35.173455281000031],
            [-80.873562452999977, 35.173353974000065],
            [-80.873466151999935, 35.17325698500008],
            [-80.873363321999989, 35.173164625000027],
            [-80.873254292999945, 35.173077189000026],
            [-80.873139414999969, 35.17299495900005],
            [-80.873019054999986, 35.172918196000069],
            [-80.872893598999951, 35.172847147000027],
            [-80.87276344899999, 35.172782040000072],
            [-80.872629021999956, 35.172723083000051],
            [-80.872490747999962, 35.172670464000078],
            [-80.872349069999984, 35.17262435400005],
            [-80.872204441999941, 35.172584898000025],
            [-80.872057326999936, 35.172552224000071],
            [-80.871908195999936, 35.172526436000055],
            [-80.871757527999989, 35.172507617000065],
            [-80.871605803999955, 35.172495827000034],
            [-80.87145351099997, 35.172491104000073],
            [-80.871301136999989, 35.17249346300008],
            [-80.871149168999978, 35.172502897000072],
            [-80.870998093999958, 35.172519373000057],
            [-80.870848396999975, 35.172542843000031],
            [-80.870700555999974, 35.172573228000033],
            [-80.870555044999946, 35.172610432000056],
            [-80.870412330999955, 35.172654336000051],
            [-80.870272870999941, 35.172704798000041],
            [-80.870137109999973, 35.172761659000059],
            [-80.870005484999979, 35.172824735000063],
            [-80.869878415999949, 35.172893825000074],
            [-80.869756310999946, 35.172968706000063],
            [-80.869639560999985, 35.173049139000057],
            [-80.869528538999987, 35.173134867000044],
            [-80.869423600999937, 35.173225615000035],
            [-80.869325084999957, 35.173321092000037],
            [-80.869233303999977, 35.173420993000036],
            [-80.869148553999935, 35.173524997000072],
            [-80.869071104999989, 35.17363277100003],
            [-80.86900120599995, 35.173743971000079],
            [-80.868939079999961, 35.173858240000072],
            [-80.868884927999943, 35.173975211000027],
            [-80.868838921999952, 35.174094510000032],
            [-80.868801208999969, 35.174215755000034],
            [-80.868771910999953, 35.174338559000034],
            [-80.868751119999956, 35.17446252600007],
            [-80.868738905999976, 35.174587261000056],
            [-80.868735304999973, 35.174712363000026],
            [-80.86874032999998, 35.174837432000061],
            [-80.86875396399995, 35.174962067000024],
            [-80.868776164999986, 35.175085870000032],
            [-80.86880685999995, 35.175208443000031],
            [-80.868845951999958, 35.175329394000073],
            [-80.868893315999969, 35.175448335000056],
            [-80.868948798999952, 35.175564885000028],
            [-80.869012224999949, 35.175678671000071],
            [-80.869083388999968, 35.175789329000054],
            [-80.869162063999966, 35.175896504000036],
            [-80.869247998999981, 35.175999853000064],
            [-80.869340916999988, 35.176099044000068],
            [-80.869440521999934, 35.176193760000046],
            [-80.869546492999973, 35.176283697000031],
            [-80.869658491999985, 35.176368567000054],
            [-80.869776158999969, 35.176448099000027],
            [-80.869899117999978, 35.17652203800003],
            [-80.870026975999963, 35.17659014700007],
            [-80.870159321999949, 35.176652206000028],
            [-80.870295732999978, 35.176708020000035],
            [-80.870435770999961, 35.176757405000046],
            [-80.870578987999977, 35.176800208000031],
            [-80.870724923999944, 35.17683629000004],
            [-80.870873113999949, 35.176865534000058],
            [-80.871023080999976, 35.176887847000046],
            [-80.871174345999975, 35.176903158000073],
            [-80.871326422999971, 35.176911419000078],
            [-80.871478825999986, 35.176912602000073]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 92,
        "SolutionID": "PI-27:L",
        "Shape_Length": 0.015440825879454768,
        "Shape_Area": 1.8694238287517181e-5
      }
    },
    {
      "type": "Feature",
      "id": 93,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.852993941999955, 35.234901622000052],
            [-80.853125560999956, 35.234961803000033],
            [-80.853261044999954, 35.235015903000033],
            [-80.853399976999981, 35.235063756000045],
            [-80.85354192899996, 35.235105215000033],
            [-80.853686464999953, 35.235140152000042],
            [-80.85383313899996, 35.235168458000032],
            [-80.853981501999954, 35.23519004800005],
            [-80.854131094999957, 35.235204856000053],
            [-80.854281458999935, 35.235212834000038],
            [-80.854432130999953, 35.235213959000077],
            [-80.854585430999975, 35.235208536000073],
            [-80.854738105999957, 35.235195966000049],
            [-80.854889661999948, 35.235176289000037],
            [-80.85503960799997, 35.235149569000043],
            [-80.855187458999978, 35.235115892000067],
            [-80.855332733999944, 35.235075369000072],
            [-80.855474963999939, 35.235028129000057],
            [-80.855613686999959, 35.234974327000032],
            [-80.855748455999958, 35.234914135000054],
            [-80.855878829999938, 35.234847750000029],
            [-80.856004390999942, 35.234775386000024],
            [-80.856124729999976, 35.234697279000045],
            [-80.856239456999958, 35.234613681000042],
            [-80.856348200999946, 35.234524861000068],
            [-80.856450609999968, 35.234431111000049],
            [-80.856546350999963, 35.234332731000052],
            [-80.85663511499996, 35.234230041000046],
            [-80.856716613999936, 35.234123373000045],
            [-80.856790583999953, 35.234013074000075],
            [-80.85685678599998, 35.233899501000053],
            [-80.856915003999973, 35.233783021000079],
            [-80.856965050999975, 35.233664012000077],
            [-80.857006764999937, 35.233542860000057],
            [-80.857040009999935, 35.233419957000024],
            [-80.857064676999983, 35.233295701000031],
            [-80.857080688999986, 35.233170495000024],
            [-80.857087992999936, 35.23304474300005],
            [-80.857086564999975, 35.232918856000026],
            [-80.857076409999934, 35.232793238000056],
            [-80.857057560999976, 35.232668298000078],
            [-80.85703007799998, 35.232544440000026],
            [-80.856994051999948, 35.232422066000026],
            [-80.856949596999982, 35.23230157100005],
            [-80.856896860999939, 35.232183348000035],
            [-80.856836011999974, 35.232067777000054],
            [-80.856767247999983, 35.231955234000054],
            [-80.856690790999949, 35.23184608400004],
            [-80.856606888999977, 35.23174067900004],
            [-80.856515814999966, 35.231639361000077],
            [-80.856417861999944, 35.231542459000025],
            [-80.856313349999937, 35.23145028700003],
            [-80.856202615999962, 35.231363143000067],
            [-80.856086017999985, 35.231281310000043],
            [-80.855963935999966, 35.231205051000074],
            [-80.855836762999957, 35.23113461500003],
            [-80.855704912999954, 35.231070230000057],
            [-80.855568811999945, 35.231012104000058],
            [-80.855428899999936, 35.230960426000024],
            [-80.855285632999937, 35.230915363000065],
            [-80.855139471999962, 35.230877060000068],
            [-80.854990892999979, 35.230845642000077],
            [-80.85484037599997, 35.230821212000023],
            [-80.854688407999959, 35.230803847000061],
            [-80.85453548299995, 35.230793604000041],
            [-80.854382095999938, 35.230790517000059],
            [-80.854228741999975, 35.230794595000077],
            [-80.854075919999957, 35.230805825000061],
            [-80.853924123999946, 35.230824171000052],
            [-80.853790224999955, 35.230762039000069],
            [-80.853652226999941, 35.230706268000063],
            [-80.853510581999956, 35.23065704000004],
            [-80.853365746999941, 35.230614514000024],
            [-80.853218193999965, 35.23057883000007],
            [-80.853068403999941, 35.230550102000052],
            [-80.852916861999972, 35.230528424000056],
            [-80.85276405999997, 35.23051386800006],
            [-80.852610494999965, 35.230506478000052],
            [-80.852456667999945, 35.230506281000032],
            [-80.852303074999952, 35.230513277000057],
            [-80.852150219999942, 35.230527442000039],
            [-80.851998594999941, 35.230548731000056],
            [-80.851848694999944, 35.230577074000053],
            [-80.851701007999964, 35.230612381000071],
            [-80.851556011999946, 35.230654535000042],
            [-80.85141417899996, 35.230703400000039],
            [-80.85127597099995, 35.230758817000037],
            [-80.851141834999964, 35.230820606000066],
            [-80.851012208999975, 35.230888565000043],
            [-80.850887511999986, 35.230962476000059],
            [-80.850768151999944, 35.231042096000067],
            [-80.850654513999984, 35.231127167000068],
            [-80.850546969999982, 35.23121741500006],
            [-80.850445866999962, 35.231312542000069],
            [-80.850351534999959, 35.231412243000079],
            [-80.850264280999966, 35.231516193000061],
            [-80.850184387999946, 35.231624053000075],
            [-80.850112114999945, 35.231735472000025],
            [-80.85004769699998, 35.231850091000069],
            [-80.849991344999978, 35.231967535000024],
            [-80.849943240999949, 35.232087422000063],
            [-80.849903539999957, 35.232209365000074],
            [-80.849872373999972, 35.232332965000069],
            [-80.849849841999969, 35.232457821000025],
            [-80.849836018999952, 35.232583528000077],
            [-80.849830947999976, 35.232709677000059],
            [-80.849834646999966, 35.232835858000044],
            [-80.849847102999945, 35.232961662000037],
            [-80.849868276999985, 35.233086677000074],
            [-80.849898098999972, 35.23321050100003],
            [-80.849936472999957, 35.23333272900004],
            [-80.849983272999964, 35.233452964000037],
            [-80.850038348999988, 35.233570815000064],
            [-80.850101518999963, 35.233685900000069],
            [-80.850172579999935, 35.233797844000037],
            [-80.850251300999957, 35.233906284000057],
            [-80.850337425999953, 35.234010866000062],
            [-80.850430673999938, 35.234111253000037],
            [-80.850530743999968, 35.23420711600005],
            [-80.850637308999978, 35.234298146000071],
            [-80.850750022999989, 35.234384043000034],
            [-80.850868520999938, 35.234464532000061],
            [-80.850992414999951, 35.234539350000034],
            [-80.851121305999982, 35.234608254000079],
            [-80.851254773999983, 35.234671019000075],
            [-80.851392382999961, 35.234727442000064],
            [-80.851533686999971, 35.234777339000061],
            [-80.851678227999969, 35.234820547000027],
            [-80.851825535999978, 35.234856929000046],
            [-80.851975130999961, 35.234886363000044],
            [-80.85212652599995, 35.234908755000049],
            [-80.852279231999944, 35.234924033000027],
            [-80.852432749999934, 35.234932146000062],
            [-80.852586582999947, 35.234933068000032],
            [-80.852722953999944, 35.234928187000037],
            [-80.852858854999965, 35.234917695000036],
            [-80.852993941999955, 35.234901622000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 93,
        "SolutionID": "PI-27:J",
        "Shape_Length": 0.019285733878802783,
        "Shape_Area": 2.6954589215552962e-5
      }
    },
    {
      "type": "Feature",
      "id": 94,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.875960028999941, 35.16416507200006],
            [-80.875671391999958, 35.164177318000043],
            [-80.875384347999955, 35.16420504000007],
            [-80.87510012599995, 35.164248119000035],
            [-80.874819942999977, 35.16430636900003],
            [-80.874544998999966, 35.164379543000052],
            [-80.874276470999973, 35.164467325000032],
            [-80.874015508999946, 35.16456934100006],
            [-80.873763228999962, 35.16468515400004],
            [-80.873520712999948, 35.164814268000043],
            [-80.873288998999953, 35.164956130000064],
            [-80.873069078999947, 35.165110133000042],
            [-80.872861893999982, 35.165275617000077],
            [-80.872668332999979, 35.165451873000052],
            [-80.872489222999945, 35.165638149000074],
            [-80.872325331999946, 35.165833643000042],
            [-80.872177359999966, 35.166037522000067],
            [-80.872045943999979, 35.166248912000071],
            [-80.871931644999961, 35.166466906000039],
            [-80.871834952999961, 35.16669057200005],
            [-80.871756280999989, 35.16691895200006],
            [-80.871695966999937, 35.167151068000067],
            [-80.871654268999976, 35.167385927000055],
            [-80.871631366999964, 35.167622522000045],
            [-80.871627357999955, 35.167859840000062],
            [-80.871642258999941, 35.168096866000042],
            [-80.871676007999952, 35.168332584000041],
            [-80.871728458999939, 35.168565985000043],
            [-80.871799388999989, 35.168796068000063],
            [-80.871888492999972, 35.169021850000036],
            [-80.871995389999938, 35.16924236400007],
            [-80.872119621999957, 35.169456664000052],
            [-80.872260657999959, 35.169663833000072],
            [-80.872417893999966, 35.169862985000066],
            [-80.872590656999989, 35.170053264000046],
            [-80.872778205999964, 35.170233859000064],
            [-80.872979738999959, 35.170403994000026],
            [-80.873194392999949, 35.170562941000071],
            [-80.873421247999943, 35.170710020000058],
            [-80.873659332999978, 35.170844601000056],
            [-80.873907628999973, 35.170966107000027],
            [-80.874165072999972, 35.171074017000024],
            [-80.874430559999951, 35.17116787100008],
            [-80.874702955999965, 35.171247265000034],
            [-80.874981091999985, 35.17131186000006],
            [-80.875263778999965, 35.171361379000075],
            [-80.875549803999945, 35.171395611000037],
            [-80.875837943999954, 35.171414407000043],
            [-80.875879238999971, 35.171415828000079],
            [-80.877690212999937, 35.171471151000048],
            [-80.877937938999935, 35.171473008000078],
            [-80.878226601999984, 35.171460757000034],
            [-80.878513670999951, 35.17143302900007],
            [-80.87879791599994, 35.171389942000076],
            [-80.879078119999974, 35.171331682000073],
            [-80.879353082999955, 35.171258499000032],
            [-80.879621627999938, 35.171170705000065],
            [-80.879882604999978, 35.171068677000051],
            [-80.880134894999969, 35.170952851000038],
            [-80.880377419999945, 35.170823724000059],
            [-80.880609136999965, 35.170681848000072],
            [-80.88082905899995, 35.170527832000062],
            [-80.881036240999947, 35.17036233500005],
            [-80.881229795999957, 35.170186065000053],
            [-80.881408894999936, 35.169999777000044],
            [-80.881572772999959, 35.169804270000043],
            [-80.881720727999948, 35.169600380000077],
            [-80.881852123999977, 35.169388980000065],
            [-80.881966400999943, 35.169170976000032],
            [-80.882063068999969, 35.168947302000049],
            [-80.882141713999943, 35.168718915000056],
            [-80.88220199899996, 35.168486794000046],
            [-80.882243666999955, 35.168251932000032],
            [-80.882266538999943, 35.168015335000064],
            [-80.882270515999949, 35.167778016000057],
            [-80.88225558399995, 35.167540992000056],
            [-80.882221805999961, 35.167305277000025],
            [-80.88216932499995, 35.167071881000027],
            [-80.882098367999959, 35.166841803000068],
            [-80.882009237999966, 35.166616028000078],
            [-80.88190231699997, 35.166395524000052],
            [-80.881778063999946, 35.166181234000078],
            [-80.881637009999963, 35.165974075000065],
            [-80.881479757999955, 35.165774936000048],
            [-80.881306983999934, 35.165584669000054],
            [-80.881119425999941, 35.16540408700007],
            [-80.880917888999988, 35.165233965000027],
            [-80.880703233999952, 35.165075032000061],
            [-80.880476380999937, 35.16492796700004],
            [-80.880238302999942, 35.164793399000075],
            [-80.879990015999965, 35.164671906000024],
            [-80.879732584999942, 35.16456400800007],
            [-80.879467111999986, 35.164470166000058],
            [-80.879194734999942, 35.164390783000044],
            [-80.878916618999938, 35.164326197000037],
            [-80.878633954999941, 35.164276686000051],
            [-80.878347952999945, 35.164242462000061],
            [-80.878059837999956, 35.164223671000059],
            [-80.878018546999954, 35.164222252000059],
            [-80.876207732999944, 35.164166933000047],
            [-80.875960028999941, 35.16416507200006]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 94,
        "SolutionID": "PI-27:W",
        "Shape_Length": 0.028950536392435201,
        "Shape_Area": 6.3437752821365694e-5
      }
    },
    {
      "type": "Feature",
      "id": 95,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.87632320199998, 35.144595690000074],
            [-80.87603463399995, 35.144607937000046],
            [-80.875747658999956, 35.144635659000073],
            [-80.87546350599996, 35.144678739000028],
            [-80.87518339099995, 35.144736990000069],
            [-80.87490851299998, 35.144810165000024],
            [-80.874640049999982, 35.14489794900004],
            [-80.874379149999982, 35.144999966000057],
            [-80.874126931999967, 35.145115779000037],
            [-80.873884473999965, 35.145244895000076],
            [-80.873652816999936, 35.145386758000029],
            [-80.873432949999938, 35.145540762000053],
            [-80.873225815999945, 35.145706246000032],
            [-80.873032301999956, 35.145882504000042],
            [-80.872853235999969, 35.146068780000064],
            [-80.872689383999955, 35.146264276000068],
            [-80.872541449999972, 35.146468156000026],
            [-80.872410065999986, 35.14667954600003],
            [-80.872295794999957, 35.146897542000033],
            [-80.872199126999988, 35.147121209000034],
            [-80.872120474999974, 35.147349590000033],
            [-80.87206017699998, 35.147581707000029],
            [-80.872018489999959, 35.147816566000074],
            [-80.871995593999941, 35.148053161000064],
            [-80.871991586999968, 35.14829048100006],
            [-80.872006485999975, 35.14852750700004],
            [-80.872040226999957, 35.148763226000028],
            [-80.872092667999937, 35.148996626000041],
            [-80.872163580999938, 35.14922671100004],
            [-80.872252664999962, 35.149452493000069],
            [-80.872359536999966, 35.149673007000047],
            [-80.87248373999995, 35.149887307000029],
            [-80.87262474299996, 35.150094476000049],
            [-80.87278194299995, 35.150293628000043],
            [-80.872954664999952, 35.150483908000069],
            [-80.873142169999937, 35.150664502000041],
            [-80.873343654999985, 35.15083463700006],
            [-80.873558257999946, 35.150993584000048],
            [-80.873785059999989, 35.151140663000035],
            [-80.874023087999944, 35.151275243000043],
            [-80.874271324999938, 35.151396749000071],
            [-80.874528706999968, 35.151504659000068],
            [-80.874794131999977, 35.151598511000032],
            [-80.87506646199995, 35.151677905000042],
            [-80.875344531999986, 35.151742500000069],
            [-80.875627150999946, 35.151792018000037],
            [-80.875913107999963, 35.151826249000067],
            [-80.876201178999963, 35.151845045000073],
            [-80.876398743999971, 35.151848968000024],
            [-80.878684749999934, 35.151852313000063],
            [-80.878776135999942, 35.151851668000063],
            [-80.879064728999936, 35.15183941500004],
            [-80.879351727999961, 35.151811685000041],
            [-80.87963590399994, 35.151768596000068],
            [-80.879916040999944, 35.151710334000029],
            [-80.880190936999952, 35.151637148000077],
            [-80.880459415999951, 35.151549352000075],
            [-80.880720328999985, 35.151447322000024],
            [-80.880972557999939, 35.151331495000079],
            [-80.881215021999935, 35.151202365000074],
            [-80.881446682999979, 35.151060488000041],
            [-80.881666549999977, 35.150906470000052],
            [-80.881873679999956, 35.150740970000072],
            [-80.882067185999972, 35.150564699000029],
            [-80.882246240999962, 35.150378409000041],
            [-80.882410077999964, 35.150182900000061],
            [-80.882557994999956, 35.149979008000059],
            [-80.882689356999947, 35.149767607000058],
            [-80.882803603999946, 35.149549602000036],
            [-80.882900245999963, 35.149325927000064],
            [-80.882978869999988, 35.149097539000024],
            [-80.883039137999958, 35.148865416000035],
            [-80.883080793999966, 35.148630554000079],
            [-80.883103657999982, 35.148393956000064],
            [-80.883107631999962, 35.148156636000067],
            [-80.883092700999953, 35.147919611000077],
            [-80.883058927999969, 35.147683896000046],
            [-80.883006457999954, 35.147450499000058],
            [-80.882935514999986, 35.147220421000043],
            [-80.882846403999963, 35.146994647000042],
            [-80.88273950699994, 35.146774143000073],
            [-80.882615281999961, 35.146559853000042],
            [-80.882474258999935, 35.146352695000076],
            [-80.88231704399999, 35.146153556000058],
            [-80.882144308999955, 35.145963289000065],
            [-80.881956794999951, 35.145782709000059],
            [-80.881755303999967, 35.145612588000063],
            [-80.88154069999996, 35.14545365500004],
            [-80.881313898999963, 35.145306591000065],
            [-80.881075876999944, 35.145172025000079],
            [-80.880827647999979, 35.145050533000074],
            [-80.880570277999936, 35.144942637000042],
            [-80.880304868999986, 35.144848797000066],
            [-80.880032555999946, 35.144769415000042],
            [-80.879754505999983, 35.14470483100007],
            [-80.87947190999995, 35.144655322000062],
            [-80.879185975999974, 35.144621100000052],
            [-80.878897929999937, 35.144602311000028],
            [-80.878700381999977, 35.144598392000034],
            [-80.87641457999996, 35.144595047000053],
            [-80.87632320199998, 35.144595690000074]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 95,
        "SolutionID": "PI-27:V",
        "Shape_Length": 0.029895430178199636,
        "Shape_Area": 6.6862678629987449e-5
      }
    },
    {
      "type": "Feature",
      "id": 96,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.882432694999977, 35.126461187000075],
            [-80.882144191999942, 35.12647344800007],
            [-80.881857282999988, 35.126501185000052],
            [-80.881573195999977, 35.126544279000029],
            [-80.881293147999941, 35.126602545000026],
            [-80.881018335999954, 35.126675734000059],
            [-80.880749938999941, 35.126763531000051],
            [-80.88048910599997, 35.126865561000045],
            [-80.880236951999962, 35.126981388000047],
            [-80.879994557999964, 35.127110516000073],
            [-80.879762962999962, 35.127252392000059],
            [-80.879543155999954, 35.127406407000024],
            [-80.879336080999963, 35.127571902000057],
            [-80.87914262299995, 35.127748170000075],
            [-80.878963610999961, 35.127934456000048],
            [-80.878799809999975, 35.128129960000024],
            [-80.878651923999939, 35.128333848000068],
            [-80.87852058499999, 35.128545246000044],
            [-80.878406355999971, 35.128763247000052],
            [-80.878309725999941, 35.128986920000045],
            [-80.878231108999955, 35.129215305000059],
            [-80.878170840999985, 35.12944742600007],
            [-80.878129180999963, 35.129682288000026],
            [-80.878106307999985, 35.129918885000052],
            [-80.878102319999982, 35.130156205000048],
            [-80.878117232999955, 35.130393231000028],
            [-80.878150984999934, 35.130628949000027],
            [-80.878203430999974, 35.130862347000061],
            [-80.878274345999955, 35.131092429000034],
            [-80.878363426999954, 35.131318207000049],
            [-80.878401961999941, 35.131402670000057],
            [-80.878405209999983, 35.131409530000042],
            [-80.878473539999959, 35.131545575000075],
            [-80.878597731999946, 35.131759870000053],
            [-80.878738718999955, 35.131967033000024],
            [-80.878895898999986, 35.132166177000045],
            [-80.879068596999957, 35.132356448000053],
            [-80.879256073999954, 35.132537034000052],
            [-80.879457526999943, 35.132707159000063],
            [-80.879672093999943, 35.132866096000043],
            [-80.879898855999954, 35.133013163000044],
            [-80.880136841999956, 35.133147731000065],
            [-80.880385032999982, 35.133269225000049],
            [-80.880642365999961, 35.13337712200007],
            [-80.880907737999962, 35.133470962000047],
            [-80.881024814999989, 35.133507119000058],
            [-80.88111338799996, 35.133533302000046],
            [-80.881694970999945, 35.133705226000075],
            [-80.881761163999954, 35.133724306000033],
            [-80.882246232999989, 35.133860584000047],
            [-80.882335251999962, 35.133884729000044],
            [-80.882495612999946, 35.133923946000039],
            [-80.882816629999979, 35.133997040000054],
            [-80.882934283999987, 35.134022401000038],
            [-80.883154047999938, 35.134062269000026],
            [-80.88326346499997, 35.134079727000028],
            [-80.883326261999969, 35.134089361000065],
            [-80.883539761999941, 35.134116391000077],
            [-80.883649176999938, 35.134128000000032],
            [-80.883721575999971, 35.134135183000069],
            [-80.883935208999958, 35.134150601000044],
            [-80.884018528999945, 35.134154943000055],
            [-80.884092905999978, 35.13415830100007],
            [-80.884381794999967, 35.134161563000077],
            [-80.884395163999955, 35.134161338000069],
            [-80.884478482999953, 35.134159831000034],
            [-80.884753644999989, 35.134147789000053],
            [-80.885040579999952, 35.134120045000031],
            [-80.88508530699994, 35.134114302000057],
            [-80.885207240999989, 35.134098123000058],
            [-80.885446625999975, 35.134060762000047],
            [-80.885620164999978, 35.134026547000076],
            [-80.88573930299998, 35.134000953000054],
            [-80.885845835999987, 35.133976894000057],
            [-80.886085096999977, 35.133914097000059],
            [-80.88620423499998, 35.133879729000057],
            [-80.886239805999935, 35.13386932700007],
            [-80.886364454999978, 35.133828550000032],
            [-80.886580085999981, 35.133819379000045],
            [-80.886867018999965, 35.133791630000076],
            [-80.88715112899996, 35.133748524000055],
            [-80.887431197999945, 35.133690245000025],
            [-80.887706026999979, 35.133617042000026],
            [-80.887974438999947, 35.133529229000033],
            [-80.888235283999961, 35.133427182000048],
            [-80.888336084999935, 35.133380875000057],
            [-80.888367101999961, 35.133415041000035],
            [-80.888554601999942, 35.133595612000079],
            [-80.888756076999982, 35.133765722000078],
            [-80.888970664999988, 35.133924642000068],
            [-80.889197447999948, 35.134071692000077],
            [-80.88943545099994, 35.134206242000062],
            [-80.889683659999946, 35.13432771600003],
            [-80.889941007999937, 35.134435594000024],
            [-80.89020639499995, 35.134529413000053],
            [-80.890478682999969, 35.134608772000036],
            [-80.89075670699998, 35.134673331000045],
            [-80.891039275999958, 35.134722813000053],
            [-80.891179467999962, 35.134741543000075],
            [-80.891225824999935, 35.134747108000056],
            [-80.891910638999946, 35.134921399000064],
            [-80.891929384999969, 35.134926132000032],
            [-80.892375990999938, 35.135038011000063],
            [-80.892602589999967, 35.135089400000027],
            [-80.89288516199997, 35.135138878000078],
            [-80.893171066999969, 35.135173069000075],
            [-80.893348576999983, 35.135186469000075],
            [-80.893432510999958, 35.135191404000068],
            [-80.893543017999946, 35.135196758000063],
            [-80.893808823999962, 35.135200309000027],
            [-80.893889182999942, 35.135199395000029],
            [-80.893912268999941, 35.135199083000032],
            [-80.894200801999943, 35.135186793000059],
            [-80.894487736999963, 35.135159027000043],
            [-80.894771846999959, 35.135115902000052],
            [-80.895050392999963, 35.13505796700008],
            [-80.895119126999987, 35.135041698000066],
            [-80.895120648999978, 35.135041337000075],
            [-80.895395475999976, 35.134968116000039],
            [-80.895663883999987, 35.134880286000055],
            [-80.895767855999964, 35.134841660000063],
            [-80.895833012999958, 35.134816615000034],
            [-80.895989880999934, 35.134753178000039],
            [-80.896242036999979, 35.134637319000035],
            [-80.896472427999981, 35.134514997000053],
            [-80.896682804999955, 35.134395528000027],
            [-80.896713346999945, 35.134379981000052],
            [-80.896720786999936, 35.134377820000054],
            [-80.896748135999985, 35.134369794000065],
            [-80.897016540999971, 35.134281961000056],
            [-80.897277377999956, 35.134179895000045],
            [-80.897529529999986, 35.134064033000072],
            [-80.897771916999943, 35.133934870000076],
            [-80.898003498999969, 35.133792960000051],
            [-80.89822328799994, 35.133638912000038],
            [-80.898430340999937, 35.133473384000069],
            [-80.898623770999961, 35.133297085000038],
            [-80.898802749999959, 35.13311077000003],
            [-80.898966511999959, 35.132915239000056],
            [-80.899114355999984, 35.132711326000049],
            [-80.899245647999976, 35.132499907000067],
            [-80.899359825999966, 35.132281886000044],
            [-80.899456402999988, 35.13205819600006],
            [-80.89953496399994, 35.13182979700008],
            [-80.899595171999977, 35.131597666000062],
            [-80.899636770999962, 35.131362797000065],
            [-80.89965958199997, 35.131126195000036],
            [-80.899663506999957, 35.130888875000039],
            [-80.899648530999968, 35.130651851000039],
            [-80.899614717999953, 35.130416140000079],
            [-80.899562210999989, 35.130182750000074],
            [-80.89949123699995, 35.129952682000066],
            [-80.899402098999985, 35.129726919000063],
            [-80.899295179999967, 35.12950642900006],
            [-80.899170936999951, 35.12929215500003],
            [-80.899029901999938, 35.129085016000033],
            [-80.898872679999954, 35.128885898000078],
            [-80.89869994299994, 35.128695655000058],
            [-80.898512431999961, 35.128515099000026],
            [-80.898310948999949, 35.128345006000075],
            [-80.898096357999975, 35.128186102000029],
            [-80.897869575999948, 35.128039069000067],
            [-80.897631575999981, 35.127904535000027],
            [-80.897383375999937, 35.127783077000061],
            [-80.897126037999953, 35.127675216000057],
            [-80.896860665999952, 35.12758141200004],
            [-80.896588394999981, 35.12750206700008],
            [-80.896310390999986, 35.127437521000047],
            [-80.896027843999946, 35.127388051000025],
            [-80.895741963999967, 35.127353867000068],
            [-80.895453974999953, 35.127335118000076],
            [-80.895165109999937, 35.127331883000068],
            [-80.894876605999968, 35.127344175000076],
            [-80.894589697999947, 35.127371942000025],
            [-80.894305614999951, 35.127415066000026],
            [-80.894025572999965, 35.127473362000046],
            [-80.893778116999954, 35.127538554000068],
            [-80.89345581699996, 35.127632144000074],
            [-80.893428469999947, 35.127640169000074],
            [-80.893375977999938, 35.12765604100008],
            [-80.893284673999972, 35.127684189000036],
            [-80.893281348999949, 35.127685297000028],
            [-80.893226210999956, 35.127673725000079],
            [-80.892943664999962, 35.127624247000028],
            [-80.892803483999955, 35.127605520000031],
            [-80.892456374999938, 35.127563845000054],
            [-80.892310675999965, 35.127548381000054],
            [-80.892022686999951, 35.127529624000033],
            [-80.891733821999935, 35.127526380000063],
            [-80.891445315999988, 35.127538664000042],
            [-80.891158406999978, 35.127566423000076],
            [-80.890874320999956, 35.127609539000048],
            [-80.890594274999955, 35.127667827000039],
            [-80.890319468999962, 35.127741036000032],
            [-80.890051078999988, 35.127828854000029],
            [-80.889790252999944, 35.127930905000028],
            [-80.889689459999943, 35.127977214000055],
            [-80.889658443999963, 35.127943048000077],
            [-80.889470954999979, 35.127762478000079],
            [-80.889269492999972, 35.12759236900007],
            [-80.889054920999968, 35.127433450000069],
            [-80.888828157999967, 35.12728640000006],
            [-80.88859017499999, 35.12715184800004],
            [-80.888341989999958, 35.127030372000036],
            [-80.888103566999973, 35.126929829000062],
            [-80.888014190999968, 35.126894913000058],
            [-80.887995290999982, 35.126887575000069],
            [-80.887729931999957, 35.126793751000037],
            [-80.887457672999972, 35.126714385000071],
            [-80.887452531999941, 35.126713043000052],
            [-80.887357792999978, 35.126688366000053],
            [-80.887084939999966, 35.12662514200008],
            [-80.886825629999976, 35.126579123000056],
            [-80.886711219999938, 35.126561757000047],
            [-80.886687990999974, 35.126558284000055],
            [-80.886430081999947, 35.126526734000038],
            [-80.886371089999955, 35.126520975000062],
            [-80.886343125999986, 35.12651831900007],
            [-80.886055141999975, 35.126499548000027],
            [-80.885766279999984, 35.126496290000034],
            [-80.885558211999978, 35.126503574000026],
            [-80.885502796999958, 35.126506591000066],
            [-80.885422361999986, 35.126511576000041],
            [-80.885319643999935, 35.126519715000029],
            [-80.885257984999953, 35.126525198000024],
            [-80.885073794999983, 35.126544804000048],
            [-80.885010049999948, 35.126553106000074],
            [-80.884948389999977, 35.126561515000049],
            [-80.884728049999978, 35.126596315000029],
            [-80.884459465999953, 35.126651886000047],
            [-80.884325415999967, 35.126683326000034],
            [-80.884313952999946, 35.126686028000051],
            [-80.884168725999984, 35.126724710000076],
            [-80.88412819399997, 35.126710894000041],
            [-80.883855937999954, 35.126631521000036],
            [-80.883577946999935, 35.126566945000036],
            [-80.883295410999949, 35.126517445000047],
            [-80.883009539999989, 35.126483232000055],
            [-80.882721556999968, 35.12646445200005],
            [-80.882432694999977, 35.126461187000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 96,
        "SolutionID": "PI-27:R",
        "Shape_Length": 0.052049649937312203,
        "Shape_Area": 0.00014305912090628679
      }
    },
    {
      "type": "Feature",
      "id": 97,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.887722703999941, 35.139848638000046],
            [-80.887434154999937, 35.139860912000074],
            [-80.887147199999959, 35.139888662000033],
            [-80.886863069999947, 35.139931768000054],
            [-80.886582978999968, 35.139990046000037],
            [-80.88630812699995, 35.140063247000057],
            [-80.88603969199994, 35.140151056000036],
            [-80.885778821999963, 35.140253097000027],
            [-80.885734730999957, 35.140272081000035],
            [-80.884451374999969, 35.140832223000075],
            [-80.88424327599995, 35.140929075000031],
            [-80.884000846999982, 35.141058211000029],
            [-80.883769218999987, 35.141200093000066],
            [-80.883549381999956, 35.141354116000059],
            [-80.883342278999976, 35.141519618000075],
            [-80.88314879699999, 35.14169589200003],
            [-80.882969762999949, 35.141882183000064],
            [-80.882805944999973, 35.142077693000033],
            [-80.882658042999935, 35.142281585000035],
            [-80.882526691999942, 35.142492986000036],
            [-80.882412453999962, 35.142710991000058],
            [-80.882315818999984, 35.142934667000077],
            [-80.882237198999974, 35.14316305400007],
            [-80.882176932999982, 35.143395177000059],
            [-80.882135276999975, 35.143630039000072],
            [-80.882112411999969, 35.143866637000031],
            [-80.882108434999964, 35.144103957000027],
            [-80.882123361999959, 35.144340982000074],
            [-80.882157130999985, 35.144576698000037],
            [-80.882209597999974, 35.144810094000036],
            [-80.882280535999939, 35.145040173000041],
            [-80.882369642999947, 35.145265948000031],
            [-80.882476536999945, 35.145486453000046],
            [-80.882600760999935, 35.145700743000077],
            [-80.882741781999982, 35.145907901000044],
            [-80.882898997999973, 35.146107039000071],
            [-80.883071734999987, 35.146297304000029],
            [-80.883259252999949, 35.146477883000045],
            [-80.883460748999937, 35.146648002000063],
            [-80.883675359999984, 35.146806931000071],
            [-80.883902168999953, 35.146953990000043],
            [-80.88414020099998, 35.147088551000024],
            [-80.884388440999942, 35.147210036000047],
            [-80.884645822999971, 35.147317924000049],
            [-80.884911245999945, 35.147411755000064],
            [-80.88518357199996, 35.147491126000034],
            [-80.885461635999945, 35.14755569700003],
            [-80.885744245999945, 35.147605192000071],
            [-80.886030191999964, 35.14763939900007],
            [-80.886318250999977, 35.147658170000057],
            [-80.886607187999971, 35.14766142700006],
            [-80.886895764999963, 35.147649154000078],
            [-80.88718274699994, 35.147621406000042],
            [-80.88746690399995, 35.147578298000042],
            [-80.887747019999949, 35.14752001800008],
            [-80.888021895999941, 35.14744681500008],
            [-80.888290352999945, 35.14735900200003],
            [-80.888551241999949, 35.147256954000056],
            [-80.88859533699997, 35.147237970000049],
            [-80.889878764999935, 35.146677785000065],
            [-80.890086871999983, 35.146580923000045],
            [-80.890329307999934, 35.14645177500006],
            [-80.890560938999954, 35.146309880000047],
            [-80.890780775999986, 35.146155845000067],
            [-80.89098787599994, 35.145990330000075],
            [-80.891181350999943, 35.145814044000076],
            [-80.891360374999977, 35.145627740000066],
            [-80.891524179999976, 35.145432219000043],
            [-80.891672065999956, 35.145228316000043],
            [-80.89180339699999, 35.145016905000034],
            [-80.891917612999976, 35.14479889100005],
            [-80.892014223999979, 35.144575208000049],
            [-80.892092817999981, 35.144346814000073],
            [-80.892153056999973, 35.144114687000069],
            [-80.892194683999946, 35.143879821000041],
            [-80.892217519999974, 35.143643221000048],
            [-80.892221466999956, 35.143405901000051],
            [-80.892206510999983, 35.143168877000051],
            [-80.892172712999979, 35.142933164000056],
            [-80.892120219999981, 35.142699772000071],
            [-80.89204925599995, 35.14246969900006],
            [-80.891960124999969, 35.142243931000053],
            [-80.891853208999976, 35.142023435000056],
            [-80.891728966999949, 35.141809154000043],
            [-80.891587929999957, 35.141602006000028],
            [-80.89143070099999, 35.141402879000054],
            [-80.891257954999958, 35.141212626000026],
            [-80.891070431999935, 35.141032059000054],
            [-80.890868933999968, 35.140861953000069],
            [-80.890654323999968, 35.140703037000037],
            [-80.890427520999935, 35.140555990000053],
            [-80.890189496999938, 35.140421442000047],
            [-80.889941268999962, 35.140299968000079],
            [-80.889683901999945, 35.140192091000074],
            [-80.889418497999941, 35.140098271000056],
            [-80.889146191999941, 35.140018910000038],
            [-80.88886815099994, 35.139954347000071],
            [-80.888585564999971, 35.139904859000069],
            [-80.888299644999961, 35.139870658000063],
            [-80.888011612999946, 35.139851891000035],
            [-80.887722703999941, 35.139848638000046]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 97,
        "SolutionID": "PI-27:T",
        "Shape_Length": 0.02812344380436536,
        "Shape_Area": 6.0820342425195197e-5
      }
    },
    {
      "type": "Feature",
      "id": 98,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.831258015999936, 35.227093529000058],
            [-80.830969148999941, 35.227105668000036],
            [-80.830681867999942, 35.227133283000057],
            [-80.830397402999949, 35.227176256000064],
            [-80.830116971999985, 35.227234403000068],
            [-80.829841774999977, 35.227307474000042],
            [-80.829572990999964, 35.227395156000057],
            [-80.829311770999936, 35.227497075000031],
            [-80.829059233999942, 35.227612793000048],
            [-80.828816458999938, 35.227741817000037],
            [-80.828584488999979, 35.227883592000069],
            [-80.828364313999941, 35.228037511000025],
            [-80.828333443999952, 35.228060784000036],
            [-80.828122538999935, 35.228221308000059],
            [-80.828090097999961, 35.228243121000048],
            [-80.82795942499996, 35.228326870000046],
            [-80.827835763999985, 35.228401505000079],
            [-80.82769988299998, 35.228486890000056],
            [-80.827593730999979, 35.228558553000028],
            [-80.827039720999949, 35.228944564000074],
            [-80.827022703999944, 35.228956481000068],
            [-80.826896038999962, 35.229045644000053],
            [-80.826873885999987, 35.229061344000058],
            [-80.826555771999949, 35.229288291000046],
            [-80.826547977999951, 35.229293864000056],
            [-80.825861700999951, 35.22978577300006],
            [-80.825477900999942, 35.23005437300003],
            [-80.82544497899994, 35.230077643000072],
            [-80.825174940999943, 35.230270420000068],
            [-80.825140828999963, 35.230295026000078],
            [-80.824833229999967, 35.230519214000026],
            [-80.824653059999946, 35.230649434000043],
            [-80.824621864999983, 35.230672196000057],
            [-80.824110872999938, 35.231048610000073],
            [-80.824088770999936, 35.231065002000037],
            [-80.823881318999952, 35.231230400000072],
            [-80.823687494999945, 35.23140657600004],
            [-80.823508127999958, 35.23159277700006],
            [-80.823343985999941, 35.231788203000065],
            [-80.823195772999952, 35.231992020000064],
            [-80.823064122999938, 35.232203354000035],
            [-80.822949600999948, 35.232421300000055],
            [-80.822852694999938, 35.232644925000045],
            [-80.822773821999988, 35.232873271000074],
            [-80.822713319999934, 35.233105360000025],
            [-80.822671446999948, 35.233340199000054],
            [-80.82264838399999, 35.233576783000046],
            [-80.822644228999934, 35.23381409700005],
            [-80.822658999999987, 35.234051127000043],
            [-80.822692633999964, 35.23428685600004],
            [-80.822744987999954, 35.234520275000079],
            [-80.822815835999961, 35.23475038600003],
            [-80.822904876999985, 35.234976202000041],
            [-80.823011727999983, 35.23519675600005],
            [-80.823135931999957, 35.235411104000036],
            [-80.823276956999962, 35.235618329000033],
            [-80.823434200999941, 35.235817542000063],
            [-80.823606988999984, 35.236007889000064],
            [-80.823794581999948, 35.236188558000038],
            [-80.823996174999934, 35.236358774000053],
            [-80.824210906999951, 35.236517806000052],
            [-80.824437857999953, 35.236664975000053],
            [-80.824676053999951, 35.236799651000069],
            [-80.824924478999947, 35.236921256000073],
            [-80.825182065999968, 35.23702927000005],
            [-80.825447711999971, 35.237123230000066],
            [-80.825720279999985, 35.23720273400005],
            [-80.82599860299996, 35.237267441000029],
            [-80.82628148799995, 35.237317074000032],
            [-80.826567724999961, 35.237351421000028],
            [-80.826856086999953, 35.237370335000037],
            [-80.827145338999969, 35.237373733000027],
            [-80.827434242999971, 35.237361603000068],
            [-80.827721561999965, 35.237333996000075],
            [-80.828006062999975, 35.237291029000062],
            [-80.828286530999947, 35.237232887000062],
            [-80.828561761999936, 35.237159820000045],
            [-80.828830578999941, 35.237072140000066],
            [-80.829091830999971, 35.236970223000071],
            [-80.829344396999943, 35.236854504000064],
            [-80.829587197999956, 35.236725480000075],
            [-80.829819191999945, 35.236583703000065],
            [-80.83001728499994, 35.236446174000037],
            [-80.830512750999958, 35.236081177000074],
            [-80.830684132999977, 35.235957302000031],
            [-80.830697849999979, 35.235947346000046],
            [-80.830995320999989, 35.235730530000069],
            [-80.831231839999987, 35.235561674000053],
            [-80.831618989999981, 35.235290715000076],
            [-80.831658535999964, 35.235262705000025],
            [-80.832360603999973, 35.234759452000048],
            [-80.832663777999983, 35.23454315400005],
            [-80.83277084599996, 35.234467781000035],
            [-80.833198661999973, 35.234169682000072],
            [-80.833249438999985, 35.234139034000066],
            [-80.833351271999959, 35.234075690000054],
            [-80.833573043999934, 35.233933550000074],
            [-80.83360709599998, 35.233911502000069],
            [-80.833653762999973, 35.233880555000042],
            [-80.833831113999963, 35.233761301000072],
            [-80.834004626999956, 35.233638319000079],
            [-80.834035496999945, 35.233615044000032],
            [-80.834345859999985, 35.233378809000044],
            [-80.834522426999968, 35.233236667000028],
            [-80.834716231999948, 35.23306047300008],
            [-80.834895575999951, 35.232874256000059],
            [-80.835059691999959, 35.232678814000053],
            [-80.835207876999959, 35.232474983000031],
            [-80.83533949599996, 35.232263635000038],
            [-80.835453984999958, 35.232045678000077],
            [-80.835550854999951, 35.231822043000079],
            [-80.835629690999951, 35.231593689000078],
            [-80.835690155999941, 35.231361593000031],
            [-80.835731989999942, 35.231126749000055],
            [-80.835755013999972, 35.230890163000026],
            [-80.835759130999975, 35.230652848000034],
            [-80.835744322999972, 35.230415820000076],
            [-80.835710651999989, 35.230180094000048],
            [-80.83565826399996, 35.229946680000069],
            [-80.83558738399995, 35.229716577000033],
            [-80.835498313999949, 35.22949077100003],
            [-80.835391436999942, 35.229270227000029],
            [-80.835267209999984, 35.22905589100003],
            [-80.835126164999963, 35.228848680000056],
            [-80.834968905999972, 35.228649482000037],
            [-80.834796106999988, 35.228459151000038],
            [-80.834608507999974, 35.228278498000066],
            [-80.834406911999963, 35.228108301000077],
            [-80.834192182999971, 35.227949286000069],
            [-80.833965237999962, 35.227802135000047],
            [-80.833727052999961, 35.227667477000068],
            [-80.833478643999968, 35.227545890000044],
            [-80.833221075999973, 35.227437894000047],
            [-80.832955451999965, 35.22734395100008],
            [-80.83268290999996, 35.22726446300004],
            [-80.832404615999963, 35.227199771000073],
            [-80.832121761999986, 35.227150152000036],
            [-80.831835558999956, 35.227115818000073],
            [-80.831547231999934, 35.22709691700004],
            [-80.831258015999936, 35.227093529000058]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 98,
        "SolutionID": "PI-27:P",
        "Shape_Length": 0.035818817196880848,
        "Shape_Area": 9.1310024246100656e-5
      }
    },
    {
      "type": "Feature",
      "id": 99,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.851287332999959, 35.223706668000034],
            [-80.850998480999976, 35.223718855000072],
            [-80.850711217999958, 35.223746517000052],
            [-80.850426775999949, 35.223789538000062],
            [-80.850146370999937, 35.223847730000045],
            [-80.849871203999953, 35.223920847000045],
            [-80.849602452999989, 35.224008574000038],
            [-80.849341268999979, 35.224110536000069],
            [-80.84908876999998, 35.22422629600004],
            [-80.848846036999987, 35.224355360000061],
            [-80.848614110999961, 35.224497173000032],
            [-80.848393982999937, 35.224651130000041],
            [-80.848186596999938, 35.22481657000003],
            [-80.84799284099995, 35.22499278500004],
            [-80.847813543999962, 35.225179022000077],
            [-80.84764947299999, 35.225374482000063],
            [-80.847501331999979, 35.225578329000029],
            [-80.847369755999978, 35.225789689000067],
            [-80.847255306999955, 35.226007658000071],
            [-80.847158475999947, 35.226231303000077],
            [-80.847079677999943, 35.22645966500005],
            [-80.84701924999996, 35.226691767000034],
            [-80.84697744999994, 35.226926614000035],
            [-80.84695445899996, 35.227163203000032],
            [-80.846950375999938, 35.227400518000024],
            [-80.846965215999944, 35.22763754500005],
            [-80.84699891799994, 35.227873268000053],
            [-80.847051336999982, 35.228106677000028],
            [-80.847122247999948, 35.228336773000024],
            [-80.847211348999963, 35.228562571000055],
            [-80.847318257999973, 35.228783105000048],
            [-80.847442515999944, 35.228997428000071],
            [-80.847583591999978, 35.229204624000033],
            [-80.847740882999972, 35.22940380600005],
            [-80.847913713999958, 35.229594119000069],
            [-80.848101345999964, 35.229774751000036],
            [-80.848302973999978, 35.22994492600003],
            [-80.848517736999952, 35.230103915000029],
            [-80.848744713999963, 35.230251039000052],
            [-80.848982931999956, 35.230385667000064],
            [-80.849231372999952, 35.230507223000075],
            [-80.849488970999971, 35.230615185000033],
            [-80.849754624999946, 35.230709091000051],
            [-80.849776382999949, 35.230716045000065],
            [-80.850099587999978, 35.230818532000058],
            [-80.850582156999963, 35.230976576000046],
            [-80.85137542199999, 35.231338362000031],
            [-80.851384565999979, 35.231342521000045],
            [-80.851604438999971, 35.231435743000077],
            [-80.852048908999961, 35.231611435000048],
            [-80.852086639999982, 35.231626169000037],
            [-80.852213634999941, 35.231673170000079],
            [-80.852979710999989, 35.231944846000033],
            [-80.853118375999941, 35.231991746000062],
            [-80.853390953999963, 35.232071187000031],
            [-80.853669281999942, 35.232135831000051],
            [-80.853952165999942, 35.232185400000049],
            [-80.854238395999971, 35.23221968200005],
            [-80.854526746999966, 35.232238529000028],
            [-80.854815981999934, 35.232241861000034],
            [-80.855104863999941, 35.232229665000034],
            [-80.855392154999947, 35.232201992000057],
            [-80.855676623999955, 35.232158960000049],
            [-80.855957053999987, 35.232100754000044],
            [-80.856232242999965, 35.232027624000068],
            [-80.856501012999956, 35.231939882000063],
            [-80.856762212999968, 35.231837905000077],
            [-80.857014724999942, 35.231722129000048],
            [-80.857257466999954, 35.231593049000026],
            [-80.857489397999984, 35.231451219000064],
            [-80.85770952699994, 35.231297246000054],
            [-80.857916909999972, 35.231131790000063],
            [-80.858110658999976, 35.230955559000051],
            [-80.858289945999957, 35.23076930700006],
            [-80.858454001999974, 35.230573833000051],
            [-80.858602123999958, 35.230369973000052],
            [-80.858733678999954, 35.230158600000038],
            [-80.858848102999957, 35.229940620000036],
            [-80.858944905999977, 35.229716967000059],
            [-80.859023673999957, 35.229488597000056],
            [-80.859084069999938, 35.229256489000079],
            [-80.859125835999976, 35.229021638000063],
            [-80.859148791999985, 35.228785047000031],
            [-80.859152839999979, 35.228547731000049],
            [-80.859137963999956, 35.228310706000059],
            [-80.859104226999989, 35.22807498700007],
            [-80.859051772999976, 35.227841583000043],
            [-80.858980828999961, 35.22761149300004],
            [-80.858891695999944, 35.227385704000028],
            [-80.858784758999946, 35.227165181000032],
            [-80.858660472999986, 35.226950869000063],
            [-80.858519372999979, 35.226743685000031],
            [-80.85836206099998, 35.226544517000036],
            [-80.858189210999967, 35.226354219000029],
            [-80.858001564999938, 35.226173603000063],
            [-80.857799925999984, 35.22600344500006],
            [-80.857585155999971, 35.225844471000073],
            [-80.857358174999945, 35.225697364000041],
            [-80.857119956999952, 35.225562753000077],
            [-80.85687151999997, 35.225441213000067],
            [-80.856613927999945, 35.225333267000053],
            [-80.856486939999968, 35.225286271000073],
            [-80.855804043999967, 35.22504409700008],
            [-80.855557422999937, 35.224946613000043],
            [-80.854630246999989, 35.224523763000036],
            [-80.854621103999989, 35.224519604000079],
            [-80.854363517999957, 35.224411653000061],
            [-80.854121365999958, 35.224325384000053],
            [-80.853354097999954, 35.224074103000078],
            [-80.853330611999979, 35.224066475000029],
            [-80.853308854999966, 35.224059521000072],
            [-80.852963002999957, 35.223949855000058],
            [-80.852712209999936, 35.223877366000067],
            [-80.852433910999935, 35.223812720000069],
            [-80.852151056999958, 35.223763148000046],
            [-80.851864856999953, 35.223728861000041],
            [-80.851576537999961, 35.223710008000069],
            [-80.851287332999959, 35.223706668000034]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 99,
        "SolutionID": "PI-27:N",
        "Shape_Length": 0.032544898318236003,
        "Shape_Area": 7.7272291217314357e-5
      }
    },
    {
      "type": "Feature",
      "id": 100,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.912318052999979, 35.165771149000079],
            [-80.912353446999987, 35.165742857000055],
            [-80.912371812999936, 35.165752712000028],
            [-80.912396552999951, 35.165765891000035],
            [-80.912541738999948, 35.165842672000053],
            [-80.912550535999969, 35.165847312000039],
            [-80.912658947999944, 35.165902550000055],
            [-80.912995149999972, 35.166068374000076],
            [-80.913135076999936, 35.166134562000025],
            [-80.913392556999952, 35.166242390000036],
            [-80.913658073999954, 35.166336157000046],
            [-80.913930491999963, 35.166415462000032],
            [-80.914208642999938, 35.166479968000033],
            [-80.914491335999969, 35.166529395000055],
            [-80.914777360999949, 35.166563534000034],
            [-80.915065492999986, 35.166582237000057],
            [-80.915354497999942, 35.166585425000051],
            [-80.91564313799995, 35.166573084000049],
            [-80.91593017699995, 35.16654526700006],
            [-80.916214384999989, 35.16650209200003],
            [-80.916494544999978, 35.166443746000027],
            [-80.916769458999966, 35.166370477000044],
            [-80.917037946999983, 35.166282600000045],
            [-80.917298860999949, 35.166180491000034],
            [-80.917551083999967, 35.166064587000051],
            [-80.917793533999941, 35.165935385000068],
            [-80.91802517299999, 35.165793437000048],
            [-80.918245009999964, 35.165639353000074],
            [-80.918452103999982, 35.165473792000057],
            [-80.918645565999952, 35.16529746100008],
            [-80.91882456899998, 35.165111118000027],
            [-80.918988347999971, 35.164915560000054],
            [-80.919136199999969, 35.164711624000063],
            [-80.91926749199996, 35.164500184000076],
            [-80.919381660999989, 35.164282144000026],
            [-80.919478219999974, 35.164058440000076],
            [-80.919556755999963, 35.163830029000053],
            [-80.91961693099995, 35.163597889000073],
            [-80.919658488999971, 35.163363014000026],
            [-80.91968124999994, 35.163126410000075],
            [-80.919685118999951, 35.162889089000032],
            [-80.919670078999957, 35.162652069000046],
            [-80.919636193999963, 35.16241636400008],
            [-80.919583608999972, 35.162182985000072],
            [-80.919512550999968, 35.161952929000051],
            [-80.91942332299999, 35.16172718100006],
            [-80.919316306999974, 35.161506710000026],
            [-80.919191962999946, 35.161292458000048],
            [-80.919050821999974, 35.161085343000025],
            [-80.91889348899997, 35.160886252000068],
            [-80.918720637999968, 35.160696039000072],
            [-80.918533008999987, 35.160515515000043],
            [-80.918331404999947, 35.160345455000027],
            [-80.918116690999966, 35.160186588000045],
            [-80.917889782999964, 35.160039593000079],
            [-80.917651657999954, 35.159905099000071],
            [-80.917543249999937, 35.159849866000059],
            [-80.917266236999978, 35.159713239000041],
            [-80.917191484999989, 35.159673708000071],
            [-80.916550549999954, 35.159329770000056],
            [-80.916517014999954, 35.159311951000063],
            [-80.916479116999938, 35.159292236000056],
            [-80.916274878999957, 35.159187176000046],
            [-80.915438758999983, 35.158543064000071],
            [-80.915426183999955, 35.158531328000038],
            [-80.915404640999952, 35.158512063000046],
            [-80.915384515999961, 35.15849419500006],
            [-80.914816440999971, 35.157952952000073],
            [-80.914816400999939, 35.157952915000067],
            [-80.912685406999969, 35.155922434000047],
            [-80.91137934599999, 35.153773265000041],
            [-80.911350367999944, 35.153726429000073],
            [-80.91134950899999, 35.153725067000039],
            [-80.91076781999999, 35.152802516000065],
            [-80.910627573999989, 35.152596753000068],
            [-80.910614288999966, 35.15257878500006],
            [-80.909798795999961, 35.151483010000049],
            [-80.909654788999944, 35.151301874000069],
            [-80.909481978999963, 35.15111164700005],
            [-80.90933682299999, 35.15096989400007],
            [-80.908999206999965, 35.150656784000034],
            [-80.908956776999958, 35.15061799800003],
            [-80.908844847999944, 35.150520813000071],
            [-80.908595847999948, 35.150312448000079],
            [-80.908506218999946, 35.150239557000077],
            [-80.908291549999944, 35.150080672000058],
            [-80.90812129599999, 35.149968513000033],
            [-80.907698663999952, 35.149703917000068],
            [-80.907642056999975, 35.149669062000044],
            [-80.907403977999934, 35.149534548000076],
            [-80.907263320999959, 35.149463536000042],
            [-80.906885691999946, 35.149280787000066],
            [-80.906778067999937, 35.149230360000047],
            [-80.906520650999937, 35.149122519000059],
            [-80.90625519799994, 35.149028736000048],
            [-80.906237177999969, 35.149022973000058],
            [-80.905918897999982, 35.148921843000039],
            [-80.905664565999984, 35.148848282000074],
            [-80.905386481999983, 35.148783757000047],
            [-80.905167850999987, 35.148744119000071],
            [-80.904764272999955, 35.148679716000061],
            [-80.90470027799995, 35.148669905000077],
            [-80.904414319999944, 35.148635742000067],
            [-80.904176197999959, 35.148619146000044],
            [-80.903802431999964, 35.148601445000054],
            [-80.903752487999952, 35.148599312000044],
            [-80.903463546999944, 35.148596097000052],
            [-80.903188285999988, 35.148607498000047],
            [-80.902974128999972, 35.148621882000043],
            [-80.902731271999983, 35.148636804000034],
            [-80.902687467999954, 35.148639675000027],
            [-80.902400487999955, 35.148667461000059],
            [-80.902288521999935, 35.148682577000045],
            [-80.90201661399999, 35.148722238000062],
            [-80.901844426999958, 35.148750264000057],
            [-80.901564315999963, 35.148808576000079],
            [-80.90142528299998, 35.148843578000026],
            [-80.90064375299994, 35.149051947000032],
            [-80.900407978999965, 35.149017059000073],
            [-80.89954583399998, 35.148873863000063],
            [-80.899466612999959, 35.148861322000073],
            [-80.898544355999945, 35.148722489000079],
            [-80.89852676299995, 35.148719871000026],
            [-80.898240806999979, 35.148685694000051],
            [-80.897952741999973, 35.148666951000052],
            [-80.897663801999954, 35.148663721000048],
            [-80.897375221999937, 35.14867601900005],
            [-80.89708823999996, 35.148703792000049],
            [-80.896804083999939, 35.148746922000043],
            [-80.896523969999976, 35.148805223000068],
            [-80.896249097999942, 35.148878446000026],
            [-80.895980643999962, 35.148966277000056],
            [-80.895719758999974, 35.149068340000042],
            [-80.89546755799995, 35.149184198000057],
            [-80.895225121999943, 35.14931335700004],
            [-80.89499348999999, 35.149455261000071],
            [-80.894773652999959, 35.149609303000034],
            [-80.894566550999969, 35.149774824000076],
            [-80.894373073999986, 35.149951116000068],
            [-80.894194047999974, 35.150137424000036],
            [-80.89403023899996, 35.150332948000027],
            [-80.893882350999945, 35.150536854000052],
            [-80.893751015999953, 35.150748268000029],
            [-80.893636796999942, 35.150966283000059],
            [-80.893540181999981, 35.15118996700005],
            [-80.893461585999944, 35.151418362000072],
            [-80.893401344999972, 35.151650489000076],
            [-80.893359717999942, 35.151885355000047],
            [-80.893336882999961, 35.152121955000041],
            [-80.893332937999958, 35.152359275000038],
            [-80.893347898999934, 35.152596299000038],
            [-80.893381703999978, 35.152832011000044],
            [-80.893434207999974, 35.153065402000038],
            [-80.893505184999981, 35.15329547400006],
            [-80.893594331999964, 35.153521241000078],
            [-80.893701267999973, 35.15374173500004],
            [-80.893825533999973, 35.153956013000027],
            [-80.893966597999963, 35.154163158000074],
            [-80.894123856999954, 35.154362282000079],
            [-80.894296637999958, 35.154552531000036],
            [-80.894484198999976, 35.154733092000072],
            [-80.894685738999954, 35.154903191000074],
            [-80.89490039399999, 35.155062100000066],
            [-80.895127245999959, 35.155209139000078],
            [-80.895365320999986, 35.155343677000076],
            [-80.895613602999958, 35.155465139000057],
            [-80.895871025999952, 35.155573003000029],
            [-80.896136487999968, 35.155666809000024],
            [-80.896408852999969, 35.155746154000042],
            [-80.896686952999971, 35.155810700000075],
            [-80.896952003999957, 35.155857550000064],
            [-80.89783465499994, 35.15599042100007],
            [-80.898703368999975, 35.156134708000025],
            [-80.898795638999957, 35.156149196000058],
            [-80.899372066999945, 35.156234493000056],
            [-80.899376619999941, 35.156235164000066],
            [-80.899662601999978, 35.156269339000062],
            [-80.899799898999959, 35.156280213000059],
            [-80.900087563999989, 35.156299279000052],
            [-80.90020091599996, 35.156305587000077],
            [-80.900589072999935, 35.156323073000067],
            [-80.900626516999978, 35.156324629000039],
            [-80.900915484999985, 35.156327851000071],
            [-80.901245667999945, 35.156322657000032],
            [-80.901534273999971, 35.156310350000069],
            [-80.901821280999968, 35.156282566000073],
            [-80.901916081999957, 35.156269927000039],
            [-80.902288231999989, 35.156216893000078],
            [-80.902477610999938, 35.156186391000062],
            [-80.902757745999963, 35.156128076000073],
            [-80.902879953999957, 35.15609551700004],
            [-80.902996429999973, 35.156252042000062],
            [-80.903490983999973, 35.157036518000041],
            [-80.905020989999969, 35.159554584000034],
            [-80.905049967999958, 35.159601421000048],
            [-80.905191069999944, 35.159808552000072],
            [-80.905348367999977, 35.160007661000066],
            [-80.905498927999986, 35.160174805000054],
            [-80.905540301999963, 35.160218084000064],
            [-80.905562559999964, 35.160241173000031],
            [-80.90562502299997, 35.160303979000048],
            [-80.905642247999936, 35.160320907000028],
            [-80.905723670999976, 35.160313645000031],
            [-80.912320675999979, 35.165706808000039],
            [-80.912318052999979, 35.165771149000079]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 100,
        "SolutionID": "PI-27:U",
        "Shape_Length": 0.069576278453083382,
        "Shape_Area": 0.00022199878906589259
      }
    },
    {
      "type": "Feature",
      "id": 101,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.845727490999934, 35.21302349900003],
            [-80.845438675999958, 35.213035673000036],
            [-80.845151449999946, 35.213063323000029],
            [-80.844867041999976, 35.213106330000073],
            [-80.844586668999966, 35.21316451000007],
            [-80.844311532999939, 35.213237614000036],
            [-80.844042811999941, 35.213325329000043],
            [-80.843781654999987, 35.21342727900003],
            [-80.843529180999951, 35.213543028000061],
            [-80.843522625999981, 35.213546274000066],
            [-80.840457158999982, 35.215067615000066],
            [-80.840220994999981, 35.215193415000044],
            [-80.839989079999953, 35.21533521200007],
            [-80.839768960999947, 35.215489153000078],
            [-80.839561579999952, 35.215654578000056],
            [-80.83936782699999, 35.215830781000079],
            [-80.839188529999944, 35.216017005000026],
            [-80.839024457999983, 35.216212453000026],
            [-80.838876311999968, 35.216416290000041],
            [-80.838744727999938, 35.216627641000059],
            [-80.838630268999964, 35.216845603000024],
            [-80.83853342499998, 35.217069240000058],
            [-80.838454610999975, 35.217297597000027],
            [-80.838394164999954, 35.217529695000053],
            [-80.838352345999965, 35.217764540000076],
            [-80.83832933299999, 35.218001127000036],
            [-80.838325223999959, 35.218238443000075],
            [-80.838340037999956, 35.218475471000033],
            [-80.838373710999974, 35.218711196000072],
            [-80.838426098999946, 35.218944609000062],
            [-80.838496978999956, 35.219174711000051],
            [-80.838586045999989, 35.219400516000064],
            [-80.838692917999936, 35.21962105700004],
            [-80.838817139999946, 35.219835390000071],
            [-80.838958177999984, 35.220042596000042],
            [-80.839115430999982, 35.220241790000046],
            [-80.839288221999936, 35.22043211600004],
            [-80.839475813999968, 35.220612761000041],
            [-80.83967740099996, 35.220782950000057],
            [-80.83989212299997, 35.220941955000058],
            [-80.84011905899996, 35.221089095000025],
            [-80.840357235999988, 35.221223740000028],
            [-80.840605635999964, 35.221345314000075],
            [-80.840863193999951, 35.22145329500006],
            [-80.841128807999951, 35.221547220000048],
            [-80.841401338999958, 35.221626689000061],
            [-80.841679620999969, 35.221691360000079],
            [-80.841962461999969, 35.221740957000065],
            [-80.842248649999988, 35.221775267000055],
            [-80.842536960999951, 35.221794143000068],
            [-80.842826158999969, 35.221797504000051],
            [-80.843115004999959, 35.221785336000039],
            [-80.843402262999973, 35.22175769200004],
            [-80.843686701999957, 35.221714688000077],
            [-80.84396710499999, 35.221656510000059],
            [-80.844242269999938, 35.221583407000026],
            [-80.844511017999935, 35.221495692000076],
            [-80.844772199999966, 35.221393740000053],
            [-80.845024695999939, 35.221277989000043],
            [-80.845031250999966, 35.221274742000048],
            [-80.848096866999981, 35.21975328700006],
            [-80.848333031999971, 35.21962747200007],
            [-80.848564944999964, 35.219485659000043],
            [-80.848785057999976, 35.219331702000034],
            [-80.848992428999964, 35.219166261000055],
            [-80.849186169999939, 35.218990043000076],
            [-80.849365449999937, 35.218803804000061],
            [-80.849529502999985, 35.218608342000039],
            [-80.849677625999959, 35.218404492000047],
            [-80.84980918499997, 35.218193129000042],
            [-80.849923614999966, 35.217975157000069],
            [-80.850020428999983, 35.217751511000074],
            [-80.850099210999986, 35.217523146000076],
            [-80.850159623999957, 35.217291043000046],
            [-80.850201408999965, 35.217056193000076],
            [-80.850224386999969, 35.216819604000079],
            [-80.850228460999972, 35.216582288000041],
            [-80.850213612999937, 35.216345261000072],
            [-80.850179906999983, 35.216109539000058],
            [-80.850127485999963, 35.215876131000073],
            [-80.850056576999975, 35.215646036000066],
            [-80.849967482999944, 35.215420239000025],
            [-80.849860584999988, 35.215199708000057],
            [-80.849736340999982, 35.214985386000023],
            [-80.849595283999975, 35.21477819200004],
            [-80.849438016999954, 35.214579012000058],
            [-80.849265213999956, 35.214388701000075],
            [-80.849077614999942, 35.21420807100003],
            [-80.848876023999935, 35.214037897000026],
            [-80.848661302999972, 35.213878908000027],
            [-80.848434371999986, 35.213731783000071],
            [-80.848196203999976, 35.21359715400007],
            [-80.847947815999987, 35.21347559600008],
            [-80.847690272999955, 35.21336763000005],
            [-80.847424678999971, 35.213273719000028],
            [-80.847152169999958, 35.213194264000037],
            [-80.846873911999978, 35.213129605000063],
            [-80.846591097999976, 35.213080020000064],
            [-80.846304938999936, 35.213045719000036],
            [-80.84601665799994, 35.213026853000031],
            [-80.845727490999934, 35.21302349900003]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 101,
        "SolutionID": "PI-27:O",
        "Shape_Length": 0.032180417608294314,
        "Shape_Area": 7.6309784764357086e-5
      }
    },
    {
      "type": "Feature",
      "id": 102,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.841433886999937, 35.232486407000067],
            [-80.841145001999962, 35.232498570000075],
            [-80.840857705999952, 35.232526209000071],
            [-80.840573226999936, 35.23256920700004],
            [-80.840292784999974, 35.232627376000039],
            [-80.840017578999948, 35.232700471000044],
            [-80.839748788999941, 35.232788175000053],
            [-80.839487563999967, 35.232890116000078],
            [-80.839235023999947, 35.233005855000044],
            [-80.838992248999944, 35.233134899000049],
            [-80.838760281999953, 35.23327669400004],
            [-80.838540110999986, 35.233430632000079],
            [-80.838332682999976, 35.233596055000078],
            [-80.838138883999989, 35.23377225400003],
            [-80.83795954499999, 35.233958476000055],
            [-80.83779543299994, 35.234153922000075],
            [-80.837647251999954, 35.234357757000055],
            [-80.837515635999978, 35.234569106000038],
            [-80.837401147999969, 35.234787066000024],
            [-80.837304279999955, 35.235010702000068],
            [-80.837225444999945, 35.235239057000058],
            [-80.83716498299998, 35.235471154000038],
            [-80.837123149999968, 35.235705998000071],
            [-80.837100127999975, 35.235942584000043],
            [-80.837096015999975, 35.236179899000035],
            [-80.837110828999982, 35.23641692700005],
            [-80.837144504999969, 35.236652652000032],
            [-80.83719690199996, 35.236886065000078],
            [-80.837267792999967, 35.237116167000067],
            [-80.837356876999934, 35.237341972000024],
            [-80.837463769999943, 35.237562513000057],
            [-80.837588014999938, 35.237776847000077],
            [-80.837729081999953, 35.237984054000037],
            [-80.837886365999964, 35.238183249000031],
            [-80.838059192999935, 35.238373575000026],
            [-80.838179226999955, 35.238491873000044],
            [-80.839919025999961, 35.240134265000052],
            [-80.839986624999938, 35.240196612000034],
            [-80.84018826199997, 35.240366800000061],
            [-80.840403035999941, 35.240525804000072],
            [-80.840630026999975, 35.24067294200006],
            [-80.840868261999958, 35.240807586000074],
            [-80.841116722999971, 35.240929158000029],
            [-80.841374343999973, 35.241037138000024],
            [-80.841640021999979, 35.241131062000079],
            [-80.84191261899997, 35.241210529000057],
            [-80.842190968999944, 35.241275199000029],
            [-80.842473877999964, 35.241324794000036],
            [-80.842760134999935, 35.241359103000036],
            [-80.843048514999964, 35.24137797800006],
            [-80.843337782999981, 35.241381338000053],
            [-80.843626698999969, 35.241369169000052],
            [-80.843914025999936, 35.241341523000074],
            [-80.84419853299994, 35.241298518000065],
            [-80.844479001999957, 35.241240339000058],
            [-80.844754232999946, 35.241167235000034],
            [-80.845023045999937, 35.241079519000039],
            [-80.845284288999949, 35.240977567000073],
            [-80.845536845999959, 35.240861815000073],
            [-80.84577963299995, 35.240732758000036],
            [-80.846011609999948, 35.240590950000069],
            [-80.846231784999986, 35.240436999000053],
            [-80.846439214999975, 35.240271563000078],
            [-80.846633010999938, 35.240095350000047],
            [-80.846812343999943, 35.239909115000046],
            [-80.846976445999985, 35.239713657000038],
            [-80.847124613999938, 35.239509811000062],
            [-80.84725621299998, 35.239298452000071],
            [-80.847370679999983, 35.239080483000066],
            [-80.847467525999946, 35.23885683900005],
            [-80.847546334999947, 35.238628477000077],
            [-80.84760677099996, 35.238396375000036],
            [-80.847648574999937, 35.238161528000035],
            [-80.847671565999974, 35.237924940000028],
            [-80.847675647999949, 35.237687624000046],
            [-80.847660803999986, 35.237450598000066],
            [-80.847627096999986, 35.237214876000053],
            [-80.847574669999972, 35.236981468000067],
            [-80.847503749999987, 35.236751372000072],
            [-80.847414638999965, 35.236525574000041],
            [-80.847307719999947, 35.236305041000037],
            [-80.847183450999978, 35.236090718000071],
            [-80.847042363999947, 35.235883521000062],
            [-80.846885061999956, 35.235684339000045],
            [-80.846712219999972, 35.235494024000047],
            [-80.84659217799998, 35.235375735000048],
            [-80.844852344999936, 35.233733438000058],
            [-80.844784748999984, 35.233671093000055],
            [-80.844583118999935, 35.233500913000057],
            [-80.844368354999972, 35.233341917000075],
            [-80.844141376999971, 35.23319478500008],
            [-80.843903158999979, 35.23306014700006],
            [-80.843654717999982, 35.232938581000042],
            [-80.843397119999963, 35.23283060600005],
            [-80.843131466999978, 35.232736686000067],
            [-80.842858896999985, 35.232657221000068],
            [-80.842580575999989, 35.232592552000028],
            [-80.842297696999935, 35.232542957000078],
            [-80.842011470999978, 35.232508647000031],
            [-80.841723122999952, 35.232489770000029],
            [-80.841433886999937, 35.232486407000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 102,
        "SolutionID": "PI-27:Q",
        "Shape_Length": 0.030124392522141382,
        "Shape_Area": 6.9573457447144108e-5
      }
    },
    {
      "type": "Feature",
      "id": 103,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.846694341999978, 35.216041688000075],
            [-80.845539038999959, 35.216090389000044],
            [-80.844390087999955, 35.216200985000057],
            [-80.843252407999955, 35.216373001000079],
            [-80.842130865999934, 35.216605702000038],
            [-80.841030263999983, 35.216898091000076],
            [-80.839955310999983, 35.217248917000063],
            [-80.838910608999981, 35.217656679000072],
            [-80.837900630999968, 35.218119631000036],
            [-80.836929698999938, 35.218635793000033],
            [-80.836001970999973, 35.219202953000035],
            [-80.835549045999983, 35.21950946100003],
            [-80.835449050999955, 35.219579452000062],
            [-80.835021419999975, 35.219888676000039],
            [-80.834191810999982, 35.22055034400006],
            [-80.83341669899994, 35.221255115000076],
            [-80.832699403999982, 35.221999974000028],
            [-80.832042998999952, 35.222781732000044],
            [-80.831450293999978, 35.223597041000062],
            [-80.830923829999961, 35.224442411000041],
            [-80.830465861999983, 35.225314225000034],
            [-80.830078353999966, 35.226208748000033],
            [-80.829762967999955, 35.227122152000049],
            [-80.829521052999951, 35.228050524000025],
            [-80.829353650999963, 35.228989891000026],
            [-80.829261479999957, 35.229936232000057],
            [-80.829244937999988, 35.230885491000038],
            [-80.829304096999977, 35.231833605000077],
            [-80.829438707999941, 35.232776515000069],
            [-80.829648194999947, 35.233710182000038],
            [-80.829931666999983, 35.234630607000042],
            [-80.830287908999935, 35.235533849000035],
            [-80.830715398999985, 35.236416040000051],
            [-80.831212307999976, 35.237273400000049],
            [-80.831776510999987, 35.238102258000026],
            [-80.832405591999986, 35.238899064000066],
            [-80.833096858999966, 35.239660404000062],
            [-80.833847351999964, 35.240383017000056],
            [-80.834355868999978, 35.240822563000052],
            [-80.834511358999976, 35.240951460000076],
            [-80.83456418999998, 35.240996911000025],
            [-80.834718147999979, 35.241129503000025],
            [-80.834803385999976, 35.24120552200003],
            [-80.835143379999977, 35.241505558000028],
            [-80.83558185399994, 35.241878891000056],
            [-80.835861854999962, 35.242108920000078],
            [-80.836103858999934, 35.242303953000032],
            [-80.836259992999942, 35.242425895000054],
            [-80.836492976999978, 35.242605646000072],
            [-80.836577974999955, 35.242673654000043],
            [-80.83665386399997, 35.24273400800007],
            [-80.836837564999939, 35.242877169000053],
            [-80.837527575999957, 35.243407237000042],
            [-80.83768189999995, 35.243524384000068],
            [-80.837895179999975, 35.243684361000078],
            [-80.838190066999971, 35.243912925000075],
            [-80.838401774999966, 35.244074350000062],
            [-80.838721781999936, 35.244314382000027],
            [-80.839152433999971, 35.244627009000055],
            [-80.839240787999984, 35.244689075000053],
            [-80.83927633199994, 35.244715471000063],
            [-80.839242541999965, 35.245005582000033],
            [-80.839223833999938, 35.245201570000063],
            [-80.839213599999937, 35.245278207000069],
            [-80.839166317999968, 35.245691243000067],
            [-80.839149176999967, 35.245806165000033],
            [-80.839129801999945, 35.245941319000053],
            [-80.839076070999965, 35.246396078000032],
            [-80.839019303999976, 35.246970591000036],
            [-80.838945622999972, 35.247575330000075],
            [-80.838922424999964, 35.247780443000067],
            [-80.838858488999961, 35.248392964000061],
            [-80.838839252999946, 35.248511278000024],
            [-80.838794065999934, 35.248882522000031],
            [-80.838678319999985, 35.249975423000024],
            [-80.838655939999967, 35.250148587000069],
            [-80.838649935999968, 35.25019577300003],
            [-80.83862990099999, 35.250355770000056],
            [-80.838609172999952, 35.250531865000028],
            [-80.838498958999935, 35.251531850000049],
            [-80.838484280999978, 35.251672668000026],
            [-80.838400289999981, 35.25252748500003],
            [-80.838286201999949, 35.253430495000032],
            [-80.838131570999963, 35.254588349000073],
            [-80.838121069999943, 35.254669067000066],
            [-80.838020104999941, 35.255466242000068],
            [-80.83798014599995, 35.25575350500003],
            [-80.837977704999957, 35.255771157000027],
            [-80.837935658999982, 35.256113010000036],
            [-80.83792355099996, 35.256224689000078],
            [-80.837908833999961, 35.256336158000067],
            [-80.837879300999987, 35.25657889200005],
            [-80.837826055999983, 35.257057158000066],
            [-80.83764651599995, 35.258365730000037],
            [-80.837619586999949, 35.258575739000037],
            [-80.837579476999963, 35.258912055000053],
            [-80.837539194999977, 35.259213687000056],
            [-80.837515897999936, 35.259350379000068],
            [-80.837363717999949, 35.260146620000057],
            [-80.83735335199998, 35.260180956000056],
            [-80.837195897999948, 35.260751556000059],
            [-80.837165873999936, 35.260871552000026],
            [-80.837083728999971, 35.261221633000048],
            [-80.836916345999953, 35.262161006000042],
            [-80.836911097999973, 35.262202057000025],
            [-80.836775407999937, 35.262706714000046],
            [-80.836735372999954, 35.262876708000078],
            [-80.836693347999983, 35.263061578000077],
            [-80.836608244999979, 35.263485931000048],
            [-80.836588221999989, 35.263595929000076],
            [-80.836505932999955, 35.264110948000052],
            [-80.836469106999971, 35.264405464000049],
            [-80.836464992999936, 35.264442038000027],
            [-80.83630567199998, 35.265340919000039],
            [-80.83621353999996, 35.26628725900008],
            [-80.836197071999948, 35.267236513000057],
            [-80.836210364999943, 35.26759121200007],
            [-80.836220322999964, 35.267781214000024],
            [-80.836249793999968, 35.268201356000077],
            [-80.836258922999946, 35.268305207000026],
            [-80.83626514499997, 35.268409202000043],
            [-80.836302813999964, 35.26888135300004],
            [-80.836312791999944, 35.268981354000061],
            [-80.836316158999978, 35.269014627000047],
            [-80.836349224999935, 35.269302529000072],
            [-80.83635798499995, 35.269371439000054],
            [-80.836362831999963, 35.269440609000071],
            [-80.836368587999971, 35.269519025000079],
            [-80.836383307999938, 35.269710930000031],
            [-80.836384676999955, 35.269742566000048],
            [-80.836396412999989, 35.269965047000028],
            [-80.836396363999938, 35.270496543000036],
            [-80.83642258399999, 35.271062073000053],
            [-80.836469370999964, 35.271728493000069],
            [-80.836536389999935, 35.272794901000054],
            [-80.836566404999985, 35.273175228000071],
            [-80.836580048999963, 35.273318819000053],
            [-80.836590955999952, 35.27351589500006],
            [-80.836606815999971, 35.273757863000071],
            [-80.83661648399999, 35.273885451000069],
            [-80.836621400999945, 35.273967173000074],
            [-80.836628949999977, 35.274111205000054],
            [-80.836637168999971, 35.274252055000034],
            [-80.836657100999957, 35.274562058000072],
            [-80.836670303999938, 35.274745336000024],
            [-80.836690249999947, 35.274995339000043],
            [-80.836711443999945, 35.275231413000029],
            [-80.836721422999972, 35.27533141400005],
            [-80.836724790999938, 35.275364687000035],
            [-80.836859559999937, 35.276307582000072],
            [-80.836873958999945, 35.276384623000069],
            [-80.836913914999968, 35.276594628000055],
            [-80.837109201999965, 35.277451235000058],
            [-80.837225142999955, 35.277858619000028],
            [-80.837273894999953, 35.278019412000049],
            [-80.837322531999973, 35.278180229000043],
            [-80.837492845999975, 35.278701649000027],
            [-80.837739649999946, 35.279347561000066],
            [-80.837859597999966, 35.279637578000063],
            [-80.837948808999954, 35.279847955000037],
            [-80.838048767999965, 35.280077968000057],
            [-80.838069271999984, 35.28012489300005],
            [-80.838295850999941, 35.280604684000025],
            [-80.838338621999981, 35.280857795000031],
            [-80.838548334999984, 35.281791438000027],
            [-80.838662900999964, 35.282194239000034],
            [-80.838862769999935, 35.282854267000062],
            [-80.838864032999936, 35.282858432000069],
            [-80.83891278599998, 35.283019226000079],
            [-80.838961422999944, 35.283180043000073],
            [-80.839013589999979, 35.283348223000075],
            [-80.839283319999936, 35.284196507000047],
            [-80.839531630999943, 35.285001933000046],
            [-80.839692303999982, 35.28543409100007],
            [-80.83980225199997, 35.285714106000057],
            [-80.839850810999963, 35.285835927000051],
            [-80.839898667999989, 35.285954213000025],
            [-80.83994450299997, 35.286073036000062],
            [-80.840168071999983, 35.286615177000044],
            [-80.840243207999947, 35.286781524000048],
            [-80.840423125999962, 35.287237176000076],
            [-80.839549001999956, 35.289849161000063],
            [-80.839439259999949, 35.290193576000036],
            [-80.839431087999969, 35.29022068200004],
            [-80.839371048999965, 35.290420673000028],
            [-80.839219183999944, 35.290972707000037],
            [-80.839179151999986, 35.291132701000038],
            [-80.839097193999976, 35.291481943000065],
            [-80.838995774999944, 35.291997815000059],
            [-80.838835580999955, 35.292917793000072],
            [-80.838779884999951, 35.293268075000071],
            [-80.838539528999945, 35.294938040000034],
            [-80.838529221999977, 35.295011257000056],
            [-80.838518659999977, 35.295096104000038],
            [-80.838460958999974, 35.29543140100003],
            [-80.838401388999955, 35.295814313000051],
            [-80.838361076999945, 35.296104104000051],
            [-80.838241075999974, 35.296897984000054],
            [-80.838107001999958, 35.297622718000071],
            [-80.838018548999969, 35.298171185000058],
            [-80.837959187999957, 35.298683893000032],
            [-80.837929118999966, 35.299003888000072],
            [-80.837896330999968, 35.299437516000069],
            [-80.837876842999947, 35.300115586000061],
            [-80.837876811999934, 35.300255587000038],
            [-80.837879843999985, 35.300526765000029],
            [-80.837888967999959, 35.300795831000073],
            [-80.837898919999986, 35.301015831000029],
            [-80.837949106999986, 35.301694865000059],
            [-80.83798461899994, 35.302001723000046],
            [-80.838024553999958, 35.302311730000042],
            [-80.838123868999958, 35.302947761000041],
            [-80.838142108999989, 35.303044852000028],
            [-80.83819205399999, 35.303304859000036],
            [-80.838383582999938, 35.304141408000078],
            [-80.838424514999986, 35.304291342000056],
            [-80.83849446499994, 35.304541352000058],
            [-80.838737350999963, 35.305311811000024],
            [-80.838826970999946, 35.305558722000058],
            [-80.838886940999942, 35.305718730000024],
            [-80.838910226999985, 35.305780371000026],
            [-80.838990186999979, 35.305990382000061],
            [-80.839233930999967, 35.306585035000069],
            [-80.839243449999969, 35.30660664100003],
            [-80.839433373999952, 35.307036667000034],
            [-80.839697466999951, 35.307597972000053],
            [-80.839787436999984, 35.30777798400004],
            [-80.839941790999944, 35.30807722000003],
            [-80.840282102999936, 35.308678960000066],
            [-80.840372080999941, 35.308828972000072],
            [-80.840529212999968, 35.309084544000029],
            [-80.840804248999973, 35.309504072000038],
            [-80.840954219999958, 35.309724091000078],
            [-80.841191624999965, 35.310061651000069],
            [-80.841561562999971, 35.310571697000057],
            [-80.841613920999976, 35.310643413000037],
            [-80.841628520999961, 35.310663243000079],
            [-80.842078447999938, 35.31127329800006],
            [-80.842423073999953, 35.311721849000037],
            [-80.842583052999942, 35.31192186800007],
            [-80.842768775999957, 35.312149364000049],
            [-80.84290632799997, 35.312314462000074],
            [-80.843297577999977, 35.31280754900007],
            [-80.843603486999939, 35.313180318000036],
            [-80.844150654999964, 35.313789867000025],
            [-80.844226701999958, 35.313891313000056],
            [-80.844356682999944, 35.314061329000026],
            [-80.844576665999966, 35.314341849000073],
            [-80.844847563999963, 35.31467871500007],
            [-80.845097886999952, 35.315004213000066],
            [-80.845315124999956, 35.315293934000067],
            [-80.845315176999975, 35.315294004000066],
            [-80.845435104999979, 35.315453949000073],
            [-80.845435125999984, 35.315453976000072],
            [-80.845585102999962, 35.315653993000069],
            [-80.845777772999952, 35.315895369000032],
            [-80.845776226999988, 35.315896172000066],
            [-80.846779572999935, 35.317193219000046],
            [-80.846810183999935, 35.317326273000049],
            [-80.846837069999935, 35.317440624000028],
            [-80.846867401999987, 35.317566892000059],
            [-80.846895815999972, 35.317693460000044],
            [-80.846911384999942, 35.317761889000053],
            [-80.847063076999973, 35.318419835000043],
            [-80.84663861699994, 35.31862373000007],
            [-80.846607637999966, 35.318638358000044],
            [-80.846571845999961, 35.318648627000073],
            [-80.845495572999937, 35.31899949600006],
            [-80.844808685999965, 35.319259505000048],
            [-80.844758691999971, 35.319279502000029],
            [-80.844399597999939, 35.319427296000072],
            [-80.843388387999937, 35.319890286000032],
            [-80.842416276999984, 35.320406483000056],
            [-80.842066408999983, 35.320611086000042],
            [-80.842016411999964, 35.320641081000076],
            [-80.841437427999949, 35.321003671000028],
            [-80.84055581299998, 35.321619432000034],
            [-80.839725211999962, 35.322281126000064],
            [-80.83894917799995, 35.322985920000065],
            [-80.838781800999982, 35.323150769000051],
            [-80.838731798999959, 35.323200763000045],
            [-80.838181033999945, 35.323780793000026],
            [-80.837523859999976, 35.324562567000044],
            [-80.836930468999981, 35.325377889000038],
            [-80.836403402999963, 35.326223268000035],
            [-80.835944920999964, 35.327095087000032],
            [-80.83555698899994, 35.327989612000067],
            [-80.835241268999937, 35.328903014000048],
            [-80.83499911399997, 35.329831381000076],
            [-80.834831565999934, 35.330770739000059],
            [-80.834739342999967, 35.331717067000056],
            [-80.834722843999941, 35.332666309000047],
            [-80.834782140999948, 35.333614404000059],
            [-80.83491698399996, 35.334557292000056],
            [-80.835079408999945, 35.335307273000069],
            [-80.835119375999966, 35.335467278000067],
            [-80.83516676399995, 35.335650938000072],
            [-80.835450652999953, 35.336571334000041],
            [-80.835639551999975, 35.337074511000026],
            [-80.835699524999939, 35.337224520000063],
            [-80.835867375999953, 35.337624552000079],
            [-80.835870786999976, 35.337632303000078],
            [-80.836668251999981, 35.339443123000024],
            [-80.836836977999951, 35.339854683000056],
            [-80.836958177999975, 35.340122099000041],
            [-80.837003357999947, 35.340218857000025],
            [-80.837045785999976, 35.340316445000042],
            [-80.837066887999981, 35.340364714000032],
            [-80.837181003999945, 35.340616931000056],
            [-80.837233172999959, 35.340729031000023],
            [-80.837283169999978, 35.340841796000063],
            [-80.837694928999952, 35.341686891000052],
            [-80.837699462999979, 35.341695400000049],
            [-80.837779437999984, 35.341845411000065],
            [-80.838067513999988, 35.34235830800003],
            [-80.838197481999941, 35.342578325000034],
            [-80.838402500999962, 35.342914234000034],
            [-80.838967509999975, 35.343743042000028],
            [-80.839498527999979, 35.344414648000054],
            [-80.839468486999976, 35.344591420000029],
            [-80.839449267999953, 35.344737608000059],
            [-80.839419216999943, 35.344977604000064],
            [-80.839346249999949, 35.345777744000031],
            [-80.839344560999962, 35.345807902000047],
            [-80.839314440999942, 35.346357897000075],
            [-80.839299756999935, 35.347272274000034],
            [-80.839217165999969, 35.347523594000052],
            [-80.838975000999937, 35.348451966000027],
            [-80.838807460999988, 35.349391326000045],
            [-80.838715262999983, 35.350337653000054],
            [-80.838698806999957, 35.351286893000065],
            [-80.838758163999955, 35.352234983000073],
            [-80.838893083999949, 35.353177862000052],
            [-80.839102989999958, 35.354111492000072],
            [-80.839386988999934, 35.355031876000055],
            [-80.839743861999978, 35.355935070000044],
            [-80.840172087999974, 35.356817208000052],
            [-80.840669832999936, 35.357674512000074],
            [-80.841234966999934, 35.358503307000035],
            [-80.841865072999951, 35.359300047000033],
            [-80.842557450999948, 35.360061316000042],
            [-80.843309139999974, 35.360783855000079],
            [-80.844116919999976, 35.361464568000031],
            [-80.844977330999939, 35.362100540000029],
            [-80.845886690999976, 35.362689046000071],
            [-80.846841102999974, 35.363227564000056],
            [-80.847836479999955, 35.36371378900003],
            [-80.848868557999936, 35.364145636000046],
            [-80.849932917999979, 35.364521256000046],
            [-80.851024996999968, 35.364839040000049],
            [-80.852140118999955, 35.365097625000033],
            [-80.853273505999937, 35.365295905000039],
            [-80.854420300999948, 35.36543302900003],
            [-80.855575593999959, 35.365508411000064],
            [-80.85673443099995, 35.365521726000054],
            [-80.857891849999987, 35.365472919000069],
            [-80.859042891999934, 35.365362198000071],
            [-80.860182622999957, 35.365190039000026],
            [-80.861306161999948, 35.364957176000075],
            [-80.862408695999989, 35.36466461100008],
            [-80.863485499999967, 35.364313594000066],
            [-80.86453196299999, 35.36390563100008],
            [-80.865543599999967, 35.363442468000073],
            [-80.86651607899995, 35.36292609000003],
            [-80.867445234999934, 35.362358710000024],
            [-80.868327087999944, 35.361742758000048],
            [-80.869157858999984, 35.361080873000049],
            [-80.86993399399995, 35.360375890000057],
            [-80.870652168999982, 35.359630828000036],
            [-80.87130930699999, 35.358848881000029],
            [-80.871902597999963, 35.358033396000053],
            [-80.872429500999942, 35.35718786700005],
            [-80.872887760999959, 35.356315915000039],
            [-80.873043006999978, 35.355979494000053],
            [-80.873122974999944, 35.355799484000045],
            [-80.873311093999973, 35.355353593000075],
            [-80.873370319999935, 35.355205453000053],
            [-80.873528347999979, 35.354854119000038],
            [-80.873740866999981, 35.354353644000071],
            [-80.873920783999949, 35.35390361900005],
            [-80.873965117999944, 35.353791181000076],
            [-80.874041427999941, 35.35358986500006],
            [-80.874211341999967, 35.353129841000055],
            [-80.874450409999952, 35.352417659000025],
            [-80.87469218199999, 35.351489217000051],
            [-80.87479665699999, 35.350954777000027],
            [-80.874846594999951, 35.350664770000037],
            [-80.874909249999973, 35.35025980000006],
            [-80.87500102599995, 35.349313446000053],
            [-80.875010818999954, 35.349110813000038],
            [-80.875020764999988, 35.348860812000055],
            [-80.875027003999946, 35.348114199000065],
            [-80.87501671299998, 35.347902657000077],
            [-80.875407722999967, 35.346853017000058],
            [-80.875659127999938, 35.346107490000065],
            [-80.875667802999942, 35.346078681000051],
            [-80.875727762999986, 35.345878672000026],
            [-80.875960826999972, 35.344979036000041],
            [-80.876027565999948, 35.344652751000069],
            [-80.876067521999971, 35.344442746000027],
            [-80.876167883999983, 35.343829618000029],
            [-80.876259638999954, 35.342883261000054],
            [-80.876278817999946, 35.342205568000054],
            [-80.87627877999995, 35.342035568000028],
            [-80.876275616999976, 35.341764014000034],
            [-80.876251037999964, 35.34122639800006],
            [-80.876220942999964, 35.340786403000038],
            [-80.876185735999968, 35.340375946000051],
            [-80.876050404999944, 35.339433106000058],
            [-80.875961733999986, 35.338996876000067],
            [-80.875921697999956, 35.338816881000071],
            [-80.875900718999958, 35.338731090000067],
            [-80.876155732999962, 35.33850693200003],
            [-80.876877441999966, 35.337851601000068],
            [-80.877846362999946, 35.336983106000048],
            [-80.879803310999989, 35.335259583000038],
            [-80.880370931999948, 35.334734766000054],
            [-80.881088784999974, 35.333989639000038],
            [-80.881745615999989, 35.333207630000061],
            [-80.882338614999981, 35.332392090000042],
            [-80.882865243999959, 35.331546512000045],
            [-80.883323246999964, 35.330674516000045],
            [-80.883710667999935, 35.329779839000025],
            [-80.884025846999975, 35.32886631100007],
            [-80.884267438999984, 35.327937846000054],
            [-80.884434409999983, 35.326998419000063],
            [-80.884526049999977, 35.326052053000069],
            [-80.884541965999972, 35.32510280200006],
            [-80.884482095999942, 35.324154730000032],
            [-80.884346694999977, 35.323211896000032],
            [-80.884136349999949, 35.322278339000036],
            [-80.883851959999959, 35.321358055000076],
            [-80.883626595999942, 35.320765506000043],
            [-80.883583291999969, 35.320659672000033],
            [-80.883576086999938, 35.320640861000072],
            [-80.883568613999955, 35.320616639000036],
            [-80.883522180999989, 35.320461972000032],
            [-80.883484381999949, 35.320338401000072],
            [-80.882964052999966, 35.318668473000059],
            [-80.882860713999946, 35.318351784000072],
            [-80.882620662999955, 35.317648064000025],
            [-80.882547689999967, 35.317412385000068],
            [-80.882458693999979, 35.317145570000037],
            [-80.882444495999948, 35.316778504000069],
            [-80.882390361999967, 35.316015031000063],
            [-80.882334321999963, 35.315558312000064],
            [-80.882316741999944, 35.31543470500003],
            [-80.882301281999958, 35.315310908000072],
            [-80.882194903999959, 35.314622201000077],
            [-80.882106231999956, 35.314185992000034],
            [-80.88192606399997, 35.313376018000042],
            [-80.881904155999962, 35.313279382000076],
            [-80.881778345999976, 35.312734713000054],
            [-80.881774503999964, 35.312712261000058],
            [-80.881564214999969, 35.311778697000079],
            [-80.881522016999952, 35.311627623000049],
            [-80.881244858999935, 35.310425075000069],
            [-80.881208440999956, 35.310271596000064],
            [-80.88110835699996, 35.309861610000041],
            [-80.881043773999977, 35.309608770000068],
            [-80.880932030999986, 35.309216147000029],
            [-80.880801945999963, 35.308786165000072],
            [-80.88062937899997, 35.308258495000075],
            [-80.880540228999962, 35.308013216000063],
            [-80.88042016899999, 35.307693232000076],
            [-80.880152201999977, 35.307035428000063],
            [-80.880115213999943, 35.306952168000066],
            [-80.879985162999958, 35.30666218600004],
            [-80.879623209999977, 35.305919060000065],
            [-80.879423147999944, 35.305539086000067],
            [-80.879393695999966, 35.305483466000055],
            [-80.878895861999979, 35.304626314000075],
            [-80.878330717999972, 35.303797685000063],
            [-80.878221186999951, 35.303650726000058],
            [-80.877851130999943, 35.303160772000069],
            [-80.87755902899994, 35.302795659000026],
            [-80.877559504999965, 35.30279540600003],
            [-80.877338817999942, 35.302515368000059],
            [-80.875696231999939, 35.300392995000038],
            [-80.87564711899995, 35.300328673000024],
            [-80.875571444999935, 35.300225986000044],
            [-80.87532131599994, 35.299896605000072],
            [-80.875240864999967, 35.299793754000063],
            [-80.875162296999974, 35.299689929000067],
            [-80.875092128999938, 35.299597973000061],
            [-80.87469207099997, 35.299078021000071],
            [-80.874496983999961, 35.298830088000045],
            [-80.874293204999958, 35.298576800000035],
            [-80.874250131999986, 35.298518220000062],
            [-80.873808489999988, 35.297948032000079],
            [-80.873752782999986, 35.297879680000051],
            [-80.873898087999976, 35.296867072000055],
            [-80.874678525999968, 35.294532082000046],
            [-80.874788123999963, 35.294187636000061],
            [-80.87483006299999, 35.294045833000041],
            [-80.875049911999952, 35.293285802000071],
            [-80.875147390999985, 35.292928816000028],
            [-80.875178140999935, 35.292809147000071],
            [-80.87521105999997, 35.292689866000046],
            [-80.875369471999988, 35.29204913500007],
            [-80.875386189999972, 35.291971487000069],
            [-80.875456121999946, 35.291641477000042],
            [-80.875539508999964, 35.29120944500005],
            [-80.875689327999964, 35.290349423000066],
            [-80.87572404399998, 35.290139157000056],
            [-80.875744954999959, 35.290005149000024],
            [-80.875839932999952, 35.289500452000027],
            [-80.875884356999961, 35.28924990400003],
            [-80.875934292999943, 35.288949897000066],
            [-80.875985939999964, 35.28860964900008],
            [-80.876072781999937, 35.287743858000056],
            [-80.876102680999963, 35.287273854000034],
            [-80.876107533999971, 35.287193278000075],
            [-80.87612354099997, 35.286244021000073],
            [-80.876108229999943, 35.285855451000032],
            [-80.876098190999983, 35.285675452000078],
            [-80.876053757999955, 35.285115940000026],
            [-80.876043397999979, 35.285019317000035],
            [-80.876023358999987, 35.284839319000071],
            [-80.875967130999982, 35.284406196000077],
            [-80.875927073999947, 35.28413620200007],
            [-80.87585842499999, 35.283723099000042],
            [-80.875822840999945, 35.283537893000073],
            [-80.875800063999975, 35.283424130000071],
            [-80.875783211999988, 35.283316180000043],
            [-80.875728270999957, 35.282993117000046],
            [-80.875518127999953, 35.282059538000055],
            [-80.875413519999938, 35.281690375000039],
            [-80.875383499999941, 35.281590379000079],
            [-80.87520395699994, 35.281039231000079],
            [-80.875090751999949, 35.280730198000072],
            [-80.875010711999948, 35.280520209000031],
            [-80.874948889999985, 35.280361232000075],
            [-80.874565318999942, 35.279394090000039],
            [-80.874513460999935, 35.279254283000057],
            [-80.874193307999974, 35.278434326000024],
            [-80.873986618999936, 35.277936244000045],
            [-80.873739594999961, 35.277407168000025],
            [-80.87371213299997, 35.277351623000072],
            [-80.873688538999943, 35.277294895000068],
            [-80.873586462999981, 35.277056298000048],
            [-80.873581436999984, 35.27704488300003],
            [-80.873564328999976, 35.277006086000029],
            [-80.873397942999986, 35.276483505000044],
            [-80.873376907999955, 35.276363453000045],
            [-80.87316680899994, 35.27542986800006],
            [-80.873143351999943, 35.275343148000047],
            [-80.872973226999989, 35.274723172000051],
            [-80.872931778999941, 35.274575718000051],
            [-80.872899037999957, 35.274461956000039],
            [-80.872868675999939, 35.27434775200004],
            [-80.872866588999955, 35.274339910000037],
            [-80.872727461999943, 35.273857625000062],
            [-80.872672634999958, 35.273681079000028],
            [-80.872637513999962, 35.273559841000065],
            [-80.872625803999938, 35.27351537900006],
            [-80.872616415999971, 35.273479945000076],
            [-80.872511587999952, 35.273109971000054],
            [-80.872451548999948, 35.272909979000076],
            [-80.872272286999987, 35.272359635000043],
            [-80.872134410999934, 35.271986323000078],
            [-80.87201435299994, 35.271676339000066],
            [-80.871933960999968, 35.271474326000032],
            [-80.871842840999989, 35.270870607000063],
            [-80.871817175999979, 35.270465025000078],
            [-80.87181779499997, 35.270444377000047],
            [-80.871819981999977, 35.269814646000043],
            [-80.871804649999945, 35.269425277000039],
            [-80.871794610999984, 35.269245278000028],
            [-80.871776403999945, 35.268974360000072],
            [-80.871736293999959, 35.268464365000057],
            [-80.871734775999983, 35.268445267000061],
            [-80.871723517999953, 35.268304993000072],
            [-80.871713668999973, 35.268164648000038],
            [-80.871707593999986, 35.268082176000064],
            [-80.87169838799997, 35.267962834000059],
            [-80.87169712299999, 35.26792264200003],
            [-80.871692415999973, 35.267765737000047],
            [-80.871689378999974, 35.26766191400003],
            [-80.871750771999984, 35.267372197000043],
            [-80.871822087999988, 35.266995085000076],
            [-80.871955939999964, 35.266356672000029],
            [-80.871961971999951, 35.266327723000074],
            [-80.871982926999976, 35.266226552000035],
            [-80.872006797999973, 35.266125816000056],
            [-80.872053774999983, 35.265919617000066],
            [-80.872087029999989, 35.265762611000071],
            [-80.872246867999934, 35.264982588000066],
            [-80.872279042999935, 35.264819923000061],
            [-80.872488812999961, 35.263719894000076],
            [-80.87253179399994, 35.263481549000062],
            [-80.872611694999989, 35.263011538000057],
            [-80.872670261999986, 35.262630132000027],
            [-80.872676664999972, 35.262582755000039],
            [-80.87275653599994, 35.261982744000079],
            [-80.872777605999943, 35.261815483000078],
            [-80.87281502899998, 35.261500564000073],
            [-80.873000366999975, 35.260145464000061],
            [-80.873037898999939, 35.259842330000026],
            [-80.873087129999988, 35.259398396000051],
            [-80.873100599999987, 35.259303967000051],
            [-80.873109537999937, 35.259240068000054],
            [-80.873156456999936, 35.258850834000043],
            [-80.873166467999965, 35.258754543000066],
            [-80.873179627999946, 35.258658508000053],
            [-80.873196256999961, 35.258532165000076],
            [-80.873300975999939, 35.257702522000045],
            [-80.873455347999936, 35.256542848000038],
            [-80.873466262999955, 35.256458571000053],
            [-80.873606025999948, 35.255348551000054],
            [-80.873642936999943, 35.255018552000024],
            [-80.873735826999962, 35.254068978000078],
            [-80.873809534999964, 35.253397574000076],
            [-80.873818278999977, 35.253339966000055],
            [-80.873853968999981, 35.253054305000035],
            [-80.873871963999989, 35.25289471800005],
            [-80.873890372999938, 35.252735162000079],
            [-80.873903233999954, 35.252618702000063],
            [-80.873997249999945, 35.251727341000048],
            [-80.874017907999985, 35.251599936000048],
            [-80.874064809999936, 35.25121077700004],
            [-80.874153934999981, 35.250353388000065],
            [-80.874220401999935, 35.249805913000046],
            [-80.874246274999962, 35.249755808000032],
            [-80.874369867999974, 35.249489690000075],
            [-80.874599777999947, 35.248979658000053],
            [-80.874710297999968, 35.248727335000069],
            [-80.874910213999954, 35.248257308000063],
            [-80.87502546799999, 35.247977183000046],
            [-80.875395296999955, 35.247047132000034],
            [-80.875433057999942, 35.246951037000031],
            [-80.875730063999981, 35.246096402000035],
            [-80.875770037999985, 35.245966396000028],
            [-80.875787983999942, 35.245907511000041],
            [-80.875924276999967, 35.245420526000032],
            [-80.876004213999977, 35.245110515000079],
            [-80.876109360999976, 35.24466903800004],
            [-80.876240934999942, 35.243969269000047],
            [-80.876270893999958, 35.243779265000057],
            [-80.876306213999953, 35.243539604000034],
            [-80.876397853999947, 35.242593230000068],
            [-80.876417007999976, 35.241915635000055],
            [-80.876416983999945, 35.241805636000038],
            [-80.876413825999975, 35.241533967000066],
            [-80.876354110999955, 35.240585877000058],
            [-80.876316709999969, 35.240307103000077],
            [-80.876329621999957, 35.240242985000066],
            [-80.876455344999954, 35.239499582000064],
            [-80.876531216999979, 35.238789385000075],
            [-80.876541188999965, 35.238659384000073],
            [-80.876556950999941, 35.238423207000039],
            [-80.876576102999934, 35.237745530000041],
            [-80.876576075999935, 35.237625530000059],
            [-80.876572916999976, 35.237353942000027],
            [-80.876513202999945, 35.236405852000075],
            [-80.876455507999935, 35.235936894000076],
            [-80.876445493999938, 35.235866896000061],
            [-80.876368026999955, 35.23539299600003],
            [-80.876157999999975, 35.234459411000046],
            [-80.876123304999965, 35.234331968000049],
            [-80.87607326899996, 35.234151974000042],
            [-80.875823969999942, 35.233359101000076],
            [-80.875678438999955, 35.232965940000042],
            [-80.875608405999969, 35.23278595000005],
            [-80.875397201999988, 35.232276002000049],
            [-80.875121806999971, 35.231689602000074],
            [-80.875071789999936, 35.231589609000025],
            [-80.874919232999957, 35.231293976000075],
            [-80.874421899999959, 35.23043679400007],
            [-80.873896859999945, 35.229662227000063],
            [-80.873816846999944, 35.229552236000075],
            [-80.873777303999987, 35.229498144000047],
            [-80.873147889999984, 35.228701552000075],
            [-80.872456341999964, 35.227940440000054],
            [-80.872302198999989, 35.227784125000028],
            [-80.872252195999977, 35.227734130000044],
            [-80.871734334999985, 35.227239452000049],
            [-80.871504327999958, 35.227029477000031],
            [-80.871425612999985, 35.226958094000054],
            [-80.87061893799995, 35.226277547000052],
            [-80.87028229699996, 35.226018396000029],
            [-80.870162298999958, 35.225928407000026],
            [-80.869639764999988, 35.225551752000058],
            [-80.868947023999965, 35.225095826000029],
            [-80.868787033999979, 35.224995840000076],
            [-80.868571774999964, 35.224863424000034],
            [-80.867618839999977, 35.224325064000027],
            [-80.866625047999946, 35.223838989000058],
            [-80.866494305999936, 35.223780299000055],
            [-80.866224335999959, 35.223660318000043],
            [-80.865324685999951, 35.223287299000049],
            [-80.864967059999969, 35.223153336000053],
            [-80.86474708999998, 35.22307334900006],
            [-80.864237826999954, 35.222895935000054],
            [-80.864057849999938, 35.222835944000053],
            [-80.863862155999982, 35.222771826000042],
            [-80.862771930999941, 35.222454150000033],
            [-80.86185515599999, 35.222236605000035],
            [-80.861656000999972, 35.222194089000027],
            [-80.861619033999943, 35.222166367000057],
            [-80.861192055999936, 35.221838436000041],
            [-80.861016540999969, 35.221651737000059],
            [-80.860265978999962, 35.220929290000072],
            [-80.860139790999938, 35.220816780000064],
            [-80.859969787999944, 35.220666797000035],
            [-80.859289456999988, 35.22009868400005],
            [-80.858430433999956, 35.219462798000052],
            [-80.857522582999934, 35.218874371000027],
            [-80.85656979099997, 35.218335923000041],
            [-80.855576137999947, 35.217849757000067],
            [-80.854545877999954, 35.217417956000077],
            [-80.853483418999986, 35.217042367000033],
            [-80.852393310999958, 35.216724597000052],
            [-80.851280218999989, 35.216466008000054],
            [-80.850148907999937, 35.216267703000028],
            [-80.849004218999937, 35.216130536000037],
            [-80.847851050999964, 35.216055089000065],
            [-80.846694341999978, 35.216041688000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 103,
        "SolutionID": "PI-10:F",
        "Shape_Length": 0.37224634674690138,
        "Shape_Area": 0.0055640924900490062
      }
    },
    {
      "type": "Feature",
      "id": 104,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.866670796999983, 35.214461738000068],
            [-80.865515528999936, 35.214510630000063],
            [-80.864366626999981, 35.214621416000057],
            [-80.863229010999987, 35.214793619000034],
            [-80.862107547999983, 35.215026506000072],
            [-80.861007038999958, 35.215319076000071],
            [-80.860711370999979, 35.215409002000058],
            [-80.86055139299998, 35.215458994000073],
            [-80.859772214999964, 35.215720071000078],
            [-80.858727630999965, 35.216128004000041],
            [-80.857717784999977, 35.216591122000068],
            [-80.856746996999959, 35.217107443000032],
            [-80.855819423999947, 35.217674755000075],
            [-80.854939035999962, 35.218290632000048],
            [-80.854109604999962, 35.218952437000041],
            [-80.853334679999989, 35.219657335000079],
            [-80.852617580999947, 35.220402313000079],
            [-80.851961379999977, 35.221184179000034],
            [-80.851368886999978, 35.221999586000038],
            [-80.85084263899995, 35.222845043000063],
            [-80.850384893999944, 35.223716932000059],
            [-80.849997612999971, 35.224611520000053],
            [-80.849682454999936, 35.225524975000042],
            [-80.84944077199998, 35.226453388000039],
            [-80.849273603999961, 35.227392783000028],
            [-80.849181665999936, 35.228339138000024],
            [-80.849165355999958, 35.22928840000003],
            [-80.849224745999948, 35.23023650500005],
            [-80.849359584999945, 35.231179393000048],
            [-80.84956929599997, 35.232113026000036],
            [-80.849852987999952, 35.233033405000072],
            [-80.850209442999983, 35.233936588000063],
            [-80.850637140999936, 35.234818709000024],
            [-80.851134249999973, 35.235675988000025],
            [-80.851143735999983, 35.23569103300008],
            [-80.851263709999955, 35.235881048000067],
            [-80.85181861999996, 35.236694768000064],
            [-80.852250376999962, 35.237253478000071],
            [-80.852380360999973, 35.237413494000066],
            [-80.852577871999983, 35.237651484000025],
            [-80.853269316999956, 35.238412709000045],
            [-80.85401997699995, 35.239135198000042],
            [-80.854099626999982, 35.239206484000078],
            [-80.854279622999968, 35.239366503000042],
            [-80.855006638999953, 35.239975872000059],
            [-80.855529752999985, 35.240372935000039],
            [-80.856709786999943, 35.241233048000026],
            [-80.857045891999974, 35.24147189100006],
            [-80.857953991999977, 35.242060319000075],
            [-80.858300516999975, 35.242264870000042],
            [-80.859030570999948, 35.242684930000053],
            [-80.859637130999943, 35.24301881100007],
            [-80.86063111499999, 35.243504941000026],
            [-80.860995941999988, 35.243665792000058],
            [-80.861345983999968, 35.243815815000062],
            [-80.862011780999978, 35.244086710000033],
            [-80.86307463199995, 35.244462223000028],
            [-80.863276926999959, 35.244526171000075],
            [-80.86356696699994, 35.24461618600003],
            [-80.864393570999937, 35.244853697000053],
            [-80.86473362299995, 35.244943712000065],
            [-80.86479524799995, 35.244959922000078],
            [-80.865908771999955, 35.245218386000033],
            [-80.865975182999989, 35.24523178100003],
            [-80.86632524099997, 35.245301793000067],
            [-80.866610646999959, 35.245351291000077],
            [-80.866855122999937, 35.245493096000075],
            [-80.866998419999959, 35.245575344000031],
            [-80.867341857999975, 35.245770397000058],
            [-80.86741116799999, 35.245815418000063],
            [-80.867804673999956, 35.246115945000042],
            [-80.86824108199994, 35.246438306000073],
            [-80.868359441999985, 35.246522857000059],
            [-80.868561259999979, 35.246674237000036],
            [-80.868790668999964, 35.246843322000075],
            [-80.86895994799994, 35.246965916000079],
            [-80.871051225999963, 35.248536907000073],
            [-80.87113115599999, 35.248596582000062],
            [-80.871633072999941, 35.248969012000032],
            [-80.871776920999935, 35.249079678000044],
            [-80.871892668999976, 35.249167929000066],
            [-80.872792686999958, 35.249848017000033],
            [-80.872973727999977, 35.249982930000044],
            [-80.873092179999958, 35.25006998200007],
            [-80.873209372999952, 35.250158172000056],
            [-80.873230361999958, 35.25017394200006],
            [-80.873746272999938, 35.250560918000076],
            [-80.873767455999939, 35.250577758000077],
            [-80.874044971999979, 35.250792288000071],
            [-80.874824986999954, 35.25138236500004],
            [-80.87493600199997, 35.251465632000077],
            [-80.875070250999954, 35.25156546900007],
            [-80.875342149999938, 35.251775371000065],
            [-80.875848853999969, 35.252157664000038],
            [-80.876566128999968, 35.252709476000064],
            [-80.876913799999954, 35.252969893000056],
            [-80.87719195599999, 35.253172737000057],
            [-80.877389653999955, 35.253323751000039],
            [-80.877989139999954, 35.253773415000069],
            [-80.87915228199995, 35.254689935000044],
            [-80.879469342999982, 35.254933691000076],
            [-80.879829350999955, 35.255203726000047],
            [-80.880204378999963, 35.25547711300004],
            [-80.880394759999945, 35.255611980000026],
            [-80.880556222999985, 35.255736761000037],
            [-80.880708397999967, 35.255852986000036],
            [-80.881238408999934, 35.256253037000079],
            [-80.881480082999985, 35.25643210800007],
            [-80.882067081999935, 35.256859060000068],
            [-80.882384788999957, 35.257095329000038],
            [-80.882507760999943, 35.257185924000055],
            [-80.882765135999989, 35.257373759000075],
            [-80.882778556999938, 35.257387286000039],
            [-80.883157248999964, 35.257766041000025],
            [-80.883467051999958, 35.258084052000072],
            [-80.88360837099998, 35.258227240000053],
            [-80.88405834799994, 35.258677290000037],
            [-80.884660434999944, 35.259248479000064],
            [-80.88475251999995, 35.259330736000038],
            [-80.886808215999963, 35.261152867000078],
            [-80.887164476999942, 35.261474416000055],
            [-80.887180377999982, 35.26148874900008],
            [-80.887647627999968, 35.261893441000041],
            [-80.888007626999979, 35.262193478000029],
            [-80.888347540999973, 35.262469217000046],
            [-80.888612354999964, 35.262674039000046],
            [-80.888942361999966, 35.262924071000043],
            [-80.889537260999987, 35.263354917000072],
            [-80.89028794099994, 35.263846471000079],
            [-80.890707965999979, 35.264106508000054],
            [-80.890865866999945, 35.264203135000059],
            [-80.891444060999959, 35.264538421000054],
            [-80.891894097999966, 35.264788457000066],
            [-80.892269460999955, 35.264991344000066],
            [-80.892323842999986, 35.265019818000042],
            [-80.893664000999934, 35.265718655000057],
            [-80.895884538999951, 35.26689716900006],
            [-80.895928101999971, 35.266920214000038],
            [-80.896925046999968, 35.26744590800007],
            [-80.897282860999951, 35.26764038500005],
            [-80.897445155999947, 35.26772754700005],
            [-80.898915284999987, 35.268507662000047],
            [-80.898980053999935, 35.268541866000078],
            [-80.899145590999979, 35.268627812000034],
            [-80.899409158999958, 35.268762983000045],
            [-80.899904095999943, 35.269026433000079],
            [-80.899990142999968, 35.269071945000064],
            [-80.900636566999935, 35.269395879000058],
            [-80.901360249999982, 35.269741291000059],
            [-80.901505763999978, 35.269814052000072],
            [-80.902115712999944, 35.270105511000054],
            [-80.902584181999941, 35.270319207000057],
            [-80.904736040999978, 35.271331360000033],
            [-80.905505841999968, 35.271705286000042],
            [-80.905750936999937, 35.271930739000027],
            [-80.905821985999978, 35.271994280000058],
            [-80.906091980999975, 35.272234309000055],
            [-80.906828355999949, 35.272851073000027],
            [-80.907688319999977, 35.273486610000077],
            [-80.908451167999942, 35.27398544700003],
            [-80.908791187999952, 35.274195476000045],
            [-80.908937164999941, 35.274284684000065],
            [-80.909890956999959, 35.274822714000038],
            [-80.910098940999944, 35.27493032600006],
            [-80.911048397999934, 35.275414039000054],
            [-80.911128797999936, 35.275456358000042],
            [-80.911141070999975, 35.275462811000068],
            [-80.911968096999942, 35.275871651000045],
            [-80.912365087999945, 35.276055977000055],
            [-80.912918967999985, 35.276326008000069],
            [-80.913379637999981, 35.276556356000071],
            [-80.913935098999957, 35.276838654000073],
            [-80.914706027999955, 35.27720869500007],
            [-80.914789735999989, 35.277246339000044],
            [-80.915259788999947, 35.277456371000028],
            [-80.916207420999967, 35.27785000800003],
            [-80.916931899999952, 35.278112789000033],
            [-80.917041137999945, 35.278150035000067],
            [-80.91714912499998, 35.278189655000062],
            [-80.91771205799995, 35.278386678000061],
            [-80.917922087999955, 35.278456689000052],
            [-80.918131080999956, 35.278525088000038],
            [-80.918668991999937, 35.278689683000039],
            [-80.91883011799996, 35.278736557000059],
            [-80.919084819999966, 35.279074525000055],
            [-80.919223728999953, 35.279249739000079],
            [-80.919401947999972, 35.27949282600008],
            [-80.919416898999941, 35.279513181000027],
            [-80.919656772999986, 35.279822173000071],
            [-80.91965621099996, 35.279822462000027],
            [-80.920178921999934, 35.280507559000057],
            [-80.920406739999976, 35.280814405000058],
            [-80.920452969999985, 35.280908022000062],
            [-80.920951095999953, 35.281765007000047],
            [-80.921516519999955, 35.282593440000028],
            [-80.922146823999981, 35.283389773000067],
            [-80.922839306999947, 35.284150594000039],
            [-80.92359100699997, 35.284872645000064],
            [-80.924398704999987, 35.285552832000064],
            [-80.925258939999935, 35.286188243000026],
            [-80.926168031999964, 35.286776155000041],
            [-80.927122084999951, 35.287314048000042],
            [-80.928117012999962, 35.287799620000044],
            [-80.929148554999983, 35.288230790000057],
            [-80.930212291999965, 35.28860571000007],
            [-80.931303665999963, 35.28892277500006],
            [-80.931415946999948, 35.288951703000066],
            [-80.931471816999988, 35.288965931000064],
            [-80.931799144999957, 35.289073162000079],
            [-80.932222577999937, 35.289215816000024],
            [-80.932410501999982, 35.289275143000054],
            [-80.932700543999943, 35.289365158000066],
            [-80.933527842999979, 35.289602867000042],
            [-80.933867893999945, 35.289692883000043],
            [-80.933944062999956, 35.289712890000033],
            [-80.934829810999986, 35.289923061000025],
            [-80.934969832999968, 35.289953066000066],
            [-80.935198440999955, 35.29000072000008],
            [-80.935720802999981, 35.290099715000053],
            [-80.935890831999984, 35.290129720000039],
            [-80.93650101299994, 35.290228231000071],
            [-80.937398651999956, 35.290340348000029],
            [-80.937688701999946, 35.290370353000071],
            [-80.937936940999975, 35.290394574000061],
            [-80.939091244999986, 35.290469161000033],
            [-80.939903240999968, 35.290484448000029],
            [-80.940433335999955, 35.290484448000029],
            [-80.940779121999981, 35.29048167600007],
            [-80.941566543999954, 35.290454657000055],
            [-80.942136644999948, 35.29042465100008],
            [-80.942505522999966, 35.290402058000041],
            [-80.942988476999972, 35.290362834000064],
            [-80.943528571999934, 35.290312826000047],
            [-80.944195481999941, 35.290240521000044],
            [-80.944805866999957, 35.290155653000056],
            [-80.944823280999969, 35.29015297400008],
            [-80.945076127999982, 35.290126358000066],
            [-80.945198359999949, 35.290112327000031],
            [-80.947034317999965, 35.289936637000039],
            [-80.94763607799996, 35.289870446000066],
            [-80.947664882999959, 35.289866777000043],
            [-80.949182787999973, 35.289714985000046],
            [-80.949252988999945, 35.289708446000077],
            [-80.949395982999988, 35.289697110000077],
            [-80.949411205999979, 35.289695897000058],
            [-80.949911294999936, 35.289655890000063],
            [-80.950568911999937, 35.289593074000038],
            [-80.951045541999974, 35.289540116000069],
            [-80.951187051999966, 35.289531952000061],
            [-80.951565176999964, 35.289502147000064],
            [-80.952135275999979, 35.289452139000048],
            [-80.952843839999957, 35.289378092000049],
            [-80.952976260999947, 35.289362020000056],
            [-80.953109049999966, 35.289348149000034],
            [-80.953527097999938, 35.289300318000073],
            [-80.953634971999975, 35.289286619000052],
            [-80.953767693999964, 35.289269421000029],
            [-80.95598410599996, 35.289046850000034],
            [-80.956241730999977, 35.289019402000065],
            [-80.95683274999999, 35.288952808000033],
            [-80.958094350999943, 35.288824943000066],
            [-80.958865030999959, 35.288754075000043],
            [-80.959227964999968, 35.28871758300005],
            [-80.961468877999948, 35.288472942000055],
            [-80.962089472999935, 35.288408104000041],
            [-80.962508652999986, 35.288360123000075],
            [-80.962610475999952, 35.288344639000059],
            [-80.964161333999982, 35.288184453000042],
            [-80.964413393999962, 35.288184452000053],
            [-80.964763392999942, 35.288181611000027],
            [-80.965919645999975, 35.288131772000042],
            [-80.967069446999972, 35.288020023000058],
            [-80.968207865999943, 35.287846845000047],
            [-80.969330027999945, 35.287612978000027],
            [-80.970431124999948, 35.287319425000078],
            [-80.971506439999985, 35.286967444000027],
            [-80.972551365999948, 35.28655854200008],
            [-80.973561425999947, 35.286094471000069],
            [-80.97453229599995, 35.28557722000005],
            [-80.975459814999965, 35.285009004000074],
            [-80.97634001199998, 35.284392257000036],
            [-80.977169114999981, 35.283729622000067],
            [-80.977943576999962, 35.283023938000042],
            [-80.978660078999951, 35.282278226000074],
            [-80.97931555599996, 35.281495681000024],
            [-80.979907199999957, 35.280679656000075],
            [-80.980432479999934, 35.279833646000043],
            [-80.98088914799996, 35.278961274000039],
            [-80.981275248999964, 35.278066276000061],
            [-80.981589132999943, 35.277152487000023],
            [-80.981829458999982, 35.276223819000052],
            [-80.981995197999936, 35.275284249000038],
            [-80.982085645999973, 35.274337801000058],
            [-80.982100413999945, 35.273388529000044],
            [-80.982039445999987, 35.272440497000048],
            [-80.981903001999967, 35.27149776400006],
            [-80.981691670999965, 35.270564367000077],
            [-80.981406358999948, 35.269644305000043],
            [-80.981048291999969, 35.268741513000066],
            [-80.980619003999948, 35.267859860000044],
            [-80.980120334999981, 35.267003118000048],
            [-80.979554421999978, 35.266174957000032],
            [-80.978923690999977, 35.265378921000035],
            [-80.978230842999949, 35.264618418000055],
            [-80.977478844999951, 35.263896704000047],
            [-80.976670918999957, 35.263216868000029],
            [-80.975810524999986, 35.262581820000037],
            [-80.974901344999978, 35.261994279000078],
            [-80.973947272999965, 35.261456760000044],
            [-80.972952393999947, 35.260971562000066],
            [-80.971920965999971, 35.260540764000041],
            [-80.970857403999958, 35.260166208000044],
            [-80.969766260999961, 35.25984949900004],
            [-80.96865220799998, 35.259591991000036],
            [-80.967520012999955, 35.25939478600003],
            [-80.966374520999977, 35.259258730000056],
            [-80.965220633999934, 35.259184403000063],
            [-80.964413166999975, 35.259169285000041],
            [-80.963223378999942, 35.259169285000041],
            [-80.962873504999948, 35.259172122000052],
            [-80.961717660999966, 35.259221932000059],
            [-80.961571425999978, 35.259232680000025],
            [-80.961181493999959, 35.25926267400007],
            [-80.960833073999936, 35.25929233100004],
            [-80.960083204999989, 35.259362319000047],
            [-80.95987282599998, 35.25938300100006],
            [-80.957743194999978, 35.259602964000067],
            [-80.957298823999963, 35.259653560000061],
            [-80.957186078999939, 35.259670707000055],
            [-80.956947991999982, 35.259695581000074],
            [-80.956849018999947, 35.259706154000071],
            [-80.954740590999961, 35.259936333000041],
            [-80.954052375999936, 35.259999617000062],
            [-80.953853630999959, 35.260018826000078],
            [-80.952373888999944, 35.260168800000031],
            [-80.952135550999969, 35.260194304000038],
            [-80.951554317999978, 35.260259795000024],
            [-80.949293624999939, 35.260486810000032],
            [-80.949167754999962, 35.260499824000078],
            [-80.948697836999941, 35.260549814000058],
            [-80.948320372999945, 35.26059336600008],
            [-80.948274024999989, 35.260599183000068],
            [-80.948108323999975, 35.260620165000034],
            [-80.948092324999948, 35.260621871000069],
            [-80.948062572999959, 35.260624818000053],
            [-80.948030538999944, 35.260626953000042],
            [-80.94796007299999, 35.260631765000028],
            [-80.94751432299995, 35.260667611000031],
            [-80.946964419999972, 35.260717602000057],
            [-80.946845646999975, 35.260729598000069],
            [-80.946657148999975, 35.260483789000034],
            [-80.946518411999989, 35.260305758000072],
            [-80.946328386999937, 35.260065781000037],
            [-80.946006017999935, 35.25967286000008],
            [-80.94572402099999, 35.259350460000064],
            [-80.94563401299996, 35.25925047100003],
            [-80.945223517999978, 35.258812178000028],
            [-80.945079512999939, 35.258666282000036],
            [-80.944979506999971, 35.258566292000069],
            [-80.944484884999952, 35.258092868000062],
            [-80.944364881999945, 35.257982881000032],
            [-80.944251859999952, 35.257880281000041],
            [-80.943715772999951, 35.257419025000047],
            [-80.943545772999983, 35.25727904200005],
            [-80.94327427199994, 35.257060240000044],
            [-80.942748164999955, 35.25666177100004],
            [-80.942638166999984, 35.256581781000079],
            [-80.94230420699995, 35.25634496400005],
            [-80.94139533799995, 35.255757171000027],
            [-80.941306390999955, 35.255703771000071],
            [-80.940736429999959, 35.255363820000071],
            [-80.939957224999944, 35.25492468300007],
            [-80.939131257999975, 35.25448536600004],
            [-80.939044077999938, 35.254437420000045],
            [-80.938731705999942, 35.254269520000037],
            [-80.938399728999968, 35.25409938100006],
            [-80.937879777999967, 35.25383941900003],
            [-80.93721717699998, 35.253524068000047],
            [-80.936289146999968, 35.253132978000053],
            [-80.936059174999968, 35.253042992000076],
            [-80.935956061999946, 35.25300297900003],
            [-80.935264700999937, 35.252751578000073],
            [-80.934914747999983, 35.25263159800005],
            [-80.934542811999961, 35.252508125000077],
            [-80.933911533999947, 35.252316643000029],
            [-80.933211637999989, 35.252116676000071],
            [-80.933107345999986, 35.252087177000078],
            [-80.932582650999962, 35.251940266000076],
            [-80.931789610999942, 35.251704596000025],
            [-80.931458981999981, 35.25161414400003],
            [-80.931249312999967, 35.251509315000078],
            [-80.931088737999971, 35.251430038000024],
            [-80.930762389999984, 35.251121725000075],
            [-80.930596464999951, 35.250974537000047],
            [-80.930436462999978, 35.250834554000051],
            [-80.929794980999986, 35.250301593000074],
            [-80.929147924999938, 35.249815806000072],
            [-80.929007928999965, 35.249715819000073],
            [-80.928795092999962, 35.249566224000034],
            [-80.928548108999962, 35.249398511000038],
            [-80.92836811899997, 35.249278527000058],
            [-80.927932200999976, 35.248997131000067],
            [-80.927772209999944, 35.248897145000058],
            [-80.927546418999952, 35.248758357000042],
            [-80.926592814999935, 35.248220463000052],
            [-80.926006205999954, 35.247925305000024],
            [-80.925736233999942, 35.247795325000027],
            [-80.925548903999982, 35.247706393000044],
            [-80.925164699999982, 35.247526562000076],
            [-80.924017193999987, 35.246975425000073],
            [-80.922654395999984, 35.246313529000076],
            [-80.922440222999967, 35.246211161000076],
            [-80.920080466999934, 35.245101330000068],
            [-80.919878074999986, 35.245007582000028],
            [-80.919616097999949, 35.244888090000074],
            [-80.919307130999982, 35.244737147000023],
            [-80.918798857999946, 35.244494576000079],
            [-80.918539605999968, 35.244356595000056],
            [-80.918453574999944, 35.244311096000047],
            [-80.918288066999935, 35.244225176000043],
            [-80.918013798999937, 35.244084534000024],
            [-80.916739918999951, 35.243408639000052],
            [-80.91636079899996, 35.243202608000047],
            [-80.916155458999981, 35.243092689000036],
            [-80.915077352999958, 35.242524268000068],
            [-80.912819307999939, 35.241325991000053],
            [-80.912754073999963, 35.241291539000031],
            [-80.912699699999962, 35.241263074000074],
            [-80.912368134999952, 35.241090200000031],
            [-80.911069866999981, 35.239939761000073],
            [-80.911026277999952, 35.239896179000027],
            [-80.910716367999953, 35.239578168000037],
            [-80.910574894999968, 35.23943487300005],
            [-80.910419374999947, 35.239279379000038],
            [-80.91011403899995, 35.238956155000039],
            [-80.909749530999989, 35.238593765000076],
            [-80.909619065999948, 35.238468253000065],
            [-80.909488723999971, 35.238342655000054],
            [-80.909092873999953, 35.237974043000065],
            [-80.908791270999984, 35.237709315000075],
            [-80.90868661199994, 35.237619721000044],
            [-80.90858351199995, 35.237528917000077],
            [-80.908309446999965, 35.237292910000065],
            [-80.90792944399999, 35.236972948000073],
            [-80.907669207999959, 35.236758354000074],
            [-80.907409208999979, 35.236548380000045],
            [-80.907265776999964, 35.236433850000026],
            [-80.906755604999944, 35.236046611000063],
            [-80.906077365999977, 35.235551732000033],
            [-80.90574860199996, 35.235307291000026],
            [-80.905603286999963, 35.235200439000039],
            [-80.905065139999977, 35.234809101000053],
            [-80.904731478999963, 35.234557309000024],
            [-80.904367153999942, 35.234275816000036],
            [-80.90379900399995, 35.23385548400006],
            [-80.903508885999941, 35.233650005000072],
            [-80.903494382999952, 35.233639129000039],
            [-80.902331097999934, 35.232722705000072],
            [-80.902013704999945, 35.232478746000027],
            [-80.901372033999962, 35.231997546000059],
            [-80.901308369999981, 35.231948035000073],
            [-80.900889571999983, 35.231632667000042],
            [-80.900585523999951, 35.23141098900004],
            [-80.899977252999975, 35.230943140000079],
            [-80.899851211999987, 35.230847135000033],
            [-80.899503435999975, 35.23058480800006],
            [-80.899465889999988, 35.230554630000029],
            [-80.899077364999982, 35.230256911000026],
            [-80.898743094999986, 35.230008379000026],
            [-80.898196570999971, 35.229595028000062],
            [-80.898145907999947, 35.229554762000078],
            [-80.897812994999981, 35.229298558000039],
            [-80.89733344199999, 35.228938933000052],
            [-80.897265919999938, 35.228884849000053],
            [-80.896634068999958, 35.228416444000061],
            [-80.895908814999984, 35.227868534000038],
            [-80.895706448999988, 35.227712885000074],
            [-80.89547220299994, 35.227535941000042],
            [-80.894892291999952, 35.227105730000062],
            [-80.892722190999962, 35.225475875000029],
            [-80.892482703999974, 35.225299263000068],
            [-80.892308308999986, 35.225172991000079],
            [-80.892062116999966, 35.224988367000037],
            [-80.891742295999961, 35.224754297000061],
            [-80.891683757999942, 35.224712489000069],
            [-80.89134869999998, 35.22445665500004],
            [-80.891136751999966, 35.224297436000029],
            [-80.890676762999988, 35.22395748100007],
            [-80.890246699999977, 35.223649806000026],
            [-80.889816479999979, 35.223361277000038],
            [-80.888846529999967, 35.222731363000037],
            [-80.888368629999945, 35.222431701000062],
            [-80.887935074999973, 35.222177492000071],
            [-80.887197020999963, 35.22175838000004],
            [-80.88626848499996, 35.221219866000069],
            [-80.886151042999984, 35.221152338000024],
            [-80.885148061999985, 35.22058058500005],
            [-80.884784599999989, 35.220369285000061],
            [-80.88410586599997, 35.21999375300004],
            [-80.883762947999969, 35.21981786200007],
            [-80.883262994999939, 35.219567900000072],
            [-80.882612078999955, 35.21925784900003],
            [-80.881894474999967, 35.21894997000004],
            [-80.881524518999981, 35.218799994000051],
            [-80.881211703999952, 35.218676301000073],
            [-80.880610200999968, 35.218455533000053],
            [-80.880180258999985, 35.218305557000065],
            [-80.880177649999951, 35.218310591000034],
            [-80.879669171999979, 35.218130970000061],
            [-80.878822216999936, 35.217878613000039],
            [-80.878312291999976, 35.217738636000036],
            [-80.878100196999981, 35.217681811000034],
            [-80.877499521999937, 35.217292633000056],
            [-80.876546616999974, 35.216754343000048],
            [-80.875552863999985, 35.216268341000045],
            [-80.874522517999935, 35.215836710000076],
            [-80.873459987999979, 35.215461296000058],
            [-80.872369822999985, 35.215143706000049],
            [-80.871256688999949, 35.214885300000049],
            [-80.870125351999945, 35.214687183000024],
            [-80.868980651999948, 35.214550204000034],
            [-80.867827486999943, 35.214474948000031],
            [-80.866670796999983, 35.214461738000068]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 104,
        "SolutionID": "PI-10:E",
        "Shape_Length": 0.32564406301787618,
        "Shape_Area": 0.0042599739074234196
      }
    },
    {
      "type": "Feature",
      "id": 105,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.027959386999953, 34.912224067000068],
            [-81.026808486999983, 34.91227450100007],
            [-81.025664051999968, 34.91238682200003],
            [-81.024530980999941, 34.912560548000044],
            [-81.023414124999988, 34.912794938000047],
            [-81.022318260999953, 34.913088985000059],
            [-81.021248081999943, 34.91344143300006],
            [-81.020208166999964, 34.913850774000025],
            [-81.019202969999981, 34.91431525400003],
            [-81.018236790999936, 34.914832887000046],
            [-81.018111707999935, 34.914905472000044],
            [-81.015006116999984, 34.916724204000047],
            [-81.014889279999977, 34.916778532000023],
            [-81.013923044999956, 34.917296131000057],
            [-81.01299996399996, 34.917864666000071],
            [-81.012123989999964, 34.91848170600008],
            [-81.011298872999987, 34.919144610000046],
            [-81.010528145999956, 34.919850538000048],
            [-81.009815109999977, 34.920596471000067],
            [-81.009162818999982, 34.921379214000069],
            [-81.008574065999937, 34.922195417000069],
            [-81.00805137499998, 34.923041586000068],
            [-81.007596985999953, 34.923914098000068],
            [-81.00721284499997, 34.924809217000075],
            [-81.006900599999938, 34.925723112000071],
            [-81.006832130999953, 34.925960556000064],
            [-81.006768259999944, 34.926190647000055],
            [-81.006597716999977, 34.92688195900007],
            [-81.006432966999967, 34.927821602000051],
            [-81.006343184999935, 34.928768108000043],
            [-81.006328759999974, 34.929717422000067],
            [-81.006339997999987, 34.930175289000033],
            [-81.006400992999943, 34.931123347000039],
            [-81.006537150999975, 34.932066091000024],
            [-81.006747889999986, 34.932999482000071],
            [-81.007032312999968, 34.933919522000053],
            [-81.007389199999977, 34.934822273000066],
            [-81.007817027999977, 34.93570386600004],
            [-81.007942684999989, 34.93593456800005],
            [-81.008576594999965, 34.937071743000047],
            [-81.009244901999978, 34.938355702000024],
            [-80.995674231999942, 34.948402867000027],
            [-80.985446767999974, 34.955455039000071],
            [-80.985400984999956, 34.955486712000038],
            [-80.976259650999964, 34.96183175300007],
            [-80.97573737099998, 34.962208967000038],
            [-80.974911517999942, 34.962871619000055],
            [-80.974140063999982, 34.963577312000041],
            [-80.973426313999937, 34.964323028000024],
            [-80.972773325999981, 34.965105572000027],
            [-80.972183893999954, 34.965921594000065],
            [-80.971660546999942, 34.966767602000061],
            [-80.971205524999959, 34.967639974000065],
            [-80.97082077999994, 34.968534975000068],
            [-80.970507961999942, 34.969448772000078],
            [-80.970268408999971, 34.970377454000072],
            [-80.970104050999964, 34.971310428000038],
            [-80.969862127999988, 34.97308877100005],
            [-80.969861230999982, 34.973095387000058],
            [-80.96977097499996, 34.974041862000036],
            [-80.969756116999974, 34.974991169000077],
            [-80.969816721999962, 34.975939242000038],
            [-80.969878374999951, 34.976433070000041],
            [-80.969989106999947, 34.977192054000056],
            [-80.963338456999963, 34.98744441100007],
            [-80.957763084999954, 34.995285971000044],
            [-80.957540540999958, 34.995608312000059],
            [-80.957016850999935, 34.99645425500006],
            [-80.956691326999987, 34.997061305000045],
            [-80.955722704999971, 34.998985542000071],
            [-80.955592878999937, 34.999250805000031],
            [-80.955207813999948, 35.000145754000073],
            [-80.954894699999954, 35.001059508000026],
            [-80.954889652999952, 35.001076366000063],
            [-80.954352818999951, 35.00287430800006],
            [-80.954153652999935, 35.003627057000074],
            [-80.953628194999965, 35.005901943000026],
            [-80.95359257299998, 35.006060982000065],
            [-80.953553068999952, 35.006250280000074],
            [-80.952269912999952, 35.012652173000049],
            [-80.95090180599999, 35.019135827000071],
            [-80.950877674999958, 35.01920959000006],
            [-80.949809049999942, 35.020757164000031],
            [-80.949731059999976, 35.020871302000046],
            [-80.947946286999979, 35.023511021000047],
            [-80.947915326999976, 35.023557005000043],
            [-80.94765242699998, 35.023964340000077],
            [-80.945557749999978, 35.027338042000054],
            [-80.945296658999951, 35.027776600000038],
            [-80.944841015999941, 35.028648868000062],
            [-80.94457453299998, 35.029247239000028],
            [-80.944025930999942, 35.03056818400006],
            [-80.943907087999946, 35.030864725000072],
            [-80.943593729999975, 35.031778447000079],
            [-80.943353687999945, 35.032707070000072],
            [-80.943191256999967, 35.033622803000071],
            [-80.942943889999981, 35.035416020000071],
            [-80.942940629999953, 35.035439835000034],
            [-80.942931073999944, 35.035511760000077],
            [-80.941705420999938, 35.044954780000069],
            [-80.941683420999937, 35.045134898000072],
            [-80.940721683999982, 35.053534595000031],
            [-80.940420959999983, 35.054554925000048],
            [-80.940301000999966, 35.054908718000036],
            [-80.940016621999973, 35.055308738000065],
            [-80.928878897999937, 35.07012928100005],
            [-80.928783889999977, 35.070257149000042],
            [-80.918942907999963, 35.083654180000053],
            [-80.918636628999934, 35.084087811000074],
            [-80.917654863999985, 35.085534779000056],
            [-80.917616537999947, 35.085591563000037],
            [-80.917091856999946, 35.08643732400003],
            [-80.916635584999938, 35.087309480000044],
            [-80.916249676999939, 35.088204295000025],
            [-80.916220515999953, 35.088280380000072],
            [-80.915673856999945, 35.089720425000053],
            [-80.91538911899994, 35.090557984000043],
            [-80.915148583999951, 35.091486544000077],
            [-80.914982447999989, 35.09242604700006],
            [-80.914896497999962, 35.093287590000045],
            [-80.914819297999941, 35.094506268000032],
            [-80.914157025999941, 35.099238166000077],
            [-80.906867686999988, 35.110099056000024],
            [-80.906345470999952, 35.110940697000046],
            [-80.906292754999981, 35.111033821000035],
            [-80.902370237999946, 35.118026794000059],
            [-80.90074827899997, 35.120252839000045],
            [-80.900440167999989, 35.120692986000051],
            [-80.90028297899994, 35.120932182000047],
            [-80.897913223999979, 35.124620285000049],
            [-80.895829195999966, 35.126996475000055],
            [-80.891393661999984, 35.130992911000078],
            [-80.891227747999949, 35.131144514000027],
            [-80.881276950999961, 35.140365104000068],
            [-80.880995275999965, 35.140632563000054],
            [-80.880279124999959, 35.141377713000054],
            [-80.879623824999953, 35.142159738000032],
            [-80.879032182999936, 35.142975290000038],
            [-80.878506731999948, 35.143820877000053],
            [-80.878063254999972, 35.144664637000062],
            [-80.877370376999977, 35.146106755000062],
            [-80.877356847999977, 35.146134999000026],
            [-80.876970230999973, 35.147029682000039],
            [-80.876802460999954, 35.147488382000063],
            [-80.875441275999947, 35.151445724000041],
            [-80.875361761999955, 35.151684662000036],
            [-80.87088831799997, 35.165590347000034],
            [-80.87082100799995, 35.165806241000041],
            [-80.870806038999945, 35.16585621300004],
            [-80.868918097999938, 35.172207189000062],
            [-80.868691763999948, 35.173085675000038],
            [-80.868524926999953, 35.174025104000066],
            [-80.868450604999964, 35.174715216000038],
            [-80.868338186999949, 35.176148296000065],
            [-80.86832085399999, 35.176404562000073],
            [-80.868304777999981, 35.17735383400003],
            [-80.868364351999958, 35.178301937000072],
            [-80.868499326999938, 35.179244811000046],
            [-80.868599827999958, 35.179734765000035],
            [-80.868923096999936, 35.181161110000062],
            [-80.869032397999945, 35.181604762000063],
            [-80.869316132999984, 35.182525102000056],
            [-80.869399195999961, 35.182754565000039],
            [-80.870209028999966, 35.184925895000049],
            [-80.867477209999947, 35.190625504000025],
            [-80.864297036999972, 35.196573041000079],
            [-80.864036948999967, 35.197049752000055],
            [-80.863136182999938, 35.198023351000074],
            [-80.862990107999963, 35.19818351400005],
            [-80.862334185999941, 35.198965439000062],
            [-80.861811928999941, 35.199677955000027],
            [-80.860405577999984, 35.201727325000036],
            [-80.860335602999953, 35.201830268000037],
            [-80.859809578999943, 35.202675767000073],
            [-80.859352042999944, 35.203547693000075],
            [-80.859338569999977, 35.203575959000034],
            [-80.858602584999971, 35.205124771000044],
            [-80.856782972999952, 35.208256868000035],
            [-80.85674137899997, 35.208328968000046],
            [-80.856288722999977, 35.209191462000035],
            [-80.85521472299996, 35.208878384000059],
            [-80.854101728999979, 35.20861982100007],
            [-80.852970519999985, 35.208421542000053],
            [-80.851825936999944, 35.208284401000071],
            [-80.850672876999965, 35.208208981000041],
            [-80.849516277999953, 35.20819560800004],
            [-80.84836108899998, 35.208244336000064],
            [-80.847212252999952, 35.208354959000076],
            [-80.846074686999941, 35.20852700100005],
            [-80.84495326199999, 35.208759729000064],
            [-80.843852775999949, 35.209052143000065],
            [-80.842777938999973, 35.209402995000062],
            [-80.841733350999959, 35.209810782000034],
            [-80.840723486999934, 35.210273758000028],
            [-80.839752665999981, 35.210789943000066],
            [-80.838825046999943, 35.211357126000053],
            [-80.838590199999942, 35.211513489000026],
            [-80.831890585999986, 35.216052257000058],
            [-80.831244910999942, 35.216511611000044],
            [-80.830415305999963, 35.217173253000055],
            [-80.829640192999989, 35.217878001000031],
            [-80.828922892999969, 35.218622838000044],
            [-80.828266478999979, 35.219404575000056],
            [-80.827673760999971, 35.220219866000036],
            [-80.827147279999963, 35.22106522100006],
            [-80.82668929099998, 35.221937021000031],
            [-80.826301756999953, 35.222831532000043],
            [-80.825986340999975, 35.223744926000052],
            [-80.825744392999979, 35.224673292000034],
            [-80.825576954999974, 35.225612654000031],
            [-80.825484743999937, 35.226558992000037],
            [-80.825468157999978, 35.227508251000074],
            [-80.825527270999942, 35.228456368000025],
            [-80.82566183299997, 35.229399283000078],
            [-80.825871267999958, 35.23033295700003],
            [-80.826154685999938, 35.231253391000052],
            [-80.826510870999982, 35.232156645000032],
            [-80.826938301999974, 35.233038849000025],
            [-80.827435150999975, 35.23389622600007],
            [-80.827999291999959, 35.234725102000027],
            [-80.828628309999942, 35.235521927000036],
            [-80.829319512999973, 35.236283289000028],
            [-80.830069941999966, 35.237005927000041],
            [-80.830876382999975, 35.23768674300004],
            [-80.831735382999966, 35.238322823000033],
            [-80.832643264999945, 35.238911441000027],
            [-80.833596138999951, 35.239450076000026],
            [-80.834589924999989, 35.239936420000049],
            [-80.835620363999965, 35.240368390000071],
            [-80.836683044999972, 35.240744135000057],
            [-80.837773412999979, 35.241062045000035],
            [-80.838886797999976, 35.241320758000029],
            [-80.84001842899994, 35.241519166000046],
            [-80.841163459999962, 35.241656419000037],
            [-80.842316983999979, 35.241731929000025],
            [-80.84347405799997, 35.241745371000036],
            [-80.844629724999947, 35.241696690000026],
            [-80.845779035999954, 35.241586092000034],
            [-80.846917063999967, 35.241414054000074],
            [-80.848038932999941, 35.241181309000069],
            [-80.849139838999974, 35.240888858000062],
            [-80.85021506399994, 35.240537951000078],
            [-80.851260001999947, 35.240130093000062],
            [-80.85227017699998, 35.239667031000067],
            [-80.853241262999973, 35.239150748000043],
            [-80.853461410999955, 35.239016148000076],
            [-80.85395710399996, 35.239075512000056],
            [-80.855110602999957, 35.239150900000027],
            [-80.856267642999967, 35.239164221000067],
            [-80.857720982999979, 35.239141913000026],
            [-80.858400241999959, 35.239120761000038],
            [-80.858940876999952, 35.239095377000069],
            [-80.859429918999979, 35.23911649300004],
            [-80.860540360999948, 35.239182130000074],
            [-80.860985365999966, 35.239203810000049],
            [-80.862142406999965, 35.239217074000067],
            [-80.862956276999967, 35.239204544000074],
            [-80.86411189599994, 35.239155677000042],
            [-80.865081325999938, 35.239066370000046],
            [-80.866995356999951, 35.238846004000038],
            [-80.867175174999943, 35.23882452600003],
            [-80.867367312999988, 35.238799861000075],
            [-80.868178990999979, 35.238691908000078],
            [-80.869124792999969, 35.238544327000056],
            [-80.870246559999941, 35.23831137600007],
            [-80.871347346999983, 35.238018722000049],
            [-80.872422437999944, 35.237667618000046],
            [-80.873467228999971, 35.23725956800007],
            [-80.874477241999955, 35.236796320000053],
            [-80.87544815199999, 35.236279859000035],
            [-80.876375800999938, 35.235712398000032],
            [-80.87715665199994, 35.235170219000054],
            [-80.877785639999956, 35.234707202000038],
            [-80.877885201999959, 35.234633350000024],
            [-80.878348535999976, 35.234274277000054],
            [-80.883343903999958, 35.230260444000066],
            [-80.883709930999942, 35.22995753500004],
            [-80.884484690999955, 35.229252443000064],
            [-80.885201575999986, 35.228507278000052],
            [-80.885857517999966, 35.227725233000058],
            [-80.886449707999986, 35.226909658000068],
            [-80.886975610999968, 35.226064046000033],
            [-80.887432977999936, 35.22519201800003],
            [-80.887754034999944, 35.22446347500005],
            [-80.888578874999951, 35.222422871000049],
            [-80.888644688999989, 35.222256708000032],
            [-80.888959395999962, 35.221343150000052],
            [-80.88902845299998, 35.221105795000028],
            [-80.889533344999961, 35.219303391000039],
            [-80.890795109999942, 35.217129998000075],
            [-80.890836677999971, 35.217057886000077],
            [-80.891293952999945, 35.216185843000062],
            [-80.891307415999961, 35.216157574000079],
            [-80.891823768999984, 35.215070055000069],
            [-80.892023751999943, 35.21477847500006],
            [-80.893419539999968, 35.213269217000061],
            [-80.893565581999951, 35.213109018000068],
            [-80.894221318999939, 35.21232692600006],
            [-80.894813312999986, 35.21151130700008],
            [-80.895339030999935, 35.21066565700005],
            [-80.895473900999946, 35.210424241000055],
            [-80.896588961999953, 35.208379023000077],
            [-80.896642190999955, 35.208280408000064],
            [-80.899984252999957, 35.202025454000079],
            [-80.900246830999947, 35.201506909000045],
            [-80.903987880999978, 35.193694930000049],
            [-80.903994333999947, 35.193681436000077],
            [-80.904380875999948, 35.192786672000068],
            [-80.904527961999975, 35.192387437000036],
            [-80.905072716999939, 35.190828227000054],
            [-80.905240041999946, 35.190313858000025],
            [-80.905480981999972, 35.189385329000061],
            [-80.905647424999984, 35.18844585100004],
            [-80.905738661999976, 35.18749944700005],
            [-80.90575430399997, 35.186550172000068],
            [-80.905694288999939, 35.185602089000042],
            [-80.905676244999938, 35.185438500000032],
            [-80.905559789999984, 35.184445336000067],
            [-80.905539358999988, 35.183571579000045],
            [-80.905479347999972, 35.182623496000076],
            [-80.905378717999952, 35.181878696000069],
            [-80.905040871999972, 35.179856272000052],
            [-80.905006094999976, 35.179658239000048],
            [-80.904795883999952, 35.178724695000028],
            [-80.904511760999981, 35.177804438000067],
            [-80.904428603999975, 35.177574999000058],
            [-80.90402157799997, 35.176484937000055],
            [-80.905096120999985, 35.172864971000024],
            [-80.909483055999942, 35.159210422000058],
            [-80.910107185999948, 35.15739374900005],
            [-80.917581874999939, 35.150465248000046],
            [-80.92261769299995, 35.145926603000078],
            [-80.923065132999966, 35.145507400000042],
            [-80.923780929999964, 35.144761997000046],
            [-80.923895306999952, 35.144633041000077],
            [-80.92728903699998, 35.140761989000055],
            [-80.927829504999977, 35.140108665000071],
            [-80.928420638999967, 35.139292874000034],
            [-80.928577780999944, 35.139053643000068],
            [-80.931297222999945, 35.134818870000061],
            [-80.933162897999978, 35.132256998000059],
            [-80.933470875999944, 35.131816768000078],
            [-80.933995678999963, 35.130970941000044],
            [-80.934048375999964, 35.130877805000068],
            [-80.938099502999989, 35.123650613000052],
            [-80.946203272999981, 35.111569075000034],
            [-80.946725081999944, 35.110727262000069],
            [-80.947181162999982, 35.109854995000035],
            [-80.947566833999986, 35.108960083000056],
            [-80.94786142199996, 35.108109039000055],
            [-80.94866934099997, 35.105471815000044],
            [-80.948688361999984, 35.10540913400007],
            [-80.948928556999988, 35.104480508000051],
            [-80.949088544999938, 35.103582927000048],
            [-80.949920361999943, 35.097621396000079],
            [-80.958955205999985, 35.085315446000038],
            [-80.970154849999972, 35.070405186000073],
            [-80.970376707999947, 35.070101525000041],
            [-80.971920172999944, 35.06792923900008],
            [-80.972137482999983, 35.067614432000028],
            [-80.97266147199997, 35.066768430000025],
            [-80.973117030999958, 35.065896061000046],
            [-80.973502208999946, 35.065001061000032],
            [-80.973682477999944, 35.064503418000072],
            [-80.974494900999957, 35.062104480000073],
            [-80.974627774999988, 35.061688325000034],
            [-80.974646521999944, 35.061625249000031],
            [-80.975316762999967, 35.059347911000032],
            [-80.975537773999974, 35.058482304000051],
            [-80.975703142999976, 35.057542714000078],
            [-80.975734599999953, 35.057290662000071],
            [-80.976801233999936, 35.047939801000041],
            [-80.977965610999945, 35.038939113000026],
            [-80.978928864999943, 35.03738670000007],
            [-80.980522532999942, 35.035028311000076],
            [-80.982248449999986, 35.032527458000061],
            [-80.982357349999972, 35.032367306000026],
            [-80.982881006999946, 35.031521256000076],
            [-80.983336259999987, 35.03064884500003],
            [-80.983397300999968, 35.030518588000064],
            [-80.984141249999936, 35.028907961000073],
            [-80.984465096999941, 35.028143180000029],
            [-80.984686643999964, 35.027520945000049],
            [-80.985172527999964, 35.026033838000046],
            [-80.985263864999979, 35.025742244000071],
            [-80.985503409999978, 35.024813538000046],
            [-80.985518082999988, 35.024744843000065],
            [-80.987033298999961, 35.017549565000024],
            [-80.987058059999981, 35.017428951000056],
            [-80.98831572399996, 35.011140897000075],
            [-80.988712896999971, 35.009418240000059],
            [-80.988815990999967, 35.009072475000039],
            [-80.993948651999972, 35.001849681000067],
            [-80.994171069999936, 35.001527272000033],
            [-80.994283593999967, 35.001356497000074],
            [-81.002804202999982, 34.988213354000038],
            [-81.002937109999948, 34.988004226000044],
            [-81.003913266999973, 34.986437315000046],
            [-81.004191024999955, 34.985971083000038],
            [-81.004645796999966, 34.985098588000028],
            [-81.005030248999958, 34.984203481000065],
            [-81.005342737999968, 34.983289595000031],
            [-81.005581929999948, 34.982360845000073],
            [-81.00574679999994, 34.981421207000039],
            [-81.005826490999937, 34.980638746000068],
            [-81.00592581099994, 34.979195125000047],
            [-81.008102869999959, 34.97768357800004],
            [-81.018544851999934, 34.970482099000037],
            [-81.019010987999934, 34.970148960000074],
            [-81.036342781999963, 34.957313871000053],
            [-81.03637000599997, 34.957294627000067],
            [-81.036865713999987, 34.957098101000042],
            [-81.036899068999958, 34.957088232000046],
            [-81.037969751999981, 34.956735645000037],
            [-81.039010123999958, 34.956326153000077],
            [-81.040015724999989, 34.955861508000055],
            [-81.040982250999946, 34.95534370200005],
            [-81.041905559999975, 34.954774954000072],
            [-81.042781698999988, 34.954157699000064],
            [-81.043606912999962, 34.953494582000076],
            [-81.044377671999939, 34.952788443000031],
            [-81.045090672999947, 34.952042308000046],
            [-81.045742864999966, 34.951259372000038],
            [-81.046331455999962, 34.950442989000067],
            [-81.046853925999983, 34.94959665600004],
            [-81.047308038999972, 34.948723997000059],
            [-81.047691852999947, 34.947828752000078],
            [-81.04800372599999, 34.946914753000044],
            [-81.048242326999969, 34.945985916000041],
            [-81.048406632999956, 34.945046217000026],
            [-81.048495945999946, 34.944099681000068],
            [-81.048509883999941, 34.943150363000029],
            [-81.048504145999971, 34.942921433000038],
            [-81.048442652999938, 34.941973396000037],
            [-81.048305994999964, 34.941030700000056],
            [-81.048094760999959, 34.940097383000079],
            [-81.047809853999979, 34.939177440000037],
            [-81.047726542999953, 34.938948104000076],
            [-81.047577503999946, 34.93854983600005],
            [-81.04730346499997, 34.937876541000037],
            [-81.046931253999958, 34.937101492000068],
            [-81.044502781999938, 34.932440492000069],
            [-81.044729296999947, 34.932005143000026],
            [-81.045113060999938, 34.931109905000028],
            [-81.045424898999954, 34.930195912000045],
            [-81.045663479999973, 34.929267079000056],
            [-81.045827781999947, 34.928327383000067],
            [-81.04591710699998, 34.927380847000052],
            [-81.045931071999973, 34.92643152900007],
            [-81.045869621999941, 34.925483490000033],
            [-81.045733019999943, 34.924540790000037],
            [-81.045521857999972, 34.923607467000068],
            [-81.045237037999982, 34.922687517000043],
            [-81.044879785999967, 34.921784878000039],
            [-81.044451630999959, 34.920903415000055],
            [-81.043954409999969, 34.920046901000035],
            [-81.043390251999938, 34.919219005000059],
            [-81.042761574999986, 34.918423270000062],
            [-81.042071073999978, 34.917663102000063],
            [-81.041321703999984, 34.916941755000039],
            [-81.040516674999935, 34.916262318000065],
            [-81.039659436999955, 34.915627699000026],
            [-81.038753654999937, 34.915040614000077],
            [-81.037803211999972, 34.914503576000072],
            [-81.036812175999955, 34.914018884000029],
            [-81.035784787999944, 34.913588613000059],
            [-81.034725445999982, 34.913214604000075],
            [-81.033638685999961, 34.912898458000029],
            [-81.032529158999978, 34.912641528000051],
            [-81.031401612999957, 34.912444913000058],
            [-81.030260874999954, 34.91230945600006],
            [-81.029111826999952, 34.912235735000024],
            [-81.027959386999953, 34.912224067000068]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 105,
        "SolutionID": "PI-10:B",
        "Shape_Length": 0.86011059689004898,
        "Shape_Area": 0.013558740027677745
      }
    },
    {
      "type": "Feature",
      "id": 106,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842817545999935, 35.212734983000075],
            [-80.841662288999942, 35.212783647000037],
            [-80.840513378999958, 35.212894206000044],
            [-80.839375735999965, 35.213066185000059],
            [-80.838254228999972, 35.213298851000047],
            [-80.837153656999988, 35.213591204000068],
            [-80.836078731999976, 35.213941996000074],
            [-80.835034052999958, 35.214349725000034],
            [-80.834024093999972, 35.214812645000052],
            [-80.833053176999954, 35.215328776000035],
            [-80.83212545899994, 35.215895906000071],
            [-80.831244910999942, 35.216511611000044],
            [-80.830415305999963, 35.217173253000055],
            [-80.829640192999989, 35.217878001000031],
            [-80.828922892999969, 35.218622838000044],
            [-80.828266478999979, 35.219404575000056],
            [-80.827673760999971, 35.220219866000036],
            [-80.827147279999963, 35.22106522100006],
            [-80.82668929099998, 35.221937021000031],
            [-80.826301756999953, 35.222831532000043],
            [-80.825986340999975, 35.223744926000052],
            [-80.825744392999979, 35.224673292000034],
            [-80.825576954999974, 35.225612654000031],
            [-80.825484743999937, 35.226558992000037],
            [-80.825468157999978, 35.227508251000074],
            [-80.825527270999942, 35.228456368000025],
            [-80.82566183299997, 35.229399283000078],
            [-80.825871267999958, 35.23033295700003],
            [-80.826154685999938, 35.231253391000052],
            [-80.826510870999982, 35.232156645000032],
            [-80.826938301999974, 35.233038849000025],
            [-80.827435150999975, 35.23389622600007],
            [-80.827999291999959, 35.234725102000027],
            [-80.828628309999942, 35.235521927000036],
            [-80.829319512999973, 35.236283289000028],
            [-80.829953003999947, 35.236899460000075],
            [-80.831632463999938, 35.238443764000067],
            [-80.831381893999946, 35.238853117000076],
            [-80.830923850999966, 35.239724930000079],
            [-80.830536278999944, 35.240619453000079],
            [-80.83022084199996, 35.241532856000049],
            [-80.82997888899996, 35.242461227000035],
            [-80.829811463999988, 35.243400593000047],
            [-80.829719280999939, 35.244346931000052],
            [-80.829702740999949, 35.245296188000054],
            [-80.829761915999939, 35.246244300000058],
            [-80.829896556999984, 35.247187207000025],
            [-80.830106085999944, 35.248120871000026],
            [-80.830389612999966, 35.249041293000062],
            [-80.830745922999938, 35.249944532000029],
            [-80.831173493999984, 35.250826718000042],
            [-80.831670495999958, 35.251684075000071],
            [-80.832234802999949, 35.252512929000034],
            [-80.832863999999972, 35.253309730000069],
            [-80.833555393999973, 35.254071066000051],
            [-80.834306023999943, 35.25479367500003],
            [-80.835112676999984, 35.255474461000063],
            [-80.835971897999968, 35.256110509000052],
            [-80.836594134999984, 35.256522268000026],
            [-80.83688916899996, 35.256708632000027],
            [-80.837175044999981, 35.256885457000067],
            [-80.83812815899995, 35.257424054000069],
            [-80.839122190999944, 35.257910360000039],
            [-80.840152881999984, 35.25834229000003],
            [-80.841215817999966, 35.258717994000051],
            [-80.841250270999979, 35.25872905500006],
            [-80.842246337999939, 35.259047871000064],
            [-80.843017154999984, 35.259271777000038],
            [-80.843288834999953, 35.259501013000033],
            [-80.843562548999955, 35.259712773000047],
            [-80.84498296999999, 35.260788469000033],
            [-80.845568597999943, 35.261212692000072],
            [-80.846010629999967, 35.261509016000048],
            [-80.847663717999978, 35.262581132000037],
            [-80.848129935999964, 35.262873315000036],
            [-80.849083192999956, 35.263411825000048],
            [-80.850077362999968, 35.263898041000061],
            [-80.850095873999976, 35.263906420000069],
            [-80.852035484999988, 35.264783255000054],
            [-80.853047819999972, 35.265206696000064],
            [-80.853490120999936, 35.26537120100005],
            [-80.854516724999939, 35.265739168000039],
            [-80.854766575999975, 35.266009447000044],
            [-80.855517504999966, 35.266731923000066],
            [-80.856324452999957, 35.267412566000075],
            [-80.85718396599998, 35.268048463000071],
            [-80.858092362999969, 35.268636887000071],
            [-80.859045753999965, 35.269175319000055],
            [-80.860001873999977, 35.269644097000025],
            [-80.861824324999986, 35.270474868000065],
            [-80.861862505999966, 35.27049222200003],
            [-80.862893476999943, 35.270923957000036],
            [-80.86312963599994, 35.27101325600006],
            [-80.864243852999948, 35.271426452000071],
            [-80.865227748999985, 35.272103639000079],
            [-80.865768980999974, 35.272461518000057],
            [-80.86579549999999, 35.272478359000047],
            [-80.866332932999967, 35.273866733000034],
            [-80.866552425999942, 35.274397917000044],
            [-80.866625896999949, 35.274561918000074],
            [-80.867361003999974, 35.276172741000039],
            [-80.867519110999979, 35.276506578000067],
            [-80.868007377999959, 35.277500967000037],
            [-80.868203904999973, 35.277885182000034],
            [-80.86870146199999, 35.278742383000065],
            [-80.869266333999974, 35.27957106000008],
            [-80.869769210999948, 35.280216077000034],
            [-80.870547249999959, 35.281158270000049],
            [-80.870674143999963, 35.281309857000053],
            [-80.871366128999966, 35.282070973000032],
            [-80.872117352999965, 35.282793344000027],
            [-80.872924599999976, 35.283473875000027],
            [-80.873932073999981, 35.284269364000068],
            [-80.874679150999953, 35.284826429000077],
            [-80.876867589999961, 35.286366970000074],
            [-80.876980344999936, 35.28644567200007],
            [-80.877889091999975, 35.287033946000065],
            [-80.878352873999972, 35.287305127000025],
            [-80.881037496999966, 35.288821946000041],
            [-80.884616045999962, 35.290860014000032],
            [-80.884900874999971, 35.291018875000077],
            [-80.889232859999936, 35.293384596000067],
            [-80.889385527999934, 35.29346703300007],
            [-80.893069265999941, 35.295433768000066],
            [-80.89367009099999, 35.295818632000078],
            [-80.899972692999938, 35.300749301000053],
            [-80.900287018999961, 35.300989274000074],
            [-80.903006237999989, 35.303015229000039],
            [-80.903431796999939, 35.304886404000058],
            [-80.903642242999979, 35.306207662000077],
            [-80.903692217999946, 35.306498454000064],
            [-80.903902746999961, 35.307431980000047],
            [-80.904187314999945, 35.308352219000028],
            [-80.904299364999986, 35.308657804000063],
            [-80.904733440999962, 35.309796034000044],
            [-80.904978785999958, 35.310393460000057],
            [-80.905407478999962, 35.311275374000047],
            [-80.905468507999956, 35.311388557000043],
            [-80.906319970999959, 35.312949609000043],
            [-80.90675711199998, 35.313693466000075],
            [-80.907322613999952, 35.314521960000036],
            [-80.907953025999973, 35.315318362000028],
            [-80.908645647999947, 35.316079260000038],
            [-80.908997217999968, 35.316428870000038],
            [-80.910069239999984, 35.317461601000048],
            [-80.91046954899997, 35.317834124000058],
            [-80.911207184999967, 35.318458662000069],
            [-80.91640825099995, 35.322605734000035],
            [-80.921557993999954, 35.326894139000046],
            [-80.921901847999948, 35.327172740000037],
            [-80.922028754999985, 35.327271806000056],
            [-80.924798170999964, 35.329412230000059],
            [-80.930373918999976, 35.334076315000061],
            [-80.930517413999951, 35.334194964000062],
            [-80.933723747999977, 35.336815621000028],
            [-80.933953687999974, 35.337000163000027],
            [-80.934157750999987, 35.337158450000061],
            [-80.936055432999979, 35.338607167000077],
            [-80.936472232999961, 35.338915378000024],
            [-80.937775447999968, 35.33984875200008],
            [-80.938015466999957, 35.340017578000072],
            [-80.938925252999979, 35.340605388000029],
            [-80.938983452999935, 35.340640372000053],
            [-80.941060122999943, 35.341883311000061],
            [-80.94591470499995, 35.345374754000034],
            [-80.946049366999944, 35.345470618000036],
            [-80.951022871999953, 35.348975018000033],
            [-80.951821934999941, 35.350839085000075],
            [-80.951876390999985, 35.350964284000042],
            [-80.952305806999959, 35.351846026000032],
            [-80.952719039999977, 35.352565562000052],
            [-80.953446244999952, 35.353746609000041],
            [-80.953531909999981, 35.35388391500004],
            [-80.954098167999973, 35.354712185000039],
            [-80.95472934999998, 35.35550833700006],
            [-80.955422751999947, 35.356268962000058],
            [-80.955774701999985, 35.356618438000055],
            [-80.957921022999983, 35.358683047000056],
            [-80.957957777999979, 35.358718287000045],
            [-80.961296207999965, 35.361908625000069],
            [-80.961660206999966, 35.36224573100003],
            [-80.962282571999935, 35.362776692000068],
            [-80.966262943999936, 35.366004212000064],
            [-80.966449377999936, 35.36615316700005],
            [-80.966544298999963, 35.366227347000063],
            [-80.970080951999989, 35.368970603000037],
            [-80.973516988999961, 35.372084278000045],
            [-80.977366217999986, 35.375611278000065],
            [-80.978107193999961, 35.376517736000039],
            [-80.978762899999936, 35.377345664000075],
            [-80.979527002999987, 35.378486846000044],
            [-80.981024222999963, 35.381171575000053],
            [-80.981184416999952, 35.381450915000073],
            [-80.982814864999966, 35.384217037000042],
            [-80.982140148999974, 35.38609668600003],
            [-80.981936497999982, 35.386713973000042],
            [-80.981887548999964, 35.386880710000071],
            [-80.981243331999963, 35.389133644000026],
            [-80.981051616999935, 35.389895555000066],
            [-80.980988328999956, 35.39020572700008],
            [-80.980523433999963, 35.392646568000032],
            [-80.980420730999981, 35.393275942000059],
            [-80.980330131999949, 35.394222368000044],
            [-80.980312743999946, 35.394732159000057],
            [-80.980292846999987, 35.396306935000041],
            [-80.980295423999962, 35.396746393000058],
            [-80.980372122999938, 35.39989403900006],
            [-80.980377761999989, 35.400071194000077],
            [-80.981451766999953, 35.427391726000053],
            [-80.981507201999989, 35.428162575000044],
            [-80.981643903999952, 35.429105280000044],
            [-80.981814844999974, 35.429882589000044],
            [-80.982008268999948, 35.430642793000061],
            [-80.982049070999949, 35.430798851000077],
            [-80.982334955999988, 35.43171888300003],
            [-80.982693755999946, 35.432621642000072],
            [-80.982827417999943, 35.43291406000003],
            [-80.983319199999983, 35.433955644000037],
            [-80.983615726999972, 35.434544842000037],
            [-80.984115460999988, 35.435401541000033],
            [-80.984417408999946, 35.43585818300005],
            [-80.985263533999955, 35.437084801000026],
            [-80.985528730999988, 35.437456270000041],
            [-80.986160863999942, 35.438252245000058],
            [-80.986734355999943, 35.438887527000077],
            [-80.988709369999981, 35.440955007000071],
            [-80.989065131999951, 35.441353675000073],
            [-80.989046733999942, 35.44155427700008],
            [-80.989015288999951, 35.441981476000024],
            [-80.989007532999949, 35.442140324000036],
            [-80.988896927999974, 35.444766198000025],
            [-80.988889963999952, 35.44555659100007],
            [-80.98894663599998, 35.446460774000059],
            [-80.989100571999984, 35.447984975000054],
            [-80.989105077999966, 35.448028788000045],
            [-80.989241901999947, 35.448971480000068],
            [-80.989280108999935, 35.449167964000026],
            [-80.99009571299996, 35.45318435300004],
            [-80.990269402999957, 35.453921217000072],
            [-80.990555462999964, 35.454841225000052],
            [-80.990914455999985, 35.45574395400007],
            [-80.991344848999972, 35.456625538000026],
            [-80.991844800999957, 35.457482202000051],
            [-80.992412171999945, 35.458310274000041],
            [-80.993044533999978, 35.459106209000026],
            [-80.993739178999988, 35.45986659700003],
            [-80.994493134999971, 35.460588183000027],
            [-80.995303173999957, 35.461267872000064],
            [-80.996165824999935, 35.461902756000029],
            [-80.997077396999941, 35.462490114000047],
            [-80.998033982999971, 35.463027430000068],
            [-80.999031487999957, 35.463512402000049],
            [-81.000065636999977, 35.463942952000025],
            [-81.001132001999963, 35.464317235000067],
            [-81.002226013999973, 35.464633649000064],
            [-81.00334298599995, 35.464890837000041],
            [-81.004478132999964, 35.465087699000037],
            [-81.005626591999942, 35.465223389000073],
            [-81.006783442999961, 35.465297328000077],
            [-81.007943727999987, 35.465309197000067],
            [-81.009102475999953, 35.465258947000052],
            [-81.010254724999982, 35.465146792000041],
            [-81.011395534999963, 35.464973214000054],
            [-81.012520018999965, 35.46473895500003],
            [-81.013623361999976, 35.464445021000074],
            [-81.014700833999939, 35.464092669000024],
            [-81.01574782199998, 35.463683409000055],
            [-81.016759837999984, 35.463218994000044],
            [-81.017732549999948, 35.462701415000026],
            [-81.018661788999964, 35.462132888000042],
            [-81.019543576999979, 35.461515850000069],
            [-81.020374135999987, 35.460852943000077],
            [-81.021149910999952, 35.460147007000046],
            [-81.021867579999935, 35.459401066000055],
            [-81.022524068999985, 35.45861831600007],
            [-81.023116569999956, 35.457802109000056],
            [-81.023642544999973, 35.456955942000036],
            [-81.024099744999944, 35.456083438000064],
            [-81.024486211999942, 35.45518833400007],
            [-81.024800293999988, 35.454274465000026],
            [-81.025040649999937, 35.453345744000046],
            [-81.025206250999986, 35.452406149000069],
            [-81.025296391999973, 35.451459702000079],
            [-81.025310687999934, 35.450510458000053],
            [-81.025249081999959, 35.449562482000033],
            [-81.025111838999976, 35.448619830000041],
            [-81.025073544999941, 35.448423357000024],
            [-81.02437600199994, 35.444995639000069],
            [-81.024367523999956, 35.444912053000053],
            [-81.024429385999952, 35.443427987000064],
            [-81.024677443999963, 35.440710341000056],
            [-81.024708700999952, 35.440283134000026],
            [-81.024723001999973, 35.439333888000078],
            [-81.024706765999952, 35.438942134000058],
            [-81.024604509999961, 35.437178550000056],
            [-81.024559156999942, 35.436622323000051],
            [-81.024421943999982, 35.435679669000024],
            [-81.024209697999936, 35.434746377000067],
            [-81.024139472999934, 35.434495952000077],
            [-81.023820787999966, 35.43340392500005],
            [-81.023604647999946, 35.432734417000063],
            [-81.023245393999957, 35.431831779000049],
            [-81.022814785999969, 35.430950304000078],
            [-81.022390899999948, 35.430215438000062],
            [-81.02160136699996, 35.428940414000067],
            [-81.021525140999984, 35.428818738000075],
            [-81.020957678999935, 35.427990797000064],
            [-81.020325285999945, 35.427195002000076],
            [-81.020101975999978, 35.426939104000041],
            [-81.017164044999959, 35.423648263000075],
            [-81.016813709999951, 35.42326902800005],
            [-81.016752103999977, 35.42320456300007],
            [-81.015798881999956, 35.399221490000059],
            [-81.015728457999955, 35.396382170000038],
            [-81.015732191999973, 35.396076172000051],
            [-81.015896446999989, 35.395211818000064],
            [-81.016275056999973, 35.393885789000024],
            [-81.017443379999975, 35.390627151000047],
            [-81.017646771999978, 35.390009805000034],
            [-81.017670424999949, 35.389930193000055],
            [-81.01826132299999, 35.387916491000055],
            [-81.018477906999976, 35.387067382000055],
            [-81.018643447999978, 35.386127782000074],
            [-81.01873359199999, 35.385181326000065],
            [-81.018747952999945, 35.384232070000053],
            [-81.018723811999962, 35.383714576000045],
            [-81.018632413999967, 35.38238018800007],
            [-81.018595077999976, 35.381949688000077],
            [-81.018525480999983, 35.381406772000048],
            [-81.018316440999968, 35.380026630000032],
            [-81.018248987999982, 35.379626874000053],
            [-81.018036958999971, 35.378693560000045],
            [-81.017750855999964, 35.377773601000058],
            [-81.017667175999975, 35.377544250000028],
            [-81.017168194999954, 35.376216645000056],
            [-81.016892936999966, 35.375543329000038],
            [-81.016462695999962, 35.374661821000075],
            [-81.016309907999982, 35.374384218000046],
            [-81.015213322999955, 35.372446299000046],
            [-81.015091542999983, 35.372235535000073],
            [-81.013166976999969, 35.36897263000003],
            [-81.011477728999978, 35.365945682000074],
            [-81.01109237899999, 35.365298160000066],
            [-81.010896325999965, 35.364997197000037],
            [-81.009505357999956, 35.362920980000069],
            [-81.009134517999939, 35.362393937000036],
            [-81.008834589999935, 35.36200186800005],
            [-81.00775248299999, 35.36063617700006],
            [-81.007610659999955, 35.360460002000025],
            [-81.006109303999949, 35.358624132000045],
            [-81.005919305999953, 35.358396504000041],
            [-81.005225412999948, 35.357636168000056],
            [-81.004596135999975, 35.357026863000044],
            [-80.999889136999968, 35.352715219000061],
            [-80.999823170999946, 35.352655126000059],
            [-80.995892136999942, 35.349093899000025],
            [-80.995834300999945, 35.349041756000076],
            [-80.995025375999944, 35.348362053000074],
            [-80.99493044999997, 35.348287895000055],
            [-80.99103611299995, 35.345267914000033],
            [-80.987709793999954, 35.342571414000076],
            [-80.984861037999963, 35.339849856000058],
            [-80.984491768999987, 35.339494768000066],
            [-80.983565110999962, 35.337335002000032],
            [-80.983510613999954, 35.33720981700003],
            [-80.983080937999944, 35.336328183000035],
            [-80.982630375999975, 35.335548803000052],
            [-80.981600288999971, 35.333895767000058],
            [-80.981551737999951, 35.33381842600005],
            [-80.980985345999954, 35.332990281000036],
            [-80.980354082999952, 35.33219426200003],
            [-80.97966065199995, 35.331433777000029],
            [-80.979491697999947, 35.331263264000029],
            [-80.978358095999965, 35.330136717000073],
            [-80.977774437999983, 35.329585527000063],
            [-80.976965856999982, 35.328905702000043],
            [-80.976104765999935, 35.328270663000069],
            [-80.975481254999977, 35.327859615000079],
            [-80.974390535999987, 35.327173233000053],
            [-80.969201792999968, 35.323517907000053],
            [-80.963818378999974, 35.319646870000042],
            [-80.963549599999965, 35.31945745400003],
            [-80.962639872999944, 35.318869827000071],
            [-80.962581677999935, 35.318834853000055],
            [-80.960489798999959, 35.317583021000075],
            [-80.959980872999949, 35.317218595000043],
            [-80.958509222999965, 35.31609536600007],
            [-80.955588496999951, 35.313708732000066],
            [-80.949838734999958, 35.308900293000079],
            [-80.949465359999976, 35.30859715400004],
            [-80.949338448999981, 35.308498116000067],
            [-80.946553776999963, 35.306346407000035],
            [-80.941499800999964, 35.302138797000055],
            [-80.941226270999948, 35.301915964000045],
            [-80.938213683999948, 35.299514538000039],
            [-80.937728482999944, 35.297385108000071],
            [-80.937684966999939, 35.297200740000051],
            [-80.93710463299999, 35.294824470000037],
            [-80.937038322999967, 35.294565256000055],
            [-80.936753430999943, 35.293645092000077],
            [-80.936747547999971, 35.293628438000042],
            [-80.936310841999955, 35.292394934000072],
            [-80.93595905799998, 35.291508667000073],
            [-80.93553013799999, 35.290626857000063],
            [-80.935031803999948, 35.289769933000059],
            [-80.934709064999936, 35.289281824000057],
            [-80.933925672999976, 35.288149273000045],
            [-80.933682806999968, 35.287809012000025],
            [-80.933052355999962, 35.28701274000008],
            [-80.932359752999957, 35.286251978000053],
            [-80.932287187999975, 35.286177983000073],
            [-80.931452484999966, 35.285332503000063],
            [-80.930773272999943, 35.284684496000068],
            [-80.929965530999937, 35.284004351000078],
            [-80.927653045999989, 35.282180463000032],
            [-80.927283874999944, 35.281897493000031],
            [-80.924244481999949, 35.279633498000067],
            [-80.917464579999944, 35.274330589000044],
            [-80.916659356999958, 35.273738194000032],
            [-80.916142496999953, 35.273394152000037],
            [-80.914252295999972, 35.272183572000074],
            [-80.913860289999946, 35.271939625000073],
            [-80.913013880999983, 35.271458490000043],
            [-80.90876912799996, 35.269192531000044],
            [-80.904657252999982, 35.266947302000062],
            [-80.901193298999942, 35.264974764000044],
            [-80.90113843599994, 35.264943646000063],
            [-80.899678766999955, 35.264119056000027],
            [-80.898876187999974, 35.262047858000074],
            [-80.898656516999949, 35.261516732000075],
            [-80.898510955999939, 35.261197726000034],
            [-80.897593142999938, 35.259255967000058],
            [-80.89731035899996, 35.258693024000024],
            [-80.89681262299996, 35.257835938000028],
            [-80.896247620999986, 35.257007387000044],
            [-80.895744676999982, 35.256362477000039],
            [-80.894966583999974, 35.255420442000059],
            [-80.894839684999965, 35.255268880000074],
            [-80.894147708999981, 35.254507895000074],
            [-80.893396549999977, 35.253785657000037],
            [-80.892589426999962, 35.253105259000051],
            [-80.891285902999982, 35.252075997000077],
            [-80.890426292999962, 35.251440341000034],
            [-80.889845351999952, 35.251055144000077],
            [-80.88769992899995, 35.249692986000071],
            [-80.885661181999978, 35.248290007000037],
            [-80.88479254899994, 35.247729216000039],
            [-80.883839239999986, 35.247190985000032],
            [-80.883275973999957, 35.246906811000031],
            [-80.88174672599996, 35.246167226000068],
            [-80.881315831999984, 35.245965456000079],
            [-80.880285078999975, 35.245533876000025],
            [-80.880048974999966, 35.24544461000005],
            [-80.879885119999983, 35.245383853000078],
            [-80.879872026999976, 35.245372361000079],
            [-80.87844811299999, 35.24415398900004],
            [-80.877948759999981, 35.243743457000051],
            [-80.877557281999941, 35.243443463000062],
            [-80.876551375999952, 35.24269569300003],
            [-80.87608344399996, 35.242359932000056],
            [-80.875175209999952, 35.241771640000024],
            [-80.874222033999956, 35.241233333000025],
            [-80.873227995999969, 35.240747314000032],
            [-80.872799465999947, 35.240567841000029],
            [-80.872864366999977, 35.24041784700006],
            [-80.873179321999942, 35.23950433300007],
            [-80.873420772999964, 35.238575875000038],
            [-80.873587685999951, 35.237636449000036],
            [-80.873679350999964, 35.236690076000059],
            [-80.873695375999944, 35.235740812000074],
            [-80.873635697999987, 35.23479272000003],
            [-80.873500571999955, 35.233849859000031],
            [-80.873290580999935, 35.232916269000043],
            [-80.87300662499996, 35.231995945000051],
            [-80.872649925999951, 35.231092828000044],
            [-80.872222009999973, 35.230210785000054],
            [-80.871724711999946, 35.229353592000052],
            [-80.871160162999956, 35.228524920000041],
            [-80.870530782999936, 35.227728314000046],
            [-80.869839266999975, 35.226967187000071],
            [-80.869088578999936, 35.226244795000071],
            [-80.868281930999956, 35.225564232000067],
            [-80.86742277999997, 35.224928410000075],
            [-80.866514802999973, 35.224340052000059],
            [-80.865561887999945, 35.223801675000061],
            [-80.864568113999951, 35.223315584000034],
            [-80.863537736999945, 35.222883859000035],
            [-80.862475165999967, 35.222508349000066],
            [-80.86163440699994, 35.222263354000063],
            [-80.859836959999939, 35.220798244000036],
            [-80.856335354999942, 35.217579475000036],
            [-80.856218410999986, 35.217473034000079],
            [-80.855411957999934, 35.21679238400003],
            [-80.854552999999953, 35.216156470000044],
            [-80.853645212999936, 35.215568014000041],
            [-80.852692485999967, 35.215029535000042],
            [-80.851698895999959, 35.214543338000055],
            [-80.850668697999936, 35.214111503000026],
            [-80.849606299999948, 35.213735879000069],
            [-80.848516251999968, 35.21341807500005],
            [-80.847403217999954, 35.213159449000045],
            [-80.846271961999946, 35.212961109000048],
            [-80.845127325999954, 35.212823904000061],
            [-80.843974207999963, 35.212748421000072],
            [-80.842817545999935, 35.212734983000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 106,
        "SolutionID": "PI-10:G",
        "Shape_Length": 0.70492232448311409,
        "Shape_Area": 0.01084074555893208
      }
    },
    {
      "type": "Feature",
      "id": 107,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.848808301999952, 35.007507173000079],
            [-80.847655950999979, 35.007555895000053],
            [-80.846509935999961, 35.00766651400005],
            [-80.845375163999961, 35.007838554000045],
            [-80.844256492999989, 35.00807128100007],
            [-80.843158707999976, 35.00836369600006],
            [-80.842086508999955, 35.008714551000025],
            [-80.841044484999941, 35.009122341000079],
            [-80.840037097999982, 35.009585324000057],
            [-80.839068657999974, 35.010101515000031],
            [-80.838143312999989, 35.010668707000036],
            [-80.837265023999976, 35.011284470000078],
            [-80.83643755199995, 35.011946171000034],
            [-80.83566443899997, 35.012650975000042],
            [-80.83494899599998, 35.013395867000042],
            [-80.834294288999956, 35.014177657000062],
            [-80.833703120999985, 35.014992998000025],
            [-80.833178024999938, 35.015838401000053],
            [-80.832721250999953, 35.016710246000059],
            [-80.832334757999945, 35.017604800000072],
            [-80.832020201999967, 35.018518233000066],
            [-80.831778930999974, 35.019446634000076],
            [-80.831611982999959, 35.020386030000054],
            [-80.831520072999979, 35.021332397000037],
            [-80.831503598999973, 35.022281681000038],
            [-80.831562632999976, 35.023229819000051],
            [-80.831591903999936, 35.023488390000068],
            [-80.831786320999981, 35.025053957000068],
            [-80.831891347999942, 35.025738320000073],
            [-80.832100331999982, 35.026672007000059],
            [-80.832271275999972, 35.027228428000058],
            [-80.83212436499997, 35.027399812000056],
            [-80.831333996999945, 35.028366049000056],
            [-80.831124018999958, 35.028628850000075],
            [-80.830532714999947, 35.029444175000037],
            [-80.830007492999982, 35.030289563000053],
            [-80.829680896999946, 35.030896254000027],
            [-80.829292094999971, 35.031665539000073],
            [-80.829161799999952, 35.031930678000037],
            [-80.828775195999981, 35.032825220000063],
            [-80.82846054099997, 35.03373864200006],
            [-80.828219184999966, 35.034667035000041],
            [-80.828052164999974, 35.035606424000036],
            [-80.827960196999982, 35.036552787000062],
            [-80.827950983999983, 35.036739301000068],
            [-80.827913284999966, 35.037646398000049],
            [-80.82790597799999, 35.038409166000065],
            [-80.827964981999969, 35.039357305000067],
            [-80.828099260999977, 35.040300240000079],
            [-80.828115772999979, 35.040388666000069],
            [-80.828378721999968, 35.041768326000067],
            [-80.828571192999959, 35.042613592000066],
            [-80.828760357999954, 35.043256116000066],
            [-80.830730366999944, 35.049337620000074],
            [-80.830513981999957, 35.049304213000028],
            [-80.830380921999961, 35.049284104000037],
            [-80.829238606999979, 35.049146746000076],
            [-80.828087815999936, 35.049071110000057],
            [-80.826933475999965, 35.049057520000076],
            [-80.82565750699996, 35.049076826000032],
            [-80.825074893999954, 35.049093559000028],
            [-80.818061299999954, 35.04939025200008],
            [-80.817866791999961, 35.049399361000042],
            [-80.813939785999935, 35.049601076000044],
            [-80.812061734999986, 35.04969507900006],
            [-80.808766419999984, 35.049603818000037],
            [-80.807755540999949, 35.04954348900003],
            [-80.777026004999982, 35.046063966000077],
            [-80.776606213999969, 35.046020588000033],
            [-80.775455515999965, 35.045944450000036],
            [-80.774301227999956, 35.045930357000032],
            [-80.773166281999977, 35.045977139000058],
            [-80.771370444999945, 35.046098931000074],
            [-80.771352453999953, 35.046100159000048],
            [-80.770205794999981, 35.046210052000049],
            [-80.769070326999952, 35.046381374000077],
            [-80.767950909999968, 35.04661339300003],
            [-80.766852333999964, 35.046905113000037],
            [-80.766624958999955, 35.046973775000026],
            [-80.765529713999967, 35.047311539000077],
            [-80.765143491999936, 35.047401190000073],
            [-80.765067536999936, 35.047418973000049],
            [-80.763968938999938, 35.047710667000047],
            [-80.762895883999988, 35.048060816000032],
            [-80.761852961999978, 35.048467921000054],
            [-80.760844640999949, 35.048930240000061],
            [-80.759875233999935, 35.049445794000064],
            [-80.758948892999967, 35.050012376000041],
            [-80.758069582999951, 35.050627560000066],
            [-80.757241070999953, 35.051288715000055],
            [-80.756466901999943, 35.051993008000068],
            [-80.755750391999982, 35.052737428000057],
            [-80.755094610999947, 35.053518784000062],
            [-80.754502366999986, 35.054333735000057],
            [-80.753976197999975, 35.055178789000024],
            [-80.753518357999951, 35.056050330000062],
            [-80.753130810999949, 35.056944627000064],
            [-80.752815216999977, 35.057857850000062],
            [-80.752572928999939, 35.05878608900008],
            [-80.752404988999956, 35.059725370000024],
            [-80.752312115999985, 35.060671673000058],
            [-80.752294713999959, 35.061620943000037],
            [-80.752343445999941, 35.062472310000032],
            [-80.752379284999961, 35.062857210000061],
            [-80.752425435999953, 35.065006531000051],
            [-80.752483581999968, 35.065954704000035],
            [-80.752551138999934, 35.066497798000057],
            [-80.752754881999977, 35.067878461000078],
            [-80.752820775999965, 35.068278384000052],
            [-80.753028964999942, 35.069212203000063],
            [-80.753272009999989, 35.07001938500008],
            [-80.753705783999976, 35.071301283000025],
            [-80.753744776999952, 35.071414726000057],
            [-80.754099461999942, 35.072318213000074],
            [-80.754525273999946, 35.073200691000068],
            [-80.755020388999981, 35.074058384000068],
            [-80.755259646999946, 35.074411223000027],
            [-80.755238115999987, 35.074644310000053],
            [-80.755100647999939, 35.075243766000028],
            [-80.755076084999985, 35.075353176000078],
            [-80.75494268999995, 35.076059468000039],
            [-80.754738308999947, 35.077350636000062],
            [-80.754703756999959, 35.077583627000024],
            [-80.75461089099997, 35.078529929000069],
            [-80.754593510999939, 35.079479197000069],
            [-80.754628320999984, 35.080168877000062],
            [-80.75470788299998, 35.081169652000028],
            [-80.754731254999967, 35.08142814200005],
            [-80.75486475699995, 35.082371154000043],
            [-80.754914578999944, 35.082628914000054],
            [-80.755109166999944, 35.083580306000044],
            [-80.755267596999943, 35.08425636100003],
            [-80.755344934999982, 35.084535531000029],
            [-80.756473573999983, 35.088431419000074],
            [-80.756643867999969, 35.088972713000032],
            [-80.757821411999942, 35.092438479000066],
            [-80.757855913999947, 35.092538631000025],
            [-80.757893778999971, 35.092645515000072],
            [-80.759681910999973, 35.097621387000061],
            [-80.761302993999948, 35.102150293000079],
            [-80.761399399999959, 35.102807630000029],
            [-80.761467219999986, 35.10322014500008],
            [-80.761483672999987, 35.103308578000053],
            [-80.761558848999982, 35.103704450000066],
            [-80.76155913499997, 35.103717711000058],
            [-80.761551083999962, 35.104037339000058],
            [-80.761363469999935, 35.105443253000033],
            [-80.761159083999985, 35.106659269000033],
            [-80.760919915999978, 35.107933845000048],
            [-80.760826604999977, 35.108506630000079],
            [-80.76075569599999, 35.109144608000065],
            [-80.760663290999958, 35.11024330500004],
            [-80.760641368999984, 35.110551631000078],
            [-80.760624052999958, 35.111500897000042],
            [-80.760641555999939, 35.11231172600003],
            [-80.760699830999954, 35.113259890000052],
            [-80.760833453999965, 35.114202892000037],
            [-80.76099039199994, 35.114935349000064],
            [-80.761374332999935, 35.116492368000024],
            [-80.762623595999969, 35.122663250000073],
            [-80.762655565999978, 35.122816105000027],
            [-80.763388986999985, 35.126213675000031],
            [-80.76356453599999, 35.128417580000075],
            [-80.763587950999977, 35.128676072000076],
            [-80.763599195999973, 35.128782131000037],
            [-80.76410464199995, 35.133354989000054],
            [-80.764227089999963, 35.134191926000028],
            [-80.764231433999953, 35.134215622000056],
            [-80.764494636999984, 35.135643101000028],
            [-80.764698786999986, 35.136553197000069],
            [-80.764981186999989, 35.137473787000033],
            [-80.765234034999935, 35.138136933000055],
            [-80.765683844999955, 35.139224578000039],
            [-80.766505457999983, 35.141308274000039],
            [-80.766577995999967, 35.141488214000049],
            [-80.768356014999938, 35.145803886000067],
            [-80.768465069999934, 35.146060625000075],
            [-80.76880668199999, 35.146780429000046],
            [-80.769170618999965, 35.147490763000064],
            [-80.769255360999978, 35.147653379000076],
            [-80.76975108299996, 35.148511003000067],
            [-80.770231411999987, 35.149226121000027],
            [-80.771010157999967, 35.150312058000054],
            [-80.771092794999959, 35.150426094000068],
            [-80.771643781999956, 35.151131598000063],
            [-80.77301573799997, 35.152781354000069],
            [-80.773092565999946, 35.152872980000041],
            [-80.773731776999966, 35.153582042000039],
            [-80.775499326999977, 35.155422867000027],
            [-80.776912701999947, 35.157202260000076],
            [-80.777142780999952, 35.157765594000068],
            [-80.777339413999982, 35.15819342900005],
            [-80.777704516999961, 35.158951431000048],
            [-80.777934394999988, 35.159405984000045],
            [-80.778430279999952, 35.16026357100003],
            [-80.778993412999966, 35.161092684000039],
            [-80.779621383999938, 35.161889773000041],
            [-80.779901311999936, 35.162211365000076],
            [-80.780765561999942, 35.163176168000064],
            [-80.782293251999988, 35.164986689000045],
            [-80.782303784999954, 35.164999158000057],
            [-80.782993956999974, 35.165760792000071],
            [-80.783743324999989, 35.166483725000035],
            [-80.783763628999964, 35.166502029000071],
            [-80.784063555999978, 35.166771964000077],
            [-80.785000927999988, 35.16832000800008],
            [-80.78737569599997, 35.172395649000066],
            [-80.788126210999962, 35.173778423000044],
            [-80.788561528999935, 35.174522727000067],
            [-80.789124862999984, 35.175351791000026],
            [-80.789742517999969, 35.176136371000041],
            [-80.790219805999982, 35.176701832000049],
            [-80.790230331999965, 35.176714287000038],
            [-80.790920676999974, 35.177475875000027],
            [-80.791670223999972, 35.178198757000075],
            [-80.792311635999965, 35.178748162000034],
            [-80.794560062999949, 35.18057552700003],
            [-80.794724196999937, 35.180707197000061],
            [-80.795582313999944, 35.181343539000068],
            [-80.795799701999954, 35.181492004000063],
            [-80.801979291999942, 35.185643760000062],
            [-80.802828060999957, 35.186226511000029],
            [-80.803736835999985, 35.188439137000046],
            [-80.803852587999984, 35.188711952000062],
            [-80.806183471999987, 35.194033990000037],
            [-80.806989893999969, 35.195990503000075],
            [-80.807101707999948, 35.196253435000074],
            [-80.807528740999942, 35.19713571300008],
            [-80.808025166999983, 35.197993174000032],
            [-80.808053295999969, 35.198035090000076],
            [-80.80790288999998, 35.19866107100006],
            [-80.807003354999949, 35.201813336000043],
            [-80.806817282999987, 35.202551148000055],
            [-80.806649671999935, 35.203490487000067],
            [-80.806603894999967, 35.203866135000055],
            [-80.806411644999969, 35.205682011000079],
            [-80.806365012999947, 35.206252690000042],
            [-80.806348208999964, 35.207201950000069],
            [-80.806407084999989, 35.208150078000074],
            [-80.806524578999984, 35.208999066000047],
            [-80.806657726999958, 35.209760448000054],
            [-80.80667453999996, 35.209854399000051],
            [-80.806883707999987, 35.210788109000077],
            [-80.807166842999948, 35.211708591000047],
            [-80.807522731999939, 35.212611903000038],
            [-80.807949854999947, 35.21349417700003],
            [-80.808446384999968, 35.214351634000025],
            [-80.808746523999957, 35.214808724000079],
            [-80.809167111999955, 35.215422671000056],
            [-80.809430788999975, 35.215794548000076],
            [-80.809692502999951, 35.216140300000063],
            [-80.810171224999976, 35.216753375000053],
            [-80.81053820599999, 35.217204545000072],
            [-80.81122908399999, 35.217966012000034],
            [-80.811979183999938, 35.218688764000035],
            [-80.812785288999976, 35.219369700000072],
            [-80.81467233799998, 35.220862668000052],
            [-80.814793803999976, 35.221404570000061],
            [-80.815077064999969, 35.222325032000072],
            [-80.815433087999963, 35.223228319000043],
            [-80.815860351999959, 35.224110564000057],
            [-80.816357029999949, 35.22496798700007],
            [-80.816920996999954, 35.225796916000036],
            [-80.817549837999934, 35.226593800000046],
            [-80.818240860999936, 35.227355227000032],
            [-80.818991108999967, 35.228077934000055],
            [-80.819797369999947, 35.228758825000057],
            [-80.820656188999976, 35.229394985000056],
            [-80.821278169999971, 35.229806841000027],
            [-80.821547064999947, 35.229976803000056],
            [-80.822969609999973, 35.230964327000038],
            [-80.830410360999963, 35.237304247000054],
            [-80.830876382999975, 35.23768674300004],
            [-80.831735382999966, 35.238322823000033],
            [-80.832643264999945, 35.238911441000027],
            [-80.833596138999951, 35.239450076000026],
            [-80.834589924999989, 35.239936420000049],
            [-80.835620363999965, 35.240368390000071],
            [-80.836683044999972, 35.240744135000057],
            [-80.837773412999979, 35.241062045000035],
            [-80.838886797999976, 35.241320758000029],
            [-80.84001842899994, 35.241519166000046],
            [-80.841163459999962, 35.241656419000037],
            [-80.842316983999979, 35.241731929000025],
            [-80.84347405799997, 35.241745371000036],
            [-80.844629724999947, 35.241696690000026],
            [-80.845779035999954, 35.241586092000034],
            [-80.846917063999967, 35.241414054000074],
            [-80.848038932999941, 35.241181309000069],
            [-80.849139838999974, 35.240888858000062],
            [-80.85021506399994, 35.240537951000078],
            [-80.851260001999947, 35.240130093000062],
            [-80.85227017699998, 35.239667031000067],
            [-80.853241262999973, 35.239150748000043],
            [-80.854169098999989, 35.238583457000061],
            [-80.855049711999982, 35.23796758800006],
            [-80.855879327999958, 35.237305779000053],
            [-80.856654398999979, 35.23660086600006],
            [-80.857371603999979, 35.235855866000065],
            [-80.85802787099999, 35.235073973000056],
            [-80.858620392999967, 35.234258535000038],
            [-80.859146632999966, 35.233413045000077],
            [-80.859604338999986, 35.232541124000079],
            [-80.859991552999986, 35.23164650700005],
            [-80.860306617999981, 35.230733026000053],
            [-80.860548189999975, 35.22980459200005],
            [-80.86071523399994, 35.22886518200005],
            [-80.860807038999951, 35.227918818000035],
            [-80.860823211999957, 35.22696955400005],
            [-80.860763689999942, 35.22602145500008],
            [-80.860628726999948, 35.22507857800008],
            [-80.860418906999939, 35.224144964000061],
            [-80.860135126999978, 35.223224609000056],
            [-80.859778608999989, 35.222321453000063],
            [-80.859350877999987, 35.221439363000059],
            [-80.85885376899995, 35.22058211600006],
            [-80.858289411999976, 35.219753382000079],
            [-80.857660224999961, 35.218956709000054],
            [-80.856968904999974, 35.218195506000029],
            [-80.856218410999986, 35.217473034000079],
            [-80.855877979999946, 35.217174785000054],
            [-80.849192529999982, 35.21147998500004],
            [-80.848810967999952, 35.21069275800005],
            [-80.848314035999977, 35.209835466000072],
            [-80.847749859999965, 35.209006682000052],
            [-80.847120859999961, 35.208209952000061],
            [-80.847062024999957, 35.208140945000025],
            [-80.847044315999938, 35.208120302000054],
            [-80.846467435999955, 35.20721582300007],
            [-80.845948132999979, 35.206457803000035],
            [-80.845792575999951, 35.206249842000034],
            [-80.845253378999985, 35.205542405000074],
            [-80.844779980999988, 35.20495362500003],
            [-80.844088899999974, 35.20419234700006],
            [-80.843338648999975, 35.203469793000068],
            [-80.842956250999976, 35.203143204000071],
            [-80.843657524999969, 35.20145744000007],
            [-80.843874955999979, 35.200966387000051],
            [-80.844102695999936, 35.200418202000037],
            [-80.844417818999943, 35.199504758000046],
            [-80.844659478999972, 35.198576351000042],
            [-80.844826641999987, 35.197636958000032],
            [-80.844918595999957, 35.196690602000047],
            [-80.844934947999946, 35.195741335000037],
            [-80.844875633999948, 35.19479322400008],
            [-80.844740906999959, 35.193850325000028],
            [-80.844531348999965, 35.192916679000064],
            [-80.844247856999971, 35.191996282000048],
            [-80.843891650999979, 35.191093075000026],
            [-80.843464255999947, 35.190210925000031],
            [-80.84296750499999, 35.189353608000033],
            [-80.842403525999941, 35.188524796000024],
            [-80.841774735999934, 35.187728037000056],
            [-80.841448209999953, 35.187355260000061],
            [-80.841424118999953, 35.187328654000055],
            [-80.841403808999985, 35.18730000000005],
            [-80.841267337999966, 35.18711077200004],
            [-80.841149044999952, 35.186951865000026],
            [-80.83994303299994, 35.185354874000041],
            [-80.839887792999946, 35.185277638000059],
            [-80.839608315999953, 35.184600241000055],
            [-80.839498150999987, 35.184341302000064],
            [-80.837168382999948, 35.179026774000079],
            [-80.835626193999985, 35.175275609000039],
            [-80.83550861599997, 35.174998860000073],
            [-80.835375905999967, 35.174706266000044],
            [-80.835111212999948, 35.174141336000048],
            [-80.835045975999947, 35.173966182000072],
            [-80.834772903999976, 35.173292428000025],
            [-80.834345701999951, 35.172410244000048],
            [-80.833849154999939, 35.171552887000075],
            [-80.833804344999976, 35.171482105000052],
            [-80.833503186999963, 35.171009562000052],
            [-80.832984238999984, 35.170251486000041],
            [-80.832355681999957, 35.169454675000054],
            [-80.831665017999967, 35.16869332300007],
            [-80.83091520399995, 35.167970688000025],
            [-80.830700648999937, 35.167780552000067],
            [-80.830108037999935, 35.167264704000047],
            [-80.829516847999969, 35.166774014000055],
            [-80.828658619999942, 35.166137911000078],
            [-80.828586962999964, 35.166088462000062],
            [-80.824574402999986, 35.163334142000053],
            [-80.824428662999935, 35.163235180000072],
            [-80.819138638999959, 35.15968177600007],
            [-80.816813421999939, 35.155693830000075],
            [-80.816695751999987, 35.155495877000078],
            [-80.815701578999949, 35.153855078000049],
            [-80.815478131999953, 35.153447622000044],
            [-80.815069148999953, 35.152751916000057],
            [-80.814653958999941, 35.152129066000043],
            [-80.814054134999935, 35.151279272000068],
            [-80.81390588499994, 35.151073174000032],
            [-80.813277661999962, 35.150276262000034],
            [-80.812587337999958, 35.149514799000031],
            [-80.81246017899997, 35.149385248000044],
            [-80.811806568999941, 35.148727093000048],
            [-80.811184268999966, 35.148133886000039],
            [-80.811163963999945, 35.148115586000074],
            [-80.810656968999979, 35.147659433000058],
            [-80.81020902399996, 35.147128777000034],
            [-80.810196975999986, 35.147101737000071],
            [-80.809769319999987, 35.146153920000074],
            [-80.809399648999943, 35.145400093000035],
            [-80.808903528999963, 35.144542631000036],
            [-80.808340205999968, 35.143713656000045],
            [-80.808095883999954, 35.143389931000058],
            [-80.806959863999964, 35.141927880000026],
            [-80.806861509999976, 35.141802719000054],
            [-80.804650825999943, 35.139020800000026],
            [-80.804365422999979, 35.138672736000046],
            [-80.803726061999953, 35.13796383500005],
            [-80.80184178799999, 35.136002165000036],
            [-80.801380852999955, 35.135448137000026],
            [-80.80007126199996, 35.132272568000076],
            [-80.79924562299999, 35.130180841000026],
            [-80.799208572999987, 35.130089204000058],
            [-80.798792019999951, 35.126335205000032],
            [-80.798566199999982, 35.123515435000058],
            [-80.798542674999965, 35.123256950000041],
            [-80.798408601999938, 35.122313991000055],
            [-80.798331868999981, 35.121929321000039],
            [-80.797443165999937, 35.117820565000045],
            [-80.796155923999947, 35.111475428000062],
            [-80.796061384999973, 35.111055008000051],
            [-80.796078544999943, 35.110963350000077],
            [-80.796118631999946, 35.110737408000034],
            [-80.796375231999946, 35.10920683300003],
            [-80.796428208999941, 35.108859964000033],
            [-80.796435877999954, 35.108803339000076],
            [-80.79673474599997, 35.106556467000075],
            [-80.796819486999937, 35.105666761000066],
            [-80.796835619999968, 35.105275180000035],
            [-80.796866341999987, 35.104034231000071],
            [-80.796867101999965, 35.103476541000077],
            [-80.796838734999938, 35.102188759000057],
            [-80.79678004799996, 35.101240612000026],
            [-80.796646030999966, 35.100297648000037],
            [-80.796629539999969, 35.100209220000067],
            [-80.796414493999976, 35.099079355000072],
            [-80.796178327999939, 35.097473745000059],
            [-80.796110337999949, 35.097061250000024],
            [-80.795901584999967, 35.096127507000062],
            [-80.795618977999936, 35.095206985000061],
            [-80.795586589999971, 35.09511550700006],
            [-80.793545712999958, 35.089420481000047],
            [-80.793540185999973, 35.089405088000035],
            [-80.791784557999961, 35.084525303000078],
            [-80.790732568999942, 35.081432866000057],
            [-80.790099407999946, 35.079250490000049],
            [-80.790105013999948, 35.079225996000048],
            [-80.790129530999934, 35.079116580000061],
            [-80.790297075999945, 35.078177248000031],
            [-80.790359164999984, 35.077636750000067],
            [-80.790371061999963, 35.077507359000037],
            [-80.790410260999977, 35.077416475000064],
            [-80.790587868999978, 35.076983114000029],
            [-80.790618655999936, 35.076893920000032],
            [-80.803508077999936, 35.078352351000035],
            [-80.803928049999968, 35.078395635000049],
            [-80.804635117999965, 35.078449616000057],
            [-80.806552934999957, 35.078564072000063],
            [-80.806997050999939, 35.078585951000036],
            [-80.807235960999947, 35.078593904000059],
            [-80.811707718999969, 35.078717736000044],
            [-80.812623563999978, 35.078723530000048],
            [-80.813373466999963, 35.078699150000034],
            [-80.816098272999966, 35.078562774000034],
            [-80.816125774999989, 35.078561380000053],
            [-80.819970660999957, 35.078363885000044],
            [-80.825817713999982, 35.078116563000037],
            [-80.828528056999971, 35.07862115000006],
            [-80.831191715999978, 35.079137418000073],
            [-80.832118312999967, 35.079295566000042],
            [-80.833261053999934, 35.079432896000071],
            [-80.834212731999969, 35.079499841000029],
            [-80.836478736999936, 35.079608533000055],
            [-80.836678283999959, 35.079617171000052],
            [-80.837298441999963, 35.079632146000051],
            [-80.841129925999951, 35.079669208000041],
            [-80.84166454199999, 35.07966771200006],
            [-80.842817914999955, 35.079619047000051],
            [-80.843336016999956, 35.07957684400003],
            [-80.844552282999985, 35.079462847000059],
            [-80.845181208999975, 35.079394461000049],
            [-80.846316975999969, 35.079222424000079],
            [-80.847436616999971, 35.078989680000063],
            [-80.848535336999987, 35.078697227000077],
            [-80.849608426999964, 35.078346318000058],
            [-80.850651291999952, 35.077938457000073],
            [-80.850737395999943, 35.077901646000043],
            [-80.851542083999959, 35.077555326000038],
            [-80.852464142999963, 35.07712906200004],
            [-80.853433292999966, 35.076612767000029],
            [-80.854359278999937, 35.076045462000025],
            [-80.855138742999941, 35.07550341700005],
            [-80.85639450299999, 35.074577591000036],
            [-80.856493893999982, 35.074503750000076],
            [-80.857321836999972, 35.073841916000049],
            [-80.858095342999945, 35.073136978000036],
            [-80.858629761999964, 35.07259038400008],
            [-80.860221519999982, 35.070879202000071],
            [-80.861922727999968, 35.06916198700003],
            [-80.862439136999967, 35.068614506000074],
            [-80.863094010999987, 35.067832567000039],
            [-80.863483212999938, 35.067309651000073],
            [-80.864270309999938, 35.066200123000044],
            [-80.864472359999979, 35.06590755700006],
            [-80.86499745399999, 35.065062023000053],
            [-80.865067488999955, 35.064937991000079],
            [-80.865792169999963, 35.063638567000055],
            [-80.866178821999938, 35.062890634000041],
            [-80.866565158999947, 35.061995975000059],
            [-80.866879495999967, 35.061082456000065],
            [-80.866973334999955, 35.060754988000042],
            [-80.867455012999983, 35.058982190000052],
            [-80.867602162999958, 35.058381189000045],
            [-80.867756271999951, 35.057530618000044],
            [-80.867956632999949, 35.05614386700006],
            [-80.867969133999964, 35.056054997000047],
            [-80.868060660999959, 35.055108605000044],
            [-80.868076715999962, 35.054159319000064],
            [-80.868017234999968, 35.053211202000057],
            [-80.867882473999941, 35.052268312000024],
            [-80.86767301499998, 35.051334689000043],
            [-80.867584654999973, 35.051019138000072],
            [-80.867081381999981, 35.049309286000039],
            [-80.867067118999955, 35.04926118700007],
            [-80.865308015999972, 35.043372079000051],
            [-80.86522116499998, 35.043093266000028],
            [-80.864529379999965, 35.040960617000053],
            [-80.864660429999958, 35.040831863000051],
            [-80.865328113999965, 35.040133490000073],
            [-80.865982731999964, 35.039351533000058],
            [-80.86657375499999, 35.038536036000039],
            [-80.867098650999935, 35.037690490000045],
            [-80.86755517499995, 35.036818516000039],
            [-80.867941372999951, 35.035923851000064],
            [-80.867970561999982, 35.035847775000036],
            [-80.868244133999951, 35.035127856000031],
            [-80.868456811999977, 35.034522269000036],
            [-80.868781811999952, 35.033515280000074],
            [-80.868854157999976, 35.033283417000064],
            [-80.869095048999952, 35.03235494300003],
            [-80.869261589999951, 35.031415497000069],
            [-80.869352142999958, 35.030485314000032],
            [-80.869393746999947, 35.029768986000079],
            [-80.869394677999935, 35.029752775000077],
            [-80.869410712999979, 35.028803486000072],
            [-80.869351234999954, 35.02785536600004],
            [-80.869216500999983, 35.026912476000064],
            [-80.869007090999958, 35.025978853000026],
            [-80.868755852999982, 35.025150824000036],
            [-80.868382863999955, 35.024059217000058],
            [-80.868350914999951, 35.023966888000075],
            [-80.867995174999976, 35.023063733000072],
            [-80.867862594999963, 35.022771170000055],
            [-80.86737476199994, 35.021729051000079],
            [-80.867327097999976, 35.021628348000036],
            [-80.866629142999955, 35.020169797000051],
            [-80.866575627999964, 35.019850204000079],
            [-80.866366266999989, 35.018916575000048],
            [-80.866083132999961, 35.017996211000025],
            [-80.865727444999948, 35.017093049000039],
            [-80.865300723999951, 35.016210958000045],
            [-80.864804801999981, 35.015353713000025],
            [-80.86424180299997, 35.014524986000026],
            [-80.863614138999935, 35.013728323000066],
            [-80.862924500999952, 35.012967135000054],
            [-80.862175839999964, 35.012244681000027],
            [-80.861371362999989, 35.011564054000075],
            [-80.860514517999945, 35.010928166000042],
            [-80.859608968999964, 35.010339740000063],
            [-80.858658595999941, 35.009801295000045],
            [-80.857667467999988, 35.009315134000076],
            [-80.856639826999981, 35.008883339000079],
            [-80.855580070999963, 35.008507758000064],
            [-80.854492737999976, 35.00819000000007],
            [-80.853382481999972, 35.007931423000059],
            [-80.852254053999957, 35.007733133000045],
            [-80.851112284999942, 35.007595982000055],
            [-80.849962058999949, 35.007520553000063],
            [-80.848808301999952, 35.007507173000079]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 107,
        "SolutionID": "PI-10:D",
        "Shape_Length": 0.70951567814680416,
        "Shape_Area": 0.011030280700117539
      }
    },
    {
      "type": "Feature",
      "id": 108,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.011593799999957, 35.076773744000036],
            [-81.010440569999957, 35.076824020000061],
            [-81.009997832999943, 35.076859915000057],
            [-81.008405319999952, 35.07700569800005],
            [-81.007701287999964, 35.077081959000054],
            [-81.006565892999959, 35.077255513000068],
            [-81.00544673099995, 35.07748973300005],
            [-81.004348590999939, 35.077783612000076],
            [-81.004149187999985, 35.077844093000067],
            [-81.002925104999974, 35.078222254000025],
            [-81.002052082999967, 35.078514049000034],
            [-81.001009968999938, 35.078923218000057],
            [-81.000923933999957, 35.078960134000056],
            [-80.999775265999972, 35.079456297000036],
            [-80.998853948999965, 35.079883686000073],
            [-80.997885680999957, 35.080401148000078],
            [-80.996960643999955, 35.080969552000056],
            [-80.996231111999975, 35.081476390000034],
            [-80.995233260999953, 35.082208581000032],
            [-80.995084942999938, 35.08231865700003],
            [-80.994258031999948, 35.082981434000033],
            [-80.993751406999934, 35.083444370000052],
            [-80.993609267999943, 35.083511530000067],
            [-80.991014664999966, 35.084689291000075],
            [-80.990416904999961, 35.084973299000069],
            [-80.990164127999947, 35.08510128000006],
            [-80.982629146999955, 35.088988028000074],
            [-80.976456814999949, 35.091851722000058],
            [-80.976004810999939, 35.09206873800008],
            [-80.975527671999942, 35.092314308000027],
            [-80.969294866999974, 35.095636726000066],
            [-80.968803406999939, 35.09590840900006],
            [-80.96810250499999, 35.096331529000054],
            [-80.964068861999976, 35.098894458000075],
            [-80.963167190999968, 35.099402693000059],
            [-80.961682229999951, 35.099824356000056],
            [-80.957882845999961, 35.100894333000042],
            [-80.957602080999948, 35.100975575000064],
            [-80.956529157999967, 35.101327443000059],
            [-80.95548652399998, 35.101736218000042],
            [-80.954930761999947, 35.101983216000065],
            [-80.945599627999968, 35.106308741000078],
            [-80.945367981999937, 35.106418013000052],
            [-80.939196915999958, 35.109379997000076],
            [-80.938976378999939, 35.109487613000056],
            [-80.938022531999934, 35.109995928000046],
            [-80.93565947999997, 35.111345912000047],
            [-80.935644327999967, 35.111354577000043],
            [-80.934718505999967, 35.111922503000073],
            [-80.933839855999963, 35.112538963000077],
            [-80.933388695999952, 35.112889209000059],
            [-80.932177882999952, 35.113862831000063],
            [-80.931801318999987, 35.114174935000051],
            [-80.93102806099995, 35.114880342000049],
            [-80.930828826999971, 35.115078030000063],
            [-80.930049058999941, 35.115865817000042],
            [-80.92953281399997, 35.116413575000024],
            [-80.928878186999953, 35.117195869000057],
            [-80.92828721799998, 35.11801166500004],
            [-80.927762438999935, 35.118857469000034],
            [-80.927475800999957, 35.119386602000077],
            [-80.926920679999967, 35.12046898400007],
            [-80.926750974999948, 35.120812043000058],
            [-80.926469065999981, 35.121446562000074],
            [-80.924994364999975, 35.125021896000078],
            [-80.924010390999968, 35.127311716000065],
            [-80.923807305999958, 35.127778680000063],
            [-80.923788207999962, 35.127805738000063],
            [-80.923667725999962, 35.127934505000042],
            [-80.923626621999972, 35.127973520000069],
            [-80.922392883999976, 35.128830175000076],
            [-80.921893516999944, 35.129190354000059],
            [-80.921549265999943, 35.12945523500008],
            [-80.916774063999981, 35.133228779000035],
            [-80.91629028899996, 35.133626151000044],
            [-80.915995521999946, 35.133884054000077],
            [-80.914573468999947, 35.13515903800004],
            [-80.914094643999988, 35.135606435000057],
            [-80.91337883999995, 35.13635178100003],
            [-80.91311421599994, 35.136654751000037],
            [-80.912548999999956, 35.137319740000066],
            [-80.912158674999944, 35.137798973000031],
            [-80.91156738899997, 35.13861468500005],
            [-80.911083816999962, 35.139388291000046],
            [-80.910455764999938, 35.140471742000045],
            [-80.910414250999963, 35.14054386600003],
            [-80.909957604999988, 35.141415991000031],
            [-80.909690495999939, 35.142014278000033],
            [-80.908865600999945, 35.143995431000064],
            [-80.90881217499998, 35.144125756000051],
            [-80.906328630999951, 35.150279725000075],
            [-80.905338755999935, 35.150878173000024],
            [-80.904986182999949, 35.151096960000075],
            [-80.901411801999984, 35.153373131000023],
            [-80.896476619999987, 35.156446540000047],
            [-80.89643297799995, 35.156473804000029],
            [-80.89436004199996, 35.15777294600008],
            [-80.887685014999988, 35.161864638000054],
            [-80.885597555999937, 35.163020647000053],
            [-80.885382677999985, 35.163141543000052],
            [-80.885144863999983, 35.163279872000032],
            [-80.884213479999971, 35.163831151000068],
            [-80.883524522999949, 35.164260353000032],
            [-80.88264492199994, 35.164876436000043],
            [-80.881816258999947, 35.165538436000077],
            [-80.881558482999935, 35.165763028000072],
            [-80.879921654999976, 35.167219843000055],
            [-80.879405233999989, 35.167700326000045],
            [-80.879298598999981, 35.167805068000064],
            [-80.87667130899996, 35.170410382000057],
            [-80.876552127999958, 35.170529848000058],
            [-80.87541650199995, 35.171680621000064],
            [-80.874925860999952, 35.172201532000031],
            [-80.874270260999936, 35.172983524000074],
            [-80.873678340999959, 35.173799046000056],
            [-80.873539471999948, 35.174009525000031],
            [-80.873188760999938, 35.174551715000064],
            [-80.872801919999972, 35.175186795000059],
            [-80.872344676999944, 35.176058773000079],
            [-80.871957862999977, 35.176953437000066],
            [-80.871643135999989, 35.177866957000049],
            [-80.87140184499998, 35.178795420000029],
            [-80.871235026999955, 35.179734852000024],
            [-80.871143968999945, 35.180671248000067],
            [-80.871143171999961, 35.180685083000071],
            [-80.871137841999939, 35.180698226000061],
            [-80.871066866999968, 35.180877127000031],
            [-80.870752114999959, 35.181790644000046],
            [-80.870510801999956, 35.182719104000057],
            [-80.87034396599995, 35.183658535000063],
            [-80.870252320999953, 35.184604912000054],
            [-80.870236265999949, 35.185554184000068],
            [-80.87029586899996, 35.186502284000028],
            [-80.870430879999958, 35.187445155000034],
            [-80.870640719999983, 35.188378758000056],
            [-80.870924496999976, 35.189299093000045],
            [-80.87128099499995, 35.19020222100005],
            [-80.871708690999981, 35.191084272000069],
            [-80.872205754999982, 35.191941471000064],
            [-80.872770060999983, 35.192770143000075],
            [-80.873399192999955, 35.193566741000041],
            [-80.874090457999955, 35.194327852000072],
            [-80.874840896999956, 35.195050216000027],
            [-80.87564729799999, 35.195730738000066],
            [-80.876506204999941, 35.196366505000071],
            [-80.877413942999965, 35.196954791000053],
            [-80.878366621999987, 35.197493076000057],
            [-80.879360162999944, 35.197979056000065],
            [-80.880390308999949, 35.198410648000049],
            [-80.881452648999982, 35.198786002000077],
            [-80.882542629999989, 35.199103510000043],
            [-80.883655583999939, 35.199361813000053],
            [-80.884786740999971, 35.199559804000046],
            [-80.885931256999982, 35.199696634000077],
            [-80.887084228999981, 35.199771717000033],
            [-80.888240714999938, 35.199784732000069],
            [-80.889395759999957, 35.199735623000038],
            [-80.890544417999934, 35.19962459900006],
            [-80.891681763999941, 35.199452138000026],
            [-80.892802927999981, 35.199218977000044],
            [-80.893903105999982, 35.198926117000042],
            [-80.894977583999946, 35.198574810000025],
            [-80.896021760999986, 35.198166563000029],
            [-80.897031160999973, 35.197703124000043],
            [-80.898001462999957, 35.197186479000038],
            [-80.898928508999973, 35.196618841000031],
            [-80.899808329999985, 35.196002642000053],
            [-80.900637155999959, 35.195340522000038],
            [-80.901411440999937, 35.194635317000063],
            [-80.902127865999944, 35.193890048000071],
            [-80.902783365999937, 35.193107907000069],
            [-80.903375134999976, 35.192292245000033],
            [-80.903900639999961, 35.191446555000027],
            [-80.904357630999982, 35.190574459000061],
            [-80.904665996999938, 35.189876467000033],
            [-80.905149428999948, 35.188688155000079],
            [-80.905199015999983, 35.188593836000052],
            [-80.905427221999958, 35.188138681000055],
            [-80.905813720999959, 35.187243912000042],
            [-80.905936486999963, 35.186887148000039],
            [-80.906218910999939, 35.186606987000062],
            [-80.906242058999965, 35.186586380000051],
            [-80.907796584999971, 35.185725374000071],
            [-80.908011489999978, 35.185604439000031],
            [-80.908486014999937, 35.185323473000039],
            [-80.915575715999978, 35.180976931000032],
            [-80.915725071999987, 35.180884339000045],
            [-80.917850833999978, 35.179551876000062],
            [-80.92284015599995, 35.176444251000078],
            [-80.922990324999944, 35.176349666000078],
            [-80.92646524099996, 35.174136475000068],
            [-80.930440640999961, 35.171732594000048],
            [-80.930989566999983, 35.171386830000074],
            [-80.931868872999985, 35.17077039600008],
            [-80.932235147999961, 35.170488169000066],
            [-80.933233231999964, 35.169696890000068],
            [-80.933695256999954, 35.16931677000008],
            [-80.934469007999951, 35.168611351000038],
            [-80.935184902999936, 35.167865883000047],
            [-80.935839875999989, 35.167083560000037],
            [-80.935849825999981, 35.167070786000068],
            [-80.936272689999953, 35.166527252000037],
            [-80.936815640999953, 35.165780971000061],
            [-80.937306839999962, 35.165057417000071],
            [-80.937345178999976, 35.165000643000042],
            [-80.937870155999974, 35.164154803000031],
            [-80.938326630999939, 35.163282575000039],
            [-80.938712651999936, 35.162387697000042],
            [-80.938741818999972, 35.162311607000049],
            [-80.939515798999935, 35.16027288500004],
            [-80.942320743999971, 35.153315517000067],
            [-80.942327649999982, 35.153298914000061],
            [-80.945631347999949, 35.150687570000059],
            [-80.947483028999955, 35.149401569000077],
            [-80.947982406999984, 35.149041285000067],
            [-80.948810363999939, 35.14837883000007],
            [-80.949436201999958, 35.147815274000038],
            [-80.950714190999975, 35.146601842000052],
            [-80.95086176999996, 35.146459879000076],
            [-80.951362381999957, 35.145948989000033],
            [-80.952495147999969, 35.144737882000072],
            [-80.952710079999974, 35.144503203000056],
            [-80.953364697999973, 35.143720783000049],
            [-80.953770758999951, 35.143173429000058],
            [-80.955175182999938, 35.141182575000073],
            [-80.955360016999975, 35.140914011000064],
            [-80.955884650999963, 35.140068090000057],
            [-80.956340798999975, 35.139195791000077],
            [-80.956527365999989, 35.138786044000028],
            [-80.957284199999947, 35.137044209000067],
            [-80.957306481999979, 35.136992619000068],
            [-80.958337825999934, 35.134590282000033],
            [-80.958410705999938, 35.134417032000044],
            [-80.958747187999961, 35.133600471000079],
            [-80.96306726399996, 35.131526664000035],
            [-80.970868614999972, 35.127910006000036],
            [-80.973152680999988, 35.127266729000041],
            [-80.973194654999986, 35.127254859000061],
            [-80.975645999999983, 35.126558747000047],
            [-80.97588486099994, 35.126489334000041],
            [-80.976146029999938, 35.126409782000053],
            [-80.977154711999958, 35.12609507500008],
            [-80.97796670699995, 35.125822582000069],
            [-80.979009535999978, 35.12541361500007],
            [-80.979504058999964, 35.125194815000043],
            [-80.980580930999963, 35.124700038000071],
            [-80.981094429999985, 35.124454696000043],
            [-80.981951964999951, 35.12400066500004],
            [-80.984458289999964, 35.122587778000025],
            [-80.98456964899998, 35.122524482000074],
            [-80.985270690999982, 35.122101268000051],
            [-80.98912381699995, 35.119652667000025],
            [-80.994287913999983, 35.116899583000077],
            [-81.000345555999957, 35.114088797000079],
            [-81.000797617999979, 35.113871690000053],
            [-81.00105046799996, 35.113743687000067],
            [-81.008516059999977, 35.109892328000058],
            [-81.01081118999997, 35.108850401000041],
            [-81.011074589999964, 35.108728389000078],
            [-81.013298580999958, 35.107677433000049],
            [-81.013633035999987, 35.107515332000048],
            [-81.014581884999984, 35.107008242000063],
            [-81.014603460999979, 35.107016661000046],
            [-81.014691269999958, 35.107050686000036],
            [-81.015753036999968, 35.107424865000041],
            [-81.016842319999967, 35.107741166000039],
            [-81.01795445099998, 35.107998236000071],
            [-81.018437000999938, 35.108090014000027],
            [-81.019967889999975, 35.108362629000055],
            [-81.020615559999953, 35.10846758100007],
            [-81.021377874999985, 35.108564912000077],
            [-81.02379560199995, 35.108829255000046],
            [-81.023975055999983, 35.109434325000052],
            [-81.024171259999946, 35.110038879000058],
            [-81.024529106999978, 35.11094156300004],
            [-81.024958043999959, 35.111823081000068],
            [-81.025179097999967, 35.112221061000071],
            [-81.025861677999956, 35.113402447000055],
            [-81.026138823999986, 35.113861042000053],
            [-81.026602668999942, 35.114549389000047],
            [-81.027279361999945, 35.115492300000028],
            [-81.02738084799995, 35.115631915000051],
            [-81.028010893999976, 35.116427716000032],
            [-81.028702946999942, 35.117187950000073],
            [-81.029454044999966, 35.117909362000034],
            [-81.030260971999951, 35.118588860000045],
            [-81.031371602999968, 35.119464813000036],
            [-81.031788663999976, 35.119783286000029],
            [-81.035338407999973, 35.122407406000036],
            [-81.035401199999967, 35.122453600000028],
            [-81.037471620999952, 35.123969383000031],
            [-81.037851126999954, 35.124239362000026],
            [-81.038037978999967, 35.124366722000048],
            [-81.040548351999973, 35.126053956000078],
            [-81.041269626999963, 35.126513661000047],
            [-81.042222563999985, 35.127050661000055],
            [-81.043216220999966, 35.127535298000055],
            [-81.043884608999974, 35.12782233300004],
            [-81.045571553999935, 35.128509483000073],
            [-81.045933293999951, 35.128652643000066],
            [-81.046995485999958, 35.129026545000045],
            [-81.048085177999951, 35.129342565000059],
            [-81.048177823999936, 35.12936643200004],
            [-81.048835414999985, 35.129534208000052],
            [-81.049855296999965, 35.129767116000039],
            [-81.050729876999981, 35.129924616000039],
            [-81.051750313999946, 35.130086216000052],
            [-81.052006329999983, 35.130125153000051],
            [-81.053150150999954, 35.130260401000044],
            [-81.054302292999978, 35.130333891000078],
            [-81.055457818999969, 35.130345307000027],
            [-81.057199431999948, 35.130315664000079],
            [-81.058353389999979, 35.130264941000064],
            [-81.058479140999964, 35.130255643000055],
            [-81.062464296999963, 35.12994911100003],
            [-81.062619881999979, 35.129936570000041],
            [-81.064435867999975, 35.129783506000024],
            [-81.065736256999969, 35.12994919700003],
            [-81.065858999999989, 35.12996447300003],
            [-81.070014897999954, 35.130469350000055],
            [-81.071797113999935, 35.130785834000051],
            [-81.074342878999971, 35.131421541000066],
            [-81.077184969999962, 35.132303413000045],
            [-81.078099234999968, 35.13256380200005],
            [-81.079211897999983, 35.132820307000031],
            [-81.080342604999942, 35.133016471000076],
            [-81.081486512999959, 35.13315145100006],
            [-81.082638721999956, 35.13322467100005],
            [-81.083794292999983, 35.133235815000035],
            [-81.084948274999988, 35.133184838000034],
            [-81.086095726999986, 35.133071956000038],
            [-81.087231729999985, 35.132897655000079],
            [-81.088351417999945, 35.132662679000077],
            [-81.08944999299996, 35.132368038000038],
            [-81.090522749999934, 35.132014991000062],
            [-81.091565093999975, 35.131605053000044],
            [-81.092572556999983, 35.131139978000078],
            [-81.09354082599998, 35.130621761000043],
            [-81.094465752999952, 35.130052620000072],
            [-81.095343376999949, 35.129434994000064],
            [-81.096169936999956, 35.128771529000062],
            [-81.096941896999965, 35.12806506700008],
            [-81.097655949999989, 35.127318634000062],
            [-81.098309037999968, 35.126535428000068],
            [-81.098898367999936, 35.125718802000051],
            [-81.099421414999938, 35.12487225700005],
            [-81.099875941999983, 35.123999416000061],
            [-81.100260003999949, 35.123104019000039],
            [-81.100571957999989, 35.122189900000023],
            [-81.100810472999967, 35.121260975000041],
            [-81.100974525999959, 35.12032122100004],
            [-81.101063420999935, 35.119374663000031],
            [-81.101076776999946, 35.118425355000056],
            [-81.101014540999984, 35.117477362000045],
            [-81.100876980999942, 35.116534742000056],
            [-81.100664690999963, 35.115601533000074],
            [-81.100378578999937, 35.114681730000029],
            [-81.100019875999976, 35.113779271000055],
            [-81.099590116999934, 35.112898020000046],
            [-81.099091146999967, 35.11204174900007],
            [-81.09852510099995, 35.11121412600005],
            [-81.097894407999945, 35.110418693000042],
            [-81.097201766999945, 35.109658854000031],
            [-81.096450145999938, 35.108937863000051],
            [-81.09564276399999, 35.108258807000027],
            [-81.094783078999967, 35.107624591000047],
            [-81.093874768999967, 35.107037931000036],
            [-81.092921726999975, 35.106501338000044],
            [-81.091928029999963, 35.10601710800006],
            [-81.090897933999941, 35.105587314000047],
            [-81.089835845999971, 35.105213796000044],
            [-81.089660309999942, 35.105158454000048],
            [-81.086273156999937, 35.104107491000036],
            [-81.085359183999969, 35.103847163000069],
            [-81.08516900099994, 35.10379871300006],
            [-81.081370891999939, 35.102850305000061],
            [-81.080448808999961, 35.102642277000029],
            [-81.079966213999967, 35.10255074500003],
            [-81.07690426299996, 35.102007016000073],
            [-81.076256579999949, 35.101902367000037],
            [-81.075756380999962, 35.101835572000027],
            [-81.071085905999951, 35.101268198000071],
            [-81.068671753999979, 35.100960584000063],
            [-81.067905804999953, 35.100877037000032],
            [-81.067198363999978, 35.100824597000042],
            [-81.065599445999965, 35.10073267000007],
            [-81.065155148999963, 35.100711743000033],
            [-81.064000041999975, 35.100700420000067],
            [-81.062846504999982, 35.100751198000069],
            [-81.062565270999983, 35.100773042000071],
            [-81.059250730999963, 35.101052442000025],
            [-81.058921411999961, 35.100811387000078],
            [-81.057643342999938, 35.099866815000041],
            [-81.057329857999946, 35.098274751000076],
            [-81.057142276999969, 35.097467711000036],
            [-81.057112615999984, 35.097359161000043],
            [-81.056633800999975, 35.095637694000061],
            [-81.056377913999938, 35.09482633600004],
            [-81.056019784999989, 35.093923744000051],
            [-81.055590606999942, 35.093042334000074],
            [-81.05509221899996, 35.092185880000045],
            [-81.054851950999989, 35.091818499000055],
            [-81.054173634999984, 35.090816123000025],
            [-81.053848449999975, 35.090355671000054],
            [-81.053218352999977, 35.089560002000042],
            [-81.052526305999947, 35.08879990500003],
            [-81.052398855999968, 35.088670601000047],
            [-81.051580035999962, 35.08784952600007],
            [-81.050956463999967, 35.087257555000065],
            [-81.050149673999954, 35.086578194000026],
            [-81.049290570999972, 35.085943652000026],
            [-81.049049841999988, 35.085780186000079],
            [-81.04809051899997, 35.085140397000032],
            [-81.04742351799996, 35.084716855000067],
            [-81.046471040999961, 35.084179894000044],
            [-81.045477891999951, 35.083695280000029],
            [-81.045304021999982, 35.083617685000036],
            [-81.044130165999945, 35.083100785000056],
            [-81.043274476999954, 35.082748181000056],
            [-81.042212904999985, 35.082374243000061],
            [-81.041249696999955, 35.082091352000077],
            [-81.039569708999977, 35.08164260500007],
            [-81.03944387699994, 35.081609420000063],
            [-81.038332036999975, 35.081352548000041],
            [-81.037202146999959, 35.08115599000007],
            [-81.036875750999968, 35.081110912000042],
            [-81.031198114999938, 35.080371569000079],
            [-81.030762440999979, 35.080319394000071],
            [-81.027555628999949, 35.079968830000041],
            [-81.024824231999958, 35.078979712000034],
            [-81.024129078999977, 35.078742383000076],
            [-81.023826100999941, 35.078647744000079],
            [-81.02177924199998, 35.07802597500006],
            [-81.020993307999959, 35.077804363000041],
            [-81.019881578999957, 35.077547321000054],
            [-81.018751785999939, 35.077350590000037],
            [-81.01792398799995, 35.077246149000075],
            [-81.016469155999971, 35.077091529000029],
            [-81.016153932999941, 35.077060392000078],
            [-81.01610872699996, 35.07705631400006],
            [-81.013854705999961, 35.076855365000029],
            [-81.012748560999967, 35.076785568000048],
            [-81.011593799999957, 35.076773744000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 108,
        "SolutionID": "PI-10:A",
        "Shape_Length": 0.58662184976919396,
        "Shape_Area": 0.0083955171124109641
      }
    },
    {
      "type": "Feature",
      "id": 109,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.987267304999989, 34.958418410000036],
            [-80.986115729999938, 34.958468455000059],
            [-80.984970591999968, 34.958580389000076],
            [-80.983836794999945, 34.958753732000048],
            [-80.982719189999955, 34.958987744000069],
            [-80.981622560999938, 34.959281420000025],
            [-80.980551600999945, 34.959633506000046],
            [-80.979510894999976, 34.96004249300006],
            [-80.978504897999983, 34.96050663300008],
            [-80.97753791599996, 34.961023938000039],
            [-80.976614088999952, 34.961592193000058],
            [-80.976259654999978, 34.96183175200008],
            [-80.975346674999969, 34.962465362000046],
            [-80.975125899999966, 34.962621161000072],
            [-80.97218615099996, 34.96473050700007],
            [-80.97188461799999, 34.96495191200006],
            [-80.971058705999951, 34.965614537000079],
            [-80.97028719299999, 34.966320206000034],
            [-80.96983370099997, 34.966782197000043],
            [-80.967018696999958, 34.969773024000062],
            [-80.964760787999978, 34.971910880000053],
            [-80.964639256999988, 34.972027181000044],
            [-80.962531916999978, 34.97406545900003],
            [-80.962475901999937, 34.974119906000055],
            [-80.96176195399994, 34.97486555200004],
            [-80.961108768999964, 34.975648032000038],
            [-80.960664465999969, 34.976251287000025],
            [-80.959050533999971, 34.978567756000075],
            [-80.959037896999973, 34.978585924000072],
            [-80.954935860999967, 34.984492595000063],
            [-80.954803149999975, 34.984687130000054],
            [-80.95429071999996, 34.985513413000035],
            [-80.952359688999934, 34.98888938500005],
            [-80.952348470999937, 34.988909033000027],
            [-80.951957894999964, 34.989647092000041],
            [-80.95036855699999, 34.992893443000071],
            [-80.950303775999942, 34.993027681000058],
            [-80.950176373999966, 34.993303810000043],
            [-80.943945963999965, 35.007230512000035],
            [-80.939883038999938, 35.016026239000041],
            [-80.938731921999988, 35.018145098000048],
            [-80.934058346999961, 35.025074264000068],
            [-80.934037276999959, 35.025105591000056],
            [-80.933993664999946, 35.025171020000073],
            [-80.927762918999974, 35.034574722000059],
            [-80.927658569999949, 35.034734560000061],
            [-80.924790733999941, 35.039193763000071],
            [-80.924414415999934, 35.039814299000057],
            [-80.924038143999951, 35.040522176000025],
            [-80.923415237999961, 35.041784564000068],
            [-80.923373321999975, 35.041870294000034],
            [-80.922820078999962, 35.043012286000078],
            [-80.922782330999951, 35.043090866000057],
            [-80.922396698999989, 35.043985706000058],
            [-80.92208304899998, 35.044899371000042],
            [-80.921842724999976, 35.045827950000046],
            [-80.921759714999951, 35.046244500000057],
            [-80.920925316999956, 35.050850125000068],
            [-80.920851626999934, 35.051306381000074],
            [-80.920298221999985, 35.055205690000037],
            [-80.918612398999983, 35.066458844000067],
            [-80.918597469999952, 35.066561493000052],
            [-80.917628133999983, 35.073433719000036],
            [-80.917335812999966, 35.075131703000068],
            [-80.916271636999966, 35.075250591000042],
            [-80.909401306999939, 35.075633971000059],
            [-80.909103151999943, 35.075652688000048],
            [-80.907956263999949, 35.075763890000076],
            [-80.907642788999965, 35.075805243000048],
            [-80.904750302999958, 35.07620875200007],
            [-80.904602370999953, 35.07622992000006],
            [-80.904576893999945, 35.076214195000034],
            [-80.903728701999967, 35.075543159000063],
            [-80.902870858999961, 35.074907579000069],
            [-80.901964294999971, 35.074319475000038],
            [-80.901012897999976, 35.073781367000038],
            [-80.900020739999945, 35.073295557000051],
            [-80.899079793999988, 35.072898235000025],
            [-80.897761983999942, 35.072382192000077],
            [-80.897674259999974, 35.072348082000076],
            [-80.896613491999972, 35.071972865000077],
            [-80.895525153999984, 35.071655478000025],
            [-80.894413903999975, 35.071397280000042],
            [-80.893284498999947, 35.071199375000049],
            [-80.892141772999935, 35.071062613000038],
            [-80.890990613999975, 35.07098757600005],
            [-80.889835953999977, 35.07097458800007],
            [-80.88868273099996, 35.071023702000048],
            [-80.887535880999963, 35.071134710000024],
            [-80.886400312999967, 35.07130713500004],
            [-80.885280888999944, 35.071540241000037],
            [-80.884182396999961, 35.071833027000025],
            [-80.883109540999953, 35.072184244000027],
            [-80.882066911999971, 35.072592386000053],
            [-80.881058973999984, 35.073055708000027],
            [-80.88009004099996, 35.073572225000078],
            [-80.879164260999971, 35.074139728000034],
            [-80.878285597999934, 35.074755786000026],
            [-80.877457814999957, 35.075417764000065],
            [-80.876684454999975, 35.076122826000073],
            [-80.875968830999966, 35.076867956000058],
            [-80.875314007999975, 35.077649963000056],
            [-80.874722789999964, 35.07846550000005],
            [-80.874197709999976, 35.079311076000067],
            [-80.873741018999965, 35.080183070000032],
            [-80.873354674999973, 35.081077750000077],
            [-80.873040333999938, 35.081991284000026],
            [-80.872799340999961, 35.082919761000028],
            [-80.872632735999957, 35.083859207000046],
            [-80.872541229999968, 35.084805598000059],
            [-80.872525220999989, 35.08575488200006],
            [-80.872584777999975, 35.086702994000063],
            [-80.872719649999965, 35.087645875000078],
            [-80.872929259999978, 35.088579485000025],
            [-80.873212715999955, 35.089499827000054],
            [-80.873568802999955, 35.090402960000063],
            [-80.873995999999977, 35.091285015000039],
            [-80.874492479999958, 35.09214221600007],
            [-80.875056118999964, 35.092970888000025],
            [-80.875684503999935, 35.093767485000058],
            [-80.876374944999952, 35.094528593000064],
            [-80.877124486999946, 35.095250953000061],
            [-80.877929918999939, 35.09593146800006],
            [-80.878787793999948, 35.096567227000037],
            [-80.879694437999945, 35.097155504000057],
            [-80.880645966999964, 35.097693779000053],
            [-80.881156645999965, 35.097943870000051],
            [-80.881732013999965, 35.098370230000057],
            [-80.882515440999953, 35.098883080000064],
            [-80.884871214999976, 35.100337338000031],
            [-80.884994476999964, 35.100412740000024],
            [-80.88594607899995, 35.100950973000067],
            [-80.886126832999935, 35.101044934000072],
            [-80.89075373299994, 35.103417833000037],
            [-80.891565435999951, 35.103809765000051],
            [-80.892594456999973, 35.104241258000059],
            [-80.893655626999987, 35.104616510000028],
            [-80.89447970599997, 35.104862780000076],
            [-80.895502289999968, 35.105144937000034],
            [-80.895766982999987, 35.105216067000072],
            [-80.896878697999966, 35.105474251000032],
            [-80.898008585999946, 35.105672122000044],
            [-80.899151806999953, 35.105808828000079],
            [-80.90030346399999, 35.105883786000049],
            [-80.901458620999961, 35.105896675000054],
            [-80.902612327999975, 35.105847439000058],
            [-80.90292328999999, 35.10582348500003],
            [-80.903792469999985, 35.105750148000027],
            [-80.904514527999936, 35.105676835000054],
            [-80.90567250099997, 35.105539290000024],
            [-80.905786797999951, 35.105525399000044],
            [-80.906248691999963, 35.105462835000026],
            [-80.910731104999968, 35.104805303000035],
            [-80.912676043999966, 35.104533985000046],
            [-80.919256498999971, 35.104166740000039],
            [-80.919554755999968, 35.104147999000077],
            [-80.920437451999987, 35.104067921000023],
            [-80.923549890999936, 35.103720191000036],
            [-80.923814462999985, 35.103688950000048],
            [-80.924950401999979, 35.103516174000049],
            [-80.925905353999951, 35.10332111200006],
            [-80.930480624999973, 35.102274343000033],
            [-80.933684819999939, 35.101603732000058],
            [-80.934228148999978, 35.101482394000072],
            [-80.935326859999975, 35.101189153000064],
            [-80.936399872999971, 35.100837474000059],
            [-80.936433702999977, 35.100825282000073],
            [-80.937297134999937, 35.100513232000026],
            [-80.938306015999956, 35.100116806000074],
            [-80.939313961999972, 35.099653008000075],
            [-80.940282829999944, 35.099136017000035],
            [-80.941208467999957, 35.098568048000061],
            [-80.942086911999979, 35.097951533000071],
            [-80.94291439899996, 35.097289115000024],
            [-80.943687386999954, 35.096583630000055],
            [-80.94370010199998, 35.096571219000054],
            [-80.944196506999958, 35.096086103000061],
            [-80.944684138999946, 35.095587648000048],
            [-80.945533265999984, 35.094679360000043],
            [-80.945748084999934, 35.094444692000025],
            [-80.946098235999955, 35.094039670000029],
            [-80.947509116999981, 35.092346983000027],
            [-80.947654239999963, 35.09217009200006],
            [-80.948288543999979, 35.091384529000038],
            [-80.948447536999936, 35.091184050000038],
            [-80.949038110999936, 35.090368153000043],
            [-80.949562485999934, 35.08952225400003],
            [-80.950018417999956, 35.088649974000077],
            [-80.950285055999984, 35.088051591000067],
            [-80.950559501999976, 35.087391108000077],
            [-80.950678401999937, 35.087094567000065],
            [-80.95099189299998, 35.086180831000036],
            [-80.951232004999952, 35.085252199000024],
            [-80.951333486999943, 35.084730234000062],
            [-80.95260712999999, 35.077314166000065],
            [-80.952663623999968, 35.076952385000027],
            [-80.953648308999959, 35.069950047000077],
            [-80.955328559999941, 35.058701856000027],
            [-80.955341903999965, 35.058610105000071],
            [-80.955867803999979, 35.05489336200003],
            [-80.95635544199996, 35.05219543000004],
            [-80.95639822399994, 35.052108659000055],
            [-80.958796337999956, 35.048377585000026],
            [-80.964939708999964, 35.039100449000046],
            [-80.969947859999934, 35.031671018000054],
            [-80.969968918999939, 35.031639685000073],
            [-80.970492699999966, 35.030793689000063],
            [-80.970627048999972, 35.030552184000044],
            [-80.972293112999978, 35.027483247000077],
            [-80.972614118999957, 35.026852382000072],
            [-80.972677261999934, 35.026717609000059],
            [-80.976948486999959, 35.01746289700003],
            [-80.977012708999951, 35.017321515000049],
            [-80.983177661999946, 35.003528697000036],
            [-80.984478460999981, 35.000869546000047],
            [-80.985896757999967, 34.998388288000058],
            [-80.989643941999987, 34.992989634000025],
            [-80.990440301999968, 34.991846029000044],
            [-80.991430674999947, 34.990887794000059],
            [-80.993953631999943, 34.988498235000066],
            [-80.994131148999941, 34.98832744300006],
            [-80.99458462299998, 34.987865362000036],
            [-80.996649483999988, 34.985670789000039],
            [-80.998181802999966, 34.984571070000072],
            [-80.998983676999956, 34.984014459000036],
            [-80.999505992999957, 34.983637144000056],
            [-81.000331856999935, 34.982974325000043],
            [-81.001103266999962, 34.982268465000061],
            [-81.001816920999943, 34.981522587000029],
            [-81.002469762999965, 34.980739887000027],
            [-81.003058997999972, 34.979923717000077],
            [-81.003582104999964, 34.97907757400003],
            [-81.004036844999973, 34.978205080000066],
            [-81.004421270999956, 34.977309975000026],
            [-81.004733740999939, 34.976396090000037],
            [-81.004972918999954, 34.975467341000069],
            [-81.005137781999963, 34.974527703000035],
            [-81.005227627999943, 34.973581202000048],
            [-81.005242073999966, 34.972631891000049],
            [-81.005181060999973, 34.971683835000078],
            [-81.005044851999969, 34.970741092000026],
            [-81.004834034999988, 34.969807701000036],
            [-81.004549512999972, 34.968887658000028],
            [-81.00419250799996, 34.967984901000079],
            [-81.003764549999971, 34.967103297000051],
            [-81.003267473999983, 34.966246618000071],
            [-81.002703408999935, 34.965418534000037],
            [-81.002074773999936, 34.964622589000044],
            [-81.001384259999952, 34.963862191000032],
            [-81.000634825999953, 34.963140593000048],
            [-80.999829679999948, 34.962460887000077],
            [-80.998972272999936, 34.961825981000061],
            [-80.99806627199996, 34.961238592000029],
            [-80.997115558999951, 34.960701235000045],
            [-80.996124203999955, 34.960216210000056],
            [-80.995096449999949, 34.959785593000049],
            [-80.994036694999977, 34.959411228000079],
            [-80.992949477999957, 34.959094716000038],
            [-80.991839451999965, 34.958837412000037],
            [-80.99071136699996, 34.958640417000026],
            [-80.989570051999976, 34.958504575000063],
            [-80.988420390999977, 34.958430467000028],
            [-80.987267304999989, 34.958418410000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 109,
        "SolutionID": "PI-10:C",
        "Shape_Length": 0.44570591343003818,
        "Shape_Area": 0.0064852416781983006
      }
    },
    {
      "type": "Feature",
      "id": 110,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859563140999967, 35.579527761000065],
            [-80.85840266799994, 35.579576582000072],
            [-80.857248584999979, 35.579687291000027],
            [-80.856105832999958, 35.579859415000044],
            [-80.854979301999947, 35.580092217000072],
            [-80.85387381299995, 35.580384700000025],
            [-80.852794097999947, 35.580735614000048],
            [-80.851744777999954, 35.581143455000074],
            [-80.850730345999978, 35.581606479000072],
            [-80.849755143999971, 35.582122704000028],
            [-80.84882334699995, 35.582689919000074],
            [-80.847938943999964, 35.583305697000071],
            [-80.847105721999981, 35.583967403000031],
            [-80.846327248999955, 35.584672203000025],
            [-80.84560685799994, 35.58541708100006],
            [-80.844947634999983, 35.586198848000038],
            [-80.844352403999949, 35.587014158000045],
            [-80.843823714999985, 35.587859519000062],
            [-80.843363833999945, 35.588731314000029],
            [-80.842974731999959, 35.58962581000003],
            [-80.842658076999953, 35.59053917600005],
            [-80.842415224999968, 35.591467502000057],
            [-80.842247222999958, 35.592406814000071],
            [-80.842154788999949, 35.593353090000051],
            [-80.842138323999961, 35.594302277000054],
            [-80.84216743799999, 35.594905854000046],
            [-80.842294035999942, 35.596621389000063],
            [-80.842324496999936, 35.596965845000057],
            [-80.842364809999935, 35.597298156000079],
            [-80.842300892999958, 35.597789760000069],
            [-80.841961451999964, 35.599809041000071],
            [-80.840184253999951, 35.609798330000046],
            [-80.840163147999988, 35.609920607000049],
            [-80.839623009999968, 35.613148993000038],
            [-80.839571522999961, 35.613486101000035],
            [-80.839479033999964, 35.614432370000031],
            [-80.83946253299996, 35.615381551000041],
            [-80.839491756999962, 35.616669191000028],
            [-80.839551316999973, 35.617617221000046],
            [-80.839686687999972, 35.618560040000034],
            [-80.839897289999954, 35.619493610000063],
            [-80.839901262999945, 35.619508435000057],
            [-80.840379921999954, 35.621290190000025],
            [-80.840660898999943, 35.622195688000033],
            [-80.840717071999961, 35.622351691000063],
            [-80.841370995999966, 35.624130788000059],
            [-80.841672899999935, 35.624877917000049],
            [-80.841871000999959, 35.625305595000043],
            [-80.842639159999976, 35.626888078000036],
            [-80.844897451999941, 35.631798402000072],
            [-80.845014466999942, 35.632138587000043],
            [-80.845325750999962, 35.633587857000066],
            [-80.845816971999966, 35.636117287000047],
            [-80.846413629999972, 35.641049815000031],
            [-80.846493376999945, 35.641600993000054],
            [-80.847090995999963, 35.645147413000075],
            [-80.84714794599995, 35.646197433000054],
            [-80.846589976999951, 35.647332307000056],
            [-80.846540526999945, 35.647433991000071],
            [-80.84646257199995, 35.647599021000076],
            [-80.845970664999982, 35.648659575000067],
            [-80.845584129999963, 35.649369808000074],
            [-80.845282951999934, 35.649957664000056],
            [-80.844893575999947, 35.650852150000048],
            [-80.844576703999962, 35.651765506000061],
            [-80.84450714999997, 35.652002811000045],
            [-80.844284536999965, 35.652791586000035],
            [-80.843958621999946, 35.653599424000049],
            [-80.843906976999961, 35.653729492000025],
            [-80.843590081999935, 35.65464284400008],
            [-80.843405461999964, 35.655318695000062],
            [-80.843004649999955, 35.65697063500005],
            [-80.842946234999943, 35.657223095000063],
            [-80.842778101999954, 35.65816239000003],
            [-80.842712240999958, 35.658743604000051],
            [-80.842576955999959, 35.660272322000026],
            [-80.842297649999978, 35.663162874000079],
            [-80.842279692999966, 35.663367064000056],
            [-80.842203755999947, 35.664325273000031],
            [-80.842104836999965, 35.664796479000074],
            [-80.842058978999944, 35.664898262000065],
            [-80.841927379999959, 35.665200625000068],
            [-80.841274037999938, 35.666756054000075],
            [-80.841026787999965, 35.667336678000026],
            [-80.84094423099998, 35.66747790200003],
            [-80.840902387999961, 35.667549989000065],
            [-80.840754112999946, 35.667814100000044],
            [-80.837670581999987, 35.673454591000052],
            [-80.837358446999986, 35.674062236000054],
            [-80.837147535999975, 35.674523319000059],
            [-80.835897355999975, 35.677404547000037],
            [-80.835718674999953, 35.67783791800008],
            [-80.835401593999961, 35.678751242000033],
            [-80.835158394999951, 35.679679531000033],
            [-80.83499012499999, 35.680618809000066],
            [-80.834897505999947, 35.681565056000068],
            [-80.83488093699998, 35.682514218000051],
            [-80.834929562999946, 35.684660244000042],
            [-80.834989119999989, 35.685608257000069],
            [-80.835124550999979, 35.686551064000071],
            [-80.835335277999945, 35.687484623000046],
            [-80.83562040299995, 35.688404939000065],
            [-80.83564247299995, 35.688466968000057],
            [-80.837046996999959, 35.692381491000049],
            [-80.837383257999988, 35.693222588000026],
            [-80.837813240999935, 35.69410466000005],
            [-80.837828147999971, 35.694132465000052],
            [-80.840066935999971, 35.698296483000036],
            [-80.841139281999972, 35.700370877000069],
            [-80.841135038999937, 35.700424385000076],
            [-80.840893798999957, 35.701512850000029],
            [-80.839212776999943, 35.704634463000048],
            [-80.838941672999965, 35.705166305000034],
            [-80.83855196199994, 35.706060756000056],
            [-80.838234801999988, 35.706974080000066],
            [-80.83799154999997, 35.707902367000031],
            [-80.837823254999989, 35.708841641000049],
            [-80.837730635999947, 35.709787882000057],
            [-80.837714094999967, 35.710737036000069],
            [-80.837773703999972, 35.711685041000067],
            [-80.837909211999943, 35.712627836000024],
            [-80.838120038999989, 35.713561384000059],
            [-80.838209003999964, 35.713876906000053],
            [-80.838942156999963, 35.716350276000071],
            [-80.84028718899998, 35.724523203000047],
            [-80.843070311999952, 35.751668506000044],
            [-80.843071212999973, 35.75167726400008],
            [-80.843109407999975, 35.752002989000061],
            [-80.84467217599996, 35.763909108000064],
            [-80.845748994999951, 35.775732780000055],
            [-80.845493475999945, 35.781371981000063],
            [-80.845485423999946, 35.781597007000073],
            [-80.845262244999958, 35.790097517000049],
            [-80.84351249599996, 35.807209796000052],
            [-80.843470582999942, 35.807734327000048],
            [-80.843454088999977, 35.808683452000025],
            [-80.843513837999978, 35.809631424000031],
            [-80.843649575999962, 35.810574183000028],
            [-80.843860724999956, 35.811507692000077],
            [-80.844146383999941, 35.812427951000075],
            [-80.844505328999958, 35.813331022000057],
            [-80.844936028999939, 35.81421303500008],
            [-80.845436639999946, 35.815070214000059],
            [-80.84600501999995, 35.815898885000024],
            [-80.846638736999978, 35.816695502000073],
            [-80.847335076999968, 35.817456650000054],
            [-80.848091059999945, 35.818179071000031],
            [-80.848903449999966, 35.818859668000073],
            [-80.849768765999954, 35.819495528000061],
            [-80.850683304999961, 35.820083925000063],
            [-80.851643147999937, 35.820622339000067],
            [-80.852644184999974, 35.821108465000066],
            [-80.853682126999956, 35.821540218000052],
            [-80.854752529999985, 35.821915749000027],
            [-80.855850805999978, 35.822233450000056],
            [-80.856972251999935, 35.822491958000057],
            [-80.858112061999975, 35.822690169000055],
            [-80.859265353999945, 35.82282723000003],
            [-80.860427185999981, 35.822902556000031],
            [-80.861592579999979, 35.822915824000063],
            [-80.862756542999989, 35.822866977000047],
            [-80.86391408999998, 35.822756223000056],
            [-80.865060258999961, 35.822584039000049],
            [-80.866190139999958, 35.822351160000039],
            [-80.867298892999941, 35.822058586000026],
            [-80.86838176699996, 35.821707569000068],
            [-80.869434124999941, 35.821299614000054],
            [-80.870451456999945, 35.820836467000049],
            [-80.871429405999947, 35.820320114000026],
            [-80.872363782999969, 35.819752766000079],
            [-80.873250586999973, 35.819136855000067],
            [-80.874086017999957, 35.818475017000026],
            [-80.874866499999939, 35.817770089000078],
            [-80.875588691999951, 35.817025090000072],
            [-80.876249499999972, 35.816243211000028],
            [-80.876846096999941, 35.815427802000045],
            [-80.877375927999935, 35.814582356000074],
            [-80.877836726999988, 35.813710492000041],
            [-80.878226521999977, 35.812815946000057],
            [-80.878543645999969, 35.811902548000035],
            [-80.878786744999957, 35.810974212000076],
            [-80.87895477699999, 35.810034912000049],
            [-80.87900534399995, 35.809613213000034],
            [-80.880793051999945, 35.79205425400005],
            [-80.880834714999935, 35.791529708000041],
            [-80.880850024999972, 35.791160452000042],
            [-80.881078115999969, 35.782324802000062],
            [-80.881362243999945, 35.775992680000058],
            [-80.881370932999971, 35.77518776800008],
            [-80.881324820999964, 35.774383700000044],
            [-80.88014777099994, 35.761521541000036],
            [-80.880133720999936, 35.761377644000049],
            [-80.88009537399995, 35.761051932000044],
            [-80.878518572999951, 35.749078847000078],
            [-80.875703656999974, 35.721740065000063],
            [-80.875702751999938, 35.721731308000074],
            [-80.87560706499994, 35.721018653000044],
            [-80.874113661999957, 35.711968088000049],
            [-80.874307613999974, 35.711607629000071],
            [-80.874578506999967, 35.711075709000056],
            [-80.874967843999968, 35.710181144000046],
            [-80.875284602999955, 35.709267725000075],
            [-80.875524136999957, 35.708354299000064],
            [-80.876318294999976, 35.704764121000039],
            [-80.876321588999986, 35.704749186000072],
            [-80.876489428999946, 35.703809857000067],
            [-80.876564167999959, 35.703119822000076],
            [-80.876903166999966, 35.698821062000036],
            [-80.876920583999947, 35.698564824000073],
            [-80.876936662999981, 35.697615661000043],
            [-80.876876605999939, 35.696667672000046],
            [-80.876740671999983, 35.695724916000074],
            [-80.87652944599995, 35.694791430000066],
            [-80.876243833999979, 35.693871212000033],
            [-80.875885063999988, 35.692968200000053],
            [-80.875540197999953, 35.69224878700004],
            [-80.87278546999994, 35.686924289000046],
            [-80.872699954999973, 35.68676176200006],
            [-80.872685038999975, 35.686733962000062],
            [-80.871028688999957, 35.683655592000036],
            [-80.873136038999974, 35.679797868000037],
            [-80.873544592999963, 35.679098497000041],
            [-80.873586412999941, 35.679026398000076],
            [-80.874046481999983, 35.678154511000059],
            [-80.874291170999982, 35.677614234000032],
            [-80.874873411999943, 35.676245634000054],
            [-80.874897858999987, 35.676187775000074],
            [-80.875499083999955, 35.674755013000038],
            [-80.876015148999954, 35.673608545000036],
            [-80.876266667999971, 35.673009707000062],
            [-80.876583265999955, 35.672096275000058],
            [-80.87682267699995, 35.671182828000042],
            [-80.877060803999939, 35.670105761000059],
            [-80.87706409499998, 35.670090834000064],
            [-80.877089020999961, 35.669974697000043],
            [-80.877404856999988, 35.668467073000045],
            [-80.877547684999968, 35.667643869000074],
            [-80.877622382999959, 35.666953822000039],
            [-80.877749851999965, 35.665336448000062],
            [-80.87802267099994, 35.662500185000056],
            [-80.878031457999953, 35.662404804000062],
            [-80.878087039999969, 35.661773628000049],
            [-80.878306689999988, 35.661228613000048],
            [-80.87835828499999, 35.66109853100005],
            [-80.878674812999975, 35.660185090000027],
            [-80.878744273999985, 35.659947765000027],
            [-80.878773925999951, 35.65984254500006],
            [-80.878963007999971, 35.659473181000067],
            [-80.87904090699999, 35.659308131000046],
            [-80.879646882999964, 35.658000499000025],
            [-80.880502599999943, 35.656258568000055],
            [-80.880552012999942, 35.656156870000075],
            [-80.880941020999956, 35.655262271000026],
            [-80.881123171999945, 35.654764820000025],
            [-80.881615825999972, 35.65332600000005],
            [-80.881750145999945, 35.652910000000077],
            [-80.881956685999967, 35.652140604000067],
            [-80.882275683999978, 35.650776027000063],
            [-80.882311727999934, 35.650617035000039],
            [-80.882479385999943, 35.64967768300005],
            [-80.882532764999951, 35.649226759000044],
            [-80.88267480199994, 35.647793359000048],
            [-80.882713448999937, 35.647297991000073],
            [-80.882729449999943, 35.646348812000042],
            [-80.882714971999974, 35.645977135000066],
            [-80.882562509999957, 35.64318865000007],
            [-80.882516903999942, 35.642612327000052],
            [-80.882413533999966, 35.641853445000038],
            [-80.881748533999939, 35.637917357000049],
            [-80.88113173399995, 35.632836599000029],
            [-80.881019226999967, 35.632101559000034],
            [-80.880998169999941, 35.631990603000077],
            [-80.880401053999947, 35.628922743000032],
            [-80.88035309899999, 35.628688715000067],
            [-80.87986190099997, 35.62640637700008],
            [-80.879719825999985, 35.625817862000076],
            [-80.87946661999996, 35.624989952000078],
            [-80.878902782999944, 35.623352776000047],
            [-80.878870584999959, 35.623260457000072],
            [-80.878512097999987, 35.622357435000026],
            [-80.878430026999979, 35.622175473000027],
            [-80.875846079999974, 35.61656208900007],
            [-80.875729879999938, 35.616316429000051],
            [-80.87518278899995, 35.615190320000067],
            [-80.87515407799998, 35.615112298000042],
            [-80.875355702999968, 35.613904028000036],
            [-80.877127793999989, 35.603919104000056],
            [-80.877147212999944, 35.60380656600006],
            [-80.877532154999983, 35.601510672000074],
            [-80.87758510599997, 35.601163800000052],
            [-80.877597719999983, 35.601069417000076],
            [-80.87789504899996, 35.598774872000035],
            [-80.877974458999972, 35.597922954000069],
            [-80.877990505999946, 35.596973763000051],
            [-80.877930510999988, 35.596025747000056],
            [-80.877878661999944, 35.595599896000067],
            [-80.877722502999973, 35.594484609000062],
            [-80.877623815999982, 35.593155158000059],
            [-80.877593205999972, 35.592810711000027],
            [-80.877457436999975, 35.591867927000067],
            [-80.877246473999946, 35.590934415000049],
            [-80.876961218999952, 35.590014171000064],
            [-80.876602900999956, 35.589111135000053],
            [-80.876173051999956, 35.588229174000048],
            [-80.875673516999939, 35.587372062000043],
            [-80.875106435999953, 35.586543471000027],
            [-80.874474237999948, 35.585746947000075],
            [-80.87377963299997, 35.584985900000049],
            [-80.873025594999945, 35.584263588000056],
            [-80.872215352999945, 35.583583103000024],
            [-80.871352378999973, 35.582947358000069],
            [-80.87044036399999, 35.582359073000077],
            [-80.869483215999935, 35.581820768000057],
            [-80.868485031999967, 35.581334746000039],
            [-80.867450085999963, 35.580903087000024],
            [-80.866382804999944, 35.580527640000071],
            [-80.865287760999934, 35.580210011000077],
            [-80.864169638999954, 35.57995156000004],
            [-80.86303322599997, 35.579753391000054],
            [-80.861883384999942, 35.579616356000031],
            [-80.860725036999952, 35.579541038000059],
            [-80.859563140999967, 35.579527761000065]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 110,
        "SolutionID": "PI-10:H",
        "Shape_Length": 0.54306276585002133,
        "Shape_Area": 0.0085942719079705101
      }
    },
    {
      "type": "Feature",
      "id": 111,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.981358639999939, 35.299341251000044],
            [-80.983080849999965, 35.299720755000067],
            [-80.985147450999989, 35.300285259000077],
            [-80.98552041399995, 35.300474535000035],
            [-80.985691993999978, 35.300559422000049],
            [-80.988563040999964, 35.301943649000066],
            [-80.988788690999968, 35.302048814000045],
            [-80.98901800699997, 35.302148510000052],
            [-80.989250788999982, 35.302242651000029],
            [-80.990239642999938, 35.302627858000051],
            [-80.990699402999951, 35.302885819000039],
            [-80.991006915999947, 35.303050775000031],
            [-80.99142737599999, 35.303253238000025],
            [-80.991860804999988, 35.303436403000035],
            [-80.992305887999976, 35.303599717000054],
            [-80.992761268999971, 35.303742681000074],
            [-80.993225568999947, 35.303864862000069],
            [-80.993697374999954, 35.30396588800005],
            [-80.99417525399997, 35.304045452000025],
            [-80.994657754999935, 35.304103314000031],
            [-80.994703792999985, 35.304107676000058],
            [-80.99579756299994, 35.304208931000062],
            [-80.996030983999958, 35.304227974000071],
            [-80.996264937999968, 35.304241937000029],
            [-80.996499261999986, 35.304250812000078],
            [-80.99673379099994, 35.304254590000028],
            [-80.996968359999983, 35.30425327100005],
            [-80.998204653999949, 35.304232874000036],
            [-81.001986254999963, 35.304170407000072],
            [-81.002232624999976, 35.304163517000063],
            [-81.007395679999945, 35.30395868800008],
            [-81.00787659599996, 35.303928811000048],
            [-81.008071786999949, 35.303910490000078],
            [-81.00945057399997, 35.303768336000076],
            [-81.009738874999982, 35.30373465100007],
            [-81.010218134999946, 35.303660885000056],
            [-81.010691708999957, 35.303565587000037],
            [-81.01115815899999, 35.303449046000026],
            [-81.011616066999977, 35.30331161600003],
            [-81.012064042999953, 35.303153713000029],
            [-81.012500726999974, 35.302975819000039],
            [-81.012924790999989, 35.302778473000046],
            [-81.013135397999974, 35.30267073300007],
            [-81.014068972999951, 35.302178089000051],
            [-81.014268517999938, 35.302069629000073],
            [-81.014663516999974, 35.30183523200003],
            [-81.01504216099994, 35.301583353000069],
            [-81.015403302999971, 35.301314754000032],
            [-81.015745844999969, 35.301030253000079],
            [-81.016068745999974, 35.300730714000053],
            [-81.016371025999945, 35.300417047000053],
            [-81.016651767999974, 35.300090204000071],
            [-81.016910119999977, 35.299751179000054],
            [-81.017145293999988, 35.299401000000046],
            [-81.017356578999966, 35.299040731000048],
            [-81.017543332999935, 35.298671468000066],
            [-81.017625806999945, 35.298487365000028],
            [-81.017653233999965, 35.298423486000047],
            [-81.01908545699996, 35.298399609000057],
            [-81.01934287499995, 35.298392242000034],
            [-81.019599921999941, 35.298378734000039],
            [-81.019856380999954, 35.298359097000059],
            [-81.020112033999965, 35.298333349000075],
            [-81.020366663999937, 35.298301510000044],
            [-81.020620054999938, 35.29826360800007],
            [-81.025547030999974, 35.297465599000077],
            [-81.025704995999945, 35.297438781000039],
            [-81.026027858999953, 35.297376230000054],
            [-81.031889614999955, 35.296144834000074],
            [-81.032040267999946, 35.296112020000066],
            [-81.032506643999966, 35.29599539600008],
            [-81.032964474999972, 35.295857885000032],
            [-81.033412368999961, 35.29569990300007],
            [-81.033848965999937, 35.295521932000042],
            [-81.033925847999967, 35.295487994000041],
            [-81.035868265999966, 35.29462038500003],
            [-81.036181598999974, 35.294473554000035],
            [-81.038696712999979, 35.293238422000059],
            [-81.03873046199999, 35.293221764000066],
            [-81.039140500999963, 35.293005478000055],
            [-81.039535383999976, 35.292771000000073],
            [-81.039913908999949, 35.292519042000038],
            [-81.040274928999963, 35.292250369000044],
            [-81.04053354399997, 35.29203838400008],
            [-81.04181542799995, 35.29094324700003],
            [-81.041970153999955, 35.29080762600006],
            [-81.042120593999982, 35.290668802000027],
            [-81.042266649999988, 35.290526865000061],
            [-81.042408225999964, 35.290381909000075],
            [-81.042545229999973, 35.290234030000079],
            [-81.045632959999978, 35.286810880000075],
            [-81.047584309999934, 35.284691654000028],
            [-81.052611981999974, 35.284274116000063],
            [-81.052632984999946, 35.284272351000027],
            [-81.052998853999952, 35.284234900000058],
            [-81.054739244999951, 35.284026449000066],
            [-81.054856721999954, 35.284011717000055],
            [-81.055335824999986, 35.283937772000058],
            [-81.055809231999945, 35.283842297000035],
            [-81.056275503999984, 35.283725582000045],
            [-81.056733224999959, 35.283587981000039],
            [-81.057181004999961, 35.283429911000042],
            [-81.05761748499998, 35.283251853000024],
            [-81.058041335999974, 35.283054349000054],
            [-81.058167978999961, 35.282990289000054],
            [-81.058885962999966, 35.282620236000071],
            [-81.059169253999983, 35.282467942000039],
            [-81.059564028999944, 35.282233398000074],
            [-81.059942442999954, 35.281981377000079],
            [-81.06030334999997, 35.281712643000049],
            [-81.060645651999948, 35.281428014000028],
            [-81.060968309999964, 35.281128355000078],
            [-81.061270344999969, 35.280814575000079],
            [-81.061363777999986, 35.280709866000052],
            [-81.061919376999981, 35.280075374000035],
            [-81.062401802999943, 35.279559261000031],
            [-81.062473030999968, 35.279481996000072],
            [-81.06275351499994, 35.279155044000049],
            [-81.063011607999954, 35.278815919000067],
            [-81.063246522999975, 35.278465649000054],
            [-81.063457549999953, 35.27810529900006],
            [-81.063497322999979, 35.278031186000078],
            [-81.064074977999951, 35.276938237000024],
            [-81.066414779999945, 35.27354364200005],
            [-81.067198768999958, 35.273317215000077],
            [-81.067371456999979, 35.273472419000029],
            [-81.067695147999984, 35.273748343000079],
            [-81.068051552999975, 35.274021057000027],
            [-81.068425736999984, 35.274277255000072],
            [-81.068816564999963, 35.27451615800004],
            [-81.068915286999982, 35.274572283000055],
            [-81.069948185999976, 35.275151031000064],
            [-81.070255754999948, 35.275315786000078],
            [-81.070676268999989, 35.275517974000024],
            [-81.071109728999943, 35.275700857000061],
            [-81.071554817999981, 35.275863880000031],
            [-81.072010183999964, 35.276006547000065],
            [-81.072474443999965, 35.276128424000035],
            [-81.072946186999957, 35.276229142000034],
            [-81.073423980999962, 35.276308394000068],
            [-81.073906373999989, 35.276365941000051],
            [-81.074391900999956, 35.276401605000046],
            [-81.074879085999953, 35.276415281000027],
            [-81.075366450999979, 35.27640692500006],
            [-81.075852512999973, 35.276376563000042],
            [-81.076335797999945, 35.276324289000058],
            [-81.076371665999943, 35.276319519000026],
            [-81.077386381999986, 35.276182826000024],
            [-81.077623939999967, 35.27614808800007],
            [-81.077860253999972, 35.276108049000072],
            [-81.078095147999989, 35.276062737000075],
            [-81.07832844699999, 35.276012188000038],
            [-81.078559977999987, 35.275956439000026],
            [-81.080283933999965, 35.275520288000052],
            [-81.081916978999971, 35.275208901000042],
            [-81.082179532999987, 35.275702147000061],
            [-81.082774175999987, 35.276850750000051],
            [-81.082792622999989, 35.276886119000039],
            [-81.082997761999934, 35.277248747000044],
            [-81.083226951999961, 35.277601557000025],
            [-81.083479496999985, 35.277943477000065],
            [-81.083754630999977, 35.278273469000055],
            [-81.084051516999978, 35.278590531000077],
            [-81.084369253999967, 35.278893698000047],
            [-81.084706876999974, 35.279182051000078],
            [-81.084794270999964, 35.279251722000026],
            [-81.085684996999987, 35.279952067000067],
            [-81.085954091999952, 35.280155058000048],
            [-81.086328360999971, 35.28041120000006],
            [-81.086719270999936, 35.280650045000073],
            [-81.087125636999986, 35.280870868000079],
            [-81.087546220999968, 35.28107299800007],
            [-81.087979747999952, 35.281255820000069],
            [-81.088018354999974, 35.281270919000065],
            [-81.089487684999938, 35.281841974000031],
            [-81.089894236999953, 35.281989831000033],
            [-81.090349668999977, 35.282132429000058],
            [-81.090813991999937, 35.282254235000039],
            [-81.09128579299994, 35.28235488200005],
            [-81.091763640999943, 35.28243406100006],
            [-81.092246082999964, 35.28249153400003],
            [-81.092364205999957, 35.282502231000024],
            [-81.093530509999937, 35.282601347000025],
            [-81.093897959999936, 35.282626237000045],
            [-81.094385184999965, 35.282639835000055],
            [-81.094872585999951, 35.28263140100006],
            [-81.095358676999979, 35.282600961000071],
            [-81.095841986999972, 35.28254860900006],
            [-81.096321043999978, 35.282474502000071],
            [-81.096794393999971, 35.282378867000034],
            [-81.097260598999981, 35.282261994000066],
            [-81.097718242999974, 35.282124238000051],
            [-81.09816593499994, 35.281966016000069],
            [-81.098272007999981, 35.281924948000039],
            [-81.099856063999937, 35.281301068000062],
            [-81.100064226999962, 35.281216303000065],
            [-81.100269602999958, 35.281127083000058],
            [-81.100472047999972, 35.281033469000079],
            [-81.100671424999973, 35.280935526000064],
            [-81.102678027999957, 35.279920146000052],
            [-81.10487858099998, 35.280499890000044],
            [-81.120799992999935, 35.284859908000044],
            [-81.120922421999978, 35.284892614000057],
            [-81.126927765999937, 35.286457203000055],
            [-81.127192019999939, 35.286522339000044],
            [-81.127458622999939, 35.286580685000047],
            [-81.127727316999938, 35.28663218500003],
            [-81.127997837999942, 35.286676789000069],
            [-81.128269925999973, 35.286714453000059],
            [-81.128543312999966, 35.28674514000005],
            [-81.130585309999958, 35.286947782000027],
            [-81.130790631999957, 35.286966165000024],
            [-81.130996421999953, 35.286980611000047],
            [-81.131202565999956, 35.286991115000035],
            [-81.141160271999979, 35.287402444000065],
            [-81.141313753999952, 35.287407683000026],
            [-81.149320838999984, 35.287623629000052],
            [-81.14956389699995, 35.287627437000026],
            [-81.149806989999945, 35.28762576500003],
            [-81.15004993499997, 35.287618618000067],
            [-81.150292547999982, 35.287606000000039],
            [-81.150534647999962, 35.287587920000078],
            [-81.151407601999949, 35.28751262600008],
            [-81.151886788999946, 35.287460404000058],
            [-81.152365823999958, 35.287386076000075],
            [-81.152839135999955, 35.287290222000024],
            [-81.153305288999945, 35.287173133000067],
            [-81.153762864999976, 35.287035165000077],
            [-81.154210475999946, 35.286876736000067],
            [-81.154646760999981, 35.286698329000046],
            [-81.154844094999987, 35.28660932300005],
            [-81.15577820499999, 35.28617519900007],
            [-81.15597915099994, 35.286078923000048],
            [-81.156176938999977, 35.285978346000036],
            [-81.156371429999979, 35.285873537000043],
            [-81.156562491999978, 35.285764569000037],
            [-81.15674998999998, 35.285651518000066],
            [-81.158038114999954, 35.28485321800008],
            [-81.158248000999947, 35.284719026000062],
            [-81.158452869999962, 35.284579717000042],
            [-81.158652532999952, 35.284435417000054],
            [-81.160205259999941, 35.28327871700003],
            [-81.16254291599995, 35.281628820000037],
            [-81.162791560999949, 35.281446515000027],
            [-81.163209574999939, 35.281128195000065],
            [-81.170635854999944, 35.278091513000049],
            [-81.170816056999968, 35.278015694000032],
            [-81.173548296999968, 35.276833460000034],
            [-81.173586076999982, 35.276817016000052],
            [-81.174009612999953, 35.276619106000055],
            [-81.17441920899995, 35.276402362000056],
            [-81.174813620999942, 35.276167441000041],
            [-81.175191649999988, 35.275915060000045],
            [-81.175552148999941, 35.275645983000061],
            [-81.175810349999949, 35.275433716000066],
            [-81.176236954999979, 35.275068194000028],
            [-81.176320626999939, 35.274995506000039],
            [-81.176642833999949, 35.27469553800006],
            [-81.176944397999989, 35.274381469000048],
            [-81.177224404999947, 35.274054253000031],
            [-81.177482004999945, 35.273714884000071],
            [-81.177716412999985, 35.27336439100003],
            [-81.177926919999948, 35.273003842000037],
            [-81.178112885999951, 35.272634329000027],
            [-81.178242858999965, 35.272329433000039],
            [-81.17963313499996, 35.272353951000071],
            [-81.179773696999973, 35.272355514000026],
            [-81.180017435999957, 35.272353878000047],
            [-81.18161665699995, 35.27232506200005],
            [-81.18185252699999, 35.272318227000028],
            [-81.182088089999979, 35.272306233000052],
            [-81.182323179999969, 35.272289089000026],
            [-81.182557626999937, 35.272266806000061],
            [-81.184285358999944, 35.272083396000028],
            [-81.187829997999984, 35.271909532000052],
            [-81.188226257999986, 35.271882746000074],
            [-81.188709443999983, 35.271830024000053],
            [-81.189188352999963, 35.271755549000034],
            [-81.189661530999956, 35.271659551000027],
            [-81.190127540999981, 35.271542320000037],
            [-81.190584967999939, 35.271404213000039],
            [-81.191032419999942, 35.271245647000057],
            [-81.191468540999949, 35.271067107000079],
            [-81.191892003999953, 35.270869134000066],
            [-81.192301522999969, 35.270652329000029],
            [-81.192695855999943, 35.270417350000059],
            [-81.193073802999947, 35.270164913000031],
            [-81.193434217999936, 35.269895782000049],
            [-81.193776004999961, 35.269610776000036],
            [-81.194098125999972, 35.269310761000042],
            [-81.194399602999965, 35.26899664900003],
            [-81.194679520999955, 35.268669392000049],
            [-81.194937030999938, 35.268329985000037],
            [-81.195171347999974, 35.26797945900006],
            [-81.19538176399999, 35.267618878000064],
            [-81.195567637999943, 35.267249339000045],
            [-81.195728407999979, 35.266871963000028],
            [-81.195863582999948, 35.266487896000058],
            [-81.195972754999957, 35.266098305000071],
            [-81.196055590999947, 35.265704373000062],
            [-81.196111842999983, 35.265307297000049],
            [-81.196141336999972, 35.264908282000079],
            [-81.196143985999981, 35.264508540000065],
            [-81.196119781999982, 35.264109285000075],
            [-81.196068797999942, 35.263711731000058],
            [-81.195991189999972, 35.26331708400005],
            [-81.195887194999955, 35.262926542000059],
            [-81.195757126999979, 35.262541291000048],
            [-81.195601382999939, 35.26216250300007],
            [-81.19542043499996, 35.261791326000036],
            [-81.195214832999966, 35.261428888000069],
            [-81.194985203999977, 35.261076290000062],
            [-81.194732243999965, 35.260734601000024],
            [-81.194456718999959, 35.26040486100004],
            [-81.194159469999988, 35.260088069000062],
            [-81.193841397999961, 35.25978518900007],
            [-81.19350346799996, 35.259497139000075],
            [-81.193146707999972, 35.259224794000033],
            [-81.192772199999979, 35.258968981000066],
            [-81.192381082999987, 35.258730478000075],
            [-81.191974542999958, 35.25851000800003],
            [-81.191553815999953, 35.258308240000076],
            [-81.191120178999938, 35.258125788000029],
            [-81.190674946999934, 35.257963204000077],
            [-81.190219474999935, 35.257820983000045],
            [-81.189755143999946, 35.257699557000024],
            [-81.189283362999959, 35.257599295000034],
            [-81.188805566999974, 35.257520499000066],
            [-81.188323204999961, 35.25746341100006],
            [-81.187837740999953, 35.257428204000064],
            [-81.187350650999974, 35.257414982000057],
            [-81.18686341099999, 35.257423789000029],
            [-81.186773693999953, 35.257427816000074],
            [-81.182924930999945, 35.257616592000034],
            [-81.182528737999974, 35.257643360000031],
            [-81.182317393999938, 35.257663681000054],
            [-81.181857777999937, 35.257712476000052],
            [-81.181820289999962, 35.257680513000025],
            [-81.181463575999942, 35.257408134000059],
            [-81.181089113999974, 35.257152284000028],
            [-81.180698039999982, 35.25691374400003],
            [-81.180291539999985, 35.256693234000068],
            [-81.179870850999976, 35.256491425000036],
            [-81.179437249999978, 35.256308931000035],
            [-81.178992051999955, 35.256146305000073],
            [-81.178536609999981, 35.25600404000005],
            [-81.17807230599999, 35.25588256900005],
            [-81.177600550999955, 35.255782260000046],
            [-81.177122776999965, 35.255703419000042],
            [-81.176770848999979, 35.255659541000057],
            [-81.175822859999982, 35.255557306000071],
            [-81.175692444999981, 35.255544048000047],
            [-81.175206998999954, 35.255508790000079],
            [-81.174719921999952, 35.255495518000032],
            [-81.174232691999975, 35.255504274000032],
            [-81.174149137999962, 35.255507991000059],
            [-81.172213935999935, 35.255601614000057],
            [-81.169915408999941, 35.255701841000075],
            [-81.169671068999946, 35.255715278000025],
            [-81.169427275999965, 35.255734258000075],
            [-81.169184216999952, 35.255758768000078],
            [-81.168942078999976, 35.255788789000064],
            [-81.168701045999967, 35.255824297000061],
            [-81.168461304999937, 35.255865265000068],
            [-81.167375905999961, 35.256063638000057],
            [-81.166941575999942, 35.256152537000048],
            [-81.166475619999972, 35.25626967900007],
            [-81.166018237999936, 35.256407696000053],
            [-81.165570817999935, 35.256566169000052],
            [-81.16513471799999, 35.256744616000049],
            [-81.164711263999948, 35.256942496000079],
            [-81.164301739999985, 35.257159209000065],
            [-81.163907388999974, 35.257394095000052],
            [-81.163625207999985, 35.257579768000028],
            [-81.163268148999975, 35.257824720000031],
            [-81.163082675999988, 35.257955578000065],
            [-81.162901684999952, 35.258090594000066],
            [-81.162725314999989, 35.258229663000066],
            [-81.162553701999968, 35.258372681000026],
            [-81.162269316999982, 35.258616331000042],
            [-81.162185656999952, 35.258689003000029],
            [-81.161863460999939, 35.258988934000058],
            [-81.161561894999977, 35.259302966000064],
            [-81.161281875999975, 35.259630147000053],
            [-81.161024252999937, 35.259969482000031],
            [-81.160789811999962, 35.26031994300007],
            [-81.160579261999942, 35.260680464000075],
            [-81.160393243999977, 35.261049950000029],
            [-81.160232321999956, 35.261427279000031],
            [-81.160096986999974, 35.261811307000073],
            [-81.159987649999948, 35.262200866000057],
            [-81.159904641999958, 35.262594773000046],
            [-81.159848215999943, 35.262991833000058],
            [-81.159818544999951, 35.263390839000067],
            [-81.159815716999958, 35.263790580000034],
            [-81.159839743999953, 35.264189842000064],
            [-81.159878744999958, 35.264511406000054],
            [-81.159967247999987, 35.265106247000062],
            [-81.15999708999999, 35.265287729000079],
            [-81.160032507999972, 35.265468536000071],
            [-81.160073478999948, 35.265648552000073],
            [-81.160119977999955, 35.265827662000049],
            [-81.160207437999986, 35.266144835000034],
            [-81.155115713999976, 35.268226729000048],
            [-81.154935519999981, 35.268302524000035],
            [-81.153928919999942, 35.268737937000026],
            [-81.153891138999938, 35.268754376000061],
            [-81.153467594999938, 35.268952216000059],
            [-81.153057980999961, 35.26916889000006],
            [-81.152663538999946, 35.26940373900004],
            [-81.152285470999971, 35.269656050000037],
            [-81.152036910999982, 35.26983828300007],
            [-81.150875943999949, 35.270722278000051],
            [-81.148574898999982, 35.272346171000038],
            [-81.148397253999974, 35.27247497500008],
            [-81.147609028999966, 35.273062106000054],
            [-81.14197278599994, 35.272910048000028],
            [-81.132403057999966, 35.272514753000053],
            [-81.131493175999935, 35.272424461000071],
            [-81.126341255999989, 35.27108221900005],
            [-81.110434941999983, 35.266726399000049],
            [-81.110337902999959, 35.26670032800007],
            [-81.106091151999976, 35.265581481000027],
            [-81.105846501999963, 35.265520206000076],
            [-81.105374779999977, 35.265419616000031],
            [-81.104897019999953, 35.265340490000028],
            [-81.104414669999983, 35.265283067000041],
            [-81.104175982999948, 35.265262828000061],
            [-81.102864540999974, 35.265166340000064],
            [-81.102620680999962, 35.265151181000078],
            [-81.102376404999973, 35.265141557000049],
            [-81.102131897999982, 35.265137476000064],
            [-81.101887344999966, 35.265138940000043],
            [-81.101642935999962, 35.265145948000054],
            [-81.101398856999936, 35.265158496000026],
            [-81.101155293999966, 35.265176574000066],
            [-81.099411830999941, 35.265326254000058],
            [-81.098932739999952, 35.265378268000063],
            [-81.098453785999936, 35.26545238600005],
            [-81.097980536999955, 35.265548027000079],
            [-81.097514427999954, 35.265664904000062],
            [-81.097056876999943, 35.265802660000077],
            [-81.096609270999977, 35.265960878000044],
            [-81.096172969999941, 35.266139076000059],
            [-81.096049572999959, 35.266196640000032],
            [-81.095299831999967, 35.265411848000042],
            [-81.095201151999959, 35.265310437000039],
            [-81.094883427999946, 35.265007297000068],
            [-81.09454582799998, 35.264718971000036],
            [-81.094189374999985, 35.264446335000059],
            [-81.093815154999959, 35.264190216000031],
            [-81.093494036999971, 35.263992016000032],
            [-81.092312676999939, 35.263296752000031],
            [-81.092242943999963, 35.263256130000059],
            [-81.091836652999973, 35.263035323000054],
            [-81.091416150999976, 35.262833208000075],
            [-81.090982713999949, 35.262650397000073],
            [-81.090944111999988, 35.262635298000077],
            [-81.088299937999977, 35.261607416000061],
            [-81.087893493999957, 35.261459555000044],
            [-81.087438181999971, 35.261316947000068],
            [-81.08697398399994, 35.261195127000065],
            [-81.086502309999958, 35.261094463000063],
            [-81.086319008999965, 35.26106141200006],
            [-81.084420084999977, 35.260736289000079],
            [-81.084125671999971, 35.260690135000061],
            [-81.083643363999954, 35.260632629000042],
            [-81.083157926999945, 35.260597002000054],
            [-81.082670835999977, 35.260583359000066],
            [-81.082183565999969, 35.260591744000067],
            [-81.081749527999989, 35.260617828000079],
            [-81.079862816999935, 35.260769552000056],
            [-81.079633018999971, 35.260790520000057],
            [-81.079403964999983, 35.260816409000029],
            [-81.079175816999964, 35.260847198000079],
            [-81.078948725999965, 35.260882870000046],
            [-81.078722850999952, 35.260923398000045],
            [-81.07790451599999, 35.261079445000064],
            [-81.077265662999935, 35.260576942000057],
            [-81.076996649999955, 35.260373927000046],
            [-81.076622501999964, 35.260117755000067],
            [-81.076231719999953, 35.259878877000062],
            [-81.075825488999953, 35.25965801600006],
            [-81.075405043999979, 35.259455845000048],
            [-81.07497166099995, 35.25927297700008],
            [-81.074526655999989, 35.25910996600004],
            [-81.074457277999954, 35.259086693000029],
            [-81.073430623999968, 35.258746442000074],
            [-81.073191749999978, 35.258670556000027],
            [-81.072950297999967, 35.258600375000071],
            [-81.072706467999978, 35.258535959000028],
            [-81.072460466999985, 35.258477363000054],
            [-81.072212499999978, 35.25842463400005],
            [-81.071962775999964, 35.258377818000042],
            [-81.07072113199996, 35.25816050800006],
            [-81.070389597999963, 35.25810788800004],
            [-81.069907315999956, 35.258050328000024],
            [-81.069421899999952, 35.25801464500006],
            [-81.068934826999964, 35.258000947000028],
            [-81.068447570999979, 35.258009277000042],
            [-81.067961615999934, 35.258039609000036],
            [-81.067478433999952, 35.258091850000028],
            [-81.066999493999958, 35.258165843000029],
            [-81.066526249999981, 35.258261362000042],
            [-81.066419164999957, 35.258286157000043],
            [-81.064250822999952, 35.258800292000046],
            [-81.063891791999936, 35.258892246000073],
            [-81.063769177999973, 35.258926835000068],
            [-81.058283041999971, 35.260511292000047],
            [-81.057948071999988, 35.260614316000044],
            [-81.05750041899995, 35.260772390000056],
            [-81.057064059999959, 35.260950448000074],
            [-81.056640320999975, 35.261147949000076],
            [-81.056513706999965, 35.261212009000076],
            [-81.055795901999943, 35.261582050000072],
            [-81.055512680999982, 35.261734334000039],
            [-81.055117994999989, 35.261968866000075],
            [-81.054739658999949, 35.262220873000047],
            [-81.054378819999954, 35.262489591000076],
            [-81.054036574999941, 35.262774203000049],
            [-81.05371396399994, 35.263073845000065],
            [-81.053633887999979, 35.263153633000059],
            [-81.052853888999948, 35.263942195000027],
            [-81.052701494999951, 35.264100718000066],
            [-81.052554537999981, 35.264262658000064],
            [-81.05241313099998, 35.264427889000046],
            [-81.052277385999957, 35.26459628300006],
            [-81.052147404999971, 35.264767710000058],
            [-81.052023289999966, 35.264942036000036],
            [-81.048513515999957, 35.270032730000025],
            [-81.044515280999974, 35.270364714000038],
            [-81.044238905999975, 35.270391264000068],
            [-81.043963684999937, 35.270424931000036],
            [-81.043689892999964, 35.270465682000065],
            [-81.042458610999972, 35.270665388000054],
            [-81.042207592999944, 35.270709212000042],
            [-81.041958230999967, 35.270758990000047],
            [-81.041710734999981, 35.270814679000068],
            [-81.041465312999946, 35.270876233000024],
            [-81.041222169999969, 35.270943600000066],
            [-81.040981513999952, 35.271016724000049],
            [-81.040187868999965, 35.271268667000072],
            [-81.040109808999944, 35.271293796000066],
            [-81.039669741999944, 35.271448889000055],
            [-81.038513260999935, 35.271887249000031],
            [-81.038072960999955, 35.272066695000035],
            [-81.03764911899998, 35.272264130000053],
            [-81.037239179999972, 35.27248041100006],
            [-81.036844388999953, 35.272714883000049],
            [-81.036465945999964, 35.272966832000066],
            [-81.036104999999964, 35.273235495000051],
            [-81.035762645999966, 35.273520055000063],
            [-81.035439924999935, 35.273819648000028],
            [-81.035169239999959, 35.27409902900007],
            [-81.031487260999938, 35.278097141000046],
            [-81.031455833999985, 35.278131475000066],
            [-81.031434738999963, 35.278154761000053],
            [-81.02865785399996, 35.281232726000042],
            [-81.028588977999959, 35.281291561000046],
            [-81.027245268999934, 35.281951392000053],
            [-81.02642962799996, 35.282315697000058],
            [-81.02187553899995, 35.283272362000048],
            [-81.017951195999956, 35.283907983000063],
            [-81.01567202199999, 35.283945956000025],
            [-81.015420227999982, 35.283953094000026],
            [-81.012941585999954, 35.284054167000079],
            [-81.012613592999969, 35.284072554000034],
            [-81.011524492999968, 35.284150297000053],
            [-81.011382660999971, 35.284161366000035],
            [-81.010899287999962, 35.28421338000004],
            [-81.010420142999976, 35.284287149000079],
            [-81.009946679999985, 35.284382446000052],
            [-81.009791561999975, 35.284418660000028],
            [-81.008996218999982, 35.284610782000073],
            [-81.008684992999974, 35.284691103000057],
            [-81.008227183999963, 35.284828523000044],
            [-81.007779300999971, 35.28498641200008],
            [-81.007342703999939, 35.285164289000079],
            [-81.006918717999952, 35.285361616000046],
            [-81.006508631999964, 35.285577793000073],
            [-81.00611368899996, 35.285812164000049],
            [-81.005735089999973, 35.286064017000058],
            [-81.005373983999959, 35.286332587000061],
            [-81.00503146799997, 35.286617060000026],
            [-81.004708581999978, 35.286916570000074],
            [-81.004406306999954, 35.28723020800004],
            [-81.004237632999946, 35.287421937000033],
            [-81.003672114999972, 35.288087336000046],
            [-81.003534479999985, 35.288254167000048],
            [-81.00340254799994, 35.288424067000051],
            [-81.003276418999974, 35.288596906000066],
            [-81.00315619099996, 35.288772548000054],
            [-81.003041956999937, 35.288950860000057],
            [-81.002625485999943, 35.289623453000047],
            [-81.001504897999951, 35.289667891000079],
            [-80.998336978999987, 35.289720226000043],
            [-80.997196948999942, 35.289276149000045],
            [-80.994748347999973, 35.288095652000038],
            [-80.99358145299999, 35.287503489000073],
            [-80.99319419599999, 35.287317927000061],
            [-80.992760849999968, 35.287134765000076],
            [-80.992315856999937, 35.286971453000035],
            [-80.991860569999972, 35.286828487000037],
            [-80.991721329999962, 35.286789398000053],
            [-80.988426176999951, 35.285889334000046],
            [-80.988101223999934, 35.285806228000069],
            [-80.987925884999981, 35.285766002000059],
            [-80.985658883999974, 35.285266454000066],
            [-80.985362531999954, 35.28520562600005],
            [-80.985068783999964, 35.285154026000043],
            [-80.983389272999943, 35.284883358000059],
            [-80.983205262999945, 35.284855356000037],
            [-80.982722883999941, 35.284797448000063],
            [-80.982237346999966, 35.284761416000038],
            [-80.981750127999987, 35.28474736700008],
            [-80.98126270299997, 35.284755346000054],
            [-80.981152981999969, 35.284760185000039],
            [-80.979623774999936, 35.284835418000057],
            [-80.975060719999988, 35.284816426000077],
            [-80.97316260499997, 35.283654829000056],
            [-80.972979778999957, 35.283349574000056],
            [-80.97285809899995, 35.283153949000052],
            [-80.972729252999954, 35.282961434000072],
            [-80.972593360999952, 35.282772204000025],
            [-80.972450543999969, 35.282586435000042],
            [-80.972300936999943, 35.282404295000049],
            [-80.97155223499999, 35.28152190000003],
            [-80.97138984299994, 35.281336783000029],
            [-80.971220435999953, 35.281155943000044],
            [-80.971044180999968, 35.28097955800007],
            [-80.97007595499997, 35.280041088000075],
            [-80.96987672399996, 35.279854538000052],
            [-80.969539504999943, 35.279565865000052],
            [-80.969183405999956, 35.279292862000034],
            [-80.968809510999961, 35.279036357000052],
            [-80.968418954999947, 35.278797132000079],
            [-80.968012921999957, 35.278575910000029],
            [-80.967592647999936, 35.27837336500005],
            [-80.96715940699994, 35.278190112000061],
            [-80.966723817999934, 35.27802987900003],
            [-80.964885828999968, 35.277402034000033],
            [-80.964769874999945, 35.277363272000059],
            [-80.959602577999988, 35.27567072800008],
            [-80.948659092999947, 35.271989903000076],
            [-80.942357493999964, 35.269845021000037],
            [-80.941091698999969, 35.269351113000027],
            [-80.94068548499996, 35.269202759000052],
            [-80.940230387999975, 35.269059598000069],
            [-80.939766366999947, 35.268937214000061],
            [-80.939294830999984, 35.268835977000037],
            [-80.938817211999947, 35.268756195000037],
            [-80.938465356999984, 35.268711624000048],
            [-80.935621849999961, 35.268399299000066],
            [-80.935491453999987, 35.268385782000053],
            [-80.935138846999962, 35.268357275000028],
            [-80.92401745899997, 35.267641739000055],
            [-80.907068160999984, 35.266480475000037],
            [-80.902562565999972, 35.266171606000057],
            [-80.901842579999936, 35.266061189000027],
            [-80.901642526999979, 35.265991104000079],
            [-80.900585561999947, 35.265522806000035],
            [-80.899392159999934, 35.263655464000067],
            [-80.899315157999979, 35.263537627000062],
            [-80.892678419999982, 35.253600997000035],
            [-80.89221680199995, 35.252783077000061],
            [-80.891669460999935, 35.251335908000044],
            [-80.891211225999939, 35.24949313500008],
            [-80.890900921999958, 35.24812686100006],
            [-80.890847298999972, 35.247909400000026],
            [-80.890785594999954, 35.247693385000048],
            [-80.890715865999937, 35.247479019000025],
            [-80.89063817899995, 35.247266504000038],
            [-80.890552605999972, 35.247056035000071],
            [-80.890459225999962, 35.246847813000045],
            [-80.89015330899997, 35.246196566000037],
            [-80.890029915999946, 35.245947713000078],
            [-80.889825714999972, 35.245584757000074],
            [-80.889597452999965, 35.245231580000052],
            [-80.889345823999975, 35.244889254000043],
            [-80.889071591999937, 35.244558819000076],
            [-80.888775588999977, 35.244241278000061],
            [-80.888458715999946, 35.24393759600008],
            [-80.888121934999958, 35.243648694000058],
            [-80.887766265999971, 35.24337545000003],
            [-80.887392792999947, 35.243118692000053],
            [-80.887232112999982, 35.243016715000067],
            [-80.886199541999986, 35.242376789000048],
            [-80.886008266999966, 35.242261522000035],
            [-80.885813290999977, 35.242150507000076],
            [-80.88561475299997, 35.242043825000053],
            [-80.885412798999937, 35.241941552000071],
            [-80.882471575999944, 35.240496380000025],
            [-80.882259208999983, 35.240395290000038],
            [-80.882043504999956, 35.240299075000053],
            [-80.881824626999958, 35.240207810000072],
            [-80.881602745999942, 35.240121567000074],
            [-80.879028127999959, 35.239156471000058],
            [-80.878824101999953, 35.238826756000037],
            [-80.878699275999963, 35.238632381000059],
            [-80.878567333999968, 35.238441196000053],
            [-80.878428394999958, 35.238253377000035],
            [-80.878282587999934, 35.238069096000061],
            [-80.878130044999978, 35.237888523000038],
            [-80.877719386999956, 35.237417787000027],
            [-80.877698823999935, 35.237394315000074],
            [-80.877402890999974, 35.237076745000024],
            [-80.877086088999988, 35.236773033000077],
            [-80.876749376999953, 35.236484099000052],
            [-80.876393777999965, 35.236210821000043],
            [-80.87602037299996, 35.235954028000037],
            [-80.875630296999987, 35.235714502000064],
            [-80.875224730999946, 35.235492968000074],
            [-80.874934462999988, 35.235349993000057],
            [-80.874109716999953, 35.234961173000045],
            [-80.872821819999956, 35.233121155000049],
            [-80.871901208999986, 35.231554207000045],
            [-80.871857226999964, 35.231480422000061],
            [-80.871514395999952, 35.230914404000032],
            [-80.871396458999982, 35.23072660400004],
            [-80.871271908999972, 35.230541702000039],
            [-80.871140847999982, 35.230359854000028],
            [-80.871003388999952, 35.230181214000027],
            [-80.870859648999954, 35.230005935000065],
            [-80.870336661999943, 35.229387814000063],
            [-80.870211333999976, 35.229243483000062],
            [-80.870081747999961, 35.229101707000041],
            [-80.86994797899996, 35.228962572000057],
            [-80.869810107999967, 35.22882615900005],
            [-80.869141139999954, 35.228180470000041],
            [-80.868961764999938, 35.228012638000052],
            [-80.868625117999954, 35.227723681000043],
            [-80.86826958499995, 35.227450378000071],
            [-80.867896245999987, 35.227193560000046],
            [-80.867599152999958, 35.227008609000052],
            [-80.865790947999983, 35.22593144700005],
            [-80.863453410999966, 35.224541212000076],
            [-80.862001468999949, 35.223646777000056],
            [-80.861794980999946, 35.223523358000079],
            [-80.861389512999949, 35.223301777000074],
            [-80.860969788999967, 35.22309886000005],
            [-80.860537081999951, 35.22291522300003],
            [-80.860092704999943, 35.22275142400008],
            [-80.85963800899998, 35.222607958000026],
            [-80.859174373999963, 35.222485264000056],
            [-80.858703206999962, 35.222383713000056],
            [-80.858225939999954, 35.222303613000065],
            [-80.857744020999974, 35.222245206000025],
            [-80.857258911999963, 35.222208673000068],
            [-80.856772088999946, 35.22219412100003],
            [-80.856285026999956, 35.222201597000037],
            [-80.855799207999951, 35.22223107700006],
            [-80.85531610299995, 35.222282471000028],
            [-80.854837181999983, 35.222355625000034],
            [-80.854363898999964, 35.222450314000071],
            [-80.85389768899995, 35.222566253000025],
            [-80.853439968999965, 35.22270308800006],
            [-80.852992127999983, 35.222860406000052],
            [-80.852555525999946, 35.223037727000076],
            [-80.852131489999977, 35.223234514000069],
            [-80.851721305999945, 35.223450168000056],
            [-80.851326218999986, 35.223684037000055],
            [-80.850947430999952, 35.223935407000056],
            [-80.850586089999979, 35.224203519000071],
            [-80.850243294999984, 35.224487556000042],
            [-80.849920086999987, 35.224786656000049],
            [-80.849617446999957, 35.225099911000029],
            [-80.849336292999965, 35.225426371000026],
            [-80.849077480999938, 35.225765042000035],
            [-80.848841796999977, 35.226114899000038],
            [-80.848629955999968, 35.226474877000044],
            [-80.848442601999977, 35.226843884000061],
            [-80.848280302999967, 35.227220799000065],
            [-80.848143554999979, 35.227604478000046],
            [-80.848032771999954, 35.227993755000057],
            [-80.847948290999966, 35.228387449000024],
            [-80.84789036799998, 35.228784364000035],
            [-80.84785918099999, 35.229183293000062],
            [-80.847854823999967, 35.229583027000047],
            [-80.847877310999934, 35.229982351000046],
            [-80.847926573999985, 35.230380052000044],
            [-80.848002462999943, 35.23077492200008],
            [-80.848104748999958, 35.231165763000035],
            [-80.848233121999954, 35.231551387000025],
            [-80.848387190999972, 35.23193062200005],
            [-80.848566489999939, 35.232302317000062],
            [-80.848770473999934, 35.232665343000065],
            [-80.848998522999977, 35.233018598000058],
            [-80.849249945999986, 35.233361009000078],
            [-80.849523978999969, 35.233691534000059],
            [-80.84981978999997, 35.234009172000071],
            [-80.850136479999946, 35.234312956000053],
            [-80.850473088999934, 35.234601964000035],
            [-80.85082859399995, 35.234875318000036],
            [-80.851201913999944, 35.235132189000069],
            [-80.851385413999935, 35.235248358000035],
            [-80.852896721999969, 35.236179437000033],
            [-80.853016036999975, 35.236251667000033],
            [-80.855407381999953, 35.237674008000056],
            [-80.856115012999965, 35.238095587000032],
            [-80.857031224999957, 35.239655557000049],
            [-80.857125751999945, 35.239811673000077],
            [-80.857224823999957, 35.239965879000067],
            [-80.85732838399997, 35.240118087000042],
            [-80.859077163999984, 35.242617232000043],
            [-80.859678474999953, 35.243607476000079],
            [-80.859896681999942, 35.243944320000026],
            [-80.860148183999968, 35.244286707000072],
            [-80.860422297999946, 35.244617207000033],
            [-80.860718191999979, 35.244934818000047],
            [-80.861034964999988, 35.245238573000051],
            [-80.86137165699995, 35.245527550000077],
            [-80.861727245999987, 35.245800872000075],
            [-80.862100650999935, 35.246057708000023],
            [-80.862334059999966, 35.246204349000038],
            [-80.862813255999981, 35.246495165000056],
            [-80.86303299399998, 35.246624303000033],
            [-80.863257440999973, 35.246747870000036],
            [-80.863486386999966, 35.24686575100003],
            [-80.863719619999983, 35.246977837000031],
            [-80.863956920999954, 35.247084020000045],
            [-80.864179492999938, 35.247180021000077],
            [-80.864376419999985, 35.247448076000069],
            [-80.864391489999946, 35.247467298000061],
            [-80.865364936999981, 35.248704704000033],
            [-80.865624016999959, 35.249015970000073],
            [-80.865919946999952, 35.249333568000054],
            [-80.866236755999978, 35.249637309000036],
            [-80.866573484999947, 35.249926271000049],
            [-80.86692911099999, 35.250199577000046],
            [-80.867302551999956, 35.25045639800004],
            [-80.867692674999944, 35.250695950000079],
            [-80.86809829699996, 35.250917509000033],
            [-80.868518181999946, 35.251120401000037],
            [-80.868951055999958, 35.251304008000034],
            [-80.868989610999961, 35.251319178000074],
            [-80.87045686099998, 35.251892892000058],
            [-80.870586088999971, 35.251942376000045],
            [-80.873805767999954, 35.25314934000005],
            [-80.874107119999962, 35.25329742100007],
            [-80.874427913999966, 35.254588566000052],
            [-80.874474569999961, 35.254765087000067],
            [-80.874526568999954, 35.254940598000076],
            [-80.874583880999978, 35.255114995000042],
            [-80.87464646899997, 35.255288166000071],
            [-80.875498147999963, 35.257541236000066],
            [-80.875570644999982, 35.257724387000053],
            [-80.875649043999942, 35.257905894000032],
            [-80.875733288999982, 35.258085630000039],
            [-80.875823322999963, 35.258263470000031],
            [-80.875919077999981, 35.258439286000055],
            [-80.876752959999976, 35.259917321000046],
            [-80.87685387099998, 35.260090151000043],
            [-80.877022056999976, 35.260354861000053],
            [-80.883761878999962, 35.270448736000048],
            [-80.885836837999989, 35.273696378000068],
            [-80.885973910999951, 35.273902709000026],
            [-80.886225614999944, 35.274245040000039],
            [-80.88649993699994, 35.274575480000067],
            [-80.886796040999968, 35.27489302500004],
            [-80.887113027999987, 35.275196710000046],
            [-80.887449935999939, 35.275485613000058],
            [-80.887537152999982, 35.275555422000025],
            [-80.888352056999963, 35.276198736000026],
            [-80.88862065099994, 35.27640216900005],
            [-80.888994281999942, 35.276658921000035],
            [-80.889384593999978, 35.276898402000029],
            [-80.889790404999985, 35.277119888000072],
            [-80.889880584999958, 35.277165603000071],
            [-80.891499392999947, 35.277975256000047],
            [-80.891829292999944, 35.278132352000057],
            [-80.891900302999943, 35.278164160000074],
            [-80.893590463999942, 35.278913033000038],
            [-80.893952531999958, 35.279064743000049],
            [-80.894319958999972, 35.279201681000075],
            [-80.895786500999975, 35.279715469000053],
            [-80.895863826999971, 35.279742203000069],
            [-80.896318905999976, 35.279885531000048],
            [-80.896782923999979, 35.280008082000052],
            [-80.897254471999986, 35.280109484000036],
            [-80.897635389999948, 35.280175050000025],
            [-80.899752127999989, 35.280499679000059],
            [-80.899973135999971, 35.280531198000062],
            [-80.900195046999954, 35.280558111000062],
            [-80.900417718999961, 35.280580402000055],
            [-80.900641008999969, 35.280598054000052],
            [-80.905594333999943, 35.280937625000035],
            [-80.922567757999957, 35.282100538000066],
            [-80.922611097999948, 35.282103416000041],
            [-80.933513855999934, 35.282804897000062],
            [-80.934742495999956, 35.282939855000052],
            [-80.934958534999964, 35.283024153000042],
            [-80.93536630899996, 35.283173038000029],
            [-80.94189404499997, 35.285394955000072],
            [-80.941927506999946, 35.285406279000028],
            [-80.952926540999954, 35.289105880000079],
            [-80.953001383999947, 35.289130723000028],
            [-80.957640348999973, 35.290650264000078],
            [-80.958158495999953, 35.291515643000025],
            [-80.958175326999935, 35.291543599000079],
            [-80.958404015999974, 35.291896644000076],
            [-80.958656079999969, 35.292238824000037],
            [-80.958930755999972, 35.292569098000058],
            [-80.959227207999959, 35.292886465000038],
            [-80.959302206999951, 35.292961302000037],
            [-80.95960023899994, 35.293254674000025],
            [-80.959842568999989, 35.293483331000061],
            [-80.960179811999978, 35.29377203100006],
            [-80.960535942999968, 35.29404506000003],
            [-80.960909880999964, 35.294301589000042],
            [-80.96129184199998, 35.294535833000054],
            [-80.962599577999981, 35.295293611000034],
            [-80.965829934999988, 35.297270619000074],
            [-80.966024820999962, 35.297386533000065],
            [-80.966430941999988, 35.297607761000052],
            [-80.96685131299995, 35.297810309000056],
            [-80.967284657999983, 35.297993563000034],
            [-80.967540525999937, 35.298090463000051],
            [-80.968641393999974, 35.298490043000072],
            [-80.968830530999981, 35.298556545000054],
            [-80.969285842999966, 35.298699598000042],
            [-80.969750077999947, 35.298821869000051],
            [-80.970221825999943, 35.298922986000036],
            [-80.97069965299994, 35.299002644000041],
            [-80.970999002999974, 35.299041223000074],
            [-80.97282275699996, 35.299250077000067],
            [-80.973057420999965, 35.299274332000039],
            [-80.973292791999938, 35.299293434000049],
            [-80.973528700999964, 35.299307373000033],
            [-80.973764980999988, 35.299316137000062],
            [-80.974001462999979, 35.299319720000028],
            [-80.979821885999968, 35.299343961000034],
            [-80.98028672099997, 35.299335871000039],
            [-80.980396461999987, 35.299331033000044],
            [-80.981085047999954, 35.299297160000037],
            [-80.981358639999939, 35.299341251000044]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 111,
        "SolutionID": "PI-11:C",
        "Shape_Length": 0.80847806082074702,
        "Shape_Area": 0.0060468920597629384
      }
    },
    {
      "type": "Feature",
      "id": 112,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.951453739999977, 34.959764517000053],
            [-80.951166095999952, 34.960064598000031],
            [-80.951121304999958, 34.960114388000079],
            [-80.949811419999946, 34.961583502000053],
            [-80.949667692999981, 34.96174953700006],
            [-80.949529690999952, 34.961918832000038],
            [-80.94939752199997, 34.962091254000029],
            [-80.949271290999945, 34.962266664000026],
            [-80.947810579999953, 34.964363026000058],
            [-80.945848134999949, 34.967064273000062],
            [-80.945742906999953, 34.967213091000076],
            [-80.945641998999974, 34.96736392400004],
            [-80.945545468999967, 34.967516685000078],
            [-80.945453369999939, 34.967671289000066],
            [-80.944626575999962, 34.969101889000058],
            [-80.944100911999953, 34.969933533000074],
            [-80.944087506999949, 34.969954825000059],
            [-80.943876749999959, 34.970314981000058],
            [-80.943690412999956, 34.970684146000053],
            [-80.943529062999971, 34.971061200000065],
            [-80.943393190999984, 34.971444998000038],
            [-80.943283208999958, 34.971834375000071],
            [-80.943211767999969, 34.972161264000079],
            [-80.942977337999935, 34.973395426000025],
            [-80.941907240999967, 34.977410035000048],
            [-80.940392796999959, 34.982753917000025],
            [-80.940347193999969, 34.98292402800007],
            [-80.940306566999936, 34.983094992000076],
            [-80.940270939999948, 34.983266709000077],
            [-80.940240331999973, 34.983439081000029],
            [-80.939879110999982, 34.985659354000063],
            [-80.939847474999965, 34.985879275000059],
            [-80.939824008999949, 34.986099888000069],
            [-80.939808737999954, 34.986320989000035],
            [-80.939801673999966, 34.986542370000052],
            [-80.939802823999969, 34.986763825000025],
            [-80.93982506399999, 34.987700356000062],
            [-80.939847933999943, 34.988099674000068],
            [-80.939897497999937, 34.988497349000056],
            [-80.939973607999946, 34.988892173000067],
            [-80.94007603099999, 34.989282947000049],
            [-80.940129967999951, 34.989455158000055],
            [-80.940526984999963, 34.990661364000061],
            [-80.941135538999958, 34.992881373000046],
            [-80.940878903999987, 34.993402042000071],
            [-80.940020317999938, 34.994887003000031],
            [-80.938612326999987, 34.997243702000048],
            [-80.938297109999951, 34.997677336000038],
            [-80.938263636999977, 34.997723774000065],
            [-80.938028993999978, 34.998073814000065],
            [-80.937818136999965, 34.998433959000067],
            [-80.937631708999959, 34.998803114000054],
            [-80.937470274999953, 34.999180159000048],
            [-80.937387739999963, 34.999403602000029],
            [-80.937000120999983, 35.000517056000035],
            [-80.93692828199994, 35.000736320000044],
            [-80.936864819999983, 35.000957326000048],
            [-80.936809794999988, 35.001179859000047],
            [-80.936763262999989, 35.001403701000072],
            [-80.936725267999975, 35.001628630000027],
            [-80.936695848999989, 35.001854428000058],
            [-80.936610546999987, 35.002622343000041],
            [-80.936354465999955, 35.004927621000036],
            [-80.936323625999989, 35.005328122000037],
            [-80.936319712999989, 35.005727870000044],
            [-80.93635937199997, 35.007400557000039],
            [-80.935924819999968, 35.010148441000069],
            [-80.935631143999956, 35.011591944000031],
            [-80.934268937999946, 35.012149818000069],
            [-80.934064215999967, 35.012236419000033],
            [-80.933641470999987, 35.012433496000028],
            [-80.933232560999954, 35.012649432000046],
            [-80.932838727999979, 35.012883573000067],
            [-80.932461167999975, 35.013135205000026],
            [-80.932101027999977, 35.013403567000069],
            [-80.93184301499997, 35.013615328000071],
            [-80.930726820999951, 35.014570080000055],
            [-80.929306173999976, 35.015785203000064],
            [-80.929117875999964, 35.015951388000076],
            [-80.92893599599995, 35.016122343000063],
            [-80.928760710999939, 35.01629790000004],
            [-80.928592192999986, 35.016477885000029],
            [-80.928430608999975, 35.01666212300006],
            [-80.927046330999985, 35.018293683000024],
            [-80.926428859999987, 35.018897480000078],
            [-80.926167774999954, 35.019071328000052],
            [-80.925978716999964, 35.019200848000025],
            [-80.925690557999985, 35.019413114000031],
            [-80.925469388999943, 35.019583368000042],
            [-80.92468108199995, 35.019942965000041],
            [-80.924463980999974, 35.020045337000056],
            [-80.924250496999946, 35.020152735000067],
            [-80.924040802999968, 35.020265074000065],
            [-80.92383506799996, 35.020382261000066],
            [-80.923633456999937, 35.020504204000076],
            [-80.922511059999977, 35.021203462000074],
            [-80.922475744999986, 35.021225579000031],
            [-80.922098114999983, 35.021477179000044],
            [-80.92173790399994, 35.021745510000073],
            [-80.92139620599994, 35.022029756000052],
            [-80.921074059999967, 35.022329053000078],
            [-80.920772442999976, 35.022642494000024],
            [-80.920604122999976, 35.02283410900003],
            [-80.919914150999944, 35.023647147000077],
            [-80.918825667999954, 35.024811861000046],
            [-80.918671040999982, 35.024982434000037],
            [-80.918522561999964, 35.025156664000065],
            [-80.91838035699999, 35.025334401000066],
            [-80.918244550999987, 35.025515493000057],
            [-80.91811525899999, 35.025699782000061],
            [-80.917414746999953, 35.026732967000044],
            [-80.917283429999941, 35.026934014000062],
            [-80.917159865999963, 35.027138360000038],
            [-80.91704417699998, 35.02734580200007],
            [-80.916936478999958, 35.027556135000054],
            [-80.916836874999944, 35.02776915100003],
            [-80.916745468999977, 35.027984638000078],
            [-80.916185420999966, 35.02937483200003],
            [-80.915624960999935, 35.03068027900008],
            [-80.915545798999972, 35.030873248000034],
            [-80.915473912999971, 35.031066108000061],
            [-80.914988326999946, 35.032436548000078],
            [-80.914928756999984, 35.032612920000076],
            [-80.914874615999963, 35.032790473000034],
            [-80.914825937999979, 35.032969094000066],
            [-80.914782756999955, 35.033148667000034],
            [-80.91474509599999, 35.033329079000055],
            [-80.914283440999952, 35.035721187000036],
            [-80.914276195999946, 35.035759386000052],
            [-80.913474879999967, 35.040058991000024],
            [-80.912377737999975, 35.044705058000034],
            [-80.912329434999947, 35.044928155000036],
            [-80.91201618499997, 35.046518509000066],
            [-80.911837164999952, 35.047116631000051],
            [-80.910951730999955, 35.049080393000054],
            [-80.909396806999951, 35.052376390000063],
            [-80.90938566799997, 35.05240012400003],
            [-80.909369015999971, 35.05243607500006],
            [-80.908061111999984, 35.055282348000048],
            [-80.907238775999986, 35.056849227000043],
            [-80.905923847999986, 35.059041782000065],
            [-80.904010912999979, 35.061921247000043],
            [-80.903977678999979, 35.061971745000051],
            [-80.901677436999989, 35.065499987000067],
            [-80.901615018999962, 35.065597493000041],
            [-80.90148796699998, 35.065807967000069],
            [-80.900492102999976, 35.067527055000028],
            [-80.900407958999949, 35.067676659000028],
            [-80.900328040999966, 35.067827656000077],
            [-80.899931662999961, 35.068600428000025],
            [-80.89982482399995, 35.06881852500004],
            [-80.899663076999957, 35.069195517000026],
            [-80.899526834999961, 35.069579262000047],
            [-80.899416511999959, 35.069968594000045],
            [-80.899332441999945, 35.070362333000048],
            [-80.899274881999986, 35.070759280000061],
            [-80.899266893999936, 35.070835616000068],
            [-80.899206398999979, 35.071451821000039],
            [-80.899065102999941, 35.072241703000032],
            [-80.899042948999977, 35.07239461100005],
            [-80.897723563999989, 35.074052735000066],
            [-80.897523080999974, 35.074316888000055],
            [-80.896058387999972, 35.076342210000064],
            [-80.893696571999953, 35.077085533000059],
            [-80.893600266999954, 35.07711637400007],
            [-80.893322574999956, 35.077211379000062],
            [-80.890342812999961, 35.078280108000058],
            [-80.89029287599999, 35.07829816900005],
            [-80.888340847999984, 35.079010073000063],
            [-80.888221506999969, 35.079054470000074],
            [-80.887785750999967, 35.079231922000076],
            [-80.887362546999952, 35.079428836000034],
            [-80.886953178999988, 35.079644614000074],
            [-80.886558888999957, 35.079878602000065],
            [-80.886491353999986, 35.079921482000032],
            [-80.885674598999969, 35.080445259000044],
            [-80.885488274999943, 35.080568115000062],
            [-80.885306063999963, 35.080695072000026],
            [-80.885128097999939, 35.080826036000076],
            [-80.884954509999943, 35.080960911000034],
            [-80.884785424999961, 35.081099599000027],
            [-80.884620966999989, 35.081241998000053],
            [-80.884461258999977, 35.08138800200004],
            [-80.883447156999978, 35.082340694000038],
            [-80.88327604899996, 35.082506382000076],
            [-80.883111054999972, 35.082676209000056],
            [-80.882952323999973, 35.082850024000038],
            [-80.88279999699995, 35.083027668000057],
            [-80.882654212999967, 35.083208985000056],
            [-80.88256318599997, 35.083325969000043],
            [-80.88222622699999, 35.08365490400007],
            [-80.882213022999963, 35.083667824000031],
            [-80.881911030999959, 35.083981166000058],
            [-80.881630492999989, 35.08430770700005],
            [-80.881372261999957, 35.084646454000051],
            [-80.881137123999963, 35.084996380000064],
            [-80.880925791999971, 35.085356422000075],
            [-80.880746876999979, 35.085708502000045],
            [-80.879857834999939, 35.087596081000072],
            [-80.879849865999972, 35.087613065000028],
            [-80.879738292999946, 35.087865590000035],
            [-80.878212603999941, 35.091528611000058],
            [-80.876918596999985, 35.094340608000039],
            [-80.876067927999941, 35.096061084000041],
            [-80.876006013999984, 35.096189809000066],
            [-80.875906555999961, 35.096413394000024],
            [-80.875218602999951, 35.098042300000031],
            [-80.875133434999952, 35.098254437000037],
            [-80.875056256999983, 35.098468628000035],
            [-80.874987140999963, 35.098684670000068],
            [-80.874602111999934, 35.099968191000073],
            [-80.874570150999944, 35.100078433000078],
            [-80.874187063999955, 35.101447091000068],
            [-80.87412980299996, 35.101667253000073],
            [-80.874080879999951, 35.101888768000038],
            [-80.874040345999958, 35.102111424000043],
            [-80.874008239999966, 35.102335007000079],
            [-80.873984590999953, 35.102559301000042],
            [-80.873969422999949, 35.102784090000057],
            [-80.873899073999951, 35.104233063000038],
            [-80.87389785199997, 35.104259447000061],
            [-80.873893628999951, 35.104659189000074],
            [-80.873938614999986, 35.106617390000054],
            [-80.873961195999982, 35.107016716000032],
            [-80.873989945999938, 35.107273452000072],
            [-80.87436532299995, 35.110078220000048],
            [-80.874385889999985, 35.110219180000058],
            [-80.874432152999987, 35.110475921000045],
            [-80.874986931999956, 35.113215411000056],
            [-80.875151731999949, 35.114303948000043],
            [-80.875152551999975, 35.114339624000024],
            [-80.874530938999953, 35.115411302000041],
            [-80.873013240999967, 35.117776032000052],
            [-80.872889677999979, 35.11797598000004],
            [-80.872773694999978, 35.118178970000031],
            [-80.872665403999974, 35.118384809000077],
            [-80.872564906999969, 35.118593300000043],
            [-80.870684961999984, 35.122676459000047],
            [-80.869102946999988, 35.125568797000028],
            [-80.868915457999947, 35.125938682000026],
            [-80.868869116999974, 35.126039979000041],
            [-80.868178821999948, 35.12758369900007],
            [-80.868063155999948, 35.127859350000051],
            [-80.868039262999957, 35.127921184000058],
            [-80.867550352999956, 35.129206280000062],
            [-80.86743775399998, 35.129528152000034],
            [-80.867325616999949, 35.129923829000063],
            [-80.867134314999987, 35.130699660000062],
            [-80.867083784999977, 35.130922418000068],
            [-80.867041747999963, 35.131146363000028],
            [-80.867008244999965, 35.131371277000028],
            [-80.866983309999966, 35.131596942000044],
            [-80.866966966999939, 35.131823135000047],
            [-80.866959231999942, 35.132049639000059],
            [-80.866960110999969, 35.132276230000059],
            [-80.866979603999937, 35.133127620000039],
            [-80.867002157999934, 35.133526946000075],
            [-80.867023890999974, 35.133729452000068],
            [-80.867150924999976, 35.134749518000035],
            [-80.867180174999987, 35.134955311000056],
            [-80.867216565999968, 35.135160333000044],
            [-80.867260071999965, 35.135364418000052],
            [-80.867310654999983, 35.135567398000035],
            [-80.867717279999965, 35.137088205000055],
            [-80.868480974999954, 35.140817258000027],
            [-80.868938641999989, 35.143424897000045],
            [-80.869415285999935, 35.146287182000037],
            [-80.86948317699995, 35.146632593000049],
            [-80.869517260999942, 35.146774950000065],
            [-80.869632707999983, 35.14723288600004],
            [-80.869620991999966, 35.14743431800008],
            [-80.869492027999968, 35.148584631000062],
            [-80.868912728999987, 35.151641360000042],
            [-80.868377334999934, 35.153900112000031],
            [-80.868309222999983, 35.154227175000074],
            [-80.868257204999964, 35.154574440000033],
            [-80.868083041999967, 35.156025003000025],
            [-80.868077288999984, 35.156074666000052],
            [-80.868055238999943, 35.156316688000061],
            [-80.867976933999955, 35.157425093000029],
            [-80.867967922999981, 35.157582011000045],
            [-80.867963667999959, 35.157981750000033],
            [-80.867986232999954, 35.158381074000033],
            [-80.868035550999934, 35.158778771000073],
            [-80.868091633999938, 35.159082982000029],
            [-80.868324344999962, 35.160186580000072],
            [-80.868344181999987, 35.160277230000077],
            [-80.868378268999948, 35.160419584000067],
            [-80.868720515999939, 35.161776994000036],
            [-80.868788725999934, 35.162025467000035],
            [-80.868917086999943, 35.162411073000044],
            [-80.86907112199998, 35.162790285000028],
            [-80.869250363999981, 35.163161954000032],
            [-80.869367334999936, 35.163376561000064],
            [-80.871096514999977, 35.16641588300007],
            [-80.871183452999958, 35.166564269000048],
            [-80.871227965999935, 35.166637058000049],
            [-80.874890189999974, 35.172542124000074],
            [-80.874991159999979, 35.172700082000063],
            [-80.880002709999985, 35.180310470000052],
            [-80.883409896999979, 35.185730703000047],
            [-80.883533802999978, 35.185920815000031],
            [-80.883664538999938, 35.186107819000028],
            [-80.88380198699997, 35.186291550000078],
            [-80.883946026999979, 35.186471846000074],
            [-80.885333593999974, 35.188153591000059],
            [-80.885484990999942, 35.188331300000073],
            [-80.885780777999969, 35.188648852000028],
            [-80.886097424999946, 35.188952544000074],
            [-80.886433973999942, 35.189241454000069],
            [-80.886780422999948, 35.189508165000063],
            [-80.888156806999973, 35.190512044000059],
            [-80.888399283999945, 35.190682331000062],
            [-80.889874929999962, 35.191681301000074],
            [-80.89001018099998, 35.191771039000059],
            [-80.890400088999968, 35.192010520000053],
            [-80.890805479999983, 35.192232005000051],
            [-80.891225118999955, 35.192434820000074],
            [-80.891657733999978, 35.192618348000053],
            [-80.892102008999984, 35.192782032000025],
            [-80.89229919099995, 35.192847112000038],
            [-80.896375716999955, 35.194145947000038],
            [-80.896448415999942, 35.19416880600005],
            [-80.900031775999935, 35.195280645000025],
            [-80.900505803999977, 35.19543578300005],
            [-80.902435998999977, 35.203291686000057],
            [-80.904024255999957, 35.210581868000077],
            [-80.904277696999941, 35.211968599000045],
            [-80.904290475999971, 35.212515224000072],
            [-80.904250196999953, 35.21295066700003],
            [-80.904179495999983, 35.213107766000064],
            [-80.903799597999978, 35.213557378000075],
            [-80.903359003999981, 35.213967350000075],
            [-80.902902249999954, 35.214305016000026],
            [-80.902130823999983, 35.214669189000062],
            [-80.901172740999982, 35.21500130700008],
            [-80.898481323999988, 35.215570014000036],
            [-80.898351869999942, 35.215598227000044],
            [-80.897885761999987, 35.21571433500003],
            [-80.897428153999954, 35.215851337000061],
            [-80.896980435999978, 35.216008818000034],
            [-80.896543966999957, 35.216186298000025],
            [-80.896120071999974, 35.216383239000038],
            [-80.895710039999983, 35.216599043000031],
            [-80.895315111999935, 35.216833055000052],
            [-80.894936491999943, 35.217084564000061],
            [-80.894575325999938, 35.217352807000054],
            [-80.894232712999951, 35.217636969000068],
            [-80.893909693999944, 35.217936187000078],
            [-80.893607247999967, 35.218249552000032],
            [-80.893326294999952, 35.21857611400003],
            [-80.893067686999984, 35.218914880000057],
            [-80.892832211999973, 35.21926482300006],
            [-80.892620582999939, 35.219624879000037],
            [-80.892433443999948, 35.219993954000074],
            [-80.892271362999963, 35.220370928000079],
            [-80.892134832999943, 35.220754657000043],
            [-80.892024269999979, 35.221143976000064],
            [-80.891940008999939, 35.221537701000045],
            [-80.891882304999967, 35.221934636000071],
            [-80.891851335999945, 35.222333578000075],
            [-80.891847194999968, 35.222733314000038],
            [-80.891869895999946, 35.223132630000066],
            [-80.891919368999936, 35.223530313000026],
            [-80.891995465999969, 35.223925156000064],
            [-80.892097953999951, 35.224315960000069],
            [-80.892226523999966, 35.224701537000044],
            [-80.892380784999943, 35.225080717000026],
            [-80.89256026999999, 35.225452347000044],
            [-80.892764432999968, 35.225815300000079],
            [-80.892992653999954, 35.22616847200004],
            [-80.893244239999945, 35.226510792000056],
            [-80.893518428999982, 35.226841218000061],
            [-80.893814385999974, 35.227158749000068],
            [-80.89413121399997, 35.227462417000027],
            [-80.894467949999978, 35.227751303000048],
            [-80.89482357299994, 35.228024529000038],
            [-80.895197000999985, 35.228281264000032],
            [-80.895587098999954, 35.228520727000046],
            [-80.895992685999943, 35.228742193000073],
            [-80.896412525999949, 35.22894498900007],
            [-80.896845345999964, 35.229128497000033],
            [-80.897289830999966, 35.229292161000046],
            [-80.897744630999966, 35.229435485000067],
            [-80.89820836399997, 35.229558031000067],
            [-80.898679620999985, 35.229659429000037],
            [-80.899156970999968, 35.229739369000072],
            [-80.899638964999951, 35.229797610000048],
            [-80.900124136999978, 35.22983397400003],
            [-80.900611012999946, 35.229848351000044],
            [-80.901098116999947, 35.229840697000043],
            [-80.901583965999976, 35.229811035000068],
            [-80.902067084999942, 35.229759456000068],
            [-80.902546006999955, 35.229686116000039],
            [-80.902889802999937, 35.229619455000034],
            [-80.906214782999939, 35.228916857000058],
            [-80.906466575999957, 35.228860381000061],
            [-80.906716226999947, 35.228797830000076],
            [-80.906963517999941, 35.228729258000044],
            [-80.907208231999959, 35.22865472400008],
            [-80.90745015899995, 35.228574295000044],
            [-80.909509361999937, 35.227860458000066],
            [-80.909756773999959, 35.227771005000079],
            [-80.910000734999983, 35.227675386000044],
            [-80.910241017999965, 35.227573690000042],
            [-80.910477398999944, 35.227466011000047],
            [-80.912530549999985, 35.226496711000038],
            [-80.912688624999987, 35.226420278000035],
            [-80.913098661999982, 35.226204417000076],
            [-80.91349358399998, 35.225970347000043],
            [-80.91387218899996, 35.225718780000079],
            [-80.914039178999985, 35.225598405000028],
            [-80.915569468999934, 35.224466988000074],
            [-80.91575542299995, 35.22432548200004],
            [-80.91593635199996, 35.224179657000036],
            [-80.916112107999936, 35.224029632000054],
            [-80.91628254799997, 35.223875533000069],
            [-80.917399774999978, 35.222835802000077],
            [-80.91756715799994, 35.22267537700003],
            [-80.917728777999969, 35.222511022000049],
            [-80.917884498999967, 35.222342875000038],
            [-80.918034186999989, 35.222171079000077],
            [-80.919346828999949, 35.220617218000029],
            [-80.919442238999977, 35.220501923000029],
            [-80.919700741999975, 35.220163100000036],
            [-80.919936103999987, 35.219813105000071],
            [-80.920147612999983, 35.21945300200008],
            [-80.920334624999953, 35.219083884000042],
            [-80.920374556999946, 35.218996839000056],
            [-80.921262083999977, 35.217023858000061],
            [-80.921384094999951, 35.216733890000057],
            [-80.92152047999997, 35.216350128000045],
            [-80.921630895999954, 35.215960782000025],
            [-80.921715007999978, 35.215567036000039],
            [-80.921772561999944, 35.215170086000057],
            [-80.921783789999949, 35.215059634000056],
            [-80.921949087999963, 35.213268457000027],
            [-80.921968678999974, 35.212979960000041],
            [-80.921972670999935, 35.212580222000042],
            [-80.921940531999951, 35.211218029000065],
            [-80.921933120999938, 35.211029963000044],
            [-80.921919768999942, 35.210842119000063],
            [-80.921900484999981, 35.21065462100006],
            [-80.921875280999984, 35.210467598000037],
            [-80.921844175999979, 35.210281174000045],
            [-80.921486782999978, 35.208327964000034],
            [-80.921446506999985, 35.208127085000058],
            [-80.919817694999949, 35.200658071000078],
            [-80.919805645999986, 35.200603996000041],
            [-80.919781189999981, 35.200500701000067],
            [-80.917715786999963, 35.192101725000043],
            [-80.917670142999953, 35.191927176000036],
            [-80.917619276999972, 35.191753605000031],
            [-80.917563220999966, 35.191581112000051],
            [-80.917502008999975, 35.191409806000024],
            [-80.916837682999983, 35.189631785000074],
            [-80.916690394999989, 35.189271513000051],
            [-80.916510879999976, 35.188899917000072],
            [-80.916306703999965, 35.188537002000032],
            [-80.916078486999936, 35.188183872000025],
            [-80.916064048999942, 35.188163025000051],
            [-80.915419791999966, 35.18723637100004],
            [-80.915182671999958, 35.186914942000044],
            [-80.914908529999934, 35.186584563000054],
            [-80.914612636999948, 35.186267082000029],
            [-80.914295892999974, 35.185963465000043],
            [-80.914168029999985, 35.185849979000068],
            [-80.913109000999953, 35.184929880000027],
            [-80.912900235999984, 35.184754532000056],
            [-80.912544741999966, 35.184481358000028],
            [-80.912171459999968, 35.184224675000053],
            [-80.911781523999935, 35.183985263000068],
            [-80.911376117999964, 35.183763848000069],
            [-80.911320751999938, 35.183735618000071],
            [-80.909850972999948, 35.182992321000029],
            [-80.909632405999957, 35.182885319000036],
            [-80.909410175999938, 35.182783527000026],
            [-80.909184468999968, 35.182687032000047],
            [-80.908955470999956, 35.182595913000057],
            [-80.90872337199994, 35.182510246000049],
            [-80.908488364999982, 35.182430102000069],
            [-80.906502131999957, 35.18178008700005],
            [-80.906353750999983, 35.181732793000037],
            [-80.902732809999975, 35.18060932000003],
            [-80.900019497999949, 35.179744862000064],
            [-80.899767062999956, 35.179573984000058],
            [-80.899343388999966, 35.179265006000037],
            [-80.89887085099997, 35.178692406000039],
            [-80.895665103999988, 35.173594155000046],
            [-80.89558554599995, 35.173470558000076],
            [-80.890584138999941, 35.165877777000048],
            [-80.887038393999944, 35.16016230300005],
            [-80.885809670999947, 35.158003380000025],
            [-80.885706101999972, 35.157592950000037],
            [-80.885671655999943, 35.157429762000049],
            [-80.885680667999964, 35.157301803000053],
            [-80.885796382999956, 35.156336315000033],
            [-80.88628392399994, 35.15427758800007],
            [-80.886341518999984, 35.154007165000053],
            [-80.886988336999934, 35.150590299000044],
            [-80.886998781999978, 35.150533649000067],
            [-80.88705611499995, 35.150139787000057],
            [-80.887228128999936, 35.148602520000054],
            [-80.887255034999953, 35.148285042000055],
            [-80.887329127999976, 35.147006346000069],
            [-80.887333356999989, 35.146923341000047],
            [-80.887337515999945, 35.146523601000069],
            [-80.887314858999957, 35.146124279000048],
            [-80.887265454999977, 35.145726590000038],
            [-80.887189454999941, 35.145331740000074],
            [-80.887155339999936, 35.145189387000073],
            [-80.886874027999966, 35.144074473000046],
            [-80.886433346999979, 35.141431543000067],
            [-80.886424433999935, 35.141379490000077],
            [-80.885946820999948, 35.138661527000068],
            [-80.885914772999968, 35.138493151000034],
            [-80.885096746999977, 35.134502956000063],
            [-80.88506975599995, 35.134377982000046],
            [-80.885013435999952, 35.13415016600004],
            [-80.884667303999947, 35.132853916000045],
            [-80.884830406999981, 35.132418173000076],
            [-80.885338624999974, 35.131281121000029],
            [-80.886902759999941, 35.128420422000033],
            [-80.887090174999969, 35.128050508000058],
            [-80.887106851999988, 35.128014560000054],
            [-80.888887546999968, 35.124145301000055],
            [-80.890233447999947, 35.122047608000059],
            [-80.890266641999972, 35.12199535700006],
            [-80.890393809999978, 35.121784896000065],
            [-80.891191205999974, 35.120409702000075],
            [-80.891888903999984, 35.119206403000078],
            [-80.891973115999974, 35.11905680600006],
            [-80.892160025999942, 35.118687726000076],
            [-80.89232190499996, 35.118310747000066],
            [-80.89245826299998, 35.117927012000052],
            [-80.892568683999968, 35.117537688000027],
            [-80.892652834999978, 35.117143957000053],
            [-80.892710460999979, 35.116747015000044],
            [-80.892737162999936, 35.116431072000069],
            [-80.892811140999981, 35.115152367000064],
            [-80.892815362999954, 35.115069362000042],
            [-80.89281949399998, 35.114669620000029],
            [-80.89279578299994, 35.113647955000033],
            [-80.892773108999961, 35.113248633000069],
            [-80.892729666999969, 35.112889470000027],
            [-80.892484907999972, 35.111275080000041],
            [-80.89247893999999, 35.111236554000072],
            [-80.892432618999976, 35.110979820000068],
            [-80.891887584999949, 35.108291296000061],
            [-80.891582212999936, 35.106013260000054],
            [-80.891549373999965, 35.104597191000039],
            [-80.891576400999952, 35.104038050000042],
            [-80.891759125999954, 35.103384728000037],
            [-80.892029801999968, 35.102481780000062],
            [-80.892508632999977, 35.101347476000058],
            [-80.893313291999959, 35.099719390000075],
            [-80.893375180999953, 35.099590656000032],
            [-80.893391848999954, 35.099554709000074],
            [-80.894772961999934, 35.096552102000032],
            [-80.894867832999978, 35.096335510000074],
            [-80.896381696999981, 35.092699108000033],
            [-80.896689020999986, 35.092046340000024],
            [-80.89675275899998, 35.09198019300004],
            [-80.896960522999962, 35.091738304000046],
            [-80.897426128999939, 35.091568489000053],
            [-80.90019598899994, 35.090575017000049],
            [-80.90371038699999, 35.089468894000049],
            [-80.903806702999987, 35.089438045000065],
            [-80.904253712999946, 35.089280537000036],
            [-80.904689486999985, 35.089103025000043],
            [-80.905112698999972, 35.088906051000038],
            [-80.905522064999957, 35.088690212000074],
            [-80.905589599999985, 35.088652026000034],
            [-80.906509889999938, 35.088126479000039],
            [-80.90683662899994, 35.087930612000036],
            [-80.907025365999971, 35.08780855100008],
            [-80.907943644999989, 35.087197854000067],
            [-80.90813288999999, 35.087068364000061],
            [-80.908493433999979, 35.086800075000042],
            [-80.908835447999934, 35.086515868000049],
            [-80.909157892999986, 35.086216605000061],
            [-80.909459788999982, 35.085903195000071],
            [-80.909553194999944, 35.085798599000043],
            [-80.91046146399998, 35.08476218900006],
            [-80.910648485999957, 35.084540178000054],
            [-80.910887110999965, 35.084228449000079],
            [-80.912596811999947, 35.08186375400004],
            [-80.914002769999968, 35.08009641700005],
            [-80.914052675999983, 35.080032963000065],
            [-80.914857256999937, 35.078998160000026],
            [-80.915027271999975, 35.07877035100006],
            [-80.915262246999987, 35.078420359000063],
            [-80.915473408999958, 35.078060257000061],
            [-80.915660118999938, 35.077691139000024],
            [-80.915821807999976, 35.077314126000033],
            [-80.915845649999937, 35.077252285000043],
            [-80.916138353999941, 35.076481106000074],
            [-80.91625068899998, 35.076159184000062],
            [-80.916360939999947, 35.075769836000063],
            [-80.916444933999969, 35.075376086000063],
            [-80.916502413999979, 35.074979131000077],
            [-80.916520891999937, 35.074783210000078],
            [-80.916577363999977, 35.074056906000067],
            [-80.916707881999969, 35.073326400000042],
            [-80.916717734999963, 35.073258297000052],
            [-80.917329901999949, 35.072201198000073],
            [-80.91951398499998, 35.068850143000077],
            [-80.921498198999984, 35.065862527000036],
            [-80.921593817999963, 35.065714509000031],
            [-80.921668126999975, 35.065593435000039],
            [-80.923163209999984, 35.063099676000036],
            [-80.92329999399999, 35.06286063500005],
            [-80.923350783999979, 35.062765698000078],
            [-80.92434209399994, 35.060876144000076],
            [-80.924477936999949, 35.060601948000055],
            [-80.924494583999945, 35.060565995000047],
            [-80.925860138999951, 35.057592964000037],
            [-80.927424353999982, 35.054275855000071],
            [-80.927435486999968, 35.054252119000068],
            [-80.92747121299999, 35.054174227000033],
            [-80.928553785999952, 35.051772150000033],
            [-80.928637589999937, 35.051577761000033],
            [-80.928714638999963, 35.05138149000004],
            [-80.928784869999959, 35.051183497000068],
            [-80.928848227999936, 35.050983942000073],
            [-80.929232173999935, 35.049700242000029],
            [-80.929309817999979, 35.049416172000065],
            [-80.929374198999938, 35.049126409000053],
            [-80.929722029999937, 35.047358587000076],
            [-80.930825338999966, 35.042682197000033],
            [-80.930885212999954, 35.042398370000058],
            [-80.93170857399997, 35.037975416000052],
            [-80.932078815999944, 35.036054862000071],
            [-80.932339404999937, 35.035318987000039],
            [-80.932846185999949, 35.034138007000024],
            [-80.932901776999984, 35.03400432400008],
            [-80.933219845999986, 35.033214395000073],
            [-80.93402141599995, 35.032356523000033],
            [-80.934092549999946, 35.032279329000062],
            [-80.934151208999936, 35.032212540000046],
            [-80.934976052999957, 35.031836250000026],
            [-80.935256146999961, 35.031702884000026],
            [-80.935665146999952, 35.031486941000026],
            [-80.93605906199997, 35.031252791000043],
            [-80.936436693999951, 35.03100114800003],
            [-80.936724865999963, 35.030788856000072],
            [-80.93751058099997, 35.030183960000045],
            [-80.938181919999977, 35.029736892000074],
            [-80.938366778999978, 35.02961031500007],
            [-80.93854737199996, 35.029479638000055],
            [-80.938723564999975, 35.029344958000024],
            [-80.938895226999989, 35.029206375000058],
            [-80.939062229999934, 35.029063994000069],
            [-80.939224448999937, 35.028917919000037],
            [-80.939381763999961, 35.02876825900006],
            [-80.94079843999998, 35.027382740000064],
            [-80.940965724999955, 35.027214054000069],
            [-80.94112678099998, 35.027041315000076],
            [-80.941281463999985, 35.026864678000038],
            [-80.94249698699997, 35.02543173600003],
            [-80.943438033999939, 35.024626718000036],
            [-80.943495503999941, 35.024577554000075],
            [-80.944076378999966, 35.02433964800008],
            [-80.946024583999986, 35.023541700000067],
            [-80.946229321999965, 35.02345507900003],
            [-80.946652093999944, 35.023257958000045],
            [-80.947061020999968, 35.023041976000059],
            [-80.947454862999962, 35.022807790000059],
            [-80.947832420999987, 35.022556111000029],
            [-80.948192549999987, 35.022287702000028],
            [-80.948534154999948, 35.022003380000058],
            [-80.948856199999966, 35.021704009000075],
            [-80.949086593999937, 35.021467701000063],
            [-80.949894219999976, 35.020602937000035],
            [-80.949965329999941, 35.020525733000056],
            [-80.950176437999971, 35.02028307300003],
            [-80.950679665999985, 35.019678737000049],
            [-80.950748601999976, 35.01959469500008],
            [-80.951006339999935, 35.019255797000028],
            [-80.951240988999984, 35.018905732000064],
            [-80.951451838999958, 35.018545565000068],
            [-80.951638248999984, 35.018176389000075],
            [-80.95173740499996, 35.017952740000055],
            [-80.952423156999942, 35.016323385000078],
            [-80.952500500999975, 35.016130958000076],
            [-80.952571279999972, 35.015936829000054],
            [-80.952635438999948, 35.015741148000075],
            [-80.952692925999941, 35.015544070000033],
            [-80.952743699999985, 35.015345747000026],
            [-80.952787718999957, 35.015146334000065],
            [-80.953343467999957, 35.012411845000031],
            [-80.953370808999978, 35.012268513000038],
            [-80.953390688999946, 35.012150261000045],
            [-80.953930101999958, 35.008734658000037],
            [-80.953960047999942, 35.008520065000027],
            [-80.953982215999986, 35.008304833000068],
            [-80.95399658599996, 35.008089154000061],
            [-80.954003143999955, 35.007873218000043],
            [-80.954001884999968, 35.007657218000077],
            [-80.953958532999934, 35.005845297000064],
            [-80.954151122999974, 35.004108212000062],
            [-80.954198174999988, 35.004037999000047],
            [-80.95427579699998, 35.003911190000053],
            [-80.95586814699999, 35.001245057000062],
            [-80.955917340999974, 35.001161334000074],
            [-80.956911229999946, 34.999441778000062],
            [-80.956995199999938, 34.999292135000076],
            [-80.957119828999964, 34.999051719000079],
            [-80.958008161999942, 34.997248694000064],
            [-80.958069870999964, 34.99711992400006],
            [-80.958231205999937, 34.996742852000068],
            [-80.958367049999936, 34.996359038000037],
            [-80.958476992999977, 34.995969648000028],
            [-80.958560700999953, 34.995575866000024],
            [-80.958576238999967, 34.995484648000058],
            [-80.958756619999974, 34.994374485000037],
            [-80.958788207999987, 34.994154559000037],
            [-80.958811624999953, 34.993933943000059],
            [-80.958826845999965, 34.993712840000057],
            [-80.958833858999981, 34.99349145900004],
            [-80.958832656999959, 34.993270005000056],
            [-80.958804072999953, 34.992078062000076],
            [-80.958795365999947, 34.991868527000065],
            [-80.95877930499995, 34.991659287000061],
            [-80.95875590199995, 34.991450516000043],
            [-80.958725177999952, 34.991242389000035],
            [-80.958687157999975, 34.991035080000074],
            [-80.958641874999955, 34.990828761000046],
            [-80.958589364999966, 34.990623606000042],
            [-80.957682142999943, 34.987316630000066],
            [-80.957645515999957, 34.98718853400004],
            [-80.957591543999968, 34.987016330000074],
            [-80.957492768999941, 34.986716429000069],
            [-80.957656483999983, 34.985708815000066],
            [-80.959086867999986, 34.980657777000033],
            [-80.959112460999961, 34.98056463100005],
            [-80.960251625999945, 34.97628751700006],
            [-80.960279107999952, 34.976180475000035],
            [-80.960350483999946, 34.975853575000031],
            [-80.960404546999939, 34.975568646000056],
            [-80.960475633999977, 34.975450575000025],
            [-80.961189951999984, 34.974214183000072],
            [-80.962978323999948, 34.971751905000076],
            [-80.963011770999969, 34.971705462000045],
            [-80.963059278999935, 34.971638104000078],
            [-80.964312892999942, 34.96983848900004],
            [-80.96516958899997, 34.968877465000048],
            [-80.967624479999984, 34.966450219000023],
            [-80.967732316999957, 34.966341470000032],
            [-80.972780197999953, 34.961148644000048],
            [-80.97288890699997, 34.961034482000059],
            [-80.974890295999955, 34.958888793000028],
            [-80.975884481999969, 34.957835530000068],
            [-80.97796267199999, 34.957875806000061],
            [-80.978195117999974, 34.957877785000051],
            [-80.978427547999956, 34.957874713000024],
            [-80.978659798999956, 34.957866592000073],
            [-80.981553309999981, 34.957733897000026],
            [-80.981812429999934, 34.957718853000074],
            [-80.98207089899995, 34.95769752800004],
            [-80.982328490999976, 34.957669937000048],
            [-80.982584983999971, 34.957636107000042],
            [-80.982840153999973, 34.957596065000075],
            [-80.985621961999982, 34.957124419000024],
            [-80.985706397999934, 34.957109746000071],
            [-80.985927639999943, 34.957067906000077],
            [-80.988707338999973, 34.956511049000028],
            [-80.988957687999971, 34.956457678000049],
            [-80.989036629999987, 34.956439500000045],
            [-80.990991074999954, 34.955981376000068],
            [-80.991376628999944, 34.95588308400005],
            [-80.991832622999937, 34.955745723000064],
            [-80.992278731999988, 34.955587886000046],
            [-80.992713601999981, 34.955410055000073],
            [-80.993135909999978, 34.95521277000006],
            [-80.993544373999953, 34.954996630000039],
            [-80.99393775499999, 34.954762290000076],
            [-80.994314854999971, 34.954510464000066],
            [-80.994353494999984, 34.954483011000036],
            [-80.995573610999941, 34.953611093000063],
            [-80.995750593999958, 34.95348113600005],
            [-80.99828998199996, 34.951565268000024],
            [-80.998486085999957, 34.951412695000045],
            [-80.998676424999985, 34.951255249000042],
            [-80.998860817999969, 34.951093078000042],
            [-80.999039092999965, 34.95092633400003],
            [-81.000319612999988, 34.949690973000031],
            [-81.002481031999935, 34.948043644000052],
            [-81.006035120999968, 34.945653035000078],
            [-81.006198386999984, 34.94554047500003],
            [-81.006486078999956, 34.94532801400004],
            [-81.007431882999981, 34.944598073000066],
            [-81.007465814999989, 34.944585638000035],
            [-81.007942752999952, 34.944472357000052],
            [-81.00997031299994, 34.944190382000045],
            [-81.012079864999976, 34.944003811000073],
            [-81.01231052299994, 34.943980872000054],
            [-81.01254036499995, 34.943952932000059],
            [-81.012769231999982, 34.943920011000046],
            [-81.012996965999946, 34.943882131000066],
            [-81.013223406999941, 34.943839318000073],
            [-81.013448396999934, 34.943791603000079],
            [-81.015094318999957, 34.943423407000068],
            [-81.015314112999988, 34.94337170600005],
            [-81.015532204999943, 34.943315326000061],
            [-81.015748445999975, 34.943254305000039],
            [-81.01596269099997, 34.943188686000042],
            [-81.017091621999953, 34.942829106000033],
            [-81.017169371999955, 34.942803992000051],
            [-81.017615361999958, 34.942646062000051],
            [-81.018050106999965, 34.942468139000027],
            [-81.018472286999952, 34.942270765000046],
            [-81.018880618999958, 34.942054539000026],
            [-81.019074008999951, 34.941942653000069],
            [-81.020399169999962, 34.941153889000077],
            [-81.020599022999988, 34.94103135000006],
            [-81.020975976999978, 34.940779440000028],
            [-81.021335505999957, 34.940510811000024],
            [-81.021676514999967, 34.940226281000037],
            [-81.021997968999983, 34.939926713000034],
            [-81.022227922999946, 34.939690262000056],
            [-81.023307645999978, 34.938531212000044],
            [-81.024123518999943, 34.937737739000056],
            [-81.024668971999972, 34.937380803000053],
            [-81.02585634899998, 34.936957309000036],
            [-81.026094803999968, 34.936868774000061],
            [-81.026329960999988, 34.936774447000062],
            [-81.026561611999966, 34.936674412000059],
            [-81.026789554999937, 34.936568757000032],
            [-81.029549252999971, 34.935244717000046],
            [-81.02975287199996, 34.935143966000055],
            [-81.02995311799998, 34.935038733000056],
            [-81.030149846999961, 34.934929090000026],
            [-81.033514897999964, 34.932998544000043],
            [-81.033840710999982, 34.932802331000062],
            [-81.034217586999944, 34.932550380000066],
            [-81.034577035999973, 34.93228171100003],
            [-81.034917964999977, 34.931997143000046],
            [-81.03523933799994, 34.931697539000027],
            [-81.035540179999941, 34.931383810000057],
            [-81.035819577999973, 34.931056908000073],
            [-81.036076683999966, 34.930717826000034],
            [-81.036310714999956, 34.930367594000074],
            [-81.036520960999951, 34.930007275000037],
            [-81.036706785999968, 34.92963796500004],
            [-81.036867624999957, 34.929260786000043],
            [-81.037002987999983, 34.928876881000065],
            [-81.03711246599994, 34.928487418000032],
            [-81.037195725999936, 34.928093580000052],
            [-81.037252515999967, 34.927696562000051],
            [-81.037282663999974, 34.927297571000054],
            [-81.037286078999955, 34.926897817000054],
            [-81.037262749999968, 34.926498515000048],
            [-81.037212749999981, 34.926100879000046],
            [-81.037136228999941, 34.925706114000036],
            [-81.037033421999979, 34.92531542100005],
            [-81.036904639999989, 34.924929985000063],
            [-81.036750274999974, 34.924550977000024],
            [-81.036570795999978, 34.92417954900003],
            [-81.036366747999978, 34.923816827000053],
            [-81.036138751999943, 34.923463914000024],
            [-81.035887499999944, 34.923121880000053],
            [-81.035613754999986, 34.922791766000046],
            [-81.035318347999976, 34.922474571000066],
            [-81.035002178999946, 34.922171262000063],
            [-81.034666204999951, 34.921882758000038],
            [-81.034311446999936, 34.921609935000049],
            [-81.033938982999985, 34.921353621000037],
            [-81.033549944999947, 34.921114595000063],
            [-81.033145511999976, 34.920893583000066],
            [-81.032726914999955, 34.920691255000065],
            [-81.032295422999937, 34.920508227000028],
            [-81.031852346999983, 34.920345053000062],
            [-81.031399031999968, 34.92020222900004],
            [-81.030936853999947, 34.920080189000032],
            [-81.030467217999956, 34.919979303000048],
            [-81.029991547999941, 34.919899878000024],
            [-81.02951128899997, 34.919842155000026],
            [-81.029027898999971, 34.91980630900008],
            [-81.028542845999937, 34.919792448000067],
            [-81.02805760199999, 34.919800615000042],
            [-81.027573641999936, 34.919830787000024],
            [-81.02709243299995, 34.919882868000059],
            [-81.026615437999965, 34.919956704000072],
            [-81.026144103999968, 34.920052069000064],
            [-81.025679862999937, 34.920168673000035],
            [-81.025224122999987, 34.920306163000078],
            [-81.024778269999956, 34.920464122000055],
            [-81.024343655999985, 34.920642069000053],
            [-81.023921601999973, 34.92083946300005],
            [-81.023513388999959, 34.92105570800004],
            [-81.023446046999936, 34.921093960000064],
            [-81.020373762999952, 34.922856427000056],
            [-81.01837489199994, 34.92381539400003],
            [-81.016600238999956, 34.924448313000028],
            [-81.016398901999935, 34.924522596000031],
            [-81.015964247999989, 34.924700513000062],
            [-81.015542152999956, 34.924897879000071],
            [-81.015133896999941, 34.925114094000037],
            [-81.014740719999963, 34.925348504000056],
            [-81.014620973999968, 34.925425481000048],
            [-81.012483702999987, 34.92682396400005],
            [-81.01229206499994, 34.926953013000059],
            [-81.012104904999944, 34.927086447000079],
            [-81.011922371999958, 34.927224159000048],
            [-81.011744608999948, 34.927366041000028],
            [-81.011571756999956, 34.927511982000055],
            [-81.011403951999966, 34.927661865000061],
            [-81.011241326999937, 34.927815572000043],
            [-81.009726286999978, 34.929288790000044],
            [-81.009689562999938, 34.92932473500008],
            [-81.009459602999982, 34.929561161000038],
            [-81.009375539999951, 34.929651385000057],
            [-81.007824912999979, 34.929788517000077],
            [-81.007379238999988, 34.929837431000067],
            [-81.007298496999965, 34.92984834400005],
            [-81.004515654999977, 34.930235354000047],
            [-81.004269783999973, 34.930272506000051],
            [-81.004025291999938, 34.930315389000043],
            [-81.003782375999947, 34.930363969000041],
            [-81.003541230999986, 34.93041820600007],
            [-81.001998896999964, 34.930784527000071],
            [-81.001766400999941, 34.930842618000042],
            [-81.00153593999994, 34.930905966000068],
            [-81.001307685999961, 34.930974525000067],
            [-81.001081815999953, 34.931048242000031],
            [-81.000858499999936, 34.931127061000041],
            [-80.998910949999981, 34.931840762000036],
            [-80.998791883999957, 34.931885269000077],
            [-80.998357151999983, 34.932063122000045],
            [-80.997934976999943, 34.932260425000038],
            [-80.997526637999954, 34.93247658100006],
            [-80.997133374999976, 34.932710933000067],
            [-80.99675638399998, 34.932962768000039],
            [-80.996468709999988, 34.933175206000044],
            [-80.994657214999961, 34.934573129000057],
            [-80.991124357999979, 34.936949224000045],
            [-80.990961088999939, 34.937061764000077],
            [-80.99071318099999, 34.937243673000069],
            [-80.987971422999976, 34.939333071000078],
            [-80.987784086999966, 34.939480091000064],
            [-80.987602076999963, 34.939631579000036],
            [-80.987425548999965, 34.939787403000025],
            [-80.987254652999979, 34.939947430000075],
            [-80.985988403999954, 34.94116884400006],
            [-80.984263858999952, 34.942469804000041],
            [-80.981914447999941, 34.942940455000041],
            [-80.979923639999981, 34.943277993000038],
            [-80.978028409999979, 34.943364904000077],
            [-80.976050080999983, 34.94332655900007],
            [-80.974239906999969, 34.943273469000076],
            [-80.973993825999969, 34.943269083000075],
            [-80.973508436999964, 34.943277031000036],
            [-80.973024317999943, 34.94330698300007],
            [-80.972542935999968, 34.94335884700007],
            [-80.97206575499996, 34.943432467000036],
            [-80.971594221999965, 34.943527618000076],
            [-80.97148752399994, 34.943552329000056],
            [-80.969944797999972, 34.943918240000073],
            [-80.969703426999956, 34.943978582000057],
            [-80.969464252999956, 34.944044589000043],
            [-80.969227471999943, 34.944116207000036],
            [-80.968993276999981, 34.944193377000033],
            [-80.968761860999962, 34.944276037000066],
            [-80.968533413999978, 34.944364118000067],
            [-80.967201553999985, 34.944896895000056],
            [-80.966918255999985, 34.945015466000029],
            [-80.96649593799998, 34.945212659000049],
            [-80.966087450999964, 34.945428708000065],
            [-80.965694035999945, 34.945662958000071],
            [-80.965316887999961, 34.945914694000066],
            [-80.964957151999954, 34.946183156000075],
            [-80.964699437999968, 34.94639498600003],
            [-80.96328047999998, 34.947610516000054],
            [-80.963122605999956, 34.947749351000027],
            [-80.962969205999968, 34.947891546000051],
            [-80.962820385999976, 34.948037004000071],
            [-80.96267624799998, 34.948185624000075],
            [-80.960961319999967, 34.950002150000046],
            [-80.960930182999959, 34.950035329000059],
            [-80.958966801999964, 34.95213988900008],
            [-80.954026902999942, 34.957220755000037],
            [-80.951453739999977, 34.959764517000053]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 112,
        "SolutionID": "PI-11:A",
        "Shape_Length": 0.83872591597412283,
        "Shape_Area": 0.006720158508192145
      }
    },
    {
      "type": "Feature",
      "id": 113,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.626989132999938, 35.323266032000049],
            [-80.624792754999987, 35.325025531000051],
            [-80.623847216999934, 35.325635165000051],
            [-80.621894188999988, 35.326676295000027],
            [-80.614743562999934, 35.33034643600007],
            [-80.614599190999968, 35.33042213300007],
            [-80.611259551999979, 35.332210665000048],
            [-80.610363719999953, 35.332615359000044],
            [-80.609537559999978, 35.332915270000058],
            [-80.609205560999953, 35.333021081000027],
            [-80.608044662999987, 35.333208972000079],
            [-80.607922291999955, 35.333229513000049],
            [-80.607474077999939, 35.333317505000025],
            [-80.605553703999988, 35.333738093000079],
            [-80.603864802999965, 35.334098528000027],
            [-80.603628289999961, 35.334151880000036],
            [-80.603393669999946, 35.334210581000036],
            [-80.603161125999975, 35.334274586000049],
            [-80.601146510999968, 35.334854644000075],
            [-80.600922356999945, 35.334921977000079],
            [-80.600700499999959, 35.334994238000036],
            [-80.600481101999947, 35.33507137600003],
            [-80.600264322999976, 35.33515333400004],
            [-80.600050317999944, 35.335240052000074],
            [-80.598733743999958, 35.335791764000078],
            [-80.598492565999948, 35.33589670300006],
            [-80.598255452999979, 35.336007688000052],
            [-80.598022629999946, 35.336124613000038],
            [-80.596478072999957, 35.336927388000049],
            [-80.596262452999952, 35.337043093000034],
            [-80.59605103399997, 35.337163893000024],
            [-80.595843996999974, 35.33728968500003],
            [-80.595641515999944, 35.337420363000035],
            [-80.595443761999945, 35.337555814000041],
            [-80.593927669999971, 35.338625345000025],
            [-80.593603929999972, 35.338865361000046],
            [-80.593306798999947, 35.339108060000058],
            [-80.59142856699998, 35.340716648000068],
            [-80.591381521999949, 35.340757250000024],
            [-80.591056900999945, 35.34105565200008],
            [-80.590752831999964, 35.341368252000052],
            [-80.590470238999956, 35.341694102000076],
            [-80.590235051999969, 35.341997777000074],
            [-80.587580840999976, 35.34562020900006],
            [-80.58755576599998, 35.345654644000035],
            [-80.587510941999938, 35.345717305000051],
            [-80.584745632999955, 35.349627371000054],
            [-80.584553283999981, 35.349914044000059],
            [-80.584485180999934, 35.350023447000069],
            [-80.583582336999939, 35.35150464700007],
            [-80.583478327999956, 35.351681208000059],
            [-80.583380181999985, 35.351860008000074],
            [-80.583287968999969, 35.352040914000042],
            [-80.583201755999937, 35.352223797000079],
            [-80.583121606999953, 35.352408522000076],
            [-80.582587595999939, 35.353694061000056],
            [-80.582511945999954, 35.353884773000061],
            [-80.582442760999982, 35.35407712600005],
            [-80.582380090999948, 35.354270972000052],
            [-80.582323985999949, 35.354466163000041],
            [-80.582274488999985, 35.354662550000057],
            [-80.58223163599996, 35.35485998300004],
            [-80.582040604999975, 35.35581662900006],
            [-80.582002030999945, 35.356029594000063],
            [-80.581971186999965, 35.356243402000075],
            [-80.581948102999945, 35.356457867000074],
            [-80.581932796999979, 35.356672798000034],
            [-80.581925285999944, 35.356888006000077],
            [-80.581925573999968, 35.357103302000041],
            [-80.581948955999962, 35.358305227000074],
            [-80.581970172999945, 35.358704590000059],
            [-80.58201121999997, 35.35905603100008],
            [-80.582145481999987, 35.359970262000047],
            [-80.58215247399994, 35.360016619000078],
            [-80.582227193999984, 35.360411646000045],
            [-80.582328365999956, 35.360802701000068],
            [-80.582455681999988, 35.361188596000034],
            [-80.582470205999982, 35.361227842000062],
            [-80.582789679999962, 35.362082405000024],
            [-80.582842367999945, 35.362218537000047],
            [-80.583476412999971, 35.363802418000034],
            [-80.58337288599995, 35.364287866000041],
            [-80.582216416999984, 35.368707080000036],
            [-80.581045604999986, 35.373166807000075],
            [-80.581032286999971, 35.373218449000035],
            [-80.581017300999974, 35.373278908000032],
            [-80.580650623999986, 35.37479124400005],
            [-80.58057968199995, 35.375124281000069],
            [-80.580538965999949, 35.375375537000025],
            [-80.580375240999956, 35.376541731000032],
            [-80.580351849999943, 35.376729933000036],
            [-80.58033445999996, 35.376918570000043],
            [-80.58032308099996, 35.377107514000045],
            [-80.580317722999951, 35.377296637000029],
            [-80.580318387999966, 35.377485810000053],
            [-80.580340638999985, 35.378630497000074],
            [-80.580372534999981, 35.380271213000071],
            [-80.580386702999988, 35.380999983000038],
            [-80.580353922999961, 35.381223359000046],
            [-80.580040818999976, 35.382859469000039],
            [-80.579535753999949, 35.384724512000048],
            [-80.57949128599995, 35.384898808000059],
            [-80.57945205499999, 35.385073947000024],
            [-80.579418084999986, 35.38524982000007],
            [-80.579060958999946, 35.387258178000025],
            [-80.579007884999953, 35.387620257000037],
            [-80.57897948599998, 35.387942446000068],
            [-80.578891164999959, 35.389393896000058],
            [-80.578882777999979, 35.389579469000068],
            [-80.578880195999943, 35.389765157000056],
            [-80.578883419999954, 35.389950837000072],
            [-80.578940069999987, 35.391667558000051],
            [-80.578959793999957, 35.39199345600008],
            [-80.579079557999989, 35.39333481400007],
            [-80.579126351999946, 35.393718393000029],
            [-80.579189729999939, 35.394061609000062],
            [-80.579471598999987, 35.395374654000079],
            [-80.579508729999986, 35.39553679200003],
            [-80.57955035699996, 35.395698196000069],
            [-80.579596454999944, 35.395858783000051],
            [-80.58046832399998, 35.398748014000034],
            [-80.580512623999937, 35.398888744000033],
            [-80.581578018999949, 35.402138016000038],
            [-80.581644910999955, 35.402331289000074],
            [-80.581718313999943, 35.402522971000053],
            [-80.581798172999981, 35.402712914000062],
            [-80.581884425999988, 35.402900974000033],
            [-80.582491850999986, 35.404171598000062],
            [-80.582587827999987, 35.404364177000048],
            [-80.582747921999953, 35.404654773000061],
            [-80.583543649999967, 35.406018346000053],
            [-80.583586783999976, 35.406091212000035],
            [-80.583774810999955, 35.406387001000041],
            [-80.584638994999978, 35.407673342000066],
            [-80.584766135999985, 35.40785625400008],
            [-80.584899706999977, 35.408036070000037],
            [-80.585039592999976, 35.40821263600003],
            [-80.585185677999959, 35.408385806000069],
            [-80.585337838999976, 35.40855543300006],
            [-80.586314117999962, 35.409611301000041],
            [-80.586474442999986, 35.409779494000077],
            [-80.586510911999937, 35.409816367000076],
            [-80.588185126999974, 35.411497781000037],
            [-80.589529192999976, 35.412861398000075],
            [-80.591704916999959, 35.41506462600006],
            [-80.592572353999969, 35.416034652000064],
            [-80.592901100999939, 35.416434489000039],
            [-80.593102812999973, 35.416727026000046],
            [-80.593479364999951, 35.417436728000041],
            [-80.594737547999955, 35.420197758000029],
            [-80.594759302999989, 35.420244996000065],
            [-80.596434202999944, 35.423843493000049],
            [-80.59704279999994, 35.425226579000025],
            [-80.597140165999974, 35.425437179000028],
            [-80.597372287999974, 35.425916629000028],
            [-80.597451869999986, 35.426155203000064],
            [-80.597544507999942, 35.427166359000068],
            [-80.59773968099995, 35.430158167000059],
            [-80.597749143999977, 35.430282989000034],
            [-80.598202184999934, 35.435527042000047],
            [-80.598250545999974, 35.43592708500006],
            [-80.598272663999978, 35.436059790000058],
            [-80.598560991999989, 35.437678024000036],
            [-80.598598955999989, 35.43787254800003],
            [-80.598643358999936, 35.438066159000073],
            [-80.598694171999966, 35.438258710000071],
            [-80.59875135499999, 35.438450061000026],
            [-80.598814865999941, 35.438640070000076],
            [-80.599135944999944, 35.439551809000079],
            [-80.599151971999959, 35.439596773000062],
            [-80.599521934999984, 35.440622363000045],
            [-80.599593850999952, 35.440812114000039],
            [-80.599672090999945, 35.441000183000028],
            [-80.599756596999953, 35.441186428000037],
            [-80.59984730299999, 35.441370708000079],
            [-80.600235335999969, 35.442128900000057],
            [-80.600310136999951, 35.44227078800003],
            [-80.600388611999961, 35.442411341000025],
            [-80.600470726999959, 35.442550494000045],
            [-80.60071174899997, 35.44294805100003],
            [-80.600815638999961, 35.443114021000042],
            [-80.600924709999958, 35.44327775000005],
            [-80.601038887999948, 35.443439130000058],
            [-80.601158098999974, 35.443598056000042],
            [-80.601282264999952, 35.443754420000062],
            [-80.601613963999966, 35.444160613000065],
            [-80.602008556999976, 35.444712049000032],
            [-80.602121996999983, 35.44486616100005],
            [-80.602240073999951, 35.445017922000034],
            [-80.602362711999945, 35.445167241000036],
            [-80.602489838999986, 35.445314027000052],
            [-80.603559770999937, 35.446517448000066],
            [-80.604639867999936, 35.447735644000034],
            [-80.60518759699994, 35.448585568000055],
            [-80.606079480999938, 35.450050180000062],
            [-80.606260420999945, 35.450400368000032],
            [-80.608701702999952, 35.457554189000064],
            [-80.610853427999984, 35.463979280000046],
            [-80.613753254999949, 35.472620455000026],
            [-80.616505238999935, 35.480861141000048],
            [-80.616527939999969, 35.480927856000051],
            [-80.617715663999945, 35.48435485300007],
            [-80.618055521999963, 35.485516030000042],
            [-80.618212652999944, 35.486269456000059],
            [-80.618232291999959, 35.486360144000059],
            [-80.618271216999972, 35.486522973000035],
            [-80.618320585999982, 35.486717641000041],
            [-80.61836427999998, 35.487743573000046],
            [-80.61829993799995, 35.488398558000029],
            [-80.618175934999954, 35.489303801000062],
            [-80.61808462099998, 35.489671747000045],
            [-80.617972961999953, 35.489992597000025],
            [-80.617633195999986, 35.490766947000054],
            [-80.617357978999962, 35.491386564000038],
            [-80.616704651999953, 35.492432333000068],
            [-80.614782135999974, 35.495343509000065],
            [-80.614737693999984, 35.495411668000031],
            [-80.613556925999944, 35.497245989000078],
            [-80.60849966699999, 35.505136003000075],
            [-80.60647798499997, 35.508228493000047],
            [-80.60637402399999, 35.508392503000039],
            [-80.606275202999939, 35.508558624000045],
            [-80.606181585999934, 35.508726749000061],
            [-80.605798911999955, 35.509437862000027],
            [-80.605704628999945, 35.50961974300003],
            [-80.605616438999959, 35.509803655000042],
            [-80.605534404999958, 35.509989464000057],
            [-80.605458590999945, 35.510177029000033],
            [-80.605214388999968, 35.510809979000044],
            [-80.605100218999951, 35.511131586000033],
            [-80.604999460999977, 35.511475648000044],
            [-80.604804180999963, 35.512222445000077],
            [-80.604756041999963, 35.512419906000048],
            [-80.60471462299995, 35.512618384000064],
            [-80.604679957999963, 35.512817726000037],
            [-80.604652073999944, 35.513017777000073],
            [-80.604551626999978, 35.513839648000044],
            [-80.604528982999966, 35.514058312000031],
            [-80.604514438999956, 35.514277435000054],
            [-80.604508008999971, 35.514496818000055],
            [-80.604499762999978, 35.515260225000077],
            [-80.604500575999964, 35.515441511000063],
            [-80.604506448999985, 35.515613019000057],
            [-80.605094167999937, 35.527672614000039],
            [-80.605189478999989, 35.53015941600006],
            [-80.605191896999941, 35.530215567000027],
            [-80.605542243999935, 35.537541922000059],
            [-80.605532198999981, 35.538658834000046],
            [-80.605449586999953, 35.539330570000061],
            [-80.605303082999967, 35.539844750000043],
            [-80.605109589999984, 35.540351909000037],
            [-80.604389740999977, 35.540971785000067],
            [-80.600320192999959, 35.544181895000065],
            [-80.600288925999962, 35.544206679000069],
            [-80.598968648999971, 35.545258322000052],
            [-80.59883597299995, 35.545325090000063],
            [-80.598697008999977, 35.545396480000079],
            [-80.597968107999975, 35.545778659000064],
            [-80.591183858999955, 35.548848664000047],
            [-80.591021328999943, 35.548924041000078],
            [-80.587181047999934, 35.550748930000054],
            [-80.587082237999937, 35.550796583000078],
            [-80.586933947999967, 35.550870780000025],
            [-80.584483340999952, 35.55212403400003],
            [-80.584267220999948, 35.552238150000051],
            [-80.584055237999962, 35.552357338000036],
            [-80.583847572999957, 35.552481496000041],
            [-80.58364439799999, 35.552610521000076],
            [-80.583445883999957, 35.552744306000079],
            [-80.582406446999983, 35.553465736000078],
            [-80.582040046999964, 35.553734906000045],
            [-80.581912208999938, 35.553836212000078],
            [-80.580808760999957, 35.554728357000045],
            [-80.58063383199999, 35.554873819000079],
            [-80.580463974999986, 35.555023243000051],
            [-80.580299324999942, 35.555176510000024],
            [-80.580140012999948, 35.555333498000039],
            [-80.579986165999969, 35.555494086000067],
            [-80.579837901999952, 35.555658144000063],
            [-80.578855590999979, 35.556777681000028],
            [-80.578711078999959, 35.556947434000051],
            [-80.578572547999954, 35.557120486000031],
            [-80.578440109999974, 35.557296694000058],
            [-80.578313871999967, 35.557475915000055],
            [-80.577723578999951, 35.558342278000055],
            [-80.577610368999956, 35.558513815000026],
            [-80.577502839999966, 35.558687775000067],
            [-80.577401067999972, 35.558864033000077],
            [-80.577305125999942, 35.559042461000047],
            [-80.576787444999979, 35.560041452000064],
            [-80.576685279999936, 35.560247328000059],
            [-80.576574856999969, 35.560493078000036],
            [-80.575957551999977, 35.561947487000054],
            [-80.575541294999937, 35.562785992000045],
            [-80.575307720999945, 35.563169783000035],
            [-80.575120529999936, 35.563388444000054],
            [-80.575033875999964, 35.56347375200005],
            [-80.574849855999958, 35.563613460000056],
            [-80.574524361999977, 35.563847893000059],
            [-80.57362276799995, 35.564181752000025],
            [-80.572023411999965, 35.564687695000032],
            [-80.567869627999983, 35.565891413000031],
            [-80.567593178999971, 35.56597577000008],
            [-80.56746071799995, 35.566019261000065],
            [-80.566161314999988, 35.566455824000059],
            [-80.565910463999955, 35.566543809000052],
            [-80.565663041999983, 35.566638054000066],
            [-80.565419282999983, 35.566738470000075],
            [-80.565179416999968, 35.566844962000062],
            [-80.56436920799996, 35.567217957000025],
            [-80.564167666999936, 35.567313608000063],
            [-80.564040191999936, 35.567377146000069],
            [-80.563230707999935, 35.567788288000031],
            [-80.562945491999983, 35.567939410000065],
            [-80.562699631999976, 35.568080219000024],
            [-80.56184527299996, 35.568587338000043],
            [-80.561656280999955, 35.568702681000048],
            [-80.561471105999942, 35.568822086000068],
            [-80.561289876999979, 35.568945469000028],
            [-80.561112725999976, 35.56907274100007],
            [-80.559454690999985, 35.570296203000055],
            [-80.559343789999957, 35.570379440000067],
            [-80.557664650999982, 35.571661322000068],
            [-80.555133499999954, 35.57356476700005],
            [-80.555031403999976, 35.573642762000077],
            [-80.554770625999936, 35.573853694000036],
            [-80.553874206999978, 35.574609359000078],
            [-80.553690423999967, 35.574769041000025],
            [-80.55351263099999, 35.574933187000056],
            [-80.553340986999956, 35.575101646000064],
            [-80.552309910999952, 35.576145249000035],
            [-80.552127810999934, 35.576335980000067],
            [-80.551895939999952, 35.576599435000048],
            [-80.550762963999944, 35.577949413000056],
            [-80.55000509499996, 35.578748609000058],
            [-80.549933115999977, 35.57882556900006],
            [-80.549764026999981, 35.579015182000035],
            [-80.54178626199996, 35.588270938000051],
            [-80.538743591999946, 35.591739253000071],
            [-80.538601711999945, 35.591905845000042],
            [-80.538465591999966, 35.592075614000066],
            [-80.538335340999936, 35.592248427000072],
            [-80.538211058999934, 35.592424149000067],
            [-80.537301775999936, 35.593752301000052],
            [-80.537187380999967, 35.593924826000034],
            [-80.536347772999989, 35.595233007000047],
            [-80.536338414999989, 35.595247630000074],
            [-80.536238744999935, 35.595408636000059],
            [-80.53440792899994, 35.598459674000026],
            [-80.53149628999995, 35.603288303000056],
            [-80.529780401999972, 35.60602833300004],
            [-80.529725197999937, 35.606118007000077],
            [-80.526259995999965, 35.611844893000068],
            [-80.524702797999964, 35.614411589000042],
            [-80.523981987999946, 35.615559589000043],
            [-80.52379592799997, 35.615874373000054],
            [-80.523735707999947, 35.615985315000046],
            [-80.52326113099997, 35.616879737000033],
            [-80.522884112999975, 35.617446068000049],
            [-80.522412704999965, 35.618055707000053],
            [-80.52197900799996, 35.618565672000045],
            [-80.521425553999961, 35.619113463000076],
            [-80.517674461999945, 35.622496095000031],
            [-80.512410537999983, 35.627256614000032],
            [-80.509218405999945, 35.630136914000047],
            [-80.509202292999987, 35.630151492000039],
            [-80.502996150999934, 35.635781092000059],
            [-80.502971084999956, 35.635803924000072],
            [-80.49874181399997, 35.639672424000025],
            [-80.498712575999946, 35.639699299000029],
            [-80.496661316999962, 35.641594209000061],
            [-80.49463480199995, 35.643387025000038],
            [-80.49387706999994, 35.643997893000062],
            [-80.493045990999974, 35.64458755000004],
            [-80.491799259999937, 35.645403908000048],
            [-80.490813212999967, 35.646022629000072],
            [-80.489800904999981, 35.646614269000054],
            [-80.488575436999952, 35.647212079000042],
            [-80.487127953999959, 35.647875143000078],
            [-80.483967323999934, 35.649222956000074],
            [-80.483862165999938, 35.649268638000024],
            [-80.48066184399994, 35.650681503000044],
            [-80.480513732999952, 35.650748383000064],
            [-80.477919192999934, 35.651946391000024],
            [-80.47507090299996, 35.653134148000049],
            [-80.474984034999977, 35.65317086500005],
            [-80.474923441999977, 35.653197066000075],
            [-80.470551202999957, 35.655105192000065],
            [-80.468052771999965, 35.656172249000065],
            [-80.46761970099999, 35.656370238000079],
            [-80.466575595999984, 35.656879526000068],
            [-80.466349606999984, 35.656993533000048],
            [-80.466127891999975, 35.657113001000027],
            [-80.465910650999945, 35.65723782300006],
            [-80.465698078999935, 35.657367888000067],
            [-80.46495835799999, 35.657834751000053],
            [-80.464770195999961, 35.657956859000024],
            [-80.464586149999946, 35.658083075000036],
            [-80.464406352999958, 35.658213309000075],
            [-80.464230936999968, 35.658347462000052],
            [-80.464060030999974, 35.658485438000071],
            [-80.463893758999973, 35.658627136000064],
            [-80.463621160999935, 35.658865825000078],
            [-80.46238124599995, 35.659768235000058],
            [-80.462181464999958, 35.659918164000032],
            [-80.462114196999948, 35.659970765000025],
            [-80.458410988999958, 35.662896880000062],
            [-80.456786614999942, 35.664067306000049],
            [-80.456554262999987, 35.664240795000069],
            [-80.456208226999934, 35.664523686000052],
            [-80.455881810999983, 35.664821701000051],
            [-80.455576005999944, 35.665133937000064],
            [-80.45529173999995, 35.665459446000057],
            [-80.455029875999969, 35.665797239000028],
            [-80.454791212999965, 35.666146290000029],
            [-80.454576471999985, 35.66650554000006],
            [-80.454386306999936, 35.666873898000063],
            [-80.454221294999968, 35.667250244000059],
            [-80.454081938999934, 35.667633436000074],
            [-80.453968660999976, 35.668022311000072],
            [-80.453881806999959, 35.668415688000039],
            [-80.453821638999955, 35.668812371000058],
            [-80.453788342999985, 35.669211156000074],
            [-80.45378201799997, 35.669610833000036],
            [-80.453802683999982, 35.670010187000059],
            [-80.453850278999937, 35.670408006000059],
            [-80.453924659999984, 35.670803081000031],
            [-80.454025598999976, 35.671194212000046],
            [-80.454152791999945, 35.671580213000027],
            [-80.454305851999948, 35.671959909000066],
            [-80.454484315999935, 35.672332148000066],
            [-80.454687639999975, 35.672695799000053],
            [-80.454915207999989, 35.673049759000037],
            [-80.455166327999962, 35.673392952000029],
            [-80.455440240999962, 35.673724334000042],
            [-80.455736110999965, 35.674042901000064],
            [-80.456053040999961, 35.674347683000065],
            [-80.456390068999951, 35.674637756000038],
            [-80.456746172999942, 35.674912239000037],
            [-80.457120268999972, 35.675170297000079],
            [-80.457511220999947, 35.675411147000034],
            [-80.457917843999951, 35.675634057000025],
            [-80.458338898999955, 35.67583834900006],
            [-80.458773109999981, 35.676023404000034],
            [-80.459219157999939, 35.676188660000037],
            [-80.459675687999948, 35.676333614000043],
            [-80.460141311999962, 35.676457826000046],
            [-80.460614615999987, 35.676560918000064],
            [-80.461094162999984, 35.676642578000042],
            [-80.461578495999959, 35.676702557000056],
            [-80.462066143999948, 35.67674067300004],
            [-80.462555623999947, 35.676756812000065],
            [-80.46304545199996, 35.676750922000053],
            [-80.463534135999964, 35.676723022000033],
            [-80.464020195999979, 35.67667319800006],
            [-80.464502151999966, 35.676601600000026],
            [-80.464978540999937, 35.676508446000071],
            [-80.465447916999949, 35.676394018000053],
            [-80.465908852999974, 35.676258666000024],
            [-80.46635994799999, 35.676102798000045],
            [-80.466799833999971, 35.675926890000028],
            [-80.467227172999969, 35.675731476000067],
            [-80.467640667999945, 35.675517149000029],
            [-80.468039062999935, 35.675284559000033],
            [-80.468421146999958, 35.675034414000038],
            [-80.468553403999977, 35.674940985000035],
            [-80.470330813999965, 35.673660157000029],
            [-80.470563162999952, 35.673486642000057],
            [-80.470630437999944, 35.67343403700005],
            [-80.47434932699997, 35.670495197000037],
            [-80.475759477999986, 35.669468778000066],
            [-80.475959256999943, 35.669318827000041],
            [-80.476308176999964, 35.669033330000047],
            [-80.476522465999949, 35.668927679000035],
            [-80.478833211999984, 35.667940737000038],
            [-80.478898430999948, 35.667912560000048],
            [-80.48323262699995, 35.666020961000072],
            [-80.486175205999984, 35.664793833000033],
            [-80.486262081999939, 35.66475710800006],
            [-80.486507506999942, 35.664647907000074],
            [-80.489192915999979, 35.663407888000052],
            [-80.492262774999972, 35.662052561000053],
            [-80.495485008999935, 35.660678421000057],
            [-80.495711098999948, 35.66057833900004],
            [-80.49738040699998, 35.659813627000062],
            [-80.497589713999957, 35.659714659000031],
            [-80.499235232999979, 35.658911895000074],
            [-80.499647900999946, 35.658697778000032],
            [-80.499849773999983, 35.658583203000035],
            [-80.501283837999949, 35.657745011000031],
            [-80.501480180999977, 35.657626888000038],
            [-80.501526277999972, 35.657598157000052],
            [-80.502704953999967, 35.656858507000038],
            [-80.502849526999967, 35.656765824000047],
            [-80.504304776999959, 35.655812845000071],
            [-80.504495993999967, 35.655684003000033],
            [-80.504573849999986, 35.655629400000066],
            [-80.505750696999939, 35.65479433400003],
            [-80.506037241999934, 35.654581884000038],
            [-80.506165332999956, 35.654480661000036],
            [-80.507270960999961, 35.653589219000025],
            [-80.507488683999952, 35.653407407000032],
            [-80.50755744099996, 35.653347261000079],
            [-80.509788428999968, 35.65137327900004],
            [-80.50991632299997, 35.651257647000079],
            [-80.512016143999972, 35.649317590000067],
            [-80.516218084999934, 35.645473523000078],
            [-80.522403398999984, 35.639862002000029],
            [-80.525593436999941, 35.636983173000033],
            [-80.530855116999987, 35.632223993000025],
            [-80.53473420399996, 35.628725434000046],
            [-80.534936630999937, 35.628536376000056],
            [-80.535001470999987, 35.628472929000054],
            [-80.535919134999972, 35.627564503000031],
            [-80.536159650999934, 35.627315509000027],
            [-80.536363187999939, 35.627085846000057],
            [-80.537117622999972, 35.626198554000041],
            [-80.537197890999948, 35.626102514000024],
            [-80.537332338999988, 35.625933835000069],
            [-80.538062300999968, 35.624989602000028],
            [-80.538189242999977, 35.62482030700005],
            [-80.538355583999987, 35.624581285000033],
            [-80.539059982999959, 35.623522878000074],
            [-80.539182435999976, 35.623332187000074],
            [-80.539297900999941, 35.623138613000037],
            [-80.539406277999944, 35.622942327000032],
            [-80.539923373999954, 35.621967411000071],
            [-80.540540352999983, 35.620984471000043],
            [-80.540595417999953, 35.620895234000045],
            [-80.54218354599999, 35.618276711000078],
            [-80.545624546999989, 35.61258799400008],
            [-80.547342605999972, 35.609843641000055],
            [-80.547402806999969, 35.60974565600003],
            [-80.550350748999961, 35.604855226000041],
            [-80.552135435999958, 35.601880109000035],
            [-80.552863154999955, 35.600745920000065],
            [-80.553470907999952, 35.59985795800003],
            [-80.556244901999946, 35.596695251000028],
            [-80.556287275999978, 35.596646515000032],
            [-80.564168437999967, 35.587500981000062],
            [-80.564959959999953, 35.58666614200007],
            [-80.565031931999954, 35.586589174000039],
            [-80.565263788999971, 35.586325695000028],
            [-80.566346392999947, 35.585035470000037],
            [-80.566906648999975, 35.58446831100008],
            [-80.567346413999985, 35.584097548000045],
            [-80.569715601999974, 35.582315702000074],
            [-80.569764268999961, 35.58227882400007],
            [-80.571412616999964, 35.581020309000053],
            [-80.572664307999958, 35.580096595000043],
            [-80.572877514999959, 35.579970033000052],
            [-80.573252644999968, 35.579779492000057],
            [-80.573419976999958, 35.579702453000039],
            [-80.574011317999975, 35.579503773000056],
            [-80.578078694999988, 35.578325067000037],
            [-80.578316214999973, 35.578253097000072],
            [-80.580266242999983, 35.577636207000069],
            [-80.580305201999977, 35.577623796000069],
            [-80.580725463999954, 35.577478689000031],
            [-80.582487897999954, 35.576826039000025],
            [-80.582723943999952, 35.576735186000064],
            [-80.582956618999958, 35.57663870500005],
            [-80.583185722999986, 35.576536680000061],
            [-80.583411056999978, 35.576429198000028],
            [-80.583632426999941, 35.576316353000038],
            [-80.58467279499996, 35.575768491000076],
            [-80.584894489999954, 35.575647860000061],
            [-80.585111653999945, 35.57552185000003],
            [-80.585324093999986, 35.57539057300005],
            [-80.585531615999969, 35.575254149000045],
            [-80.585734034999973, 35.575112699000044],
            [-80.586678356999982, 35.574432498000078],
            [-80.586850803999937, 35.574304962000042],
            [-80.587541238999961, 35.573780727000042],
            [-80.587707442999942, 35.573651217000076],
            [-80.587869487999967, 35.57351823700003],
            [-80.588027268999952, 35.573381877000031],
            [-80.588180678999947, 35.573242228000026],
            [-80.588329614999964, 35.573099383000056],
            [-80.589017039999987, 35.572422521000078],
            [-80.589277111999934, 35.572153771000046],
            [-80.58946027799999, 35.571947580000028],
            [-80.590236594999965, 35.571040561000075],
            [-80.590384868999934, 35.570861708000052],
            [-80.590526556999976, 35.57067932700005],
            [-80.590661534999981, 35.570493578000026],
            [-80.59078968099999, 35.570304628000031],
            [-80.590910883999982, 35.570112643000073],
            [-80.591589650999936, 35.568996990000073],
            [-80.591728264999972, 35.568758349000063],
            [-80.591867122999986, 35.568493735000061],
            [-80.592496170999937, 35.56722607100005],
            [-80.592546621999986, 35.567122103000031],
            [-80.592657004999978, 35.566876339000032],
            [-80.59325192099999, 35.565474007000034],
            [-80.593467835999945, 35.565057185000057],
            [-80.593579359999978, 35.564893459000075],
            [-80.59382906999997, 35.56460880800006],
            [-80.594159175999948, 35.564341882000065],
            [-80.594447607999939, 35.56414167500003],
            [-80.596229766999954, 35.563230212000065],
            [-80.599864891999971, 35.561502727000061],
            [-80.606819349999967, 35.558355544000051],
            [-80.607080690999965, 35.558232477000047],
            [-80.607312323999963, 35.558115084000065],
            [-80.608213903999967, 35.557642340000029],
            [-80.608883915999968, 35.55730513900005],
            [-80.609105401999955, 35.557189942000036],
            [-80.609322621999979, 35.557069443000046],
            [-80.609535383999969, 35.556943746000059],
            [-80.609743504999983, 35.556812962000038],
            [-80.609946799999989, 35.556677205000028],
            [-80.61056879399996, 35.556249151000031],
            [-80.610731650999981, 35.556134287000077],
            [-80.610891052999989, 35.556016231000058],
            [-80.611046906999945, 35.555895053000029],
            [-80.612663391999945, 35.554607324000074],
            [-80.616854827999987, 35.551300673000071],
            [-80.617123982999942, 35.551078976000042],
            [-80.618363616999943, 35.550012939000055],
            [-80.618531447999942, 35.549864520000028],
            [-80.618694153999968, 35.549712338000063],
            [-80.618851608999989, 35.549556511000048],
            [-80.619003689999943, 35.54939715900008],
            [-80.619666552999945, 35.548682276000079],
            [-80.619808625999951, 35.548524678000035],
            [-80.619945442999949, 35.548364004000064],
            [-80.620076902999983, 35.548200371000064],
            [-80.620202912999957, 35.548033898000028],
            [-80.620323378999956, 35.547864707000031],
            [-80.620438214999979, 35.547692920000031],
            [-80.621185654999977, 35.546538006000048],
            [-80.621213700999988, 35.546494313000039],
            [-80.621427384999947, 35.546134757000061],
            [-80.621616508999978, 35.545766127000036],
            [-80.621780496999975, 35.545389542000066],
            [-80.621815610999988, 35.545299277000026],
            [-80.622347972999989, 35.543899157000055],
            [-80.622451208999962, 35.543606025000031],
            [-80.622516794999967, 35.543390651000038],
            [-80.622865836999949, 35.542164716000059],
            [-80.622914712999943, 35.541982458000064],
            [-80.622957836999944, 35.541799232000074],
            [-80.622995179999975, 35.541615161000038],
            [-80.623026717999949, 35.541430365000053],
            [-80.623052429999973, 35.541244968000058],
            [-80.623233108999955, 35.539773212000057],
            [-80.623256291999951, 35.539549577000059],
            [-80.623270999999988, 35.539325463000068],
            [-80.623277217999942, 35.539101084000038],
            [-80.623291453999968, 35.537478892000024],
            [-80.623290494999935, 35.537308262000067],
            [-80.623284847999969, 35.537142251000034],
            [-80.622926167999935, 35.529675791000045],
            [-80.622830208999972, 35.527186253000025],
            [-80.622827510999969, 35.527124607000076],
            [-80.62224428199994, 35.515210573000047],
            [-80.622247007999988, 35.514953027000047],
            [-80.622256358999948, 35.514876377000064],
            [-80.622285615999942, 35.514821991000076],
            [-80.624163966999959, 35.511947895000048],
            [-80.624197544999959, 35.511896010000044],
            [-80.629262877999963, 35.503991018000079],
            [-80.63041392599996, 35.502202342000032],
            [-80.632361697999954, 35.499252064000075],
            [-80.632425778999959, 35.499153179000075],
            [-80.632455843999935, 35.499105478000047],
            [-80.633405327999981, 35.497585181000034],
            [-80.633526486999983, 35.497383527000068],
            [-80.633639949999974, 35.497178900000051],
            [-80.633745608999959, 35.49697149800005],
            [-80.633843361999936, 35.496761521000053],
            [-80.634312128999966, 35.495705667000038],
            [-80.634323864999942, 35.495679069000062],
            [-80.634770059999937, 35.494661682000071],
            [-80.634856509999963, 35.494454799000039],
            [-80.634952397999939, 35.494197352000072],
            [-80.635239129999945, 35.493372937000061],
            [-80.635298621999937, 35.493193144000031],
            [-80.635352447999935, 35.493012161000024],
            [-80.635400573999959, 35.492830106000042],
            [-80.635637951999968, 35.491872776000037],
            [-80.635716773999945, 35.491507051000042],
            [-80.63575990399994, 35.491237526000077],
            [-80.635942930999988, 35.489899275000028],
            [-80.635958947999939, 35.489772009000035],
            [-80.635969532999979, 35.489672601000052],
            [-80.636085652999952, 35.488487912000039],
            [-80.636102370999936, 35.488279847000058],
            [-80.636111767999978, 35.488071475000027],
            [-80.63611383999995, 35.487862968000059],
            [-80.636108582999952, 35.48765449800004],
            [-80.636020409999958, 35.485594760000026],
            [-80.636008089999962, 35.48538985700003],
            [-80.635988688999987, 35.485185321000074],
            [-80.635962224999957, 35.484981315000027],
            [-80.635928716999956, 35.484778003000031],
            [-80.635888192999971, 35.484575547000077],
            [-80.635840685999938, 35.484374110000033],
            [-80.63567001399997, 35.483701704000055],
            [-80.635489983999946, 35.482839365000075],
            [-80.63547032699995, 35.482748680000043],
            [-80.635372466999968, 35.482370329000048],
            [-80.634919656999955, 35.480824384000073],
            [-80.634828930999959, 35.480541109000058],
            [-80.63360215299997, 35.477003577000062],
            [-80.63085668399998, 35.46878758400004],
            [-80.627953253999976, 35.460141081000074],
            [-80.625795688999972, 35.453702590000034],
            [-80.62578414699999, 35.45366847400004],
            [-80.623183093999955, 35.446051113000067],
            [-80.623119386999974, 35.445873315000028],
            [-80.623050150999973, 35.445696908000059],
            [-80.622975431999976, 35.445522005000043],
            [-80.622895277999987, 35.445348721000073],
            [-80.622809741999959, 35.445177169000033],
            [-80.622296898999934, 35.44418502700006],
            [-80.622089949999975, 35.443814984000028],
            [-80.622069571999987, 35.443781312000056],
            [-80.621008773999961, 35.442039876000024],
            [-80.620915708999974, 35.441891395000027],
            [-80.620025372999976, 35.440510245000041],
            [-80.61990444099996, 35.440328945000033],
            [-80.61977724999997, 35.440150536000033],
            [-80.619643903999986, 35.439975164000032],
            [-80.619504508999967, 35.439802968000038],
            [-80.619359181999982, 35.439634092000063],
            [-80.617900655999961, 35.437989423000033],
            [-80.61708282699999, 35.437069736000069],
            [-80.616777587999934, 35.436643280000055],
            [-80.61660401599994, 35.436410892000026],
            [-80.61649835299994, 35.436278428000037],
            [-80.616395190999981, 35.436152125000035],
            [-80.616385023999953, 35.436132268000051],
            [-80.616160657999956, 35.435510650000026],
            [-80.616014333999942, 35.43509539400003],
            [-80.615860257999941, 35.434231698000076],
            [-80.615434942999968, 35.429320932000053],
            [-80.61523810999995, 35.426313702000073],
            [-80.615228227999978, 35.426184364000051],
            [-80.615225867999982, 35.426158029000078],
            [-80.615077091999979, 35.424537968000038],
            [-80.615054246999989, 35.424329490000048],
            [-80.615024062999964, 35.424121638000031],
            [-80.614986564999981, 35.423914585000034],
            [-80.614941782999949, 35.423708505000036],
            [-80.614889755999968, 35.423503567000068],
            [-80.614713714999937, 35.422857094000051],
            [-80.614678825999988, 35.422734028000036],
            [-80.614612656999952, 35.422523421000051],
            [-80.614339980999944, 35.42170648900003],
            [-80.614272042999971, 35.421513496000046],
            [-80.614197604999958, 35.421322115000066],
            [-80.614116720999959, 35.421132496000041],
            [-80.61402945399999, 35.420944783000039],
            [-80.613667776999989, 35.420198051000057],
            [-80.613078848999976, 35.418860288000076],
            [-80.613024044999975, 35.418739377000065],
            [-80.611330559999942, 35.415102542000056],
            [-80.610001026999953, 35.412186235000036],
            [-80.609853845999965, 35.41188422700003],
            [-80.609826244999965, 35.41183165800004],
            [-80.609172483999942, 35.410599949000073],
            [-80.60899672499994, 35.410289101000046],
            [-80.608765347999963, 35.409929778000048],
            [-80.608161521999989, 35.409054294000043],
            [-80.607918321999989, 35.408722652000051],
            [-80.607795882999937, 35.408569643000078],
            [-80.607174913999984, 35.407814561000066],
            [-80.607023714999968, 35.407636508000053],
            [-80.606973805999985, 35.407580142000029],
            [-80.605877376999956, 35.406354280000073],
            [-80.605631800999959, 35.406092424000065],
            [-80.60561822599999, 35.406078646000026],
            [-80.603313901999968, 35.403745596000078],
            [-80.601960553999959, 35.40237279300004],
            [-80.60193269399997, 35.402344675000052],
            [-80.600345145999938, 35.40075057100006],
            [-80.59985355699996, 35.400219008000079],
            [-80.599421571999983, 35.39957617500005],
            [-80.598873608999952, 35.398637492000034],
            [-80.59856242099994, 35.397986822000064],
            [-80.597646855999983, 35.395196284000065],
            [-80.59686828599996, 35.392618040000059],
            [-80.596716129999947, 35.39190995000007],
            [-80.59664431899995, 35.391107676000047],
            [-80.596602085999962, 35.389836266000032],
            [-80.596652374999962, 35.389006875000064],
            [-80.596901626999966, 35.387603458000058],
            [-80.597395887999937, 35.385776864000036],
            [-80.597421317999988, 35.385679740000057],
            [-80.597499071999948, 35.385329479000063],
            [-80.597879403999968, 35.38333983800004],
            [-80.597887506999939, 35.383296591000033],
            [-80.597922879999942, 35.38308225600008],
            [-80.598046152999984, 35.382240980000063],
            [-80.598071523999977, 35.382045473000062],
            [-80.598090413999955, 35.381849473000045],
            [-80.598102812999969, 35.38165312600006],
            [-80.598108707999984, 35.381456576000062],
            [-80.598108096999965, 35.381259968000052],
            [-80.598084093999944, 35.380038974000058],
            [-80.598051840999972, 35.378398262000076],
            [-80.598038648999989, 35.377727139000058],
            [-80.598094952999986, 35.377325469000027],
            [-80.598377399999947, 35.376159506000079],
            [-80.599533705999988, 35.371751445000029],
            [-80.600725979999936, 35.367191702000071],
            [-80.600738108999963, 35.367144551000024],
            [-80.600790500999949, 35.366919652000036],
            [-80.601067737999983, 35.365618343000051],
            [-80.601104932999988, 35.365429004000077],
            [-80.601136004999944, 35.365238926000075],
            [-80.601160931999971, 35.365048242000057],
            [-80.601179694999985, 35.36485708500004],
            [-80.601236039999947, 35.364169350000054],
            [-80.601248691999956, 35.363976541000056],
            [-80.601255074999983, 35.363783525000031],
            [-80.601255183999967, 35.363590437000028],
            [-80.601249016999986, 35.363397416000055],
            [-80.601236582999945, 35.363204598000038],
            [-80.601174974999935, 35.362442090000059],
            [-80.601153892999946, 35.362228906000041],
            [-80.601125146999948, 35.362016324000024],
            [-80.60108876399994, 35.36180452900004],
            [-80.601044772999956, 35.361593704000029],
            [-80.600993212999981, 35.361384033000036],
            [-80.600791873999981, 35.360623371000031],
            [-80.600740419999966, 35.360440435000044],
            [-80.60068320299996, 35.360258650000048],
            [-80.600620263999986, 35.360078140000041],
            [-80.600551641999971, 35.359899023000025],
            [-80.59969687399996, 35.357764881000037],
            [-80.599652075999984, 35.35764511900004],
            [-80.599693251999952, 35.357545945000027],
            [-80.60020555899996, 35.356705189000024],
            [-80.602798177999944, 35.353038321000042],
            [-80.604907268999966, 35.350159159000043],
            [-80.60580517599999, 35.349390051000057],
            [-80.606507400999988, 35.348894618000031],
            [-80.607160757999964, 35.34855501800007],
            [-80.607580187999986, 35.348379249000061],
            [-80.608683629999973, 35.348061535000056],
            [-80.610043602999951, 35.347771292000061],
            [-80.610098126999958, 35.347759503000077],
            [-80.611763018999966, 35.347394868000038],
            [-80.613402501999985, 35.347129509000069],
            [-80.613656373999959, 35.347085238000034],
            [-80.613908552999987, 35.347034889000042],
            [-80.61415882099999, 35.346978505000038],
            [-80.61440696599999, 35.346916132000047],
            [-80.614652769999964, 35.346847826000044],
            [-80.61489602499995, 35.346773645000042],
            [-80.616145843999959, 35.346375316000035],
            [-80.616175082999973, 35.346365948000027],
            [-80.616522568999983, 35.346246982000025],
            [-80.617886899999974, 35.345751690000043],
            [-80.618119559999968, 35.345663904000048],
            [-80.61834902399994, 35.345570640000062],
            [-80.618575096999962, 35.345471976000056],
            [-80.620098593999955, 35.34478370100004],
            [-80.620374889999937, 35.344653507000032],
            [-80.620667496999943, 35.344503392000036],
            [-80.624213722999968, 35.342604115000029],
            [-80.631356618999973, 35.338937725000051],
            [-80.631484399999977, 35.338870875000055],
            [-80.633834726999964, 35.337617870000031],
            [-80.63405480199998, 35.337496658000077],
            [-80.634270344999948, 35.337370098000065],
            [-80.634481164999954, 35.337238304000039],
            [-80.636113491999936, 35.336185772000078],
            [-80.636298279999949, 35.336063291000073],
            [-80.636478987999965, 35.335936787000037],
            [-80.636655484999949, 35.335806354000056],
            [-80.636827643999936, 35.335672085000056],
            [-80.639371565999966, 35.333633938000048],
            [-80.63938570199997, 35.333622587000036],
            [-80.645366423999974, 35.328808833000039],
            [-80.645429708999984, 35.328757375000066],
            [-80.649808974999985, 35.325160302000029],
            [-80.65108291599995, 35.324199250000049],
            [-80.65134526199995, 35.324051996000037],
            [-80.652121515999966, 35.323734227000045],
            [-80.652647297999977, 35.323560764000035],
            [-80.652868946999945, 35.32351370300006],
            [-80.654284124999947, 35.323372758000062],
            [-80.654311531999952, 35.323369993000028],
            [-80.660552434999943, 35.32273202600004],
            [-80.670441624999967, 35.321738118000042],
            [-80.677033994999988, 35.32107710400004],
            [-80.681618149999963, 35.320640816000036],
            [-80.682926738999981, 35.320536698000069],
            [-80.68352020399999, 35.320528449000051],
            [-80.684221281999953, 35.320558988000073],
            [-80.685199486999977, 35.320659669000065],
            [-80.686054442999989, 35.32074973400006],
            [-80.687461588999952, 35.320965953000041],
            [-80.688443560999985, 35.32114860300004],
            [-80.688643098999989, 35.321183740000038],
            [-80.690231921999953, 35.321447828000032],
            [-80.69044237899999, 35.321480639000072],
            [-80.690483203999975, 35.321486505000053],
            [-80.691464039999971, 35.321625479000033],
            [-80.691738322999981, 35.321660718000032],
            [-80.692013820999989, 35.321688889000029],
            [-80.692290259999936, 35.321709963000046],
            [-80.692567369999949, 35.321723920000068],
            [-80.692844878999949, 35.321730746000071],
            [-80.693122511999945, 35.321730435000063],
            [-80.693650122999941, 35.321723058000032],
            [-80.694201395999983, 35.32173040400005],
            [-80.694459007999967, 35.321730763000062],
            [-80.694716522999954, 35.321724976000041],
            [-80.694973723999965, 35.321713049000039],
            [-80.695834203999937, 35.32166283600003],
            [-80.696087025999987, 35.32164509200004],
            [-80.696266552999987, 35.321628847000056],
            [-80.696940411999947, 35.321562156000027],
            [-80.697161739999956, 35.321537926000076],
            [-80.697382251999954, 35.32150912700007],
            [-80.697601806999955, 35.321475777000046],
            [-80.697820264999962, 35.321437897000067],
            [-80.698037487999954, 35.321395513000027],
            [-80.700819634999959, 35.320822135000071],
            [-80.70086921099994, 35.320811792000029],
            [-80.705769827999973, 35.319776915000034],
            [-80.709859800999936, 35.318943813000033],
            [-80.710045081999965, 35.318904319000069],
            [-80.710185409999951, 35.318872056000032],
            [-80.712409546999936, 35.318344455000044],
            [-80.712613679999947, 35.318293836000066],
            [-80.714095749999956, 35.317910281000024],
            [-80.714316890999953, 35.317850415000066],
            [-80.714536033999934, 35.317785799000035],
            [-80.714753024999936, 35.317716481000048],
            [-80.71496771599999, 35.317642505000038],
            [-80.715179956999975, 35.317563924000069],
            [-80.715389601999959, 35.317480793000072],
            [-80.717505855999946, 35.316613253000071],
            [-80.720199599999944, 35.315546024000071],
            [-80.720480303999977, 35.315429718000075],
            [-80.72083885699999, 35.315265710000062],
            [-80.722590446999959, 35.314420212000073],
            [-80.722808053999984, 35.314311682000039],
            [-80.723021758999948, 35.314198064000038],
            [-80.723231384999963, 35.314079451000055],
            [-80.72343675999997, 35.313955941000074],
            [-80.72363771299996, 35.313827638000078],
            [-80.725061367999956, 35.312891351000076],
            [-80.725237824999965, 35.312772197000072],
            [-80.725410465999971, 35.312649345000068],
            [-80.725579176999986, 35.312522878000038],
            [-80.72574384699999, 35.312392881000051],
            [-80.726750937999952, 35.311577012000043],
            [-80.726927631999956, 35.311429729000054],
            [-80.727099135999936, 35.311278384000047],
            [-80.727265313999965, 35.311123096000074],
            [-80.727426029999947, 35.31096399300003],
            [-80.728109678999942, 35.310267227000054],
            [-80.728290770999934, 35.310076220000042],
            [-80.728384664999965, 35.309971770000061],
            [-80.72899847399998, 35.309275996000054],
            [-80.729140034999944, 35.309110734000058],
            [-80.729275926999946, 35.308942307000052],
            [-80.729406045999951, 35.308770843000048],
            [-80.729530292999982, 35.308596477000037],
            [-80.729648568999949, 35.308419344000072],
            [-80.72976078399995, 35.308239580000077],
            [-80.730094115999975, 35.307686749000027],
            [-80.730427179999936, 35.307152618000032],
            [-80.73061510499997, 35.306832086000043],
            [-80.730766741999958, 35.306539366000038],
            [-80.731346678999955, 35.30534788600005],
            [-80.731383112999936, 35.305271787000038],
            [-80.731483651999952, 35.305047320000028],
            [-80.734055226999942, 35.298999151000032],
            [-80.73407376199998, 35.298955100000057],
            [-80.735712399999954, 35.295019455000045],
            [-80.735727825999959, 35.294982073000028],
            [-80.736361571999964, 35.293432317000054],
            [-80.736635926999952, 35.292888112000071],
            [-80.737396313999966, 35.29164551100007],
            [-80.740439920999961, 35.28715728700007],
            [-80.743299233999949, 35.282945813000026],
            [-80.74518907199996, 35.280178686000056],
            [-80.745244063999962, 35.280096933000038],
            [-80.747957454999948, 35.27600075600003],
            [-80.74895266599998, 35.274627881000072],
            [-80.749493964999942, 35.274052509000057],
            [-80.749681941999938, 35.273883683000065],
            [-80.750317439999947, 35.273436673000049],
            [-80.750771240999939, 35.273168603000045],
            [-80.751061854999989, 35.273031576000051],
            [-80.752957437999953, 35.272460503000048],
            [-80.753955185999985, 35.272187834000079],
            [-80.763248776999944, 35.269757178000077],
            [-80.763316789999976, 35.269739138000034],
            [-80.768837087999941, 35.268254526000078],
            [-80.769961832999968, 35.267959776000055],
            [-80.77006004399999, 35.267933519000053],
            [-80.770157394999956, 35.267906459000073],
            [-80.771151850999956, 35.267624734000037],
            [-80.771512611999981, 35.267515273000072],
            [-80.77193479999994, 35.267368048000037],
            [-80.772996536999983, 35.26697082000004],
            [-80.773189608999985, 35.266896263000035],
            [-80.773380430999964, 35.266817907000075],
            [-80.773568893999936, 35.26673579900006],
            [-80.775020651999967, 35.266084745000057],
            [-80.775335808999955, 35.265936430000068],
            [-80.775640196999973, 35.26577928200004],
            [-80.777457021999965, 35.26479841400004],
            [-80.77768103599999, 35.264673393000066],
            [-80.77790027399999, 35.26454280400003],
            [-80.778114530999972, 35.264406767000025],
            [-80.778323606999948, 35.264265411000054],
            [-80.779476717999955, 35.263461118000066],
            [-80.779845628999965, 35.263188214000024],
            [-80.779969865999988, 35.263089054000034],
            [-80.78091246799994, 35.262321802000031],
            [-80.782193875999951, 35.261362914000074],
            [-80.78327362899995, 35.260691956000073],
            [-80.783844458999965, 35.260395717000051],
            [-80.784708843999965, 35.260020069000063],
            [-80.78535082999997, 35.259817799000075],
            [-80.78646241499996, 35.259500357000036],
            [-80.786746790999985, 35.259433638000075],
            [-80.787375508999958, 35.259351952000031],
            [-80.787445187999936, 35.25934266400003],
            [-80.791203154999948, 35.258829082000034],
            [-80.791273277999949, 35.258819260000053],
            [-80.796605937999971, 35.25805404700003],
            [-80.797925247999956, 35.257941524000046],
            [-80.798176394999984, 35.257917120000059],
            [-80.798426589999963, 35.257886835000079],
            [-80.798675624999987, 35.257850694000069],
            [-80.798923298999966, 35.257808725000075],
            [-80.799169408999944, 35.257760966000035],
            [-80.800165327999935, 35.257555315000047],
            [-80.800410654999951, 35.257501571000034],
            [-80.800654000999941, 35.257442071000071],
            [-80.800895161999961, 35.257376865000026],
            [-80.801133941999979, 35.257306007000068],
            [-80.801370142999986, 35.257229553000059],
            [-80.801394207999977, 35.25722143400003],
            [-80.803234027999963, 35.256969347000052],
            [-80.805316334999986, 35.25674211900008],
            [-80.805523775999973, 35.256717425000033],
            [-80.805611591999934, 35.256705727000053],
            [-80.806701787999941, 35.256555866000042],
            [-80.806947026999978, 35.256519231000027],
            [-80.807190910999964, 35.256476940000027],
            [-80.807433242999934, 35.256429030000049],
            [-80.807673833999957, 35.256375534000028],
            [-80.80940974799995, 35.255967909000049],
            [-80.809769053999958, 35.255876709000063],
            [-80.810056695999947, 35.255793616000062],
            [-80.812228971999957, 35.255131328000061],
            [-80.812457840999969, 35.255058567000049],
            [-80.812684158999957, 35.254980622000062],
            [-80.812907751999944, 35.254897554000024],
            [-80.813128448999976, 35.254809429000034],
            [-80.813346078999984, 35.254716312000028],
            [-80.813560471999949, 35.254618277000077],
            [-80.814895831999934, 35.253987564000056],
            [-80.815129321999962, 35.253873325000029],
            [-80.81535841799996, 35.253753251000035],
            [-80.815582906999964, 35.253627458000039],
            [-80.815802573999974, 35.253496063000057],
            [-80.816017212999952, 35.253359191000072],
            [-80.816521645999956, 35.253027189000079],
            [-80.816733147999969, 35.252883499000063],
            [-80.817094727999972, 35.25261548900005],
            [-80.817437756999936, 35.252331548000029],
            [-80.817761193999957, 35.252032536000058],
            [-80.818064056999958, 35.251719362000074],
            [-80.818345425999951, 35.251392976000034],
            [-80.818433801999959, 35.251282040000035],
            [-80.81875045299995, 35.250876513000037],
            [-80.818879306999975, 35.250706766000064],
            [-80.819002221999938, 35.250534079000033],
            [-80.819119095999952, 35.250358594000033],
            [-80.819229834999987, 35.250180451000062],
            [-80.819334349999963, 35.249999796000054],
            [-80.819432555999981, 35.249816774000067],
            [-80.821162645999948, 35.248519836000071],
            [-80.821195220999982, 35.248495293000076],
            [-80.82451223399994, 35.245983461000037],
            [-80.824608224999963, 35.245909674000075],
            [-80.828707755999972, 35.242710951000049],
            [-80.828908074999958, 35.242556165000053],
            [-80.82955192299994, 35.242382924000026],
            [-80.829753360999973, 35.242326510000055],
            [-80.829953084999943, 35.242266134000033],
            [-80.830150979999985, 35.242201831000045],
            [-80.830346929999962, 35.242133639000031],
            [-80.830900746999987, 35.241934416000049],
            [-80.831050536999953, 35.241879165000057],
            [-80.831487286999959, 35.241701922000061],
            [-80.831911468999976, 35.241505208000035],
            [-80.832321796999963, 35.241289621000078],
            [-80.832717022999987, 35.241055816000028],
            [-80.83278471999995, 35.241012968000064],
            [-80.833243358999937, 35.240719756000033],
            [-80.833554583999955, 35.240511290000029],
            [-80.833623141999965, 35.240462697000055],
            [-80.834618943999942, 35.239749522000068],
            [-80.836448467999958, 35.23844835400007],
            [-80.836764527999946, 35.238212349000037],
            [-80.837107427999968, 35.23792835100005],
            [-80.837430734999941, 35.237629286000072],
            [-80.837733468999943, 35.237316062000048],
            [-80.838014709999982, 35.236989630000039],
            [-80.838273603999937, 35.236650982000072],
            [-80.838509363999947, 35.236301147000063],
            [-80.838721274999955, 35.235941187000037],
            [-80.83890869399994, 35.235572196000078],
            [-80.839071051999952, 35.23519529400005],
            [-80.839207855999973, 35.234811626000067],
            [-80.83931869099996, 35.234422357000028],
            [-80.839403219999951, 35.234028669000054],
            [-80.839461186999984, 35.233631759000048],
            [-80.839492416999974, 35.233232832000056],
            [-80.839496815999951, 35.232833098000071],
            [-80.839474368999959, 35.232433773000025],
            [-80.839425146999986, 35.23203606900006],
            [-80.839349296999956, 35.231641194000076],
            [-80.839247052999951, 35.231250346000024],
            [-80.839118722999956, 35.230864712000027],
            [-80.838964698999973, 35.230485465000072],
            [-80.838785447999953, 35.230113756000037],
            [-80.838581514999987, 35.229750713000044],
            [-80.838353519999941, 35.229397438000035],
            [-80.838102155999934, 35.229055004000031],
            [-80.837828183999989, 35.228724453000041],
            [-80.837532438999972, 35.228406785000061],
            [-80.837215818999937, 35.22810296800003],
            [-80.836879283999963, 35.227813923000042],
            [-80.836523856999975, 35.227540527000031],
            [-80.836150616999987, 35.227283611000075],
            [-80.835760698999934, 35.227043956000045],
            [-80.835355284999935, 35.226822288000051],
            [-80.834935607999967, 35.226619281000069],
            [-80.834502940999982, 35.226435551000066],
            [-80.834058596999967, 35.226271656000051],
            [-80.833603927999945, 35.226128093000057],
            [-80.833140311999955, 35.226005299000064],
            [-80.832669156999941, 35.225903646000063],
            [-80.832191894999937, 35.225823443000024],
            [-80.831709972999988, 35.22576493400004],
            [-80.831224855999949, 35.225728296000057],
            [-80.830738015999941, 35.225713639000048],
            [-80.830250930999966, 35.225721010000029],
            [-80.829765080999948, 35.225750386000072],
            [-80.829281938999941, 35.22580167600006],
            [-80.828802973999984, 35.225874726000029],
            [-80.828329639999936, 35.225969314000054],
            [-80.827863372999957, 35.226085152000053],
            [-80.827405588999966, 35.226221889000044],
            [-80.826957678999975, 35.226379110000039],
            [-80.826521000999946, 35.22655633800008],
            [-80.82609688399998, 35.226753032000033],
            [-80.825686612999959, 35.226968599000031],
            [-80.82529143499994, 35.22720238200003],
            [-80.824912549999965, 35.227453671000035],
            [-80.82486715999994, 35.227485730000069],
            [-80.82302600099996, 35.228795042000058],
            [-80.823002838999969, 35.228811571000051],
            [-80.822860528999968, 35.228913481000063],
            [-80.822505588999945, 35.229006714000036],
            [-80.822277632999942, 35.229069408000043],
            [-80.822051837999936, 35.229137164000065],
            [-80.82182837199997, 35.22920993200006],
            [-80.821607399999948, 35.229287659000079],
            [-80.82115403399996, 35.229453154000055],
            [-80.820817910999949, 35.229564054000036],
            [-80.820417003999978, 35.22970586100007],
            [-80.819980293999947, 35.229883065000024],
            [-80.819556143999989, 35.23007973700004],
            [-80.819145838999987, 35.230295281000053],
            [-80.81875062499995, 35.230529042000057],
            [-80.818371704999947, 35.230780311000046],
            [-80.81805521199999, 35.231013322000024],
            [-80.816568982999968, 35.232161618000077],
            [-80.816537528999959, 35.232186040000045],
            [-80.812469968999949, 35.235359456000026],
            [-80.809217595999939, 35.237822067000025],
            [-80.806739921999963, 35.239679198000033],
            [-80.806568579999976, 35.239811088000067],
            [-80.806401570999981, 35.239946669000062],
            [-80.806239012999981, 35.240085842000042],
            [-80.805554507999943, 35.240687668000078],
            [-80.805246856999986, 35.240972806000059],
            [-80.804943984999966, 35.241285948000041],
            [-80.804662596999947, 35.241612303000068],
            [-80.804403546999936, 35.241950878000068],
            [-80.804327923999949, 35.242062992000058],
            [-80.804215122999949, 35.242097382000054],
            [-80.803286731999947, 35.24231538500004],
            [-80.802836074999959, 35.242377333000036],
            [-80.800756271999944, 35.242604285000027],
            [-80.800548864999939, 35.242628970000055],
            [-80.800465316999976, 35.242640082000037],
            [-80.797496714999966, 35.243046820000075],
            [-80.797101168999973, 35.243108635000056],
            [-80.796627697999952, 35.243203099000027],
            [-80.796161286999961, 35.243318814000077],
            [-80.795703352999965, 35.243455431000029],
            [-80.795590057999959, 35.243492905000039],
            [-80.795344334999982, 35.243575804000045],
            [-80.794470832999934, 35.243650301000059],
            [-80.794012469999984, 35.243699356000036],
            [-80.793861280999977, 35.243719949000024],
            [-80.788261230999979, 35.244523537000077],
            [-80.784573905999935, 35.245027463000042],
            [-80.783448830999987, 35.245173635000071],
            [-80.783184216999985, 35.245211402000052],
            [-80.782921123999984, 35.24525574300003],
            [-80.782659792999937, 35.245306618000029],
            [-80.782400466999945, 35.245363979000047],
            [-80.781358974999989, 35.245608326000024],
            [-80.780999687999952, 35.245699441000056],
            [-80.780883170999971, 35.245731971000055],
            [-80.77940356199997, 35.24615450500005],
            [-80.779140219999988, 35.246233569000026],
            [-80.777869480999982, 35.246633928000051],
            [-80.777623095999957, 35.246715054000049],
            [-80.777379789999941, 35.246802212000034],
            [-80.777139783999985, 35.246895321000068],
            [-80.776903291999986, 35.24699429900005],
            [-80.775264030999949, 35.247706668000035],
            [-80.77484579299994, 35.247900529000049],
            [-80.774675957999989, 35.24798647800003],
            [-80.773502766999968, 35.248595284000032],
            [-80.773262084999942, 35.248724724000056],
            [-80.772890785999948, 35.248943285000053],
            [-80.771192205999967, 35.249998697000024],
            [-80.770980294999958, 35.250134574000072],
            [-80.770773518999988, 35.250275673000033],
            [-80.770572069999957, 35.250421866000067],
            [-80.768855144999975, 35.251706520000027],
            [-80.768710260999967, 35.251817389000053],
            [-80.768586026999969, 35.251916537000056],
            [-80.767752873999939, 35.252594621000071],
            [-80.767279293999934, 35.252924906000032],
            [-80.766217945999983, 35.253497871000036],
            [-80.765364660999978, 35.253880517000027],
            [-80.76497834099996, 35.254025047000027],
            [-80.764480306999985, 35.254166135000048],
            [-80.76342174499996, 35.254443536000053],
            [-80.763357571999961, 35.254460574000063],
            [-80.75784005099996, 35.25594441800007],
            [-80.748527131999936, 35.258380088000024],
            [-80.748421465999968, 35.258408321000047],
            [-80.74723815599998, 35.258731692000026],
            [-80.746984366999982, 35.25880458000006],
            [-80.74425649899996, 35.259626368000056],
            [-80.744022873999938, 35.259699838000074],
            [-80.743791874999943, 35.259778699000037],
            [-80.743563687999938, 35.259862888000043],
            [-80.743338496999968, 35.259952338000062],
            [-80.74311647899998, 35.260046976000069],
            [-80.742897814999935, 35.260146728000052],
            [-80.741561797999964, 35.260776646000068],
            [-80.741366902999971, 35.260871278000025],
            [-80.741175025999951, 35.260969972000055],
            [-80.740986292999935, 35.26107266300005],
            [-80.740800826999987, 35.261179284000036],
            [-80.739674935999972, 35.261844326000073],
            [-80.739474324999946, 35.261966385000051],
            [-80.739277995999942, 35.26209304200006],
            [-80.739086106999935, 35.26222419700008],
            [-80.737756541999943, 35.263159320000057],
            [-80.737554755999952, 35.263305672000058],
            [-80.737358495999956, 35.263456996000059],
            [-80.737167944999953, 35.263613150000026],
            [-80.736983278999958, 35.26377399200004],
            [-80.736184197999989, 35.264491561000057],
            [-80.735944505999953, 35.264715894000062],
            [-80.735712805999981, 35.26495178700003],
            [-80.73462065199999, 35.266112474000067],
            [-80.734460694999939, 35.266287878000071],
            [-80.734307267999952, 35.266467162000026],
            [-80.734160508999935, 35.266650163000065],
            [-80.734020554999972, 35.266836715000068],
            [-80.732664227999976, 35.268707271000039],
            [-80.732651617999977, 35.268724717000055],
            [-80.732494798999937, 35.268951582000057],
            [-80.729726203999974, 35.273129907000055],
            [-80.727858191999985, 35.27586431800006],
            [-80.727847152999971, 35.275880525000048],
            [-80.724979907999966, 35.280102507000038],
            [-80.721841637999944, 35.284729026000036],
            [-80.721720527999935, 35.284913797000058],
            [-80.721666124999956, 35.285001197000042],
            [-80.720676250999986, 35.286618302000079],
            [-80.720518092999953, 35.286890654000047],
            [-80.720407605999981, 35.287100319000047],
            [-80.719893815999967, 35.288119057000074],
            [-80.719816226999967, 35.288278195000032],
            [-80.719681703999981, 35.288584083000046],
            [-80.718958967999981, 35.290350571000033],
            [-80.717336609999961, 35.294245193000052],
            [-80.714837744999954, 35.300119530000075],
            [-80.714482620999945, 35.300848832000042],
            [-80.714309543999946, 35.301126307000061],
            [-80.714255918999982, 35.30121372900004],
            [-80.714224335999972, 35.301266092000049],
            [-80.714177483999947, 35.301319190000072],
            [-80.713952141999982, 35.301548817000025],
            [-80.713629440999966, 35.301810213000067],
            [-80.713060351999957, 35.302184450000027],
            [-80.712171158999979, 35.302613643000029],
            [-80.709750419999978, 35.30357267200003],
            [-80.709642892999966, 35.303616008000063],
            [-80.708099410999978, 35.304248729000051],
            [-80.707386784999983, 35.304433149000033],
            [-80.705427406999945, 35.304897941000036],
            [-80.701464776999956, 35.305705094000075],
            [-80.701390799999956, 35.305720440000073],
            [-80.69647873699995, 35.306757726000058],
            [-80.694290400999989, 35.307208727000045],
            [-80.69410249799995, 35.307219692000047],
            [-80.693790454999942, 35.307215532000043],
            [-80.693739458999971, 35.307214973000043],
            [-80.693668337999952, 35.30721516400007],
            [-80.693624150999938, 35.30720890300006],
            [-80.692260837999982, 35.306982298000037],
            [-80.691215212999964, 35.30678780900007],
            [-80.690887259999954, 35.306732133000025],
            [-80.689065592999953, 35.306452221000029],
            [-80.688983621999967, 35.306439952000062],
            [-80.688562996999963, 35.306387189000077],
            [-80.687443104999943, 35.306269216000032],
            [-80.687417513999947, 35.306266551000078],
            [-80.686111042999983, 35.306132082000033],
            [-80.685900506999985, 35.30611250700008],
            [-80.685689460999981, 35.306097069000032],
            [-80.685478023999963, 35.306085778000067],
            [-80.684149865999984, 35.306027924000034],
            [-80.68377398399997, 35.306018107000057],
            [-80.683530137999981, 35.306018744000028],
            [-80.68227337899998, 35.306036210000059],
            [-80.682029645999989, 35.306042351000031],
            [-80.681568304999985, 35.306069108000031],
            [-80.679825169999958, 35.306207803000063],
            [-80.679800128999943, 35.306209826000043],
            [-80.679659234999974, 35.306222301000048],
            [-80.674965866999969, 35.306668984000055],
            [-80.674911491999978, 35.306674298000075],
            [-80.668288658999984, 35.307338368000046],
            [-80.658389922999959, 35.308333234000031],
            [-80.658371055999964, 35.308335147000037],
            [-80.652135261999945, 35.308972593000078],
            [-80.650150860999986, 35.309170223000024],
            [-80.649918336999974, 35.309195948000024],
            [-80.649686724999981, 35.309226716000069],
            [-80.649456190999956, 35.309262506000039],
            [-80.64922689499997, 35.309303292000038],
            [-80.648998997999968, 35.309349048000058],
            [-80.647654492999948, 35.309634517000063],
            [-80.647433884999941, 35.309683856000049],
            [-80.647214906999977, 35.309737853000058],
            [-80.646997702999954, 35.309796468000059],
            [-80.646782419999965, 35.309859663000054],
            [-80.646569199999988, 35.309927397000024],
            [-80.645181598999955, 35.310385174000032],
            [-80.644797045999951, 35.31052078700003],
            [-80.644531781999945, 35.310624897000025],
            [-80.64289236999997, 35.311295985000072],
            [-80.642676535999954, 35.31138738900006],
            [-80.642463842999973, 35.311483614000053],
            [-80.642254452999964, 35.311584588000073],
            [-80.642048521999982, 35.311690235000071],
            [-80.641846206999958, 35.31180047600003],
            [-80.640557033999983, 35.312524032000056],
            [-80.640349515999958, 35.312644122000052],
            [-80.640146287999983, 35.31276904300006],
            [-80.639947518999975, 35.312898695000058],
            [-80.639753369999937, 35.313032969000062],
            [-80.639564001999986, 35.313171755000042],
            [-80.637683442999958, 35.314590286000055],
            [-80.63757577399997, 35.314672868000059],
            [-80.637411948999954, 35.314803988000051],
            [-80.632931214999985, 35.318483957000069],
            [-80.626989132999938, 35.323266032000049]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 113,
        "SolutionID": "PI-11:D",
        "Shape_Length": 1.4292148726991076,
        "Shape_Area": 0.011315779135791982
      }
    },
    {
      "type": "Feature",
      "id": 114,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.240747084999953, 35.29438755700005],
            [-81.241657843999974, 35.294740062000074],
            [-81.242064733999939, 35.294887406000043],
            [-81.24252050399997, 35.295029431000046],
            [-81.24298512699994, 35.295150654000054],
            [-81.243457190999948, 35.295250707000037],
            [-81.24393526199998, 35.295329285000037],
            [-81.244287396999937, 35.295372968000038],
            [-81.246563889999948, 35.295617031000063],
            [-81.24680776799994, 35.295640357000025],
            [-81.247052340999971, 35.295658126000035],
            [-81.247297421999974, 35.295670324000071],
            [-81.247542820999968, 35.295676941000067],
            [-81.24963889199995, 35.29570958100004],
            [-81.249883663999981, 35.295710614000029],
            [-81.250128376999953, 35.295706094000025],
            [-81.250372843999969, 35.295696027000076],
            [-81.25061687699997, 35.295680418000074],
            [-81.250860289999935, 35.295659279000063],
            [-81.251102896999953, 35.295632629000067],
            [-81.252494996999985, 35.295463593000079],
            [-81.252612457999987, 35.295448668000063],
            [-81.253091447999964, 35.295373941000037],
            [-81.253564687999983, 35.295277694000049],
            [-81.254030740999951, 35.295160217000046],
            [-81.254186268999945, 35.295115807000059],
            [-81.256004701999984, 35.294580933000077],
            [-81.256249855999954, 35.294505445000027],
            [-81.256492175999938, 35.29442404100007],
            [-81.25673145199994, 35.29433679400006],
            [-81.256967471999985, 35.294243778000066],
            [-81.257200030999968, 35.294145076000063],
            [-81.257428924999942, 35.294040773000063],
            [-81.257576848999975, 35.293970998000077],
            [-81.258028921999937, 35.294054750000043],
            [-81.258353685999964, 35.294109691000074],
            [-81.260019420999981, 35.294364893000079],
            [-81.260146493999969, 35.29438357500004],
            [-81.26062912499998, 35.294440377000058],
            [-81.261114840999937, 35.294475292000072],
            [-81.261602163999953, 35.294488216000047],
            [-81.261845967999989, 35.294486416000041],
            [-81.263329256999953, 35.29445869400007],
            [-81.263596427999971, 35.294450385000061],
            [-81.263863169999979, 35.294435461000035],
            [-81.264129240999978, 35.294413938000048],
            [-81.265087024999957, 35.294324450000033],
            [-81.265347550999934, 35.294296888000076],
            [-81.26560696599995, 35.294262998000079],
            [-81.265865036999969, 35.294222809000075],
            [-81.266121540999961, 35.294176354000058],
            [-81.266376247999972, 35.294123677000073],
            [-81.266628935999961, 35.294064823000042],
            [-81.266879379999978, 35.29399984500003],
            [-81.268005676999962, 35.293692462000024],
            [-81.268044571999951, 35.293681765000031],
            [-81.268501989999947, 35.293543363000026],
            [-81.268949412999973, 35.293384511000056],
            [-81.269385480999972, 35.293205690000036],
            [-81.26958270099999, 35.293116499000064],
            [-81.270702986999936, 35.292594489000066],
            [-81.270931075999954, 35.292484473000059],
            [-81.271155049999948, 35.292368914000065],
            [-81.27137470699995, 35.292247914000029],
            [-81.271589850999987, 35.29212158100006],
            [-81.271800290999977, 35.291990029000033],
            [-81.273172302999967, 35.291105411000046],
            [-81.27348258699999, 35.290895821000049],
            [-81.273745253999948, 35.290702392000071],
            [-81.275322476999975, 35.289493404000041],
            [-81.275340791999952, 35.289502578000054],
            [-81.275558838999984, 35.289608314000077],
            [-81.275780489999988, 35.289708887000074],
            [-81.276005562999956, 35.28980421600005],
            [-81.276233871999978, 35.289894222000044],
            [-81.276465231999964, 35.289978830000052],
            [-81.276699450999956, 35.290057974000035],
            [-81.276936338999974, 35.29013158500004],
            [-81.278520811999954, 35.290602776000071],
            [-81.278729541999951, 35.290662437000037],
            [-81.278940025999987, 35.290717804000053],
            [-81.279152129999943, 35.290768842000034],
            [-81.279365723999945, 35.290815519000034],
            [-81.280946213999982, 35.291143610000063],
            [-81.281183512999974, 35.29119002200008],
            [-81.281422280999948, 35.291231053000047],
            [-81.281662338999979, 35.291266672000063],
            [-81.283085248999953, 35.291461208000044],
            [-81.284784477999949, 35.291832996000039],
            [-81.285091670999975, 35.291895403000069],
            [-81.285569761999966, 35.291973817000041],
            [-81.286052396999935, 35.292030518000047],
            [-81.286538108999935, 35.29206533100006],
            [-81.287025421999942, 35.292078153000034],
            [-81.287269217999949, 35.292076302000055],
            [-81.288403457999948, 35.292054866000058],
            [-81.288647097999956, 35.292047505000028],
            [-81.289133172999982, 35.292016287000024],
            [-81.289616412999976, 35.291963162000059],
            [-81.290095347999966, 35.291888289000042],
            [-81.29056852399998, 35.291791897000053],
            [-81.291034503999981, 35.291674277000027],
            [-81.291491871999938, 35.291535789000079],
            [-81.291939237999941, 35.291376852000042],
            [-81.29237524499996, 35.291197948000047],
            [-81.292613941999946, 35.29108924600007],
            [-81.293144308999956, 35.29083885700004],
            [-81.294172533999983, 35.290468720000035],
            [-81.294355973999984, 35.290400633000047],
            [-81.294586932999948, 35.290308930000037],
            [-81.294993071999954, 35.290141665000078],
            [-81.297226878999936, 35.289921560000039],
            [-81.297407894999935, 35.289902166000047],
            [-81.30184533299996, 35.289388435000035],
            [-81.30202621799998, 35.289365923000048],
            [-81.30206207599997, 35.289361087000032],
            [-81.304312575999973, 35.289053648000049],
            [-81.307608874999971, 35.288719812000068],
            [-81.307942711999942, 35.288680687000067],
            [-81.308421610999972, 35.288605741000026],
            [-81.308894745999964, 35.28850927700006],
            [-81.309360679999941, 35.288391587000035],
            [-81.309817998999961, 35.288253030000078],
            [-81.309999901999959, 35.288191260000076],
            [-81.310864221999964, 35.287888473000066],
            [-81.311121954999976, 35.287794169000051],
            [-81.312507469999957, 35.287265307000041],
            [-81.312947246999954, 35.287084862000029],
            [-81.313245198999937, 35.286947860000055],
            [-81.31402021699995, 35.286575184000071],
            [-81.31414551499995, 35.286513788000036],
            [-81.314554786999963, 35.286296570000047],
            [-81.314948842999968, 35.286061194000069],
            [-81.315326482999978, 35.285808375000045],
            [-81.315365167999971, 35.285780824000028],
            [-81.316391710999937, 35.28504545800007],
            [-81.316580876999978, 35.284905962000039],
            [-81.316765073999989, 35.284762064000063],
            [-81.316944146999958, 35.284613881000041],
            [-81.317117947999975, 35.284461537000027],
            [-81.317286333999959, 35.284305158000052],
            [-81.31744916599996, 35.284144873000059],
            [-81.317606308999984, 35.283980815000064],
            [-81.318622617999949, 35.282887760000051],
            [-81.318693604999964, 35.28281034400004],
            [-81.318973070999959, 35.282482800000025],
            [-81.319230105999964, 35.282143130000065],
            [-81.319463927999948, 35.281792365000058],
            [-81.319673827999964, 35.281431569000063],
            [-81.319689171999983, 35.281403044000058],
            [-81.320106693999946, 35.280622294000068],
            [-81.320497155999988, 35.28004270100007],
            [-81.320622707999973, 35.279849929000079],
            [-81.320740895999961, 35.279654050000033],
            [-81.320851603999984, 35.279455255000073],
            [-81.320954724999979, 35.279253735000054],
            [-81.32105016099996, 35.279049685000075],
            [-81.321242017999964, 35.278936255000076],
            [-81.322086893999938, 35.278422521000039],
            [-81.323043430999974, 35.27802824500003],
            [-81.32324840299998, 35.277940989000058],
            [-81.323671580999985, 35.277742556000078],
            [-81.324080782999943, 35.27752530500004],
            [-81.324474768999949, 35.277289898000049],
            [-81.324830774999953, 35.277052271000059],
            [-81.325771933999988, 35.27639003400003],
            [-81.32579349699995, 35.276374811000039],
            [-81.326153499999975, 35.276105286000075],
            [-81.326494848999971, 35.275819906000038],
            [-81.326816504999954, 35.275519539000072],
            [-81.327046517999975, 35.275282519000029],
            [-81.328062591999981, 35.274189384000067],
            [-81.328193547999945, 35.274044805000074],
            [-81.328320085999962, 35.27389760300008],
            [-81.328442128999939, 35.273747872000058],
            [-81.328559600999938, 35.273595701000033],
            [-81.330548144999966, 35.270947488000047],
            [-81.332299284999976, 35.268679657000064],
            [-81.334502687999986, 35.266005378000045],
            [-81.334559066999986, 35.265936092000061],
            [-81.334717054999942, 35.265732194000066],
            [-81.336339024999972, 35.263559721000036],
            [-81.337540897999986, 35.262038195000059],
            [-81.337726444999987, 35.261792717000048],
            [-81.338146086999984, 35.261212023000041],
            [-81.338179439999976, 35.261165477000077],
            [-81.338413119999984, 35.260814673000027],
            [-81.338622882999971, 35.26045384300005],
            [-81.338808089999986, 35.260084084000027],
            [-81.338968181999974, 35.25970651800003],
            [-81.339102668999942, 35.259322291000046],
            [-81.339166129999967, 35.259106521000035],
            [-81.339351114999943, 35.258432484000025],
            [-81.339608974999976, 35.257682670000065],
            [-81.339675375999946, 35.257478214000059],
            [-81.339734479999947, 35.257272256000078],
            [-81.339786234999963, 35.25706497200008],
            [-81.339830596999946, 35.256856538000079],
            [-81.339867528999946, 35.256647131000079],
            [-81.339918744999977, 35.256323480000049],
            [-81.339941677999946, 35.25626227500004],
            [-81.339996135999968, 35.256116937000058],
            [-81.341587954999966, 35.255000792000033],
            [-81.343846854999981, 35.253541545000076],
            [-81.344156811999937, 35.25333177300007],
            [-81.34430598199998, 35.253223624000043],
            [-81.346532621999984, 35.251572266000039],
            [-81.347893256999953, 35.250708405000069],
            [-81.34810108299996, 35.250572306000038],
            [-81.348303826999938, 35.250431143000071],
            [-81.348501301999988, 35.250285041000041],
            [-81.348693331999982, 35.250134135000053],
            [-81.348879743999987, 35.249978559000056],
            [-81.349816264999959, 35.249173065000036],
            [-81.349899751999942, 35.249100260000034],
            [-81.350221215999966, 35.248799829000063],
            [-81.350522013999978, 35.248485327000026],
            [-81.350801234999949, 35.248157708000065],
            [-81.351058028999944, 35.247817969000039],
            [-81.351196565999942, 35.247615491000033],
            [-81.351865239999938, 35.246600461000071],
            [-81.351960285999951, 35.246452109000074],
            [-81.352169950999951, 35.246091256000057],
            [-81.352355064999983, 35.245721475000039],
            [-81.352515064999977, 35.245343889000026],
            [-81.352538623999976, 35.245281963000025],
            [-81.35294360499995, 35.244200481000064],
            [-81.353013455999985, 35.24400412500006],
            [-81.353076529999953, 35.243806230000075],
            [-81.353132776999985, 35.243606956000065],
            [-81.353182152999977, 35.243406460000074],
            [-81.353224617999956, 35.24320490100007],
            [-81.35326013699995, 35.243002441000044],
            [-81.353288684999939, 35.242799238000032],
            [-81.353429993999953, 35.24165130800003],
            [-81.353441663999945, 35.24154930800006],
            [-81.353470378999987, 35.241150255000036],
            [-81.353472254999986, 35.240750508000076],
            [-81.353447285999948, 35.240351284000042],
            [-81.353395549999959, 35.239953795000076],
            [-81.353317201999971, 35.239559247000045],
            [-81.353212482999936, 35.239168839000058],
            [-81.35308170899998, 35.238783757000078],
            [-81.352925277999987, 35.238405170000078],
            [-81.352743665999981, 35.238034227000071],
            [-81.35253742499998, 35.237672056000065],
            [-81.352307181999947, 35.237319755000044],
            [-81.352053635999937, 35.236978394000062],
            [-81.351777554999956, 35.236649011000054],
            [-81.351479780999966, 35.23633260500003],
            [-81.351161216999969, 35.236030137000057],
            [-81.35082282999997, 35.235742526000024],
            [-81.350465647999954, 35.235470644000031],
            [-81.350090755999986, 35.235215317000041],
            [-81.349699292999958, 35.234977322000077],
            [-81.349292445999936, 35.23475737900003],
            [-81.348871452999958, 35.234556158000032],
            [-81.348437589999946, 35.234374269000057],
            [-81.347992174999945, 35.234212264000064],
            [-81.34753656099997, 35.234070634000034],
            [-81.347072130999948, 35.233949812000048],
            [-81.346600294999973, 35.233850162000067],
            [-81.346122486999946, 35.233771988000058],
            [-81.345640155999945, 35.233715526000026],
            [-81.345154765999951, 35.233680950000064],
            [-81.344667792999985, 35.23366836100007],
            [-81.344180712999957, 35.233677801000056],
            [-81.343695007999941, 35.233709241000042],
            [-81.343212148999953, 35.233762582000054],
            [-81.342733603999989, 35.233837666000056],
            [-81.342260826999961, 35.233934263000037],
            [-81.341795250999951, 35.234052081000073],
            [-81.341338291999989, 35.234190760000047],
            [-81.34089133599997, 35.23434988200006],
            [-81.340455739999982, 35.23452896200007],
            [-81.340032826999959, 35.234727455000041],
            [-81.339623882999945, 35.234944761000065],
            [-81.339230145999977, 35.235180220000075],
            [-81.338852813999949, 35.235433115000035],
            [-81.338493032999963, 35.235702680000031],
            [-81.338151894999953, 35.235988095000039],
            [-81.33783043699998, 35.236288495000053],
            [-81.337529634999953, 35.236602966000078],
            [-81.337250399999959, 35.236930555000072],
            [-81.33699358299998, 35.237270266000053],
            [-81.336759964999942, 35.237621068000067],
            [-81.336550252999984, 35.237981896000065],
            [-81.336365084999954, 35.238351653000052],
            [-81.33620502399998, 35.238729217000071],
            [-81.336070556999971, 35.239113442000075],
            [-81.335962091999988, 35.239503161000073],
            [-81.335879957999964, 35.239897190000079],
            [-81.335864621999974, 35.240000390000034],
            [-81.33544195199994, 35.240268713000034],
            [-81.335072958999945, 35.240516286000059],
            [-81.334923800999945, 35.240624424000032],
            [-81.33266940499999, 35.242296201000045],
            [-81.330507071999989, 35.243692939000027],
            [-81.330232234999983, 35.24387792300007],
            [-81.327922915999977, 35.245496994000064],
            [-81.327753902999973, 35.245618569000044],
            [-81.327588709999986, 35.24574362900006],
            [-81.327427444999955, 35.24587209200007],
            [-81.327270212999963, 35.246003877000078],
            [-81.326418660999934, 35.246735988000069],
            [-81.326335159999985, 35.246808774000044],
            [-81.326013615999955, 35.247109141000067],
            [-81.325712727999985, 35.247423582000067],
            [-81.32543340899997, 35.247751144000063],
            [-81.325176508999959, 35.248090829000034],
            [-81.325129137999966, 35.248158328000045],
            [-81.324626352999985, 35.248883723000063],
            [-81.324453371999937, 35.249145665000071],
            [-81.323952616999975, 35.249942590000046],
            [-81.323939263999989, 35.249963925000031],
            [-81.323729463999939, 35.250324730000045],
            [-81.323544209999966, 35.250694467000073],
            [-81.323384064999971, 35.251072014000044],
            [-81.323360482999988, 35.251133933000062],
            [-81.323036153999965, 35.251999044000058],
            [-81.322792904999972, 35.252647876000026],
            [-81.322723011999983, 35.252844103000029],
            [-81.322659887999976, 35.253041868000025],
            [-81.322603581999942, 35.253241013000036],
            [-81.322554138999976, 35.253441380000027],
            [-81.322511599999984, 35.253642811000077],
            [-81.32247599699997, 35.253845145000071],
            [-81.322411380999938, 35.254252931000053],
            [-81.322264569999959, 35.254679569000075],
            [-81.322254810999937, 35.254709223000077],
            [-81.321434114999988, 35.255747946000042],
            [-81.321314111999982, 35.255904146000034],
            [-81.319736571999954, 35.258016595000072],
            [-81.317570155999988, 35.260645404000059],
            [-81.317513765999934, 35.260714682000071],
            [-81.317426060999935, 35.260825981000039],
            [-81.31557447299997, 35.263223335000077],
            [-81.315515533999985, 35.263300715000071],
            [-81.313782058999948, 35.265608692000058],
            [-81.313460232999944, 35.265741337000065],
            [-81.313234977999969, 35.265837525000052],
            [-81.313013182999953, 35.265938977000076],
            [-81.312795028999972, 35.266045609000059],
            [-81.312580697999977, 35.26615733400007],
            [-81.312370364999936, 35.266274059000068],
            [-81.312164205999977, 35.266395688000046],
            [-81.31080051899994, 35.267224834000046],
            [-81.309866659999955, 35.267761584000027],
            [-81.309667799999943, 35.26787928400006],
            [-81.309472994999965, 35.26800146100004],
            [-81.309282393999979, 35.268128019000073],
            [-81.309096144999955, 35.268258864000074],
            [-81.308914389999984, 35.268393892000063],
            [-81.308145606999972, 35.268981138000072],
            [-81.307972407999955, 35.269117078000079],
            [-81.307803762999981, 35.269256818000031],
            [-81.307639798999958, 35.26940025500005],
            [-81.307480633999944, 35.269547284000055],
            [-81.307326385999943, 35.26969779500007],
            [-81.306817226999954, 35.270208510000032],
            [-81.306668206999973, 35.270362182000042],
            [-81.306524312999954, 35.270519105000062],
            [-81.306385649999982, 35.270679164000057],
            [-81.306252318999952, 35.270842243000061],
            [-81.306124418999957, 35.271008221000045],
            [-81.306002042999978, 35.271176977000039],
            [-81.305885279999984, 35.271348387000046],
            [-81.30563359699994, 35.271729898000046],
            [-81.305302684999958, 35.272187409000026],
            [-81.305269316999954, 35.272233936000077],
            [-81.305035461999978, 35.272584675000076],
            [-81.304825518999962, 35.272945445000062],
            [-81.30464012799996, 35.273315152000066],
            [-81.30447985099994, 35.273692672000038],
            [-81.304387557999974, 35.273955957000055],
            [-81.304292936999957, 35.274101839000025],
            [-81.304085148999945, 35.274458894000077],
            [-81.301958784999954, 35.274674230000073],
            [-81.30162500299997, 35.274713338000026],
            [-81.301589150999973, 35.274718174000043],
            [-81.299262923999947, 35.275035961000071],
            [-81.295025190999979, 35.275526571000057],
            [-81.292417672999989, 35.275783494000052],
            [-81.292055829999981, 35.275825377000047],
            [-81.292019976999939, 35.275830210000038],
            [-81.290802758999973, 35.275996399000064],
            [-81.290359770999942, 35.276066442000058],
            [-81.289886685999988, 35.276162834000047],
            [-81.289420792999977, 35.276280449000069],
            [-81.288963507999938, 35.276418930000034],
            [-81.288516217999984, 35.276577857000063],
            [-81.288285290999966, 35.276669548000029],
            [-81.286850600999969, 35.277260403000071],
            [-81.286814930999981, 35.277273243000025],
            [-81.286453102999985, 35.277213891000031],
            [-81.28641225299998, 35.277208226000027],
            [-81.284933422999984, 35.277006050000068],
            [-81.284136973999978, 35.276840719000063],
            [-81.283804862999943, 35.276741958000059],
            [-81.283324654999944, 35.276501426000038],
            [-81.283095213999957, 35.276390355000046],
            [-81.282861798999988, 35.27628500000003],
            [-81.282624619999979, 35.276185459000033],
            [-81.282383894999953, 35.276091819000044],
            [-81.282139838999967, 35.276004166000064],
            [-81.281892675999984, 35.275922581000032],
            [-81.280395700999975, 35.275449789000049],
            [-81.280127009999944, 35.275368994000075],
            [-81.280083020999939, 35.275356531000057],
            [-81.278411603999984, 35.274887001000025],
            [-81.277991032999978, 35.274778378000065],
            [-81.277519045999952, 35.274678460000075],
            [-81.277041063999945, 35.274600013000054],
            [-81.27655853899995, 35.274543276000031],
            [-81.276072934999945, 35.274508423000043],
            [-81.275893996999969, 35.274501135000037],
            [-81.274496307999982, 35.274455838000051],
            [-81.274188039999956, 35.27445025600008],
            [-81.273700706999989, 35.274459412000056],
            [-81.273214731999985, 35.274490568000033],
            [-81.272731584999974, 35.27454362800006],
            [-81.272252735999984, 35.274618433000057],
            [-81.271779637999941, 35.274714754000058],
            [-81.271313726999949, 35.274832299000025],
            [-81.271158247999949, 35.274876732000052],
            [-81.269600053999966, 35.275335393000034],
            [-81.269365017999974, 35.275407679000068],
            [-81.269132576999937, 35.275485403000062],
            [-81.268902917999981, 35.27556850600007],
            [-81.268676226999958, 35.275656918000038],
            [-81.268452685999989, 35.275750568000035],
            [-81.267417855999952, 35.276199391000034],
            [-81.267380093999975, 35.27621586500004],
            [-81.26695678699997, 35.276414102000047],
            [-81.266547437999975, 35.276631159000033],
            [-81.266153287999941, 35.276866379000069],
            [-81.266021041999977, 35.27695162100008],
            [-81.264563945999953, 35.277909327000032],
            [-81.26431843599994, 35.278076747000057],
            [-81.264055786999961, 35.278270156000076],
            [-81.261934074999942, 35.279895771000042],
            [-81.261766669999986, 35.27987395100007],
            [-81.259822775999965, 35.279513807000058],
            [-81.259371019999946, 35.279440187000034],
            [-81.25888847899995, 35.27938338000007],
            [-81.258402853999939, 35.279348456000037],
            [-81.257915621999985, 35.279335519000028],
            [-81.257428257999948, 35.279344609000077],
            [-81.256942246999984, 35.27937570000006],
            [-81.256792885999971, 35.279389711000078],
            [-81.255052123999974, 35.279565299000069],
            [-81.254718296999954, 35.279604278000079],
            [-81.254352332999986, 35.279659361000029],
            [-81.253396690999978, 35.27982030000004],
            [-81.253161729999988, 35.279862607000041],
            [-81.252928255999961, 35.279910137000059],
            [-81.252696446999948, 35.279962854000075],
            [-81.252466469999945, 35.280020720000039],
            [-81.252238496999951, 35.280083692000062],
            [-81.252012695999952, 35.280151724000063],
            [-81.250801265999939, 35.280532116000074],
            [-81.250544574999935, 35.280616512000051],
            [-81.250291224999955, 35.280707446000065],
            [-81.250041463999935, 35.280804829000033],
            [-81.249795537999944, 35.280908566000051],
            [-81.249553685999956, 35.28101855500006],
            [-81.249288627999988, 35.281143578000069],
            [-81.249125923999941, 35.281191435000039],
            [-81.248370394999938, 35.281179669000039],
            [-81.247952839999982, 35.281134905000044],
            [-81.247436248999975, 35.280635202000042],
            [-81.245586616999958, 35.278662181000072],
            [-81.24542936499995, 35.278499313000054],
            [-81.245195257999967, 35.278273439000031],
            [-81.243224817999987, 35.27644911200008],
            [-81.243062899999984, 35.276303240000061],
            [-81.242896203999976, 35.276161031000072],
            [-81.242724854999949, 35.27602259300005],
            [-81.242548979999981, 35.275888025000029],
            [-81.240766904999987, 35.274561171000073],
            [-81.240573517999962, 35.27442134100005],
            [-81.24037524299996, 35.274286192000034],
            [-81.240172249999944, 35.274155839000059],
            [-81.239964710999971, 35.274030392000043],
            [-81.239752802999988, 35.273909959000036],
            [-81.234481696999978, 35.271006346000036],
            [-81.228668540999934, 35.267543834000037],
            [-81.228547610999954, 35.267473063000068],
            [-81.228430541999955, 35.26740690400004],
            [-81.225063231999968, 35.265536434000069],
            [-81.22484319199998, 35.265418078000039],
            [-81.224067132999949, 35.265014053000073],
            [-81.224014508999971, 35.264964559000077],
            [-81.223856446999946, 35.264819859000056],
            [-81.223693719999972, 35.264678681000078],
            [-81.223526441999979, 35.26454112600004],
            [-81.219288795999944, 35.261148386000059],
            [-81.215762256999938, 35.258244005000051],
            [-81.215695715999971, 35.258189779000077],
            [-81.215608232999955, 35.258120203000033],
            [-81.21444915099994, 35.257210992000068],
            [-81.214179810999951, 35.257008287000076],
            [-81.213805246999982, 35.256752539000047],
            [-81.21341407999995, 35.256514104000075],
            [-81.213007492999964, 35.25629370300004],
            [-81.212586725999984, 35.256092009000042],
            [-81.212153052999952, 35.255909632000055],
            [-81.211862838999934, 35.255800987000043],
            [-81.210717429999988, 35.255392491000066],
            [-81.210562383999957, 35.255338628000061],
            [-81.210106890999953, 35.255196482000031],
            [-81.209642544999951, 35.255075132000059],
            [-81.209170755999935, 35.254974947000051],
            [-81.208916963999968, 35.254930338000065],
            [-81.207339406999949, 35.254672841000058],
            [-81.207102928999973, 35.254636975000039],
            [-81.206865349999987, 35.254606396000042],
            [-81.206626845999949, 35.254581126000062],
            [-81.206387590999952, 35.254561186000046],
            [-81.206147763999979, 35.254546589000029],
            [-81.205907542999967, 35.254537346000063],
            [-81.204335812999943, 35.254494418000036],
            [-81.204088781999985, 35.254490502000067],
            [-81.20360156199996, 35.254499376000069],
            [-81.203115677999961, 35.254530250000073],
            [-81.202632604999962, 35.254583031000038],
            [-81.202153808999981, 35.254657558000076],
            [-81.201680743999987, 35.254753606000065],
            [-81.201216949999946, 35.25487030000005],
            [-81.199830184999939, 35.255253647000075],
            [-81.199601944999984, 35.255319631000077],
            [-81.199375988999975, 35.255390713000054],
            [-81.19915248999996, 35.255466839000064],
            [-81.198931612999957, 35.255547952000029],
            [-81.19871352399997, 35.255633992000071],
            [-81.197246364999955, 35.256233210000062],
            [-81.197021280999934, 35.256328461000066],
            [-81.196682925999937, 35.256484638000074],
            [-81.195401868999966, 35.257106691000047],
            [-81.194420421999951, 35.257555940000032],
            [-81.194109218999984, 35.257591706000028],
            [-81.191750224999964, 35.257580716000064],
            [-81.18603995999996, 35.257404614000052],
            [-81.185776117999978, 35.257399702000043],
            [-81.185512213999971, 35.257401251000033],
            [-81.185248483999942, 35.257409256000074],
            [-81.184985162999965, 35.257423712000048],
            [-81.184722483999963, 35.257444607000025],
            [-81.184460682999941, 35.257471920000057],
            [-81.180634904999977, 35.25791866600008],
            [-81.180432917999951, 35.257922306000069],
            [-81.180305690999944, 35.257884778000061],
            [-81.178611654999941, 35.257325054000034],
            [-81.178225613999984, 35.257206003000078],
            [-81.177761303999944, 35.257084531000032],
            [-81.177289541999983, 35.256984221000039],
            [-81.176811760999954, 35.256905379000045],
            [-81.176726339999959, 35.25689363400005],
            [-81.174362014999986, 35.256578288000071],
            [-81.174117513999988, 35.256548545000044],
            [-81.173872096999958, 35.256524419000073],
            [-81.173625951999952, 35.256505926000045],
            [-81.173379272999966, 35.256493083000066],
            [-81.172156375999975, 35.256443471000068],
            [-81.17176965799996, 35.256434728000045],
            [-81.17128242199999, 35.256443472000058],
            [-81.17079651499995, 35.256474217000061],
            [-81.17031340799997, 35.256526868000037],
            [-81.169834571999957, 35.256601268000054],
            [-81.169612614999949, 35.256643444000076],
            [-81.168050388999973, 35.256957726000053],
            [-81.16781350499997, 35.257008240000062],
            [-81.16757841499998, 35.25706411200008],
            [-81.167345299999965, 35.257125297000073],
            [-81.167114337999976, 35.257191750000061],
            [-81.16688570599996, 35.257263421000061],
            [-81.166659579999987, 35.257340253000052],
            [-81.166436131999944, 35.257422188000078],
            [-81.165050181999959, 35.257949380000071],
            [-81.164610238999956, 35.258129287000031],
            [-81.164186775999951, 35.258327165000026],
            [-81.163777243999959, 35.258543875000043],
            [-81.163382884999976, 35.258778760000041],
            [-81.163004898999986, 35.259031105000076],
            [-81.162756393999985, 35.259213362000025],
            [-81.162045034999949, 35.259755204000044],
            [-81.160906114999989, 35.260591866000027],
            [-81.160767310999972, 35.260696033000045],
            [-81.157522587999949, 35.263183495000078],
            [-81.154363011999976, 35.265597781000054],
            [-81.154261878999989, 35.265676284000051],
            [-81.154003642999953, 35.26588851200006],
            [-81.151220646999946, 35.26827215000003],
            [-81.149811942999975, 35.269328094000059],
            [-81.148765825999988, 35.269927277000079],
            [-81.146187530999953, 35.271381287000054],
            [-81.145321087999946, 35.271828790000029],
            [-81.140735728999971, 35.271616558000062],
            [-81.135371094999982, 35.271223436000071],
            [-81.135138702999939, 35.271208929000068],
            [-81.129433835999976, 35.270914602000062],
            [-81.128494015999934, 35.270777573000032],
            [-81.127732820999938, 35.27055008800005],
            [-81.124854037999967, 35.269625208000036],
            [-81.121960232999982, 35.268565975000058],
            [-81.120678613999985, 35.268068214000039],
            [-81.120052229999942, 35.267728563000048],
            [-81.119999534999977, 35.267700211000033],
            [-81.116233015999967, 35.265689202000033],
            [-81.112370982999948, 35.263468534000026],
            [-81.111999739999987, 35.26326630300008],
            [-81.108814192999944, 35.261624266000069],
            [-81.108399768999959, 35.261350152000034],
            [-81.108253694999974, 35.261189734000027],
            [-81.107930407999959, 35.260809734000077],
            [-81.107876192999981, 35.260725329000024],
            [-81.107803044999969, 35.260480058000041],
            [-81.10741078999996, 35.258824706000041],
            [-81.107132548999971, 35.256998253000063],
            [-81.107095576999939, 35.256782047000058],
            [-81.107050688999948, 35.256566852000049],
            [-81.106997924999973, 35.256352862000028],
            [-81.106937335999987, 35.256140271000049],
            [-81.106460543999958, 35.25457404000008],
            [-81.106390236999971, 35.25435738200008],
            [-81.106311800999947, 35.254142610000031],
            [-81.106225310999946, 35.253929928000048],
            [-81.106130849999943, 35.253719541000066],
            [-81.106028508999941, 35.253511649000075],
            [-81.105918383999949, 35.253306450000025],
            [-81.105800580999983, 35.253104141000051],
            [-81.10506931599997, 35.251896627000065],
            [-81.104837970999938, 35.251540340000076],
            [-81.104585418999989, 35.251198463000037],
            [-81.10431029199998, 35.250868517000072],
            [-81.104013427999973, 35.250551503000054],
            [-81.10369572899998, 35.250248386000067],
            [-81.103358157999935, 35.249960084000065],
            [-81.103270778999956, 35.249890427000025],
            [-81.102202122999984, 35.24905016200006],
            [-81.102029830999982, 35.248918257000071],
            [-81.101853314999971, 35.248790166000049],
            [-81.101672700999984, 35.248665980000055],
            [-81.101488117999963, 35.248545784000044],
            [-81.101299694999966, 35.248429667000039],
            [-81.101107568999964, 35.248317710000038],
            [-81.099513879999961, 35.247414969000033],
            [-81.099168193999958, 35.247228790000065],
            [-81.098898747999954, 35.247096351000039],
            [-81.094218014999967, 35.244887231000064],
            [-81.094175004999954, 35.244867063000072],
            [-81.089732706999939, 35.242797285000051],
            [-81.089426878999973, 35.242641614000036],
            [-81.089349787999936, 35.242567445000077],
            [-81.089165327999979, 35.242252298000039],
            [-81.088795872999981, 35.24153848800006],
            [-81.088326986999959, 35.240460599000073],
            [-81.08824621499997, 35.240282335000074],
            [-81.088159775999941, 35.240105867000068],
            [-81.088067727999942, 35.239931321000029],
            [-81.087970133999988, 35.239758813000037],
            [-81.087513972999943, 35.238979415000074],
            [-81.087057820999974, 35.238200016000064],
            [-81.08693458099998, 35.237997593000046],
            [-81.086803705999955, 35.237798423000072],
            [-81.086665323999966, 35.237602698000046],
            [-81.086519568999961, 35.237410609000051],
            [-81.085796998999967, 35.236491125000043],
            [-81.085665199999937, 35.236328267000033],
            [-81.085528099999976, 35.236168382000074],
            [-81.085385798999937, 35.236011585000028],
            [-81.08523840099997, 35.235857992000035],
            [-81.085086012999966, 35.235707713000068],
            [-81.084928743999967, 35.235560858000042],
            [-81.080910013999983, 35.231908434000047],
            [-81.080891347999966, 35.23189152100008],
            [-81.080636757999969, 35.231671037000069],
            [-81.079063726999948, 35.230364593000047],
            [-81.077935488999969, 35.229408589000059],
            [-81.077790858999947, 35.229288851000035],
            [-81.077434617999984, 35.229016164000029],
            [-81.077060612999958, 35.22875999200005],
            [-81.076669979999963, 35.228521114000046],
            [-81.076263903999973, 35.228300254000033],
            [-81.075843619999944, 35.228098083000077],
            [-81.075410402999978, 35.227915216000042],
            [-81.075371821999966, 35.227900111000054],
            [-81.074490863999984, 35.22755737500006],
            [-81.074084613999958, 35.22740946600004],
            [-81.073629515999983, 35.227266806000046],
            [-81.073165532999951, 35.227144932000044],
            [-81.072694073999969, 35.227044214000045],
            [-81.072216569999966, 35.226964959000043],
            [-81.071734469999967, 35.226907405000077],
            [-81.071249239999986, 35.226871730000028],
            [-81.071197156999972, 35.226869212000054],
            [-81.070061951999946, 35.226817075000042],
            [-81.069826904999957, 35.226808851000044],
            [-81.069591675999959, 35.226805761000037],
            [-81.069356428999981, 35.226807809000036],
            [-81.069121331999952, 35.226814993000062],
            [-81.068886550999935, 35.226827306000075],
            [-81.068652252999982, 35.226844742000026],
            [-81.067605809999975, 35.22693434200005],
            [-81.067337943999974, 35.226960673000065],
            [-81.06707118099996, 35.226993697000069],
            [-81.066805769999974, 35.227033386000073],
            [-81.066541958999949, 35.227079700000047],
            [-81.066279992999966, 35.227132597000036],
            [-81.066020114999958, 35.227192028000047],
            [-81.065066531999946, 35.22742302000006],
            [-81.064755583999954, 35.227503485000057],
            [-81.064298190999978, 35.227641118000065],
            [-81.063850731999935, 35.227799216000051],
            [-81.063414562999981, 35.227977297000052],
            [-81.062991011999941, 35.228174822000028],
            [-81.062581361999946, 35.228391191000071],
            [-81.062186856999972, 35.22862574800007],
            [-81.062168094999947, 35.228637595000066],
            [-81.060193589999983, 35.229887917000042],
            [-81.059964720999972, 35.230037869000057],
            [-81.057906573999958, 35.231432715000039],
            [-81.057840213999953, 35.231478135000032],
            [-81.053382150999937, 35.234559794000063],
            [-81.053317960999948, 35.234604594000075],
            [-81.053295264999974, 35.234620642000039],
            [-81.050549650999983, 35.236568672000033],
            [-81.050383536999959, 35.236640372000068],
            [-81.050356293999982, 35.236638157000073],
            [-81.047461394999971, 35.236358111000072],
            [-81.039338043999976, 35.235306537000042],
            [-81.035345898999935, 35.234774168000058],
            [-81.034743601999935, 35.234661299000038],
            [-81.03415589399998, 35.234398770000041],
            [-81.032320952999953, 35.233550253000033],
            [-81.030498110999986, 35.232681314000047],
            [-81.027735261999965, 35.231236083000056],
            [-81.027528731999951, 35.231131289000075],
            [-81.027318771999944, 35.231031188000031],
            [-81.027105541999958, 35.230935856000031],
            [-81.02688920099996, 35.23084536500005],
            [-81.026669912999978, 35.230759783000053],
            [-81.023940398999969, 35.22973195000003],
            [-81.023706025999957, 35.229647041000078],
            [-81.023468753999964, 35.22956774000005],
            [-81.023228780999943, 35.229494113000044],
            [-81.022986308999975, 35.229426223000075],
            [-81.02096679999994, 35.228887430000043],
            [-81.020741571999963, 35.228830052000035],
            [-81.020514558999935, 35.228777624000031],
            [-81.02028592299996, 35.228730182000049],
            [-81.020055825999975, 35.22868776100006],
            [-81.01982443199995, 35.228650390000041],
            [-81.01959190599996, 35.228618098000027],
            [-81.018104419999986, 35.228428217000044],
            [-81.017849576999936, 35.228398796000079],
            [-81.01759379899994, 35.228375471000049],
            [-81.017337301999987, 35.228358266000043],
            [-81.017080301999954, 35.228347194000037],
            [-81.016823018999958, 35.228342263000059],
            [-81.016565670999967, 35.228343479000046],
            [-81.015514982999946, 35.228360990000056],
            [-81.013416283999959, 35.228329731000031],
            [-81.01332006399997, 35.228328727000076],
            [-81.012828537999951, 35.228337009000029],
            [-81.009774342999947, 35.228459463000036],
            [-81.009642946999975, 35.228465534000065],
            [-81.008594376999952, 35.22852040600003],
            [-81.007682155999987, 35.228405778000024],
            [-81.006969389999938, 35.228294951000066],
            [-81.006231497999977, 35.228059839000025],
            [-81.005967561999967, 35.227979683000058],
            [-81.003154331999951, 35.227166821000026],
            [-81.002845863999937, 35.227082955000071],
            [-80.999949430999948, 35.226343625000027],
            [-80.999714937999954, 35.226286673000061],
            [-80.999478615999976, 35.226235065000026],
            [-80.99924064399994, 35.226188838000041],
            [-80.999001205999946, 35.226148031000037],
            [-80.994151627999941, 35.225378672000033],
            [-80.982644741999934, 35.223430732000054],
            [-80.982379273999982, 35.223389239000028],
            [-80.982027590999962, 35.22334479400007],
            [-80.978617085999986, 35.222971222000069],
            [-80.976343433999943, 35.222722120000071],
            [-80.976249152999969, 35.222712212000033],
            [-80.973801153999943, 35.222465911000029],
            [-80.973765104999984, 35.222462346000043],
            [-80.973279943999955, 35.222426278000057],
            [-80.972793098999944, 35.222412193000025],
            [-80.972306046999961, 35.22242013500005],
            [-80.972148373999971, 35.222427435000043],
            [-80.970232129999943, 35.222530231000064],
            [-80.969982689999938, 35.222546522000073],
            [-80.96973391299997, 35.222568600000045],
            [-80.969485995999946, 35.222596447000058],
            [-80.969239137999978, 35.22263004000007],
            [-80.967066526999986, 35.22295166400005],
            [-80.966769554999985, 35.222999946000073],
            [-80.966518277999967, 35.223047639000072],
            [-80.963827728999945, 35.223592327000063],
            [-80.96360584599995, 35.223639756000068],
            [-80.96317868999995, 35.223745515000076],
            [-80.960925731999964, 35.224354602000062],
            [-80.960695707999946, 35.22441967900005],
            [-80.960467953999967, 35.224489926000047],
            [-80.960242641999969, 35.224565288000065],
            [-80.960019942999963, 35.224645711000051],
            [-80.959800023999946, 35.224731131000055],
            [-80.95958305399995, 35.224821485000064],
            [-80.957511283999963, 35.22571384400004],
            [-80.957473482999944, 35.225730222000038],
            [-80.957083426999986, 35.225910739000028],
            [-80.956433451999942, 35.226229612000054],
            [-80.95616714199997, 35.225997451000069],
            [-80.95598296899999, 35.225841633000073],
            [-80.955793197999981, 35.225690406000069],
            [-80.955597997999973, 35.225543906000041],
            [-80.955397544999983, 35.225402264000024],
            [-80.955192016999945, 35.22526560600005],
            [-80.953424143999939, 35.224125625000056],
            [-80.95262138399994, 35.223550039000031],
            [-80.952289257999951, 35.223323398000048],
            [-80.95189901699996, 35.223084117000042],
            [-80.951493306999964, 35.222862838000026],
            [-80.951073360999942, 35.222660234000045],
            [-80.95076734099996, 35.222528096000076],
            [-80.949093779999941, 35.221839398000043],
            [-80.948882787999935, 35.221755424000037],
            [-80.948669155999937, 35.221676072000037],
            [-80.948453034999943, 35.221601398000075],
            [-80.947222249999982, 35.221191812000029],
            [-80.946995458999936, 35.221119305000059],
            [-80.946766321999974, 35.221051948000024],
            [-80.94653501199997, 35.220989794000047],
            [-80.946301705999986, 35.220932889000039],
            [-80.946066577999943, 35.220881277000046],
            [-80.945829808999974, 35.220834994000029],
            [-80.94559157599997, 35.220794079000029],
            [-80.939944123999965, 35.219890423000038],
            [-80.931943108999974, 35.218541814000048],
            [-80.929588902999967, 35.218098564000059],
            [-80.929325889999973, 35.218052486000033],
            [-80.929061291999972, 35.218012995000038],
            [-80.928795350999962, 35.217980126000043],
            [-80.928528314999937, 35.217953912000041],
            [-80.928260430999956, 35.217934376000073],
            [-80.927991947999942, 35.217921537000052],
            [-80.925198914999953, 35.217822891000026],
            [-80.924890887999936, 35.217816420000077],
            [-80.924647307999976, 35.217817541000045],
            [-80.922903777999977, 35.21784527300008],
            [-80.922640026999943, 35.217852700000037],
            [-80.922376663999955, 35.217866584000035],
            [-80.922113922999984, 35.21788691200004],
            [-80.921852039999976, 35.21791366900004],
            [-80.919677680999939, 35.218162900000038],
            [-80.919416989999945, 35.218196040000066],
            [-80.919157620999954, 35.218235549000042],
            [-80.918899801999942, 35.218281391000062],
            [-80.918643764999956, 35.218333525000048],
            [-80.918389738999963, 35.218391906000079],
            [-80.918137946999934, 35.218456481000032],
            [-80.917601306999984, 35.218601354000043],
            [-80.916912331999981, 35.218310853000048],
            [-80.915052202999959, 35.217521719000047],
            [-80.913876443999982, 35.216944239000043],
            [-80.913683597999977, 35.216852212000049],
            [-80.913487983999971, 35.216764208000029],
            [-80.91328972599996, 35.216680283000073],
            [-80.913088951999953, 35.216600490000076],
            [-80.912885786999936, 35.216524880000065],
            [-80.911742433999962, 35.21611355400006],
            [-80.911587660999942, 35.216059308000069],
            [-80.911132911999971, 35.215916036000067],
            [-80.910669237999969, 35.21579353900006],
            [-80.910198044999959, 35.215692188000048],
            [-80.909720766999953, 35.21561229100007],
            [-80.909478559999968, 35.215580256000067],
            [-80.90842911499999, 35.215453686000046],
            [-80.908186566999973, 35.215427240000054],
            [-80.907943218999947, 35.215406314000063],
            [-80.907699259999958, 35.215390923000029],
            [-80.907454873999939, 35.215381081000032],
            [-80.907210249999935, 35.215376792000029],
            [-80.900927318999948, 35.215342078000049],
            [-80.898407074999966, 35.215242760000024],
            [-80.89817089099995, 35.21523604600003],
            [-80.889533994999965, 35.215085018000025],
            [-80.889483246999987, 35.215084248000039],
            [-80.871862499999963, 35.214857272000074],
            [-80.871799909999936, 35.214856643000076],
            [-80.871649367999964, 35.214856621000024],
            [-80.86336616799997, 35.214912952000077],
            [-80.863111231999937, 35.214917696000043],
            [-80.862856568999973, 35.214928473000043],
            [-80.86260238899996, 35.21494527200008],
            [-80.862348905999966, 35.214968081000052],
            [-80.860695858999975, 35.215136657000073],
            [-80.860428615999979, 35.215167315000031],
            [-80.860162638999952, 35.215204651000079],
            [-80.859898173999966, 35.215248633000044],
            [-80.859635466999976, 35.21529921900003],
            [-80.859374763999938, 35.21535636200008],
            [-80.859116307999955, 35.215420008000024],
            [-80.85677562799998, 35.216028496000035],
            [-80.856687769999951, 35.216051750000076],
            [-80.855561003999981, 35.216355308000061],
            [-80.855522091999944, 35.216365873000029],
            [-80.855064409999954, 35.216502715000047],
            [-80.854616605999979, 35.216660039000033],
            [-80.85418004099995, 35.21683736600005],
            [-80.85375604099994, 35.217034158000047],
            [-80.853345892999982, 35.217249819000074],
            [-80.852950839999949, 35.217483693000077],
            [-80.852572085999952, 35.217735069000071],
            [-80.852483187999951, 35.217798318000064],
            [-80.851367763999974, 35.218602626000063],
            [-80.851192881999964, 35.21873215800008],
            [-80.851022265999973, 35.218865468000047],
            [-80.85085603999994, 35.219002462000049],
            [-80.850694319999945, 35.219143041000052],
            [-80.850537222999947, 35.21928710800006],
            [-80.850384859999963, 35.219434558000046],
            [-80.849214159999974, 35.220598585000062],
            [-80.847918110999956, 35.221592239000074],
            [-80.847736918999942, 35.221735164000052],
            [-80.847560750999946, 35.221882258000051],
            [-80.847389749999934, 35.222033403000069],
            [-80.847224052999934, 35.222188476000042],
            [-80.845407551999983, 35.223938014000055],
            [-80.84084816699999, 35.227899609000076],
            [-80.84082547099996, 35.227919402000055],
            [-80.840502213999969, 35.228218476000052],
            [-80.840199525999935, 35.228531707000059],
            [-80.839918322999949, 35.228858145000061],
            [-80.83965945999995, 35.229196796000053],
            [-80.83942372599995, 35.22954663400003],
            [-80.839211833999968, 35.229906596000035],
            [-80.839024429999938, 35.23027558800004],
            [-80.838862080999945, 35.230652490000068],
            [-80.838725281999984, 35.231036158000052],
            [-80.838614449999966, 35.231425427000033],
            [-80.838529918999939, 35.231819114000075],
            [-80.838471947999949, 35.232216024000024],
            [-80.838440713999944, 35.232614951000073],
            [-80.838436309999963, 35.233014684000068],
            [-80.838458751999951, 35.233414010000047],
            [-80.838507970999956, 35.233811714000069],
            [-80.838583817999961, 35.234206590000042],
            [-80.838686062999955, 35.234597438000037],
            [-80.838814395999975, 35.234983072000034],
            [-80.838968427999987, 35.235362319000046],
            [-80.839147691999983, 35.235734028000024],
            [-80.839351642999986, 35.236097070000028],
            [-80.83957965999997, 35.236450342000069],
            [-80.839831053999944, 35.236792772000058],
            [-80.840105060999974, 35.237123319000034],
            [-80.840400847999945, 35.237440980000031],
            [-80.840717515999984, 35.237744788000043],
            [-80.841054104999955, 35.238033822000034],
            [-80.84140959299998, 35.23830720400008],
            [-80.841782899999941, 35.238564103000044],
            [-80.842172890999962, 35.238803738000058],
            [-80.842578382999989, 35.239025383000069],
            [-80.842998142999988, 35.239228364000041],
            [-80.843430896999962, 35.239412062000042],
            [-80.843875331999982, 35.239575923000075],
            [-80.844330093999986, 35.239719447000027],
            [-80.844793804999938, 35.239842198000076],
            [-80.84526505599996, 35.239943803000074],
            [-80.845742413999972, 35.240023954000037],
            [-80.84622442999995, 35.240082408000035],
            [-80.846709639999972, 35.240118986000027],
            [-80.847196567999958, 35.240133578000041],
            [-80.847683737999944, 35.24012613900004],
            [-80.848169666999979, 35.240096691000076],
            [-80.848652881999953, 35.240045326000029],
            [-80.849131911999962, 35.239972197000043],
            [-80.849605303999965, 35.239877528000079],
            [-80.850071618999948, 35.239761606000059],
            [-80.850529440999935, 35.239624784000057],
            [-80.850977377999982, 35.239467476000073],
            [-80.851414071999955, 35.239290161000042],
            [-80.851838193999981, 35.239093377000074],
            [-80.852248455999984, 35.23887772300003],
            [-80.852643613999987, 35.238643852000052],
            [-80.853022464999981, 35.238392477000048],
            [-80.853383859999951, 35.23812435800005],
            [-80.853704006999976, 35.237860108000064],
            [-80.858418473999961, 35.233763199000066],
            [-80.858441166999967, 35.233743403000062],
            [-80.858720173999984, 35.233487198000034],
            [-80.86035680699996, 35.231910661000029],
            [-80.861703516999967, 35.230878038000071],
            [-80.861868583999978, 35.230748144000074],
            [-80.862029498999959, 35.230614790000061],
            [-80.86218615699994, 35.230478065000057],
            [-80.862338449999982, 35.230338059000076],
            [-80.862486277999949, 35.230194869000059],
            [-80.86307527799994, 35.229609143000062],
            [-80.863681815999939, 35.229451463000032],
            [-80.864022611999985, 35.229416708000031],
            [-80.871690007999973, 35.229364553000039],
            [-80.889181973999939, 35.229589863000058],
            [-80.897676814999954, 35.229738410000039],
            [-80.900261427999965, 35.229840262000039],
            [-80.900618287999976, 35.229848396000079],
            [-80.905782922999947, 35.229876964000027],
            [-80.906208066999966, 35.23008578300005],
            [-80.906476288999954, 35.230212348000066],
            [-80.906688175999989, 35.23030526000008],
            [-80.908802535999939, 35.231202288000077],
            [-80.908822034999957, 35.231210535000059],
            [-80.909946124999976, 35.23168451600003],
            [-80.910546172999943, 35.231940897000072],
            [-80.911010557999987, 35.232184454000048],
            [-80.911079953999945, 35.232220477000055],
            [-80.911499848999938, 35.232423220000044],
            [-80.91193272199996, 35.232606674000067],
            [-80.912377255999957, 35.232770283000036],
            [-80.912832101999982, 35.232913550000035],
            [-80.913295876999939, 35.23303603800008],
            [-80.91376717299994, 35.233137377000048],
            [-80.91424455899994, 35.233217258000025],
            [-80.914726583999936, 35.233275439000067],
            [-80.915211783999951, 35.233311742000069],
            [-80.91547749199998, 35.233322287000078],
            [-80.91713584699994, 35.233367585000053],
            [-80.917357038999967, 35.233371353000052],
            [-80.917844161999938, 35.233363632000078],
            [-80.918330024999989, 35.233333902000027],
            [-80.918813154999953, 35.233282257000042],
            [-80.918849009999974, 35.233277534000024],
            [-80.920066340999938, 35.233115085000065],
            [-80.920338910999988, 35.233075105000069],
            [-80.920609820999971, 35.233028141000034],
            [-80.920878804999973, 35.232974238000054],
            [-80.921145599999988, 35.232913451000059],
            [-80.921409942999958, 35.23284583800006],
            [-80.922909507999975, 35.232441021000056],
            [-80.923774710999965, 35.232341848000033],
            [-80.924714137999956, 35.232326905000036],
            [-80.926423428999954, 35.232387278000033],
            [-80.92808791799996, 35.232700664000049],
            [-80.928285717999984, 35.232735952000041],
            [-80.936430319999943, 35.23410877200007],
            [-80.936516473999973, 35.234122926000055],
            [-80.941369048999945, 35.234899415000029],
            [-80.941481776999979, 35.23493693000006],
            [-80.941834479999955, 35.235082080000041],
            [-80.941962906999947, 35.23517417100004],
            [-80.942295052999953, 35.235400840000068],
            [-80.942317380999953, 35.23541528700008],
            [-80.943745409999963, 35.236336212000026],
            [-80.944983865999973, 35.237415972000065],
            [-80.945207245999939, 35.237603798000066],
            [-80.945563081999978, 35.237876874000051],
            [-80.945936714999959, 35.238133452000056],
            [-80.946327009999948, 35.238372751000043],
            [-80.946557415999962, 35.238501645000042],
            [-80.947971410999969, 35.239266130000033],
            [-80.948146781999981, 35.239358529000071],
            [-80.948566806999963, 35.23956114300006],
            [-80.948999800999957, 35.239744464000069],
            [-80.949444448999941, 35.239907937000055],
            [-80.949825111999985, 35.240029294000067],
            [-80.950967279999986, 35.24036871900006],
            [-80.951041567999937, 35.240390489000049],
            [-80.951505443999963, 35.240512830000057],
            [-80.951976830999968, 35.240614020000066],
            [-80.952454297999964, 35.240693750000048],
            [-80.952495105999958, 35.24069952800005],
            [-80.95433318399995, 35.240956121000067],
            [-80.954607306999947, 35.240990765000049],
            [-80.954882619999978, 35.241018337000071],
            [-80.955158851999954, 35.241038812000056],
            [-80.955435730999966, 35.241052167000078],
            [-80.955712984999934, 35.24105839200007],
            [-80.955990338999982, 35.241057478000073],
            [-80.957734354999957, 35.24102927000007],
            [-80.957977871999958, 35.241022574000056],
            [-80.958463765999966, 35.240992684000048],
            [-80.958946915999945, 35.24094087800006],
            [-80.959425851999981, 35.240867313000024],
            [-80.959899120999978, 35.240772212000024],
            [-80.960365282999987, 35.240655866000054],
            [-80.960388270999943, 35.240649527000073],
            [-80.962034733999985, 35.24019343100008],
            [-80.962288600999955, 35.240119550000031],
            [-80.962539607999986, 35.240039358000047],
            [-80.962787519999949, 35.239952930000072],
            [-80.963032106999947, 35.239860346000057],
            [-80.963273141999935, 35.239761692000059],
            [-80.963510402999987, 35.239657059000024],
            [-80.96374366699996, 35.239546545000053],
            [-80.965946971999983, 35.238465595000037],
            [-80.967153832999941, 35.237945754000066],
            [-80.968391151999981, 35.237611237000067],
            [-80.970481670999959, 35.23718801800004],
            [-80.971885501999964, 35.236980201000051],
            [-80.972473307999962, 35.236948670000061],
            [-80.974050261999935, 35.237107327000047],
            [-80.976277151999966, 35.23735130600005],
            [-80.979377757999941, 35.237690937000025],
            [-80.990633402999947, 35.239596338000069],
            [-80.990740767999966, 35.239613940000027],
            [-80.995164133999936, 35.240315697000028],
            [-80.997437126999955, 35.240895895000051],
            [-80.999961481999946, 35.241625303000035],
            [-81.001324969999985, 35.242059750000067],
            [-81.001577957999984, 35.242136734000042],
            [-81.00183369299998, 35.242207349000068],
            [-81.002091934999953, 35.242271527000071],
            [-81.002352443999939, 35.242329211000026],
            [-81.00261497799994, 35.242380346000061],
            [-81.002879290999942, 35.242424885000048],
            [-81.004543162999937, 35.242683602000056],
            [-81.004670095999984, 35.242702553000072],
            [-81.004850835999946, 35.242726840000046],
            [-81.006863528999986, 35.242979745000071],
            [-81.007164934999935, 35.243013273000031],
            [-81.007650233999982, 35.243049205000034],
            [-81.008137207999937, 35.243063150000069],
            [-81.008624380999947, 35.243055063000043],
            [-81.00876468599995, 35.24304864000004],
            [-81.010702837999986, 35.24294722500008],
            [-81.013391449999972, 35.242839429000071],
            [-81.015364161999969, 35.242868808000026],
            [-81.015460398999949, 35.242869811000048],
            [-81.015704052999979, 35.242868506000036],
            [-81.016150839999966, 35.242861062000031],
            [-81.016172874999938, 35.242863875000069],
            [-81.017030371999965, 35.24309265200003],
            [-81.018741225999975, 35.243736917000035],
            [-81.021145209999986, 35.244994491000057],
            [-81.021214662999967, 35.245030450000058],
            [-81.02145643099999, 35.245149985000069],
            [-81.023487011999975, 35.24611800100007],
            [-81.023587392999957, 35.246165135000069],
            [-81.025529038999935, 35.247063037000032],
            [-81.025607131999948, 35.247098724000068],
            [-81.025641664999966, 35.247114236000073],
            [-81.026876796999943, 35.24766610100005],
            [-81.027114739999945, 35.24776859800005],
            [-81.027356370999939, 35.247865115000025],
            [-81.027601465999965, 35.247955563000062],
            [-81.027849798999966, 35.248039859000073],
            [-81.028101140999979, 35.248117925000031],
            [-81.029243746999953, 35.248456610000062],
            [-81.029499164999947, 35.248528720000024],
            [-81.029757149999966, 35.248594395000055],
            [-81.030017459999954, 35.248653575000048],
            [-81.030279852999968, 35.24870620400003],
            [-81.031682314999955, 35.248968937000029],
            [-81.032133691999945, 35.249043407000045],
            [-81.032231841999987, 35.249056963000044],
            [-81.036521135999976, 35.249628964000067],
            [-81.036561089999964, 35.249634215000071],
            [-81.044876434999935, 35.250710639000033],
            [-81.045218641999952, 35.250749322000047],
            [-81.048368136999954, 35.251053998000032],
            [-81.048530497999934, 35.251068442000076],
            [-81.050716149999971, 35.251246126000069],
            [-81.051040108999985, 35.251267535000068],
            [-81.051527138999973, 35.251281305000077],
            [-81.052014356999962, 35.251273044000072],
            [-81.052500276999979, 35.251242776000026],
            [-81.052983427999948, 35.251190594000036],
            [-81.053113034999967, 35.251172792000034],
            [-81.054243153999948, 35.251010473000065],
            [-81.054592456999956, 35.250954334000028],
            [-81.055065672999945, 35.25085886200003],
            [-81.055531756999983, 35.250742149000075],
            [-81.055989294999961, 35.250604549000059],
            [-81.05643689499999, 35.25044648100004],
            [-81.056873198999938, 35.250268425000058],
            [-81.057171444999938, 35.250132053000073],
            [-81.057781285999965, 35.249840402000075],
            [-81.059682529999975, 35.249019730000043],
            [-81.059720324999944, 35.249003320000043],
            [-81.06014399299994, 35.248805807000053],
            [-81.060553751999976, 35.248589447000029],
            [-81.060948357999962, 35.24835489700007],
            [-81.061326609999981, 35.248102870000025],
            [-81.06134930799999, 35.248086820000026],
            [-81.064819817999989, 35.245624258000078],
            [-81.068999788999974, 35.242734566000024],
            [-81.071322220999946, 35.244845590000068],
            [-81.071355503999939, 35.244887953000045],
            [-81.071572040999968, 35.245258059000037],
            [-81.071827259999964, 35.245694276000052],
            [-81.072227332999944, 35.246614400000055],
            [-81.072254233999956, 35.246675405000076],
            [-81.07241615199996, 35.247011396000062],
            [-81.072966701999974, 35.248075504000042],
            [-81.072985136999989, 35.248110874000076],
            [-81.073134637999942, 35.248380344000054],
            [-81.073590711999941, 35.249159791000068],
            [-81.07368579599995, 35.249317369000039],
            [-81.073785509999936, 35.249473009000042],
            [-81.073889794999957, 35.249626622000051],
            [-81.074433090999946, 35.250404570000057],
            [-81.074567918999946, 35.250591107000048],
            [-81.074709483999982, 35.250774254000078],
            [-81.074857660999953, 35.250953848000051],
            [-81.075012317999949, 35.251129727000034],
            [-81.075173313999983, 35.251301736000073],
            [-81.075340507999954, 35.251469719000056],
            [-81.075513748999981, 35.25163352800007],
            [-81.075857789999986, 35.251949176000039],
            [-81.07648695599994, 35.252608132000034],
            [-81.076585595999973, 35.252709561000074],
            [-81.076903203999962, 35.25301274900005],
            [-81.077240693999954, 35.253301125000064],
            [-81.07759704099999, 35.253573811000024],
            [-81.077971160999937, 35.253829981000024],
            [-81.078361918999974, 35.254068853000035],
            [-81.078768129999958, 35.254289706000066],
            [-81.078785693999976, 35.254298671000072],
            [-81.08055371599994, 35.255198685000039],
            [-81.080848605999961, 35.255342373000076],
            [-81.085418936999986, 35.257471911000039],
            [-81.089686635999954, 35.25948620500003],
            [-81.089896512999985, 35.260865828000078],
            [-81.089923437999971, 35.261027908000074],
            [-81.089954808999948, 35.261189449000028],
            [-81.089990613999987, 35.26135036900007],
            [-81.090479986999981, 35.263417426000046],
            [-81.090570740999965, 35.263757037000062],
            [-81.09095459599996, 35.265045004000058],
            [-81.091086061999988, 35.265436684000065],
            [-81.091241325999988, 35.265815607000036],
            [-81.09142180799995, 35.266186938000033],
            [-81.091626958999939, 35.266549552000072],
            [-81.091718571999934, 35.266696248000073],
            [-81.092536704999986, 35.267970352000077],
            [-81.092668594999964, 35.268168221000053],
            [-81.092807919999984, 35.268362632000049],
            [-81.092954547999966, 35.268553397000062],
            [-81.093108335999943, 35.26874033100006],
            [-81.093828241999972, 35.269586710000056],
            [-81.093921896999973, 35.269694701000049],
            [-81.093995162999988, 35.269776363000062],
            [-81.094893227999989, 35.270762791000038],
            [-81.095116861999941, 35.270998165000037],
            [-81.095434609999984, 35.271301304000076],
            [-81.095772240999963, 35.271589627000026],
            [-81.096128729999975, 35.271862258000056],
            [-81.096427294999955, 35.272068852000075],
            [-81.098114195999983, 35.273184731000072],
            [-81.098318873999972, 35.273316097000077],
            [-81.098528168999962, 35.273442475000024],
            [-81.098741903999951, 35.273563754000065],
            [-81.098959890999936, 35.273679831000038],
            [-81.102397602999986, 35.275451969000073],
            [-81.106201725999938, 35.277639482000041],
            [-81.10645330899996, 35.277778923000028],
            [-81.110320921999971, 35.279844039000068],
            [-81.111445515999947, 35.280453874000045],
            [-81.111656852999943, 35.280564973000025],
            [-81.111871950999955, 35.280671108000035],
            [-81.112090633999969, 35.280772196000044],
            [-81.112312728999939, 35.280868154000075],
            [-81.112538054999959, 35.280958906000023],
            [-81.114477794999971, 35.281712301000027],
            [-81.114658361999943, 35.281780405000063],
            [-81.117830701999935, 35.282941625000035],
            [-81.118056735999971, 35.283021286000064],
            [-81.118209177999972, 35.283071610000036],
            [-81.121377889999962, 35.284089662000042],
            [-81.121575649999954, 35.284150971000031],
            [-81.123159105999946, 35.284624195000049],
            [-81.123400678999985, 35.284693160000074],
            [-81.123644582999987, 35.284756369000036],
            [-81.123890613999947, 35.284813770000028],
            [-81.124138566999989, 35.284865313000068],
            [-81.12438823399998, 35.284910957000079],
            [-81.124639405999972, 35.284950662000028],
            [-81.126829872999963, 35.285270043000025],
            [-81.127076708999937, 35.285303086000056],
            [-81.127324585999986, 35.285330394000027],
            [-81.127573306999977, 35.285351946000048],
            [-81.127822669999944, 35.285367722000046],
            [-81.133911980999983, 35.285681898000064],
            [-81.139305508999939, 35.286077132000059],
            [-81.139552364999986, 35.286092364000069],
            [-81.139593932999958, 35.286094369000068],
            [-81.14518708199995, 35.286353230000032],
            [-81.145632769999963, 35.286364617000061],
            [-81.145876551999947, 35.286363050000034],
            [-81.147010747999957, 35.286342939000065],
            [-81.14725438399995, 35.286335863000033],
            [-81.147688534999986, 35.286309545000051],
            [-81.148820841999964, 35.286217880000038],
            [-81.14887278499998, 35.286213548000035],
            [-81.149356080999951, 35.286160982000069],
            [-81.149835110999959, 35.286086664000038],
            [-81.150308418999941, 35.285990820000052],
            [-81.150774566999985, 35.285873741000046],
            [-81.15123214099998, 35.285735782000074],
            [-81.151679748999982, 35.285577363000073],
            [-81.15211603299997, 35.285398964000024],
            [-81.152354898999988, 35.285290539000073],
            [-81.153303233999964, 35.284844253000074],
            [-81.153487998999935, 35.284754842000041],
            [-81.153614567999966, 35.284690684000054],
            [-81.155767241999968, 35.283578814000066],
            [-81.156057804999989, 35.283422102000031],
            [-81.15881716399997, 35.281865889000073],
            [-81.15886984499997, 35.281835945000068],
            [-81.160417391999943, 35.280949492000047],
            [-81.16060538499994, 35.280838776000053],
            [-81.160789780999949, 35.280724065000072],
            [-81.160970453999937, 35.28060543600003],
            [-81.161147278999977, 35.280482972000073],
            [-81.161320134999983, 35.28035675600006],
            [-81.163373942999954, 35.278817091000064],
            [-81.163536754999939, 35.278691904000027],
            [-81.163795005999987, 35.278479655000069],
            [-81.166607024999962, 35.276070854000068],
            [-81.169592146999946, 35.273789605000047],
            [-81.172778428999948, 35.271346681000068],
            [-81.173067948999972, 35.271133977000034],
            [-81.173850697999967, 35.271392606000063],
            [-81.174162420999949, 35.271490038000024],
            [-81.175305962999971, 35.271827343000041],
            [-81.175540785999942, 35.271893568000053],
            [-81.175777782999944, 35.271954361000041],
            [-81.176016767999954, 35.272009673000071],
            [-81.176257551999981, 35.272059461000026],
            [-81.176499945999979, 35.272103686000037],
            [-81.177639677999935, 35.272297896000055],
            [-81.177934181999944, 35.272343822000039],
            [-81.178416624999954, 35.272400952000055],
            [-81.178902173999973, 35.272436197000047],
            [-81.179389351999987, 35.272449452000046],
            [-81.179633090999971, 35.272447818000046],
            [-81.181552158999978, 35.272413242000027],
            [-81.18181606099995, 35.272405250000077],
            [-81.182079555999962, 35.272390801000029],
            [-81.182342405999975, 35.272369909000076],
            [-81.18260437899994, 35.272342589000061],
            [-81.186168257999952, 35.271926445000076],
            [-81.191226877999952, 35.272082431000058],
            [-81.191508605999957, 35.272087428000077],
            [-81.194651743999941, 35.27210206500007],
            [-81.194907831999956, 35.27210019100005],
            [-81.195163746999981, 35.272092237000038],
            [-81.195419274999949, 35.272078210000075],
            [-81.195674198999939, 35.272058123000079],
            [-81.195928306999974, 35.272031990000073],
            [-81.197407347999956, 35.27186200400007],
            [-81.19768111999997, 35.271826943000065],
            [-81.19795341799994, 35.271784867000065],
            [-81.198223975999952, 35.271735816000046],
            [-81.199439384999948, 35.271499022000057],
            [-81.199755701999948, 35.271432309000033],
            [-81.200221695999971, 35.271315039000058],
            [-81.20067910399996, 35.271176894000064],
            [-81.201126535999947, 35.271018291000075],
            [-81.201232541999957, 35.270977134000077],
            [-81.202182376999986, 35.270601999000064],
            [-81.202512464999984, 35.270464577000041],
            [-81.202655425999978, 35.270400546000076],
            [-81.204120862999957, 35.269729726000037],
            [-81.204316299999959, 35.269637562000071],
            [-81.204515332999961, 35.269540913000071],
            [-81.20679854399998, 35.271421512000074],
            [-81.206865088999962, 35.271475743000053],
            [-81.206887011999981, 35.271493360000079],
            [-81.210914804999959, 35.274718488000076],
            [-81.211576795999974, 35.275341191000052],
            [-81.211704880999946, 35.275459057000035],
            [-81.212042940999936, 35.275747055000068],
            [-81.212399836999964, 35.276019344000076],
            [-81.212774483999965, 35.276275095000074],
            [-81.213165744999969, 35.276513531000035],
            [-81.213502868999967, 35.276698081000063],
            [-81.215250927999989, 35.27760820900005],
            [-81.218389183999989, 35.279351560000066],
            [-81.224216116999969, 35.28282252300005],
            [-81.224337065999975, 35.282893298000033],
            [-81.224481558999969, 35.282974619000072],
            [-81.229351446999942, 35.285657419000074],
            [-81.230298929999947, 35.286362946000054],
            [-81.231746103999967, 35.287703006000072],
            [-81.23353697899995, 35.289613688000031],
            [-81.23369422799999, 35.289776571000061],
            [-81.233802424999965, 35.289883303000067],
            [-81.235326904999965, 35.291358160000073],
            [-81.235537052999973, 35.291554199000075],
            [-81.235875263999958, 35.291842130000077],
            [-81.236232309999934, 35.292114347000052],
            [-81.236607106999941, 35.292370024000036],
            [-81.237002435999955, 35.292610633000038],
            [-81.238336763999939, 35.293375575000027],
            [-81.238552435999964, 35.293495376000067],
            [-81.238772317999974, 35.293609918000072],
            [-81.238996216999965, 35.293719103000058],
            [-81.239223940999977, 35.293822835000071],
            [-81.239455293999981, 35.293921025000031],
            [-81.239690069999938, 35.294013587000052],
            [-81.239928070999952, 35.294100442000058],
            [-81.240747084999953, 35.29438755700005]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 114,
        "SolutionID": "PI-11:B",
        "Shape_Length": 1.1750968962201838,
        "Shape_Area": 0.0087507454839618921
      }
    },
    {
      "type": "Feature",
      "id": 115,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.847444539999969, 35.540688210000042],
            [-80.846956809999938, 35.540717653000058],
            [-80.846471803999975, 35.540769008000041],
            [-80.845990994999966, 35.540842122000072],
            [-80.845515843999976, 35.540936771000077],
            [-80.845047790999956, 35.541052668000077],
            [-80.844588258999977, 35.541189461000045],
            [-80.844138643999941, 35.541346736000037],
            [-80.843700308999985, 35.541524013000071],
            [-80.84327458599995, 35.541720756000075],
            [-80.842862768999964, 35.541936366000073],
            [-80.842466105999961, 35.542170190000036],
            [-80.842085803999964, 35.542421517000037],
            [-80.841723014999957, 35.542689584000073],
            [-80.841378842999973, 35.542973578000044],
            [-80.841054333999978, 35.543272634000061],
            [-80.840750471999968, 35.543585846000042],
            [-80.84046817899997, 35.543912264000028],
            [-80.840208312999948, 35.544250895000062],
            [-80.83997166599994, 35.544600711000044],
            [-80.83975895399999, 35.544960650000064],
            [-80.839570823999964, 35.545329619000029],
            [-80.83942142899997, 35.545672392000029],
            [-80.838674481999988, 35.547532483000055],
            [-80.838385237999944, 35.548130790000073],
            [-80.835748748999947, 35.550687204000042],
            [-80.835735460999956, 35.550700119000055],
            [-80.835534653999957, 35.550903045000041],
            [-80.835442583999964, 35.550999588000025],
            [-80.835348576999934, 35.551043355000047],
            [-80.834332582999934, 35.551477867000074],
            [-80.832713653999974, 35.552170210000043],
            [-80.83251900099998, 35.552255994000063],
            [-80.832327022999948, 35.552345714000069],
            [-80.832137839999973, 35.552439314000026],
            [-80.831951568999955, 35.552536737000025],
            [-80.830855673999963, 35.553125752000028],
            [-80.83062436299997, 35.553254386000049],
            [-80.830398127999956, 35.553388913000049],
            [-80.83017719299994, 35.55352920200005],
            [-80.828911569999946, 35.554359292000072],
            [-80.828710331999957, 35.554495302000078],
            [-80.828514074999987, 35.554636091000077],
            [-80.828322970999977, 35.554781538000043],
            [-80.828137186999982, 35.554931515000078],
            [-80.827956879999988, 35.555085893000069],
            [-80.826525792999973, 35.556347814000048],
            [-80.826369068999952, 35.556489714000065],
            [-80.826216973999976, 35.556634939000048],
            [-80.826069614999938, 35.556783390000078],
            [-80.825927091999972, 35.556934962000071],
            [-80.825789506999968, 35.557089548000079],
            [-80.824366930999986, 35.558732973000076],
            [-80.824227489999942, 35.558898895000027],
            [-80.82415887999997, 35.55898430700006],
            [-80.822058365999965, 35.561639736000075],
            [-80.821881226999949, 35.561873246000061],
            [-80.821144965999963, 35.562886139000057],
            [-80.821130719999985, 35.56290580700005],
            [-80.820893935999948, 35.563255585000036],
            [-80.820681090999983, 35.563615489000028],
            [-80.820492830999967, 35.563984426000047],
            [-80.820476033999967, 35.564020361000075],
            [-80.819807734999983, 35.565461592000077],
            [-80.819713279999974, 35.565675329000044],
            [-80.819627066999942, 35.56589137900005],
            [-80.819549178999978, 35.566109531000052],
            [-80.819479693999938, 35.566329568000072],
            [-80.819270849999953, 35.567036482000049],
            [-80.819196208999983, 35.56720415500007],
            [-80.819076012999972, 35.567357480000055],
            [-80.818501190999939, 35.567786305000027],
            [-80.818355812999982, 35.567897223000045],
            [-80.818060230999947, 35.568138988000044],
            [-80.813191174999986, 35.572313426000051],
            [-80.808835383999963, 35.575908449000053],
            [-80.808719036999946, 35.576006291000056],
            [-80.803676386999939, 35.580327172000068],
            [-80.803626792999978, 35.580370013000049],
            [-80.80330199399998, 35.580668966000076],
            [-80.802997845999982, 35.580982080000069],
            [-80.802715270999954, 35.581308407000051],
            [-80.802455127999963, 35.581646953000075],
            [-80.802218208999989, 35.581996693000065],
            [-80.802005231999942, 35.582356562000029],
            [-80.801816842999983, 35.582725469000025],
            [-80.801653615999953, 35.583102293000024],
            [-80.80151604699995, 35.583485890000077],
            [-80.801404553999987, 35.583875095000053],
            [-80.801319475999946, 35.584268727000051],
            [-80.801261070999942, 35.584665589000053],
            [-80.801229517999957, 35.58506447600007],
            [-80.801224910999963, 35.585464178000052],
            [-80.801247266999951, 35.585863480000057],
            [-80.801296516999969, 35.586261170000057],
            [-80.80137251299999, 35.586656039000047],
            [-80.801475022999966, 35.587046889000078],
            [-80.801603736999937, 35.587432533000026],
            [-80.801758264999989, 35.587811799000065],
            [-80.801938136999979, 35.588183535000042],
            [-80.802142806999939, 35.588546612000073],
            [-80.802371653999955, 35.588899928000046],
            [-80.802623982999989, 35.589242409000065],
            [-80.802899028999946, 35.589573015000042],
            [-80.803195955999968, 35.589890743000069],
            [-80.803513860999942, 35.590194625000038],
            [-80.803851780999935, 35.590483741000071],
            [-80.80420868799996, 35.590757211000039],
            [-80.804583499999978, 35.591014206000068],
            [-80.804975075999948, 35.591253943000027],
            [-80.805382228999974, 35.591475695000042],
            [-80.805803720999961, 35.591678789000071],
            [-80.806238270999984, 35.591862607000053],
            [-80.806684561999987, 35.592026592000025],
            [-80.80714123599995, 35.592170245000034],
            [-80.80760690599999, 35.592293128000051],
            [-80.808080157999939, 35.592394871000067],
            [-80.808559553999942, 35.592475163000074],
            [-80.809043637999935, 35.592533760000038],
            [-80.809530939999945, 35.592570484000078],
            [-80.810019977999957, 35.59258522500005],
            [-80.810509269999955, 35.592577936000055],
            [-80.81099732399997, 35.592548640000075],
            [-80.811482662999936, 35.592497427000069],
            [-80.811963808999963, 35.592424451000056],
            [-80.812439300999984, 35.592329935000066],
            [-80.812907695999968, 35.59221416500003],
            [-80.813367570999958, 35.592077493000033],
            [-80.813817526999969, 35.591920335000054],
            [-80.814256199999988, 35.591743167000061],
            [-80.814682255999969, 35.591546529000027],
            [-80.815094399999964, 35.591331017000073],
            [-80.815491382999937, 35.591097285000046],
            [-80.815871994999952, 35.590846045000035],
            [-80.81623508499996, 35.590578058000062],
            [-80.816529952999986, 35.590336985000079],
            [-80.821514949999937, 35.586064940000028],
            [-80.825871117999952, 35.58246914700004],
            [-80.825988252999935, 35.582370603000072],
            [-80.830702264999957, 35.578328569000064],
            [-80.831910003999951, 35.577427471000078],
            [-80.832055380999975, 35.577316536000069],
            [-80.832399730999953, 35.577032572000064],
            [-80.83272440799999, 35.576733541000067],
            [-80.833028424999952, 35.576420352000071],
            [-80.833310860999973, 35.576093954000044],
            [-80.833399569999983, 35.575983015000077],
            [-80.834194171999968, 35.574969171000077],
            [-80.83427291299995, 35.574866833000044],
            [-80.834783176999963, 35.574191227000028],
            [-80.834875716999989, 35.574065888000064],
            [-80.835112472999981, 35.573716084000068],
            [-80.83532527999995, 35.573356156000045],
            [-80.835513494999987, 35.572987196000042],
            [-80.835571466999966, 35.572860598000034],
            [-80.836127122999983, 35.571611812000071],
            [-80.836208763999934, 35.571420009000065],
            [-80.836283800999979, 35.571226413000034],
            [-80.836352172999966, 35.571031177000066],
            [-80.83641382899998, 35.570834453000032],
            [-80.836608607999949, 35.570174681000026],
            [-80.836805480999942, 35.569749924000064],
            [-80.837060909999934, 35.569398432000071],
            [-80.838969077999934, 35.566985609000028],
            [-80.839946117999943, 35.565856661000055],
            [-80.840534511999977, 35.565337747000058],
            [-80.840962498999943, 35.565057017000072],
            [-80.841337632999966, 35.56485538000004],
            [-80.842564488999983, 35.564330685000073],
            [-80.843720985999937, 35.563836062000064],
            [-80.843758967999975, 35.563819721000073],
            [-80.843999063999945, 35.563711911000041],
            [-80.845270102999962, 35.563120121000054],
            [-80.845490607999977, 35.563013983000076],
            [-80.845707275999985, 35.562902696000037],
            [-80.845919927999944, 35.562786350000067],
            [-80.846128385999975, 35.562665043000038],
            [-80.846332476999976, 35.562538876000076],
            [-80.847023087999958, 35.562099010000054],
            [-80.84733550499999, 35.56189052700006],
            [-80.847698359999981, 35.561622444000079],
            [-80.848042588999988, 35.561338434000049],
            [-80.848367145999987, 35.561039360000052],
            [-80.848567945999946, 35.560836413000061],
            [-80.849432778999983, 35.559929417000035],
            [-80.852122049999934, 35.557321395000031],
            [-80.852135335999947, 35.557308479000028],
            [-80.852402316999985, 35.557035209000048],
            [-80.853257035999945, 35.556115727000076],
            [-80.853293923999956, 35.556075757000031],
            [-80.853576206999946, 35.555749311000056],
            [-80.853836053999942, 35.555410652000035],
            [-80.854072672999962, 35.555060810000043],
            [-80.854285346999973, 35.554700847000049],
            [-80.854444484999988, 35.554392553000071],
            [-80.855280607999987, 35.552662307000048],
            [-80.855309553999973, 35.552601610000067],
            [-80.85545889499997, 35.552258819000031],
            [-80.85628733599998, 35.550194728000065],
            [-80.856300910999948, 35.550160621000032],
            [-80.856438164999986, 35.549776959000042],
            [-80.856549346999941, 35.549387699000079],
            [-80.856634119999967, 35.548994026000059],
            [-80.856692228999975, 35.548597133000044],
            [-80.856723494999983, 35.548198228000047],
            [-80.85672782599994, 35.547798520000072],
            [-80.856705206999948, 35.54739922400006],
            [-80.856655708999938, 35.54700155300003],
            [-80.856579480999983, 35.546606714000063],
            [-80.856476756999939, 35.546215906000043],
            [-80.856347847999984, 35.545830316000036],
            [-80.856193144999963, 35.545451116000038],
            [-80.856013118999954, 35.545079456000053],
            [-80.855808316999969, 35.544716466000068],
            [-80.855579361999958, 35.544363246000046],
            [-80.855326948999959, 35.544020871000043],
            [-80.855051843999945, 35.543690380000044],
            [-80.854754882999941, 35.54337277500008],
            [-80.854436969999938, 35.543069023000044],
            [-80.854099066999936, 35.542780045000029],
            [-80.853742201999978, 35.542506717000037],
            [-80.853367459999959, 35.542249871000024],
            [-80.852975976999971, 35.542010287000039],
            [-80.852568941999948, 35.541788691000079],
            [-80.852147592999984, 35.541585757000064],
            [-80.851713206999989, 35.541402100000028],
            [-80.851267103999987, 35.541238279000027],
            [-80.850810638999974, 35.541094790000045],
            [-80.850345196999967, 35.540972070000066],
            [-80.849872191999964, 35.540870491000078],
            [-80.849393059999954, 35.540790361000063],
            [-80.848909254999967, 35.540731924000056],
            [-80.848422246999974, 35.540695357000061],
            [-80.847933514999966, 35.540680771000041],
            [-80.847444539999969, 35.540688210000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 115,
        "SolutionID": "PI-11:E",
        "Shape_Length": 0.15933965984100332,
        "Shape_Area": 0.0010805830881172359
      }
    },
    {
      "type": "Feature",
      "id": 116,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.836786073999974, 35.223481681000067],
            [-80.835630660999982, 35.223530287000074],
            [-80.834481590999985, 35.223640789000058],
            [-80.833343785999944, 35.223812711000051],
            [-80.83222211399999, 35.224045320000073],
            [-80.831121374999952, 35.224337619000039],
            [-80.830046280999966, 35.224688356000058],
            [-80.829001433999963, 35.225096032000067],
            [-80.82799130799998, 35.225558901000056],
            [-80.827020223999966, 35.226074982000057],
            [-80.826092341999981, 35.226642066000068],
            [-80.825211632999981, 35.227257725000072],
            [-80.824381868999978, 35.227919325000073],
            [-80.823606601999984, 35.228624033000074],
            [-80.823444160999941, 35.228784088000054],
            [-80.82340415799996, 35.228824083000063],
            [-80.822849148999978, 35.229408828000032],
            [-80.822598677999963, 35.229694274000053],
            [-80.822008615999948, 35.230384204000075],
            [-80.821737276999954, 35.23071083800005],
            [-80.82123721399995, 35.231330778000029],
            [-80.821102455999949, 35.231500400000073],
            [-80.820728175999989, 35.232000757000037],
            [-80.820678165999936, 35.232070750000048],
            [-80.82045956799999, 35.232385647000058],
            [-80.820302101999971, 35.232638405000046],
            [-80.820086930999935, 35.232915667000043],
            [-80.819908457999986, 35.233150557000045],
            [-80.819711349999977, 35.233415567000065],
            [-80.819317272999967, 35.233922119000056],
            [-80.819043204999957, 35.234286131000033],
            [-80.818973301999961, 35.234382113000038],
            [-80.818901150999977, 35.23447696900007],
            [-80.818795936999948, 35.234617057000037],
            [-80.818587779999973, 35.234897757000056],
            [-80.818392049999943, 35.235140072000036],
            [-80.818213269999944, 35.235373394000078],
            [-80.818085886999938, 35.235543196000037],
            [-80.817976309999949, 35.235685612000054],
            [-80.817653552999957, 35.23609792700006],
            [-80.817651944999966, 35.236099982000042],
            [-80.817520595999952, 35.236270432000026],
            [-80.81710678799999, 35.236815777000061],
            [-80.817047533999983, 35.236892896000029],
            [-80.816960261999952, 35.237003696000045],
            [-80.816931843999953, 35.237039895000066],
            [-80.816788168999949, 35.237226607000025],
            [-80.816278102999945, 35.237900769000078],
            [-80.816030278999961, 35.238216107000028],
            [-80.816011711999977, 35.23823978300004],
            [-80.815762375999952, 35.238567939000063],
            [-80.815695243999983, 35.238658972000053],
            [-80.815625705999935, 35.238748780000037],
            [-80.815515377999986, 35.238897061000046],
            [-80.815248643999951, 35.23924373300008],
            [-80.81518411199994, 35.23932824700006],
            [-80.814799384999958, 35.239835964000065],
            [-80.814719254999943, 35.239936103000048],
            [-80.814621512999963, 35.240059615000064],
            [-80.81426292499998, 35.240541720000067],
            [-80.813767313999961, 35.241196774000059],
            [-80.81364887999996, 35.241355573000078],
            [-80.812469773999965, 35.242959527000039],
            [-80.81227144099995, 35.243223907000072],
            [-80.81219895299995, 35.243321391000052],
            [-80.812058929999978, 35.243511374000036],
            [-80.812048963999985, 35.243524912000055],
            [-80.811229640999954, 35.244639292000045],
            [-80.810941009999965, 35.245024039000043],
            [-80.810889922999934, 35.245092563000071],
            [-80.810089795999943, 35.24617246400004],
            [-80.809952223999971, 35.24636134900004],
            [-80.809886010999946, 35.246453845000076],
            [-80.809817396999961, 35.246545156000025],
            [-80.809810637999988, 35.246554160000073],
            [-80.809660614999984, 35.246754141000054],
            [-80.809452667999949, 35.247038564000036],
            [-80.809040005999975, 35.247617898000044],
            [-80.808924108999975, 35.247775898000043],
            [-80.808520250999948, 35.248314244000028],
            [-80.80845463199995, 35.248402415000044],
            [-80.808144581999954, 35.248822376000078],
            [-80.80809628999998, 35.248888181000041],
            [-80.807219035999935, 35.250090710000052],
            [-80.806895035999958, 35.250514894000048],
            [-80.806701754999949, 35.250773923000054],
            [-80.806345129999954, 35.25126328500005],
            [-80.80617089399999, 35.251484988000072],
            [-80.806023490999962, 35.251651753000033],
            [-80.805819583999948, 35.251887343000078],
            [-80.80520951699998, 35.252607272000034],
            [-80.805043834999935, 35.252810623000073],
            [-80.80441781899998, 35.253552744000046],
            [-80.804201482999986, 35.253815280000026],
            [-80.803501392999976, 35.254685195000036],
            [-80.803379890999963, 35.254838255000038],
            [-80.803154149999955, 35.255134359000067],
            [-80.80292411399995, 35.255444331000035],
            [-80.80280879299994, 35.25560196400005],
            [-80.802498739999976, 35.256031926000048],
            [-80.802246571999945, 35.256393352000032],
            [-80.802168937999966, 35.256509674000029],
            [-80.801838870999973, 35.257009633000052],
            [-80.801438071999939, 35.257654655000067],
            [-80.801087980999966, 35.258254609000062],
            [-80.801039427999967, 35.258338503000061],
            [-80.800982624999961, 35.258438452000064],
            [-80.800802575999967, 35.258758428000078],
            [-80.800658229999954, 35.259021812000071],
            [-80.800418156999967, 35.259471781000059],
            [-80.800316026999951, 35.259667082000078],
            [-80.800175727999942, 35.259940893000078],
            [-80.799858746999973, 35.260525873000063],
            [-80.799699432999944, 35.260829133000072],
            [-80.799469810999938, 35.26128028100004],
            [-80.79869030399999, 35.262738426000055],
            [-80.798548704999973, 35.262978179000072],
            [-80.79852009199999, 35.263028272000042],
            [-80.798468612999955, 35.263118844000076],
            [-80.798397546999979, 35.263228292000065],
            [-80.798366572999953, 35.263267774000042],
            [-80.798358830999973, 35.263277651000067],
            [-80.798035733999939, 35.263713667000047],
            [-80.798034099999938, 35.26371285700003],
            [-80.797684806999939, 35.264186780000045],
            [-80.797601400999952, 35.26428899800004],
            [-80.797447969999951, 35.264480279000054],
            [-80.796853590999945, 35.265234172000078],
            [-80.796686561999934, 35.265438872000061],
            [-80.796073242999967, 35.266169287000025],
            [-80.79588883699995, 35.266393336000078],
            [-80.795834757999955, 35.266460380000069],
            [-80.795752409999977, 35.266554067000072],
            [-80.795515426999941, 35.266830408000033],
            [-80.795483673999968, 35.26686836600004],
            [-80.795448413999964, 35.266904183000065],
            [-80.79498545499996, 35.267395616000044],
            [-80.794328321999956, 35.268177163000075],
            [-80.794275794999976, 35.268244637000066],
            [-80.793505402999983, 35.269240275000072],
            [-80.793175897999959, 35.269656398000052],
            [-80.793128155999966, 35.269717018000051],
            [-80.793111046999968, 35.269738905000054],
            [-80.792854850999959, 35.270067284000049],
            [-80.792628090999983, 35.27033695800003],
            [-80.792365792999988, 35.270682388000068],
            [-80.791786778999949, 35.271469289000038],
            [-80.791379144999951, 35.272006888000078],
            [-80.790924457999949, 35.272641928000041],
            [-80.790635498999961, 35.273092847000044],
            [-80.789951745999986, 35.273945332000039],
            [-80.789917186999958, 35.27398858600003],
            [-80.789177088999963, 35.274918497000044],
            [-80.789107574999946, 35.275006532000077],
            [-80.789020190999963, 35.275119216000064],
            [-80.788816641999972, 35.275384384000063],
            [-80.788248401999965, 35.27608235200006],
            [-80.788077437999959, 35.276296407000075],
            [-80.787962524999955, 35.276445065000075],
            [-80.787915687999941, 35.276506477000055],
            [-80.787865630999988, 35.276566156000058],
            [-80.787629021999976, 35.276855629000067],
            [-80.78512869399998, 35.279995328000041],
            [-80.785057035999955, 35.28008604200005],
            [-80.784944942999971, 35.280230987000039],
            [-80.784856928999943, 35.280346297000051],
            [-80.784767542999987, 35.280460894000043],
            [-80.784549363999986, 35.280747924000025],
            [-80.784500827999977, 35.280813468000076],
            [-80.784449129999985, 35.280877365000038],
            [-80.784423682999943, 35.280908905000047],
            [-80.784401039999977, 35.280937048000055],
            [-80.784074180999937, 35.28128435800005],
            [-80.784005247999971, 35.281361359000073],
            [-80.783858355999939, 35.281526580000047],
            [-80.78345148599999, 35.281981798000061],
            [-80.78297646499999, 35.282540290000043],
            [-80.782596416999979, 35.283010244000025],
            [-80.782456717999935, 35.283185728000035],
            [-80.782440527999938, 35.28320657900008],
            [-80.782266134999986, 35.283417639000049],
            [-80.782247946999973, 35.283439163000025],
            [-80.782174642999962, 35.283521615000041],
            [-80.781586610999966, 35.284225623000054],
            [-80.780992953999942, 35.28504067700004],
            [-80.780465568999944, 35.285885819000043],
            [-80.780006714999956, 35.286757432000059],
            [-80.779618359999972, 35.287651785000037],
            [-80.779302168999948, 35.288565047000077],
            [-80.779059495999945, 35.289493309000079],
            [-80.778891385999941, 35.290432597000063],
            [-80.778798557999949, 35.291378888000054],
            [-80.778781414999969, 35.292328131000033],
            [-80.778840030999959, 35.293276260000027],
            [-80.778974158999972, 35.294219216000045],
            [-80.77918322499994, 35.295152960000053],
            [-80.779466339999942, 35.296073494000041],
            [-80.779822290999959, 35.296976875000041],
            [-80.779846956999961, 35.297032405000039],
            [-80.780188457999941, 35.297886596000069],
            [-80.780245384999944, 35.298026575000051],
            [-80.780339469999944, 35.29825406100008],
            [-80.78086405099998, 35.299561417000064],
            [-80.780945537999969, 35.299759665000067],
            [-80.781025503999956, 35.29994967600004],
            [-80.781101723999939, 35.300127049000025],
            [-80.781529019999937, 35.30100940300008],
            [-80.781538896999962, 35.301027962000035],
            [-80.781618872999957, 35.301177973000051],
            [-80.781783939999968, 35.301478250000059],
            [-80.78195389199999, 35.301778273000025],
            [-80.782038264999983, 35.301925061000077],
            [-80.78210824699994, 35.302045070000077],
            [-80.782345721999945, 35.302436997000029],
            [-80.782715560999975, 35.302994641000055],
            [-80.783032584999944, 35.303448685000035],
            [-80.783085703999973, 35.303529948000062],
            [-80.783519768999952, 35.30415614900005],
            [-80.783808123999961, 35.304535725000051],
            [-80.783906080999941, 35.304660348000027],
            [-80.784003160999987, 35.304785431000028],
            [-80.784398856999985, 35.305273226000054],
            [-80.784620513999982, 35.305528911000067],
            [-80.784719876999986, 35.305640943000071],
            [-80.784818013999939, 35.305753698000046],
            [-80.784891588999983, 35.305837598000039],
            [-80.785041210999964, 35.306006942000067],
            [-80.785268765999945, 35.306283329000053],
            [-80.785302974999979, 35.306323256000042],
            [-80.785441211999967, 35.306516836000071],
            [-80.785549488999948, 35.30666640000004],
            [-80.785571582999978, 35.306696423000062],
            [-80.785881532999952, 35.307116462000067],
            [-80.786290197999961, 35.307644248000031],
            [-80.786298946999977, 35.307655027000067],
            [-80.786304651999956, 35.307663154000068],
            [-80.786479343999986, 35.30790651500007],
            [-80.787108520999936, 35.30870354700005],
            [-80.787799956999947, 35.309465136000028],
            [-80.788550691999944, 35.310188022000034],
            [-80.789357510999935, 35.310869106000041],
            [-80.790216958999963, 35.311505473000068],
            [-80.791125356999942, 35.312094395000031],
            [-80.792078812999989, 35.312633350000056],
            [-80.79307324399997, 35.313120029000061],
            [-80.794104389999973, 35.313552347000041],
            [-80.795167834999972, 35.313928452000027],
            [-80.796259020999969, 35.314246732000072],
            [-80.797373274999984, 35.314505823000047],
            [-80.798505822999971, 35.314704618000064],
            [-80.799651813999958, 35.314842261000024],
            [-80.800806336999983, 35.314918166000041],
            [-80.801964444999953, 35.31493200500006],
            [-80.803121175999934, 35.314883721000058],
            [-80.804271576999952, 35.314773519000028],
            [-80.805410715999983, 35.314601873000072],
            [-80.806533712999965, 35.314369516000056],
            [-80.807635757999947, 35.314077446000056],
            [-80.808712128999957, 35.313726913000039],
            [-80.809758214999988, 35.313319418000049],
            [-80.810769534999963, 35.312856708000027],
            [-80.811741756999936, 35.312340765000044],
            [-80.812670716999946, 35.311773800000026],
            [-80.813552435999952, 35.311158241000044],
            [-80.814383135999947, 35.310496724000075],
            [-80.815159263999988, 35.309792086000073],
            [-80.815877492999959, 35.309047342000042],
            [-80.81653474899997, 35.308265684000048],
            [-80.817128219999972, 35.307450459000052],
            [-80.817655364999951, 35.30660516100005],
            [-80.818113926999956, 35.305733407000048],
            [-80.81850194499998, 35.304838935000078],
            [-80.818817758999955, 35.303925573000072],
            [-80.819060021999974, 35.302997233000042],
            [-80.819227693999949, 35.30205789200005],
            [-80.819320063999953, 35.301111571000035],
            [-80.819336735999968, 35.300162325000031],
            [-80.819277643999953, 35.299214217000042],
            [-80.819143039999972, 35.298271306000061],
            [-80.81893350699994, 35.297337631000062],
            [-80.818649941999979, 35.296417190000057],
            [-80.818293562999941, 35.295513923000044],
            [-80.81786589699999, 35.294631698000046],
            [-80.817368777999945, 35.293774290000044],
            [-80.817032349999977, 35.293265065000071],
            [-80.816942332999986, 35.293135077000045],
            [-80.816937879999955, 35.293137150000064],
            [-80.816932115999975, 35.293128685000056],
            [-80.817140378999966, 35.292867037000065],
            [-80.817435859999989, 35.292515199000036],
            [-80.817751912999938, 35.292125553000062],
            [-80.81791098399998, 35.29190699600008],
            [-80.818380888999968, 35.291329553000025],
            [-80.81855180499997, 35.291115455000067],
            [-80.818639165999969, 35.291002750000075],
            [-80.81889169599998, 35.290673617000039],
            [-80.819534937999947, 35.289865034000059],
            [-80.820367506999958, 35.28882653800008],
            [-80.82047154899999, 35.288695222000058],
            [-80.820594944999982, 35.288535338000031],
            [-80.820812230999934, 35.288249694000058],
            [-80.820881490999966, 35.288165172000049],
            [-80.821203016999959, 35.287738463000039],
            [-80.821266738999952, 35.287650531000054],
            [-80.82133300199996, 35.287563871000032],
            [-80.821824712999955, 35.286879880000072],
            [-80.82215802199994, 35.28636133200007],
            [-80.822174655999959, 35.286334135000061],
            [-80.822402448999981, 35.286024402000066],
            [-80.822511232999943, 35.28589497400003],
            [-80.822528336999937, 35.28587308300007],
            [-80.822886036999989, 35.28541438700006],
            [-80.823223164999945, 35.284988445000067],
            [-80.823239559999934, 35.28496782600007],
            [-80.823269841999945, 35.284936427000048],
            [-80.823624031999941, 35.284556463000058],
            [-80.82406096699998, 35.284048969000025],
            [-80.824570905999963, 35.283428908000076],
            [-80.824790951999944, 35.283154697000043],
            [-80.824868695999953, 35.283054488000062],
            [-80.824918833999959, 35.282989274000045],
            [-80.82497209099995, 35.282925743000078],
            [-80.825150565999934, 35.282708670000034],
            [-80.825309625999978, 35.282511390000025],
            [-80.825896095999951, 35.281812652000042],
            [-80.82602828499995, 35.281652889000043],
            [-80.826338245999978, 35.281272853000075],
            [-80.826491463999957, 35.281081752000034],
            [-80.827085598999986, 35.280327826000075],
            [-80.827317944999947, 35.280042951000041],
            [-80.827500601999986, 35.279814368000075],
            [-80.827755249999939, 35.279479092000031],
            [-80.828315160999978, 35.278719023000065],
            [-80.828316962999963, 35.278716514000052],
            [-80.828522711999938, 35.278454122000028],
            [-80.828530450999949, 35.278444243000024],
            [-80.829108413999961, 35.277651404000039],
            [-80.829318372999978, 35.277341377000027],
            [-80.829333543999951, 35.277318928000057],
            [-80.829592033999973, 35.276917859000037],
            [-80.829919540999981, 35.276413172000048],
            [-80.830310262999944, 35.275773514000036],
            [-80.830338859999983, 35.275723413000037],
            [-80.830491935999987, 35.275453908000031],
            [-80.830559677999986, 35.275342297000066],
            [-80.830730152999934, 35.275053458000059],
            [-80.830926246999979, 35.274699373000033],
            [-80.831935942999962, 35.272809239000026],
            [-80.832059372999936, 35.272572480000065],
            [-80.83227177599997, 35.272154835000038],
            [-80.832579948999978, 35.271585691000041],
            [-80.832722762999936, 35.271314525000037],
            [-80.832882535999943, 35.271002468000063],
            [-80.83300002499999, 35.270782093000037],
            [-80.833054379999965, 35.270685428000036],
            [-80.83314489899999, 35.270530202000032],
            [-80.833203409999953, 35.270449021000047],
            [-80.833206570999948, 35.270444758000053],
            [-80.833621494999988, 35.269928952000043],
            [-80.834211526999979, 35.269229193000058],
            [-80.834357420999936, 35.269049635000044],
            [-80.834769340999969, 35.268563332000042],
            [-80.835045941999965, 35.268250274000025],
            [-80.835470106999935, 35.267748289000053],
            [-80.835680079999975, 35.267488264000065],
            [-80.835802224999952, 35.267334908000066],
            [-80.836242164999987, 35.266774855000051],
            [-80.836259059999975, 35.266753305000066],
            [-80.83655757799994, 35.266358005000029],
            [-80.836972537999941, 35.265788309000072],
            [-80.837294211999961, 35.265366965000055],
            [-80.837483120999934, 35.265113803000077],
            [-80.838428928999974, 35.263816643000041],
            [-80.838682026999948, 35.263473651000027],
            [-80.839098967999973, 35.262917592000065],
            [-80.839190609999946, 35.262793994000049],
            [-80.839410573999942, 35.262493968000058],
            [-80.839526632999934, 35.262333334000061],
            [-80.839861395999947, 35.261863119000054],
            [-80.839911430999962, 35.26179912200007],
            [-80.840404117999981, 35.261129940000046],
            [-80.840455106999968, 35.261056268000061],
            [-80.841093929999943, 35.260193642000047],
            [-80.841398227999946, 35.259787810000034],
            [-80.841480437999962, 35.259677064000073],
            [-80.842121390999978, 35.258804853000072],
            [-80.842513253999982, 35.258278873000052],
            [-80.842574959999979, 35.25819180700006],
            [-80.842639264999946, 35.258106016000056],
            [-80.84272058099998, 35.25799644600005],
            [-80.843881806999946, 35.256416022000053],
            [-80.844411897999976, 35.255715052000028],
            [-80.844467337999959, 35.255641249000064],
            [-80.84453972099999, 35.255544240000063],
            [-80.844614276999948, 35.255448344000058],
            [-80.844725265999955, 35.255303712000057],
            [-80.845193111999947, 35.254686006000043],
            [-80.845350716999974, 35.254481072000033],
            [-80.845406748999949, 35.254411840000046],
            [-80.845557665999934, 35.254222175000052],
            [-80.84595795599995, 35.253685135000069],
            [-80.84597624099996, 35.253659363000054],
            [-80.846089022999934, 35.253515788000072],
            [-80.84610758499997, 35.253492107000056],
            [-80.846251220999989, 35.253305361000059],
            [-80.846676393999985, 35.25274313400007],
            [-80.846807486999978, 35.25258202200007],
            [-80.847046256999988, 35.25226801000008],
            [-80.847163010999964, 35.25211001100007],
            [-80.847482988999957, 35.251688118000061],
            [-80.847693703999937, 35.251418808000039],
            [-80.847739467999986, 35.251362470000061],
            [-80.847907330999988, 35.251151888000038],
            [-80.84808340099994, 35.250922069000069],
            [-80.848162546999959, 35.250816585000052],
            [-80.848167802999967, 35.250809799000024],
            [-80.848246151999945, 35.25071212000006],
            [-80.848357268999962, 35.250571834000027],
            [-80.848583407999968, 35.250274870000055],
            [-80.848921945999962, 35.249818124000058],
            [-80.84913753699999, 35.249539876000028],
            [-80.849315174999958, 35.249294321000036],
            [-80.84962199499995, 35.248899745000074],
            [-80.849810866999974, 35.24865135500005],
            [-80.850012978999985, 35.248379484000054],
            [-80.850822276999963, 35.247336143000041],
            [-80.851379603999987, 35.246566138000048],
            [-80.851403762999951, 35.246529705000057],
            [-80.851550763999967, 35.246357751000062],
            [-80.851547854999978, 35.246356078000076],
            [-80.851996751999934, 35.24582139100005],
            [-80.852589411999986, 35.245005984000045],
            [-80.853115784999943, 35.244160522000072],
            [-80.853573614999959, 35.243288625000048],
            [-80.853960946999962, 35.242394029000025],
            [-80.854276120999941, 35.241480565000074],
            [-80.854517793999946, 35.240552145000038],
            [-80.854684928999973, 35.239612745000045],
            [-80.854776815999969, 35.23866638700008],
            [-80.854793061999942, 35.237717126000064],
            [-80.854733601999953, 35.236769025000058],
            [-80.854598690999978, 35.235826143000054],
            [-80.85438891199999, 35.234892520000074],
            [-80.854105162999986, 35.233972152000035],
            [-80.853748664999955, 35.233068980000041],
            [-80.853320942999972, 35.232186871000067],
            [-80.852823831999956, 35.231329600000038],
            [-80.85225946099996, 35.230500839000058],
            [-80.85163025099996, 35.229704136000066],
            [-80.850938894999956, 35.228942901000039],
            [-80.85018835599999, 35.228220392000026],
            [-80.849381846999961, 35.227539703000048],
            [-80.848522822999939, 35.226903747000051],
            [-80.847614959999987, 35.226315247000059],
            [-80.846662145999971, 35.225776722000035],
            [-80.845668461999935, 35.225290475000065],
            [-80.844638159999988, 35.224858590000053],
            [-80.843575649999934, 35.224482914000077],
            [-80.842485480999983, 35.224165056000061],
            [-80.841372318999959, 35.22390637500007],
            [-80.840240928999947, 35.223707979000039],
            [-80.839096151999968, 35.22357071700003],
            [-80.837942887999986, 35.223495176000029],
            [-80.836786073999974, 35.223481681000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 116,
        "SolutionID": "PI-12:I",
        "Shape_Length": 0.25433299133957138,
        "Shape_Area": 0.0033774182903793435
      }
    },
    {
      "type": "Feature",
      "id": 117,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.76999862599996, 35.045621127000061],
            [-80.769915853999976, 35.045622500000036],
            [-80.768296140999951, 35.04565249500007],
            [-80.76774794499994, 35.045669659000055],
            [-80.767068065999979, 35.045699654000032],
            [-80.766546095999956, 35.04572906900006],
            [-80.766369981999958, 35.045741885000041],
            [-80.765850072999967, 35.045781877000024],
            [-80.765583077999963, 35.045804101000044],
            [-80.764913195999952, 35.045864091000055],
            [-80.764267807999943, 35.045931809000024],
            [-80.763187992999974, 35.046061785000063],
            [-80.763129826999943, 35.046068868000077],
            [-80.762514628999952, 35.046153809000032],
            [-80.761534793999942, 35.046303783000042],
            [-80.761014508999949, 35.04639009300007],
            [-80.760773228999938, 35.046434694000027],
            [-80.759673408999959, 35.046644658000048],
            [-80.75885797899997, 35.046817288000057],
            [-80.757698160999951, 35.047087242000032],
            [-80.757635424999989, 35.047101947000044],
            [-80.757060713999977, 35.047246356000073],
            [-80.756310827999982, 35.047446322000042],
            [-80.755918851999979, 35.047555038000041],
            [-80.755328937999934, 35.047725010000079],
            [-80.755197002999978, 35.04776350700007],
            [-80.754879233999986, 35.047860243000059],
            [-80.754209328999934, 35.04807020800007],
            [-80.753583456999934, 35.048277728000073],
            [-80.752583589999972, 35.048627672000066],
            [-80.752454131999968, 35.048673482000027],
            [-80.751840610999977, 35.048904553000057],
            [-80.750880729999949, 35.049284492000027],
            [-80.750451264999981, 35.049460433000036],
            [-80.750307694999947, 35.049521956000035],
            [-80.749407795999957, 35.049911896000026],
            [-80.748869669999976, 35.050155000000075],
            [-80.748359722999965, 35.050394963000031],
            [-80.748033010999961, 35.050552552000056],
            [-80.747764705999941, 35.050687865000043],
            [-80.74638483199999, 35.051397760000043],
            [-80.745683617999987, 35.051777890000039],
            [-80.745446890999972, 35.051915356000052],
            [-80.744566951999957, 35.052435281000044],
            [-80.744291467999972, 35.052601401000061],
            [-80.743771500999969, 35.052921355000024],
            [-80.743357233999973, 35.05318423500006],
            [-80.743165409999961, 35.053311544000053],
            [-80.742155455999978, 35.053991452000048],
            [-80.741467804999957, 35.054479208000032],
            [-80.741416300999958, 35.054517839000027],
            [-80.740606317999948, 35.055127759000072],
            [-80.740285556999936, 35.055375406000053],
            [-80.739805560999969, 35.055755358000056],
            [-80.739372469999978, 35.05611027100008],
            [-80.738712463999946, 35.056670202000078],
            [-80.738689117999968, 35.056690046000028],
            [-80.738263864999965, 35.057065241000032],
            [-80.737913854999988, 35.057385203000024],
            [-80.737664612999936, 35.057618003000073],
            [-80.737004586999944, 35.058247932000029],
            [-80.736904696999943, 35.058344109000075],
            [-80.736453332999986, 35.05880103800007],
            [-80.736236272999975, 35.059030116000031],
            [-80.735974001999978, 35.059292343000038],
            [-80.735417896999934, 35.059878322000031],
            [-80.735194301999968, 35.060132497000041],
            [-80.734704251999972, 35.060702439000067],
            [-80.734431632999986, 35.061028860000079],
            [-80.734131595999941, 35.061398824000037],
            [-80.733971769999982, 35.061599471000079],
            [-80.733854182999949, 35.061751862000051],
            [-80.733504130999961, 35.062211819000026],
            [-80.733444007999935, 35.062291387000073],
            [-80.732933929999945, 35.062971325000035],
            [-80.732542652999939, 35.063519404000033],
            [-80.727810340999952, 35.070492968000053],
            [-80.727273847999982, 35.07125056600006],
            [-80.726909319999947, 35.071719850000079],
            [-80.726535746999957, 35.072164746000055],
            [-80.726426036999953, 35.072288588000049],
            [-80.726017763999948, 35.072736802000065],
            [-80.725601581999967, 35.07318807200005],
            [-80.725488902999984, 35.073298524000052],
            [-80.725001309999982, 35.073756676000073],
            [-80.724501730999975, 35.07419154400003],
            [-80.724309123999944, 35.074352684000075],
            [-80.723716344999957, 35.07482133700006],
            [-80.723568073999957, 35.074935378000077],
            [-80.721958960999984, 35.07611526900007],
            [-80.720863380999958, 35.076910210000051],
            [-80.720677253999952, 35.07704719700007],
            [-80.720607199999961, 35.077099780000026],
            [-80.720293046999984, 35.077336851000041],
            [-80.720175351999956, 35.077419922000047],
            [-80.719807275999983, 35.077687113000025],
            [-80.719661622999979, 35.077798095000048],
            [-80.719292305999943, 35.078071360000024],
            [-80.716687287999946, 35.079988609000054],
            [-80.715895543999977, 35.080559342000072],
            [-80.715679642999987, 35.080717564000054],
            [-80.715203421999945, 35.081072357000039],
            [-80.713338799999974, 35.082447138000077],
            [-80.71118931999996, 35.083992778000038],
            [-80.710292706999951, 35.084614693000049],
            [-80.710089517999961, 35.084751715000039],
            [-80.709755492999989, 35.084968194000055],
            [-80.708428382999955, 35.085810154000058],
            [-80.708048893999944, 35.086047314000041],
            [-80.706906848999949, 35.086734088000071],
            [-80.706101845999967, 35.087196186000028],
            [-80.705115323999962, 35.087743362000026],
            [-80.704914395999936, 35.087849107000068],
            [-80.70464588699997, 35.087995585000044],
            [-80.704036909999957, 35.088303069000062],
            [-80.702995770999962, 35.088815726000064],
            [-80.700936684999988, 35.08977137200003],
            [-80.700670933999959, 35.089890496000066],
            [-80.700655118999975, 35.089897170000029],
            [-80.700410720999969, 35.090002543000026],
            [-80.699920039999938, 35.090218137000079],
            [-80.699183476999963, 35.09052502600008],
            [-80.698851238999964, 35.090661184000055],
            [-80.698449794999988, 35.090814109000064],
            [-80.698006342999975, 35.090992260000064],
            [-80.696317815999976, 35.091660790000049],
            [-80.695285512999988, 35.09204789100005],
            [-80.69521922499996, 35.092072884000061],
            [-80.692159612999944, 35.093232699000055],
            [-80.691972283999974, 35.09330480400007],
            [-80.691223119999961, 35.093597571000032],
            [-80.689567849999946, 35.094219328000065],
            [-80.688512974999981, 35.094590632000063],
            [-80.688430593999954, 35.094619832000035],
            [-80.688115350999965, 35.094735377000063],
            [-80.681736163999972, 35.097134994000044],
            [-80.681007474999944, 35.097425870000052],
            [-80.679998090999959, 35.097887511000067],
            [-80.679027591999954, 35.098402413000031],
            [-80.678100133999976, 35.098968371000069],
            [-80.67721968699999, 35.099582963000046],
            [-80.676390021999964, 35.100243559000035],
            [-80.675614690999964, 35.100947330000054],
            [-80.674897013999953, 35.101691265000056],
            [-80.674240064999935, 35.102472178000028],
            [-80.673646657999939, 35.103286727000068],
            [-80.673119335999957, 35.104131424000059],
            [-80.672660357999973, 35.105002653000042],
            [-80.672271691999981, 35.105896685000062],
            [-80.671955003999983, 35.106809691000024],
            [-80.671711649999963, 35.107737763000046],
            [-80.67154267799998, 35.108676926000044],
            [-80.671448811999937, 35.109623161000059],
            [-80.671430457999975, 35.110572413000057],
            [-80.671487694999939, 35.111520619000032],
            [-80.671620283999971, 35.112463720000051],
            [-80.671827654999959, 35.113397675000044],
            [-80.672108927999943, 35.11431848400008],
            [-80.672462895999956, 35.115222206000055],
            [-80.672888047999948, 35.116104968000059],
            [-80.673382563999951, 35.116962990000047],
            [-80.673944329999983, 35.117792597000062],
            [-80.674570940999956, 35.118590236000045],
            [-80.675259713999935, 35.11935249000004],
            [-80.676007700999946, 35.120076094000069],
            [-80.676811699999973, 35.120757948000062],
            [-80.677668264999966, 35.121395131000043],
            [-80.678573732999951, 35.121984915000041],
            [-80.679524222999987, 35.12252477100003],
            [-80.680515662999937, 35.123012389000053],
            [-80.681543807999958, 35.123445678000053],
            [-80.682604253999955, 35.123822782000047],
            [-80.68369245699995, 35.124142085000074],
            [-80.684803755999951, 35.124402219000046],
            [-80.685933388999956, 35.124602072000073],
            [-80.687076517999969, 35.124740785000029],
            [-80.688228244999948, 35.124817765000046],
            [-80.689383632999977, 35.124832681000044],
            [-80.690537733999975, 35.124785471000052],
            [-80.691685604999975, 35.124676335000061],
            [-80.692822324999952, 35.124505743000043],
            [-80.693943023999964, 35.124274423000031],
            [-80.695042902999944, 35.123983370000076],
            [-80.69611724899994, 35.123633827000049],
            [-80.696432591999951, 35.123518262000061],
            [-80.702615630999958, 35.121192285000063],
            [-80.703665847999957, 35.120822597000029],
            [-80.703748250999979, 35.120793387000049],
            [-80.704053803999955, 35.120681474000037],
            [-80.70602405599999, 35.119941355000037],
            [-80.706266427999935, 35.119848476000072],
            [-80.707043104999968, 35.119544936000068],
            [-80.709976427999948, 35.118432920000032],
            [-80.71114339799999, 35.117995291000057],
            [-80.711476937999976, 35.117866735000064],
            [-80.713447181999982, 35.117086611000047],
            [-80.713585602999956, 35.117029944000024],
            [-80.71392906899996, 35.116899095000065],
            [-80.714383019999957, 35.116719644000057],
            [-80.714993092999975, 35.116469605000077],
            [-80.715097594999975, 35.11642641800006],
            [-80.716057708999983, 35.116026355000031],
            [-80.716153749999989, 35.11598603300007],
            [-80.716398205999951, 35.11588062800007],
            [-80.71692186599995, 35.115650525000035],
            [-80.716974213999947, 35.11562848300008],
            [-80.71700384799999, 35.115615976000072],
            [-80.71737812799995, 35.115452946000062],
            [-80.717958191999969, 35.115192906000061],
            [-80.71818561799995, 35.115089157000057],
            [-80.720555868999952, 35.113988989000063],
            [-80.720947902999967, 35.11380151700007],
            [-80.722268031999988, 35.113151418000029],
            [-80.722283669999968, 35.113143708000052],
            [-80.722435932999986, 35.113067686000079],
            [-80.723446028999945, 35.112557610000067],
            [-80.723879112999953, 35.112331720000043],
            [-80.724009124999952, 35.112261710000041],
            [-80.724167850999947, 35.112175228000069],
            [-80.724288354999942, 35.112108799000055],
            [-80.724410147999947, 35.112043975000063],
            [-80.724690206999981, 35.111891789000026],
            [-80.72597030999998, 35.111181685000076],
            [-80.726154305999955, 35.111078220000024],
            [-80.726203364999947, 35.111050156000033],
            [-80.727283445999944, 35.110430066000049],
            [-80.727599105999957, 35.110244562000048],
            [-80.729029200999946, 35.109384440000042],
            [-80.729290022999976, 35.109224512000026],
            [-80.729850056999965, 35.108874463000063],
            [-80.729951749999941, 35.108810419000065],
            [-80.73140183299995, 35.107890291000047],
            [-80.731545485999959, 35.107798160000073],
            [-80.732085513999948, 35.107448111000053],
            [-80.732141854999952, 35.107411437000053],
            [-80.732352291999973, 35.107271707000052],
            [-80.732782310999937, 35.106981667000071],
            [-80.732970157999944, 35.106853169000033],
            [-80.734080200999983, 35.106083065000064],
            [-80.73431833799998, 35.105914848000054],
            [-80.736668413999951, 35.10422462400004],
            [-80.736832119999974, 35.104105398000058],
            [-80.73881216999996, 35.10264520700008],
            [-80.73887986799997, 35.10259502100007],
            [-80.739282713999955, 35.102294830000062],
            [-80.740033995999966, 35.101753153000061],
            [-80.74016984299999, 35.101654185000029],
            [-80.742859913999951, 35.099673925000047],
            [-80.742894476999936, 35.099648412000079],
            [-80.743361514999947, 35.09930276800003],
            [-80.743437227999948, 35.09924729800008],
            [-80.743547593999949, 35.099168989000077],
            [-80.743882260999953, 35.09892538500003],
            [-80.743952318999959, 35.09887278900004],
            [-80.744355359999986, 35.098568575000058],
            [-80.74535616899999, 35.097842249000053],
            [-80.745424788999969, 35.097792186000049],
            [-80.747224837999966, 35.096472013000039],
            [-80.747342346999972, 35.096385049000048],
            [-80.747534703999975, 35.096239249000064],
            [-80.74792470999995, 35.09593921000004],
            [-80.748100530999977, 35.095802072000026],
            [-80.748960539999985, 35.095121986000038],
            [-80.74931533299997, 35.094833364000067],
            [-80.749805329999958, 35.09442331300005],
            [-80.749911396999948, 35.094333783000025],
            [-80.750047796999979, 35.094216344000074],
            [-80.75089778499995, 35.093476255000041],
            [-80.751349206999976, 35.09306790800008],
            [-80.75217917699996, 35.092287817000056],
            [-80.752365966999946, 35.092109349000054],
            [-80.752419100999987, 35.092057494000073],
            [-80.752929076999976, 35.091557438000052],
            [-80.753470005999986, 35.090999729000032],
            [-80.75417994899999, 35.090229647000058],
            [-80.754243114999952, 35.090160704000027],
            [-80.754753072999961, 35.089600645000075],
            [-80.754812717999982, 35.089534751000031],
            [-80.754892408999979, 35.089445479000062],
            [-80.755202379999957, 35.08909544200003],
            [-80.755460262999975, 35.088796355000056],
            [-80.756140187999961, 35.08798627300007],
            [-80.756458675999966, 35.087593278000043],
            [-80.756490310999936, 35.087552683000069],
            [-80.757220208999968, 35.08661259400003],
            [-80.757597760999943, 35.086103533000028],
            [-80.758397621999961, 35.084973433000073],
            [-80.758557347999954, 35.084742936000055],
            [-80.763178158999949, 35.077929937000079],
            [-80.763455308999937, 35.07756031100007],
            [-80.763639492999971, 35.077318185000024],
            [-80.763650569999982, 35.077304519000052],
            [-80.763936145999935, 35.077011091000031],
            [-80.764028819999965, 35.076913251000065],
            [-80.76428180299996, 35.076671729000054],
            [-80.764285851999944, 35.07666802600005],
            [-80.764504273999989, 35.076482675000079],
            [-80.764605483999958, 35.07640254100005],
            [-80.76489251299995, 35.076186359000076],
            [-80.765226181999935, 35.075961691000032],
            [-80.765301172999955, 35.07591553900005],
            [-80.765583536999941, 35.075748676000046],
            [-80.766164044999982, 35.075449991000028],
            [-80.766477351999981, 35.075314219000063],
            [-80.766780565999966, 35.075194192000026],
            [-80.767094614999962, 35.075084272000026],
            [-80.767225468999982, 35.075043256000072],
            [-80.767394251999974, 35.074994624000055],
            [-80.767630549999978, 35.074931610000078],
            [-80.768064744999947, 35.074830546000044],
            [-80.768375418999938, 35.074771235000071],
            [-80.768637205999937, 35.07473116500006],
            [-80.769057465999936, 35.074680579000074],
            [-80.76927057599994, 35.074661494000054],
            [-80.769308365999962, 35.074658587000044],
            [-80.769364777999954, 35.074656098000048],
            [-80.770169489999944, 35.074641197000062],
            [-80.770528959999979, 35.074660116000075],
            [-80.770789240999989, 35.074676182000076],
            [-80.770851034999964, 35.074682639000059],
            [-80.771613825999964, 35.074773841000024],
            [-80.771872834999954, 35.074803200000076],
            [-80.772534253999936, 35.074874066000064],
            [-80.774046329999976, 35.07506195600007],
            [-80.774230590999935, 35.075084035000032],
            [-80.775950755999986, 35.075282517000062],
            [-80.77641086999995, 35.075340031000053],
            [-80.776586532999943, 35.075361246000057],
            [-80.782249065999963, 35.076021057000048],
            [-80.782800846999976, 35.076090030000046],
            [-80.783057135999968, 35.076120484000057],
            [-80.783712402999981, 35.076194317000045],
            [-80.785777960999951, 35.076437898000052],
            [-80.785818630999984, 35.076442653000072],
            [-80.790616112999942, 35.07699888500008],
            [-80.79447866199996, 35.077458944000057],
            [-80.794874260999961, 35.077511192000031],
            [-80.795488627999987, 35.077583248000053],
            [-80.795983594999939, 35.077634013000079],
            [-80.796469867999974, 35.077697165000075],
            [-80.796826988999953, 35.077740470000037],
            [-80.798203217999969, 35.077895538000064],
            [-80.799699498999985, 35.078070429000036],
            [-80.800004697999952, 35.078103868000028],
            [-80.800884851999967, 35.078193885000076],
            [-80.801077826999972, 35.078212734000033],
            [-80.801464918999955, 35.078239527000051],
            [-80.801892109999983, 35.078281644000072],
            [-80.802007988999947, 35.07829274900007],
            [-80.802428761999977, 35.078327721000051],
            [-80.803153245999965, 35.078380733000074],
            [-80.80378781099995, 35.078432888000066],
            [-80.804194955999947, 35.078462441000056],
            [-80.805465180999988, 35.078542455000047],
            [-80.805741956999952, 35.078558090000058],
            [-80.806142027999954, 35.078578093000033],
            [-80.806232564999959, 35.078582428000061],
            [-80.807762834999949, 35.078652440000042],
            [-80.807910009999944, 35.078658665000034],
            [-80.807924711999988, 35.078659232000064],
            [-80.809234943999968, 35.07870924100007],
            [-80.810052996999957, 35.078724847000046],
            [-80.812673463999943, 35.078724846000057],
            [-80.812951814999963, 35.078723038000078],
            [-80.814492088999941, 35.078703034000057],
            [-80.81453573999994, 35.078702423000038],
            [-80.815007351999952, 35.078690148000078],
            [-80.815485444999979, 35.078672441000037],
            [-80.816383593999944, 35.078644374000078],
            [-80.816527403999942, 35.078639397000074],
            [-80.817317543999934, 35.078609391000043],
            [-80.817684698999983, 35.078592294000032],
            [-80.819134954999981, 35.078512280000041],
            [-80.819134927999983, 35.078511943000024],
            [-80.819387670999959, 35.078502050000054],
            [-80.82008252199995, 35.078458072000046],
            [-80.820647181999959, 35.078427384000065],
            [-80.821386822999955, 35.078405194000027],
            [-80.821894441999973, 35.078383942000073],
            [-80.825561738999966, 35.078186777000042],
            [-80.825791318999961, 35.078175577000025],
            [-80.825811776999956, 35.078176217000077],
            [-80.826175800999977, 35.07820741900008],
            [-80.82630100099999, 35.07822360800003],
            [-80.830028616999982, 35.078917114000035],
            [-80.830443467999942, 35.078989990000025],
            [-80.83171368099994, 35.079200026000024],
            [-80.832070742999974, 35.079255927000077],
            [-80.832341615999951, 35.07929417400004],
            [-80.832861704999971, 35.079364187000067],
            [-80.833462954999959, 35.079436408000049],
            [-80.834693167999944, 35.079566432000036],
            [-80.834963787999982, 35.07959328700008],
            [-80.835795718999975, 35.07965413900007],
            [-80.836151794999978, 35.079673215000071],
            [-80.836498228999972, 35.079698410000049],
            [-80.837225212999954, 35.079738865000024],
            [-80.837576320999972, 35.079749545000027],
            [-80.838476481999976, 35.079769548000058],
            [-80.83884184599998, 35.079774555000029],
            [-80.840772189999939, 35.07978455600005],
            [-80.840882771999986, 35.079784843000027],
            [-80.845988589999934, 35.079784845000063],
            [-80.850904374999971, 35.079814457000055],
            [-80.85121658099996, 35.079814060000047],
            [-80.852294968999956, 35.07980484400008],
            [-80.853145524999945, 35.07980484400008],
            [-80.853858188999936, 35.079818638000063],
            [-80.853976571999965, 35.079903777000027],
            [-80.854117438999936, 35.080003223000062],
            [-80.854255837999972, 35.080128725000066],
            [-80.854299676999972, 35.08017622400007],
            [-80.854335977999938, 35.080216567000036],
            [-80.854354593999972, 35.080240297000046],
            [-80.854533628999945, 35.080469351000033],
            [-80.854590998999981, 35.080551686000035],
            [-80.854781463999984, 35.080893202000027],
            [-80.854837766999935, 35.081001026000024],
            [-80.854899100999944, 35.081156799000041],
            [-80.854918109999971, 35.081230661000063],
            [-80.854994602999966, 35.081572951000055],
            [-80.855182506999938, 35.082595352000055],
            [-80.855309156999965, 35.083198128000049],
            [-80.855356549999954, 35.083398413000054],
            [-80.855712037999979, 35.085133216000031],
            [-80.856134683999983, 35.087258547000033],
            [-80.856160685999953, 35.087385749000077],
            [-80.856851805999952, 35.090676839000025],
            [-80.856929352999941, 35.091058516000032],
            [-80.856979742999954, 35.091348612000047],
            [-80.856999720999966, 35.091460507000079],
            [-80.857047695999938, 35.091707633000055],
            [-80.857287453999959, 35.092877668000028],
            [-80.857297022999944, 35.092923905000077],
            [-80.857693660999985, 35.094821964000062],
            [-80.858160856999973, 35.097179944000061],
            [-80.858338031999949, 35.097944305000055],
            [-80.858377431999941, 35.098088988000029],
            [-80.85861725999996, 35.098949021000067],
            [-80.858844081999962, 35.099675594000075],
            [-80.858892307999952, 35.099815002000071],
            [-80.858963062999976, 35.100033837000069],
            [-80.859061073999953, 35.100324741000065],
            [-80.859372699999938, 35.101125601000035],
            [-80.859552623999946, 35.101545625000028],
            [-80.859596975999978, 35.101647939000031],
            [-80.85977345799995, 35.102033067000036],
            [-80.859993379999935, 35.102493097000036],
            [-80.860235517999968, 35.102974058000029],
            [-80.860505434999936, 35.103484094000066],
            [-80.860513923999974, 35.103500108000048],
            [-80.861010336999982, 35.10435736200003],
            [-80.861109259999978, 35.104512402000069],
            [-80.861165367999945, 35.10459906400007],
            [-80.861218711999982, 35.104686895000043],
            [-80.861330264999935, 35.104867403000071],
            [-80.86168708799994, 35.105406649000031],
            [-80.861847058999956, 35.105636670000024],
            [-80.862019569999973, 35.105879237000067],
            [-80.862179543999957, 35.10609925600005],
            [-80.862213803999964, 35.106146175000049],
            [-80.862691174999952, 35.106761566000046],
            [-80.862756565999973, 35.106841359000043],
            [-80.862819299999956, 35.106922581000049],
            [-80.863232118999974, 35.107432885000037],
            [-80.863922558999946, 35.108194063000042],
            [-80.864083634999986, 35.108357571000056],
            [-80.865033582999956, 35.10930767800005],
            [-80.865622076999955, 35.109866600000032],
            [-80.86576076099999, 35.109990278000055],
            [-80.86635075099997, 35.110510339000029],
            [-80.86701755699994, 35.111067250000076],
            [-80.867156955999974, 35.111176371000056],
            [-80.867776964999962, 35.111656433000064],
            [-80.868495521999989, 35.112183144000028],
            [-80.868577123999955, 35.112239406000072],
            [-80.869117145999951, 35.11260945600003],
            [-80.869942294999987, 35.11314154300004],
            [-80.870274099999961, 35.113337872000045],
            [-80.87124417299998, 35.113897953000048],
            [-80.871864026999958, 35.114239970000028],
            [-80.872348499999987, 35.114486267000075],
            [-80.872858548999943, 35.114736305000065],
            [-80.873366567999938, 35.114976044000059],
            [-80.874000097999954, 35.115250006000053],
            [-80.876820434999956, 35.116410188000032],
            [-80.877094905999968, 35.116520659000059],
            [-80.88128667999996, 35.118170878000058],
            [-80.881311401999938, 35.118181338000056],
            [-80.881330658999957, 35.118190186000049],
            [-80.88151607399999, 35.118295185000079],
            [-80.881516958999953, 35.118295747000047],
            [-80.881576934999941, 35.118342690000077],
            [-80.881692634999979, 35.118448327000067],
            [-80.881703881999954, 35.118458460000056],
            [-80.881741915999953, 35.118492695000043],
            [-80.882001927999966, 35.118748485000026],
            [-80.88230813499996, 35.119060871000045],
            [-80.882745084999954, 35.119522635000067],
            [-80.88288272799997, 35.119666218000077],
            [-80.882979104999947, 35.119764581000027],
            [-80.88385905399997, 35.12065468000003],
            [-80.884512519999987, 35.121278629000074],
            [-80.88531827199995, 35.121959094000033],
            [-80.886176477999982, 35.12259479800008],
            [-80.887083463999943, 35.123183017000031],
            [-80.888035345999981, 35.123721232000037],
            [-80.889028044999975, 35.124207137000042],
            [-80.890057310999964, 35.124638651000055],
            [-80.891118733999974, 35.125013924000029],
            [-80.892207766999945, 35.125331349000078],
            [-80.893319744999985, 35.125589565000041],
            [-80.894449902999952, 35.125787468000055],
            [-80.895593399999939, 35.125924207000025],
            [-80.89674533799996, 35.125999200000024],
            [-80.897900777999951, 35.126012122000077],
            [-80.899054772999989, 35.125962920000063],
            [-80.90020237899995, 35.125851804000035],
            [-80.901338675999966, 35.125679250000076],
            [-80.902458797999941, 35.125445997000043],
            [-80.903557945999978, 35.125153046000037],
            [-80.904631410999968, 35.124801650000052],
            [-80.905674594999937, 35.124393315000077],
            [-80.906683026999985, 35.12392979100008],
            [-80.907652389999953, 35.123413063000044],
            [-80.908578530999989, 35.122845346000076],
            [-80.909457482999983, 35.12222907000006],
            [-80.91028548099996, 35.121566877000078],
            [-80.911058981999986, 35.120861604000027],
            [-80.911774670999989, 35.12011626900005],
            [-80.912429485999951, 35.119334068000057],
            [-80.913020622999966, 35.118518351000034],
            [-80.913545552999949, 35.117672611000046],
            [-80.91400202799997, 35.116800469000054],
            [-80.914388094999936, 35.115905664000024],
            [-80.914702103999957, 35.114992026000039],
            [-80.914942713999949, 35.11406346900003],
            [-80.915108893999957, 35.113123968000025],
            [-80.915199937999944, 35.112177547000044],
            [-80.915215454999952, 35.111228261000065],
            [-80.915155384999935, 35.110280173000035],
            [-80.915019984999958, 35.10933734200006],
            [-80.914809839999975, 35.108403807000059],
            [-80.914525849999961, 35.107483565000052],
            [-80.914169234999974, 35.10658055500005],
            [-80.913741522999942, 35.105698643000039],
            [-80.913244547999966, 35.104841606000036],
            [-80.912680438999985, 35.104013113000065],
            [-80.912051613999949, 35.103216710000027],
            [-80.911360766999962, 35.102455807000069],
            [-80.911264374999973, 35.102357465000068],
            [-80.910502916999974, 35.101587486000028],
            [-80.910088335999944, 35.101149517000067],
            [-80.909950676999983, 35.10100596500007],
            [-80.90990024599995, 35.100954293000029],
            [-80.909400215999938, 35.100444349000043],
            [-80.909200605999956, 35.100244453000073],
            [-80.908590575999938, 35.099644521000073],
            [-80.908142441999985, 35.099220739000032],
            [-80.908056340999963, 35.09914242900004],
            [-80.907972378999943, 35.099062564000064],
            [-80.907660735999968, 35.098773974000039],
            [-80.907506959999978, 35.098637108000048],
            [-80.907176952999976, 35.09834714200008],
            [-80.906596892999971, 35.097860852000053],
            [-80.906296894999969, 35.097620882000058],
            [-80.906225026999948, 35.09756371900005],
            [-80.906157143999962, 35.097510315000079],
            [-80.905467152999961, 35.096970384000031],
            [-80.904676946999984, 35.096388220000051],
            [-80.904655553999987, 35.096373411000059],
            [-80.903975579999951, 35.095903475000057],
            [-80.903407869999967, 35.095527293000032],
            [-80.90266791099998, 35.095057358000076],
            [-80.902350213999966, 35.094860253000036],
            [-80.901723607999941, 35.094497520000061],
            [-80.901529964999952, 35.094390514000054],
            [-80.901413789999935, 35.09432276900003],
            [-80.901113813999984, 35.094152794000024],
            [-80.900793602999954, 35.09397556600004],
            [-80.900423634999981, 35.093775595000068],
            [-80.900248671999975, 35.093682238000042],
            [-80.899898231999941, 35.093502378000039],
            [-80.899578261999977, 35.093342402000076],
            [-80.899017218999973, 35.093073390000029],
            [-80.898277297999982, 35.092733442000053],
            [-80.898196388999963, 35.092696497000077],
            [-80.897742729999948, 35.092497654000056],
            [-80.897222789999944, 35.092277688000024],
            [-80.896768596999948, 35.092092269000034],
            [-80.892595236999966, 35.090449427000067],
            [-80.892524131999949, 35.090091315000052],
            [-80.892498821999936, 35.089967233000039],
            [-80.892093256999942, 35.088030401000026],
            [-80.891894547999982, 35.087062709000065],
            [-80.891845768999985, 35.086782572000061],
            [-80.891825743999959, 35.086670683000079],
            [-80.891781716999958, 35.086443381000038],
            [-80.89166159499996, 35.08585339800004],
            [-80.891645436999966, 35.085775347000038],
            [-80.890958118999947, 35.082508996000058],
            [-80.890540353999938, 35.080412701000057],
            [-80.890526307999949, 35.080343271000061],
            [-80.890125903999945, 35.078393328000061],
            [-80.89004622899995, 35.07803307100005],
            [-80.890027330999942, 35.077953353000055],
            [-80.889852789999964, 35.077005856000028],
            [-80.889760166999963, 35.076551825000024],
            [-80.889590009999949, 35.07579185000003],
            [-80.889498125999978, 35.075410269000031],
            [-80.889327992999938, 35.074750292000033],
            [-80.889303667999968, 35.074657429000069],
            [-80.889110567999978, 35.074004595000076],
            [-80.888970483999969, 35.073574615000041],
            [-80.888908695999987, 35.073389848000033],
            [-80.888788627999986, 35.073039864000066],
            [-80.88875993299996, 35.072957159000055],
            [-80.888591849999955, 35.072505805000048],
            [-80.888201668999955, 35.071515859000044],
            [-80.888013576999981, 35.071064123000042],
            [-80.88781023699994, 35.070623354000077],
            [-80.887660183999969, 35.070313374000079],
            [-80.887477753999974, 35.069950850000055],
            [-80.887127644999964, 35.069280897000056],
            [-80.88708617399999, 35.069202181000037],
            [-80.886952944999962, 35.068957524000041],
            [-80.886422796999966, 35.068007594000051],
            [-80.886059561999957, 35.067395099000066],
            [-80.885934870999961, 35.067200435000075],
            [-80.885634807999963, 35.066740473000038],
            [-80.885393715999953, 35.066383082000073],
            [-80.884933632999946, 35.065723139000056],
            [-80.88473584999997, 35.065446569000073],
            [-80.884466932999942, 35.065091543000051],
            [-80.884101795999982, 35.06462461600006],
            [-80.883945304999941, 35.064418382000042],
            [-80.883457510999961, 35.063810094000075],
            [-80.883297211999945, 35.063623862000043],
            [-80.883017184999972, 35.063303894000057],
            [-80.88287760999998, 35.063146645000074],
            [-80.882607585999949, 35.062846676000049],
            [-80.882478957999979, 35.062705584000071],
            [-80.88211892999999, 35.062315625000053],
            [-80.881870961999937, 35.062053331000072],
            [-80.881570941999939, 35.06174336600003],
            [-80.881557174999955, 35.061729160000027],
            [-80.880834186999948, 35.061030601000027],
            [-80.88008416699995, 35.060350681000045],
            [-80.880057882999949, 35.060326905000068],
            [-80.879722030999972, 35.060032140000033],
            [-80.879312026999969, 35.059682182000074],
            [-80.878992608999965, 35.059416474000045],
            [-80.878622609999979, 35.05911651200006],
            [-80.878472784999985, 35.058996480000076],
            [-80.87802021899995, 35.058650525000076],
            [-80.877600229999985, 35.058340565000037],
            [-80.877306795999971, 35.058128776000046],
            [-80.877168154999936, 35.058030922000057],
            [-80.877164216999972, 35.058027969000079],
            [-80.876669936999974, 35.057670948000066],
            [-80.876439945999948, 35.057510969000077],
            [-80.876425349999977, 35.057500827000069],
            [-80.876079083999969, 35.057266626000057],
            [-80.875639106999984, 35.056976666000025],
            [-80.875193742999954, 35.056692638000072],
            [-80.874723770999935, 35.05640267900003],
            [-80.874609224999972, 35.056332601000065],
            [-80.874057316999938, 35.056011038000065],
            [-80.873467362999975, 35.05568108600005],
            [-80.873095158999945, 35.055478529000027],
            [-80.872961802999953, 35.055407933000026],
            [-80.872871652999947, 35.055359691000035],
            [-80.872746168999981, 35.055290445000026],
            [-80.872465056999943, 35.055138496000041],
            [-80.87229507099994, 35.055048509000073],
            [-80.87226833699998, 35.055034385000056],
            [-80.87188081499994, 35.05483582100004],
            [-80.871720513999946, 35.054756032000057],
            [-80.871560599999953, 35.05467572200007],
            [-80.871026311999969, 35.054417873000034],
            [-80.870616353999935, 35.054227902000036],
            [-80.870486661999962, 35.054168392000065],
            [-80.870156896999958, 35.054022309000061],
            [-80.869716946999972, 35.053832338000063],
            [-80.869433322999953, 35.053712544000064],
            [-80.868973376999975, 35.053522574000056],
            [-80.86855849799997, 35.053356764000057],
            [-80.868340303999958, 35.053273930000046],
            [-80.867750379999961, 35.053053965000061],
            [-80.86768333599997, 35.053029105000064],
            [-80.867522232999988, 35.052969699000073],
            [-80.867361622999965, 35.052909401000079],
            [-80.866661922999981, 35.052661584000077],
            [-80.866301972999963, 35.052541604000055],
            [-80.866118298999936, 35.052481363000027],
            [-80.865720692999957, 35.052357602000029],
            [-80.865520720999939, 35.052297611000029],
            [-80.865332272999979, 35.05224207200007],
            [-80.864952327999958, 35.052132090000043],
            [-80.864450424999973, 35.051993726000035],
            [-80.864418225999941, 35.05198531700006],
            [-80.864237986999967, 35.051938402000076],
            [-80.864057951999939, 35.051890961000026],
            [-80.864030489999948, 35.051883744000065],
            [-80.86291960899996, 35.051625256000079],
            [-80.862612432999981, 35.051567251000051],
            [-80.862409988999957, 35.05152358600003],
            [-80.862179727999944, 35.051475283000059],
            [-80.86126048999995, 35.051309124000056],
            [-80.860690586999965, 35.051219139000068],
            [-80.860480773999939, 35.051187090000042],
            [-80.86015112299998, 35.051141079000047],
            [-80.859771186999978, 35.051091087000032],
            [-80.858958446999964, 35.051000023000029],
            [-80.858762256999967, 35.050982762000046],
            [-80.858522298999958, 35.050962766000055],
            [-80.858386595999946, 35.050951892000057],
            [-80.857866686999955, 35.050911900000074],
            [-80.857047736999959, 35.050864675000071],
            [-80.856962455999962, 35.050861565000048],
            [-80.85638255899994, 35.050841568000067],
            [-80.856193140999949, 35.050835874000029],
            [-80.855803209999976, 35.050825876000033],
            [-80.85525321099999, 35.050818826000068],
            [-80.855110810999975, 35.050818827000057],
            [-80.853768568999953, 35.050792846000036],
            [-80.853353122999977, 35.05078882600003],
            [-80.852203326999984, 35.050788827000076],
            [-80.852019747999975, 35.050789612000074],
            [-80.851006000999973, 35.05079827600008],
            [-80.846182934999945, 35.050769219000074],
            [-80.846052775999965, 35.05076882700007],
            [-80.840938964999964, 35.050768828000059],
            [-80.839247225999941, 35.050760064000031],
            [-80.839069777999953, 35.050756121000063],
            [-80.839057779999962, 35.05075524800003],
            [-80.838650237999957, 35.050729516000047],
            [-80.838643090999938, 35.050729132000072],
            [-80.838312805999976, 35.05069422400004],
            [-80.83760966799997, 35.05057795700003],
            [-80.83351719999996, 35.049816570000075],
            [-80.832745559999978, 35.049687805000076],
            [-80.832364041999938, 35.049634943000058],
            [-80.831204239999977, 35.049484969000048],
            [-80.830443436999985, 35.04940048900005],
            [-80.830292354999983, 35.049386999000035],
            [-80.828892599999961, 35.049267020000059],
            [-80.827892888999941, 35.049204873000065],
            [-80.82773315999998, 35.049199286000032],
            [-80.826453385999969, 35.049159293000059],
            [-80.82545877299998, 35.049151278000068],
            [-80.82535414299997, 35.049153115000024],
            [-80.82485423199995, 35.049163114000066],
            [-80.824238320999939, 35.049184290000028],
            [-80.823418464999975, 35.049224283000058],
            [-80.823311761999946, 35.049229754000066],
            [-80.81984595199998, 35.049416092000058],
            [-80.819100034999963, 35.049438468000062],
            [-80.818579542999942, 35.049460415000055],
            [-80.817659704999983, 35.049510406000024],
            [-80.817469120999988, 35.04952161500006],
            [-80.816926645999956, 35.04955595000007],
            [-80.816771913999958, 35.049561381000046],
            [-80.815505573999985, 35.049631249000072],
            [-80.814971200999935, 35.049651542000049],
            [-80.814083263999976, 35.049679289000039],
            [-80.813959281999985, 35.049683522000066],
            [-80.813676803999954, 35.049693985000033],
            [-80.812533849999966, 35.049708827000075],
            [-80.810462548999965, 35.049708830000043],
            [-80.809642370999939, 35.04967752400006],
            [-80.808238792999987, 35.049613308000062],
            [-80.808022521999987, 35.049602495000045],
            [-80.807094718999963, 35.049544051000055],
            [-80.806568138999978, 35.049500769000076],
            [-80.806376981999961, 35.049485920000052],
            [-80.805825785999957, 35.049445590000062],
            [-80.805383714999948, 35.049402004000058],
            [-80.805267875999959, 35.049390903000074],
            [-80.804916623999986, 35.049366593000059],
            [-80.804513697999937, 35.049325385000031],
            [-80.803126332999966, 35.049163225000029],
            [-80.803038361999938, 35.049153128000057],
            [-80.801797374999978, 35.04901329900008],
            [-80.801205735999986, 35.048936463000075],
            [-80.800636903999987, 35.048870373000057],
            [-80.800164886999937, 35.048821962000034],
            [-80.799941284999989, 35.048792430000049],
            [-80.799672075999979, 35.048758623000026],
            [-80.795642769999972, 35.048278707000065],
            [-80.795576103999963, 35.048270873000035],
            [-80.790767265999989, 35.047713327000054],
            [-80.788667954999937, 35.047465768000052],
            [-80.788558328999954, 35.04745312700004],
            [-80.787976687999958, 35.047387591000074],
            [-80.787464762999946, 35.047323600000027],
            [-80.787288033999971, 35.047302261000027],
            [-80.781626888999938, 35.046642618000078],
            [-80.78115472099995, 35.046583597000051],
            [-80.780954657999985, 35.046559553000066],
            [-80.779227139999989, 35.046360225000058],
            [-80.777629458999968, 35.04616169600007],
            [-80.777272639999978, 35.046120415000075],
            [-80.776562376999948, 35.046044316000064],
            [-80.775771863999978, 35.045949799000027],
            [-80.775456851999934, 35.045914513000071],
            [-80.774786966999955, 35.045844525000064],
            [-80.774553203999972, 35.045821404000037],
            [-80.773883793999971, 35.045769520000079],
            [-80.773073936999936, 35.045719529000053],
            [-80.772880047999934, 35.045708442000034],
            [-80.772120180999934, 35.045668449000061],
            [-80.771832787999983, 35.045655257000078],
            [-80.771623792999947, 35.045648091000032],
            [-80.77094391299994, 35.045628094000051],
            [-80.76999862599996, 35.045621127000061]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 117,
        "SolutionID": "PI-12:D",
        "Shape_Length": 0.60439745373210407,
        "Shape_Area": 0.0085413733511310128
      }
    },
    {
      "type": "Feature",
      "id": 118,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.84705514999996, 35.230522354000072],
            [-80.845899642999939, 35.230571058000066],
            [-80.844750487999988, 35.230681657000048],
            [-80.843612605999965, 35.230853676000038],
            [-80.842490865999935, 35.231086380000079],
            [-80.841390068999942, 35.231378771000038],
            [-80.840314926999952, 35.231729600000051],
            [-80.839270040999963, 35.232137364000039],
            [-80.838259884999957, 35.232600318000038],
            [-80.837288782999963, 35.233116481000025],
            [-80.836360891999959, 35.233683643000063],
            [-80.835480183999948, 35.234299376000024],
            [-80.834650431999989, 35.234961046000024],
            [-80.83387518699999, 35.23566581800003],
            [-80.833157768999968, 35.236410679000073],
            [-80.832501251999986, 35.237192437000033],
            [-80.831908446999989, 35.238007746000051],
            [-80.831381893999946, 35.238853117000076],
            [-80.831054570999981, 35.239459601000078],
            [-80.827173266999978, 35.24711861000003],
            [-80.827042521999942, 35.247383935000073],
            [-80.826756096999986, 35.248026180000068],
            [-80.824769929999945, 35.252827197000045],
            [-80.824668691999989, 35.253079459000048],
            [-80.824487093999949, 35.253576871000064],
            [-80.823832097999968, 35.255495162000045],
            [-80.823698137999941, 35.255911135000076],
            [-80.823456067999984, 35.256839491000051],
            [-80.823288536999939, 35.257778845000075],
            [-80.823196262999943, 35.258725176000041],
            [-80.823179643999936, 35.259674430000075],
            [-80.823189845999934, 35.260129968000058],
            [-80.819142426999974, 35.261069018000057],
            [-80.81516963699994, 35.261817549000057],
            [-80.814526407999949, 35.261949184000059],
            [-80.812045563999959, 35.262497549000045],
            [-80.810821437999948, 35.262326514000051],
            [-80.810753963999957, 35.262317197000073],
            [-80.807603236999967, 35.261887196000032],
            [-80.806688704999942, 35.261782409000034],
            [-80.805534929999965, 35.261706559000061],
            [-80.804377577999958, 35.26169275400008],
            [-80.80322160299994, 35.261741051000058],
            [-80.802910011999984, 35.261764748000076],
            [-80.801516512999967, 35.261880920000067],
            [-80.800678448999975, 35.261967407000043],
            [-80.799540036999986, 35.262139010000055],
            [-80.798417739999934, 35.262371304000055],
            [-80.797915795999984, 35.262496286000044],
            [-80.795602380999981, 35.263103154000078],
            [-80.795308820999935, 35.263182483000037],
            [-80.790161817999945, 35.264614240000071],
            [-80.782236883999985, 35.266572017000044],
            [-80.781443815999978, 35.266784760000064],
            [-80.772772606999979, 35.269297578000078],
            [-80.77268098199994, 35.269324358000063],
            [-80.772524343999976, 35.269371224000054],
            [-80.770212618999949, 35.270072995000078],
            [-80.769293300999948, 35.270376328000054],
            [-80.768247563999978, 35.270783476000076],
            [-80.767236524999987, 35.271245834000069],
            [-80.766264511999964, 35.271761423000044],
            [-80.765335685999958, 35.272328037000079],
            [-80.764454021999939, 35.272943249000036],
            [-80.763623299999949, 35.273604427000066],
            [-80.762923857999965, 35.274234814000067],
            [-80.762011886999971, 35.27510686800008],
            [-80.761935097999981, 35.27518079400005],
            [-80.76180659399995, 35.275306789000069],
            [-80.759072610999965, 35.278018295000038],
            [-80.758482649999962, 35.278636715000061],
            [-80.758367823999947, 35.278765510000028],
            [-80.753597214999957, 35.28417819200007],
            [-80.753304334999939, 35.284520758000042],
            [-80.75058206999995, 35.287804405000031],
            [-80.750332105999973, 35.288114363000034],
            [-80.749738107999974, 35.288929263000057],
            [-80.749563463999948, 35.289194143000032],
            [-80.748324695999941, 35.291120418000048],
            [-80.747971586999938, 35.291700541000068],
            [-80.747512352999934, 35.292572030000031],
            [-80.747123612999985, 35.29346627700005],
            [-80.746826253999984, 35.294316808000076],
            [-80.745983440999964, 35.297038106000059],
            [-80.744608687999971, 35.300538804000041],
            [-80.744598949999954, 35.300563675000035],
            [-80.744416756999954, 35.301060961000076],
            [-80.743057904999944, 35.305025864000072],
            [-80.742712872999959, 35.30570894400006],
            [-80.742213611999944, 35.306455268000036],
            [-80.739818875999958, 35.309366965000038],
            [-80.735511702999986, 35.31391952000007],
            [-80.735269848999963, 35.314181194000071],
            [-80.734793927999988, 35.31473498500003],
            [-80.732978614999979, 35.316955596000071],
            [-80.732796440999948, 35.317183018000037],
            [-80.73220205399997, 35.317997828000046],
            [-80.731715709999946, 35.318770694000079],
            [-80.730592454999965, 35.320695114000046],
            [-80.730550690999962, 35.320767172000046],
            [-80.730171431999963, 35.321474417000047],
            [-80.728831540999977, 35.32416525800005],
            [-80.728751209999984, 35.324329431000024],
            [-80.728362111999957, 35.325223612000059],
            [-80.728332690999935, 35.325299646000076],
            [-80.727670797999963, 35.327026612000054],
            [-80.727383292999946, 35.32786370000008],
            [-80.727370398999938, 35.327906345000031],
            [-80.726504400999943, 35.330789210000034],
            [-80.725843061999967, 35.332574958000066],
            [-80.725696201999938, 35.332850061000045],
            [-80.724396443999979, 35.334407529000032],
            [-80.720848663999959, 35.338218321000056],
            [-80.712576813999988, 35.346949574000064],
            [-80.704414648999943, 35.355203535000044],
            [-80.700468997999963, 35.359124205000057],
            [-80.700236715999949, 35.359359973000039],
            [-80.698184578999985, 35.361487923000027],
            [-80.697848542999964, 35.361847771000043],
            [-80.697189712999943, 35.362628777000054],
            [-80.696876495999959, 35.363042439000026],
            [-80.694838096999945, 35.365838053000061],
            [-80.694556218999935, 35.36623901300004],
            [-80.694392180999955, 35.366486845000054],
            [-80.692586521999942, 35.369279162000055],
            [-80.692221723999978, 35.369876077000072],
            [-80.691761450999934, 35.370747342000072],
            [-80.691491984999971, 35.371345123000026],
            [-80.690159905999963, 35.374512454000069],
            [-80.690039635999938, 35.374808726000026],
            [-80.689916341999947, 35.375137050000035],
            [-80.687052540999957, 35.383096506000072],
            [-80.687034506999964, 35.383146958000054],
            [-80.683785784999941, 35.392296375000058],
            [-80.682516969999938, 35.395598858000028],
            [-80.682389784999941, 35.395853700000032],
            [-80.681947794999985, 35.396125449000067],
            [-80.68182924499996, 35.396200855000075],
            [-80.680836180999961, 35.396624787000064],
            [-80.677070931999936, 35.397840775000077],
            [-80.676515566999967, 35.398029093000048],
            [-80.676233399999944, 35.398131797000076],
            [-80.673573576999956, 35.399122704000035],
            [-80.672807880999983, 35.399426319000042],
            [-80.671794699999964, 35.399887868000064],
            [-80.671726796999963, 35.39992139800006],
            [-80.668958191999934, 35.401295363000031],
            [-80.66805190599996, 35.401776622000057],
            [-80.667120909999937, 35.402342468000029],
            [-80.666988946999936, 35.402429139000049],
            [-80.664344498999981, 35.404182951000053],
            [-80.663592611999945, 35.404710744000056],
            [-80.662759721999976, 35.405371213000024],
            [-80.661981361999949, 35.406074861000036],
            [-80.661260865999964, 35.406818677000047],
            [-80.660907944999963, 35.407222856000033],
            [-80.659201166999935, 35.409249947000035],
            [-80.658894525999983, 35.409626563000074],
            [-80.658298722999973, 35.410440995000044],
            [-80.657993512999951, 35.410911092000049],
            [-80.656012264999958, 35.41410247500005],
            [-80.654030140999964, 35.417198952000035],
            [-80.653901341999983, 35.41740410400007],
            [-80.65189739799996, 35.420659227000044],
            [-80.650024362999943, 35.421961932000045],
            [-80.648243612999977, 35.423007301000041],
            [-80.646541909999939, 35.423834208000073],
            [-80.645522828999958, 35.424208192000037],
            [-80.64521229099995, 35.424276233000057],
            [-80.643485829999975, 35.424388082000064],
            [-80.637810392999938, 35.424554255000032],
            [-80.636992677999956, 35.424593679000054],
            [-80.63595528899998, 35.424688655000068],
            [-80.631303369999955, 35.425228395000033],
            [-80.631188576999989, 35.425242025000045],
            [-80.630047520999938, 35.425412028000039],
            [-80.629130971999984, 35.425595128000055],
            [-80.62321226399996, 35.426914881000073],
            [-80.623003771999947, 35.426962487000026],
            [-80.622280505999981, 35.427145288000077],
            [-80.614164310999968, 35.429352701000028],
            [-80.613875176999954, 35.42943359800006],
            [-80.604977828999949, 35.431992885000057],
            [-80.597005690999936, 35.434159730000033],
            [-80.596624652999935, 35.434267232000025],
            [-80.59554577199998, 35.434615871000062],
            [-80.595058570999981, 35.434795779000069],
            [-80.592744349999975, 35.435684915000024],
            [-80.592182817999969, 35.435910630000024],
            [-80.591623666999965, 35.436155941000038],
            [-80.590121526999951, 35.436843540000041],
            [-80.589666575999956, 35.437059095000052],
            [-80.588691453999957, 35.437573241000052],
            [-80.587759494999943, 35.438138472000048],
            [-80.586874688999956, 35.438752370000032],
            [-80.586040826999977, 35.439412307000055],
            [-80.584779143999981, 35.440478347000067],
            [-80.58399977199997, 35.441181488000041],
            [-80.583278250999967, 35.441924835000066],
            [-80.58261767099998, 35.442705204000049],
            [-80.58260762599997, 35.442717956000024],
            [-80.581014714999981, 35.444742408000025],
            [-80.580485284999952, 35.445459969000069],
            [-80.576629353999977, 35.451044114000069],
            [-80.576571978999937, 35.451127842000062],
            [-80.576486619999969, 35.45125487100006],
            [-80.56900815299997, 35.462515133000068],
            [-80.568994550999946, 35.462535651000053],
            [-80.561288124999976, 35.474179979000041],
            [-80.561222476999944, 35.474280090000036],
            [-80.558011839999949, 35.479222213000071],
            [-80.55396766399997, 35.484183275000078],
            [-80.551369940999962, 35.487335705000078],
            [-80.551178082999968, 35.487573524000027],
            [-80.550915578999934, 35.487916364000057],
            [-80.548413401999937, 35.491287728000032],
            [-80.548078421999946, 35.491758768000068],
            [-80.547547270999985, 35.492602857000065],
            [-80.547084748999964, 35.493473548000054],
            [-80.547071121999977, 35.493501775000027],
            [-80.546285545999979, 35.495133784000075],
            [-80.545907247999935, 35.495999119000032],
            [-80.545587617999956, 35.496911729000033],
            [-80.545462965999945, 35.497345381000059],
            [-80.545027257999948, 35.498972925000032],
            [-80.544905936999953, 35.499467022000033],
            [-80.544734682999945, 35.500405941000054],
            [-80.544638885999973, 35.501352009000072],
            [-80.544636284999967, 35.501395933000026],
            [-80.544548677999956, 35.502923632000034],
            [-80.544531351999979, 35.503828872000042],
            [-80.544587384999943, 35.504777070000046],
            [-80.544719140999973, 35.505720241000063],
            [-80.544723440999974, 35.505743943000027],
            [-80.544983986999966, 35.507171798000059],
            [-80.545186609999973, 35.508082200000047],
            [-80.545190522999974, 35.50809703200008],
            [-80.545693489999962, 35.509998856000038],
            [-80.545970791999935, 35.510905059000038],
            [-80.546325084999978, 35.51180908300006],
            [-80.54675094199996, 35.512692224000034],
            [-80.547246540999936, 35.513550699000064],
            [-80.547527900999967, 35.51398139500003],
            [-80.549328895999963, 35.51662993900004],
            [-80.549610774999962, 35.517029371000035],
            [-80.549766169999941, 35.517237700000067],
            [-80.551566460999936, 35.519606125000053],
            [-80.552125234999949, 35.520674823000036],
            [-80.552153414999964, 35.520728420000069],
            [-80.553122902999974, 35.522562119000042],
            [-80.553538899999978, 35.523786976000054],
            [-80.553570688999969, 35.523879385000043],
            [-80.553830374999961, 35.524560726000061],
            [-80.554497109999943, 35.526169536000054],
            [-80.554774648999967, 35.527457881000032],
            [-80.554903051999986, 35.527994505000038],
            [-80.555010723999942, 35.528378051000061],
            [-80.555485464999947, 35.529969777000076],
            [-80.55585238499998, 35.531734841000059],
            [-80.556103089999965, 35.533188748000043],
            [-80.556211030999975, 35.53486153700004],
            [-80.556229253999959, 35.535811613000078],
            [-80.556124507999982, 35.536889346000066],
            [-80.55577591399998, 35.539407961000052],
            [-80.554915899999969, 35.544362395000064],
            [-80.554020772999934, 35.548343397000053],
            [-80.553507857999989, 35.549961281000037],
            [-80.552819081999985, 35.55151829600004],
            [-80.551727774999961, 35.553447153000036],
            [-80.550779548999969, 35.55505942700006],
            [-80.545822796999971, 35.561005936000072],
            [-80.545582279999962, 35.561302191000038],
            [-80.545131680999987, 35.561903853000047],
            [-80.543084871999952, 35.564792171000079],
            [-80.542937405999965, 35.565004351000027],
            [-80.542405720999966, 35.565848402000029],
            [-80.542373134999934, 35.565904891000059],
            [-80.540112558999965, 35.569845374000067],
            [-80.540088655999966, 35.569887211000037],
            [-80.538442133999979, 35.572780972000032],
            [-80.537738135999973, 35.573977287000048],
            [-80.537539626999944, 35.574211940000055],
            [-80.536107479999941, 35.575473702000068],
            [-80.518058182999937, 35.590200791000029],
            [-80.517859065999971, 35.590365830000053],
            [-80.517725907999989, 35.590479195000057],
            [-80.502114593999977, 35.603911742000037],
            [-80.482921992999934, 35.619678383000064],
            [-80.482771999999954, 35.619803059000049],
            [-80.482497229999979, 35.620039475000056],
            [-80.472871788999953, 35.628508589000035],
            [-80.463038432999952, 35.636134789000039],
            [-80.457177418999947, 35.640494352000076],
            [-80.457149419999951, 35.640515222000033],
            [-80.447910204999971, 35.647415531000036],
            [-80.447157697999955, 35.648011685000029],
            [-80.446375135999972, 35.648713905000079],
            [-80.445650504999946, 35.649456393000037],
            [-80.444986907999976, 35.65023597000004],
            [-80.444387186999961, 35.651049300000068],
            [-80.443853910999962, 35.651892899000075],
            [-80.443389366999952, 35.652763158000027],
            [-80.442995544999974, 35.653656349000073],
            [-80.442674132999969, 35.654568650000044],
            [-80.442426508999972, 35.655496153000058],
            [-80.442253737999977, 35.656434887000046],
            [-80.442156558999955, 35.657380835000026],
            [-80.44213539499998, 35.65832994200008],
            [-80.442190335999953, 35.659278148000055],
            [-80.442321150999987, 35.66022139100005],
            [-80.442527280999968, 35.661155631000042],
            [-80.442807848999962, 35.662076867000053],
            [-80.443161650999969, 35.662981155000068],
            [-80.443587177999973, 35.663864621000073],
            [-80.444082608999963, 35.664723482000056],
            [-80.444645822999973, 35.665554058000055],
            [-80.445274411999947, 35.666352793000044],
            [-80.44596568299994, 35.667116265000061],
            [-80.446716676999984, 35.667841204000069],
            [-80.447524179999959, 35.668524503000071],
            [-80.448384731999965, 35.669163237000078],
            [-80.449294650999946, 35.669754669000042],
            [-80.450250036999989, 35.670296266000037],
            [-80.451246798999989, 35.67078570700005],
            [-80.452280666999968, 35.671220896000079],
            [-80.453347212999972, 35.671599967000077],
            [-80.454441868999936, 35.67192129700004],
            [-80.455559942999969, 35.672183509000035],
            [-80.456696645999955, 35.672385482000038],
            [-80.457847108999943, 35.672526346000041],
            [-80.459006402999989, 35.672605502000067],
            [-80.460169558999951, 35.67262260800004],
            [-80.46133159499999, 35.672577593000028],
            [-80.46248753399999, 35.672470647000068],
            [-80.463632419999954, 35.67230223100006],
            [-80.464761348999957, 35.672073064000074],
            [-80.465869485999974, 35.671784130000049],
            [-80.466952082999967, 35.671436666000034],
            [-80.468004501999985, 35.671032161000028],
            [-80.469022232999976, 35.670572347000075],
            [-80.470000918999972, 35.670059194000032],
            [-80.470936366999979, 35.669494901000064],
            [-80.471824570999956, 35.668881886000065],
            [-80.471909205999964, 35.66881907800007],
            [-80.481134831999952, 35.661927438000077],
            [-80.487118097999939, 35.657475980000072],
            [-80.487387505999948, 35.657271316000049],
            [-80.497759834999954, 35.64922524900004],
            [-80.498270620999961, 35.648812591000024],
            [-80.498545442999955, 35.648576142000024],
            [-80.50834223399994, 35.639953951000052],
            [-80.527461811999956, 35.624243288000059],
            [-80.527611744999945, 35.624118557000031],
            [-80.527744943999949, 35.624005182000076],
            [-80.543331880999972, 35.61059007700004],
            [-80.561452675999988, 35.595800904000043],
            [-80.561651717999951, 35.595635794000032],
            [-80.56192316399995, 35.595401852000066],
            [-80.564450078999982, 35.593174930000032],
            [-80.564959653999949, 35.592705890000047],
            [-80.565682684999956, 35.591962679000062],
            [-80.566036881999935, 35.59155879900004],
            [-80.567749825999954, 35.589533109000058],
            [-80.568057571999987, 35.589156738000042],
            [-80.568655594999939, 35.588342785000066],
            [-80.569145108999976, 35.587570621000054],
            [-80.570502039999951, 35.585263256000076],
            [-80.570544081999969, 35.585191258000066],
            [-80.570600547999959, 35.585092918000043],
            [-80.572282840999947, 35.582134238000037],
            [-80.574204005999945, 35.578783114000032],
            [-80.575539522999975, 35.576897520000045],
            [-80.580879814999946, 35.570487999000079],
            [-80.581120229999954, 35.570191676000036],
            [-80.581717981999986, 35.569377655000039],
            [-80.582207259999961, 35.568605429000058],
            [-80.583789533999948, 35.565913317000025],
            [-80.583831550999946, 35.565841319000072],
            [-80.583902447999947, 35.565717464000045],
            [-80.585369736999951, 35.563122239000052],
            [-80.585761353999942, 35.562375274000033],
            [-80.585950762999971, 35.561966139000049],
            [-80.587180721999971, 35.559183163000057],
            [-80.587383104999958, 35.558698621000076],
            [-80.587670212999967, 35.55788949500004],
            [-80.588549874999956, 35.555111079000028],
            [-80.588582168999949, 35.555007499000055],
            [-80.588824474999967, 35.554094599000052],
            [-80.589898235999954, 35.549309999000059],
            [-80.589901575999988, 35.549295074000042],
            [-80.590014294999946, 35.54872591000003],
            [-80.590958110999964, 35.543275115000029],
            [-80.591016217999936, 35.542905307000069],
            [-80.591021401999967, 35.542868171000066],
            [-80.591430227999979, 35.539905064000038],
            [-80.591486765999946, 35.539423185000032],
            [-80.591680872999973, 35.537417002000041],
            [-80.591714446999958, 35.536989922000032],
            [-80.591733829999953, 35.53604075800007],
            [-80.591696403999947, 35.534133021000059],
            [-80.591673714999956, 35.533596332000059],
            [-80.591506502999948, 35.531022413000073],
            [-80.591472590999956, 35.530610933000048],
            [-80.591356878999989, 35.529761781000047],
            [-80.590961923999942, 35.527477078000061],
            [-80.590945297999951, 35.527383116000067],
            [-80.590884645999949, 35.527071526000043],
            [-80.590369297999985, 35.52459757400004],
            [-80.590222458999961, 35.52397514200004],
            [-80.590114625999945, 35.523591626000041],
            [-80.589626589999966, 35.52195766400007],
            [-80.589213155999971, 35.520042278000062],
            [-80.589084537999952, 35.519505689000027],
            [-80.588802802999965, 35.518584756000052],
            [-80.588542842999971, 35.517903489000048],
            [-80.587599639999951, 35.515629882000042],
            [-80.586989398999947, 35.513835287000063],
            [-80.586957575999975, 35.513742886000045],
            [-80.586602821999975, 35.512838981000073],
            [-80.586179059999949, 35.511960757000054],
            [-80.58473924599997, 35.509239573000059],
            [-80.583662393999987, 35.507181563000074],
            [-80.583631669999988, 35.507123196000066],
            [-80.583135724999977, 35.506264868000073],
            [-80.582572198999969, 35.505434899000079],
            [-80.582416742999953, 35.505226611000069],
            [-80.580975973999955, 35.503332149000073],
            [-80.581027953999978, 35.503262075000066],
            [-80.583413973999939, 35.500365278000061],
            [-80.583462751999946, 35.500305739000055],
            [-80.588020385999982, 35.494712287000027],
            [-80.588163380999958, 35.494533951000051],
            [-80.588760504999982, 35.493719880000071],
            [-80.588925121999978, 35.493472198000063],
            [-80.592516395999951, 35.487940948000073],
            [-80.600179819999937, 35.476354901000036],
            [-80.607578161999982, 35.465208892000078],
            [-80.610423884999989, 35.461085546000049],
            [-80.616300965999983, 35.459488022000073],
            [-80.616590203999976, 35.459407120000037],
            [-80.625490188999947, 35.456846996000024],
            [-80.633001638999986, 35.454804008000053],
            [-80.637379837999958, 35.453827751000063],
            [-80.640009192999969, 35.453522677000024],
            [-80.645137143999989, 35.45337250700004],
            [-80.64590157899994, 35.45333656400004],
            [-80.649628204999942, 35.453095117000032],
            [-80.649681769999972, 35.453091580000034],
            [-80.650834334999956, 35.452982838000025],
            [-80.651975733999961, 35.452812640000047],
            [-80.652813659999936, 35.452646811000079],
            [-80.656064202999971, 35.451934580000056],
            [-80.656351617999974, 35.451869475000024],
            [-80.657456066999941, 35.451578780000034],
            [-80.658534906999989, 35.451229591000072],
            [-80.658732683999972, 35.451158193000026],
            [-80.661973354999986, 35.449968863000038],
            [-80.662824161999936, 35.449634054000057],
            [-80.66377023299998, 35.449205690000042],
            [-80.667002921999938, 35.447634675000074],
            [-80.667070700999943, 35.447601567000049],
            [-80.66804544699994, 35.44708679300004],
            [-80.668284461999974, 35.446948897000027],
            [-80.671279915999946, 35.445190198000034],
            [-80.67197237299996, 35.444762230000038],
            [-80.67242668199998, 35.444456685000034],
            [-80.675645239999938, 35.442217725000035],
            [-80.676075134999962, 35.441908740000031],
            [-80.676908290999961, 35.441248185000063],
            [-80.678970886999934, 35.439502182000069],
            [-80.679749419999951, 35.438798430000077],
            [-80.68047003099997, 35.43805450900004],
            [-80.681129632999955, 35.437273607000066],
            [-80.681654998999988, 35.43656190400003],
            [-80.68278694299994, 35.434924147000061],
            [-80.682857344999945, 35.434821311000064],
            [-80.68319011899996, 35.434306459000027],
            [-80.685495130999982, 35.430559969000058],
            [-80.687462189999962, 35.427485173000036],
            [-80.687563306999948, 35.427324681000073],
            [-80.688758397999948, 35.42539847200004],
            [-80.689205833999949, 35.425176399000065],
            [-80.69049961199994, 35.424694374000069],
            [-80.694707774999983, 35.423335254000051],
            [-80.695263270999988, 35.423146854000038],
            [-80.696311334999962, 35.422740349000037],
            [-80.696397868999952, 35.422703653000042],
            [-80.699632862999977, 35.421322535000058],
            [-80.700559622999947, 35.420897453000066],
            [-80.701533836999943, 35.420382406000044],
            [-80.702311566999981, 35.41991489000003],
            [-80.703462578999961, 35.419182660000047],
            [-80.705530056999976, 35.417954143000031],
            [-80.706147658999953, 35.417569972000024],
            [-80.707031331999985, 35.416955202000054],
            [-80.707863980999946, 35.41629443100004],
            [-80.708642041999951, 35.415590490000056],
            [-80.708926459999986, 35.415308461000052],
            [-80.710638148999976, 35.41356683600003],
            [-80.71107385199997, 35.413104762000046],
            [-80.711732956999981, 35.412323690000051],
            [-80.712181491999957, 35.411721390000025],
            [-80.713539468999954, 35.409793882000031],
            [-80.713686208999945, 35.409581487000025],
            [-80.714215090999971, 35.408736656000031],
            [-80.714594893999958, 35.40802947800006],
            [-80.715601210999978, 35.40601150100008],
            [-80.715681651999944, 35.405847336000079],
            [-80.71607127599998, 35.404953209000041],
            [-80.716100738999955, 35.404877175000024],
            [-80.71764716499996, 35.400847787000032],
            [-80.717758943999968, 35.400545015000034],
            [-80.721049082999969, 35.391268059000026],
            [-80.723787738999988, 35.383647612000061],
            [-80.724539665999941, 35.381857998000044],
            [-80.725486691999947, 35.380392610000058],
            [-80.726699383999971, 35.37872859600003],
            [-80.727920822999977, 35.377461575000041],
            [-80.731796254999949, 35.373609396000063],
            [-80.731891429999962, 35.373513965000029],
            [-80.740215510999974, 35.365093406000028],
            [-80.74044360299996, 35.364857682000036],
            [-80.748872091999942, 35.355957899000032],
            [-80.748962162999987, 35.355861953000044],
            [-80.752832584999965, 35.351703052000062],
            [-80.752987240999971, 35.351534327000024],
            [-80.753370083999982, 35.351093289000062],
            [-80.755867683999952, 35.348099158000025],
            [-80.756143024999972, 35.347758880000072],
            [-80.756737388999966, 35.346943957000065],
            [-80.757265416999985, 35.34609892800006],
            [-80.757473224999956, 35.345723973000077],
            [-80.758704356999942, 35.343416070000046],
            [-80.758955966999963, 35.342919504000065],
            [-80.759344812999984, 35.342025228000068],
            [-80.759424874999979, 35.341814592000048],
            [-80.760525025999982, 35.338840641000047],
            [-80.760761567999964, 35.338138073000039],
            [-80.76077444699996, 35.338095424000073],
            [-80.761618774999988, 35.335280767000029],
            [-80.761892653999951, 35.334565409000049],
            [-80.762757212999986, 35.332827751000025],
            [-80.763104282999961, 35.332232736000037],
            [-80.763866389999976, 35.331300061000036],
            [-80.768157023999947, 35.326763296000024],
            [-80.768398808999962, 35.326501557000029],
            [-80.768845709999937, 35.32598284900007],
            [-80.772021602999985, 35.322119673000032],
            [-80.77223253599999, 35.321856977000039],
            [-80.772826548999944, 35.321041972000046],
            [-80.772864579999975, 35.320985391000079],
            [-80.774217523999937, 35.318961782000031],
            [-80.774707160999981, 35.318173254000044],
            [-80.775050814999986, 35.317536134000079],
            [-80.776166727999964, 35.31532513500008],
            [-80.776282153999944, 35.315090665000071],
            [-80.77667067699997, 35.314196328000037],
            [-80.776852702999975, 35.313698995000038],
            [-80.778404196999986, 35.309166527000059],
            [-80.779845961999968, 35.305491287000052],
            [-80.779855688999987, 35.305466413000033],
            [-80.780152742999974, 35.304615803000047],
            [-80.780579044999968, 35.303237513000056],
            [-80.782431949999989, 35.301001517000032],
            [-80.786714730999961, 35.296140406000063],
            [-80.786870274999956, 35.295986095000046],
            [-80.792842261999965, 35.294255358000044],
            [-80.800660992999951, 35.292323740000029],
            [-80.80123991399995, 35.292171753000048],
            [-80.805429180999965, 35.291006405000076],
            [-80.809197805999986, 35.29153289900006],
            [-80.810180172999935, 35.291646985000057],
            [-80.81133437699998, 35.291722789000062],
            [-80.812492156999951, 35.291736528000058],
            [-80.813648551999961, 35.291688143000044],
            [-80.814472388999945, 35.291615497000066],
            [-80.816802188999986, 35.291364656000042],
            [-80.817128408999963, 35.291326993000041],
            [-80.81826719299994, 35.291155225000068],
            [-80.819103117999987, 35.290988241000036],
            [-80.823415209999951, 35.290035129000046],
            [-80.827495795999937, 35.289266263000059],
            [-80.828349764999984, 35.289086864000069],
            [-80.834937897999964, 35.287558249000028],
            [-80.836147315999938, 35.287540822000039],
            [-80.838897411999938, 35.287653372000079],
            [-80.838949238999987, 35.287655430000029],
            [-80.840106964999961, 35.287668904000043],
            [-80.841263286999947, 35.287620256000025],
            [-80.842413249999936, 35.287509691000025],
            [-80.843551926999965, 35.287337685000068],
            [-80.844674438999959, 35.287104973000055],
            [-80.845775977999949, 35.28681255500004],
            [-80.846132806999947, 35.286703560000035],
            [-80.848674850999942, 35.285901401000046],
            [-80.84939385399997, 35.285659506000059],
            [-80.85043937599994, 35.285251659000039],
            [-80.850525691999962, 35.28521485400006],
            [-80.852369653999972, 35.28442328400007],
            [-80.85329405899995, 35.28399702400003],
            [-80.854265666999936, 35.283480737000048],
            [-80.855194000999973, 35.282913442000051],
            [-80.856075085999976, 35.282297570000026],
            [-80.856905145999974, 35.281635759000039],
            [-80.857680629999948, 35.280930844000068],
            [-80.858398214999966, 35.280185844000073],
            [-80.859054829999934, 35.279403951000063],
            [-80.859338728999944, 35.279028550000078],
            [-80.860467085999971, 35.277484474000062],
            [-80.860776009999938, 35.27704443500005],
            [-80.86130250399998, 35.276198942000065],
            [-80.861760426999979, 35.275327019000031],
            [-80.862147820999951, 35.274432402000059],
            [-80.862463027999979, 35.273518921000061],
            [-80.862704701999974, 35.272590490000027],
            [-80.862871807999966, 35.271651083000052],
            [-80.862963635999961, 35.270704724000041],
            [-80.862979792999965, 35.269755466000049],
            [-80.862944864999974, 35.268229262000034],
            [-80.862885287999973, 35.267281170000047],
            [-80.862750232999986, 35.266338303000055],
            [-80.862540281999941, 35.265404698000054],
            [-80.862256333999937, 35.264484354000047],
            [-80.861899610999956, 35.26358121100003],
            [-80.86147164099998, 35.262699134000059],
            [-80.860974257999942, 35.261841902000072],
            [-80.860929372999976, 35.261771129000067],
            [-80.859481488999961, 35.259503271000028],
            [-80.859407173999955, 35.259394898000039],
            [-80.860064181999974, 35.25780513400008],
            [-80.863716009999962, 35.250593215000038],
            [-80.863846639999963, 35.250327850000076],
            [-80.864233891999959, 35.24943322300004],
            [-80.864548978999949, 35.24851973300008],
            [-80.864790554999956, 35.247591293000028],
            [-80.864957585999946, 35.246651880000059],
            [-80.86504936099999, 35.245705515000054],
            [-80.86506548899996, 35.244756253000048],
            [-80.865005904999975, 35.243808159000025],
            [-80.864870862999965, 35.242865290000054],
            [-80.864660948999983, 35.241931685000054],
            [-80.864377058999935, 35.241011343000025],
            [-80.86402041499997, 35.240108202000044],
            [-80.86359254499996, 35.239226130000077],
            [-80.86309528299995, 35.238368902000047],
            [-80.86253075999997, 35.237540191000051],
            [-80.861901393999972, 35.236743542000056],
            [-80.861209882999958, 35.23598236600003],
            [-80.860459187999936, 35.235259921000079],
            [-80.859652522999966, 35.234579302000043],
            [-80.858793343999935, 35.23394342000006],
            [-80.857885328999942, 35.233354997000049],
            [-80.856932364999977, 35.232816553000077],
            [-80.855938532999971, 35.232330392000051],
            [-80.854908087999945, 35.231898595000075],
            [-80.85384543899994, 35.231523009000057],
            [-80.852755134999938, 35.23120524400008],
            [-80.851641844999961, 35.23094665800005],
            [-80.850510331999942, 35.230748358000028],
            [-80.849365438999939, 35.230611194000062],
            [-80.848212064999984, 35.230535751000048],
            [-80.84705514999996, 35.230522354000072]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 118,
        "SolutionID": "PI-12:G",
        "Shape_Length": 1.352579243892905,
        "Shape_Area": 0.021151421324416945
      }
    },
    {
      "type": "Feature",
      "id": 119,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.890524729999981, 35.178644536000036],
            [-80.891216027999974, 35.17940555000007],
            [-80.891966481999987, 35.180127810000045],
            [-80.892310084999963, 35.180428680000034],
            [-80.893466137999951, 35.181412523000063],
            [-80.89370989799994, 35.181612427000061],
            [-80.893972961999964, 35.182278398000051],
            [-80.894400861999941, 35.183160371000042],
            [-80.894898115999979, 35.184017477000054],
            [-80.895462597999938, 35.184846044000039],
            [-80.896091890999969, 35.185642525000048],
            [-80.896783300999971, 35.18640350700008],
            [-80.897533867999982, 35.187125731000037],
            [-80.89834037899999, 35.187806103000071],
            [-80.89919937999997, 35.188441709000074],
            [-80.899440115999937, 35.188605475000031],
            [-80.90012681099995, 35.189064259000077],
            [-80.903974314999971, 35.192011740000055],
            [-80.904170010999962, 35.192880719000073],
            [-80.904454182999984, 35.193800975000045],
            [-80.904811071999973, 35.194704003000027],
            [-80.905239153999958, 35.195585935000054],
            [-80.905736598999965, 35.196442995000041],
            [-80.906301276999955, 35.197271510000064],
            [-80.906930770999963, 35.198067933000061],
            [-80.907622387999936, 35.198828852000076],
            [-80.90837316599999, 35.199551008000071],
            [-80.909179889999962, 35.200231306000035],
            [-80.910039105999942, 35.200866834000067],
            [-80.910126178999974, 35.200923222000029],
            [-80.910125095999945, 35.200929127000052],
            [-80.909974257999977, 35.201933454000027],
            [-80.909951535999937, 35.20209191400005],
            [-80.909860330999948, 35.203038319000029],
            [-80.909844732999943, 35.203987593000079],
            [-80.909854795999934, 35.204416838000043],
            [-80.909914873999981, 35.205364917000054],
            [-80.910050373999979, 35.206307741000046],
            [-80.910260716999971, 35.207241272000033],
            [-80.910545006999939, 35.208161512000061],
            [-80.910902026999963, 35.209064520000027],
            [-80.91100503399997, 35.209276660000057],
            [-80.910821776999967, 35.209961143000044],
            [-80.910686378999969, 35.21051877900004],
            [-80.910529251999947, 35.211391559000049],
            [-80.910304651999979, 35.212969588000078],
            [-80.910295347999977, 35.213036290000048],
            [-80.910233952999988, 35.213576834000037],
            [-80.910026114999937, 35.21587026900005],
            [-80.909996294999985, 35.21627612900005],
            [-80.909980695999934, 35.217225401000064],
            [-80.910042764999957, 35.219872408000072],
            [-80.91010285699997, 35.220820484000058],
            [-80.910238384999957, 35.221763306000071],
            [-80.910448769999959, 35.222696834000033],
            [-80.910452736999957, 35.222711659000026],
            [-80.910835032999955, 35.224136964000024],
            [-80.910984541999937, 35.224649784000064],
            [-80.911051851999957, 35.22486342600007],
            [-80.911080403999961, 35.225313777000054],
            [-80.911215951999964, 35.226256597000031],
            [-80.911426358999961, 35.227190123000071],
            [-80.911484002999941, 35.227398719000064],
            [-80.911769072999959, 35.228396154000052],
            [-80.911995807999972, 35.229107791000047],
            [-80.912352934999944, 35.23001079200003],
            [-80.912550450999959, 35.230438399000036],
            [-80.913375500999962, 35.232142964000047],
            [-80.913606344999948, 35.232597256000076],
            [-80.914104102999943, 35.233454277000078],
            [-80.914669122999953, 35.234282748000055],
            [-80.915298984999936, 35.235079123000048],
            [-80.915661224999951, 35.235490336000055],
            [-80.91727285199994, 35.237253984000063],
            [-80.91760263499998, 35.237603633000049],
            [-80.918353858999978, 35.238325723000059],
            [-80.919161050999946, 35.239005950000035],
            [-80.92002075399995, 35.239641403000064],
            [-80.92010013099997, 35.239695997000069],
            [-80.921430073999943, 35.240605255000048],
            [-80.922259249999968, 35.241138608000028],
            [-80.923212741999976, 35.241676536000057],
            [-80.923228317999985, 35.241684712000051],
            [-80.924553256999957, 35.242379315000051],
            [-80.925532043999965, 35.242856737000068],
            [-80.925768438999967, 35.24295628200008],
            [-80.926138121999941, 35.243423534000044],
            [-80.926830302999974, 35.244184337000036],
            [-80.927581669999938, 35.244906369000034],
            [-80.928389003999939, 35.24558653400004],
            [-80.929248848999976, 35.246221921000028],
            [-80.930157522999934, 35.246809806000044],
            [-80.931111133999934, 35.247347672000046],
            [-80.932105597999964, 35.247833215000071],
            [-80.933136653999952, 35.248264353000025],
            [-80.934199886999977, 35.248639241000035],
            [-80.93529074099996, 35.248956272000044],
            [-80.936404542999981, 35.249214087000041],
            [-80.937536521999959, 35.24941158300004],
            [-80.938681826999982, 35.249547912000025],
            [-80.939835552999966, 35.249622493000061],
            [-80.940992753999979, 35.249635003000037],
            [-80.942148474999954, 35.24958539000005],
            [-80.943297763999965, 35.249473866000073],
            [-80.944435694999981, 35.249300911000034],
            [-80.945557393999934, 35.24906726200004],
            [-80.946658054999943, 35.248773924000034],
            [-80.947732962999964, 35.248422152000046],
            [-80.948777514999961, 35.248013452000066],
            [-80.949787231999949, 35.247549577000029],
            [-80.95075779299998, 35.247032512000033],
            [-80.951685038999983, 35.246464475000039],
            [-80.952564998999947, 35.245847897000033],
            [-80.953393902999949, 35.245185420000041],
            [-80.954168204999974, 35.244479884000043],
            [-80.954884585999935, 35.243734307000068],
            [-80.955539980999959, 35.242951887000061],
            [-80.956131582999944, 35.242135973000075],
            [-80.956656861999988, 35.24129006000004],
            [-80.957113567999954, 35.240417772000058],
            [-80.957499747999975, 35.239522845000067],
            [-80.957813749999957, 35.238609111000073],
            [-80.958054232999984, 35.237680484000066],
            [-80.958220167999968, 35.23674094100005],
            [-80.958310848999986, 35.235794504000069],
            [-80.95832588799999, 35.23484522900003],
            [-80.958265224999934, 35.233897179000053],
            [-80.958129119999967, 35.232954413000073],
            [-80.958098885999959, 35.232796812000061],
            [-80.957900423999945, 35.231798076000075],
            [-80.957719703999942, 35.231022233000033],
            [-80.957434800999977, 35.23010210700005],
            [-80.957077173999949, 35.229199240000071],
            [-80.956648353999981, 35.228317495000056],
            [-80.956150178999962, 35.227460649000079],
            [-80.955584784999985, 35.226632371000051],
            [-80.954954593999958, 35.225836205000064],
            [-80.954819480999959, 35.225679374000038],
            [-80.953832992999935, 35.224550227000066],
            [-80.953275832999964, 35.223946408000074],
            [-80.952524431999962, 35.223224534000053],
            [-80.951717117999976, 35.222544528000071],
            [-80.950857347999943, 35.221909298000071],
            [-80.950777967999954, 35.221854725000071],
            [-80.949448002999986, 35.220945786000073],
            [-80.949182997999969, 35.22076820500007],
            [-80.947588112999938, 35.219720380000069],
            [-80.947023983999941, 35.219364785000039],
            [-80.946070594999981, 35.218827042000044],
            [-80.945599087999938, 35.218596824000031],
            [-80.94535872299997, 35.217834947000028],
            [-80.94534691299998, 35.217340439000054],
            [-80.945465290999948, 35.216027896000071],
            [-80.945543878999956, 35.215474073000053],
            [-80.945834612999988, 35.214386406000074],
            [-80.945969776999959, 35.213828731000035],
            [-80.945989195999971, 35.213737832000049],
            [-80.946380610999938, 35.211870770000075],
            [-80.946527209999942, 35.211022139000079],
            [-80.946617997999965, 35.210075707000044],
            [-80.946633167999948, 35.209126429000037],
            [-80.946610562999979, 35.208180414000026],
            [-80.947376547999966, 35.207642814000053],
            [-80.948205105999989, 35.206980368000075],
            [-80.948979088999977, 35.206274861000054],
            [-80.949695181999971, 35.205529311000078],
            [-80.950350318999938, 35.204746913000065],
            [-80.950941696999962, 35.203931020000027],
            [-80.951466782999944, 35.20308512500003],
            [-80.951923329999943, 35.20221285100007],
            [-80.952309385999968, 35.201317935000077],
            [-80.952623298999981, 35.200404209000055],
            [-80.952863728999944, 35.199475587000052],
            [-80.953029645999948, 35.198536045000026],
            [-80.953120343999956, 35.197589607000054],
            [-80.953135436999958, 35.196640327000068],
            [-80.953074861999937, 35.195692269000062],
            [-80.952938881999955, 35.194749491000039],
            [-80.952728081999965, 35.193816033000076],
            [-80.952443365999954, 35.192895891000035],
            [-80.952118747999975, 35.192068107000068],
            [-80.951749591999942, 35.191215249000038],
            [-80.951716800999975, 35.191140145000077],
            [-80.951288237999961, 35.190258377000077],
            [-80.950790352999945, 35.189401503000056],
            [-80.950550265999937, 35.189033918000064],
            [-80.949736856999948, 35.187830379000047],
            [-80.949411877999978, 35.187369654000065],
            [-80.948782049999977, 35.186573451000072],
            [-80.948090153999942, 35.185812766000026],
            [-80.947339150999937, 35.185090856000045],
            [-80.947124285999962, 35.184900933000051],
            [-80.945968413999935, 35.183897350000052],
            [-80.944017086999963, 35.181810062000068],
            [-80.943804949999958, 35.181587653000065],
            [-80.943262649999951, 35.18105685300003],
            [-80.942281977999983, 35.180142227000033],
            [-80.94207335599998, 35.179951087000063],
            [-80.941266556999949, 35.179271007000068],
            [-80.940407324999967, 35.17863569900004],
            [-80.939499336999972, 35.17804788400008],
            [-80.938546480999946, 35.177510078000068],
            [-80.937552836999942, 35.177024584000037],
            [-80.936522658999934, 35.176593478000029],
            [-80.935460354999975, 35.176218606000077],
            [-80.934431574999962, 35.175919347000047],
            [-80.934251452999945, 35.175729165000064],
            [-80.934025419999955, 35.17550157900007],
            [-80.932954395999957, 35.174445189000039],
            [-80.932429654999964, 35.173950773000058],
            [-80.931622995999987, 35.173270628000068],
            [-80.930763902999956, 35.172635251000031],
            [-80.930141808999963, 35.172223958000075],
            [-80.929040002999955, 35.171528597000076],
            [-80.925864481999952, 35.16909654300008],
            [-80.925670854999964, 35.168697748000056],
            [-80.925173370999971, 35.16784076700003],
            [-80.92471007499995, 35.167152030000068],
            [-80.923898953999981, 35.166019856000048],
            [-80.923797586999967, 35.165880161000075],
            [-80.92316817599999, 35.165083822000042],
            [-80.922476700999937, 35.164322988000038],
            [-80.921934661999956, 35.163792098000044],
            [-80.921175285999936, 35.163083408000034],
            [-80.92115036499996, 35.162910079000028],
            [-80.92094001199996, 35.161976562000063],
            [-80.920816893999984, 35.161546544000032],
            [-80.920533631999945, 35.160620662000042],
            [-80.920372505999978, 35.160130457000037],
            [-80.920015595999985, 35.159227470000076],
            [-80.919587544999956, 35.158345586000053],
            [-80.919090186999938, 35.157488578000027],
            [-80.918525654999939, 35.156660118000048],
            [-80.917896365999979, 35.155863751000027],
            [-80.917674124999962, 35.155607657000076],
            [-80.917047529999934, 35.154901917000075],
            [-80.916578431999937, 35.154397144000029],
            [-80.915827996999951, 35.153675034000059],
            [-80.91502167699997, 35.152994776000071],
            [-80.91416292699995, 35.152359280000042],
            [-80.913255419999984, 35.151771266000026],
            [-80.913098417999947, 35.151677301000063],
            [-80.912038044999974, 35.151049971000077],
            [-80.911242682999955, 35.150605914000039],
            [-80.910249529999987, 35.150120193000078],
            [-80.909219835999977, 35.149688853000043],
            [-80.908158007999987, 35.149313740000025],
            [-80.907068592999963, 35.148996460000035],
            [-80.906032551999942, 35.148754028000042],
            [-80.90506940399996, 35.148554512000032],
            [-80.90499310499996, 35.148538855000027],
            [-80.903862606999951, 35.148341051000045],
            [-80.902718780999976, 35.148204390000046],
            [-80.901566523999975, 35.14812945500006],
            [-80.900410768999961, 35.148116568000034],
            [-80.899256461999983, 35.148165782000035],
            [-80.898108540999942, 35.148276890000034],
            [-80.896971921999977, 35.148449412000048],
            [-80.896953882999981, 35.148452663000057],
            [-80.895218477999947, 35.148766162000072],
            [-80.894142760999955, 35.148990526000034],
            [-80.893585943999938, 35.148861269000065],
            [-80.89245546799998, 35.148663358000078],
            [-80.89131165699996, 35.148526590000074],
            [-80.890159405999952, 35.148451546000047],
            [-80.889003647999971, 35.148438549000048],
            [-80.88787130999998, 35.148456244000045],
            [-80.886716990999957, 35.148505339000053],
            [-80.885569047999979, 35.14861632700007],
            [-80.884432396999955, 35.148788731000025],
            [-80.884414357999958, 35.148791981000045],
            [-80.883112763999975, 35.149026972000058],
            [-80.882010301999969, 35.149256796000031],
            [-80.880910751999977, 35.149549551000064],
            [-80.879836858999965, 35.149900735000074],
            [-80.879120917999956, 35.150180700000078],
            [-80.878976637999983, 35.150129081000046],
            [-80.87837172899998, 35.149923516000058],
            [-80.877282421999951, 35.14960596800006],
            [-80.876170167999987, 35.14934760500006],
            [-80.876149639999937, 35.149343437000027],
            [-80.873523362999947, 35.148811641000066],
            [-80.872765776999984, 35.148674305000043],
            [-80.869938057999946, 35.147539452000046],
            [-80.869695835999948, 35.147444103000055],
            [-80.86922962999995, 35.147270877000039],
            [-80.86483687499998, 35.145701107000036],
            [-80.864587063999977, 35.145390312000075],
            [-80.863896301999944, 35.144629142000042],
            [-80.863146423999979, 35.143906704000074],
            [-80.862340640999946, 35.143226094000056],
            [-80.861482403999958, 35.142590222000024],
            [-80.860575385999937, 35.142001812000046],
            [-80.860418466999988, 35.141907781000043],
            [-80.859005348999972, 35.141070721000062],
            [-80.858210369999938, 35.140626313000041],
            [-80.857217653999953, 35.14014015500004],
            [-80.856188366999959, 35.139708363000068],
            [-80.855126912999935, 35.139332784000032],
            [-80.854037837999954, 35.139015026000038],
            [-80.852925801999959, 35.138756448000038],
            [-80.851911886999972, 35.138575648000028],
            [-80.849269908999986, 35.138170848000073],
            [-80.844260934999966, 35.137239862000058],
            [-80.843815967999944, 35.137162083000078],
            [-80.842904798999939, 35.137012857000059],
            [-80.836673512999937, 35.134764426000061],
            [-80.836026026999946, 35.134543260000044],
            [-80.83555131899999, 35.134396544000026],
            [-80.830026183999962, 35.132762744000047],
            [-80.829411978999985, 35.132591501000036],
            [-80.828300107999951, 35.132332697000038],
            [-80.827170020999972, 35.132134176000079],
            [-80.826026550999984, 35.131996789000027],
            [-80.825074259999951, 35.131929779000075],
            [-80.823940536999942, 35.13187533200005],
            [-80.823740871999973, 35.131866673000047],
            [-80.822585359999948, 35.131853042000046],
            [-80.821431235999967, 35.131901513000059],
            [-80.820830041999955, 35.13195151900004],
            [-80.81909150499996, 35.132120840000027],
            [-80.818597821999958, 35.132174726000073],
            [-80.813065913999935, 35.132843731000037],
            [-80.802920645999961, 35.133538436000038],
            [-80.802911834999975, 35.133539041000063],
            [-80.801763988999937, 35.133649233000028],
            [-80.800627369999972, 35.133820850000063],
            [-80.799506842999961, 35.134053159000075],
            [-80.798763662999988, 35.134243327000036],
            [-80.797118427999976, 35.134697208000034],
            [-80.796761962999938, 35.134799039000029],
            [-80.795687901999941, 35.135149476000038],
            [-80.794644029999972, 35.135556861000055],
            [-80.793634817999987, 35.136019449000059],
            [-80.792664583999965, 35.136535261000063],
            [-80.792064007999954, 35.13689355200006],
            [-80.789829447999978, 35.138286435000055],
            [-80.789788079999937, 35.138312300000052],
            [-80.786866088999943, 35.140144705000068],
            [-80.786580906999973, 35.14032736300004],
            [-80.785700830999986, 35.14094274200005],
            [-80.784871624999937, 35.141604079000047],
            [-80.784096836999936, 35.14230854200008],
            [-80.783968578999975, 35.142434561000073],
            [-80.782945302999963, 35.143451592000076],
            [-80.782356495999977, 35.144070143000079],
            [-80.78224189599996, 35.144198964000054],
            [-80.780721559999961, 35.145927783000047],
            [-80.780210634999946, 35.146450145000074],
            [-80.777814645999968, 35.147951034000073],
            [-80.777066761999947, 35.148361654000041],
            [-80.776808236999955, 35.148506328000053],
            [-80.775880887999961, 35.149073033000036],
            [-80.775000637999938, 35.149688334000075],
            [-80.774171255999988, 35.150349597000059],
            [-80.773396291999973, 35.151053991000026],
            [-80.772679066999956, 35.151798502000076],
            [-80.772022651999976, 35.152579941000056],
            [-80.771429856999987, 35.153394964000029],
            [-80.770903223999937, 35.154240082000058],
            [-80.770445008999957, 35.155111678000026],
            [-80.770057175999966, 35.156006018000028],
            [-80.769741388999989, 35.156919274000074],
            [-80.76949899899995, 35.157847536000077],
            [-80.769331049999948, 35.158786830000054],
            [-80.769238260999941, 35.159733134000078],
            [-80.769221033999941, 35.160682394000048],
            [-80.769279443999949, 35.161630547000073],
            [-80.769413244999953, 35.162573534000046],
            [-80.769621864999976, 35.163507315000061],
            [-80.769904414999985, 35.16442789000007],
            [-80.770259683999939, 35.165331318000028],
            [-80.770604703999936, 35.166045199000052],
            [-80.769965161999949, 35.16645391000003],
            [-80.769812483999942, 35.166552592000073],
            [-80.769265315999974, 35.166925276000029],
            [-80.767205348999937, 35.16838672800003],
            [-80.766872019999937, 35.168629294000027],
            [-80.766378494999969, 35.169012128000077],
            [-80.764750965999951, 35.170324084000072],
            [-80.764414839999972, 35.170602450000047],
            [-80.763639599999976, 35.171306779000076],
            [-80.762922106999952, 35.172051230000079],
            [-80.762265434999961, 35.172832614000072],
            [-80.762255449999941, 35.172845384000027],
            [-80.761407330999987, 35.173931232000029],
            [-80.760824260999982, 35.17473343100005],
            [-80.76029737999994, 35.175578500000029],
            [-80.75983893199998, 35.176450053000053],
            [-80.759450880999964, 35.177344356000049],
            [-80.759134891999963, 35.178257582000072],
            [-80.759065493999969, 35.178494865000062],
            [-80.758791859999974, 35.17946635800007],
            [-80.758580690999963, 35.179966438000065],
            [-80.757785726999941, 35.181253919000028],
            [-80.757135309999967, 35.182199635000075],
            [-80.757065222999984, 35.18230251500006],
            [-80.756538253999963, 35.183147567000049],
            [-80.756079722999971, 35.184019105000061],
            [-80.755691594999973, 35.184913395000024],
            [-80.755375533999938, 35.185826610000049],
            [-80.755132894999974, 35.186754839000059],
            [-80.754964720999965, 35.187694109000063],
            [-80.75487173199997, 35.188640398000075],
            [-80.754854331999979, 35.189589653000041],
            [-80.754912594999951, 35.190537809000034],
            [-80.755046277999952, 35.191480808000051],
            [-80.755254805999982, 35.192414609000025],
            [-80.755537293999964, 35.193335214000058],
            [-80.755892529999983, 35.194238681000058],
            [-80.756318997999983, 35.195121140000026],
            [-80.756814872999939, 35.195978811000032],
            [-80.757378032999952, 35.196808021000038],
            [-80.758006067999986, 35.197605218000035],
            [-80.758696288999943, 35.198366988000032],
            [-80.759445741999969, 35.199090067000043],
            [-80.760251217999951, 35.199771358000078],
            [-80.761109265999949, 35.200407943000073],
            [-80.76201621599995, 35.200997095000048],
            [-80.762968178999984, 35.201536289000046],
            [-80.763961079999945, 35.202023216000043],
            [-80.76499066599996, 35.202455790000045],
            [-80.766052525999953, 35.202832157000046],
            [-80.767142110999941, 35.203150705000041],
            [-80.768254752999951, 35.203410069000029],
            [-80.769385685999964, 35.203609140000026],
            [-80.770530064999946, 35.203747061000058],
            [-80.771682986999963, 35.203823245000024],
            [-80.772839509999983, 35.203837363000048],
            [-80.773994681999966, 35.203789356000073],
            [-80.775143552999964, 35.203679429000033],
            [-80.776281199999971, 35.203508053000064],
            [-80.777402748999975, 35.203275962000077],
            [-80.778503395999962, 35.202984152000056],
            [-80.779578423999965, 35.202633871000046],
            [-80.780623229999946, 35.202226621000079],
            [-80.78163333699996, 35.201764146000073],
            [-80.782604417999949, 35.201248427000053],
            [-80.783532312999967, 35.200681675000055],
            [-80.784413050999945, 35.200066316000061],
            [-80.785242854999979, 35.199404987000037],
            [-80.78601817599997, 35.19870052300007],
            [-80.786735692999969, 35.197955938000064],
            [-80.787392331999968, 35.197174423000035],
            [-80.787915224999949, 35.196462224000072],
            [-80.788760081999953, 35.19523311100005],
            [-80.788830140999949, 35.195130212000038],
            [-80.789133789999937, 35.194659766000029],
            [-80.789641077999988, 35.193837688000031],
            [-80.79008050799996, 35.193819364000035],
            [-80.79098793899999, 35.193737609000038],
            [-80.792466389999959, 35.193572570000072],
            [-80.792707666999945, 35.19354424200003],
            [-80.79384513499997, 35.193372701000044],
            [-80.794966493999937, 35.193140447000076],
            [-80.796066940999935, 35.192848476000052],
            [-80.797141760999978, 35.192498039000043],
            [-80.798186348999934, 35.192090636000046],
            [-80.79919622999995, 35.19162801300007],
            [-80.799263915999973, 35.191594407000025],
            [-80.800846751999984, 35.190804521000075],
            [-80.802479517999984, 35.190103647000058],
            [-80.804844752999941, 35.189328341000078],
            [-80.805349809999939, 35.189155289000041],
            [-80.806394314999977, 35.188747815000056],
            [-80.807404107999957, 35.18828512400006],
            [-80.808374864999962, 35.18776919700008],
            [-80.809302427999967, 35.187202246000027],
            [-80.81018282499997, 35.186586698000042],
            [-80.811012282999968, 35.18592519200007],
            [-80.811787252999977, 35.185220561000051],
            [-80.812504414999978, 35.184475821000035],
            [-80.81316069899998, 35.183694165000077],
            [-80.813753296999948, 35.18287894100007],
            [-80.814279669999962, 35.182033640000043],
            [-80.814737567999941, 35.181161882000026],
            [-80.81512502999999, 35.180267401000037],
            [-80.815440398999954, 35.17935403000007],
            [-80.815682329999959, 35.178425679000043],
            [-80.815849784999955, 35.177486323000039],
            [-80.815942052999958, 35.17653998600008],
            [-80.815958738999939, 35.175590721000049],
            [-80.815899775999981, 35.174642592000055],
            [-80.815765417999955, 35.173699658000032],
            [-80.815556243999936, 35.17276595900006],
            [-80.81527314899995, 35.171845492000045],
            [-80.815131335999979, 35.171460814000056],
            [-80.813836701999946, 35.168116624000049],
            [-80.813798593999934, 35.168019367000056],
            [-80.812782934999973, 35.165458210000054],
            [-80.812607087999936, 35.165036845000031],
            [-80.812601871999959, 35.165024963000064],
            [-80.811307799999952, 35.162080615000036],
            [-80.816556718999948, 35.161721060000048],
            [-80.816565532999959, 35.161720454000033],
            [-80.817660812999975, 35.161616590000051],
            [-80.821672624999962, 35.161131462000071],
            [-80.82301674699994, 35.161528918000045],
            [-80.830458345999944, 35.164214125000058],
            [-80.831106052999985, 35.164435321000042],
            [-80.832195378999984, 35.164753284000028],
            [-80.833307703999935, 35.165012051000076],
            [-80.834108320999974, 35.165159144000029],
            [-80.836684331999948, 35.165581057000054],
            [-80.839019076999989, 35.166015031000029],
            [-80.839626291999934, 35.166528017000076],
            [-80.840602416999957, 35.167300393000062],
            [-80.841460750999943, 35.167936411000028],
            [-80.842367918999969, 35.168524962000049],
            [-80.843320034999977, 35.169063526000059],
            [-80.844313019999959, 35.169549796000069],
            [-80.844346505999965, 35.169564958000024],
            [-80.848485242999971, 35.171434046000059],
            [-80.84948140299997, 35.171850741000071],
            [-80.849947706999956, 35.172024043000079],
            [-80.854774347999978, 35.173748995000039],
            [-80.858030252999981, 35.175055764000035],
            [-80.858272542999941, 35.175151137000057],
            [-80.858841499999983, 35.175360753000064],
            [-80.860598841999945, 35.17597782100006],
            [-80.861091826999939, 35.176143747000026],
            [-80.862181426999939, 35.176461440000026],
            [-80.863294006999979, 35.176719931000036],
            [-80.864406606999978, 35.176915424000072],
            [-80.865606112999956, 35.177093224000032],
            [-80.866236386999958, 35.177220853000051],
            [-80.868618587999947, 35.178073157000028],
            [-80.86966408699999, 35.178507116000048],
            [-80.870120696999948, 35.178689919000078],
            [-80.871182731999966, 35.179065364000053],
            [-80.872272409999937, 35.179382966000048],
            [-80.873385061999954, 35.179641364000076],
            [-80.874515920999954, 35.179839452000067],
            [-80.875660142999948, 35.179976379000038],
            [-80.875902477999944, 35.179997370000024],
            [-80.877387100999954, 35.18011745900003],
            [-80.87829745099998, 35.180171639000037],
            [-80.879453656999942, 35.180184738000037],
            [-80.880608429999938, 35.180135712000038],
            [-80.881301310999959, 35.180076271000075],
            [-80.882953604999955, 35.179907413000024],
            [-80.883409114999949, 35.179855907000046],
            [-80.884546200999978, 35.179683513000043],
            [-80.885667112999954, 35.179450417000055],
            [-80.886767049999946, 35.179157621000058],
            [-80.886891331999948, 35.179120373000046],
            [-80.888882187999968, 35.178516745000024],
            [-80.889634177999937, 35.17827253400003],
            [-80.890102593999984, 35.178110139000069],
            [-80.890524729999981, 35.178644536000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 119,
        "SolutionID": "PI-12:E",
        "Shape_Length": 0.60464965205877041,
        "Shape_Area": 0.0091302544878878299
      }
    },
    {
      "type": "Feature",
      "id": 120,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842817545999935, 35.212734983000075],
            [-80.841662288999942, 35.212783647000037],
            [-80.840513378999958, 35.212894206000044],
            [-80.839375735999965, 35.213066185000059],
            [-80.838254228999972, 35.213298851000047],
            [-80.837153656999988, 35.213591204000068],
            [-80.836078731999976, 35.213941996000074],
            [-80.835034052999958, 35.214349725000034],
            [-80.834024093999972, 35.214812645000052],
            [-80.833053176999954, 35.215328776000035],
            [-80.83212545899994, 35.215895906000071],
            [-80.831244910999942, 35.216511611000044],
            [-80.830415305999963, 35.217173253000055],
            [-80.829640192999989, 35.217878001000031],
            [-80.828922892999969, 35.218622838000044],
            [-80.828266478999979, 35.219404575000056],
            [-80.827673760999971, 35.220219866000036],
            [-80.827147279999963, 35.22106522100006],
            [-80.82668929099998, 35.221937021000031],
            [-80.826301756999953, 35.222831532000043],
            [-80.825986340999975, 35.223744926000052],
            [-80.825744392999979, 35.224673292000034],
            [-80.825576954999974, 35.225612654000031],
            [-80.825484743999937, 35.226558992000037],
            [-80.825468157999978, 35.227508251000074],
            [-80.825527270999942, 35.228456368000025],
            [-80.82566183299997, 35.229399283000078],
            [-80.825871267999958, 35.23033295700003],
            [-80.826154685999938, 35.231253391000052],
            [-80.826510870999982, 35.232156645000032],
            [-80.826938301999974, 35.233038849000025],
            [-80.827435150999975, 35.23389622600007],
            [-80.827999291999959, 35.234725102000027],
            [-80.828628309999942, 35.235521927000036],
            [-80.829319512999973, 35.236283289000028],
            [-80.829953003999947, 35.236899460000075],
            [-80.833807376999971, 35.240443528000071],
            [-80.833924324999941, 35.240549990000034],
            [-80.834536411999977, 35.241074682000033],
            [-80.83738017099995, 35.243393207000054],
            [-80.83757460399994, 35.243549300000041],
            [-80.838313853999978, 35.244101514000079],
            [-80.840796054999942, 35.245852924000076],
            [-80.840915923999944, 35.245936741000037],
            [-80.841823957999964, 35.246525289000033],
            [-80.842135866999968, 35.246709925000062],
            [-80.842344683999954, 35.246830723000073],
            [-80.842638972999964, 35.247424334000073],
            [-80.843136074999961, 35.248281644000031],
            [-80.843700475999981, 35.249110445000042],
            [-80.844329758999947, 35.249907187000076],
            [-80.845021229999986, 35.250668457000074],
            [-80.845093694999946, 35.250742516000059],
            [-80.845718782999938, 35.25137707600004],
            [-80.846397028999945, 35.252025553000067],
            [-80.847203755999942, 35.252706258000046],
            [-80.848063041999978, 35.253342221000025],
            [-80.848971209999945, 35.253930715000024],
            [-80.849924366999971, 35.254469220000033],
            [-80.850355308999951, 35.254688906000069],
            [-80.854250555999954, 35.256611533000068],
            [-80.856046568999943, 35.259723485000052],
            [-80.856318829999964, 35.260174787000039],
            [-80.856883446999973, 35.261003524000046],
            [-80.857039142999952, 35.261211475000039],
            [-80.857578810999939, 35.261918852000065],
            [-80.85805262699995, 35.262507571000071],
            [-80.858744333999937, 35.263268761000063],
            [-80.859425729999941, 35.263928212000053],
            [-80.860584205999942, 35.264983898000025],
            [-80.860653749999983, 35.265046899000026],
            [-80.861460724999972, 35.265727508000055],
            [-80.861658157999955, 35.265881088000071],
            [-80.864589148999983, 35.268126080000059],
            [-80.86476059599994, 35.268255669000041],
            [-80.865080516999967, 35.268494304000058],
            [-80.86527689199994, 35.268738996000025],
            [-80.865532015999975, 35.269048152000039],
            [-80.865700636999975, 35.26924346900006],
            [-80.866976636999937, 35.270695895000074],
            [-80.869640007999976, 35.274185615000079],
            [-80.870113986999968, 35.274774291000028],
            [-80.870436255999948, 35.275141759000064],
            [-80.872701124999935, 35.277640968000071],
            [-80.873946001999968, 35.279271781000034],
            [-80.87442004199994, 35.279860439000061],
            [-80.874782274999973, 35.280271771000059],
            [-80.876393902999951, 35.282035950000079],
            [-80.876723690999938, 35.282385708000049],
            [-80.877474965999966, 35.283108046000052],
            [-80.878282259999935, 35.283788541000035],
            [-80.879101186999947, 35.284395864000032],
            [-80.880609207999953, 35.285445937000077],
            [-80.880650139999943, 35.285474351000062],
            [-80.881558900999948, 35.286062598000058],
            [-80.882512650999956, 35.286600843000031],
            [-80.883248003999938, 35.286967008000033],
            [-80.885012911999979, 35.287798331000033],
            [-80.885272217999955, 35.287918102000049],
            [-80.886144553999941, 35.28828311500007],
            [-80.888542236999967, 35.29021005900006],
            [-80.888656576999949, 35.290301122000074],
            [-80.889516582999988, 35.290936786000032],
            [-80.889958932999946, 35.291232946000036],
            [-80.892440353999973, 35.292840203000026],
            [-80.892906908999976, 35.293132213000035],
            [-80.893860816999961, 35.293670368000051],
            [-80.894855623999945, 35.294156213000065],
            [-80.895887069999958, 35.294587666000041],
            [-80.896750347999955, 35.294897318000039],
            [-80.897981991999984, 35.295307412000057],
            [-80.898182381999959, 35.295372970000074],
            [-80.899273721999975, 35.295690323000031],
            [-80.900388049999947, 35.295948467000073],
            [-80.901326636999954, 35.296116909000034],
            [-80.902991282999949, 35.296377019000033],
            [-80.903185237999935, 35.296406407000063],
            [-80.904331146999937, 35.296543060000033],
            [-80.90548550799997, 35.296617968000078],
            [-80.90580937499999, 35.296627821000072],
            [-80.915067465999982, 35.296839565000028],
            [-80.915591067999969, 35.296845164000047],
            [-80.920566645999941, 35.296837904000029],
            [-80.920877042999962, 35.296835213000065],
            [-80.922273221999944, 35.296813053000051],
            [-80.923429623999937, 35.296763620000036],
            [-80.924579603999973, 35.296652275000042],
            [-80.925718234999977, 35.296479497000064],
            [-80.926840637999987, 35.296246023000037],
            [-80.927942004999977, 35.295952858000078],
            [-80.928342889999954, 35.295829609000066],
            [-80.930942014999971, 35.295000951000077],
            [-80.931124662999935, 35.294941757000061],
            [-80.933175482999957, 35.29426627600003],
            [-80.933840823999958, 35.294537302000037],
            [-80.934198630999958, 35.294678969000074],
            [-80.934452263999958, 35.294774530000041],
            [-80.936794114999941, 35.295638566000036],
            [-80.938768658999948, 35.296624861000055],
            [-80.939428799999973, 35.296938795000074],
            [-80.940460516999963, 35.297369868000033],
            [-80.941524424999955, 35.29774468800008],
            [-80.941558903999976, 35.297755719000065],
            [-80.943054311999958, 35.29823269700006],
            [-80.944111386999964, 35.298538615000041],
            [-80.945225894999965, 35.298796346000074],
            [-80.946340369999973, 35.298991083000033],
            [-80.94787170099994, 35.299216651000052],
            [-80.950587522999967, 35.29979316400005],
            [-80.954299533999972, 35.300982833000035],
            [-80.957639069999971, 35.302211623000062],
            [-80.96038344599998, 35.303618321000044],
            [-80.962484269999948, 35.304882140000075],
            [-80.963398555999959, 35.305496227000049],
            [-80.963784152999949, 35.305909965000069],
            [-80.963797388999978, 35.305923523000047],
            [-80.96469254699997, 35.306839373000059],
            [-80.965431591999959, 35.307547603000046],
            [-80.966239864999977, 35.308227508000073],
            [-80.966805702999977, 35.308654321000063],
            [-80.967872012999976, 35.309424233000072],
            [-80.968076622999945, 35.309569700000054],
            [-80.96887593799994, 35.310129243000063],
            [-80.968966297999941, 35.310192069000038],
            [-80.96987597399999, 35.310779650000029],
            [-80.970830596999974, 35.311317197000051],
            [-80.971826078999982, 35.311802408000062],
            [-80.972352275999981, 35.312030757000059],
            [-80.97367530799994, 35.312581736000027],
            [-80.974181194999971, 35.312784177000026],
            [-80.97524545999994, 35.313158700000031],
            [-80.975592847999962, 35.313266627000075],
            [-80.980782297999951, 35.314827895000064],
            [-80.981526815999985, 35.31503659200007],
            [-80.982641668999975, 35.315293979000046],
            [-80.983774678999964, 35.31549104000004],
            [-80.984920991999957, 35.315626930000064],
            [-80.98607569699999, 35.315701069000056],
            [-80.987233844999935, 35.315713137000046],
            [-80.98839047599995, 35.315663083000061],
            [-80.989540633999979, 35.315551121000055],
            [-80.990679390999958, 35.315377733000048],
            [-80.991801867999982, 35.315143659000057],
            [-80.992903255999977, 35.314849903000038],
            [-80.993978837999975, 35.314497724000034],
            [-80.995024004999948, 35.314088630000072],
            [-80.996034278999957, 35.313624374000028],
            [-80.996823275999986, 35.313203956000052],
            [-80.99750394299997, 35.313249858000063],
            [-80.998153159999958, 35.313283803000047],
            [-80.999311274999968, 35.31329575500007],
            [-81.000467863999972, 35.31324558600005],
            [-81.000617515999977, 35.313234535000049],
            [-81.002970481999967, 35.313052503000051],
            [-81.003970932999948, 35.312951458000043],
            [-81.005109621999964, 35.312777934000053],
            [-81.005364056999952, 35.312730438000074],
            [-81.007308034999937, 35.312355155000034],
            [-81.009962928999983, 35.311971395000057],
            [-81.011172363999947, 35.31188510800007],
            [-81.011889697999948, 35.311974257000031],
            [-81.012137816999939, 35.312037499000041],
            [-81.012147486999936, 35.312040577000062],
            [-81.014224350999939, 35.313519132000067],
            [-81.025152595999941, 35.321777087000044],
            [-81.031458326999939, 35.326675911000052],
            [-81.035646032999978, 35.330759830000034],
            [-81.036131157999989, 35.331213409000043],
            [-81.036304479999956, 35.331366589000027],
            [-81.041764390999958, 35.336122442000033],
            [-81.044396619999986, 35.338692112000047],
            [-81.044889383999987, 35.339153013000043],
            [-81.045155311999963, 35.339386188000049],
            [-81.047119227999985, 35.341070638000076],
            [-81.047281249999969, 35.341207783000073],
            [-81.048708789999978, 35.342400299000076],
            [-81.049090123999974, 35.342709336000041],
            [-81.049951937999936, 35.343343853000079],
            [-81.050738781999939, 35.343855591000079],
            [-81.053577838999956, 35.345596690000036],
            [-81.053701631999957, 35.345671917000061],
            [-81.053815893999968, 35.34574015000004],
            [-81.058515819999968, 35.348523088000036],
            [-81.058757070999945, 35.348663429000055],
            [-81.063872887999935, 35.351586835000035],
            [-81.06982643799995, 35.355103288000066],
            [-81.071142349999946, 35.356169589000046],
            [-81.072315511999989, 35.35730561500003],
            [-81.074741878999987, 35.360724006000055],
            [-81.076595328999986, 35.363407701000028],
            [-81.07686070699998, 35.363778979000074],
            [-81.07749315999996, 35.364574490000052],
            [-81.07818778799998, 35.36533441000006],
            [-81.078758448999963, 35.365888894000079],
            [-81.080188229999976, 35.367209641000045],
            [-81.080613780999954, 35.367615279000063],
            [-81.08118220099999, 35.368322646000024],
            [-81.081566560999988, 35.369370335000042],
            [-81.081680658999971, 35.369729273000075],
            [-81.081679040999973, 35.369742044000077],
            [-81.081601987999989, 35.370594138000058],
            [-81.08159968199999, 35.370638072000077],
            [-81.081541649999963, 35.371784153000078],
            [-81.081530330999954, 35.37268948600007],
            [-81.08159253499997, 35.373637450000047],
            [-81.081655167999941, 35.374131189000025],
            [-81.081760827999972, 35.374845050000033],
            [-81.081835966999961, 35.375293914000054],
            [-81.082048718999943, 35.376227116000052],
            [-81.082052722999947, 35.376241925000045],
            [-81.082342239999946, 35.377310467000029],
            [-81.08262506799997, 35.37821546400005],
            [-81.082984744999976, 35.379117936000057],
            [-81.083415729999956, 35.379999207000026],
            [-81.08376932699997, 35.380618728000059],
            [-81.083856840999943, 35.380763087000048],
            [-81.083816986999977, 35.380990371000053],
            [-81.083727604999979, 35.381936875000065],
            [-81.083714002999955, 35.382886140000039],
            [-81.083745177999958, 35.384102254000027],
            [-81.083807415999956, 35.385050214000046],
            [-81.083809137999936, 35.385066375000065],
            [-81.083924000999957, 35.386137914000074],
            [-81.084060098999942, 35.38706435000006],
            [-81.084272907999946, 35.387997546000065],
            [-81.084559798999976, 35.388917346000028],
            [-81.084919544999934, 35.389819809000073],
            [-81.085350607999942, 35.39070107200007],
            [-81.085646127999951, 35.391223803000059],
            [-81.086194155999976, 35.392144750000057],
            [-81.086399176999976, 35.392478306000044],
            [-81.086967056999981, 35.393305947000044],
            [-81.087599838999949, 35.394101401000057],
            [-81.088294812999948, 35.394861258000049],
            [-81.088332450999985, 35.394899586000065],
            [-81.089858098999969, 35.396447778000038],
            [-81.090574679999975, 35.397130449000031],
            [-81.090751241999953, 35.397286154000028],
            [-81.095758105999948, 35.401636884000027],
            [-81.096288236999953, 35.402078247000077],
            [-81.101375217999987, 35.406136133000075],
            [-81.103507492999938, 35.408039762000044],
            [-81.104428044999963, 35.409553488000029],
            [-81.107480754999983, 35.415686125000036],
            [-81.111156968999978, 35.423174993000032],
            [-81.111375841999973, 35.423601072000054],
            [-81.111876855999981, 35.424457246000031],
            [-81.112445218999937, 35.425284762000047],
            [-81.112520362999987, 35.425385320000032],
            [-81.113425956999947, 35.426586187000055],
            [-81.113681573999941, 35.42691516800005],
            [-81.114133453999955, 35.427479830000038],
            [-81.114435992999972, 35.427845600000069],
            [-81.115131506999944, 35.428605298000036],
            [-81.115886250999949, 35.429326134000064],
            [-81.116696992999948, 35.430005017000042],
            [-81.117469650999965, 35.430576328000029],
            [-81.11827118399998, 35.431134870000051],
            [-81.118361793999952, 35.431197584000074],
            [-81.119273904999943, 35.431784027000049],
            [-81.120230936999974, 35.432320383000047],
            [-81.121228792999943, 35.432804353000051],
            [-81.122263197999985, 35.433233864000044],
            [-81.122403510999959, 35.43328686600006],
            [-81.129887788999952, 35.436081338000065],
            [-81.130082198999958, 35.436154442000031],
            [-81.131524845999934, 35.437649264000072],
            [-81.137710452999954, 35.444126237000035],
            [-81.137926278999942, 35.444394229000068],
            [-81.138109480999958, 35.44539627000006],
            [-81.138119916999983, 35.445452595000063],
            [-81.138171177999936, 35.445710183000074],
            [-81.139070969999977, 35.449988366000071],
            [-81.139233306999984, 35.45066386600007],
            [-81.139521041999956, 35.451583522000078],
            [-81.139634170999955, 35.451888871000051],
            [-81.140291381999987, 35.453594895000037],
            [-81.140538896999942, 35.454191833000039],
            [-81.140970904999961, 35.455072886000039],
            [-81.141472423999971, 35.455928932000063],
            [-81.142041308999978, 35.456756303000077],
            [-81.142526815999986, 35.457375325000044],
            [-81.143520640999952, 35.458574414000054],
            [-81.143668953999963, 35.458750546000033],
            [-81.144365005999987, 35.459510071000068],
            [-81.145120295999959, 35.460230720000027],
            [-81.145700817999966, 35.460725268000033],
            [-81.147966891999943, 35.462566510000045],
            [-81.14894116399995, 35.46339613300006],
            [-81.152764310999942, 35.467845607000072],
            [-81.153181884999981, 35.468311550000067],
            [-81.160745848999966, 35.476407330000029],
            [-81.160772125999983, 35.476435381000044],
            [-81.165994973999943, 35.481996084000059],
            [-81.166227210999978, 35.482237970000028],
            [-81.166711641999939, 35.482710707000024],
            [-81.169928957999957, 35.485717916000056],
            [-81.171710780999945, 35.487390934000075],
            [-81.172008706999975, 35.48766364100004],
            [-81.172820499999943, 35.488342140000043],
            [-81.173893036999971, 35.489181741000039],
            [-81.17475737999996, 35.489815350000072],
            [-81.175276706999966, 35.490158269000062],
            [-81.176701070999968, 35.49106310600007],
            [-81.177094928999963, 35.491306193000071],
            [-81.178053043999967, 35.49184208500003],
            [-81.179051975999982, 35.492325573000073],
            [-81.180087445999959, 35.492754585000057],
            [-81.18115501699998, 35.493127284000025],
            [-81.182250116999967, 35.493442071000061],
            [-81.182575856999961, 35.493523214000049],
            [-81.183291345999976, 35.493695321000075],
            [-81.185050235999938, 35.494354066000028],
            [-81.186010028999988, 35.494685984000057],
            [-81.186945935999972, 35.494959024000025],
            [-81.188708822999956, 35.49542821700004],
            [-81.188868056999979, 35.495469919000072],
            [-81.189986042999976, 35.495725386000061],
            [-81.191122071999985, 35.495920500000068],
            [-81.192271276999975, 35.496054422000043],
            [-81.193428734999941, 35.496126581000055],
            [-81.194589484999938, 35.496136665000051],
            [-81.195551659999978, 35.496119263000026],
            [-81.196385509999971, 35.496081826000079],
            [-81.197665318999952, 35.496345768000026],
            [-81.197792169999957, 35.496371522000061],
            [-81.19786933499995, 35.496386793000056],
            [-81.199540967999951, 35.496714349000058],
            [-81.200599867999983, 35.496894104000035],
            [-81.201749101999951, 35.497027936000052],
            [-81.202593181999987, 35.497086638000042],
            [-81.203644778999944, 35.497139117000074],
            [-81.203958179999972, 35.497152480000068],
            [-81.20511894699996, 35.497162464000041],
            [-81.206278024999961, 35.497110332000034],
            [-81.207430450999937, 35.496996306000028],
            [-81.208571284999948, 35.496820876000072],
            [-81.20969563999995, 35.496584792000078],
            [-81.210527056999979, 35.49636799600006],
            [-81.212958672999946, 35.495679574000064],
            [-81.213230309999972, 35.495600639000031],
            [-81.214307326999972, 35.495246518000044],
            [-81.215329419999989, 35.494845872000042],
            [-81.217985688999988, 35.493717875000073],
            [-81.225988543999961, 35.493093103000035],
            [-81.227011264999987, 35.492988622000041],
            [-81.227135772999986, 35.492972515000076],
            [-81.229738442999974, 35.49262802100003],
            [-81.231468029999974, 35.492487181000058],
            [-81.232420403999981, 35.492388266000034],
            [-81.233561118999944, 35.492212601000062],
            [-81.234685336999974, 35.491976285000078],
            [-81.235788241999956, 35.491680333000033],
            [-81.23686510899995, 35.491326012000059],
            [-81.23791132499997, 35.490914838000037],
            [-81.238922407999951, 35.490448575000073],
            [-81.239894025999945, 35.489929219000032],
            [-81.240822019999939, 35.489358996000078],
            [-81.241702412999985, 35.488740349000068],
            [-81.242531433999943, 35.488075927000068],
            [-81.24330553599998, 35.48736857800003],
            [-81.244021402999977, 35.486621329000059],
            [-81.244675968999957, 35.485837384000035],
            [-81.245266432999983, 35.485020099000053],
            [-81.245790267999951, 35.484172976000025],
            [-81.246245232999968, 35.483299643000066],
            [-81.246629379999945, 35.482403840000075],
            [-81.246941065999977, 35.481489403000069],
            [-81.247178960999975, 35.480560251000043],
            [-81.247342046999961, 35.479620360000069],
            [-81.247429627999963, 35.478673757000024],
            [-81.247441332999983, 35.477724495000075],
            [-81.24737711299997, 35.476776640000026],
            [-81.247237245999941, 35.475834248000069],
            [-81.247022333999951, 35.474901357000078],
            [-81.24673329899997, 35.47398196100005],
            [-81.246371382999939, 35.473079995000035],
            [-81.246273418999976, 35.472865640000066],
            [-81.245343584999944, 35.470878926000069],
            [-81.245008317999975, 35.470212605000029],
            [-81.244505618999938, 35.469356990000051],
            [-81.244341708999968, 35.469105943000045],
            [-81.242461417999948, 35.46629267600008],
            [-81.242055337999943, 35.46571682900003],
            [-81.241420485999981, 35.464922195000042],
            [-81.240723480999975, 35.464163228000075],
            [-81.239967305999983, 35.463443175000066],
            [-81.239155199999971, 35.462765121000075],
            [-81.238290642999971, 35.462131967000062],
            [-81.237377332999984, 35.461546423000073],
            [-81.236419183999942, 35.461010995000038],
            [-81.235420295999972, 35.460527976000037],
            [-81.234384944999988, 35.460099433000039],
            [-81.233317562999957, 35.459727200000032],
            [-81.232222719999982, 35.459412870000051],
            [-81.231105100999969, 35.459157790000063],
            [-81.229969490999963, 35.458963048000044],
            [-81.228820748999965, 35.458829482000056],
            [-81.227663790999941, 35.45875766000006],
            [-81.226503569999977, 35.458747892000076],
            [-81.225345051999966, 35.458800217000032],
            [-81.224193192999962, 35.458914415000038],
            [-81.22305292599998, 35.459089993000077],
            [-81.221929128999989, 35.459326203000046],
            [-81.220826612999986, 35.459622031000038],
            [-81.219750095999984, 35.459976213000061],
            [-81.218704185999968, 35.460387233000063],
            [-81.217693361999977, 35.460853331000067],
            [-81.216721948999975, 35.461372513000072],
            [-81.215794106999965, 35.461942555000064],
            [-81.21491380599997, 35.462561019000077],
            [-81.214084819999982, 35.463225256000044],
            [-81.213310692999983, 35.463932423000074],
            [-81.212594743999944, 35.464679494000052],
            [-81.212305022999942, 35.465026334000072],
            [-81.21193058099999, 35.465057725000065],
            [-81.211032465999949, 35.465152024000076],
            [-81.209892082999943, 35.465327478000063],
            [-81.20884440399999, 35.465545507000058],
            [-81.207192818999943, 35.465933406000033],
            [-81.207116575999976, 35.465951464000057],
            [-81.206013915999961, 35.466247157000055],
            [-81.204937245999986, 35.466601207000053],
            [-81.203915457999983, 35.467001768000046],
            [-81.202761454999973, 35.467491826000071],
            [-81.20161070599994, 35.467278543000077],
            [-81.20076538699999, 35.467139446000033],
            [-81.199616576999972, 35.467005604000065],
            [-81.19845952899999, 35.466933504000053],
            [-81.197299194999971, 35.466923456000075],
            [-81.197025728999961, 35.466928408000058],
            [-81.196915714999989, 35.466887206000024],
            [-81.196658163999984, 35.466645450000044],
            [-81.196631533999948, 35.466620509000052],
            [-81.193772942999942, 35.463949425000067],
            [-81.188906926999948, 35.458770494000078],
            [-81.181569410999941, 35.450919992000024],
            [-81.177168288999951, 35.445799874000045],
            [-81.17649225699995, 35.445064096000067],
            [-81.175736828999959, 35.444343640000056],
            [-81.175447986999984, 35.444091828000069],
            [-81.17345028699998, 35.442391197000063],
            [-81.173144638999986, 35.440940870000077],
            [-81.172559055999955, 35.43774539900005],
            [-81.172548596999945, 35.437689077000073],
            [-81.172334643999989, 35.436756046000028],
            [-81.172046589999979, 35.435836464000033],
            [-81.171685672999956, 35.434934268000063],
            [-81.171339281999963, 35.434215644000062],
            [-81.170417456999985, 35.432442941000033],
            [-81.170331617999977, 35.43228061700006],
            [-81.169829939999943, 35.431424686000071],
            [-81.169260949999966, 35.430597440000042],
            [-81.16890491099997, 35.430136983000068],
            [-81.166915329999938, 35.427667662000033],
            [-81.166637519999938, 35.427333095000051],
            [-81.166080386999965, 35.426717140000051],
            [-81.159424813999976, 35.419750592000071],
            [-81.159368251999979, 35.41969169500004],
            [-81.156727738999962, 35.416956594000055],
            [-81.156289805999961, 35.416424702000029],
            [-81.15611209299999, 35.416212927000061],
            [-81.155416301999935, 35.415453462000073],
            [-81.154661335999947, 35.41473287000008],
            [-81.153850429999977, 35.414054239000052],
            [-81.152987055999972, 35.413420470000062],
            [-81.152074907999975, 35.412834277000059],
            [-81.15111789599996, 35.412298171000032],
            [-81.150120112999957, 35.411814444000072],
            [-81.14917402399999, 35.411419092000074],
            [-81.147937465999973, 35.410940029000074],
            [-81.147849276999978, 35.410906104000048],
            [-81.147752867999941, 35.410869571000035],
            [-81.144662484999969, 35.409707619000073],
            [-81.144618602999969, 35.40969117800006],
            [-81.14231244299998, 35.408830230000035],
            [-81.140347702999975, 35.404830926000045],
            [-81.140315036999937, 35.404764898000053],
            [-81.136992770999939, 35.398096099000043],
            [-81.136806491999948, 35.397736094000038],
            [-81.136445247999973, 35.397104935000073],
            [-81.134161561999974, 35.393352046000075],
            [-81.134021706999988, 35.393127126000024],
            [-81.133453349999968, 35.392299705000028],
            [-81.132820132999939, 35.391504491000035],
            [-81.132124770999951, 35.390744888000029],
            [-81.131504858999961, 35.390145909000069],
            [-81.129893054999968, 35.388671310000063],
            [-81.129758442999957, 35.388549547000025],
            [-81.129746197999964, 35.388538608000033],
            [-81.127151095999977, 35.386222428000053],
            [-81.126456538999946, 35.385636485000077],
            [-81.121283091999942, 35.381510647000027],
            [-81.11962310499996, 35.380068651000045],
            [-81.119647749999956, 35.379073981000033],
            [-81.119647358999941, 35.378469904000042],
            [-81.119584707999934, 35.37752196100007],
            [-81.119575409999982, 35.377437327000052],
            [-81.119461953999974, 35.376437356000054],
            [-81.119333039999958, 35.375579431000062],
            [-81.119119860999945, 35.374646294000058],
            [-81.11883262899994, 35.373726574000045],
            [-81.118472577999967, 35.372824208000054],
            [-81.118041250999966, 35.371943059000046],
            [-81.117687430999979, 35.371323638000035],
            [-81.117179138999973, 35.370485801000029],
            [-81.117207419999943, 35.37017392100006],
            [-81.117266811999968, 35.369099358000028],
            [-81.117267696999988, 35.369083140000043],
            [-81.117280904999973, 35.368133870000065],
            [-81.117218288999936, 35.367185923000079],
            [-81.117080118999979, 35.366243360000055],
            [-81.116866990999938, 35.365310217000058],
            [-81.11671191399995, 35.364782849000051],
            [-81.115760420999948, 35.361793593000073],
            [-81.11562833399995, 35.361401233000038],
            [-81.115582368999981, 35.361273980000078],
            [-81.114642121999964, 35.358713929000032],
            [-81.114328143999956, 35.357938802000035],
            [-81.11389694099995, 35.357057636000036],
            [-81.113645962999954, 35.356610452000041],
            [-81.112731852999957, 35.355051890000027],
            [-81.112482220999937, 35.354642894000051],
            [-81.111914350999939, 35.353815366000049],
            [-81.111558970999965, 35.353354729000046],
            [-81.109573007999984, 35.350884429000075],
            [-81.109295698999972, 35.350549728000033],
            [-81.108600906999982, 35.34978998400004],
            [-81.108211514999937, 35.349405757000056],
            [-81.10695760599998, 35.348210908000055],
            [-81.106776250999985, 35.348040788000048],
            [-81.106496618999984, 35.347782562000077],
            [-81.105512612999973, 35.346358523000049],
            [-81.105408158999978, 35.34620942500004],
            [-81.102144768999949, 35.341614148000076],
            [-81.101983788999974, 35.341392024000072],
            [-81.101351275999946, 35.340596637000033],
            [-81.100656643999969, 35.339836846000026],
            [-81.100354968999966, 35.339536767000027],
            [-81.097667585999943, 35.33693530100004],
            [-81.097215510999945, 35.336514426000065],
            [-81.096601010999962, 35.33599107200007],
            [-81.093746635999935, 35.333678686000042],
            [-81.093551498999943, 35.333523009000032],
            [-81.092689449999966, 35.332888800000035],
            [-81.091778639999973, 35.332302145000028],
            [-81.09164943899998, 35.332225124000047],
            [-81.084466609999936, 35.32798327900008],
            [-81.084240301999955, 35.327851813000052],
            [-81.079132793999975, 35.324933568000063],
            [-81.074673150999956, 35.322293294000076],
            [-81.073035459999971, 35.321289123000042],
            [-81.072636604999957, 35.320956015000036],
            [-81.071146072999966, 35.319677932000047],
            [-81.068556905999969, 35.31715108700007],
            [-81.068064140999979, 35.316690280000046],
            [-81.067890789999979, 35.316537145000041],
            [-81.062426645999949, 35.311778950000075],
            [-81.057911437999962, 35.307376991000069],
            [-81.057426333999956, 35.306923497000071],
            [-81.056617312999947, 35.306244201000027],
            [-81.056536753999978, 35.306181241000047],
            [-81.04941288699996, 35.300648138000042],
            [-81.049232496999934, 35.300509959000067],
            [-81.038020544999938, 35.292039462000048],
            [-81.037628625999957, 35.29175203300008],
            [-81.033896025999979, 35.289095210000028],
            [-81.033687659999941, 35.288949194000054],
            [-81.033446364999975, 35.28878570300003],
            [-81.032292455999936, 35.288017830000058],
            [-81.031623870999965, 35.287594211000055],
            [-81.030669123999985, 35.287057141000048],
            [-81.029673593999973, 35.286572412000055],
            [-81.028641541999946, 35.286142099000074],
            [-81.027577383999983, 35.285768044000065],
            [-81.026918031999969, 35.285569211000052],
            [-81.025562450999985, 35.285185285000068],
            [-81.024377064999953, 35.28480806400006],
            [-81.023319878999985, 35.284502850000024],
            [-81.023227055999939, 35.284478963000026],
            [-81.021118935999937, 35.283941635000076],
            [-81.020097211999939, 35.283708492000073],
            [-81.018964556999947, 35.283511769000029],
            [-81.018517402999976, 35.283451392000075],
            [-81.01597843199994, 35.283135863000041],
            [-81.015595686999973, 35.283091794000029],
            [-81.013845479999986, 35.282906221000076],
            [-81.013529467999945, 35.282875079000064],
            [-81.012375202999976, 35.28280120200003],
            [-81.011217519999946, 35.28278937400006],
            [-81.01006136999996, 35.28283964600007],
            [-81.010041658999967, 35.282841044000065],
            [-81.006120937999981, 35.283120788000076],
            [-81.004990970999984, 35.283231511000054],
            [-81.004582112999969, 35.283286579000048],
            [-81.000668384999983, 35.283852302000071],
            [-80.999938955999937, 35.283970734000036],
            [-80.999684607999939, 35.284018219000075],
            [-80.99866480899999, 35.284215096000025],
            [-80.996995702999982, 35.284102506000067],
            [-80.996346719999963, 35.284068555000033],
            [-80.995680337999943, 35.284054070000025],
            [-80.992537720999962, 35.284034272000042],
            [-80.99204640399995, 35.284036764000064],
            [-80.990890224999987, 35.28408685200003],
            [-80.990288010999961, 35.284137700000031],
            [-80.988546578999944, 35.284309454000038],
            [-80.987999077999973, 35.284370574000036],
            [-80.98782136799997, 35.284397639000076],
            [-80.987749528999984, 35.284344642000065],
            [-80.987562385999979, 35.284217205000061],
            [-80.984545414999957, 35.282191246000025],
            [-80.983823083999937, 35.281731216000026],
            [-80.983799531999978, 35.281717025000034],
            [-80.980787969999938, 35.279905588000076],
            [-80.979857199999969, 35.279382309000027],
            [-80.97969982799998, 35.27930071000003],
            [-80.975369436999983, 35.277081298000041],
            [-80.97453171899997, 35.276677719000077],
            [-80.973500083999966, 35.276246935000074],
            [-80.973233845999971, 35.276146808000078],
            [-80.968390764999981, 35.274364913000056],
            [-80.96759326199998, 35.274090468000054],
            [-80.96758734499997, 35.27408857100005],
            [-80.962486254999988, 35.272453773000052],
            [-80.961400896999976, 35.27213888600005],
            [-80.960478949999981, 35.271921165000037],
            [-80.956093187999954, 35.270990184000027],
            [-80.955900944999939, 35.270950314000061],
            [-80.955108567999957, 35.270811876000039],
            [-80.95426226099994, 35.270389169000055],
            [-80.953602277999948, 35.270075315000042],
            [-80.952570836999939, 35.269644352000057],
            [-80.952317260999962, 35.26954882800004],
            [-80.949280041999941, 35.268428325000059],
            [-80.946245904999955, 35.267192407000039],
            [-80.945888196999988, 35.26705077500003],
            [-80.944824669999946, 35.266675991000056],
            [-80.944262195999954, 35.266504381000061],
            [-80.941450415999952, 35.265690714000073],
            [-80.940921770999978, 35.265545368000062],
            [-80.939807730999974, 35.265287596000064],
            [-80.938675526999987, 35.265090121000071],
            [-80.937530002999949, 35.264953792000028],
            [-80.937484705999964, 35.264949685000033],
            [-80.934773874999962, 35.264706785000044],
            [-80.933665235999968, 35.264636265000036],
            [-80.932507821999934, 35.264623685000061],
            [-80.931405299999938, 35.264669543000025],
            [-80.930011370999978, 35.264763349000077],
            [-80.929957952999985, 35.264767010000071],
            [-80.928808429999947, 35.264878407000026],
            [-80.92767024799997, 35.265051215000028],
            [-80.926548277999984, 35.265284697000027],
            [-80.925447320999979, 35.265577852000035],
            [-80.925090687999955, 35.265687081000067],
            [-80.923185198999988, 35.266289910000069],
            [-80.922958499999936, 35.266363102000071],
            [-80.918517822999945, 35.267825749000053],
            [-80.91579074699996, 35.267829708000079],
            [-80.911112691999961, 35.267722794000065],
            [-80.908415821999938, 35.265555772000027],
            [-80.908301494999989, 35.265464727000051],
            [-80.907441610999967, 35.264829193000025],
            [-80.906532898999956, 35.264241139000035],
            [-80.905579252999985, 35.263703081000074],
            [-80.904584753999984, 35.26321732200006],
            [-80.903553659999943, 35.262785941000061],
            [-80.902934481999978, 35.262565969000036],
            [-80.90231238299998, 35.261751377000053],
            [-80.901838246999944, 35.261162827000078],
            [-80.901515890999974, 35.260795441000027],
            [-80.899250607999988, 35.258296765000068],
            [-80.896655600999964, 35.254898207000053],
            [-80.89618154499999, 35.254309633000048],
            [-80.89601288199998, 35.254114359000027],
            [-80.89461812899998, 35.252527407000059],
            [-80.893745368999987, 35.251440377000051],
            [-80.893490192999934, 35.251131279000049],
            [-80.892798263999964, 35.250370286000077],
            [-80.89257214099996, 35.25014262600007],
            [-80.891857824999988, 35.249438130000044],
            [-80.89133284899998, 35.248943540000027],
            [-80.890525790999959, 35.248263128000076],
            [-80.890156900999955, 35.247980041000062],
            [-80.888645163999968, 35.246852675000071],
            [-80.887047950999943, 35.245629585000074],
            [-80.885201309999957, 35.24243192800003],
            [-80.884561562999977, 35.241248749000079],
            [-80.88410554799998, 35.240468931000066],
            [-80.883540795999977, 35.239640318000056],
            [-80.882921761999967, 35.238856233000035],
            [-80.882204320999961, 35.238008597000032],
            [-80.882193767999979, 35.237996143000032],
            [-80.881502055999988, 35.23723508300003],
            [-80.88075116899995, 35.236512765000043],
            [-80.879944322999961, 35.235832281000057],
            [-80.879084972999976, 35.235196543000029],
            [-80.878176795999934, 35.234608273000049],
            [-80.877223683999944, 35.234069989000034],
            [-80.876700901999982, 35.233805372000063],
            [-80.875958285999957, 35.233443933000046],
            [-80.875915286999941, 35.23331064100006],
            [-80.875558548999948, 35.232407533000071],
            [-80.875130594999973, 35.231525501000078],
            [-80.87463325799996, 35.230668320000063],
            [-80.874068670999975, 35.229839661000028],
            [-80.873439251999969, 35.229043071000035],
            [-80.872747697999955, 35.228281960000061],
            [-80.871996970999987, 35.227559586000041],
            [-80.871190278999961, 35.22687903700006],
            [-80.870301978999976, 35.226177109000048],
            [-80.869442813999967, 35.225541308000061],
            [-80.868534814999975, 35.224952965000057],
            [-80.867581878999943, 35.22441460400006],
            [-80.866588085999979, 35.223928529000034],
            [-80.865557690999935, 35.223496822000072],
            [-80.864495101999978, 35.223121330000026],
            [-80.864169464999975, 35.223019509000039],
            [-80.862149532999979, 35.222406523000075],
            [-80.861630157999969, 35.222259890000032],
            [-80.859836959999939, 35.220798244000036],
            [-80.856335354999942, 35.217579475000036],
            [-80.856218410999986, 35.217473034000079],
            [-80.855411957999934, 35.21679238400003],
            [-80.854552999999953, 35.216156470000044],
            [-80.853645212999936, 35.215568014000041],
            [-80.852692485999967, 35.215029535000042],
            [-80.851698895999959, 35.214543338000055],
            [-80.850668697999936, 35.214111503000026],
            [-80.849606299999948, 35.213735879000069],
            [-80.848516251999968, 35.21341807500005],
            [-80.847403217999954, 35.213159449000045],
            [-80.846271961999946, 35.212961109000048],
            [-80.845127325999954, 35.212823904000061],
            [-80.843974207999963, 35.212748421000072],
            [-80.842817545999935, 35.212734983000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 120,
        "SolutionID": "PI-12:F",
        "Shape_Length": 1.1110796573655706,
        "Shape_Area": 0.016812590269121252
      }
    },
    {
      "type": "Feature",
      "id": 121,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.581215330999953, 35.395710400000041],
            [-80.58005732099997, 35.395756564000067],
            [-80.578905472999963, 35.395864635000066],
            [-80.577764719999948, 35.39603414700008],
            [-80.576639943999965, 35.396264379000058],
            [-80.575535956999943, 35.396554341000069],
            [-80.574457486999961, 35.396902795000074],
            [-80.573409147999939, 35.397308250000037],
            [-80.572395428999982, 35.397768968000037],
            [-80.571420668999963, 35.398282980000033],
            [-80.570489040999973, 35.398848083000075],
            [-80.569604531999971, 35.399461861000077],
            [-80.568770932999939, 35.400121684000055],
            [-80.568627567999954, 35.400244264000037],
            [-80.567283076999956, 35.401407352000035],
            [-80.566647298999953, 35.401987810000037],
            [-80.565925966999941, 35.402731060000065],
            [-80.565265538999938, 35.403511341000069],
            [-80.564668842999936, 35.404325313000072],
            [-80.564138434999961, 35.405169491000038],
            [-80.563676588999954, 35.406040263000079],
            [-80.56328528399996, 35.406933898000034],
            [-80.562966198999959, 35.407846572000039],
            [-80.562720699999943, 35.408774377000043],
            [-80.562549842999942, 35.409713340000053],
            [-80.562454359999947, 35.410659442000053],
            [-80.562434664999955, 35.411608629000057],
            [-80.562490842999978, 35.412556838000057],
            [-80.562622656999963, 35.413500010000064],
            [-80.562829543999953, 35.414434104000065],
            [-80.563110622999943, 35.415355119000026],
            [-80.563464689999989, 35.416259113000024],
            [-80.563890232999938, 35.417142213000034],
            [-80.564385430999948, 35.418000637000034],
            [-80.564948164999976, 35.41883070800003],
            [-80.565576025999974, 35.419628870000054],
            [-80.566266327999983, 35.420391706000032],
            [-80.567016114999944, 35.421115947000033],
            [-80.567382346999977, 35.421437174000062],
            [-80.574621745999934, 35.42759651800003],
            [-80.575251223999942, 35.428425136000044],
            [-80.575311141999975, 35.428503450000051],
            [-80.575894829999982, 35.429260968000051],
            [-80.575924196999949, 35.429314680000061],
            [-80.576332524999941, 35.430011162000028],
            [-80.576895463999961, 35.430841175000069],
            [-80.577523535999944, 35.431639274000077],
            [-80.578214051999964, 35.432402039000067],
            [-80.578964056999951, 35.433126205000065],
            [-80.579770334999978, 35.433808665000072],
            [-80.582612166999979, 35.436061541000072],
            [-80.58300348399996, 35.436362477000046],
            [-80.585821879999969, 35.438464955000029],
            [-80.588115874999971, 35.440283216000068],
            [-80.58888490999999, 35.440857889000029],
            [-80.59127891299994, 35.442543949000026],
            [-80.591369117999989, 35.44260704800007],
            [-80.591793829999972, 35.44289296900007],
            [-80.593299669999965, 35.44387504000008],
            [-80.593783389999942, 35.444179510000026],
            [-80.594737085999952, 35.444720003000043],
            [-80.595731948999969, 35.44520828800006],
            [-80.596763718999966, 35.445642274000079],
            [-80.597827977999941, 35.446020100000055],
            [-80.598920162999946, 35.446340149000036],
            [-80.600035597999977, 35.446601048000048],
            [-80.601169501999948, 35.446801682000057],
            [-80.602317019999987, 35.446941189000029],
            [-80.603473233999978, 35.44701897300007],
            [-80.604633188999969, 35.447034698000039],
            [-80.605791915999987, 35.446988300000044],
            [-80.606944452999983, 35.446879976000048],
            [-80.608085858999971, 35.446710190000033],
            [-80.609211244999983, 35.446479670000031],
            [-80.610315788999969, 35.446189404000052],
            [-80.611394759999939, 35.445840634000035],
            [-80.612443535999944, 35.445434856000077],
            [-80.613341693999985, 35.445026514000062],
            [-80.614817738999989, 35.445219472000076],
            [-80.615754552999988, 35.445404964000033],
            [-80.617746508999971, 35.445953644000042],
            [-80.617977723999957, 35.446015887000044],
            [-80.61807061199994, 35.44604008400006],
            [-80.622240637999937, 35.447115978000056],
            [-80.624042626999938, 35.447641600000054],
            [-80.624571883999977, 35.447788323000054],
            [-80.625687420999952, 35.44804898600006],
            [-80.626564591999966, 35.448209545000054],
            [-80.627792891999945, 35.448407750000058],
            [-80.628049709999971, 35.448447583000075],
            [-80.629197296999962, 35.448586835000071],
            [-80.630153136999979, 35.448655385000052],
            [-80.631291148999935, 35.448711647000039],
            [-80.631491571999959, 35.448720623000042],
            [-80.632651556999974, 35.44873608100005],
            [-80.633810292999954, 35.448689415000047],
            [-80.634962815999984, 35.448580824000032],
            [-80.63610418799999, 35.44841077500007],
            [-80.636572830999967, 35.448322442000062],
            [-80.638489038999978, 35.447938771000054],
            [-80.639145718999941, 35.447796314000072],
            [-80.639869131999944, 35.447613413000056],
            [-80.640297551999936, 35.447496835000038],
            [-80.641059130999963, 35.447602153000048],
            [-80.648565617999964, 35.448928476000049],
            [-80.649137375999942, 35.449021456000025],
            [-80.649300958999959, 35.449045130000059],
            [-80.655580437999959, 35.449928921000037],
            [-80.657827490999978, 35.450290941000048],
            [-80.658084326999983, 35.45033070900007],
            [-80.659231990999956, 35.450469678000047],
            [-80.660388309999973, 35.450546919000033],
            [-80.661548325999945, 35.45056210000007],
            [-80.663734086999966, 35.450532142000043],
            [-80.664366596999969, 35.450514221000049],
            [-80.666211456999974, 35.450434933000054],
            [-80.668320783999945, 35.450461605000044],
            [-80.66889247499995, 35.450461285000074],
            [-80.671848023999985, 35.450420588000043],
            [-80.67400298299998, 35.45048124300007],
            [-80.677499463999936, 35.450840793000054],
            [-80.677693045999945, 35.450859812000033],
            [-80.678049988999987, 35.45089026100004],
            [-80.682723188999944, 35.451249724000036],
            [-80.688137510999979, 35.451694818000078],
            [-80.68967183999996, 35.451891217000025],
            [-80.690397246999964, 35.451971548000074],
            [-80.691553616999954, 35.452048492000074],
            [-80.692713659999981, 35.45206337500008],
            [-80.693696505999981, 35.452027318000034],
            [-80.695268779999935, 35.451933795000059],
            [-80.695444680999969, 35.451922610000054],
            [-80.696597167999983, 35.45181343400003],
            [-80.697738455999968, 35.451642806000052],
            [-80.698207048999961, 35.451554234000071],
            [-80.698606327999983, 35.451474080000025],
            [-80.700162098999954, 35.451673086000028],
            [-80.700602811999943, 35.451724826000031],
            [-80.702969450999944, 35.451977870000064],
            [-80.703254155999957, 35.452006393000033],
            [-80.704410537999934, 35.452083215000073],
            [-80.705570583999986, 35.452097975000072],
            [-80.706729325999959, 35.45205061300004],
            [-80.707186884999942, 35.452008468000031],
            [-80.707488251999962, 35.452062031000025],
            [-80.708436252999945, 35.452329084000041],
            [-80.708798351999974, 35.452427532000058],
            [-80.709914221999952, 35.452687420000075],
            [-80.711048481999967, 35.452887027000031],
            [-80.712002676999987, 35.453006530000039],
            [-80.714707787999942, 35.453283842000076],
            [-80.719071367999959, 35.453881356000068],
            [-80.725637608999989, 35.454788087000054],
            [-80.72610670399996, 35.454847576000077],
            [-80.733565845999976, 35.455709780000063],
            [-80.736556379999968, 35.45612854500007],
            [-80.737540634999959, 35.456243216000075],
            [-80.737561731999961, 35.456245171000035],
            [-80.739577342999951, 35.456430970000042],
            [-80.740266565999946, 35.456483370000058],
            [-80.743156546999955, 35.45665656400007],
            [-80.743602707999969, 35.456678674000045],
            [-80.744762826999988, 35.456693060000077],
            [-80.745395359999975, 35.456674717000055],
            [-80.746893823999983, 35.45660932100003],
            [-80.74962256799995, 35.456729321000068],
            [-80.749726289999955, 35.456733632000066],
            [-80.750886410999954, 35.45674795900004],
            [-80.752045191999969, 35.456700165000029],
            [-80.752790012999981, 35.456636419000063],
            [-80.75585498099997, 35.456320385000026],
            [-80.760362227999963, 35.455925168000078],
            [-80.765559399999972, 35.455574894000051],
            [-80.76557747399994, 35.455573669000046],
            [-80.76574366899996, 35.455561679000027],
            [-80.766140685999972, 35.455531497000038],
            [-80.766330249999953, 35.455590893000078],
            [-80.770518386999981, 35.456867129000045],
            [-80.770654177999972, 35.456920224000044],
            [-80.771323721999977, 35.457330832000025],
            [-80.771416796999972, 35.457387522000033],
            [-80.772371827999962, 35.457926616000066],
            [-80.772387438999942, 35.457934815000044],
            [-80.773449176999975, 35.458491795000043],
            [-80.77442967099995, 35.458970414000078],
            [-80.775462568999956, 35.459402878000049],
            [-80.776527836999946, 35.45977913400003],
            [-80.777620909999939, 35.460097573000041],
            [-80.778579887999967, 35.460324195000055],
            [-80.780161632999977, 35.460658807000073],
            [-80.780318851999937, 35.460691438000026],
            [-80.78047894599996, 35.460721901000056],
            [-80.782111761999943, 35.461325586000044],
            [-80.78411007699998, 35.46207172000004],
            [-80.784981682999955, 35.462374411000042],
            [-80.785712585999988, 35.462594557000045],
            [-80.786578769999949, 35.462837904000025],
            [-80.789329494999947, 35.464868396000043],
            [-80.789758553999945, 35.465175014000067],
            [-80.790668667999967, 35.465763921000075],
            [-80.791623924999953, 35.466302862000077],
            [-80.791959282999983, 35.466474808000044],
            [-80.792462557999954, 35.466726489000052],
            [-80.792986126999949, 35.467026326000052],
            [-80.793571424999982, 35.467347521000079],
            [-80.79456775899996, 35.467834173000028],
            [-80.79560087699997, 35.468266464000067],
            [-80.796666354999957, 35.468642543000044],
            [-80.797556578999945, 35.468906644000072],
            [-80.799052706999987, 35.469313843000066],
            [-80.79925575599998, 35.469367996000074],
            [-80.800372147999951, 35.469627052000078],
            [-80.801506866999944, 35.469825812000067],
            [-80.802655050999988, 35.469963422000035],
            [-80.803811782999958, 35.470039296000039],
            [-80.804972102999955, 35.47005310600008],
            [-80.805741961999956, 35.47002795700007],
            [-80.807751698999937, 35.469926469000029],
            [-80.808140777999938, 35.469903300000055],
            [-80.809293367999942, 35.469793054000036],
            [-80.810434670999939, 35.469621366000069],
            [-80.811559796999973, 35.469388970000068],
            [-80.812663927999949, 35.469096864000051],
            [-80.81324897099995, 35.468914986000073],
            [-80.815767361999974, 35.468090136000058],
            [-80.816260711999973, 35.46792143700003],
            [-80.816488371999981, 35.467838707000055],
            [-80.822025200999974, 35.46578826800004],
            [-80.83068650599995, 35.462687516000074],
            [-80.831182484999943, 35.46250244600003],
            [-80.834824280999953, 35.461087729000042],
            [-80.83535360999997, 35.460873177000053],
            [-80.836366620999968, 35.460410268000032],
            [-80.837105015999953, 35.460025777000055],
            [-80.839783176999958, 35.458553933000076],
            [-80.840018598999961, 35.45842228600003],
            [-80.840901525999982, 35.457885999000041],
            [-80.842711699999938, 35.456713509000053],
            [-80.842759203999947, 35.456682631000035],
            [-80.843642268999986, 35.456066871000075],
            [-80.844391175999988, 35.455471209000052],
            [-80.849904452999965, 35.455783322000059],
            [-80.87662196499997, 35.457703699000035],
            [-80.88593308999998, 35.458535766000068],
            [-80.891292642999986, 35.459041765000052],
            [-80.891345653999963, 35.45904670300007],
            [-80.892502311999976, 35.459121731000039],
            [-80.893662490999986, 35.459134693000067],
            [-80.894781671999965, 35.459117219000063],
            [-80.895940400999962, 35.459068050000042],
            [-80.897092717999953, 35.458956969000042],
            [-80.898233681999955, 35.458784455000057],
            [-80.898251792999986, 35.458781204000047],
            [-80.898948599999983, 35.458655797000063],
            [-80.900055209999948, 35.458425831000056],
            [-80.901158872999986, 35.458132919000036],
            [-80.90223674899994, 35.457781565000062],
            [-80.903284221999968, 35.457373275000066],
            [-80.903370695999968, 35.457336434000069],
            [-80.904202010999938, 35.456979881000052],
            [-80.905128107999985, 35.456553239000073],
            [-80.906101449999937, 35.456036554000036],
            [-80.907031393999944, 35.455468883000037],
            [-80.907047968999962, 35.455458043000078],
            [-80.907943814999953, 35.454871445000038],
            [-80.908544623999944, 35.454459884000073],
            [-80.909852036999951, 35.453523288000042],
            [-80.910117197999966, 35.453329452000048],
            [-80.910948565999945, 35.452667293000047],
            [-80.911648389999982, 35.452036078000049],
            [-80.912579239999957, 35.451145222000036],
            [-80.913506028999961, 35.450307706000046],
            [-80.913916165999979, 35.449923712000043],
            [-80.913928939999948, 35.449911306000047],
            [-80.915365300999952, 35.448514603000035],
            [-80.916071064999983, 35.447781692000035],
            [-80.916728479999961, 35.446999513000037],
            [-80.917321957999945, 35.446183821000034],
            [-80.917714330999956, 35.44556757600003],
            [-80.918419480999944, 35.444392209000057],
            [-80.919147627999962, 35.443459861000065],
            [-80.919971824999948, 35.443022141000029],
            [-80.920495009999968, 35.442712193000034],
            [-80.921270772999947, 35.442234739000071],
            [-80.921379306999938, 35.442165899000031],
            [-80.922195553999984, 35.442330244000061],
            [-80.923309575999951, 35.442523704000052],
            [-80.924457579999967, 35.442660163000028],
            [-80.924814572999935, 35.442689886000039],
            [-80.925997256999949, 35.442778459000067],
            [-80.92679671999997, 35.44282344100003],
            [-80.92746438599994, 35.442838305000066],
            [-80.928678715999979, 35.442846601000042],
            [-80.93151409799998, 35.442960256000049],
            [-80.931566095999983, 35.44296227600006],
            [-80.932726049999985, 35.442974865000053],
            [-80.935654549999981, 35.442928165000069],
            [-80.93667482699999, 35.442887792000079],
            [-80.938945127999943, 35.442744173000051],
            [-80.939083323999967, 35.442734985000072],
            [-80.940235348999977, 35.442623494000031],
            [-80.940303189999952, 35.442614969000033],
            [-80.942875158999982, 35.442287539000063],
            [-80.94318786499997, 35.442245379000042],
            [-80.948293577999948, 35.441518473000031],
            [-80.94920831799999, 35.441597155000068],
            [-80.949683524999955, 35.441632741000035],
            [-80.954409497999961, 35.44193412900006],
            [-80.954875692999963, 35.442220478000024],
            [-80.957091465999952, 35.443677272000059],
            [-80.957570681999982, 35.443981439000027],
            [-80.966993953999975, 35.449753208000061],
            [-80.967385767999986, 35.450011458000063],
            [-80.967534507999972, 35.450133115000028],
            [-80.968192923999936, 35.450724749000074],
            [-80.968206282999972, 35.450736740000025],
            [-80.969016003999968, 35.451416607000056],
            [-80.969878344999984, 35.452051679000078],
            [-80.970502778999958, 35.452462745000048],
            [-80.971390944999939, 35.453020833000039],
            [-80.971677785999987, 35.453197322000051],
            [-80.972634094999989, 35.453734839000049],
            [-80.973199105999981, 35.454018586000075],
            [-80.973774349999985, 35.454295488000071],
            [-80.974206579999986, 35.454496920000054],
            [-80.975240482999936, 35.454927683000051],
            [-80.975811705999945, 35.455136739000068],
            [-80.976693843999954, 35.455444410000041],
            [-80.977188757999954, 35.455609853000055],
            [-80.978282558999979, 35.455926483000042],
            [-80.979399335999972, 35.45618389200007],
            [-80.980516045999934, 35.45637830700008],
            [-80.981574769999952, 35.456533647000072],
            [-80.984002822999969, 35.45700325100006],
            [-80.984952323999948, 35.457164581000029],
            [-80.985104666999973, 35.457186928000056],
            [-80.98542324899995, 35.457338801000049],
            [-80.987438163999968, 35.458374088000028],
            [-80.991158222999957, 35.460624455000072],
            [-80.991189660999964, 35.460643428000026],
            [-80.991949273999978, 35.461076250000076],
            [-80.993367570999965, 35.461840169000027],
            [-80.993564487999947, 35.461944707000043],
            [-80.99456195199997, 35.462429716000031],
            [-80.995254646999967, 35.462725811000041],
            [-80.996359054999971, 35.463172816000053],
            [-80.996612292999941, 35.463273272000038],
            [-80.997583795999958, 35.463650888000075],
            [-80.99767197999995, 35.463684921000038],
            [-80.99873833099997, 35.464059226000074],
            [-80.999832329999947, 35.464375661000076],
            [-81.000949290999984, 35.464632871000049],
            [-81.001239480999971, 35.464689269000075],
            [-81.002118292999967, 35.464853682000069],
            [-81.002654544999984, 35.464946893000047],
            [-81.003664521999951, 35.46510913000003],
            [-81.003973220999967, 35.465156398000033],
            [-81.005092565999973, 35.465289425000037],
            [-81.006659241999955, 35.46543402900005],
            [-81.009373814999947, 35.46578878300005],
            [-81.015959210999938, 35.466675212000041],
            [-81.016167007999968, 35.466702142000031],
            [-81.023975633999953, 35.467675029000077],
            [-81.041878280999981, 35.469980651000071],
            [-81.041970204999984, 35.469992280000042],
            [-81.043728285999975, 35.470210823000059],
            [-81.044839227999944, 35.470405002000064],
            [-81.046218291999935, 35.470730644000071],
            [-81.04658590799994, 35.47081395500004],
            [-81.049735165999948, 35.471497845000044],
            [-81.053544973999976, 35.472349033000057],
            [-81.053982376999954, 35.472441866000054],
            [-81.054645280999978, 35.472564238000075],
            [-81.055611732999978, 35.472726783000041],
            [-81.055889774999969, 35.472771654000042],
            [-81.056724152999948, 35.472900644000049],
            [-81.056918584999949, 35.472929785000076],
            [-81.05798568299997, 35.473057442000027],
            [-81.058950332999984, 35.47314842600008],
            [-81.05903189299994, 35.473155961000032],
            [-81.060188905999951, 35.473229391000075],
            [-81.061349310999958, 35.47324074900007],
            [-81.062212737999971, 35.473208845000045],
            [-81.064003606999961, 35.473106829000074],
            [-81.064299006999988, 35.473087969000062],
            [-81.065451290999988, 35.472975290000079],
            [-81.066489600999944, 35.472819433000041],
            [-81.068372009999962, 35.472489190000033],
            [-81.069290811999963, 35.472760430000051],
            [-81.070229885999936, 35.473765926000056],
            [-81.070480716999953, 35.474028199000031],
            [-81.071137284999963, 35.474660741000037],
            [-81.07422739499998, 35.477470598000025],
            [-81.074325603999966, 35.477559158000076],
            [-81.075136479999969, 35.47823831200003],
            [-81.07599993499997, 35.478872626000054],
            [-81.076912273999937, 35.479459382000073],
            [-81.077192564999962, 35.479624390000026],
            [-81.07863159499999, 35.480454257000076],
            [-81.078634594999983, 35.480456611000079],
            [-81.079052778999937, 35.480800249000026],
            [-81.08043786799999, 35.482083639000052],
            [-81.082279326999981, 35.483934122000051],
            [-81.082878393999977, 35.484505185000046],
            [-81.084625192999965, 35.486085427000035],
            [-81.084695098999987, 35.486148295000078],
            [-81.08550614699999, 35.486827378000044],
            [-81.088185221999936, 35.488928289000057],
            [-81.088555891999988, 35.489210755000045],
            [-81.090161572999989, 35.49039973400005],
            [-81.09065458799995, 35.490751481000075],
            [-81.091567166999937, 35.491338125000027],
            [-81.092524717999936, 35.491874692000067],
            [-81.093090399999937, 35.492157871000074],
            [-81.09366632199999, 35.492434201000037],
            [-81.094099065999956, 35.492635212000039],
            [-81.095134088999941, 35.493064949000029],
            [-81.095876767999982, 35.493331951000073],
            [-81.096582988999955, 35.49357023400006],
            [-81.09690749899994, 35.493676675000074],
            [-81.098002278999957, 35.493992223000078],
            [-81.098490709999965, 35.49411217800008],
            [-81.099062963999984, 35.494245414000034],
            [-81.099692213999958, 35.494381760000067],
            [-81.100828006999961, 35.494577719000063],
            [-81.101977042999977, 35.494712496000034],
            [-81.103134401999966, 35.494785516000036],
            [-81.10429512099995, 35.494796464000046],
            [-81.104816351999943, 35.494781154000066],
            [-81.106041947999984, 35.494730381000068],
            [-81.107749281999986, 35.494700742000077],
            [-81.108908387999975, 35.494649540000069],
            [-81.110060914999963, 35.494536438000068],
            [-81.111201923999943, 35.494361923000042],
            [-81.111727439999981, 35.494260005000058],
            [-81.112293201999989, 35.494142811000074],
            [-81.112892287999955, 35.49400954400005],
            [-81.113995663999958, 35.493714699000066],
            [-81.11477365199994, 35.493466388000058],
            [-81.115987962999952, 35.49305160800003],
            [-81.116287396999951, 35.492946674000052],
            [-81.116484869999965, 35.492874531000041],
            [-81.117530874999943, 35.492486112000051],
            [-81.119399736999981, 35.491829959000029],
            [-81.119538509999984, 35.491780660000074],
            [-81.120585325999969, 35.491370498000038],
            [-81.121597086999941, 35.490905213000076],
            [-81.122569460999955, 35.490386796000053],
            [-81.122760978999963, 35.490275671000063],
            [-81.123450409999975, 35.48986999400006],
            [-81.124187706999976, 35.489411790000077],
            [-81.125068988999942, 35.488793989000044],
            [-81.125898964999976, 35.488130364000028],
            [-81.126674082999955, 35.48742375900008],
            [-81.127244946999951, 35.48683767600005],
            [-81.127883096999938, 35.486146605000044],
            [-81.128029169999934, 35.485986127000047],
            [-81.128684849999956, 35.485202808000054],
            [-81.128851381999937, 35.484984949000079],
            [-81.129231816999948, 35.484477308000066],
            [-81.129656906999969, 35.483878445000073],
            [-81.130181940999989, 35.483031822000044],
            [-81.130327395999984, 35.482770190000053],
            [-81.130909027999962, 35.481695875000071],
            [-81.131061925999973, 35.481422604000045],
            [-81.131202372999951, 35.481343366000033],
            [-81.131299899999988, 35.481287947000055],
            [-81.131538220999971, 35.481149145000074],
            [-81.132007210999973, 35.480871198000045],
            [-81.140502549999951, 35.481680988000051],
            [-81.140619477999962, 35.481691808000051],
            [-81.141234244999964, 35.481738164000035],
            [-81.143508777999955, 35.48187703800005],
            [-81.144935896999982, 35.482164420000061],
            [-81.149205008999957, 35.483179418000077],
            [-81.149936742999955, 35.483339594000029],
            [-81.150037698999938, 35.483359558000075],
            [-81.154365088999953, 35.484204230000046],
            [-81.156412495999973, 35.484682259000067],
            [-81.15706355399999, 35.484823381000069],
            [-81.158199350999951, 35.485018804000049],
            [-81.159348346999934, 35.485153038000078],
            [-81.159705613999961, 35.485182069000075],
            [-81.160889170999951, 35.485268351000059],
            [-81.16168917899995, 35.485311784000032],
            [-81.16284976999998, 35.485322172000053],
            [-81.163734807999958, 35.485288291000074],
            [-81.16548208599994, 35.485185557000079],
            [-81.16560805499995, 35.485177780000072],
            [-81.167136679999942, 35.485078924000049],
            [-81.167284607999989, 35.485068848000026],
            [-81.168431070999986, 35.484955927000044],
            [-81.169032815999969, 35.484880327000042],
            [-81.169928957999957, 35.485717916000056],
            [-81.171710780999945, 35.487390934000075],
            [-81.172008706999975, 35.48766364100004],
            [-81.172820499999943, 35.488342140000043],
            [-81.173893036999971, 35.489181741000039],
            [-81.17475737999996, 35.489815350000072],
            [-81.175276706999966, 35.490158269000062],
            [-81.176701070999968, 35.49106310600007],
            [-81.177094928999963, 35.491306193000071],
            [-81.178053043999967, 35.49184208500003],
            [-81.179051975999982, 35.492325573000073],
            [-81.180087445999959, 35.492754585000057],
            [-81.18115501699998, 35.493127284000025],
            [-81.182250116999967, 35.493442071000061],
            [-81.182575856999961, 35.493523214000049],
            [-81.183291345999976, 35.493695321000075],
            [-81.185050235999938, 35.494354066000028],
            [-81.186010028999988, 35.494685984000057],
            [-81.186945935999972, 35.494959024000025],
            [-81.188708822999956, 35.49542821700004],
            [-81.188868056999979, 35.495469919000072],
            [-81.189986042999976, 35.495725386000061],
            [-81.191122071999985, 35.495920500000068],
            [-81.192271276999975, 35.496054422000043],
            [-81.193428734999941, 35.496126581000055],
            [-81.194589484999938, 35.496136665000051],
            [-81.195551659999978, 35.496119263000026],
            [-81.196385509999971, 35.496081826000079],
            [-81.197665318999952, 35.496345768000026],
            [-81.197792169999957, 35.496371522000061],
            [-81.19786933499995, 35.496386793000056],
            [-81.199540967999951, 35.496714349000058],
            [-81.200599867999983, 35.496894104000035],
            [-81.201749101999951, 35.497027936000052],
            [-81.202593181999987, 35.497086638000042],
            [-81.203644778999944, 35.497139117000074],
            [-81.203958179999972, 35.497152480000068],
            [-81.20511894699996, 35.497162464000041],
            [-81.206278024999961, 35.497110332000034],
            [-81.207430450999937, 35.496996306000028],
            [-81.208571284999948, 35.496820876000072],
            [-81.20969563999995, 35.496584792000078],
            [-81.210527056999979, 35.49636799600006],
            [-81.212958672999946, 35.495679574000064],
            [-81.213230309999972, 35.495600639000031],
            [-81.214307326999972, 35.495246518000044],
            [-81.215329419999989, 35.494845872000042],
            [-81.217985688999988, 35.493717875000073],
            [-81.225988543999961, 35.493093103000035],
            [-81.227011264999987, 35.492988622000041],
            [-81.227135772999986, 35.492972515000076],
            [-81.229738442999974, 35.49262802100003],
            [-81.231468029999974, 35.492487181000058],
            [-81.232420403999981, 35.492388266000034],
            [-81.232651902999976, 35.492357713000047],
            [-81.234797095999966, 35.49206265600003],
            [-81.235706303999962, 35.491917529000034],
            [-81.236034712999981, 35.491855101000056],
            [-81.236866920999944, 35.491690068000025],
            [-81.23917542099997, 35.49136031200004],
            [-81.239262619999977, 35.491347671000028],
            [-81.24281394999997, 35.490825335000068],
            [-81.244796745999963, 35.490546215000052],
            [-81.24564068799998, 35.490410094000026],
            [-81.246316185999945, 35.490275734000079],
            [-81.247403533999943, 35.490040897000029],
            [-81.247852190999936, 35.489938825000024],
            [-81.248955019999983, 35.489642754000045],
            [-81.249878685999988, 35.489342841000052],
            [-81.252295709999942, 35.48849313900007],
            [-81.25916512799995, 35.486294982000061],
            [-81.263012432999972, 35.485107248000077],
            [-81.263907965999977, 35.484807687000057],
            [-81.264953961999936, 35.484396280000055],
            [-81.265964806999989, 35.483929791000037],
            [-81.266936174999955, 35.483410218000074],
            [-81.267863903999967, 35.482839788000035],
            [-81.268744020999975, 35.482220943000073],
            [-81.269572753999967, 35.48155633500005],
            [-81.270346557999972, 35.48084881300008],
            [-81.271062117999975, 35.480101403000049],
            [-81.271716369999979, 35.479317311000045],
            [-81.272306514999968, 35.478499894000038],
            [-81.272830026999941, 35.477652653000064],
            [-81.27328466299997, 35.476779217000058],
            [-81.273668480999959, 35.475883327000076],
            [-81.273979837999946, 35.474968820000072],
            [-81.274217404999945, 35.474039613000059],
            [-81.274380163999979, 35.473099685000079],
            [-81.274467423999965, 35.472153060000039],
            [-81.274478810999938, 35.471203794000076],
            [-81.274414280999963, 35.470255952000059],
            [-81.274274110999954, 35.46931359000007],
            [-81.274058905999937, 35.468380745000047],
            [-81.273769587999936, 35.467461412000034],
            [-81.273407400999986, 35.466559526000026],
            [-81.27297389499995, 35.465678948000061],
            [-81.27247092999994, 35.464823448000061],
            [-81.271900659999972, 35.463996690000045],
            [-81.271265529999937, 35.463202212000056],
            [-81.270568259999948, 35.462443416000042],
            [-81.269811836999963, 35.461723550000045],
            [-81.26899949899996, 35.461045695000053],
            [-81.26813472799995, 35.460412753000071],
            [-81.267221222999979, 35.459827434000033],
            [-81.26626289699999, 35.45929224200006],
            [-81.265263851999975, 35.458809468000027],
            [-81.264228365999941, 35.458381179000071],
            [-81.263160869999979, 35.458009209000068],
            [-81.262065934999953, 35.457695148000028],
            [-81.260948245999941, 35.457440342000041],
            [-81.259812587999988, 35.457245880000073],
            [-81.258663820999971, 35.457112596000059],
            [-81.257506860999968, 35.457041059000062],
            [-81.256346661999942, 35.457031576000077],
            [-81.255188186999987, 35.457084187000078],
            [-81.254036394999957, 35.457198667000057],
            [-81.252896215999954, 35.457374526000024],
            [-81.251772529999982, 35.457611013000076],
            [-81.250670144999958, 35.457907112000044],
            [-81.250489067999979, 35.457962096000074],
            [-81.246542744999942, 35.459180347000029],
            [-81.24634296499994, 35.459243144000027],
            [-81.239102732999982, 35.461559848000036],
            [-81.238560229999962, 35.461741931000063],
            [-81.237413323999988, 35.462145114000066],
            [-81.236733021999953, 35.462240876000067],
            [-81.236601797999981, 35.462259764000066],
            [-81.233029672999976, 35.462785158000031],
            [-81.230197785999962, 35.463189668000041],
            [-81.229398156999935, 35.463319437000052],
            [-81.229069857999946, 35.463381847000051],
            [-81.228291858999967, 35.463536132000058],
            [-81.227359585999977, 35.463664360000053],
            [-81.22568459699994, 35.46380074700005],
            [-81.224732549999942, 35.463899609000066],
            [-81.224608085999989, 35.463915714000052],
            [-81.222041217999958, 35.464255481000066],
            [-81.214236866999954, 35.46486472600003],
            [-81.214112592999982, 35.464874785000063],
            [-81.21193058099999, 35.465057725000065],
            [-81.211032465999949, 35.465152024000076],
            [-81.209892082999943, 35.465327478000063],
            [-81.20884440399999, 35.465545507000058],
            [-81.207192818999943, 35.465933406000033],
            [-81.207116575999976, 35.465951464000057],
            [-81.206013915999961, 35.466247157000055],
            [-81.204937245999986, 35.466601207000053],
            [-81.203915457999983, 35.467001768000046],
            [-81.202761454999973, 35.467491826000071],
            [-81.20161070599994, 35.467278543000077],
            [-81.20076538699999, 35.467139446000033],
            [-81.199616576999972, 35.467005604000065],
            [-81.19845952899999, 35.466933504000053],
            [-81.197299194999971, 35.466923456000075],
            [-81.197025728999961, 35.466928408000058],
            [-81.196915714999989, 35.466887206000024],
            [-81.196658163999984, 35.466645450000044],
            [-81.196631533999948, 35.466620509000052],
            [-81.193772941999953, 35.463949424000077],
            [-81.193480010999963, 35.463637679000044],
            [-81.193247747999976, 35.463395847000072],
            [-81.192492000999948, 35.462675498000067],
            [-81.191680299999973, 35.461997127000075],
            [-81.190816120999955, 35.461363634000065],
            [-81.18990316299994, 35.460777732000054],
            [-81.188945334999971, 35.460241930000052],
            [-81.187946738999983, 35.459758520000037],
            [-81.186911648999967, 35.459329572000058],
            [-81.185979310999983, 35.459000310000079],
            [-81.185953386999984, 35.458991866000076],
            [-81.185929524999949, 35.458980034000035],
            [-81.185265437999988, 35.458666625000035],
            [-81.185090638999952, 35.458589240000038],
            [-81.184585843999969, 35.458368741000072],
            [-81.184457443999975, 35.458295818000067],
            [-81.183870669999976, 35.457976509000048],
            [-81.182872130999954, 35.457493057000079],
            [-81.181837096999971, 35.457064065000054],
            [-81.18086612999997, 35.456722208000031],
            [-81.180027880999944, 35.456451003000041],
            [-81.179931746999955, 35.456420164000065],
            [-81.17883715499994, 35.456105352000066],
            [-81.177719749999937, 35.455849780000051],
            [-81.176584313999967, 35.455654539000079],
            [-81.176038106999954, 35.455582955000068],
            [-81.175248388999989, 35.455489766000028],
            [-81.174645989999988, 35.455427274000044],
            [-81.173489127999972, 35.455354936000049],
            [-81.17313424699995, 35.455345192000038],
            [-81.172171593999963, 35.455326650000075],
            [-81.171366310999986, 35.455326101000026],
            [-81.170207805999951, 35.455377901000077],
            [-81.16905591799997, 35.455491574000064],
            [-81.168675453999981, 35.455542969000078],
            [-81.167169153999964, 35.455760229000077],
            [-81.163691590999974, 35.456197190000069],
            [-81.163646940999968, 35.456200078000052],
            [-81.163074667999979, 35.456076022000047],
            [-81.162973742999952, 35.456056069000056],
            [-81.158688615999949, 35.455219673000045],
            [-81.15448091199994, 35.454219282000054],
            [-81.153770012999985, 35.454063266000048],
            [-81.151483672999973, 35.45360286500005],
            [-81.151463091999972, 35.453598731000056],
            [-81.151172828999961, 35.453542691000052],
            [-81.150118042999964, 35.453346690000046],
            [-81.149272967999934, 35.453207235000036],
            [-81.148124439999947, 35.453072905000056],
            [-81.147509885999966, 35.453026584000042],
            [-81.144240639999964, 35.45282700000007],
            [-81.13193516399997, 35.451653782000051],
            [-81.131818279999948, 35.451642953000032],
            [-81.130661510999971, 35.451570206000042],
            [-81.129501404999985, 35.451559510000038],
            [-81.128342927999938, 35.451610910000056],
            [-81.127989026999956, 35.451639063000073],
            [-81.12646191999994, 35.451773194000054],
            [-81.125663924999969, 35.451858308000055],
            [-81.125634987999945, 35.451861963000056],
            [-81.124327367999967, 35.452028004000056],
            [-81.123215918999961, 35.452198989000067],
            [-81.122091928999964, 35.45243427500003],
            [-81.120989142999974, 35.452729196000064],
            [-81.120685132999938, 35.452822193000031],
            [-81.119600719999937, 35.453163199000073],
            [-81.118827862999979, 35.453423491000024],
            [-81.118121948999942, 35.453692599000078],
            [-81.117472780999947, 35.453954417000034],
            [-81.117132355999956, 35.45409545800004],
            [-81.116121027999952, 35.454560710000067],
            [-81.115164239999956, 35.45507039000006],
            [-81.11322503699995, 35.45617769200004],
            [-81.113209837999989, 35.456186381000066],
            [-81.112971558999959, 35.456325148000076],
            [-81.110898391999967, 35.45755366700007],
            [-81.109300426999937, 35.45845507100006],
            [-81.109202911999944, 35.458510472000057],
            [-81.108274347999952, 35.459079692000046],
            [-81.107448358999989, 35.459656291000044],
            [-81.10646143699995, 35.460389100000043],
            [-81.106406338999989, 35.460430183000028],
            [-81.10623904299996, 35.460557082000037],
            [-81.105381395999984, 35.461216070000035],
            [-81.105299996999975, 35.461281994000046],
            [-81.105287629999964, 35.461270538000065],
            [-81.105077901999948, 35.46107968900003],
            [-81.104561248999971, 35.460636952000073],
            [-81.10362280999999, 35.45986599400004],
            [-81.103328512999951, 35.459629773000074],
            [-81.10225709599996, 35.45878954300008],
            [-81.101393649999977, 35.458155413000043],
            [-81.10048137299998, 35.457568839000032],
            [-81.10032356499994, 35.457475124000041],
            [-81.09943538999994, 35.456953748000046],
            [-81.099312937999969, 35.456882507000046],
            [-81.099256416999935, 35.456849918000046],
            [-81.097976286999938, 35.455686209000078],
            [-81.094855012999972, 35.452345213000058],
            [-81.094604168999979, 35.452082990000065],
            [-81.093899611999973, 35.451407115000052],
            [-81.093004168999983, 35.450599551000039],
            [-81.092953960999978, 35.450554461000024],
            [-81.092143212999986, 35.449875422000048],
            [-81.091279939999936, 35.449241219000044],
            [-81.090367835999984, 35.448654567000062],
            [-81.090087625999956, 35.448489588000029],
            [-81.089244243999985, 35.448003150000034],
            [-81.088567434999959, 35.447631536000074],
            [-81.087569590999976, 35.447147298000061],
            [-81.086535191999985, 35.446717492000062],
            [-81.085468664999951, 35.446343958000057],
            [-81.084807867999984, 35.446145447000049],
            [-81.083970965999981, 35.445909346000064],
            [-81.083537676999981, 35.445792189000031],
            [-81.082420712999976, 35.445535739000036],
            [-81.081285650999973, 35.445339605000072],
            [-81.080618258999948, 35.445259440000029],
            [-81.080329034999977, 35.445174060000056],
            [-81.078511413999934, 35.44462175700005],
            [-81.077685923999979, 35.444389669000032],
            [-81.07759290599995, 35.444365825000034],
            [-81.076272509999967, 35.444030602000055],
            [-81.07524860999996, 35.443797930000073],
            [-81.074113589999968, 35.443601730000069],
            [-81.073308033999979, 35.443500433000054],
            [-81.071861106999961, 35.443346242000075],
            [-81.071518396999977, 35.443312492000075],
            [-81.070361799999944, 35.443239170000027],
            [-81.069201821999968, 35.443227897000043],
            [-81.068520908999972, 35.443250232000025],
            [-81.067385412999954, 35.443305375000079],
            [-81.066907928999967, 35.443333860000052],
            [-81.066193469999973, 35.443396384000039],
            [-81.064143736999938, 35.44361020000008],
            [-81.063706331999981, 35.44366036200006],
            [-81.062668388999953, 35.443816197000046],
            [-81.061427847999937, 35.444033853000064],
            [-81.059015772999942, 35.443494940000051],
            [-81.058892975999981, 35.443467890000079],
            [-81.055867565999961, 35.442810909000059],
            [-81.054071252999961, 35.442386744000032],
            [-81.053389310999989, 35.442237677000037],
            [-81.052858761999971, 35.442137957000057],
            [-81.050618842999938, 35.441746450000039],
            [-81.050014451999971, 35.441649754000025],
            [-81.049577687999943, 35.441590893000068],
            [-81.047342762999961, 35.441313082000079],
            [-81.029447190999974, 35.439008369000078],
            [-81.029357133999952, 35.438996957000029],
            [-81.021610115999977, 35.438031753000075],
            [-81.015089487999944, 35.437154041000042],
            [-81.015006949999986, 35.437143092000042],
            [-81.011848661999977, 35.436730346000047],
            [-81.011182653999981, 35.436656131000063],
            [-81.007973642999957, 35.434715161000042],
            [-81.007942210999943, 35.434696192000047],
            [-81.006985892999978, 35.434158951000029],
            [-81.006861729999969, 35.434094576000064],
            [-81.004027639999947, 35.432638561000033],
            [-81.003524207999988, 35.432389347000026],
            [-81.001710363999962, 35.431524728000056],
            [-81.001340805999973, 35.431353382000054],
            [-81.000307064999959, 35.430922840000051],
            [-81.000186668999959, 35.430877139000074],
            [-80.998818708999977, 35.430362951000063],
            [-80.997873192999975, 35.43003434700006],
            [-80.996779663999973, 35.429717892000042],
            [-80.99668668299995, 35.429693985000029],
            [-80.995102894999945, 35.429290657000024],
            [-80.994079417999956, 35.429057301000057],
            [-80.992963043999964, 35.428863008000064],
            [-80.991685281999935, 35.428675589000079],
            [-80.991351755999972, 35.428611085000057],
            [-80.990664181999989, 35.428104792000056],
            [-80.990362549999986, 35.42790149800004],
            [-80.988985069999956, 35.42699374700004],
            [-80.988486791999946, 35.426677170000062],
            [-80.979054884999982, 35.420901048000076],
            [-80.976847930999952, 35.419450295000047],
            [-80.976386418999937, 35.419157018000078],
            [-80.973945782999976, 35.41765811700003],
            [-80.973816958999976, 35.417579742000044],
            [-80.973377227999947, 35.417323097000065],
            [-80.972269228999949, 35.416697035000027],
            [-80.97175307699996, 35.416416163000065],
            [-80.970756313999971, 35.415930962000061],
            [-80.969722930999978, 35.415500157000054],
            [-80.969344139999976, 35.415359231000025],
            [-80.968109449999986, 35.414914104000047],
            [-80.967422667999983, 35.414680459000067],
            [-80.966329463999955, 35.414363728000069],
            [-80.966236512999956, 35.414339799000061],
            [-80.964521230999935, 35.413902427000039],
            [-80.963498032999951, 35.413668811000036],
            [-80.96238194099999, 35.41347423600007],
            [-80.961504629999979, 35.413345302000039],
            [-80.961486391999983, 35.413342629000056],
            [-80.960338739999941, 35.413206520000074],
            [-80.959581761999971, 35.413150777000055],
            [-80.958176961999982, 35.413072137000029],
            [-80.952675746999944, 35.41272133800004],
            [-80.951160976999972, 35.412591037000027],
            [-80.950320623999971, 35.412535251000065],
            [-80.94918290399994, 35.412481981000042],
            [-80.948982519999959, 35.412473529000067],
            [-80.947822997999936, 35.412461096000072],
            [-80.946980648999954, 35.412491049000039],
            [-80.946063962999972, 35.412541549000025],
            [-80.945748279999975, 35.412561259000029],
            [-80.945303670999976, 35.412596916000041],
            [-80.943864383999937, 35.412727348000033],
            [-80.943157398999972, 35.412803226000051],
            [-80.94277698999997, 35.41285391200006],
            [-80.937270056999978, 35.41363795500007],
            [-80.935531252999965, 35.413859321000075],
            [-80.934452839999949, 35.413927540000032],
            [-80.932642118999979, 35.413956416000076],
            [-80.930053705999967, 35.413852650000024],
            [-80.930001725999944, 35.413850628000034],
            [-80.929334296999969, 35.413835778000077],
            [-80.929167555999982, 35.413834640000061],
            [-80.926552300999958, 35.413210962000051],
            [-80.926083306999942, 35.413104807000025],
            [-80.924062418999938, 35.412671722000027],
            [-80.923828408999952, 35.412622963000047],
            [-80.922694189999959, 35.412425344000042],
            [-80.921546615999944, 35.412288868000076],
            [-80.920928443999969, 35.412241144000063],
            [-80.918652207999969, 35.412098274000073],
            [-80.918114362999972, 35.412071235000042],
            [-80.916954851999947, 35.41205850700004],
            [-80.915796805999946, 35.412107877000039],
            [-80.91464517899999, 35.412219136000033],
            [-80.914616244999934, 35.412222741000051],
            [-80.913308835999942, 35.412386508000054],
            [-80.912197489999983, 35.41255556200008],
            [-80.911073438999949, 35.412788894000073],
            [-80.910793186999967, 35.412857183000028],
            [-80.909576507999986, 35.413162610000029],
            [-80.908753742999977, 35.413387318000048],
            [-80.907676483999978, 35.413738733000059],
            [-80.906629595999959, 35.414147065000066],
            [-80.905617561999975, 35.414610566000079],
            [-80.904644712999982, 35.415127252000048],
            [-80.903715213999988, 35.41569491100006],
            [-80.903557077999949, 35.415799468000046],
            [-80.902954567999984, 35.416202530000078],
            [-80.902230525999983, 35.41671417200007],
            [-80.901399455999979, 35.417376276000027],
            [-80.900713714999938, 35.417959544000041],
            [-80.900465627999949, 35.418174909000072],
            [-80.899437829999954, 35.41908556900006],
            [-80.899324682999975, 35.419190462000074],
            [-80.898502815999962, 35.419592160000036],
            [-80.898435151999934, 35.419625400000029],
            [-80.897462196999982, 35.420142027000054],
            [-80.896532590999982, 35.420709630000033],
            [-80.896374420999962, 35.420814188000065],
            [-80.895771837999973, 35.42121721500007],
            [-80.895047721999958, 35.421728803000065],
            [-80.894216540999935, 35.422390858000028],
            [-80.893530706999968, 35.422974083000042],
            [-80.892754178999951, 35.423679208000067],
            [-80.89203564099995, 35.42442439000007],
            [-80.891837899999985, 35.424648295000054],
            [-80.891335567999988, 35.425228644000072],
            [-80.890875832999939, 35.42578678700005],
            [-80.890865835999989, 35.425799568000059],
            [-80.88801297599997, 35.42945085000008],
            [-80.887955517999956, 35.429529882000054],
            [-80.880284415999938, 35.428844304000052],
            [-80.879907860999936, 35.428813963000039],
            [-80.852846161999935, 35.426868844000069],
            [-80.852515632999939, 35.426847596000073],
            [-80.844901162999975, 35.426416473000074],
            [-80.841835252999942, 35.426142003000052],
            [-80.840738827999985, 35.426071922000062],
            [-80.839579129999947, 35.426058454000042],
            [-80.838005905999978, 35.426082309000037],
            [-80.836847611999985, 35.426130925000052],
            [-80.835695678999969, 35.426241434000076],
            [-80.834555038999952, 35.426413361000073],
            [-80.833430573999976, 35.426645973000063],
            [-80.832418872999938, 35.426911448000055],
            [-80.831115899999986, 35.427288946000033],
            [-80.831024120999984, 35.427315768000028],
            [-80.829946340999982, 35.427666492000071],
            [-80.828898883999955, 35.428074151000033],
            [-80.827886232999958, 35.428537002000041],
            [-80.826912722999964, 35.429053062000037],
            [-80.825982521999947, 35.429620123000063],
            [-80.825892039999985, 35.429679542000031],
            [-80.825116640999966, 35.430192157000079],
            [-80.824324202999946, 35.430748368000025],
            [-80.824091898999939, 35.430924523000044],
            [-80.823354396999946, 35.431493863000071],
            [-80.821245419999968, 35.433019786000045],
            [-80.821055667999985, 35.433159076000038],
            [-80.820223767999948, 35.433820622000042],
            [-80.81944649999997, 35.434525275000055],
            [-80.818915076999986, 35.435072079000065],
            [-80.818429575999971, 35.435338862000037],
            [-80.81622312799999, 35.43619592400006],
            [-80.807710975999953, 35.439243090000048],
            [-80.807542821999959, 35.43930431800004],
            [-80.80738454599998, 35.439201905000061],
            [-80.806429502999947, 35.438663084000041],
            [-80.806413895999981, 35.438654892000045],
            [-80.804821343999947, 35.437819846000025],
            [-80.803840922999939, 35.437341469000046],
            [-80.80280815499998, 35.436909244000049],
            [-80.801943725999934, 35.436598932000038],
            [-80.800093777999962, 35.435982337000041],
            [-80.799893120999968, 35.435916618000078],
            [-80.79916241899997, 35.435696557000028],
            [-80.798043265999979, 35.435382161000064],
            [-80.796800856999937, 35.434918290000041],
            [-80.796740626999963, 35.434895914000037],
            [-80.793390558999988, 35.433657369000059],
            [-80.792579457999977, 35.433377115000042],
            [-80.791486685999985, 35.433058809000045],
            [-80.790510034999954, 35.432832000000076],
            [-80.790453979999938, 35.432797684000036],
            [-80.789499123999974, 35.432258730000058],
            [-80.788503256999945, 35.431772033000072],
            [-80.787558700999966, 35.431373864000079],
            [-80.785089271999936, 35.430408359000069],
            [-80.785001213999976, 35.430374171000039],
            [-80.783936291999964, 35.429997986000046],
            [-80.783632283999964, 35.429902750000053],
            [-80.779742682999938, 35.42871757100005],
            [-80.778784214999973, 35.428307735000033],
            [-80.778130233999946, 35.428041978000067],
            [-80.77706537399996, 35.427665732000037],
            [-80.775972739999986, 35.427347287000032],
            [-80.774857005999934, 35.427088004000041],
            [-80.773917168999958, 35.426918587000046],
            [-80.772250263999979, 35.426656709000042],
            [-80.772056045999989, 35.426627113000052],
            [-80.770908524999982, 35.426489211000046],
            [-80.769772240999941, 35.426413804000049],
            [-80.768459641999982, 35.426361391000057],
            [-80.768439844999989, 35.426360609000028],
            [-80.76728015499998, 35.42634645000004],
            [-80.766121815999952, 35.426394390000041],
            [-80.76595568099998, 35.42640638000006],
            [-80.762556279999956, 35.426664862000052],
            [-80.757235608999963, 35.42702345400005],
            [-80.757217540999989, 35.427024679000056],
            [-80.756801154999948, 35.427057137000077],
            [-80.751914254999974, 35.427485644000058],
            [-80.751586092999958, 35.427516946000026],
            [-80.749936359999936, 35.427687066000033],
            [-80.747848999999974, 35.427595249000035],
            [-80.747745314999975, 35.427590936000058],
            [-80.746585610999944, 35.427576579000061],
            [-80.745953304999944, 35.427594928000076],
            [-80.744627222999952, 35.427652813000066],
            [-80.743201686999953, 35.42756737600007],
            [-80.742035187999988, 35.427459849000059],
            [-80.739290962999974, 35.427075567000031],
            [-80.738779446999956, 35.427010210000049],
            [-80.731301501999951, 35.426145848000033],
            [-80.724958972999957, 35.425270007000051],
            [-80.724933939999971, 35.425266565000072],
            [-80.720199065999964, 35.42461820300008],
            [-80.719476350999969, 35.424531712000032],
            [-80.718235300999936, 35.424404498000058],
            [-80.718040046999988, 35.424349494000069],
            [-80.717678062999937, 35.42425107400004],
            [-80.716562557999964, 35.423991255000033],
            [-80.716032547999987, 35.423890062000055],
            [-80.713048846999982, 35.423359752000067],
            [-80.712444993999952, 35.423261373000059],
            [-80.71129762299995, 35.423122908000039],
            [-80.710161441999958, 35.423046942000042],
            [-80.707536428999958, 35.422940810000057],
            [-80.707516633999944, 35.422940019000066],
            [-80.706357003999983, 35.422925277000047],
            [-80.705327765999982, 35.422967358000051],
            [-80.703687446999936, 35.422757533000038],
            [-80.70182565999994, 35.422507372000041],
            [-80.700966409999978, 35.422409521000077],
            [-80.699810452999941, 35.422332666000045],
            [-80.698650832999988, 35.42231785000007],
            [-80.697165040999948, 35.422338654000043],
            [-80.696006717999978, 35.422385925000071],
            [-80.694854647999989, 35.422495096000034],
            [-80.694255927999961, 35.422576749000029],
            [-80.692687200999956, 35.42281334200004],
            [-80.692297415999974, 35.422877287000063],
            [-80.692165668999962, 35.422865708000074],
            [-80.686210571999936, 35.422376169000074],
            [-80.68609676799997, 35.422367114000053],
            [-80.681643933999965, 35.422024606000036],
            [-80.677625709999973, 35.421611386000052],
            [-80.677432197999963, 35.421592367000073],
            [-80.676276274999964, 35.42151528800008],
            [-80.676024603999963, 35.421506742000076],
            [-80.672613095999964, 35.421410723000065],
            [-80.671705164999935, 35.421404202000076],
            [-80.668582343999958, 35.421447213000079],
            [-80.666157252999938, 35.421416539000063],
            [-80.665585765999936, 35.421416847000046],
            [-80.664953481999987, 35.421434774000033],
            [-80.662831136999955, 35.421525996000071],
            [-80.662218637999956, 35.421427312000048],
            [-80.661961891999965, 35.421387551000066],
            [-80.661798359999977, 35.421363894000024],
            [-80.655679470999985, 35.420502725000063],
            [-80.648149183999976, 35.419172185000036],
            [-80.647577636999983, 35.419079200000056],
            [-80.647361074999935, 35.419048122000049],
            [-80.643591434999962, 35.418526786000029],
            [-80.64266080699997, 35.418418756000051],
            [-80.641504961999942, 35.418341345000044],
            [-80.640345409999952, 35.418325972000048],
            [-80.638947071999951, 35.418344880000063],
            [-80.637788771999965, 35.41839159500006],
            [-80.637344004999989, 35.418426123000074],
            [-80.63542416699994, 35.41859515200008],
            [-80.634716838999964, 35.41866923200007],
            [-80.633575882999935, 35.418839269000046],
            [-80.632450948999974, 35.419070017000024],
            [-80.631944935999968, 35.419197965000023],
            [-80.628892051999969, 35.41841031000007],
            [-80.626328506999982, 35.41770419900007],
            [-80.626097365999954, 35.417641972000069],
            [-80.624982244999956, 35.417381311000042],
            [-80.624842421999972, 35.417353134000052],
            [-80.62247274899994, 35.416883938000069],
            [-80.621479029999989, 35.416711683000074],
            [-80.621100360999947, 35.416658735000055],
            [-80.618120395999938, 35.416269173000046],
            [-80.617351948999953, 35.416182776000028],
            [-80.616572742999949, 35.416123585000037],
            [-80.614597766999964, 35.416009474000077],
            [-80.61434938299999, 35.415938365000045],
            [-80.613234322999972, 35.41567759600008],
            [-80.612100822999935, 35.415477073000034],
            [-80.610953732999974, 35.415337658000055],
            [-80.609797962999949, 35.415259945000059],
            [-80.608638459999952, 35.415244269000027],
            [-80.607480186999965, 35.415290695000067],
            [-80.607391614999983, 35.415299023000046],
            [-80.607078532999935, 35.415065512000069],
            [-80.60652811999995, 35.414629285000046],
            [-80.605319521999945, 35.413061518000063],
            [-80.603733941999963, 35.410975300000075],
            [-80.603261098999951, 35.410385599000051],
            [-80.602570534999984, 35.409622973000069],
            [-80.601820536999981, 35.408898950000037],
            [-80.601454225999987, 35.408577825000066],
            [-80.598456056999964, 35.406027896000069],
            [-80.598183666999944, 35.405333194000036],
            [-80.597757813999976, 35.404450212000029],
            [-80.59726234599998, 35.403591922000032],
            [-80.596699387999934, 35.402761999000063],
            [-80.596071349999988, 35.401963994000027],
            [-80.595380925999962, 35.401201325000045],
            [-80.594631069999934, 35.400477256000045],
            [-80.593824993999988, 35.399794888000031],
            [-80.592966151999974, 35.399157140000057],
            [-80.592058217999977, 35.39856674300006],
            [-80.591105081999956, 35.398026224000034],
            [-80.590110823999964, 35.397537895000028],
            [-80.589079699999957, 35.397103849000075],
            [-80.588016122999989, 35.396725941000057],
            [-80.586924646999989, 35.396405791000063],
            [-80.585809942999958, 35.396144769000045],
            [-80.584676782999964, 35.395943989000045],
            [-80.583530015999941, 35.395804314000031],
            [-80.58237454999994, 35.395726339000078],
            [-80.581215330999953, 35.395710400000041]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 121,
        "SolutionID": "PI-12:J",
        "Shape_Length": 1.5424599005691828,
        "Shape_Area": 0.022438569159462848
      }
    },
    {
      "type": "Feature",
      "id": 122,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.827678009999943, 35.068233889000055],
            [-80.826524788999961, 35.068282410000052],
            [-80.825377892999938, 35.068392827000025],
            [-80.825064403999988, 35.068433967000033],
            [-80.823618090999958, 35.068634741000039],
            [-80.822795914999972, 35.068765432000077],
            [-80.821676343999968, 35.06899794800006],
            [-80.820830820999959, 35.069217002000073],
            [-80.818596102999948, 35.069847080000045],
            [-80.818342936999954, 35.069920230000037],
            [-80.817269821999957, 35.07027086100004],
            [-80.817264015999967, 35.070272943000077],
            [-80.81177435799998, 35.072241709000025],
            [-80.808202432999963, 35.073348335000048],
            [-80.807427736999955, 35.073403928000062],
            [-80.806340642999942, 35.073509951000062],
            [-80.805204870999944, 35.07368161200003],
            [-80.804085182999984, 35.073913965000031],
            [-80.803077776999942, 35.074179206000053],
            [-80.801780298999972, 35.074556410000071],
            [-80.801688888999934, 35.074583216000065],
            [-80.800615641999968, 35.074933700000031],
            [-80.799572564999949, 35.075341129000037],
            [-80.798564125999974, 35.075803762000078],
            [-80.797609797999939, 35.076310970000065],
            [-80.796535081999934, 35.076923358000045],
            [-80.796519921999959, 35.07693200600005],
            [-80.79559352299998, 35.077498867000031],
            [-80.794714192999947, 35.07811431600004],
            [-80.794460685999979, 35.078307615000028],
            [-80.794456026999967, 35.078311238000026],
            [-80.79081415099995, 35.078989209000042],
            [-80.789837213999988, 35.079195347000052],
            [-80.788738276999936, 35.079487266000058],
            [-80.78766490999999, 35.079837634000057],
            [-80.787222690999954, 35.080001920000029],
            [-80.783841325999958, 35.081304341000077],
            [-80.783240321999983, 35.081547356000044],
            [-80.782231712999987, 35.082009852000056],
            [-80.782047696999939, 35.082102107000026],
            [-80.777455241999974, 35.084436077000078],
            [-80.77711557799995, 35.084613116000071],
            [-80.774899412999957, 35.085797498000034],
            [-80.774471150999943, 35.085888670000031],
            [-80.774024140999984, 35.085988995000037],
            [-80.772925055999963, 35.086280769000041],
            [-80.771851531999971, 35.086630996000054],
            [-80.771306614999958, 35.086835068000028],
            [-80.76893242899996, 35.087764452000044],
            [-80.768433964999986, 35.087967551000077],
            [-80.767425191999962, 35.088429923000035],
            [-80.766455357999973, 35.088945528000067],
            [-80.765528613999948, 35.089512157000058],
            [-80.764648927999986, 35.090127387000052],
            [-80.764282428999934, 35.090409114000067],
            [-80.764108027999953, 35.09054704600004],
            [-80.762899667999989, 35.091065897000078],
            [-80.762346487999935, 35.091313847000038],
            [-80.759185727999977, 35.09279126000007],
            [-80.758816205999949, 35.092968882000037],
            [-80.757846263999966, 35.09348441700007],
            [-80.756919408999977, 35.094050980000077],
            [-80.75603960899997, 35.094666147000055],
            [-80.75521063299999, 35.095327285000053],
            [-80.754496958999937, 35.095934955000075],
            [-80.751758606999942, 35.097870571000044],
            [-80.75139747999998, 35.098132957000075],
            [-80.751151774999983, 35.098319979000053],
            [-80.748787797999967, 35.100153824000074],
            [-80.748636359999978, 35.100243542000044],
            [-80.745431549999978, 35.10034719600003],
            [-80.744661359999952, 35.100385964000054],
            [-80.743513901999961, 35.10049560300007],
            [-80.742377622999982, 35.100666673000035],
            [-80.741257384999983, 35.100898442000073],
            [-80.740847324999947, 35.100999545000036],
            [-80.739620406999961, 35.101315415000045],
            [-80.738931058999981, 35.101505781000071],
            [-80.737857187999964, 35.101855705000048],
            [-80.736813453999957, 35.102262592000045],
            [-80.736727275999954, 35.102299318000064],
            [-80.735145197999941, 35.102978080000071],
            [-80.734222231999979, 35.103403448000051],
            [-80.733252009999944, 35.103918785000076],
            [-80.732604988999981, 35.104305797000052],
            [-80.729630445999987, 35.10617110700008],
            [-80.72718506199999, 35.107361796000077],
            [-80.725163361999989, 35.107986272000062],
            [-80.724929076999956, 35.107931505000067],
            [-80.723799578999945, 35.107732016000057],
            [-80.722656626999935, 35.107593651000059],
            [-80.722301168999934, 35.107563331000051],
            [-80.720992722999938, 35.107462704000056],
            [-80.720196666999982, 35.107416363000027],
            [-80.719041519999962, 35.107401742000036],
            [-80.714615874999936, 35.10746464500005],
            [-80.714300758999968, 35.107471431000079],
            [-80.710239114999979, 35.107588645000078],
            [-80.709400384999981, 35.107629272000054],
            [-80.708252777999974, 35.107738576000031],
            [-80.707116323999969, 35.10790931400004],
            [-80.705995886999972, 35.108140757000058],
            [-80.704896260999988, 35.108431911000025],
            [-80.703822152999976, 35.10878153200008],
            [-80.702778160999969, 35.10918812400007],
            [-80.701768753999943, 35.109649946000047],
            [-80.700798252999959, 35.110165022000047],
            [-80.700276369999983, 35.110474038000064],
            [-80.698986533999971, 35.111267479000048],
            [-80.698580967999987, 35.111524584000051],
            [-80.697700539999971, 35.112139324000054],
            [-80.696870913999987, 35.112800060000041],
            [-80.696506425999985, 35.113121684000077],
            [-80.696111390999988, 35.113307792000057],
            [-80.695140809999941, 35.113822822000031],
            [-80.694213288999947, 35.114388903000076],
            [-80.693332796999982, 35.115003611000077],
            [-80.692503105999947, 35.115664317000039],
            [-80.691727766999975, 35.11636818900007],
            [-80.691010100999961, 35.117112219000035],
            [-80.690353182999957, 35.117893218000063],
            [-80.689759824999953, 35.118707844000028],
            [-80.689232568999955, 35.119552610000028],
            [-80.688773676999972, 35.120423899000059],
            [-80.688385113999971, 35.121317981000061],
            [-80.688068545999954, 35.122231028000044],
            [-80.687825329999953, 35.123159130000033],
            [-80.68765651199999, 35.124098314000037],
            [-80.687562814999978, 35.12504455900006],
            [-80.687544643999956, 35.125993812000047],
            [-80.687602079999976, 35.126942009000061],
            [-80.687734879999937, 35.127885090000063],
            [-80.687942474999943, 35.12881901600008],
            [-80.688223982999943, 35.129739786000073],
            [-80.68857819599998, 35.130643458000065],
            [-80.689003602999946, 35.131526162000057],
            [-80.689498382999943, 35.132384116000026],
            [-80.690060418999963, 35.133213647000048],
            [-80.690687305999973, 35.134011200000032],
            [-80.691376359999936, 35.134773361000043],
            [-80.692124630999956, 35.135496864000061],
            [-80.692928915999971, 35.136178609000069],
            [-80.693785769999977, 35.136815677000072],
            [-80.694691525999986, 35.137405339000054],
            [-80.695642300999964, 35.137945067000032],
            [-80.69663402599997, 35.13843255200004],
            [-80.697662450999985, 35.138865702000032],
            [-80.698723171999973, 35.139242664000051],
            [-80.699811643999965, 35.139561822000076],
            [-80.700923203999935, 35.139821807000033],
            [-80.701212090999945, 35.139878937000049],
            [-80.701503657999979, 35.139934469000025],
            [-80.702344657999959, 35.140077039000062],
            [-80.703488031999939, 35.140215596000075],
            [-80.704639992999944, 35.140292420000037],
            [-80.705795602999956, 35.140307179000047],
            [-80.706949913999949, 35.140259812000068],
            [-80.708097979999934, 35.140150520000077],
            [-80.709234881999976, 35.139979774000039],
            [-80.710355746999937, 35.139748303000033],
            [-80.71145577599998, 35.139457100000072],
            [-80.71253025499999, 35.139107412000044],
            [-80.713574581999978, 35.138700737000079],
            [-80.714584281999976, 35.138238818000048],
            [-80.715555029999962, 35.137723633000064],
            [-80.715867227999979, 35.137541639000062],
            [-80.716081645999964, 35.137413753000033],
            [-80.716632047999951, 35.137131727000053],
            [-80.717349400999979, 35.136743938000052],
            [-80.717416551999975, 35.136702946000071],
            [-80.717758762999949, 35.13680323300008],
            [-80.718870342999935, 35.137063054000066],
            [-80.720000234999986, 35.137262588000056],
            [-80.721114614999976, 35.137398243000064],
            [-80.722205804999987, 35.137501971000063],
            [-80.72223478899997, 35.137504706000072],
            [-80.723386728999969, 35.137581351000051],
            [-80.724542304999943, 35.137595931000078],
            [-80.725703557999964, 35.137579352000046],
            [-80.726857818999974, 35.137531795000029],
            [-80.728005819999964, 35.137422315000038],
            [-80.729142641999942, 35.137251381000056],
            [-80.730115128999955, 35.137054076000027],
            [-80.734305613999936, 35.136099431000048],
            [-80.734453893999955, 35.136065074000044],
            [-80.735553786999958, 35.135773652000069],
            [-80.735800887999972, 35.135699052000064],
            [-80.741065865999985, 35.134072710000055],
            [-80.74189305799996, 35.13379737200006],
            [-80.742937169999948, 35.133390443000053],
            [-80.743853965999961, 35.132973717000027],
            [-80.748523965999937, 35.130699609000033],
            [-80.748616631999937, 35.130654167000046],
            [-80.749587074999965, 35.130138708000061],
            [-80.750234219999982, 35.12975160600007],
            [-80.751120372999935, 35.129195847000062],
            [-80.752758220999965, 35.129132490000075],
            [-80.753393394999989, 35.12909847800006],
            [-80.754541241999959, 35.128988745000072],
            [-80.754733146999968, 35.128964260000032],
            [-80.756253268999956, 35.128763258000049],
            [-80.757198006999943, 35.128616548000025],
            [-80.758318580999969, 35.128384631000074],
            [-80.759418284999981, 35.128092992000063],
            [-80.759774536999942, 35.127984248000075],
            [-80.760567650999974, 35.12773413900004],
            [-80.761285517999966, 35.127492763000078],
            [-80.761566639999955, 35.127389856000036],
            [-80.763222829999961, 35.126769312000079],
            [-80.763985629999979, 35.126465112000062],
            [-80.764071811999941, 35.126428369000052],
            [-80.765654104999953, 35.125749232000032],
            [-80.766577168999959, 35.125323622000053],
            [-80.767547434999983, 35.124808018000067],
            [-80.767806015999952, 35.124657685000045],
            [-80.770386041999984, 35.123128940000072],
            [-80.771054565999975, 35.122712616000058],
            [-80.771934554999973, 35.122097341000028],
            [-80.772180283999944, 35.12191027800003],
            [-80.775095335999936, 35.119648463000033],
            [-80.778145284999937, 35.117492140000024],
            [-80.778506410999967, 35.117229675000033],
            [-80.77877370899995, 35.117016454000066],
            [-80.779529982999975, 35.116662910000059],
            [-80.781839316999935, 35.115671211000063],
            [-80.78276217399997, 35.115245480000056],
            [-80.783732217999955, 35.11472974600008],
            [-80.784332664999965, 35.114371496000047],
            [-80.785864525999955, 35.113416468000025],
            [-80.786677099999963, 35.113200731000063],
            [-80.787750910999989, 35.112850373000072],
            [-80.78778475699994, 35.112838227000054],
            [-80.790377120999949, 35.111905249000074],
            [-80.791386865999982, 35.111510047000024],
            [-80.792395787999965, 35.111047477000056],
            [-80.792919597999969, 35.110778124000035],
            [-80.796194138999965, 35.109027935000029],
            [-80.799747451999963, 35.107221890000062],
            [-80.800474991999977, 35.106941644000074],
            [-80.801970561999951, 35.106663214000037],
            [-80.805327843999976, 35.106195355000068],
            [-80.80613436799996, 35.106067015000065],
            [-80.807254492999959, 35.105834644000026],
            [-80.807997396999951, 35.105644422000069],
            [-80.80936788799994, 35.105266064000034],
            [-80.809724205999942, 35.105164197000079],
            [-80.810797810999986, 35.104813634000038],
            [-80.811841208999965, 35.104406108000035],
            [-80.812397407999981, 35.104159775000028],
            [-80.813331246999951, 35.103728285000045],
            [-80.813783760999968, 35.103511873000059],
            [-80.814753468999982, 35.102995889000056],
            [-80.81568002399996, 35.102428882000027],
            [-80.816559457999972, 35.10181328200008],
            [-80.816974327999958, 35.101482028000078],
            [-80.817050608999978, 35.101467857000046],
            [-80.818038482999953, 35.101259500000026],
            [-80.819137607999949, 35.100967316000037],
            [-80.819384527999944, 35.100892545000079],
            [-80.824645590999978, 35.099262555000053],
            [-80.825472146999971, 35.098986643000046],
            [-80.825477955999986, 35.098984561000066],
            [-80.826299117999952, 35.098690074000046],
            [-80.827088136999976, 35.09907695000004],
            [-80.828116757999965, 35.09950899200004],
            [-80.829177568999967, 35.099884811000038],
            [-80.829599022999957, 35.100015781000025],
            [-80.83054877699999, 35.100299587000052],
            [-80.831215783999937, 35.100486595000064],
            [-80.831660392999936, 35.100597880000066],
            [-80.833776229999955, 35.101102529000059],
            [-80.834443073999978, 35.101250011000047],
            [-80.835185770999942, 35.10138762400004],
            [-80.838463714999989, 35.101934167000024],
            [-80.838594871999987, 35.101955608000026],
            [-80.839614535999942, 35.102119000000073],
            [-80.839870365999957, 35.102158387000031],
            [-80.841013439999983, 35.102295644000037],
            [-80.842164993999972, 35.10237115700005],
            [-80.843320090999953, 35.10238460100004],
            [-80.844843544999947, 35.102361341000062],
            [-80.844917392999946, 35.102360085000043],
            [-80.860595022999973, 35.102065659000061],
            [-80.861450281999964, 35.102306428000077],
            [-80.861811013999954, 35.102404426000078],
            [-80.862040742999966, 35.102463174000036],
            [-80.864741269999968, 35.103137187000073],
            [-80.865623123999967, 35.103336916000046],
            [-80.866752905999988, 35.10353507800005],
            [-80.867896044999952, 35.103672080000024],
            [-80.868444803999978, 35.103715719000036],
            [-80.869898461999981, 35.103812502000039],
            [-80.87050130199998, 35.103844113000036],
            [-80.871656424999969, 35.10385728600005],
            [-80.872810121999976, 35.103808335000053],
            [-80.873502353999982, 35.10374893900007],
            [-80.874877990999948, 35.103608313000052],
            [-80.875333085999955, 35.103556836000052],
            [-80.876469125999961, 35.103384516000062],
            [-80.877589012999977, 35.103151493000041],
            [-80.878687949999971, 35.102858767000043],
            [-80.87976122799995, 35.102507591000062],
            [-80.880397932999983, 35.102266670000063],
            [-80.881332765999957, 35.101894272000038],
            [-80.881739079999988, 35.101727069000049],
            [-80.882244665999963, 35.101503644000047],
            [-80.886207388999935, 35.099683480000067],
            [-80.887860218999947, 35.099862952000024],
            [-80.888201409999965, 35.099897223000028],
            [-80.889352973999962, 35.099972286000025],
            [-80.89050804499999, 35.099985279000066],
            [-80.891661674999966, 35.09993614800004],
            [-80.89280892499994, 35.099825101000079],
            [-80.893944875999978, 35.099652617000061],
            [-80.895064660999935, 35.099419431000058],
            [-80.896163484999988, 35.099126546000036],
            [-80.89723663999996, 35.098775215000046],
            [-80.898279527999989, 35.09836694300003],
            [-80.899287680999976, 35.097903479000024],
            [-80.900256780999939, 35.097386809000056],
            [-80.901182676999952, 35.09681914600003],
            [-80.902061404999984, 35.096202923000078],
            [-80.902889198999958, 35.095540778000043],
            [-80.903662516999987, 35.094835550000028],
            [-80.904378045999977, 35.094090257000062],
            [-80.905032722999977, 35.093308094000065],
            [-80.905623744999957, 35.092492410000034],
            [-80.906148583999936, 35.09164669900008],
            [-80.906604991999984, 35.090774583000041],
            [-80.906991017999985, 35.089879798000027],
            [-80.907305009999959, 35.088966177000032],
            [-80.907545626999934, 35.088037631000077],
            [-80.907711838999944, 35.087098138000044],
            [-80.907802938999964, 35.086151720000032],
            [-80.907818537999958, 35.085202432000074],
            [-80.907758572999967, 35.084254337000061],
            [-80.907623301999934, 35.083311495000032],
            [-80.907413308999935, 35.082377944000029],
            [-80.907129491999967, 35.081457682000064],
            [-80.906773072999954, 35.080554647000042],
            [-80.906345576999968, 35.079672707000043],
            [-80.90584883799994, 35.078815636000058],
            [-80.905284983999934, 35.07798710600008],
            [-80.904656431999967, 35.077190662000078],
            [-80.903965873999937, 35.076429714000028],
            [-80.903216267999937, 35.075707519000048],
            [-80.902410823999958, 35.075027171000045],
            [-80.901552991999949, 35.074391578000075],
            [-80.900646442999971, 35.073803464000036],
            [-80.899695060999989, 35.073265345000038],
            [-80.89870291699998, 35.072779525000044],
            [-80.897674259999974, 35.072348082000076],
            [-80.896613491999972, 35.071972865000077],
            [-80.895525153999984, 35.071655478000025],
            [-80.894413903999975, 35.071397280000042],
            [-80.893284498999947, 35.071199375000049],
            [-80.892482844999961, 35.071096872000055],
            [-80.890082880999955, 35.070836266000072],
            [-80.889741810999965, 35.070802000000072],
            [-80.888756497999964, 35.07073391800003],
            [-80.885851696999964, 35.07060040600004],
            [-80.885685858999977, 35.070593424000037],
            [-80.884531203999984, 35.070580385000028],
            [-80.884027447999983, 35.070594167000024],
            [-80.881490739999947, 35.070693366000057],
            [-80.880841274999966, 35.070728634000034],
            [-80.879694417999985, 35.070839568000054],
            [-80.878558837999947, 35.071011918000067],
            [-80.87796231599998, 35.071128115000079],
            [-80.876735007999969, 35.071385760000055],
            [-80.876212083999974, 35.071502591000069],
            [-80.875113559999988, 35.071795296000062],
            [-80.874040664999939, 35.072146432000068],
            [-80.872997990999977, 35.072554496000066],
            [-80.872712181999987, 35.072678674000031],
            [-80.870485969999947, 35.073667425000053],
            [-80.870266341999979, 35.07376663000008],
            [-80.869764294999982, 35.073997230000032],
            [-80.869006682999952, 35.073821032000069],
            [-80.868893250999974, 35.073798244000045],
            [-80.867217112999981, 35.07346633700007],
            [-80.866201175999947, 35.073290968000038],
            [-80.865058465999937, 35.073153950000062],
            [-80.863907302999962, 35.073078655000074],
            [-80.863789283999949, 35.073074448000057],
            [-80.862555097999973, 35.073033854000073],
            [-80.861518431999968, 35.073024803000067],
            [-80.861444610999968, 35.073026068000047],
            [-80.844557643999963, 35.073343354000031],
            [-80.844239523999988, 35.073290315000065],
            [-80.843225347999976, 35.072581736000075],
            [-80.843184537999946, 35.072553310000046],
            [-80.842278429999965, 35.071964758000036],
            [-80.841327454999941, 35.071426179000071],
            [-80.840335680999942, 35.070939879000036],
            [-80.839307355999949, 35.070507939000038],
            [-80.839013785999953, 35.070397111000034],
            [-80.837770254999953, 35.069938929000045],
            [-80.837003355999968, 35.069674018000057],
            [-80.83634621899995, 35.069474143000036],
            [-80.834959026999968, 35.069077757000059],
            [-80.834528091999971, 35.068959702000029],
            [-80.833417071999975, 35.068700943000067],
            [-80.832287852999968, 35.068502468000077],
            [-80.831145266999954, 35.068365129000028],
            [-80.829994202999956, 35.068289510000056],
            [-80.829940772999976, 35.068287511000051],
            [-80.828779190999967, 35.068245472000058],
            [-80.827678009999943, 35.068233889000055]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 122,
        "SolutionID": "PI-12:C",
        "Shape_Length": 0.49385426867031224,
        "Shape_Area": 0.006688876541450276
      }
    },
    {
      "type": "Feature",
      "id": 123,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.901906655999937, 34.991301323000073],
            [-80.900858260999939, 34.991343570000026],
            [-80.89989008699996, 34.991406398000038],
            [-80.897216050999987, 34.991579882000053],
            [-80.89454051499996, 34.991579878000039],
            [-80.893610816999967, 34.991550137000047],
            [-80.892538411999965, 34.991365853000048],
            [-80.889605611999968, 34.990698559000066],
            [-80.889145323999969, 34.990599307000025],
            [-80.888204535999989, 34.990429865000067],
            [-80.886000946999957, 34.990084570000079],
            [-80.885886667999955, 34.99006367700008],
            [-80.88497915499994, 34.989495742000031],
            [-80.884772495999982, 34.989368365000075],
            [-80.883822201999976, 34.988830116000031],
            [-80.882831177999947, 34.988344161000043],
            [-80.88216179799997, 34.988055126000063],
            [-80.88027374699999, 34.987281863000078],
            [-80.879915622999988, 34.987139311000078],
            [-80.878856041999938, 34.986763935000056],
            [-80.87776890799995, 34.986446385000079],
            [-80.876658873999986, 34.986188022000078],
            [-80.875530689999948, 34.985989950000032],
            [-80.874389184999984, 34.985853018000057],
            [-80.87323924399999, 34.985777811000048],
            [-80.87241821899994, 34.985762073000046],
            [-80.871796055999937, 34.985762073000046],
            [-80.871463626999969, 34.985764651000068],
            [-80.870311596999954, 34.985813590000078],
            [-80.869949935999955, 34.985841830000027],
            [-80.862848683999971, 34.986456917000055],
            [-80.862754658999961, 34.986465268000075],
            [-80.854065811999988, 34.987256087000048],
            [-80.853375789999973, 34.987330235000059],
            [-80.852241314999958, 34.987502339000059],
            [-80.851122940999971, 34.987735130000033],
            [-80.85002545399999, 34.988027609000028],
            [-80.848953551999955, 34.98837852500003],
            [-80.847911820999968, 34.988786376000064],
            [-80.846904723999955, 34.989249416000064],
            [-80.845936568999946, 34.98976566400006],
            [-80.845011501999977, 34.990332909000074],
            [-80.844133482999951, 34.990948724000077],
            [-80.843306272999939, 34.991610473000037],
            [-80.842533410999977, 34.992315322000024],
            [-80.841818209999985, 34.993060256000035],
            [-80.841163730999938, 34.99384208400005],
            [-80.840572778999956, 34.994657461000031],
            [-80.840047883999944, 34.995502895000072],
            [-80.839591297999959, 34.996374768000067],
            [-80.839204975999962, 34.997269345000063],
            [-80.838890574999937, 34.998182798000073],
            [-80.838649441999962, 34.999111215000028],
            [-80.838482614999975, 35.000050621000071],
            [-80.838390806999939, 35.000996995000037],
            [-80.838374417999944, 35.001946283000052],
            [-80.838433516999942, 35.002894419000029],
            [-80.838567855999941, 35.003837347000058],
            [-80.838776860999985, 35.004771024000036],
            [-80.839059640999949, 35.005691454000043],
            [-80.839414984999962, 35.006594696000036],
            [-80.839841374999935, 35.007476879000023],
            [-80.840336986999944, 35.008334227000034],
            [-80.840899700999955, 35.009163066000042],
            [-80.84152710799998, 35.009959848000051],
            [-80.842216521999944, 35.010721157000035],
            [-80.842964991999963, 35.011443736000047],
            [-80.843769313999985, 35.012124485000072],
            [-80.844626041999959, 35.012760492000041],
            [-80.845531509999944, 35.013349031000075],
            [-80.846481836999942, 35.013887579000027],
            [-80.847472953999954, 35.014373832000047],
            [-80.848500615999967, 35.014805705000072],
            [-80.849560420999978, 35.015181347000066],
            [-80.850647827999978, 35.015499150000039],
            [-80.851758177999955, 35.01575775200007],
            [-80.852886715999944, 35.015956046000042],
            [-80.854028604999939, 35.016093180000041],
            [-80.855178955999975, 35.016168570000048],
            [-80.856332835999979, 35.016181890000041],
            [-80.857485302999976, 35.016133085000035],
            [-80.857941160999985, 35.016096535000031],
            [-80.866586023999957, 35.015309720000062],
            [-80.867784318999952, 35.015205957000035],
            [-80.868221539999979, 35.01545360700004],
            [-80.86921280599995, 35.015939681000077],
            [-80.870044312999937, 35.016294260000052],
            [-80.87088126499998, 35.016628264000076],
            [-80.871077555999989, 35.016705370000068],
            [-80.872137488999954, 35.017080814000053],
            [-80.873225008999952, 35.01739841400007],
            [-80.874335456999972, 35.017656808000027],
            [-80.874554584999942, 35.017700260000026],
            [-80.876013944999954, 35.017981529000053],
            [-80.876210114999935, 35.018018367000025],
            [-80.878613772999984, 35.018457849000072],
            [-80.879139557999963, 35.018547089000037],
            [-80.880900167999982, 35.018822981000028],
            [-80.883682766999982, 35.019456099000024],
            [-80.884143211999969, 35.01955537200007],
            [-80.884777184999962, 35.019674400000042],
            [-80.88784614399998, 35.02020176700006],
            [-80.888340844999959, 35.020280718000038],
            [-80.889482854999983, 35.020417517000055],
            [-80.890633298999944, 35.020492568000066],
            [-80.890765716999965, 35.02049721700007],
            [-80.893512788999942, 35.020585111000059],
            [-80.894197495999947, 35.020596062000038],
            [-80.897910334999949, 35.020596062000038],
            [-80.898247156999957, 35.02059341000006],
            [-80.89929592499999, 35.020551186000034],
            [-80.902419795999947, 35.020348530000035],
            [-80.903033110999957, 35.020322728000053],
            [-80.903380229999982, 35.020311795000055],
            [-80.904201461999946, 35.020656412000051],
            [-80.904296285999976, 35.020692755000027],
            [-80.906293048999942, 35.021452117000024],
            [-80.90677123599994, 35.021626928000046],
            [-80.908268820999979, 35.022152637000033],
            [-80.908755970999948, 35.022316614000033],
            [-80.909843702999979, 35.022633884000072],
            [-80.910142557999961, 35.022709701000053],
            [-80.910855707999985, 35.022884939000051],
            [-80.911667490999946, 35.023067174000062],
            [-80.912796273999959, 35.023264907000055],
            [-80.913938366999957, 35.023401475000071],
            [-80.915088874999981, 35.023476293000044],
            [-80.915902457999948, 35.023491743000079],
            [-80.916686941999956, 35.023491742000033],
            [-80.917027351999934, 35.023489037000047],
            [-80.918179885999962, 35.023439652000036],
            [-80.919326024999975, 35.023328353000068],
            [-80.920460854999988, 35.023155618000033],
            [-80.921579515999952, 35.022922184000038],
            [-80.921925643999941, 35.022836781000024],
            [-80.922860335999985, 35.022597537000024],
            [-80.923659272999942, 35.022410570000034],
            [-80.926446141999975, 35.021899538000071],
            [-80.927576377999969, 35.02185647400006],
            [-80.934395165999945, 35.021856494000076],
            [-80.934591304999969, 35.022144706000063],
            [-80.934878261999984, 35.022522533000028],
            [-80.935266992999971, 35.023017284000048],
            [-80.935608459999969, 35.023435749000043],
            [-80.93629886399998, 35.024196520000032],
            [-80.93704828999995, 35.024918514000035],
            [-80.937853528999938, 35.025598635000051],
            [-80.938417257999959, 35.026025595000078],
            [-80.939125488999935, 35.026539057000036],
            [-80.939419365999981, 35.026747433000025],
            [-80.940065779999941, 35.027174700000046],
            [-80.945496283999944, 35.030594201000042],
            [-80.946380249999947, 35.031241161000025],
            [-80.946671608999964, 35.031568931000038],
            [-80.946740305999981, 35.031645683000079],
            [-80.948582379999948, 35.033689568000057],
            [-80.950560962999987, 35.036082313000065],
            [-80.95067518999997, 35.036218763000079],
            [-80.951126847999944, 35.036727812000038],
            [-80.954655688999935, 35.040528582000036],
            [-80.955171613999937, 35.041167244000064],
            [-80.955393134999952, 35.041434931000026],
            [-80.95608387599998, 35.042195587000037],
            [-80.956833641999935, 35.042917456000055],
            [-80.957639222999944, 35.043597444000056],
            [-80.958497167999951, 35.044232640000075],
            [-80.959403804999965, 35.044820322000078],
            [-80.960355248999974, 35.045357972000033],
            [-80.961088778999965, 35.045723677000069],
            [-80.961898256999973, 35.046105419000071],
            [-80.96260209899998, 35.046474992000071],
            [-80.963578761999941, 35.046952120000071],
            [-80.964607447999981, 35.047383002000061],
            [-80.965250989999959, 35.047618323000052],
            [-80.965335317999973, 35.047647514000062],
            [-80.970497943999987, 35.051795974000072],
            [-80.970602649999989, 35.051879411000073],
            [-80.971019128999956, 35.05219809700003],
            [-80.974295248999965, 35.054624963000037],
            [-80.974008052999977, 35.058771914000033],
            [-80.973997824999969, 35.058935959000053],
            [-80.973988942999938, 35.05912249000005],
            [-80.97391676899997, 35.060936768000033],
            [-80.973910825999951, 35.061699536000049],
            [-80.973971542999948, 35.062647600000048],
            [-80.974107545999971, 35.063590370000043],
            [-80.974318253999968, 35.064523807000057],
            [-80.97460276999999, 35.065443913000024],
            [-80.974959872999989, 35.066346748000058],
            [-80.975388039999984, 35.067228447000048],
            [-80.975885438999967, 35.068085232000044],
            [-80.976449940999942, 35.068913433000034],
            [-80.977079129999936, 35.069709504000059],
            [-80.977770311999961, 35.070470034000039],
            [-80.978520529999969, 35.071191767000073],
            [-80.979326570999945, 35.071871608000038],
            [-80.980184980999979, 35.072506648000058],
            [-80.981092088999958, 35.073094165000043],
            [-80.982044004999977, 35.073631642000066],
            [-80.983036654999978, 35.074116778000075],
            [-80.984065784999984, 35.074547493000068],
            [-80.98512698899998, 35.07492194200006],
            [-80.986215718999972, 35.075238521000074],
            [-80.987327310999945, 35.075495873000079],
            [-80.988457001999961, 35.075692898000057],
            [-80.98959995499996, 35.075828749000038],
            [-80.990751270999965, 35.07590284500003],
            [-80.991906016999963, 35.07591486900003],
            [-80.993059245999973, 35.075864769000077],
            [-80.994206018999989, 35.075752759000068],
            [-80.995341418999942, 35.075579320000031],
            [-80.996460583999976, 35.075345194000079],
            [-80.997558719999972, 35.075051386000041],
            [-80.99863111999997, 35.07469915300004],
            [-80.999673192999978, 35.07429000500008],
            [-81.000680471999942, 35.07382569400005],
            [-81.001648643999943, 35.073308209000061],
            [-81.002573561999952, 35.072739768000076],
            [-81.003451263999978, 35.072122805000049],
            [-81.004277990999981, 35.071459964000042],
            [-81.005050203999986, 35.070754086000079],
            [-81.00576459499996, 35.070008190000067],
            [-81.006418106999945, 35.069225476000042],
            [-81.007007941999973, 35.068409294000048],
            [-81.007531574999973, 35.067563141000051],
            [-81.007986764999941, 35.066690640000047],
            [-81.008371564999948, 35.06579552900007],
            [-81.008684328999948, 35.064881642000046],
            [-81.008923721999963, 35.063952892000032],
            [-81.009088717999987, 35.06301325700008],
            [-81.009178615999986, 35.06206676100004],
            [-81.009187417999954, 35.061880228000064],
            [-81.009251906999964, 35.060241314000052],
            [-81.009667218999937, 35.054206649000037],
            [-81.009677374999967, 35.054042600000059],
            [-81.009691782999937, 35.053093296000043],
            [-81.009642461999988, 35.051090151000039],
            [-81.009591053999941, 35.050238888000024],
            [-81.009435136999969, 35.048619226000028],
            [-81.009425423999971, 35.048522441000046],
            [-81.009289037999963, 35.047579710000036],
            [-81.009077973999979, 35.046646333000069],
            [-81.008793134999962, 35.045726306000063],
            [-81.00846852899997, 35.04489865000005],
            [-81.007976422999945, 35.043761715000073],
            [-81.007943642999976, 35.043686634000039],
            [-81.007515247999947, 35.042805048000048],
            [-81.007017670999971, 35.041948391000062],
            [-81.00645304599999, 35.041120331000059],
            [-81.005823790999955, 35.040324410000039],
            [-81.005688886999963, 35.040167633000067],
            [-81.004375626999945, 35.038662620000025],
            [-81.003819361999945, 35.038059019000059],
            [-81.003507557999967, 35.037747405000061],
            [-81.001962427999956, 35.036246216000052],
            [-81.001524110999981, 35.035836246000031],
            [-81.000718240999959, 35.035156551000057],
            [-81.000301733999947, 35.034837967000044],
            [-80.994895677999978, 35.030834306000031],
            [-80.988051545999951, 35.025335802000029],
            [-80.987946855999951, 35.025252379000051],
            [-80.987088880999977, 35.024617392000039],
            [-80.986182268999983, 35.024029917000064],
            [-80.985230903999934, 35.023492469000075],
            [-80.984668824999972, 35.023208757000077],
            [-80.983142849999979, 35.02247043400007],
            [-80.98271288899997, 35.022269021000056],
            [-80.98169214699999, 35.021841527000049],
            [-80.979453483999976, 35.019431212000029],
            [-80.977544905999935, 35.017124099000057],
            [-80.977430660999971, 35.016987673000074],
            [-80.977129478999984, 35.016643222000027],
            [-80.975107937999951, 35.014401078000049],
            [-80.973741170999972, 35.012864071000024],
            [-80.973282955999935, 35.012371237000025],
            [-80.972533324999972, 35.01164946800003],
            [-80.97172793499999, 35.010969578000072],
            [-80.971227469999974, 35.010588891000054],
            [-80.968452883999987, 35.008558667000045],
            [-80.968095667999989, 35.008304241000076],
            [-80.967449256999942, 35.007877124000061],
            [-80.964663200999951, 35.006123168000045],
            [-80.964605940999945, 35.005989571000043],
            [-80.964558458999988, 35.005880183000045],
            [-80.964130729999965, 35.004998441000055],
            [-80.963638089999961, 35.004148359000055],
            [-80.963416925999979, 35.003796587000068],
            [-80.963412674999972, 35.003789829000027],
            [-80.962848754999982, 35.002961562000053],
            [-80.962503599999934, 35.002510321000045],
            [-80.962218346999975, 35.002152161000026],
            [-80.96193496799998, 35.001807252000049],
            [-80.961290042999963, 35.001093701000059],
            [-80.961276626999961, 35.001079770000047],
            [-80.961267110999984, 35.00106381300003],
            [-80.961094517999982, 35.000782165000032],
            [-80.96053064299997, 34.999953886000071],
            [-80.959902151999984, 34.999157724000042],
            [-80.959211739999944, 34.998397086000068],
            [-80.958462360999988, 34.997675229000038],
            [-80.957657225999981, 34.996995243000072],
            [-80.957550732999948, 34.996911701000045],
            [-80.957435655999973, 34.99682217700007],
            [-80.956684708999944, 34.99627051300007],
            [-80.955778629999941, 34.995682808000026],
            [-80.954827794999972, 34.995145120000075],
            [-80.95383627199999, 34.99465974900005],
            [-80.952808307999987, 34.994228773000032],
            [-80.951748299999963, 34.993854036000073],
            [-80.950660788999983, 34.993537144000072],
            [-80.949550427999952, 34.993279452000024],
            [-80.94842196999997, 34.993082062000042],
            [-80.947280243999955, 34.992945820000045],
            [-80.946130135999965, 34.992871309000066],
            [-80.944976570999984, 34.992858847000036],
            [-80.944767924999951, 34.992862212000034],
            [-80.943615837999971, 34.992911851000031],
            [-80.943483489999949, 34.992922518000057],
            [-80.942896240999971, 34.992827663000071],
            [-80.94175452799999, 34.992691370000045],
            [-80.940624085999957, 34.992617557000074],
            [-80.940275889999953, 34.992604075000031],
            [-80.940256231999967, 34.992603322000036],
            [-80.93910267199999, 34.992590805000077],
            [-80.937950585999943, 34.992640390000076],
            [-80.936804902999938, 34.992751867000038],
            [-80.936724268999967, 34.992762080000034],
            [-80.93665404099994, 34.992771112000071],
            [-80.93504930499995, 34.99284029100005],
            [-80.927169274999983, 34.992840276000038],
            [-80.926826299999959, 34.992843009000069],
            [-80.926352828999939, 34.992855811000027],
            [-80.923287301999949, 34.992972598000051],
            [-80.922608673999946, 34.993009247000032],
            [-80.921462964999989, 34.99312057800006],
            [-80.92032855299999, 34.993293323000046],
            [-80.920266908999963, 34.993304533000071],
            [-80.917974563999962, 34.993724913000051],
            [-80.917333584999938, 34.993455940000047],
            [-80.917320662999941, 34.993450954000025],
            [-80.916108647999977, 34.992983795000043],
            [-80.915061745999935, 34.992613722000044],
            [-80.913974393999979, 34.992296499000076],
            [-80.912864164999974, 34.992038469000079],
            [-80.912245618999975, 34.991922213000066],
            [-80.910891068999945, 34.99168863500006],
            [-80.910381264999955, 34.991607152000029],
            [-80.909239622999962, 34.991470551000077],
            [-80.909111921999965, 34.99145915400004],
            [-80.907757382999989, 34.991342366000026],
            [-80.90673503499994, 34.99127887700007],
            [-80.905919788999938, 34.991263366000055],
            [-80.904066216999979, 34.991263365000066],
            [-80.903727925999988, 34.991266034000034],
            [-80.903391384999964, 34.99127399200006],
            [-80.902362408999977, 34.991306411000039],
            [-80.901906655999937, 34.991301323000073]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 123,
        "SolutionID": "PI-12:B",
        "Shape_Length": 0.43155970973156221,
        "Shape_Area": 0.0059120231901315917
      }
    },
    {
      "type": "Feature",
      "id": 124,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-81.027835888999959, 34.912180784000043],
            [-81.026684989999978, 34.912231216000066],
            [-81.025540554999964, 34.912343536000037],
            [-81.024407484999983, 34.912517261000062],
            [-81.023290628999973, 34.912751650000075],
            [-81.022194764999938, 34.91304569600004],
            [-81.021124585999985, 34.913398143000052],
            [-81.02008467099995, 34.913807482000038],
            [-81.019079473999966, 34.914271962000043],
            [-81.018113294999978, 34.914789594000069],
            [-81.017190271999937, 34.915358161000029],
            [-81.016314355999953, 34.915975232000051],
            [-81.015489298999967, 34.916638165000052],
            [-81.014718630999937, 34.917344120000052],
            [-81.014005654999949, 34.918090078000034],
            [-81.013353423999945, 34.918872844000077],
            [-81.012764731999937, 34.919689067000036],
            [-81.01224209999998, 34.920535254000072],
            [-81.011787768999966, 34.921407782000074],
            [-81.011403685999937, 34.922302915000046],
            [-81.011091496999938, 34.92321682100004],
            [-81.010852540999963, 34.924145586000066],
            [-81.010687845999939, 34.925085234000051],
            [-81.010598115999983, 34.926031743000067],
            [-81.010583740999948, 34.926981058000024],
            [-81.010644782999975, 34.92792911500004],
            [-81.01078098499994, 34.928871854000079],
            [-81.010991763999982, 34.929805238000029],
            [-81.011276223999971, 34.93072526800006],
            [-81.011633143999973, 34.931628006000039],
            [-81.01206100099995, 34.932509585000048],
            [-81.012557963999939, 34.933366229000058],
            [-81.01312190699997, 34.934194269000045],
            [-81.013750415999937, 34.934990158000062],
            [-81.014440800999978, 34.935750488000053],
            [-81.015190105999977, 34.936472001000027],
            [-81.015995122999982, 34.937151605000054],
            [-81.016852403999962, 34.937786392000078],
            [-81.017407946999981, 34.938155857000027],
            [-81.022759182999948, 34.941569210000068],
            [-81.032714229999954, 34.948222771000076],
            [-81.032843010999954, 34.948308009000073],
            [-81.03658977799995, 34.950764076000041],
            [-81.037126839999985, 34.951102451000054],
            [-81.038077703999988, 34.951639492000027],
            [-81.039069204999976, 34.952124173000072],
            [-81.040009449999957, 34.95252041100008],
            [-81.041414010999972, 34.953069205000077],
            [-81.04150165599998, 34.953103208000073],
            [-81.042561545999945, 34.953477156000076],
            [-81.043384538999987, 34.953722411000058],
            [-81.044364894999944, 34.953992076000077],
            [-81.04462923799997, 34.954062884000052],
            [-81.045739365999964, 34.954319701000031],
            [-81.046867522999946, 34.954516182000077],
            [-81.048008877999962, 34.954651481000042],
            [-81.04884724499999, 34.954711257000042],
            [-81.049682859999962, 34.954754310000055],
            [-81.049994155999968, 34.954768073000025],
            [-81.050876143999972, 34.95478242400003],
            [-81.054969882999956, 34.954764572000045],
            [-81.055224236999948, 34.954773591000048],
            [-81.059163245999969, 34.956570197000076],
            [-81.059209969999984, 34.956591431000049],
            [-81.059437178999985, 34.956692538000027],
            [-81.068897025999945, 34.960828093000032],
            [-81.076047419999952, 34.964194883000062],
            [-81.076305976999947, 34.96431424900004],
            [-81.076398009999934, 34.964355610000041],
            [-81.086529105999944, 34.968875830000059],
            [-81.087465411999972, 34.969264314000043],
            [-81.087878866999972, 34.969418038000072],
            [-81.089704129999973, 34.970073509000031],
            [-81.090350994999937, 34.970293319000064],
            [-81.091438737999965, 34.970608953000067],
            [-81.092549236999957, 34.970865339000056],
            [-81.093677734999972, 34.971061381000027],
            [-81.094504548999964, 34.971165304000067],
            [-81.095899518999943, 34.971312839000063],
            [-81.096214369999984, 34.971343768000054],
            [-81.096845260999942, 34.971391579000056],
            [-81.09830829699996, 34.971480644000053],
            [-81.09882734599995, 34.971505907000051],
            [-81.099980631999983, 34.971516897000072],
            [-81.101079088999938, 34.971469503000037],
            [-81.102190070999939, 34.971392881000043],
            [-81.102243302999966, 34.971389143000067],
            [-81.103274377999981, 34.971290174000046],
            [-81.104669374999958, 34.971121851000078],
            [-81.106102754999938, 34.970985939000059],
            [-81.109613060999948, 34.97082481800004],
            [-81.109789971999987, 34.970815960000039],
            [-81.122776509999937, 34.970111079000048],
            [-81.125829735999957, 34.971947126000032],
            [-81.128607685999953, 34.973642097000038],
            [-81.128675938999947, 34.973683532000052],
            [-81.129627667999955, 34.974219850000054],
            [-81.130619984999953, 34.974703776000069],
            [-81.13164863999998, 34.97513323700008],
            [-81.132709227999953, 34.975506393000046],
            [-81.132909980999955, 34.975569557000028],
            [-81.133961921999969, 34.975894493000055],
            [-81.134182470999974, 34.975961240000061],
            [-81.135093861999962, 34.976231397000049],
            [-81.135760546999961, 34.976416725000036],
            [-81.136589362999985, 34.976613820000068],
            [-81.137822463999953, 34.976880313000038],
            [-81.138018005999982, 34.977101322000067],
            [-81.139521656999989, 34.978735139000037],
            [-81.139822452999965, 34.979052710000076],
            [-81.140573261999975, 34.979773441000077],
            [-81.141379747999963, 34.980452206000052],
            [-81.142374256999972, 34.98123615600008],
            [-81.143232981999972, 34.981870042000025],
            [-81.144140253999979, 34.982456338000077],
            [-81.145092185999943, 34.982992534000061],
            [-81.14608470099995, 34.983476333000056],
            [-81.146657409999989, 34.983723901000076],
            [-81.147853101999942, 34.98421793600005],
            [-81.14830924499995, 34.984399691000078],
            [-81.149370028999954, 34.984772700000065],
            [-81.150458191999974, 34.985087800000031],
            [-81.151569073999951, 34.985343643000078],
            [-81.152311249999968, 34.985479308000038],
            [-81.153568996999979, 34.985685887000045],
            [-81.153955661999987, 34.985745708000024],
            [-81.155097629999943, 34.985879993000026],
            [-81.155728655999951, 34.985927496000045],
            [-81.157192016999943, 34.986015847000033],
            [-81.15771118899994, 34.986040857000035],
            [-81.158864687999937, 34.986051284000041],
            [-81.160089073999984, 34.986029360000032],
            [-81.166158990999975, 34.988198232000059],
            [-81.166789927999957, 34.988411808000023],
            [-81.16699073999996, 34.98847491500004],
            [-81.168042982999964, 34.988799554000025],
            [-81.168930456999988, 34.98905138300006],
            [-81.170041449999985, 34.98930705500004],
            [-81.17117038799995, 34.989502372000061],
            [-81.17231243599997, 34.989636494000024],
            [-81.17266753399997, 34.989665488000071],
            [-81.173922349999941, 34.989757394000037],
            [-81.174614368999983, 34.989796799000032],
            [-81.175589493999951, 34.989836471000046],
            [-81.175692645999959, 34.989840418000028],
            [-81.176846199999943, 34.989850673000035],
            [-81.178147218999982, 34.989827184000035],
            [-81.178485885999976, 34.99014541400004],
            [-81.179292788999987, 34.99082392400004],
            [-81.180151900999988, 34.991457546000049],
            [-81.180441981999934, 34.991653505000045],
            [-81.18221269999998, 34.992823911000073],
            [-81.182830279999962, 34.993213961000038],
            [-81.183782591999943, 34.993749851000075],
            [-81.184457595999959, 34.994086349000042],
            [-81.18713560599997, 34.995354766000048],
            [-81.187453486999971, 34.995501741000055],
            [-81.187591615999963, 34.995563411000035],
            [-81.187602227999946, 34.995568099000025],
            [-81.18760998099998, 34.995575683000027],
            [-81.188106243999982, 34.996040679000032],
            [-81.188913285999945, 34.996719125000027],
            [-81.189772533999985, 34.997352678000027],
            [-81.190680311999984, 34.997938624000028],
            [-81.191632729999981, 34.99847445100005],
            [-81.192625708999969, 34.998957867000058],
            [-81.193654994999974, 34.999386798000046],
            [-81.19471618099999, 34.999759407000056],
            [-81.195804717999977, 35.000074098000027],
            [-81.196915944999944, 35.000329522000072],
            [-81.198045099999945, 35.000524587000029],
            [-81.199187346999963, 35.000658455000064],
            [-81.200337791999971, 35.000730554000029],
            [-81.201491502999943, 35.00074057300003],
            [-81.202643538999951, 35.000688471000046],
            [-81.20378896699998, 35.000574470000061],
            [-81.204922874999966, 35.000399060000063],
            [-81.206040406999989, 35.000162990000035],
            [-81.207136774999981, 34.999867275000042],
            [-81.208047195999939, 34.999566132000041],
            [-81.208570532999943, 34.999669930000039],
            [-81.209647814999983, 34.999854526000036],
            [-81.210790070999963, 34.99998828400004],
            [-81.211496733999979, 35.000039883000056],
            [-81.214461625999945, 35.00020693700003],
            [-81.217078859999958, 35.000379347000035],
            [-81.21772568099999, 35.000412117000053],
            [-81.218506537999986, 35.000418787000058],
            [-81.218966691999981, 35.000578719000032],
            [-81.219179998999948, 35.000651504000075],
            [-81.22062858299995, 35.001136660000043],
            [-81.227199901999938, 35.003863371000079],
            [-81.233365247999984, 35.006689655000059],
            [-81.233475986999963, 35.006739988000049],
            [-81.234505585999955, 35.007168570000033],
            [-81.235567058999948, 35.007540819000042],
            [-81.236655857999949, 35.007855142000039],
            [-81.237767317999953, 35.008110189000035],
            [-81.238896677999946, 35.008304872000053],
            [-81.24003909999999, 35.008438353000031],
            [-81.241189690999988, 35.008510062000028],
            [-81.242343516999938, 35.008519690000071],
            [-81.243495636999967, 35.008467198000062],
            [-81.244641115999968, 35.008352810000076],
            [-81.245775044999959, 35.008177016000047],
            [-81.246892564999939, 35.007940568000038],
            [-81.247988888999942, 35.007644482000046],
            [-81.249059321999937, 35.007290023000053],
            [-81.250099275999958, 35.006878712000059],
            [-81.251104296999983, 35.00641231000003],
            [-81.252070079999953, 35.005892815000038],
            [-81.252992488999951, 35.005322454000066],
            [-81.25386757299998, 35.004703669000037],
            [-81.254691581999964, 35.004039111000054],
            [-81.25546099099995, 35.003331629000058],
            [-81.256172503999949, 35.002584249000051],
            [-81.256823074999943, 35.001800176000074],
            [-81.25740991899994, 35.000982768000028],
            [-81.25793052399996, 35.000135526000065],
            [-81.258382661999974, 34.999262078000072],
            [-81.258764399999961, 34.998366166000039],
            [-81.259074102999989, 34.997451627000032],
            [-81.259310450999976, 34.996522378000066],
            [-81.25947242999996, 34.995582398000067],
            [-81.259559352999986, 34.994635712000047],
            [-81.259570845999974, 34.993686375000038],
            [-81.25950686699997, 34.992738453000072],
            [-81.259367687999941, 34.991796002000058],
            [-81.259153911999988, 34.990863060000038],
            [-81.25886645199995, 34.989943622000055],
            [-81.258506546999968, 34.989041622000059],
            [-81.258075735999967, 34.988160925000045],
            [-81.257575865999968, 34.98730529900007],
            [-81.257009080999978, 34.986478409000028],
            [-81.256377806999978, 34.985683793000078],
            [-81.25568475099999, 34.984924855000031],
            [-81.254932879999956, 34.984204841000064],
            [-81.254125413999986, 34.983526837000056],
            [-81.253265812999985, 34.982893741000055],
            [-81.252357752999956, 34.982308266000075],
            [-81.251405124999962, 34.981772917000058],
            [-81.25052272299996, 34.981340302000035],
            [-81.244040642999948, 34.978369087000033],
            [-81.243929934999983, 34.978318764000051],
            [-81.243397493999964, 34.978088416000048],
            [-81.235868288999939, 34.974964470000032],
            [-81.235371481999948, 34.974766284000054],
            [-81.234575928999959, 34.974480885000048],
            [-81.232576642999959, 34.973811332000025],
            [-81.229241726999987, 34.972652252000046],
            [-81.228763002999983, 34.972492619000036],
            [-81.22767470499997, 34.97217822500005],
            [-81.22656376499998, 34.971923083000036],
            [-81.22648707999997, 34.971907827000052],
            [-81.225158275999945, 34.971646060000069],
            [-81.224106138999957, 34.97146650600007],
            [-81.222964259999969, 34.971332874000041],
            [-81.221814208999945, 34.971260991000065],
            [-81.220660911999971, 34.971251166000059],
            [-81.219757067999979, 34.97126780900004],
            [-81.218605456999967, 34.971320074000062],
            [-81.218295102999946, 34.971344840000029],
            [-81.218258559999981, 34.971348025000054],
            [-81.217173679999974, 34.971276551000074],
            [-81.216970716999981, 34.971264149000035],
            [-81.215847191999956, 34.971200854000074],
            [-81.215648363999946, 34.971131058000026],
            [-81.214718364999953, 34.970858217000057],
            [-81.213316988999964, 34.970483135000052],
            [-81.213158760999988, 34.970441461000064],
            [-81.212047889999951, 34.970186186000035],
            [-81.210919119999971, 34.969991251000067],
            [-81.209777282999937, 34.969857494000053],
            [-81.208627264999961, 34.969785486000035],
            [-81.207473989999983, 34.969775535000053],
            [-81.206570159999956, 34.969792078000069],
            [-81.205418560999988, 34.969844218000048],
            [-81.204273566999973, 34.969958234000046],
            [-81.204220999999961, 34.969965311000067],
            [-81.204170766999937, 34.969945322000058],
            [-81.203621657999975, 34.96968527100006],
            [-81.203462645999934, 34.969534177000071],
            [-81.202606741999944, 34.968748189000053],
            [-81.202472712999963, 34.968626499000038],
            [-81.20166583699995, 34.967948143000058],
            [-81.200806804999957, 34.967314673000033],
            [-81.200566124999966, 34.967151508000029],
            [-81.198724636999941, 34.965925407000043],
            [-81.198057826999957, 34.965502690000051],
            [-81.197105750999981, 34.964966911000033],
            [-81.196113158999935, 34.964483532000031],
            [-81.195735917999968, 34.964318146000039],
            [-81.192640135999966, 34.963000272000045],
            [-81.192076261999944, 34.962770625000076],
            [-81.190670524999973, 34.96222358700004],
            [-81.190582802999984, 34.962189691000049],
            [-81.18952212399995, 34.961817045000032],
            [-81.188434119999954, 34.961502296000049],
            [-81.187323446999983, 34.961246793000043],
            [-81.186194859999944, 34.961051627000074],
            [-81.185245721999934, 34.960935846000041],
            [-81.184269406999988, 34.960839020000037],
            [-81.18407687399997, 34.96082080900004],
            [-81.182927005999943, 34.960748555000066],
            [-81.181773860999954, 34.960738358000071],
            [-81.178025633999937, 34.960806130000037],
            [-81.171347276999938, 34.958419806000052],
            [-81.17071655999996, 34.958206253000071],
            [-81.169628676999935, 34.957891335000056],
            [-81.168518111999958, 34.957635658000072],
            [-81.168036270999949, 34.957544476000066],
            [-81.166568855999969, 34.957284564000076],
            [-81.165922208999973, 34.957180396000069],
            [-81.164780624999935, 34.957046213000069],
            [-81.163897951999957, 34.956990605000044],
            [-81.163063975999989, 34.95611552400004],
            [-81.162943516999974, 34.955990547000056],
            [-81.162192728999969, 34.955269954000073],
            [-81.161386327999935, 34.954591334000042],
            [-81.160605037999972, 34.953975495000066],
            [-81.159746462999976, 34.95334172500003],
            [-81.158839401999955, 34.952755545000059],
            [-81.157887731999949, 34.95221945600008],
            [-81.15691400999998, 34.951744116000043],
            [-81.155576698999937, 34.951138193000077],
            [-81.155558215999974, 34.95112983000007],
            [-81.154529736999962, 34.950700572000073],
            [-81.153469371999961, 34.950327608000066],
            [-81.152381659999946, 34.950012534000052],
            [-81.151271255999973, 34.949756698000044],
            [-81.151250765999976, 34.949752578000073],
            [-81.14930493899999, 34.949362306000069],
            [-81.147296412999935, 34.948928281000065],
            [-81.146766334999938, 34.948604892000048],
            [-81.146698092999941, 34.948563468000032],
            [-81.146667272999935, 34.948544895000055],
            [-81.141580186999988, 34.945486172000074],
            [-81.140659532999962, 34.944968521000078],
            [-81.140006088999939, 34.94464174400008],
            [-81.138034494999943, 34.94370334000007],
            [-81.137695930999939, 34.943546267000045],
            [-81.136667640999974, 34.943116857000064],
            [-81.136374126999954, 34.943006752000031],
            [-81.135180602999981, 34.942569834000039],
            [-81.134413938999955, 34.942306810000048],
            [-81.133326407999959, 34.941991563000045],
            [-81.132216172999961, 34.941735552000068],
            [-81.131281194999985, 34.941568896000035],
            [-81.129954680999958, 34.941363311000032],
            [-81.129761473999963, 34.94133428300006],
            [-81.128934902999958, 34.94123059900005],
            [-81.127540386999954, 34.941083448000029],
            [-81.127225654999961, 34.94105260200007],
            [-81.126076114999989, 34.94097980600003],
            [-81.124923256999978, 34.940969065000047],
            [-81.124460005999936, 34.940982253000072],
            [-81.121820407999962, 34.941086005000045],
            [-81.121499999999969, 34.941101006000054],
            [-81.107562172999963, 34.941857588000062],
            [-81.103622383999948, 34.942038411000055],
            [-81.103077938999945, 34.942070361000049],
            [-81.102586644999974, 34.942111217000047],
            [-81.10087501299995, 34.942273518000036],
            [-81.093366475999971, 34.938923602000045],
            [-81.08615098599995, 34.935526477000053],
            [-81.085892505999936, 34.93540713200008],
            [-81.085665322999944, 34.935306074000039],
            [-81.076100261999954, 34.931124908000072],
            [-81.070187530999988, 34.928428237000048],
            [-81.070140819999949, 34.928407007000033],
            [-81.069113075999951, 34.927977020000071],
            [-81.068409746999976, 34.927721471000041],
            [-81.066656537999961, 34.927121717000034],
            [-81.066300191999972, 34.927003545000048],
            [-81.065213125999946, 34.926687684000058],
            [-81.064103311999986, 34.926431045000072],
            [-81.062975500999983, 34.926234726000075],
            [-81.06228383499996, 34.926145308000059],
            [-81.06095918699998, 34.925996196000028],
            [-81.060509871999955, 34.925950452000052],
            [-81.059360603999949, 34.92587701900004],
            [-81.059330575999979, 34.925875935000079],
            [-81.056060211999977, 34.925760002000061],
            [-81.055208564999987, 34.925746774000061],
            [-81.055003625999973, 34.925747671000067],
            [-81.05485165999994, 34.925648068000044],
            [-81.044805462999989, 34.918934772000057],
            [-81.044490154999949, 34.918728912000063],
            [-81.038980438999943, 34.915215064000051],
            [-81.038630147999982, 34.91499734100006],
            [-81.037679705999949, 34.914460303000055],
            [-81.036688670999979, 34.91397561000008],
            [-81.035661283999957, 34.913545337000073],
            [-81.034601943999974, 34.913171327000043],
            [-81.033515184999942, 34.912855180000065],
            [-81.032405657999959, 34.912598249000041],
            [-81.031278113999974, 34.912401633000059],
            [-81.03013737699996, 34.91226617500007],
            [-81.028988328999958, 34.912192453000046],
            [-81.027835888999959, 34.912180784000043]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 124,
        "SolutionID": "PI-12:A",
        "Shape_Length": 0.56150622584964305,
        "Shape_Area": 0.0077263815733476611
      }
    },
    {
      "type": "Feature",
      "id": 125,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 125,
        "SolutionID": "PI-9",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 126,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 126,
        "SolutionID": "PI-13",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 127,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 127,
        "SolutionID": "PI-14",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 128,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 128,
        "SolutionID": "PI-15",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 129,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 129,
        "SolutionID": "PI-16",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 130,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 130,
        "SolutionID": "PI-17",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 131,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.842868243999987, 35.253035887000067],
            [-80.842890242999943, 35.253045887000042],
            [-80.842909243999941, 35.253056887000071],
            [-80.843106242999966, 35.253262886000073],
            [-80.843179243999941, 35.253172887000062],
            [-80.843752242999983, 35.252443887000027],
            [-80.843781243999956, 35.252376887000025],
            [-80.843926243999988, 35.252045887000065],
            [-80.844353242999944, 35.251109887000041],
            [-80.844640243999947, 35.250463886000034],
            [-80.845242243999962, 35.249137886000028],
            [-80.845418243999973, 35.248732887000074],
            [-80.845523244999981, 35.248508886000025],
            [-80.845571242999938, 35.248397886000078],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.85721224699995, 35.249820885000076],
            [-80.858236247999969, 35.25041288500006],
            [-80.859896247999984, 35.251112886000044],
            [-80.861236248999944, 35.251518886000042],
            [-80.86243124799995, 35.251880886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.865280248999966, 35.252654886000073],
            [-80.865396248999957, 35.252555887000028],
            [-80.865591248999976, 35.252369886000054],
            [-80.86573924999999, 35.252089886000078],
            [-80.865773248999972, 35.251892886000064],
            [-80.865796250999949, 35.251844886000072],
            [-80.865813249999974, 35.251746886000035],
            [-80.865827249999938, 35.251606885000058],
            [-80.865789249999978, 35.251424886000052],
            [-80.865767249999976, 35.25128288500008],
            [-80.86575924999994, 35.251081885000076],
            [-80.865799249999952, 35.250882885000067],
            [-80.865861249999966, 35.25077288600005],
            [-80.86616424999994, 35.250541886000065],
            [-80.86625424999994, 35.250482886000043],
            [-80.866139248999957, 35.250361885000075],
            [-80.865839250999954, 35.250028885000063],
            [-80.865777249999951, 35.249959885000067],
            [-80.865438249999954, 35.249585885000045],
            [-80.865240248999953, 35.249376885000061],
            [-80.864632249999943, 35.248730886000033],
            [-80.864552248999985, 35.248636885000053],
            [-80.864029249999987, 35.248065885000074],
            [-80.863803247999954, 35.24781588500008],
            [-80.863412248999964, 35.247411885000076],
            [-80.863099247999969, 35.247070885000028],
            [-80.862811247999957, 35.24675488500003],
            [-80.862506247999988, 35.246420884000031],
            [-80.862109248999957, 35.245996885000068],
            [-80.862007247999941, 35.245886885000061],
            [-80.861787248999974, 35.245646885000042],
            [-80.861670248999985, 35.245518884000035],
            [-80.861054247999959, 35.244870885000068],
            [-80.860332247999963, 35.244062885000062],
            [-80.860211246999938, 35.24393688400005],
            [-80.860150246999979, 35.243873885000028],
            [-80.860008247999986, 35.243700885000067],
            [-80.859893247999935, 35.243575884000052],
            [-80.859677247999969, 35.243317884000078],
            [-80.859417246999953, 35.242975885000078],
            [-80.859354247999988, 35.242881884000042],
            [-80.859243246999938, 35.242687884000077],
            [-80.859139246999973, 35.242470885000046],
            [-80.859081247999939, 35.242320883000048],
            [-80.859063246999938, 35.242212884000025],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858816246999936, 35.237053882000055],
            [-80.85896424699996, 35.237018883000076],
            [-80.859264246999942, 35.237010884000028],
            [-80.860178247999954, 35.23698788300004],
            [-80.860555247999969, 35.236976883000068],
            [-80.861485247999951, 35.236929883000073],
            [-80.86159024799997, 35.236924883000029],
            [-80.862604247999968, 35.23688988300006],
            [-80.863003248999973, 35.236871883000049],
            [-80.863157247999936, 35.236866883000062],
            [-80.863673248999987, 35.23684888300005],
            [-80.863951248999967, 35.23685688300003],
            [-80.86409324899995, 35.236868883000056],
            [-80.86428824799998, 35.236862882000025],
            [-80.864663247999943, 35.236814883000079],
            [-80.865424248999943, 35.236790883000026],
            [-80.865396248999957, 35.236748882000029],
            [-80.864598247999936, 35.235481882000045],
            [-80.863696248999986, 35.234048882000025],
            [-80.863632247999988, 35.233910882000032],
            [-80.863650247999942, 35.233888882000031],
            [-80.863571247999971, 35.233877882000058],
            [-80.863508248999949, 35.233868882000024],
            [-80.863470247999942, 35.233863882000037],
            [-80.863351247999958, 35.233726882000042],
            [-80.863217247999955, 35.233570882000038],
            [-80.863081247999958, 35.233428882000055],
            [-80.862777247999986, 35.233204882000052],
            [-80.862583247999964, 35.23306688200006],
            [-80.862530247999985, 35.233033882000029],
            [-80.862477247999948, 35.232999881000069],
            [-80.862306247999982, 35.232909882000058],
            [-80.861285246999955, 35.232345882000061],
            [-80.861329246999958, 35.232291882000027],
            [-80.859888246999958, 35.231495881000058],
            [-80.859762246999935, 35.231423882000058],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.861678246999986, 35.218638879000025],
            [-80.863315246999946, 35.217718879000074],
            [-80.863591247999977, 35.217564879000065],
            [-80.864682247999951, 35.216943879000041],
            [-80.864793246999966, 35.216867879000063],
            [-80.864987246999988, 35.216717878000054],
            [-80.865101247999974, 35.216621878000069],
            [-80.865275247999989, 35.216454878000036],
            [-80.86548924799996, 35.216210878000027],
            [-80.865592247999984, 35.216079878000073],
            [-80.865764247999948, 35.215807878000078],
            [-80.866202247999979, 35.215177877000031],
            [-80.866254247999962, 35.215081878000035],
            [-80.86627324799997, 35.215031878000048],
            [-80.866292247999979, 35.214946878000035],
            [-80.866298246999975, 35.214875878000043],
            [-80.866284248999989, 35.214724878000027],
            [-80.866272248999962, 35.214651878000041],
            [-80.866258247999951, 35.21456887800008],
            [-80.866263247999939, 35.214464878000058],
            [-80.866290247999984, 35.214362878000031],
            [-80.866337247999979, 35.214265878000049],
            [-80.866494247999981, 35.21402187700005],
            [-80.866639247999956, 35.213806878000071],
            [-80.86667824899996, 35.213742878000062],
            [-80.866740248999974, 35.213608878000059],
            [-80.866764247999981, 35.213539877000073],
            [-80.866796248999947, 35.213398877000031],
            [-80.866817248999951, 35.213128877000031],
            [-80.864751247999948, 35.213085877000026],
            [-80.864094246999969, 35.213096878000044],
            [-80.865014247999966, 35.212538877000043],
            [-80.865624247999961, 35.212169877000065],
            [-80.865716247999956, 35.21210787800004],
            [-80.865888247999976, 35.211973876000059],
            [-80.865968247999945, 35.211901877000059],
            [-80.866172247999941, 35.21169487700007],
            [-80.866283247999945, 35.211568877000047],
            [-80.866361247999976, 35.21146587700008],
            [-80.866475247999972, 35.211295877000055],
            [-80.866553246999956, 35.211161877000052],
            [-80.866235246999963, 35.211131877000071],
            [-80.865569247999986, 35.211054878000027],
            [-80.864606247999973, 35.210957877000055],
            [-80.864358247999974, 35.210936877000051],
            [-80.861892246999957, 35.210678877000078],
            [-80.860768245999964, 35.210565877000079],
            [-80.860662245999947, 35.210541878000072],
            [-80.860611245999962, 35.21052387800006],
            [-80.860551245999943, 35.210495877000028],
            [-80.860475245999964, 35.210447877000036],
            [-80.860436245999949, 35.210415877000059],
            [-80.860386245999962, 35.210360877000028],
            [-80.859496245999935, 35.211548877000041],
            [-80.858696244999976, 35.212448877000043],
            [-80.858667245999982, 35.212483878000057],
            [-80.858640244999947, 35.212467877000051],
            [-80.85824424599997, 35.212173877000055],
            [-80.857711244999962, 35.211757878000071],
            [-80.857017244999952, 35.211230878000038],
            [-80.856542243999968, 35.210876877000032],
            [-80.856056243999944, 35.210513877000039],
            [-80.855698244999985, 35.210235878000049],
            [-80.855407244999981, 35.210022878000075],
            [-80.854317244999947, 35.210999877000063],
            [-80.85334424399997, 35.211881878000042],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84488324199998, 35.206825877000028],
            [-80.844533239999976, 35.206627877000074],
            [-80.844335240999953, 35.206534877000024],
            [-80.844233240999984, 35.206492878000063],
            [-80.844002240999941, 35.206415876000051],
            [-80.843777239999952, 35.206355877000078],
            [-80.843549240999948, 35.206314877000068],
            [-80.843321239999966, 35.206290877000072],
            [-80.843093240999963, 35.206282877000035],
            [-80.842657240999984, 35.206263877000026],
            [-80.842146240999966, 35.206208877000051],
            [-80.842051240999979, 35.206203877000064],
            [-80.842004240999984, 35.206198877000077],
            [-80.841677240999957, 35.206125877000034],
            [-80.841474239999968, 35.206079877000036],
            [-80.841239239999936, 35.205988876000049],
            [-80.840822239999966, 35.205814876000034],
            [-80.840639239999973, 35.205760877000046],
            [-80.840585239999939, 35.205956877000062],
            [-80.840523239999982, 35.206203876000075],
            [-80.840486240999951, 35.206289877000074],
            [-80.840428239999937, 35.206465878000074],
            [-80.840402239999946, 35.206596877000038],
            [-80.840398239999956, 35.20666387700004],
            [-80.840406239999936, 35.207266877000052],
            [-80.840399238999964, 35.207378877000053],
            [-80.840389240999968, 35.207442878000052],
            [-80.840347239999971, 35.207606877000046],
            [-80.840318239999988, 35.207687877000069],
            [-80.840254239999979, 35.207827877000057],
            [-80.840180239999938, 35.207945878000032],
            [-80.840135239999938, 35.208000877000075],
            [-80.840035239999963, 35.208103878000031],
            [-80.839936239999986, 35.208188878000044],
            [-80.839833239999962, 35.208269877000077],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834444238999936, 35.211977878000027],
            [-80.834343237999974, 35.211957878000078],
            [-80.834049237999977, 35.211842878000027],
            [-80.833633237999948, 35.211669879000056],
            [-80.833578237999973, 35.211635878000038],
            [-80.83348923799997, 35.211566878000042],
            [-80.833357237999962, 35.211445879000053],
            [-80.833227237999949, 35.211338879000039],
            [-80.833092237999949, 35.211220878000063],
            [-80.83293823799994, 35.211093879000032],
            [-80.832555237999941, 35.210789878000071],
            [-80.832482237999955, 35.21072687800006],
            [-80.832356237999988, 35.210635879000051],
            [-80.832290236999938, 35.210595878000049],
            [-80.831697236999958, 35.210189878000051],
            [-80.83152423699994, 35.210065877000034],
            [-80.831292236999957, 35.209901878000039],
            [-80.83073623699994, 35.209528878000071],
            [-80.830594236999957, 35.209417878000068],
            [-80.830526237999948, 35.209355879000043],
            [-80.830432236999968, 35.209252878000029],
            [-80.829971236999938, 35.208667878000028],
            [-80.829861236999989, 35.208533878000026],
            [-80.829731236999976, 35.208364878000054],
            [-80.829455235999944, 35.208027878000053],
            [-80.829431236999937, 35.208005878000051],
            [-80.829394237999963, 35.207980877000068],
            [-80.828938235999942, 35.207770878000076],
            [-80.828789236999967, 35.20770187800008],
            [-80.828423235999935, 35.20751887800003],
            [-80.828249235999976, 35.207432877000031],
            [-80.828155235999986, 35.207381878000035],
            [-80.827950235999936, 35.207287877000056],
            [-80.827558235999959, 35.207121877000077],
            [-80.82749023599996, 35.207263878000049],
            [-80.827443235999965, 35.20739087700008],
            [-80.827406236999934, 35.207519878000028],
            [-80.827375236999956, 35.207683877000079],
            [-80.827362235999942, 35.207881877000034],
            [-80.827365235999935, 35.207950878000077],
            [-80.827370234999989, 35.208047878000059],
            [-80.827390235999985, 35.208179878000067],
            [-80.827411235999989, 35.208277878000047],
            [-80.827461236999966, 35.208437878000041],
            [-80.827526235999983, 35.208589878000055],
            [-80.827626235999958, 35.208887878000041],
            [-80.827710236999962, 35.209143879000067],
            [-80.827748235999934, 35.209293879000029],
            [-80.827784236999946, 35.209497878000036],
            [-80.827832235999949, 35.209992878000037],
            [-80.827830235999954, 35.210149879000028],
            [-80.827831236999941, 35.210174878000032],
            [-80.827818235999985, 35.210317878000069],
            [-80.827790235999942, 35.210487878000038],
            [-80.827745235999942, 35.210604879000073],
            [-80.827685235999979, 35.210739878000027],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826110235999977, 35.212494879000076],
            [-80.825601235999954, 35.212906880000048],
            [-80.825329235999959, 35.213129879000064],
            [-80.824788235999961, 35.213584880000042],
            [-80.824359235999964, 35.213932879000026],
            [-80.824267234999979, 35.214013880000039],
            [-80.823322235999967, 35.214790879000077],
            [-80.822921233999978, 35.215127880000068],
            [-80.822102234999988, 35.215785879000066],
            [-80.82245923499994, 35.216090879000035],
            [-80.822771234999948, 35.216343880000068],
            [-80.822953235999989, 35.216472880000026],
            [-80.822842235999985, 35.216935880000051],
            [-80.822761234999973, 35.217296880000049],
            [-80.822605234999969, 35.217944880000061],
            [-80.822493234999968, 35.218465880000053],
            [-80.822471234999966, 35.218730881000056],
            [-80.821067235999976, 35.218642880000061],
            [-80.820841234999989, 35.21862888000004],
            [-80.82035323499997, 35.218597881000051],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820100235999973, 35.235037884000064],
            [-80.819981234999943, 35.235177884000052],
            [-80.81988823599994, 35.235315884000045],
            [-80.819799235999938, 35.235482884000078],
            [-80.819720234999977, 35.235645884000064],
            [-80.819631234999974, 35.235732884000072],
            [-80.81942223599998, 35.235893884000063],
            [-80.819243234999988, 35.236065884000027],
            [-80.81919323599999, 35.236099884000055],
            [-80.818958235999958, 35.236234884000055],
            [-80.818802235999954, 35.236314884000024],
            [-80.818692234999958, 35.236377884000035],
            [-80.818588234999936, 35.236431884000069],
            [-80.818381234999947, 35.236520885000061],
            [-80.818281234999972, 35.236574884000049],
            [-80.818214234999971, 35.236617885000044],
            [-80.818178235999937, 35.236653885000067],
            [-80.818135234999943, 35.236780884000041],
            [-80.818114234999939, 35.236888885000042],
            [-80.818140235999977, 35.236998884000059],
            [-80.818171234999966, 35.237034884000025],
            [-80.818222233999961, 35.237113884000053],
            [-80.818248234999942, 35.237150884000073],
            [-80.818318234999936, 35.237251884000045],
            [-80.818373234999967, 35.237297885000032],
            [-80.818505235999964, 35.237379884000063],
            [-80.818611234999935, 35.237472885000045],
            [-80.818725235999977, 35.237584884000057],
            [-80.818801234999967, 35.237646885000061],
            [-80.818842234999977, 35.23768588400003],
            [-80.818936234999967, 35.23775588400008],
            [-80.818987234999952, 35.237778884000079],
            [-80.819022233999988, 35.237814884000045],
            [-80.819033235999939, 35.237835884000049],
            [-80.819040234999989, 35.237873884000066],
            [-80.819050235999953, 35.237897884000063],
            [-80.819011235999938, 35.238052885000059],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.815552233999938, 35.24903888800003],
            [-80.814748234999968, 35.249669888000028],
            [-80.815168234999987, 35.249990887000024],
            [-80.815845234999983, 35.250490887000069],
            [-80.81686723599995, 35.251257888000055],
            [-80.816899234999937, 35.251294887000029],
            [-80.816909235999958, 35.251320887000077],
            [-80.816908234999971, 35.251338888000078],
            [-80.817725235999944, 35.250860887000044],
            [-80.817826235999974, 35.250647887000071],
            [-80.818018235999943, 35.250461887000029],
            [-80.818523235999976, 35.25084688700008],
            [-80.818796235999969, 35.251048888000071],
            [-80.818978236999953, 35.251194887000054],
            [-80.819068235999964, 35.251239887000054],
            [-80.819101235999938, 35.251262887000053],
            [-80.819225235999966, 35.251390887000071],
            [-80.819359234999979, 35.251555888000041],
            [-80.819399235999981, 35.251599888000044],
            [-80.819462235999936, 35.25157488800005],
            [-80.82007523599998, 35.251245888000028],
            [-80.820787236999934, 35.250852888000054],
            [-80.820905235999987, 35.250788887000056],
            [-80.821469236999974, 35.250482887000032],
            [-80.821863236999945, 35.250264887000071],
            [-80.822185236999985, 35.250096887000041],
            [-80.822311236999951, 35.250030887000037],
            [-80.822858237999981, 35.249723887000073],
            [-80.823336237999968, 35.249420886000053],
            [-80.823538237999969, 35.249296887000071],
            [-80.823650236999981, 35.249221886000043],
            [-80.823701235999977, 35.24917788700003],
            [-80.823781236999935, 35.249088887000028],
            [-80.823814237999954, 35.249040887000035],
            [-80.824099236999984, 35.24867088600007],
            [-80.824222237999948, 35.248517887000048],
            [-80.824280237999972, 35.24845388600005],
            [-80.82442423699996, 35.248316886000055],
            [-80.82478023799996, 35.248019886000066],
            [-80.825508237999941, 35.248371887000076],
            [-80.825803237999935, 35.248523887000033],
            [-80.82597323799996, 35.24829988700003],
            [-80.826215237999975, 35.247962886000039],
            [-80.826506238999968, 35.247583886000029],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835851240999943, 35.249538886000039],
            [-80.835993240999983, 35.249530886000059],
            [-80.836173240999983, 35.249530887000049],
            [-80.836235241999987, 35.249620887000049],
            [-80.836357240999973, 35.249764887000026],
            [-80.836440240999934, 35.249836887000072],
            [-80.836493240999971, 35.249861887000066],
            [-80.836825241999975, 35.249969887000077],
            [-80.837000241999988, 35.249976887000059],
            [-80.837069241999984, 35.249979887000052],
            [-80.83729624099999, 35.249977886000067],
            [-80.83733024199995, 35.249991886000032],
            [-80.837347241999964, 35.250006886000051],
            [-80.837355240999955, 35.250032886000042],
            [-80.83735724099995, 35.250086886000076],
            [-80.837375240999961, 35.250219886000025],
            [-80.837438240999973, 35.250445887000069],
            [-80.837534240999958, 35.250613887000043],
            [-80.837565240999936, 35.250641887000029],
            [-80.837624241999947, 35.25067088600008],
            [-80.837849241999947, 35.250714887000072],
            [-80.837964240999952, 35.250747886000056],
            [-80.837993241999982, 35.250786886000071],
            [-80.838017241999978, 35.250947886000063],
            [-80.838059241999986, 35.250979886000039],
            [-80.838111241999968, 35.250967886000069],
            [-80.838116241999955, 35.250933887000031],
            [-80.838158240999974, 35.250923887000056],
            [-80.838193240999942, 35.250902886000063],
            [-80.838207241999953, 35.250883887000043],
            [-80.838214241999935, 35.250844887000028],
            [-80.838234240999952, 35.250810887000057],
            [-80.83826024199999, 35.250779887000078],
            [-80.838398241999982, 35.250726886000052],
            [-80.838459241999942, 35.250722887000052],
            [-80.838688241999989, 35.250760887000069],
            [-80.839083242999948, 35.250900886000068],
            [-80.839146242999959, 35.250957887000027],
            [-80.839197242999944, 35.251012887000059],
            [-80.839271240999949, 35.251063887000043],
            [-80.839334242999939, 35.25108788700004],
            [-80.83941224199998, 35.251117886000031],
            [-80.839530242999956, 35.251145887000064],
            [-80.839568241999984, 35.251164887000073],
            [-80.83960824199994, 35.251191886000072],
            [-80.839798241999972, 35.251291886000047],
            [-80.839948241999934, 35.251377886000057],
            [-80.840011242999935, 35.251404887000035],
            [-80.840202241999975, 35.251569886000027],
            [-80.840284241999939, 35.251610886000037],
            [-80.84051124299998, 35.251735887000052],
            [-80.840539241999977, 35.251742887000034],
            [-80.840577241999938, 35.25173688700005],
            [-80.840728241999955, 35.25169988600004],
            [-80.840819242999942, 35.251696887000037],
            [-80.840861242999949, 35.251709887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840912242999934, 35.251799887000061],
            [-80.840912242999934, 35.251817887000072],
            [-80.840904242999954, 35.251842887000066],
            [-80.840746241999966, 35.252026887000056],
            [-80.840737241999989, 35.252077886000052],
            [-80.840735242999983, 35.252119887000049],
            [-80.840765241999975, 35.252153887000077],
            [-80.840896242999975, 35.252234887000043],
            [-80.840976242999943, 35.252304886000047],
            [-80.841025241999944, 35.252328887000033],
            [-80.841107242999954, 35.252354887000024],
            [-80.841271242999937, 35.25243088700006],
            [-80.841386242999988, 35.252484887000037],
            [-80.841513242999952, 35.252515887000072],
            [-80.841542242999935, 35.252509887000031],
            [-80.841651242999944, 35.252441887000032],
            [-80.841689242999962, 35.25243088700006],
            [-80.841726242999982, 35.252431887000057],
            [-80.841942242999949, 35.252505887000041],
            [-80.84196424299995, 35.252522886000065],
            [-80.841976241999987, 35.252582886000027],
            [-80.84209524299996, 35.252671887000076],
            [-80.842316242999971, 35.252706887000045],
            [-80.84235724399997, 35.252730887000041],
            [-80.842417242999943, 35.252776887000039],
            [-80.842464241999949, 35.252922888000057],
            [-80.842524242999957, 35.25297888700004],
            [-80.842584242999976, 35.253001887000039],
            [-80.842708243999937, 35.253039887000057],
            [-80.842837242999963, 35.253032887000074],
            [-80.842868243999987, 35.253035887000067]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 131,
        "SolutionID": "PI-19",
        "Shape_Length": 0.25960688478978416,
        "Shape_Area": 0.0017279713442268829
      }
    },
    {
      "type": "Feature",
      "id": 132,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.885594247999961, 35.112545855000064],
              [-80.885866247999957, 35.112567855000066],
              [-80.886136247999957, 35.112605855000027],
              [-80.886436248999985, 35.11267485500008],
              [-80.886740248999956, 35.112760855000033],
              [-80.886889247999989, 35.112810855000077],
              [-80.887378248999937, 35.11303885500007],
              [-80.888287249999962, 35.113464855000075],
              [-80.888543249999941, 35.113565856000037],
              [-80.888704248999943, 35.113618855000027],
              [-80.888961249999966, 35.11368785500008],
              [-80.889137248999987, 35.113723856000036],
              [-80.889406249999979, 35.113765855000054],
              [-80.889609248999989, 35.113784855000063],
              [-80.889888249999956, 35.113794856000027],
              [-80.890181249999955, 35.113786855000058],
              [-80.890315249999958, 35.113775855000029],
              [-80.890578249999976, 35.113741856000047],
              [-80.890868249999983, 35.113684856000077],
              [-80.891066249999938, 35.113632855000048],
              [-80.892878250999956, 35.113047855000048],
              [-80.895826251999949, 35.112095854000074],
              [-80.897564251999938, 35.111530855000069],
              [-80.896719250999979, 35.110666855000034],
              [-80.896162250999964, 35.110071854000068],
              [-80.89564625099996, 35.109545855000079],
              [-80.89504625099994, 35.108949854000059],
              [-80.894750249999959, 35.108670854000025],
              [-80.894612250999955, 35.108540853000079],
              [-80.894282249999947, 35.108259855000028],
              [-80.893983250999952, 35.108012853000048],
              [-80.89370624999998, 35.107793855000068],
              [-80.893288249999955, 35.107474854000031],
              [-80.892614249999951, 35.107009854000069],
              [-80.892283249999934, 35.106794854000043],
              [-80.891868249999959, 35.106539854000061],
              [-80.891496249999989, 35.106321854000043],
              [-80.891394249999962, 35.106264854000074],
              [-80.891090248999944, 35.106093854000051],
              [-80.890726249999943, 35.105898854000031],
              [-80.890400249999971, 35.10573785400004],
              [-80.889666248999958, 35.105396854000048],
              [-80.889144248999969, 35.105173854000043],
              [-80.88542924799998, 35.103713854000034],
              [-80.885276247999968, 35.103653854000072],
              [-80.884399246999976, 35.103289853000035],
              [-80.884233246999941, 35.103227854000068],
              [-80.882886246999988, 35.102723854000033],
              [-80.882819246999986, 35.102698853000049],
              [-80.881755246999944, 35.102214854000067],
              [-80.880874246999952, 35.10176385300008],
              [-80.880831246999946, 35.101921854000068],
              [-80.880791245999944, 35.102167853000026],
              [-80.880737246999956, 35.102581853000061],
              [-80.880700246999936, 35.102996854000025],
              [-80.880681245999938, 35.10347485300008],
              [-80.880679245999943, 35.103620854000042],
              [-80.880687245999979, 35.104036853000025],
              [-80.880709245999981, 35.104403853000065],
              [-80.880738245999964, 35.104606853000064],
              [-80.880784245999962, 35.104933853000034],
              [-80.880810245999953, 35.105070853000029],
              [-80.880888246999973, 35.105476853000027],
              [-80.880978245999984, 35.105845853000062],
              [-80.881056245999957, 35.106132854000066],
              [-80.88144824699998, 35.107475854000029],
              [-80.881449245999988, 35.107476855000073],
              [-80.881612246999964, 35.108011855000029],
              [-80.88206024699997, 35.109521855000025],
              [-80.882392246999984, 35.110643854000045],
              [-80.882548246999988, 35.111185855000031],
              [-80.882801246999975, 35.112107855000033],
              [-80.882891246999975, 35.112474856000063],
              [-80.883015247999936, 35.112977855000054],
              [-80.883681247999959, 35.112819856000044],
              [-80.883994247999965, 35.112745855000071],
              [-80.884339247999947, 35.112673855000025],
              [-80.88467824899999, 35.112609855000073],
              [-80.884859246999952, 35.112582856000074],
              [-80.885195247999945, 35.112545855000064],
              [-80.88532024899996, 35.112541855000075],
              [-80.885594247999961, 35.112545855000064]
            ]
          ],
          [
            [
              [-80.877847246999977, 35.140237862000049],
              [-80.877965247999953, 35.140263861000051],
              [-80.878077246999965, 35.140306861000056],
              [-80.87879924799995, 35.14068386100007],
              [-80.879001248999941, 35.140768862000073],
              [-80.879326247999984, 35.140877861000035],
              [-80.879784247999964, 35.141011862000028],
              [-80.880254248999961, 35.141130861000079],
              [-80.880397247999952, 35.141162862000044],
              [-80.880752248999954, 35.141224861000069],
              [-80.880818248999958, 35.141232862000038],
              [-80.880951247999974, 35.141234862000033],
              [-80.881022247999965, 35.141229862000046],
              [-80.881146248999983, 35.141206862000047],
              [-80.881264248999969, 35.141167861000042],
              [-80.881388247999951, 35.141105861000028],
              [-80.881524248999938, 35.141014861000031],
              [-80.882396248999953, 35.140340861000027],
              [-80.882554248999952, 35.140214861000061],
              [-80.882872247999956, 35.139961861000074],
              [-80.882983249999938, 35.139872862000061],
              [-80.883187248999945, 35.139664861000028],
              [-80.883373249999977, 35.139446860000078],
              [-80.883518248999962, 35.139251861000048],
              [-80.883567248999952, 35.139178861000062],
              [-80.883694248999973, 35.13898886100003],
              [-80.884173248999957, 35.138269861000026],
              [-80.884322248999979, 35.138044861000026],
              [-80.884382248999941, 35.137930861000029],
              [-80.884414248999974, 35.137833861000047],
              [-80.884433248999983, 35.137723860000051],
              [-80.884431248999988, 35.137639861000025],
              [-80.884420248999959, 35.137586861000045],
              [-80.884325248999971, 35.137285861000066],
              [-80.883926248999956, 35.137379860000067],
              [-80.881651248999958, 35.137909861000026],
              [-80.881465247999984, 35.137942861000056],
              [-80.881322248999936, 35.137962861000062],
              [-80.881037247999984, 35.137989861000051],
              [-80.880823247999956, 35.138000862000069],
              [-80.880532247999952, 35.137995861000036],
              [-80.880216247999954, 35.137969861000045],
              [-80.87935424899996, 35.137846861000071],
              [-80.879336247999959, 35.137455861000035],
              [-80.879337247999956, 35.137165861000028],
              [-80.879351247999978, 35.136860861000059],
              [-80.879376247999971, 35.136596861000044],
              [-80.879409247999945, 35.136390861000052],
              [-80.879424247999964, 35.136298860000068],
              [-80.879474247999951, 35.136068861000069],
              [-80.879560247999962, 35.13574286000005],
              [-80.87965524699996, 35.135455860000036],
              [-80.87973324799998, 35.135252861000026],
              [-80.879882247999944, 35.134914860000038],
              [-80.879984247999971, 35.13471786000008],
              [-80.880769247999979, 35.133287860000053],
              [-80.88134724799994, 35.132229860000052],
              [-80.881690248999973, 35.131604859000049],
              [-80.882025247999934, 35.130995859000052],
              [-80.882175247999953, 35.130714859000079],
              [-80.882516247999945, 35.130087859000071],
              [-80.882002247999935, 35.129917859000045],
              [-80.88009324799998, 35.129274859000077],
              [-80.878580245999956, 35.128784859000064],
              [-80.877672246999964, 35.12847885900004],
              [-80.877149245999988, 35.128293859000053],
              [-80.876974246999964, 35.128235858000039],
              [-80.876753246999954, 35.128162859000042],
              [-80.876567245999979, 35.128430858000058],
              [-80.876368246999959, 35.128743858000064],
              [-80.876268245999938, 35.128919859000064],
              [-80.876187246999962, 35.129064859000039],
              [-80.876104245999954, 35.129227860000071],
              [-80.875951245999943, 35.12960085900005],
              [-80.875605246999953, 35.130485859000032],
              [-80.875540245999957, 35.130655859000058],
              [-80.875472245999958, 35.130853860000059],
              [-80.875391245999936, 35.131141860000071],
              [-80.87535924599996, 35.131286860000046],
              [-80.875327245999983, 35.131462860000056],
              [-80.875280245999988, 35.131880859000034],
              [-80.875265245999969, 35.132090861000052],
              [-80.875299246999987, 35.132696860000067],
              [-80.875729246999981, 35.134780860000035],
              [-80.875831246999951, 35.135244861000047],
              [-80.875987245999966, 35.135956860000078],
              [-80.876031245999968, 35.136155860000031],
              [-80.876338245999989, 35.137725861000035],
              [-80.876684247999947, 35.137719861000051],
              [-80.876692246999937, 35.137760861000061],
              [-80.876797247999946, 35.138950861000069],
              [-80.877116246999947, 35.140341862000071],
              [-80.877527246999989, 35.140337861000035],
              [-80.877534246999971, 35.140328862000047],
              [-80.877592247999985, 35.140281861000062],
              [-80.877664246999984, 35.140249862000076],
              [-80.877702247999935, 35.140238862000047],
              [-80.877783246999968, 35.140230861000077],
              [-80.877847246999977, 35.140237862000049]
            ]
          ],
          [
            [
              [-80.876997248999942, 35.156601865000027],
              [-80.878228247999971, 35.156763865000073],
              [-80.881161249999934, 35.158181866000064],
              [-80.88131924999999, 35.157614865000028],
              [-80.882423248999942, 35.157614865000028],
              [-80.883306249999976, 35.157356865000054],
              [-80.884758250999937, 35.156891865000034],
              [-80.885388249999949, 35.156479865000051],
              [-80.886082250999948, 35.156582865000075],
              [-80.887016250999977, 35.157074865000027],
              [-80.88733425099997, 35.156644865000032],
              [-80.887690250999981, 35.156233865000047],
              [-80.888241250999954, 35.155548864000025],
              [-80.888324251999961, 35.155471864000049],
              [-80.888371251999956, 35.155437865000067],
              [-80.888450250999938, 35.155392864000078],
              [-80.888512250999952, 35.155364864000035],
              [-80.888643250999962, 35.15532186400003],
              [-80.888699251999981, 35.155308864000062],
              [-80.888958251999952, 35.15526886400005],
              [-80.889476251999952, 35.15522786400004],
              [-80.890254251999977, 35.155103865000058],
              [-80.890222251999944, 35.154984864000028],
              [-80.890240251999955, 35.154871864000029],
              [-80.890230251999981, 35.154770864000056],
              [-80.890199251999945, 35.15455886500007],
              [-80.890181251999934, 35.154466863000039],
              [-80.890146251999965, 35.154399865000073],
              [-80.890129251999952, 35.154283864000035],
              [-80.890120251999974, 35.154143864000048],
              [-80.890122252999959, 35.154069865000054],
              [-80.89013625299998, 35.153996864000078],
              [-80.890162251999982, 35.153950864000024],
              [-80.890166250999982, 35.15390986400007],
              [-80.890178251999941, 35.15388086400003],
              [-80.890198251999948, 35.153784864000045],
              [-80.890230251999981, 35.153675864000036],
              [-80.890232251999976, 35.153632864000031],
              [-80.890275251999981, 35.153481864000071],
              [-80.890290251999943, 35.153368865000061],
              [-80.890312251999944, 35.153274864000025],
              [-80.890356251999947, 35.153178863000051],
              [-80.890362251999989, 35.153141864000077],
              [-80.890330251999956, 35.153069863000042],
              [-80.890379250999956, 35.152983863000031],
              [-80.890394251999965, 35.152824863000035],
              [-80.889219250999986, 35.153000864000035],
              [-80.889162251999949, 35.152589864000049],
              [-80.88908225199998, 35.152070863000063],
              [-80.887997251999934, 35.152233864000038],
              [-80.887832250999963, 35.152245863000076],
              [-80.887690250999981, 35.152248864000057],
              [-80.887505250999936, 35.152238864000026],
              [-80.887414250999939, 35.152227864000054],
              [-80.886958250999953, 35.152147864000028],
              [-80.886497249999934, 35.152061864000075],
              [-80.886617249999972, 35.151582863000044],
              [-80.886653250999984, 35.151522863000025],
              [-80.886678250999978, 35.151497864000078],
              [-80.886768250999978, 35.151424863000045],
              [-80.886904251999965, 35.15129586300003],
              [-80.886939250999944, 35.151238864000049],
              [-80.886957250999956, 35.151175864000038],
              [-80.886956250999958, 35.15111186300004],
              [-80.886935249999965, 35.151049863000026],
              [-80.886894250999944, 35.150977863000037],
              [-80.886837250999974, 35.150916863000077],
              [-80.886755250999954, 35.150860863000048],
              [-80.886669250999944, 35.150824863000025],
              [-80.886576249999962, 35.15080286400007],
              [-80.88579925099998, 35.150763863000066],
              [-80.884878248999939, 35.15072886300004],
              [-80.884762249999937, 35.15072886400003],
              [-80.884648249999941, 35.150717863000068],
              [-80.884455249999974, 35.150690864000069],
              [-80.884386250999967, 35.150651864000054],
              [-80.884374248999961, 35.150613864000036],
              [-80.884346249999965, 35.150553864000074],
              [-80.884259249999957, 35.149364864000063],
              [-80.88425424899998, 35.149302863000059],
              [-80.882522248999976, 35.149411864000058],
              [-80.882484248999958, 35.149004863000073],
              [-80.882439248999958, 35.148679863000041],
              [-80.882184249999966, 35.148689864000062],
              [-80.882013247999964, 35.148683863000031],
              [-80.881864249999978, 35.148670864000053],
              [-80.88142124899997, 35.148582863000058],
              [-80.881265248999966, 35.148566863000042],
              [-80.881113248999952, 35.148557863000065],
              [-80.880983248999939, 35.14855986300006],
              [-80.880723248999971, 35.148595863000025],
              [-80.880521248999969, 35.148633863000043],
              [-80.880086247999941, 35.14873186300008],
              [-80.879972248999934, 35.148775863000026],
              [-80.879881248999936, 35.148844863000079],
              [-80.879820248999977, 35.14891586300007],
              [-80.879777248999972, 35.14900186400007],
              [-80.879734247999977, 35.149230863000071],
              [-80.879696247999959, 35.149309863000042],
              [-80.879641247999984, 35.149368863000063],
              [-80.879558248999956, 35.14942986300008],
              [-80.879466247999972, 35.14947486300008],
              [-80.87936624799994, 35.149500863000071],
              [-80.879266247999965, 35.149511863000043],
              [-80.87916624799999, 35.149503864000053],
              [-80.879062248999958, 35.149484864000044],
              [-80.878957247999949, 35.14943786300006],
              [-80.878852247999987, 35.149365863000071],
              [-80.878769248999959, 35.149315863000027],
              [-80.878658247999965, 35.149278863000063],
              [-80.878530248999937, 35.149260863000052],
              [-80.878491247999989, 35.149488864000034],
              [-80.878293247999977, 35.15082586300008],
              [-80.878148248999935, 35.151652864000027],
              [-80.877857247999941, 35.152932864000036],
              [-80.877299247999986, 35.15284086500003],
              [-80.876997248999942, 35.156601865000027]
            ]
          ],
          [
            [
              [-80.835420236999937, 35.164250868000067],
              [-80.835529235999957, 35.164258868000047],
              [-80.83623623699998, 35.164352868000037],
              [-80.836324235999939, 35.163934868000069],
              [-80.836380235999968, 35.163692867000066],
              [-80.836437235999938, 35.163493868000046],
              [-80.836483236999982, 35.163365868000028],
              [-80.836590236999939, 35.163114868000036],
              [-80.836717236999959, 35.16286986700004],
              [-80.836805236999965, 35.162735867000038],
              [-80.836913236999976, 35.162581868000075],
              [-80.837138235999987, 35.162304867000046],
              [-80.837377235999952, 35.162057867000044],
              [-80.837479235999979, 35.161960868000051],
              [-80.837835236999979, 35.161622867000062],
              [-80.838026236999951, 35.161433868000074],
              [-80.838102236999987, 35.161348867000072],
              [-80.837650235999945, 35.161076867000077],
              [-80.83755423599996, 35.160998867000046],
              [-80.837476235999986, 35.160908868000035],
              [-80.837414235999972, 35.160810867000066],
              [-80.837391235999974, 35.160758868000073],
              [-80.837360236999984, 35.160650867000072],
              [-80.837349236999955, 35.160540868000055],
              [-80.837376235999955, 35.160148868000078],
              [-80.837428236999983, 35.159356868000032],
              [-80.837439235999966, 35.159067867000033],
              [-80.837427236999986, 35.158939867000072],
              [-80.837413235999975, 35.158872867000071],
              [-80.837369235999972, 35.15874186700006],
              [-80.837339236999981, 35.158675867000056],
              [-80.837304235999966, 35.158611867000047],
              [-80.837755235999964, 35.158395867000024],
              [-80.838648236999973, 35.158025867000049],
              [-80.838868236999986, 35.157935866000059],
              [-80.838920236999968, 35.15792086600004],
              [-80.838973236999948, 35.157910867000055],
              [-80.839081236999959, 35.157902867000075],
              [-80.840304236999941, 35.157955866000066],
              [-80.840494236999973, 35.157953866000071],
              [-80.84058923799995, 35.157946866000032],
              [-80.840775236999946, 35.157919866000043],
              [-80.840868236999938, 35.157899867000026],
              [-80.841024236999942, 35.15785586700008],
              [-80.841076236999982, 35.157835867000074],
              [-80.841145236999978, 35.157805867000036],
              [-80.84134023699994, 35.15769186700004],
              [-80.841471236999951, 35.157607867000024],
              [-80.84212023799995, 35.157155866000039],
              [-80.842506237999942, 35.156884866000041],
              [-80.842593237999949, 35.156827866000071],
              [-80.842733238999983, 35.156755867000072],
              [-80.842891237999936, 35.156694866000066],
              [-80.842972236999969, 35.15667086600007],
              [-80.843704237999987, 35.156477866000046],
              [-80.843484237999974, 35.155889866000052],
              [-80.843460237999977, 35.155771866000066],
              [-80.843456237999987, 35.155675866000024],
              [-80.84346323799997, 35.155569866000064],
              [-80.843509238999957, 35.15529086600003],
              [-80.843534236999972, 35.155140866000067],
              [-80.843616237999981, 35.154618866000078],
              [-80.843619237999974, 35.154552866000074],
              [-80.84361023799994, 35.154432866000036],
              [-80.843581237999956, 35.154272865000053],
              [-80.843542237999941, 35.154146866000076],
              [-80.843503237999983, 35.154050866000034],
              [-80.843131237999955, 35.153414866000048],
              [-80.843038237999963, 35.153260866000039],
              [-80.843013237999969, 35.153211866000049],
              [-80.842979237999941, 35.153111866000074],
              [-80.842966237999974, 35.153030866000051],
              [-80.842969237999966, 35.152925865000043],
              [-80.842984237999985, 35.152848865000067],
              [-80.84309223799994, 35.152468866000049],
              [-80.843138237999938, 35.152290866000044],
              [-80.843249237999942, 35.151899865000075],
              [-80.84193223799997, 35.151670866000075],
              [-80.841672237999944, 35.151599865000037],
              [-80.841741236999951, 35.151455866000049],
              [-80.842549237999947, 35.149775865000038],
              [-80.842994236999971, 35.148872864000055],
              [-80.843244237999954, 35.148365864000027],
              [-80.84347923699994, 35.147919865000063],
              [-80.843780237999965, 35.147348864000037],
              [-80.844061236999949, 35.146810864000031],
              [-80.844121236999968, 35.146702864000076],
              [-80.844160236999983, 35.146633865000069],
              [-80.844380236999939, 35.146248864000029],
              [-80.84482223699996, 35.145417863000034],
              [-80.845092238999939, 35.144905864000066],
              [-80.843801237999969, 35.144828864000033],
              [-80.843027235999955, 35.144777864000048],
              [-80.842847236999944, 35.144748864000064],
              [-80.842805236999936, 35.144740864000028],
              [-80.842749237999953, 35.144715863000044],
              [-80.84268723699995, 35.144662864000054],
              [-80.842658236999966, 35.144629864000024],
              [-80.842594236999958, 35.144529863000059],
              [-80.842568236999966, 35.144476864000069],
              [-80.842507237999939, 35.144293864000076],
              [-80.842248235999989, 35.14344886300006],
              [-80.840838235999968, 35.143738863000067],
              [-80.841330236999966, 35.145365864000041],
              [-80.841337236999948, 35.145411864000039],
              [-80.841338236999945, 35.145456864000039],
              [-80.841330236999966, 35.145518863000063],
              [-80.841300236999984, 35.145609863000061],
              [-80.841249236999943, 35.145694864000063],
              [-80.841210236999984, 35.145738864000066],
              [-80.841120236999984, 35.145809864000057],
              [-80.840693236999982, 35.146063864000041],
              [-80.840566236999962, 35.146134864000032],
              [-80.839657235999937, 35.146661865000056],
              [-80.839548236999974, 35.146708864000061],
              [-80.839430235999941, 35.146740864000037],
              [-80.839308235999965, 35.146756865000043],
              [-80.839165236999975, 35.146759864000046],
              [-80.838989235999975, 35.146762864000038],
              [-80.838533235999989, 35.146756865000043],
              [-80.837162235999983, 35.146730865000052],
              [-80.836972235999951, 35.146734865000042],
              [-80.83686423599994, 35.146748864000074],
              [-80.836551235999934, 35.146755865000046],
              [-80.836584235999965, 35.145777864000024],
              [-80.836612235999951, 35.144952864000061],
              [-80.836178235999967, 35.144948863000025],
              [-80.835994235999976, 35.144956864000051],
              [-80.835903234999989, 35.144967863000034],
              [-80.83580223499996, 35.144985863000045],
              [-80.835074234999979, 35.145146864000026],
              [-80.833995234999975, 35.145387864000043],
              [-80.833275234999974, 35.14554886500008],
              [-80.833161234999977, 35.145556864000071],
              [-80.833078234999959, 35.145552864000024],
              [-80.832983234999972, 35.145539864000057],
              [-80.832449233999967, 35.145439864000025],
              [-80.832398234999971, 35.145418864000078],
              [-80.832187233999946, 35.145855864000055],
              [-80.831902233999983, 35.146421864000047],
              [-80.831607233999989, 35.147007865000035],
              [-80.831342234999966, 35.147532864000027],
              [-80.83104423399999, 35.148122865000062],
              [-80.83098423399997, 35.148242865000043],
              [-80.830896234999955, 35.148427865000031],
              [-80.830870234999963, 35.148484865000057],
              [-80.830657233999943, 35.148883865000073],
              [-80.830501233999939, 35.149143865000042],
              [-80.83031323299997, 35.149430866000046],
              [-80.830156232999968, 35.149650865000069],
              [-80.829968233999978, 35.149896866000063],
              [-80.829770232999977, 35.150136865000036],
              [-80.829561232999936, 35.150371865000068],
              [-80.829343233999964, 35.15059986600005],
              [-80.829115232999982, 35.150821866000058],
              [-80.828877232999957, 35.151036865000037],
              [-80.828687233999972, 35.151196866000078],
              [-80.827429232999975, 35.152256866000073],
              [-80.826863231999937, 35.152741866000042],
              [-80.826968232999945, 35.152819867000062],
              [-80.827154232999987, 35.152940866000051],
              [-80.827739232999988, 35.153247866000072],
              [-80.828611233999936, 35.153690866000034],
              [-80.830815234999989, 35.154807866000056],
              [-80.830435234999982, 35.155301866000059],
              [-80.830356232999975, 35.155412866000063],
              [-80.830202234999945, 35.155652866000025],
              [-80.830141233999939, 35.155760867000026],
              [-80.830050234999987, 35.155938867000032],
              [-80.830027233999942, 35.15598486600004],
              [-80.829903232999982, 35.156206866000048],
              [-80.82984823399994, 35.156292867000047],
              [-80.829788232999988, 35.156376866000073],
              [-80.829625233999934, 35.156577867000067],
              [-80.829515232999938, 35.156692867000061],
              [-80.82936323399997, 35.156822867000074],
              [-80.829199233999987, 35.156945867000047],
              [-80.82904423399998, 35.157041867000032],
              [-80.828915233999965, 35.157104867000044],
              [-80.828738233999957, 35.157182867000074],
              [-80.828605233999951, 35.15723386600007],
              [-80.82836223299995, 35.157301867000058],
              [-80.828249233999941, 35.157336867000026],
              [-80.827719232999982, 35.157500868000056],
              [-80.827462233999938, 35.157580867000036],
              [-80.827571232999958, 35.157854867000026],
              [-80.827650232999986, 35.15809686700004],
              [-80.827696232999983, 35.158286867000072],
              [-80.827710233999937, 35.158345867000037],
              [-80.827743233999968, 35.158551867000028],
              [-80.827770233999956, 35.158957867000026],
              [-80.827752233999945, 35.15989386800004],
              [-80.828045233999944, 35.159892867000053],
              [-80.828225234999934, 35.159899868000025],
              [-80.828348233999975, 35.159898867000038],
              [-80.828472233999946, 35.159903868000072],
              [-80.82857923399996, 35.159913868000046],
              [-80.828685233999977, 35.159928868000065],
              [-80.828893232999974, 35.159968867000032],
              [-80.829092233999972, 35.160034868000025],
              [-80.82932623399995, 35.160134867000068],
              [-80.829576233999944, 35.160249867000061],
              [-80.830326233999983, 35.160600868000074],
              [-80.83051923499994, 35.160698868000054],
              [-80.830666233999978, 35.160763867000071],
              [-80.830719234999947, 35.160781867000026],
              [-80.830844234999972, 35.160811868000053],
              [-80.830975233999936, 35.160824868000077],
              [-80.831042234999984, 35.160824867000031],
              [-80.831175233999943, 35.16081286800005],
              [-80.831240234999939, 35.160798868000029],
              [-80.831439234999948, 35.160725867000053],
              [-80.831705234999959, 35.160636867000051],
              [-80.831816233999973, 35.160605868000061],
              [-80.831933234999951, 35.160594867000043],
              [-80.832054234999987, 35.160597868000025],
              [-80.832113234999952, 35.16060686700007],
              [-80.832227234999948, 35.160634867000056],
              [-80.83228223499998, 35.16065586700006],
              [-80.832561234999957, 35.160781867000026],
              [-80.832764234999956, 35.160858868000048],
              [-80.832956234999983, 35.160938867000027],
              [-80.833034235999946, 35.160981867000032],
              [-80.833204234999982, 35.161085868000043],
              [-80.833510235999938, 35.16132286800007],
              [-80.833662234999963, 35.161419868000053],
              [-80.833830235999983, 35.161511868000048],
              [-80.834055235999983, 35.161621867000065],
              [-80.834200234999969, 35.161676868000029],
              [-80.834355235999965, 35.161727868000071],
              [-80.834599235999974, 35.161794868000072],
              [-80.835003235999977, 35.161881867000034],
              [-80.835195235999947, 35.161939867000058],
              [-80.835602235999943, 35.162090868000064],
              [-80.835212235999961, 35.162813868000057],
              [-80.835070236999968, 35.163141868000025],
              [-80.834949236999989, 35.163474868000037],
              [-80.83491223599998, 35.163608869000029],
              [-80.834895234999976, 35.163699868000037],
              [-80.834884235999937, 35.16379286800003],
              [-80.834879235999949, 35.163924868000038],
              [-80.834886235999988, 35.164255868000055],
              [-80.835201235999989, 35.164246868000077],
              [-80.835420236999937, 35.164250868000067]
            ]
          ],
          [
            [
              [-80.848621239999943, 35.160053867000045],
              [-80.848429238999984, 35.160086867000075],
              [-80.848378238999942, 35.160136867000062],
              [-80.848328239999944, 35.160222867000073],
              [-80.848265238999943, 35.160381867000069],
              [-80.84821723899995, 35.160547867000048],
              [-80.848125238999955, 35.160935867000035],
              [-80.848042238999938, 35.161174867000057],
              [-80.847805238999968, 35.162039867000033],
              [-80.847735239999963, 35.162192867000044],
              [-80.847662239999977, 35.162273867000067],
              [-80.847590239999988, 35.162335867000024],
              [-80.847472239999945, 35.162391867000053],
              [-80.847333239999955, 35.162467868000078],
              [-80.847224238999956, 35.162566867000066],
              [-80.847197239999957, 35.162688868000032],
              [-80.847276239999985, 35.162894868000024],
              [-80.847338238999953, 35.163021868000044],
              [-80.847417238999981, 35.163208867000037],
              [-80.847508239999968, 35.163490868000054],
              [-80.847533239999962, 35.163626868000051],
              [-80.847550239999975, 35.163715867000064],
              [-80.84757923899997, 35.163995868000029],
              [-80.847568239999987, 35.164178867000032],
              [-80.84753423899997, 35.16429486800007],
              [-80.847483239999974, 35.164350867000053],
              [-80.847374240999955, 35.164424868000026],
              [-80.847271239999941, 35.164481868000053],
              [-80.84714723999997, 35.164556868000034],
              [-80.846916238999938, 35.164672868000025],
              [-80.846805238999934, 35.164716868000028],
              [-80.846599238999943, 35.164768868000067],
              [-80.846483239999941, 35.164873869000076],
              [-80.846403238999983, 35.164972869000053],
              [-80.846237238999947, 35.165209867000044],
              [-80.846110238999984, 35.165433868000036],
              [-80.846002239999962, 35.16560086800007],
              [-80.845842239999968, 35.165724868000041],
              [-80.845646238999961, 35.165910868000026],
              [-80.845548238999982, 35.166031868000061],
              [-80.845303238999975, 35.16633286900003],
              [-80.844993238999962, 35.166751868000063],
              [-80.844855239999958, 35.166869868000049],
              [-80.844814238999959, 35.166901869000071],
              [-80.84473823999997, 35.166962869000031],
              [-80.844526238999947, 35.167093869000041],
              [-80.844499238999958, 35.167197868000073],
              [-80.844523238999955, 35.167270869000049],
              [-80.844571238999947, 35.167323869000029],
              [-80.844687238999938, 35.167453868000052],
              [-80.844807238999977, 35.167543868000052],
              [-80.844935239999984, 35.167614868000044],
              [-80.845010238999976, 35.167643869000074],
              [-80.845129238999959, 35.167678868000053],
              [-80.845234238999979, 35.167701869000041],
              [-80.84547323999999, 35.167770868000048],
              [-80.845704238999986, 35.167821868000033],
              [-80.846247239999968, 35.167886868000039],
              [-80.846418238999945, 35.167920868000067],
              [-80.846583239999973, 35.167979868000032],
              [-80.846710239999936, 35.168044869000028],
              [-80.84680923999997, 35.168127868000056],
              [-80.846832239999969, 35.168182869000077],
              [-80.846850239999981, 35.168322868000075],
              [-80.846921238999983, 35.168515869000032],
              [-80.846998239999948, 35.168810869000026],
              [-80.847046239999941, 35.168955869000058],
              [-80.847071238999945, 35.169083869000076],
              [-80.847036239999966, 35.169162869000047],
              [-80.847014239999965, 35.169187868000051],
              [-80.846705239999949, 35.169308869000076],
              [-80.846559238999987, 35.169401869000069],
              [-80.846480239999948, 35.169506869000031],
              [-80.846335239999974, 35.16964887000006],
              [-80.846270239999967, 35.169753869000033],
              [-80.846191239999939, 35.169845869000028],
              [-80.846141238999962, 35.169931868000049],
              [-80.846031238999956, 35.170143868000025],
              [-80.845909239999969, 35.170322869000074],
              [-80.84580823999994, 35.170482869000068],
              [-80.845607238999946, 35.17074186900004],
              [-80.845527238999978, 35.170833869000035],
              [-80.845462238999971, 35.170895869000049],
              [-80.845419238999966, 35.170975869000074],
              [-80.845377238999959, 35.171085869000024],
              [-80.845375238999964, 35.171120869000049],
              [-80.845366239999976, 35.171262869000032],
              [-80.845402239999942, 35.171350870000026],
              [-80.845493239999939, 35.171470869000075],
              [-80.845615239999972, 35.171590869000056],
              [-80.845713239999952, 35.17166886900003],
              [-80.845848239999953, 35.171757870000079],
              [-80.846308239999985, 35.172079869000072],
              [-80.846414239999945, 35.172169870000062],
              [-80.846527239999944, 35.172234869000079],
              [-80.846581238999988, 35.172306870000057],
              [-80.846597238999948, 35.172361870000032],
              [-80.846598238999945, 35.172422869000059],
              [-80.846587239999963, 35.172574870000062],
              [-80.846527238999954, 35.172756869000068],
              [-80.846521238999969, 35.172778870000059],
              [-80.846515240999963, 35.172865870000066],
              [-80.846525239999949, 35.17298087000006],
              [-80.846529239999938, 35.173151870000027],
              [-80.846519239999964, 35.173358871000062],
              [-80.846520239999961, 35.173422870000024],
              [-80.846527238999954, 35.173693870000079],
              [-80.846524239999951, 35.173891870000034],
              [-80.846525239999949, 35.173914870000033],
              [-80.846540239999968, 35.17405787000007],
              [-80.846560239999974, 35.174218870000061],
              [-80.846569239999951, 35.174387870000032],
              [-80.846572239999944, 35.174553870000068],
              [-80.84658423999997, 35.174743871000032],
              [-80.846580238999934, 35.175028870000062],
              [-80.846578238999939, 35.175133870000025],
              [-80.84658823999996, 35.175236870000049],
              [-80.846627240999965, 35.175519870000073],
              [-80.846647239999982, 35.175726870000062],
              [-80.846650240999963, 35.175872871000024],
              [-80.846634239999958, 35.176122870000029],
              [-80.846540239999968, 35.17658687100004],
              [-80.846519239999964, 35.17679787000003],
              [-80.846515240999963, 35.17696187100006],
              [-80.84649023999998, 35.177163870000072],
              [-80.846455239999955, 35.177553871000043],
              [-80.846402239999975, 35.177758871000037],
              [-80.846393240999987, 35.177791871000068],
              [-80.846257240999989, 35.178479870000047],
              [-80.84622423999997, 35.17862787100006],
              [-80.846196240999973, 35.178967871000054],
              [-80.846590239999955, 35.179050871000072],
              [-80.846894239999983, 35.179122871000061],
              [-80.848875241999963, 35.179629871000031],
              [-80.849413240999979, 35.179767871000024],
              [-80.849676241999987, 35.179830871000036],
              [-80.849830241999939, 35.179873871000041],
              [-80.850018240999987, 35.179909871000064],
              [-80.850435241999946, 35.179954870000074],
              [-80.851363241999934, 35.180022871000062],
              [-80.851201241999945, 35.178971871000044],
              [-80.851162241999987, 35.178714871000068],
              [-80.851130241999954, 35.178544871000042],
              [-80.851088240999957, 35.178278870000042],
              [-80.851057241999968, 35.177973870000073],
              [-80.851035241999966, 35.177737871000033],
              [-80.851018241999952, 35.177363871000068],
              [-80.851016240999968, 35.177188870000066],
              [-80.85102324099995, 35.176714870000069],
              [-80.851034241999969, 35.176368870000033],
              [-80.851045240999952, 35.176164871000026],
              [-80.851030240999989, 35.175930871000048],
              [-80.851042241999949, 35.175758870000038],
              [-80.851092241999936, 35.175047870000071],
              [-80.851134241999944, 35.174351870000066],
              [-80.851177241999949, 35.173800870000036],
              [-80.85121824099997, 35.173354870000026],
              [-80.851233241999978, 35.173038870000028],
              [-80.85123224199998, 35.172761870000045],
              [-80.851213241999972, 35.172469869000054],
              [-80.85116224099994, 35.172079870000061],
              [-80.851042241999949, 35.171274868000069],
              [-80.850945240999977, 35.170531870000048],
              [-80.85090723999997, 35.170224869000037],
              [-80.850734239999952, 35.168817868000076],
              [-80.85065124099998, 35.168206869000073],
              [-80.850499240999966, 35.167339868000056],
              [-80.850442240999939, 35.166706869000052],
              [-80.850399239999945, 35.165665868000076],
              [-80.850267240999983, 35.165027868000038],
              [-80.850175239999942, 35.164590867000072],
              [-80.850123240999949, 35.164422868000031],
              [-80.850053240999955, 35.164259867000055],
              [-80.849966240999947, 35.164102867000054],
              [-80.849862239999936, 35.163952868000024],
              [-80.849679239999944, 35.163732868000068],
              [-80.849584239999956, 35.163596867000024],
              [-80.849501240999984, 35.163455867000039],
              [-80.849430239999947, 35.163310868000053],
              [-80.849360240999943, 35.163123867000024],
              [-80.849317240999937, 35.162971868000056],
              [-80.849245240999949, 35.162686868000037],
              [-80.849155239999959, 35.162336868000068],
              [-80.849053239999989, 35.16193486700007],
              [-80.848916240999984, 35.161179867000044],
              [-80.848833239999976, 35.160465867000028],
              [-80.848785239999984, 35.160046867000062],
              [-80.848621239999943, 35.160053867000045]
            ]
          ],
          [
            [
              [-80.87909824999997, 35.17587486900004],
              [-80.879178249999939, 35.176164870000036],
              [-80.879403248999949, 35.177497869000035],
              [-80.879433249999977, 35.177954869000075],
              [-80.879583249999939, 35.177997869000023],
              [-80.879658249999977, 35.178013870000029],
              [-80.880131249999977, 35.178139869000063],
              [-80.880662249999943, 35.178292870000064],
              [-80.880826250999974, 35.178339870000059],
              [-80.881113250999988, 35.178405869000073],
              [-80.883076251999967, 35.178925870000057],
              [-80.883477250999988, 35.179038869000067],
              [-80.883782251999946, 35.179119870000079],
              [-80.884355251999978, 35.179287870000053],
              [-80.884808251999971, 35.179418870000063],
              [-80.88501925099996, 35.17947987000008],
              [-80.886461251999947, 35.179905869000038],
              [-80.886632252999959, 35.179956870000069],
              [-80.886863252999945, 35.180024870000068],
              [-80.886711251999941, 35.179521870000031],
              [-80.886573252999938, 35.179121870000074],
              [-80.886473251999973, 35.178814869000064],
              [-80.886372252999934, 35.178448870000068],
              [-80.886311251999985, 35.178165869000054],
              [-80.88625725199995, 35.177781869000057],
              [-80.886229251999964, 35.177461869000069],
              [-80.886220251999987, 35.177023869000038],
              [-80.886235251999949, 35.176663869000038],
              [-80.886265250999941, 35.176341870000044],
              [-80.886291251999978, 35.176150868000036],
              [-80.886355251999987, 35.175787869000033],
              [-80.886396252999987, 35.175602868000055],
              [-80.886493252999969, 35.175234868000075],
              [-80.886612251999964, 35.174822869000025],
              [-80.886487251999938, 35.174635868000053],
              [-80.886305251999943, 35.174362869000049],
              [-80.885668251999959, 35.173406868000029],
              [-80.885296251999989, 35.173723869000071],
              [-80.885047251999936, 35.175446868000051],
              [-80.884485251999934, 35.175479869000071],
              [-80.882464250999988, 35.175618869000061],
              [-80.882239250999987, 35.175635869000075],
              [-80.879283249999958, 35.175851869000041],
              [-80.879165248999982, 35.175357869000038],
              [-80.878895248999982, 35.174230869000041],
              [-80.878608249999957, 35.173082869000041],
              [-80.878463248999935, 35.172462869000071],
              [-80.878434249999941, 35.172290868000061],
              [-80.878423248999979, 35.17221986800007],
              [-80.878247248999969, 35.171485868000048],
              [-80.878211247999957, 35.171375868000041],
              [-80.878139248999958, 35.171105868000041],
              [-80.878080248999936, 35.170795868000027],
              [-80.878065249999963, 35.170673867000062],
              [-80.878044249999959, 35.170259868000073],
              [-80.878309248999983, 35.170221868000056],
              [-80.880204249999963, 35.169954868000048],
              [-80.880244248999986, 35.169941868000024],
              [-80.880307249999987, 35.169906868000055],
              [-80.880339248999974, 35.169882868000059],
              [-80.880394248999949, 35.169823868000037],
              [-80.88054724999995, 35.169597868000039],
              [-80.88067424999997, 35.169390867000061],
              [-80.880702250999946, 35.169337868000071],
              [-80.880743249999966, 35.169227868000064],
              [-80.880918249999979, 35.168487867000067],
              [-80.880935249999936, 35.168443868000054],
              [-80.880958248999946, 35.168400868000049],
              [-80.880986249999978, 35.168360867000047],
              [-80.881057249999969, 35.168286868000052],
              [-80.881099249999977, 35.168253867000033],
              [-80.881179248999956, 35.168206868000027],
              [-80.881787249999945, 35.167918868000072],
              [-80.882064249999985, 35.16779286700006],
              [-80.882143249999956, 35.167762868000068],
              [-80.881197249999957, 35.166249867000033],
              [-80.880654249999964, 35.165551867000033],
              [-80.880497248999973, 35.165349867000032],
              [-80.878297247999967, 35.161949867000033],
              [-80.878251248999959, 35.161843866000027],
              [-80.877297247999934, 35.159649866000052],
              [-80.877109248999943, 35.158869865000042],
              [-80.876994248999949, 35.158315866000066],
              [-80.876897247999977, 35.15784986500006],
              [-80.876927247999959, 35.158309865000035],
              [-80.876965247999976, 35.158885866000048],
              [-80.877197247999959, 35.162949866000076],
              [-80.877455248999979, 35.165107867000074],
              [-80.877471247999949, 35.165368867000041],
              [-80.877585247999946, 35.167158867000069],
              [-80.877639248999969, 35.168020867000052],
              [-80.877723248999985, 35.16907586800005],
              [-80.877762248999943, 35.169558867000035],
              [-80.877857248999987, 35.17027986800008],
              [-80.877906248999977, 35.170687869000062],
              [-80.878021248999971, 35.171408868000071],
              [-80.878213248999941, 35.17231786900004],
              [-80.878624249999973, 35.174153869000065],
              [-80.879059249999955, 35.175718869000036],
              [-80.879081249999956, 35.175812870000073],
              [-80.87909824999997, 35.17587486900004]
            ]
          ],
          [
            [
              [-80.873304248999943, 35.194455873000038],
              [-80.87447224999994, 35.194872873000065],
              [-80.874775249999971, 35.194973873000038],
              [-80.875134249999974, 35.195098874000053],
              [-80.87548524999994, 35.195221873000037],
              [-80.876259248999986, 35.195511874000033],
              [-80.876885249999987, 35.195736873000044],
              [-80.877218249999942, 35.195867873000054],
              [-80.877931249999961, 35.194949872000052],
              [-80.878011249999986, 35.194845874000066],
              [-80.878146250999976, 35.194667873000071],
              [-80.879313250999985, 35.193183873000066],
              [-80.879918250999935, 35.19241687300007],
              [-80.880481250999935, 35.191700872000069],
              [-80.88131225099994, 35.190641872000072],
              [-80.881465250999952, 35.19044787200005],
              [-80.882041250999976, 35.189716872000076],
              [-80.882472251999957, 35.189159872000062],
              [-80.882548250999946, 35.189054872000042],
              [-80.883367251999971, 35.188013872000056],
              [-80.884355251999978, 35.186758871000052],
              [-80.884705251999947, 35.185164870000051],
              [-80.88444825299996, 35.18531487100006],
              [-80.884115252999948, 35.185492871000065],
              [-80.884014251999986, 35.185422871000071],
              [-80.883953251999969, 35.185385871000051],
              [-80.883872251999946, 35.185344871000041],
              [-80.882312250999973, 35.184635871000069],
              [-80.882227250999961, 35.184588871000074],
              [-80.882101250999938, 35.184528871000055],
              [-80.882050250999953, 35.184506870000064],
              [-80.881759250999949, 35.184381871000028],
              [-80.881189250999967, 35.184120871000061],
              [-80.880523250999943, 35.183824870000024],
              [-80.880010250999987, 35.183601870000075],
              [-80.879423250999935, 35.183325870000033],
              [-80.879128249999951, 35.183757871000068],
              [-80.878565249999951, 35.184541871000079],
              [-80.878478249999944, 35.184782871000039],
              [-80.878439250999975, 35.184872871000039],
              [-80.878161250999938, 35.18549887100005],
              [-80.878042249999964, 35.185752871000034],
              [-80.877749249999965, 35.186317872000075],
              [-80.87737924999999, 35.186985872000037],
              [-80.877290249999987, 35.187148871000034],
              [-80.877230249999968, 35.187237872000026],
              [-80.876935249999974, 35.187762872000064],
              [-80.876891249999971, 35.187840872000038],
              [-80.876851249999959, 35.187893871000028],
              [-80.876757249999969, 35.187990872000057],
              [-80.876657249999937, 35.18807187200008],
              [-80.876606249999952, 35.188103872000056],
              [-80.876542248999954, 35.18813687200003],
              [-80.876388249999934, 35.18845187200003],
              [-80.875493249999977, 35.189857872000061],
              [-80.873097248999954, 35.194349873000078],
              [-80.872923248999939, 35.194740873000057],
              [-80.872901249999984, 35.19477687300008],
              [-80.872884248999981, 35.194826874000057],
              [-80.872918248999952, 35.194781874000057],
              [-80.872955248999972, 35.194726874000025],
              [-80.873074248999956, 35.194511873000067],
              [-80.873119248999956, 35.194474873000047],
              [-80.87317724899998, 35.194451873000048],
              [-80.873241247999943, 35.194444873000066],
              [-80.873304248999943, 35.194455873000038]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 132,
        "SolutionID": "PI-13",
        "Shape_Length": 0.36209032382917383,
        "Shape_Area": 0.00065399302543622546
      }
    },
    {
      "type": "Feature",
      "id": 133,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.84615424399999, 35.248069886000053],
            [-80.846319243999972, 35.248083885000028],
            [-80.84640624299999, 35.248095886000044],
            [-80.846516243999986, 35.248115885000061],
            [-80.846620243999951, 35.248139885000057],
            [-80.846742243999984, 35.248176886000067],
            [-80.846904244999962, 35.248242886000071],
            [-80.847084243999973, 35.248337886000058],
            [-80.847192243999984, 35.248395886000026],
            [-80.847332244999961, 35.248489886000073],
            [-80.847398243999976, 35.248541885000066],
            [-80.847833244999947, 35.248958886000025],
            [-80.848025243999984, 35.249117886000079],
            [-80.848231244999965, 35.249264886000049],
            [-80.848540244999981, 35.249451887000077],
            [-80.848978244999955, 35.249688886000058],
            [-80.849291244999961, 35.249857886000029],
            [-80.850624244999949, 35.250579886000025],
            [-80.850889244999962, 35.250720887000057],
            [-80.851133245999961, 35.250844885000049],
            [-80.851729245999934, 35.251160886000036],
            [-80.852110245999938, 35.251363887000025],
            [-80.852348245999963, 35.25147488600004],
            [-80.852594245999967, 35.251574886000071],
            [-80.85290024599999, 35.251684886000078],
            [-80.853384244999972, 35.251858886000036],
            [-80.853910245999941, 35.252044887000068],
            [-80.854554246999953, 35.25228088700004],
            [-80.854772245999982, 35.25235288600004],
            [-80.855105246999983, 35.252464887000031],
            [-80.85557924699998, 35.252632887000061],
            [-80.856123246999971, 35.25280988600008],
            [-80.856207247999976, 35.252041886000029],
            [-80.85627724699998, 35.251412886000026],
            [-80.856288245999963, 35.251323887000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856421247999947, 35.250138885000069],
            [-80.856477247999976, 35.249634885000035],
            [-80.856508246999965, 35.249355886000046],
            [-80.856546246999983, 35.249017885000058],
            [-80.856629246999944, 35.248274886000047],
            [-80.856703246999984, 35.247564884000042],
            [-80.856765246999942, 35.247091886000078],
            [-80.856792247999977, 35.246850885000072],
            [-80.856890247999957, 35.246010885000032],
            [-80.856932246999975, 35.245730884000068],
            [-80.856949247999978, 35.245655885000076],
            [-80.857003246999966, 35.24549688500008],
            [-80.857033246999947, 35.245437885000058],
            [-80.857107246999988, 35.245326885000054],
            [-80.857538246999979, 35.244792885000038],
            [-80.857630246999975, 35.244685885000024],
            [-80.857699246999971, 35.244591885000034],
            [-80.857786246999979, 35.244451884000057],
            [-80.858009246999984, 35.243950885000061],
            [-80.858212247999973, 35.243474885000069],
            [-80.858582247999948, 35.242545884000037],
            [-80.858620246999976, 35.242420883000079],
            [-80.858699246999947, 35.242111884000053],
            [-80.858725247999985, 35.24193688400004],
            [-80.858728246999988, 35.241918884000029],
            [-80.858728246999988, 35.241808884000079],
            [-80.858716247999951, 35.241739883000037],
            [-80.858673246999956, 35.241489884000032],
            [-80.858670246999964, 35.241440883000053],
            [-80.858623247999958, 35.241203884000072],
            [-80.858544246999941, 35.240924884000037],
            [-80.858500246999938, 35.240738883000063],
            [-80.858471247999944, 35.240559884000049],
            [-80.858453246999943, 35.240271883000048],
            [-80.858455246999938, 35.239899883000078],
            [-80.85846124699998, 35.239795884000046],
            [-80.858475246999944, 35.239531883000041],
            [-80.858520246999944, 35.239156884000067],
            [-80.858553245999985, 35.238978884000062],
            [-80.85859624699998, 35.238845884000057],
            [-80.858627246999959, 35.23875088300008],
            [-80.858729246999985, 35.238531884000054],
            [-80.858810246999951, 35.238225883000041],
            [-80.858868246999975, 35.237933883000039],
            [-80.858884246999935, 35.237872882000033],
            [-80.858900246999951, 35.237747883000054],
            [-80.858895247999953, 35.237622882000039],
            [-80.858882245999951, 35.237551883000037],
            [-80.858833247999939, 35.237372883000035],
            [-80.858764246999954, 35.237197883000078],
            [-80.858711246999974, 35.23709688200006],
            [-80.858630245999962, 35.236985883000045],
            [-80.858585246999951, 35.236938882000061],
            [-80.858348246999981, 35.23672588200003],
            [-80.858232246999989, 35.236637883000071],
            [-80.858076246999985, 35.236542883000027],
            [-80.857900246999975, 35.236458883000068],
            [-80.857800246999943, 35.236419883000053],
            [-80.85758324699998, 35.23633888300003],
            [-80.857400245999941, 35.236279883000066],
            [-80.85730624699994, 35.236256883000067],
            [-80.856517246999942, 35.236090883000031],
            [-80.856192245999978, 35.23602388300003],
            [-80.856033245999981, 35.235984883000071],
            [-80.855792245999965, 35.23592988300004],
            [-80.855929246999949, 35.235802883000076],
            [-80.856110246999947, 35.235646883000072],
            [-80.856303246999971, 35.235490883000068],
            [-80.856497246999936, 35.235342883000044],
            [-80.856882245999941, 35.235064882000074],
            [-80.85716324699996, 35.234877883000024],
            [-80.857377246999988, 35.234718882000038],
            [-80.857540245999985, 35.234575882000058],
            [-80.857688246999942, 35.234454883000069],
            [-80.857805246999988, 35.234359883000025],
            [-80.857993246999968, 35.234185882000077],
            [-80.858107246999964, 35.234067882000033],
            [-80.858231246999935, 35.23392988300003],
            [-80.85832624699998, 35.233810883000046],
            [-80.858397245999981, 35.233730882000032],
            [-80.858498245999954, 35.233613882000043],
            [-80.858576246999974, 35.233498882000049],
            [-80.85864124699998, 35.233405882000056],
            [-80.858721246999949, 35.233285882000075],
            [-80.858836246999942, 35.23310788200007],
            [-80.858951246999936, 35.232909882000058],
            [-80.859075247999954, 35.232671882000034],
            [-80.859185246999971, 35.232395881000059],
            [-80.859295246999977, 35.23211688300006],
            [-80.859451245999935, 35.23172788200003],
            [-80.859581247999984, 35.231326882000076],
            [-80.859587246999979, 35.231307881000077],
            [-80.859659246999968, 35.23108588100007],
            [-80.859688246999951, 35.230996881000067],
            [-80.859958246999952, 35.230195881000043],
            [-80.86006724799995, 35.229879881000045],
            [-80.860150246999979, 35.229650881000055],
            [-80.860200247999956, 35.229528881000078],
            [-80.860283247999973, 35.229339881000044],
            [-80.860347246999936, 35.229199881000056],
            [-80.860437246999936, 35.229017881000061],
            [-80.860519246999957, 35.228853881000077],
            [-80.860627246999968, 35.22866188100005],
            [-80.860697246999962, 35.228557881000029],
            [-80.860872246999975, 35.228311882000071],
            [-80.86100524699998, 35.228127881000034],
            [-80.861118246999979, 35.227989881000042],
            [-80.861255247999964, 35.227814881000029],
            [-80.861344246999977, 35.227729881000073],
            [-80.861504246999971, 35.22755088100007],
            [-80.86189124699996, 35.227178881000043],
            [-80.86202624699996, 35.227069880000045],
            [-80.862316246999967, 35.226837880000062],
            [-80.862545247999947, 35.226658880000059],
            [-80.862702247999948, 35.226536881000072],
            [-80.862913247999984, 35.226397881000025],
            [-80.863551247999965, 35.225938880000058],
            [-80.864230247999956, 35.225444880000055],
            [-80.86438624799996, 35.225323880000076],
            [-80.864550247999944, 35.225195879000069],
            [-80.864741247999973, 35.225046880000036],
            [-80.864835247999963, 35.22496988000006],
            [-80.865325247999976, 35.224571880000042],
            [-80.865536247999955, 35.224391880000042],
            [-80.865685248999966, 35.224264880000078],
            [-80.865750247999983, 35.224209880000046],
            [-80.866330248999986, 35.223693879000052],
            [-80.866778248999935, 35.223255880000067],
            [-80.866834248999965, 35.223200880000036],
            [-80.866986247999989, 35.223036879000063],
            [-80.866882247999968, 35.223013879000064],
            [-80.866715248999981, 35.222986879000075],
            [-80.866545248999955, 35.222972880000043],
            [-80.866375248999987, 35.222970880000048],
            [-80.86624024799994, 35.22297788000003],
            [-80.86484724799999, 35.223094880000076],
            [-80.864334247999977, 35.223135880000029],
            [-80.864029248999941, 35.223154879000049],
            [-80.863930247999974, 35.223157880000031],
            [-80.862988247999965, 35.222049880000043],
            [-80.862017246999983, 35.220920880000051],
            [-80.860974246999945, 35.219699879000075],
            [-80.860573245999944, 35.219256879000056],
            [-80.860512246999974, 35.219201879000025],
            [-80.860295245999964, 35.219058879000045],
            [-80.859297245999983, 35.218460879000077],
            [-80.859572245999971, 35.218070879000038],
            [-80.860024246999956, 35.217414878000056],
            [-80.860695246999967, 35.216440878000071],
            [-80.858310245999974, 35.215334878000078],
            [-80.857102244999965, 35.214768878000029],
            [-80.857006245999969, 35.214705878000075],
            [-80.856838244999949, 35.214592878000076],
            [-80.856788245999951, 35.214554878000058],
            [-80.856290243999979, 35.214180878000036],
            [-80.855889244999958, 35.213887877000047],
            [-80.855610243999934, 35.213678878000053],
            [-80.85495724499998, 35.213182878000055],
            [-80.854414244999987, 35.212782878000041],
            [-80.854090243999963, 35.212543878000076],
            [-80.853744243999984, 35.212271878000024],
            [-80.853374243999951, 35.212014877000058],
            [-80.853284244999941, 35.211943878000056],
            [-80.853006243999971, 35.211726877000046],
            [-80.852287243999967, 35.211187878000032],
            [-80.852148243999977, 35.211083878000068],
            [-80.85178024399994, 35.210810878000075],
            [-80.851704242999972, 35.210745877000079],
            [-80.851661243999956, 35.210699878000071],
            [-80.85160024399994, 35.210619877000056],
            [-80.85153524399999, 35.210494878000077],
            [-80.851491242999941, 35.210364877000075],
            [-80.851476243999969, 35.210297877000073],
            [-80.851466242999948, 35.21022087700004],
            [-80.851477243999966, 35.21008187700005],
            [-80.851500242999975, 35.209976878000077],
            [-80.851434242999972, 35.20994687700005],
            [-80.851384243999973, 35.209904877000042],
            [-80.850890242999981, 35.209371877000024],
            [-80.850678242999948, 35.209178878000046],
            [-80.850599242999976, 35.209125877000076],
            [-80.850509243999966, 35.209087877000059],
            [-80.850398242999972, 35.209033877000024],
            [-80.850370242999986, 35.209006878000025],
            [-80.850363242999947, 35.208987878000073],
            [-80.85012924199998, 35.209000877000051],
            [-80.849988242999984, 35.208997877000058],
            [-80.849850242999935, 35.208976877000055],
            [-80.849716242999989, 35.208938877000037],
            [-80.849589242999969, 35.208885877000057],
            [-80.849469241999941, 35.208815877000063],
            [-80.849363242999971, 35.208732878000035],
            [-80.849272241999984, 35.208638876000066],
            [-80.84923624299995, 35.208590877000063],
            [-80.849124242999949, 35.208391877000054],
            [-80.849084241999947, 35.20830687800003],
            [-80.849058242999945, 35.208265877000031],
            [-80.848992241999952, 35.208189878000042],
            [-80.848910242999978, 35.208125877000043],
            [-80.848822241999983, 35.208077877000051],
            [-80.848568241999942, 35.207963877000054],
            [-80.848492241999963, 35.207923877000042],
            [-80.848349241999983, 35.207832877000044],
            [-80.848282242999971, 35.207782877000056],
            [-80.848188241999935, 35.207698878000031],
            [-80.848123241999986, 35.207633877000035],
            [-80.848008241999935, 35.207495877000042],
            [-80.847975241999961, 35.207448877000047],
            [-80.847886241999959, 35.207329878000053],
            [-80.847820242999944, 35.207251877000033],
            [-80.847743242999968, 35.207169877000069],
            [-80.847616241999958, 35.207062877000055],
            [-80.847475241999973, 35.206969877000063],
            [-80.847324241999956, 35.20688887600005],
            [-80.847160241999973, 35.206820877000041],
            [-80.847075242999949, 35.206791877000057],
            [-80.846901241999944, 35.206747876000065],
            [-80.846812241999942, 35.206731877000038],
            [-80.846585241999946, 35.20670887700004],
            [-80.846452241999941, 35.206767876000072],
            [-80.846352240999977, 35.206805877000079],
            [-80.846145241999977, 35.206869876000042],
            [-80.846016240999973, 35.206901877000064],
            [-80.845977241999947, 35.206910877000041],
            [-80.845706241999949, 35.206996877000051],
            [-80.845446240999934, 35.207040877000054],
            [-80.845288241999981, 35.207066877000045],
            [-80.84519224099995, 35.207150877000061],
            [-80.845104240999945, 35.207190877000073],
            [-80.84493624199996, 35.207282877000068],
            [-80.844881240999939, 35.207320877000029],
            [-80.844767240999943, 35.207415877000074],
            [-80.844669240999963, 35.207521877000033],
            [-80.844627239999966, 35.20757887700006],
            [-80.844558240999959, 35.207686877000071],
            [-80.844465240999966, 35.207861877000028],
            [-80.844427241999938, 35.20795087700003],
            [-80.844385241999987, 35.208066878000068],
            [-80.844350241999962, 35.208209877000058],
            [-80.844340240999941, 35.208297877000064],
            [-80.844337240999948, 35.20835487800008],
            [-80.844368241999973, 35.208991877000074],
            [-80.844378240999959, 35.209177878000048],
            [-80.844385241999987, 35.209544877000042],
            [-80.844380240999953, 35.209733877000076],
            [-80.844356240999957, 35.21007787800005],
            [-80.844336240999951, 35.210264878000032],
            [-80.84428724199995, 35.21067787800007],
            [-80.844210240999985, 35.211402878000058],
            [-80.844139240999937, 35.212045879000073],
            [-80.844130241999949, 35.212199879000025],
            [-80.844135241999936, 35.212419878000048],
            [-80.844146241999965, 35.212529878000055],
            [-80.844147240999973, 35.212589878000074],
            [-80.844165240999985, 35.212734878000049],
            [-80.844183240999939, 35.212819878000062],
            [-80.844207241999982, 35.212904878000074],
            [-80.844244240999956, 35.213012879000075],
            [-80.844337241999938, 35.21322187800007],
            [-80.844391241999972, 35.21332387800004],
            [-80.844525241999975, 35.213547879000032],
            [-80.844622241999957, 35.213711878000026],
            [-80.844836241999985, 35.21403987900004],
            [-80.844594240999982, 35.213788878000059],
            [-80.84430224099998, 35.213485879000075],
            [-80.844162240999935, 35.213339878000056],
            [-80.843885240999953, 35.213012879000075],
            [-80.843823240999939, 35.212938878000045],
            [-80.843461240999943, 35.212549878000061],
            [-80.842867240999965, 35.211900878000051],
            [-80.842421240999954, 35.211425879000046],
            [-80.84207724099997, 35.211050878000037],
            [-80.841896239999983, 35.210846878000041],
            [-80.840955239999971, 35.209825878000061],
            [-80.840388239999982, 35.209204877000047],
            [-80.839999239999941, 35.208789877000072],
            [-80.839778239999987, 35.20854287700007],
            [-80.839641239999935, 35.208395877000044],
            [-80.838659238999981, 35.20732287800007],
            [-80.838290238999946, 35.206923877000065],
            [-80.838034238999967, 35.206654877000062],
            [-80.837688238999988, 35.206323877000045],
            [-80.837514238999972, 35.206175877000078],
            [-80.837393237999947, 35.206071877000056],
            [-80.836861238999973, 35.205651877000037],
            [-80.836824238999952, 35.20562387700005],
            [-80.835905237999953, 35.204896877000067],
            [-80.83594723899995, 35.205085877000045],
            [-80.83611923899997, 35.20585987700008],
            [-80.83613123899994, 35.205952877000072],
            [-80.836141239999961, 35.206139877000055],
            [-80.836133238999935, 35.206262877000029],
            [-80.83611523899998, 35.206418877000033],
            [-80.836080238999955, 35.206604877000075],
            [-80.836073237999983, 35.206622878000076],
            [-80.836046238999984, 35.206750877000047],
            [-80.836009238999964, 35.206926877000058],
            [-80.835929237999949, 35.207360877000042],
            [-80.835834238999951, 35.207819877000077],
            [-80.835813237999957, 35.20794287800004],
            [-80.835805237999978, 35.208004877000064],
            [-80.835798238999985, 35.20819087700005],
            [-80.835811238999952, 35.208416878000037],
            [-80.83584123899999, 35.208627878000073],
            [-80.835864238999989, 35.208731878000037],
            [-80.835950238999942, 35.209010877000026],
            [-80.836045239999976, 35.209349877000079],
            [-80.836084238999945, 35.209557877000066],
            [-80.836095237999984, 35.209662878000074],
            [-80.836103238999954, 35.209876878000046],
            [-80.836089237999943, 35.210096878000058],
            [-80.836055238999961, 35.210308878000035],
            [-80.836048238999979, 35.210332879000077],
            [-80.83602923899997, 35.210413877000065],
            [-80.835970237999959, 35.210601878000034],
            [-80.835897238999962, 35.210766878000072],
            [-80.835813238999947, 35.21091087800005],
            [-80.835756237999988, 35.210987879000072],
            [-80.835666238999977, 35.211088878000055],
            [-80.835562238999955, 35.211181878000048],
            [-80.835513238999965, 35.211232877000043],
            [-80.835427238999955, 35.211290877000067],
            [-80.835374238999975, 35.211326878000079],
            [-80.835107238999967, 35.211524878000034],
            [-80.834631238999975, 35.211900878000051],
            [-80.83449423899998, 35.212025879000066],
            [-80.834432237999977, 35.212092878000078],
            [-80.834341238999968, 35.212206879000064],
            [-80.834313237999936, 35.212251878000075],
            [-80.834290238999984, 35.21229887800007],
            [-80.833942237999963, 35.213355879000062],
            [-80.833733237999979, 35.213313879000054],
            [-80.833514237999964, 35.213283878000027],
            [-80.833293238999943, 35.213265878000072],
            [-80.833015238999963, 35.213260878000028],
            [-80.832773236999969, 35.213272879000044],
            [-80.832464236999954, 35.213310879000062],
            [-80.832248236999988, 35.213353879000067],
            [-80.832035237999946, 35.213407879000044],
            [-80.83177823799997, 35.213492880000047],
            [-80.831579237999961, 35.21357387900008],
            [-80.831387236999944, 35.213665879000075],
            [-80.831203237999944, 35.213768878000053],
            [-80.831037236999975, 35.213876879000054],
            [-80.830979236999951, 35.213921879000054],
            [-80.830854237999972, 35.213752879000026],
            [-80.830644236999944, 35.213519879000046],
            [-80.830614236999963, 35.213509879000071],
            [-80.830584237999972, 35.213515878000067],
            [-80.830434236999963, 35.213368879000029],
            [-80.83028423799999, 35.213244879000058],
            [-80.829875236999953, 35.212949878000074],
            [-80.829667237999956, 35.212799878000055],
            [-80.829359236999949, 35.212565879000067],
            [-80.829239236999967, 35.212452879000068],
            [-80.829139237999982, 35.212335879000079],
            [-80.829019236999955, 35.212167879000049],
            [-80.828787236999972, 35.211833879000039],
            [-80.828595236999945, 35.211592879000079],
            [-80.828533236999988, 35.211520879000034],
            [-80.828384235999977, 35.211379879000049],
            [-80.828249236999966, 35.21126687900005],
            [-80.827903236999987, 35.211013879000063],
            [-80.827707235999981, 35.210895879000077],
            [-80.827620236999962, 35.210849878000033],
            [-80.827425236999943, 35.211104879000061],
            [-80.827280236999968, 35.211309879000055],
            [-80.827046236999934, 35.211609878000047],
            [-80.826793235999958, 35.211898879000046],
            [-80.826493234999987, 35.212173878000044],
            [-80.826862234999965, 35.212446879000026],
            [-80.826915235999934, 35.21248287800006],
            [-80.827373236999961, 35.212794878000068],
            [-80.827686236999966, 35.21301887900006],
            [-80.827862236999977, 35.213136879000047],
            [-80.828852235999989, 35.213832879000051],
            [-80.828940236999983, 35.21389687900006],
            [-80.829444236999962, 35.214255879000063],
            [-80.830005236999966, 35.214646879000043],
            [-80.829970237999987, 35.214672879000034],
            [-80.828990236999971, 35.215523879000045],
            [-80.828912235999951, 35.21557487900003],
            [-80.828719235999984, 35.215713880000067],
            [-80.82855223699994, 35.215852880000057],
            [-80.82839723699999, 35.216001880000078],
            [-80.828255236999951, 35.21615888000008],
            [-80.828129235999938, 35.216320880000069],
            [-80.828052235999962, 35.216408880000074],
            [-80.827563235999946, 35.216972879000025],
            [-80.827536236999947, 35.217004879000058],
            [-80.826623236999978, 35.21808087900007],
            [-80.826497235999966, 35.218229880000024],
            [-80.826396235999937, 35.218374880000056],
            [-80.826224236999963, 35.218619881000052],
            [-80.826177236999968, 35.218686880000064],
            [-80.826114236999956, 35.218756881000047],
            [-80.825842235999971, 35.218945880000035],
            [-80.825395235999963, 35.219293880000066],
            [-80.82522423599994, 35.21940088100007],
            [-80.825022235999938, 35.21950288000005],
            [-80.824930235999943, 35.219540880000068],
            [-80.824710235999987, 35.219444880000026],
            [-80.824428234999971, 35.219343881000043],
            [-80.824291235999965, 35.21929988100004],
            [-80.824088235999966, 35.219234880000045],
            [-80.823795235999967, 35.21915087900004],
            [-80.823742235999987, 35.219135880000067],
            [-80.823449234999941, 35.219072880000056],
            [-80.822968234999962, 35.218969881000078],
            [-80.822459235999986, 35.218892880000055],
            [-80.822298234999948, 35.218870880000054],
            [-80.82104723499998, 35.218703880000078],
            [-80.820755235999968, 35.218664880000063],
            [-80.820233234999989, 35.218595880000066],
            [-80.820742233999965, 35.218782880000049],
            [-80.821121234999964, 35.218921880000039],
            [-80.821942234999938, 35.219194880000032],
            [-80.822248234999961, 35.21932688000004],
            [-80.82221823499998, 35.219605881000064],
            [-80.822029234999945, 35.220444881000049],
            [-80.821955234999962, 35.220766881000031],
            [-80.821845235999945, 35.221223881000071],
            [-80.821709234999958, 35.221821881000039],
            [-80.821693234999941, 35.221873881000079],
            [-80.821658234999973, 35.221938881000028],
            [-80.821224235999978, 35.221816882000041],
            [-80.821084234999944, 35.221800881000036],
            [-80.820921234999958, 35.221776881000039],
            [-80.82054823499999, 35.221699881000063],
            [-80.82013623499995, 35.221619882000027],
            [-80.819546234999962, 35.221509881000031],
            [-80.818554233999976, 35.221327881000036],
            [-80.818449234999946, 35.221745881000061],
            [-80.818378233999965, 35.22208388100006],
            [-80.818350233999979, 35.222274881000033],
            [-80.818333234999955, 35.222362882000027],
            [-80.818268233999959, 35.222584881000046],
            [-80.818228233999946, 35.222694882000042],
            [-80.818156234999947, 35.222865882000065],
            [-80.818120234999981, 35.222936881000066],
            [-80.817999234999945, 35.223124882000036],
            [-80.818708234999974, 35.22364788200008],
            [-80.819796234999956, 35.224448881000058],
            [-80.819839234999961, 35.224476882000033],
            [-80.821902235999971, 35.225798882000049],
            [-80.822797235999985, 35.226372882000078],
            [-80.823696236999979, 35.226948883000034],
            [-80.823780235999948, 35.227015882000046],
            [-80.824696235999966, 35.227748883000061],
            [-80.824843235999936, 35.227872882000042],
            [-80.82486023599995, 35.227886882000064],
            [-80.824607236999952, 35.22807888300008],
            [-80.824583235999967, 35.228092883000045],
            [-80.824553235999986, 35.228109882000069],
            [-80.824483234999946, 35.228130882000073],
            [-80.824397235999982, 35.228171883000073],
            [-80.824308235999979, 35.228219882000076],
            [-80.824157235999962, 35.228316882000058],
            [-80.824083236999968, 35.228349882000032],
            [-80.823924235999982, 35.228406883000048],
            [-80.823887235999962, 35.22842088200008],
            [-80.823851235999939, 35.228442882000024],
            [-80.823707235999962, 35.228569882000045],
            [-80.823629235999988, 35.228643882000028],
            [-80.823613235999971, 35.228665883000076],
            [-80.82358723599998, 35.228701883000042],
            [-80.823552235999955, 35.228797882000038],
            [-80.823552235999955, 35.22882188300008],
            [-80.823559236999984, 35.228862882000044],
            [-80.823584235999988, 35.228907882000044],
            [-80.823598236999942, 35.228998882000042],
            [-80.823683235999965, 35.229302883000059],
            [-80.823710235999954, 35.229393883000057],
            [-80.823715235999941, 35.229412882000076],
            [-80.823739236999984, 35.229494883000029],
            [-80.823736236999935, 35.229518883000026],
            [-80.823731235999958, 35.229539883000029],
            [-80.823721235999983, 35.229568882000024],
            [-80.823703234999982, 35.229602882000052],
            [-80.823673235999934, 35.229640883000059],
            [-80.823571235999964, 35.229733883000051],
            [-80.823522236999963, 35.229776883000056],
            [-80.823473235999984, 35.229816883000069],
            [-80.823401236999985, 35.229857883000079],
            [-80.823312234999946, 35.229898883000033],
            [-80.823268236999979, 35.229909883000062],
            [-80.823193235999952, 35.229913883000052],
            [-80.823146235999957, 35.229899882000041],
            [-80.823103235999952, 35.229878883000026],
            [-80.823051236999959, 35.229835883000078],
            [-80.822939236999957, 35.229698883000026],
            [-80.822919235999962, 35.229682882000077],
            [-80.822894235999968, 35.229664882000066],
            [-80.82284023699998, 35.229637883000066],
            [-80.822787235999954, 35.22962588200005],
            [-80.82275423599998, 35.229628882000043],
            [-80.82272123599995, 35.229627882000045],
            [-80.822655235999946, 35.229636883000069],
            [-80.822607235999953, 35.229650882000044],
            [-80.822583235999957, 35.229660883000065],
            [-80.82253323499998, 35.229689882000059],
            [-80.822471236999945, 35.229749883000068],
            [-80.822422235999966, 35.229817882000077],
            [-80.822377235999966, 35.22989988300003],
            [-80.822365235999939, 35.229936882000061],
            [-80.822356234999972, 35.229971883000076],
            [-80.82234223599994, 35.230039883000074],
            [-80.822332235999966, 35.230088883000064],
            [-80.822310235999964, 35.230199882000079],
            [-80.82229323599995, 35.230291882000074],
            [-80.822290235999958, 35.230312883000067],
            [-80.822292235999953, 35.230383882000069],
            [-80.822362235999947, 35.230868884000074],
            [-80.822362234999957, 35.230925883000054],
            [-80.822355235999964, 35.230973883000047],
            [-80.822334235999961, 35.231034883000063],
            [-80.822278235999988, 35.231146883000065],
            [-80.822266234999972, 35.231188883000073],
            [-80.822261234999985, 35.231248883000035],
            [-80.822272235999947, 35.231317883000031],
            [-80.822321234999947, 35.231412883000075],
            [-80.82233423699995, 35.231427883000038],
            [-80.822470235999958, 35.231552883000063],
            [-80.82260823699994, 35.231666884000049],
            [-80.822724236999989, 35.231769883000027],
            [-80.823020235999934, 35.231986883000047],
            [-80.823045235999984, 35.232020884000065],
            [-80.823168235999958, 35.232185883000056],
            [-80.823182236999969, 35.232204883000065],
            [-80.823199235999937, 35.23226388300003],
            [-80.823204235999981, 35.232436883000048],
            [-80.823189236999951, 35.23251788400006],
            [-80.82317823599999, 35.232555883000032],
            [-80.823160236999968, 35.232619883000041],
            [-80.823121235999963, 35.23271688300008],
            [-80.823041235999938, 35.232870883000032],
            [-80.823010235999959, 35.232938883000031],
            [-80.822974235999936, 35.232984883000029],
            [-80.822915235999972, 35.233045883000045],
            [-80.822880235999946, 35.23308888400004],
            [-80.822849234999978, 35.233106884000051],
            [-80.822806235999963, 35.233127884000055],
            [-80.822784236999951, 35.233145883000077],
            [-80.822728234999943, 35.233207884000024],
            [-80.822680236999986, 35.233249883000042],
            [-80.822365235999939, 35.233485883000071],
            [-80.822208235999938, 35.233609884000032],
            [-80.822073235999937, 35.233709884000064],
            [-80.821955234999962, 35.233800883000072],
            [-80.821904235999966, 35.233840883000028],
            [-80.82182723699998, 35.233887884000069],
            [-80.821582235999983, 35.234055883000053],
            [-80.821507234999956, 35.234098884000048],
            [-80.821239236999986, 35.234257884000044],
            [-80.821102235999945, 35.234328883000046],
            [-80.820831235999947, 35.234437884000045],
            [-80.820711235999966, 35.234509884000033],
            [-80.820481235999978, 35.234667884000032],
            [-80.820418234999977, 35.234715884000025],
            [-80.820139235999989, 35.234998884000049],
            [-80.820207235999987, 35.235051884000029],
            [-80.820423235999954, 35.235209884000028],
            [-80.820613234999939, 35.235340883000049],
            [-80.820800234999979, 35.235468884000056],
            [-80.821207234999974, 35.235756884000068],
            [-80.821687235999946, 35.236096884000062],
            [-80.821797235999952, 35.236164884000061],
            [-80.821916236999982, 35.236223884000026],
            [-80.822071235999942, 35.23628188400005],
            [-80.822152235999965, 35.236304885000038],
            [-80.822284235999973, 35.236335884000027],
            [-80.822532235999972, 35.236382885000069],
            [-80.822762236999949, 35.236408883000024],
            [-80.822903235999945, 35.236414884000055],
            [-80.823018236999985, 35.23641288400006],
            [-80.823187235999967, 35.236393883000062],
            [-80.823345236999955, 35.236364884000068],
            [-80.82358723699997, 35.236311884000031],
            [-80.823754235999957, 35.236259884000049],
            [-80.823834236999971, 35.236227884000073],
            [-80.823911236999948, 35.23619188400005],
            [-80.824109236999959, 35.236082884000041],
            [-80.824203236999949, 35.236022883000032],
            [-80.824335236999957, 35.235929884000029],
            [-80.824582236999959, 35.235751884000024],
            [-80.824886236999987, 35.235520884000039],
            [-80.825132236999934, 35.235329884000066],
            [-80.825887236999961, 35.23474288400007],
            [-80.825968236999984, 35.234681884000054],
            [-80.826137236999955, 35.234555884000031],
            [-80.826164236999944, 35.234573884000042],
            [-80.826412236999943, 35.234735883000042],
            [-80.827339237999979, 35.23539188400008],
            [-80.823796237999943, 35.238048884000079],
            [-80.821196235999935, 35.240076884000075],
            [-80.814695233999942, 35.245148886000038],
            [-80.814195234999943, 35.245448886000077],
            [-80.814126233999957, 35.245508886000039],
            [-80.813395233999984, 35.246148887000061],
            [-80.813360233999958, 35.246166886000026],
            [-80.813667233999979, 35.246575886000073],
            [-80.814069233999987, 35.247110887000076],
            [-80.814390234999962, 35.247551886000053],
            [-80.814610234999975, 35.247813888000053],
            [-80.814799234999953, 35.248003887000039],
            [-80.815005234999944, 35.248182887000041],
            [-80.81549023499997, 35.248555887000066],
            [-80.815816234999943, 35.24881788700003],
            [-80.816078233999974, 35.248599886000079],
            [-80.816850234999947, 35.247986887000025],
            [-80.817224234999969, 35.247688887000038],
            [-80.817293234999966, 35.247632886000076],
            [-80.817909234999945, 35.247146887000042],
            [-80.818016234999959, 35.247056886000053],
            [-80.818816234999986, 35.24634788700007],
            [-80.818914235999955, 35.246253886000034],
            [-80.819018235999977, 35.246163886000033],
            [-80.819568234999963, 35.245656885000074],
            [-80.81989923499998, 35.245355886000027],
            [-80.820311235999952, 35.244976886000075],
            [-80.821287236999979, 35.244098886000074],
            [-80.821809236999968, 35.243612886000051],
            [-80.822318235999944, 35.243960886000025],
            [-80.822916236999959, 35.244395886000063],
            [-80.823335236999981, 35.244697885000051],
            [-80.824016236999967, 35.245202886000072],
            [-80.824408236999943, 35.245491886000025],
            [-80.824725237999985, 35.245735886000034],
            [-80.825115237999967, 35.246019886000056],
            [-80.82542523799998, 35.246248886000046],
            [-80.825919237999983, 35.246607887000039],
            [-80.826294237999946, 35.246887886000025],
            [-80.826420237999969, 35.246962886000063],
            [-80.826488237999968, 35.246994887000028],
            [-80.82663023799995, 35.247047887000065],
            [-80.826745237999944, 35.247081886000046],
            [-80.827254237999966, 35.247231886000066],
            [-80.828036237999981, 35.247466886000041],
            [-80.829105237999954, 35.247774886000059],
            [-80.829505238999957, 35.247899886000027],
            [-80.829191237999964, 35.24831488600006],
            [-80.829276239999956, 35.248482886000033],
            [-80.829409239999961, 35.248713887000065],
            [-80.82949623799999, 35.248848887000065],
            [-80.829694238999934, 35.249139887000069],
            [-80.829889238999954, 35.249451887000077],
            [-80.829947237999988, 35.249512887000037],
            [-80.829984238999941, 35.249541887000078],
            [-80.830066238999962, 35.24958588700008],
            [-80.830110238999964, 35.24960188700004],
            [-80.83015723799997, 35.249613886000077],
            [-80.830326238999987, 35.249642886000061],
            [-80.830354238999973, 35.249644886000056],
            [-80.830457238999941, 35.249648887000035],
            [-80.831263239999942, 35.249614886000074],
            [-80.83187823999998, 35.249588887000073],
            [-80.832599239999979, 35.249563887000079],
            [-80.833033239999963, 35.249541886000031],
            [-80.833641239999963, 35.249522886000079],
            [-80.833888239999965, 35.249509887000045],
            [-80.834822240999983, 35.249480886000072],
            [-80.835847240999954, 35.24942588600004],
            [-80.835847240999954, 35.249406886000031],
            [-80.835837239999989, 35.249034886000061],
            [-80.835829240999942, 35.248915886000077],
            [-80.835828241999934, 35.248679886000048],
            [-80.835877240999935, 35.248211886000036],
            [-80.836005240999953, 35.24719288600005],
            [-80.83610024099994, 35.246739886000057],
            [-80.836351240999988, 35.245470886000078],
            [-80.837209241999972, 35.245723886000064],
            [-80.837793240999986, 35.245902886000067],
            [-80.838533241999983, 35.246133886000052],
            [-80.839216241999964, 35.246349886000075],
            [-80.839978242999962, 35.246581886000058],
            [-80.840575241999943, 35.246769885000049],
            [-80.84182924199996, 35.247150886000043],
            [-80.84269124299999, 35.247418886000048],
            [-80.84343424399998, 35.247652885000036],
            [-80.84405024299997, 35.247846886000048],
            [-80.844202242999984, 35.247900886000025],
            [-80.844417242999953, 35.247971886000073],
            [-80.844670242999939, 35.248035885000036],
            [-80.84488724299996, 35.248073886000043],
            [-80.845107243999962, 35.248093886000049],
            [-80.845218243999966, 35.248097886000039],
            [-80.845707243999982, 35.248080886000025],
            [-80.845924243999946, 35.248070885000061],
            [-80.846018243999936, 35.248066886000061],
            [-80.84615424399999, 35.248069886000053]
          ],
          [
            [-80.854216244999975, 35.223963881000032],
            [-80.854261243999986, 35.22400988000004],
            [-80.854375244999972, 35.224125880000031],
            [-80.854633244999945, 35.224389881000036],
            [-80.854869244999975, 35.224631880000061],
            [-80.855136244999983, 35.224922880000065],
            [-80.855200244999935, 35.224992881000048],
            [-80.855502244999968, 35.225342880000028],
            [-80.855574245999946, 35.22543488000008],
            [-80.855535244999942, 35.225466881000045],
            [-80.854305244999978, 35.226555881000024],
            [-80.852796244999979, 35.227848881000057],
            [-80.851227244999961, 35.229194882000058],
            [-80.850287243999958, 35.22988188100004],
            [-80.849696243999972, 35.230548881000061],
            [-80.849440243999936, 35.230804882000029],
            [-80.848496242999943, 35.231748882000034],
            [-80.848430242999939, 35.231808882000053],
            [-80.847391242999947, 35.232751882000059],
            [-80.846517243999983, 35.233334883000055],
            [-80.846496243999979, 35.233348883000076],
            [-80.846077242999968, 35.233729883000024],
            [-80.84558524299996, 35.234176882000042],
            [-80.845396242999982, 35.234348882000063],
            [-80.844321241999978, 35.235262883000075],
            [-80.843404241999963, 35.236042883000039],
            [-80.84235124199995, 35.237093883000057],
            [-80.841796242999976, 35.237648884000066],
            [-80.840896241999985, 35.237348883000038],
            [-80.840821241999947, 35.237335884000061],
            [-80.840783241999986, 35.237328883000032],
            [-80.840617241999951, 35.237299883000048],
            [-80.840439241999945, 35.237267884000062],
            [-80.839980241999967, 35.236688883000056],
            [-80.839393241999971, 35.236039883000046],
            [-80.839187240999934, 35.235844883000027],
            [-80.838955240999951, 35.235647884000059],
            [-80.838726240999961, 35.235472883000057],
            [-80.838486240999941, 35.235308884000062],
            [-80.838342240999964, 35.235217883000075],
            [-80.838150240999937, 35.235104883000076],
            [-80.837939240999958, 35.234992883000075],
            [-80.837678240999935, 35.234865884000044],
            [-80.836000240999965, 35.233857883000042],
            [-80.835892238999975, 35.233813883000039],
            [-80.835672239999951, 35.233695883000053],
            [-80.835439239999971, 35.233537884000043],
            [-80.834919239999977, 35.23313788300004],
            [-80.834819238999955, 35.233056882000028],
            [-80.834214238999948, 35.232531883000036],
            [-80.833754239999962, 35.232147882000049],
            [-80.833360239999934, 35.231812882000042],
            [-80.832968237999978, 35.231450883000036],
            [-80.832780238999987, 35.231288882000058],
            [-80.831964238999944, 35.230583883000065],
            [-80.831765237999946, 35.23040188300007],
            [-80.831559238999944, 35.230214882000041],
            [-80.831294237999941, 35.229988883000033],
            [-80.831021238999938, 35.229749883000068],
            [-80.830819237999947, 35.22957288200007],
            [-80.830536238999969, 35.229324883000061],
            [-80.830048237999961, 35.228896882000072],
            [-80.829850237999949, 35.228722883000046],
            [-80.829527237999969, 35.228440882000029],
            [-80.829366237999977, 35.228299882000044],
            [-80.829025236999939, 35.227984882000044],
            [-80.828858236999963, 35.227808882000033],
            [-80.828780236999989, 35.227716882000038],
            [-80.828624237999975, 35.227508882000052],
            [-80.82850323699995, 35.227316882000025],
            [-80.82845023699997, 35.227218882000045],
            [-80.82832323699995, 35.226941882000062],
            [-80.828234237999936, 35.226735882000071],
            [-80.828187236999952, 35.226579882000067],
            [-80.828171236999935, 35.226500883000028],
            [-80.828147236999939, 35.226231882000036],
            [-80.828140236999957, 35.225977881000063],
            [-80.828153236999981, 35.225749882000059],
            [-80.828163237999945, 35.225675882000075],
            [-80.828427236999971, 35.225046881000026],
            [-80.828488237999977, 35.224802882000063],
            [-80.828562236999971, 35.224503881000032],
            [-80.82867523699997, 35.224111881000056],
            [-80.828803237999978, 35.223664881000047],
            [-80.828792236999959, 35.223415881000051],
            [-80.828781236999987, 35.223347881000052],
            [-80.828772236999953, 35.22307088000008],
            [-80.828741236999974, 35.222794881000027],
            [-80.828742237999961, 35.222696881000047],
            [-80.828751237999938, 35.222649881000052],
            [-80.828809237999963, 35.222579881000058],
            [-80.828899236999973, 35.222286881000059],
            [-80.828906236999956, 35.22226388100006],
            [-80.829112236999947, 35.222167881000075],
            [-80.829134236999948, 35.222117881000031],
            [-80.829153237999947, 35.222085881000055],
            [-80.82917823799994, 35.222054881000076],
            [-80.82937323699997, 35.22189588100008],
            [-80.829405237999936, 35.221861880000063],
            [-80.829457237999975, 35.221782880000035],
            [-80.829607237999937, 35.221557881000024],
            [-80.829670236999959, 35.221480881000048],
            [-80.829754237999964, 35.221418881000034],
            [-80.830015237999987, 35.22128788100008],
            [-80.83005923799999, 35.221265880000033],
            [-80.830111237999972, 35.221096881000051],
            [-80.830193238999982, 35.221053881000046],
            [-80.830502237999951, 35.220912880000071],
            [-80.830520237999963, 35.220897881000042],
            [-80.830526237999948, 35.220879880000041],
            [-80.83052123799996, 35.220861880000029],
            [-80.830901237999967, 35.22072888100007],
            [-80.831082237999965, 35.220654880000041],
            [-80.831344237999986, 35.220546881000075],
            [-80.83164323699998, 35.220406881000031],
            [-80.831881237999937, 35.220277880000026],
            [-80.832062238999981, 35.22033188000006],
            [-80.832180238999968, 35.220249881000029],
            [-80.832355237999934, 35.220107880000057],
            [-80.832598238999935, 35.219874880000077],
            [-80.832916237999939, 35.219596881000029],
            [-80.833367237999937, 35.219152880000024],
            [-80.833804237999971, 35.218727879000028],
            [-80.834053238999957, 35.218472879000046],
            [-80.834274238999967, 35.218246880000038],
            [-80.834524238999961, 35.218000879000044],
            [-80.834711238999944, 35.217816880000044],
            [-80.834892238999942, 35.21765287900007],
            [-80.835121238999989, 35.217422879000026],
            [-80.835223239999948, 35.217307880000078],
            [-80.835447238999961, 35.217084879000026],
            [-80.835690239999963, 35.216842879000069],
            [-80.836217239999939, 35.216340879000029],
            [-80.836595238999962, 35.216006880000066],
            [-80.836798239999951, 35.215839879000043],
            [-80.837020239999958, 35.215681879000044],
            [-80.837137239999947, 35.215606879000063],
            [-80.837381239999957, 35.215467879000073],
            [-80.837614239999937, 35.215354879000074],
            [-80.837816239999938, 35.215273879000051],
            [-80.837968238999963, 35.215221879000069],
            [-80.838229239999976, 35.21514987900008],
            [-80.838412238999979, 35.21510487900008],
            [-80.838508239999953, 35.215086879000069],
            [-80.83865123999999, 35.215062879000072],
            [-80.838763239999935, 35.215047879000053],
            [-80.838836239999978, 35.215042878000077],
            [-80.838932239999963, 35.215029879000042],
            [-80.839187239999944, 35.215009879000036],
            [-80.83944323999998, 35.215005879000046],
            [-80.839698239999962, 35.215019878000078],
            [-80.839951239999948, 35.215050879000046],
            [-80.840195240999947, 35.215091879000056],
            [-80.840334240999937, 35.215121878000048],
            [-80.840609239999935, 35.215192878000039],
            [-80.840743240999984, 35.215234879000036],
            [-80.841007239999954, 35.215330878000032],
            [-80.841278239999951, 35.215457879000041],
            [-80.841553239999939, 35.215604879000068],
            [-80.841703239999958, 35.215694880000058],
            [-80.841943239999978, 35.215852879000067],
            [-80.842801240999961, 35.216513879000047],
            [-80.843310240999983, 35.216865880000057],
            [-80.844207241999982, 35.217543880000051],
            [-80.845745241999964, 35.21870688000007],
            [-80.846157242999936, 35.219036880000033],
            [-80.846362241999941, 35.219185880000055],
            [-80.846576242999959, 35.219341879000069],
            [-80.846721241999944, 35.219445879000034],
            [-80.846826241999963, 35.219521879000069],
            [-80.848172243999954, 35.220545880000032],
            [-80.848288243999946, 35.220633879000047],
            [-80.84840324299995, 35.220720880000044],
            [-80.848967243999937, 35.221144879000065],
            [-80.849271242999976, 35.221373879000055],
            [-80.84941624399994, 35.221485881000035],
            [-80.849829242999988, 35.221803880000039],
            [-80.850123243999974, 35.222029880000036],
            [-80.850340242999948, 35.22218588000004],
            [-80.850630243999944, 35.222394880000024],
            [-80.850798243999975, 35.222529880000025],
            [-80.851092243999972, 35.222756880000077],
            [-80.851130243999989, 35.222783880000065],
            [-80.851265244999979, 35.22287988000005],
            [-80.851480243999958, 35.22302588000008],
            [-80.851552243999947, 35.223075880000067],
            [-80.851760243999934, 35.223207880000075],
            [-80.852051243999938, 35.223370880000061],
            [-80.852121243999989, 35.223409880000077],
            [-80.852469244999952, 35.223589880000077],
            [-80.852628243999959, 35.223666880000053],
            [-80.852954243999989, 35.223809881000079],
            [-80.853099244999953, 35.223866881000049],
            [-80.853121244999954, 35.223874880000039],
            [-80.853267243999937, 35.223943880000036],
            [-80.853432244999965, 35.224015880000024],
            [-80.853618243999961, 35.223881880000079],
            [-80.853942245999974, 35.223656880000078],
            [-80.854216244999975, 35.223963881000032]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 133,
        "SolutionID": "PI-20",
        "Shape_Length": 0.30682779878025429,
        "Shape_Area": 0.0010521056452945316
      }
    },
    {
      "type": "Feature",
      "id": 134,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.990107118999958, 34.983662227000025],
              [-81.065580601999955, 34.985049088000039],
              [-81.067268052999964, 34.92171988900003],
              [-80.991852994999988, 34.920334096000033],
              [-80.990107118999958, 34.983662227000025]
            ]
          ],
          [
            [
              [-80.936793744999989, 35.170622963000028],
              [-80.99744326199999, 35.172108623000042],
              [-81.000427076999983, 35.089046839000048],
              [-80.939839243999984, 35.087562675000072],
              [-80.936793744999989, 35.170622963000028]
            ]
          ],
          [
            [
              [-80.832849609999982, 35.190478417000065],
              [-80.884352070999967, 35.192530168000076],
              [-80.890895495999985, 35.081094697000026],
              [-80.839463200999944, 35.079045717000042],
              [-80.832849609999982, 35.190478417000065]
            ]
          ],
          [
            [
              [-80.736794979999956, 35.425785992000044],
              [-80.802267785999959, 35.427099170000076],
              [-80.799788386999978, 35.507924607000064],
              [-80.916184380999937, 35.510259619000067],
              [-80.921010783999975, 35.345135237000079],
              [-80.804850946999977, 35.342804882000053],
              [-80.80428836599998, 35.361170630000061],
              [-80.738799599999936, 35.359857114000079],
              [-80.736794979999956, 35.425785992000044]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 134,
        "SolutionID": "PI-21",
        "Shape_Length": 1.5853782793228635,
        "Shape_Area": 0.039099572116792047
      }
    },
    {
      "type": "Feature",
      "id": 135,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.886904289999961, 35.842971010000042],
            [-80.887375288999976, 35.843023010000024],
            [-80.88749828899995, 35.843045011000072],
            [-80.888178288999939, 35.843159011000068],
            [-80.889546289999942, 35.843444010000042],
            [-80.890237290999949, 35.843572011000049],
            [-80.892643290999956, 35.844049010000049],
            [-80.892959290999954, 35.844070010000053],
            [-80.893697289999977, 35.844138011000041],
            [-80.894623291999949, 35.844217010000079],
            [-80.89538429199996, 35.844283010000026],
            [-80.896657291999986, 35.844382010000061],
            [-80.897476291999965, 35.844461010000032],
            [-80.898068292999938, 35.84449101000007],
            [-80.89852029299999, 35.844465011000068],
            [-80.898883292999983, 35.844413010000039],
            [-80.899495292999973, 35.844272010000054],
            [-80.899995292999961, 35.844198010000071],
            [-80.900496292999946, 35.844170010000028],
            [-80.901070292999975, 35.844148010000026],
            [-80.901685293999947, 35.844132010000067],
            [-80.902088292999963, 35.84411201000006],
            [-80.901961292999943, 35.843102010000052],
            [-80.901964293999981, 35.842789010000047],
            [-80.902027293999936, 35.842481009000039],
            [-80.902149293999969, 35.842185010000037],
            [-80.902326293999977, 35.841907009000067],
            [-80.904292293999958, 35.839121009000053],
            [-80.904431294999938, 35.838920009000049],
            [-80.904947293999953, 35.838175008000064],
            [-80.905830293999941, 35.836520008000036],
            [-80.906051293999951, 35.836064008000051],
            [-80.906185293999954, 35.835808008000072],
            [-80.906608294999955, 35.834977008000067],
            [-80.90680529399998, 35.834673008000038],
            [-80.907053293999979, 35.834396008000056],
            [-80.907373294999957, 35.834111008000036],
            [-80.908153294999977, 35.833417007000037],
            [-80.908389294999949, 35.833228008000049],
            [-80.908590294999954, 35.833015007000029],
            [-80.90875329499994, 35.83278100800004],
            [-80.908875294999973, 35.832532008000044],
            [-80.909250295999982, 35.831297007000046],
            [-80.909334294999951, 35.830951007000067],
            [-80.909369294999976, 35.830600007000044],
            [-80.90935329499996, 35.830247007000025],
            [-80.909287294999956, 35.829898007000054],
            [-80.90912829499996, 35.829402007000056],
            [-80.908925293999971, 35.82891700700003],
            [-80.908677294999961, 35.828446006000036],
            [-80.908124293999947, 35.827572007000072],
            [-80.910433294999962, 35.82756600600004],
            [-80.914584295999987, 35.827556006000066],
            [-80.914979296999945, 35.827556006000066],
            [-80.91692829699997, 35.827551006000078],
            [-80.917113297999947, 35.827568006000035],
            [-80.917355296999972, 35.82762600600006],
            [-80.917505296999934, 35.827676006000047],
            [-80.917816296999945, 35.827877006000051],
            [-80.918110296999942, 35.828025006000075],
            [-80.918253296999978, 35.828085007000027],
            [-80.918366297999967, 35.82817000600005],
            [-80.918423296999947, 35.828150005000055],
            [-80.918522297999971, 35.828185006000069],
            [-80.917827297999963, 35.826600006000035],
            [-80.917506296999989, 35.825925005000045],
            [-80.915392296999983, 35.821300005000069],
            [-80.915325296999981, 35.821153005000042],
            [-80.914913294999963, 35.820236005000027],
            [-80.91485729599998, 35.820112005000055],
            [-80.914543295999977, 35.819442005000042],
            [-80.914391295999962, 35.819118004000075],
            [-80.91428329699994, 35.818886004000035],
            [-80.913712295999972, 35.817659004000063],
            [-80.911894294999968, 35.813754003000042],
            [-80.911869294999974, 35.813700003000065],
            [-80.911524293999946, 35.812879003000035],
            [-80.911225293999962, 35.812045003000037],
            [-80.91121829399998, 35.812016003000053],
            [-80.911137294999946, 35.811690002000034],
            [-80.910969294999973, 35.811011003000033],
            [-80.910931294999955, 35.81086500300006],
            [-80.910788294999975, 35.810222003000035],
            [-80.910612294999964, 35.809482003000028],
            [-80.910307294999939, 35.80814900200005],
            [-80.91018629499996, 35.807672001000071],
            [-80.910073294999961, 35.807358003000047],
            [-80.910197292999953, 35.807336002000056],
            [-80.910318293999978, 35.807356002000063],
            [-80.91045629499996, 35.807452002000048],
            [-80.910611293999978, 35.807625002000066],
            [-80.91092129499998, 35.807942003000051],
            [-80.911119293999946, 35.808123002000059],
            [-80.911476294999943, 35.808342002000074],
            [-80.911678294999945, 35.808464002000051],
            [-80.912112294999986, 35.808661002000065],
            [-80.913108294999972, 35.809131002000072],
            [-80.913209294999945, 35.809179002000064],
            [-80.913386294999952, 35.80926300200008],
            [-80.913611294999953, 35.809369002000039],
            [-80.913792294999951, 35.809457002000045],
            [-80.914021294999941, 35.809567002000051],
            [-80.915374295999982, 35.810221003000038],
            [-80.918958296999961, 35.810007002000077],
            [-80.919282296999938, 35.809997002000046],
            [-80.919602296999983, 35.809952002000045],
            [-80.919898296999975, 35.809877002000064],
            [-80.920768296999938, 35.80963500200005],
            [-80.921713297999986, 35.809372002000032],
            [-80.921925297999962, 35.809313002000067],
            [-80.92230529699998, 35.809196002000078],
            [-80.922429296999951, 35.809175001000028],
            [-80.922717297999952, 35.809135001000072],
            [-80.922942297999953, 35.809112002000063],
            [-80.924487296999985, 35.809053002000041],
            [-80.925999298999955, 35.808995002000074],
            [-80.927654299999972, 35.808975002000068],
            [-80.928589299999942, 35.808976002000065],
            [-80.929310299999941, 35.808984002000045],
            [-80.929702299999974, 35.809000001000072],
            [-80.930245299999967, 35.808827001000054],
            [-80.930602299999975, 35.808713001000058],
            [-80.931758300999945, 35.808344002000069],
            [-80.932172299999934, 35.808212001000072],
            [-80.932245300999966, 35.808189001000073],
            [-80.933222300999944, 35.807878002000052],
            [-80.93371130099996, 35.807722001000059],
            [-80.934037301999979, 35.807635001000051],
            [-80.934373300999937, 35.807580001000076],
            [-80.934714300999985, 35.80755600100008],
            [-80.935266301999945, 35.807543001000056],
            [-80.935581301999946, 35.807535001000076],
            [-80.935767300999942, 35.807529001000034],
            [-80.93770230299998, 35.807479001000047],
            [-80.93923330299998, 35.807440001000032],
            [-80.939348302999974, 35.807395001000032],
            [-80.940165302999958, 35.807075001000044],
            [-80.94025830399994, 35.807031001000041],
            [-80.940425302999984, 35.806927001000076],
            [-80.942032303999952, 35.805771001000039],
            [-80.942192303999946, 35.805673001000059],
            [-80.942830303999983, 35.80538400000006],
            [-80.943460304999974, 35.805087000000071],
            [-80.942922302999989, 35.80446100000006],
            [-80.941666303999966, 35.802613000000065],
            [-80.941677302999949, 35.802562999000031],
            [-80.941671302999964, 35.802132999000037],
            [-80.941571302999989, 35.801982999000074],
            [-80.941357302999961, 35.801664000000073],
            [-80.941302302999986, 35.801582000000053],
            [-80.941171302999976, 35.801385000000039],
            [-80.941040302999966, 35.801190000000076],
            [-80.940983303999985, 35.80109199900005],
            [-80.940685301999963, 35.800571000000048],
            [-80.939987302999953, 35.799357999000051],
            [-80.939497302999939, 35.798721999000065],
            [-80.93981230199995, 35.798598999000035],
            [-80.939556301999971, 35.79815300000007],
            [-80.939496302999942, 35.798047999000062],
            [-80.938864301999956, 35.796947998000064],
            [-80.93805230199996, 35.795684999000059],
            [-80.937532300999976, 35.794957998000029],
            [-80.937208301999988, 35.794630999000049],
            [-80.93695330099996, 35.794420998000078],
            [-80.936930300999961, 35.794399998000074],
            [-80.936749300999963, 35.794301998000037],
            [-80.936512301999983, 35.79415799800006],
            [-80.936131300999989, 35.79395899900004],
            [-80.936073300999965, 35.793928998000069],
            [-80.935972299999946, 35.793875998000033],
            [-80.935840299999938, 35.793818998000063],
            [-80.934477299999969, 35.794169999000076],
            [-80.932565299999965, 35.794649999000058],
            [-80.932339299999967, 35.794706998000038],
            [-80.929046299999982, 35.795535999000037],
            [-80.927693298999941, 35.795870999000044],
            [-80.926338296999972, 35.796217999000078],
            [-80.924978296999939, 35.796554998000033],
            [-80.922388296999941, 35.797213000000056],
            [-80.921762296999987, 35.797372000000053],
            [-80.918763295999952, 35.798125000000027],
            [-80.916060295999955, 35.798812000000055],
            [-80.913233293999951, 35.799521000000027],
            [-80.910396293999952, 35.800240000000031],
            [-80.908165292999968, 35.800797001000035],
            [-80.90788629299999, 35.800867000000039],
            [-80.905240291999974, 35.801537001000042],
            [-80.904938292999987, 35.801276001000076],
            [-80.90461929199995, 35.801001001000031],
            [-80.903258291999975, 35.799825001000045],
            [-80.902378290999934, 35.799072000000024],
            [-80.90156129099995, 35.798375000000078],
            [-80.900529289999952, 35.79751600000003],
            [-80.899975289999986, 35.797055000000057],
            [-80.899536289999958, 35.797340000000077],
            [-80.899059289999968, 35.797580000000039],
            [-80.898550289999946, 35.797773001000053],
            [-80.898030288999962, 35.797995000000071],
            [-80.897475290999978, 35.798232000000041],
            [-80.896723289999954, 35.79855400100007],
            [-80.895872289999943, 35.798926000000051],
            [-80.895672288999947, 35.799002001000076],
            [-80.895524289999969, 35.799059001000046],
            [-80.895159288999935, 35.799157001000026],
            [-80.894782289999966, 35.799220001000037],
            [-80.894399288999978, 35.799247000000037],
            [-80.893844288999958, 35.799264002000029],
            [-80.893323287999976, 35.799293001000024],
            [-80.892872287999978, 35.79930900100004],
            [-80.892576287999987, 35.799312001000033],
            [-80.892066287999967, 35.799312001000033],
            [-80.891811287999985, 35.799307001000045],
            [-80.890721286999963, 35.799285000000054],
            [-80.888613287999988, 35.799196001000041],
            [-80.887180285999989, 35.799141001000066],
            [-80.88581828599996, 35.799089002000073],
            [-80.882987283999967, 35.798956001000079],
            [-80.882063284999958, 35.798912001000076],
            [-80.879896283999983, 35.798810001000049],
            [-80.878031283999974, 35.798739001000058],
            [-80.876782282999955, 35.798694002000047],
            [-80.876723283999979, 35.797390002000043],
            [-80.876685283999961, 35.796561001000043],
            [-80.876656282999988, 35.796489001000054],
            [-80.876592282999979, 35.796330001000058],
            [-80.876515282999947, 35.796132001000046],
            [-80.876244282999949, 35.795437001000039],
            [-80.87612728299996, 35.795071001000053],
            [-80.875316282999961, 35.79532400100004],
            [-80.875068283999951, 35.795384001000059],
            [-80.874921282999935, 35.795386000000065],
            [-80.874139282999977, 35.795374001000027],
            [-80.873881281999957, 35.795358001000068],
            [-80.873687281999935, 35.795341001000054],
            [-80.873573282999985, 35.795298000000059],
            [-80.873508281999989, 35.795220001000075],
            [-80.872831280999947, 35.793851000000075],
            [-80.872262280999962, 35.792693000000043],
            [-80.871676281999953, 35.791501000000039],
            [-80.870976280999969, 35.790076000000056],
            [-80.869713280999974, 35.790470999000036],
            [-80.868278280999959, 35.790962000000036],
            [-80.867871279999974, 35.791097000000036],
            [-80.867456280999988, 35.791216000000077],
            [-80.867046279999954, 35.791317000000049],
            [-80.86675527999995, 35.791378000000066],
            [-80.866462279999951, 35.791432000000043],
            [-80.866059279999945, 35.791475001000038],
            [-80.865705279999986, 35.791512000000068],
            [-80.865477278999947, 35.791512000000068],
            [-80.865249279999944, 35.791497000000049],
            [-80.864462278999952, 35.791385001000037],
            [-80.86369327999995, 35.791314000000057],
            [-80.863564278999945, 35.791303001000074],
            [-80.862765278999973, 35.791233000000034],
            [-80.862670277999939, 35.791226000000051],
            [-80.862649278999982, 35.791225001000043],
            [-80.862482277999959, 35.791211000000033],
            [-80.862445278999985, 35.791207001000032],
            [-80.862223278999977, 35.791188000000034],
            [-80.862018278999983, 35.791171001000066],
            [-80.861078277999979, 35.791090001000043],
            [-80.860893277999935, 35.791074001000027],
            [-80.86084227799995, 35.791070001000037],
            [-80.859967278999989, 35.790989001000071],
            [-80.859483277999971, 35.790944000000025],
            [-80.859278276999987, 35.790925000000072],
            [-80.857112276999942, 35.79072300100006],
            [-80.856424277999963, 35.790659000000062],
            [-80.856107276999978, 35.791618001000074],
            [-80.855776277999951, 35.792621001000043],
            [-80.855669276999947, 35.792946001000075],
            [-80.855444276999947, 35.793627001000061],
            [-80.854825276999975, 35.795501002000037],
            [-80.854791276999947, 35.79563500200004],
            [-80.854780276999975, 35.795677001000058],
            [-80.854756276999979, 35.795883002000039],
            [-80.854769276999946, 35.796580001000052],
            [-80.854773276999936, 35.796764002000032],
            [-80.854773276999936, 35.797094002000051],
            [-80.854773275999946, 35.797420002000024],
            [-80.854773276999936, 35.797551001000045],
            [-80.854751275999945, 35.797888002000036],
            [-80.854718275999971, 35.798127002000058],
            [-80.854683276999936, 35.798393002000068],
            [-80.854677276999951, 35.798594002000073],
            [-80.854724277999935, 35.798757003000048],
            [-80.854829275999975, 35.79892600200003],
            [-80.855008276999968, 35.799101003000033],
            [-80.853871276999939, 35.799801002000038],
            [-80.853677275999985, 35.799602002000029],
            [-80.853516276999983, 35.799404002000074],
            [-80.853462275999959, 35.799316003000058],
            [-80.85343527699996, 35.799294002000067],
            [-80.85330827599995, 35.799074002000054],
            [-80.853213276999952, 35.798964003000037],
            [-80.853119276999962, 35.798876002000043],
            [-80.852870276999965, 35.798683002000075],
            [-80.852749276999987, 35.798628002000044],
            [-80.852587275999952, 35.798578003000046],
            [-80.85247927599994, 35.798556002000055],
            [-80.852290276999952, 35.798533002000056],
            [-80.85218227699994, 35.798533002000056],
            [-80.85215627599996, 35.798522003000073],
            [-80.85191227699994, 35.798510002000057],
            [-80.850941275999958, 35.798420003000047],
            [-80.850677274999953, 35.798386002000029],
            [-80.850510274999976, 35.798364003000074],
            [-80.850240275999965, 35.798319003000074],
            [-80.850213275999977, 35.798308002000056],
            [-80.849889275999942, 35.798252002000027],
            [-80.849781275999987, 35.798219003000042],
            [-80.849377275999984, 35.798136002000035],
            [-80.849355274999937, 35.798140002000025],
            [-80.849269274999983, 35.798119002000078],
            [-80.84905327499996, 35.79808500300004],
            [-80.848972274999937, 35.798063002000049],
            [-80.848676274999946, 35.798019002000046],
            [-80.848487275999958, 35.797974002000046],
            [-80.848379274999957, 35.797963002000074],
            [-80.848001273999955, 35.797885002000044],
            [-80.847840274999953, 35.79784100300003],
            [-80.847758273999943, 35.797829002000071],
            [-80.847543274999964, 35.79777400200004],
            [-80.847165273999963, 35.797696002000066],
            [-80.846949274999986, 35.797630002000062],
            [-80.846680273999937, 35.797574002000033],
            [-80.846626273999959, 35.797552002000032],
            [-80.846599273999971, 35.797552002000032],
            [-80.846491274999948, 35.797519003000048],
            [-80.846410273999936, 35.797507003000078],
            [-80.846167273999981, 35.797441002000028],
            [-80.845979273999944, 35.797407002000057],
            [-80.845925273999967, 35.797385003000045],
            [-80.84570927499999, 35.797341002000053],
            [-80.845601274999979, 35.797330002000024],
            [-80.84557427499999, 35.797318002000054],
            [-80.845520274999956, 35.797318002000054],
            [-80.845358273999977, 35.797285002000024],
            [-80.845169273999943, 35.797273003000043],
            [-80.845142273999954, 35.797262002000025],
            [-80.845007273999954, 35.797262002000025],
            [-80.844954273999974, 35.797251002000053],
            [-80.844832273999941, 35.797258002000035],
            [-80.844338273999938, 35.797266002000072],
            [-80.844090273999939, 35.797292003000052],
            [-80.843792273999952, 35.797379002000071],
            [-80.84366227299995, 35.797403002000067],
            [-80.84355627299999, 35.797423002000073],
            [-80.843442272999937, 35.797433002000048],
            [-80.843415273999938, 35.797444003000066],
            [-80.843361272999971, 35.797444002000077],
            [-80.843334272999982, 35.797455002000049],
            [-80.843280273999937, 35.797455002000049],
            [-80.843253272999959, 35.797466003000068],
            [-80.84309127299997, 35.797487003000072],
            [-80.84295627299997, 35.797498002000054],
            [-80.842740273999937, 35.79753000200003],
            [-80.842686273999959, 35.79753000200003],
            [-80.842659272999981, 35.79754100200006],
            [-80.842422272999954, 35.797573003000025],
            [-80.842362273999981, 35.797573002000036],
            [-80.842335272999946, 35.797584003000054],
            [-80.842281272999969, 35.797584003000054],
            [-80.842065272999946, 35.79761600300003],
            [-80.841930272999946, 35.79762700200007],
            [-80.841903271999968, 35.797638003000031],
            [-80.841849273999969, 35.797638002000042],
            [-80.841822272999934, 35.797649002000071],
            [-80.841768272999957, 35.79764900300006],
            [-80.841498272999956, 35.797703003000038],
            [-80.84128227299999, 35.797735003000071],
            [-80.839877271999967, 35.798017002000051],
            [-80.839796271999944, 35.798017002000051],
            [-80.839668272999972, 35.798038002000055],
            [-80.839607271999967, 35.798038003000045],
            [-80.839290271999971, 35.798092003000079],
            [-80.839148271999989, 35.798092003000079],
            [-80.839095271999952, 35.79808100200006],
            [-80.839041271999974, 35.798058003000051],
            [-80.838960272999941, 35.797998002000043],
            [-80.83889327199995, 35.797921003000056],
            [-80.838839271999973, 35.797827002000076],
            [-80.838812271999984, 35.797739003000061],
            [-80.838812272999974, 35.797673003000057],
            [-80.838772271999972, 35.797553002000029],
            [-80.838806272999989, 35.79746500300007],
            [-80.838814271999979, 35.797333002000073],
            [-80.838827271999946, 35.797294003000047],
            [-80.838827272999936, 35.797223003000056],
            [-80.838841271999968, 35.797163002000048],
            [-80.838841271999968, 35.797047002000056],
            [-80.838855271999989, 35.796943003000024],
            [-80.838849271999948, 35.796718002000034],
            [-80.838857271999984, 35.796586003000073],
            [-80.838870272999941, 35.796564002000025],
            [-80.838871271999949, 35.796425002000035],
            [-80.838535271999945, 35.796477002000074],
            [-80.837410271999943, 35.796653002000028],
            [-80.83709627199994, 35.796703003000061],
            [-80.836652271999981, 35.796773002000066],
            [-80.836474270999986, 35.796801002000052],
            [-80.835777270999984, 35.796912002000056],
            [-80.83542227099997, 35.796968003000075],
            [-80.835078270999986, 35.797030003000032],
            [-80.834954270999958, 35.79706800200006],
            [-80.832880270999965, 35.797988003000057],
            [-80.830258269999945, 35.799152003000074],
            [-80.829772268999989, 35.799367003000043],
            [-80.828690269999981, 35.799844004000079],
            [-80.82809526799997, 35.798388003000071],
            [-80.828029268999956, 35.798166003000063],
            [-80.828013268999939, 35.79793800300007],
            [-80.828041268999982, 35.797019003000059],
            [-80.828056268999944, 35.796720002000029],
            [-80.828104269999983, 35.796331003000034],
            [-80.828112268999973, 35.796297003000063],
            [-80.828209267999966, 35.79595000300003],
            [-80.828368268999952, 35.79558200200006],
            [-80.828580267999939, 35.795232002000034],
            [-80.828652268999974, 35.795132002000059],
            [-80.828841268999952, 35.794904003000056],
            [-80.829148268999973, 35.794604002000028],
            [-80.829497268999944, 35.79433600200008],
            [-80.830835268999977, 35.793422002000057],
            [-80.831053268999938, 35.793266002000053],
            [-80.831307268999979, 35.793084001000068],
            [-80.831739269999957, 35.792713001000038],
            [-80.832127269999944, 35.792310002000079],
            [-80.832371269999953, 35.792002002000061],
            [-80.832468269999936, 35.791880002000028],
            [-80.832499269999971, 35.791832002000035],
            [-80.832758269999943, 35.791426001000048],
            [-80.832919269999934, 35.791096001000028],
            [-80.833033270999977, 35.790753001000041],
            [-80.833097269999939, 35.790401001000077],
            [-80.833104269999978, 35.790139001000057],
            [-80.833116270999938, 35.78971800100004],
            [-80.832928268999979, 35.789717001000042],
            [-80.832874269999934, 35.789706002000059],
            [-80.832712269999945, 35.78970600100007],
            [-80.832685269999956, 35.789695001000041],
            [-80.83260426999999, 35.789695001000041],
            [-80.832442268999955, 35.789672001000042],
            [-80.832415269999956, 35.78966100100007],
            [-80.832200269999987, 35.78962800100004],
            [-80.832092268999986, 35.789594001000069],
            [-80.832065269999987, 35.789594001000069],
            [-80.832011269999953, 35.789572001000067],
            [-80.831930269999987, 35.789561001000038],
            [-80.831714268999974, 35.789494001000037],
            [-80.831633268999951, 35.789461001000063],
            [-80.831606268999963, 35.789461001000063],
            [-80.831445269999961, 35.789395001000059],
            [-80.831148268999982, 35.789295001000028],
            [-80.830825268999945, 35.789228001000026],
            [-80.83071726899999, 35.789217001000054],
            [-80.830555268999944, 35.789184001000024],
            [-80.830447269999979, 35.789150001000053],
            [-80.830420269999934, 35.789150001000053],
            [-80.829881268999941, 35.788929000000053],
            [-80.829666268999972, 35.788819001000036],
            [-80.829396267999982, 35.788659001000042],
            [-80.828992268999968, 35.788460002000079],
            [-80.828857268999968, 35.788410001000045],
            [-80.82883026799999, 35.788410001000045],
            [-80.828696267999987, 35.78835500100007],
            [-80.828669267999942, 35.78835500100007],
            [-80.828588268999965, 35.78832200100004],
            [-80.828561268999977, 35.788322002000029],
            [-80.828480267999964, 35.788288001000069],
            [-80.828453267999976, 35.788288001000069],
            [-80.828237268999942, 35.788200001000064],
            [-80.828076267999961, 35.788106001000074],
            [-80.827887267999984, 35.787952002000054],
            [-80.827740267999957, 35.787803000000054],
            [-80.827704267999934, 35.787759000000051],
            [-80.827632268999935, 35.787671001000035],
            [-80.827498267999943, 35.787451001000079],
            [-80.827498268999989, 35.787429001000078],
            [-80.827458268999976, 35.787363001000074],
            [-80.827444267999965, 35.78729700100007],
            [-80.827378267999961, 35.78712100000007],
            [-80.82727026699996, 35.786945001000049],
            [-80.827210267999988, 35.786879001000045],
            [-80.827051267999934, 35.786749000000043],
            [-80.826874267999983, 35.786582001000056],
            [-80.826779267999939, 35.78647200100005],
            [-80.826537267999981, 35.78625100000005],
            [-80.826524267999957, 35.786229001000038],
            [-80.826302267999949, 35.786058001000072],
            [-80.825992267999936, 35.785865000000058],
            [-80.825803267999959, 35.78576600100007],
            [-80.825777267999968, 35.785744001000069],
            [-80.825669267999956, 35.785700000000077],
            [-80.825642266999978, 35.785678001000065],
            [-80.825372267999967, 35.78553400100003],
            [-80.82486126699996, 35.785302000000058],
            [-80.824457267999946, 35.785070000000076],
            [-80.824295266999968, 35.784966001000043],
            [-80.824194266999939, 35.784872001000053],
            [-80.824154266999983, 35.784817000000032],
            [-80.824107267999977, 35.784707001000072],
            [-80.824020265999934, 35.784553000000074],
            [-80.823980266999968, 35.784509000000071],
            [-80.823865266999974, 35.784426000000053],
            [-80.823744265999949, 35.784365001000026],
            [-80.823582266999949, 35.78432100100008],
            [-80.823474266999938, 35.784321000000034],
            [-80.823312265999959, 35.784298000000035],
            [-80.82323226699998, 35.784265001000051],
            [-80.823124266999969, 35.784193000000073],
            [-80.82300326699999, 35.784072001000027],
            [-80.822916266999982, 35.784012000000075],
            [-80.822801266999988, 35.783967000000075],
            [-80.822774265999954, 35.783967000000075],
            [-80.822693265999987, 35.783934001000034],
            [-80.822666266999988, 35.783934001000034],
            [-80.822558265999987, 35.783901000000071],
            [-80.822424265999985, 35.78384600000004],
            [-80.822343266999951, 35.783823000000041],
            [-80.822154266999974, 35.783746000000065],
            [-80.822127265999939, 35.783724000000063],
            [-80.822046265999973, 35.783691000000033],
            [-80.821885266999971, 35.783602001000077],
            [-80.821669266999947, 35.783464000000038],
            [-80.821642265999969, 35.783437001000038],
            [-80.821562265999944, 35.783387000000062],
            [-80.821266265999952, 35.783145000000047],
            [-80.821077265999975, 35.783013001000029],
            [-80.820781264999937, 35.782825000000059],
            [-80.820754266999984, 35.782841001000065],
            [-80.82072726499996, 35.782829999000057],
            [-80.82037726599998, 35.782637000000079],
            [-80.820240265999985, 35.782569000000024],
            [-80.820134264999979, 35.782515999000054],
            [-80.819811264999942, 35.782328000000064],
            [-80.819569264999984, 35.782200999000054],
            [-80.819219264999958, 35.781986001000064],
            [-80.819057265999959, 35.781903000000057],
            [-80.818707264999944, 35.78169900000006],
            [-80.818546264999952, 35.781589000000054],
            [-80.817988263999951, 35.78095600000006],
            [-80.81785326499994, 35.780844999000067],
            [-80.817813264999984, 35.780800999000064],
            [-80.817753264999965, 35.780763000000036],
            [-80.817423264999945, 35.780504000000064],
            [-80.817174263999959, 35.780344000000071],
            [-80.817032263999977, 35.780238000000054],
            [-80.816792264999947, 35.780165000000068],
            [-80.81654726499994, 35.78009000000003],
            [-80.816246264999961, 35.780056000000059],
            [-80.816019263999976, 35.780024999000034],
            [-80.814941264999959, 35.780024000000026],
            [-80.813113262999934, 35.779871000000071],
            [-80.812636262999945, 35.779855000000055],
            [-80.811976262999963, 35.779914000000076],
            [-80.811890262999952, 35.779949000000045],
            [-80.811771262999969, 35.779997999000045],
            [-80.811486262999949, 35.78025400000007],
            [-80.811436262999962, 35.780270999000038],
            [-80.811290262999989, 35.780319000000077],
            [-80.810998262999988, 35.780391000000066],
            [-80.810417262999977, 35.780542000000025],
            [-80.810034261999988, 35.780582000000038],
            [-80.809832262999976, 35.780585000000031],
            [-80.809034262999944, 35.780518000000029],
            [-80.807973261999962, 35.780083000000047],
            [-80.807082261999938, 35.779825999000025],
            [-80.806356260999962, 35.779521000000045],
            [-80.806190260999983, 35.77952300000004],
            [-80.806022260999953, 35.779391001000079],
            [-80.805856261999963, 35.779392999000038],
            [-80.805687260999946, 35.779260000000079],
            [-80.805409260999966, 35.779219000000069],
            [-80.80434926099997, 35.77878400000003],
            [-80.803622259999941, 35.778434000000061],
            [-80.802219259999958, 35.777554000000066],
            [-80.80079625999997, 35.776782000000026],
            [-80.80076325999994, 35.776764000000071],
            [-80.799927259999947, 35.77646100000004],
            [-80.798732258999962, 35.776168999000049],
            [-80.798537258999943, 35.776121000000046],
            [-80.798150258999954, 35.776125999000044],
            [-80.797982257999934, 35.776038999000036],
            [-80.795796256999949, 35.776111000000071],
            [-80.795766257999958, 35.776186000000052],
            [-80.79557325799999, 35.776668000000029],
            [-80.795399257999975, 35.777267000000052],
            [-80.795288256999982, 35.777874999000062],
            [-80.794870258999936, 35.780608000000029],
            [-80.794840257999965, 35.78095200100006],
            [-80.794855257999984, 35.781296001000044],
            [-80.794915258999936, 35.781637001000036],
            [-80.795019257999968, 35.781971000000055],
            [-80.795166258999984, 35.782294001000025],
            [-80.795372257999986, 35.782680001000074],
            [-80.795944257999963, 35.783758001000024],
            [-80.796432258999971, 35.784705001000077],
            [-80.796710259999941, 35.785181002000058],
            [-80.797032258999934, 35.785621002000028],
            [-80.797041258999968, 35.785633002000054],
            [-80.798715258999948, 35.787695003000067],
            [-80.798928259999968, 35.78792300200007],
            [-80.799177259999965, 35.78812600200007],
            [-80.79945725999994, 35.788299002000031],
            [-80.800875260999987, 35.789060002000042],
            [-80.802329260999954, 35.789839002000065],
            [-80.802677261999975, 35.79001700300006],
            [-80.802738259999956, 35.79005800300007],
            [-80.802993260999983, 35.790231002000041],
            [-80.803269260999969, 35.79047900300003],
            [-80.803501260999951, 35.79075600300007],
            [-80.803684260999944, 35.791055002000064],
            [-80.80375326099994, 35.79121000300006],
            [-80.804108260999953, 35.792007003000037],
            [-80.804574260999971, 35.793159004000074],
            [-80.805068261999963, 35.794380003000072],
            [-80.805195261999984, 35.794769003000056],
            [-80.80526826199997, 35.795167003000074],
            [-80.805288261999976, 35.795569003000026],
            [-80.80523526099995, 35.798694004000026],
            [-80.805219262999969, 35.799961004000068],
            [-80.805215260999944, 35.800180005000072],
            [-80.805199261999974, 35.801279004000037],
            [-80.80517826199997, 35.802844005000054],
            [-80.80517426199998, 35.80315300500007],
            [-80.805155261999971, 35.804808005000041],
            [-80.805147262999981, 35.805518005000067],
            [-80.805132262999962, 35.806900006000035],
            [-80.80590626299994, 35.80699200600003],
            [-80.806625262999944, 35.807127005000041],
            [-80.807692262999979, 35.807369006000044],
            [-80.807720262999965, 35.80764500600003],
            [-80.807792262999953, 35.807964006000077],
            [-80.807815262999952, 35.808116006000034],
            [-80.807832262999966, 35.808496006000041],
            [-80.807760263999967, 35.809030006000057],
            [-80.807706263999989, 35.809526006000056],
            [-80.807625262999977, 35.81007500700008],
            [-80.80755626399997, 35.810639006000031],
            [-80.807530263999979, 35.810853006000059],
            [-80.807423262999976, 35.81152400600007],
            [-80.807379262999973, 35.812035007000077],
            [-80.807375263999973, 35.812712007000073],
            [-80.807366263999938, 35.812925007000047],
            [-80.807324263999988, 35.813961008000035],
            [-80.80729026399996, 35.814277007000044],
            [-80.807266263999963, 35.814502007000044],
            [-80.807248262999963, 35.814553008000075],
            [-80.807099263999987, 35.814992008000047],
            [-80.806985263999934, 35.815259007000066],
            [-80.806948263999971, 35.815344007000078],
            [-80.806826262999948, 35.815524008000068],
            [-80.806243263999988, 35.816398008000078],
            [-80.806166262999966, 35.816491008000071],
            [-80.805997262999938, 35.81669800800006],
            [-80.805659262999939, 35.817023008000035],
            [-80.80561626299999, 35.817053008000073],
            [-80.805070262999948, 35.817443008000055],
            [-80.804453262999971, 35.817840008000076],
            [-80.803818262999982, 35.818283008000037],
            [-80.803570262999983, 35.81845000800007],
            [-80.803348261999986, 35.818602008000028],
            [-80.802888262999943, 35.818906008000056],
            [-80.802681261999965, 35.819070009000029],
            [-80.802498262999961, 35.819214009000063],
            [-80.802265262999981, 35.819480008000028],
            [-80.801995262999981, 35.819933009000067],
            [-80.801781261999963, 35.820377009000026],
            [-80.801535261999959, 35.820943009000075],
            [-80.801292262999937, 35.821540009000046],
            [-80.801187262999974, 35.821826009000063],
            [-80.800993261999963, 35.822351009000045],
            [-80.800914262999981, 35.822588010000061],
            [-80.800662261999946, 35.823445010000057],
            [-80.800510261999989, 35.82376701000004],
            [-80.800264260999938, 35.824021009000035],
            [-80.800077261999945, 35.824128010000038],
            [-80.799922261999939, 35.824217010000041],
            [-80.799476261999985, 35.824345010000059],
            [-80.799030261999974, 35.824435010000059],
            [-80.798643261999985, 35.824507010000048],
            [-80.798490260999984, 35.824535010000034],
            [-80.797386260999986, 35.824864010000056],
            [-80.79706926199998, 35.824959009000054],
            [-80.796291260999965, 35.82523701000008],
            [-80.795392260999961, 35.825577009000028],
            [-80.794622260999972, 35.825848010000072],
            [-80.793961258999957, 35.825956010000027],
            [-80.793232260999957, 35.825982011000065],
            [-80.792522259999942, 35.825985010000068],
            [-80.791971259999968, 35.826024010000026],
            [-80.791452258999982, 35.826191011000049],
            [-80.790814258999944, 35.82652801100005],
            [-80.789126258999943, 35.82750401100003],
            [-80.788490258999957, 35.827909012000077],
            [-80.788059258999965, 35.828311011000039],
            [-80.78779125899996, 35.828863011000067],
            [-80.787741257999983, 35.829138012000044],
            [-80.78773425899999, 35.830572011000072],
            [-80.787731258999941, 35.831209011000055],
            [-80.787729257999956, 35.831558012000073],
            [-80.78774225799998, 35.832805012000051],
            [-80.78774625899996, 35.833157012000072],
            [-80.787797258999944, 35.833772012000054],
            [-80.787909259999935, 35.834189012000024],
            [-80.788116258999935, 35.835107013000027],
            [-80.788195258999963, 35.835692013000028],
            [-80.788198258999955, 35.836278013000026],
            [-80.788131258999954, 35.837063013000034],
            [-80.788127258999964, 35.837178013000027],
            [-80.788128258999961, 35.837198012000044],
            [-80.789039258999935, 35.837674013000026],
            [-80.789874258999987, 35.838110013000062],
            [-80.792979260999971, 35.839869013000055],
            [-80.793469261999974, 35.840160013000059],
            [-80.793931260999955, 35.840371013000038],
            [-80.794410260999939, 35.840547014000038],
            [-80.795039260999943, 35.840721014000053],
            [-80.795879261999971, 35.84092101300007],
            [-80.796857261999946, 35.841155013000048],
            [-80.797559261999936, 35.841327014000058],
            [-80.799655262999977, 35.841840013000024],
            [-80.800501262999944, 35.842038013000035],
            [-80.801978263999956, 35.84239901400008],
            [-80.802436263999937, 35.842511014000024],
            [-80.803099263999968, 35.84268501300005],
            [-80.80388226499997, 35.842874013000028],
            [-80.804096264999941, 35.842928014000051],
            [-80.804605263999974, 35.842981013000042],
            [-80.805171264999956, 35.842959013000041],
            [-80.805802264999954, 35.842855013000076],
            [-80.806665264999936, 35.842693014000076],
            [-80.808018265999976, 35.842449013000078],
            [-80.808757265999986, 35.842363014000057],
            [-80.809925265999937, 35.842298013000061],
            [-80.810946266999963, 35.842236013000047],
            [-80.811487266999961, 35.842194013000039],
            [-80.812727265999968, 35.842128013000035],
            [-80.813452266999946, 35.842158013000073],
            [-80.814235267999948, 35.842196013000034],
            [-80.815429267999946, 35.842237013000044],
            [-80.815796266999939, 35.842266012000039],
            [-80.816302267999959, 35.842357013000026],
            [-80.816822267999953, 35.842450013000075],
            [-80.817754267999987, 35.842616013000054],
            [-80.817925268999943, 35.842650013000025],
            [-80.818850267999949, 35.84282401300004],
            [-80.819278267999948, 35.842940013000032],
            [-80.819716268999969, 35.843177014000048],
            [-80.820083268999952, 35.843471013000055],
            [-80.820174268999949, 35.843544013000042],
            [-80.821022269999958, 35.844266013000038],
            [-80.821471269999961, 35.843843013000026],
            [-80.821668269999975, 35.843710013000077],
            [-80.821786269999961, 35.843630013000052],
            [-80.822127269999953, 35.843448013000057],
            [-80.82250326999997, 35.843294013000047],
            [-80.822857269999986, 35.843188013000031],
            [-80.822898269999939, 35.843176013000061],
            [-80.823307269999987, 35.843097013000033],
            [-80.823725269999954, 35.843057013000077],
            [-80.824422270999946, 35.84301901300006],
            [-80.824465269999962, 35.84301501200008],
            [-80.825115270999959, 35.842951012000071],
            [-80.825803270999984, 35.842855013000076],
            [-80.826267270999949, 35.842772013000058],
            [-80.826718270999947, 35.842651013000079],
            [-80.827151270999934, 35.842492012000037],
            [-80.827562271999966, 35.842298013000061],
            [-80.82917127199994, 35.841078011000036],
            [-80.829469271999983, 35.840829012000029],
            [-80.829713271999935, 35.840545013000053],
            [-80.829899271999977, 35.840231012000061],
            [-80.830396271999973, 35.839098012000079],
            [-80.830415270999936, 35.83905001100004],
            [-80.830761270999972, 35.838194011000041],
            [-80.830846271999974, 35.837831011000048],
            [-80.830880271999945, 35.837463012000057],
            [-80.83087427199996, 35.837413011000024],
            [-80.83086227199999, 35.837093011000036],
            [-80.830843271999981, 35.836831011000072],
            [-80.830876271999955, 35.836570011000049],
            [-80.83096027199997, 35.836317011000062],
            [-80.83119727199994, 35.835891010000068],
            [-80.831258271999957, 35.835780010000065],
            [-80.831501272999958, 35.835224011000037],
            [-80.831528270999968, 35.835054011000068],
            [-80.831506271999956, 35.834884011000042],
            [-80.83147427199998, 35.834717011000066],
            [-80.831020271999989, 35.83234501000004],
            [-80.833146271999965, 35.832339011000045],
            [-80.834865272999934, 35.832335010000065],
            [-80.835204273999977, 35.832325010000034],
            [-80.835274272999982, 35.832320011000036],
            [-80.835542272999987, 35.832299010000042],
            [-80.837973274999968, 35.832046010000056],
            [-80.838119272999961, 35.832038010000076],
            [-80.841664274999971, 35.832105010000078],
            [-80.841890275999958, 35.832131010000069],
            [-80.842102274999945, 35.832199010000068],
            [-80.842289274999985, 35.832306009000035],
            [-80.842441275999988, 35.832444010000074],
            [-80.842549275999943, 35.832608010000058],
            [-80.842627274999984, 35.83276901000005],
            [-80.842841274999955, 35.833210010000073],
            [-80.842960275999985, 35.833405010000035],
            [-80.84312027499999, 35.833581010000046],
            [-80.843315275999942, 35.833730010000068],
            [-80.843970275999936, 35.834168010000042],
            [-80.844076276999942, 35.834239010000033],
            [-80.844405275999975, 35.834385010000062],
            [-80.844759275999934, 35.834483010000042],
            [-80.845129276999955, 35.83452901000004],
            [-80.850804278999988, 35.83465601000006],
            [-80.850763278999978, 35.835790011000029],
            [-80.852767278999977, 35.835787010000047],
            [-80.855145278999942, 35.835783010000057],
            [-80.855423278999979, 35.835771011000077],
            [-80.855700279999951, 35.835740010000052],
            [-80.856051279999974, 35.835653010000044],
            [-80.85637627899996, 35.835514011000043],
            [-80.857462279999936, 35.834882010000058],
            [-80.858711279999966, 35.834120010000049],
            [-80.859179279999978, 35.833871009000063],
            [-80.859568279999962, 35.833747009000035],
            [-80.859760279999989, 35.83370000900004],
            [-80.859935280999935, 35.833657010000024],
            [-80.860377280999955, 35.833577010000056],
            [-80.863907281999957, 35.833259009000074],
            [-80.864598280999985, 35.833239009000067],
            [-80.865290281999989, 35.833248009000044],
            [-80.866989282999953, 35.833409009000036],
            [-80.867179281999938, 35.833430010000029],
            [-80.86736928199997, 35.833461009000075],
            [-80.867738282999937, 35.833551009000075],
            [-80.868317283999943, 35.833812010000031],
            [-80.86886528399998, 35.834113010000067],
            [-80.869390282999973, 35.834316009000077],
            [-80.869931282999971, 35.834485009000048],
            [-80.870761283999968, 35.834722009000075],
            [-80.871330283999953, 35.834856009000077],
            [-80.871908284999961, 35.83495700900005],
            [-80.87249428299998, 35.835023009000054],
            [-80.872688284999981, 35.834715009000035],
            [-80.873401283999954, 35.835285008000028],
            [-80.873588283999936, 35.835515009000062],
            [-80.873724283999934, 35.835768010000038],
            [-80.873805283999957, 35.836037009000052],
            [-80.874094284999956, 35.837341010000046],
            [-80.874501285999941, 35.837346010000033],
            [-80.874688284999934, 35.837343009000051],
            [-80.874867285999983, 35.837311010000064],
            [-80.875062285999945, 35.837250010000048],
            [-80.877347285999974, 35.837247010000056],
            [-80.878843285999949, 35.837247009000066],
            [-80.878888286999938, 35.838263010000048],
            [-80.880219286999989, 35.838213010000061],
            [-80.880287286999987, 35.838234009000075],
            [-80.880310286999986, 35.838275009000029],
            [-80.880351286999939, 35.839101010000036],
            [-80.880471286999978, 35.842044011000041],
            [-80.880483287999937, 35.842826010000067],
            [-80.881153286999961, 35.842808011000045],
            [-80.881779286999972, 35.842846011000063],
            [-80.882162287999961, 35.842857010000046],
            [-80.882653288999961, 35.842872010000065],
            [-80.883520287999943, 35.842892010000071],
            [-80.884743287999981, 35.842939010000066],
            [-80.885901288999946, 35.842967010000052],
            [-80.886362287999987, 35.842953010000031],
            [-80.886904289999961, 35.842971010000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 135,
        "SolutionID": "PI-24:A",
        "Shape_Length": 0.49691866838517318,
        "Shape_Area": 0.0055389564186813867
      }
    },
    {
      "type": "Feature",
      "id": 136,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.838707243999977, 35.292984895000075],
            [-80.839487244999987, 35.293009895000068],
            [-80.840108244999954, 35.293029895000075],
            [-80.840792244999989, 35.293052896000063],
            [-80.841973245999952, 35.293087895000042],
            [-80.842607244999954, 35.293108895000046],
            [-80.842797244999986, 35.29312189500007],
            [-80.842931244999988, 35.293140895000079],
            [-80.843025245999968, 35.293166896000059],
            [-80.843079245999945, 35.293187895000074],
            [-80.843276245999959, 35.293295895000028],
            [-80.843457244999968, 35.293418896000048],
            [-80.843650245999982, 35.293568895000078],
            [-80.843759244999944, 35.29366989500005],
            [-80.843851245999986, 35.293781896000041],
            [-80.843907245999958, 35.293871895000052],
            [-80.843976245999954, 35.294006896000042],
            [-80.84400724599999, 35.294077895000044],
            [-80.844337245999952, 35.29482489600008],
            [-80.844510244999981, 35.295216896000056],
            [-80.844669245999967, 35.295576895000067],
            [-80.844712245999972, 35.295698896000033],
            [-80.844733245999976, 35.295780896000053],
            [-80.844752245999985, 35.295954895000079],
            [-80.844770245999939, 35.296510896000029],
            [-80.844771245999937, 35.296824896000032],
            [-80.844775245999983, 35.297190896000075],
            [-80.844770245999939, 35.297853896000049],
            [-80.844762245999959, 35.297964896000053],
            [-80.844634245999941, 35.299852897000051],
            [-80.844616245999987, 35.300286897000035],
            [-80.84461224599994, 35.300507898000035],
            [-80.844576246999964, 35.301290897000058],
            [-80.844584246999943, 35.301851897000063],
            [-80.844596245999981, 35.302172897000048],
            [-80.844639245999986, 35.303385898000045],
            [-80.844728245999988, 35.305966899000055],
            [-80.844756246999964, 35.306736898000054],
            [-80.844781246999958, 35.307156898000073],
            [-80.844833245999951, 35.307820898000045],
            [-80.84520424699997, 35.30776189900007],
            [-80.845684246999951, 35.307698899000059],
            [-80.846841247999976, 35.307546899000045],
            [-80.847599247999938, 35.30744689900007],
            [-80.848016247999965, 35.307391899000038],
            [-80.84847924799999, 35.307330898000032],
            [-80.848670246999973, 35.306500898000024],
            [-80.849202247999983, 35.30416389800007],
            [-80.849458247999962, 35.303117898000039],
            [-80.849606247999986, 35.302511898000034],
            [-80.849711247999949, 35.302037897000048],
            [-80.850058247999982, 35.300472896000031],
            [-80.85020624699996, 35.299827897000057],
            [-80.850289246999978, 35.299484896000024],
            [-80.850323246999949, 35.299262896000073],
            [-80.850406247999956, 35.298886897000045],
            [-80.851042248999988, 35.296123895000051],
            [-80.851208247999978, 35.295363895000037],
            [-80.85130124799997, 35.29486989600008],
            [-80.851359247999937, 35.294467895000025],
            [-80.851404247999938, 35.294064895000076],
            [-80.85143624799997, 35.293618895000066],
            [-80.851462247999962, 35.293026895000025],
            [-80.851460247999967, 35.292502895000041],
            [-80.851450247999935, 35.292168895000032],
            [-80.851436246999981, 35.291991895000024],
            [-80.851400247999948, 35.291530895000051],
            [-80.851372247999961, 35.291281895000054],
            [-80.851318246999938, 35.290872895000064],
            [-80.851274247999982, 35.290583895000054],
            [-80.851201246999949, 35.290156894000063],
            [-80.851132247999942, 35.289861895000058],
            [-80.850943247999965, 35.289134895000075],
            [-80.850848247999977, 35.288817894000033],
            [-80.850744247999955, 35.288505895000071],
            [-80.850586246999967, 35.288027894000038],
            [-80.850450246999969, 35.287687894000044],
            [-80.85015324799997, 35.286990894000041],
            [-80.850395247999984, 35.286894894000056],
            [-80.850669246999985, 35.286819894000075],
            [-80.850784246999979, 35.286768893000044],
            [-80.850842247999935, 35.286746894000032],
            [-80.851041247999945, 35.286706894000076],
            [-80.851083246999963, 35.286656894000032],
            [-80.851668246999964, 35.286845893000077],
            [-80.851854247999938, 35.286912894000068],
            [-80.851943246999951, 35.286952894000024],
            [-80.852088246999983, 35.287026894000064],
            [-80.852238246999946, 35.287118894000059],
            [-80.852380248999964, 35.287228894000066],
            [-80.852507247999938, 35.287349894000045],
            [-80.852833247999968, 35.287730894000049],
            [-80.85293024799995, 35.287851894000028],
            [-80.853213247999975, 35.288194894000071],
            [-80.853272247999939, 35.288256894000028],
            [-80.853377248999948, 35.28835289400007],
            [-80.853496248999988, 35.28844289400007],
            [-80.853603247999956, 35.288517894000051],
            [-80.853792247999934, 35.288633894000043],
            [-80.854372248999937, 35.288975894000032],
            [-80.856100248999951, 35.289970894000078],
            [-80.856500249999954, 35.290185894000047],
            [-80.857074248999936, 35.290466895000066],
            [-80.857456248999938, 35.290644894000025],
            [-80.857612249999988, 35.290694894000069],
            [-80.857755248999979, 35.290262895000069],
            [-80.857882249999989, 35.28985089400004],
            [-80.857942249999951, 35.289600894000046],
            [-80.858191248999958, 35.288370894000025],
            [-80.858293249999974, 35.287976894000053],
            [-80.859515249999959, 35.288032893000036],
            [-80.860124249999956, 35.288065894000056],
            [-80.860490249999941, 35.28809089400005],
            [-80.86063625099996, 35.288108893000071],
            [-80.860709250999946, 35.288124894000077],
            [-80.860837249999975, 35.288164894000033],
            [-80.860897249999937, 35.288190893000035],
            [-80.861009249999938, 35.288254894000033],
            [-80.861911249999935, 35.288834894000047],
            [-80.862063250999938, 35.288920894000057],
            [-80.862303249999968, 35.289046893000034],
            [-80.862809250999987, 35.289274894000073],
            [-80.863100250999935, 35.289400894000039],
            [-80.86327025099996, 35.289461893000066],
            [-80.863358250999966, 35.289483894000057],
            [-80.863437250999937, 35.289496893000035],
            [-80.863527250999937, 35.289503894000063],
            [-80.863623250999979, 35.289502894000066],
            [-80.863815251999938, 35.289490894000039],
            [-80.86497325199997, 35.289376893000053],
            [-80.865390251999941, 35.289335894000033],
            [-80.865598251999984, 35.28932089400007],
            [-80.865717251999968, 35.289317893000032],
            [-80.865889251999988, 35.289325894000058],
            [-80.866005251999979, 35.289338894000025],
            [-80.866106251999952, 35.289354894000041],
            [-80.866790252999976, 35.289494894000029],
            [-80.866806252999936, 35.28942289400004],
            [-80.866809251999939, 35.289305894000051],
            [-80.866812251999988, 35.289209894000066],
            [-80.86678625199994, 35.289121893000072],
            [-80.866763251999942, 35.288965893000068],
            [-80.866763251999942, 35.288718893000066],
            [-80.866767251999988, 35.288677893000056],
            [-80.866798251999967, 35.288570894000031],
            [-80.866805251999949, 35.288493894000055],
            [-80.866802250999967, 35.288357894000058],
            [-80.866789252999979, 35.288271894000047],
            [-80.866794251999977, 35.288226893000058],
            [-80.866870252999945, 35.288042893000068],
            [-80.866850252999939, 35.287907894000057],
            [-80.86656125199994, 35.287564893000024],
            [-80.866593251999973, 35.287297893000073],
            [-80.866390250999984, 35.287169894000044],
            [-80.866475251999987, 35.287102893000053],
            [-80.866644250999968, 35.286905893000039],
            [-80.86674125199994, 35.286818894000078],
            [-80.866806251999947, 35.286727893000034],
            [-80.866812251999988, 35.286659894000024],
            [-80.866881251999985, 35.286627893000059],
            [-80.866934252999954, 35.286578894000058],
            [-80.867057251999938, 35.286537894000048],
            [-80.867113251999967, 35.286479894000024],
            [-80.867179251999971, 35.28647389300005],
            [-80.867274251999959, 35.286378893000062],
            [-80.867307251999989, 35.286353893000069],
            [-80.867468251999981, 35.286284893000072],
            [-80.867712252999979, 35.286065893000057],
            [-80.867756251999936, 35.285998893000055],
            [-80.867809251999972, 35.285935893000044],
            [-80.867938251999988, 35.28572689300006],
            [-80.86802325299999, 35.285621893000041],
            [-80.868083252999952, 35.285538892000034],
            [-80.868201252999938, 35.285377893000032],
            [-80.868231252999976, 35.285327892000055],
            [-80.868239252999956, 35.28528089200006],
            [-80.868231251999987, 35.285169892000056],
            [-80.868199252999943, 35.285113893000073],
            [-80.868173252999952, 35.285042893000025],
            [-80.868127251999965, 35.284971892000044],
            [-80.868054252999968, 35.28493089300008],
            [-80.868010251999976, 35.284879893000038],
            [-80.868010251999976, 35.284820893000074],
            [-80.868019251999954, 35.28479989300007],
            [-80.868045252999934, 35.284740893000048],
            [-80.868048251999937, 35.28461989300007],
            [-80.868095251999989, 35.284483893000072],
            [-80.86812125199998, 35.284411892000037],
            [-80.86816625299997, 35.284331893000058],
            [-80.868168252999965, 35.284284893000063],
            [-80.868176251999955, 35.284235893000073],
            [-80.868274251999935, 35.284051892000036],
            [-80.868342251999934, 35.283988893000071],
            [-80.86839125299997, 35.283903893000058],
            [-80.868412251999985, 35.28382489300003],
            [-80.86839125299997, 35.283724892000066],
            [-80.868371251999974, 35.283630893000065],
            [-80.868409252999982, 35.283563892000075],
            [-80.868482252999968, 35.283354892000034],
            [-80.86855725199996, 35.283289893000074],
            [-80.868671252999945, 35.283238893000032],
            [-80.868891252999958, 35.283153893000076],
            [-80.868875251999953, 35.283089892000078],
            [-80.868862252999975, 35.283076892000054],
            [-80.868776251999975, 35.282985892000056],
            [-80.868716251999956, 35.282874893000042],
            [-80.868731251999975, 35.282794892000027],
            [-80.868649252999944, 35.282762892000051],
            [-80.868647252999949, 35.282761893000043],
            [-80.868524251999986, 35.282700893000026],
            [-80.868450251999946, 35.282701893000024],
            [-80.868406252999989, 35.282690892000062],
            [-80.868344251999986, 35.282622893000052],
            [-80.868311251999955, 35.282548892000079],
            [-80.86831325299994, 35.282512892000057],
            [-80.868346251999981, 35.282472891000054],
            [-80.868372251999972, 35.282422892000056],
            [-80.868352251999966, 35.282356892000053],
            [-80.868168251999975, 35.282238891000077],
            [-80.868117251999934, 35.282186892000027],
            [-80.868194251999967, 35.281925892000061],
            [-80.868128251999963, 35.28189589200008],
            [-80.867874251999979, 35.281611892000058],
            [-80.867663251999943, 35.281218892000027],
            [-80.867632252999954, 35.28119789200008],
            [-80.867595251999944, 35.281157892000067],
            [-80.867585251999969, 35.281096892000051],
            [-80.86761525199995, 35.280964892000043],
            [-80.867623250999941, 35.280895892000046],
            [-80.867628251999975, 35.280735892000052],
            [-80.86757725199999, 35.280514892000042],
            [-80.867587251999964, 35.280304892000061],
            [-80.867556251999986, 35.280213892000063],
            [-80.86757425199994, 35.280017891000057],
            [-80.867726251999954, 35.279691892000073],
            [-80.867764251999972, 35.279647892000071],
            [-80.868045251999945, 35.279432891000056],
            [-80.868171251999968, 35.279328891000034],
            [-80.86821525199997, 35.279277891000049],
            [-80.868248251999944, 35.279174892000071],
            [-80.868324251999979, 35.279010891000041],
            [-80.868335252999941, 35.278930891000073],
            [-80.868316251999943, 35.27889089100006],
            [-80.868200252999941, 35.278826891000051],
            [-80.868168251999975, 35.278780892000043],
            [-80.868176251999955, 35.278583892000029],
            [-80.868195251999964, 35.278507891000061],
            [-80.868183252999984, 35.278365892000068],
            [-80.868025251999939, 35.278070891000027],
            [-80.868020251999951, 35.278037892000043],
            [-80.867978251999943, 35.277950891000046],
            [-80.867921251999974, 35.277890891000027],
            [-80.867784251999979, 35.277796891000037],
            [-80.867752252999935, 35.277753891000032],
            [-80.867715251999982, 35.277692892000061],
            [-80.867714252999974, 35.277646892000064],
            [-80.867684251999947, 35.277579891000073],
            [-80.86757025199995, 35.277389891000041],
            [-80.867482250999956, 35.277195891000076],
            [-80.86742325199998, 35.277121892000025],
            [-80.86734125199996, 35.27699989100006],
            [-80.86733325199998, 35.27693989100004],
            [-80.867322252999941, 35.276704891000065],
            [-80.86734125199996, 35.276664891000053],
            [-80.867359251999972, 35.276601891000041],
            [-80.867335251999975, 35.276541891000079],
            [-80.867263251999987, 35.276449890000038],
            [-80.86706725099998, 35.276171892000036],
            [-80.866996250999989, 35.276060891000043],
            [-80.867572251999945, 35.276118891000067],
            [-80.868473252999934, 35.276220891000037],
            [-80.86895425199998, 35.276286891000041],
            [-80.869127252999988, 35.276309891000039],
            [-80.869927251999968, 35.276388891000067],
            [-80.870179252999947, 35.276404892000073],
            [-80.870324252999978, 35.276417891000051],
            [-80.870472252999946, 35.276425892000077],
            [-80.870767251999951, 35.27642889100008],
            [-80.871060252999939, 35.276414890000069],
            [-80.871208251999974, 35.276397891000045],
            [-80.871385252999971, 35.276368890000072],
            [-80.871491252999988, 35.276344891000065],
            [-80.871590252999965, 35.276317891000076],
            [-80.871786252999982, 35.276250890000028],
            [-80.871947253999963, 35.276184891000071],
            [-80.872062253999957, 35.276131891000034],
            [-80.872284252999975, 35.276014891000045],
            [-80.872444253999959, 35.275911891000078],
            [-80.872618252999985, 35.275772891000031],
            [-80.872806252999965, 35.27559989100007],
            [-80.872873252999966, 35.275528891000079],
            [-80.872994252999945, 35.275380890000065],
            [-80.873142252999969, 35.275165890000039],
            [-80.873272253999971, 35.274941891000026],
            [-80.873329253999941, 35.27482789000004],
            [-80.873450252999987, 35.274620890000051],
            [-80.873583253999982, 35.274354890000041],
            [-80.873636252999972, 35.27424889100007],
            [-80.873799253999948, 35.273985891000052],
            [-80.874582253999961, 35.272583891000068],
            [-80.874620253999979, 35.272480890000054],
            [-80.874810253999954, 35.272153890000027],
            [-80.874970253999948, 35.271876890000044],
            [-80.875126253999952, 35.271627890000048],
            [-80.875433253999972, 35.27109088900005],
            [-80.875477253999975, 35.271002889000044],
            [-80.875672253999937, 35.270618889000048],
            [-80.875834253999983, 35.270326889000046],
            [-80.876123253999936, 35.269811890000028],
            [-80.876207253999951, 35.269652889000042],
            [-80.876313253999967, 35.269449889000043],
            [-80.876892253999983, 35.268405889000064],
            [-80.877022253999939, 35.268184889000054],
            [-80.877097253999978, 35.268080888000043],
            [-80.877175252999962, 35.268011889000036],
            [-80.877266254999938, 35.267960889000051],
            [-80.877406253999936, 35.267915889000051],
            [-80.877735254999948, 35.267830888000049],
            [-80.877535253999952, 35.267659888000026],
            [-80.876206253999953, 35.266053888000044],
            [-80.875988252999946, 35.266229888000055],
            [-80.875919253999939, 35.266191888000037],
            [-80.875897252999948, 35.266173888000026],
            [-80.875500253999974, 35.265610889000072],
            [-80.875449253999989, 35.265554889000043],
            [-80.875356253999939, 35.265478889000065],
            [-80.875572253999962, 35.26528588900004],
            [-80.87544225299996, 35.265134887000045],
            [-80.87529525399998, 35.264963888000068],
            [-80.874862253999936, 35.265366889000063],
            [-80.874514253999962, 35.265656888000024],
            [-80.874109253999961, 35.266010889000029],
            [-80.873833253999976, 35.266240888000027],
            [-80.873495252999987, 35.266499888000055],
            [-80.873274251999987, 35.266655889000049],
            [-80.872899252999957, 35.266901889000053],
            [-80.872675252999954, 35.267032888000074],
            [-80.87246625299997, 35.267144888000075],
            [-80.872261251999987, 35.26724488900004],
            [-80.872056252999982, 35.267351889000054],
            [-80.871798252999952, 35.267476888000033],
            [-80.871417251999958, 35.26764188900006],
            [-80.871036251999953, 35.267785889000038],
            [-80.870848251999973, 35.267850888000055],
            [-80.870682251999938, 35.267902889000027],
            [-80.870455251999942, 35.267973889000075],
            [-80.870229251999945, 35.268039889000079],
            [-80.870035252999969, 35.268090889000064],
            [-80.869967251999981, 35.267909888000077],
            [-80.86989625199999, 35.267722889000026],
            [-80.869833251999978, 35.267643890000045],
            [-80.869821251999952, 35.267605890000027],
            [-80.869812251999974, 35.267535889000044],
            [-80.869777251999949, 35.267446889000041],
            [-80.869760252999981, 35.267350889000056],
            [-80.86975825199994, 35.267275890000064],
            [-80.869780252999988, 35.267167888000074],
            [-80.869788251999978, 35.267019889000039],
            [-80.869783251999934, 35.266987889000063],
            [-80.869693251999934, 35.266783889000067],
            [-80.869669251999937, 35.266544889000045],
            [-80.86966825199994, 35.26641188800005],
            [-80.869658251999965, 35.266369889000032],
            [-80.869642252999938, 35.266327889000024],
            [-80.869631251999976, 35.266278889000034],
            [-80.869571251999957, 35.266187888000047],
            [-80.869555251999941, 35.26613788800006],
            [-80.869541251999976, 35.266049889000044],
            [-80.869486251999945, 35.265975888000071],
            [-80.869451252999966, 35.265916888000049],
            [-80.869434250999973, 35.265870888000052],
            [-80.869418251999946, 35.265759888000048],
            [-80.869381251999982, 35.265687889000048],
            [-80.869380251999985, 35.265593889000058],
            [-80.869371251999951, 35.265549888000066],
            [-80.869348251999952, 35.265502889000061],
            [-80.869326250999961, 35.265419889000043],
            [-80.869326250999961, 35.265374888000053],
            [-80.869318251999971, 35.265336889000025],
            [-80.869303251999952, 35.265309889000036],
            [-80.86928825199999, 35.265295889000072],
            [-80.869209251999962, 35.265201889000025],
            [-80.869112251999979, 35.265103889000045],
            [-80.869070251999972, 35.265036889000044],
            [-80.869037251999941, 35.264963889000057],
            [-80.869006250999973, 35.264942888000064],
            [-80.86897025199994, 35.26490988900008],
            [-80.868849250999972, 35.264853889000051],
            [-80.868796250999935, 35.264833889000045],
            [-80.868773250999936, 35.26483588800005],
            [-80.868721250999954, 35.264724889000036],
            [-80.868695251999952, 35.264319888000045],
            [-80.868715251999959, 35.264266889000055],
            [-80.868793250999943, 35.264115888000049],
            [-80.868824251999968, 35.263999889000047],
            [-80.868842251999979, 35.263932888000056],
            [-80.868852250999964, 35.263642888000049],
            [-80.868829250999966, 35.263558888000034],
            [-80.868817250999939, 35.263335889000075],
            [-80.868756250999979, 35.263098888000059],
            [-80.868694250999965, 35.262963888000058],
            [-80.868569251999986, 35.26274188800005],
            [-80.86850425199998, 35.262668888000064],
            [-80.868451251999943, 35.262533888000064],
            [-80.86837325099998, 35.262097888000028],
            [-80.868319251999935, 35.261962888000028],
            [-80.868250251999939, 35.261847888000034],
            [-80.868169250999983, 35.261569888000054],
            [-80.868046250999953, 35.261186888000054],
            [-80.868049250999945, 35.26104588700008],
            [-80.868050250999943, 35.261011888000041],
            [-80.868119250999939, 35.260678888000029],
            [-80.868163251999988, 35.260541888000034],
            [-80.868208249999952, 35.260394888000064],
            [-80.86821525199997, 35.260313888000042],
            [-80.868319250999946, 35.260008887000026],
            [-80.868348250999986, 35.25978088800008],
            [-80.868420249999986, 35.259674887000074],
            [-80.868453250999949, 35.259465887000033],
            [-80.868476250999947, 35.259316887000068],
            [-80.86850825099998, 35.259232887000053],
            [-80.868501250999941, 35.259134887000073],
            [-80.868493250999961, 35.258844888000056],
            [-80.868461249999939, 35.258785887000045],
            [-80.868409250999946, 35.258713887000056],
            [-80.86827925099999, 35.258645887000057],
            [-80.868079250999983, 35.25844988700004],
            [-80.868035250999981, 35.258418887000062],
            [-80.867906250999965, 35.258374887000059],
            [-80.867821249999963, 35.25835588700005],
            [-80.86772825099996, 35.258321887000079],
            [-80.867651250999984, 35.258277887000077],
            [-80.867500250999967, 35.25822088700005],
            [-80.867235250999954, 35.25817188700006],
            [-80.867029249999973, 35.258108888000038],
            [-80.866937249999978, 35.258062887000051],
            [-80.866817250999986, 35.258003887000029],
            [-80.866569250999987, 35.257948887000055],
            [-80.866259249999985, 35.25789388700008],
            [-80.865749249999965, 35.257768887000054],
            [-80.865650249999987, 35.257734887000026],
            [-80.865357249999988, 35.257610887000055],
            [-80.865140250999957, 35.257414887000039],
            [-80.865095249999968, 35.257306887000027],
            [-80.864995249999936, 35.257140886000059],
            [-80.864982250999958, 35.257099887000038],
            [-80.86496724899996, 35.257053887000041],
            [-80.864919249999957, 35.256784887000038],
            [-80.864965250999944, 35.256598887000052],
            [-80.864980249999974, 35.256488887000046],
            [-80.864973249999935, 35.256304887000056],
            [-80.86496724999995, 35.25617188700005],
            [-80.864965249999955, 35.256082887000048],
            [-80.864981250999961, 35.255884887000036],
            [-80.864997249999988, 35.255659886000046],
            [-80.865011249999952, 35.255436886000041],
            [-80.865032249999956, 35.255123887000025],
            [-80.865037249999943, 35.25472088600003],
            [-80.865072249999969, 35.254517886000031],
            [-80.865078249999954, 35.254220886000041],
            [-80.865069249999976, 35.253919887000052],
            [-80.865080249999949, 35.253700887000036],
            [-80.865095249999968, 35.253416886000025],
            [-80.865157249999982, 35.253073886000038],
            [-80.865256250999948, 35.252735886000039],
            [-80.86243124799995, 35.251880886000038],
            [-80.861236248999944, 35.251518886000042],
            [-80.859896247999984, 35.251112886000044],
            [-80.858236247999969, 35.25041288500006],
            [-80.85721224699995, 35.249820885000076],
            [-80.856508246999965, 35.249355886000046],
            [-80.856477247999976, 35.249634885000035],
            [-80.856421247999947, 35.250138885000069],
            [-80.856320246999985, 35.25099388600006],
            [-80.856288245999963, 35.251323887000069],
            [-80.85627724699998, 35.251412886000026],
            [-80.856207247999976, 35.252041886000029],
            [-80.856123246999971, 35.25280988600008],
            [-80.856099246999975, 35.253030886000033],
            [-80.856035246999966, 35.253668886000071],
            [-80.856011247999959, 35.253852886000061],
            [-80.855990246999966, 35.254017887000032],
            [-80.855873246999977, 35.254969887000073],
            [-80.85571424699998, 35.256165887000066],
            [-80.855603246999976, 35.257036887000027],
            [-80.855533246999983, 35.25761288700005],
            [-80.851164245999939, 35.257280888000025],
            [-80.849219245999961, 35.257130888000063],
            [-80.844800243999941, 35.256745888000069],
            [-80.844682243999955, 35.256810888000075],
            [-80.844417242999953, 35.256956887000058],
            [-80.843851242999961, 35.256908888000055],
            [-80.842000242999973, 35.256871888000035],
            [-80.84176824299999, 35.25686988800004],
            [-80.842172242999936, 35.25594288700006],
            [-80.842950242999962, 35.254226887000073],
            [-80.843135243999939, 35.253804887000058],
            [-80.843718242999955, 35.25252088600007],
            [-80.843752242999983, 35.252443887000027],
            [-80.843179243999941, 35.253172887000062],
            [-80.843106242999966, 35.253262886000073],
            [-80.842909243999941, 35.253056887000071],
            [-80.842890242999943, 35.253045887000042],
            [-80.842868243999987, 35.253035887000067],
            [-80.842837242999963, 35.253032887000074],
            [-80.842708243999937, 35.253039887000057],
            [-80.842584242999976, 35.253001887000039],
            [-80.842524242999957, 35.25297888700004],
            [-80.842464241999949, 35.252922888000057],
            [-80.842417242999943, 35.252776887000039],
            [-80.84235724399997, 35.252730887000041],
            [-80.842316242999971, 35.252706887000045],
            [-80.84209524299996, 35.252671887000076],
            [-80.841976241999987, 35.252582886000027],
            [-80.84196424299995, 35.252522886000065],
            [-80.841942242999949, 35.252505887000041],
            [-80.841726242999982, 35.252431887000057],
            [-80.841689242999962, 35.25243088700006],
            [-80.841651242999944, 35.252441887000032],
            [-80.841542242999935, 35.252509887000031],
            [-80.841513242999952, 35.252515887000072],
            [-80.841386242999988, 35.252484887000037],
            [-80.841271242999937, 35.25243088700006],
            [-80.841107242999954, 35.252354887000024],
            [-80.841025241999944, 35.252328887000033],
            [-80.840976242999943, 35.252304886000047],
            [-80.840896242999975, 35.252234887000043],
            [-80.840765241999975, 35.252153887000077],
            [-80.840735242999983, 35.252119887000049],
            [-80.840737241999989, 35.252077886000052],
            [-80.840746241999966, 35.252026887000056],
            [-80.840904242999954, 35.251842887000066],
            [-80.840912242999934, 35.251817887000072],
            [-80.840912242999934, 35.251799887000061],
            [-80.840880242999958, 35.251731887000062],
            [-80.840861242999949, 35.251709887000061],
            [-80.840819242999942, 35.251696887000037],
            [-80.840728241999955, 35.25169988600004],
            [-80.840577241999938, 35.25173688700005],
            [-80.840539241999977, 35.251742887000034],
            [-80.84051124299998, 35.251735887000052],
            [-80.840284241999939, 35.251610886000037],
            [-80.840202241999975, 35.251569886000027],
            [-80.840011242999935, 35.251404887000035],
            [-80.839948241999934, 35.251377886000057],
            [-80.839798241999972, 35.251291886000047],
            [-80.83960824199994, 35.251191886000072],
            [-80.839568241999984, 35.251164887000073],
            [-80.839530242999956, 35.251145887000064],
            [-80.83941224199998, 35.251117886000031],
            [-80.839334242999939, 35.25108788700004],
            [-80.839271240999949, 35.251063887000043],
            [-80.839197242999944, 35.251012887000059],
            [-80.839146242999959, 35.250957887000027],
            [-80.839083242999948, 35.250900886000068],
            [-80.838688241999989, 35.250760887000069],
            [-80.838459241999942, 35.250722887000052],
            [-80.838398241999982, 35.250726886000052],
            [-80.83826024199999, 35.250779887000078],
            [-80.838234240999952, 35.250810887000057],
            [-80.838214241999935, 35.250844887000028],
            [-80.838207241999953, 35.250883887000043],
            [-80.838193240999942, 35.250902886000063],
            [-80.838158240999974, 35.250923887000056],
            [-80.838116241999955, 35.250933887000031],
            [-80.838111241999968, 35.250967886000069],
            [-80.838059241999986, 35.250979886000039],
            [-80.838017241999978, 35.250947886000063],
            [-80.837993241999982, 35.250786886000071],
            [-80.837964240999952, 35.250747886000056],
            [-80.837849241999947, 35.250714887000072],
            [-80.837624241999947, 35.25067088600008],
            [-80.837565240999936, 35.250641887000029],
            [-80.837534240999958, 35.250613887000043],
            [-80.837438240999973, 35.250445887000069],
            [-80.837375240999961, 35.250219886000025],
            [-80.83735724099995, 35.250086886000076],
            [-80.837355240999955, 35.250032886000042],
            [-80.837347241999964, 35.250006886000051],
            [-80.83733024199995, 35.249991886000032],
            [-80.83729624099999, 35.249977886000067],
            [-80.837069241999984, 35.249979887000052],
            [-80.837000241999988, 35.249976887000059],
            [-80.836825241999975, 35.249969887000077],
            [-80.836493240999971, 35.249861887000066],
            [-80.836440240999934, 35.249836887000072],
            [-80.836357240999973, 35.249764887000026],
            [-80.836235241999987, 35.249620887000049],
            [-80.836173240999983, 35.249530887000049],
            [-80.835993240999983, 35.249530886000059],
            [-80.835851240999943, 35.249538886000039],
            [-80.835869240999955, 35.250261886000033],
            [-80.835898240999938, 35.251096887000074],
            [-80.835909240999968, 35.251327887000059],
            [-80.835934240999961, 35.252153887000077],
            [-80.835966241999984, 35.253233887000079],
            [-80.835975240999971, 35.253517887000044],
            [-80.83599824199996, 35.254170887000043],
            [-80.836014240999987, 35.254684887000053],
            [-80.836017240999979, 35.255018887000062],
            [-80.836028239999962, 35.255357888000049],
            [-80.836031240999944, 35.255731888000071],
            [-80.836058240999989, 35.256368888000054],
            [-80.836077241999988, 35.256569888000058],
            [-80.836113241999954, 35.256884889000048],
            [-80.836151240999982, 35.257066888000054],
            [-80.836165240999946, 35.257132889000047],
            [-80.83564224099996, 35.25727488900003],
            [-80.835225240999989, 35.257382888000052],
            [-80.834986240999967, 35.257455887000049],
            [-80.834550240999988, 35.257463888000075],
            [-80.833150239999952, 35.257486889000063],
            [-80.832710239999983, 35.257490888000063],
            [-80.830825239999967, 35.257526888000029],
            [-80.829041239999981, 35.257557889000054],
            [-80.828213238999979, 35.257568888000037],
            [-80.828094237999949, 35.257554888000072],
            [-80.827989238999976, 35.257526889000076],
            [-80.82787123899999, 35.257475888000045],
            [-80.827816238999958, 35.257444888000066],
            [-80.827764238999976, 35.25740988900003],
            [-80.827193238999939, 35.256962889000079],
            [-80.826423237999961, 35.256360888000074],
            [-80.826317237999945, 35.256279888000051],
            [-80.825728238999943, 35.255793888000028],
            [-80.825577237999937, 35.255679888000031],
            [-80.825542237999969, 35.25565388900003],
            [-80.825369237999951, 35.255560888000048],
            [-80.824601236999968, 35.25519588800006],
            [-80.824251237999988, 35.255017888000054],
            [-80.824152236999964, 35.254965888000072],
            [-80.823278236999954, 35.254477888000054],
            [-80.823098236999954, 35.254382888000066],
            [-80.822443237999948, 35.254015888000026],
            [-80.821951235999961, 35.25375088800007],
            [-80.821747236999954, 35.253641888000061],
            [-80.820744235999939, 35.253133888000036],
            [-80.820698236999988, 35.253104887000063],
            [-80.820616236999967, 35.253038888000049],
            [-80.820580236999945, 35.252998887000047],
            [-80.820322235999981, 35.252680888000043],
            [-80.819446236999966, 35.251645887000052],
            [-80.819399235999981, 35.251599888000044],
            [-80.819359234999979, 35.251555888000041],
            [-80.819225235999966, 35.251390887000071],
            [-80.819101235999938, 35.251262887000053],
            [-80.819068235999964, 35.251239887000054],
            [-80.818978236999953, 35.251194887000054],
            [-80.818796235999969, 35.251048888000071],
            [-80.818523235999976, 35.25084688700008],
            [-80.818018235999943, 35.250461887000029],
            [-80.817826235999974, 35.250647887000071],
            [-80.817725235999944, 35.250860887000044],
            [-80.816908234999971, 35.251338888000078],
            [-80.816900235999981, 35.251355887000045],
            [-80.816885235999962, 35.251371887000062],
            [-80.81670623499997, 35.251475888000073],
            [-80.816385234999984, 35.251682888000062],
            [-80.816044234999936, 35.25187588700004],
            [-80.814760234999937, 35.252586888000053],
            [-80.814574233999963, 35.252683888000035],
            [-80.813609233999955, 35.251501888000064],
            [-80.813116233999949, 35.250908887000037],
            [-80.812976232999972, 35.250721888000044],
            [-80.812722233999978, 35.250834888000043],
            [-80.811786232999964, 35.251219888000037],
            [-80.811703233999935, 35.251257887000065],
            [-80.813732234999975, 35.253674888000035],
            [-80.814041234999934, 35.254043888000069],
            [-80.812714233999941, 35.254667888000029],
            [-80.810940233999986, 35.256222888000025],
            [-80.809988233999945, 35.25734888900007],
            [-80.809322232999989, 35.256752888000051],
            [-80.809298232999936, 35.256626889000074],
            [-80.809286233999956, 35.256589889000054],
            [-80.809283233999963, 35.25653188900003],
            [-80.809296232999941, 35.256448889000069],
            [-80.809328233999963, 35.256370888000049],
            [-80.809346233999975, 35.256300888000055],
            [-80.80936423299994, 35.256255889000045],
            [-80.809368232999987, 35.256132888000025],
            [-80.809411232999935, 35.255846889000054],
            [-80.809390232999988, 35.255646889000047],
            [-80.809371232999979, 35.255370888000073],
            [-80.809377233999953, 35.255175888000053],
            [-80.809395233999965, 35.255109889000039],
            [-80.809396233999962, 35.254971889000046],
            [-80.809405233999939, 35.254811888000063],
            [-80.809411232999935, 35.254674889000057],
            [-80.809356233999949, 35.254613889000041],
            [-80.809368232999987, 35.254484889000025],
            [-80.809516232999954, 35.25431388800007],
            [-80.809563232999949, 35.254225889000054],
            [-80.809605232999957, 35.254127889000074],
            [-80.809603232999962, 35.25407388900004],
            [-80.809586232999948, 35.254017889000067],
            [-80.809386233999987, 35.25379588800007],
            [-80.809348231999934, 35.253665888000057],
            [-80.80934823299998, 35.253577888000052],
            [-80.80945023299995, 35.253498888000024],
            [-80.809503233999976, 35.253472887000044],
            [-80.809692233999954, 35.253405888000032],
            [-80.809734232999972, 35.253386889000069],
            [-80.809812233999935, 35.253342888000077],
            [-80.80986223299999, 35.253279888000066],
            [-80.809865232999982, 35.253256889000056],
            [-80.809841232999986, 35.253181888000029],
            [-80.809832233999941, 35.253130888000044],
            [-80.809835233999934, 35.252996888000041],
            [-80.809884233999981, 35.252810888000056],
            [-80.809887233999973, 35.252697888000057],
            [-80.809883232999937, 35.252639888000033],
            [-80.809878233999939, 35.252480887000047],
            [-80.809884232999934, 35.252389888000039],
            [-80.809854233999943, 35.252150888000074],
            [-80.809818232999987, 35.252086888000065],
            [-80.808134232999976, 35.252817888000038],
            [-80.806454231999965, 35.25354788900006],
            [-80.806222232999971, 35.253657889000067],
            [-80.805931231999978, 35.253780888000051],
            [-80.805632231999937, 35.25388988800006],
            [-80.805328232999955, 35.253986889000032],
            [-80.805212232999963, 35.254019888000073],
            [-80.804808230999981, 35.25411888800005],
            [-80.80448923199998, 35.254179888000067],
            [-80.804275231999952, 35.25421288900003],
            [-80.803904231999979, 35.254276889000039],
            [-80.803190230999974, 35.254400889000067],
            [-80.802004230999955, 35.254605889000061],
            [-80.801055230999964, 35.254818889000035],
            [-80.80076023099997, 35.254908889000035],
            [-80.800487229999987, 35.255014888000062],
            [-80.800187229999949, 35.255154889000039],
            [-80.797969229999978, 35.256567889000053],
            [-80.797614229999965, 35.256772889000047],
            [-80.796552229999975, 35.257453889000033],
            [-80.796364228999948, 35.257592890000069],
            [-80.796147228999985, 35.257737889000055],
            [-80.795921229999976, 35.257872890000044],
            [-80.795687229999942, 35.257998889000078],
            [-80.795444228999941, 35.258113890000061],
            [-80.795195228999944, 35.25821889000008],
            [-80.794940228999963, 35.25831289000007],
            [-80.794768228999942, 35.258348890000036],
            [-80.795164229999955, 35.260706890000051],
            [-80.795179228999984, 35.26089789100007],
            [-80.795163229999957, 35.261404891000041],
            [-80.795147229999941, 35.261872891000053],
            [-80.795123229999945, 35.262356890000035],
            [-80.795083228999943, 35.263037891000067],
            [-80.795061228999941, 35.263513891000059],
            [-80.794990229999939, 35.264926891000073],
            [-80.794946228999947, 35.265762891000065],
            [-80.794896229999949, 35.266730892000055],
            [-80.794874228999959, 35.267168892000029],
            [-80.794834229999935, 35.26747189200006],
            [-80.794786229999943, 35.267692891000024],
            [-80.79466722899997, 35.268247892000034],
            [-80.794326229999967, 35.269861892000051],
            [-80.794087229999946, 35.27099189200004],
            [-80.793788228999972, 35.272438893000071],
            [-80.793525229999943, 35.273675894000064],
            [-80.79349222999997, 35.274038894000057],
            [-80.793491229999972, 35.274128893000068],
            [-80.793487229999982, 35.274525893000032],
            [-80.793495229999962, 35.275346894000052],
            [-80.793511230999968, 35.276558894000061],
            [-80.793511229999979, 35.276915894000069],
            [-80.793527229999938, 35.277978894000057],
            [-80.79352922999999, 35.278731894000032],
            [-80.793530229999988, 35.278933894000033],
            [-80.793531229999985, 35.279478895000068],
            [-80.793540229999962, 35.280777894000039],
            [-80.793546230999937, 35.28105789500006],
            [-80.793552229999989, 35.282143895000047],
            [-80.79358222999997, 35.284347895000053],
            [-80.793606229999966, 35.284676895000075],
            [-80.793613229999949, 35.28478089500004],
            [-80.793662230999985, 35.285056896000071],
            [-80.793731229999935, 35.285330896000062],
            [-80.793796230999988, 35.285532896000063],
            [-80.793849229999978, 35.285670895000067],
            [-80.793971230999944, 35.285944896000046],
            [-80.794056230999956, 35.286117896000064],
            [-80.794322230999967, 35.286661896000055],
            [-80.794510230999947, 35.287037896000072],
            [-80.794722229999934, 35.287514896000062],
            [-80.794809230999988, 35.287708896000026],
            [-80.795913230999986, 35.29046089600007],
            [-80.796380230999944, 35.291642897000031],
            [-80.796585231999984, 35.292145896000079],
            [-80.79680123199995, 35.292762897000046],
            [-80.797156231999963, 35.29366189700005],
            [-80.797560231999967, 35.294716898000047],
            [-80.797782231999975, 35.295235898000044],
            [-80.797880231999955, 35.295453898000062],
            [-80.797989231999964, 35.295644898000035],
            [-80.79809523299997, 35.295821897000053],
            [-80.798322232999965, 35.296145898000077],
            [-80.798511231999953, 35.296419897000078],
            [-80.798686231999966, 35.296675898000046],
            [-80.798764232999986, 35.296779898000068],
            [-80.798892231999957, 35.296939898000062],
            [-80.798945231999937, 35.296913898000071],
            [-80.799011232999987, 35.29688789800008],
            [-80.799091232999956, 35.296854898000049],
            [-80.799053231999949, 35.296806897000067],
            [-80.798936232999949, 35.296651897000061],
            [-80.798777232999953, 35.296425897000063],
            [-80.798720231999937, 35.296337898000047],
            [-80.79865423199999, 35.296226897000054],
            [-80.79860923199999, 35.296135897000056],
            [-80.798585231999937, 35.296069898000042],
            [-80.798578231999954, 35.295994897000071],
            [-80.798591231999978, 35.295910898000045],
            [-80.798602231999951, 35.295877898000072],
            [-80.798630232999983, 35.295839898000054],
            [-80.798685231999968, 35.295801898000036],
            [-80.798763231999942, 35.295767898000065],
            [-80.798851232999937, 35.295738897000035],
            [-80.800308232999953, 35.295325897000055],
            [-80.80021923299995, 35.295112897000024],
            [-80.80018523199999, 35.295002898000064],
            [-80.800150232999954, 35.294835897000041],
            [-80.800127231999966, 35.29467089700006],
            [-80.80012123299997, 35.294529897000075],
            [-80.800142233999964, 35.294341898000027],
            [-80.80016223299998, 35.294227897000042],
            [-80.800159232999988, 35.294132898000043],
            [-80.80014823199997, 35.294060897000065],
            [-80.800131232999945, 35.293988897000077],
            [-80.800062232999949, 35.293842897000047],
            [-80.799991232999957, 35.293748897000057],
            [-80.800572231999979, 35.293490896000037],
            [-80.800803232999954, 35.293393897000044],
            [-80.800893232999954, 35.293354896000039],
            [-80.801039232999983, 35.293305897000039],
            [-80.80114923299999, 35.293280897000045],
            [-80.801277232999951, 35.293260897000039],
            [-80.801553232999936, 35.293238897000037],
            [-80.801804232999984, 35.293210898000041],
            [-80.80198223299999, 35.29319689700003],
            [-80.803008233999947, 35.293154897000079],
            [-80.803857232999974, 35.29313589800006],
            [-80.804604234999942, 35.293107897000027],
            [-80.80508223399994, 35.293069897000066],
            [-80.80510723499998, 35.292979897000066],
            [-80.805273233999969, 35.292778897000062],
            [-80.805865233999953, 35.292433897000024],
            [-80.80581723499995, 35.292381896000052],
            [-80.806126233999976, 35.292203897000036],
            [-80.806137233999948, 35.292215897000062],
            [-80.806639233999988, 35.292739897000047],
            [-80.807695234999983, 35.293815897000059],
            [-80.808090234999952, 35.293555897000033],
            [-80.80827523399995, 35.293425897000077],
            [-80.808383234999951, 35.293332897000028],
            [-80.808527234999985, 35.293187896000063],
            [-80.808783234999964, 35.292902897000033],
            [-80.808931235999978, 35.292725897000025],
            [-80.809113235999973, 35.293121897000049],
            [-80.80950923499995, 35.293936897000037],
            [-80.809574235999946, 35.294061897000063],
            [-80.809604235999984, 35.294101896000029],
            [-80.810329234999983, 35.295052896000072],
            [-80.810565235999945, 35.295403897000028],
            [-80.810679235999942, 35.29555689700004],
            [-80.810998235999989, 35.295984898000029],
            [-80.811415235999959, 35.29654989800008],
            [-80.811476235999976, 35.296612897000045],
            [-80.811426235999988, 35.296685897000032],
            [-80.811387235999973, 35.296773898000026],
            [-80.811333236999985, 35.296965897000064],
            [-80.811292235999986, 35.297203897000031],
            [-80.811266235999938, 35.297512897000047],
            [-80.811262235999948, 35.29773689700005],
            [-80.811277235999967, 35.297873898000034],
            [-80.811300235999965, 35.297946898000077],
            [-80.811336235999988, 35.298025898000049],
            [-80.811428235999983, 35.298144898000032],
            [-80.811547235999967, 35.298084897000024],
            [-80.811699236999971, 35.297997897000073],
            [-80.812005236999937, 35.297806897000044],
            [-80.812363236999943, 35.297590897000077],
            [-80.812575236999976, 35.297455897000077],
            [-80.812728236999988, 35.297348898000052],
            [-80.812840235999943, 35.297262897000053],
            [-80.813025236999977, 35.297098897000069],
            [-80.813350235999962, 35.296757897000077],
            [-80.813540236999984, 35.296546898000031],
            [-80.814588236999953, 35.295408897000073],
            [-80.814841236999939, 35.295131896000044],
            [-80.814842237999983, 35.295131897000033],
            [-80.815505236999968, 35.294407896000052],
            [-80.816797237999936, 35.292993896000041],
            [-80.818259237999939, 35.291372896000041],
            [-80.819421237999961, 35.290067896000039],
            [-80.819785237999952, 35.289653895000072],
            [-80.819954238999969, 35.289466896000079],
            [-80.820091237999975, 35.289562896000064],
            [-80.820182237999973, 35.289642896000032],
            [-80.820255237999959, 35.289710896000031],
            [-80.820288237999989, 35.289744896000059],
            [-80.82032223899995, 35.289811895000071],
            [-80.820466237999938, 35.289992896000058],
            [-80.820521238999959, 35.290038895000066],
            [-80.820640238999943, 35.29013489600004],
            [-80.820656238999959, 35.290156895000052],
            [-80.820738238999979, 35.290213895000079],
            [-80.820823238999935, 35.290301895000027],
            [-80.820899237999981, 35.290404896000041],
            [-80.820926238999959, 35.29046889500006],
            [-80.821007238999982, 35.290565895000043],
            [-80.821072237999942, 35.290600896000058],
            [-80.821127237999974, 35.290643895000073],
            [-80.821177238999951, 35.290722895000044],
            [-80.821215238999969, 35.290820895000024],
            [-80.821296238999935, 35.290869896000061],
            [-80.821313238999949, 35.290986896000049],
            [-80.82131523999999, 35.291006896000056],
            [-80.821291238999947, 35.29117889500003],
            [-80.821276238999985, 35.291231896000056],
            [-80.821247238999945, 35.291386895000073],
            [-80.82119223899997, 35.291578897000079],
            [-80.821183238999936, 35.291633897000054],
            [-80.82119223899997, 35.291739896000024],
            [-80.82120023899995, 35.291768896000065],
            [-80.821258238999974, 35.291886896000051],
            [-80.82128223899997, 35.291925896000066],
            [-80.821338238999942, 35.291996896000057],
            [-80.821405238999944, 35.292021896000051],
            [-80.821888237999985, 35.291381897000065],
            [-80.821987238999952, 35.291318896000064],
            [-80.822103238999944, 35.291253896000057],
            [-80.822413239999946, 35.29110989600008],
            [-80.822642238999947, 35.291015896000033],
            [-80.822913238999945, 35.290904896000029],
            [-80.823361238999951, 35.290703895000036],
            [-80.824732239999946, 35.290088896000043],
            [-80.824957239999947, 35.289992896000058],
            [-80.825413240999978, 35.289798896000036],
            [-80.825929239999937, 35.28958689600006],
            [-80.826196240999934, 35.289480896000043],
            [-80.826889239999957, 35.289158895000071],
            [-80.827601240999968, 35.288836895000031],
            [-80.827716240999962, 35.288770895000027],
            [-80.827798239999936, 35.288733894000075],
            [-80.82812224099996, 35.288613895000026],
            [-80.828284239999959, 35.288571895000075],
            [-80.828428239999937, 35.288543895000032],
            [-80.828596239999968, 35.28852889500007],
            [-80.828805240999941, 35.288527895000072],
            [-80.828916240999945, 35.288521895000031],
            [-80.830113240999935, 35.288534895000055],
            [-80.83076024199994, 35.288548895000076],
            [-80.831239241999981, 35.288565895000033],
            [-80.831374241999981, 35.288576894000073],
            [-80.831566240999962, 35.288600894000069],
            [-80.831758241999978, 35.288641895000069],
            [-80.831852241999968, 35.288668895000058],
            [-80.832039241999951, 35.288733894000075],
            [-80.832363241999985, 35.288879895000036],
            [-80.832495241999936, 35.288947895000035],
            [-80.832649241999945, 35.289034895000043],
            [-80.83275224199997, 35.289099895000049],
            [-80.832814241999984, 35.289145894000058],
            [-80.832875241999943, 35.289198895000027],
            [-80.83321024199995, 35.289551895000045],
            [-80.833283241999936, 35.289614895000057],
            [-80.833391241999948, 35.289690895000035],
            [-80.833510242999978, 35.289747895000062],
            [-80.833633241999962, 35.289786894000031],
            [-80.833872242999973, 35.289840895000054],
            [-80.83399024299996, 35.289850895000029],
            [-80.834105242999954, 35.289843895000047],
            [-80.834183242999984, 35.289828895000028],
            [-80.834314242999938, 35.289788895000072],
            [-80.83518724299995, 35.289437895000049],
            [-80.835446241999989, 35.289323895000052],
            [-80.835778242999936, 35.289176895000026],
            [-80.836322242999984, 35.288950895000028],
            [-80.836474242999941, 35.288895895000053],
            [-80.836548242999982, 35.288877895000041],
            [-80.836658242999988, 35.288877895000041],
            [-80.836720242999945, 35.289095895000059],
            [-80.837017243999981, 35.290042895000056],
            [-80.837087242999985, 35.290238895000073],
            [-80.837187243999949, 35.290499895000039],
            [-80.837371243999939, 35.290950895000037],
            [-80.837646242999938, 35.291582895000033],
            [-80.837845243999936, 35.292037895000078],
            [-80.838223243999948, 35.292899896000051],
            [-80.838256242999989, 35.292988896000054],
            [-80.838361243999941, 35.292980896000074],
            [-80.838707243999977, 35.292984895000075]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 136,
        "SolutionID": "PI-24:D",
        "Shape_Length": 0.33892727997000716,
        "Shape_Area": 0.0028604946759830433
      }
    },
    {
      "type": "Feature",
      "id": 137,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.923646260999988, 35.131746858000042],
            [-80.92366526099994, 35.131771858000036],
            [-80.924902260999943, 35.133981858000027],
            [-80.931495262999988, 35.131622858000071],
            [-80.932444262999979, 35.131290857000067],
            [-80.932472263999955, 35.131293858000049],
            [-80.932512262999978, 35.131288857000072],
            [-80.932590263999941, 35.131271858000048],
            [-80.932688262999989, 35.131239857000025],
            [-80.933197263999944, 35.131054857000038],
            [-80.933495262999941, 35.130947857000024],
            [-80.934107262999987, 35.130735857000047],
            [-80.934245263999969, 35.130684857000062],
            [-80.934758263999981, 35.13049785700008],
            [-80.934945263999964, 35.13042985800007],
            [-80.935633264999979, 35.130168857000058],
            [-80.935803263999958, 35.130105856000057],
            [-80.936413263999953, 35.129883857000038],
            [-80.937749264999979, 35.129412857000034],
            [-80.937826264999956, 35.12937585700007],
            [-80.937934264999967, 35.129332857000065],
            [-80.938047264999966, 35.129265858000053],
            [-80.93824026499999, 35.129136857000049],
            [-80.938437264999948, 35.128976857000055],
            [-80.938458264999952, 35.128986857000029],
            [-80.938760265999974, 35.129092856000057],
            [-80.939293264999947, 35.129201857000055],
            [-80.939647265999952, 35.129274857000041],
            [-80.939998265999975, 35.12933485700006],
            [-80.94060826599997, 35.129416857000024],
            [-80.941744265999944, 35.12962785700006],
            [-80.941930265999986, 35.129193857000075],
            [-80.942172266999989, 35.128588857000068],
            [-80.942343266999956, 35.128195857000037],
            [-80.942471264999938, 35.127913856000077],
            [-80.942620265999949, 35.127514856000062],
            [-80.942767266999965, 35.127151857000058],
            [-80.943077265999989, 35.126445856000032],
            [-80.943170265999981, 35.126245856000025],
            [-80.943266264999977, 35.12605585600005],
            [-80.943364264999957, 35.125877856000045],
            [-80.943596266999975, 35.125491856000053],
            [-80.943850266999959, 35.125120856000024],
            [-80.944025266999972, 35.124891856000033],
            [-80.944220266999935, 35.124654856000063],
            [-80.944494265999936, 35.12434885600004],
            [-80.944825266999942, 35.124016856000026],
            [-80.944976266999959, 35.123873855000056],
            [-80.945041265999976, 35.123817856000073],
            [-80.945163266999941, 35.123711855000067],
            [-80.945478266999942, 35.123457855000026],
            [-80.945644266999977, 35.123328855000068],
            [-80.945986266999967, 35.123088855000049],
            [-80.946281266999961, 35.122896856000068],
            [-80.946764267999981, 35.122618855000042],
            [-80.946926266999981, 35.122529855000039],
            [-80.94732126699995, 35.122328855000035],
            [-80.947857266999961, 35.122068855000066],
            [-80.94850626799996, 35.121766855000033],
            [-80.948673266999947, 35.121685854000077],
            [-80.949250267999957, 35.121406855000032],
            [-80.94962026799999, 35.121225855000034],
            [-80.950544266999941, 35.120783854000024],
            [-80.950823267999965, 35.120646855000075],
            [-80.95128626799999, 35.120405855000058],
            [-80.952752267999983, 35.119688855000049],
            [-80.95326326899999, 35.119445854000048],
            [-80.953227268999967, 35.119372855000051],
            [-80.953120267999964, 35.119225854000035],
            [-80.95290726799999, 35.118662854000036],
            [-80.952889267999979, 35.118594854000037],
            [-80.952872267999965, 35.118475855000042],
            [-80.952867267999977, 35.118288853000024],
            [-80.952861267999936, 35.118098854000039],
            [-80.95286626799998, 35.117837855000062],
            [-80.952861267999936, 35.117612854000072],
            [-80.952878268999939, 35.117066854000029],
            [-80.952869267999972, 35.116877854000052],
            [-80.952854268999943, 35.116700854000044],
            [-80.952873268999952, 35.116501854000035],
            [-80.952856268999938, 35.116276854000034],
            [-80.952831268999944, 35.116134853000062],
            [-80.952786268999944, 35.115989853000031],
            [-80.952776268999969, 35.115943853000033],
            [-80.952759268999955, 35.115873853000039],
            [-80.952756268999963, 35.115821854000046],
            [-80.952763268999945, 35.115739854000026],
            [-80.952749267999934, 35.115575854000042],
            [-80.95267526899994, 35.115384854000069],
            [-80.952648267999962, 35.115200853000033],
            [-80.952558268999951, 35.114809853000054],
            [-80.952473268999938, 35.114357854000048],
            [-80.952465268999958, 35.114211853000029],
            [-80.952441267999973, 35.11413985300004],
            [-80.952398267999968, 35.113974853000059],
            [-80.952298267999936, 35.113422854000078],
            [-80.952215267999975, 35.113079854000034],
            [-80.952093267999942, 35.112430853000035],
            [-80.952031267999985, 35.112161853000032],
            [-80.951995267999962, 35.111963853000077],
            [-80.951957267999944, 35.111791853000057],
            [-80.951946266999983, 35.11168585300004],
            [-80.951951267999959, 35.111549853000042],
            [-80.951973267999961, 35.111365852000063],
            [-80.952051267999934, 35.110973853000075],
            [-80.952073267999936, 35.11090185200004],
            [-80.952097267999989, 35.110824852000064],
            [-80.95211926799999, 35.110715853000045],
            [-80.952163266999946, 35.110322852000024],
            [-80.952185267999937, 35.109984853000071],
            [-80.952185267999937, 35.109842853000032],
            [-80.952211267999985, 35.109572852000042],
            [-80.952211267999985, 35.109527853000031],
            [-80.952182267999945, 35.109444852000024],
            [-80.951550267999949, 35.108461852000062],
            [-80.951509267999938, 35.108378852000044],
            [-80.951491267999984, 35.108314852000035],
            [-80.951479266999968, 35.108225852000032],
            [-80.951475267999967, 35.108107851000057],
            [-80.951494267999976, 35.107957852000027],
            [-80.951665266999953, 35.107175852000069],
            [-80.951732266999954, 35.106962852000038],
            [-80.95177526699996, 35.106840851000072],
            [-80.951837267999963, 35.106716852000034],
            [-80.952035267999975, 35.106383852000079],
            [-80.952215267999975, 35.106174852000038],
            [-80.95229926799999, 35.10607585200006],
            [-80.952327267999976, 35.106054852000057],
            [-80.952363267999942, 35.106041851000043],
            [-80.952477267999939, 35.106020852000029],
            [-80.952552266999987, 35.106010851000065],
            [-80.953068267999981, 35.105859852000037],
            [-80.953219267999941, 35.105800852000073],
            [-80.95331626799998, 35.105757851000078],
            [-80.953456267999968, 35.105659851000041],
            [-80.953516268999977, 35.105595851000032],
            [-80.953563267999982, 35.105507852000073],
            [-80.953621267999949, 35.105271851000055],
            [-80.953680266999982, 35.105100851000032],
            [-80.953761267999937, 35.104918851000036],
            [-80.953082267999946, 35.103786850000063],
            [-80.952868266999985, 35.10345785100003],
            [-80.952714267999966, 35.103239851000069],
            [-80.952525268999977, 35.10299585100006],
            [-80.952234267999984, 35.10266185100005],
            [-80.952018267999961, 35.102427851000073],
            [-80.951870266999947, 35.102280850000056],
            [-80.951715267999987, 35.102135851000071],
            [-80.950802266999972, 35.101351850000071],
            [-80.950462266999978, 35.101134850000051],
            [-80.949863266999955, 35.100751851000041],
            [-80.948636265999937, 35.100182851000056],
            [-80.946956265999972, 35.099516851000033],
            [-80.944754264999972, 35.098637850000046],
            [-80.945889264999948, 35.097113850000028],
            [-80.94737026599995, 35.094969849000051],
            [-80.947842265999952, 35.09429084900006],
            [-80.948050265999939, 35.09402884900004],
            [-80.948354266999957, 35.093730849000053],
            [-80.949216265999951, 35.093108849000032],
            [-80.950998266999989, 35.091882848000068],
            [-80.952573266999934, 35.090821848000076],
            [-80.952831266999965, 35.090652848000047],
            [-80.95301726699995, 35.090529848000074],
            [-80.953537267999934, 35.090113848000044],
            [-80.954129266999985, 35.089464847000045],
            [-80.954215266999938, 35.089354848000028],
            [-80.954139267999949, 35.089294848000065],
            [-80.953979267999955, 35.089239848000034],
            [-80.953778266999961, 35.089162848000058],
            [-80.953618267999957, 35.089086848000079],
            [-80.953457266999976, 35.088954848000071],
            [-80.95315626699994, 35.088728847000027],
            [-80.952835267999944, 35.088453847000039],
            [-80.952615265999952, 35.088322848000075],
            [-80.952434266999944, 35.088278848000073],
            [-80.952146265999943, 35.088261847000069],
            [-80.952013266999984, 35.088212848000069],
            [-80.951799266999956, 35.088080848000061],
            [-80.951611266999976, 35.088052848000075],
            [-80.951437265999971, 35.088074848000076],
            [-80.951237266999954, 35.088173848000054],
            [-80.950956265999935, 35.088360847000047],
            [-80.950689265999983, 35.088580848000049],
            [-80.950495265999962, 35.08875684800006],
            [-80.950180265999961, 35.089086848000079],
            [-80.95002726599995, 35.089185848000056],
            [-80.949639265999963, 35.08940584700008],
            [-80.949532265999949, 35.089498849000051],
            [-80.94937826599994, 35.089729848000047],
            [-80.949237265999955, 35.089762848000078],
            [-80.949090266999974, 35.089823848000037],
            [-80.948903264999956, 35.089834848000066],
            [-80.948776265999982, 35.089784848000079],
            [-80.948689264999985, 35.089707848000046],
            [-80.948535265999965, 35.089515848000076],
            [-80.948341265999943, 35.089411848000054],
            [-80.948268265999957, 35.089284848000034],
            [-80.948274265999942, 35.089158848000068],
            [-80.948415264999937, 35.089009848000046],
            [-80.948542265999947, 35.088844848000065],
            [-80.948555264999982, 35.088663849000056],
            [-80.948535265999965, 35.08832884800006],
            [-80.948441265999975, 35.087998848000041],
            [-80.948354265999967, 35.087734848000025],
            [-80.948214265999979, 35.08745484800005],
            [-80.94808726499997, 35.087272848000055],
            [-80.948020265999958, 35.087201848000063],
            [-80.947719265999979, 35.086651848000031],
            [-80.947585264999987, 35.086541847000035],
            [-80.947398265999936, 35.086453848000076],
            [-80.947197265999989, 35.086321848000068],
            [-80.947117264999974, 35.086228847000029],
            [-80.947037264999949, 35.086030847000075],
            [-80.947037264999949, 35.085887848000027],
            [-80.947057264999955, 35.085766847000059],
            [-80.947137264999981, 35.08553084700003],
            [-80.947264264999944, 35.085216847000027],
            [-80.94734426499997, 35.085084847000076],
            [-80.947505264999961, 35.084997848000057],
            [-80.947652265999977, 35.084975848000056],
            [-80.947873264999942, 35.084975847000067],
            [-80.948133264999967, 35.085013847000027],
            [-80.948976265999988, 35.085007847000043],
            [-80.949264265999943, 35.085040847000073],
            [-80.949651265999989, 35.085117847000049],
            [-80.949765265999986, 35.085145848000025],
            [-80.950093265999953, 35.085106847000077],
            [-80.950307265999982, 35.084991847000026],
            [-80.950434266999935, 35.084980847000054],
            [-80.950574266999979, 35.084991847000026],
            [-80.950688265999986, 35.085029847000044],
            [-80.95078826699995, 35.085002847000055],
            [-80.950755265999987, 35.084815847000073],
            [-80.950695265999968, 35.084716847000038],
            [-80.950614265999945, 35.084358847000033],
            [-80.950394265999989, 35.08407384700007],
            [-80.950360265999961, 35.083974847000036],
            [-80.950273265999954, 35.08380384700007],
            [-80.950180265999961, 35.083699847000048],
            [-80.950046265999958, 35.083639847000029],
            [-80.94971826699998, 35.083606847000055],
            [-80.949604264999948, 35.083600846000024],
            [-80.949504265999963, 35.083556846000079],
            [-80.949310265999941, 35.08334284700004],
            [-80.948608265999951, 35.082798847000049],
            [-80.948474264999959, 35.082660847000056],
            [-80.948274264999952, 35.082248846000027],
            [-80.948233265999988, 35.082089847000077],
            [-80.94826726499997, 35.081781846000069],
            [-80.948292265999953, 35.081699846000049],
            [-80.948314265999954, 35.08162784600006],
            [-80.948360265999952, 35.081544846000043],
            [-80.94854126499996, 35.081429846000049],
            [-80.948822265999979, 35.08115484700005],
            [-80.948949265999943, 35.08108384600007],
            [-80.949049265999975, 35.081033847000072],
            [-80.949123265999958, 35.08097884700004],
            [-80.949156264999942, 35.080830846000026],
            [-80.949143265999965, 35.080720846000077],
            [-80.948935265999978, 35.080346846000054],
            [-80.948955264999938, 35.080209846000059],
            [-80.948902264999958, 35.080038846000036],
            [-80.948761264999973, 35.079851845000064],
            [-80.948741264999967, 35.079741846000047],
            [-80.94878126499998, 35.07945584600003],
            [-80.948835265999946, 35.079295846000036],
            [-80.948842264999939, 35.079274846000033],
            [-80.949015265999947, 35.079115846000036],
            [-80.949236264999968, 35.079032846000075],
            [-80.94992526499999, 35.078994845000068],
            [-80.950165265999942, 35.07894484600007],
            [-80.950600265999981, 35.078867846000037],
            [-80.950754264999944, 35.07853284600003],
            [-80.950894265999978, 35.078202845000078],
            [-80.950968265999961, 35.077955846000066],
            [-80.950981265999985, 35.077647845000058],
            [-80.950968265999961, 35.077471845000048],
            [-80.95093426599999, 35.07737284600006],
            [-80.950734265999984, 35.077245846000039],
            [-80.950466265999978, 35.077119845000027],
            [-80.950279265999939, 35.076965845000075],
            [-80.950078265999935, 35.076861846000043],
            [-80.949951265999971, 35.07682884500008],
            [-80.949343264999982, 35.076833845000067],
            [-80.949115264999989, 35.076800846000026],
            [-80.948881265999944, 35.076811845000066],
            [-80.948768264999956, 35.076800846000026],
            [-80.948641264999935, 35.076734845000033],
            [-80.948534264999978, 35.076635845000055],
            [-80.948413265999989, 35.076482845000044],
            [-80.948233264999942, 35.076025845000061],
            [-80.948095264999949, 35.075640845000066],
            [-80.948052264999944, 35.075520845000028],
            [-80.948019264999971, 35.075415845000066],
            [-80.94800526399996, 35.075217845000054],
            [-80.948059264999983, 35.075091844000042],
            [-80.94822626499996, 35.074953845000039],
            [-80.948305264999988, 35.074934846000076],
            [-80.948460264999937, 35.074898844000074],
            [-80.948968264999962, 35.074953845000039],
            [-80.94914926499996, 35.074953845000039],
            [-80.949476264999987, 35.074766845000056],
            [-80.949583265999934, 35.074635845000046],
            [-80.949637264999978, 35.074552845000028],
            [-80.949717265999936, 35.074404845000061],
            [-80.949770265999973, 35.074321845000043],
            [-80.949750265999967, 35.074030845000038],
            [-80.949726264999981, 35.073956845000055],
            [-80.949596264999968, 35.073552844000062],
            [-80.949583265999934, 35.07340384400004],
            [-80.94961026499999, 35.073288845000036],
            [-80.949703264999982, 35.073007844000074],
            [-80.949697265999987, 35.072793845000035],
            [-80.949695264999946, 35.072767845000044],
            [-80.949690264999958, 35.072700844000053],
            [-80.94966326499997, 35.072535845000061],
            [-80.94966326499997, 35.072364844000049],
            [-80.94979026499999, 35.07210084500008],
            [-80.949797265999962, 35.071908844000063],
            [-80.949696264999943, 35.07168884500004],
            [-80.949543265999978, 35.07149684400008],
            [-80.94938226499994, 35.071309844000041],
            [-80.949322264999978, 35.071166844000061],
            [-80.949262264999959, 35.070798844000024],
            [-80.949195263999968, 35.070717844000058],
            [-80.94872926499994, 35.071338845000071],
            [-80.945504264999954, 35.075655845000028],
            [-80.94433526499995, 35.077262846000053],
            [-80.94401226399998, 35.077705845000025],
            [-80.943672264999975, 35.078112846000067],
            [-80.943050263999964, 35.078883846000053],
            [-80.941912263999939, 35.080391847000044],
            [-80.940955262999978, 35.081690847000061],
            [-80.940404261999959, 35.08246884600004],
            [-80.93980226299999, 35.083316848000038],
            [-80.93833626299994, 35.085304847000032],
            [-80.937341261999961, 35.086628848000032],
            [-80.937166261999948, 35.086857848000079],
            [-80.937153261999981, 35.086873848000039],
            [-80.936255262999964, 35.088041848000046],
            [-80.935204261999957, 35.089504849000036],
            [-80.93420426199998, 35.090785849000042],
            [-80.933658261999938, 35.091616849000047],
            [-80.933445260999974, 35.091952850000041],
            [-80.933302260999938, 35.091748849000055],
            [-80.933233261999987, 35.091639849000046],
            [-80.932838260999972, 35.091063849000079],
            [-80.932719260999988, 35.090878849000035],
            [-80.932360260999985, 35.09028384800007],
            [-80.93232926099995, 35.090199849000044],
            [-80.932316260999983, 35.090135848000045],
            [-80.932152259999953, 35.089563849000058],
            [-80.932127260999948, 35.089361849000056],
            [-80.932161261999966, 35.088843849000057],
            [-80.932697260999987, 35.086810848000027],
            [-80.932805260999942, 35.086439849000044],
            [-80.933080261999976, 35.085498848000043],
            [-80.933466260999978, 35.083949847000042],
            [-80.933318260999954, 35.083984847000067],
            [-80.932874259999949, 35.084088847000032],
            [-80.932211259999974, 35.084674847000031],
            [-80.932080260999953, 35.084860848000062],
            [-80.931821259999936, 35.085228848000042],
            [-80.931395259999988, 35.08581484900003],
            [-80.930919260999985, 35.086453849000065],
            [-80.930510260999938, 35.086866848000057],
            [-80.929866260999972, 35.087260848000028],
            [-80.929317259999948, 35.087468848000071],
            [-80.928486259999943, 35.087658848000046],
            [-80.927365259999988, 35.087901849000048],
            [-80.926378258999989, 35.088109848000045],
            [-80.925839258999986, 35.088227849000077],
            [-80.924282257999948, 35.088566850000063],
            [-80.923502257999985, 35.088732849000053],
            [-80.922782257999984, 35.088890849000052],
            [-80.922616257999948, 35.088941849000037],
            [-80.922527257999946, 35.088968849000025],
            [-80.922217257999989, 35.089006849000043],
            [-80.921139257999982, 35.089240849000078],
            [-80.920511257999976, 35.089371849000031],
            [-80.92013925699996, 35.089418849000026],
            [-80.91957625699996, 35.089481849000038],
            [-80.919535255999961, 35.08948484900003],
            [-80.918368256999941, 35.08958184800008],
            [-80.91995125699998, 35.091241850000074],
            [-80.919920257999934, 35.091386850000049],
            [-80.919684256999972, 35.092130850000046],
            [-80.918915256999981, 35.094588851000026],
            [-80.918572257999983, 35.095665850000046],
            [-80.918521256999952, 35.095826850000037],
            [-80.918443256999979, 35.095794851000051],
            [-80.918206256999952, 35.095664851000038],
            [-80.918096256999945, 35.095624851000025],
            [-80.918010256999935, 35.095607850000079],
            [-80.917831256999989, 35.095552850000047],
            [-80.917594256999962, 35.095456851000051],
            [-80.91717125699995, 35.09529985000006],
            [-80.917103256999951, 35.095260851000035],
            [-80.917059256999949, 35.095251851000057],
            [-80.916943255999968, 35.09520985000006],
            [-80.916748255999948, 35.095176851000076],
            [-80.916610255999956, 35.095187850000059],
            [-80.916390256999989, 35.095189851000043],
            [-80.916261255999984, 35.095181850000074],
            [-80.915993256999968, 35.095173850000037],
            [-80.915840256999957, 35.095190851000041],
            [-80.915703256999961, 35.095235851000041],
            [-80.915671255999939, 35.095253851000052],
            [-80.915562256999976, 35.09529185100007],
            [-80.915473256999974, 35.095311851000076],
            [-80.915365255999973, 35.095326850000049],
            [-80.915162255999974, 35.095343851000052],
            [-80.914899255999956, 35.095383851000065],
            [-80.914706255999988, 35.095405851000066],
            [-80.914516256999946, 35.095415850000052],
            [-80.914321255999937, 35.095432850000066],
            [-80.914190255999983, 35.095474850000073],
            [-80.913944255999979, 35.09549185000003],
            [-80.913821254999959, 35.095511851000026],
            [-80.913620255999945, 35.095534850000035],
            [-80.913575254999955, 35.095547851000049],
            [-80.913446256999976, 35.095607850000079],
            [-80.91338925499997, 35.095616851000045],
            [-80.913327255999945, 35.095634851000057],
            [-80.913241254999946, 35.095641851000039],
            [-80.913185254999973, 35.095636851000052],
            [-80.913109254999938, 35.095602851000024],
            [-80.912877255999945, 35.095458850000057],
            [-80.912619255999971, 35.095361851000064],
            [-80.912585255999943, 35.095341851000057],
            [-80.912423254999965, 35.095185850000064],
            [-80.912360254999953, 35.095131851000076],
            [-80.91228625499997, 35.095091851000063],
            [-80.912199254999962, 35.095053851000046],
            [-80.911954254999955, 35.094946850000042],
            [-80.911918254999989, 35.094938851000052],
            [-80.911867254999947, 35.094939851000049],
            [-80.911755254999946, 35.094964850000054],
            [-80.911681254999962, 35.094966850000048],
            [-80.911630255999967, 35.094943851000039],
            [-80.911599254999942, 35.09491685100005],
            [-80.911521254999968, 35.094861851000076],
            [-80.911485254999945, 35.094822850000071],
            [-80.911335255999973, 35.094561850000048],
            [-80.911283254999944, 35.09445485100008],
            [-80.911171254999942, 35.09429485000004],
            [-80.911101254999949, 35.094229850000033],
            [-80.911071254999968, 35.094209850000027],
            [-80.911042254999984, 35.094201851000037],
            [-80.91098425499996, 35.094198851000044],
            [-80.910905254999989, 35.094215851000058],
            [-80.910878253999954, 35.094209850000027],
            [-80.910840254999982, 35.094182851000028],
            [-80.910686254999973, 35.094035850000068],
            [-80.910610254999938, 35.093970850000062],
            [-80.910486253999977, 35.093905851000045],
            [-80.910361254999941, 35.093803850000029],
            [-80.910267254999951, 35.093748851000043],
            [-80.910203253999953, 35.093657850000056],
            [-80.91013925499999, 35.093610850000061],
            [-80.91005325499998, 35.093571850000046],
            [-80.909973254999954, 35.093540850000068],
            [-80.90991825499998, 35.093534850000026],
            [-80.909853254999973, 35.093549850000045],
            [-80.909802253999942, 35.093550851000032],
            [-80.909735254999987, 35.093533851000075],
            [-80.909568254999954, 35.093507850000037],
            [-80.909494253999981, 35.093477851000046],
            [-80.909090253999977, 35.093281851000029],
            [-80.909024253999974, 35.093233850000047],
            [-80.908990253999946, 35.093194850000032],
            [-80.908894253999961, 35.093031851000035],
            [-80.90877025399999, 35.092859850000025],
            [-80.908745253999939, 35.092837850000024],
            [-80.908727254999974, 35.092798849000076],
            [-80.90871825399995, 35.092727850000074],
            [-80.908707254999968, 35.092684850000069],
            [-80.908666253999968, 35.092580850000047],
            [-80.908648254999946, 35.092554850000056],
            [-80.908605253999951, 35.092520850000028],
            [-80.908553253999969, 35.092501850000076],
            [-80.908180254999934, 35.092338850000033],
            [-80.907986253999979, 35.092189850000068],
            [-80.907883253999955, 35.091987850000066],
            [-80.90784025399995, 35.091920851000054],
            [-80.90780325299994, 35.091807849000077],
            [-80.90779925399994, 35.091701850000049],
            [-80.90776525299998, 35.091646850000075],
            [-80.907651253999973, 35.091541850000056],
            [-80.907599252999944, 35.091465850000077],
            [-80.907584253999971, 35.091396849000034],
            [-80.907594253999946, 35.091354850000073],
            [-80.907545252999967, 35.091306850000024],
            [-80.907514253999977, 35.091288850000069],
            [-80.907478253999955, 35.09127385000005],
            [-80.907432253999957, 35.091240850000077],
            [-80.907360253999968, 35.091140850000045],
            [-80.907324252999956, 35.091109850000066],
            [-80.907274252999969, 35.091088850000062],
            [-80.907207252999967, 35.091079850000028],
            [-80.907080252999947, 35.091072850000046],
            [-80.907035253999936, 35.091065850000064],
            [-80.906986253999946, 35.091037850000077],
            [-80.906944252999949, 35.091001851000044],
            [-80.906914253999958, 35.090930850000063],
            [-80.906899253999939, 35.090850850000038],
            [-80.906903252999939, 35.090732849000062],
            [-80.906904253999983, 35.090714849000051],
            [-80.906894252999962, 35.090677849000031],
            [-80.903763252999966, 35.091065849000074],
            [-80.903746251999962, 35.091118850000043],
            [-80.903730252999935, 35.091153850000069],
            [-80.903658251999957, 35.091263850000075],
            [-80.903353251999988, 35.091686851000077],
            [-80.903161251999961, 35.091972850000047],
            [-80.903097252999942, 35.092091850000031],
            [-80.90300825199995, 35.092332850000048],
            [-80.90294725199999, 35.092472850000036],
            [-80.902867252999954, 35.092600850000053],
            [-80.902779251999959, 35.092693851000035],
            [-80.902682252999966, 35.092770850000079],
            [-80.902597251999964, 35.092817851000063],
            [-80.902671252999937, 35.092923850000034],
            [-80.902873252999939, 35.093213850000041],
            [-80.902975251999976, 35.093365850000055],
            [-80.90372425299995, 35.094484851000061],
            [-80.903940252999973, 35.094792851000079],
            [-80.904303251999977, 35.095311851000076],
            [-80.904685252999968, 35.095884851000051],
            [-80.905331253999975, 35.096884851000027],
            [-80.905618252999943, 35.097337852000067],
            [-80.905655252999964, 35.097402851000027],
            [-80.905682253999942, 35.097450851000076],
            [-80.905718253999964, 35.097525851000057],
            [-80.905812252999965, 35.097756851000042],
            [-80.90594525299997, 35.098168851000025],
            [-80.906283253999959, 35.099271852000072],
            [-80.906498253999985, 35.099916853000025],
            [-80.90681125399999, 35.100737851000076],
            [-80.907028253999954, 35.101366852000069],
            [-80.907043254999962, 35.101410853000061],
            [-80.907717253999976, 35.103434853000067],
            [-80.907858254999951, 35.103889853000055],
            [-80.90793025399995, 35.104174852000028],
            [-80.907961254999975, 35.104330853000079],
            [-80.907984254999974, 35.104480852000052],
            [-80.908015254999953, 35.104793852000057],
            [-80.908050253999988, 35.105922853000038],
            [-80.908058254999958, 35.106189853000046],
            [-80.908067253999945, 35.106464853000034],
            [-80.908103254999958, 35.107278853000025],
            [-80.908133254999939, 35.107729853000023],
            [-80.90814725499996, 35.107983854000054],
            [-80.908168254999964, 35.10838285300008],
            [-80.908183254999983, 35.108478854000055],
            [-80.908189254999968, 35.108520853000073],
            [-80.908217254999954, 35.108616853000058],
            [-80.908226254999988, 35.108633854000061],
            [-80.908362254999986, 35.108689854000033],
            [-80.908706255999959, 35.108770854000056],
            [-80.909149254999988, 35.108875853000029],
            [-80.909779255999979, 35.109024853000051],
            [-80.910788255999989, 35.109262854000065],
            [-80.911763255999972, 35.109512854000059],
            [-80.911856255999965, 35.109205853000049],
            [-80.912494255999945, 35.109222853000063],
            [-80.912874256999942, 35.109174854000059],
            [-80.913349256999936, 35.109094854000034],
            [-80.913825256999985, 35.109059854000066],
            [-80.915754256999946, 35.109010853000029],
            [-80.915883256999962, 35.109013854000068],
            [-80.91600425699994, 35.109603854000056],
            [-80.916035257999965, 35.109822854000072],
            [-80.916049256999941, 35.110027853000076],
            [-80.916029256999934, 35.110250854000071],
            [-80.915982257999985, 35.110437854000054],
            [-80.915907256999958, 35.110632854000073],
            [-80.915704256999959, 35.110973854000065],
            [-80.915516256999979, 35.111223855000048],
            [-80.915482256999951, 35.111256854000032],
            [-80.915296256999966, 35.111454854000044],
            [-80.915076256999953, 35.111700854000048],
            [-80.914190255999983, 35.112602854000045],
            [-80.913746256999957, 35.113066854000067],
            [-80.91359825699999, 35.113220855000066],
            [-80.913271256999963, 35.113542855000048],
            [-80.913176255999986, 35.113625855000066],
            [-80.913098256999945, 35.113687854000034],
            [-80.91291225599997, 35.113824855000075],
            [-80.912586256999987, 35.114026855000077],
            [-80.911715255999979, 35.114568855000073],
            [-80.911091255999963, 35.114961855000047],
            [-80.910311254999954, 35.115445854000029],
            [-80.909690254999987, 35.115818855000043],
            [-80.909524255999941, 35.115924856000049],
            [-80.909151254999983, 35.116153856000039],
            [-80.908866254999964, 35.116317855000034],
            [-80.908463254999958, 35.116529855000067],
            [-80.907035254999982, 35.117220855000028],
            [-80.906703253999979, 35.117386856000053],
            [-80.905617254999981, 35.117915856000025],
            [-80.90499125499997, 35.118237856000064],
            [-80.904716253999936, 35.118429856000034],
            [-80.905421254999965, 35.119085857000073],
            [-80.90582825499996, 35.119435857000042],
            [-80.90633825499998, 35.119848856000033],
            [-80.90698325599999, 35.120324856000025],
            [-80.907655255999941, 35.120774856000025],
            [-80.90843225499998, 35.121234856000058],
            [-80.909014255999978, 35.121550856000056],
            [-80.909575255999982, 35.121834857000067],
            [-80.910624255999949, 35.122311856000067],
            [-80.910743255999989, 35.122359857000049],
            [-80.912190256999963, 35.122946857000045],
            [-80.912652256999934, 35.123140857000067],
            [-80.912946256999987, 35.123229857000069],
            [-80.913651257999959, 35.123467856000048],
            [-80.914443256999959, 35.123752857000056],
            [-80.914511256999958, 35.123776857000053],
            [-80.91474625799998, 35.123870856000053],
            [-80.91567325699998, 35.124277857000038],
            [-80.916311257999951, 35.124588857000049],
            [-80.916484258999958, 35.124680857000044],
            [-80.916784256999961, 35.124850857000069],
            [-80.916985257999954, 35.124970857000051],
            [-80.917416258999936, 35.125220857000045],
            [-80.917520258999957, 35.125269857000035],
            [-80.917800258999989, 35.12544385700005],
            [-80.918147258999966, 35.125668857000051],
            [-80.918252257999939, 35.125735857000052],
            [-80.918503258999976, 35.125896857000043],
            [-80.918890258999966, 35.126162857000054],
            [-80.919495258999973, 35.126628857000071],
            [-80.920117258999937, 35.127157858000032],
            [-80.920521258999941, 35.12752185800008],
            [-80.920825258999969, 35.12781185700004],
            [-80.921142259999954, 35.12812685800003],
            [-80.921465258999945, 35.128477858000053],
            [-80.921740259999979, 35.128813858000058],
            [-80.92224325899997, 35.129441858000064],
            [-80.922525259999986, 35.129843858000072],
            [-80.922866260999967, 35.130374858000039],
            [-80.923291259999985, 35.131101857000033],
            [-80.923646260999988, 35.131746858000042]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 137,
        "SolutionID": "PI-24:E",
        "Shape_Length": 0.25664240026987367,
        "Shape_Area": 0.0017154679767870586
      }
    },
    {
      "type": "Feature",
      "id": 138,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.82984085399994, 35.281574581000029],
            [-80.829938916999936, 35.281844109000076],
            [-80.829956266999943, 35.281885018000025],
            [-80.830331899999976, 35.282753934000027],
            [-80.831154695999942, 35.28527304000005],
            [-80.831208535999963, 35.285424595000052],
            [-80.831270367999934, 35.285574087000043],
            [-80.831340075999947, 35.285721231000025],
            [-80.831417525999939, 35.285865749000038],
            [-80.831502572999966, 35.286007366000035],
            [-80.83159505499998, 35.286145815000054],
            [-80.831694795999965, 35.286280832000045],
            [-80.831801608999967, 35.28641216300008],
            [-80.831915289999984, 35.286539560000051],
            [-80.832035624999946, 35.28666277800005],
            [-80.832162385999936, 35.286781587000064],
            [-80.832295331999944, 35.286895761000039],
            [-80.832434210999963, 35.287005083000054],
            [-80.832578760999979, 35.287109347000069],
            [-80.832728706999944, 35.287208354000029],
            [-80.832883766999942, 35.287301918000026],
            [-80.833043643999986, 35.287389860000076],
            [-80.833208037999952, 35.287472015000048],
            [-80.833376635999969, 35.287548227000059],
            [-80.833549119999986, 35.287618351000049],
            [-80.833725161999951, 35.28768225500005],
            [-80.833904428999972, 35.287739816000055],
            [-80.834086580999951, 35.287790928000049],
            [-80.834271272999956, 35.287835492000056],
            [-80.834458154999936, 35.287873424000054],
            [-80.834646873999986, 35.287904652000066],
            [-80.834837070999981, 35.287929118000079],
            [-80.835028386999966, 35.287946774000034],
            [-80.835220457999981, 35.287957587000051],
            [-80.846000350999986, 35.288370989000043],
            [-80.846177430999944, 35.288374860000033],
            [-80.846354558999963, 35.288372915000025],
            [-80.84653144899994, 35.288365156000054],
            [-80.84670781899996, 35.288351595000051],
            [-80.846883384999956, 35.288332255000057],
            [-80.847057864999954, 35.288307167000028],
            [-80.847230980999939, 35.288276370000062],
            [-80.853040588999988, 35.28713364500004],
            [-80.857898492999936, 35.286567135000041],
            [-80.85809222499995, 35.286541062000026],
            [-80.858284350999952, 35.286507947000075],
            [-80.858474488999946, 35.286467856000058],
            [-80.858662262999985, 35.28642086800005],
            [-80.858847301999958, 35.286367078000069],
            [-80.859029238999938, 35.286306589000048],
            [-80.859207712999989, 35.286239523000063],
            [-80.859382370999981, 35.28616601300007],
            [-80.859552866999934, 35.286086205000061],
            [-80.859718863999944, 35.286000255000033],
            [-80.859880030999989, 35.285908335000045],
            [-80.860036051999941, 35.285810626000057],
            [-80.860186616999954, 35.285707322000064],
            [-80.860331425999959, 35.285598628000059],
            [-80.860470193999959, 35.285484759000042],
            [-80.860602645999961, 35.285365940000077],
            [-80.860728517999974, 35.285242407000055],
            [-80.860847563999982, 35.28511440300008],
            [-80.860959544999957, 35.284982185000047],
            [-80.861064240999951, 35.28484601100007],
            [-80.861161443999947, 35.284706154000048],
            [-80.861250962999975, 35.284562889000028],
            [-80.861332619999985, 35.284416500000077],
            [-80.861406250999948, 35.284267276000037],
            [-80.861471712999958, 35.284115515000053],
            [-80.861528875999966, 35.283961516000033],
            [-80.861577626999974, 35.283805584000049],
            [-80.86161786699995, 35.283648027000027],
            [-80.861649519999958, 35.283489159000055],
            [-80.861672520999946, 35.283329294000055],
            [-80.861687447999941, 35.283189236000055],
            [-80.861695700999974, 35.283048808000046],
            [-80.861697266999954, 35.28290822200006],
            [-80.861692142999971, 35.282767694000029],
            [-80.861680336999939, 35.282627437000031],
            [-80.861661867999942, 35.28248766300004],
            [-80.861643913999956, 35.282362306000039],
            [-80.861619610999981, 35.282237670000029],
            [-80.861589002999949, 35.282113972000047],
            [-80.861552140999947, 35.281991425000058],
            [-80.858702094999956, 35.273140277000039],
            [-80.858317241999941, 35.267123081000079],
            [-80.859030724999968, 35.263679823000075],
            [-80.859059297999977, 35.263520619000076],
            [-80.859079230999953, 35.263360533000025],
            [-80.859090486999946, 35.263199879000069],
            [-80.859093043999962, 35.26303897300005],
            [-80.859086895999951, 35.262878133000072],
            [-80.859072054999956, 35.262717675000033],
            [-80.85904855199999, 35.26255791400007],
            [-80.859016430999986, 35.262399165000033],
            [-80.858975756999939, 35.262241742000072],
            [-80.85892660799999, 35.262085954000042],
            [-80.858869084999981, 35.261932106000074],
            [-80.858803295999962, 35.261780504000058],
            [-80.85872937299996, 35.261631443000056],
            [-80.858647462999954, 35.261485219000065],
            [-80.858557724999969, 35.261342118000073],
            [-80.858460337999986, 35.261202423000043],
            [-80.858355490999941, 35.261066410000069],
            [-80.858243391999963, 35.260934344000077],
            [-80.858124260999944, 35.260806486000035],
            [-80.857998333999944, 35.260683088000064],
            [-80.857865856999979, 35.260564393000038],
            [-80.857727091999948, 35.260450634000051],
            [-80.857582310999987, 35.260342036000054],
            [-80.857431800999962, 35.260238812000068],
            [-80.857275855999944, 35.26014116500005],
            [-80.857114784999965, 35.260049288000062],
            [-80.856948903999978, 35.259963361000075],
            [-80.856778539999937, 35.259883553000066],
            [-80.856604026999946, 35.259810022000067],
            [-80.856425709999939, 35.259742913000025],
            [-80.856243939999956, 35.259682357000031],
            [-80.856059072999983, 35.259628473000078],
            [-80.855871474999958, 35.259581368000056],
            [-80.851525201999948, 35.258575886000074],
            [-80.851185847999943, 35.258509496000045],
            [-80.85104122599995, 35.258488149000073],
            [-80.843083609999951, 35.257424908000075],
            [-80.842916771999967, 35.257405280000057],
            [-80.842749141999946, 35.257390881000049],
            [-80.842580965999957, 35.257381732000056],
            [-80.84241248699999, 35.257377847000043],
            [-80.842243948999965, 35.257379231000073],
            [-80.842075598999941, 35.257385882000051],
            [-80.836722301999941, 35.25768122900007],
            [-80.836526417999949, 35.257695627000032],
            [-80.83633150899999, 35.257717154000034],
            [-80.836137958999984, 35.257745769000053],
            [-80.835946152999952, 35.257781415000068],
            [-80.835756466999953, 35.257824021000033],
            [-80.835569278999969, 35.257873502000052],
            [-80.835384958999953, 35.25792976200006],
            [-80.835203869999987, 35.257992688000058],
            [-80.835026371999959, 35.25806215700004],
            [-80.834852813999987, 35.258138031000044],
            [-80.834683541999937, 35.258220158000029],
            [-80.83451888899998, 35.258308379000027],
            [-80.834359179999979, 35.258402517000036],
            [-80.834204732999979, 35.258502387000078],
            [-80.834055852999938, 35.258607791000031],
            [-80.833912833999989, 35.258718520000059],
            [-80.833775956999943, 35.258834356000079],
            [-80.833645495999974, 35.258955069000024],
            [-80.833521707999978, 35.259080422000068],
            [-80.833404837999979, 35.25921016500007],
            [-80.833295115999988, 35.259344041000077],
            [-80.833192760999964, 35.259481787000027],
            [-80.833097973999941, 35.25962313000008],
            [-80.833010941999987, 35.25976778900008],
            [-80.832931838999968, 35.259915481000064],
            [-80.83286082099994, 35.260065910000037],
            [-80.832798028999946, 35.260218781000049],
            [-80.832743583999957, 35.260373792000053],
            [-80.832697597999982, 35.260530634000077],
            [-80.832660160999978, 35.260688999000024],
            [-80.832631344999982, 35.260848572000043],
            [-80.832611209999982, 35.261009037000065],
            [-80.832599791999939, 35.261170080000056],
            [-80.832597117999967, 35.261331378000079],
            [-80.832603189999986, 35.261492616000055],
            [-80.833135561999939, 35.269703994000054],
            [-80.830379301999983, 35.276239481000061],
            [-80.830328295999948, 35.276368133000062],
            [-80.830283174999977, 35.276498255000035],
            [-80.830244001999972, 35.276629666000076],
            [-80.830210833999956, 35.276762183000073],
            [-80.830183714999976, 35.27689562300003],
            [-80.830162682999969, 35.277029800000037],
            [-80.82972843999994, 35.280278019000036],
            [-80.829712670999982, 35.280422643000065],
            [-80.829703972999937, 35.28056766800006],
            [-80.829702361999978, 35.280712863000076],
            [-80.82970783899998, 35.28085799400003],
            [-80.82972039699996, 35.281002829000045],
            [-80.829740013999981, 35.281147136000072],
            [-80.829766658999972, 35.281290684000055],
            [-80.829800291999959, 35.281433241000059],
            [-80.82984085399994, 35.281574581000029]
          ],
          [
            [-80.849371618999953, 35.265614503000052],
            [-80.849645377999934, 35.26567784100007],
            [-80.84951562599997, 35.26630367000007],
            [-80.849487155999952, 35.266462069000056],
            [-80.849467237999988, 35.266621343000054],
            [-80.849455908999971, 35.266781183000035],
            [-80.849453193999977, 35.266941278000047],
            [-80.849459096999965, 35.267101315000048],
            [-80.849892800999953, 35.273893826000062],
            [-80.849906052999984, 35.274042655000073],
            [-80.84992675899997, 35.274190910000073],
            [-80.849954884999988, 35.274338342000078],
            [-80.849990381999987, 35.274484700000073],
            [-80.850033191999955, 35.274629736000065],
            [-80.851748096999984, 35.279957598000067],
            [-80.851588753999977, 35.279976176000048],
            [-80.851362227999971, 35.280007529000045],
            [-80.851179529999968, 35.280040138000061],
            [-80.845785835999948, 35.281101081000031],
            [-80.838863344999936, 35.280835682000031],
            [-80.83883164599996, 35.280738660000054],
            [-80.838797598999975, 35.280640044000052],
            [-80.83872801199999, 35.280465155000059],
            [-80.838632079999968, 35.280243301000041],
            [-80.838901670999974, 35.278225072000055],
            [-80.841779117999977, 35.271400465000056],
            [-80.841836869999952, 35.271252771000036],
            [-80.84188686799996, 35.271103187000051],
            [-80.841929021999988, 35.270951987000046],
            [-80.841963255999985, 35.270799446000069],
            [-80.841989503999969, 35.270645846000036],
            [-80.842007721999948, 35.270491465000077],
            [-80.842017873999964, 35.270336587000031],
            [-80.842019941999979, 35.270181494000042],
            [-80.842013922999968, 35.270026471000051],
            [-80.841666607999969, 35.26467866400003],
            [-80.842162602999963, 35.264651291000064],
            [-80.849371618999953, 35.265614503000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 138,
        "SolutionID": "PI-27:I",
        "Shape_Length": 0.16204184282837628,
        "Shape_Area": 0.00066660797735262878
      }
    },
    {
      "type": "Feature",
      "id": 139,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.885938537999948, 35.110583299000041],
            [-80.88574581599994, 35.110605294000038],
            [-80.885554461999959, 35.110634246000075],
            [-80.885364845999959, 35.11067010000005],
            [-80.885177336999959, 35.110712785000032],
            [-80.884992299999965, 35.110762220000026],
            [-80.884810092999942, 35.110818307000045],
            [-80.884631070999944, 35.110880938000037],
            [-80.884455581999987, 35.11094999200003],
            [-80.884283966999988, 35.111025333000043],
            [-80.88411655699997, 35.111106816000074],
            [-80.883953681999969, 35.111194282000042],
            [-80.883795654999972, 35.11128756100004],
            [-80.88364278399996, 35.111386473000039],
            [-80.883495365999977, 35.111490824000043],
            [-80.883353687999943, 35.111600413000076],
            [-80.883218023999973, 35.11171502600007],
            [-80.883088638999936, 35.111834440000052],
            [-80.882965783999964, 35.111958424000079],
            [-80.882849697999973, 35.112086735000048],
            [-80.88274060599997, 35.11221912700006],
            [-80.882638718999942, 35.112355341000068],
            [-80.88254423799998, 35.112495111000044],
            [-80.882457343999988, 35.112638168000046],
            [-80.882378206999988, 35.112784232000024],
            [-80.882306979999953, 35.112933019000025],
            [-80.882243802999938, 35.11308424300006],
            [-80.882188797999959, 35.113237606000041],
            [-80.882142071999965, 35.113392813000075],
            [-80.882103713999982, 35.11354956100007],
            [-80.882073801999979, 35.113707545000068],
            [-80.882052389999956, 35.113866460000054],
            [-80.882039524999982, 35.114025995000077],
            [-80.88203522699996, 35.11418584200004],
            [-80.882035182999971, 35.11499824100008],
            [-80.882039346999989, 35.115155882000067],
            [-80.882051843999989, 35.115313225000079],
            [-80.882072651999977, 35.115469973000074],
            [-80.882101731999967, 35.115625830000056],
            [-80.882139028999973, 35.115780501000074],
            [-80.882184471999949, 35.115933694000034],
            [-80.882237975999942, 35.116085120000037],
            [-80.882299438999951, 35.116234491000057],
            [-80.882368744999951, 35.116381526000055],
            [-80.882445763999954, 35.116525947000071],
            [-80.882530349999968, 35.116667481000036],
            [-80.882622341999934, 35.116805861000046],
            [-80.882721567999965, 35.116940824000039],
            [-80.882827838999958, 35.117072116000031],
            [-80.882940954999981, 35.117199489000029],
            [-80.883060701999966, 35.117322701000035],
            [-80.883186853999973, 35.117441520000057],
            [-80.883319171999972, 35.117555722000077],
            [-80.88345740699998, 35.117665091000049],
            [-80.885756447999938, 35.119406455000046],
            [-80.885639672999957, 35.121124364000025],
            [-80.88337500199998, 35.121803508000028],
            [-80.883195101999945, 35.12186114800005],
            [-80.88301844199998, 35.121925198000042],
            [-80.882845359999976, 35.121995533000074],
            [-80.882676186999959, 35.122072020000076],
            [-80.882511246999968, 35.122154511000076],
            [-80.882350853999981, 35.122242850000077],
            [-80.882195316999969, 35.122336867000058],
            [-80.882044931999985, 35.122436382000046],
            [-80.881899987999986, 35.122541206000051],
            [-80.881760760999953, 35.122651137000048],
            [-80.881627517999959, 35.122765964000052],
            [-80.881500514999971, 35.122885470000028],
            [-80.881379992999939, 35.123009424000031],
            [-80.88126618299998, 35.123137590000056],
            [-80.881159303999937, 35.123269722000032],
            [-80.881059559999983, 35.123405567000077],
            [-80.880967141999975, 35.123544867000078],
            [-80.880882225999983, 35.123687354000026],
            [-80.880804975999979, 35.123832755000024],
            [-80.880735536999964, 35.123980794000033],
            [-80.880674045999967, 35.124131184000078],
            [-80.880620616999977, 35.124283641000034],
            [-80.879964045999941, 35.126323916000047],
            [-80.877907088999962, 35.130921625000042],
            [-80.877843343999984, 35.131074540000043],
            [-80.877787953999984, 35.131229637000047],
            [-80.877741029999981, 35.131386607000024],
            [-80.877702665999948, 35.131545139000025],
            [-80.877672936999943, 35.131704917000036],
            [-80.877651902999958, 35.131865625000046],
            [-80.877639605999946, 35.132026943000028],
            [-80.877636068999948, 35.13218855100007],
            [-80.877641299999937, 35.132350128000041],
            [-80.87765528999995, 35.13251135400003],
            [-80.877678009999954, 35.132671906000041],
            [-80.877709412999934, 35.132831469000052],
            [-80.877749439999945, 35.132989723000037],
            [-80.877798009999935, 35.133146354000075],
            [-80.877855026999953, 35.133301053000025],
            [-80.877920376999953, 35.133453511000027],
            [-80.877993930999935, 35.133603426000036],
            [-80.878075542999966, 35.133750500000076],
            [-80.87816504999995, 35.133894440000063],
            [-80.878262275999987, 35.134034962000044],
            [-80.878367026999967, 35.134171786000024],
            [-80.878479093999943, 35.134304640000039],
            [-80.878598255999975, 35.134433260000037],
            [-80.878724274999968, 35.134557390000055],
            [-80.878856901999939, 35.134676786000057],
            [-80.878995872999951, 35.134791207000035],
            [-80.879140911999968, 35.134900429000027],
            [-80.879291731999956, 35.135004234000064],
            [-80.879448031999971, 35.135102416000052],
            [-80.879609500999948, 35.135194779000074],
            [-80.881152381999982, 35.13603600700003],
            [-80.882014022999954, 35.138925500000028],
            [-80.881894921999958, 35.141551971000069],
            [-80.88189194399996, 35.141709192000064],
            [-80.881897265999953, 35.141866373000028],
            [-80.881910876999939, 35.14202321700003],
            [-80.881932749999976, 35.142179427000031],
            [-80.881962846999954, 35.142334713000025],
            [-80.88200110799994, 35.142488781000054],
            [-80.882047463999982, 35.142641342000047],
            [-80.882101825999939, 35.142792109000027],
            [-80.882164093999961, 35.142940800000076],
            [-80.882234148999942, 35.143087133000051],
            [-80.882311859999959, 35.143230835000054],
            [-80.882397080999965, 35.143371635000051],
            [-80.882489650999958, 35.143509269000049],
            [-80.882589397999936, 35.143643478000058],
            [-80.882696133999957, 35.143774009000026],
            [-80.882809656999939, 35.143900617000043],
            [-80.882929754999964, 35.144023065000056],
            [-80.883056200999988, 35.144141121000075],
            [-80.88318875899995, 35.14425456500004],
            [-80.883327177999945, 35.144363183000053],
            [-80.883471199999974, 35.144466771000054],
            [-80.885517574999938, 35.145872462000057],
            [-80.88580013699999, 35.151886386000058],
            [-80.884379604999936, 35.153241313000024],
            [-80.884258446999979, 35.153362045000051],
            [-80.884143739999956, 35.153486955000062],
            [-80.88403569899998, 35.153615812000055],
            [-80.883934524999972, 35.153748376000067],
            [-80.883840403999955, 35.153884400000038],
            [-80.883753513999977, 35.15402363100003],
            [-80.883674015999986, 35.154165810000052],
            [-80.883602056999962, 35.154310672000065],
            [-80.883537771999954, 35.154457949000061],
            [-80.883481279999955, 35.154607365000061],
            [-80.883432685999935, 35.154758644000026],
            [-80.883392080999954, 35.154911502000061],
            [-80.883359539999958, 35.15506565700008],
            [-80.883335123999984, 35.155220820000068],
            [-80.883318878999944, 35.155376704000048],
            [-80.883310835999964, 35.155533019000075],
            [-80.883311005999985, 35.155689472000063],
            [-80.883319392999965, 35.155845775000046],
            [-80.88333597999997, 35.156001635000052],
            [-80.883360735999986, 35.156156761000034],
            [-80.883393614999989, 35.156310868000048],
            [-80.883434555999941, 35.156463666000036],
            [-80.883483482999964, 35.156614871000045],
            [-80.883540303999951, 35.156764204000069],
            [-80.883604914999978, 35.156911384000068],
            [-80.883677193999972, 35.157056140000066],
            [-80.883757007999975, 35.157198201000028],
            [-80.884909110999956, 35.159137673000032],
            [-80.883574153999973, 35.159754505000024],
            [-80.883406015999981, 35.15983626600007],
            [-80.88324244599994, 35.159924056000079],
            [-80.883083763999934, 35.160017700000026],
            [-80.882930280999972, 35.160117018000051],
            [-80.882782295999959, 35.160221814000067],
            [-80.882640101999982, 35.160331881000047],
            [-80.882503973999974, 35.160447007000073],
            [-80.882374179999942, 35.160566964000054],
            [-80.882250974999977, 35.160691520000057],
            [-80.882134597999936, 35.160820428000079],
            [-80.882025277999958, 35.160953438000035],
            [-80.881923228999938, 35.161090287000036],
            [-80.881828651999967, 35.16123071100003],
            [-80.881741729999987, 35.161374433000049],
            [-80.881662632999962, 35.161521171000061],
            [-80.881591517999937, 35.161670638000032],
            [-80.881528522999986, 35.161822543000028],
            [-80.881473770999946, 35.161976587000026],
            [-80.881427370999972, 35.162132469000028],
            [-80.881389410999986, 35.162289884000074],
            [-80.881359968999959, 35.162448524000069],
            [-80.881339098999945, 35.162608077000073],
            [-80.88132684499999, 35.162768234000055],
            [-80.881323227999985, 35.162928678000071],
            [-80.88132825799994, 35.163089096000078],
            [-80.881341922999979, 35.163249175000033],
            [-80.881364198999961, 35.163408601000071],
            [-80.881395039999973, 35.163567062000027],
            [-80.881434385999967, 35.163724248000051],
            [-80.881482158999972, 35.163879849000068],
            [-80.881538268999975, 35.164033564000079],
            [-80.881602603999966, 35.164185090000046],
            [-80.881675037999969, 35.164334131000032],
            [-80.881755428999952, 35.164480394000066],
            [-80.881843619999984, 35.164623594000034],
            [-80.88193943899995, 35.164763451000056],
            [-80.882042697999964, 35.164899691000073],
            [-80.882153193999955, 35.165032046000078],
            [-80.882270712999969, 35.165160257000025],
            [-80.882395022999958, 35.165284075000045],
            [-80.882525880999935, 35.165403255000058],
            [-80.882663031999982, 35.165517566000062],
            [-80.882806204999952, 35.16562678300005],
            [-80.882955121999942, 35.165730693000057],
            [-80.88310949199996, 35.165829091000035],
            [-80.883269009999935, 35.165921786000069],
            [-80.883433365999963, 35.166008595000051],
            [-80.88360223799998, 35.16608934900006],
            [-80.883775293999975, 35.166163890000064],
            [-80.883952197999974, 35.166232072000071],
            [-80.884132599999987, 35.166293761000077],
            [-80.884316148999972, 35.166348837000044],
            [-80.884502484999985, 35.166397192000034],
            [-80.884691244999942, 35.166438731000028],
            [-80.884882055999981, 35.166473372000041],
            [-80.885074547999977, 35.166501050000079],
            [-80.885268341999961, 35.166521707000072],
            [-80.88546305899996, 35.166535304000035],
            [-80.885658317999969, 35.166541815000073],
            [-80.885853736999934, 35.166541228000028],
            [-80.886048932999984, 35.166533542000025],
            [-80.886243522999962, 35.166518773000064],
            [-80.886437126999965, 35.166496951000056],
            [-80.886629366999955, 35.166468118000068],
            [-80.88681986499995, 35.166432330000077],
            [-80.887008247999972, 35.166389656000035],
            [-80.887194148999981, 35.166340182000056],
            [-80.887377201999982, 35.166284004000033],
            [-80.887557048999952, 35.166221230000076],
            [-80.887733338999965, 35.166151986000045],
            [-80.887905725999985, 35.166076407000048],
            [-80.891169011999978, 35.164568491000068],
            [-80.89133461299997, 35.164488016000064],
            [-80.891495787999986, 35.164401693000059],
            [-80.891652231999956, 35.16430968800006],
            [-80.891803646999961, 35.164212173000067],
            [-80.891949744999977, 35.164109335000035],
            [-80.892090248999978, 35.164001369000061],
            [-80.892224891999945, 35.163888479000036],
            [-80.892353419999949, 35.163770881000062],
            [-80.892475587999968, 35.163648796000075],
            [-80.892591163999953, 35.163522458000045],
            [-80.892699928999946, 35.16339210600006],
            [-80.892801676999966, 35.163257986000076],
            [-80.892896212999972, 35.163120355000046],
            [-80.894031180999946, 35.161380447000056],
            [-80.894117208999944, 35.161241466000035],
            [-80.894195884999988, 35.161099576000026],
            [-80.894267060999937, 35.160955041000079],
            [-80.894330608999951, 35.160808128000042],
            [-80.894386409999981, 35.160659108000061],
            [-80.894434359999934, 35.160508256000071],
            [-80.894474370999944, 35.160355851000077],
            [-80.894506369999988, 35.160202175000052],
            [-80.894530295999971, 35.160047510000027],
            [-80.89454610699994, 35.159892146000061],
            [-80.894553773999974, 35.159736366000061],
            [-80.894553280999958, 35.15958045900004],
            [-80.894544630999974, 35.159424714000068],
            [-80.894527837999988, 35.159269418000065],
            [-80.894502932999956, 35.159114859000056],
            [-80.894469963999938, 35.158961321000049],
            [-80.894428989999938, 35.158809089000044],
            [-80.894380088999981, 35.158658443000036],
            [-80.89432334899999, 35.158509662000029],
            [-80.894258875999981, 35.158363021000071],
            [-80.894186789999935, 35.158218792000071],
            [-80.894107222999935, 35.158077239000079],
            [-80.892970572999957, 35.156164206000028],
            [-80.89362667599994, 35.155538325000066],
            [-80.89375040799996, 35.155414890000031],
            [-80.893867396999951, 35.155287095000062],
            [-80.893977412999959, 35.155155191000063],
            [-80.894080243999952, 35.155019432000074],
            [-80.89417568999994, 35.154880084000069],
            [-80.89426356499996, 35.154737415000056],
            [-80.894343697999943, 35.15459170500003],
            [-80.894415934999984, 35.154443236000077],
            [-80.894480132999945, 35.15429229700004],
            [-80.894536169999981, 35.154139180000072],
            [-80.894583935999947, 35.153984184000024],
            [-80.894623337999974, 35.153827610000064],
            [-80.894654299999956, 35.15366976100006],
            [-80.894676760999971, 35.15351094500005],
            [-80.894690677999961, 35.153351472000054],
            [-80.894696024999973, 35.153191648000075],
            [-80.894692788999976, 35.153031787000032],
            [-80.894266663999986, 35.143983150000054],
            [-80.894254891999935, 35.143823917000077],
            [-80.894234606999987, 35.14366526200007],
            [-80.894205847999956, 35.143507494000062],
            [-80.894168669999942, 35.143350918000067],
            [-80.894123147999949, 35.143195835000029],
            [-80.894069366999986, 35.143042546000061],
            [-80.894007430999977, 35.142891349000024],
            [-80.89393746199994, 35.142742535000025],
            [-80.893859594999981, 35.14259639200003],
            [-80.893773978999945, 35.142453203000059],
            [-80.89368078199999, 35.142313244000036],
            [-80.893580181999937, 35.142176787000039],
            [-80.893472374999988, 35.142044096000063],
            [-80.893357567999942, 35.141915427000072],
            [-80.89323598499999, 35.141791028000057],
            [-80.893107860999976, 35.141671142000064],
            [-80.892973443999949, 35.141556000000037],
            [-80.892832993999946, 35.141445824000073],
            [-80.892686779999963, 35.141340827000079],
            [-80.890800200999934, 35.140045028000031],
            [-80.890861537999967, 35.138689107000062],
            [-80.890864568999973, 35.138545374000046],
            [-80.890860663999945, 35.138401655000052],
            [-80.890849829999979, 35.138258176000079],
            [-80.890832081999974, 35.138115163000066],
            [-80.890807448999965, 35.137972839000042],
            [-80.890775970999982, 35.137831429000073],
            [-80.890737695999974, 35.137691155000027],
            [-80.889318676999949, 35.132934133000049],
            [-80.889268552999965, 35.132780710000077],
            [-80.889210299999945, 35.132629236000071],
            [-80.889144030999944, 35.132480003000069],
            [-80.889069869999958, 35.132333297000059],
            [-80.888987961999987, 35.132189400000073],
            [-80.88889846099994, 35.132048587000043],
            [-80.888801541999953, 35.13191112800007],
            [-80.888697389999948, 35.13177728800008],
            [-80.888586203999978, 35.131647321000059],
            [-80.888468197999941, 35.13152148000006],
            [-80.888343597999949, 35.131400004000056],
            [-80.888212643999964, 35.131283126000028],
            [-80.888075584999967, 35.13117107100004],
            [-80.887932685999942, 35.131064053000046],
            [-80.887784220999947, 35.130962278000027],
            [-80.887630472999945, 35.130865942000071],
            [-80.887471738999977, 35.130775228000061],
            [-80.887397847999978, 35.130734944000039],
            [-80.888323040999978, 35.128666404000057],
            [-80.888355654999941, 35.128590917000054],
            [-80.888447364999934, 35.12834226800004],
            [-80.888567648999981, 35.127968378000048],
            [-80.890658795999968, 35.127341220000062],
            [-80.890835770999956, 35.127284567000061],
            [-80.891009615999963, 35.127221714000029],
            [-80.891180007999935, 35.12715277500007],
            [-80.891346633999945, 35.127077877000033],
            [-80.891509183999972, 35.126997161000077],
            [-80.891667355999971, 35.126910776000045],
            [-80.891820860999985, 35.12681888000003],
            [-80.891969409999945, 35.126721645000032],
            [-80.892112731999987, 35.126619250000033],
            [-80.892250559999979, 35.12651188500007],
            [-80.892382638999948, 35.126399749000029],
            [-80.892508723999981, 35.126283049000051],
            [-80.892628581999986, 35.126162000000079],
            [-80.89274199099998, 35.126036829000043],
            [-80.892848742999945, 35.125907766000068],
            [-80.89294863899994, 35.125775049000026],
            [-80.893041491999952, 35.125638925000032],
            [-80.89312713399994, 35.125499645000048],
            [-80.893205402999968, 35.125357469000051],
            [-80.893276156999946, 35.125212657000077],
            [-80.894127346999937, 35.123355910000043],
            [-80.894191101999979, 35.123207100000059],
            [-80.894246919999944, 35.123056152000061],
            [-80.894294694999985, 35.122903353000027],
            [-80.894334337999965, 35.122748992000027],
            [-80.894365773999937, 35.122593360000053],
            [-80.894388941999978, 35.122436752000056],
            [-80.894403799999964, 35.12227946400003],
            [-80.894687329999954, 35.118101683000077],
            [-80.894693878999988, 35.117941864000045],
            [-80.894691847999979, 35.117781963000027],
            [-80.894681240999944, 35.117622291000032],
            [-80.894662077999953, 35.117463159000067],
            [-80.894634397999937, 35.117304875000059],
            [-80.894598254999948, 35.117147747000047],
            [-80.894553715999962, 35.116992082000024],
            [-80.894500869999945, 35.116838180000059],
            [-80.894439820999935, 35.116686342000037],
            [-80.894370684999956, 35.116536862000032],
            [-80.89429359899998, 35.116390032000027],
            [-80.894208710999976, 35.116246137000076],
            [-80.894116185999962, 35.116105455000024],
            [-80.894016205999947, 35.115968262000024],
            [-80.893908961999955, 35.115834822000068],
            [-80.893794664999973, 35.115705397000056],
            [-80.893673537999973, 35.115580237000074],
            [-80.893545812999946, 35.115459585000053],
            [-80.893411741999955, 35.115343676000066],
            [-80.893271582999944, 35.115232736000053],
            [-80.890728196999987, 35.113306484000077],
            [-80.890677583999945, 35.113153620000048],
            [-80.890618883999934, 35.113002721000043],
            [-80.890552209999953, 35.11285407500003],
            [-80.890477689999955, 35.112707967000063],
            [-80.890395465999973, 35.112564678000069],
            [-80.890305693999949, 35.112424480000072],
            [-80.890208546999986, 35.11228764100008],
            [-80.890104211999983, 35.112154424000039],
            [-80.889992886999948, 35.112025083000049],
            [-80.88987478599995, 35.111899867000034],
            [-80.889750133999939, 35.111779014000035],
            [-80.889619170999936, 35.111662756000044],
            [-80.889482145999978, 35.111551315000042],
            [-80.889339321999955, 35.111444905000042],
            [-80.889190970999948, 35.111343728000065],
            [-80.889037378999944, 35.111247979000041],
            [-80.888878838999972, 35.111157840000033],
            [-80.888715652999963, 35.111073485000077],
            [-80.888548134999951, 35.110995074000073],
            [-80.888376604999962, 35.110922757000026],
            [-80.888201389999949, 35.110856672000068],
            [-80.88802282599994, 35.110796948000029],
            [-80.887841254999955, 35.110743697000032],
            [-80.887657023999964, 35.110697022000068],
            [-80.887470485999984, 35.110657010000068],
            [-80.887281996999945, 35.110623740000051],
            [-80.887091916999964, 35.110597275000032],
            [-80.886900610999987, 35.11057766600004],
            [-80.886708444999954, 35.110564948000047],
            [-80.886515785999961, 35.110559149000039],
            [-80.886323002999973, 35.110560278000037],
            [-80.886130463999962, 35.11056833300006],
            [-80.885938537999948, 35.110583299000041]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 139,
        "SolutionID": "PI-27:S",
        "Shape_Length": 0.14565042916810758,
        "Shape_Area": 0.00056299015305719038
      }
    },
    {
      "type": "Feature",
      "id": 140,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.859572396999965, 35.169089143000065],
            [-80.859283738999977, 35.169101350000062],
            [-80.858996671999989, 35.169129033000047],
            [-80.858712424999965, 35.169172073000027],
            [-80.858432213999947, 35.169230285000026],
            [-80.85815723799999, 35.169303421000052],
            [-80.857888675999959, 35.169391168000061],
            [-80.857627677999972, 35.169493148000072],
            [-80.857375359999935, 35.16960892700007],
            [-80.857132802999956, 35.169738008000024],
            [-80.856901046999951, 35.169879839000032],
            [-80.856681081999966, 35.170033811000053],
            [-80.856473850999976, 35.170199267000044],
            [-80.856280242999958, 35.170375497000066],
            [-80.856101084999978, 35.170561748000068],
            [-80.855937143999938, 35.170757220000041],
            [-80.855789122999965, 35.170961079000051],
            [-80.855657656999938, 35.171172450000029],
            [-80.855543306999948, 35.171390429000041],
            [-80.855446562999987, 35.171614082000076],
            [-80.855367840999975, 35.171842451000032],
            [-80.85530747699994, 35.172074559000066],
            [-80.855265728999939, 35.172309412000061],
            [-80.85524277799999, 35.172546003000036],
            [-80.855238720999978, 35.172783321000054],
            [-80.855253575999939, 35.173020349000069],
            [-80.855287278999981, 35.173256071000026],
            [-80.855332035999936, 35.173460134000038],
            [-80.855751156999986, 35.175100701000076],
            [-80.855876440999964, 35.175605045000054],
            [-80.85588237799999, 35.175628546000041],
            [-80.856051627999989, 35.176287570000056],
            [-80.856058324999935, 35.176313186000073],
            [-80.856072306999977, 35.176363990000027],
            [-80.856268481999962, 35.17705374600007],
            [-80.856308784999953, 35.177184431000057],
            [-80.856345614999952, 35.177295166000079],
            [-80.856362222999962, 35.177343769000061],
            [-80.856388685999946, 35.177416331000074],
            [-80.856430236999984, 35.177526079000074],
            [-80.856490248999989, 35.177673381000034],
            [-80.856537626999966, 35.177781893000031],
            [-80.856540222999968, 35.177787821000038],
            [-80.856577297999934, 35.177869205000036],
            [-80.856602209999949, 35.177921953000066],
            [-80.856672001999982, 35.178061097000068],
            [-80.856676478999987, 35.178069495000045],
            [-80.856735911999976, 35.178180570000052],
            [-80.856804747999945, 35.178302988000041],
            [-80.856869157999938, 35.178412155000046],
            [-80.856920049999985, 35.178495654000074],
            [-80.856940485999985, 35.17852774000005],
            [-80.857005544999936, 35.178628651000054],
            [-80.857072719999962, 35.178728983000042],
            [-80.857196150999982, 35.178906634000043],
            [-80.857235236999941, 35.17896175900006],
            [-80.857415817999936, 35.179211359000078],
            [-80.85743013299998, 35.17923100400003],
            [-80.857432690999985, 35.179234484000062],
            [-80.857856409999954, 35.179810355000029],
            [-80.857862750999971, 35.179818946000069],
            [-80.858106536999969, 35.180148204000034],
            [-80.858325425999965, 35.180447360000073],
            [-80.858328520999976, 35.180451583000035],
            [-80.858366092999972, 35.180501846000027],
            [-80.858686432999946, 35.180922671000076],
            [-80.858729817999972, 35.180984667000075],
            [-80.858776469999952, 35.181049753000025],
            [-80.858911371999966, 35.181222321000064],
            [-80.859138813999948, 35.181495931000029],
            [-80.859161136999944, 35.181522531000041],
            [-80.859209501999942, 35.181578478000063],
            [-80.859282235999956, 35.181660950000037],
            [-80.859406627999988, 35.181795301000079],
            [-80.859412158999987, 35.181800984000063],
            [-80.859489846999963, 35.181880632000059],
            [-80.859607124999968, 35.181995892000032],
            [-80.859688656999936, 35.182072730000073],
            [-80.859753395999974, 35.182132402000036],
            [-80.859797578999974, 35.182171650000043],
            [-80.86051086599997, 35.182795961000068],
            [-80.860520820999966, 35.182804645000033],
            [-80.860774965999951, 35.183025624000038],
            [-80.860810723999975, 35.18305635300004],
            [-80.861329837999961, 35.183497251000063],
            [-80.861540379999951, 35.183677675000069],
            [-80.861568014999989, 35.183701145000043],
            [-80.861819181999977, 35.183912539000062],
            [-80.862002289999964, 35.184069457000078],
            [-80.862041842999986, 35.184102918000065],
            [-80.862253315999965, 35.184279541000024],
            [-80.862435334999986, 35.184435773000075],
            [-80.862501688999942, 35.184491515000047],
            [-80.862764893999952, 35.184707924000065],
            [-80.863236529999938, 35.185107051000045],
            [-80.863266846999977, 35.185132457000066],
            [-80.86351304599998, 35.185336756000027],
            [-80.863978250999935, 35.185729079000055],
            [-80.864171781999971, 35.185896113000069],
            [-80.864233206999984, 35.18594807900007],
            [-80.864463402999945, 35.186138978000031],
            [-80.864843517999986, 35.186457731000075],
            [-80.865145219999988, 35.186714125000037],
            [-80.865365347999955, 35.186902974000077],
            [-80.865392182999983, 35.186925796000025],
            [-80.865662695999958, 35.187153842000043],
            [-80.865676600999961, 35.187165512000036],
            [-80.865849414999957, 35.187309891000041],
            [-80.866037562999963, 35.187471791000064],
            [-80.866205350999962, 35.187621492000062],
            [-80.866285285999936, 35.187690949000057],
            [-80.866806165999947, 35.188131777000024],
            [-80.86682687199999, 35.188149183000064],
            [-80.867040551999935, 35.18832761200008],
            [-80.867523208999955, 35.188740784000061],
            [-80.867548606999947, 35.188762346000033],
            [-80.868117521999977, 35.189241358000061],
            [-80.868216125999936, 35.189321808000045],
            [-80.868233532999966, 35.189335494000034],
            [-80.868469021999942, 35.189519649000033],
            [-80.868666309999981, 35.189664917000073],
            [-80.868769221999969, 35.189734087000033],
            [-80.86881240699995, 35.189762234000057],
            [-80.868853955999953, 35.189790710000068],
            [-80.869043097999963, 35.189913315000069],
            [-80.869130244999951, 35.189966702000049],
            [-80.869149496999967, 35.189978428000074],
            [-80.86929848699998, 35.190064831000029],
            [-80.869397667999976, 35.190119864000053],
            [-80.869486811999934, 35.190168050000068],
            [-80.86960467199998, 35.190227974000038],
            [-80.869687906999957, 35.190268825000032],
            [-80.869818395999971, 35.190330415000062],
            [-80.869878493999977, 35.190357176000077],
            [-80.869932697999957, 35.190380871000059],
            [-80.870095055999968, 35.19045538000006],
            [-80.870261251999978, 35.190534909000064],
            [-80.870386355999983, 35.19059721900004],
            [-80.870491326999968, 35.190647890000037],
            [-80.871043139999983, 35.190905919000045],
            [-80.87138824699997, 35.191072805000033],
            [-80.872028138999951, 35.19138437600003],
            [-80.872269958999937, 35.19150836700004],
            [-80.87247397699997, 35.191606840000077],
            [-80.872496450999961, 35.191616963000058],
            [-80.872676568999964, 35.19169753400007],
            [-80.872770870999943, 35.191738514000065],
            [-80.873336825999957, 35.191977312000063],
            [-80.873426215999984, 35.192013985000074],
            [-80.87345662499996, 35.192026110000029],
            [-80.873507965999977, 35.192046246000075],
            [-80.873663969999939, 35.192103428000053],
            [-80.873726401999988, 35.192125133000047],
            [-80.873835953999958, 35.192161806000058],
            [-80.874028383999985, 35.192219581000074],
            [-80.874092963999942, 35.192237589000058],
            [-80.874172998999938, 35.192259208000053],
            [-80.874322276999976, 35.192295874000024],
            [-80.874370797999973, 35.192307031000041],
            [-80.874499727999989, 35.192334961000029],
            [-80.874663912999949, 35.192365647000031],
            [-80.874729168999977, 35.192376774000024],
            [-80.874847742999975, 35.192395608000027],
            [-80.875133841999968, 35.192429840000045],
            [-80.87542205699998, 35.19244863800003],
            [-80.875711151999951, 35.192451920000053],
            [-80.875999888999957, 35.19243967400007],
            [-80.876287032999983, 35.192411951000054],
            [-80.876571351999985, 35.192368870000053],
            [-80.876851629999976, 35.192310616000043],
            [-80.877126665999981, 35.192237437000074],
            [-80.877395281999952, 35.192149649000044],
            [-80.87765632899999, 35.192047626000033],
            [-80.877908687999934, 35.191931805000024],
            [-80.878151277999962, 35.191802683000049],
            [-80.878383058999987, 35.191660812000066],
            [-80.878603040999963, 35.19150680000007],
            [-80.878810280999971, 35.191341307000073],
            [-80.879003890999968, 35.191165041000033],
            [-80.879183041999966, 35.190978757000039],
            [-80.879346966999947, 35.190783254000053],
            [-80.879494964999935, 35.190579367000055],
            [-80.879626400999939, 35.190367970000068],
            [-80.87974071299999, 35.190149969000061],
            [-80.879837411999972, 35.189926298000046],
            [-80.879916082999955, 35.189697913000032],
            [-80.879976389999968, 35.189465793000068],
            [-80.880018075999942, 35.189230933000033],
            [-80.880040959999974, 35.188994337000054],
            [-80.880044944999952, 35.188757019000036],
            [-80.880030015999978, 35.188519995000036],
            [-80.879996234999965, 35.188284280000062],
            [-80.87994374699997, 35.188050884000063],
            [-80.879872777999935, 35.187820805000058],
            [-80.879783630999952, 35.187595030000068],
            [-80.87967668899995, 35.187374524000063],
            [-80.879552408999984, 35.187160232000053],
            [-80.879411323999989, 35.186953072000051],
            [-80.879254037999942, 35.186753930000066],
            [-80.879081223999947, 35.186563660000047],
            [-80.878893623999943, 35.186383076000027],
            [-80.878692038999986, 35.186212951000073],
            [-80.878477332999978, 35.186054014000035],
            [-80.878250425999966, 35.185906945000056],
            [-80.878012289999958, 35.185772373000077],
            [-80.877763941999945, 35.185650876000068],
            [-80.877506447999963, 35.185542973000054],
            [-80.877240909999955, 35.185449127000027],
            [-80.877091185999973, 35.18540549800008],
            [-80.876857519999987, 35.18530690800003],
            [-80.876839463999943, 35.185298831000068],
            [-80.876665908999939, 35.185209845000031],
            [-80.876579256999946, 35.185166543000037],
            [-80.875890045999938, 35.184830967000039],
            [-80.875878591999935, 35.18482541000003],
            [-80.875500021999983, 35.184642345000043],
            [-80.875444290999951, 35.184615843000074],
            [-80.874917432999951, 35.184369488000073],
            [-80.874811183999952, 35.184316572000057],
            [-80.874744420999946, 35.184283975000028],
            [-80.874510314999952, 35.184171953000032],
            [-80.874441235999939, 35.184139578000043],
            [-80.874348567999959, 35.184097051000037],
            [-80.873887607999961, 35.183708959000057],
            [-80.873398589999965, 35.183290369000076],
            [-80.873360338999987, 35.183258032000026],
            [-80.873137798999949, 35.183072217000074],
            [-80.872667758999967, 35.182674442000064],
            [-80.872512375999975, 35.182535819000066],
            [-80.872457844999985, 35.182488043000035],
            [-80.872219658999938, 35.182283099000074],
            [-80.872174292999944, 35.182244638000043],
            [-80.871985590999941, 35.182086995000077],
            [-80.87173549399995, 35.181876173000035],
            [-80.871521495999957, 35.181692595000072],
            [-80.871507009999959, 35.181680226000026],
            [-80.871187806999956, 35.181408976000057],
            [-80.871167256999968, 35.181391629000075],
            [-80.870768219999945, 35.181057030000034],
            [-80.870750949999945, 35.181042630000036],
            [-80.870543122999948, 35.180870293000055],
            [-80.87036236299997, 35.180714291000072],
            [-80.870326900999942, 35.180684039000027],
            [-80.869831399999953, 35.180266194000069],
            [-80.869806384999947, 35.180245270000057],
            [-80.869562861999952, 35.180043204000071],
            [-80.869084123999983, 35.179638092000062],
            [-80.869039429999987, 35.179600813000036],
            [-80.868787254999972, 35.179393486000038],
            [-80.868617193999967, 35.17924752700003],
            [-80.86857519199998, 35.179211967000072],
            [-80.868362476999948, 35.179034319000039],
            [-80.868185230999984, 35.178882435000048],
            [-80.86815753999997, 35.178858919000049],
            [-80.867906344999938, 35.17864751500008],
            [-80.867702723999969, 35.178473033000046],
            [-80.867689030999941, 35.178461353000046],
            [-80.867181040999981, 35.178029932000072],
            [-80.866949649999981, 35.17782875100005],
            [-80.866353029999971, 35.177306590000057],
            [-80.866304943999978, 35.177248749000057],
            [-80.866259439999965, 35.177185274000067],
            [-80.866221863999954, 35.177135013000054],
            [-80.865878709999947, 35.176684272000045],
            [-80.86567387499997, 35.17640435900006],
            [-80.865670780999949, 35.17640013700003],
            [-80.865661881999984, 35.176388067000062],
            [-80.865415230999986, 35.176054982000039],
            [-80.86500313099998, 35.175494972000024],
            [-80.864850759999968, 35.175284390000058],
            [-80.864828623999983, 35.175252535000027],
            [-80.864686286999984, 35.174752260000048],
            [-80.864529826999956, 35.174143291000064],
            [-80.864404954999941, 35.173640822000038],
            [-80.864399970999955, 35.173621050000065],
            [-80.863978158999942, 35.171970633000058],
            [-80.863970504999941, 35.171941290000063],
            [-80.863899594999964, 35.171711201000051],
            [-80.863810509999951, 35.171485413000028],
            [-80.863703631999954, 35.171264893000057],
            [-80.863579418999961, 35.171050584000056],
            [-80.863438402999975, 35.170843405000028],
            [-80.863281186999984, 35.170644242000037],
            [-80.863108443999977, 35.170453949000034],
            [-80.86292091599995, 35.170273339000062],
            [-80.86271940499995, 35.170103187000052],
            [-80.862504772999955, 35.169944221000037],
            [-80.862277938999966, 35.169797122000034],
            [-80.862039875999983, 35.169662519000042],
            [-80.861791600999936, 35.169540988000051],
            [-80.861534178999989, 35.169433052000045],
            [-80.861268711999969, 35.169339170000057],
            [-80.860996335999971, 35.16925974600008],
            [-80.860718217999988, 35.169195118000061],
            [-80.860435547999941, 35.169145565000065],
            [-80.860149536999984, 35.169111297000029],
            [-80.859861407999972, 35.169092463000027],
            [-80.859572396999965, 35.169089143000065]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 140,
        "SolutionID": "PI-27:X",
        "Shape_Length": 0.072486971120467744,
        "Shape_Area": 0.0002405891545343963
      }
    },
    {
      "type": "Feature",
      "id": 141,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.841538247999949, 35.346088907000023],
            [-80.841501247999986, 35.346362907000071],
            [-80.841466247999961, 35.346674906000032],
            [-80.84143624799998, 35.34698690700003],
            [-80.841366247999986, 35.34772990700003],
            [-80.841266247999954, 35.348601907000045],
            [-80.841184247999934, 35.349397907000025],
            [-80.841130247999956, 35.349863908000032],
            [-80.841086247999954, 35.350323907000075],
            [-80.840942247999976, 35.35161690800004],
            [-80.840878247999967, 35.352187909000065],
            [-80.840802247999989, 35.352873908000049],
            [-80.840684248999935, 35.353844909000031],
            [-80.840672246999986, 35.35394990900005],
            [-80.84064824799998, 35.354147908000073],
            [-80.840608247999967, 35.354593909000073],
            [-80.840454247999958, 35.356129908000071],
            [-80.840329247999989, 35.357258909000052],
            [-80.840292247999969, 35.357636909000064],
            [-80.840264247999983, 35.358011909000027],
            [-80.840174247999983, 35.358830909000062],
            [-80.839839247999976, 35.359328910000045],
            [-80.839475247999985, 35.359904910000068],
            [-80.838867247999985, 35.360966910000059],
            [-80.838605247999965, 35.361577910000051],
            [-80.838380247999964, 35.362696911000057],
            [-80.838371247999987, 35.363030911000067],
            [-80.838366246999954, 35.363188911000066],
            [-80.838359247999961, 35.363503910000077],
            [-80.838448246999974, 35.364117911000051],
            [-80.838479247999942, 35.364327911000032],
            [-80.838511247999975, 35.364544910000063],
            [-80.838533247999976, 35.364690910000036],
            [-80.838900247999959, 35.365658911000025],
            [-80.838940247999972, 35.365763911000045],
            [-80.839313248999986, 35.366409911000062],
            [-80.839599247999956, 35.36690591100006],
            [-80.839776247999964, 35.36721291200007],
            [-80.840318248999949, 35.368015911000043],
            [-80.840783248999969, 35.368553911000049],
            [-80.840925248999952, 35.369062911000071],
            [-80.84118024899999, 35.369933912000079],
            [-80.842256249999934, 35.373728912000047],
            [-80.84308324999995, 35.376645913000061],
            [-80.844964250999965, 35.376458913000079],
            [-80.845338249999941, 35.376424913000051],
            [-80.847063251999941, 35.376251913000033],
            [-80.847508251999955, 35.37620791300003],
            [-80.853866253999968, 35.37560691300007],
            [-80.854165252999962, 35.375584913000068],
            [-80.854194252999946, 35.375382912000077],
            [-80.854450252999982, 35.37385091200008],
            [-80.854625252999938, 35.372838912000077],
            [-80.854687252999952, 35.372445912000046],
            [-80.85474725399996, 35.372173912000051],
            [-80.854812252999977, 35.371954912000035],
            [-80.854901253999969, 35.371711911000034],
            [-80.85500725299994, 35.371474912000053],
            [-80.855492252999966, 35.370564912000077],
            [-80.856462252999961, 35.368695911000032],
            [-80.856499253999971, 35.368275912000058],
            [-80.85647325299999, 35.367982912000059],
            [-80.856298252999977, 35.367475911000042],
            [-80.855906252999944, 35.366825911000035],
            [-80.855972253999937, 35.36681591100006],
            [-80.856223253999985, 35.366758911000034],
            [-80.856337253999982, 35.366727911000055],
            [-80.856368252999971, 35.366719910000029],
            [-80.856566253999972, 35.366659911000056],
            [-80.857023253999955, 35.366487910000046],
            [-80.857228253999949, 35.366396910000049],
            [-80.857422253999971, 35.366303910000056],
            [-80.858200253999939, 35.365909911000074],
            [-80.858537253999941, 35.365751910000029],
            [-80.85868325499996, 35.365693910000061],
            [-80.858801254999946, 35.365635910000037],
            [-80.85887125499994, 35.365596910000079],
            [-80.858907253999973, 35.365575910000075],
            [-80.859030254999936, 35.365489911000054],
            [-80.859274253999956, 35.365301910000028],
            [-80.862499254999989, 35.362748909000061],
            [-80.863367254999957, 35.36205491000004],
            [-80.863977254999952, 35.361532908000072],
            [-80.864452254999946, 35.361125909000066],
            [-80.864738254999963, 35.360880910000049],
            [-80.865001255999971, 35.360665909000033],
            [-80.865418254999952, 35.360336909000068],
            [-80.865875255999981, 35.35999190800004],
            [-80.868500255999948, 35.358075909000036],
            [-80.869571256999961, 35.357268908000037],
            [-80.869514255999945, 35.357186908000074],
            [-80.86834825599999, 35.355243908000034],
            [-80.868200255999966, 35.35500490700008],
            [-80.868100255999934, 35.354864907000035],
            [-80.867984255999943, 35.354724908000037],
            [-80.867802255999948, 35.354538908000052],
            [-80.867598255999951, 35.354349907000028],
            [-80.867312255999934, 35.354110908000052],
            [-80.867044255999986, 35.353873908000025],
            [-80.866626255999961, 35.353590907000068],
            [-80.866236255999979, 35.353231907000065],
            [-80.86599625599996, 35.352981907000071],
            [-80.865936255999941, 35.352912907000075],
            [-80.865487255999938, 35.35239690800006],
            [-80.865139254999974, 35.351964907000024],
            [-80.865053254999964, 35.351857907000067],
            [-80.864801254999975, 35.351545908000048],
            [-80.864585254999952, 35.351305907000039],
            [-80.864436254999987, 35.35112190600006],
            [-80.863815254999963, 35.350445907000051],
            [-80.863703254999962, 35.350320906000036],
            [-80.862517254999943, 35.348927907000075],
            [-80.86235725399996, 35.348693906000051],
            [-80.862462253999979, 35.348394906000067],
            [-80.862524254999983, 35.348307907000049],
            [-80.862567253999941, 35.348246907000032],
            [-80.86258625499994, 35.348197906000053],
            [-80.86258625499994, 35.348110907000034],
            [-80.862613253999939, 35.348020906000045],
            [-80.862684253999987, 35.347948907000045],
            [-80.862771253999938, 35.347796906000042],
            [-80.862778254999967, 35.347755906000032],
            [-80.86277025399994, 35.347710906000032],
            [-80.862771253999938, 35.347676906000061],
            [-80.862788253999952, 35.347652906000064],
            [-80.862818254999979, 35.347624907000068],
            [-80.862844253999981, 35.347592906000045],
            [-80.862889253999981, 35.347514906000072],
            [-80.862896254999953, 35.347465907000071],
            [-80.862891254999965, 35.347426906000067],
            [-80.862899253999956, 35.347390907000033],
            [-80.862918254999954, 35.347346907000031],
            [-80.862931253999989, 35.347291906000066],
            [-80.86291625399997, 35.347210906000043],
            [-80.862918253999965, 35.347169906000033],
            [-80.862966254999947, 35.347100906000037],
            [-80.862971253999945, 35.347070906000056],
            [-80.862969254999939, 35.34703890600008],
            [-80.862977253999986, 35.346992906000025],
            [-80.863013254999942, 35.346888906000061],
            [-80.863037253999948, 35.346840906000068],
            [-80.863078253999959, 35.346785907000026],
            [-80.863113254999973, 35.346705907000057],
            [-80.863123253999959, 35.34665990600007],
            [-80.863136253999983, 35.346508907000043],
            [-80.863196253999945, 35.346314906000032],
            [-80.863216253999951, 35.346226906000027],
            [-80.863215253999954, 35.346173906000047],
            [-80.863236253999958, 35.34608690500005],
            [-80.863241253999945, 35.346022907000076],
            [-80.863270253999985, 35.345926906000045],
            [-80.86327625399997, 35.345878906000053],
            [-80.863258253999959, 35.345857906000049],
            [-80.86328025499995, 35.345800906000079],
            [-80.863328254999942, 35.345723906000046],
            [-80.863326254999947, 35.345692906000068],
            [-80.863364254999965, 35.345576906000076],
            [-80.863446253999939, 35.34542590500007],
            [-80.86347125399999, 35.345359906000056],
            [-80.863486253999952, 35.345302906000029],
            [-80.86350125499996, 35.345254906000036],
            [-80.863424254999984, 35.345259906000024],
            [-80.863376253999945, 35.345255906000034],
            [-80.863278254999955, 35.345296906000044],
            [-80.863202253999987, 35.345317906000048],
            [-80.863101253999957, 35.345361906000051],
            [-80.863076252999974, 35.345378906000064],
            [-80.863052254999957, 35.345393906000027],
            [-80.862985254999955, 35.345419906000075],
            [-80.862891254999965, 35.345445906000066],
            [-80.86273225399998, 35.345493906000058],
            [-80.862652253999954, 35.345520906000047],
            [-80.862589252999953, 35.345536906000063],
            [-80.862462254999969, 35.34556490600005],
            [-80.862298253999938, 35.345589906000043],
            [-80.862180253999952, 35.34562190500003],
            [-80.862106253999968, 35.34564690600007],
            [-80.861981253999943, 35.345694906000062],
            [-80.861898253999982, 35.34573290600008],
            [-80.86174525399997, 35.34579390600004],
            [-80.861717253999984, 35.345805906000066],
            [-80.861567253999965, 35.345853906000059],
            [-80.86144225299995, 35.345948906000046],
            [-80.861316253999973, 35.345996906000039],
            [-80.861182253999971, 35.346016906000045],
            [-80.861102253999945, 35.346025907000069],
            [-80.861003253999968, 35.346000906000029],
            [-80.860979253999972, 35.345979906000025],
            [-80.860884253999984, 35.345913906000078],
            [-80.860767253999938, 35.345847906000074],
            [-80.86069925399994, 35.34579390600004],
            [-80.860557252999968, 35.345697906000055],
            [-80.860373252999977, 35.345572906000029],
            [-80.860295253999936, 35.345520905000058],
            [-80.860162252999942, 35.345421907000059],
            [-80.860094252999943, 35.345374906000075],
            [-80.860045253999942, 35.345337906000054],
            [-80.860010252999984, 35.345313906000058],
            [-80.859939252999936, 35.345258906000026],
            [-80.859772253999949, 35.34513690600005],
            [-80.859689252999942, 35.345086906000063],
            [-80.85963725299996, 35.345058906000077],
            [-80.859461252999949, 35.344967906000079],
            [-80.859083251999948, 35.34478090600004],
            [-80.859014253999987, 35.344733906000044],
            [-80.858969252999941, 35.344701905000079],
            [-80.858871252999961, 35.344640906000052],
            [-80.858827252999959, 35.34462290700003],
            [-80.858675252999944, 35.344549905000065],
            [-80.858551251999984, 35.344497906000072],
            [-80.858490252999957, 35.34444290600004],
            [-80.858208252999987, 35.344294906000073],
            [-80.858118252999986, 35.344230906000064],
            [-80.858035251999979, 35.344189906000054],
            [-80.857950251999966, 35.344166906000055],
            [-80.857902252999963, 35.344158905000029],
            [-80.857850252999981, 35.344147905000057],
            [-80.85779125199997, 35.34412790600004],
            [-80.857742252999969, 35.344134906000079],
            [-80.857694251999988, 35.344120905000068],
            [-80.857617251999955, 35.344056907000038],
            [-80.857526252999946, 35.344004905000077],
            [-80.857328252999935, 35.343986906000055],
            [-80.857149251999942, 35.343946906000042],
            [-80.857051251999962, 35.343928906000031],
            [-80.856992251999941, 35.343908906000024],
            [-80.856923252999934, 35.343891906000067],
            [-80.856863251999982, 35.34387990700003],
            [-80.856812252999987, 35.343873905000066],
            [-80.856751251999981, 35.343856906000042],
            [-80.856699251999942, 35.343834906000041],
            [-80.856516252999938, 35.343791905000046],
            [-80.856365251999989, 35.343717906000052],
            [-80.856302251999978, 35.343714906000059],
            [-80.856277251999984, 35.343700906000038],
            [-80.856190252999966, 35.343674906000047],
            [-80.856041251999955, 35.34364690600006],
            [-80.855951251999954, 35.343624906000059],
            [-80.855898251999974, 35.343621906000067],
            [-80.855843250999953, 35.343610905000048],
            [-80.855794252999942, 35.343606906000048],
            [-80.855721252999956, 35.343597905000024],
            [-80.855625250999935, 35.343589906000034],
            [-80.85551725199997, 35.343578906000062],
            [-80.85547625099997, 35.343575906000069],
            [-80.855434251999952, 35.343579906000059],
            [-80.855320251999956, 35.343578905000072],
            [-80.855299251999952, 35.34356490600004],
            [-80.855281251999941, 35.343545906000031],
            [-80.855241251999985, 35.343535905000067],
            [-80.85519425199999, 35.343530906000069],
            [-80.855165251999949, 35.343520906000037],
            [-80.855027250999967, 35.343499906000034],
            [-80.854992251999988, 35.343487906000064],
            [-80.854908250999983, 35.343480905000035],
            [-80.854783251999947, 35.343448906000049],
            [-80.854733250999971, 35.343446906000054],
            [-80.854693251999947, 35.343434906000027],
            [-80.85464725199995, 35.343432906000032],
            [-80.85459825099997, 35.343439905000025],
            [-80.854546251999977, 35.343436906000079],
            [-80.854499251999982, 35.343427906000045],
            [-80.85440325199994, 35.343390906000025],
            [-80.854296251999983, 35.343358906000049],
            [-80.854239251999957, 35.343334907000042],
            [-80.854192250999972, 35.343324906000078],
            [-80.85414825099997, 35.34332390600008],
            [-80.854066250999949, 35.343299905000038],
            [-80.854022250999947, 35.343279906000078],
            [-80.853975250999952, 35.343265906000056],
            [-80.853920251999966, 35.343258905000027],
            [-80.85385525199996, 35.343265905000067],
            [-80.853795250999951, 35.343260905000079],
            [-80.853543250999962, 35.34318890600008],
            [-80.853445250999982, 35.343150905000073],
            [-80.853301250999948, 35.343121906000079],
            [-80.853205250999963, 35.343084906000058],
            [-80.853153250999981, 35.343052905000036],
            [-80.85306325099998, 35.343017906000057],
            [-80.853003251999951, 35.342970905000072],
            [-80.852959250999959, 35.342944906000071],
            [-80.852909250999971, 35.342923906000067],
            [-80.852849250999952, 35.342902906000063],
            [-80.852769250999984, 35.342867906000038],
            [-80.852701250999985, 35.342840906000049],
            [-80.852651250999941, 35.342818906000048],
            [-80.852623250999955, 35.342801905000044],
            [-80.852573250999967, 35.342761906000078],
            [-80.85247825099998, 35.342707905000054],
            [-80.85239224999998, 35.342643906000035],
            [-80.852332249999961, 35.342605905000028],
            [-80.852176249999957, 35.342532906000031],
            [-80.852128250999954, 35.342493906000072],
            [-80.852068250999935, 35.34246190500005],
            [-80.851877250999962, 35.342332906000024],
            [-80.851678249999964, 35.342167905000053],
            [-80.851593249999951, 35.342143906000047],
            [-80.851541249999968, 35.342121906000045],
            [-80.851495249999971, 35.342107905000034],
            [-80.85144625099997, 35.342049905000067],
            [-80.851392250999936, 35.342006905000062],
            [-80.851354250999975, 35.341989906000038],
            [-80.851237250999986, 35.341932905000078],
            [-80.851070250999953, 35.341832906000036],
            [-80.850754249999966, 35.341687906000061],
            [-80.850596249999967, 35.341647905000059],
            [-80.850426249999941, 35.341607905000046],
            [-80.85039625099995, 35.341590906000079],
            [-80.850304249999965, 35.34157990600005],
            [-80.850251249999985, 35.341564906000031],
            [-80.850136249999935, 35.341554906000056],
            [-80.850061249999953, 35.341536906000044],
            [-80.850022249999938, 35.341509905000066],
            [-80.849859249999952, 35.341476906000025],
            [-80.849737248999986, 35.341447905000052],
            [-80.849630249999962, 35.341426905000048],
            [-80.849529249999989, 35.341417905000071],
            [-80.849463249999985, 35.341393905000075],
            [-80.849323250999987, 35.341356906000044],
            [-80.848987249999936, 35.341150905000063],
            [-80.849029249999944, 35.339797905000069],
            [-80.849016248999988, 35.338614905000043],
            [-80.848997248999979, 35.338168904000042],
            [-80.848996248999981, 35.338015905000077],
            [-80.848902249999981, 35.336354904000075],
            [-80.848890249999954, 35.336064904000068],
            [-80.848872248999953, 35.335784904000036],
            [-80.848828248999951, 35.335025904000076],
            [-80.848799249999956, 35.334616904000029],
            [-80.848757249999949, 35.33385090400003],
            [-80.848318248999988, 35.333908904000054],
            [-80.846675248999986, 35.334133904000055],
            [-80.845706248999988, 35.334265905000052],
            [-80.845415247999938, 35.334313904000055],
            [-80.845385247999957, 35.334321905000024],
            [-80.84527924799994, 35.334349904000078],
            [-80.844901247999985, 35.334474905000036],
            [-80.844753247999961, 35.334530904000076],
            [-80.844542248999971, 35.334623904000068],
            [-80.844467247999944, 35.334659903000045],
            [-80.844431247999978, 35.334678904000043],
            [-80.844373247999954, 35.334708904000024],
            [-80.843267247999961, 35.335338905000071],
            [-80.843024248999939, 35.335485905000041],
            [-80.842947247999973, 35.335560904000033],
            [-80.842826247999938, 35.335649905000025],
            [-80.842736246999948, 35.33569990500007],
            [-80.842654247999974, 35.335735904000046],
            [-80.842437247999953, 35.337750905000064],
            [-80.842349246999959, 35.338509905000024],
            [-80.84221324799995, 35.339801905000058],
            [-80.842153247999988, 35.340353905000029],
            [-80.842103247999944, 35.340899905000072],
            [-80.842000246999987, 35.341815905000033],
            [-80.841814247999935, 35.343582907000041],
            [-80.841744247999941, 35.344186907000051],
            [-80.841538247999949, 35.346088907000023]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 141,
        "SolutionID": "PI-29:A",
        "Shape_Length": 0.12441929195955422,
        "Shape_Area": 0.00074371064008093516
      }
    },
    {
      "type": "Feature",
      "id": 142,
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-80.834132247999946, 35.382744916000036],
              [-80.83421724699997, 35.383565915000077],
              [-80.835030248999942, 35.383299914000077],
              [-80.835683247999953, 35.383086915000035],
              [-80.837083247999942, 35.382631915000047],
              [-80.84177724999995, 35.381115914000077],
              [-80.842581249999967, 35.380856915000038],
              [-80.84414124999995, 35.380333913000072],
              [-80.84308324999995, 35.376645913000061],
              [-80.844964250999965, 35.376458913000079],
              [-80.845338249999941, 35.376424913000051],
              [-80.847063251999941, 35.376251913000033],
              [-80.847508251999955, 35.37620791300003],
              [-80.853866253999968, 35.37560691300007],
              [-80.854165252999962, 35.375584913000068],
              [-80.854194252999946, 35.375382912000077],
              [-80.854450252999982, 35.37385091200008],
              [-80.854625252999938, 35.372838912000077],
              [-80.854687252999952, 35.372445912000046],
              [-80.85474725399996, 35.372173912000051],
              [-80.854812252999977, 35.371954912000035],
              [-80.854901253999969, 35.371711911000034],
              [-80.85500725299994, 35.371474912000053],
              [-80.855492252999966, 35.370564912000077],
              [-80.856462252999961, 35.368695911000032],
              [-80.856499253999971, 35.368275912000058],
              [-80.85647325299999, 35.367982912000059],
              [-80.856298252999977, 35.367475911000042],
              [-80.855906252999944, 35.366825911000035],
              [-80.855825252999978, 35.366837910000072],
              [-80.855620252999984, 35.366853911000078],
              [-80.85544925399995, 35.366856910000024],
              [-80.855395252999983, 35.366854911000075],
              [-80.855298252999944, 35.366851911000026],
              [-80.855123251999942, 35.36683891000007],
              [-80.854922252999984, 35.366816910000068],
              [-80.854644252999947, 35.36677891100004],
              [-80.853643252999973, 35.366628911000078],
              [-80.85269525199999, 35.366463911000039],
              [-80.852489251999941, 35.366435911000053],
              [-80.851776251999979, 35.366369911000049],
              [-80.850943251999979, 35.36630591100004],
              [-80.850530251999942, 35.366274910000072],
              [-80.849587250999946, 35.366192910000052],
              [-80.849233250999987, 35.366167911000048],
              [-80.849068250999949, 35.366160911000065],
              [-80.84891425099994, 35.366160911000065],
              [-80.848784250999984, 35.366167911000048],
              [-80.848532250999938, 35.366196911000031],
              [-80.847870250999961, 35.366319911000062],
              [-80.846682249999958, 35.366550911000047],
              [-80.846228249999967, 35.366632911000067],
              [-80.845420249999961, 35.36677891100004],
              [-80.845138248999945, 35.366835910000077],
              [-80.844874250999965, 35.366896911000026],
              [-80.844637249999948, 35.366968911000072],
              [-80.844483249999939, 35.367025911000042],
              [-80.844294249999962, 35.367112911000049],
              [-80.844057249999935, 35.367244911000057],
              [-80.843761249999943, 35.367414912000072],
              [-80.843698249999989, 35.367451912000035],
              [-80.843545248999988, 35.367541911000046],
              [-80.843060249999951, 35.367830911000055],
              [-80.84257624899999, 35.368119912000054],
              [-80.842105248999985, 35.368396911000048],
              [-80.841551248999963, 35.368723912000064],
              [-80.841224248999936, 35.368910911000057],
              [-80.840925248999952, 35.369062911000071],
              [-80.839159248999977, 35.369913912000072],
              [-80.838736248999965, 35.370107912000037],
              [-80.838531247999981, 35.370189912000058],
              [-80.83834924699994, 35.370249912000077],
              [-80.83818324799995, 35.370291912000027],
              [-80.838012247999984, 35.370325913000045],
              [-80.837812247999977, 35.370350912000049],
              [-80.837564247999978, 35.370370912000055],
              [-80.837265247999937, 35.370386912000072],
              [-80.83698224799997, 35.370393912000054],
              [-80.834968247999939, 35.370380913000076],
              [-80.833984246999989, 35.370381912000028],
              [-80.833860247999951, 35.370378912000035],
              [-80.833770245999972, 35.370377913000027],
              [-80.833201246999977, 35.370350912000049],
              [-80.832701246999989, 35.370312912000031],
              [-80.832261245999973, 35.370272912000075],
              [-80.831521245999966, 35.370195912000042],
              [-80.831443245999935, 35.370191912000053],
              [-80.830877245999943, 35.37015591200003],
              [-80.830042244999959, 35.370074912000064],
              [-80.829789244999972, 35.370050912000067],
              [-80.828941245999943, 35.369943913000043],
              [-80.828499244999989, 35.369888913000068],
              [-80.827900244999967, 35.369805912000061],
              [-80.827738243999988, 35.369777913000064],
              [-80.827530243999945, 35.369729913000072],
              [-80.827260244999934, 35.369655912000042],
              [-80.826294244999985, 35.369358912000052],
              [-80.824661243999969, 35.368832912000073],
              [-80.823581243999968, 35.36847791200006],
              [-80.823544243999947, 35.368586912000069],
              [-80.823497243999952, 35.368723913000053],
              [-80.822971243999973, 35.370305913000038],
              [-80.822651242999939, 35.371220913000059],
              [-80.821786243999952, 35.37372991400008],
              [-80.821597242999985, 35.374239914000043],
              [-80.821413242999938, 35.374778913000057],
              [-80.821367242999941, 35.374976914000058],
              [-80.821311243999958, 35.375296914000046],
              [-80.82125724399998, 35.375657914000044],
              [-80.820985243999985, 35.377457914000047],
              [-80.820952242999965, 35.377691914000025],
              [-80.820825243999934, 35.378599914000063],
              [-80.820803242999943, 35.378719915000033],
              [-80.820765243999972, 35.378872914000056],
              [-80.820707242999958, 35.379063914000028],
              [-80.820651243999976, 35.37920091400008],
              [-80.82044024399994, 35.37961791500004],
              [-80.821949243999939, 35.380241914000067],
              [-80.822677243999976, 35.381303915000046],
              [-80.823587243999953, 35.380902915000036],
              [-80.824647244999937, 35.381089916000064],
              [-80.824807244999988, 35.381477915000062],
              [-80.824864244999958, 35.381501914000069],
              [-80.827469245999964, 35.382502914000042],
              [-80.827987245999964, 35.382702915000039],
              [-80.829051245999949, 35.383109915000034],
              [-80.829186245999949, 35.382619915000078],
              [-80.830422245999955, 35.382975915000031],
              [-80.833855247999963, 35.382726915000035],
              [-80.834132247999946, 35.382744916000036]
            ]
          ],
          [
            [
              [-80.786112233999972, 35.381666916000029],
              [-80.786140232999969, 35.381821916000035],
              [-80.786258232999955, 35.382450917000028],
              [-80.786374233999936, 35.38306491600008],
              [-80.786556232999942, 35.383994917000052],
              [-80.786603233999983, 35.384203917000036],
              [-80.786639233999949, 35.38431891700003],
              [-80.786700233999966, 35.384445916000061],
              [-80.787773233999985, 35.383361917000059],
              [-80.788118233999967, 35.383049916000061],
              [-80.788435233999962, 35.382791916000031],
              [-80.788806233999935, 35.382512917000042],
              [-80.788673234999976, 35.382364916000029],
              [-80.788323233999961, 35.382013917000052],
              [-80.788210233999962, 35.381930916000044],
              [-80.788088233999986, 35.381868917000077],
              [-80.787945233999949, 35.381812917000047],
              [-80.787829233999958, 35.381777916000033],
              [-80.787695233999955, 35.381757916000026],
              [-80.787321232999943, 35.381732917000079],
              [-80.787354233999963, 35.381476917000043],
              [-80.787439232999986, 35.380923917000075],
              [-80.787469232999968, 35.380810917000076],
              [-80.787508232999983, 35.380710916000055],
              [-80.787727233999988, 35.380317916000024],
              [-80.787834233999945, 35.380178916000034],
              [-80.787908233999985, 35.380093916000078],
              [-80.788009233999958, 35.379998916000034],
              [-80.788090233999981, 35.37992891600004],
              [-80.788184233999971, 35.379866915000036],
              [-80.788461234999943, 35.379717916000061],
              [-80.788380233999987, 35.379580915000076],
              [-80.788296233999972, 35.379456916000038],
              [-80.788257233999957, 35.379368916000033],
              [-80.788227233999976, 35.379285916000072],
              [-80.788225233999981, 35.379225916000053],
              [-80.788340233999975, 35.378968916000076],
              [-80.789500232999956, 35.376610915000072],
              [-80.789552234999974, 35.376530915000046],
              [-80.789612233999947, 35.376476915000069],
              [-80.789875234999954, 35.376303915000051],
              [-80.790028233999976, 35.376196914000047],
              [-80.790190233999965, 35.376067915000078],
              [-80.789804233999973, 35.375845914000024],
              [-80.789889233999986, 35.375686916000063],
              [-80.790138234999972, 35.375176915000054],
              [-80.790268233999939, 35.374897915000076],
              [-80.790462233999961, 35.374511915000028],
              [-80.790615233999972, 35.374188914000058],
              [-80.790985233999947, 35.373388914000031],
              [-80.791253233999953, 35.373446914000056],
              [-80.791601234999973, 35.373552914000072],
              [-80.791984234999973, 35.373705915000073],
              [-80.792397234999953, 35.373879914000042],
              [-80.792819234999968, 35.374084915000026],
              [-80.793234234999943, 35.374280915000043],
              [-80.793300234999947, 35.37415091500003],
              [-80.793362234999961, 35.374043914000026],
              [-80.793407234999961, 35.373948914000039],
              [-80.79347523499996, 35.373829915000044],
              [-80.793616233999956, 35.373562914000047],
              [-80.793684234999944, 35.373454915000025],
              [-80.793838234999953, 35.373247914000046],
              [-80.79392923499995, 35.373298914000031],
              [-80.794324234999976, 35.373487914000066],
              [-80.794597235999959, 35.373596915000064],
              [-80.795023234999974, 35.373707914000079],
              [-80.795480235999946, 35.373809915000038],
              [-80.795690235999984, 35.373861914000031],
              [-80.79575723499994, 35.37386891400007],
              [-80.795836235999957, 35.373865914000078],
              [-80.795928235999952, 35.373853915000041],
              [-80.796176235999951, 35.373786915000039],
              [-80.796212235999974, 35.373861914000031],
              [-80.796271235999939, 35.373937914000066],
              [-80.796316235999939, 35.373975914000027],
              [-80.796381235999945, 35.374014914000043],
              [-80.796468236999942, 35.37404891500006],
              [-80.796554235999963, 35.374076914000057],
              [-80.796931235999978, 35.374166915000046],
              [-80.797451236999962, 35.374244915000077],
              [-80.797468235999986, 35.374094914000068],
              [-80.797462235999944, 35.374016914000038],
              [-80.797446236999974, 35.373914914000068],
              [-80.797416235999947, 35.373811914000044],
              [-80.797039235999989, 35.372922914000071],
              [-80.796997235999982, 35.372774914000047],
              [-80.796976235999978, 35.372682915000041],
              [-80.796966234999957, 35.372515914000076],
              [-80.796959235999964, 35.37238691400006],
              [-80.796958235999966, 35.372212914000045],
              [-80.796933235999973, 35.372133914000074],
              [-80.796915235999961, 35.372047913000074],
              [-80.796870235999961, 35.371958914000061],
              [-80.796635235999986, 35.371587914000031],
              [-80.796389234999936, 35.371225914000036],
              [-80.796325234999983, 35.371120914000073],
              [-80.796280235999973, 35.371011914000064],
              [-80.796123235999971, 35.370414914000037],
              [-80.796087234999959, 35.370324914000037],
              [-80.796038234999969, 35.370239914000024],
              [-80.795949234999966, 35.370134913000072],
              [-80.79568123599995, 35.369461914000055],
              [-80.794869234999965, 35.369124913000064],
              [-80.792751234999969, 35.368284913000025],
              [-80.787396232999981, 35.366161913000042],
              [-80.787087232999966, 35.367248913000026],
              [-80.786982232999947, 35.367620913000053],
              [-80.786934232999954, 35.367773913000065],
              [-80.786891231999959, 35.36788791400005],
              [-80.786838232999969, 35.368000914000049],
              [-80.786786232999987, 35.368091913000058],
              [-80.786619231999964, 35.368341913000052],
              [-80.785950232999937, 35.369276914000068],
              [-80.785749231999944, 35.369558914000038],
              [-80.785350231999985, 35.370138913000062],
              [-80.785086231999969, 35.370557914000074],
              [-80.784972231999973, 35.370762914000068],
              [-80.78488723199996, 35.370932914000036],
              [-80.784819231999961, 35.371098914000072],
              [-80.784758231999945, 35.371274914000026],
              [-80.784708232999947, 35.371461914000065],
              [-80.784672231999934, 35.371645914000055],
              [-80.784647231999941, 35.371820914000068],
              [-80.784640231999958, 35.371903915000075],
              [-80.784645231999946, 35.372131915000068],
              [-80.784658232999959, 35.372251915000049],
              [-80.784684231999961, 35.37241991500008],
              [-80.784763232999978, 35.372854915000062],
              [-80.784020231999989, 35.372967914000071],
              [-80.783845231999976, 35.372981914000036],
              [-80.783657231999939, 35.372989915000062],
              [-80.783514231999959, 35.372989915000062],
              [-80.783321231999935, 35.372982914000033],
              [-80.783093231999942, 35.372950915000047],
              [-80.782883231999961, 35.372905915000047],
              [-80.782674230999987, 35.372850915000072],
              [-80.782419230999949, 35.372767914000065],
              [-80.782275231999961, 35.372698915000058],
              [-80.782121231999952, 35.372634915000049],
              [-80.781961231999958, 35.372581915000069],
              [-80.781785231999947, 35.372536915000069],
              [-80.781553230999975, 35.372494915000061],
              [-80.781388230999937, 35.372479914000053],
              [-80.781203231999939, 35.372475914000063],
              [-80.780962231999979, 35.372490915000071],
              [-80.780787230999977, 35.372517914000071],
              [-80.780521230999966, 35.372581914000079],
              [-80.779601230999958, 35.372850915000072],
              [-80.779266230999951, 35.372948915000052],
              [-80.779093229999944, 35.372992915000054],
              [-80.77790023099999, 35.373335914000052],
              [-80.778098230999944, 35.373904915000026],
              [-80.778124230999936, 35.373976915000071],
              [-80.778131229999985, 35.374045916000057],
              [-80.77813722999997, 35.374180915000068],
              [-80.778160230999958, 35.374286915000027],
              [-80.778223229999981, 35.37440091600007],
              [-80.778302230999941, 35.374474915000064],
              [-80.778374230999987, 35.374519914000075],
              [-80.778466229999935, 35.374557916000072],
              [-80.778573230999939, 35.374602915000025],
              [-80.778679230999956, 35.374647915000025],
              [-80.778920230999972, 35.374747915000057],
              [-80.779128230999959, 35.374834915000065],
              [-80.779265230999954, 35.374891915000035],
              [-80.779513229999964, 35.374995915000056],
              [-80.779710230999967, 35.375078915000074],
              [-80.779951230999984, 35.375186915000029],
              [-80.780080230999943, 35.37524591500005],
              [-80.780143230999954, 35.375298916000077],
              [-80.78029823199995, 35.375461915000074],
              [-80.780483230999948, 35.37563691500003],
              [-80.780667231999985, 35.375789915000041],
              [-80.780710230999944, 35.375820916000066],
              [-80.78062423099999, 35.375878915000044],
              [-80.780553230999942, 35.375938916000052],
              [-80.78050923099994, 35.375991916000032],
              [-80.78045623099996, 35.376073915000063],
              [-80.780035231999989, 35.377064916000052],
              [-80.780018230999985, 35.377117915000042],
              [-80.780019230999983, 35.377168916000073],
              [-80.780115230999968, 35.377669916000059],
              [-80.779945230999942, 35.377761916000054],
              [-80.779815230999986, 35.377995915000042],
              [-80.779677230999937, 35.378317916000071],
              [-80.779516230999945, 35.378698916000076],
              [-80.779502230999981, 35.378953916000057],
              [-80.779536230999952, 35.379037917000062],
              [-80.779665231999957, 35.379168916000026],
              [-80.779858231999981, 35.379297916000041],
              [-80.780334230999983, 35.379612916000042],
              [-80.780757231999985, 35.379892916000074],
              [-80.781171230999973, 35.38014991600005],
              [-80.781368231999977, 35.380199916000038],
              [-80.781625231999953, 35.380269917000078],
              [-80.782115231999967, 35.380523917000062],
              [-80.782722232999959, 35.380973917000063],
              [-80.78291623299998, 35.381855917000053],
              [-80.782924231999971, 35.381984917000068],
              [-80.783470232999946, 35.38190591700004],
              [-80.786088232999987, 35.381527916000039],
              [-80.786112233999972, 35.381666916000029]
            ]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 142,
        "SolutionID": "PI-29:B",
        "Shape_Length": 0.16548990250309834,
        "Shape_Area": 0.00053003085845849886
      }
    },
    {
      "type": "Feature",
      "id": 143,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.857382255999937, 35.404980918000035],
            [-80.857522255999982, 35.405869919000054],
            [-80.857607256999984, 35.406357919000072],
            [-80.857740255999943, 35.407182919000036],
            [-80.857819255999971, 35.407643919000066],
            [-80.85795825699995, 35.408444919000033],
            [-80.858044254999982, 35.408945919000075],
            [-80.857413256999962, 35.409146920000069],
            [-80.856911255999989, 35.409306920000063],
            [-80.856401256999959, 35.409446919000061],
            [-80.856366254999955, 35.409455919000038],
            [-80.855477255999972, 35.409700919000045],
            [-80.855334254999946, 35.40973991900006],
            [-80.85487425599996, 35.40987092000006],
            [-80.854965254999968, 35.410149920000038],
            [-80.855024254999989, 35.410314920000076],
            [-80.855401254999947, 35.411583920000055],
            [-80.856178255999964, 35.414087921000032],
            [-80.856465255999979, 35.41501192100003],
            [-80.856524255999943, 35.415204920000065],
            [-80.856579255999975, 35.415382921000059],
            [-80.856779255999982, 35.416021921000038],
            [-80.856838255999946, 35.416210921000072],
            [-80.857153255999947, 35.417201922000061],
            [-80.857342255999981, 35.417743921000067],
            [-80.857572256999958, 35.418322921000026],
            [-80.85767225699999, 35.418549922000068],
            [-80.858088255999974, 35.419412922000049],
            [-80.858676256999956, 35.420592922000026],
            [-80.858928256999945, 35.421098922000056],
            [-80.859637257999964, 35.422521922000044],
            [-80.861272257999985, 35.425813922000032],
            [-80.862235258999988, 35.427743923000037],
            [-80.862493257999972, 35.428265923000026],
            [-80.862705258999938, 35.428125923000039],
            [-80.86280125899998, 35.428069924000056],
            [-80.863261258999955, 35.427766923000036],
            [-80.863362259999974, 35.427692923000052],
            [-80.863525258999971, 35.427583923000043],
            [-80.86367525899999, 35.427493923000043],
            [-80.863811258999988, 35.427406924000024],
            [-80.863877258999935, 35.427348924000057],
            [-80.863922257999945, 35.427294923000034],
            [-80.863954258999968, 35.427281923000066],
            [-80.864108258999977, 35.427201923000041],
            [-80.864243259999967, 35.427108923000048],
            [-80.864282258999935, 35.427104923000059],
            [-80.864436258999945, 35.426981923000028],
            [-80.86447125899997, 35.426969923000058],
            [-80.864709258999937, 35.42679292300005],
            [-80.865381258999946, 35.426292923000062],
            [-80.865013259999955, 35.425669923000044],
            [-80.864908258999947, 35.425463923000052],
            [-80.864818258999946, 35.425326923000057],
            [-80.864343257999963, 35.424510923000071],
            [-80.863406258999987, 35.422934922000024],
            [-80.863185257999987, 35.422554922000074],
            [-80.862778258999981, 35.421852922000028],
            [-80.862699258999953, 35.42170892300004],
            [-80.86300125799994, 35.421605922000026],
            [-80.863858257999937, 35.421285922000038],
            [-80.864313257999981, 35.421108921000041],
            [-80.865050258999986, 35.420819921000032],
            [-80.865272259999983, 35.420011922000072],
            [-80.865347258999975, 35.419759921000036],
            [-80.865369258999976, 35.419698921000077],
            [-80.865408259999981, 35.419610921000071],
            [-80.865455258999987, 35.419523922000053],
            [-80.865539258999945, 35.419390922000048],
            [-80.86561925999996, 35.419283922000034],
            [-80.865735258999962, 35.419151922000026],
            [-80.865831258999947, 35.419062921000034],
            [-80.866069259999961, 35.418878921000044],
            [-80.866624258999934, 35.418497921000039],
            [-80.867214258999979, 35.418092921000039],
            [-80.868695259999981, 35.417051921000052],
            [-80.869499259999941, 35.416482921000068],
            [-80.869750259999989, 35.416301920000024],
            [-80.870168260999947, 35.416001920000042],
            [-80.870998260999954, 35.415417920000039],
            [-80.871109260999958, 35.415336920000072],
            [-80.871301259999939, 35.415197920000026],
            [-80.871470260999956, 35.415061920000028],
            [-80.871723260999943, 35.414830920000043],
            [-80.872393260999957, 35.414143921000061],
            [-80.872671260999937, 35.413875920000066],
            [-80.873269260999962, 35.413286920000075],
            [-80.873359260999962, 35.413176920000069],
            [-80.873443261999967, 35.413047920000054],
            [-80.873549259999947, 35.41283892000007],
            [-80.873719261999952, 35.412438920000056],
            [-80.874005260999979, 35.411702919000049],
            [-80.87417826099994, 35.411300919000041],
            [-80.874262261999945, 35.411092919000055],
            [-80.874454260999983, 35.410642919000054],
            [-80.874494261999985, 35.410547919000066],
            [-80.875089260999971, 35.409157919000052],
            [-80.87513826199995, 35.409025918000054],
            [-80.875166260999947, 35.408928919000061],
            [-80.875179260999971, 35.408811919000073],
            [-80.875176260999979, 35.408740919000024],
            [-80.875162261999947, 35.408644919000039],
            [-80.875089260999971, 35.408261918000051],
            [-80.874954260999971, 35.407765919000042],
            [-80.87480726199999, 35.407254918000035],
            [-80.874719260999939, 35.406859918000066],
            [-80.874689261999947, 35.406540919000065],
            [-80.874675260999936, 35.406294919000061],
            [-80.874676260999934, 35.406260918000044],
            [-80.874384260999989, 35.40622391800008],
            [-80.873939260999975, 35.40613391800008],
            [-80.87357326099999, 35.405977918000076],
            [-80.873253260999945, 35.405780918000062],
            [-80.873120259999951, 35.405698918000041],
            [-80.872023260999981, 35.405025918000035],
            [-80.871820259999936, 35.404894918000025],
            [-80.871642259999987, 35.404779919000077],
            [-80.871244260999958, 35.404597918000036],
            [-80.870751259999963, 35.404487918000029],
            [-80.870378259999939, 35.404474918000062],
            [-80.869925259999945, 35.404552918000036],
            [-80.869581258999972, 35.404654918000062],
            [-80.869580259999964, 35.404654918000062],
            [-80.869486258999984, 35.404670918000079],
            [-80.869373259999975, 35.404706918000045],
            [-80.869343258999947, 35.404448918000071],
            [-80.869360258999961, 35.404152918000079],
            [-80.869384258999958, 35.403960918000053],
            [-80.869508258999986, 35.402689918000078],
            [-80.869524258999945, 35.402506918000029],
            [-80.869667258999982, 35.401284918000044],
            [-80.869673258999967, 35.40120091700004],
            [-80.869682259999934, 35.401077917000066],
            [-80.869670258999975, 35.400930917000039],
            [-80.86961225899995, 35.400716917000068],
            [-80.869453258999954, 35.400280918000078],
            [-80.869347259999984, 35.399965917000031],
            [-80.86933025899998, 35.399895917000038],
            [-80.869323258999941, 35.399831918000075],
            [-80.869327258999988, 35.399725917000069],
            [-80.869353259999968, 35.399605917000031],
            [-80.869378258999973, 35.39955291800004],
            [-80.86832925799996, 35.399005917000068],
            [-80.867964258999962, 35.398823917000072],
            [-80.867874257999972, 35.398780917000067],
            [-80.867493257999968, 35.398600918000056],
            [-80.867241257999979, 35.398481917000026],
            [-80.866540257999986, 35.398139917000037],
            [-80.86610025799996, 35.397940917000028],
            [-80.865858257999946, 35.397847917000036],
            [-80.865745257999947, 35.397809917000075],
            [-80.865585257999953, 35.397766917000069],
            [-80.865190257999984, 35.397686917000044],
            [-80.865097257999935, 35.397672916000033],
            [-80.864823257999944, 35.397651917000076],
            [-80.864328257999944, 35.39762491700003],
            [-80.864032256999963, 35.39761291700006],
            [-80.864086257999986, 35.396638916000029],
            [-80.864100256999961, 35.396377917000052],
            [-80.864126256999953, 35.396095917000025],
            [-80.864267256999938, 35.39544791600008],
            [-80.864631257999974, 35.394380916000046],
            [-80.864721257999975, 35.394060915000068],
            [-80.86473925699994, 35.39396191700007],
            [-80.864754256999959, 35.393817916000046],
            [-80.864758257999938, 35.393691915000034],
            [-80.864753256999961, 35.393584916000066],
            [-80.864740256999937, 35.393462916000033],
            [-80.864717256999938, 35.393336916000067],
            [-80.864683257999957, 35.393208916000049],
            [-80.864634257999967, 35.393070916000056],
            [-80.864583257999982, 35.392954915000075],
            [-80.864512256999944, 35.392821916000059],
            [-80.864437257999953, 35.392702915000029],
            [-80.864338256999986, 35.392571916000065],
            [-80.864249256999983, 35.392468916000041],
            [-80.864149256999951, 35.392366916000071],
            [-80.864040256999942, 35.392269916000032],
            [-80.863932257999977, 35.392184916000076],
            [-80.863799255999936, 35.392092916000024],
            [-80.863666255999988, 35.392012915000066],
            [-80.863520256999948, 35.391936916000077],
            [-80.86337625699997, 35.391871916000071],
            [-80.863222256999961, 35.391813916000046],
            [-80.863078256999984, 35.391768916000046],
            [-80.862935256999947, 35.391730916000029],
            [-80.862781256999938, 35.391698916000053],
            [-80.862625256999934, 35.391675916000054],
            [-80.862437255999964, 35.391657916000042],
            [-80.862290256999984, 35.391651915000068],
            [-80.862144256999954, 35.391653916000053],
            [-80.862006255999972, 35.391660916000035],
            [-80.861830255999962, 35.391679916000044],
            [-80.86170225699999, 35.391699917000039],
            [-80.861564255999951, 35.391728916000034],
            [-80.861418255999979, 35.391765916000054],
            [-80.861299255999938, 35.391802915000028],
            [-80.86115525699995, 35.391854916000057],
            [-80.861007256999983, 35.391918915000076],
            [-80.860898255999984, 35.391971916000045],
            [-80.860748255999965, 35.392055915000071],
            [-80.860618254999963, 35.392140916000074],
            [-80.860496255999976, 35.392230915000027],
            [-80.860388255999965, 35.392321917000061],
            [-80.860293255999977, 35.392412916000069],
            [-80.860268254999937, 35.39243891600006],
            [-80.860223256999973, 35.392486916000053],
            [-80.860114255999974, 35.392420916000049],
            [-80.858470254999986, 35.39142191600007],
            [-80.85843125599996, 35.391404916000056],
            [-80.858229254999969, 35.39132491600003],
            [-80.858019255999977, 35.391258915000037],
            [-80.857804254999962, 35.391208916000039],
            [-80.857584254999949, 35.391173916000071],
            [-80.857361254999944, 35.391154915000072],
            [-80.857137254999941, 35.391152916000067],
            [-80.856792254999959, 35.391169916000024],
            [-80.855460253999979, 35.391236915000036],
            [-80.855135254999936, 35.391226916000051],
            [-80.854840254999942, 35.391204916000049],
            [-80.854547253999954, 35.391169916000024],
            [-80.854160253999964, 35.391103916000077],
            [-80.854135253999971, 35.391206916000044],
            [-80.853990252999949, 35.391694916000063],
            [-80.853956253999968, 35.391781915000024],
            [-80.853744253999935, 35.392241916000046],
            [-80.853436254999963, 35.392829917000029],
            [-80.853150252999967, 35.393362916000058],
            [-80.85308125399996, 35.393481917000031],
            [-80.853231253999979, 35.393731916000036],
            [-80.853614253999979, 35.394330917000048],
            [-80.854202253999972, 35.395249917000058],
            [-80.854525253999952, 35.39576691700006],
            [-80.854873254999973, 35.39638291600005],
            [-80.855252254999982, 35.397105917000033],
            [-80.855562254999938, 35.397749917000056],
            [-80.855622254999957, 35.397889917000043],
            [-80.855803254999955, 35.39831491700005],
            [-80.855923254999936, 35.398612918000026],
            [-80.856048254999962, 35.398939917000064],
            [-80.856208255999945, 35.399384917000077],
            [-80.85639325599999, 35.399940917000038],
            [-80.856632255999955, 35.400816918000032],
            [-80.85669025499999, 35.401043918000028],
            [-80.856812255999955, 35.401604918000032],
            [-80.85687825499997, 35.401941918000034],
            [-80.857004255999982, 35.40271191800008],
            [-80.857083254999964, 35.403099919000056],
            [-80.857290255999942, 35.404263918000026],
            [-80.857346255999971, 35.404580919000068],
            [-80.857382255999937, 35.404980918000035]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 143,
        "SolutionID": "PI-29:C",
        "Shape_Length": 0.10559202138688506,
        "Shape_Area": 0.00041186279569382483
      }
    },
    {
      "type": "Feature",
      "id": 144,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.869536264999965, 35.49713693800004],
            [-80.870377264999945, 35.497676938000041],
            [-80.874061265999956, 35.49985293900005],
            [-80.874262266999949, 35.507660940000051],
            [-80.874266266999939, 35.507785940000076],
            [-80.874385267999969, 35.512475940000058],
            [-80.874799266999958, 35.512507941000024],
            [-80.890049270999953, 35.513657941000076],
            [-80.890675271999953, 35.513703941000074],
            [-80.906065276999982, 35.514832941000066],
            [-80.909828276999974, 35.515109940000059],
            [-80.91006927799998, 35.514932940000051],
            [-80.910322277999967, 35.514745940000068],
            [-80.91821127999998, 35.508934939000028],
            [-80.930149281999945, 35.500141937000024],
            [-80.936342284999967, 35.49557993600007],
            [-80.940500284999985, 35.492517935000023],
            [-80.936053283999968, 35.492251934000024],
            [-80.931552282999974, 35.491815934000044],
            [-80.923014280999951, 35.490675934000024],
            [-80.91647927799994, 35.489558935000048],
            [-80.916012276999936, 35.489519935000033],
            [-80.915154277999989, 35.489398935000054],
            [-80.913867277999941, 35.489231935000078],
            [-80.913223276999986, 35.48915293500005],
            [-80.91022727699999, 35.488767935000055],
            [-80.909697276999964, 35.488701935000051],
            [-80.908611274999942, 35.488558935000071],
            [-80.90822127499996, 35.488491934000024],
            [-80.907944275999967, 35.488427935000061],
            [-80.90773227599999, 35.48836493500005],
            [-80.907489275999978, 35.488284935000024],
            [-80.907190274999948, 35.488171935000025],
            [-80.906923275999986, 35.488054935000036],
            [-80.906568273999937, 35.487886934000073],
            [-80.905402274999972, 35.48735193400006],
            [-80.904964273999951, 35.487150934000056],
            [-80.903363273999958, 35.486411935000035],
            [-80.903032273999941, 35.486266934000071],
            [-80.902687273999959, 35.486105935000069],
            [-80.902391272999978, 35.485968935000074],
            [-80.902021273999935, 35.485802935000038],
            [-80.901813272999959, 35.485709934000056],
            [-80.901603272999978, 35.485633934000077],
            [-80.901446273999966, 35.485586934000025],
            [-80.901269272999969, 35.485550934000059],
            [-80.901037272999986, 35.485512934000042],
            [-80.900628273999985, 35.485462934000054],
            [-80.900606272999937, 35.485459934000062],
            [-80.900101272999962, 35.485408934000077],
            [-80.899033272999986, 35.485290934000034],
            [-80.897883272999934, 35.48516393400007],
            [-80.897305271999983, 35.485094934000074],
            [-80.896910271999957, 35.48502893400007],
            [-80.896578272999989, 35.484958934000076],
            [-80.896322272999953, 35.484892935000062],
            [-80.896004271999971, 35.484794934000035],
            [-80.895727271999988, 35.484697935000042],
            [-80.895457271999987, 35.484589934000041],
            [-80.895302271999981, 35.484521934000043],
            [-80.895081270999981, 35.484417934000078],
            [-80.894807270999934, 35.484274934000041],
            [-80.894568271999958, 35.484136935000038],
            [-80.893229269999949, 35.483331934000034],
            [-80.892076269999961, 35.482638933000032],
            [-80.891584269999953, 35.48234293400003],
            [-80.888794268999959, 35.480667933000063],
            [-80.888443269999982, 35.480461933000072],
            [-80.888368269999944, 35.480407934000027],
            [-80.888330268999937, 35.48035793400004],
            [-80.888311268999985, 35.480323934000069],
            [-80.888879268999972, 35.48008793300005],
            [-80.889098268999987, 35.47998593300008],
            [-80.889299269999981, 35.479882934000045],
            [-80.889500268999939, 35.479771933000052],
            [-80.889669269999956, 35.479666933000033],
            [-80.889848270999948, 35.479543933000059],
            [-80.889933268999982, 35.479477933000055],
            [-80.890019269999982, 35.479410933000054],
            [-80.890248269999972, 35.47920593300006],
            [-80.890387269999962, 35.47907093300006],
            [-80.890566269999965, 35.478874933000043],
            [-80.890699269999971, 35.478709933000061],
            [-80.890917269999989, 35.478397933000053],
            [-80.890989269999977, 35.478281933000062],
            [-80.891119268999944, 35.478034932000071],
            [-80.891174270999954, 35.477908933000037],
            [-80.891290269999956, 35.477598933000024],
            [-80.891347269999983, 35.47742693400005],
            [-80.891413268999941, 35.477200933000063],
            [-80.891453269999943, 35.476992933000076],
            [-80.891472268999962, 35.476791933000072],
            [-80.891476269999941, 35.47669893300008],
            [-80.891469269999959, 35.476215933000049],
            [-80.891485269999976, 35.475475933000041],
            [-80.890844269999945, 35.47545793300003],
            [-80.890669270999979, 35.475433933000033],
            [-80.890479268999968, 35.475382932000059],
            [-80.890374269999938, 35.475329932000079],
            [-80.890272269999969, 35.475260932000026],
            [-80.890026268999975, 35.475051932000042],
            [-80.889570268999989, 35.474646932000041],
            [-80.889473269999939, 35.474569932000065],
            [-80.889318268999943, 35.474468932000036],
            [-80.889159268999947, 35.474390932000063],
            [-80.88910126899998, 35.474373932000049],
            [-80.88896226899999, 35.474332932000038],
            [-80.888856268999973, 35.474308933000032],
            [-80.888744268999972, 35.474294932000078],
            [-80.888569268999959, 35.474289932000033],
            [-80.888328268999942, 35.474306932000047],
            [-80.88807326899996, 35.474334932000033],
            [-80.887129268999956, 35.474438933000044],
            [-80.885632268999984, 35.474606932000029],
            [-80.885073266999939, 35.474675933000071],
            [-80.883905266999989, 35.474865932000057],
            [-80.883788266999943, 35.474881932000073],
            [-80.883613266999987, 35.474895933000028],
            [-80.883447267999941, 35.474898932000031],
            [-80.883190266999975, 35.474884933000055],
            [-80.883061267999949, 35.474866933000044],
            [-80.882916267999974, 35.474838932000068],
            [-80.882729267999935, 35.474789932000078],
            [-80.882539265999981, 35.474729933000049],
            [-80.882431266999959, 35.474687933000041],
            [-80.882321267999941, 35.474638933000051],
            [-80.882254266999951, 35.474746933000063],
            [-80.882214266999938, 35.47480093300004],
            [-80.882173266999985, 35.474843933000045],
            [-80.88209326599997, 35.474911933000044],
            [-80.881994266999982, 35.474977932000058],
            [-80.881894265999961, 35.47502993300003],
            [-80.88184126699997, 35.47505293200004],
            [-80.881782265999959, 35.475071933000038],
            [-80.881644266999956, 35.475099933000024],
            [-80.881609266999988, 35.475102932000027],
            [-80.881504265999979, 35.475111933000051],
            [-80.881370266999966, 35.475105933000066],
            [-80.881299266999974, 35.475096933000032],
            [-80.88077526699999, 35.475007933000029],
            [-80.880588265999961, 35.474995933000059],
            [-80.880467266999972, 35.47499593200007],
            [-80.880369266999935, 35.475005933000034],
            [-80.879927265999982, 35.47508193300007],
            [-80.879568265999978, 35.47521693300007],
            [-80.880817266999941, 35.474294932000078],
            [-80.880853266999964, 35.474267933000078],
            [-80.880937266999979, 35.471912932000066],
            [-80.880936266999981, 35.471483932000069],
            [-80.879842266999958, 35.471512932000053],
            [-80.878878265999958, 35.471568932000025],
            [-80.877979264999965, 35.471515932000045],
            [-80.877097264999975, 35.47149793300008],
            [-80.877469265999935, 35.470156931000076],
            [-80.877931264999972, 35.468624932000068],
            [-80.877754265999954, 35.468434932000036],
            [-80.87765426499999, 35.468287931000077],
            [-80.87760226599994, 35.468187931000045],
            [-80.877554264999958, 35.468072931000052],
            [-80.877318264999985, 35.467593931000067],
            [-80.877222264999943, 35.467426931000034],
            [-80.877124264999964, 35.46721393100006],
            [-80.877036265999948, 35.467081931000052],
            [-80.87691526499998, 35.466883931000041],
            [-80.876813264999953, 35.466688931000078],
            [-80.876766263999968, 35.466581932000054],
            [-80.876697264999962, 35.466457931000036],
            [-80.876624264999975, 35.466309931000069],
            [-80.876543264999952, 35.466178931000059],
            [-80.876482264999936, 35.466028931000039],
            [-80.876392264999936, 35.465845930000057],
            [-80.876388264999946, 35.465782932000025],
            [-80.876424264999969, 35.465566932000058],
            [-80.876427264999961, 35.465523931000064],
            [-80.876428264999959, 35.465458930000068],
            [-80.876441264999983, 35.465377930000045],
            [-80.876440264999985, 35.465325930000063],
            [-80.876428264999959, 35.465270931000077],
            [-80.876420264999979, 35.465038931000038],
            [-80.876409265999939, 35.464965931000052],
            [-80.876411264999945, 35.464556931000061],
            [-80.876403264999965, 35.464065930000061],
            [-80.87640126499997, 35.463875930000029],
            [-80.876345264999941, 35.463818930000059],
            [-80.876377264999974, 35.463636931000053],
            [-80.876267263999978, 35.463663930000052],
            [-80.875926265999965, 35.463736931000028],
            [-80.875420263999956, 35.463845931000037],
            [-80.875119263999977, 35.463909931000046],
            [-80.874803263999979, 35.463965930000029],
            [-80.874321263999946, 35.464045931000044],
            [-80.87382426399995, 35.464119931000027],
            [-80.873311263999938, 35.464183931000036],
            [-80.872769262999952, 35.464240931000063],
            [-80.871620262999954, 35.46433993100004],
            [-80.871044262999987, 35.464371931000073],
            [-80.871189262999962, 35.464745931000039],
            [-80.872171263999974, 35.46721793200004],
            [-80.873376263999944, 35.470264932000077],
            [-80.873260263999953, 35.470351932000028],
            [-80.873221262999948, 35.47039893200008],
            [-80.873099263999961, 35.470584932000065],
            [-80.87304026299995, 35.470652932000064],
            [-80.872963262999974, 35.470751932000042],
            [-80.872936264999964, 35.470769932000053],
            [-80.872874263999961, 35.470803932000024],
            [-80.872724263999942, 35.470919932000072],
            [-80.872661264999977, 35.470956932000036],
            [-80.87251026399997, 35.471008932000075],
            [-80.87237926399996, 35.471036932000061],
            [-80.872324263999985, 35.471053931000029],
            [-80.872246262999965, 35.471099932000072],
            [-80.872168263999981, 35.471061932000055],
            [-80.872100263999982, 35.471090933000028],
            [-80.872054262999939, 35.471098932000075],
            [-80.872008262999941, 35.471101933000057],
            [-80.871938263999937, 35.471173932000056],
            [-80.871904263999966, 35.471184933000075],
            [-80.871809263999978, 35.471200933000034],
            [-80.871731263999948, 35.471203933000027],
            [-80.871687263999945, 35.471205932000032],
            [-80.871617262999962, 35.471208932000025],
            [-80.871387263999964, 35.47123193200008],
            [-80.871125262999954, 35.47123593200007],
            [-80.871012262999955, 35.471230932000026],
            [-80.870959262999975, 35.47123193200008],
            [-80.87076626399994, 35.471237932000065],
            [-80.870367263999981, 35.471257933000061],
            [-80.870297263999987, 35.471257933000061],
            [-80.870106262999968, 35.471275932000026],
            [-80.870000262999952, 35.471275932000026],
            [-80.869793263999952, 35.471310932000051],
            [-80.869585262999976, 35.471322933000067],
            [-80.869407262999971, 35.471354932000054],
            [-80.869302262999952, 35.471345932000077],
            [-80.869274263999955, 35.471344933000069],
            [-80.869180262999976, 35.471346932000074],
            [-80.868997262999983, 35.471406932000036],
            [-80.868949262999934, 35.471433932000025],
            [-80.868908261999934, 35.471482932000072],
            [-80.868540262999943, 35.471814932000029],
            [-80.86847626399998, 35.471884933000069],
            [-80.868396262999966, 35.471950932000027],
            [-80.868341262999934, 35.471988932000045],
            [-80.868268262999948, 35.472074933000044],
            [-80.868239262999964, 35.472097933000043],
            [-80.868178262999947, 35.472168932000045],
            [-80.867978262999941, 35.472343932000058],
            [-80.867856262999965, 35.472461932000044],
            [-80.867795262999948, 35.472527933000038],
            [-80.867680261999965, 35.472624932000031],
            [-80.867550261999952, 35.472746933000053],
            [-80.867392262999942, 35.472912933000032],
            [-80.867312262999974, 35.472967933000064],
            [-80.867277261999959, 35.472989933000065],
            [-80.867234262999943, 35.473030933000075],
            [-80.867210261999958, 35.473038933000055],
            [-80.867151261999936, 35.473060933000056],
            [-80.867087262999974, 35.473074933000078],
            [-80.86706626299997, 35.473086933000047],
            [-80.867044261999979, 35.473109933000046],
            [-80.86701326299999, 35.47312693300006],
            [-80.866956261999974, 35.473147932000074],
            [-80.866937261999965, 35.473177934000034],
            [-80.866915262999953, 35.473201932000052],
            [-80.866877262999935, 35.473230933000025],
            [-80.86684526199997, 35.473245933000044],
            [-80.866741261999948, 35.47331093300005],
            [-80.866694261999953, 35.473316933000035],
            [-80.866692261999958, 35.473336933000041],
            [-80.866701262999982, 35.473369934000061],
            [-80.866692261999958, 35.473386933000029],
            [-80.866671261999954, 35.473393933000068],
            [-80.86661226199999, 35.473394932000076],
            [-80.866578261999962, 35.473412932000031],
            [-80.866562261999945, 35.473442933000058],
            [-80.866527261999977, 35.47345393300003],
            [-80.866509261999965, 35.473465933000057],
            [-80.866492261999952, 35.473490932000061],
            [-80.866483262999964, 35.473513933000049],
            [-80.866453261999936, 35.473547933000077],
            [-80.866416261999973, 35.473576933000061],
            [-80.86633926199994, 35.473614933000079],
            [-80.866301261999979, 35.473645933000057],
            [-80.866095262999977, 35.473878933000037],
            [-80.866008262999969, 35.473938933000056],
            [-80.865968261999967, 35.47395593300007],
            [-80.86593626299998, 35.473957933000065],
            [-80.865897262999965, 35.473966933000042],
            [-80.865815261999956, 35.474003933000063],
            [-80.865754261999939, 35.474040933000026],
            [-80.86571926299996, 35.474055933000045],
            [-80.865689260999943, 35.474074934000043],
            [-80.865661261999946, 35.474092934000055],
            [-80.865577261999988, 35.474115932000075],
            [-80.865496260999976, 35.474157933000072],
            [-80.865425262999963, 35.474172933000034],
            [-80.865328261999935, 35.474209934000044],
            [-80.865289261999976, 35.474212932000057],
            [-80.865237262999983, 35.474236933000043],
            [-80.865218261999985, 35.474254933000054],
            [-80.86517526199998, 35.474312933000078],
            [-80.86517126199999, 35.474339933000067],
            [-80.865154262999965, 35.474377933000028],
            [-80.865068261999966, 35.474462933000041],
            [-80.865026260999969, 35.474493933000076],
            [-80.864963261999947, 35.47460893300007],
            [-80.864956261999964, 35.474636933000056],
            [-80.864941261999945, 35.474670933000027],
            [-80.864929261999976, 35.474719933000074],
            [-80.864889260999973, 35.474781933000031],
            [-80.86484526199996, 35.474876933000075],
            [-80.864811260999943, 35.47489493300003],
            [-80.86476326099995, 35.474897933000079],
            [-80.864738260999957, 35.474909933000049],
            [-80.864719261999937, 35.47492793300006],
            [-80.864696261999939, 35.47497293300006],
            [-80.864690261999954, 35.475006934000078],
            [-80.864667261999955, 35.475045933000047],
            [-80.864569261999975, 35.475141934000078],
            [-80.864467261999948, 35.475251933000038],
            [-80.864420261999953, 35.475286933000064],
            [-80.86438326199999, 35.475308933000065],
            [-80.864333261999946, 35.475313934000042],
            [-80.864275261999978, 35.475358934000042],
            [-80.864221261999944, 35.475421933000064],
            [-80.864209260999985, 35.475444933000063],
            [-80.864187260999984, 35.475448934000042],
            [-80.864144261999968, 35.475438934000067],
            [-80.864107261999948, 35.475424933000056],
            [-80.86408026099997, 35.47540893300004],
            [-80.864033260999975, 35.475398934000054],
            [-80.863983261999977, 35.475394933000075],
            [-80.86392226199996, 35.475387933000036],
            [-80.863865260999944, 35.475373933000071],
            [-80.863813261999951, 35.475350933000072],
            [-80.863642260999939, 35.475261934000059],
            [-80.863586260999966, 35.47524293400005],
            [-80.863493261999963, 35.475219934000052],
            [-80.863361261999955, 35.475180934000036],
            [-80.863306261999981, 35.475149933000068],
            [-80.863222260999976, 35.475050933000034],
            [-80.863185260999956, 35.474985933000028],
            [-80.86317126199998, 35.474929934000045],
            [-80.863152261999971, 35.474912933000041],
            [-80.863111261999961, 35.474909934000038],
            [-80.863019260999977, 35.474920933000078],
            [-80.862970261999976, 35.474917933000029],
            [-80.86281926099997, 35.474855933000072],
            [-80.862771260999978, 35.474850934000074],
            [-80.862285260999954, 35.474853933000077],
            [-80.862036260999957, 35.474848934000079],
            [-80.861869260999981, 35.47483393300007],
            [-80.861762260999967, 35.474830934000067],
            [-80.861266260999969, 35.474835933000065],
            [-80.861213260999989, 35.474856933000069],
            [-80.861148260999983, 35.474718933000077],
            [-80.861046261999945, 35.474535933000027],
            [-80.86133726099996, 35.474450933000071],
            [-80.861514261999957, 35.474404934000063],
            [-80.861584260999962, 35.474394933000042],
            [-80.861715260999972, 35.474392933000047],
            [-80.862478260999978, 35.474438933000044],
            [-80.862628260999941, 35.474449933000074],
            [-80.862756260999959, 35.474453933000063],
            [-80.862858261999975, 35.474444934000076],
            [-80.862947261999977, 35.474429933000067],
            [-80.863028260999954, 35.474407933000066],
            [-80.863568260999955, 35.47422593400006],
            [-80.865124260999949, 35.473703934000071],
            [-80.86532626199994, 35.473631933000036],
            [-80.865506262999986, 35.473557933000052],
            [-80.865663261999941, 35.473476933000029],
            [-80.86575026099996, 35.473422933000052],
            [-80.866062261999957, 35.473214934000055],
            [-80.866625261999957, 35.472851933000072],
            [-80.866141261999985, 35.472359933000064],
            [-80.866026261999934, 35.472257933000037],
            [-80.865932261999944, 35.472183932000064],
            [-80.865995261999956, 35.472111933000065],
            [-80.866044261999946, 35.472047932000066],
            [-80.866112261999945, 35.471934933000057],
            [-80.86642126299995, 35.471194933000049],
            [-80.866445261999957, 35.471090933000028],
            [-80.866316261999941, 35.471070933000078],
            [-80.865975261999949, 35.47099193300005],
            [-80.865848261999986, 35.470965933000059],
            [-80.865755261999936, 35.470951933000038],
            [-80.865563261999966, 35.470931933000031],
            [-80.865521260999969, 35.470931933000031],
            [-80.865344261999951, 35.470937932000027],
            [-80.865231260999963, 35.470949932000053],
            [-80.86488626199997, 35.471000932000038],
            [-80.864715260999958, 35.471015932000057],
            [-80.864544260999935, 35.471025933000078],
            [-80.864359261999937, 35.471027932000027],
            [-80.863081260999934, 35.470982933000073],
            [-80.86285626099999, 35.470975933000034],
            [-80.86254926099997, 35.470984933000068],
            [-80.862447260999943, 35.470992933000048],
            [-80.86235026099996, 35.470992933000048],
            [-80.86223825999997, 35.470983932000024],
            [-80.862040260999947, 35.470951933000038],
            [-80.861943260999965, 35.47094293300006],
            [-80.861788260999958, 35.470936933000075],
            [-80.86112026099994, 35.470932933000029],
            [-80.860897259999945, 35.470926933000044],
            [-80.860820259999969, 35.470917932000077],
            [-80.86075225999997, 35.470901932000061],
            [-80.860675260999983, 35.470865933000027],
            [-80.860295259999987, 35.47063593200005],
            [-80.860186259999978, 35.470575933000077],
            [-80.860029260999966, 35.47048193300003],
            [-80.859936259999984, 35.47041493200004],
            [-80.859740259999967, 35.470594933000029],
            [-80.859659259999944, 35.47067693200006],
            [-80.859587259999955, 35.470757933000073],
            [-80.858859259999974, 35.471678933000078],
            [-80.858555259999946, 35.472049933000051],
            [-80.857323259999987, 35.473587933000033],
            [-80.856831258999989, 35.474246934000064],
            [-80.856700258999979, 35.474407933000066],
            [-80.856625258999941, 35.474479934000044],
            [-80.856551259999947, 35.474534933000029],
            [-80.856454258999975, 35.474588933000064],
            [-80.856376258999944, 35.474620934000029],
            [-80.855609258999948, 35.474847934000024],
            [-80.855484259999969, 35.474881933000063],
            [-80.854070258999968, 35.475303933000077],
            [-80.853982258999963, 35.47534093400003],
            [-80.854498258999968, 35.476132934000077],
            [-80.854375259999983, 35.476185934000057],
            [-80.85432525899995, 35.476207934000058],
            [-80.854248258999974, 35.476235934000044],
            [-80.854076258999953, 35.476217934000033],
            [-80.853888258999973, 35.476206934000061],
            [-80.853842257999986, 35.476203934000068],
            [-80.853144258999976, 35.476172934000033],
            [-80.852972258999955, 35.476171934000035],
            [-80.852636258999951, 35.476185934000057],
            [-80.85186325799998, 35.476248934000068],
            [-80.850824257999989, 35.476333934000024],
            [-80.85025825799994, 35.476361934000067],
            [-80.849362257999985, 35.47636493400006],
            [-80.849206257999981, 35.476372935000029],
            [-80.848857257999953, 35.47641393400005],
            [-80.848220255999934, 35.476497934000065],
            [-80.847933256999966, 35.47654093500006],
            [-80.847820256999967, 35.479670935000058],
            [-80.847818256999972, 35.479714935000061],
            [-80.847508257999948, 35.480333935000033],
            [-80.846344256999942, 35.482654936000074],
            [-80.845026255999983, 35.48527993600004],
            [-80.844949256999939, 35.485434937000036],
            [-80.844928256999935, 35.48547893600005],
            [-80.844942256999957, 35.485578937000071],
            [-80.844948256999942, 35.485693936000075],
            [-80.844965255999966, 35.485725936000051],
            [-80.845066256999985, 35.485788936000063],
            [-80.84512125599997, 35.485817936000046],
            [-80.84517025699995, 35.48583493600006],
            [-80.845294255999988, 35.485863936000044],
            [-80.845351256999948, 35.485894936000079],
            [-80.845488256999943, 35.485935936000033],
            [-80.845548255999972, 35.485959936000029],
            [-80.84560225599995, 35.485975936000045],
            [-80.845653256999981, 35.485998936000044],
            [-80.845697256999983, 35.486013936000063],
            [-80.845816256999967, 35.486028937000071],
            [-80.845869256999947, 35.486050936000026],
            [-80.845921256999986, 35.486080937000054],
            [-80.845972255999982, 35.486091936000037],
            [-80.846073256999944, 35.486093936000032],
            [-80.846119256999941, 35.486113936000038],
            [-80.846161256999949, 35.486142937000068],
            [-80.84617525699997, 35.486155937000035],
            [-80.846228255999961, 35.486230937000073],
            [-80.846261256999981, 35.486240937000048],
            [-80.846290256999964, 35.48623993700005],
            [-80.84634525599995, 35.486191936000068],
            [-80.846384256999954, 35.48617293600006],
            [-80.846624256999974, 35.486148936000063],
            [-80.846682256999941, 35.486132937000036],
            [-80.846702256999947, 35.486110936000046],
            [-80.846707257999981, 35.486087936000047],
            [-80.846719256999961, 35.48607193600003],
            [-80.846751256999937, 35.486055936000071],
            [-80.846781257999965, 35.486033936000069],
            [-80.846835256999952, 35.486023937000027],
            [-80.84692625699995, 35.486017936000053],
            [-80.847017256999948, 35.486026937000076],
            [-80.847190256999966, 35.486081936000062],
            [-80.84724525699994, 35.486087937000036],
            [-80.847304256999962, 35.486101936000068],
            [-80.847349257999952, 35.486124936000067],
            [-80.847395257999949, 35.486130936000052],
            [-80.847456256999976, 35.486125937000054],
            [-80.847498257999973, 35.486103937000053],
            [-80.847523256999978, 35.486069936000035],
            [-80.847544256999981, 35.486029936000079],
            [-80.847590257999968, 35.485987936000072],
            [-80.847653256999934, 35.485981937000076],
            [-80.847691256999951, 35.485997936000047],
            [-80.847742257999982, 35.486077936000072],
            [-80.847801257999947, 35.486142937000068],
            [-80.847813257999974, 35.486185936000027],
            [-80.847854257999984, 35.48623593700006],
            [-80.847942256999943, 35.486290936000046],
            [-80.847993257999974, 35.486328936000064],
            [-80.848082256999987, 35.486343936000026],
            [-80.848133257999962, 35.486359936000042],
            [-80.848159256999963, 35.486384936000036],
            [-80.848195256999986, 35.486423936000051],
            [-80.848214257999985, 35.486458936000076],
            [-80.848232258999985, 35.486474936000036],
            [-80.848290257999963, 35.486551937000058],
            [-80.848346257999935, 35.486642936000067],
            [-80.848354256999983, 35.486710936000065],
            [-80.848356257999967, 35.486779937000051],
            [-80.848403257999962, 35.486807937000037],
            [-80.84844525799997, 35.486817937000069],
            [-80.848499256999958, 35.486849936000056],
            [-80.848526257999936, 35.486875936000047],
            [-80.848560257999964, 35.486898936000046],
            [-80.848603257999969, 35.486915937000049],
            [-80.848626256999978, 35.486935937000055],
            [-80.848641257999986, 35.486959936000062],
            [-80.848646256999984, 35.486996936000025],
            [-80.848640257999989, 35.48703193700004],
            [-80.848646257999974, 35.487049936000062],
            [-80.848685256999943, 35.487084936000031],
            [-80.84877225799994, 35.487084936000031],
            [-80.848847256999989, 35.48705493600005],
            [-80.848958256999936, 35.487038936000033],
            [-80.849009258999956, 35.487053937000042],
            [-80.84903825799995, 35.487084936000031],
            [-80.849107257999947, 35.487273937000055],
            [-80.849134257999935, 35.48732093600006],
            [-80.849176257999943, 35.487354937000077],
            [-80.849226258999977, 35.487375936000035],
            [-80.849378257999945, 35.487425936000079],
            [-80.849420257999952, 35.487461936000045],
            [-80.849465257999952, 35.487573936000047],
            [-80.849383257999989, 35.487761937000073],
            [-80.849281258999952, 35.487989936000076],
            [-80.849345258999961, 35.488633937000031],
            [-80.849350257999959, 35.488675937000039],
            [-80.84933125799995, 35.489115937000065],
            [-80.849320257999977, 35.489542937000067],
            [-80.849236257999962, 35.491938937000043],
            [-80.849182258999974, 35.493682938000063],
            [-80.848594257999935, 35.493694937000043],
            [-80.848194257999978, 35.493694937000043],
            [-80.84811125799996, 35.493687938000051],
            [-80.847999257999959, 35.493667938000044],
            [-80.847889257999952, 35.493631938000078],
            [-80.847314257999983, 35.493354938000039],
            [-80.846881257999939, 35.493145938000055],
            [-80.846851257999958, 35.49313993800007],
            [-80.84682825799996, 35.493140938000067],
            [-80.846796257999983, 35.493149938000045],
            [-80.846769257999938, 35.493165938000061],
            [-80.845728256999962, 35.494502938000039],
            [-80.845054256999958, 35.495374938000054],
            [-80.844980255999985, 35.495470938000039],
            [-80.844893256999967, 35.495583938000038],
            [-80.844596256999978, 35.495976938000069],
            [-80.84450525799997, 35.496083938000027],
            [-80.844473256999947, 35.496120938000047],
            [-80.844087255999966, 35.496633939000048],
            [-80.843569256999956, 35.49729693900008],
            [-80.843483256999946, 35.497399938000058],
            [-80.844129256999963, 35.49788594000006],
            [-80.844364256999938, 35.49806393800003],
            [-80.844736257999955, 35.498343939000051],
            [-80.844834256999945, 35.498412940000037],
            [-80.844956257999968, 35.498479939000049],
            [-80.845046256999979, 35.498522939000054],
            [-80.845221257999981, 35.498589939000055],
            [-80.845364257999961, 35.498630939000066],
            [-80.846508256999982, 35.498867939000036],
            [-80.847584257999983, 35.499083939000059],
            [-80.848480258999984, 35.499261939000064],
            [-80.84811125899995, 35.500119939000058],
            [-80.847458256999971, 35.501660939000033],
            [-80.847347257999957, 35.501917940000055],
            [-80.847973258999957, 35.502086940000027],
            [-80.848602258999961, 35.502270940000074],
            [-80.848775258999979, 35.502327940000043],
            [-80.848796258999982, 35.502333939000039],
            [-80.849242258999936, 35.502461940000046],
            [-80.849624257999949, 35.502550940000049],
            [-80.849750258999961, 35.502573939000058],
            [-80.849869258999945, 35.502588939000077],
            [-80.850341258999947, 35.502631940000072],
            [-80.851865259999954, 35.502782939000042],
            [-80.852372258999935, 35.502823940000042],
            [-80.85278625899997, 35.502847939000048],
            [-80.853516259999935, 35.502871940000034],
            [-80.85463225999996, 35.502914940000039],
            [-80.855089259999943, 35.502933940000048],
            [-80.855492260999938, 35.502945940000075],
            [-80.85581726099997, 35.50294793900008],
            [-80.856092260999958, 35.502948939000078],
            [-80.856244260999972, 35.502939939000044],
            [-80.856404260999966, 35.502925939000079],
            [-80.856553261999977, 35.502912940000044],
            [-80.857021260999943, 35.502847940000038],
            [-80.858570260999954, 35.502573939000058],
            [-80.859478261999982, 35.502413939000064],
            [-80.859902260999945, 35.502336939000031],
            [-80.860281261999944, 35.502273939000077],
            [-80.860355261999985, 35.502264939000042],
            [-80.860652262999963, 35.502229940000063],
            [-80.86086026199996, 35.502212940000049],
            [-80.861082261999968, 35.502203939000026],
            [-80.861257261999981, 35.502201939000031],
            [-80.861401261999958, 35.502201939000031],
            [-80.861518261999947, 35.502209940000057],
            [-80.861867261999976, 35.502251939000075],
            [-80.86210226299994, 35.502297939000073],
            [-80.862238262999938, 35.502328940000041],
            [-80.862557262999985, 35.502420939000046],
            [-80.863270262999947, 35.502679939000075],
            [-80.863743262999947, 35.502862939000067],
            [-80.86440926299997, 35.503120939000041],
            [-80.864849263999986, 35.503285940000069],
            [-80.865063263999957, 35.503371939000033],
            [-80.865134263999948, 35.503402940000058],
            [-80.865495262999957, 35.503531940000073],
            [-80.866244263999988, 35.503812939000056],
            [-80.866471263999983, 35.503896939000072],
            [-80.866498263999972, 35.503666940000073],
            [-80.866527263999956, 35.503512939000075],
            [-80.866611263999971, 35.503072940000038],
            [-80.866662264999945, 35.502850939000041],
            [-80.866760263999936, 35.502533939000045],
            [-80.866980263999949, 35.50193893900007],
            [-80.867067264999946, 35.501737939000066],
            [-80.86732826299999, 35.501231938000046],
            [-80.867368262999946, 35.501143938000041],
            [-80.867466263999972, 35.500925939000069],
            [-80.867752264999979, 35.500355939000031],
            [-80.867860263999944, 35.500140939000062],
            [-80.867917263999971, 35.500027938000073],
            [-80.868013263999956, 35.499830938000059],
            [-80.868197263999946, 35.499465939000061],
            [-80.869199263999974, 35.49744393800006],
            [-80.869399263999981, 35.497048937000045],
            [-80.869536264999965, 35.49713693800004]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 144,
        "SolutionID": "PI-29:G",
        "Shape_Length": 0.30358314547973997,
        "Shape_Area": 0.0024033942242563749
      }
    },
    {
      "type": "Feature",
      "id": 145,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.839915813999937, 35.239666094000029],
            [-80.840309629999979, 35.239656503000049],
            [-80.840703087999941, 35.239639715000067],
            [-80.841095989999985, 35.239615738000055],
            [-80.841488145999961, 35.239584584000056],
            [-80.841879356999982, 35.239546268000026],
            [-80.842269429999988, 35.239500811000028],
            [-80.842658170999982, 35.239448233000076],
            [-80.843045386999961, 35.239388563000034],
            [-80.843430886999954, 35.239321828000072],
            [-80.843814477999956, 35.239248063000048],
            [-80.844195969999987, 35.239167302000055],
            [-80.844575171999963, 35.239079588000038],
            [-80.844951898999966, 35.238984963000064],
            [-80.845325961999947, 35.23888347500008],
            [-80.845697173999952, 35.238775174000068],
            [-80.846065351999982, 35.238660113000037],
            [-80.846430312999985, 35.238538350000056],
            [-80.846791876999987, 35.238409945000058],
            [-80.847149861999981, 35.238274962000048],
            [-80.847504090999962, 35.238133469000047],
            [-80.847854389999952, 35.237985535000064],
            [-80.848200582999937, 35.237831233000065],
            [-80.848542497999972, 35.237670642000069],
            [-80.848879966999959, 35.237503839000055],
            [-80.849212820999981, 35.237330908000047],
            [-80.849540894999961, 35.237151935000043],
            [-80.849864025999977, 35.236967009000068],
            [-80.850182053999958, 35.23677622200006],
            [-80.850494820999984, 35.236579668000047],
            [-80.850802170999941, 35.236377445000073],
            [-80.85110395099997, 35.236169654000037],
            [-80.851400012999989, 35.235956397000052],
            [-80.851690208999969, 35.235737781000068],
            [-80.851974394999957, 35.23551391400008],
            [-80.852252429999965, 35.23528490700005],
            [-80.852524175999974, 35.235050874000024],
            [-80.852789497999936, 35.234811932000071],
            [-80.853048264999984, 35.234568198000034],
            [-80.853300346999958, 35.234319794000044],
            [-80.853545619999977, 35.234066843000051],
            [-80.853783962999955, 35.233809472000075],
            [-80.854015255999968, 35.233547807000036],
            [-80.854239383999982, 35.233281979000026],
            [-80.854456236999965, 35.23301211900008],
            [-80.85466570799997, 35.232738362000077],
            [-80.85486769299996, 35.232460843000069],
            [-80.855062088999944, 35.232179701000064],
            [-80.855248801999949, 35.231895075000068],
            [-80.855427739999982, 35.23160710600007],
            [-80.855598812999972, 35.231315937000034],
            [-80.855761934999975, 35.231021714000065],
            [-80.855917026999975, 35.230724581000061],
            [-80.856064010999944, 35.230424686000049],
            [-80.85620281599995, 35.230122179000034],
            [-80.856333370999948, 35.229817209000032],
            [-80.856455611999934, 35.229509929000073],
            [-80.856569477999983, 35.229200490000039],
            [-80.856674913999939, 35.228889046000063],
            [-80.856771865999974, 35.228575751000051],
            [-80.856860285999971, 35.228260763000037],
            [-80.856940131999977, 35.227944236000042],
            [-80.857011363999959, 35.227626328000042],
            [-80.857073943999978, 35.227307197000073],
            [-80.85712784499998, 35.226987000000065],
            [-80.857173036999939, 35.226665898000078],
            [-80.857209500999943, 35.226344049000033],
            [-80.857237214999941, 35.226021614000047],
            [-80.857256168999982, 35.225698752000028],
            [-80.857266351999954, 35.225375624000037],
            [-80.857267758999967, 35.225052389000041],
            [-80.857260389999965, 35.224729209000031],
            [-80.857244246999983, 35.224406244000079],
            [-80.857219340999961, 35.224083655000072],
            [-80.857185681999965, 35.223761600000046],
            [-80.857143287999975, 35.223440241000048],
            [-80.857092179999938, 35.223119736000058],
            [-80.857032383999979, 35.222800246000077],
            [-80.856963927999971, 35.222481928000036],
            [-80.856886848999977, 35.222164940000027],
            [-80.856801182999959, 35.221849440000028],
            [-80.856706971999984, 35.221535585000026],
            [-80.856604265999977, 35.221223529000042],
            [-80.856493112999942, 35.220913429000063],
            [-80.85637356899997, 35.220605438000064],
            [-80.856245694999984, 35.220299709000074],
            [-80.856109552999953, 35.219996392000041],
            [-80.855965210999955, 35.219695641000044],
            [-80.855812740999966, 35.219397603000061],
            [-80.855652217999989, 35.219102425000074],
            [-80.855483722999963, 35.21881025600004],
            [-80.855307339999968, 35.21852124000003],
            [-80.855123154999944, 35.218235519000075],
            [-80.854931258999954, 35.217953237000074],
            [-80.854731749999985, 35.217674533000036],
            [-80.854524724999976, 35.217399545000035],
            [-80.854310286999976, 35.217128411000033],
            [-80.854088542999989, 35.216861263000055],
            [-80.853859603999979, 35.216598237000028],
            [-80.853623581999955, 35.216339460000029],
            [-80.853380594999976, 35.216085062000047],
            [-80.853130762999967, 35.215835170000048],
            [-80.852874209999982, 35.215589907000037],
            [-80.852611065999952, 35.215349395000032],
            [-80.852341457999955, 35.215113753000026],
            [-80.852065521999975, 35.214883099000076],
            [-80.851783393999938, 35.214657546000069],
            [-80.851495214999943, 35.21443720700006],
            [-80.851201125999978, 35.214222190000044],
            [-80.850901276999934, 35.214012604000061],
            [-80.850595811999938, 35.213808551000056],
            [-80.850284886999987, 35.213610134000078],
            [-80.849968652999962, 35.213417449000076],
            [-80.849647267999956, 35.213230594000038],
            [-80.84932089199998, 35.213049659000035],
            [-80.848989686999971, 35.21287473700005],
            [-80.848653816999956, 35.212705913000036],
            [-80.848313447999942, 35.212543271000072],
            [-80.84796874999995, 35.21238689200004],
            [-80.847619893999934, 35.212236853000036],
            [-80.847267053999985, 35.212093229000061],
            [-80.846910401999935, 35.211956091000047],
            [-80.846550117999982, 35.211825506000025],
            [-80.84618637899996, 35.211701541000025],
            [-80.845819365999944, 35.211584257000027],
            [-80.845449261999988, 35.211473710000064],
            [-80.845076249999977, 35.211369958000034],
            [-80.844700514999943, 35.211273051000035],
            [-80.844322241999976, 35.211183036000079],
            [-80.843941620999942, 35.21109995900008],
            [-80.843558840999947, 35.211023860000068],
            [-80.843174089999934, 35.210954779000076],
            [-80.842787559999977, 35.210892748000049],
            [-80.842399441999987, 35.210837800000036],
            [-80.842009929999961, 35.210789959000067],
            [-80.84161921599997, 35.210749252000028],
            [-80.841227494999941, 35.210715697000069],
            [-80.840834959999938, 35.210689312000056],
            [-80.840441805999944, 35.210670110000024],
            [-80.840048229999979, 35.210658101000035],
            [-80.839654423999946, 35.21065328800006],
            [-80.839260585999966, 35.210655677000034],
            [-80.838866909999979, 35.210665264000056],
            [-80.838473591999957, 35.210682047000034],
            [-80.838080827999988, 35.210706015000028],
            [-80.837688809999975, 35.210737158000029],
            [-80.837297734999936, 35.210775460000036],
            [-80.836907796999981, 35.21082090200008],
            [-80.836519188999944, 35.210873461000062],
            [-80.836132102999954, 35.210933112000077],
            [-80.835746731999961, 35.210999823000066],
            [-80.835363265999945, 35.21107356400006],
            [-80.834981896999977, 35.211154296000075],
            [-80.834602814999982, 35.211241981000057],
            [-80.834226204999936, 35.211336574000029],
            [-80.833852253999964, 35.211438029000078],
            [-80.833481150999944, 35.211546294000073],
            [-80.83311307699995, 35.211661316000061],
            [-80.83274821699996, 35.211783041000047],
            [-80.832386748999966, 35.211911403000045],
            [-80.832028854999976, 35.212046343000054],
            [-80.831674712999984, 35.212187792000066],
            [-80.83132449499999, 35.212335680000024],
            [-80.830978378999987, 35.212489934000075],
            [-80.83063653399995, 35.212650477000068],
            [-80.830299130999947, 35.212817229000052],
            [-80.829966336999973, 35.212990109000032],
            [-80.829638317999979, 35.213169029000028],
            [-80.829315234999967, 35.213353901000062],
            [-80.828997249999986, 35.213544635000062],
            [-80.828684519999968, 35.213741133000042],
            [-80.828377199999977, 35.213943301000029],
            [-80.828075444999968, 35.214151036000032],
            [-80.82777939999994, 35.214364237000041],
            [-80.827489216999936, 35.214582796000059],
            [-80.827205035999953, 35.214806607000071],
            [-80.826927000999945, 35.215035557000078],
            [-80.826655247999952, 35.215269533000026],
            [-80.826389911999968, 35.215508419000059],
            [-80.82613112699994, 35.215752096000074],
            [-80.825879019999945, 35.216000444000031],
            [-80.825633714999981, 35.216253339000048],
            [-80.825395334999939, 35.216510656000025],
            [-80.825163997999937, 35.216772267000067],
            [-80.82493981999994, 35.21703804200007],
            [-80.82472291199997, 35.217307849000065],
            [-80.824513379999985, 35.217581555000038],
            [-80.824311328999954, 35.217859024000063],
            [-80.824116859999947, 35.218140118000065],
            [-80.82393006999996, 35.218424697000046],
            [-80.823751049999942, 35.21871261900003],
            [-80.823579889999962, 35.219003744000076],
            [-80.823416675999965, 35.219297925000035],
            [-80.823261486999968, 35.219595016000028],
            [-80.823114400999941, 35.219894872000054],
            [-80.822975490999966, 35.220197341000073],
            [-80.822844825999937, 35.220502275000058],
            [-80.822722471999953, 35.220809522000025],
            [-80.822608489999936, 35.221118930000046],
            [-80.822502932999953, 35.221430344000055],
            [-80.822405857999968, 35.221743611000079],
            [-80.822317311999939, 35.222058575000062],
            [-80.82223733699999, 35.222375079000074],
            [-80.822165973999972, 35.222692967000057],
            [-80.822103259999949, 35.223012081000036],
            [-80.822049224999944, 35.223332262000042],
            [-80.822003895999956, 35.223653350000063],
            [-80.821967294999979, 35.223975189000043],
            [-80.821939440999984, 35.224297616000058],
            [-80.821920347999935, 35.224620473000073],
            [-80.821910024999966, 35.224943599000028],
            [-80.821908476999965, 35.225266833000035],
            [-80.82191570699996, 35.22559001500008],
            [-80.821931708999955, 35.225912984000047],
            [-80.821956476999958, 35.226235581000026],
            [-80.821989996999946, 35.226557645000071],
            [-80.822032252999975, 35.226879016000055],
            [-80.822083225999961, 35.227199535000068],
            [-80.822142887999973, 35.227519043000029],
            [-80.822211211999957, 35.227837381000029],
            [-80.82228816199995, 35.228154390000043],
            [-80.822373700999947, 35.228469913000026],
            [-80.822467787999983, 35.228783796000073],
            [-80.822570371999973, 35.229095879000056],
            [-80.822681406999948, 35.229406010000048],
            [-80.822800836999988, 35.229714035000029],
            [-80.822928599999955, 35.230019799000047],
            [-80.82306463499998, 35.230323152000039],
            [-80.823208874999978, 35.230623942000079],
            [-80.82336124699998, 35.230922021000026],
            [-80.823521674999938, 35.231217241000024],
            [-80.823690080999938, 35.231509454000047],
            [-80.82386637999997, 35.231798516000026],
            [-80.824050486999965, 35.232084283000063],
            [-80.824242308999942, 35.232366614000057],
            [-80.824441748999959, 35.232645367000032],
            [-80.824648710999952, 35.232920405000073],
            [-80.824863092999976, 35.233191592000026],
            [-80.825084784999945, 35.233458792000079],
            [-80.825313678999976, 35.233721873000036],
            [-80.825549660999968, 35.233980705000079],
            [-80.825792614999955, 35.234235157000057],
            [-80.826042418999975, 35.234485105000033],
            [-80.826298949999966, 35.234730424000077],
            [-80.826562079999974, 35.234970993000047],
            [-80.826831678999952, 35.23520669100003],
            [-80.827107611999963, 35.235437403000049],
            [-80.827389743999959, 35.235663012000032],
            [-80.827677932999961, 35.235883408000063],
            [-80.827972036999938, 35.236098480000067],
            [-80.828271908999966, 35.236308123000072],
            [-80.828577401999951, 35.236512231000063],
            [-80.828888361999987, 35.236710705000064],
            [-80.829204635999986, 35.236903444000063],
            [-80.82952606799995, 35.237090353000042],
            [-80.829852495999944, 35.237271339000074],
            [-80.830183759999954, 35.237446314000067],
            [-80.830519692999985, 35.237615188000063],
            [-80.830860130999952, 35.237777880000067],
            [-80.831204903999947, 35.237934307000046],
            [-80.83155383899998, 35.238084393000065],
            [-80.831906764999985, 35.238228062000076],
            [-80.832263506999936, 35.238365243000032],
            [-80.832623884999975, 35.238495870000065],
            [-80.832987722999974, 35.23861987500004],
            [-80.83335483999997, 35.238737198000024],
            [-80.833725050999988, 35.238847781000061],
            [-80.834098174999951, 35.238951568000061],
            [-80.834474024999963, 35.239048508000053],
            [-80.83485241599999, 35.239138553000032],
            [-80.835233158999984, 35.239221659000066],
            [-80.835616062999975, 35.23929778300004],
            [-80.836000941999941, 35.239366889000053],
            [-80.836387601999945, 35.239428940000039],
            [-80.83677585199996, 35.239483908000068],
            [-80.83716549799999, 35.239531765000038],
            [-80.837556347999964, 35.239572487000032],
            [-80.837948205999965, 35.239606052000056],
            [-80.838340879999976, 35.239632447000076],
            [-80.838734172999978, 35.239651655000046],
            [-80.83912788899994, 35.239663670000027],
            [-80.839521834999971, 35.239668483000059],
            [-80.839915813999937, 35.239666094000029]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 145,
        "SolutionID": "PI-31:B",
        "Shape_Length": 0.10136465508815058,
        "Shape_Area": 0.00080574532343306914
      }
    },
    {
      "type": "Feature",
      "id": 146,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.831660951999936, 35.22902332700005],
            [-80.83035818999997, 35.227959856000041],
            [-80.828777598999977, 35.229219275000048],
            [-80.829852398999947, 35.230235436000044],
            [-80.831660951999936, 35.22902332700005]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 146,
        "SolutionID": "PI-26:D",
        "Shape_Length": 0.0073589887902793753,
        "Shape_Area": 3.2310958423912024e-6
      }
    },
    {
      "type": "Feature",
      "id": 147,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.862509036999938, 35.229037982000079],
            [-80.862581546999934, 35.22887606200004],
            [-80.861071978999973, 35.229042231000051],
            [-80.860329437999951, 35.22894645100007],
            [-80.859768659999986, 35.228704935000053],
            [-80.857773658999974, 35.227636769000071],
            [-80.856730858999981, 35.226786230000073],
            [-80.855664987999944, 35.225400025000056],
            [-80.854437331999975, 35.224078826000039],
            [-80.853967842999964, 35.223541079000029],
            [-80.85379452899997, 35.223579470000061],
            [-80.855089906999979, 35.22500683800007],
            [-80.855602944999987, 35.225543913000024],
            [-80.856590798999946, 35.226850914000067],
            [-80.857468105999942, 35.227605727000025],
            [-80.858759413999962, 35.228372040000067],
            [-80.860080540999945, 35.229012813000054],
            [-80.861119391999978, 35.229211239000051],
            [-80.862509036999938, 35.229037982000079]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 147,
        "SolutionID": "PI-27:G",
        "Shape_Length": 0.022001369935131382,
        "Shape_Area": 1.634689951472266e-6
      }
    },
    {
      "type": "Feature",
      "id": 148,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.868600706999985, 35.256175855000038],
            [-80.868578228999979, 35.256107592000035],
            [-80.867570093999973, 35.256523375000029],
            [-80.867634378999981, 35.256590992000042],
            [-80.868600706999985, 35.256175855000038]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 148,
        "SolutionID": "PI-29:F",
        "Shape_Length": 0.0023074034459577009,
        "Shape_Area": 8.5095786111009598e-8
      }
    },
    {
      "type": "Feature",
      "id": 149,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.876453543999958, 35.137809771000036],
            [-80.878070931999957, 35.137784643000032],
            [-80.881129123999983, 35.138094075000026],
            [-80.891306407999934, 35.135726159000058],
            [-80.895062520999943, 35.13497048000005],
            [-80.895925354999974, 35.134662132000074],
            [-80.896944600999973, 35.134065444000043],
            [-80.897461418999967, 35.133842920000063],
            [-80.897915711999985, 35.133737515000064],
            [-80.898405941999954, 35.133774482000035],
            [-80.898877134999964, 35.133927884000059],
            [-80.901340544999982, 35.135104127000034],
            [-80.903527241999939, 35.13607027300003],
            [-80.905341443999987, 35.136917181000058],
            [-80.90616911099994, 35.137431216000039],
            [-80.907862319999936, 35.138217460000078],
            [-80.914643210999941, 35.141228071000057],
            [-80.917304865999938, 35.142490191000036],
            [-80.919890708999958, 35.143762392000042],
            [-80.924640713999963, 35.146402685000055],
            [-80.926093612999978, 35.147129969000048],
            [-80.926655925999967, 35.147451553000053],
            [-80.927522679999981, 35.147768279000047],
            [-80.928395455999976, 35.147879428000067],
            [-80.92962132699995, 35.14774372100004],
            [-80.931292128999985, 35.147565157000031],
            [-80.931956844999945, 35.147617069000034],
            [-80.932603202999985, 35.147812210000041],
            [-80.933339933999946, 35.148148841000079],
            [-80.933863875999975, 35.148685416000035],
            [-80.934429555999941, 35.149605471000029],
            [-80.934838573999969, 35.150340424000035],
            [-80.935341428999948, 35.150904132000051],
            [-80.936632465999935, 35.151678552000078],
            [-80.937734647999946, 35.152286247000063],
            [-80.938541140999973, 35.152809338000054],
            [-80.938942564999934, 35.153222784000036],
            [-80.93947783699997, 35.154232642000068],
            [-80.939850901999989, 35.154825215000074],
            [-80.940306924999959, 35.155246714000043],
            [-80.940979363999986, 35.155620067000029],
            [-80.941746480999939, 35.155857891000039],
            [-80.942511475999936, 35.156006404000038],
            [-80.943444918999944, 35.15591992800006],
            [-80.950325547999967, 35.153432709000072],
            [-80.953647209999986, 35.152691158000039],
            [-80.957798179999941, 35.152061157000048],
            [-80.958342912999967, 35.152096999000037],
            [-80.961336574999962, 35.15264701600006],
            [-80.968048347999968, 35.154011979000074],
            [-80.969851963999986, 35.154375682000079],
            [-80.970004193999955, 35.154373200000066],
            [-80.96996747299994, 35.154204057000072],
            [-80.968274964999978, 35.153936818000034],
            [-80.958393203999947, 35.151926443000036],
            [-80.957893035999973, 35.151934547000053],
            [-80.953362149999975, 35.152597495000066],
            [-80.952927644999988, 35.15262238400004],
            [-80.951215857999955, 35.152909113000078],
            [-80.945552307999947, 35.154885442000079],
            [-80.943977959999984, 35.155464668000036],
            [-80.943134705999967, 35.155683705000058],
            [-80.942253931999971, 35.155697862000068],
            [-80.941411973999948, 35.155514848000053],
            [-80.940772579999987, 35.155158834000076],
            [-80.940373687999966, 35.154852558000073],
            [-80.939882070999943, 35.154306557000041],
            [-80.939519243999939, 35.153687019000074],
            [-80.939358941999956, 35.15335010900003],
            [-80.938698294999938, 35.15255667200006],
            [-80.936005961999967, 35.150947090000045],
            [-80.935452340999973, 35.150536072000079],
            [-80.934926468999947, 35.149919131000047],
            [-80.934245908999969, 35.148741838000035],
            [-80.93377653899995, 35.148213325000029],
            [-80.93317733899994, 35.147713689000057],
            [-80.932222734999982, 35.147362673000032],
            [-80.931197954999959, 35.147262915000056],
            [-80.93014584499997, 35.147386929000049],
            [-80.928573320999988, 35.147581776000038],
            [-80.927801147999958, 35.147585163000031],
            [-80.927187631999971, 35.147398406000036],
            [-80.926306422999971, 35.146930035000025],
            [-80.924554486999966, 35.145975238000062],
            [-80.922284640999976, 35.144733845000076],
            [-80.919002643999988, 35.142999265000071],
            [-80.917020885999989, 35.141976541000076],
            [-80.915526350999983, 35.141321279000067],
            [-80.911480267999934, 35.139455670000075],
            [-80.910556376999978, 35.139014662000079],
            [-80.909337499999936, 35.138515773000051],
            [-80.90876611799996, 35.138265722000028],
            [-80.90739192999996, 35.13763526200006],
            [-80.906074623999984, 35.137111093000044],
            [-80.905151622999938, 35.136705766000034],
            [-80.904127661999951, 35.136168017000045],
            [-80.902808733999962, 35.135572369000045],
            [-80.901809459999981, 35.135159284000054],
            [-80.900820859999953, 35.134737088000065],
            [-80.899786080999945, 35.13419947400007],
            [-80.898928161999947, 35.133784143000071],
            [-80.898456553999949, 35.133612880000044],
            [-80.898162207999974, 35.133581770000035],
            [-80.897304653999981, 35.133648841000024],
            [-80.896530464999955, 35.13402727700003],
            [-80.89608177599996, 35.134373799000059],
            [-80.895503460999976, 35.134758088000069],
            [-80.893565627999976, 35.135136886000055],
            [-80.891573421999965, 35.135516505000055],
            [-80.884467586999961, 35.137208797000028],
            [-80.881275765999987, 35.137919625000052],
            [-80.879282574999934, 35.137789835000035],
            [-80.87832238499999, 35.137652893000052],
            [-80.877735324999946, 35.137662017000025],
            [-80.876405304999935, 35.137521872000036],
            [-80.876453543999958, 35.137809771000036]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 149,
        "SolutionID": "PI-12:H",
        "Shape_Length": 0.20382671979913536,
        "Shape_Area": 2.2182141669501605e-5
      }
    },
    {
      "type": "Feature",
      "id": 151,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.981352773999959, 34.870199636000052],
            [-80.980594408999934, 34.870235815000058],
            [-80.979367160999971, 34.870305354000038],
            [-80.978465792999941, 34.870362659000079],
            [-80.977562301999967, 34.870429175000027],
            [-80.977053111999965, 34.87045385600004],
            [-80.976352650999956, 34.870547681000062],
            [-80.974530791999939, 34.870643324000071],
            [-80.972819634999951, 34.870869817000028],
            [-80.97153293599996, 34.871074236000027],
            [-80.970046537999963, 34.871342885000047],
            [-80.968570118999935, 34.871646712000029],
            [-80.967104946999939, 34.871985457000051],
            [-80.965652232999958, 34.872358839000071],
            [-80.963993929999958, 34.87283044600008],
            [-80.962788907999936, 34.873208262000048],
            [-80.96138068199997, 34.873683598000071],
            [-80.959989623999945, 34.874192175000076],
            [-80.958616933999963, 34.874733554000045],
            [-80.957263736999948, 34.87530729000008],
            [-80.955710193999948, 34.876015617000064],
            [-80.954620248999959, 34.876549921000048],
            [-80.953332158999956, 34.877217783000049],
            [-80.952067935999935, 34.877915945000041],
            [-80.950828630999979, 34.878643830000044],
            [-80.949615260999963, 34.879400840000073],
            [-80.948216426999977, 34.880329998000036],
            [-80.947270412999956, 34.880999648000056],
            [-80.946140849999949, 34.88184013800003],
            [-80.945041119999985, 34.882707099000072],
            [-80.943972149999979, 34.883599801000059],
            [-80.942934820999938, 34.884517508000044],
            [-80.941929993999963, 34.885459458000071],
            [-80.941544186999977, 34.885829398000055],
            [-80.940958508999984, 34.886424867000073],
            [-80.940021164999962, 34.887412939000058],
            [-80.939118744999973, 34.888422852000076],
            [-80.938251997999942, 34.889453769000056],
            [-80.937421644999972, 34.89050483300008],
            [-80.936628377999966, 34.891575171000056],
            [-80.936304328999938, 34.892024435000053],
            [-80.935872849999953, 34.892663902000038],
            [-80.935155702999964, 34.893770108000069],
            [-80.934492913999975, 34.894865498000058],
            [-80.933883165999987, 34.895948450000049],
            [-80.933515743999976, 34.896630172000073],
            [-80.933257118999961, 34.89712445400005],
            [-80.933036662999939, 34.89754801600003],
            [-80.932860120999976, 34.89788855300003],
            [-80.932635523999977, 34.898320237000064],
            [-80.932260199999973, 34.899043293000034],
            [-80.931938142999968, 34.899662294000052],
            [-80.931662436999943, 34.90019691100008],
            [-80.931514299999947, 34.900480383000058],
            [-80.931382024999948, 34.900736166000058],
            [-80.931178597999974, 34.901126165000051],
            [-80.930759710999951, 34.901938176000044],
            [-80.930294301999936, 34.902878499000053],
            [-80.930017452999948, 34.90344895700008],
            [-80.929590565999945, 34.904274379000071],
            [-80.929054753999935, 34.905308121000076],
            [-80.928697249999971, 34.905992618000028],
            [-80.928253592999965, 34.906854917000032],
            [-80.92796696299996, 34.907405806000043],
            [-80.927521462999948, 34.908252115000039],
            [-80.927232190999973, 34.908811613000069],
            [-80.926993098999958, 34.909278310000047],
            [-80.926507825999977, 34.910210878000044],
            [-80.926059291999934, 34.911075652000079],
            [-80.925702222999973, 34.911762779000071],
            [-80.925242523999941, 34.912646676000065],
            [-80.924856254999952, 34.913388394000037],
            [-80.924450440999976, 34.914168532000076],
            [-80.92398473999998, 34.915065245000051],
            [-80.923447092999936, 34.916097637000064],
            [-80.92314098899999, 34.916694171000074],
            [-80.922783785999968, 34.917400345000033],
            [-80.922260603999973, 34.918485410000073],
            [-80.921876229999953, 34.919327266000039],
            [-80.921505054999955, 34.920198071000073],
            [-80.921029268999973, 34.921389380000051],
            [-80.920734100999937, 34.922177158000068],
            [-80.920519271999979, 34.922791025000038],
            [-80.920386701999973, 34.923181854000063],
            [-80.920256293999955, 34.923582074000024],
            [-80.92000474799994, 34.924384501000077],
            [-80.919843484999944, 34.924925639000037],
            [-80.919661700999939, 34.925583367000058],
            [-80.919403944999942, 34.926584303000027],
            [-80.919241641999974, 34.927264711000078],
            [-80.919124014999966, 34.92779762400005],
            [-80.919009215999949, 34.928356093000048],
            [-80.918889771999943, 34.928994763000048],
            [-80.918725182999935, 34.929966361000027],
            [-80.918632729999956, 34.930573540000069],
            [-80.918569329999968, 34.931036023000047],
            [-80.918499721999979, 34.931613635000076],
            [-80.918377081999949, 34.932790858000033],
            [-80.918314296999938, 34.933548552000047],
            [-80.918288759999939, 34.933969601000058],
            [-80.918249954999965, 34.934762127000056],
            [-80.918216596999969, 34.93590795700004],
            [-80.918211563999989, 34.936526364000031],
            [-80.918227774999934, 34.937485930000037],
            [-80.918250343999944, 34.938138510000044],
            [-80.918288872999938, 34.938905911000063],
            [-80.918324278999989, 34.939443378000078],
            [-80.918387516999985, 34.940176504000078],
            [-80.918498104999969, 34.941225271000064],
            [-80.918561101999956, 34.941749093000055],
            [-80.918615808999959, 34.942153305000033],
            [-80.918714138999974, 34.942799390000062],
            [-80.918897804999972, 34.94387649500004],
            [-80.918997815999944, 34.944414106000067],
            [-80.919109456999934, 34.94496403200003],
            [-80.91928601099994, 34.945748485000024],
            [-80.919597129999943, 34.947002123000061],
            [-80.919770232999952, 34.947649223000042],
            [-80.919867370999953, 34.947986813000057],
            [-80.920076006999977, 34.948686340000052],
            [-80.920251261999965, 34.949238394000076],
            [-80.920520453999984, 34.950044794000064],
            [-80.920786876999955, 34.950794471000052],
            [-80.92102050699998, 34.95141505600003],
            [-80.921194975999981, 34.951863307000053],
            [-80.92138276899999, 34.952333098000054],
            [-80.92150535899998, 34.952631730000064],
            [-80.921778011999947, 34.953274135000072],
            [-80.922033302999978, 34.953864254000052],
            [-80.922388385999966, 34.954691646000072],
            [-80.922672409999961, 34.955357236000054],
            [-80.922990439999978, 34.956094636000046],
            [-80.923231093999959, 34.956645913000045],
            [-80.923423876999948, 34.957100909000076],
            [-80.923553211999945, 34.957399046000035],
            [-80.923705148999943, 34.957756346000053],
            [-80.923902229999953, 34.958209941000064],
            [-80.924228640999956, 34.958977253000057],
            [-80.924491972999988, 34.959585812000057],
            [-80.92473954999997, 34.960152657000037],
            [-80.924985087999971, 34.960724126000059],
            [-80.925169035999943, 34.96114863400004],
            [-80.925358021999955, 34.961586338000075],
            [-80.925613268999939, 34.962180400000079],
            [-80.926031087999945, 34.963149774000044],
            [-80.926334831999952, 34.963849768000046],
            [-80.92665696399996, 34.964608483000063],
            [-80.926884403999964, 34.96514387700006],
            [-80.927129358999935, 34.965701050000064],
            [-80.927405796999949, 34.96634748200006],
            [-80.92774478299998, 34.96714100500003],
            [-80.928082323999945, 34.96793120600006],
            [-80.928339813999969, 34.968529039000032],
            [-80.928547699999967, 34.969012176000035],
            [-80.928966564999939, 34.969993392000049],
            [-80.929198434999989, 34.970527663000041],
            [-80.929594904999988, 34.971449482000025],
            [-80.929795934999959, 34.971925296000052],
            [-80.93008733299996, 34.972614667000073],
            [-80.930383595999956, 34.97329312200003],
            [-80.930731121999941, 34.974117534000072],
            [-80.931133428999942, 34.975165154000024],
            [-80.930639901999939, 34.975823310000067],
            [-80.930326964999949, 34.976287537000076],
            [-80.930038757999966, 34.976711247000026],
            [-80.929434653999976, 34.977598680000028],
            [-80.929144373999975, 34.978031122000061],
            [-80.928807867999978, 34.978517046000036],
            [-80.928511354999955, 34.978950184000041],
            [-80.927960623999979, 34.979769081000029],
            [-80.927709689999972, 34.980146194000042],
            [-80.92713753999999, 34.98099611400005],
            [-80.926855972999988, 34.981425665000074],
            [-80.926325590999966, 34.982471942000075],
            [-80.925981432999947, 34.982942598000079],
            [-80.925655432999974, 34.983409154000071],
            [-80.925162674999967, 34.984243520000064],
            [-80.924607975999947, 34.985191687000054],
            [-80.924404488999983, 34.98555977500007],
            [-80.924208345999944, 34.985930554000049],
            [-80.924019600999941, 34.986303925000072],
            [-80.923838302999968, 34.98667978800006],
            [-80.923664500999962, 34.987058042000058],
            [-80.923498242999983, 34.987438585000064],
            [-80.923339572999964, 34.987821317000055],
            [-80.923188532999973, 34.988206134000052],
            [-80.922677764999946, 34.988991798000029],
            [-80.922489266999946, 34.989407205000077],
            [-80.92224534099995, 34.989955331000033],
            [-80.922080523999966, 34.990334450000034],
            [-80.921824636999986, 34.990942071000063],
            [-80.921520278999935, 34.991690110000036],
            [-80.921295864999934, 34.992260632000068],
            [-80.92115780599994, 34.992625372000077],
            [-80.920954248999976, 34.99316458900006],
            [-80.920739527999956, 34.993758283000034],
            [-80.920576807999964, 34.994223247000036],
            [-80.920346638999945, 34.994902737000075],
            [-80.920057512999961, 34.995801632000052],
            [-80.91983171399994, 34.996543493000047],
            [-80.919692063999946, 34.997016554000027],
            [-80.919586849999973, 34.997385188000067],
            [-80.919401289999939, 34.998069961000056],
            [-80.919222458999968, 34.99876254600008],
            [-80.919069228999945, 34.999387780000063],
            [-80.918903143999955, 35.000104423000039],
            [-80.918680105999954, 35.001126957000054],
            [-80.918566599999963, 35.001676992000057],
            [-80.918400151999947, 35.002523384000028],
            [-80.918262004999974, 35.003200975000027],
            [-80.918181446999938, 35.003600548000065],
            [-80.918018871999948, 35.004408010000077],
            [-80.91782152199994, 35.005361009000069],
            [-80.917613674999984, 35.006392078000033],
            [-80.917506508999963, 35.006909386000075],
            [-80.917402325999944, 35.007429349000063],
            [-80.917291449999937, 35.007966857000042],
            [-80.91715643799995, 35.008620551000035],
            [-80.91707650099994, 35.009016342000052],
            [-80.916974024999945, 35.009373958000026],
            [-80.916631535999954, 35.009879193000074],
            [-80.916301351999948, 35.010366306000037],
            [-80.915679956999952, 35.01131128600008],
            [-80.915376220999974, 35.011787130000073],
            [-80.914931374999981, 35.012506475000066],
            [-80.914665272999969, 35.012946294000074],
            [-80.91437024399994, 35.013447867000025],
            [-80.913952001999974, 35.014179003000038],
            [-80.913445820999982, 35.015099685000052],
            [-80.913159726999936, 35.015641499000026],
            [-80.912826719999941, 35.016293904000065],
            [-80.912545398999953, 35.01685924800006],
            [-80.912223005999977, 35.017527410000071],
            [-80.911839933999943, 35.018353149000063],
            [-80.911662249999949, 35.018749787000047],
            [-80.911302576999958, 35.019582771000046],
            [-80.91087952099997, 35.020611919000032],
            [-80.910544254999934, 35.021471443000053],
            [-80.910265826999989, 35.022220033000053],
            [-80.909990518999962, 35.023006420000058],
            [-80.909643181999968, 35.024053544000026],
            [-80.909347594999986, 35.025000937000073],
            [-80.909150936999936, 35.025681533000068],
            [-80.908950230999949, 35.026434433000077],
            [-80.908705877999978, 35.027408180000066],
            [-80.908540181999967, 35.028108616000054],
            [-80.90838410799995, 35.028808131000062],
            [-80.908268623999959, 35.02936690100006],
            [-80.908084154999983, 35.030345767000028],
            [-80.907956666999951, 35.031093742000053],
            [-80.907871850999982, 35.031631148000031],
            [-80.907737059999988, 35.03254706000007],
            [-80.907619015999956, 35.033404683000072],
            [-80.907521637999935, 35.034122045000061],
            [-80.907382233999954, 35.03519417800004],
            [-80.907332286999974, 35.035570793000034],
            [-80.90723337299994, 35.036323117000052],
            [-80.907147926999983, 35.03704216400007],
            [-80.907070558999976, 35.03759941800007],
            [-80.906978244999948, 35.038260705000027],
            [-80.90689297199998, 35.038832391000028],
            [-80.906827045999989, 35.039303867000058],
            [-80.906649930999947, 35.040666679000026],
            [-80.906522393999978, 35.041607608000049],
            [-80.906306088999941, 35.043217367000068],
            [-80.906232726999974, 35.043796593000025],
            [-80.906170094999936, 35.044231448000062],
            [-80.906036341999936, 35.045215645000042],
            [-80.905315738999946, 35.046149867000054],
            [-80.904856094999957, 35.046759683000062],
            [-80.903761956999972, 35.048246255000038],
            [-80.903378773999975, 35.048767303000034],
            [-80.901839088999964, 35.050835457000062],
            [-80.900325136999982, 35.052868923000062],
            [-80.899275694999972, 35.054255586000068],
            [-80.898905622999962, 35.054744562000053],
            [-80.898341092999942, 35.05549374900005],
            [-80.89762196099997, 35.056468944000073],
            [-80.897075757999971, 35.057211926000036],
            [-80.896739943999989, 35.057649878000063],
            [-80.896322322999936, 35.058199135000052],
            [-80.89578628299995, 35.058922191000079],
            [-80.895320846999937, 35.059524613000065],
            [-80.894644586999959, 35.06041739300008],
            [-80.894325354999978, 35.060846339000079],
            [-80.893298631999983, 35.062249447000056],
            [-80.892063503999964, 35.063857178000035],
            [-80.89100956599998, 35.065264009000032],
            [-80.889827471999979, 35.066872989000046],
            [-80.889222759999939, 35.06768407800007],
            [-80.888667040999962, 35.068439874000035],
            [-80.888111820999939, 35.069217045000073],
            [-80.887545087999968, 35.070034784000029],
            [-80.887194120999936, 35.070558629000061],
            [-80.886509817999979, 35.071614050000051],
            [-80.885998229999984, 35.072445479000066],
            [-80.885561008999957, 35.073198447000038],
            [-80.885288512999978, 35.07368187700007],
            [-80.885002258999975, 35.07419881800007],
            [-80.884646414999963, 35.074858445000075],
            [-80.884286517999954, 35.075564385000064],
            [-80.883789906999937, 35.076588646000062],
            [-80.883549495999944, 35.077103350000073],
            [-80.88336557599996, 35.077513586000066],
            [-80.883236991999979, 35.077805814000044],
            [-80.883093164999934, 35.078140251000036],
            [-80.882950585999936, 35.078477376000023],
            [-80.882763359999956, 35.078937490000044],
            [-80.882401422999976, 35.079861054000048],
            [-80.882147079999982, 35.080548775000068],
            [-80.881952405999982, 35.081110471000045],
            [-80.881661215999941, 35.081986879000056],
            [-80.881363309999983, 35.082939506000059],
            [-80.881180756999981, 35.083566273000031],
            [-80.88101484699996, 35.084178924000071],
            [-80.880791614999964, 35.085047862000067],
            [-80.880563668999969, 35.085982225000066],
            [-80.880396215999951, 35.086713638000049],
            [-80.880292201999964, 35.087212813000065],
            [-80.88012133899997, 35.088107042000047],
            [-80.880025655999987, 35.088654201000054],
            [-80.879830052999978, 35.089724897000053],
            [-80.879648040999939, 35.09081438000004],
            [-80.879072411999971, 35.091767796000056],
            [-80.878323677999958, 35.092861615000061],
            [-80.877877112999954, 35.09347032200003],
            [-80.877542571999982, 35.093926361000058],
            [-80.877010179999957, 35.094651947000045],
            [-80.87647553499994, 35.095366618000071],
            [-80.875836380999942, 35.096248479000053],
            [-80.875324872999954, 35.096997659000067],
            [-80.87460521099996, 35.098103391000052],
            [-80.874151732999962, 35.098832819000052],
            [-80.873840555999948, 35.099357123000061],
            [-80.873421839999935, 35.100067248000073],
            [-80.872845609999956, 35.101085028000057],
            [-80.872451524999974, 35.101808697000024],
            [-80.872255428999949, 35.102166360000069],
            [-80.872054504999937, 35.102535463000038],
            [-80.87184879199998, 35.102911520000077],
            [-80.871507225999949, 35.10355136700008],
            [-80.871242093999967, 35.103970600000025],
            [-80.870878865999941, 35.104510657000048],
            [-80.870147876999965, 35.105636335000042],
            [-80.869676538999954, 35.106319244000076],
            [-80.869417772999952, 35.106686525000043],
            [-80.868860377999965, 35.107490502000076],
            [-80.868396659999974, 35.108179133000078],
            [-80.868170006999947, 35.108514887000069],
            [-80.86720584599999, 35.109478153000055],
            [-80.865950716999976, 35.110640373000024],
            [-80.865090129999942, 35.111441494000076],
            [-80.864321056999984, 35.112148381000054],
            [-80.863919733999978, 35.112522718000037],
            [-80.863432147999958, 35.112984095000058],
            [-80.863132878999977, 35.113262832000032],
            [-80.862679327999956, 35.113672060000056],
            [-80.862156148999986, 35.114149518000033],
            [-80.861481322999964, 35.11477996800005],
            [-80.861073215999966, 35.115151788000048],
            [-80.860631806999947, 35.115552340000079],
            [-80.860086739999986, 35.116055842000037],
            [-80.859461710999938, 35.116643174000046],
            [-80.858941303999984, 35.117143273000067],
            [-80.858489296999949, 35.117553673000032],
            [-80.857990787999938, 35.118014154000036],
            [-80.857121456999948, 35.118832242000053],
            [-80.85648333499995, 35.119436567000037],
            [-80.855975635999982, 35.119916302000036],
            [-80.855037548999974, 35.120800744000064],
            [-80.854389836999985, 35.121451800000045],
            [-80.853554033999956, 35.122322573000076],
            [-80.852921905999949, 35.12300547500007],
            [-80.852617270999986, 35.123342895000064],
            [-80.851988848999952, 35.124057958000037],
            [-80.851303211999948, 35.124865740000075],
            [-80.851003184999968, 35.125229268000055],
            [-80.850547748999986, 35.125794337000059],
            [-80.850128583999947, 35.126330100000075],
            [-80.849607749999961, 35.127015785000026],
            [-80.849351215999945, 35.127360600000031],
            [-80.849123003999978, 35.127674811000077],
            [-80.848771485999976, 35.128167182000027],
            [-80.848519440999951, 35.128530542000078],
            [-80.848132174999989, 35.129103344000043],
            [-80.847792830999936, 35.129619889000026],
            [-80.84727892799998, 35.130428707000078],
            [-80.846946558999946, 35.130972395000072],
            [-80.846624060999943, 35.13152040500006],
            [-80.846215540999935, 35.132235173000026],
            [-80.845734902999936, 35.133109018000027],
            [-80.845447724999985, 35.133653308000078],
            [-80.845202602999962, 35.134137113000065],
            [-80.844855597999981, 35.134843077000028],
            [-80.844323758999963, 35.135979399000064],
            [-80.84409371199996, 35.136501890000034],
            [-80.843784526999968, 35.137239557000044],
            [-80.843495503999975, 35.137959223000053],
            [-80.843207043999939, 35.138710150000065],
            [-80.842963357999963, 35.139385825000033],
            [-80.842708597999945, 35.140136635000033],
            [-80.842552190999982, 35.140617801000076],
            [-80.842416535999973, 35.141030985000043],
            [-80.842286920999982, 35.141385124000067],
            [-80.842131219999942, 35.141814478000072],
            [-80.841946284999949, 35.142335819000039],
            [-80.841672675999973, 35.143077125000048],
            [-80.841525004999937, 35.143488953000031],
            [-80.841238100999988, 35.144308735000038],
            [-80.841081948999943, 35.144767146000049],
            [-80.840858266999987, 35.145432956000036],
            [-80.840596888999983, 35.146168388000035],
            [-80.840241463999973, 35.147189336000054],
            [-80.840007068999967, 35.147852913000065],
            [-80.839708500999961, 35.148738939000054],
            [-80.83947995699998, 35.149401675000036],
            [-80.839313893999986, 35.149864879000063],
            [-80.839117293999948, 35.150425860000041],
            [-80.838858157999937, 35.151181382000061],
            [-80.838620795999987, 35.151908215000049],
            [-80.838316655999961, 35.152897470000028],
            [-80.837594828999954, 35.155406477000042],
            [-80.837405955999941, 35.156035017000079],
            [-80.837201289999939, 35.156706177000046],
            [-80.83704559499995, 35.157247344000041],
            [-80.836578021999969, 35.15885193400004],
            [-80.836452512999983, 35.159295696000072],
            [-80.836197843999969, 35.160223590000044],
            [-80.83585956099995, 35.161382644000071],
            [-80.835520364999979, 35.162560279000047],
            [-80.835281168999984, 35.163385590000075],
            [-80.835056527999939, 35.164161573000058],
            [-80.834884909999971, 35.164750360000028],
            [-80.834681478999983, 35.165462309000077],
            [-80.834501580999984, 35.166119923000053],
            [-80.834323476999941, 35.166810618000056],
            [-80.834213357999943, 35.167263196000079],
            [-80.834088262999956, 35.167804229000069],
            [-80.834016735999967, 35.168126491000066],
            [-80.833931121999967, 35.168534854000029],
            [-80.833821139999941, 35.169087855000043],
            [-80.833733522999978, 35.169557728000029],
            [-80.833704354999952, 35.170517227000062],
            [-80.833633364999969, 35.170772549000048],
            [-80.833569762999957, 35.171029181000051],
            [-80.833513584999935, 35.171286979000058],
            [-80.833464861999971, 35.171545797000078],
            [-80.833423622999987, 35.171805491000043],
            [-80.833389888999989, 35.172065914000029],
            [-80.83336368099998, 35.172326921000035],
            [-80.83331941299997, 35.172752870000068],
            [-80.833253204999949, 35.173303646000079],
            [-80.833202451999966, 35.17372262300006],
            [-80.833157171999972, 35.174193476000028],
            [-80.83313018399997, 35.174747847000049],
            [-80.83310832799998, 35.175511072000063],
            [-80.833118960999968, 35.176275377000024],
            [-80.83314844299997, 35.17650709600008],
            [-80.833170540999959, 35.176739369000074],
            [-80.833185237999942, 35.176972037000041],
            [-80.833192524999959, 35.17720494100007],
            [-80.833192396999948, 35.177437921000035],
            [-80.833184852999977, 35.17767081900007],
            [-80.833169898999984, 35.17790347600004],
            [-80.833147544999974, 35.178135733000033],
            [-80.83319206699997, 35.178570594000064],
            [-80.833258314999966, 35.179380627000057],
            [-80.833155301999966, 35.179998229000034],
            [-80.833194814999956, 35.180524324000032],
            [-80.83323163099999, 35.180949551000026],
            [-80.833281672999988, 35.181465026000069],
            [-80.833287621999943, 35.181884800000034],
            [-80.832805174999976, 35.182891206000079],
            [-80.832342421999954, 35.183478341000068],
            [-80.831826847999935, 35.184136760000058],
            [-80.831029503999957, 35.185206246000064],
            [-80.830556162999983, 35.185867335000069],
            [-80.830202306999979, 35.186382437000077],
            [-80.829871969999942, 35.186873920000039],
            [-80.829443608999952, 35.187526638000065],
            [-80.828940855999974, 35.188317780000034],
            [-80.82864228699998, 35.188804283000024],
            [-80.828389376999951, 35.18923056400007],
            [-80.828056792999973, 35.189804000000038],
            [-80.82759055799994, 35.190631575000054],
            [-80.827309475999982, 35.191147839000052],
            [-80.826981990999968, 35.191758352000079],
            [-80.826641093999967, 35.192401687000029],
            [-80.826345365999941, 35.19296212900008],
            [-80.826060705999964, 35.193488509000076],
            [-80.82579852799995, 35.193976431000067],
            [-80.825365857999941, 35.194798185000025],
            [-80.825042986999961, 35.195407714000055],
            [-80.824581942999941, 35.196263411000075],
            [-80.824045208999962, 35.19670942700003],
            [-80.823706111999968, 35.196996564000074],
            [-80.823151049999979, 35.197480247000044],
            [-80.822311002999982, 35.198237973000062],
            [-80.821865917999958, 35.198645704000057],
            [-80.821388849999948, 35.199094152000043],
            [-80.820932429999971, 35.199535528000069],
            [-80.820361808999962, 35.200110003000077],
            [-80.819527156999982, 35.200981322000075],
            [-80.819061115999943, 35.201475043000073],
            [-80.818700554999964, 35.201866684000038],
            [-80.818076877999943, 35.20257312800004],
            [-80.81720545099995, 35.203603017000034],
            [-80.816600104999964, 35.20435090400008],
            [-80.816158607999967, 35.204923131000044],
            [-80.815769426999964, 35.205440008000039],
            [-80.815303467999968, 35.206075198000065],
            [-80.814911471999949, 35.206623662000027],
            [-80.814530167999976, 35.207174468000062],
            [-80.814072931999988, 35.207857036000064],
            [-80.81372485299994, 35.208392355000058],
            [-80.813440562999972, 35.208841145000065],
            [-80.813260419999949, 35.209131257000024],
            [-80.812646064999967, 35.210089664000066],
            [-80.812057911999943, 35.21054149400004],
            [-80.811261785999989, 35.211140009000076],
            [-80.810665920999952, 35.211601873000063],
            [-80.80987866199996, 35.212236545000053],
            [-80.809448801999963, 35.212594324000065],
            [-80.808865983999965, 35.213090512000065],
            [-80.80845560399996, 35.213447573000053],
            [-80.807897434999973, 35.21395153800006],
            [-80.806887126999982, 35.214892295000027],
            [-80.806499197999983, 35.215261780000048],
            [-80.806096044999947, 35.215664261000029],
            [-80.805514035999977, 35.216259686000058],
            [-80.805098889999954, 35.216695288000039],
            [-80.804583873999945, 35.217252071000075],
            [-80.803990364999947, 35.217922608000038],
            [-80.803176852999968, 35.218879493000031],
            [-80.802695282999935, 35.219465491000051],
            [-80.802353367999956, 35.219900109000037],
            [-80.801886062999984, 35.220509314000026],
            [-80.801539644999934, 35.220973251000032],
            [-80.801017412999954, 35.221696964000046],
            [-80.800711219999982, 35.222137949000057],
            [-80.800331189999952, 35.222700821000046],
            [-80.800022583999976, 35.22316931000006],
            [-80.799740575999976, 35.223606897000025],
            [-80.799407081999959, 35.22413668400003],
            [-80.798911094999937, 35.224967449000076],
            [-80.798268146999987, 35.226104938000049],
            [-80.797899067999936, 35.226788415000044],
            [-80.797731995999982, 35.227113757000041],
            [-80.797506470999963, 35.227567011000076],
            [-80.797160468999948, 35.22828479900005],
            [-80.796872900999972, 35.22890629300008],
            [-80.796629591999988, 35.229456175000053],
            [-80.796339415999967, 35.230142411000031],
            [-80.796133162999979, 35.230654250000043],
            [-80.795860262999952, 35.231360038000048],
            [-80.795680660999949, 35.231843353000045],
            [-80.795433435999939, 35.232558575000041],
            [-80.795060759999956, 35.233708444000058],
            [-80.794744243999958, 35.234797019000041],
            [-80.794479784999965, 35.235822734000067],
            [-80.794286309999961, 35.236613159000058],
            [-80.79394195499998, 35.237455407000027],
            [-80.793630388999986, 35.238153935000071],
            [-80.793367135999972, 35.238730561000068],
            [-80.793105602999958, 35.239315391000048],
            [-80.792892141999971, 35.23978687500005],
            [-80.792714846999957, 35.24017525000005],
            [-80.792519895999988, 35.240611749000038],
            [-80.792245756999989, 35.241215938000039],
            [-80.791807272999961, 35.242146578000074],
            [-80.791631973999984, 35.242529268000055],
            [-80.791176568999958, 35.243555516000072],
            [-80.790983714999982, 35.244005407000031],
            [-80.790532484999972, 35.245103762000042],
            [-80.79025526099997, 35.245816811000054],
            [-80.790057451999985, 35.246346456000026],
            [-80.789920045999963, 35.246727669000052],
            [-80.789798856999937, 35.247075799000072],
            [-80.789560446999985, 35.247787434000031],
            [-80.789417654999966, 35.248235603000069],
            [-80.789250971999934, 35.248781233000045],
            [-80.789119623999966, 35.249230121000039],
            [-80.788991768999949, 35.249691631000076],
            [-80.788827728999934, 35.250311999000076],
            [-80.788705010999934, 35.250802078000049],
            [-80.78844799999996, 35.251930815000037],
            [-80.788316348999956, 35.252576943000065],
            [-80.788198096999963, 35.253207520000046],
            [-80.788113329999987, 35.253694242000051],
            [-80.787977524999974, 35.254565435000075],
            [-80.787906833999955, 35.255078182000034],
            [-80.787827153999956, 35.255719514000077],
            [-80.787782229999948, 35.256115685000054],
            [-80.787745474999952, 35.256485546000079],
            [-80.787692800999935, 35.257075067000073],
            [-80.787663137999971, 35.257449456000074],
            [-80.787637165999968, 35.257847470000058],
            [-80.787602135999975, 35.258490490000042],
            [-80.787584773999981, 35.258887562000041],
            [-80.787573377999934, 35.259275940000066],
            [-80.787561581999967, 35.259901220000074],
            [-80.787558306999983, 35.260292999000058],
            [-80.78755887799997, 35.260601263000069],
            [-80.787566459999937, 35.261230086000069],
            [-80.787583300999984, 35.26180363800006],
            [-80.787619957999937, 35.262620067000057],
            [-80.787694141999964, 35.263640867000049],
            [-80.787763846999951, 35.264439321000054],
            [-80.787813185999937, 35.264925492000032],
            [-80.787905871999953, 35.265696511000044],
            [-80.788040322999962, 35.26660283700005],
            [-80.788256779999983, 35.267844055000069],
            [-80.788443442999949, 35.268773190000047],
            [-80.78860191299998, 35.269475477000071],
            [-80.788717825999981, 35.269958540000061],
            [-80.788879743999985, 35.270598203000077],
            [-80.789017211999976, 35.271112856000059],
            [-80.789171450999959, 35.271657502000039],
            [-80.789368631999935, 35.272314281000035],
            [-80.789532179999981, 35.272828656000058],
            [-80.789763834999974, 35.273523667000063],
            [-80.789929093999945, 35.27399957800003],
            [-80.79006702099997, 35.274378794000029],
            [-80.79034355999994, 35.275109852000071],
            [-80.790551821999941, 35.275632881000035],
            [-80.790840366999987, 35.276328486000068],
            [-80.791063272999963, 35.276845871000035],
            [-80.791311939999957, 35.277398952000055],
            [-80.791655565999974, 35.278132209000034],
            [-80.791909942999951, 35.278653317000078],
            [-80.792033320999963, 35.279258063000043],
            [-80.792113601999972, 35.279811000000052],
            [-80.792189061999977, 35.280282168000042],
            [-80.79230292799997, 35.280940886000053],
            [-80.792391969999983, 35.28141989900007],
            [-80.792520459999935, 35.282057016000067],
            [-80.792642658999966, 35.282616492000045],
            [-80.792743204999965, 35.283049257000073],
            [-80.792916938999952, 35.28380134400004],
            [-80.793025236999938, 35.284429332000059],
            [-80.793222195999988, 35.285469014000057],
            [-80.793366237999976, 35.286133103000054],
            [-80.793593400999953, 35.287085009000066],
            [-80.79374788399997, 35.287688107000065],
            [-80.79390701899996, 35.288264777000052],
            [-80.794059903999937, 35.288789156000064],
            [-80.794278262999967, 35.289501850000079],
            [-80.79441100799994, 35.289918020000073],
            [-80.794552137999972, 35.290339931000062],
            [-80.794752291999941, 35.290934840000034],
            [-80.794874030999949, 35.29128251800006],
            [-80.795004868999968, 35.291644053000027],
            [-80.795236012999965, 35.29226000500006],
            [-80.795397922999939, 35.292674293000061],
            [-80.79561807999994, 35.293220164000047],
            [-80.795777816999987, 35.293604797000057],
            [-80.796002531999989, 35.294126028000051],
            [-80.79633245499997, 35.29485744200008],
            [-80.796614706999947, 35.295469081000078],
            [-80.796516477999944, 35.296512628000073],
            [-80.796370433999982, 35.297149674000025],
            [-80.796204300999989, 35.297885093000048],
            [-80.796040663999975, 35.298555094000051],
            [-80.795927426999981, 35.299036362000038],
            [-80.795801641999958, 35.299594839000065],
            [-80.795715279999968, 35.299998455000036],
            [-80.795588914999939, 35.300629411000045],
            [-80.795487647999948, 35.301184897000041],
            [-80.795403750999981, 35.301658083000063],
            [-80.795313388999944, 35.30210988400006],
            [-80.795154393999951, 35.302944645000025],
            [-80.79500447099997, 35.303801070000077],
            [-80.794872407999947, 35.304626595000059],
            [-80.794795952999948, 35.305119385000069],
            [-80.794700638999984, 35.30579491900005],
            [-80.794632706999948, 35.306281823000063],
            [-80.794542941999964, 35.306995331000053],
            [-80.794468502999962, 35.307669010000041],
            [-80.794582840999965, 35.308568019000063],
            [-80.794405179999956, 35.309173689000033],
            [-80.794376703999944, 35.309481731000062],
            [-80.79435633199995, 35.309790204000024],
            [-80.79434407499997, 35.310098966000055],
            [-80.794339936999961, 35.310407872000042],
            [-80.79434392099995, 35.310716780000064],
            [-80.794308090999948, 35.311164155000029],
            [-80.794227038999963, 35.312282251000056],
            [-80.794184691999988, 35.313199144000066],
            [-80.79419590599997, 35.313752187000034],
            [-80.794226661999971, 35.31420487400004],
            [-80.794278493999968, 35.31491569800005],
            [-80.794336605999945, 35.315823886000032],
            [-80.794349954999973, 35.316727921000052],
            [-80.794402816999934, 35.317161808000037],
            [-80.794312325999954, 35.318137570000033],
            [-80.794352390999961, 35.318918778000068],
            [-80.794397390999961, 35.31961850700003],
            [-80.79443882999999, 35.320341357000075],
            [-80.794458630999941, 35.320664419000025],
            [-80.794478983999966, 35.321059655000056],
            [-80.794501985999943, 35.321455031000028],
            [-80.794532815999958, 35.321936268000059],
            [-80.794563214999982, 35.32258054700003],
            [-80.794628571999965, 35.323583410000026],
            [-80.794665949999967, 35.324281307000035],
            [-80.794692411999961, 35.324750923000067],
            [-80.794717107999986, 35.325124902000027],
            [-80.794747625999946, 35.325607756000068],
            [-80.794784745999948, 35.326320431000056],
            [-80.794820609999988, 35.326922153000055],
            [-80.794858750999936, 35.327638901000057],
            [-80.794896920999975, 35.32821188500003],
            [-80.794929826999976, 35.328756296000051],
            [-80.794964793999952, 35.329394562000061],
            [-80.794988822999983, 35.329793712000026],
            [-80.79501156699996, 35.330187982000041],
            [-80.795042747999958, 35.330672143000072],
            [-80.795063001999949, 35.331025465000039],
            [-80.795089318999942, 35.331493667000075],
            [-80.795116979999989, 35.332132710000053],
            [-80.795132277999983, 35.332446727000047],
            [-80.795159651999938, 35.333003443000052],
            [-80.795205195999984, 35.333775761000027],
            [-80.795246426999938, 35.334491286000059],
            [-80.795275851999975, 35.335076723000043],
            [-80.795327589999943, 35.336012857000071],
            [-80.795363693999946, 35.336582484000076],
            [-80.795405161999952, 35.33719411900006],
            [-80.795446690999938, 35.33799613900004],
            [-80.795461940999985, 35.338436286000046],
            [-80.79518130799994, 35.33913629400007],
            [-80.793643352999936, 35.342500414000028],
            [-80.79344206199994, 35.342955505000077],
            [-80.793209483999988, 35.343500849000066],
            [-80.792776468999989, 35.344546276000074],
            [-80.79234644099995, 35.345591979000062],
            [-80.791977650999968, 35.346534450000036],
            [-80.791783059999943, 35.347056866000059],
            [-80.791496926999969, 35.347856363000062],
            [-80.791306434999967, 35.348416604000079],
            [-80.790943909999953, 35.34954069500003],
            [-80.790766057999974, 35.350124755000024],
            [-80.790598281999962, 35.35071008400007],
            [-80.79043917599995, 35.351302101000044],
            [-80.790141534999975, 35.35248802600006],
            [-80.789977543999953, 35.353201639000076],
            [-80.789792638999984, 35.354087012000036],
            [-80.789696465999953, 35.354583221000041],
            [-80.789518126999951, 35.355577345000029],
            [-80.78943596299996, 35.356075260000068],
            [-80.789281388999939, 35.357102389000033],
            [-80.789224395999952, 35.357507320000025],
            [-80.789145204999954, 35.358124188000033],
            [-80.78907954899995, 35.35868406000003],
            [-80.78902093499994, 35.359200084000065],
            [-80.788937418999978, 35.360035977000052],
            [-80.788871790999963, 35.360809538000069],
            [-80.78882858999998, 35.361434233000068],
            [-80.788790602999939, 35.362155898000026],
            [-80.788774049999972, 35.362554316000057],
            [-80.788761802999943, 35.362962676000052],
            [-80.788747488999945, 35.363694124000062],
            [-80.788745351999978, 35.364062665000063],
            [-80.788750015999938, 35.364608788000055],
            [-80.788756072999945, 35.364944614000024],
            [-80.788775722999958, 35.365730403000043],
            [-80.788819276999959, 35.366750979000074],
            [-80.788914996999949, 35.367961131000072],
            [-80.789020172999983, 35.368905128000051],
            [-80.78915260499997, 35.370047018000037],
            [-80.789303348999965, 35.371117000000027],
            [-80.789536904999977, 35.37245800200003],
            [-80.789921815999946, 35.374427426000068],
            [-80.790060498999935, 35.375069040000028],
            [-80.790303748999975, 35.376093810000043],
            [-80.790613426999982, 35.377295090000075],
            [-80.79075161999998, 35.377806108000073],
            [-80.790992494999955, 35.378618754000058],
            [-80.791382164999959, 35.379831212000056],
            [-80.79176294399997, 35.380903384000078],
            [-80.792126301999986, 35.381836474000067],
            [-80.792355113999974, 35.38239830200007],
            [-80.792761931999962, 35.38336457500003],
            [-80.792974826999966, 35.383858940000039],
            [-80.793207995999978, 35.384383669000044],
            [-80.793563730999949, 35.385156901000073],
            [-80.793895770999939, 35.385853668000038],
            [-80.794185504999973, 35.386444538000035],
            [-80.794442757999946, 35.386952984000061],
            [-80.794712981999965, 35.38747255100003],
            [-80.794938041999956, 35.387892648000047],
            [-80.795256978999987, 35.388471930000037],
            [-80.795520219999958, 35.388946392000037],
            [-80.795813430999942, 35.389458926000032],
            [-80.796083407999959, 35.389920280000069],
            [-80.796311191999962, 35.39030587700006],
            [-80.796511253999938, 35.390644563000023],
            [-80.796578257999954, 35.391027339000061],
            [-80.796640482999976, 35.391443605000063],
            [-80.796710941999947, 35.39188848200007],
            [-80.796824870999956, 35.392559272000028],
            [-80.796927690999951, 35.39315390400003],
            [-80.79702317999994, 35.39367474900007],
            [-80.797169783999948, 35.394418785000028],
            [-80.797325442999977, 35.395112513000072],
            [-80.797614525999961, 35.396288936000076],
            [-80.797796342999959, 35.396972898000058],
            [-80.798008468999967, 35.397745899000029],
            [-80.798222299999964, 35.398478197000031],
            [-80.79852163299995, 35.39943293500005],
            [-80.798677905999966, 35.399908910000079],
            [-80.798947173999977, 35.400692706000029],
            [-80.799110760999952, 35.401155475000053],
            [-80.799302388999934, 35.401676847000033],
            [-80.79962528599998, 35.40252124400007],
            [-80.80007496099995, 35.403631226000073],
            [-80.800339691999966, 35.404248020000068],
            [-80.800713271999939, 35.405064762000052],
            [-80.801408274999972, 35.406523843000059],
            [-80.801780694999934, 35.407275907000042],
            [-80.802165263999939, 35.408010259000037],
            [-80.802641395999956, 35.408875218000048],
            [-80.802885222999976, 35.409305566000057],
            [-80.803330581999944, 35.410069042000032],
            [-80.80362930199999, 35.410569783000028],
            [-80.804061857999955, 35.41127171800008],
            [-80.804302616999962, 35.412290022000036],
            [-80.804786106999984, 35.415226039000061],
            [-80.805075614999964, 35.416842742000028],
            [-80.805165254999963, 35.417324334000057],
            [-80.80528604899996, 35.417993250000052],
            [-80.805369155999983, 35.418425108000065],
            [-80.805464461999975, 35.418951158000027],
            [-80.80556594799998, 35.419487068000024],
            [-80.805732417999934, 35.420307921000074],
            [-80.805876148999971, 35.420960754000077],
            [-80.805985183999951, 35.421439618000079],
            [-80.806099188999951, 35.42191064900004],
            [-80.80633987899995, 35.422850536000055],
            [-80.806538049999972, 35.423566489000052],
            [-80.806657104999942, 35.423973224000065],
            [-80.806851860999984, 35.424626117000059],
            [-80.807038725999973, 35.425222747000078],
            [-80.807246612999961, 35.42586130400008],
            [-80.807478189999983, 35.426547002000063],
            [-80.807736593999948, 35.427288752000038],
            [-80.807923984999945, 35.427806650000036],
            [-80.808180812999979, 35.428498138000066],
            [-80.808405709999988, 35.429080111000076],
            [-80.808568399999956, 35.429488058000061],
            [-80.808789815999944, 35.430031488000054],
            [-80.809072785999945, 35.430706438000072],
            [-80.809281347999956, 35.431184936000079],
            [-80.809503323999934, 35.43168614800004],
            [-80.809744415999944, 35.432218959000068],
            [-80.809909138999956, 35.432575664000069],
            [-80.810287198999958, 35.433380123000063],
            [-80.810496074999946, 35.433811797000033],
            [-80.810743086999935, 35.434309864000056],
            [-80.811051956999961, 35.434920868000063],
            [-80.811432638999975, 35.435652813000047],
            [-80.811792643999979, 35.436333206000029],
            [-80.812059188999967, 35.436832882000033],
            [-80.812342875999946, 35.437367154000071],
            [-80.812608824999984, 35.43786532300004],
            [-80.812941639999963, 35.438482749000059],
            [-80.813248809999948, 35.439053381000065],
            [-80.813466451999943, 35.439458191000028],
            [-80.814121222999972, 35.440662532000033],
            [-80.815364912999939, 35.442790591000062],
            [-80.815305165999973, 35.443332117000068],
            [-80.815307896999968, 35.44378516900008],
            [-80.815273911999952, 35.444461794000063],
            [-80.815287275999935, 35.445396599000048],
            [-80.815348222999944, 35.446126444000072],
            [-80.81541858099996, 35.446947113000078],
            [-80.815458025999988, 35.447673816000076],
            [-80.815590032999978, 35.448560775000033],
            [-80.815669501999935, 35.449282493000055],
            [-80.815614890999939, 35.449916633000043],
            [-80.815697887999988, 35.450595544000066],
            [-80.815745113999981, 35.45094874800003],
            [-80.815830093999978, 35.451537997000059],
            [-80.815982490999943, 35.452491959000042],
            [-80.816189159999965, 35.453683316000024],
            [-80.816369331999965, 35.45462550600007],
            [-80.816476242999954, 35.455142207000051],
            [-80.816606362999948, 35.455712394000045],
            [-80.816893753999977, 35.456884613000057],
            [-80.817047320999961, 35.457469185000036],
            [-80.817251501999976, 35.458195759000034],
            [-80.817512201999989, 35.459091116000025],
            [-80.817780314999936, 35.459966962000067],
            [-80.818072322999967, 35.460863393000068],
            [-80.818312276999961, 35.461561330000052],
            [-80.81855154699997, 35.462222305000068],
            [-80.818869439999958, 35.463051199000063],
            [-80.819067725999957, 35.463551052000071],
            [-80.819255168999973, 35.464020961000074],
            [-80.819562811999958, 35.464764657000046],
            [-80.819723106999959, 35.46514077300003],
            [-80.819885829999976, 35.465511507000031],
            [-80.820073483999977, 35.46594268900003],
            [-80.820286075999945, 35.466415509000058],
            [-80.820763714999941, 35.467434419000028],
            [-80.821256999999946, 35.468438025000069],
            [-80.821509849999984, 35.468948699000066],
            [-80.821829210999965, 35.469625694000058],
            [-80.821832877999952, 35.470055691000027],
            [-80.821787018999942, 35.470863454000039],
            [-80.821721886999967, 35.472100562000037],
            [-80.821698871999956, 35.472637644000031],
            [-80.821680069999957, 35.473197592000076],
            [-80.82166592599998, 35.473568234000027],
            [-80.821648241999981, 35.474195862000045],
            [-80.821636989999945, 35.474542310000061],
            [-80.821626619999961, 35.474940999000069],
            [-80.821614427999975, 35.475331554000036],
            [-80.821597555999972, 35.476151161000075],
            [-80.821591324999986, 35.477084421000029],
            [-80.821362486999988, 35.477695415000028],
            [-80.821099084999958, 35.478198306000024],
            [-80.820851516999937, 35.478684603000033],
            [-80.820461368999986, 35.479469047000066],
            [-80.820193555999936, 35.480012649000059],
            [-80.819765275999941, 35.480875836000052],
            [-80.819468926999946, 35.481475673000034],
            [-80.819203904999938, 35.481997031000049],
            [-80.818799984999941, 35.482804201000079],
            [-80.818546164999987, 35.483316586000058],
            [-80.818162398999959, 35.484084268000061],
            [-80.81790416299998, 35.484590284000035],
            [-80.817651611999963, 35.485096110000029],
            [-80.81746074199998, 35.485489390000055],
            [-80.817041929999959, 35.486368241000037],
            [-80.816759629999979, 35.486991991000025],
            [-80.816381515999979, 35.487885578000032],
            [-80.81619937399995, 35.488334062000035],
            [-80.816071416999989, 35.488655128000062],
            [-80.815867775999948, 35.489177432000076],
            [-80.815704729999936, 35.489609162000079],
            [-80.815528684999947, 35.490094809000027],
            [-80.815347217999943, 35.490615723000076],
            [-80.815222404999986, 35.490985498000043],
            [-80.815107874999967, 35.491335933000073],
            [-80.815011668999944, 35.491635919000032],
            [-80.814805507999949, 35.492299832000072],
            [-80.814683226999989, 35.492709650000052],
            [-80.814530679999962, 35.49325880300006],
            [-80.814286330999948, 35.494193149000068],
            [-80.814135484999952, 35.49481197800003],
            [-80.813980938999975, 35.495504357000073],
            [-80.813834801999974, 35.496210317000077],
            [-80.813700911999945, 35.496900250000067],
            [-80.813629528999968, 35.497295981000036],
            [-80.813546648999989, 35.49778394100008],
            [-80.813489138999955, 35.498142785000027],
            [-80.813441293999972, 35.498459205000074],
            [-80.813369828999953, 35.49899023200004],
            [-80.813267677999988, 35.49984575700006],
            [-80.813192525999966, 35.500582940000072],
            [-80.81310168899995, 35.501655482000047],
            [-80.813041363999957, 35.502434665000067],
            [-80.812965462999955, 35.503242039000042],
            [-80.812928054999986, 35.503732631000048],
            [-80.812860669999964, 35.504748708000079],
            [-80.812824541999987, 35.505296850000036],
            [-80.812721008999972, 35.50666196700007],
            [-80.812624824999943, 35.507939689000068],
            [-80.812522653999963, 35.509334458000069],
            [-80.812488160999976, 35.509914995000031],
            [-80.812453657999981, 35.510464636000052],
            [-80.812401797999939, 35.510951127000055],
            [-80.812373449999939, 35.511341601000026],
            [-80.812229207999962, 35.512091023000039],
            [-80.812086495999949, 35.512750415000028],
            [-80.811959062999961, 35.513321636000057],
            [-80.811855912999988, 35.51379002200008],
            [-80.811757499999942, 35.514232230000061],
            [-80.81164810599995, 35.514717828000073],
            [-80.811522734999983, 35.515290096000058],
            [-80.811439804999964, 35.515660675000049],
            [-80.811323093999988, 35.516191316000061],
            [-80.81120604299997, 35.51671943000008],
            [-80.811101286999985, 35.517193410000061],
            [-80.811002945999974, 35.517632565000042],
            [-80.810894664999978, 35.518131906000065],
            [-80.810807900999976, 35.518521987000042],
            [-80.810694976999969, 35.519023316000073],
            [-80.810507281999946, 35.519870516000026],
            [-80.810414923999986, 35.520285125000044],
            [-80.810256724999988, 35.520998501000065],
            [-80.810129243999938, 35.521577416000071],
            [-80.810051252999983, 35.521937682000043],
            [-80.809960825999951, 35.522347555000067],
            [-80.809855927999934, 35.522819279000032],
            [-80.809754182999939, 35.52328803100005],
            [-80.809672780999961, 35.52365295900006],
            [-80.80958037399995, 35.524073168000029],
            [-80.80937374399997, 35.524961137000048],
            [-80.809172782999951, 35.525797524000041],
            [-80.809050404999937, 35.526351951000038],
            [-80.808758500999943, 35.527690179000047],
            [-80.808674716999974, 35.528023129000076],
            [-80.80854487299996, 35.528554548000045],
            [-80.808436205999953, 35.529021597000053],
            [-80.808310089999964, 35.529589257000055],
            [-80.808212346999937, 35.530044063000048],
            [-80.808129573999963, 35.530433724000034],
            [-80.807945858999972, 35.53129892700008],
            [-80.807769991999976, 35.53215696400008],
            [-80.807684327999937, 35.532569095000042],
            [-80.807576876999974, 35.533045387000072],
            [-80.807455807999986, 35.533588411000039],
            [-80.807311377999952, 35.534233091000033],
            [-80.807196053999974, 35.534765976000074],
            [-80.807008438999958, 35.53560212900004],
            [-80.806834901999935, 35.536367655000049],
            [-80.806703791999951, 35.536970500000052],
            [-80.806597039999986, 35.537440343000071],
            [-80.806488244999969, 35.537928157000067],
            [-80.806366665999974, 35.538491990000068],
            [-80.806150656999989, 35.539458471000046],
            [-80.806042738999963, 35.539967599000079],
            [-80.805915719999973, 35.540543832000026],
            [-80.805782760999989, 35.541127625000058],
            [-80.805638316999989, 35.541774401000055],
            [-80.805520004999948, 35.542337682000039],
            [-80.805414515999985, 35.542868657000042],
            [-80.805325022999966, 35.543343619000041],
            [-80.805243476999976, 35.543808092000063],
            [-80.805135783999958, 35.544468528000039],
            [-80.80504555999994, 35.545087851000062],
            [-80.804981003999956, 35.545524382000053],
            [-80.804914015999941, 35.546005862000072],
            [-80.804844100999958, 35.54654793900005],
            [-80.804771276999986, 35.547157236000032],
            [-80.80471584199995, 35.547668334000036],
            [-80.804664241999944, 35.548194609000063],
            [-80.804602151999973, 35.548928053000054],
            [-80.804557554999974, 35.549566340000069],
            [-80.804518787999939, 35.550193310000054],
            [-80.80449845399994, 35.550569447000044],
            [-80.804476893999947, 35.551044310000066],
            [-80.804459752999946, 35.551527010000029],
            [-80.804444399999966, 35.552112932000057],
            [-80.804433385999971, 35.552930162000052],
            [-80.804439848999948, 35.553809612000066],
            [-80.804475784999966, 35.554950616000042],
            [-80.804506767999953, 35.55569637900004],
            [-80.804536553999981, 35.55624651200003],
            [-80.804563523999946, 35.556676393000032],
            [-80.80459012099999, 35.55705159300004],
            [-80.804624753999974, 35.557491729000049],
            [-80.804676275999952, 35.55806861700006],
            [-80.80473232199995, 35.558633444000066],
            [-80.804774264999935, 35.559017955000058],
            [-80.805036732999952, 35.559311949000062],
            [-80.804946453999946, 35.559895152000024],
            [-80.804915498999947, 35.56041966600003],
            [-80.804845342999954, 35.560954181000056],
            [-80.804587967999964, 35.561495378000075],
            [-80.804430770999943, 35.562056073000065],
            [-80.804241024999953, 35.562753572000076],
            [-80.804108275999965, 35.563253133000046],
            [-80.803961197999968, 35.563808771000026],
            [-80.80378765599994, 35.564497108000069],
            [-80.803604255999971, 35.56527300700003],
            [-80.803473810999947, 35.565861003000066],
            [-80.803326632999983, 35.566574517000049],
            [-80.803234701999941, 35.567049578000024],
            [-80.80313134499994, 35.567619183000033],
            [-80.803000736999934, 35.568396678000056],
            [-80.802881934999959, 35.569178431000068],
            [-80.802796412999953, 35.569799365000051],
            [-80.80273355199995, 35.570302759000072],
            [-80.802666866999971, 35.57088893100007],
            [-80.802606111999978, 35.571488596000052],
            [-80.802552632999948, 35.572090030000027],
            [-80.802518869999972, 35.572519778000071],
            [-80.802489729999934, 35.572943119000058],
            [-80.802460698999937, 35.573417253000059],
            [-80.80243511599997, 35.573916952000047],
            [-80.802410162999934, 35.574551645000042],
            [-80.80239336599999, 35.575219381000068],
            [-80.802385892999951, 35.575927014000058],
            [-80.802387834999934, 35.576513421000072],
            [-80.802396036999937, 35.577052452000032],
            [-80.802411577999976, 35.577670854000075],
            [-80.802433919999942, 35.578262571000039],
            [-80.802463580999984, 35.578854855000031],
            [-80.802500730999952, 35.579447734000041],
            [-80.802550710999981, 35.580106923000073],
            [-80.802599143999942, 35.580660635000072],
            [-80.802660381999942, 35.581272182000077],
            [-80.802734033999968, 35.581920331000049],
            [-80.802816102999941, 35.582563163000032],
            [-80.802889479999976, 35.583084558000053],
            [-80.802987636999944, 35.583725712000046],
            [-80.803089339999985, 35.58434429600004],
            [-80.803196170999968, 35.584946487000025],
            [-80.80330536699995, 35.585519834000024],
            [-80.803429470999959, 35.586129415000073],
            [-80.80354244199998, 35.58665183100004],
            [-80.803657989999977, 35.587159030000066],
            [-80.803752949999989, 35.587557950000075],
            [-80.803869426999938, 35.588031220000062],
            [-80.804047736999962, 35.588724149000029],
            [-80.804198027999973, 35.589300342000058],
            [-80.804295928999977, 35.589667443000053],
            [-80.804490595999937, 35.590411084000038],
            [-80.804637727999989, 35.590966819000073],
            [-80.804747025999973, 35.591382600000031],
            [-80.804851359999986, 35.591775763000044],
            [-80.804943639999976, 35.592123495000067],
            [-80.805070282999964, 35.592603721000046],
            [-80.805208044999972, 35.593120618000057],
            [-80.805326263999973, 35.59356904100008],
            [-80.805467866999948, 35.594098966000047],
            [-80.805778553999971, 35.595272606000037],
            [-80.805955868999945, 35.595950558000027],
            [-80.806272638999985, 35.597148693000065],
            [-80.806356533999974, 35.597530996000046],
            [-80.806211633999965, 35.598292131000051],
            [-80.80609134599996, 35.598962638000046],
            [-80.805949004999945, 35.599737680000032],
            [-80.805850916999987, 35.600279157000045],
            [-80.805754409999963, 35.600804694000033],
            [-80.805646740999975, 35.601413626000067],
            [-80.805547984999976, 35.601959824000062],
            [-80.805477846999963, 35.602354521000052],
            [-80.805375517999948, 35.60292524700003],
            [-80.805273949999957, 35.603486869000051],
            [-80.805128091999961, 35.604290951000053],
            [-80.805007885999942, 35.604948695000076],
            [-80.804930117999959, 35.605386041000031],
            [-80.804824210999982, 35.605971767000028],
            [-80.804686152999977, 35.606739982000079],
            [-80.804600498999946, 35.607223149000049],
            [-80.80451076899999, 35.607732003000024],
            [-80.804391022999937, 35.608443333000025],
            [-80.804259964999972, 35.609309435000057],
            [-80.804147519999958, 35.610159776000046],
            [-80.804070072999934, 35.610826081000027],
            [-80.804017929999986, 35.61133638900003],
            [-80.803954830999942, 35.612058804000071],
            [-80.80392253499997, 35.612495208000041],
            [-80.803875919999939, 35.613267456000074],
            [-80.803843106999977, 35.613980620000063],
            [-80.80382739099997, 35.61446287800004],
            [-80.803816238999957, 35.614972454000053],
            [-80.803810174999967, 35.615556190000063],
            [-80.803811886999938, 35.616111996000029],
            [-80.803821780999954, 35.616697868000074],
            [-80.803840817999969, 35.617308435000041],
            [-80.803867632999982, 35.617897258000028],
            [-80.803903294999941, 35.618492171000071],
            [-80.803946143999951, 35.619071461000033],
            [-80.803997524999943, 35.619654669000056],
            [-80.804061961999935, 35.620278162000034],
            [-80.804136226999958, 35.620920916000046],
            [-80.804221097999971, 35.621570782000049],
            [-80.804315903999964, 35.622233645000051],
            [-80.804419951999989, 35.622889799000063],
            [-80.804523682999957, 35.623498545000075],
            [-80.804594367999982, 35.623881380000057],
            [-80.804703558999961, 35.624440875000062],
            [-80.804780538999978, 35.624813882000069],
            [-80.804948311999965, 35.625558139000077],
            [-80.805131600999971, 35.62631377200006],
            [-80.805326488999981, 35.627060631000063],
            [-80.805532446999962, 35.627800543000035],
            [-80.805765314999974, 35.62858486600004],
            [-80.80597897499996, 35.629267936000076],
            [-80.806234122999967, 35.630040516000065],
            [-80.806480912999973, 35.630749276000074],
            [-80.806621735999954, 35.631136983000033],
            [-80.806847970999968, 35.631736387000046],
            [-80.807083132999935, 35.632336925000061],
            [-80.807235369999944, 35.632709390000059],
            [-80.807474113999945, 35.633276994000028],
            [-80.807711167999969, 35.633820354000079],
            [-80.807973421999975, 35.634404460000042],
            [-80.80826733899994, 35.635041413000067],
            [-80.808666765999988, 35.635886649000042],
            [-80.80902187099997, 35.63662948700005],
            [-80.809320326999966, 35.637242767000032],
            [-80.809543340999937, 35.637706447000028],
            [-80.809801337999943, 35.638238778000073],
            [-80.810092598999972, 35.63874798300003],
            [-80.810323638999989, 35.639336766000042],
            [-80.810411731999977, 35.640043425000044],
            [-80.810506273999977, 35.640757047000079],
            [-80.810846177999963, 35.641548701000033],
            [-80.810838901999944, 35.642021185000033],
            [-80.810805562999974, 35.642552966000039],
            [-80.810447655999951, 35.643120373000045],
            [-80.810274796999977, 35.643576171000063],
            [-80.810073373999955, 35.644124565000027],
            [-80.809814442999937, 35.644855808000045],
            [-80.809593207999967, 35.645507418000079],
            [-80.809410457999945, 35.646065036000039],
            [-80.809286971999938, 35.646452871000065],
            [-80.809158080999964, 35.64686981400007],
            [-80.809011368999961, 35.64736098700007],
            [-80.808835273999989, 35.64797808700007],
            [-80.808619226999951, 35.648776099000031],
            [-80.80842043499996, 35.649558573000036],
            [-80.808221198999945, 35.650388921000058],
            [-80.808075332999977, 35.651029980000033],
            [-80.808001679999961, 35.651367452000045],
            [-80.80790535899996, 35.651827969000067],
            [-80.807807921999938, 35.652316839000036],
            [-80.807682686999954, 35.652988578000077],
            [-80.80753002299997, 35.653881779000073],
            [-80.807410216999983, 35.654658749000077],
            [-80.807321226999989, 35.655295705000071],
            [-80.807223575999956, 35.656057006000026],
            [-80.807126159999939, 35.656900515000075],
            [-80.806983174999971, 35.657835650000038],
            [-80.806699827999978, 35.658395598000027],
            [-80.806426372999965, 35.658924730000024],
            [-80.805939128999967, 35.659839901000055],
            [-80.805754847999935, 35.66017855900003],
            [-80.805353477999972, 35.660925387000077],
            [-80.805023644999949, 35.661546706000024],
            [-80.804715146999968, 35.662127511000051],
            [-80.804393230999949, 35.662748836000048],
            [-80.804028130999939, 35.663475733000041],
            [-80.803776593999942, 35.663993808000043],
            [-80.803523588999951, 35.664529854000079],
            [-80.803228375999936, 35.665176908000035],
            [-80.802922437999939, 35.665882037000074],
            [-80.802668769999968, 35.666489915000056],
            [-80.802432804999967, 35.667079910000041],
            [-80.802128789999983, 35.667873947000032],
            [-80.801901843999985, 35.668495182000072],
            [-80.801652178999973, 35.669215831000031],
            [-80.801456476999988, 35.669808587000034],
            [-80.801191696999979, 35.670654195000054],
            [-80.800987171999964, 35.671353890000034],
            [-80.800791353999955, 35.672063100000059],
            [-80.800582682999959, 35.672864324000045],
            [-80.80038129999997, 35.673701400000027],
            [-80.80026570299998, 35.674214024000037],
            [-80.800145750999945, 35.674790786000074],
            [-80.800032061999957, 35.675374324000074],
            [-80.799925790999964, 35.675960394000072],
            [-80.799824371999989, 35.67656478400005],
            [-80.799729527999943, 35.677181114000064],
            [-80.799647738999965, 35.677764914000079],
            [-80.799570699999947, 35.678373630000067],
            [-80.799503577999985, 35.678968088000033],
            [-80.799444580999989, 35.679560974000026],
            [-80.799395890999961, 35.680122761000064],
            [-80.799361424999972, 35.680582125000058],
            [-80.799313176999988, 35.681359073000067],
            [-80.799284872999976, 35.68197592000007],
            [-80.799268429999984, 35.682703269000058],
            [-80.799270933999935, 35.683117518000074],
            [-80.799262220999935, 35.683643062000044],
            [-80.799263013999962, 35.68430433900005],
            [-80.799269471999935, 35.68460895700008],
            [-80.799284281999974, 35.685147131000065],
            [-80.799300865999953, 35.685603488000027],
            [-80.799321029999987, 35.686015964000035],
            [-80.799364720999961, 35.686682813000061],
            [-80.799426991999951, 35.687395366000032],
            [-80.799480499999959, 35.68794570600005],
            [-80.799538259999963, 35.688470874000075],
            [-80.799605091999979, 35.689167135000048],
            [-80.799679007999941, 35.689723580000077],
            [-80.799768272999984, 35.690334727000049],
            [-80.799866497999972, 35.690947655000059],
            [-80.799973338999962, 35.691559958000028],
            [-80.800088277999976, 35.692168883000079],
            [-80.800218185999938, 35.692807477000031],
            [-80.80035391499996, 35.693428542000049],
            [-80.800501710999981, 35.694061134000037],
            [-80.800653310999962, 35.694670946000031],
            [-80.800808270999937, 35.695259531000033],
            [-80.800976283999944, 35.695863959000064],
            [-80.801150296999936, 35.696458618000065],
            [-80.801321844999961, 35.697017441000071],
            [-80.80152554099999, 35.697652380000079],
            [-80.80172702699997, 35.698251517000074],
            [-80.801928264999958, 35.698824656000056],
            [-80.802114057999972, 35.699333742000078],
            [-80.802308926999956, 35.699848819000067],
            [-80.802490475999946, 35.700313290000054],
            [-80.80290247399995, 35.700574749000054],
            [-80.802843016999987, 35.701187179000044],
            [-80.802965229999984, 35.701698578000048],
            [-80.802633742999944, 35.702046084000074],
            [-80.802526476999958, 35.702556817000072],
            [-80.80239241299995, 35.703246104000073],
            [-80.802268909999952, 35.703934748000052],
            [-80.802192154999943, 35.704401186000041],
            [-80.802121861999979, 35.704859366000051],
            [-80.802065142999936, 35.705257232000065],
            [-80.802118420999989, 35.705818645000079],
            [-80.802041151999958, 35.706313672000078],
            [-80.801963457999989, 35.706867188000047],
            [-80.801873001999979, 35.707647868000038],
            [-80.801812680999944, 35.708272358000045],
            [-80.80178284699997, 35.708633647000056],
            [-80.801747354999975, 35.709128464000059],
            [-80.801705327999969, 35.709756421000066],
            [-80.801670262999949, 35.710363606000044],
            [-80.80164022799994, 35.71100801800003],
            [-80.801647856999978, 35.711473475000048],
            [-80.801662075999957, 35.711965554000074],
            [-80.801712984999938, 35.712385632000064],
            [-80.801751765999938, 35.712965567000026],
            [-80.801761283999952, 35.713375271000075],
            [-80.801782498999955, 35.713739413000042],
            [-80.801811554999972, 35.714103196000053],
            [-80.801848441999937, 35.714466505000075],
            [-80.801893148999966, 35.714829231000067],
            [-80.80194566199998, 35.715191259000051],
            [-80.801869089999968, 35.715894147000029],
            [-80.801939150999942, 35.716533417000051],
            [-80.802051309999968, 35.717422390000024],
            [-80.802156564999962, 35.718146211000033],
            [-80.802279021999937, 35.718895245000056],
            [-80.802420577999953, 35.719672865000064],
            [-80.802582853999979, 35.720479168000054],
            [-80.802741482999977, 35.721199348000027],
            [-80.802877931999944, 35.721777682000038],
            [-80.803005578999944, 35.722296164000056],
            [-80.80313496499997, 35.722824957000057],
            [-80.803308207999976, 35.723503738000034],
            [-80.803449757999942, 35.724063197000078],
            [-80.803609055999971, 35.724688155000024],
            [-80.803792321999936, 35.725409698000078],
            [-80.803980932999934, 35.726163057000065],
            [-80.804136946999961, 35.726775201000066],
            [-80.804233088999979, 35.727158924000037],
            [-80.804332723999948, 35.727562754000076],
            [-80.804414425999937, 35.727915041000074],
            [-80.804629972999976, 35.729138129000034],
            [-80.804799238999976, 35.730008810000072],
            [-80.804923513999938, 35.730944084000043],
            [-80.805012966999982, 35.731744348000063],
            [-80.80507003699995, 35.73227015100008],
            [-80.805122867999955, 35.732745008000052],
            [-80.805201550999982, 35.733463047000043],
            [-80.805253844999982, 35.733968470000036],
            [-80.805305529999941, 35.734455669000056],
            [-80.805363778999947, 35.734972825000057],
            [-80.805433969999967, 35.73559937300007],
            [-80.805604741999957, 35.737165573000027],
            [-80.805655871999988, 35.737616484000057],
            [-80.805702366999981, 35.738054064000039],
            [-80.805820923999988, 35.739129318000039],
            [-80.805938677999961, 35.740233854000053],
            [-80.805999682999982, 35.740777648000062],
            [-80.806057844999941, 35.741310331000079],
            [-80.806123477999961, 35.74192658700008],
            [-80.806195169999967, 35.742587932000049],
            [-80.806261387999939, 35.743185815000061],
            [-80.806358001999968, 35.744045305000043],
            [-80.806416539999987, 35.744589719000032],
            [-80.806557139999938, 35.745874703000027],
            [-80.806643984999937, 35.746664318000057],
            [-80.806708844999946, 35.747264481000059],
            [-80.806798492999974, 35.748072225000044],
            [-80.806858377999959, 35.748617437000064],
            [-80.806961901999955, 35.749579738000079],
            [-80.807021913999961, 35.750146864000044],
            [-80.807097451999937, 35.75081300800008],
            [-80.807178809999982, 35.751571599000044],
            [-80.807239975999948, 35.752120563000062],
            [-80.807302095999944, 35.752659959000027],
            [-80.807375891999982, 35.753332556000032],
            [-80.807428679999987, 35.753826887000059],
            [-80.807492543999956, 35.754407366000066],
            [-80.807544058999952, 35.754866689000039],
            [-80.807662944999947, 35.755962091000072],
            [-80.807734279999977, 35.756594867000047],
            [-80.807821875999934, 35.757392012000025],
            [-80.807895650999967, 35.758081992000029],
            [-80.807952006999983, 35.758590041000048],
            [-80.808004736999976, 35.759081992000063],
            [-80.808160166999983, 35.760479641000074],
            [-80.808225363999952, 35.761088271000062],
            [-80.808271402999935, 35.761502525000026],
            [-80.808328415999938, 35.762029723000069],
            [-80.808398988999954, 35.762665927000057],
            [-80.808532367999987, 35.763896720000048],
            [-80.808597650999957, 35.764478565000047],
            [-80.808793782999942, 35.766270708000036],
            [-80.808907155999975, 35.767288625000049],
            [-80.809027765999986, 35.768402278000053],
            [-80.809141888999989, 35.769434305000061],
            [-80.80920643799999, 35.770054440000024],
            [-80.809275730999957, 35.770691151000051],
            [-80.809343197999965, 35.771296562000032],
            [-80.809412065999936, 35.77213220200008],
            [-80.809542059999956, 35.77338110200003],
            [-80.809678521999956, 35.774357155000075],
            [-80.809724668999934, 35.774781573000041],
            [-80.809772715999941, 35.775215465000031],
            [-80.809829676999982, 35.775887471000033],
            [-80.809885016999942, 35.776451568000027],
            [-80.809944297999948, 35.776991510000073],
            [-80.809987429999978, 35.777353525000024],
            [-80.810045878999972, 35.777907363000054],
            [-80.810114814999963, 35.778540551000049],
            [-80.810096261999945, 35.779048998000064],
            [-80.810038457999951, 35.77963610300003],
            [-80.809990392999964, 35.780131728000072],
            [-80.809880517999943, 35.781276611000067],
            [-80.809828249999953, 35.781804385000044],
            [-80.809739432999947, 35.782686823000063],
            [-80.809651628999973, 35.783388547000072],
            [-80.809530449999954, 35.784534665000024],
            [-80.809440602999985, 35.785831705000078],
            [-80.809382325999934, 35.78627235700003],
            [-80.80924665699996, 35.787520843000038],
            [-80.809162328999946, 35.788603837000039],
            [-80.809126315999947, 35.78914820600005],
            [-80.809075212999971, 35.78969231800005],
            [-80.809013903999983, 35.790318052000032],
            [-80.808958783999969, 35.790917312000033],
            [-80.808900392999988, 35.791515872000048],
            [-80.808853892999934, 35.792012146000047],
            [-80.808802208999964, 35.792536211000026],
            [-80.808757137999976, 35.792990926000073],
            [-80.808695420999982, 35.793631326000025],
            [-80.808652022999979, 35.79407704700003],
            [-80.808606727999972, 35.794556683000053],
            [-80.80856425099995, 35.794992805000049],
            [-80.80852103999996, 35.795458950000068],
            [-80.808493240999951, 35.795774542000061],
            [-80.808446778999951, 35.796293344000048],
            [-80.808379612999943, 35.79707529600006],
            [-80.808335417999956, 35.797642445000065],
            [-80.80829950399999, 35.798142443000074],
            [-80.808266542999945, 35.79863614900006],
            [-80.808234423999977, 35.799152799000069],
            [-80.808197373999974, 35.79978447600007],
            [-80.808154895999962, 35.800499675000026],
            [-80.808085046999963, 35.801665927000045],
            [-80.808044267999946, 35.802401088000067],
            [-80.808028254999954, 35.802711199000044],
            [-80.807983320999938, 35.803170723000051],
            [-80.807911587999968, 35.804230456000028],
            [-80.80786324799999, 35.805540391000079],
            [-80.807825046999938, 35.805938467000033],
            [-80.807733545999952, 35.807189609000034],
            [-80.807682529999965, 35.808606627000074],
            [-80.807658826999955, 35.80928087500007],
            [-80.807612214999949, 35.809996658000045],
            [-80.807504519999952, 35.811396685000034],
            [-80.80745731199994, 35.812649438000051],
            [-80.807445074999976, 35.81308941900005],
            [-80.807415015999936, 35.813604938000026],
            [-80.807357162999949, 35.814630859000033],
            [-80.807328480999956, 35.815115965000075],
            [-80.807306448999952, 35.81551005700004],
            [-80.807277411999962, 35.816050147000055],
            [-80.807254115999967, 35.816459869000028],
            [-80.807226863999972, 35.816905126000051],
            [-80.807195758999967, 35.81744939500004],
            [-80.807158537999953, 35.818148842000028],
            [-80.807125588999952, 35.818707921000055],
            [-80.807095662999984, 35.819260568000061],
            [-80.807070100999965, 35.819706336000024],
            [-80.807020700999942, 35.820591451000041],
            [-80.806964812999979, 35.821575821000067],
            [-80.806924863999939, 35.822313665000024],
            [-80.806880394999951, 35.823103348000075],
            [-80.806855151999969, 35.823566549000077],
            [-80.806823342999962, 35.82410369400003],
            [-80.806763156999978, 35.825180384000078],
            [-80.806714651999982, 35.826087942000072],
            [-80.806659096999965, 35.827079840000067],
            [-80.806602985999973, 35.828041681000059],
            [-80.806576689999986, 35.828518419000034],
            [-80.80654588799996, 35.829102443000068],
            [-80.806491925999978, 35.830039746000068],
            [-80.806399146999979, 35.831685316000062],
            [-80.806372305999957, 35.832160816000055],
            [-80.806447094999953, 35.83288230800008],
            [-80.806380041999944, 35.833411221000063],
            [-80.806320159999984, 35.833940704000042],
            [-80.80626745699999, 35.834470692000025],
            [-80.806221938999954, 35.835001122000051],
            [-80.806183611999984, 35.835531929000069],
            [-80.80615248099997, 35.836063047000039],
            [-80.806133487999944, 35.836443221000025],
            [-80.806121100999974, 35.836823575000039],
            [-80.806115321999982, 35.837204034000024],
            [-80.806116152999948, 35.837584521000053],
            [-80.806123593999985, 35.837964961000068],
            [-80.805984581999951, 35.83899800100005],
            [-80.80591740899996, 35.840195965000078],
            [-80.805873247999955, 35.841004113000054],
            [-80.805837928999949, 35.841596035000066],
            [-80.805803388999948, 35.842191202000038],
            [-80.805693376999955, 35.843700431000059],
            [-80.805639614999961, 35.845096480000052],
            [-80.805613508999954, 35.845595531000072],
            [-80.805591937999964, 35.846080918000041],
            [-80.805574466999985, 35.84656969200006],
            [-80.80556095999998, 35.84707042000008],
            [-80.805553259999954, 35.847476808000067],
            [-80.805548907999935, 35.847970036000049],
            [-80.805551363999939, 35.848486738000076],
            [-80.805561392999948, 35.849137294000059],
            [-80.805579078999983, 35.849779755000043],
            [-80.805594127999939, 35.850167131000035],
            [-80.805614623999986, 35.850588500000072],
            [-80.805652914999939, 35.851235065000026],
            [-80.805731677999972, 35.852221370000052],
            [-80.805859326999951, 35.853435495000042],
            [-80.806031737999945, 35.85466344200006],
            [-80.806249970999943, 35.855904084000031],
            [-80.806377385999951, 35.856557426000052],
            [-80.806512037999937, 35.857139084000039],
            [-80.806817724999974, 35.858367426000029],
            [-80.807166776999964, 35.859588082000073],
            [-80.807558907999976, 35.86080004300004],
            [-80.807993795999948, 35.862002309000047],
            [-80.808471076999979, 35.86319386100007],
            [-80.808749249999948, 35.863852350000059],
            [-80.808990350999977, 35.864373707000027],
            [-80.809551201999966, 35.865540890000034],
            [-80.810153166999953, 35.866694447000043],
            [-80.810795730999985, 35.867833381000025],
            [-80.811478356999942, 35.868956749000063],
            [-80.812200502999985, 35.870063650000077],
            [-80.812635084999954, 35.870703548000051],
            [-80.812961565999956, 35.871153154000069],
            [-80.813760900999966, 35.872224341000049],
            [-80.814597852999952, 35.873276331000056],
            [-80.815471724999952, 35.87430824300003],
            [-80.816381793999938, 35.875319224000066],
            [-80.817327302999956, 35.876308431000041],
            [-80.817918166999959, 35.876904617000037],
            [-80.818307464999975, 35.877275041000075],
            [-80.819321471999956, 35.878218254000046],
            [-80.820368479999956, 35.879137284000024],
            [-80.821447617999979, 35.880031368000061],
            [-80.822557998999969, 35.880899766000027],
            [-80.823698683999964, 35.881741746000046],
            [-80.824654141999986, 35.882412651000038],
            [-80.826067155999965, 35.883343679000063],
            [-80.827293005999934, 35.884102319000078],
            [-80.828545221999946, 35.884831875000032],
            [-80.829822772999989, 35.885531747000073],
            [-80.831124597999974, 35.886201355000026],
            [-80.832226271999957, 35.886737137000068],
            [-80.833796717999974, 35.887447574000078],
            [-80.83516478599995, 35.888023145000034],
            [-80.836552697999934, 35.888566385000047],
            [-80.837959313999988, 35.889076845000034],
            [-80.839383414999986, 35.889554082000075],
            [-80.840602114999967, 35.889933522000035],
            [-80.842279387999952, 35.890407356000026],
            [-80.843748839999989, 35.890782681000076],
            [-80.84523100399997, 35.891123378000032],
            [-80.846724649999942, 35.891429164000044],
            [-80.848228493999954, 35.891699775000063],
            [-80.849530369999968, 35.891905885000028],
            [-80.851261821999969, 35.892134618000057],
            [-80.852788775999954, 35.892298486000072],
            [-80.854320915999949, 35.892426464000039],
            [-80.85585697099998, 35.892518443000029],
            [-80.857395633999943, 35.892574345000071],
            [-80.858743089999962, 35.89259504000006],
            [-80.860475702999963, 35.892577766000045],
            [-80.862014558999988, 35.892525282000065],
            [-80.863550901999986, 35.892436716000077],
            [-80.86508345499999, 35.892312142000037],
            [-80.866610965999939, 35.892151665000029],
            [-80.867963753999959, 35.891980303000025],
            [-80.86964575099995, 35.891723561000049],
            [-80.871150478999937, 35.891456294000079],
            [-80.872645124999963, 35.891153831000054],
            [-80.874128457999973, 35.890816421000068],
            [-80.875599182999963, 35.890444357000035],
            [-80.876915586999985, 35.890080133000026],
            [-80.878497988999982, 35.889597524000067],
            [-80.879923694999945, 35.889123449000067],
            [-80.881331995999972, 35.888616124000066],
            [-80.882721726999989, 35.888075970000045],
            [-80.884091731999945, 35.887503435000042],
            [-80.885329955999964, 35.886951554000063],
            [-80.886768040999982, 35.886263150000048],
            [-80.888072083999987, 35.885596448000058],
            [-80.889351978999969, 35.884899416000053],
            [-80.890606659999946, 35.884172631000069],
            [-80.891835036999964, 35.883416726000064],
            [-80.89287264099994, 35.882744616000025],
            [-80.893364156999951, 35.882389906000071],
            [-80.894199661999949, 35.881811233000064],
            [-80.894888546999937, 35.881317682000031],
            [-80.895560183999976, 35.880812457000047],
            [-80.896461663999958, 35.880111274000058],
            [-80.897113702999945, 35.879586846000052],
            [-80.897749643999987, 35.879049968000061],
            [-80.898599826999941, 35.878307775000053],
            [-80.899212724999984, 35.877754481000068],
            [-80.899755437999943, 35.87724146000005],
            [-80.900606983999978, 35.876406691000057],
            [-80.901169219999986, 35.875836553000056],
            [-80.901534805999972, 35.875451675000079],
            [-80.90246390599998, 35.874451961000034],
            [-80.90354182699997, 35.87320330700004],
            [-80.904382298999963, 35.872153182000034],
            [-80.905153218999942, 35.871129043000053],
            [-80.90594992299998, 35.869995970000048],
            [-80.906675772999961, 35.868890678000071],
            [-80.907362170999988, 35.867768813000055],
            [-80.908008548999987, 35.866631306000045],
            [-80.908614366999984, 35.865479108000045],
            [-80.909164524999937, 35.864345901000036],
            [-80.909702355999968, 35.863134474000049],
            [-80.910183625999935, 35.86194398300006],
            [-80.910622536999938, 35.860742696000045],
            [-80.911018724999963, 35.859531615000037],
            [-80.911371864999978, 35.85831173400004],
            [-80.911677951999934, 35.857101211000042],
            [-80.911947870999938, 35.855849642000067],
            [-80.912170255999968, 35.854609503000063],
            [-80.912348645999941, 35.853364647000035],
            [-80.912482891999957, 35.852116116000047],
            [-80.912572882999939, 35.850864958000045],
            [-80.912618546999965, 35.849612206000074],
            [-80.912619847999963, 35.84835890100004],
            [-80.912611422999987, 35.847870558000068],
            [-80.912633866999954, 35.847468592000041],
            [-80.912715840999965, 35.846368682000048],
            [-80.912749117999965, 35.845823587000041],
            [-80.912796276999984, 35.844774575000031],
            [-80.912815911999985, 35.844250419000048],
            [-80.912838567999984, 35.84373862800004],
            [-80.912864737999939, 35.843269838000026],
            [-80.912895062999951, 35.842722909000031],
            [-80.912915683999984, 35.84233617600006],
            [-80.912973014999977, 35.841326029000072],
            [-80.913007745999948, 35.840664281000045],
            [-80.913068062999969, 35.839605478000067],
            [-80.913096096999936, 35.839080855000077],
            [-80.913123844999973, 35.838579312000036],
            [-80.913154047999967, 35.838001920000067],
            [-80.913180094999973, 35.837515187000065],
            [-80.913205411999968, 35.837065748000043],
            [-80.913230878999968, 35.836602625000069],
            [-80.913296410999976, 35.835469940000053],
            [-80.913369260999957, 35.834093084000074],
            [-80.913409885999954, 35.833379007000076],
            [-80.913464570999963, 35.832374167000069],
            [-80.913520793999965, 35.831381774000079],
            [-80.913552290999974, 35.83079755600005],
            [-80.913574805999986, 35.830365224000047],
            [-80.913608873999976, 35.829764859000079],
            [-80.913685391999934, 35.828346306000071],
            [-80.91375101899996, 35.827164254000024],
            [-80.913789834999989, 35.826436905000037],
            [-80.913821721999966, 35.82587081500003],
            [-80.91385487499997, 35.825230733000069],
            [-80.91388821399994, 35.824642073000064],
            [-80.913924646999988, 35.824000987000034],
            [-80.91394556399996, 35.82361684600005],
            [-80.914003433999937, 35.822603451000077],
            [-80.914038461999951, 35.821952042000078],
            [-80.914092296999968, 35.820997039000076],
            [-80.914139116999934, 35.820113381000056],
            [-80.914178245999949, 35.819409225000072],
            [-80.914237659999969, 35.818345198000031],
            [-80.914275518999943, 35.817687273000047],
            [-80.914312934999941, 35.816979160000074],
            [-80.914347058999965, 35.81637586800008],
            [-80.914376656999934, 35.815864513000065],
            [-80.914404108999975, 35.815327745000047],
            [-80.914450253999973, 35.814511733000074],
            [-80.914489059999937, 35.813797961000034],
            [-80.914522396999985, 35.813188527000079],
            [-80.914599805999956, 35.812080016000039],
            [-80.914638973999956, 35.811291260000075],
            [-80.914658696999936, 35.810842424000043],
            [-80.914675555999963, 35.810422073000041],
            [-80.914706343999967, 35.80987512400003],
            [-80.914735617999952, 35.809302030000026],
            [-80.914770684999951, 35.808696027000053],
            [-80.914799236999954, 35.808325506000074],
            [-80.914826442999981, 35.807932506000043],
            [-80.914852810999946, 35.807511400000067],
            [-80.91487776799994, 35.807116842000028],
            [-80.914904886999977, 35.806619559000069],
            [-80.914926109999953, 35.80617198200008],
            [-80.914961630999983, 35.805417558000045],
            [-80.914990892999981, 35.804626606000056],
            [-80.915014025999938, 35.80418241600006],
            [-80.91507495899998, 35.803224385000078],
            [-80.915101475999961, 35.802835752000078],
            [-80.915132484999958, 35.802481589000024],
            [-80.91518376199997, 35.801928728000064],
            [-80.915225815999975, 35.801438244000053],
            [-80.915368776999969, 35.799946959000067],
            [-80.915418382999974, 35.799413980000054],
            [-80.915466625999954, 35.79891679800005],
            [-80.915543868999976, 35.798090105000028],
            [-80.915619900999957, 35.797310817000039],
            [-80.915679889999979, 35.796668540000042],
            [-80.91575232799994, 35.795901873000048],
            [-80.915837124999939, 35.795149683000034],
            [-80.915890631999957, 35.794592895000051],
            [-80.915942886999972, 35.794077002000051],
            [-80.915980695999963, 35.793681954000078],
            [-80.916014349999955, 35.793280326000058],
            [-80.91607953099998, 35.792433679000055],
            [-80.916169568999976, 35.791471887000057],
            [-80.916229574999988, 35.790845674000025],
            [-80.916280215999961, 35.790297552000027],
            [-80.916326125999944, 35.789815866000026],
            [-80.916372539999941, 35.789319749000072],
            [-80.916428326999949, 35.788739902000032],
            [-80.916471411999964, 35.788272025000026],
            [-80.916556656999944, 35.787359307000031],
            [-80.916629052999951, 35.786606060000054],
            [-80.916694610999969, 35.785908636000045],
            [-80.916751916999942, 35.785314479000078],
            [-80.916816419999975, 35.784607393000044],
            [-80.916868285999954, 35.784098392000033],
            [-80.916916367999988, 35.783588122000026],
            [-80.916977460999988, 35.782853419000048],
            [-80.917039500999977, 35.781965250000042],
            [-80.917087803999948, 35.781087310000032],
            [-80.917123534999973, 35.780244444000061],
            [-80.917142745999968, 35.779689846000053],
            [-80.917155156999968, 35.77925556100007],
            [-80.917162082999937, 35.778743076000069],
            [-80.917159044999948, 35.777618699000072],
            [-80.917153739999947, 35.777287114000046],
            [-80.917141661999949, 35.776682496000035],
            [-80.917102264999983, 35.775829890000068],
            [-80.917011190999972, 35.774535626000045],
            [-80.916984613999944, 35.774182662000044],
            [-80.91693990899995, 35.773649803000069],
            [-80.916887334999956, 35.77308825700004],
            [-80.916839250999942, 35.772623734000035],
            [-80.916785968999989, 35.772139396000057],
            [-80.916687783999976, 35.771094383000047],
            [-80.916600029999984, 35.770269323000036],
            [-80.916536401999963, 35.769680944000072],
            [-80.916450544999975, 35.768916604000026],
            [-80.916384640999979, 35.768298238000057],
            [-80.916177703999949, 35.767737025000031],
            [-80.916137144999936, 35.767486579000035],
            [-80.916103686999975, 35.767235436000078],
            [-80.916077349999966, 35.766983730000049],
            [-80.916058146999944, 35.766731597000046],
            [-80.916046086999984, 35.766479169000036],
            [-80.916041177999944, 35.766226582000058],
            [-80.916043421999973, 35.765973969000072],
            [-80.916052817999969, 35.765721466000059],
            [-80.915969360999952, 35.76473240200005],
            [-80.915923785999951, 35.764250046000029],
            [-80.915881859999956, 35.763835099000062],
            [-80.91581062399996, 35.763325823000059],
            [-80.915727549999986, 35.762704824000025],
            [-80.915641281999967, 35.762114824000037],
            [-80.915502858999957, 35.761343176000025],
            [-80.915319981999971, 35.760749901000054],
            [-80.915434871999935, 35.759932870000057],
            [-80.915362613999946, 35.759288067000057],
            [-80.91531078099996, 35.758838618000027],
            [-80.91514078299997, 35.757286124000075],
            [-80.915083268999979, 35.756766847000051],
            [-80.915032873999962, 35.756317963000072],
            [-80.914818620999938, 35.754365594000035],
            [-80.914762379999956, 35.753870249000045],
            [-80.91460725799999, 35.752471580000076],
            [-80.914546365999968, 35.751915500000052],
            [-80.914490877999981, 35.75141773100006],
            [-80.914435220999962, 35.75090724000006],
            [-80.914369006999948, 35.750330985000062],
            [-80.914248260999955, 35.749230858000033],
            [-80.914191897999956, 35.748703414000033],
            [-80.914121064999961, 35.748067930000047],
            [-80.914011501999937, 35.747088062000046],
            [-80.913955312999974, 35.746605427000077],
            [-80.913890805999984, 35.746006697000041],
            [-80.91382546799997, 35.745423494000079],
            [-80.913724288999958, 35.744509047000065],
            [-80.913666628999977, 35.743991264000044],
            [-80.913597019999941, 35.743345929000043],
            [-80.913545463999981, 35.742903951000073],
            [-80.913468192999972, 35.742226487000039],
            [-80.913385216999984, 35.741464912000026],
            [-80.913294198999949, 35.74064544600003],
            [-80.913222488999963, 35.739980909000053],
            [-80.913169862999951, 35.739489347000074],
            [-80.913086246999967, 35.738760152000054],
            [-80.912981746999947, 35.737834930000076],
            [-80.912917843999935, 35.737241992000065],
            [-80.912845123999944, 35.736602042000072],
            [-80.912765158999946, 35.735879480000051],
            [-80.912714173999973, 35.735401514000046],
            [-80.912623919999987, 35.734602811000059],
            [-80.912550502999977, 35.73393344200008],
            [-80.91247349799994, 35.733249869000076],
            [-80.912404265999953, 35.73260859100003],
            [-80.912328228999968, 35.731916984000065],
            [-80.912236605999965, 35.731102511000074],
            [-80.912187407999966, 35.730638767000073],
            [-80.912135846999945, 35.730166580000059],
            [-80.912081508999961, 35.729681392000032],
            [-80.911971917999949, 35.72870643400006],
            [-80.91190022699999, 35.728054607000047],
            [-80.911841849999973, 35.727520728000059],
            [-80.911739986999976, 35.726618300000041],
            [-80.911687166999968, 35.726096128000052],
            [-80.911643921999939, 35.725629938000054],
            [-80.911592267999936, 35.725114655000027],
            [-80.911532075999958, 35.724558807000051],
            [-80.911424644999954, 35.723722959000042],
            [-80.911321442999963, 35.722824555000045],
            [-80.911250716999973, 35.722225129000037],
            [-80.911180325999965, 35.721672066000053],
            [-80.911043248999988, 35.720616392000068],
            [-80.910948151999946, 35.719956745000047],
            [-80.910867260999964, 35.719415952000077],
            [-80.910709016999988, 35.718464578000066],
            [-80.910614850999934, 35.717845993000026],
            [-80.910544954999978, 35.717417152000053],
            [-80.910382044999949, 35.716389309000078],
            [-80.910283872999969, 35.715863171000024],
            [-80.91010390799994, 35.714971253000044],
            [-80.910001429999966, 35.714495861000046],
            [-80.909902493999937, 35.713971515000026],
            [-80.910018438999941, 35.713585838000029],
            [-80.910247979999951, 35.712855730000058],
            [-80.910454215999948, 35.712166717000059],
            [-80.91066111799995, 35.711431615000038],
            [-80.910855698999967, 35.710700956000039],
            [-80.910979190999967, 35.710207438000054],
            [-80.911118148999947, 35.709622205000073],
            [-80.911232661999975, 35.709110856000052],
            [-80.911335845999986, 35.708623325000076],
            [-80.911435392999977, 35.708125120000034],
            [-80.911534478999954, 35.707596590000037],
            [-80.91162247799997, 35.707093564000047],
            [-80.91172697799999, 35.706443426000078],
            [-80.911852307999936, 35.705576656000062],
            [-80.911935981999989, 35.704925612000068],
            [-80.911992077999969, 35.704438184000026],
            [-80.912034971999958, 35.704030755000076],
            [-80.912096368999983, 35.703367988000025],
            [-80.912150260999965, 35.702686954000058],
            [-80.912199915999963, 35.701871069000049],
            [-80.912227648999988, 35.701220939000052],
            [-80.912249989999964, 35.700349435000078],
            [-80.912255529999982, 35.69951254800003],
            [-80.912249145999965, 35.698855582000078],
            [-80.912232868999979, 35.698224137000068],
            [-80.912202831999934, 35.697485894000067],
            [-80.912164655999959, 35.696830596000041],
            [-80.912104487999954, 35.696017500000039],
            [-80.912028739999982, 35.695199660000071],
            [-80.911926023999968, 35.694284301000039],
            [-80.911805544999936, 35.693376905000036],
            [-80.911699314999964, 35.69267227000006],
            [-80.911606310999957, 35.692113845000051],
            [-80.911529652999945, 35.691682983000078],
            [-80.911439688999963, 35.69120711100004],
            [-80.911346897999977, 35.690743372000043],
            [-80.91125563199995, 35.690310550000049],
            [-80.911099078999939, 35.689615954000033],
            [-80.910895953999955, 35.688779442000055],
            [-80.910674959999938, 35.68793528100008],
            [-80.910486874999947, 35.687261160000048],
            [-80.910279380999953, 35.686565330000064],
            [-80.909994433999941, 35.685668445000033],
            [-80.909769427999947, 35.685000974000047],
            [-80.909604498999954, 35.684533692000059],
            [-80.909346184999947, 35.683862597000029],
            [-80.909333244999971, 35.683307837000029],
            [-80.909670465999966, 35.682992308000053],
            [-80.909887461999972, 35.682426732000067],
            [-80.910098642999969, 35.681855754000026],
            [-80.910254407999957, 35.681418575000066],
            [-80.910377176999987, 35.681064155000058],
            [-80.910567944999968, 35.680500474000041],
            [-80.910750230999952, 35.67994772600008],
            [-80.910933307999983, 35.679375604000029],
            [-80.911069607999934, 35.678937361000067],
            [-80.911250140999982, 35.678337147000036],
            [-80.911384658999964, 35.677871771000071],
            [-80.911537770999985, 35.677318661000072],
            [-80.911735089999979, 35.676567785000032],
            [-80.911898658999974, 35.675908610000079],
            [-80.911993009999946, 35.675508882000031],
            [-80.912147913999945, 35.674824147000038],
            [-80.91229890599999, 35.674120462000076],
            [-80.912422872999969, 35.673503671000049],
            [-80.912548091999952, 35.672837620000053],
            [-80.912639162999938, 35.672320483000078],
            [-80.912733809999963, 35.671747888000027],
            [-80.912823215999936, 35.67116769900008],
            [-80.91288517299995, 35.670739726000079],
            [-80.912938031999943, 35.670351887000038],
            [-80.913004587999978, 35.669834818000027],
            [-80.913066716999936, 35.669316983000044],
            [-80.913123956999982, 35.668803127000047],
            [-80.91317051599998, 35.668352791000075],
            [-80.913286061999941, 35.667727649000028],
            [-80.91354802799998, 35.667172303000029],
            [-80.913833396999962, 35.666554882000071],
            [-80.914119945999971, 35.665911957000048],
            [-80.914360835999958, 35.665350578000073],
            [-80.914677610999945, 35.664583407000066],
            [-80.91501317899997, 35.663721549000059],
            [-80.91519763499997, 35.663220725000031],
            [-80.915369398999985, 35.662754150000069],
            [-80.915524912999956, 35.662325360000068],
            [-80.915698202999977, 35.661827306000077],
            [-80.915869593999957, 35.661311056000045],
            [-80.91600723199997, 35.660868081000046],
            [-80.916127862999986, 35.660468929000047],
            [-80.916269994999936, 35.659989629000052],
            [-80.916402109999979, 35.659531488000027],
            [-80.916557577999981, 35.658986151000079],
            [-80.916721418999941, 35.658409866000056],
            [-80.916883197999937, 35.657774507000056],
            [-80.917079522999984, 35.65695504200005],
            [-80.917220329999964, 35.656324674000075],
            [-80.917361562999986, 35.655648843000051],
            [-80.917489953999961, 35.654989015000069],
            [-80.917578527999979, 35.654503007000073],
            [-80.917686733999972, 35.653861533000054],
            [-80.917808038999965, 35.653071136000051],
            [-80.917915196999957, 35.652286996000043],
            [-80.917984064999985, 35.651728763000051],
            [-80.918041715999948, 35.651207561000035],
            [-80.918110575999947, 35.650507335000043],
            [-80.918164624999974, 35.649867439000047],
            [-80.918210338999984, 35.649230167000042],
            [-80.918250561999969, 35.648570091000067],
            [-80.918281898999965, 35.647890337000035],
            [-80.918300906999946, 35.647304916000053],
            [-80.918313069999954, 35.646654717000047],
            [-80.918316072999971, 35.645990682000047],
            [-80.918311184999936, 35.645449143000064],
            [-80.918294389999971, 35.644668653000053],
            [-80.918274164999957, 35.644071813000039],
            [-80.918245893999938, 35.643484683000054],
            [-80.918198522999944, 35.642729661000033],
            [-80.918148726999959, 35.642082046000041],
            [-80.918106470999987, 35.641601460000061],
            [-80.918049617999941, 35.641022507000059],
            [-80.917976098999986, 35.640354338000066],
            [-80.917880277999984, 35.639585213000032],
            [-80.917774435999945, 35.638811907000047],
            [-80.917670544999964, 35.638079201000039],
            [-80.917560561999949, 35.637311975000046],
            [-80.917420521999986, 35.636349487000075],
            [-80.917320779999955, 35.635655391000057],
            [-80.917260010999939, 35.635247797000034],
            [-80.917195242999981, 35.634790275000057],
            [-80.917094950999967, 35.63408232300003],
            [-80.916892970999982, 35.63270404900004],
            [-80.916812971999946, 35.632132653000042],
            [-80.916742285999987, 35.63163640700003],
            [-80.916662487999986, 35.631089768000038],
            [-80.916593605999935, 35.630628239000032],
            [-80.916527291999955, 35.630165733000069],
            [-80.916443766999976, 35.629585261000045],
            [-80.916341671999987, 35.628859250000062],
            [-80.916247354999939, 35.628206385000055],
            [-80.916109118999941, 35.627254912000069],
            [-80.916041174999975, 35.626771869000038],
            [-80.915928636999979, 35.626002638000045],
            [-80.915830237999955, 35.625374647000058],
            [-80.915734357999952, 35.624800596000057],
            [-80.915634836999971, 35.624236813000039],
            [-80.915531414999975, 35.623683960000051],
            [-80.915349078999952, 35.622786331000043],
            [-80.915253901999961, 35.62235196000006],
            [-80.915107620999947, 35.621715466000069],
            [-80.914936836999971, 35.621004367000069],
            [-80.914800401999969, 35.620462209000038],
            [-80.914674138999942, 35.619981234000079],
            [-80.914542883999957, 35.619499870000027],
            [-80.914383890999943, 35.618940097000063],
            [-80.914164708999976, 35.618203046000076],
            [-80.913970454999969, 35.617577044000029],
            [-80.913811498999962, 35.617084219000049],
            [-80.913650805999964, 35.61660112800007],
            [-80.913460899999961, 35.61602663900004],
            [-80.913163882999982, 35.615179443000045],
            [-80.912935565999987, 35.614560515000051],
            [-80.912726128999964, 35.614018455000064],
            [-80.912496215999965, 35.61344897500004],
            [-80.912234836999971, 35.612822762000064],
            [-80.911971076999976, 35.612207419000072],
            [-80.911739218999969, 35.61168542300004],
            [-80.911810511999988, 35.61110345700007],
            [-80.911908447999963, 35.610542935000069],
            [-80.911979751999979, 35.609327047000079],
            [-80.912089899999955, 35.608893568000042],
            [-80.912183117999973, 35.608442835000062],
            [-80.912346964999983, 35.607911228000034],
            [-80.912434725999958, 35.607453828000075],
            [-80.912535915999968, 35.607031728000038],
            [-80.912638885999968, 35.606490643000029],
            [-80.912721089999934, 35.606031804000054],
            [-80.912788215999967, 35.605581310000048],
            [-80.912846226999989, 35.605119345000048],
            [-80.912922561999949, 35.604545699000028],
            [-80.913007068999946, 35.60394169500006],
            [-80.91308597799997, 35.603160238000044],
            [-80.913088757999958, 35.602433532000077],
            [-80.913092536999955, 35.601952733000076],
            [-80.91332652899996, 35.601513092000062],
            [-80.913382164999973, 35.600849532000041],
            [-80.91343216599995, 35.600174908000042],
            [-80.913472580999951, 35.599488468000061],
            [-80.913494602999947, 35.599013422000041],
            [-80.913519379999968, 35.598317927000039],
            [-80.91353673399999, 35.597635756000045],
            [-80.913544251999951, 35.596842202000062],
            [-80.913541531999954, 35.596232280000038],
            [-80.913535852999985, 35.595795790000068],
            [-80.913518118999946, 35.595086061000075],
            [-80.913492347999977, 35.594412899000076],
            [-80.913463387999968, 35.593834192000031],
            [-80.913443091999966, 35.593483325000079],
            [-80.913416502999951, 35.593080031000056],
            [-80.913375894999945, 35.592538554000043],
            [-80.913328305999983, 35.591984402000037],
            [-80.913287819999937, 35.591559372000063],
            [-80.913244523999936, 35.591141953000033],
            [-80.913190550999957, 35.590659014000039],
            [-80.913129433999984, 35.590152828000043],
            [-80.913063591999958, 35.589646662000064],
            [-80.912982126999964, 35.589066519000028],
            [-80.912903292999943, 35.588544803000048],
            [-80.912813222999944, 35.587998718000051],
            [-80.91272758599996, 35.587497875000054],
            [-80.912634139999966, 35.586979765000024],
            [-80.912531422999962, 35.586446865000028],
            [-80.912403063999989, 35.585822976000031],
            [-80.912269725999977, 35.585210204000077],
            [-80.912101606999954, 35.584476826000071],
            [-80.911941837999962, 35.583815732000062],
            [-80.911742502999971, 35.58303869100007],
            [-80.911570787999949, 35.582332350000058],
            [-80.911435142999949, 35.581816452000055],
            [-80.911294340999973, 35.581301640000049],
            [-80.911116818999972, 35.580670045000033],
            [-80.910905673999935, 35.579880790000061],
            [-80.91075305399994, 35.579309200000068],
            [-80.910608836999984, 35.578766097000027],
            [-80.910504514999957, 35.57837145700006],
            [-80.910364724999965, 35.57783966900007],
            [-80.910225982999975, 35.57732784500007],
            [-80.910122167999987, 35.576945414000079],
            [-80.909974094999939, 35.57638849600005],
            [-80.910022501999947, 35.575858326000059],
            [-80.910218067999949, 35.575150693000069],
            [-80.910353745999942, 35.574650333000079],
            [-80.910479201999976, 35.57417913300003],
            [-80.910587916999987, 35.573758076000047],
            [-80.910705168999982, 35.573281850000058],
            [-80.910822907999943, 35.57277911400007],
            [-80.91095330099995, 35.572193024000057],
            [-80.911082120999936, 35.571576579000066],
            [-80.911198113999944, 35.570983827000077],
            [-80.911302506999959, 35.570413849000033],
            [-80.911398499999962, 35.569859435000069],
            [-80.911484990999952, 35.569325513000024],
            [-80.91157041699995, 35.568758135000053],
            [-80.911652076999985, 35.568169614000055],
            [-80.911732195999946, 35.567533016000027],
            [-80.911796817999971, 35.566958793000026],
            [-80.911855620999972, 35.566370496000047],
            [-80.911909049999963, 35.565756787000055],
            [-80.911955584999987, 35.565126598000063],
            [-80.91199292999994, 35.564510344000041],
            [-80.912025255999936, 35.563817005000033],
            [-80.912043308999955, 35.563294129000042],
            [-80.912056829999983, 35.562719662000063],
            [-80.912063917999944, 35.562135970000043],
            [-80.912064732999966, 35.561628213000063],
            [-80.91205950899996, 35.56107125300008],
            [-80.912036546999957, 35.560163283000065],
            [-80.911992805999944, 35.55948741800006],
            [-80.911950659999945, 35.558593423000048],
            [-80.911875239999972, 35.557570054000053],
            [-80.911841799999934, 35.557174789000044],
            [-80.911782140999946, 35.556572589000041],
            [-80.911693191999973, 35.555864314000075],
            [-80.911586247999935, 35.555083861000071],
            [-80.911472782999965, 35.554193982000072],
            [-80.911423947999936, 35.553857781000033],
            [-80.911492686999964, 35.55355038600004],
            [-80.91158074699996, 35.553148171000032],
            [-80.911718231999942, 35.552514219000045],
            [-80.911821885999984, 35.552037860000041],
            [-80.911916291999944, 35.551613724000049],
            [-80.912029114999939, 35.551099542000031],
            [-80.912162587999944, 35.550500681000074],
            [-80.91227384299998, 35.549989949000064],
            [-80.912365098999942, 35.549579651000045],
            [-80.91246492099998, 35.549124901000027],
            [-80.912720150999974, 35.547972163000054],
            [-80.91284776599997, 35.547373709000055],
            [-80.912945756999989, 35.546905020000054],
            [-80.913032688999976, 35.546485612000026],
            [-80.913156225999955, 35.545923390000041],
            [-80.913289716999941, 35.545287034000069],
            [-80.913402367999936, 35.544774177000079],
            [-80.91350222799997, 35.544363084000054],
            [-80.913657587999978, 35.54369212000006],
            [-80.913814041999956, 35.542976254000052],
            [-80.91397439299999, 35.542225083000062],
            [-80.914084399999979, 35.541723307000041],
            [-80.914307472999951, 35.540639790000057],
            [-80.914391031999969, 35.540260934000059],
            [-80.91448010199997, 35.539849491000041],
            [-80.91461095599999, 35.539259714000025],
            [-80.914701894999951, 35.53884307800007],
            [-80.914851344999988, 35.538169656000036],
            [-80.914941158999966, 35.537751677000074],
            [-80.915056102999984, 35.537227821000045],
            [-80.915185751999957, 35.536639831000059],
            [-80.915353866999965, 35.535879236000028],
            [-80.915501863999964, 35.535227164000048],
            [-80.915684060999979, 35.534465495000063],
            [-80.915762799999982, 35.534118530000057],
            [-80.915938532999974, 35.53332792100008],
            [-80.916088083999966, 35.53262972400006],
            [-80.91625552499994, 35.531871927000054],
            [-80.91639841999995, 35.531197478000024],
            [-80.916495096999938, 35.530718709000041],
            [-80.916578397999956, 35.530308641000033],
            [-80.916766546999952, 35.529460787000062],
            [-80.916855234999957, 35.529053943000065],
            [-80.916965042999948, 35.528554561000078],
            [-80.917052402999957, 35.528154744000062],
            [-80.917164269999944, 35.527654976000065],
            [-80.917345166999951, 35.52684152900008],
            [-80.917438252999943, 35.526418371000034],
            [-80.917566845999943, 35.525821915000051],
            [-80.917706306999946, 35.525145259000055],
            [-80.917808854999976, 35.524620595000044],
            [-80.917905419999954, 35.52410069900003],
            [-80.917853949999937, 35.523393817000056],
            [-80.917988874999935, 35.522849850000057],
            [-80.91809539999997, 35.522325651000074],
            [-80.918238857999938, 35.52176053200003],
            [-80.918333541999971, 35.521255170000074],
            [-80.918411703999936, 35.520700617000045],
            [-80.918482753999967, 35.520172369000079],
            [-80.918547066999963, 35.51968024100006],
            [-80.918616228999952, 35.519017046000045],
            [-80.918660043999978, 35.518506898000055],
            [-80.918712299999981, 35.517843222000067],
            [-80.918754029999945, 35.516994747000069],
            [-80.918707373999951, 35.516300784000066],
            [-80.91888634999998, 35.515580572000033],
            [-80.918939750999982, 35.515002449000065],
            [-80.918965316999959, 35.514688312000033],
            [-80.91902421399999, 35.513973732000068],
            [-80.919101722999983, 35.513140583000052],
            [-80.91917254699996, 35.512494693000065],
            [-80.91958399799995, 35.51166316900003],
            [-80.919919931999971, 35.510983599000042],
            [-80.920166799999947, 35.510482617000036],
            [-80.92052688199999, 35.509772243000043],
            [-80.920766717999982, 35.509288812000079],
            [-80.92116189799998, 35.508474532000037],
            [-80.921483941999952, 35.507826934000036],
            [-80.921771027999966, 35.507255646000033],
            [-80.922115022999947, 35.506563418000042],
            [-80.92247262099994, 35.505845062000049],
            [-80.922819660999949, 35.505133208000075],
            [-80.923107594999976, 35.504543682000076],
            [-80.923303112999974, 35.504147064000051],
            [-80.923458098999959, 35.503827706000038],
            [-80.923696912999958, 35.503345128000035],
            [-80.923915744999988, 35.502886800000056],
            [-80.924267085999986, 35.502122199000041],
            [-80.924500733999935, 35.50159220900008],
            [-80.924794717999987, 35.500899372000049],
            [-80.924997910999934, 35.50041058100004],
            [-80.925240853999981, 35.499793405000048],
            [-80.925571108999975, 35.498909444000049],
            [-80.92580596199997, 35.498246744000028],
            [-80.925977263999982, 35.497740030000045],
            [-80.926098808999939, 35.497366299000078],
            [-80.926222111999948, 35.496974202000047],
            [-80.926359035999951, 35.496524008000051],
            [-80.926472806999982, 35.49613643400005],
            [-80.926691276999975, 35.495351303000064],
            [-80.926870593999979, 35.494665932000032],
            [-80.926998975999936, 35.494141209000077],
            [-80.927154151999957, 35.49346935300008],
            [-80.927258497999958, 35.492982728000072],
            [-80.927417795999986, 35.492186852000032],
            [-80.92752533099997, 35.491602391000072],
            [-80.92763946499997, 35.490913268000043],
            [-80.927695770999946, 35.490546290000054],
            [-80.927778172999979, 35.489966032000041],
            [-80.927832055999943, 35.489552770000046],
            [-80.927887474999977, 35.489090938000061],
            [-80.927943543999959, 35.488555801000075],
            [-80.928006707999941, 35.487837461000026],
            [-80.928055899999947, 35.48722391900003],
            [-80.928093360999981, 35.486645059000068],
            [-80.92813321999995, 35.485763013000053],
            [-80.928158042999939, 35.484741052000061],
            [-80.928164627999934, 35.484039001000042],
            [-80.928168738999943, 35.483484347000058],
            [-80.928175150999948, 35.482159546000048],
            [-80.928174155999955, 35.481566624000038],
            [-80.92817602599996, 35.481172308000055],
            [-80.928188333999969, 35.480543906000037],
            [-80.928197897999951, 35.479528005000077],
            [-80.92819588499998, 35.479138920000025],
            [-80.928186721999964, 35.47839450400005],
            [-80.928191121999987, 35.477245348000054],
            [-80.928204366999978, 35.476736613000071],
            [-80.928227220999986, 35.476238670000043],
            [-80.928248525999948, 35.475767488000031],
            [-80.928276852999943, 35.475364219000028],
            [-80.928303694999954, 35.475022840000065],
            [-80.928347145999965, 35.474554727000054],
            [-80.928391371999965, 35.47415473500007],
            [-80.928426925999986, 35.473809600000038],
            [-80.928489344999946, 35.473270438000043],
            [-80.928548670999987, 35.472720432000074],
            [-80.928637039999956, 35.471916738000061],
            [-80.928730146999953, 35.471137798000029],
            [-80.92879781399995, 35.470535163000079],
            [-80.928846169999986, 35.470083526000053],
            [-80.928886019999936, 35.469694471000025],
            [-80.928946505999988, 35.469036263000078],
            [-80.928990012999975, 35.468515143000047],
            [-80.929049747999954, 35.467659670000046],
            [-80.929076911999971, 35.467167434000032],
            [-80.929102970999963, 35.466583423000031],
            [-80.929122086999939, 35.466000496000049],
            [-80.92913180499994, 35.465548937000051],
            [-80.929138679999937, 35.464949980000029],
            [-80.929131378999955, 35.463927925000064],
            [-80.929118875999961, 35.463380975000064],
            [-80.929101913999943, 35.462842078000051],
            [-80.929088202999935, 35.462467941000057],
            [-80.929059450999944, 35.461910077000027],
            [-80.928984451999952, 35.460795078000046],
            [-80.928919820999965, 35.46005219400007],
            [-80.928872579999961, 35.459583862000045],
            [-80.92882689299995, 35.459171242000025],
            [-80.928729285999964, 35.458383162000075],
            [-80.928659220999975, 35.457875175000027],
            [-80.928559775999986, 35.45721876500005],
            [-80.928444966999962, 35.456520122000029],
            [-80.928344608999964, 35.455952945000035],
            [-80.928258891999974, 35.455488638000077],
            [-80.928161225999986, 35.454986346000055],
            [-80.928058052999972, 35.454486389000067],
            [-80.92783045699997, 35.453474198000038],
            [-80.927687148999951, 35.452883022000037],
            [-80.927516241999967, 35.452220340000054],
            [-80.927335300999971, 35.451553999000055],
            [-80.927224330999934, 35.451165044000049],
            [-80.92708257999999, 35.450686010000027],
            [-80.926987577999967, 35.450372119000065],
            [-80.92676877599996, 35.449663991000079],
            [-80.926626793999958, 35.449229225000067],
            [-80.926457150999966, 35.448729159000038],
            [-80.926220335999972, 35.448059045000036],
            [-80.926090388999967, 35.447702091000053],
            [-80.925965749999989, 35.44736673500006],
            [-80.925682868999957, 35.446630693000031],
            [-80.925421805999974, 35.44597968000005],
            [-80.925083683999958, 35.445166845000074],
            [-80.924860448999937, 35.444652251000036],
            [-80.924456602999953, 35.443761373000029],
            [-80.924218007999968, 35.443254248000073],
            [-80.92403471199998, 35.44286909300007],
            [-80.923791679999965, 35.442375195000068],
            [-80.92325157199997, 35.44132116600008],
            [-80.922787346999939, 35.440536230000077],
            [-80.922652788999983, 35.439986332000046],
            [-80.922697750999987, 35.439283452000041],
            [-80.922706252999944, 35.438763068000071],
            [-80.922710971999948, 35.438292506000039],
            [-80.922708700999976, 35.437553816000047],
            [-80.922701304999975, 35.437093168000047],
            [-80.922686053999939, 35.436557997000079],
            [-80.922634451999954, 35.435223471000029],
            [-80.922602025999936, 35.434638512000049],
            [-80.922517948999939, 35.433469519000028],
            [-80.922454534999986, 35.432776650000051],
            [-80.922414514999957, 35.432414874000074],
            [-80.922340952999946, 35.431762028000037],
            [-80.922227500999952, 35.430888537000044],
            [-80.922157518999938, 35.430400244000054],
            [-80.922106480999958, 35.430061885000043],
            [-80.922014415999968, 35.42948549700003],
            [-80.921921704999988, 35.428952673000026],
            [-80.921846623999954, 35.428546951000044],
            [-80.921720353999945, 35.427906569000072],
            [-80.92147844699997, 35.426788326000064],
            [-80.921366360999968, 35.426311725000062],
            [-80.921262083999977, 35.425860965000027],
            [-80.921133140999984, 35.425327400000072],
            [-80.921000445999937, 35.424808579000057],
            [-80.92087556499996, 35.424338102000036],
            [-80.920703814999968, 35.423709888000076],
            [-80.92043840599996, 35.422795992000033],
            [-80.920276987999955, 35.422269162000077],
            [-80.920120013999963, 35.421771349000039],
            [-80.919977399999937, 35.421337354000059],
            [-80.919780905999971, 35.420751960000075],
            [-80.919519080999976, 35.42000325600003],
            [-80.919262661999937, 35.419304724000028],
            [-80.91902386199996, 35.418676474000051],
            [-80.91889791899996, 35.418353975000059],
            [-80.918776755999943, 35.418051157000036],
            [-80.918541777999963, 35.417477396000038],
            [-80.918275421999965, 35.416844133000041],
            [-80.917952634999949, 35.416105326000036],
            [-80.917694684999958, 35.415536492000058],
            [-80.917427725999971, 35.414965094000024],
            [-80.916997553999977, 35.414072560000079],
            [-80.916537330999972, 35.413156584000035],
            [-80.916225870999938, 35.412559754000029],
            [-80.91595231499997, 35.412048878000064],
            [-80.915628528999946, 35.411457885000061],
            [-80.915297844999941, 35.41085871000007],
            [-80.915012486999956, 35.41035796400007],
            [-80.914676736999979, 35.409784261000027],
            [-80.914213612999959, 35.409006765000072],
            [-80.913980037999977, 35.408603945000038],
            [-80.913699848999954, 35.408119691000024],
            [-80.913225575999945, 35.407321180000054],
            [-80.912936282999965, 35.406828535000045],
            [-80.912631572999942, 35.406311986000048],
            [-80.912366127999974, 35.405860534000055],
            [-80.912022086999968, 35.40527245100003],
            [-80.911778903999959, 35.40486028600003],
            [-80.911433628999987, 35.404271072000029],
            [-80.91124948199996, 35.403958984000042],
            [-80.91109998099995, 35.403707854000061],
            [-80.910777229999951, 35.403159948000052],
            [-80.910394047999944, 35.401249406000034],
            [-80.91031649599995, 35.400784219000059],
            [-80.910240091999981, 35.400306947000047],
            [-80.910170172999983, 35.399838715000044],
            [-80.910006283999962, 35.398785666000038],
            [-80.909901666999986, 35.398161827000024],
            [-80.909708862999935, 35.397342060000028],
            [-80.90954394399995, 35.396696813000062],
            [-80.90949283499998, 35.396099876000051],
            [-80.909397521999949, 35.395672753000042],
            [-80.909305529999983, 35.395093294000048],
            [-80.909189453999943, 35.394413533000034],
            [-80.909106729999962, 35.393924919000028],
            [-80.90902766499994, 35.393504202000031],
            [-80.908873931999949, 35.392906406000066],
            [-80.908702415999983, 35.392325565000078],
            [-80.908610978999945, 35.391960712000071],
            [-80.908463762999986, 35.391421915000024],
            [-80.908311449999985, 35.390884069000037],
            [-80.90815405099994, 35.390347207000048],
            [-80.907991573999936, 35.389811360000067],
            [-80.907837530999984, 35.38902592900007],
            [-80.907691283999952, 35.388568095000039],
            [-80.907489900999963, 35.387956563000046],
            [-80.907244262999939, 35.387241251000034],
            [-80.907069317999969, 35.386752386000069],
            [-80.906890363999935, 35.386265348000052],
            [-80.906667753999955, 35.385678167000037],
            [-80.906485473999965, 35.385208434000049],
            [-80.906353626999987, 35.384878663000052],
            [-80.90614267899997, 35.384367578000024],
            [-80.905780099999959, 35.383524194000074],
            [-80.904968365999935, 35.381718539000076],
            [-80.90472376699995, 35.381190089000029],
            [-80.904334564999942, 35.380398897000077],
            [-80.903734783999937, 35.379245376000029],
            [-80.90329918499998, 35.378450890000067],
            [-80.902756057999966, 35.377467656000078],
            [-80.902685004999967, 35.376981409000052],
            [-80.90259879499996, 35.376428698000041],
            [-80.902524301999961, 35.37597555900004],
            [-80.902438850999943, 35.375489875000028],
            [-80.902292794999937, 35.374732287000029],
            [-80.902130604999968, 35.37395289300008],
            [-80.902008739999985, 35.373396218000039],
            [-80.901853342999971, 35.372723611000026],
            [-80.901715684999942, 35.372163793000027],
            [-80.901547715999982, 35.371515593000026],
            [-80.901298098999973, 35.370619826000052],
            [-80.901092156999937, 35.36992536300005],
            [-80.900877354999977, 35.369236071000046],
            [-80.900700979999954, 35.368692409000062],
            [-80.900547446999951, 35.368235828000024],
            [-80.900400222999963, 35.367809431000069],
            [-80.900285442999973, 35.36748570900005],
            [-80.900112006999962, 35.36700815100005],
            [-80.89992958199997, 35.366519685000071],
            [-80.899731396999982, 35.366004288000056],
            [-80.899543929999936, 35.365536904000066],
            [-80.899277371999972, 35.364899189000027],
            [-80.899110664999967, 35.364506875000075],
            [-80.898890225999935, 35.363999792000072],
            [-80.89869516899995, 35.363563692000071],
            [-80.898234767999952, 35.362992685000052],
            [-80.898192194999979, 35.362363110000047],
            [-80.898462203999941, 35.362067109000066],
            [-80.898579100999939, 35.361704946000032],
            [-80.898739722999949, 35.361187991000065],
            [-80.898927119999939, 35.360587041000031],
            [-80.89925348099996, 35.359474799000054],
            [-80.899387907999937, 35.359016860000054],
            [-80.899534643999971, 35.358513559000073],
            [-80.899789616999954, 35.357587846000058],
            [-80.899927395999953, 35.35705194500008],
            [-80.900045207999938, 35.356558883000048],
            [-80.90021121999996, 35.355842241000062],
            [-80.900395208999953, 35.355007914000055],
            [-80.900606937999953, 35.354010359000029],
            [-80.900724011999955, 35.35342838300005],
            [-80.900874481999949, 35.352642985000045],
            [-80.900996321999969, 35.352004168000065],
            [-80.901095832999943, 35.351445031000026],
            [-80.901200356999937, 35.350799547000065],
            [-80.90134037699994, 35.349857008000072],
            [-80.901444482999977, 35.349078427000052],
            [-80.901723493999953, 35.346734679000065],
            [-80.901785964999988, 35.346137673000044],
            [-80.901855139999952, 35.345344451000074],
            [-80.901919480999936, 35.344426010000063],
            [-80.901979643999937, 35.343348509000066],
            [-80.902011139999956, 35.342787722000025],
            [-80.902039646999981, 35.342148039000051],
            [-80.902057465999974, 35.341526114000033],
            [-80.902064235999944, 35.341142432000026],
            [-80.902075886999967, 35.340611956000032],
            [-80.902082400999973, 35.339642430000026],
            [-80.902075932999935, 35.33903302300007],
            [-80.902065256999947, 35.33854982400004],
            [-80.902055798999982, 35.338246879000053],
            [-80.902033546999974, 35.337539029000027],
            [-80.90200436799995, 35.336853069000028],
            [-80.901734328999964, 35.331919041000049],
            [-80.901704410999969, 35.331374093000079],
            [-80.90167751599995, 35.330836502000068],
            [-80.901614324999969, 35.329740069000025],
            [-80.901576859999977, 35.329099605000067],
            [-80.901538755999979, 35.32854130700008],
            [-80.901508876999969, 35.328040643000065],
            [-80.901475324999979, 35.327470324000046],
            [-80.901444187999971, 35.326935718000072],
            [-80.901408300999947, 35.326375675000065],
            [-80.901379848999966, 35.325845474000062],
            [-80.901346612999987, 35.325223665000067],
            [-80.901306150999972, 35.324581262000038],
            [-80.901278291999972, 35.324091204000069],
            [-80.901243927999985, 35.323565358000053],
            [-80.901209453999968, 35.322975995000036],
            [-80.901188954999952, 35.32263479900007],
            [-80.90117227799999, 35.322329962000026],
            [-80.901140601999941, 35.321786280000026],
            [-80.901119254999969, 35.321440536000068],
            [-80.901065666999955, 35.32051595400003],
            [-80.901030274999982, 35.319976202000078],
            [-80.900989297999956, 35.319310485000074],
            [-80.900941461999935, 35.318478303000063],
            [-80.90087884999997, 35.31740787800004],
            [-80.900847797999972, 35.316815459000054],
            [-80.900801139999942, 35.316058471000076],
            [-80.900777919999939, 35.315692717000047],
            [-80.900756339999987, 35.314919777000057],
            [-80.900923159999934, 35.314151533000029],
            [-80.901305534999949, 35.312462356000026],
            [-80.901498910999976, 35.311671712000077],
            [-80.90167244099996, 35.310958291000077],
            [-80.901808985999935, 35.310370801000033],
            [-80.902056245999972, 35.309253781000052],
            [-80.902253460999987, 35.308362589000069],
            [-80.902353345999984, 35.30793101200004],
            [-80.902473971999939, 35.307414059000052],
            [-80.902562805999935, 35.307033950000061],
            [-80.902753314999984, 35.306201259000034],
            [-80.902899862999959, 35.305575909000027],
            [-80.90301846899996, 35.305061092000074],
            [-80.903220580999971, 35.304164462000074],
            [-80.903358398999956, 35.303550542000039],
            [-80.903498527999943, 35.302898105000054],
            [-80.903634847999967, 35.302230633000079],
            [-80.903763506999951, 35.301564451000047],
            [-80.903839504999951, 35.301138915000024],
            [-80.903914395999948, 35.300689729000055],
            [-80.904023165999945, 35.299982080000063],
            [-80.904069309999954, 35.299661504000028],
            [-80.904194608999944, 35.298722209000061],
            [-80.904282338999963, 35.297957416000031],
            [-80.904338686999949, 35.297388127000033],
            [-80.904385974999968, 35.296852086000058],
            [-80.904454400999953, 35.295890186000065],
            [-80.904491315999962, 35.29520605600004],
            [-80.90450698799998, 35.294838403000028],
            [-80.904521074999934, 35.294458443000053],
            [-80.904534324999986, 35.293988995000063],
            [-80.904540481999959, 35.293695892000073],
            [-80.904548290999969, 35.293210482000063],
            [-80.904550852999989, 35.292445010000051],
            [-80.904545994999978, 35.291980354000032],
            [-80.904539711999973, 35.291634916000078],
            [-80.904530749999935, 35.291280552000046],
            [-80.904510252999955, 35.290772512000046],
            [-80.904466486999979, 35.28998488700006],
            [-80.904424027999937, 35.289324729000043],
            [-80.904375946999949, 35.288700838000068],
            [-80.904314512999974, 35.28801620400003],
            [-80.90426957699998, 35.287585935000038],
            [-80.904215527999952, 35.287112813000078],
            [-80.904153820999966, 35.286599184000067],
            [-80.904088037999941, 35.286110588000042],
            [-80.90394306099995, 35.285134980000066],
            [-80.903861515999949, 35.284634636000078],
            [-80.903785518999939, 35.284208362000072],
            [-80.903651565999951, 35.283489597000028],
            [-80.903439929999934, 35.282453849000035],
            [-80.903289878999942, 35.281784386000027],
            [-80.903138693999949, 35.28114127200007],
            [-80.903047042999958, 35.280764809000061],
            [-80.902948850999962, 35.280373122000071],
            [-80.902790974999959, 35.27978590400005],
            [-80.902455485999951, 35.27861524900004],
            [-80.902252256999986, 35.277952736000032],
            [-80.902097602999959, 35.277479260000064],
            [-80.901887041999942, 35.276852224000038],
            [-80.90149017899995, 35.275738280000041],
            [-80.901219031999972, 35.27501979200008],
            [-80.901038353999979, 35.274556426000061],
            [-80.900760891999937, 35.27386716500007],
            [-80.900444634999985, 35.273109079000051],
            [-80.900025394999943, 35.272153591000063],
            [-80.899802972999964, 35.271603990000074],
            [-80.899773299999936, 35.271036621000064],
            [-80.899757453999939, 35.270499698000037],
            [-80.899717801999941, 35.26965529000006],
            [-80.899688988999969, 35.269203179000044],
            [-80.899647211999934, 35.26865099500003],
            [-80.899611514999947, 35.26823758300003],
            [-80.899558881999951, 35.267709767000042],
            [-80.89946704099998, 35.266921947000071],
            [-80.899400499999956, 35.266425983000033],
            [-80.899304239999935, 35.265773638000042],
            [-80.899220938999974, 35.265256050000062],
            [-80.899127746999966, 35.264727681000068],
            [-80.899034234999988, 35.264235493000058],
            [-80.898942906999935, 35.263782670000069],
            [-80.898808220999967, 35.26315810400007],
            [-80.898589293999976, 35.262753136000072],
            [-80.898673869999982, 35.26226910400004],
            [-80.899083298999983, 35.261971686000038],
            [-80.89975939499999, 35.261291596000035],
            [-80.900287729999945, 35.260742328000049],
            [-80.900769376999961, 35.260223395000025],
            [-80.901364341999965, 35.259566527000061],
            [-80.902017010999941, 35.258824111000024],
            [-80.902511278999953, 35.258239952000054],
            [-80.903094007999982, 35.257524199000045],
            [-80.903541018999988, 35.256958696000027],
            [-80.904001638999944, 35.256359887000031],
            [-80.904540923999946, 35.255624885000032],
            [-80.905331850999971, 35.254491296000026],
            [-80.905833390999987, 35.253738209000062],
            [-80.906219692999969, 35.253126491000046],
            [-80.906491107999955, 35.252684926000029],
            [-80.906784409999943, 35.25219575400007],
            [-80.906996412999945, 35.251875025000061],
            [-80.907510284999944, 35.251382489000036],
            [-80.908206189999987, 35.250723140000048],
            [-80.908725431999983, 35.250229585000056],
            [-80.909368374999985, 35.249624016000041],
            [-80.909894245999965, 35.249129725000046],
            [-80.910390312999937, 35.248672474000045],
            [-80.910953080999946, 35.248143864000042],
            [-80.911275134999983, 35.247834071000057],
            [-80.911777719999975, 35.247340763000068],
            [-80.912129280999977, 35.246988393000038],
            [-80.912603701999956, 35.246502639000028],
            [-80.913225074999957, 35.245837889000029],
            [-80.914131607999934, 35.244828375000054],
            [-80.914960635999989, 35.243850136000049],
            [-80.915714962999971, 35.242905861000054],
            [-80.916219875999957, 35.24225018900006],
            [-80.916475336999952, 35.241907444000049],
            [-80.916733554999951, 35.241554111000028],
            [-80.91699684799994, 35.241187370000034],
            [-80.917350381999938, 35.24068425400003],
            [-80.91761980299998, 35.240291978000073],
            [-80.91801623799995, 35.239700476000053],
            [-80.918209262999937, 35.23940600800006],
            [-80.918452701999968, 35.239026380000041],
            [-80.918877907999956, 35.238346112000045],
            [-80.919211756999971, 35.237794935000068],
            [-80.919615338999961, 35.237106935000043],
            [-80.920039972999973, 35.236342948000072],
            [-80.920557333999966, 35.235362413000075],
            [-80.920874946999959, 35.234734777000028],
            [-80.921075911999935, 35.23432013200005],
            [-80.921384941999975, 35.233659550000027],
            [-80.921556451999948, 35.233280576000027],
            [-80.921805621999965, 35.232712374000073],
            [-80.921984100999964, 35.232293051000056],
            [-80.922226204999959, 35.231703492000065],
            [-80.922419567999953, 35.231213280000077],
            [-80.922563871999955, 35.230835465000041],
            [-80.922735585999988, 35.230372948000024],
            [-80.922876336999934, 35.22998246800006],
            [-80.922982220999984, 35.229680052000049],
            [-80.923171645999957, 35.229120812000076],
            [-80.923414915999956, 35.22860614800004],
            [-80.92387716799999, 35.228034688000037],
            [-80.924176025999941, 35.22765617500005],
            [-80.924705948999986, 35.22696763600004],
            [-80.924982387999989, 35.226599100000044],
            [-80.92541816399995, 35.226003456000058],
            [-80.925697930999945, 35.225612675000036],
            [-80.926098215999957, 35.225043385000049],
            [-80.926361449999945, 35.22465980000004],
            [-80.926731074999964, 35.224107966000076],
            [-80.926966205999975, 35.223747959000036],
            [-80.927233218999959, 35.223329312000033],
            [-80.92755492799995, 35.222813125000073],
            [-80.927818180999964, 35.222379915000033],
            [-80.928234290999967, 35.22167289500004],
            [-80.928467055999988, 35.221267655000077],
            [-80.929079483999942, 35.22017461300004],
            [-80.929680395999981, 35.219021762000068],
            [-80.930070267999952, 35.21823239500003],
            [-80.930399632999979, 35.217532443000039],
            [-80.93073697899996, 35.216850574000034],
            [-80.930911039999955, 35.21649611600003],
            [-80.931186842999978, 35.215908940000077],
            [-80.931658212999935, 35.214857817000052],
            [-80.932283086999973, 35.213498309000045],
            [-80.932524742999988, 35.21297467900007],
            [-80.932963044999951, 35.212049862000072],
            [-80.933312254999976, 35.211338786000056],
            [-80.933691711999984, 35.210522018000063],
            [-80.934036540999955, 35.20977300900006],
            [-80.934479170999964, 35.208839011000066],
            [-80.93475373199999, 35.20824478000003],
            [-80.93499873899998, 35.207735099000047],
            [-80.935357834999934, 35.206971526000075],
            [-80.935615484999971, 35.206392701000027],
            [-80.935953088999952, 35.205698464000079],
            [-80.936195593999969, 35.205186871000024],
            [-80.936518794999984, 35.204488949000051],
            [-80.936963737999974, 35.203530994000062],
            [-80.937246719999962, 35.20290723800008],
            [-80.937395851999952, 35.202571849000037],
            [-80.93758448899996, 35.20213717200005],
            [-80.937806549999948, 35.201610249000055],
            [-80.93797836799996, 35.201190570000051],
            [-80.938108494999938, 35.200862976000053],
            [-80.938341183999967, 35.200257733000058],
            [-80.938493654999945, 35.199846869000055],
            [-80.938656858999934, 35.199392928000066],
            [-80.938786070999981, 35.199022442000057],
            [-80.93900021099995, 35.198381547000054],
            [-80.93920525499999, 35.197731035000061],
            [-80.939445206999949, 35.196925023000063],
            [-80.939570525999954, 35.196480025000028],
            [-80.939763836999987, 35.195756847000041],
            [-80.939868176999937, 35.195345131000067],
            [-80.940028071999961, 35.19467809300005],
            [-80.940133237999987, 35.194215191000069],
            [-80.940304284999968, 35.193423186000075],
            [-80.940396694999947, 35.192963905000056],
            [-80.940518663999967, 35.192311868000047],
            [-80.940609431999974, 35.191782293000074],
            [-80.940710154999977, 35.191122918000076],
            [-80.940845096999965, 35.190121678000025],
            [-80.940937703999964, 35.189288126000065],
            [-80.941008890999967, 35.188456805000044],
            [-80.941054654999959, 35.187803121000059],
            [-80.941091342999982, 35.187185611000075],
            [-80.941118408999955, 35.186628250000069],
            [-80.941133957999966, 35.186232802000063],
            [-80.941146883999977, 35.185576061000063],
            [-80.941148533999979, 35.184295831000043],
            [-80.941120916999978, 35.183284894000053],
            [-80.941066235999983, 35.182338714000025],
            [-80.941027670999972, 35.18181366400006],
            [-80.94099436099998, 35.181411321000041],
            [-80.940947230999939, 35.180899959000044],
            [-80.940860638999936, 35.18014502300008],
            [-80.940710946999957, 35.179045562000056],
            [-80.940604103999988, 35.178356793000034],
            [-80.940529995999952, 35.177929186000028],
            [-80.940434265999954, 35.177362729000038],
            [-80.940526529999943, 35.176906713000051],
            [-80.940760959999977, 35.176078627000038],
            [-80.940964062999967, 35.17534147300006],
            [-80.941227293999987, 35.174556270000039],
            [-80.941567016999954, 35.173581872000057],
            [-80.94182456499999, 35.172818494000069],
            [-80.941997396999966, 35.17228202900003],
            [-80.94214328999999, 35.171865666000031],
            [-80.942303083999946, 35.171470172000056],
            [-80.943071539999949, 35.170719883000061],
            [-80.943478711999944, 35.170348922000073],
            [-80.943978138999967, 35.169895394000037],
            [-80.944335278999972, 35.169564620000074],
            [-80.945070859999987, 35.168882318000044],
            [-80.945751565999956, 35.168264713000042],
            [-80.946495311999968, 35.167585053000039],
            [-80.946872593999956, 35.167248432000065],
            [-80.94734337999995, 35.166820186000052],
            [-80.947913090999975, 35.166284599000051],
            [-80.948348350999936, 35.165861645000064],
            [-80.949092089999965, 35.165164685000036],
            [-80.950515687999939, 35.163827866000076],
            [-80.95142089999996, 35.162946840000075],
            [-80.951776003999953, 35.162592151000069],
            [-80.952213993999976, 35.162144699000066],
            [-80.952612989999977, 35.161730449000061],
            [-80.953112228999942, 35.161198357000046],
            [-80.953830115999949, 35.160410888000058],
            [-80.954477834999977, 35.15967762300005],
            [-80.955140233999941, 35.158904114000052],
            [-80.955644422999967, 35.158298916000035],
            [-80.956150823999963, 35.157674709000048],
            [-80.956830169999989, 35.156809707000036],
            [-80.957187961999978, 35.156339910000042],
            [-80.957383432999961, 35.156076691000067],
            [-80.957610791999969, 35.155765676000044],
            [-80.957900416999962, 35.155363974000068],
            [-80.95859851299997, 35.15438316500007],
            [-80.959079333999966, 35.153688678000037],
            [-80.959819746999983, 35.152590278000048],
            [-80.960423183999978, 35.151745787000038],
            [-80.961630063999962, 35.150065157000029],
            [-80.961988304999977, 35.149564763000058],
            [-80.962701288999938, 35.148533274000044],
            [-80.963197851999951, 35.147814717000074],
            [-80.963596913999936, 35.147225997000078],
            [-80.963890125999967, 35.14680112700006],
            [-80.964278512999954, 35.146224691000043],
            [-80.964544631999956, 35.145819933000041],
            [-80.964916209999956, 35.145247544000028],
            [-80.965753979999988, 35.143918524000071],
            [-80.966155298999979, 35.143263756000067],
            [-80.966458147999958, 35.142755995000073],
            [-80.966721417999963, 35.142306508000047],
            [-80.967020735999938, 35.141785535000054],
            [-80.967424446999985, 35.141077047000067],
            [-80.967699394999954, 35.140572442000064],
            [-80.968110802999945, 35.139820672000042],
            [-80.96842126599995, 35.13924030700008],
            [-80.968658481999967, 35.138793949000046],
            [-80.969874432999973, 35.137099207000063],
            [-80.970064071999957, 35.136834848000035],
            [-80.970572945999947, 35.136116105000042],
            [-80.970896213999936, 35.135650607000059],
            [-80.971348250999938, 35.13498738800007],
            [-80.971647920999942, 35.13453949500007],
            [-80.972994116999985, 35.132489170000042],
            [-80.97514817299998, 35.129151212000068],
            [-80.975351257999989, 35.128836469000078],
            [-80.975586116999978, 35.128467465000028],
            [-80.976858959999959, 35.126245487000062],
            [-80.977097293999975, 35.125908029000072],
            [-80.977329491999967, 35.125567696000076],
            [-80.977555505999987, 35.125224565000053],
            [-80.977775282999971, 35.124878711000065],
            [-80.97798877699995, 35.124530211000035],
            [-80.978195938999988, 35.12417914100007],
            [-80.978396723999936, 35.123825579000027],
            [-80.97870925899997, 35.123247501000037],
            [-80.979050798999936, 35.122585505000075],
            [-80.979271376999975, 35.12215210200003],
            [-80.979476409999961, 35.12166116800006],
            [-80.979732260999981, 35.121031953000056],
            [-80.979825110999968, 35.120883125000034],
            [-80.979910905999986, 35.120731466000052],
            [-80.979989518999957, 35.12057720100006],
            [-80.980060832999982, 35.120420560000071],
            [-80.980124741999987, 35.120261775000074],
            [-80.980181149999964, 35.120101084000055],
            [-80.98022997399994, 35.119938725000054],
            [-80.980271140999946, 35.11977494000007],
            [-80.980304589999946, 35.119609973000024],
            [-80.980781662999959, 35.118909956000039],
            [-80.980976586999986, 35.118457895000063],
            [-80.981268985999975, 35.117760206000071],
            [-80.981565746999934, 35.117005576000054],
            [-80.981697937999968, 35.116654082000025],
            [-80.98185728499999, 35.116217793000033],
            [-80.982075079999959, 35.115607244000046],
            [-80.982246867999947, 35.115104619000078],
            [-80.982555160999937, 35.114126695000039],
            [-80.982735994999985, 35.113527114000078],
            [-80.982969208999975, 35.112720614000068],
            [-80.983159433999958, 35.112039241000048],
            [-80.983388144999935, 35.111160300000051],
            [-80.983572939999988, 35.110433003000026],
            [-80.983670361999941, 35.110032243000035],
            [-80.983808171999954, 35.10943694100007],
            [-80.983891195999945, 35.109061113000053],
            [-80.983992791999981, 35.10857238400007],
            [-80.98411779199995, 35.107932450000078],
            [-80.984239891999948, 35.10723858700004],
            [-80.98482140599998, 35.106356467000069],
            [-80.985248077999984, 35.10578892500007],
            [-80.986333427999966, 35.104346934000034],
            [-80.98709377299997, 35.103380339000068],
            [-80.987544014999969, 35.102786843000047],
            [-80.987829561999945, 35.102396171000066],
            [-80.988124945999971, 35.10199405000003],
            [-80.988451794999946, 35.101541788000077],
            [-80.988672017999988, 35.101232960000061],
            [-80.989019815999939, 35.100759245000063],
            [-80.989620829999978, 35.099977678000073],
            [-80.989900865999971, 35.099610200000029],
            [-80.99053539099998, 35.098763826000038],
            [-80.990838246999942, 35.098352565000027],
            [-80.991155627999944, 35.097914882000055],
            [-80.991493362999961, 35.097457791000068],
            [-80.991711932999976, 35.097158797000077],
            [-80.99240492399997, 35.096223451000071],
            [-80.993248894999965, 35.095075431000055],
            [-80.993966910999973, 35.094105217000049],
            [-80.994569472999956, 35.093307648000064],
            [-80.994938574999935, 35.092811773000051],
            [-80.995384108999986, 35.092200360000049],
            [-80.995721295999942, 35.091758700000071],
            [-80.995927569999935, 35.091486304000057],
            [-80.996195532999934, 35.091142969000032],
            [-80.996616149999966, 35.090594650000071],
            [-80.997022638999965, 35.090047005000031],
            [-80.997415115999956, 35.089500261000069],
            [-80.99761097999999, 35.089222557000028],
            [-80.997883668999975, 35.08884305600003],
            [-80.998382842999945, 35.088165298000035],
            [-80.998655097999972, 35.08781282800004],
            [-80.998976277999986, 35.087390868000057],
            [-80.999178907999976, 35.087120352000056],
            [-81.000189872999954, 35.085748737000074],
            [-81.000716960999966, 35.085013415000049],
            [-81.001081479999982, 35.084490610000046],
            [-81.00167150499999, 35.083633017000068],
            [-81.002078241999982, 35.083026724000035],
            [-81.002381620999984, 35.082559759000048],
            [-81.002734227999952, 35.081999161000056],
            [-81.003341155999976, 35.081008510000061],
            [-81.003801644999953, 35.080231815000047],
            [-81.004283520999934, 35.079392374000065],
            [-81.004711713999939, 35.078609821000043],
            [-81.005310598999984, 35.077456509000058],
            [-81.005643154999973, 35.076785531000041],
            [-81.006033416999969, 35.075958563000029],
            [-81.006433625999989, 35.075076686000045],
            [-81.006800603999977, 35.074230647000036],
            [-81.007157117999952, 35.073384068000053],
            [-81.007363672999986, 35.072879210000053],
            [-81.007717887999945, 35.071986909000032],
            [-81.007999599999948, 35.071259766000026],
            [-81.008252611999978, 35.070589211000026],
            [-81.008639184999936, 35.069505505000052],
            [-81.009008840999968, 35.06835323200005],
            [-81.009357283999975, 35.067132387000072],
            [-81.009655652999982, 35.065937213000041],
            [-81.009968349999951, 35.064471658000059],
            [-81.010235521999959, 35.063184626000066],
            [-81.010388140999964, 35.062343247000058],
            [-81.010518044999969, 35.061506397000073],
            [-81.010605819999967, 35.060881957000049],
            [-81.010716622999951, 35.060200270000053],
            [-81.010845038999946, 35.059330931000034],
            [-81.011190064999937, 35.056709857000044],
            [-81.011378459999946, 35.055299146000038],
            [-81.011917813999958, 35.051227185000073],
            [-81.012408321999942, 35.047681858000033],
            [-81.012638282999944, 35.047333599000069],
            [-81.012882898999976, 35.046953317000032],
            [-81.013281338999946, 35.046316741000055],
            [-81.01354205399997, 35.045889892000048],
            [-81.014059862999943, 35.045039607000035],
            [-81.014545906999956, 35.044213692000028],
            [-81.014881194999987, 35.043622074000041],
            [-81.015077365999957, 35.043284780000079],
            [-81.015341457999966, 35.042827952000039],
            [-81.015781235999953, 35.042025763000026],
            [-81.016379712999935, 35.04087237400006],
            [-81.016840292999973, 35.039923613000042],
            [-81.017252257999985, 35.039009488000033],
            [-81.017579082999987, 35.038248297000052],
            [-81.017807189999985, 35.037698105000061],
            [-81.017976622999981, 35.037274925000077],
            [-81.018125217999966, 35.036892366000075],
            [-81.01833054399998, 35.036347953000075],
            [-81.018675755999936, 35.035399974000029],
            [-81.019023507999975, 35.034407414000043],
            [-81.019204678999984, 35.033870012000079],
            [-81.019460319999951, 35.033046278000029],
            [-81.01980846899994, 35.031825380000043],
            [-81.019988816999955, 35.031144174000076],
            [-81.020151146999979, 35.030478790000075],
            [-81.020327915999985, 35.029711033000069],
            [-81.020467674999963, 35.029064135000056],
            [-81.02073788499996, 35.027754414000071],
            [-81.020989337999936, 35.026460919000044],
            [-81.021171291999963, 35.025556847000075],
            [-81.021420629999966, 35.024371557000052],
            [-81.021670761999985, 35.023160145000077],
            [-81.021828664999987, 35.022434642000064],
            [-81.021970016999944, 35.021745808000048],
            [-81.022188187999973, 35.020652881000046],
            [-81.022281988999964, 35.020230522000077],
            [-81.02245508499999, 35.019395040000063],
            [-81.024180141999977, 35.016826473000037],
            [-81.024684744999945, 35.016078958000037],
            [-81.025515266999946, 35.014871822000032],
            [-81.025989657999958, 35.014169099000071],
            [-81.026544083999966, 35.013336005000042],
            [-81.026906513999961, 35.012805180000043],
            [-81.027495525999939, 35.011941829000079],
            [-81.027976525999975, 35.011242852000066],
            [-81.028272804999972, 35.010811113000045],
            [-81.02908692799997, 35.009636013000033],
            [-81.02949979999994, 35.009017736000033],
            [-81.029739688999939, 35.008645100000024],
            [-81.030144078999967, 35.008030864000034],
            [-81.03045653099997, 35.007565671000066],
            [-81.030834701999936, 35.007028795000053],
            [-81.031328145999964, 35.006322855000064],
            [-81.03173008999994, 35.005734489000076],
            [-81.031971574999943, 35.005376269000067],
            [-81.03269077899995, 35.004334305000043],
            [-81.033907092999982, 35.002496154000028],
            [-81.034373700999936, 35.001759985000035],
            [-81.035021582999946, 35.000689794000039],
            [-81.035336584999982, 35.00015103100003],
            [-81.035834941999951, 34.999267696000061],
            [-81.036183764999976, 34.998623721000058],
            [-81.036781659999974, 34.99747020500007],
            [-81.037324492999971, 34.996335748000035],
            [-81.037855004999983, 34.995123037000042],
            [-81.038234117999934, 34.994194010000058],
            [-81.038571761999947, 34.993312591000063],
            [-81.038745480999978, 34.992838948000042],
            [-81.039079776999984, 34.991888544000062],
            [-81.039424893999978, 34.990803357000061],
            [-81.039772583999934, 34.989582374000065],
            [-81.03998390199996, 34.988770900000077],
            [-81.040100934999941, 34.988273080000056],
            [-81.040287832999979, 34.987434217000043],
            [-81.040501111999959, 34.986379535000026],
            [-81.040625899999952, 34.985686484000041],
            [-81.040734522999969, 34.985001329000056],
            [-81.040857364999965, 34.984146238000051],
            [-81.040967748999947, 34.983274163000033],
            [-81.041058662999944, 34.982351419000054],
            [-81.04114194999994, 34.981203347000076],
            [-81.041174373999979, 34.980628852000052],
            [-81.041200972999945, 34.979965992000075],
            [-81.041216784999961, 34.979200415000037],
            [-81.041216177999956, 34.977946345000078],
            [-81.041198872999985, 34.977167820000034],
            [-81.041181895999955, 34.976789146000044],
            [-81.041129318999936, 34.975780775000032],
            [-81.041098090999981, 34.975305932000026],
            [-81.041023033999977, 34.974356919000058],
            [-81.040902180999979, 34.973258858000065],
            [-81.040726327999948, 34.972013167000057],
            [-81.040593678999983, 34.971203820000028],
            [-81.04045076999995, 34.970429363000051],
            [-81.040320115999975, 34.969766943000025],
            [-81.040078973999982, 34.968654488000027],
            [-81.039799328999948, 34.967535259000044],
            [-81.039475600999936, 34.96639250100003],
            [-81.039234264999948, 34.965602465000075],
            [-81.039079759999936, 34.965116967000029],
            [-81.03884015999995, 34.964409342000067],
            [-81.038406600999963, 34.963207125000054],
            [-81.03811049799998, 34.962438534000057],
            [-81.037935964999974, 34.962014174000046],
            [-81.037685368999973, 34.961415337000062],
            [-81.037337959999945, 34.960590830000058],
            [-81.036630387999935, 34.958973537000077],
            [-81.036300331999939, 34.958191365000062],
            [-81.035884750999969, 34.957235329000071],
            [-81.035618435999936, 34.95660719600005],
            [-81.034974431999956, 34.955104721000055],
            [-81.034208757999977, 34.953274614000065],
            [-81.033906993999949, 34.952573335000068],
            [-81.033672611999975, 34.952046387000053],
            [-81.033411992999959, 34.951471430000026],
            [-81.033253017999982, 34.951118658000041],
            [-81.033029477999946, 34.950634235000052],
            [-81.032812863999936, 34.95017408700005],
            [-81.032660151999949, 34.949843034000025],
            [-81.032343071999946, 34.949115465000034],
            [-81.032093803999942, 34.948556232000044],
            [-81.031888785999968, 34.948062857000025],
            [-81.031514352999977, 34.947148899000069],
            [-81.03120557799997, 34.946421735000058],
            [-81.031000344999939, 34.945953898000027],
            [-81.030837023999936, 34.94556534000003],
            [-81.030372092999983, 34.944465511000033],
            [-81.030085087999964, 34.943808021000052],
            [-81.029426993999948, 34.942348327000047],
            [-81.029230430999974, 34.941844687000071],
            [-81.029012784999964, 34.941305598000042],
            [-81.028641388999972, 34.940428048000058],
            [-81.028085759999954, 34.939169363000076],
            [-81.027728885999977, 34.93834398100006],
            [-81.027465391999954, 34.937586329000055],
            [-81.027824215999942, 34.936861879000048],
            [-81.028282037999986, 34.93597352300003],
            [-81.028513262999979, 34.935511614000063],
            [-81.028834408999955, 34.934912669000028],
            [-81.02954815999999, 34.933528958000068],
            [-81.02985618799994, 34.932952641000043],
            [-81.030317183999955, 34.932074813000042],
            [-81.030520960999979, 34.931675319000078],
            [-81.03087845999994, 34.930998665000061],
            [-81.031373871999961, 34.930022201000043],
            [-81.031820324999956, 34.929075542000078],
            [-81.032350497999971, 34.927862808000043],
            [-81.032824760999972, 34.926671080000062],
            [-81.033257114999969, 34.925468590000037],
            [-81.033647201999941, 34.924256339000067],
            [-81.033994702999962, 34.923035326000047],
            [-81.034295679999957, 34.921823724000035],
            [-81.03456082799994, 34.920571083000027],
            [-81.034778980999988, 34.919329927000035],
            [-81.034953615999939, 34.918084092000072],
            [-81.035084587999961, 34.916834625000035],
            [-81.035171786999967, 34.915582571000073],
            [-81.035215143999949, 34.914328966000028],
            [-81.035214624999981, 34.913074850000044],
            [-81.035170232999974, 34.91182126800004],
            [-81.035082005999982, 34.910569259000056],
            [-81.034950016999971, 34.909319860000039],
            [-81.034774381999966, 34.908074117000069],
            [-81.034555250999972, 34.90683307300003],
            [-81.034289136999973, 34.905580568000062],
            [-81.033987248999949, 34.904369117000044],
            [-81.03363885999994, 34.90314827900005],
            [-81.033247921999987, 34.901936222000074],
            [-81.032814760999941, 34.900733943000034],
            [-81.032339735999983, 34.899542444000076],
            [-81.031808833999946, 34.898329961000059],
            [-81.031265720999954, 34.89719573900004],
            [-81.030667623999989, 34.896042479000073],
            [-81.030029454999976, 34.894903893000048],
            [-81.029351743999939, 34.893780931000038],
            [-81.028635057999963, 34.892674523000039],
            [-81.027848392999942, 34.891540270000064],
            [-81.027087169999959, 34.89051502500007],
            [-81.026257255999951, 34.889463727000077],
            [-81.025390942999934, 34.888432570000077],
            [-81.024488941999948, 34.887422400000048],
            [-81.023552002999963, 34.886434055000052],
            [-81.022526752999966, 34.885414857000058],
            [-81.02157648299999, 34.884526136000034],
            [-81.020539535999944, 34.883608135000031],
            [-81.019470944999966, 34.882715138000037],
            [-81.018371584999954, 34.881847871000048],
            [-81.017242367999984, 34.88100705800008],
            [-81.016003532999946, 34.880137155000057],
            [-81.014898175999974, 34.879407580000077],
            [-81.013685134999946, 34.878650236000055],
            [-81.012446112999953, 34.877921988000026],
            [-81.011182180999981, 34.877223467000078],
            [-81.009894391999978, 34.876555253000049],
            [-81.008474220999972, 34.875865181000052],
            [-81.007251406999956, 34.875311866000061],
            [-81.005898437999974, 34.874737744000072],
            [-81.004525970999964, 34.874195976000067],
            [-81.003135147999956, 34.873687014000041],
            [-81.001727112999959, 34.873211277000053],
            [-81.000164296999969, 34.872726818000046],
            [-80.998864160999972, 34.872361048000073],
            [-80.997411583999963, 34.871987251000064],
            [-80.99594652199994, 34.871648084000071],
            [-80.994470251999985, 34.871343845000069],
            [-80.992983982999988, 34.871074779000026],
            [-80.991322572999934, 34.870816013000024],
            [-80.989643741999942, 34.870608040000036],
            [-80.988132105999966, 34.870461946000034],
            [-80.987537127999985, 34.870409738000035],
            [-80.986442269999941, 34.870399961000032],
            [-80.985434830999964, 34.87036773300008],
            [-80.984530559999939, 34.870314407000023],
            [-80.98362844899998, 34.870270251000079],
            [-80.982401204999974, 34.870218668000064],
            [-80.981855059999987, 34.870199219000028],
            [-80.981352773999959, 34.870199636000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 151,
        "SolutionID": "PI-30:C",
        "Shape_Length": 2.3001769675427952,
        "Shape_Area": 0.11202781108794944
      }
    },
    {
      "type": "Feature",
      "id": 152,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.981352773999959, 34.870199636000052],
            [-80.980594408999934, 34.870235815000058],
            [-80.979367160999971, 34.870305354000038],
            [-80.978465792999941, 34.870362659000079],
            [-80.977562301999967, 34.870429175000027],
            [-80.977053111999965, 34.87045385600004],
            [-80.976352650999956, 34.870547681000062],
            [-80.974530791999939, 34.870643324000071],
            [-80.972819634999951, 34.870869817000028],
            [-80.97153293599996, 34.871074236000027],
            [-80.970046537999963, 34.871342885000047],
            [-80.968570118999935, 34.871646712000029],
            [-80.967104946999939, 34.871985457000051],
            [-80.965652232999958, 34.872358839000071],
            [-80.963993929999958, 34.87283044600008],
            [-80.962788907999936, 34.873208262000048],
            [-80.96138068199997, 34.873683598000071],
            [-80.959989623999945, 34.874192175000076],
            [-80.958616933999963, 34.874733554000045],
            [-80.957263736999948, 34.87530729000008],
            [-80.955710193999948, 34.876015617000064],
            [-80.954620248999959, 34.876549921000048],
            [-80.953332158999956, 34.877217783000049],
            [-80.952067935999935, 34.877915945000041],
            [-80.950828630999979, 34.878643830000044],
            [-80.949615260999963, 34.879400840000073],
            [-80.948216426999977, 34.880329998000036],
            [-80.947270412999956, 34.880999648000056],
            [-80.946140849999949, 34.88184013800003],
            [-80.945041119999985, 34.882707099000072],
            [-80.943972149999979, 34.883599801000059],
            [-80.942934820999938, 34.884517508000044],
            [-80.941929993999963, 34.885459458000071],
            [-80.941544186999977, 34.885829398000055],
            [-80.940958508999984, 34.886424867000073],
            [-80.940021164999962, 34.887412939000058],
            [-80.939118744999973, 34.888422852000076],
            [-80.938251997999942, 34.889453769000056],
            [-80.937421644999972, 34.89050483300008],
            [-80.936628377999966, 34.891575171000056],
            [-80.936304328999938, 34.892024435000053],
            [-80.935872849999953, 34.892663902000038],
            [-80.935155702999964, 34.893770108000069],
            [-80.934492913999975, 34.894865498000058],
            [-80.933883165999987, 34.895948450000049],
            [-80.933515743999976, 34.896630172000073],
            [-80.933257118999961, 34.89712445400005],
            [-80.933036662999939, 34.89754801600003],
            [-80.932860120999976, 34.89788855300003],
            [-80.932635523999977, 34.898320237000064],
            [-80.932260199999973, 34.899043293000034],
            [-80.931938142999968, 34.899662294000052],
            [-80.931662436999943, 34.90019691100008],
            [-80.931514299999947, 34.900480383000058],
            [-80.931382024999948, 34.900736166000058],
            [-80.931178597999974, 34.901126165000051],
            [-80.930759710999951, 34.901938176000044],
            [-80.930294301999936, 34.902878499000053],
            [-80.930017452999948, 34.90344895700008],
            [-80.929590565999945, 34.904274379000071],
            [-80.929054753999935, 34.905308121000076],
            [-80.928697249999971, 34.905992618000028],
            [-80.928253592999965, 34.906854917000032],
            [-80.92796696299996, 34.907405806000043],
            [-80.927521462999948, 34.908252115000039],
            [-80.927232190999973, 34.908811613000069],
            [-80.926993098999958, 34.909278310000047],
            [-80.926507825999977, 34.910210878000044],
            [-80.926059291999934, 34.911075652000079],
            [-80.925702222999973, 34.911762779000071],
            [-80.925242523999941, 34.912646676000065],
            [-80.924856254999952, 34.913388394000037],
            [-80.924450440999976, 34.914168532000076],
            [-80.92398473999998, 34.915065245000051],
            [-80.923447092999936, 34.916097637000064],
            [-80.92314098899999, 34.916694171000074],
            [-80.922783785999968, 34.917400345000033],
            [-80.922260603999973, 34.918485410000073],
            [-80.921876229999953, 34.919327266000039],
            [-80.921505054999955, 34.920198071000073],
            [-80.921029268999973, 34.921389380000051],
            [-80.920734100999937, 34.922177158000068],
            [-80.920519271999979, 34.922791025000038],
            [-80.920386701999973, 34.923181854000063],
            [-80.920256293999955, 34.923582074000024],
            [-80.92000474799994, 34.924384501000077],
            [-80.919843484999944, 34.924925639000037],
            [-80.919661700999939, 34.925583367000058],
            [-80.919403944999942, 34.926584303000027],
            [-80.919241641999974, 34.927264711000078],
            [-80.919124014999966, 34.92779762400005],
            [-80.919009215999949, 34.928356093000048],
            [-80.918889771999943, 34.928994763000048],
            [-80.918725182999935, 34.929966361000027],
            [-80.918632729999956, 34.930573540000069],
            [-80.918569329999968, 34.931036023000047],
            [-80.918499721999979, 34.931613635000076],
            [-80.918377081999949, 34.932790858000033],
            [-80.918314296999938, 34.933548552000047],
            [-80.918288759999939, 34.933969601000058],
            [-80.918249954999965, 34.934762127000056],
            [-80.918216596999969, 34.93590795700004],
            [-80.918211563999989, 34.936526364000031],
            [-80.918227774999934, 34.937485930000037],
            [-80.918250343999944, 34.938138510000044],
            [-80.918288872999938, 34.938905911000063],
            [-80.918324278999989, 34.939443378000078],
            [-80.918387516999985, 34.940176504000078],
            [-80.918498104999969, 34.941225271000064],
            [-80.918561101999956, 34.941749093000055],
            [-80.918615808999959, 34.942153305000033],
            [-80.918714138999974, 34.942799390000062],
            [-80.918897804999972, 34.94387649500004],
            [-80.918997815999944, 34.944414106000067],
            [-80.919109456999934, 34.94496403200003],
            [-80.91928601099994, 34.945748485000024],
            [-80.919597129999943, 34.947002123000061],
            [-80.919770232999952, 34.947649223000042],
            [-80.919867370999953, 34.947986813000057],
            [-80.920076006999977, 34.948686340000052],
            [-80.920251261999965, 34.949238394000076],
            [-80.920520453999984, 34.950044794000064],
            [-80.920786876999955, 34.950794471000052],
            [-80.92102050699998, 34.95141505600003],
            [-80.921194975999981, 34.951863307000053],
            [-80.92138276899999, 34.952333098000054],
            [-80.92150535899998, 34.952631730000064],
            [-80.921778011999947, 34.953274135000072],
            [-80.922033302999978, 34.953864254000052],
            [-80.922388385999966, 34.954691646000072],
            [-80.922672409999961, 34.955357236000054],
            [-80.922990439999978, 34.956094636000046],
            [-80.923231093999959, 34.956645913000045],
            [-80.923423876999948, 34.957100909000076],
            [-80.923553211999945, 34.957399046000035],
            [-80.923705148999943, 34.957756346000053],
            [-80.923902229999953, 34.958209941000064],
            [-80.924228640999956, 34.958977253000057],
            [-80.924491972999988, 34.959585812000057],
            [-80.92473954999997, 34.960152657000037],
            [-80.924985087999971, 34.960724126000059],
            [-80.925169035999943, 34.96114863400004],
            [-80.925358021999955, 34.961586338000075],
            [-80.925613268999939, 34.962180400000079],
            [-80.926031087999945, 34.963149774000044],
            [-80.926334831999952, 34.963849768000046],
            [-80.92665696399996, 34.964608483000063],
            [-80.926884403999964, 34.96514387700006],
            [-80.927129358999935, 34.965701050000064],
            [-80.927405796999949, 34.96634748200006],
            [-80.92774478299998, 34.96714100500003],
            [-80.928082323999945, 34.96793120600006],
            [-80.928339813999969, 34.968529039000032],
            [-80.928547699999967, 34.969012176000035],
            [-80.928966564999939, 34.969993392000049],
            [-80.929198434999989, 34.970527663000041],
            [-80.929594904999988, 34.971449482000025],
            [-80.929795934999959, 34.971925296000052],
            [-80.93008733299996, 34.972614667000073],
            [-80.930383595999956, 34.97329312200003],
            [-80.930731121999941, 34.974117534000072],
            [-80.931133428999942, 34.975165154000024],
            [-80.930639901999939, 34.975823310000067],
            [-80.930326964999949, 34.976287537000076],
            [-80.930038757999966, 34.976711247000026],
            [-80.929434653999976, 34.977598680000028],
            [-80.929144373999975, 34.978031122000061],
            [-80.928807867999978, 34.978517046000036],
            [-80.928511354999955, 34.978950184000041],
            [-80.927960623999979, 34.979769081000029],
            [-80.927709689999972, 34.980146194000042],
            [-80.92713753999999, 34.98099611400005],
            [-80.926855972999988, 34.981425665000074],
            [-80.926325590999966, 34.982471942000075],
            [-80.925981432999947, 34.982942598000079],
            [-80.925655432999974, 34.983409154000071],
            [-80.925162674999967, 34.984243520000064],
            [-80.924607975999947, 34.985191687000054],
            [-80.924404488999983, 34.98555977500007],
            [-80.924208345999944, 34.985930554000049],
            [-80.924019600999941, 34.986303925000072],
            [-80.923838302999968, 34.98667978800006],
            [-80.923664500999962, 34.987058042000058],
            [-80.923498242999983, 34.987438585000064],
            [-80.923339572999964, 34.987821317000055],
            [-80.923188532999973, 34.988206134000052],
            [-80.922677764999946, 34.988991798000029],
            [-80.922489266999946, 34.989407205000077],
            [-80.92224534099995, 34.989955331000033],
            [-80.922080523999966, 34.990334450000034],
            [-80.921824636999986, 34.990942071000063],
            [-80.921520278999935, 34.991690110000036],
            [-80.921295864999934, 34.992260632000068],
            [-80.92115780599994, 34.992625372000077],
            [-80.920954248999976, 34.99316458900006],
            [-80.920739527999956, 34.993758283000034],
            [-80.920576807999964, 34.994223247000036],
            [-80.920346638999945, 34.994902737000075],
            [-80.920057512999961, 34.995801632000052],
            [-80.91983171399994, 34.996543493000047],
            [-80.919692063999946, 34.997016554000027],
            [-80.919586849999973, 34.997385188000067],
            [-80.919401289999939, 34.998069961000056],
            [-80.919222458999968, 34.99876254600008],
            [-80.919069228999945, 34.999387780000063],
            [-80.918903143999955, 35.000104423000039],
            [-80.918680105999954, 35.001126957000054],
            [-80.918566599999963, 35.001676992000057],
            [-80.918400151999947, 35.002523384000028],
            [-80.918262004999974, 35.003200975000027],
            [-80.918181446999938, 35.003600548000065],
            [-80.918018871999948, 35.004408010000077],
            [-80.91782152199994, 35.005361009000069],
            [-80.917613674999984, 35.006392078000033],
            [-80.917506508999963, 35.006909386000075],
            [-80.917402325999944, 35.007429349000063],
            [-80.917291449999937, 35.007966857000042],
            [-80.91715643799995, 35.008620551000035],
            [-80.91707650099994, 35.009016342000052],
            [-80.916974024999945, 35.009373958000026],
            [-80.916631535999954, 35.009879193000074],
            [-80.916301351999948, 35.010366306000037],
            [-80.915679956999952, 35.01131128600008],
            [-80.915376220999974, 35.011787130000073],
            [-80.914931374999981, 35.012506475000066],
            [-80.914665272999969, 35.012946294000074],
            [-80.91437024399994, 35.013447867000025],
            [-80.913952001999974, 35.014179003000038],
            [-80.913445820999982, 35.015099685000052],
            [-80.913159726999936, 35.015641499000026],
            [-80.912826719999941, 35.016293904000065],
            [-80.912545398999953, 35.01685924800006],
            [-80.912223005999977, 35.017527410000071],
            [-80.911839933999943, 35.018353149000063],
            [-80.911662249999949, 35.018749787000047],
            [-80.911302576999958, 35.019582771000046],
            [-80.91087952099997, 35.020611919000032],
            [-80.910544254999934, 35.021471443000053],
            [-80.910265826999989, 35.022220033000053],
            [-80.909990518999962, 35.023006420000058],
            [-80.909643181999968, 35.024053544000026],
            [-80.909347594999986, 35.025000937000073],
            [-80.909150936999936, 35.025681533000068],
            [-80.908950230999949, 35.026434433000077],
            [-80.908705877999978, 35.027408180000066],
            [-80.908540181999967, 35.028108616000054],
            [-80.90838410799995, 35.028808131000062],
            [-80.908268623999959, 35.02936690100006],
            [-80.908084154999983, 35.030345767000028],
            [-80.907956666999951, 35.031093742000053],
            [-80.907871850999982, 35.031631148000031],
            [-80.907737059999988, 35.03254706000007],
            [-80.907619015999956, 35.033404683000072],
            [-80.907521637999935, 35.034122045000061],
            [-80.907382233999954, 35.03519417800004],
            [-80.907332286999974, 35.035570793000034],
            [-80.90723337299994, 35.036323117000052],
            [-80.907147926999983, 35.03704216400007],
            [-80.907070558999976, 35.03759941800007],
            [-80.906978244999948, 35.038260705000027],
            [-80.90689297199998, 35.038832391000028],
            [-80.906827045999989, 35.039303867000058],
            [-80.906649930999947, 35.040666679000026],
            [-80.906522393999978, 35.041607608000049],
            [-80.906306088999941, 35.043217367000068],
            [-80.906232726999974, 35.043796593000025],
            [-80.906170094999936, 35.044231448000062],
            [-80.906036341999936, 35.045215645000042],
            [-80.905315738999946, 35.046149867000054],
            [-80.904856094999957, 35.046759683000062],
            [-80.903761956999972, 35.048246255000038],
            [-80.903378773999975, 35.048767303000034],
            [-80.901839088999964, 35.050835457000062],
            [-80.900325136999982, 35.052868923000062],
            [-80.899275694999972, 35.054255586000068],
            [-80.898905622999962, 35.054744562000053],
            [-80.898341092999942, 35.05549374900005],
            [-80.89762196099997, 35.056468944000073],
            [-80.897075757999971, 35.057211926000036],
            [-80.896739943999989, 35.057649878000063],
            [-80.896322322999936, 35.058199135000052],
            [-80.89578628299995, 35.058922191000079],
            [-80.895320846999937, 35.059524613000065],
            [-80.894644586999959, 35.06041739300008],
            [-80.894325354999978, 35.060846339000079],
            [-80.893298631999983, 35.062249447000056],
            [-80.892063503999964, 35.063857178000035],
            [-80.89100956599998, 35.065264009000032],
            [-80.889827471999979, 35.066872989000046],
            [-80.889222759999939, 35.06768407800007],
            [-80.888667040999962, 35.068439874000035],
            [-80.888111820999939, 35.069217045000073],
            [-80.887545087999968, 35.070034784000029],
            [-80.887194120999936, 35.070558629000061],
            [-80.886509817999979, 35.071614050000051],
            [-80.885998229999984, 35.072445479000066],
            [-80.885561008999957, 35.073198447000038],
            [-80.885288512999978, 35.07368187700007],
            [-80.885002258999975, 35.07419881800007],
            [-80.884646414999963, 35.074858445000075],
            [-80.884286517999954, 35.075564385000064],
            [-80.883789906999937, 35.076588646000062],
            [-80.883549495999944, 35.077103350000073],
            [-80.88336557599996, 35.077513586000066],
            [-80.883236991999979, 35.077805814000044],
            [-80.883093164999934, 35.078140251000036],
            [-80.882950585999936, 35.078477376000023],
            [-80.882763359999956, 35.078937490000044],
            [-80.882401422999976, 35.079861054000048],
            [-80.882147079999982, 35.080548775000068],
            [-80.881952405999982, 35.081110471000045],
            [-80.881661215999941, 35.081986879000056],
            [-80.881363309999983, 35.082939506000059],
            [-80.881180756999981, 35.083566273000031],
            [-80.88101484699996, 35.084178924000071],
            [-80.880791614999964, 35.085047862000067],
            [-80.880563668999969, 35.085982225000066],
            [-80.880396215999951, 35.086713638000049],
            [-80.880292201999964, 35.087212813000065],
            [-80.88012133899997, 35.088107042000047],
            [-80.880025655999987, 35.088654201000054],
            [-80.879830052999978, 35.089724897000053],
            [-80.879648040999939, 35.09081438000004],
            [-80.879072411999971, 35.091767796000056],
            [-80.878323677999958, 35.092861615000061],
            [-80.877877112999954, 35.09347032200003],
            [-80.877542571999982, 35.093926361000058],
            [-80.877010179999957, 35.094651947000045],
            [-80.87647553499994, 35.095366618000071],
            [-80.875836380999942, 35.096248479000053],
            [-80.875324872999954, 35.096997659000067],
            [-80.87460521099996, 35.098103391000052],
            [-80.874151732999962, 35.098832819000052],
            [-80.873840555999948, 35.099357123000061],
            [-80.873421839999935, 35.100067248000073],
            [-80.872845609999956, 35.101085028000057],
            [-80.872451524999974, 35.101808697000024],
            [-80.872255428999949, 35.102166360000069],
            [-80.872054504999937, 35.102535463000038],
            [-80.87184879199998, 35.102911520000077],
            [-80.871507225999949, 35.10355136700008],
            [-80.871242093999967, 35.103970600000025],
            [-80.870878865999941, 35.104510657000048],
            [-80.870147876999965, 35.105636335000042],
            [-80.869676538999954, 35.106319244000076],
            [-80.869417772999952, 35.106686525000043],
            [-80.868860377999965, 35.107490502000076],
            [-80.868396659999974, 35.108179133000078],
            [-80.868170006999947, 35.108514887000069],
            [-80.86720584599999, 35.109478153000055],
            [-80.865950716999976, 35.110640373000024],
            [-80.865090129999942, 35.111441494000076],
            [-80.864321056999984, 35.112148381000054],
            [-80.863919733999978, 35.112522718000037],
            [-80.863432147999958, 35.112984095000058],
            [-80.863132878999977, 35.113262832000032],
            [-80.862679327999956, 35.113672060000056],
            [-80.862156148999986, 35.114149518000033],
            [-80.861481322999964, 35.11477996800005],
            [-80.861073215999966, 35.115151788000048],
            [-80.860631806999947, 35.115552340000079],
            [-80.860086739999986, 35.116055842000037],
            [-80.859461710999938, 35.116643174000046],
            [-80.858941303999984, 35.117143273000067],
            [-80.858489296999949, 35.117553673000032],
            [-80.857990787999938, 35.118014154000036],
            [-80.857121456999948, 35.118832242000053],
            [-80.85648333499995, 35.119436567000037],
            [-80.855975635999982, 35.119916302000036],
            [-80.855037548999974, 35.120800744000064],
            [-80.854389836999985, 35.121451800000045],
            [-80.853554033999956, 35.122322573000076],
            [-80.852921905999949, 35.12300547500007],
            [-80.852617270999986, 35.123342895000064],
            [-80.851988848999952, 35.124057958000037],
            [-80.851303211999948, 35.124865740000075],
            [-80.851003184999968, 35.125229268000055],
            [-80.850547748999986, 35.125794337000059],
            [-80.850128583999947, 35.126330100000075],
            [-80.849607749999961, 35.127015785000026],
            [-80.849351215999945, 35.127360600000031],
            [-80.849123003999978, 35.127674811000077],
            [-80.848771485999976, 35.128167182000027],
            [-80.848519440999951, 35.128530542000078],
            [-80.848132174999989, 35.129103344000043],
            [-80.847792830999936, 35.129619889000026],
            [-80.84727892799998, 35.130428707000078],
            [-80.846946558999946, 35.130972395000072],
            [-80.846624060999943, 35.13152040500006],
            [-80.846215540999935, 35.132235173000026],
            [-80.845734902999936, 35.133109018000027],
            [-80.845447724999985, 35.133653308000078],
            [-80.845202602999962, 35.134137113000065],
            [-80.844855597999981, 35.134843077000028],
            [-80.844323758999963, 35.135979399000064],
            [-80.84409371199996, 35.136501890000034],
            [-80.843784526999968, 35.137239557000044],
            [-80.843495503999975, 35.137959223000053],
            [-80.843207043999939, 35.138710150000065],
            [-80.842963357999963, 35.139385825000033],
            [-80.842708597999945, 35.140136635000033],
            [-80.842552190999982, 35.140617801000076],
            [-80.842416535999973, 35.141030985000043],
            [-80.842286920999982, 35.141385124000067],
            [-80.842131219999942, 35.141814478000072],
            [-80.841946284999949, 35.142335819000039],
            [-80.841672675999973, 35.143077125000048],
            [-80.841525004999937, 35.143488953000031],
            [-80.841238100999988, 35.144308735000038],
            [-80.841081948999943, 35.144767146000049],
            [-80.840858266999987, 35.145432956000036],
            [-80.840596888999983, 35.146168388000035],
            [-80.840241463999973, 35.147189336000054],
            [-80.840007068999967, 35.147852913000065],
            [-80.839708500999961, 35.148738939000054],
            [-80.83947995699998, 35.149401675000036],
            [-80.839313893999986, 35.149864879000063],
            [-80.839117293999948, 35.150425860000041],
            [-80.838858157999937, 35.151181382000061],
            [-80.838620795999987, 35.151908215000049],
            [-80.838316655999961, 35.152897470000028],
            [-80.837594828999954, 35.155406477000042],
            [-80.837405955999941, 35.156035017000079],
            [-80.837201289999939, 35.156706177000046],
            [-80.83704559499995, 35.157247344000041],
            [-80.836578021999969, 35.15885193400004],
            [-80.836452512999983, 35.159295696000072],
            [-80.836197843999969, 35.160223590000044],
            [-80.83585956099995, 35.161382644000071],
            [-80.835520364999979, 35.162560279000047],
            [-80.835281168999984, 35.163385590000075],
            [-80.835056527999939, 35.164161573000058],
            [-80.834884909999971, 35.164750360000028],
            [-80.834681478999983, 35.165462309000077],
            [-80.834501580999984, 35.166119923000053],
            [-80.834323476999941, 35.166810618000056],
            [-80.834213357999943, 35.167263196000079],
            [-80.834088262999956, 35.167804229000069],
            [-80.834016735999967, 35.168126491000066],
            [-80.833931121999967, 35.168534854000029],
            [-80.833821139999941, 35.169087855000043],
            [-80.833733522999978, 35.169557728000029],
            [-80.833704354999952, 35.170517227000062],
            [-80.833633364999969, 35.170772549000048],
            [-80.833569762999957, 35.171029181000051],
            [-80.833513584999935, 35.171286979000058],
            [-80.833464861999971, 35.171545797000078],
            [-80.833423622999987, 35.171805491000043],
            [-80.833389888999989, 35.172065914000029],
            [-80.83336368099998, 35.172326921000035],
            [-80.83331941299997, 35.172752870000068],
            [-80.833253204999949, 35.173303646000079],
            [-80.833202451999966, 35.17372262300006],
            [-80.833157171999972, 35.174193476000028],
            [-80.83313018399997, 35.174747847000049],
            [-80.83310832799998, 35.175511072000063],
            [-80.833118960999968, 35.176275377000024],
            [-80.83314844299997, 35.17650709600008],
            [-80.833170540999959, 35.176739369000074],
            [-80.833185237999942, 35.176972037000041],
            [-80.833192524999959, 35.17720494100007],
            [-80.833192396999948, 35.177437921000035],
            [-80.833184852999977, 35.17767081900007],
            [-80.833169898999984, 35.17790347600004],
            [-80.833147544999974, 35.178135733000033],
            [-80.83319206699997, 35.178570594000064],
            [-80.833258314999966, 35.179380627000057],
            [-80.833155301999966, 35.179998229000034],
            [-80.833194814999956, 35.180524324000032],
            [-80.83323163099999, 35.180949551000026],
            [-80.833281672999988, 35.181465026000069],
            [-80.833287621999943, 35.181884800000034],
            [-80.832805174999976, 35.182891206000079],
            [-80.832342421999954, 35.183478341000068],
            [-80.831826847999935, 35.184136760000058],
            [-80.831029503999957, 35.185206246000064],
            [-80.830556162999983, 35.185867335000069],
            [-80.830202306999979, 35.186382437000077],
            [-80.829871969999942, 35.186873920000039],
            [-80.829443608999952, 35.187526638000065],
            [-80.828940855999974, 35.188317780000034],
            [-80.82864228699998, 35.188804283000024],
            [-80.828389376999951, 35.18923056400007],
            [-80.828056792999973, 35.189804000000038],
            [-80.82759055799994, 35.190631575000054],
            [-80.827309475999982, 35.191147839000052],
            [-80.826981990999968, 35.191758352000079],
            [-80.826641093999967, 35.192401687000029],
            [-80.826345365999941, 35.19296212900008],
            [-80.826060705999964, 35.193488509000076],
            [-80.82579852799995, 35.193976431000067],
            [-80.825365857999941, 35.194798185000025],
            [-80.825042986999961, 35.195407714000055],
            [-80.824581942999941, 35.196263411000075],
            [-80.824045208999962, 35.19670942700003],
            [-80.823706111999968, 35.196996564000074],
            [-80.823151049999979, 35.197480247000044],
            [-80.822311002999982, 35.198237973000062],
            [-80.821865917999958, 35.198645704000057],
            [-80.821388849999948, 35.199094152000043],
            [-80.820932429999971, 35.199535528000069],
            [-80.820361808999962, 35.200110003000077],
            [-80.819527156999982, 35.200981322000075],
            [-80.819061115999943, 35.201475043000073],
            [-80.818700554999964, 35.201866684000038],
            [-80.818076877999943, 35.20257312800004],
            [-80.81720545099995, 35.203603017000034],
            [-80.816600104999964, 35.20435090400008],
            [-80.816158607999967, 35.204923131000044],
            [-80.815769426999964, 35.205440008000039],
            [-80.815303467999968, 35.206075198000065],
            [-80.814911471999949, 35.206623662000027],
            [-80.814530167999976, 35.207174468000062],
            [-80.814072931999988, 35.207857036000064],
            [-80.81372485299994, 35.208392355000058],
            [-80.813440562999972, 35.208841145000065],
            [-80.813260419999949, 35.209131257000024],
            [-80.812646064999967, 35.210089664000066],
            [-80.812057911999943, 35.21054149400004],
            [-80.811261785999989, 35.211140009000076],
            [-80.810665920999952, 35.211601873000063],
            [-80.80987866199996, 35.212236545000053],
            [-80.809448801999963, 35.212594324000065],
            [-80.808865983999965, 35.213090512000065],
            [-80.80845560399996, 35.213447573000053],
            [-80.807897434999973, 35.21395153800006],
            [-80.806887126999982, 35.214892295000027],
            [-80.806499197999983, 35.215261780000048],
            [-80.806096044999947, 35.215664261000029],
            [-80.805514035999977, 35.216259686000058],
            [-80.805098889999954, 35.216695288000039],
            [-80.804583873999945, 35.217252071000075],
            [-80.803990364999947, 35.217922608000038],
            [-80.803176852999968, 35.218879493000031],
            [-80.802695282999935, 35.219465491000051],
            [-80.802353367999956, 35.219900109000037],
            [-80.801886062999984, 35.220509314000026],
            [-80.801539644999934, 35.220973251000032],
            [-80.801017412999954, 35.221696964000046],
            [-80.800711219999982, 35.222137949000057],
            [-80.800331189999952, 35.222700821000046],
            [-80.800022583999976, 35.22316931000006],
            [-80.799740575999976, 35.223606897000025],
            [-80.799407081999959, 35.22413668400003],
            [-80.798911094999937, 35.224967449000076],
            [-80.798268146999987, 35.226104938000049],
            [-80.797899067999936, 35.226788415000044],
            [-80.797731995999982, 35.227113757000041],
            [-80.797506470999963, 35.227567011000076],
            [-80.797160468999948, 35.22828479900005],
            [-80.796872900999972, 35.22890629300008],
            [-80.796629591999988, 35.229456175000053],
            [-80.796339415999967, 35.230142411000031],
            [-80.796133162999979, 35.230654250000043],
            [-80.795860262999952, 35.231360038000048],
            [-80.795680660999949, 35.231843353000045],
            [-80.795433435999939, 35.232558575000041],
            [-80.795060759999956, 35.233708444000058],
            [-80.794744243999958, 35.234797019000041],
            [-80.794479784999965, 35.235822734000067],
            [-80.794286309999961, 35.236613159000058],
            [-80.79394195499998, 35.237455407000027],
            [-80.793630388999986, 35.238153935000071],
            [-80.793367135999972, 35.238730561000068],
            [-80.793105602999958, 35.239315391000048],
            [-80.792892141999971, 35.23978687500005],
            [-80.792714846999957, 35.24017525000005],
            [-80.792519895999988, 35.240611749000038],
            [-80.792245756999989, 35.241215938000039],
            [-80.791807272999961, 35.242146578000074],
            [-80.791631973999984, 35.242529268000055],
            [-80.791176568999958, 35.243555516000072],
            [-80.790983714999982, 35.244005407000031],
            [-80.790532484999972, 35.245103762000042],
            [-80.79025526099997, 35.245816811000054],
            [-80.790057451999985, 35.246346456000026],
            [-80.789920045999963, 35.246727669000052],
            [-80.789798856999937, 35.247075799000072],
            [-80.789560446999985, 35.247787434000031],
            [-80.789417654999966, 35.248235603000069],
            [-80.789250971999934, 35.248781233000045],
            [-80.789119623999966, 35.249230121000039],
            [-80.788991768999949, 35.249691631000076],
            [-80.788827728999934, 35.250311999000076],
            [-80.788705010999934, 35.250802078000049],
            [-80.78844799999996, 35.251930815000037],
            [-80.788316348999956, 35.252576943000065],
            [-80.788198096999963, 35.253207520000046],
            [-80.788113329999987, 35.253694242000051],
            [-80.787977524999974, 35.254565435000075],
            [-80.787906833999955, 35.255078182000034],
            [-80.787827153999956, 35.255719514000077],
            [-80.787782229999948, 35.256115685000054],
            [-80.787745474999952, 35.256485546000079],
            [-80.787692800999935, 35.257075067000073],
            [-80.787663137999971, 35.257449456000074],
            [-80.787637165999968, 35.257847470000058],
            [-80.787602135999975, 35.258490490000042],
            [-80.787584773999981, 35.258887562000041],
            [-80.787573377999934, 35.259275940000066],
            [-80.787561581999967, 35.259901220000074],
            [-80.787558306999983, 35.260292999000058],
            [-80.78755887799997, 35.260601263000069],
            [-80.787566459999937, 35.261230086000069],
            [-80.787583300999984, 35.26180363800006],
            [-80.787619957999937, 35.262620067000057],
            [-80.787694141999964, 35.263640867000049],
            [-80.787763846999951, 35.264439321000054],
            [-80.787813185999937, 35.264925492000032],
            [-80.787905871999953, 35.265696511000044],
            [-80.788040322999962, 35.26660283700005],
            [-80.788256779999983, 35.267844055000069],
            [-80.788443442999949, 35.268773190000047],
            [-80.78860191299998, 35.269475477000071],
            [-80.788717825999981, 35.269958540000061],
            [-80.788879743999985, 35.270598203000077],
            [-80.789017211999976, 35.271112856000059],
            [-80.789171450999959, 35.271657502000039],
            [-80.789368631999935, 35.272314281000035],
            [-80.789532179999981, 35.272828656000058],
            [-80.789763834999974, 35.273523667000063],
            [-80.789929093999945, 35.27399957800003],
            [-80.79006702099997, 35.274378794000029],
            [-80.79034355999994, 35.275109852000071],
            [-80.790551821999941, 35.275632881000035],
            [-80.790840366999987, 35.276328486000068],
            [-80.791063272999963, 35.276845871000035],
            [-80.791311939999957, 35.277398952000055],
            [-80.791655565999974, 35.278132209000034],
            [-80.791909942999951, 35.278653317000078],
            [-80.792033320999963, 35.279258063000043],
            [-80.792113601999972, 35.279811000000052],
            [-80.792189061999977, 35.280282168000042],
            [-80.79230292799997, 35.280940886000053],
            [-80.792391969999983, 35.28141989900007],
            [-80.792520459999935, 35.282057016000067],
            [-80.792642658999966, 35.282616492000045],
            [-80.792743204999965, 35.283049257000073],
            [-80.792916938999952, 35.28380134400004],
            [-80.793025236999938, 35.284429332000059],
            [-80.793222195999988, 35.285469014000057],
            [-80.793366237999976, 35.286133103000054],
            [-80.793593400999953, 35.287085009000066],
            [-80.79374788399997, 35.287688107000065],
            [-80.79390701899996, 35.288264777000052],
            [-80.794059903999937, 35.288789156000064],
            [-80.794278262999967, 35.289501850000079],
            [-80.79441100799994, 35.289918020000073],
            [-80.794552137999972, 35.290339931000062],
            [-80.794752291999941, 35.290934840000034],
            [-80.794874030999949, 35.29128251800006],
            [-80.795004868999968, 35.291644053000027],
            [-80.795236012999965, 35.29226000500006],
            [-80.795397922999939, 35.292674293000061],
            [-80.79561807999994, 35.293220164000047],
            [-80.795777816999987, 35.293604797000057],
            [-80.796002531999989, 35.294126028000051],
            [-80.79633245499997, 35.29485744200008],
            [-80.796614706999947, 35.295469081000078],
            [-80.796516477999944, 35.296512628000073],
            [-80.796370433999982, 35.297149674000025],
            [-80.796204300999989, 35.297885093000048],
            [-80.796040663999975, 35.298555094000051],
            [-80.795927426999981, 35.299036362000038],
            [-80.795801641999958, 35.299594839000065],
            [-80.795715279999968, 35.299998455000036],
            [-80.795588914999939, 35.300629411000045],
            [-80.795487647999948, 35.301184897000041],
            [-80.795403750999981, 35.301658083000063],
            [-80.795313388999944, 35.30210988400006],
            [-80.795154393999951, 35.302944645000025],
            [-80.79500447099997, 35.303801070000077],
            [-80.794872407999947, 35.304626595000059],
            [-80.794795952999948, 35.305119385000069],
            [-80.794700638999984, 35.30579491900005],
            [-80.794632706999948, 35.306281823000063],
            [-80.794542941999964, 35.306995331000053],
            [-80.794468502999962, 35.307669010000041],
            [-80.794582840999965, 35.308568019000063],
            [-80.794405179999956, 35.309173689000033],
            [-80.794376703999944, 35.309481731000062],
            [-80.79435633199995, 35.309790204000024],
            [-80.79434407499997, 35.310098966000055],
            [-80.794339936999961, 35.310407872000042],
            [-80.79434392099995, 35.310716780000064],
            [-80.794308090999948, 35.311164155000029],
            [-80.794227038999963, 35.312282251000056],
            [-80.794184691999988, 35.313199144000066],
            [-80.79419590599997, 35.313752187000034],
            [-80.794226661999971, 35.31420487400004],
            [-80.794278493999968, 35.31491569800005],
            [-80.794336605999945, 35.315823886000032],
            [-80.794349954999973, 35.316727921000052],
            [-80.794402816999934, 35.317161808000037],
            [-80.794312325999954, 35.318137570000033],
            [-80.794352390999961, 35.318918778000068],
            [-80.794397390999961, 35.31961850700003],
            [-80.79443882999999, 35.320341357000075],
            [-80.794458630999941, 35.320664419000025],
            [-80.794478983999966, 35.321059655000056],
            [-80.794501985999943, 35.321455031000028],
            [-80.794532815999958, 35.321936268000059],
            [-80.794563214999982, 35.32258054700003],
            [-80.794628571999965, 35.323583410000026],
            [-80.794665949999967, 35.324281307000035],
            [-80.794692411999961, 35.324750923000067],
            [-80.794717107999986, 35.325124902000027],
            [-80.794747625999946, 35.325607756000068],
            [-80.794784745999948, 35.326320431000056],
            [-80.794820609999988, 35.326922153000055],
            [-80.794858750999936, 35.327638901000057],
            [-80.794896920999975, 35.32821188500003],
            [-80.794929826999976, 35.328756296000051],
            [-80.794964793999952, 35.329394562000061],
            [-80.794988822999983, 35.329793712000026],
            [-80.79501156699996, 35.330187982000041],
            [-80.795042747999958, 35.330672143000072],
            [-80.795063001999949, 35.331025465000039],
            [-80.795089318999942, 35.331493667000075],
            [-80.795116979999989, 35.332132710000053],
            [-80.795132277999983, 35.332446727000047],
            [-80.795159651999938, 35.333003443000052],
            [-80.795205195999984, 35.333775761000027],
            [-80.795246426999938, 35.334491286000059],
            [-80.795275851999975, 35.335076723000043],
            [-80.795327589999943, 35.336012857000071],
            [-80.795363693999946, 35.336582484000076],
            [-80.795405161999952, 35.33719411900006],
            [-80.795446690999938, 35.33799613900004],
            [-80.795461940999985, 35.338436286000046],
            [-80.79518130799994, 35.33913629400007],
            [-80.793643352999936, 35.342500414000028],
            [-80.79344206199994, 35.342955505000077],
            [-80.793209483999988, 35.343500849000066],
            [-80.792776468999989, 35.344546276000074],
            [-80.79234644099995, 35.345591979000062],
            [-80.791977650999968, 35.346534450000036],
            [-80.791783059999943, 35.347056866000059],
            [-80.791496926999969, 35.347856363000062],
            [-80.791306434999967, 35.348416604000079],
            [-80.790943909999953, 35.34954069500003],
            [-80.790766057999974, 35.350124755000024],
            [-80.790598281999962, 35.35071008400007],
            [-80.79043917599995, 35.351302101000044],
            [-80.790141534999975, 35.35248802600006],
            [-80.789977543999953, 35.353201639000076],
            [-80.789792638999984, 35.354087012000036],
            [-80.789696465999953, 35.354583221000041],
            [-80.789518126999951, 35.355577345000029],
            [-80.78943596299996, 35.356075260000068],
            [-80.789281388999939, 35.357102389000033],
            [-80.789224395999952, 35.357507320000025],
            [-80.789145204999954, 35.358124188000033],
            [-80.78907954899995, 35.35868406000003],
            [-80.78902093499994, 35.359200084000065],
            [-80.788937418999978, 35.360035977000052],
            [-80.788871790999963, 35.360809538000069],
            [-80.78882858999998, 35.361434233000068],
            [-80.788790602999939, 35.362155898000026],
            [-80.788774049999972, 35.362554316000057],
            [-80.788761802999943, 35.362962676000052],
            [-80.788747488999945, 35.363694124000062],
            [-80.788745351999978, 35.364062665000063],
            [-80.788750015999938, 35.364608788000055],
            [-80.788756072999945, 35.364944614000024],
            [-80.788775722999958, 35.365730403000043],
            [-80.788819276999959, 35.366750979000074],
            [-80.788914996999949, 35.367961131000072],
            [-80.789020172999983, 35.368905128000051],
            [-80.78915260499997, 35.370047018000037],
            [-80.789303348999965, 35.371117000000027],
            [-80.789536904999977, 35.37245800200003],
            [-80.789921815999946, 35.374427426000068],
            [-80.790060498999935, 35.375069040000028],
            [-80.790303748999975, 35.376093810000043],
            [-80.790613426999982, 35.377295090000075],
            [-80.79075161999998, 35.377806108000073],
            [-80.790992494999955, 35.378618754000058],
            [-80.791382164999959, 35.379831212000056],
            [-80.79176294399997, 35.380903384000078],
            [-80.792126301999986, 35.381836474000067],
            [-80.792355113999974, 35.38239830200007],
            [-80.792761931999962, 35.38336457500003],
            [-80.792974826999966, 35.383858940000039],
            [-80.793207995999978, 35.384383669000044],
            [-80.793563730999949, 35.385156901000073],
            [-80.793895770999939, 35.385853668000038],
            [-80.794185504999973, 35.386444538000035],
            [-80.794442757999946, 35.386952984000061],
            [-80.794712981999965, 35.38747255100003],
            [-80.794938041999956, 35.387892648000047],
            [-80.795256978999987, 35.388471930000037],
            [-80.795520219999958, 35.388946392000037],
            [-80.795813430999942, 35.389458926000032],
            [-80.796083407999959, 35.389920280000069],
            [-80.796311191999962, 35.39030587700006],
            [-80.796511253999938, 35.390644563000023],
            [-80.796578257999954, 35.391027339000061],
            [-80.796640482999976, 35.391443605000063],
            [-80.796710941999947, 35.39188848200007],
            [-80.796824870999956, 35.392559272000028],
            [-80.796927690999951, 35.39315390400003],
            [-80.79702317999994, 35.39367474900007],
            [-80.797169783999948, 35.394418785000028],
            [-80.797325442999977, 35.395112513000072],
            [-80.797614525999961, 35.396288936000076],
            [-80.797796342999959, 35.396972898000058],
            [-80.798008468999967, 35.397745899000029],
            [-80.798222299999964, 35.398478197000031],
            [-80.79852163299995, 35.39943293500005],
            [-80.798677905999966, 35.399908910000079],
            [-80.798947173999977, 35.400692706000029],
            [-80.799110760999952, 35.401155475000053],
            [-80.799302388999934, 35.401676847000033],
            [-80.79962528599998, 35.40252124400007],
            [-80.80007496099995, 35.403631226000073],
            [-80.800339691999966, 35.404248020000068],
            [-80.800713271999939, 35.405064762000052],
            [-80.801408274999972, 35.406523843000059],
            [-80.801780694999934, 35.407275907000042],
            [-80.802165263999939, 35.408010259000037],
            [-80.802641395999956, 35.408875218000048],
            [-80.802885222999976, 35.409305566000057],
            [-80.803330581999944, 35.410069042000032],
            [-80.80362930199999, 35.410569783000028],
            [-80.804061857999955, 35.41127171800008],
            [-80.804302616999962, 35.412290022000036],
            [-80.804786106999984, 35.415226039000061],
            [-80.805075614999964, 35.416842742000028],
            [-80.805165254999963, 35.417324334000057],
            [-80.80528604899996, 35.417993250000052],
            [-80.805369155999983, 35.418425108000065],
            [-80.805464461999975, 35.418951158000027],
            [-80.80556594799998, 35.419487068000024],
            [-80.805732417999934, 35.420307921000074],
            [-80.805876148999971, 35.420960754000077],
            [-80.805985183999951, 35.421439618000079],
            [-80.806099188999951, 35.42191064900004],
            [-80.80633987899995, 35.422850536000055],
            [-80.806538049999972, 35.423566489000052],
            [-80.806657104999942, 35.423973224000065],
            [-80.806851860999984, 35.424626117000059],
            [-80.807038725999973, 35.425222747000078],
            [-80.807246612999961, 35.42586130400008],
            [-80.807478189999983, 35.426547002000063],
            [-80.807736593999948, 35.427288752000038],
            [-80.807923984999945, 35.427806650000036],
            [-80.808180812999979, 35.428498138000066],
            [-80.808405709999988, 35.429080111000076],
            [-80.808568399999956, 35.429488058000061],
            [-80.808789815999944, 35.430031488000054],
            [-80.809072785999945, 35.430706438000072],
            [-80.809281347999956, 35.431184936000079],
            [-80.809503323999934, 35.43168614800004],
            [-80.809744415999944, 35.432218959000068],
            [-80.809909138999956, 35.432575664000069],
            [-80.810287198999958, 35.433380123000063],
            [-80.810496074999946, 35.433811797000033],
            [-80.810743086999935, 35.434309864000056],
            [-80.811051956999961, 35.434920868000063],
            [-80.811432638999975, 35.435652813000047],
            [-80.811792643999979, 35.436333206000029],
            [-80.812059188999967, 35.436832882000033],
            [-80.812342875999946, 35.437367154000071],
            [-80.812608824999984, 35.43786532300004],
            [-80.812941639999963, 35.438482749000059],
            [-80.813248809999948, 35.439053381000065],
            [-80.813466451999943, 35.439458191000028],
            [-80.814121222999972, 35.440662532000033],
            [-80.815364912999939, 35.442790591000062],
            [-80.815305165999973, 35.443332117000068],
            [-80.815307896999968, 35.44378516900008],
            [-80.815273911999952, 35.444461794000063],
            [-80.815287275999935, 35.445396599000048],
            [-80.815348222999944, 35.446126444000072],
            [-80.81541858099996, 35.446947113000078],
            [-80.815458025999988, 35.447673816000076],
            [-80.815590032999978, 35.448560775000033],
            [-80.815669501999935, 35.449282493000055],
            [-80.815614890999939, 35.449916633000043],
            [-80.815697887999988, 35.450595544000066],
            [-80.815745113999981, 35.45094874800003],
            [-80.815830093999978, 35.451537997000059],
            [-80.815982490999943, 35.452491959000042],
            [-80.816189159999965, 35.453683316000024],
            [-80.816369331999965, 35.45462550600007],
            [-80.816476242999954, 35.455142207000051],
            [-80.816606362999948, 35.455712394000045],
            [-80.816893753999977, 35.456884613000057],
            [-80.817047320999961, 35.457469185000036],
            [-80.817251501999976, 35.458195759000034],
            [-80.817512201999989, 35.459091116000025],
            [-80.817780314999936, 35.459966962000067],
            [-80.818072322999967, 35.460863393000068],
            [-80.818312276999961, 35.461561330000052],
            [-80.81855154699997, 35.462222305000068],
            [-80.818869439999958, 35.463051199000063],
            [-80.819067725999957, 35.463551052000071],
            [-80.819255168999973, 35.464020961000074],
            [-80.819562811999958, 35.464764657000046],
            [-80.819723106999959, 35.46514077300003],
            [-80.819885829999976, 35.465511507000031],
            [-80.820073483999977, 35.46594268900003],
            [-80.820286075999945, 35.466415509000058],
            [-80.820763714999941, 35.467434419000028],
            [-80.821256999999946, 35.468438025000069],
            [-80.821509849999984, 35.468948699000066],
            [-80.821829210999965, 35.469625694000058],
            [-80.821832877999952, 35.470055691000027],
            [-80.821787018999942, 35.470863454000039],
            [-80.821721886999967, 35.472100562000037],
            [-80.821698871999956, 35.472637644000031],
            [-80.821680069999957, 35.473197592000076],
            [-80.82166592599998, 35.473568234000027],
            [-80.821648241999981, 35.474195862000045],
            [-80.821636989999945, 35.474542310000061],
            [-80.821626619999961, 35.474940999000069],
            [-80.821614427999975, 35.475331554000036],
            [-80.821597555999972, 35.476151161000075],
            [-80.821591324999986, 35.477084421000029],
            [-80.821362486999988, 35.477695415000028],
            [-80.821099084999958, 35.478198306000024],
            [-80.820851516999937, 35.478684603000033],
            [-80.820461368999986, 35.479469047000066],
            [-80.820193555999936, 35.480012649000059],
            [-80.819765275999941, 35.480875836000052],
            [-80.819468926999946, 35.481475673000034],
            [-80.819203904999938, 35.481997031000049],
            [-80.818799984999941, 35.482804201000079],
            [-80.818546164999987, 35.483316586000058],
            [-80.818162398999959, 35.484084268000061],
            [-80.81790416299998, 35.484590284000035],
            [-80.817651611999963, 35.485096110000029],
            [-80.81746074199998, 35.485489390000055],
            [-80.817041929999959, 35.486368241000037],
            [-80.816759629999979, 35.486991991000025],
            [-80.816381515999979, 35.487885578000032],
            [-80.81619937399995, 35.488334062000035],
            [-80.816071416999989, 35.488655128000062],
            [-80.815867775999948, 35.489177432000076],
            [-80.815704729999936, 35.489609162000079],
            [-80.815528684999947, 35.490094809000027],
            [-80.815347217999943, 35.490615723000076],
            [-80.815222404999986, 35.490985498000043],
            [-80.815107874999967, 35.491335933000073],
            [-80.815011668999944, 35.491635919000032],
            [-80.814805507999949, 35.492299832000072],
            [-80.814683226999989, 35.492709650000052],
            [-80.814530679999962, 35.49325880300006],
            [-80.814286330999948, 35.494193149000068],
            [-80.814135484999952, 35.49481197800003],
            [-80.813980938999975, 35.495504357000073],
            [-80.813834801999974, 35.496210317000077],
            [-80.813700911999945, 35.496900250000067],
            [-80.813629528999968, 35.497295981000036],
            [-80.813546648999989, 35.49778394100008],
            [-80.813489138999955, 35.498142785000027],
            [-80.813441293999972, 35.498459205000074],
            [-80.813369828999953, 35.49899023200004],
            [-80.813267677999988, 35.49984575700006],
            [-80.813192525999966, 35.500582940000072],
            [-80.81310168899995, 35.501655482000047],
            [-80.813041363999957, 35.502434665000067],
            [-80.812965462999955, 35.503242039000042],
            [-80.812928054999986, 35.503732631000048],
            [-80.812860669999964, 35.504748708000079],
            [-80.812824541999987, 35.505296850000036],
            [-80.812721008999972, 35.50666196700007],
            [-80.812624824999943, 35.507939689000068],
            [-80.812522653999963, 35.509334458000069],
            [-80.812488160999976, 35.509914995000031],
            [-80.812453657999981, 35.510464636000052],
            [-80.812401797999939, 35.510951127000055],
            [-80.812373449999939, 35.511341601000026],
            [-80.812229207999962, 35.512091023000039],
            [-80.812086495999949, 35.512750415000028],
            [-80.811959062999961, 35.513321636000057],
            [-80.811855912999988, 35.51379002200008],
            [-80.811757499999942, 35.514232230000061],
            [-80.81164810599995, 35.514717828000073],
            [-80.811522734999983, 35.515290096000058],
            [-80.811439804999964, 35.515660675000049],
            [-80.811323093999988, 35.516191316000061],
            [-80.81120604299997, 35.51671943000008],
            [-80.811101286999985, 35.517193410000061],
            [-80.811002945999974, 35.517632565000042],
            [-80.810894664999978, 35.518131906000065],
            [-80.810807900999976, 35.518521987000042],
            [-80.810694976999969, 35.519023316000073],
            [-80.810507281999946, 35.519870516000026],
            [-80.810414923999986, 35.520285125000044],
            [-80.810256724999988, 35.520998501000065],
            [-80.810129243999938, 35.521577416000071],
            [-80.810051252999983, 35.521937682000043],
            [-80.809960825999951, 35.522347555000067],
            [-80.809855927999934, 35.522819279000032],
            [-80.809754182999939, 35.52328803100005],
            [-80.809672780999961, 35.52365295900006],
            [-80.80958037399995, 35.524073168000029],
            [-80.80937374399997, 35.524961137000048],
            [-80.809172782999951, 35.525797524000041],
            [-80.809050404999937, 35.526351951000038],
            [-80.808758500999943, 35.527690179000047],
            [-80.808674716999974, 35.528023129000076],
            [-80.80854487299996, 35.528554548000045],
            [-80.808436205999953, 35.529021597000053],
            [-80.808310089999964, 35.529589257000055],
            [-80.808212346999937, 35.530044063000048],
            [-80.808129573999963, 35.530433724000034],
            [-80.807945858999972, 35.53129892700008],
            [-80.807769991999976, 35.53215696400008],
            [-80.807684327999937, 35.532569095000042],
            [-80.807576876999974, 35.533045387000072],
            [-80.807455807999986, 35.533588411000039],
            [-80.807311377999952, 35.534233091000033],
            [-80.807196053999974, 35.534765976000074],
            [-80.807008438999958, 35.53560212900004],
            [-80.806834901999935, 35.536367655000049],
            [-80.806703791999951, 35.536970500000052],
            [-80.806597039999986, 35.537440343000071],
            [-80.806488244999969, 35.537928157000067],
            [-80.806366665999974, 35.538491990000068],
            [-80.806150656999989, 35.539458471000046],
            [-80.806042738999963, 35.539967599000079],
            [-80.805915719999973, 35.540543832000026],
            [-80.805782760999989, 35.541127625000058],
            [-80.805638316999989, 35.541774401000055],
            [-80.805520004999948, 35.542337682000039],
            [-80.805414515999985, 35.542868657000042],
            [-80.805325022999966, 35.543343619000041],
            [-80.805243476999976, 35.543808092000063],
            [-80.805135783999958, 35.544468528000039],
            [-80.80504555999994, 35.545087851000062],
            [-80.804981003999956, 35.545524382000053],
            [-80.804914015999941, 35.546005862000072],
            [-80.804844100999958, 35.54654793900005],
            [-80.804771276999986, 35.547157236000032],
            [-80.80471584199995, 35.547668334000036],
            [-80.804664241999944, 35.548194609000063],
            [-80.804602151999973, 35.548928053000054],
            [-80.804557554999974, 35.549566340000069],
            [-80.804518787999939, 35.550193310000054],
            [-80.80449845399994, 35.550569447000044],
            [-80.804476893999947, 35.551044310000066],
            [-80.804459752999946, 35.551527010000029],
            [-80.804444399999966, 35.552112932000057],
            [-80.804433385999971, 35.552930162000052],
            [-80.804439848999948, 35.553809612000066],
            [-80.804475784999966, 35.554950616000042],
            [-80.804506767999953, 35.55569637900004],
            [-80.804536553999981, 35.55624651200003],
            [-80.804563523999946, 35.556676393000032],
            [-80.80459012099999, 35.55705159300004],
            [-80.804624753999974, 35.557491729000049],
            [-80.804676275999952, 35.55806861700006],
            [-80.80473232199995, 35.558633444000066],
            [-80.804774264999935, 35.559017955000058],
            [-80.805036732999952, 35.559311949000062],
            [-80.804946453999946, 35.559895152000024],
            [-80.804915498999947, 35.56041966600003],
            [-80.804845342999954, 35.560954181000056],
            [-80.804587967999964, 35.561495378000075],
            [-80.804430770999943, 35.562056073000065],
            [-80.804241024999953, 35.562753572000076],
            [-80.804108275999965, 35.563253133000046],
            [-80.803961197999968, 35.563808771000026],
            [-80.80378765599994, 35.564497108000069],
            [-80.803604255999971, 35.56527300700003],
            [-80.803473810999947, 35.565861003000066],
            [-80.803326632999983, 35.566574517000049],
            [-80.803234701999941, 35.567049578000024],
            [-80.80313134499994, 35.567619183000033],
            [-80.803000736999934, 35.568396678000056],
            [-80.802881934999959, 35.569178431000068],
            [-80.802796412999953, 35.569799365000051],
            [-80.80273355199995, 35.570302759000072],
            [-80.802666866999971, 35.57088893100007],
            [-80.802606111999978, 35.571488596000052],
            [-80.802552632999948, 35.572090030000027],
            [-80.802518869999972, 35.572519778000071],
            [-80.802489729999934, 35.572943119000058],
            [-80.802460698999937, 35.573417253000059],
            [-80.80243511599997, 35.573916952000047],
            [-80.802410162999934, 35.574551645000042],
            [-80.80239336599999, 35.575219381000068],
            [-80.802385892999951, 35.575927014000058],
            [-80.802387834999934, 35.576513421000072],
            [-80.802396036999937, 35.577052452000032],
            [-80.802411577999976, 35.577670854000075],
            [-80.802433919999942, 35.578262571000039],
            [-80.802463580999984, 35.578854855000031],
            [-80.802500730999952, 35.579447734000041],
            [-80.802550710999981, 35.580106923000073],
            [-80.802599143999942, 35.580660635000072],
            [-80.802660381999942, 35.581272182000077],
            [-80.802734033999968, 35.581920331000049],
            [-80.802816102999941, 35.582563163000032],
            [-80.802889479999976, 35.583084558000053],
            [-80.802987636999944, 35.583725712000046],
            [-80.803089339999985, 35.58434429600004],
            [-80.803196170999968, 35.584946487000025],
            [-80.80330536699995, 35.585519834000024],
            [-80.803429470999959, 35.586129415000073],
            [-80.80354244199998, 35.58665183100004],
            [-80.803657989999977, 35.587159030000066],
            [-80.803752949999989, 35.587557950000075],
            [-80.803869426999938, 35.588031220000062],
            [-80.804047736999962, 35.588724149000029],
            [-80.804198027999973, 35.589300342000058],
            [-80.804295928999977, 35.589667443000053],
            [-80.804490595999937, 35.590411084000038],
            [-80.804637727999989, 35.590966819000073],
            [-80.804747025999973, 35.591382600000031],
            [-80.804851359999986, 35.591775763000044],
            [-80.804943639999976, 35.592123495000067],
            [-80.805070282999964, 35.592603721000046],
            [-80.805208044999972, 35.593120618000057],
            [-80.805326263999973, 35.59356904100008],
            [-80.805467866999948, 35.594098966000047],
            [-80.805778553999971, 35.595272606000037],
            [-80.805955868999945, 35.595950558000027],
            [-80.806272638999985, 35.597148693000065],
            [-80.806356533999974, 35.597530996000046],
            [-80.806211633999965, 35.598292131000051],
            [-80.80609134599996, 35.598962638000046],
            [-80.805949004999945, 35.599737680000032],
            [-80.805850916999987, 35.600279157000045],
            [-80.805754409999963, 35.600804694000033],
            [-80.805646740999975, 35.601413626000067],
            [-80.805547984999976, 35.601959824000062],
            [-80.805477846999963, 35.602354521000052],
            [-80.805375517999948, 35.60292524700003],
            [-80.805273949999957, 35.603486869000051],
            [-80.805128091999961, 35.604290951000053],
            [-80.805007885999942, 35.604948695000076],
            [-80.804930117999959, 35.605386041000031],
            [-80.804824210999982, 35.605971767000028],
            [-80.804686152999977, 35.606739982000079],
            [-80.804600498999946, 35.607223149000049],
            [-80.80451076899999, 35.607732003000024],
            [-80.804391022999937, 35.608443333000025],
            [-80.804259964999972, 35.609309435000057],
            [-80.804147519999958, 35.610159776000046],
            [-80.804070072999934, 35.610826081000027],
            [-80.804017929999986, 35.61133638900003],
            [-80.803954830999942, 35.612058804000071],
            [-80.80392253499997, 35.612495208000041],
            [-80.803875919999939, 35.613267456000074],
            [-80.803843106999977, 35.613980620000063],
            [-80.80382739099997, 35.61446287800004],
            [-80.803816238999957, 35.614972454000053],
            [-80.803810174999967, 35.615556190000063],
            [-80.803811886999938, 35.616111996000029],
            [-80.803821780999954, 35.616697868000074],
            [-80.803840817999969, 35.617308435000041],
            [-80.803867632999982, 35.617897258000028],
            [-80.803903294999941, 35.618492171000071],
            [-80.803946143999951, 35.619071461000033],
            [-80.803997524999943, 35.619654669000056],
            [-80.804061961999935, 35.620278162000034],
            [-80.804136226999958, 35.620920916000046],
            [-80.804221097999971, 35.621570782000049],
            [-80.804315903999964, 35.622233645000051],
            [-80.804419951999989, 35.622889799000063],
            [-80.804523682999957, 35.623498545000075],
            [-80.804594367999982, 35.623881380000057],
            [-80.804703558999961, 35.624440875000062],
            [-80.804780538999978, 35.624813882000069],
            [-80.804948311999965, 35.625558139000077],
            [-80.805131600999971, 35.62631377200006],
            [-80.805326488999981, 35.627060631000063],
            [-80.805532446999962, 35.627800543000035],
            [-80.805765314999974, 35.62858486600004],
            [-80.80597897499996, 35.629267936000076],
            [-80.806234122999967, 35.630040516000065],
            [-80.806480912999973, 35.630749276000074],
            [-80.806621735999954, 35.631136983000033],
            [-80.806847970999968, 35.631736387000046],
            [-80.807083132999935, 35.632336925000061],
            [-80.807235369999944, 35.632709390000059],
            [-80.807474113999945, 35.633276994000028],
            [-80.807711167999969, 35.633820354000079],
            [-80.807973421999975, 35.634404460000042],
            [-80.80826733899994, 35.635041413000067],
            [-80.808666765999988, 35.635886649000042],
            [-80.80902187099997, 35.63662948700005],
            [-80.809320326999966, 35.637242767000032],
            [-80.809543340999937, 35.637706447000028],
            [-80.809801337999943, 35.638238778000073],
            [-80.810092598999972, 35.63874798300003],
            [-80.810323638999989, 35.639336766000042],
            [-80.810411731999977, 35.640043425000044],
            [-80.810506273999977, 35.640757047000079],
            [-80.810846177999963, 35.641548701000033],
            [-80.810838901999944, 35.642021185000033],
            [-80.810805562999974, 35.642552966000039],
            [-80.810447655999951, 35.643120373000045],
            [-80.810274796999977, 35.643576171000063],
            [-80.810073373999955, 35.644124565000027],
            [-80.809814442999937, 35.644855808000045],
            [-80.809593207999967, 35.645507418000079],
            [-80.809410457999945, 35.646065036000039],
            [-80.809286971999938, 35.646452871000065],
            [-80.809158080999964, 35.64686981400007],
            [-80.809011368999961, 35.64736098700007],
            [-80.808835273999989, 35.64797808700007],
            [-80.808619226999951, 35.648776099000031],
            [-80.80842043499996, 35.649558573000036],
            [-80.808221198999945, 35.650388921000058],
            [-80.808075332999977, 35.651029980000033],
            [-80.808001679999961, 35.651367452000045],
            [-80.80790535899996, 35.651827969000067],
            [-80.807807921999938, 35.652316839000036],
            [-80.807682686999954, 35.652988578000077],
            [-80.80753002299997, 35.653881779000073],
            [-80.807410216999983, 35.654658749000077],
            [-80.807321226999989, 35.655295705000071],
            [-80.807223575999956, 35.656057006000026],
            [-80.807126159999939, 35.656900515000075],
            [-80.806983174999971, 35.657835650000038],
            [-80.806699827999978, 35.658395598000027],
            [-80.806426372999965, 35.658924730000024],
            [-80.805939128999967, 35.659839901000055],
            [-80.805754847999935, 35.66017855900003],
            [-80.805353477999972, 35.660925387000077],
            [-80.805023644999949, 35.661546706000024],
            [-80.804715146999968, 35.662127511000051],
            [-80.804393230999949, 35.662748836000048],
            [-80.804028130999939, 35.663475733000041],
            [-80.803776593999942, 35.663993808000043],
            [-80.803523588999951, 35.664529854000079],
            [-80.803228375999936, 35.665176908000035],
            [-80.802922437999939, 35.665882037000074],
            [-80.802668769999968, 35.666489915000056],
            [-80.802432804999967, 35.667079910000041],
            [-80.802128789999983, 35.667873947000032],
            [-80.801901843999985, 35.668495182000072],
            [-80.801652178999973, 35.669215831000031],
            [-80.801456476999988, 35.669808587000034],
            [-80.801191696999979, 35.670654195000054],
            [-80.800987171999964, 35.671353890000034],
            [-80.800791353999955, 35.672063100000059],
            [-80.800582682999959, 35.672864324000045],
            [-80.80038129999997, 35.673701400000027],
            [-80.80026570299998, 35.674214024000037],
            [-80.800145750999945, 35.674790786000074],
            [-80.800032061999957, 35.675374324000074],
            [-80.799925790999964, 35.675960394000072],
            [-80.799824371999989, 35.67656478400005],
            [-80.799729527999943, 35.677181114000064],
            [-80.799647738999965, 35.677764914000079],
            [-80.799570699999947, 35.678373630000067],
            [-80.799503577999985, 35.678968088000033],
            [-80.799444580999989, 35.679560974000026],
            [-80.799395890999961, 35.680122761000064],
            [-80.799361424999972, 35.680582125000058],
            [-80.799313176999988, 35.681359073000067],
            [-80.799284872999976, 35.68197592000007],
            [-80.799268429999984, 35.682703269000058],
            [-80.799270933999935, 35.683117518000074],
            [-80.799262220999935, 35.683643062000044],
            [-80.799263013999962, 35.68430433900005],
            [-80.799269471999935, 35.68460895700008],
            [-80.799284281999974, 35.685147131000065],
            [-80.799300865999953, 35.685603488000027],
            [-80.799321029999987, 35.686015964000035],
            [-80.799364720999961, 35.686682813000061],
            [-80.799426991999951, 35.687395366000032],
            [-80.799480499999959, 35.68794570600005],
            [-80.799538259999963, 35.688470874000075],
            [-80.799605091999979, 35.689167135000048],
            [-80.799679007999941, 35.689723580000077],
            [-80.799768272999984, 35.690334727000049],
            [-80.799866497999972, 35.690947655000059],
            [-80.799973338999962, 35.691559958000028],
            [-80.800088277999976, 35.692168883000079],
            [-80.800218185999938, 35.692807477000031],
            [-80.80035391499996, 35.693428542000049],
            [-80.800501710999981, 35.694061134000037],
            [-80.800653310999962, 35.694670946000031],
            [-80.800808270999937, 35.695259531000033],
            [-80.800976283999944, 35.695863959000064],
            [-80.801150296999936, 35.696458618000065],
            [-80.801321844999961, 35.697017441000071],
            [-80.80152554099999, 35.697652380000079],
            [-80.80172702699997, 35.698251517000074],
            [-80.801928264999958, 35.698824656000056],
            [-80.802114057999972, 35.699333742000078],
            [-80.802308926999956, 35.699848819000067],
            [-80.802490475999946, 35.700313290000054],
            [-80.80290247399995, 35.700574749000054],
            [-80.802843016999987, 35.701187179000044],
            [-80.802965229999984, 35.701698578000048],
            [-80.802633742999944, 35.702046084000074],
            [-80.802526476999958, 35.702556817000072],
            [-80.80239241299995, 35.703246104000073],
            [-80.802268909999952, 35.703934748000052],
            [-80.802192154999943, 35.704401186000041],
            [-80.802121861999979, 35.704859366000051],
            [-80.802065142999936, 35.705257232000065],
            [-80.802118420999989, 35.705818645000079],
            [-80.802041151999958, 35.706313672000078],
            [-80.801963457999989, 35.706867188000047],
            [-80.801873001999979, 35.707647868000038],
            [-80.801812680999944, 35.708272358000045],
            [-80.80178284699997, 35.708633647000056],
            [-80.801747354999975, 35.709128464000059],
            [-80.801705327999969, 35.709756421000066],
            [-80.801670262999949, 35.710363606000044],
            [-80.80164022799994, 35.71100801800003],
            [-80.801647856999978, 35.711473475000048],
            [-80.801662075999957, 35.711965554000074],
            [-80.801712984999938, 35.712385632000064],
            [-80.801751765999938, 35.712965567000026],
            [-80.801761283999952, 35.713375271000075],
            [-80.801782498999955, 35.713739413000042],
            [-80.801811554999972, 35.714103196000053],
            [-80.801848441999937, 35.714466505000075],
            [-80.801893148999966, 35.714829231000067],
            [-80.80194566199998, 35.715191259000051],
            [-80.801869089999968, 35.715894147000029],
            [-80.801939150999942, 35.716533417000051],
            [-80.802051309999968, 35.717422390000024],
            [-80.802156564999962, 35.718146211000033],
            [-80.802279021999937, 35.718895245000056],
            [-80.802420577999953, 35.719672865000064],
            [-80.802582853999979, 35.720479168000054],
            [-80.802741482999977, 35.721199348000027],
            [-80.802877931999944, 35.721777682000038],
            [-80.803005578999944, 35.722296164000056],
            [-80.80313496499997, 35.722824957000057],
            [-80.803308207999976, 35.723503738000034],
            [-80.803449757999942, 35.724063197000078],
            [-80.803609055999971, 35.724688155000024],
            [-80.803792321999936, 35.725409698000078],
            [-80.803980932999934, 35.726163057000065],
            [-80.804136946999961, 35.726775201000066],
            [-80.804233088999979, 35.727158924000037],
            [-80.804332723999948, 35.727562754000076],
            [-80.804414425999937, 35.727915041000074],
            [-80.804629972999976, 35.729138129000034],
            [-80.804799238999976, 35.730008810000072],
            [-80.804923513999938, 35.730944084000043],
            [-80.805012966999982, 35.731744348000063],
            [-80.80507003699995, 35.73227015100008],
            [-80.805122867999955, 35.732745008000052],
            [-80.805201550999982, 35.733463047000043],
            [-80.805253844999982, 35.733968470000036],
            [-80.805305529999941, 35.734455669000056],
            [-80.805363778999947, 35.734972825000057],
            [-80.805433969999967, 35.73559937300007],
            [-80.805604741999957, 35.737165573000027],
            [-80.805655871999988, 35.737616484000057],
            [-80.805702366999981, 35.738054064000039],
            [-80.805820923999988, 35.739129318000039],
            [-80.805938677999961, 35.740233854000053],
            [-80.805999682999982, 35.740777648000062],
            [-80.806057844999941, 35.741310331000079],
            [-80.806123477999961, 35.74192658700008],
            [-80.806195169999967, 35.742587932000049],
            [-80.806261387999939, 35.743185815000061],
            [-80.806358001999968, 35.744045305000043],
            [-80.806416539999987, 35.744589719000032],
            [-80.806557139999938, 35.745874703000027],
            [-80.806643984999937, 35.746664318000057],
            [-80.806708844999946, 35.747264481000059],
            [-80.806798492999974, 35.748072225000044],
            [-80.806858377999959, 35.748617437000064],
            [-80.806961901999955, 35.749579738000079],
            [-80.807021913999961, 35.750146864000044],
            [-80.807097451999937, 35.75081300800008],
            [-80.807178809999982, 35.751571599000044],
            [-80.807239975999948, 35.752120563000062],
            [-80.807302095999944, 35.752659959000027],
            [-80.807375891999982, 35.753332556000032],
            [-80.807428679999987, 35.753826887000059],
            [-80.807492543999956, 35.754407366000066],
            [-80.807544058999952, 35.754866689000039],
            [-80.807662944999947, 35.755962091000072],
            [-80.807734279999977, 35.756594867000047],
            [-80.807821875999934, 35.757392012000025],
            [-80.807895650999967, 35.758081992000029],
            [-80.807952006999983, 35.758590041000048],
            [-80.808004736999976, 35.759081992000063],
            [-80.808160166999983, 35.760479641000074],
            [-80.808225363999952, 35.761088271000062],
            [-80.808271402999935, 35.761502525000026],
            [-80.808328415999938, 35.762029723000069],
            [-80.808398988999954, 35.762665927000057],
            [-80.808532367999987, 35.763896720000048],
            [-80.808597650999957, 35.764478565000047],
            [-80.808793782999942, 35.766270708000036],
            [-80.808907155999975, 35.767288625000049],
            [-80.809027765999986, 35.768402278000053],
            [-80.809141888999989, 35.769434305000061],
            [-80.80920643799999, 35.770054440000024],
            [-80.809275730999957, 35.770691151000051],
            [-80.809343197999965, 35.771296562000032],
            [-80.809412065999936, 35.77213220200008],
            [-80.809542059999956, 35.77338110200003],
            [-80.809678521999956, 35.774357155000075],
            [-80.809724668999934, 35.774781573000041],
            [-80.809772715999941, 35.775215465000031],
            [-80.809829676999982, 35.775887471000033],
            [-80.809885016999942, 35.776451568000027],
            [-80.809944297999948, 35.776991510000073],
            [-80.809987429999978, 35.777353525000024],
            [-80.810045878999972, 35.777907363000054],
            [-80.810114814999963, 35.778540551000049],
            [-80.810096261999945, 35.779048998000064],
            [-80.810038457999951, 35.77963610300003],
            [-80.809990392999964, 35.780131728000072],
            [-80.809880517999943, 35.781276611000067],
            [-80.809828249999953, 35.781804385000044],
            [-80.809739432999947, 35.782686823000063],
            [-80.809651628999973, 35.783388547000072],
            [-80.809530449999954, 35.784534665000024],
            [-80.809440602999985, 35.785831705000078],
            [-80.809382325999934, 35.78627235700003],
            [-80.80924665699996, 35.787520843000038],
            [-80.809162328999946, 35.788603837000039],
            [-80.809126315999947, 35.78914820600005],
            [-80.809075212999971, 35.78969231800005],
            [-80.809013903999983, 35.790318052000032],
            [-80.808958783999969, 35.790917312000033],
            [-80.808900392999988, 35.791515872000048],
            [-80.808853892999934, 35.792012146000047],
            [-80.808802208999964, 35.792536211000026],
            [-80.808757137999976, 35.792990926000073],
            [-80.808695420999982, 35.793631326000025],
            [-80.808652022999979, 35.79407704700003],
            [-80.808606727999972, 35.794556683000053],
            [-80.80856425099995, 35.794992805000049],
            [-80.80852103999996, 35.795458950000068],
            [-80.808493240999951, 35.795774542000061],
            [-80.808446778999951, 35.796293344000048],
            [-80.808379612999943, 35.79707529600006],
            [-80.808335417999956, 35.797642445000065],
            [-80.80829950399999, 35.798142443000074],
            [-80.808266542999945, 35.79863614900006],
            [-80.808234423999977, 35.799152799000069],
            [-80.808197373999974, 35.79978447600007],
            [-80.808154895999962, 35.800499675000026],
            [-80.808085046999963, 35.801665927000045],
            [-80.808044267999946, 35.802401088000067],
            [-80.808028254999954, 35.802711199000044],
            [-80.807983320999938, 35.803170723000051],
            [-80.807911587999968, 35.804230456000028],
            [-80.80786324799999, 35.805540391000079],
            [-80.807825046999938, 35.805938467000033],
            [-80.807733545999952, 35.807189609000034],
            [-80.807682529999965, 35.808606627000074],
            [-80.807658826999955, 35.80928087500007],
            [-80.807612214999949, 35.809996658000045],
            [-80.807504519999952, 35.811396685000034],
            [-80.80745731199994, 35.812649438000051],
            [-80.807445074999976, 35.81308941900005],
            [-80.807415015999936, 35.813604938000026],
            [-80.807357162999949, 35.814630859000033],
            [-80.807328480999956, 35.815115965000075],
            [-80.807306448999952, 35.81551005700004],
            [-80.807277411999962, 35.816050147000055],
            [-80.807254115999967, 35.816459869000028],
            [-80.807226863999972, 35.816905126000051],
            [-80.807195758999967, 35.81744939500004],
            [-80.807158537999953, 35.818148842000028],
            [-80.807125588999952, 35.818707921000055],
            [-80.807095662999984, 35.819260568000061],
            [-80.807070100999965, 35.819706336000024],
            [-80.807020700999942, 35.820591451000041],
            [-80.806964812999979, 35.821575821000067],
            [-80.806924863999939, 35.822313665000024],
            [-80.806880394999951, 35.823103348000075],
            [-80.806855151999969, 35.823566549000077],
            [-80.806823342999962, 35.82410369400003],
            [-80.806763156999978, 35.825180384000078],
            [-80.806714651999982, 35.826087942000072],
            [-80.806659096999965, 35.827079840000067],
            [-80.806602985999973, 35.828041681000059],
            [-80.806576689999986, 35.828518419000034],
            [-80.80654588799996, 35.829102443000068],
            [-80.806491925999978, 35.830039746000068],
            [-80.806399146999979, 35.831685316000062],
            [-80.806372305999957, 35.832160816000055],
            [-80.806447094999953, 35.83288230800008],
            [-80.806380041999944, 35.833411221000063],
            [-80.806320159999984, 35.833940704000042],
            [-80.80626745699999, 35.834470692000025],
            [-80.806221938999954, 35.835001122000051],
            [-80.806183611999984, 35.835531929000069],
            [-80.80615248099997, 35.836063047000039],
            [-80.806133487999944, 35.836443221000025],
            [-80.806121100999974, 35.836823575000039],
            [-80.806115321999982, 35.837204034000024],
            [-80.806116152999948, 35.837584521000053],
            [-80.806123593999985, 35.837964961000068],
            [-80.805984581999951, 35.83899800100005],
            [-80.80591740899996, 35.840195965000078],
            [-80.805873247999955, 35.841004113000054],
            [-80.805837928999949, 35.841596035000066],
            [-80.805803388999948, 35.842191202000038],
            [-80.805693376999955, 35.843700431000059],
            [-80.805639614999961, 35.845096480000052],
            [-80.805613508999954, 35.845595531000072],
            [-80.805591937999964, 35.846080918000041],
            [-80.805574466999985, 35.84656969200006],
            [-80.80556095999998, 35.84707042000008],
            [-80.805553259999954, 35.847476808000067],
            [-80.805548907999935, 35.847970036000049],
            [-80.805551363999939, 35.848486738000076],
            [-80.805561392999948, 35.849137294000059],
            [-80.805579078999983, 35.849779755000043],
            [-80.805594127999939, 35.850167131000035],
            [-80.805614623999986, 35.850588500000072],
            [-80.805652914999939, 35.851235065000026],
            [-80.805731677999972, 35.852221370000052],
            [-80.805859326999951, 35.853435495000042],
            [-80.806031737999945, 35.85466344200006],
            [-80.806249970999943, 35.855904084000031],
            [-80.806377385999951, 35.856557426000052],
            [-80.806512037999937, 35.857139084000039],
            [-80.806817724999974, 35.858367426000029],
            [-80.807166776999964, 35.859588082000073],
            [-80.807558907999976, 35.86080004300004],
            [-80.807993795999948, 35.862002309000047],
            [-80.808471076999979, 35.86319386100007],
            [-80.808749249999948, 35.863852350000059],
            [-80.808990350999977, 35.864373707000027],
            [-80.809551201999966, 35.865540890000034],
            [-80.810153166999953, 35.866694447000043],
            [-80.810795730999985, 35.867833381000025],
            [-80.811478356999942, 35.868956749000063],
            [-80.812200502999985, 35.870063650000077],
            [-80.812635084999954, 35.870703548000051],
            [-80.812961565999956, 35.871153154000069],
            [-80.813760900999966, 35.872224341000049],
            [-80.814597852999952, 35.873276331000056],
            [-80.815471724999952, 35.87430824300003],
            [-80.816381793999938, 35.875319224000066],
            [-80.817327302999956, 35.876308431000041],
            [-80.817918166999959, 35.876904617000037],
            [-80.818307464999975, 35.877275041000075],
            [-80.819321471999956, 35.878218254000046],
            [-80.820368479999956, 35.879137284000024],
            [-80.821447617999979, 35.880031368000061],
            [-80.822557998999969, 35.880899766000027],
            [-80.823698683999964, 35.881741746000046],
            [-80.824654141999986, 35.882412651000038],
            [-80.826067155999965, 35.883343679000063],
            [-80.827293005999934, 35.884102319000078],
            [-80.828545221999946, 35.884831875000032],
            [-80.829822772999989, 35.885531747000073],
            [-80.831124597999974, 35.886201355000026],
            [-80.832226271999957, 35.886737137000068],
            [-80.833796717999974, 35.887447574000078],
            [-80.83516478599995, 35.888023145000034],
            [-80.836552697999934, 35.888566385000047],
            [-80.837959313999988, 35.889076845000034],
            [-80.839383414999986, 35.889554082000075],
            [-80.840602114999967, 35.889933522000035],
            [-80.842279387999952, 35.890407356000026],
            [-80.843748839999989, 35.890782681000076],
            [-80.84523100399997, 35.891123378000032],
            [-80.846724649999942, 35.891429164000044],
            [-80.848228493999954, 35.891699775000063],
            [-80.849530369999968, 35.891905885000028],
            [-80.851261821999969, 35.892134618000057],
            [-80.852788775999954, 35.892298486000072],
            [-80.854320915999949, 35.892426464000039],
            [-80.85585697099998, 35.892518443000029],
            [-80.857395633999943, 35.892574345000071],
            [-80.858743089999962, 35.89259504000006],
            [-80.860475702999963, 35.892577766000045],
            [-80.862014558999988, 35.892525282000065],
            [-80.863550901999986, 35.892436716000077],
            [-80.86508345499999, 35.892312142000037],
            [-80.866610965999939, 35.892151665000029],
            [-80.867963753999959, 35.891980303000025],
            [-80.86964575099995, 35.891723561000049],
            [-80.871150478999937, 35.891456294000079],
            [-80.872645124999963, 35.891153831000054],
            [-80.874128457999973, 35.890816421000068],
            [-80.875599182999963, 35.890444357000035],
            [-80.876915586999985, 35.890080133000026],
            [-80.878497988999982, 35.889597524000067],
            [-80.879923694999945, 35.889123449000067],
            [-80.881331995999972, 35.888616124000066],
            [-80.882721726999989, 35.888075970000045],
            [-80.884091731999945, 35.887503435000042],
            [-80.885329955999964, 35.886951554000063],
            [-80.886768040999982, 35.886263150000048],
            [-80.888072083999987, 35.885596448000058],
            [-80.889351978999969, 35.884899416000053],
            [-80.890606659999946, 35.884172631000069],
            [-80.891835036999964, 35.883416726000064],
            [-80.89287264099994, 35.882744616000025],
            [-80.893364156999951, 35.882389906000071],
            [-80.894199661999949, 35.881811233000064],
            [-80.894888546999937, 35.881317682000031],
            [-80.895560183999976, 35.880812457000047],
            [-80.896461663999958, 35.880111274000058],
            [-80.897113702999945, 35.879586846000052],
            [-80.897749643999987, 35.879049968000061],
            [-80.898599826999941, 35.878307775000053],
            [-80.899212724999984, 35.877754481000068],
            [-80.899755437999943, 35.87724146000005],
            [-80.900606983999978, 35.876406691000057],
            [-80.901169219999986, 35.875836553000056],
            [-80.901534805999972, 35.875451675000079],
            [-80.90246390599998, 35.874451961000034],
            [-80.90354182699997, 35.87320330700004],
            [-80.904382298999963, 35.872153182000034],
            [-80.905153218999942, 35.871129043000053],
            [-80.90594992299998, 35.869995970000048],
            [-80.906675772999961, 35.868890678000071],
            [-80.907362170999988, 35.867768813000055],
            [-80.908008548999987, 35.866631306000045],
            [-80.908614366999984, 35.865479108000045],
            [-80.909164524999937, 35.864345901000036],
            [-80.909702355999968, 35.863134474000049],
            [-80.910183625999935, 35.86194398300006],
            [-80.910622536999938, 35.860742696000045],
            [-80.911018724999963, 35.859531615000037],
            [-80.911371864999978, 35.85831173400004],
            [-80.911677951999934, 35.857101211000042],
            [-80.911947870999938, 35.855849642000067],
            [-80.912170255999968, 35.854609503000063],
            [-80.912348645999941, 35.853364647000035],
            [-80.912482891999957, 35.852116116000047],
            [-80.912572882999939, 35.850864958000045],
            [-80.912618546999965, 35.849612206000074],
            [-80.912619847999963, 35.84835890100004],
            [-80.912611422999987, 35.847870558000068],
            [-80.912633866999954, 35.847468592000041],
            [-80.912715840999965, 35.846368682000048],
            [-80.912749117999965, 35.845823587000041],
            [-80.912796276999984, 35.844774575000031],
            [-80.912815911999985, 35.844250419000048],
            [-80.912838567999984, 35.84373862800004],
            [-80.912864737999939, 35.843269838000026],
            [-80.912895062999951, 35.842722909000031],
            [-80.912915683999984, 35.84233617600006],
            [-80.912973014999977, 35.841326029000072],
            [-80.913007745999948, 35.840664281000045],
            [-80.913068062999969, 35.839605478000067],
            [-80.913096096999936, 35.839080855000077],
            [-80.913123844999973, 35.838579312000036],
            [-80.913154047999967, 35.838001920000067],
            [-80.913180094999973, 35.837515187000065],
            [-80.913205411999968, 35.837065748000043],
            [-80.913230878999968, 35.836602625000069],
            [-80.913296410999976, 35.835469940000053],
            [-80.913369260999957, 35.834093084000074],
            [-80.913409885999954, 35.833379007000076],
            [-80.913464570999963, 35.832374167000069],
            [-80.913520793999965, 35.831381774000079],
            [-80.913552290999974, 35.83079755600005],
            [-80.913574805999986, 35.830365224000047],
            [-80.913608873999976, 35.829764859000079],
            [-80.913685391999934, 35.828346306000071],
            [-80.91375101899996, 35.827164254000024],
            [-80.913789834999989, 35.826436905000037],
            [-80.913821721999966, 35.82587081500003],
            [-80.91385487499997, 35.825230733000069],
            [-80.91388821399994, 35.824642073000064],
            [-80.913924646999988, 35.824000987000034],
            [-80.91394556399996, 35.82361684600005],
            [-80.914003433999937, 35.822603451000077],
            [-80.914038461999951, 35.821952042000078],
            [-80.914092296999968, 35.820997039000076],
            [-80.914139116999934, 35.820113381000056],
            [-80.914178245999949, 35.819409225000072],
            [-80.914237659999969, 35.818345198000031],
            [-80.914275518999943, 35.817687273000047],
            [-80.914312934999941, 35.816979160000074],
            [-80.914347058999965, 35.81637586800008],
            [-80.914376656999934, 35.815864513000065],
            [-80.914404108999975, 35.815327745000047],
            [-80.914450253999973, 35.814511733000074],
            [-80.914489059999937, 35.813797961000034],
            [-80.914522396999985, 35.813188527000079],
            [-80.914599805999956, 35.812080016000039],
            [-80.914638973999956, 35.811291260000075],
            [-80.914658696999936, 35.810842424000043],
            [-80.914675555999963, 35.810422073000041],
            [-80.914706343999967, 35.80987512400003],
            [-80.914735617999952, 35.809302030000026],
            [-80.914770684999951, 35.808696027000053],
            [-80.914799236999954, 35.808325506000074],
            [-80.914826442999981, 35.807932506000043],
            [-80.914852810999946, 35.807511400000067],
            [-80.91487776799994, 35.807116842000028],
            [-80.914904886999977, 35.806619559000069],
            [-80.914926109999953, 35.80617198200008],
            [-80.914961630999983, 35.805417558000045],
            [-80.914990892999981, 35.804626606000056],
            [-80.915014025999938, 35.80418241600006],
            [-80.91507495899998, 35.803224385000078],
            [-80.915101475999961, 35.802835752000078],
            [-80.915132484999958, 35.802481589000024],
            [-80.91518376199997, 35.801928728000064],
            [-80.915225815999975, 35.801438244000053],
            [-80.915368776999969, 35.799946959000067],
            [-80.915418382999974, 35.799413980000054],
            [-80.915466625999954, 35.79891679800005],
            [-80.915543868999976, 35.798090105000028],
            [-80.915619900999957, 35.797310817000039],
            [-80.915679889999979, 35.796668540000042],
            [-80.91575232799994, 35.795901873000048],
            [-80.915837124999939, 35.795149683000034],
            [-80.915890631999957, 35.794592895000051],
            [-80.915942886999972, 35.794077002000051],
            [-80.915980695999963, 35.793681954000078],
            [-80.916014349999955, 35.793280326000058],
            [-80.91607953099998, 35.792433679000055],
            [-80.916169568999976, 35.791471887000057],
            [-80.916229574999988, 35.790845674000025],
            [-80.916280215999961, 35.790297552000027],
            [-80.916326125999944, 35.789815866000026],
            [-80.916372539999941, 35.789319749000072],
            [-80.916428326999949, 35.788739902000032],
            [-80.916471411999964, 35.788272025000026],
            [-80.916556656999944, 35.787359307000031],
            [-80.916629052999951, 35.786606060000054],
            [-80.916694610999969, 35.785908636000045],
            [-80.916751916999942, 35.785314479000078],
            [-80.916816419999975, 35.784607393000044],
            [-80.916868285999954, 35.784098392000033],
            [-80.916916367999988, 35.783588122000026],
            [-80.916977460999988, 35.782853419000048],
            [-80.917039500999977, 35.781965250000042],
            [-80.917087803999948, 35.781087310000032],
            [-80.917123534999973, 35.780244444000061],
            [-80.917142745999968, 35.779689846000053],
            [-80.917155156999968, 35.77925556100007],
            [-80.917162082999937, 35.778743076000069],
            [-80.917159044999948, 35.777618699000072],
            [-80.917153739999947, 35.777287114000046],
            [-80.917141661999949, 35.776682496000035],
            [-80.917102264999983, 35.775829890000068],
            [-80.917011190999972, 35.774535626000045],
            [-80.916984613999944, 35.774182662000044],
            [-80.91693990899995, 35.773649803000069],
            [-80.916887334999956, 35.77308825700004],
            [-80.916839250999942, 35.772623734000035],
            [-80.916785968999989, 35.772139396000057],
            [-80.916687783999976, 35.771094383000047],
            [-80.916600029999984, 35.770269323000036],
            [-80.916536401999963, 35.769680944000072],
            [-80.916450544999975, 35.768916604000026],
            [-80.916384640999979, 35.768298238000057],
            [-80.916177703999949, 35.767737025000031],
            [-80.916137144999936, 35.767486579000035],
            [-80.916103686999975, 35.767235436000078],
            [-80.916077349999966, 35.766983730000049],
            [-80.916058146999944, 35.766731597000046],
            [-80.916046086999984, 35.766479169000036],
            [-80.916041177999944, 35.766226582000058],
            [-80.916043421999973, 35.765973969000072],
            [-80.916052817999969, 35.765721466000059],
            [-80.915969360999952, 35.76473240200005],
            [-80.915923785999951, 35.764250046000029],
            [-80.915881859999956, 35.763835099000062],
            [-80.91581062399996, 35.763325823000059],
            [-80.915727549999986, 35.762704824000025],
            [-80.915641281999967, 35.762114824000037],
            [-80.915502858999957, 35.761343176000025],
            [-80.915319981999971, 35.760749901000054],
            [-80.915434871999935, 35.759932870000057],
            [-80.915362613999946, 35.759288067000057],
            [-80.91531078099996, 35.758838618000027],
            [-80.91514078299997, 35.757286124000075],
            [-80.915083268999979, 35.756766847000051],
            [-80.915032873999962, 35.756317963000072],
            [-80.914818620999938, 35.754365594000035],
            [-80.914762379999956, 35.753870249000045],
            [-80.91460725799999, 35.752471580000076],
            [-80.914546365999968, 35.751915500000052],
            [-80.914490877999981, 35.75141773100006],
            [-80.914435220999962, 35.75090724000006],
            [-80.914369006999948, 35.750330985000062],
            [-80.914248260999955, 35.749230858000033],
            [-80.914191897999956, 35.748703414000033],
            [-80.914121064999961, 35.748067930000047],
            [-80.914011501999937, 35.747088062000046],
            [-80.913955312999974, 35.746605427000077],
            [-80.913890805999984, 35.746006697000041],
            [-80.91382546799997, 35.745423494000079],
            [-80.913724288999958, 35.744509047000065],
            [-80.913666628999977, 35.743991264000044],
            [-80.913597019999941, 35.743345929000043],
            [-80.913545463999981, 35.742903951000073],
            [-80.913468192999972, 35.742226487000039],
            [-80.913385216999984, 35.741464912000026],
            [-80.913294198999949, 35.74064544600003],
            [-80.913222488999963, 35.739980909000053],
            [-80.913169862999951, 35.739489347000074],
            [-80.913086246999967, 35.738760152000054],
            [-80.912981746999947, 35.737834930000076],
            [-80.912917843999935, 35.737241992000065],
            [-80.912845123999944, 35.736602042000072],
            [-80.912765158999946, 35.735879480000051],
            [-80.912714173999973, 35.735401514000046],
            [-80.912623919999987, 35.734602811000059],
            [-80.912550502999977, 35.73393344200008],
            [-80.91247349799994, 35.733249869000076],
            [-80.912404265999953, 35.73260859100003],
            [-80.912328228999968, 35.731916984000065],
            [-80.912236605999965, 35.731102511000074],
            [-80.912187407999966, 35.730638767000073],
            [-80.912135846999945, 35.730166580000059],
            [-80.912081508999961, 35.729681392000032],
            [-80.911971917999949, 35.72870643400006],
            [-80.91190022699999, 35.728054607000047],
            [-80.911841849999973, 35.727520728000059],
            [-80.911739986999976, 35.726618300000041],
            [-80.911687166999968, 35.726096128000052],
            [-80.911643921999939, 35.725629938000054],
            [-80.911592267999936, 35.725114655000027],
            [-80.911532075999958, 35.724558807000051],
            [-80.911424644999954, 35.723722959000042],
            [-80.911321442999963, 35.722824555000045],
            [-80.911250716999973, 35.722225129000037],
            [-80.911180325999965, 35.721672066000053],
            [-80.911043248999988, 35.720616392000068],
            [-80.910948151999946, 35.719956745000047],
            [-80.910867260999964, 35.719415952000077],
            [-80.910709016999988, 35.718464578000066],
            [-80.910614850999934, 35.717845993000026],
            [-80.910544954999978, 35.717417152000053],
            [-80.910382044999949, 35.716389309000078],
            [-80.910283872999969, 35.715863171000024],
            [-80.91010390799994, 35.714971253000044],
            [-80.910001429999966, 35.714495861000046],
            [-80.909902493999937, 35.713971515000026],
            [-80.910018438999941, 35.713585838000029],
            [-80.910247979999951, 35.712855730000058],
            [-80.910454215999948, 35.712166717000059],
            [-80.91066111799995, 35.711431615000038],
            [-80.910855698999967, 35.710700956000039],
            [-80.910979190999967, 35.710207438000054],
            [-80.911118148999947, 35.709622205000073],
            [-80.911232661999975, 35.709110856000052],
            [-80.911335845999986, 35.708623325000076],
            [-80.911435392999977, 35.708125120000034],
            [-80.911534478999954, 35.707596590000037],
            [-80.91162247799997, 35.707093564000047],
            [-80.91172697799999, 35.706443426000078],
            [-80.911852307999936, 35.705576656000062],
            [-80.911935981999989, 35.704925612000068],
            [-80.911992077999969, 35.704438184000026],
            [-80.912034971999958, 35.704030755000076],
            [-80.912096368999983, 35.703367988000025],
            [-80.912150260999965, 35.702686954000058],
            [-80.912199915999963, 35.701871069000049],
            [-80.912227648999988, 35.701220939000052],
            [-80.912249989999964, 35.700349435000078],
            [-80.912255529999982, 35.69951254800003],
            [-80.912249145999965, 35.698855582000078],
            [-80.912232868999979, 35.698224137000068],
            [-80.912202831999934, 35.697485894000067],
            [-80.912164655999959, 35.696830596000041],
            [-80.912104487999954, 35.696017500000039],
            [-80.912028739999982, 35.695199660000071],
            [-80.911926023999968, 35.694284301000039],
            [-80.911805544999936, 35.693376905000036],
            [-80.911699314999964, 35.69267227000006],
            [-80.911606310999957, 35.692113845000051],
            [-80.911529652999945, 35.691682983000078],
            [-80.911439688999963, 35.69120711100004],
            [-80.911346897999977, 35.690743372000043],
            [-80.91125563199995, 35.690310550000049],
            [-80.911099078999939, 35.689615954000033],
            [-80.910895953999955, 35.688779442000055],
            [-80.910674959999938, 35.68793528100008],
            [-80.910486874999947, 35.687261160000048],
            [-80.910279380999953, 35.686565330000064],
            [-80.909994433999941, 35.685668445000033],
            [-80.909769427999947, 35.685000974000047],
            [-80.909604498999954, 35.684533692000059],
            [-80.909346184999947, 35.683862597000029],
            [-80.909333244999971, 35.683307837000029],
            [-80.909670465999966, 35.682992308000053],
            [-80.909887461999972, 35.682426732000067],
            [-80.910098642999969, 35.681855754000026],
            [-80.910254407999957, 35.681418575000066],
            [-80.910377176999987, 35.681064155000058],
            [-80.910567944999968, 35.680500474000041],
            [-80.910750230999952, 35.67994772600008],
            [-80.910933307999983, 35.679375604000029],
            [-80.911069607999934, 35.678937361000067],
            [-80.911250140999982, 35.678337147000036],
            [-80.911384658999964, 35.677871771000071],
            [-80.911537770999985, 35.677318661000072],
            [-80.911735089999979, 35.676567785000032],
            [-80.911898658999974, 35.675908610000079],
            [-80.911993009999946, 35.675508882000031],
            [-80.912147913999945, 35.674824147000038],
            [-80.91229890599999, 35.674120462000076],
            [-80.912422872999969, 35.673503671000049],
            [-80.912548091999952, 35.672837620000053],
            [-80.912639162999938, 35.672320483000078],
            [-80.912733809999963, 35.671747888000027],
            [-80.912823215999936, 35.67116769900008],
            [-80.91288517299995, 35.670739726000079],
            [-80.912938031999943, 35.670351887000038],
            [-80.913004587999978, 35.669834818000027],
            [-80.913066716999936, 35.669316983000044],
            [-80.913123956999982, 35.668803127000047],
            [-80.91317051599998, 35.668352791000075],
            [-80.913286061999941, 35.667727649000028],
            [-80.91354802799998, 35.667172303000029],
            [-80.913833396999962, 35.666554882000071],
            [-80.914119945999971, 35.665911957000048],
            [-80.914360835999958, 35.665350578000073],
            [-80.914677610999945, 35.664583407000066],
            [-80.91501317899997, 35.663721549000059],
            [-80.91519763499997, 35.663220725000031],
            [-80.915369398999985, 35.662754150000069],
            [-80.915524912999956, 35.662325360000068],
            [-80.915698202999977, 35.661827306000077],
            [-80.915869593999957, 35.661311056000045],
            [-80.91600723199997, 35.660868081000046],
            [-80.916127862999986, 35.660468929000047],
            [-80.916269994999936, 35.659989629000052],
            [-80.916402109999979, 35.659531488000027],
            [-80.916557577999981, 35.658986151000079],
            [-80.916721418999941, 35.658409866000056],
            [-80.916883197999937, 35.657774507000056],
            [-80.917079522999984, 35.65695504200005],
            [-80.917220329999964, 35.656324674000075],
            [-80.917361562999986, 35.655648843000051],
            [-80.917489953999961, 35.654989015000069],
            [-80.917578527999979, 35.654503007000073],
            [-80.917686733999972, 35.653861533000054],
            [-80.917808038999965, 35.653071136000051],
            [-80.917915196999957, 35.652286996000043],
            [-80.917984064999985, 35.651728763000051],
            [-80.918041715999948, 35.651207561000035],
            [-80.918110575999947, 35.650507335000043],
            [-80.918164624999974, 35.649867439000047],
            [-80.918210338999984, 35.649230167000042],
            [-80.918250561999969, 35.648570091000067],
            [-80.918281898999965, 35.647890337000035],
            [-80.918300906999946, 35.647304916000053],
            [-80.918313069999954, 35.646654717000047],
            [-80.918316072999971, 35.645990682000047],
            [-80.918311184999936, 35.645449143000064],
            [-80.918294389999971, 35.644668653000053],
            [-80.918274164999957, 35.644071813000039],
            [-80.918245893999938, 35.643484683000054],
            [-80.918198522999944, 35.642729661000033],
            [-80.918148726999959, 35.642082046000041],
            [-80.918106470999987, 35.641601460000061],
            [-80.918049617999941, 35.641022507000059],
            [-80.917976098999986, 35.640354338000066],
            [-80.917880277999984, 35.639585213000032],
            [-80.917774435999945, 35.638811907000047],
            [-80.917670544999964, 35.638079201000039],
            [-80.917560561999949, 35.637311975000046],
            [-80.917420521999986, 35.636349487000075],
            [-80.917320779999955, 35.635655391000057],
            [-80.917260010999939, 35.635247797000034],
            [-80.917195242999981, 35.634790275000057],
            [-80.917094950999967, 35.63408232300003],
            [-80.916892970999982, 35.63270404900004],
            [-80.916812971999946, 35.632132653000042],
            [-80.916742285999987, 35.63163640700003],
            [-80.916662487999986, 35.631089768000038],
            [-80.916593605999935, 35.630628239000032],
            [-80.916527291999955, 35.630165733000069],
            [-80.916443766999976, 35.629585261000045],
            [-80.916341671999987, 35.628859250000062],
            [-80.916247354999939, 35.628206385000055],
            [-80.916109118999941, 35.627254912000069],
            [-80.916041174999975, 35.626771869000038],
            [-80.915928636999979, 35.626002638000045],
            [-80.915830237999955, 35.625374647000058],
            [-80.915734357999952, 35.624800596000057],
            [-80.915634836999971, 35.624236813000039],
            [-80.915531414999975, 35.623683960000051],
            [-80.915349078999952, 35.622786331000043],
            [-80.915253901999961, 35.62235196000006],
            [-80.915107620999947, 35.621715466000069],
            [-80.914936836999971, 35.621004367000069],
            [-80.914800401999969, 35.620462209000038],
            [-80.914674138999942, 35.619981234000079],
            [-80.914542883999957, 35.619499870000027],
            [-80.914383890999943, 35.618940097000063],
            [-80.914164708999976, 35.618203046000076],
            [-80.913970454999969, 35.617577044000029],
            [-80.913811498999962, 35.617084219000049],
            [-80.913650805999964, 35.61660112800007],
            [-80.913460899999961, 35.61602663900004],
            [-80.913163882999982, 35.615179443000045],
            [-80.912935565999987, 35.614560515000051],
            [-80.912726128999964, 35.614018455000064],
            [-80.912496215999965, 35.61344897500004],
            [-80.912234836999971, 35.612822762000064],
            [-80.911971076999976, 35.612207419000072],
            [-80.911739218999969, 35.61168542300004],
            [-80.911810511999988, 35.61110345700007],
            [-80.911908447999963, 35.610542935000069],
            [-80.911979751999979, 35.609327047000079],
            [-80.912089899999955, 35.608893568000042],
            [-80.912183117999973, 35.608442835000062],
            [-80.912346964999983, 35.607911228000034],
            [-80.912434725999958, 35.607453828000075],
            [-80.912535915999968, 35.607031728000038],
            [-80.912638885999968, 35.606490643000029],
            [-80.912721089999934, 35.606031804000054],
            [-80.912788215999967, 35.605581310000048],
            [-80.912846226999989, 35.605119345000048],
            [-80.912922561999949, 35.604545699000028],
            [-80.913007068999946, 35.60394169500006],
            [-80.91308597799997, 35.603160238000044],
            [-80.913088757999958, 35.602433532000077],
            [-80.913092536999955, 35.601952733000076],
            [-80.91332652899996, 35.601513092000062],
            [-80.913382164999973, 35.600849532000041],
            [-80.91343216599995, 35.600174908000042],
            [-80.913472580999951, 35.599488468000061],
            [-80.913494602999947, 35.599013422000041],
            [-80.913519379999968, 35.598317927000039],
            [-80.91353673399999, 35.597635756000045],
            [-80.913544251999951, 35.596842202000062],
            [-80.913541531999954, 35.596232280000038],
            [-80.913535852999985, 35.595795790000068],
            [-80.913518118999946, 35.595086061000075],
            [-80.913492347999977, 35.594412899000076],
            [-80.913463387999968, 35.593834192000031],
            [-80.913443091999966, 35.593483325000079],
            [-80.913416502999951, 35.593080031000056],
            [-80.913375894999945, 35.592538554000043],
            [-80.913328305999983, 35.591984402000037],
            [-80.913287819999937, 35.591559372000063],
            [-80.913244523999936, 35.591141953000033],
            [-80.913190550999957, 35.590659014000039],
            [-80.913129433999984, 35.590152828000043],
            [-80.913063591999958, 35.589646662000064],
            [-80.912982126999964, 35.589066519000028],
            [-80.912903292999943, 35.588544803000048],
            [-80.912813222999944, 35.587998718000051],
            [-80.91272758599996, 35.587497875000054],
            [-80.912634139999966, 35.586979765000024],
            [-80.912531422999962, 35.586446865000028],
            [-80.912403063999989, 35.585822976000031],
            [-80.912269725999977, 35.585210204000077],
            [-80.912101606999954, 35.584476826000071],
            [-80.911941837999962, 35.583815732000062],
            [-80.911742502999971, 35.58303869100007],
            [-80.911570787999949, 35.582332350000058],
            [-80.911435142999949, 35.581816452000055],
            [-80.911294340999973, 35.581301640000049],
            [-80.911116818999972, 35.580670045000033],
            [-80.910905673999935, 35.579880790000061],
            [-80.91075305399994, 35.579309200000068],
            [-80.910608836999984, 35.578766097000027],
            [-80.910504514999957, 35.57837145700006],
            [-80.910364724999965, 35.57783966900007],
            [-80.910225982999975, 35.57732784500007],
            [-80.910122167999987, 35.576945414000079],
            [-80.909974094999939, 35.57638849600005],
            [-80.910022501999947, 35.575858326000059],
            [-80.910218067999949, 35.575150693000069],
            [-80.910353745999942, 35.574650333000079],
            [-80.910479201999976, 35.57417913300003],
            [-80.910587916999987, 35.573758076000047],
            [-80.910705168999982, 35.573281850000058],
            [-80.910822907999943, 35.57277911400007],
            [-80.91095330099995, 35.572193024000057],
            [-80.911082120999936, 35.571576579000066],
            [-80.911198113999944, 35.570983827000077],
            [-80.911302506999959, 35.570413849000033],
            [-80.911398499999962, 35.569859435000069],
            [-80.911484990999952, 35.569325513000024],
            [-80.91157041699995, 35.568758135000053],
            [-80.911652076999985, 35.568169614000055],
            [-80.911732195999946, 35.567533016000027],
            [-80.911796817999971, 35.566958793000026],
            [-80.911855620999972, 35.566370496000047],
            [-80.911909049999963, 35.565756787000055],
            [-80.911955584999987, 35.565126598000063],
            [-80.91199292999994, 35.564510344000041],
            [-80.912025255999936, 35.563817005000033],
            [-80.912043308999955, 35.563294129000042],
            [-80.912056829999983, 35.562719662000063],
            [-80.912063917999944, 35.562135970000043],
            [-80.912064732999966, 35.561628213000063],
            [-80.91205950899996, 35.56107125300008],
            [-80.912036546999957, 35.560163283000065],
            [-80.911992805999944, 35.55948741800006],
            [-80.911950659999945, 35.558593423000048],
            [-80.911875239999972, 35.557570054000053],
            [-80.911841799999934, 35.557174789000044],
            [-80.911782140999946, 35.556572589000041],
            [-80.911693191999973, 35.555864314000075],
            [-80.911586247999935, 35.555083861000071],
            [-80.911472782999965, 35.554193982000072],
            [-80.911423947999936, 35.553857781000033],
            [-80.911492686999964, 35.55355038600004],
            [-80.91158074699996, 35.553148171000032],
            [-80.911718231999942, 35.552514219000045],
            [-80.911821885999984, 35.552037860000041],
            [-80.911916291999944, 35.551613724000049],
            [-80.912029114999939, 35.551099542000031],
            [-80.912162587999944, 35.550500681000074],
            [-80.91227384299998, 35.549989949000064],
            [-80.912365098999942, 35.549579651000045],
            [-80.91246492099998, 35.549124901000027],
            [-80.912720150999974, 35.547972163000054],
            [-80.91284776599997, 35.547373709000055],
            [-80.912945756999989, 35.546905020000054],
            [-80.913032688999976, 35.546485612000026],
            [-80.913156225999955, 35.545923390000041],
            [-80.913289716999941, 35.545287034000069],
            [-80.913402367999936, 35.544774177000079],
            [-80.91350222799997, 35.544363084000054],
            [-80.913657587999978, 35.54369212000006],
            [-80.913814041999956, 35.542976254000052],
            [-80.91397439299999, 35.542225083000062],
            [-80.914084399999979, 35.541723307000041],
            [-80.914307472999951, 35.540639790000057],
            [-80.914391031999969, 35.540260934000059],
            [-80.91448010199997, 35.539849491000041],
            [-80.91461095599999, 35.539259714000025],
            [-80.914701894999951, 35.53884307800007],
            [-80.914851344999988, 35.538169656000036],
            [-80.914941158999966, 35.537751677000074],
            [-80.915056102999984, 35.537227821000045],
            [-80.915185751999957, 35.536639831000059],
            [-80.915353866999965, 35.535879236000028],
            [-80.915501863999964, 35.535227164000048],
            [-80.915684060999979, 35.534465495000063],
            [-80.915762799999982, 35.534118530000057],
            [-80.915938532999974, 35.53332792100008],
            [-80.916088083999966, 35.53262972400006],
            [-80.91625552499994, 35.531871927000054],
            [-80.91639841999995, 35.531197478000024],
            [-80.916495096999938, 35.530718709000041],
            [-80.916578397999956, 35.530308641000033],
            [-80.916766546999952, 35.529460787000062],
            [-80.916855234999957, 35.529053943000065],
            [-80.916965042999948, 35.528554561000078],
            [-80.917052402999957, 35.528154744000062],
            [-80.917164269999944, 35.527654976000065],
            [-80.917345166999951, 35.52684152900008],
            [-80.917438252999943, 35.526418371000034],
            [-80.917566845999943, 35.525821915000051],
            [-80.917706306999946, 35.525145259000055],
            [-80.917808854999976, 35.524620595000044],
            [-80.917905419999954, 35.52410069900003],
            [-80.917853949999937, 35.523393817000056],
            [-80.917988874999935, 35.522849850000057],
            [-80.91809539999997, 35.522325651000074],
            [-80.918238857999938, 35.52176053200003],
            [-80.918333541999971, 35.521255170000074],
            [-80.918411703999936, 35.520700617000045],
            [-80.918482753999967, 35.520172369000079],
            [-80.918547066999963, 35.51968024100006],
            [-80.918616228999952, 35.519017046000045],
            [-80.918660043999978, 35.518506898000055],
            [-80.918712299999981, 35.517843222000067],
            [-80.918754029999945, 35.516994747000069],
            [-80.918707373999951, 35.516300784000066],
            [-80.91888634999998, 35.515580572000033],
            [-80.918939750999982, 35.515002449000065],
            [-80.918965316999959, 35.514688312000033],
            [-80.91902421399999, 35.513973732000068],
            [-80.919101722999983, 35.513140583000052],
            [-80.91917254699996, 35.512494693000065],
            [-80.91958399799995, 35.51166316900003],
            [-80.919919931999971, 35.510983599000042],
            [-80.920166799999947, 35.510482617000036],
            [-80.92052688199999, 35.509772243000043],
            [-80.920766717999982, 35.509288812000079],
            [-80.92116189799998, 35.508474532000037],
            [-80.921483941999952, 35.507826934000036],
            [-80.921771027999966, 35.507255646000033],
            [-80.922115022999947, 35.506563418000042],
            [-80.92247262099994, 35.505845062000049],
            [-80.922819660999949, 35.505133208000075],
            [-80.923107594999976, 35.504543682000076],
            [-80.923303112999974, 35.504147064000051],
            [-80.923458098999959, 35.503827706000038],
            [-80.923696912999958, 35.503345128000035],
            [-80.923915744999988, 35.502886800000056],
            [-80.924267085999986, 35.502122199000041],
            [-80.924500733999935, 35.50159220900008],
            [-80.924794717999987, 35.500899372000049],
            [-80.924997910999934, 35.50041058100004],
            [-80.925240853999981, 35.499793405000048],
            [-80.925571108999975, 35.498909444000049],
            [-80.92580596199997, 35.498246744000028],
            [-80.925977263999982, 35.497740030000045],
            [-80.926098808999939, 35.497366299000078],
            [-80.926222111999948, 35.496974202000047],
            [-80.926359035999951, 35.496524008000051],
            [-80.926472806999982, 35.49613643400005],
            [-80.926691276999975, 35.495351303000064],
            [-80.926870593999979, 35.494665932000032],
            [-80.926998975999936, 35.494141209000077],
            [-80.927154151999957, 35.49346935300008],
            [-80.927258497999958, 35.492982728000072],
            [-80.927417795999986, 35.492186852000032],
            [-80.92752533099997, 35.491602391000072],
            [-80.92763946499997, 35.490913268000043],
            [-80.927695770999946, 35.490546290000054],
            [-80.927778172999979, 35.489966032000041],
            [-80.927832055999943, 35.489552770000046],
            [-80.927887474999977, 35.489090938000061],
            [-80.927943543999959, 35.488555801000075],
            [-80.928006707999941, 35.487837461000026],
            [-80.928055899999947, 35.48722391900003],
            [-80.928093360999981, 35.486645059000068],
            [-80.92813321999995, 35.485763013000053],
            [-80.928158042999939, 35.484741052000061],
            [-80.928164627999934, 35.484039001000042],
            [-80.928168738999943, 35.483484347000058],
            [-80.928175150999948, 35.482159546000048],
            [-80.928174155999955, 35.481566624000038],
            [-80.92817602599996, 35.481172308000055],
            [-80.928188333999969, 35.480543906000037],
            [-80.928197897999951, 35.479528005000077],
            [-80.92819588499998, 35.479138920000025],
            [-80.928186721999964, 35.47839450400005],
            [-80.928191121999987, 35.477245348000054],
            [-80.928204366999978, 35.476736613000071],
            [-80.928227220999986, 35.476238670000043],
            [-80.928248525999948, 35.475767488000031],
            [-80.928276852999943, 35.475364219000028],
            [-80.928303694999954, 35.475022840000065],
            [-80.928347145999965, 35.474554727000054],
            [-80.928391371999965, 35.47415473500007],
            [-80.928426925999986, 35.473809600000038],
            [-80.928489344999946, 35.473270438000043],
            [-80.928548670999987, 35.472720432000074],
            [-80.928637039999956, 35.471916738000061],
            [-80.928730146999953, 35.471137798000029],
            [-80.92879781399995, 35.470535163000079],
            [-80.928846169999986, 35.470083526000053],
            [-80.928886019999936, 35.469694471000025],
            [-80.928946505999988, 35.469036263000078],
            [-80.928990012999975, 35.468515143000047],
            [-80.929049747999954, 35.467659670000046],
            [-80.929076911999971, 35.467167434000032],
            [-80.929102970999963, 35.466583423000031],
            [-80.929122086999939, 35.466000496000049],
            [-80.92913180499994, 35.465548937000051],
            [-80.929138679999937, 35.464949980000029],
            [-80.929131378999955, 35.463927925000064],
            [-80.929118875999961, 35.463380975000064],
            [-80.929101913999943, 35.462842078000051],
            [-80.929088202999935, 35.462467941000057],
            [-80.929059450999944, 35.461910077000027],
            [-80.928984451999952, 35.460795078000046],
            [-80.928919820999965, 35.46005219400007],
            [-80.928872579999961, 35.459583862000045],
            [-80.92882689299995, 35.459171242000025],
            [-80.928729285999964, 35.458383162000075],
            [-80.928659220999975, 35.457875175000027],
            [-80.928559775999986, 35.45721876500005],
            [-80.928444966999962, 35.456520122000029],
            [-80.928344608999964, 35.455952945000035],
            [-80.928258891999974, 35.455488638000077],
            [-80.928161225999986, 35.454986346000055],
            [-80.928058052999972, 35.454486389000067],
            [-80.92783045699997, 35.453474198000038],
            [-80.927687148999951, 35.452883022000037],
            [-80.927516241999967, 35.452220340000054],
            [-80.927335300999971, 35.451553999000055],
            [-80.927224330999934, 35.451165044000049],
            [-80.92708257999999, 35.450686010000027],
            [-80.926987577999967, 35.450372119000065],
            [-80.92676877599996, 35.449663991000079],
            [-80.926626793999958, 35.449229225000067],
            [-80.926457150999966, 35.448729159000038],
            [-80.926220335999972, 35.448059045000036],
            [-80.926090388999967, 35.447702091000053],
            [-80.925965749999989, 35.44736673500006],
            [-80.925682868999957, 35.446630693000031],
            [-80.925421805999974, 35.44597968000005],
            [-80.925083683999958, 35.445166845000074],
            [-80.924860448999937, 35.444652251000036],
            [-80.924456602999953, 35.443761373000029],
            [-80.924218007999968, 35.443254248000073],
            [-80.92403471199998, 35.44286909300007],
            [-80.923791679999965, 35.442375195000068],
            [-80.92325157199997, 35.44132116600008],
            [-80.922787346999939, 35.440536230000077],
            [-80.922652788999983, 35.439986332000046],
            [-80.922697750999987, 35.439283452000041],
            [-80.922706252999944, 35.438763068000071],
            [-80.922710971999948, 35.438292506000039],
            [-80.922708700999976, 35.437553816000047],
            [-80.922701304999975, 35.437093168000047],
            [-80.922686053999939, 35.436557997000079],
            [-80.922634451999954, 35.435223471000029],
            [-80.922602025999936, 35.434638512000049],
            [-80.922517948999939, 35.433469519000028],
            [-80.922454534999986, 35.432776650000051],
            [-80.922414514999957, 35.432414874000074],
            [-80.922340952999946, 35.431762028000037],
            [-80.922227500999952, 35.430888537000044],
            [-80.922157518999938, 35.430400244000054],
            [-80.922106480999958, 35.430061885000043],
            [-80.922014415999968, 35.42948549700003],
            [-80.921921704999988, 35.428952673000026],
            [-80.921846623999954, 35.428546951000044],
            [-80.921720353999945, 35.427906569000072],
            [-80.92147844699997, 35.426788326000064],
            [-80.921366360999968, 35.426311725000062],
            [-80.921262083999977, 35.425860965000027],
            [-80.921133140999984, 35.425327400000072],
            [-80.921000445999937, 35.424808579000057],
            [-80.92087556499996, 35.424338102000036],
            [-80.920703814999968, 35.423709888000076],
            [-80.92043840599996, 35.422795992000033],
            [-80.920276987999955, 35.422269162000077],
            [-80.920120013999963, 35.421771349000039],
            [-80.919977399999937, 35.421337354000059],
            [-80.919780905999971, 35.420751960000075],
            [-80.919519080999976, 35.42000325600003],
            [-80.919262661999937, 35.419304724000028],
            [-80.91902386199996, 35.418676474000051],
            [-80.91889791899996, 35.418353975000059],
            [-80.918776755999943, 35.418051157000036],
            [-80.918541777999963, 35.417477396000038],
            [-80.918275421999965, 35.416844133000041],
            [-80.917952634999949, 35.416105326000036],
            [-80.917694684999958, 35.415536492000058],
            [-80.917427725999971, 35.414965094000024],
            [-80.916997553999977, 35.414072560000079],
            [-80.916537330999972, 35.413156584000035],
            [-80.916225870999938, 35.412559754000029],
            [-80.91595231499997, 35.412048878000064],
            [-80.915628528999946, 35.411457885000061],
            [-80.915297844999941, 35.41085871000007],
            [-80.915012486999956, 35.41035796400007],
            [-80.914676736999979, 35.409784261000027],
            [-80.914213612999959, 35.409006765000072],
            [-80.913980037999977, 35.408603945000038],
            [-80.913699848999954, 35.408119691000024],
            [-80.913225575999945, 35.407321180000054],
            [-80.912936282999965, 35.406828535000045],
            [-80.912631572999942, 35.406311986000048],
            [-80.912366127999974, 35.405860534000055],
            [-80.912022086999968, 35.40527245100003],
            [-80.911778903999959, 35.40486028600003],
            [-80.911433628999987, 35.404271072000029],
            [-80.91124948199996, 35.403958984000042],
            [-80.91109998099995, 35.403707854000061],
            [-80.910777229999951, 35.403159948000052],
            [-80.910394047999944, 35.401249406000034],
            [-80.91031649599995, 35.400784219000059],
            [-80.910240091999981, 35.400306947000047],
            [-80.910170172999983, 35.399838715000044],
            [-80.910006283999962, 35.398785666000038],
            [-80.909901666999986, 35.398161827000024],
            [-80.909708862999935, 35.397342060000028],
            [-80.90954394399995, 35.396696813000062],
            [-80.90949283499998, 35.396099876000051],
            [-80.909397521999949, 35.395672753000042],
            [-80.909305529999983, 35.395093294000048],
            [-80.909189453999943, 35.394413533000034],
            [-80.909106729999962, 35.393924919000028],
            [-80.90902766499994, 35.393504202000031],
            [-80.908873931999949, 35.392906406000066],
            [-80.908702415999983, 35.392325565000078],
            [-80.908610978999945, 35.391960712000071],
            [-80.908463762999986, 35.391421915000024],
            [-80.908311449999985, 35.390884069000037],
            [-80.90815405099994, 35.390347207000048],
            [-80.907991573999936, 35.389811360000067],
            [-80.907837530999984, 35.38902592900007],
            [-80.907691283999952, 35.388568095000039],
            [-80.907489900999963, 35.387956563000046],
            [-80.907244262999939, 35.387241251000034],
            [-80.907069317999969, 35.386752386000069],
            [-80.906890363999935, 35.386265348000052],
            [-80.906667753999955, 35.385678167000037],
            [-80.906485473999965, 35.385208434000049],
            [-80.906353626999987, 35.384878663000052],
            [-80.90614267899997, 35.384367578000024],
            [-80.905780099999959, 35.383524194000074],
            [-80.904968365999935, 35.381718539000076],
            [-80.90472376699995, 35.381190089000029],
            [-80.904334564999942, 35.380398897000077],
            [-80.903734783999937, 35.379245376000029],
            [-80.90329918499998, 35.378450890000067],
            [-80.902756057999966, 35.377467656000078],
            [-80.902685004999967, 35.376981409000052],
            [-80.90259879499996, 35.376428698000041],
            [-80.902524301999961, 35.37597555900004],
            [-80.902438850999943, 35.375489875000028],
            [-80.902292794999937, 35.374732287000029],
            [-80.902130604999968, 35.37395289300008],
            [-80.902008739999985, 35.373396218000039],
            [-80.901853342999971, 35.372723611000026],
            [-80.901715684999942, 35.372163793000027],
            [-80.901547715999982, 35.371515593000026],
            [-80.901298098999973, 35.370619826000052],
            [-80.901092156999937, 35.36992536300005],
            [-80.900877354999977, 35.369236071000046],
            [-80.900700979999954, 35.368692409000062],
            [-80.900547446999951, 35.368235828000024],
            [-80.900400222999963, 35.367809431000069],
            [-80.900285442999973, 35.36748570900005],
            [-80.900112006999962, 35.36700815100005],
            [-80.89992958199997, 35.366519685000071],
            [-80.899731396999982, 35.366004288000056],
            [-80.899543929999936, 35.365536904000066],
            [-80.899277371999972, 35.364899189000027],
            [-80.899110664999967, 35.364506875000075],
            [-80.898890225999935, 35.363999792000072],
            [-80.89869516899995, 35.363563692000071],
            [-80.898234767999952, 35.362992685000052],
            [-80.898192194999979, 35.362363110000047],
            [-80.898462203999941, 35.362067109000066],
            [-80.898579100999939, 35.361704946000032],
            [-80.898739722999949, 35.361187991000065],
            [-80.898927119999939, 35.360587041000031],
            [-80.89925348099996, 35.359474799000054],
            [-80.899387907999937, 35.359016860000054],
            [-80.899534643999971, 35.358513559000073],
            [-80.899789616999954, 35.357587846000058],
            [-80.899927395999953, 35.35705194500008],
            [-80.900045207999938, 35.356558883000048],
            [-80.90021121999996, 35.355842241000062],
            [-80.900395208999953, 35.355007914000055],
            [-80.900606937999953, 35.354010359000029],
            [-80.900724011999955, 35.35342838300005],
            [-80.900874481999949, 35.352642985000045],
            [-80.900996321999969, 35.352004168000065],
            [-80.901095832999943, 35.351445031000026],
            [-80.901200356999937, 35.350799547000065],
            [-80.90134037699994, 35.349857008000072],
            [-80.901444482999977, 35.349078427000052],
            [-80.901723493999953, 35.346734679000065],
            [-80.901785964999988, 35.346137673000044],
            [-80.901855139999952, 35.345344451000074],
            [-80.901919480999936, 35.344426010000063],
            [-80.901979643999937, 35.343348509000066],
            [-80.902011139999956, 35.342787722000025],
            [-80.902039646999981, 35.342148039000051],
            [-80.902057465999974, 35.341526114000033],
            [-80.902064235999944, 35.341142432000026],
            [-80.902075886999967, 35.340611956000032],
            [-80.902082400999973, 35.339642430000026],
            [-80.902075932999935, 35.33903302300007],
            [-80.902065256999947, 35.33854982400004],
            [-80.902055798999982, 35.338246879000053],
            [-80.902033546999974, 35.337539029000027],
            [-80.90200436799995, 35.336853069000028],
            [-80.901734328999964, 35.331919041000049],
            [-80.901704410999969, 35.331374093000079],
            [-80.90167751599995, 35.330836502000068],
            [-80.901614324999969, 35.329740069000025],
            [-80.901576859999977, 35.329099605000067],
            [-80.901538755999979, 35.32854130700008],
            [-80.901508876999969, 35.328040643000065],
            [-80.901475324999979, 35.327470324000046],
            [-80.901444187999971, 35.326935718000072],
            [-80.901408300999947, 35.326375675000065],
            [-80.901379848999966, 35.325845474000062],
            [-80.901346612999987, 35.325223665000067],
            [-80.901306150999972, 35.324581262000038],
            [-80.901278291999972, 35.324091204000069],
            [-80.901243927999985, 35.323565358000053],
            [-80.901209453999968, 35.322975995000036],
            [-80.901188954999952, 35.32263479900007],
            [-80.90117227799999, 35.322329962000026],
            [-80.901140601999941, 35.321786280000026],
            [-80.901119254999969, 35.321440536000068],
            [-80.901065666999955, 35.32051595400003],
            [-80.901030274999982, 35.319976202000078],
            [-80.900989297999956, 35.319310485000074],
            [-80.900941461999935, 35.318478303000063],
            [-80.90087884999997, 35.31740787800004],
            [-80.900847797999972, 35.316815459000054],
            [-80.900801139999942, 35.316058471000076],
            [-80.900777919999939, 35.315692717000047],
            [-80.900756339999987, 35.314919777000057],
            [-80.900923159999934, 35.314151533000029],
            [-80.901305534999949, 35.312462356000026],
            [-80.901498910999976, 35.311671712000077],
            [-80.90167244099996, 35.310958291000077],
            [-80.901808985999935, 35.310370801000033],
            [-80.902056245999972, 35.309253781000052],
            [-80.902253460999987, 35.308362589000069],
            [-80.902353345999984, 35.30793101200004],
            [-80.902473971999939, 35.307414059000052],
            [-80.902562805999935, 35.307033950000061],
            [-80.902753314999984, 35.306201259000034],
            [-80.902899862999959, 35.305575909000027],
            [-80.90301846899996, 35.305061092000074],
            [-80.903220580999971, 35.304164462000074],
            [-80.903358398999956, 35.303550542000039],
            [-80.903498527999943, 35.302898105000054],
            [-80.903634847999967, 35.302230633000079],
            [-80.903763506999951, 35.301564451000047],
            [-80.903839504999951, 35.301138915000024],
            [-80.903914395999948, 35.300689729000055],
            [-80.904023165999945, 35.299982080000063],
            [-80.904069309999954, 35.299661504000028],
            [-80.904194608999944, 35.298722209000061],
            [-80.904282338999963, 35.297957416000031],
            [-80.904338686999949, 35.297388127000033],
            [-80.904385974999968, 35.296852086000058],
            [-80.904454400999953, 35.295890186000065],
            [-80.904491315999962, 35.29520605600004],
            [-80.90450698799998, 35.294838403000028],
            [-80.904521074999934, 35.294458443000053],
            [-80.904534324999986, 35.293988995000063],
            [-80.904540481999959, 35.293695892000073],
            [-80.904548290999969, 35.293210482000063],
            [-80.904550852999989, 35.292445010000051],
            [-80.904545994999978, 35.291980354000032],
            [-80.904539711999973, 35.291634916000078],
            [-80.904530749999935, 35.291280552000046],
            [-80.904510252999955, 35.290772512000046],
            [-80.904466486999979, 35.28998488700006],
            [-80.904424027999937, 35.289324729000043],
            [-80.904375946999949, 35.288700838000068],
            [-80.904314512999974, 35.28801620400003],
            [-80.90426957699998, 35.287585935000038],
            [-80.904215527999952, 35.287112813000078],
            [-80.904153820999966, 35.286599184000067],
            [-80.904088037999941, 35.286110588000042],
            [-80.90394306099995, 35.285134980000066],
            [-80.903861515999949, 35.284634636000078],
            [-80.903785518999939, 35.284208362000072],
            [-80.903651565999951, 35.283489597000028],
            [-80.903439929999934, 35.282453849000035],
            [-80.903289878999942, 35.281784386000027],
            [-80.903138693999949, 35.28114127200007],
            [-80.903047042999958, 35.280764809000061],
            [-80.902948850999962, 35.280373122000071],
            [-80.902790974999959, 35.27978590400005],
            [-80.902455485999951, 35.27861524900004],
            [-80.902252256999986, 35.277952736000032],
            [-80.902097602999959, 35.277479260000064],
            [-80.901887041999942, 35.276852224000038],
            [-80.90149017899995, 35.275738280000041],
            [-80.901219031999972, 35.27501979200008],
            [-80.901038353999979, 35.274556426000061],
            [-80.900760891999937, 35.27386716500007],
            [-80.900444634999985, 35.273109079000051],
            [-80.900025394999943, 35.272153591000063],
            [-80.899802972999964, 35.271603990000074],
            [-80.899773299999936, 35.271036621000064],
            [-80.899757453999939, 35.270499698000037],
            [-80.899717801999941, 35.26965529000006],
            [-80.899688988999969, 35.269203179000044],
            [-80.899647211999934, 35.26865099500003],
            [-80.899611514999947, 35.26823758300003],
            [-80.899558881999951, 35.267709767000042],
            [-80.89946704099998, 35.266921947000071],
            [-80.899400499999956, 35.266425983000033],
            [-80.899304239999935, 35.265773638000042],
            [-80.899220938999974, 35.265256050000062],
            [-80.899127746999966, 35.264727681000068],
            [-80.899034234999988, 35.264235493000058],
            [-80.898942906999935, 35.263782670000069],
            [-80.898808220999967, 35.26315810400007],
            [-80.898589293999976, 35.262753136000072],
            [-80.898673869999982, 35.26226910400004],
            [-80.899083298999983, 35.261971686000038],
            [-80.89975939499999, 35.261291596000035],
            [-80.900287729999945, 35.260742328000049],
            [-80.900769376999961, 35.260223395000025],
            [-80.901364341999965, 35.259566527000061],
            [-80.902017010999941, 35.258824111000024],
            [-80.902511278999953, 35.258239952000054],
            [-80.903094007999982, 35.257524199000045],
            [-80.903541018999988, 35.256958696000027],
            [-80.904001638999944, 35.256359887000031],
            [-80.904540923999946, 35.255624885000032],
            [-80.905331850999971, 35.254491296000026],
            [-80.905833390999987, 35.253738209000062],
            [-80.906219692999969, 35.253126491000046],
            [-80.906491107999955, 35.252684926000029],
            [-80.906784409999943, 35.25219575400007],
            [-80.906996412999945, 35.251875025000061],
            [-80.907510284999944, 35.251382489000036],
            [-80.908206189999987, 35.250723140000048],
            [-80.908725431999983, 35.250229585000056],
            [-80.909368374999985, 35.249624016000041],
            [-80.909894245999965, 35.249129725000046],
            [-80.910390312999937, 35.248672474000045],
            [-80.910953080999946, 35.248143864000042],
            [-80.911275134999983, 35.247834071000057],
            [-80.911777719999975, 35.247340763000068],
            [-80.912129280999977, 35.246988393000038],
            [-80.912603701999956, 35.246502639000028],
            [-80.913225074999957, 35.245837889000029],
            [-80.914131607999934, 35.244828375000054],
            [-80.914960635999989, 35.243850136000049],
            [-80.915714962999971, 35.242905861000054],
            [-80.916219875999957, 35.24225018900006],
            [-80.916475336999952, 35.241907444000049],
            [-80.916733554999951, 35.241554111000028],
            [-80.91699684799994, 35.241187370000034],
            [-80.917350381999938, 35.24068425400003],
            [-80.91761980299998, 35.240291978000073],
            [-80.91801623799995, 35.239700476000053],
            [-80.918209262999937, 35.23940600800006],
            [-80.918452701999968, 35.239026380000041],
            [-80.918877907999956, 35.238346112000045],
            [-80.919211756999971, 35.237794935000068],
            [-80.919615338999961, 35.237106935000043],
            [-80.920039972999973, 35.236342948000072],
            [-80.920557333999966, 35.235362413000075],
            [-80.920874946999959, 35.234734777000028],
            [-80.921075911999935, 35.23432013200005],
            [-80.921384941999975, 35.233659550000027],
            [-80.921556451999948, 35.233280576000027],
            [-80.921805621999965, 35.232712374000073],
            [-80.921984100999964, 35.232293051000056],
            [-80.922226204999959, 35.231703492000065],
            [-80.922419567999953, 35.231213280000077],
            [-80.922563871999955, 35.230835465000041],
            [-80.922735585999988, 35.230372948000024],
            [-80.922876336999934, 35.22998246800006],
            [-80.922982220999984, 35.229680052000049],
            [-80.923171645999957, 35.229120812000076],
            [-80.923414915999956, 35.22860614800004],
            [-80.92387716799999, 35.228034688000037],
            [-80.924176025999941, 35.22765617500005],
            [-80.924705948999986, 35.22696763600004],
            [-80.924982387999989, 35.226599100000044],
            [-80.92541816399995, 35.226003456000058],
            [-80.925697930999945, 35.225612675000036],
            [-80.926098215999957, 35.225043385000049],
            [-80.926361449999945, 35.22465980000004],
            [-80.926731074999964, 35.224107966000076],
            [-80.926966205999975, 35.223747959000036],
            [-80.927233218999959, 35.223329312000033],
            [-80.92755492799995, 35.222813125000073],
            [-80.927818180999964, 35.222379915000033],
            [-80.928234290999967, 35.22167289500004],
            [-80.928467055999988, 35.221267655000077],
            [-80.929079483999942, 35.22017461300004],
            [-80.929680395999981, 35.219021762000068],
            [-80.930070267999952, 35.21823239500003],
            [-80.930399632999979, 35.217532443000039],
            [-80.93073697899996, 35.216850574000034],
            [-80.930911039999955, 35.21649611600003],
            [-80.931186842999978, 35.215908940000077],
            [-80.931658212999935, 35.214857817000052],
            [-80.932283086999973, 35.213498309000045],
            [-80.932524742999988, 35.21297467900007],
            [-80.932963044999951, 35.212049862000072],
            [-80.933312254999976, 35.211338786000056],
            [-80.933691711999984, 35.210522018000063],
            [-80.934036540999955, 35.20977300900006],
            [-80.934479170999964, 35.208839011000066],
            [-80.93475373199999, 35.20824478000003],
            [-80.93499873899998, 35.207735099000047],
            [-80.935357834999934, 35.206971526000075],
            [-80.935615484999971, 35.206392701000027],
            [-80.935953088999952, 35.205698464000079],
            [-80.936195593999969, 35.205186871000024],
            [-80.936518794999984, 35.204488949000051],
            [-80.936963737999974, 35.203530994000062],
            [-80.937246719999962, 35.20290723800008],
            [-80.937395851999952, 35.202571849000037],
            [-80.93758448899996, 35.20213717200005],
            [-80.937806549999948, 35.201610249000055],
            [-80.93797836799996, 35.201190570000051],
            [-80.938108494999938, 35.200862976000053],
            [-80.938341183999967, 35.200257733000058],
            [-80.938493654999945, 35.199846869000055],
            [-80.938656858999934, 35.199392928000066],
            [-80.938786070999981, 35.199022442000057],
            [-80.93900021099995, 35.198381547000054],
            [-80.93920525499999, 35.197731035000061],
            [-80.939445206999949, 35.196925023000063],
            [-80.939570525999954, 35.196480025000028],
            [-80.939763836999987, 35.195756847000041],
            [-80.939868176999937, 35.195345131000067],
            [-80.940028071999961, 35.19467809300005],
            [-80.940133237999987, 35.194215191000069],
            [-80.940304284999968, 35.193423186000075],
            [-80.940396694999947, 35.192963905000056],
            [-80.940518663999967, 35.192311868000047],
            [-80.940609431999974, 35.191782293000074],
            [-80.940710154999977, 35.191122918000076],
            [-80.940845096999965, 35.190121678000025],
            [-80.940937703999964, 35.189288126000065],
            [-80.941008890999967, 35.188456805000044],
            [-80.941054654999959, 35.187803121000059],
            [-80.941091342999982, 35.187185611000075],
            [-80.941118408999955, 35.186628250000069],
            [-80.941133957999966, 35.186232802000063],
            [-80.941146883999977, 35.185576061000063],
            [-80.941148533999979, 35.184295831000043],
            [-80.941120916999978, 35.183284894000053],
            [-80.941066235999983, 35.182338714000025],
            [-80.941027670999972, 35.18181366400006],
            [-80.94099436099998, 35.181411321000041],
            [-80.940947230999939, 35.180899959000044],
            [-80.940860638999936, 35.18014502300008],
            [-80.940710946999957, 35.179045562000056],
            [-80.940604103999988, 35.178356793000034],
            [-80.940529995999952, 35.177929186000028],
            [-80.940434265999954, 35.177362729000038],
            [-80.940526529999943, 35.176906713000051],
            [-80.940760959999977, 35.176078627000038],
            [-80.940964062999967, 35.17534147300006],
            [-80.941227293999987, 35.174556270000039],
            [-80.941567016999954, 35.173581872000057],
            [-80.94182456499999, 35.172818494000069],
            [-80.941997396999966, 35.17228202900003],
            [-80.94214328999999, 35.171865666000031],
            [-80.942303083999946, 35.171470172000056],
            [-80.943071539999949, 35.170719883000061],
            [-80.943478711999944, 35.170348922000073],
            [-80.943978138999967, 35.169895394000037],
            [-80.944335278999972, 35.169564620000074],
            [-80.945070859999987, 35.168882318000044],
            [-80.945751565999956, 35.168264713000042],
            [-80.946495311999968, 35.167585053000039],
            [-80.946872593999956, 35.167248432000065],
            [-80.94734337999995, 35.166820186000052],
            [-80.947913090999975, 35.166284599000051],
            [-80.948348350999936, 35.165861645000064],
            [-80.949092089999965, 35.165164685000036],
            [-80.950515687999939, 35.163827866000076],
            [-80.95142089999996, 35.162946840000075],
            [-80.951776003999953, 35.162592151000069],
            [-80.952213993999976, 35.162144699000066],
            [-80.952612989999977, 35.161730449000061],
            [-80.953112228999942, 35.161198357000046],
            [-80.953830115999949, 35.160410888000058],
            [-80.954477834999977, 35.15967762300005],
            [-80.955140233999941, 35.158904114000052],
            [-80.955644422999967, 35.158298916000035],
            [-80.956150823999963, 35.157674709000048],
            [-80.956830169999989, 35.156809707000036],
            [-80.957187961999978, 35.156339910000042],
            [-80.957383432999961, 35.156076691000067],
            [-80.957610791999969, 35.155765676000044],
            [-80.957900416999962, 35.155363974000068],
            [-80.95859851299997, 35.15438316500007],
            [-80.959079333999966, 35.153688678000037],
            [-80.959819746999983, 35.152590278000048],
            [-80.960423183999978, 35.151745787000038],
            [-80.961630063999962, 35.150065157000029],
            [-80.961988304999977, 35.149564763000058],
            [-80.962701288999938, 35.148533274000044],
            [-80.963197851999951, 35.147814717000074],
            [-80.963596913999936, 35.147225997000078],
            [-80.963890125999967, 35.14680112700006],
            [-80.964278512999954, 35.146224691000043],
            [-80.964544631999956, 35.145819933000041],
            [-80.964916209999956, 35.145247544000028],
            [-80.965753979999988, 35.143918524000071],
            [-80.966155298999979, 35.143263756000067],
            [-80.966458147999958, 35.142755995000073],
            [-80.966721417999963, 35.142306508000047],
            [-80.967020735999938, 35.141785535000054],
            [-80.967424446999985, 35.141077047000067],
            [-80.967699394999954, 35.140572442000064],
            [-80.968110802999945, 35.139820672000042],
            [-80.96842126599995, 35.13924030700008],
            [-80.968658481999967, 35.138793949000046],
            [-80.969874432999973, 35.137099207000063],
            [-80.970064071999957, 35.136834848000035],
            [-80.970572945999947, 35.136116105000042],
            [-80.970896213999936, 35.135650607000059],
            [-80.971348250999938, 35.13498738800007],
            [-80.971647920999942, 35.13453949500007],
            [-80.972994116999985, 35.132489170000042],
            [-80.97514817299998, 35.129151212000068],
            [-80.975351257999989, 35.128836469000078],
            [-80.975586116999978, 35.128467465000028],
            [-80.976858959999959, 35.126245487000062],
            [-80.977097293999975, 35.125908029000072],
            [-80.977329491999967, 35.125567696000076],
            [-80.977555505999987, 35.125224565000053],
            [-80.977775282999971, 35.124878711000065],
            [-80.97798877699995, 35.124530211000035],
            [-80.978195938999988, 35.12417914100007],
            [-80.978396723999936, 35.123825579000027],
            [-80.97870925899997, 35.123247501000037],
            [-80.979050798999936, 35.122585505000075],
            [-80.979271376999975, 35.12215210200003],
            [-80.979476409999961, 35.12166116800006],
            [-80.979732260999981, 35.121031953000056],
            [-80.979825110999968, 35.120883125000034],
            [-80.979910905999986, 35.120731466000052],
            [-80.979989518999957, 35.12057720100006],
            [-80.980060832999982, 35.120420560000071],
            [-80.980124741999987, 35.120261775000074],
            [-80.980181149999964, 35.120101084000055],
            [-80.98022997399994, 35.119938725000054],
            [-80.980271140999946, 35.11977494000007],
            [-80.980304589999946, 35.119609973000024],
            [-80.980781662999959, 35.118909956000039],
            [-80.980976586999986, 35.118457895000063],
            [-80.981268985999975, 35.117760206000071],
            [-80.981565746999934, 35.117005576000054],
            [-80.981697937999968, 35.116654082000025],
            [-80.98185728499999, 35.116217793000033],
            [-80.982075079999959, 35.115607244000046],
            [-80.982246867999947, 35.115104619000078],
            [-80.982555160999937, 35.114126695000039],
            [-80.982735994999985, 35.113527114000078],
            [-80.982969208999975, 35.112720614000068],
            [-80.983159433999958, 35.112039241000048],
            [-80.983388144999935, 35.111160300000051],
            [-80.983572939999988, 35.110433003000026],
            [-80.983670361999941, 35.110032243000035],
            [-80.983808171999954, 35.10943694100007],
            [-80.983891195999945, 35.109061113000053],
            [-80.983992791999981, 35.10857238400007],
            [-80.98411779199995, 35.107932450000078],
            [-80.984239891999948, 35.10723858700004],
            [-80.98482140599998, 35.106356467000069],
            [-80.985248077999984, 35.10578892500007],
            [-80.986333427999966, 35.104346934000034],
            [-80.98709377299997, 35.103380339000068],
            [-80.987544014999969, 35.102786843000047],
            [-80.987829561999945, 35.102396171000066],
            [-80.988124945999971, 35.10199405000003],
            [-80.988451794999946, 35.101541788000077],
            [-80.988672017999988, 35.101232960000061],
            [-80.989019815999939, 35.100759245000063],
            [-80.989620829999978, 35.099977678000073],
            [-80.989900865999971, 35.099610200000029],
            [-80.99053539099998, 35.098763826000038],
            [-80.990838246999942, 35.098352565000027],
            [-80.991155627999944, 35.097914882000055],
            [-80.991493362999961, 35.097457791000068],
            [-80.991711932999976, 35.097158797000077],
            [-80.99240492399997, 35.096223451000071],
            [-80.993248894999965, 35.095075431000055],
            [-80.993966910999973, 35.094105217000049],
            [-80.994569472999956, 35.093307648000064],
            [-80.994938574999935, 35.092811773000051],
            [-80.995384108999986, 35.092200360000049],
            [-80.995721295999942, 35.091758700000071],
            [-80.995927569999935, 35.091486304000057],
            [-80.996195532999934, 35.091142969000032],
            [-80.996616149999966, 35.090594650000071],
            [-80.997022638999965, 35.090047005000031],
            [-80.997415115999956, 35.089500261000069],
            [-80.99761097999999, 35.089222557000028],
            [-80.997883668999975, 35.08884305600003],
            [-80.998382842999945, 35.088165298000035],
            [-80.998655097999972, 35.08781282800004],
            [-80.998976277999986, 35.087390868000057],
            [-80.999178907999976, 35.087120352000056],
            [-81.000189872999954, 35.085748737000074],
            [-81.000716960999966, 35.085013415000049],
            [-81.001081479999982, 35.084490610000046],
            [-81.00167150499999, 35.083633017000068],
            [-81.002078241999982, 35.083026724000035],
            [-81.002381620999984, 35.082559759000048],
            [-81.002734227999952, 35.081999161000056],
            [-81.003341155999976, 35.081008510000061],
            [-81.003801644999953, 35.080231815000047],
            [-81.004283520999934, 35.079392374000065],
            [-81.004711713999939, 35.078609821000043],
            [-81.005310598999984, 35.077456509000058],
            [-81.005643154999973, 35.076785531000041],
            [-81.006033416999969, 35.075958563000029],
            [-81.006433625999989, 35.075076686000045],
            [-81.006800603999977, 35.074230647000036],
            [-81.007157117999952, 35.073384068000053],
            [-81.007363672999986, 35.072879210000053],
            [-81.007717887999945, 35.071986909000032],
            [-81.007999599999948, 35.071259766000026],
            [-81.008252611999978, 35.070589211000026],
            [-81.008639184999936, 35.069505505000052],
            [-81.009008840999968, 35.06835323200005],
            [-81.009357283999975, 35.067132387000072],
            [-81.009655652999982, 35.065937213000041],
            [-81.009968349999951, 35.064471658000059],
            [-81.010235521999959, 35.063184626000066],
            [-81.010388140999964, 35.062343247000058],
            [-81.010518044999969, 35.061506397000073],
            [-81.010605819999967, 35.060881957000049],
            [-81.010716622999951, 35.060200270000053],
            [-81.010845038999946, 35.059330931000034],
            [-81.011190064999937, 35.056709857000044],
            [-81.011378459999946, 35.055299146000038],
            [-81.011917813999958, 35.051227185000073],
            [-81.012408321999942, 35.047681858000033],
            [-81.012638282999944, 35.047333599000069],
            [-81.012882898999976, 35.046953317000032],
            [-81.013281338999946, 35.046316741000055],
            [-81.01354205399997, 35.045889892000048],
            [-81.014059862999943, 35.045039607000035],
            [-81.014545906999956, 35.044213692000028],
            [-81.014881194999987, 35.043622074000041],
            [-81.015077365999957, 35.043284780000079],
            [-81.015341457999966, 35.042827952000039],
            [-81.015781235999953, 35.042025763000026],
            [-81.016379712999935, 35.04087237400006],
            [-81.016840292999973, 35.039923613000042],
            [-81.017252257999985, 35.039009488000033],
            [-81.017579082999987, 35.038248297000052],
            [-81.017807189999985, 35.037698105000061],
            [-81.017976622999981, 35.037274925000077],
            [-81.018125217999966, 35.036892366000075],
            [-81.01833054399998, 35.036347953000075],
            [-81.018675755999936, 35.035399974000029],
            [-81.019023507999975, 35.034407414000043],
            [-81.019204678999984, 35.033870012000079],
            [-81.019460319999951, 35.033046278000029],
            [-81.01980846899994, 35.031825380000043],
            [-81.019988816999955, 35.031144174000076],
            [-81.020151146999979, 35.030478790000075],
            [-81.020327915999985, 35.029711033000069],
            [-81.020467674999963, 35.029064135000056],
            [-81.02073788499996, 35.027754414000071],
            [-81.020989337999936, 35.026460919000044],
            [-81.021171291999963, 35.025556847000075],
            [-81.021420629999966, 35.024371557000052],
            [-81.021670761999985, 35.023160145000077],
            [-81.021828664999987, 35.022434642000064],
            [-81.021970016999944, 35.021745808000048],
            [-81.022188187999973, 35.020652881000046],
            [-81.022281988999964, 35.020230522000077],
            [-81.02245508499999, 35.019395040000063],
            [-81.024180141999977, 35.016826473000037],
            [-81.024684744999945, 35.016078958000037],
            [-81.025515266999946, 35.014871822000032],
            [-81.025989657999958, 35.014169099000071],
            [-81.026544083999966, 35.013336005000042],
            [-81.026906513999961, 35.012805180000043],
            [-81.027495525999939, 35.011941829000079],
            [-81.027976525999975, 35.011242852000066],
            [-81.028272804999972, 35.010811113000045],
            [-81.02908692799997, 35.009636013000033],
            [-81.02949979999994, 35.009017736000033],
            [-81.029739688999939, 35.008645100000024],
            [-81.030144078999967, 35.008030864000034],
            [-81.03045653099997, 35.007565671000066],
            [-81.030834701999936, 35.007028795000053],
            [-81.031328145999964, 35.006322855000064],
            [-81.03173008999994, 35.005734489000076],
            [-81.031971574999943, 35.005376269000067],
            [-81.03269077899995, 35.004334305000043],
            [-81.033907092999982, 35.002496154000028],
            [-81.034373700999936, 35.001759985000035],
            [-81.035021582999946, 35.000689794000039],
            [-81.035336584999982, 35.00015103100003],
            [-81.035834941999951, 34.999267696000061],
            [-81.036183764999976, 34.998623721000058],
            [-81.036781659999974, 34.99747020500007],
            [-81.037324492999971, 34.996335748000035],
            [-81.037855004999983, 34.995123037000042],
            [-81.038234117999934, 34.994194010000058],
            [-81.038571761999947, 34.993312591000063],
            [-81.038745480999978, 34.992838948000042],
            [-81.039079776999984, 34.991888544000062],
            [-81.039424893999978, 34.990803357000061],
            [-81.039772583999934, 34.989582374000065],
            [-81.03998390199996, 34.988770900000077],
            [-81.040100934999941, 34.988273080000056],
            [-81.040287832999979, 34.987434217000043],
            [-81.040501111999959, 34.986379535000026],
            [-81.040625899999952, 34.985686484000041],
            [-81.040734522999969, 34.985001329000056],
            [-81.040857364999965, 34.984146238000051],
            [-81.040967748999947, 34.983274163000033],
            [-81.041058662999944, 34.982351419000054],
            [-81.04114194999994, 34.981203347000076],
            [-81.041174373999979, 34.980628852000052],
            [-81.041200972999945, 34.979965992000075],
            [-81.041216784999961, 34.979200415000037],
            [-81.041216177999956, 34.977946345000078],
            [-81.041198872999985, 34.977167820000034],
            [-81.041181895999955, 34.976789146000044],
            [-81.041129318999936, 34.975780775000032],
            [-81.041098090999981, 34.975305932000026],
            [-81.041023033999977, 34.974356919000058],
            [-81.040902180999979, 34.973258858000065],
            [-81.040726327999948, 34.972013167000057],
            [-81.040593678999983, 34.971203820000028],
            [-81.04045076999995, 34.970429363000051],
            [-81.040320115999975, 34.969766943000025],
            [-81.040078973999982, 34.968654488000027],
            [-81.039799328999948, 34.967535259000044],
            [-81.039475600999936, 34.96639250100003],
            [-81.039234264999948, 34.965602465000075],
            [-81.039079759999936, 34.965116967000029],
            [-81.03884015999995, 34.964409342000067],
            [-81.038406600999963, 34.963207125000054],
            [-81.03811049799998, 34.962438534000057],
            [-81.037935964999974, 34.962014174000046],
            [-81.037685368999973, 34.961415337000062],
            [-81.037337959999945, 34.960590830000058],
            [-81.036630387999935, 34.958973537000077],
            [-81.036300331999939, 34.958191365000062],
            [-81.035884750999969, 34.957235329000071],
            [-81.035618435999936, 34.95660719600005],
            [-81.034974431999956, 34.955104721000055],
            [-81.034208757999977, 34.953274614000065],
            [-81.033906993999949, 34.952573335000068],
            [-81.033672611999975, 34.952046387000053],
            [-81.033411992999959, 34.951471430000026],
            [-81.033253017999982, 34.951118658000041],
            [-81.033029477999946, 34.950634235000052],
            [-81.032812863999936, 34.95017408700005],
            [-81.032660151999949, 34.949843034000025],
            [-81.032343071999946, 34.949115465000034],
            [-81.032093803999942, 34.948556232000044],
            [-81.031888785999968, 34.948062857000025],
            [-81.031514352999977, 34.947148899000069],
            [-81.03120557799997, 34.946421735000058],
            [-81.031000344999939, 34.945953898000027],
            [-81.030837023999936, 34.94556534000003],
            [-81.030372092999983, 34.944465511000033],
            [-81.030085087999964, 34.943808021000052],
            [-81.029426993999948, 34.942348327000047],
            [-81.029230430999974, 34.941844687000071],
            [-81.029012784999964, 34.941305598000042],
            [-81.028641388999972, 34.940428048000058],
            [-81.028085759999954, 34.939169363000076],
            [-81.027728885999977, 34.93834398100006],
            [-81.027465391999954, 34.937586329000055],
            [-81.027824215999942, 34.936861879000048],
            [-81.028282037999986, 34.93597352300003],
            [-81.028513262999979, 34.935511614000063],
            [-81.028834408999955, 34.934912669000028],
            [-81.02954815999999, 34.933528958000068],
            [-81.02985618799994, 34.932952641000043],
            [-81.030317183999955, 34.932074813000042],
            [-81.030520960999979, 34.931675319000078],
            [-81.03087845999994, 34.930998665000061],
            [-81.031373871999961, 34.930022201000043],
            [-81.031820324999956, 34.929075542000078],
            [-81.032350497999971, 34.927862808000043],
            [-81.032824760999972, 34.926671080000062],
            [-81.033257114999969, 34.925468590000037],
            [-81.033647201999941, 34.924256339000067],
            [-81.033994702999962, 34.923035326000047],
            [-81.034295679999957, 34.921823724000035],
            [-81.03456082799994, 34.920571083000027],
            [-81.034778980999988, 34.919329927000035],
            [-81.034953615999939, 34.918084092000072],
            [-81.035084587999961, 34.916834625000035],
            [-81.035171786999967, 34.915582571000073],
            [-81.035215143999949, 34.914328966000028],
            [-81.035214624999981, 34.913074850000044],
            [-81.035170232999974, 34.91182126800004],
            [-81.035082005999982, 34.910569259000056],
            [-81.034950016999971, 34.909319860000039],
            [-81.034774381999966, 34.908074117000069],
            [-81.034555250999972, 34.90683307300003],
            [-81.034289136999973, 34.905580568000062],
            [-81.033987248999949, 34.904369117000044],
            [-81.03363885999994, 34.90314827900005],
            [-81.033247921999987, 34.901936222000074],
            [-81.032814760999941, 34.900733943000034],
            [-81.032339735999983, 34.899542444000076],
            [-81.031808833999946, 34.898329961000059],
            [-81.031265720999954, 34.89719573900004],
            [-81.030667623999989, 34.896042479000073],
            [-81.030029454999976, 34.894903893000048],
            [-81.029351743999939, 34.893780931000038],
            [-81.028635057999963, 34.892674523000039],
            [-81.027848392999942, 34.891540270000064],
            [-81.027087169999959, 34.89051502500007],
            [-81.026257255999951, 34.889463727000077],
            [-81.025390942999934, 34.888432570000077],
            [-81.024488941999948, 34.887422400000048],
            [-81.023552002999963, 34.886434055000052],
            [-81.022526752999966, 34.885414857000058],
            [-81.02157648299999, 34.884526136000034],
            [-81.020539535999944, 34.883608135000031],
            [-81.019470944999966, 34.882715138000037],
            [-81.018371584999954, 34.881847871000048],
            [-81.017242367999984, 34.88100705800008],
            [-81.016003532999946, 34.880137155000057],
            [-81.014898175999974, 34.879407580000077],
            [-81.013685134999946, 34.878650236000055],
            [-81.012446112999953, 34.877921988000026],
            [-81.011182180999981, 34.877223467000078],
            [-81.009894391999978, 34.876555253000049],
            [-81.008474220999972, 34.875865181000052],
            [-81.007251406999956, 34.875311866000061],
            [-81.005898437999974, 34.874737744000072],
            [-81.004525970999964, 34.874195976000067],
            [-81.003135147999956, 34.873687014000041],
            [-81.001727112999959, 34.873211277000053],
            [-81.000164296999969, 34.872726818000046],
            [-80.998864160999972, 34.872361048000073],
            [-80.997411583999963, 34.871987251000064],
            [-80.99594652199994, 34.871648084000071],
            [-80.994470251999985, 34.871343845000069],
            [-80.992983982999988, 34.871074779000026],
            [-80.991322572999934, 34.870816013000024],
            [-80.989643741999942, 34.870608040000036],
            [-80.988132105999966, 34.870461946000034],
            [-80.987537127999985, 34.870409738000035],
            [-80.986442269999941, 34.870399961000032],
            [-80.985434830999964, 34.87036773300008],
            [-80.984530559999939, 34.870314407000023],
            [-80.98362844899998, 34.870270251000079],
            [-80.982401204999974, 34.870218668000064],
            [-80.981855059999987, 34.870199219000028],
            [-80.981352773999959, 34.870199636000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 152,
        "SolutionID": "PI-29:E",
        "Shape_Length": 2.3001769675427952,
        "Shape_Area": 0.11202781108794944
      }
    },
    {
      "type": "Feature",
      "id": 153,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.981352773999959, 34.870199636000052],
            [-80.980594408999934, 34.870235815000058],
            [-80.979367160999971, 34.870305354000038],
            [-80.978465792999941, 34.870362659000079],
            [-80.977562301999967, 34.870429175000027],
            [-80.977053111999965, 34.87045385600004],
            [-80.976352650999956, 34.870547681000062],
            [-80.974530791999939, 34.870643324000071],
            [-80.972819634999951, 34.870869817000028],
            [-80.97153293599996, 34.871074236000027],
            [-80.970046537999963, 34.871342885000047],
            [-80.968570118999935, 34.871646712000029],
            [-80.967104946999939, 34.871985457000051],
            [-80.965652232999958, 34.872358839000071],
            [-80.963993929999958, 34.87283044600008],
            [-80.962788907999936, 34.873208262000048],
            [-80.96138068199997, 34.873683598000071],
            [-80.959989623999945, 34.874192175000076],
            [-80.958616933999963, 34.874733554000045],
            [-80.957263736999948, 34.87530729000008],
            [-80.955710193999948, 34.876015617000064],
            [-80.954620248999959, 34.876549921000048],
            [-80.953332158999956, 34.877217783000049],
            [-80.952067935999935, 34.877915945000041],
            [-80.950828630999979, 34.878643830000044],
            [-80.949615260999963, 34.879400840000073],
            [-80.948216426999977, 34.880329998000036],
            [-80.947270412999956, 34.880999648000056],
            [-80.946140849999949, 34.88184013800003],
            [-80.945041119999985, 34.882707099000072],
            [-80.943972149999979, 34.883599801000059],
            [-80.942934820999938, 34.884517508000044],
            [-80.941929993999963, 34.885459458000071],
            [-80.941544186999977, 34.885829398000055],
            [-80.940958508999984, 34.886424867000073],
            [-80.940021164999962, 34.887412939000058],
            [-80.939118744999973, 34.888422852000076],
            [-80.938251997999942, 34.889453769000056],
            [-80.937421644999972, 34.89050483300008],
            [-80.936628377999966, 34.891575171000056],
            [-80.936304328999938, 34.892024435000053],
            [-80.935872849999953, 34.892663902000038],
            [-80.935155702999964, 34.893770108000069],
            [-80.934492913999975, 34.894865498000058],
            [-80.933883165999987, 34.895948450000049],
            [-80.933515743999976, 34.896630172000073],
            [-80.933257118999961, 34.89712445400005],
            [-80.933036662999939, 34.89754801600003],
            [-80.932860120999976, 34.89788855300003],
            [-80.932635523999977, 34.898320237000064],
            [-80.932260199999973, 34.899043293000034],
            [-80.931938142999968, 34.899662294000052],
            [-80.931662436999943, 34.90019691100008],
            [-80.931514299999947, 34.900480383000058],
            [-80.931382024999948, 34.900736166000058],
            [-80.931178597999974, 34.901126165000051],
            [-80.930759710999951, 34.901938176000044],
            [-80.930294301999936, 34.902878499000053],
            [-80.930017452999948, 34.90344895700008],
            [-80.929590565999945, 34.904274379000071],
            [-80.929054753999935, 34.905308121000076],
            [-80.928697249999971, 34.905992618000028],
            [-80.928253592999965, 34.906854917000032],
            [-80.92796696299996, 34.907405806000043],
            [-80.927521462999948, 34.908252115000039],
            [-80.927232190999973, 34.908811613000069],
            [-80.926993098999958, 34.909278310000047],
            [-80.926507825999977, 34.910210878000044],
            [-80.926059291999934, 34.911075652000079],
            [-80.925702222999973, 34.911762779000071],
            [-80.925242523999941, 34.912646676000065],
            [-80.924856254999952, 34.913388394000037],
            [-80.924450440999976, 34.914168532000076],
            [-80.92398473999998, 34.915065245000051],
            [-80.923447092999936, 34.916097637000064],
            [-80.92314098899999, 34.916694171000074],
            [-80.922783785999968, 34.917400345000033],
            [-80.922260603999973, 34.918485410000073],
            [-80.921876229999953, 34.919327266000039],
            [-80.921505054999955, 34.920198071000073],
            [-80.921029268999973, 34.921389380000051],
            [-80.920734100999937, 34.922177158000068],
            [-80.920519271999979, 34.922791025000038],
            [-80.920386701999973, 34.923181854000063],
            [-80.920256293999955, 34.923582074000024],
            [-80.92000474799994, 34.924384501000077],
            [-80.919843484999944, 34.924925639000037],
            [-80.919661700999939, 34.925583367000058],
            [-80.919403944999942, 34.926584303000027],
            [-80.919241641999974, 34.927264711000078],
            [-80.919124014999966, 34.92779762400005],
            [-80.919009215999949, 34.928356093000048],
            [-80.918889771999943, 34.928994763000048],
            [-80.918725182999935, 34.929966361000027],
            [-80.918632729999956, 34.930573540000069],
            [-80.918569329999968, 34.931036023000047],
            [-80.918499721999979, 34.931613635000076],
            [-80.918377081999949, 34.932790858000033],
            [-80.918314296999938, 34.933548552000047],
            [-80.918288759999939, 34.933969601000058],
            [-80.918249954999965, 34.934762127000056],
            [-80.918216596999969, 34.93590795700004],
            [-80.918211563999989, 34.936526364000031],
            [-80.918227774999934, 34.937485930000037],
            [-80.918250343999944, 34.938138510000044],
            [-80.918288872999938, 34.938905911000063],
            [-80.918324278999989, 34.939443378000078],
            [-80.918387516999985, 34.940176504000078],
            [-80.918498104999969, 34.941225271000064],
            [-80.918561101999956, 34.941749093000055],
            [-80.918615808999959, 34.942153305000033],
            [-80.918714138999974, 34.942799390000062],
            [-80.918897804999972, 34.94387649500004],
            [-80.918997815999944, 34.944414106000067],
            [-80.919109456999934, 34.94496403200003],
            [-80.91928601099994, 34.945748485000024],
            [-80.919597129999943, 34.947002123000061],
            [-80.919770232999952, 34.947649223000042],
            [-80.919867370999953, 34.947986813000057],
            [-80.920076006999977, 34.948686340000052],
            [-80.920251261999965, 34.949238394000076],
            [-80.920520453999984, 34.950044794000064],
            [-80.920786876999955, 34.950794471000052],
            [-80.92102050699998, 34.95141505600003],
            [-80.921194975999981, 34.951863307000053],
            [-80.92138276899999, 34.952333098000054],
            [-80.92150535899998, 34.952631730000064],
            [-80.921778011999947, 34.953274135000072],
            [-80.922033302999978, 34.953864254000052],
            [-80.922388385999966, 34.954691646000072],
            [-80.922672409999961, 34.955357236000054],
            [-80.922990439999978, 34.956094636000046],
            [-80.923231093999959, 34.956645913000045],
            [-80.923423876999948, 34.957100909000076],
            [-80.923553211999945, 34.957399046000035],
            [-80.923705148999943, 34.957756346000053],
            [-80.923902229999953, 34.958209941000064],
            [-80.924228640999956, 34.958977253000057],
            [-80.924491972999988, 34.959585812000057],
            [-80.92473954999997, 34.960152657000037],
            [-80.924985087999971, 34.960724126000059],
            [-80.925169035999943, 34.96114863400004],
            [-80.925358021999955, 34.961586338000075],
            [-80.925613268999939, 34.962180400000079],
            [-80.926031087999945, 34.963149774000044],
            [-80.926334831999952, 34.963849768000046],
            [-80.92665696399996, 34.964608483000063],
            [-80.926884403999964, 34.96514387700006],
            [-80.927129358999935, 34.965701050000064],
            [-80.927405796999949, 34.96634748200006],
            [-80.92774478299998, 34.96714100500003],
            [-80.928082323999945, 34.96793120600006],
            [-80.928339813999969, 34.968529039000032],
            [-80.928547699999967, 34.969012176000035],
            [-80.928966564999939, 34.969993392000049],
            [-80.929198434999989, 34.970527663000041],
            [-80.929594904999988, 34.971449482000025],
            [-80.929795934999959, 34.971925296000052],
            [-80.93008733299996, 34.972614667000073],
            [-80.930383595999956, 34.97329312200003],
            [-80.930731121999941, 34.974117534000072],
            [-80.931133428999942, 34.975165154000024],
            [-80.930639901999939, 34.975823310000067],
            [-80.930326964999949, 34.976287537000076],
            [-80.930038757999966, 34.976711247000026],
            [-80.929434653999976, 34.977598680000028],
            [-80.929144373999975, 34.978031122000061],
            [-80.928807867999978, 34.978517046000036],
            [-80.928511354999955, 34.978950184000041],
            [-80.927960623999979, 34.979769081000029],
            [-80.927709689999972, 34.980146194000042],
            [-80.92713753999999, 34.98099611400005],
            [-80.926855972999988, 34.981425665000074],
            [-80.926325590999966, 34.982471942000075],
            [-80.925981432999947, 34.982942598000079],
            [-80.925655432999974, 34.983409154000071],
            [-80.925162674999967, 34.984243520000064],
            [-80.924607975999947, 34.985191687000054],
            [-80.924404488999983, 34.98555977500007],
            [-80.924208345999944, 34.985930554000049],
            [-80.924019600999941, 34.986303925000072],
            [-80.923838302999968, 34.98667978800006],
            [-80.923664500999962, 34.987058042000058],
            [-80.923498242999983, 34.987438585000064],
            [-80.923339572999964, 34.987821317000055],
            [-80.923188532999973, 34.988206134000052],
            [-80.922677764999946, 34.988991798000029],
            [-80.922489266999946, 34.989407205000077],
            [-80.92224534099995, 34.989955331000033],
            [-80.922080523999966, 34.990334450000034],
            [-80.921824636999986, 34.990942071000063],
            [-80.921520278999935, 34.991690110000036],
            [-80.921295864999934, 34.992260632000068],
            [-80.92115780599994, 34.992625372000077],
            [-80.920954248999976, 34.99316458900006],
            [-80.920739527999956, 34.993758283000034],
            [-80.920576807999964, 34.994223247000036],
            [-80.920346638999945, 34.994902737000075],
            [-80.920057512999961, 34.995801632000052],
            [-80.91983171399994, 34.996543493000047],
            [-80.919692063999946, 34.997016554000027],
            [-80.919586849999973, 34.997385188000067],
            [-80.919401289999939, 34.998069961000056],
            [-80.919222458999968, 34.99876254600008],
            [-80.919069228999945, 34.999387780000063],
            [-80.918903143999955, 35.000104423000039],
            [-80.918680105999954, 35.001126957000054],
            [-80.918566599999963, 35.001676992000057],
            [-80.918400151999947, 35.002523384000028],
            [-80.918262004999974, 35.003200975000027],
            [-80.918181446999938, 35.003600548000065],
            [-80.918018871999948, 35.004408010000077],
            [-80.91782152199994, 35.005361009000069],
            [-80.917613674999984, 35.006392078000033],
            [-80.917506508999963, 35.006909386000075],
            [-80.917402325999944, 35.007429349000063],
            [-80.917291449999937, 35.007966857000042],
            [-80.91715643799995, 35.008620551000035],
            [-80.91707650099994, 35.009016342000052],
            [-80.916974024999945, 35.009373958000026],
            [-80.916631535999954, 35.009879193000074],
            [-80.916301351999948, 35.010366306000037],
            [-80.915679956999952, 35.01131128600008],
            [-80.915376220999974, 35.011787130000073],
            [-80.914931374999981, 35.012506475000066],
            [-80.914665272999969, 35.012946294000074],
            [-80.91437024399994, 35.013447867000025],
            [-80.913952001999974, 35.014179003000038],
            [-80.913445820999982, 35.015099685000052],
            [-80.913159726999936, 35.015641499000026],
            [-80.912826719999941, 35.016293904000065],
            [-80.912545398999953, 35.01685924800006],
            [-80.912223005999977, 35.017527410000071],
            [-80.911839933999943, 35.018353149000063],
            [-80.911662249999949, 35.018749787000047],
            [-80.911302576999958, 35.019582771000046],
            [-80.91087952099997, 35.020611919000032],
            [-80.910544254999934, 35.021471443000053],
            [-80.910265826999989, 35.022220033000053],
            [-80.909990518999962, 35.023006420000058],
            [-80.909643181999968, 35.024053544000026],
            [-80.909347594999986, 35.025000937000073],
            [-80.909150936999936, 35.025681533000068],
            [-80.908950230999949, 35.026434433000077],
            [-80.908705877999978, 35.027408180000066],
            [-80.908540181999967, 35.028108616000054],
            [-80.90838410799995, 35.028808131000062],
            [-80.908268623999959, 35.02936690100006],
            [-80.908084154999983, 35.030345767000028],
            [-80.907956666999951, 35.031093742000053],
            [-80.907871850999982, 35.031631148000031],
            [-80.907737059999988, 35.03254706000007],
            [-80.907619015999956, 35.033404683000072],
            [-80.907521637999935, 35.034122045000061],
            [-80.907382233999954, 35.03519417800004],
            [-80.907332286999974, 35.035570793000034],
            [-80.90723337299994, 35.036323117000052],
            [-80.907147926999983, 35.03704216400007],
            [-80.907070558999976, 35.03759941800007],
            [-80.906978244999948, 35.038260705000027],
            [-80.90689297199998, 35.038832391000028],
            [-80.906827045999989, 35.039303867000058],
            [-80.906649930999947, 35.040666679000026],
            [-80.906522393999978, 35.041607608000049],
            [-80.906306088999941, 35.043217367000068],
            [-80.906232726999974, 35.043796593000025],
            [-80.906170094999936, 35.044231448000062],
            [-80.906036341999936, 35.045215645000042],
            [-80.905315738999946, 35.046149867000054],
            [-80.904856094999957, 35.046759683000062],
            [-80.903761956999972, 35.048246255000038],
            [-80.903378773999975, 35.048767303000034],
            [-80.901839088999964, 35.050835457000062],
            [-80.900325136999982, 35.052868923000062],
            [-80.899275694999972, 35.054255586000068],
            [-80.898905622999962, 35.054744562000053],
            [-80.898341092999942, 35.05549374900005],
            [-80.89762196099997, 35.056468944000073],
            [-80.897075757999971, 35.057211926000036],
            [-80.896739943999989, 35.057649878000063],
            [-80.896322322999936, 35.058199135000052],
            [-80.89578628299995, 35.058922191000079],
            [-80.895320846999937, 35.059524613000065],
            [-80.894644586999959, 35.06041739300008],
            [-80.894325354999978, 35.060846339000079],
            [-80.893298631999983, 35.062249447000056],
            [-80.892063503999964, 35.063857178000035],
            [-80.89100956599998, 35.065264009000032],
            [-80.889827471999979, 35.066872989000046],
            [-80.889222759999939, 35.06768407800007],
            [-80.888667040999962, 35.068439874000035],
            [-80.888111820999939, 35.069217045000073],
            [-80.887545087999968, 35.070034784000029],
            [-80.887194120999936, 35.070558629000061],
            [-80.886509817999979, 35.071614050000051],
            [-80.885998229999984, 35.072445479000066],
            [-80.885561008999957, 35.073198447000038],
            [-80.885288512999978, 35.07368187700007],
            [-80.885002258999975, 35.07419881800007],
            [-80.884646414999963, 35.074858445000075],
            [-80.884286517999954, 35.075564385000064],
            [-80.883789906999937, 35.076588646000062],
            [-80.883549495999944, 35.077103350000073],
            [-80.88336557599996, 35.077513586000066],
            [-80.883236991999979, 35.077805814000044],
            [-80.883093164999934, 35.078140251000036],
            [-80.882950585999936, 35.078477376000023],
            [-80.882763359999956, 35.078937490000044],
            [-80.882401422999976, 35.079861054000048],
            [-80.882147079999982, 35.080548775000068],
            [-80.881952405999982, 35.081110471000045],
            [-80.881661215999941, 35.081986879000056],
            [-80.881363309999983, 35.082939506000059],
            [-80.881180756999981, 35.083566273000031],
            [-80.88101484699996, 35.084178924000071],
            [-80.880791614999964, 35.085047862000067],
            [-80.880563668999969, 35.085982225000066],
            [-80.880396215999951, 35.086713638000049],
            [-80.880292201999964, 35.087212813000065],
            [-80.88012133899997, 35.088107042000047],
            [-80.880025655999987, 35.088654201000054],
            [-80.879830052999978, 35.089724897000053],
            [-80.879648040999939, 35.09081438000004],
            [-80.879072411999971, 35.091767796000056],
            [-80.878323677999958, 35.092861615000061],
            [-80.877877112999954, 35.09347032200003],
            [-80.877542571999982, 35.093926361000058],
            [-80.877010179999957, 35.094651947000045],
            [-80.87647553499994, 35.095366618000071],
            [-80.875836380999942, 35.096248479000053],
            [-80.875324872999954, 35.096997659000067],
            [-80.87460521099996, 35.098103391000052],
            [-80.874151732999962, 35.098832819000052],
            [-80.873840555999948, 35.099357123000061],
            [-80.873421839999935, 35.100067248000073],
            [-80.872845609999956, 35.101085028000057],
            [-80.872451524999974, 35.101808697000024],
            [-80.872255428999949, 35.102166360000069],
            [-80.872054504999937, 35.102535463000038],
            [-80.87184879199998, 35.102911520000077],
            [-80.871507225999949, 35.10355136700008],
            [-80.871242093999967, 35.103970600000025],
            [-80.870878865999941, 35.104510657000048],
            [-80.870147876999965, 35.105636335000042],
            [-80.869676538999954, 35.106319244000076],
            [-80.869417772999952, 35.106686525000043],
            [-80.868860377999965, 35.107490502000076],
            [-80.868396659999974, 35.108179133000078],
            [-80.868170006999947, 35.108514887000069],
            [-80.86720584599999, 35.109478153000055],
            [-80.865950716999976, 35.110640373000024],
            [-80.865090129999942, 35.111441494000076],
            [-80.864321056999984, 35.112148381000054],
            [-80.863919733999978, 35.112522718000037],
            [-80.863432147999958, 35.112984095000058],
            [-80.863132878999977, 35.113262832000032],
            [-80.862679327999956, 35.113672060000056],
            [-80.862156148999986, 35.114149518000033],
            [-80.861481322999964, 35.11477996800005],
            [-80.861073215999966, 35.115151788000048],
            [-80.860631806999947, 35.115552340000079],
            [-80.860086739999986, 35.116055842000037],
            [-80.859461710999938, 35.116643174000046],
            [-80.858941303999984, 35.117143273000067],
            [-80.858489296999949, 35.117553673000032],
            [-80.857990787999938, 35.118014154000036],
            [-80.857121456999948, 35.118832242000053],
            [-80.85648333499995, 35.119436567000037],
            [-80.855975635999982, 35.119916302000036],
            [-80.855037548999974, 35.120800744000064],
            [-80.854389836999985, 35.121451800000045],
            [-80.853554033999956, 35.122322573000076],
            [-80.852921905999949, 35.12300547500007],
            [-80.852617270999986, 35.123342895000064],
            [-80.851988848999952, 35.124057958000037],
            [-80.851303211999948, 35.124865740000075],
            [-80.851003184999968, 35.125229268000055],
            [-80.850547748999986, 35.125794337000059],
            [-80.850128583999947, 35.126330100000075],
            [-80.849607749999961, 35.127015785000026],
            [-80.849351215999945, 35.127360600000031],
            [-80.849123003999978, 35.127674811000077],
            [-80.848771485999976, 35.128167182000027],
            [-80.848519440999951, 35.128530542000078],
            [-80.848132174999989, 35.129103344000043],
            [-80.847792830999936, 35.129619889000026],
            [-80.84727892799998, 35.130428707000078],
            [-80.846946558999946, 35.130972395000072],
            [-80.846624060999943, 35.13152040500006],
            [-80.846215540999935, 35.132235173000026],
            [-80.845734902999936, 35.133109018000027],
            [-80.845447724999985, 35.133653308000078],
            [-80.845202602999962, 35.134137113000065],
            [-80.844855597999981, 35.134843077000028],
            [-80.844323758999963, 35.135979399000064],
            [-80.84409371199996, 35.136501890000034],
            [-80.843784526999968, 35.137239557000044],
            [-80.843495503999975, 35.137959223000053],
            [-80.843207043999939, 35.138710150000065],
            [-80.842963357999963, 35.139385825000033],
            [-80.842708597999945, 35.140136635000033],
            [-80.842552190999982, 35.140617801000076],
            [-80.842416535999973, 35.141030985000043],
            [-80.842286920999982, 35.141385124000067],
            [-80.842131219999942, 35.141814478000072],
            [-80.841946284999949, 35.142335819000039],
            [-80.841672675999973, 35.143077125000048],
            [-80.841525004999937, 35.143488953000031],
            [-80.841238100999988, 35.144308735000038],
            [-80.841081948999943, 35.144767146000049],
            [-80.840858266999987, 35.145432956000036],
            [-80.840596888999983, 35.146168388000035],
            [-80.840241463999973, 35.147189336000054],
            [-80.840007068999967, 35.147852913000065],
            [-80.839708500999961, 35.148738939000054],
            [-80.83947995699998, 35.149401675000036],
            [-80.839313893999986, 35.149864879000063],
            [-80.839117293999948, 35.150425860000041],
            [-80.838858157999937, 35.151181382000061],
            [-80.838620795999987, 35.151908215000049],
            [-80.838316655999961, 35.152897470000028],
            [-80.837594828999954, 35.155406477000042],
            [-80.837405955999941, 35.156035017000079],
            [-80.837201289999939, 35.156706177000046],
            [-80.83704559499995, 35.157247344000041],
            [-80.836578021999969, 35.15885193400004],
            [-80.836452512999983, 35.159295696000072],
            [-80.836197843999969, 35.160223590000044],
            [-80.83585956099995, 35.161382644000071],
            [-80.835520364999979, 35.162560279000047],
            [-80.835281168999984, 35.163385590000075],
            [-80.835056527999939, 35.164161573000058],
            [-80.834884909999971, 35.164750360000028],
            [-80.834681478999983, 35.165462309000077],
            [-80.834501580999984, 35.166119923000053],
            [-80.834323476999941, 35.166810618000056],
            [-80.834213357999943, 35.167263196000079],
            [-80.834088262999956, 35.167804229000069],
            [-80.834016735999967, 35.168126491000066],
            [-80.833931121999967, 35.168534854000029],
            [-80.833821139999941, 35.169087855000043],
            [-80.833733522999978, 35.169557728000029],
            [-80.833704354999952, 35.170517227000062],
            [-80.833633364999969, 35.170772549000048],
            [-80.833569762999957, 35.171029181000051],
            [-80.833513584999935, 35.171286979000058],
            [-80.833464861999971, 35.171545797000078],
            [-80.833423622999987, 35.171805491000043],
            [-80.833389888999989, 35.172065914000029],
            [-80.83336368099998, 35.172326921000035],
            [-80.83331941299997, 35.172752870000068],
            [-80.833253204999949, 35.173303646000079],
            [-80.833202451999966, 35.17372262300006],
            [-80.833157171999972, 35.174193476000028],
            [-80.83313018399997, 35.174747847000049],
            [-80.83310832799998, 35.175511072000063],
            [-80.833118960999968, 35.176275377000024],
            [-80.83314844299997, 35.17650709600008],
            [-80.833170540999959, 35.176739369000074],
            [-80.833185237999942, 35.176972037000041],
            [-80.833192524999959, 35.17720494100007],
            [-80.833192396999948, 35.177437921000035],
            [-80.833184852999977, 35.17767081900007],
            [-80.833169898999984, 35.17790347600004],
            [-80.833147544999974, 35.178135733000033],
            [-80.83319206699997, 35.178570594000064],
            [-80.833258314999966, 35.179380627000057],
            [-80.833155301999966, 35.179998229000034],
            [-80.833194814999956, 35.180524324000032],
            [-80.83323163099999, 35.180949551000026],
            [-80.833281672999988, 35.181465026000069],
            [-80.833287621999943, 35.181884800000034],
            [-80.832805174999976, 35.182891206000079],
            [-80.832342421999954, 35.183478341000068],
            [-80.831826847999935, 35.184136760000058],
            [-80.831029503999957, 35.185206246000064],
            [-80.830556162999983, 35.185867335000069],
            [-80.830202306999979, 35.186382437000077],
            [-80.829871969999942, 35.186873920000039],
            [-80.829443608999952, 35.187526638000065],
            [-80.828940855999974, 35.188317780000034],
            [-80.82864228699998, 35.188804283000024],
            [-80.828389376999951, 35.18923056400007],
            [-80.828056792999973, 35.189804000000038],
            [-80.82759055799994, 35.190631575000054],
            [-80.827309475999982, 35.191147839000052],
            [-80.826981990999968, 35.191758352000079],
            [-80.826641093999967, 35.192401687000029],
            [-80.826345365999941, 35.19296212900008],
            [-80.826060705999964, 35.193488509000076],
            [-80.82579852799995, 35.193976431000067],
            [-80.825365857999941, 35.194798185000025],
            [-80.825042986999961, 35.195407714000055],
            [-80.824581942999941, 35.196263411000075],
            [-80.824045208999962, 35.19670942700003],
            [-80.823706111999968, 35.196996564000074],
            [-80.823151049999979, 35.197480247000044],
            [-80.822311002999982, 35.198237973000062],
            [-80.821865917999958, 35.198645704000057],
            [-80.821388849999948, 35.199094152000043],
            [-80.820932429999971, 35.199535528000069],
            [-80.820361808999962, 35.200110003000077],
            [-80.819527156999982, 35.200981322000075],
            [-80.819061115999943, 35.201475043000073],
            [-80.818700554999964, 35.201866684000038],
            [-80.818076877999943, 35.20257312800004],
            [-80.81720545099995, 35.203603017000034],
            [-80.816600104999964, 35.20435090400008],
            [-80.816158607999967, 35.204923131000044],
            [-80.815769426999964, 35.205440008000039],
            [-80.815303467999968, 35.206075198000065],
            [-80.814911471999949, 35.206623662000027],
            [-80.814530167999976, 35.207174468000062],
            [-80.814072931999988, 35.207857036000064],
            [-80.81372485299994, 35.208392355000058],
            [-80.813440562999972, 35.208841145000065],
            [-80.813260419999949, 35.209131257000024],
            [-80.812646064999967, 35.210089664000066],
            [-80.812057911999943, 35.21054149400004],
            [-80.811261785999989, 35.211140009000076],
            [-80.810665920999952, 35.211601873000063],
            [-80.80987866199996, 35.212236545000053],
            [-80.809448801999963, 35.212594324000065],
            [-80.808865983999965, 35.213090512000065],
            [-80.80845560399996, 35.213447573000053],
            [-80.807897434999973, 35.21395153800006],
            [-80.806887126999982, 35.214892295000027],
            [-80.806499197999983, 35.215261780000048],
            [-80.806096044999947, 35.215664261000029],
            [-80.805514035999977, 35.216259686000058],
            [-80.805098889999954, 35.216695288000039],
            [-80.804583873999945, 35.217252071000075],
            [-80.803990364999947, 35.217922608000038],
            [-80.803176852999968, 35.218879493000031],
            [-80.802695282999935, 35.219465491000051],
            [-80.802353367999956, 35.219900109000037],
            [-80.801886062999984, 35.220509314000026],
            [-80.801539644999934, 35.220973251000032],
            [-80.801017412999954, 35.221696964000046],
            [-80.800711219999982, 35.222137949000057],
            [-80.800331189999952, 35.222700821000046],
            [-80.800022583999976, 35.22316931000006],
            [-80.799740575999976, 35.223606897000025],
            [-80.799407081999959, 35.22413668400003],
            [-80.798911094999937, 35.224967449000076],
            [-80.798268146999987, 35.226104938000049],
            [-80.797899067999936, 35.226788415000044],
            [-80.797731995999982, 35.227113757000041],
            [-80.797506470999963, 35.227567011000076],
            [-80.797160468999948, 35.22828479900005],
            [-80.796872900999972, 35.22890629300008],
            [-80.796629591999988, 35.229456175000053],
            [-80.796339415999967, 35.230142411000031],
            [-80.796133162999979, 35.230654250000043],
            [-80.795860262999952, 35.231360038000048],
            [-80.795680660999949, 35.231843353000045],
            [-80.795433435999939, 35.232558575000041],
            [-80.795060759999956, 35.233708444000058],
            [-80.794744243999958, 35.234797019000041],
            [-80.794479784999965, 35.235822734000067],
            [-80.794286309999961, 35.236613159000058],
            [-80.79394195499998, 35.237455407000027],
            [-80.793630388999986, 35.238153935000071],
            [-80.793367135999972, 35.238730561000068],
            [-80.793105602999958, 35.239315391000048],
            [-80.792892141999971, 35.23978687500005],
            [-80.792714846999957, 35.24017525000005],
            [-80.792519895999988, 35.240611749000038],
            [-80.792245756999989, 35.241215938000039],
            [-80.791807272999961, 35.242146578000074],
            [-80.791631973999984, 35.242529268000055],
            [-80.791176568999958, 35.243555516000072],
            [-80.790983714999982, 35.244005407000031],
            [-80.790532484999972, 35.245103762000042],
            [-80.79025526099997, 35.245816811000054],
            [-80.790057451999985, 35.246346456000026],
            [-80.789920045999963, 35.246727669000052],
            [-80.789798856999937, 35.247075799000072],
            [-80.789560446999985, 35.247787434000031],
            [-80.789417654999966, 35.248235603000069],
            [-80.789250971999934, 35.248781233000045],
            [-80.789119623999966, 35.249230121000039],
            [-80.788991768999949, 35.249691631000076],
            [-80.788827728999934, 35.250311999000076],
            [-80.788705010999934, 35.250802078000049],
            [-80.78844799999996, 35.251930815000037],
            [-80.788316348999956, 35.252576943000065],
            [-80.788198096999963, 35.253207520000046],
            [-80.788113329999987, 35.253694242000051],
            [-80.787977524999974, 35.254565435000075],
            [-80.787906833999955, 35.255078182000034],
            [-80.787827153999956, 35.255719514000077],
            [-80.787782229999948, 35.256115685000054],
            [-80.787745474999952, 35.256485546000079],
            [-80.787692800999935, 35.257075067000073],
            [-80.787663137999971, 35.257449456000074],
            [-80.787637165999968, 35.257847470000058],
            [-80.787602135999975, 35.258490490000042],
            [-80.787584773999981, 35.258887562000041],
            [-80.787573377999934, 35.259275940000066],
            [-80.787561581999967, 35.259901220000074],
            [-80.787558306999983, 35.260292999000058],
            [-80.78755887799997, 35.260601263000069],
            [-80.787566459999937, 35.261230086000069],
            [-80.787583300999984, 35.26180363800006],
            [-80.787619957999937, 35.262620067000057],
            [-80.787694141999964, 35.263640867000049],
            [-80.787763846999951, 35.264439321000054],
            [-80.787813185999937, 35.264925492000032],
            [-80.787905871999953, 35.265696511000044],
            [-80.788040322999962, 35.26660283700005],
            [-80.788256779999983, 35.267844055000069],
            [-80.788443442999949, 35.268773190000047],
            [-80.78860191299998, 35.269475477000071],
            [-80.788717825999981, 35.269958540000061],
            [-80.788879743999985, 35.270598203000077],
            [-80.789017211999976, 35.271112856000059],
            [-80.789171450999959, 35.271657502000039],
            [-80.789368631999935, 35.272314281000035],
            [-80.789532179999981, 35.272828656000058],
            [-80.789763834999974, 35.273523667000063],
            [-80.789929093999945, 35.27399957800003],
            [-80.79006702099997, 35.274378794000029],
            [-80.79034355999994, 35.275109852000071],
            [-80.790551821999941, 35.275632881000035],
            [-80.790840366999987, 35.276328486000068],
            [-80.791063272999963, 35.276845871000035],
            [-80.791311939999957, 35.277398952000055],
            [-80.791655565999974, 35.278132209000034],
            [-80.791909942999951, 35.278653317000078],
            [-80.792033320999963, 35.279258063000043],
            [-80.792113601999972, 35.279811000000052],
            [-80.792189061999977, 35.280282168000042],
            [-80.79230292799997, 35.280940886000053],
            [-80.792391969999983, 35.28141989900007],
            [-80.792520459999935, 35.282057016000067],
            [-80.792642658999966, 35.282616492000045],
            [-80.792743204999965, 35.283049257000073],
            [-80.792916938999952, 35.28380134400004],
            [-80.793025236999938, 35.284429332000059],
            [-80.793222195999988, 35.285469014000057],
            [-80.793366237999976, 35.286133103000054],
            [-80.793593400999953, 35.287085009000066],
            [-80.79374788399997, 35.287688107000065],
            [-80.79390701899996, 35.288264777000052],
            [-80.794059903999937, 35.288789156000064],
            [-80.794278262999967, 35.289501850000079],
            [-80.79441100799994, 35.289918020000073],
            [-80.794552137999972, 35.290339931000062],
            [-80.794752291999941, 35.290934840000034],
            [-80.794874030999949, 35.29128251800006],
            [-80.795004868999968, 35.291644053000027],
            [-80.795236012999965, 35.29226000500006],
            [-80.795397922999939, 35.292674293000061],
            [-80.79561807999994, 35.293220164000047],
            [-80.795777816999987, 35.293604797000057],
            [-80.796002531999989, 35.294126028000051],
            [-80.79633245499997, 35.29485744200008],
            [-80.796614706999947, 35.295469081000078],
            [-80.796516477999944, 35.296512628000073],
            [-80.796370433999982, 35.297149674000025],
            [-80.796204300999989, 35.297885093000048],
            [-80.796040663999975, 35.298555094000051],
            [-80.795927426999981, 35.299036362000038],
            [-80.795801641999958, 35.299594839000065],
            [-80.795715279999968, 35.299998455000036],
            [-80.795588914999939, 35.300629411000045],
            [-80.795487647999948, 35.301184897000041],
            [-80.795403750999981, 35.301658083000063],
            [-80.795313388999944, 35.30210988400006],
            [-80.795154393999951, 35.302944645000025],
            [-80.79500447099997, 35.303801070000077],
            [-80.794872407999947, 35.304626595000059],
            [-80.794795952999948, 35.305119385000069],
            [-80.794700638999984, 35.30579491900005],
            [-80.794632706999948, 35.306281823000063],
            [-80.794542941999964, 35.306995331000053],
            [-80.794468502999962, 35.307669010000041],
            [-80.794582840999965, 35.308568019000063],
            [-80.794405179999956, 35.309173689000033],
            [-80.794376703999944, 35.309481731000062],
            [-80.79435633199995, 35.309790204000024],
            [-80.79434407499997, 35.310098966000055],
            [-80.794339936999961, 35.310407872000042],
            [-80.79434392099995, 35.310716780000064],
            [-80.794308090999948, 35.311164155000029],
            [-80.794227038999963, 35.312282251000056],
            [-80.794184691999988, 35.313199144000066],
            [-80.79419590599997, 35.313752187000034],
            [-80.794226661999971, 35.31420487400004],
            [-80.794278493999968, 35.31491569800005],
            [-80.794336605999945, 35.315823886000032],
            [-80.794349954999973, 35.316727921000052],
            [-80.794402816999934, 35.317161808000037],
            [-80.794312325999954, 35.318137570000033],
            [-80.794352390999961, 35.318918778000068],
            [-80.794397390999961, 35.31961850700003],
            [-80.79443882999999, 35.320341357000075],
            [-80.794458630999941, 35.320664419000025],
            [-80.794478983999966, 35.321059655000056],
            [-80.794501985999943, 35.321455031000028],
            [-80.794532815999958, 35.321936268000059],
            [-80.794563214999982, 35.32258054700003],
            [-80.794628571999965, 35.323583410000026],
            [-80.794665949999967, 35.324281307000035],
            [-80.794692411999961, 35.324750923000067],
            [-80.794717107999986, 35.325124902000027],
            [-80.794747625999946, 35.325607756000068],
            [-80.794784745999948, 35.326320431000056],
            [-80.794820609999988, 35.326922153000055],
            [-80.794858750999936, 35.327638901000057],
            [-80.794896920999975, 35.32821188500003],
            [-80.794929826999976, 35.328756296000051],
            [-80.794964793999952, 35.329394562000061],
            [-80.794988822999983, 35.329793712000026],
            [-80.79501156699996, 35.330187982000041],
            [-80.795042747999958, 35.330672143000072],
            [-80.795063001999949, 35.331025465000039],
            [-80.795089318999942, 35.331493667000075],
            [-80.795116979999989, 35.332132710000053],
            [-80.795132277999983, 35.332446727000047],
            [-80.795159651999938, 35.333003443000052],
            [-80.795205195999984, 35.333775761000027],
            [-80.795246426999938, 35.334491286000059],
            [-80.795275851999975, 35.335076723000043],
            [-80.795327589999943, 35.336012857000071],
            [-80.795363693999946, 35.336582484000076],
            [-80.795405161999952, 35.33719411900006],
            [-80.795446690999938, 35.33799613900004],
            [-80.795461940999985, 35.338436286000046],
            [-80.79518130799994, 35.33913629400007],
            [-80.793643352999936, 35.342500414000028],
            [-80.79344206199994, 35.342955505000077],
            [-80.793209483999988, 35.343500849000066],
            [-80.792776468999989, 35.344546276000074],
            [-80.79234644099995, 35.345591979000062],
            [-80.791977650999968, 35.346534450000036],
            [-80.791783059999943, 35.347056866000059],
            [-80.791496926999969, 35.347856363000062],
            [-80.791306434999967, 35.348416604000079],
            [-80.790943909999953, 35.34954069500003],
            [-80.790766057999974, 35.350124755000024],
            [-80.790598281999962, 35.35071008400007],
            [-80.79043917599995, 35.351302101000044],
            [-80.790141534999975, 35.35248802600006],
            [-80.789977543999953, 35.353201639000076],
            [-80.789792638999984, 35.354087012000036],
            [-80.789696465999953, 35.354583221000041],
            [-80.789518126999951, 35.355577345000029],
            [-80.78943596299996, 35.356075260000068],
            [-80.789281388999939, 35.357102389000033],
            [-80.789224395999952, 35.357507320000025],
            [-80.789145204999954, 35.358124188000033],
            [-80.78907954899995, 35.35868406000003],
            [-80.78902093499994, 35.359200084000065],
            [-80.788937418999978, 35.360035977000052],
            [-80.788871790999963, 35.360809538000069],
            [-80.78882858999998, 35.361434233000068],
            [-80.788790602999939, 35.362155898000026],
            [-80.788774049999972, 35.362554316000057],
            [-80.788761802999943, 35.362962676000052],
            [-80.788747488999945, 35.363694124000062],
            [-80.788745351999978, 35.364062665000063],
            [-80.788750015999938, 35.364608788000055],
            [-80.788756072999945, 35.364944614000024],
            [-80.788775722999958, 35.365730403000043],
            [-80.788819276999959, 35.366750979000074],
            [-80.788914996999949, 35.367961131000072],
            [-80.789020172999983, 35.368905128000051],
            [-80.78915260499997, 35.370047018000037],
            [-80.789303348999965, 35.371117000000027],
            [-80.789536904999977, 35.37245800200003],
            [-80.789921815999946, 35.374427426000068],
            [-80.790060498999935, 35.375069040000028],
            [-80.790303748999975, 35.376093810000043],
            [-80.790613426999982, 35.377295090000075],
            [-80.79075161999998, 35.377806108000073],
            [-80.790992494999955, 35.378618754000058],
            [-80.791382164999959, 35.379831212000056],
            [-80.79176294399997, 35.380903384000078],
            [-80.792126301999986, 35.381836474000067],
            [-80.792355113999974, 35.38239830200007],
            [-80.792761931999962, 35.38336457500003],
            [-80.792974826999966, 35.383858940000039],
            [-80.793207995999978, 35.384383669000044],
            [-80.793563730999949, 35.385156901000073],
            [-80.793895770999939, 35.385853668000038],
            [-80.794185504999973, 35.386444538000035],
            [-80.794442757999946, 35.386952984000061],
            [-80.794712981999965, 35.38747255100003],
            [-80.794938041999956, 35.387892648000047],
            [-80.795256978999987, 35.388471930000037],
            [-80.795520219999958, 35.388946392000037],
            [-80.795813430999942, 35.389458926000032],
            [-80.796083407999959, 35.389920280000069],
            [-80.796311191999962, 35.39030587700006],
            [-80.796511253999938, 35.390644563000023],
            [-80.796578257999954, 35.391027339000061],
            [-80.796640482999976, 35.391443605000063],
            [-80.796710941999947, 35.39188848200007],
            [-80.796824870999956, 35.392559272000028],
            [-80.796927690999951, 35.39315390400003],
            [-80.79702317999994, 35.39367474900007],
            [-80.797169783999948, 35.394418785000028],
            [-80.797325442999977, 35.395112513000072],
            [-80.797614525999961, 35.396288936000076],
            [-80.797796342999959, 35.396972898000058],
            [-80.798008468999967, 35.397745899000029],
            [-80.798222299999964, 35.398478197000031],
            [-80.79852163299995, 35.39943293500005],
            [-80.798677905999966, 35.399908910000079],
            [-80.798947173999977, 35.400692706000029],
            [-80.799110760999952, 35.401155475000053],
            [-80.799302388999934, 35.401676847000033],
            [-80.79962528599998, 35.40252124400007],
            [-80.80007496099995, 35.403631226000073],
            [-80.800339691999966, 35.404248020000068],
            [-80.800713271999939, 35.405064762000052],
            [-80.801408274999972, 35.406523843000059],
            [-80.801780694999934, 35.407275907000042],
            [-80.802165263999939, 35.408010259000037],
            [-80.802641395999956, 35.408875218000048],
            [-80.802885222999976, 35.409305566000057],
            [-80.803330581999944, 35.410069042000032],
            [-80.80362930199999, 35.410569783000028],
            [-80.804061857999955, 35.41127171800008],
            [-80.804302616999962, 35.412290022000036],
            [-80.804786106999984, 35.415226039000061],
            [-80.805075614999964, 35.416842742000028],
            [-80.805165254999963, 35.417324334000057],
            [-80.80528604899996, 35.417993250000052],
            [-80.805369155999983, 35.418425108000065],
            [-80.805464461999975, 35.418951158000027],
            [-80.80556594799998, 35.419487068000024],
            [-80.805732417999934, 35.420307921000074],
            [-80.805876148999971, 35.420960754000077],
            [-80.805985183999951, 35.421439618000079],
            [-80.806099188999951, 35.42191064900004],
            [-80.80633987899995, 35.422850536000055],
            [-80.806538049999972, 35.423566489000052],
            [-80.806657104999942, 35.423973224000065],
            [-80.806851860999984, 35.424626117000059],
            [-80.807038725999973, 35.425222747000078],
            [-80.807246612999961, 35.42586130400008],
            [-80.807478189999983, 35.426547002000063],
            [-80.807736593999948, 35.427288752000038],
            [-80.807923984999945, 35.427806650000036],
            [-80.808180812999979, 35.428498138000066],
            [-80.808405709999988, 35.429080111000076],
            [-80.808568399999956, 35.429488058000061],
            [-80.808789815999944, 35.430031488000054],
            [-80.809072785999945, 35.430706438000072],
            [-80.809281347999956, 35.431184936000079],
            [-80.809503323999934, 35.43168614800004],
            [-80.809744415999944, 35.432218959000068],
            [-80.809909138999956, 35.432575664000069],
            [-80.810287198999958, 35.433380123000063],
            [-80.810496074999946, 35.433811797000033],
            [-80.810743086999935, 35.434309864000056],
            [-80.811051956999961, 35.434920868000063],
            [-80.811432638999975, 35.435652813000047],
            [-80.811792643999979, 35.436333206000029],
            [-80.812059188999967, 35.436832882000033],
            [-80.812342875999946, 35.437367154000071],
            [-80.812608824999984, 35.43786532300004],
            [-80.812941639999963, 35.438482749000059],
            [-80.813248809999948, 35.439053381000065],
            [-80.813466451999943, 35.439458191000028],
            [-80.814121222999972, 35.440662532000033],
            [-80.815364912999939, 35.442790591000062],
            [-80.815305165999973, 35.443332117000068],
            [-80.815307896999968, 35.44378516900008],
            [-80.815273911999952, 35.444461794000063],
            [-80.815287275999935, 35.445396599000048],
            [-80.815348222999944, 35.446126444000072],
            [-80.81541858099996, 35.446947113000078],
            [-80.815458025999988, 35.447673816000076],
            [-80.815590032999978, 35.448560775000033],
            [-80.815669501999935, 35.449282493000055],
            [-80.815614890999939, 35.449916633000043],
            [-80.815697887999988, 35.450595544000066],
            [-80.815745113999981, 35.45094874800003],
            [-80.815830093999978, 35.451537997000059],
            [-80.815982490999943, 35.452491959000042],
            [-80.816189159999965, 35.453683316000024],
            [-80.816369331999965, 35.45462550600007],
            [-80.816476242999954, 35.455142207000051],
            [-80.816606362999948, 35.455712394000045],
            [-80.816893753999977, 35.456884613000057],
            [-80.817047320999961, 35.457469185000036],
            [-80.817251501999976, 35.458195759000034],
            [-80.817512201999989, 35.459091116000025],
            [-80.817780314999936, 35.459966962000067],
            [-80.818072322999967, 35.460863393000068],
            [-80.818312276999961, 35.461561330000052],
            [-80.81855154699997, 35.462222305000068],
            [-80.818869439999958, 35.463051199000063],
            [-80.819067725999957, 35.463551052000071],
            [-80.819255168999973, 35.464020961000074],
            [-80.819562811999958, 35.464764657000046],
            [-80.819723106999959, 35.46514077300003],
            [-80.819885829999976, 35.465511507000031],
            [-80.820073483999977, 35.46594268900003],
            [-80.820286075999945, 35.466415509000058],
            [-80.820763714999941, 35.467434419000028],
            [-80.821256999999946, 35.468438025000069],
            [-80.821509849999984, 35.468948699000066],
            [-80.821829210999965, 35.469625694000058],
            [-80.821832877999952, 35.470055691000027],
            [-80.821787018999942, 35.470863454000039],
            [-80.821721886999967, 35.472100562000037],
            [-80.821698871999956, 35.472637644000031],
            [-80.821680069999957, 35.473197592000076],
            [-80.82166592599998, 35.473568234000027],
            [-80.821648241999981, 35.474195862000045],
            [-80.821636989999945, 35.474542310000061],
            [-80.821626619999961, 35.474940999000069],
            [-80.821614427999975, 35.475331554000036],
            [-80.821597555999972, 35.476151161000075],
            [-80.821591324999986, 35.477084421000029],
            [-80.821362486999988, 35.477695415000028],
            [-80.821099084999958, 35.478198306000024],
            [-80.820851516999937, 35.478684603000033],
            [-80.820461368999986, 35.479469047000066],
            [-80.820193555999936, 35.480012649000059],
            [-80.819765275999941, 35.480875836000052],
            [-80.819468926999946, 35.481475673000034],
            [-80.819203904999938, 35.481997031000049],
            [-80.818799984999941, 35.482804201000079],
            [-80.818546164999987, 35.483316586000058],
            [-80.818162398999959, 35.484084268000061],
            [-80.81790416299998, 35.484590284000035],
            [-80.817651611999963, 35.485096110000029],
            [-80.81746074199998, 35.485489390000055],
            [-80.817041929999959, 35.486368241000037],
            [-80.816759629999979, 35.486991991000025],
            [-80.816381515999979, 35.487885578000032],
            [-80.81619937399995, 35.488334062000035],
            [-80.816071416999989, 35.488655128000062],
            [-80.815867775999948, 35.489177432000076],
            [-80.815704729999936, 35.489609162000079],
            [-80.815528684999947, 35.490094809000027],
            [-80.815347217999943, 35.490615723000076],
            [-80.815222404999986, 35.490985498000043],
            [-80.815107874999967, 35.491335933000073],
            [-80.815011668999944, 35.491635919000032],
            [-80.814805507999949, 35.492299832000072],
            [-80.814683226999989, 35.492709650000052],
            [-80.814530679999962, 35.49325880300006],
            [-80.814286330999948, 35.494193149000068],
            [-80.814135484999952, 35.49481197800003],
            [-80.813980938999975, 35.495504357000073],
            [-80.813834801999974, 35.496210317000077],
            [-80.813700911999945, 35.496900250000067],
            [-80.813629528999968, 35.497295981000036],
            [-80.813546648999989, 35.49778394100008],
            [-80.813489138999955, 35.498142785000027],
            [-80.813441293999972, 35.498459205000074],
            [-80.813369828999953, 35.49899023200004],
            [-80.813267677999988, 35.49984575700006],
            [-80.813192525999966, 35.500582940000072],
            [-80.81310168899995, 35.501655482000047],
            [-80.813041363999957, 35.502434665000067],
            [-80.812965462999955, 35.503242039000042],
            [-80.812928054999986, 35.503732631000048],
            [-80.812860669999964, 35.504748708000079],
            [-80.812824541999987, 35.505296850000036],
            [-80.812721008999972, 35.50666196700007],
            [-80.812624824999943, 35.507939689000068],
            [-80.812522653999963, 35.509334458000069],
            [-80.812488160999976, 35.509914995000031],
            [-80.812453657999981, 35.510464636000052],
            [-80.812401797999939, 35.510951127000055],
            [-80.812373449999939, 35.511341601000026],
            [-80.812229207999962, 35.512091023000039],
            [-80.812086495999949, 35.512750415000028],
            [-80.811959062999961, 35.513321636000057],
            [-80.811855912999988, 35.51379002200008],
            [-80.811757499999942, 35.514232230000061],
            [-80.81164810599995, 35.514717828000073],
            [-80.811522734999983, 35.515290096000058],
            [-80.811439804999964, 35.515660675000049],
            [-80.811323093999988, 35.516191316000061],
            [-80.81120604299997, 35.51671943000008],
            [-80.811101286999985, 35.517193410000061],
            [-80.811002945999974, 35.517632565000042],
            [-80.810894664999978, 35.518131906000065],
            [-80.810807900999976, 35.518521987000042],
            [-80.810694976999969, 35.519023316000073],
            [-80.810507281999946, 35.519870516000026],
            [-80.810414923999986, 35.520285125000044],
            [-80.810256724999988, 35.520998501000065],
            [-80.810129243999938, 35.521577416000071],
            [-80.810051252999983, 35.521937682000043],
            [-80.809960825999951, 35.522347555000067],
            [-80.809855927999934, 35.522819279000032],
            [-80.809754182999939, 35.52328803100005],
            [-80.809672780999961, 35.52365295900006],
            [-80.80958037399995, 35.524073168000029],
            [-80.80937374399997, 35.524961137000048],
            [-80.809172782999951, 35.525797524000041],
            [-80.809050404999937, 35.526351951000038],
            [-80.808758500999943, 35.527690179000047],
            [-80.808674716999974, 35.528023129000076],
            [-80.80854487299996, 35.528554548000045],
            [-80.808436205999953, 35.529021597000053],
            [-80.808310089999964, 35.529589257000055],
            [-80.808212346999937, 35.530044063000048],
            [-80.808129573999963, 35.530433724000034],
            [-80.807945858999972, 35.53129892700008],
            [-80.807769991999976, 35.53215696400008],
            [-80.807684327999937, 35.532569095000042],
            [-80.807576876999974, 35.533045387000072],
            [-80.807455807999986, 35.533588411000039],
            [-80.807311377999952, 35.534233091000033],
            [-80.807196053999974, 35.534765976000074],
            [-80.807008438999958, 35.53560212900004],
            [-80.806834901999935, 35.536367655000049],
            [-80.806703791999951, 35.536970500000052],
            [-80.806597039999986, 35.537440343000071],
            [-80.806488244999969, 35.537928157000067],
            [-80.806366665999974, 35.538491990000068],
            [-80.806150656999989, 35.539458471000046],
            [-80.806042738999963, 35.539967599000079],
            [-80.805915719999973, 35.540543832000026],
            [-80.805782760999989, 35.541127625000058],
            [-80.805638316999989, 35.541774401000055],
            [-80.805520004999948, 35.542337682000039],
            [-80.805414515999985, 35.542868657000042],
            [-80.805325022999966, 35.543343619000041],
            [-80.805243476999976, 35.543808092000063],
            [-80.805135783999958, 35.544468528000039],
            [-80.80504555999994, 35.545087851000062],
            [-80.804981003999956, 35.545524382000053],
            [-80.804914015999941, 35.546005862000072],
            [-80.804844100999958, 35.54654793900005],
            [-80.804771276999986, 35.547157236000032],
            [-80.80471584199995, 35.547668334000036],
            [-80.804664241999944, 35.548194609000063],
            [-80.804602151999973, 35.548928053000054],
            [-80.804557554999974, 35.549566340000069],
            [-80.804518787999939, 35.550193310000054],
            [-80.80449845399994, 35.550569447000044],
            [-80.804476893999947, 35.551044310000066],
            [-80.804459752999946, 35.551527010000029],
            [-80.804444399999966, 35.552112932000057],
            [-80.804433385999971, 35.552930162000052],
            [-80.804439848999948, 35.553809612000066],
            [-80.804475784999966, 35.554950616000042],
            [-80.804506767999953, 35.55569637900004],
            [-80.804536553999981, 35.55624651200003],
            [-80.804563523999946, 35.556676393000032],
            [-80.80459012099999, 35.55705159300004],
            [-80.804624753999974, 35.557491729000049],
            [-80.804676275999952, 35.55806861700006],
            [-80.80473232199995, 35.558633444000066],
            [-80.804774264999935, 35.559017955000058],
            [-80.805036732999952, 35.559311949000062],
            [-80.804946453999946, 35.559895152000024],
            [-80.804915498999947, 35.56041966600003],
            [-80.804845342999954, 35.560954181000056],
            [-80.804587967999964, 35.561495378000075],
            [-80.804430770999943, 35.562056073000065],
            [-80.804241024999953, 35.562753572000076],
            [-80.804108275999965, 35.563253133000046],
            [-80.803961197999968, 35.563808771000026],
            [-80.80378765599994, 35.564497108000069],
            [-80.803604255999971, 35.56527300700003],
            [-80.803473810999947, 35.565861003000066],
            [-80.803326632999983, 35.566574517000049],
            [-80.803234701999941, 35.567049578000024],
            [-80.80313134499994, 35.567619183000033],
            [-80.803000736999934, 35.568396678000056],
            [-80.802881934999959, 35.569178431000068],
            [-80.802796412999953, 35.569799365000051],
            [-80.80273355199995, 35.570302759000072],
            [-80.802666866999971, 35.57088893100007],
            [-80.802606111999978, 35.571488596000052],
            [-80.802552632999948, 35.572090030000027],
            [-80.802518869999972, 35.572519778000071],
            [-80.802489729999934, 35.572943119000058],
            [-80.802460698999937, 35.573417253000059],
            [-80.80243511599997, 35.573916952000047],
            [-80.802410162999934, 35.574551645000042],
            [-80.80239336599999, 35.575219381000068],
            [-80.802385892999951, 35.575927014000058],
            [-80.802387834999934, 35.576513421000072],
            [-80.802396036999937, 35.577052452000032],
            [-80.802411577999976, 35.577670854000075],
            [-80.802433919999942, 35.578262571000039],
            [-80.802463580999984, 35.578854855000031],
            [-80.802500730999952, 35.579447734000041],
            [-80.802550710999981, 35.580106923000073],
            [-80.802599143999942, 35.580660635000072],
            [-80.802660381999942, 35.581272182000077],
            [-80.802734033999968, 35.581920331000049],
            [-80.802816102999941, 35.582563163000032],
            [-80.802889479999976, 35.583084558000053],
            [-80.802987636999944, 35.583725712000046],
            [-80.803089339999985, 35.58434429600004],
            [-80.803196170999968, 35.584946487000025],
            [-80.80330536699995, 35.585519834000024],
            [-80.803429470999959, 35.586129415000073],
            [-80.80354244199998, 35.58665183100004],
            [-80.803657989999977, 35.587159030000066],
            [-80.803752949999989, 35.587557950000075],
            [-80.803869426999938, 35.588031220000062],
            [-80.804047736999962, 35.588724149000029],
            [-80.804198027999973, 35.589300342000058],
            [-80.804295928999977, 35.589667443000053],
            [-80.804490595999937, 35.590411084000038],
            [-80.804637727999989, 35.590966819000073],
            [-80.804747025999973, 35.591382600000031],
            [-80.804851359999986, 35.591775763000044],
            [-80.804943639999976, 35.592123495000067],
            [-80.805070282999964, 35.592603721000046],
            [-80.805208044999972, 35.593120618000057],
            [-80.805326263999973, 35.59356904100008],
            [-80.805467866999948, 35.594098966000047],
            [-80.805778553999971, 35.595272606000037],
            [-80.805955868999945, 35.595950558000027],
            [-80.806272638999985, 35.597148693000065],
            [-80.806356533999974, 35.597530996000046],
            [-80.806211633999965, 35.598292131000051],
            [-80.80609134599996, 35.598962638000046],
            [-80.805949004999945, 35.599737680000032],
            [-80.805850916999987, 35.600279157000045],
            [-80.805754409999963, 35.600804694000033],
            [-80.805646740999975, 35.601413626000067],
            [-80.805547984999976, 35.601959824000062],
            [-80.805477846999963, 35.602354521000052],
            [-80.805375517999948, 35.60292524700003],
            [-80.805273949999957, 35.603486869000051],
            [-80.805128091999961, 35.604290951000053],
            [-80.805007885999942, 35.604948695000076],
            [-80.804930117999959, 35.605386041000031],
            [-80.804824210999982, 35.605971767000028],
            [-80.804686152999977, 35.606739982000079],
            [-80.804600498999946, 35.607223149000049],
            [-80.80451076899999, 35.607732003000024],
            [-80.804391022999937, 35.608443333000025],
            [-80.804259964999972, 35.609309435000057],
            [-80.804147519999958, 35.610159776000046],
            [-80.804070072999934, 35.610826081000027],
            [-80.804017929999986, 35.61133638900003],
            [-80.803954830999942, 35.612058804000071],
            [-80.80392253499997, 35.612495208000041],
            [-80.803875919999939, 35.613267456000074],
            [-80.803843106999977, 35.613980620000063],
            [-80.80382739099997, 35.61446287800004],
            [-80.803816238999957, 35.614972454000053],
            [-80.803810174999967, 35.615556190000063],
            [-80.803811886999938, 35.616111996000029],
            [-80.803821780999954, 35.616697868000074],
            [-80.803840817999969, 35.617308435000041],
            [-80.803867632999982, 35.617897258000028],
            [-80.803903294999941, 35.618492171000071],
            [-80.803946143999951, 35.619071461000033],
            [-80.803997524999943, 35.619654669000056],
            [-80.804061961999935, 35.620278162000034],
            [-80.804136226999958, 35.620920916000046],
            [-80.804221097999971, 35.621570782000049],
            [-80.804315903999964, 35.622233645000051],
            [-80.804419951999989, 35.622889799000063],
            [-80.804523682999957, 35.623498545000075],
            [-80.804594367999982, 35.623881380000057],
            [-80.804703558999961, 35.624440875000062],
            [-80.804780538999978, 35.624813882000069],
            [-80.804948311999965, 35.625558139000077],
            [-80.805131600999971, 35.62631377200006],
            [-80.805326488999981, 35.627060631000063],
            [-80.805532446999962, 35.627800543000035],
            [-80.805765314999974, 35.62858486600004],
            [-80.80597897499996, 35.629267936000076],
            [-80.806234122999967, 35.630040516000065],
            [-80.806480912999973, 35.630749276000074],
            [-80.806621735999954, 35.631136983000033],
            [-80.806847970999968, 35.631736387000046],
            [-80.807083132999935, 35.632336925000061],
            [-80.807235369999944, 35.632709390000059],
            [-80.807474113999945, 35.633276994000028],
            [-80.807711167999969, 35.633820354000079],
            [-80.807973421999975, 35.634404460000042],
            [-80.80826733899994, 35.635041413000067],
            [-80.808666765999988, 35.635886649000042],
            [-80.80902187099997, 35.63662948700005],
            [-80.809320326999966, 35.637242767000032],
            [-80.809543340999937, 35.637706447000028],
            [-80.809801337999943, 35.638238778000073],
            [-80.810092598999972, 35.63874798300003],
            [-80.810323638999989, 35.639336766000042],
            [-80.810411731999977, 35.640043425000044],
            [-80.810506273999977, 35.640757047000079],
            [-80.810846177999963, 35.641548701000033],
            [-80.810838901999944, 35.642021185000033],
            [-80.810805562999974, 35.642552966000039],
            [-80.810447655999951, 35.643120373000045],
            [-80.810274796999977, 35.643576171000063],
            [-80.810073373999955, 35.644124565000027],
            [-80.809814442999937, 35.644855808000045],
            [-80.809593207999967, 35.645507418000079],
            [-80.809410457999945, 35.646065036000039],
            [-80.809286971999938, 35.646452871000065],
            [-80.809158080999964, 35.64686981400007],
            [-80.809011368999961, 35.64736098700007],
            [-80.808835273999989, 35.64797808700007],
            [-80.808619226999951, 35.648776099000031],
            [-80.80842043499996, 35.649558573000036],
            [-80.808221198999945, 35.650388921000058],
            [-80.808075332999977, 35.651029980000033],
            [-80.808001679999961, 35.651367452000045],
            [-80.80790535899996, 35.651827969000067],
            [-80.807807921999938, 35.652316839000036],
            [-80.807682686999954, 35.652988578000077],
            [-80.80753002299997, 35.653881779000073],
            [-80.807410216999983, 35.654658749000077],
            [-80.807321226999989, 35.655295705000071],
            [-80.807223575999956, 35.656057006000026],
            [-80.807126159999939, 35.656900515000075],
            [-80.806983174999971, 35.657835650000038],
            [-80.806699827999978, 35.658395598000027],
            [-80.806426372999965, 35.658924730000024],
            [-80.805939128999967, 35.659839901000055],
            [-80.805754847999935, 35.66017855900003],
            [-80.805353477999972, 35.660925387000077],
            [-80.805023644999949, 35.661546706000024],
            [-80.804715146999968, 35.662127511000051],
            [-80.804393230999949, 35.662748836000048],
            [-80.804028130999939, 35.663475733000041],
            [-80.803776593999942, 35.663993808000043],
            [-80.803523588999951, 35.664529854000079],
            [-80.803228375999936, 35.665176908000035],
            [-80.802922437999939, 35.665882037000074],
            [-80.802668769999968, 35.666489915000056],
            [-80.802432804999967, 35.667079910000041],
            [-80.802128789999983, 35.667873947000032],
            [-80.801901843999985, 35.668495182000072],
            [-80.801652178999973, 35.669215831000031],
            [-80.801456476999988, 35.669808587000034],
            [-80.801191696999979, 35.670654195000054],
            [-80.800987171999964, 35.671353890000034],
            [-80.800791353999955, 35.672063100000059],
            [-80.800582682999959, 35.672864324000045],
            [-80.80038129999997, 35.673701400000027],
            [-80.80026570299998, 35.674214024000037],
            [-80.800145750999945, 35.674790786000074],
            [-80.800032061999957, 35.675374324000074],
            [-80.799925790999964, 35.675960394000072],
            [-80.799824371999989, 35.67656478400005],
            [-80.799729527999943, 35.677181114000064],
            [-80.799647738999965, 35.677764914000079],
            [-80.799570699999947, 35.678373630000067],
            [-80.799503577999985, 35.678968088000033],
            [-80.799444580999989, 35.679560974000026],
            [-80.799395890999961, 35.680122761000064],
            [-80.799361424999972, 35.680582125000058],
            [-80.799313176999988, 35.681359073000067],
            [-80.799284872999976, 35.68197592000007],
            [-80.799268429999984, 35.682703269000058],
            [-80.799270933999935, 35.683117518000074],
            [-80.799262220999935, 35.683643062000044],
            [-80.799263013999962, 35.68430433900005],
            [-80.799269471999935, 35.68460895700008],
            [-80.799284281999974, 35.685147131000065],
            [-80.799300865999953, 35.685603488000027],
            [-80.799321029999987, 35.686015964000035],
            [-80.799364720999961, 35.686682813000061],
            [-80.799426991999951, 35.687395366000032],
            [-80.799480499999959, 35.68794570600005],
            [-80.799538259999963, 35.688470874000075],
            [-80.799605091999979, 35.689167135000048],
            [-80.799679007999941, 35.689723580000077],
            [-80.799768272999984, 35.690334727000049],
            [-80.799866497999972, 35.690947655000059],
            [-80.799973338999962, 35.691559958000028],
            [-80.800088277999976, 35.692168883000079],
            [-80.800218185999938, 35.692807477000031],
            [-80.80035391499996, 35.693428542000049],
            [-80.800501710999981, 35.694061134000037],
            [-80.800653310999962, 35.694670946000031],
            [-80.800808270999937, 35.695259531000033],
            [-80.800976283999944, 35.695863959000064],
            [-80.801150296999936, 35.696458618000065],
            [-80.801321844999961, 35.697017441000071],
            [-80.80152554099999, 35.697652380000079],
            [-80.80172702699997, 35.698251517000074],
            [-80.801928264999958, 35.698824656000056],
            [-80.802114057999972, 35.699333742000078],
            [-80.802308926999956, 35.699848819000067],
            [-80.802490475999946, 35.700313290000054],
            [-80.80290247399995, 35.700574749000054],
            [-80.802843016999987, 35.701187179000044],
            [-80.802965229999984, 35.701698578000048],
            [-80.802633742999944, 35.702046084000074],
            [-80.802526476999958, 35.702556817000072],
            [-80.80239241299995, 35.703246104000073],
            [-80.802268909999952, 35.703934748000052],
            [-80.802192154999943, 35.704401186000041],
            [-80.802121861999979, 35.704859366000051],
            [-80.802065142999936, 35.705257232000065],
            [-80.802118420999989, 35.705818645000079],
            [-80.802041151999958, 35.706313672000078],
            [-80.801963457999989, 35.706867188000047],
            [-80.801873001999979, 35.707647868000038],
            [-80.801812680999944, 35.708272358000045],
            [-80.80178284699997, 35.708633647000056],
            [-80.801747354999975, 35.709128464000059],
            [-80.801705327999969, 35.709756421000066],
            [-80.801670262999949, 35.710363606000044],
            [-80.80164022799994, 35.71100801800003],
            [-80.801647856999978, 35.711473475000048],
            [-80.801662075999957, 35.711965554000074],
            [-80.801712984999938, 35.712385632000064],
            [-80.801751765999938, 35.712965567000026],
            [-80.801761283999952, 35.713375271000075],
            [-80.801782498999955, 35.713739413000042],
            [-80.801811554999972, 35.714103196000053],
            [-80.801848441999937, 35.714466505000075],
            [-80.801893148999966, 35.714829231000067],
            [-80.80194566199998, 35.715191259000051],
            [-80.801869089999968, 35.715894147000029],
            [-80.801939150999942, 35.716533417000051],
            [-80.802051309999968, 35.717422390000024],
            [-80.802156564999962, 35.718146211000033],
            [-80.802279021999937, 35.718895245000056],
            [-80.802420577999953, 35.719672865000064],
            [-80.802582853999979, 35.720479168000054],
            [-80.802741482999977, 35.721199348000027],
            [-80.802877931999944, 35.721777682000038],
            [-80.803005578999944, 35.722296164000056],
            [-80.80313496499997, 35.722824957000057],
            [-80.803308207999976, 35.723503738000034],
            [-80.803449757999942, 35.724063197000078],
            [-80.803609055999971, 35.724688155000024],
            [-80.803792321999936, 35.725409698000078],
            [-80.803980932999934, 35.726163057000065],
            [-80.804136946999961, 35.726775201000066],
            [-80.804233088999979, 35.727158924000037],
            [-80.804332723999948, 35.727562754000076],
            [-80.804414425999937, 35.727915041000074],
            [-80.804629972999976, 35.729138129000034],
            [-80.804799238999976, 35.730008810000072],
            [-80.804923513999938, 35.730944084000043],
            [-80.805012966999982, 35.731744348000063],
            [-80.80507003699995, 35.73227015100008],
            [-80.805122867999955, 35.732745008000052],
            [-80.805201550999982, 35.733463047000043],
            [-80.805253844999982, 35.733968470000036],
            [-80.805305529999941, 35.734455669000056],
            [-80.805363778999947, 35.734972825000057],
            [-80.805433969999967, 35.73559937300007],
            [-80.805604741999957, 35.737165573000027],
            [-80.805655871999988, 35.737616484000057],
            [-80.805702366999981, 35.738054064000039],
            [-80.805820923999988, 35.739129318000039],
            [-80.805938677999961, 35.740233854000053],
            [-80.805999682999982, 35.740777648000062],
            [-80.806057844999941, 35.741310331000079],
            [-80.806123477999961, 35.74192658700008],
            [-80.806195169999967, 35.742587932000049],
            [-80.806261387999939, 35.743185815000061],
            [-80.806358001999968, 35.744045305000043],
            [-80.806416539999987, 35.744589719000032],
            [-80.806557139999938, 35.745874703000027],
            [-80.806643984999937, 35.746664318000057],
            [-80.806708844999946, 35.747264481000059],
            [-80.806798492999974, 35.748072225000044],
            [-80.806858377999959, 35.748617437000064],
            [-80.806961901999955, 35.749579738000079],
            [-80.807021913999961, 35.750146864000044],
            [-80.807097451999937, 35.75081300800008],
            [-80.807178809999982, 35.751571599000044],
            [-80.807239975999948, 35.752120563000062],
            [-80.807302095999944, 35.752659959000027],
            [-80.807375891999982, 35.753332556000032],
            [-80.807428679999987, 35.753826887000059],
            [-80.807492543999956, 35.754407366000066],
            [-80.807544058999952, 35.754866689000039],
            [-80.807662944999947, 35.755962091000072],
            [-80.807734279999977, 35.756594867000047],
            [-80.807821875999934, 35.757392012000025],
            [-80.807895650999967, 35.758081992000029],
            [-80.807952006999983, 35.758590041000048],
            [-80.808004736999976, 35.759081992000063],
            [-80.808160166999983, 35.760479641000074],
            [-80.808225363999952, 35.761088271000062],
            [-80.808271402999935, 35.761502525000026],
            [-80.808328415999938, 35.762029723000069],
            [-80.808398988999954, 35.762665927000057],
            [-80.808532367999987, 35.763896720000048],
            [-80.808597650999957, 35.764478565000047],
            [-80.808793782999942, 35.766270708000036],
            [-80.808907155999975, 35.767288625000049],
            [-80.809027765999986, 35.768402278000053],
            [-80.809141888999989, 35.769434305000061],
            [-80.80920643799999, 35.770054440000024],
            [-80.809275730999957, 35.770691151000051],
            [-80.809343197999965, 35.771296562000032],
            [-80.809412065999936, 35.77213220200008],
            [-80.809542059999956, 35.77338110200003],
            [-80.809678521999956, 35.774357155000075],
            [-80.809724668999934, 35.774781573000041],
            [-80.809772715999941, 35.775215465000031],
            [-80.809829676999982, 35.775887471000033],
            [-80.809885016999942, 35.776451568000027],
            [-80.809944297999948, 35.776991510000073],
            [-80.809987429999978, 35.777353525000024],
            [-80.810045878999972, 35.777907363000054],
            [-80.810114814999963, 35.778540551000049],
            [-80.810096261999945, 35.779048998000064],
            [-80.810038457999951, 35.77963610300003],
            [-80.809990392999964, 35.780131728000072],
            [-80.809880517999943, 35.781276611000067],
            [-80.809828249999953, 35.781804385000044],
            [-80.809739432999947, 35.782686823000063],
            [-80.809651628999973, 35.783388547000072],
            [-80.809530449999954, 35.784534665000024],
            [-80.809440602999985, 35.785831705000078],
            [-80.809382325999934, 35.78627235700003],
            [-80.80924665699996, 35.787520843000038],
            [-80.809162328999946, 35.788603837000039],
            [-80.809126315999947, 35.78914820600005],
            [-80.809075212999971, 35.78969231800005],
            [-80.809013903999983, 35.790318052000032],
            [-80.808958783999969, 35.790917312000033],
            [-80.808900392999988, 35.791515872000048],
            [-80.808853892999934, 35.792012146000047],
            [-80.808802208999964, 35.792536211000026],
            [-80.808757137999976, 35.792990926000073],
            [-80.808695420999982, 35.793631326000025],
            [-80.808652022999979, 35.79407704700003],
            [-80.808606727999972, 35.794556683000053],
            [-80.80856425099995, 35.794992805000049],
            [-80.80852103999996, 35.795458950000068],
            [-80.808493240999951, 35.795774542000061],
            [-80.808446778999951, 35.796293344000048],
            [-80.808379612999943, 35.79707529600006],
            [-80.808335417999956, 35.797642445000065],
            [-80.80829950399999, 35.798142443000074],
            [-80.808266542999945, 35.79863614900006],
            [-80.808234423999977, 35.799152799000069],
            [-80.808197373999974, 35.79978447600007],
            [-80.808154895999962, 35.800499675000026],
            [-80.808085046999963, 35.801665927000045],
            [-80.808044267999946, 35.802401088000067],
            [-80.808028254999954, 35.802711199000044],
            [-80.807983320999938, 35.803170723000051],
            [-80.807911587999968, 35.804230456000028],
            [-80.80786324799999, 35.805540391000079],
            [-80.807825046999938, 35.805938467000033],
            [-80.807733545999952, 35.807189609000034],
            [-80.807682529999965, 35.808606627000074],
            [-80.807658826999955, 35.80928087500007],
            [-80.807612214999949, 35.809996658000045],
            [-80.807504519999952, 35.811396685000034],
            [-80.80745731199994, 35.812649438000051],
            [-80.807445074999976, 35.81308941900005],
            [-80.807415015999936, 35.813604938000026],
            [-80.807357162999949, 35.814630859000033],
            [-80.807328480999956, 35.815115965000075],
            [-80.807306448999952, 35.81551005700004],
            [-80.807277411999962, 35.816050147000055],
            [-80.807254115999967, 35.816459869000028],
            [-80.807226863999972, 35.816905126000051],
            [-80.807195758999967, 35.81744939500004],
            [-80.807158537999953, 35.818148842000028],
            [-80.807125588999952, 35.818707921000055],
            [-80.807095662999984, 35.819260568000061],
            [-80.807070100999965, 35.819706336000024],
            [-80.807020700999942, 35.820591451000041],
            [-80.806964812999979, 35.821575821000067],
            [-80.806924863999939, 35.822313665000024],
            [-80.806880394999951, 35.823103348000075],
            [-80.806855151999969, 35.823566549000077],
            [-80.806823342999962, 35.82410369400003],
            [-80.806763156999978, 35.825180384000078],
            [-80.806714651999982, 35.826087942000072],
            [-80.806659096999965, 35.827079840000067],
            [-80.806602985999973, 35.828041681000059],
            [-80.806576689999986, 35.828518419000034],
            [-80.80654588799996, 35.829102443000068],
            [-80.806491925999978, 35.830039746000068],
            [-80.806399146999979, 35.831685316000062],
            [-80.806372305999957, 35.832160816000055],
            [-80.806447094999953, 35.83288230800008],
            [-80.806380041999944, 35.833411221000063],
            [-80.806320159999984, 35.833940704000042],
            [-80.80626745699999, 35.834470692000025],
            [-80.806221938999954, 35.835001122000051],
            [-80.806183611999984, 35.835531929000069],
            [-80.80615248099997, 35.836063047000039],
            [-80.806133487999944, 35.836443221000025],
            [-80.806121100999974, 35.836823575000039],
            [-80.806115321999982, 35.837204034000024],
            [-80.806116152999948, 35.837584521000053],
            [-80.806123593999985, 35.837964961000068],
            [-80.805984581999951, 35.83899800100005],
            [-80.80591740899996, 35.840195965000078],
            [-80.805873247999955, 35.841004113000054],
            [-80.805837928999949, 35.841596035000066],
            [-80.805803388999948, 35.842191202000038],
            [-80.805693376999955, 35.843700431000059],
            [-80.805639614999961, 35.845096480000052],
            [-80.805613508999954, 35.845595531000072],
            [-80.805591937999964, 35.846080918000041],
            [-80.805574466999985, 35.84656969200006],
            [-80.80556095999998, 35.84707042000008],
            [-80.805553259999954, 35.847476808000067],
            [-80.805548907999935, 35.847970036000049],
            [-80.805551363999939, 35.848486738000076],
            [-80.805561392999948, 35.849137294000059],
            [-80.805579078999983, 35.849779755000043],
            [-80.805594127999939, 35.850167131000035],
            [-80.805614623999986, 35.850588500000072],
            [-80.805652914999939, 35.851235065000026],
            [-80.805731677999972, 35.852221370000052],
            [-80.805859326999951, 35.853435495000042],
            [-80.806031737999945, 35.85466344200006],
            [-80.806249970999943, 35.855904084000031],
            [-80.806377385999951, 35.856557426000052],
            [-80.806512037999937, 35.857139084000039],
            [-80.806817724999974, 35.858367426000029],
            [-80.807166776999964, 35.859588082000073],
            [-80.807558907999976, 35.86080004300004],
            [-80.807993795999948, 35.862002309000047],
            [-80.808471076999979, 35.86319386100007],
            [-80.808749249999948, 35.863852350000059],
            [-80.808990350999977, 35.864373707000027],
            [-80.809551201999966, 35.865540890000034],
            [-80.810153166999953, 35.866694447000043],
            [-80.810795730999985, 35.867833381000025],
            [-80.811478356999942, 35.868956749000063],
            [-80.812200502999985, 35.870063650000077],
            [-80.812635084999954, 35.870703548000051],
            [-80.812961565999956, 35.871153154000069],
            [-80.813760900999966, 35.872224341000049],
            [-80.814597852999952, 35.873276331000056],
            [-80.815471724999952, 35.87430824300003],
            [-80.816381793999938, 35.875319224000066],
            [-80.817327302999956, 35.876308431000041],
            [-80.817918166999959, 35.876904617000037],
            [-80.818307464999975, 35.877275041000075],
            [-80.819321471999956, 35.878218254000046],
            [-80.820368479999956, 35.879137284000024],
            [-80.821447617999979, 35.880031368000061],
            [-80.822557998999969, 35.880899766000027],
            [-80.823698683999964, 35.881741746000046],
            [-80.824654141999986, 35.882412651000038],
            [-80.826067155999965, 35.883343679000063],
            [-80.827293005999934, 35.884102319000078],
            [-80.828545221999946, 35.884831875000032],
            [-80.829822772999989, 35.885531747000073],
            [-80.831124597999974, 35.886201355000026],
            [-80.832226271999957, 35.886737137000068],
            [-80.833796717999974, 35.887447574000078],
            [-80.83516478599995, 35.888023145000034],
            [-80.836552697999934, 35.888566385000047],
            [-80.837959313999988, 35.889076845000034],
            [-80.839383414999986, 35.889554082000075],
            [-80.840602114999967, 35.889933522000035],
            [-80.842279387999952, 35.890407356000026],
            [-80.843748839999989, 35.890782681000076],
            [-80.84523100399997, 35.891123378000032],
            [-80.846724649999942, 35.891429164000044],
            [-80.848228493999954, 35.891699775000063],
            [-80.849530369999968, 35.891905885000028],
            [-80.851261821999969, 35.892134618000057],
            [-80.852788775999954, 35.892298486000072],
            [-80.854320915999949, 35.892426464000039],
            [-80.85585697099998, 35.892518443000029],
            [-80.857395633999943, 35.892574345000071],
            [-80.858743089999962, 35.89259504000006],
            [-80.860475702999963, 35.892577766000045],
            [-80.862014558999988, 35.892525282000065],
            [-80.863550901999986, 35.892436716000077],
            [-80.86508345499999, 35.892312142000037],
            [-80.866610965999939, 35.892151665000029],
            [-80.867963753999959, 35.891980303000025],
            [-80.86964575099995, 35.891723561000049],
            [-80.871150478999937, 35.891456294000079],
            [-80.872645124999963, 35.891153831000054],
            [-80.874128457999973, 35.890816421000068],
            [-80.875599182999963, 35.890444357000035],
            [-80.876915586999985, 35.890080133000026],
            [-80.878497988999982, 35.889597524000067],
            [-80.879923694999945, 35.889123449000067],
            [-80.881331995999972, 35.888616124000066],
            [-80.882721726999989, 35.888075970000045],
            [-80.884091731999945, 35.887503435000042],
            [-80.885329955999964, 35.886951554000063],
            [-80.886768040999982, 35.886263150000048],
            [-80.888072083999987, 35.885596448000058],
            [-80.889351978999969, 35.884899416000053],
            [-80.890606659999946, 35.884172631000069],
            [-80.891835036999964, 35.883416726000064],
            [-80.89287264099994, 35.882744616000025],
            [-80.893364156999951, 35.882389906000071],
            [-80.894199661999949, 35.881811233000064],
            [-80.894888546999937, 35.881317682000031],
            [-80.895560183999976, 35.880812457000047],
            [-80.896461663999958, 35.880111274000058],
            [-80.897113702999945, 35.879586846000052],
            [-80.897749643999987, 35.879049968000061],
            [-80.898599826999941, 35.878307775000053],
            [-80.899212724999984, 35.877754481000068],
            [-80.899755437999943, 35.87724146000005],
            [-80.900606983999978, 35.876406691000057],
            [-80.901169219999986, 35.875836553000056],
            [-80.901534805999972, 35.875451675000079],
            [-80.90246390599998, 35.874451961000034],
            [-80.90354182699997, 35.87320330700004],
            [-80.904382298999963, 35.872153182000034],
            [-80.905153218999942, 35.871129043000053],
            [-80.90594992299998, 35.869995970000048],
            [-80.906675772999961, 35.868890678000071],
            [-80.907362170999988, 35.867768813000055],
            [-80.908008548999987, 35.866631306000045],
            [-80.908614366999984, 35.865479108000045],
            [-80.909164524999937, 35.864345901000036],
            [-80.909702355999968, 35.863134474000049],
            [-80.910183625999935, 35.86194398300006],
            [-80.910622536999938, 35.860742696000045],
            [-80.911018724999963, 35.859531615000037],
            [-80.911371864999978, 35.85831173400004],
            [-80.911677951999934, 35.857101211000042],
            [-80.911947870999938, 35.855849642000067],
            [-80.912170255999968, 35.854609503000063],
            [-80.912348645999941, 35.853364647000035],
            [-80.912482891999957, 35.852116116000047],
            [-80.912572882999939, 35.850864958000045],
            [-80.912618546999965, 35.849612206000074],
            [-80.912619847999963, 35.84835890100004],
            [-80.912611422999987, 35.847870558000068],
            [-80.912633866999954, 35.847468592000041],
            [-80.912715840999965, 35.846368682000048],
            [-80.912749117999965, 35.845823587000041],
            [-80.912796276999984, 35.844774575000031],
            [-80.912815911999985, 35.844250419000048],
            [-80.912838567999984, 35.84373862800004],
            [-80.912864737999939, 35.843269838000026],
            [-80.912895062999951, 35.842722909000031],
            [-80.912915683999984, 35.84233617600006],
            [-80.912973014999977, 35.841326029000072],
            [-80.913007745999948, 35.840664281000045],
            [-80.913068062999969, 35.839605478000067],
            [-80.913096096999936, 35.839080855000077],
            [-80.913123844999973, 35.838579312000036],
            [-80.913154047999967, 35.838001920000067],
            [-80.913180094999973, 35.837515187000065],
            [-80.913205411999968, 35.837065748000043],
            [-80.913230878999968, 35.836602625000069],
            [-80.913296410999976, 35.835469940000053],
            [-80.913369260999957, 35.834093084000074],
            [-80.913409885999954, 35.833379007000076],
            [-80.913464570999963, 35.832374167000069],
            [-80.913520793999965, 35.831381774000079],
            [-80.913552290999974, 35.83079755600005],
            [-80.913574805999986, 35.830365224000047],
            [-80.913608873999976, 35.829764859000079],
            [-80.913685391999934, 35.828346306000071],
            [-80.91375101899996, 35.827164254000024],
            [-80.913789834999989, 35.826436905000037],
            [-80.913821721999966, 35.82587081500003],
            [-80.91385487499997, 35.825230733000069],
            [-80.91388821399994, 35.824642073000064],
            [-80.913924646999988, 35.824000987000034],
            [-80.91394556399996, 35.82361684600005],
            [-80.914003433999937, 35.822603451000077],
            [-80.914038461999951, 35.821952042000078],
            [-80.914092296999968, 35.820997039000076],
            [-80.914139116999934, 35.820113381000056],
            [-80.914178245999949, 35.819409225000072],
            [-80.914237659999969, 35.818345198000031],
            [-80.914275518999943, 35.817687273000047],
            [-80.914312934999941, 35.816979160000074],
            [-80.914347058999965, 35.81637586800008],
            [-80.914376656999934, 35.815864513000065],
            [-80.914404108999975, 35.815327745000047],
            [-80.914450253999973, 35.814511733000074],
            [-80.914489059999937, 35.813797961000034],
            [-80.914522396999985, 35.813188527000079],
            [-80.914599805999956, 35.812080016000039],
            [-80.914638973999956, 35.811291260000075],
            [-80.914658696999936, 35.810842424000043],
            [-80.914675555999963, 35.810422073000041],
            [-80.914706343999967, 35.80987512400003],
            [-80.914735617999952, 35.809302030000026],
            [-80.914770684999951, 35.808696027000053],
            [-80.914799236999954, 35.808325506000074],
            [-80.914826442999981, 35.807932506000043],
            [-80.914852810999946, 35.807511400000067],
            [-80.91487776799994, 35.807116842000028],
            [-80.914904886999977, 35.806619559000069],
            [-80.914926109999953, 35.80617198200008],
            [-80.914961630999983, 35.805417558000045],
            [-80.914990892999981, 35.804626606000056],
            [-80.915014025999938, 35.80418241600006],
            [-80.91507495899998, 35.803224385000078],
            [-80.915101475999961, 35.802835752000078],
            [-80.915132484999958, 35.802481589000024],
            [-80.91518376199997, 35.801928728000064],
            [-80.915225815999975, 35.801438244000053],
            [-80.915368776999969, 35.799946959000067],
            [-80.915418382999974, 35.799413980000054],
            [-80.915466625999954, 35.79891679800005],
            [-80.915543868999976, 35.798090105000028],
            [-80.915619900999957, 35.797310817000039],
            [-80.915679889999979, 35.796668540000042],
            [-80.91575232799994, 35.795901873000048],
            [-80.915837124999939, 35.795149683000034],
            [-80.915890631999957, 35.794592895000051],
            [-80.915942886999972, 35.794077002000051],
            [-80.915980695999963, 35.793681954000078],
            [-80.916014349999955, 35.793280326000058],
            [-80.91607953099998, 35.792433679000055],
            [-80.916169568999976, 35.791471887000057],
            [-80.916229574999988, 35.790845674000025],
            [-80.916280215999961, 35.790297552000027],
            [-80.916326125999944, 35.789815866000026],
            [-80.916372539999941, 35.789319749000072],
            [-80.916428326999949, 35.788739902000032],
            [-80.916471411999964, 35.788272025000026],
            [-80.916556656999944, 35.787359307000031],
            [-80.916629052999951, 35.786606060000054],
            [-80.916694610999969, 35.785908636000045],
            [-80.916751916999942, 35.785314479000078],
            [-80.916816419999975, 35.784607393000044],
            [-80.916868285999954, 35.784098392000033],
            [-80.916916367999988, 35.783588122000026],
            [-80.916977460999988, 35.782853419000048],
            [-80.917039500999977, 35.781965250000042],
            [-80.917087803999948, 35.781087310000032],
            [-80.917123534999973, 35.780244444000061],
            [-80.917142745999968, 35.779689846000053],
            [-80.917155156999968, 35.77925556100007],
            [-80.917162082999937, 35.778743076000069],
            [-80.917159044999948, 35.777618699000072],
            [-80.917153739999947, 35.777287114000046],
            [-80.917141661999949, 35.776682496000035],
            [-80.917102264999983, 35.775829890000068],
            [-80.917011190999972, 35.774535626000045],
            [-80.916984613999944, 35.774182662000044],
            [-80.91693990899995, 35.773649803000069],
            [-80.916887334999956, 35.77308825700004],
            [-80.916839250999942, 35.772623734000035],
            [-80.916785968999989, 35.772139396000057],
            [-80.916687783999976, 35.771094383000047],
            [-80.916600029999984, 35.770269323000036],
            [-80.916536401999963, 35.769680944000072],
            [-80.916450544999975, 35.768916604000026],
            [-80.916384640999979, 35.768298238000057],
            [-80.916177703999949, 35.767737025000031],
            [-80.916137144999936, 35.767486579000035],
            [-80.916103686999975, 35.767235436000078],
            [-80.916077349999966, 35.766983730000049],
            [-80.916058146999944, 35.766731597000046],
            [-80.916046086999984, 35.766479169000036],
            [-80.916041177999944, 35.766226582000058],
            [-80.916043421999973, 35.765973969000072],
            [-80.916052817999969, 35.765721466000059],
            [-80.915969360999952, 35.76473240200005],
            [-80.915923785999951, 35.764250046000029],
            [-80.915881859999956, 35.763835099000062],
            [-80.91581062399996, 35.763325823000059],
            [-80.915727549999986, 35.762704824000025],
            [-80.915641281999967, 35.762114824000037],
            [-80.915502858999957, 35.761343176000025],
            [-80.915319981999971, 35.760749901000054],
            [-80.915434871999935, 35.759932870000057],
            [-80.915362613999946, 35.759288067000057],
            [-80.91531078099996, 35.758838618000027],
            [-80.91514078299997, 35.757286124000075],
            [-80.915083268999979, 35.756766847000051],
            [-80.915032873999962, 35.756317963000072],
            [-80.914818620999938, 35.754365594000035],
            [-80.914762379999956, 35.753870249000045],
            [-80.91460725799999, 35.752471580000076],
            [-80.914546365999968, 35.751915500000052],
            [-80.914490877999981, 35.75141773100006],
            [-80.914435220999962, 35.75090724000006],
            [-80.914369006999948, 35.750330985000062],
            [-80.914248260999955, 35.749230858000033],
            [-80.914191897999956, 35.748703414000033],
            [-80.914121064999961, 35.748067930000047],
            [-80.914011501999937, 35.747088062000046],
            [-80.913955312999974, 35.746605427000077],
            [-80.913890805999984, 35.746006697000041],
            [-80.91382546799997, 35.745423494000079],
            [-80.913724288999958, 35.744509047000065],
            [-80.913666628999977, 35.743991264000044],
            [-80.913597019999941, 35.743345929000043],
            [-80.913545463999981, 35.742903951000073],
            [-80.913468192999972, 35.742226487000039],
            [-80.913385216999984, 35.741464912000026],
            [-80.913294198999949, 35.74064544600003],
            [-80.913222488999963, 35.739980909000053],
            [-80.913169862999951, 35.739489347000074],
            [-80.913086246999967, 35.738760152000054],
            [-80.912981746999947, 35.737834930000076],
            [-80.912917843999935, 35.737241992000065],
            [-80.912845123999944, 35.736602042000072],
            [-80.912765158999946, 35.735879480000051],
            [-80.912714173999973, 35.735401514000046],
            [-80.912623919999987, 35.734602811000059],
            [-80.912550502999977, 35.73393344200008],
            [-80.91247349799994, 35.733249869000076],
            [-80.912404265999953, 35.73260859100003],
            [-80.912328228999968, 35.731916984000065],
            [-80.912236605999965, 35.731102511000074],
            [-80.912187407999966, 35.730638767000073],
            [-80.912135846999945, 35.730166580000059],
            [-80.912081508999961, 35.729681392000032],
            [-80.911971917999949, 35.72870643400006],
            [-80.91190022699999, 35.728054607000047],
            [-80.911841849999973, 35.727520728000059],
            [-80.911739986999976, 35.726618300000041],
            [-80.911687166999968, 35.726096128000052],
            [-80.911643921999939, 35.725629938000054],
            [-80.911592267999936, 35.725114655000027],
            [-80.911532075999958, 35.724558807000051],
            [-80.911424644999954, 35.723722959000042],
            [-80.911321442999963, 35.722824555000045],
            [-80.911250716999973, 35.722225129000037],
            [-80.911180325999965, 35.721672066000053],
            [-80.911043248999988, 35.720616392000068],
            [-80.910948151999946, 35.719956745000047],
            [-80.910867260999964, 35.719415952000077],
            [-80.910709016999988, 35.718464578000066],
            [-80.910614850999934, 35.717845993000026],
            [-80.910544954999978, 35.717417152000053],
            [-80.910382044999949, 35.716389309000078],
            [-80.910283872999969, 35.715863171000024],
            [-80.91010390799994, 35.714971253000044],
            [-80.910001429999966, 35.714495861000046],
            [-80.909902493999937, 35.713971515000026],
            [-80.910018438999941, 35.713585838000029],
            [-80.910247979999951, 35.712855730000058],
            [-80.910454215999948, 35.712166717000059],
            [-80.91066111799995, 35.711431615000038],
            [-80.910855698999967, 35.710700956000039],
            [-80.910979190999967, 35.710207438000054],
            [-80.911118148999947, 35.709622205000073],
            [-80.911232661999975, 35.709110856000052],
            [-80.911335845999986, 35.708623325000076],
            [-80.911435392999977, 35.708125120000034],
            [-80.911534478999954, 35.707596590000037],
            [-80.91162247799997, 35.707093564000047],
            [-80.91172697799999, 35.706443426000078],
            [-80.911852307999936, 35.705576656000062],
            [-80.911935981999989, 35.704925612000068],
            [-80.911992077999969, 35.704438184000026],
            [-80.912034971999958, 35.704030755000076],
            [-80.912096368999983, 35.703367988000025],
            [-80.912150260999965, 35.702686954000058],
            [-80.912199915999963, 35.701871069000049],
            [-80.912227648999988, 35.701220939000052],
            [-80.912249989999964, 35.700349435000078],
            [-80.912255529999982, 35.69951254800003],
            [-80.912249145999965, 35.698855582000078],
            [-80.912232868999979, 35.698224137000068],
            [-80.912202831999934, 35.697485894000067],
            [-80.912164655999959, 35.696830596000041],
            [-80.912104487999954, 35.696017500000039],
            [-80.912028739999982, 35.695199660000071],
            [-80.911926023999968, 35.694284301000039],
            [-80.911805544999936, 35.693376905000036],
            [-80.911699314999964, 35.69267227000006],
            [-80.911606310999957, 35.692113845000051],
            [-80.911529652999945, 35.691682983000078],
            [-80.911439688999963, 35.69120711100004],
            [-80.911346897999977, 35.690743372000043],
            [-80.91125563199995, 35.690310550000049],
            [-80.911099078999939, 35.689615954000033],
            [-80.910895953999955, 35.688779442000055],
            [-80.910674959999938, 35.68793528100008],
            [-80.910486874999947, 35.687261160000048],
            [-80.910279380999953, 35.686565330000064],
            [-80.909994433999941, 35.685668445000033],
            [-80.909769427999947, 35.685000974000047],
            [-80.909604498999954, 35.684533692000059],
            [-80.909346184999947, 35.683862597000029],
            [-80.909333244999971, 35.683307837000029],
            [-80.909670465999966, 35.682992308000053],
            [-80.909887461999972, 35.682426732000067],
            [-80.910098642999969, 35.681855754000026],
            [-80.910254407999957, 35.681418575000066],
            [-80.910377176999987, 35.681064155000058],
            [-80.910567944999968, 35.680500474000041],
            [-80.910750230999952, 35.67994772600008],
            [-80.910933307999983, 35.679375604000029],
            [-80.911069607999934, 35.678937361000067],
            [-80.911250140999982, 35.678337147000036],
            [-80.911384658999964, 35.677871771000071],
            [-80.911537770999985, 35.677318661000072],
            [-80.911735089999979, 35.676567785000032],
            [-80.911898658999974, 35.675908610000079],
            [-80.911993009999946, 35.675508882000031],
            [-80.912147913999945, 35.674824147000038],
            [-80.91229890599999, 35.674120462000076],
            [-80.912422872999969, 35.673503671000049],
            [-80.912548091999952, 35.672837620000053],
            [-80.912639162999938, 35.672320483000078],
            [-80.912733809999963, 35.671747888000027],
            [-80.912823215999936, 35.67116769900008],
            [-80.91288517299995, 35.670739726000079],
            [-80.912938031999943, 35.670351887000038],
            [-80.913004587999978, 35.669834818000027],
            [-80.913066716999936, 35.669316983000044],
            [-80.913123956999982, 35.668803127000047],
            [-80.91317051599998, 35.668352791000075],
            [-80.913286061999941, 35.667727649000028],
            [-80.91354802799998, 35.667172303000029],
            [-80.913833396999962, 35.666554882000071],
            [-80.914119945999971, 35.665911957000048],
            [-80.914360835999958, 35.665350578000073],
            [-80.914677610999945, 35.664583407000066],
            [-80.91501317899997, 35.663721549000059],
            [-80.91519763499997, 35.663220725000031],
            [-80.915369398999985, 35.662754150000069],
            [-80.915524912999956, 35.662325360000068],
            [-80.915698202999977, 35.661827306000077],
            [-80.915869593999957, 35.661311056000045],
            [-80.91600723199997, 35.660868081000046],
            [-80.916127862999986, 35.660468929000047],
            [-80.916269994999936, 35.659989629000052],
            [-80.916402109999979, 35.659531488000027],
            [-80.916557577999981, 35.658986151000079],
            [-80.916721418999941, 35.658409866000056],
            [-80.916883197999937, 35.657774507000056],
            [-80.917079522999984, 35.65695504200005],
            [-80.917220329999964, 35.656324674000075],
            [-80.917361562999986, 35.655648843000051],
            [-80.917489953999961, 35.654989015000069],
            [-80.917578527999979, 35.654503007000073],
            [-80.917686733999972, 35.653861533000054],
            [-80.917808038999965, 35.653071136000051],
            [-80.917915196999957, 35.652286996000043],
            [-80.917984064999985, 35.651728763000051],
            [-80.918041715999948, 35.651207561000035],
            [-80.918110575999947, 35.650507335000043],
            [-80.918164624999974, 35.649867439000047],
            [-80.918210338999984, 35.649230167000042],
            [-80.918250561999969, 35.648570091000067],
            [-80.918281898999965, 35.647890337000035],
            [-80.918300906999946, 35.647304916000053],
            [-80.918313069999954, 35.646654717000047],
            [-80.918316072999971, 35.645990682000047],
            [-80.918311184999936, 35.645449143000064],
            [-80.918294389999971, 35.644668653000053],
            [-80.918274164999957, 35.644071813000039],
            [-80.918245893999938, 35.643484683000054],
            [-80.918198522999944, 35.642729661000033],
            [-80.918148726999959, 35.642082046000041],
            [-80.918106470999987, 35.641601460000061],
            [-80.918049617999941, 35.641022507000059],
            [-80.917976098999986, 35.640354338000066],
            [-80.917880277999984, 35.639585213000032],
            [-80.917774435999945, 35.638811907000047],
            [-80.917670544999964, 35.638079201000039],
            [-80.917560561999949, 35.637311975000046],
            [-80.917420521999986, 35.636349487000075],
            [-80.917320779999955, 35.635655391000057],
            [-80.917260010999939, 35.635247797000034],
            [-80.917195242999981, 35.634790275000057],
            [-80.917094950999967, 35.63408232300003],
            [-80.916892970999982, 35.63270404900004],
            [-80.916812971999946, 35.632132653000042],
            [-80.916742285999987, 35.63163640700003],
            [-80.916662487999986, 35.631089768000038],
            [-80.916593605999935, 35.630628239000032],
            [-80.916527291999955, 35.630165733000069],
            [-80.916443766999976, 35.629585261000045],
            [-80.916341671999987, 35.628859250000062],
            [-80.916247354999939, 35.628206385000055],
            [-80.916109118999941, 35.627254912000069],
            [-80.916041174999975, 35.626771869000038],
            [-80.915928636999979, 35.626002638000045],
            [-80.915830237999955, 35.625374647000058],
            [-80.915734357999952, 35.624800596000057],
            [-80.915634836999971, 35.624236813000039],
            [-80.915531414999975, 35.623683960000051],
            [-80.915349078999952, 35.622786331000043],
            [-80.915253901999961, 35.62235196000006],
            [-80.915107620999947, 35.621715466000069],
            [-80.914936836999971, 35.621004367000069],
            [-80.914800401999969, 35.620462209000038],
            [-80.914674138999942, 35.619981234000079],
            [-80.914542883999957, 35.619499870000027],
            [-80.914383890999943, 35.618940097000063],
            [-80.914164708999976, 35.618203046000076],
            [-80.913970454999969, 35.617577044000029],
            [-80.913811498999962, 35.617084219000049],
            [-80.913650805999964, 35.61660112800007],
            [-80.913460899999961, 35.61602663900004],
            [-80.913163882999982, 35.615179443000045],
            [-80.912935565999987, 35.614560515000051],
            [-80.912726128999964, 35.614018455000064],
            [-80.912496215999965, 35.61344897500004],
            [-80.912234836999971, 35.612822762000064],
            [-80.911971076999976, 35.612207419000072],
            [-80.911739218999969, 35.61168542300004],
            [-80.911810511999988, 35.61110345700007],
            [-80.911908447999963, 35.610542935000069],
            [-80.911979751999979, 35.609327047000079],
            [-80.912089899999955, 35.608893568000042],
            [-80.912183117999973, 35.608442835000062],
            [-80.912346964999983, 35.607911228000034],
            [-80.912434725999958, 35.607453828000075],
            [-80.912535915999968, 35.607031728000038],
            [-80.912638885999968, 35.606490643000029],
            [-80.912721089999934, 35.606031804000054],
            [-80.912788215999967, 35.605581310000048],
            [-80.912846226999989, 35.605119345000048],
            [-80.912922561999949, 35.604545699000028],
            [-80.913007068999946, 35.60394169500006],
            [-80.91308597799997, 35.603160238000044],
            [-80.913088757999958, 35.602433532000077],
            [-80.913092536999955, 35.601952733000076],
            [-80.91332652899996, 35.601513092000062],
            [-80.913382164999973, 35.600849532000041],
            [-80.91343216599995, 35.600174908000042],
            [-80.913472580999951, 35.599488468000061],
            [-80.913494602999947, 35.599013422000041],
            [-80.913519379999968, 35.598317927000039],
            [-80.91353673399999, 35.597635756000045],
            [-80.913544251999951, 35.596842202000062],
            [-80.913541531999954, 35.596232280000038],
            [-80.913535852999985, 35.595795790000068],
            [-80.913518118999946, 35.595086061000075],
            [-80.913492347999977, 35.594412899000076],
            [-80.913463387999968, 35.593834192000031],
            [-80.913443091999966, 35.593483325000079],
            [-80.913416502999951, 35.593080031000056],
            [-80.913375894999945, 35.592538554000043],
            [-80.913328305999983, 35.591984402000037],
            [-80.913287819999937, 35.591559372000063],
            [-80.913244523999936, 35.591141953000033],
            [-80.913190550999957, 35.590659014000039],
            [-80.913129433999984, 35.590152828000043],
            [-80.913063591999958, 35.589646662000064],
            [-80.912982126999964, 35.589066519000028],
            [-80.912903292999943, 35.588544803000048],
            [-80.912813222999944, 35.587998718000051],
            [-80.91272758599996, 35.587497875000054],
            [-80.912634139999966, 35.586979765000024],
            [-80.912531422999962, 35.586446865000028],
            [-80.912403063999989, 35.585822976000031],
            [-80.912269725999977, 35.585210204000077],
            [-80.912101606999954, 35.584476826000071],
            [-80.911941837999962, 35.583815732000062],
            [-80.911742502999971, 35.58303869100007],
            [-80.911570787999949, 35.582332350000058],
            [-80.911435142999949, 35.581816452000055],
            [-80.911294340999973, 35.581301640000049],
            [-80.911116818999972, 35.580670045000033],
            [-80.910905673999935, 35.579880790000061],
            [-80.91075305399994, 35.579309200000068],
            [-80.910608836999984, 35.578766097000027],
            [-80.910504514999957, 35.57837145700006],
            [-80.910364724999965, 35.57783966900007],
            [-80.910225982999975, 35.57732784500007],
            [-80.910122167999987, 35.576945414000079],
            [-80.909974094999939, 35.57638849600005],
            [-80.910022501999947, 35.575858326000059],
            [-80.910218067999949, 35.575150693000069],
            [-80.910353745999942, 35.574650333000079],
            [-80.910479201999976, 35.57417913300003],
            [-80.910587916999987, 35.573758076000047],
            [-80.910705168999982, 35.573281850000058],
            [-80.910822907999943, 35.57277911400007],
            [-80.91095330099995, 35.572193024000057],
            [-80.911082120999936, 35.571576579000066],
            [-80.911198113999944, 35.570983827000077],
            [-80.911302506999959, 35.570413849000033],
            [-80.911398499999962, 35.569859435000069],
            [-80.911484990999952, 35.569325513000024],
            [-80.91157041699995, 35.568758135000053],
            [-80.911652076999985, 35.568169614000055],
            [-80.911732195999946, 35.567533016000027],
            [-80.911796817999971, 35.566958793000026],
            [-80.911855620999972, 35.566370496000047],
            [-80.911909049999963, 35.565756787000055],
            [-80.911955584999987, 35.565126598000063],
            [-80.91199292999994, 35.564510344000041],
            [-80.912025255999936, 35.563817005000033],
            [-80.912043308999955, 35.563294129000042],
            [-80.912056829999983, 35.562719662000063],
            [-80.912063917999944, 35.562135970000043],
            [-80.912064732999966, 35.561628213000063],
            [-80.91205950899996, 35.56107125300008],
            [-80.912036546999957, 35.560163283000065],
            [-80.911992805999944, 35.55948741800006],
            [-80.911950659999945, 35.558593423000048],
            [-80.911875239999972, 35.557570054000053],
            [-80.911841799999934, 35.557174789000044],
            [-80.911782140999946, 35.556572589000041],
            [-80.911693191999973, 35.555864314000075],
            [-80.911586247999935, 35.555083861000071],
            [-80.911472782999965, 35.554193982000072],
            [-80.911423947999936, 35.553857781000033],
            [-80.911492686999964, 35.55355038600004],
            [-80.91158074699996, 35.553148171000032],
            [-80.911718231999942, 35.552514219000045],
            [-80.911821885999984, 35.552037860000041],
            [-80.911916291999944, 35.551613724000049],
            [-80.912029114999939, 35.551099542000031],
            [-80.912162587999944, 35.550500681000074],
            [-80.91227384299998, 35.549989949000064],
            [-80.912365098999942, 35.549579651000045],
            [-80.91246492099998, 35.549124901000027],
            [-80.912720150999974, 35.547972163000054],
            [-80.91284776599997, 35.547373709000055],
            [-80.912945756999989, 35.546905020000054],
            [-80.913032688999976, 35.546485612000026],
            [-80.913156225999955, 35.545923390000041],
            [-80.913289716999941, 35.545287034000069],
            [-80.913402367999936, 35.544774177000079],
            [-80.91350222799997, 35.544363084000054],
            [-80.913657587999978, 35.54369212000006],
            [-80.913814041999956, 35.542976254000052],
            [-80.91397439299999, 35.542225083000062],
            [-80.914084399999979, 35.541723307000041],
            [-80.914307472999951, 35.540639790000057],
            [-80.914391031999969, 35.540260934000059],
            [-80.91448010199997, 35.539849491000041],
            [-80.91461095599999, 35.539259714000025],
            [-80.914701894999951, 35.53884307800007],
            [-80.914851344999988, 35.538169656000036],
            [-80.914941158999966, 35.537751677000074],
            [-80.915056102999984, 35.537227821000045],
            [-80.915185751999957, 35.536639831000059],
            [-80.915353866999965, 35.535879236000028],
            [-80.915501863999964, 35.535227164000048],
            [-80.915684060999979, 35.534465495000063],
            [-80.915762799999982, 35.534118530000057],
            [-80.915938532999974, 35.53332792100008],
            [-80.916088083999966, 35.53262972400006],
            [-80.91625552499994, 35.531871927000054],
            [-80.91639841999995, 35.531197478000024],
            [-80.916495096999938, 35.530718709000041],
            [-80.916578397999956, 35.530308641000033],
            [-80.916766546999952, 35.529460787000062],
            [-80.916855234999957, 35.529053943000065],
            [-80.916965042999948, 35.528554561000078],
            [-80.917052402999957, 35.528154744000062],
            [-80.917164269999944, 35.527654976000065],
            [-80.917345166999951, 35.52684152900008],
            [-80.917438252999943, 35.526418371000034],
            [-80.917566845999943, 35.525821915000051],
            [-80.917706306999946, 35.525145259000055],
            [-80.917808854999976, 35.524620595000044],
            [-80.917905419999954, 35.52410069900003],
            [-80.917853949999937, 35.523393817000056],
            [-80.917988874999935, 35.522849850000057],
            [-80.91809539999997, 35.522325651000074],
            [-80.918238857999938, 35.52176053200003],
            [-80.918333541999971, 35.521255170000074],
            [-80.918411703999936, 35.520700617000045],
            [-80.918482753999967, 35.520172369000079],
            [-80.918547066999963, 35.51968024100006],
            [-80.918616228999952, 35.519017046000045],
            [-80.918660043999978, 35.518506898000055],
            [-80.918712299999981, 35.517843222000067],
            [-80.918754029999945, 35.516994747000069],
            [-80.918707373999951, 35.516300784000066],
            [-80.91888634999998, 35.515580572000033],
            [-80.918939750999982, 35.515002449000065],
            [-80.918965316999959, 35.514688312000033],
            [-80.91902421399999, 35.513973732000068],
            [-80.919101722999983, 35.513140583000052],
            [-80.91917254699996, 35.512494693000065],
            [-80.91958399799995, 35.51166316900003],
            [-80.919919931999971, 35.510983599000042],
            [-80.920166799999947, 35.510482617000036],
            [-80.92052688199999, 35.509772243000043],
            [-80.920766717999982, 35.509288812000079],
            [-80.92116189799998, 35.508474532000037],
            [-80.921483941999952, 35.507826934000036],
            [-80.921771027999966, 35.507255646000033],
            [-80.922115022999947, 35.506563418000042],
            [-80.92247262099994, 35.505845062000049],
            [-80.922819660999949, 35.505133208000075],
            [-80.923107594999976, 35.504543682000076],
            [-80.923303112999974, 35.504147064000051],
            [-80.923458098999959, 35.503827706000038],
            [-80.923696912999958, 35.503345128000035],
            [-80.923915744999988, 35.502886800000056],
            [-80.924267085999986, 35.502122199000041],
            [-80.924500733999935, 35.50159220900008],
            [-80.924794717999987, 35.500899372000049],
            [-80.924997910999934, 35.50041058100004],
            [-80.925240853999981, 35.499793405000048],
            [-80.925571108999975, 35.498909444000049],
            [-80.92580596199997, 35.498246744000028],
            [-80.925977263999982, 35.497740030000045],
            [-80.926098808999939, 35.497366299000078],
            [-80.926222111999948, 35.496974202000047],
            [-80.926359035999951, 35.496524008000051],
            [-80.926472806999982, 35.49613643400005],
            [-80.926691276999975, 35.495351303000064],
            [-80.926870593999979, 35.494665932000032],
            [-80.926998975999936, 35.494141209000077],
            [-80.927154151999957, 35.49346935300008],
            [-80.927258497999958, 35.492982728000072],
            [-80.927417795999986, 35.492186852000032],
            [-80.92752533099997, 35.491602391000072],
            [-80.92763946499997, 35.490913268000043],
            [-80.927695770999946, 35.490546290000054],
            [-80.927778172999979, 35.489966032000041],
            [-80.927832055999943, 35.489552770000046],
            [-80.927887474999977, 35.489090938000061],
            [-80.927943543999959, 35.488555801000075],
            [-80.928006707999941, 35.487837461000026],
            [-80.928055899999947, 35.48722391900003],
            [-80.928093360999981, 35.486645059000068],
            [-80.92813321999995, 35.485763013000053],
            [-80.928158042999939, 35.484741052000061],
            [-80.928164627999934, 35.484039001000042],
            [-80.928168738999943, 35.483484347000058],
            [-80.928175150999948, 35.482159546000048],
            [-80.928174155999955, 35.481566624000038],
            [-80.92817602599996, 35.481172308000055],
            [-80.928188333999969, 35.480543906000037],
            [-80.928197897999951, 35.479528005000077],
            [-80.92819588499998, 35.479138920000025],
            [-80.928186721999964, 35.47839450400005],
            [-80.928191121999987, 35.477245348000054],
            [-80.928204366999978, 35.476736613000071],
            [-80.928227220999986, 35.476238670000043],
            [-80.928248525999948, 35.475767488000031],
            [-80.928276852999943, 35.475364219000028],
            [-80.928303694999954, 35.475022840000065],
            [-80.928347145999965, 35.474554727000054],
            [-80.928391371999965, 35.47415473500007],
            [-80.928426925999986, 35.473809600000038],
            [-80.928489344999946, 35.473270438000043],
            [-80.928548670999987, 35.472720432000074],
            [-80.928637039999956, 35.471916738000061],
            [-80.928730146999953, 35.471137798000029],
            [-80.92879781399995, 35.470535163000079],
            [-80.928846169999986, 35.470083526000053],
            [-80.928886019999936, 35.469694471000025],
            [-80.928946505999988, 35.469036263000078],
            [-80.928990012999975, 35.468515143000047],
            [-80.929049747999954, 35.467659670000046],
            [-80.929076911999971, 35.467167434000032],
            [-80.929102970999963, 35.466583423000031],
            [-80.929122086999939, 35.466000496000049],
            [-80.92913180499994, 35.465548937000051],
            [-80.929138679999937, 35.464949980000029],
            [-80.929131378999955, 35.463927925000064],
            [-80.929118875999961, 35.463380975000064],
            [-80.929101913999943, 35.462842078000051],
            [-80.929088202999935, 35.462467941000057],
            [-80.929059450999944, 35.461910077000027],
            [-80.928984451999952, 35.460795078000046],
            [-80.928919820999965, 35.46005219400007],
            [-80.928872579999961, 35.459583862000045],
            [-80.92882689299995, 35.459171242000025],
            [-80.928729285999964, 35.458383162000075],
            [-80.928659220999975, 35.457875175000027],
            [-80.928559775999986, 35.45721876500005],
            [-80.928444966999962, 35.456520122000029],
            [-80.928344608999964, 35.455952945000035],
            [-80.928258891999974, 35.455488638000077],
            [-80.928161225999986, 35.454986346000055],
            [-80.928058052999972, 35.454486389000067],
            [-80.92783045699997, 35.453474198000038],
            [-80.927687148999951, 35.452883022000037],
            [-80.927516241999967, 35.452220340000054],
            [-80.927335300999971, 35.451553999000055],
            [-80.927224330999934, 35.451165044000049],
            [-80.92708257999999, 35.450686010000027],
            [-80.926987577999967, 35.450372119000065],
            [-80.92676877599996, 35.449663991000079],
            [-80.926626793999958, 35.449229225000067],
            [-80.926457150999966, 35.448729159000038],
            [-80.926220335999972, 35.448059045000036],
            [-80.926090388999967, 35.447702091000053],
            [-80.925965749999989, 35.44736673500006],
            [-80.925682868999957, 35.446630693000031],
            [-80.925421805999974, 35.44597968000005],
            [-80.925083683999958, 35.445166845000074],
            [-80.924860448999937, 35.444652251000036],
            [-80.924456602999953, 35.443761373000029],
            [-80.924218007999968, 35.443254248000073],
            [-80.92403471199998, 35.44286909300007],
            [-80.923791679999965, 35.442375195000068],
            [-80.92325157199997, 35.44132116600008],
            [-80.922787346999939, 35.440536230000077],
            [-80.922652788999983, 35.439986332000046],
            [-80.922697750999987, 35.439283452000041],
            [-80.922706252999944, 35.438763068000071],
            [-80.922710971999948, 35.438292506000039],
            [-80.922708700999976, 35.437553816000047],
            [-80.922701304999975, 35.437093168000047],
            [-80.922686053999939, 35.436557997000079],
            [-80.922634451999954, 35.435223471000029],
            [-80.922602025999936, 35.434638512000049],
            [-80.922517948999939, 35.433469519000028],
            [-80.922454534999986, 35.432776650000051],
            [-80.922414514999957, 35.432414874000074],
            [-80.922340952999946, 35.431762028000037],
            [-80.922227500999952, 35.430888537000044],
            [-80.922157518999938, 35.430400244000054],
            [-80.922106480999958, 35.430061885000043],
            [-80.922014415999968, 35.42948549700003],
            [-80.921921704999988, 35.428952673000026],
            [-80.921846623999954, 35.428546951000044],
            [-80.921720353999945, 35.427906569000072],
            [-80.92147844699997, 35.426788326000064],
            [-80.921366360999968, 35.426311725000062],
            [-80.921262083999977, 35.425860965000027],
            [-80.921133140999984, 35.425327400000072],
            [-80.921000445999937, 35.424808579000057],
            [-80.92087556499996, 35.424338102000036],
            [-80.920703814999968, 35.423709888000076],
            [-80.92043840599996, 35.422795992000033],
            [-80.920276987999955, 35.422269162000077],
            [-80.920120013999963, 35.421771349000039],
            [-80.919977399999937, 35.421337354000059],
            [-80.919780905999971, 35.420751960000075],
            [-80.919519080999976, 35.42000325600003],
            [-80.919262661999937, 35.419304724000028],
            [-80.91902386199996, 35.418676474000051],
            [-80.91889791899996, 35.418353975000059],
            [-80.918776755999943, 35.418051157000036],
            [-80.918541777999963, 35.417477396000038],
            [-80.918275421999965, 35.416844133000041],
            [-80.917952634999949, 35.416105326000036],
            [-80.917694684999958, 35.415536492000058],
            [-80.917427725999971, 35.414965094000024],
            [-80.916997553999977, 35.414072560000079],
            [-80.916537330999972, 35.413156584000035],
            [-80.916225870999938, 35.412559754000029],
            [-80.91595231499997, 35.412048878000064],
            [-80.915628528999946, 35.411457885000061],
            [-80.915297844999941, 35.41085871000007],
            [-80.915012486999956, 35.41035796400007],
            [-80.914676736999979, 35.409784261000027],
            [-80.914213612999959, 35.409006765000072],
            [-80.913980037999977, 35.408603945000038],
            [-80.913699848999954, 35.408119691000024],
            [-80.913225575999945, 35.407321180000054],
            [-80.912936282999965, 35.406828535000045],
            [-80.912631572999942, 35.406311986000048],
            [-80.912366127999974, 35.405860534000055],
            [-80.912022086999968, 35.40527245100003],
            [-80.911778903999959, 35.40486028600003],
            [-80.911433628999987, 35.404271072000029],
            [-80.91124948199996, 35.403958984000042],
            [-80.91109998099995, 35.403707854000061],
            [-80.910777229999951, 35.403159948000052],
            [-80.910394047999944, 35.401249406000034],
            [-80.91031649599995, 35.400784219000059],
            [-80.910240091999981, 35.400306947000047],
            [-80.910170172999983, 35.399838715000044],
            [-80.910006283999962, 35.398785666000038],
            [-80.909901666999986, 35.398161827000024],
            [-80.909708862999935, 35.397342060000028],
            [-80.90954394399995, 35.396696813000062],
            [-80.90949283499998, 35.396099876000051],
            [-80.909397521999949, 35.395672753000042],
            [-80.909305529999983, 35.395093294000048],
            [-80.909189453999943, 35.394413533000034],
            [-80.909106729999962, 35.393924919000028],
            [-80.90902766499994, 35.393504202000031],
            [-80.908873931999949, 35.392906406000066],
            [-80.908702415999983, 35.392325565000078],
            [-80.908610978999945, 35.391960712000071],
            [-80.908463762999986, 35.391421915000024],
            [-80.908311449999985, 35.390884069000037],
            [-80.90815405099994, 35.390347207000048],
            [-80.907991573999936, 35.389811360000067],
            [-80.907837530999984, 35.38902592900007],
            [-80.907691283999952, 35.388568095000039],
            [-80.907489900999963, 35.387956563000046],
            [-80.907244262999939, 35.387241251000034],
            [-80.907069317999969, 35.386752386000069],
            [-80.906890363999935, 35.386265348000052],
            [-80.906667753999955, 35.385678167000037],
            [-80.906485473999965, 35.385208434000049],
            [-80.906353626999987, 35.384878663000052],
            [-80.90614267899997, 35.384367578000024],
            [-80.905780099999959, 35.383524194000074],
            [-80.904968365999935, 35.381718539000076],
            [-80.90472376699995, 35.381190089000029],
            [-80.904334564999942, 35.380398897000077],
            [-80.903734783999937, 35.379245376000029],
            [-80.90329918499998, 35.378450890000067],
            [-80.902756057999966, 35.377467656000078],
            [-80.902685004999967, 35.376981409000052],
            [-80.90259879499996, 35.376428698000041],
            [-80.902524301999961, 35.37597555900004],
            [-80.902438850999943, 35.375489875000028],
            [-80.902292794999937, 35.374732287000029],
            [-80.902130604999968, 35.37395289300008],
            [-80.902008739999985, 35.373396218000039],
            [-80.901853342999971, 35.372723611000026],
            [-80.901715684999942, 35.372163793000027],
            [-80.901547715999982, 35.371515593000026],
            [-80.901298098999973, 35.370619826000052],
            [-80.901092156999937, 35.36992536300005],
            [-80.900877354999977, 35.369236071000046],
            [-80.900700979999954, 35.368692409000062],
            [-80.900547446999951, 35.368235828000024],
            [-80.900400222999963, 35.367809431000069],
            [-80.900285442999973, 35.36748570900005],
            [-80.900112006999962, 35.36700815100005],
            [-80.89992958199997, 35.366519685000071],
            [-80.899731396999982, 35.366004288000056],
            [-80.899543929999936, 35.365536904000066],
            [-80.899277371999972, 35.364899189000027],
            [-80.899110664999967, 35.364506875000075],
            [-80.898890225999935, 35.363999792000072],
            [-80.89869516899995, 35.363563692000071],
            [-80.898234767999952, 35.362992685000052],
            [-80.898192194999979, 35.362363110000047],
            [-80.898462203999941, 35.362067109000066],
            [-80.898579100999939, 35.361704946000032],
            [-80.898739722999949, 35.361187991000065],
            [-80.898927119999939, 35.360587041000031],
            [-80.89925348099996, 35.359474799000054],
            [-80.899387907999937, 35.359016860000054],
            [-80.899534643999971, 35.358513559000073],
            [-80.899789616999954, 35.357587846000058],
            [-80.899927395999953, 35.35705194500008],
            [-80.900045207999938, 35.356558883000048],
            [-80.90021121999996, 35.355842241000062],
            [-80.900395208999953, 35.355007914000055],
            [-80.900606937999953, 35.354010359000029],
            [-80.900724011999955, 35.35342838300005],
            [-80.900874481999949, 35.352642985000045],
            [-80.900996321999969, 35.352004168000065],
            [-80.901095832999943, 35.351445031000026],
            [-80.901200356999937, 35.350799547000065],
            [-80.90134037699994, 35.349857008000072],
            [-80.901444482999977, 35.349078427000052],
            [-80.901723493999953, 35.346734679000065],
            [-80.901785964999988, 35.346137673000044],
            [-80.901855139999952, 35.345344451000074],
            [-80.901919480999936, 35.344426010000063],
            [-80.901979643999937, 35.343348509000066],
            [-80.902011139999956, 35.342787722000025],
            [-80.902039646999981, 35.342148039000051],
            [-80.902057465999974, 35.341526114000033],
            [-80.902064235999944, 35.341142432000026],
            [-80.902075886999967, 35.340611956000032],
            [-80.902082400999973, 35.339642430000026],
            [-80.902075932999935, 35.33903302300007],
            [-80.902065256999947, 35.33854982400004],
            [-80.902055798999982, 35.338246879000053],
            [-80.902033546999974, 35.337539029000027],
            [-80.90200436799995, 35.336853069000028],
            [-80.901734328999964, 35.331919041000049],
            [-80.901704410999969, 35.331374093000079],
            [-80.90167751599995, 35.330836502000068],
            [-80.901614324999969, 35.329740069000025],
            [-80.901576859999977, 35.329099605000067],
            [-80.901538755999979, 35.32854130700008],
            [-80.901508876999969, 35.328040643000065],
            [-80.901475324999979, 35.327470324000046],
            [-80.901444187999971, 35.326935718000072],
            [-80.901408300999947, 35.326375675000065],
            [-80.901379848999966, 35.325845474000062],
            [-80.901346612999987, 35.325223665000067],
            [-80.901306150999972, 35.324581262000038],
            [-80.901278291999972, 35.324091204000069],
            [-80.901243927999985, 35.323565358000053],
            [-80.901209453999968, 35.322975995000036],
            [-80.901188954999952, 35.32263479900007],
            [-80.90117227799999, 35.322329962000026],
            [-80.901140601999941, 35.321786280000026],
            [-80.901119254999969, 35.321440536000068],
            [-80.901065666999955, 35.32051595400003],
            [-80.901030274999982, 35.319976202000078],
            [-80.900989297999956, 35.319310485000074],
            [-80.900941461999935, 35.318478303000063],
            [-80.90087884999997, 35.31740787800004],
            [-80.900847797999972, 35.316815459000054],
            [-80.900801139999942, 35.316058471000076],
            [-80.900777919999939, 35.315692717000047],
            [-80.900756339999987, 35.314919777000057],
            [-80.900923159999934, 35.314151533000029],
            [-80.901305534999949, 35.312462356000026],
            [-80.901498910999976, 35.311671712000077],
            [-80.90167244099996, 35.310958291000077],
            [-80.901808985999935, 35.310370801000033],
            [-80.902056245999972, 35.309253781000052],
            [-80.902253460999987, 35.308362589000069],
            [-80.902353345999984, 35.30793101200004],
            [-80.902473971999939, 35.307414059000052],
            [-80.902562805999935, 35.307033950000061],
            [-80.902753314999984, 35.306201259000034],
            [-80.902899862999959, 35.305575909000027],
            [-80.90301846899996, 35.305061092000074],
            [-80.903220580999971, 35.304164462000074],
            [-80.903358398999956, 35.303550542000039],
            [-80.903498527999943, 35.302898105000054],
            [-80.903634847999967, 35.302230633000079],
            [-80.903763506999951, 35.301564451000047],
            [-80.903839504999951, 35.301138915000024],
            [-80.903914395999948, 35.300689729000055],
            [-80.904023165999945, 35.299982080000063],
            [-80.904069309999954, 35.299661504000028],
            [-80.904194608999944, 35.298722209000061],
            [-80.904282338999963, 35.297957416000031],
            [-80.904338686999949, 35.297388127000033],
            [-80.904385974999968, 35.296852086000058],
            [-80.904454400999953, 35.295890186000065],
            [-80.904491315999962, 35.29520605600004],
            [-80.90450698799998, 35.294838403000028],
            [-80.904521074999934, 35.294458443000053],
            [-80.904534324999986, 35.293988995000063],
            [-80.904540481999959, 35.293695892000073],
            [-80.904548290999969, 35.293210482000063],
            [-80.904550852999989, 35.292445010000051],
            [-80.904545994999978, 35.291980354000032],
            [-80.904539711999973, 35.291634916000078],
            [-80.904530749999935, 35.291280552000046],
            [-80.904510252999955, 35.290772512000046],
            [-80.904466486999979, 35.28998488700006],
            [-80.904424027999937, 35.289324729000043],
            [-80.904375946999949, 35.288700838000068],
            [-80.904314512999974, 35.28801620400003],
            [-80.90426957699998, 35.287585935000038],
            [-80.904215527999952, 35.287112813000078],
            [-80.904153820999966, 35.286599184000067],
            [-80.904088037999941, 35.286110588000042],
            [-80.90394306099995, 35.285134980000066],
            [-80.903861515999949, 35.284634636000078],
            [-80.903785518999939, 35.284208362000072],
            [-80.903651565999951, 35.283489597000028],
            [-80.903439929999934, 35.282453849000035],
            [-80.903289878999942, 35.281784386000027],
            [-80.903138693999949, 35.28114127200007],
            [-80.903047042999958, 35.280764809000061],
            [-80.902948850999962, 35.280373122000071],
            [-80.902790974999959, 35.27978590400005],
            [-80.902455485999951, 35.27861524900004],
            [-80.902252256999986, 35.277952736000032],
            [-80.902097602999959, 35.277479260000064],
            [-80.901887041999942, 35.276852224000038],
            [-80.90149017899995, 35.275738280000041],
            [-80.901219031999972, 35.27501979200008],
            [-80.901038353999979, 35.274556426000061],
            [-80.900760891999937, 35.27386716500007],
            [-80.900444634999985, 35.273109079000051],
            [-80.900025394999943, 35.272153591000063],
            [-80.899802972999964, 35.271603990000074],
            [-80.899773299999936, 35.271036621000064],
            [-80.899757453999939, 35.270499698000037],
            [-80.899717801999941, 35.26965529000006],
            [-80.899688988999969, 35.269203179000044],
            [-80.899647211999934, 35.26865099500003],
            [-80.899611514999947, 35.26823758300003],
            [-80.899558881999951, 35.267709767000042],
            [-80.89946704099998, 35.266921947000071],
            [-80.899400499999956, 35.266425983000033],
            [-80.899304239999935, 35.265773638000042],
            [-80.899220938999974, 35.265256050000062],
            [-80.899127746999966, 35.264727681000068],
            [-80.899034234999988, 35.264235493000058],
            [-80.898942906999935, 35.263782670000069],
            [-80.898808220999967, 35.26315810400007],
            [-80.898589293999976, 35.262753136000072],
            [-80.898673869999982, 35.26226910400004],
            [-80.899083298999983, 35.261971686000038],
            [-80.89975939499999, 35.261291596000035],
            [-80.900287729999945, 35.260742328000049],
            [-80.900769376999961, 35.260223395000025],
            [-80.901364341999965, 35.259566527000061],
            [-80.902017010999941, 35.258824111000024],
            [-80.902511278999953, 35.258239952000054],
            [-80.903094007999982, 35.257524199000045],
            [-80.903541018999988, 35.256958696000027],
            [-80.904001638999944, 35.256359887000031],
            [-80.904540923999946, 35.255624885000032],
            [-80.905331850999971, 35.254491296000026],
            [-80.905833390999987, 35.253738209000062],
            [-80.906219692999969, 35.253126491000046],
            [-80.906491107999955, 35.252684926000029],
            [-80.906784409999943, 35.25219575400007],
            [-80.906996412999945, 35.251875025000061],
            [-80.907510284999944, 35.251382489000036],
            [-80.908206189999987, 35.250723140000048],
            [-80.908725431999983, 35.250229585000056],
            [-80.909368374999985, 35.249624016000041],
            [-80.909894245999965, 35.249129725000046],
            [-80.910390312999937, 35.248672474000045],
            [-80.910953080999946, 35.248143864000042],
            [-80.911275134999983, 35.247834071000057],
            [-80.911777719999975, 35.247340763000068],
            [-80.912129280999977, 35.246988393000038],
            [-80.912603701999956, 35.246502639000028],
            [-80.913225074999957, 35.245837889000029],
            [-80.914131607999934, 35.244828375000054],
            [-80.914960635999989, 35.243850136000049],
            [-80.915714962999971, 35.242905861000054],
            [-80.916219875999957, 35.24225018900006],
            [-80.916475336999952, 35.241907444000049],
            [-80.916733554999951, 35.241554111000028],
            [-80.91699684799994, 35.241187370000034],
            [-80.917350381999938, 35.24068425400003],
            [-80.91761980299998, 35.240291978000073],
            [-80.91801623799995, 35.239700476000053],
            [-80.918209262999937, 35.23940600800006],
            [-80.918452701999968, 35.239026380000041],
            [-80.918877907999956, 35.238346112000045],
            [-80.919211756999971, 35.237794935000068],
            [-80.919615338999961, 35.237106935000043],
            [-80.920039972999973, 35.236342948000072],
            [-80.920557333999966, 35.235362413000075],
            [-80.920874946999959, 35.234734777000028],
            [-80.921075911999935, 35.23432013200005],
            [-80.921384941999975, 35.233659550000027],
            [-80.921556451999948, 35.233280576000027],
            [-80.921805621999965, 35.232712374000073],
            [-80.921984100999964, 35.232293051000056],
            [-80.922226204999959, 35.231703492000065],
            [-80.922419567999953, 35.231213280000077],
            [-80.922563871999955, 35.230835465000041],
            [-80.922735585999988, 35.230372948000024],
            [-80.922876336999934, 35.22998246800006],
            [-80.922982220999984, 35.229680052000049],
            [-80.923171645999957, 35.229120812000076],
            [-80.923414915999956, 35.22860614800004],
            [-80.92387716799999, 35.228034688000037],
            [-80.924176025999941, 35.22765617500005],
            [-80.924705948999986, 35.22696763600004],
            [-80.924982387999989, 35.226599100000044],
            [-80.92541816399995, 35.226003456000058],
            [-80.925697930999945, 35.225612675000036],
            [-80.926098215999957, 35.225043385000049],
            [-80.926361449999945, 35.22465980000004],
            [-80.926731074999964, 35.224107966000076],
            [-80.926966205999975, 35.223747959000036],
            [-80.927233218999959, 35.223329312000033],
            [-80.92755492799995, 35.222813125000073],
            [-80.927818180999964, 35.222379915000033],
            [-80.928234290999967, 35.22167289500004],
            [-80.928467055999988, 35.221267655000077],
            [-80.929079483999942, 35.22017461300004],
            [-80.929680395999981, 35.219021762000068],
            [-80.930070267999952, 35.21823239500003],
            [-80.930399632999979, 35.217532443000039],
            [-80.93073697899996, 35.216850574000034],
            [-80.930911039999955, 35.21649611600003],
            [-80.931186842999978, 35.215908940000077],
            [-80.931658212999935, 35.214857817000052],
            [-80.932283086999973, 35.213498309000045],
            [-80.932524742999988, 35.21297467900007],
            [-80.932963044999951, 35.212049862000072],
            [-80.933312254999976, 35.211338786000056],
            [-80.933691711999984, 35.210522018000063],
            [-80.934036540999955, 35.20977300900006],
            [-80.934479170999964, 35.208839011000066],
            [-80.93475373199999, 35.20824478000003],
            [-80.93499873899998, 35.207735099000047],
            [-80.935357834999934, 35.206971526000075],
            [-80.935615484999971, 35.206392701000027],
            [-80.935953088999952, 35.205698464000079],
            [-80.936195593999969, 35.205186871000024],
            [-80.936518794999984, 35.204488949000051],
            [-80.936963737999974, 35.203530994000062],
            [-80.937246719999962, 35.20290723800008],
            [-80.937395851999952, 35.202571849000037],
            [-80.93758448899996, 35.20213717200005],
            [-80.937806549999948, 35.201610249000055],
            [-80.93797836799996, 35.201190570000051],
            [-80.938108494999938, 35.200862976000053],
            [-80.938341183999967, 35.200257733000058],
            [-80.938493654999945, 35.199846869000055],
            [-80.938656858999934, 35.199392928000066],
            [-80.938786070999981, 35.199022442000057],
            [-80.93900021099995, 35.198381547000054],
            [-80.93920525499999, 35.197731035000061],
            [-80.939445206999949, 35.196925023000063],
            [-80.939570525999954, 35.196480025000028],
            [-80.939763836999987, 35.195756847000041],
            [-80.939868176999937, 35.195345131000067],
            [-80.940028071999961, 35.19467809300005],
            [-80.940133237999987, 35.194215191000069],
            [-80.940304284999968, 35.193423186000075],
            [-80.940396694999947, 35.192963905000056],
            [-80.940518663999967, 35.192311868000047],
            [-80.940609431999974, 35.191782293000074],
            [-80.940710154999977, 35.191122918000076],
            [-80.940845096999965, 35.190121678000025],
            [-80.940937703999964, 35.189288126000065],
            [-80.941008890999967, 35.188456805000044],
            [-80.941054654999959, 35.187803121000059],
            [-80.941091342999982, 35.187185611000075],
            [-80.941118408999955, 35.186628250000069],
            [-80.941133957999966, 35.186232802000063],
            [-80.941146883999977, 35.185576061000063],
            [-80.941148533999979, 35.184295831000043],
            [-80.941120916999978, 35.183284894000053],
            [-80.941066235999983, 35.182338714000025],
            [-80.941027670999972, 35.18181366400006],
            [-80.94099436099998, 35.181411321000041],
            [-80.940947230999939, 35.180899959000044],
            [-80.940860638999936, 35.18014502300008],
            [-80.940710946999957, 35.179045562000056],
            [-80.940604103999988, 35.178356793000034],
            [-80.940529995999952, 35.177929186000028],
            [-80.940434265999954, 35.177362729000038],
            [-80.940526529999943, 35.176906713000051],
            [-80.940760959999977, 35.176078627000038],
            [-80.940964062999967, 35.17534147300006],
            [-80.941227293999987, 35.174556270000039],
            [-80.941567016999954, 35.173581872000057],
            [-80.94182456499999, 35.172818494000069],
            [-80.941997396999966, 35.17228202900003],
            [-80.94214328999999, 35.171865666000031],
            [-80.942303083999946, 35.171470172000056],
            [-80.943071539999949, 35.170719883000061],
            [-80.943478711999944, 35.170348922000073],
            [-80.943978138999967, 35.169895394000037],
            [-80.944335278999972, 35.169564620000074],
            [-80.945070859999987, 35.168882318000044],
            [-80.945751565999956, 35.168264713000042],
            [-80.946495311999968, 35.167585053000039],
            [-80.946872593999956, 35.167248432000065],
            [-80.94734337999995, 35.166820186000052],
            [-80.947913090999975, 35.166284599000051],
            [-80.948348350999936, 35.165861645000064],
            [-80.949092089999965, 35.165164685000036],
            [-80.950515687999939, 35.163827866000076],
            [-80.95142089999996, 35.162946840000075],
            [-80.951776003999953, 35.162592151000069],
            [-80.952213993999976, 35.162144699000066],
            [-80.952612989999977, 35.161730449000061],
            [-80.953112228999942, 35.161198357000046],
            [-80.953830115999949, 35.160410888000058],
            [-80.954477834999977, 35.15967762300005],
            [-80.955140233999941, 35.158904114000052],
            [-80.955644422999967, 35.158298916000035],
            [-80.956150823999963, 35.157674709000048],
            [-80.956830169999989, 35.156809707000036],
            [-80.957187961999978, 35.156339910000042],
            [-80.957383432999961, 35.156076691000067],
            [-80.957610791999969, 35.155765676000044],
            [-80.957900416999962, 35.155363974000068],
            [-80.95859851299997, 35.15438316500007],
            [-80.959079333999966, 35.153688678000037],
            [-80.959819746999983, 35.152590278000048],
            [-80.960423183999978, 35.151745787000038],
            [-80.961630063999962, 35.150065157000029],
            [-80.961988304999977, 35.149564763000058],
            [-80.962701288999938, 35.148533274000044],
            [-80.963197851999951, 35.147814717000074],
            [-80.963596913999936, 35.147225997000078],
            [-80.963890125999967, 35.14680112700006],
            [-80.964278512999954, 35.146224691000043],
            [-80.964544631999956, 35.145819933000041],
            [-80.964916209999956, 35.145247544000028],
            [-80.965753979999988, 35.143918524000071],
            [-80.966155298999979, 35.143263756000067],
            [-80.966458147999958, 35.142755995000073],
            [-80.966721417999963, 35.142306508000047],
            [-80.967020735999938, 35.141785535000054],
            [-80.967424446999985, 35.141077047000067],
            [-80.967699394999954, 35.140572442000064],
            [-80.968110802999945, 35.139820672000042],
            [-80.96842126599995, 35.13924030700008],
            [-80.968658481999967, 35.138793949000046],
            [-80.969874432999973, 35.137099207000063],
            [-80.970064071999957, 35.136834848000035],
            [-80.970572945999947, 35.136116105000042],
            [-80.970896213999936, 35.135650607000059],
            [-80.971348250999938, 35.13498738800007],
            [-80.971647920999942, 35.13453949500007],
            [-80.972994116999985, 35.132489170000042],
            [-80.97514817299998, 35.129151212000068],
            [-80.975351257999989, 35.128836469000078],
            [-80.975586116999978, 35.128467465000028],
            [-80.976858959999959, 35.126245487000062],
            [-80.977097293999975, 35.125908029000072],
            [-80.977329491999967, 35.125567696000076],
            [-80.977555505999987, 35.125224565000053],
            [-80.977775282999971, 35.124878711000065],
            [-80.97798877699995, 35.124530211000035],
            [-80.978195938999988, 35.12417914100007],
            [-80.978396723999936, 35.123825579000027],
            [-80.97870925899997, 35.123247501000037],
            [-80.979050798999936, 35.122585505000075],
            [-80.979271376999975, 35.12215210200003],
            [-80.979476409999961, 35.12166116800006],
            [-80.979732260999981, 35.121031953000056],
            [-80.979825110999968, 35.120883125000034],
            [-80.979910905999986, 35.120731466000052],
            [-80.979989518999957, 35.12057720100006],
            [-80.980060832999982, 35.120420560000071],
            [-80.980124741999987, 35.120261775000074],
            [-80.980181149999964, 35.120101084000055],
            [-80.98022997399994, 35.119938725000054],
            [-80.980271140999946, 35.11977494000007],
            [-80.980304589999946, 35.119609973000024],
            [-80.980781662999959, 35.118909956000039],
            [-80.980976586999986, 35.118457895000063],
            [-80.981268985999975, 35.117760206000071],
            [-80.981565746999934, 35.117005576000054],
            [-80.981697937999968, 35.116654082000025],
            [-80.98185728499999, 35.116217793000033],
            [-80.982075079999959, 35.115607244000046],
            [-80.982246867999947, 35.115104619000078],
            [-80.982555160999937, 35.114126695000039],
            [-80.982735994999985, 35.113527114000078],
            [-80.982969208999975, 35.112720614000068],
            [-80.983159433999958, 35.112039241000048],
            [-80.983388144999935, 35.111160300000051],
            [-80.983572939999988, 35.110433003000026],
            [-80.983670361999941, 35.110032243000035],
            [-80.983808171999954, 35.10943694100007],
            [-80.983891195999945, 35.109061113000053],
            [-80.983992791999981, 35.10857238400007],
            [-80.98411779199995, 35.107932450000078],
            [-80.984239891999948, 35.10723858700004],
            [-80.98482140599998, 35.106356467000069],
            [-80.985248077999984, 35.10578892500007],
            [-80.986333427999966, 35.104346934000034],
            [-80.98709377299997, 35.103380339000068],
            [-80.987544014999969, 35.102786843000047],
            [-80.987829561999945, 35.102396171000066],
            [-80.988124945999971, 35.10199405000003],
            [-80.988451794999946, 35.101541788000077],
            [-80.988672017999988, 35.101232960000061],
            [-80.989019815999939, 35.100759245000063],
            [-80.989620829999978, 35.099977678000073],
            [-80.989900865999971, 35.099610200000029],
            [-80.99053539099998, 35.098763826000038],
            [-80.990838246999942, 35.098352565000027],
            [-80.991155627999944, 35.097914882000055],
            [-80.991493362999961, 35.097457791000068],
            [-80.991711932999976, 35.097158797000077],
            [-80.99240492399997, 35.096223451000071],
            [-80.993248894999965, 35.095075431000055],
            [-80.993966910999973, 35.094105217000049],
            [-80.994569472999956, 35.093307648000064],
            [-80.994938574999935, 35.092811773000051],
            [-80.995384108999986, 35.092200360000049],
            [-80.995721295999942, 35.091758700000071],
            [-80.995927569999935, 35.091486304000057],
            [-80.996195532999934, 35.091142969000032],
            [-80.996616149999966, 35.090594650000071],
            [-80.997022638999965, 35.090047005000031],
            [-80.997415115999956, 35.089500261000069],
            [-80.99761097999999, 35.089222557000028],
            [-80.997883668999975, 35.08884305600003],
            [-80.998382842999945, 35.088165298000035],
            [-80.998655097999972, 35.08781282800004],
            [-80.998976277999986, 35.087390868000057],
            [-80.999178907999976, 35.087120352000056],
            [-81.000189872999954, 35.085748737000074],
            [-81.000716960999966, 35.085013415000049],
            [-81.001081479999982, 35.084490610000046],
            [-81.00167150499999, 35.083633017000068],
            [-81.002078241999982, 35.083026724000035],
            [-81.002381620999984, 35.082559759000048],
            [-81.002734227999952, 35.081999161000056],
            [-81.003341155999976, 35.081008510000061],
            [-81.003801644999953, 35.080231815000047],
            [-81.004283520999934, 35.079392374000065],
            [-81.004711713999939, 35.078609821000043],
            [-81.005310598999984, 35.077456509000058],
            [-81.005643154999973, 35.076785531000041],
            [-81.006033416999969, 35.075958563000029],
            [-81.006433625999989, 35.075076686000045],
            [-81.006800603999977, 35.074230647000036],
            [-81.007157117999952, 35.073384068000053],
            [-81.007363672999986, 35.072879210000053],
            [-81.007717887999945, 35.071986909000032],
            [-81.007999599999948, 35.071259766000026],
            [-81.008252611999978, 35.070589211000026],
            [-81.008639184999936, 35.069505505000052],
            [-81.009008840999968, 35.06835323200005],
            [-81.009357283999975, 35.067132387000072],
            [-81.009655652999982, 35.065937213000041],
            [-81.009968349999951, 35.064471658000059],
            [-81.010235521999959, 35.063184626000066],
            [-81.010388140999964, 35.062343247000058],
            [-81.010518044999969, 35.061506397000073],
            [-81.010605819999967, 35.060881957000049],
            [-81.010716622999951, 35.060200270000053],
            [-81.010845038999946, 35.059330931000034],
            [-81.011190064999937, 35.056709857000044],
            [-81.011378459999946, 35.055299146000038],
            [-81.011917813999958, 35.051227185000073],
            [-81.012408321999942, 35.047681858000033],
            [-81.012638282999944, 35.047333599000069],
            [-81.012882898999976, 35.046953317000032],
            [-81.013281338999946, 35.046316741000055],
            [-81.01354205399997, 35.045889892000048],
            [-81.014059862999943, 35.045039607000035],
            [-81.014545906999956, 35.044213692000028],
            [-81.014881194999987, 35.043622074000041],
            [-81.015077365999957, 35.043284780000079],
            [-81.015341457999966, 35.042827952000039],
            [-81.015781235999953, 35.042025763000026],
            [-81.016379712999935, 35.04087237400006],
            [-81.016840292999973, 35.039923613000042],
            [-81.017252257999985, 35.039009488000033],
            [-81.017579082999987, 35.038248297000052],
            [-81.017807189999985, 35.037698105000061],
            [-81.017976622999981, 35.037274925000077],
            [-81.018125217999966, 35.036892366000075],
            [-81.01833054399998, 35.036347953000075],
            [-81.018675755999936, 35.035399974000029],
            [-81.019023507999975, 35.034407414000043],
            [-81.019204678999984, 35.033870012000079],
            [-81.019460319999951, 35.033046278000029],
            [-81.01980846899994, 35.031825380000043],
            [-81.019988816999955, 35.031144174000076],
            [-81.020151146999979, 35.030478790000075],
            [-81.020327915999985, 35.029711033000069],
            [-81.020467674999963, 35.029064135000056],
            [-81.02073788499996, 35.027754414000071],
            [-81.020989337999936, 35.026460919000044],
            [-81.021171291999963, 35.025556847000075],
            [-81.021420629999966, 35.024371557000052],
            [-81.021670761999985, 35.023160145000077],
            [-81.021828664999987, 35.022434642000064],
            [-81.021970016999944, 35.021745808000048],
            [-81.022188187999973, 35.020652881000046],
            [-81.022281988999964, 35.020230522000077],
            [-81.02245508499999, 35.019395040000063],
            [-81.024180141999977, 35.016826473000037],
            [-81.024684744999945, 35.016078958000037],
            [-81.025515266999946, 35.014871822000032],
            [-81.025989657999958, 35.014169099000071],
            [-81.026544083999966, 35.013336005000042],
            [-81.026906513999961, 35.012805180000043],
            [-81.027495525999939, 35.011941829000079],
            [-81.027976525999975, 35.011242852000066],
            [-81.028272804999972, 35.010811113000045],
            [-81.02908692799997, 35.009636013000033],
            [-81.02949979999994, 35.009017736000033],
            [-81.029739688999939, 35.008645100000024],
            [-81.030144078999967, 35.008030864000034],
            [-81.03045653099997, 35.007565671000066],
            [-81.030834701999936, 35.007028795000053],
            [-81.031328145999964, 35.006322855000064],
            [-81.03173008999994, 35.005734489000076],
            [-81.031971574999943, 35.005376269000067],
            [-81.03269077899995, 35.004334305000043],
            [-81.033907092999982, 35.002496154000028],
            [-81.034373700999936, 35.001759985000035],
            [-81.035021582999946, 35.000689794000039],
            [-81.035336584999982, 35.00015103100003],
            [-81.035834941999951, 34.999267696000061],
            [-81.036183764999976, 34.998623721000058],
            [-81.036781659999974, 34.99747020500007],
            [-81.037324492999971, 34.996335748000035],
            [-81.037855004999983, 34.995123037000042],
            [-81.038234117999934, 34.994194010000058],
            [-81.038571761999947, 34.993312591000063],
            [-81.038745480999978, 34.992838948000042],
            [-81.039079776999984, 34.991888544000062],
            [-81.039424893999978, 34.990803357000061],
            [-81.039772583999934, 34.989582374000065],
            [-81.03998390199996, 34.988770900000077],
            [-81.040100934999941, 34.988273080000056],
            [-81.040287832999979, 34.987434217000043],
            [-81.040501111999959, 34.986379535000026],
            [-81.040625899999952, 34.985686484000041],
            [-81.040734522999969, 34.985001329000056],
            [-81.040857364999965, 34.984146238000051],
            [-81.040967748999947, 34.983274163000033],
            [-81.041058662999944, 34.982351419000054],
            [-81.04114194999994, 34.981203347000076],
            [-81.041174373999979, 34.980628852000052],
            [-81.041200972999945, 34.979965992000075],
            [-81.041216784999961, 34.979200415000037],
            [-81.041216177999956, 34.977946345000078],
            [-81.041198872999985, 34.977167820000034],
            [-81.041181895999955, 34.976789146000044],
            [-81.041129318999936, 34.975780775000032],
            [-81.041098090999981, 34.975305932000026],
            [-81.041023033999977, 34.974356919000058],
            [-81.040902180999979, 34.973258858000065],
            [-81.040726327999948, 34.972013167000057],
            [-81.040593678999983, 34.971203820000028],
            [-81.04045076999995, 34.970429363000051],
            [-81.040320115999975, 34.969766943000025],
            [-81.040078973999982, 34.968654488000027],
            [-81.039799328999948, 34.967535259000044],
            [-81.039475600999936, 34.96639250100003],
            [-81.039234264999948, 34.965602465000075],
            [-81.039079759999936, 34.965116967000029],
            [-81.03884015999995, 34.964409342000067],
            [-81.038406600999963, 34.963207125000054],
            [-81.03811049799998, 34.962438534000057],
            [-81.037935964999974, 34.962014174000046],
            [-81.037685368999973, 34.961415337000062],
            [-81.037337959999945, 34.960590830000058],
            [-81.036630387999935, 34.958973537000077],
            [-81.036300331999939, 34.958191365000062],
            [-81.035884750999969, 34.957235329000071],
            [-81.035618435999936, 34.95660719600005],
            [-81.034974431999956, 34.955104721000055],
            [-81.034208757999977, 34.953274614000065],
            [-81.033906993999949, 34.952573335000068],
            [-81.033672611999975, 34.952046387000053],
            [-81.033411992999959, 34.951471430000026],
            [-81.033253017999982, 34.951118658000041],
            [-81.033029477999946, 34.950634235000052],
            [-81.032812863999936, 34.95017408700005],
            [-81.032660151999949, 34.949843034000025],
            [-81.032343071999946, 34.949115465000034],
            [-81.032093803999942, 34.948556232000044],
            [-81.031888785999968, 34.948062857000025],
            [-81.031514352999977, 34.947148899000069],
            [-81.03120557799997, 34.946421735000058],
            [-81.031000344999939, 34.945953898000027],
            [-81.030837023999936, 34.94556534000003],
            [-81.030372092999983, 34.944465511000033],
            [-81.030085087999964, 34.943808021000052],
            [-81.029426993999948, 34.942348327000047],
            [-81.029230430999974, 34.941844687000071],
            [-81.029012784999964, 34.941305598000042],
            [-81.028641388999972, 34.940428048000058],
            [-81.028085759999954, 34.939169363000076],
            [-81.027728885999977, 34.93834398100006],
            [-81.027465391999954, 34.937586329000055],
            [-81.027824215999942, 34.936861879000048],
            [-81.028282037999986, 34.93597352300003],
            [-81.028513262999979, 34.935511614000063],
            [-81.028834408999955, 34.934912669000028],
            [-81.02954815999999, 34.933528958000068],
            [-81.02985618799994, 34.932952641000043],
            [-81.030317183999955, 34.932074813000042],
            [-81.030520960999979, 34.931675319000078],
            [-81.03087845999994, 34.930998665000061],
            [-81.031373871999961, 34.930022201000043],
            [-81.031820324999956, 34.929075542000078],
            [-81.032350497999971, 34.927862808000043],
            [-81.032824760999972, 34.926671080000062],
            [-81.033257114999969, 34.925468590000037],
            [-81.033647201999941, 34.924256339000067],
            [-81.033994702999962, 34.923035326000047],
            [-81.034295679999957, 34.921823724000035],
            [-81.03456082799994, 34.920571083000027],
            [-81.034778980999988, 34.919329927000035],
            [-81.034953615999939, 34.918084092000072],
            [-81.035084587999961, 34.916834625000035],
            [-81.035171786999967, 34.915582571000073],
            [-81.035215143999949, 34.914328966000028],
            [-81.035214624999981, 34.913074850000044],
            [-81.035170232999974, 34.91182126800004],
            [-81.035082005999982, 34.910569259000056],
            [-81.034950016999971, 34.909319860000039],
            [-81.034774381999966, 34.908074117000069],
            [-81.034555250999972, 34.90683307300003],
            [-81.034289136999973, 34.905580568000062],
            [-81.033987248999949, 34.904369117000044],
            [-81.03363885999994, 34.90314827900005],
            [-81.033247921999987, 34.901936222000074],
            [-81.032814760999941, 34.900733943000034],
            [-81.032339735999983, 34.899542444000076],
            [-81.031808833999946, 34.898329961000059],
            [-81.031265720999954, 34.89719573900004],
            [-81.030667623999989, 34.896042479000073],
            [-81.030029454999976, 34.894903893000048],
            [-81.029351743999939, 34.893780931000038],
            [-81.028635057999963, 34.892674523000039],
            [-81.027848392999942, 34.891540270000064],
            [-81.027087169999959, 34.89051502500007],
            [-81.026257255999951, 34.889463727000077],
            [-81.025390942999934, 34.888432570000077],
            [-81.024488941999948, 34.887422400000048],
            [-81.023552002999963, 34.886434055000052],
            [-81.022526752999966, 34.885414857000058],
            [-81.02157648299999, 34.884526136000034],
            [-81.020539535999944, 34.883608135000031],
            [-81.019470944999966, 34.882715138000037],
            [-81.018371584999954, 34.881847871000048],
            [-81.017242367999984, 34.88100705800008],
            [-81.016003532999946, 34.880137155000057],
            [-81.014898175999974, 34.879407580000077],
            [-81.013685134999946, 34.878650236000055],
            [-81.012446112999953, 34.877921988000026],
            [-81.011182180999981, 34.877223467000078],
            [-81.009894391999978, 34.876555253000049],
            [-81.008474220999972, 34.875865181000052],
            [-81.007251406999956, 34.875311866000061],
            [-81.005898437999974, 34.874737744000072],
            [-81.004525970999964, 34.874195976000067],
            [-81.003135147999956, 34.873687014000041],
            [-81.001727112999959, 34.873211277000053],
            [-81.000164296999969, 34.872726818000046],
            [-80.998864160999972, 34.872361048000073],
            [-80.997411583999963, 34.871987251000064],
            [-80.99594652199994, 34.871648084000071],
            [-80.994470251999985, 34.871343845000069],
            [-80.992983982999988, 34.871074779000026],
            [-80.991322572999934, 34.870816013000024],
            [-80.989643741999942, 34.870608040000036],
            [-80.988132105999966, 34.870461946000034],
            [-80.987537127999985, 34.870409738000035],
            [-80.986442269999941, 34.870399961000032],
            [-80.985434830999964, 34.87036773300008],
            [-80.984530559999939, 34.870314407000023],
            [-80.98362844899998, 34.870270251000079],
            [-80.982401204999974, 34.870218668000064],
            [-80.981855059999987, 34.870199219000028],
            [-80.981352773999959, 34.870199636000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 153,
        "SolutionID": "PI-28:C",
        "Shape_Length": 2.3001769675427952,
        "Shape_Area": 0.11202781108794944
      }
    },
    {
      "type": "Feature",
      "id": 154,
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-80.981352773999959, 34.870199636000052],
            [-80.980594408999934, 34.870235815000058],
            [-80.979367160999971, 34.870305354000038],
            [-80.978465792999941, 34.870362659000079],
            [-80.977562301999967, 34.870429175000027],
            [-80.977053111999965, 34.87045385600004],
            [-80.976352650999956, 34.870547681000062],
            [-80.974530791999939, 34.870643324000071],
            [-80.972819634999951, 34.870869817000028],
            [-80.97153293599996, 34.871074236000027],
            [-80.970046537999963, 34.871342885000047],
            [-80.968570118999935, 34.871646712000029],
            [-80.967104946999939, 34.871985457000051],
            [-80.965652232999958, 34.872358839000071],
            [-80.963993929999958, 34.87283044600008],
            [-80.962788907999936, 34.873208262000048],
            [-80.96138068199997, 34.873683598000071],
            [-80.959989623999945, 34.874192175000076],
            [-80.958616933999963, 34.874733554000045],
            [-80.957263736999948, 34.87530729000008],
            [-80.955710193999948, 34.876015617000064],
            [-80.954620248999959, 34.876549921000048],
            [-80.953332158999956, 34.877217783000049],
            [-80.952067935999935, 34.877915945000041],
            [-80.950828630999979, 34.878643830000044],
            [-80.949615260999963, 34.879400840000073],
            [-80.948216426999977, 34.880329998000036],
            [-80.947270412999956, 34.880999648000056],
            [-80.946140849999949, 34.88184013800003],
            [-80.945041119999985, 34.882707099000072],
            [-80.943972149999979, 34.883599801000059],
            [-80.942934820999938, 34.884517508000044],
            [-80.941929993999963, 34.885459458000071],
            [-80.941544186999977, 34.885829398000055],
            [-80.940958508999984, 34.886424867000073],
            [-80.940021164999962, 34.887412939000058],
            [-80.939118744999973, 34.888422852000076],
            [-80.938251997999942, 34.889453769000056],
            [-80.937421644999972, 34.89050483300008],
            [-80.936628377999966, 34.891575171000056],
            [-80.936304328999938, 34.892024435000053],
            [-80.935872849999953, 34.892663902000038],
            [-80.935155702999964, 34.893770108000069],
            [-80.934492913999975, 34.894865498000058],
            [-80.933883165999987, 34.895948450000049],
            [-80.933515743999976, 34.896630172000073],
            [-80.933257118999961, 34.89712445400005],
            [-80.933036662999939, 34.89754801600003],
            [-80.932860120999976, 34.89788855300003],
            [-80.932635523999977, 34.898320237000064],
            [-80.932260199999973, 34.899043293000034],
            [-80.931938142999968, 34.899662294000052],
            [-80.931662436999943, 34.90019691100008],
            [-80.931514299999947, 34.900480383000058],
            [-80.931382024999948, 34.900736166000058],
            [-80.931178597999974, 34.901126165000051],
            [-80.930759710999951, 34.901938176000044],
            [-80.930294301999936, 34.902878499000053],
            [-80.930017452999948, 34.90344895700008],
            [-80.929590565999945, 34.904274379000071],
            [-80.929054753999935, 34.905308121000076],
            [-80.928697249999971, 34.905992618000028],
            [-80.928253592999965, 34.906854917000032],
            [-80.92796696299996, 34.907405806000043],
            [-80.927521462999948, 34.908252115000039],
            [-80.927232190999973, 34.908811613000069],
            [-80.926993098999958, 34.909278310000047],
            [-80.926507825999977, 34.910210878000044],
            [-80.926059291999934, 34.911075652000079],
            [-80.925702222999973, 34.911762779000071],
            [-80.925242523999941, 34.912646676000065],
            [-80.924856254999952, 34.913388394000037],
            [-80.924450440999976, 34.914168532000076],
            [-80.92398473999998, 34.915065245000051],
            [-80.923447092999936, 34.916097637000064],
            [-80.92314098899999, 34.916694171000074],
            [-80.922783785999968, 34.917400345000033],
            [-80.922260603999973, 34.918485410000073],
            [-80.921876229999953, 34.919327266000039],
            [-80.921505054999955, 34.920198071000073],
            [-80.921029268999973, 34.921389380000051],
            [-80.920734100999937, 34.922177158000068],
            [-80.920519271999979, 34.922791025000038],
            [-80.920386701999973, 34.923181854000063],
            [-80.920256293999955, 34.923582074000024],
            [-80.92000474799994, 34.924384501000077],
            [-80.919843484999944, 34.924925639000037],
            [-80.919661700999939, 34.925583367000058],
            [-80.919403944999942, 34.926584303000027],
            [-80.919241641999974, 34.927264711000078],
            [-80.919124014999966, 34.92779762400005],
            [-80.919009215999949, 34.928356093000048],
            [-80.918889771999943, 34.928994763000048],
            [-80.918725182999935, 34.929966361000027],
            [-80.918632729999956, 34.930573540000069],
            [-80.918569329999968, 34.931036023000047],
            [-80.918499721999979, 34.931613635000076],
            [-80.918377081999949, 34.932790858000033],
            [-80.918314296999938, 34.933548552000047],
            [-80.918288759999939, 34.933969601000058],
            [-80.918249954999965, 34.934762127000056],
            [-80.918216596999969, 34.93590795700004],
            [-80.918211563999989, 34.936526364000031],
            [-80.918227774999934, 34.937485930000037],
            [-80.918250343999944, 34.938138510000044],
            [-80.918288872999938, 34.938905911000063],
            [-80.918324278999989, 34.939443378000078],
            [-80.918387516999985, 34.940176504000078],
            [-80.918498104999969, 34.941225271000064],
            [-80.918561101999956, 34.941749093000055],
            [-80.918615808999959, 34.942153305000033],
            [-80.918714138999974, 34.942799390000062],
            [-80.918897804999972, 34.94387649500004],
            [-80.918997815999944, 34.944414106000067],
            [-80.919109456999934, 34.94496403200003],
            [-80.91928601099994, 34.945748485000024],
            [-80.919597129999943, 34.947002123000061],
            [-80.919770232999952, 34.947649223000042],
            [-80.919867370999953, 34.947986813000057],
            [-80.920076006999977, 34.948686340000052],
            [-80.920251261999965, 34.949238394000076],
            [-80.920520453999984, 34.950044794000064],
            [-80.920786876999955, 34.950794471000052],
            [-80.92102050699998, 34.95141505600003],
            [-80.921194975999981, 34.951863307000053],
            [-80.92138276899999, 34.952333098000054],
            [-80.92150535899998, 34.952631730000064],
            [-80.921778011999947, 34.953274135000072],
            [-80.922033302999978, 34.953864254000052],
            [-80.922388385999966, 34.954691646000072],
            [-80.922672409999961, 34.955357236000054],
            [-80.922990439999978, 34.956094636000046],
            [-80.923231093999959, 34.956645913000045],
            [-80.923423876999948, 34.957100909000076],
            [-80.923553211999945, 34.957399046000035],
            [-80.923705148999943, 34.957756346000053],
            [-80.923902229999953, 34.958209941000064],
            [-80.924228640999956, 34.958977253000057],
            [-80.924491972999988, 34.959585812000057],
            [-80.92473954999997, 34.960152657000037],
            [-80.924985087999971, 34.960724126000059],
            [-80.925169035999943, 34.96114863400004],
            [-80.925358021999955, 34.961586338000075],
            [-80.925613268999939, 34.962180400000079],
            [-80.926031087999945, 34.963149774000044],
            [-80.926334831999952, 34.963849768000046],
            [-80.92665696399996, 34.964608483000063],
            [-80.926884403999964, 34.96514387700006],
            [-80.927129358999935, 34.965701050000064],
            [-80.927405796999949, 34.96634748200006],
            [-80.92774478299998, 34.96714100500003],
            [-80.928082323999945, 34.96793120600006],
            [-80.928339813999969, 34.968529039000032],
            [-80.928547699999967, 34.969012176000035],
            [-80.928966564999939, 34.969993392000049],
            [-80.929198434999989, 34.970527663000041],
            [-80.929594904999988, 34.971449482000025],
            [-80.929795934999959, 34.971925296000052],
            [-80.93008733299996, 34.972614667000073],
            [-80.930383595999956, 34.97329312200003],
            [-80.930731121999941, 34.974117534000072],
            [-80.931133428999942, 34.975165154000024],
            [-80.930639901999939, 34.975823310000067],
            [-80.930326964999949, 34.976287537000076],
            [-80.930038757999966, 34.976711247000026],
            [-80.929434653999976, 34.977598680000028],
            [-80.929144373999975, 34.978031122000061],
            [-80.928807867999978, 34.978517046000036],
            [-80.928511354999955, 34.978950184000041],
            [-80.927960623999979, 34.979769081000029],
            [-80.927709689999972, 34.980146194000042],
            [-80.92713753999999, 34.98099611400005],
            [-80.926855972999988, 34.981425665000074],
            [-80.926325590999966, 34.982471942000075],
            [-80.925981432999947, 34.982942598000079],
            [-80.925655432999974, 34.983409154000071],
            [-80.925162674999967, 34.984243520000064],
            [-80.924607975999947, 34.985191687000054],
            [-80.924404488999983, 34.98555977500007],
            [-80.924208345999944, 34.985930554000049],
            [-80.924019600999941, 34.986303925000072],
            [-80.923838302999968, 34.98667978800006],
            [-80.923664500999962, 34.987058042000058],
            [-80.923498242999983, 34.987438585000064],
            [-80.923339572999964, 34.987821317000055],
            [-80.923188532999973, 34.988206134000052],
            [-80.922677764999946, 34.988991798000029],
            [-80.922489266999946, 34.989407205000077],
            [-80.92224534099995, 34.989955331000033],
            [-80.922080523999966, 34.990334450000034],
            [-80.921824636999986, 34.990942071000063],
            [-80.921520278999935, 34.991690110000036],
            [-80.921295864999934, 34.992260632000068],
            [-80.92115780599994, 34.992625372000077],
            [-80.920954248999976, 34.99316458900006],
            [-80.920739527999956, 34.993758283000034],
            [-80.920576807999964, 34.994223247000036],
            [-80.920346638999945, 34.994902737000075],
            [-80.920057512999961, 34.995801632000052],
            [-80.91983171399994, 34.996543493000047],
            [-80.919692063999946, 34.997016554000027],
            [-80.919586849999973, 34.997385188000067],
            [-80.919401289999939, 34.998069961000056],
            [-80.919222458999968, 34.99876254600008],
            [-80.919069228999945, 34.999387780000063],
            [-80.918903143999955, 35.000104423000039],
            [-80.918680105999954, 35.001126957000054],
            [-80.918566599999963, 35.001676992000057],
            [-80.918400151999947, 35.002523384000028],
            [-80.918262004999974, 35.003200975000027],
            [-80.918181446999938, 35.003600548000065],
            [-80.918018871999948, 35.004408010000077],
            [-80.91782152199994, 35.005361009000069],
            [-80.917613674999984, 35.006392078000033],
            [-80.917506508999963, 35.006909386000075],
            [-80.917402325999944, 35.007429349000063],
            [-80.917291449999937, 35.007966857000042],
            [-80.91715643799995, 35.008620551000035],
            [-80.91707650099994, 35.009016342000052],
            [-80.916974024999945, 35.009373958000026],
            [-80.916631535999954, 35.009879193000074],
            [-80.916301351999948, 35.010366306000037],
            [-80.915679956999952, 35.01131128600008],
            [-80.915376220999974, 35.011787130000073],
            [-80.914931374999981, 35.012506475000066],
            [-80.914665272999969, 35.012946294000074],
            [-80.91437024399994, 35.013447867000025],
            [-80.913952001999974, 35.014179003000038],
            [-80.913445820999982, 35.015099685000052],
            [-80.913159726999936, 35.015641499000026],
            [-80.912826719999941, 35.016293904000065],
            [-80.912545398999953, 35.01685924800006],
            [-80.912223005999977, 35.017527410000071],
            [-80.911839933999943, 35.018353149000063],
            [-80.911662249999949, 35.018749787000047],
            [-80.911302576999958, 35.019582771000046],
            [-80.91087952099997, 35.020611919000032],
            [-80.910544254999934, 35.021471443000053],
            [-80.910265826999989, 35.022220033000053],
            [-80.909990518999962, 35.023006420000058],
            [-80.909643181999968, 35.024053544000026],
            [-80.909347594999986, 35.025000937000073],
            [-80.909150936999936, 35.025681533000068],
            [-80.908950230999949, 35.026434433000077],
            [-80.908705877999978, 35.027408180000066],
            [-80.908540181999967, 35.028108616000054],
            [-80.90838410799995, 35.028808131000062],
            [-80.908268623999959, 35.02936690100006],
            [-80.908084154999983, 35.030345767000028],
            [-80.907956666999951, 35.031093742000053],
            [-80.907871850999982, 35.031631148000031],
            [-80.907737059999988, 35.03254706000007],
            [-80.907619015999956, 35.033404683000072],
            [-80.907521637999935, 35.034122045000061],
            [-80.907382233999954, 35.03519417800004],
            [-80.907332286999974, 35.035570793000034],
            [-80.90723337299994, 35.036323117000052],
            [-80.907147926999983, 35.03704216400007],
            [-80.907070558999976, 35.03759941800007],
            [-80.906978244999948, 35.038260705000027],
            [-80.90689297199998, 35.038832391000028],
            [-80.906827045999989, 35.039303867000058],
            [-80.906649930999947, 35.040666679000026],
            [-80.906522393999978, 35.041607608000049],
            [-80.906306088999941, 35.043217367000068],
            [-80.906232726999974, 35.043796593000025],
            [-80.906170094999936, 35.044231448000062],
            [-80.906036341999936, 35.045215645000042],
            [-80.905315738999946, 35.046149867000054],
            [-80.904856094999957, 35.046759683000062],
            [-80.903761956999972, 35.048246255000038],
            [-80.903378773999975, 35.048767303000034],
            [-80.901839088999964, 35.050835457000062],
            [-80.900325136999982, 35.052868923000062],
            [-80.899275694999972, 35.054255586000068],
            [-80.898905622999962, 35.054744562000053],
            [-80.898341092999942, 35.05549374900005],
            [-80.89762196099997, 35.056468944000073],
            [-80.897075757999971, 35.057211926000036],
            [-80.896739943999989, 35.057649878000063],
            [-80.896322322999936, 35.058199135000052],
            [-80.89578628299995, 35.058922191000079],
            [-80.895320846999937, 35.059524613000065],
            [-80.894644586999959, 35.06041739300008],
            [-80.894325354999978, 35.060846339000079],
            [-80.893298631999983, 35.062249447000056],
            [-80.892063503999964, 35.063857178000035],
            [-80.89100956599998, 35.065264009000032],
            [-80.889827471999979, 35.066872989000046],
            [-80.889222759999939, 35.06768407800007],
            [-80.888667040999962, 35.068439874000035],
            [-80.888111820999939, 35.069217045000073],
            [-80.887545087999968, 35.070034784000029],
            [-80.887194120999936, 35.070558629000061],
            [-80.886509817999979, 35.071614050000051],
            [-80.885998229999984, 35.072445479000066],
            [-80.885561008999957, 35.073198447000038],
            [-80.885288512999978, 35.07368187700007],
            [-80.885002258999975, 35.07419881800007],
            [-80.884646414999963, 35.074858445000075],
            [-80.884286517999954, 35.075564385000064],
            [-80.883789906999937, 35.076588646000062],
            [-80.883549495999944, 35.077103350000073],
            [-80.88336557599996, 35.077513586000066],
            [-80.883236991999979, 35.077805814000044],
            [-80.883093164999934, 35.078140251000036],
            [-80.882950585999936, 35.078477376000023],
            [-80.882763359999956, 35.078937490000044],
            [-80.882401422999976, 35.079861054000048],
            [-80.882147079999982, 35.080548775000068],
            [-80.881952405999982, 35.081110471000045],
            [-80.881661215999941, 35.081986879000056],
            [-80.881363309999983, 35.082939506000059],
            [-80.881180756999981, 35.083566273000031],
            [-80.88101484699996, 35.084178924000071],
            [-80.880791614999964, 35.085047862000067],
            [-80.880563668999969, 35.085982225000066],
            [-80.880396215999951, 35.086713638000049],
            [-80.880292201999964, 35.087212813000065],
            [-80.88012133899997, 35.088107042000047],
            [-80.880025655999987, 35.088654201000054],
            [-80.879830052999978, 35.089724897000053],
            [-80.879648040999939, 35.09081438000004],
            [-80.879072411999971, 35.091767796000056],
            [-80.878323677999958, 35.092861615000061],
            [-80.877877112999954, 35.09347032200003],
            [-80.877542571999982, 35.093926361000058],
            [-80.877010179999957, 35.094651947000045],
            [-80.87647553499994, 35.095366618000071],
            [-80.875836380999942, 35.096248479000053],
            [-80.875324872999954, 35.096997659000067],
            [-80.87460521099996, 35.098103391000052],
            [-80.874151732999962, 35.098832819000052],
            [-80.873840555999948, 35.099357123000061],
            [-80.873421839999935, 35.100067248000073],
            [-80.872845609999956, 35.101085028000057],
            [-80.872451524999974, 35.101808697000024],
            [-80.872255428999949, 35.102166360000069],
            [-80.872054504999937, 35.102535463000038],
            [-80.87184879199998, 35.102911520000077],
            [-80.871507225999949, 35.10355136700008],
            [-80.871242093999967, 35.103970600000025],
            [-80.870878865999941, 35.104510657000048],
            [-80.870147876999965, 35.105636335000042],
            [-80.869676538999954, 35.106319244000076],
            [-80.869417772999952, 35.106686525000043],
            [-80.868860377999965, 35.107490502000076],
            [-80.868396659999974, 35.108179133000078],
            [-80.868170006999947, 35.108514887000069],
            [-80.86720584599999, 35.109478153000055],
            [-80.865950716999976, 35.110640373000024],
            [-80.865090129999942, 35.111441494000076],
            [-80.864321056999984, 35.112148381000054],
            [-80.863919733999978, 35.112522718000037],
            [-80.863432147999958, 35.112984095000058],
            [-80.863132878999977, 35.113262832000032],
            [-80.862679327999956, 35.113672060000056],
            [-80.862156148999986, 35.114149518000033],
            [-80.861481322999964, 35.11477996800005],
            [-80.861073215999966, 35.115151788000048],
            [-80.860631806999947, 35.115552340000079],
            [-80.860086739999986, 35.116055842000037],
            [-80.859461710999938, 35.116643174000046],
            [-80.858941303999984, 35.117143273000067],
            [-80.858489296999949, 35.117553673000032],
            [-80.857990787999938, 35.118014154000036],
            [-80.857121456999948, 35.118832242000053],
            [-80.85648333499995, 35.119436567000037],
            [-80.855975635999982, 35.119916302000036],
            [-80.855037548999974, 35.120800744000064],
            [-80.854389836999985, 35.121451800000045],
            [-80.853554033999956, 35.122322573000076],
            [-80.852921905999949, 35.12300547500007],
            [-80.852617270999986, 35.123342895000064],
            [-80.851988848999952, 35.124057958000037],
            [-80.851303211999948, 35.124865740000075],
            [-80.851003184999968, 35.125229268000055],
            [-80.850547748999986, 35.125794337000059],
            [-80.850128583999947, 35.126330100000075],
            [-80.849607749999961, 35.127015785000026],
            [-80.849351215999945, 35.127360600000031],
            [-80.849123003999978, 35.127674811000077],
            [-80.848771485999976, 35.128167182000027],
            [-80.848519440999951, 35.128530542000078],
            [-80.848132174999989, 35.129103344000043],
            [-80.847792830999936, 35.129619889000026],
            [-80.84727892799998, 35.130428707000078],
            [-80.846946558999946, 35.130972395000072],
            [-80.846624060999943, 35.13152040500006],
            [-80.846215540999935, 35.132235173000026],
            [-80.845734902999936, 35.133109018000027],
            [-80.845447724999985, 35.133653308000078],
            [-80.845202602999962, 35.134137113000065],
            [-80.844855597999981, 35.134843077000028],
            [-80.844323758999963, 35.135979399000064],
            [-80.84409371199996, 35.136501890000034],
            [-80.843784526999968, 35.137239557000044],
            [-80.843495503999975, 35.137959223000053],
            [-80.843207043999939, 35.138710150000065],
            [-80.842963357999963, 35.139385825000033],
            [-80.842708597999945, 35.140136635000033],
            [-80.842552190999982, 35.140617801000076],
            [-80.842416535999973, 35.141030985000043],
            [-80.842286920999982, 35.141385124000067],
            [-80.842131219999942, 35.141814478000072],
            [-80.841946284999949, 35.142335819000039],
            [-80.841672675999973, 35.143077125000048],
            [-80.841525004999937, 35.143488953000031],
            [-80.841238100999988, 35.144308735000038],
            [-80.841081948999943, 35.144767146000049],
            [-80.840858266999987, 35.145432956000036],
            [-80.840596888999983, 35.146168388000035],
            [-80.840241463999973, 35.147189336000054],
            [-80.840007068999967, 35.147852913000065],
            [-80.839708500999961, 35.148738939000054],
            [-80.83947995699998, 35.149401675000036],
            [-80.839313893999986, 35.149864879000063],
            [-80.839117293999948, 35.150425860000041],
            [-80.838858157999937, 35.151181382000061],
            [-80.838620795999987, 35.151908215000049],
            [-80.838316655999961, 35.152897470000028],
            [-80.837594828999954, 35.155406477000042],
            [-80.837405955999941, 35.156035017000079],
            [-80.837201289999939, 35.156706177000046],
            [-80.83704559499995, 35.157247344000041],
            [-80.836578021999969, 35.15885193400004],
            [-80.836452512999983, 35.159295696000072],
            [-80.836197843999969, 35.160223590000044],
            [-80.83585956099995, 35.161382644000071],
            [-80.835520364999979, 35.162560279000047],
            [-80.835281168999984, 35.163385590000075],
            [-80.835056527999939, 35.164161573000058],
            [-80.834884909999971, 35.164750360000028],
            [-80.834681478999983, 35.165462309000077],
            [-80.834501580999984, 35.166119923000053],
            [-80.834323476999941, 35.166810618000056],
            [-80.834213357999943, 35.167263196000079],
            [-80.834088262999956, 35.167804229000069],
            [-80.834016735999967, 35.168126491000066],
            [-80.833931121999967, 35.168534854000029],
            [-80.833821139999941, 35.169087855000043],
            [-80.833733522999978, 35.169557728000029],
            [-80.833704354999952, 35.170517227000062],
            [-80.833633364999969, 35.170772549000048],
            [-80.833569762999957, 35.171029181000051],
            [-80.833513584999935, 35.171286979000058],
            [-80.833464861999971, 35.171545797000078],
            [-80.833423622999987, 35.171805491000043],
            [-80.833389888999989, 35.172065914000029],
            [-80.83336368099998, 35.172326921000035],
            [-80.83331941299997, 35.172752870000068],
            [-80.833253204999949, 35.173303646000079],
            [-80.833202451999966, 35.17372262300006],
            [-80.833157171999972, 35.174193476000028],
            [-80.83313018399997, 35.174747847000049],
            [-80.83310832799998, 35.175511072000063],
            [-80.833118960999968, 35.176275377000024],
            [-80.83314844299997, 35.17650709600008],
            [-80.833170540999959, 35.176739369000074],
            [-80.833185237999942, 35.176972037000041],
            [-80.833192524999959, 35.17720494100007],
            [-80.833192396999948, 35.177437921000035],
            [-80.833184852999977, 35.17767081900007],
            [-80.833169898999984, 35.17790347600004],
            [-80.833147544999974, 35.178135733000033],
            [-80.83319206699997, 35.178570594000064],
            [-80.833258314999966, 35.179380627000057],
            [-80.833155301999966, 35.179998229000034],
            [-80.833194814999956, 35.180524324000032],
            [-80.83323163099999, 35.180949551000026],
            [-80.833281672999988, 35.181465026000069],
            [-80.833287621999943, 35.181884800000034],
            [-80.832805174999976, 35.182891206000079],
            [-80.832342421999954, 35.183478341000068],
            [-80.831826847999935, 35.184136760000058],
            [-80.831029503999957, 35.185206246000064],
            [-80.830556162999983, 35.185867335000069],
            [-80.830202306999979, 35.186382437000077],
            [-80.829871969999942, 35.186873920000039],
            [-80.829443608999952, 35.187526638000065],
            [-80.828940855999974, 35.188317780000034],
            [-80.82864228699998, 35.188804283000024],
            [-80.828389376999951, 35.18923056400007],
            [-80.828056792999973, 35.189804000000038],
            [-80.82759055799994, 35.190631575000054],
            [-80.827309475999982, 35.191147839000052],
            [-80.826981990999968, 35.191758352000079],
            [-80.826641093999967, 35.192401687000029],
            [-80.826345365999941, 35.19296212900008],
            [-80.826060705999964, 35.193488509000076],
            [-80.82579852799995, 35.193976431000067],
            [-80.825365857999941, 35.194798185000025],
            [-80.825042986999961, 35.195407714000055],
            [-80.824581942999941, 35.196263411000075],
            [-80.824045208999962, 35.19670942700003],
            [-80.823706111999968, 35.196996564000074],
            [-80.823151049999979, 35.197480247000044],
            [-80.822311002999982, 35.198237973000062],
            [-80.821865917999958, 35.198645704000057],
            [-80.821388849999948, 35.199094152000043],
            [-80.820932429999971, 35.199535528000069],
            [-80.820361808999962, 35.200110003000077],
            [-80.819527156999982, 35.200981322000075],
            [-80.819061115999943, 35.201475043000073],
            [-80.818700554999964, 35.201866684000038],
            [-80.818076877999943, 35.20257312800004],
            [-80.81720545099995, 35.203603017000034],
            [-80.816600104999964, 35.20435090400008],
            [-80.816158607999967, 35.204923131000044],
            [-80.815769426999964, 35.205440008000039],
            [-80.815303467999968, 35.206075198000065],
            [-80.814911471999949, 35.206623662000027],
            [-80.814530167999976, 35.207174468000062],
            [-80.814072931999988, 35.207857036000064],
            [-80.81372485299994, 35.208392355000058],
            [-80.813440562999972, 35.208841145000065],
            [-80.813260419999949, 35.209131257000024],
            [-80.812646064999967, 35.210089664000066],
            [-80.812057911999943, 35.21054149400004],
            [-80.811261785999989, 35.211140009000076],
            [-80.810665920999952, 35.211601873000063],
            [-80.80987866199996, 35.212236545000053],
            [-80.809448801999963, 35.212594324000065],
            [-80.808865983999965, 35.213090512000065],
            [-80.80845560399996, 35.213447573000053],
            [-80.807897434999973, 35.21395153800006],
            [-80.806887126999982, 35.214892295000027],
            [-80.806499197999983, 35.215261780000048],
            [-80.806096044999947, 35.215664261000029],
            [-80.805514035999977, 35.216259686000058],
            [-80.805098889999954, 35.216695288000039],
            [-80.804583873999945, 35.217252071000075],
            [-80.803990364999947, 35.217922608000038],
            [-80.803176852999968, 35.218879493000031],
            [-80.802695282999935, 35.219465491000051],
            [-80.802353367999956, 35.219900109000037],
            [-80.801886062999984, 35.220509314000026],
            [-80.801539644999934, 35.220973251000032],
            [-80.801017412999954, 35.221696964000046],
            [-80.800711219999982, 35.222137949000057],
            [-80.800331189999952, 35.222700821000046],
            [-80.800022583999976, 35.22316931000006],
            [-80.799740575999976, 35.223606897000025],
            [-80.799407081999959, 35.22413668400003],
            [-80.798911094999937, 35.224967449000076],
            [-80.798268146999987, 35.226104938000049],
            [-80.797899067999936, 35.226788415000044],
            [-80.797731995999982, 35.227113757000041],
            [-80.797506470999963, 35.227567011000076],
            [-80.797160468999948, 35.22828479900005],
            [-80.796872900999972, 35.22890629300008],
            [-80.796629591999988, 35.229456175000053],
            [-80.796339415999967, 35.230142411000031],
            [-80.796133162999979, 35.230654250000043],
            [-80.795860262999952, 35.231360038000048],
            [-80.795680660999949, 35.231843353000045],
            [-80.795433435999939, 35.232558575000041],
            [-80.795060759999956, 35.233708444000058],
            [-80.794744243999958, 35.234797019000041],
            [-80.794479784999965, 35.235822734000067],
            [-80.794286309999961, 35.236613159000058],
            [-80.79394195499998, 35.237455407000027],
            [-80.793630388999986, 35.238153935000071],
            [-80.793367135999972, 35.238730561000068],
            [-80.793105602999958, 35.239315391000048],
            [-80.792892141999971, 35.23978687500005],
            [-80.792714846999957, 35.24017525000005],
            [-80.792519895999988, 35.240611749000038],
            [-80.792245756999989, 35.241215938000039],
            [-80.791807272999961, 35.242146578000074],
            [-80.791631973999984, 35.242529268000055],
            [-80.791176568999958, 35.243555516000072],
            [-80.790983714999982, 35.244005407000031],
            [-80.790532484999972, 35.245103762000042],
            [-80.79025526099997, 35.245816811000054],
            [-80.790057451999985, 35.246346456000026],
            [-80.789920045999963, 35.246727669000052],
            [-80.789798856999937, 35.247075799000072],
            [-80.789560446999985, 35.247787434000031],
            [-80.789417654999966, 35.248235603000069],
            [-80.789250971999934, 35.248781233000045],
            [-80.789119623999966, 35.249230121000039],
            [-80.788991768999949, 35.249691631000076],
            [-80.788827728999934, 35.250311999000076],
            [-80.788705010999934, 35.250802078000049],
            [-80.78844799999996, 35.251930815000037],
            [-80.788316348999956, 35.252576943000065],
            [-80.788198096999963, 35.253207520000046],
            [-80.788113329999987, 35.253694242000051],
            [-80.787977524999974, 35.254565435000075],
            [-80.787906833999955, 35.255078182000034],
            [-80.787827153999956, 35.255719514000077],
            [-80.787782229999948, 35.256115685000054],
            [-80.787745474999952, 35.256485546000079],
            [-80.787692800999935, 35.257075067000073],
            [-80.787663137999971, 35.257449456000074],
            [-80.787637165999968, 35.257847470000058],
            [-80.787602135999975, 35.258490490000042],
            [-80.787584773999981, 35.258887562000041],
            [-80.787573377999934, 35.259275940000066],
            [-80.787561581999967, 35.259901220000074],
            [-80.787558306999983, 35.260292999000058],
            [-80.78755887799997, 35.260601263000069],
            [-80.787566459999937, 35.261230086000069],
            [-80.787583300999984, 35.26180363800006],
            [-80.787619957999937, 35.262620067000057],
            [-80.787694141999964, 35.263640867000049],
            [-80.787763846999951, 35.264439321000054],
            [-80.787813185999937, 35.264925492000032],
            [-80.787905871999953, 35.265696511000044],
            [-80.788040322999962, 35.26660283700005],
            [-80.788256779999983, 35.267844055000069],
            [-80.788443442999949, 35.268773190000047],
            [-80.78860191299998, 35.269475477000071],
            [-80.788717825999981, 35.269958540000061],
            [-80.788879743999985, 35.270598203000077],
            [-80.789017211999976, 35.271112856000059],
            [-80.789171450999959, 35.271657502000039],
            [-80.789368631999935, 35.272314281000035],
            [-80.789532179999981, 35.272828656000058],
            [-80.789763834999974, 35.273523667000063],
            [-80.789929093999945, 35.27399957800003],
            [-80.79006702099997, 35.274378794000029],
            [-80.79034355999994, 35.275109852000071],
            [-80.790551821999941, 35.275632881000035],
            [-80.790840366999987, 35.276328486000068],
            [-80.791063272999963, 35.276845871000035],
            [-80.791311939999957, 35.277398952000055],
            [-80.791655565999974, 35.278132209000034],
            [-80.791909942999951, 35.278653317000078],
            [-80.792033320999963, 35.279258063000043],
            [-80.792113601999972, 35.279811000000052],
            [-80.792189061999977, 35.280282168000042],
            [-80.79230292799997, 35.280940886000053],
            [-80.792391969999983, 35.28141989900007],
            [-80.792520459999935, 35.282057016000067],
            [-80.792642658999966, 35.282616492000045],
            [-80.792743204999965, 35.283049257000073],
            [-80.792916938999952, 35.28380134400004],
            [-80.793025236999938, 35.284429332000059],
            [-80.793222195999988, 35.285469014000057],
            [-80.793366237999976, 35.286133103000054],
            [-80.793593400999953, 35.287085009000066],
            [-80.79374788399997, 35.287688107000065],
            [-80.79390701899996, 35.288264777000052],
            [-80.794059903999937, 35.288789156000064],
            [-80.794278262999967, 35.289501850000079],
            [-80.79441100799994, 35.289918020000073],
            [-80.794552137999972, 35.290339931000062],
            [-80.794752291999941, 35.290934840000034],
            [-80.794874030999949, 35.29128251800006],
            [-80.795004868999968, 35.291644053000027],
            [-80.795236012999965, 35.29226000500006],
            [-80.795397922999939, 35.292674293000061],
            [-80.79561807999994, 35.293220164000047],
            [-80.795777816999987, 35.293604797000057],
            [-80.796002531999989, 35.294126028000051],
            [-80.79633245499997, 35.29485744200008],
            [-80.796614706999947, 35.295469081000078],
            [-80.796516477999944, 35.296512628000073],
            [-80.796370433999982, 35.297149674000025],
            [-80.796204300999989, 35.297885093000048],
            [-80.796040663999975, 35.298555094000051],
            [-80.795927426999981, 35.299036362000038],
            [-80.795801641999958, 35.299594839000065],
            [-80.795715279999968, 35.299998455000036],
            [-80.795588914999939, 35.300629411000045],
            [-80.795487647999948, 35.301184897000041],
            [-80.795403750999981, 35.301658083000063],
            [-80.795313388999944, 35.30210988400006],
            [-80.795154393999951, 35.302944645000025],
            [-80.79500447099997, 35.303801070000077],
            [-80.794872407999947, 35.304626595000059],
            [-80.794795952999948, 35.305119385000069],
            [-80.794700638999984, 35.30579491900005],
            [-80.794632706999948, 35.306281823000063],
            [-80.794542941999964, 35.306995331000053],
            [-80.794468502999962, 35.307669010000041],
            [-80.794582840999965, 35.308568019000063],
            [-80.794405179999956, 35.309173689000033],
            [-80.794376703999944, 35.309481731000062],
            [-80.79435633199995, 35.309790204000024],
            [-80.79434407499997, 35.310098966000055],
            [-80.794339936999961, 35.310407872000042],
            [-80.79434392099995, 35.310716780000064],
            [-80.794308090999948, 35.311164155000029],
            [-80.794227038999963, 35.312282251000056],
            [-80.794184691999988, 35.313199144000066],
            [-80.79419590599997, 35.313752187000034],
            [-80.794226661999971, 35.31420487400004],
            [-80.794278493999968, 35.31491569800005],
            [-80.794336605999945, 35.315823886000032],
            [-80.794349954999973, 35.316727921000052],
            [-80.794402816999934, 35.317161808000037],
            [-80.794312325999954, 35.318137570000033],
            [-80.794352390999961, 35.318918778000068],
            [-80.794397390999961, 35.31961850700003],
            [-80.79443882999999, 35.320341357000075],
            [-80.794458630999941, 35.320664419000025],
            [-80.794478983999966, 35.321059655000056],
            [-80.794501985999943, 35.321455031000028],
            [-80.794532815999958, 35.321936268000059],
            [-80.794563214999982, 35.32258054700003],
            [-80.794628571999965, 35.323583410000026],
            [-80.794665949999967, 35.324281307000035],
            [-80.794692411999961, 35.324750923000067],
            [-80.794717107999986, 35.325124902000027],
            [-80.794747625999946, 35.325607756000068],
            [-80.794784745999948, 35.326320431000056],
            [-80.794820609999988, 35.326922153000055],
            [-80.794858750999936, 35.327638901000057],
            [-80.794896920999975, 35.32821188500003],
            [-80.794929826999976, 35.328756296000051],
            [-80.794964793999952, 35.329394562000061],
            [-80.794988822999983, 35.329793712000026],
            [-80.79501156699996, 35.330187982000041],
            [-80.795042747999958, 35.330672143000072],
            [-80.795063001999949, 35.331025465000039],
            [-80.795089318999942, 35.331493667000075],
            [-80.795116979999989, 35.332132710000053],
            [-80.795132277999983, 35.332446727000047],
            [-80.795159651999938, 35.333003443000052],
            [-80.795205195999984, 35.333775761000027],
            [-80.795246426999938, 35.334491286000059],
            [-80.795275851999975, 35.335076723000043],
            [-80.795327589999943, 35.336012857000071],
            [-80.795363693999946, 35.336582484000076],
            [-80.795405161999952, 35.33719411900006],
            [-80.795446690999938, 35.33799613900004],
            [-80.795461940999985, 35.338436286000046],
            [-80.79518130799994, 35.33913629400007],
            [-80.793643352999936, 35.342500414000028],
            [-80.79344206199994, 35.342955505000077],
            [-80.793209483999988, 35.343500849000066],
            [-80.792776468999989, 35.344546276000074],
            [-80.79234644099995, 35.345591979000062],
            [-80.791977650999968, 35.346534450000036],
            [-80.791783059999943, 35.347056866000059],
            [-80.791496926999969, 35.347856363000062],
            [-80.791306434999967, 35.348416604000079],
            [-80.790943909999953, 35.34954069500003],
            [-80.790766057999974, 35.350124755000024],
            [-80.790598281999962, 35.35071008400007],
            [-80.79043917599995, 35.351302101000044],
            [-80.790141534999975, 35.35248802600006],
            [-80.789977543999953, 35.353201639000076],
            [-80.789792638999984, 35.354087012000036],
            [-80.789696465999953, 35.354583221000041],
            [-80.789518126999951, 35.355577345000029],
            [-80.78943596299996, 35.356075260000068],
            [-80.789281388999939, 35.357102389000033],
            [-80.789224395999952, 35.357507320000025],
            [-80.789145204999954, 35.358124188000033],
            [-80.78907954899995, 35.35868406000003],
            [-80.78902093499994, 35.359200084000065],
            [-80.788937418999978, 35.360035977000052],
            [-80.788871790999963, 35.360809538000069],
            [-80.78882858999998, 35.361434233000068],
            [-80.788790602999939, 35.362155898000026],
            [-80.788774049999972, 35.362554316000057],
            [-80.788761802999943, 35.362962676000052],
            [-80.788747488999945, 35.363694124000062],
            [-80.788745351999978, 35.364062665000063],
            [-80.788750015999938, 35.364608788000055],
            [-80.788756072999945, 35.364944614000024],
            [-80.788775722999958, 35.365730403000043],
            [-80.788819276999959, 35.366750979000074],
            [-80.788914996999949, 35.367961131000072],
            [-80.789020172999983, 35.368905128000051],
            [-80.78915260499997, 35.370047018000037],
            [-80.789303348999965, 35.371117000000027],
            [-80.789536904999977, 35.37245800200003],
            [-80.789921815999946, 35.374427426000068],
            [-80.790060498999935, 35.375069040000028],
            [-80.790303748999975, 35.376093810000043],
            [-80.790613426999982, 35.377295090000075],
            [-80.79075161999998, 35.377806108000073],
            [-80.790992494999955, 35.378618754000058],
            [-80.791382164999959, 35.379831212000056],
            [-80.79176294399997, 35.380903384000078],
            [-80.792126301999986, 35.381836474000067],
            [-80.792355113999974, 35.38239830200007],
            [-80.792761931999962, 35.38336457500003],
            [-80.792974826999966, 35.383858940000039],
            [-80.793207995999978, 35.384383669000044],
            [-80.793563730999949, 35.385156901000073],
            [-80.793895770999939, 35.385853668000038],
            [-80.794185504999973, 35.386444538000035],
            [-80.794442757999946, 35.386952984000061],
            [-80.794712981999965, 35.38747255100003],
            [-80.794938041999956, 35.387892648000047],
            [-80.795256978999987, 35.388471930000037],
            [-80.795520219999958, 35.388946392000037],
            [-80.795813430999942, 35.389458926000032],
            [-80.796083407999959, 35.389920280000069],
            [-80.796311191999962, 35.39030587700006],
            [-80.796511253999938, 35.390644563000023],
            [-80.796578257999954, 35.391027339000061],
            [-80.796640482999976, 35.391443605000063],
            [-80.796710941999947, 35.39188848200007],
            [-80.796824870999956, 35.392559272000028],
            [-80.796927690999951, 35.39315390400003],
            [-80.79702317999994, 35.39367474900007],
            [-80.797169783999948, 35.394418785000028],
            [-80.797325442999977, 35.395112513000072],
            [-80.797614525999961, 35.396288936000076],
            [-80.797796342999959, 35.396972898000058],
            [-80.798008468999967, 35.397745899000029],
            [-80.798222299999964, 35.398478197000031],
            [-80.79852163299995, 35.39943293500005],
            [-80.798677905999966, 35.399908910000079],
            [-80.798947173999977, 35.400692706000029],
            [-80.799110760999952, 35.401155475000053],
            [-80.799302388999934, 35.401676847000033],
            [-80.79962528599998, 35.40252124400007],
            [-80.80007496099995, 35.403631226000073],
            [-80.800339691999966, 35.404248020000068],
            [-80.800713271999939, 35.405064762000052],
            [-80.801408274999972, 35.406523843000059],
            [-80.801780694999934, 35.407275907000042],
            [-80.802165263999939, 35.408010259000037],
            [-80.802641395999956, 35.408875218000048],
            [-80.802885222999976, 35.409305566000057],
            [-80.803330581999944, 35.410069042000032],
            [-80.80362930199999, 35.410569783000028],
            [-80.804061857999955, 35.41127171800008],
            [-80.804302616999962, 35.412290022000036],
            [-80.804786106999984, 35.415226039000061],
            [-80.805075614999964, 35.416842742000028],
            [-80.805165254999963, 35.417324334000057],
            [-80.80528604899996, 35.417993250000052],
            [-80.805369155999983, 35.418425108000065],
            [-80.805464461999975, 35.418951158000027],
            [-80.80556594799998, 35.419487068000024],
            [-80.805732417999934, 35.420307921000074],
            [-80.805876148999971, 35.420960754000077],
            [-80.805985183999951, 35.421439618000079],
            [-80.806099188999951, 35.42191064900004],
            [-80.80633987899995, 35.422850536000055],
            [-80.806538049999972, 35.423566489000052],
            [-80.806657104999942, 35.423973224000065],
            [-80.806851860999984, 35.424626117000059],
            [-80.807038725999973, 35.425222747000078],
            [-80.807246612999961, 35.42586130400008],
            [-80.807478189999983, 35.426547002000063],
            [-80.807736593999948, 35.427288752000038],
            [-80.807923984999945, 35.427806650000036],
            [-80.808180812999979, 35.428498138000066],
            [-80.808405709999988, 35.429080111000076],
            [-80.808568399999956, 35.429488058000061],
            [-80.808789815999944, 35.430031488000054],
            [-80.809072785999945, 35.430706438000072],
            [-80.809281347999956, 35.431184936000079],
            [-80.809503323999934, 35.43168614800004],
            [-80.809744415999944, 35.432218959000068],
            [-80.809909138999956, 35.432575664000069],
            [-80.810287198999958, 35.433380123000063],
            [-80.810496074999946, 35.433811797000033],
            [-80.810743086999935, 35.434309864000056],
            [-80.811051956999961, 35.434920868000063],
            [-80.811432638999975, 35.435652813000047],
            [-80.811792643999979, 35.436333206000029],
            [-80.812059188999967, 35.436832882000033],
            [-80.812342875999946, 35.437367154000071],
            [-80.812608824999984, 35.43786532300004],
            [-80.812941639999963, 35.438482749000059],
            [-80.813248809999948, 35.439053381000065],
            [-80.813466451999943, 35.439458191000028],
            [-80.814121222999972, 35.440662532000033],
            [-80.815364912999939, 35.442790591000062],
            [-80.815305165999973, 35.443332117000068],
            [-80.815307896999968, 35.44378516900008],
            [-80.815273911999952, 35.444461794000063],
            [-80.815287275999935, 35.445396599000048],
            [-80.815348222999944, 35.446126444000072],
            [-80.81541858099996, 35.446947113000078],
            [-80.815458025999988, 35.447673816000076],
            [-80.815590032999978, 35.448560775000033],
            [-80.815669501999935, 35.449282493000055],
            [-80.815614890999939, 35.449916633000043],
            [-80.815697887999988, 35.450595544000066],
            [-80.815745113999981, 35.45094874800003],
            [-80.815830093999978, 35.451537997000059],
            [-80.815982490999943, 35.452491959000042],
            [-80.816189159999965, 35.453683316000024],
            [-80.816369331999965, 35.45462550600007],
            [-80.816476242999954, 35.455142207000051],
            [-80.816606362999948, 35.455712394000045],
            [-80.816893753999977, 35.456884613000057],
            [-80.817047320999961, 35.457469185000036],
            [-80.817251501999976, 35.458195759000034],
            [-80.817512201999989, 35.459091116000025],
            [-80.817780314999936, 35.459966962000067],
            [-80.818072322999967, 35.460863393000068],
            [-80.818312276999961, 35.461561330000052],
            [-80.81855154699997, 35.462222305000068],
            [-80.818869439999958, 35.463051199000063],
            [-80.819067725999957, 35.463551052000071],
            [-80.819255168999973, 35.464020961000074],
            [-80.819562811999958, 35.464764657000046],
            [-80.819723106999959, 35.46514077300003],
            [-80.819885829999976, 35.465511507000031],
            [-80.820073483999977, 35.46594268900003],
            [-80.820286075999945, 35.466415509000058],
            [-80.820763714999941, 35.467434419000028],
            [-80.821256999999946, 35.468438025000069],
            [-80.821509849999984, 35.468948699000066],
            [-80.821829210999965, 35.469625694000058],
            [-80.821832877999952, 35.470055691000027],
            [-80.821787018999942, 35.470863454000039],
            [-80.821721886999967, 35.472100562000037],
            [-80.821698871999956, 35.472637644000031],
            [-80.821680069999957, 35.473197592000076],
            [-80.82166592599998, 35.473568234000027],
            [-80.821648241999981, 35.474195862000045],
            [-80.821636989999945, 35.474542310000061],
            [-80.821626619999961, 35.474940999000069],
            [-80.821614427999975, 35.475331554000036],
            [-80.821597555999972, 35.476151161000075],
            [-80.821591324999986, 35.477084421000029],
            [-80.821362486999988, 35.477695415000028],
            [-80.821099084999958, 35.478198306000024],
            [-80.820851516999937, 35.478684603000033],
            [-80.820461368999986, 35.479469047000066],
            [-80.820193555999936, 35.480012649000059],
            [-80.819765275999941, 35.480875836000052],
            [-80.819468926999946, 35.481475673000034],
            [-80.819203904999938, 35.481997031000049],
            [-80.818799984999941, 35.482804201000079],
            [-80.818546164999987, 35.483316586000058],
            [-80.818162398999959, 35.484084268000061],
            [-80.81790416299998, 35.484590284000035],
            [-80.817651611999963, 35.485096110000029],
            [-80.81746074199998, 35.485489390000055],
            [-80.817041929999959, 35.486368241000037],
            [-80.816759629999979, 35.486991991000025],
            [-80.816381515999979, 35.487885578000032],
            [-80.81619937399995, 35.488334062000035],
            [-80.816071416999989, 35.488655128000062],
            [-80.815867775999948, 35.489177432000076],
            [-80.815704729999936, 35.489609162000079],
            [-80.815528684999947, 35.490094809000027],
            [-80.815347217999943, 35.490615723000076],
            [-80.815222404999986, 35.490985498000043],
            [-80.815107874999967, 35.491335933000073],
            [-80.815011668999944, 35.491635919000032],
            [-80.814805507999949, 35.492299832000072],
            [-80.814683226999989, 35.492709650000052],
            [-80.814530679999962, 35.49325880300006],
            [-80.814286330999948, 35.494193149000068],
            [-80.814135484999952, 35.49481197800003],
            [-80.813980938999975, 35.495504357000073],
            [-80.813834801999974, 35.496210317000077],
            [-80.813700911999945, 35.496900250000067],
            [-80.813629528999968, 35.497295981000036],
            [-80.813546648999989, 35.49778394100008],
            [-80.813489138999955, 35.498142785000027],
            [-80.813441293999972, 35.498459205000074],
            [-80.813369828999953, 35.49899023200004],
            [-80.813267677999988, 35.49984575700006],
            [-80.813192525999966, 35.500582940000072],
            [-80.81310168899995, 35.501655482000047],
            [-80.813041363999957, 35.502434665000067],
            [-80.812965462999955, 35.503242039000042],
            [-80.812928054999986, 35.503732631000048],
            [-80.812860669999964, 35.504748708000079],
            [-80.812824541999987, 35.505296850000036],
            [-80.812721008999972, 35.50666196700007],
            [-80.812624824999943, 35.507939689000068],
            [-80.812522653999963, 35.509334458000069],
            [-80.812488160999976, 35.509914995000031],
            [-80.812453657999981, 35.510464636000052],
            [-80.812401797999939, 35.510951127000055],
            [-80.812373449999939, 35.511341601000026],
            [-80.812229207999962, 35.512091023000039],
            [-80.812086495999949, 35.512750415000028],
            [-80.811959062999961, 35.513321636000057],
            [-80.811855912999988, 35.51379002200008],
            [-80.811757499999942, 35.514232230000061],
            [-80.81164810599995, 35.514717828000073],
            [-80.811522734999983, 35.515290096000058],
            [-80.811439804999964, 35.515660675000049],
            [-80.811323093999988, 35.516191316000061],
            [-80.81120604299997, 35.51671943000008],
            [-80.811101286999985, 35.517193410000061],
            [-80.811002945999974, 35.517632565000042],
            [-80.810894664999978, 35.518131906000065],
            [-80.810807900999976, 35.518521987000042],
            [-80.810694976999969, 35.519023316000073],
            [-80.810507281999946, 35.519870516000026],
            [-80.810414923999986, 35.520285125000044],
            [-80.810256724999988, 35.520998501000065],
            [-80.810129243999938, 35.521577416000071],
            [-80.810051252999983, 35.521937682000043],
            [-80.809960825999951, 35.522347555000067],
            [-80.809855927999934, 35.522819279000032],
            [-80.809754182999939, 35.52328803100005],
            [-80.809672780999961, 35.52365295900006],
            [-80.80958037399995, 35.524073168000029],
            [-80.80937374399997, 35.524961137000048],
            [-80.809172782999951, 35.525797524000041],
            [-80.809050404999937, 35.526351951000038],
            [-80.808758500999943, 35.527690179000047],
            [-80.808674716999974, 35.528023129000076],
            [-80.80854487299996, 35.528554548000045],
            [-80.808436205999953, 35.529021597000053],
            [-80.808310089999964, 35.529589257000055],
            [-80.808212346999937, 35.530044063000048],
            [-80.808129573999963, 35.530433724000034],
            [-80.807945858999972, 35.53129892700008],
            [-80.807769991999976, 35.53215696400008],
            [-80.807684327999937, 35.532569095000042],
            [-80.807576876999974, 35.533045387000072],
            [-80.807455807999986, 35.533588411000039],
            [-80.807311377999952, 35.534233091000033],
            [-80.807196053999974, 35.534765976000074],
            [-80.807008438999958, 35.53560212900004],
            [-80.806834901999935, 35.536367655000049],
            [-80.806703791999951, 35.536970500000052],
            [-80.806597039999986, 35.537440343000071],
            [-80.806488244999969, 35.537928157000067],
            [-80.806366665999974, 35.538491990000068],
            [-80.806150656999989, 35.539458471000046],
            [-80.806042738999963, 35.539967599000079],
            [-80.805915719999973, 35.540543832000026],
            [-80.805782760999989, 35.541127625000058],
            [-80.805638316999989, 35.541774401000055],
            [-80.805520004999948, 35.542337682000039],
            [-80.805414515999985, 35.542868657000042],
            [-80.805325022999966, 35.543343619000041],
            [-80.805243476999976, 35.543808092000063],
            [-80.805135783999958, 35.544468528000039],
            [-80.80504555999994, 35.545087851000062],
            [-80.804981003999956, 35.545524382000053],
            [-80.804914015999941, 35.546005862000072],
            [-80.804844100999958, 35.54654793900005],
            [-80.804771276999986, 35.547157236000032],
            [-80.80471584199995, 35.547668334000036],
            [-80.804664241999944, 35.548194609000063],
            [-80.804602151999973, 35.548928053000054],
            [-80.804557554999974, 35.549566340000069],
            [-80.804518787999939, 35.550193310000054],
            [-80.80449845399994, 35.550569447000044],
            [-80.804476893999947, 35.551044310000066],
            [-80.804459752999946, 35.551527010000029],
            [-80.804444399999966, 35.552112932000057],
            [-80.804433385999971, 35.552930162000052],
            [-80.804439848999948, 35.553809612000066],
            [-80.804475784999966, 35.554950616000042],
            [-80.804506767999953, 35.55569637900004],
            [-80.804536553999981, 35.55624651200003],
            [-80.804563523999946, 35.556676393000032],
            [-80.80459012099999, 35.55705159300004],
            [-80.804624753999974, 35.557491729000049],
            [-80.804676275999952, 35.55806861700006],
            [-80.80473232199995, 35.558633444000066],
            [-80.804774264999935, 35.559017955000058],
            [-80.805036732999952, 35.559311949000062],
            [-80.804946453999946, 35.559895152000024],
            [-80.804915498999947, 35.56041966600003],
            [-80.804845342999954, 35.560954181000056],
            [-80.804587967999964, 35.561495378000075],
            [-80.804430770999943, 35.562056073000065],
            [-80.804241024999953, 35.562753572000076],
            [-80.804108275999965, 35.563253133000046],
            [-80.803961197999968, 35.563808771000026],
            [-80.80378765599994, 35.564497108000069],
            [-80.803604255999971, 35.56527300700003],
            [-80.803473810999947, 35.565861003000066],
            [-80.803326632999983, 35.566574517000049],
            [-80.803234701999941, 35.567049578000024],
            [-80.80313134499994, 35.567619183000033],
            [-80.803000736999934, 35.568396678000056],
            [-80.802881934999959, 35.569178431000068],
            [-80.802796412999953, 35.569799365000051],
            [-80.80273355199995, 35.570302759000072],
            [-80.802666866999971, 35.57088893100007],
            [-80.802606111999978, 35.571488596000052],
            [-80.802552632999948, 35.572090030000027],
            [-80.802518869999972, 35.572519778000071],
            [-80.802489729999934, 35.572943119000058],
            [-80.802460698999937, 35.573417253000059],
            [-80.80243511599997, 35.573916952000047],
            [-80.802410162999934, 35.574551645000042],
            [-80.80239336599999, 35.575219381000068],
            [-80.802385892999951, 35.575927014000058],
            [-80.802387834999934, 35.576513421000072],
            [-80.802396036999937, 35.577052452000032],
            [-80.802411577999976, 35.577670854000075],
            [-80.802433919999942, 35.578262571000039],
            [-80.802463580999984, 35.578854855000031],
            [-80.802500730999952, 35.579447734000041],
            [-80.802550710999981, 35.580106923000073],
            [-80.802599143999942, 35.580660635000072],
            [-80.802660381999942, 35.581272182000077],
            [-80.802734033999968, 35.581920331000049],
            [-80.802816102999941, 35.582563163000032],
            [-80.802889479999976, 35.583084558000053],
            [-80.802987636999944, 35.583725712000046],
            [-80.803089339999985, 35.58434429600004],
            [-80.803196170999968, 35.584946487000025],
            [-80.80330536699995, 35.585519834000024],
            [-80.803429470999959, 35.586129415000073],
            [-80.80354244199998, 35.58665183100004],
            [-80.803657989999977, 35.587159030000066],
            [-80.803752949999989, 35.587557950000075],
            [-80.803869426999938, 35.588031220000062],
            [-80.804047736999962, 35.588724149000029],
            [-80.804198027999973, 35.589300342000058],
            [-80.804295928999977, 35.589667443000053],
            [-80.804490595999937, 35.590411084000038],
            [-80.804637727999989, 35.590966819000073],
            [-80.804747025999973, 35.591382600000031],
            [-80.804851359999986, 35.591775763000044],
            [-80.804943639999976, 35.592123495000067],
            [-80.805070282999964, 35.592603721000046],
            [-80.805208044999972, 35.593120618000057],
            [-80.805326263999973, 35.59356904100008],
            [-80.805467866999948, 35.594098966000047],
            [-80.805778553999971, 35.595272606000037],
            [-80.805955868999945, 35.595950558000027],
            [-80.806272638999985, 35.597148693000065],
            [-80.806356533999974, 35.597530996000046],
            [-80.806211633999965, 35.598292131000051],
            [-80.80609134599996, 35.598962638000046],
            [-80.805949004999945, 35.599737680000032],
            [-80.805850916999987, 35.600279157000045],
            [-80.805754409999963, 35.600804694000033],
            [-80.805646740999975, 35.601413626000067],
            [-80.805547984999976, 35.601959824000062],
            [-80.805477846999963, 35.602354521000052],
            [-80.805375517999948, 35.60292524700003],
            [-80.805273949999957, 35.603486869000051],
            [-80.805128091999961, 35.604290951000053],
            [-80.805007885999942, 35.604948695000076],
            [-80.804930117999959, 35.605386041000031],
            [-80.804824210999982, 35.605971767000028],
            [-80.804686152999977, 35.606739982000079],
            [-80.804600498999946, 35.607223149000049],
            [-80.80451076899999, 35.607732003000024],
            [-80.804391022999937, 35.608443333000025],
            [-80.804259964999972, 35.609309435000057],
            [-80.804147519999958, 35.610159776000046],
            [-80.804070072999934, 35.610826081000027],
            [-80.804017929999986, 35.61133638900003],
            [-80.803954830999942, 35.612058804000071],
            [-80.80392253499997, 35.612495208000041],
            [-80.803875919999939, 35.613267456000074],
            [-80.803843106999977, 35.613980620000063],
            [-80.80382739099997, 35.61446287800004],
            [-80.803816238999957, 35.614972454000053],
            [-80.803810174999967, 35.615556190000063],
            [-80.803811886999938, 35.616111996000029],
            [-80.803821780999954, 35.616697868000074],
            [-80.803840817999969, 35.617308435000041],
            [-80.803867632999982, 35.617897258000028],
            [-80.803903294999941, 35.618492171000071],
            [-80.803946143999951, 35.619071461000033],
            [-80.803997524999943, 35.619654669000056],
            [-80.804061961999935, 35.620278162000034],
            [-80.804136226999958, 35.620920916000046],
            [-80.804221097999971, 35.621570782000049],
            [-80.804315903999964, 35.622233645000051],
            [-80.804419951999989, 35.622889799000063],
            [-80.804523682999957, 35.623498545000075],
            [-80.804594367999982, 35.623881380000057],
            [-80.804703558999961, 35.624440875000062],
            [-80.804780538999978, 35.624813882000069],
            [-80.804948311999965, 35.625558139000077],
            [-80.805131600999971, 35.62631377200006],
            [-80.805326488999981, 35.627060631000063],
            [-80.805532446999962, 35.627800543000035],
            [-80.805765314999974, 35.62858486600004],
            [-80.80597897499996, 35.629267936000076],
            [-80.806234122999967, 35.630040516000065],
            [-80.806480912999973, 35.630749276000074],
            [-80.806621735999954, 35.631136983000033],
            [-80.806847970999968, 35.631736387000046],
            [-80.807083132999935, 35.632336925000061],
            [-80.807235369999944, 35.632709390000059],
            [-80.807474113999945, 35.633276994000028],
            [-80.807711167999969, 35.633820354000079],
            [-80.807973421999975, 35.634404460000042],
            [-80.80826733899994, 35.635041413000067],
            [-80.808666765999988, 35.635886649000042],
            [-80.80902187099997, 35.63662948700005],
            [-80.809320326999966, 35.637242767000032],
            [-80.809543340999937, 35.637706447000028],
            [-80.809801337999943, 35.638238778000073],
            [-80.810092598999972, 35.63874798300003],
            [-80.810323638999989, 35.639336766000042],
            [-80.810411731999977, 35.640043425000044],
            [-80.810506273999977, 35.640757047000079],
            [-80.810846177999963, 35.641548701000033],
            [-80.810838901999944, 35.642021185000033],
            [-80.810805562999974, 35.642552966000039],
            [-80.810447655999951, 35.643120373000045],
            [-80.810274796999977, 35.643576171000063],
            [-80.810073373999955, 35.644124565000027],
            [-80.809814442999937, 35.644855808000045],
            [-80.809593207999967, 35.645507418000079],
            [-80.809410457999945, 35.646065036000039],
            [-80.809286971999938, 35.646452871000065],
            [-80.809158080999964, 35.64686981400007],
            [-80.809011368999961, 35.64736098700007],
            [-80.808835273999989, 35.64797808700007],
            [-80.808619226999951, 35.648776099000031],
            [-80.80842043499996, 35.649558573000036],
            [-80.808221198999945, 35.650388921000058],
            [-80.808075332999977, 35.651029980000033],
            [-80.808001679999961, 35.651367452000045],
            [-80.80790535899996, 35.651827969000067],
            [-80.807807921999938, 35.652316839000036],
            [-80.807682686999954, 35.652988578000077],
            [-80.80753002299997, 35.653881779000073],
            [-80.807410216999983, 35.654658749000077],
            [-80.807321226999989, 35.655295705000071],
            [-80.807223575999956, 35.656057006000026],
            [-80.807126159999939, 35.656900515000075],
            [-80.806983174999971, 35.657835650000038],
            [-80.806699827999978, 35.658395598000027],
            [-80.806426372999965, 35.658924730000024],
            [-80.805939128999967, 35.659839901000055],
            [-80.805754847999935, 35.66017855900003],
            [-80.805353477999972, 35.660925387000077],
            [-80.805023644999949, 35.661546706000024],
            [-80.804715146999968, 35.662127511000051],
            [-80.804393230999949, 35.662748836000048],
            [-80.804028130999939, 35.663475733000041],
            [-80.803776593999942, 35.663993808000043],
            [-80.803523588999951, 35.664529854000079],
            [-80.803228375999936, 35.665176908000035],
            [-80.802922437999939, 35.665882037000074],
            [-80.802668769999968, 35.666489915000056],
            [-80.802432804999967, 35.667079910000041],
            [-80.802128789999983, 35.667873947000032],
            [-80.801901843999985, 35.668495182000072],
            [-80.801652178999973, 35.669215831000031],
            [-80.801456476999988, 35.669808587000034],
            [-80.801191696999979, 35.670654195000054],
            [-80.800987171999964, 35.671353890000034],
            [-80.800791353999955, 35.672063100000059],
            [-80.800582682999959, 35.672864324000045],
            [-80.80038129999997, 35.673701400000027],
            [-80.80026570299998, 35.674214024000037],
            [-80.800145750999945, 35.674790786000074],
            [-80.800032061999957, 35.675374324000074],
            [-80.799925790999964, 35.675960394000072],
            [-80.799824371999989, 35.67656478400005],
            [-80.799729527999943, 35.677181114000064],
            [-80.799647738999965, 35.677764914000079],
            [-80.799570699999947, 35.678373630000067],
            [-80.799503577999985, 35.678968088000033],
            [-80.799444580999989, 35.679560974000026],
            [-80.799395890999961, 35.680122761000064],
            [-80.799361424999972, 35.680582125000058],
            [-80.799313176999988, 35.681359073000067],
            [-80.799284872999976, 35.68197592000007],
            [-80.799268429999984, 35.682703269000058],
            [-80.799270933999935, 35.683117518000074],
            [-80.799262220999935, 35.683643062000044],
            [-80.799263013999962, 35.68430433900005],
            [-80.799269471999935, 35.68460895700008],
            [-80.799284281999974, 35.685147131000065],
            [-80.799300865999953, 35.685603488000027],
            [-80.799321029999987, 35.686015964000035],
            [-80.799364720999961, 35.686682813000061],
            [-80.799426991999951, 35.687395366000032],
            [-80.799480499999959, 35.68794570600005],
            [-80.799538259999963, 35.688470874000075],
            [-80.799605091999979, 35.689167135000048],
            [-80.799679007999941, 35.689723580000077],
            [-80.799768272999984, 35.690334727000049],
            [-80.799866497999972, 35.690947655000059],
            [-80.799973338999962, 35.691559958000028],
            [-80.800088277999976, 35.692168883000079],
            [-80.800218185999938, 35.692807477000031],
            [-80.80035391499996, 35.693428542000049],
            [-80.800501710999981, 35.694061134000037],
            [-80.800653310999962, 35.694670946000031],
            [-80.800808270999937, 35.695259531000033],
            [-80.800976283999944, 35.695863959000064],
            [-80.801150296999936, 35.696458618000065],
            [-80.801321844999961, 35.697017441000071],
            [-80.80152554099999, 35.697652380000079],
            [-80.80172702699997, 35.698251517000074],
            [-80.801928264999958, 35.698824656000056],
            [-80.802114057999972, 35.699333742000078],
            [-80.802308926999956, 35.699848819000067],
            [-80.802490475999946, 35.700313290000054],
            [-80.80290247399995, 35.700574749000054],
            [-80.802843016999987, 35.701187179000044],
            [-80.802965229999984, 35.701698578000048],
            [-80.802633742999944, 35.702046084000074],
            [-80.802526476999958, 35.702556817000072],
            [-80.80239241299995, 35.703246104000073],
            [-80.802268909999952, 35.703934748000052],
            [-80.802192154999943, 35.704401186000041],
            [-80.802121861999979, 35.704859366000051],
            [-80.802065142999936, 35.705257232000065],
            [-80.802118420999989, 35.705818645000079],
            [-80.802041151999958, 35.706313672000078],
            [-80.801963457999989, 35.706867188000047],
            [-80.801873001999979, 35.707647868000038],
            [-80.801812680999944, 35.708272358000045],
            [-80.80178284699997, 35.708633647000056],
            [-80.801747354999975, 35.709128464000059],
            [-80.801705327999969, 35.709756421000066],
            [-80.801670262999949, 35.710363606000044],
            [-80.80164022799994, 35.71100801800003],
            [-80.801647856999978, 35.711473475000048],
            [-80.801662075999957, 35.711965554000074],
            [-80.801712984999938, 35.712385632000064],
            [-80.801751765999938, 35.712965567000026],
            [-80.801761283999952, 35.713375271000075],
            [-80.801782498999955, 35.713739413000042],
            [-80.801811554999972, 35.714103196000053],
            [-80.801848441999937, 35.714466505000075],
            [-80.801893148999966, 35.714829231000067],
            [-80.80194566199998, 35.715191259000051],
            [-80.801869089999968, 35.715894147000029],
            [-80.801939150999942, 35.716533417000051],
            [-80.802051309999968, 35.717422390000024],
            [-80.802156564999962, 35.718146211000033],
            [-80.802279021999937, 35.718895245000056],
            [-80.802420577999953, 35.719672865000064],
            [-80.802582853999979, 35.720479168000054],
            [-80.802741482999977, 35.721199348000027],
            [-80.802877931999944, 35.721777682000038],
            [-80.803005578999944, 35.722296164000056],
            [-80.80313496499997, 35.722824957000057],
            [-80.803308207999976, 35.723503738000034],
            [-80.803449757999942, 35.724063197000078],
            [-80.803609055999971, 35.724688155000024],
            [-80.803792321999936, 35.725409698000078],
            [-80.803980932999934, 35.726163057000065],
            [-80.804136946999961, 35.726775201000066],
            [-80.804233088999979, 35.727158924000037],
            [-80.804332723999948, 35.727562754000076],
            [-80.804414425999937, 35.727915041000074],
            [-80.804629972999976, 35.729138129000034],
            [-80.804799238999976, 35.730008810000072],
            [-80.804923513999938, 35.730944084000043],
            [-80.805012966999982, 35.731744348000063],
            [-80.80507003699995, 35.73227015100008],
            [-80.805122867999955, 35.732745008000052],
            [-80.805201550999982, 35.733463047000043],
            [-80.805253844999982, 35.733968470000036],
            [-80.805305529999941, 35.734455669000056],
            [-80.805363778999947, 35.734972825000057],
            [-80.805433969999967, 35.73559937300007],
            [-80.805604741999957, 35.737165573000027],
            [-80.805655871999988, 35.737616484000057],
            [-80.805702366999981, 35.738054064000039],
            [-80.805820923999988, 35.739129318000039],
            [-80.805938677999961, 35.740233854000053],
            [-80.805999682999982, 35.740777648000062],
            [-80.806057844999941, 35.741310331000079],
            [-80.806123477999961, 35.74192658700008],
            [-80.806195169999967, 35.742587932000049],
            [-80.806261387999939, 35.743185815000061],
            [-80.806358001999968, 35.744045305000043],
            [-80.806416539999987, 35.744589719000032],
            [-80.806557139999938, 35.745874703000027],
            [-80.806643984999937, 35.746664318000057],
            [-80.806708844999946, 35.747264481000059],
            [-80.806798492999974, 35.748072225000044],
            [-80.806858377999959, 35.748617437000064],
            [-80.806961901999955, 35.749579738000079],
            [-80.807021913999961, 35.750146864000044],
            [-80.807097451999937, 35.75081300800008],
            [-80.807178809999982, 35.751571599000044],
            [-80.807239975999948, 35.752120563000062],
            [-80.807302095999944, 35.752659959000027],
            [-80.807375891999982, 35.753332556000032],
            [-80.807428679999987, 35.753826887000059],
            [-80.807492543999956, 35.754407366000066],
            [-80.807544058999952, 35.754866689000039],
            [-80.807662944999947, 35.755962091000072],
            [-80.807734279999977, 35.756594867000047],
            [-80.807821875999934, 35.757392012000025],
            [-80.807895650999967, 35.758081992000029],
            [-80.807952006999983, 35.758590041000048],
            [-80.808004736999976, 35.759081992000063],
            [-80.808160166999983, 35.760479641000074],
            [-80.808225363999952, 35.761088271000062],
            [-80.808271402999935, 35.761502525000026],
            [-80.808328415999938, 35.762029723000069],
            [-80.808398988999954, 35.762665927000057],
            [-80.808532367999987, 35.763896720000048],
            [-80.808597650999957, 35.764478565000047],
            [-80.808793782999942, 35.766270708000036],
            [-80.808907155999975, 35.767288625000049],
            [-80.809027765999986, 35.768402278000053],
            [-80.809141888999989, 35.769434305000061],
            [-80.80920643799999, 35.770054440000024],
            [-80.809275730999957, 35.770691151000051],
            [-80.809343197999965, 35.771296562000032],
            [-80.809412065999936, 35.77213220200008],
            [-80.809542059999956, 35.77338110200003],
            [-80.809678521999956, 35.774357155000075],
            [-80.809724668999934, 35.774781573000041],
            [-80.809772715999941, 35.775215465000031],
            [-80.809829676999982, 35.775887471000033],
            [-80.809885016999942, 35.776451568000027],
            [-80.809944297999948, 35.776991510000073],
            [-80.809987429999978, 35.777353525000024],
            [-80.810045878999972, 35.777907363000054],
            [-80.810114814999963, 35.778540551000049],
            [-80.810096261999945, 35.779048998000064],
            [-80.810038457999951, 35.77963610300003],
            [-80.809990392999964, 35.780131728000072],
            [-80.809880517999943, 35.781276611000067],
            [-80.809828249999953, 35.781804385000044],
            [-80.809739432999947, 35.782686823000063],
            [-80.809651628999973, 35.783388547000072],
            [-80.809530449999954, 35.784534665000024],
            [-80.809440602999985, 35.785831705000078],
            [-80.809382325999934, 35.78627235700003],
            [-80.80924665699996, 35.787520843000038],
            [-80.809162328999946, 35.788603837000039],
            [-80.809126315999947, 35.78914820600005],
            [-80.809075212999971, 35.78969231800005],
            [-80.809013903999983, 35.790318052000032],
            [-80.808958783999969, 35.790917312000033],
            [-80.808900392999988, 35.791515872000048],
            [-80.808853892999934, 35.792012146000047],
            [-80.808802208999964, 35.792536211000026],
            [-80.808757137999976, 35.792990926000073],
            [-80.808695420999982, 35.793631326000025],
            [-80.808652022999979, 35.79407704700003],
            [-80.808606727999972, 35.794556683000053],
            [-80.80856425099995, 35.794992805000049],
            [-80.80852103999996, 35.795458950000068],
            [-80.808493240999951, 35.795774542000061],
            [-80.808446778999951, 35.796293344000048],
            [-80.808379612999943, 35.79707529600006],
            [-80.808335417999956, 35.797642445000065],
            [-80.80829950399999, 35.798142443000074],
            [-80.808266542999945, 35.79863614900006],
            [-80.808234423999977, 35.799152799000069],
            [-80.808197373999974, 35.79978447600007],
            [-80.808154895999962, 35.800499675000026],
            [-80.808085046999963, 35.801665927000045],
            [-80.808044267999946, 35.802401088000067],
            [-80.808028254999954, 35.802711199000044],
            [-80.807983320999938, 35.803170723000051],
            [-80.807911587999968, 35.804230456000028],
            [-80.80786324799999, 35.805540391000079],
            [-80.807825046999938, 35.805938467000033],
            [-80.807733545999952, 35.807189609000034],
            [-80.807682529999965, 35.808606627000074],
            [-80.807658826999955, 35.80928087500007],
            [-80.807612214999949, 35.809996658000045],
            [-80.807504519999952, 35.811396685000034],
            [-80.80745731199994, 35.812649438000051],
            [-80.807445074999976, 35.81308941900005],
            [-80.807415015999936, 35.813604938000026],
            [-80.807357162999949, 35.814630859000033],
            [-80.807328480999956, 35.815115965000075],
            [-80.807306448999952, 35.81551005700004],
            [-80.807277411999962, 35.816050147000055],
            [-80.807254115999967, 35.816459869000028],
            [-80.807226863999972, 35.816905126000051],
            [-80.807195758999967, 35.81744939500004],
            [-80.807158537999953, 35.818148842000028],
            [-80.807125588999952, 35.818707921000055],
            [-80.807095662999984, 35.819260568000061],
            [-80.807070100999965, 35.819706336000024],
            [-80.807020700999942, 35.820591451000041],
            [-80.806964812999979, 35.821575821000067],
            [-80.806924863999939, 35.822313665000024],
            [-80.806880394999951, 35.823103348000075],
            [-80.806855151999969, 35.823566549000077],
            [-80.806823342999962, 35.82410369400003],
            [-80.806763156999978, 35.825180384000078],
            [-80.806714651999982, 35.826087942000072],
            [-80.806659096999965, 35.827079840000067],
            [-80.806602985999973, 35.828041681000059],
            [-80.806576689999986, 35.828518419000034],
            [-80.80654588799996, 35.829102443000068],
            [-80.806491925999978, 35.830039746000068],
            [-80.806399146999979, 35.831685316000062],
            [-80.806372305999957, 35.832160816000055],
            [-80.806447094999953, 35.83288230800008],
            [-80.806380041999944, 35.833411221000063],
            [-80.806320159999984, 35.833940704000042],
            [-80.80626745699999, 35.834470692000025],
            [-80.806221938999954, 35.835001122000051],
            [-80.806183611999984, 35.835531929000069],
            [-80.80615248099997, 35.836063047000039],
            [-80.806133487999944, 35.836443221000025],
            [-80.806121100999974, 35.836823575000039],
            [-80.806115321999982, 35.837204034000024],
            [-80.806116152999948, 35.837584521000053],
            [-80.806123593999985, 35.837964961000068],
            [-80.805984581999951, 35.83899800100005],
            [-80.80591740899996, 35.840195965000078],
            [-80.805873247999955, 35.841004113000054],
            [-80.805837928999949, 35.841596035000066],
            [-80.805803388999948, 35.842191202000038],
            [-80.805693376999955, 35.843700431000059],
            [-80.805639614999961, 35.845096480000052],
            [-80.805613508999954, 35.845595531000072],
            [-80.805591937999964, 35.846080918000041],
            [-80.805574466999985, 35.84656969200006],
            [-80.80556095999998, 35.84707042000008],
            [-80.805553259999954, 35.847476808000067],
            [-80.805548907999935, 35.847970036000049],
            [-80.805551363999939, 35.848486738000076],
            [-80.805561392999948, 35.849137294000059],
            [-80.805579078999983, 35.849779755000043],
            [-80.805594127999939, 35.850167131000035],
            [-80.805614623999986, 35.850588500000072],
            [-80.805652914999939, 35.851235065000026],
            [-80.805731677999972, 35.852221370000052],
            [-80.805859326999951, 35.853435495000042],
            [-80.806031737999945, 35.85466344200006],
            [-80.806249970999943, 35.855904084000031],
            [-80.806377385999951, 35.856557426000052],
            [-80.806512037999937, 35.857139084000039],
            [-80.806817724999974, 35.858367426000029],
            [-80.807166776999964, 35.859588082000073],
            [-80.807558907999976, 35.86080004300004],
            [-80.807993795999948, 35.862002309000047],
            [-80.808471076999979, 35.86319386100007],
            [-80.808749249999948, 35.863852350000059],
            [-80.808990350999977, 35.864373707000027],
            [-80.809551201999966, 35.865540890000034],
            [-80.810153166999953, 35.866694447000043],
            [-80.810795730999985, 35.867833381000025],
            [-80.811478356999942, 35.868956749000063],
            [-80.812200502999985, 35.870063650000077],
            [-80.812635084999954, 35.870703548000051],
            [-80.812961565999956, 35.871153154000069],
            [-80.813760900999966, 35.872224341000049],
            [-80.814597852999952, 35.873276331000056],
            [-80.815471724999952, 35.87430824300003],
            [-80.816381793999938, 35.875319224000066],
            [-80.817327302999956, 35.876308431000041],
            [-80.817918166999959, 35.876904617000037],
            [-80.818307464999975, 35.877275041000075],
            [-80.819321471999956, 35.878218254000046],
            [-80.820368479999956, 35.879137284000024],
            [-80.821447617999979, 35.880031368000061],
            [-80.822557998999969, 35.880899766000027],
            [-80.823698683999964, 35.881741746000046],
            [-80.824654141999986, 35.882412651000038],
            [-80.826067155999965, 35.883343679000063],
            [-80.827293005999934, 35.884102319000078],
            [-80.828545221999946, 35.884831875000032],
            [-80.829822772999989, 35.885531747000073],
            [-80.831124597999974, 35.886201355000026],
            [-80.832226271999957, 35.886737137000068],
            [-80.833796717999974, 35.887447574000078],
            [-80.83516478599995, 35.888023145000034],
            [-80.836552697999934, 35.888566385000047],
            [-80.837959313999988, 35.889076845000034],
            [-80.839383414999986, 35.889554082000075],
            [-80.840602114999967, 35.889933522000035],
            [-80.842279387999952, 35.890407356000026],
            [-80.843748839999989, 35.890782681000076],
            [-80.84523100399997, 35.891123378000032],
            [-80.846724649999942, 35.891429164000044],
            [-80.848228493999954, 35.891699775000063],
            [-80.849530369999968, 35.891905885000028],
            [-80.851261821999969, 35.892134618000057],
            [-80.852788775999954, 35.892298486000072],
            [-80.854320915999949, 35.892426464000039],
            [-80.85585697099998, 35.892518443000029],
            [-80.857395633999943, 35.892574345000071],
            [-80.858743089999962, 35.89259504000006],
            [-80.860475702999963, 35.892577766000045],
            [-80.862014558999988, 35.892525282000065],
            [-80.863550901999986, 35.892436716000077],
            [-80.86508345499999, 35.892312142000037],
            [-80.866610965999939, 35.892151665000029],
            [-80.867963753999959, 35.891980303000025],
            [-80.86964575099995, 35.891723561000049],
            [-80.871150478999937, 35.891456294000079],
            [-80.872645124999963, 35.891153831000054],
            [-80.874128457999973, 35.890816421000068],
            [-80.875599182999963, 35.890444357000035],
            [-80.876915586999985, 35.890080133000026],
            [-80.878497988999982, 35.889597524000067],
            [-80.879923694999945, 35.889123449000067],
            [-80.881331995999972, 35.888616124000066],
            [-80.882721726999989, 35.888075970000045],
            [-80.884091731999945, 35.887503435000042],
            [-80.885329955999964, 35.886951554000063],
            [-80.886768040999982, 35.886263150000048],
            [-80.888072083999987, 35.885596448000058],
            [-80.889351978999969, 35.884899416000053],
            [-80.890606659999946, 35.884172631000069],
            [-80.891835036999964, 35.883416726000064],
            [-80.89287264099994, 35.882744616000025],
            [-80.893364156999951, 35.882389906000071],
            [-80.894199661999949, 35.881811233000064],
            [-80.894888546999937, 35.881317682000031],
            [-80.895560183999976, 35.880812457000047],
            [-80.896461663999958, 35.880111274000058],
            [-80.897113702999945, 35.879586846000052],
            [-80.897749643999987, 35.879049968000061],
            [-80.898599826999941, 35.878307775000053],
            [-80.899212724999984, 35.877754481000068],
            [-80.899755437999943, 35.87724146000005],
            [-80.900606983999978, 35.876406691000057],
            [-80.901169219999986, 35.875836553000056],
            [-80.901534805999972, 35.875451675000079],
            [-80.90246390599998, 35.874451961000034],
            [-80.90354182699997, 35.87320330700004],
            [-80.904382298999963, 35.872153182000034],
            [-80.905153218999942, 35.871129043000053],
            [-80.90594992299998, 35.869995970000048],
            [-80.906675772999961, 35.868890678000071],
            [-80.907362170999988, 35.867768813000055],
            [-80.908008548999987, 35.866631306000045],
            [-80.908614366999984, 35.865479108000045],
            [-80.909164524999937, 35.864345901000036],
            [-80.909702355999968, 35.863134474000049],
            [-80.910183625999935, 35.86194398300006],
            [-80.910622536999938, 35.860742696000045],
            [-80.911018724999963, 35.859531615000037],
            [-80.911371864999978, 35.85831173400004],
            [-80.911677951999934, 35.857101211000042],
            [-80.911947870999938, 35.855849642000067],
            [-80.912170255999968, 35.854609503000063],
            [-80.912348645999941, 35.853364647000035],
            [-80.912482891999957, 35.852116116000047],
            [-80.912572882999939, 35.850864958000045],
            [-80.912618546999965, 35.849612206000074],
            [-80.912619847999963, 35.84835890100004],
            [-80.912611422999987, 35.847870558000068],
            [-80.912633866999954, 35.847468592000041],
            [-80.912715840999965, 35.846368682000048],
            [-80.912749117999965, 35.845823587000041],
            [-80.912796276999984, 35.844774575000031],
            [-80.912815911999985, 35.844250419000048],
            [-80.912838567999984, 35.84373862800004],
            [-80.912864737999939, 35.843269838000026],
            [-80.912895062999951, 35.842722909000031],
            [-80.912915683999984, 35.84233617600006],
            [-80.912973014999977, 35.841326029000072],
            [-80.913007745999948, 35.840664281000045],
            [-80.913068062999969, 35.839605478000067],
            [-80.913096096999936, 35.839080855000077],
            [-80.913123844999973, 35.838579312000036],
            [-80.913154047999967, 35.838001920000067],
            [-80.913180094999973, 35.837515187000065],
            [-80.913205411999968, 35.837065748000043],
            [-80.913230878999968, 35.836602625000069],
            [-80.913296410999976, 35.835469940000053],
            [-80.913369260999957, 35.834093084000074],
            [-80.913409885999954, 35.833379007000076],
            [-80.913464570999963, 35.832374167000069],
            [-80.913520793999965, 35.831381774000079],
            [-80.913552290999974, 35.83079755600005],
            [-80.913574805999986, 35.830365224000047],
            [-80.913608873999976, 35.829764859000079],
            [-80.913685391999934, 35.828346306000071],
            [-80.91375101899996, 35.827164254000024],
            [-80.913789834999989, 35.826436905000037],
            [-80.913821721999966, 35.82587081500003],
            [-80.91385487499997, 35.825230733000069],
            [-80.91388821399994, 35.824642073000064],
            [-80.913924646999988, 35.824000987000034],
            [-80.91394556399996, 35.82361684600005],
            [-80.914003433999937, 35.822603451000077],
            [-80.914038461999951, 35.821952042000078],
            [-80.914092296999968, 35.820997039000076],
            [-80.914139116999934, 35.820113381000056],
            [-80.914178245999949, 35.819409225000072],
            [-80.914237659999969, 35.818345198000031],
            [-80.914275518999943, 35.817687273000047],
            [-80.914312934999941, 35.816979160000074],
            [-80.914347058999965, 35.81637586800008],
            [-80.914376656999934, 35.815864513000065],
            [-80.914404108999975, 35.815327745000047],
            [-80.914450253999973, 35.814511733000074],
            [-80.914489059999937, 35.813797961000034],
            [-80.914522396999985, 35.813188527000079],
            [-80.914599805999956, 35.812080016000039],
            [-80.914638973999956, 35.811291260000075],
            [-80.914658696999936, 35.810842424000043],
            [-80.914675555999963, 35.810422073000041],
            [-80.914706343999967, 35.80987512400003],
            [-80.914735617999952, 35.809302030000026],
            [-80.914770684999951, 35.808696027000053],
            [-80.914799236999954, 35.808325506000074],
            [-80.914826442999981, 35.807932506000043],
            [-80.914852810999946, 35.807511400000067],
            [-80.91487776799994, 35.807116842000028],
            [-80.914904886999977, 35.806619559000069],
            [-80.914926109999953, 35.80617198200008],
            [-80.914961630999983, 35.805417558000045],
            [-80.914990892999981, 35.804626606000056],
            [-80.915014025999938, 35.80418241600006],
            [-80.91507495899998, 35.803224385000078],
            [-80.915101475999961, 35.802835752000078],
            [-80.915132484999958, 35.802481589000024],
            [-80.91518376199997, 35.801928728000064],
            [-80.915225815999975, 35.801438244000053],
            [-80.915368776999969, 35.799946959000067],
            [-80.915418382999974, 35.799413980000054],
            [-80.915466625999954, 35.79891679800005],
            [-80.915543868999976, 35.798090105000028],
            [-80.915619900999957, 35.797310817000039],
            [-80.915679889999979, 35.796668540000042],
            [-80.91575232799994, 35.795901873000048],
            [-80.915837124999939, 35.795149683000034],
            [-80.915890631999957, 35.794592895000051],
            [-80.915942886999972, 35.794077002000051],
            [-80.915980695999963, 35.793681954000078],
            [-80.916014349999955, 35.793280326000058],
            [-80.91607953099998, 35.792433679000055],
            [-80.916169568999976, 35.791471887000057],
            [-80.916229574999988, 35.790845674000025],
            [-80.916280215999961, 35.790297552000027],
            [-80.916326125999944, 35.789815866000026],
            [-80.916372539999941, 35.789319749000072],
            [-80.916428326999949, 35.788739902000032],
            [-80.916471411999964, 35.788272025000026],
            [-80.916556656999944, 35.787359307000031],
            [-80.916629052999951, 35.786606060000054],
            [-80.916694610999969, 35.785908636000045],
            [-80.916751916999942, 35.785314479000078],
            [-80.916816419999975, 35.784607393000044],
            [-80.916868285999954, 35.784098392000033],
            [-80.916916367999988, 35.783588122000026],
            [-80.916977460999988, 35.782853419000048],
            [-80.917039500999977, 35.781965250000042],
            [-80.917087803999948, 35.781087310000032],
            [-80.917123534999973, 35.780244444000061],
            [-80.917142745999968, 35.779689846000053],
            [-80.917155156999968, 35.77925556100007],
            [-80.917162082999937, 35.778743076000069],
            [-80.917159044999948, 35.777618699000072],
            [-80.917153739999947, 35.777287114000046],
            [-80.917141661999949, 35.776682496000035],
            [-80.917102264999983, 35.775829890000068],
            [-80.917011190999972, 35.774535626000045],
            [-80.916984613999944, 35.774182662000044],
            [-80.91693990899995, 35.773649803000069],
            [-80.916887334999956, 35.77308825700004],
            [-80.916839250999942, 35.772623734000035],
            [-80.916785968999989, 35.772139396000057],
            [-80.916687783999976, 35.771094383000047],
            [-80.916600029999984, 35.770269323000036],
            [-80.916536401999963, 35.769680944000072],
            [-80.916450544999975, 35.768916604000026],
            [-80.916384640999979, 35.768298238000057],
            [-80.916177703999949, 35.767737025000031],
            [-80.916137144999936, 35.767486579000035],
            [-80.916103686999975, 35.767235436000078],
            [-80.916077349999966, 35.766983730000049],
            [-80.916058146999944, 35.766731597000046],
            [-80.916046086999984, 35.766479169000036],
            [-80.916041177999944, 35.766226582000058],
            [-80.916043421999973, 35.765973969000072],
            [-80.916052817999969, 35.765721466000059],
            [-80.915969360999952, 35.76473240200005],
            [-80.915923785999951, 35.764250046000029],
            [-80.915881859999956, 35.763835099000062],
            [-80.91581062399996, 35.763325823000059],
            [-80.915727549999986, 35.762704824000025],
            [-80.915641281999967, 35.762114824000037],
            [-80.915502858999957, 35.761343176000025],
            [-80.915319981999971, 35.760749901000054],
            [-80.915434871999935, 35.759932870000057],
            [-80.915362613999946, 35.759288067000057],
            [-80.91531078099996, 35.758838618000027],
            [-80.91514078299997, 35.757286124000075],
            [-80.915083268999979, 35.756766847000051],
            [-80.915032873999962, 35.756317963000072],
            [-80.914818620999938, 35.754365594000035],
            [-80.914762379999956, 35.753870249000045],
            [-80.91460725799999, 35.752471580000076],
            [-80.914546365999968, 35.751915500000052],
            [-80.914490877999981, 35.75141773100006],
            [-80.914435220999962, 35.75090724000006],
            [-80.914369006999948, 35.750330985000062],
            [-80.914248260999955, 35.749230858000033],
            [-80.914191897999956, 35.748703414000033],
            [-80.914121064999961, 35.748067930000047],
            [-80.914011501999937, 35.747088062000046],
            [-80.913955312999974, 35.746605427000077],
            [-80.913890805999984, 35.746006697000041],
            [-80.91382546799997, 35.745423494000079],
            [-80.913724288999958, 35.744509047000065],
            [-80.913666628999977, 35.743991264000044],
            [-80.913597019999941, 35.743345929000043],
            [-80.913545463999981, 35.742903951000073],
            [-80.913468192999972, 35.742226487000039],
            [-80.913385216999984, 35.741464912000026],
            [-80.913294198999949, 35.74064544600003],
            [-80.913222488999963, 35.739980909000053],
            [-80.913169862999951, 35.739489347000074],
            [-80.913086246999967, 35.738760152000054],
            [-80.912981746999947, 35.737834930000076],
            [-80.912917843999935, 35.737241992000065],
            [-80.912845123999944, 35.736602042000072],
            [-80.912765158999946, 35.735879480000051],
            [-80.912714173999973, 35.735401514000046],
            [-80.912623919999987, 35.734602811000059],
            [-80.912550502999977, 35.73393344200008],
            [-80.91247349799994, 35.733249869000076],
            [-80.912404265999953, 35.73260859100003],
            [-80.912328228999968, 35.731916984000065],
            [-80.912236605999965, 35.731102511000074],
            [-80.912187407999966, 35.730638767000073],
            [-80.912135846999945, 35.730166580000059],
            [-80.912081508999961, 35.729681392000032],
            [-80.911971917999949, 35.72870643400006],
            [-80.91190022699999, 35.728054607000047],
            [-80.911841849999973, 35.727520728000059],
            [-80.911739986999976, 35.726618300000041],
            [-80.911687166999968, 35.726096128000052],
            [-80.911643921999939, 35.725629938000054],
            [-80.911592267999936, 35.725114655000027],
            [-80.911532075999958, 35.724558807000051],
            [-80.911424644999954, 35.723722959000042],
            [-80.911321442999963, 35.722824555000045],
            [-80.911250716999973, 35.722225129000037],
            [-80.911180325999965, 35.721672066000053],
            [-80.911043248999988, 35.720616392000068],
            [-80.910948151999946, 35.719956745000047],
            [-80.910867260999964, 35.719415952000077],
            [-80.910709016999988, 35.718464578000066],
            [-80.910614850999934, 35.717845993000026],
            [-80.910544954999978, 35.717417152000053],
            [-80.910382044999949, 35.716389309000078],
            [-80.910283872999969, 35.715863171000024],
            [-80.91010390799994, 35.714971253000044],
            [-80.910001429999966, 35.714495861000046],
            [-80.909902493999937, 35.713971515000026],
            [-80.910018438999941, 35.713585838000029],
            [-80.910247979999951, 35.712855730000058],
            [-80.910454215999948, 35.712166717000059],
            [-80.91066111799995, 35.711431615000038],
            [-80.910855698999967, 35.710700956000039],
            [-80.910979190999967, 35.710207438000054],
            [-80.911118148999947, 35.709622205000073],
            [-80.911232661999975, 35.709110856000052],
            [-80.911335845999986, 35.708623325000076],
            [-80.911435392999977, 35.708125120000034],
            [-80.911534478999954, 35.707596590000037],
            [-80.91162247799997, 35.707093564000047],
            [-80.91172697799999, 35.706443426000078],
            [-80.911852307999936, 35.705576656000062],
            [-80.911935981999989, 35.704925612000068],
            [-80.911992077999969, 35.704438184000026],
            [-80.912034971999958, 35.704030755000076],
            [-80.912096368999983, 35.703367988000025],
            [-80.912150260999965, 35.702686954000058],
            [-80.912199915999963, 35.701871069000049],
            [-80.912227648999988, 35.701220939000052],
            [-80.912249989999964, 35.700349435000078],
            [-80.912255529999982, 35.69951254800003],
            [-80.912249145999965, 35.698855582000078],
            [-80.912232868999979, 35.698224137000068],
            [-80.912202831999934, 35.697485894000067],
            [-80.912164655999959, 35.696830596000041],
            [-80.912104487999954, 35.696017500000039],
            [-80.912028739999982, 35.695199660000071],
            [-80.911926023999968, 35.694284301000039],
            [-80.911805544999936, 35.693376905000036],
            [-80.911699314999964, 35.69267227000006],
            [-80.911606310999957, 35.692113845000051],
            [-80.911529652999945, 35.691682983000078],
            [-80.911439688999963, 35.69120711100004],
            [-80.911346897999977, 35.690743372000043],
            [-80.91125563199995, 35.690310550000049],
            [-80.911099078999939, 35.689615954000033],
            [-80.910895953999955, 35.688779442000055],
            [-80.910674959999938, 35.68793528100008],
            [-80.910486874999947, 35.687261160000048],
            [-80.910279380999953, 35.686565330000064],
            [-80.909994433999941, 35.685668445000033],
            [-80.909769427999947, 35.685000974000047],
            [-80.909604498999954, 35.684533692000059],
            [-80.909346184999947, 35.683862597000029],
            [-80.909333244999971, 35.683307837000029],
            [-80.909670465999966, 35.682992308000053],
            [-80.909887461999972, 35.682426732000067],
            [-80.910098642999969, 35.681855754000026],
            [-80.910254407999957, 35.681418575000066],
            [-80.910377176999987, 35.681064155000058],
            [-80.910567944999968, 35.680500474000041],
            [-80.910750230999952, 35.67994772600008],
            [-80.910933307999983, 35.679375604000029],
            [-80.911069607999934, 35.678937361000067],
            [-80.911250140999982, 35.678337147000036],
            [-80.911384658999964, 35.677871771000071],
            [-80.911537770999985, 35.677318661000072],
            [-80.911735089999979, 35.676567785000032],
            [-80.911898658999974, 35.675908610000079],
            [-80.911993009999946, 35.675508882000031],
            [-80.912147913999945, 35.674824147000038],
            [-80.91229890599999, 35.674120462000076],
            [-80.912422872999969, 35.673503671000049],
            [-80.912548091999952, 35.672837620000053],
            [-80.912639162999938, 35.672320483000078],
            [-80.912733809999963, 35.671747888000027],
            [-80.912823215999936, 35.67116769900008],
            [-80.91288517299995, 35.670739726000079],
            [-80.912938031999943, 35.670351887000038],
            [-80.913004587999978, 35.669834818000027],
            [-80.913066716999936, 35.669316983000044],
            [-80.913123956999982, 35.668803127000047],
            [-80.91317051599998, 35.668352791000075],
            [-80.913286061999941, 35.667727649000028],
            [-80.91354802799998, 35.667172303000029],
            [-80.913833396999962, 35.666554882000071],
            [-80.914119945999971, 35.665911957000048],
            [-80.914360835999958, 35.665350578000073],
            [-80.914677610999945, 35.664583407000066],
            [-80.91501317899997, 35.663721549000059],
            [-80.91519763499997, 35.663220725000031],
            [-80.915369398999985, 35.662754150000069],
            [-80.915524912999956, 35.662325360000068],
            [-80.915698202999977, 35.661827306000077],
            [-80.915869593999957, 35.661311056000045],
            [-80.91600723199997, 35.660868081000046],
            [-80.916127862999986, 35.660468929000047],
            [-80.916269994999936, 35.659989629000052],
            [-80.916402109999979, 35.659531488000027],
            [-80.916557577999981, 35.658986151000079],
            [-80.916721418999941, 35.658409866000056],
            [-80.916883197999937, 35.657774507000056],
            [-80.917079522999984, 35.65695504200005],
            [-80.917220329999964, 35.656324674000075],
            [-80.917361562999986, 35.655648843000051],
            [-80.917489953999961, 35.654989015000069],
            [-80.917578527999979, 35.654503007000073],
            [-80.917686733999972, 35.653861533000054],
            [-80.917808038999965, 35.653071136000051],
            [-80.917915196999957, 35.652286996000043],
            [-80.917984064999985, 35.651728763000051],
            [-80.918041715999948, 35.651207561000035],
            [-80.918110575999947, 35.650507335000043],
            [-80.918164624999974, 35.649867439000047],
            [-80.918210338999984, 35.649230167000042],
            [-80.918250561999969, 35.648570091000067],
            [-80.918281898999965, 35.647890337000035],
            [-80.918300906999946, 35.647304916000053],
            [-80.918313069999954, 35.646654717000047],
            [-80.918316072999971, 35.645990682000047],
            [-80.918311184999936, 35.645449143000064],
            [-80.918294389999971, 35.644668653000053],
            [-80.918274164999957, 35.644071813000039],
            [-80.918245893999938, 35.643484683000054],
            [-80.918198522999944, 35.642729661000033],
            [-80.918148726999959, 35.642082046000041],
            [-80.918106470999987, 35.641601460000061],
            [-80.918049617999941, 35.641022507000059],
            [-80.917976098999986, 35.640354338000066],
            [-80.917880277999984, 35.639585213000032],
            [-80.917774435999945, 35.638811907000047],
            [-80.917670544999964, 35.638079201000039],
            [-80.917560561999949, 35.637311975000046],
            [-80.917420521999986, 35.636349487000075],
            [-80.917320779999955, 35.635655391000057],
            [-80.917260010999939, 35.635247797000034],
            [-80.917195242999981, 35.634790275000057],
            [-80.917094950999967, 35.63408232300003],
            [-80.916892970999982, 35.63270404900004],
            [-80.916812971999946, 35.632132653000042],
            [-80.916742285999987, 35.63163640700003],
            [-80.916662487999986, 35.631089768000038],
            [-80.916593605999935, 35.630628239000032],
            [-80.916527291999955, 35.630165733000069],
            [-80.916443766999976, 35.629585261000045],
            [-80.916341671999987, 35.628859250000062],
            [-80.916247354999939, 35.628206385000055],
            [-80.916109118999941, 35.627254912000069],
            [-80.916041174999975, 35.626771869000038],
            [-80.915928636999979, 35.626002638000045],
            [-80.915830237999955, 35.625374647000058],
            [-80.915734357999952, 35.624800596000057],
            [-80.915634836999971, 35.624236813000039],
            [-80.915531414999975, 35.623683960000051],
            [-80.915349078999952, 35.622786331000043],
            [-80.915253901999961, 35.62235196000006],
            [-80.915107620999947, 35.621715466000069],
            [-80.914936836999971, 35.621004367000069],
            [-80.914800401999969, 35.620462209000038],
            [-80.914674138999942, 35.619981234000079],
            [-80.914542883999957, 35.619499870000027],
            [-80.914383890999943, 35.618940097000063],
            [-80.914164708999976, 35.618203046000076],
            [-80.913970454999969, 35.617577044000029],
            [-80.913811498999962, 35.617084219000049],
            [-80.913650805999964, 35.61660112800007],
            [-80.913460899999961, 35.61602663900004],
            [-80.913163882999982, 35.615179443000045],
            [-80.912935565999987, 35.614560515000051],
            [-80.912726128999964, 35.614018455000064],
            [-80.912496215999965, 35.61344897500004],
            [-80.912234836999971, 35.612822762000064],
            [-80.911971076999976, 35.612207419000072],
            [-80.911739218999969, 35.61168542300004],
            [-80.911810511999988, 35.61110345700007],
            [-80.911908447999963, 35.610542935000069],
            [-80.911979751999979, 35.609327047000079],
            [-80.912089899999955, 35.608893568000042],
            [-80.912183117999973, 35.608442835000062],
            [-80.912346964999983, 35.607911228000034],
            [-80.912434725999958, 35.607453828000075],
            [-80.912535915999968, 35.607031728000038],
            [-80.912638885999968, 35.606490643000029],
            [-80.912721089999934, 35.606031804000054],
            [-80.912788215999967, 35.605581310000048],
            [-80.912846226999989, 35.605119345000048],
            [-80.912922561999949, 35.604545699000028],
            [-80.913007068999946, 35.60394169500006],
            [-80.91308597799997, 35.603160238000044],
            [-80.913088757999958, 35.602433532000077],
            [-80.913092536999955, 35.601952733000076],
            [-80.91332652899996, 35.601513092000062],
            [-80.913382164999973, 35.600849532000041],
            [-80.91343216599995, 35.600174908000042],
            [-80.913472580999951, 35.599488468000061],
            [-80.913494602999947, 35.599013422000041],
            [-80.913519379999968, 35.598317927000039],
            [-80.91353673399999, 35.597635756000045],
            [-80.913544251999951, 35.596842202000062],
            [-80.913541531999954, 35.596232280000038],
            [-80.913535852999985, 35.595795790000068],
            [-80.913518118999946, 35.595086061000075],
            [-80.913492347999977, 35.594412899000076],
            [-80.913463387999968, 35.593834192000031],
            [-80.913443091999966, 35.593483325000079],
            [-80.913416502999951, 35.593080031000056],
            [-80.913375894999945, 35.592538554000043],
            [-80.913328305999983, 35.591984402000037],
            [-80.913287819999937, 35.591559372000063],
            [-80.913244523999936, 35.591141953000033],
            [-80.913190550999957, 35.590659014000039],
            [-80.913129433999984, 35.590152828000043],
            [-80.913063591999958, 35.589646662000064],
            [-80.912982126999964, 35.589066519000028],
            [-80.912903292999943, 35.588544803000048],
            [-80.912813222999944, 35.587998718000051],
            [-80.91272758599996, 35.587497875000054],
            [-80.912634139999966, 35.586979765000024],
            [-80.912531422999962, 35.586446865000028],
            [-80.912403063999989, 35.585822976000031],
            [-80.912269725999977, 35.585210204000077],
            [-80.912101606999954, 35.584476826000071],
            [-80.911941837999962, 35.583815732000062],
            [-80.911742502999971, 35.58303869100007],
            [-80.911570787999949, 35.582332350000058],
            [-80.911435142999949, 35.581816452000055],
            [-80.911294340999973, 35.581301640000049],
            [-80.911116818999972, 35.580670045000033],
            [-80.910905673999935, 35.579880790000061],
            [-80.91075305399994, 35.579309200000068],
            [-80.910608836999984, 35.578766097000027],
            [-80.910504514999957, 35.57837145700006],
            [-80.910364724999965, 35.57783966900007],
            [-80.910225982999975, 35.57732784500007],
            [-80.910122167999987, 35.576945414000079],
            [-80.909974094999939, 35.57638849600005],
            [-80.910022501999947, 35.575858326000059],
            [-80.910218067999949, 35.575150693000069],
            [-80.910353745999942, 35.574650333000079],
            [-80.910479201999976, 35.57417913300003],
            [-80.910587916999987, 35.573758076000047],
            [-80.910705168999982, 35.573281850000058],
            [-80.910822907999943, 35.57277911400007],
            [-80.91095330099995, 35.572193024000057],
            [-80.911082120999936, 35.571576579000066],
            [-80.911198113999944, 35.570983827000077],
            [-80.911302506999959, 35.570413849000033],
            [-80.911398499999962, 35.569859435000069],
            [-80.911484990999952, 35.569325513000024],
            [-80.91157041699995, 35.568758135000053],
            [-80.911652076999985, 35.568169614000055],
            [-80.911732195999946, 35.567533016000027],
            [-80.911796817999971, 35.566958793000026],
            [-80.911855620999972, 35.566370496000047],
            [-80.911909049999963, 35.565756787000055],
            [-80.911955584999987, 35.565126598000063],
            [-80.91199292999994, 35.564510344000041],
            [-80.912025255999936, 35.563817005000033],
            [-80.912043308999955, 35.563294129000042],
            [-80.912056829999983, 35.562719662000063],
            [-80.912063917999944, 35.562135970000043],
            [-80.912064732999966, 35.561628213000063],
            [-80.91205950899996, 35.56107125300008],
            [-80.912036546999957, 35.560163283000065],
            [-80.911992805999944, 35.55948741800006],
            [-80.911950659999945, 35.558593423000048],
            [-80.911875239999972, 35.557570054000053],
            [-80.911841799999934, 35.557174789000044],
            [-80.911782140999946, 35.556572589000041],
            [-80.911693191999973, 35.555864314000075],
            [-80.911586247999935, 35.555083861000071],
            [-80.911472782999965, 35.554193982000072],
            [-80.911423947999936, 35.553857781000033],
            [-80.911492686999964, 35.55355038600004],
            [-80.91158074699996, 35.553148171000032],
            [-80.911718231999942, 35.552514219000045],
            [-80.911821885999984, 35.552037860000041],
            [-80.911916291999944, 35.551613724000049],
            [-80.912029114999939, 35.551099542000031],
            [-80.912162587999944, 35.550500681000074],
            [-80.91227384299998, 35.549989949000064],
            [-80.912365098999942, 35.549579651000045],
            [-80.91246492099998, 35.549124901000027],
            [-80.912720150999974, 35.547972163000054],
            [-80.91284776599997, 35.547373709000055],
            [-80.912945756999989, 35.546905020000054],
            [-80.913032688999976, 35.546485612000026],
            [-80.913156225999955, 35.545923390000041],
            [-80.913289716999941, 35.545287034000069],
            [-80.913402367999936, 35.544774177000079],
            [-80.91350222799997, 35.544363084000054],
            [-80.913657587999978, 35.54369212000006],
            [-80.913814041999956, 35.542976254000052],
            [-80.91397439299999, 35.542225083000062],
            [-80.914084399999979, 35.541723307000041],
            [-80.914307472999951, 35.540639790000057],
            [-80.914391031999969, 35.540260934000059],
            [-80.91448010199997, 35.539849491000041],
            [-80.91461095599999, 35.539259714000025],
            [-80.914701894999951, 35.53884307800007],
            [-80.914851344999988, 35.538169656000036],
            [-80.914941158999966, 35.537751677000074],
            [-80.915056102999984, 35.537227821000045],
            [-80.915185751999957, 35.536639831000059],
            [-80.915353866999965, 35.535879236000028],
            [-80.915501863999964, 35.535227164000048],
            [-80.915684060999979, 35.534465495000063],
            [-80.915762799999982, 35.534118530000057],
            [-80.915938532999974, 35.53332792100008],
            [-80.916088083999966, 35.53262972400006],
            [-80.91625552499994, 35.531871927000054],
            [-80.91639841999995, 35.531197478000024],
            [-80.916495096999938, 35.530718709000041],
            [-80.916578397999956, 35.530308641000033],
            [-80.916766546999952, 35.529460787000062],
            [-80.916855234999957, 35.529053943000065],
            [-80.916965042999948, 35.528554561000078],
            [-80.917052402999957, 35.528154744000062],
            [-80.917164269999944, 35.527654976000065],
            [-80.917345166999951, 35.52684152900008],
            [-80.917438252999943, 35.526418371000034],
            [-80.917566845999943, 35.525821915000051],
            [-80.917706306999946, 35.525145259000055],
            [-80.917808854999976, 35.524620595000044],
            [-80.917905419999954, 35.52410069900003],
            [-80.917853949999937, 35.523393817000056],
            [-80.917988874999935, 35.522849850000057],
            [-80.91809539999997, 35.522325651000074],
            [-80.918238857999938, 35.52176053200003],
            [-80.918333541999971, 35.521255170000074],
            [-80.918411703999936, 35.520700617000045],
            [-80.918482753999967, 35.520172369000079],
            [-80.918547066999963, 35.51968024100006],
            [-80.918616228999952, 35.519017046000045],
            [-80.918660043999978, 35.518506898000055],
            [-80.918712299999981, 35.517843222000067],
            [-80.918754029999945, 35.516994747000069],
            [-80.918707373999951, 35.516300784000066],
            [-80.91888634999998, 35.515580572000033],
            [-80.918939750999982, 35.515002449000065],
            [-80.918965316999959, 35.514688312000033],
            [-80.91902421399999, 35.513973732000068],
            [-80.919101722999983, 35.513140583000052],
            [-80.91917254699996, 35.512494693000065],
            [-80.91958399799995, 35.51166316900003],
            [-80.919919931999971, 35.510983599000042],
            [-80.920166799999947, 35.510482617000036],
            [-80.92052688199999, 35.509772243000043],
            [-80.920766717999982, 35.509288812000079],
            [-80.92116189799998, 35.508474532000037],
            [-80.921483941999952, 35.507826934000036],
            [-80.921771027999966, 35.507255646000033],
            [-80.922115022999947, 35.506563418000042],
            [-80.92247262099994, 35.505845062000049],
            [-80.922819660999949, 35.505133208000075],
            [-80.923107594999976, 35.504543682000076],
            [-80.923303112999974, 35.504147064000051],
            [-80.923458098999959, 35.503827706000038],
            [-80.923696912999958, 35.503345128000035],
            [-80.923915744999988, 35.502886800000056],
            [-80.924267085999986, 35.502122199000041],
            [-80.924500733999935, 35.50159220900008],
            [-80.924794717999987, 35.500899372000049],
            [-80.924997910999934, 35.50041058100004],
            [-80.925240853999981, 35.499793405000048],
            [-80.925571108999975, 35.498909444000049],
            [-80.92580596199997, 35.498246744000028],
            [-80.925977263999982, 35.497740030000045],
            [-80.926098808999939, 35.497366299000078],
            [-80.926222111999948, 35.496974202000047],
            [-80.926359035999951, 35.496524008000051],
            [-80.926472806999982, 35.49613643400005],
            [-80.926691276999975, 35.495351303000064],
            [-80.926870593999979, 35.494665932000032],
            [-80.926998975999936, 35.494141209000077],
            [-80.927154151999957, 35.49346935300008],
            [-80.927258497999958, 35.492982728000072],
            [-80.927417795999986, 35.492186852000032],
            [-80.92752533099997, 35.491602391000072],
            [-80.92763946499997, 35.490913268000043],
            [-80.927695770999946, 35.490546290000054],
            [-80.927778172999979, 35.489966032000041],
            [-80.927832055999943, 35.489552770000046],
            [-80.927887474999977, 35.489090938000061],
            [-80.927943543999959, 35.488555801000075],
            [-80.928006707999941, 35.487837461000026],
            [-80.928055899999947, 35.48722391900003],
            [-80.928093360999981, 35.486645059000068],
            [-80.92813321999995, 35.485763013000053],
            [-80.928158042999939, 35.484741052000061],
            [-80.928164627999934, 35.484039001000042],
            [-80.928168738999943, 35.483484347000058],
            [-80.928175150999948, 35.482159546000048],
            [-80.928174155999955, 35.481566624000038],
            [-80.92817602599996, 35.481172308000055],
            [-80.928188333999969, 35.480543906000037],
            [-80.928197897999951, 35.479528005000077],
            [-80.92819588499998, 35.479138920000025],
            [-80.928186721999964, 35.47839450400005],
            [-80.928191121999987, 35.477245348000054],
            [-80.928204366999978, 35.476736613000071],
            [-80.928227220999986, 35.476238670000043],
            [-80.928248525999948, 35.475767488000031],
            [-80.928276852999943, 35.475364219000028],
            [-80.928303694999954, 35.475022840000065],
            [-80.928347145999965, 35.474554727000054],
            [-80.928391371999965, 35.47415473500007],
            [-80.928426925999986, 35.473809600000038],
            [-80.928489344999946, 35.473270438000043],
            [-80.928548670999987, 35.472720432000074],
            [-80.928637039999956, 35.471916738000061],
            [-80.928730146999953, 35.471137798000029],
            [-80.92879781399995, 35.470535163000079],
            [-80.928846169999986, 35.470083526000053],
            [-80.928886019999936, 35.469694471000025],
            [-80.928946505999988, 35.469036263000078],
            [-80.928990012999975, 35.468515143000047],
            [-80.929049747999954, 35.467659670000046],
            [-80.929076911999971, 35.467167434000032],
            [-80.929102970999963, 35.466583423000031],
            [-80.929122086999939, 35.466000496000049],
            [-80.92913180499994, 35.465548937000051],
            [-80.929138679999937, 35.464949980000029],
            [-80.929131378999955, 35.463927925000064],
            [-80.929118875999961, 35.463380975000064],
            [-80.929101913999943, 35.462842078000051],
            [-80.929088202999935, 35.462467941000057],
            [-80.929059450999944, 35.461910077000027],
            [-80.928984451999952, 35.460795078000046],
            [-80.928919820999965, 35.46005219400007],
            [-80.928872579999961, 35.459583862000045],
            [-80.92882689299995, 35.459171242000025],
            [-80.928729285999964, 35.458383162000075],
            [-80.928659220999975, 35.457875175000027],
            [-80.928559775999986, 35.45721876500005],
            [-80.928444966999962, 35.456520122000029],
            [-80.928344608999964, 35.455952945000035],
            [-80.928258891999974, 35.455488638000077],
            [-80.928161225999986, 35.454986346000055],
            [-80.928058052999972, 35.454486389000067],
            [-80.92783045699997, 35.453474198000038],
            [-80.927687148999951, 35.452883022000037],
            [-80.927516241999967, 35.452220340000054],
            [-80.927335300999971, 35.451553999000055],
            [-80.927224330999934, 35.451165044000049],
            [-80.92708257999999, 35.450686010000027],
            [-80.926987577999967, 35.450372119000065],
            [-80.92676877599996, 35.449663991000079],
            [-80.926626793999958, 35.449229225000067],
            [-80.926457150999966, 35.448729159000038],
            [-80.926220335999972, 35.448059045000036],
            [-80.926090388999967, 35.447702091000053],
            [-80.925965749999989, 35.44736673500006],
            [-80.925682868999957, 35.446630693000031],
            [-80.925421805999974, 35.44597968000005],
            [-80.925083683999958, 35.445166845000074],
            [-80.924860448999937, 35.444652251000036],
            [-80.924456602999953, 35.443761373000029],
            [-80.924218007999968, 35.443254248000073],
            [-80.92403471199998, 35.44286909300007],
            [-80.923791679999965, 35.442375195000068],
            [-80.92325157199997, 35.44132116600008],
            [-80.922787346999939, 35.440536230000077],
            [-80.922652788999983, 35.439986332000046],
            [-80.922697750999987, 35.439283452000041],
            [-80.922706252999944, 35.438763068000071],
            [-80.922710971999948, 35.438292506000039],
            [-80.922708700999976, 35.437553816000047],
            [-80.922701304999975, 35.437093168000047],
            [-80.922686053999939, 35.436557997000079],
            [-80.922634451999954, 35.435223471000029],
            [-80.922602025999936, 35.434638512000049],
            [-80.922517948999939, 35.433469519000028],
            [-80.922454534999986, 35.432776650000051],
            [-80.922414514999957, 35.432414874000074],
            [-80.922340952999946, 35.431762028000037],
            [-80.922227500999952, 35.430888537000044],
            [-80.922157518999938, 35.430400244000054],
            [-80.922106480999958, 35.430061885000043],
            [-80.922014415999968, 35.42948549700003],
            [-80.921921704999988, 35.428952673000026],
            [-80.921846623999954, 35.428546951000044],
            [-80.921720353999945, 35.427906569000072],
            [-80.92147844699997, 35.426788326000064],
            [-80.921366360999968, 35.426311725000062],
            [-80.921262083999977, 35.425860965000027],
            [-80.921133140999984, 35.425327400000072],
            [-80.921000445999937, 35.424808579000057],
            [-80.92087556499996, 35.424338102000036],
            [-80.920703814999968, 35.423709888000076],
            [-80.92043840599996, 35.422795992000033],
            [-80.920276987999955, 35.422269162000077],
            [-80.920120013999963, 35.421771349000039],
            [-80.919977399999937, 35.421337354000059],
            [-80.919780905999971, 35.420751960000075],
            [-80.919519080999976, 35.42000325600003],
            [-80.919262661999937, 35.419304724000028],
            [-80.91902386199996, 35.418676474000051],
            [-80.91889791899996, 35.418353975000059],
            [-80.918776755999943, 35.418051157000036],
            [-80.918541777999963, 35.417477396000038],
            [-80.918275421999965, 35.416844133000041],
            [-80.917952634999949, 35.416105326000036],
            [-80.917694684999958, 35.415536492000058],
            [-80.917427725999971, 35.414965094000024],
            [-80.916997553999977, 35.414072560000079],
            [-80.916537330999972, 35.413156584000035],
            [-80.916225870999938, 35.412559754000029],
            [-80.91595231499997, 35.412048878000064],
            [-80.915628528999946, 35.411457885000061],
            [-80.915297844999941, 35.41085871000007],
            [-80.915012486999956, 35.41035796400007],
            [-80.914676736999979, 35.409784261000027],
            [-80.914213612999959, 35.409006765000072],
            [-80.913980037999977, 35.408603945000038],
            [-80.913699848999954, 35.408119691000024],
            [-80.913225575999945, 35.407321180000054],
            [-80.912936282999965, 35.406828535000045],
            [-80.912631572999942, 35.406311986000048],
            [-80.912366127999974, 35.405860534000055],
            [-80.912022086999968, 35.40527245100003],
            [-80.911778903999959, 35.40486028600003],
            [-80.911433628999987, 35.404271072000029],
            [-80.91124948199996, 35.403958984000042],
            [-80.91109998099995, 35.403707854000061],
            [-80.910777229999951, 35.403159948000052],
            [-80.910394047999944, 35.401249406000034],
            [-80.91031649599995, 35.400784219000059],
            [-80.910240091999981, 35.400306947000047],
            [-80.910170172999983, 35.399838715000044],
            [-80.910006283999962, 35.398785666000038],
            [-80.909901666999986, 35.398161827000024],
            [-80.909708862999935, 35.397342060000028],
            [-80.90954394399995, 35.396696813000062],
            [-80.90949283499998, 35.396099876000051],
            [-80.909397521999949, 35.395672753000042],
            [-80.909305529999983, 35.395093294000048],
            [-80.909189453999943, 35.394413533000034],
            [-80.909106729999962, 35.393924919000028],
            [-80.90902766499994, 35.393504202000031],
            [-80.908873931999949, 35.392906406000066],
            [-80.908702415999983, 35.392325565000078],
            [-80.908610978999945, 35.391960712000071],
            [-80.908463762999986, 35.391421915000024],
            [-80.908311449999985, 35.390884069000037],
            [-80.90815405099994, 35.390347207000048],
            [-80.907991573999936, 35.389811360000067],
            [-80.907837530999984, 35.38902592900007],
            [-80.907691283999952, 35.388568095000039],
            [-80.907489900999963, 35.387956563000046],
            [-80.907244262999939, 35.387241251000034],
            [-80.907069317999969, 35.386752386000069],
            [-80.906890363999935, 35.386265348000052],
            [-80.906667753999955, 35.385678167000037],
            [-80.906485473999965, 35.385208434000049],
            [-80.906353626999987, 35.384878663000052],
            [-80.90614267899997, 35.384367578000024],
            [-80.905780099999959, 35.383524194000074],
            [-80.904968365999935, 35.381718539000076],
            [-80.90472376699995, 35.381190089000029],
            [-80.904334564999942, 35.380398897000077],
            [-80.903734783999937, 35.379245376000029],
            [-80.90329918499998, 35.378450890000067],
            [-80.902756057999966, 35.377467656000078],
            [-80.902685004999967, 35.376981409000052],
            [-80.90259879499996, 35.376428698000041],
            [-80.902524301999961, 35.37597555900004],
            [-80.902438850999943, 35.375489875000028],
            [-80.902292794999937, 35.374732287000029],
            [-80.902130604999968, 35.37395289300008],
            [-80.902008739999985, 35.373396218000039],
            [-80.901853342999971, 35.372723611000026],
            [-80.901715684999942, 35.372163793000027],
            [-80.901547715999982, 35.371515593000026],
            [-80.901298098999973, 35.370619826000052],
            [-80.901092156999937, 35.36992536300005],
            [-80.900877354999977, 35.369236071000046],
            [-80.900700979999954, 35.368692409000062],
            [-80.900547446999951, 35.368235828000024],
            [-80.900400222999963, 35.367809431000069],
            [-80.900285442999973, 35.36748570900005],
            [-80.900112006999962, 35.36700815100005],
            [-80.89992958199997, 35.366519685000071],
            [-80.899731396999982, 35.366004288000056],
            [-80.899543929999936, 35.365536904000066],
            [-80.899277371999972, 35.364899189000027],
            [-80.899110664999967, 35.364506875000075],
            [-80.898890225999935, 35.363999792000072],
            [-80.89869516899995, 35.363563692000071],
            [-80.898234767999952, 35.362992685000052],
            [-80.898192194999979, 35.362363110000047],
            [-80.898462203999941, 35.362067109000066],
            [-80.898579100999939, 35.361704946000032],
            [-80.898739722999949, 35.361187991000065],
            [-80.898927119999939, 35.360587041000031],
            [-80.89925348099996, 35.359474799000054],
            [-80.899387907999937, 35.359016860000054],
            [-80.899534643999971, 35.358513559000073],
            [-80.899789616999954, 35.357587846000058],
            [-80.899927395999953, 35.35705194500008],
            [-80.900045207999938, 35.356558883000048],
            [-80.90021121999996, 35.355842241000062],
            [-80.900395208999953, 35.355007914000055],
            [-80.900606937999953, 35.354010359000029],
            [-80.900724011999955, 35.35342838300005],
            [-80.900874481999949, 35.352642985000045],
            [-80.900996321999969, 35.352004168000065],
            [-80.901095832999943, 35.351445031000026],
            [-80.901200356999937, 35.350799547000065],
            [-80.90134037699994, 35.349857008000072],
            [-80.901444482999977, 35.349078427000052],
            [-80.901723493999953, 35.346734679000065],
            [-80.901785964999988, 35.346137673000044],
            [-80.901855139999952, 35.345344451000074],
            [-80.901919480999936, 35.344426010000063],
            [-80.901979643999937, 35.343348509000066],
            [-80.902011139999956, 35.342787722000025],
            [-80.902039646999981, 35.342148039000051],
            [-80.902057465999974, 35.341526114000033],
            [-80.902064235999944, 35.341142432000026],
            [-80.902075886999967, 35.340611956000032],
            [-80.902082400999973, 35.339642430000026],
            [-80.902075932999935, 35.33903302300007],
            [-80.902065256999947, 35.33854982400004],
            [-80.902055798999982, 35.338246879000053],
            [-80.902033546999974, 35.337539029000027],
            [-80.90200436799995, 35.336853069000028],
            [-80.901734328999964, 35.331919041000049],
            [-80.901704410999969, 35.331374093000079],
            [-80.90167751599995, 35.330836502000068],
            [-80.901614324999969, 35.329740069000025],
            [-80.901576859999977, 35.329099605000067],
            [-80.901538755999979, 35.32854130700008],
            [-80.901508876999969, 35.328040643000065],
            [-80.901475324999979, 35.327470324000046],
            [-80.901444187999971, 35.326935718000072],
            [-80.901408300999947, 35.326375675000065],
            [-80.901379848999966, 35.325845474000062],
            [-80.901346612999987, 35.325223665000067],
            [-80.901306150999972, 35.324581262000038],
            [-80.901278291999972, 35.324091204000069],
            [-80.901243927999985, 35.323565358000053],
            [-80.901209453999968, 35.322975995000036],
            [-80.901188954999952, 35.32263479900007],
            [-80.90117227799999, 35.322329962000026],
            [-80.901140601999941, 35.321786280000026],
            [-80.901119254999969, 35.321440536000068],
            [-80.901065666999955, 35.32051595400003],
            [-80.901030274999982, 35.319976202000078],
            [-80.900989297999956, 35.319310485000074],
            [-80.900941461999935, 35.318478303000063],
            [-80.90087884999997, 35.31740787800004],
            [-80.900847797999972, 35.316815459000054],
            [-80.900801139999942, 35.316058471000076],
            [-80.900777919999939, 35.315692717000047],
            [-80.900756339999987, 35.314919777000057],
            [-80.900923159999934, 35.314151533000029],
            [-80.901305534999949, 35.312462356000026],
            [-80.901498910999976, 35.311671712000077],
            [-80.90167244099996, 35.310958291000077],
            [-80.901808985999935, 35.310370801000033],
            [-80.902056245999972, 35.309253781000052],
            [-80.902253460999987, 35.308362589000069],
            [-80.902353345999984, 35.30793101200004],
            [-80.902473971999939, 35.307414059000052],
            [-80.902562805999935, 35.307033950000061],
            [-80.902753314999984, 35.306201259000034],
            [-80.902899862999959, 35.305575909000027],
            [-80.90301846899996, 35.305061092000074],
            [-80.903220580999971, 35.304164462000074],
            [-80.903358398999956, 35.303550542000039],
            [-80.903498527999943, 35.302898105000054],
            [-80.903634847999967, 35.302230633000079],
            [-80.903763506999951, 35.301564451000047],
            [-80.903839504999951, 35.301138915000024],
            [-80.903914395999948, 35.300689729000055],
            [-80.904023165999945, 35.299982080000063],
            [-80.904069309999954, 35.299661504000028],
            [-80.904194608999944, 35.298722209000061],
            [-80.904282338999963, 35.297957416000031],
            [-80.904338686999949, 35.297388127000033],
            [-80.904385974999968, 35.296852086000058],
            [-80.904454400999953, 35.295890186000065],
            [-80.904491315999962, 35.29520605600004],
            [-80.90450698799998, 35.294838403000028],
            [-80.904521074999934, 35.294458443000053],
            [-80.904534324999986, 35.293988995000063],
            [-80.904540481999959, 35.293695892000073],
            [-80.904548290999969, 35.293210482000063],
            [-80.904550852999989, 35.292445010000051],
            [-80.904545994999978, 35.291980354000032],
            [-80.904539711999973, 35.291634916000078],
            [-80.904530749999935, 35.291280552000046],
            [-80.904510252999955, 35.290772512000046],
            [-80.904466486999979, 35.28998488700006],
            [-80.904424027999937, 35.289324729000043],
            [-80.904375946999949, 35.288700838000068],
            [-80.904314512999974, 35.28801620400003],
            [-80.90426957699998, 35.287585935000038],
            [-80.904215527999952, 35.287112813000078],
            [-80.904153820999966, 35.286599184000067],
            [-80.904088037999941, 35.286110588000042],
            [-80.90394306099995, 35.285134980000066],
            [-80.903861515999949, 35.284634636000078],
            [-80.903785518999939, 35.284208362000072],
            [-80.903651565999951, 35.283489597000028],
            [-80.903439929999934, 35.282453849000035],
            [-80.903289878999942, 35.281784386000027],
            [-80.903138693999949, 35.28114127200007],
            [-80.903047042999958, 35.280764809000061],
            [-80.902948850999962, 35.280373122000071],
            [-80.902790974999959, 35.27978590400005],
            [-80.902455485999951, 35.27861524900004],
            [-80.902252256999986, 35.277952736000032],
            [-80.902097602999959, 35.277479260000064],
            [-80.901887041999942, 35.276852224000038],
            [-80.90149017899995, 35.275738280000041],
            [-80.901219031999972, 35.27501979200008],
            [-80.901038353999979, 35.274556426000061],
            [-80.900760891999937, 35.27386716500007],
            [-80.900444634999985, 35.273109079000051],
            [-80.900025394999943, 35.272153591000063],
            [-80.899802972999964, 35.271603990000074],
            [-80.899773299999936, 35.271036621000064],
            [-80.899757453999939, 35.270499698000037],
            [-80.899717801999941, 35.26965529000006],
            [-80.899688988999969, 35.269203179000044],
            [-80.899647211999934, 35.26865099500003],
            [-80.899611514999947, 35.26823758300003],
            [-80.899558881999951, 35.267709767000042],
            [-80.89946704099998, 35.266921947000071],
            [-80.899400499999956, 35.266425983000033],
            [-80.899304239999935, 35.265773638000042],
            [-80.899220938999974, 35.265256050000062],
            [-80.899127746999966, 35.264727681000068],
            [-80.899034234999988, 35.264235493000058],
            [-80.898942906999935, 35.263782670000069],
            [-80.898808220999967, 35.26315810400007],
            [-80.898589293999976, 35.262753136000072],
            [-80.898673869999982, 35.26226910400004],
            [-80.899083298999983, 35.261971686000038],
            [-80.89975939499999, 35.261291596000035],
            [-80.900287729999945, 35.260742328000049],
            [-80.900769376999961, 35.260223395000025],
            [-80.901364341999965, 35.259566527000061],
            [-80.902017010999941, 35.258824111000024],
            [-80.902511278999953, 35.258239952000054],
            [-80.903094007999982, 35.257524199000045],
            [-80.903541018999988, 35.256958696000027],
            [-80.904001638999944, 35.256359887000031],
            [-80.904540923999946, 35.255624885000032],
            [-80.905331850999971, 35.254491296000026],
            [-80.905833390999987, 35.253738209000062],
            [-80.906219692999969, 35.253126491000046],
            [-80.906491107999955, 35.252684926000029],
            [-80.906784409999943, 35.25219575400007],
            [-80.906996412999945, 35.251875025000061],
            [-80.907510284999944, 35.251382489000036],
            [-80.908206189999987, 35.250723140000048],
            [-80.908725431999983, 35.250229585000056],
            [-80.909368374999985, 35.249624016000041],
            [-80.909894245999965, 35.249129725000046],
            [-80.910390312999937, 35.248672474000045],
            [-80.910953080999946, 35.248143864000042],
            [-80.911275134999983, 35.247834071000057],
            [-80.911777719999975, 35.247340763000068],
            [-80.912129280999977, 35.246988393000038],
            [-80.912603701999956, 35.246502639000028],
            [-80.913225074999957, 35.245837889000029],
            [-80.914131607999934, 35.244828375000054],
            [-80.914960635999989, 35.243850136000049],
            [-80.915714962999971, 35.242905861000054],
            [-80.916219875999957, 35.24225018900006],
            [-80.916475336999952, 35.241907444000049],
            [-80.916733554999951, 35.241554111000028],
            [-80.91699684799994, 35.241187370000034],
            [-80.917350381999938, 35.24068425400003],
            [-80.91761980299998, 35.240291978000073],
            [-80.91801623799995, 35.239700476000053],
            [-80.918209262999937, 35.23940600800006],
            [-80.918452701999968, 35.239026380000041],
            [-80.918877907999956, 35.238346112000045],
            [-80.919211756999971, 35.237794935000068],
            [-80.919615338999961, 35.237106935000043],
            [-80.920039972999973, 35.236342948000072],
            [-80.920557333999966, 35.235362413000075],
            [-80.920874946999959, 35.234734777000028],
            [-80.921075911999935, 35.23432013200005],
            [-80.921384941999975, 35.233659550000027],
            [-80.921556451999948, 35.233280576000027],
            [-80.921805621999965, 35.232712374000073],
            [-80.921984100999964, 35.232293051000056],
            [-80.922226204999959, 35.231703492000065],
            [-80.922419567999953, 35.231213280000077],
            [-80.922563871999955, 35.230835465000041],
            [-80.922735585999988, 35.230372948000024],
            [-80.922876336999934, 35.22998246800006],
            [-80.922982220999984, 35.229680052000049],
            [-80.923171645999957, 35.229120812000076],
            [-80.923414915999956, 35.22860614800004],
            [-80.92387716799999, 35.228034688000037],
            [-80.924176025999941, 35.22765617500005],
            [-80.924705948999986, 35.22696763600004],
            [-80.924982387999989, 35.226599100000044],
            [-80.92541816399995, 35.226003456000058],
            [-80.925697930999945, 35.225612675000036],
            [-80.926098215999957, 35.225043385000049],
            [-80.926361449999945, 35.22465980000004],
            [-80.926731074999964, 35.224107966000076],
            [-80.926966205999975, 35.223747959000036],
            [-80.927233218999959, 35.223329312000033],
            [-80.92755492799995, 35.222813125000073],
            [-80.927818180999964, 35.222379915000033],
            [-80.928234290999967, 35.22167289500004],
            [-80.928467055999988, 35.221267655000077],
            [-80.929079483999942, 35.22017461300004],
            [-80.929680395999981, 35.219021762000068],
            [-80.930070267999952, 35.21823239500003],
            [-80.930399632999979, 35.217532443000039],
            [-80.93073697899996, 35.216850574000034],
            [-80.930911039999955, 35.21649611600003],
            [-80.931186842999978, 35.215908940000077],
            [-80.931658212999935, 35.214857817000052],
            [-80.932283086999973, 35.213498309000045],
            [-80.932524742999988, 35.21297467900007],
            [-80.932963044999951, 35.212049862000072],
            [-80.933312254999976, 35.211338786000056],
            [-80.933691711999984, 35.210522018000063],
            [-80.934036540999955, 35.20977300900006],
            [-80.934479170999964, 35.208839011000066],
            [-80.93475373199999, 35.20824478000003],
            [-80.93499873899998, 35.207735099000047],
            [-80.935357834999934, 35.206971526000075],
            [-80.935615484999971, 35.206392701000027],
            [-80.935953088999952, 35.205698464000079],
            [-80.936195593999969, 35.205186871000024],
            [-80.936518794999984, 35.204488949000051],
            [-80.936963737999974, 35.203530994000062],
            [-80.937246719999962, 35.20290723800008],
            [-80.937395851999952, 35.202571849000037],
            [-80.93758448899996, 35.20213717200005],
            [-80.937806549999948, 35.201610249000055],
            [-80.93797836799996, 35.201190570000051],
            [-80.938108494999938, 35.200862976000053],
            [-80.938341183999967, 35.200257733000058],
            [-80.938493654999945, 35.199846869000055],
            [-80.938656858999934, 35.199392928000066],
            [-80.938786070999981, 35.199022442000057],
            [-80.93900021099995, 35.198381547000054],
            [-80.93920525499999, 35.197731035000061],
            [-80.939445206999949, 35.196925023000063],
            [-80.939570525999954, 35.196480025000028],
            [-80.939763836999987, 35.195756847000041],
            [-80.939868176999937, 35.195345131000067],
            [-80.940028071999961, 35.19467809300005],
            [-80.940133237999987, 35.194215191000069],
            [-80.940304284999968, 35.193423186000075],
            [-80.940396694999947, 35.192963905000056],
            [-80.940518663999967, 35.192311868000047],
            [-80.940609431999974, 35.191782293000074],
            [-80.940710154999977, 35.191122918000076],
            [-80.940845096999965, 35.190121678000025],
            [-80.940937703999964, 35.189288126000065],
            [-80.941008890999967, 35.188456805000044],
            [-80.941054654999959, 35.187803121000059],
            [-80.941091342999982, 35.187185611000075],
            [-80.941118408999955, 35.186628250000069],
            [-80.941133957999966, 35.186232802000063],
            [-80.941146883999977, 35.185576061000063],
            [-80.941148533999979, 35.184295831000043],
            [-80.941120916999978, 35.183284894000053],
            [-80.941066235999983, 35.182338714000025],
            [-80.941027670999972, 35.18181366400006],
            [-80.94099436099998, 35.181411321000041],
            [-80.940947230999939, 35.180899959000044],
            [-80.940860638999936, 35.18014502300008],
            [-80.940710946999957, 35.179045562000056],
            [-80.940604103999988, 35.178356793000034],
            [-80.940529995999952, 35.177929186000028],
            [-80.940434265999954, 35.177362729000038],
            [-80.940526529999943, 35.176906713000051],
            [-80.940760959999977, 35.176078627000038],
            [-80.940964062999967, 35.17534147300006],
            [-80.941227293999987, 35.174556270000039],
            [-80.941567016999954, 35.173581872000057],
            [-80.94182456499999, 35.172818494000069],
            [-80.941997396999966, 35.17228202900003],
            [-80.94214328999999, 35.171865666000031],
            [-80.942303083999946, 35.171470172000056],
            [-80.943071539999949, 35.170719883000061],
            [-80.943478711999944, 35.170348922000073],
            [-80.943978138999967, 35.169895394000037],
            [-80.944335278999972, 35.169564620000074],
            [-80.945070859999987, 35.168882318000044],
            [-80.945751565999956, 35.168264713000042],
            [-80.946495311999968, 35.167585053000039],
            [-80.946872593999956, 35.167248432000065],
            [-80.94734337999995, 35.166820186000052],
            [-80.947913090999975, 35.166284599000051],
            [-80.948348350999936, 35.165861645000064],
            [-80.949092089999965, 35.165164685000036],
            [-80.950515687999939, 35.163827866000076],
            [-80.95142089999996, 35.162946840000075],
            [-80.951776003999953, 35.162592151000069],
            [-80.952213993999976, 35.162144699000066],
            [-80.952612989999977, 35.161730449000061],
            [-80.953112228999942, 35.161198357000046],
            [-80.953830115999949, 35.160410888000058],
            [-80.954477834999977, 35.15967762300005],
            [-80.955140233999941, 35.158904114000052],
            [-80.955644422999967, 35.158298916000035],
            [-80.956150823999963, 35.157674709000048],
            [-80.956830169999989, 35.156809707000036],
            [-80.957187961999978, 35.156339910000042],
            [-80.957383432999961, 35.156076691000067],
            [-80.957610791999969, 35.155765676000044],
            [-80.957900416999962, 35.155363974000068],
            [-80.95859851299997, 35.15438316500007],
            [-80.959079333999966, 35.153688678000037],
            [-80.959819746999983, 35.152590278000048],
            [-80.960423183999978, 35.151745787000038],
            [-80.961630063999962, 35.150065157000029],
            [-80.961988304999977, 35.149564763000058],
            [-80.962701288999938, 35.148533274000044],
            [-80.963197851999951, 35.147814717000074],
            [-80.963596913999936, 35.147225997000078],
            [-80.963890125999967, 35.14680112700006],
            [-80.964278512999954, 35.146224691000043],
            [-80.964544631999956, 35.145819933000041],
            [-80.964916209999956, 35.145247544000028],
            [-80.965753979999988, 35.143918524000071],
            [-80.966155298999979, 35.143263756000067],
            [-80.966458147999958, 35.142755995000073],
            [-80.966721417999963, 35.142306508000047],
            [-80.967020735999938, 35.141785535000054],
            [-80.967424446999985, 35.141077047000067],
            [-80.967699394999954, 35.140572442000064],
            [-80.968110802999945, 35.139820672000042],
            [-80.96842126599995, 35.13924030700008],
            [-80.968658481999967, 35.138793949000046],
            [-80.969874432999973, 35.137099207000063],
            [-80.970064071999957, 35.136834848000035],
            [-80.970572945999947, 35.136116105000042],
            [-80.970896213999936, 35.135650607000059],
            [-80.971348250999938, 35.13498738800007],
            [-80.971647920999942, 35.13453949500007],
            [-80.972994116999985, 35.132489170000042],
            [-80.97514817299998, 35.129151212000068],
            [-80.975351257999989, 35.128836469000078],
            [-80.975586116999978, 35.128467465000028],
            [-80.976858959999959, 35.126245487000062],
            [-80.977097293999975, 35.125908029000072],
            [-80.977329491999967, 35.125567696000076],
            [-80.977555505999987, 35.125224565000053],
            [-80.977775282999971, 35.124878711000065],
            [-80.97798877699995, 35.124530211000035],
            [-80.978195938999988, 35.12417914100007],
            [-80.978396723999936, 35.123825579000027],
            [-80.97870925899997, 35.123247501000037],
            [-80.979050798999936, 35.122585505000075],
            [-80.979271376999975, 35.12215210200003],
            [-80.979476409999961, 35.12166116800006],
            [-80.979732260999981, 35.121031953000056],
            [-80.979825110999968, 35.120883125000034],
            [-80.979910905999986, 35.120731466000052],
            [-80.979989518999957, 35.12057720100006],
            [-80.980060832999982, 35.120420560000071],
            [-80.980124741999987, 35.120261775000074],
            [-80.980181149999964, 35.120101084000055],
            [-80.98022997399994, 35.119938725000054],
            [-80.980271140999946, 35.11977494000007],
            [-80.980304589999946, 35.119609973000024],
            [-80.980781662999959, 35.118909956000039],
            [-80.980976586999986, 35.118457895000063],
            [-80.981268985999975, 35.117760206000071],
            [-80.981565746999934, 35.117005576000054],
            [-80.981697937999968, 35.116654082000025],
            [-80.98185728499999, 35.116217793000033],
            [-80.982075079999959, 35.115607244000046],
            [-80.982246867999947, 35.115104619000078],
            [-80.982555160999937, 35.114126695000039],
            [-80.982735994999985, 35.113527114000078],
            [-80.982969208999975, 35.112720614000068],
            [-80.983159433999958, 35.112039241000048],
            [-80.983388144999935, 35.111160300000051],
            [-80.983572939999988, 35.110433003000026],
            [-80.983670361999941, 35.110032243000035],
            [-80.983808171999954, 35.10943694100007],
            [-80.983891195999945, 35.109061113000053],
            [-80.983992791999981, 35.10857238400007],
            [-80.98411779199995, 35.107932450000078],
            [-80.984239891999948, 35.10723858700004],
            [-80.98482140599998, 35.106356467000069],
            [-80.985248077999984, 35.10578892500007],
            [-80.986333427999966, 35.104346934000034],
            [-80.98709377299997, 35.103380339000068],
            [-80.987544014999969, 35.102786843000047],
            [-80.987829561999945, 35.102396171000066],
            [-80.988124945999971, 35.10199405000003],
            [-80.988451794999946, 35.101541788000077],
            [-80.988672017999988, 35.101232960000061],
            [-80.989019815999939, 35.100759245000063],
            [-80.989620829999978, 35.099977678000073],
            [-80.989900865999971, 35.099610200000029],
            [-80.99053539099998, 35.098763826000038],
            [-80.990838246999942, 35.098352565000027],
            [-80.991155627999944, 35.097914882000055],
            [-80.991493362999961, 35.097457791000068],
            [-80.991711932999976, 35.097158797000077],
            [-80.99240492399997, 35.096223451000071],
            [-80.993248894999965, 35.095075431000055],
            [-80.993966910999973, 35.094105217000049],
            [-80.994569472999956, 35.093307648000064],
            [-80.994938574999935, 35.092811773000051],
            [-80.995384108999986, 35.092200360000049],
            [-80.995721295999942, 35.091758700000071],
            [-80.995927569999935, 35.091486304000057],
            [-80.996195532999934, 35.091142969000032],
            [-80.996616149999966, 35.090594650000071],
            [-80.997022638999965, 35.090047005000031],
            [-80.997415115999956, 35.089500261000069],
            [-80.99761097999999, 35.089222557000028],
            [-80.997883668999975, 35.08884305600003],
            [-80.998382842999945, 35.088165298000035],
            [-80.998655097999972, 35.08781282800004],
            [-80.998976277999986, 35.087390868000057],
            [-80.999178907999976, 35.087120352000056],
            [-81.000189872999954, 35.085748737000074],
            [-81.000716960999966, 35.085013415000049],
            [-81.001081479999982, 35.084490610000046],
            [-81.00167150499999, 35.083633017000068],
            [-81.002078241999982, 35.083026724000035],
            [-81.002381620999984, 35.082559759000048],
            [-81.002734227999952, 35.081999161000056],
            [-81.003341155999976, 35.081008510000061],
            [-81.003801644999953, 35.080231815000047],
            [-81.004283520999934, 35.079392374000065],
            [-81.004711713999939, 35.078609821000043],
            [-81.005310598999984, 35.077456509000058],
            [-81.005643154999973, 35.076785531000041],
            [-81.006033416999969, 35.075958563000029],
            [-81.006433625999989, 35.075076686000045],
            [-81.006800603999977, 35.074230647000036],
            [-81.007157117999952, 35.073384068000053],
            [-81.007363672999986, 35.072879210000053],
            [-81.007717887999945, 35.071986909000032],
            [-81.007999599999948, 35.071259766000026],
            [-81.008252611999978, 35.070589211000026],
            [-81.008639184999936, 35.069505505000052],
            [-81.009008840999968, 35.06835323200005],
            [-81.009357283999975, 35.067132387000072],
            [-81.009655652999982, 35.065937213000041],
            [-81.009968349999951, 35.064471658000059],
            [-81.010235521999959, 35.063184626000066],
            [-81.010388140999964, 35.062343247000058],
            [-81.010518044999969, 35.061506397000073],
            [-81.010605819999967, 35.060881957000049],
            [-81.010716622999951, 35.060200270000053],
            [-81.010845038999946, 35.059330931000034],
            [-81.011190064999937, 35.056709857000044],
            [-81.011378459999946, 35.055299146000038],
            [-81.011917813999958, 35.051227185000073],
            [-81.012408321999942, 35.047681858000033],
            [-81.012638282999944, 35.047333599000069],
            [-81.012882898999976, 35.046953317000032],
            [-81.013281338999946, 35.046316741000055],
            [-81.01354205399997, 35.045889892000048],
            [-81.014059862999943, 35.045039607000035],
            [-81.014545906999956, 35.044213692000028],
            [-81.014881194999987, 35.043622074000041],
            [-81.015077365999957, 35.043284780000079],
            [-81.015341457999966, 35.042827952000039],
            [-81.015781235999953, 35.042025763000026],
            [-81.016379712999935, 35.04087237400006],
            [-81.016840292999973, 35.039923613000042],
            [-81.017252257999985, 35.039009488000033],
            [-81.017579082999987, 35.038248297000052],
            [-81.017807189999985, 35.037698105000061],
            [-81.017976622999981, 35.037274925000077],
            [-81.018125217999966, 35.036892366000075],
            [-81.01833054399998, 35.036347953000075],
            [-81.018675755999936, 35.035399974000029],
            [-81.019023507999975, 35.034407414000043],
            [-81.019204678999984, 35.033870012000079],
            [-81.019460319999951, 35.033046278000029],
            [-81.01980846899994, 35.031825380000043],
            [-81.019988816999955, 35.031144174000076],
            [-81.020151146999979, 35.030478790000075],
            [-81.020327915999985, 35.029711033000069],
            [-81.020467674999963, 35.029064135000056],
            [-81.02073788499996, 35.027754414000071],
            [-81.020989337999936, 35.026460919000044],
            [-81.021171291999963, 35.025556847000075],
            [-81.021420629999966, 35.024371557000052],
            [-81.021670761999985, 35.023160145000077],
            [-81.021828664999987, 35.022434642000064],
            [-81.021970016999944, 35.021745808000048],
            [-81.022188187999973, 35.020652881000046],
            [-81.022281988999964, 35.020230522000077],
            [-81.02245508499999, 35.019395040000063],
            [-81.024180141999977, 35.016826473000037],
            [-81.024684744999945, 35.016078958000037],
            [-81.025515266999946, 35.014871822000032],
            [-81.025989657999958, 35.014169099000071],
            [-81.026544083999966, 35.013336005000042],
            [-81.026906513999961, 35.012805180000043],
            [-81.027495525999939, 35.011941829000079],
            [-81.027976525999975, 35.011242852000066],
            [-81.028272804999972, 35.010811113000045],
            [-81.02908692799997, 35.009636013000033],
            [-81.02949979999994, 35.009017736000033],
            [-81.029739688999939, 35.008645100000024],
            [-81.030144078999967, 35.008030864000034],
            [-81.03045653099997, 35.007565671000066],
            [-81.030834701999936, 35.007028795000053],
            [-81.031328145999964, 35.006322855000064],
            [-81.03173008999994, 35.005734489000076],
            [-81.031971574999943, 35.005376269000067],
            [-81.03269077899995, 35.004334305000043],
            [-81.033907092999982, 35.002496154000028],
            [-81.034373700999936, 35.001759985000035],
            [-81.035021582999946, 35.000689794000039],
            [-81.035336584999982, 35.00015103100003],
            [-81.035834941999951, 34.999267696000061],
            [-81.036183764999976, 34.998623721000058],
            [-81.036781659999974, 34.99747020500007],
            [-81.037324492999971, 34.996335748000035],
            [-81.037855004999983, 34.995123037000042],
            [-81.038234117999934, 34.994194010000058],
            [-81.038571761999947, 34.993312591000063],
            [-81.038745480999978, 34.992838948000042],
            [-81.039079776999984, 34.991888544000062],
            [-81.039424893999978, 34.990803357000061],
            [-81.039772583999934, 34.989582374000065],
            [-81.03998390199996, 34.988770900000077],
            [-81.040100934999941, 34.988273080000056],
            [-81.040287832999979, 34.987434217000043],
            [-81.040501111999959, 34.986379535000026],
            [-81.040625899999952, 34.985686484000041],
            [-81.040734522999969, 34.985001329000056],
            [-81.040857364999965, 34.984146238000051],
            [-81.040967748999947, 34.983274163000033],
            [-81.041058662999944, 34.982351419000054],
            [-81.04114194999994, 34.981203347000076],
            [-81.041174373999979, 34.980628852000052],
            [-81.041200972999945, 34.979965992000075],
            [-81.041216784999961, 34.979200415000037],
            [-81.041216177999956, 34.977946345000078],
            [-81.041198872999985, 34.977167820000034],
            [-81.041181895999955, 34.976789146000044],
            [-81.041129318999936, 34.975780775000032],
            [-81.041098090999981, 34.975305932000026],
            [-81.041023033999977, 34.974356919000058],
            [-81.040902180999979, 34.973258858000065],
            [-81.040726327999948, 34.972013167000057],
            [-81.040593678999983, 34.971203820000028],
            [-81.04045076999995, 34.970429363000051],
            [-81.040320115999975, 34.969766943000025],
            [-81.040078973999982, 34.968654488000027],
            [-81.039799328999948, 34.967535259000044],
            [-81.039475600999936, 34.96639250100003],
            [-81.039234264999948, 34.965602465000075],
            [-81.039079759999936, 34.965116967000029],
            [-81.03884015999995, 34.964409342000067],
            [-81.038406600999963, 34.963207125000054],
            [-81.03811049799998, 34.962438534000057],
            [-81.037935964999974, 34.962014174000046],
            [-81.037685368999973, 34.961415337000062],
            [-81.037337959999945, 34.960590830000058],
            [-81.036630387999935, 34.958973537000077],
            [-81.036300331999939, 34.958191365000062],
            [-81.035884750999969, 34.957235329000071],
            [-81.035618435999936, 34.95660719600005],
            [-81.034974431999956, 34.955104721000055],
            [-81.034208757999977, 34.953274614000065],
            [-81.033906993999949, 34.952573335000068],
            [-81.033672611999975, 34.952046387000053],
            [-81.033411992999959, 34.951471430000026],
            [-81.033253017999982, 34.951118658000041],
            [-81.033029477999946, 34.950634235000052],
            [-81.032812863999936, 34.95017408700005],
            [-81.032660151999949, 34.949843034000025],
            [-81.032343071999946, 34.949115465000034],
            [-81.032093803999942, 34.948556232000044],
            [-81.031888785999968, 34.948062857000025],
            [-81.031514352999977, 34.947148899000069],
            [-81.03120557799997, 34.946421735000058],
            [-81.031000344999939, 34.945953898000027],
            [-81.030837023999936, 34.94556534000003],
            [-81.030372092999983, 34.944465511000033],
            [-81.030085087999964, 34.943808021000052],
            [-81.029426993999948, 34.942348327000047],
            [-81.029230430999974, 34.941844687000071],
            [-81.029012784999964, 34.941305598000042],
            [-81.028641388999972, 34.940428048000058],
            [-81.028085759999954, 34.939169363000076],
            [-81.027728885999977, 34.93834398100006],
            [-81.027465391999954, 34.937586329000055],
            [-81.027824215999942, 34.936861879000048],
            [-81.028282037999986, 34.93597352300003],
            [-81.028513262999979, 34.935511614000063],
            [-81.028834408999955, 34.934912669000028],
            [-81.02954815999999, 34.933528958000068],
            [-81.02985618799994, 34.932952641000043],
            [-81.030317183999955, 34.932074813000042],
            [-81.030520960999979, 34.931675319000078],
            [-81.03087845999994, 34.930998665000061],
            [-81.031373871999961, 34.930022201000043],
            [-81.031820324999956, 34.929075542000078],
            [-81.032350497999971, 34.927862808000043],
            [-81.032824760999972, 34.926671080000062],
            [-81.033257114999969, 34.925468590000037],
            [-81.033647201999941, 34.924256339000067],
            [-81.033994702999962, 34.923035326000047],
            [-81.034295679999957, 34.921823724000035],
            [-81.03456082799994, 34.920571083000027],
            [-81.034778980999988, 34.919329927000035],
            [-81.034953615999939, 34.918084092000072],
            [-81.035084587999961, 34.916834625000035],
            [-81.035171786999967, 34.915582571000073],
            [-81.035215143999949, 34.914328966000028],
            [-81.035214624999981, 34.913074850000044],
            [-81.035170232999974, 34.91182126800004],
            [-81.035082005999982, 34.910569259000056],
            [-81.034950016999971, 34.909319860000039],
            [-81.034774381999966, 34.908074117000069],
            [-81.034555250999972, 34.90683307300003],
            [-81.034289136999973, 34.905580568000062],
            [-81.033987248999949, 34.904369117000044],
            [-81.03363885999994, 34.90314827900005],
            [-81.033247921999987, 34.901936222000074],
            [-81.032814760999941, 34.900733943000034],
            [-81.032339735999983, 34.899542444000076],
            [-81.031808833999946, 34.898329961000059],
            [-81.031265720999954, 34.89719573900004],
            [-81.030667623999989, 34.896042479000073],
            [-81.030029454999976, 34.894903893000048],
            [-81.029351743999939, 34.893780931000038],
            [-81.028635057999963, 34.892674523000039],
            [-81.027848392999942, 34.891540270000064],
            [-81.027087169999959, 34.89051502500007],
            [-81.026257255999951, 34.889463727000077],
            [-81.025390942999934, 34.888432570000077],
            [-81.024488941999948, 34.887422400000048],
            [-81.023552002999963, 34.886434055000052],
            [-81.022526752999966, 34.885414857000058],
            [-81.02157648299999, 34.884526136000034],
            [-81.020539535999944, 34.883608135000031],
            [-81.019470944999966, 34.882715138000037],
            [-81.018371584999954, 34.881847871000048],
            [-81.017242367999984, 34.88100705800008],
            [-81.016003532999946, 34.880137155000057],
            [-81.014898175999974, 34.879407580000077],
            [-81.013685134999946, 34.878650236000055],
            [-81.012446112999953, 34.877921988000026],
            [-81.011182180999981, 34.877223467000078],
            [-81.009894391999978, 34.876555253000049],
            [-81.008474220999972, 34.875865181000052],
            [-81.007251406999956, 34.875311866000061],
            [-81.005898437999974, 34.874737744000072],
            [-81.004525970999964, 34.874195976000067],
            [-81.003135147999956, 34.873687014000041],
            [-81.001727112999959, 34.873211277000053],
            [-81.000164296999969, 34.872726818000046],
            [-80.998864160999972, 34.872361048000073],
            [-80.997411583999963, 34.871987251000064],
            [-80.99594652199994, 34.871648084000071],
            [-80.994470251999985, 34.871343845000069],
            [-80.992983982999988, 34.871074779000026],
            [-80.991322572999934, 34.870816013000024],
            [-80.989643741999942, 34.870608040000036],
            [-80.988132105999966, 34.870461946000034],
            [-80.987537127999985, 34.870409738000035],
            [-80.986442269999941, 34.870399961000032],
            [-80.985434830999964, 34.87036773300008],
            [-80.984530559999939, 34.870314407000023],
            [-80.98362844899998, 34.870270251000079],
            [-80.982401204999974, 34.870218668000064],
            [-80.981855059999987, 34.870199219000028],
            [-80.981352773999959, 34.870199636000052]
          ]
        ]
      },
      "properties": {
        "OBJECTID": 154,
        "SolutionID": "PI-27:F",
        "Shape_Length": 2.3001769675427952,
        "Shape_Area": 0.11202781108794944
      }
    }
  ]
}
