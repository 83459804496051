import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PrintComponent } from './print/print.component'
import { DetailsComponent } from './recommendations/details/details.component'
import { ListComponent } from './recommendations/list/list.component'
import { RecommendationsComponent } from './recommendations/recommendations.component'
import { WelcomeComponent } from './welcome/welcome.component'
import { WorkplanComponent } from './workplan/workplan.component'

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'recommendations', component: RecommendationsComponent },
  { path: 'list', component: ListComponent },
  { path: 'print', component: PrintComponent },
  { path: 'details/:id', component: DetailsComponent },
  {
    path: 'workplan',
    component: WorkplanComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
