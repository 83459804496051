import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Observable } from 'rxjs'
import { Filter } from '../recommendations/state/filters/filter.model'
import { FiltersService } from '../recommendations/state/filters/filters.service'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit {
  @ViewChild('printArea') printArea: ElementRef
  recommendations: Observable<Filter[]>
  constructor(filterService: FiltersService) {
    this.recommendations = filterService.selectAll()
  }

  ngOnInit(): void {}

  public makePDF(): void {
    let DATA = document.getElementById('printArea')
    console.log(DATA)

    let PDF = new jsPDF('p', 'mm', 'a4')
    html2canvas(DATA, { foreignObjectRendering: false, useCORS: true }).then(
      (canvas) => {
        // canvas.height = 1600
        console.log(canvas)
        const FILEURI = canvas.toDataURL('image/png')
        // canvas.width = 800

        let fileWidth = 190
        let fileHeight = (canvas.height * fileWidth) / canvas.width

        document.body.appendChild(canvas)
        console.log(FILEURI)
        let position = 10
        PDF.addImage(FILEURI, 'PNG', 10, position, fileWidth, fileHeight)
        PDF.save('angular-demo.pdf')
      }
    )
  }
}
