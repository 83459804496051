import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDialogModule } from '@angular/material/dialog'
import { MatChipsModule } from '@angular/material/chips'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatBadgeModule } from '@angular/material/badge'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { MatRippleModule } from '@angular/material/core'
import { MatGridListModule } from '@angular/material/grid-list'

import { NgxMapboxGLModule } from 'ngx-mapbox-gl'
import { NgxPrintModule } from 'ngx-print'
import { MatSortModule } from '@angular/material/sort'

@NgModule({
  declarations: [],
  imports: [
    // MatToolbarModule,
    // MatButtonModule,
    // MatSidenavModule,
    // MatIconModule,
    // MatListModule,
    // MatCardModule,
    // MatListModule,
    // FlexLayoutModule,
    // MatDividerModule,
    // MatListModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1IjoibHVrZXVzIiwiYSI6ImNqZ3h3MXRoejBmNjUyd3BqMWs1NDl3YWQifQ.eLzXI0TBnx8l1xHo5hdm5w', // Optional, can also be set per map (accessToken input of mgl-map)
    }),
  ],
  exports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatListModule,
    FlexLayoutModule,
    MatDividerModule,
    MatListModule,
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    MatSortModule,
    // MatFormFieldModule,
    MatDialogModule,
    NgxMapboxGLModule,
    MatChipsModule,
    MatTooltipModule,
    MatRippleModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    MatGridListModule,
    NgxPrintModule,
  ],
})
export class MaterialModule {}
