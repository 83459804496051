import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Actions } from '@datorama/akita-ng-effects'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Recommendation } from '../recommendations/state/recommendation.model'
import { WorkplanActions } from './state/workplan.actions'
import { Workplan } from './state/workplan.model'
import { WorkplanQuery } from './state/workplan.query'
import *  as XLSX from'xlsx';
import { MatSort } from '@angular/material/sort'
import {MatTable, MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-workplan',
  templateUrl: './workplan.component.html',
  styleUrls: ['./workplan.component.scss'],
})
export class WorkplanComponent implements OnInit, AfterViewInit {
  workplans$: Observable<Workplan & Recommendation[]>
  notify$: Observable<boolean>
  displayedColumns = [
    'recommendationId',
    'stratSolu',
    'keyArea',
    'uct',
    'impTimeFrame',
    'description',
    'remove',
  ]
  smallCount = 0
  largeCount = 0
  mediumCount = 0
  mpoCount = 0
  fileName = 'S&S-'+ Date.now()+'.xlsx';
  data: any;

  @ViewChild(MatSort) sort: MatSort;
  constructor(private workplanQuery: WorkplanQuery, private actions: Actions) {}

  ngOnInit(): void {
    this.workplans$ = this.workplanQuery.selectedItems$
    this.notify$ = this.workplanQuery.selectNotificaton$
    this.workplans$.subscribe((s) => {
    this.data = new MatTableDataSource(s);


      // this.smallCount = s.filter((f) => f.loeSmall.length > 0).length
      // this.mediumCount = s.filter((f) => f.loeMPPOTeam.length > 0).length
      // this.largeCount = s.filter((f) => f.loeLarge.length > 0).length
      // this.mpoCount = s.filter((f) => f.mpoRole.length > 0).length
    })
  }
  ngAfterViewInit(){
    this.data.sort = this.sort;
  }


  modeCount() {}

  remove({ recommendationId }: Workplan) {
    this.actions.dispatch(WorkplanActions.removeWorkplan({ recommendationId }))
  }

  exportexcel(): void {
    /* pass here the table id */
    let element = document.getElementById('exceltable');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);
 
  }


  print() {
    window.print()
  }
}
