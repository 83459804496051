import {
  Directive,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Input,
} from '@angular/core'
@Directive({
  selector: '[scrollToSelected]',
})
export class ScrollToSelectedDirective implements AfterViewInit {
  @Input() scrollToClass: string
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  ngAfterViewInit() {
    if (this.el.nativeElement.classList.contains(this.scrollToClass)) {
      this.el.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }
}
