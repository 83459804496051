import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'
import { FiltersStore, FiltersState } from './filters.store'

@Injectable({ providedIn: 'root' })
export class FiltersQuery extends QueryEntity<FiltersState> {
  constructor(protected store: FiltersStore) {
    super(store)
  }

  getFilters(term: string) {
    return this.selectAll({
      filterBy: (entity) =>
        entity.keyArea.toLowerCase().includes(term) ||
        entity.jurisdictions.toLowerCase().includes(term) ||
        entity.modeBike.toLowerCase().includes(term) ||
        entity.modeBus.toLowerCase().includes(term) ||
        entity.modeCar.toLowerCase().includes(term) ||
        entity.modeFreight.toLowerCase().includes(term) ||
        entity.modeMicro.toLowerCase().includes(term) ||
        entity.modeOther.toLowerCase().includes(term) ||
        entity.modePed.toLowerCase().includes(term) ||
        entity.modeRideShare.toLowerCase().includes(term) ||
        entity.modeTransit.toLowerCase().includes(term),
    })
  }
}
