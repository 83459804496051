import { Component, Input, OnInit } from '@angular/core'

import { barChartData } from './chart-data'

import * as Highcharts from 'highcharts'
require('highcharts/modules/exporting')(Highcharts)

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() id: string

  Highcharts: typeof Highcharts = Highcharts
  chartOptions: Highcharts.Options
  chartData: Highcharts.SeriesOptionsType[]
  recommnedationData: {
    ID: string
    Category: string
    'Travel Performance': number
    Safety: number
    'Land Use Alignment': number
    'Mode Choice': number
    'Regional Scalability': number
    Equity: number
    Total: number
  }[]

  constructor() {}

  ngOnInit(): void {
    this.recommnedationData = barChartData.filter((f) => f.ID === this.id)

    this.buildChart()
  }

  buildChart() {
    let ndata: any[] = []
    let safetyd: any[] = []
    let traveld: any[] = []
    let landd: any[] = []
    let moded: any[] = []
    let regionald: any[] = []
    let totald: any[] = []
    let data: any[] = []

    this.recommnedationData.forEach((v, i) => {
      ndata.push(...[v.Equity])
      safetyd.push(...[v.Safety])
      traveld.push(...[v['Travel Performance']])
      landd.push(...[v['Land Use Alignment']])
      moded.push(...[v['Mode Choice']])
      regionald.push(...[v['Regional Scalability']])
      // totald.push(...[v.Total])
    })
    data.push({ type: 'bar', name: 'Equity', data: ndata })
    data.push({ type: 'bar', name: 'Safety', data: safetyd })
    data.push({ type: 'bar', name: 'Travel Performance', data: traveld })
    data.push({ type: 'bar', name: 'Land Use Alignment', data: landd })
    data.push({ type: 'bar', name: 'Mode Choice', data: moded })
    data.push({
      type: 'bar',
      name: 'Regional Scalability',
      data: regionald,
    })
    // Safety and Travel Peformance
    // Multimodal & Land Use Coordination
    // Public Engagement Feedback
    // data.push({ type: 'bar', name: 'Regional Scalability', data: regionald })
    this.chartData = data

    this.chartOptions = {
      credits: {
        enabled: false,
      },
      colors: [
        '#5CA044',
        '#16183E',
        '#448AA0',
        '#A05A44',
        '#8844A0',
        '#B9D2A7',
        '#FF9655',
        '#FFF263',
        '#6AF9C4',
      ],
      chart: {
        type: 'bar',
      },
      xAxis: {
        categories: [
          'Safety and Travel Peformance',
          'Multimodal & Land Use Coordination',
          'Public Engagement Feedback',
        ],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Percent of Ideal Solution',
        },
      },
      title: { text: 'Scenario Comparison' },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      series: this.chartData,
    }
  }
}
