import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog, DialogPosition } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Recommendation } from 'src/app/types'
import { WorkplanService } from 'src/app/workplan/state/workplan.service'
import { DetailsComponent } from '../details/details.component'
import { PopupComponent } from '../popup/popup.component'
import { RecommendationsQuery } from '../state/recommendations.query'
import { RecommendationsService } from '../state/recommendations.service'

@Component({
  selector: 'app-reco-card',
  templateUrl: './recommendation-card.component.html',
  styleUrls: ['./recommendation-card.component.scss'],
})
export class RecommendationCardComponent implements OnInit {
  @Input() recommendation: Recommendation
  @Output() toggleEvent: EventEmitter<string> = new EventEmitter()
  active = false
  selectedRecommendation: Recommendation

  constructor(
    public dialog: MatDialog,
    private workplanService: WorkplanService,
    private recommendationService: RecommendationsService,
    private recommendationQuery: RecommendationsQuery,
    private router: Router
  ) {}

  ngOnInit(): void {}

  addToWorkplan(id: string) {
    this.workplanService.addRecommendationToWorkplan(id)
  }

  setActive(id: string) {
    this.recommendationService.setActive(id)
  }

  goToDetailsPage(id: string) {
    this.recommendationService.setActive(id)
    this.router.navigateByUrl(`/details/${id}`)
  }
  toggleActive(id: string) {
    if (
      this.recommendationQuery.getActive()[0] &&
      this.recommendationQuery.getActive()[0].id !== id
    ) {
      this.toggleEvent.emit(this.recommendationQuery.getActive()[0].id)
    }
    this.recommendationService.setActive(id)
    this.recommendation = this.recommendationQuery.getActive()[0]
    // console.log(this.recommendationQuery.getActive())
    this.active = !this.active
  }

  openDetails(): void {
    this.dialog
      .open(PopupComponent, {
        // position: { top: '15' },
        width: '50%',
        // height: '100%',
        data: {
          recommendation: this.recommendation,
        },
      })
      .afterClosed()
      .subscribe((s) => {
        if (s) {
          this.active = true
        }
      })
  }
}
